import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { LimitSettings } from './LimitSettings';

import {
  req as getFactoringLimit,
  RequestDataType,
  Limit
} from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';
import { Loader } from 'Layouts/components';
import {
  InfoBlock,
  InfoBottom,
  StatusItem,
  ActiveContainer,
  ActiveContainerHovered,
  InactiveContainer,
  InactiveContainerHovered,
  CheckedIcon,
  CrossIcon,
  HandleContainer,
  SettingsChoiceStyled,
  SettingsButtonChosen
} from './styles';
import { PageStyled, PageHeaderContainer } from 'src/features/SCF/UI/ScfStyles';
import '../../styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import SCFApi from 'src/features/SCF/api';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringLimit: Limit[];
}

interface DispatchToProps {
  getFactoringLimit: (inn: string, data?: RequestDataType) => void;
}

interface State {
  settingsState: string;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class LimitInfo extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        settingsState: 'Settings'
      };
    }
  }

  handleSettingsStateChange = (setting: string) => {
    this.setState({ settingsState: setting });
  };

  onSettingsClick = () => {
    let settings = document.getElementById('settingsLink');
    let suppliers = document.getElementById('suppliersLink');
    let registries = document.getElementById('registriesLink');
    this.handleSettingsStateChange('Settings');
    settings.style.color = '#000AFE';
    suppliers.style.color = '#7A7D83';
    registries.style.color = '#7A7D83';
  };

  onSuppliersClick = () => {
    let settings = document.getElementById('settingsLink');
    let suppliers = document.getElementById('suppliersLink');
    let registries = document.getElementById('registriesLink');
    this.handleSettingsStateChange('Suppliers');
    suppliers.style.color = '#000AFE';
    settings.style.color = '#7A7D83';
    registries.style.color = '#7A7D83';
  };

  onRegistriesClick = () => {
    let settings = document.getElementById('settingsLink');
    let suppliers = document.getElementById('suppliersLink');
    let registries = document.getElementById('registriesLink');
    this.handleSettingsStateChange('Registries');
    registries.style.color = '#000AFE';
    suppliers.style.color = '#7A7D83';
    settings.style.color = '#7A7D83';
  };

  componentDidMount() {
    const { getFactoringLimit, match } = this.props;

    getFactoringLimit(match.params.id);
  }

  render() {
    const { status, factoringLimit, error } = this.props;
    return (
      <PageStyled>
        <PageHeaderContainer>
          <h2>Карточка лимита</h2>
        </PageHeaderContainer>
        {/* {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <InfoBlock>
            <h1>ИНН {factoringLimit[0].debtorINN}</h1>
            <InfoBottom>
              <h2>{factoringLimit[0].debtorShortName}</h2>
              {factoringLimit[0].isActual == true ? (
                <StatusItem className="td-status">
                  <ActiveContainer style={{ margin: '0 7px' }}>
                    <CheckedIcon style={{ margin: '0 7px' }} />
                    <p>Доступен</p>
                  </ActiveContainer>
                  <InactiveContainerHovered
                    className="inactive-status"
                    style={{ margin: '0 7px' }}
                    // onClick={() =>
                    //   SCFApi.setFactoringLimitInactive(
                    //     factoringLimit.items[0].debtorINN
                    //   )
                    // }
                  >
                    <HandleContainer>
                      <CrossIcon style={{ margin: '0 7px 0 7px' }} />
                    </HandleContainer>
                  </InactiveContainerHovered>
                  <h1 className="inactive-status">Отключить</h1>
                </StatusItem>
              ) : (
                <StatusItem className="td-status">
                  <InactiveContainer style={{ margin: '0 7px' }}>
                    <CrossIcon style={{ margin: '0 7px' }} />
                    <p>Отключен</p>
                  </InactiveContainer>
                  <ActiveContainerHovered
                    className="active-status"
                    style={{ margin: '0 7px' }}
                    // onClick={() =>
                    //   SCFApi.setFactoringLimitActive(
                    //     factoringLimit.items[0].debtorINN
                    //   )
                    // }
                  >
                    <HandleContainer>
                      <CheckedIcon style={{ marginLeft: '5px' }} />
                    </HandleContainer>
                  </ActiveContainerHovered>
                  <h1 className="active-status">Подключить</h1>
                </StatusItem>
              )}
            </InfoBottom>
          </InfoBlock>
        )} */}
        <SettingsChoiceStyled>
          <SettingsButtonChosen
            id="settingsLink"
            onClick={() => this.onSettingsClick()}
          >
            Настройки
          </SettingsButtonChosen>
          <SettingsButtonChosen
            style={{ color: '#7A7D83' }}
            id="suppliersLink"
            onClick={() => this.onSuppliersClick()}
          >
            Поставщики
          </SettingsButtonChosen>
          <SettingsButtonChosen
            style={{ color: '#7A7D83' }}
            id="registriesLink"
            onClick={() => this.onRegistriesClick()}
          >
            Реестры
          </SettingsButtonChosen>
        </SettingsChoiceStyled>
        {this.state.settingsState == 'Settings' ? <LimitSettings /> : ''}
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  factoringLimit: SCFFactor.getFactoringFeesOfLimits.data,
  status: SCFFactor.getFactoringFeesOfLimits.status,
  error: SCFFactor.getFactoringFeesOfLimits.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringLimit
    },
    dispatch
  );

const LimitInfoConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LimitInfo)
);

export { LimitInfoConnect as LimitInfo };
