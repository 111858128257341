import { Reducer, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';

export interface GetUserDataStoreState extends Reducer {
  data: ResponseDataType;
}

export interface ResponseDataType {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyInn?: string;
}

export enum USER_ACTIONS {
  USER_REQ = 'USER_REQ',
  USER_GOT = 'USER_GOT',
  USER_ERR = 'USER_ERR',
  USER_RESET = 'USER_RESET'
}

const initialState: GetUserDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    permissions: [],
    roles: [],
    companyInn: ''
  },
  error: {}
};

const getUserData = (
  state: GetUserDataStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetUserDataStoreState => {
  switch (action.type) {
    case USER_ACTIONS.USER_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case USER_ACTIONS.USER_GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case USER_ACTIONS.USER_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    case USER_ACTIONS.USER_RESET:
      return initialState;

    default:
      return state;
  }
};

export default getUserData;
