import { PATCH_UPDATE_COMPANY_BLANK } from '../reducers/patchUpdateCompanyBlank';

export interface RequestData {
  shortTermLoans?: number;
  longTermLoans?: number;
  totalLoans?: number;
  revenue?: number;
  capital?: number;
  netIncome?: number;
  taxSystem?: number;
}

export const req = (inn: string, data: RequestData) => ({
  type: PATCH_UPDATE_COMPANY_BLANK.REQ,
  inn,
  data
});

export const got = () => ({
  type: PATCH_UPDATE_COMPANY_BLANK.GOT
});

export const err = (error: {}) => ({
  type: PATCH_UPDATE_COMPANY_BLANK.ERR,
  error
});
