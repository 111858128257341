import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { NegativeValue } from 'src/features/Beneficiaries/components/styled';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader
} from 'src/features/Layouts/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { req as getManagers } from 'src/features/Persons/actions/getManagers';
import {
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { ManagersTypes } from 'src/features/Persons/types';

import { formSumStringThousands } from 'src/shared/utils/Utils';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  managers: ManagersTypes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusCompanyByInn: REQUEST_STATUSES;
}

interface DispatchToProps {
  getManagers: (inn: string) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Managers: React.FC<Props> = ({
  getManagers,
  managers,
  status,
  error,
  getCompanyByInn,
  resetStateCompanyByInn,
  statusCompanyByInn,
  match
}) => {
  React.useEffect(
    () => {
      getManagers(match.params.inn);
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (statusCompanyByInn === REQUEST_STATUSES.GOT) {
        window.location.reload();

        return () => resetStateCompanyByInn();
      }
    },
    [statusCompanyByInn]
  );

  const navigateToCompany = (companyInn: string) => {
    window.open(`/crm/companies/${companyInn}/beneficiaries`, '_blank');
  };

  return (
    <div>
      <CompanySectionTitle>Руководитель в:</CompanySectionTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">Инн</TableThStyled>
              <TableThStyled width="15%">Наименование</TableThStyled>
              <TableThStyled width="15%">Должность</TableThStyled>
              <TableThStyled width="15%">Тип позиции ЕГРЮЛ</TableThStyled>
              <TableThStyled width="10%">Год отчетности</TableThStyled>
              <TableThStyled width="10%">Выручка, тыс.</TableThStyled>
              <TableThStyled width="10%">Прибыль, тыс.</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {managers.map((manager, key) => (
              <TableRowStyled
                key={key}
                onClick={
                  Object.keys(manager).some(key => key !== 'companyInn')
                    ? () => navigateToCompany(manager.companyInn)
                    : undefined
                }
              >
                <td>{manager.companyInn}</td>
                {Object.keys(manager).some(key => key !== 'companyInn') ? (
                  <>
                    <td>{manager.companyShortName}</td>
                    <td>{`${manager.position}, с ${new Date(
                      manager.positionStartDate
                    ).toLocaleDateString()}`}</td>
                    <td>{manager.positionType}</td>
                    <td>
                      {manager.financialsYear === null
                        ? 'Неизв.'
                        : manager.financialsYear}
                    </td>
                    <td>
                      {manager.revenue === null ? (
                        'Неизв.'
                      ) : (
                        <NegativeValue isNegative={manager.revenue < 0}>
                          {formSumStringThousands(manager.revenue)}
                        </NegativeValue>
                      )}
                    </td>
                    <td>
                      {manager.netIncome === null ? (
                        'Неизв.'
                      ) : (
                        <NegativeValue isNegative={manager.netIncome < 0}>
                          {formSumStringThousands(manager.netIncome)}
                        </NegativeValue>
                      )}
                    </td>
                  </>
                ) : (
                  <td>
                    <Button
                      label="Обновить данные ЕГРЮЛ"
                      disabled={statusCompanyByInn === REQUEST_STATUSES.REQUEST}
                      onClick={() =>
                        getCompanyByInn({ inn: manager.companyInn })
                      }
                      w="200px"
                      h="40px"
                    />
                  </td>
                )}
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </div>
  );
};

const mapStateToProps = ({ Persons, SCF }: CRM & STORE) => ({
  managers: Persons.getManagers.data,
  status: Persons.getManagers.status,
  error: Persons.getManagers.error,
  statusCompanyByInn: SCF.getCompanyByInnThirdParty.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getManagers,
      getCompanyByInn,
      resetStateCompanyByInn
    },
    dispatch
  );

const ManagersConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Managers)
);

export { ManagersConnect as Managers };
