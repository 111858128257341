export enum GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE {
  REQ = 'GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE_REQ',
  GOT = 'GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE_GOT',
  ERR = 'GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE_ERR'
}

export const req = (guid: string) => ({
  type: GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE.REQ,
  guid
});

export const got = () => ({
  type: GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE.GOT
});

export const err = (error = {}) => ({
  type: GET_SUPPLY_CONTRACT_DEBTOR_SIGNATURE.ERR,
  error
});
