import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';

interface Props {
  inn: string;
  onFilterByInn: (e: React.FormEvent<HTMLInputElement>) => void;
}

const FilterByClient: React.FC<Props> = ({ inn, onFilterByInn }) => {
  return (
    <>
      <Input
        value={inn}
        label="ИНН Компании"
        name="companyInn"
        placeholder={InnPlaceholders.entity}
        onChange={onFilterByInn}
      />
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByClientConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByClient);

export { FilterByClientConnect as FilterByClient };
