import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  req as getUserLeads,
  ResponseData as LeadsData
} from 'src/features/Users/actions/getUserLeads';
import { req as getUserApplications } from 'src/features/Users/actions/getUserApplication';
import { ResponseData as ApplicationData } from 'src/features/ApplicationForCompanies/actions/getApplications';
import { ScrollTopComponent } from 'src/features/Common';
import { LeadsList } from 'src/features/ApplicationForCompanies/components/Tabs/Leads/Leads';
import { ApplicationsList } from 'src/features/ApplicationForCompanies/components/Tabs/Applications/Applications';
import { APPLICATION_TABS } from 'src/features/Users/types';
import { Menu } from 'src/features/Layouts/components';

interface StateToProps {
  applications: ApplicationData;
  statusApplications: REQUEST_STATUSES;
  errorApplications: ResponseError;
  leads: LeadsData;
  statusLeads: REQUEST_STATUSES;
  errorLeads: ResponseError;
}

interface State {
  activeTab: string;
}

interface DispatchToProps {
  getUserLeads: (inn: number | string) => void;
  getUserApplications: (inn: number | string) => void;
}

interface MatchParams {
  id: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ApplicationsForUser extends React.Component<Props, State> {
  tab = this.props.match.params.applicationTab;
  state = {
    activeTab: (this.tab && this.tab.toUpperCase()) || 'DEFAULT'
  };

  componentDidMount() {
    this.initFetch();
  }

  initFetch = () => {
    const { getUserLeads, getUserApplications } = this.props;

    getUserLeads(this.props.match.params.id);
    getUserApplications(this.props.match.params.id);
  };

  handleActiveTab = (tabName: string) => {
    const { pathname } = this.props.location;
    const baseUrl = pathname.replace(
      `/${this.state.activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    this.props.history.replace(newPathname);
    this.setState({ activeTab: tabName });
  };

  render() {
    const {
      leads,
      statusLeads,
      errorLeads,
      applications,
      statusApplications,
      errorApplications
    } = this.props;
    return (
      <ScrollTopComponent>
        <Menu
          activeTab={this.state.activeTab}
          handleActiveTab={this.handleActiveTab}
          tabs={APPLICATION_TABS}
          isSubMenu
        />

        {this.state.activeTab === 'DEFAULT' && (
          <ApplicationsList
            applications={applications}
            status={statusApplications}
            error={errorApplications}
          />
        )}

        {this.state.activeTab === 'LEADS' && (
          <LeadsList leads={leads} status={statusLeads} error={errorLeads} />
        )}
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Users }: CRM) => ({
  applications: Users.getUserApplications.data,
  statusApplications: Users.getUserApplications.status,
  errorApplications: Users.getUserApplications.error,
  leads: Users.getUserLeads.data,
  statusLeads: Users.getUserLeads.status,
  errorLeads: Users.getUserLeads.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserLeads, getUserApplications }, dispatch);

const ApplicationsForUserConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationsForUser)
);

export { ApplicationsForUserConnect as ApplicationsForUser };
