import { POST_CONTACT_BY_PRODUCT } from '../reducers/postContactByProduct';

export interface RequestData {
  contactId: number;
  productId: number;
}

export const req = (data: RequestData) => ({
  type: POST_CONTACT_BY_PRODUCT.REQ,
  data
});

export const got = () => ({
  type: POST_CONTACT_BY_PRODUCT.GOT
});

export const err = (error: {}) => ({
  type: POST_CONTACT_BY_PRODUCT.ERR,
  error
});
