import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutChecklistStoreState extends Reducer {}

export enum PUT_CHECKLIST {
  REQ = 'PUT_CHECKLIST_REQ',
  GOT = 'PUT_CHECKLIST_GOT',
  ERR = 'PUT_CHECKLIST_ERR',
  RESET = 'PUT_CHECKLIST_RESET'
}

export const initialState: PutChecklistStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putChecklist = (
  state: PutChecklistStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PutChecklistStoreState => {
  switch (action.type) {
    case PUT_CHECKLIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_CHECKLIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_CHECKLIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PUT_CHECKLIST.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default putChecklist;
