import { LeadsData } from '../actions/setLeadFormData';

export interface SetLeadDataStoreState extends Partial<LeadsData> {}

export enum SET_LEAD_DATA {
  SET = 'SET_LEAD_DATA_SET',
  RESET = 'SET_LEAD_DATA_RESET',
}

export const initialState: SetLeadDataStoreState = {
  amount: 0,
  sourceUserId: 0,
  inn: null
};

const setLeadFormData = (
  state: SetLeadDataStoreState = initialState,
  action: {
    type: string;
    data: LeadsData;
  }
): SetLeadDataStoreState => {
  switch (action.type) {
    case SET_LEAD_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_LEAD_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setLeadFormData;
