import { GET_FACTORING_LIMITS_BY_DEBTOR_LIST } from '../reducers/getFactoringLimitsByDebtorList';
import { FactoringLimitRead } from './setFactoringLimitFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: FactoringLimitRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  debtorInn: string;
}

export const req = (data: RequestData) => ({
  type: GET_FACTORING_LIMITS_BY_DEBTOR_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_FACTORING_LIMITS_BY_DEBTOR_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_LIMITS_BY_DEBTOR_LIST.ERR,
  error
});
