import { combineReducers } from 'redux';
import businessRegister, {
  BusinessRegisterStoreState
} from './businessRegister';
import businessStatus, { BusinessStatusStoreState } from './businessStatus';
import getPersonsOfCompanies, {
  GetPersonsOfCompaniesStoreState
} from './getPersonsOfCompanies';
import getBenificiariesOfCompanies, {
  GetBeneficiariesOfCompaniesStoreState
} from './getBenificiariesOfCompanies';
import getSubsidiariesOfCompanies, {
  GetSubsidiariesOfCompaniesStoreState
} from './getSubsidiariesOfCompanies';
import getCompanyByInnConfirmedFounders, {
  GetCompanyByInnConfirmedFoundersStoreState
} from './getCompanyByInnConfirmedFounders';
import getCompanyByInnConfirmedSubsidiaries, {
  GetCompanyByInnConfirmedSubsidiariesStoreState
} from './getCompanyByInnConfirmedSubsidiaries';

export interface BusinessReducers {
  businessRegister: BusinessRegisterStoreState;
  businessStatus: BusinessStatusStoreState;
  getPersonsOfCompanies: GetPersonsOfCompaniesStoreState;
  getBenificiariesOfCompanies: GetBeneficiariesOfCompaniesStoreState;
  getSubsidiariesOfCompanies: GetSubsidiariesOfCompaniesStoreState;
  getCompanyByInnConfirmedFounders: GetCompanyByInnConfirmedFoundersStoreState;
  getCompanyByInnConfirmedSubsidiaries: GetCompanyByInnConfirmedSubsidiariesStoreState;
}

export const Business = combineReducers({
  businessRegister,
  businessStatus,
  getPersonsOfCompanies,
  getBenificiariesOfCompanies,
  getSubsidiariesOfCompanies,
  getCompanyByInnConfirmedFounders,
  getCompanyByInnConfirmedSubsidiaries
});
