import { GET_FINANCIAL_DOCUMENTS } from '../reducers/getFinancialDocuments';
import { FinancialDocumentsData } from '../types';

export const req = (inn: string) => ({
  type: GET_FINANCIAL_DOCUMENTS.REQ,
  inn
});

export const got = (data: FinancialDocumentsData) => ({
  type: GET_FINANCIAL_DOCUMENTS.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_FINANCIAL_DOCUMENTS.ERR,
  error
});
