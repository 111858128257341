import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { APPLICATION_STATUSES } from 'Application/types';

export interface ChangeApplicationStatusStoreState extends Reducer {
  data: RequestDataType;
}

export interface RequestDataType {
  id: number;
  status: APPLICATION_STATUSES;
}

export enum CHANGE_APPLICATION_STATUS {
  REQ = 'CHANGE_APPLICATION_STATUS_REQ',
  GOT = 'CHANGE_APPLICATION_STATUS_GOT',
  ERR = 'CHANGE_APPLICATION_STATUS_ERR'
}

export const initialState: ChangeApplicationStatusStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: null,
    status: null
  },
  error: {}
};

const changeApplicationStatus = (
  state: ChangeApplicationStatusStoreState = initialState,
  action: {
    type: CHANGE_APPLICATION_STATUS;
    data: RequestDataType;
    error: {};
  }
): ChangeApplicationStatusStoreState => {
  switch (action.type) {
    case CHANGE_APPLICATION_STATUS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case CHANGE_APPLICATION_STATUS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CHANGE_APPLICATION_STATUS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default changeApplicationStatus;
