import { GET_DEBTOR } from '../reducers/getDebtor';
import { DebtorWrite } from './setDebtorFormData';

export const req = (inn: string) => ({
  type: GET_DEBTOR.REQ,
  inn
});

export const got = (debtor: DebtorWrite) => ({
  type: GET_DEBTOR.GOT,
  debtor
});

export const err = (error: {}) => ({
  type: GET_DEBTOR.ERR,
  error
});
