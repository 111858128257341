import { GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID } from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';

export interface Calc {
  baseFullSumm: number;
  baseMonetaryClaimGuid: string;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  cessedMonetaryClaimGuid: string;
  createdDateTime: string;
  creditorInn: string;
  creditorName: string;
  currencyCode: string;
  currencyID: number;
  emergenceDate: string;
  firstDebtorRegistryOccurence: number;
  guid: string;
  invoiceDocsQty: number;
  invoiceDocumentDate: string;
  invoiceDocumentName: string;
  invoiceDocumentNumber: string;
  lastDebtorRegistryOccurence: number;
  maturityDate: string;
  montaryClaimId: null;
  paidStatus: number;
  status: null;
  supplierName: string;
  supplyContractGuid: string;
  supplyContractNumber: string;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ,
  data
});

export const got = (data: Calc[]) => ({
  type:
    GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR,
  error
});
