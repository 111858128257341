import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ApplicationForCompaniesApi {
  static getLeads(inn: string) {
    return ApiRequest(`/api/lead/agent/${inn}`);
  }

  static getApplications(inn: string) {
    return ApiRequest(`/api/application/agent/${inn}`);
  }

  static getApplicationsCompany(inn: string) {
    return ApiRequest(`/api/application/company/${inn}`);
  }
}
