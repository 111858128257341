import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { Input } from 'shared/ui/Input';
import { updateClientNameFilter } from 'src/features/Filters/NameClientFilter/actions/updateNameClientFilter';
import { NameClientFilterContainerStyled } from 'src/features/Filters/NameClientFilter/components/styled';

interface StateToProps {
  clientName: string;
}

interface AnyProps {
  label?: string;
}

interface DispatchToProps {
  updateClientNameFilter: (name: string) => void;
}

type Props = StateToProps & DispatchToProps & AnyProps;

class NameClientFilter extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    this.props.updateClientNameFilter(value);
  };

  render() {
    return (
      <NameClientFilterContainerStyled>
        <Input
          value={this.props.clientName}
          label={this.props.label ? this.props.label : 'Наименование Клиента'}
          name="clientName"
          onChange={this.onChange}
        />
      </NameClientFilterContainerStyled>
    );
  }
}

const mapStateToProps = ({ Filters }: STORE) => ({
  clientName: Filters.updateClientNameFilter.clientName
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ updateClientNameFilter }, dispatch);

const NameClientFilterConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(NameClientFilter);

export { NameClientFilterConnect as NameClientFilter };
