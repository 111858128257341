import * as React from 'react';
import { connect } from 'react-redux';

import {
  ContractFinancingRead,
  Document as IDocument
} from 'Application/types';
import { DocumentsBlock } from 'Layouts/components';
import { DownloadLink } from 'shared/styled';
import { STORE } from 'src/globaltypes';

import ApplicationApi from 'src/features/Application/api';
import { ApplicationSubtitle } from '../../styles';
import { GeneralDocumentsBlockStyled } from './styles';

interface OwnProps {
  documents: IDocument[];
}

interface StateToProps {
  externalApplication: Partial<ContractFinancingRead>;
}

type Props = OwnProps & StateToProps;

const GeneralDocumentsBlock: React.FC<Props> = ({
  externalApplication,
  documents
}) =>
  documents.length > 0 && (
    <GeneralDocumentsBlockStyled>
      <ApplicationSubtitle>
        Общие документы
        <DownloadLink
          href={ApplicationApi.getDownloadApplicationDocumentsLink(
            externalApplication.id
          )}
        />
      </ApplicationSubtitle>
      <DocumentsBlock documents={documents} />
    </GeneralDocumentsBlockStyled>
  );

const mapStateToProps = ({ ExternalAgentRole }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data
});

const GeneralDocumentsBlockConnect = connect<StateToProps>(mapStateToProps)(
  GeneralDocumentsBlock
);

export { GeneralDocumentsBlockConnect as GeneralDocumentsBlock };
