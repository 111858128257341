import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationsList } from './ApplicationsList/ApplicationsList';
import { PickCreditor } from './PickCreditor/PickCreditor';
import { ApplicationCalc } from './ApplicationCalc/ApplicationCalc';
import { Agreement } from './Agreement/Agreement';
import { ApplicationInfo } from './ApplicationInfo/ApplicationInfo';
import { PickSignee } from './PickSignee/PickSignee';
import { EarlyPaymentApplicationsPageStyled } from './styles';
import { MakeOffer } from './MakeOffer/MakeOffer';

export class EarlyPaymentApplicationsPage extends React.Component {
  render() {
    return (
      <EarlyPaymentApplicationsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={'/supplier/early_payment_applications'}
            component={() => <ApplicationsList />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:id/pick_creditor'}
            render={() => <PickCreditor />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:id/pick_signee'}
            render={() => <PickSignee />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:id/calc'}
            render={() => <ApplicationCalc />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:id/agreement'}
            render={() => <Agreement />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:id'}
            render={() => <ApplicationInfo />}
          />
          <Route
            exact={true}
            path={'/supplier/early_payment_applications/:guid/make_offer'}
            render={() => <MakeOffer />}
          />
        </Switch>
      </EarlyPaymentApplicationsPageStyled>
    );
  }
}
