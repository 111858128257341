import { POST_DOCUMENT_LIST_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postDocumentListExternal';

export const req = (guid: string) => ({
  type: POST_DOCUMENT_LIST_EXTERNAL.REQ,
  guid
});

export const got = () => ({
  type: POST_DOCUMENT_LIST_EXTERNAL.GOT
});

export const err = (error: {}) => ({
  type: POST_DOCUMENT_LIST_EXTERNAL.ERR,
  error
});
