import {
  CHANGE_APPLICATION_STATUS,
  RequestDataType
} from 'Application/reducers/changeApplicationStatus';

export const req = (data: RequestDataType) => ({
  type: CHANGE_APPLICATION_STATUS.REQ,
  data
});

export const got = () => ({
  type: CHANGE_APPLICATION_STATUS.GOT
});

export const err = (error: {}) => ({
  type: CHANGE_APPLICATION_STATUS.ERR,
  error
});
