import * as React from 'react';
import { connect } from 'react-redux';

import { req as getBankAccount } from './../../../../../../../actions/getBankAccount';
import { ResponseDataType as GetBankAccountResponseDataType } from './../../../../../../../actions/getBankAccount';
import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';
import { BankAccount as BankAccountType } from './../../../../../../../types/bankAccount';
import { BankAccountsTableView } from './BankAccountsTableView/BankAccountsTableView';
import { RequestDataType as DeleteBankAccountRequestDataType } from './../../../../../../../reducers/deleteBankAccount';
import { req as deleteBankAccount } from './../../../../../../../actions/deleteBankAccount'


interface StateToProps {
  bankAccount: GetBankAccountResponseDataType;
}

interface DispatchToProps {
  getBankAccount: () => void;
  deleteBankAccount: (data: DeleteBankAccountRequestDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class BankAccountsList extends React.Component<Props> {
  componentDidMount() {
    this.props.getBankAccount();
  }

  getBankAccountId = (index: number): number => {
    const item = this.props.bankAccount.items[index];
    return !!item && item.id;
  };

  onBankAccountClick = (index: number): void => {
    var id: number,
      id = this.getBankAccountId(index)
    this.props.deleteBankAccount({ id })
  };

  formBankAccountData = (): BankAccountType[] => {
    return this.props.bankAccount.items.map(this.bankAccountToView);
  };

  bankAccountToView = (bankAccount: BankAccountType) => {
    const viewItem: BankAccountType = {
      id: bankAccount.id,
      isDeleted: bankAccount.isDeleted,
      clientInn: bankAccount.clientInn,
      bankInn: bankAccount.bankInn,
      bankBik: bankAccount.bankBik,
      bankName: bankAccount.bankName,
      accountNumber: bankAccount.accountNumber,
      correspondentAccountNumber: bankAccount.correspondentAccountNumber,
      isActive: bankAccount.isActive,
      isMain: bankAccount.isMain,
      currencyNumber: bankAccount.currencyNumber,
      currencyCode: bankAccount.currencyCode,
      creatorId: bankAccount.creatorId,
    }
    return viewItem;
  }

  render() {
    const { bankAccount } = this.props;
    return (
      <React.Fragment>
        <BankAccountsTableView
          list={this.formBankAccountData()}
          onBankAccountClick={this.onBankAccountClick}>
        </BankAccountsTableView>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  bankAccount: SCF.getBankAccount.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBankAccount,
      deleteBankAccount,
    },
    dispatch
  );


const BankAccountsListConnect = (
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BankAccountsList));


export { BankAccountsListConnect as BankAccountsList };
