import { GET_EXTERNAL_LEADS } from 'entities/Cabinet/ExternalAgent/model/reducers/getExternalLeads';

interface LeadData {
  id: number;
  createdDateTime: string;
  inn: string;
  clientName: string;
  applicationFinancingType: string;
  amount: number;
  termStartDate: null;
  termEndDate: null;
  purchaseNumber: null;
  comment: string;
  status: string;
  rejectReason: null;
  docsChecklist: number;
  bkiChecklist: number;
  bgParametersChecklist: number;
  applicationToBankCheckList: number;
  dealToBankChecklist: number;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: LeadData[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  clientinn?: string;
}

export const req = (data: RequestData) => ({
  type: GET_EXTERNAL_LEADS.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_EXTERNAL_LEADS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_LEADS.ERR,
  error
});
