import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type DeleteBorrowerCompanyDocumentStoreState = Reducer;

export interface RequestDataType {
  id: number;
}

export interface SagaRequestDataType extends RequestDataType {
  companyId: number;
}

export enum DELETE_BORROWER_COMPANY_DOCUMENT {
  REQ = 'DELETE_BORROWER_COMPANY_DOCUMENT_REQ',
  GOT = 'DELETE_BORROWER_COMPANY_DOCUMENT_GOT',
  ERR = 'DELETE_BORROWER_COMPANY_DOCUMENT_ERR'
}

export const initialState: DeleteBorrowerCompanyDocumentStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deleteBorrowerCompanyDocument = (
  state: DeleteBorrowerCompanyDocumentStoreState = initialState,
  action: {
    type: DELETE_BORROWER_COMPANY_DOCUMENT;
    error: {};
  }
): DeleteBorrowerCompanyDocumentStoreState => {
  switch (action.type) {
    case DELETE_BORROWER_COMPANY_DOCUMENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_BORROWER_COMPANY_DOCUMENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_BORROWER_COMPANY_DOCUMENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteBorrowerCompanyDocument;
