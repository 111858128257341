export enum CREDIT_PRODUCTS {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть кредитные продукты'
}

export enum HAS_PLEDGES {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть залоги'
}

export enum CREDIT_GOZ {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть поддержка'
}

export enum OPEN_LIMITS {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть лимиты'
}
