import { GET_FINANCIAL_QUALITY } from '../reducers/getFinancialQuality';

export interface ResponseData {
  financialsQuality: number;
}

export const req = (inn: string) => ({
  type: GET_FINANCIAL_QUALITY.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_FINANCIAL_QUALITY.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FINANCIAL_QUALITY.ERR,
  error
});
