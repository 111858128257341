import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResponseError, REQUEST_STATUSES, CRM } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { ScrollTopComponent } from 'src/features/Common';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getPersonsList,
  ResponseData,
  RequestData
} from 'src/features/Persons/actions/getPersonsList';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { FilterStyled } from './styled';
import { InnPlaceholders } from 'shared/constants';

interface StateToProps {
  persons: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getPersonsList: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const PersonsList: React.FC<Props> = ({
  getPersonsList,
  persons,
  status,
  error,
  history
}) => {
  const [inn, setInn] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [middlename, setMiddlename] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const inn = searchParams.get('inn') || '';
      const firstname = searchParams.get('firstname') || '';
      const lastname = searchParams.get('lastname') || '';
      const middlename = searchParams.get('middlename') || '';

      setInn(inn);
      setFirstname(firstname);
      setLastname(lastname);
      setMiddlename(middlename);

      getPersonsList({
        page,
        pageSize: 20,
        ...(firstname && { firstname }),
        ...(lastname && { lastname }),
        ...(middlename && { middlename }),
        ...(inn && { inn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToPerson = (inn: string) => {
    history.push(`/crm/persons/${inn}`);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Бенефициары и руководители</ApplicationTitle>

      <FiltersStyled>
        <FilterStyled>
          <Input
            value={lastname}
            label="Фамилия"
            name="lastname"
            onChange={onFilterChange}
          />
        </FilterStyled>
        <FilterStyled>
          <Input
            value={firstname}
            label="Имя"
            name="firstname"
            onChange={onFilterChange}
          />
        </FilterStyled>
        <FilterStyled>
          <Input
            value={middlename}
            label="Отчество"
            name="middlename"
            onChange={onFilterChange}
          />
        </FilterStyled>
        <FilterStyled>
          <Input
            value={inn}
            label="ИНН ФЛ"
            name="inn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </FilterStyled>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ИНН ФЛ</TableThStyled>
              <TableThStyled width="15%">ФИО</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {persons.items.map(person => (
              <TableRowStyled
                key={person.id}
                onClick={() => navigateToPerson(person.personINN)}
              >
                <td>{person.personINN}</td>
                <td>
                  {person.lastName} {person.firstName} {person.middleName}
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={persons} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Persons }: CRM) => ({
  persons: Persons.getPersonsList.data,
  status: Persons.getPersonsList.status,
  error: Persons.getPersonsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getPersonsList }, dispatch);

const PersonsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PersonsList)
);

export { PersonsListConnect as PersonsList };
