import { PUT_SEND_TO_CLIENT } from '../reducers/putSendToClient';

export const req = (
  guid: string,
  { leadContactId }: { leadContactId: number }
) => ({
  type: PUT_SEND_TO_CLIENT.REQ,
  guid,
  leadContactId
});

export const got = () => ({
  type: PUT_SEND_TO_CLIENT.GOT
});

export const err = (error: {}) => ({
  type: PUT_SEND_TO_CLIENT.ERR,
  error
});
