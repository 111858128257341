import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { PersonType } from '../types';

export interface GetPersonStoreState extends Reducer {
  data: PersonType;
}

export enum GET_PERSON {
  REQ = 'GET_PERSON_REQ',
  GOT = 'GET_PERSON_GOT',
  ERR = 'GET_PERSON_ERR',
  RESET = 'GET_PERSON_RESET'
}

export const initialState: GetPersonStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getPerson = (
  state: GetPersonStoreState = initialState,
  action: {
    type: string;
    data: PersonType;
    error: {};
  }
): GetPersonStoreState => {
  switch (action.type) {
    case GET_PERSON.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PERSON.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PERSON.RESET:
      return {
        ...initialState
      };
    case GET_PERSON.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPerson;
