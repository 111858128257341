export enum MODAL_NAMES {
  EMPTY = 'EMPTY',
  APPLICATION_ACTION = 'APPLICATION_ACTION',
  CLOSE_APPLICATION_PROCESS = 'CLOSE_APPLICATION_PROCESS',
  REGISTRATION_AGREEMENT = 'REGISTRATION_AGREEMENT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CHOOSE_BANKS = 'CHOOSE_BANKS',
  REJECT_REASON_MODAL = 'REJECT_REASON_MODAL',
  INCORRECT_AUTH_DATA_MODAL = 'INCORRECT_AUTH_DATA_MODAL',
  ERROR_FINANCIAL_COMPANY_MODAL = 'ERROR_FINANCIAL_COMPANY_MODAL',
  HINT_FINANCIAL_COMPANY_MODAL = 'HINT_FINANCIAL_COMPANY_MODAL',
  FORM_OF_THE_DISCOUNT_AGREEMENT = 'FORM_OF_THE_DISCOUNT_AGREEMENT',
  INN_COMPANIES_ADDED = 'INN_COMPANIES_ADDED',
  ALL_IN_LIST_REFRESH = 'ALL_IN_LIST_REFRESH',
  DOCUMENT_FORM = 'DOCUMENT_FORM',
  RESPONSE_DOCUMENT_FORM = 'RESPONSE_DOCUMENT_FORM',
  PAYER_ACCOUNT = 'PAYER_ACCOUNT',
  POWER_ATTORNEY = 'POWER_ATTORNEY',
  DISCOUNT_AUCTION = 'DISCOUNT_AUCTION',
  ERROR_DELETE_AUCTION = 'ERROR_DELETE_AUCTION',
  ADDING_MONETARY_REQUIREMENTS = 'ADDING_MONETARY_REQUIREMENTS',
  ERROR_OFFER_DISCOUNT = 'ERROR_OFFER_DISCOUNT',
  ERROR_EARLY_DATE_PICK = 'ERROR_EARLY_DATE_PICK'
}
