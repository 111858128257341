import * as React from 'react';

import { ActionButtonsBlockStyled } from './styles';
import { Button } from 'shared/ui/Button';
import { red } from 'shared/styled';

interface Props {
  openFileUploader: () => void;
  postCommentHandler: () => void;
  postEnabled: boolean;
}

export const ActionButtonsBlock: React.FC<Props> = ({
  openFileUploader,
  postCommentHandler,
  postEnabled
}) => (
  <ActionButtonsBlockStyled>
    <Button
      label="Прикрепить файл"
      onClick={openFileUploader}
      w="210px"
      backgroundColor="transparent"
    />
    <Button
      label={
        postEnabled
          ? 'Отправить комментрий'
          : 'Нельзя отправить пустой комментарий'
      }
      onClick={postCommentHandler}
      disabled={!postEnabled}
      w="210px"
      backgroundColor={red}
    />
  </ActionButtonsBlockStyled>
);
