import { GET_ONE_DEBTOR_ROLE_BY_GUID } from 'entities/SCF/Debtor/model/reducers/getOneDebtorRoleByGuid';

export interface ResponseDataType {
  guid: string;
  createdDateTime: Date;
  debtorName: string;
  supplierInn: string;
  supplierName: string;
  payDate: Date;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: Date;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  statusDebtor: string;
  status: number;
  agreementFileIdentifier: string;
  archiveFileIdentifier: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  rejectSubject: number;
  rejectComment: string;
  contractId: string;
  supplierCertificateSerial: string;
  supplierSigneeInn: string;
  supplierSigneePersonInn: string;
  investorCertificateSerial: string;
  investorSigneeInn: string;
  investorSigneePersonInn: string;
  keyRate: number;
  keyRateIncome: number;
  platformFee: number;
  debtorInspectorUserId: number;
  debtorInspectorComment: string;
  debtorSigneeName: string;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_ERR,
  error
});
