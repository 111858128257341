import { PUT_REJECT_DEBTOR } from 'entities/SCF/Debtor/model/reducers/putRejectDebtor';

export const req = (inn: string) => ({
  type: PUT_REJECT_DEBTOR.REQ,
  inn
});

export const got = () => ({
  type: PUT_REJECT_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: PUT_REJECT_DEBTOR.ERR,
  error
});
