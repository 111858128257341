import * as React from 'react';
import {
  Container,
  Block,
  NormalText,
  BoldText,
  Title,
  BoldTextItalic
} from './styles';

type Props = {
  positionGenitive: string;
  personFullnameGenitive: string;
  authorityGenitive: string;
};

export const DisplayHeader = ({
  positionGenitive,
  personFullnameGenitive,
  authorityGenitive
}: Props) => (
  <Container>
    <Title>Как будет отображаться в шапке договора:</Title>
    <Block>
      <NormalText>
        Общество с ограниченной ответственностью “ФинФэктори” в лице
      </NormalText>
      {positionGenitive !== '' &&
      positionGenitive !== null &&
      positionGenitive !== undefined ? (
        <BoldText>{positionGenitive}</BoldText>
      ) : (
        <BoldTextItalic>{'{должность в р.п.}'}</BoldTextItalic>
      )}
      {personFullnameGenitive !== '' &&
      personFullnameGenitive !== null &&
      personFullnameGenitive !== undefined ? (
        <BoldText>{personFullnameGenitive},</BoldText>
      ) : (
        <BoldTextItalic>{'{Фамилия Имя Отчество в р.п.}'},</BoldTextItalic>
      )}
      <NormalText>действующего на основании</NormalText>
      {authorityGenitive !== '' &&
      authorityGenitive !== null &&
      authorityGenitive !== undefined ? (
        <BoldText>{authorityGenitive},</BoldText>
      ) : (
        <BoldTextItalic>{'{Основание полномочий в р.п.}'},</BoldTextItalic>
      )}
      <NormalText>именуемое в дальнейшем</NormalText>
      {window.location.pathname.includes('supplier') ? (
        <NormalText>"Поставщик", ...</NormalText>
      ) : window.location.pathname.includes('debtor') ? (
        <NormalText>"Покупатель", ...</NormalText>
      ) : (
        <NormalText>"Фактор", ...</NormalText>
      )}
    </Block>
  </Container>
);
