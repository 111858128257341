import styled from 'styled-components';
import { gray, lightBlue } from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const ConatinerInputs = styled.form`
  max-width: 400px;

  div:not(:last-of-type) {
    margin-left: 30px;
  }

  button {
    width: 110px;
  }
`;

export const WrapperInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  div {
    margin-left: 0 !important;
  }

  span {
    color: ${gray};
  }

  p {
    width: 50%;
  }

  & > ${InnPopupStyled} {
    top: 50px;
  }
`;

export const WrapperInputPlatform = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  div {
    display: flex;
    align-items: center;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active !== false && lightBlue};
    color: ${({ active }) => active !== false && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active !== true && lightBlue};
    color: ${({ active }) => active !== true && lightBlue};
  }
`;
