import * as React from 'react';
import { RadioStyled, RadioButtonLabel, RadioChildren } from './styles';

interface Props {
  label?: string;
  name: string;
  value: string;
  // TODO rename
  keyValue?: any;
  checked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  children?: any;
}

export const RadioButton: React.FC<Props> = ({
  label,
  name,
  value,
  keyValue,
  checked,
  onChange,
  children
}: Props) => (
  <React.Fragment>
    <RadioButtonLabel
      className={keyValue === value ? 'checked' : ''}
      label={label}
    >
      <RadioStyled
        name={name}
        value={value}
        aria-labelledby={name}
        checked={keyValue === value}
        onChange={onChange}
      />
    </RadioButtonLabel>

    {!!children &&
      keyValue === value && <RadioChildren>{children}</RadioChildren>}
  </React.Fragment>
);
