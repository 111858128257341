import { GET_COMPANY_FAST_INFO } from '../reducers/getCompanyFastInfo';

export interface ResponseDataType {
  regDate: string;
  endDate: string;
  ogrn: string;
  inn: string;
  kpp: string;
  shortName: string;
  fullName: string;
  address: string;
  chiefPosition: string;
  chief: string;
}

export const req = (inn: string) => ({
  type: GET_COMPANY_FAST_INFO.REQ,
  inn
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMPANY_FAST_INFO.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_FAST_INFO.ERR,
  error
});
