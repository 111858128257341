import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface UploadPropertyDocumentsStoreState extends Reducer {
}

export interface PropertyDocumentsRequestData {
  files: any[];
  propertyId: number;
}

export enum UPLOAD_PROPERTY_DOCUMENTS {
  REQ = 'UPLOAD_PROPERTY_DOCUMENTS_REQ',
  GOT = 'UPLOAD_PROPERTY_DOCUMENTS_GOT',
  ERR = 'UPLOAD_PROPERTY_DOCUMENTS_ERR'
}

export const initialState: UploadPropertyDocumentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const uploadPropertyDocuments = (
  state: UploadPropertyDocumentsStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): UploadPropertyDocumentsStoreState => {
  switch (action.type) {
    case UPLOAD_PROPERTY_DOCUMENTS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPLOAD_PROPERTY_DOCUMENTS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case UPLOAD_PROPERTY_DOCUMENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default uploadPropertyDocuments;
