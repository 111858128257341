import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/EarlyPaymentApplications/actions/getEarlyPaymentByGuid';

export interface GetEarlyPaymentByGuidStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_EARLY_PAYMENT_BY_GUID {
  REQ = 'GET_EARLY_PAYMENT_BY_GUID_REQ',
  GOT = 'GET_EARLY_PAYMENT_BY_GUID_GOT',
  ERR = 'GET_EARLY_PAYMENT_BY_GUID_ERR'
}

export const initialState: GetEarlyPaymentByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getEarlyPaymentByGuid = (
  state: GetEarlyPaymentByGuidStoreState = initialState,
  action: {
    type: GET_EARLY_PAYMENT_BY_GUID;
    data: ResponseData;
    error: {};
  }
): GetEarlyPaymentByGuidStoreState => {
  switch (action.type) {
    case GET_EARLY_PAYMENT_BY_GUID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EARLY_PAYMENT_BY_GUID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EARLY_PAYMENT_BY_GUID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getEarlyPaymentByGuid;
