import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getOpenLoanLimits';

import { GET_OPEN_LOAN_LIMITS_EXTERNAL } from '../reducers/getOpenLoanLimitsExternal';
import PreApprovalApi from '../api';

function* getOpenLoanLimitsExternal(action: {
  type: GET_OPEN_LOAN_LIMITS_EXTERNAL;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(PreApprovalApi.getOpenLoanLimits, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOpenLoanLimitsExternalSaga(): Generator {
  yield takeLatest(
    GET_OPEN_LOAN_LIMITS_EXTERNAL.REQ,
    getOpenLoanLimitsExternal
  );
}
