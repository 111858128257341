import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from 'src/shared/utils/History';
import { STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {}

type Props = StateToProps & DispatchToProps;

const FactoringLimitsBank = ({ permissions }: Props) => {
  return (
    <ApplicationViewStyled>
      <ApplicationTitle>Лимиты факторинг</ApplicationTitle>
      {!permissions.includes(USER_PERMISSIONS.FACTOR) ? (
        <p>
          Для получения доступа к кабинету фактора обратитесь в техническую
          поддержку support@finfactory.one
        </p>
      ) : (
        <Button
          label="Перейти в кабинет фактора"
          onClick={() => history.push('/factor/limits?page=1')}
          w="fit-content"
        />
      )}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FactoringLimitsBankConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringLimitsBank);

export { FactoringLimitsBankConnect as FactoringLimitsBank };
