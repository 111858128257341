import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestDataType } from 'entities/SCF/Supplier/model/actions/SCFRegisterSupplier';

export interface SCFRegisterSupplierStoreState extends Reducer {
  data: RequestDataType;
}

export enum SCF_REGISTER_SUPPLIER {
  SCF_REGISTER_SUPPLIER_REQ = 'SCF_REGISTER_SUPPLIER_REQ',
  SCF_REGISTER_SUPPLIER_GOT = 'SCF_REGISTER_SUPPLIER_GOT',
  SCF_REGISTER_SUPPLIER_ERR = 'SCF_REGISTER_SUPPLIER_ERR',
  SCF_REGISTER_SUPPLIER_RESET = 'SCF_REGISTER_SUPPLIER_RESET'
}

export const initialState: SCFRegisterSupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    legalForm: '',
    code: '',
    companyName: '',
    email: '',
    password: ''
  },
  error: {}
};

const SCFRegisterSupplier = (
  state: SCFRegisterSupplierStoreState = initialState,
  action: {
    type: string;
    data: RequestDataType;
    error: {};
  }
): SCFRegisterSupplierStoreState => {
  switch (action.type) {
    case SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default SCFRegisterSupplier;
