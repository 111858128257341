import * as React from 'react';
import { Route } from 'react-router';
import SubscriptionsInfoSupplier from './components/SubscriptionsInfoSupplier';
import SubscriptionsEditSupplier from './components/SubscriptionsEditSupplier';
import SubscriptionsInfoDebtor from './components/SubscriptionsInfoDebtor';
import SubscriptionsEditDebtor from './components/SubscriptionsEditDebtor';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';

const SubscriptionsPage = () => {
  return (
    <PageNoMarginStyled>
      <Route
        path={'/supplier/settings/subscriptions'}
        exact
        render={() => <SubscriptionsInfoSupplier />}
      />
      <Route
        path={'/supplier/settings/subscriptions/edit'}
        exact
        render={() => <SubscriptionsEditSupplier />}
      />
      <Route
        path={'/debtor/settings/subscriptions'}
        exact
        render={() => <SubscriptionsInfoDebtor />}
      />
      <Route
        path={'/debtor/settings/subscriptions/edit'}
        exact
        render={() => <SubscriptionsEditDebtor />}
      />
    </PageNoMarginStyled>
  );
};

export default SubscriptionsPage;
