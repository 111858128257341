import { CounterpartiesTypes } from 'src/features/Counterparties/types';
import { GET_COUNTERPARTIES } from '../reducers/getCounterparties';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CounterpartiesTypes[];
}

export interface RequestData {
  debtorInnToFilterBy?: string;
  SupplierInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_COUNTERPARTIES.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_COUNTERPARTIES.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COUNTERPARTIES.ERR,
  error
});
