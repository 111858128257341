import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getFactoringConnectionById,
  ResponseDataType as DataFactoringConnectionById
} from 'entities/SCF/Factor/model/actions/getFactoringConnectionById';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Container, INNContainer, INNField, NoData } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  connection: DataFactoringConnectionById;
  statusConnection: REQUEST_STATUSES;
}

interface DispatchToProps {
  getFactoringConnectionById: (guid: string) => void;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ConnectionView: React.FC<Props> = ({
  getFactoringConnectionById,
  connection,
  statusConnection,
  history,
  match
}) => {
  const [supplierCompanyInfo, setSupplierCompanyInfo] = React.useState<
    ResponseDataType
  >(null);
  const [debtorCompanyInfo, setDebtorCompanyInfo] = React.useState<
    ResponseDataType
  >(null);

  React.useEffect(
    () => {
      if (match.params.guid) {
        getFactoringConnectionById(match.params.guid);
      }
    },
    [match.params.guid]
  );

  React.useEffect(
    () => {
      if (statusConnection === REQUEST_STATUSES.GOT) {
        setSupplierCompanyInfo(prevState => ({
          ...prevState,
          inn: connection.supplierInn,
          companyShortName: connection.supplierName
        }));
        setDebtorCompanyInfo(prevState => ({
          ...prevState,
          inn: connection.debtorInn,
          companyShortName: connection.debtorName
        }));
      }
    },
    [statusConnection]
  );

  const navigateToCreateConnections = () => {
    history.push('/factor/connections/add');
  };

  return (
    <Container>
      <ApplicationTitle>Связка {match.params.guid}</ApplicationTitle>

      <INNContainer>
        <div>
          {supplierCompanyInfo && (
            <INNField>
              <p>Поставщик:</p>
              <div>
                <p>ИНН:</p>
                <p>{supplierCompanyInfo.inn}</p>
              </div>
              <div>
                <p>Наименование:</p>
                <p>{supplierCompanyInfo.companyShortName}</p>
              </div>
            </INNField>
          )}
          {debtorCompanyInfo && (
            <INNField>
              <p>Дебитор:</p>
              <div>
                <p>ИНН:</p>
                <p>{debtorCompanyInfo.inn}</p>
              </div>
              <div>
                <p>Наименование:</p>
                <p>{debtorCompanyInfo.companyShortName}</p>
              </div>
            </INNField>
          )}
        </div>
      </INNContainer>

      {statusConnection === REQUEST_STATUSES.ERROR && (
        <NoData>
          <h3>СВЯЗКА НЕ НАЙДЕНА</h3>
          <p>на платформе нет такой связки поставщика и дебитора</p>
          <Button
            label="Создать связку"
            onClick={navigateToCreateConnections}
          />
        </NoData>
      )}
    </Container>
  );
};

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  connection: SCFFactor.getFactoringConnectionsById.data,
  statusConnection: SCFFactor.getFactoringConnectionsById.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFactoringConnectionById }, dispatch);

const ConnectionViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectionView)
);

export { ConnectionViewConnect as ConnectionView };
