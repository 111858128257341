import { SEND_AGENT } from '../reducers/sendAgentData';

export const req = data => ({
  type: SEND_AGENT.REQ,
  data
});

export const reset = () => ({
  type: SEND_AGENT.RESET
});

export const got = () => ({
  type: SEND_AGENT.GOT
});

export const err = (error: {}) => ({
  type: SEND_AGENT.ERR,
  error
});
