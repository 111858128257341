import { PATCH_DETAIL } from '../reducers/patchDetail';

export const req = (id: string | number, data: any) => ({
  type: PATCH_DETAIL.REQ,
  id,
  data
});

export const reset = () => ({
  type: PATCH_DETAIL.RESET
});

export const got = () => ({
  type: PATCH_DETAIL.GOT
});

export const err = (error: {}) => ({
  type: PATCH_DETAIL.ERR,
  error
});
