import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { addDays, format, isAfter, isBefore, parseISO } from 'date-fns';

import { Loader, Pagination } from 'Layouts/components';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  req as getAllDiscountAuctions,
  ResponseData
} from 'src/features/SCF/actions/getAllDiscountAuctionsBySupplier';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { AuctionHeader, Center, Status } from './styled';
import { ScrollTopComponent } from 'src/features/Common';
import { formatNumberWithComma } from 'shared/utils/Utils';

interface StateToProps {
  auctionList: ResponseData;
  statusAuctionList: REQUEST_STATUSES;
}

interface DispatchToProps {
  getAllDiscountAuctions: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const DiscountAuctionsList: React.FC<Props> = ({
  getAllDiscountAuctions,
  auctionList,
  statusAuctionList,
  history
}) => {
  React.useEffect(() => {
    getAllDiscountAuctions();
  }, []);

  const navigateToAuction = (guid: string) => {
    history.push(`/supplier/discount_auctions/${guid}`);
  };

  function statusView(resultsDateTimeStr: string, payDate: string) {
    const currentDate = new Date();
    const resultsDateTime = parseISO(resultsDateTimeStr);
    const payDatePlusOne = payDate ? addDays(new Date(payDate), 1) : null;

    if (isBefore(currentDate, resultsDateTime)) {
      return 'Сбор заявок';
    }

    if (
      isAfter(currentDate, resultsDateTime) &&
      payDatePlusOne &&
      isBefore(currentDate, payDatePlusOne)
    ) {
      return 'Подведение итогов';
    }

    if (payDatePlusOne && isAfter(currentDate, payDatePlusOne)) {
      return 'Аукцион завершен';
    }
  }

  return (
    <ScrollTopComponent>
      <AuctionHeader>
        <h2>Аукционы дисконта</h2>
      </AuctionHeader>

      {statusAuctionList === REQUEST_STATUSES.REQUEST && <Loader />}
      {statusAuctionList === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="7%">ID</TableThStyled>
              <TableThStyled width="10%">Аукцион проводит</TableThStyled>
              <TableThStyled width="10%">Подведение итогов</TableThStyled>
              <TableThStyled width="10%">Дата оплаты</TableThStyled>
              <TableThStyled width="10%">Ставка</TableThStyled>
              <TableThStyled width="12%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {auctionList.items.map((item, key) => (
              <TableRowStyled
                key={key}
                onClick={() => navigateToAuction(item.guid)}
              >
                <td>{item.guid.substring(item.guid.length - 5)}</td>
                <td>
                  <div>{item.debtorInn}</div>
                  <div>{item.debtorName}</div>
                </td>
                <td>
                  <Center w="55%">
                    <div>
                      {new Date(item.resultsDateTime).toLocaleDateString()}
                    </div>
                    <div>{format(new Date(item.resultsDateTime), 'HH:mm')}</div>
                  </Center>
                </td>
                <td>
                  <Center w="35%">
                    {new Date(item.payDate).toLocaleDateString()}
                  </Center>
                </td>
                <td>
                  от {formatNumberWithComma(+item.minDiscountRate)} % годовых
                </td>
                <td>
                  <Status
                    status={statusView(item.resultsDateTime, item.payDate)}
                  >
                    {statusView(item.resultsDateTime, item.payDate)}
                  </Status>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={auctionList} />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  auctionList: SCF.getAllDiscountAuctionsBySupplier.data,
  statusAuctionList: SCF.getAllDiscountAuctionsBySupplier.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllDiscountAuctions
    },
    dispatch
  );

const DiscountAuctionsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DiscountAuctionsList)
);

export { DiscountAuctionsListConnect as DiscountAuctionsList };
