import {
  RequestDataType,
  SAVE_BORROWER_COMPANIES_LIST
} from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';

export const req = (data: RequestDataType) => ({
  type: SAVE_BORROWER_COMPANIES_LIST.REQ,
  data
});

export const got = () => ({
  type: SAVE_BORROWER_COMPANIES_LIST.GOT
});

export const err = (error: {}) => ({
  type: SAVE_BORROWER_COMPANIES_LIST.ERR,
  error
});
