import {
  PREPARE_FOR_APPLICATION_EDIT,
  RequestDataType
} from 'Application/reducers/prepareForApplicationEdit';

export const req = (data: RequestDataType) => ({
  type: PREPARE_FOR_APPLICATION_EDIT.REQ,
  data
});

export const got = () => ({
  type: PREPARE_FOR_APPLICATION_EDIT.GOT
});

export const err = (error: {}) => ({
  type: PREPARE_FOR_APPLICATION_EDIT.ERR,
  error
});

export const reset = () => ({
  type: PREPARE_FOR_APPLICATION_EDIT.RESET
});
