import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFileSettingsCurrent';

export interface GetDebtorRegistryFileSettingsCurrentStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT {
  GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_REQ = 'GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_REQ',
  GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_GOT = 'GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_GOT',
  GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_ERR = 'GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_ERR'
}

export const initialState: GetDebtorRegistryFileSettingsCurrentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    fileResolution: null,
    skipFirstRow: false,
    supplierIdentificationPolicy: null,
    supplierInnPolicy: false,
    supplierInnColumn: null,
    supplyContractIdColumn: null,
    invoiceDocumentNameColumn: null,
    invoiceDocumentDateColumn: null,
    invoiceDocumentNumberColumn: null,
    baseSumPolicy: false,
    baseSumColumn: null,
    monetaryClaimIdColumn: null,
    baseVatSumPolicy: false,
    baseVatSumColumn: null,
    baseFullSumPolicy: false,
    baseFullSumColumn: null,
    currencyIdPolicy: false,
    currencyIdColumn: null,
    currencyCodePolicy: false,
    currencyCodeColumn: null,
    emergenceDateColumn: null,
    maturityDateColumn: null,
    statusPolicy: null,
    monetaryClaimStatusColumn: null,
    monetaryClaimPaidStatus: null,
    monetaryClaimUnpaidStatus: null,
    treatRegistryEntriesPolicy: null,
    treatRegistryExcludedPolicy: null,
    parserStartPolicy: null
  },
  error: {}
};

const getDebtorRegistryFileSettingsCurrent = (
  state: GetDebtorRegistryFileSettingsCurrentStoreState = initialState,
  action: {
    type: GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT;
    data: ResponseDataType;
    error: {};
  }
): GetDebtorRegistryFileSettingsCurrentStoreState => {
  switch (action.type) {
    case GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDebtorRegistryFileSettingsCurrent;
