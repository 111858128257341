import { call, put, takeLatest } from 'redux-saga/effects';
import { updateLocalProperty } from '../actions/localProperties';
import { got, err } from '../actions/updateProperty';
import PropertiesApi from '../api';

import {
  UPDATE_PROPERTY,
} from '../reducers/updateProperty';
import { PropertyRead, PROPERTY_SUB_TYPES, PROPERTY_TYPES } from '../types/Property';

function* updateProperty(action: {
  type: UPDATE_PROPERTY;
  property: Partial<PropertyRead>;
}): Generator {
  try {
    yield call(PropertiesApi.updateProperty, action.property);
    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* updatePropertySaga(): Generator {
  yield takeLatest(UPDATE_PROPERTY.REQ, updateProperty);
}
