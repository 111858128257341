import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchSetForbiddenRegionAll';

import { PATCH_SET_FORBIDDEN_REGION_ALL } from '../reducers/patchSetForbiddenRegionAll';
import CustomerRequirementsApi from '../api';

function* patchSetForbiddenRegionAll(action: {
  type: PATCH_SET_FORBIDDEN_REGION_ALL;
  id: string;
}): Generator {
  try {
    yield call(CustomerRequirementsApi.patchSetForbiddenRegionAll, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchSetForbiddenRegionAllSaga(): Generator {
  yield takeLatest(
    PATCH_SET_FORBIDDEN_REGION_ALL.REQ,
    patchSetForbiddenRegionAll
  );
}
