import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { InnPlaceholders } from 'shared/constants';
import { ScrollTopComponent } from 'src/features/Common';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { Input } from 'shared/ui/Input';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getAgents,
  ResponseData as AgentsData
} from 'src/features/Agents/actions/getAllAgent';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  AgentTypeContainer,
  ErrorTD,
  FilterContainer,
  FilterWrapper,
  InnInputContainer
} from 'src/features/Agents/components/AgentsList/styled';
import { HeadContainer } from 'src/features/Products/components/ProductsList/styled';
import {
  AGENT_TYPE,
  AgentFilter,
  HAS_CONTACT,
  HAS_CONTRACT,
  HAS_USERS
} from 'src/features/Agents/types';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {
  agents: AgentsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getAgents: (filters: AgentFilter) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const AgentList: React.FC<Props> = ({
  getAgents,
  agents,
  status,
  error,
  history
}) => {
  const [companyInn, setCompanyInn] = React.useState('');
  const [isLastLoginAt, setIsLastLoginAt] = React.useState(null);
  const [isLastOurActivity, setIsLastOurActivity] = React.useState(false);
  const [isLastAgentActivity, setIsLastAgentActivity] = React.useState(null);
  const [radioBtnState, setRadioBtnState] = React.useState({
    agentType: 'NOT_CHECK',
    hasContacts: 'NOT_CHECK',
    hasUsers: 'NOT_CHECK',
    hasContract: 'NOT_CHECK'
  });

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const companyInn = searchParams.get('companyInn') || '';
      const agentType = searchParams.get('agentType') || 'NOT_CHECK';
      const hasContacts = searchParams.get('hasContact') || 'NOT_CHECK';
      const hasUsers = searchParams.get('hasUsers') || 'NOT_CHECK';
      const hasContract = searchParams.get('hasContract') || 'NOT_CHECK';

      setCompanyInn(companyInn);
      setRadioBtnState({
        agentType,
        hasContract,
        hasContacts,
        hasUsers
      });

      getAgents({
        page,
        pageSize: 20,
        sortBy: 'lastOurActivityAsc',
        ...(agentType !== 'NOT_CHECK' && { [agentType]: true }),
        ...(companyInn && { agentInn: companyInn }),
        ...(hasContract === 'TRUE'
          ? { hasContract: true }
          : hasContract === 'FALSE' && { hasContract: false }),
        ...(hasUsers === 'TRUE'
          ? { hasUsers: true }
          : hasUsers === 'FALSE' && { hasUsers: false }),
        ...(hasContacts === 'TRUE'
          ? { hasContacts: true }
          : hasContacts === 'FALSE' && { hasContacts: false })
      });
    },
    [history.location.search]
  );

  const handleRadioBtn = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value === 'NOT_CHECK') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    history.push({ search: searchParams.toString() });
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToAgent = (inn: string) => {
    window.open(`/crm/agents/${inn}`, '_blank');
  };

  const navigateToAddAgent = () => {
    history.push('/crm/agents/new');
  };

  const handleLastLoginAtFilter = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;

    setIsLastOurActivity(null);
    setIsLastAgentActivity(null);

    if (isLastLoginAt === null) {
      setIsLastLoginAt(false);
      getAgents({ page, pageSize: 20, sortBy: 'lastLoginAsc' });
    } else if (isLastLoginAt === false) {
      setIsLastLoginAt(true);
      getAgents({ page, pageSize: 20, sortBy: 'lastLoginDesc' });
    } else {
      setIsLastLoginAt(false);
      getAgents({ page, pageSize: 20, sortBy: 'lastLoginAsc' });
    }
  };

  const handleLastOurActivityFilter = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;

    setIsLastLoginAt(null);
    setIsLastAgentActivity(null);

    if (isLastOurActivity === false) {
      setIsLastOurActivity(true);
      getAgents({ page, pageSize: 20, sortBy: 'lastOurActivityDesc' });
    } else {
      setIsLastOurActivity(false);
      getAgents({ page, pageSize: 20, sortBy: 'lastOurActivityAsc' });
    }
  };

  const handleLastAgentActivityFilter = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;

    setIsLastOurActivity(null);
    setIsLastLoginAt(null);

    if (isLastAgentActivity === null) {
      setIsLastAgentActivity(false);
      getAgents({ page, pageSize: 20, sortBy: 'lastActivityAsc' });
    } else if (isLastAgentActivity === false) {
      setIsLastAgentActivity(true);
      getAgents({ page, pageSize: 20, sortBy: 'lastActivityDesc' });
    } else {
      setIsLastAgentActivity(false);
      getAgents({ page, pageSize: 20, sortBy: 'lastActivityAsc' });
    }
  };

  return (
    <ScrollTopComponent>
      <HeadContainer>
        <ApplicationTitle>Все агенты</ApplicationTitle>
        <Button
          label="+ Добавить агента"
          onClick={navigateToAddAgent}
          w="fit-content"
          h="30px"
        />
      </HeadContainer>

      <InnInputContainer>
        <Input
          value={companyInn}
          label="ИНН Компании"
          name="companyInn"
          placeholder={InnPlaceholders.entity}
          onChange={onFilterChange}
        />
      </InnInputContainer>

      <FilterContainer>
        <AgentTypeContainer>
          <RadioGroup
            label="Тип агента:"
            name="agentType"
            keyValue={radioBtnState.agentType}
            onChange={handleRadioBtn}
            radioBtns={Object.keys(AGENT_TYPE).map(value => ({
              value,
              label: AGENT_TYPE[value]
            }))}
          />
        </AgentTypeContainer>

        <RadioGroup
          label="Наличие контактов:"
          name="hasContact"
          keyValue={radioBtnState.hasContacts}
          onChange={handleRadioBtn}
          radioBtns={Object.keys(HAS_CONTACT).map(value => ({
            value,
            label: HAS_CONTACT[value]
          }))}
        />

        <RadioGroup
          label="Наличие пользователей:"
          name="hasUsers"
          keyValue={radioBtnState.hasUsers}
          onChange={handleRadioBtn}
          radioBtns={Object.keys(HAS_USERS).map(value => ({
            value,
            label: HAS_USERS[value]
          }))}
        />

        <RadioGroup
          label="Наличие договора:"
          name="hasContract"
          keyValue={radioBtnState.hasContract}
          onChange={handleRadioBtn}
          radioBtns={Object.keys(HAS_CONTRACT).map(value => ({
            value,
            label: HAS_CONTRACT[value]
          }))}
        />
      </FilterContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ИНН</TableThStyled>
              <TableThStyled width="10%">Наименование</TableThStyled>
              <TableThStyled width="10%">
                Последний вход{' '}
                <FilterWrapper active={isLastLoginAt}>
                  <FontAwesomeIcon
                    icon={
                      isLastLoginAt ? faSortAmountUpAlt : faSortAmountDownAlt
                    }
                    onClick={handleLastLoginAtFilter}
                  />
                </FilterWrapper>
              </TableThStyled>
              <TableThStyled width="15%">
                Наша активность{' '}
                <FilterWrapper active={isLastOurActivity}>
                  <FontAwesomeIcon
                    icon={
                      isLastOurActivity
                        ? faSortAmountUpAlt
                        : faSortAmountDownAlt
                    }
                    onClick={handleLastOurActivityFilter}
                  />
                </FilterWrapper>
              </TableThStyled>
              <TableThStyled width="10%">
                Активность агента{' '}
                <FilterWrapper active={isLastAgentActivity}>
                  <FontAwesomeIcon
                    icon={
                      isLastAgentActivity
                        ? faSortAmountUpAlt
                        : faSortAmountDownAlt
                    }
                    onClick={handleLastAgentActivityFilter}
                  />
                </FilterWrapper>
              </TableThStyled>
              <TableThStyled width="10%">Договор</TableThStyled>
              <TableThStyled width="10%">Пользователи</TableThStyled>
              <TableThStyled width="10%">Контакты</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {agents.items.map(agent => (
              <TableRowStyled
                key={agent.id}
                onClick={() => navigateToAgent(agent.inn)}
              >
                <td>{agent.inn}</td>
                <td>{agent.shortName}</td>
                <td>
                  {agent.lastLoginAt
                    ? new Date(agent.lastLoginAt).toLocaleDateString()
                    : null}
                </td>
                <td>
                  {agent.lastOurActivity
                    ? new Date(agent.lastOurActivity).toLocaleDateString()
                    : null}
                </td>
                <td>
                  {agent.lastAgentActivity
                    ? new Date(agent.lastAgentActivity).toLocaleDateString()
                    : null}
                </td>
                <td>
                  {agent.hasContract ? (
                    'Заключен'
                  ) : (
                    <ErrorTD>Отсутствует</ErrorTD>
                  )}
                </td>
                <td>
                  {agent.hasUsers ? (
                    'Есть пользователи'
                  ) : (
                    <ErrorTD>Нет пользователей</ErrorTD>
                  )}
                </td>
                <td>
                  {agent.hasContacts ? (
                    'Есть контакты'
                  ) : (
                    <ErrorTD>Нет контактов</ErrorTD>
                  )}
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={agents} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Agents }: CRM) => ({
  agents: Agents.getAllAgent.data,
  status: Agents.getAllAgent.status,
  error: Agents.getAllAgent.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getAgents }, dispatch);

const AgentListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AgentList)
);

export { AgentListConnect as AgentList };
