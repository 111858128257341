import { GET_USER_FACTOR_LIST } from '../reducers/getUserFactorList';

interface Factor {
  accessFailedCount: number;
  accountStatus: number;
  allowBlankFields: boolean;
  allowSimpleProcess: boolean;
  companyInn: string;
  createdDateTime: string;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  id: number;
  isBlocked: boolean;
  lastApplicationAt: any;
  lastLoginAt: any;
  lastName: string;
  lockoutEnd: any;
  modifiedDateTime: any;
  permissions: string[];
  phoneNumber: string;
  roles: string[];
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Factor[];
}

export interface RequestData {
  page: number;
  pageSize: number;
}

export const req = (data: RequestData) => ({
  type: GET_USER_FACTOR_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_USER_FACTOR_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_USER_FACTOR_LIST.ERR,
  error
});
