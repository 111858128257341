import { GET_INTERACTION_SUMMARY } from '../reducers/getInteractionSummary';

export interface ResponseData {
  activitiesQty: number;
  contactsQty: number;
  lastActivityType: number;
  lastContact: string;
  lastActivity: string;
}

export const req = (inn: string[]) => ({
  type: GET_INTERACTION_SUMMARY.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_INTERACTION_SUMMARY.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INTERACTION_SUMMARY.ERR,
  error
});

export const reset = () => ({
  type: GET_INTERACTION_SUMMARY.RESET
});
