import { combineReducers } from 'redux';
import getBeneficiaries, {
  GetBeneficiariesStoreState
} from './getBeneficiaries';
import getSubsidiaries, { GetSubsidiariesStoreState } from './getSubsidiaries';
import getManagersAndSignatories, {
  GetManagersAndSignatoriesStoreState
} from './getManagersAndSignatories';

export interface BeneficiariesReducers {
  getBeneficiaries: GetBeneficiariesStoreState;
  getSubsidiaries: GetSubsidiariesStoreState;
  getManagersAndSignatories: GetManagersAndSignatoriesStoreState;
}

export const Beneficiaries = combineReducers({
  getBeneficiaries,
  getSubsidiaries,
  getManagersAndSignatories
});
