import styled from 'styled-components';

import { ApplicationStatus } from 'Application/components';
import { rr, rm, gray, navyBlue } from 'shared/styled';

// NOTE duplication with EachApplicationView/DataBlcok/Styles.ts
export const PropertyInformationStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    font-family: ${rr};
    font-size: 1.4rem;
    color: ${gray};

    span {
      color: ${navyBlue};
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;
