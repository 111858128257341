import getPersonsSaga from './getPersons';
import getManagersSaga from './getManagers';
import getPersonSaga from './getPerson';
import getPersonsListSaga from './getPersonsList';
import patchEditPersonSaga from './patchEditPerson';

export default [
  getPersonsSaga,
  getManagersSaga,
  getPersonSaga,
  getPersonsListSaga,
  patchEditPersonSaga
];
