import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/refreshApiKey';

import {
  REFRESH_API_KEY,
} from '../reducers/refreshApiKey';
import ApiKeysApi from '../api';

function* refreshApiKey(action: {
  type: REFRESH_API_KEY;
}): Generator {
  try {

    const res:any = yield call(ApiKeysApi.refresh);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* refreshApiKeySaga(): Generator {
  yield takeLatest(REFRESH_API_KEY.REQ, refreshApiKey);
}
