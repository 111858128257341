import { GET_APPLICATIONS_LIST } from 'Application/reducers/getApplicationsList';
import { ApplicationRead, APPLICATION_STATUSES } from 'Application/types';

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: ApplicationRead[];
}

export interface RequestDataType {
  page: number;
  pageSize: number;
  statusesToFilterBy?: string[];
  typesToFilterBy?: string[];
  internalAgentsToFilterBy?: number[];
  innToFilterBy?: string;
  companyInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_APPLICATIONS_LIST.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_APPLICATIONS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATIONS_LIST.ERR,
  error
});
