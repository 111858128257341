import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getPersonsList';

import { GET_PERSONS_LIST } from '../reducers/getPersonsList';
import PersonsApi from '../api';

function* getPersonsList(action: {
  type: GET_PERSONS_LIST;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(PersonsApi.getPersonsList, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPersonsListSaga(): Generator {
  yield takeLatest(GET_PERSONS_LIST.REQ, getPersonsList);
}
