import styled from 'styled-components';
import { rr } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';
import { InputStyled } from 'src/shared/ui/Input/styles';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const WrapperInputDetail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  max-width: 500px;

  & > *:first-child {
    width: 40%;
  }

  span {
    font-size: 12px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
  }

  & > ${InnPopupStyled} {
    top: 50px;
    left: 100px;
  }
`;

export const ContainerInputs = styled.form`
  display: flex;
  align-items: flex-start;
  column-gap: 100px;
  margin: 40px 0 0 20px;
`;

export const InputDetail = styled(InputStyled)`
  max-width: 200px;
`;

export const ButtonSaveDetail = styled(ButtonStyled)`
  max-width: 100px;
  height: auto;
  justify-content: center;
  margin-top: 20px;

  :disabled {
    background-color: #9fa6b2;
  }
`;

export const ErrorMsg = styled.p`
  color: red;
  font-weight: 600;
  margin: 0;
`;

export const WrapperInput = styled.div`
  max-width: 40%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
