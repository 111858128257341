import { ContactsOfProductsData } from '../actions/setContactsOfProductsFormData';

export interface SetContactsOfProductsDataStoreState extends Partial<ContactsOfProductsData> {}

export enum SET_CONTACTS_OF_PRODUCTS_DATA {
  SET = 'SET_CONTACTS_OF_PRODUCTS_DATA_SET',
  RESET = 'SET_CONTACTS_OF_PRODUCTS_DATA_RESET',
}

export const initialState: SetContactsOfProductsDataStoreState = {
  //
};

const setContactsOfProductsFormData = (
  state: SetContactsOfProductsDataStoreState = initialState,
  action: {
    type: string;
    data: ContactsOfProductsData;
  }
): SetContactsOfProductsDataStoreState => {
  switch (action.type) {
    case SET_CONTACTS_OF_PRODUCTS_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_CONTACTS_OF_PRODUCTS_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setContactsOfProductsFormData;
