import { GET_FACTORING_LIMITS_LIST } from '../reducers/getFactoringLimitsList';
import { FactoringLimitRead } from './setFactoringLimitFormData';
import { FACTORING_LIMIT_LIST_TYPE } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: FactoringLimitRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  debtorInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_FACTORING_LIMITS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_FACTORING_LIMITS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_LIMITS_LIST.ERR,
  error
});
