import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';

import { RequestDataType as CreateBorrowerCompanyRequestDataType } from 'BorrowerCompanies/reducers/createBorrowerCompany';
import { RequestDataType as GetBorrowerCompaniesListRequestDataType } from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';
import { RequestDataType as DeleteBorrowerCompanyRequestDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompany';
import { RequestDataType as SaveBorrowerCompaniesListRequestDataType } from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';
import { RequestDataType as UploadBorrowerCompanyDocumentsRequestDataType } from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';
import { RequestDataType as DeleteBorrowerCompanyDocumentRequestDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';

export default class BorrowerCompaniesApi {
  static createBorrowerCompany(data: CreateBorrowerCompanyRequestDataType) {
    return ApiRequest(
      `/api/borrowerCompanies/${data.applicationId}`,
      'post',
      data
    );
  }

  static getBorrowerCompaniesList(
    data: GetBorrowerCompaniesListRequestDataType
  ) {
    return ApiRequest(`/api/borrowerCompanies/${data.applicationId}`);
  }

  static deleteBorrowerCompany(data: DeleteBorrowerCompanyRequestDataType) {
    return ApiRequest(`/api/borrowerCompanies/${data.id}`, 'delete');
  }

  static saveBorrowerCompaniesList(
    data: SaveBorrowerCompaniesListRequestDataType
  ) {
    return ApiRequest(`/api/borrowerCompanies`, 'post', data);
  }

  static uploadBorrowerCompanyDocuments(
    data: UploadBorrowerCompanyDocumentsRequestDataType
  ) {
    return ApiUploadFiles(
      `/api/documents/borrowerCompany/${data.id}?metaInfo=${data.metaInfo}`,
      data.file
    );
  }

  static deleteBorrowerCompanyDocument(
    data: DeleteBorrowerCompanyDocumentRequestDataType
  ) {
    return ApiRequest(`/api/documents/borrowerCompany/${data.id}`, 'delete');
  }

  static getDocumentListByApplicationId(guid: string) {
    return ApiRequest(`/api/document_list/internal_role/application/${guid}`);
  }

  static postDocumentListByApplicationId(guid: string) {
    return ApiRequest(
      `/api/document_list/internal_role/application/${guid}`,
      'post'
    );
  }

  static postUploadFinDocInternal(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/financial_document/internal_role/${guid}/upload_file`,
      data
    );
  }

  static postUploadNonFinDocInternal(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/nonfinancial_document/internal_role/${guid}/upload_file`,
      data
    );
  }
}
