import { combineReducers } from 'redux';
import setProductFormData, {
  SetProductDataStoreState
} from './setProductFormData';
import getProduct, { GetProductStoreState } from './getProduct';
import getProductsList, { GetProductsListStoreState } from './getProductsList';
import getProductsByBankList, {
  GetProductsByBankListStoreState
} from './getProductsByBankList';
import sendProductData, { SendProductStoreState } from './sendProductData';
import postCalculateRating, {
  PostCalculateRatingStoreState
} from './postCalculateRating';

export interface ProductsReducers {
  setProductFormData: SetProductDataStoreState;
  getProduct: GetProductStoreState;
  getProductsList: GetProductsListStoreState;
  getProductsByBankList: GetProductsByBankListStoreState;
  sendProductData: SendProductStoreState;
  postCalculateRating: PostCalculateRatingStoreState;
}

export const Products = combineReducers({
  setProductFormData,
  getProduct,
  getProductsList,
  getProductsByBankList,
  sendProductData,
  postCalculateRating
});
