import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { SubsidiariesTypes } from '../types';

export interface GetSubsidiariesStoreState extends Reducer {
  data: SubsidiariesTypes[];
}

export enum GET_SUBSIDIARIES {
  REQ = 'GET_SUBSIDIARIES_REQ',
  GOT = 'GET_SUBSIDIARIES_GOT',
  ERR = 'GET_SUBSIDIARIES_ERR'
}

export const initialState: GetSubsidiariesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getSubsidiaries = (
  state: GetSubsidiariesStoreState = initialState,
  action: {
    type: string;
    data: SubsidiariesTypes[];
    error: {};
  }
): GetSubsidiariesStoreState => {
  switch (action.type) {
    case GET_SUBSIDIARIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUBSIDIARIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUBSIDIARIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSubsidiaries;
