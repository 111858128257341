import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { HoldingRead } from '../../actions/setHoldingFormData';
import { req as getHolding } from '../../actions/getHolding';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  HoldingsData,
  setHoldingFormData
} from '../../actions/setHoldingFormData';
import { CompaniesListForHoldingPage } from '../../../Companies/components/CompaniesList/CompaniesListForHoldingPage';

interface StateToProps {
  holding: HoldingRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  getHolding: (id: number | string) => void;
  setHoldingFormData: (data: HoldingsData) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class HoldingView extends React.Component<Props> {
  componentDidMount() {
    this.props.getHolding(this.props.match.params.id);
  }

  /* TODO extract ApplicationViewStyled and others */
  render() {
    const { holding, status, error } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ApplicationId>id {holding.id}</ApplicationId>
            <ApplicationTitle>{holding.holdingName}</ApplicationTitle>

            <p>Компании холдинга</p>
            {<CompaniesListForHoldingPage holdingId={holding.id} />}
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Holdings, User }: CRM) => ({
  holding: Holdings.getHolding.holding,
  status: Holdings.getHolding.status,
  error: Holdings.getHolding.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getHolding,
      setHoldingFormData
    },
    dispatch
  );

const HoldingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(HoldingView)
);

export { HoldingViewConnect as HoldingView };
