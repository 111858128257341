import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { EachPage } from './EachPage';
import { UserCabinetPageStyled } from 'pages/Cabinet/styled';

interface StateToProps {
  roles: string[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

type Props = RouteComponentProps & StateToProps;

class CabinetPage extends React.Component<Props> {
  renderCabinet = () => {
    if (!!this.props.roles) {
      if (this.props.roles.includes('InternalAgent')) {
        return (
          <UserCabinetPageStyled>
            <EachPage />
          </UserCabinetPageStyled>
        );
      } else {
        this.props.history.goBack();
      }
    }
  };

  render() {
    const { status, error } = this.props;

    return (
      <React.Fragment>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && this.renderCabinet()}
        {status === REQUEST_STATUSES.ERROR &&
          error.code === 401 && (
            <ActionResponseBlock
              showIn={status === REQUEST_STATUSES.ERROR}
              template={ACTION_RESPONSE_TEMPLATE.SESSION_ERROR}
            />
          )}

        <ActionResponseBlock
          showIn={status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  roles: User.getUserData.data.roles,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const CabinetPageConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(CabinetPage)
);

export { CabinetPageConnect as InternalCabinetPage };
