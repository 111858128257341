import ApiRequest from 'src/shared/utils/ApiRequest';
import { LeadsData } from '../actions/setLeadFormData';
import { RequestData as ChangeLeadStatusData } from '../actions/changeLeadStatus';
import { RequestData as GetLeadsListRequestData } from '../actions/getLeadsList';
import { RequestData as ReqPutChecklist } from '../actions/putChecklist';

export default class LeadsApi {
  static sendLeadData(data: LeadsData) {
    return ApiRequest('/api/lead', 'post', data);
  }

  static getLeadogenerators() {
    return ApiRequest('/api/account/get/leadogenerators');
  }

  static getLead(id: number) {
    return ApiRequest(`/api/lead/${id}`);
  }

  static getActivitiesByLeadInternal(leadId: string) {
    return ApiRequest(`/api/activity/all?lead=${leadId}`);
  }

  static getActivitiesByLeadExternal(leadId: string) {
    return ApiRequest(`/api/agent/activities?lead=${leadId}`);
  }

  static changeLeadStatus(data: ChangeLeadStatusData) {
    var requestString = `/api/lead/${data.leadId}/statuses/${data.status}`;
    if (data.rejectReason)
      requestString += `?rejectReason=${data.rejectReason}`; // TODO just do post instead of put
    return ApiRequest(requestString, 'put');
  }

  static getLeadList(data: GetLeadsListRequestData) {
    return ApiRequest('/api/lead/all', 'get', data);
  }

  static getMyLeads(data: GetLeadsListRequestData) {
    return ApiRequest('/api/lead/my', 'get', data);
  }

  // TODO rename
  static getNewLeads(data: GetLeadsListRequestData) {
    return ApiRequest('/api/lead/new', 'get', data);
  }

  static getTrashedLeads(data: GetLeadsListRequestData) {
    return ApiRequest('/api/lead/trashed', 'get', data);
  }

  static getProductsOfApplicationLead(id: string) {
    return ApiRequest(`/api/products_of_applications/lead/${id}`);
  }

  static postSelectProductLead(data: any) {
    return ApiRequest(`/api/product/select`, 'post', data);
  }

  static postExternalApiAlfaBank(LeadID: number) {
    return ApiRequest(`/api/external-api/1-alfa-bank-mmb`, 'post', {
      LeadID
    });
  }

  static postExternalApiPSB(LeadID: number) {
    return ApiRequest(`/api/external-api/2-psb-loans`, 'post', {
      LeadID
    });
  }

  static getLeadDocumentList(id: string) {
    return ApiRequest(`/api/document_list/internal_role/lead/${id}`);
  }

  static postCreateForLead(id: string) {
    return ApiRequest(`/api/document_list/internal_role/lead/${id}`, 'post');
  }

  static putSendToClient(guid: string, leadContactId: number) {
    return ApiRequest(`/api/document_list/${guid}/send_to_client`, 'put', {
      leadContactId
    });
  }

  static postSendCompanyToScoring(inn: string) {
    return ApiRequest(
      `/api/loan_limit/open-user/send_to_scoring/${inn}`,
      'post'
    );
  }

  static putChecklist(id: string, data: ReqPutChecklist) {
    return ApiRequest(`/api/lead/internal_role/${id}/checklist`, 'put', data);
  }
}
