import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postDocumentListByApplicationId';

import { POST_DOCUMENT_LIST_BY_APPLICATION_ID } from '../reducers/postDocumentListByApplicationId';
import DocumentListApi from '../api';

function* postDocumentListByApplicationId(action: {
  type: POST_DOCUMENT_LIST_BY_APPLICATION_ID;
  guid: string;
}): Generator {
  try {
    yield call(DocumentListApi.postDocumentListByApplicationId, action.guid);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDocumentListByApplicationIdSaga(): Generator {
  yield takeLatest(
    POST_DOCUMENT_LIST_BY_APPLICATION_ID.REQ,
    postDocumentListByApplicationId
  );
}
