import styled from 'styled-components';
import { blue, green, rb, red, rl } from 'shared/styled';

export const Title = styled.h2`
  font-family: ${rl};
  margin-bottom: 50px;
`;

export const BlockSpace = styled.div`
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  align-items: center;
  column-gap: 25px;
`;

export const Result = styled.div`
  margin-top: 40px;

  p {
    margin: 0;
  }

  svg {
    color: ${({ result }) => (result ? green : red)};
    font-size: 30px;
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0 0 20px;

    span {
      cursor: pointer;
      color: ${blue};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const SignatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 30px 0 0 20px;

  & > p {
    font-family: ${rb};
    font-size: 16px;
    margin-bottom: 10px;
  }

  & > div {
    display: grid;
    grid-template-columns: 120px auto;
    align-items: center;
    column-gap: 15px;
  }
`;
