import { ContactsOfProductsData } from '../actions/setContactsOfProductsFormData';
//import { RequestData as GetFactoringLimitsListRequestData } from '../actions/getFactoringLimitsList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ContactsOfProductsApi {
  static sendContactsOfProductsData(data: ContactsOfProductsData) {
    return ApiRequest('/api/contacts_of_products', 'post', data);
  }

  static getContactsOfProductsLink(id: number) {
    return ApiRequest(`/api/contacts_of_products/id/${id}`);
  }

  static getContactsListByProduct(productId: number) {
    return ApiRequest(`/api/contacts_of_products/product/${productId}`);
  }

  static getProductsListByContact(contactId: number) {
    return ApiRequest(`/api/contacts_of_products/contact/${contactId}`);
  }

  static postContactByProduct(productId: number, contactId: number) {
    return ApiRequest(
      `/api/contacts_of_products/product/${productId}/contact/${contactId}`,
      'post'
    );
  }
}
