import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'src/features/SCF/actions/getQtySidebar';
import { GET_INTERNAL_COUNTER_SIDEBAR } from 'src/features/SCF/reducers/getInternalCounterSidebar';

import SCFApi from '../api';

function* getQtySidebar(action: { type: GET_INTERNAL_COUNTER_SIDEBAR }) {
  try {
    const res = yield call(SCFApi.getQtySidebar);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getQtySidebarSaga() {
  yield takeLatest(GET_INTERNAL_COUNTER_SIDEBAR.REQ, getQtySidebar);
}
