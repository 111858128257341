import { SET_DISCOUNTING_SETTINGS } from '../reducers/setDiscountingSettings';

export interface DiscountingSettingsWrite {
  enableDiscounting: boolean;
  enableFactroing: boolean;
  discountLimitPolicy: number;
  manualMaxLimitSize: number;
  autoLimitPolicy: number;
  fixedSubstractionSum: number;
  substractionInPeriodDays: number;
  discountRatePolicy: number;
  discountRate: number;
  autoRateSubstraction: number;
  factoringOfferPolicy: number;
  minimalDiscount: number;
  payWeekdays: string;
  paydayOffset: number;
  holidayPaydays: boolean;
  suppliesDataSourcePolicy: number;
  verificationPolicy: number;
  epaMcPolicy: number;
  factoringFormatPolicy: number;
  factoringNotificationPolicy: number;
  rateIsSurplusToKeyRate: boolean;
  protocolsPolicy: boolean;
}

export interface DiscountingSettingsRead extends DiscountingSettingsWrite {
  enableDiscounting: boolean;
  enableFactroing: boolean;
  discountLimitPolicy: number;
  manualMaxLimitSize: number;
  autoLimitPolicy: number;
  fixedSubstractionSum: number;
  substractionInPeriodDays: number;
  discountRatePolicy: number;
  discountRate: number;
  autoRateSubstraction: number;
  factoringOfferPolicy: number;
  minimalDiscount: number;
  payWeekdays: string;
  holidayPaydays: boolean;
  suppliesDataSourcePolicy: number;
  verificationPolicy: number;
}

export interface DiscountingSettings
  extends Partial<DiscountingSettingsWrite> {}

export const setDiscountingSettings = (data: DiscountingSettings) => ({
  type: SET_DISCOUNTING_SETTINGS.SET,
  data
});

export const resetDiscountingSettings = () => ({
  type: SET_DISCOUNTING_SETTINGS.RESET
});
