import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import {
  req as getPlatforms,
  ResponseData as PlatformsData
} from '../actions/getPlatforms';
import {
  req as getExternalCredential,
  ResponseData as ExternalCredentialData
} from '../actions/getExternalCredential';
import { req as postExternalCredential } from '../actions/postExternalCredential';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  BtnAddPlatform,
  PopupContainerPlatform,
  PopupContentPlatform
} from 'src/features/PlatformsForProducts/components/styled';

interface MatchParams {
  id: string;
  tab: string;
}

interface AnyProps {
  productId: number;
}

interface State {
  popupIsVisible: boolean;
}

interface StateToProps {
  platforms: PlatformsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  externalCredential: ExternalCredentialData;
  statusExternalCredential: REQUEST_STATUSES;
  errorExternalCredential: ResponseError;
  statusCreateExternalCredential: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPlatforms: (id: number) => void;
  getExternalCredential: () => void;
  postExternalCredential: ({ externalCredentialsId, productId }) => void;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class PlatformsForProducts extends React.Component<Props, State> {
  state: State = {
    popupIsVisible: false
  };

  popupModalRef = React.createRef();

  componentDidMount() {
    this.props.getPlatforms(this.props.productId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.statusCreateExternalCredential !==
      this.props.statusCreateExternalCredential
    ) {
      this.props.getPlatforms(this.props.productId);
    }
  }

  openModalPlatform = e => {
    e.stopPropagation();

    this.setState({ popupIsVisible: true });

    this.props.getExternalCredential();
  };

  handleAddPlatform = (externalCredentialsId: number) => {
    this.props.postExternalCredential({
      externalCredentialsId,
      productId: this.props.match.params.id
    });
    this.setState({ popupIsVisible: false });
  };

  handleOutsideClickPlatform = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.popupModalRef.current &&
      !(this.popupModalRef.current as Element).contains(target)
    ) {
      this.setState({ popupIsVisible: false });
    }
  };

  render() {
    const {
      platforms,
      status,
      error,
      externalCredential,
      statusExternalCredential
    } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">Платформа</TableThStyled>
                  <TableThStyled width="10%">Ссылка</TableThStyled>
                  <TableThStyled width="10%">Компания</TableThStyled>
                  <TableThStyled width="15%">Логин</TableThStyled>
                  <TableThStyled width="10%">Пароль</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {platforms.items.map(platform => (
                  <TableRowStyled key={platform.id}>
                    <td>{platform.platformName}</td>
                    <td>{platform.website}</td>
                    <td>{platform.ourCompany}</td>
                    <td>{platform.login}</td>
                    <td>{platform.password}</td>
                  </TableRowStyled>
                ))}
              </tbody>
              <BtnAddPlatform onClick={this.openModalPlatform}>
                + Добавить
              </BtnAddPlatform>
              <PopupContainerPlatform
                visible={this.state.popupIsVisible}
                onClick={this.handleOutsideClickPlatform}
              >
                <PopupContentPlatform ref={this.popupModalRef}>
                  <h2>Выберите площадку для добавления к продукту</h2>
                  {statusExternalCredential === REQUEST_STATUSES.REQUEST && (
                    <Loader />
                  )}
                  {statusExternalCredential === REQUEST_STATUSES.GOT && (
                    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                      <TableHeaderStyled>
                        <tr>
                          <TableThStyled width="5%">Платформа</TableThStyled>
                          <TableThStyled width="10%">Ссылка</TableThStyled>
                          <TableThStyled width="10%">
                            Наша компания
                          </TableThStyled>
                          <TableThStyled width="15%">Логин</TableThStyled>
                          <TableThStyled width="10%">Пароль</TableThStyled>
                        </tr>
                      </TableHeaderStyled>
                      <tbody>
                        {externalCredential.items.map(platform => (
                          <TableRowStyled
                            key={platform.id}
                            onClick={() => this.handleAddPlatform(platform.id)}
                          >
                            <td>{platform.platformName}</td>
                            <td>{platform.website}</td>
                            <td>{platform.ourCompany}</td>
                            <td>{platform.login}</td>
                            <td>{platform.password}</td>
                          </TableRowStyled>
                        ))}
                      </tbody>
                    </TableStyled>
                  )}
                </PopupContentPlatform>
              </PopupContainerPlatform>
            </TableStyled>
          </>
        )}
        <Pagination list={platforms} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Platforms }: CRM) => ({
  platforms: Platforms.getPlatforms.data,
  status: Platforms.getPlatforms.status,
  error: Platforms.getPlatforms.error,
  externalCredential: Platforms.getExternalCredential.data,
  statusExternalCredential: Platforms.getExternalCredential.status,
  errorExternalCredential: Platforms.getExternalCredential.error,
  statusCreateExternalCredential: Platforms.postExternalCredential.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getPlatforms, getExternalCredential, postExternalCredential },
    dispatch
  );

const PlatformsForProductsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PlatformsForProducts)
);

export { PlatformsForProductsConnect as PlatformsForProducts };
