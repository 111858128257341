import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getEarlyPaymentApplicationsFactorRole';
import { GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getEarlyPaymentApplicationsFactorRole';
import SCFApi from 'entities/SCF/Factor/api';

function* getEarlyPaymentApplicationsFactorRole(action: {
  type: GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getEarlyPaymentApplicationsFactorRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getEarlyPaymentApplicationsFactorRoleSaga() {
  yield takeLatest(
    GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_REQ,
    getEarlyPaymentApplicationsFactorRole
  );
}
