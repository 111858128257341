import { GET_ACTIVITIES } from 'src/features/Activities/reducers/getActivities';
import { Activities } from 'src/features/Activities/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Activities[];
}

export interface RequestData {
  page: number;
  pageSize: number;
}

export const req = (data: RequestData) => ({
  type: GET_ACTIVITIES.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_ACTIVITIES.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_ACTIVITIES.ERR,
  error
});
