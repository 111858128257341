import {
  GET_APPLICATIONS_QUANTITY,
  ResponseData
} from 'Application/reducers/getApplicationsQuantity';

export const req = () => ({
  type: GET_APPLICATIONS_QUANTITY.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_APPLICATIONS_QUANTITY.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATIONS_QUANTITY.ERR,
  error
});
