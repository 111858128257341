import * as React from 'react';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { Input } from '../Input';
import { Label } from '../Label';
import { DatePickerWrapper, DatePickerWrapperStyles } from './styled';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  dateValue: any;
  onDateChange: (date: Date | null) => void;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  disabledDays?: any;
  error?: string;
  name?: string;
  label?: string;
  required?: boolean;
  highlightDates?: Date[];
  lastDate?: Date;
}

export const InputTime: React.FC<Props> = ({
  dateValue,
  onDateChange,
  error,
  name,
  label,
  required
}) => {
  return (
    <DatePickerWrapper label={!!label} error={error}>
      <Label name={name} label={label} required={required} />
      <DatePicker
        locale={ru}
        selected={dateValue ? new Date(dateValue) : null}
        onChange={onDateChange}
        showTimeSelect
        showTimeSelectOnly
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        timeCaption={false}
        timeIntervals={30}
        timeClassName={() => 'time'}
        customInput={<Input value="" name={name} error={error} />}
      />
      <DatePickerWrapperStyles />
    </DatePickerWrapper>
  );
};
