import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getProperties } from 'Properties/actions/getProperties';
import { req as addProperty } from 'Properties/actions/addProperty';
import { PropertyRead } from '../../types/Property';
import { EditProperty } from './EditProperty/EditProperty';
import PreviewProperty from './PreviewProperty/PreviewProperty';
import { resetLocalProperties } from 'Properties/actions/localProperties';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  // TODO refactor Partial
  properties: Partial<PropertyRead>[];
  gotProperties: boolean;
  addPropertyFinished: boolean;
  updatePropertyFinished: boolean;
  addPropertyStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getProperties: (applicationId: number) => void;
  addProperty: (property: Partial<PropertyRead>) => void;
  resetLocalProperties: () => void;
}

interface State {
  editingPropertyId: number;
}

interface OwnProps {
  applicationId: number;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class EditProperties extends React.Component<Props, State> {
  state = {
    editingPropertyId: null
  };

  componentDidMount() {
    this.props.resetLocalProperties();
    this.props.getProperties(this.props.applicationId);
  }

  componentWillUpdate(prevProps: Props) {
    if (prevProps.properties.length !== this.props.properties.length)
      return true;
    return false;
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.addPropertyStatus !== REQUEST_STATUSES.REQUEST &&
      this.props.gotProperties &&
      prevProps.properties.length == 0 &&
      this.props.properties.length == 0
    )
      this.props.addProperty({ applicationId: this.props.applicationId });
    if (
      this.props.gotProperties &&
      prevProps.properties.length < this.props.properties.length
    ) {
      this.setState({
        editingPropertyId: this.props.properties[prevProps.properties.length].id
      });
      this.props.setIsEditing(true);
    }
    /* if (this.props.gotProperties && prevProps.properties.length > this.props.properties.length) {
* } */
  }

  addProperty = () => {
    this.props.addProperty({ applicationId: this.props.applicationId });
  };

  render() {
    const { properties } = this.props;
    const { editingPropertyId } = this.state;
    return (
      <>
        {properties.map(
          (property, key) =>
            editingPropertyId === property.id ? (
              <EditProperty
                key={property.id}
                property={property}
                onDelete={() => {
                  this.setState({ editingPropertyId: null });
                  this.props.setIsEditing(false);
                }}
                onSave={() => {
                  this.setState({ editingPropertyId: null });
                  this.props.setIsEditing(false);
                }}
              />
            ) : (
              <PreviewProperty
                key={property.id}
                onClick={() => {
                  this.setState({ editingPropertyId: property.id });
                  this.props.setIsEditing(true);
                }}
                property={property}
              />
            )
        )}

        {properties.length < 3 && (
          <Button onClick={this.addProperty} label="Добавить еще один объект" />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ Properties }: STORE) => ({
  properties: Properties.localProperties.properties,
  gotProperties: Properties.getProperties.status === REQUEST_STATUSES.GOT,
  addPropertyFinished: Properties.addProperty.status === REQUEST_STATUSES.GOT,
  addPropertyStatus: Properties.addProperty.status,
  updatePropertyFinished:
    Properties.updateProperty.status === REQUEST_STATUSES.GOT
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProperties,
      addProperty,
      resetLocalProperties
    },
    dispatch
  );

const EditPropertiesConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(EditProperties);

export { EditPropertiesConnect as EditProperties };
