import { GET_CONTACTS_LIST_BY_PRODUCT } from '../reducers/getContactsListByProduct';
import { ContactsOfProductsRead } from './setContactsOfProductsFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ContactsOfProductsRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  productId: number;
}

export const req = (data: RequestData) => ({
  type: GET_CONTACTS_LIST_BY_PRODUCT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_CONTACTS_LIST_BY_PRODUCT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CONTACTS_LIST_BY_PRODUCT.ERR,
  error
});
