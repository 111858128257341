import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getListInternalAgents';

export interface GetListInternalAgentsStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_LIST_INTERNAL_AGENTS {
  REQ = 'GET_LIST_INTERNAL_AGENTS_REQ',
  GOT = 'GET_LIST_INTERNAL_AGENTS_GOT',
  ERR = 'GET_LIST_INTERNAL_AGENTS_ERR'
}

export const initialState: GetListInternalAgentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getListInternalAgentsStoreState = (
  state: GetListInternalAgentsStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetListInternalAgentsStoreState => {
  switch (action.type) {
    case GET_LIST_INTERNAL_AGENTS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LIST_INTERNAL_AGENTS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_LIST_INTERNAL_AGENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getListInternalAgentsStoreState;
