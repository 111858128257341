import styled from 'styled-components';
import { darkestBlue, gray, blue, rr } from 'shared/styled';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 10px;
  background-color: #fff;

  transition: box-shadow 0.2s;

  span:first-child {
    margin-bottom: 8px;
    font-family: ${rr};
    font-size: 1.4rem;
    color: rgba(159, 166, 178, 1);
  }

  span:last-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;
    font-family: ${rr};
    font-size: 1.6rem;
    color: rgba(63, 78, 101, 1);
  }

  &:hover {
    background: repeating-linear-gradient(
      -45deg,
      #f1f8ff -5%,
      #fff 25%,
      #f1f8fe 0%
    );

    @supports not (background: repeating-linear-gradient) {
      background-color: #fff;
    }
  }
`;

export const CardBank = styled(Card)``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 60px;

  p {
    margin: 0;
  }
`;

export const ProductRating = styled(HeaderContainer)`
  column-gap: 20px;

  p {
    color: #06243c;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ProductRatingNameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 5px;

  svg {
    cursor: pointer;
  }
`;

export const ProductRatingName = styled.p`
  color: #a9aab3;
  font-size: 16px;
`;

export const PopupContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const PopupContent = styled.div`
  max-width: 500px;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
