import {
  RequestDataType,
  POST_FACTORING_CONNECTION_SUPPLIER_ROLE
} from 'entities/SCF/Supplier/model/reducers/postFactoringConnectionSupplierRole';

export const req = (data: RequestDataType) => ({
  type: POST_FACTORING_CONNECTION_SUPPLIER_ROLE.REQ,
  data
});

export const got = () => ({
  type: POST_FACTORING_CONNECTION_SUPPLIER_ROLE.GOT
});

export const err = (error: {}) => ({
  type: POST_FACTORING_CONNECTION_SUPPLIER_ROLE.ERR,
  error
});
