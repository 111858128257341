import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchBasisParams';

import { PATCH_BASIS_PARAMS_ACTIONS } from '../reducers/patchBasisParams';
import BasisParamsApi from '../api';

function* patchBasisParams(action: {
  type: PATCH_BASIS_PARAMS_ACTIONS;
  id: string;
  data: any;
}): Generator {
  try {
    yield call(BasisParamsApi.patchBasisParams, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchBasisParamsSaga(): Generator {
  yield takeLatest(PATCH_BASIS_PARAMS_ACTIONS.REQ, patchBasisParams);
}
