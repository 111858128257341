import { GET_CONTACTS_LIST } from '../reducers/getContactsList';
import { ContactRead } from './setContactFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ContactRead[];
}

export const req = (inn: string) => ({
  type: GET_CONTACTS_LIST.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_CONTACTS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CONTACTS_LIST.ERR,
  error
});
