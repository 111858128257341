import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/isDebtorFactorable';
import FactoringCheckerApi from '../api';

import {
  IS_DEBTOR_FACTORABLE,
} from '../reducers/isDebtorFactorable';

function* isDebtorFactorable(action: {
  type: IS_DEBTOR_FACTORABLE;
  debtorINN: string,
  clientINN: string
}): Generator {
  try {

    const res: any = yield call(FactoringCheckerApi.isDebtorFactorable, action.debtorINN, action.clientINN);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* isDebtorFactorableSaga(): Generator {
  yield takeLatest(IS_DEBTOR_FACTORABLE.REQ, isDebtorFactorable);
}
