import { SET_FACTORING_SETTINGS_BY_DEBTOR_INN } from '../reducers/setFactoringSettingsByDebtorInn';

export interface FactoringSettingsByDebtorInnWrite {
  debtorInn: string;
  volume: number;
  autoLimitPolicy: number;
  factoringBaseRate: number;
  factoringFullRate: number;
  vatSize: number;
  factualUsageOnly: boolean;
  gracePeriodBaseRate: number;
  gracePeriodFullRate: number;
  regressPeriodBaseRate: number;
  regressPeriodFullRate: number;
  defaultGracePeriodDays: number;
  defaultRegressPeriodDays: number;
  regressPeriodIsInfinite: boolean;
  defaultMaximalClaimDaysToMaturity: number;
  defaultMaximalClaimSum: number;
  defaultMaximalShareOfFunding: number;
  defaultMaximalCessionShare: number;
  defaultMinimalClaimDaysToMaturity: number;
  defaultMinimalShareOfFunding: number;
  defaultMinimalCessionShare: number;
  fundingSharePolicy: number;
  isRegressed: boolean;
  isNotHidden: boolean;
  isForOnboardedClientsOnly: boolean;
  factoringRateBase: '1' | '2' | '3';
  rateIsSurplusToKeyRate: boolean;
}

export interface FactoringSettingsByDebtorInnRead
  extends FactoringSettingsByDebtorInnWrite {
  volume: number;
  autoLimitPolicy: number;
  factoringBaseRate: number;
  vatSize: number;
  factualUsageOnly: boolean;
  gracePeriodBaseRate: number;
  regressPeriodBaseRate: number;
  defaultGracePeriodDays: number;
  defaultRegressPeriodDays: number;
  regressPeriodIsInfinite: boolean;
  defaultMaximalClaimDaysToMaturity: number;
  defaultMaximalClaimSum: number;
  defaultMaximalShareOfFunding: number;
  defaultMaximalCessionShare: number;
  defaultMinimalClaimDaysToMaturity: number;
  defaultMinimalShareOfFunding: number;
  defaultMinimalCessionShare: number;
  fundingSharePolicy: number;
  isRegressed: boolean;
  isNotHidden: boolean;
  isForOnboardedClientsOnly: boolean;
}

export interface FactoringSettingsByDebtorInn
  extends Partial<FactoringSettingsByDebtorInnWrite> {}

export const setFactoringSettingsByDebtorInn = (
  data: FactoringSettingsByDebtorInn
) => ({
  type: SET_FACTORING_SETTINGS_BY_DEBTOR_INN.SET,
  data
});

export const resetFactoringSettingsByDebtorInn = () => ({
  type: SET_FACTORING_SETTINGS_BY_DEBTOR_INN.RESET
});
