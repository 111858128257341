import styled, { css } from 'styled-components';
import {
  rr,
  rl,
  red,
  gray,
  lightGray,
  lighterGray,
  blue,
  navyBlue
} from 'shared/styled';

const icons = {
  firstName:
    'M15.92 15.563c.05.107.08.226.08.352v.28c0 .445-.36.805-.805.805H4.805A.805.805 0 0 1 4 16.195v-.28c0-.155.044-.3.12-.423.601-1.395 3.352-2.422 5.88-2.422 2.588 0 5.41.973 5.92 2.493zM10 4a3.22 3.22 0 0 1 3.22 3.22v.967a3.22 3.22 0 0 1-6.44 0V7.22A3.22 3.22 0 0 1 10 4z',
  lastName:
    'M15.92 15.563c.05.107.08.226.08.352v.28c0 .445-.36.805-.805.805H4.805A.805.805 0 0 1 4 16.195v-.28c0-.155.044-.3.12-.423.601-1.395 3.352-2.422 5.88-2.422 2.588 0 5.41.973 5.92 2.493zM10 4a3.22 3.22 0 0 1 3.22 3.22v.967a3.22 3.22 0 0 1-6.44 0V7.22A3.22 3.22 0 0 1 10 4z',
  companyName:
    'M7.6 6.4v-.862c0-.535.056-.729.16-.924a1.09 1.09 0 0 1 .454-.454c.195-.104.39-.16.924-.16h1.724c.535 0 .729.056.924.16.196.105.35.258.454.454.104.195.16.39.16.924V6.4h2.062c.535 0 .729.056.924.16.196.105.35.258.454.454.104.195.16.39.16.924v6.524c0 .535-.056.729-.16.924a1.09 1.09 0 0 1-.454.454c-.195.104-.39.16-.924.16H5.538c-.535 0-.729-.056-.924-.16a1.09 1.09 0 0 1-.454-.454c-.104-.195-.16-.39-.16-.924V7.938c0-.535.056-.729.16-.924a1.09 1.09 0 0 1 .454-.454c.195-.104.39-.16.924-.16H7.6zM8.8 10H4.45v.9h11.1V10H11.2v-.45a.75.75 0 0 0-.75-.75h-.9a.75.75 0 0 0-.75.75V10z',
  phoneNumber:
    'M12.987 11.768a.67.67 0 0 0-.683.004l-1.067.636a.668.668 0 0 1-.757-.046 17.369 17.369 0 0 1-1.52-1.324c-.53-.53-1.028-1.14-1.323-1.519a.668.668 0 0 1-.046-.757l.636-1.067a.673.673 0 0 0 .003-.685L6.663 4.334a.675.675 0 0 0-.745-.314c-.283.069-.65.237-1.036.623-1.208 1.207-1.849 3.243 2.691 7.784 4.54 4.54 6.576 3.9 7.784 2.691.387-.386.554-.755.624-1.039a.672.672 0 0 0-.313-.741l-2.681-1.57z',
  email:
    'M14.8 6H5.2c-.66 0-1.194.506-1.194 1.125L4 13.875C4 14.494 4.54 15 5.2 15h9.6c.66 0 1.2-.506 1.2-1.125v-6.75C16 6.506 15.46 6 14.8 6zm0 2.25L10 11.063 5.2 8.25V7.125L10 9.938l4.8-2.813V8.25z',
  password:
    'M13.75 7.333h-.625V6.095C13.125 4.387 11.725 3 10 3S6.875 4.387 6.875 6.095v1.238H6.25c-.69 0-1.25.554-1.25 1.238v6.19C5 15.447 5.56 16 6.25 16h7.5c.69 0 1.25-.554 1.25-1.238v-6.19c0-.685-.56-1.239-1.25-1.239zM10 12.905c-.69 0-1.25-.554-1.25-1.238 0-.684.56-1.238 1.25-1.238s1.25.554 1.25 1.238c0 .684-.56 1.238-1.25 1.238zm1.938-5.572H8.062V6.095A1.93 1.93 0 0 1 10 4.176a1.93 1.93 0 0 1 1.938 1.92v1.237z',
  repeatPassword:
    'M13.75 7.333h-.625V6.095C13.125 4.387 11.725 3 10 3S6.875 4.387 6.875 6.095v1.238H6.25c-.69 0-1.25.554-1.25 1.238v6.19C5 15.447 5.56 16 6.25 16h7.5c.69 0 1.25-.554 1.25-1.238v-6.19c0-.685-.56-1.239-1.25-1.239zM10 12.905c-.69 0-1.25-.554-1.25-1.238 0-.684.56-1.238 1.25-1.238s1.25.554 1.25 1.238c0 .684-.56 1.238-1.25 1.238zm1.938-5.572H8.062V6.095A1.93 1.93 0 0 1 10 4.176a1.93 1.93 0 0 1 1.938 1.92v1.237z'
};

export const InputContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const InputWrapperStyled = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${({ isLabel }) => (isLabel ? '8px' : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  :focus-within > svg {
    color: ${blue};
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: ${gray};
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const WarningMessage = styled.div`
  position: absolute;
  bottom: -25px;
  z-index: 1;
  left: 0;
  width: 100%;
  background-color: #f8f0a8;
  color: black;
  padding: 5px 10px;
  font-family: ${rr};
`;

export const InputStyled = styled.input.attrs(props => ({
  ['aria-label']: props.name
}))`
  width: 100%;
  height: 50px;

  padding-left: ${({ name }) => (!!icons[name] ? '40px' : '15px')};
  padding-right: 40px;

  outline: none;

  background-size: 25px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: ${({
    name,
    isFocused,
    isError
  }) => `url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path fill="${isError ? red : isFocused ? navyBlue : gray}" d="${
    icons[name]
  }"/>
    </svg>')`};
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid
    ${({ isError, isFocused }) => (isFocused ? blue : isError ? red : gray)};

  font-family: ${rr};
  font-size: 14px;
  color: ${({ isError, isFocused }) =>
    isFocused ? navyBlue : isError ? red : gray};

  ${[
    '::-webkit-input-placeholder',
    '::-moz-placeholder',
    ':-ms-input-placeholder',
    ':-moz-placeholder'
  ].map(
    selector =>
      css`
        text-overflow: ellipsis;
        &:not([disabled])${selector} {
          color: ${({ isError, isFocused }) =>
            isFocused ? navyBlue : isError ? red : gray};
        }
        &[disabled]${selector} {
          color: ${lightGray};
        }
      `
  )};

  &[disabled] {
    border: 1px solid ${lightGray};
    background-color: ${lighterGray};
    background-image: ${({ name }) => `url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="${lightGray}" d="${icons[name]}"/>
      </svg>')
    `};

    color: ${gray};
  }
`;
