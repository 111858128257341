import styled from 'styled-components';
import { blue } from 'shared/styled';

export const CertificatesFormStyled = styled.section`
  margin-top: 25px;
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AddButton = styled.div`
  margin-top: 15px;
  width: auto;
  height: auto;
  display: flex;

  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #005db1;
  }
  p {
    color: #ffffff;
    padding: 0 15px 0 15px;
  }
`;
