import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostEarlyPaymentApplicationSupplierRoleStoreState = Reducer;

export enum POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE {
  REQ = 'POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE_REQ',
  GOT = 'POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE_GOT',
  ERR = 'POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE_ERR',
  RESET = 'POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE_RESET'
}

export interface RequestDataType {
  createRequests: string[];
}

export const initialState: PostEarlyPaymentApplicationSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postEarlyPaymentApplicationSupplierRole = (
  state: PostEarlyPaymentApplicationSupplierRoleStoreState = initialState,
  action: {
    type: POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE;
    error: {};
  }
): PostEarlyPaymentApplicationSupplierRoleStoreState => {
  switch (action.type) {
    case POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default postEarlyPaymentApplicationSupplierRole;
