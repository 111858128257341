import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getOpenLoanLimits';
import { GET_OPEN_LOAN_LIMITS } from 'Application/reducers/getOpenLoanLimits';

import ApplicationApi from 'Application/api';

function* getOpenLoanLimits(action: {
  type: GET_OPEN_LOAN_LIMITS;
  inn: string;
}) {
  try {
    const res = yield call(ApplicationApi.getOpenLoanLimits, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOpenLoanLimitsSaga() {
  yield takeLatest(GET_OPEN_LOAN_LIMITS.REQ, getOpenLoanLimits);
}
