import { isValidINN } from './Utils';
import { Document } from 'Application/types';

export const alwaysTrueRule = () => true;
export const pattern = (r: string | RegExp) => (v: string): boolean => {
  const regexp = new RegExp(r);
  return regexp.test(v);
};

export const notEmptyArrayRule = (v: any[]) => v.length > 0;
export const singleElementArrayRule = (v: any[]) => v.length === 1;
export const notEmptyStringRule = (v: string) => !!(v && v.length !== 0);

export const inRangeRule = (v: string, min: number, max: number) =>
  parseInt(v, 10) > min && parseInt(v, 10) <= max;

export const documentWithMetaInfoUploadedRule = (
  documents: Document[],
  metaInfo: string
) => documents.find(d => d.metaInfo === metaInfo) !== undefined;

export const notZeroRule = (v: string) => v !== '0';
export const equalValuesRule = (v1, v2) => v1 === v2;

export const minLength = (l: number) => (v: string): boolean => v.length >= l;
export const maxLength = (l: number) => (v: string): boolean => v.length <= l;

export const phoneRule = pattern(
  /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
);
export const emailRule = pattern(
  /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
);
export const passwordRule = pattern(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*["\'.,!@#$%*(){}\\[\\]_\\+\\-/\\\\;:\\?<>])'
);

export const passwordCharEnum = pattern(
  '^(?=.*["\'.,!@#$%*(){}\\[\\]_\\+\\-/\\\\;:\\?<>])'
);

export const passwordCharCase = pattern('^(?=.*[a-z])(?=.*[A-Z])');

export const innLengthEntityRule = pattern(/^\d{10}$/);
export const innLengthIndividualRule = pattern(/^\d{12}$/);

export const innValidationRule = (v: string): boolean => {
  if (v) return isValidINN(v);
  else return false;
};

export const dateValidationRule = (
  date: string,
  isSelected: boolean
): boolean => {
  return true;
};

// TODO TERM_TYPE exposed

export const innValidationEntityRule = (v: string): boolean => {
  /*
   1) Находим произведения первых 9 цифр ИНН на специальные множители соотственно.
      9 множителей - [2, 4, 10, 3, 5, 9, 4, 6, 8].
   2) Складываем все 9 получившихся произведений.
   3) Получившуюся сумму делим на число 11 и извлекаем целую часть частного от деления.
   4) Умножаем получившееся число на 11.
   5) Сравниваем числа получившиеся на шаге 2 и шаге 4. Их разница и есть контрольное число,
      которое и должно равняться 10-й цифре в ИНН.
   6) Если контрольное число получилось равным 10-ти, в этом случае принимаем
      контрольное число равным 0.
  */

  const numbers: number[] = v
    .split('')
    .map(strNumber => parseInt(strNumber, 10));
  const lastDigit: number = numbers.pop();
  const multipliers = [2, 4, 10, 3, 5, 9, 4, 6, 8];

  const firstStep: number = numbers.reduce(
    (multiplication: number, num: number, index: number) => {
      multiplication = multiplication + num * multipliers[index];
      return multiplication;
    },
    0
  );

  const secondStep: number = Math.floor(firstStep / 11) * 11;
  const numberResult: number = firstStep - secondStep;
  const isValidationPassed: boolean =
    numberResult === 10 ? lastDigit === 0 : lastDigit === numberResult;

  return innLengthEntityRule(v) && isValidationPassed;
};

export const innValidationIndividualRule = (v: string): boolean => {
  /*
    1) Находим произведения первых 10-ти цифр ИНН на специальные множители соотственно.
       10 множителей - [7, 2, 4, 10, 3, 5, 9, 4, 6, 8].
    2) Складываем все 10 получившихся произведений.
    3) Получившуюся сумму делим на число 11 и извлекаем целую часть частного от деления.
    4) Умножаем получившееся число на 11.
    5) Сравниваем числа, получившиеся на шаге 2 и шаге 4. Их разница и есть первое контрольное число,
       которое и должно равняться 11-й цифре в ИНН.
       (Если контрольное число получилось равным 10-ти, в этом случае принимаем контрольное число равным 0.)
       Если получившееся число не равно 11-ой цифре ИНН, значит ИНН не верный,
       если же совпадает, тогда высчитываем следующее контрольное число, которое должно быть равным 12-ой цифре ИНН.
    6) Находим произведения первых 11-ти цифр ИНН на специальные множители соответственно.
       11 множителей - [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8].
    7) Складываем все 11 получившихся произведений.
    8) Получившуюся сумму делим на число 11 и извлекаем целую часть частного от деления.
    9) Умножаем получившееся число на 11.
   10) Сравниваем числа, получившиеся на шаге 7 и шаге 9. Их разница и есть контрольное число,
       которое и должно равняться 12-й цифре в ИНН.
       (Если контрольное число получилось равным 10-ти, в этом случае принимаем контрольное число равным 0.)
       Если высчитанное число равно 12-ой цифре ИНН,
       и на первом этапе контрольное число совпало с 11-ой цифрой ИНН, ИНН считается верным.
  */

  const checkINN = (numbersArray, multipliersArray, numberToCompare) => {
    const firstStep: number = numbersArray.reduce(
      (multiplication: number, num: number, index: number) =>
        (multiplication = multiplication + num * multipliersArray[index]),
      0
    );

    const secondStep: number = Math.floor(firstStep / 11) * 11;

    const numberResultFirst: number = firstStep - secondStep;

    const isValidationPassed: boolean =
      numberResultFirst === 10
        ? numberToCompare === 0
        : numberToCompare === numberResultFirst;

    return isValidationPassed;
  };

  const numbers: number[] = v
    .split('')
    .map(strNumber => parseInt(strNumber, 10));

  const lastDigit: number = numbers.pop();
  const penultimateDigit: number = numbers.pop();

  const multipliersFirst = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
  const multipliersSecond = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

  if (
    innLengthIndividualRule(v) &&
    checkINN(numbers, multipliersFirst, penultimateDigit)
  ) {
    return checkINN(
      [...numbers, penultimateDigit],
      multipliersSecond,
      lastDigit
    );
  }

  return false;
};

export const validateINN = (inn: string): boolean => {
  if (!inn) return;

  // Проверка на длину ИНН
  if (inn.length !== 10 && inn.length !== 12) {
    return false;
  }

  // Проверка на наличие только цифр в ИНН
  if (!/^\d+$/.test(inn)) {
    return false;
  }

  // Рассчет контрольной цифры для 10-значного ИНН
  if (inn.length === 10) {
    const weights = [2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
    const controlDigit = calculateControlDigit(inn, weights);
    return +inn[9] === controlDigit;
  }

  // Рассчет контрольной цифры для 12-значного ИНН
  if (inn.length === 12) {
    const weights1 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
    const weights2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
    const controlDigit1 = calculateControlDigit(inn.slice(0, 10), weights1);
    const controlDigit2 = calculateControlDigit(inn.slice(0, 11), weights2);
    return +inn[10] === controlDigit1 && +inn[11] === controlDigit2;
  }

  return false;
};

function calculateControlDigit(part, weights) {
  let sum = 0;
  for (let i = 0; i < part.length; i++) {
    sum += +part[i] * weights[i];
  }
  return (sum % 11) % 10;
}

export const legalFormRule = (v: string): boolean => v !== '';
