import styled from 'styled-components';
import { gray, lightGray, lighterBlue } from 'shared/styled';

export const SummContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 30% 55%;
  column-gap: 30px;

  div {
    text-align: end;
  }

  p {
    color: ${gray};
    margin: 0;
  }
`;

export const SummDataContainer = styled.div`
  div {
    display: grid;
    grid-template-columns: 1fr 60px;
    column-gap: 10px;

    p:last-of-type {
      text-align: start;
    }
  }
`;

export const NoDataSummContainer = styled.div`
  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const SupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p {
    margin: 0;
  }
`;

export const Status = styled.div`
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ status }) =>
    status === 10
      ? 'rgba(213, 232, 253, 1)'
      : status === 20
        ? lightGray
        : status === 11
          ? 'rgba(253, 241, 224, 1)'
          : 'transparent'};
`;

export const ErrorDeleteDiscount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  font-size: 20px;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: end;
  padding: 10px 20px;
  background-color: ${lighterBlue};
  margin-bottom: 20px;

  span {
    align-self: center;
    margin: 20px 10px 0 10px;
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(5) {
    margin-right: 20px;
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(3),
  & > div:nth-child(5),
  & > div:nth-child(6),
  & > div:nth-child(8) {
    max-width: 200px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;
