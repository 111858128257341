import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, STORE, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled, TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import {
    req as getCompaniesByHoldingList,
    ResponseData as getCompaniesByHoldingListResponseData,
    RequestData as getCompaniesByHoldingListRequestData
} from '../../actions/getCompaniesByHoldingList';
import { history } from 'src/shared/utils/History';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { ApplicationStatusStyled, TransferApplicationBtn } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ApplicationStatus/styles';
//import { LeadStatusStyled } from '../LeadView/styled';
//import { ContactStatuses, ApplicationTypes } from 'shared/constants';
import { Pagination, Loader, ACTION_RESPONSE_TEMPLATE, ActionResponseBlock } from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
//import { req as changeLeadStatus, RequestData as changeLeadStatusRequestData } from '../../actions/changeLeadStatus';
import { CompanyRead } from '../../actions/setCompanyFormData';
//import ContactPopup from './ContactPopup/ContactPopup';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
    companies: getCompaniesByHoldingListResponseData;
    status: REQUEST_STATUSES;
    error: ResponseError;
}

interface DispatchToProps {
    getCompaniesByHoldingList: (data: getCompaniesByHoldingListRequestData) => void;
}

interface OwnProps {
    holdingId: number;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class CompaniesListForHoldingPage extends React.Component<Props> {
    componentDidMount() {
        const { holdingId, getCompaniesByHoldingList } = this.props;

        // TODO duplication with applicationslist
        const urlParams = new URLSearchParams(history.location.search);
        const page = parseInt(urlParams.get('page'), 10);
        getCompaniesByHoldingList({ page, pageSize: 20, holdingId: holdingId });
    }

    onCompanyClick(index: number) {
      history.push(`/crm/companies/${this.props.companies.items[index].inn}`);
    }

    render() {
        const { companies, status, error, holdingId } = this.props;
        return (
            <ScrollTopComponent>
                {status === REQUEST_STATUSES.REQUEST && (<Loader />)}
                {status === REQUEST_STATUSES.GOT && (
                    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                      <TableHeaderStyled>
                        <tr>
                          <TableThStyled width="6%">ИНН</TableThStyled>
                          <TableThStyled width="12%">Наименование</TableThStyled>
                          <TableThStyled width="9%">Выручка</TableThStyled>
                          <TableThStyled width="9%">Прибыль</TableThStyled>
                          <TableThStyled width="9%">Капитал и резервы</TableThStyled>
                          <TableThStyled width="5%">Фин. год</TableThStyled>
                          <TableThStyled width="8%">Контакты</TableThStyled>
                        </tr>
                      </TableHeaderStyled>
                      <tbody>
                        {companies.items.map((company, key) => (
                          <TableRowStyled
                            onClick={() => this.onCompanyClick(key)}
                            key={company.inn}
                          >
                            <td>{company.inn}</td>
                            <td>{company.companyShortName}</td>
                            <td>{formSumStringThousands(company.revenue)}</td>
                            <td>{formSumStringThousands(company.netIncome)}</td>
                            <td>{formSumStringThousands(company.capital)}</td>
                            <td>{company.financialsYear}</td>
                            <td>
                              {company.hasContacts ? 'Есть контакты' : 'Нет контактов'}
                            </td>
                          </TableRowStyled>
                        ))}
                      </tbody>
                    </TableStyled>
                )}
                <Pagination list={companies} />

                <ActionResponseBlock
                    showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
                    template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
                />

                <ActionResponseBlock
                    showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
                    template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
                />

            </ScrollTopComponent>
        );
    }


}

const mapStateToProps = ({ Companies, User }: CRM) => ({
  companies: Companies.getCompaniesByHoldingList.data,
  status: Companies.getCompaniesByHoldingList.status,
  error: Companies.getCompaniesByHoldingList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompaniesByHoldingList
    },
    dispatch
  );

const CompaniesListForHoldingPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesListForHoldingPage);

export { CompaniesListForHoldingPageConnect as CompaniesListForHoldingPage };
