import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getBankAccounts';

import { GET_BANK_ACCOUNTS } from '../reducers/getBankAccounts';
import BankProductApi from '../api';

function* getBankAccounts(action: {
  type: GET_BANK_ACCOUNTS;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BankProductApi.getBankAccounts, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBankAccountsSaga(): Generator {
  yield takeLatest(GET_BANK_ACCOUNTS.REQ, getBankAccounts);
}
