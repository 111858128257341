import {
  RequestDataType,
  APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE
} from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';

export const req = (data: RequestDataType, reqType?: 'array' | null) => ({
  type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ,
  reqType,
  data
});

export const got = (data?: { success: boolean }[]) => ({
  type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.ERR,
  error
});

export const reset = () => ({
  type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.RESET
});
