import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendFactoringContract';

import { SEND_FACTORING_CONTRACT } from '../reducers/sendFactoringContract';
import SCFApi from '../api';
import {
  FactoringContract,
  resetFactoringContract
} from '../actions/setFactoringContract';

function* sendFactoringContract(action: {
  type: SEND_FACTORING_CONTRACT;
  data: FactoringContract;
}): Generator {
  try {
    yield call(SCFApi.sendFactoringContract, action.data);
    yield put(got());
    yield put(resetFactoringContract());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendFactoringContractSaga(): Generator {
  yield takeLatest(SEND_FACTORING_CONTRACT.REQ, sendFactoringContract);
}
