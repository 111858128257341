import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type UploadDebtorContractDocumentStoreState = Reducer;

export interface RequestDataType {
  file: FormData;
  metaInfo: string;
  guid: string;
}

export interface SagaRequestDataType {
  files: any[];
  guid: string;
}

export enum UPLOAD_DEBTOR_CONTRACT_DOCUMENT {
  REQ = 'UPLOAD_DEBTOR_CONTRACT_DOCUMENT_REQUEST',
  GOT = 'UPLOAD_DEBTOR_CONTRACT_DOCUMENT_GOT',
  ERR = 'UPLOAD_DEBTOR_CONTRACT_DOCUMENT_ERROR'
}

export const initialState: UploadDebtorContractDocumentStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const uploadDebtorContractDocument = (
  state: UploadDebtorContractDocumentStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): UploadDebtorContractDocumentStoreState => {
  switch (action.type) {
    case UPLOAD_DEBTOR_CONTRACT_DOCUMENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPLOAD_DEBTOR_CONTRACT_DOCUMENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case UPLOAD_DEBTOR_CONTRACT_DOCUMENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default uploadDebtorContractDocument;
