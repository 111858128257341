import { GET_EXTERNAL_CREDENTIALS_LIST_BY_PRODUCT } from '../reducers/getExternalCredentialsListByProduct';
import { ExternalCredentialsOfProductsRead } from './setExternalCredentialsOfProductsFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ExternalCredentialsOfProductsRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  productId: number;
}

export const req = (data: RequestData) => ({
  type: GET_EXTERNAL_CREDENTIALS_LIST_BY_PRODUCT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_EXTERNAL_CREDENTIALS_LIST_BY_PRODUCT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_CREDENTIALS_LIST_BY_PRODUCT.ERR,
  error
});
