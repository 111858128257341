import * as React from 'react';
import {
  DocumentsBlock,
  Props as DocumentsBlockProps
} from 'Layouts/components';

import { AttachedDocumentsLabel as Title } from './styles';

export const AttachedDocumentsBlock: React.FC<DocumentsBlockProps> = ({
  documents
}) =>
  documents.length > 0 && (
    <React.Fragment>
      <Title>Прикрепленные файлы:</Title>
      <DocumentsBlock documents={documents} />
    </React.Fragment>
  );
