import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu
} from 'src/features/Layouts/components';
import { history } from 'src/shared/utils/History';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  ButtonRoleContainer,
  CardHolding,
  CompanyMainInfoContainer,
  CompanyRoleContainer,
  CompanyRoleName,
  OwnerCompanyRole
} from 'src/features/Companies/components/CompanyView/styled';
import { req as getCompany } from 'src/features/Companies/actions/getCompany';
import { req as getMainCounter } from 'src/features/Companies/actions/getMainCounter';
import { Role } from 'src/features/Companies/types';
import CompaniesApi from 'src/features/Companies/api';
import UserAccountsApi from 'src/features/Users/api';
import { AGENTS_TABS } from 'src/features/Agents/types';
import { MAIN_COUNTER } from 'src/features/Companies/types/mainCounter';
import { CompanyRead } from 'src/features/Companies/actions/setCompanyFormData';
import { ContactsList } from 'src/features/Contacts/components/ContactsList/ContactsList';
import { UserListForBankPage } from 'src/features/Users/components/UserListForBankPage/UserListForBankPage';
import { ActivitiesListOfCompanies } from 'src/features/ActivitiesOfCompanies/components/ActivitiesOfCompanies';
import { AgentApplicationList } from 'src/features/ApplicationForCompanies/components/AgentApplication';
import { Questionnaire } from 'src/features/Questionnaire/components/Questionnaire';
import { ClientsForAgent } from 'src/features/ClientsForAgent/components/ClientsForAgent';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {
  mainCounter: MAIN_COUNTER;
  company: CompanyRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {
  activeTab: string;
  manager: {
    id: number;
    firstName: string;
    lastName: string;
  };
  analyst: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

interface DispatchToProps {
  getCompany: (inn: string) => void;
  getMainCounter: (inn: string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class AgentView extends React.Component<Props, State> {
  state: State = {
    activeTab: 'DEFAULT',
    manager: {
      id: null,
      firstName: null,
      lastName: null
    },
    analyst: {
      id: null,
      firstName: null,
      lastName: null
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.company.managerId !== this.props.company.managerId ||
      prevProps.company.analystId !== this.props.company.analystId
    ) {
      this.getNameByManager();
      this.getNameByAnalyst();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      }
    }
  }

  initFetch() {
    if (
      this.props.match.params.tab &&
      this.props.match.params.tab !== 'requests'
    ) {
      this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
    }

    this.props.getCompany(this.props.match.params.inn);
    this.props.getMainCounter(this.props.match.params.inn);
  }

  getNameByManager = async () => {
    if (!!this.props.company.managerId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.managerId.toString()
      );
      this.setState({
        manager: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        }
      });
    }
  };

  getNameByAnalyst = async () => {
    if (!!this.props.company.analystId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.analystId.toString()
      );
      this.setState({
        analyst: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        }
      });
    }
  };

  navigateToHolding = (holdingId: number) => {
    if (holdingId) {
      history.push(`/crm/holdings/${holdingId}`);
    }
  };

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });

    const { tab, applicationTab } = this.props.match.params;

    const baseUrl = history.location.pathname
      .replace(`/${tab && tab.toLowerCase()}`, '')
      .replace(`/${applicationTab}`, '');

    if (tabName !== 'DEFAULT') {
      const url = baseUrl + `/${tabName.toLowerCase()}`;
      history.push(url);
    } else {
      history.push(baseUrl);
    }
  };

  handleSetRole = async (inn: string, role: Role, locked: boolean) => {
    await CompaniesApi.putLockAndUnlockCompany(inn, role, locked);
    this.initFetch();
  };

  getCountByKey = (key: string): number | null => {
    const { mainCounter } = this.props;

    if (key === 'DEFAULT') {
      const count = mainCounter.applicationsCounter + mainCounter.leadsCounter;
      return count > 0 ? count : null;
    } else if (key === 'AGENT_APPLICATIONS') {
      const count =
        mainCounter.agentApplicationsCounter + mainCounter.agentLeadsCounter;
      return count > 0 ? count : null;
    }

    const count = +mainCounter[`${key.toLowerCase()}Counter`];

    return count > 0 ? count : null;
  };

  render() {
    const { company, status, error } = this.props;

    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <CompanyMainInfoContainer>
              <div>
                <ApplicationId>ИНН {company.inn}</ApplicationId>
                <ApplicationTitle>{company.companyShortName}</ApplicationTitle>
              </div>
              <CompanyRoleContainer>
                {!!this.props.company.managerId && (
                  <div>
                    <CompanyRoleName>Менеджер</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.manager.firstName}{' '}
                      {this.state.manager.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}
                {!!this.props.company.analystId && (
                  <div>
                    <CompanyRoleName>Аналитик</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.analyst.firstName}{' '}
                      {this.state.analyst.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}
                <ButtonRoleContainer>
                  <div>
                    {company.managerId === null && (
                      <Button
                        label="Закрепиться как менеджер"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(company.inn, 'as_manager', true)
                        }
                        h="40px"
                      />
                    )}
                    {company.analystId === null && (
                      <Button
                        label="Закрепиться как аналитик"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(company.inn, 'as_analyst', true)
                        }
                        h="40px"
                      />
                    )}
                  </div>
                  <div>
                    {company.managerId !== null &&
                      company.managerId === this.state.manager.id && (
                        <Button
                          label="Открепиться как менеджер"
                          onClick={() =>
                            this.handleSetRole(company.inn, 'as_manager', false)
                          }
                          h="40px"
                        />
                      )}
                    {company.analystId !== null &&
                      company.analystId === this.state.analyst.id && (
                        <Button
                          label="Открепиться как аналитик"
                          onClick={() =>
                            this.handleSetRole(company.inn, 'as_analyst', false)
                          }
                          h="40px"
                        />
                      )}
                  </div>
                </ButtonRoleContainer>
              </CompanyRoleContainer>
            </CompanyMainInfoContainer>

            <ViewSeparatorTop>
              <MainInfoBlock>
                {company.holdingId && (
                  <MainInfoItem>
                    <CardHolding
                      onClick={() => this.navigateToHolding(company.holdingId)}
                    >
                      <span>Холдинг</span>
                      <span>- {company.holdingName} -</span>
                    </CardHolding>
                  </MainInfoItem>
                )}
                <MainInfoItem>
                  <span>Выручка, тыс. руб.</span>
                  <span>
                    {company.revenue === null
                      ? 'Нет данных'
                      : formSumStringThousands(company.revenue)}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Чистая прибыль</span>
                  <span>
                    {company.netIncome === null
                      ? 'Нет данных'
                      : formSumStringThousands(company.netIncome)}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Капитал и резервы</span>
                  <span>
                    {company.capital === null
                      ? 'Нет данных'
                      : formSumStringThousands(company.capital)}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Отчетный год</span>
                  <span>
                    {company.financialsYear === null
                      ? 'Нет данных'
                      : formSumStringThousands(company.financialsYear)}
                  </span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <Menu
              activeTab={this.state.activeTab}
              getCountByKey={this.getCountByKey}
              handleActiveTab={this.handleActiveTab}
              tabs={AGENTS_TABS}
            />

            {this.state.activeTab === 'DEFAULT' && (
              <>
                <ApplicationId>Агентские заявки</ApplicationId>
                <AgentApplicationList />
              </>
            )}

            {this.state.activeTab === 'CONTACTS' && (
              <>
                <ApplicationId>Все контакты</ApplicationId>
                <ContactsList companyInn={company.inn} />
              </>
            )}

            {this.state.activeTab === 'USERS' && (
              <>
                <ApplicationId>Все пользователи</ApplicationId>
                <UserListForBankPage bankInn={company.inn} />
              </>
            )}

            {this.state.activeTab === 'ACTIVITIES' && (
              <ActivitiesListOfCompanies companyInn={company.inn} />
            )}

            {this.state.activeTab === 'QUESTIONNAIRE' && (
              <Questionnaire companyInn={company.inn} />
            )}

            {this.state.activeTab === 'CLIENTS' && (
              <ClientsForAgent agentInn={company.inn} />
            )}
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Companies }: CRM) => ({
  mainCounter: Companies.getMainCounter.data,
  company: Companies.getCompany.company,
  status: Companies.getCompany.status,
  error: Companies.getCompany.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompany, getMainCounter }, dispatch);

const AgentViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AgentView)
);

export { AgentViewConnect as AgentView };
