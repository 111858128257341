import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getMyBuyers'

export interface GetMyBuyersStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_MY_BUYERS {
  GET_MY_BUYERS_REQ = 'GET_MY_BUYERS_REQ',
  GET_MY_BUYERS_GOT = 'GET_MY_BUYERS_GOT',
  GET_MY_BUYERS_ERR = 'GET_MY_BUYERS_ERR'
}

export const initialState: GetMyBuyersStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getMyBuyers = (
  state: GetMyBuyersStoreState = initialState,
  action: {
    type: GET_MY_BUYERS;
    data: ResponseDataType;
    error: {};
  }
): GetMyBuyersStoreState => {
  switch (action.type) {
    case GET_MY_BUYERS.GET_MY_BUYERS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MY_BUYERS.GET_MY_BUYERS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MY_BUYERS.GET_MY_BUYERS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMyBuyers;
