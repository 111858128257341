import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { InnPlaceholders } from 'shared/constants';
import { ScrollTopComponent } from 'src/features/Common';
import { Input } from 'shared/ui/Input';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getDebtorsList,
  ResponseData as DebtorsListResponseData,
  RequestData as DebtorsListRequestData
} from '../../actions/getDebtorsList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  debtors: DebtorsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getDebtorsList: (data: DebtorsListRequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const DebtorsList: React.FC<Props> = ({
  getDebtorsList,
  debtors,
  status,
  error,
  history
}) => {
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const debtorInn = searchParams.get('debtorInn') || '';

    setDebtorInn(debtorInn);

    getDebtorsList({
      page,
      pageSize: 20,
      ...(debtorInn && { debtorinnToFilterBy: debtorInn })
    });
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToDebror = (inn: string) => {
    history.push(`/crm/debtors/${inn}`);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все дебиторы</ApplicationTitle>

      <FiltersStyled>
        <INNFilterContainerStyled>
          <Input
            value={debtorInn}
            label="ИНН Дебитора"
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNFilterContainerStyled>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="6%">ИНН Дебитора</TableThStyled>
              <TableThStyled width="14%">Наименование дебитора</TableThStyled>
              <TableThStyled width="10%">Холдинг</TableThStyled>
              <TableThStyled width="10%">Выручка</TableThStyled>
              <TableThStyled width="10%">Кредиторка</TableThStyled>
              <TableThStyled width="10%">Себестоимость продаж</TableThStyled>
              <TableThStyled width="5%">
                Отсрочка,
                <br />
                дн.
              </TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {debtors.items.map(debtor => (
              <TableRowStyled
                key={debtor.inn}
                onClick={() => navigateToDebror(debtor.inn)}
              >
                <td>{debtor.inn}</td>
                <td>{debtor.shortName}</td>
                <td>{debtor.holdingName}</td>
                <td>{formSumStringThousands(debtor.revenue)}</td>
                <td>{formSumStringThousands(debtor.accountPayables)}</td>
                <td>{formSumStringThousands(debtor.costOfSales)}</td>
                <td>{debtor.paymentPostponement}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={debtors} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Debtors }: CRM) => ({
  debtors: Debtors.getDebtorsList.data,
  status: Debtors.getDebtorsList.status,
  error: Debtors.getDebtorsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getDebtorsList }, dispatch);

const DebtorsListConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorsList)
);

export { DebtorsListConnect as DebtorsList };
