import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  CONTRACT_PREPAYMENT_TYPE,
  ContractFinancingRead
} from 'src/features/Application/types';
import {
  ApplicationTypes,
  ContractFinancingType,
  ContractSubjectType,
  ContractPrepaymentType
} from 'shared/constants';
import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import { req as getExternalApplication } from 'entities/Cabinet/ExternalAgent/model/actions/getApplicationExternal';
import { RequestDataType as ReqApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';
import { req as getInBankApplication } from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { reset as resetFinancialsSummary } from 'src/features/Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';
import { EachApplicationView } from './EachApplicationView';
import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  externalApplication: Partial<ContractFinancingRead>;
  status: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getExternalApplication: (data: ReqApplicationExternal) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ContractFinancingView: React.FC<Props> = ({
  getExternalApplication,
  externalApplication,
  status,
  getInBankApplication,
  inBankApplication,
  statusInBankApplication,
  resetFinancialsSummary,
  resetSelectApplicationState,
  getProductApplication,
  statusPostSendBank,
  statusSendApi,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusChangeApplicationStatus,
  permissions,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getExternalApplication({ id: id });
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getExternalApplication({ id: inBankId });
        getInBankApplication({ id: +inBankId });
      }

      return () => resetFinancialsSummary();
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getExternalApplication({ id: id });
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getExternalApplication({ id: inBankId });
          getInBankApplication({ id: +inBankId });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getExternalApplication({ id: id });
        } else if (inBankId) {
          getExternalApplication({ id: inBankId });
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  if (
    status !== REQUEST_STATUSES.GOT ||
    statusInBankApplication !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  const setSubInfo = () => {
    const subinfo = [
      {
        name: 'Тип контракта',
        value: ContractFinancingType[externalApplication.contractType]
      },
      {
        name: 'Ссылка на закупку',
        value: externalApplication.purchaseLink
      },
      {
        name: 'Цена контракта',
        value: formSumString(externalApplication.contractAmount)
      },
      {
        name: 'Предмет контракта',
        value: ContractSubjectType[externalApplication.contractSubject]
      },
      {
        name: 'Срок окончания контракта',
        value: externalApplication.contractEndDate
      },
      {
        name: 'Аванс по договору',
        value: ContractPrepaymentType[externalApplication.prepaymentType]
      }
    ];
    if (
      externalApplication.prepaymentType ===
      CONTRACT_PREPAYMENT_TYPE.HAS_PREPAYMENT
    )
      subinfo.push({
        name: 'Размер аванса',
        value: externalApplication.prepaymentAmount
      });
    return subinfo;
  };

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[externalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(externalApplication.amount)
        },
        {
          name: 'Срок кредита',
          value: externalApplication.termEndDate
            ? externalApplication.termEndDate
            : formTermString(externalApplication.term)
        },
        {
          name: 'ИНН',
          value: externalApplication.code
        }
      ]}
      subInfo={[...setSubInfo()]}
      documents={{
        general: [
          ...inBankApplication.agreementDocuments,
          ...inBankApplication.groupDocuments,
          ...inBankApplication.contractDocuments
        ],
        company: inBankApplication.borrowerCompanies
      }}
    />
  );
};

const mapStateToProps = ({ Application, ExternalAgentRole, User }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  status: ExternalAgentRole.getApplicationExternal.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getExternalApplication,
      getInBankApplication,
      getProductApplication,
      resetSelectApplicationState,
      resetFinancialsSummary
    },
    dispatch
  );

const ContractFinancingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingView)
);

export { ContractFinancingViewConnect as ContractFinancingView };
