import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getClientsList';

import { GET_CLIENTS_LIST } from '../reducers/getClientsList';
import ClientsApi from '../api';

function* getClientsList(action: {
  type: GET_CLIENTS_LIST;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(ClientsApi.getAllClients, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getClientsListSaga(): Generator {
  yield takeLatest(GET_CLIENTS_LIST.REQ, getClientsList);
}
