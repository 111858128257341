import { BANK_ACCOUNT_ACTIONS } from './../reducers/getBankAccount';
import { BankAccount } from './../types/bankAccount';

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: BankAccount[];
}

export const req = () => ({ type: BANK_ACCOUNT_ACTIONS.REQ });
export const got = (data: ResponseDataType) => ({ type: BANK_ACCOUNT_ACTIONS.GOT, data });
export const err = (error: {}) => ({
  type: BANK_ACCOUNT_ACTIONS.ERR,
  error
});


