import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationTypes } from 'shared/constants';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { Status } from 'src/features/ApplicationForCompanies/components/styled';
import { ResponseData as LeadsData } from 'src/features/ApplicationForCompanies/actions/getLeads';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';

interface Props {
  leads: LeadsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

const LeadsList: React.FC<Props> = ({ leads, status, error }) => {
  const navigateToLead = (id: number) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    window.open(`/cabinet/${role}/lead/${id}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">ID</TableThStyled>
                <TableThStyled width="10%">Клиент</TableThStyled>
                <TableThStyled width="10%">Тип финансирования</TableThStyled>
                <TableThStyled width="15%">Сумма</TableThStyled>
                <TableThStyled width="15%">Дата изменения</TableThStyled>
                <TableThStyled width="10%">Статус</TableThStyled>
                <TableThStyled width="15%">Инициатор</TableThStyled>
                <TableThStyled width="15%">Сопроводит заявку</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {leads.items.map(user => (
                <TableRowStyled
                  key={user.id}
                  onClick={() => navigateToLead(user.id)}
                >
                  <td>{user.id}</td>
                  <td>{user.clientName}</td>
                  <td>{ApplicationTypes[user.applicationFinancingType]}</td>
                  <td>{formSumStringThousands(user.amount)} ₽</td>
                  <td>
                    {user.modifiedAt &&
                      format(new Date(user.modifiedAt), 'dd/MM/yy')}
                  </td>
                  <td>
                    <Status>{user.status}</Status>
                  </td>
                  <td />
                  <td />
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
          <Pagination list={leads} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const LeadsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsList);

export { LeadsListConnect as LeadsList };
