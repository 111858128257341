import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader,
  Menu
} from 'src/features/Layouts/components';
import { UserData, USER_TABS } from 'src/features/Users/types';
import { req as getUser } from '../../actions/getUser';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { CheckboxStyle, TooltipBoxStyle } from '../UsersList/styled';
import {
  CheckboxIsBlocked,
  CompanyCard,
  EmailWrapper,
  NameWrapper,
  TooltipIsBlocked
} from 'src/features/Users/components/UserView/styled';
import { BasicParametersForUser } from 'src/features/Users/components/BasicParametersForUser/BasicParametersForUser';
import { ApplicationsForUser } from 'src/features/Users/components/ApplicationsForUser/ApplicationsForUser';

interface StateToProps {
  companyByInn: ResponseDataType;
  user: UserData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface MatchParams {
  id: string;
  tab: string;
  applicationTab: string;
}

interface DispatchToProps {
  getUser: (id: string) => void;
  getCompanyByInn: ({ inn }: RequestDataType) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const UserView: React.FC<Props> = ({
  getUser,
  getCompanyByInn,
  companyByInn,
  user,
  status,
  error,
  history,
  location,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(
    () => {
      getUser(match.params.id);
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      if (user && user.companyInn) {
        getCompanyByInn({ inn: user.companyInn });
      }
    },
    [user]
  );

  React.useEffect(
    () => {
      if (match.params.tab === undefined) {
        setActiveTab('DEFAULT');
      } else {
        setActiveTab(match.params.tab.toUpperCase());
      }
    },
    [location.pathname]
  );

  const handleActiveTab = (tabName: string) => {
    setActiveTab(tabName);

    const { tab, applicationTab } = match.params;

    const baseUrl = location.pathname
      .replace(`/${tab && tab.toLowerCase()}`, '')
      .replace(`/${applicationTab}`, '');

    if (tabName !== 'DEFAULT') {
      const url = baseUrl + `/${tabName.toLowerCase()}`;
      history.push(url);
    } else {
      history.push(baseUrl);
    }
  };

  const navigateToCompanies = () => {
    history.push(`/crm/companies/${companyByInn.inn}`);
  };

  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <ApplicationId>Пользователь #{user.id}</ApplicationId>
          <ApplicationTitle>
            <NameWrapper isBlocked={user.isBlocked}>
              <h1>
                {user.lastName} {user.firstName}
              </h1>
              {user.isBlocked && (
                <TooltipIsBlocked textVerify={'Пользователь заблокирован'}>
                  <CheckboxIsBlocked isBlocked={user.isBlocked}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </CheckboxIsBlocked>
                </TooltipIsBlocked>
              )}
            </NameWrapper>
          </ApplicationTitle>

          <ViewSeparatorTop>
            <MainInfoBlock>
              <MainInfoItem>
                {!user.companyInn && <span>Компания</span>}
                {user.companyInn ? (
                  <CompanyCard onClick={navigateToCompanies}>
                    <span>Компания</span>
                    <span>{companyByInn.companyFullName}</span>
                  </CompanyCard>
                ) : (
                  <span>Нет данных</span>
                )}
              </MainInfoItem>
              <MainInfoItem>
                <span>Телефон</span>
                <span>{user.phoneNumber}</span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Email</span>
                <EmailWrapper>
                  <span>{user.email}</span>
                  {user.emailConfirmed ? (
                    <TooltipBoxStyle textVerify={'Email подтвержден'}>
                      <CheckboxStyle verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faCheck} />
                      </CheckboxStyle>
                    </TooltipBoxStyle>
                  ) : (
                    <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                      <CheckboxStyle verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </CheckboxStyle>
                    </TooltipBoxStyle>
                  )}
                </EmailWrapper>
              </MainInfoItem>
              <MainInfoItem>
                <span>Дата регистрации</span>
                <span>
                  {user.createdDateTime &&
                    new Date(user.createdDateTime).toLocaleDateString()}
                </span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Дата последней заявки</span>
                <span>
                  {user.lastApplicationAt
                    ? new Date(user.lastApplicationAt).toLocaleDateString()
                    : 'Нет данных'}
                </span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Дата последнего входа</span>
                <span>
                  {user.lastLoginAt
                    ? new Date(user.lastLoginAt).toLocaleDateString()
                    : 'Нет данных'}
                </span>
              </MainInfoItem>
            </MainInfoBlock>
          </ViewSeparatorTop>

          <Menu
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            tabs={USER_TABS}
          />

          {activeTab === 'DEFAULT' && <BasicParametersForUser user={user} />}

          {activeTab === 'APPLICATIONS' && <ApplicationsForUser />}

          {/* {this.state.activeTab === 'ACTIVITIES' && (
              <CooperationForProducts product={product} />
            )} */}
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ Users, SCF }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  user: Users.getUser.user,
  status: Users.getUser.status,
  error: Users.getUser.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUser, getCompanyByInn }, dispatch);

const UserViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(UserView)
);

export { UserViewConnect as UserView };
