import { CONTRACT_FINANCING_FORM_ACTIONS, SetContractFinancingFormData } from "../reducers/setContractFinancingFormData";

export const setContractFinancingFormData = (data: SetContractFinancingFormData) => ({
  type: CONTRACT_FINANCING_FORM_ACTIONS.SET,
  data
});

export const resetContractFinancingFormData = () => ({
  type: CONTRACT_FINANCING_FORM_ACTIONS.RESET
});
