import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCompaniesOfCompanies';
import { GET_COMPANIES_OF_COMPANIES } from '../reducers/getCompaniesOfCompanies';
import CompaniesApi from '../api';

function* getCompaniesOfCompanies(action: {
  type: GET_COMPANIES_OF_COMPANIES;
  id: string;
  data: RequestData;
}): Generator {
  try {
    let res;
    res = yield call(
      CompaniesApi.getCompaniesOfCompanies,
      action.id,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesOfCompaniesSaga(): Generator {
  yield takeLatest(GET_COMPANIES_OF_COMPANIES.REQ, getCompaniesOfCompanies);
}
