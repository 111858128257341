import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Loader } from 'Layouts/components';
import { STORE, REQUEST_STATUSES } from 'globaltypes';
import { req as getCertificate } from 'src/features/SCF/actions/getCertificate';
import {
  req as putAddSignee,
  reset as resetAddSignee
} from 'entities/SCF/Supplier/model/actions/putAddSignee';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import { ResponseDataType as ResCertificate } from 'src/features/SCF/actions/getCertificate';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { CreateLimitsNudge } from '../ApplicationInfo/styles';
import { lightGray } from 'shared/styled';
import { ContractsPage, NoData, TableContainer, TableRow } from './styled';
import { Certificate } from 'src/features/SCF/types/certificate';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  certificates: ResCertificate;
  status: REQUEST_STATUSES;
  statusAddSignee: REQUEST_STATUSES;
  investorOneDebtor: OneDebtorResponseDataType;
}

interface DispatchToProps {
  getCertificate: () => void;
  putAddSignee: (guid: string, signeeGuid: string) => void;
  resetAddSignee: () => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const PickSignee: React.FC<Props> = ({
  getCertificate,
  certificates,
  status,
  putAddSignee,
  resetAddSignee,
  statusAddSignee,
  getInvestorOneDebtor,
  investorOneDebtor,
  history,
  match
}) => {
  const [selectedItems, setSelectedItems] = React.useState({
    selectedRow: null,
    guidCert: null
  });

  React.useEffect(
    () => {
      getCertificate();
      if (match.params.id) {
        getInvestorOneDebtor({ guid: match.params.id });
      }
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      if (statusAddSignee === REQUEST_STATUSES.GOT) {
        history.push(
          `/supplier/early_payment_applications/${match.params.id}/calc`
        );

        return () => {
          resetAddSignee();
          setSelectedItems({ guidCert: null, selectedRow: null });
        };
      }
    },
    [statusAddSignee]
  );

  const handleSelect = () => {
    if (selectedItems.guidCert && match.params.id) {
      putAddSignee(match.params.id, selectedItems.guidCert);
    }
  };

  const navigateToCertificateEdit = () => {
    if (match.params.id) {
      history.push(`/supplier/settings/certificates/edit`);
    }
  };

  const handleSelectCertificate = (certificate: Certificate, index: number) => {
    if (
      new Date() <= new Date(certificate.certValidUntil) &&
      new Date() <= new Date(certificate.keyValidUntil) &&
      certificate.isAvailable !== false
    ) {
      setSelectedItems({ selectedRow: index, guidCert: certificate.guid });
    }
  };

  return (
    <ContractsPage>
      <CreateLimitsNudge>
        <h2>Выберите сертификат для подписания:</h2>
      </CreateLimitsNudge>
      <TableStyled
        sizes={[]}
        cellSpacing="0"
        cellPadding="0"
        style={{ marginTop: '30px' }}
      >
        <TableHeaderStyled>
          <tr>
            <TableHeaderCellStyled width="20%">Дебитор</TableHeaderCellStyled>
            <TableHeaderCellStyled width="20%">
              Сумма счетов
            </TableHeaderCellStyled>
            <TableHeaderCellStyled width="20%">
              Дата ранней оплаты
            </TableHeaderCellStyled>
            <TableHeaderCellStyled width="20%">ID заявки</TableHeaderCellStyled>
          </tr>
        </TableHeaderStyled>
        <tbody>
          <TableRowStyled style={{ height: '20px' }}>
            <td style={{ fontFamily: 'Roboto-Regular' }}>
              {investorOneDebtor.debtorName}
            </td>
            <td>
              {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString(
                'ru',
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              )}
            </td>

            {investorOneDebtor.payDate != null ? (
              <td>
                {format(new Date(investorOneDebtor.payDate), 'dd.MM.yyyy')}
              </td>
            ) : (
              <td />
            )}
            <td>
              {investorOneDebtor.guid.substring(
                investorOneDebtor.guid.length - 5
              )}
            </td>
          </TableRowStyled>
        </tbody>
      </TableStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && certificates.items.length > 0 ? (
        <>
          <TableContainer>
            <TableStyled
              sizes={[]}
              cellSpacing="0"
              cellPadding="0"
              style={{ marginTop: '50px' }}
            >
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    Фамилия Имя Отчество
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    Должность
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    Срок действия
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%" />
                </tr>
              </TableHeaderStyled>
              <tbody>
                {certificates.items.map((certificate, key) => (
                  <TableRow
                    key={key}
                    isSelected={selectedItems.selectedRow === key}
                    onClick={() => handleSelectCertificate(certificate, key)}
                    untilColor={
                      new Date() <= new Date(certificate.certValidUntil) &&
                      new Date() <= new Date(certificate.keyValidUntil) &&
                      certificate.isAvailable !== false
                        ? ''
                        : new Date() >= new Date(certificate.certValidUntil) &&
                          new Date() >= new Date(certificate.keyValidUntil) &&
                          certificate.isAvailable !== false
                          ? 'red'
                          : new Date() <=
                              new Date(certificate.certValidUntil) &&
                            new Date() <= new Date(certificate.keyValidUntil) &&
                            certificate.isAvailable === false &&
                            lightGray
                    }
                  >
                    <td>
                      {certificate.personSurNameSN} {certificate.personNameG}
                    </td>
                    <td>{certificate.positionT}</td>
                    <td>
                      {format(
                        new Date(certificate.certValidFrom),
                        'dd.MM.yyyy'
                      ) +
                        ' - ' +
                        format(
                          new Date(certificate.certValidUntil),
                          'dd.MM.yyyy'
                        )}
                    </td>
                    <td>
                      {new Date() <= new Date(certificate.certValidUntil) &&
                      new Date() <= new Date(certificate.keyValidUntil) &&
                      certificate.isAvailable !== false
                        ? 'Действующий'
                        : new Date() >= new Date(certificate.certValidUntil) &&
                          new Date() >= new Date(certificate.keyValidUntil) &&
                          certificate.isAvailable !== false
                          ? 'Истёк'
                          : new Date() <=
                              new Date(certificate.certValidUntil) &&
                            new Date() <= new Date(certificate.keyValidUntil) &&
                            certificate.isAvailable === false &&
                            'Недоступный'}
                    </td>
                  </TableRow>
                ))}
              </tbody>
            </TableStyled>
          </TableContainer>

          <Button
            label="Выбрать"
            disabled={
              selectedItems.selectedRow === null ||
              statusAddSignee === REQUEST_STATUSES.REQUEST
            }
            onClick={handleSelect}
            w="fit-content"
            h="40px"
          />
        </>
      ) : (
        <NoData>
          <div>Нет доступных сертификатов</div>
          <div>
            <span onClick={navigateToCertificateEdit}>Добавьте</span> новый
            сертификат в{' '}
            <span onClick={navigateToCertificateEdit}>настройках</span>
          </div>
        </NoData>
      )}
    </ContractsPage>
  );
};

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  certificates: SCF.getCertificate.data,
  status: SCF.getCertificate.status,
  statusAddSignee: SCFSupplier.putAddSignee.status,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCertificate, putAddSignee, getInvestorOneDebtor, resetAddSignee },
    dispatch
  );

const PickSigneeConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PickSignee)
);

export { PickSigneeConnect as PickSignee };
