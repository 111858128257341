import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/getSupplierCounterSidebar';
import { GET_SUPPLIER_COUNTER_SIDEBAR } from 'entities/SCF/Supplier/model/reducers/getSupplierCounterSidebar';

import SCF from 'entities/SCF/Supplier/api';

function* getSupplierCounterSidebar(): Generator {
  try {
    const res: any = yield call(SCF.getSupplierCounterSidebar);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplierCounterSidebarSaga(): Generator {
  yield takeLatest(GET_SUPPLIER_COUNTER_SIDEBAR.REQ, getSupplierCounterSidebar);
}
