import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { req as getSupplierNotificationSettings } from 'entities/SCF/Supplier/model/actions/getSupplierNotificationSettings';
import { RequestDataType as supplierSubscriptionsProps } from 'entities/SCF/Supplier/model/reducers/postSupplierNotificationSettings';
import {
  req as sendSupplierNotificationSettings,
  reset as supplierNotificationSettingsReset
} from 'entities/SCF/Supplier/model/actions/postSupplierNotificationSettings';
import SubscriptionsHeader from './SubscriptionsHeader';
import {
  SubscriptionsSettingsBlock,
  SubscriptionsSettingsRow,
  SubscriptionsSettingsTitle
} from '../styles';
import {
  SwitcherContainer,
  ChangableOffActive,
  ChangableOffInactive,
  ChangableOnActive,
  ChangableOnInactive
} from 'src/features/SCF/UI/Switcher';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

const SubscriptionsEditSupplier = ({
  getSupplierNotificationSettings,
  sendSupplierNotificationSettings,
  supplierNotificationSettings,
  postSupplierNotificationSettingsStatus,
  getSupplierNotificationSettingsStatus,
  supplierNotificationSettingsReset
}) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getSupplierNotificationSettings());

      return () => {
        supplierNotificationSettingsReset();
      };
    },
    [
      dispatch,
      getSupplierNotificationSettings,
      supplierNotificationSettingsReset
    ]
  );

  useEffect(
    () => {
      if (postSupplierNotificationSettingsStatus === REQUEST_STATUSES.GOT) {
        history.push('/supplier/settings/subscriptions');
      }
    },
    [postSupplierNotificationSettingsStatus]
  );

  const [subscriptionsState, setSubscriptionsState] = useState<
    supplierSubscriptionsProps
  >({
    newBuyerToSupplierLinkEvent:
      supplierNotificationSettings.newBuyerToSupplierLinkEvent,
    newSupplyContractLinkEvent:
      supplierNotificationSettings.newSupplyContractLinkEvent,
    newMonetaryClaimsEvent: supplierNotificationSettings.newMonetaryClaimsEvent,
    earlyPaymentApplicationStatusEvent:
      supplierNotificationSettings.earlyPaymentApplicationStatusEvent
  });

  const saveClick = (e: Event) => {
    sendSupplierNotificationSettings(subscriptionsState);
  };

  return (
    <PageNoMarginStyled>
      <SubscriptionsHeader onSaveClick={(e: Event) => saveClick(e)} />
      {getSupplierNotificationSettingsStatus === REQUEST_STATUSES.REQUEST && (
        <Loader />
      )}
      {getSupplierNotificationSettingsStatus === REQUEST_STATUSES.GOT && (
        <SubscriptionsSettingsBlock>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Покупатель добавил вас как поставщика
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newBuyerToSupplierLinkEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newBuyerToSupplierLinkEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Покупатель добавил договор с вами
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newSupplyContractLinkEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newSupplyContractLinkEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Появилось новое денежное требование для ранней оплаты
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newMonetaryClaimsEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newMonetaryClaimsEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newMonetaryClaimsEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newMonetaryClaimsEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newMonetaryClaimsEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newMonetaryClaimsEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Изменился статус по вашей заявке на раннюю оплату
            </SubscriptionsSettingsTitle>
            {subscriptionsState.earlyPaymentApplicationStatusEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      earlyPaymentApplicationStatusEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.earlyPaymentApplicationStatusEvent ===
            false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      earlyPaymentApplicationStatusEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      earlyPaymentApplicationStatusEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      earlyPaymentApplicationStatusEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
        </SubscriptionsSettingsBlock>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  supplierNotificationSettings:
    SCFSupplier.getSupplierNotificationSettings.data,
  postSupplierNotificationSettingsStatus:
    SCFSupplier.postSupplierNotificationSettings.status,
  getSupplierNotificationSettingsStatus:
    SCFSupplier.getSupplierNotificationSettings.status
});

const mapDispatchToProps = {
  getSupplierNotificationSettings: getSupplierNotificationSettings,
  sendSupplierNotificationSettings: sendSupplierNotificationSettings,
  supplierNotificationSettingsReset: supplierNotificationSettingsReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsEditSupplier);
