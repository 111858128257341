import styled from 'styled-components';

import { HeaderHeight } from 'Layouts/components/Header/styles';
import { media, BoxStyled } from 'shared/styled';

export const RegistrationPageStyled = styled.section`
  width: 100%;

  margin-top: ${HeaderHeight};

  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${media.tablet`
    flex-direction: column;
    align-items: center;
  `};
`;

export const RegistrationFormBox = styled(BoxStyled)`
  max-width: 490px;
  min-width: 400px;
  margin-left: 100px;
  padding: 40px 60px;

  ${media.desktop`
    padding: 30px;
    margin-left: 50px;
  `};

  ${media.tablet`
    margin-left: 0;
  `};

  ${media.tablet`
    width: 100%;
    max-width: auto;
    min-width: auto;
  `};
`;

export const InfoBlock = styled.div`
  max-width: 470px;
  min-width: 300px;

  padding-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h2 {
    margin-top: 0;
  }

  p {
    opacity: 0.6;
    text-align: justify;
    line-height: 1.86;
  }

  ${media.tablet`
    padding-top: 0;
  `};
`;
