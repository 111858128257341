import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import {
  req as getSummaryPerson,
  reset as resetSummaryPerson
} from 'src/features/Person/actions/getSummaryPerson';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Menu,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { SummaryPerson } from 'src/features/Person/types';
import { ClientInfoContainer, NoData } from './styled';
import { LinksWithCompanies } from './Tabs/LinksWithCompanies/LinksWithCompanies';

interface StateToProps {
  summaryPerson: SummaryPerson;
  statusSummaryPerson: REQUEST_STATUSES;
  errorSummaryPerson: ResponseError;
}

interface DispatchToProps {
  getSummaryPerson: (inn: string) => void;
  resetSummaryPerson: () => void;
}

export enum PERSON_TABS {
  DEFAULT = 'Связи с компаниями',
  PASSPORT_DATA = 'Паспортные данные'
}

interface MatchParams {
  inn: string;
  tab: keyof typeof PERSON_TABS;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Person: React.FC<Props> = ({
  getSummaryPerson,
  resetSummaryPerson,
  summaryPerson,
  statusSummaryPerson,
  errorSummaryPerson,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof PERSON_TABS>(
    'DEFAULT'
  );

  React.useEffect(() => {
    if (match.params.inn) {
      getSummaryPerson(match.params.inn);
    }

    return () => resetSummaryPerson();
  }, []);

  React.useEffect(
    () => {
      if (match.params.tab === undefined) {
        setActiveTab('DEFAULT');
      } else {
        setActiveTab(
          match.params.tab.toUpperCase() as keyof typeof PERSON_TABS
        );
      }
    },
    [match.url, match.params.tab]
  );

  const handleActiveTab = (tabName: keyof typeof PERSON_TABS) => {
    setActiveTab(tabName);

    const { tab } = match.params;

    const baseUrl = history.location.pathname.replace(
      `/${tab && tab.toLowerCase()}`,
      ''
    );

    if (tabName !== 'DEFAULT') {
      const url = baseUrl + `/${tabName.toLowerCase()}`;
      history.push(url);
    } else {
      history.push(baseUrl);
    }
  };

  return (
    <ApplicationViewStyled>
      {statusSummaryPerson === REQUEST_STATUSES.GOT ? (
        <>
          <ClientInfoContainer>
            <div>
              <ApplicationId>ИНН {match.params.inn},</ApplicationId>
            </div>

            <ApplicationTitle>{summaryPerson.personFullName}</ApplicationTitle>
          </ClientInfoContainer>

          <Menu
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            tabs={PERSON_TABS}
          />

          {activeTab === 'DEFAULT' && <LinksWithCompanies />}

          {activeTab === 'PASSPORT_DATA' && <></>}
        </>
      ) : (
        statusSummaryPerson === REQUEST_STATUSES.ERROR && (
          <NoData>
            <h3>Данные физического лица не найдены</h3>
          </NoData>
        )
      )}

      {/* <ActionResponseBlock
        showIn={
          errorCompanyByInn &&
          errorCompanyByInn.code === 403 &&
          statusCompanyByInn === REQUEST_STATUSES.ERROR
        }
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      /> */}

      {/* <ActionResponseBlock
        showIn={
          errorCompanyByInn &&
          errorCompanyByInn.code === 404 &&
          statusCompanyByInn === REQUEST_STATUSES.ERROR
        }
        template={ACTION_RESPONSE_TEMPLATE.NOT_FOUND}
      /> */}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ Person }: CRM & STORE) => ({
  summaryPerson: Person.getSummaryPerson.data,
  statusSummaryPerson: Person.getSummaryPerson.status,
  errorSummaryPerson: Person.getSummaryPerson.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getSummaryPerson, resetSummaryPerson }, dispatch);

const PersonConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Person)
);

export { PersonConnect as Person };
