import { REFRESH_API_KEY_PUBLIC } from '../reducers/refreshApiKeyPublic';

export const req = () => ({
  type: REFRESH_API_KEY_PUBLIC.REQ
});

export const got = (key: string) => ({
  type: REFRESH_API_KEY_PUBLIC.GOT,
  key
});

export const err = (error: {}) => ({
  type: REFRESH_API_KEY_PUBLIC.ERR,
  error
});
