import styled, { keyframes } from 'styled-components';
import { ButtonStyled } from 'shared/ui/Button/styled';
import { gray } from 'shared/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UploadButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  column-gap: 10px;
  margin-bottom: 15px;

  :disabled {
    background-color: ${gray};

    :hover {
      background-color: ${gray};
    }
  }

  p {
    font-size: 16px;
    color: white;
    margin: 0;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;
