import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  APPLICATION_TYPE_ROUTES,
  APPLICATION_TYPES,
  CreditingApplicationRead
} from 'Application/types';

import {
  ViewSeparatorBottom,
  EditApplicationBtn
} from 'Application/components/ApplicationView/EachApplicationView/styles';
import { STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { IApplicationDocuments } from '../EachApplicationView';
import { BorrowerCompanyDocumentsBlock } from './BorrowerCompanyDocumentsBlock/BorrowerCompanyDocumentsBlock';
import { CompanyDocumentsBlock } from './CompanyDocumentsBlock/CompanyDocumentsBlock';
import { GeneralDocumentsBlock } from './GeneralDocumentsBlock/GeneralDocumentsBlock';

interface StateToProps {
  externalApplication: Partial<CreditingApplicationRead>;
}

interface OwnProps {
  permissions: USER_PERMISSIONS[];
  documentList: ResDocumentListByApplicationId;
}

type Props = RouteComponentProps &
  StateToProps &
  IApplicationDocuments &
  OwnProps;

const DocumentsBlock: React.FC<Props> = ({
  externalApplication,
  documents,
  permissions,
  documentList,
  history
}) => (
  <React.Fragment>
    <GeneralDocumentsBlock documents={documents.general} />

    {/* Factoring Documents - Table */}
    {externalApplication.financingType === APPLICATION_TYPES.FACTORING && (
      <React.Fragment>
        <ViewSeparatorBottom>
          <EditApplicationBtn
            onClick={() =>
              history.push(
                `/cabinet/partner/application-form-${APPLICATION_TYPE_ROUTES[
                  externalApplication.financingType
                ].replace('_', '-')}/edit/${externalApplication.id}/documents`
              )
            }
          >
            Редактировать документы
          </EditApplicationBtn>
        </ViewSeparatorBottom>

        {/* TODO refactor whole eachApplication block
              * documents.company in this case is application documents
            */}
        <CompanyDocumentsBlock
          documents={documents.company}
          applicationId={externalApplication.id}
        />
      </React.Fragment>
    )}

    {/* Other Documents - Table in Tabs */}
    {/* TODO refactor */}
    {![
      APPLICATION_TYPES.FACTORING,
      APPLICATION_TYPES.PROPERTY_FINANCING
    ].includes(externalApplication.financingType) && (
      <BorrowerCompanyDocumentsBlock
        companies={documents.company}
        permissions={permissions}
        documentList={documentList}
      />
    )}
  </React.Fragment>
);

const mapStateToProps = ({ ExternalAgentRole }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data
});

const DocumentsBlockConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(DocumentsBlock)
);

export { DocumentsBlockConnect as DocumentsBlock };
