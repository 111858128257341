import { rr } from 'shared/styled';
import styled from 'styled-components';

export const SupplierInfoArea = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 30px;

  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin: 0;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    color: #000000;
  }
`;

export const SupplierInfo = styled.div`
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: ${rr};
    font-size: 1.3em;
    line-height: 140%;
    letter-spacing: 0.5px;
    margin-right: 20px;
  }
`;

export const DiscountingContainer = styled.div`
  height: 70%;

  h1 {
    font-family: ${rr};
    font-size: 1.7em;
  }

  & > div:first-of-type {
    display: flex;
    margin-bottom: 30px;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h2 {
    text-align: center;
    font-size: 24px;
    width: 500px;
  }
`;
