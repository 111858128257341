import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestData
} from 'entities/SCF/Debtor/model/actions/getDocumentForms';
import { GET_DOCUMENT_FORMS } from 'entities/SCF/Debtor/model/reducers/getDocumentForms';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDocumentForms(action: {
  type: GET_DOCUMENT_FORMS;
  data: RequestData;
}) {
  try {
    const res: any = yield call(SCFDebtorApi.getDocumentForms, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentFormsSaga() {
  yield takeLatest(GET_DOCUMENT_FORMS.REQ, getDocumentForms);
}
