import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import {
  CRM,
  REQUEST_STATUSES,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import {
  ApplicationFormStyled,
  CloseApplicationBtn,
  ApplicationStepTitle,
  ApplicationForm,
  ApplicationInputsColumn
} from '../styled';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { ResponseData as ResGetInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import {
  req as getClientsList,
  ResponseData as ClientsData,
  RequestData as ReqClientsList
} from 'src/features/Clients/actions/getClientsList';
import {
  req as sendApplicationData,
  reset as resetApplication
} from 'Application/actions/sendApplicationData';
import { RequestDataType as sendApplicationDataReq } from 'Application/reducers/sendApplicationData';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { createValidaton } from './validator';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { INPUT_RANGE_VALUE_TYPE, InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { ApplicationTypes, InnPlaceholders } from 'shared/constants';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { InputDate } from 'shared/ui/InputDate';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import {
  APPLICATION_TYPES,
  GUARANTEES_TYPE
} from 'src/features/Application/types';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { PopupContainer } from 'src/features/Layouts/components/InnPopup/styled';
import {
  ButtonContainer,
  ButtonStyle,
  ClientList,
  InputContainer,
  ModalContainer,
  PopupContentClients,
  TableRow,
  TextareaWrapper,
  TooltipBoxStyle
} from './styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { ClientsListType } from 'src/features/Clients/types';
import { Button } from 'shared/ui/Button';

interface StateToProps extends ResGetInternalApplication {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  userCompanyInn: string;
  roles: string[];
  permissions: USER_PERMISSIONS[];
  allowFlags: GetApplicationFlagsResponseDataType;
  clients: ClientsData;
  statusClients: REQUEST_STATUSES;
  statusCreateEmpty: REQUEST_STATUSES;
  statusSendApplication: REQUEST_STATUSES;
  statusGetInternalApplication: REQUEST_STATUSES;
}

interface OwnProps {
  path: string;
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  sendApplicationData: (data: sendApplicationDataReq) => void;
  resetApplication: () => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  getClientsList: (data?: ReqClientsList) => void;
}

export enum TERM_TYPE {
  DATE = 'DATE',
  PERIOD = 'PERIOD'
}

interface State {
  guaranteesType: GUARANTEES_TYPE;
  termType: TERM_TYPE;
  isInfoPopup: boolean;
  clientsIsVisible: boolean;
  code: string;
  clientCompanyName: string;
  selectedRow: number;
  financingType: APPLICATION_TYPES;
  term: number;
  termEndDate: string;
  amount: number;
  desiredBanks: string;
}

type Props = OwnProps &
  RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps;

class ContractFinancingFormStep1 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    guaranteesType: GUARANTEES_TYPE['44-FZ'],
    termType: this.props.term === 0 ? TERM_TYPE.DATE : TERM_TYPE.PERIOD,
    isInfoPopup: false,
    clientsIsVisible: false,
    code: '',
    clientCompanyName: '',
    selectedRow: null,
    financingType: null,
    term: 0,
    termEndDate: '',
    amount: 0,
    desiredBanks: ''
  };

  clientsModalRef = React.createRef();

  componentDidMount(): void {
    if (this.props.statusGetInternalApplication === REQUEST_STATUSES.GOT) {
      this.setState({
        financingType: this.props.financingType,
        code: this.props.code,
        clientCompanyName: this.props.clientCompanyName,
        amount: this.props.amount,
        guaranteesType: this.props.guaranteesType,
        termEndDate: this.props.termEndDate,
        term: this.props.term,
        desiredBanks: this.props.desiredBanks
      });
    }
    if (this.props.location.state && this.props.location.state.financingType) {
      this.setState({ financingType: this.props.location.state.financingType });
    }
    if (this.props.roles.includes('InternalAgent')) {
      this.setState({ termType: TERM_TYPE.PERIOD });
    }
    if (
      this.props.roles.includes('Applicant') ||
      this.props.roles.includes('Client')
    ) {
      this.setState({ termType: TERM_TYPE.PERIOD });

      if (!!this.props.userCompanyInn && !!this.props.userCompanyInn.length) {
        this.props.getCompanyByInn({ inn: this.props.userCompanyInn });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.code !== this.props.code) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.code !== this.state.code &&
      this.state.code &&
      this.state.code.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.code });
    }
    if (
      this.props.statusSendApplication !== prevProps.statusSendApplication &&
      this.props.statusSendApplication === REQUEST_STATUSES.GOT
    ) {
      this.props.resetApplication();
    }
    if (this.state.termType !== prevState.termType) {
      this.setState({ term: 0, termEndDate: '' });
    }
  }

  componentWillUnmount(): void {
    this.props.resetApplication();
    this.setState({
      guaranteesType: GUARANTEES_TYPE['44-FZ'],
      financingType: null,
      code: '',
      clientCompanyName: '',
      selectedRow: null,
      amount: 0,
      desiredBanks: '',
      term: 0,
      termEndDate: '',
      clientsIsVisible: false,
      isInfoPopup: false,
      termType: TERM_TYPE.PERIOD
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber = name === 'amount' || name === 'term';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (name === 'code' && sanitizedValue.length > 12) {
      return;
    } else if (name === 'code' && sanitizedValue.length <= 12) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');

      this.setState({ [name]: sanitizedValue } as any);
    } else {
      const floated = toFloatFormatter(value);

      this.setState({
        [name]: isCheckbox ? checked : isToNumber ? floated : value
      } as any);
    }
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    if (!this.isFormValid()) return;

    this.props.resetStateCompanyByInn();
    this.sendData();

    this.navigateFromTo(this.props.path, 'contract');
  };

  sendData = () => {
    this.props.sendApplicationData({
      id: +this.props.match.params.id,
      code: this.state.code,
      clientCompanyName: this.state.clientCompanyName,
      amount: this.state.amount,
      term: this.state.term,
      termEndDate: this.state.termEndDate
    });
  };

  onTermEndDateChange = (date: string) => {
    this.setState({ termEndDate: date });
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });

    this.setState({
      clientCompanyName: this.props.companyByInn.companyShortName
    });
  };

  handleOpenModal = () => {
    this.setState({ clientsIsVisible: true });
    this.props.getClientsList();
  };

  handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.clientsModalRef.current &&
      !(this.clientsModalRef.current as Element).contains(target)
    ) {
      this.setState({ clientsIsVisible: false });
    }
  };

  handleSelectClient = (client: ClientsListType, index: number) => {
    this.setState({
      selectedRow: index,
      code: client.companyINN,
      clientCompanyName: client.companyName
    });
  };

  isFormValid = (): boolean => {
    const { code, clientCompanyName, amount, term, termEndDate } = this.state;

    const { financingType } = this.props;

    const isTermValid =
      (TERM_TYPE.DATE && termEndDate) || (TERM_TYPE.PERIOD && term);

    return (
      !!financingType &&
      !!code &&
      !!clientCompanyName &&
      !!amount &&
      !!isTermValid
    );
  };

  render() {
    const {
      companyByInn,
      statusInn,
      openModal,
      financingType,
      roles,
      allowFlags: { allowBlankFields },
      code: inn
    } = this.props;

    const {
      termType,
      term,
      termEndDate,
      amount,
      code,
      clientCompanyName,
      isInfoPopup,
      desiredBanks
    } = this.state;

    // TODO extract
    const amountRange = {
      min: 0,
      max: 100000000000
    };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max]
    }).validate({ ...this.state });

    const types =
      roles.includes('Applicant') ||
      roles.includes('Client') ||
      roles.includes('InternalAgent')
        ? [
            {
              value: TERM_TYPE.PERIOD,
              label: 'Период'
            },
            {
              value: TERM_TYPE.DATE,
              label: 'Точная дата'
            }
          ]
        : [
            {
              value: TERM_TYPE.DATE,
              label: 'Точная дата'
            },
            {
              value: TERM_TYPE.PERIOD,
              label: 'Период'
            }
          ];

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        {!!inn ? (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        ) : (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        {this.props.statusCreateEmpty === REQUEST_STATUSES.REQUEST && (
          <Loader />
        )}

        <ApplicationInputsColumn>
          <ApplicationForm
            onSubmit={this.onSubmit}
            name="initialize-application-form"
          >
            <Select
              options={Object.keys(ApplicationTypes).map(type => ({
                id: type,
                name: ApplicationTypes[type]
              }))}
              value={financingType}
              label="Тип продукта"
              name="financingType"
              placeholder="Выберите тип продукта"
              required={true}
              disabled={true}
              error={errors.financingType}
              onChange={this.onChange}
            />

            <PopupContainer>
              <InputContainer>
                <Input
                  value={code}
                  label="ИНН"
                  name="code"
                  placeholder={InnPlaceholders.entity}
                  required={true}
                  error={errors.code}
                  onChange={this.onChange}
                />

                {this.props.roles.includes('ExternalAgent') && (
                  <Button
                    label="Выбрать из моих клиентов"
                    onClick={this.handleOpenModal}
                  />
                )}
              </InputContainer>

              {code && code.length < 10 ? null : statusInn ===
              REQUEST_STATUSES.ERROR ? (
                <InnPopup>
                  <h2>
                    Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                    можете создать связку с введенным ИНН
                  </h2>
                </InnPopup>
              ) : (
                statusInn === REQUEST_STATUSES.GOT &&
                code &&
                code.length >= 10 &&
                !isInfoPopup && (
                  <InnPopup
                    companyInfo={companyByInn}
                    onPopupClick={this.onPopupClick}
                  />
                )
              )}
            </PopupContainer>

            <Input
              value={clientCompanyName}
              label="Наименование клиента"
              name="clientCompanyName"
              placeholder="Введите краткое наименование клиента"
              required={true}
              error={errors.clientCompanyName}
              onChange={this.onChange}
            />

            <InputRange
              value={amount.toFixed(2)}
              label="Сумма кредита"
              name="amount"
              placeholder="Введите сумму"
              min={amountRange.min}
              max={amountRange.max}
              step={1000}
              sliderType={RANGE_SLIDER_TYPE.SUM}
              valueType={INPUT_RANGE_VALUE_TYPE.SUM}
              disableSlider={true}
              required={true}
              error={errors.amount}
              onChange={this.onChange}
            />

            <RadioGroup
              name="termType"
              label="Срок"
              template="row"
              keyValue={termType}
              onChange={this.onChange}
              radioBtns={types}
            />

            {termType === TERM_TYPE.PERIOD && (
              <InputRange
                value={term.toString()}
                name="term"
                placeholder="Введите срок"
                min={1}
                max={5 * 12}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                error={errors.term}
                onChange={this.onChange}
              />
            )}
            {termType === TERM_TYPE.DATE && (
              <InputDate
                onDateChange={this.onTermEndDateChange}
                error={errors.termEndDate}
                name="termEndDate"
                minDate={new Date()}
                dateValue={termEndDate}
                placeholder="По Датy"
              />
            )}

            {!this.props.permissions.includes(
              USER_PERMISSIONS.INTERNAL_AGENT
            ) && (
              <TextareaWrapper>
                <Textarea
                  onChange={this.onChange}
                  label="Пожелания по банкам"
                  required={!allowBlankFields}
                  error={allowBlankFields ? null : errors.desiredBanks}
                  name="desiredBanks"
                  value={desiredBanks}
                  placeholder="Напр., “любые банки” или “строго только банк XXX”"
                />
              </TextareaWrapper>
            )}

            <ButtonContainer>
              <TooltipBoxStyle
                isWarning={!this.isFormValid()}
                text="не заполнены все обязательные поля"
              >
                <ButtonStyle
                  notValid={!this.isFormValid()}
                  type="submit"
                  label="Настроить параметры заявки"
                  template="nextBtn"
                />
              </TooltipBoxStyle>
            </ButtonContainer>
          </ApplicationForm>
        </ApplicationInputsColumn>

        <ModalContainer
          visible={this.state.clientsIsVisible}
          onClick={this.handleOutsideClick}
        >
          <PopupContentClients ref={this.clientsModalRef}>
            <h2>Выберите компанию или ИП для заявки</h2>

            {this.props.statusClients === REQUEST_STATUSES.GOT && (
              <ClientList>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="50%">Наименование</TableThStyled>
                      <TableThStyled width="50%">ИНН</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {this.props.clients.items.map((client, index) => (
                      <TableRow
                        key={index}
                        isSelected={this.state.selectedRow === index}
                        onClick={() => this.handleSelectClient(client, index)}
                      >
                        <td>{client.companyName}</td>
                        <td>{client.companyINN}</td>
                      </TableRow>
                    ))}
                  </tbody>
                </TableStyled>
              </ClientList>
            )}
            <Pagination list={this.props.clients} />
            <Button
              label="Выбрать клиента"
              disabled={!this.state.code && !this.state.clientCompanyName}
            />
          </PopupContentClients>
        </ModalContainer>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({
  Application,
  InternalAgentRole,
  SCF,
  User,
  Clients
}: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  userCompanyInn: User.getUserData.data.companyInn,
  roles: User.getUserData.data.roles,
  permissions: User.getUserData.data.permissions,
  allowFlags: Application.getApplicationFlags.data,
  clients: Clients.getClientsList.data,
  statusClients: Clients.getClientsList.status,
  statusCreateEmpty: Application.createApplication.status,
  statusSendApplication: Application.sendApplicationData.status,
  ...InternalAgentRole.getInternalApplication.data,
  statusGetInternalApplication: InternalAgentRole.getInternalApplication.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      sendApplicationData,
      resetApplication,
      getCompanyByInn,
      resetStateCompanyByInn,
      getClientsList
    },
    dispatch
  );

const ContractFinancingFormStep1Connect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingFormStep1)
);

export { ContractFinancingFormStep1Connect as ContractFinancingFormStep1 };
