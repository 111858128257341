import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddSupplierStoreState extends Reducer {}

export enum POST_ADD_SUPPLIER {
  REQ = 'POST_ADD_SUPPLIER_REQ',
  GOT = 'POST_ADD_SUPPLIER_GOT',
  ERR = 'POST_ADD_SUPPLIER_ERR'
}

export const initialState: PostAddSupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddSupplier = (
  state: PostAddSupplierStoreState = initialState,
  action: {
    type: POST_ADD_SUPPLIER;
    error: {};
  }
): PostAddSupplierStoreState => {
  switch (action.type) {
    case POST_ADD_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADD_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADD_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddSupplier;
