import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseType } from '../actions/getExternalAgentPeriodList';

export interface GetExternalAgentPeriodListStoreState extends Reducer {
  data: ResponseType;
}

export enum GET_EXTERNAL_AGENT_PERIOD_LIST {
  REQ = 'GET_EXTERNAL_AGENT_PERIOD_LIST_REQ',
  GOT = 'GET_EXTERNAL_AGENT_PERIOD_LIST_GOT',
  ERR = 'GET_EXTERNAL_AGENT_PERIOD_LIST_ERR',
  RESET = 'RESET_EXTERNAL_AGENT_PERIOD_LIST'
}

export const initialState: GetExternalAgentPeriodListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getExternalAgentPeriodList = (
  state: GetExternalAgentPeriodListStoreState = initialState,
  action: {
    type: string;
    data: ResponseType;
    error: {};
  }
): GetExternalAgentPeriodListStoreState => {
  switch (action.type) {
    case GET_EXTERNAL_AGENT_PERIOD_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EXTERNAL_AGENT_PERIOD_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EXTERNAL_AGENT_PERIOD_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_EXTERNAL_AGENT_PERIOD_LIST.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getExternalAgentPeriodList;
