import { SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/signedEarlyPaymentApplication';

export const req = (signedCertificates: string, guid: string[]) => ({
  type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ,
  signedCertificates,
  guid
});

export const got = (data?: { success: boolean }[]) => ({
  type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.ERR,
  error
});

export const reset = () => ({
  type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.RESET
});
