import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getOneDiscountAuction';

export interface GetOneDiscountAuctionStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ONE_DISCOUNT_AUCTION {
  REQ = 'GET_ONE_DISCOUNT_AUCTION_REQ',
  GOT = 'GET_ONE_DISCOUNT_AUCTION_GOT',
  ERR = 'GET_ONE_DISCOUNT_AUCTION_ERR',
  RESET = 'GET_ONE_DISCOUNT_AUCTION_RESET'
}

export const initialState: GetOneDiscountAuctionStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    createdDateTime: null,
    liquidityLimit: 0,
    resultsDateTime: null,
    payDate: null,
    targetDiscountRate: 0,
    minDiscountRate: 0,
    status: null
  },
  error: {}
};

const getOneDiscountAuction = (
  state: GetOneDiscountAuctionStoreState = initialState,
  action: {
    type: GET_ONE_DISCOUNT_AUCTION;
    data: ResponseData;
    error: {};
  }
): GetOneDiscountAuctionStoreState => {
  switch (action.type) {
    case GET_ONE_DISCOUNT_AUCTION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ONE_DISCOUNT_AUCTION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ONE_DISCOUNT_AUCTION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_ONE_DISCOUNT_AUCTION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getOneDiscountAuction;
