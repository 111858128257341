import {
  GET_BANKS_LIST,
  ResponseDataType
} from 'Application/reducers/getBanksList';

export const req = () => ({
  type: GET_BANKS_LIST.REQ
});

export const got = (data: ResponseDataType) => ({
  type: GET_BANKS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_BANKS_LIST.ERR,
  error
});
