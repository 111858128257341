import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchPledge extends Reducer {}

export enum PATCH_PLEDGE_ACTIONS {
  REQ = 'PATCH_PLEDGE_REQ',
  GOT = 'PATCH_PLEDGE_GOT',
  ERR = 'PATCH_PLEDGE_ERR'
}

export const initialState: PatchPledge = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchPledge = (
  state: PatchPledge = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchPledge => {
  switch (action.type) {
    case PATCH_PLEDGE_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_PLEDGE_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_PLEDGE_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchPledge;
