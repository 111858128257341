import styled from 'styled-components';

import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { ButtonStyled } from 'src/shared/ui/Button/styled';
import { Form } from 'shared/ui/Form';
import { CrossBtn } from 'shared/styled';
import { darkGray, lightGray } from 'shared/styled';

export const CloseApplicationBtn = styled(CrossBtn)`
  width: 20px;
  height: 20px;

  top: 0;
  right: 0;
  position: absolute;
`;

export const ApplicationFormContrainer = styled.section`
  width: 100%;
  height: auto;
  padding: 0 70px;

  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const ApplicationFormStyled = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

export const ApplicationStepTitle = styled.h1`
  margin-bottom: 50px;
  color: ${darkGray};
`;

export const ApplicationFormContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ApplicationInputsColumn = styled.div`
  margin-right: 16vw;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ApplicationForm = styled(Form)`
  width: 100%;

  & > ${InputContainerStyled} {
    width: 400px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > ${ButtonStyled} {
    width: fit-content;
    margin-top: 40px;

    :disabled {
      background-color: ${lightGray};
    }
  }
`;
