import { GET_SUPPLY_CONTRACTS } from '../reducers/getSupplyContracts';

export interface SupplyContract {
    id: string;
    debtorInn: string;
    debtorName: string;
    contractName: string;
    contractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    isPerpetual: boolean;
    contraceFileIdentifyer: any;
    contraceFileName: string;
    supplierSignatureIdentifier: any;
    supplierSignatureFileName: string;
    debtorSignatureIdentifier: any;
    debtorSignatureFileName: string;
}

export interface ResponseDataType {
    page: number;
    pageSize: number;
    totalItems: number;
    items: SupplyContract[];
}

export interface RequestDataType {
    page: number;
    pageSize?: number;
    DebtorInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
    type: GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_REQ,
    data
});

export const got = (data: ResponseDataType) => ({
    type: GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_GOT,
    data
});

export const err = (error: {}) => ({
    type: GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_ERR,
    error
});
