import * as React from 'react';

import { BoxStyled, ErrorIcon } from 'shared/styled';
import store from 'store';
import { reset as resetUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';
import { Button } from 'shared/ui/Button';

// TODO test & refactor
export const Forbidden: React.FC = () => (
  <React.Fragment>
    <h1>У вас нет доступа к этой странице</h1>
    <ErrorIcon />
    <BoxStyled>
      <p>
        Если вы уверены что у вас он должен быть попробуйте перезайти
        <Button
          label="Выйти"
          onClick={() => {
            store.dispatch(resetUserData());
            store.dispatch(exit());
          }}
        />
      </p>
    </BoxStyled>
  </React.Fragment>
);
