import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getPlatforms';

import { GET_PLATFORMS } from '../reducers/getPlatforms';
import ProductsApi from '../api';

function* getPlatforms(action: { type: GET_PLATFORMS; id: number }): Generator {
  try {
    const res: any = yield call(ProductsApi.getPlatform, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPlatformsSaga(): Generator {
  yield takeLatest(GET_PLATFORMS.REQ, getPlatforms);
}
