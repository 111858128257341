import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUsers,
  faIndustry,
  faUniversity,
  faCheck,
  faTimes,
  faQuestion
} from '@fortawesome/free-solid-svg-icons';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getProductsList,
  ResponseData as ProductsData
} from 'src/features/ProductsForBank/actions/getProductsList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  HeadContainer,
  ProductClientTypeWrapper,
  ProductNameWrapper,
  ProductRevenueWrapper,
  TooltipText,
  TooltipWrapper
} from './styled';
import { history } from 'src/shared/utils/History';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  products: ProductsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  typesToFilterBy: string[];
}

interface DispatchToProps {
  getProductsList: () => void;
}

interface OwnProps {}

type Props = OwnProps & StateToProps & DispatchToProps;

const ProductsListBank: React.FC<Props> = ({
  getProductsList,
  products,
  status,
  error
}) => {
  React.useEffect(() => {
    getProductsList();
  }, []);

  const navigateToProduct = (guid: string) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/products/${guid}`);
  };

  const navigateToAddProduct = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/products/new`);
  };

  return (
    <ScrollTopComponent>
      <HeadContainer>
        <ApplicationTitle>Мои продукты</ApplicationTitle>
        <Button
          label="Добавить продукт"
          onClick={navigateToAddProduct}
          w="max-content"
          h="40px"
        />
      </HeadContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">Наименование</TableThStyled>
              <TableThStyled width="7%">Сумма продукта</TableThStyled>
              <TableThStyled width="4%">Срок, мес.</TableThStyled>
              <TableThStyled width="10%">Выручка клиента в год</TableThStyled>
              <TableThStyled width="10%">Тип клиента</TableThStyled>
              {/* <TableThStyled width="5%">Качество данных</TableThStyled> */}
            </tr>
          </TableHeaderStyled>
          <tbody>
            {products.items.map((product, key) => (
              <TableRowStyled
                key={key}
                onClick={() => navigateToProduct(product.guid)}
              >
                <td>
                  <ProductNameWrapper>
                    <div>
                      {product.productNameForBank !== null
                        ? product.productNameForBank
                        : product.financeTypeName}
                    </div>
                    <div>{product.financeTypeName}</div>
                  </ProductNameWrapper>
                </td>
                <td>
                  {formSumStringThousands(product.minSize)} -{' '}
                  {formSumStringThousands(product.maxSize)}
                </td>
                <td>
                  {product.minTermInMonthes !== null
                    ? product.minTermInMonthes
                    : 'неизв.'}{' '}
                  -{' '}
                  {product.maxTermInMonthes !== null
                    ? product.maxTermInMonthes
                    : 'неизв.'}
                </td>
                <td>
                  <ProductRevenueWrapper>
                    <div>
                      <p>
                        {product.minComfortClientRevenue !== null
                          ? formSumStringThousands(
                              product.minComfortClientRevenue
                            )
                          : 'неизв.'}{' '}
                        -{' '}
                        {product.maxComfortClientRevenue !== null
                          ? product.maxComfortClientRevenue === 0
                            ? '∞'
                            : formSumStringThousands(
                                product.maxComfortClientRevenue
                              )
                          : 'неизв.'}
                      </p>
                      <p>целевая</p>
                    </div>
                    <div>
                      <p>
                        {product.minClientRevenue !== null
                          ? formSumStringThousands(product.minClientRevenue)
                          : 'неизв.'}{' '}
                        -{' '}
                        {product.maxClientRevenue !== null
                          ? product.maxClientRevenue === 0
                            ? '∞'
                            : formSumStringThousands(product.maxClientRevenue)
                          : 'неизв.'}
                      </p>
                      <p>допустимая</p>
                    </div>
                  </ProductRevenueWrapper>
                </td>
                <td>
                  <ProductClientTypeWrapper>
                    <Tooltip text="Самозанятый">
                      <FontAwesomeIcon
                        icon={faUser}
                        size="lg"
                        color={
                          product.isForSelfEmployed === true
                            ? '#6CDCD0'
                            : '#9FA6B2'
                        }
                      />
                      {product.isForSelfEmployed === true ? (
                        <FontAwesomeIcon icon={faCheck} color="#6CDCD0" />
                      ) : product.isForSelfEmployed === false ? (
                        <FontAwesomeIcon icon={faTimes} color="#f53737" />
                      ) : (
                        <FontAwesomeIcon icon={faQuestion} color="#9FA6B2" />
                      )}
                    </Tooltip>
                    <Tooltip text="Индивидуальный предприниматель">
                      <FontAwesomeIcon
                        icon={faUsers}
                        size="lg"
                        color={
                          product.isForEnterpreneurs === true
                            ? '#6CDCD0'
                            : '#9FA6B2'
                        }
                      />
                      {product.isForEnterpreneurs === true ? (
                        <FontAwesomeIcon icon={faCheck} color="#6CDCD0" />
                      ) : product.isForEnterpreneurs === false ? (
                        <FontAwesomeIcon icon={faTimes} color="#f53737" />
                      ) : (
                        <FontAwesomeIcon icon={faQuestion} color="#9FA6B2" />
                      )}
                    </Tooltip>
                    <Tooltip text="Коммерческая компания">
                      <FontAwesomeIcon
                        icon={faIndustry}
                        size="lg"
                        color={
                          product.isForCommercialCompanies === true
                            ? '#6CDCD0'
                            : '#9FA6B2'
                        }
                      />
                      {product.isForCommercialCompanies === true ? (
                        <FontAwesomeIcon icon={faCheck} color="#6CDCD0" />
                      ) : product.isForCommercialCompanies === false ? (
                        <FontAwesomeIcon icon={faTimes} color="#f53737" />
                      ) : (
                        <FontAwesomeIcon icon={faQuestion} color="#9FA6B2" />
                      )}
                    </Tooltip>
                    <Tooltip text="Государственная компания">
                      <FontAwesomeIcon
                        icon={faUniversity}
                        size="lg"
                        color={
                          product.isForStateCompanies === true
                            ? '#6CDCD0'
                            : '#9FA6B2'
                        }
                      />
                      {product.isForStateCompanies === true ? (
                        <FontAwesomeIcon icon={faCheck} color="#6CDCD0" />
                      ) : product.isForStateCompanies === false ? (
                        <FontAwesomeIcon icon={faTimes} color="#f53737" />
                      ) : (
                        <FontAwesomeIcon icon={faQuestion} color="#9FA6B2" />
                      )}
                    </Tooltip>
                  </ProductClientTypeWrapper>
                </td>
                {/* <td>
                  <ProgressWrap percent={product.dataQuality}>
                    <figcaption>{product.dataQuality}%</figcaption>
                  </ProgressWrap>
                </td> */}
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={products} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ ProductsBank, Filters }: CRM) => ({
  products: ProductsBank.getProductsListBank.data,
  status: ProductsBank.getProductsListBank.status,
  error: ProductsBank.getProductsListBank.error,
  typesToFilterBy: Filters.updateTypeFilter.types
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProductsList }, dispatch);

const ProductsListBankConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsListBank);

export { ProductsListBankConnect as ProductsListBank };

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <TooltipWrapper
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <TooltipText>{text}</TooltipText>}
    </TooltipWrapper>
  );
};
