import { ADD_CONTACT } from '../reducers/addContact';

export const req = (activityId: string, contactId: number) => ({
  type: ADD_CONTACT.REQ,
  activityId,
  contactId
});

export const reset = () => ({
  type: ADD_CONTACT.RESET
});

export const got = data => ({
  type: ADD_CONTACT.GOT,
  data
});

export const err = (error: {}) => ({
  type: ADD_CONTACT.ERR,
  error
});
