import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Comments/actions/getCommentsList';
import {
  RequestDataType,
  GET_COMMENTS_LIST
} from 'Comments/reducers/getCommentsList';

import CommentsApi from 'Comments/api';

function* getCommentsList(action: {
  type: GET_COMMENTS_LIST;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(CommentsApi.getCommentsList, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCommentsListSaga() {
  yield takeLatest(GET_COMMENTS_LIST.REQ, getCommentsList);
}
