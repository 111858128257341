import styled from 'styled-components';
import {
  blue,
  darkBlue,
  darkestBlue,
  gray,
  lightBlue,
  navyBlue,
  rm
} from 'shared/styled';
import { shadeColor } from 'shared/utils/Utils';

export const ButtonStyled = styled.button.attrs({
  type: 'button'
})`
  width: ${({ w }) => (w ? w : '100%')};
  height: ${({ h }) => (h ? h : 'auto')};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 24px;

  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : blue};
  background-repeat: no-repeat;

  font-family: ${rm};
  font-size: 16px;
  color: #fff;

  &:disabled {
    background-color: ${gray};
  }

  &:active {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? shadeColor(backgroundColor, -60) : darkestBlue};
    color: rgb(170, 215, 255);
  }

  &:not([disabled]):hover {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? shadeColor(backgroundColor, -20) : darkBlue};
  }
`;

export const ButtonStyledReg = styled.button.attrs({
  type: 'button'
})`
  width: 100%;
  height: 49px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 24px;
  border: 3px, solid, rgba(0, 133, 254, 1);

  cursor: pointer;
  border-radius: 6px;

  font-family: ${rm};
  font-size: 16px;
  color: rgba(0, 133, 254, 1);

  &:active {
    background-color: none;
    border: 3px, solid, ${blue};
  }

  &:not([disabled]):hover {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? shadeColor(backgroundColor, 30) : lightBlue};
    color: #ffffff;
    border: none;
  }
`;

export const ButtonNext = styled(ButtonStyled)`
  background-position: calc(100% - 24px) calc(100% - 13px);

  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
      <path opacity=".9" fill="rgb(255,255,255)" d="M23 13l-5.514-5v4.072H4v1.845h13.486V18z" />
    </svg>
  ');

  &:active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
        <path opacity=".9" fill="rgb(170,215,255)" d="M23 13l-5.514-5v4.072H4v1.845h13.486V18z" />
      </svg>
    ');
  }
`;

export const ButtonAdd = styled(ButtonStyled)`
  padding: 0;
  padding-left: 35px;
  position: relative;
  justify-content: center;
  font-size: 14px;

  &::before {
    content: '';

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    background-position: calc(10%) 60%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" x="0px" y="0px" viewBox="0 0 96 120">
        <path fill="rgb(255,255,255)" d="M83.333,44.999L51,45V12.668c0-1.654-1.346-3-3-3s-3,1.346-3,3V45l-32.333,0.001c-1.654,0-3,1.346-3,3s1.346,3,3,3L45,51  v32.332c0,1.654,1.346,3,3,3s3-1.346,3-3V51l32.333-0.001c1.654,0,3-1.346,3-3S84.987,44.999,83.333,44.999z"/>
      </svg>
    ');

    transition: background-image 0.2s;
  }
  &:active::before {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" x="0px" y="0px" viewBox="0 0 96 120">
        <path fill="rgb(170,215,255)" d="M83.333,44.999L51,45V12.668c0-1.654-1.346-3-3-3s-3,1.346-3,3V45l-32.333,0.001c-1.654,0-3,1.346-3,3s1.346,3,3,3L45,51  v32.332c0,1.654,1.346,3,3,3s3-1.346,3-3V51l32.333-0.001c1.654,0,3-1.346,3-3S84.987,44.999,83.333,44.999z"/>
      </svg>
    ');
  }
`;

export const ButtonBack = styled(ButtonStyled)`
  padding-left: 30px;
  color: ${navyBlue};

  background-color: transparent;
  background-position: -7px 50%;

  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="24px" x="0px" y="0px" viewBox="0 0 26 24">
      <path fill="${navyBlue}" d="M8 12l4.644-4v3.258H24v1.475H12.644V16z"/>
    </svg>
  ');

  &:not([disabled]):hover {
    background-color: transparent;
  }

  &:active {
    color: ${gray};
    background-color: transparent;
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="24px" x="0px" y="0px" viewBox="0 0 26 24">
        <path fill="${gray}" d="M8 12l4.644-4v3.258H24v1.475H12.644V16z"/>
      </svg>
    ');
  }
`;

export const ButtonReg = styled(ButtonStyledReg)`
  background-position: calc(100% - 24px) calc(100% - 13px);
  margin-top: 20px;

  border: 3px, solid, ${blue};
  border-color: ${blue};
  background: none;
`;
