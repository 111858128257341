import { PATCH_CABINET_SET_FORBIDDEN_REGION } from '../reducers/patchCabinetSetForbiddenRegion';

export const req = (id: string, region: string) => ({
  type: PATCH_CABINET_SET_FORBIDDEN_REGION.REQ,
  id,
  region
});

export const got = () => ({
  type: PATCH_CABINET_SET_FORBIDDEN_REGION.GOT
});

export const err = (error: {}) => ({
  type: PATCH_CABINET_SET_FORBIDDEN_REGION.ERR,
  error
});
