import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/postAddTenderByEruz';

import { POST_ADD_TENDER_BY_ERUZ_CLIENT } from 'entities/Cabinet/Client/model/reducers/postAddTenderByEruz';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* postAddTenderByEruz(action: {
  type: POST_ADD_TENDER_BY_ERUZ_CLIENT;
  id: string;
  eruzId: string;
}): Generator {
  try {
    yield call(
      ClientRoleApi.postAddTenderByEruzClient,
      action.id,
      action.eruzId
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddTenderByEruzSaga(): Generator {
  yield takeLatest(POST_ADD_TENDER_BY_ERUZ_CLIENT.REQ, postAddTenderByEruz);
}
