import { REQUEST_STATUSES, Reducer } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/ExternalAgent/model/actions/getUserStats';

export interface GetUserStatsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_USER_STATS {
  REQ = 'GET_USER_STATS_REQ',
  GOT = 'GET_USER_STATS_GOT',
  ERR = 'GET_USER_STATS_ERR'
}

export const initialState: GetUserStatsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getUserStats = (
  state: GetUserStatsStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetUserStatsStoreState => {
  switch (action.type) {
    case GET_USER_STATS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_USER_STATS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_USER_STATS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getUserStats;
