import * as React from 'react';

import { COMMENT_TYPES } from 'Comments/types';
import { CommentsList, AddTopicBlock } from 'Comments/components';
import { CommentsContainer } from './styles';

interface Props {
  postComment: (data: IPostComment) => void;
  postReply: (data: IPostReply) => void;
  disabled: boolean;
}

export interface IPostComment {
  comment: string;
  files: any[];
  type: COMMENT_TYPES;
}

export interface IPostReply extends IPostComment {
  parentId: number;
}

export const defaultPostCommentProps = {
  comment: '',
  files: [],
  type: COMMENT_TYPES.NONE
};

export const defaultPostReplyProps = {
  ...defaultPostCommentProps,
  parentId: null
};

const defaultProps = {
  postComment: defaultPostCommentProps => null,
  postReply: defaultPostReplyProps => null,
  disabled: false
};

const defaultState = {
  activeCommentId: null,
  setActiveCommentId: (id: null) => null
};

export interface CommentsContextType extends Props {
  activeCommentId: number;
  setActiveCommentId: (id: number) => void;
}

export const CommentsContext = React.createContext({
  ...defaultProps,
  ...defaultState
});
export const CommentsConsumer = CommentsContext.Consumer;

export const CommentsProvider: React.FC<Props> = props => {
  const [activeCommentId, setActiveCommentId] = React.useState<number>(null);

  return (
    <CommentsContext.Provider
      value={{ ...props, activeCommentId, setActiveCommentId }}
    >
      {props.children}
    </CommentsContext.Provider>
  );
};

export const Comments: React.FC<Props> = props => (
  <CommentsProvider {...props}>
    <CommentsConsumer>
      {(context: CommentsContextType) => (
        <CommentsContainer>
          <CommentsList />
          <AddTopicBlock />
        </CommentsContainer>
      )}
    </CommentsConsumer>
  </CommentsProvider>
);
