import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getFactoringContractsSupplierRole';
import { GET_FACTORING_CONTRACTS_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getFactoringContractsSupplierRole';
import SCFApi from 'entities/SCF/Supplier/api';

function* getFactoringContractsSupplierRole(action: {
  type: GET_FACTORING_CONTRACTS_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getFactoringContractsSupplierRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractsSupplierRoleSaga() {
  yield takeLatest(
    GET_FACTORING_CONTRACTS_SUPPLIER_ROLE.GET_FACTORING_CONTRACTS_SUPPLIER_ROLE_REQ,
    getFactoringContractsSupplierRole
  );
}
