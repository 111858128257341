import { GET_ALL_OFFERS } from 'Application/reducers/getAllOffers';

export interface ResponseData {
  id: number;
  leadId: string;
  mainApplicationId: number;
  subApplicationId: string;
  offerBankStatus: number;
  offerClientStatus: any;
  offerAgentStatus: any;
  rate: number;
  price: number;
  term: any;
  termStartDate: string;
  termEndDate: string;
  timeToDecisionFrom: number;
  timeToDecisionTo: number;
  timeToDecisionType: number;
  offerCommentInternal: string;
  offerApi: string;
  apiName: string;
  offerExternalParam1: string;
  offerExternalParam1Name: string;
  offerExternalParam2: string;
  offerExternalParam2Name: string;
  offerExternalParam3: string;
  offerExternalParam3Name: string;
  offerCommentToAgent: any;
  offerCommentToClient: any;
  agentUserId: string;
  agentInn: string;
  clientUserId: any;
  clientInn: string;
  bankUserId: number;
  bankInn: string;
  bankCommissionRateToUs: number;
  bankName: string;
  productId: any;
  productType: number;
  productSum: number;
  offerVisibleToAgent: boolean;
  offerVisibleToClient: boolean;
  offerVisibleToBank: boolean;
  bankVisibleToClient: boolean;
  bankVisibleToAgent: boolean;
  clientCommissionToUs: number;
  bankCommissionToUs: number;
  ourCommissionToAgent: number;
}

export const req = (id: string) => ({
  type: GET_ALL_OFFERS.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_OFFERS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_OFFERS.ERR,
  error
});

export const reset = () => ({
  type: GET_ALL_OFFERS.RESET
});
