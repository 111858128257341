import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetSignatureFileStoreState extends Reducer {}

export enum GET_SIGNATURE_FILE {
  REQ = 'GET_SIGNATURE_FILE_REQ',
  GOT = 'GET_SIGNATURE_FILE_GOT',
  ERR = 'GET_SIGNATURE_FILE_ERR'
}

export const initialState: GetSignatureFileStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const getSignatureFile = (
  state: GetSignatureFileStoreState = initialState,
  action: {
    type: GET_SIGNATURE_FILE;
    error: {};
  }
): GetSignatureFileStoreState => {
  switch (action.type) {
    case GET_SIGNATURE_FILE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SIGNATURE_FILE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case GET_SIGNATURE_FILE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSignatureFile;
