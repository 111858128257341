import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
  faMinus
} from '@fortawesome/free-solid-svg-icons';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getUserDebtorList,
  RequestData as getUserDebtorListRequestData,
  ResponseData as getUserDebtorListResponseData
} from '../actions/getUserDebtorsList';
import { req as postAllowProcess } from 'src/features/Users/actions/postAllowProcess';
import { req as postAllowFields } from 'src/features/Users/actions/postAllowFields';
import { ScrollTopComponent } from 'src/features/Common';
import { USER_DEBTOR_LIST_TYPE } from 'src/features/UserDebtors/types';
import {
  TDEmailStyle,
  CheckboxStyle,
  TooltipBoxStyle,
  CheckboxStyleEmail,
  TDCopyTooltip,
  CopyBlock
} from './styled';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  userDebtors: getUserDebtorListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusPostAllowProcess: REQUEST_STATUSES;
  statusPostAllowFields: REQUEST_STATUSES;
}

interface DispatchToProps {
  getUserDebtorList: (data: getUserDebtorListRequestData) => void;
  postAllowProcess: (id: string, typeProcess: string) => void;
  postAllowFields: (id: string, typeProcess: string) => void;
}

interface OwnProps {
  listType: USER_DEBTOR_LIST_TYPE;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const UserDebtorList: React.FC<Props> = ({
  postAllowFields,
  postAllowProcess,
  statusPostAllowFields,
  statusPostAllowProcess,
  getUserDebtorList,
  userDebtors,
  status,
  error,
  history
}) => {
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    initFetch();
  }, []);

  React.useEffect(
    () => {
      if (
        statusPostAllowFields === REQUEST_STATUSES.GOT ||
        statusPostAllowProcess === REQUEST_STATUSES.GOT
      ) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;

        getUserDebtorList({ page, pageSize: 20 });
      }
    },
    [statusPostAllowFields, statusPostAllowProcess]
  );

  const initFetch = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    getUserDebtorList({ page, pageSize: 20 });
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Дебиторы (пользователи)</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="10%">Имя</TableThStyled>
              <TableThStyled width="10%">Телефон</TableThStyled>
              <TableThStyled width="15%">Email</TableThStyled>
              <TableThStyled width="10%">Дата регистрации</TableThStyled>
              <TableThStyled width="10%">Последний вход</TableThStyled>
              <TableThStyled width="10%">Последняя заявка</TableThStyled>
              <TableThStyled width="10%">ИНН компании</TableThStyled>
              <TableThStyled width="10%">Простой процесс</TableThStyled>
              <TableThStyled width="10%">Пустые поля и документы</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {userDebtors.items.map(debtor => (
              <TableRowStyled key={debtor.id}>
                <td>{debtor.id}</td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      debtor.firstName && !isCopied
                        ? 'Скопировать имя'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() =>
                      handleCopyClick(`${debtor.lastName} ${debtor.firstName}`)
                    }
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {debtor.lastName} {debtor.firstName}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      debtor.phoneNumber && !isCopied
                        ? 'Скопировать номер'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(debtor.phoneNumber)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {debtor.phoneNumber}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDEmailStyle>
                  <CopyBlock
                    text={
                      debtor.email && !isCopied
                        ? 'Скопировать почту'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(debtor.email)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {debtor.email}
                  </CopyBlock>
                  {debtor.emailConfirmed ? (
                    <TooltipBoxStyle textVerify={'Email подтвержден'}>
                      <CheckboxStyleEmail verify={debtor.emailConfirmed}>
                        <FontAwesomeIcon icon={faCheck} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  ) : (
                    <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                      <CheckboxStyleEmail verify={debtor.emailConfirmed}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  )}
                </TDEmailStyle>
                <td>
                  {debtor.createdDateTime &&
                    new Date(debtor.createdDateTime).toLocaleDateString()}
                </td>
                <td>
                  {debtor.lastLoginAt &&
                    new Date(debtor.lastLoginAt).toLocaleDateString()}
                </td>
                <td>
                  {debtor.lastApplicationAt &&
                    new Date(debtor.lastApplicationAt).toLocaleDateString()}
                </td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      debtor.companyInn && !isCopied
                        ? 'Скопировать ИНН'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(debtor.companyInn)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {debtor.companyInn}
                  </CopyBlock>
                </TDCopyTooltip>
                <td>
                  {debtor.allowSimpleProcess}
                  <CheckboxStyle
                    verify={debtor.allowSimpleProcess}
                    onClick={
                      debtor.allowSimpleProcess
                        ? () =>
                            postAllowProcess(debtor.id.toString(), 'disallow')
                        : () => postAllowProcess(debtor.id.toString(), 'allow')
                    }
                  >
                    {debtor.allowSimpleProcess ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
                <td>
                  {debtor.allowBlankFields}
                  <CheckboxStyle
                    verify={debtor.allowBlankFields}
                    onClick={
                      debtor.allowBlankFields
                        ? () =>
                            postAllowFields(debtor.id.toString(), 'disallow')
                        : () => postAllowFields(debtor.id.toString(), 'allow')
                    }
                  >
                    {debtor.allowBlankFields ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={userDebtors} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ UserDebtors, Users }: CRM) => ({
  userDebtors: UserDebtors.getUserDebtorList.data,
  status: UserDebtors.getUserDebtorList.status,
  error: UserDebtors.getUserDebtorList.error,
  statusPostAllowProcess: Users.postAllowProcess.status,
  statusPostAllowFields: Users.postAllowFields.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getUserDebtorList, postAllowFields, postAllowProcess },
    dispatch
  );

const UserDebtorListConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserDebtorList)
);

export { UserDebtorListConnect as UserDebtorList };
