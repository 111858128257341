import { SET_FACTORING_LIMIT_DATA } from '../reducers/setFactoringLimitFormData';

export interface FactoringLimitWrite {
  id?: number;
  debtorINN?: string;
  debtorShortName?: string;
  factorINN?: string;
  factorShortName?: string;
  volume?: number;
  setDate?: Date;
  isActual?: boolean;
  productId?: number;
  productName?: string;
  isDeleted?: boolean;
}

export interface FactoringLimitRead extends FactoringLimitWrite {
}

export interface FactoringLimitsData extends Partial<FactoringLimitWrite> { }

export const setFactoringLimitFormData = (data: FactoringLimitsData) => ({
  type: SET_FACTORING_LIMIT_DATA.SET,
  data
});

export const resetFactoringLimitFormData = () => ({
  type: SET_FACTORING_LIMIT_DATA.RESET
});
