import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchFactoringFeesOfLimitsStoreState extends Reducer {}

export enum PATCH_FACTORING_FEES_OF_LIMITS {
  REQ = 'PATCH_FACTORING_FEES_OF_LIMITS_REQ',
  GOT = 'PATCH_FACTORING_FEES_OF_LIMITS_GOT',
  ERR = 'PATCH_FACTORING_FEES_OF_LIMITS_ERR'
}

export interface RequestDataType {
  name: string;
  momentOfPayment: number;
  feeBase: number;
  feeBaseSumm: number;
  debtorINN: string;
  vatPercentage: number;
}

export const initialState: PatchFactoringFeesOfLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchFactoringFeesOfLimits = (
  state: PatchFactoringFeesOfLimitsStoreState = initialState,
  action: {
    type: PATCH_FACTORING_FEES_OF_LIMITS;
    error: {};
  }
): PatchFactoringFeesOfLimitsStoreState => {
  switch (action.type) {
    case PATCH_FACTORING_FEES_OF_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_FACTORING_FEES_OF_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_FACTORING_FEES_OF_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchFactoringFeesOfLimits;
