import styled, { createGlobalStyle } from 'styled-components';
import { blue, lighterBlue } from 'shared/styled';

export const DatePickerWrapper = styled.div`
  display: inline-block;
  max-height: 75px;

  label {
    margin-bottom: ${({ label }) => (label ? '8px' : '')};
  }
`;

export const DatePickerWrapperStyles = createGlobalStyle`
.react-datepicker{
  font-size: inherit!important;
  font-family: inherit!important;
  .react-datepicker__current-month{
    font-size: inherit!important;
  }
  .react-datepicker__header  {
    background-color: ${lighterBlue}
  }
  .react-datepicker__day, .react-datepicker__day-name{
    width: 2.5rem;
    line-height: 2.5rem;
  }
  .react-datepicker__day--highlighted {
    cursor: pointer;
    border: 1px solid ${blue} !important;
    background-color: transparent;
  }
  .paymentDate{
    border-radius: 3px;
    border: 1px solid red !important;
    color: gray
  }
  .time{
    height: 22px !important;
  }
}
`;
