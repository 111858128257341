import { FinbanksData } from '../actions/setFinbankFormData';
import { RequestData as GetFinbanksListRequestData } from '../actions/getFinbanksList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class FinbanksApi {
  static sendFinbankData(data: FinbanksData) {
    return ApiRequest('/api/banks', 'post', data);
  }

  static getFinbankByINN(inn: string) {
    return ApiRequest(`/api/banks/inn/${inn}`);
  }

  static getAllFinbanks(data: GetFinbanksListRequestData) {
    return ApiRequest('/api/banks/all', 'get', data);
  }
}
