import { ApplicationRead, Document } from 'Application/types';

export enum GUARANTEES_TYPE {
  TAX = 'TAX',
  TENDER = 'TENDER',
  LIMIT = 'LIMIT',
  '44-FZ' = 'FOURTY_FOUR_FZ',
  '223-FZ' = 'TWO_TWO_THREE_FZ',
  '615-P' = 'SIX_ONE_FIVE_P', // TODO refactor
  COMMERCIAL = 'COMMERCIAL',
  OTHER = 'OTHER'
}

export const TENDER_SEGMENT = {
  1: '44-ФЗ',
  2: '223-ФЗ',
  3: '615п',
  4: 'ГОЗ (ГосОборонЗаказ)',
  5: 'Коммерческая',
  6: '115-ФЗ',
  7: '185-ФЗ'
};

export enum UPLOAD_BLOCK_TYPE {
  DEFAULT = 'DEFAULT',
  SLIM = 'SLIM'
}

// TODO refactor none
export enum TENDER_TYPE {
  NONE = 'NONE',
  PARTICIPATION = 'PARTICIPATION',
  OBLIGATIONS = 'OBLIGATIONS',
  ADVANCE_RETURNS = 'ADVANCE_RETURNS',
  QUALITY = 'QUALITY',
  PAYMENT = 'PAYMENT',
  OTHER = 'OTHER'
}

export enum TAX_TYPE {
  NONE = 'NONE',
  NDS = 'NDS',
  CUSTOMS = 'CUSTOMS',
  REGULATION = 'REGULATION',
  OTHER = 'OTHER'
}

export enum GUARANTEES_TAX_TYPE {
  NONE = '',
  NDS = 'NDS',
  CUSTOMS = 'CUSTOMS',
  REGULATION = 'REGULATION'
}

export enum GUARANTEES_TENDER_TYPE {
  AVANCE = 'AVANCE',
  OBLIGATION = 'OBLIGATION',
  QUALITY = 'QUALITY',
  OTHER = 'OTHER'
}

export enum GUARANTEES_COMMERCIAL_TYPE {
  NONE = '',
  AVANCE = 'AVANCE',
  OBLIGATION = 'OBLIGATION',
  QUALITY = 'QUALITY',
  OTHER = 'OTHER'
}

export interface GuaranteesApplicationWrite {
  guid: string;
  guaranteesType: GUARANTEES_TYPE;
  tender: TENDER_TYPE;
  tax: TAX_TYPE;
  commercial: TENDER_TYPE;
  desiredBanks: string;
  tenderLink: string;
  tenderComment: string;
  typeComment: string;
  contractDocuments: Document[];
  refinancing?: boolean;
  startPrice: number;
  sumFinal: number;
  lotCode: string;
  subject: string;
  isClosedAuction: boolean;
  isSingleSupplier: boolean;
  taxSystem?: string;
  purchaseLink?: string;
}

export interface FileUploadBlock {
  type: string;
}

export type GuaranteesApplicationRead = ApplicationRead &
  GuaranteesApplicationWrite;
