import { GET_COMPANY_CLIENT } from 'entities/Cabinet/Client/model/reducers/getCompanyClient';

export interface ResponseData {
  inn: string;
  companyShortName: string;
  ogrn: string;
  taxSystem: "FILLED" | null;
}

export const req = (inn: string) => ({
  type: GET_COMPANY_CLIENT.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANY_CLIENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_CLIENT.ERR,
  error
});
