import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendDiscountingSettings';
import { ResponseGuid as ResponseDataType } from '../actions/sendDiscountingSettings';

export interface SendDiscountingSettingsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum SEND_DISCOUNTING_SETTINGS {
  REQ = 'SEND_DISCOUNTING_SETTINGS_REQ',
  GOT = 'SEND_DISCOUNTING_SETTINGS_GOT',
  ERR = 'SEND_DISCOUNTING_SETTINGS_ERR'
}

export const initialState: SendDiscountingSettingsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
  },
  error: {}
};

const sendDiscountingSettings = (
  state: SendDiscountingSettingsStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType
    error: {};
  }
): SendDiscountingSettingsStoreState => {
  switch (action.type) {
    case SEND_DISCOUNTING_SETTINGS.REQ:
      return {
        ...state,
        ...action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_DISCOUNTING_SETTINGS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data,
      };
    case SEND_DISCOUNTING_SETTINGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendDiscountingSettings;
