import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  ExternalCredentialsData
} from '../actions/setExternalCredentialFormData';

import { SET_EXTERNAL_CREDENTIAL_DATA } from '../reducers/setExternalCredentialFormData';
import ExternalCredentialsApi from '../api';

function* setExternalCredential(action: {
  type: SET_EXTERNAL_CREDENTIAL_DATA;
  data: ExternalCredentialsData;
}): Generator {
  try {
    yield call(ExternalCredentialsApi.sendExternalCredentialData, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(SET_EXTERNAL_CREDENTIAL_DATA.SET, setExternalCredential);
}
