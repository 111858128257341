import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetAddTenderByEruzStoreState extends Reducer {}

export enum GET_ADD_TENDER_BY_ERUZ {
  REQ = 'GET_ADD_TENDER_BY_ERUZ_REQ',
  GOT = 'GET_ADD_TENDER_BY_ERUZ_GOT',
  ERR = 'GET_ADD_TENDER_BY_ERUZ_ERR'
}

export const initialState: GetAddTenderByEruzStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const getAddTenderByEruz = (
  state: GetAddTenderByEruzStoreState = initialState,
  action: {
    type: GET_ADD_TENDER_BY_ERUZ;
    error: {};
  }
): GetAddTenderByEruzStoreState => {
  switch (action.type) {
    case GET_ADD_TENDER_BY_ERUZ.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ADD_TENDER_BY_ERUZ.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case GET_ADD_TENDER_BY_ERUZ.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAddTenderByEruz;
