import styled from 'styled-components';
import { blue, rr } from 'shared/styled';

export const ImageStyled = styled.img`
  position: absolute;
  z-index: -1;
  width: 100px;
  right: -30px;
  bottom: -25px;
  filter: invert(0.9);
`;

export const InnPopupStyled = styled.div`
  position: absolute;
  width: 400px;
  top: 110px;
  z-index: 1;
  box-shadow: 0px 2px 11px 0px rgb(0 0 0 / 20%);
  padding: 12px 15px;
  overflow: hidden;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s;

  & > div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
  }

  h2 {
    font-family: ${rr};
    font-size: 1em;
    width: 100%;
  }

  p:first-child {
    font-size: 16px !important;
    margin: 0 0 10px !important;
  }

  p {
    font-family: ${rr};
    font-size: 0.8em !important;
    width: auto;
    margin-right: 10px !important;
    margin-top: 5px !important;
  }

  &:hover {
    background-color: ${blue};
    h2,
    p {
      color: white;
    }

    ${ImageStyled} {
      mix-blend-mode: soft-light;
    }
  }
`;

export const PopupContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  & > ${InnPopupStyled} {
    top: 70px;
  }
`;
