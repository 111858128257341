import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  CheckboxLabel,
  CheckboxError,
  CheckboxStyled,
  AgreementLabelBtn
} from './styles';

type Template = 'defaultCheckbox' | 'agreementCheckbox';

export interface OwnProps {
  name: string;
  value?: string;
  label?: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  error?: string;
  template?: Template;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: any;
  className?: string;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
}

type Props = OwnProps & DispatchToProps;

const defaultProps = {
  label: '',
  value: '',
  checked: false,
  disabled: false,
  template: 'defaultCheckbox' as Template
};

const Checkbox: React.FC<Props> = ({
  name,
  value,
  label,
  checked,
  disabled,
  error,
  template,
  onChange,
  children,
  className,
  openModal
}: Props) => {
  const defineLabel = () => {
    switch (template) {
      case 'agreementCheckbox':
        return (
          <React.Fragment>
            Согласие на
            {` `}
            <AgreementLabelBtn
              onClick={() =>
                openModal({ name: MODAL_NAMES.REGISTRATION_AGREEMENT })
              }
            >
              обработку персональных данных
            </AgreementLabelBtn>
          </React.Fragment>
        );
      default:
        return label;
    }
  };
  return (
    <React.Fragment>
      <CheckboxLabel
        label={defineLabel()}
        className={!!className ? className : ''}
        disabled={disabled}
      >
        <CheckboxStyled
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          aria-labelledby={name}
        />
        <CheckboxError error={error} />
      </CheckboxLabel>

      {!!children && checked && children}
    </React.Fragment>
  );
};

Checkbox.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ openModal }, dispatch);

const CheckboxConnect = connect<null, DispatchToProps>(
  null,
  mapDispatchToProps
)(Checkbox);

export { CheckboxConnect as Checkbox };
