import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { BorrowerCompany } from 'Application/types';

export type SaveBorrowerCompaniesListStoreState = Reducer;

export enum SAVE_BORROWER_COMPANIES_LIST {
  REQ = 'SAVE_BORROWER_COMPANIES_LIST_REQ',
  GOT = 'SAVE_BORROWER_COMPANIES_LIST_GOT',
  ERR = 'SAVE_BORROWER_COMPANIES_LIST_ERR'
}

export interface RequestDataType {
  borrowerCompanies: BorrowerCompany[];
}

export const initialState: SaveBorrowerCompaniesListStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const saveBorrowerCompaniesList = (
  state: SaveBorrowerCompaniesListStoreState = initialState,
  action: {
    type: SAVE_BORROWER_COMPANIES_LIST;
    error: {};
  }
): SaveBorrowerCompaniesListStoreState => {
  switch (action.type) {
    case SAVE_BORROWER_COMPANIES_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SAVE_BORROWER_COMPANIES_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SAVE_BORROWER_COMPANIES_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default saveBorrowerCompaniesList;
