import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { ScrollTopComponent } from 'src/features/Common';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  req as getFactoringLimitsList,
  ResponseData as FactoringLimitsListResponseData,
  RequestData as FactoringLimitsListRequestData
} from '../../actions/getFactoringLimitsList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { InnPlaceholders } from 'shared/constants';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';

interface StateToProps {
  factoringLimits: FactoringLimitsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getFactoringLimitsList: (data: FactoringLimitsListRequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const FactoringLimitsList: React.FC<Props> = ({
  factoringLimits,
  status,
  error,
  getFactoringLimitsList,
  history
}) => {
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const debtorInn = searchParams.get('debtorInn') || '';

    setDebtorInn(debtorInn);

    getFactoringLimitsList({
      page,
      pageSize: 20,
      ...(debtorInn && { debtorInnToFilterBy: debtorInn })
    });
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToFactoringLimit = (id: number) => {
    history.push(`/crm/factoring_limits/${id}`);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все лимиты факторинга</ApplicationTitle>

      <FiltersStyled>
        <INNFilterContainerStyled>
          <Input
            value={debtorInn}
            label="ИНН Дебитора"
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNFilterContainerStyled>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="6%">ИНН Дебитора</TableThStyled>
              <TableThStyled width="18%">Наименование дебитора</TableThStyled>
              <TableThStyled width="6%">ИНН Фактора</TableThStyled>
              <TableThStyled width="18%">Наименование фактора</TableThStyled>
              <TableThStyled width="12%">Продукт</TableThStyled>
              <TableThStyled width="12%">Объем лимита</TableThStyled>
              <TableThStyled width="12%">Дата актуальности</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {factoringLimits.items.map(factoringLimit => (
              <TableRowStyled
                key={factoringLimit.id}
                onClick={() => navigateToFactoringLimit(factoringLimit.id)}
              >
                <td>{factoringLimit.debtorINN}</td>
                <td>{factoringLimit.debtorShortName}</td>
                <td>{factoringLimit.factorINN}</td>
                <td>{factoringLimit.factorShortName}</td>
                <td>{factoringLimit.productName}</td>
                <td>
                  {factoringLimit.volume > 0
                    ? formSumStringThousands(factoringLimit.volume)
                    : 'неизв.'}
                </td>
                <td>{factoringLimit.setDate}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={factoringLimits} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ FactoringLimits }: CRM) => ({
  factoringLimits: FactoringLimits.getFactoringLimitsList.data,
  status: FactoringLimits.getFactoringLimitsList.status,
  error: FactoringLimits.getFactoringLimitsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFactoringLimitsList }, dispatch);

const FactoringLimitsListConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringLimitsList)
);

export { FactoringLimitsListConnect as FactoringLimitsList };
