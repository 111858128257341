import { GET_BENEFICIARIES_OF_COMPANIES_PERSON } from 'src/features/Person/reducers/getBeneficiariesOfCompanies';
import { BeneficiariesOfCompaniesType } from '../types';

export const req = (inn: string) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_PERSON.REQ,
  inn
});

export const got = (data: BeneficiariesOfCompaniesType[]) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_PERSON.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_PERSON.ERR,
  error
});
