import { APPLICATION_STATUSES } from 'Application/types';
import {
  ACTION_BUTTON_TEMPLATE,
  OwnProps as ActionButtonProps
} from 'Application/components';

export const bankEmplpyeeBtnConstructor = (
  status: APPLICATION_STATUSES
): ActionButtonProps[] => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      return [
        {
          label: 'Взять в работу',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.REVIEW
        }
      ];
    case APPLICATION_STATUSES.REVIEW:
      return [
        {
          label: 'Запросить данные',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REQUEST,
          actionStatus: APPLICATION_STATUSES.DOCS_REQUEST
        },
        {
          label: 'Oтклонить',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DENIAL,
          actionStatus: APPLICATION_STATUSES.BANK_REJECT
        },
        {
          label: 'Одобрить',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.APPROVED
        }
      ];

    case APPLICATION_STATUSES.DOCS_REQUEST:
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
      return [];
    default:
      return [];
  }
};
