import { GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT } from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFileSettingsCurrent';

export interface ResponseDataType {
  fileResolution: string;
  skipFirstRow: boolean;
  supplierIdentificationPolicy: number;
  supplierInnPolicy: boolean;
  supplierInnColumn: number;
  supplyContractIdColumn: number;
  invoiceDocumentNameColumn: number;
  invoiceDocumentDateColumn: number;
  invoiceDocumentNumberColumn: number;
  baseSumPolicy: boolean;
  baseSumColumn: number;
  monetaryClaimIdColumn: number;
  baseVatSumPolicy: boolean;
  baseVatSumColumn: number;
  baseFullSumPolicy: boolean;
  baseFullSumColumn: number;
  currencyIdPolicy: boolean;
  currencyIdColumn: number;
  currencyCodePolicy: boolean;
  currencyCodeColumn: number;
  emergenceDateColumn: number;
  maturityDateColumn: number;
  statusPolicy: number;
  monetaryClaimStatusColumn: number;
  monetaryClaimPaidStatus: string;
  monetaryClaimUnpaidStatus: string;
  treatRegistryEntriesPolicy: number;
  treatRegistryExcludedPolicy: number;
  parserStartPolicy: 1 | 2;
}

export const req = () => ({
  type:
    GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_REQ
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_ERR,
  error
});
