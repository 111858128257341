import { PATCH_TENDER_UPDATE_BY_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/patchTenderUpdateByInternal';

export interface RequestData {
  CustomerINN?: string;
  SupplierINN?: string;
  PublicationDate?: string;
  StartPrice?: number;
  TenderType?: number;
  TenderSegment?: number;
  EruzId?: string;
  ProtocolDate?: string;
  HasWinner?: boolean;
  ContractConcluded?: boolean;
  ContractGuid?: string;
  TenderCompleted?: boolean;
  TenderFailed?: boolean;
  TenderLink?: string;
  WriteoffRequest?: boolean;
  Ikz?: string;
  Subject?: string;
  PurchaseRegion?: string;
  SupplierDeterminationMethod?: string;
  PlatformName?: string;
  PlatformLink?: string;
  Status?: string;
  IsClosedAuction?: boolean;
  IsSingleSupplier?: boolean;
  IsPublic?: boolean;
  SumFinal?: number;
  AuctionDate?: string;
  CustomerEmail?: string;
  CustomerPhone?: string;
  lotCode: string;
  subjectType?: number;
}

export const req = (id: string, data: RequestData) => ({
  type: PATCH_TENDER_UPDATE_BY_INTERNAL.REQ,
  id,
  data
});

export const got = () => ({
  type: PATCH_TENDER_UPDATE_BY_INTERNAL.GOT
});

export const err = (error: {}) => ({
  type: PATCH_TENDER_UPDATE_BY_INTERNAL.ERR,
  error
});
