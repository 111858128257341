import styled, { keyframes, css } from 'styled-components';
import {
  blue,
  lightBlue,
  lightGray,
  lighterBlue,
  lighterGray,
  navyBlue,
  rr
} from 'shared/styled';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { FormSection } from 'shared/ui/FormSection';
import { PopupContent } from 'src/features/Contacts/components/ContactsList/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { HeaderClients } from 'src/features/ClientsForAgent/components/styled';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'shared/ui/Button';

export const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 20px;
`;

export const RadioChildrenGroup = styled(RadioGroup)`
  padding: 10px 20px;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const PurchaseLinkInput = styled(Input)`
  margin-top: 10px;
  margin-bottom: ${({ error }) => (!!error ? '20px' : '0')};
`;

export const FormSectionStyled = styled(FormSection)`
  padding: 10px 20px;
  border-radius: 4px;
  background-color: ${lighterBlue};

  ${RadioChildrenGroup} {
    margin-top: 10px;
    padding: 10px 0;
  }
`;

export const InfoField = styled.div`
  width: 350px;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    font-family: ${rr};
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    font-family: ${rr};
    font-size: 0.9em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 400px;

  & > div {
    min-width: 400px;
  }

  & > button {
    min-width: fit-content;
    height: 40px;
    margin-top: 20px;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ClientList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0;
  overflow: auto;

  tr {
    height: 40px;
  }
`;

export const PopupContentClients = styled(PopupContent)`
  width: 550px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  *:nth-last-child(1) {
    margin-top: auto;
  }

  button {
    width: fit-content !important;
    width: 170px;
    height: 20px;
    font-size: 14px;
    justify-content: center;
  }
`;

export const PurchaseContent = styled(PopupContentClients)`
  width: auto;
  max-width: 550px;
  min-width: 250px;
`;

export const TableRow = styled(TableRowStyled)`
  background-color: ${({ isSelected }) => isSelected && blue};
  pointer-events: ${({ isSelected }) => isSelected && 'none'};

  & > td {
    color: ${({ isSelected }) => isSelected && 'white'};
  }
`;

export const ClientHeader = styled(HeaderClients)`
  h2 {
    margin: 0;
  }
`;

export const InnInputContainer = styled(INNFilterContainerStyled)`
  margin: 10px 0 0 0;
  max-width: fit-content;
`;

export const TDCompanyName = styled.td`
  font-style: ${({ isFilled }) => !isFilled && 'italic'};
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  row-gap: 10px;
`;

export const ButtonStyle = styled(Button)`
  width: 400px;

  background-color: ${({ notValid }) => notValid && lightGray};

  :hover {
    background-color: ${({ notValid }) => notValid && lightGray} !important;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  height: 19px;

  img {
    height: 19px;
  }

  ${({ isWarning, text }) =>
    isWarning &&
    `
    &:hover:before {
      content: '${text}';
      position: absolute;
      bottom: 20px;
      left: 275px;
      z-index: 100;
      background-color: ${lightGray};
      color: black;
      padding: 10px;
      border-radius: 4px;
    }
  `};
`;

export const TitleTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${lighterBlue};
  border-radius: 5px;
  padding: 5px 20px;
  height: 60px;
  margin: 15px 0 20px -40px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }

  p {
    margin: 0;
    font-size: 24px;
    color: ${navyBlue};
  }

  svg {
    cursor: pointer;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: end;
  column-gap: 20px;
  margin-bottom: 20px;

  & > div:nth-child(1) {
    width: 400px;
  }

  & > div:nth-child(2) {
    width: 300px;
  }
`;

export const TeledocContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 15px;
  background-color: ${({ isTeledoc }) => (isTeledoc ? '#0085fe' : lighterGray)};
  p {
    color: ${({ isTeledoc }) => isTeledoc && '#fff'};
  }

  svg {
    font-size: 16px;
    width: 34px !important;
    color: ${({ isTeledoc }) => (isTeledoc ? '#FFFFFF' : '#BCC1C9')};
  }

  &:hover {
    cursor: pointer;
    background-color: #0085fe;

    svg {
      color: #ffffff;
    }

    p {
      color: white;
    }
  }
`;

export const TaxSystemContainer = styled.div`
  display: flex;
  align-items: end;
  column-gap: 20px;
  margin-bottom: 20px;

  & > div {
    width: 400px;
  }

  button {
    width: fit-content;
    height: 30px;
    margin-bottom: 10px;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${rr};

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#1CCA94' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: ${({ active }) => (active === false ? 'not-allowed' : 'pointer')};

  :hover {
    border-color: ${({ active }) => active !== false && lightBlue};
    color: ${({ active }) => active !== false && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#F53737' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: ${({ active }) => (active === true ? 'not-allowed' : 'pointer')};

  :hover {
    border-color: ${({ active }) => active !== true && lightBlue};
    color: ${({ active }) => active !== true && lightBlue};
  }
`;

export const EISContainer = styled.div`
  display: flex;
  align-items: end;
  column-gap: 20px;
  margin-bottom: 20px;

  & > div {
    width: 400px;
  }

  button {
    width: fit-content;
    height: 30px;
    margin-bottom: 10px;
  }
`;

export const ProductOffersSection = styled.div`
  button {
    width: fit-content;

    :disabled {
      background-color: ${lightGray};
    }
  }
`;

export const SendApi = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  span {
    cursor: pointer;
    color: ${blue};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ButtonTeledocContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
`;

export const RefreshIcon = styled(FontAwesomeIcon)`
  ${({ refresh }) =>
    refresh &&
    css`
      animation: ${spin} 1s linear infinite;
    `};
`;

export const RejectionToggle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  p {
    font-size: 18px !important;
  }
`;
