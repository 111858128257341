import { SET_DEBTOR_DATA } from '../reducers/setDebtorFormData';

export interface DebtorWrite {
  id?: number;
  inn?: string;
  shortName?: string;
  holdingId?: number;
  holdingName?: string;
  equityOwnershipType?: number;
  isActive?: boolean;
  priority?: number;
  revenue?: number;
  costOfSales?: number;
  accountPayables?: number;
  paymentPostponement?: number;
  hasBuyerPlatform?: boolean;
  hasSellerPlatform?: boolean;
  hasDynamicDiscounting?: boolean;
  factoringMaturityLevel?: number;
  hasLoansForSellers?: boolean;
  isDeleted?: boolean;
}

export interface DebtorRead extends DebtorWrite {
}

export interface DebtorsData extends Partial<DebtorWrite> { }

export const setDebtorFormData = (data: DebtorsData) => ({
  type: SET_DEBTOR_DATA.SET,
  data
});

export const resetDebtorFormData = () => ({
  type: SET_DEBTOR_DATA.RESET
});
