import { GET_MY_LIMITS } from '../reducers/getFactoringLimits';

export interface MyLimit {
  id: number;
  debtorINN: string;
  debtorShortName: string;
  factorINN: string;
  factorShortName: string;
  volume: number;
  setDate: string;
  isActual: boolean;
  productId: number;
  productName: string;
  isDeleted: boolean;
  rate: number;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: MyLimit[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  debtorInn?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MY_LIMITS.GET_MY_LIMITS_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_MY_LIMITS.GET_MY_LIMITS_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MY_LIMITS.GET_MY_LIMITS_ERR,
  error
});
