import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddTenderByEruzClientStoreState extends Reducer {}

export enum POST_ADD_TENDER_BY_ERUZ_CLIENT {
  REQ = 'POST_ADD_TENDER_BY_ERUZ_CLIENT_REQ',
  GOT = 'POST_ADD_TENDER_BY_ERUZ_CLIENT_GOT',
  ERR = 'POST_ADD_TENDER_BY_ERUZ_CLIENT_ERR'
}

export const initialState: PostAddTenderByEruzClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddTenderByEruzClient = (
  state: PostAddTenderByEruzClientStoreState = initialState,
  action: {
    type: POST_ADD_TENDER_BY_ERUZ_CLIENT;
    error: {};
  }
): PostAddTenderByEruzClientStoreState => {
  switch (action.type) {
    case POST_ADD_TENDER_BY_ERUZ_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADD_TENDER_BY_ERUZ_CLIENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADD_TENDER_BY_ERUZ_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddTenderByEruzClient;
