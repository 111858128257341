import getFinancialDocumentsSaga from './getFinancialDocuments';
import getNonFinancialDocumentsSaga from './getNonFinancialDocuments';
import getDownloadDocumentsSaga from './getDownloadDocuments';
import getDonwloadNonFinDocumentSaga from './getDonwloadNonFinDocument';
import getDownloadSignatureSaga from './getDownloadSignature';
import getDonwloadNonFinDocumentSignatureSaga from './getDonwloadNonFinDocumentSignature';

export default [
  getFinancialDocumentsSaga,
  getNonFinancialDocumentsSaga,
  getDownloadDocumentsSaga,
  getDownloadSignatureSaga,
  getDonwloadNonFinDocumentSaga,
  getDonwloadNonFinDocumentSignatureSaga
];
