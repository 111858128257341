import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getBanksList';
import { GET_BANKS_LIST } from 'Application/reducers/getBanksList';

import ApplicationApi from 'Application/api';

function* getBanksList(action: { type: GET_BANKS_LIST }) {
  try {
    const res:any = yield call(ApplicationApi.getBanksList);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBanksListSaga() {
  yield takeLatest(GET_BANKS_LIST.REQ, getBanksList);
}
