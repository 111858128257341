import styled, { keyframes } from 'styled-components';
import {
  darkBlue,
  gray,
  lightGray,
  lighterBlue,
  rl,
  rr
} from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;

  & > ${INNField}:first-of-type {
    width: 240px;
  }
`;

export const TDMain = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  width: fit-content;
  height: 19px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: #1cca94;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    left: 100%;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const DownloadBtn = styled.a.attrs({
  download: true
})`
  width: fit-content;
  cursor: pointer;
  margin: 0;
  color: ${darkBlue};
`;

export const WrapperInputDetail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  width: 60%;

  span {
    font-size: 12px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
  }

  & > ${InnPopupStyled} {
    top: 70px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;

  & > div:first-of-type {
    margin-top: 30px;

    & > p:last-of-type {
      color: ${gray};
    }
  }

  & > div:last-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-family: ${rl};
    color: ${gray};
  }

  & > ${WrapperInputDetail} {
    margin: 40px 0 25px 0;
  }

  & > div > button {
    width: fit-content;

    &:disabled {
      background-color: ${gray};
    }
  }
`;

export const FormDataField = styled.div`
  background-color: ${lighterBlue};
  padding: 5px 15px;
  margin-bottom: 20px;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;

export const FinancialInfoContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const FinDataCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${gray};
`;
