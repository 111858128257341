import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import {
  ApplicationTypes,
  GuaranteesSubTypeId,
  GuaranteesType
} from 'shared/constants';

import {
  GUARANTEES_TYPE,
  GuaranteesApplicationRead,
  TENDER_TYPE
} from 'Application/types';

import { req as getInBankApplication } from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import { req as getExternalApplication } from 'entities/Cabinet/ExternalAgent/model/actions/getApplicationExternal';
import { RequestDataType as ReqApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';
import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { reset as resetFinancialsSummary } from 'src/features/Application/actions/getFinancialsSummary';

import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';

import { EachApplicationView } from './EachApplicationView';
import { Loader } from 'src/features/Layouts/components';
import { TenderLink, TenderComment } from './styles';

interface StateToProps {
  externalApplication: Partial<GuaranteesApplicationRead>;
  status: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getExternalApplication: (data: ReqApplicationExternal) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const GuaranteesView: React.FC<Props> = ({
  getExternalApplication,
  externalApplication,
  status,
  getInBankApplication,
  inBankApplication,
  statusInBankApplication,
  getProductApplication,
  permissions,
  resetFinancialsSummary,
  resetSelectApplicationState,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusPostSendBank,
  statusSendApi,
  statusChangeApplicationStatus,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getExternalApplication({ id });
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getExternalApplication({ id: inBankId });
        getInBankApplication({ id: +inBankId });
      }

      return () => resetFinancialsSummary();
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getExternalApplication({ id });
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getExternalApplication({ id: inBankId });
          getInBankApplication({ id: +inBankId });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getExternalApplication({ id });
        } else if (inBankId) {
          getExternalApplication({ id: inBankId });
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  if (
    status !== REQUEST_STATUSES.GOT ||
    statusInBankApplication !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  const setSubInfo = () => {
    if (externalApplication.guaranteesType === GUARANTEES_TYPE.TENDER) {
      switch (externalApplication.tender) {
        case TENDER_TYPE.OTHER:
          return [
            {
              name: 'Комментарий',
              value: (
                <TenderComment>
                  {externalApplication.tenderComment}
                </TenderComment>
              )
            }
          ];
        default:
          return [];
      }
    }

    return [];
  };

  const formGuaranteesString = () => {
    let str1 = '';
    let str2 = '';
    let str3 = '';

    if (externalApplication.tender) {
      str1 += 'Тендерная';
    } else if (externalApplication.commercial) {
      str1 += 'Коммерческая';
    } else if (externalApplication.tax) {
      str1 += 'Налоговая';
    } else {
      str1 += 'Другая';
    }

    if (
      !!externalApplication.guaranteesType &&
      GuaranteesType[externalApplication.guaranteesType] !==
        GuaranteesType['COMMERCIAL'] &&
      GuaranteesType[externalApplication.guaranteesType] !==
        GuaranteesType['OTHER']
    ) {
      str2 += ` / ${GuaranteesType[externalApplication.guaranteesType]}`;
    } else {
      str2 += '';
    }

    str3 += externalApplication.guaranteesSubTypeId
      ? ` / ${GuaranteesSubTypeId[externalApplication.guaranteesSubTypeId]}`
      : '';

    return str1 + str2 + str3;
  };

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[externalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(externalApplication.amount)
        },
        {
          name: 'Срок', // TODO REFACTOR
          value: externalApplication.term
            ? formTermString(externalApplication.term)
            : externalApplication.termStartDate &&
              externalApplication.termEndDate
              ? `${externalApplication.termStartDate.replace(
                  /\//g,
                  '.'
                )} - ${externalApplication.termEndDate.replace(/\//g, '.')}`
              : undefined
        },
        {
          name: 'ИНН',
          value: externalApplication.code
        },
        {
          name: 'Рефинансирование',
          value: externalApplication.refinancing ? 'Да' : 'Нет'
        }
      ]}
      subInfo={[
        {
          name: 'Тип гарантии',
          value: formGuaranteesString()
        },
        {
          name: 'Пожелания по банкам',
          value: externalApplication.desiredBanks
        },
        {
          name: 'Комментарии по типу гарантий',
          value: externalApplication.typeComment
        },
        {
          name: 'Ссылка на закупку',
          value: externalApplication.purchaseLink
        },
        ...(!!externalApplication.purchaseLink
          ? [
              {
                name: 'Ссылка на тендер',
                value: (
                  <TenderLink href={externalApplication.purchaseLink}>
                    {externalApplication.purchaseLink}
                  </TenderLink>
                )
              }
            ]
          : []),
        ...setSubInfo()
      ]}
      documents={{
        general: [
          ...inBankApplication.agreementDocuments,
          ...inBankApplication.groupDocuments,
          ...inBankApplication.contractDocuments
        ],
        company: inBankApplication.borrowerCompanies
      }}
    />
  );
};

const mapStateToProps = ({ Application, ExternalAgentRole, User }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  status: ExternalAgentRole.getApplicationExternal.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getExternalApplication,
      getInBankApplication,
      getProductApplication,
      resetSelectApplicationState,
      resetFinancialsSummary
    },
    dispatch
  );

const GuaranteesViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(GuaranteesView)
);

export { GuaranteesViewConnect as GuaranteesView };
