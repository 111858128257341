import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getProductsApplicationLead';

export interface GetProductApplicationLeadStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_PRODUCTS_APPLICATION_LEADS {
  REQ = 'GET_PRODUCTS_APPLICATION_LEADS_REQ',
  GOT = 'GET_PRODUCTS_APPLICATION_LEADS_GOT',
  ERR = 'GET_PRODUCTS_APPLICATION_LEADS_ERR'
}

export const initialState: GetProductApplicationLeadStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getProductApplicationLead = (
  state: GetProductApplicationLeadStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetProductApplicationLeadStoreState => {
  switch (action.type) {
    case GET_PRODUCTS_APPLICATION_LEADS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PRODUCTS_APPLICATION_LEADS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PRODUCTS_APPLICATION_LEADS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getProductApplicationLead;
