import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getApiKeyPublic';

import { GET_API_KEY_PUBLIC } from '../reducers/getApiKeyPublic';
import ApiKeysApi from '../api';

function* getApiKeyPublic(action: { type: GET_API_KEY_PUBLIC }): Generator {
  try {
    const res: any = yield call(ApiKeysApi.getApiKeyPublic);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApiKeyPublicSaga(): Generator {
  yield takeLatest(GET_API_KEY_PUBLIC.REQ, getApiKeyPublic);
}
