import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getProductsList';

import { GET_PRODUCTS_LIST } from '../reducers/getProductsList';
import ProductsApi from '../api';

function* getProductsList(action: {
  type: GET_PRODUCTS_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(ProductsApi.getAllProducts, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductsListSaga(): Generator {
  yield takeLatest(GET_PRODUCTS_LIST.REQ, getProductsList);
}
