import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'entities/SCF/Supplier/model/actions/SCFStatus';
import { SCF_STATUS_ACTIONS } from 'entities/SCF/Supplier/model/reducers/SCFStatus';
import SCFApi from 'entities/SCF/Supplier/api';

function* checkSCFStatus() {
  try {
    const res: any = yield call(SCFApi.getStatus);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* checkSCFStatusSaga() {
  yield takeLatest(SCF_STATUS_ACTIONS.REQ, checkSCFStatus);
}
