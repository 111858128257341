import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/putSendToClient';

import { PUT_SEND_TO_CLIENT } from '../reducers/putSendToClient';
import LeadsApi from '../api';

function* putSendToClient(action: {
  type: PUT_SEND_TO_CLIENT;
  guid: string;
  leadContactId: number;
}): Generator {
  try {
    yield call(LeadsApi.putSendToClient, action.guid, action.leadContactId);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putSendToClientSaga(): Generator {
  yield takeLatest(PUT_SEND_TO_CLIENT.REQ, putSendToClient);
}
