import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/putSetPreapproved';
import { PUT_SET_PREAPPROVED } from 'Application/reducers/putSetPreapproved';

import ApplicationApi from 'Application/api';

function* putSentToBank(action: { type: PUT_SET_PREAPPROVED; id: number }) {
  try {
    yield call(ApplicationApi.putSetPreapproved, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putSentToBankSaga() {
  yield takeLatest(PUT_SET_PREAPPROVED.REQ, putSentToBank);
}
