import { MODAL_ACTIONS, OpenModalDataType } from 'Modal/reducers/toggleModal';

export const openModal = (data: OpenModalDataType) => ({
  type: MODAL_ACTIONS.OPEN,
  data
});

export const closeModal = () => ({
  type: MODAL_ACTIONS.CLOSE
});
