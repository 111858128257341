import ApiRequest from 'src/shared/utils/ApiRequest';

import { RequestDataType as RequestDataTypeFactor } from 'entities/SCF/Factor/model/actions/SCFRegisterFactor';
import { RequestDataType as PostFactoringConnectionFactorRoleRequestDataType } from 'entities/SCF/Factor/model/reducers/postFactoringConnectionFactorRole';
import { RequestDataType as GetFactoringConnectionsFactorRoleRequestDataType } from 'entities/SCF/Factor/model/actions/getFactoringConnectionsFactorRole';
import { RequestDataType as GetFactorRegistryFilesRequestDataType } from 'entities/SCF/Factor/model/actions/getFactorRegistryFiles';
import { RequestDataType as GetFactoringContractsFactorRoleRequestDataType } from 'entities/SCF/Factor/model/actions/getFactoringContractsFactorRole';
import { RequestDataType as GetFactoringContractFactorRoleByGuidRequestDataType } from 'entities/SCF/Factor/model/actions/getFactoringContractFactorRoleByGuid';
import { RequestDataType as PostAgreementSignatureFactorRoleRequestDataType } from 'entities/SCF/Factor/model/reducers/postAgreementSignatureFactorRole';
import { RequestDataType as GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidRequestDataType } from 'entities/SCF/Factor/model/actions/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';
import { RequestDataType as GetEarlyPaymentApplicationsFactorRoleRequestDataType } from 'entities/SCF/Factor/model/actions/getEarlyPaymentApplicationsFactorRole';
import { RequestDataType as PutEarlyPaymentApplicationDeclineFactorRoleRequestDataType } from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';
import { RequestDataType as GetOneFactorRoleByGuidRequestDataType } from 'entities/SCF/Factor/model/actions/getOneFactorRoleByGuid';
import { RequestDataType as GetFactoringFeesOfLimitsRequestDataType } from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';
import { RequestDataType as PostFactoringFeesOfLimits } from 'entities/SCF/Factor/model/reducers/postFactoringFeesOfLimits';
import { RequestDataType as GetCurrentFactoringSettingsByDebtorInnRequestDataType } from 'entities/SCF/Factor/model/actions/getCurrentFactoringSettingsByDebtorInn';

export default class SCFFactorApi {
  static registerFactor(data: RequestDataTypeFactor) {
    return ApiRequest('/api/account/factor/register', 'post', data);
  }

  static postFactoringConnectionFactorRole(
    data: PostFactoringConnectionFactorRoleRequestDataType
  ) {
    return ApiRequest(`/api/factoring_connection/factor_role`, 'post', data);
  }

  static getFactoringConnectionsFactorRole(
    requestData: GetFactoringConnectionsFactorRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/factoring_connection/factor_role/my',
      'get',
      requestData
    );
  }

  static putFactoringConnectionFactorRoleConfirm(id: string) {
    return ApiRequest(
      `/api/factoring_connection/factor_role/confirm/${id}`,
      'put',
      id
    );
  }

  static putFactoringConnectionFactorRoleDecline(id: string) {
    return ApiRequest(
      `/api/factoring_connection/factor_role/decline/${id}`,
      'put',
      id
    );
  }

  static putFactoringConnectionFactorRoleEnableFinance(id: string) {
    return ApiRequest(
      `/api/factoring_connection/factor_role/enable_finance/${id}`,
      'put',
      id
    );
  }

  static putFactoringConnectionFactorRoleDisableFinance(id: string) {
    return ApiRequest(
      `/api/factoring_connection/factor_role/disable_finance/${id}`,
      'put',
      id
    );
  }

  static getFactorRegistryFiles(
    requestData: GetFactorRegistryFilesRequestDataType
  ) {
    return ApiRequest(
      '/api/factor_role/factor_registry/my',
      'get',
      requestData
    );
  }

  static downloadFactorRegistry(documentId: string): string {
    return `/api/factor_role/factor_registry/download_document/${documentId}`;
  }

  static downloadFactorSignature(documentId: string): string {
    return `/api/factor_role/factor_registry/download_signature/${documentId}`;
  }

  static getNewRegistriesCount() {
    return ApiRequest('/api/factor_role/factor_registry/counter/new', 'get');
  }

  static getFactoringContractsFactorRole(
    requestData: GetFactoringContractsFactorRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/factoring_contract/factor_role/my',
      'get',
      requestData
    );
  }

  static getFactoringContractFactorRoleByGuid(
    requestData: GetFactoringContractFactorRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_contract/factor_role/id/${requestData.id}`,
      'get'
    );
  }

  static downloadFactorAgreementFile(guid: string): string {
    return `/api/early_payment_applications/factor_role/${guid}/get_agreement_file`;
  }

  static downloadFactorSignatureFile(guid: string): string {
    return `/api/early_payment_applications/factor_role/${guid}/get_signature_file`;
  }

  static downloadSignatureFile(guid: string): string {
    return `/api/early_payment_applications/factor_role/${guid}/get_supplier_signature_file`;
  }

  static postAgreementSignatureFactorRole(
    data: PostAgreementSignatureFactorRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/factor_role/${
        data.guid
      }/accept_agreement`,
      'post',
      data
    );
  }

  static getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid(
    data: GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/monetary_claim/factor_role/early_payment_application/${data.guid}`,
      'get',
      data.guid
    );
  }

  static getEarlyPaymentApplicationsFactorRole(
    requestData: GetEarlyPaymentApplicationsFactorRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/early_payment_applications/factor_role/my',
      'get',
      requestData
    );
  }

  static putEarlyPaymentApplicationDeclineFactorRole(
    data: PutEarlyPaymentApplicationDeclineFactorRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/factor_role/${data.guid}/decline`,
      'put',
      data.body
    );
  }

  static getOneFactorRoleByGuid(
    requestData: GetOneFactorRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/factor_role/${requestData.guid}/get_one`,
      'get',
      requestData.guid
    );
  }

  static getStartRateFactorRole() {
    return ApiRequest('/api/users/factor_role/scf_start');
  }

  static postFactoringFeesOfLimits(
    inn: string,
    data: PostFactoringFeesOfLimits
  ) {
    return ApiRequest(
      `/api/factoring_fees_of_limits/factor_role/${inn}`,
      'post',
      data
    );
  }

  static patchFactoringFeesOfLimits(guid: string, data: any) {
    return ApiRequest(
      `/api/factoring_fees_of_limits/factor_role/${guid}`,
      'patch',
      data
    );
  }

  static deleteFactoringFeesOfLimits(guid: string) {
    return ApiRequest(
      `/api/factoring_fees_of_limits/factor_role/${guid}`,
      'delete'
    );
  }

  static getFactoringFeesOfLimits(
    inn: string,
    data: GetFactoringFeesOfLimitsRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_fees_of_limits/factor_role/${inn}`,
      'get',
      data
    );
  }

  static getFactoringConnectionById(guid: string) {
    return ApiRequest(`/api/factoring_connection/factor_role/id/${guid}`);
  }

  static getCurrentFactoringSettingsByDebtorInn(
    data: GetCurrentFactoringSettingsByDebtorInnRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_settings/${data.debtorInn}/current`,
      'get',
      data.debtorInn
    );
  }
}
