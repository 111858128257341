import { POST_CONFIRM_CONTRACT_BY_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/postConfirmContractBySupplier';

export interface RequestData {
  type: number;
  action: 'confirm' | 'reject';
}

export const req = (
  accountGuid: string,
  contractGuid: string,
  data: RequestData
) => ({
  type: POST_CONFIRM_CONTRACT_BY_SUPPLIER.REQ,
  accountGuid,
  contractGuid,
  data
});

export const got = () => ({
  type: POST_CONFIRM_CONTRACT_BY_SUPPLIER.GOT
});

export const err = (error: {}) => ({
  type: POST_CONFIRM_CONTRACT_BY_SUPPLIER.ERR,
  error
});
