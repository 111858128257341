import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/addParticipant';

import { ADD_PARTICIPANT } from '../reducers/addParticipant';
import ActivitiesApi from '../api';

function* addParticipant(action: {
  type: ADD_PARTICIPANT;
  activityId: string;
  contactId: number;
}): Generator {
  try {
    let res: any;

    res = yield call(
      ActivitiesApi.addParticipant,
      action.activityId,
      action.contactId
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addParticipantSaga(): Generator {
  yield takeLatest(ADD_PARTICIPANT.REQ, addParticipant);
}
