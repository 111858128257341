import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestDataType as GetAgentListRequestDataType } from '../actions/getExternalAgentsList';
import { RequestDataType as ConfirmAgentRequestType } from '../actions/confirmExternalAgent';

export default class ManageExternalAgentsApi {
  static getExternalAgentsList(requestData: GetAgentListRequestDataType) {
    return ApiRequest('/api/agentmanagers/externalagents', 'get', requestData);
  }

  static confirmExternalAgent(requestData: ConfirmAgentRequestType) {
    return ApiRequest(
      `/api/agentmanagers/externalagents/${requestData.id}/statuses/${
        requestData.status
      }`,
      'put'
    );
  }
  static toggleLeadogeneratorPermission(userId: number){
    return ApiRequest(`/api/leadogenerator/toggle/${userId}`, 'put');
 }
}
