import { GET_USER } from '../reducers/getUser';
import { UserData } from 'src/features/Users/types';

export interface ResponseData {
  user: UserData;
}

export const req = (id: string) => ({
  type: GET_USER.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_USER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_USER.ERR,
  error
});
