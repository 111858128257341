import { SEND_DISCOUNTING_SETTINGS } from '../reducers/sendDiscountingSettings';
import { DiscountingSettingsWrite } from './setDiscountingSettings';

export interface RequestData extends DiscountingSettingsWrite {
}

export interface ResponseGuid {
  guid: string;
}

export const req = (data: RequestData) => ({
  type: SEND_DISCOUNTING_SETTINGS.REQ,
  data
});

export const got = (data: ResponseGuid) => ({
  type: SEND_DISCOUNTING_SETTINGS.GOT,
  data
});

export const err = (error: {}) => ({
  type: SEND_DISCOUNTING_SETTINGS.ERR,
  error
});
