import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchEditPersonStoreState extends Reducer {}

export enum PATCH_EDIT_PERSON {
  REQ = 'PATCH_EDIT_PERSON_REQ',
  GOT = 'PATCH_EDIT_PERSON_GOT',
  ERR = 'PATCH_EDIT_PERSON_ERR'
}

export const initialState: PatchEditPersonStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchEditPerson = (
  state: PatchEditPersonStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchEditPersonStoreState => {
  switch (action.type) {
    case PATCH_EDIT_PERSON.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_EDIT_PERSON.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_EDIT_PERSON.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchEditPerson;
