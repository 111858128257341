import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetQtySidebarStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  extAppQty: number;
  myAppQty: number;
  myDraftQty: number;
  myTrashQty: number;
  myLeadsQty: number;
  myClientsQty: number;
  myUsersQty: number;
}

export enum GET_QTY_SIDEBAR {
  REQ = 'GET_QTY_SIDEBAR_REQ',
  GOT = 'GET_QTY_SIDEBAR_GOT',
  ERR = 'GET_QTY_SIDEBAR_ERR'
}

export const initialState: GetQtySidebarStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    extAppQty: null,
    myAppQty: null,
    myDraftQty: null,
    myTrashQty: null,
    myLeadsQty: null,
    myClientsQty: null,
    myUsersQty: null
  },
  error: {}
};

const getQtySidebar = (
  state: GetQtySidebarStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetQtySidebarStoreState => {
  switch (action.type) {
    case GET_QTY_SIDEBAR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_QTY_SIDEBAR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_QTY_SIDEBAR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getQtySidebar;
