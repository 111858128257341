import * as React from 'react';

import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';
import { EachPage } from 'src/pages/Cabinet/Bank/EachPage';

export const CabinetApproved: React.FC = () => (
  <EachPage
    cabinetLists={[
      APPLICATIONS_LIST_TYPE.MY,
      APPLICATIONS_LIST_TYPE.DEAL,
      APPLICATIONS_LIST_TYPE.DRAFT
    ]}
  />
);
