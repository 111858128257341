import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getContactsListByProduct,
  ResponseData as getContactsListByProductResponseData,
  RequestData as getContactsListByProductRequestData
} from '../../actions/getContactsListByProduct';
import { history } from 'src/shared/utils/History';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getContactsList,
  ResponseData as ContactsData
} from 'src/features/Contacts/actions/getContactsList';
import {
  req as postContact,
  RequestData as PostContactData
} from 'src/features/ContactsOfProducts/actions/postContactByProduct';
import {
  PopupContainerContact,
  PopupContentContact
} from 'src/features/ContactsOfProducts/components/ContactsOfProductsList/styled';
import { ContactList } from 'src/features/Activities/components/ActivitiesView/styled';
import { Button } from 'shared/ui/Button';

interface State {
  popupIsVisible: boolean;
}

interface StateToProps {
  contactsOfProducts: getContactsListByProductResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  contacts: ContactsData;
  statusContacts: REQUEST_STATUSES;
  statusCreateContact: REQUEST_STATUSES;
}

interface DispatchToProps {
  getContactsListByProduct: (data: getContactsListByProductRequestData) => void;
  getContactsList: (inn: string) => void;
  postContact: (data: PostContactData) => void;
}

interface OwnProps {
  productId: number;
  bankInn: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ContactsListByProduct extends React.Component<Props, State> {
  state: State = {
    popupIsVisible: false
  };

  contactModalRef = React.createRef();

  componentDidMount() {
    const { productId, getContactsListByProduct } = this.props;

    // TODO duplication with applicationslist
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getContactsListByProduct({ page, pageSize: 20, productId });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { productId, getContactsListByProduct } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    if (prevProps.statusCreateContact !== this.props.statusCreateContact) {
      getContactsListByProduct({ page, pageSize: 20, productId });
    }
  }

  onContactsOfProductsClick(index: number) {
    history.push(
      `/crm/contacts/${this.props.contactsOfProducts.items[index].contactId}`
    );
  }

  openModalPlatform = e => {
    e.stopPropagation();

    this.setState({ popupIsVisible: true });

    this.props.getContactsList(this.props.bankInn);
  };

  navigateToCreateContact = () => {
    history.push(`/crm/contacts/new`);
  };

  handleOutsideClickContact = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.contactModalRef.current &&
      !(this.contactModalRef.current as Element).contains(target)
    ) {
      this.setState({ popupIsVisible: false });
    }
  };

  handleAddContact = (contactId: number) => {
    this.props.postContact({ contactId, productId: this.props.productId });
    this.setState({ popupIsVisible: false });
  };

  render() {
    const {
      contactsOfProducts,
      status,
      error,
      contacts,
      statusContacts
    } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="3%">ID</TableThStyled>
                <TableThStyled width="12%">ФИО</TableThStyled>
                <TableThStyled width="8%">Роль</TableThStyled>
                <TableThStyled width="8%">Действующий</TableThStyled>
                <TableThStyled width="69%">Комментарий</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {contactsOfProducts.items.map((contactOfProduct, key) => (
                <TableRowStyled
                  onClick={() => this.onContactsOfProductsClick(key)}
                  key={key}
                >
                  <td>{contactOfProduct.contactId}</td>
                  <td>
                    {contactOfProduct.firstName} {contactOfProduct.lastName}
                  </td>
                  <td />
                  <td />
                  <td>{contactOfProduct.comment}</td>
                </TableRowStyled>
              ))}
            </tbody>
            <Button label="+ Добавить" onClick={this.openModalPlatform} w='140px' h='40px' />
            <PopupContainerContact
              visible={this.state.popupIsVisible}
              onClick={this.handleOutsideClickContact}
            >
              <PopupContentContact ref={this.contactModalRef}>
                <h4>Выберите контакт куратора</h4>
                {statusContacts === REQUEST_STATUSES.REQUEST && <Loader />}
                {statusContacts === REQUEST_STATUSES.GOT && (
                  <>
                    <ContactList>
                      {contacts.items.map(contact => (
                        <li
                          key={contact.id}
                          onClick={() => this.handleAddContact(contact.id)}
                        >
                          {contact.lastName} {contact.firstName}{' '}
                          {contact.middleName} {contact.position}
                        </li>
                      ))}
                    </ContactList>
                    <div>
                      <p>Нет в списке?</p>
                      <Button
                        label="+ Создать новый контакт"
                        onClick={this.navigateToCreateContact}
                      />
                    </div>
                    <Pagination list={contacts} />
                  </>
                )}
              </PopupContentContact>
            </PopupContainerContact>
          </TableStyled>
        )}
        <Pagination list={contactsOfProducts} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ ContactsOfProducts, Contacts }: CRM) => ({
  contactsOfProducts: ContactsOfProducts.getContactsListByProduct.data,
  status: ContactsOfProducts.getContactsListByProduct.status,
  error: ContactsOfProducts.getContactsListByProduct.error,
  contacts: Contacts.getContactsList.data,
  statusContacts: Contacts.getContactsList.status,
  statusCreateContact: ContactsOfProducts.postContactByProduct.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getContactsListByProduct, getContactsList, postContact },
    dispatch
  );

const ContactsListByProductConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContactsListByProduct);

export { ContactsListByProductConnect as ContactsListByProduct };
