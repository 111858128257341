import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { SummaryPerson } from '../types';

export interface GetSummaryPersonStoreState extends Reducer {
  data: SummaryPerson;
}

export enum GET_SUMMARY_PERSON {
  REQ = 'GET_SUMMARY_PERSON_REQ',
  GOT = 'GET_SUMMARY_PERSON_GOT',
  ERR = 'GET_SUMMARY_PERSON_ERR',
  RESET = 'GET_SUMMARY_PERSON_RESET'
}

export const initialState: GetSummaryPersonStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    personFullName: '',
    personINN: ''
  },
  error: {}
};

const getSummaryPerson = (
  state: GetSummaryPersonStoreState = initialState,
  action: {
    type: GET_SUMMARY_PERSON;
    data: SummaryPerson;
    error: {};
  }
): GetSummaryPersonStoreState => {
  switch (action.type) {
    case GET_SUMMARY_PERSON.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUMMARY_PERSON.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUMMARY_PERSON.RESET:
      return {
        ...initialState
      };
    case GET_SUMMARY_PERSON.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSummaryPerson;
