import { GET_CLIENT_BENEFICIARIES } from '../reducers/getBeneficiaries';
import { BeneficiariesTypes } from 'src/features/Beneficiaries/types';

export const req = (inn: string) => ({
  type: GET_CLIENT_BENEFICIARIES.REQ,
  inn
});

export const got = (data: BeneficiariesTypes[]) => ({
  type: GET_CLIENT_BENEFICIARIES.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_CLIENT_BENEFICIARIES.ERR,
  err
});
