import styled from 'styled-components';

import { LoaderContainer } from 'shared/styled';
import { rl, rr, gray, navyBlue } from 'shared/styled';

export const CommentsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const LoaderContainerStyled = styled(LoaderContainer)`
  height: 100%;
  padding-top: 200px;
  align-items: flex-start;
`;
