import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { rr } from 'shared/styled';

export const SettingsPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: auto;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const SettingsChoiceStyled = styled.div`
  width: auto;
  height: auto;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SettingsButtonChosen = styled.a`
  padding: 10px 0;
  display: block;
  font-family: ${rr};
  width: auto;
  height: auto;
  text-align: start;
  font-size: 16px;
  cursor: pointer;
  color: #000AFE;
  margin-right: 20px;
`;

export const SettingsButtonNotChosen = styled.a`
  padding: 10px 0;
  display: block;
  font-family: ${rr};
  width: auto;
  height: auto;
  text-align: start;
  font-size: 16px;
  cursor: pointer;
  color: #7A7D83;
  margin-right: 20px;
`;
