import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { ValidatorMessages } from 'shared/constants';
import { emailRule } from 'src/shared/utils/ValidationRules';
import { State } from 'User/components';

export const createValidation = () =>
  new Validator<State>({
    email: [
      {
        rule: requiredRule,
        message: ValidatorMessages.emailRequire
      },
      {
        rule: emailRule,
        message: ValidatorMessages.emailValid
      }
    ]
  });
