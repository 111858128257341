import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getCompanyHints';
import {
  GET_COMPANY_HINTS,
  ResponseData
} from 'Application/reducers/getCompanyHints';

import ApplicationApi from 'Application/api';

function* getCompanyHints(action: { type: GET_COMPANY_HINTS; inn: string }) {
  try {
    const res: ResponseData = yield call(
      ApplicationApi.getCompanyHints,
      action.inn
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyHintsSaga() {
  yield takeLatest(GET_COMPANY_HINTS.REQ, getCompanyHints);
}
