import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';

export default class DocumentListApi {
  static getDocumentList(token: string) {
    return ApiRequest(`/api/document_list?token=${token}`);
  }

  static postFinFile(token: string, data: FormData) {
    return ApiUploadFiles(
      `/api/financial_document/upload_file?token=${token}`,
      data
    );
  }

  static postNotFinFile(token: string, data: FormData) {
    return ApiUploadFiles(
      `/api/nonfinancial_document/upload_file?token=${token}`,
      data
    );
  }
}
