import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { STORE } from 'globaltypes';
import { SentryErrorCatcher } from 'App/components';

interface StateProps {
  authorized: boolean;
}

interface OwnProps {
  component: any;
  path: string;
}

type Props = StateProps & OwnProps;

const PrivatePage = ({ authorized, component: Component, ...rest }: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        authorized ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  </SentryErrorCatcher>
);

const PrivateSupplierPage = ({
  authorized,
  component: Component,
  ...rest
}: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        authorized ? <Component {...props} /> : <Redirect to="/scf" />
      }
    />
  </SentryErrorCatcher>
);

const PrivateDebtorPage = ({
  authorized,
  component: Component,
  ...rest
}: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        authorized ? <Component {...props} /> : <Redirect to="/scf" />
      }
    />
  </SentryErrorCatcher>
);

const PrivateFactorPage = ({
  authorized,
  component: Component,
  ...rest
}: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        authorized ? <Component {...props} /> : <Redirect to="/scf" />
      }
    />
  </SentryErrorCatcher>
);

const mapStateToProps = ({ Cached }: STORE): StateProps => ({
  authorized: Cached.authorized
});

const PrivateConnect = connect<StateProps>(mapStateToProps)(PrivatePage);
export { PrivateConnect as PrivatePage };

const PrivateSupplierConnect = connect<StateProps>(mapStateToProps)(
  PrivateSupplierPage
);
export { PrivateSupplierConnect as PrivateSupplierPage };

const PrivateDebtorConnect = connect<StateProps>(mapStateToProps)(
  PrivateDebtorPage
);
export { PrivateDebtorConnect as PrivateDebtorPage };

const PrivateFactorConnect = connect<StateProps>(mapStateToProps)(
  PrivateFactorPage
);
export { PrivateFactorConnect as PrivateFactorPage };
