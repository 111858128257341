import styled from 'styled-components';
import {
  blue,
  gray,
  lightGray,
  lighterBlue,
  rr
} from 'shared/styled';

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;
`;

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;

export const CheckboxStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : gray)};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    left: 30px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TDStyled = styled.td`
  overflow: visible !important;
  font-family: ${rr} !important;
`;

export const LinkNotSet = styled.div`
  color: ${blue};
  text-decoration: underline;
`;

export const DiscountMargin = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > div:first-of-type {
    width: 25%;
  }

  input {
    height: 40px;
  }

  & > div:last-of-type {
    font-size: 34px;
    color: ${blue};
  }
`;
