import ApiRequest from "src/shared/utils/ApiRequest";
import ApiUploadFiles from "src/shared/utils/ApiUploadFiles";
import { PropertyDocumentsRequestData } from "../reducers/uploadPropertyDocuments";
import { PropertyRead, PropertyWrite } from "../types/Property";

export default class PropertiesApi {

  static deleteProperty(propertyId:number) {
    return ApiRequest(`/api/propertyFinancingProperties/delete/${propertyId}`,'delete');
  }

  static addProperty(property:Partial<PropertyRead>) {
    return ApiRequest(`/api/propertyFinancingProperties/add/${property.applicationId}`,'post',property);
  }

  static updateProperty(property:Partial<PropertyRead>) {
    return ApiRequest(`/api/propertyFinancingProperties/update/${property.id}`,'post',property);
  }

  static uploadPropertyDocuments(document: any) {
    return ApiUploadFiles(`/api/documents/property/${document.propertyId}?metaInfo=${document.metaInfo}`,document.file);
  }

  static getProperties(applicationId: number) {
    return ApiRequest(`/api/propertyFinancingProperties/all/${applicationId}`);
  }

}
