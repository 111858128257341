import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ResponseData as PledgesData } from 'src/features/Clients/actions/getPledges';
import { ApplicationTypes } from 'shared/constants';
import { TRWrapper } from 'src/features/BankProducts/components/Tabs/CreditProducts/styled';

interface StateToProps {
  pledges: PledgesData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {}

interface DispatchToProps {}

interface MatchParams {}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class AccountProducts extends React.Component<Props, State> {
  navigateToCompaniesProduct = (clientInn: string) => {
    window.open(`/crm/companies/${clientInn}/bank_products`, '_blank');
  };

  render() {
    const { pledges, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="10%">ИНН клиента</TableThStyled>
                  <TableThStyled width="20%">Наименование</TableThStyled>
                  <TableThStyled width="20%">Номер счета</TableThStyled>
                  <TableThStyled width="20%">БИК</TableThStyled>
                  <TableThStyled width="20%">
                    Корреспондентский счет
                  </TableThStyled>
                  <TableThStyled width="10%">Валюта</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {pledges.items.map((product, index) => (
                  <TRWrapper
                    key={index}
                    isActive={product.isActive}
                    onClick={() =>
                      this.navigateToCompaniesProduct(product.clientInn)
                    }
                  >
                    {/* <td>{product.clientInn}</td>
                    <td>{product.clientName}</td>
                    <td>{product.}</td>
                    <td>
                      {
                        Object.values(ApplicationTypes)[
                          product.productTypeID - 1
                        ]
                      }
                    </td>
                    <td>{product.rate ? product.rate : 'Неизвестно'}</td>
                    <td>{product.source}</td> */}
                  </TRWrapper>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={pledges} />
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const AccountProductsConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountProducts)
);

export { AccountProductsConnect as AccountProducts };
