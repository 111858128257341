import { GET_SIGNATURE_FILE } from '../reducers/getSignatureFile';

export const req = (guid: string) => ({
  type: GET_SIGNATURE_FILE.REQ,
  guid
});

export const got = () => ({
  type: GET_SIGNATURE_FILE.GOT
});

export const err = (error: {}) => ({
  type: GET_SIGNATURE_FILE.ERR,
  error
});
