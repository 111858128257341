export interface FinancialStatementsType {
  id: number;
  year: number;
  taxPeriodCode: number;
}

export interface PeriodListType {
  id?: string;
  isEdit?: boolean;
  year: number;
  taxPeriodCode: number;
  acсessType?: 'Closed' | 'Open' | 'Accessible';
  type?: number;
}

export enum FINANCILASTATEMENTS_TABS {
  DEFAULT = 'Скрывать пустые строки',
  EMPTY_FIELDS = 'Отображать пустые строки'
}

export type Colors = '#9FA6B2' | '#0085FE' | '#1CCA94' | '#f0cd74';

export const financialStatementActiveLabels = [
  { name: 'АКТИВ', subName: 'I. ВНЕОБОРОТНЫЕ АКТИВЫ', required: true },
  { name: 'Нематериальные активы', code: 1110 },
  { name: 'Результаты исследований и разработок', code: 1120 },
  { name: 'Нематериальные поисковые активы', code: 1130 },
  { name: 'Материальные поисковые активы', code: 1140 },
  { name: 'Основные средства', code: 1150 },
  { name: 'Доходные вложения в материальные ценности', code: 1160 },
  { name: 'Финансовые вложения', code: 1170 },
  { name: 'Отложенные налоговые активы', code: 1180 },
  { name: 'Прочие внеоборотные активы', code: 1190 },
  { name: 'Итого по разделу I', code: 1100 },
  { subName: 'II. ОБОРОТНЫЕ АКТИВЫ', required: true },
  { name: 'Запасы', code: 1210 },
  {
    name: 'Налог на добавленную стоимость по приобретенным ценностям',
    code: 1220
  },
  { name: 'Дебиторская задолженность', code: 1230 },
  {
    name: 'Финансовые вложения (за исключением денежных эквивалентов)',
    code: 1240
  },
  { name: 'Денежные средства и денежные эквиваленты', code: 1250 },
  { name: 'Прочие оборотные активы', code: 1260 },
  { name: 'Итого по разделу II', code: 1200 },
  { name: 'БАЛАНС', code: 1600 }
];

export const financialStatementPassiveLabels = [
  { name: 'ПАССИВ', subName: 'III. КАПИТАЛ И РЕЗЕРВЫ', required: true },
  {
    name:
      'Уставный капитал (складочный капитал, уставной фонд, вклады товарищей)',
    code: 1310
  },
  { name: 'Собственные акции, выкупленные у акционеров', code: 1320 },
  { name: 'Переоценка внеоборотных активов', code: 1340 },
  { name: 'Добавочный капитал (без переоценки)', code: 1350 },
  { name: 'Резервный капитал', code: 1360 },
  { name: 'Нераспределенная прибыль (непокрытый убыток)', code: 1370 },
  { name: 'Итого по разделу III', code: 1300 },
  { subName: 'IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', required: true },
  { name: 'Заемные средства', code: 1410 },
  { name: 'Отложенные налоговые обязательства', code: 1420 },
  { name: 'Оценочные обязательства', code: 1430 },
  { name: 'Прочие обязательства', code: 1450 },
  { name: 'Итого по разделу IV', code: 1400 },
  { subName: 'V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА', required: true },
  { name: 'Заемные средства', code: 1510 },
  { name: 'Кредиторская задолженность', code: 1520 },
  { name: 'Доходы будущих периодов', code: 1530 },
  { name: 'Оценочные обязательства', code: 1540 },
  { name: 'Прочие обязательства', code: 1550 },
  { name: 'Итого по разделу V', code: 1500 },
  { name: 'БАЛАНС', code: 1700 }
];

export const OPIY = [
  { name: 'Выручка', code: 2110 },
  { name: 'Себестоимость продаж', code: 2120 },
  { name: 'Валовая прибыль (убыток)', code: 2100 },
  { name: 'Коммерческие расходы', code: 2210 },
  { name: 'Управленческие расходы', code: 2220 },
  { name: 'Прибыль (убыток) от продаж', code: 2200 },
  { name: 'Доходы от участия в других организациях', code: 2310 },
  { name: 'Проценты к получению', code: 2320 },
  { name: 'Проценты у уплате', code: 2330 },
  { name: 'Прочие доходы', code: 2340 },
  { name: 'Прочие расходы', code: 2350 },
  { name: 'Прибыль (убыток) до налогообложения', code: 2300 },
  { name: 'Текущий налог на прибыль', code: 2410 },
  { name: 'Чистая прибыль (убыток)', code: 2400 }
];

export interface FinancialStatementsAllType {
  id: number;
  companyINN: string;
  year: number;
  taxPeriodCode: number;
  currencyCode: number;
  measurementUnit: null;
  row1110: number;
  row1120: number;
  row1130: number;
  row1140: number;
  row1150: number;
  row1160: number;
  row1170: number;
  row1180: number;
  row1190: number;
  row1100: number;
  row1210: number;
  row1220: number;
  row1230: number;
  row1240: number;
  row1250: number;
  row1260: number;
  row1200: number;
  row1600: number;
  row1310: number;
  row1320: number;
  row1340: number;
  row1350: number;
  row1360: number;
  row1370: number;
  row1300: number;
  row1410: number;
  row1420: number;
  row1430: number;
  row1450: number;
  row1400: number;
  row1510: number;
  row1520: number;
  row1530: number;
  row1540: number;
  row1550: number;
  row1500: number;
  row1700: number;
  row2110: number;
  row2120: number;
  row2100: number;
  row2210: number;
  row2220: number;
  row2200: number;
  row2310: number;
  row2320: number;
  row2330: number;
  row2340: number;
  row2350: number;
  row2300: number;
  row2410: number;
  row2411: number;
  row2412: number;
  row2460: number;
  row2400: number;
  row2510: number;
  row2520: number;
  row2530: number;
  row2500: number;
  isValid: boolean;
  actualDate: string;
  fromFns: boolean;
}
