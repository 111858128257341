import styled from 'styled-components';

export const RequsitesStyled = styled.section`
  width: auto;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
