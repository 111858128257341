import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendLeadData';
import { history } from 'src/shared/utils/History';

import { SEND_LEAD_DATA } from '../reducers/sendLeadData';
import LeadsApi from '../api';
import { LeadsData, resetLeadFormData } from '../actions/setLeadFormData';

function* sendLeadData(action: {
  type: SEND_LEAD_DATA;
  data: LeadsData;
}): Generator {
  try {
    const leadId = yield call(LeadsApi.sendLeadData, action.data);
    yield put(got());
    yield put(resetLeadFormData());

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/lead/` + leadId);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendLeadDataSaga(): Generator {
  yield takeLatest(SEND_LEAD_DATA.REQ, sendLeadData);
}
