import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { REQUEST_STATUSES, STORE } from 'globaltypes';

import {
  req as getAllBanksList,
  ResponseDataType as allBankListData
} from 'Application/actions/getAllBanksList';
import { req as sendApplicationToBanks } from 'Application/actions/sendApplicationToBanks';
import { RequestDataType as SendApplicationToBanksRequestDataType } from 'Application/reducers/sendApplicationToBanks';

import { closeModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { Checkbox } from 'shared/ui/Checkbox';
import { Modal } from 'Common';

import {
  ChooseBanksModalStyled,
  BanksForm,
  BanksFormSection,
  ChooseAllCheckbox,
  BtnsBlock,
  CancelButton
} from './styles';
import { Bank } from 'src/features/Application/actions/getAllBanksList';
import { Button } from 'shared/ui/Button';

interface State {
  all: boolean;
  banks: Bank[];
}

interface StateToProps {
  allBanksList: allBankListData;
  statusAllBanksList: REQUEST_STATUSES;
}

interface DispatchToProps {
  closeModal: () => void;
  getAllBanksList: () => void;
  sendApplicationToBanks: (data: SendApplicationToBanksRequestDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ChooseBanksModal extends React.Component<Props, State> {
  state: State = {
    all: false,
    banks: []
  };

  componentDidMount() {
    this.props.getAllBanksList();
  }

  filterBanksWithUsers = (banks: allBankListData) => {
    return banks.items.filter(bank => bank.hasUsers === true);
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget;
    const bankId = parseInt(name, 10);
    const { allBanksList } = this.props;

    if (name === 'all') {
      this.setState({
        all: checked,
        banks: checked ? this.filterBanksWithUsers(allBanksList) : []
      });
    } else {
      if (checked) {
        this.setState(prevState => ({
          banks: [
            ...prevState.banks,
            allBanksList.items.find(bank => bank.id === bankId)!
          ]
        }));
      } else {
        this.setState(prevState => ({
          banks: prevState.banks.filter(bank => bank.id !== bankId)
        }));
      }
    }
  };

  onSubmit = () => {
    const { closeModal, sendApplicationToBanks, match } = this.props;

    const banks = this.state.banks.map(bank => bank.id);

    sendApplicationToBanks({ id: +match.params.id, body: { banks } });

    closeModal();
  };

  render() {
    const { closeModal, allBanksList } = this.props;

    return (
      <Modal name={MODAL_NAMES.CHOOSE_BANKS}>
        <ChooseBanksModalStyled>
          <h2>Выберите банки</h2>
          <p style={{ color: 'red' }}>
            ВНИМАНИЕ! Выбирайте только те банки, чьему портрету соответствует
            клиент
          </p>

          <BanksForm
            onSubmit={this.onSubmit}
            name="send-application-to-banks-form"
          >
            <BanksFormSection>
              <ChooseAllCheckbox
                label="Выбрать все"
                name="all"
                checked={this.state.all}
                onChange={this.onChange}
              />

              {allBanksList.items
                .sort((a, b) => {
                  const hasUsersA = a.hasUsers ? 1 : 0;
                  const hasUsersB = b.hasUsers ? 1 : 0;
                  return hasUsersB - hasUsersA;
                })
                .map(({ id, name, hasUsers }) => (
                  <Checkbox
                    key={id}
                    label={name}
                    name={`${id}`}
                    checked={this.state.banks.some(bank => bank.id === id)}
                    onChange={this.onChange}
                    disabled={!hasUsers}
                  />
                ))}
            </BanksFormSection>

            <BtnsBlock>
              <CancelButton label="Отмена" onClick={closeModal} />
              <Button type="submit" label="Отправить" />
            </BtnsBlock>
          </BanksForm>
        </ChooseBanksModalStyled>
      </Modal>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  allBanksList: Application.getAllBanksList.data,
  statusAllBanksList: Application.getAllBanksList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { closeModal, getAllBanksList, sendApplicationToBanks },
    dispatch
  );

const ChooseBanksModalConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ChooseBanksModal)
);

export { ChooseBanksModalConnect as ChooseBanksModal };
