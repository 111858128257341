import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ApiKeysApi {
  static getApiKey() {
    return ApiRequest('/api/key');
  }

  static getApiKeyPublic() {
    return ApiRequest('/api/key/public');
  }

  static getApiKeyInfo(key: string) {
    return ApiRequest(`/api/key/info?key=${key}`);
  }

  static refresh() {
    return ApiRequest('/api/key/refresh', 'post');
  }

  static refreshPublic() {
    return ApiRequest('/api/key/public/refresh', 'post');
  }
}
