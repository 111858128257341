import { GET_DOCUMENT_LIST } from '../reducers/getDocumentList';
import { DocumentLimitsType, DocumentListType } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: DocumentListType[];
  limits: DocumentLimitsType[];
  guid?: string;
}

export const req = (token: string) => ({
  type: GET_DOCUMENT_LIST.REQ,
  token
});

export const got = (data: ResponseData) => ({
  type: GET_DOCUMENT_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DOCUMENT_LIST.ERR,
  error
});
