import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { history } from 'src/shared/utils/History';

import { TRANSITION_DIRECTIONS } from 'Common';
import { BorrowerCompanies as BorrowerCompaniesStep } from 'BorrowerCompanies/components';

interface OwnProps {
  path: string;
}

interface WithRouterProps {
  match: any;
}

type Props = OwnProps & WithRouterProps;

class FinancingFormStep2 extends React.Component<Props> {
  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  goBack = () => {
    history.push({
      pathname: this.navigateFromTo(this.props.path, 'options'),
      state: TRANSITION_DIRECTIONS.BACKWARDS
    });
  };

  render() {
    return <BorrowerCompaniesStep goBack={this.goBack} />;
  }
}

const WithFinancingFormStep2 = withRouter<any>(FinancingFormStep2);

export { WithFinancingFormStep2 as FinancingFormStep2 };
