import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getCreditProducts';

import { GET_CREDIT_PRODUCTS } from '../reducers/getCreditProducts';
import ClientsApi from '../api';

function* getCreditProducts(action: {
  type: GET_CREDIT_PRODUCTS;
  bankInn: string;
}): Generator {
  try {
    const res: any = yield call(ClientsApi.getCreditProducts, action.bankInn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCreditProductsSaga(): Generator {
  yield takeLatest(GET_CREDIT_PRODUCTS.REQ, getCreditProducts);
}
