import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/refreshPublicApiKey';

import { REFRESH_API_KEY_PUBLIC } from '../reducers/refreshApiKeyPublic';
import ApiKeysApi from '../api';

function* refreshApiKeyPublic(action: {
  type: REFRESH_API_KEY_PUBLIC;
}): Generator {
  try {
    const res: any = yield call(ApiKeysApi.refreshPublic);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* refreshApiKeyPublicSaga(): Generator {
  yield takeLatest(REFRESH_API_KEY_PUBLIC.REQ, refreshApiKeyPublic);
}
