import { GET_COMPANY_INFO } from './../reducers/getCompanyInfo';
import { CompanyInfoWrite } from './setCompanyInfo'

export const req = () => ({
  type: GET_COMPANY_INFO.REQ
});

export const got = (data: CompanyInfoWrite) => ({
  type: GET_COMPANY_INFO.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_INFO.ERR,
  error
});
