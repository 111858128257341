import { PUT_ADD_SIGNEE } from 'entities/SCF/Supplier/model/reducers/putAddSignee';

export const req = (guid: string, signeeGuid: string) => ({
  type: PUT_ADD_SIGNEE.REQ,
  guid,
  signeeGuid
});

export const got = () => ({
  type: PUT_ADD_SIGNEE.GOT
});

export const err = (error: {}) => ({
  type: PUT_ADD_SIGNEE.ERR,
  error
});

export const reset = () => ({
  type: PUT_ADD_SIGNEE.RESET
});
