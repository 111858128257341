import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ExternalCredentialRead } from '../actions/setExternalCredentialFormData';

export interface GetExternalCredentialStoreState extends Reducer {
  externalCredential: ExternalCredentialRead;
}

export enum GET_EXTERNAL_CREDENTIAL {
  REQ = 'GET_EXTERNAL_CREDENTIAL_REQ',
  GOT = 'GET_EXTERNAL_CREDENTIAL_GOT',
  ERR = 'GET_EXTERNAL_CREDENTIAL_ERR'
}

export const initialState: GetExternalCredentialStoreState = {
  status: REQUEST_STATUSES.NONE,
  externalCredential: {
    id: 0
  },
  error: {}
};

const getExternalCredential = (
  state: GetExternalCredentialStoreState = initialState,
  action: {
    type: string;
    externalCredential: ExternalCredentialRead;
    error: {};
  }
): GetExternalCredentialStoreState => {
  switch (action.type) {
    case GET_EXTERNAL_CREDENTIAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EXTERNAL_CREDENTIAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        externalCredential: action.externalCredential
      };
    case GET_EXTERNAL_CREDENTIAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getExternalCredential;
