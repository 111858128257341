import { ContractFinancingWrite } from 'Application/types';
import {
  CONTRACT_FINANCING_TYPE,
  CONTRACT_SUBJECT_TYPE,
  CONTRACT_PREPAYMENT_TYPE
} from '../types/ContractFinancingApplicationType';

export type SetContractFinancingFormData = Partial<ContractFinancingWrite>;

export enum CONTRACT_FINANCING_FORM_ACTIONS {
  SET = 'SET_GUARANTEES_FORM_DATA',
  RESET = 'RESET_GUARANTEES_FORM_DATA'
}

export const initialState: ContractFinancingWrite = {
  guid: null,
  contractType: CONTRACT_FINANCING_TYPE.NOT_SET,
  purchaseLink: '',
  contractAmount: 0,
  contractSubject: CONTRACT_SUBJECT_TYPE.NONE,
  contractEndDate: '',
  prepaymentType: CONTRACT_PREPAYMENT_TYPE.NOT_SET,
  prepaymentAmount: 0,
  contractDocuments: []
};

const setContractFinancingFormData = (
  state: ContractFinancingWrite = initialState,
  action: {
    type: CONTRACT_FINANCING_FORM_ACTIONS;
    data: SetContractFinancingFormData;
  }
): ContractFinancingWrite => {
  switch (action.type) {
    case CONTRACT_FINANCING_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case CONTRACT_FINANCING_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setContractFinancingFormData;
