import { Reducer, REQUEST_STATUSES } from 'globaltypes';

import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import { SetGuaranteesApplicationType } from 'Application/reducers/setGuaranteesFormData';
import { SetCreditingApplicationType } from 'Application/reducers/setCreditingFormData';
import { SetFinancingApplicationType } from 'Application/reducers/setFinancingFormData';
import { SetContractFinancingFormData } from './setContractFinancingFormData';
import { SetPropertyFinancingFormData } from './setPropertyFinancingFormData';

export type SendApplicationDataStoreState = Reducer;

export interface RequestDataType {
  id: number;
  code?: string;
  clientCompanyName?: string;
  amount?: number;
  customerInn?: string;
  customerName?: string;
  term?: number;
  termStartDate?: string;
  termEndDate?: string;
  refinancing?: boolean;
  factoring?: SetFactoringApplicationType;
  guarantees?: SetGuaranteesApplicationType;
  crediting?: SetCreditingApplicationType;
  financing?: SetFinancingApplicationType; // TODO rename
  contractFinancing?: SetContractFinancingFormData;
  propertyFinancing?: SetPropertyFinancingFormData;
}

export enum SEND_APPLICATION_DATA {
  REQ = 'SEND_APPLICATION_DATA_REQUEST',
  GOT = 'SEND_APPLICATION_DATA_GOT',
  ERR = 'SEND_APPLICATION_DATA_ERROR',
  RESET = 'SEND_APPLICATION_DATA_RESET'
}

export const initialState: SendApplicationDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendApplicationData = (
  state: SendApplicationDataStoreState = initialState,
  action: {
    type: SEND_APPLICATION_DATA;
    error: {};
  }
): SendApplicationDataStoreState => {
  switch (action.type) {
    case SEND_APPLICATION_DATA.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_APPLICATION_DATA.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_APPLICATION_DATA.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SEND_APPLICATION_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default sendApplicationData;
