import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { BusinessCabinet } from 'Business/components';

import { CRMApproved } from 'InternalAgent/components';

import { UserCabinetPageStyled } from './styles';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getUserData: () => void;
  exit: () => void;
  resetUserData: () => void;
}

class CRMPage extends React.Component<StateToProps & DispatchToProps> {
  componentDidMount() {
    this.props.getUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.status === REQUEST_STATUSES.ERROR &&
      prevProps.status !== REQUEST_STATUSES.GOT
    ) {
      this.props.exit();
      this.props.resetUserData();
    }
  }

  //TODO NOTE without cabinetLists routing doesnt work
  renderCRM = () => {
    if (this.props.permissions) {
      if (this.props.permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT))
        return <CRMApproved />;

      return <BusinessCabinet />;
    }
    return <BusinessCabinet />;
  };

  render() {
    const { status, error } = this.props;

    return (
      <React.Fragment>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <UserCabinetPageStyled> {this.renderCRM()} </UserCabinetPageStyled>
        )}
        {status === REQUEST_STATUSES.ERROR &&
          error.code === 401 && (
            <ActionResponseBlock
              showIn={status === REQUEST_STATUSES.ERROR}
              template={ACTION_RESPONSE_TEMPLATE.SESSION_ERROR}
            />
          )}

        <ActionResponseBlock
          showIn={status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }: CRM) => ({
  permissions: User.getUserData.data.permissions,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserData, exit, resetUserData }, dispatch);

const CRMPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CRMPage);

export { CRMPageConnect as CRMPage };
