import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getEarlyPaymentApplicationsSupplierRole';
import { GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getEarlyPaymentApplicationsSupplierRole';
import SCFApi from 'entities/SCF/Supplier/api';

function* getEarlyPaymentApplicationsSupplierRole(action: {
  type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getEarlyPaymentApplicationsSupplierRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getEarlyPaymentApplicationsSupplierRoleSaga() {
  yield takeLatest(
    GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.REQ,
    getEarlyPaymentApplicationsSupplierRole
  );
}
