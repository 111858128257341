import { POST_ADD_TO_CONTRACT_BY_SUPPLIER } from 'src/features/SCF/reducers/postAddToContractBySupplier';

export const req = (contractAccount: string, guid: string) => ({
  type: POST_ADD_TO_CONTRACT_BY_SUPPLIER.REQ,
  guid,
  contractAccount
});

export const got = () => ({
  type: POST_ADD_TO_CONTRACT_BY_SUPPLIER.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_TO_CONTRACT_BY_SUPPLIER.ERR,
  error
});
