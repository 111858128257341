import { ApplicationItem } from 'src/features/ApplicationForCompanies/types';
import { GET_APPLICATIONS } from '../reducers/getApplications';

export interface ResponseData {
  totalItems?: number;
  totalQty?: number;
  page: number;
  pageSize: number;
  items: ApplicationItem[];
}

export const req = (inn: string | number) => ({
  type: GET_APPLICATIONS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_APPLICATIONS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATIONS.ERR,
  error
});
