import * as React from 'react';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData } from 'src/features/SupplyContracts/actions/getSupplyContractsList';
import {
  DebtorAgreementForm,
  FlexContainer,
  PaymentDeferment,
  Postponement,
  PostponementItem,
  Status,
  StatusContainer,
  SupplyContainer,
  TDStyled,
  TextRed,
  TooltipBoxStyle
} from './styled';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'src/shared/ui/Button';

interface OwnProps {
  supplyContracts: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

type Props = RouteComponentProps & OwnProps;

const InternalSupplyList: React.FC<Props> = ({
  supplyContracts,
  status,
  error,
  history
}) => {
  const navigateToDebtor = (inn: string) => {
    window.open(`/internal/debtors/${inn}`, '_blank');
  };

  const navigateToSupplier = (inn: string) => {
    window.open(`/internal/suppliers/${inn}`, '_blank');
  };

  const navigateToSupplyContract = (id: string) => {
    history.push(`/internal/supply_contracts/${id}`);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Поставщик</TableThStyled>
                <TableThStyled width="10%">Дебитор</TableThStyled>
                <TableThStyled width="10%">Реквизиты договора</TableThStyled>
                <TableThStyled width="10%" style={{ paddingLeft: '30px' }}>
                  Отсрочка, дней
                </TableThStyled>
                <TableThStyled width="20%">Статус договора</TableThStyled>
                <TableThStyled width="10%">Соглашение дисконт</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {supplyContracts.items.map(contract => (
                <TableRowStyled
                  key={contract.id}
                  onClick={() => navigateToSupplyContract(contract.id)}
                >
                  <TDStyled>
                    <SupplyContainer>
                      <div>
                        <FlexContainer>
                          <div>{contract.supplierInn}</div>
                          {contract.supplierInn && (
                            <TooltipBoxStyle text="Открыть в новом окне">
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                onClick={() =>
                                  navigateToSupplier(contract.supplierInn)
                                }
                              />
                            </TooltipBoxStyle>
                          )}
                        </FlexContainer>
                      </div>
                      <div>{contract.supplierName}</div>
                    </SupplyContainer>
                  </TDStyled>
                  <TDStyled>
                    <SupplyContainer>
                      <div>
                        <FlexContainer>
                          <div>{contract.debtorInn}</div>
                          {contract.debtorInn && (
                            <TooltipBoxStyle text="Открыть в новом окне">
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                onClick={() =>
                                  navigateToDebtor(contract.debtorInn)
                                }
                              />
                            </TooltipBoxStyle>
                          )}
                        </FlexContainer>
                      </div>
                      <div>{contract.debtorName}</div>
                    </SupplyContainer>
                  </TDStyled>
                  <td>
                    № {contract.contractNumber} <br />
                    от{' '}
                    {format(new Date(contract.contractStartDate), 'dd.MM.yyyy')}
                  </td>
                  <td>
                    <Postponement>
                      {contract.paymentDeferment > 0 &&
                      contract.paymentDefermentFixed === true ? (
                        <PostponementItem>
                          <div>{contract.paymentDeferment}</div>
                          <PaymentDeferment>фиксированная</PaymentDeferment>
                        </PostponementItem>
                      ) : contract.paymentDefermentFixed === false ? (
                        <PostponementItem>
                          <div>Плавающая</div>
                        </PostponementItem>
                      ) : contract.paymentDefermentFixed === null ||
                      (contract.paymentDefermentFixed === true &&
                        contract.paymentDeferment === null) ? (
                        <PostponementItem>
                          <Button label="не заполнено" />
                        </PostponementItem>
                      ) : (
                        contract.paymentDefermentFixed === true &&
                        contract.paymentDeferment <= 0 && (
                          <PostponementItem>
                            <div>{contract.paymentDeferment}</div>
                            <TextRed>
                              может быть <br /> некорректно
                            </TextRed>
                          </PostponementItem>
                        )
                      )}
                    </Postponement>
                  </td>
                  <td>
                    <StatusContainer>
                      <Status
                        status={
                          contract.contraceFileIdentifyer === null
                            ? 'Не загружен'
                            : 'Загружен'
                        }
                      >
                        {contract.contraceFileIdentifyer === null
                          ? 'Не загружен'
                          : 'Загружен'}
                      </Status>
                      <Status
                        status={
                          contract.supplierSignatureIdentifier === null
                            ? 'Не подписан поставщиком'
                            : 'Подписан поставщиком'
                        }
                      >
                        {contract.supplierSignatureIdentifier === null
                          ? 'Не подписан поставщиком'
                          : 'Подписан поставщиком'}
                      </Status>
                      <Status
                        status={
                          contract.confirmedByDebtor === null
                            ? 'Еще не подтвержден дебитором'
                            : contract.confirmedByDebtor === false
                              ? 'Отклонен дебитором'
                              : 'Подтвержден дебитором'
                        }
                      >
                        {contract.confirmedByDebtor === null
                          ? 'Еще не подтвержден дебитором'
                          : contract.confirmedByDebtor === false
                            ? 'Отклонен дебитором'
                            : 'Подтвержден дебитором'}
                      </Status>
                      <Status
                        status={
                          contract.debtorSignatureIdentifier === null
                            ? 'Не подписан дебитором'
                            : 'Подписан дебитором'
                        }
                      >
                        {contract.debtorSignatureIdentifier === null
                          ? 'Не подписан дебитором'
                          : 'Подписан дебитором'}
                      </Status>
                    </StatusContainer>
                  </td>
                  <td>
                    <DebtorAgreementForm>
                      {!!contract.debtorAgreementForm ? (
                        'Форма выбрана'
                      ) : (
                        <TextRed>
                          Форма дебитора
                          <br /> не выбрана
                        </TextRed>
                      )}
                    </DebtorAgreementForm>
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={supplyContracts} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

export default withRouter(InternalSupplyList);
