import {
  PATCH_PURCHASE_CONTRACT_DEBTOR,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/patchPurchaseContractDebtor';

export const req = (guid: string, data: RequestDataType) => ({
  type: PATCH_PURCHASE_CONTRACT_DEBTOR.REQ,
  guid,
  data
});

export const got = () => ({
  type: PATCH_PURCHASE_CONTRACT_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: PATCH_PURCHASE_CONTRACT_DEBTOR.ERR,
  error
});
