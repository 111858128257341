import { GET_PLEDGES } from 'src/features/Clients/reducers/getPledges';
import { Pledges } from 'src/features/Clients/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Pledges[];
}

export const req = (bankInn: string) => ({
  type: GET_PLEDGES.REQ,
  bankInn
});

export const got = (data: ResponseData) => ({
  type: GET_PLEDGES.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_PLEDGES.ERR,
  error
});
