import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { req as getInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';

import { EachForm } from 'Application/components';
import { ContractFinancingFormStep1 } from './ContractFinancingFormStep1/ContractFinancingFormStep1';
import { ContractFinancingFormStep2 } from './ContractFinancingFormStep2/ContractFinancingFormStep2';
import { ContractFinancingFormStep3 } from './ContractFinancingFormStep3/ContractFinancingFormStep3';
import { ApplicationFormContrainer } from './styled';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & DispatchToProps & StateToProps;

const ContractFinancingForm: React.FC<Props> = ({
  getInternalApplication,
  status,
  setApplicationProcessMode,
  match,
  location
}) => {
  React.useEffect(
    () => {
      if (match.params.id) {
        getInternalApplication(match.params.id);
      }

      return () =>
        setApplicationProcessMode({ mode: APPLICATION_PROCESS_MODES.NONE });
    },
    [match.params.id, location.pathname]
  );

  const routes = [
    {
      path: 'options',
      component: ContractFinancingFormStep1,
      step: 'Параметры кредитования'
    },
    {
      path: 'contract',
      component: ContractFinancingFormStep2,
      step: 'Параметры контракта'
    },
    {
      path: 'documents',
      component: ContractFinancingFormStep3,
      step: 'Документы по заявке'
    }
  ];

  if (status !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <ApplicationFormContrainer>
      <EachForm routes={routes} />
    </ApplicationFormContrainer>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  status: InternalAgentRole.getInternalApplication.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getInternalApplication, setApplicationProcessMode },
    dispatch
  );

const ContractFinancingFormConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingForm)
);

export { ContractFinancingFormConnect as ContractFinancingForm };
