import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col } from 'react-grid-system';
import { REQUEST_STATUSES, STORE } from 'globaltypes';
import { Error } from 'shared/ui/Error';
import {
  BorrowerCompany,
  GUARANTEES_TYPE,
  GuaranteesApplicationRead
} from 'Application/types';
import { getYearlyReportYear, getQuarterReportBoundary } from 'src/shared/utils/Utils';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import { req as deleteBorrowerCompanyDocument } from 'BorrowerCompanies/actions/deleteBorrowerCompanyDocument';
import { req as uploadBorrowerCompanyDocuments } from 'BorrowerCompanies/actions/uploadBorrowerCompanyDocuments';
import { SagaRequestDataType as DeleteBorrowerCompanyDocumentRequestDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import { BorrowerCompaniesOtherDocuments as BorrowerCompaniesStep } from '../../BorrowerCompanies/BorrowerCompaniesOtherDocuments';

export const SlimFilesRequired = {
  yearReport: 'year_report',
  quarterReport: 'quarter_report',
  charter: 'charter',
  directorPassport: 'director_passport',
  directorDecision: 'director_decision',
  requisites: 'requisites'
};

const SlimFiles = {
  contract: 'contract',
  ...SlimFilesRequired
};

interface OwnProps {
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  fileUploadBlock: any;
  path: string;
}

interface StateToProps {
  clientApplication: Partial<GuaranteesApplicationRead>;
  documentsUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  uploadBorrowerCompanyDocuments: (
    data: UploadDocumentsRequestDataType
  ) => void;
  deleteBorrowerCompanyDocument: (
    data: DeleteBorrowerCompanyDocumentRequestDataType
  ) => void;
}

interface MatchParams {
  id: string;
  inBankId?: string;
}

type Props = RouteComponentProps<MatchParams> &
  OwnProps &
  StateToProps &
  DispatchToProps;

class BorrowerCompanySlimFileUploadBlock extends React.Component<Props> {
  onFileUpload = file => {
    const { uploadBorrowerCompanyDocuments, borrowerCompany } = this.props;
    uploadBorrowerCompanyDocuments({
      id: borrowerCompany.id,
      files: [file]
    });
  };

  onFileRemove = (documentData: DeleteDocumentRequestDataType) => {
    const { deleteBorrowerCompanyDocument, borrowerCompany } = this.props;

    deleteBorrowerCompanyDocument({
      id: documentData.id,
      companyId: borrowerCompany.id
    });
  };

  onFilesUpload = files => {
    const { uploadBorrowerCompanyDocuments, borrowerCompany } = this.props;

    uploadBorrowerCompanyDocuments({
      id: borrowerCompany.id,
      files
    });
  };

  onFilesRemove = (documentData: DeleteDocumentRequestDataType) => {
    const { deleteBorrowerCompanyDocument, borrowerCompany } = this.props;

    deleteBorrowerCompanyDocument({
      id: documentData.id,
      companyId: borrowerCompany.id
    });
  };

  render() {
    const findErrorByMetaInfo = metaInfo => {
      return errors.documents &&
        !borrowerCompany.documents.find(doc => doc.metaInfo === metaInfo)
        ? errors.documents
        : '';
    };

    const {
      clientApplication: { guaranteesType },
      errors,
      borrowerCompany,
      documentsUploadStatus
    } = this.props;

    const onFileUploadWithMetaInfo = (metaInfo, file) => {
      file.metaInfo = metaInfo;
      this.onFileUpload(file);
    };

    const findDocumentByMetaInfo = metaInfo => {
      return borrowerCompany.documents.find(doc => doc.metaInfo === metaInfo);
    };

    const renameFile = (file: any, newName: string) => {
      const extension = file.name.substr(file.name.lastIndexOf('.') + 1);
      const name = newName + '.' + extension;
      return new File([file], name);
    };

    const quarterBoundaries = getQuarterReportBoundary();

    return (
      <>
        <Col lg={12}>
          <InputSingleFile
            label={`Отчетность за ${getYearlyReportYear()} год`}
            file={findDocumentByMetaInfo(SlimFiles.yearReport)}
            error={findErrorByMetaInfo(SlimFiles.yearReport)}
            uploadStatus={documentsUploadStatus}
            onFileUpload={(file: any) => {
              onFileUploadWithMetaInfo(
                SlimFiles.yearReport,
                renameFile(
                  file,
                  `${
                    borrowerCompany.individualNumber
                  }_Годовая_отчетность_${getYearlyReportYear()}`
                )
              );
            }}
            onFileRemove={this.onFileRemove}
          />

          {quarterBoundaries.quarter &&
            quarterBoundaries.year && (
              <InputSingleFile
                label={`Квартальная отчетность за ${
                  quarterBoundaries.quarter
                } квартал ${quarterBoundaries.year} года`}
                file={findDocumentByMetaInfo(SlimFiles.quarterReport)}
                error={findErrorByMetaInfo(SlimFiles.quarterReport)}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  onFileUploadWithMetaInfo(
                    SlimFiles.quarterReport,
                    renameFile(
                      file,
                      `${borrowerCompany.individualNumber}_Отчетность_${
                        quarterBoundaries.year
                      }-${quarterBoundaries.quarter}_кв`
                    )
                  )
                }
                onFileRemove={this.onFileRemove}
              />
            )}

          <InputSingleFile
            label="Устав"
            file={findDocumentByMetaInfo(SlimFiles.charter)}
            error={findErrorByMetaInfo(SlimFiles.charter)}
            uploadStatus={documentsUploadStatus}
            onFileUpload={file =>
              onFileUploadWithMetaInfo(
                SlimFiles.charter,
                renameFile(file, `${borrowerCompany.individualNumber}_Устав`)
              )
            }
            onFileRemove={this.onFileRemove}
          />

          <InputSingleFile
            label="Паспорт генерального директора"
            file={findDocumentByMetaInfo(SlimFiles.directorPassport)}
            error={findErrorByMetaInfo(SlimFiles.directorPassport)}
            uploadStatus={documentsUploadStatus}
            onFileUpload={file =>
              onFileUploadWithMetaInfo(
                SlimFiles.directorPassport,
                renameFile(
                  file,
                  `${borrowerCompany.individualNumber}_Паспорт_ГД`
                )
              )
            }
            onFileRemove={this.onFileRemove}
          />

          <InputSingleFile
            label="Решение на генерального директора"
            file={findDocumentByMetaInfo(SlimFiles.directorDecision)}
            error={findErrorByMetaInfo(SlimFiles.directorDecision)}
            uploadStatus={documentsUploadStatus}
            onFileUpload={file =>
              onFileUploadWithMetaInfo(
                SlimFiles.directorDecision,
                renameFile(
                  file,
                  `${borrowerCompany.individualNumber}_Решение_ГД`
                )
              )
            }
            onFileRemove={this.onFileRemove}
          />

          <InputSingleFile
            label="Карточка с реквизитами"
            file={findDocumentByMetaInfo(SlimFiles.requisites)}
            error={findErrorByMetaInfo(SlimFiles.requisites)}
            uploadStatus={documentsUploadStatus}
            onFileUpload={file =>
              onFileUploadWithMetaInfo(
                SlimFiles.requisites,
                renameFile(
                  file,
                  `${borrowerCompany.individualNumber}_Реквизиты`
                )
              )
            }
            onFileRemove={this.onFileRemove}
          />

          {/* TODO refactor contract placement */}
          {guaranteesType === GUARANTEES_TYPE.COMMERCIAL && (
            <InputSingleFile
              label="Контракт"
              file={findDocumentByMetaInfo('contract')}
              error={findErrorByMetaInfo('contract')}
              uploadStatus={documentsUploadStatus}
              onFileUpload={file =>
                onFileUploadWithMetaInfo(
                  'contract',
                  renameFile(
                    file,
                    `${borrowerCompany.individualNumber}_Контракт`
                  )
                )
              }
              onFileRemove={this.onFileRemove}
            />
          )}
        </Col>

        <Col lg={4} style={{ paddingTop: 20, marginRight: 40, paddingLeft: 0 }}>
          <BorrowerCompaniesStep style={{ height: 200 }} />
        </Col>

        <Error error={errors.documents} />
      </>
    );
  }
}

const mapStateToProps = ({ ClientRole, BorrowerCompanies }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data,
  documentsUploadStatus: BorrowerCompanies.uploadBorrowerCompanyDocuments.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadBorrowerCompanyDocuments,
      deleteBorrowerCompanyDocument
    },
    dispatch
  );

const BorrowerCompanySlimFileUploadBlockConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompanySlimFileUploadBlock)
);

export {
  BorrowerCompanySlimFileUploadBlockConnect as BorrowerCompanySlimFileUploadBlock
};
