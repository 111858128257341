import {
  RequestDataType,
  POST_DISCOUNTING_SETTINGS_SIGNATURE
} from 'src/features/SCF/reducers/postDiscountingSettingsSignature';

export const req = (data: RequestDataType) => ({
  type: POST_DISCOUNTING_SETTINGS_SIGNATURE.REQ,
  data
});

export const got = () => ({
  type: POST_DISCOUNTING_SETTINGS_SIGNATURE.GOT
});

export const err = (error: {}) => ({
  type: POST_DISCOUNTING_SETTINGS_SIGNATURE.ERR,
  error
});
