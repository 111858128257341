import {
  UPLOAD_BLOCK_TYPE_ACTIONS,
  SetFileUploadBlockType
} from 'Application/reducers/setFileUploadBlockType';

export const setFileUploadBlock = (data: SetFileUploadBlockType) => ({
  type: UPLOAD_BLOCK_TYPE_ACTIONS.SET,
  data
});

export const resetFileUploadBlock = () => ({
  type: UPLOAD_BLOCK_TYPE_ACTIONS.RESET
});
