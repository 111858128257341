import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsSupplierRole'

export interface GetMonetaryClaimsSupplierRoleStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_MONETARY_CLAIMS_SUPPLIER_ROLE {
  GET_MONETARY_CLAIMS_SUPPLIER_ROLE_REQ = 'GET_MONETARY_CLAIMS_SUPPLIER_ROLE_REQ',
  GET_MONETARY_CLAIMS_SUPPLIER_ROLE_GOT = 'GET_MONETARY_CLAIMS_SUPPLIER_ROLE_GOT',
  GET_MONETARY_CLAIMS_SUPPLIER_ROLE_ERR = 'GET_MONETARY_CLAIMS_SUPPLIER_ROLE_ERR'
}

export const initialState: GetMonetaryClaimsSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: [],
    availableChecks: 0,
    fullSum: 0,
  },
  error: {}
};

const getMonetaryClaimsSupplierRole = (
  state: GetMonetaryClaimsSupplierRoleStoreState = initialState,
  action: {
    type: GET_MONETARY_CLAIMS_SUPPLIER_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetMonetaryClaimsSupplierRoleStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsSupplierRole;
