import getCompanyInfo from './getCompanyInfo';
import sendCompanyInfo from './sendCompanyInfoData';
import getCompanyInn from './getCompanyInn';
import getBankAccountData from './getBankAccount';
import sendBankAccountData from './sendBankAccountData';
import deleteBankAccount from './deleteBankAccount';
import getCertificate from './getCertificate';
import getCertificateGenitive from './getCertificateGenitive';
import deleteCertificate from './deleteCertificate';
import sendCertificateData from './sendCertificateData';
import sendBuyersSaga from './sendBuyers';
import sendSuppliersSaga from './sendSuppliers';
import checkAvailableLimitsSaga from './checkAvailableLimits';
import getMySuppliersSaga from './getMySuppliers';
import getCompanyByInnSaga from './getCompanyByInn';
import getMyBuyersSaga from './getMyBuyers';
import getMyLimitsSaga from './getFactoringLimits';
import getCompanyByInnThirdPartySaga from './getCompanyByInnThirdParty';
import getSecondCompanyByInnThirdPartySaga from './getSecondCompanyByInnThirdParty';
import sendFactoringContract from './sendFactoringContract';
import getPurchaseContractsSaga from './getPurchaseContracts';
import getPurchaseContractByGuidSaga from './getPurchaseContractByGuid';
import sendPurchaseContract from './sendPurchaseContract';
import getSupplyContractsSaga from './getSupplyContracts';
import getSupplyContractByGuidSaga from './getSupplyContractByGuid';
import sendSupplyContract from './sendSupplyContract';
import addListOfLimits from './addListOfLimits';
import sendDiscountingSettings from './sendDiscountingSettings';
import getDiscountingSettingsSaga from './getDiscountingSettings';
import postDiscountingSettingsSignatureSaga from './postDiscountingSettingsSignature';
import sendFactoringSettingsByDebtorInn from './sendFactoringSettingsByDebtorInn';
import postFactoringSettingsSignatureSaga from './postFactoringSettingsSignature';
import putCertificateFillSaga from './putCertificateFill';
import getCompanyFastInfoSaga from './getCompanyFastInfo';
import getContractFileSaga from './getContractFile';
import postAddSupplierSaga from './postAddSupplier';
import postAddBuyerSaga from './postAddBuyer';
import getKeyRatesSaga from './getKeyRates';
import getPOASSaga from './getPOAS';
import putAddPoaSaga from './putAddPoa';
import postCreateAgreementSupplierRoleSaga from './postCreateAgreementSupplierRole';
import getAllDiscountAuctionsByDebtorSaga from './getAllDiscountAuctions';
import postCreateDiscountAuctionsSaga from './postCreateDiscountAuctions';
import getOneDiscountAuctionSaga from './getOneDiscountAuction';
import getSuppluerAuctionSaga from './getSuppluerAuction';
import postAddToAuctionSaga from './postAddToAuction';
import deleteFromAuctionSaga from './deleteFromAuction';
import getAllDiscountAuctionsBySupplierSaga from './getAllDiscountAuctionsBySupplier';
import getOneDiscountAuctionBySupplierSaga from './getOneDiscountAuctionBySupplier';
import getInvestorListBySupplierSaga from './getInvestorListBySupplier';
import putMonetaryClaimDeleteToAuctionSaga from './putMonetaryClaimDeleteToAuction';
import postAddedMonetaryClaimByDebtorSaga from './postAddedMonetaryClaimByDebtor';
import getInternalCounterSidebarSaga from './getInternalCounterSidebar';

export default [
  getCompanyInfo,
  sendCompanyInfo,
  getCompanyInn,
  getBankAccountData,
  sendBankAccountData,
  deleteBankAccount,
  getCertificate,
  getCertificateGenitive,
  deleteCertificate,
  sendCertificateData,
  sendBuyersSaga,
  sendSuppliersSaga,
  checkAvailableLimitsSaga,
  getMySuppliersSaga,
  getCompanyByInnSaga,
  getMyBuyersSaga,
  getMyLimitsSaga,
  getCompanyByInnThirdPartySaga,
  getSecondCompanyByInnThirdPartySaga,
  sendFactoringContract,
  getPurchaseContractsSaga,
  getPurchaseContractByGuidSaga,
  sendPurchaseContract,
  getSupplyContractsSaga,
  getSupplyContractByGuidSaga,
  sendSupplyContract,
  addListOfLimits,
  sendDiscountingSettings,
  getDiscountingSettingsSaga,
  postDiscountingSettingsSignatureSaga,
  sendFactoringSettingsByDebtorInn,
  postFactoringSettingsSignatureSaga,
  putCertificateFillSaga,
  getCompanyFastInfoSaga,
  getContractFileSaga,
  postAddSupplierSaga,
  postAddBuyerSaga,
  getKeyRatesSaga,
  getPOASSaga,
  putAddPoaSaga,
  postCreateAgreementSupplierRoleSaga,
  getAllDiscountAuctionsByDebtorSaga,
  postCreateDiscountAuctionsSaga,
  getOneDiscountAuctionSaga,
  getSuppluerAuctionSaga,
  postAddToAuctionSaga,
  deleteFromAuctionSaga,
  getAllDiscountAuctionsBySupplierSaga,
  getOneDiscountAuctionBySupplierSaga,
  getInvestorListBySupplierSaga,
  putMonetaryClaimDeleteToAuctionSaga,
  postAddedMonetaryClaimByDebtorSaga,
  getInternalCounterSidebarSaga
];
