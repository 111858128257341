import { POST_NEW_CLIENT } from 'src/features/Clients/reducers/postNewClient';

export interface RequestData {
  companyinn: string;
  companyshortname: string;
}

export const req = (data: RequestData) => ({
  type: POST_NEW_CLIENT.REQ,
  data
});

export const got = () => ({
  type: POST_NEW_CLIENT.GOT
});

export const err = (error = {}) => ({
  type: POST_NEW_CLIENT.ERR,
  error
});

export const reset = () => ({
  type: POST_NEW_CLIENT.RESET
});
