import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { CRMComponent } from '../EachCRM';
import { SectionSeparatorStyled } from '../styles';
import { Tenders } from 'src/features/Tenders/components/Tenders';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: () => <Component listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const TendersSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>
        Закупки и тендеры
      </SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: Tenders,
      path: '/crm/tenders/all',
      label: 'Закупки',
      icon: <FontAwesomeIcon icon={faGavel} fixedWidth={true} />,
      listType: null
    })
  }
];
