import styled from 'styled-components';

import { Label } from 'shared/ui/Label';
import {
  rr,
  navyBlue,
  lighterBlue,
  gray,
  lighterGray
} from 'shared/styled';

export const DocumentsInfoBlockLabel = styled(Label)`
  margin-bottom: 12px;
  color: ${navyBlue};
`;

export const DocumentsInfoBlockStyled = styled.div.attrs(props => ({}))`
  width: 100%;

  padding: 24px;
  border-radius: 4px;
  background-color: ${lighterGray};
`;

export const DataListBlock = styled.div`
  span {
    font-family: ${rr};
    font-size: 14px;
    color: ${navyBlue};
  }

  ul {
    margin: 10px 0;
    padding: 0;
    list-style: none;

    font-family: ${rr};
    font-size: 14px;
    color: ${gray};

    li {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &::before {
        content: '';
        display: block;

        width: 5px;
        height: 5px;

        margin-right: 10px;

        border: 1px solid ${gray};
        border-radius: 50%;
      }
    }
  }
`;
