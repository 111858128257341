import ApiRequest from 'src/shared/utils/ApiRequest';

export default class PersonsApi {
  static getPersons(inn: string) {
    return ApiRequest(`/api/beneficiaries_of_companies/person/${inn}`);
  }

  static getManagers(inn: string) {
    return ApiRequest(`/api/persons_of_companies/person/${inn}`);
  }

  static getPerson(inn: string) {
    return ApiRequest(`/api/person/inn/${inn}`);
  }

  static getPersonsList(data) {
    return ApiRequest(`/api/person/all`, 'get', data);
  }

  static patchEditPerson(inn: string, data) {
    return ApiRequest(`/api/person/inn/${inn}`, 'patch', data);
  }
}
