import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeleteBankAccountStoreState extends Reducer {
    data: RequestDataType;
}

export interface RequestDataType {
    id: number;
}

export enum DELETE_BANK_ACCOUNT {
    REQ = 'DELETE_BANK_ACCOUNT_REQ',
    GOT = 'DELETE_BANK_ACCOUNT_GOT',
    ERR = 'DELETE_BANK_ACCOUNT_ERR'
}

export const initialState: DeleteBankAccountStoreState = {
    status: REQUEST_STATUSES.NONE,
    data: {
        id: -1
    },
    error: {}
};

const deleteBankAccount = (
    state: DeleteBankAccountStoreState = initialState,
    action: {
        type: DELETE_BANK_ACCOUNT;
        data: RequestDataType;
        error: {};
    }
): DeleteBankAccountStoreState => {
    switch (action.type) {
        case DELETE_BANK_ACCOUNT.REQ:
            return {
                ...state,
                status: REQUEST_STATUSES.REQUEST,
                data: action.data
            };
        case DELETE_BANK_ACCOUNT.GOT:
            return {
                ...state,
                status: REQUEST_STATUSES.GOT
            };
        case DELETE_BANK_ACCOUNT.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };
        default:
            return state;
    }
};

export default deleteBankAccount;
