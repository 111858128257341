import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from '../api';

import { got, err } from './../actions/getAllDiscountAuctionsBySupplier';
import { GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER } from './../reducers/getAllDiscountAuctionsBySupplier';

function* getAllDiscountAuctionsBySupplier(action: {
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER;
}) {
  try {
    const res: any = yield call(SCFApi.getAllDiscountAuctionsBySupplier);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllDiscountAuctionsBySupplierSaga() {
  yield takeLatest(
    GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.REQ,
    getAllDiscountAuctionsBySupplier
  );
}
