import getActiveDDSOfDebtorSaga from './getActiveDDSOfDebtor';
import getCertificatesSaga from './getCertificates';
import getUserStatsSaga from './getUserStats';
import getAllDocumentsFormsSaga from './getAllDocumentsForms';
import getInternalFormFileSaga from './getInternalFormFile';
import postDocumentsFormFillSaga from './postDocumentsFormFill';
import getInternalApplicationSaga from './getInternalApplication';
import getFactoringLimitsSaga from './getFactoringLimits';
import patchTenderUpdateByInternalSaga from './patchTenderUpdateByInternal';
import patchSetDiscountMarginSaga from './patchSetDiscountMargin';
import getCheckSignatureSaga from './getCheckSignature';

export default [
  getActiveDDSOfDebtorSaga,
  getCertificatesSaga,
  getUserStatsSaga,
  getAllDocumentsFormsSaga,
  getInternalFormFileSaga,
  postDocumentsFormFillSaga,
  getInternalApplicationSaga,
  getFactoringLimitsSaga,
  patchTenderUpdateByInternalSaga,
  patchSetDiscountMarginSaga,
  getCheckSignatureSaga
];
