import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getProduct';

import { GET_PRODUCT } from '../reducers/getProduct';
import ProductsApi from '../api';

function* getProduct(action: { type: GET_PRODUCT; id: string }): Generator {
  try {
    const res: any = yield call(ProductsApi.getProduct, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(GET_PRODUCT.REQ, getProduct);
}
