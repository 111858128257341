import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/toggleExternalVisibility';

import {
  TOGGLE_EXTERNAL_VISIBILITY_ACTIONS,
} from '../reducers/toggleExternalVisibility';
import ApplicationApi from '../api';

function* toggleExternalVisibility(action: {
  type: TOGGLE_EXTERNAL_VISIBILITY_ACTIONS;
  appId: number;
}): Generator {
  try {

    yield call(ApplicationApi.toggleExternalVisibility,action.appId);

    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* toggleExternalVisibilitySaga(): Generator {
  yield takeLatest(TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.REQ, toggleExternalVisibility);
}
