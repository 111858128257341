import styled from 'styled-components';
import { rr } from 'shared/styled';

export const SectionSeparatorStyled = styled.div`
  padding-top: 5px;
  margin: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #686f86;

  font-family: ${rr};
  font-size: 14px;
  text-decoration: none;
  color: #686f86;
`;

export const UserCabinetPageStyled = styled.section`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100%;
`;
