import { GET_ALL_BANKS_LIST } from 'Application/reducers/getAllBanksList';

export interface Bank {
  id: number;
  name: string;
  hasBankManagers: boolean;
  inn: string;
  isBank: boolean;
  isFactor: boolean;
  isMfo: boolean;
  isLeasingCompany: boolean;
  loanPortfolioVolume: number;
  loanPortfolioRating: number;
  bgPortfolioVolume: number;
  bgPortfolioRating: number;
  factoringPortfolioVolume: number;
  factoringPortfolioRating: number;
  leasingPortfolioVolume: number;
  leasingPortfolioRating: number;
  lastActivity: string;
  lastLoginAt: string;
  lastApplicationAt: string;
  hasContacts: boolean;
  hasUsers: boolean;
  isCorporate: boolean;
  isSupportFund: boolean;
  basicBankLicense: boolean;
  universalBankLicense: boolean;
  accreditedFor44: boolean;
  accreditedFor223: boolean;
  accreditedForSpecialAccounts: boolean;
  accreditedMinfin: boolean;
  accreditedMinagro: boolean;
  productsQty: number;
  usersQty: number;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: Bank[];
}

export const req = () => ({
  type: GET_ALL_BANKS_LIST.REQ
});

export const got = (data: ResponseDataType) => ({
  type: GET_ALL_BANKS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_BANKS_LIST.ERR,
  error
});
