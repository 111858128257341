import { GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getEarlyPaymentApplicationsFactorRole';

export interface EarlyPaymentApplication {
  guid: string;
  createdDateTime: Date;
  debtorInn: string;
  debtorName: string;
  supplierInn: string;
  supplierName: string;
  payDate: Date;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: Date;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  statusDebtor: string;
  agreementFileIdentifier: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  rejectSubject: number;
  rejectComment: string;
  contractId: number;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: EarlyPaymentApplication[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  SupplierInnToFilterBy?: string;
  StartDate?: string;
  EndDate?: string;
  ActionType?: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_ERR,
  error
});
