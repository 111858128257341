import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ScrollTopComponent } from 'src/features/Common';
import { BANK_PRODUCT_TABS } from 'src/features/BankProducts/types';
import { CreditProducts } from 'src/features/BankProducts/components/Tabs/CreditProducts/CreditProducts';
import {
  req as getCreditProducts,
  ResponseData as ResponseCreditProducts
} from 'src/features/BankProducts/actions/getCreditProduct';
import {
  req as getPledges,
  ResponseData as PledgesData
} from 'src/features/BankProducts/actions/getPledge';
import {
  req as getBankAccounts,
  ResponseData as BankAccountsData
} from 'src/features/BankProducts/actions/getBankAccounts';
import {
  req as getOpenLoanLimits,
  ResponseData as ResponseOpenLoanLimits
} from 'Application/actions/getOpenLoanLimits';
import { PledgesBank } from 'src/features/BankProducts/components/Tabs/Pledges/Pledges';
import { BankAccounts } from 'src/features/BankProducts/components/Tabs/BankAccounts/BankAccounts';
import { OpenLoanLimits } from './Tabs/OpenLoanLimits/OpenLoanLimits';
import { Menu } from 'src/features/Layouts/components';

interface StateToProps {
  creditProducts: ResponseCreditProducts;
  statusCreditProducts: REQUEST_STATUSES;
  errorCreditProducts: ResponseError;
  pledges: PledgesData;
  statusPledges: REQUEST_STATUSES;
  errorPledges: ResponseError;
  bankAccounts: BankAccountsData;
  statusBankAccounts: REQUEST_STATUSES;
  errorBankAccounts: ResponseError;
  openLoanLimits: ResponseOpenLoanLimits;
  statusOpenLoanLimits: REQUEST_STATUSES;
  errorOpenLoanLimits: ResponseError;
}

interface State {
  activeTab: string;
}

interface DispatchToProps {
  getCreditProducts: (inn: string) => void;
  getPledges: (clientInn: string) => void;
  getBankAccounts: (inn: string) => void;
  getOpenLoanLimits: (inn: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class BankProducts extends React.Component<Props, State> {
  state = {
    activeTab: 'DEFAULT'
  };

  componentDidMount() {
    this.initFetch();
  }

  initFetch = () => {
    const {
      getCreditProducts,
      getPledges,
      getBankAccounts,
      getOpenLoanLimits
    } = this.props;

    if (this.props.match.params.applicationTab) {
      this.setState({
        activeTab: this.props.match.params.applicationTab.toUpperCase()
      });
    }

    getCreditProducts(this.props.match.params.inn);
    getPledges(this.props.match.params.inn);
    getBankAccounts(this.props.match.params.inn);
    getOpenLoanLimits(this.props.match.params.inn);
  };

  handleActiveTab = (tabName: string) => {
    const { pathname } = this.props.location;
    const baseUrl = pathname.replace(
      `/${this.state.activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    this.props.history.replace(newPathname);
    this.setState({ activeTab: tabName });
  };

  render() {
    const {
      creditProducts,
      statusCreditProducts,
      errorCreditProducts,
      pledges,
      statusPledges,
      errorPledges,
      bankAccounts,
      statusBankAccounts,
      errorBankAccounts,
      openLoanLimits,
      statusOpenLoanLimits,
      errorOpenLoanLimits
    } = this.props;
    return (
      <ScrollTopComponent>
        <Menu
          activeTab={this.state.activeTab}
          handleActiveTab={this.handleActiveTab}
          tabs={BANK_PRODUCT_TABS}
          isSubMenu
        />

        {this.state.activeTab === 'DEFAULT' && (
          <CreditProducts
            creditProducts={creditProducts}
            status={statusCreditProducts}
            error={errorCreditProducts}
          />
        )}

        {this.state.activeTab === 'PLEDGES' && (
          <PledgesBank
            pledges={pledges}
            status={statusPledges}
            error={errorPledges}
          />
        )}

        {this.state.activeTab === 'BANK_ACCOUNTS' && (
          <BankAccounts
            bankAccounts={bankAccounts}
            status={statusBankAccounts}
            error={errorBankAccounts}
          />
        )}

        {this.state.activeTab === 'OPEN_LOAN_LIMITS' && (
          <OpenLoanLimits
            openLoanLimits={openLoanLimits}
            status={statusOpenLoanLimits}
            error={errorOpenLoanLimits}
          />
        )}
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ BankProducts, Application }: CRM) => ({
  creditProducts: BankProducts.getCreditProduct.data,
  statusCreditProducts: BankProducts.getCreditProduct.status,
  errorCreditProducts: BankProducts.getCreditProduct.error,
  pledges: BankProducts.getPledges.data,
  statusPledges: BankProducts.getPledges.status,
  errorPledges: BankProducts.getPledges.error,
  bankAccounts: BankProducts.getBankAccounts.data,
  statusBankAccounts: BankProducts.getBankAccounts.status,
  errorBankAccounts: BankProducts.getBankAccounts.error,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCreditProducts, getPledges, getBankAccounts, getOpenLoanLimits },
    dispatch
  );

const BankProductsConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BankProducts)
);

export { BankProductsConnect as BankProducts };
