import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendProductData';

import { SEND_PRODUCT } from '../reducers/sendProductData';
import ProductsApi from '../api';

function* sendProductData(action: {
  type: SEND_PRODUCT;
  data: any;
}): Generator {
  try {
    let res: any;

    res = yield call(ProductsApi.sendProductData, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendProductDataSaga(): Generator {
  yield takeLatest(SEND_PRODUCT.REQ, sendProductData);
}
