import { combineReducers } from 'redux';
import {
  getApplicationExternal,
  GetApplicationExternalStoreState
} from './getApplicationExternal';
import getQtySidebar, { GetQtySidebarStoreState } from './getQtySidebar';
import getUserStats, { GetUserStatsStoreState } from './getUserStats';
import getExternalLeads, {
  GetExternalLeadsStoreState
} from './getExternalLeads';
import getTenderByApplicationExternal, {
  GetTenderByApplicationExternalStoreState
} from './getTenderByApplicationExternal';
import getDocumentListExternal, {
  GetDocumentListExternalStoreState
} from './getDocumentListExternal';
import postFinancialDocumentExternal, {
  PostFinancialDocumentExternalStoreState
} from './postFinancialDocumentExternal';
import postNonFinancialDocumentExternal, {
  PostNonFinancialDocumentExternalStoreState
} from './postNonFinancialDocumentExternal';
import patchCompanyExternal, {
  PatchCompanyExternalStoreState
} from './patchCompanyExternal';
import getCompanyExternal, {
  GetCompanyExternalStoreState
} from './getCompanyExternal';
import postCreateTenderExternal, {
  PostCreateTenderExternalStoreState
} from './postCreateTenderExternal';
import postAddTenderByEruzExternal, {
  PostAddTenderByEruzExternalStoreState
} from './postAddTenderByEruzExternal';
import postDocumentListExternal, {
  PostDocumentListExternalStoreState
} from './postDocumentListExternal';
import getBeneficiariesOfCompanies, {
  GetBeneficiariesOfCompaniesStoreState
} from './getBeneficiariesOfCompanies';

export interface ExternalAgentRoleReducers {
  getApplicationExternal: GetApplicationExternalStoreState;
  getQtySidebar: GetQtySidebarStoreState;
  getUserStats: GetUserStatsStoreState;
  getExternalLeads: GetExternalLeadsStoreState;
  getTenderByApplicationExternal: GetTenderByApplicationExternalStoreState;
  getDocumentListExternal: GetDocumentListExternalStoreState;
  postFinancialDocumentExternal: PostFinancialDocumentExternalStoreState;
  postNonFinancialDocumentExternal: PostNonFinancialDocumentExternalStoreState;
  patchCompanyExternal: PatchCompanyExternalStoreState;
  getCompanyExternal: GetCompanyExternalStoreState;
  postCreateTenderExternal: PostCreateTenderExternalStoreState;
  postAddTenderByEruzExternal: PostAddTenderByEruzExternalStoreState;
  postDocumentListExternal: PostDocumentListExternalStoreState;
  getBeneficiariesOfCompanies: GetBeneficiariesOfCompaniesStoreState;
}

export const ExternalAgentRole = combineReducers({
  getApplicationExternal,
  getQtySidebar,
  getUserStats,
  getExternalLeads,
  getTenderByApplicationExternal,
  getDocumentListExternal,
  postFinancialDocumentExternal,
  postNonFinancialDocumentExternal,
  patchCompanyExternal,
  getCompanyExternal,
  postCreateTenderExternal,
  postAddTenderByEruzExternal,
  postDocumentListExternal,
  getBeneficiariesOfCompanies
});
