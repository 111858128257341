import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { Input } from 'shared/ui/Input';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import {
  MspSubject,
  SegmentClient
} from 'src/features/Filters/Products/FilterByClient/types';
import {
  MspSubjectContainer,
  RevenueContainer,
  SegmentClientContainer,
  TypeClientContainer
} from 'src/features/Filters/Products/FilterByClient/components/styled';
import { TypeClient } from 'src/features/Filters/Products/FilterByClient/types';
import { CheckboxState, RadioBtnState } from 'src/features/Products/types';

interface Props {
  revenue: number;
  handleRevenue: (event: React.FormEvent<HTMLInputElement>) => void;
  checkboxState: CheckboxState;
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  radioBtnState: RadioBtnState;
  handleRadioBtn: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterByClient: React.FC<Props> = ({
  handleRevenue,
  revenue,
  checkboxState,
  handleFilter,
  radioBtnState,
  handleRadioBtn
}) => {
  return (
    <>
      <RevenueContainer>
        <Input
          value={revenue.toLocaleString('ru')}
          label="Выручка клиента"
          name="revenue"
          placeholder="0"
          onChange={handleRevenue}
        />
      </RevenueContainer>
      <SegmentClientContainer>
        <CheckboxGroup
          name="segmentClient"
          label="Сегмент клиента:"
          checkboxes={Object.keys(SegmentClient).map(type => ({
            label: SegmentClient[type],
            value: type,
            checked: checkboxState.segmentClient.includes(type),
            onChange: handleFilter
          }))}
        />
      </SegmentClientContainer>
      <TypeClientContainer>
        <CheckboxGroup
          name="typeClient"
          label="Тип клиента:"
          checkboxes={Object.keys(TypeClient).map(type => ({
            label: TypeClient[type],
            value: type,
            checked: checkboxState.typeClient.includes(type),
            onChange: handleFilter
          }))}
        />
      </TypeClientContainer>
      <MspSubjectContainer>
        <RadioGroup
          label="Субъект МСП:"
          name="mspsubject"
          keyValue={radioBtnState.mspsubject}
          onChange={handleRadioBtn}
          radioBtns={Object.keys(MspSubject).map(value => ({
            value,
            label: MspSubject[value]
          }))}
        />
      </MspSubjectContainer>
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByClientConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByClient);

export { FilterByClientConnect as FilterByClient };
