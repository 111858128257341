import { GET_FACTORING_LIMIT_BY_DEBTOR_INN } from 'entities/SCF/Debtor/model/reducers/getFactoringLimitByDebtorInn';

export interface MyLimit {
  id: number;
  debtorINN: string;
  debtorShortName: string;
  factorINN: string;
  factorShortName: string;
  volume: number;
  setDate: string;
  isActual: boolean;
  productId: number;
  productName: string;
  isDeleted: boolean;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: MyLimit[];
}

export interface RequestDataType {
  page?: number;
  pageSize?: number;
  debtorInn: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_ERR,
  error
});

export const reset = () => ({
  type:
    GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_RESET
});
