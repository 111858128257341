import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postExternalAgentFinancialStatementsAll';

import { POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL } from '../reducers/postExternalAgentFinancialStatementsAll';
import FinancialStatementsApi from '../api';

function* postExternalAgentFinancialStatementsAll(action: {
  type: POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL;
  data;
}): Generator {
  try {
    yield call(
      FinancialStatementsApi.postExternalAgentFinancialStatementsAll,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postExternalAgentFinancialStatementsAllSaga(): Generator {
  yield takeLatest(
    POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL.REQ,
    postExternalAgentFinancialStatementsAll
  );
}
