import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { BeneficiariesOfCompanies } from '../types';

export interface GetBeneficiariesOfCompaniesStoreState extends Reducer {
  data: BeneficiariesOfCompanies[];
}

export enum GET_BENEFICIARIES_OF_COMPANIES {
  REQ = 'GET_BENEFICIARIES_OF_COMPANIES_REQ',
  GOT = 'GET_BENEFICIARIES_OF_COMPANIES_GOT',
  ERR = 'GET_BENEFICIARIES_OF_COMPANIES_ERR'
}

export const initialState: GetBeneficiariesOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getBeneficiariesOfCompanies = (
  state: GetBeneficiariesOfCompaniesStoreState = initialState,
  action: {
    type: GET_BENEFICIARIES_OF_COMPANIES;
    data: BeneficiariesOfCompanies[];
    error: {};
  }
): GetBeneficiariesOfCompaniesStoreState => {
  switch (action.type) {
    case GET_BENEFICIARIES_OF_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BENEFICIARIES_OF_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_BENEFICIARIES_OF_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBeneficiariesOfCompanies;
