import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/getClientApplication';
import {
  GET_CLIENT_APPLICATION,
  RequestDataType
} from 'entities/Cabinet/Client/model/reducers/getClientApplication';

import ApplicationApi from 'entities/Cabinet/Client/api';

function* getClientApplication(action: {
  type: GET_CLIENT_APPLICATION;
  data: RequestDataType;
}) {
  try {
    const res = yield call(ApplicationApi.getClientApplication, action.data.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getClientApplicationSaga() {
  yield takeLatest(GET_CLIENT_APPLICATION.REQ, getClientApplication);
}
