import { combineReducers } from 'redux';
import getDocumentList, { GetDocumentListStoreState } from './getDocumentList';
import postFinFile, { PostFinFileStoreState } from './postFinFile';
import postNotFinFile, { PostNotFinFileStoreState } from './postNotFinFile';

export interface DocumentListReducers {
  getDocumentList: GetDocumentListStoreState;
  postFinFile: PostFinFileStoreState;
  postNotFinFile: PostNotFinFileStoreState;
}

export const DocumentList = combineReducers({
  getDocumentList,
  postFinFile,
  postNotFinFile
});
