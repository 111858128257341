import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import {
  PurchaseContract,
  setPurchaseContract
} from 'src/features/SCF/actions/setPurchaseContract';
import {
  req as sendPurchaseContract,
  RequestData as SendPurchaseContract
} from 'src/features/SCF/actions/sendPurchaseContract';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { REQUEST_STATUSES, STORE, ResponseError } from 'src/globaltypes';
import { SetPurchaseContractStoreState } from 'src/features/SCF/reducers/setPurchaseContract';
import { InnPlaceholders } from 'shared/constants';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import {
  CreateContractPageStyled,
  InnFilter,
  InnContainer,
  InnArea,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  InfoField
} from './styles';
import './styles/style.css';
import { Button } from 'shared/ui/Button';

interface StateToProps extends Partial<PurchaseContract> {
  isSendingData: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  sendPurchaseContract: (data: SendPurchaseContract) => void;
  setPurchaseContract: (data: SetPurchaseContractStoreState) => void;
  getCompanyByInn: (data: RequestDataType) => void;
}

interface State {
  isPerpetualState: boolean;
  supplierInn: string;
  isInfoPopup: boolean;
  companyByInnPre: ResponseDataType;
  isPaymentDefermentFixed: boolean;
  paymentDeferment: number;
}

type LocationState = {
  inn: string;
};

type Props = RouteComponentProps<{}, StaticContext, LocationState> &
  StateToProps &
  DispatchToProps;

class ContractsCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        isPerpetualState: false,
        isPaymentDefermentFixed: false,
        paymentDeferment: 0,
        supplierInn:
          (this.props.location.state && this.props.location.state.inn) || '',
        isInfoPopup: false,
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          taxSystemName: '',
          employees: null,
          employeesYear: null,
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      };
    }
  }

  componentDidMount(): void {
    if (this.props.location.state && this.props.location.state.inn) {
      this.props.getCompanyByInn({ inn: this.props.location.state.inn });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.supplierInn !== this.state.supplierInn) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.supplierInn !== this.state.supplierInn &&
      this.state.supplierInn.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.supplierInn });
    }
  }

  handlePerpetualChange = () => {
    this.setState({ isPerpetualState: !this.state.isPerpetualState });
  };

  onSubmit = () => {
    this.props.sendPurchaseContract({
      supplierInn: this.state.supplierInn,
      supplierName: this.props.supplierName,
      contractName: this.props.contractName,
      contractNumber: this.props.contractNumber,
      contractStartDate: this.props.contractStartDate,
      contractEndDate: this.props.contractEndDate,
      isPerpetual: this.state.isPerpetualState,
      paymentDefermentFixed: this.state.isPaymentDefermentFixed,
      paymentDeferment: this.state.paymentDeferment
    });
    this.props.history.replace('/debtor/purchase_contracts?page=1');
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (name === 'supplierInn') {
      this.setState({ [name]: value });
    } else {
      this.props.setPurchaseContract({ [name]: value });
    }
  };

  handlePaymentDefermentChange = () => {
    this.setState(prevState => ({
      ...prevState,
      isPaymentDefermentFixed: !prevState.isPaymentDefermentFixed
    }));
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  render() {
    const {
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      companyByInn,
      status
    } = this.props;

    return (
      <CreateContractPageStyled>
        <h2>+ Новый договор</h2>
        <InnArea>
          <InnFilter>
            <p>Поставщик:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder={InnPlaceholders.entity}
                maxLength={10}
                name="supplierInn"
                value={this.state.supplierInn}
                onChange={this.onChange}
              />
            </InnContainer>

            {this.state.supplierInn &&
            this.state.supplierInn.length < 10 ? null : status ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              status === REQUEST_STATUSES.GOT &&
              !this.state.isInfoPopup && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={this.onPopupClick}
                />
              )
            )}

            {this.state.isInfoPopup && (
              <InfoField>
                <h2>{companyByInn.companyFullName}</h2>
                {companyByInn.companyAddress &&
                companyByInn.companyAddress.length > 3 ? (
                  <p>{companyByInn.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {companyByInn.inn}</p>
                <p>КПП {companyByInn.kpp}</p>
              </InfoField>
            )}
          </InnFilter>
        </InnArea>

        <ContractsInfoArea>
          <h1>Реквизиты договора:</h1>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Наименование договора
            </ContractsInfoItemTitle>
            <input
              type="text"
              value={contractName}
              name="contractName"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Номер договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="text"
              value={contractNumber}
              name="contractNumber"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Дата договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Срок действия договора
            </ContractsInfoItemTitle>
            <p>c</p>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '20px' }}>по</p>
            <input
              className="contract-input"
              type="date"
              value={contractEndDate}
              name="contractEndDate"
              onChange={this.onChange}
            />
            {this.state.isPerpetualState == true ? (
              <SelectContainerActive
                name="isPerpetual"
                value={this.state.isPerpetualState}
                onClick={this.handlePerpetualChange}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                name="isPerpetual"
                value={this.state.isPerpetualState}
                onClick={this.handlePerpetualChange}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>бессрочный</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>

        <ContractsInfoArea>
          <h1>Условия оплаты:</h1>
          <p>Отсрочка платежа</p>
          <ContractsInfoItemContainer>
            {this.state.isPaymentDefermentFixed === true ? (
              <SelectContainerActive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>фиксированная</p>
            {this.state.isPaymentDefermentFixed && (
              <>
                <input
                  className="short-input"
                  type="number"
                  value={this.state.paymentDeferment}
                  onChange={e => {
                    this.setState({
                      paymentDeferment: +e.currentTarget.value
                    });
                  }}
                />
                <p>дней</p>
              </>
            )}
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            {this.state.isPaymentDefermentFixed === false ? (
              <SelectContainerActive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>плавающая</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>

        {this.state.supplierInn.length < 10 ||
        contractName.length < 1 ||
        contractNumber.length < 1 ||
        contractStartDate.length < 1 ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : this.state.isPerpetualState == false && contractEndDate ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : (
          <Button
            type="submit"
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        )}
      </CreateContractPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  isSendingData: SCF.sendPurchaseContract.status,
  ...SCF.setPurchaseContract
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendPurchaseContract,
      setPurchaseContract,
      getCompanyByInn
    },
    dispatch
  );

const ContractsCreateConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractsCreate)
);

export { ContractsCreateConnect as ContractsCreate };
