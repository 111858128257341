import getCompaniesListCRMSaga from './getCompaniesList';
import getCompaniesListByIdSaga from './getCompaniesListById';
import postCompaniesListCRMSaga from './postCompaniesList';
import getCompaniesOfCompaniesSaga from './getCompaniesOfCompanies';
import postCompaniesOfCompaniesSaga from './postCompaniesOfCompanies';
import postAllInListRefreshSaga from './postAllInListRefresh';
import getInteractionSummarySaga from './getInteractionSummary';

export default [
  getCompaniesListCRMSaga,
  postCompaniesListCRMSaga,
  getCompaniesOfCompaniesSaga,
  getCompaniesListByIdSaga,
  postCompaniesOfCompaniesSaga,
  postAllInListRefreshSaga,
  getInteractionSummarySaga
];
