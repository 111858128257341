import { PUT_DECLINE_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/putDeclineByDebtor';

export const req = (guid: string) => ({
  type: PUT_DECLINE_BY_DEBTOR.REQ,
  guid
});

export const got = () => ({
  type: PUT_DECLINE_BY_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: PUT_DECLINE_BY_DEBTOR.ERR,
  error
});
