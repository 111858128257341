import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as sendResetPasswordLetterRequest,
  reset as sendResetPasswordLetterReset
} from 'User/actions/sendResetPasswordLetter';

import { Spinner } from 'Layouts/components';
import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';

import { SuccessIcon, ErrorIcon } from 'shared/styled';
import {
  ForgotPasswordModalStyled,
  ForgotPasswordForm,
  ForgotPasswordInput
} from './styles';

import { createValidation } from './validator';
import { Button } from 'shared/ui/Button';

export interface State {
  submitClicked: boolean;
  email: string;
}

interface StateToProps {
  emailSendStatus: REQUEST_STATUSES;
  emailSendError: ResponseError;
}

interface DispatchToProps {
  sendResetPasswordLetterRequest: (email: string) => void;
  sendResetPasswordLetterReset: () => void;
}

type Props = StateToProps & DispatchToProps;

class ForgotPasswordModal extends React.Component<Props, State> {
  Validator = createValidation();

  state = {
    submitClicked: false,
    email: ''
  };

  componentDidMount() {
    this.props.sendResetPasswordLetterReset();
  }

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    this.props.sendResetPasswordLetterRequest(this.state.email);
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value.replace(/\s+/g, '')
    } as { [key in keyof State]: any });
  };

  renderErrorMessage = () => {
    switch (this.props.emailSendError.code) {
      case 606:
        return (
          <React.Fragment>
            <ErrorIcon size={92} />
            <p>
              Пожалуйста, убедитесь в корректности введенного Вами email.
              Указанный email не зарегистрирован в системе
            </p>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <ErrorIcon size={92} />
            <p>Что-то пошло не так. Пожалуйста, попробуйте позже.</p>
          </React.Fragment>
        );
    }
  };

  renderModalView = errors => {
    switch (this.props.emailSendStatus) {
      case REQUEST_STATUSES.NONE:
        return (
          <React.Fragment>
            <h2>Восстановление пароля</h2>
            <p>
              Введите адрес электронной почты, и мы вышлем вам инструкции по
              восстановлению пароля.
            </p>

            <ForgotPasswordForm
              onSubmit={this.onSubmit}
              name="reset-password-form"
            >
              <ForgotPasswordInput
                value={this.state.email}
                label="Email"
                name="email"
                placeholder="Email"
                required={true}
                error={errors.email}
                onChange={this.onChange}
              />

              <Button
                type="submit"
                label="Восстановить пароль"
                template="nextBtn"
              />
            </ForgotPasswordForm>
          </React.Fragment>
        );
      case REQUEST_STATUSES.GOT:
        return (
          <React.Fragment>
            <SuccessIcon size={92} />
            <p>
              Инструкция по восстановлению пароля отправлена  на адрес{' '}
              <b>{this.state.email}</b>
            </p>
          </React.Fragment>
        );
      case REQUEST_STATUSES.ERROR:
        return this.renderErrorMessage();
      default:
        return <Spinner />;
    }
  };

  render() {
    const { errors } = this.Validator.validate(this.state);
    return (
      <Modal name={MODAL_NAMES.FORGOT_PASSWORD}>
        <ForgotPasswordModalStyled>
          {this.renderModalView(errors)}
        </ForgotPasswordModalStyled>
      </Modal>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  emailSendStatus: User.sendResetPasswordLetter.status,
  emailSendError: User.sendResetPasswordLetter.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { sendResetPasswordLetterRequest, sendResetPasswordLetterReset },
    dispatch
  );

const ForgotPasswordModalConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);

export { ForgotPasswordModalConnect as ForgotPasswordModal };
