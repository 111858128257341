import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/getSupplierNotificationSettings';
import { SUPPLIER_NOTIFICATION_SETTINGS } from 'entities/SCF/Supplier/model/reducers/getSupplierNotificationSettings';

import SCFApi from 'entities/SCF/Supplier/api';

function* getSupplierNotificationSettingsData(action: {
  type: SUPPLIER_NOTIFICATION_SETTINGS;
}) {
  try {
    const res: any = yield call(SCFApi.getSupplierNotificationSettings);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplierNotificationSettingsDataSaga() {
  yield takeLatest(
    SUPPLIER_NOTIFICATION_SETTINGS.REQ,
    getSupplierNotificationSettingsData
  );
}
