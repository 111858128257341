import { SEND_COMPANY_INFO_DATA } from '../reducers/sendCompanyInfoData';
import { CompanyInfoWrite } from './setCompanyInfo';

export interface RequestData extends Partial<CompanyInfoWrite> {}

export const req = (data: RequestData) => ({
  type: SEND_COMPANY_INFO_DATA.REQ,
  data
});

export const got = () => ({
  type: SEND_COMPANY_INFO_DATA.GOT
});

export const err = (error: {}) => ({
  type: SEND_COMPANY_INFO_DATA.ERR,
  error
});
