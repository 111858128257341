import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'src/entities/Cabinet/Bank/model/actions/getApplicationBank';
import { GET_APPLICATION_BANK } from 'entities/Cabinet/Bank/model/reducers/getApplicationBank';

import ApplicationApi from 'entities/Cabinet/Bank/api';

function* getApplicationBank(action: {
  type: GET_APPLICATION_BANK;
  id: string;
}) {
  try {
    const res = yield call(ApplicationApi.getApplicationBank, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationBankSaga() {
  yield takeLatest(GET_APPLICATION_BANK.REQ, getApplicationBank);
}
