import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileArchive,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import { history } from 'src/shared/utils/History';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Icon } from 'shared/ui/Icon';
import SignatureImg from 'src/assets/icons/signature.svg';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  FiltredBeneficiariesType,
  FiltredPersonsType,
  NonFinancialDocumentsData
} from 'src/features/DossierForCompany/types';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader
} from 'src/features/Layouts/components';
import { req as getDonwloadNonFinDocument } from 'src/features/DossierForCompany/actions/getDonwloadNonFinDocument';
import { req as getDonwloadNonFinDocumentSignature } from 'src/features/DossierForCompany/actions/getDonwloadNonFinDocumentSignature';
import { FileContainer } from '../FinancialDocuments/styled';
import { FinancialDocumentsContainer, TitleTabContainer } from './styled';

interface OwnProps {
  fin_documents: NonFinancialDocumentsData[];
  status: REQUEST_STATUSES;
  error: ResponseError;
  persons: FiltredPersonsType[];
  statusPersons: REQUEST_STATUSES;
  errorPersons: ResponseError;
  beneficiaries: FiltredBeneficiariesType[];
  statusBeneficiaries: REQUEST_STATUSES;
  errorBeneficiaries: ResponseError;
}

interface DispatchToProps {
  getDonwloadNonFinDocument: (guid: string) => void;
  getDonwloadNonFinDocumentSignature: (guid: string) => void;
}

type Props = OwnProps & DispatchToProps;

const NonFinancialDocumets: React.FC<Props> = ({
  getDonwloadNonFinDocument,
  getDonwloadNonFinDocumentSignature,
  fin_documents,
  status,
  error,
  persons,
  statusPersons,
  beneficiaries,
  statusBeneficiaries
}) => {
  const getIconByFileType = (fileName: string, guid: string) => {
    if (!fileName) return;

    const matchResult = fileName.match(/\.(\w+)$/);
    if (matchResult) {
      const fileExtension = matchResult[1];
      if (fileExtension === 'doc' || fileExtension === 'docx') {
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            size="lg"
            onClick={() => getDonwloadNonFinDocument(guid)}
          />
        );
      } else if (fileExtension === 'pdf') {
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            size="lg"
            onClick={() => getDonwloadNonFinDocument(guid)}
          />
        );
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        return (
          <FontAwesomeIcon
            icon={faFileExcel}
            size="lg"
            onClick={() => getDonwloadNonFinDocument(guid)}
          />
        );
      } else if (fileExtension === 'zip' || fileExtension === 'rar') {
        return (
          <FontAwesomeIcon
            icon={faFileArchive}
            size="lg"
            onClick={() => getDonwloadNonFinDocument(guid)}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faFile}
            size="lg"
            onClick={() => getDonwloadNonFinDocument(guid)}
          />
        );
      }
    }
    return null;
  };

  const navigateToPerson = (personInn: string) => {
    history.push(`/crm/persons/${personInn}`);
  };

  const formatName = (name: string) => {
    const words = name.split(' ');

    return words
      .map(word => {
        return word.charAt(0) + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      <FinancialDocumentsContainer>
        <TitleTabContainer isDocs={true}>
          <p>Документы компании</p>
        </TitleTabContainer>

        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Наименование файла</TableThStyled>
                <TableThStyled width="10%">Тип документа</TableThStyled>
                <TableThStyled width="10%">Период</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {fin_documents.map(doc => (
                <TableRowStyled key={doc.id}>
                  <td>
                    <FileContainer>
                      <div>{doc.documentFileName}</div>
                      <div>
                        {getIconByFileType(doc.documentFileName, doc.guid)}
                        {doc.signatureFileName && (
                          <Icon
                            iconLink={SignatureImg}
                            width={20}
                            onClick={() =>
                              getDonwloadNonFinDocumentSignature(doc.guid)
                            }
                          />
                        )}
                      </div>
                    </FileContainer>
                  </td>
                  <td>{doc.documentName}</td>
                  <td>
                    {doc.periodStartDate && doc.periodEndDate
                      ? `${new Date(
                          doc.periodStartDate
                        ).toLocaleDateString()} - ${new Date(
                          doc.periodEndDate
                        ).toLocaleDateString()}`
                      : '-'}
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}

        {statusPersons === REQUEST_STATUSES.REQUEST && <Loader />}

        {statusPersons === REQUEST_STATUSES.GOT &&
          persons.map(person => (
            <React.Fragment key={person.id}>
              <TitleTabContainer
                onClick={() => navigateToPerson(person.personInn)}
              >
                <p>
                  {formatName(
                    `${person.lastName} ${person.firstName} ${
                      person.middleName
                    }`
                  )}{' '}
                  - {formatName(person.position)}
                </p>
              </TitleTabContainer>

              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="10%">
                      Наименование файла
                    </TableThStyled>
                    <TableThStyled width="10%">Тип документа</TableThStyled>
                    <TableThStyled width="10%">Период</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {person.documents.map(doc => (
                    <TableRowStyled key={doc.id}>
                      <td>
                        <FileContainer>
                          <div>{doc.documentFileName}</div>
                          <div>
                            {getIconByFileType(doc.documentFileName, doc.guid)}
                            {doc.signatureFileName && (
                              <Icon
                                iconLink={SignatureImg}
                                width={20}
                                onClick={() =>
                                  getDonwloadNonFinDocumentSignature(doc.guid)
                                }
                              />
                            )}
                          </div>
                        </FileContainer>
                      </td>
                      <td>{doc.documentName}</td>
                      <td>{doc.periods}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </React.Fragment>
          ))}

        {statusBeneficiaries === REQUEST_STATUSES.REQUEST && <Loader />}

        {statusPersons === REQUEST_STATUSES.GOT &&
          beneficiaries.map(beneficiar => (
            <React.Fragment key={beneficiar.id}>
              <TitleTabContainer
                onClick={() => navigateToPerson(beneficiar.personInn)}
              >
                <p>
                  {formatName(
                    `${beneficiar.lastName} ${beneficiar.firstName} ${
                      beneficiar.middleName
                    }`
                  )}{' '}
                  - Бенефициар, {beneficiar.ownershipShareDeJure}%
                </p>
              </TitleTabContainer>

              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="10%">
                      Наименование файла
                    </TableThStyled>
                    <TableThStyled width="10%">Тип документа</TableThStyled>
                    <TableThStyled width="10%">Период</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {beneficiar.documents.map(doc => (
                    <TableRowStyled key={doc.id}>
                      <td>
                        <FileContainer>
                          <div>{doc.documentFileName}</div>
                          <div>
                            {getIconByFileType(doc.documentFileName, doc.guid)}
                            {doc.signatureFileName && (
                              <Icon
                                iconLink={SignatureImg}
                                width={20}
                                onClick={() =>
                                  getDonwloadNonFinDocumentSignature(doc.guid)
                                }
                              />
                            )}
                          </div>
                        </FileContainer>
                      </td>
                      <td>{doc.documentName}</td>
                      <td>{doc.periods}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </React.Fragment>
          ))}
      </FinancialDocumentsContainer>

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({  }: CRM) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDonwloadNonFinDocument,
      getDonwloadNonFinDocumentSignature
    },
    dispatch
  );

const NonFinancialDocumetsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(NonFinancialDocumets);

export { NonFinancialDocumetsConnect as NonFinancialDocumets };
