import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import {
  AbsoluteValue,
  LinkedToKS,
  ManualDiscountBlock,
  PageBlock,
  SuppliersRate
} from './styles';
import {
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive
} from './settingsStyles';

import {
  BtnsContainer,
  BackBtn,
  BackBtnText,
  CreateBtn,
  CreateBtnText
} from 'src/features/SCF/UI/ButtonsStyles';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { req as getKeyRates } from 'src/features/SCF/actions/getKeyRates';
import {
  req as getFactoringSettings,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getCurrentFactoringSettingsByDebtorInn';
import {
  req as getFactoringFeesOfLimits,
  Limit,
  RequestDataType as factoringFeesOfLimitsReq
} from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { navyBlue } from 'shared/styled';
import '../../styles/style.css';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';

interface StateToProps {
  factoringSettings: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  commissions: Limit[];
  statusCommission: REQUEST_STATUSES;
  keyRate: number;
}

interface DispatchToProps {
  getFactoringSettings: (data: RequestDataType) => void;
  getFactoringFeesOfLimits: (
    inn: string,
    data?: factoringFeesOfLimitsReq
  ) => void;
  getKeyRates: () => void;
}

interface State {
  monetaryClaim: number;
  remainingPayment: number;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const LimitSettings: React.FC<Props> = ({
  getKeyRates,
  keyRate,
  getFactoringSettings,
  factoringSettings,
  getFactoringFeesOfLimits,
  commissions,
  statusCommission,
  history,
  match
}) => {
  const [supplierRateFilters, setSupplierRateFilters] = React.useState<State>({
    monetaryClaim: 1000000,
    remainingPayment: 60
  });

  React.useEffect(
    () => {
      if (match.params.id) {
        getFactoringSettings({ debtorInn: match.params.id });
        getFactoringFeesOfLimits(match.params.id);
        getKeyRates();
      }
    },
    [match.params.id]
  );

  const onEditClick = () => {
    history.push(`/factor/limits/${match.params.id}/edit`);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setSupplierRateFilters({ ...supplierRateFilters, [name]: +value });
  };

  const concededClaim = (): number => {
    return (
      (supplierRateFilters.monetaryClaim *
        factoringSettings.defaultMaximalCessionShare) /
      100
    );
  };

  const amountOfFinancing = (): number => {
    return (
      (concededClaim() * factoringSettings.defaultMaximalShareOfFunding) / 100
    );
  };

  const actualAmountOfFinancing = (): number => {
    return (
      amountOfFinancing() - commissionAtTheTime() - VATonCommissionsAtTheTime()
    );
  };

  const interestPaymentsExcludingVAT = (): number => {
    const factoringBaseRate =
      factoringSettings.rateIsSurplusToKeyRate === false
        ? factoringSettings.factoringBaseRate
        : keyRate + factoringSettings.factoringBaseRate;

    return factoringSettings.factoringRateBase === '1'
      ? supplierRateFilters.monetaryClaim *
          (supplierRateFilters.remainingPayment / 365) *
          (factoringBaseRate / 100)
      : factoringSettings.factoringRateBase === '2'
        ? concededClaim() *
          (supplierRateFilters.remainingPayment / 365) *
          (factoringBaseRate / 100)
        : factoringSettings.factoringRateBase === '3' &&
          amountOfFinancing() *
            (supplierRateFilters.remainingPayment / 365) *
            (factoringBaseRate / 100);
  };

  const VATOnInterestPayments = (): number => {
    return (interestPaymentsExcludingVAT() * factoringSettings.vatSize) / 100;
  };

  const fullAmountExcludingVAT = (): number => {
    return (
      interestPaymentsExcludingVAT() +
      commissionsAtTheTimeOfRepayment() +
      commissionAtTheTime()
    );
  };

  const VATOnPaymentsAndCommissions = (): number => {
    return (
      VATOnInterestPayments() +
      VATOnCommissionsAtTheTimeOfRepayment() +
      VATonCommissionsAtTheTime()
    );
  };

  const commissionAtTheTime = (): number => {
    const sum = commissions.reduce((total, item) => {
      if (item.momentOfPayment === 1) {
        if (item.feeBase === 4) {
          return total + item.feeBaseSumm;
        } else if (item.feeBase === 3) {
          return total + (amountOfFinancing() * item.feeBaseSumm) / 100;
        } else if (item.feeBase === 2) {
          return total + (concededClaim() * item.feeBaseSumm) / 100;
        } else if (item.feeBase === 1) {
          return (
            total + (supplierRateFilters.monetaryClaim * item.feeBaseSumm) / 100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  const VATonCommissionsAtTheTime = (): number => {
    const sum = commissions.reduce((total, item) => {
      if (item.momentOfPayment === 1) {
        if (item.feeBase === 4) {
          return total + (item.feeBaseSumm * item.vatPercentage) / 100;
        } else if (item.feeBase === 3) {
          return (
            total +
            (((amountOfFinancing() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase === 2) {
          return (
            total +
            (((concededClaim() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase === 1) {
          return (
            total +
            (((supplierRateFilters.monetaryClaim * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  const commissionsAtTheTimeOfRepayment = (): number => {
    const sum = commissions.reduce((total, item) => {
      if (item.momentOfPayment === 2) {
        if (item.feeBase === 4) {
          return total + item.feeBaseSumm;
        } else if (item.feeBase === 3) {
          return total + (amountOfFinancing() * item.feeBaseSumm) / 100;
        } else if (item.feeBase === 2) {
          return total + (concededClaim() * item.feeBaseSumm) / 100;
        } else if (item.feeBase === 1) {
          return (
            total + (supplierRateFilters.monetaryClaim * item.feeBaseSumm) / 100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  const VATOnCommissionsAtTheTimeOfRepayment = (): number => {
    const sum = commissions.reduce((total, item) => {
      if (item.momentOfPayment === 2) {
        if (item.feeBase === 4) {
          return total + (item.feeBaseSumm * item.vatPercentage) / 100;
        } else if (item.feeBase === 3) {
          return (
            total +
            (((amountOfFinancing() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase === 2) {
          return (
            total +
            (((concededClaim() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase === 1) {
          return (
            total +
            (((supplierRateFilters.monetaryClaim * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  return (
    <>
      <PageBlock>
        <div>
          <p>
            Указанные здесь настройки будут отображаться по умолчанию для
            клиентов, с которыми еще не заключен договор факторинга.
            <br />
            Для клиентов с заключенными договорами факторинга будут отображаться
            условия соответствующих договоров. <br />
            Для поставщиков, исключенных из работы банком, условия отображаться
            не будут.
          </p>
          <BtnsContainer>
            <CreateBtn onClick={onEditClick}>
              <CreateBtnText>Редактировать</CreateBtnText>
            </CreateBtn>
            <BackBtn>
              <BackBtnText>История изменений</BackBtnText>
            </BackBtn>
          </BtnsContainer>
          <ContractsInfoArea>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>Объем лимита:</ContractsInfoItemTitle>
              <input
                className="contract-input"
                disabled={true}
                value={
                  factoringSettings.volume.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }) || ''
                }
              />
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              {factoringSettings.autoLimitPolicy == 2 ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>Автоматически уменьшать на сумму использования лимита</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Использование лимита:
              </ContractsInfoItemTitle>
              {factoringSettings.limitUsage > 0 ? (
                <p style={{ width: '150px', textAlign: 'end' }}>
                  {factoringSettings.limitUsage.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </p>
              ) : (
                <p style={{ width: '150px', textAlign: 'end' }}>0</p>
              )}
              <p>руб.</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Свободный остаток:
              </ContractsInfoItemTitle>
              <p style={{ width: '150px', textAlign: 'end' }}>
                {(
                  factoringSettings.defaultMaximalClaimSum -
                  factoringSettings.limitUsage
                ).toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </p>
              <p>руб.</p>
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
          <ContractsInfoArea>
            <h1>Тип факторинга:</h1>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>Без регресса</ContractsInfoItemTitle>
              {factoringSettings.isRegressed == true ? (
                <TypeContainer>
                  <TypeNoActive>
                    <p>нет</p>
                  </TypeNoActive>
                  <TypeYesInactive>
                    <p>да</p>
                  </TypeYesInactive>
                </TypeContainer>
              ) : (
                <TypeContainer>
                  <TypeNoInactive>
                    <p>нет</p>
                  </TypeNoInactive>
                  <TypeYesActive>
                    <p>да</p>
                  </TypeYesActive>
                </TypeContainer>
              )}
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>Открытый</ContractsInfoItemTitle>
              {factoringSettings.isNotHidden == false ? (
                <TypeContainer>
                  <TypeNoActive>
                    <p>нет</p>
                  </TypeNoActive>
                  <TypeYesInactive>
                    <p>да</p>
                  </TypeYesInactive>
                </TypeContainer>
              ) : (
                <TypeContainer>
                  <TypeNoInactive>
                    <p>нет</p>
                  </TypeNoInactive>
                  <TypeYesActive>
                    <p>да</p>
                  </TypeYesActive>
                </TypeContainer>
              )}
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
          <ContractsInfoArea>
            <h1>Параметры финансирования:</h1>
            <ContractsInfoItemContainer>
              <p>Срок отсрочки от</p>
              <input
                className="short-input"
                disabled={true}
                value={
                  factoringSettings.defaultMinimalClaimDaysToMaturity || ''
                }
              />
              <p>до</p>
              <input
                className="short-input"
                disabled={true}
                value={
                  factoringSettings.defaultMaximalClaimDaysToMaturity || ''
                }
              />
              <p>дней</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
              <ContractsInfoItemTitle>
                Ставка в период отсрочки:
              </ContractsInfoItemTitle>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>
                База расчета для ставки:
              </ContractsInfoItemTitle>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
              {factoringSettings.factoringRateBase === '3' ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>Сумма финансирования</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
              {factoringSettings.factoringRateBase === '2' ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>Сумма уступленного денежного требования</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
              {factoringSettings.factoringRateBase === '1' ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>Базовая сумма денежного требования</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ManualDiscountBlock>
                <ContractsInfoItemTitle>
                  Базовая ставка факторинга
                </ContractsInfoItemTitle>

                <div>
                  <AbsoluteValue>
                    {factoringSettings.rateIsSurplusToKeyRate === false ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Абсолютное значение</p>
                        <input
                          className="short-input"
                          disabled={true}
                          value={factoringSettings.factoringBaseRate}
                        />
                        <p style={{ marginLeft: '10px' }}>% годовых</p>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Абсолютное значение</p>
                      </>
                    )}
                  </AbsoluteValue>

                  <LinkedToKS>
                    {factoringSettings.rateIsSurplusToKeyRate === true ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Привязаное к КС</p>
                        <div>
                          <p>Ключ +</p>
                          <input
                            className="short-input"
                            disabled={true}
                            value={factoringSettings.factoringBaseRate}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Привязаное к КС</p>
                      </>
                    )}
                  </LinkedToKS>
                </div>
              </ManualDiscountBlock>
            </ContractsInfoItemContainer>

            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.vatSize || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>
                Полная ставка факторинга
              </ContractsInfoItemTitle>
              <p>
                {(factoringSettings.rateIsSurplusToKeyRate === false
                  ? factoringSettings.factoringBaseRate *
                    (1 + factoringSettings.vatSize / 100)
                  : (keyRate + factoringSettings.factoringBaseRate) *
                    (1 + factoringSettings.vatSize / 100)
                ).toFixed(2)}
              </p>
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              {factoringSettings.factualUsageOnly === true ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>Оплата только за фактическое использование</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
              <ContractsInfoItemTitle>Льготный период:</ContractsInfoItemTitle>
              <input
                style={{ marginLeft: '40px' }}
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultGracePeriodDays || ''}
              />
              <p>дней</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ManualDiscountBlock>
                <ContractsInfoItemTitle>
                  Базовая ставка льготного периода
                </ContractsInfoItemTitle>

                <div>
                  <AbsoluteValue>
                    {factoringSettings.rateIsSurplusToKeyRate === false ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Абсолютное значение</p>
                        <input
                          className="short-input"
                          disabled={true}
                          value={factoringSettings.gracePeriodBaseRate}
                        />
                        <p style={{ marginLeft: '10px' }}>% годовых</p>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Абсолютное значение</p>
                      </>
                    )}
                  </AbsoluteValue>

                  <LinkedToKS>
                    {factoringSettings.rateIsSurplusToKeyRate === true ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Привязаное к КС</p>
                        <div>
                          <p>Ключ +</p>
                          <input
                            className="short-input"
                            disabled={true}
                            value={factoringSettings.gracePeriodBaseRate}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Привязаное к КС</p>
                      </>
                    )}
                  </LinkedToKS>
                </div>
              </ManualDiscountBlock>
            </ContractsInfoItemContainer>

            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={
                  factoringSettings.vatSize !== 0
                    ? factoringSettings.vatSize
                    : null
                }
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>
                Полная ставка льготного периода
              </ContractsInfoItemTitle>
              <p>
                {(factoringSettings.rateIsSurplusToKeyRate === false
                  ? factoringSettings.gracePeriodBaseRate *
                    (1 + factoringSettings.vatSize / 100)
                  : (keyRate + factoringSettings.gracePeriodBaseRate) *
                    (1 + factoringSettings.vatSize / 100)
                ).toFixed(2)}
              </p>
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
              <ContractsInfoItemTitle>Период регресса:</ContractsInfoItemTitle>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              {factoringSettings.regressPeriodIsInfinite === false ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>не более</p>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultRegressPeriodDays || ''}
              />
              <p>дней</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              {factoringSettings.regressPeriodIsInfinite === true ? (
                <SelectContainerActive>
                  <SelectActive />
                </SelectContainerActive>
              ) : (
                <SelectContainerInactive>
                  <SelectInactive />
                </SelectContainerInactive>
              )}
              <p>не ограничен до даты погашения</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ManualDiscountBlock>
                <ContractsInfoItemTitle>
                  Базовая ставка периода регресса
                </ContractsInfoItemTitle>

                <div>
                  <AbsoluteValue>
                    {factoringSettings.rateIsSurplusToKeyRate === false ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Абсолютное значение</p>
                        <input
                          className="short-input"
                          disabled={true}
                          value={factoringSettings.regressPeriodBaseRate}
                        />
                        <p style={{ marginLeft: '10px' }}>% годовых</p>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Абсолютное значение</p>
                      </>
                    )}
                  </AbsoluteValue>

                  <LinkedToKS>
                    {factoringSettings.rateIsSurplusToKeyRate === true ? (
                      <>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Привязаное к КС</p>
                        <div>
                          <p>Ключ +</p>
                          <input
                            className="short-input"
                            disabled={true}
                            value={factoringSettings.regressPeriodBaseRate}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <SelectContainerInactive>
                          <SelectInactive />
                        </SelectContainerInactive>
                        <p>Привязаное к КС</p>
                      </>
                    )}
                  </LinkedToKS>
                </div>
              </ManualDiscountBlock>
            </ContractsInfoItemContainer>

            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.vatSize || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
              <ContractsInfoItemTitle>
                Полная ставка периода регресса
              </ContractsInfoItemTitle>
              <p>
                {(factoringSettings.rateIsSurplusToKeyRate === false
                  ? factoringSettings.regressPeriodBaseRate *
                    (1 + factoringSettings.vatSize / 100)
                  : (keyRate + factoringSettings.regressPeriodBaseRate) *
                    (1 + factoringSettings.vatSize / 100)
                ).toFixed(2)}
              </p>
              <p>%</p>
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
          <ContractsInfoArea>
            <h1>Параметры уступки:</h1>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Минимальная доля уступки
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultMinimalCessionShare || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Максимальная доля уступки
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultMaximalCessionShare || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Минимальная доля финансирования
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultMinimalShareOfFunding || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Максимальная доля финансирования
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                disabled={true}
                value={factoringSettings.defaultMaximalShareOfFunding || ''}
              />
              <p>%</p>
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
        </div>

        {(factoringSettings.factoringRateBase !== '3' ||
          commissions.length) && (
          <SuppliersRate>
            <ContractsInfoItemTitle>
              Какую ставку увидит поставщик?
            </ContractsInfoItemTitle>

            <ContractsInfoItemTitle>Исходные данные:</ContractsInfoItemTitle>

            <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
              <ContractsInfoItemTitle>
                Денежное требование:
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                type="number"
                value={supplierRateFilters.monetaryClaim}
                name="monetaryClaim"
                onChange={onChange}
              />
              <p>руб.</p>
            </ContractsInfoItemContainer>

            <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
              <ContractsInfoItemTitle>
                Оставшаяся отсрочка платежа:
              </ContractsInfoItemTitle>
              <input
                className="short-input"
                type="number"
                value={supplierRateFilters.remainingPayment}
                name="remainingPayment"
                onChange={onChange}
              />
              <p>дней</p>
            </ContractsInfoItemContainer>

            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>Расчет:</ContractsInfoItemTitle>
            </ContractsInfoItemContainer>

            <div>
              <ContractsInfoItemContainer>
                <p>Уступленное требование:</p>
                <p>{formSumStringThousands(concededClaim())} руб.</p>
              </ContractsInfoItemContainer>

              {commissionAtTheTime() > 0 && (
                <ContractsInfoItemContainer>
                  <p>Сумма финансирования:</p>
                  <p>{formSumStringThousands(amountOfFinancing())} руб.</p>
                </ContractsInfoItemContainer>
              )}

              {commissionAtTheTime() > 0 && (
                <ContractsInfoItemContainer>
                  <p>Комиссии в момент выдачи, без НДС:</p>
                  <p>{formSumStringThousands(commissionAtTheTime())} руб.</p>
                </ContractsInfoItemContainer>
              )}

              {VATonCommissionsAtTheTime() > 0 && (
                <ContractsInfoItemContainer>
                  <p>НДС по комиссиям в момент выдачи</p>
                  <p>
                    {formSumStringThousands(VATonCommissionsAtTheTime())} руб.
                  </p>
                </ContractsInfoItemContainer>
              )}

              <ContractsInfoItemContainer>
                <p style={{ color: navyBlue }}>
                  Реальная сумма финансирования:
                </p>
                <p style={{ color: navyBlue }}>
                  {formSumStringThousands(actualAmountOfFinancing())} руб.
                </p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer>
                <p>Процентные платежи, без НДС:</p>
                <p>
                  {formSumStringThousands(interestPaymentsExcludingVAT())} руб.
                </p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer>
                <p>НДС по процентным платежам</p>
                <p>{formSumStringThousands(VATOnInterestPayments())} руб.</p>
              </ContractsInfoItemContainer>

              {commissionsAtTheTimeOfRepayment() > 0 && (
                <ContractsInfoItemContainer>
                  <p>Комиссии в момент погашения, без НДС:</p>
                  <p>
                    {formSumStringThousands(commissionsAtTheTimeOfRepayment())}{' '}
                    руб.
                  </p>
                </ContractsInfoItemContainer>
              )}

              {VATOnCommissionsAtTheTimeOfRepayment() > 0 && (
                <ContractsInfoItemContainer>
                  <p>НДС по комиссиям в момент погашения:</p>
                  <p>
                    {formSumStringThousands(
                      VATOnCommissionsAtTheTimeOfRepayment()
                    )}{' '}
                    руб.
                  </p>
                </ContractsInfoItemContainer>
              )}

              <ContractsInfoItemContainer>
                <p style={{ color: navyBlue }}>
                  Полная сумма платежей и комиссий, без НДС:
                </p>
                <p style={{ color: navyBlue }}>
                  {formSumStringThousands(fullAmountExcludingVAT())} руб.
                </p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer>
                <p style={{ color: navyBlue }}>НДС по платежам и комиссиям</p>
                <p style={{ color: navyBlue }}>
                  {formSumStringThousands(VATOnPaymentsAndCommissions())} руб.
                </p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer style={{ marginTop: '40px' }}>
                <p style={{ color: navyBlue }}>
                  Расчетная полная ставка, без НДС:
                </p>
                <p style={{ color: navyBlue }}>
                  {(
                    ((fullAmountExcludingVAT() * 365) /
                      actualAmountOfFinancing() /
                      supplierRateFilters.remainingPayment) *
                    100
                  ).toFixed(4)}{' '}
                  % годовых
                </p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer>
                <p style={{ color: navyBlue }}>
                  Расчетная полная ставка, с НДС:
                </p>
                <p style={{ color: navyBlue }}>
                  {(
                    (((fullAmountExcludingVAT() +
                      VATOnPaymentsAndCommissions()) *
                      365) /
                      actualAmountOfFinancing() /
                      supplierRateFilters.remainingPayment) *
                    100
                  ).toFixed(4)}{' '}
                  % годовых
                </p>
              </ContractsInfoItemContainer>
            </div>
          </SuppliersRate>
        )}
      </PageBlock>

      <ContractsInfoArea>
        <h1>Дополнительные комиссии:</h1>
        {!!commissions.length ? (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Название комиссии</TableThStyled>
                <TableThStyled width="15%">Момент списания</TableThStyled>
                <TableThStyled width="15%">База расчета</TableThStyled>
                <TableThStyled width="10%">
                  Размер комиссии без НДС
                </TableThStyled>
                <TableThStyled width="10%">НДС</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {commissions.map(commission => (
                <TableRowStyled key={commission.guid}>
                  <td>{commission.name}</td>
                  <td>
                    {commission.momentOfPayment === 1
                      ? 'В начале, при подаче заявки'
                      : commission.momentOfPayment === 2 &&
                        'В конце, при поступлении от дебитора'}
                  </td>
                  <td>
                    {commission.feeBase === 1
                      ? 'Доля от суммы денежного требования, %'
                      : commission.feeBase === 2
                        ? 'Доля от суммы уступленного требования, %'
                        : commission.feeBase === 3
                          ? 'Доля от суммы финансирования, %'
                          : commission.feeBase === 4 &&
                            'Абсолютное значение в рублях'}
                  </td>
                  <td>
                    {commission.feeBase === 4
                      ? `${formSumStringThousands(commission.feeBaseSumm)} руб.`
                      : `${new Intl.NumberFormat('ru-RU', {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4
                        }).format(commission.feeBaseSumm)} %`}
                  </td>
                  <td>{formSumStringThousands(commission.vatPercentage)} %</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        ) : (
          <p>Отсутствуют</p>
        )}
      </ContractsInfoArea>
    </>
  );
};

const mapStateToProps = ({ SCF, SCFFactor }: STORE) => ({
  factoringSettings: SCFFactor.getCurrentFactoringSettingsByDebtorInn.data,
  status: SCFFactor.getCurrentFactoringSettingsByDebtorInn.status,
  error: SCFFactor.getCurrentFactoringSettingsByDebtorInn.error,
  commissions: SCFFactor.getFactoringFeesOfLimits.data,
  statusCommission: SCFFactor.getFactoringFeesOfLimits.status,
  keyRate: SCF.getKeyRates.data.keyRate
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getFactoringSettings, getFactoringFeesOfLimits, getKeyRates },
    dispatch
  );

const LimitSettingsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LimitSettings)
);

export { LimitSettingsConnect as LimitSettings };
