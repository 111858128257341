import {
  GET_APPLICATION_BANK,
  ResponseData
} from 'entities/Cabinet/Bank/model/reducers/getApplicationBank';

export const req = (id: string) => ({
  type: GET_APPLICATION_BANK.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_APPLICATION_BANK.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATION_BANK.ERR,
  error
});
