import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { req as getUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';
import { req as getSupplierCounterSidebar } from 'entities/SCF/Supplier/model/actions/getSupplierCounterSidebar';
import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { SupplierCabinet } from 'pages/SCF/Supplier/components';
import { SCFPageStyled } from 'src/features/SCF/UI/ScfStyles';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getUserData: () => void;
  exit: () => void;
  resetUserData: () => void;
  getSupplierCounterSidebar: () => void;
}

type Props = StateToProps & DispatchToProps;

class SupplierPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getUserData();
    this.props.getSupplierCounterSidebar();
  }

  componentDidUpdate(prevProps: Props, prevState) {
    if (
      this.props.status === REQUEST_STATUSES.ERROR &&
      prevProps.status !== REQUEST_STATUSES.GOT
    ) {
      this.props.exit();
      this.props.resetUserData();
    }
  }

  renderCabinet = () => {
    if (this.props.permissions) {
      if (this.props.permissions.includes(USER_PERMISSIONS.SUPPLIER)) {
        return <SupplierCabinet type={''} />;
      } else if (this.props.permissions.includes(USER_PERMISSIONS.DEBTOR)) {
        return <Redirect to="/debtor" />;
      } else if (this.props.permissions.includes(USER_PERMISSIONS.FACTOR)) {
        return <Redirect to="/factor" />;
      } else {
        return <Redirect to="/cabinet" />;
      }
    }
  };

  render() {
    const { status, error } = this.props;
    return (
      <React.Fragment>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <SCFPageStyled type={''}> {this.renderCabinet()} </SCFPageStyled>
        )}
        {status === REQUEST_STATUSES.ERROR &&
          error.code === 401 && (
            <ActionResponseBlock
              showIn={status === REQUEST_STATUSES.ERROR}
              template={ACTION_RESPONSE_TEMPLATE.SESSION_ERROR}
            />
          )}

        <ActionResponseBlock
          showIn={status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getUserData, exit, resetUserData, getSupplierCounterSidebar },
    dispatch
  );

const SupplierPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(SupplierPage);

export { SupplierPageConnect as SupplierPage };
