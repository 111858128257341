import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';
import { GET_BY_MONETARY_CLAIM_DEBTOR } from 'entities/SCF/Debtor/model/reducers/getByMonetaryClaimDebtor';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getByMonetaryClaimDebtor(action: {
  type: GET_BY_MONETARY_CLAIM_DEBTOR;
  guid: string;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getByMonetaryClaimDebtor,
      action.guid
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getByMonetaryClaimDebtorSaga() {
  yield takeLatest(GET_BY_MONETARY_CLAIM_DEBTOR.REQ, getByMonetaryClaimDebtor);
}
