import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostSendCompanyToScoringStoreState extends Reducer {
  data: any;
}

export enum POST_SEND_COMPANY_TO_SCORING {
  REQ = 'POST_SEND_COMPANY_TO_SCORING_REQ',
  GOT = 'POST_SEND_COMPANY_TO_SCORING_GOT',
  ERR = 'POST_SEND_COMPANY_TO_SCORING_ERR',
  RESET = 'POST_SEND_COMPANY_TO_SCORING_RESET'
}

export const initialState: PostSendCompanyToScoringStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const postSendCompanyToScoring = (
  state: PostSendCompanyToScoringStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): PostSendCompanyToScoringStoreState => {
  switch (action.type) {
    case POST_SEND_COMPANY_TO_SCORING.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_SEND_COMPANY_TO_SCORING.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case POST_SEND_COMPANY_TO_SCORING.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_SEND_COMPANY_TO_SCORING.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: {}
      };
    default:
      return state;
  }
};

export default postSendCompanyToScoring;
