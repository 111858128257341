import styled from 'styled-components';
import { lighterBlue, navyBlue } from 'shared/styled';

export const FinancialDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const TitleTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${lighterBlue};
  padding: 15px 20px;
  border-radius: 5px;

  p {
    margin: 0;
    font-size: 24px;
    color: ${navyBlue};
    cursor: ${({ isDocs }) => (isDocs ? '' : 'pointer')};
  }
`;
