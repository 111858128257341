import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import {
  req as getEarlyPaymentApplications,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getEarlyPaymentApplicationsDebtorRole';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import {
  AbsoluteDiscountRate,
  BaseDiscountRate,
  CancelBtn,
  CancelBtnText,
  CheckContainer,
  CheckedIcon,
  CreateBtn,
  CreateBtnContainer,
  CrossIcon,
  DatePopup,
  DatePopupBtns,
  DatesContainer,
  DeclineContainer,
  DiscountRateSortContainer,
  DiscountSortContainer,
  FirstBasePaymentDate,
  GotBtn,
  GotBtnText,
  MonetaryClaimsTotalFullSum,
  PaidBtnText,
  StatusContainer,
  SupplierInn
} from './styled';
import { format } from 'date-fns';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { gray, navyBlue } from 'shared/styled';
import {
  formatNumberWithComma,
  formSumStringThousands
} from 'shared/utils/Utils';

interface StateToProps {
  earlyPaymentApplications: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getEarlyPaymentApplications: (data: RequestDataType) => void;
}

interface MatchParams {
  guid: string;
  tab?: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationsCompany: React.FC<Props> = ({
  getEarlyPaymentApplications,
  earlyPaymentApplications,
  status,
  history,
  match
}) => {
  const [guid, setGuid] = React.useState(null);
  const [comment, setComment] = React.useState('');
  const [tableSort, setTableSort] = useState({
    discountSort: {
      isShare: null,
      isAbsolute: null
    },
    profitabilitySort: {
      isDiscount: null,
      isEffective: null
    }
  });

  React.useEffect(() => {
    getEarlyPaymentApplications({
      page: 1,
      pageSize: 20,
      auction: match.params.guid
    });
  }, []);

  const handleDiscountSort = (name: string) => {
    if (name === 'isShare') {
      setTableSort(prevState => ({
        ...prevState,
        profitabilitySort: {
          isDiscount: null,
          isEffective: null
        },
        discountSort: {
          isAbsolute: null,
          [name]: !prevState.discountSort.isShare
        }
      }));
    } else if (name === 'isAbsolute') {
      setTableSort(prevState => ({
        ...prevState,
        profitabilitySort: {
          isDiscount: null,
          isEffective: null
        },
        discountSort: {
          isShare: null,
          [name]: !prevState.discountSort.isAbsolute
        }
      }));
    }
  };

  const handleProfitabilitySort = (name: string) => {
    if (name === 'isDiscount') {
      setTableSort(prevState => ({
        ...prevState,
        discountSort: {
          isAbsolute: null,
          isShare: null
        },
        profitabilitySort: {
          isEffective: null,
          [name]: !prevState.profitabilitySort.isDiscount
        }
      }));
    } else if (name === 'isEffective') {
      setTableSort(prevState => ({
        ...prevState,
        discountSort: {
          isAbsolute: null,
          isShare: null
        },
        profitabilitySort: {
          isDiscount: null,
          [name]: !prevState.profitabilitySort.isEffective
        }
      }));
    }
  };

  const handleCommentStateChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setComment(event.target.value);
  };

  const onDeclineBtnClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'none';
    setComment('');
  };

  const onCancelClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'none';
    setComment('');
  };

  const onSignClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
    setGuid(guid);
  };

  const onDeclineClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'flex';
  };

  const onCloseIconClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
  };

  const onNudgeClick = () => {
    history.push({
      pathname: `/debtor/settings/certificates/edit`
    });
  };

  const onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  const onRawClick = (guid: string) => {
    history.push(`/debtor/discount_applications/${guid}`);
  };

  return (
    <>
      {status === REQUEST_STATUSES.GOT &&
        !!earlyPaymentApplications.items.length && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="7%" />
                <TableThStyled width="15%">Поставщик</TableThStyled>
                <TableThStyled width="10%">Дата оплаты</TableThStyled>
                <TableThStyled width="10%">Сумма</TableThStyled>
                <TableThStyled width="15%">
                  <DiscountSortContainer>
                    <div>Дисконт</div>
                    <div>
                      <div>
                        <div>доля</div>
                        <FontAwesomeIcon
                          icon={
                            tableSort.discountSort.isShare
                              ? faSortAmountUpAlt
                              : faSortAmountDownAlt
                          }
                          color={
                            tableSort.discountSort.isShare !== null && navyBlue
                          }
                          onClick={() => handleDiscountSort('isShare')}
                        />
                      </div>
                      <div>
                        <div>абсолютный</div>
                        <FontAwesomeIcon
                          icon={
                            tableSort.discountSort.isAbsolute
                              ? faSortAmountUpAlt
                              : faSortAmountDownAlt
                          }
                          color={
                            tableSort.discountSort.isAbsolute !== null &&
                            navyBlue
                          }
                          onClick={() => handleDiscountSort('isAbsolute')}
                        />
                      </div>
                    </div>
                  </DiscountSortContainer>
                </TableThStyled>
                <TableThStyled width="20%">
                  <DiscountRateSortContainer color={gray}>
                    <div>Доходность</div>
                    <div>
                      <div>
                        <div>дисконт</div>
                        <FontAwesomeIcon
                          icon={
                            tableSort.profitabilitySort.isDiscount
                              ? faSortAmountUpAlt
                              : faSortAmountDownAlt
                          }
                          color={
                            tableSort.profitabilitySort.isDiscount !== null &&
                            navyBlue
                          }
                          onClick={() => handleProfitabilitySort('isDiscount')}
                        />
                      </div>
                      <div>
                        <div>эффективная</div>
                        <FontAwesomeIcon
                          icon={
                            tableSort.profitabilitySort.isEffective
                              ? faSortAmountUpAlt
                              : faSortAmountDownAlt
                          }
                          color={
                            tableSort.profitabilitySort.isEffective !== null &&
                            navyBlue
                          }
                          onClick={() => handleProfitabilitySort('isEffective')}
                        />
                      </div>
                    </div>
                  </DiscountRateSortContainer>
                </TableThStyled>
                <TableThStyled width="20%">Статус</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {earlyPaymentApplications.items.map((item, key) => (
                <TableRowStyled key={key} onClick={() => onRawClick(item.guid)}>
                  <td>{item.guid.substring(item.guid.length - 5)}</td>
                  <td>
                    <SupplierInn>
                      <div>ИНН {item.supplierInn}</div>
                      <div>{item.supplierName}</div>
                    </SupplierInn>
                  </td>
                  <td>
                    <FirstBasePaymentDate>
                      <div>
                        {format(
                          new Date(item.firstBasePaymentDate),
                          'dd.MM.yyyy'
                        )}
                      </div>
                      <div>{format(new Date(item.payDate), 'dd.MM.yyyy')}</div>
                    </FirstBasePaymentDate>
                  </td>
                  <td>
                    <MonetaryClaimsTotalFullSum>
                      <div>
                        {item.monetaryClaimsTotalFullSum.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </div>
                      <div>
                        {item.newTotalSumOnPayday.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        руб.
                      </div>
                    </MonetaryClaimsTotalFullSum>
                  </td>
                  <td>
                    <AbsoluteDiscountRate>
                      <div>
                        <p>
                          {item.absoluteDiscountRate
                            .toFixed(4)
                            .replace('.', ',')}
                        </p>{' '}
                        <span>%</span>
                      </div>
                      <div>
                        <p>{formSumStringThousands(item.absoluteDiscount)}</p>{' '}
                        <span>руб.</span>
                      </div>
                    </AbsoluteDiscountRate>
                  </td>
                  <td>
                    <BaseDiscountRate>
                      <div>
                        <div>
                          <p>{formatNumberWithComma(item.baseDiscountRate)}</p>{' '}
                          <span>%</span>
                        </div>
                        <p>годовых по дисконту</p>
                      </div>
                      <div>
                        <div>
                          <p>
                            {item.depositRateEquivalent
                              .toFixed(4)
                              .replace('.', ',')}
                          </p>{' '}
                          <span>%</span>
                        </div>
                        <p>годовых эффективная</p>
                      </div>
                    </BaseDiscountRate>
                  </td>
                  {item.statusDebtor == 'На рассмотрении' ? (
                    <td>
                      <StatusContainer>
                        <GotBtn style={{ backgroundColor: '#D0E9FF' }}>
                          <GotBtnText>{item.statusDebtor}</GotBtnText>
                        </GotBtn>

                        <div>
                          <CheckContainer
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => onSignClick(e, item.guid)}
                          >
                            <CheckedIcon />
                          </CheckContainer>
                          <DeclineContainer
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => onDeclineClick(e, item.guid)}
                          >
                            <CrossIcon />
                          </DeclineContainer>
                        </div>
                      </StatusContainer>
                    </td>
                  ) : item.statusDebtor == 'Отказана' ? (
                    <td>
                      <GotBtn style={{ backgroundColor: '#FF0000' }}>
                        <PaidBtnText>{item.statusDebtor}</PaidBtnText>
                      </GotBtn>
                    </td>
                  ) : (
                    <td>
                      <GotBtn>
                        <GotBtnText>{item.statusDebtor}</GotBtnText>
                      </GotBtn>
                    </td>
                  )}

                  <DatePopup
                    id={item.guid + 'datePopup'}
                    onClick={e => e.stopPropagation()}
                  >
                    <DatesContainer>
                      <textarea
                        value={comment}
                        onChange={handleCommentStateChange}
                        placeholder="Ваша причина отказа"
                        style={{
                          width: '400px',
                          paddingTop: '10px',
                          paddingLeft: '15px',
                          minHeight: '100px'
                        }}
                      />
                    </DatesContainer>
                    <DatePopupBtns>
                      {comment.length < 10 ? (
                        <CreateBtn
                          onClick={onDeclineBtnClick}
                          style={{
                            backgroundColor: '#9FA6B2',
                            cursor: 'not-allowed'
                          }}
                        >
                          <CancelBtnText>Отправить</CancelBtnText>
                        </CreateBtn>
                      ) : (
                        <CreateBtn
                        // onClick={() =>
                        //   putEarlyPaymentApplicationDecline({
                        //     guid: item.guid,
                        //     body: {
                        //       rejectReason: comment
                        //     }
                        //   })
                        // }
                        >
                          <CreateBtnContainer
                            onClick={(
                              e: React.MouseEvent<
                                HTMLParagraphElement,
                                MouseEvent
                              >
                            ) => onDeclineBtnClick(e, item.guid)}
                          >
                            <CancelBtnText>Отправить</CancelBtnText>
                          </CreateBtnContainer>
                        </CreateBtn>
                      )}
                      <CancelBtn
                        onClick={(
                          e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
                        ) => onCancelClick(e, item.guid)}
                      >
                        <CancelBtnText>Отменить</CancelBtnText>
                      </CancelBtn>
                    </DatePopupBtns>
                  </DatePopup>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}

      <CertificatesPopup id="certificatesPopup">
        <PopupExit>
          <p onClick={onCloseIconClick}>X</p>
        </PopupExit>
        <h1>Список сертификатов в системе</h1>
        <CertificatesArea>
          <CertificatesListPopup guid={guid} pageUrl={guid} />
        </CertificatesArea>
        <NudgeArea>
          <p>Нет нужного сертификата? </p>
          <Nudge onClick={onNudgeClick}>Перейдите в настройки добаления</Nudge>
        </NudgeArea>
      </CertificatesPopup>
      <CertificatesPopupError id="certificatesPopupError">
        <PopupExitError>
          <p onClick={onCloseErrorIconClick}>X</p>
        </PopupExitError>
        <h1>
          Невозможно подписать выбранным сертификатом
          <br />
          (не найден на устройстве)
        </h1>
      </CertificatesPopupError>
    </>
  );
};

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  earlyPaymentApplications:
    SCFDebtor.getEarlyPaymentApplicationsDebtorRole.data,
  status: SCFDebtor.getEarlyPaymentApplicationsDebtorRole.status,
  error: SCFDebtor.getEarlyPaymentApplicationsDebtorRole.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getEarlyPaymentApplications }, dispatch);

const ApplicationsCompanyConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationsCompany)
);

export { ApplicationsCompanyConnect as ApplicationsCompany };
