import { GET_EXTERNAL_CREDENTIAL_PLATFORM } from '../reducers/getExternalCredential';
import { ExternalCredential } from 'src/features/PlatformsForProducts/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ExternalCredential[];
}

export const req = () => ({
  type: GET_EXTERNAL_CREDENTIAL_PLATFORM.REQ
});

export const got = (platforms: ResponseData) => ({
  type: GET_EXTERNAL_CREDENTIAL_PLATFORM.GOT,
  platforms
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_CREDENTIAL_PLATFORM.ERR,
  error
});
