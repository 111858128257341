import { GET_SUPPLY_CONTRACT_INTERNAL } from '../reducers/getSupplyContractInternal';

export interface ResponseData {
  id: string;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  isDeleted: boolean;
  contraceFileIdentifyer: string;
  contraceFileName: string;
  supplierSignatureIdentifier: string;
  supplierSignatureFileName: string;
  debtorSignatureIdentifier: string;
  debtorSignatureFileName: string;
  contractPrice: any;
  completedPrice: any;
  paidPrice: any;
  status: any;
  failedBySupplier: any;
  failedByCustomer: any;
  supplierCertificateSerial: string;
  supplierSigneeInn: string;
  supplierSigneePersonInn: string;
  debtorCertificateSerial: any;
  debtorSigneeInn: string;
  debtorSigneePersonInn: string;
  confirmedByDebtor: boolean;
  paymentDeferment: number;
  paymentDefermentFixed: boolean;
}

export const req = (guid: string) => ({
  type: GET_SUPPLY_CONTRACT_INTERNAL.REQ,
  guid
});

export const got = (data: ResponseData) => ({
  type: GET_SUPPLY_CONTRACT_INTERNAL.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_SUPPLY_CONTRACT_INTERNAL.ERR,
  err
});
