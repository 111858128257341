import getDebtorCounterSidebarSaga from 'entities/SCF/Debtor/model/sagas/getDebtorCounterSidebar';
import SCFRegisterDebtorSaga from 'entities/SCF/Debtor/model/sagas/SCFRegisterDebtor';
import uploadRegistriesSaga from 'entities/SCF/Debtor/model/sagas/uploadRegistries';
import getDebtorRegistryFilesSaga from 'entities/SCF/Debtor/model/sagas/getDebtorRegistryFiles';
import sendSignatureSaga from 'entities/SCF/Debtor/model/sagas/sendSignature';
import getSubRegistriesSaga from 'entities/SCF/Debtor/model/sagas/getSubRegistries';
import sendSubSignatureSaga from 'entities/SCF/Debtor/model/sagas/sendSubSignature';
import getFactoringContractsDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/getFactoringContractsDebtorRole';
import getFactoringContractDebtorRoleByGuidSaga from 'entities/SCF/Debtor/model/sagas/getFactoringContractDebtorRoleByGuid';
import getEarlyPaymentApplicationsDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/getEarlyPaymentApplicationsDebtorRole';
import putEarlyPaymentApplicationDeclineSaga from 'entities/SCF/Debtor/model/sagas/putEarlyPaymentApplicationDecline';
import postAgreementSignatureDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/postAgreementSignatureDebtorRole';
import getOneDebtorRoleByGuidSaga from 'entities/SCF/Debtor/model/sagas/getOneDebtorRoleByGuid';
import getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidSaga from 'entities/SCF/Debtor/model/sagas/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import getFactoringLimitByDebtorInnSaga from 'entities/SCF/Debtor/model/sagas/getFactoringLimitByDebtorInn';
import getDebtorRegistryFileSettingsCurrentSaga from 'entities/SCF/Debtor/model/sagas/getDebtorRegistryFileSettingsCurrent';
import uploadDebtorContractDocumentSaga from 'entities/SCF/Debtor/model/sagas/uploadDebtorContractDocument';
import putPurchaseContractSignatureDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/putPurchaseContractSignatureDebtorRole';
import postDebtorNotificationSettingsSaga from 'entities/SCF/Debtor/model/sagas/postDebtorNotificationSettings';
import approveEarlyPaymentApplicationDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/approveEarlyPaymentApplicationDebtorRole';
import getDocumentFormsSaga from 'entities/SCF/Debtor/model/sagas/getDocumentForms';
import postAddFormToSupplyContractsSaga from 'entities/SCF/Debtor/model/sagas/postAddFormToSupplyContracts';
import patchSetDiscountMarginSaga from 'entities/SCF/Debtor/model/sagas/patchSetDiscountMargin';
import postConfirmContractByDebtorSaga from 'entities/SCF/Debtor/model/sagas/postConfirmContractByDebtor';
import getStartRateDebtorRoleSaga from 'entities/SCF/Debtor/model/sagas/getStartRateDebtorRole';
import getByMonetaryClaimDebtorSaga from 'entities/SCF/Debtor/model/sagas/getByMonetaryClaimDebtor';
import getDebtorNotificationSettingsDataSaga from 'entities/SCF/Debtor/model/sagas/getDebtorNotificationSettings';
import getDocumentFormBySupplyContractSaga from 'entities/SCF/Debtor/model/sagas/getDocumentFormBySupplyContract';
import getMonetaryClaimsSuppliesRoleSaga from 'entities/SCF/Debtor/model/sagas/getMonetaryClaimsSuppliesRole';
import getContractByDebtorSaga from 'entities/SCF/Debtor/model/sagas/getContractByDebtor';
import getDebtorSignatureFileSaga from 'entities/SCF/Debtor/model/sagas/getDebtorSignatureFile';
import putRejectDebtorSaga from 'entities/SCF/Debtor/model/sagas/putRejectDebtor';
import putRejectSupplierSaga from 'entities/SCF/Debtor/model/sagas/putRejectSupplier';
import putAcceptByDebtorSaga from 'entities/SCF/Debtor/model/sagas/putAcceptByDebtor';
import putDeclineByDebtorSaga from 'entities/SCF/Debtor/model/sagas/putDeclineByDebtor';
import getFormFileSaga from 'entities/SCF/Debtor/model/sagas/getFormFile';
import patchPurchaseContractDebtorSaga from 'entities/SCF/Debtor/model/sagas/patchPurchaseContractDebtor';
import postAddToContractByDebtorSaga from 'entities/SCF/Debtor/model/sagas/postAddToContractByDebtor';
import signedEarlyPaymentApplicationSaga from 'entities/SCF/Debtor/model/sagas/signedEarlyPaymentApplication';

export default [
  getDebtorCounterSidebarSaga,
  SCFRegisterDebtorSaga,
  uploadRegistriesSaga,
  getDebtorRegistryFilesSaga,
  sendSignatureSaga,
  getSubRegistriesSaga,
  sendSubSignatureSaga,
  getFactoringContractsDebtorRoleSaga,
  getFactoringContractDebtorRoleByGuidSaga,
  getEarlyPaymentApplicationsDebtorRoleSaga,
  putEarlyPaymentApplicationDeclineSaga,
  postAgreementSignatureDebtorRoleSaga,
  getOneDebtorRoleByGuidSaga,
  getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidSaga,
  getFactoringLimitByDebtorInnSaga,
  getDebtorRegistryFileSettingsCurrentSaga,
  uploadDebtorContractDocumentSaga,
  putPurchaseContractSignatureDebtorRoleSaga,
  postDebtorNotificationSettingsSaga,
  approveEarlyPaymentApplicationDebtorRoleSaga,
  getDocumentFormsSaga,
  postAddFormToSupplyContractsSaga,
  patchSetDiscountMarginSaga,
  postConfirmContractByDebtorSaga,
  getStartRateDebtorRoleSaga,
  getByMonetaryClaimDebtorSaga,
  getDebtorNotificationSettingsDataSaga,
  getDocumentFormBySupplyContractSaga,
  getMonetaryClaimsSuppliesRoleSaga,
  getContractByDebtorSaga,
  getDebtorSignatureFileSaga,
  putRejectDebtorSaga,
  putRejectSupplierSaga,
  putAcceptByDebtorSaga,
  putDeclineByDebtorSaga,
  getFormFileSaga,
  patchPurchaseContractDebtorSaga,
  postAddToContractByDebtorSaga,
  signedEarlyPaymentApplicationSaga
];
