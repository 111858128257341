import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { rr } from 'shared/styled';

export const ContractsPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 80vw;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;
