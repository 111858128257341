import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getAllAgent';

import { GET_ALL_AGENT } from '../reducers/getAllAgent';
import AgentApi from '../api';

function* getAllAgent(action: {
  type: GET_ALL_AGENT;
  filters: any;
}): Generator {
  try {
    const res: any = yield call(AgentApi.getAllAgent, action.filters);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllAgentSaga(): Generator {
  yield takeLatest(GET_ALL_AGENT.REQ, getAllAgent);
}
