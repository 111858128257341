export interface CreditProducts {
  clientInn: string;
  clientName: string;
  bankInn: string;
  bankName: string;
  productTypeID: null;
  productName: string;
  source: null;
  comment: string;
  productID: null;
  productStartDate: null;
  productEndDate: null;
  agentInn: string;
  agentName: string;
  amount: null;
  currencyNumber: null;
  currencyCode: null;
  termInMonthes: null;
  rate: null;
  isActive: boolean;
}

export interface Pledges {
  clientInn: string;
  clientName: string;
  bankInn: string;
  bankName: string;
  firstPledgeContractDate: null;
  lastPledgeContractDate: null;
  isActive: boolean;
  source: null;
}

export enum CLIENTS_PRODUCT_TABS {
  DEFAULT = 'Кредитные продукты',
  PLEDGES = 'Залоги',
  ACCOUNTS = 'Расчетные счета'
}

export enum CLIENTS_TABS {
  PRE_APPROVAL = 'Предодобрения',
  APPLICATIONS = 'Заявки',
  USERS = 'Пользователи',
  FINANCIAL = 'Финансы',
  BENEFICIARIES = 'Бенефициары',
  DOCUMENTS = 'Документы'
}

export interface ClientsListType {
  comment: string;
  companyINN: string;
  companyName: string;
  isActive: boolean;
  longestTerm: number;
  longestTermName: string;
  longestTermRate: number;
  longestTermVolume: number;
  lowestRate: number;
  lowestRateName: string;
  lowestRateTerm: number;
  lowestRateVolume: number;
  maxVolume: number;
  maxVolumeName: string;
  maxVolumeRate: number;
  maxVolumeTerm: number;
}
