import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/createBankAccount';

import { CREATE_BANK_ACCOUNT } from '../reducers/createBankAccount';
import BankAccountApi from '../api';

function* createBankAccount(action: {
  type: CREATE_BANK_ACCOUNT;
  data: any;
}): Generator {
  try {
    yield call(BankAccountApi.createBankAccount, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createBankAccountSaga(): Generator {
  yield takeLatest(CREATE_BANK_ACCOUNT.REQ, createBankAccount);
}
