import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { FinbankRead } from '../../actions/setFinbankFormData';
import { req as getFinbank } from '../../actions/getFinbank';
import { req as getCompany } from 'src/features/Companies/actions/getCompany';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu
} from 'src/features/Layouts/components';
import { history } from 'src/shared/utils/History';
import {
  FinbanksData,
  setFinbankFormData
} from '../../actions/setFinbankFormData';
import { BankMainInfoContainer } from 'src/features/Finbanks/components/FinbankView/styled';
import { FINBANK_TABS } from 'src/features/Finbanks/types';
import {
  ButtonRoleContainer,
  CompanyRoleContainer,
  CompanyRoleName,
  OwnerCompanyRole
} from 'src/features/Companies/components/CompanyView/styled';
import { CompanyRead } from 'src/features/Companies/actions/setCompanyFormData';
import UserAccountsApi from 'src/features/Users/api';
import { Role } from 'src/features/Companies/types';
import CompaniesApi from 'src/features/Companies/api';
import { ContactsList } from 'src/features/Contacts/components/ContactsList/ContactsList';
import { ProductsByBankList } from 'src/features/Products/components/ProductsList/ProductsListForBankPage';
import { UserListForBankPage } from 'src/features/Users/components/UserListForBankPage/UserListForBankPage';
import { ClientsList } from 'src/features/Clients/components/Clients';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  finbank: FinbankRead;
  company: CompanyRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {
  activeTab: string;
  manager: {
    id: number;
    firstName: string;
    lastName: string;
  };
  analyst: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

interface MatchParams {
  inn: string;
  tab: string;
}

interface DispatchToProps {
  getFinbank: (inn: number | string) => void;
  setFinbankFormData: (data: FinbanksData) => void;
  getCompany: (inn: string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class FinbankView extends React.Component<Props, State> {
  state: State = {
    activeTab:
      (this.props.match.params.tab &&
        this.props.match.params.tab.toUpperCase()) ||
      'DEFAULT',
    manager: {
      id: null,
      firstName: null,
      lastName: null
    },
    analyst: {
      id: null,
      firstName: null,
      lastName: null
    }
  };

  componentDidMount() {
    this.props.getFinbank(this.props.match.params.inn);
    this.props.getCompany(this.props.match.params.inn);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.company.managerId !== this.props.company.managerId ||
      prevProps.company.analystId !== this.props.company.analystId
    ) {
      this.getNameByManager();
      this.getNameByAnalyst();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      } else {
        this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
      }
    }
    if (prevState.activeTab !== this.state.activeTab) {
      const { tab } = this.props.match.params;
      const baseUrl = history.location.pathname.replace(
        `/${tab && tab.toLowerCase()}`,
        ''
      );
      if (this.state.activeTab !== 'DEFAULT') {
        const url = baseUrl + `/${this.state.activeTab.toLowerCase()}`;
        history.push(url);
      } else {
        history.push(baseUrl);
      }
    }
  }

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  getNameByManager = async () => {
    if (!!this.props.company.managerId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.managerId.toString()
      );
      this.setState({
        manager: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        }
      });
    }
  };

  getNameByAnalyst = async () => {
    if (!!this.props.company.analystId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.analystId.toString()
      );
      this.setState({
        analyst: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        }
      });
    }
  };

  handleSetRole = async (inn: string, role: Role, locked: boolean) => {
    await CompaniesApi.putLockAndUnlockCompany(inn, role, locked);
    this.props.getCompany(this.props.match.params.inn);
  };

  render() {
    const { company, finbank, status, error } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <BankMainInfoContainer>
              <div>
                <ApplicationId>Банк</ApplicationId>
                <ApplicationId>ИНН {finbank.inn}</ApplicationId>
                <ApplicationTitle>{finbank.name}</ApplicationTitle>
              </div>

              <CompanyRoleContainer>
                {!!this.props.company.managerId && (
                  <div>
                    <CompanyRoleName>Менеджер</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.manager.firstName}{' '}
                      {this.state.manager.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}
                {!!this.props.company.analystId && (
                  <div>
                    <CompanyRoleName>Аналитик</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.analyst.firstName}{' '}
                      {this.state.analyst.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}
                <ButtonRoleContainer>
                  <div>
                    {company.managerId === null && (
                      <Button
                        label="Закрепиться как менеджер"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(company.inn, 'as_manager', true)
                        }
                        h="40px"
                      />
                    )}
                    {company.analystId === null && (
                      <Button
                        label="Закрепиться как аналитик"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(company.inn, 'as_analyst', true)
                        }
                        h="40px"
                      />
                    )}
                  </div>
                  <div>
                    {company.managerId !== null &&
                      company.managerId === this.state.manager.id && (
                        <Button
                          label="Открепиться как менеджер"
                          onClick={() =>
                            this.handleSetRole(company.inn, 'as_manager', false)
                          }
                          h="40px"
                        />
                      )}
                    {company.analystId !== null &&
                      company.analystId === this.state.analyst.id && (
                        <Button
                          label="Открепиться как аналитик"
                          onClick={() =>
                            this.handleSetRole(company.inn, 'as_analyst', false)
                          }
                          h="40px"
                        />
                      )}
                  </div>
                </ButtonRoleContainer>
              </CompanyRoleContainer>
            </BankMainInfoContainer>

            <Menu
              activeTab={this.state.activeTab}
              handleActiveTab={this.handleActiveTab}
              tabs={FINBANK_TABS}
            />

            {this.state.activeTab === 'CONTACTS' && (
              <>
                <ApplicationId>Все контакты</ApplicationId>
                <ContactsList companyInn={finbank.inn} />
              </>
            )}

            {this.state.activeTab === 'PRODUCTS' && (
              <>
                <ApplicationId>Все продукты</ApplicationId>
                <ProductsByBankList bankInn={finbank.inn} />
              </>
            )}

            {this.state.activeTab === 'USERS' && (
              <>
                <ApplicationId>Все пользователи</ApplicationId>
                <UserListForBankPage bankInn={finbank.inn} />
              </>
            )}

            {this.state.activeTab === 'CLIENTS' && (
              <ClientsList bankInn={finbank.inn} />
            )}
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Finbanks, Companies }: CRM) => ({
  company: Companies.getCompany.company,
  finbank: Finbanks.getFinbank.finbank,
  status: Finbanks.getFinbank.status,
  error: Finbanks.getFinbank.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFinbank, setFinbankFormData, getCompany }, dispatch);

const FinbankViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FinbankView)
);

export { FinbankViewConnect as FinbankView };
