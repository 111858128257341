import styled, { css } from 'styled-components';
import { FormSection } from 'shared/ui/FormSection';

export const RadioGroupStyled = styled(FormSection)`
  margin: 0;
`;

export const RadioContainer = styled.div`
  display: flex;

  ${({ template }) =>
    css`
      flex-direction: ${template};
    `};

  & > label {
    margin-right: 20px;
  }
`;
