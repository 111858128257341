import styled from 'styled-components';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';

export const FormSectionStyled = styled.fieldset`
  margin-bottom: 40px;
  padding: 0;

  display: flex;
  flex-direction: column;

  border: none;

  > ${InputContainerStyled} {
    margin-bottom: 25px;

    :last-of-type {
      margin: 0;
    }
  }
`;

export const LegendStyled = styled.legend`
  display: flex;
`;
