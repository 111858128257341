import { FILTER_INTERNAL_AGENT_ACTIONS } from '../reducers/updateInternalAgentsFilter';

export const addInternalAgentsFilter = (internalAgentId: number) => ({
  type: FILTER_INTERNAL_AGENT_ACTIONS.ADD,
  internalAgent: internalAgentId
});

export const removeInternalAgentsFilter = (indexToRemove: number) => ({
  type: FILTER_INTERNAL_AGENT_ACTIONS.REMOVE,
  index: indexToRemove
});
