import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';

export interface GetBuyersToSupplierLinkListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_BUYERS_TO_SUPPLIER_LINK_LIST {
  REQ = 'GET_BUYERS_TO_SUPPLIER_LINK_LIST_REQ',
  GOT = 'GET_BUYERS_TO_SUPPLIER_LINK_LIST_GOT',
  ERR = 'GET_BUYERS_TO_SUPPLIER_LINK_LIST_ERR',
  RESET = 'GET_BUYERS_TO_SUPPLIER_LINK_LIST_RESET'
}

export const initialState: GetBuyersToSupplierLinkListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getBuyersToSupplierLinkList = (
  state: GetBuyersToSupplierLinkListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetBuyersToSupplierLinkListStoreState => {
  switch (action.type) {
    case GET_BUYERS_TO_SUPPLIER_LINK_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BUYERS_TO_SUPPLIER_LINK_LIST.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_BUYERS_TO_SUPPLIER_LINK_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBuyersToSupplierLinkList;
