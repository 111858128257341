import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  req as getActivities,
  ResponseData,
  RequestData
} from 'src/features/Activities/actions/getActivities';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  activities: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getActivities: (data: RequestData) => void;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

const Activities: React.FC<Props> = ({
  getActivities,
  activities,
  status,
  error,
  history
}) => {
  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;

    getActivities({ page, pageSize: 20 });
  };

  const navigateToActivity = (id: number) => {
    window.open(`/crm/activities/${id}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все активности</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">ID</TableThStyled>
                <TableThStyled width="10%">ИНН</TableThStyled>
                <TableThStyled width="10%">Наименование</TableThStyled>
                <TableThStyled width="15%">Тип активности</TableThStyled>
                <TableThStyled width="10%">Дата и время</TableThStyled>
                <TableThStyled width="10%">Инициатор</TableThStyled>
                <TableThStyled width="10%">Результат</TableThStyled>
                <TableThStyled width="10%">Комментарий</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {activities.items.map(activity => (
                <TableRowStyled
                  key={activity.id}
                  onClick={() => navigateToActivity(activity.id)}
                >
                  <td>{activity.id}</td>
                  <td>{activity.companyInn}</td>
                  <td>{activity.companyName}</td>
                  <td>{activity.activityTypeName}</td>
                  <td />
                  <td />
                  <td />
                  <td />
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        </>
      )}
      <Pagination list={activities} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Activities }: CRM) => ({
  activities: Activities.getActivities.data,
  status: Activities.getActivities.status,
  error: Activities.getActivities.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getActivities }, dispatch);

const ActivitiesConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Activities)
);

export { ActivitiesConnect as Activities };
