export enum PRODUCT_COMPLEXITY {
  '4 - Проектное финансирование' = '4 - Проектное финансирование',
  '3 - Корпоративный' = '3 - Корпоративный',
  '2 - Простой' = '2 - Простой',
  '1 - Скоринг' = '1 - Скоринг'
}

export enum RISK_APPETITE {
  '3 - Высокий' = '3 - Высокий',
  '2 - Средний' = '2 - Средний',
  '1 - Низкий' = '1 - Низкий'
}

export enum FORMAT_PRICE {
  rate = 'ставка',
  keyPlusRate = 'ключ + ставка'
}
