import styled, { keyframes } from 'styled-components';
import {
  blue,
  darkBlue,
  gray,
  green,
  lightGray,
  navyBlue,
  red,
  rr
} from 'shared/styled';

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 100px;
  margin: 40px 0 70px 0;

  div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    p {
      margin: 0;
      text-transform: uppercase;

      &:first-of-type {
        color: ${gray};
      }
    }
  }
`;

export const TargetDiscountRate = styled.div`
  margin-bottom: 50px;

  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 300px max-content max-content 320px;
    column-gap: 10px;
    margin-left: 10px;
    height: 45px;

    span {
      font-family: ${rr};
    }

    & > div {
      &:nth-child(4) {
        display: grid;
        grid-template-columns: max-content 100%;
        align-items: center;
        column-gap: 8px;
        margin-left: 10px;

        p {
          margin: 0;
        }
      }

      input {
        width: 120px;
        height: 30px;
        padding: 15px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const DocumentHint = styled.div`
  width: 300px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  top: 0;
  left: 100%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }

    & > div {
      & > p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const MonetaryClaimsContainer = styled.div`
  margin-top: 30px;
`;

export const SummarySection = styled.div`
  margin-top: 30px;
  height: auto;
  display: flex;

  justify-content: flex-end;
`;

export const ConfirmSectionRightPart = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`;

export const DiscountItem = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export const Circle = styled.div`
  position: relative;
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ value, minRate, targetDiscountRate }) => {
    if (value >= targetDiscountRate) return green;
    if (value >= minRate && value < targetDiscountRate) return '#e99f3b';
    return red;
  }};
`;

export const BtnsBlock = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
`;

export const CreateBtn = styled.div`
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${({ disabled }) => (disabled ? lightGray : blue)};
  transition: 0.2s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? lightGray : darkBlue)};
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  margin: 0;
  color: #ffffff;
`;

export const BackBtn = styled.div`
  margin-right: 20px;
  width: 150px;
  height: 40px;
  display: flex;

  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  color: #3f4e65;
  margin: 0;
`;
