import { GET_LIMITS } from 'src/features/PreapprovedLimits/reducers/getLimits';
import { CompanyLimits } from '../types';

export const req = (inn: string) => ({
  type: GET_LIMITS.REQ,
  inn
});

export const got = (data: CompanyLimits[]) => ({
  type: GET_LIMITS.GOT,
  data
});

export const reset = () => ({
  type: GET_LIMITS.RESET
});

export const err = (error: {}) => ({
  type: GET_LIMITS.ERR,
  error
});
