import * as React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';

interface AnimationProps {
  className: string;
  duration: number;
  onEnter: (arg: string) => any;
  onExit: (arg: string) => any;
}

interface Props extends AnimationProps {
  pageKey: string | number;
  children: any;
  component?: any;
}

export enum TRANSITION_DIRECTIONS {
  FORWARD = 'FORWARD',
  BACKWARDS = 'BACKWARDS'
}

const forwardDefault = {
  className: 'forward',
  duration: 200,
  onEnter: keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0%); }
  `,
  onExit: keyframes`
    from { transform: translateX(0%); }
    to { transform: translateX(-100%); }
  `
};

const backwardsDefault = {
  className: 'backwards',
  duration: 200,
  onEnter: keyframes`
    from { transform: translateX(-100%); }
    to { transform: translateX(0%); }
  `,
  onExit: keyframes`
    from { transform: translateX(0%); }
    to { transform: translateX(100%); }
  `
};

export const animation = (
  direction: TRANSITION_DIRECTIONS = TRANSITION_DIRECTIONS.FORWARD,
  props: Partial<AnimationProps> = {}
): AnimationProps => {
  switch (direction) {
    case TRANSITION_DIRECTIONS.FORWARD:
      return {
        ...forwardDefault,
        ...props
      };
    case TRANSITION_DIRECTIONS.BACKWARDS:
      return {
        ...backwardsDefault,
        ...props
      };
    default:
      return forwardDefault;
  }
};

export const TransitionTemplate = createGlobalStyle`
  ${({ className, onEnter, onExit, duration }) => css`
    .${className}-enter, .${className}-exit {
      top: 0;
      left: 0;
      position: absolute;
    }
    .${className}-enter-active {
      animation: ${onEnter} ${duration}ms ease-out both;
    }
    .${className}-exit-active {
      animation: ${onExit} ${duration}ms ease-out both;
    }
  `}
`;

const TransitionGroupBlock = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const childFactoryCreator = props => child => React.cloneElement(child, props);

export const StepsTransitionGroup: React.FC<Props> = ({
  className,
  duration,
  onEnter,
  onExit,
  pageKey,
  children,
  component
}: Props) => {
  return (
    <React.Fragment>
      <TransitionTemplate
        {...{ className, onEnter, onExit, duration }}
        suppressMultiMountWarning
      />

      <TransitionGroup
        component={!!component ? component : TransitionGroupBlock}
        childFactory={childFactoryCreator({
          classNames: className,
          timeout: duration
        })}
      >
        <CSSTransition key={pageKey} timeout={duration}>
          {children}
        </CSSTransition>
      </TransitionGroup>
    </React.Fragment>
  );
};
