import { DELETE_FROM_AUCTION } from './../reducers/deleteFromAuction';

export const req = (guid: string, inn: string) => ({
  type: DELETE_FROM_AUCTION.REQ,
  guid,
  inn
});

export const got = () => ({
  type: DELETE_FROM_AUCTION.GOT
});

export const err = (error: {}) => ({
  type: DELETE_FROM_AUCTION.ERR,
  error
});
