import { GET_FILTERS_FOR_APPLICATION_LIST_TYPE } from '../reducers/getFiltersForApplicationListType';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';

export const req = (listType: APPLICATIONS_LIST_TYPE) => ({
  type: GET_FILTERS_FOR_APPLICATION_LIST_TYPE.REQ,
  listType
});

export const got = (data: string[]) => ({
  type: GET_FILTERS_FOR_APPLICATION_LIST_TYPE.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FILTERS_FOR_APPLICATION_LIST_TYPE.ERR,
  error
});
