import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ContactRead } from '../actions/setContactFormData';

export interface GetContactStoreState extends Reducer {
  contact: ContactRead;
}

export enum GET_CONTACT {
  REQ = 'GET_CONTACT_REQ',
  GOT = 'GET_CONTACT_GOT',
  ERR = 'GET_CONTACT_ERR'
}

export const initialState: GetContactStoreState = {
  status: REQUEST_STATUSES.NONE,
  contact: {
    id: 0
  },
  error: {}
};

const getContact = (
  state: GetContactStoreState = initialState,
  action: {
    type: string;
    contact: ContactRead;
    error: {};
  }
): GetContactStoreState => {
  switch (action.type) {
    case GET_CONTACT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTACT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        contact: action.contact
      };
    case GET_CONTACT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContact;
