import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  req as getAllDocumentForms,
  RequestData as ReqGetDocumentForms,
  ResponseData as DocumentsFormsRes
} from 'entities/Cabinet/InternalAgent/model/actions/getAllDocumentForms';
import { req as getFormFile } from 'entities/Cabinet/InternalAgent/model/actions/getInternalFormFile';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { InnPlaceholders } from 'shared/constants';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import {
  INNField,
  INNContainer,
  TDMain,
  TooltipBoxStyle,
  DownloadBtn
} from './styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';

interface StateToProps {
  documents: DocumentsFormsRes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getAllDocumentForms: (data?: ReqGetDocumentForms) => void;
  getFormFile: (guid: string, name: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FormTypes = {
  1: 'Соглашение о дисконтировании',
  2: 'Уведомление о факторинге по форме дебитора',
  3: 'Уведомление о факторинге по форме фактора',
  4: 'Заявка на факторинг',
  5: 'Анкета банка',
  6: 'Справка аналитика'
};

const DocumentForms: React.FC<Props> = ({
  getAllDocumentForms,
  documents,
  status,
  error,
  getFormFile,
  history
}) => {
  const [ownnerInn, setOwnerInn] = React.useState('');
  const [contractorInn, setContractorInn] = React.useState('');
  const [formType, setFormType] = React.useState(null);

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const ownnerInn = searchParams.get('ownnerInn') || '';
      const contractorInn = searchParams.get('contractorInn') || '';
      const formType = +searchParams.get('formType') || '';

      setOwnerInn(ownnerInn);
      setContractorInn(contractorInn);
      setFormType(formType);

      getAllDocumentForms({
        ...(formType && { type: typeof formType === 'number' ? formType : 1 }),
        ...(contractorInn && { counterparty: contractorInn }),
        ...(ownnerInn && { owner: ownnerInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <ApplicationTitle>Формы документов</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Владельца формы"
            value={ownnerInn}
            name="ownnerInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Контрагента"
            value={contractorInn}
            name="contractorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Select
            options={Object.keys(FormTypes).map(type => ({
              id: type,
              name: FormTypes[type]
            }))}
            value={formType}
            label="Тип формы"
            name="formType"
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">ID</TableThStyled>
                <TableThStyled width="15%">Наименование</TableThStyled>
                <TableThStyled width="15%">Тип формы</TableThStyled>
                <TableThStyled width="5%">Основная</TableThStyled>
                <TableThStyled width="10%" />
                <TableThStyled width="10%">Владелец формы</TableThStyled>
                <TableThStyled width="10%">Форма по контрагенту</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {documents.map(doc => (
                <TableRowStyled key={doc.id}>
                  <td>{doc.guid.slice(-7)}</td>
                  <td>{doc.name}</td>
                  <td>
                    {doc.formType === 1
                      ? 'Соглашение о дисконтировании'
                      : doc.formType === 2
                        ? 'Уведомление о факторинге по форме дебитора'
                        : doc.formType === 3
                          ? 'Уведомление о факторинге по форме фактора'
                          : doc.formType === 4
                            ? 'Заявка на факторинг'
                            : doc.formType === 5
                              ? 'Анкета банка'
                              : doc.formType === 6 && 'Справка аналитика'}
                  </td>
                  <TDMain>
                    {doc.isMain && (
                      <TooltipBoxStyle
                        textVerify={
                          'Используется по умолчанию, если для договора поставки не установлена форма'
                        }
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </TooltipBoxStyle>
                    )}
                  </TDMain>
                  <td>
                    <DownloadBtn
                      onClick={() => getFormFile(doc.guid, doc.exampleFilename)}
                    >
                      Скачать
                    </DownloadBtn>
                  </td>
                  <td>{doc.ownerINN}</td>
                  <td>{doc.counterpartyInn}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={documents} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  documents: InternalAgentRole.getAllDocumentsForms.data,
  status: InternalAgentRole.getAllDocumentsForms.status,
  error: InternalAgentRole.getAllDocumentsForms.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getAllDocumentForms, getFormFile }, dispatch);

const DocumentFormsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentForms)
);

export { DocumentFormsConnect as DocumentForms };
