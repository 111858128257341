import { GET_EARLY_PAYMENT_BY_GUID } from '../reducers/getEarlyPaymentByGuid';

export interface ResponseData {
  id: number;
  guid: string;
  createdDateTime: string;
  modifiedDateTime: string;
  isDeleted: boolean;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  payerInn: string;
  payerName: string;
  payDate: string;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: string;
  lastBasePaymentDate: string;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  status: number;
  agreementFileIdentifier: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  rejectSubject: string;
  rejectComment: string;
  contractId: any;
  supplierCertificateSerial: string;
  supplierSigneeInn: string;
  supplierSigneePersonInn: string;
  investorCertificateSerial: string;
  investorSigneeInn: string;
  investorSigneePersonInn: string;
  keyRate: number;
  keyRateIncome: number;
  discountingFee: number;
  minFee: number;
  debtorInspectorUserId: any;
  supplyContractGuid: string;
  debtorInspectorComment: string;
  agreementFileIdentifyerPdf: any;
  agreementFilePathPdf: any;
  archiveFileIdentifyer: any;
  archiveFilePath: string;
  supplierSigneeGuid: string;
  investorSigneeGuid: string;
  newTotalSumOnPayday: number;
  supplierSigneePoaGuid: string;
  investorSigneePoaGuid: string;
  absoluteDiscountRate: number;
  depositRateEquivalent: number;
  discountPeriod: number;
  absoluteDiscount: number;
  rateType: number;
  auctionGuid: string;
  supplierProtocolFileIdentifyer: string;
  supplierProtocolFilePath: string;
  debtorProtocolFileIdentifyer: string;
  debtorProtocolFilePath: string;
  offerNumber: number;
  platformFee: number;
}

export const req = (guid: string) => ({
  type: GET_EARLY_PAYMENT_BY_GUID.REQ,
  guid
});

export const got = (data: ResponseData) => ({
  type: GET_EARLY_PAYMENT_BY_GUID.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EARLY_PAYMENT_BY_GUID.ERR,
  error
});
