import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';

import { InputDate } from 'shared/ui/InputDate';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getEarlyPaymentApplications,
  reset as resetEarlyPaymentApplications,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getEarlyPaymentApplicationsDebtorRole';
import {
  req as approveEarlyPaymentApplication,
  reset as resetApproveEarlyPaymentApplication
} from 'entities/SCF/Debtor/model/actions/approveEarlyPaymentApplicationDebtorRole';
import { RequestDataType as ApproveEarlyPaymentApplicationRequestDataType } from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';
import {
  CreateBtn,
  CreateBtnContainer,
  DatesContainer,
  DatePopup,
  DatePopupBtns,
  CancelBtn,
  CancelBtnText,
  GotBtn,
  GotBtnText,
  CheckedIcon,
  CrossIcon,
  CheckContainer,
  DeclineContainer,
  StatusContainer,
  PaidBtnText,
  SupplierInn,
  FirstBasePaymentDate,
  MonetaryClaimsTotalFullSum,
  AbsoluteDiscountRate,
  BaseDiscountRate,
  DiscountSortContainer,
  DiscountRateSortContainer,
  ContainerFilter,
  FlexContainer,
  ContainerFilterShort,
  MoreFilters,
  ButtonContainer,
  ButtonSelected,
  ActionListContainer,
  ApprovalApplication,
  ModalButtonSelected,
  Spinner as SpinnerApi,
  ProgressBarContainer,
  ProgressFill,
  ProgressApprovalApplication,
  SuccessField,
  ModalBlock
} from './styles';

import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';

import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import './styles/style.css';
import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';
import { RequestDataType as PutEarlyPaymentApplicationDeclineDataType } from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';
import {
  req as putEarlyPaymentApplicationDecline,
  reset as resetEarlyPaymentApplicationDecline
} from 'entities/SCF/Debtor/model/actions/putEarlyPaymentApplicationDecline';
import {
  req as signedEarlyPaymentApplication,
  reset as resetSignedEarlyPaymentApplication
} from 'entities/SCF/Debtor/model/actions/signedEarlyPaymentApplication';
import { Nudge } from '../../../../../../features/SCF/UI/ScfStyles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  formatNumberWithComma,
  formSumStringThousands
} from 'shared/utils/Utils';
import { blue, CrossBtn, gray, green, navyBlue, red } from 'shared/styled';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Button } from 'src/shared/ui/Button';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Select } from 'shared/ui/Select';
import { Textarea } from 'src/shared/ui/Textarea';
import { ModalStyled } from 'src/features/Common/Modal/components/Modal/styles';
import { SignedCertificatesListPopup } from './CertificatesListPopup/SignedCertificateList';

interface StateToProps {
  earlyPaymentApplications: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  approveData: any[];
  approveStatus: REQUEST_STATUSES;
  signedData: any[];
  signedStatus: REQUEST_STATUSES;
  rejectedData: any[];
  rejectedStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getEarlyPaymentApplications: (data: RequestDataType) => void;
  resetEarlyPaymentApplications: () => void;
  putEarlyPaymentApplicationDecline: (
    data: PutEarlyPaymentApplicationDeclineDataType,
    reqType?: 'array' | null
  ) => void;
  resetEarlyPaymentApplicationDecline: () => void;
  approveEarlyPaymentApplication: (
    data: ApproveEarlyPaymentApplicationRequestDataType,
    reqType?: 'array' | null
  ) => void;
  resetApproveEarlyPaymentApplication: () => void;
  signedEarlyPaymentApplication: (
    signedCertificates: string,
    guid: string[]
  ) => void;
  resetSignedEarlyPaymentApplication: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

export const StatusTypes = {
  ALL: 'Все заявки',
  ACTION_REQUIRED: 'Ожидающие решения',
  UNCONFIRMED: 'Ожидающие согласования',
  UNSIGNED: 'Ожидающий подписи',
  SIGNED: 'Подписанные',
  DECLINED: 'Отклоненные',
  WITHDRAWN: 'Отозваны контрагентом'
} as const;

const ApplicationsList: React.FC<Props> = ({
  getEarlyPaymentApplications,
  resetEarlyPaymentApplications,
  earlyPaymentApplications,
  status,
  putEarlyPaymentApplicationDecline,
  resetEarlyPaymentApplicationDecline,
  rejectedData,
  rejectedStatus,
  approveEarlyPaymentApplication,
  resetApproveEarlyPaymentApplication,
  approveData,
  approveStatus,
  signedEarlyPaymentApplication,
  resetSignedEarlyPaymentApplication,
  signedData,
  signedStatus,
  permissions,
  history
}) => {
  const [isCertificates, setIsCertificates] = React.useState(false);
  const [isSignedCertificates, setIsSignedCertificates] = React.useState(false);
  const [signedCertificates, setSignedCertificates] = React.useState<string>(
    null
  );
  const [isCertificatesError, setIsCertificatesError] = React.useState(false);
  const [isMoreFilters, setIsMoreFilters] = React.useState(false);
  const [supplierInn, setSupplierInn] = React.useState('');
  const [actionType, setActionType] = React.useState<
    keyof typeof StatusTypes | ''
  >('');
  const [minBaseSum, setMinBaseSum] = React.useState('');
  const [maxBaseSum, setMaxBaseSum] = React.useState('');
  const [minDiscountAmount, setMinDiscountAmount] = React.useState('');
  const [maxDiscountAmount, setMaxDiscountAmount] = React.useState('');
  const [minDiscount, setMinDiscount] = React.useState('');
  const [maxDiscount, setMaxDiscount] = React.useState('');
  const [minDiscountRate, setMinDiscountRate] = React.useState('');
  const [maxDiscountRate, setMaxDiscountRate] = React.useState('');
  const [minEquivalentRate, setMinEquivalentRate] = React.useState('');
  const [maxEquivalentRate, setMaxEquivalentRate] = React.useState('');

  const [dateFrom, setDateFrom] = React.useState('');
  const [dateUntil, setDateUntil] = React.useState('');
  const [earlyPaymentDateFrom, setEarlyPaymentDateFrom] = React.useState('');
  const [earlyPaymentDateUntil, setEarlyPaymentDateUntil] = React.useState('');

  const [comment, setComment] = React.useState('');
  const [rejectReason, setRejectReason] = React.useState('');
  const [guid, setGuid] = React.useState(null);
  const [discountSort, setDiscountSort] = React.useState({
    isShare: null,
    isAbsolute: null
  });
  const [profitabilitySort, setProfitabilitySort] = React.useState({
    isDiscount: null,
    isEffective: null
  });
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [selectAllClicked, setSelectAllClicked] = React.useState(false);
  const [debtorInspectorComment, setDebtorInspectorComment] = React.useState(
    ''
  );
  const [isApproveEarlyPayment, setIsApproveEarlyPayment] = React.useState(
    false
  );
  const [isCloseApprovalModal, setIsCloseApprovalModal] = React.useState(false);
  const [
    isApprovalOfApplicationModal,
    setIsApprovalOfApplicationModal
  ] = React.useState(false);
  const [
    isSigningApplicationModal,
    setIsSigningApplicationModal
  ] = React.useState(false);
  const [
    isCloseSigningApplicationModal,
    setIsCloseSigningApplicationModal
  ] = React.useState(false);
  const [isSignedEarlyPayment, setIsSignedEarlyPayment] = React.useState(false);
  const [
    isRejectedApplicationModal,
    setIsRejectedApplicationModal
  ] = React.useState(false);
  const [
    isCloseRejectedApplicationModal,
    setIsCloseRejectedApplicationModal
  ] = React.useState(false);
  const [isRejectedEarlyPayment, setIsRejectedEarlyPayment] = React.useState(
    false
  );
  const [earlyPaymentGuids, setEarlyPaymentGuids] = React.useState<string[]>(
    []
  );

  const allSelected =
    selectedItems.length === earlyPaymentApplications.items.length;
  const isSomeDeselected =
    selectAllClicked && !allSelected && selectedItems.length > 0;
  const isSomeSelected = !selectAllClicked && selectedItems.length > 0;

  React.useEffect(
    () => {
      if (isApprovalOfApplicationModal === false) {
        setIsApproveEarlyPayment(false);
      }

      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const supplierInn = searchParams.get('supplierInn') || '';
      const actionType = (searchParams.get('actionType') ||
        'ACTION_REQUIRED') as keyof typeof StatusTypes;
      const minBaseSum = searchParams.get('minBaseSum') || '';
      const maxBaseSum = searchParams.get('maxBaseSum') || '';
      const minDiscountAmount = searchParams.get('minDiscountAmount') || '';
      const maxDiscountAmount = searchParams.get('maxDiscountAmount') || '';
      const minDiscount = searchParams.get('minDiscount') || '';
      const maxDiscount = searchParams.get('maxDiscount') || '';
      const minDiscountRate = searchParams.get('minDiscountRate') || '';
      const maxDiscountRate = searchParams.get('maxDiscountRate') || '';
      const minEquivalentRate = searchParams.get('minEquivalentRate') || '';
      const maxEquivalentRate = searchParams.get('maxEquivalentRate') || '';

      setSupplierInn(supplierInn);
      setActionType(actionType);
      setMinBaseSum(minBaseSum);
      setMaxBaseSum(maxBaseSum);
      setMinDiscountAmount(minDiscountAmount);
      setMaxDiscountAmount(maxDiscountAmount);
      setMinDiscount(minDiscount);
      setMaxDiscount(maxDiscount);
      setMinDiscountRate(minDiscountRate);
      setMaxDiscountRate(maxDiscountRate);
      setMinEquivalentRate(minEquivalentRate);
      setMaxEquivalentRate(maxEquivalentRate);

      getEarlyPaymentApplications({
        page,
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn }),
        ...(actionType && { ActionType: actionType }),
        ...(dateFrom && { StartDate: dateFrom }),
        ...(dateUntil && { EndDate: dateUntil }),
        ...(minDiscountRate && { minDiscountRate }),
        ...(maxDiscountRate && { maxDiscountRate }),
        ...(minEquivalentRate && { minEquivalentRate }),
        ...(maxEquivalentRate && { maxEquivalentRate }),
        ...(minBaseSum && { minBaseSum }),
        ...(maxBaseSum && { maxBaseSum }),
        ...(minDiscountAmount && { minDiscountAmount }),
        ...(maxDiscountAmount && { maxDiscountAmount }),
        ...(minDiscount && { minDiscount }),
        ...(maxDiscount && { maxDiscount }),
        ...(earlyPaymentDateFrom && { payDateAfter: earlyPaymentDateFrom }),
        ...(earlyPaymentDateUntil && { payDateBefore: earlyPaymentDateUntil }),
        ...((discountSort.isAbsolute !== null ||
          discountSort.isShare !== null ||
          profitabilitySort.isDiscount !== null ||
          profitabilitySort.isEffective !== null) && {
          sortBy:
            discountSort.isAbsolute === true
              ? 'discountAmountDesc'
              : discountSort.isAbsolute === false
                ? 'discountAmountAsc'
                : discountSort.isShare === true
                  ? 'discountDesc'
                  : discountSort.isShare === false
                    ? 'discountAsc'
                    : profitabilitySort.isDiscount === true
                      ? 'discountRateDesc'
                      : profitabilitySort.isDiscount === false
                        ? 'discountRateAsc'
                        : profitabilitySort.isEffective === true
                          ? 'equivalentRateDesc'
                          : profitabilitySort.isEffective === false &&
                            'equivalentRateAsc'
        })
      });
    },
    [
      discountSort,
      profitabilitySort,
      dateFrom,
      dateUntil,
      earlyPaymentDateFrom,
      earlyPaymentDateUntil,
      history.location.search,
      isApprovalOfApplicationModal
    ]
  );

  React.useEffect(
    () => {
      if (isApproveEarlyPayment && status === REQUEST_STATUSES.GOT) {
        const earlyPaymentGuid = earlyPaymentApplications.items
          .filter(item => selectedItems.some(id => item.guid === id))
          .map(item => item.guid);

        if (earlyPaymentGuid.length > 0) {
          setEarlyPaymentGuids(earlyPaymentGuid);
          approveEarlyPaymentApplication(
            { guid: earlyPaymentGuid, body: { debtorInspectorComment } },
            'array'
          );
        }
      }
    },
    [isApproveEarlyPayment, status]
  );

  React.useEffect(
    () => {
      if (
        isSignedEarlyPayment &&
        signedCertificates.length &&
        status === REQUEST_STATUSES.GOT
      ) {
        const earlyPaymentGuid = earlyPaymentApplications.items
          .filter(item => selectedItems.some(id => item.guid === id))
          .map(item => item.guid);

        if (earlyPaymentGuid.length > 0) {
          setEarlyPaymentGuids(earlyPaymentGuid);
          signedEarlyPaymentApplication(signedCertificates, earlyPaymentGuid);
        }
      }
    },
    [isSignedEarlyPayment, signedCertificates, status]
  );

  React.useEffect(
    () => {
      if (isRejectedEarlyPayment && status === REQUEST_STATUSES.GOT) {
        const earlyPaymentGuid = earlyPaymentApplications.items
          .filter(item => selectedItems.some(id => item.guid === id))
          .map(item => item.guid);

        if (earlyPaymentGuid.length > 0) {
          setEarlyPaymentGuids(earlyPaymentGuid);
          putEarlyPaymentApplicationDecline(
            { guid: earlyPaymentGuid, body: { rejectReason } },
            'array'
          );
        }
      }
    },
    [isRejectedEarlyPayment, status]
  );

  React.useEffect(
    () => {
      if (isSigningApplicationModal) {
        handleSignedEarlyPayment();
      }
    },
    [isSigningApplicationModal]
  );

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const onDeclineClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'flex';
  };

  const onCancelClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'none';
    setComment('');
  };

  const onDeclineBtnClick = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    let datePopup = document.getElementById(guid + 'datePopup');
    await delay(700);
    datePopup.style.display = 'none';
    setComment('');
  };

  const onCloseIconClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsCertificates(false);
    setIsSignedCertificates(false);
  };

  const handleCommentStateChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setComment(event.target.value);
  };

  const handleGuidStateChange = (guid: string) => {
    setGuid(guid);
  };

  const handleDateChange = (name: string, date: string) => {
    if (name === 'dateFrom') {
      setDateFrom(date);
    } else if (name === 'dateUntil') {
      setDateUntil(date);
    } else if (name === 'earlyPaymentDateFrom') {
      setEarlyPaymentDateFrom(date);
    } else if (name === 'earlyPaymentDateUntil') {
      setEarlyPaymentDateUntil(date);
    }
  };

  const onSignClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string
  ) => {
    e.stopPropagation();
    handleGuidStateChange(guid);
    setIsCertificates(true);
  };

  const onCloseErrorIconClick = () => {
    setIsCertificatesError(false);
  };

  const onNudgeClick = () => {
    history.push(`/debtor/settings/certificates/edit`);
  };

  const onRawClick = (guid: string) => {
    history.push(`/debtor/discount_applications/${guid}`);
  };

  const handleDiscountSort = (name: string) => {
    if (name === 'isShare') {
      setProfitabilitySort({ isDiscount: null, isEffective: null });
      setDiscountSort(prevState => ({
        isAbsolute: null,
        [name]: !prevState.isShare
      }));
    } else if (name === 'isAbsolute') {
      setProfitabilitySort({ isDiscount: null, isEffective: null });
      setDiscountSort(prevState => ({
        isShare: null,
        [name]: !prevState.isAbsolute
      }));
    }
  };

  const handleProfitabilitySort = (name: string) => {
    if (name === 'isDiscount') {
      setProfitabilitySort(prevState => ({
        isEffective: null,
        [name]: !prevState.isDiscount
      }));
      setDiscountSort({ isAbsolute: null, isShare: null });
    } else if (name === 'isEffective') {
      setProfitabilitySort(prevState => ({
        isDiscount: null,
        [name]: !prevState.isEffective
      }));
      setDiscountSort({ isAbsolute: null, isShare: null });
    }
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    let newValue = e.currentTarget.value;

    const searchParams = new URLSearchParams(history.location.search);

    newValue = newValue.replace(/[^0-9.]/g, '');

    if ((newValue.match(/\./g) || []).length > 1) return;

    const rates = [
      'minDiscountRate',
      'maxDiscountRate',
      'minEquivalentRate',
      'maxEquivalentRate',
      'minDiscount',
      'maxDiscount'
    ];

    const summ = [
      'minBaseSum',
      'maxBaseSum',
      'minDiscountAmount',
      'maxDiscountAmount'
    ];

    if (rates.includes(name)) {
      newValue = newValue.replace(/^(\d+\.?\d{0,4}).*$/, '$1');
    } else if (summ.includes(name)) {
      newValue = newValue.replace(/^(\d+\.?\d{0,2}).*$/, '$1');
    }

    if (newValue) {
      searchParams.set(name, newValue);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleSetActionType = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: type } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    setSelectedItems([]);

    if (type) {
      searchParams.set('actionType', type);
    } else {
      searchParams.delete('actionType');
    }

    history.push({ search: searchParams.toString() });
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectAllClicked(false);
      setSelectedItems([]);
    } else {
      setSelectAllClicked(true);
      setSelectedItems(earlyPaymentApplications.items.map(item => item.guid));
    }
  };

  const calculateStyle = () => {
    if (allSelected) return 'allActive';
    if (isSomeDeselected) return 'almostAllActive';
    if (isSomeSelected) return 'onlyOneActive';
    return 'allInActive';
  };

  const handleCheckboxChange = (
    id: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();

    setSelectedItems(
      prev =>
        prev.includes(id) ? prev.filter(itemId => itemId !== id) : [...prev, id]
    );
  };

  const handleApproveEarlyPayment = () => {
    if (calculateStyle() === 'onlyOneActive') {
      approveEarlyPaymentApplication(
        { guid: selectedItems, body: { debtorInspectorComment } },
        'array'
      );
    } else if (
      calculateStyle() === 'allActive' ||
      calculateStyle() === 'almostAllActive'
    ) {
      setIsApproveEarlyPayment(true);
      baseReqEarlyPaymentApplications(true, 'UNCONFIRMED');
    }
  };

  const handleSignedEarlyPayment = () => {
    if (calculateStyle() === 'onlyOneActive') {
      signedEarlyPaymentApplication(signedCertificates, selectedItems);
    } else if (
      calculateStyle() === 'allActive' ||
      calculateStyle() === 'almostAllActive'
    ) {
      setIsSignedEarlyPayment(true);
      baseReqEarlyPaymentApplications(true);
    }
  };

  const handleRejectedEarlyPayment = () => {
    if (calculateStyle() === 'onlyOneActive') {
      putEarlyPaymentApplicationDecline(
        {
          guid: selectedItems,
          body: { rejectReason }
        },
        'array'
      );
    } else if (
      calculateStyle() === 'allActive' ||
      calculateStyle() === 'almostAllActive'
    ) {
      const searchParams = new URLSearchParams(history.location.search);
      const actionType = searchParams.get(
        'actionType'
      ) as keyof typeof StatusTypes;

      setIsRejectedEarlyPayment(true);
      baseReqEarlyPaymentApplications(true, actionType);
    }
  };

  const baseReqEarlyPaymentApplications = (
    allGuids?: boolean,
    actionType?: keyof typeof StatusTypes
  ) => {
    getEarlyPaymentApplications({
      page: 1,
      ActionType: actionType || 'ACTION_REQUIRED',
      ...(allGuids && { allGuids }),
      ...(supplierInn && { SupplierInnToFilterBy: supplierInn }),
      ...(dateFrom && { StartDate: dateFrom }),
      ...(dateUntil && { EndDate: dateUntil }),
      ...(minDiscountRate && { minDiscountRate }),
      ...(maxDiscountRate && { maxDiscountRate }),
      ...(minEquivalentRate && { minEquivalentRate }),
      ...(maxEquivalentRate && { maxEquivalentRate }),
      ...(minBaseSum && { minBaseSum }),
      ...(maxBaseSum && { maxBaseSum }),
      ...(minDiscountAmount && { minDiscountAmount }),
      ...(maxDiscountAmount && { maxDiscountAmount }),
      ...(minDiscount && { minDiscount }),
      ...(maxDiscount && { maxDiscount }),
      ...(earlyPaymentDateFrom && { payDateAfter: earlyPaymentDateFrom }),
      ...(earlyPaymentDateUntil && { payDateBefore: earlyPaymentDateUntil })
    });
  };

  const handleCloseSigningApplicationModal = () => {
    if (signedData.length === selectedItems.length) {
      resetSignedEarlyPaymentApplication();
      setIsSigningApplicationModal(false);
      baseReqEarlyPaymentApplications();
    } else {
      setIsCloseSigningApplicationModal(true);
    }
  };

  const handleCloseApprovalModal = () => {
    baseReqEarlyPaymentApplications();
    setComment('');
    setSelectedItems([]);
    setIsApprovalOfApplicationModal(false);
    setIsCloseApprovalModal(false);
    resetApproveEarlyPaymentApplication();
  };

  const handleCloseSigningModal = () => {
    baseReqEarlyPaymentApplications();
    setComment('');
    setSelectedItems([]);
    setIsSigningApplicationModal(false);
    setIsCloseSigningApplicationModal(false);
    resetSignedEarlyPaymentApplication();
  };

  const handleCloseRejectModal = () => {
    baseReqEarlyPaymentApplications();
    setRejectReason('');
    setSelectedItems([]);
    setIsRejectedApplicationModal(false);
    setIsCloseRejectedApplicationModal(false);
    resetEarlyPaymentApplicationDecline();
  };

  const successApproveCount =
    approveData.filter(item => item.success).length || 0;
  const failureApproveCount =
    approveData.filter(item => !item.success).length || 0;

  const successSignedCount =
    signedData.filter(item => item.success).length || 0;
  const failureSignedCount =
    signedData.filter(item => !item.success).length || 0;

  const successRejectedCount =
    rejectedData.filter(item => item.success).length || 0;
  const failureRejectedCount =
    rejectedData.filter(item => !item.success).length || 0;

  return (
    <PageNoMarginStyled>
      <ApplicationTitle>Заявки на финансирование</ApplicationTitle>

      <FlexContainer>
        <div>
          <Select
            options={Object.keys(StatusTypes).map(type => ({
              id: type,
              name: StatusTypes[type]
            }))}
            value={actionType}
            label="Статус"
            name="actionType"
            onChange={handleSetActionType}
          />

          <Input
            value={supplierInn}
            label="ИНН Поставщика"
            name="supplierInn"
            onChange={onFilterChange}
            placeholder={InnPlaceholders.entity}
          />
        </div>

        <Button
          label={isMoreFilters ? 'Скрыть фильтры' : 'Показать все фильтры'}
          w="fit-content"
          onClick={() => setIsMoreFilters(prevState => !prevState)}
        />
      </FlexContainer>

      {isMoreFilters && (
        <MoreFilters>
          <div>
            <p>Ставка</p>
            <ContainerFilterShort>
              <Input
                value={minDiscountRate}
                label="Ставка дисконта, % годовых"
                name="minDiscountRate"
                onChange={onFilterChange}
              />
              <span>-</span>
              <Input
                value={maxDiscountRate}
                name="maxDiscountRate"
                onChange={onFilterChange}
              />
              <Input
                value={minEquivalentRate}
                label="Эквивалентная ставка депозита, % годовых"
                name="minEquivalentRate"
                onChange={onFilterChange}
              />
              <span>-</span>
              <Input
                value={maxEquivalentRate}
                name="maxEquivalentRate"
                onChange={onFilterChange}
              />
              <Input
                value={minDiscount}
                label="Абсолютный размер дисконта, % от суммы"
                name="minDiscount"
                onChange={onFilterChange}
              />
              <span>-</span>
              <Input
                value={maxDiscount}
                name="maxDiscount"
                onChange={onFilterChange}
              />
            </ContainerFilterShort>
          </div>

          <div>
            <p>Сумма</p>
            <ContainerFilter>
              <Input
                value={minBaseSum}
                label="Базовая сумма заявки, руб."
                name="minBaseSum"
                onChange={onFilterChange}
              />
              <span>-</span>
              <Input
                value={maxBaseSum}
                name="maxBaseSum"
                onChange={onFilterChange}
              />
              <Input
                value={minDiscountAmount}
                label="Абсолютный размер дисконта, руб."
                name="minDiscountAmount"
                onChange={onFilterChange}
              />
              <span>-</span>
              <Input
                value={maxDiscountAmount}
                name="maxDiscountAmount"
                onChange={onFilterChange}
              />
            </ContainerFilter>
          </div>

          <div>
            <p>Дата</p>
            <ContainerFilter>
              <InputDate
                dateValue={dateFrom}
                label="Дата заявки"
                name="dateFrom"
                onDateChange={date => handleDateChange('dateFrom', date)}
              />
              <span>-</span>
              <InputDate
                dateValue={dateUntil}
                name="dateUntil"
                onDateChange={date => handleDateChange('dateUntil', date)}
              />
              <InputDate
                dateValue={earlyPaymentDateFrom}
                label="Дата ранней оплаты"
                name="earlyPaymentDateFrom"
                onDateChange={date =>
                  handleDateChange('earlyPaymentDateFrom', date)
                }
              />
              <span>-</span>
              <InputDate
                dateValue={earlyPaymentDateUntil}
                name="earlyPaymentDateUntil"
                onDateChange={date =>
                  handleDateChange('earlyPaymentDateUntil', date)
                }
              />
            </ContainerFilter>
          </div>
        </MoreFilters>
      )}

      {(actionType === 'ACTION_REQUIRED' ||
        actionType === 'UNCONFIRMED' ||
        actionType === 'UNSIGNED') && (
        <ActionListContainer>
          <ButtonContainer onClick={handleSelectAll}>
            <ButtonSelected active={calculateStyle()} />
            <p>Выбрать всё</p>
          </ButtonContainer>

          {actionType !== 'UNSIGNED' && (
            <Button
              label="Согласовать"
              onClick={() => {
                resetEarlyPaymentApplications();
                setIsApprovalOfApplicationModal(true);
              }}
              backgroundColor={blue}
              w="fit-content"
              h="35px"
              disabled={selectedItems.length === 0}
            />
          )}

          {permissions.includes(USER_PERMISSIONS.DEBTOR_SIGN) && (
            <Button
              label="Подписать"
              backgroundColor={green}
              onClick={() => setIsSignedCertificates(true)}
              w="fit-content"
              h="40px"
              disabled={selectedItems.length === 0}
            />
          )}

          {!!earlyPaymentApplications.items.length && (
            <Button
              label="Отклонить"
              backgroundColor={red}
              onClick={() => setIsRejectedApplicationModal(true)}
              w="fit-content"
              h="40px"
              disabled={selectedItems.length === 0}
            />
          )}
        </ActionListContainer>
      )}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              {(actionType === 'ACTION_REQUIRED' ||
                actionType === 'UNCONFIRMED' ||
                actionType === 'UNSIGNED') && <TableThStyled width="3%" />}
              <TableThStyled width="7%" />
              <TableThStyled width="15%">Поставщик</TableThStyled>
              <TableThStyled width="10%">Дата оплаты</TableThStyled>
              <TableThStyled width="10%">Сумма</TableThStyled>
              <TableThStyled width="15%">
                <DiscountSortContainer>
                  <div>Дисконт</div>
                  <div>
                    <div>
                      <div>доля</div>
                      <FontAwesomeIcon
                        icon={
                          discountSort.isShare
                            ? faSortAmountUpAlt
                            : faSortAmountDownAlt
                        }
                        color={discountSort.isShare !== null && navyBlue}
                        onClick={() => handleDiscountSort('isShare')}
                      />
                    </div>
                    <div>
                      <div>абсолютный</div>
                      <FontAwesomeIcon
                        icon={
                          discountSort.isAbsolute
                            ? faSortAmountUpAlt
                            : faSortAmountDownAlt
                        }
                        color={discountSort.isAbsolute !== null && navyBlue}
                        onClick={() => handleDiscountSort('isAbsolute')}
                      />
                    </div>
                  </div>
                </DiscountSortContainer>
              </TableThStyled>
              <TableThStyled width="20%">
                <DiscountRateSortContainer color={gray}>
                  <div>Доходность</div>
                  <div>
                    <div>
                      <div>дисконт</div>
                      <FontAwesomeIcon
                        icon={
                          profitabilitySort.isDiscount
                            ? faSortAmountUpAlt
                            : faSortAmountDownAlt
                        }
                        color={
                          profitabilitySort.isDiscount !== null && navyBlue
                        }
                        onClick={() => handleProfitabilitySort('isDiscount')}
                      />
                    </div>
                    <div>
                      <div>эффективная</div>
                      <FontAwesomeIcon
                        icon={
                          profitabilitySort.isEffective
                            ? faSortAmountUpAlt
                            : faSortAmountDownAlt
                        }
                        color={
                          profitabilitySort.isEffective !== null && navyBlue
                        }
                        onClick={() => handleProfitabilitySort('isEffective')}
                      />
                    </div>
                  </div>
                </DiscountRateSortContainer>
              </TableThStyled>
              <TableThStyled width="20%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {!isApprovalOfApplicationModal &&
              !isSigningApplicationModal &&
              !isRejectedApplicationModal &&
              earlyPaymentApplications.items.map((item, key) => (
                <TableRowStyled key={key} onClick={() => onRawClick(item.guid)}>
                  {(actionType === 'ACTION_REQUIRED' ||
                    actionType === 'UNCONFIRMED' ||
                    actionType === 'UNSIGNED') && (
                    <td>
                      <ModalButtonSelected
                        isActive={selectedItems.includes(item.guid)}
                        onClick={event =>
                          handleCheckboxChange(item.guid, event)
                        }
                      />
                    </td>
                  )}
                  <td>{item.guid.substring(item.guid.length - 5)}</td>
                  <td>
                    <SupplierInn>
                      <div>ИНН {item.supplierInn}</div>
                      <div>{item.supplierName}</div>
                    </SupplierInn>
                  </td>
                  <td>
                    <FirstBasePaymentDate>
                      <div>
                        {format(
                          new Date(item.firstBasePaymentDate),
                          'dd.MM.yyyy'
                        )}
                      </div>
                      <div>{format(new Date(item.payDate), 'dd.MM.yyyy')}</div>
                    </FirstBasePaymentDate>
                  </td>
                  <td>
                    <MonetaryClaimsTotalFullSum>
                      <div>
                        {item.monetaryClaimsTotalFullSum.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </div>
                      <div>
                        {item.newTotalSumOnPayday.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        руб.
                      </div>
                    </MonetaryClaimsTotalFullSum>
                  </td>
                  <td>
                    <AbsoluteDiscountRate>
                      <div>
                        <p>
                          {item.absoluteDiscountRate
                            .toFixed(4)
                            .replace('.', ',')}
                        </p>{' '}
                        <span>%</span>
                      </div>
                      <div>
                        <p>{formSumStringThousands(item.absoluteDiscount)}</p>{' '}
                        <span>руб.</span>
                      </div>
                    </AbsoluteDiscountRate>
                  </td>
                  <td>
                    <BaseDiscountRate>
                      <div>
                        <div>
                          <p>{formatNumberWithComma(item.baseDiscountRate)}</p>{' '}
                          <span>%</span>
                        </div>
                        <p>годовых по дисконту</p>
                      </div>
                      <div>
                        <div>
                          <p>
                            {item.depositRateEquivalent
                              .toFixed(4)
                              .replace('.', ',')}
                          </p>{' '}
                          <span>%</span>
                        </div>
                        <p>годовых эффективная</p>
                      </div>
                    </BaseDiscountRate>
                  </td>
                  {item.statusDebtor == 'На рассмотрении' ? (
                    <td>
                      <StatusContainer>
                        <GotBtn style={{ backgroundColor: '#D0E9FF' }}>
                          <GotBtnText>{item.statusDebtor}</GotBtnText>
                        </GotBtn>

                        <div>
                          <CheckContainer
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => onSignClick(e, item.guid)}
                          >
                            <CheckedIcon />
                          </CheckContainer>
                          <DeclineContainer
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => onDeclineClick(e, item.guid)}
                          >
                            <CrossIcon />
                          </DeclineContainer>
                        </div>
                      </StatusContainer>
                    </td>
                  ) : item.statusDebtor == 'Отказана' ? (
                    <td>
                      <GotBtn style={{ backgroundColor: '#FF0000' }}>
                        <PaidBtnText>{item.statusDebtor}</PaidBtnText>
                      </GotBtn>
                    </td>
                  ) : (
                    <td>
                      <GotBtn>
                        <GotBtnText>{item.statusDebtor}</GotBtnText>
                      </GotBtn>
                    </td>
                  )}

                  <DatePopup
                    id={item.guid + 'datePopup'}
                    onClick={e => e.stopPropagation()}
                  >
                    <DatesContainer>
                      <textarea
                        value={comment}
                        onChange={handleCommentStateChange}
                        placeholder="Ваша причина отказа"
                        style={{
                          width: '400px',
                          paddingTop: '10px',
                          paddingLeft: '15px',
                          minHeight: '100px'
                        }}
                      />
                    </DatesContainer>
                    <DatePopupBtns>
                      {comment.length < 10 ? (
                        <CreateBtn
                          onClick={onDeclineBtnClick}
                          style={{
                            backgroundColor: '#9FA6B2',
                            cursor: 'not-allowed'
                          }}
                        >
                          <CancelBtnText>Отправить</CancelBtnText>
                        </CreateBtn>
                      ) : (
                        <CreateBtn
                          onClick={() =>
                            putEarlyPaymentApplicationDecline({
                              guid: item.guid,
                              body: { rejectReason: comment }
                            })
                          }
                        >
                          <CreateBtnContainer
                            onClick={(
                              e: React.MouseEvent<
                                HTMLParagraphElement,
                                MouseEvent
                              >
                            ) => onDeclineBtnClick(e, item.guid)}
                          >
                            <CancelBtnText>Отправить</CancelBtnText>
                          </CreateBtnContainer>
                        </CreateBtn>
                      )}
                      <CancelBtn
                        onClick={(
                          e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
                        ) => onCancelClick(e, item.guid)}
                      >
                        <CancelBtnText>Отменить</CancelBtnText>
                      </CancelBtn>
                    </DatePopupBtns>
                  </DatePopup>
                </TableRowStyled>
              ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={earlyPaymentApplications} />

      {isApprovalOfApplicationModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn onClick={() => setIsCloseApprovalModal(true)} />
            <ApprovalApplication>
              <div>
                <h2>Согласование заявок</h2>
                {status === REQUEST_STATUSES.REQUEST && (
                  <SpinnerApi icon={faSpinner} />
                )}
              </div>

              {!!approveData.length ? (
                <ProgressApprovalApplication>
                  <div>
                    <p>Заявок обработано:</p>
                    <div>
                      <p>
                        {successApproveCount + failureApproveCount} /{' '}
                        {calculateStyle() === 'onlyOneActive'
                          ? selectedItems.length
                          : earlyPaymentGuids.length}
                      </p>
                      <ProgressBarContainer>
                        <ProgressFill
                          max={
                            calculateStyle() === 'onlyOneActive'
                              ? selectedItems.length
                              : earlyPaymentGuids.length
                          }
                          value={successApproveCount + failureApproveCount}
                        />
                      </ProgressBarContainer>
                    </div>
                  </div>
                  <div>
                    <p>Ошибок:</p>
                    <p>{failureApproveCount}</p>
                  </div>
                </ProgressApprovalApplication>
              ) : (
                <Textarea
                  placeholder="Комментарий"
                  name="debtorInspectorComment"
                  value={debtorInspectorComment}
                  onChange={e =>
                    setDebtorInspectorComment(e.currentTarget.value)
                  }
                />
              )}

              {successApproveCount + failureApproveCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) && (
                <SuccessField>Обработка завершена!</SuccessField>
              )}

              <div>
                {successApproveCount + failureApproveCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) ? (
                  <Button
                    label="Закрыть окно"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      resetApproveEarlyPaymentApplication();
                      setIsApprovalOfApplicationModal(false);
                      baseReqEarlyPaymentApplications();
                    }}
                  />
                ) : (
                  <>
                    <Button
                      label="Согласовать"
                      onClick={handleApproveEarlyPayment}
                      disabled={
                        debtorInspectorComment.length === 0 ||
                        approveStatus === REQUEST_STATUSES.REQUEST
                      }
                      w="fit-content"
                      h="30px"
                    />
                    <Button
                      label="Отмена"
                      w="fit-content"
                      h="30px"
                      onClick={() => setIsCloseApprovalModal(true)}
                    />
                  </>
                )}
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      {isCloseApprovalModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn onClick={() => setIsCloseApprovalModal(false)} />
            <ApprovalApplication>
              <h2>Остановить согласование заявок?</h2>

              <div style={{ marginTop: '30px' }}>
                <Button
                  label="Да"
                  w="fit-content"
                  h="30px"
                  onClick={handleCloseApprovalModal}
                />
                <Button
                  label="Нет"
                  w="fit-content"
                  h="30px"
                  onClick={() => setIsCloseApprovalModal(false)}
                />
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      {isSigningApplicationModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn onClick={handleCloseSigningApplicationModal} />
            <ApprovalApplication>
              <div>
                <h2>Подписание заявок</h2>
                {signedData.length <
                  (calculateStyle() === 'onlyOneActive'
                    ? selectedItems.length
                    : earlyPaymentGuids.length) && (
                  <SpinnerApi icon={faSpinner} />
                )}
              </div>

              <ProgressApprovalApplication>
                <div>
                  <p>Заявок обработано:</p>
                  <div>
                    <p>
                      {successSignedCount + failureSignedCount} /{' '}
                      {calculateStyle() === 'onlyOneActive'
                        ? selectedItems.length
                        : earlyPaymentGuids.length}
                    </p>
                    <ProgressBarContainer>
                      <ProgressFill
                        max={
                          calculateStyle() === 'onlyOneActive'
                            ? selectedItems.length
                            : earlyPaymentGuids.length
                        }
                        value={successSignedCount + failureSignedCount}
                      />
                    </ProgressBarContainer>
                  </div>
                </div>
                <div>
                  <p>Ошибок:</p>
                  <p>{failureSignedCount}</p>
                </div>
              </ProgressApprovalApplication>

              {successSignedCount + failureSignedCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) && (
                <SuccessField>Обработка завершена!</SuccessField>
              )}

              <div>
                {successSignedCount + failureSignedCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) ? (
                  <Button
                    label="Закрыть окно"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      resetSignedEarlyPaymentApplication();
                      setIsSigningApplicationModal(false);
                      baseReqEarlyPaymentApplications();
                    }}
                  />
                ) : (
                  <>
                    <Button
                      label="Подписать"
                      disabled={true}
                      w="fit-content"
                      h="30px"
                    />
                    <Button
                      label="Отмена"
                      w="fit-content"
                      h="30px"
                      onClick={() => setIsCloseSigningApplicationModal(true)}
                    />
                  </>
                )}
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      {isCloseSigningApplicationModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn
              onClick={() => setIsCloseSigningApplicationModal(false)}
            />
            <ApprovalApplication>
              <h2>Остановить согласование заявок?</h2>

              <div style={{ marginTop: '30px' }}>
                <Button
                  label="Да"
                  w="fit-content"
                  h="30px"
                  onClick={handleCloseSigningModal}
                />
                <Button
                  label="Нет"
                  w="fit-content"
                  h="30px"
                  onClick={() => setIsCloseSigningApplicationModal(false)}
                />
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      {isRejectedApplicationModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn
              onClick={() => setIsCloseRejectedApplicationModal(true)}
            />
            <ApprovalApplication>
              <div>
                <h2>Отклонить заявки</h2>
                {rejectedStatus === REQUEST_STATUSES.REQUEST && (
                  <SpinnerApi icon={faSpinner} />
                )}
              </div>

              {!!rejectedData.length ? (
                <ProgressApprovalApplication>
                  <div>
                    <p>Заявок обработано:</p>
                    <div>
                      <p>
                        {successRejectedCount + failureRejectedCount} /{' '}
                        {calculateStyle() === 'onlyOneActive'
                          ? selectedItems.length
                          : earlyPaymentGuids.length}
                      </p>
                      <ProgressBarContainer>
                        <ProgressFill
                          max={
                            calculateStyle() === 'onlyOneActive'
                              ? selectedItems.length
                              : earlyPaymentGuids.length
                          }
                          value={successRejectedCount + failureRejectedCount}
                        />
                      </ProgressBarContainer>
                    </div>
                  </div>
                  <div>
                    <p>Ошибок:</p>
                    <p>{failureRejectedCount}</p>
                  </div>
                </ProgressApprovalApplication>
              ) : (
                <Textarea
                  placeholder="Причина отказа"
                  name="rejectReason"
                  value={rejectReason}
                  onChange={e => setRejectReason(e.currentTarget.value)}
                />
              )}

              {successRejectedCount + failureRejectedCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) && (
                <SuccessField>Обработка завершена!</SuccessField>
              )}

              <div>
                {successRejectedCount + failureRejectedCount ===
                (calculateStyle() === 'onlyOneActive'
                  ? selectedItems.length
                  : earlyPaymentGuids.length) ? (
                  <Button
                    label="Закрыть окно"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      resetEarlyPaymentApplicationDecline();
                      setIsRejectedApplicationModal(false);
                      baseReqEarlyPaymentApplications();
                    }}
                  />
                ) : (
                  <>
                    <Button
                      label="Отклонить"
                      onClick={handleRejectedEarlyPayment}
                      disabled={
                        rejectReason.length === 0 ||
                        rejectedStatus === REQUEST_STATUSES.REQUEST
                      }
                      backgroundColor={red}
                      w="fit-content"
                      h="30px"
                    />
                    <Button
                      label="Отмена"
                      w="fit-content"
                      h="30px"
                      onClick={() => setIsCloseApprovalModal(true)}
                    />
                  </>
                )}
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      {isCloseRejectedApplicationModal && (
        <ModalStyled>
          <ModalBlock>
            <CrossBtn
              onClick={() => setIsCloseRejectedApplicationModal(false)}
            />
            <ApprovalApplication>
              <h2>Остановить согласование заявок?</h2>

              <div style={{ marginTop: '30px' }}>
                <Button
                  label="Да"
                  w="fit-content"
                  h="30px"
                  onClick={handleCloseRejectModal}
                />
                <Button
                  label="Нет"
                  w="fit-content"
                  h="30px"
                  onClick={() => setIsCloseRejectedApplicationModal(false)}
                />
              </div>
            </ApprovalApplication>
          </ModalBlock>
        </ModalStyled>
      )}

      <CertificatesPopup isActive={isCertificates}>
        <PopupExit>
          <p onClick={onCloseIconClick}>X</p>
        </PopupExit>
        <h1>Список сертификатов в системе</h1>
        <CertificatesArea>
          <CertificatesListPopup
            guid={guid}
            setIsCertificates={setIsCertificates}
            setIsCertificatesError={setIsCertificatesError}
            setIsSigningApplicationModal={setIsSigningApplicationModal}
          />
        </CertificatesArea>
        <NudgeArea>
          <p>Нет нужного сертификата? </p>
          <Nudge onClick={onNudgeClick}>Перейдите в настройки добаления</Nudge>
        </NudgeArea>
      </CertificatesPopup>

      <CertificatesPopup isActive={isSignedCertificates}>
        <PopupExit>
          <p onClick={onCloseIconClick}>X</p>
        </PopupExit>
        <h1>Список сертификатов в системе</h1>
        <CertificatesArea>
          <SignedCertificatesListPopup
            setSignedCertificates={setSignedCertificates}
            setIsSignedCertificates={setIsSignedCertificates}
            setIsCertificatesError={setIsCertificatesError}
            setIsSigningApplicationModal={setIsSigningApplicationModal}
          />
        </CertificatesArea>
        <NudgeArea>
          <p>Нет нужного сертификата? </p>
          <Nudge onClick={onNudgeClick}>Перейдите в настройки добаления</Nudge>
        </NudgeArea>
      </CertificatesPopup>

      <CertificatesPopupError isActive={isCertificatesError}>
        <PopupExitError>
          <p onClick={onCloseErrorIconClick}>X</p>
        </PopupExitError>
        <h1>
          Невозможно подписать выбранным сертификатом
          <br />
          (не найден на устройстве)
        </h1>
      </CertificatesPopupError>
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ User, SCFDebtor }: STORE) => ({
  earlyPaymentApplications:
    SCFDebtor.getEarlyPaymentApplicationsDebtorRole.data,
  status: SCFDebtor.getEarlyPaymentApplicationsDebtorRole.status,
  error: SCFDebtor.getEarlyPaymentApplicationsDebtorRole.error,
  approveData: SCFDebtor.approveEarlyPaymentApplicationDebtorRole.data,
  approveStatus: SCFDebtor.approveEarlyPaymentApplicationDebtorRole.status,
  rejectedData: SCFDebtor.putEarlyPaymentApplicationDecline.data,
  rejectedStatus: SCFDebtor.putEarlyPaymentApplicationDecline.status,
  signedData: SCFDebtor.signedEarlyPaymentApplication.data,
  signedStatus: SCFDebtor.approveEarlyPaymentApplicationDebtorRole.status,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEarlyPaymentApplications,
      resetEarlyPaymentApplications,
      putEarlyPaymentApplicationDecline,
      resetEarlyPaymentApplicationDecline,
      approveEarlyPaymentApplication,
      resetApproveEarlyPaymentApplication,
      signedEarlyPaymentApplication,
      resetSignedEarlyPaymentApplication
    },
    dispatch
  );

const ApplicationsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationsList)
);

export { ApplicationsListConnect as ApplicationsList };
