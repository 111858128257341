import * as React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import { animation, StepsTransitionGroup } from 'Common';
import { StepsIndicator } from 'Application/components';

interface IRoute {
  path: string;
  component: any;
  step?: string;
}

interface OwnProps {
  routes: IRoute[];
  indicator?: boolean;
  location: any;
}

type Props = RouteComponentProps & OwnProps;

const EachForm: React.FC<Props> = ({
  routes,
  indicator = true,
  match,
  location
}) => (
  <React.Fragment>
    {!!indicator && <StepsIndicator routes={routes} />}

    <StepsTransitionGroup {...animation(location.state)} pageKey={location.key}>
      <Switch location={location}>
        {routes.map((route, key) => (
          <Route
            key={key}
            exact={true}
            path={!!route.path ? `${match.path}/${route.path}` : match.path}
            render={() => <route.component path={route.path} />}
          />
        ))}

        {/*TODO: find other way to avoid redirect warnings */}
        <Route
          render={({ match }) =>
            `${match.url}/${routes[0].path}` !== location.pathname &&
            `${match.url}/${routes[0].path}` !== window.location.pathname && (
              <Redirect to={`${match.url}/${routes[0].path}`} />
            )
          }
        />
      </Switch>
    </StepsTransitionGroup>
  </React.Fragment>
);

const WithRouterEachForm = withRouter(EachForm);

export { WithRouterEachForm as EachForm };
