import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface InternalActivities {
  activityTypeName: string;
  createdDateTime: string;
  result: string;
  extComment: string;
  id: number;
}

export interface ResponseDataInternal {
  totalItems: number;
  page: number;
  pageSize: number;
  items: InternalActivities[];
}

export interface GetActivitiesByLeadInternalStoreState extends Reducer {
  data: ResponseDataInternal;
}

export enum GET_ACTIVITIES_BY_LEAD_INTERNAL {
  REQ = 'GET_ACTIVITIES_BY_LEAD_INTERNAL_REQ',
  GOT = 'GET_ACTIVITIES_BY_LEAD_INTERNAL_GOT',
  ERR = 'GET_ACTIVITIES_BY_LEAD_INTERNAL_ERR'
}

export const initialState: GetActivitiesByLeadInternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getActivitiesByLeadInternal = (
  state: GetActivitiesByLeadInternalStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetActivitiesByLeadInternalStoreState => {
  switch (action.type) {
    case GET_ACTIVITIES_BY_LEAD_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ACTIVITIES_BY_LEAD_INTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ACTIVITIES_BY_LEAD_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getActivitiesByLeadInternal;
