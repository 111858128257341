import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';
import { got, err } from 'User/actions/reConfirmAccount';
import { RE_CONFIRM_ACCOUNT } from 'User/reducers/reConfirmAccount';

function* reConfirmAccount(action: {
  type: RE_CONFIRM_ACCOUNT;
  userId: string;
}) {
  try {
    const res:any = yield call(UserApi.reConfirmAccount, action.userId);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* reConfirmAccountSaga() {
  yield takeLatest(RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_REQ, reConfirmAccount);
}
