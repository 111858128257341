import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/postAgreementSignatureSupplierRole';

import {
  POST_AGREEMENT_SIGNATURE_SUPPLIER_ROLE,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/postAgreementSignatureSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* postAgreementSignatureSupplierRole(action: {
  type: POST_AGREEMENT_SIGNATURE_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.postAgreementSignatureSupplierRole, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAgreementSignatureSupplierRoleSaga() {
  yield takeLatest(
    POST_AGREEMENT_SIGNATURE_SUPPLIER_ROLE.REQ,
    postAgreementSignatureSupplierRole
  );
}
