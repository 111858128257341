import { combineReducers } from 'redux';

import SCFRegisterFactor, {
  SCFRegisterFactorStoreState
} from 'entities/SCF/Factor/model/reducers/SCFRegisterFactor';
import postFactoringConnectionFactorRole, {
  PostFactoringConnectionFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/postFactoringConnectionFactorRole';
import getFactoringConnectionsFactorRole, {
  GetFactoringConnectionsFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/getFactoringConnectionsFactorRole';
import getFactorRegistryFiles, {
  GetFactorRegistryFilesStoreState
} from 'entities/SCF/Factor/model/reducers/getFactorRegistryFiles';
import getFactoringContractsFactorRole, {
  GetFactoringContractsFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/getFactoringContractsFactorRole';
import getFactoringContractFactorRoleByGuid, {
  GetFactoringContractFactorRoleByGuidStoreState
} from 'entities/SCF/Factor/model/reducers/getFactoringContractFactorRoleByGuid';
import postAgreementSignatureFactorRole, {
  PostAgreementSignatureFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/postAgreementSignatureFactorRole';
import getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid, {
  GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState
} from 'entities/SCF/Factor/model/reducers/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';
import getEarlyPaymentApplicationsFactorRole, {
  GetEarlyPaymentApplicationsFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/getEarlyPaymentApplicationsFactorRole';
import putEarlyPaymentApplicationDeclineFactorRole, {
  PutEarlyPaymentApplicationDeclineFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';
import getOneFactorRoleByGuid, {
  GetOneFactorRoleByGuidStoreState
} from 'entities/SCF/Factor/model/reducers/getOneFactorRoleByGuid';
import getFactoringFeesOfLimits, {
  GetFactoringFeesOfLimitsStoreState
} from 'entities/SCF/Factor/model/reducers/getFactoringFeesOfLimits';
import postFactoringFeesOfLimits, {
  PostFactoringFeesOfLimitsStoreState
} from 'entities/SCF/Factor/model/reducers/postFactoringFeesOfLimits';
import getStartRateFactorRole, {
  GetStartRateFactorRoleStoreState
} from 'entities/SCF/Factor/model/reducers/getStartRateFactorRole';
import getFactoringConnectionsById, {
  GetFactoringConnectionsByIdStoreState
} from 'entities/SCF/Factor/model/reducers/getFactoringConnectionsById';
import deleteFactoringFeesOfLimits, {
  DeleteFactoringFeesOfLimitsStoreState
} from 'entities/SCF/Factor/model/reducers/deleteFactoringFeesOfLimits';
import patchFactoringFeesOfLimits, {
  PatchFactoringFeesOfLimitsStoreState
} from 'entities/SCF/Factor/model/reducers/patchFactoringFeesOfLimits';
import getCurrentFactoringSettingsByDebtorInn, {
  GetCurrentFactoringSettingsByDebtorInnStoreState
} from 'entities/SCF/Factor/model/reducers/getCurrentFactoringSettingsByDebtorInn';

export interface SCFFactorReducers {
  SCFRegisterFactor: SCFRegisterFactorStoreState;
  postFactoringConnectionFactorRole: PostFactoringConnectionFactorRoleStoreState;
  getFactoringConnectionsFactorRole: GetFactoringConnectionsFactorRoleStoreState;
  getFactorRegistryFiles: GetFactorRegistryFilesStoreState;
  getFactoringContractsFactorRole: GetFactoringContractsFactorRoleStoreState;
  getFactoringContractFactorRoleByGuid: GetFactoringContractFactorRoleByGuidStoreState;
  postAgreementSignatureFactorRole: PostAgreementSignatureFactorRoleStoreState;
  getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid: GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState;
  getEarlyPaymentApplicationsFactorRole: GetEarlyPaymentApplicationsFactorRoleStoreState;
  putEarlyPaymentApplicationDeclineFactorRole: PutEarlyPaymentApplicationDeclineFactorRoleStoreState;
  getOneFactorRoleByGuid: GetOneFactorRoleByGuidStoreState;
  getFactoringFeesOfLimits: GetFactoringFeesOfLimitsStoreState;
  postFactoringFeesOfLimits: PostFactoringFeesOfLimitsStoreState;
  getStartRateFactorRole: GetStartRateFactorRoleStoreState;
  getFactoringConnectionsById: GetFactoringConnectionsByIdStoreState;
  deleteFactoringFeesOfLimits: DeleteFactoringFeesOfLimitsStoreState;
  patchFactoringFeesOfLimits: PatchFactoringFeesOfLimitsStoreState;
  getCurrentFactoringSettingsByDebtorInn: GetCurrentFactoringSettingsByDebtorInnStoreState;
}

export const SCFFactor = combineReducers({
  SCFRegisterFactor,
  postFactoringConnectionFactorRole,
  getFactoringConnectionsFactorRole,
  getFactorRegistryFiles,
  getFactoringContractsFactorRole,
  getFactoringContractFactorRoleByGuid,
  postAgreementSignatureFactorRole,
  getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid,
  getEarlyPaymentApplicationsFactorRole,
  putEarlyPaymentApplicationDeclineFactorRole,
  getOneFactorRoleByGuid,
  getFactoringFeesOfLimits,
  postFactoringFeesOfLimits,
  getStartRateFactorRole,
  getFactoringConnectionsById,
  deleteFactoringFeesOfLimits,
  patchFactoringFeesOfLimits,
  getCurrentFactoringSettingsByDebtorInn
});
