import * as React from 'react';
import { connect } from 'react-redux';

import { Document as IDocument } from 'Application/types';
import { DocumentsBlock } from 'Layouts/components';
import { DownloadLink } from 'shared/styled';
import { STORE } from 'src/globaltypes';

import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';

import ApplicationApi from 'src/features/Application/api';
import { ApplicationSubtitle } from '../../styles';
import { GeneralDocumentsBlockStyled } from './styles';

interface OwnProps {
  documents: IDocument[];
}

interface StateToProps {
  internalApplication: ResInternalApplication;
}

type Props = OwnProps & StateToProps;

const GeneralDocumentsBlock: React.FC<Props> = ({
  internalApplication,
  documents
}) =>
  documents.length > 0 && (
    <GeneralDocumentsBlockStyled>
      <ApplicationSubtitle>
        Общие документы
        <DownloadLink
          href={ApplicationApi.getDownloadApplicationDocumentsLink(
            internalApplication.id
          )}
        />
      </ApplicationSubtitle>
      <DocumentsBlock documents={documents} />
    </GeneralDocumentsBlockStyled>
  );

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data
});

const GeneralDocumentsBlockConnect = connect<StateToProps>(mapStateToProps)(
  GeneralDocumentsBlock
);

export { GeneralDocumentsBlockConnect as GeneralDocumentsBlock };
