import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import {
  BankAccountsFormStyled,
  AddButton,
} from './styles';
import { BankAccountsList } from './components/BankAccountsList/BankAccountsList';
import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';

interface StateToProps {
}

interface DispatchToProps {
}

type Props = StateToProps & DispatchToProps;

class BankAccountsForm extends React.Component<Props> {
  bankAccountAddOnClick = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/createBankAccount');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/createBankAccount');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/createBankAccount');
    }
  }

  render() {
    return (
      <BankAccountsFormStyled>
        <BankAccountsList></BankAccountsList>
        <AddButton onClick={this.bankAccountAddOnClick}><p>+ Добавить</p></AddButton>
      </BankAccountsFormStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );


const BankAccountsFormConnect = (
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BankAccountsForm));


export { BankAccountsFormConnect as BankAccountsForm };
