import { GET_DOCUMENT_LIST_BY_APPLICATION_ID } from '../reducers/getDocumentListByApplicationId';

export interface DocumentListItems {
  guid: string;
  companyINN: string;
  companyShortName: string;
  companyRole: string;
  personINN: string;
  personName: string;
  personComment: string;
  documentTypeId: number;
  documentCategory: number;
  documentName: string;
  taxYear: number;
  taxPeriodId: number;
  taxPeriodName: string;
  isMandatory: boolean;
  isUploaded: boolean;
  documentGuid: string;
  extApi: string;
  isUploadedExternal: boolean;
  extTypeName: string;
  extTypeDescription: string;
}

export interface ResponseData {
  guid: string;
  items: DocumentListItems[];
}

export const req = (guid: string) => ({
  type: GET_DOCUMENT_LIST_BY_APPLICATION_ID.REQ,
  guid
});

export const got = (data: ResponseData) => ({
  type: GET_DOCUMENT_LIST_BY_APPLICATION_ID.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DOCUMENT_LIST_BY_APPLICATION_ID.ERR,
  error
});

export const reset = () => ({
  type: GET_DOCUMENT_LIST_BY_APPLICATION_ID.RESET
});
