import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getApplicationExternal';
import {
  GET_APPLICATION_EXTERNAL,
  RequestDataType
} from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';

import ApplicationApi from 'entities/Cabinet/ExternalAgent/api';

function* getApplicationExternal(action: {
  type: GET_APPLICATION_EXTERNAL;
  data: RequestDataType;
}) {
  try {
    const res = yield call(
      ApplicationApi.getApplicationExternal,
      action.data.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationExternalSaga() {
  yield takeLatest(GET_APPLICATION_EXTERNAL.REQ, getApplicationExternal);
}
