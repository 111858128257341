import { PATCH_CONTRACT_ACTIONS } from '../reducers/patchContract';

export const req = (id: string, data: any) => ({
  type: PATCH_CONTRACT_ACTIONS.REQ,
  id,
  data
});

export const got = () => ({
  type: PATCH_CONTRACT_ACTIONS.GOT
});

export const err = (error: {}) => ({
  type: PATCH_CONTRACT_ACTIONS.ERR,
  error
});
