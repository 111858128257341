import * as React from 'react';

import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';
import { EachCRM } from 'User/components';

export const CRMApproved: React.FC = () => (
  <EachCRM
    cabinetLists={[
      APPLICATIONS_LIST_TYPE.MY,
      APPLICATIONS_LIST_TYPE.DEAL,
      APPLICATIONS_LIST_TYPE.DRAFT
    ]}
  />
);
