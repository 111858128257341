import { GET_ONE_DISCOUNT_AUCTION } from '../reducers/getOneDiscountAuction';

export interface ResponseData {
  guid: string;
  createdDateTime: string;
  liquidityLimit: number;
  resultsDateTime: string;
  payDate: string;
  targetDiscountRate: number;
  status: string;
  minDiscountRate: number;
}

export const req = (guid: string) => ({
  type: GET_ONE_DISCOUNT_AUCTION.REQ,
  guid
});

export const got = (data: ResponseData) => ({
  type: GET_ONE_DISCOUNT_AUCTION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ONE_DISCOUNT_AUCTION.ERR,
  error
});

export const reset = () => ({
  type: GET_ONE_DISCOUNT_AUCTION.RESET
});
