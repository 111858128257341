import { GET_PRODUCTS_APPLICATION_LEADS } from '../reducers/getProductApplicationLead';

export interface ResponseData {
  productId: number;
  rating: number;
  sentToBank: boolean;
  status: number;
  rejectSubject: null;
  rejectReason: null;
  productName: string;
  bankName: string;
  complexity: string;
  hasContract: boolean;
  hasCashback: boolean;
  riskAppetite: string;
  minRate: number;
  meanRate: number;
  maxRate: number;
  offerSize: null;
  offerRate: null;
  offerTime: null;
  offerActualTill: null;
  apiComment: string;
}

export const req = (id: string) => ({
  type: GET_PRODUCTS_APPLICATION_LEADS.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_APPLICATION_LEADS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_APPLICATION_LEADS.ERR,
  error
});
