import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ContractsList } from './SupplyContractsList/SupplyContractsList';
import { ContractsInfoPage } from './SupplyContractInfo/SupplyContractInfo';
import { ContractsCreate } from './SupplyContractCreate/SupplyContractCreate';
import { SupplyContractsPageStyled } from './styles';

export class SupplyContractsPage extends React.Component {
  render() {
    return (
      <SupplyContractsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={'/supplier/supply_contracts'}
            render={() => <ContractsList />}
          />
          <Route
            exact={true}
            path={'/supplier/supply_contracts/add'}
            render={() => <ContractsCreate />}
          />
          <Route
            exact={true}
            path={'/supplier/supply_contracts/:guid'}
            render={() => <ContractsInfoPage />}
          />
        </Switch>
      </SupplyContractsPageStyled>
    );
  }
}
