import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Page, PaginationWrapper, Separator } from './styles';

interface PaginationProps {
  list: any;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & PaginationProps;

const PaginationView: React.FC<Props> = ({ location, list }) => {
  const { pageSize, totalItems } = list;
  const maxPages = 7;
  const separator = '...';

  const pagesCount = Math.ceil(totalItems / pageSize);
  const currentPage = parseInt(
    new URLSearchParams(location.search).get('page') || '1',
    10
  );

  const createPageLink = (pageNum: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageNum.toString());
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const renderPages = () => {
    const pages = [];
    if (pagesCount <= maxPages) {
      for (let i = 1; i <= pagesCount; i++) {
        pages.push(
          <Page
            key={i}
            to={createPageLink(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </Page>
        );
      }
    } else {
      pages.push(
        <Page
          key={1}
          to={createPageLink(1)}
          className={1 === currentPage ? 'active' : ''}
        >
          1
        </Page>
      );
      if (currentPage > 4)
        pages.push(<Separator key="start">{separator}</Separator>);

      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(pagesCount - 1, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <Page
            key={i}
            to={createPageLink(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </Page>
        );
      }

      if (currentPage < pagesCount - 3)
        pages.push(<Separator key="end">{separator}</Separator>);
      pages.push(
        <Page
          key={pagesCount}
          to={createPageLink(pagesCount)}
          className={pagesCount === currentPage ? 'active' : ''}
        >
          {pagesCount}
        </Page>
      );
    }

    return pages;
  };

  return (
    totalItems > pageSize && (
      <PaginationWrapper>{renderPages()}</PaginationWrapper>
    )
  );
};

export const Pagination = withRouter(PaginationView);
