import { SEND_RESET_PASSWORD_LETTER } from 'User/reducers/sendResetPasswordLetter';

export const req = (email: string) => ({
  type: SEND_RESET_PASSWORD_LETTER.REQ,
  email
});

export const got = () => ({
  type: SEND_RESET_PASSWORD_LETTER.GOT
});

export const err = (error: {}) => ({
  type: SEND_RESET_PASSWORD_LETTER.ERR,
  error
});

export const reset = () => ({
  type: SEND_RESET_PASSWORD_LETTER.RESET
});
