import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getCompanyExternal';

import { GET_COMPANY_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getCompanyExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getCompanyExternal(action: {
  type: GET_COMPANY_EXTERNAL;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      ExternalAgentRoleApi.getCompanyExternal,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyExternalSaga(): Generator {
  yield takeLatest(GET_COMPANY_EXTERNAL.REQ, getCompanyExternal);
}
