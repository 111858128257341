import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { LEGAL_TYPES } from 'globaltypes';
import { ValidatorMessages } from 'shared/constants';
import { passwordRules } from 'src/shared/utils/ValidationPatterns';
import {
  alwaysTrueRule,
  phoneRule,
  emailRule,
  legalFormRule,
  innValidationIndividualRule,
  innValidationEntityRule
} from 'src/shared/utils/ValidationRules';
import { BankManagerFormFields } from './BankManagerForm';

export const createValidation = () =>
  new Validator<BankManagerFormFields>({
    firstName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.firstNameRequire
      }
    ],
    lastName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.lastNameRequire
      }
    ],
    phoneNumber: [
      {
        rule: requiredRule,
        message: ValidatorMessages.phoneNumberRequire
      },
      {
        rule: phoneRule,
        message: ValidatorMessages.phoneNumberValid
      }
    ],
    email: [
      {
        rule: requiredRule,
        message: ValidatorMessages.emailRequire
      },
      {
        rule: emailRule,
        message: ValidatorMessages.emailValid
      }
    ]
  });
