import { GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER } from '../reducers/getAllDiscountAuctionsBySupplier';

export interface AuctionItem {
  debtorInn: string;
  debtorName: string;
  guid: string;
  minDiscountRate: number;
  payDate: string;
  resultsDateTime: string;
  status: string;
  targetDiscountRate: number;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: AuctionItem[];
}

export const req = () => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.ERR,
  error
});
