import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { ErrorModal, ModalContent } from './styles';
import { PopupTitle } from 'src/features/Contacts/components/ContactsList/styled';

interface State {}

interface StateToProps {
  leadStatus: REQUEST_STATUSES;
  leadError: ResponseError;
}

interface DispatchToProps {}

type Props = StateToProps & DispatchToProps;

class ErrorRejectReasonModal extends React.Component<Props, State> {
  render() {
    return (
      <ErrorModal>
        <ModalContent>
          <PopupTitle>Ошибка</PopupTitle>
          <p>{this.props.leadError.message}</p>
        </ModalContent>
      </ErrorModal>
    );
  }
}

const mapStateToProps = ({ Leads }: STORE) => ({
  leadStatus: Leads.changeLeadStatus.status,
  leadError: Leads.changeLeadStatus.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ErrorRejectReasonModalConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ErrorRejectReasonModal)
);

export { ErrorRejectReasonModalConnect as ErrorRejectReasonModal };
