import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from './../actions/getCompanyInfo';
import { GET_COMPANY_INFO } from './../reducers/getCompanyInfo';

import SCFApi from '../api';

function* getCompanyInfo( action: {
   type: GET_COMPANY_INFO;
  }) {
  try {
    const res:any = yield call(SCFApi.getCompanyInfo);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyInfoSaga() {
  yield takeLatest(GET_COMPANY_INFO.REQ, getCompanyInfo);
}
