import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ContractsList } from './ContractsList/ContractsList';
import { ContractsInfoPage } from './ContractsInfo/ContractsInfo';
import { ContractsPageStyled } from './styles';

export class SupplierFactoringContractsPage extends React.Component {
  render() {
    return (
      <ContractsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={'/supplier/factoring_contracts'}
            render={() => <ContractsList />}
          />
          <Route
            exact={true}
            path={'/supplier/factoring_contracts/:guid'}
            render={() => <ContractsInfoPage />}
          />
        </Switch>
      </ContractsPageStyled>
    );
  }
}
