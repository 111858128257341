import { call, takeLatest } from 'redux-saga/effects';
import CommentsApi from 'Comments/api';

export interface RequestDataType{
  commentId: number;
}


export default function* notifyAboutCommentSaga(data: RequestDataType) {
    yield call(CommentsApi.notifyAboutComment, data);
}

