import styled from 'styled-components';
import {
  CheckboxStyle,
  TooltipBoxStyle
} from 'src/features/Users/components/UsersList/styled';
import { Card } from 'src/features/Products/components/ProductView/styled';

export const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span:first-child {
    font-size: 1.6rem;
    color: rgba(63, 78, 101, 1);
    margin: auto;
  }
`;

export const CheckboxIsBlocked = styled(CheckboxStyle)`
  width: 32px;
  height: 32px;
  svg path {
    fill: ${({ isBlocked }) => isBlocked && '#F53737'};
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  h1 {
    color: ${({ isBlocked }) => (isBlocked ? '#F53737' : '#000000')};
  }
`;

export const TooltipIsBlocked = styled(TooltipBoxStyle)`
  &:hover:before {
    bottom: -32px;
    left: 32px;
    font-size: 14px;
    right: auto;
  }
`;

export const CompanyCard = styled(Card)``;
