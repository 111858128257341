import { GET_MANAGERS_AND_SIGNATORIES } from '../reducers/getManagersAndSignatories';
import { ManagersAndSignatoriesTypes } from '../types';

export const req = (inn: string) => ({
  type: GET_MANAGERS_AND_SIGNATORIES.REQ,
  inn
});

export const got = (data: ManagersAndSignatoriesTypes[]) => ({
  type: GET_MANAGERS_AND_SIGNATORIES.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_MANAGERS_AND_SIGNATORIES.ERR,
  err
});
