import styled from 'styled-components';
import { rr, gray, blue, navyBlue } from 'shared/styled';

export const UploaderDescriptionBlock = styled.div`
  margin: 0 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &::before {
    width: 40px;
    height: 40px;

    margin-right: 15px;
    display: block;

    content: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path fill="none" stroke="${gray}" stroke-linecap="round" stroke-width="2.526" d="M23.399 13.59l-7.405 7.872c-1.325 1.41-1.325 3.694 0 5.103 1.325 1.409 3.474 1.409 4.8 0L30 16.779c2.319-2.466 2.319-6.464 0-8.93-2.32-2.465-6.08-2.465-8.399 0L11.487 18.601c-3.313 3.523-3.313 9.234 0 12.757 3.313 3.523 8.686 3.523 11.999 0l7.114-7.564"/>
      </svg>
    ');
  }
`;

export const UploaderDescriptionText = styled.p`
  margin: 0;
  font-family: ${rr};
  font-size: 14px;
  line-height: 1.71;
  color: ${gray};
`;

export const OpenFileUploaderText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${blue};
`;

export const UploaderHintsBlock = styled.div`
  bottom: 0;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0 0 10px 0;
    font-size: 12px;
    font-family: ${rr};
    text-align: center;
    color: ${gray};

    b {
      font-family: inherit;
      font-weight: lighter;
      color: ${navyBlue};
    }
  }
`;
