import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/Person/actions/getCompanyByInnConfirmedBeneficiaries';
import { GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES } from 'src/features/Person/reducers/getCompanyByInnConfirmedBeneficiaries';
import BusinessApi from 'src/features/SCF/api/index';

function* getCompanyByInnConfirmedBeneficiries(action: {
  type: GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES;
  data: string[];
}): Generator {
  try {
    const res: any = yield all(
      action.data.map(item =>
        call(BusinessApi.getCompanyByInnThirdParty, { inn: item, force: true })
      )
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnConfirmedBeneficiriesSaga(): Generator {
  yield takeLatest(
    GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.REQ,
    getCompanyByInnConfirmedBeneficiries
  );
}
