import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectionsList } from './ConnectionsList/ConnectionsList';
import { ConnectionsCreatePage } from './ConnectionsCreate/ConnectionsCreate';
import { ConnectionsPageStyled } from './styles';

export class ConnectionsPage extends React.Component {
  render() {
    return (
      <ConnectionsPageStyled>
        <Switch>
          <Route
            exact
            path={'/supplier/connections'}
            render={() => <ConnectionsList />}
          />
          <Route
            path={'/supplier/connections/add'}
            render={() => <ConnectionsCreatePage />}
          />
        </Switch>
      </ConnectionsPageStyled>
    );
  }
}
