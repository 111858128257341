import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { req as getInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as getApplicationFlags } from 'Application/actions/getApplicationFlags';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';

import { EachForm } from 'Application/components/ApplicationForm/ApplicationProcess/ReusableComponents/EachForm/EachForm';
import { GuaranteesFormStep0 } from './GuaranteesFormStep0/GuaranteesFormStep0';
import { GuaranteesFormStep2 } from './GuaranteesFormStep2/GuaranteesFormStep2';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Loader } from 'src/features/Layouts/components';
import { ApplicationFormContrainer } from './styled';

interface StateToProps {
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  getApplicationFlags: () => void;
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & DispatchToProps & StateToProps;

const GuaranteesForm: React.FC<Props> = ({
  getInternalApplication,
  status,
  getApplicationFlags,
  setApplicationProcessMode,
  match,
  location
}) => {
  React.useEffect(
    () => {
      if (match.params.id) {
        getInternalApplication(match.params.id);
        getApplicationFlags();
      }

      return () =>
        setApplicationProcessMode({ mode: APPLICATION_PROCESS_MODES.NONE });
    },
    [match.params.id, location.pathname]
  );

  if (status !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <ApplicationFormContrainer>
      <EachForm
        routes={[
          {
            path: 'options',
            component: GuaranteesFormStep0,
            step: 'Параметры заявки'
          },
          {
            path: 'documents',
            component: GuaranteesFormStep2,
            step: 'Документы по заявке'
          }
        ]}
      />
    </ApplicationFormContrainer>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  status: InternalAgentRole.getInternalApplication.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getInternalApplication, getApplicationFlags, setApplicationProcessMode },
    dispatch
  );

const GuaranteesFormConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(GuaranteesForm)
);

export { GuaranteesFormConnect as GuaranteesForm };
