import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface CreateApplicationStoreState extends Reducer {
  id: number;
}

export enum CREATE_APPLICATION {
  REQ = 'CREATE_APPLICATION_REQ',
  GOT = 'CREATE_APPLICATION_GOT',
  ERR = 'CREATE_APPLICATION_ERR',
  RESET = 'CREATE_APPLICATION_RESET'
}

export const initialState: CreateApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  id: null,
  error: {}
};

const createApplication = (
  state: CreateApplicationStoreState = initialState,
  action: {
    type: CREATE_APPLICATION;
    id: number;
    error: {};
  }
): CreateApplicationStoreState => {
  switch (action.type) {
    case CREATE_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CREATE_APPLICATION.GOT:
      return {
        ...state,
        id: action.id,
        status: REQUEST_STATUSES.GOT
      };
    case CREATE_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case CREATE_APPLICATION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default createApplication;
