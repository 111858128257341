import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Investor as ResponseDataType } from 'entities/SCF/Supplier/model/actions/getInvestorsListSupplierRoleByGuid';

export interface GetInvestorsListSupplierRoleByGuidStoreState extends Reducer {
  data: ResponseDataType[];
}

export enum GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID {
  GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_REQ = 'GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_REQ',
  GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_GOT = 'GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_GOT',
  GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_ERR = 'GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_ERR'
}

export const initialState: GetInvestorsListSupplierRoleByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [{
    payerInn: '',
    payerName: '',
    rate: 0,
    firstPaymentShare: 0,
    payerType: 0,
    isRegressed: null,
  }],
  error: {}
};

const getInvestorsListSupplierRoleByGuid = (
  state: GetInvestorsListSupplierRoleByGuidStoreState = initialState,
  action: {
    type: GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID;
    data: ResponseDataType[];
    error: {};
  }
): GetInvestorsListSupplierRoleByGuidStoreState => {
  switch (action.type) {
    case GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getInvestorsListSupplierRoleByGuid;
