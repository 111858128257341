import { DELEGATE_TO_INTERNAL_AGENT } from 'InternalAgentsManager/reducers/delegateToInternalAgent';

export interface RequestData {
  userId: number;
  applicationId: number;
}

export const req = (data: RequestData) => ({
  type: DELEGATE_TO_INTERNAL_AGENT.REQ,
  data
});

export const got = () => ({
  type: DELEGATE_TO_INTERNAL_AGENT.GOT
});

export const err = (error: {}) => ({
  type: DELEGATE_TO_INTERNAL_AGENT.ERR,
  error
});
