import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';

export interface GetMonetaryClaimsSuppliesRoleStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_MONETARY_CLAIMS_SUPPLIES_ROLE {
  REQ = 'GET_MONETARY_CLAIMS_SUPPLIES_ROLE_REQ',
  GOT = 'GET_MONETARY_CLAIMS_SUPPLIES_ROLE_GOT',
  ERR = 'GET_MONETARY_CLAIMS_SUPPLIES_ROLE_ERR',
  RESET = 'GET_MONETARY_CLAIMS_SUPPLIES_ROLE_RESET'
}

export const initialState: GetMonetaryClaimsSuppliesRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: [],
    baseFullSum: 0,
    suppliersQty: 0
  },
  error: {}
};

const getMonetaryClaimsSuppliesRole = (
  state: GetMonetaryClaimsSuppliesRoleStoreState = initialState,
  action: {
    type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetMonetaryClaimsSuppliesRoleStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_SUPPLIES_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_SUPPLIES_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_SUPPLIES_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_MONETARY_CLAIMS_SUPPLIES_ROLE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsSuppliesRole;
