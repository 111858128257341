import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { all, fork } from 'redux-saga/effects';

// do not install @types/redux-localstorage version 1.0.8
// it wont work
import persistState from 'redux-localstorage';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { Cached } from 'App/reducers';
import { FactoringChecker } from 'FactoringChecker/reducers';

import { Properties } from 'Properties/reducers';

import { User } from 'User/reducers';
import { Filters } from 'Filters/reducers';
import { Application } from 'Application/reducers';
import { ExternalAgent } from 'ExternalAgent/reducers';
import { Permissions } from 'Permissions/reducers';
import { InternalAgentsManager } from 'InternalAgentsManager/reducers';
import { Business } from 'Business/reducers';
import { Modal } from 'Modal/reducers';
import { Comment } from 'Comments/reducers';
import { BorrowerCompanies } from 'BorrowerCompanies/reducers';
import { ApiKeys } from 'ApiKeys/reducers';
import { Leads } from 'Leads/reducers';

import { Companies } from '../features/Companies/reducers';
import { FactoringLimits } from '../features/FactoringLimits/reducers';
import { Debtors } from '../features/Debtors/reducers';
import { Finbanks } from '../features/Finbanks/reducers';
import { Products } from '../features/Products/reducers';
import { ContactsOfProducts } from '../features/ContactsOfProducts/reducers';
import { ExternalCredentialsOfProducts } from '../features/ExternalCredentialsOfProducts/reducers';
import { ExternalCredentials } from '../features/ExternalCredentials/reducers';
import { Contacts } from '../features/Contacts/reducers';
import { Holdings } from '../features/Holdings/reducers';
import { Users } from '../features/Users/reducers';
import { Suppliers } from '../features/UserSuppliers/reducers';
import { UserDebtors } from '../features/UserDebtors/reducers';
import { UserFactors } from '../features/UserFactors/reducers';
import { Cooperation } from '../features/CooperationForProducts/reducers';
import { CustomerRequirements } from '../features/CustomerRequirements/reducers';
import { Contract } from '../features/ContractLogic/reducers';
import { StopFactors } from '../features/StopFactors/reducers';
import { BasisParams } from '../features/BasicParametersForProducts/reducers';
import { Pledge } from '../features/ProductPledge/reducers';
import { ContactDetail } from '../features/ContactDetailsForContact/reducers';
import { LeadsOfCompanies } from '../features/ApplicationForCompanies/reducers';
import { ActivitiesOfCompanies } from '../features/ActivitiesOfCompanies/reducers';
import { Activities } from '../features/Activities/reducers';
import { Agents } from '../features/Agents/reducers';
import { Platforms } from '../features/PlatformsForProducts/reducers';
import { Counterparties } from '../features/Counterparties/reducers';
import { BankProducts } from '../features/BankProducts/reducers';
import { Clients } from '../features/Clients/reducers';
import { Questionnaire } from '../features/Questionnaire/reducers';
import { ClientsForAgent } from '../features/ClientsForAgent/reducers';
import { Dossier } from '../features/DossierForCompany/reducers';
import { FinancialStatements } from '../features/FinancialForCompany/reducers';
import { ProductsBank } from '../features/ProductsForBank/reducers';
import { LimitsForBank } from '../features/LimitsForBank/reducers';
import { IntegrationApplications } from '../features/IntegrationApplications/reducers';
import { Beneficiaries } from '../features/Beneficiaries/reducers';
import { Persons } from '../features/Persons/reducers';
import { Tenders } from '../features/Tenders/reducers';
import { BuyersToSupplierLink } from '../features/BuyersToSupplierLink/reducers';
import { BankAccount } from '../features/BankAccount/reducers';
import { SupplyContracts } from '../features/SupplyContracts/reducers';
import { FactoringContracts } from '../features/FactoringContracts/reducers';
import { DocumentList } from '../features/DocumentList/reducers';
import { Person } from '../features/Person/reducers';
import { MonetaryClaims } from '../features/MonetaryClaims/reducers';
import { PreapprovedLimits } from '../features/PreapprovedLimits/reducers';
import { EarlyPayment } from '../features/EarlyPaymentApplications/reducers';
import { ClientRole } from 'entities/Cabinet/Client/model/reducers';
import { InternalAgentRole } from 'entities/Cabinet/InternalAgent/model/reducers';
import { PreApproval } from '../features/PreApproval/reducers';
import { CompaniesListCRM } from '../features/CompaniesLists/reducers';
import { ExternalAgentRole } from 'entities/Cabinet/ExternalAgent/model/reducers';
import { BankRole } from 'entities/Cabinet/Bank/model/reducers';

import { SCF } from '../features/SCF/reducers';
import { SCFDebtor } from 'entities/SCF/Debtor/model/reducers';
import { SCFFactor } from 'entities/SCF/Factor/model/reducers';
import { SCFSupplier } from 'entities/SCF/Supplier/model/reducers';
import { SCFInternal } from 'entities/SCF/Internal/model/reducers';

import UserSagas from 'User/sagas';
import FactoringCheckerSagas from 'FactoringChecker/sagas';

import PropertiesSagas from 'Properties/sagas';

import ExternalAgentSagas from 'ExternalAgent/sagas';
import PermissionsSagas from 'Permissions/sagas';
import InternalAgentsManagerSagas from 'InternalAgentsManager/sagas';
import ApplicationSagas from 'Application/sagas';
import BusinessSagas from 'Business/sagas';
import CommentSagas from 'Comments/sagas';
import BorrowerCompaniesSagas from 'BorrowerCompanies/sagas';
import ApiKeysSagas from 'ApiKeys/sagas';
import LeadSagas from 'Leads/sagas';
import FilterSagas from 'Filters/sagas';

import CompanySagas from 'Companies/sagas';
import FactoringLimitSagas from '../features/FactoringLimits/sagas';
import DebtorSagas from '../features/Debtors/sagas';
import FinbankSagas from '../features/Finbanks/sagas';
import ProductSagas from '../features/Products/sagas';
import ContactsOfProductsSagas from '../features/ContactsOfProducts/sagas';
import ExternalCredentialsOfProductsSagas from '../features/ExternalCredentialsOfProducts/sagas';
import ExternalCredentialsSagas from '../features/ExternalCredentials/sagas';
import ContactSagas from '../features/Contacts/sagas';
import HoldingSagas from '../features/Holdings/sagas';
import SCFSagas from '../features/SCF/sagas';
import SCFDebtorSagas from 'entities/SCF/Debtor/model/sagas';
import SCFFactorSagas from 'entities/SCF/Factor/model/sagas';
import SCFSupplierSagas from 'entities/SCF/Supplier/model/sagas';
import SCFInternalSagas from 'entities/SCF/Internal/model/sagas';
import UsersSagas from '../features/Users/sagas';
import SuppliersSagas from '../features/UserSuppliers/sagas';
import UserDebtorsSagas from '../features/UserDebtors/sagas';
import UserFactorsSagas from '../features/UserFactors/sagas';
import CooperationSagas from '../features/CooperationForProducts/sagas';
import CustomerRequirementsSagas from '../features/CustomerRequirements/sagas';
import ContractSagas from '../features/ContractLogic/sagas';
import StopFactorsSagas from '../features/StopFactors/sagas';
import BasisParamsSagas from '../features/BasicParametersForProducts/sagas';
import PledgeSagas from '../features/ProductPledge/sagas';
import ContactDetailSagas from '../features/ContactDetailsForContact/sagas';
import LeadsOfCompaniesSagas from '../features/ApplicationForCompanies/sagas';
import ActivitiesOfCompaniesSagas from '../features/ActivitiesOfCompanies/sagas';
import ActivitiesSagas from '../features/Activities/sagas';
import AgentsSagas from '../features/Agents/sagas';
import PlatformsSagas from '../features/PlatformsForProducts/sagas';
import CounterpartiesSagas from '../features/Counterparties/sagas';
import BankProductsSagas from '../features/BankProducts/sagas';
import ClientsSagas from '../features/Clients/sagas';
import QuestionnaireSagas from '../features/Questionnaire/sagas';
import ClientsForAgentSagas from '../features/ClientsForAgent/sagas';
import DossierForCompanySagas from '../features/DossierForCompany/sagas';
import FinancialForCompanySagas from '../features/FinancialForCompany/sagas';
import ProductsBankSagas from '../features/ProductsForBank/sagas';
import LimitsForBankSagas from '../features/LimitsForBank/sagas';
import IntegrationApplicationsSagas from '../features/IntegrationApplications/sagas';
import BeneficiariesSagas from '../features/Beneficiaries/sagas';
import PersonsSagas from '../features/Persons/sagas';
import TendersSagas from '../features/Tenders/sagas';
import BuyersToSupplierLinkSagas from '../features/BuyersToSupplierLink/sagas';
import BankAccountSagas from '../features/BankAccount/sagas';
import SupplySagas from '../features/SupplyContracts/sagas';
import FactoringContractsSagas from '../features/FactoringContracts/sagas';
import DocumentListSagas from '../features/DocumentList/sagas';
import PersonSagas from '../features/Person/sagas';
import MonetaryClaimsSagas from '../features/MonetaryClaims/sagas';
import PreapprovedLimitsSagas from '../features/PreapprovedLimits/sagas';
import EarlyPaymentApplicationsSagas from '../features/EarlyPaymentApplications/sagas';
import ClientRoleSagas from 'entities/Cabinet/Client/model/sagas';
import InternalAgentSagas from 'entities/Cabinet/InternalAgent/model/sagas';
import ExternalAgentRoleSagas from 'entities/Cabinet/ExternalAgent/model/sagas';
import BankRoleSagas from 'entities/Cabinet/Bank/model/sagas';
import PreApprovalSagas from '../features/PreApproval/sagas';
import CompaniesListsCRMSagas from '../features/CompaniesLists/sagas';

const createLocalStore = compose(
  persistState(['Cached', 'Application'], {
    key: 'finfactory-redux-store',
    slicer: paths => state => {
      let persist = {};

      paths.forEach(path => {
        switch (path) {
          case 'Application':
            persist[path] = {
              setFactoringFormData: state[path].setFactoringFormData,
              setListViewType: state[path].setListViewType,
              setApplicationProcessMode: state[path].setApplicationProcessMode
            };
            break;
          default:
            persist[path] = state[path];
        }
      });

      return persist;
    }
  })
)(createStore);

export const mergedReducers = {
  FactoringChecker,

  Properties,

  Cached,
  User,
  Application,
  ExternalAgent,
  Permissions,
  Business,
  Modal,
  Comment,
  BorrowerCompanies,
  InternalAgentsManager,
  Filters,
  ApiKeys,
  Leads,
  Companies,
  FactoringLimits,
  Debtors,
  Finbanks,
  Products,
  ContactsOfProducts,
  ExternalCredentialsOfProducts,
  ExternalCredentials,
  Contacts,
  Holdings,
  SCF,
  SCFDebtor,
  SCFFactor,
  SCFSupplier,
  Users,
  Suppliers,
  UserDebtors,
  UserFactors,
  Cooperation,
  CustomerRequirements,
  Contract,
  StopFactors,
  BasisParams,
  Pledge,
  ContactDetail,
  LeadsOfCompanies,
  ActivitiesOfCompanies,
  Activities,
  Agents,
  Platforms,
  Counterparties,
  BankProducts,
  Clients,
  Questionnaire,
  ClientsForAgent,
  Dossier,
  FinancialStatements,
  ProductsBank,
  LimitsForBank,
  IntegrationApplications,
  Beneficiaries,
  Persons,
  Tenders,
  BuyersToSupplierLink,
  BankAccount,
  SupplyContracts,
  FactoringContracts,
  DocumentList,
  Person,
  MonetaryClaims,
  PreapprovedLimits,
  SCFInternal,
  EarlyPayment,
  ClientRole,
  InternalAgentRole,
  PreApproval,
  CompaniesListCRM,
  ExternalAgentRole,
  BankRole
};

const sagaMiddleware = createSagaMiddleware();
const store = createLocalStore(
  combineReducers(mergedReducers),
  {},
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export function* rootSaga() {
  const UserSagasForks = UserSagas.map(saga => fork(saga));
  const FactoringCheckerForks = FactoringCheckerSagas.map(saga => fork(saga));

  const PropertiesForks = PropertiesSagas.map(saga => fork(saga));

  const ApplicationSagasForks = ApplicationSagas.map(saga => fork(saga));
  const ExternalAgentSagasForks = ExternalAgentSagas.map(saga => fork(saga));
  const PermissionsSagasForks = PermissionsSagas.map(saga => fork(saga));
  const InternalAgentsManagerSagasForks = InternalAgentsManagerSagas.map(saga =>
    fork(saga)
  );
  const BusinessSagasForks = BusinessSagas.map(saga => fork(saga));
  const CommentSagasForks = CommentSagas.map(saga => fork(saga));
  const ApiKeysSagasForks = ApiKeysSagas.map(saga => fork(saga));
  const LeadsForks = LeadSagas.map(saga => fork(saga));
  const FiltersForks = FilterSagas.map(saga => fork(saga));
  const BorrowerCompaniesSagasForks = BorrowerCompaniesSagas.map(saga =>
    fork(saga)
  );

  const CompaniesForks = CompanySagas.map(saga => fork(saga));
  const FactoringLimitsForks = FactoringLimitSagas.map(saga => fork(saga));
  const DebtorsForks = DebtorSagas.map(saga => fork(saga));
  const FinbanksForks = FinbankSagas.map(saga => fork(saga));
  const ProductsForks = ProductSagas.map(saga => fork(saga));
  const ContactsOfProductsForks = ContactsOfProductsSagas.map(saga =>
    fork(saga)
  );
  const ExternalCredentialsOfProductsForks = ExternalCredentialsOfProductsSagas.map(
    saga => fork(saga)
  );
  const ExternalCredentialsForks = ExternalCredentialsSagas.map(saga =>
    fork(saga)
  );
  const ContactsForks = ContactSagas.map(saga => fork(saga));
  const HoldingsForks = HoldingSagas.map(saga => fork(saga));

  const SCFInternalForks = SCFInternalSagas.map(saga => fork(saga));
  const SCFSupplierForks = SCFSupplierSagas.map(saga => fork(saga));
  const SCFFactorForks = SCFFactorSagas.map(saga => fork(saga));
  const SCFDebtorForks = SCFDebtorSagas.map(saga => fork(saga));
  const SCFForks = SCFSagas.map(saga => fork(saga));
  const UsersForks = UsersSagas.map(saga => fork(saga));
  const SuppliersForks = SuppliersSagas.map(saga => fork(saga));
  const UserDebtorsForks = UserDebtorsSagas.map(saga => fork(saga));
  const UserFactorsForks = UserFactorsSagas.map(saga => fork(saga));
  const CooperationForks = CooperationSagas.map(saga => fork(saga));
  const CustomerRequirementsForks = CustomerRequirementsSagas.map(saga =>
    fork(saga)
  );
  const ContractForks = ContractSagas.map(saga => fork(saga));
  const StopFactorsForks = StopFactorsSagas.map(saga => fork(saga));
  const BasisParamsForks = BasisParamsSagas.map(saga => fork(saga));
  const PledgeForks = PledgeSagas.map(saga => fork(saga));
  const ContactDetailForks = ContactDetailSagas.map(saga => fork(saga));
  const LeadsOfCompaniesForks = LeadsOfCompaniesSagas.map(saga => fork(saga));
  const ActivitiesOfCompaniesForks = ActivitiesOfCompaniesSagas.map(saga =>
    fork(saga)
  );
  const ActivitiesForks = ActivitiesSagas.map(saga => fork(saga));
  const AgentsForks = AgentsSagas.map(saga => fork(saga));
  const PlatformsForks = PlatformsSagas.map(saga => fork(saga));
  const CounterpartiesForks = CounterpartiesSagas.map(saga => fork(saga));
  const BankProductsForks = BankProductsSagas.map(saga => fork(saga));
  const ClientsForks = ClientsSagas.map(saga => fork(saga));
  const QuestionnaireForks = QuestionnaireSagas.map(saga => fork(saga));
  const ClientsForAgentForks = ClientsForAgentSagas.map(saga => fork(saga));
  const DossierForCompanyForks = DossierForCompanySagas.map(saga => fork(saga));
  const FinancialForCompanyForks = FinancialForCompanySagas.map(saga =>
    fork(saga)
  );
  const ProductsBankForks = ProductsBankSagas.map(saga => fork(saga));
  const LimitsForBankForks = LimitsForBankSagas.map(saga => fork(saga));
  const IntegrationApplicationsForks = IntegrationApplicationsSagas.map(saga =>
    fork(saga)
  );
  const BeneficiariesForks = BeneficiariesSagas.map(saga => fork(saga));
  const PersonsForks = PersonsSagas.map(saga => fork(saga));
  const TendersForks = TendersSagas.map(saga => fork(saga));
  const BuyersToSupplierLinkForks = BuyersToSupplierLinkSagas.map(saga =>
    fork(saga)
  );
  const BankAccountForks = BankAccountSagas.map(saga => fork(saga));
  const SupplyForks = SupplySagas.map(saga => fork(saga));
  const FactoringContractsForks = FactoringContractsSagas.map(saga =>
    fork(saga)
  );
  const DocumentListForks = DocumentListSagas.map(saga => fork(saga));
  const PersonForks = PersonSagas.map(saga => fork(saga));
  const MonetaryClaimsForks = MonetaryClaimsSagas.map(saga => fork(saga));
  const PreapprovedLimitsForks = PreapprovedLimitsSagas.map(saga => fork(saga));
  const EarlyPaymentApplicationsForks = EarlyPaymentApplicationsSagas.map(
    saga => fork(saga)
  );
  const ClientRoleForks = ClientRoleSagas.map(saga => fork(saga));
  const InternalAgentForks = InternalAgentSagas.map(saga => fork(saga));
  const ExternalAgentRoleForks = ExternalAgentRoleSagas.map(saga => fork(saga));
  const BankRoleForks = BankRoleSagas.map(saga => fork(saga));
  const PreApprovalForks = PreApprovalSagas.map(saga => fork(saga));
  const CompaniesListsCRMForks = CompaniesListsCRMSagas.map(saga => fork(saga));

  yield all([
    ...FactoringCheckerForks,

    ...PropertiesForks,
    ...UserSagasForks,
    ...ApplicationSagasForks,
    ...ExternalAgentSagasForks,
    ...PermissionsSagasForks,
    ...InternalAgentsManagerSagasForks,
    ...BusinessSagasForks,
    ...CommentSagasForks,
    ...BorrowerCompaniesSagasForks,
    ...ApiKeysSagasForks,
    ...LeadsForks,
    ...FiltersForks,

    ...CompaniesForks,
    ...FactoringLimitsForks,
    ...DebtorsForks,
    ...FinbanksForks,
    ...ProductsForks,
    ...ContactsOfProductsForks,
    ...ExternalCredentialsOfProductsForks,
    ...ExternalCredentialsForks,
    ...ContactsForks,
    ...HoldingsForks,
    ...SCFForks,
    ...SCFDebtorForks,
    ...SCFFactorForks,
    ...SCFSupplierForks,
    ...SCFInternalForks,
    ...UsersForks,
    ...SuppliersForks,
    ...UserDebtorsForks,
    ...UserFactorsForks,
    ...CooperationForks,
    ...CustomerRequirementsForks,
    ...ContractForks,
    ...StopFactorsForks,
    ...BasisParamsForks,
    ...PledgeForks,
    ...ContactDetailForks,
    ...LeadsOfCompaniesForks,
    ...ActivitiesOfCompaniesForks,
    ...ActivitiesForks,
    ...AgentsForks,
    ...PlatformsForks,
    ...CounterpartiesForks,
    ...BankProductsForks,
    ...ClientsForks,
    ...QuestionnaireForks,
    ...ClientsForAgentForks,
    ...DossierForCompanyForks,
    ...FinancialForCompanyForks,
    ...ProductsBankForks,
    ...LimitsForBankForks,
    ...IntegrationApplicationsForks,
    ...BeneficiariesForks,
    ...PersonsForks,
    ...TendersForks,
    ...BuyersToSupplierLinkForks,
    ...BankAccountForks,
    ...SupplyForks,
    ...FactoringContractsForks,
    ...DocumentListForks,
    ...PersonForks,
    ...MonetaryClaimsForks,
    ...PreapprovedLimitsForks,
    ...EarlyPaymentApplicationsForks,
    ...ClientRoleForks,
    ...InternalAgentForks,
    ...ExternalAgentRoleForks,
    ...BankRoleForks,
    ...PreApprovalForks,
    ...CompaniesListsCRMForks
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;
