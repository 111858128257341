import { PATCH_COMPANY_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/patchCompanyExternal';

export interface RequestData {
  taxSystem: number;
}

export const req = (inn: string, data: RequestData) => ({
  type: PATCH_COMPANY_EXTERNAL.REQ,
  data,
  inn
});

export const got = () => ({
  type: PATCH_COMPANY_EXTERNAL.GOT
});

export const err = (error: {}) => ({
  type: PATCH_COMPANY_EXTERNAL.ERR,
  error
});
