import styled from 'styled-components';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { blue, lightGray, rl } from 'shared/styled';

export const Status = styled.div`
  width: 75%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ status }) =>
    status === 'Не загружен' || status === 'Не подписан поставщиком'
      ? lightGray
      : status === 'Ожидает вашего подписания'
        ? 'rgba(253, 241, 224, 1)'
        : status === 'Подписан' && 'rgba(213, 232, 253, 1)'};
`;

export const DownloadContractContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  a {
    color: ${blue};
  }
`;

export const TDDocument = styled.td`
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible !important;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  height: 19px;

  img{
    height: 19px;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    right: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TableTRStyled = styled(TableRowStyled)`
  height: 85px;
`;

export const NoData = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    button {
      width: fit-content;
    }
    h2 {
      font-size: 32px;
      font-family: ${rl};
    }
  }
`;
