import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/ApiKeys/actions/getApiKeyInfo';

export interface GetApiKeyInfoStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_API_KEY_INFO {
  REQ = 'GET_API_KEY_INFO_REQ',
  GOT = 'GET_API_KEY_INFO_GOT',
  ERR = 'GET_API_KEY_INFO_ERR'
}

export const initialState: GetApiKeyInfoStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getApiKeyInfo = (
  state: GetApiKeyInfoStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetApiKeyInfoStoreState => {
  switch (action.type) {
    case GET_API_KEY_INFO.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_API_KEY_INFO.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_API_KEY_INFO.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getApiKeyInfo;
