import { SET_EXTERNAL_CREDENTIAL_DATA } from '../reducers/setExternalCredentialFormData';

export interface ExternalCredentialWrite {
  id?: number;
  ourCompany?: string;
  website?: string;
  login?: string;
  password?: string;
  comment?: string;
  signatureRequired?: null;
  ourCompanyINN?: string;
  isBlocked?: boolean;
  platformINN?: string;
  platformName?: string;
  isBankPlatform?: boolean;
  isCompetitorPlatform?: boolean;
  isDeleted?: boolean;
}

export interface ExternalCredentialRead extends ExternalCredentialWrite {}

export interface ExternalCredentialsData
  extends Partial<ExternalCredentialWrite> {}

export const setExternalCredentialFormData = (
  data: ExternalCredentialsData
) => ({
  type: SET_EXTERNAL_CREDENTIAL_DATA.SET,
  data
});

export const resetExternalCredentialFormData = () => ({
  type: SET_EXTERNAL_CREDENTIAL_DATA.RESET
});

export const got = () => ({
  type: SET_EXTERNAL_CREDENTIAL_DATA.GOT
});

export const err = (error: {}) => ({
  type: SET_EXTERNAL_CREDENTIAL_DATA.ERR,
  error
});
