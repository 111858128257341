import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import {
  ResponseError,
  REQUEST_STATUSES,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { TableHeaderStyled } from 'Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'Layouts/components';
import { ApplicationTitle } from 'Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  req as getAllOfBank,
  ResponseData as ResAllOfBank
} from 'entities/Cabinet/Bank/model/actions/getAllOfBank';
import { ScrollTopComponent } from 'Common';
import {
  CheckboxStyleEmail,
  PhoneBlock,
  RoleBlock,
  TDEmailStyle,
  TooltipBoxStyle
} from './styled';
import { format } from 'date-fns';

interface StateToProps {
  users: ResAllOfBank[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getAllOfBank: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const UserList: React.FC<Props> = ({ getAllOfBank, users, status, error }) => {
  React.useEffect(() => {
    getAllOfBank();
  }, []);

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все пользователи</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="10%">Имя</TableThStyled>
              <TableThStyled width="10%">Телефон</TableThStyled>
              <TableThStyled width="12%">Email</TableThStyled>
              <TableThStyled width="10%" style={{ textAlign: 'center' }}>
                Дата регистрации
              </TableThStyled>
              <TableThStyled width="10%" style={{ textAlign: 'center' }}>
                Последний вход
              </TableThStyled>
              <TableThStyled width="20%">Роли</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {users.map(user => (
              <TableRowStyled key={user.userGuid}>
                <td>{user.userGuid.slice(user.userGuid.length - 5)}</td>
                <td>
                  {user.lastName} {user.firstName}
                </td>
                <td>
                  <PhoneBlock phone={user.phoneNumber}>
                    {user.phoneNumber ? user.phoneNumber : 'не заполнен'}
                  </PhoneBlock>
                </td>
                <TDEmailStyle>
                  <td>{user.email}</td>
                  {user.emailConfirmed ? (
                    <TooltipBoxStyle textVerify={'Email подтвержден'}>
                      <CheckboxStyleEmail verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faCheck} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  ) : (
                    <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                      <CheckboxStyleEmail verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  )}
                </TDEmailStyle>
                <td style={{ textAlign: 'center' }}>
                  {user.createdDateTime &&
                    format(new Date(user.createdDateTime), 'dd.MM.yyyy')}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {user.lastLoginAt &&
                    format(new Date(user.lastLoginAt), 'dd.MM.yyyy')}
                </td>
                <td>
                  {
                    <RoleBlock
                      admin={user.permissions.includes(
                        USER_PERMISSIONS.BANK_ADMIN
                      )}
                    >
                      {user.permissions.includes(USER_PERMISSIONS.BANK_ADMIN)
                        ? 'Администратор'
                        : 'Сотрудник'}
                    </RoleBlock>
                  }
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={users} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ User, BankRole }: STORE) => ({
  users: BankRole.getAllOfBank.data,
  status: BankRole.getAllOfBank.status,
  error: BankRole.getAllOfBank.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllOfBank
    },
    dispatch
  );

const UserListConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserList)
);

export { UserListConnect as UserList };
