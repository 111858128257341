import { requiredRule } from 'instant-validation/build/rules';
import { ValidatorMessages } from 'shared/constants';
import {
  passwordCharEnum,
  passwordCharCase,
  minLength,
  maxLength
} from 'src/shared/utils/ValidationRules';

export const passwordRules = [
  {
    rule: requiredRule,
    message: ValidatorMessages.passwordRequire
  },
  {
    rule: passwordCharEnum,
    message: ValidatorMessages.passwordIncorrect
  },
  {
    rule: passwordCharCase,
    message: ValidatorMessages.passwordIncorrect
  },
  {
    rule: minLength(8),
    message: ValidatorMessages.passwordShort
  },
  {
    rule: maxLength(14),
    message: ValidatorMessages.passwordLong
  }
];
