import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { rr, blue, gray } from 'shared/styled';

export const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
`;

const PaginationItem = css`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: transparent;

  font-family: ${rr};
  font-size: 1.4rem;
  color: ${gray};
`;

export const Page = styled(Link)`
  ${PaginationItem};

  border-radius: 50%;
  background-color: transparent;

  text-decoration: none;

  &.active {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    color: ${blue};
  }
`;

export const Separator = styled.span`
  user-select: none;
`;

export const PreviousPage = styled(Link)`
  ${PaginationItem};
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 1px;
  width: 9px;
  height: 14px;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(164, 164, 164)" d="M9.1248 19.0999L0.699804 10.6999C0.599804 10.5999 0.529138 10.4916 0.487804 10.3749C0.445804 10.2582 0.424805 10.1332 0.424805 9.9999C0.424805 9.86657 0.445804 9.74157 0.487804 9.6249C0.529138 9.50824 0.599804 9.3999 0.699804 9.2999L9.1248 0.874902C9.35814 0.641569 9.6498 0.524902 9.9998 0.524902C10.3498 0.524902 10.6498 0.649902 10.8998 0.899902C11.1498 1.1499 11.2748 1.44157 11.2748 1.7749C11.2748 2.10824 11.1498 2.3999 10.8998 2.6499L3.5498 9.9999L10.8998 17.3499C11.1331 17.5832 11.2498 17.8706 11.2498 18.2119C11.2498 18.5539 11.1248 18.8499 10.8748 19.0999C10.6248 19.3499 10.3331 19.4749 9.9998 19.4749C9.66647 19.4749 9.3748 19.3499 9.1248 19.0999Z" />
  </svg>
  ');
`;

export const PreviousPageDisabled = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 1px;
  width: 9px;
  height: 15px;
  cursor: not-allowed;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(225, 225, 225)" d="M9.1248 19.0999L0.699804 10.6999C0.599804 10.5999 0.529138 10.4916 0.487804 10.3749C0.445804 10.2582 0.424805 10.1332 0.424805 9.9999C0.424805 9.86657 0.445804 9.74157 0.487804 9.6249C0.529138 9.50824 0.599804 9.3999 0.699804 9.2999L9.1248 0.874902C9.35814 0.641569 9.6498 0.524902 9.9998 0.524902C10.3498 0.524902 10.6498 0.649902 10.8998 0.899902C11.1498 1.1499 11.2748 1.44157 11.2748 1.7749C11.2748 2.10824 11.1498 2.3999 10.8998 2.6499L3.5498 9.9999L10.8998 17.3499C11.1331 17.5832 11.2498 17.8706 11.2498 18.2119C11.2498 18.5539 11.1248 18.8499 10.8748 19.0999C10.6248 19.3499 10.3331 19.4749 9.9998 19.4749C9.66647 19.4749 9.3748 19.3499 9.1248 19.0999Z" />
  </svg>
  ');
`;

export const NextPage = styled(Link)`
  ${PaginationItem};
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 1px;
  width: 10px;
  height: 15px;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(164, 164, 164)" d="M2.1501 18.575L10.5751 10.175C10.6751 10.075 10.7458 9.96667 10.7871 9.85C10.8291 9.73333 10.8501 9.60833 10.8501 9.475C10.8501 9.34167 10.8291 9.21667 10.7871 9.1C10.7458 8.98333 10.6751 8.875 10.5751 8.775L2.1501 0.35C1.91676 0.116667 1.6251 0 1.2751 0C0.925098 0 0.625098 0.125 0.375098 0.375C0.125098 0.625 9.82285e-05 0.916667 9.82285e-05 1.25C9.82285e-05 1.58333 0.125098 1.875 0.375098 2.125L7.7251 9.475L0.375098 16.825C0.141765 17.0583 0.0250978 17.3457 0.0250978 17.687C0.0250978 18.029 0.150098 18.325 0.400098 18.575C0.650098 18.825 0.941765 18.95 1.2751 18.95C1.60843 18.95 1.9001 18.825 2.1501 18.575Z" />
  </svg>
  ');
`;

export const NextPageDisabled = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 1px;
  width: 10px;
  height: 15px;
  cursor: not-allowed;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(225, 225, 225)" d="M2.1501 18.575L10.5751 10.175C10.6751 10.075 10.7458 9.96667 10.7871 9.85C10.8291 9.73333 10.8501 9.60833 10.8501 9.475C10.8501 9.34167 10.8291 9.21667 10.7871 9.1C10.7458 8.98333 10.6751 8.875 10.5751 8.775L2.1501 0.35C1.91676 0.116667 1.6251 0 1.2751 0C0.925098 0 0.625098 0.125 0.375098 0.375C0.125098 0.625 9.82285e-05 0.916667 9.82285e-05 1.25C9.82285e-05 1.58333 0.125098 1.875 0.375098 2.125L7.7251 9.475L0.375098 16.825C0.141765 17.0583 0.0250978 17.3457 0.0250978 17.687C0.0250978 18.029 0.150098 18.325 0.400098 18.575C0.650098 18.825 0.941765 18.95 1.2751 18.95C1.60843 18.95 1.9001 18.825 2.1501 18.575Z" />
  </svg>
  ');
`;

export const ToFirstPage = styled(Link)`
  ${PaginationItem};
  margin-bottom: 1px;
  width: 13px;
  height: 13px;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(164, 164, 164)" d="M16.7 18.575L8.275 10.175C8.175 10.075 8.10433 9.96667 8.063 9.85C8.021 9.73333 8 9.60833 8 9.475C8 9.34167 8.021 9.21667 8.063 9.1C8.10433 8.98333 8.175 8.875 8.275 8.775L16.7 0.35C16.9333 0.116667 17.225 0 17.575 0C17.925 0 18.225 0.125 18.475 0.375C18.725 0.625 18.85 0.916667 18.85 1.25C18.85 1.58333 18.725 1.875 18.475 2.125L11.125 9.475L18.475 16.825C18.7083 17.0583 18.825 17.3457 18.825 17.687C18.825 18.029 18.7 18.325 18.45 18.575C18.2 18.825 17.9083 18.95 17.575 18.95C17.2417 18.95 16.95 18.825 16.7 18.575Z" />
  <path fill="rgb(164, 164, 164)" d="M8.7 18.575L0.275 10.175C0.175 10.075 0.104333 9.96667 0.0629997 9.85C0.0209997 9.73333 0 9.60833 0 9.475C0 9.34167 0.0209997 9.21667 0.0629997 9.1C0.104333 8.98333 0.175 8.875 0.275 8.775L8.7 0.35C8.93333 0.116667 9.225 0 9.575 0C9.925 0 10.225 0.125 10.475 0.375C10.725 0.625 10.85 0.916667 10.85 1.25C10.85 1.58333 10.725 1.875 10.475 2.125L3.125 9.475L10.475 16.825C10.7083 17.0583 10.825 17.3457 10.825 17.687C10.825 18.029 10.7 18.325 10.45 18.575C10.2 18.825 9.90833 18.95 9.575 18.95C9.24167 18.95 8.95 18.825 8.7 18.575Z" />
  </svg>
  ');
`;

export const ToFirstPageDisabled = styled.div`
  margin-bottom: 1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  cursor: not-allowed;
  transform: rotate(180deg);
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(225, 225, 225)" d="M2.1501 18.575L10.5751 10.175C10.6751 10.075 10.7458 9.96667 10.7871 9.85C10.8291 9.73333 10.8501 9.60833 10.8501 9.475C10.8501 9.34167 10.8291 9.21667 10.7871 9.1C10.7458 8.98333 10.6751 8.875 10.5751 8.775L2.1501 0.35C1.91676 0.116667 1.6251 0 1.2751 0C0.925098 0 0.625098 0.125 0.375098 0.375C0.125098 0.625 9.82285e-05 0.916667 9.82285e-05 1.25C9.82285e-05 1.58333 0.125098 1.875 0.375098 2.125L7.7251 9.475L0.375098 16.825C0.141765 17.0583 0.0250978 17.3457 0.0250978 17.687C0.0250978 18.029 0.150098 18.325 0.400098 18.575C0.650098 18.825 0.941765 18.95 1.2751 18.95C1.60843 18.95 1.9001 18.825 2.1501 18.575Z" />
  <path fill="rgb(225, 225, 225)" d="M10.1501 18.575L18.5751 10.175C18.6751 10.075 18.7458 9.96667 18.7871 9.85C18.8291 9.73333 18.8501 9.60833 18.8501 9.475C18.8501 9.34167 18.8291 9.21667 18.7871 9.1C18.7458 8.98333 18.6751 8.875 18.5751 8.775L10.1501 0.35C9.91676 0.116667 9.6251 0 9.2751 0C8.9251 0 8.6251 0.125 8.3751 0.375C8.1251 0.625 8.0001 0.916667 8.0001 1.25C8.0001 1.58333 8.1251 1.875 8.3751 2.125L15.7251 9.475L8.3751 16.825C8.14176 17.0583 8.0251 17.3457 8.0251 17.687C8.0251 18.029 8.1501 18.325 8.4001 18.575C8.6501 18.825 8.94176 18.95 9.2751 18.95C9.60843 18.95 9.9001 18.825 10.1501 18.575Z" />
  </svg>
  ');
`;

export const ToLastPage = styled(Link)`
  ${PaginationItem};
  margin-bottom: 1px;
  width: 13px;
  height: 13px;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(164, 164, 164)" d="M2.1501 18.575L10.5751 10.175C10.6751 10.075 10.7458 9.96667 10.7871 9.85C10.8291 9.73333 10.8501 9.60833 10.8501 9.475C10.8501 9.34167 10.8291 9.21667 10.7871 9.1C10.7458 8.98333 10.6751 8.875 10.5751 8.775L2.1501 0.35C1.91676 0.116667 1.6251 0 1.2751 0C0.925098 0 0.625098 0.125 0.375098 0.375C0.125098 0.625 9.82285e-05 0.916667 9.82285e-05 1.25C9.82285e-05 1.58333 0.125098 1.875 0.375098 2.125L7.7251 9.475L0.375098 16.825C0.141765 17.0583 0.0250978 17.3457 0.0250978 17.687C0.0250978 18.029 0.150098 18.325 0.400098 18.575C0.650098 18.825 0.941765 18.95 1.2751 18.95C1.60843 18.95 1.9001 18.825 2.1501 18.575Z" />
  <path fill="rgb(164, 164, 164)" d="M10.1501 18.575L18.5751 10.175C18.6751 10.075 18.7458 9.96667 18.7871 9.85C18.8291 9.73333 18.8501 9.60833 18.8501 9.475C18.8501 9.34167 18.8291 9.21667 18.7871 9.1C18.7458 8.98333 18.6751 8.875 18.5751 8.775L10.1501 0.35C9.91676 0.116667 9.6251 0 9.2751 0C8.9251 0 8.6251 0.125 8.3751 0.375C8.1251 0.625 8.0001 0.916667 8.0001 1.25C8.0001 1.58333 8.1251 1.875 8.3751 2.125L15.7251 9.475L8.3751 16.825C8.14176 17.0583 8.0251 17.3457 8.0251 17.687C8.0251 18.029 8.1501 18.325 8.4001 18.575C8.6501 18.825 8.94176 18.95 9.2751 18.95C9.60843 18.95 9.9001 18.825 10.1501 18.575Z" />
  </svg>
  ');
`;

export const ToLastPageDisabled = styled.div`
  margin-bottom: 1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  cursor: not-allowed;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(225, 225, 225)" d="M2.1501 18.575L10.5751 10.175C10.6751 10.075 10.7458 9.96667 10.7871 9.85C10.8291 9.73333 10.8501 9.60833 10.8501 9.475C10.8501 9.34167 10.8291 9.21667 10.7871 9.1C10.7458 8.98333 10.6751 8.875 10.5751 8.775L2.1501 0.35C1.91676 0.116667 1.6251 0 1.2751 0C0.925098 0 0.625098 0.125 0.375098 0.375C0.125098 0.625 9.82285e-05 0.916667 9.82285e-05 1.25C9.82285e-05 1.58333 0.125098 1.875 0.375098 2.125L7.7251 9.475L0.375098 16.825C0.141765 17.0583 0.0250978 17.3457 0.0250978 17.687C0.0250978 18.029 0.150098 18.325 0.400098 18.575C0.650098 18.825 0.941765 18.95 1.2751 18.95C1.60843 18.95 1.9001 18.825 2.1501 18.575Z" />
  <path fill="rgb(225, 225, 225)" d="M10.1501 18.575L18.5751 10.175C18.6751 10.075 18.7458 9.96667 18.7871 9.85C18.8291 9.73333 18.8501 9.60833 18.8501 9.475C18.8501 9.34167 18.8291 9.21667 18.7871 9.1C18.7458 8.98333 18.6751 8.875 18.5751 8.775L10.1501 0.35C9.91676 0.116667 9.6251 0 9.2751 0C8.9251 0 8.6251 0.125 8.3751 0.375C8.1251 0.625 8.0001 0.916667 8.0001 1.25C8.0001 1.58333 8.1251 1.875 8.3751 2.125L15.7251 9.475L8.3751 16.825C8.14176 17.0583 8.0251 17.3457 8.0251 17.687C8.0251 18.029 8.1501 18.325 8.4001 18.575C8.6501 18.825 8.94176 18.95 9.2751 18.95C9.60843 18.95 9.9001 18.825 10.1501 18.575Z" />
  </svg>
  ');
`;