import ApiRequest from 'src/shared/utils/ApiRequest';

export default class LimitsApi {
  static getLimits() {
    return ApiRequest('/api/bank_role/loan_limit/all');
  }

  static putOpenLoanLimitsSetActive(id: string) {
    return ApiRequest(`/api/bank_role/loan_limit/set_active/${id}`, 'put');
  }

  static putOpenLoanLimitsSetInactive(id: string) {
    return ApiRequest(`/api/bank_role/loan_limit/set_inactive/${id}`, 'put');
  }
}
