import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getContractBySupplier';

export interface GetContractBySupplierStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_CONTRACT_BY_SUPPLIER {
  REQ = 'GET_CONTRACT_BY_SUPPLIER_REQ',
  GOT = 'GET_CONTRACT_BY_SUPPLIER_GOT',
  ERR = 'GET_CONTRACT_BY_SUPPLIER_ERR'
}

export const initialState: GetContractBySupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getContractBySupplier = (
  state: GetContractBySupplierStoreState = initialState,
  action: {
    type: GET_CONTRACT_BY_SUPPLIER;
    data: ResponseData[];
    error: {};
  }
): GetContractBySupplierStoreState => {
  switch (action.type) {
    case GET_CONTRACT_BY_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTRACT_BY_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_CONTRACT_BY_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContractBySupplier;
