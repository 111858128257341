import * as React from 'react';
import {
  DocumentsInfoBlockLabel,
  DocumentsInfoBlockStyled,
  DataListBlock
} from './styles';

interface Data {
  title?: string;
  list?: string[];
}

interface Props {
  name?: string;
  label?: string;
  data: Data[];
  children?: any;
}

export const DocumentsInfoBlock: React.FC<Props> = ({
  name,
  label,
  data,
  children
}: Props) => (
  <React.Fragment>
    <DocumentsInfoBlockLabel name={name} label={label} />
    <DocumentsInfoBlockStyled>
      {data.map((item, key) => (
        <DataListBlock key={key}>
          {item.title &&
           <span>{item.title}</span>
          }
          {!!item.list && (
            <ul>
              {item.list.map((listItem, listItemKey) => (
                <li key={listItemKey}>{listItem}</li>
              ))}
            </ul>
          )}
        </DataListBlock>
      ))}
      {!!children && children}
    </DocumentsInfoBlockStyled>
  </React.Fragment>
);
