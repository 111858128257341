import { GET_CHECK_SIGNATURE_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/getCheckSignature';

export interface RequestData {
  documentFile: File;
  signatureFile: File;
}

export interface ResponseData {
  companyName: string;
  inn: string;
  lastName: string;
  name: string;
  pdfLink: string;
  personInn: string;
  position: string;
  result: boolean;
  signerCertificate: string;
}

export const req = (data: RequestData) => ({
  type: GET_CHECK_SIGNATURE_INTERNAL.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_CHECK_SIGNATURE_INTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CHECK_SIGNATURE_INTERNAL.ERR,
  error
});

export const reset = () => ({
  type: GET_CHECK_SIGNATURE_INTERNAL.RESET
});
