import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from 'src/shared/utils/History';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu
} from 'src/features/Layouts/components';
import { HeaderContainer } from 'src/features/Products/components/ProductView/styled';
import { req as getProduct } from 'src/features/ProductsForBank/actions/getProduct';
import {
  CABINET_PRODUCT_TABS,
  ProductsListBank as ProductData
} from 'src/features/ProductsForBank/types';

import { BasicCabinetParametersForProducts } from 'src/features/BasicParametersForProducts/components/BasicCabinetParametersForProducts/BasicCabinetParametersForProducts';
import { CustomerRequirementsForProduct } from 'src/features/CustomerRequirements/components/CustomerRequirements';
import { PledgeForProducts } from 'src/features/ProductPledge/components/ProductPledge';
import { ContractLogigForProduct } from 'src/features/ContractLogic/components/ContractLogic';

interface StateToProps {
  product: ProductData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
  roles: string[];
}

interface State {
  activeTab: string;
}

interface MatchParams {
  id: string;
  tab: string;
}

interface DispatchToProps {
  getProduct: (guid: number | string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ProductsForCabinetBankView extends React.Component<Props, State> {
  tab = this.props.match.params.tab;
  state = {
    activeTab: (this.tab && this.tab.toUpperCase()) || 'DEFAULT'
  };

  componentDidMount() {
    this.props.getProduct(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      } else {
        this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
      }
    }
    if (prevState.activeTab !== this.state.activeTab) {
      const { tab } = this.props.match.params;
      const baseUrl = history.location.pathname.replace(
        `/${tab && tab.toLowerCase()}`,
        ''
      );
      if (this.state.activeTab !== 'DEFAULT') {
        const url = baseUrl + `/${this.state.activeTab.toLowerCase()}`;
        history.push(url);
      } else {
        history.push(baseUrl);
      }
    }
  }

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  render() {
    const { product, status, error, roles, permissions } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <HeaderContainer>
              <div>
                <ApplicationId>Продукт #{product.guid}</ApplicationId>
                <ApplicationTitle>
                  {product.productNameForBank}
                </ApplicationTitle>
              </div>
            </HeaderContainer>

            <ApplicationId>Общая информация</ApplicationId>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Тип финансирования</span>
                  <span>{product.financeTypeName}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Минимальная сумма</span>
                  <span>
                    {product.minSize === null
                      ? 'Нет данных'
                      : formSumStringThousands(product.minSize)}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Максимальная сумма</span>
                  <span>
                    {product.maxSize === 0
                      ? 'Без ограничений'
                      : product.maxSize === null
                        ? 'Нет данных'
                        : formSumStringThousands(product.maxSize)}
                  </span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <Menu
              activeTab={this.state.activeTab}
              handleActiveTab={this.handleActiveTab}
              tabs={CABINET_PRODUCT_TABS}
            />

            {this.state.activeTab === 'DEFAULT' && (
              <BasicCabinetParametersForProducts
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'CLIENT' && (
              <CustomerRequirementsForProduct
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'PLEDGE' && (
              <PledgeForProducts
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'CONTRACT' && (
              <ContractLogigForProduct
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ ProductsBank, User }: CRM) => ({
  product: ProductsBank.getProductBank.data,
  status: ProductsBank.getProductBank.status,
  error: ProductsBank.getProductBank.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProduct }, dispatch);

const ProductsForCabinetBankViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ProductsForCabinetBankView)
);

export { ProductsForCabinetBankViewConnect as ProductsForCabinetBankView };
