import { ExternalCredentialsData } from '../actions/setExternalCredentialFormData';
//import { RequestData as GetExternalCredentialsListRequestData } from '../actions/getExternalCredentialsList'; TODO = make in future when needed
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ExternalCredentialsApi {
  static sendExternalCredentialData(data: ExternalCredentialsData) {
    return ApiRequest('/api/external_credential', 'post', data);
  }

  static getExternalCredential(id: number) {
    return ApiRequest(`/api/external_credential/id/${id}`);
  }

  //static getAllExternalCredentials(data: GetExternalCredentialsListRequestData) {
  //  return ApiRequest('/api/external_credential/all', 'get', data);
  //}
}
