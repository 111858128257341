import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { FormContainer } from 'src/features/Products/components/NewProduct/styled';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as createAgent,
  reset as resetAgentState
} from 'src/features/Agents/actions/sendAgentData';
import { ErrorMsg } from 'src/features/Contacts/components/CreateNewContact/styled';
import {
  PopupText,
  PopupTitle
} from 'src/features/Contacts/components/ContactsList/styled';
import {
  PopupContainerAgent,
  PopupContentAgent
} from 'src/features/Agents/components/NewAgent/styled';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {
  companyByInn: ResponseDataType;
  statusInn: REQUEST_STATUSES;
  createStatus: REQUEST_STATUSES;
  createError: ResponseError;
}

interface State {
  inn: string;
  shortname: string;
  isInfoPopup: boolean;
  errors: {
    inn: string;
    shortname: string;
  };
}

interface MatchParams {}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  createAgent: (data: any) => void;
  resetAgentState: () => void;
  resetStateCompanyByInn: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class NewAgent extends React.Component<Props, State> {
  state: State = {
    inn: '',
    shortname: '',
    isInfoPopup: false,
    errors: {
      inn: '',
      shortname: ''
    }
  };

  agentModalRef = React.createRef();

  componentDidMount() {
    if (history.location.state && history.location.state.inn) {
      this.setState({ inn: history.location.state.inn });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetAgentState();
      history.goBack();
    }
    if (prevState.inn !== this.state.inn) {
      this.setState({ isInfoPopup: false });
    }
    if (prevState.inn !== this.state.inn && this.state.inn.length >= 10) {
      this.props.getCompanyByInn({ inn: this.state.inn });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopup !== this.state.isInfoPopup
    ) {
      this.setState({ shortname: this.props.companyByInn.companyShortName });
    }
  }

  handleFieldNewProduct = (name: keyof State, value: string) => {
    this.setState({ [name]: value } as any);
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  handleCreateProduct = event => {
    event.preventDefault();

    const innRegex = /^[a-zA-Z0-9]+$/;

    const errors: State['errors'] = {
      inn: '',
      shortname: ''
    };

    if (this.state.inn !== null && !innRegex.test(this.state.inn)) {
      errors.inn = 'Введите корректный ИНН';
    }

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, isInfoPopup, ...data } = this.state;
      this.props.createAgent(data);
    }
  };

  handleOutsideClickAgent = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.agentModalRef.current &&
      !(this.agentModalRef.current as Element).contains(target)
    ) {
      this.props.resetAgentState();
    }
  };

  render() {
    const { companyByInn, statusInn, createStatus } = this.props;
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание агента</ApplicationTitle>

        {createStatus === REQUEST_STATUSES.ERROR && (
          <PopupContainerAgent onClick={this.handleOutsideClickAgent}>
            <PopupContentAgent ref={this.agentModalRef}>
              <PopupTitle>Ошибка: {this.props.createError.code}</PopupTitle>
              {this.props.createError.code === 409 && (
                <PopupText>Такой агент уже существует</PopupText>
              )}
            </PopupContentAgent>
          </PopupContainerAgent>
        )}

        <FormContainer onSubmit={this.handleCreateProduct}>
          <Input
            label="ИНН:"
            name="inn"
            value={this.state.inn}
            onChange={e =>
              this.handleFieldNewProduct('inn', e.currentTarget.value)
            }
          />

          {this.state.inn && this.state.inn.length < 10 ? null : statusInn ===
          REQUEST_STATUSES.ERROR ? (
            <InnPopup>
              <h2>
                Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                создать связку с введенным ИНН
              </h2>
            </InnPopup>
          ) : (
            statusInn === REQUEST_STATUSES.GOT &&
            !this.state.isInfoPopup && (
              <InnPopup
                companyInfo={companyByInn}
                onPopupClick={this.onPopupClick}
              />
            )
          )}

          {this.state.errors.inn && (
            <ErrorMsg>{this.state.errors.inn}</ErrorMsg>
          )}

          <Input
            label="Наименование:"
            name="shortname"
            value={this.state.shortname}
            onChange={e =>
              this.handleFieldNewProduct('shortname', e.currentTarget.value)
            }
          />

          {this.state.errors.shortname && (
            <ErrorMsg>{this.state.errors.shortname}</ErrorMsg>
          )}

          <Button label="Создать" type={'submit'} w="fit-content" h='20px' />
        </FormContainer>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ SCF, Agents }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  createStatus: Agents.sendAgentData.status,
  createError: Agents.sendAgentData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCompanyByInn, createAgent, resetAgentState, resetStateCompanyByInn },
    dispatch
  );

const NewAgentConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewAgent)
);

export { NewAgentConnect as NewAgent };
