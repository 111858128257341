export interface CachedStoreState {
  authorized: boolean;
}

export enum CACHED_ACTIONS {
  SESSION_SET = 'SESSION_SET',
  SESSION_CLEAR = 'SESSION_CLEAR'
}

export const initialState: CachedStoreState = {
  authorized: false
};

const CachedReducer = (
  state: CachedStoreState = initialState,
  action: {
    type: string;
  }
): CachedStoreState => {
  switch (action.type) {
    case CACHED_ACTIONS.SESSION_SET:
      return {
        authorized: true
      };

    case CACHED_ACTIONS.SESSION_CLEAR:
      return {
        authorized: false
      };

    default:
      return state;
  }
};

export default CachedReducer;
