import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ResponseData as PledgesData } from 'src/features/Clients/actions/getPledges';
import { TRWrapper } from 'src/features/BankProducts/components/Tabs/CreditProducts/styled';

interface StateToProps {
  pledges: PledgesData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {}

interface DispatchToProps {}

interface MatchParams {}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class Pledges extends React.Component<Props, State> {
  navigateToBankProduct = (clientInn: string) => {
    window.open(`/crm/companies/${clientInn}/bank_products/pledges`, '_blank');
  };

  render() {
    const { pledges, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="12%">ИНН клиента</TableThStyled>
                  <TableThStyled width="12%">Наименование</TableThStyled>
                  <TableThStyled width="12%">
                    Дата самого раннего залога
                  </TableThStyled>
                  <TableThStyled width="40%">
                    Дата последнего договора залога
                  </TableThStyled>
                  <TableThStyled width="12%">Источник данных</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {pledges.items.map((product, index) => (
                  <TRWrapper
                    key={index}
                    isActive={product.isActive}
                    onClick={() =>
                      this.navigateToBankProduct(product.clientInn)
                    }
                  >
                    <td>{product.clientInn}</td>
                    <td>{product.clientName}</td>
                    <td>
                      {product.firstPledgeContractDate
                        ? new Date(
                            product.firstPledgeContractDate
                          ).toLocaleDateString()
                        : null}
                    </td>
                    <td>
                      {product.lastPledgeContractDate
                        ? new Date(
                            product.lastPledgeContractDate
                          ).toLocaleDateString()
                        : null}
                    </td>
                    <td>{product.source}</td>
                  </TRWrapper>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={pledges} />
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const PledgesConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Pledges)
);

export { PledgesConnect as Pledges };
