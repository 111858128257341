import { CompaniesData } from '../actions/setCompanyFormData';
import { RequestData as GetCompaniesListRequestData } from '../actions/getCompaniesList';
import ApiRequest from 'src/shared/utils/ApiRequest';
import { Role } from 'src/features/Companies/types/CompanyApiType';

export default class CompaniesApi {
  static sendCompanyData(data: CompaniesData) {
    return ApiRequest('/api/companies', 'post', data);
  }

  static getCompany(inn: string) {
    return ApiRequest(`/api/company/inn/${inn}`);
  }

  static getMyCompanies(data: GetCompaniesListRequestData) {
    return ApiRequest('/api/company/all', 'get', data);
  }

  static getAllCompanies(data: GetCompaniesListRequestData) {
    return ApiRequest('/api/company/all', 'get', data);
  }

  static getTargetCompanies(data: GetCompaniesListRequestData) {
    return ApiRequest('/api/company/targets', 'get', data);
  }

  static getBackboneCompanies(data: GetCompaniesListRequestData) {
    return ApiRequest('/api/company/backbone', 'get', data);
  }

  static getCompaniesByHoldingList(holdingId: number) {
    return ApiRequest(`/api/company/holding/${holdingId}`);
  }

  static getAllCompaniesWithFilter(data) {
    return ApiRequest('/api/company/all', 'get', data);
  }

  static putLockAndUnlockCompany(
    inn: string,
    role: Role,
    locked: boolean,
    managerUserId?: number,
    analystUserId?: number
  ) {
    return ApiRequest(
      `/api/company/inn/${inn}/${locked ? 'lock' : 'unlock'}/${role}`,
      'put',
      { managerUserId, analystUserId }
    );
  }

  static getMainCounter(inn: string) {
    return ApiRequest(`/api/company/inn/${inn}/main_counters_list`);
  }

  static getListInternalAgents() {
    return ApiRequest('/api/internalAgentsManagers/listInternalAgents');
  }
}
