import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/ExternalAgent/model/actions/postCreateTenderExternal';

import { POST_CREATE_TENDER_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postCreateTenderExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* postCreateTenderExternal(action: {
  type: POST_CREATE_TENDER_EXTERNAL;
  data: RequestData;
}): Generator {
  try {
    yield call(ExternalAgentRoleApi.postCreateTenderExternal, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateTenderExternalSaga(): Generator {
  yield takeLatest(POST_CREATE_TENDER_EXTERNAL.REQ, postCreateTenderExternal);
}
