import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getPurchaseContracts';
import { GET_PURCHASE_CONTRACTS } from '../reducers/getPurchaseContracts';
import SCFApi from '../api';

function* getPurchaseContracts(action: {
  type: GET_PURCHASE_CONTRACTS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getPurchaseContracts, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPurchaseContractsSaga() {
  yield takeLatest(
    GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_REQ,
    getPurchaseContracts
  );
}
