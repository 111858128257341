import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STORE } from 'src/globaltypes';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import {
  CONTRACT_SUBJECT_TYPE,
  ContractFinancingRead
} from 'src/features/Application/types';
import {
  BorrowerCompanyDefaultFileUploadBlock,
  FILE_UPLOAD_BLOCK_TYPE
} from '../Borrower/BorrowerCompanyFileUploadBlock';
import { BorrowerCompanies as BorrowerCompaniesStep } from '../Borrower/BorrowerCompanies/BorrowerCompanies';
import { UpTo500ThousandsBorrowerCompanyFileUploadBlock } from '../Borrower/BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/UpTo500ThousandsBorrowerCompanyFileUploadBlock';
import { From500To30MilFileUploadBlock } from '../Borrower/BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/From500To30MilFileUploadBlock';
import { From30MilTo100MilFileUploadBlock } from '../Borrower/BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/From30MilTo100MilFileUploadBlock';

interface StateToProps extends Partial<ContractFinancingRead> {}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps;

const ContractFinancingFormStep3: React.FC<Props> = ({
  amount,
  contractSubject,
  history,
  match,
  location
}) => {
  const navigateFromTo = (from: string, to: string) =>
    match.url.replace(from, to);

  const goBack = () => {
    history.push({
      pathname: navigateFromTo(location.pathname, 'contract'),
      state: TRANSITION_DIRECTIONS.BACKWARDS
    });
  };

  const getFileUploadBlockAndType = () => {
    if (amount <= 500000)
      return {
        block: UpTo500ThousandsBorrowerCompanyFileUploadBlock,
        type: FILE_UPLOAD_BLOCK_TYPE.CONTRACT_UP_TO_500_THOUSANDS
      };
    if (
      (amount <= 30000000 &&
        contractSubject === CONTRACT_SUBJECT_TYPE.SUPPLY) ||
      (amount <= 20000000 && contractSubject === CONTRACT_SUBJECT_TYPE.SERVICES)
    )
      return {
        block: From500To30MilFileUploadBlock,
        type: FILE_UPLOAD_BLOCK_TYPE.CONTRACT_FROM_500THOUSANDS_TO_30MIL
      };
    if (amount <= 100000000)
      return {
        block: From30MilTo100MilFileUploadBlock,
        type: FILE_UPLOAD_BLOCK_TYPE.CONTRACT_FROM_30MIL_TO_100MIL
      };

    return {
      block: BorrowerCompanyDefaultFileUploadBlock,
      type: FILE_UPLOAD_BLOCK_TYPE.DEFAULT
    };
  };

  const blockAndType = getFileUploadBlockAndType();

  return (
    <BorrowerCompaniesStep
      borrowerCompanyFileUploadBlock={blockAndType.block}
      borrowerCompanyFileUploadBlockType={blockAndType.type}
      disableApplicationDocumentsUpload={amount <= 100000000}
      goBack={goBack}
    />
  );
};

const mapStateToProps = ({ ExternalAgentRole }: STORE) => ({
  ...ExternalAgentRole.getApplicationExternal.data
});

const ContractFinancingFormStep3Connect = withRouter(
  connect<StateToProps>(mapStateToProps)(ContractFinancingFormStep3)
);

export { ContractFinancingFormStep3Connect as ContractFinancingFormStep3 };
