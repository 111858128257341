import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { ClientsList } from 'src/features/Clients/components/ClientsList/ClientsList';
import { UserListForExternalAgent } from 'src/features/Users/components/UserListForExternalAgent/UserListForExternalAgent';
import { showQuantity } from 'pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { CabinetComponent } from '../EachPage';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

function createClientsListCabinetComponent({
  path,
  listType,
  icon,
  label,
  countName,
  Component
}) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        labelInfo={showQuantity(countName)}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const ClientsSection: CabinetComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.EXTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Клиенты</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.EXTERNAL_AGENT].isIn(permissions),
    ...createClientsListCabinetComponent({
      Component: ClientsList,
      path: '/cabinet/partner/clients/all',
      label: 'Мои клиенты',
      icon: <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
      listType: null,
      countName: 'myClientsQty'
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.EXTERNAL_AGENT].isIn(permissions),
    ...createClientsListCabinetComponent({
      Component: UserListForExternalAgent,
      path: '/cabinet/partner/users/all',
      label: 'Пользователи',
      icon: <FontAwesomeIcon icon={faUserCircle} fixedWidth={true} />,
      listType: null,
      countName: 'myUsersQty'
    })
  }
];
