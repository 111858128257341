import styled from 'styled-components';
import { rr } from 'shared/styled';

export const ExternalAgentsListStyled = styled.div`
  margin-top: 100px;
`;

export const ExternalAgentStatusStyled = styled.span`
  padding: 8px 20px;
  border-radius: 6px;
  font-family: ${rr};
  font-size: 12px;
  text-align: center;

  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'white'};
  color: ${({ color }) => (color ? color : 'black')};
`;

export const ConfirmButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;

  button {
    padding: 0 14px;
  }
`;
