import getUsersListSaga from './getUsersList';
import getUsersListForBankSaga from './getUserListForBankPage';
import getUserSaga from './getUser';
import postAllowProcessSaga from './postAllowProcess';
import postAllowFieldsSaga from './postAllowFields';
import getUserLeadsSaga from './getUserLeads';
import getUserApplicationsSaga from './getUserApplications';
import getAllUsersForExternalAgentSaga from './getAllUsersForExternalAgent';

export default [
  getUsersListSaga,
  getUsersListForBankSaga,
  getUserSaga,
  postAllowProcessSaga,
  postAllowFieldsSaga,
  getUserLeadsSaga,
  getUserApplicationsSaga,
  getAllUsersForExternalAgentSaga
];
