import * as React from 'react';
import { EconomyEffectContainer, EconomyEffectBlock, EconomyEffectRow, EconomyEffectDescAndValue, EconomyEffectText, EconomyEffectTextLarge, EconomyEffectTextMiddle, EconomyEffectTitle } from './styles';

type EconomyEffectProps = {
  title: string,
  keyRate: string,
  keyRateIncome: string,
  platformIncome: string,
  summToGet: string,
  earlyPaymentDiscont: string,
}

export const EconomyEffect = ({ title, keyRate, keyRateIncome, platformIncome, summToGet, earlyPaymentDiscont }: EconomyEffectProps) =>
<EconomyEffectContainer>
  <EconomyEffectTitle>{ title }</EconomyEffectTitle>
  <EconomyEffectBlock>
    <EconomyEffectRow>
      <EconomyEffectDescAndValue>
          <EconomyEffectTextMiddle>Размещено средств:</EconomyEffectTextMiddle>
          <EconomyEffectTextMiddle>{summToGet}</EconomyEffectTextMiddle>
      </EconomyEffectDescAndValue>
      <EconomyEffectTextMiddle>руб.</EconomyEffectTextMiddle>
    </EconomyEffectRow>
    <EconomyEffectRow>
      <EconomyEffectDescAndValue>
          <EconomyEffectText>Ключевая ставка:</EconomyEffectText>
          <EconomyEffectText>{keyRate}</EconomyEffectText>
      </EconomyEffectDescAndValue>
      <EconomyEffectText>% год.</EconomyEffectText>
    </EconomyEffectRow>
    <EconomyEffectRow>
      <EconomyEffectDescAndValue>
          <EconomyEffectText>Доходность депозита:</EconomyEffectText>
          <EconomyEffectText>{keyRateIncome}</EconomyEffectText>
      </EconomyEffectDescAndValue>
      <EconomyEffectText>руб.</EconomyEffectText>
    </EconomyEffectRow>
  </EconomyEffectBlock>
  <EconomyEffectBlock>
    <EconomyEffectRow>
        <EconomyEffectDescAndValue>
            <EconomyEffectTextLarge>Доход по дисконту:</EconomyEffectTextLarge>
            <EconomyEffectTextLarge>{earlyPaymentDiscont}</EconomyEffectTextLarge>
        </EconomyEffectDescAndValue>
        <EconomyEffectTextLarge>руб.</EconomyEffectTextLarge>
      </EconomyEffectRow>
      <EconomyEffectRow>
        <EconomyEffectDescAndValue>
            <EconomyEffectText>Комиссия платформы:</EconomyEffectText>
            <EconomyEffectText>{platformIncome}</EconomyEffectText>
        </EconomyEffectDescAndValue>
        <EconomyEffectText>руб.</EconomyEffectText>
      </EconomyEffectRow>
    </EconomyEffectBlock>
</EconomyEffectContainer>
