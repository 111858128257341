import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getActivities';

import { GET_ACTIVITIES_COMPANIES } from '../reducers/getActivities';
import ActivitiesOfCompaniesApi from '../api';

function* getActivitiesOfCompanies(action: {
  type: GET_ACTIVITIES_COMPANIES;
  companyInn: string;
}): Generator {
  try {
    const res: any = yield call(
      ActivitiesOfCompaniesApi.getActivities,
      action.companyInn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadsSaga(): Generator {
  yield takeLatest(GET_ACTIVITIES_COMPANIES.REQ, getActivitiesOfCompanies);
}
