import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestType } from '../actions/postCreateBuyersToSupplierLink';
import { RequestData } from '../actions/getBuyersToSupplierLinkList';

export default class BuyersToSupplierLinkApi {
  static postCreateBuyersToSupplierLink(data: RequestType) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/internal_role/add_buyers_to_supplier`,
      'post',
      data
    );
  }

  static getBuyersToSupplierLinkList(data: RequestData) {
    return ApiRequest(`/api/buyers_to_suppliers_link/all`, 'get', data);
  }
}
