import * as React from 'react';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ResponseData } from 'src/features/Tenders/actions/getTenders';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';

interface Props {
  tenders: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export const TendersList: React.FC<Props> = ({ tenders, status, error }) => {
  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="6%">Заказчик</TableThStyled>
                <TableThStyled width="12%">Поставщик</TableThStyled>
                <TableThStyled width="9%">Номер ЕРУЗ</TableThStyled>
                <TableThStyled width="9%">Категория</TableThStyled>
                <TableThStyled width="9%">НМЦ</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {tenders.items.map(tender => (
                <TableRowStyled key={tender.id}>
                  <td>
                    <div>{tender.customerName}</div>
                    <div>{tender.customerINN}</div>
                  </td>
                  <td>
                    <div>{tender.supplierName}</div>
                    <div>{tender.supplierINN}</div>
                  </td>
                  <td>{tender.eruzId}</td>
                  <td>
                    {tender.tenderSegment === 1
                      ? '44-ФЗ'
                      : tender.tenderSegment === 2
                        ? '223-ФЗ'
                        : tender.tenderSegment === 3
                          ? '615n'
                          : tender.tenderSegment === 4
                            ? 'ГОЗ (ГосОборонЗаказ)'
                            : tender.tenderSegment === 5
                              ? 'Коммерческая'
                              : 'Неизв.'}
                  </td>
                  <td>
                    {tender.startPrice
                      ? formSumStringThousands(tender.startPrice)
                      : 'Неизв.'}
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={tenders} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code === 404 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.NOT_FOUND}
      />
    </>
  );
};
