import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getSupplierNotificationSettings';

export interface GetSupplierNotificationSettingsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum SUPPLIER_NOTIFICATION_SETTINGS {
  REQ = 'SUPPLIER_NOTIFICATION_SETTINGS_REQ',
  GOT = 'SUPPLIER_NOTIFICATION_SETTINGS_GOT',
  ERR = 'SUPPLIER_NOTIFICATION_SETTINGS_ERR',
}

const initialState: GetSupplierNotificationSettingsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    newBuyerToSupplierLinkEvent: false,
    newSupplyContractLinkEvent: false,
    newMonetaryClaimsEvent: false,
    earlyPaymentApplicationStatusEvent: false,
  },
  error: {}
};

const getSupplierNotificationSettings = (
  state: GetSupplierNotificationSettingsStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetSupplierNotificationSettingsStoreState => {
  switch (action.type) {
    case SUPPLIER_NOTIFICATION_SETTINGS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case SUPPLIER_NOTIFICATION_SETTINGS.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case SUPPLIER_NOTIFICATION_SETTINGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getSupplierNotificationSettings;
