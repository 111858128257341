import { history } from 'src/shared/utils/History';

export const onRowClick = (guid: string) => {
  if(window.location.pathname.includes('factor')) {
    history.push(`/factor/settings/certificates/${guid}`)
  }
  if(window.location.pathname.includes('debtor')) {
    history.push(`/debtor/settings/certificates/${guid}`)
  }
  if(window.location.pathname.includes('supplier')) {
    history.push(`/supplier/settings/certificates/${guid}`)
    console.log(guid);
  }
}

export const onAddDataClick = (e: Event, guid: string) => {
  e.stopPropagation();
  if(window.location.pathname.includes('debtor')) {
    history.push(`/debtor/settings/certificates/${guid}/edit`)
  }
  if(window.location.pathname.includes('factor')) {
    history.push(`/factor/settings/certificates/${guid}/edit`)
  }
  if(window.location.pathname.includes('supplier')) {
    history.push(`/supplier/settings/certificates/${guid}/edit`)
  }
}
