import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import { convertKbToMb } from 'src/shared/utils/Utils';
import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  DOCUMENT_UPLOAD_TYPE,
  BorrowerCompany,
  APPLICATION_TYPES,
  BORROWER_COMPANY_ROLE,
  APPLICATION_TYPE_ROUTES,
  ContractFinancingRead,
  Document
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  setInitializationFormData,
  resetInitializationFormData
} from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';

import { req as getBorrowerCompaniesList } from 'BorrowerCompanies/actions/getBorrowerCompaniesList';
import { req as saveBorrowerCompaniesList } from 'BorrowerCompanies/actions/saveBorrowerCompaniesList';
import { req as createBorrowerCompany } from 'BorrowerCompanies/actions/createBorrowerCompany';
import { req as deleteBorrowerCompany } from 'BorrowerCompanies/actions/deleteBorrowerCompany';
import { reset as resetInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';

import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import {
  RequestDataType as DeleteDocumentRequestDataType,
  SagaRequestDataType as DeleteDocumentSagaRequestDataType
} from 'Application/reducers/deleteDocument';
import { RequestDataType as GetBorrowerCompaniesListRequestDataType } from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';
import { RequestDataType as SaveBorrowerCompaniesListDataType } from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';
import { RequestDataType as CreateBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/createBorrowerCompany';
import { RequestDataType as DeleteBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

import { TRANSITION_DIRECTIONS } from 'Common';
import { DocumentsInfoBlock } from 'Application/components';
import { InputFile } from 'shared/ui/InputFile';

import { UploadFileIcon } from 'shared/styled';
import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationBtnsWrapper
} from 'Application/components/ApplicationForm/ApplicationProcess/styles';
import {
  ExampleDocumentBlock,
  ExampleDocumentInfo,
  DownloadButton,
  UploadTypeButton,
  UploadTypeButtonSlim
} from './styles';

import { createValidaton } from './validator';
import { FILE_UPLOAD_BLOCK_TYPE } from '../BorrowerCompanyFileUploadBlock';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import { setFileUploadBlock } from 'Application/actions/setFileUploadBlock';
import { SetFileUploadBlockType } from 'src/features/Application/reducers/setFileUploadBlockType';
import { BorrowerCompaniesTabs } from '../BorrowerCompaniesTabs/BorrowerCompaniesTabs';
import groupSchemeDocument_1 from 'assets/documents/Схема_группы_связанных_заемщиков.pptx';
import groupSchemeDocument_2 from 'assets/documents/Схема_товарно-денежных_потоков.pptx';
import { Button } from 'src/shared/ui/Button';

interface OwnProps {
  goBack: () => void;
  borrowerCompanyFileUploadBlock?: any; // TODO create interface for this
  borrowerCompanyFileUploadBlockType?: FILE_UPLOAD_BLOCK_TYPE;
  disableApplicationDocumentsUpload?: boolean;
}

interface StateToProps {
  clientApplication: Partial<ContractFinancingRead>;
  companies: BorrowerCompany[];
  groupDocuments: Document[];
  uploadStatus: REQUEST_STATUSES;
  borrowerCompaniesUploadStatus: REQUEST_STATUSES;
  borrowerCompaniesDeleteStatus: REQUEST_STATUSES;
  allowFlags: GetApplicationFlagsResponseDataType;
  createStatus: REQUEST_STATUSES;
  deleteStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
  setFileUploadBlock: (data: SetFileUploadBlockType) => void;
  getBorrowerCompaniesList: (
    data: GetBorrowerCompaniesListRequestDataType
  ) => void;
  saveBorrowerCompaniesList: (data: SaveBorrowerCompaniesListDataType) => void;
  createBorrowerCompany: (data: CreateBorrowerCompanyDataType) => void;
  deleteBorrowerCompany: (data: DeleteBorrowerCompanyDataType) => void;
  resetInitializationFormData: () => void;
  resetInternalApplication: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  OwnProps &
  StateToProps &
  DispatchToProps;

const BorrowerCompanies: React.FC<Props> = ({
  clientApplication,
  uploadStatus,
  getBorrowerCompaniesList,
  companies,
  groupDocuments,
  borrowerCompanyFileUploadBlockType,
  borrowerCompanyFileUploadBlock,
  borrowerCompaniesUploadStatus,
  borrowerCompaniesDeleteStatus,
  saveBorrowerCompaniesList,
  disableApplicationDocumentsUpload,
  goBack,
  setFileUploadBlock,
  resetInitializationFormData,
  allowFlags,
  setInitializationFormData,
  uploadDocuments,
  deleteDocument,
  createStatus,
  deleteStatus,
  openModal,
  resetInternalApplication,
  history,
  match
}) => {
  const [dynamicValidatiorFields, setDynamicValidatiorFields] = React.useState(
    {}
  );

  let Validator = createValidaton([]);

  React.useEffect(
    () => {
      getBorrowerCompaniesList({ applicationId: +match.params.id });
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      formDynamicValidatiorFields(companies);
    },
    [match.params.id, companies]
  );

  React.useEffect(
    () => {
      if (
        borrowerCompaniesUploadStatus === REQUEST_STATUSES.GOT ||
        borrowerCompaniesDeleteStatus === REQUEST_STATUSES.GOT ||
        createStatus === REQUEST_STATUSES.GOT ||
        deleteStatus === REQUEST_STATUSES.GOT
      ) {
        getBorrowerCompaniesList({ applicationId: +match.params.id });
      }
    },
    [
      borrowerCompaniesUploadStatus,
      borrowerCompaniesDeleteStatus,
      createStatus,
      deleteStatus
    ]
  );

  React.useEffect(
    () => {
      formDynamicValidatiorFields(companies);
      Validator.showAllErrors();

      const updatedBorrowerCompanies = companies.map((company, index) => {
        if (index === 0) {
          return {
            ...company,
            roles: [...company.roles, BORROWER_COMPANY_ROLE.BORROWER]
          };
        } else {
          return { ...company };
        }
      });

      setInitializationFormData({
        borrowerCompanies: updatedBorrowerCompanies
      });
    },
    [companies, borrowerCompaniesUploadStatus]
  );

  const slimClick = () => {
    const btnSlim = document.getElementById('slimUploadBtn');
    const btnDefault = document.getElementById('defaultUploadBtn');
    btnSlim.style.background = '#F1F8FF';
    btnSlim.style.color = '#3F4E65';
    btnDefault.style.background = 'none';
    btnDefault.style.color = '#9FA6B2';
  };

  const defaultClick = () => {
    const btnSlim = document.getElementById('slimUploadBtn');
    const btnDefault = document.getElementById('defaultUploadBtn');
    btnSlim.style.background = 'none';
    btnDefault.style.background = '#F1F8FF';
    btnDefault.style.color = '#3F4E65';
    btnSlim.style.color = '#9FA6B2';
  };

  const formDynamicValidatiorFields = (companies: BorrowerCompany[]) => {
    const dynamicValidatiorFields = companies.reduce((fieldsObj, company) => {
      fieldsObj[`name-${company.id}`] = company.name;
      fieldsObj[`individualNumber-${company.id}`] = company.individualNumber;
      fieldsObj[`documents-${company.id}`] = company.documents;
      fieldsObj[`roles-${company.id}`] = company.roles;
      return fieldsObj;
    }, {});

    setDynamicValidatiorFields(dynamicValidatiorFields);

    Validator = createValidaton(Object.keys(dynamicValidatiorFields));
  };

  const onGroupFilesUpload = files => {
    uploadDocuments({
      id: +match.params.id,
      files,
      type: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });

    formDynamicValidatiorFields(companies);
  };

  const onGroupFilesRemove = (data: DeleteDocumentRequestDataType) => {
    deleteDocument({
      ...data,
      subType: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });

    formDynamicValidatiorFields(companies);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const [fieldName, companyId] = name.split('-');

    const checkRoles = ({ roles }, value) =>
      roles.includes(value)
        ? roles.filter(item => item !== value)
        : [...roles, value];

    const newCompanies = companies.map(item => {
      if (item.id === parseInt(companyId, 10)) {
        item[fieldName] =
          fieldName === 'roles' ? checkRoles(item, value) : value;
      }
      return item;
    });

    setInitializationFormData({
      borrowerCompanies: newCompanies
    });

    formDynamicValidatiorFields(newCompanies);
  };

  const onSubmit = () => {
    const { id } = match.params;
    const { financingType } = clientApplication;

    Validator.showAllErrors();

    if (allowFlags.allowBlankFields === false && !Validator.isFormValid())
      return;

    companies.length !== 0 &&
      saveBorrowerCompaniesList({ borrowerCompanies: companies });

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    resetInitializationFormData();
    resetInternalApplication();

    if (!!id) {
      history.push({
        pathname: `/cabinet/${role}/application-${APPLICATION_TYPE_ROUTES[
          financingType
        ].replace('_', '-')}/${id}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    }
  };

  const exampleDocuments = [
    {
      name: 'Схема группы.pptx',
      size: 46,
      link: groupSchemeDocument_1
    },
    {
      name: 'Схема товарно-денежных потоков.pptx',
      size: 48,
      link: groupSchemeDocument_2
    }
  ];

  const { errors } = Validator.insertArgs({
    disableApplicationDocumentsUpload: [disableApplicationDocumentsUpload],
    borrowerCompanyFileUploadBlockType: [borrowerCompanyFileUploadBlockType]
  }).validate({
    groupDocuments: groupDocuments,
    ...dynamicValidatiorFields
  });

  const {
    groupDocuments: groupDocumentsError,
    ...dynamicValidatiorFieldErrors
  } = errors;
  const dynamicValidatiorFieldErrorsObject = Object.keys(
    dynamicValidatiorFieldErrors
  ).reduce((errorsObj, field) => {
    const [fieldName, companyId] = field.split('-');

    errorsObj[companyId] = {
      name: dynamicValidatiorFieldErrors[`name-${companyId}`],
      individualNumber:
        dynamicValidatiorFieldErrors[`individualNumber-${companyId}`],
      documents: dynamicValidatiorFieldErrors[`documents-${companyId}`],
      roles: dynamicValidatiorFieldErrors[`roles-${companyId}`]
    };

    const isValid =
      Object.values(errorsObj[companyId]).filter(error => !!error).length === 0;
    errorsObj[companyId]['isValid'] = isValid;

    return errorsObj;
  }, {});

  return (
    <ApplicationFormStyled>
      <CloseApplicationBtn
        onClick={() =>
          openModal({
            name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS
          })
        }
      />

      <ApplicationStepTitle>
        Компании
        {clientApplication.financingType === APPLICATION_TYPES.GUARANTEES
          ? ' Принципала'
          : ' заемщика'}
      </ApplicationStepTitle>
      {allowFlags.allowSimpleProcess &&
        clientApplication.amount < 125000000 &&
        clientApplication.financingType === APPLICATION_TYPES.GUARANTEES && (
          <div>
            <UploadTypeButton
              id="defaultUploadBtn"
              onClick={() => {
                setFileUploadBlock({ type: 'default' });
                defaultClick();
              }}
            >
              Стандартная заявка
            </UploadTypeButton>
            <UploadTypeButtonSlim
              id="slimUploadBtn"
              onClick={() => {
                setFileUploadBlock({ type: 'slim' });
                slimClick();
              }}
            >
              Упрощенная заявка
            </UploadTypeButtonSlim>
          </div>
        )}

      <BorrowerCompaniesTabs
        tabs={companies.map((company, index) => ({
          label: !!company.name ? company.name : `Компания №${index + 1}`,
          data: company,
          errors: dynamicValidatiorFieldErrorsObject[company.id]
        }))}
        borrowerCompanyFileUploadBlock={borrowerCompanyFileUploadBlock}
        onChange={onChange}
      />

      {!disableApplicationDocumentsUpload && (
        <Row>
          {clientApplication.financingType !==
            APPLICATION_TYPES.CONTRACT_FINANCING && (
            <Col lg={4}>
              <DocumentsInfoBlock
                label="Документы по сделке:"
                data={[
                  {
                    title: 'Приложите на текущую дату:',
                    list: ['Схема группы', 'Схема товарно-денежных потоков']
                  },
                  {
                    title: 'Образцы заполнения:'
                  }
                ]}
              >
                {exampleDocuments.map(({ name, size, link }, key) => (
                  <ExampleDocumentBlock key={key}>
                    <UploadFileIcon />
                    <ExampleDocumentInfo>
                      <span>{name}</span>
                      <span>{convertKbToMb(size * 1000)}</span>
                    </ExampleDocumentInfo>
                    <DownloadButton href={link}>Скачать</DownloadButton>
                  </ExampleDocumentBlock>
                ))}
              </DocumentsInfoBlock>
            </Col>
          )}

          <Col lg={4}>
            <InputFile
              label="Поле для загрузки документов по сделке"
              name="file"
              required={true}
              error={groupDocumentsError}
              files={groupDocuments}
              multiple={true}
              uploadStatus={uploadStatus}
              onFilesUpload={onGroupFilesUpload}
              onFilesRemove={onGroupFilesRemove}
            />
          </Col>
        </Row>
      )}
      <ApplicationBtnsWrapper>
        <Button template="backBtn" label="Назад" onClick={goBack} />
        <Button template="nextBtn" label="Продолжить" onClick={onSubmit} />
      </ApplicationBtnsWrapper>
    </ApplicationFormStyled>
  );
};

const mapStateToProps = ({
  Application,
  ClientRole,
  BorrowerCompanies
}: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data,
  companies: BorrowerCompanies.getBorrowerCompaniesList.data,
  groupDocuments: Application.setInitializationFormData.groupDocuments,
  uploadStatus: Application.uploadDocuments.status,
  borrowerCompaniesUploadStatus:
    BorrowerCompanies.uploadBorrowerCompanyDocuments.status,
  borrowerCompaniesDeleteStatus:
    BorrowerCompanies.deleteBorrowerCompanyDocument.status,
  allowFlags: Application.getApplicationFlags.data,
  createStatus: BorrowerCompanies.createBorrowerCompany.status,
  deleteStatus: BorrowerCompanies.deleteBorrowerCompany.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      setInitializationFormData,
      resetInitializationFormData,
      uploadDocuments,
      deleteDocument,
      getBorrowerCompaniesList,
      saveBorrowerCompaniesList,
      createBorrowerCompany,
      deleteBorrowerCompany,
      setFileUploadBlock,
      resetInternalApplication
    },
    dispatch
  );

const BorrowerCompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompanies)
);

export { BorrowerCompaniesConnect as BorrowerCompanies };
