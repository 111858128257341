import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getDebtorsList';

export interface GetDebtorsListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DEBTORS_LIST {
  REQ = 'GET_DEBTORS_LIST_REQ',
  GOT = 'GET_DEBTORS_LIST_GOT',
  ERR = 'GET_DEBTORS_LIST_ERR'
}

export const initialState: GetDebtorsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getDebtorsList = (
  state: GetDebtorsListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetDebtorsListStoreState => {
  switch (action.type) {
    case GET_DEBTORS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DEBTORS_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DEBTORS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDebtorsList;
