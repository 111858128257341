import { GET_LEADS_LIST } from '../reducers/getLeadsList';
import { LeadRead } from './setLeadFormData';
import { LEAD_LIST_TYPE } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: LeadRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  companyInn?: string;
  product?: string;
  dateFrom?: string;
  dateUntil?: string;
  clientName?: string;
  status?: string;
  user?: string;
}

export const req = (leadListType: LEAD_LIST_TYPE, data: RequestData) => ({
  type: GET_LEADS_LIST.REQ,
  data,
  leadListType
});

export const got = (data: ResponseData) => ({
  type: GET_LEADS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_LEADS_LIST.ERR,
  error
});
