import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getUserList';

import { GET_USER_LIST } from '../reducers/getUserList';
import UserApi from '../api';

function* getUsersList(action: {
  type: GET_USER_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(UserApi.getAllUsers, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUsersListSaga(): Generator {
  yield takeLatest(GET_USER_LIST.REQ, getUsersList);
}
