import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/SCF/Supplier/model/actions/postOfferDiscount';

import { POST_OFFER_DISCOUNT } from 'entities/SCF/Supplier/model/reducers/postOfferDiscount';

import SCFApi from 'entities/SCF/Supplier/api';

function* postOfferDiscount(action: {
  type: POST_OFFER_DISCOUNT;
  guid: string;
  data: RequestData;
}) {
  try {
    yield call(SCFApi.postOfferDiscount, action.guid, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postOfferDiscountSaga() {
  yield takeLatest(POST_OFFER_DISCOUNT.REQ, postOfferDiscount);
}
