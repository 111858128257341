import styled from 'styled-components';
import { ButtonStyled } from 'src/shared/ui/Button/styled';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { Form } from 'shared/ui/Form';
import { blue } from 'shared/styled';

export const RequsitesFormStyled = styled.section`
  width: auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RequsitesFormInputItemStyled = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;

  input {
    margin-top: 10px;
    width: 500px;
    height: 30px;
    display: flex;

    border: 1px solid #a6a8ad;
    padding: 5px 10px;
  }
`;

export const RequsitesFormInputDescriptionStyled = styled.p`
  width: 160px;
  display: block;
  margin-right: 50px;
  padding-top: 4px;
`;

export const RequsitesFormInputValuesFormStyled = styled.div`
  width: 500px;
  height: 30px;
  display: flex;
`;

export const RequsitesFormInputValuesStyled = styled.p`
  padding: 5px 10px 5px 10px;
  background-color: #f7f8fc;
  border: 1px solid #a6a8ad;
  width: 100%;
`;

export const ButtonsBlock = styled.div`
  margin-top: 8px;
  display: flex;
  width: 360px;
  justify-content: space-between;
`;

export const ChangeButton = styled.div`
  width: 150px;
  height: 45px;
  display: flex;

  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #005db1;
  }
  p {
    color: #ffffff;
  }
`;

export const StatusButton = styled.div`
  width: 150px;
  height: 45px;
  display: flex;

  justify-content: center;
  background-color: #9fa6b2;
  border-radius: 5px;
  cursor: pointer;
  p {
    color: #ffffff;
  }
`;

export const Status = styled.div`
  margin-top: 8px;
  display: flex;
  p {
    color: #000000;
    font-size: 12px;
  }
`;

export const ApplicationStepTitle = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

export const ApplicationForm = styled(Form)`
  width: 100%;

  & > ${InputContainerStyled} {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > ${ButtonStyled} {
    margin-top: 30px;
  }
`;
