import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';
import { RequestData as ReqPatchCompanyExternal } from 'entities/Cabinet/ExternalAgent/model/actions/patchCompanyExternal';
import { RequestData as ReqPostCreateTenderExternal } from 'entities/Cabinet/ExternalAgent/model/actions/postCreateTenderExternal';
import { RequestData as ReqExternalLeads } from 'entities/Cabinet/ExternalAgent/model/actions/getExternalLeads';

export default class ExternalAgentRoleApi {
  static getApplicationExternal(id: string) {
    return ApiRequest(`/api/application/partner-api/${id}`);
  }

  static getQtySidebar() {
    return ApiRequest('/api/partner_role/users/counter');
  }

  static getUserStats() {
    return ApiRequest('/api/partner_role/users/stats');
  }

  static getExternalLeads(data: ReqExternalLeads) {
    return ApiRequest('/api/agent/leads', 'get', data);
  }

  static getTenderByApplicationExternal(id: string) {
    return ApiRequest(
      `/api/tenders_of_application/partner-api/application/${id}`
    );
  }

  static getDocumentListExternal(id: string) {
    return ApiRequest(`/api/document_list/partner-api/application/${id}`);
  }

  static postDocumentListExternal(guid: string) {
    return ApiRequest(
      `/api/document_list/partner-api/application/${guid}`,
      'post'
    );
  }

  static postFinancialDocumentExternal(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/financial_document/partner-api/${guid}/upload_file`,
      data
    );
  }

  static postNonFinancialDocumentExternal(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/nonfinancial_document/partner-api/${guid}/upload_file`,
      data
    );
  }

  static patchCompanyExternal(inn: string, data: ReqPatchCompanyExternal) {
    return ApiRequest(`/api/company/partner_api/${inn}`, 'patch', data);
  }

  static getCompanyExternal(inn: string) {
    return ApiRequest(`/api/company/partner_api/inn/${inn}`);
  }

  static postCreateTenderExternal(data: ReqPostCreateTenderExternal) {
    return ApiRequest(`/api/tender/partner-api/new`, 'post', data);
  }

  static postAddTenderByEruz(id: string, eruzId: string) {
    return ApiRequest(
      `/api/application/partner-api/${id}/add_tender_by_eruz/${eruzId}`,
      'post'
    );
  }

  static getBeneficiariesOfCompanies(inn: string) {
    return ApiRequest(
      `/api/partner-api/beneficiaries_of_companies/person/${inn}`
    );
  }
}
