import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/createApplication';
import { CREATE_APPLICATION } from '../reducers/createApplication';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { APPLICATION_TYPES } from '../types';

import Application from 'Application/api';

function* createApplication(action: {
  type: CREATE_APPLICATION;
  financingType: APPLICATION_TYPES;
}): Generator {
  try {
    const res: any = yield call(
      Application.createApplication,
      action.financingType
    );
    yield put(got(res));
    yield put(setInitializationFormData(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createApplicationSaga(): Generator {
  yield takeLatest(CREATE_APPLICATION.REQ, createApplication);
}
