import { APPLICATION_STATUSES } from 'Application/types';

export const clientWithAgent = (status: APPLICATION_STATUSES) => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_AGENT:
    case APPLICATION_STATUSES.IN_WORK:
    case APPLICATION_STATUSES.AWAITING:
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.DOCS_REQUEST:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
      return false;

    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
      return true;
    default:
      return true;
  }
};

export const agentWithClient = (status: APPLICATION_STATUSES) => {
  switch (status) {
    case APPLICATION_STATUSES.IN_WORK:
    case APPLICATION_STATUSES.AWAITING:
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.DOCS_REQUEST:
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
    case APPLICATION_STATUSES.TRANSFERRED_TO_AGENT:
      return false;

    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
      return true;
    default:
      return true;
  }
};

export const agentWithBank = (status: APPLICATION_STATUSES, inBankId) => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      return !!inBankId ? false : true;
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.DOCS_REQUEST:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
      return false;

    case APPLICATION_STATUSES.BANK_REJECT:
      return true;
    default:
      return true;
  }
};

export const bankWithAgent = (status: APPLICATION_STATUSES) => {
  switch (status) {
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.DOCS_REQUEST:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.SUCCESS:
      return false;

    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      return true;
    default:
      return true;
  }
};
