import { GET_ALL_AGENT } from 'src/features/Agents/reducers/getAllAgent';
import { AgentFilter, ResponseAgent } from 'src/features/Agents/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ResponseAgent[];
}

export const req = (filters: AgentFilter) => ({
  type: GET_ALL_AGENT.REQ,
  filters
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_AGENT.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_ALL_AGENT.ERR,
  error
});
