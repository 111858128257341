import { call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/getActiveDDSOfDebtor';
import { GET_ACTIVE_DDS_OF_DEBTOR } from 'entities/Cabinet/InternalAgent/model/reducers/getActiveDDSOfDebtor';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* getActiveDDSOfDebtor(action: {
  type: GET_ACTIVE_DDS_OF_DEBTOR.REQ;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentRoleApi.getActiveDDSOfDebtor,
      action.inn
    );

    yield put(got(action.inn, res));
  } catch (e) {
    yield put(err(action.inn, e));
  }
}

export default function* getActiveDDSOfDebtorSaga(): Generator {
  yield takeEvery(GET_ACTIVE_DDS_OF_DEBTOR.REQ, getActiveDDSOfDebtor);
}
