import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postSelectProductLead';

import { POST_SELECT_PRODUCT_LEAD } from '../reducers/postSelectProductLead';
import LeadsApi from '../api';

function* postSelectProductLead(action: {
  type: POST_SELECT_PRODUCT_LEAD;
  data: any;
}): Generator {
  try {
    const res: any = yield call(LeadsApi.postSelectProductLead, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postSelectProductLeadSaga(): Generator {
  yield takeLatest(POST_SELECT_PRODUCT_LEAD.REQ, postSelectProductLead);
}
