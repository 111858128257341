import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getAgentByInn';

import { GET_AGENT } from '../reducers/getAgentByInn';
import QuestionnaireApi from '../api';

function* getAgentByInn(action: { type: GET_AGENT; inn: string }): Generator {
  try {
    const res: any = yield call(QuestionnaireApi.getAgent, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAgentSaga(): Generator {
  yield takeLatest(GET_AGENT.REQ, getAgentByInn);
}
