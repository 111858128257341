import { GET_BUYERS_TO_SUPPLIER_LINK_LIST } from '../reducers/getBuyersToSupplierLinkList';
import { BuyersToSupplierLinkListType } from '../types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: BuyersToSupplierLinkListType[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  SupplierInnToFilterBy?: string;
  DebtorInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_BUYERS_TO_SUPPLIER_LINK_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_BUYERS_TO_SUPPLIER_LINK_LIST.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_BUYERS_TO_SUPPLIER_LINK_LIST.ERR,
  err
});
