export enum USER_TABS {
  DEFAULT = 'Основные параметры',
  APPLICATIONS = 'Заявки',
  ACTIVITIES = 'Активности'
}

export interface UserData {
  id: number;
  createdDateTime: null;
  modifiedDateTime: null;
  lockoutEnd: null;
  accessFailedCount: number;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  lastName: string;
  isBlocked: boolean;
  phoneNumber: string;
  companyInn: string;
  accountStatus: number;
  allowSimpleProcess: boolean;
  allowBlankFields: boolean;
  lastLoginAt: null;
  lastApplicationAt: null;
  roles: string[];
  permissions: string[];
}
