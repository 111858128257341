import { GET_MY_SUPPLIERS } from '../reducers/getMySuppliers';

export interface MySupplier {
  confirmedByDebtor: boolean;
  confirmedBySupplier: boolean;
  debtorAllowAuction: any;
  debtorAllowDiscounting: any;
  debtorAllowFactoring: any;
  discountMargin: number;
  rejectedByDebtor: boolean;
  rejectedBySupplier: boolean;
  supplierHoldingName: string;
  supplierInAction: boolean;
  supplierInn: string;
  supplierName: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: MySupplier[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  supplierInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_ERR,
  error
});
