import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { russiaPhoneMask } from 'shared/utils/InputMasks';
import { LegalTypes, InnPlaceholders } from 'shared/constants';
import { LEGAL_TYPES } from 'globaltypes';

import {
  req as SCFRegister,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/SCFRegisterSupplier';

import { InputPassword } from 'shared/ui/InputPassword';
import { PasswordHint } from 'shared/ui/Hint';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';

import { CheckboxStyled } from './styles';
import { createValidation } from './validator';

export interface Fields {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  legalForm: string;
  code: string;
  companyName: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface State extends Fields {
  rulesAgreement: boolean;
  submitClicked: boolean;
}

interface DispatchToProps {
  SCFRegister: (data: RequestDataType) => void;
}

type Props = DispatchToProps;

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  legalForm: '',
  code: '',
  companyName: '',
  email: '',
  password: '',
  repeatPassword: '',
  rulesAgreement: false,
  submitClicked: false
};

class SCFRegistrationForm extends React.Component<Props, State> {
  Validator = createValidation();
  state: State = initialState;

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    const {
      repeatPassword,
      rulesAgreement,
      submitClicked,
      ...dataForRequest
    } = this.state;

    const { SCFRegister } = this.props;
    SCFRegister(dataForRequest);
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isFieldToTrim =
      name === 'code' ||
      name === 'email' ||
      name === 'phoneNumber' ||
      name === 'password' ||
      name === 'repeatPassword';
    const isCheckbox = type === 'checkbox';

    this.setState({
      [name]: isCheckbox
        ? checked
        : isFieldToTrim
          ? value.replace(/\s+/g, '')
          : value
    } as { [key in keyof Fields]: string });
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      legalForm,
      code,
      companyName,
      email,
      password,
      repeatPassword,
      rulesAgreement
    } = this.state;

    const { errors, fields } = this.Validator.insertArgs({
      passwordEqual: [password],
      innRule: [legalForm],
      companyNameRule: [legalForm]
    }).validate(this.state);

    return (
      <Form onSubmit={this.onSubmit} name="scf-registration-form">
        <FormSection>
          <Input
            value={firstName}
            label="Ваше имя"
            name="firstName"
            placeholder="Имя"
            required={true}
            error={errors.firstName}
            onChange={this.onChange}
          />
          <Input
            value={lastName}
            label="Ваша фамилия"
            name="lastName"
            placeholder="Фамилия"
            required={true}
            error={errors.lastName}
            onChange={this.onChange}
          />
          <Input
            type="tel"
            value={phoneNumber}
            label="Номер телефона"
            name="phoneNumber"
            placeholder="+7(___)___-__-__"
            mask={russiaPhoneMask}
            required={true}
            error={errors.phoneNumber}
            onChange={this.onChange}
          />
          <Select
            options={Object.keys(LegalTypes).map(type => ({
              id: type,
              name: LegalTypes[type]
            }))}
            value={legalForm}
            label="Тип"
            name="legalForm"
            placeholder="Выберите тип"
            required={true}
            error={errors.legalForm}
            onChange={this.onChange}
          />
          <Input
            value={code}
            label="ИНН"
            name="code"
            placeholder={
              legalForm === LEGAL_TYPES.ENTITY
                ? InnPlaceholders.entity
                : legalForm === LEGAL_TYPES.INDIVIDUAL
                  ? InnPlaceholders.individual
                  : 'Введите ИНН'
            }
            required={true}
            error={errors.code}
            onChange={this.onChange}
          />
          {legalForm === LEGAL_TYPES.ENTITY && (
            <Input
              value={companyName}
              label="Наименование организации"
              name="companyName"
              placeholder="Название компании"
              required={legalForm === LEGAL_TYPES.ENTITY}
              error={errors.companyName}
              onChange={this.onChange}
            />
          )}
        </FormSection>
        <FormSection>
          <Input
            value={email}
            label="Email"
            name="email"
            placeholder="Email"
            required={true}
            error={errors.email}
            onChange={this.onChange}
          />
          <InputPassword
            value={password}
            label="Пароль"
            name="password"
            placeholder="Пароль"
            required={true}
            error={errors.password}
            onChange={this.onChange}
            hint={{
              validatable: true,
              position: 'left',
              component: (
                <PasswordHint validationStatuses={fields.password.statuses} />
              )
            }}
          />
          <InputPassword
            value={repeatPassword}
            label="Подтвердите пароль"
            name="repeatPassword"
            placeholder="Пароль"
            required={true}
            error={errors.repeatPassword}
            onChange={this.onChange}
          />
          <CheckboxStyled
            template="agreementCheckbox"
            name="rulesAgreement"
            checked={rulesAgreement}
            error={errors.rulesAgreement}
            onChange={this.onChange}
          />
        </FormSection>
        <Button type="submit" label="Зарегистрироваться" template="nextBtn" />
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ SCFRegister }, dispatch);

const SCFRegistrationFormConnect = connect<null, DispatchToProps>(
  null,
  mapDispatchToProps
)(SCFRegistrationForm);

export { SCFRegistrationFormConnect as SCFRegistrationFormSupplier };
