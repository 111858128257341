import * as React from 'react';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import { Document } from 'src/features/Application/types';
import { FileInfoStyled } from './styles';

interface FileInfoProps {
  file: Document;
  onFileRemove: (data: DeleteDocumentRequestDataType) => void;
}

export const FileInfo: React.FC<FileInfoProps> = ({ file, onFileRemove }) => (
  <FileInfoStyled>
    <p>{file.name.substring(0, 30) + '...'}</p>
  </FileInfoStyled>
);
