import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blue, lightGray, lighterBlue, rl } from 'shared/styled';
import { BoxStyled } from 'shared/styled';

export const FinancingTypeBlockStyled = styled(BoxStyled)`
  position: relative;
  border: 2px solid ${lightGray};
  padding: 50px;
  height: 300px;

  background-color: ${({ isDisabled }) => isDisabled && lightGray};

  &:hover {
    background: ${({ isDisabled }) => !isDisabled && lighterBlue};
    cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  }
`;

export const FinancingTypeBlockIconStyled = styled.div`
  font-size: 5em;
`;
export const FinancingTypeBlockTextStyled = styled.p`
  font-size: 2em;
`;

export const FinancingTypeBlockDisabledTextStyled = styled.p`
  font-size: 20px;
`;

export const InstructionStyled = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  p {
    text-decoration: underline;
    font-family: ${rl};
    color: ${blue};
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${blue};
  font-size: 1.5em;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  position: relative;
  background: #fff;
  padding: 20px;
  width: 100%;
  max-width: 1300px;
  max-height: 100%;
  overflow-y: auto;
`;

export const FinancingTemporaryTypeBlockStyled = styled(BoxStyled)`
  position: relative;
  border: 2px solid ${lightGray};
  padding: 30px 50px;
  height: 300px;

  background-color: ${({ isDisabled }) => isDisabled && lightGray};

  &:hover {
    background: ${({ isDisabled }) => !isDisabled && lighterBlue};
    cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  }

  ${FinancingTypeBlockTextStyled} {
    margin: 0 !important;
  }

  ${FinancingTypeBlockDisabledTextStyled} {
    margin: 15px 0 0 0 !important;
  }
`;
