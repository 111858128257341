import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import {
  ExampleDocumentBlock,
  ExampleDocumentInfo,
  DownloadButton
} from '../../../BorrowerCompanies/styles';
import { convertKbToMb } from 'src/shared/utils/Utils';

interface ExampleDocumentProps {
  name: string;
  size: number;
  link: string;
}

// TODO expand to include other icons
const ExampleDocument: React.FC<ExampleDocumentProps> = ({
  name,
  size,
  link
}) => (
  <ExampleDocumentBlock>
    {/* <UploadFileIcon /> */}
    <FontAwesomeIcon icon={faFileExcel} size="lg" color="green" />
    <ExampleDocumentInfo>
      <span>{name}</span>
      <span>{convertKbToMb(size * 1000)}</span>
    </ExampleDocumentInfo>
    <DownloadButton href={link}>Скачать</DownloadButton>
  </ExampleDocumentBlock>
);

export default ExampleDocument;
