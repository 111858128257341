import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestDataType
} from 'src/features/Application/actions/getCompanyByInn';
import { GET_COMPANY_BY_INN_APPLICATION } from 'src/features/Application/reducers/getCompanyByInn';
import SCFApi from 'src/features/SCF/api/index';

function* getCompanyByInnApplication(action: {
  type: GET_COMPANY_BY_INN_APPLICATION;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getCompanyByInnThirdParty, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnApplicationSaga() {
  yield takeLatest(
    GET_COMPANY_BY_INN_APPLICATION.REQ,
    getCompanyByInnApplication
  );
}
