import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getCompanyFastInfo';

export interface GetCompanyFastInfoStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_COMPANY_FAST_INFO {
  REQ = 'GET_COMPANY_FAST_INFO_REQ',
  GOT = 'GET_COMPANY_FAST_INFO_GOT',
  ERR = 'GET_COMPANY_FAST_INFO_ERR'
}

export const initialState: GetCompanyFastInfoStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    address: '',
    chief: '',
    chiefPosition: '',
    fullName: '',
    shortName: '',
    inn: '',
    kpp: '',
    ogrn: '',
    regDate: '',
    endDate: ''
  },
  error: {}
};

const getCompanyFastInfo = (
  state: GetCompanyFastInfoStoreState = initialState,
  action: {
    type: GET_COMPANY_FAST_INFO;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyFastInfoStoreState => {
  switch (action.type) {
    case GET_COMPANY_FAST_INFO.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_FAST_INFO.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_FAST_INFO.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyFastInfo;
