import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getBeneficiaries';

import { GET_CLIENT_BENEFICIARIES } from '../reducers/getBeneficiaries';
import BeneficiariesApi from '../api';

function* getClientBeneficiaries(action: {
  type: GET_CLIENT_BENEFICIARIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BeneficiariesApi.getBeneficiaries, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getClientBeneficiariesSaga(): Generator {
  yield takeLatest(GET_CLIENT_BENEFICIARIES.REQ, getClientBeneficiaries);
}
