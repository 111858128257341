import { call, put, takeLatest } from 'redux-saga/effects';

import { APPLICATION_TYPES, DOCUMENT_UPLOAD_TYPE } from 'Application/types';

import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import { APPLICATION_PROCESS_MODES } from 'Application/reducers/setApplicationProcessMode';

import { err, got } from 'Application/actions/prepareForApplicationEdit';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { setGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';
import { setCreditingFormData } from 'Application/actions/setCreditingFormData';
import { setFinancingFormData } from 'Application/actions/setFinancingFormData';
import { setContractFinancingFormData } from 'Application/actions/setContractFinancingFormData';

import {
  PREPARE_FOR_APPLICATION_EDIT,
  RequestDataType
} from 'Application/reducers/prepareForApplicationEdit';

import ApplicationApi from 'Application/api';
import BorrowerCompaniesApi from 'BorrowerCompanies/api';
import { setPropertyFinancingFormData } from '../actions/setPropertyFinancingFormData';

function* prepareForApplicationEdit(action: {
  type: PREPARE_FOR_APPLICATION_EDIT;
  data: RequestDataType;
}): Generator {
  try {
    const applicationData: any = yield call(
      ApplicationApi.getApplication,
      action.data
    );

    const agreementDocuments: any = yield call(
      ApplicationApi.getDocumentsList,
      {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.AGREEMENT
      }
    );

    const groupDocuments: any = yield call(ApplicationApi.getDocumentsList, {
      id: action.data.id,
      type: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });

    const borrowerCompanies: any = yield call(
      BorrowerCompaniesApi.getBorrowerCompaniesList,
      {
        applicationId: action.data.id
      }
    );

    yield put(
      setApplicationProcessMode({
        mode: APPLICATION_PROCESS_MODES.EDIT
      })
    );

    yield put(
      setInitializationFormData({
        id: action.data.id,
        financingType: applicationData.financingType,
        refinancing: applicationData.refinancing,
        clientCompanyName: applicationData.clientCompanyName,
        code: applicationData.code,
        amount: applicationData.amount,
        term: applicationData.term,
        borrowerCompanies,
        agreementDocuments,
        groupDocuments,
        termStartDate: applicationData.termStartDate,
        termEndDate: applicationData.termEndDate
      })
    );

    if (applicationData.financingType === APPLICATION_TYPES.FACTORING) {
      const factoringDocuments: any = yield call(
        ApplicationApi.getDocumentsList,
        {
          id: action.data.id,
          type: DOCUMENT_UPLOAD_TYPE.FACTORING
        }
      );

      yield put(
        setFactoringFormData({
          debtorType: applicationData.debtorType,
          debtors: applicationData.debtors,
          documents: factoringDocuments
        })
      );
    }

    if (applicationData.financingType === APPLICATION_TYPES.GUARANTEES) {
      const contractDocuments: any = yield call(
        ApplicationApi.getDocumentsList,
        {
          id: action.data.id,
          type: DOCUMENT_UPLOAD_TYPE.GUARANTEE_CONTRACT
        }
      );

      yield put(
        setGuaranteesFormData({
          guaranteesType: applicationData.guaranteesType,
          tenderLink: applicationData.tenderLink,
          tenderComment: applicationData.tenderComment,
          contractDocuments,
          typeComment: applicationData.typeComment,
          desiredBanks: applicationData.desiredBanks,
          tax: applicationData.tax,
          tender: applicationData.tender
        })
      );
    }

    if (applicationData.financingType === APPLICATION_TYPES.CREDITING) {
      yield put(
        setCreditingFormData({
          creditingType: applicationData.creditingType,
          pledge: applicationData.pledge,
          pledges: applicationData.pledges,
          otherPledgeEstate: applicationData.otherPledgeEstate,
          guarantor: applicationData.guarantor,
          guarantors: applicationData.guarantors
        })
      );
    }

    if (applicationData.financingType === APPLICATION_TYPES.FINANCING) {
      yield put(
        setFinancingFormData({
          creditType: applicationData.creditType,
          equity: applicationData.equity,
          termInvestmentPhase: applicationData.termInvestmentPhase,
          isAgriculture: applicationData.isAgriculture,
          paymentConfirmationInvestmentPhase:
            applicationData.paymentConfirmationInvestmentPhase
        })
      );
    }

    if (
      applicationData.financingType === APPLICATION_TYPES.CONTRACT_FINANCING
    ) {
      const contractDocuments: any = yield call(
        ApplicationApi.getDocumentsList,
        {
          id: action.data.id,
          type: DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING
        }
      );

      yield put(
        setContractFinancingFormData({
          contractType: applicationData.contractType,
          purchaseLink: applicationData.purchaseLink,
          contractDocuments,
          contractAmount: applicationData.contractAmount,
          contractSubject: applicationData.contractSubject,
          contractEndDate: applicationData.contractEndDate,
          prepaymentType: applicationData.prepaymentType,
          prepaymentAmount: applicationData.prepaymentAmount
        })
      );
    }

    if (
      applicationData.financingType === APPLICATION_TYPES.PROPERTY_FINANCING
    ) {
      const propertyFinancingDocuments: any = yield call(
        ApplicationApi.getDocumentsList,
        {
          id: action.data.id,
          type: DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING
        }
      );

      yield put(
        setPropertyFinancingFormData({
          propertyFinancingDocuments,
          term: applicationData.term,
          inn: applicationData.code,
          amount: applicationData.amount,
          clientType: applicationData.clientType,
          clientAverageMonthlyIncome:
            applicationData.clientAverageMonthlyIncome,
          clientIncomeConfirmationType:
            applicationData.clientIncomeConfirmationType,
          client: applicationData.clientCompanyName
        })
      );
    }

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* prepareForApplicationEditSaga(): Generator<any> {
  yield takeLatest(PREPARE_FOR_APPLICATION_EDIT.REQ, prepareForApplicationEdit);
}
