import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/putChecklist';

import { PUT_CHECKLIST } from '../reducers/putChecklist';
import LeadsApi from '../api';

function* putChecklist(action: {
  type: PUT_CHECKLIST;
  id: string;
  data: RequestData;
}): Generator {
  try {
    yield call(LeadsApi.putChecklist, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putChecklistSaga(): Generator {
  yield takeLatest(PUT_CHECKLIST.REQ, putChecklist);
}
