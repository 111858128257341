import { POST_OFFER_SEND } from '../reducers/postOfferSend';

export const req = (id: number) => ({
  type: POST_OFFER_SEND.REQ,
  id
});

export const got = () => ({
  type: POST_OFFER_SEND.GOT
});

export const err = (error: {}) => ({
  type: POST_OFFER_SEND.ERR,
  error
});

export const reset = () => ({
  type: POST_OFFER_SEND.RESET
});
