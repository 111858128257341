import { combineReducers } from 'redux';
import setLeadFormData, { SetLeadDataStoreState } from './setLeadFormData';
import sendLeadData, { SendLeadDataStoreState } from './sendLeadData';
import getLeadogenerators, {
  GetLeadogeneratorsStoreState
} from './getLeadogenerators';
import getLead, { GetLeadStoreState } from './getLead';
import changeLeadStatus, {
  ChangeLeadStatusStoreState
} from './changeLeadStatus';
import getLeadsList, { GetLeadsListStoreState } from './getLeadsList';
import getProductApplicationLead, {
  GetProductApplicationLeadStoreState
} from './getProductApplicationLead';
import postSelectProductLead, {
  PostSelectProductLeadStoreState
} from './postSelectProductLead';
import getActivitiesByLeadInternal, {
  GetActivitiesByLeadInternalStoreState
} from './getActivitiesByLeadInternal';
import getActivitiesByLeadExternal, {
  GetActivitiesByLeadExternalStoreState
} from './getActivitiesByLeadExternal';
import postExternalApiAlfaBankLead, {
  PostExternalApiAlfaBankLeadStoreState
} from './postExternalApiAlfaBank';
import postExternalApiPSBLead, {
  PostExternalApiPSBLeadStoreState
} from './postExternalApiPSB';
import getLeadDocumentList, {
  GetLeadDocumentListStoreState
} from './getLeadDocumentList';
import postCreateForLead, {
  PostCreateForLeadStoreState
} from './postCreateForLead';
import putSendToClient, { PutSendToClientStoreState } from './putSendToClient';
import postSendCompanyToScoring, {
  PostSendCompanyToScoringStoreState
} from './postSendCompanyToScoring';
import putChecklist, { PutChecklistStoreState } from './putChecklist';

export interface LeadsReducers {
  setLeadFormData: SetLeadDataStoreState;
  sendLeadData: SendLeadDataStoreState;
  getLeadogenerators: GetLeadogeneratorsStoreState;
  getLead: GetLeadStoreState;
  changeLeadStatus: ChangeLeadStatusStoreState;
  getLeadsList: GetLeadsListStoreState;
  getProductApplicationLead: GetProductApplicationLeadStoreState;
  postSelectProductLead: PostSelectProductLeadStoreState;
  getActivitiesByLeadInternal: GetActivitiesByLeadInternalStoreState;
  getActivitiesByLeadExternal: GetActivitiesByLeadExternalStoreState;
  postExternalApiAlfaBankLead: PostExternalApiAlfaBankLeadStoreState;
  postExternalApiPSBLead: PostExternalApiPSBLeadStoreState;
  getLeadDocumentList: GetLeadDocumentListStoreState;
  postCreateForLead: PostCreateForLeadStoreState;
  putSendToClient: PutSendToClientStoreState;
  postSendCompanyToScoring: PostSendCompanyToScoringStoreState;
  putChecklist: PutChecklistStoreState;
}

export const Leads = combineReducers({
  setLeadFormData,
  sendLeadData,
  getLeadogenerators,
  getLead,
  changeLeadStatus,
  getLeadsList,
  getProductApplicationLead,
  postSelectProductLead,
  getActivitiesByLeadInternal,
  getActivitiesByLeadExternal,
  postExternalApiAlfaBankLead,
  postExternalApiPSBLead,
  getLeadDocumentList,
  postCreateForLead,
  putSendToClient,
  postSendCompanyToScoring,
  putChecklist
});
