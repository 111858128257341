import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFinancialStatements';

import { GET_FINANCIAL_STATEMENTS } from '../reducers/getFinancialStatements';
import FinancialStatementsApi from '../api';

function* getFinancialStatements(action: {
  type: GET_FINANCIAL_STATEMENTS;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      FinancialStatementsApi.getFinancialStatements,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinancialStatementsSaga(): Generator {
  yield takeLatest(GET_FINANCIAL_STATEMENTS.REQ, getFinancialStatements);
}
