import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from './../actions/deleteFromAuction';

import { DELETE_FROM_AUCTION } from './../reducers/deleteFromAuction';
import SCFApi from './../api';

function* deleteFromAuction(action: {
  type: DELETE_FROM_AUCTION;
  guid: string;
  inn: string;
}): Generator {
  try {
    yield call(SCFApi.deleteFromAuction, action.guid, action.inn);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteFromAuctionSaga(): Generator {
  yield takeLatest(DELETE_FROM_AUCTION.REQ, deleteFromAuction);
}
