import ApiRequest from 'src/shared/utils/ApiRequest';

export default class PlatformApi {
  static getPlatform(id: number) {
    return ApiRequest(`/api/external_credentials_of_products/product/${id}`);
  }

  static getExternalCredential() {
    return ApiRequest(`/api/external_credential/all`);
  }

  static postExternalCredential(
    externalCredentialsId: number,
    productId: string
  ) {
    return ApiRequest(
      `/api/external_credentials_of_products/external_credential`,
      'post',
      {
        externalCredentialsId,
        productId
      }
    );
  }
}
