import * as React from 'react';
import { USER_PERMISSIONS } from 'globaltypes';
import { ExternalAgentsList } from 'src/features/Permissions/ManageExternalAgents/components';
import { NavigationLink } from 'Layouts/components';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { CabinetComponent } from '../EachPage';
import { createApplicationsListCabinetComponent } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

export const ManagementSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>
        Администрирование
      </SectionSeparatorStyled>
    ),
    hasPermission: permissions =>
      [USER_PERMISSIONS.MANAGE_EXTERNAL_AGENTS].isIn(permissions)
  },
  {
    path: '/cabinet/internal/externalagentslist',
    hasPermission: permissions =>
      [USER_PERMISSIONS.MANAGE_EXTERNAL_AGENTS].isIn(permissions),
    render: location => <ExternalAgentsList key={location.key} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={<FontAwesomeIcon icon={faUserTie} fixedWidth={true} />}
        to={{
          pathname: '/cabinet/internal/externalagentslist',
          search: '?page=1'
        }}
        label="Список внешних агентов"
      />
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/internal/distribution',
      type: APPLICATIONS_LIST_TYPE.TO_DISTRIBUTE,
      link: {
        label: 'Распределение',
        template: 'fontawesome',
        icon: <FontAwesomeIcon icon={faCoffee} fixedWidth={true} />,
        countName: 'distribution'
      }
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.SEE_ALL_APPLICATIONS].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/internal/allinwork',
      type: APPLICATIONS_LIST_TYPE.ALL_IN_WORK,
      link: {
        label: 'Все заявки в работе',
        icon: 'bag',
        countName: 'allInWork'
      }
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.SEE_ALL_APPLICATIONS].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/internal/alldeals',
      type: APPLICATIONS_LIST_TYPE.ALL_DEALS,
      link: {
        label: 'Все сделки',
        icon: 'docs',
        countName: 'allDeals'
      }
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.SEE_ALL_APPLICATIONS].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/internal/alltrashed',
      type: APPLICATIONS_LIST_TYPE.ALL_TRASHED,
      link: {
        label: 'Вся корзина',
        icon: 'trash',
        countName: 'allTrashed'
      }
    })
  }
];
