import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';
import { InputFile } from 'src/features/SCF/components/InputFile/InputFile';
import { Loader } from 'Layouts/components';

import {
  req as getPurchaseContract,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getPurchaseContractByGuid';
import {
  req as getDocumentFormBySupplyContract,
  ResponseDataType as DocumentFormBySupplyContractRes
} from 'entities/SCF/Debtor/model/actions/getDocumentFormBySupplyContract';
import {
  req as getDocumentForms,
  ResponseDataType as DocumentsFormsRes
} from 'entities/SCF/Debtor/model/actions/getDocumentForms';
import {
  req as postAddFormToSupplyContracts,
  RequestDataType as PostAddFormToSupplyContractsReq
} from 'entities/SCF/Debtor/model/actions/postAddFormToSupplyContracts';
import { req as patchPurchaseContractDebtor } from 'entities/SCF/Debtor/model/actions/patchPurchaseContractDebtor';
import { RequestDataType as ReqPatchPurchaseContract } from 'entities/SCF/Debtor/model/reducers/patchPurchaseContractDebtor';
import { req as getFormFile } from 'entities/SCF/Debtor/model/actions/getFormFile';
import {
  req as getContractByDebtor,
  ResponseData as ResContractByDebtor
} from 'entities/SCF/Debtor/model/actions/getContractByDebtor';
import { req as postAddToContractByDebtor } from 'entities/SCF/Debtor/model/actions/postAddToContractByDebtor';
import { req as getBankAccount } from 'src/features/SCF/actions/getBankAccount';
import { ResponseDataType as GetBankAccountResponseDataType } from 'src/features/SCF/actions/getBankAccount';
import {
  req as postConfirmContractByDebtor,
  RequestData as ReqConfirmContractByDebtor
} from 'entities/SCF/Debtor/model/actions/postConfirmContractByDebtor';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { closeModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { ResponseData as CounterRes } from 'entities/SCF/Debtor/model/reducers/getDebtorCounterSidebar';

import {
  ContractsInfoPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  ContractsContainer,
  ContractsHeader,
  ContractsTopSection,
  ContractsTopLeftContainer,
  StatusArea,
  CheckIcon,
  IconPopup,
  TimesIcon,
  DiscountingForm,
  DiscountingFormModal,
  DiscountContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  ContractInputFileContainer,
  TableStyledWrapper,
  PayerContractDescription,
  AccountContainer,
  AccountInfo,
  AccountInfoContainer,
  ConfirmedStatus
} from './styles';

import {
  BackBtn,
  BackBtnText,
  AddBtn,
  AddBtnText,
  BtnsBlock,
  BtnsBlockSignBtn,
  BtnsBlockDownloadDocument,
  BtnsBlockDownloadSignature,
  BtnsBlockDownloadSignatureTopContainer,
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError,
  AcceptButton,
  DeclineButton,
  AcceptContract
} from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { req as uploadContractDocument } from 'entities/SCF/Debtor/model/actions/uploadDebtorContractDocument';
import { req as putAcceptByDebtor } from 'entities/SCF/Debtor/model/actions/putAcceptByDebtor';
import { req as putDeclineByDebtor } from 'entities/SCF/Debtor/model/actions/putDeclineByDebtor';
import { SagaRequestDataType as UploadContractDocumentRequestDataType } from 'entities/SCF/Debtor/model/reducers/uploadDebtorContractDocument';
import SCFApi from 'src/features/SCF/api';
import SCFDebtorApi from 'entities/SCF/Debtor/api';
import SCFSupplierApi from 'entities/SCF/Supplier/api';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { lightGray, navyBlue, orange } from 'shared/styled';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  purchaseContract: ResponseDataType;
  documentsUploadStatus: REQUEST_STATUSES;
  statusAcceptByDebtor: REQUEST_STATUSES;
  statusDeclineByDebtor: REQUEST_STATUSES;
  documentFormBySupplyContract: DocumentFormBySupplyContractRes;
  statusDocumentFormBySupplyContract: REQUEST_STATUSES;
  errorDocumentFormBySupplyContract: ResponseError;
  counter: CounterRes;
  documents: DocumentsFormsRes[];
  statusDocuments: REQUEST_STATUSES;
  statusPostAddFormToSupplyContracts: REQUEST_STATUSES;
  statusPatchPurchaseContract: REQUEST_STATUSES;
  debtorContract: ResContractByDebtor[];
  statusDebtorContract: REQUEST_STATUSES;
  bankAccount: GetBankAccountResponseDataType;
  statusAddContract: REQUEST_STATUSES;
  statusConfirmContractDebtor: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPurchaseContract: (data: RequestDataType) => void;
  uploadContractDocument: (data: UploadContractDocumentRequestDataType) => void;
  putAcceptByDebtor: (guid: string) => void;
  putDeclineByDebtor: (guid: string) => void;
  getDocumentFormBySupplyContract: (guid: string) => void;
  getFormFile: (guid: string, name: string) => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  getDocumentForms: () => void;
  postAddFormToSupplyContracts: (data: PostAddFormToSupplyContractsReq) => void;
  patchPurchaseContractDebtor: (
    guid: string,
    data: ReqPatchPurchaseContract
  ) => void;
  getContractByDebtor: (guid: string) => void;
  getBankAccount: () => void;
  postAddToContractByDebtor: (contractAccount: string, guid: string) => void;
  postConfirmContractByDebtor: (
    accountGuid: string,
    contractGuid: string,
    data: ReqConfirmContractByDebtor
  ) => void;
}

interface State {
  pageUrl: string;
  filesDisplay: any[];
  selectedFormDiscountAgreement: string;
  isPaymentDefermentFixed: boolean;
  paymentDeferment: number;
  selectedPayerContract: string;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ContractsInfoPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: this.props.match.params.guid,
        filesDisplay: null,
        selectedFormDiscountAgreement: null,
        isPaymentDefermentFixed: null,
        paymentDeferment: 0,
        selectedPayerContract: null
      };
    }
  }

  handleFilesChange = file => {
    this.setState({ filesDisplay: file });
  };

  onFilesUpload = contract => {
    this.handleFilesChange(contract);
  };

  sendContractDocument = () => {
    const { uploadContractDocument } = this.props;
    uploadContractDocument({
      files: this.state.filesDisplay,
      guid: this.props.purchaseContract.id
    });
    this.handleFilesChange(null);
  };

  initFetch = () => {
    const { getPurchaseContract, getContractByDebtor } = this.props;
    getPurchaseContract({ id: this.state.pageUrl });
    getContractByDebtor(this.props.match.params.guid);

    function base64ToBlob(base64, type = 'application/octet-stream') {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    }

    function arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    }

    fetch(`/api/purchase_contract/${this.state.pageUrl}/get_agreement_file`)
      .then(res => res.arrayBuffer())
      .then(data => {
        const base64Str = arrayBufferToBase64(data);
        const blob = base64ToBlob(base64Str, 'application/pdf');
        const url = URL.createObjectURL(blob);
        const outputObject = document.createElement('iframe');
        const outputText = document.createElement('p');
        outputText.style.margin = '30px 0 8px 0';
        outputText.style.fontFamily = 'Roboto-Regular';
        outputText.innerHTML = '* Превью договора';
        outputObject.src = url + '#toolbar=0';
        outputObject.style.width = '1200px';
        outputObject.style.height = '1000px';
        outputObject.style.background = '#262B41';
        const container = document.getElementById('documentObject');
        if (container) {
          container.innerHTML = '';
          container.appendChild(outputText);
          container.appendChild(outputObject);
        }
      });
  };

  componentDidMount() {
    this.initFetch();
    this.props.getDocumentFormBySupplyContract(this.props.match.params.guid);
    this.props.getDocumentForms();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (this.props.status === REQUEST_STATUSES.GOT &&
        this.props.documentsUploadStatus !== prevProps.documentsUploadStatus) ||
      (this.props.statusPatchPurchaseContract === REQUEST_STATUSES.GOT &&
        this.props.statusPatchPurchaseContract !==
          prevProps.statusPatchPurchaseContract)
    ) {
      this.initFetch();
      this.setState({ paymentDeferment: 0, isPaymentDefermentFixed: null });
    }
    if (
      this.props.statusConfirmContractDebtor !==
        prevProps.statusConfirmContractDebtor &&
      this.props.statusConfirmContractDebtor === REQUEST_STATUSES.GOT
    ) {
      this.props.getContractByDebtor(this.props.match.params.guid);
    }
    if (
      this.props.statusAddContract !== prevProps.statusAddContract &&
      this.props.statusAddContract === REQUEST_STATUSES.GOT
    ) {
      this.props.getContractByDebtor(this.props.match.params.guid);
    }
    if (
      this.props.status === REQUEST_STATUSES.GOT &&
      this.props.purchaseContract !== prevProps.purchaseContract
    ) {
      this.setState({
        paymentDeferment: this.props.purchaseContract.paymentDeferment
      });
    }
    if (
      this.props.statusAcceptByDebtor !== prevProps.statusAcceptByDebtor ||
      this.props.statusDeclineByDebtor !== prevProps.statusDeclineByDebtor
    ) {
      window.location.reload();
    }
    if (
      this.props.statusPostAddFormToSupplyContracts !==
      prevProps.statusPostAddFormToSupplyContracts
    ) {
      this.initFetch();
      this.props.getDocumentFormBySupplyContract(this.props.match.params.guid);
      this.props.getDocumentForms();
    }
  }

  onBackClick = () => {
    this.props.history.push(`/debtor/purchase_contracts?page=1`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
  };

  onSignClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    this.props.history.push({ pathname: `/debtor/settings/certificates/edit` });
  };

  handleRadioBtn = (guid: string) => {
    this.setState({ selectedFormDiscountAgreement: guid });
  };

  handlePaymentDefermentChange = (name: string) => {
    if (name === 'active') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: true
      }));
    } else if (name === 'inActive') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: false
      }));
    }
  };

  render() {
    const {
      documentFormBySupplyContract,
      statusDocumentFormBySupplyContract,
      errorDocumentFormBySupplyContract,
      counter,
      status,
      purchaseContract,
      documentsUploadStatus,
      debtorContract,
      bankAccount
    } = this.props;

    return (
      <ContractsInfoPageStyled>
        {(status === REQUEST_STATUSES.REQUEST ||
          documentsUploadStatus === REQUEST_STATUSES.REQUEST) && <Loader />}

        {status === REQUEST_STATUSES.GOT && (
          <ContractsContainer>
            <ContractsTopSection>
              <ContractsTopLeftContainer>
                <ContractsHeader>
                  <div>
                    <h1>
                      Договор № {purchaseContract.contractNumber} от{' '}
                      {format(
                        new Date(purchaseContract.contractStartDate),
                        'dd.MM.yyyy'
                      )}
                    </h1>

                    {purchaseContract.confirmedByDebtor === null ? (
                      <></>
                    ) : purchaseContract.confirmedByDebtor ? (
                      <StatusArea className="icon-to-show-popup">
                        <CheckIcon />
                        <IconPopup className="icon-popup">
                          <p>Подтвержден вами</p>
                        </IconPopup>
                      </StatusArea>
                    ) : (
                      <StatusArea className="icon-to-show-popup">
                        <TimesIcon />
                        <IconPopup className="icon-popup">
                          <p>Отклонен вами</p>
                        </IconPopup>
                      </StatusArea>
                    )}
                  </div>

                  <BackBtn onClick={() => this.onBackClick()}>
                    <BackBtnText>Назад</BackBtnText>
                  </BackBtn>
                </ContractsHeader>
                <ContractsInfoArea>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle style={{ width: '150px' }}>
                      Поставщик:
                    </ContractsInfoItemTitle>
                    <p>{purchaseContract.supplierInn}</p>
                    <p>{purchaseContract.supplierName}</p>
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>

                <DiscountContainer>
                  <ContractsInfoArea>
                    <h1>Реквизиты договора:</h1>
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Наименование договора
                      </ContractsInfoItemTitle>
                      <p>{purchaseContract.contractName}</p>
                    </ContractsInfoItemContainer>
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Номер договора
                      </ContractsInfoItemTitle>
                      <p>{purchaseContract.contractNumber}</p>
                    </ContractsInfoItemContainer>
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Дата договора
                      </ContractsInfoItemTitle>
                      <p>
                        {format(
                          new Date(purchaseContract.contractStartDate),
                          'dd.MM.yyyy'
                        )}
                      </p>
                    </ContractsInfoItemContainer>
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Срок действия договора
                      </ContractsInfoItemTitle>
                      {purchaseContract.isPerpetual == true ? (
                        <p>Бессрочный</p>
                      ) : (
                        <p>
                          {format(
                            new Date(purchaseContract.contractStartDate),
                            'dd.MM.yyyy'
                          )}{' '}
                          -{' '}
                          {format(
                            new Date(purchaseContract.contractEndDate),
                            'dd.MM.yyyy'
                          )}
                        </p>
                      )}
                    </ContractsInfoItemContainer>
                  </ContractsInfoArea>
                </DiscountContainer>

                <ContractsInfoArea>
                  <h1>Условия оплаты:</h1>
                  {purchaseContract.paymentDefermentFixed !== null ? (
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Отсрочка платежа
                      </ContractsInfoItemTitle>
                      <p>
                        {purchaseContract.paymentDeferment
                          ? 'Фиксированная,'
                          : 'Плавающая'}
                      </p>
                      {purchaseContract.paymentDeferment && (
                        <>
                          <input
                            style={{
                              width: '60px',
                              marginRight: '15px',
                              color: navyBlue,
                              fontSize: '1.15em'
                            }}
                            type="number"
                            value={this.state.paymentDeferment}
                            onChange={e => {
                              this.setState({
                                paymentDeferment: +e.currentTarget.value
                              });
                            }}
                          />
                          <p>дн.</p>
                        </>
                      )}

                      {this.state.paymentDeferment !==
                        purchaseContract.paymentDeferment && (
                        <Button
                          label="Сохранить"
                          onClick={() =>
                            this.props.patchPurchaseContractDebtor(
                              this.props.match.params.guid,
                              {
                                paymentDeferment: this.state.paymentDeferment
                              }
                            )
                          }
                          disabled={!this.state.paymentDeferment}
                          w="fit-content"
                          h="30px"
                        />
                      )}
                    </ContractsInfoItemContainer>
                  ) : (
                    <>
                      <ContractsInfoItemTitle>
                        Отсрочка платежа
                      </ContractsInfoItemTitle>
                      <ContractsInfoItemContainer style={{ height: '25px' }}>
                        {this.state.isPaymentDefermentFixed === true ? (
                          <SelectContainerActive>
                            <SelectActive />
                          </SelectContainerActive>
                        ) : (
                          <SelectContainerInactive
                            onClick={() =>
                              this.handlePaymentDefermentChange('active')
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactive>
                        )}
                        <p>фиксированная</p>
                        {this.state.isPaymentDefermentFixed && (
                          <>
                            <input
                              className="short-input"
                              type="number"
                              value={this.state.paymentDeferment}
                              onChange={e => {
                                this.setState({
                                  paymentDeferment: +e.currentTarget.value
                                });
                              }}
                            />
                            <p>дней</p>
                          </>
                        )}
                      </ContractsInfoItemContainer>
                      <ContractsInfoItemContainer style={{ height: '25px' }}>
                        {this.state.isPaymentDefermentFixed === false ? (
                          <SelectContainerActive>
                            <SelectActive />
                          </SelectContainerActive>
                        ) : (
                          <SelectContainerInactive
                            onClick={() =>
                              this.handlePaymentDefermentChange('inActive')
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactive>
                        )}
                        <p>плавающая</p>
                      </ContractsInfoItemContainer>
                    </>
                  )}

                  {this.state.isPaymentDefermentFixed !== null && (
                    <Button
                      label="Сохранить"
                      onClick={() =>
                        this.props.patchPurchaseContractDebtor(
                          this.props.match.params.guid,
                          {
                            paymentDeferment: this.state.paymentDeferment,
                            paymentDefermentFixed: this.state
                              .isPaymentDefermentFixed
                          }
                        )
                      }
                      disabled={
                        this.state.isPaymentDefermentFixed === true &&
                        !this.state.paymentDeferment
                      }
                    />
                  )}
                </ContractsInfoArea>

                <AccountContainer>
                  <h1>Счет плательщика:</h1>

                  {debtorContract.find(item => item.type === 1) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 1)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 1)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 1)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 1)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {debtorContract.find(item => item.type === 1)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Вы подтвердили счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 1)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Поставщик подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 1)
                          .confirmedBySupplier === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Поставщик еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 1)
                          .confirmedBySupplier === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Поставщик отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <Button
                      label="Добавить"
                      onClick={() => {
                        this.props.openModal({
                          name: MODAL_NAMES.PAYER_ACCOUNT
                        });
                        this.props.getBankAccount();
                      }}
                      w="fit-content"
                      h="30px"
                    />
                  )}
                </AccountContainer>

                <AccountContainer>
                  <h1>Счет получателя:</h1>

                  {debtorContract.find(item => item.type === 2) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 2)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 2)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 2)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              debtorContract.find(item => item.type === 2)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {debtorContract.find(item => item.type === 2)
                          .confirmedByDebtor !== 2 && (
                          <div>
                            <>
                              <Button
                                label="Подтвердить счет"
                                onClick={() =>
                                  this.props.postConfirmContractByDebtor(
                                    debtorContract.find(item => item.type === 2)
                                      .bankAccountGuid,
                                    this.props.match.params.guid,
                                    { type: 2, action: 'confirm' }
                                  )
                                }
                              />
                              <Button
                                label="Отклонить счет"
                                onClick={() =>
                                  this.props.postConfirmContractByDebtor(
                                    debtorContract.find(item => item.type === 2)
                                      .bankAccountGuid,
                                    this.props.match.params.guid,
                                    { type: 2, action: 'reject' }
                                  )
                                }
                              />
                            </>
                          </div>
                        )}

                        {debtorContract.find(item => item.type === 2)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Вы подтвердили счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 2)
                          .confirmedByDebtor === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Вы отклонили счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 2)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Поставщик подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 2)
                          .confirmedBySupplier === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Поставщик еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {debtorContract.find(item => item.type === 2)
                          .confirmedBySupplier === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Поставщик отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <p>Получатель не добавил реквизиты счета</p>
                  )}
                </AccountContainer>
              </ContractsTopLeftContainer>

              <AcceptContract>
                {(purchaseContract.confirmedByDebtor === false ||
                  purchaseContract.confirmedByDebtor === null) && (
                  <AcceptButton
                    onClick={() =>
                      this.props.putAcceptByDebtor(this.props.match.params.guid)
                    }
                  >
                    Подтвердить договор
                  </AcceptButton>
                )}
                {purchaseContract.confirmedByDebtor === null && (
                  <DeclineButton
                    onClick={() =>
                      this.props.putDeclineByDebtor(
                        this.props.match.params.guid
                      )
                    }
                  >
                    Отклонить договор
                  </DeclineButton>
                )}
              </AcceptContract>

              {purchaseContract.contraceFileIdentifyer && (
                <BtnsBlock>
                  {!purchaseContract.debtorSignatureFileName && (
                    <BtnsBlockSignBtn onClick={this.onSignClick}>
                      Подписать ЭЦП
                    </BtnsBlockSignBtn>
                  )}
                  <BtnsBlockDownloadDocument
                    href={SCFApi.downloadSupplyContractAgreementFile(
                      this.state.pageUrl
                    )}
                  >
                    Скачать документ
                  </BtnsBlockDownloadDocument>
                  {purchaseContract.supplierSignatureFileName && (
                    <BtnsBlockDownloadSignature>
                      <BtnsBlockDownloadSignatureTopContainer
                        href={SCFSupplierApi.downloadSupplierPurchaseContractSignatureFile(
                          this.state.pageUrl
                        )}
                      >
                        <p>Скачать подпись поставщика</p>
                        {/* <CaretIcon className='caret' id='firstCaretClosed' onClick={() => onFirstCaretClick(event)} /> */}
                      </BtnsBlockDownloadSignatureTopContainer>

                      {/* <BtnsBlockDownloadSignatureSubInfo id='firstInfoClosed'>{supplyContract.debtorInfo}</BtnsBlockDownloadSignatureSubInfo> */}
                    </BtnsBlockDownloadSignature>
                  )}
                  {purchaseContract.debtorSignatureFileName && (
                    <BtnsBlockDownloadSignature>
                      <BtnsBlockDownloadSignatureTopContainer
                        href={SCFDebtorApi.downloadDebtorPurchaseContractSignatureFile(
                          this.state.pageUrl
                        )}
                      >
                        <p>Скачать мою подпись</p>
                        {/* <CaretIcon className='caret' id='secondCaretClosed' onClick={() => onSecondCaretClick(event)} /> */}
                      </BtnsBlockDownloadSignatureTopContainer>
                      {/* <BtnsBlockDownloadSignatureSubInfo id='secondInfoClosed'>{supplyContract.supplierInfo}</BtnsBlockDownloadSignatureSubInfo> */}
                    </BtnsBlockDownloadSignature>
                  )}
                </BtnsBlock>
              )}
            </ContractsTopSection>
            {purchaseContract.contraceFileIdentifyer ? (
              <div
                id="documentObject"
                style={{ width: '900px', marginBottom: '30px' }}
              />
            ) : (
              <ContractInputFileContainer>
                <div>
                  <InputFile
                    label=""
                    name="registry"
                    required={false}
                    files={
                      this.state.filesDisplay !== null
                        ? this.state.filesDisplay
                        : undefined
                    }
                    multiple={true}
                    uploadStatus={documentsUploadStatus}
                    onFilesUpload={this.onFilesUpload}
                    inputFileTypesDescription="PDF"
                    inputFileFormatDescription="файл договора"
                    accept=".pdf"
                  />

                  {this.state.filesDisplay !== null ? (
                    <AddBtn onClick={this.sendContractDocument}>
                      <AddBtnText>Загрузить договор</AddBtnText>
                    </AddBtn>
                  ) : (
                    <AddBtn
                      style={{
                        backgroundColor: '#9FA6B2',
                        cursor: 'not-allowed'
                      }}
                    >
                      <AddBtnText>Загрузить договор</AddBtnText>
                    </AddBtn>
                  )}
                </div>
              </ContractInputFileContainer>
            )}

            <ContractsInfoArea>
              {counter.discounting === true &&
              documentFormBySupplyContract.isDefault === false ? (
                <DiscountingForm>
                  <h1>Форма для дисконтирования:</h1>
                  <div>
                    <p>{documentFormBySupplyContract.name}</p>
                    <a
                      download
                      onClick={() =>
                        this.props.getFormFile(
                          documentFormBySupplyContract.guid,
                          documentFormBySupplyContract.name
                        )
                      }
                    >
                      Скачать
                    </a>
                  </div>
                </DiscountingForm>
              ) : counter.discounting === true &&
              documentFormBySupplyContract.isDefault === true ? (
                <DiscountingForm>
                  <h1>Форма для дисконтирования:</h1>
                  <p>не выбрана, используется форма по умолчанию</p>
                  <div>
                    <p>{documentFormBySupplyContract.name}</p>
                    <a
                      download
                      onClick={() =>
                        this.props.getFormFile(
                          documentFormBySupplyContract.guid,
                          documentFormBySupplyContract.name
                        )
                      }
                    >
                      Скачать
                    </a>
                  </div>
                  <Button
                    label="Выбрать другую форму"
                    onClick={() =>
                      this.props.openModal({
                        name: MODAL_NAMES.FORM_OF_THE_DISCOUNT_AGREEMENT
                      })
                    }
                  />
                </DiscountingForm>
              ) : (
                statusDocumentFormBySupplyContract !== REQUEST_STATUSES.GOT &&
                errorDocumentFormBySupplyContract &&
                errorDocumentFormBySupplyContract.message &&
                !!errorDocumentFormBySupplyContract.message.length && (
                  <DiscountingForm>
                    <h1>Форма для дисконтирования:</h1>
                    <p>{errorDocumentFormBySupplyContract.message}</p>
                  </DiscountingForm>
                )
              )}
            </ContractsInfoArea>

            <CertificatesPopup id="certificatesPopup">
              <PopupExit>
                <p onClick={this.onCloseIconClick}>X</p>
              </PopupExit>
              <h1>Список сертификатов в системе</h1>
              <CertificatesArea>
                <CertificatesListPopup
                  guid={this.state.pageUrl}
                  pageUrl={this.state.pageUrl}
                />
              </CertificatesArea>
              <NudgeArea>
                <p>Нет нужного сертификата? </p>
                <Nudge onClick={this.onNudgeClick}>
                  Перейдите в настройки добаления
                </Nudge>
              </NudgeArea>
            </CertificatesPopup>
            <CertificatesPopupError id="certificatesPopupError">
              <PopupExitError>
                <p onClick={this.onCloseErrorIconClick}>X</p>
              </PopupExitError>
              <h1>
                Невозможно подписать выбранным сертификатом
                <br />
                (не найден на устройстве)
              </h1>
            </CertificatesPopupError>
          </ContractsContainer>
        )}

        <Modal name={MODAL_NAMES.FORM_OF_THE_DISCOUNT_AGREEMENT}>
          <DiscountingFormModal>
            <p>Выберите форму соглашения о дисконте</p>
            {this.props.documents.map(doc => (
              <label
                key={doc.guid}
                onClick={() => this.handleRadioBtn(doc.guid)}
              >
                <input
                  type="radio"
                  name="formDiscountAgreement"
                  checked={
                    this.state.selectedFormDiscountAgreement === doc.guid
                  }
                />
                <div>
                  <p>{doc.name}</p>
                  {doc.isMain && <p>Используется по умолчанию</p>}
                </div>
                <a
                  download
                  onClick={() =>
                    this.props.getFormFile(doc.guid, doc.exampleFilename)
                  }
                >
                  Скачать
                </a>
              </label>
            ))}

            <Button
              label={'Выбрать форму'}
              disabled={this.state.selectedFormDiscountAgreement === null}
              onClick={() => {
                this.props.closeModal();
                this.props.postAddFormToSupplyContracts({
                  DocumentFormGuid: this.state.selectedFormDiscountAgreement,
                  SupplyContractGuid: this.props.match.params.guid
                });
              }}
            />
          </DiscountingFormModal>
        </Modal>

        <Modal name={MODAL_NAMES.PAYER_ACCOUNT}>
          <DiscountingFormModal>
            <p>Выберите счет плательщика по договору</p>

            <ContractsInfoArea>
              <h1>Банковские счета:</h1>

              <TableStyledWrapper sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="65%">Номер счета</TableThStyled>
                    <TableThStyled width="35%">Банк</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {bankAccount.items.map(bank => (
                    <TableRowStyled
                      key={bank.id}
                      onClick={() => {
                        this.setState({ selectedPayerContract: bank.guid });
                      }}
                    >
                      <td>{bank.accountNumber}</td>
                      <td>{bank.bankName}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyledWrapper>
            </ContractsInfoArea>

            <Button
              label="+ Добавить"
              disabled={this.state.selectedPayerContract === null}
              onClick={() => {
                this.props.closeModal();
                this.setState({ selectedPayerContract: null });
                this.props.postAddToContractByDebtor(
                  this.state.selectedPayerContract,
                  this.props.match.params.guid
                );
              }}
            />

            <PayerContractDescription>
              Нет нужного счета?{' '}
              <span onClick={() => window.open(`/debtor/settings`, '_blank')}>
                Добавьте счет в настройках
              </span>
            </PayerContractDescription>
          </DiscountingFormModal>
        </Modal>
      </ContractsInfoPageStyled>
    );
  }
}

const mapStateToProps = ({ SCFDebtor, SCF }: STORE) => ({
  counter: SCFDebtor.getDebtorCounterSidebar.data,
  purchaseContract: SCF.getPurchaseContractByGuid.data,
  status: SCF.getPurchaseContractByGuid.status,
  error: SCF.getPurchaseContractByGuid.error,
  documentsUploadStatus: SCFDebtor.uploadDebtorContractDocument.status,
  statusAcceptByDebtor: SCFDebtor.putAcceptByDebtor.status,
  statusDeclineByDebtor: SCFDebtor.putDeclineByDebtor.status,
  documentFormBySupplyContract: SCFDebtor.getDocumentFormBySupplyContract.data,
  statusDocumentFormBySupplyContract:
    SCFDebtor.getDocumentFormBySupplyContract.status,
  errorDocumentFormBySupplyContract:
    SCFDebtor.getDocumentFormBySupplyContract.error,
  documents: SCFDebtor.getDocumentForms.data,
  statusDocuments: SCFDebtor.getDocumentForms.status,
  statusPostAddFormToSupplyContracts:
    SCFDebtor.postAddFormToSupplyContracts.status,
  statusPatchPurchaseContract: SCFDebtor.patchPurchaseContractDebtor.status,
  debtorContract: SCFDebtor.getContractByDebtor.data,
  statusDebtorContract: SCFDebtor.getContractByDebtor.status,
  bankAccount: SCF.getBankAccount.data,
  statusAddContract: SCFDebtor.postAddToContractByDebtor.status,
  statusConfirmContractDebtor: SCFDebtor.postConfirmContractByDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPurchaseContract,
      uploadContractDocument,
      putAcceptByDebtor,
      putDeclineByDebtor,
      getDocumentFormBySupplyContract,
      getFormFile,
      openModal,
      closeModal,
      getDocumentForms,
      postAddFormToSupplyContracts,
      patchPurchaseContractDebtor,
      getContractByDebtor,
      getBankAccount,
      postAddToContractByDebtor,
      postConfirmContractByDebtor
    },
    dispatch
  );

const ContractsInfoPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractsInfoPage)
);

export { ContractsInfoPageConnect as ContractsInfoPage };
