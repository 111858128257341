import styled, { css, keyframes } from 'styled-components';
import {
  rm,
  rr,
  lightBlue,
  blue,
  navyBlue,
  black
} from 'shared/styled';

export const LoaderStyled = styled.section`
  width: 100%;
  height: calc(100vh - 80px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: ${rr};
    font-size: 1.6rem;
    color: ${black};
  }
`;

const loaderSettings = [
  {
    width: 10,
    bg: blue
  },
  {
    width: 30,
    bg: lightBlue
  },
  {
    width: 5,
    bg: blue
  },
  {
    width: 25,
    bg: lightBlue
  },
  {
    width: 30,
    bg: blue
  }
];

const transform = keyframes`
  from {
    transform: translateX(250%) skew(-30deg);
  }

  to {
    transform: translateX(-250%) skew(-30deg);
  }
`;

export const LoadingIndicator = styled.div`
  width: 500px;
  height: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  position: relative;

  background-color: ${blue};
  border-radius: 3px;

  ${loaderSettings.map(
    (item, index) => css`
      & > .bar-${index + 1} {
        width: ${item.width}%;
        height: 100%;
        background-color: ${item.bg};
        animation: ${transform} 1s linear infinite;
      }
    `
  )};
`;
