import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import { got, err } from 'entities/SCF/Debtor/model/actions/uploadRegistries';
import {
  SagaRequestDataType,
  UPLOAD_REGISTRIES
} from 'entities/SCF/Debtor/model/reducers/uploadRegistries';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* uploadRegistries(action: {
  type: UPLOAD_REGISTRIES;
  data: SagaRequestDataType;
}) {
  const updateRegistries = (registry, newRegestry) =>
    registry.map(registry => {
      registry.registry = [...registry.registry, ...newRegestry];
      return registry;
    });

  try {
    const { files, ...apiRequestData } = action.data;

    const newRegestry = yield all(
      files.map(function*(file) {
        const formData = new FormData();
        formData.append('file', file);

        const requestData = {
          ...apiRequestData,
          metaInfo: file.metaInfo,
          file: formData
        };

        return yield call(SCFDebtorApi.uploadRegistries, requestData);
      })
    );

    const registries = yield select(
      ({ Application }: STORE) => Application.setInitializationFormData.registry
    );

    yield put(
      setInitializationFormData({
        registry: updateRegistries(registries, registries)
      })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* uploadRegistriesSaga() {
  yield takeLatest(UPLOAD_REGISTRIES.REQ, uploadRegistries);
}
