import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getOneDiscountAuction';
import { GET_ONE_DISCOUNT_AUCTION } from '../reducers/getOneDiscountAuction';
import SCFApi from '../api';

function* getOneDiscountAuction(action: {
  type: GET_ONE_DISCOUNT_AUCTION;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFApi.getOneDiscountAuction, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOneDiscountAuctionSaga() {
  yield takeLatest(GET_ONE_DISCOUNT_AUCTION.REQ, getOneDiscountAuction);
}
