import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getBankAccountList';

import { GET_BANK_ACCOUNT_LIST } from '../reducers/getBankAccountList';
import BankAccountApi from '../api';

function* getBankAccountList(action: {
  type: GET_BANK_ACCOUNT_LIST;
}): Generator {
  try {
    let res: any;
    res = yield call(BankAccountApi.getBankAccountList);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* BankAccountListSaga(): Generator {
  yield takeLatest(GET_BANK_ACCOUNT_LIST.REQ, getBankAccountList);
}
