import styled from 'styled-components';
import { rr } from 'shared/styled';

export const ApiKeyBlockStyled = styled.div`
  display: flex;
  & > * {
    margin: 15px;
  }
`;

export const TitleContainer = styled.div`
  margin: 30px 0 20px 0;

  h2 {
    font-family: ${rr};
  }

  p {
    width: 65%;
  }
`;
