import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchCompanyClientStoreState extends Reducer {}

export enum PATCH_COMPANY_CLIENT {
  REQ = 'PATCH_COMPANY_CLIENT_REQ',
  GOT = 'PATCH_COMPANY_CLIENT_GOT',
  ERR = 'PATCH_COMPANY_CLIENT_ERR'
}

export const initialState: PatchCompanyClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCompanyClient = (
  state: PatchCompanyClientStoreState = initialState,
  action: {
    type: PATCH_COMPANY_CLIENT;
    error: {};
  }
): PatchCompanyClientStoreState => {
  switch (action.type) {
    case PATCH_COMPANY_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_COMPANY_CLIENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_COMPANY_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchCompanyClient;
