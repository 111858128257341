import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getCompaniesListById';
import { GET_COMPANIES_LIST_BY_ID_CRM } from '../reducers/getCompaniesListById';
import CompaniesApi from '../api';

function* getCompaniesListByIdCRM(action: {
  type: GET_COMPANIES_LIST_BY_ID_CRM;
  id: string;
}): Generator {
  try {
    let res;
    res = yield call(CompaniesApi.getCompaniesListById, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesListByIdCRMSaga(): Generator {
  yield takeLatest(GET_COMPANIES_LIST_BY_ID_CRM.REQ, getCompaniesListByIdCRM);
}
