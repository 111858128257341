import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostFactoringSettingsSignatureStoreState = Reducer;

export enum POST_FACTORING_SETTINGS_SIGNATURE {
  REQ = 'POST_FACTORING_SETTINGS_SIGNATURE_REQ',
  GOT = 'POST_FACTORING_SETTINGS_SIGNATURE_GOT',
  ERR = 'POST_FACTORING_SETTINGS_SIGNATURE_ERR'
}

export interface RequestDataType {
  guid: string;
  request: string;
  signature: string;
}

export const initialState: PostFactoringSettingsSignatureStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFactoringSettingsSignature = (
  state: PostFactoringSettingsSignatureStoreState = initialState,
  action: {
    type: POST_FACTORING_SETTINGS_SIGNATURE;
    error: {};
  }
): PostFactoringSettingsSignatureStoreState => {
  switch (action.type) {
    case POST_FACTORING_SETTINGS_SIGNATURE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FACTORING_SETTINGS_SIGNATURE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_FACTORING_SETTINGS_SIGNATURE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postFactoringSettingsSignature;
