import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { req as getDebtorNotificationSettings } from 'entities/SCF/Debtor/model/actions/getDebtorNotificationSettings';
import { RequestDataType as debtorSubscriptionsProps } from 'entities/SCF/Debtor/model/reducers/postDebtorNotificationSettings';
import {
  req as sendDebtorNotificationSettings,
  reset as debtorNotificationSettingsReset
} from 'entities/SCF/Debtor/model/actions/postDebtorNotificationSettings';
import SubscriptionsHeader from './SubscriptionsHeader';
import {
  SubscriptionsSettingsBlock,
  SubscriptionsSettingsRow,
  SubscriptionsSettingsTitle,
  SubscriptionsSettingsMarginTitle
} from '../styles';
import {
  SwitcherContainer,
  ChangableOffActive,
  ChangableOffInactive,
  ChangableOnActive,
  ChangableOnInactive
} from 'src/features/SCF/UI/Switcher';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

const SubscriptionsEditDebtor = ({
  getDebtorNotificationSettings,
  sendDebtorNotificationSettings,
  debtorNotificationSettings,
  postDebtorNotificationSettingsStatus,
  getDebtorNotificationSettingsStatus,
  debtorNotificationSettingsReset
}) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getDebtorNotificationSettings());

      return () => {
        debtorNotificationSettingsReset();
      };
    },
    [dispatch, getDebtorNotificationSettings, debtorNotificationSettingsReset]
  );

  useEffect(
    () => {
      if (postDebtorNotificationSettingsStatus === REQUEST_STATUSES.GOT) {
        history.push('/debtor/settings/subscriptions');
      }
    },
    [postDebtorNotificationSettingsStatus]
  );

  const [subscriptionsState, setSubscriptionsState] = useState<
    debtorSubscriptionsProps
  >({
    newBuyerToSupplierLinkEvent:
      debtorNotificationSettings.newBuyerToSupplierLinkEvent,
    newSupplyContractLinkEvent:
      debtorNotificationSettings.newSupplyContractLinkEvent,
    newEarlyPaymentApplicationEvent:
      debtorNotificationSettings.newEarlyPaymentApplicationEvent,
    newApprovedEpaEvent: debtorNotificationSettings.newApprovedEpaEvent,
    newSignedEpaEvent: debtorNotificationSettings.newSignedEpaEvent,
    getSignedEpaDocuments: debtorNotificationSettings.getSignedEpaDocuments
  });

  const saveClick = (e: Event) => {
    sendDebtorNotificationSettings(subscriptionsState);
  };

  return (
    <PageNoMarginStyled>
      <SubscriptionsHeader onSaveClick={(e: Event) => saveClick(e)} />
      {getDebtorNotificationSettingsStatus === REQUEST_STATUSES.REQUEST && (
        <Loader />
      )}
      {getDebtorNotificationSettingsStatus === REQUEST_STATUSES.GOT && (
        <SubscriptionsSettingsBlock>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Поставщик добавил вас как покупателя
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newBuyerToSupplierLinkEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newBuyerToSupplierLinkEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newBuyerToSupplierLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Поставщик добавил договор с вами
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newSupplyContractLinkEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newSupplyContractLinkEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSupplyContractLinkEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Постащик подал вам заявку на раннюю оплату
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newEarlyPaymentApplicationEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newEarlyPaymentApplicationEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newEarlyPaymentApplicationEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newEarlyPaymentApplicationEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newEarlyPaymentApplicationEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newEarlyPaymentApplicationEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Заявка на раннюю оплату согласована вашим сотрудником
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newApprovedEpaEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newApprovedEpaEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newApprovedEpaEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newApprovedEpaEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newApprovedEpaEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newApprovedEpaEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Заявка на раннюю оплату подписана
            </SubscriptionsSettingsTitle>
            {subscriptionsState.newSignedEpaEvent === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSignedEpaEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.newSignedEpaEvent === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSignedEpaEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSignedEpaEvent: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      newSignedEpaEvent: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsMarginTitle>
              получить вместе с документами
            </SubscriptionsSettingsMarginTitle>
            {subscriptionsState.getSignedEpaDocuments === true ? (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      getSignedEpaDocuments: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnActive>
                  <p>ВКЛ</p>
                </ChangableOnActive>
              </SwitcherContainer>
            ) : subscriptionsState.getSignedEpaDocuments === false ? (
              <SwitcherContainer>
                <ChangableOffActive>
                  <p>ВЫКЛ</p>
                </ChangableOffActive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      getSignedEpaDocuments: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <ChangableOffInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      getSignedEpaDocuments: false
                    })
                  }
                >
                  <p>ВЫКЛ</p>
                </ChangableOffInactive>
                <ChangableOnInactive
                  onClick={() =>
                    setSubscriptionsState({
                      ...subscriptionsState,
                      getSignedEpaDocuments: true
                    })
                  }
                >
                  <p>ВКЛ</p>
                </ChangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
        </SubscriptionsSettingsBlock>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  debtorNotificationSettings: SCFDebtor.getDebtorNotificationSettings.data,
  postDebtorNotificationSettingsStatus:
    SCFDebtor.postDebtorNotificationSettings.status,
  getDebtorNotificationSettingsStatus:
    SCFDebtor.getDebtorNotificationSettings.status
});

const mapDispatchToProps = {
  getDebtorNotificationSettings: getDebtorNotificationSettings,
  sendDebtorNotificationSettings: sendDebtorNotificationSettings,
  debtorNotificationSettingsReset: debtorNotificationSettingsReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsEditDebtor);
