import styled from 'styled-components';
import { lighterBlue, lightGray, rr, yellow } from 'shared/styled';

export const RoleBlock = styled.div`
  width: fit-content;
  background-color: ${({ admin }) => (admin ? yellow : lighterBlue)};
  border-radius: 5px;
  padding: 10px 20px;
`;

export const PhoneBlock = styled.div`
  font-style: ${({ phone }) => !phone && 'italic'};
`;

export const TDEmailStyle = styled.td`
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible !important;

  td {
    font-family: ${rr} !important;
  }
`;

export const CheckboxStyleEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : 'orange')};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    right: 24px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;
