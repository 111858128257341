import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Limit } from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';

export interface GetFactoringFeesOfLimitsStoreState extends Reducer {
  data: Limit[];
}

export enum GET_FACTORING_FEES_OF_LIMITS {
  REQ = 'GET_FACTORING_FEES_OF_LIMITS_REQ',
  GOT = 'GET_FACTORING_FEES_OF_LIMITS_GOT',
  ERR = 'GET_FACTORING_FEES_OF_LIMITS_ERR'
}

export const initialState: GetFactoringFeesOfLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getFactoringFeesOfLimits = (
  state: GetFactoringFeesOfLimitsStoreState = initialState,
  action: {
    type: GET_FACTORING_FEES_OF_LIMITS;
    data: Limit[];
    error: {};
  }
): GetFactoringFeesOfLimitsStoreState => {
  switch (action.type) {
    case GET_FACTORING_FEES_OF_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_FEES_OF_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_FEES_OF_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactoringFeesOfLimits;
