import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendFactoringSettingsByDebtorInn';

import { SEND_FACTORING_SETTINGS_BY_DEBTOR_INN } from '../reducers/sendFactoringSettingsByDebtorInn';
import SCFApi from '../api';
import {
  FactoringSettingsByDebtorInn,
  resetFactoringSettingsByDebtorInn
} from '../actions/setFactoringSettingsByDebtorInn';

function* sendFactoringSettingsByDebtorInn(action: {
  type: SEND_FACTORING_SETTINGS_BY_DEBTOR_INN;
  data: FactoringSettingsByDebtorInn;
}): Generator {
  try {
    const res: any = yield call(
      SCFApi.sendFactoringSettingsByDebtorInn,
      action.data
    );
    yield put(got(res));
    yield put(resetFactoringSettingsByDebtorInn());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendFactoringSettingsByDebtorInnSaga(): Generator {
  yield takeLatest(
    SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.REQ,
    sendFactoringSettingsByDebtorInn
  );
}
