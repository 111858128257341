import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/confirmExternalAgent';
import { CONFIRM_EXTERNAL_AGENT } from '../reducers/confirmExternalAgent';
import ManageExternalAgentsApi from '../api';

function* confirmExternalAgent(action: {
  type: CONFIRM_EXTERNAL_AGENT;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(ManageExternalAgentsApi.confirmExternalAgent, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* confirmExternalAgentSaga() {
  yield takeLatest(
    CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_REQ,
    confirmExternalAgent
  );
}
