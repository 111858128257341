import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { format, parse } from 'date-fns';

import { history } from 'src/shared/utils/History';
import { InputDate } from 'shared/ui/InputDate';
import { Loader, Pagination } from 'Layouts/components';
import {
  ListSection,
  DownloadLink,
  InnContainer,
  InnFilter,
  FilterArea,
  DateFilterStyled,
  DatesContainer,
  Dates
} from './styles';
import { PaginationWrapper, PageStyled } from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as getFactorRegistryFiles,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getFactorRegistryFiles';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'entities/SCF/Factor/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factorRegistryFiles: ResponseDataType;
}

interface DispatchToProps {
  getFactorRegistryFiles: (data: RequestDataType) => void;
}

interface State {
  dateFrom: string;
  dateUntil: string;
  pageUrl: Href;
  fileName: string;
  debtorInnToFilter: string;
  downloadChecker: number;
}

type Props = StateToProps & DispatchToProps;

class RegistriesList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        downloadChecker: 0,
        debtorInnToFilter: '',
        dateFrom: format(
          parse('01/01/2023', 'dd/MM/yyyy', new Date()),
          'dd/MM/yyyy'
        ),
        dateUntil: format(new Date(), 'dd/MM/yyyy'),
        pageUrl: '',
        fileName: null
      };
      this.handleDateFromChange = this.handleDateFromChange.bind(this);
      this.handleDateUntilChange = this.handleDateUntilChange.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleDebtorInnToFilterChange = this.handleDebtorInnToFilterChange.bind(
        this
      );
    }
  }

  handleDebtorInnToFilterChange(event) {
    this.setState({ debtorInnToFilter: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  onDownloadClick = async e => {
    await this.delay(1000);
    let check = this.state.downloadChecker;
    this.setState({ downloadChecker: check + 1 });
  };

  handleDateFromChange(date: string) {
    this.setState({ dateFrom: date });
  }

  handleDateUntilChange(date: string) {
    this.setState({ dateUntil: date });
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  initFetch = () => {
    const { getFactorRegistryFiles } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getFactorRegistryFiles({
      page,
      pageSize: 20,
      dateFrom: this.state.dateFrom,
      dateUntil: this.state.dateUntil,
      debtorInn: this.state.debtorInnToFilter
    });
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.dateFrom !== prevState.dateFrom ||
      this.state.dateUntil !== prevState.dateUntil ||
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.debtorInnToFilter !== prevState.debtorInnToFilter ||
      this.state.downloadChecker !== prevState.downloadChecker
    ) {
      this.initFetch();
    }
  }

  render() {
    const { status, factorRegistryFiles } = this.props;
    return (
      <PageStyled>
        <h2>Мои реестры</h2>
        <FilterArea>
          <InnFilter>
            <p>ИНН Дебитора</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
          <DateFilterStyled>
            <p>Дата добавления:</p>
            <Dates>
              <DatesContainer>
                <p>с</p>
                <InputDate
                  onDateChange={this.handleDateFromChange}
                  dateValue={this.state.dateFrom}
                  placeholder="c"
                />
              </DatesContainer>
              <DatesContainer>
                <p>по</p>
                <InputDate
                  onDateChange={this.handleDateUntilChange}
                  dateValue={this.state.dateUntil}
                  placeholder="по"
                />
              </DatesContainer>
            </Dates>
          </DateFilterStyled>
        </FilterArea>
        <ListSection>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="10%">
                    ИНН ДЕБИТОРА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    НАИМЕНОВАНИЕ ДЕБИТОРА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="15%">
                    РЕЕСТР
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="15%">
                    ПОДПИСЬ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    ДАТА РЕЕСТРА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ПОДПИСАНТ ДЕБИТОРА
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {factorRegistryFiles.items.map((item, key) => (
                  <TableRowStyled key={key}>
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        {item.debtorInn}
                      </td>
                    ) : (
                      <td>{item.debtorInn}</td>
                    )}
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        {item.debtorCompanyName}
                      </td>
                    ) : (
                      <td>{item.debtorCompanyName}</td>
                    )}
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        <DownloadLink
                          href={SCFApi.downloadFactorRegistry(item.id)}
                          onClick={e => this.onDownloadClick(e)}
                        />{' '}
                        {item.registryFilename}
                      </td>
                    ) : (
                      <td>
                        <DownloadLink
                          href={SCFApi.downloadFactorRegistry(item.id)}
                          onClick={e => this.onDownloadClick(e)}
                        />{' '}
                        {item.registryFilename}
                      </td>
                    )}
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        <DownloadLink
                          href={SCFApi.downloadFactorSignature(item.id)}
                        />{' '}
                        {item.signatureFilename}
                      </td>
                    ) : (
                      <td>
                        <DownloadLink
                          href={SCFApi.downloadFactorSignature(item.id)}
                        />{' '}
                        {item.signatureFilename}
                      </td>
                    )}
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        {format(new Date(item.createdDateTime), 'dd.MM.yyyy')}
                      </td>
                    ) : (
                      <td>
                        {format(new Date(item.createdDateTime), 'dd.MM.yyyy')}
                      </td>
                    )}
                    {item.isNew === true ? (
                      <td style={{ backgroundColor: 'rgba(241, 248, 255, 1)' }}>
                        {item.debtorSignatureName}
                      </td>
                    ) : (
                      <td>{item.debtorSignatureName}</td>
                    )}
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          )}
          <PaginationWrapper onClick={this.handlePageChange}>
            <Pagination list={this.props.factorRegistryFiles} />
          </PaginationWrapper>
        </ListSection>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  factorRegistryFiles: SCFFactor.getFactorRegistryFiles.data,
  status: SCFFactor.getFactorRegistryFiles.status,
  error: SCFFactor.getFactorRegistryFiles.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactorRegistryFiles
    },
    dispatch
  );

const RegistriesListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RegistriesList);

export { RegistriesListConnect as RegistriesList };
