import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getStartRateDebtorRole';

export interface GetStartRateDebtorRoleStoreState extends Reducer {
  data: ResponseDataType;
}

export enum START_RATE_DEBTOR_ROLE {
  REQ = 'START_RATE_DEBTOR_ROLE_REQ',
  GOT = 'START_RATE_DEBTOR_ROLE_GOT',
  ERR = 'START_RATE_DEBTOR_ROLE_ERR'
}

const initialState: GetStartRateDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    regData: null,
    bankAccountsData: null,
    certificateData: null,
    subscriptionsData: null
  },
  error: {}
};

const getStartRateDebtorRole = (
  state: GetStartRateDebtorRoleStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetStartRateDebtorRoleStoreState => {
  switch (action.type) {
    case START_RATE_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case START_RATE_DEBTOR_ROLE.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case START_RATE_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getStartRateDebtorRole;
