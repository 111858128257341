import * as React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import { COMMENT_TYPES } from 'Comments/types';

import { InputFileProps, inputFileDefaultProps } from 'shared/ui/InputFile';
import {
  IPostReply,
  CommentsContextType,
  CommentsContext,
  AttachedDocumentsBlock
} from 'Comments/components';

import { AttachFileButton, SendMessageButton } from 'shared/styled';
import { DropArea } from '../styles';
import {
  AddReplyContainer,
  AddReplyBlockStyled,
  ReplyTextarea,
  ActionButtonsBlock,
  AddReplyLoaderBar
} from './styles';

type State = Partial<IPostReply>;

interface OwnProps extends Partial<InputFileProps> {
  type: COMMENT_TYPES;
  parentId: number;
}

interface StateToProps {
  postReplyStatus: REQUEST_STATUSES;
}

type Props = OwnProps & StateToProps;

const defaultProps = {
  ...inputFileDefaultProps,
  name: 'add-reply-textarea'
};

const initialState = {
  files: [],
  comment: ''
};

const AddReplyBlock: React.FC<Props> = ({
  accept,
  maxSize,
  multiple,
  name,
  postReplyStatus,
  parentId,
  type
}) => {
  const { postReply }: CommentsContextType = React.useContext(CommentsContext);

  // add generic State type and define why it's warning
  const [state, setState] = React.useState<any>(initialState);

  const onChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) =>
    setState(prevState => ({ ...prevState, comment: currentTarget.value }));

  const onFilesUpload = files => {
    setState(prevState => ({
      ...prevState,
      files: [...state.files, ...files]
    }));
  };

  const postReplyHandler = () => postReply({ ...state, type, parentId });

  React.useEffect(
    () => {
      setState(prevState => ({ ...initialState }));
    },
    [postReplyStatus === REQUEST_STATUSES.GOT]
  );

  return (
    <AddReplyContainer>
      <Dropzone
        disableClick
        accept={accept}
        maxSize={maxSize}
        multiple={multiple}
        onDrop={onFilesUpload}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          open
        }) => (
          <DropArea {...getRootProps()}>
            <AddReplyBlockStyled
              isEmpty={state.files.length === 0}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            >
              <input {...getInputProps()} />

              <ReplyTextarea
                name={name}
                value={state.comment}
                onChange={onChange}
                placeholder="Напишите сообщение"
              >
                <ActionButtonsBlock>
                  <AttachFileButton
                    title="Прикрепить файл"
                    size={30}
                    onClick={open}
                  />
                  <SendMessageButton
                    size={30}
                    onClick={() => !!state.comment && postReplyHandler()}
                    disabled={state.comment === ''}
                    title={
                      !!state.comment
                        ? 'Нельзя отправить пустой комментарий'
                        : ''
                    }
                  />
                </ActionButtonsBlock>

                <AddReplyLoaderBar
                  status={postReplyStatus}
                  processText="Отправка сообщения..."
                />
              </ReplyTextarea>

              <AttachedDocumentsBlock documents={state.files} />
            </AddReplyBlockStyled>
          </DropArea>
        )}
      </Dropzone>
    </AddReplyContainer>
  );
};

AddReplyBlock.defaultProps = defaultProps;

const mapStateToProps = ({ Comment }: STORE) => ({
  postReplyStatus: Comment.postReply.status
});

const AddReplyBlockConnect = connect<StateToProps>(mapStateToProps)(
  AddReplyBlock
);

export { AddReplyBlockConnect as AddReplyBlock };
