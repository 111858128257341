import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/Client/model/actions/postTenderByClient';

import { POST_TENDER_BY_CLIENT } from 'entities/Cabinet/Client/model/reducers/postTenderByClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* postTenderByClient(action: {
  type: POST_TENDER_BY_CLIENT;
  data: RequestData;
}): Generator {
  try {
    yield call(ClientRoleApi.postTenderByClient, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postTenderByClientSaga(): Generator {
  yield takeLatest(POST_TENDER_BY_CLIENT.REQ, postTenderByClient);
}
