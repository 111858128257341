import {
  RequestDataType,
  SEND_APPLICATION_DATA
} from 'Application/reducers/sendApplicationData';

export const req = (data: RequestDataType) => ({
  type: SEND_APPLICATION_DATA.REQ,
  data
});

export const got = () => ({
  type: SEND_APPLICATION_DATA.GOT
});

export const err = (error: {}) => ({
  type: SEND_APPLICATION_DATA.ERR,
  error
});

export const reset = () => ({
  type: SEND_APPLICATION_DATA.RESET
});
