import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Debtor/model/actions/putRejectDebtor';

import { PUT_REJECT_DEBTOR } from 'entities/SCF/Debtor/model/reducers/putRejectDebtor';
import SCFApi from 'entities/SCF/Debtor/api';

function* putRejectDebtor(action: { type: PUT_REJECT_DEBTOR; inn: string }) {
  try {
    yield call(SCFApi.putRejectDebtor, action.inn);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putRejectDebtorSaga() {
  yield takeLatest(PUT_REJECT_DEBTOR.REQ, putRejectDebtor);
}
