import styled from 'styled-components';

import { CompanyTabHeaderItem } from 'BorrowerCompanies/components/BorrowerCompaniesTabs/styles';
import { blue, lighterBlue, rl } from 'shared/styled';

export const ViewCompanyTabHeaderItem = styled(CompanyTabHeaderItem)`
  flex: none;
  background: ${({ selected }) => (selected ? blue : lighterBlue)};
  transition: opacity 0.2s;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.85;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    row-gap: 3px;

    p {
      font-family: ${rl};
      color: ${({ selected }) => (selected ? 'white' : 'black')};
      margin: 0;
    }
  }
`;

export const CompanyName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
