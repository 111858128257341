export enum SUBJECT_POLICY {
  '3 - Клиент должен быть субъектом МСП' = '3 - Клиент должен быть субъектом МСП',
  '2 - для любых клиентов' = '2 - для любых клиентов',
  '1 - Клиент не должен быть субъектом МСП' = '1 - Клиент не должен быть субъектом МСП'
}

export const tabData = [
  {
    title: 'Центральный Федеральный Округ',
    cities: [
      { id: '77', city: 'г. Москва' },
      { id: '31', city: 'Белгородская область' },
      { id: '32', city: 'Брянская область' },
      { id: '33', city: 'Владимирская область' },
      { id: '36', city: 'Воронежская область' },
      { id: '37', city: 'Ивановская область' },
      { id: '40', city: 'Калужская область' },
      { id: '44', city: 'Костромская область' },
      { id: '46', city: 'Курская область' },
      { id: '48', city: 'Липецкая область' },
      { id: '50', city: 'Московская область' },
      { id: '57', city: 'Орловская область' },
      { id: '62', city: 'Рязанская область' },
      { id: '67', city: 'Смоленская область' },
      { id: '68', city: 'Тамбовская область' },
      { id: '69', city: 'Тверская область' },
      { id: '71', city: 'Тульская область' },
      { id: '76', city: 'Ярославская область' }
    ]
  },
  {
    title: 'Северо-Западный Федеральный округ',
    cities: [
      { id: '78', city: 'г. Санкт-Петербург' },
      { id: '10', city: 'Республика Карелия' },
      { id: '11', city: 'Республика Коми' },
      { id: '29', city: 'Архангельская область' },
      { id: '35', city: 'Вологодская область' },
      { id: '39', city: 'Калининградская область' },
      { id: '47', city: 'Ленинградская область' },
      { id: '51', city: 'Мурманская область' },
      { id: '53', city: 'Новгородская область' },
      { id: '60', city: 'Псковская область' },
      { id: '83', city: 'Ненецкий Автономный округ' }
    ]
  },
  {
    title: 'Южный Федеральный округ',
    cities: [
      { id: '92', city: 'г. Севастополь' },
      { id: '82', city: 'Республика Крым' },
      { id: '81', city: 'Луганская Народная Республика' },
      { id: '80', city: 'Донецкая Народная Республика' },
      { id: '85', city: 'Запорожская область' },
      { id: '84', city: 'Херсонская область' },
      { id: '01', city: 'Республика Адыгея' },
      { id: '08', city: 'Республика Калмыкия' },
      { id: '23', city: 'Краснодарский край' },
      { id: '29', city: 'Астраханская область' },
      { id: '34', city: 'Волгоградская область' },
      { id: '61', city: 'Ростовская область' }
    ]
  },
  {
    title: 'Северо-Кавказский Федеральный округ',
    cities: [
      { id: '05', city: 'Республика Дагестан' },
      { id: '06', city: 'Республика Ингушетия' },
      { id: '07', city: 'Кабардино-Балкарская Республика' },
      { id: '09', city: 'Карачаево-Черкесская Республика' },
      { id: '15', city: 'Республика Северная Осетия — Алания' },
      { id: '20', city: 'Чеченская Республика' },
      { id: '26', city: 'Ставропольский край' }
    ]
  },
  {
    title: 'Приволжский Федеральный округ',
    cities: [
      { id: '02', city: 'Республика Башкортостан' },
      { id: '12', city: 'Республика Марий Эл' },
      { id: '13', city: 'Республика Мордовия' },
      { id: '16', city: 'Республика Татарстан' },
      { id: '18', city: 'Удмуртская Республика' },
      { id: '21', city: 'Чувашская Республика' },
      { id: '59', city: 'Пермский край' },
      { id: '43', city: 'Кировская область' },
      { id: '52', city: 'Нижегородская область' },
      { id: '56', city: 'Оренбургская область' },
      { id: '58', city: 'Пензенская область' },
      { id: '63', city: 'Самарская область' },
      { id: '64', city: 'Саратовская область' },
      { id: '73', city: 'Ульяновская область' }
    ]
  },
  {
    title: 'Уральский Федеральный округ',
    cities: [
      { id: '45', city: 'Курганская область' },
      { id: '66', city: 'Свердловская область' },
      { id: '72', city: 'Тюменская область' },
      { id: '74', city: 'Челябинская область' },
      { id: '86', city: 'Ханты-Мансийский автономный округ — Югра' },
      { id: '89', city: 'Ямало-Ненецкий автономный округ' }
    ]
  },
  {
    title: 'Сибирский Федеральный округ',
    cities: [
      { id: '04', city: 'Республика Алтай' },
      { id: '17', city: 'Республика Тыва' },
      { id: '19', city: 'Республика Хакасия' },
      { id: '22', city: 'Алтайский край' },
      { id: '24', city: 'Красноярский край' },
      { id: '38', city: 'Иркутская область' },
      { id: '42', city: 'Кемеровская область — Кузбасс' },
      { id: '54', city: 'Новосибирская область' },
      { id: '55', city: 'Омская область' },
      { id: '70', city: 'Томская область' }
    ]
  },
  {
    title: 'Дальневосточный Федеральный округ',
    cities: [
      { id: '03', city: 'Республика Бурятия' },
      { id: '14', city: 'Республика Саха (Якутия)' },
      { id: '75', city: 'Забайкальский край' },
      { id: '41', city: 'Камчатский край' },
      { id: '25', city: 'Приморский край' },
      { id: '27', city: 'Хабаровский край' },
      { id: '28', city: 'Амурская область' },
      { id: '49', city: 'Магаданская область' },
      { id: '65', city: 'Сахалинская область' },
      { id: '79', city: 'Еврейская автономная область' },
      { id: '87', city: 'Чукотский автономный округ' }
    ]
  }
];
