import { GET_INTERNAL_COUNTER_SIDEBAR } from 'src/features/SCF/reducers/getInternalCounterSidebar';

export interface ResponseData {
  supplyContractsQty: number;
  newSupplyContractsQty: number;
  epaQty: number;
  epaInWorkQty: number;
  buyersToSuppliersLinksQty: number;
  newBuyersToSuppliersLinksQty: number;
  debtorUsersQty: number;
  newDebtorUsersQty: number;
  factorUsersQty: number;
  newFactorUsersQty: number;
  supplierUsersQty: number;
  registriesQty: number;
  auctionsQty: number;
  activeAuctionsQty: number;
  emptyAuctionsQty: number;
  monetaryClaimsQty: number;
  activeMonetaryClaimsQty: number;
}

export const req = () => ({
  type: GET_INTERNAL_COUNTER_SIDEBAR.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_INTERNAL_COUNTER_SIDEBAR.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INTERNAL_COUNTER_SIDEBAR.ERR,
  error
});
