import styled from 'styled-components';
import {
  lighterBlue,
  lightGray,
  rr,
  blue,
  darkBlue,
  rl,
  black
} from 'shared/styled';

export const DiscountingSettingsPageStyled = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const ContractsHeader = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
  }
  h2 {
    font-size: 24px;
    font-family: ${rr};
  }
`;

export const ContractsContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
  }
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 110px;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InnArea = styled.div`
  width: 800px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
`;

export const InnFilter = styled.div`
  width: 370px;
  height: auto;
  margin-top: 10px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 20px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0 20px 0 0;
  }
`;

export const ContractsInfoArea = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-size: 18px;
    font-family: ${rr};
  }
`;

export const ContractsInfoItemContainer = styled.div`
  width: auto;
  min-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-family: ${rr};
    font-size: 16px;
    margin-right: 20px;
    margin: 0 20px 0 0;
  }
  input {
    padding: 10px 10px;
    height: 30px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const ContractsInfoItemLink = styled.p`
  cursor: pointer;
  font-family: ${rr};
  font-size: 16px;
  color: ${blue};
  margin-right: 20px;
  margin: 0 20px 0 0;
  &:hover {
    color: ${darkBlue};
  }
`;

export const ContractsInfoItemTitle = styled.p`
  font-family: ${rr};
  font-size: 1.3em;
  width: 350px;
`;

export const TypeContainer = styled.div`
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 5px;
`;

export const TypeNoActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f53737;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #f53737;
  border-left: 1px solid #f53737;
  border-bottom: 1px solid #f53737;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
    margin: 9px 0 0 0;
  }
`;

export const TypeYesInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
    margin: 9px 0 0 0;
  }
`;

export const TypeYesActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #1cca94;
  border-right: 1px solid #1cca94;
  border-bottom: 1px solid #1cca94;
  background-color: #1cca94;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
    margin: 9px 0 0 0;
  }
`;

export const TypeNoInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
    margin: 9px 0 0 0;
  }
`;

export const MovingContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${blue};
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  transition: 0.3s;
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const EditBtn = styled.div`
  margin-left: 50px;
  width: auto;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const EditBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 7px 14px;
  color: #fff;
  margin: 0;
`;

export const HistoryBtn = styled.div`
  display: none;
  margin-left: 150px;
  width: auto;
  height: auto;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const HistoryBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 7px 14px;
  color: #3f4e65;
  margin: 0;
`;

export const NullBtn = styled.div`
  margin-top: 4px;
  margin-left: 50px;
  width: 103px;
  height: auto;
  display: block;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const NullBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 10px;
  padding: 5px 10px;
  color: #fff;
  margin: 0;
`;

export const NullBtnHandler = styled.div`
  width: 100%;
  height: 100%;
`;
export const DaysContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: 900;
    color: #000;
  }
`;

export const DaysItemLeft = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: 1px solid ${lightGray};
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemLeftActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;

export const DaysItemRight = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: 1px solid ${lightGray};
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemRightActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;

export const DaysItem = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${lightGray};
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;

export const InfoHover = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  div {
    display: none;
    position: absolute;
    width: 500px;
  }
  p {
    font-family: ${rl};
    font-size: 1.1em;
    margin-left: 20px;
    padding: 10px;
    color: ${black};
  }
  &:hover {
    div {
      display: flex;
    }
  }
`;

export const HeaderWithHover = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: 18px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1) !important;
    margin: 0 !important;
  }
`;

export const FormOfTheAgreement = styled.div`
  width: 100%;
  min-height: 150px;

  h1 {
    font-size: 18px;
    font-family: ${rr};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  width: fit-content;
  height: 19px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: #1cca94;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    left: 100%;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TDMain = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const DownloadBtn = styled.a.attrs({
  download: true
})`
  width: fit-content;
  cursor: pointer;
  margin: 0;
  color: ${darkBlue};
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h2 {
    text-align: center;
    font-size: 18px;
  }
`;

export const ManualDiscountBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 15px;
    margin-left: 15px;
  }
`;

export const LinkedToKS = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 20px;

  & > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    column-gap: 5px;

    & > p {
      margin: 0 10px 0 0;
    }
  }
`;

export const AbsoluteValue = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 20px;
`;
