import { GET_EXTERNAL_AGENTS_LIST } from '../reducers/getExternalAgentsList';
import { EXTERNAL_AGENT_STATUSES } from 'src/features/ExternalAgent/reducers/externalAgentStatus';

export interface ExternalAgentObj {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  status: EXTERNAL_AGENT_STATUSES;
  legalForm: string;
  email: string;
  code: string;
  isLeadogenerator: boolean;
  userId: number;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: ExternalAgentObj[];
}

export interface RequestDataType {
  page: number;
  pageSize: number;
  innToFilterBy?: string;
  emailToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_ERR,
  error
});
