import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestDataType } from 'ExternalAgent/actions/externalAgentRegister';

export interface ExternalAgentRegisterStoreState extends Reducer {
  data: RequestDataType;
}

export enum EXTERNAL_AGENT_REGISTER {
  EXTERNAL_AGENT_REGISTER_REQ = 'EXTERNAL_AGENT_REGISTER_REQ',
  EXTERNAL_AGENT_REGISTER_GOT = 'EXTERNAL_AGENT_REGISTER_GOT',
  EXTERNAL_AGENT_REGISTER_ERR = 'EXTERNAL_AGENT_REGISTER_ERR',
  EXTERNAL_AGENT_REGISTER_RESET = 'EXTERNAL_AGENT_REGISTER_RESET'
}

export const initialState: ExternalAgentRegisterStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    legalForm: '',
    code: '',
    companyName: '',
    email: '',
    password: ''
  },
  error: {}
};

const externalAgentRegister = (
  state: ExternalAgentRegisterStoreState = initialState,
  action: {
    type: string;
    data: RequestDataType;
    error: {};
  }
): ExternalAgentRegisterStoreState => {
  switch (action.type) {
    case EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default externalAgentRegister;
