import { GET_OPEN_LOAN_LIMITS_CLIENT } from 'entities/Cabinet/Client/model/reducers/getOpenLoanLimits';

export interface ResponseData {
  volume: number;
  productName: string;
  guid: string;
  term: null;
  rate: number;
}

export interface RequestData {
  companyInn?: string;
}

export const req = (data: RequestData) => ({
  type: GET_OPEN_LOAN_LIMITS_CLIENT.REQ,
  data
});

export const got = (data: ResponseData[]) => ({
  type: GET_OPEN_LOAN_LIMITS_CLIENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_OPEN_LOAN_LIMITS_CLIENT.ERR,
  error
});
