import * as React from 'react';
import { HintStyled } from './styles';

export interface IHint {
  validatable: boolean;
  position: HintPosition;
  component: JSX.Element;
}

type HintPosition = 'left' | 'right';

export const Hint: React.FC<IHint> = ({ position, component }: IHint) => {
  return <HintStyled position={position}>{component}</HintStyled>;
};
