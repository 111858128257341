import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  STORE,
  REQUEST_STATUSES,
  USER_PERMISSIONS,
  ResponseError
} from 'globaltypes';
import { history } from 'src/shared/utils/History';

import {
  Document,
  APPLICATION_STATUSES,
  SELECT_STATUS,
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES
} from 'Application/types';

import { TransitionWrapper, ANIMATION_NAMES } from 'Common';
import { Select } from 'shared/ui/Select';
import {
  Spinner,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader,
  Pagination
} from 'Layouts/components';

import {
  ApplicationViewStyled,
  LoaderContainerStyled,
  ViewSeparatorTop,
  EditApplicationBtn,
  ActivityWrapper,
  SentToBankStyled,
  ShowToAgentWrapper,
  CheckboxStyleShowToAgent,
  ProductApiContainer,
  Spinner as SpinnerApi,
  Limit,
  Rejection,
  OpenLoanLimitsContainer,
  UploadButton,
  TitleTabContainer,
  RejectionToggle,
  TooltipBoxStyle
} from './styles';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { ResponseData as ResponseActivities } from 'src/features/Application/actions/getActivitiesByApplication';
import { ResponseData as ResponseProductOfApplication } from 'src/features/Application/actions/getProductApplication';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as postSelectApplication } from 'Application/actions/postSelectApplication';
import {
  req as sendToBank,
  reset as resetSentToBank
} from 'src/features/Application/actions/putSendToBank';
import {
  req as getOpenLoanLimits,
  ResponseData as OpenLoanLimitsData
} from 'Application/actions/getOpenLoanLimits';
import { req as postExternalApiAlfaBank } from 'Application/actions/postExternalApiAlfaBank';
import { req as postExternalApiPSB } from 'Application/actions/postExternalApiPSB';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { req as putSetPreapproved } from 'Application/actions/putSetPreapproved';
import { req as putSetApproved } from 'Application/actions/putSetApproved';
import { req as putRejected } from 'src/features/Application/actions/putRejected';
import { req as postSendCompanyToScoring } from 'src/features/Leads/actions/postSendCompanyToScoring';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { CheckboxStyle } from 'src/features/Leads/components/LeadView/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  CloseButton,
  PopupContainer,
  PopupContent
} from 'src/features/Products/components/ProductView/styled';
import {
  ModalContainer,
  PurchaseContent
} from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import {
  RejectReasonModal,
  rejectReasons
} from 'src/features/Application/components/ApplicationView/EachApplicationView/RejectReasonModal';
import { DelegateInternalAgentToApplicationSelect } from 'src/features/Application/components/ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { SwitchBanksBlock } from './SwitchBanksBlock/SwitchBanksBlock';
import { ContentContainer } from './ContentContainer/ContentContainer';
import { ActionButtonsBlock } from './ActionButtonsBlock/ActionButtonsBlock';
import { ChooseBanksModal } from './ChooseBanksModal/ChooseBanksModal';
import { ActionModal } from './ActionModal/ActionModal';
import { CommentTabs } from './CommentTabs/CommentTabs';
import { Button } from 'shared/ui/Button';
import { redirectToApplication } from 'shared/lib/routing/redirectToApplication';

export interface Info {
  name: string;
  value: JSX.Element | string;
}

export interface IApplicationInfo {
  mainInfo: Info[];
  subInfo: Info[];
}

export interface IApplicationDocuments {
  documents?: {
    general: Document[];
    company: Document[] | any;
  };
}

export type IApplicationData = IApplicationInfo & IApplicationDocuments;

export interface IOwnProps extends IApplicationData {
  children?: any;
}

interface StateToProps {
  internalApplication: ResInternalApplication;
  statusInternalApplication: REQUEST_STATUSES;
  switchBankStatus: REQUEST_STATUSES;
  sendToBankStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  statusProductOfApplication: REQUEST_STATUSES;
  errorProductOfApplication: ResponseError;
  activities: ResponseActivities;
  statusActivities: REQUEST_STATUSES;
  errorActivities: ResponseError;
  statusPostAlfaBank: REQUEST_STATUSES;
  statusPostPSB: REQUEST_STATUSES;
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  errorSendToBank: ResponseError;
  openLoanLimits: OpenLoanLimitsData;
  statusOpenLoanLimits: REQUEST_STATUSES;
  errorOpenLoanLimits: ResponseError;
  statusCompanyToScoring: REQUEST_STATUSES;
  sendApiError: string;
  documentList: ResDocumentListByApplicationId;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  postSelectApplication: (data: any) => void;
  sendToBank: (id: number) => void;
  postExternalApiAlfaBank: (ApplicationID: number) => void;
  postExternalApiPSB: (ApplicationID: number) => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  resetSentToBank: () => void;
  putSetPreapproved: (id: number) => void;
  putSetApproved: (id: number) => void;
  putRejected: (id: number, { rejectSubject, rejectReason }) => void;
  getOpenLoanLimits: (inn: string) => void;
  postSendCompanyToScoring: (inn: string) => void;
}

interface MatchParams {
  id: string;
  inBankId?: string;
}

type Props = RouteComponentProps<MatchParams> &
  IOwnProps &
  StateToProps &
  DispatchToProps;

const EachApplicationView: React.FC<Props> = ({
  setApplicationProcessMode,
  internalApplication,
  statusInternalApplication,
  switchBankStatus,
  sendToBankStatus,
  permissions,
  roles,
  mainInfo,
  subInfo,
  documents,
  companyHints,
  productOfApplication,
  postSelectApplication,
  statusProductOfApplication,
  errorProductOfApplication,
  sendToBank,
  activities,
  statusActivities,
  errorActivities,
  postExternalApiAlfaBank,
  statusPostAlfaBank,
  postExternalApiPSB,
  statusPostPSB,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  statusSendToBank,
  errorSendToBank,
  resetSentToBank,
  putSetPreapproved,
  putSetApproved,
  putRejected,
  getOpenLoanLimits,
  openLoanLimits,
  statusOpenLoanLimits,
  errorOpenLoanLimits,
  postSendCompanyToScoring,
  statusCompanyToScoring,
  sendApiError,
  documentList,
  children,
  match
}: Props) => {
  const productRef = React.useRef<HTMLDivElement>(null);
  const sendApiErrorModalRef = React.useRef<HTMLDivElement>(null);
  const [productStatus, setProductStatus] = React.useState(
    productOfApplication.reduce((acc, product) => {
      acc[product.id] = product.status;
      return acc;
    }, {})
  );
  const [productId, setProductId] = React.useState(null);
  const [isRejectModal, setIsRejectModal] = React.useState(false);
  const [isSendApiError, setIsSendApiError] = React.useState(false);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState([]);
  const [isSectionProductOffers, setIsSectionProductOffers] = React.useState(
    true
  );
  const [isRejection, setIsRejection] = React.useState(false);

  React.useEffect(
    () => {
      if (statusInternalApplication === REQUEST_STATUSES.GOT) {
        redirectToApplication(internalApplication);
      }
    },
    [internalApplication, statusInternalApplication]
  );

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        setFiltredFinancailsSummaryData(
          financailsSummaryData.sort((a, b) => a.year - b.year)
        );
      }
    },
    [financailsSummaryData]
  );

  React.useEffect(
    () => {
      if (
        statusInternalApplication === REQUEST_STATUSES.GOT &&
        !permissions.includes(USER_PERMISSIONS.CLIENT) &&
        !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
        !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
        !match.params.inBankId
      ) {
        getOpenLoanLimits(internalApplication.code);
      }
    },
    [permissions, statusInternalApplication]
  );

  const onProductClick = (id: number) => {
    window.open(`/crm/products/${id}`, '_blank');
  };

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      setIsSendApiError(false);
    }
  };

  const navigateToAddActivity = () => {
    const state = {
      inn: internalApplication.code,
      applicationCode: match.params.id
    };

    localStorage.setItem('applicationState', JSON.stringify(state));

    window.open('/crm/activities/new', '_blank');
  };

  const handleSentToBank = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    sendToBank(id);
  };

  const navigateToActivities = (id: number) => {
    window.open(`/crm/activities/${id}`, '_blank');
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(internalApplication.code, year));
    });
  };

  const closePopup = () => {
    resetSentToBank();
  };

  const handleOptionClick = (value: string, id: number) => {
    if (value === '1') {
      sendToBank(id);
    } else if (value === '2') {
      putSetPreapproved(id);
    } else if (value === '3') {
      putSetApproved(id);
    } else if (value === '4') {
      setIsRejectModal(true);
    }

    setProductId(id);
    setProductStatus(prevStatus => ({ ...prevStatus, [id]: value }));
  };

  function getFilteredOptions(status: number) {
    return Object.entries(SELECT_STATUS)
      .map(([key, value], index) => {
        if (status === 1 && key === '3') {
          return null;
        }
        if (status === 3 && key === '1') {
          return null;
        }
        return {
          id: (index + 1).toString(),
          name: `${key} - ${value}`
        };
      })
      .filter(option => option !== null);
  }

  const getRejectReasonMessage = (rejectCode: number) => {
    const reasons = Object.values(rejectReasons).flat();
    const matchedReason = reasons.find(reason =>
      reason.startsWith(`${rejectCode}`)
    );
    return matchedReason;
  };

  const sendCompanyToScoring = () => {
    postSendCompanyToScoring(internalApplication.code);
  };

  return (
    <ApplicationViewStyled>
      {/* Application modals, opens when you click on ActionButtons */}
      <ActionModal />
      <ChooseBanksModal />

      {/* ViewSeparator with two buttons - Edit and goBack */}
      {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
        <ViewSeparatorTop>
          <TransitionWrapper
            in={permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)}
            animationName={ANIMATION_NAMES.FADE}
          >
            <EditApplicationBtn
              onClick={() => {
                setApplicationProcessMode({
                  mode: APPLICATION_PROCESS_MODES.EDIT
                });

                history.push(
                  `/cabinet/internal/application-form-${APPLICATION_TYPE_ROUTES[
                    internalApplication.financingType
                  ].replace('_', '-')}/edit/${internalApplication.id}`
                );
              }}
            >
              Редактировать заявку
            </EditApplicationBtn>
          </TransitionWrapper>
        </ViewSeparatorTop>
      )}

      {/* Change application id block. 
        Visible only in if application was transfered to banks  */}
      <SwitchBanksBlock />

      {/* Application content */}
      <ContentContainer
        application={internalApplication}
        mainInfo={mainInfo}
        subInfo={subInfo}
        documents={documents}
        permissions={permissions}
        roles={roles}
        companyHints={companyHints}
        productOfApplication={productOfApplication}
        postSelectApplication={postSelectApplication}
        scrollToProduct={scrollToProduct}
        sendRequestsGIR_BO={sendRequestsGIR_BO}
        financailsSummaryData={filtredFinancailsSummaryData}
        statusFinancailsSummaryData={statusFinancailsSummaryData}
        statusExternalApiGIR_BO={statusExternalApiGIR_BO}
        documentList={documentList}
      >
        {children}
      </ContentContainer>

      {/* Application action buttons block, 
        contains specific set of buttons, which changes application status */}
      <ActionButtonsBlock />

      {permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
        internalApplication.status ===
          APPLICATION_STATUSES.TRANSFERRED_TO_AGENT && (
          <DelegateInternalAgentToApplicationSelect
            applicationId={internalApplication.id}
          />
        )}
      {/* Application Comments, not visible in drafts */}
      <CommentTabs />

      {!match.params.inBankId && (
        <>
          <TitleTabContainer>
            <div>
              <p>Продуктовые предложения</p>
              <div>
                {isRejection ? (
                  <RejectionToggle onClick={() => setIsRejection(false)}>
                    <FontAwesomeIcon icon={faEye} />
                    <p>Отказы показаны</p>
                  </RejectionToggle>
                ) : (
                  <RejectionToggle onClick={() => setIsRejection(true)}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                    <p>Отказы скрыты</p>
                  </RejectionToggle>
                )}
              </div>
            </div>
            <FontAwesomeIcon
              icon={isSectionProductOffers ? faChevronUp : faChevronDown}
              onClick={() => setIsSectionProductOffers(prevState => !prevState)}
            />
          </TitleTabContainer>

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
          statusActivities === REQUEST_STATUSES.GOT ? (
            <>
              <ActivityWrapper>
                <ApplicationTitle>Активности</ApplicationTitle>
                <Button
                  label="+ Добавить активность"
                  onClick={navigateToAddActivity}
                  w="fit-content"
                />
              </ActivityWrapper>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="10%">Дата</TableThStyled>
                    <TableThStyled width="15%">Тип активности</TableThStyled>
                    <TableThStyled width="25%">Результат</TableThStyled>
                    <TableThStyled width="25%">Комментарий</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {activities.items.map(activity => (
                    <TableRowStyled
                      key={activity.id}
                      onClick={() => navigateToActivities(activity.id)}
                    >
                      <td />
                      <td>
                        <ShowToAgentWrapper>
                          {activity.showToAgent ? (
                            <TooltipBoxStyle
                              textVerify={
                                'Активность видна агенту без внутренних комментариев'
                              }
                            >
                              <CheckboxStyleShowToAgent>
                                <FontAwesomeIcon icon={faEye} />
                              </CheckboxStyleShowToAgent>
                            </TooltipBoxStyle>
                          ) : (
                            <TooltipBoxStyle
                              textVerify={'Активность не видна агенту'}
                            >
                              <CheckboxStyleShowToAgent>
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </CheckboxStyleShowToAgent>
                            </TooltipBoxStyle>
                          )}
                          <p>{activity.activityTypeName}</p>
                        </ShowToAgentWrapper>
                      </td>
                      <td>{activity.result}</td>
                      <td>{activity.comment}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </>
          ) : (
            permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
            statusActivities === REQUEST_STATUSES.ERROR && (
              <div>
                Ошибка {errorActivities.code} {errorActivities.message}
              </div>
            )
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <OpenLoanLimitsContainer>
              <ApplicationTitle>Предодобренные лимиты:</ApplicationTitle>

              <UploadButton
                disabled={statusCompanyToScoring === REQUEST_STATUSES.REQUEST}
                onClick={sendCompanyToScoring}
              >
                <p>Обновить скоринг Альфа-Банка</p>
                {statusCompanyToScoring === REQUEST_STATUSES.REQUEST && (
                  <SpinnerApi icon={faSpinner} />
                )}
              </UploadButton>

              {statusOpenLoanLimits === REQUEST_STATUSES.REQUEST && <Loader />}
              {statusOpenLoanLimits === REQUEST_STATUSES.GOT ? (
                <>
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="10%">Банк</TableThStyled>
                        <TableThStyled width="10%">Продукт</TableThStyled>
                        <TableThStyled width="5%">Лимит</TableThStyled>
                        <TableThStyled width="7%">Срок, мес.</TableThStyled>
                        <TableThStyled width="7%">Ставка</TableThStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {openLoanLimits.items.map((limit, key) => (
                        <TableRowStyled
                          onClick={() => onProductClick(limit.productId)}
                          key={key}
                        >
                          <td>{limit.bankShortName}</td>
                          <td>{limit.productName}</td>
                          <td>
                            {limit.volume === null
                              ? 'Неизв.'
                              : formSumStringThousands(limit.volume)}
                          </td>
                          <td>
                            {limit.term === null
                              ? 'Неизв.'
                              : `До ${limit.term} мес.`}
                          </td>
                          <td>
                            {limit.rate === null
                              ? 'Неизв.'
                              : `${limit.rate}% годовых`}
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>

                  <Pagination list={openLoanLimits} />
                </>
              ) : (
                <div>
                  Ошибка {errorOpenLoanLimits.code}{' '}
                  {errorOpenLoanLimits.message}
                </div>
              )}
            </OpenLoanLimitsContainer>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <>
              <ApplicationTitle ref={productRef}>Продукты</ApplicationTitle>

              {internalApplication &&
                (!internalApplication.financingType.includes(
                  APPLICATION_TYPES.GUARANTEES
                ) &&
                  !internalApplication.financingType.includes(
                    APPLICATION_TYPES.FACTORING
                  )) &&
                statusInternalApplication === REQUEST_STATUSES.GOT && (
                  <ProductApiContainer>
                    <div>
                      <Button
                        label={'API Альфа-Банк ММБ'}
                        onClick={() =>
                          postExternalApiAlfaBank(internalApplication.id)
                        }
                        disabled={statusPostAlfaBank.includes(
                          REQUEST_STATUSES.REQUEST
                        )}
                        w="fit-content"
                      />
                      {statusPostAlfaBank.includes(
                        REQUEST_STATUSES.REQUEST
                      ) && <SpinnerApi icon={faSpinner} />}
                    </div>
                    <div>
                      <Button
                        label={'API ПСБ “Без Бумаг”'}
                        onClick={() =>
                          postExternalApiPSB(internalApplication.id)
                        }
                        disabled={statusPostPSB.includes(
                          REQUEST_STATUSES.REQUEST
                        )}
                        w="fit-content"
                      />
                      {statusPostPSB.includes(REQUEST_STATUSES.REQUEST) && (
                        <SpinnerApi icon={faSpinner} />
                      )}
                    </div>
                  </ProductApiContainer>
                )}

              {statusProductOfApplication === REQUEST_STATUSES.REQUEST && (
                <Loader />
              )}
              {statusProductOfApplication === REQUEST_STATUSES.GOT ? (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="1%">ID</TableThStyled>
                      <TableThStyled width="10%">Банк</TableThStyled>
                      <TableThStyled width="10%">Продукт</TableThStyled>
                      <TableThStyled width="5%">Сложность</TableThStyled>
                      <TableThStyled width="7%">Договор с банком</TableThStyled>
                      <TableThStyled width="4%">Ставка min</TableThStyled>
                      <TableThStyled width="4%">Сред</TableThStyled>
                      <TableThStyled width="4%">Max</TableThStyled>
                      <TableThStyled width="7%">Cashback</TableThStyled>
                      <TableThStyled width="7%">Рейтинг</TableThStyled>
                      <TableThStyled width="10%">Статус</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {productOfApplication.map(product => (
                      <TableRowStyled
                        onClick={() => onProductClick(product.productId)}
                        key={product.productId}
                      >
                        <td>{product.productId}</td>
                        <td>{product.bankName}</td>
                        <td>{product.productName}</td>
                        <td>{product.complexity}</td>
                        <td>
                          {product.hasContract
                            ? 'Есть договор'
                            : 'Нет договора'}
                        </td>
                        {(product.offerSize === 0 && product.offerRate === 0) ||
                        (product.offerSize === null &&
                          product.offerRate === null) ? (
                          <>
                            <td>{product.minRate}</td>
                            <td>
                              {product.meanRate ? product.meanRate : 'н/д'}
                            </td>
                            <td>{product.maxRate}</td>
                          </>
                        ) : (
                          <>
                            <td />
                            <td>
                              <Limit>
                                <p>Индикатив:</p>
                                <p>
                                  лимит до{' '}
                                  {formSumStringThousands(product.offerSize)} р,
                                  ставка {product.offerRate}%
                                </p>
                              </Limit>
                            </td>
                            <td />
                          </>
                        )}
                        <td>
                          {product.hasCashback ? (
                            <CheckboxStyle verify={product.hasCashback}>
                              <FontAwesomeIcon icon={faCheck} />
                            </CheckboxStyle>
                          ) : (
                            'Нет'
                          )}
                        </td>
                        <td>{product.rating}</td>
                        <td>
                          {product.sentToBank ? (
                            product.status === 4 ? (
                              <Rejection>
                                4 - Отказ <br />
                                {getRejectReasonMessage(product.rejectReason)}
                              </Rejection>
                            ) : (
                              <Select
                                options={getFilteredOptions(
                                  productStatus[product.id]
                                )}
                                name={`productStatus_${product.id}`}
                                value={productStatus[product.id]}
                                onClick={event => event.stopPropagation()}
                                onChange={e =>
                                  handleOptionClick(
                                    e.currentTarget.value,
                                    product.id
                                  )
                                }
                              />
                            )
                          ) : (
                            <SentToBankStyled
                              disabled={
                                statusSendToBank === REQUEST_STATUSES.REQUEST
                              }
                              onClick={e => handleSentToBank(e, product.id)}
                            >
                              {statusSendToBank ===
                                REQUEST_STATUSES.REQUEST && (
                                <SpinnerApi icon={faSpinner} />
                              )}{' '}
                              Отметить отправку в банк
                            </SentToBankStyled>
                          )}
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              ) : (
                <div>
                  Ошибка {errorProductOfApplication.code}{' '}
                  {errorProductOfApplication.message}
                </div>
              )}

              <RejectReasonModal
                isVisible={isRejectModal}
                setIsVisible={setIsRejectModal}
                putRejected={putRejected}
                productId={productId}
              />

              <PopupContainer
                visible={statusSendToBank === REQUEST_STATUSES.ERROR}
              >
                <PopupContent>
                  <CloseButton onClick={closePopup}>&times;</CloseButton>
                  <h3>{errorSendToBank.code}</h3>
                  <p>{errorSendToBank.message}</p>
                </PopupContent>
              </PopupContainer>

              <Pagination list={productOfApplication} />
            </>
          )}
        </>
      )}

      <ModalContainer
        visible={isSendApiError}
        onClick={e => handleOutsideClick(e, sendApiErrorModalRef)}
      >
        <PurchaseContent ref={sendApiErrorModalRef}>
          <p>{sendApiError}</p>
        </PurchaseContent>
      </ModalContainer>

      {(switchBankStatus === REQUEST_STATUSES.REQUEST ||
        sendToBankStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={100} />
        </LoaderContainerStyled>
      )}

      <ActionResponseBlock
        showIn={switchBankStatus === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  Application,
  InternalAgentRole,
  User,
  Leads,
  BorrowerCompanies
}: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  statusInternalApplication: InternalAgentRole.getInternalApplication.status,
  companyHints: Application.getCompanyHints.data,
  switchBankStatus: Application.getInBankApplication.status,
  sendToBankStatus: Application.sendApplicationToBanks.status,
  productOfApplication: Application.getProductApplication.data,
  statusProductOfApplication: Application.getProductApplication.status,
  errorProductOfApplication: Application.getProductApplication.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  activities: Application.getActivitiesByApplication.data,
  statusActivities: Application.getActivitiesByApplication.status,
  errorActivities: Application.getActivitiesByApplication.error,
  statusPostAlfaBank: Application.postExternakApiAlfaBank.status,
  statusPostPSB: Application.postExternakApiPSB.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  errorSendToBank: Application.putSendToBank.error,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  sendApiError: Application.postOfferSend.error.body,
  documentList: BorrowerCompanies.getDocumentListByApplicationId.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setApplicationProcessMode,
      postSelectApplication,
      sendToBank,
      postExternalApiAlfaBank,
      postExternalApiPSB,
      getExternalApiGIR_BO,
      resetSentToBank,
      putSetPreapproved,
      putSetApproved,
      putRejected,
      getOpenLoanLimits,
      postSendCompanyToScoring
    },
    dispatch
  );

const EachApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachApplicationView)
);

export { EachApplicationViewConnect as EachApplicationView };
