import { POST_EXTERNAL_API_PSB_LEAD } from '../reducers/postExternalApiPSB';

export const req = (LeadID: number) => ({
  type: POST_EXTERNAL_API_PSB_LEAD.REQ,
  LeadID
});

export const got = () => ({
  type: POST_EXTERNAL_API_PSB_LEAD.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_API_PSB_LEAD.ERR,
  error
});
