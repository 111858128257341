import { combineReducers } from 'redux';
import getSupplierList, {
  GetSupplierListStoreState
} from 'src/features/UserSuppliers/reducers/getSupplierList';

export interface SupplierReducers {
  getSupplierList: GetSupplierListStoreState;
}

export const Suppliers = combineReducers({ getSupplierList });
