import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/SupplyContracts/actions/getContractByInternal';

export interface GetContractByInternalStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_CONTRACT_BY_INTERNAL {
  REQ = 'GET_CONTRACT_BY_INTERNAL_REQ',
  GOT = 'GET_CONTRACT_BY_INTERNAL_GOT',
  ERR = 'GET_CONTRACT_BY_INTERNAL_ERR'
}

export const initialState: GetContractByInternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getContractByInternal = (
  state: GetContractByInternalStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetContractByInternalStoreState => {
  switch (action.type) {
    case GET_CONTRACT_BY_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTRACT_BY_INTERNAL.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_CONTRACT_BY_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContractByInternal;
