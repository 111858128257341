import { combineReducers } from 'redux';
import getCreditProducts, {
  GetCreditProductsStoreState
} from './getCreditProducts';
import getPledges, { GetPledgesStoreState } from './getPledges';
import getClientsList, { GetClientsListStoreState } from './getClientsList';
import postNewClient, { PostNewClientStoreState } from './postNewClient';
import getBeneficiaries, {
  GetClientBeneficiariesStoreState
} from './getBeneficiaries';
import getFinancialDocuments, {
  GetFinancialDocumentsStoreState
} from './getFinancialDocuments';
import getSubsidariesClient, {
  GetSubsidariesClientStoreState
} from './getSubsidariesClient';

export interface ClientsReducers {
  getCreditProducts: GetCreditProductsStoreState;
  getPledges: GetPledgesStoreState;
  getClientsList: GetClientsListStoreState;
  postNewClient: PostNewClientStoreState;
  getBeneficiaries: GetClientBeneficiariesStoreState;
  getFinancialDocuments: GetFinancialDocumentsStoreState;
  getSubsidariesClient: GetSubsidariesClientStoreState;
}

export const Clients = combineReducers({
  getCreditProducts,
  getPledges,
  getClientsList,
  postNewClient,
  getBeneficiaries,
  getFinancialDocuments,
  getSubsidariesClient
});
