import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rl, rr, gray } from 'shared/styled';

export const LabelInfoStyled = styled.span`
  position: absolute;
  font-family: ${rl};
`;

export const NavigationLinkStyled = styled(NavLink)`
  width: 100%;
  height: 49px;

  padding: 13px 24px;
  padding-left: calc(24px + 40px);
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  outline: none;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  background-repeat: no-repeat;

  font-family: ${rr};
  font-size: 14px;
  text-decoration: none;
  color: #656c82;

  cursor: pointer;

  background-position: 22px 50%;

  & > p {
    width: 90%;
  }

  &.active {
    background-color: rgba(156, 175, 192, 0.2);

    p {
      color: #fff;
    }
  }
`;

export const NavigationLinkTrash = styled(NavigationLinkStyled)`
  margin-top:auto;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path fill="${gray}" d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path>    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="rgb(255,255,255)" d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"></path>
      </svg>
    ');
`;

export const NavigationLinkPlanet = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M8.683 17.892a8.002 8.002 0 0 1 0-15.784 8.055 8.055 0 0 1 2.634 0 8.002 8.002 0 0 1 0 15.784 8.055 8.055 0 0 1-2.634 0zm-3.006-2.817c.515.439 1.096.8 1.728 1.068a12.083 12.083 0 0 1-.837-1.625c-.311.165-.608.351-.89.557zm-.93-.97a8.67 8.67 0 0 1 1.371-.856 11.526 11.526 0 0 1-.433-2.582H3.366a6.634 6.634 0 0 0 1.381 3.439zm4.586-1.746v-1.692H7.02c.044.71.157 1.402.341 2.075a8.63 8.63 0 0 1 1.972-.383zm0 1.338a7.3 7.3 0 0 0-1.535.306c.377.895.888 1.754 1.535 2.58v-2.886zm4.99 1.378a7.337 7.337 0 0 0-.89-.557 12.083 12.083 0 0 1-.838 1.625 6.668 6.668 0 0 0 1.728-1.068zm.93-.97a6.634 6.634 0 0 0 1.38-3.438h-2.318a11.526 11.526 0 0 1-.433 2.582c.484.243.943.53 1.37.857zm-4.586-1.746a8.63 8.63 0 0 1 1.972.383c.184-.673.297-1.364.34-2.075h-2.312v1.692zm0 1.338v2.885a11.298 11.298 0 0 0 1.535-2.579 7.3 7.3 0 0 0-1.535-.306zm-4.99-8.772c.283.206.58.392.89.557.234-.554.514-1.096.838-1.625a6.668 6.668 0 0 0-1.728 1.068zm-.93.97a6.634 6.634 0 0 0-1.38 3.438h2.318c.048-.887.193-1.748.433-2.582a8.67 8.67 0 0 1-1.37-.857zM9.333 7.64a8.63 8.63 0 0 1-1.972-.383 10.204 10.204 0 0 0-.34 2.075h2.312V7.641zm0-1.338V3.418a11.298 11.298 0 0 0-1.535 2.579 7.3 7.3 0 0 0 1.535.306zm4.99-1.378a6.668 6.668 0 0 0-1.728-1.068c.324.529.604 1.07.837 1.625.311-.165.608-.351.89-.557zm.93.97a8.67 8.67 0 0 1-1.371.856c.24.834.385 1.695.433 2.582h2.319a6.634 6.634 0 0 0-1.381-3.439zM10.667 7.64v1.692h2.313a10.204 10.204 0 0 0-.341-2.075c-.63.2-1.29.331-1.972.383zm0-1.338a7.3 7.3 0 0 0 1.535-.306 11.298 11.298 0 0 0-1.535-2.58v2.886z"/>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M8.683 17.892a8.002 8.002 0 0 1 0-15.784 8.055 8.055 0 0 1 2.634 0 8.002 8.002 0 0 1 0 15.784 8.055 8.055 0 0 1-2.634 0zm-3.006-2.817c.515.439 1.096.8 1.728 1.068a12.083 12.083 0 0 1-.837-1.625c-.311.165-.608.351-.89.557zm-.93-.97a8.67 8.67 0 0 1 1.371-.856 11.526 11.526 0 0 1-.433-2.582H3.366a6.634 6.634 0 0 0 1.381 3.439zm4.586-1.746v-1.692H7.02c.044.71.157 1.402.341 2.075a8.63 8.63 0 0 1 1.972-.383zm0 1.338a7.3 7.3 0 0 0-1.535.306c.377.895.888 1.754 1.535 2.58v-2.886zm4.99 1.378a7.337 7.337 0 0 0-.89-.557 12.083 12.083 0 0 1-.838 1.625 6.668 6.668 0 0 0 1.728-1.068zm.93-.97a6.634 6.634 0 0 0 1.38-3.438h-2.318a11.526 11.526 0 0 1-.433 2.582c.484.243.943.53 1.37.857zm-4.586-1.746a8.63 8.63 0 0 1 1.972.383c.184-.673.297-1.364.34-2.075h-2.312v1.692zm0 1.338v2.885a11.298 11.298 0 0 0 1.535-2.579 7.3 7.3 0 0 0-1.535-.306zm-4.99-8.772c.283.206.58.392.89.557.234-.554.514-1.096.838-1.625a6.668 6.668 0 0 0-1.728 1.068zm-.93.97a6.634 6.634 0 0 0-1.38 3.438h2.318c.048-.887.193-1.748.433-2.582a8.67 8.67 0 0 1-1.37-.857zM9.333 7.64a8.63 8.63 0 0 1-1.972-.383 10.204 10.204 0 0 0-.34 2.075h2.312V7.641zm0-1.338V3.418a11.298 11.298 0 0 0-1.535 2.579 7.3 7.3 0 0 0 1.535.306zm4.99-1.378a6.668 6.668 0 0 0-1.728-1.068c.324.529.604 1.07.837 1.625.311-.165.608-.351.89-.557zm.93.97a8.67 8.67 0 0 1-1.371.856c.24.834.385 1.695.433 2.582h2.319a6.634 6.634 0 0 0-1.381-3.439zM10.667 7.64v1.692h2.313a10.204 10.204 0 0 0-.341-2.075c-.63.2-1.29.331-1.972.383zm0-1.338a7.3 7.3 0 0 0 1.535-.306 11.298 11.298 0 0 0-1.535-2.58v2.886z"/>
      </svg>
    ');
`;

export const NavigationLinkDesk = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
      <path fill="${gray}" d="M13.7777778,1.6 L10.327619,1.6 C9.98095238,0.672 9.07301587,0 8,0 C6.92698413,0 6.01904762,0.672 5.67238095,1.6 L2.22222222,1.6 C1.31428571,1.6 0.571428571,2.32 0.571428571,3.2 L0.571428571,14.4 C0.571428571,15.28 1.31428571,16 2.22222222,16 L13.7777778,16 C14.6857143,16 15.4285714,15.28 15.4285714,14.4 L15.4285714,3.2 C15.4285714,2.32 14.6857143,1.6 13.7777778,1.6 Z M8,1.6 C8.45396825,1.6 8.82539683,1.96 8.82539683,2.4 C8.82539683,2.84 8.45396825,3.2 8,3.2 C7.54603175,3.2 7.17460317,2.84 7.17460317,2.4 C7.17460317,1.96 7.54603175,1.6 8,1.6 Z M9.65079365,12.8 L3.87301587,12.8 L3.87301587,11.2 L9.65079365,11.2 L9.65079365,12.8 Z M12.1269841,9.6 L3.87301587,9.6 L3.87301587,8 L12.1269841,8 L12.1269841,9.6 Z M12.1269841,6.4 L3.87301587,6.4 L3.87301587,4.8 L12.1269841,4.8 L12.1269841,6.4 Z"></path>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M13.7777778,1.6 L10.327619,1.6 C9.98095238,0.672 9.07301587,0 8,0 C6.92698413,0 6.01904762,0.672 5.67238095,1.6 L2.22222222,1.6 C1.31428571,1.6 0.571428571,2.32 0.571428571,3.2 L0.571428571,14.4 C0.571428571,15.28 1.31428571,16 2.22222222,16 L13.7777778,16 C14.6857143,16 15.4285714,15.28 15.4285714,14.4 L15.4285714,3.2 C15.4285714,2.32 14.6857143,1.6 13.7777778,1.6 Z M8,1.6 C8.45396825,1.6 8.82539683,1.96 8.82539683,2.4 C8.82539683,2.84 8.45396825,3.2 8,3.2 C7.54603175,3.2 7.17460317,2.84 7.17460317,2.4 C7.17460317,1.96 7.54603175,1.6 8,1.6 Z M9.65079365,12.8 L3.87301587,12.8 L3.87301587,11.2 L9.65079365,11.2 L9.65079365,12.8 Z M12.1269841,9.6 L3.87301587,9.6 L3.87301587,8 L12.1269841,8 L12.1269841,9.6 Z M12.1269841,6.4 L3.87301587,6.4 L3.87301587,4.8 L12.1269841,4.8 L12.1269841,6.4 Z"></path>
      </svg>
    ');
`;

export const NavigationLinkDocs = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M15.976 5.444H6.068c-.95 0-1.296.108-1.643.31-.348.2-.62.496-.807.873-.186.376-.285.75-.285 1.78v6.4a1.393 1.393 0 0 1-1.137-.787C2.068 13.762 2 13.505 2 12.796v-6.76c0-.707.068-.964.196-1.223.128-.26.315-.462.554-.6.24-.14.476-.213 1.13-.213h10.24c.654 0 .89.074 1.13.212.239.139.426.342.554.6.084.17.142.338.172.632zM6.214 6.528h9.906c.654 0 .89.074 1.13.212.239.138.426.342.554.6.128.26.196.516.196 1.225v6.398c0 .708-.068.965-.196 1.224a1.393 1.393 0 0 1-.554.6c-.24.14-.476.213-1.13.213H6.214c-.654 0-.891-.074-1.13-.212a1.393 1.393 0 0 1-.555-.6c-.128-.26-.196-.517-.196-1.225V8.565c0-.709.068-.965.196-1.224.128-.26.316-.463.555-.601.239-.138.476-.212 1.13-.212zm.929 2.2a.571.571 0 0 0-.572.57v.04c0 .315.256.571.572.571H14a.571.571 0 0 0 .571-.571v-.04a.571.571 0 0 0-.571-.57H7.143zm0 2.363a.571.571 0 0 0-.572.571v.04c0 .315.256.57.572.57H14a.571.571 0 0 0 .571-.57v-.04a.571.571 0 0 0-.571-.571H7.143zm0 2.364a.571.571 0 0 0-.572.571v.039c0 .316.256.571.572.571h3.428a.571.571 0 0 0 .572-.571v-.039a.571.571 0 0 0-.572-.571H7.143z"/>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M15.976 5.444H6.068c-.95 0-1.296.108-1.643.31-.348.2-.62.496-.807.873-.186.376-.285.75-.285 1.78v6.4a1.393 1.393 0 0 1-1.137-.787C2.068 13.762 2 13.505 2 12.796v-6.76c0-.707.068-.964.196-1.223.128-.26.315-.462.554-.6.24-.14.476-.213 1.13-.213h10.24c.654 0 .89.074 1.13.212.239.139.426.342.554.6.084.17.142.338.172.632zM6.214 6.528h9.906c.654 0 .89.074 1.13.212.239.138.426.342.554.6.128.26.196.516.196 1.225v6.398c0 .708-.068.965-.196 1.224a1.393 1.393 0 0 1-.554.6c-.24.14-.476.213-1.13.213H6.214c-.654 0-.891-.074-1.13-.212a1.393 1.393 0 0 1-.555-.6c-.128-.26-.196-.517-.196-1.225V8.565c0-.709.068-.965.196-1.224.128-.26.316-.463.555-.601.239-.138.476-.212 1.13-.212zm.929 2.2a.571.571 0 0 0-.572.57v.04c0 .315.256.571.572.571H14a.571.571 0 0 0 .571-.571v-.04a.571.571 0 0 0-.571-.57H7.143zm0 2.363a.571.571 0 0 0-.572.571v.04c0 .315.256.57.572.57H14a.571.571 0 0 0 .571-.57v-.04a.571.571 0 0 0-.571-.571H7.143zm0 2.364a.571.571 0 0 0-.572.571v.039c0 .316.256.571.572.571h3.428a.571.571 0 0 0 .572-.571v-.039a.571.571 0 0 0-.572-.571H7.143z"/>
      </svg>
    ');
`;

export const NavigationLinkClock = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M10 17a7 7 0 1 1 0-14 7 7 0 0 1 0 14zM9.957 4.75a.603.603 0 0 0-.302.062.424.424 0 0 0-.176.177.603.603 0 0 0-.062.301v3.828c0 .104-.011.141-.031.18a.212.212 0 0 1-.089.088c-.038.02-.075.03-.18.03H6.75a.603.603 0 0 0-.302.063.424.424 0 0 0-.176.176.603.603 0 0 0-.063.302v.086c0 .139.022.226.063.302.04.076.1.135.176.176.076.04.163.062.302.062H9.61c.338 0 .46-.035.584-.101a.69.69 0 0 0 .287-.287c.066-.123.101-.246.101-.584v-4.32a.603.603 0 0 0-.062-.302.424.424 0 0 0-.176-.177.603.603 0 0 0-.302-.062h-.086z"/>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M10 17a7 7 0 1 1 0-14 7 7 0 0 1 0 14zM9.957 4.75a.603.603 0 0 0-.302.062.424.424 0 0 0-.176.177.603.603 0 0 0-.062.301v3.828c0 .104-.011.141-.031.18a.212.212 0 0 1-.089.088c-.038.02-.075.03-.18.03H6.75a.603.603 0 0 0-.302.063.424.424 0 0 0-.176.176.603.603 0 0 0-.063.302v.086c0 .139.022.226.063.302.04.076.1.135.176.176.076.04.163.062.302.062H9.61c.338 0 .46-.035.584-.101a.69.69 0 0 0 .287-.287c.066-.123.101-.246.101-.584v-4.32a.603.603 0 0 0-.062-.302.424.424 0 0 0-.176-.177.603.603 0 0 0-.302-.062h-.086z"/>
      </svg>
    ');
`;

export const NavigationLinkBag = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M6 5v-.84c0-.752.093-1.024.267-1.298.174-.275.43-.49.756-.637.326-.147.65-.225 1.54-.225h2.873c.892 0 1.215.078 1.54.225.327.147.583.362.757.637.174.274.267.546.267 1.297V5h1.949c.713 0 .972.074 1.232.214.261.14.466.344.605.605.14.26.214.519.214 1.232v2.824h-6.4V9.25a1 1 0 0 0-1-1H9.4a1 1 0 0 0-1 1v.625H2V7.051c0-.713.074-.972.214-1.232.14-.261.344-.466.605-.605.26-.14.519-.214 1.232-.214H6zm1.155 0h5.723V3.862a1 1 0 0 0-1-1H8.155a1 1 0 0 0-1 1V5zM18 11.094v3.855c0 .713-.074.972-.214 1.232-.14.261-.344.466-.605.605-.26.14-.519.214-1.232.214H4.05c-.713 0-.972-.074-1.232-.214a1.454 1.454 0 0 1-.605-.605c-.14-.26-.214-.519-.214-1.232v-3.855h16z" opacity=".9"/>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M6 5v-.84c0-.752.093-1.024.267-1.298.174-.275.43-.49.756-.637.326-.147.65-.225 1.54-.225h2.873c.892 0 1.215.078 1.54.225.327.147.583.362.757.637.174.274.267.546.267 1.297V5h1.949c.713 0 .972.074 1.232.214.261.14.466.344.605.605.14.26.214.519.214 1.232v2.824h-6.4V9.25a1 1 0 0 0-1-1H9.4a1 1 0 0 0-1 1v.625H2V7.051c0-.713.074-.972.214-1.232.14-.261.344-.466.605-.605.26-.14.519-.214 1.232-.214H6zm1.155 0h5.723V3.862a1 1 0 0 0-1-1H8.155a1 1 0 0 0-1 1V5zM18 11.094v3.855c0 .713-.074.972-.214 1.232-.14.261-.344.466-.605.605-.26.14-.519.214-1.232.214H4.05c-.713 0-.972-.074-1.232-.214a1.454 1.454 0 0 1-.605-.605c-.14-.26-.214-.519-.214-1.232v-3.855h16z" opacity=".9"/>
      </svg>
    ');
`;

export const NavigationLinkPen = styled(NavigationLinkStyled)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M2.5 14.375V17.5h3.125l9.215-9.215-3.125-3.125L2.5 14.375zm14.756-8.507a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.174 0L12.607 4.27l3.124 3.124 1.525-1.525z"/>
    </svg>
  ');

  &.active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="rgb(255,255,255)" d="M2.5 14.375V17.5h3.125l9.215-9.215-3.125-3.125L2.5 14.375zm14.756-8.507a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.174 0L12.607 4.27l3.124 3.124 1.525-1.525z"/>
      </svg>
    ');
`;

export const NavigationLinkFontAwesome = styled(NavigationLinkStyled)`
  svg {
    position: absolute;
    display: block;
    left: 22px;
    font-size: 16px;
    color: ${gray};
  }
`;
