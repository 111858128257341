import { APPLICATION_STATUSES } from 'Application/types';
import { SUBMENU_CLICK_ACTIONS } from 'Application/components';

export const agentAsClientSubmenuConstructor = (
  status: APPLICATION_STATUSES
) => {
  switch (status) {
    case APPLICATION_STATUSES.DRAFT:
      return [
        {
          name: 'Редактировать',
          action: SUBMENU_CLICK_ACTIONS.EDIT
        },
        {
          name: 'Удалить черновик',
          action: SUBMENU_CLICK_ACTIONS.REMOVE
        }
      ];

    case APPLICATION_STATUSES.DOCS_REQUEST:
      return [
        {
          name: 'Редактировать',
          action: SUBMENU_CLICK_ACTIONS.EDIT
        },
        {
          name: 'Отозвать заявку',
          action: SUBMENU_CLICK_ACTIONS.REJECT
        }
      ];

    case APPLICATION_STATUSES.APPROVED:
      return [
        {
          name: 'Отозвать заявку',
          action: SUBMENU_CLICK_ACTIONS.REJECT
        }
      ];

    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.SUCCESS:
      return [];

    default:
      return [];
  }
};
