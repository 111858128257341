import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/patchEditPerson';

import { PATCH_EDIT_PERSON } from '../reducers/patchEditPerson';
import PersonsApi from '../api';

function* patchEditPerson(action: {
  type: PATCH_EDIT_PERSON;
  data: RequestData;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      PersonsApi.patchEditPerson,
      action.inn,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchEditPersonSaga(): Generator {
  yield takeLatest(PATCH_EDIT_PERSON.REQ, patchEditPerson);
}
