import Validator from 'instant-validation';
import { ValidatorMessages } from 'shared/constants';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import { checkIfDocumentsContainMetaInfoFrom } from 'src/features/BorrowerCompanies/components/BorrowerCompanies/validator';
import { FactoringDocumentsRequired } from './FactoringFormStep3';
import { USER_PERMISSIONS } from 'src/globaltypes';

export const createValidaton = () =>
  new Validator<SetFactoringApplicationType>({
    documents: [
      {
        rule: (documents, permissions: USER_PERMISSIONS[], roles: string[]) => {
          return permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) ||
            roles.includes('Applicant')
            ? true
            : checkIfDocumentsContainMetaInfoFrom(
                documents,
                FactoringDocumentsRequired
              );
        },
        ruleId: 'permissionsAndRoles',
        message: ValidatorMessages.applicationFilesEmpty
      }
    ]
  });
