import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format } from 'date-fns';

import { Loader } from 'Layouts/components';
import { Calc as ResponseDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import { ResponseDataType as OneDebtorResponseDataType } from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import { req as postCreateAgreementSupplierRole } from 'src/features/SCF/actions/postCreateAgreementSupplierRole';
import {
  ContractsPageStyled,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  ListSectionWide,
  FullListWide,
  BackBtn,
  BackBtnText,
  SelectActive,
  SelectContainerActive,
  SelectContainerInactive,
  SelectInactive,
  ConfirmSection,
  ConfirmSectionCheckBox,
  ConfirmSectionRightPart,
  DiscountItem,
  TDInvoiceDocumenContainer,
  InvoiceDocumentDate,
  DocumentHint,
  SkeletonWrapper
} from '../styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableStyledWrapped,
  TableHeaderCellCenteredStyled
} from 'src/features/SCF/UI/Table/styles';
import {
  TableRowStyled,
  TdCentred
} from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
  monetaryClaimsHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
}

interface DispatchToProps {
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
  postCreateAgreementSupplierRole: (guid: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationCalcFactorPayer: React.FC<Props> = ({
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  investorOneDebtor,
  monetaryClaimsEarlyPaymentApplications,
  status,
  postCreateAgreementSupplierRole,
  history,
  match
}) => {
  const [confirmState, setConfirmState] = React.useState(false);

  const handleConfirmStateChange = () => {
    setConfirmState(prevState => !prevState);
  };

  const onBackClick = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const onSignClick = () => {
    const { id } = match.params;
    postCreateAgreementSupplierRole(id);
    history.push(`/supplier/early_payment_applications/${id}/agreement`);
  };

  const intermediateDiscount = (): number => {
    if (monetaryClaimsEarlyPaymentApplications[0].baseSumm > 0) {
      return Math.round(investorOneDebtor.baseDiscountRate * 1.2 * 1000) / 1000;
    }
    return Math.round(investorOneDebtor.baseDiscountRate * 1000) / 1000;
  };

  const DiscountCounter = () => {
    let discountCounter: number;
    discountCounter = 0;
    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      discountCounter += monetaryClaimsEarlyPaymentApplications[i].baseFullSumm;
    }
    return discountCounter;
  };

  const DiscountCounterToGetFactor = () => {
    let discountCounterToGetFactor = 0;

    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      const maturityDate = new Date(
        monetaryClaimsEarlyPaymentApplications[i].maturityDate
      );
      const payDate = new Date(investorOneDebtor.payDate);

      const fullTilt =
        (investorOneDebtor.baseDiscountRate *
          1.2 *
          Math.round((differenceInDays(maturityDate, payDate) / 365) * 10000)) /
        10000 /
        100;

      discountCounterToGetFactor += Math.round(
        (Math.min(
          (monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
            investorOneDebtor.firstPaymentShare) /
            100,
          monetaryClaimsEarlyPaymentApplications[i].baseFullSumm /
            (1 + fullTilt)
        ) *
          100) /
          100
      );
    }

    return discountCounterToGetFactor;
  };

  const PreConditionsFactor = () => {
    let preConditionsFactor = 0;

    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      const maturityDate = new Date(
        monetaryClaimsEarlyPaymentApplications[i].maturityDate
      );
      const payDate = new Date(investorOneDebtor.payDate);

      const daysDifference = differenceInDays(maturityDate, payDate);

      preConditionsFactor +=
        ((investorOneDebtor.baseDiscountRate * 1.2 * (daysDifference / 365)) /
          100) *
        ((monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
          Math.min(
            investorOneDebtor.firstPaymentShare,
            100 /
              (1 +
                (investorOneDebtor.baseDiscountRate *
                  1.2 *
                  (daysDifference / 365)) /
                  100)
          )) /
          100);
    }

    return preConditionsFactor;
  };

  const FinalCalcFactor = () => {
    let finalCalc = 0;

    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      const maturityDate = new Date(
        monetaryClaimsEarlyPaymentApplications[i].maturityDate
      );
      const payDate = new Date(investorOneDebtor.payDate);

      const daysDifference = differenceInDays(maturityDate, payDate);

      finalCalc +=
        monetaryClaimsEarlyPaymentApplications[i].baseFullSumm -
        (monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
          Math.min(
            investorOneDebtor.firstPaymentShare,
            100 /
              (1 +
                (investorOneDebtor.baseDiscountRate *
                  1.2 *
                  (daysDifference / 365)) /
                  100)
          )) /
          100 -
        ((investorOneDebtor.baseDiscountRate * 1.2 * (daysDifference / 365)) /
          100) *
          ((monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
            Math.min(
              investorOneDebtor.firstPaymentShare,
              100 /
                (1 +
                  (investorOneDebtor.baseDiscountRate *
                    1.2 *
                    (daysDifference / 365)) /
                    100)
            )) /
            100);
    }

    return finalCalc < 0.001 ? 0 : finalCalc;
  };

  return (
    <ContractsPageStyled>
      <ListSectionWide>
        <FullListWide>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyledWrapped sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellCenteredStyled width="7%">
                    ID
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="15%">
                    СУММА БЕЗ НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="8%">
                    НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА С НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДОКУМЕНТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="16%">
                    ДОЛЯ ФИНАНСИРОВАНИЯ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДАТА ОПЛАТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="17%">
                    СРОК ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="20%">
                    СТАВКА ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    ПРЕДВАРИТЕЛЬНАЯ СТОИМОСТЬ ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="28%">
                    ПРОГНОЗ К ПОЛУЧЕНИЮ
                  </TableHeaderCellCenteredStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                  <TableRowStyled key={key}>
                    <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </TdCentred>
                    <TdCentred>
                      {item.baseSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    {item.baseVatPecentage != null ? (
                      <TdCentred>
                        {item.baseVatPecentage.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                        %
                      </TdCentred>
                    ) : (
                      <TdCentred />
                    )}
                    <TdCentred>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      {Math.min(
                        investorOneDebtor.firstPaymentShare,
                        100 /
                          (1 +
                            (investorOneDebtor.baseDiscountRate *
                              1.2 *
                              (differenceInDays(
                                new Date(item.maturityDate),
                                new Date(investorOneDebtor.payDate)
                              ) /
                                365)) /
                              100)
                      ).toLocaleString('ru', {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}{' '}
                      %
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          textAlign: 'center',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {investorOneDebtor.baseDiscountRate &&
                          investorOneDebtor.baseDiscountRate.toLocaleString(
                            'ru',
                            {
                              maximumFractionDigits: 3,
                              minimumFractionDigits: 3
                            }
                          )}
                        %{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          без НДС
                        </p>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          investorOneDebtor.baseDiscountRate * 1.2
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })}
                        %{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          с НДС
                        </p>
                      </p>
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          investorOneDebtor.baseDiscountRate *
                          1.2 *
                          (differenceInDays(
                            new Date(item.maturityDate),
                            new Date(investorOneDebtor.payDate)
                          ) /
                            365)
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4
                        })}
                        %
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          ((investorOneDebtor.baseDiscountRate *
                            1.2 *
                            (differenceInDays(
                              new Date(item.maturityDate),
                              new Date(investorOneDebtor.payDate)
                            ) /
                              365)) /
                            100) *
                          ((item.baseFullSumm *
                            Math.min(
                              investorOneDebtor.firstPaymentShare,
                              100 /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    (differenceInDays(
                                      new Date(item.maturityDate),
                                      new Date(investorOneDebtor.payDate)
                                    ) /
                                      365)) /
                                    100)
                            )) /
                            100)
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </p>
                    </TdCentred>
                    <td>
                      <p
                        style={{
                          display: 'flex',
                          width: '325px',
                          alignItems: 'center',
                          margin: '5px 0',
                          justifyContent: 'space-between'
                        }}
                      >
                        {(
                          Math.round(
                            Math.min(
                              (item.baseFullSumm *
                                investorOneDebtor.firstPaymentShare) /
                                100,
                              item.baseFullSumm /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    Math.round(
                                      (differenceInDays(
                                        new Date(item.maturityDate),
                                        new Date(investorOneDebtor.payDate)
                                      ) /
                                        365) *
                                        10000
                                    )) /
                                    10000 /
                                    100)
                            ) * 100
                          ) / 100
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            marginLeft: '5px'
                          }}
                        >
                          {format(
                            new Date(investorOneDebtor.payDate),
                            'dd.MM.yyyy'
                          )}
                        </p>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          width: '325px',
                          alignItems: 'center',
                          margin: '5px 0',
                          justifyContent: 'space-between'
                        }}
                      >
                        {item.baseFullSumm -
                          (item.baseFullSumm *
                            Math.min(
                              investorOneDebtor.firstPaymentShare,
                              100 /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    (differenceInDays(
                                      new Date(item.maturityDate),
                                      new Date(investorOneDebtor.payDate)
                                    ) /
                                      365)) /
                                    100)
                            )) /
                            100 -
                          ((investorOneDebtor.baseDiscountRate *
                            1.2 *
                            (differenceInDays(
                              new Date(item.maturityDate),
                              new Date(investorOneDebtor.payDate)
                            ) /
                              365)) /
                            100) *
                            ((item.baseFullSumm *
                              Math.min(
                                investorOneDebtor.firstPaymentShare,
                                100 /
                                  (1 +
                                    (investorOneDebtor.baseDiscountRate *
                                      1.2 *
                                      (differenceInDays(
                                        new Date(item.maturityDate),
                                        new Date(investorOneDebtor.payDate)
                                      ) /
                                        365)) /
                                      100)
                              )) /
                              100) <
                        0.001
                          ? (0).toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })
                          : (
                              item.baseFullSumm -
                              (item.baseFullSumm *
                                Math.min(
                                  investorOneDebtor.firstPaymentShare,
                                  100 /
                                    (1 +
                                      (investorOneDebtor.baseDiscountRate *
                                        1.2 *
                                        (differenceInDays(
                                          new Date(item.maturityDate),
                                          new Date(investorOneDebtor.payDate)
                                        ) /
                                          365)) /
                                        100)
                                )) /
                                100 -
                              ((investorOneDebtor.baseDiscountRate *
                                1.2 *
                                (differenceInDays(
                                  new Date(item.maturityDate),
                                  new Date(investorOneDebtor.payDate)
                                ) /
                                  365)) /
                                100) *
                                ((item.baseFullSumm *
                                  Math.min(
                                    investorOneDebtor.firstPaymentShare,
                                    100 /
                                      (1 +
                                        (investorOneDebtor.baseDiscountRate *
                                          1.2 *
                                          (differenceInDays(
                                            new Date(item.maturityDate),
                                            new Date(investorOneDebtor.payDate)
                                          ) /
                                            365)) /
                                          100)
                                  )) /
                                  100)
                            ).toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}

                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            marginLeft: '5px'
                          }}
                        >
                          {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                        </p>
                      </p>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyledWrapped>
          )}
        </FullListWide>
      </ListSectionWide>
      {status == REQUEST_STATUSES.GOT && (
        <ConfirmSection style={{ marginTop: '10px' }}>
          <ConfirmSectionRightPart style={{ marginRight: '20px' }}>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Сумма требований
              </p>
              <p
                className="discount-1"
                id="discount-1"
                style={{ color: '#9FA6B2', fontSize: '14px' }}
              >
                {DiscountCounter().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}{' '}
                руб.
              </p>
            </DiscountItem>
            <DiscountItem style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '16px' }}>Сумма к получению</p>
              <p style={{ fontSize: '16px' }}>
                {DiscountCounterToGetFactor().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}{' '}
                руб.
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Прогноз стоимости факторинга
              </p>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                {PreConditionsFactor().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Прогноз окончательного расчета
              </p>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                {FinalCalcFactor().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </p>
            </DiscountItem>
          </ConfirmSectionRightPart>
        </ConfirmSection>
      )}

      <ConfirmSection>
        <ConfirmSectionRightPart style={{ marginRight: '20px' }}>
          <ConfirmSectionCheckBox>
            {confirmState === false ? (
              <SelectContainerInactive onClick={handleConfirmStateChange}>
                <SelectInactive />
              </SelectContainerInactive>
            ) : (
              <SelectContainerActive onClick={handleConfirmStateChange}>
                <SelectActive />
              </SelectContainerActive>
            )}
            <p>Выражаю согласие с условиями дисконтирования оплаты</p>
          </ConfirmSectionCheckBox>
          <BtnsBlock>
            <BackBtn onClick={onBackClick}>
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
            {confirmState === false ? (
              <CreateBtn
                style={{
                  backgroundColor: '#9FA6B2',
                  cursor: 'not-allowed'
                }}
              >
                <CreateBtnText>Далее</CreateBtnText>
              </CreateBtn>
            ) : (
              <CreateBtn onClick={onSignClick}>
                <CreateBtnText>Далее</CreateBtnText>
              </CreateBtn>
            )}
          </BtnsBlock>
        </ConfirmSectionRightPart>
      </ConfirmSection>
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier, SCFDebtor }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint,
      postCreateAgreementSupplierRole
    },
    dispatch
  );

const ApplicationCalcFactorPayerConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationCalcFactorPayer)
);

export { ApplicationCalcFactorPayerConnect as ApplicationCalcFactorPayer };
