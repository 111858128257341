import {
  SCF_STATUS_ACTIONS,
  ResponseData
} from 'entities/SCF/Supplier/model/reducers/SCFStatus';

export const req = () => ({
  type: SCF_STATUS_ACTIONS.REQ
});

export const got = (data: ResponseData) => ({
  type: SCF_STATUS_ACTIONS.GOT,
  data
});

export const err = (error: string) => ({
  type: SCF_STATUS_ACTIONS.ERR,
  error
});
