import { EXTERNAL_AGENT_REGISTER } from 'ExternalAgent/reducers/externalAgentRegister';

export interface RequestDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;

  legalForm: string;
  code?: string;
  companyName: string;

  email: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_REQ,
  data
});
export const got = (data: RequestDataType) => ({
  type: EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_GOT
});
export const err = (error: {}) => ({
  type: EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_ERR,
  error
});
export const reset = () => ({
  type: EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_RESET
});
