import * as React from 'react';
import { formTermString, toFloatFormatter } from 'src/shared/utils/Utils';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Input, Props as InputProps } from 'shared/ui/Input';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import {
  RangeSlider,
  Props as RangeSliderProps
} from './RangeSlider/RangeSlider';

export enum INPUT_RANGE_VALUE_TYPE {
  SUM = 'SUM',
  TERM = 'TERM'
}

interface OwnProps{
  disableSlider?: boolean;
  valueType: INPUT_RANGE_VALUE_TYPE;
}

type Props = OwnProps & InputProps & RangeSliderProps;

interface State {
  typedValue: string;
  selfcontrol: boolean;
}

const setNumberMask = max =>
  createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: ' ',
    suffix: ` ₽`,
    integerLimit: max.length,
    decimalLimit: 2,
    allowLeadingZeroes: true,
    allowDecimal: true
  });

export class InputRange extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      typedValue: props.value,
      selfcontrol: false
    };
  }

  onChange = (e: React.FormEvent<HTMLInputElement>, selfcontrol) => {
    const { max, onChange } = this.props;
    const { value } = e.currentTarget;
    this.setState({ typedValue: value, selfcontrol });
    if (!selfcontrol) {
      return onChange(e);
    }

    // logic only for INPUT_RANGE_VALUE_TYPE.TERM
    if (selfcontrol && this.props.valueType === INPUT_RANGE_VALUE_TYPE.TERM) {
      const parsedMonthes = this.parseMothAndYearsValue(value);
      if (value === formTermString(parsedMonthes)) {
        e.currentTarget.value = parsedMonthes + '';
        this.setState({ selfcontrol: false });
        return onChange(e);
      }
      return;
    }

    // Backup validation, can be usefull
    // if (toFloatFormatter(e.currentTarget.value) > max) {
    //   return;
    // }
  };

  defineValueFormation = () => {
    const { valueType, value } = this.props;

    switch (valueType) {
      case INPUT_RANGE_VALUE_TYPE.SUM:
        return value;
      case INPUT_RANGE_VALUE_TYPE.TERM:
        return formTermString(value);
      default:
        return value;
    }
  };

  defineMask = () => {
    const { valueType, max } = this.props;

    switch (valueType) {
      case INPUT_RANGE_VALUE_TYPE.SUM:
        return setNumberMask(max.toString());
      default:
        return undefined;
    }
  };

  parseMothAndYearsValue(value: string) {
    const extracted = (value.match(/\d+/g) || []).map(Number);
    if (!extracted || extracted.length === 0) {
      return 0;
    }
    const [yearsOrMonth, month] = extracted;
    if (extracted.length === 1) {
      const areYears = value.match(/(год|года|лет*)$/g);
      const areMonth = value.match(/(месяц|месяцев|месяца*)$/g);
      return areYears ? yearsOrMonth * 12 : areMonth ? yearsOrMonth : 0;
    }
    return (yearsOrMonth || 0) * 12 + (month || 0);
  }

  render() {
    const {
      min,
      max,
      step,
      valueType,
      disableSlider,
      value,
      onChange: onChangeInput,
      ...inputProps
    } = this.props;

    const {
      label,
      placeholder,
      required,
      error,
      onChange: onChangeSlider,
      ...sliderProps
    } = this.props;
    const { selfcontrol, typedValue } = this.state;
    const validValue = this.defineValueFormation();
    const val = selfcontrol ? typedValue : validValue;
    return (
      <Input
        {...inputProps}
        onChange={e => this.onChange(e, valueType === INPUT_RANGE_VALUE_TYPE.TERM)}
        mask={this.defineMask()}
        value={val}
        error={val && selfcontrol ? `Введите корректное значение` : error}
        renderRangeSlider={!disableSlider &&
          <RangeSlider
            {...sliderProps}
            onChange={e => this.onChange(e, false)}
          />
        }
      />
    );
  }
}
