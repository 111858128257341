import { lightBlue } from "shared/styled";
import styled from "styled-components";

// TODO refactor
export const PopupTrigger = styled.span`
margin-top: 3px;
color: ${lightBlue};
cursor: pointer;
`
export const FormWithoutAutocomplete = styled.form`

`
