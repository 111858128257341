import * as React from 'react';
import Transition from 'react-transition-group/Transition';

import { fadeClassName, scaleClassName } from 'shared/styled';

export enum ANIMATION_NAMES {
  NONE = 'NONE',
  FADE = 'FADE',
  SCALE = 'SCALE'
}

export interface Props {
  className?: string;
  in: boolean;
  timeout?: number;
  animationName?: ANIMATION_NAMES;
  mountOnEnter?: boolean;
  children: any;
}

const defaultProps = {
  className: '',
  timeout: 100,
  animationName: ANIMATION_NAMES.NONE,
  mountOnEnter: true,
  unmountOnExit: true
};

export class TransitionWrapper extends React.Component<Props> {
  static defaultProps = defaultProps;

  defineAnimationClassName = animationState => {
    switch (this.props.animationName) {
      case ANIMATION_NAMES.FADE:
        return fadeClassName(animationState);
      case ANIMATION_NAMES.SCALE:
        return scaleClassName(animationState);
      default:
        return animationState;
    }
  };

  render() {
    const {
      children,
      animationName,
      className,
      ...transitionProps
    } = this.props;

    return (
      <Transition {...transitionProps}>
        {animationState =>
          React.cloneElement(children, {
            className: `${this.defineAnimationClassName(
              animationState
            )} ${className}`
          })
        }
      </Transition>
    );
  }
}
