import styled from 'styled-components';

// TODO: wtf, fix to normal import  (styled-components error)
import { Label } from 'shared/ui/Label';
import { Error } from 'src/shared/ui/Error/Error';

import { gray, blue, darkGray } from 'shared/styled';

export const AgreementLabelBtn = styled.button.attrs({
  type: 'button'
})`
  padding: 0;

  outline: none;
  border: none;
  cursor: pointer;

  background-color: transparent;

  font-family: inherit;
  font-size: inherit;
  color: ${blue};
`;

export const CheckboxLabel = styled(Label)`
  cursor: pointer;
  font-size: 14px;
  color: ${({ disabled }) => (disabled ? gray : darkGray)};
`;

export const CheckboxError = styled(Error)`
  margin-top: 5px;
  position: absolute;
`;

export const CheckboxStyled = styled.input.attrs({
  type: 'checkbox'
})`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 17px;
  height: 17px;

  margin-right: 12px;

  cursor: pointer;
  outline: none;
  border-radius: 2px;
  border: 1px solid ${gray};

  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: none;
  background-color: #fff;

  &:disabled + label {
    color: ${gray};
  }

  &:hover,
  &:checked {
    border-color: ${blue};
  }

  &:checked {
    background-color: ${blue};
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="rgb(255,255,255)" d="M21.55 7.679L9.93 19.54a1.517 1.517 0 0 1-2.173 0l-5.306-5.417a1.59 1.59 0 0 1 0-2.219 1.513 1.513 0 0 1 2.173 0l4.22 4.308L19.376 5.46a1.513 1.513 0 0 1 2.173 0c.601.614.601 1.605 0 2.219z"/>
      </svg>
    ');
  }
`;
