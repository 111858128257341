import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostFinancialStatementsAllStoreState extends Reducer {}

export enum POST_FINANCIAL_STATEMENTS_ALL {
  REQ = 'POST_FINANCIAL_STATEMENTS_ALL_REQ',
  GOT = 'POST_FINANCIAL_STATEMENTS_ALL_GOT',
  ERR = 'POST_FINANCIAL_STATEMENTS_ALL_ERR',
  RESET = 'POST_FINANCIAL_STATEMENTS_ALL_RESET'
}

export const initialState: PostFinancialStatementsAllStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFinancialStatementsAll = (
  state: PostFinancialStatementsAllStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PostFinancialStatementsAllStoreState => {
  switch (action.type) {
    case POST_FINANCIAL_STATEMENTS_ALL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FINANCIAL_STATEMENTS_ALL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_FINANCIAL_STATEMENTS_ALL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_FINANCIAL_STATEMENTS_ALL.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default postFinancialStatementsAll;
