import { PATCH_SET_AVAILABLE_REGION } from '../reducers/patchSetAvailableRegion';

export const req = (id: string, region: string) => ({
  type: PATCH_SET_AVAILABLE_REGION.REQ,
  id,
  region
});

export const got = () => ({
  type: PATCH_SET_AVAILABLE_REGION.GOT
});

export const err = (error: {}) => ({
  type: PATCH_SET_AVAILABLE_REGION.ERR,
  error
});
