import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { updateLocalProperty } from '../actions/localProperties';
import { got, err } from '../actions/uploadPropertyDocuments';
import PropertiesApi from '../api';
import { STORE } from 'globaltypes';
import { Document } from 'Application/types';

import {
  PropertyDocumentsRequestData,
  UPLOAD_PROPERTY_DOCUMENTS,
} from '../reducers/uploadPropertyDocuments';

function* uploadPropertyDocuments(action: {
  type: UPLOAD_PROPERTY_DOCUMENTS;
  // TODO rename
  documents: PropertyDocumentsRequestData
}): Generator {
  try {
    const { files, ...apiRequestData } = action.documents;

    // TODO fix documents deletion
    const newDocuments = yield all(
      files.map(function*(file) {
        const formData = new FormData();
        formData.append('file', file);
        const requestData = {
          ...apiRequestData,
          metaInfo: file.metaInfo,
          file: formData
        }
        return yield call(PropertiesApi.uploadPropertyDocuments, requestData);
      })
    );

    const oldDocuments = yield select(
      ({ Properties }: STORE) =>
        Properties.localProperties.properties.find(p => p.id === action.documents.propertyId).documents
    );

    if (oldDocuments)
      yield put(
        updateLocalProperty({ id: action.documents.propertyId, documents: [...oldDocuments as Document[], ...newDocuments as Document[]] })
      )
    else {
      yield put(
        updateLocalProperty({ id: action.documents.propertyId, documents: [...newDocuments as Document[]] })
      )
    }

    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* uploadPropertyDocumentsSaga(): Generator {
  yield takeLatest(UPLOAD_PROPERTY_DOCUMENTS.REQ, uploadPropertyDocuments);
}
