import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getLead';

import {
  GET_LEAD,
} from '../reducers/getLead';
import LeadsApi from '../api';

function* getLead(action: {
  type: GET_LEAD;
  id: number
}): Generator {
  try {

    const res:any = yield call(LeadsApi.getLead, action.id);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(GET_LEAD.REQ, getLead);
}
