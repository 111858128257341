import { combineReducers } from 'redux';
import getLeads, { GetLeadsStoreState } from './getLeads';
import getApplications, { GetApplicationsStoreState } from './getApplications';
import getApplicationsCompany, {
  GetApplicationsCompanyStoreState
} from './getApplicationsCompany';

export interface LeadsOfCompaniesReducers {
  getLeads: GetLeadsStoreState;
  getApplications: GetApplicationsStoreState;
  getApplicationsCompany: GetApplicationsCompanyStoreState;
}

export const LeadsOfCompanies = combineReducers({
  getLeads,
  getApplications,
  getApplicationsCompany
});
