import { combineReducers } from 'redux';
import getMonetaryClaimsList, {
  GetMonetaryClaimsListStoreState
} from './getMonetaryClaimsList';

export interface MonetaryClaimsReducers {
  getMonetaryClaimsList: GetMonetaryClaimsListStoreState;
}

export const MonetaryClaims = combineReducers({
  getMonetaryClaimsList
});
