import { combineReducers } from 'redux';

import getInternalAgentsList, {
  GetInternalAgentsListStoreState
} from './getInternalAgentsList';
import delegateToInternalAgent, { DelegateInternalAgentStoreState } from './delegateToInternalAgent';

export interface InternalAgentsManagerReducers {
  getInternalAgentsList: GetInternalAgentsListStoreState;
  delegateToInternalAgent: DelegateInternalAgentStoreState;
}

export const InternalAgentsManager = combineReducers({
  getInternalAgentsList,
  delegateToInternalAgent
});
