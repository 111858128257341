import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { ValidatorMessages } from 'shared/constants';

import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';

export const createValidaton = () =>
  new Validator<SetFactoringApplicationType>({
    debtorType: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
