import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getOpenLoanLimits';

export interface GetOpenLoanLimitsStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_OPEN_LOAN_LIMITS_CLIENT {
  REQ = 'GET_OPEN_LOAN_LIMITS_CLIENT_REQ',
  GOT = 'GET_OPEN_LOAN_LIMITS_CLIENT_GOT',
  ERR = 'GET_OPEN_LOAN_LIMITS_CLIENT_ERR'
}

export const initialState: GetOpenLoanLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getOpenLoanLimitsClient = (
  state: GetOpenLoanLimitsStoreState = initialState,
  action: {
    type: GET_OPEN_LOAN_LIMITS_CLIENT;
    data: ResponseData[];
    error: {};
  }
): GetOpenLoanLimitsStoreState => {
  switch (action.type) {
    case GET_OPEN_LOAN_LIMITS_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_OPEN_LOAN_LIMITS_CLIENT.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_OPEN_LOAN_LIMITS_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getOpenLoanLimitsClient;
