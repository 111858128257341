import { POST_FIN_FILE } from '../reducers/postFinFile';

export const req = (token: string, data: FormData) => ({
  type: POST_FIN_FILE.REQ,
  data,
  token
});

export const got = () => ({
  type: POST_FIN_FILE.GOT
});

export const err = (error: {}) => ({
  type: POST_FIN_FILE.ERR,
  error
});
