import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import { req as signInByLink } from 'User/actions/signInByLink';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { IncorrectAuthModal } from './IncorrectAuth/IncorrectAuth';

interface StateProps {
  signInStatus: REQUEST_STATUSES;
  signInError: ResponseError;
}

interface DispatchToProps {
  signInByLink: (key: string) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  key: string;
}

type Props = RouteComponentProps<MatchParams> & StateProps & DispatchToProps;

const LinkAuthenticationPage: React.FC<Props> = ({
  signInByLink,
  signInError,
  openModal,
  match
}) => {
  React.useEffect(
    () => {
      signInByLink(match.params.key);
    },
    [match.params.key]
  );

  React.useEffect(
    () => {
      if (signInError.code === 404) {
        openModal({ name: MODAL_NAMES.INCORRECT_AUTH_DATA_MODAL });
      }
    },
    [signInError.code]
  );

  return <IncorrectAuthModal />;
};

const mapStateToProps = ({ User }: STORE): StateProps => ({
  signInStatus: User.signInByLink.status,
  signInError: User.signInByLink.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ signInByLink, openModal }, dispatch);

const LinkAuthenticationPageConnect = withRouter(
  connect<StateProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LinkAuthenticationPage)
);

export { LinkAuthenticationPageConnect as LinkAuthenticationPage };
