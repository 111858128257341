import { APPLICATION_TYPES, ApplicationWrite } from 'Application/types';

export type SetInitializationApplicationType = Partial<ApplicationWrite>;

export enum INITIALIZATION_FORM_ACTIONS {
  SET = 'SET_INITIALIZATION_FORM_DATA',
  RESET = 'RESET_INITIALIZATION_FORM_DATA'
}

export const initialState: SetInitializationApplicationType = {
  id: null,
  financingType: APPLICATION_TYPES.NONE,
  refinancing: false,
  clientCompanyName: '',
  code: '',
  customerInn: '',
  customerName: '',
  amount: 0,
  taxSystem: '',
  term: 0,
  borrowerCompanies: [],
  // registry: [],
  agreementDocuments: [],
  groupDocuments: [],
  registries: [],
  termEndDate: '',
  termStartDate: ''
};

const setInitializationFormData = (
  state: SetInitializationApplicationType = initialState,
  action: {
    type: INITIALIZATION_FORM_ACTIONS;
    data: SetInitializationApplicationType;
  }
): SetInitializationApplicationType => {
  switch (action.type) {
    case INITIALIZATION_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case INITIALIZATION_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setInitializationFormData;
