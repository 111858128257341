import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { CompanyRead } from '../actions/setCompanyFormData';

export interface GetCompanyStoreState extends Reducer {
  company: CompanyRead;
}

export enum GET_COMPANY {
  REQ = 'GET_COMPANY_REQ',
  GOT = 'GET_COMPANY_GOT',
  ERR = 'GET_COMPANY_ERR'
}

export const initialState: GetCompanyStoreState = {
  status: REQUEST_STATUSES.NONE,
  company: {
    inn: ''
  },
  error: {}
};

const getCompany = (
  state: GetCompanyStoreState = initialState,
  action: {
    type: string;
    company: CompanyRead;
    error: {};
  }
): GetCompanyStoreState => {
  switch (action.type) {
    case GET_COMPANY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        company: action.company
      };
    case GET_COMPANY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompany;
