import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import {
  ContractFinancingRead,
  CreditingApplicationRead,
  FactoringApplicationRead,
  FinancingApplicationRead,
  GuaranteesApplicationRead
} from 'src/features/Application/types';

export type ResponseData = Partial<
  | FactoringApplicationRead
  | GuaranteesApplicationRead
  | CreditingApplicationRead
  | FinancingApplicationRead
  | ContractFinancingRead
>;

export interface GetApplicationBankStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_APPLICATION_BANK {
  REQ = 'GET_APPLICATION_BANK_REQ',
  GOT = 'GET_APPLICATION_BANK_GOT',
  ERR = 'GET_APPLICATION_BANK_ERR',
  RESET = 'GET_APPLICATION_BANK_RESET'
}

export const initialState: GetApplicationBankStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

export const getApplicationBank = (
  state: GetApplicationBankStoreState = initialState,
  action: {
    type: GET_APPLICATION_BANK;
    data: ResponseData;
    error: {};
  }
): GetApplicationBankStoreState => {
  switch (action.type) {
    case GET_APPLICATION_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_APPLICATION_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_APPLICATION_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_APPLICATION_BANK.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
