import * as React from 'react';
import { OwnProps as CheckboxProps } from 'shared/ui/Checkbox';
import { Label } from 'shared/ui/Label';
import {
  CheckboxGroupStyled,
  CheckboxGroupError,
  CheckboxStyled
} from './styles';

interface Props {
  checkboxes: Array<Partial<CheckboxProps>>;
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
  className?: string;
}

export const CheckboxGroup: React.FC<Props> = ({
  name,
  label = '',
  className,
  required = false,
  error,
  checkboxes
}: Props) => (
  <CheckboxGroupStyled legendId={name} className={className}>
    <Label name={name} label={label} required={required} />

    {checkboxes.map((item, key) => (
      <CheckboxStyled key={key} name={name} {...item}>
        {!!item.children && item.children}
      </CheckboxStyled>
    ))}

    <CheckboxGroupError error={error} />
  </CheckboxGroupStyled>
);
