import { CachedStoreState as CachedReducer } from 'App/reducers/cached';
import { UserReducers } from 'User/reducers';
import { ApplicationReducers } from 'Application/reducers';
import { ExternalAgentReducers } from 'ExternalAgent/reducers';
import { BusinessReducers } from 'Business/reducers';
import { PermissionsReducers } from 'Permissions/reducers';
import { ModalReducers } from 'Modal/reducers';
import { CommentReducers } from 'Comments/reducers';
import { BorrowerCompaniesReducers } from 'BorrowerCompanies/reducers';
import { InternalAgentsManagerReducers } from 'src/features/InternalAgentsManager/reducers';
import { FiltersReducers } from 'Filters/reducers';
import { ApiKeysReducers } from 'src/features/ApiKeys/reducers';
import { LeadsReducers } from 'src/features/Leads/reducers';

import { CompaniesReducers } from 'src/features/Companies/reducers';
import { FactoringLimitsReducers } from 'src/features/FactoringLimits/reducers';
import { ContactsReducers } from 'src/features/Contacts/reducers';
import { ContactDetailReducers } from 'src/features/ContactDetailsForContact/reducers';

import { FactoringCheckerReducers } from 'src/features/FactoringChecker/reducers';

import { PropertiesReducers } from 'src/features/Properties/reducers';
import { HoldingsReducers } from 'src/features/Holdings/reducers';
import { DebtorsReducers } from 'src/features/Debtors/reducers';
import { FinbanksReducers } from 'src/features/Finbanks/reducers';
import { ProductsReducers } from 'src/features/Products/reducers';
import { ContactsOfProductsReducers } from 'src/features/ContactsOfProducts/reducers';
import { ExternalCredentialsOfProductsReducers } from 'src/features/ExternalCredentialsOfProducts/reducers';
import { ExternalCredentialsReducers } from 'src/features/ExternalCredentials/reducers';
import { SCFReducers } from 'src/features/SCF/reducers';
import { SCFDebtorReducers } from 'entities/SCF/Debtor/model/reducers';
import { SCFFactorReducers } from 'entities/SCF/Factor/model/reducers';
import { SCFSupplierReducers } from 'entities/SCF/Supplier/model/reducers';
import { SCFInternalReducers } from 'entities/SCF/Internal/model/reducers';
import { UsersReducers } from 'src/features/Users/reducers';
import { SupplierReducers } from 'src/features/UserSuppliers/reducers';
import { UserDebtorsReducers } from 'src/features/UserDebtors/reducers';
import { UserFactorsReducers } from 'src/features/UserFactors/reducers';
import { CooperationReducers } from 'src/features/CooperationForProducts/reducers';
import { CustomerRequirementsReducers } from 'src/features/CustomerRequirements/reducers';
import { ContractLogicReducers } from 'src/features/ContractLogic/reducers';
import { StopFactorsReducers } from 'src/features/StopFactors/reducers';
import { BasisParamsReducers } from 'src/features/BasicParametersForProducts/reducers';
import { PledgeReducers } from 'src/features/ProductPledge/reducers';
import { LeadsOfCompaniesReducers } from 'src/features/ApplicationForCompanies/reducers';
import { ActivitieOfCompaniesReducers } from 'src/features/ActivitiesOfCompanies/reducers';
import { ActivitiesReducers } from 'src/features/Activities/reducers';
import { AgentsReducers } from 'src/features/Agents/reducers';
import { PlatformsReducers } from 'src/features/PlatformsForProducts/reducers';
import { CounterpartiesReducers } from 'src/features/Counterparties/reducers';
import { BankProductsReducers } from 'src/features/BankProducts/reducers';
import { ClientsReducers } from 'src/features/Clients/reducers';
import { QuestionnaireReducers } from 'src/features/Questionnaire/reducers';
import { ClientsForAgentReducers } from 'src/features/ClientsForAgent/reducers';
import { DossierReducers } from 'src/features/DossierForCompany/reducers';
import { FinancialStatementsReducers } from 'src/features/FinancialForCompany/reducers';
import { ProductsBankReducers } from 'src/features/ProductsForBank/reducers';
import { LimitsForBankReducers } from 'src/features/LimitsForBank/reducers';
import { IntegrationApplicationsReducers } from 'src/features/IntegrationApplications/reducers';
import { BeneficiariesReducers } from 'src/features/Beneficiaries/reducers';
import { PersonsReducers } from 'src/features/Persons/reducers';
import { TendersReducers } from 'src/features/Tenders/reducers';
import { BuyersToSupplierLinkReducers } from 'src/features/BuyersToSupplierLink/reducers';
import { BankAccountReducers } from 'src/features/BankAccount/reducers';
import { SupplyContractsReducers } from 'src/features/SupplyContracts/reducers';
import { FactoringContractsReducers } from 'src/features/FactoringContracts/reducers';
import { DocumentListReducers } from 'src/features/DocumentList/reducers';
import { PersonReducers } from 'src/features/Person/reducers';
import { MonetaryClaimsReducers } from 'src/features/MonetaryClaims/reducers';
import { PreapprovedLimitsReducers } from 'src/features/PreapprovedLimits/reducers';
import { EarlyPaymentReducers } from 'src/features/EarlyPaymentApplications/reducers';
import { ClientRoleReducers } from 'entities/Cabinet/Client/model/reducers';
import { InternalAgentRoleReducers } from 'entities/Cabinet/InternalAgent/model/reducers';
import { ExternalAgentRoleReducers } from 'entities/Cabinet/ExternalAgent/model/reducers';
import { BankRoleReducers } from 'entities/Cabinet/Bank/model/reducers';
import { PreApprovalReducers } from 'src/features/PreApproval/reducers';
import { CompaniesListCRMReducers } from 'src/features/CompaniesLists/reducers';

export enum REQUEST_STATUSES {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  GOT = 'GOT',
  ERROR = 'ERROR'
}

export interface ResponseError {
  code?: number;
  message?: string;
  body?: any;
}

export interface Reducer {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export interface STORE {
  FactoringChecker: FactoringCheckerReducers;

  Properties: PropertiesReducers;

  Cached: CachedReducer;
  User: UserReducers;
  Application: ApplicationReducers;
  ExternalAgent: ExternalAgentReducers;
  InternalAgentsManager: InternalAgentsManagerReducers;
  Permissions: PermissionsReducers;
  Business: BusinessReducers;
  Modal: ModalReducers;
  Comment: CommentReducers;
  BorrowerCompanies: BorrowerCompaniesReducers;
  Filters: FiltersReducers;
  ApiKeys: ApiKeysReducers;
  Leads: LeadsReducers;
  Companies: CompaniesReducers;
  FactoringLimits: FactoringLimitsReducers;
  Contacts: ContactsReducers;
  ContactDetail: ContactDetailReducers;
  Holdings: HoldingsReducers;
  SCF: SCFReducers;
  SCFDebtor: SCFDebtorReducers;
  SCFFactor: SCFFactorReducers;
  SCFSupplier: SCFSupplierReducers;
  SCFInternal: SCFInternalReducers;
  EarlyPayment: EarlyPaymentReducers;
  ClientRole: ClientRoleReducers;
  InternalAgentRole: InternalAgentRoleReducers;
  ExternalAgentRole: ExternalAgentRoleReducers;
  BankRole: BankRoleReducers;
  PreApproval: PreApprovalReducers;
}

// TODO - delete unused (was copied from STORE)
export interface CRM {
  Properties: PropertiesReducers;

  Cached: CachedReducer;
  User: UserReducers;
  Application: ApplicationReducers;
  //ExternalAgent: ExternalAgentReducers;
  //InternalAgentsManager: InternalAgentsManagerReducers;
  Permissions: PermissionsReducers;
  //Business: BusinessReducers;
  //Modal: ModalReducers;
  //Comment: CommentReducers;
  //BorrowerCompanies: BorrowerCompaniesReducers;
  Filters: FiltersReducers;
  Companies: CompaniesReducers;
  FactoringLimits: FactoringLimitsReducers;
  Debtors: DebtorsReducers;
  Finbanks: FinbanksReducers;
  Contacts: ContactsReducers;
  Holdings: HoldingsReducers;
  Products: ProductsReducers;
  ContactsOfProducts: ContactsOfProductsReducers;
  ExternalCredentialsOfProducts: ExternalCredentialsOfProductsReducers;
  ExternalCredentials: ExternalCredentialsReducers;
  Users: UsersReducers;
  Suppliers: SupplierReducers;
  UserDebtors: UserDebtorsReducers;
  UserFactors: UserFactorsReducers;
  Cooperation: CooperationReducers;
  CustomerRequirements: CustomerRequirementsReducers;
  Contract: ContractLogicReducers;
  StopFactors: StopFactorsReducers;
  BasisParams: BasisParamsReducers;
  Pledge: PledgeReducers;
  LeadsOfCompanies: LeadsOfCompaniesReducers;
  ActivitiesOfCompanies: ActivitieOfCompaniesReducers;
  Activities: ActivitiesReducers;
  Agents: AgentsReducers;
  Platforms: PlatformsReducers;
  Counterparties: CounterpartiesReducers;
  BankProducts: BankProductsReducers;
  Clients: ClientsReducers;
  Questionnaire: QuestionnaireReducers;
  ClientsForAgent: ClientsForAgentReducers;
  Dossier: DossierReducers;
  FinancialStatements: FinancialStatementsReducers;
  ProductsBank: ProductsBankReducers;
  LimitsForBank: LimitsForBankReducers;
  IntegrationApplications: IntegrationApplicationsReducers;
  Beneficiaries: BeneficiariesReducers;
  Persons: PersonsReducers;
  Tenders: TendersReducers;
  BuyersToSupplierLink: BuyersToSupplierLinkReducers;
  BankAccount: BankAccountReducers;
  SupplyContracts: SupplyContractsReducers;
  FactoringContracts: FactoringContractsReducers;
  DocumentList: DocumentListReducers;
  Person: PersonReducers;
  MonetaryClaims: MonetaryClaimsReducers;
  PreapprovedLimits: PreapprovedLimitsReducers;
  CompaniesListCRM: CompaniesListCRMReducers;
}

export enum USER_PERMISSIONS {
  NONE = '',
  ADMIN = 'Admin',
  DELEGATE_TO_INTERNAL_AGENTS = 'DelegateApplicationsToInternalAgents',
  CREATE_APPLICATIONS = 'CreateApplications',
  ADD_BANK_MANAGERS = 'AddBankManagers',
  MANAGE_EXTERNAL_AGENTS = 'ManageExternalAgents',
  INTERNAL_AGENT = 'InternalAgent',
  EXTERNAL_AGENT = 'ExternalAgent',
  BANK_ADMIN = 'BankAdmin',
  BANK_MANAGER = 'BankManager',
  CLIENT = 'Client',
  SEE_ALL_APPLICATIONS = 'SeeAllApplications',
  DELETE_APPLICATIONS = 'DeleteApplications',
  MANAGE_INTERNAL_AGENTS = 'ManageInternalAgents',
  CONTROL_ANY_APPLICATION = 'ControlAnyApplication',
  CREATE_LEAD = 'CreateLead',
  LEADS_MANAGER = 'LeadsManager',
  LEADOGENERATOR = 'Leadogenerator',
  SUPPLIER = 'Supplier',
  DEBTOR = 'Debtor',
  FACTOR = 'Factor',
  DEBTOR_SIGN = 'DebtorSign'
}

export enum LEGAL_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTITY = 'ENTITY'
}
