import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  STORE,
  REQUEST_STATUSES,
  USER_PERMISSIONS,
  ResponseError
} from 'globaltypes';
import { history } from 'src/shared/utils/History';

import {
  Document,
  APPLICATION_STATUSES,
  SELECT_STATUS,
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES
} from 'Application/types';

import { TransitionWrapper, ANIMATION_NAMES } from 'Common';
import { Select } from 'shared/ui/Select';
import {
  Spinner,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader,
  Pagination
} from 'Layouts/components';

import {
  ApplicationViewStyled,
  LoaderContainerStyled,
  ViewSeparatorTop,
  EditApplicationBtn,
  ActivityWrapper,
  SentToBankStyled,
  ShowToAgentWrapper,
  CheckboxStyleShowToAgent,
  ProductApiContainer,
  Spinner as SpinnerApi,
  Limit,
  Rejection,
  OpenLoanLimitsContainer,
  UploadButton,
  TitleTabContainer,
  RejectionToggle,
  CompanyHead,
  TableWrapper,
  TDDocumentName,
  Period,
  IsNecessarily,
  FileContainer,
  IsUploadContainer,
  DownloadFile,
  ButtonContainer,
  PlatformContainer,
  TooltipBoxStyle,
  RefreshIcon
} from './styles';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { ResponseData as ResponseActivities } from 'src/features/Application/actions/getActivitiesByApplication';
import { ResponseData as ResponseProductOfApplication } from 'src/features/Application/actions/getProductApplication';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as postFinFile } from 'src/features/BorrowerCompanies/actions/postUploadFinDocInternal';
import { req as postNotFinFile } from 'src/features/BorrowerCompanies/actions/postUploadNonFinDocInternal';
import { req as postSelectApplication } from 'Application/actions/postSelectApplication';
import { req as getDownloadDocumets } from 'src/features/DossierForCompany/actions/getDownloadDocument';
import { req as getDonwloadNonFinDocument } from 'src/features/DossierForCompany/actions/getDonwloadNonFinDocument';
import {
  req as sendToBank,
  reset as resetSentToBank
} from 'src/features/Application/actions/putSendToBank';
import {
  req as getOpenLoanLimits,
  ResponseData as OpenLoanLimitsData
} from 'Application/actions/getOpenLoanLimits';
import { req as postExternalApiAlfaBank } from 'Application/actions/postExternalApiAlfaBank';
import { req as postExternalApiPSB } from 'Application/actions/postExternalApiPSB';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { req as putSetPreapproved } from 'Application/actions/putSetPreapproved';
import { req as putSetApproved } from 'Application/actions/putSetApproved';
import { req as putRejected } from 'src/features/Application/actions/putRejected';
import { req as postSendCompanyToScoring } from 'src/features/Leads/actions/postSendCompanyToScoring';
import {
  req as getAllOffers,
  reset as resetAllOffers,
  ResponseData as ResAllOffers
} from 'Application/actions/getAllOffers';
import {
  req as postOfferSend,
  reset as resetOfferSend
} from 'Application/actions/postOfferSend';
import {
  DocumentListItems,
  req as getDocumentListByApplicationId,
  reset as resetDocumentListByApplicationId,
  ResponseData as ResDocumentListByApplicationId
} from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { req as postDocumentsOfDocumentList } from 'src/features/Application/actions/postDocumentsOfDocumentLists';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { CheckboxStyle } from 'src/features/Leads/components/LeadView/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faSpinner,
  faSyncAlt,
  faTimes,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  CloseButton,
  PopupContainer,
  PopupContent
} from 'src/features/Products/components/ProductView/styled';
import {
  ModalContainer,
  ProductOffersSection,
  PurchaseContent,
  SendApi
} from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import { PersonDocumentsType } from 'src/features/DocumentList/types';
import {
  RejectReasonModal,
  rejectReasons
} from 'src/features/Application/components/ApplicationView/EachApplicationView/RejectReasonModal';
import { DelegateInternalAgentToApplicationSelect } from 'src/features/Application/components/ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { SwitchBanksBlock } from './SwitchBanksBlock/SwitchBanksBlock';
import { ContentContainer } from './ContentContainer/ContentContainer';
import { ChooseBanksModal } from './ChooseBanksModal/ChooseBanksModal';
import { ActionButtonsBlock } from './ActionButtonsBlock/ActionButtonsBlock';
import { ActionModal } from './ActionModal/ActionModal';
import { CommentTabs } from './CommentTabs/CommentTabs';
import { Button } from 'shared/ui/Button';
import { redirectToApplication } from 'shared/lib/routing/redirectToApplication';

export interface Info {
  name: string;
  value: JSX.Element | string;
}

export interface IApplicationInfo {
  mainInfo: Info[];
  subInfo: Info[];
}

export interface IApplicationDocuments {
  documents?: {
    general: Document[];
    company: Document[] | any;
  };
}

export type IApplicationData = IApplicationInfo & IApplicationDocuments;

export interface IOwnProps extends IApplicationData {
  children?: any;
}

interface StateToProps {
  internalApplication: ResInternalApplication;
  statusInternalApplication: REQUEST_STATUSES;
  switchBankStatus: REQUEST_STATUSES;
  sendToBankStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  statusProductOfApplication: REQUEST_STATUSES;
  errorProductOfApplication: ResponseError;
  activities: ResponseActivities;
  statusActivities: REQUEST_STATUSES;
  errorActivities: ResponseError;
  statusPostAlfaBank: REQUEST_STATUSES;
  statusPostPSB: REQUEST_STATUSES;
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  errorSendToBank: ResponseError;
  openLoanLimits: OpenLoanLimitsData;
  statusOpenLoanLimits: REQUEST_STATUSES;
  errorOpenLoanLimits: ResponseError;
  statusCompanyToScoring: REQUEST_STATUSES;
  allOffers: ResAllOffers[];
  statusAllOffers: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  sendApiError: string;
  documentList: ResDocumentListByApplicationId;
  statusDocumentList: REQUEST_STATUSES;
  statusFinDocUpload: REQUEST_STATUSES;
  statusNotFinDocUpload: REQUEST_STATUSES;
  statusPostDocumentsOfDocumentList: REQUEST_STATUSES;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  postSelectApplication: (data: any) => void;
  sendToBank: (id: number) => void;
  postExternalApiAlfaBank: (ApplicationID: number) => void;
  postExternalApiPSB: (ApplicationID: number) => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  resetSentToBank: () => void;
  putSetPreapproved: (id: number) => void;
  putSetApproved: (id: number) => void;
  putRejected: (id: number, { rejectSubject, rejectReason }) => void;
  getOpenLoanLimits: (inn: string) => void;
  postSendCompanyToScoring: (inn: string) => void;
  getAllOffers: (id: string) => void;
  resetAllOffers: () => void;
  postOfferSend: (id: number) => void;
  resetOfferSend: () => void;
  getDocumentListByApplicationId: (guid: string) => void;
  resetDocumentListByApplicationId: () => void;
  postFinFile: (guid: string, data: FormData) => void;
  postNotFinFile: (guid: string, data: FormData) => void;
  getDownloadDocumets: (guid: string) => void;
  getDonwloadNonFinDocument: (guid: string) => void;
  postDocumentsOfDocumentList: (documentListGuid: string) => void;
}

interface MatchParams {
  id: string;
  inBankId?: string;
}

type Props = RouteComponentProps<MatchParams> &
  IOwnProps &
  StateToProps &
  DispatchToProps;

const EachApplicationView: React.FC<Props> = ({
  setApplicationProcessMode,
  internalApplication,
  statusInternalApplication,
  switchBankStatus,
  sendToBankStatus,
  permissions,
  roles,
  mainInfo,
  subInfo,
  documents,
  companyHints,
  productOfApplication,
  postSelectApplication,
  statusProductOfApplication,
  errorProductOfApplication,
  sendToBank,
  activities,
  statusActivities,
  errorActivities,
  postExternalApiAlfaBank,
  statusPostAlfaBank,
  postExternalApiPSB,
  statusPostPSB,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  statusSendToBank,
  errorSendToBank,
  resetSentToBank,
  putSetPreapproved,
  putSetApproved,
  putRejected,
  getOpenLoanLimits,
  openLoanLimits,
  statusOpenLoanLimits,
  errorOpenLoanLimits,
  postSendCompanyToScoring,
  statusCompanyToScoring,
  getAllOffers,
  resetAllOffers,
  allOffers,
  statusAllOffers,
  postOfferSend,
  resetOfferSend,
  statusSendApi,
  sendApiError,
  getDocumentListByApplicationId,
  resetDocumentListByApplicationId,
  documentList,
  statusDocumentList,
  postFinFile,
  statusFinDocUpload,
  postNotFinFile,
  statusNotFinDocUpload,
  getDonwloadNonFinDocument,
  getDownloadDocumets,
  postDocumentsOfDocumentList,
  statusPostDocumentsOfDocumentList,
  children,
  match
}: Props) => {
  const productRef = React.useRef<HTMLDivElement>(null);
  const sendApiErrorModalRef = React.useRef<HTMLDivElement>(null);
  const [productStatus, setProductStatus] = React.useState(
    productOfApplication.reduce((acc, product) => {
      acc[product.id] = product.status;
      return acc;
    }, {})
  );
  const [productId, setProductId] = React.useState(null);
  const [isRejectModal, setIsRejectModal] = React.useState(false);
  const [isSendApiError, setIsSendApiError] = React.useState(false);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState([]);
  const [isSectionProductOffers, setIsSectionProductOffers] = React.useState(
    true
  );
  const [file, setFile] = React.useState({ id: null, file: null });
  const [isSectionDocumentList, setIsSectionDocumentList] = React.useState(
    true
  );
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentListItems[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);
  const [companyName, setCompanyName] = React.useState('');
  const [companyINN, setCompanyINN] = React.useState('');
  const [isRejection, setIsRejection] = React.useState(false);
  const [offerId, setOfferId] = React.useState(null);

  React.useEffect(() => {
    return () => resetDocumentListByApplicationId();
  }, []);

  React.useEffect(
    () => {
      if (
        statusDocumentList === REQUEST_STATUSES.GOT &&
        !!documentList.items.length
      ) {
        setCompanyINN(documentList.items[0].companyINN);
        setCompanyName(documentList.items[0].companyShortName);

        const filtredInnDocuments = documentList.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        documentList.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            isUploadedExternal,
            guid,
            documentCategory,
            documentTypeId,
            extTypeDescription,
            documentGuid
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                isUploadedExternal,
                guid,
                documentCategory,
                documentTypeId,
                extTypeDescription,
                documentGuid
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [statusDocumentList]
  );

  React.useEffect(
    () => {
      if (statusPostDocumentsOfDocumentList === REQUEST_STATUSES.GOT) {
        getDocumentListByApplicationId(internalApplication.guid);
      }
    },
    [statusPostDocumentsOfDocumentList]
  );

  React.useEffect(
    () => {
      if (statusInternalApplication === REQUEST_STATUSES.GOT) {
        getDocumentListByApplicationId(internalApplication.guid);

        redirectToApplication(internalApplication);
      }
    },
    [internalApplication, statusInternalApplication]
  );

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        setFiltredFinancailsSummaryData(
          financailsSummaryData.sort((a, b) => a.year - b.year)
        );
      }
    },
    [financailsSummaryData]
  );

  React.useEffect(
    () => {
      if (
        statusInternalApplication === REQUEST_STATUSES.GOT &&
        !permissions.includes(USER_PERMISSIONS.CLIENT) &&
        !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
        !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
        !match.params.inBankId
      ) {
        getOpenLoanLimits(internalApplication.code);
      }
    },
    [permissions, statusInternalApplication]
  );

  React.useEffect(
    () => {
      if (match.params.id && !match.params.inBankId) {
        getAllOffers(match.params.id);
      }

      return () => resetAllOffers();
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      if (statusSendApi === REQUEST_STATUSES.GOT) {
        getAllOffers(match.params.id);
      }

      if (statusSendApi === REQUEST_STATUSES.ERROR) {
        setIsSendApiError(true);

        return () => resetOfferSend();
      }
    },
    [statusSendApi]
  );

  const onProductClick = (id: number) => {
    window.open(`/crm/products/${id}`, '_blank');
  };

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      setIsSendApiError(false);
    }
  };

  const navigateToAddActivity = () => {
    const state = {
      inn: internalApplication.code,
      applicationCode: match.params.id
    };

    localStorage.setItem('applicationState', JSON.stringify(state));

    window.open('/crm/activities/new', '_blank');
  };

  const handleSentToBank = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    sendToBank(id);
  };

  const navigateToActivities = (id: number) => {
    window.open(`/crm/activities/${id}`, '_blank');
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(internalApplication.code, year));
    });
  };

  const closePopup = () => {
    resetSentToBank();
  };

  const handleOptionClick = (value: string, id: number) => {
    if (value === '1') {
      sendToBank(id);
    } else if (value === '2') {
      putSetPreapproved(id);
    } else if (value === '3') {
      putSetApproved(id);
    } else if (value === '4') {
      setIsRejectModal(true);
    }

    setProductId(id);
    setProductStatus(prevStatus => ({ ...prevStatus, [id]: value }));
  };

  function getFilteredOptions(status: number) {
    return Object.entries(SELECT_STATUS)
      .map(([key, value], index) => {
        if (status === 1 && key === '3') {
          return null;
        }
        if (status === 3 && key === '1') {
          return null;
        }
        return {
          id: (index + 1).toString(),
          name: `${key} - ${value}`
        };
      })
      .filter(option => option !== null);
  }

  const getRejectReasonMessage = (rejectCode: number) => {
    const reasons = Object.values(rejectReasons).flat();
    const matchedReason = reasons.find(reason =>
      reason.startsWith(`${rejectCode}`)
    );
    return matchedReason;
  };

  const sendCompanyToScoring = () => {
    postSendCompanyToScoring(internalApplication.code);
  };

  const filteredOffers = allOffers.filter(offer => {
    if (!isRejection && offer.offerBankStatus === 1) {
      return false;
    }
    return true;
  });

  const handleSetFile = (guid: string, file) => {
    setFile({ id: guid, file });
  };

  const postFile = (docCategory: number, guid: string) => {
    if (file) {
      const formData = new FormData();
      formData.append('guid', guid);
      formData.append('file', file.file);

      if (docCategory === 1) {
        postFinFile(match.params.id, formData);
      } else if (docCategory === 2) {
        postNotFinFile(match.params.id, formData);
      }
    }
  };

  const handleDownloadDocument = (documentCategory: number, guid: string) => {
    if (documentCategory === 1) {
      getDownloadDocumets(guid);
    } else if (documentCategory === 2) {
      getDonwloadNonFinDocument(guid);
    }
  };

  return (
    <ApplicationViewStyled>
      {/* Application modals, opens when you click on ActionButtons */}
      <ActionModal />
      <ChooseBanksModal />

      {/* ViewSeparator with two buttons - Edit and goBack */}
      {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
        <ViewSeparatorTop>
          <TransitionWrapper
            in={permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)}
            animationName={ANIMATION_NAMES.FADE}
          >
            <EditApplicationBtn
              onClick={() => {
                setApplicationProcessMode({
                  mode: APPLICATION_PROCESS_MODES.EDIT
                });

                history.push(
                  `/cabinet/internal/application-form-${APPLICATION_TYPE_ROUTES[
                    internalApplication.financingType
                  ].replace('_', '-')}/edit/${internalApplication.id}`
                );
              }}
            >
              Редактировать заявку
            </EditApplicationBtn>
          </TransitionWrapper>
        </ViewSeparatorTop>
      )}

      {/* Change application id block. 
        Visible only in if application was transfered to banks  */}
      <SwitchBanksBlock />

      {/* Application content */}
      <ContentContainer
        application={internalApplication}
        mainInfo={mainInfo}
        subInfo={subInfo}
        documents={documents}
        permissions={permissions}
        roles={roles}
        companyHints={companyHints}
        productOfApplication={productOfApplication}
        postSelectApplication={postSelectApplication}
        scrollToProduct={scrollToProduct}
        sendRequestsGIR_BO={sendRequestsGIR_BO}
        financailsSummaryData={filtredFinancailsSummaryData}
        statusFinancailsSummaryData={statusFinancailsSummaryData}
        statusExternalApiGIR_BO={statusExternalApiGIR_BO}
        documentList={documentList}
      >
        {children}
      </ContentContainer>

      {/* Application action buttons block, 
        contains specific set of buttons, which changes application status */}
      {match.params.inBankId ? (
        internalApplication.status === APPLICATION_STATUSES['AWAITING'] &&
        internalApplication.apiId === 7 && (
          <>
            <ActionButtonsBlock />
            <p>Внимание: проверьте, что загружены все обязательные документы</p>
          </>
        )
      ) : (
        <ActionButtonsBlock />
      )}

      {permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
        internalApplication.status ===
          APPLICATION_STATUSES.TRANSFERRED_TO_AGENT && (
          <DelegateInternalAgentToApplicationSelect
            applicationId={internalApplication.id}
          />
        )}
      {/* Application Comments, not visible in drafts */}
      <CommentTabs />

      {!!documentList.items.length && (
        <>
          <TitleTabContainer>
            <div>
              <p>Список документов</p>

              <RejectionToggle onClick={() => getAllOffers(match.params.id)}>
                <RefreshIcon
                  icon={faSyncAlt}
                  refresh={statusAllOffers === REQUEST_STATUSES.REQUEST}
                />
                <p>Обновить</p>
              </RejectionToggle>
            </div>

            <FontAwesomeIcon
              icon={isSectionDocumentList ? faChevronUp : faChevronDown}
              onClick={() => setIsSectionDocumentList(prevState => !prevState)}
            />
          </TitleTabContainer>

          {isSectionDocumentList && (
            <>
              <CompanyHead>
                <p>{companyName}</p>
                <p>ИНН: {companyINN}</p>
              </CompanyHead>

              <TableWrapper>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="10%">Тип документа</TableThStyled>
                      <TableThStyled width="10%">Период</TableThStyled>
                      <TableThStyled width="30%">На платформе FF</TableThStyled>
                      {!!match.params.inBankId && (
                        <TableThStyled width="25%">
                          На платформе{' '}
                          {!!filtredInnDocuments.length &&
                            filtredInnDocuments[0].extApi === '7' &&
                            'TELEDOC'}
                        </TableThStyled>
                      )}
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {filtredInnDocuments.map(document => (
                      <TableRowStyled key={document.guid}>
                        <TDDocumentName text={document.documentName}>
                          <div>{document.documentName}</div>
                          {document.documentCategory === 2 &&
                            document.documentTypeId === 19 && (
                              <div>
                                {document.extTypeDescription}
                                <TooltipBoxStyle>
                                  {document.extTypeDescription}
                                </TooltipBoxStyle>
                              </div>
                            )}
                        </TDDocumentName>
                        <td>
                          <Period>
                            <p>
                              {document.taxYear && document.taxPeriodName
                                ? `${document.taxYear}, ${
                                    document.taxPeriodName
                                  }`
                                : '-'}
                            </p>
                            <IsNecessarily isUploaded={document.isUploaded}>
                              {document.isMandatory && <p>*Обязательный</p>}
                            </IsNecessarily>
                          </Period>
                        </td>
                        <td>
                          <PlatformContainer>
                            <FileContainer>
                              <IsUploadContainer
                                isUploaded={document.isUploaded}
                              >
                                {document.isUploaded ? (
                                  <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                    <p>Загружен</p>
                                  </div>
                                ) : (
                                  <div>
                                    <FontAwesomeIcon icon={faTimes} />
                                    <p>Не загружен</p>
                                  </div>
                                )}
                              </IsUploadContainer>
                              {!document.isUploaded ? (
                                <input
                                  type="file"
                                  onChange={e =>
                                    handleSetFile(
                                      document.guid,
                                      e.target.files[0]
                                    )
                                  }
                                />
                              ) : (
                                <DownloadFile
                                  onClick={() =>
                                    handleDownloadDocument(
                                      document.documentCategory,
                                      document.documentGuid
                                    )
                                  }
                                >
                                  Скачать
                                </DownloadFile>
                              )}
                              {file.id === document.guid && (
                                <ButtonContainer>
                                  <UploadButton
                                    disabled={
                                      statusFinDocUpload ===
                                      REQUEST_STATUSES.REQUEST
                                    }
                                    onClick={() =>
                                      postFile(
                                        document.documentCategory,
                                        document.guid
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Загрузить</p>
                                  </UploadButton>
                                  {statusFinDocUpload ===
                                    REQUEST_STATUSES.REQUEST && (
                                    <SpinnerApi icon={faSpinner} />
                                  )}
                                </ButtonContainer>
                              )}
                            </FileContainer>
                          </PlatformContainer>
                        </td>
                        {!!match.params.inBankId && (
                          <td>
                            <PlatformContainer>
                              <IsNecessarily
                                isUploaded={document.isUploadedExternal}
                              >
                                {document.isMandatory && <p>*Обязательный</p>}
                              </IsNecessarily>

                              <FileContainer>
                                <IsUploadContainer
                                  isUploaded={document.isUploadedExternal}
                                >
                                  {document.isUploadedExternal ? (
                                    <div>
                                      <FontAwesomeIcon icon={faCheck} />
                                      <p>Загружен</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <FontAwesomeIcon icon={faTimes} />
                                      <p>Не загружен</p>
                                    </div>
                                  )}
                                </IsUploadContainer>

                                {document.isUploadedExternal === false &&
                                  document.isUploaded === true && (
                                    <ButtonContainer>
                                      <UploadButton
                                        disabled={
                                          statusPostDocumentsOfDocumentList ===
                                          REQUEST_STATUSES.REQUEST
                                        }
                                        onClick={() =>
                                          postDocumentsOfDocumentList(
                                            document.guid
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                        <p>Загрузить</p>
                                      </UploadButton>
                                      {statusPostDocumentsOfDocumentList ===
                                        REQUEST_STATUSES.REQUEST && (
                                        <SpinnerApi icon={faSpinner} />
                                      )}
                                    </ButtonContainer>
                                  )}
                              </FileContainer>
                            </PlatformContainer>
                          </td>
                        )}
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              </TableWrapper>

              {filtredPersonDocuments.map((person, index) => (
                <React.Fragment key={index}>
                  <CompanyHead>
                    <p>{person.personName}</p>
                    <p>{person.personComment}</p>
                  </CompanyHead>

                  <TableWrapper>
                    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                      <TableHeaderStyled>
                        <tr>
                          <TableThStyled width="12%">
                            Тип документа
                          </TableThStyled>
                          <TableThStyled width="25%">
                            На платформе FF
                          </TableThStyled>
                          {!!match.params.inBankId && (
                            <TableThStyled width="25%">
                              На платформе{' '}
                              {!!filtredInnDocuments.length &&
                                filtredInnDocuments[0].extApi === '7' &&
                                'TELEDOC'}
                            </TableThStyled>
                          )}
                        </tr>
                      </TableHeaderStyled>
                      <tbody>
                        {person.documents.map((document, index) => (
                          <TableRowStyled key={index}>
                            <TDDocumentName>
                              <div>{document.documentName}</div>
                              {document.documentCategory === 2 &&
                                document.documentTypeId === 19 && (
                                  <div>
                                    {document.extTypeDescription}
                                    <TooltipBoxStyle>
                                      {document.extTypeDescription}
                                    </TooltipBoxStyle>
                                  </div>
                                )}
                            </TDDocumentName>
                            <td>
                              <PlatformContainer>
                                <IsNecessarily isUploaded={document.isUploaded}>
                                  {document.isMandatory && <p>*Обязательный</p>}
                                </IsNecessarily>

                                <FileContainer>
                                  <IsUploadContainer
                                    isUploaded={document.isUploaded}
                                  >
                                    {document.isUploaded ? (
                                      <div>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>Загружен</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <FontAwesomeIcon icon={faTimes} />
                                        <p>Не загружен</p>
                                      </div>
                                    )}
                                  </IsUploadContainer>
                                  {!document.isUploaded ? (
                                    <input
                                      type="file"
                                      onChange={e =>
                                        handleSetFile(
                                          document.guid,
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  ) : (
                                    <DownloadFile
                                      onClick={() =>
                                        handleDownloadDocument(
                                          document.documentCategory,
                                          document.documentGuid
                                        )
                                      }
                                    >
                                      Скачать
                                    </DownloadFile>
                                  )}
                                  {file.id === document.guid && (
                                    <ButtonContainer>
                                      <UploadButton
                                        disabled={
                                          statusNotFinDocUpload ===
                                          REQUEST_STATUSES.REQUEST
                                        }
                                        onClick={() =>
                                          postFile(
                                            document.documentCategory,
                                            document.guid
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                        <p>Загрузить</p>
                                      </UploadButton>
                                      {statusNotFinDocUpload ===
                                        REQUEST_STATUSES.REQUEST && (
                                        <SpinnerApi icon={faSpinner} />
                                      )}
                                    </ButtonContainer>
                                  )}
                                </FileContainer>
                              </PlatformContainer>
                            </td>
                            {!!match.params.inBankId && (
                              <td>
                                <PlatformContainer>
                                  <IsNecessarily
                                    isUploaded={document.isUploadedExternal}
                                  >
                                    {document.isMandatory && (
                                      <p>*Обязательный</p>
                                    )}
                                  </IsNecessarily>

                                  <FileContainer>
                                    <IsUploadContainer
                                      isUploaded={document.isUploadedExternal}
                                    >
                                      {document.isUploadedExternal ? (
                                        <div>
                                          <FontAwesomeIcon icon={faCheck} />
                                          <p>Загружен</p>
                                        </div>
                                      ) : (
                                        <div>
                                          <FontAwesomeIcon icon={faTimes} />
                                          <p>Не загружен</p>
                                        </div>
                                      )}
                                    </IsUploadContainer>

                                    {document.isUploadedExternal === false &&
                                      document.isUploaded === true && (
                                        <ButtonContainer>
                                          <UploadButton
                                            disabled={
                                              statusPostDocumentsOfDocumentList ===
                                              REQUEST_STATUSES.REQUEST
                                            }
                                            onClick={() =>
                                              postDocumentsOfDocumentList(
                                                document.guid
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faUpload} />
                                            <p>Загрузить</p>
                                          </UploadButton>
                                          {statusPostDocumentsOfDocumentList ===
                                            REQUEST_STATUSES.REQUEST && (
                                            <SpinnerApi icon={faSpinner} />
                                          )}
                                        </ButtonContainer>
                                      )}
                                  </FileContainer>
                                </PlatformContainer>
                              </td>
                            )}
                          </TableRowStyled>
                        ))}
                      </tbody>
                    </TableStyled>
                  </TableWrapper>
                </React.Fragment>
              ))}
            </>
          )}
        </>
      )}

      {!match.params.inBankId && (
        <>
          <TitleTabContainer>
            <div>
              <p>Продуктовые предложения</p>
              <div>
                {isRejection ? (
                  <RejectionToggle onClick={() => setIsRejection(false)}>
                    <FontAwesomeIcon icon={faEye} />
                    <p>Отказы показаны</p>
                  </RejectionToggle>
                ) : (
                  <RejectionToggle onClick={() => setIsRejection(true)}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                    <p>Отказы скрыты</p>
                  </RejectionToggle>
                )}
              </div>
            </div>
            <FontAwesomeIcon
              icon={isSectionProductOffers ? faChevronUp : faChevronDown}
              onClick={() => setIsSectionProductOffers(prevState => !prevState)}
            />
          </TitleTabContainer>

          {isSectionProductOffers && (
            <ProductOffersSection>
              {!!allOffers.length && (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="15%">Банк</TableThStyled>
                      <TableThStyled width="15%">API</TableThStyled>
                      <TableThStyled width="15%">Ставка</TableThStyled>
                      <TableThStyled width="15%">КВ Банка</TableThStyled>
                      <TableThStyled width="20%">Статус Банка</TableThStyled>
                      <TableThStyled width="20%">Отправка API</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {filteredOffers.map(offer => (
                      <TableRowStyled key={offer.id}>
                        <td>
                          <div>{offer.bankName}</div>
                          <div>{offer.bankInn}</div>
                        </td>
                        <td>{offer.apiName}</td>
                        <td>
                          <div>{offer.rate.toFixed(2)} % годовых</div>
                          <div>{formSumStringThousands(offer.price)} руб.</div>
                        </td>
                        <td>
                          <div>{offer.bankCommissionRateToUs} %</div>
                          <div>
                            {formSumStringThousands(offer.bankCommissionToUs)}{' '}
                            руб.
                          </div>
                        </td>
                        <td>
                          {offer.offerBankStatus === 1
                            ? 'Отказ банка'
                            : offer.offerBankStatus === 2
                              ? 'Прошел прескоринг'
                              : offer.offerBankStatus === 3
                                ? 'Одобрен'
                                : offer.offerBankStatus === 4 &&
                                  'Сделка заключена'}
                        </td>
                        <td>
                          {offer.offerApi === '7' &&
                          offer.offerExternalParam2 === null ? (
                            <SendApi
                              onClick={() => {
                                postOfferSend(offer.id);
                                setOfferId(offer.id);
                              }}
                            >
                              <span>Отправить заявку</span>

                              {statusSendApi === REQUEST_STATUSES.REQUEST &&
                                offerId === offer.id && (
                                  <SpinnerApi icon={faSpinner} />
                                )}
                            </SendApi>
                          ) : offer.offerExternalParam2 !== null &&
                          offer.apiName === 'Teledoc' &&
                          offer.offerExternalParam3 !== null ? (
                            <SendApi>
                              <p>Отправлена по API</p>
                              <span
                                onClick={() =>
                                  window.open(
                                    `https://testlk.teledoc.ru/guarantee/${
                                      offer.offerExternalParam3
                                    }/demand/${offer.offerExternalParam2}`,
                                    '_blank'
                                  )
                                }
                              >
                                Перейти
                              </span>
                            </SendApi>
                          ) : (
                            ''
                          )}
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              )}
            </ProductOffersSection>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
          statusActivities === REQUEST_STATUSES.GOT ? (
            <>
              <ActivityWrapper>
                <ApplicationTitle>Активности</ApplicationTitle>
                <Button
                  label="+ Добавить активность"
                  onClick={navigateToAddActivity}
                  w="fit-content"
                />
              </ActivityWrapper>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="10%">Дата</TableThStyled>
                    <TableThStyled width="15%">Тип активности</TableThStyled>
                    <TableThStyled width="25%">Результат</TableThStyled>
                    <TableThStyled width="25%">Комментарий</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {activities.items.map(activity => (
                    <TableRowStyled
                      key={activity.id}
                      onClick={() => navigateToActivities(activity.id)}
                    >
                      <td />
                      <td>
                        <ShowToAgentWrapper>
                          {activity.showToAgent ? (
                            <TooltipBoxStyle
                              textVerify={
                                'Активность видна агенту без внутренних комментариев'
                              }
                            >
                              <CheckboxStyleShowToAgent>
                                <FontAwesomeIcon icon={faEye} />
                              </CheckboxStyleShowToAgent>
                            </TooltipBoxStyle>
                          ) : (
                            <TooltipBoxStyle
                              textVerify={'Активность не видна агенту'}
                            >
                              <CheckboxStyleShowToAgent>
                                <FontAwesomeIcon icon={faEyeSlash} />
                              </CheckboxStyleShowToAgent>
                            </TooltipBoxStyle>
                          )}
                          <p>{activity.activityTypeName}</p>
                        </ShowToAgentWrapper>
                      </td>
                      <td>{activity.result}</td>
                      <td>{activity.comment}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </>
          ) : (
            permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
            statusActivities === REQUEST_STATUSES.ERROR && (
              <div>
                Ошибка {errorActivities.code} {errorActivities.message}
              </div>
            )
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <OpenLoanLimitsContainer>
              <ApplicationTitle>Предодобренные лимиты:</ApplicationTitle>

              <UploadButton
                disabled={statusCompanyToScoring === REQUEST_STATUSES.REQUEST}
                onClick={sendCompanyToScoring}
              >
                <p>Обновить скоринг Альфа-Банка</p>
                {statusCompanyToScoring === REQUEST_STATUSES.REQUEST && (
                  <SpinnerApi icon={faSpinner} />
                )}
              </UploadButton>

              {statusOpenLoanLimits === REQUEST_STATUSES.REQUEST && <Loader />}
              {statusOpenLoanLimits === REQUEST_STATUSES.GOT ? (
                <>
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="10%">Банк</TableThStyled>
                        <TableThStyled width="10%">Продукт</TableThStyled>
                        <TableThStyled width="5%">Лимит</TableThStyled>
                        <TableThStyled width="7%">Срок, мес.</TableThStyled>
                        <TableThStyled width="7%">Ставка</TableThStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {openLoanLimits.items.map((limit, key) => (
                        <TableRowStyled
                          onClick={() => onProductClick(limit.productId)}
                          key={key}
                        >
                          <td>{limit.bankShortName}</td>
                          <td>{limit.productName}</td>
                          <td>
                            {limit.volume === null
                              ? 'Неизв.'
                              : formSumStringThousands(limit.volume)}
                          </td>
                          <td>
                            {limit.term === null
                              ? 'Неизв.'
                              : `До ${limit.term} мес.`}
                          </td>
                          <td>
                            {limit.rate === null
                              ? 'Неизв.'
                              : `${limit.rate}% годовых`}
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>

                  <Pagination list={openLoanLimits} />
                </>
              ) : (
                <div>
                  Ошибка {errorOpenLoanLimits.code}{' '}
                  {errorOpenLoanLimits.message}
                </div>
              )}
            </OpenLoanLimitsContainer>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <>
              <ApplicationTitle ref={productRef}>Продукты</ApplicationTitle>

              {internalApplication &&
                (!internalApplication.financingType.includes(
                  APPLICATION_TYPES.GUARANTEES
                ) &&
                  !internalApplication.financingType.includes(
                    APPLICATION_TYPES.FACTORING
                  )) &&
                statusInternalApplication === REQUEST_STATUSES.GOT && (
                  <ProductApiContainer>
                    <div>
                      <Button
                        label="API Альфа-Банк ММБ"
                        onClick={() =>
                          postExternalApiAlfaBank(internalApplication.id)
                        }
                        disabled={statusPostAlfaBank.includes(
                          REQUEST_STATUSES.REQUEST
                        )}
                        w="fit-content"
                      />
                      {statusPostAlfaBank.includes(
                        REQUEST_STATUSES.REQUEST
                      ) && <SpinnerApi icon={faSpinner} />}
                    </div>
                    <div>
                      <Button
                        label="API ПСБ “Без Бумаг”"
                        onClick={() =>
                          postExternalApiPSB(internalApplication.id)
                        }
                        disabled={statusPostPSB.includes(
                          REQUEST_STATUSES.REQUEST
                        )}
                        w="fit-content"
                      />
                      {statusPostPSB.includes(REQUEST_STATUSES.REQUEST) && (
                        <SpinnerApi icon={faSpinner} />
                      )}
                    </div>
                  </ProductApiContainer>
                )}

              {statusProductOfApplication === REQUEST_STATUSES.REQUEST && (
                <Loader />
              )}
              {statusProductOfApplication === REQUEST_STATUSES.GOT ? (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="1%">ID</TableThStyled>
                      <TableThStyled width="10%">Банк</TableThStyled>
                      <TableThStyled width="10%">Продукт</TableThStyled>
                      <TableThStyled width="5%">Сложность</TableThStyled>
                      <TableThStyled width="7%">Договор с банком</TableThStyled>
                      <TableThStyled width="4%">Ставка min</TableThStyled>
                      <TableThStyled width="4%">Сред</TableThStyled>
                      <TableThStyled width="4%">Max</TableThStyled>
                      <TableThStyled width="7%">Cashback</TableThStyled>
                      <TableThStyled width="7%">Рейтинг</TableThStyled>
                      <TableThStyled width="10%">Статус</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {productOfApplication.map(product => (
                      <TableRowStyled
                        onClick={() => onProductClick(product.productId)}
                        key={product.productId}
                      >
                        <td>{product.productId}</td>
                        <td>{product.bankName}</td>
                        <td>{product.productName}</td>
                        <td>{product.complexity}</td>
                        <td>
                          {product.hasContract
                            ? 'Есть договор'
                            : 'Нет договора'}
                        </td>
                        {(product.offerSize === 0 && product.offerRate === 0) ||
                        (product.offerSize === null &&
                          product.offerRate === null) ? (
                          <>
                            <td>{product.minRate}</td>
                            <td>
                              {product.meanRate ? product.meanRate : 'н/д'}
                            </td>
                            <td>{product.maxRate}</td>
                          </>
                        ) : (
                          <>
                            <td />
                            <td>
                              <Limit>
                                <p>Индикатив:</p>
                                <p>
                                  лимит до{' '}
                                  {formSumStringThousands(product.offerSize)} р,
                                  ставка {product.offerRate}%
                                </p>
                              </Limit>
                            </td>
                            <td />
                          </>
                        )}
                        <td>
                          {product.hasCashback ? (
                            <CheckboxStyle verify={product.hasCashback}>
                              <FontAwesomeIcon icon={faCheck} />
                            </CheckboxStyle>
                          ) : (
                            'Нет'
                          )}
                        </td>
                        <td>{product.rating}</td>
                        <td>
                          {product.sentToBank ? (
                            product.status === 4 ? (
                              <Rejection>
                                4 - Отказ <br />
                                {getRejectReasonMessage(product.rejectReason)}
                              </Rejection>
                            ) : (
                              <Select
                                options={getFilteredOptions(
                                  productStatus[product.id]
                                )}
                                name={`productStatus_${product.id}`}
                                value={productStatus[product.id]}
                                onClick={event => event.stopPropagation()}
                                onChange={e =>
                                  handleOptionClick(
                                    e.currentTarget.value,
                                    product.id
                                  )
                                }
                              />
                            )
                          ) : (
                            <SentToBankStyled
                              disabled={
                                statusSendToBank === REQUEST_STATUSES.REQUEST
                              }
                              onClick={e => handleSentToBank(e, product.id)}
                            >
                              {statusSendToBank ===
                                REQUEST_STATUSES.REQUEST && (
                                <SpinnerApi icon={faSpinner} />
                              )}{' '}
                              Отметить отправку в банк
                            </SentToBankStyled>
                          )}
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              ) : (
                <div>
                  Ошибка {errorProductOfApplication.code}{' '}
                  {errorProductOfApplication.message}
                </div>
              )}

              <RejectReasonModal
                isVisible={isRejectModal}
                setIsVisible={setIsRejectModal}
                putRejected={putRejected}
                productId={productId}
              />

              <PopupContainer
                visible={statusSendToBank === REQUEST_STATUSES.ERROR}
              >
                <PopupContent>
                  <CloseButton onClick={closePopup}>&times;</CloseButton>
                  <h3>{errorSendToBank.code}</h3>
                  <p>{errorSendToBank.message}</p>
                </PopupContent>
              </PopupContainer>

              <Pagination list={productOfApplication} />
            </>
          )}
        </>
      )}

      <ModalContainer
        visible={isSendApiError}
        onClick={e => handleOutsideClick(e, sendApiErrorModalRef)}
      >
        <PurchaseContent ref={sendApiErrorModalRef}>
          <p>{sendApiError}</p>
        </PurchaseContent>
      </ModalContainer>

      {(switchBankStatus === REQUEST_STATUSES.REQUEST ||
        sendToBankStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={100} />
        </LoaderContainerStyled>
      )}

      <ActionResponseBlock
        showIn={switchBankStatus === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  Application,
  InternalAgentRole,
  User,
  Leads,
  BorrowerCompanies
}: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  statusInternalApplication: InternalAgentRole.getInternalApplication.status,
  companyHints: Application.getCompanyHints.data,
  switchBankStatus: Application.getInBankApplication.status,
  sendToBankStatus: Application.sendApplicationToBanks.status,
  productOfApplication: Application.getProductApplication.data,
  statusProductOfApplication: Application.getProductApplication.status,
  errorProductOfApplication: Application.getProductApplication.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  activities: Application.getActivitiesByApplication.data,
  statusActivities: Application.getActivitiesByApplication.status,
  errorActivities: Application.getActivitiesByApplication.error,
  statusPostAlfaBank: Application.postExternakApiAlfaBank.status,
  statusPostPSB: Application.postExternakApiPSB.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  errorSendToBank: Application.putSendToBank.error,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  allOffers: Application.getAllOffers.data,
  statusAllOffers: Application.getAllOffers.status,
  statusSendApi: Application.postOfferSend.status,
  sendApiError: Application.postOfferSend.error.body,
  documentList: BorrowerCompanies.getDocumentListByApplicationId.data,
  statusDocumentList: BorrowerCompanies.getDocumentListByApplicationId.status,
  statusFinDocUpload: BorrowerCompanies.postUploadFinDocInternal.status,
  statusNotFinDocUpload: BorrowerCompanies.postUploadNonFinDocInternal.status,
  statusPostDocumentsOfDocumentList:
    Application.postDocumentsOfDocumentList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setApplicationProcessMode,
      postSelectApplication,
      sendToBank,
      postExternalApiAlfaBank,
      postExternalApiPSB,
      getExternalApiGIR_BO,
      resetSentToBank,
      putSetPreapproved,
      putSetApproved,
      putRejected,
      getOpenLoanLimits,
      postSendCompanyToScoring,
      getAllOffers,
      resetAllOffers,
      postOfferSend,
      resetOfferSend,
      getDocumentListByApplicationId,
      resetDocumentListByApplicationId,
      postFinFile,
      postNotFinFile,
      getDownloadDocumets,
      getDonwloadNonFinDocument,
      postDocumentsOfDocumentList
    },
    dispatch
  );

const EachApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachApplicationView)
);

export { EachApplicationViewConnect as EachApplicationView };
