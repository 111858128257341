import { GET_COMPANY } from '../reducers/getCompany';
import { CompanyWrite } from './setCompanyFormData';

export const req = (inn: string | number) => ({
  type: GET_COMPANY.REQ,
  inn
});

export const got = (company: CompanyWrite) => ({
  type: GET_COMPANY.GOT,
  company
});

export const err = (error: {}) => ({
  type: GET_COMPANY.ERR,
  error
});
