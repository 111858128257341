import { GET_COMPANY_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getCompanyExternal';

export interface ResponseData {
  inn: string;
  companyShortName: string;
  ogrn: string;
  taxSystem: 'FILLED' | null;
}

export const req = (inn: string) => ({
  type: GET_COMPANY_EXTERNAL.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANY_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_EXTERNAL.ERR,
  error
});
