import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/SupplyContracts/actions/getSupplyContract';

export interface GetSupplyContractInternalStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_SUPPLY_CONTRACT_INTERNAL {
  REQ = 'GET_SUPPLY_CONTRACT_INTERNAL_REQ',
  GOT = 'GET_SUPPLY_CONTRACT_INTERNAL_GOT',
  ERR = 'GET_SUPPLY_CONTRACT_INTERNAL_ERR'
}

export const initialState: GetSupplyContractInternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getSupplyContractInternal = (
  state: GetSupplyContractInternalStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetSupplyContractInternalStoreState => {
  switch (action.type) {
    case GET_SUPPLY_CONTRACT_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUPPLY_CONTRACT_INTERNAL.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_SUPPLY_CONTRACT_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSupplyContractInternal;
