import * as React from 'react';
import Dropzone from 'react-dropzone';

import { REQUEST_STATUSES } from 'globaltypes';
import { Document } from 'Application/types';

import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';

import { FilesList, FileUploadInfoBlock } from 'shared/ui/InputFile';
import { Error } from 'shared/ui/Error';
import { Label } from 'shared/ui/Label';

import { InputFileContainer, DropArea, FileLoaderBar } from './styles';

export interface IFile {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate?: any;
  webkitRelativePath?: string;
}

export interface InputFileProps {
  className?: string;
  label?: string;
  name?: string;
  required?: boolean;
  error?: string;
  files: Document[];
  multiple?: boolean;
  uploadStatus?: REQUEST_STATUSES;
  accept?: string;
  maxSize?: number;
  dropAreaHeight?: number | string;
  onFilesUpload: (files: IFile[]) => void;
  onFilesRemove: (data: DeleteDocumentRequestDataType) => void;
}

export const inputFileDefaultProps = {
  files: [],
  accept:
    'image/*, .pdf, .pptx, .zip, .rar, .doc, .docx, .xls, .xlsx, .txt, .tiff',
  required: false,
  multiple: true,
  maxSize: 52 * 1000 * 1000,
  className: ''
};

export const InputFile: React.FC<InputFileProps> = React.memo(
  ({
    className,
    label,
    name,
    required,
    error,
    files,
    multiple,
    uploadStatus,
    accept,
    maxSize,
    onFilesUpload,
    onFilesRemove,
    dropAreaHeight
  }) => (
    <Dropzone
      disableClick
      accept={accept}
      maxSize={maxSize}
      multiple={multiple}
      onDrop={onFilesUpload}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
      }) => (
        <InputFileContainer className={!!className ? className : ''}>
          <Label name={name} label={label} required={required} />
          <DropArea
            name={name}
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            dropAreaHeight={dropAreaHeight}
            {...getRootProps()}
            isEmpty={files.length === 0}
          >
            <input {...getInputProps()} />

            {files.length === 0 ? (
              <FileUploadInfoBlock openUploader={open} />
            ) : (
              <FilesList
                isDragActive={isDragActive}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                files={files}
                multiple={multiple}
                openUploader={open}
                onFilesRemove={onFilesRemove}
              />
            )}
          </DropArea>

          <Error error={error} />
          <FileLoaderBar
            status={uploadStatus}
            processText="Загрузка файла..."
          />
        </InputFileContainer>
      )}
    </Dropzone>
  )
);

InputFile.defaultProps = inputFileDefaultProps;
