import ApiRequest from 'src/shared/utils/ApiRequest';
import { CompanyInfoData } from './../actions/setCompanyInfo';
import { BankAccountData } from './../actions/setBankAccount';
import { RequestDataType as DeleteBankAccountDataType } from './../reducers/deleteBankAccount';
import { RequestDataType as DeleteCertificateDataType } from './../reducers/deleteCertificate';
import { CertificateData } from './../actions/setCertificate';
import { RequestDataType as SendBuyersRequestDataType } from 'src/features/SCF/reducers/sendBuyers';
import { RequestDataType as SendSuppliersRequestDataType } from 'src/features/SCF/reducers/sendSuppliers';
import { RequestDataType as CheckAvailableLimitsRequestDataType } from '../actions/checkAvailableLimits';
import { RequestDataType as GetMySuppliersRequestDataType } from '../actions/getMySuppliers';
import { RequestDataType as GetCompanyByInnRequestDataType } from '../actions/getCompanyByInn';
import { RequestDataType as GetMyBuyersRequestDataType } from '../actions/getMyBuyers';
import { RequestDataType as GetMyLimitsRequestDataType } from '../actions/getFactoringLimits';
import { RequestDataType as GetCompanyByInnThirdPartyRequestDataType } from '../actions/getCompanyByInnThirdParty';
import { RequestDataType as GetSecondCompanyByInnThirdPartyRequestDataType } from '../actions/getSecondCompanyByInnThirdParty';
import { FactoringContract } from './../actions/setFactoringContract';
import { RequestDataType as GetPurchaseContractsRequestDataType } from '../actions/getPurchaseContracts';
import { RequestDataType as GetPurchaseContractByGuidRequestDataType } from '../actions/getPurchaseContractByGuid';
import { PurchaseContract } from './../actions/setPurchaseContract';
import { RequestDataType as GetSupplyContractsRequestDataType } from '../actions/getSupplyContracts';
import { RequestDataType as GetSupplyContractByGuidRequestDataType } from '../actions/getSupplyContractByGuid';
import { SupplyContract } from './../actions/setSupplyContract';
import { RequestDataType as AddListOfLimitsRequestDataType } from 'src/features/SCF/reducers/addListOfLimits';
import { DiscountingSettings } from './../actions/setDiscountingSettings';
import { RequestDataType as PostDiscountingSettingsSignatureRequestDataType } from 'src/features/SCF/reducers/postDiscountingSettingsSignature';
import { RequestDataType as PostFactoringSettingsSignatureRequestDataType } from 'src/features/SCF/reducers/postFactoringSettingsSignature';
import { FactoringSettingsByDebtorInn } from './../actions/setFactoringSettingsByDebtorInn';
import { RequestDataType as PutCertificateFillRequestDataType } from 'src/features/SCF/reducers/putCertificateFill';
import { RequestData as ReqAddPoa } from 'src/features/SCF/actions/putAddPoa';
import { RequestData as ReqCreateDiscountAuctions } from 'src/features/SCF/actions/postCreateDiscountAuctions';
import { RequestData as ReqAddToAuction } from 'src/features/SCF/actions/postAddToAuction';
import { RequestData as ReqSupplierAuction } from 'src/features/SCF/actions/getSupplierAuction';
import { RequestData as ReqAddedMonetaryClaimByDebtor } from 'src/features/SCF/actions/postAddedMonetaryClaimByDebtor';

export default class SCFApi {
  static getStatus() {
    return ApiRequest('/api/supplier/status');
  }

  static sendCompanyInfoData(data: CompanyInfoData) {
    return ApiRequest('/api/company', 'post', data);
  }

  static getCompanyInn() {
    return ApiRequest('/api/account/company_inn');
  }

  static getCompanyInfo() {
    return ApiRequest('/api/company/get_company_requisites');
  }

  static getBankAccount() {
    return ApiRequest('/api/bank_account/get_all_bank_requisites');
  }

  static sendBankAccountData(data: BankAccountData) {
    return ApiRequest('/api/bank_account', 'post', data);
  }

  static deleteBankAccount(data: DeleteBankAccountDataType) {
    return ApiRequest(`/api/bank_account/${data.id}`, 'delete');
  }

  static getCertificate() {
    return ApiRequest('/api/certificates/company');
  }

  static deleteCertificate(data: DeleteCertificateDataType) {
    return ApiRequest(`/api/certificates/${data.id}`, 'delete');
  }

  static sendCertificateData(data: CertificateData) {
    return ApiRequest('/api/certificates', 'post', data);
  }

  static sendBuyersList(data: SendBuyersRequestDataType) {
    return ApiRequest('/api/buyers_to_suppliers_link/add_buyers', 'post', data);
  }

  static sendSuppliersList(data: SendSuppliersRequestDataType) {
    return ApiRequest(
      '/api/buyers_to_suppliers_link/add_suppliers',
      'post',
      data
    );
  }

  static postAddSupplier(inn: string) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/add_supplier/${inn}`,
      'post'
    );
  }

  static postAddBuyer(inn: string) {
    return ApiRequest(`/api/buyers_to_suppliers_link/add_buyer/${inn}`, 'post');
  }

  static checkAvailableLimits(data: CheckAvailableLimitsRequestDataType) {
    return ApiRequest(
      `/api/factoring_limit/check_available_limits`,
      'post',
      data
    );
  }

  static getMySuppliers(requestData: GetMySuppliersRequestDataType) {
    return ApiRequest(
      '/api/buyers_to_suppliers_link/my_suppliers',
      'get',
      requestData
    );
  }

  static getCompanyByInn(requestData: GetCompanyByInnRequestDataType) {
    return ApiRequest(`/api/company/inn/${requestData.inn}`, 'get');
  }

  static getMyBuyers(requestData: GetMyBuyersRequestDataType) {
    return ApiRequest(
      '/api/buyers_to_suppliers_link/my_buyers',
      'get',
      requestData
    );
  }

  static getMyLimits(requestData: GetMyLimitsRequestDataType) {
    return ApiRequest('/api/factoring_limit/my', 'get', requestData);
  }

  static setFactoringLimitActive(debtor_inn: string) {
    return ApiRequest(
      `/api/factoring_limit/set_active/${debtor_inn}`,
      'post',
      debtor_inn
    );
  }

  static setFactoringLimitInactive(debtor_inn: string) {
    return ApiRequest(
      `/api/factoring_limit/set_inactive/${debtor_inn}`,
      'post',
      debtor_inn
    );
  }

  static getCompanyByInnThirdParty(
    requestData: GetCompanyByInnThirdPartyRequestDataType
  ) {
    return ApiRequest(
      `/api/company/summary/${requestData.inn}${
        requestData.force ? `?force=${requestData.force}` : ''
      }`
    );
  }

  static getCompanyFastInfo(inn: string) {
    return ApiRequest(`/api/company/brief/${inn}`);
  }

  static getSecondCompanyByInnThirdParty(
    requestData: GetSecondCompanyByInnThirdPartyRequestDataType
  ) {
    return ApiRequest(`/api/company/summary/${requestData.inn}`, 'get');
  }

  static sendFactoringContract(data: FactoringContract) {
    return ApiRequest('/api/factoring_contract', 'post', data);
  }

  static getPurchaseContracts(
    requestData: GetPurchaseContractsRequestDataType
  ) {
    return ApiRequest('/api/purchase_contract/my', 'get', requestData);
  }

  static getPurchaseContractByGuid(
    requestData: GetPurchaseContractByGuidRequestDataType
  ) {
    return ApiRequest(`/api/purchase_contract/id/${requestData.id}`, 'get');
  }

  static sendPurchaseContract(data: PurchaseContract) {
    return ApiRequest('/api/purchase_contract', 'post', data);
  }

  static getSupplyContracts(requestData: GetSupplyContractsRequestDataType) {
    return ApiRequest('/api/supply_contract/my', 'get', requestData);
  }

  static getSupplyContractByGuid(
    requestData: GetSupplyContractByGuidRequestDataType
  ) {
    return ApiRequest(`/api/supply_contract/id/${requestData.id}`, 'get');
  }

  static sendSupplyContract(data: SupplyContract) {
    return ApiRequest('/api/supply_contract', 'post', data);
  }

  static addListOfLimits(data: AddListOfLimitsRequestDataType) {
    return ApiRequest('/api/factoring_limit/add_list_of_limits', 'post', data);
  }

  static sendDiscountingSettings(data: DiscountingSettings) {
    return ApiRequest('/api/discounting_settings/new', 'post', data);
  }

  static getDiscountingSettings() {
    return ApiRequest('/api/discounting_settings/current', 'get');
  }

  static postReleaseLimit() {
    return ApiRequest('/api/discounting_settings/release_limit', 'post');
  }

  static postDiscountingSettingsSignature(
    data: PostDiscountingSettingsSignatureRequestDataType
  ) {
    return ApiRequest(
      `/api/discounting_settings/sign/${data.guid}`,
      'post',
      data
    );
  }

  static sendFactoringSettingsByDebtorInn(data: FactoringSettingsByDebtorInn) {
    return ApiRequest(
      `/api/factoring_settings/${data.debtorInn}/new`,
      'post',
      data
    );
  }

  static postFactoringSettingsSignature(
    data: PostFactoringSettingsSignatureRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_settings/sign/${data.guid}`,
      'post',
      data
    );
  }

  static downloadSupplyContractAgreementFile(guid: string): string {
    return `/api/purchase_contract/${guid}/get_agreement_file`;
  }

  static putCertificateFill(data: PutCertificateFillRequestDataType) {
    return ApiRequest(
      `/api/certificates/${data.guid}/contract_fill`,
      'put',
      data.body
    );
  }

  static getContractFile(guid: string) {
    return ApiRequest(`/api/purchase_contract/${guid}/get_agreement_file`);
  }

  static putAddSignee(guid: string, signeeGuid: string) {
    return ApiRequest(
      `/api/early_payment_applications/supplier/${guid}/add_signee/${signeeGuid}`,
      'put'
    );
  }

  static getKeyRates() {
    return ApiRequest(`/api/key_rate/current`);
  }

  static getPOAS(inn: string) {
    return ApiRequest(`/api/poas/scf?personINN=${inn}`);
  }

  static putAddPoa(data: ReqAddPoa) {
    return ApiRequest(`/api/poas/scf/add_poa`, 'put', data);
  }

  static getAllDiscountAuctionsByDebtor() {
    return ApiRequest(`/api/discount_auction/debtor_role/all`);
  }

  static postCreateDiscountAuctions(data: ReqCreateDiscountAuctions) {
    return ApiRequest(`/api/discount_auction/debtor_role/create`, 'post', data);
  }

  static getOneDiscountAuction(guid: string) {
    return ApiRequest(`/api/discount_auction/debtor_role/${guid}`);
  }

  static getSupplierAuction(guid: string, data: ReqSupplierAuction) {
    return ApiRequest(
      `/api/suppliers_of_auction/debtor_role/${guid}`,
      'get',
      data
    );
  }

  static postAddToAuction(guid: string, data: ReqAddToAuction) {
    return ApiRequest(
      `/api/suppliers_of_auction/${guid}/debtor_role/add`,
      'post',
      data
    );
  }

  static deleteFromAuction(guid: string, inn: string) {
    return ApiRequest(
      `/api/suppliers_of_auction/${guid}/debtor_role/delete/${inn}`,
      'delete'
    );
  }

  static getAllDiscountAuctionsBySupplier() {
    return ApiRequest(`/api/discount_auction/supplier_role/all`);
  }

  static getOneDiscountAuctionBySupplier(guid: string) {
    return ApiRequest(`/api/discount_auction/supplier_role/${guid}`);
  }

  static getInvestorListBySupplier(guid: string) {
    return ApiRequest(
      `/api/early_payment_applications/supplier/${guid}/investors_list`
    );
  }

  static putMonetaryClaimDeleteToAuction(guid: string, auction_guid: string) {
    return ApiRequest(
      `/api/monetary_claim/debtor_role/${guid}/delete_from_auction/${auction_guid}`,
      'put'
    );
  }

  static postAddedMonetaryClaimByDebtor(
    auctionGuid: string,
    data: ReqAddedMonetaryClaimByDebtor
  ) {
    return ApiRequest(
      `/api/monetary_claim/debtor_role/add_to_auction/${auctionGuid}`,
      'post',
      data
    );
  }

  static getQtySidebar() {
    return ApiRequest(`/api/users/internal_role/scf_counter`);
  }
}
