import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  DOSSIER_TABS,
  FiltredBeneficiariesType,
  FiltredPersonsType,
  FinancialDocumentsData,
  NonFinancialDocumentsData
} from '../types';
import { FinancialDocumets } from './Tabs/FinancialDocuments/FinancialDocuments';
import { req as getFinDocuments } from 'src/features/DossierForCompany/actions/getFinancialDocuments';
import { req as getNonFinancialDocuments } from 'src/features/DossierForCompany/actions/getNonFinancialDocuments';
import { req as getPersons } from 'src/features/Beneficiaries/actions/getManagersAndSignatories';
import { req as getBeneficiaries } from 'src/features/Beneficiaries/actions/getBeneficiaries';
import { Menu } from 'src/features/Layouts/components';
import { NonFinancialDocumets } from './Tabs/NonFinancialDocuments/NonFinancialDocuments';
import {
  BeneficiariesTypes,
  ManagersAndSignatoriesTypes
} from 'src/features/Beneficiaries/types';

interface StateToProps {
  fin_documents: FinancialDocumentsData[];
  statusFinDocuments: REQUEST_STATUSES;
  errorFinDocuments: ResponseError;
  nonFin_documents: NonFinancialDocumentsData[];
  statusNonFinDocuments: REQUEST_STATUSES;
  errorNonFinDocuments: ResponseError;
  persons: ManagersAndSignatoriesTypes[];
  statusPersons: REQUEST_STATUSES;
  errorPersons: ResponseError;
  beneficiaries: BeneficiariesTypes[];
  statusBeneficiaries: REQUEST_STATUSES;
  errorBeneficiaries: ResponseError;
}

interface DispatchToProps {
  getFinDocuments: (inn: string) => void;
  getNonFinancialDocuments: (name: string, inn: string) => void;
  getPersons: (inn: string) => void;
  getBeneficiaries: (inn: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const DossierForCompany: React.FC<Props> = ({
  getBeneficiaries,
  getPersons,
  getFinDocuments,
  getNonFinancialDocuments,
  beneficiaries,
  statusBeneficiaries,
  errorBeneficiaries,
  persons,
  statusPersons,
  errorPersons,
  fin_documents,
  statusFinDocuments,
  errorFinDocuments,
  nonFin_documents,
  statusNonFinDocuments,
  errorNonFinDocuments,
  history,
  location,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');
  const [filtredPersons, setFiltredPersons] = React.useState<
    FiltredPersonsType[]
  >([]);
  const [filtredBeneficiaries, setFiltredBeneficiaries] = React.useState<
    FiltredBeneficiariesType[]
  >([]);
  const [filtredNonFinDocuments, setFiltredNonFinDocuments] = React.useState<
    NonFinancialDocumentsData[]
  >([]);

  React.useEffect(() => {
    initFetch();
  }, []);

  React.useEffect(
    () => {
      if (persons.length && nonFin_documents.length) {
        const filtredPersons = persons.map(personObj => {
          const matchingDocuments = nonFin_documents.filter(
            doc => doc.personInn === personObj.personInn
          );

          return {
            id: personObj.id,
            personInn: personObj.personInn,
            lastName: personObj.lastName,
            firstName: personObj.firstName,
            middleName: personObj.middleName,
            position: personObj.position,
            documents: matchingDocuments.map(doc => ({
              id: doc.id,
              guid: doc.guid,
              documentName: doc.documentName,
              documentFileName: doc.documentFileName,
              signatureFileName: doc.signatureFileName,
              periods:
                doc.periodStartDate && doc.periodEndDate
                  ? `${new Date(
                      doc.periodStartDate
                    ).toLocaleDateString()} - ${new Date(
                      doc.periodEndDate
                    ).toLocaleDateString()}`
                  : '-'
            }))
          };
        });

        setFiltredPersons(filtredPersons);
      }
    },
    [persons, nonFin_documents]
  );

  React.useEffect(
    () => {
      if (beneficiaries.length && nonFin_documents.length) {
        const sortedData = beneficiaries.sort((a, b) => {
          if (a.beneficiaryType === 1 && b.beneficiaryType !== 1) {
            return -1;
          } else if (a.beneficiaryType !== 1 && b.beneficiaryType === 1) {
            return 1;
          } else {
            return b.ownershipShareDeJure - a.ownershipShareDeJure;
          }
        });

        const filtredBeneficiaries = sortedData.map(beneficiaryObj => {
          const matchingDocuments = nonFin_documents.filter(
            doc => doc.personInn === beneficiaryObj.personInn
          );

          return {
            id: beneficiaryObj.id,
            personInn: beneficiaryObj.personInn,
            lastName: beneficiaryObj.lastName,
            firstName: beneficiaryObj.firstName,
            middleName: beneficiaryObj.middleName,
            ownershipShareDeJure: beneficiaryObj.ownershipShareDeJure,
            documents: matchingDocuments.map(doc => ({
              id: doc.id,
              guid: doc.guid,
              documentName: doc.documentName,
              documentFileName: doc.documentFileName,
              signatureFileName: doc.signatureFileName,
              periods:
                doc.periodStartDate && doc.periodEndDate
                  ? `${new Date(
                      doc.periodStartDate
                    ).toLocaleDateString()} - ${new Date(
                      doc.periodEndDate
                    ).toLocaleDateString()}`
                  : '-'
            }))
          };
        });

        setFiltredBeneficiaries(filtredBeneficiaries);
      }
    },
    [beneficiaries, nonFin_documents]
  );

  React.useEffect(
    () => {
      if (nonFin_documents.length) {
        const filteredDocuments = nonFin_documents.filter(
          item => item.companyInn === match.params.inn
        );
        setFiltredNonFinDocuments(filteredDocuments);
      }
    },
    [nonFin_documents]
  );

  const initFetch = () => {
    if (match.params.applicationTab) {
      setActiveTab(match.params.applicationTab.toUpperCase());
    } else if (match.params.tab === 'CONSTITUENT_DOCUMENT') {
      setActiveTab(match.params.tab.toUpperCase());
    }

    getFinDocuments(match.params.inn);
    getNonFinancialDocuments('companyinn', match.params.inn);
    getPersons(match.params.inn);
    getBeneficiaries(match.params.inn);
  };

  const handleActiveTab = (tabName: string) => {
    const baseUrl = location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={DOSSIER_TABS}
        isSubMenu
      />

      {activeTab === 'DEFAULT' && (
        <FinancialDocumets
          fin_documents={fin_documents}
          status={statusFinDocuments}
          error={errorFinDocuments}
        />
      )}

      {activeTab === 'CONSTITUENT_DOCUMENT' && (
        <NonFinancialDocumets
          fin_documents={filtredNonFinDocuments}
          status={statusNonFinDocuments}
          error={errorNonFinDocuments}
          persons={filtredPersons}
          statusPersons={statusPersons}
          errorPersons={errorPersons}
          beneficiaries={filtredBeneficiaries}
          statusBeneficiaries={statusBeneficiaries}
          errorBeneficiaries={errorBeneficiaries}
        />
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Dossier, Beneficiaries }: CRM) => ({
  fin_documents: Dossier.getFinancialDocuments.data,
  statusFinDocuments: Dossier.getFinancialDocuments.status,
  errorFinDocuments: Dossier.getFinancialDocuments.error,
  nonFin_documents: Dossier.getNonFinancialDocuments.data,
  statusNonFinDocuments: Dossier.getNonFinancialDocuments.status,
  errorNonFinDocuments: Dossier.getNonFinancialDocuments.error,
  persons: Beneficiaries.getManagersAndSignatories.data,
  statusPersons: Beneficiaries.getManagersAndSignatories.status,
  errorPersons: Beneficiaries.getManagersAndSignatories.error,
  beneficiaries: Beneficiaries.getBeneficiaries.data,
  statusBeneficiaries: Beneficiaries.getBeneficiaries.status,
  errorBeneficiaries: Beneficiaries.getBeneficiaries.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getFinDocuments, getNonFinancialDocuments, getPersons, getBeneficiaries },
    dispatch
  );

const DossierForCompanyConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DossierForCompany)
);

export { DossierForCompanyConnect as DossierForCompany };
