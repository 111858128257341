import styled, { css } from 'styled-components';

import {
  rr,
  rm,
  rb,
  lightGray,
  gray,
  navyBlue,
  blue,
  black,
  darkBlue,
  customUnderline
} from 'shared/styled';

export const EachCommentStyled = styled.div`
  width: 100%;
  height: auto;

  margin: 15px 0;
  padding-bottom: 15px;

  border-bottom: 1px solid ${lightGray};
`;

export const CommentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 10px;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ isAuthor }) => (!!isAuthor ? gray : blue)};

  font-family: ${rb};
  font-size: 1.8rem;
  color: #fff;
`;

export const CommentInfo = styled.div`
  height: 44px;
  margin-left: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    margin-bottom: 5px;
    
    font-family: ${rm};
    font-size: 1.4rem;
    color: ${navyBlue};
  }

  p {
    margin: 0;

    font-family: ${rr};
    font-size: 1.2rem
    color: ${lightGray};
  }
`;

export const CommentBody = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CommentTextHeight = 200;
const ShowFullCommentButtonHeight = 20;
export const CommentText = styled.p`
  max-width: 80%;
  height: auto;
  max-height: calc(${CommentTextHeight}px + ${ShowFullCommentButtonHeight}px);

  overflow: hidden;
  position: relative;

  margin: 0;
  font-family: ${rr};
  font-size: 1.2rem;
  word-break: break-word;
  white-space: pre-line;
  line-height: 1.5;
  color: ${black};

  &::after {
    content: '';
    width: 100%;
    height: 100%;

    display: block;

    top: 0;
    left: 0;
    position: absolute;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.001) 0%,
      rgba(255, 255, 255, 1) 80%
    );
  }

  ${({ isFullView }) =>
    isFullView &&
    css`
      max-height: 10000px;

      &::after {
        display: none;
        visibility: hidden;
      }
    `};
`;

export const ReplyButton = styled.button`
  padding: 8px 12px;

  align-self: flex-end;

  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: ${lightGray};

  font-family: ${rm};
  font-size: 1.2rem;
  color: ${gray};

  &:hover {
    color: ${black};
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      opacity: 0.5;
    `};
`;

export const ShowFullCommentButton = styled.button.attrs({
  type: 'button'
})`
  height: 20px;

  top: calc(${CommentTextHeight}px - ${ShowFullCommentButtonHeight}px / 2);
  left: 0;
  z-index: 2;
  position: absolute;

  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  font-family: ${rr};
  font-size: 1.2rem;
  color: ${darkBlue};

  ${customUnderline(darkBlue)};
`;
