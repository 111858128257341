import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestType } from '../actions/getMonetaryClaimsList';
import { GET_MONETARY_CLAIMS_LIST } from '../reducers/getMonetaryClaimsList';
import MonetaryClaimsApi from '../api';

function* getMonetaryClaimsList(action: {
  type: GET_MONETARY_CLAIMS_LIST;
  data: RequestType;
}): Generator {
  try {
    const res: any = yield call(
      MonetaryClaimsApi.getMonetaryClaimsList,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimsListSaga(): Generator {
  yield takeLatest(GET_MONETARY_CLAIMS_LIST.REQ, getMonetaryClaimsList);
}
