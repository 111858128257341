import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from '../api';

import { got, err } from '../actions/getCertificateGenitive';
import { CERTIFICATE_GENITIVE_ACTIONS } from '../reducers/getCertificateGenitive';

function* getCertificateGenitiveData(action: {
  type: CERTIFICATE_GENITIVE_ACTIONS;
}) {
  try {
    const res: any = yield call(SCFApi.getCertificate);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCertificateGenitiveDataSaga() {
  yield takeLatest(
    CERTIFICATE_GENITIVE_ACTIONS.REQ,
    getCertificateGenitiveData
  );
}
