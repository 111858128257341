import { GET_COMPANIES_OF_COMPANIES } from '../reducers/getCompaniesOfCompanies';

interface CompaniesList {
  capital: any;
  companyINN: string;
  companyName: string;
  financialsYear: null;
  id: number;
  lastScoringAt: string;
  listId: number;
  longTermLoans: any;
  longestTerm: number;
  longestTermName: string;
  longestTermRate: number;
  longestTermVolume: number;
  lowestRate: number;
  lowestRateName: string;
  lowestRateTerm: number;
  lowestRateVolume: number;
  maxVolume: number;
  maxVolumeName: string;
  maxVolumeRate: number;
  maxVolumeTerm: number;
  netIncome: number;
  revenue: number;
  shortTermLoans: null;
  totalLoans: number;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CompaniesList[];
}

export interface RequestData {
  page: number;
}

export const req = (id: string, data: RequestData) => ({
  type: GET_COMPANIES_OF_COMPANIES.REQ,
  id,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_OF_COMPANIES.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_OF_COMPANIES.ERR,
  error
});
