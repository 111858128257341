import * as React from 'react';
import { Input } from 'shared/ui/Input';

interface Props {
  eruz: string;
  onFilterByEruz: ({ name, eruz }) => void;
}

export const FilterByEruz: React.FC<Props> = ({ eruz, onFilterByEruz }) => {
  return (
    <Input
      value={eruz}
      label="Номер закупки ЕРУЗ"
      name="eruz"
      onChange={e =>
        onFilterByEruz({
          name: e.currentTarget.name,
          eruz: e.currentTarget.value
        })
      }
    />
  );
};
