import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getAllIntegrationApplications';

import { GET_ALL_INTEGRATION_APPLICATIONS } from '../reducers/getAllIntegrationApplications';
import IntegrationApplicationsApi from '../api';
import { RequestData } from 'src/features/Users/actions/getUserList';

function* getAllIntegrationApplications(action: {
  type: GET_ALL_INTEGRATION_APPLICATIONS;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      IntegrationApplicationsApi.getAllIntegrationApplications,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllIntegrationApplicationsSaga(): Generator {
  yield takeLatest(
    GET_ALL_INTEGRATION_APPLICATIONS.REQ,
    getAllIntegrationApplications
  );
}
