import { PATCH_CABINET_BASIS_PARAMS } from '../reducers/patchCabinetBasisParams';

export const req = (guid: string, data: any) => ({
  type: PATCH_CABINET_BASIS_PARAMS.REQ,
  guid,
  data
});

export const got = () => ({
  type: PATCH_CABINET_BASIS_PARAMS.GOT
});

export const err = (error: {}) => ({
  type: PATCH_CABINET_BASIS_PARAMS.ERR,
  error
});
