import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { FormContainer } from 'src/features/Products/components/NewProduct/styled';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType,
  reset as resetStateCompanyByInn
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as postNewCompanies,
  reset as postNewCompaniesReset
} from 'entities/Cabinet/Client/model/actions/postNewCompanies';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  companyByInn: ResponseDataType;
  statusInn: REQUEST_STATUSES;
  createStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  postNewCompanies: (companyInn: string) => void;
  resetStateCompanyByInn: () => void;
  postNewCompaniesReset: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const NewCompanies: React.FC<Props> = ({
  getCompanyByInn,
  resetStateCompanyByInn,
  postNewCompanies,
  postNewCompaniesReset,
  companyByInn,
  createStatus,
  statusInn,
  history
}) => {
  const [companyinn, setCompanyinn] = React.useState('');
  const [shortname, setShortname] = React.useState('');
  const [isInfoPopup, setIsInfoPopup] = React.useState(false);

  React.useEffect(
    () => {
      if (createStatus === REQUEST_STATUSES.GOT) {
        postNewCompaniesReset();
        history.goBack();
      }

      return () => resetStateCompanyByInn();
    },
    [createStatus]
  );

  React.useEffect(
    () => {
      if (companyinn.length >= 10) {
        setIsInfoPopup(false);
        getCompanyByInn({ inn: companyinn });
      }
    },
    [companyinn]
  );

  React.useEffect(
    () => {
      if (history.location.state && history.location.state.inn) {
        setCompanyinn(history.location.state.inn);
      }
    },
    [history.location.state]
  );

  const onChangeInn = (value: string) => {
    setCompanyinn(value);
  };

  const onChangeName = (value: string) => {
    setShortname(value);
  };

  const onPopupClick = () => {
    setIsInfoPopup(true);

    if (companyByInn.companyShortName) {
      setShortname(companyByInn.companyShortName);
    }
  };

  const handleCreateClient = event => {
    event.preventDefault();
    postNewCompanies(companyinn);
  };

  return (
    <ApplicationViewStyled>
      <ApplicationTitle>Добавление компании</ApplicationTitle>

      <FormContainer onSubmit={handleCreateClient}>
        <Input
          label="ИНН:"
          name="companyinn"
          value={companyinn}
          onChange={e => onChangeInn(e.currentTarget.value)}
        />

        {companyinn && companyinn.length < 10 ? null : statusInn ===
        REQUEST_STATUSES.ERROR ? (
          <InnPopup>
            <h2>
              Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
              создать связку с введенным ИНН
            </h2>
          </InnPopup>
        ) : (
          statusInn === REQUEST_STATUSES.GOT &&
          !isInfoPopup && (
            <InnPopup companyInfo={companyByInn} onPopupClick={onPopupClick} />
          )
        )}

        <Input
          label="Наименование:"
          name="shortname"
          value={shortname}
          onChange={e => onChangeName(e.currentTarget.value)}
        />

        <Button label="Создать" type={'submit'} w="fit-content" />
      </FormContainer>
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ SCF, ClientRole }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  createStatus: ClientRole.postNewCompanies.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      postNewCompanies,
      resetStateCompanyByInn,
      postNewCompaniesReset
    },
    dispatch
  );

const NewCompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewCompanies)
);

export { NewCompaniesConnect as NewCompanies };
