export enum SET_APPLICATION_LIST_VIEW {
  SET = 'SET_APPLICATION_LIST_VIEW'
}

export enum APPLICATION_LIST_VIEWS {
  TILE = 'TILE',
  TABLE = 'TABLE'
}

export interface RequestDataType {
  view: APPLICATION_LIST_VIEWS;
}

export const initialState: RequestDataType = {
  view: APPLICATION_LIST_VIEWS.TABLE
};

const setApplicationListView = (
  state: RequestDataType = initialState,
  action: {
    type: SET_APPLICATION_LIST_VIEW;
    data: RequestDataType;
  }
): RequestDataType => {
  switch (action.type) {
    case SET_APPLICATION_LIST_VIEW.SET:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default setApplicationListView;
