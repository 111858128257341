import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postSendCompanyToScoring';

import { POST_SEND_COMPANY_TO_SCORING } from '../reducers/postSendCompanyToScoring';
import LeadsApi from '../api';

function* postSendCompanyToScoring(action: {
  type: POST_SEND_COMPANY_TO_SCORING;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(LeadsApi.postSendCompanyToScoring, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postSendCompanyToScoringLeadSaga(): Generator {
  yield takeLatest(POST_SEND_COMPANY_TO_SCORING.REQ, postSendCompanyToScoring);
}
