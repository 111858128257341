import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { StopFactorsContainer } from 'src/features/Filters/Products/FilterByStopFactors/components/styled';
import { CheckboxState } from 'src/features/Products/types';

interface Props {
  checkboxState: CheckboxState;
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ageOfCompany: number;
  handleAgeOfCompany: (event: React.FormEvent<HTMLInputElement>) => void;
}

const stopFactorsCrimea = {
  crimeanClient: 'Клиент',
  crimeanCustomer: 'Заказчик'
};

const stopFactorsCaucasus = {
  caucasusClient: 'Клиент',
  caucasusCustomer: 'Заказчик'
};

const FilterByStopFactors: React.FC<Props> = ({
  ageOfCompany,
  handleAgeOfCompany,
  checkboxState,
  handleFilter
}) => {
  return (
    <>
      <CheckboxGroup
        label="Крым:"
        name="stopFactorsCrimea"
        checkboxes={Object.keys(stopFactorsCrimea).map(type => ({
          label: stopFactorsCrimea[type],
          value: type,
          checked: checkboxState.stopFactorsCrimea.includes(type),
          onChange: handleFilter
        }))}
      />
      <CheckboxGroup
        label="Кавказ:"
        name="stopFactorsCaucasus"
        checkboxes={Object.keys(stopFactorsCaucasus).map(type => ({
          label: stopFactorsCaucasus[type],
          value: type,
          checked: checkboxState.stopFactorsCaucasus.includes(type),
          onChange: handleFilter
        }))}
      />
      <StopFactorsContainer>
        <Input
          value={ageOfCompany}
          label="Срок жизни компании:"
          name="age"
          placeholder="0"
          onChange={handleAgeOfCompany}
        />
        <Checkbox
          label={'Без проверки БКИ'}
          name="bki"
          onChange={handleFilter}
          checked={checkboxState.bki}
        />
      </StopFactorsContainer>
    </>
  );
};

const mapStateToProps = ({ Filters }: STORE) => {
  return {
    types: Filters.updateTypeFilter.types
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByStopFactorsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByStopFactors);

export { FilterByStopFactorsConnect as FilterByStopFactors };
