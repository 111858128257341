import { SET_COMPANY_INFO_DATA } from '../reducers/setCompanyInfo';

export interface CompanyInfoWrite {
  id?: number;
  inn?: string;
  companyShortName?: string;
  companyFullName?: string;
  companyPhone?: string;
  companyAddress?: string;
  holdingId?: number;
  holdingName?: string;
  revenue?: number;
  netIncome?: number;
  capital?: number;
  financialsYear?: number;
  hasContacts?: boolean;
  segment?: string;
  backbone?: number;
  inAction?: number;
  kpp?: string;
  is_customer223?: boolean;
  is_customer44?: boolean;
  is_target_client?: boolean;
  isDeleted?: boolean;
  regDate?: Date;
  minNum?: number;
  maxNum?: number;
  opfId?: number;
  okvedId?: number;
  companyEmail?: string;
  pfr?: string;
  fss?: string;
  startCapital?: number;
  kladr?: number;
  index?: number;
  region?: number;
  area?: string;
  city?: string;
  settlement?: string;
  street?: string;
  house?: string;
  corpus?: string;
  apartment?: string;
  lastActivity?: Date;
  lastLogin?: Date;
  companyNameEng?: string;
}

export interface CompanyInfoRead extends CompanyInfoWrite {
  id?: number;
  inn?: string;
  companyShortName?: string;
  companyFullName?: string;
  kpp?: string;
  companyPhone?: string;
  modifiedDateTime?: Date;
  companyAddress?: string;
  companyEmail?: string;
  companyNameEng?: string;
}

export interface CompanyInfoData extends Partial<CompanyInfoWrite> {}

export const setCompanyInfoData = (data: CompanyInfoData) => ({
  type: SET_COMPANY_INFO_DATA.SET,
  data
});

export const resetCompanyInfoData = () => ({
  type: SET_COMPANY_INFO_DATA.RESET
});
