import { combineReducers } from "redux";
import setExternalCredentialsOfProductsFormData, { SetExternalCredentialsOfProductsDataStoreState } from "./setExternalCredentialsOfProductsFormData";
import getProductsListByExternalCredential, { GetProductsListByExternalCredentialStoreState } from "./getProductsListByExternalCredential";
import getExternalCredentialsListByProduct, { GetExternalCredentialsListByProductStoreState } from "./getExternalCredentialsListByProduct";

export interface ExternalCredentialsOfProductsReducers {
  setExternalCredentialsOfProductsFormData: SetExternalCredentialsOfProductsDataStoreState;
  getProductsListByExternalCredential: GetProductsListByExternalCredentialStoreState;
  getExternalCredentialsListByProduct: GetExternalCredentialsListByProductStoreState;
}

export const ExternalCredentialsOfProducts  = combineReducers({
  setExternalCredentialsOfProductsFormData,
  getProductsListByExternalCredential,
  getExternalCredentialsListByProduct
});
