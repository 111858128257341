import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';

export interface GetInvestorOneDebtorSupplierRoleByGuidStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID {
  REQ = 'GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID_REQ',
  GOT = 'GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID_GOT',
  ERR = 'GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID_ERR',
  RESET = 'GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID_RESET'
}

export const initialState: GetInvestorOneDebtorSupplierRoleByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    auctionGuid: '',
    createdDateTime: null,
    debtorInn: '',
    debtorName: '',
    supplierName: '',
    payerInn: '',
    payerName: '',
    payDate: null,
    absoluteDiscount: 0,
    monetaryClaimsTotalBaseSum: 0,
    monetaryClaimsTotalVatSum: 0,
    monetaryClaimsTotalFullSum: 0,
    firstBasePaymentDate: null,
    lastBasePaymentDate: null,
    baseDiscountRate: 0,
    firstPaymentShare: 0,
    payerType: null,
    statusSupplier: '',
    agreementFileIdentifier: '',
    agreementFilename: '',
    supplierSigneeGuid: '',
    supplierSignatureFileIdentifier: '',
    supplierSignatureFilename: '',
    debtorSignatureFileIdentifier: '',
    debtorSignatureFilename: '',
    rejectSubject: null,
    rejectComment: ''
  },
  error: {}
};

const getInvestorOneDebtorSupplierRoleByGuid = (
  state: GetInvestorOneDebtorSupplierRoleByGuidStoreState = initialState,
  action: {
    type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID;
    data: ResponseDataType;
    error: {};
  }
): GetInvestorOneDebtorSupplierRoleByGuidStoreState => {
  switch (action.type) {
    case GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default getInvestorOneDebtorSupplierRoleByGuid;
