import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getBuyersToSupplierLinkList';

import { GET_BUYERS_TO_SUPPLIER_LINK_LIST } from '../reducers/getBuyersToSupplierLinkList';
import BuyersToSupplierLinkApi from '../api';

function* getBuyersToSupplierLinkList(action: {
  type: GET_BUYERS_TO_SUPPLIER_LINK_LIST;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      BuyersToSupplierLinkApi.getBuyersToSupplierLinkList,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBuyersToSupplierLinkListSaga(): Generator {
  yield takeLatest(
    GET_BUYERS_TO_SUPPLIER_LINK_LIST.REQ,
    getBuyersToSupplierLinkList
  );
}
