export enum USER_LIST_TYPE {
  ALL = 'ALL'
}

export enum USERS_ROLE_TYPE {
  'Все пользователи' = 'Все пользователи',
  EXTERNALAGENT = 'Агенты',
  APPLICANT = 'Клиенты',
  BANK = 'Банкиры',
  INTERNALAGENT = 'Сотрудники'
}
