import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface PostFinancialDocumentExternalStoreState extends Reducer {}

export enum POST_FINANCIAL_DOCUMENT_EXTERNAL {
  REQ = 'POST_FINANCIAL_DOCUMENT_EXTERNAL_REQ',
  GOT = 'POST_FINANCIAL_DOCUMENT_EXTERNAL_GOT',
  ERR = 'POST_FINANCIAL_DOCUMENT_EXTERNAL_ERR'
}

export const initialState: PostFinancialDocumentExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFinancialDocumentExternal = (
  state: PostFinancialDocumentExternalStoreState = initialState,
  action: {
    type: string;
    data: POST_FINANCIAL_DOCUMENT_EXTERNAL;
    error: {};
  }
): PostFinancialDocumentExternalStoreState => {
  switch (action.type) {
    case POST_FINANCIAL_DOCUMENT_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case POST_FINANCIAL_DOCUMENT_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };

    case POST_FINANCIAL_DOCUMENT_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default postFinancialDocumentExternal;
