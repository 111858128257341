import styled from 'styled-components';
import { lighterBlue, rr, blue } from 'shared/styled';

export const ContractsPageStyled = styled.section`
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 130px;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InnFilterArea = styled.div`
  width: 450px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;
export const ListSection = styled.section`
  margin-top: 30px;
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FullList = styled.section`
  margin-top: 30px;
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
