import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Person/actions/getSearchPerson';

export interface GetSearchPersonStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_SEARCH_PERSON {
  REQ = 'GET_SEARCH_PERSON_REQ',
  GOT = 'GET_SEARCH_PERSON_GOT',
  ERR = 'GET_SEARCH_PERSON_ERR'
}

export const initialState: GetSearchPersonStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getSearchPerson = (
  state: GetSearchPersonStoreState = initialState,
  action: {
    type: GET_SEARCH_PERSON;
    data: ResponseData;
    error: {};
  }
): GetSearchPersonStoreState => {
  switch (action.type) {
    case GET_SEARCH_PERSON.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SEARCH_PERSON.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SEARCH_PERSON.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSearchPerson;
