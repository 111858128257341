import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getFactoringConnections,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getFactoringConnectionsSupplierRole';
import {
  ConnectionsPageStyled,
  CreateBtn,
  CreateBtnText,
  InnFilterArea,
  InnFilter,
  InnContainer,
  ListSection,
  FullList,
  CheckIcon,
  TimesIcon,
  GreyIcon,
  IconPopup,
  StatusArea,
  StatusAreaRow,
  ConfirmContainer,
  DeclineContainer,
  HandleContainer
} from './styles';
import {
  CreateLimitsNudge,
  PaginationWrapper
} from 'src/features/SCF/UI/ScfStyles';
import '../styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'entities/SCF/Supplier/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringConnections: ResponseDataType;
}

interface DispatchToProps {
  getFactoringConnections: (data: RequestDataType) => void;
}

interface State {
  pageUrl: Href;
  debtorInnToFilter: string;
  factorInnToFilter: string;
  updateChecker: ResponseDataType;
}

type Props = StateToProps & DispatchToProps;

class ConnectionsList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        debtorInnToFilter: '',
        factorInnToFilter: '',
        updateChecker: undefined
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleDebtorInnToFilterChange = this.handleDebtorInnToFilterChange.bind(
        this
      );
      this.handleFactorInnToFilterChange = this.handleFactorInnToFilterChange.bind(
        this
      );
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  handleDebtorInnToFilterChange(event) {
    this.setState({ debtorInnToFilter: event.target.value });
  }

  handleFactorInnToFilterChange(event) {
    this.setState({ factorInnToFilter: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  handleUpdateCheckerChange = async () => {
    await this.delay(200);
    this.setState({ updateChecker: this.props.factoringConnections });
  };

  initFetch = () => {
    const { getFactoringConnections } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.debtorInnToFilter == '' && this.state.factorInnToFilter == ''
        ? getFactoringConnections({ page, pageSize: 10 })
        : this.state.debtorInnToFilter != '' &&
          this.state.factorInnToFilter == ''
          ? getFactoringConnections({
              page: 1,
              DebtorInnToFilterBy: this.state.debtorInnToFilter
            })
          : this.state.debtorInnToFilter == '' &&
            this.state.factorInnToFilter != ''
            ? getFactoringConnections({
                page: 1,
                FactorInnToFilterBy: this.state.factorInnToFilter
              })
            : getFactoringConnections({
                page: 1,
                DebtorInnToFilterBy: this.state.debtorInnToFilter,
                FactorInnToFilterBy: this.state.factorInnToFilter
              });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.debtorInnToFilter !== prevState.debtorInnToFilter ||
      this.state.factorInnToFilter !== prevState.factorInnToFilter ||
      this.state.updateChecker !== prevState.updateChecker
    ) {
      this.initFetch();
    }
  }

  CreateLimitsClick = () => {
    history.push('/supplier/connections/add');
  };
  render() {
    const { status, factoringConnections, error } = this.props;
    return (
      <ConnectionsPageStyled>
        <CreateLimitsNudge>
          <h2>Факторинговые связки</h2>
          <CreateBtn onClick={this.CreateLimitsClick}>
            <CreateBtnText>+ Создать</CreateBtnText>
          </CreateBtn>
        </CreateLimitsNudge>
        <InnFilterArea>
          <InnFilter>
            <p>ИНН Покупателя</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
          <InnFilter>
            <p>ИНН Фактора</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleFactorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
        </InnFilterArea>

        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="20%">
                      ИНН ПОКУПАТЕЛЯ
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="35%">
                      ПОКУПАТЕЛЬ
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      ИНН ФАКТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="35%">
                      ФАКТОР
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="50%">
                      СТАТУС
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="22%">
                      ФИНАНСИРОВАНИЕ
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {factoringConnections.items.map((item, key) => (
                    <TableRowStyled key={key}>
                      <td>{item.debtorInn}</td>
                      <td>{item.debtorName}</td>
                      <td>{item.factorInn}</td>
                      <td>{item.factorName}</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        {item.confirmedByFactor == 1 ? (
                          <StatusArea className="icon-to-show-popup">
                            <CheckIcon />
                            <IconPopup className="icon-popup">
                              <p>Подтверждено фактором</p>
                            </IconPopup>
                          </StatusArea>
                        ) : item.confirmedByFactor == 0 ? (
                          <StatusArea className="icon-to-show-popup">
                            <GreyIcon />
                            <IconPopup className="icon-popup">
                              <p>Не подтверждено фактором</p>
                            </IconPopup>
                          </StatusArea>
                        ) : (
                          <StatusArea className="icon-to-show-popup">
                            <TimesIcon />
                            <IconPopup className="icon-popup">
                              <p>Отклонено фактором</p>
                            </IconPopup>
                          </StatusArea>
                        )}
                        {item.confirmedBySupplier == 1 ? (
                          <StatusArea className="icon-to-show-popup">
                            <CheckIcon />
                            <IconPopup className="icon-popup">
                              <p>Вы подтвердили связку</p>
                            </IconPopup>
                          </StatusArea>
                        ) : item.confirmedBySupplier == 0 ? (
                          <StatusAreaRow>
                            <ConfirmContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionSupplierRoleConfirm(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Подтвердить</p>
                              </HandleContainer>
                            </ConfirmContainer>
                            <DeclineContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionSupplierRoleDecline(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Отклонить</p>
                              </HandleContainer>
                            </DeclineContainer>
                          </StatusAreaRow>
                        ) : (
                          <StatusAreaRow>
                            <StatusArea className="icon-to-show-popup">
                              <TimesIcon style={{ marginTop: '22px' }} />
                              <IconPopup className="icon-popup">
                                <p>Вы отклонили связку</p>
                              </IconPopup>
                            </StatusArea>
                            <ConfirmContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionSupplierRoleConfirm(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Подтвердить</p>
                              </HandleContainer>
                            </ConfirmContainer>
                          </StatusAreaRow>
                        )}
                      </td>
                      {item.readyForFactorFinance == true ? (
                        <td>Включено</td>
                      ) : (
                        <td style={{ color: '#FF0000' }}>Приостановлено</td>
                      )}
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
        </ListSection>
        <PaginationWrapper onClick={this.handlePageChange}>
          <Pagination list={this.props.factoringConnections} />
        </PaginationWrapper>
      </ConnectionsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  factoringConnections: SCFSupplier.getFactoringConnectionsSupplierRole.data,
  status: SCFSupplier.getFactoringConnectionsSupplierRole.status,
  error: SCFSupplier.getFactoringConnectionsSupplierRole.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringConnections
    },
    dispatch
  );

const ConnectionsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionsList);

export { ConnectionsListConnect as ConnectionsList };
