import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/SCF/Internal/model/actions/getDebtorRegistries';

import { GET_DEBTOR_REGISTRIES_INTERNAL_AGENT } from 'entities/SCF/Internal/model/reducers/getDebtorRegistries';
import InternalAgentApi from 'entities/SCF/Internal/api';

function* getDebtorRegistries(action: {
  type: GET_DEBTOR_REGISTRIES_INTERNAL_AGENT;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentApi.getDebtorRegistries,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorRegistriesSaga(): Generator {
  yield takeLatest(
    GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.REQ,
    getDebtorRegistries
  );
}
