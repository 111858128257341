import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'Business/actions/getPersonsOfCompanies';
import { GET_PERSONS_OF_COMPANIES } from 'Business/reducers/getPersonsOfCompanies';
import BusinessApi from 'Business/api';

function* getPersonsOfCompanies(action: {
  type: GET_PERSONS_OF_COMPANIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BusinessApi.getPersonsOfCompanies, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPersonsOfCompaniesSaga(): Generator {
  yield takeLatest(GET_PERSONS_OF_COMPANIES.REQ, getPersonsOfCompanies);
}
