import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ProductRead } from '../actions/setProductFormData';

export interface GetProductStoreState extends Reducer {
  product: ProductRead;
}

export enum GET_PRODUCT {
  REQ = 'GET_PRODUCT_REQ',
  GOT = 'GET_PRODUCT_GOT',
  ERR = 'GET_PRODUCT_ERR'
}

export const initialState: GetProductStoreState = {
  status: REQUEST_STATUSES.NONE,
  product: {
    id: 0
  },
  error: {}
};

const getProduct = (
  state: GetProductStoreState = initialState,
  action: {
    type: string;
    product: ProductRead;
    error: {};
  }
): GetProductStoreState => {
  switch (action.type) {
    case GET_PRODUCT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PRODUCT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        product: action.product
      };
    case GET_PRODUCT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getProduct;
