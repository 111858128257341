import {
    DELETE_CERTIFICATE,
    RequestDataType
} from './../reducers/deleteCertificate';

export const req = (data: RequestDataType) => ({
    type: DELETE_CERTIFICATE.REQ,
    data
});

export const got = () => ({
    type: DELETE_CERTIFICATE.GOT
});

export const err = (error: {}) => ({
    type: DELETE_CERTIFICATE.ERR,
    error
});
