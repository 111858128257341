import styled from 'styled-components';
import { Checkbox } from 'shared/ui/Checkbox';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';
import { rr } from 'shared/styled';

export const CheckboxStyled = styled(Checkbox)`
  margin-top: 20px;
`;

export const PopupContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  & > ${InnPopupStyled} {
    top: 70px;
  }
`;

export const PopupInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 5px 0;
  }
`;

export const InfoField = styled.div`
  width: 350px;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;

  p {
    font-family: ${rr};
    font-size: 0.9em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;
