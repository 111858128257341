import styled from 'styled-components';
import { Modal } from 'Common';
import { navyBlue } from 'shared/styled';

export const ModalStyled = styled(Modal)`
  max-width: 600px;
  box-sizing: content-box;
`;

export const AgreementModalStyled = styled.div`
  width: 600px;

  h2 {
    margin-bottom: 30px;
  }

  p {
    opacity: 0.7;
    line-height: 1.71;
    color: ${navyBlue};
  }
`;
