import styled, { css } from 'styled-components';

import { TableHeaderStyled } from './TableHeader/styles';
import { BoxStyled } from 'shared/styled';
import { rr , lightGray } from 'shared/styled';

export const TableStyled = styled.table`
    table-layout: fixed;
    width:100%;
    border-collapse: collapse;
    td,th {
      padding: 0 5px;
      font-family: ${rr};
      white-space: nowrap;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;

      ${({ sizes }) =>
        sizes.map(
          (item, key) => css`
            &:${`nth-child(${key + 1})`} {
              width: ${item};
            }
          `
        )};

      ${({ sizes }) => css`
          &:${`nth-child(${sizes.length - 1})`},
          &:${`nth-child(${sizes.length})`} {
            overflow: initial;
          }
        `};
    }

  tr:not(:last-child) {
    border-bottom: 1px solid ${lightGray};
  }
`

export const TableStyledWrapped = styled.table`
    table-layout: fixed;
    width:100%;
    border-collapse: collapse;
    td,th {
      padding: 0 5px;
      font-family: ${rr};
      white-space: wrap;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;

      ${({ sizes }) =>
        sizes.map(
          (item, key) => css`
            &:${`nth-child(${key + 1})`} {
              width: ${item};
            }
          `
        )};

      ${({ sizes }) => css`
          &:${`nth-child(${sizes.length - 1})`},
          &:${`nth-child(${sizes.length})`} {
            overflow: initial;
          }
        `};
    }

  tr:not(:last-child) {
    border-bottom: 1px solid ${lightGray};
  }
`

export const TableHeaderCellStyled = styled.th`
${({width}) => css`width: ${width}`}
`
