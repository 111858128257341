import * as React from 'react';
import styled, { css } from 'styled-components';

import { ButtonStyled } from 'shared/ui/Button/styled';

export const ActionButtonStyled = styled(ButtonStyled)`
  width: 220px;
  margin: 0 10px 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: none;

  &:active {
    background-image: none;
  }

  ${({ color }) => css`
    background-color: ${color};
    &&:hover {
      background-color: ${color};
    }
  `};
`;












