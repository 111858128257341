import getApplicationBankSaga from './getApplicationBank';
import getDocumentListSaga from './getDocumentList';
import getDownloadDocumentSaga from './getDownloadDocument';
import getAllOfBankSaga from './getAllOfBank';

export default [
  getApplicationBankSaga,
  getDocumentListSaga,
  getDownloadDocumentSaga,
  getAllOfBankSaga
];
