import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutSetPreapprovedDataStoreState extends Reducer {}

export enum PUT_SET_PREAPPROVED {
  REQ = 'PUT_SET_PREAPPROVED_REQ',
  GOT = 'PUT_SET_PREAPPROVED_GOT',
  ERR = 'PUT_SET_PREAPPROVED_ERROR'
}

export const initialState: PutSetPreapprovedDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putSetPreapproved = (
  state: PutSetPreapprovedDataStoreState = initialState,
  action: {
    type: PUT_SET_PREAPPROVED;
    error: {};
  }
): PutSetPreapprovedDataStoreState => {
  switch (action.type) {
    case PUT_SET_PREAPPROVED.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_SET_PREAPPROVED.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_SET_PREAPPROVED.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putSetPreapproved;
