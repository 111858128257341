import * as React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';

import { history } from 'src/shared/utils/History';

import { Header, HeaderSCF } from 'Layouts/components';

import {
  PublicPage,
  PrivatePage,
  PublicSupplierPage,
  PrivateSupplierPage,
  PrivateDebtorPage,
  PrivateFactorPage
} from 'App/components';
import { ExternalAgentRegistrationPage } from 'ExternalAgent/components';
import { BusinessRegistrationPage } from 'Business/components';
import { SCFPage } from 'src/features/SCF/components';
import { SupplierPage } from 'pages/SCF/Supplier/SupplierPage';
import { FactorPage } from 'pages/SCF/Factor/FactorPage';
import { DebtorPage } from 'pages/SCF/Debtor/DebtorPage';
import { InternalPage } from 'pages/SCF/Internal/InternalPage';
import {
  AuthenticationPage,
  ResetPasswordPage,
  ConfirmAccountPage,
  CRMPage
} from 'User/components';
import { LinkAuthenticationPage } from 'src/features/User/components/AuthenticationPage/LinkAuthenticationPage';
import { DocumentList } from 'src/features/DocumentList/components/DocumentList';
import { HeaderDocuments } from 'src/features/Layouts/components/HeaderDocuments/HeaderDocuments';
import { BusinessView } from 'src/features/Business/components/BusinessView/BusinessView';
import { BusinessSearch } from 'src/features/Business/components/BusinessSearch/BusinessSearch';
import { PersonView } from 'src/features/Person/components/Person/Person';
import { SearchPerson } from 'src/features/Person/components/SearchPerson/SearchPerson';
import { CabinetWrapper } from 'pages/Cabinet/CabinetWrapper';

// TODO - refactor; now Header, PublicPage and PrivatePage include all STORE application properties
// if user start with /crm CRM application, it should not load all STORE properties
export const App: React.FC = () => (
  <Router history={history}>
    <React.Fragment>
      <Switch>
        <Route path="/registration/externalagent">
          <Header />
        </Route>
        <Route path="/registration/business">
          <Header />
        </Route>
        <Route path="/document_list">
          <HeaderDocuments />
        </Route>
        <Route path="/business">
          <HeaderDocuments />
        </Route>
        <Route path="/person">
          <HeaderDocuments />
        </Route>
        <Route path="/login">
          <Header />
        </Route>
        <Route path="/login-link">
          <Header />
        </Route>
        <Route path="/reset-password">
          <Header />
        </Route>
        <Route path="/confirm">
          <Header />
        </Route>
        <Route path="/cabinet">
          <Header />
        </Route>
        <Route path="/crm">
          <Header />
        </Route>
        <Route path="/internal">
          <Header />
        </Route>
        <Route path="/scf">
          <HeaderSCF />
        </Route>
        <Route path="/supplier">
          <Header />
        </Route>
        <Route path="/factor">
          <Header />
        </Route>
        <Route path="/debtor">
          <Header />
        </Route>
      </Switch>

      <Switch>
        <PublicPage
          path="/registration/externalagent"
          component={ExternalAgentRegistrationPage}
        />
        <Route
          path="/registration/business/:inn?"
          component={BusinessRegistrationPage}
        />
        <PublicPage path="/login" component={AuthenticationPage} />
        <PublicPage
          path="/login-link/:key"
          component={LinkAuthenticationPage}
        />
        <Route path={'/document_list/:token'} component={DocumentList} />
        <Route path={'/person/:inn'} component={PersonView} />
        <Route path={'/person'} component={SearchPerson} />
        <Route path={'/business/:inn'} component={BusinessView} />
        <Route path={'/business'} component={BusinessSearch} />
        <PublicPage path="/reset-password" component={ResetPasswordPage} />
        <PublicPage path="/confirm" component={ConfirmAccountPage} />
        <PrivatePage path="/cabinet" component={CabinetWrapper} />;
        <PrivatePage path="/crm" component={CRMPage} />
        <PublicSupplierPage path="/scf" component={SCFPage} />
        <PrivatePage path="/internal" component={InternalPage} />
        <PrivateSupplierPage path="/supplier" component={SupplierPage} />
        <PrivateFactorPage path="/factor" component={FactorPage} />
        <PrivateDebtorPage path="/debtor" component={DebtorPage} />
        <Redirect to="/login" />
      </Switch>
    </React.Fragment>
  </Router>
);
