import { Document } from 'Application/types';

export enum COMMENT_TYPES {
  NONE = 'NONE',
  WITH_CLIENT = 'WITH_CLIENT',
  WITH_BANK = 'WITH_BANK'
}

export interface Comment {
  id: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  text: string;
  type: COMMENT_TYPES;
  parentId: number;
  isAuthor: boolean;
  replies: Comment[];
  documents: Document[];
}
