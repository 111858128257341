import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/patchPurchaseContractSupplier';

import {
  PATCH_PURCHASE_CONTRACT_SUPPLIER,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/patchPurchaseContractSupplier';

import SCFApi from 'entities/SCF/Supplier/api';

function* patchPurchaseContractSupplier(action: {
  type: PATCH_PURCHASE_CONTRACT_SUPPLIER;
  guid: string;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.patchPurchaseContractSupplier, action.guid, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchPurchaseContractSupplierSaga() {
  yield takeLatest(
    PATCH_PURCHASE_CONTRACT_SUPPLIER.REQ,
    patchPurchaseContractSupplier
  );
}
