import * as React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  req as getStartRate,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getStartRateFactorRole';
import { PageStyled } from 'src/features/SCF/UI/ScfStyles';
import { STORE } from 'src/globaltypes';
import Pie from 'pages/SCF/Supplier/components/MainPage/Pie';
import {
  ContentContainer,
  MainTitle,
  ItemsBlock,
  ItemRow,
  ItemRowText,
  RowLink,
  IconContainer
} from './styled';

export const useTypedSelector: TypedUseSelectorHook<STORE> = useSelector;

type Props = RouteComponentProps;

const MainPage: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();

  const calculateAverage = (data: ResponseDataType): number => {
    const values = Object.values(data);
    const sum = values.reduce((acc, value) => acc + value, 0);
    return values.length > 0 ? sum / values.length : 0;
  };

  const LinkClick = (e: Event, path: string) => {
    e.preventDefault();
    history.push(`/factor/${path}`);
  };

  React.useEffect(
    () => {
      dispatch(getStartRate());
    },
    [dispatch]
  );

  const startRate = useTypedSelector(
    ({ SCFFactor }) => SCFFactor.getStartRateFactorRole.data
  );
  const shouldRedirect = Object.values(startRate).every(value => value === 100);

  const average = calculateAverage(startRate);

  if (shouldRedirect) {
    return <Redirect to="factor/factoring_applications" />;
  }

  return (
    <PageStyled>
      <MainTitle>Начало работы</MainTitle>
      <ContentContainer>
        <Pie
          percentage={average}
          colour={'rgba(28, 202, 148, 1)'}
          radius={150}
          textSize={70}
        />
        <ItemsBlock>
          {startRate.regData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Заполните реквизиты компании</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.regData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Заполните</p>
                <RowLink onClick={e => LinkClick(e, 'settings/requisites')}>
                  реквизиты
                </RowLink>
                <p>компании</p>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.bankAccountsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте реквизиты ваших банковских счетов</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.bankAccountsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте реквизиты ваших </p>
                <RowLink
                  onClick={e => LinkClick(e, 'settings/createBankAccount')}
                >
                  банковских счетов
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.certificateData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте сертификат для подписания документов</p>
              </ItemRowText>
            </ItemRow>
          ) : startRate.certificateData === 75 ? (
            <ItemRow>
              <Pie
                percentage={startRate.certificateData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Дозаполните данные</p>
                <RowLink onClick={e => LinkClick(e, 'settings/certificates')}>
                  сертификата
                </RowLink>
                <p>о подписанте</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.certificateData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте</p>
                <RowLink onClick={e => LinkClick(e, 'settings/certificates')}>
                  сертификат
                </RowLink>
                <p>для подписания документов</p>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.limitsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте первый лимит на дебитора</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.limitsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте первый лимит</p>
                <RowLink onClick={e => LinkClick(e, 'limits?page=1')}>
                  на дебитора
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.connectionsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте первую факторинговую связку</p>
              </ItemRowText>
            </ItemRow>
          ) : startRate.connectionsData === 50 ? (
            <ItemRow>
              <Pie
                percentage={startRate.connectionsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Включите финансирование по первой</p>
                <RowLink onClick={e => LinkClick(e, 'connections?page=1')}>
                  факторинговой связке
                </RowLink>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.connectionsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте первую</p>
                <RowLink onClick={e => LinkClick(e, 'connections?page=1')}>
                  факторинговую связку
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.contractsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте первый договор факторинга</p>
              </ItemRowText>
            </ItemRow>
          ) : startRate.contractsData === 75 ? (
            <ItemRow>
              <Pie
                percentage={startRate.contractsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Подпишите</p>
                <RowLink onClick={e => LinkClick(e, '/contracts?page=1')}>
                  договор факторинга
                </RowLink>
              </ItemRowText>
            </ItemRow>
          ) : startRate.contractsData === 30 ? (
            <ItemRow>
              <Pie
                percentage={startRate.contractsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Загрузите</p>
                <RowLink onClick={e => LinkClick(e, 'contracts?page=1')}>
                  договор факторинга
                </RowLink>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.contractsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте и загрузите</p>
                <RowLink onClick={e => LinkClick(e, 'contracts?page=1')}>
                  договор факторинга
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.subscriptionsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="rgba(28, 202, 148, 1)"
                  size="3x"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте первого покупателя</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.subscriptionsData}
                colour={'rgba(28, 202, 148, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Настройте</p>
                <RowLink onClick={e => LinkClick(e, 'contracts')}>
                  уведомления
                </RowLink>
                <p>по электронной почте</p>
              </ItemRowText>
            </ItemRow>
          )}
        </ItemsBlock>
      </ContentContainer>
    </PageStyled>
  );
};

export default withRouter(MainPage);
