export enum COUNTERPARTIES_TABS {
  DEFAULT = 'Поставщики',
  BUYERS = 'Покупатели',
  AGENTS = 'Агенты'
}

export interface CounterpartiesTypes {
  confirmedByDebtor: boolean;
  confirmedBySupplier: boolean;
  debtorInn: string;
  debtorName: string;
  id: number;
  isDeleted: boolean;
  supplierInn: string;
  supplierName: string;
}

export interface Agents {
  companyINN: string;
  companyName: string;
  agentINN: string;
  agentName: string;
  comment: string;
  isActive: boolean;
}
