import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetContract';

import { PATCH_CABINET_CONTRACT } from '../reducers/patchCabinetContract';
import ContractApi from '../api';

function* patchCabinetContract(action: {
  type: PATCH_CABINET_CONTRACT;
  guid: string;
  data: any;
}): Generator {
  try {
    yield call(ContractApi.patchCabinetContractLogic, action.guid, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetContractSaga(): Generator {
  yield takeLatest(PATCH_CABINET_CONTRACT.REQ, patchCabinetContract);
}
