import { POST_ADD_TO_AUCTION } from 'src/features/SCF/reducers/postAddToAuction';

export interface RequestData {
  SupplierInn: string;
}

export const req = (guid: string, data: RequestData) => ({
  type: POST_ADD_TO_AUCTION.REQ,
  guid,
  data
});

export const got = () => ({
  type: POST_ADD_TO_AUCTION.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_TO_AUCTION.ERR,
  error
});
