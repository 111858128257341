import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCertificate as getCertificateCryptoPro,
  createHash,
  createDetachedSignature,
  Certificate
} from 'crypto-pro-js';
import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/signedEarlyPaymentApplication';
import { SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/signedEarlyPaymentApplication';
import SCFDebtorApi from 'entities/SCF/Debtor/api';
import { STORE } from 'src/globaltypes';

function* processApplication(signedCertificates: string, guid: string) {
  try {
    const certificates: any[] = yield select(
      ({ SCF }: STORE) => SCF.getCertificate.data.items
    );
    const dbCert: CertificateType = certificates.find(
      cert => cert.guid === signedCertificates
    );

    if (!dbCert) throw new Error(`Сертификат ${guid} не найден`);

    // Получаем выбранный сертификат
    const selectedCertificate: Certificate = yield call(
      getCertificateCryptoPro,
      dbCert.serialNumber,
      false
    );

    // Читаем данные из ответа
    const fileUrl = yield call(SCFDebtorApi.downloadDebtorAgreementFile, guid);
    const response = yield call(fetch, fileUrl);

    if (!response.ok) {
      throw new Error(
        `Ошибка загрузки файла: ${response.status} ${response.statusText}`
      );
    }

    const blob = yield call([response, 'blob']);
    const buffer = yield call([blob, 'arrayBuffer']);

    const hash = yield call(createHash, buffer);
    const sig = yield call(
      createDetachedSignature,
      selectedCertificate.thumbprint,
      hash
    );

    // Отправляем подписанный документ
    yield call(SCFDebtorApi.postAgreementSignatureDebtorRole, {
      guid,
      serial: selectedCertificate.thumbprint,
      signature: sig
    });

    const currentResults = yield select(
      ({ SCFDebtor }: STORE) => SCFDebtor.signedEarlyPaymentApplication.data
    );

    yield put(got([...currentResults, { success: true }]));
  } catch (e) {
    const currentResults = yield select(
      ({ SCFDebtor }: STORE) => SCFDebtor.signedEarlyPaymentApplication.data
    );
    yield put(got([...currentResults, { success: false }]));
  }
}

function* signedEarlyPaymentApplication(action: {
  type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE;
  signedCertificates: string;
  guid: string[];
}) {
  try {
    if (action.guid.length) {
      for (const guid of action.guid) {
        yield call(processApplication, action.signedCertificates, guid);
      }
    }
  } catch (e) {
    yield put(err(e));
  }
}

export default function* signedEarlyPaymentApplicationSaga() {
  yield takeLatest(
    SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ,
    signedEarlyPaymentApplication
  );
}
