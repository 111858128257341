import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { STORE } from 'globaltypes';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import { req as deleteApplication } from 'Application/actions/deleteApplication';
import { RequestDataType as DeleteApplicationRequestDataType } from 'Application/reducers/deleteApplication';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';

import { APPLICATION_TYPE_ROUTES } from 'Application/types';

import { ApplicationProcess } from 'Application/components';

interface StateToProps {
  id: number;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  deleteApplication: (data: DeleteApplicationRequestDataType) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const ApplicationCreate: React.FC<Props> = ({
  setApplicationProcessMode,
  deleteApplication,
  id,
  history
}) => {
  React.useEffect(() => {
    setApplicationProcessMode({ mode: APPLICATION_PROCESS_MODES.CREATE });
  }, []);

  const saveApplication = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/application/${id}`);
  };

  const handleDeleteApplication = () => {
    deleteApplication({ id });

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/my-applications?page=1`);
  };

  const isInitializeStep =
    history.location.pathname.indexOf(APPLICATION_TYPE_ROUTES.NONE) !== -1;

  return (
    <ApplicationProcess
      cancelProcess={
        isInitializeStep ? handleDeleteApplication : saveApplication
      }
    />
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  id: Application.setInitializationFormData.id
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setApplicationProcessMode, deleteApplication },
    dispatch
  );

const ApplicationCreateConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationCreate)
);

export { ApplicationCreateConnect as ApplicationCreate };
