import { GET_MANAGERS } from '../reducers/getManagers';
import { ManagersTypes } from 'src/features/Persons/types';

export const req = (inn: string) => ({
  type: GET_MANAGERS.REQ,
  inn
});

export const got = (data: ManagersTypes[]) => ({
  type: GET_MANAGERS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MANAGERS.ERR,
  error
});
