import { GET_FINBANKS_LIST } from '../reducers/getFinbanksList';
import { FinbankRead } from './setFinbankFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: FinbankRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  name?: string;
  creditorType?: string;
  accreditation?: string;
  accreditationStateSupport?: string;
}

export const req = (data: RequestData) => ({
  type: GET_FINBANKS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_FINBANKS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FINBANKS_LIST.ERR,
  error
});
