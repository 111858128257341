import { Reducer, REQUEST_STATUSES } from 'globaltypes';

// export interface ExternalActivities {
//   activityTypeName: string;
//   createdDateTime: string;
//   extComment: string;
//   id: number;
// }

export interface ResponseDataExternal {
  activityTypeName: string;
  createdDateTime: string;
  extComment: string;
  id: number;
}

export interface GetActivitiesByLeadExternalStoreState extends Reducer {
  data: ResponseDataExternal[];
}

export enum GET_ACTIVITIES_BY_LEAD_EXTERNAL {
  REQ = 'GET_ACTIVITIES_BY_LEAD_EXTERNAL_REQ',
  GOT = 'GET_ACTIVITIES_BY_LEAD_EXTERNAL_GOT',
  ERR = 'GET_ACTIVITIES_BY_LEAD_EXTERNAL_ERR'
}

export const initialState: GetActivitiesByLeadExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getActivitiesByLeadExternal = (
  state: GetActivitiesByLeadExternalStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetActivitiesByLeadExternalStoreState => {
  switch (action.type) {
    case GET_ACTIVITIES_BY_LEAD_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ACTIVITIES_BY_LEAD_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ACTIVITIES_BY_LEAD_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getActivitiesByLeadExternal;
