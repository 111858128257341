import * as React from 'react';
import { EquityRatioProportionStyled } from './styles';

interface Props {
  percentage: number;
  proportion: number[];
  position: number;
}

export const EquityRatioProportion: React.FC<Props> = ({
  proportion,
  position
}: Props) => {
  const width = 1111.84;
  const barMargin = 0.5;
  const pointerMargin = 10;
  const pointerPosition = (width * position) / 100;
  const maxPointerPosition = 1080;

  return (
    <EquityRatioProportionStyled viewBox={`0 0 ${width} 102.89`}>
      <rect
        x="0%"
        y="35.69"
        width={`${proportion[0] - barMargin}%`}
        height="12.88"
        fill="#ed446c"
      />
      <rect
        x={`${proportion[0]}%`}
        y="35.69"
        width={`${proportion[1] - barMargin}%`}
        height="12.88"
        fill="#f7cc64"
      />
      <rect
        x={`${proportion[0] + proportion[1]}%`}
        y="35.69"
        width={`${proportion[2] - barMargin}%`}
        height="12.88"
        fill="#6cdcd0"
      />

      <path
        fill="#434e62"
        d="M21.08,0H2.9A2.83,2.83,0,0,0,.07,2.82V19L12,27.31,23.91,19V2.87A2.83,2.83,0,0,0,21.13,0Z"
        transform={`translate(${
          pointerPosition > maxPointerPosition
            ? maxPointerPosition
            : pointerPosition - pointerMargin
        } 0)`}
      />

      <text x="0" y="93.13">
        0%
      </text>
      <text x={`${proportion[0]}%`} y="93.13">
        {proportion[0]}%
      </text>
      <text x={`${proportion[0] + proportion[1]}%`} y="93.13">
        {proportion[0] + proportion[1]}%
      </text>

      <text x="92%" y="93.13">
        100%
      </text>
    </EquityRatioProportionStyled>
  );
};
