import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface CreateActivitiesStoreState extends Reducer {}

export enum CREATE_ACTIVITIES {
  REQ = 'CREATE_ACTIVITIES_REQ',
  GOT = 'CREATE_ACTIVITIES_GOT',
  ERR = 'CREATE_ACTIVITIES_ERR',
  RESET = 'CREATE_ACTIVITIES_RESET'
}

export const initialState: CreateActivitiesStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const createActivities = (
  state: CreateActivitiesStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): CreateActivitiesStoreState => {
  switch (action.type) {
    case CREATE_ACTIVITIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CREATE_ACTIVITIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CREATE_ACTIVITIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case CREATE_ACTIVITIES.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE
      };
    default:
      return state;
  }
};

export default createActivities;
