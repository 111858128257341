import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getExternalCredential';

import {
  GET_EXTERNAL_CREDENTIAL,
} from '../reducers/getExternalCredential';
import ExternalCredentialsApi from '../api';

function* getExternalCredential(action: {
  type: GET_EXTERNAL_CREDENTIAL;
  id: number
}): Generator {
  try {

    const res:any = yield call(ExternalCredentialsApi.getExternalCredential, action.id);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(GET_EXTERNAL_CREDENTIAL.REQ, getExternalCredential);
}
