import { blue, darkBlue } from 'shared/styled';
import styled from 'styled-components';

export const BankAccountsListFormStyled = styled.section`
  margin-top: 10px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BankAccount = styled.div`
  width: 700px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E3E4E9;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const BankAccountItem = styled.p`
  width: 120px;
  color: #000000;
`;

export const AddDataBtn = styled.div`
  z-index: 100;
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${darkBlue};
  }
  p {
    color: #ffffff;
  }
`;
