import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { ForgotPasswordBtnStyled } from './styles';

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
}

class ForgotPasswordBtn extends React.Component<DispatchToProps> {
  render() {
    return (
      <ForgotPasswordBtnStyled
        onClick={() =>
          this.props.openModal({ name: MODAL_NAMES.FORGOT_PASSWORD })
        }
      >
        Забыли пароль?
      </ForgotPasswordBtnStyled>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ openModal }, dispatch);

const ForgotPasswordBtnConnect = connect<null, DispatchToProps>(
  null,
  mapDispatchToProps
)(ForgotPasswordBtn);

export { ForgotPasswordBtnConnect as ForgotPasswordBtn };
