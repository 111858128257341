import { combineReducers } from 'redux';
import patchBasisParams, { PatchBasisParams } from './patchBasisParams';
import patchCabinetBasisParams, {
  PatchCabinetBasisParams
} from './patchCabinetBasisParams';

export interface BasisParamsReducers {
  patchBasisParams: PatchBasisParams;
  patchCabinetBasisParams: PatchCabinetBasisParams;
}

export const BasisParams = combineReducers({
  patchBasisParams,
  patchCabinetBasisParams
});
