import styled from 'styled-components';
import { InputHint } from 'shared/ui/InputHint';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { lighterBlue } from 'shared/styled';
import { FormSection } from 'shared/ui/FormSection';

export const CreditingFormSection = styled(FormSection)`
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const CheckboxGroupStyled = styled(CheckboxGroup)`
  margin-top: 20px;
`;

export const InputHintStyled = styled(InputHint)`
  margin-top: 10px !important;
`;
