import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface ToggleLeadogeneratorPermissionStoreState extends Reducer {}

export enum TOGGLE_LEADOGENERATOR_PERMISSION {
  REQ = 'TOGGLE_LEADOGENERATOR_PERMISSION_REQ',
  GOT = 'TOGGLE_LEADOGENERATOR_PERMISSION_GOT',
  ERR = 'TOGGLE_LEADOGENERATOR_PERMISSION_ERR'
}

export const initialState: ToggleLeadogeneratorPermissionStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const toggleLeadogeneratorPermission = (
  state: ToggleLeadogeneratorPermissionStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): ToggleLeadogeneratorPermissionStoreState => {
  switch (action.type) {
    case TOGGLE_LEADOGENERATOR_PERMISSION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case TOGGLE_LEADOGENERATOR_PERMISSION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case TOGGLE_LEADOGENERATOR_PERMISSION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default toggleLeadogeneratorPermission;
