import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getSubRegistries';

export interface GetSubRegistriesStoreState extends Reducer {
  data: ResponseDataType[];
}

export enum GET_SUB_REGISTRIES {
  GET_SUB_REGISTRIES_REQ = 'GET_SUB_REGISTRIES_REQ',
  GET_SUB_REGISTRIES_GOT = 'GET_SUB_REGISTRIES_GOT',
  GET_SUB_REGISTRIES_ERR = 'GET_SUB_REGISTRIES_ERR'
}

export const initialState: GetSubRegistriesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [
    {
      id: null,
      debtorRegistryId: 0,
      debtorCertificateId: 0,
      debtorSignatureName: null,
      debtorSignaturePosition: null,
      debtorInn: null,
      factorInn: null,
      registryFilename: null,
      registryFileIdentifier: null,
      signatureFilename: null,
      signatureFileIdentifier: null,
      fileSize: null,
      downloadedByFactor: false,
      createdDateTime: null,
      modifiedDateTime: null
    }
  ],
  error: {}
};

const getSubRegistries = (
  state: GetSubRegistriesStoreState = initialState,
  action: {
    type: GET_SUB_REGISTRIES;
    data: ResponseDataType[];
    error: {};
  }
): GetSubRegistriesStoreState => {
  switch (action.type) {
    case GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSubRegistries;
