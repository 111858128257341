import {
  RequestDataType,
  PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE
} from 'entities/SCF/Debtor/model/reducers/putPurchaseContractSignatureDebtorRole';

export const req = (data: RequestDataType) => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.REQ,
  data
});

export const got = () => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.GOT
});

export const err = (error: {}) => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.ERR,
  error
});
