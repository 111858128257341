import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getContactsOfActivity';

import { GET_CONTACTS_OF_ACTIVITIES } from '../reducers/getContactsOfActivities';
import ActivitiesApi from '../api';

function* getContactsOfActivities(action: {
  type: GET_CONTACTS_OF_ACTIVITIES;
  id: string;
}): Generator {
  try {
    const res: any = yield call(ActivitiesApi.getContactsOfActivity, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContactsOfActivitiesSaga(): Generator {
  yield takeLatest(GET_CONTACTS_OF_ACTIVITIES.REQ, getContactsOfActivities);
}
