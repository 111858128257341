import { GET_PURCHASE_CONTRACTS } from '../reducers/getPurchaseContracts';

export interface PurchaseContract {
  id: string;
  supplierInn: string;
  supplierName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  contraceFileIdentifyer: any;
  contraceFileName: string;
  supplierSignatureIdentifier: any;
  supplierSignatureFileName: string;
  debtorSignatureIdentifier: any;
  debtorSignatureFileName: string;
  confirmedByDebtor: boolean;
  paymentDeferment: number;
  paymentDefermentFixed: boolean;
  debtorAgreementForm: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: PurchaseContract[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  SuplierInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_ERR,
  error
});
