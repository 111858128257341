import getContactsListSaga from './getContactsList';
import getContactsSaga from './getContact';
import deleteContactSaga from './deleteContact';
import createContactSaga from './createContact';

export default [
  getContactsSaga,
  getContactsListSaga,
  deleteContactSaga,
  createContactSaga
];
