import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/putSetApproved';
import { PUT_SET_APPROVED } from 'Application/reducers/putSetApproved';

import ApplicationApi from 'Application/api';

function* putSentToBank(action: { type: PUT_SET_APPROVED; id: number }) {
  try {
    yield call(ApplicationApi.putSetApproved, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putSentToBankSaga() {
  yield takeLatest(PUT_SET_APPROVED.REQ, putSentToBank);
}
