import * as React from 'react';
import { connect } from 'react-redux';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu,
  Loader
} from 'src/features/Layouts/components';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { req as getBeneficiaries } from 'src/features/Clients/actions/getBeneficiaries';
import { req as getSubsidaries } from 'src/features/Clients/actions/getSubsidaries';
import {
  req as getFinancialDocuments,
  ResponseData as ResFinancialDocuments
} from 'src/features/Clients/actions/getFinancialDocuments';
import {
  req as getFinancialQuality,
  ResponseData as ResFinancialQuality
} from 'src/features/FinancialForCompany/actions/getFinancialQuality';
import {
  BeneficiariesContainer,
  ClientInfoContainer,
  ClientSectionContainer
} from './styled';
import { CLIENTS_TABS } from '../../types';
import { UserListForExternalAgent } from 'src/features/Users/components/UserListForExternalAgent/UserListForExternalAgent';
import { Beneficiaries } from 'src/features/Beneficiaries/components/Beneficiaries';
import { ExternalAgentFinancialForCompany } from 'src/features/FinancialForCompany/components/ExternalAgentFinancialForCompany/ExternalAgentFinancialForCompany';
import { IntegrationApplications } from 'src/features/IntegrationApplications/components/IntegrationApplications';
import {
  BeneficiariesTypes,
  SubsidiariesTypes
} from 'src/features/Beneficiaries/types';
import { PreApproval } from 'src/features/PreApproval/components/PreApproval';
import { Documents } from './Tabs/Documents/Documents';
import { Subsidiaries } from 'src/features/Beneficiaries/components/Subsidiaries';

interface StateToProps {
  company: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  beneficiaries: BeneficiariesTypes[];
  statusBeneficiaries: REQUEST_STATUSES;
  errorBeneficiaries: ResponseError;
  permissions: USER_PERMISSIONS[];
  documents: ResFinancialDocuments[];
  statusDocuments: REQUEST_STATUSES;
  errorDocuments: ResponseError;
  subsidiaries: SubsidiariesTypes[];
  statusSubsidiaries: REQUEST_STATUSES;
  errorSubsidiaries: ResponseError;
  quality: ResFinancialQuality;
}

interface MatchParams {
  inn: string;
  tab: string;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  getBeneficiaries: (inn: string) => void;
  getFinancialDocuments: (inn: string) => void;
  getSubsidaries: (inn: string) => void;
  getFinancialQuality: (inn: string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ClientView: React.FC<Props> = ({
  company,
  status,
  error,
  getCompanyByInn,
  getBeneficiaries,
  beneficiaries,
  errorBeneficiaries,
  statusBeneficiaries,
  resetStateCompanyByInn,
  getFinancialDocuments,
  documents,
  statusDocuments,
  errorDocuments,
  getSubsidaries,
  subsidiaries,
  statusSubsidiaries,
  errorSubsidiaries,
  permissions,
  getFinancialQuality,
  quality,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof CLIENTS_TABS>(
    permissions.includes(USER_PERMISSIONS.LEADOGENERATOR)
      ? 'PRE_APPROVAL'
      : 'APPLICATIONS'
  );
  const [tabs, setTabs] = React.useState({});
  const [progressDataTab, setProgressDataTab] = React.useState<
    Partial<Record<keyof typeof CLIENTS_TABS, number>>
  >({
    FINANCIAL: null
  });

  React.useEffect(
    () => {
      if (quality !== null) {
        setProgressDataTab({ FINANCIAL: quality.financialsQuality });
      }
    },
    [quality]
  );

  React.useEffect(
    () => {
      const filteredTabs = Object.keys(CLIENTS_TABS)
        .filter(
          tab =>
            tab !== 'PRE_APPROVAL' ||
            permissions.includes(USER_PERMISSIONS.LEADOGENERATOR)
        )
        .reduce(
          (obj, key) => {
            obj[key] = CLIENTS_TABS[key];
            return obj;
          },
          {} as { [key: string]: string }
        );

      setTabs(filteredTabs);
    },
    [permissions]
  );

  React.useEffect(
    () => {
      if (!!match.params.inn.length) {
        getCompanyByInn({ inn: match.params.inn });
        getBeneficiaries(match.params.inn);
        getFinancialDocuments(match.params.inn);
        getSubsidaries(match.params.inn);
        getFinancialQuality(match.params.inn);
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (match.params.tab) {
        setActiveTab(
          match.params.tab.toUpperCase() as keyof typeof CLIENTS_TABS
        );
      } else {
        const url = `${history.location.pathname}${
          permissions.includes(USER_PERMISSIONS.LEADOGENERATOR)
            ? '/pre_approval'
            : '/applications'
        }`;

        history.push(url);
      }
    },
    [match.url, match.params.tab]
  );

  const handleActiveTab = (tabName: keyof typeof CLIENTS_TABS) => {
    setActiveTab(tabName);

    const { tab } = match.params;

    const baseUrl = history.location.pathname.replace(
      `/${tab && tab.toLowerCase()}`,
      ''
    );

    const url = baseUrl + `/${tabName.toLowerCase()}`;
    history.push(url);
  };

  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <ClientInfoContainer>
            <div>
              <ApplicationId>ИНН {company.inn},</ApplicationId>
              <ApplicationId>ОГРН {company.ogrn},</ApplicationId>
              <ApplicationId>КПП {company.kpp}</ApplicationId>
            </div>

            <ApplicationTitle>{company.companyShortName}</ApplicationTitle>
          </ClientInfoContainer>

          <Menu
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            tabs={tabs}
            progressData={progressDataTab}
          />

          <ClientSectionContainer>
            <div>
              {permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) &&
                activeTab === 'PRE_APPROVAL' && <PreApproval />}

              {activeTab === 'APPLICATIONS' && <IntegrationApplications />}

              {activeTab === 'USERS' && <UserListForExternalAgent />}

              {activeTab === 'FINANCIAL' && (
                <ExternalAgentFinancialForCompany />
              )}

              {activeTab === 'BENEFICIARIES' && (
                <BeneficiariesContainer>
                  <Beneficiaries
                    beneficiaries={beneficiaries}
                    status={statusBeneficiaries}
                    error={errorBeneficiaries}
                  />
                  <Subsidiaries
                    subsidiaries={subsidiaries}
                    status={statusSubsidiaries}
                    error={errorSubsidiaries}
                  />
                </BeneficiariesContainer>
              )}

              {activeTab === 'DOCUMENTS' && (
                <Documents
                  fin_documents={documents}
                  status={statusDocuments}
                  error={errorDocuments}
                />
              )}
            </div>
          </ClientSectionContainer>
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  FinancialStatements,
  User,
  SCF,
  Clients
}: CRM & STORE) => ({
  company: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  permissions: User.getUserData.data.permissions,
  beneficiaries: Clients.getBeneficiaries.data,
  statusBeneficiaries: Clients.getBeneficiaries.status,
  errorBeneficiaries: Clients.getBeneficiaries.error,
  documents: Clients.getFinancialDocuments.data,
  statusDocuments: Clients.getFinancialDocuments.status,
  errorDocuments: Clients.getFinancialDocuments.error,
  subsidiaries: Clients.getSubsidariesClient.data,
  statusSubsidiaries: Clients.getSubsidariesClient.status,
  errorSubsidiaries: Clients.getSubsidariesClient.error,
  quality: FinancialStatements.getFinancialQuality.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      resetStateCompanyByInn,
      getBeneficiaries,
      getFinancialDocuments,
      getSubsidaries,
      getFinancialQuality
    },
    dispatch
  );

const ClientViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ClientView)
);

export { ClientViewConnect as ClientView };
