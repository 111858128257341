import * as React from 'react';
import { blue, bgColor } from 'shared/styled';

interface Props {
  size?: number | string;
  className?: string;
}

export const Spinner: React.FC<Props> = ({ size = 88, className }: Props) => (
  <svg
    className={!!className ? className : ''}
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <rect x="0" y="0" width="100" height="100" fill="none" />
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke={bgColor}
      fill="none"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke={blue}
      fill="none"
      strokeWidth="8"
      strokeLinecap="round"
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="1.5s"
        repeatCount="indefinite"
        from="0"
        to="502"
      />
      <animate
        attributeName="stroke-dasharray"
        dur="1.5"
        repeatCount="indefinite"
        values="150.6 100.4;1 250;150.6 100.4"
      />
    </circle>
  </svg>
);
