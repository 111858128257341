import getSupplyContractsListSaga from './getSupplyContractsList';
import getSupplyContractInternalSaga from './getSupplyContractInternal';
import getSupplyContractSupplierSignatureFileSaga from './getSupplyContractSupplierSignatureFile';
import getSupplyContractDebtorSignatureFileSaga from './getSupplyContractDebtorSignatureFile';
import getSupplyContractAgreementFileSaga from './getSupplyContractAgreementFile';
import getContractByInternalSaga from './getContractByInternal';

export default [
  getSupplyContractsListSaga,
  getSupplyContractInternalSaga,
  getSupplyContractSupplierSignatureFileSaga,
  getSupplyContractDebtorSignatureFileSaga,
  getSupplyContractAgreementFileSaga,
  getContractByInternalSaga
];
