import { GET_CONTRACT_FILE } from '../reducers/getContractFile';

export const req = (guid: string) => ({
  type: GET_CONTRACT_FILE.REQ,
  guid
});

export const got = () => ({
  type: GET_CONTRACT_FILE.GOT
});

export const err = (error: {}) => ({
  type: GET_CONTRACT_FILE.ERR,
  error
});
