import { GET_ALL_INTEGRATION_APPLICATIONS } from '../reducers/getAllIntegrationApplications';

export interface IntegrationApplicationsData {
  amount: number;
  clientCompanyName: string;
  clientId: number;
  code: string;
  createdAt: string;
  financingType: string;
  id: string;
  initiatorEmail: string;
  isRead: boolean;
  modifiedAt: string;
  refinancing: boolean;
  source: string;
  status: string;
  term: number;
  termEndDate: string;
  termStartDate: string;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: IntegrationApplicationsData[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  companyInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_ALL_INTEGRATION_APPLICATIONS.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_INTEGRATION_APPLICATIONS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_INTEGRATION_APPLICATIONS.ERR,
  error
});
