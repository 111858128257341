import styled from 'styled-components';
import { rr, gray, navyBlue } from 'shared/styled';

export const Title = styled.span`
  font-family: ${rr};
  font-size: 12px;
  color: ${navyBlue};
`;

export const List = styled.ul`
  width: 250px;
  margin: 10px 0;
  padding-left: 15px;
  line-height: 20px;
`;

export const ListItem = styled.li`
  font-family: ${rr};
  font-size: 12px;
  color: ${({ isRulePassed }) => (isRulePassed ? navyBlue : gray)};
`;
