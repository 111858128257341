import {
  RequestDataType,
  SEND_APPLICATION_TO_BANKS
} from 'Application/reducers/sendApplicationToBanks';

export const req = (data: RequestDataType) => ({
  type: SEND_APPLICATION_TO_BANKS.REQ,
  data
});

export const got = () => ({
  type: SEND_APPLICATION_TO_BANKS.GOT
});

export const err = (error: {}) => ({
  type: SEND_APPLICATION_TO_BANKS.ERR,
  error
});
