import styled from 'styled-components';

export const ClientInfoContainer = styled.div`
  margin-bottom: 40px;

  div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;
