import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'src/features/SCF/actions/postAddedMonetaryClaimByDebtor';

import { POST_ADDED_MONETARY_CLAIM_BY_DEBTOR } from 'src/features/SCF/reducers/postAddedMonetaryClaimByDebtor';

import SCFApi from 'src/features/SCF/api';

function* postAddedMonetaryClaimByDebtor(action: {
  type: POST_ADDED_MONETARY_CLAIM_BY_DEBTOR;
  auctionGuid: string;
  data: RequestData;
}) {
  try {
    yield call(
      SCFApi.postAddedMonetaryClaimByDebtor,
      action.auctionGuid,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddedMonetaryClaimByDebtorSaga() {
  yield takeLatest(
    POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.REQ,
    postAddedMonetaryClaimByDebtor
  );
}
