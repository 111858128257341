import styled from 'styled-components';

export const SupplierCabinetStyled = styled.section`
  width: 100%;
  min-height: 100%;
  display: flex;
  background-color: #FDFDFD;
`;

export const MultiPageStyled = styled.section`
  width: auto;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #FDFDFD;
  margin-bottom: 50px;
`;
