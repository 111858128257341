import { combineReducers } from 'redux';
import setHoldingFormData, {
  SetHoldingDataStoreState
} from './setHoldingFormData';
import getHolding, { GetHoldingStoreState } from './getHolding';
import getHoldingsList, { GetHoldingsListStoreState } from './getHoldingsList';

export interface HoldingsReducers {
  setHoldingFormData: SetHoldingDataStoreState;
  getHolding: GetHoldingStoreState;
  getHoldingsList: GetHoldingsListStoreState;
}

export const Holdings = combineReducers({
  setHoldingFormData,
  getHolding,
  getHoldingsList
});
