import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { req as getUserAccount } from 'src/features/Users/actions/getUser';
import { UserData } from 'src/features/Users/types/UserView';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';

interface StateToProps {
  userAccount: UserData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  getUserAccount: (id: number | string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class UserAccountView extends React.Component<Props> {
  componentDidMount() {
    this.props.getUserAccount(this.props.match.params.id);
  }

  /* TODO extract ApplicationViewStyled and others */
  render() {
    const { userAccount, status, error } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ApplicationId>Аккаунт #{userAccount.id}</ApplicationId>
            <ApplicationTitle>
              {userAccount.lastName} {userAccount.firstName}
            </ApplicationTitle>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>E-mail</span>
                  <span>{userAccount.email}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Телефон</span>
                  <span>{userAccount.phoneNumber}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Роли</span>
                  <span>{userAccount.roles}</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Разрешения</span>
                  <span>{userAccount.permissions}</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Users, User }: CRM) => ({
  userAccount: Users.getUser.user,
  status: Users.getUser.status,
  error: Users.getUser.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserAccount }, dispatch);

const UserAccountViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(UserAccountView)
);

export { UserAccountViewConnect as UserAccountView };
