export enum GET_DOWNLOAD_NON_FIN_SIGNATURE {
  REQ = 'GET_DOWNLOAD_NON_FIN_SIGNATURE_REQ',
  GOT = 'GET_DOWNLOAD_NON_FIN_SIGNATURE_GOT',
  ERR = 'GET_DOWNLOAD_NON_FIN_SIGNATURE_ERR'
}

export interface RequestData {
  fileStream: {
    canRead: boolean;
    canSeek: boolean;
    canWrite: boolean;
    capacity: number;
    length: number;
    position: number;
    canTimeout: boolean;
  };
}

export const req = (guid: string) => ({
  type: GET_DOWNLOAD_NON_FIN_SIGNATURE.REQ,
  guid
});

export const got = (data: RequestData) => ({
  type: GET_DOWNLOAD_NON_FIN_SIGNATURE.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_DOWNLOAD_NON_FIN_SIGNATURE.ERR,
  error
});
