import * as React from 'react';
import { CRMComponent } from '../EachCRM';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { SectionSeparatorStyled } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUniversity,
  faCube,
  faUserPlus,
  faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import { FINBANK_LIST_TYPE } from 'src/features/Finbanks/types';
import { PRODUCT_LIST_TYPE } from 'src/features/Products/types';
import { FinbanksList } from 'src/features/Finbanks/components/FinbanksList/FinbanksList';
import { ProductsList } from 'src/features/Products/components/ProductsList/ProductsList';
import { BankAccountList } from 'src/features/BankAccount/components/BankAccountList/BankAccountList';
import { ExternalCredentialList } from 'src/features/ExternalCredentials/components/ExternalCredentialList/ExternalCredentialList';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const BanksSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Банки</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: FinbanksList,
      path: '/crm/banks/all',
      label: 'Все банки',
      icon: <FontAwesomeIcon icon={faUniversity} fixedWidth={true} />,
      listType: FINBANK_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: ProductsList,
      path: '/crm/products/all',
      label: 'Все продукты',
      icon: <FontAwesomeIcon icon={faCube} fixedWidth={true} />,
      listType: PRODUCT_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: BankAccountList,
      path: '/crm/bank_accounts/all',
      label: 'Банковские счета',
      icon: <FontAwesomeIcon icon={faCreditCard} fixedWidth={true} />,
      listType: PRODUCT_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: ExternalCredentialList,
      path: '/crm/external_credentials/all',
      label: 'Доступы к площадкам',
      icon: <FontAwesomeIcon icon={faUserPlus} fixedWidth={true} />,
      listType: PRODUCT_LIST_TYPE.ALL
    })
  }
];
