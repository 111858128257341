import styled from 'styled-components';

import { Label } from 'shared/ui/Label';
import { gray, blue, darkGray } from 'shared/styled';

export const RadioStyled = styled.input.attrs({
  type: 'radio'
})`
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: unset;

  margin: 6px 8px 6px 0;

  min-width: 16px;
  min-height: 16px;

  cursor: pointer;
  outline: none;
  border-radius: 50%;
  border: 1px solid ${gray};
  background-color: #fff;

  &:checked {
    border: 5px solid ${blue};
    box-sizing: border-box;
  }
`;

export const RadioLabel = styled(Label)`
  cursor: pointer;
  font-size: 14px;
  color: ${darkGray};
`;

export const RadioChildren = styled.div`
  margin: 5px 0 10px 0;
`;
