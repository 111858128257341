import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/postDocumentListClient';

import { POST_DOCUMENT_LIST_CLIENT } from 'entities/Cabinet/Client/model/reducers/postDocumentListClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* postDocumentListClient(action: {
  type: POST_DOCUMENT_LIST_CLIENT;
  guid: string;
}): Generator {
  try {
    yield call(ClientRoleApi.postDocumentListClient, action.guid);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDocumentListClientSaga(): Generator {
  yield takeLatest(POST_DOCUMENT_LIST_CLIENT.REQ, postDocumentListClient);
}
