import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'src/features/Application/actions/getAllBanksList';
import { GET_ALL_BANKS_LIST } from 'src/features/Application/reducers/getAllBanksList';

import ApplicationApi from 'Application/api';

function* getAllBanksList(action: { type: GET_ALL_BANKS_LIST }) {
  try {
    const res: any = yield call(ApplicationApi.getAllBanksList);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBanksListSaga() {
  yield takeLatest(GET_ALL_BANKS_LIST.REQ, getAllBanksList);
}
