import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { PersonsOfCompanies } from '../types';

export interface GetPersonsOfCompaniesStoreState extends Reducer {
  data: PersonsOfCompanies[];
}

export enum GET_PERSONS_OF_COMPANIES {
  REQ = 'GET_PERSONS_OF_COMPANIES_REQ',
  GOT = 'GET_PERSONS_OF_COMPANIES_GOT',
  ERR = 'GET_PERSONS_OF_COMPANIES_ERR'
}

export const initialState: GetPersonsOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getPersonsOfCompanies = (
  state: GetPersonsOfCompaniesStoreState = initialState,
  action: {
    type: GET_PERSONS_OF_COMPANIES;
    data: PersonsOfCompanies[];
    error: {};
  }
): GetPersonsOfCompaniesStoreState => {
  switch (action.type) {
    case GET_PERSONS_OF_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PERSONS_OF_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PERSONS_OF_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPersonsOfCompanies;
