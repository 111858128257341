import ApiRequest from 'shared/utils/ApiRequest';

export default class BankApi {
  static getApplicationBank(id: string) {
    return ApiRequest(`/api/application/bank_role/${id}`);
  }

  static getDocumentList(guid: string) {
    return ApiRequest(`/api/document_list/bank_role/application/${guid}`);
  }

  static getAllOfBank() {
    return ApiRequest('/api/users/bank_admin_role/all');
  }
}
