import styled from 'styled-components';
import { blue, lightBlue, rr } from 'shared/styled';
import {
  ProductColumnContainer,
  Square,
  ProductItem,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';

export const AskStyled = styled.h4`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ProductStandardCashback = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  & > input {
    width: 90px;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${rr};

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: ${({ active, isAdmin }) =>
    isAdmin ? 'pointer' : active !== null ? 'not-allowed' : 'pointer'};

  :hover {
    border-color: ${({ active, isAdmin }) =>
      (active === null || isAdmin) && lightBlue};
    color: ${({ active, isAdmin }) =>
      (active === null || isAdmin) && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: ${({ active, isAdmin }) =>
    isAdmin ? 'pointer' : active !== null ? 'not-allowed' : 'pointer'};

  :hover {
    border-color: ${({ active, isAdmin }) =>
      (active === null || isAdmin) && lightBlue};
    color: ${({ active, isAdmin }) =>
      (active === null || isAdmin) && lightBlue};
  }
`;

export const AskSection = styled.div`
  display: flex;
  flex-direction: column;
  :not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const ProductExclusivityContainer = styled(ProductColumnContainer)`
  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - Эксклюзив' && product !== 2 && active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - Эксклюзив' && product !== 2 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Несколько конкурентов' && product !== 1 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Несколько конкурентов' && product !== 1 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '0 - Стандартные условия' && product !== 0 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '0 - Стандартные условия' && product !== 0 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(3) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '0 - Стандартные условия' || product === 0) && 'white'};
  }
`;

export const ProductSecuringClientsContainer = styled(ProductColumnContainer)`
  margin: 35px 0;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - На клиента больше года' && product !== 3 && active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - На клиента больше года' && product !== 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - На клиента до года' && product !== 2 && active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - На клиента до года' && product !== 2 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - На заявку' && product !== 1 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - На заявку' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '0 - Нет' && product !== 0 && active ? 'gray' : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '0 - Нет' && product !== 0 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '3 - На клиента больше года' || product === 3) && 'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '0 - Нет' || product === 0) && 'white'};
  }
`;

export const ProductCashbackBaseContainer = styled(ProductColumnContainer)`
  margin: 35px 0;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Периодические процентные платежи' &&
        product !== 4 &&
        active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Периодические процентные платежи' &&
        product !== 4 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Абсолютное значение годовых' && product !== 3 && active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Абсолютное значение годовых' && product !== 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - Абсолютное значение от суммы сделки' &&
        product !== 2 &&
        active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - Абсолютное значение от суммы сделки' &&
        product !== 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Валовая маржа' && product !== 1 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Валовая маржа' && product !== 1 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '4 - Периодические процентные платежи' || product === 4) &&
      'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Валовая маржа' || product === 1) && 'white'};
  }
`;
