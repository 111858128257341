import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faPenAlt,
  faPencilAlt,
  faPencilRuler,
  faPenSquare
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { LEAD_LIST_TYPE } from 'src/features/Leads/types';
import { showQuantity } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { CabinetComponent } from '../EachPage';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';
import { CheckSignature } from '../Pages/CheckSignature/CheckSignature';
import { DocumentFormsList } from '../Pages/DocumentsFormList/DocumentsFormList';

interface Props {
  path: string;
  search?: string;
  listType: string;
  icon: any;
  label: string;
  countName: string;
  Component: any;
}

function createListCabinetComponent({
  path,
  search,
  listType,
  icon,
  label,
  countName,
  Component
}: Props) {
  return {
    path: path,
    render: location => <Component listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        labelInfo={showQuantity(countName)}
        to={{
          pathname: path,
          search: `?page=1${search ? `&${search}` : ''}`
        }}
        label={label}
      />
    )
  };
}

function createCabinetComponent({
  path,
  listType,
  icon,
  label,
  countName,
  Component
}: Props) {
  return {
    path: path,
    render: location => <Component listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        labelInfo={showQuantity(countName)}
        to={{ pathname: path }}
        label={label}
      />
    )
  };
}

export const AssistiveSection: CabinetComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Вспомогательное</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createListCabinetComponent({
      Component: DocumentFormsList,
      path: '/cabinet/internal/document_forms/all',
      search: 'formType=6',
      label: 'Формы документов',
      icon: <FontAwesomeIcon icon={faFileAlt} fixedWidth={true} />,
      listType: LEAD_LIST_TYPE.ALL,
      countName: 'files'
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCabinetComponent({
      Component: CheckSignature,
      path: '/cabinet/internal/check_signature',
      label: 'Проверить подпись',
      icon: <FontAwesomeIcon icon={faPencilAlt} fixedWidth={true} />,
      listType: LEAD_LIST_TYPE.ALL,
      countName: 'files'
    })
  }
];
