import {
  RequestDataType,
  ResponseDataType,
  GET_IN_BANK_APPLICATION
} from 'Application/reducers/getInBankApplication';

export const req = (data: RequestDataType) => ({
  type: GET_IN_BANK_APPLICATION.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_IN_BANK_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_IN_BANK_APPLICATION.ERR,
  error
});

export const reset = () => ({
  type: GET_IN_BANK_APPLICATION.RESET
});
