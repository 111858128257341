import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_SUPPLY_CONTRACT_AGREEMENT,
  err
} from '../actions/getSupplyContractAgreementFile';

function* getSupplyContractAgreementFile(action: {
  type: GET_SUPPLY_CONTRACT_AGREEMENT;
  guid: string;
}): Generator {
  try {
    const url = `/api/supply_contract/internal_role/${
      action.guid
    }/get_agreement_file`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractAgreementFileSaga(): Generator {
  yield takeLatest(
    GET_SUPPLY_CONTRACT_AGREEMENT.REQ,
    getSupplyContractAgreementFile
  );
}
