import { ContactsData } from '../actions/setContactFormData';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ContactsApi {
  static getContactsList(inn: string) {
    return ApiRequest(`/api/contacts/inn/${inn}`);
  }

  static getContact(id: number) {
    return ApiRequest(`/api/contacts/id/${id}`);
  }

  static deleteContact(id: number, reason: string) {
    return ApiRequest(`/api/contacts/id/${id}`, 'delete', {
      deleteComment: reason
    });
  }

  static createContact(data) {
    return ApiRequest('/api/contacts', 'post', data);
  }
}
