import { CREATE_APPLICATION } from '../reducers/createApplication';
import { APPLICATION_TYPES } from '../types';

export interface ResponseDataType {
  id: number;
}

export const req = (financingType: APPLICATION_TYPES) => ({
  type: CREATE_APPLICATION.REQ,
  financingType
});

export const got = ({ id }: ResponseDataType) => ({
  type: CREATE_APPLICATION.GOT,
  id
});

export const err = (error: {}) => ({
  type: CREATE_APPLICATION.ERR,
  error
});

export const reset = () => ({
  type: CREATE_APPLICATION.RESET
});
