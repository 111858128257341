import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getClients';

import { GET_CLIENTS_FOR_AGENT } from '../reducers/getClients';
import ClientsApi from '../api';

function* getClientsForAgent(action: {
  type: GET_CLIENTS_FOR_AGENT;
  agentInn: string;
}): Generator {
  try {
    let res: any;

    res = yield call(ClientsApi.getClients, action.agentInn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getClientsForAgentSaga(): Generator {
  yield takeLatest(GET_CLIENTS_FOR_AGENT.REQ, getClientsForAgent);
}
