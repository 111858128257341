import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutRejectedDataStoreState extends Reducer {}

export enum PUT_REJECTED {
  REQ = 'PUT_REJECTED_REQ',
  GOT = 'PUT_REJECTED_GOT',
  ERR = 'PUT_REJECTED_ERROR'
}

export const initialState: PutRejectedDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putRejected = (
  state: PutRejectedDataStoreState = initialState,
  action: {
    type: PUT_REJECTED;
    error: {};
  }
): PutRejectedDataStoreState => {
  switch (action.type) {
    case PUT_REJECTED.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_REJECTED.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_REJECTED.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putRejected;
