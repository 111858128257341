import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { rr } from 'shared/styled';

export const BuyerInfoPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 80vw;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BuyerInfoArea = styled.div`
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    color: #000000;
    margin-top: 20px;
  }
`;

export const BuyerInfo = styled.div`
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
  p {
    font-family:  ${rr};
    margin-bottom: 0!important;
    font-size: 1.3em;
    margin-right: 20px;
    line-height: 140%;
    letter-spacing: 0.5px;
  }
`;
