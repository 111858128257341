import { combineReducers } from 'redux';

// Application helper reducers
import setApplicationProcessMode, {
  RequestDataType as SetApplicatioProcessnModeRequestDataType
} from './setApplicationProcessMode';
import setApplicationListView, {
  RequestDataType as SetApplicatioListViewRequestDataType
} from './setApplicationListView';
import prepareForApplicationEdit, {
  PrepareForApplicationEditStoreState
} from './prepareForApplicationEdit';
import setApplicationListType, {
  RequestDataType as SetApplicationListTypeStoreState
} from './setApplicationListType';

// Application lists reducers
import getApplicationsList, {
  GetApplicationsListStoreState
} from './getApplicationsList';
import getApplicationsQuantity, {
  GetApplicationsQuantityStoreState
} from './getApplicationsQuantity';

// Single application reducers
import { getApplication, GetApplicationStoreState } from './getApplication';
import {
  getInBankApplication,
  GetInBankApplicationStoreState
} from './getInBankApplication';
import changeApplicationStatus, {
  ChangeApplicationStatusStoreState
} from './changeApplicationStatus';
import deleteApplication, {
  DeleteApplicationStoreState
} from './deleteApplication';

// Send data to backend reducers
import updateInitializationFormData, {
  UpdateInitializationFormDataStoreState
} from './updateInitializationFormData';
import sendApplicationData, {
  SendApplicationDataStoreState
} from './sendApplicationData';

// Document reducers
import deleteDocument, { DeleteDocumentStoreState } from './deleteDocument';
import uploadDocuments, { UploadDocumentsStoreState } from './uploadDocuments';

// Bank reducers
import getBanksList, { GetBanksListStoreState } from './getBanksList';
import sendApplicationToBanks, {
  SendApplicationToBanksDataStoreState
} from './sendApplicationToBanks';

import getApplicationFlags, {
  GetApplicationFlagsStoreState
} from './getApplicationFlags';

// Set redux store reducers
import setInitializationFormData, {
  SetInitializationApplicationType
} from './setInitializationFormData';
import setFactoringFormData from './setFactoringFormData';
import setGuaranteesFormData from './setGuaranteesFormData';
import setCreditingFormData from './setCreditingFormData';
import setFinancingFormData from './setFinancingFormData';

import {
  FactoringApplicationWrite,
  GuaranteesApplicationWrite,
  CreditingApplicationWrite,
  FinancingApplicationWrite
} from 'Application/types';
import sendInitialAndGuaranteesData, {
  SendInitialAndGuaranteesDataStoreState
} from './sendInitialAndGuaranteesData';
import setContractFinancingFormData, {
  SetContractFinancingFormData
} from './setContractFinancingFormData';
import setPropertyFinancingFormData, {
  SetPropertyFinancingFormData
} from './setPropertyFinancingFormData';

import toggleExternalVisibility, {
  ToggleExternalVisibility
} from './toggleExternalVisibility';
import setFileUploadBlock, {
  SetFileUploadBlockType
} from './setFileUploadBlockType';

import getCompanyHints, {
  GetCompanyHintsStoreState
} from 'src/features/Application/reducers/getCompanyHints';

import getProductApplication, {
  GetProductApplicationStoreState
} from 'src/features/Application/reducers/getProductApplication';

import postSelectApplication, {
  PostSelectApplicationStoreState
} from 'src/features/Application/reducers/postSelectApplication';

import putSendToBank, {
  SendToBankDataStoreState
} from 'src/features/Application/reducers/putSendToBank';

import putSetPreapproved, {
  PutSetPreapprovedDataStoreState
} from 'src/features/Application/reducers/putSetPreapproved';

import putSetApproved, {
  PutSetApprovedDataStoreState
} from 'src/features/Application/reducers/putSetApproved';

import getActivitiesByApplication, {
  GetActivitiesByApplicationStoreState
} from 'src/features/Application/reducers/getActivitiesByApplication';

import postExternakApiAlfaBank, {
  PostExternalApiAlfaBankStoreState
} from 'src/features/Application/reducers/postExternalApiAlfaBank';

import postExternakApiPSB, {
  PostExternalApiPSBStoreState
} from 'src/features/Application/reducers/postExternalApiPSB';

import getFinancialsSummary, {
  GetFinancialsSummaryStoreState
} from 'src/features/Application/reducers/getFinancailsSummary';

import getExternalApiGIR_BO, {
  GetExternalApiGIR_BOStoreState
} from 'src/features/Application/reducers/getExternalApiGIR_BO';

import putRejected, {
  PutRejectedDataStoreState
} from 'src/features/Application/reducers/putRejected';

import getOpenLoanLimits, {
  GetOpenLoanLimitsStoreState
} from 'src/features/Application/reducers/getOpenLoanLimits';

import getCompanyByInn, {
  GetCompanyByInnApplicationStoreState
} from 'src/features/Application/reducers/getCompanyByInn';

import getAllBanksList, {
  GetAllBanksListStoreState
} from 'src/features/Application/reducers/getAllBanksList';

import patchUpdateCompanyBlank, {
  PatchUpdateCompanyBlankStoreState
} from 'src/features/Application/reducers/patchUpdateCompanyBlank';

import getTenderTeledoc, {
  GetTenderTeledocStoreState
} from 'src/features/Application/reducers/getTenderTeledoc';

import getAddTenderByEruz, {
  GetAddTenderByEruzStoreState
} from 'src/features/Application/reducers/getAddTenderByEruz';

import createApplication, {
  CreateApplicationStoreState
} from 'src/features/Application/reducers/createApplication';

import createDraft, {
  CreateDraftStoreState
} from 'src/features/Application/reducers/createDraft';

import getTenderByApplication, {
  GetTenderByApplicationStoreState
} from 'src/features/Application/reducers/getTenderByApplication';

import getAllOffers, {
  GetAllOffersStoreState
} from 'src/features/Application/reducers/getAllOffers';

import postApplicationPrescoring, {
  PostApplicationPrescoringStoreState
} from 'src/features/Application/reducers/postApplicationPrescoring';

import postOfferSend, {
  PostOfferSendStoreState
} from 'src/features/Application/reducers/postOfferSend';

import postCreateTender, {
  PostCreateTenderStoreState
} from 'src/features/Application/reducers/postCreateTender';

import postAddTenderById, {
  PostAddTenderByIdStoreState
} from 'src/features/Application/reducers/postAddTenderById';

import postDocumentsOfDocumentList, {
  PostDocumentsOfDocumentListStoreState
} from 'src/features/Application/reducers/postDocumentsOfDocumentList';

export interface ApplicationReducers {
  setApplicationProcessMode: SetApplicatioProcessnModeRequestDataType;
  setApplicationListView: SetApplicatioListViewRequestDataType;
  prepareForApplicationEdit: PrepareForApplicationEditStoreState;
  setApplicationListType: SetApplicationListTypeStoreState;

  getApplicationsList: GetApplicationsListStoreState;
  getApplicationsQuantity: GetApplicationsQuantityStoreState;

  getApplication: GetApplicationStoreState;
  getInBankApplication: GetInBankApplicationStoreState;
  changeApplicationStatus: ChangeApplicationStatusStoreState;
  deleteApplication: DeleteApplicationStoreState;

  updateInitializationFormData: UpdateInitializationFormDataStoreState;
  sendApplicationData: SendApplicationDataStoreState;

  deleteDocument: DeleteDocumentStoreState;
  uploadDocuments: UploadDocumentsStoreState;

  getBanksList: GetBanksListStoreState;
  sendApplicationToBanks: SendApplicationToBanksDataStoreState;

  getApplicationFlags: GetApplicationFlagsStoreState;

  setInitializationFormData: SetInitializationApplicationType;
  setFactoringFormData: FactoringApplicationWrite;
  setGuaranteesFormData: GuaranteesApplicationWrite;
  setCreditingFormData: CreditingApplicationWrite;
  setFinancingFormData: FinancingApplicationWrite;
  sendInitialAndGuaranteesData: SendInitialAndGuaranteesDataStoreState;
  setContractFinancingFormData: SetContractFinancingFormData;
  setPropertyFinancingFormData: SetPropertyFinancingFormData;
  toggleExternalVisibility: ToggleExternalVisibility;
  setFileUploadBlock: SetFileUploadBlockType;
  getCompanyHints: GetCompanyHintsStoreState;
  getProductApplication: GetProductApplicationStoreState;
  postSelectApplication: PostSelectApplicationStoreState;
  putSendToBank: SendToBankDataStoreState;
  getActivitiesByApplication: GetActivitiesByApplicationStoreState;
  postExternakApiAlfaBank: PostExternalApiAlfaBankStoreState;
  postExternakApiPSB: PostExternalApiPSBStoreState;
  getFinancialsSummary: GetFinancialsSummaryStoreState;
  getExternalApiGIR_BO: GetExternalApiGIR_BOStoreState;
  putSetPreapproved: PutSetPreapprovedDataStoreState;
  putSetApproved: PutSetApprovedDataStoreState;
  putRejected: PutRejectedDataStoreState;
  getOpenLoanLimits: GetOpenLoanLimitsStoreState;
  getCompanyByInn: GetCompanyByInnApplicationStoreState;
  getAllBanksList: GetAllBanksListStoreState;
  patchUpdateCompanyBlank: PatchUpdateCompanyBlankStoreState;
  getTenderTeledoc: GetTenderTeledocStoreState;
  getAddTenderByEruz: GetAddTenderByEruzStoreState;
  createApplication: CreateApplicationStoreState;
  createDraft: CreateDraftStoreState;
  getTenderByApplication: GetTenderByApplicationStoreState;
  getAllOffers: GetAllOffersStoreState;
  postApplicationPrescoring: PostApplicationPrescoringStoreState;
  postOfferSend: PostOfferSendStoreState;
  postCreateTender: PostCreateTenderStoreState;
  postAddTenderById: PostAddTenderByIdStoreState;
  postDocumentsOfDocumentList: PostDocumentsOfDocumentListStoreState;
}

export const Application = combineReducers({
  setApplicationProcessMode,
  setApplicationListView,
  prepareForApplicationEdit,
  setApplicationListType,

  getApplicationsList,
  getApplicationsQuantity,

  getApplication,
  getInBankApplication,
  changeApplicationStatus,
  deleteApplication,

  updateInitializationFormData,
  sendApplicationData,

  deleteDocument,
  uploadDocuments,

  getBanksList,
  sendApplicationToBanks,

  setInitializationFormData,
  setFactoringFormData,
  setGuaranteesFormData,
  setCreditingFormData,
  setFinancingFormData,
  sendInitialAndGuaranteesData,

  setContractFinancingFormData,
  setPropertyFinancingFormData,

  toggleExternalVisibility,

  getApplicationFlags,
  setFileUploadBlock,
  getCompanyHints,
  getProductApplication,
  postSelectApplication,
  putSendToBank,
  putSetPreapproved,
  putSetApproved,

  getActivitiesByApplication,
  postExternakApiAlfaBank,
  postExternakApiPSB,
  getFinancialsSummary,
  getExternalApiGIR_BO,
  putRejected,
  getOpenLoanLimits,
  getCompanyByInn,
  getAllBanksList,
  patchUpdateCompanyBlank,
  getTenderTeledoc,
  getAddTenderByEruz,
  createApplication,
  createDraft,
  getTenderByApplication,
  getAllOffers,
  postApplicationPrescoring,
  postOfferSend,
  postCreateTender,
  postAddTenderById,
  postDocumentsOfDocumentList
});
