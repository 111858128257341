import { combineReducers } from 'redux';
import getUserFactorList, {
  GetUserFactorListStoreState
} from '../reducers/getUserFactorList';

export interface UserFactorsReducers {
  getUserFactorList: GetUserFactorListStoreState;
}

export const UserFactors = combineReducers({ getUserFactorList });
