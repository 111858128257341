export enum AGENT_APPLICATION_TABS {
  DEFAULT = 'Лиды',
  REQUESTS = 'Заявки'
}

export enum COMPANY_APPLICATION_TABS {
  DEFAULT = 'Заявки',
  LEADS = 'Лиды'
}

export interface ApplicationItem {
  amount: number;
  analystName: string;
  bankManagerId: number;
  client: string;
  code: string;
  currencyCode: null;
  currencySymbol: null;
  employeeEmail: null;
  financingType: string;
  id: number;
  initiatorEmail: null;
  internalAgentId: number;
  mainApplicationId: number;
  refinancing: boolean;
  source: null;
  status: string;
  term: number;
  termEndDate: null;
  termStartDate: null;
  userId: number;
  userName: string;
}

export interface ApplicationListWrite {
  bgQty: number;
  bgSum: number;
  factoringQty: number;
  factoringSum: number;
  items: ApplicationItem[];
  loansQty: number;
  loansSum: number;
  page: number;
  pageSize: number;
  propertyQty: number;
  propertySum: number;
  totalQty: number;
  totalSum: number;
}

export interface ApplicationCompanyType {
  id: number;
  userName: string;
  userId: number;
  initiatorEmail: null;
  term: number;
  amount: number;
  employeeEmail: null;
  refinancing: boolean;
  code: string;
  client: string;
  internalAgentId: number;
  mainApplicationId: number;
  bankManagerId: number;
  source: null;
  termStartDate: null;
  termEndDate: null;
  currencyCode: null;
  currencySymbol: null;
  status: string;
  financingType: string;
  analystName: string;
}
