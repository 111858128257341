import styled from 'styled-components';

import { CrossBtn } from 'shared/styled';

import {
  navyBlue,
  lighterBlue,
  lightGray,
  gray,
  rr
} from 'shared/styled';

export const MultiComponentStyled = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const ValuesContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const EachValue = styled.div`
  margin: 0 4px 4px 0;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;
  background-color: ${lightGray};

  font-family: ${rr};
  font-size: 12px;
  color: ${navyBlue};

  ${CrossBtn} {
    margin-left: 10px;
  }
`;
