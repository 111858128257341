import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getCompaniesList';

export interface GetCompaniesListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_COMPANIES_LIST {
  REQ = 'GET_COMPANIES_LIST_REQ',
  GOT = 'GET_COMPANIES_LIST_GOT',
  ERR = 'GET_COMPANIES_LIST_ERR'
}

export enum GET_COMPANIES_WITH_FILTERS_LIST {
  REQ = 'GET_COMPANIES_WITH_FILTERS_REQ',
  GOT = 'GET_COMPANIES_WITH_FILTERS_GOT',
  ERR = 'GET_COMPANIES_WITH_FILTERS_ERR'
}

export const initialState: GetCompaniesListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getCompaniesList = (
  state: GetCompaniesListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetCompaniesListStoreState => {
  switch (action.type) {
    case GET_COMPANIES_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANIES_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANIES_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_COMPANIES_WITH_FILTERS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANIES_WITH_FILTERS_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANIES_WITH_FILTERS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompaniesList;
