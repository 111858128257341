import * as React from 'react';

import { Error } from 'shared/ui/Error';
import {
  TextareaContainerStyled,
  TextareaStyled,
  TextareaLabel
} from './styles';

interface Props {
  children?: any;
  className?: string;
  name?: string;
  value: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const Textarea: React.FC<Props> = ({
  children,
  className,
  label,
  name,
  value,
  placeholder,
  disabled = false,
  required = false,
  error,
  onChange
}) => (
  <TextareaContainerStyled>
    <TextareaLabel name={name} label={label} required={required} />

    <TextareaStyled
      className={!!className ? className : ''}
      value={value}
      name={name}
      aria-label={name}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />

    <Error error={error} />

    {!!children && children}
  </TextareaContainerStyled>
);
