import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getCompanyByInnThirdParty';
import { GET_COMPANY_BY_INN_THIRD_PARTY } from '../reducers/getCompanyByInnThirdParty';
import SCFApi from '../api';

function* getCompanyByInnThirdParty(action: {
  type: GET_COMPANY_BY_INN_THIRD_PARTY;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getCompanyByInnThirdParty, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnThirdPartySaga() {
  yield takeLatest(
    GET_COMPANY_BY_INN_THIRD_PARTY.GET_COMPANY_BY_INN_THIRD_PARTY_REQ,
    getCompanyByInnThirdParty
  );
}
