import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { LEGAL_TYPES } from 'globaltypes';
import { ValidatorMessages } from 'shared/constants';
import { passwordRules } from 'src/shared/utils/ValidationPatterns';
import {
  alwaysTrueRule,
  phoneRule,
  emailRule,
  legalFormRule,
  innValidationIndividualRule,
  innValidationEntityRule
} from 'src/shared/utils/ValidationRules';
import { Fields } from 'ExternalAgent/components';

export const createValidation = () =>
  new Validator<Fields>({
    firstName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.firstNameRequire
      }
    ],
    lastName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.lastNameRequire
      }
    ],
    phoneNumber: [
      {
        rule: requiredRule,
        message: ValidatorMessages.phoneNumberRequire
      },
      {
        rule: phoneRule,
        message: ValidatorMessages.phoneNumberValid
      }
    ],
    legalForm: [
      {
        rule: legalFormRule,
        message: ValidatorMessages.legalFormRequire
      }
    ],
    code: [
      {
        rule: (code, legalType) =>
          legalType === LEGAL_TYPES.INDIVIDUAL
            ? innValidationIndividualRule(code)
            : legalType === LEGAL_TYPES.ENTITY
              ? innValidationEntityRule(code)
              : requiredRule,
        message: ValidatorMessages.codeValid,
        ruleId: 'innRule'
      }
    ],
    companyName: [
      {
        rule: (company, legalType) =>
          legalType === LEGAL_TYPES.ENTITY
            ? requiredRule(company)
            : alwaysTrueRule,
        message: ValidatorMessages.companyNameRequire,
        ruleId: 'companyNameRule'
      }
    ],
    email: [
      {
        rule: requiredRule,
        message: ValidatorMessages.emailRequire
      },
      {
        rule: emailRule,
        message: ValidatorMessages.emailValid
      }
    ],
    password: passwordRules,
    repeatPassword: [
      {
        rule: (passwordRepeat, passwordOriginal) =>
          passwordRepeat === passwordOriginal,
        message: ValidatorMessages.passwordsEquality,
        ruleId: 'passwordEqual'
      }
    ],
    rulesAgreement: [
      {
        rule: requiredRule,
        message: ValidatorMessages.agreementRequire
      }
    ]
  });
