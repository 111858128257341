import { combineReducers } from 'redux';
import getPlatforms, { GetPlatformsStoreState } from './getPlatforms';
import getExternalCredential, {
  GetExternalCredentialStoreState
} from './getExternalCredential';
import postExternalCredential, {
  PostExternalCredentialStoreState
} from './postExternalCredential';

export interface PlatformsReducers {
  getPlatforms: GetPlatformsStoreState;
  getExternalCredential: GetExternalCredentialStoreState;
  postExternalCredential: PostExternalCredentialStoreState;
}

export const Platforms = combineReducers({
  getPlatforms,
  getExternalCredential,
  postExternalCredential
});
