import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import { reset as SCFRegistrationReset } from 'entities/SCF/Supplier/model/actions/SCFRegisterSupplier';

import { RegistrationPage } from 'User/components';
import { SCFRegistrationFormSupplier } from './SCFRegistrationForm/SCFRegistrationFormSupplier';

import {
  RegistrationPageSCFStyled,
  InfoBlock,
  RegistrationFormSCFBox
} from './styles';

interface StateToProps {
  registrationStatus: REQUEST_STATUSES;
  registrationError: ResponseError;
  email: string;
}

interface DispatchToProps {
  SCFRegistrationReset: () => void;
}

type Props = StateToProps & DispatchToProps;

const SCFRegistrationPage: React.FC<Props> = ({
  email,
  registrationStatus,
  registrationError,
  SCFRegistrationReset
}) => (
  <RegistrationPage
    email={email}
    registrationStatus={registrationStatus}
    registrationError={registrationError}
    registrationReset={SCFRegistrationReset}
  >
    <RegistrationPageSCFStyled>
      <InfoBlock>
        <h2>Регистрация поставщика</h2>
        <p>
          Чтобы стать зарегистрированным поставщиком FINFACTORY вам необходимо
          пройти процесс регистрации. После заполнения данной анкеты с вами
          свяжется наш сотрудник, который уточнит все необходимые детали и
          предоставит вам специальный логин и пароль. После этого вы сможете
          залогиниться в системе и пользоваться ранней оплатой или факторингом.
        </p>
      </InfoBlock>

      <RegistrationFormSCFBox>
        <SCFRegistrationFormSupplier />
      </RegistrationFormSCFBox>
    </RegistrationPageSCFStyled>
  </RegistrationPage>
);

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  email: SCFSupplier.supplierRegister.data.email,
  registrationStatus: SCFSupplier.supplierRegister.status,
  registrationError: SCFSupplier.supplierRegister.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ SCFRegistrationReset }, dispatch);

const SCFRegistrationPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(SCFRegistrationPage);

export { SCFRegistrationPageConnect as SCFRegistrationPageSupplier };
