import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetSetAvailableRegion';

import { PATCH_CABINET_SET_AVAILABLE_REGION } from '../reducers/patchCabinetSetAvailableRegion';
import CustomerRequirementsApi from '../api';

function* patchCabinetSetAvailableRegion(action: {
  type: PATCH_CABINET_SET_AVAILABLE_REGION;
  id: string;
  region: string;
}): Generator {
  try {
    yield call(
      CustomerRequirementsApi.patchCabinetSetAvailableRegion,
      action.id,
      action.region
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetSetAvailableRegionSaga(): Generator {
  yield takeLatest(
    PATCH_CABINET_SET_AVAILABLE_REGION.REQ,
    patchCabinetSetAvailableRegion
  );
}
