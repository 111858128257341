import {
  RequestDataType,
  ADD_INVESTOR_SUPPLIER_ROLE
} from 'entities/SCF/Supplier/model/reducers/addInvestorSupplierRole';

export const req = (data: RequestDataType) => ({
  type: ADD_INVESTOR_SUPPLIER_ROLE.REQ,
  data
});

export const got = () => ({
  type: ADD_INVESTOR_SUPPLIER_ROLE.GOT
});

export const err = (error: {}) => ({
  type: ADD_INVESTOR_SUPPLIER_ROLE.ERR,
  error
});

export const reset = () => ({
  type: ADD_INVESTOR_SUPPLIER_ROLE.RESET
});
