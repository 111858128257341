import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ApplicationStatuses } from 'shared/constants';
import {
    req as getApplicationsList,
    RequestDataType as ApplicationListRequestDataType
} from 'Application/actions/getApplicationsList';
import { addStatusFilter, removeStatusFilter } from '../actions/updateStatusesFilter';
import { req as getFiltersForApplicationListType } from '../actions/getFiltersForApplicationListType';
import {
    RequestDataType as SetApplicationListTypeRequestDataType, APPLICATIONS_LIST_TYPE
} from 'Application/reducers/setApplicationListType';
import { set as setApplicationListType } from 'Application/actions/setApplicationListType';
import { STORE } from 'globaltypes';
import { MultiSelect } from 'shared/ui/MultiSelect';

interface DispatchProps {
    getApplicationsList: (data: ApplicationListRequestDataType) => void;
    addStatusFilter: (status: string) => void;
    removeStatusFilter: (indexToRemove: number) => void;
    setApplicationListType: (data: SetApplicationListTypeRequestDataType) => void;
    getFiltersForApplicationListType: (listType:APPLICATIONS_LIST_TYPE) => void;
}

interface StateToProps {
    statuses: string[],
    availableStatuses: string[]
}

interface OwnProps {
  listType: APPLICATIONS_LIST_TYPE;
}

type Props = OwnProps & DispatchProps & StateToProps;

class StatusFilter extends React.Component<Props> {
    componentDidMount(){
      this.props.getFiltersForApplicationListType(this.props.listType);
    }

    onMultiAdd = (status: string) => {
        this.props.addStatusFilter(status);
    }

    onMultiRemove = (index: number) => {
        this.props.removeStatusFilter(index);
    }

    getStatuses() {
        return this.props.availableStatuses.map((key, index) => {
            return { id: Object.keys(ApplicationStatuses).indexOf(key).toString(), name: ApplicationStatuses[key] }
        });
    }

    render() {
        const initialStatuses = this.getStatuses();
        const statuses = this.props.statuses.map((index) => Object.values(ApplicationStatuses)[index])
        return initialStatuses.length > 1 && (
            <MultiSelect
                name="name"
                label="Фильтрация по статусу"
                placeholder="Статус"
                value=""
                selected={statuses}
                onMultiAdd={this.onMultiAdd}
                onMultiRemove={this.onMultiRemove}
                options={initialStatuses} />)
    }
}

const mapStateToProps = ({ Filters }: STORE) => {
    return {
        statuses: Filters.updateStatusesFilter.statuses,
        availableStatuses: Filters.getFiltersForApplicationListType.data
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        getApplicationsList,
        addStatusFilter,
        setApplicationListType,
        removeStatusFilter,
        getFiltersForApplicationListType
    }, dispatch)

const StatusFilterConnect = connect(
    mapStateToProps,
    mapDispatchToProps)(StatusFilter)

export { StatusFilterConnect as StatusFilter }
