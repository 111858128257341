import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postFinbank';
import { FinbankRead } from 'src/features/Finbanks/actions/setFinbankFormData';

import { POST_FINBANK } from '../reducers/postFinbank';
import FinbanksApi from '../api';

function* postFinbank(action: {
  type: POST_FINBANK;
  data: FinbankRead;
}): Generator {
  try {
    const res: any = yield call(FinbanksApi.sendFinbankData, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFinbankSaga(): Generator {
  yield takeLatest(POST_FINBANK.REQ, postFinbank);
}
