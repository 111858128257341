import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TableHeaderCellStyled, TableStyled } from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

import {
  req as checkAvailableLimits,
  RequestDataType,
  AvailableLimits
} from 'src/features/SCF/actions/checkAvailableLimits';

import {
  PreCheck,
  AddBtn,
  AddBtnText,
  DescriptionArea,
  Description,
  InputArea,
  InputTitle,
  CheckSection,
} from './styles';
import './styles/style.css'
import { PageStyled } from 'src/features/SCF/UI/ScfStyles';


interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  availableLimits: AvailableLimits[];
}

interface DispatchToProps {
  checkAvailableLimits: (data: RequestDataType) => void;
}

type OwnState = {
  innList: string;
  innListCheck: string;
  buyerID: string;
}

type Props = StateToProps & DispatchToProps;

class CheckLimitsPage extends React.Component<Props, OwnState> {

  constructor(props) {
    super(props)
    this.state = {
      innList: '',
      innListCheck: '',
      buyerID: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  initFetch = () => {
    const innToSend = this.state.innListCheck
    const { checkAvailableLimits } = this.props;
    checkAvailableLimits({ innlist: innToSend });
  }

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: OwnState) {
    if (this.state.innListCheck !== prevState.innListCheck) {
      this.initFetch();
    }
  }

  handleChange(event) {
    this.setState({ innList: event.target.value })
  }

  handleCheckChange() {
    this.setState({ innListCheck: this.state.innList })
  }

  CheckClick = () => {
    this.handleCheckChange();
    const checkField = document.getElementById('checkArea')
    checkField.style.display = 'flex'
  }

  onRawClick = (inn: string) => {
    this.setState({ buyerID: inn });
    window.open(`/supplier/debtors/${inn}`);
  }

  render() {
    const { status, availableLimits } = this.props;
    return (
      <PageStyled>
        <PreCheck>
          <h2>Проверить покупателей на лимиты по факторингу:</h2>
          <DescriptionArea>
            <Description>Можно ввести не более 100 покупателей (ИНН) за 1 запрос.<br></br>Разделителем может быть любой нечисловой символ.</Description>
          </DescriptionArea>
          <InputArea>
            <InputTitle>Список покупателей (ИНН):</InputTitle>
            <textarea
              value={this.state.innList}
              onChange={this.handleChange}
              placeholder='1111111111, 2222222222, 3333333333, ...&#10;&#10;или&#10;&#10;1111111111&#10;2222222222&#10;3333333333'
            />
          </InputArea>
          {this.state.innList !== '' ? this.state.innList.length > 11000 ?
            <AddBtn style={{ backgroundColor: '#FF0000', cursor: 'not-allowed' }}>
              <AddBtnText>Проверить</AddBtnText>
            </AddBtn>
            :
            <AddBtn onClick={this.CheckClick}>
              <AddBtnText>Проверить</AddBtnText>
            </AddBtn> :
            <AddBtn style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}>
              <AddBtnText>Проверить</AddBtnText>
            </AddBtn>
          }
        </PreCheck>
        <CheckSection id="checkArea">
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableHeaderCellStyled width="30%">ИНН</TableHeaderCellStyled>
                <TableHeaderCellStyled width="40%">Наименование</TableHeaderCellStyled>
                <TableHeaderCellStyled width="30%">Лимит факторинга</TableHeaderCellStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {availableLimits.map((item, key) => (
                <TableRowStyled key={key} className='table-row' onClick={() => this.onRawClick(item.inn)}>
                  {item.hasLimit === true ?
                  <td>{item.inn}</td>:
                  <td style={{ color: '#FF0000' }}>{item.inn}</td>
                  }
                  {item.hasLimit === true ?
                  <td>{item.companyName}</td>:
                  <td style={{ color: '#FF0000' }}>{item.companyName}</td>
                  }
                  <td>{item.hasLimit === true ? <p>Действует</p> : <p style={{ color: '#FF0000' }}>Отсутствует</p>}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        </CheckSection>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  availableLimits: SCF.checkAvailableLimits.data,
  status: SCF.checkAvailableLimits.status,
  error: SCF.checkAvailableLimits.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ checkAvailableLimits, }, dispatch);

const CheckLimitsConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CheckLimitsPage);

export { CheckLimitsConnect as CheckLimits };
