export enum DOSSIER_TABS {
  DEFAULT = 'Финансовые документы',
  CONSTITUENT_DOCUMENT = 'Нефинансовые документы'
}

export interface FinancialDocumentsData {
  id: number;
  guid: string;
  companyInn: string;
  typeId: number;
  taxPeriodId: string;
  taxYear: number;
  periodStartDate: string;
  periodEndDate: string;
  documentFileName: string;
  documentFileIdentifyer: string;
  signatureFileName: string;
  signatureFileIdentifyer: string;
  version: number;
  userId: number;
  documentName: string;
  taxPeriod: string;
}

export interface NonFinancialDocumentsData {
  id: number;
  guid: string;
  companyInn: string;
  personInn: string;
  bankInn: string;
  personPassport: null;
  objectGuid: string;
  typeId: number;
  periodStartDate: string;
  periodEndDate: string;
  documentFileName: string;
  documentFileIdentifyer: string;
  signatureFileName: string;
  signatureFileIdentifyer: string;
  version: number;
  userId: number;
  documentName: string;
}

interface DocumentsType {
  id: number;
  guid: string;
  documentName: string;
  documentFileName: string;
  signatureFileName: string;
  periods: string;
}

export interface FiltredBeneficiariesType {
  id: number;
  personInn: string;
  firstName: string;
  lastName: string;
  middleName: string;
  ownershipShareDeJure: number;
  documents: DocumentsType[];
}

export interface FiltredPersonsType {
  id: number;
  personInn: string;
  firstName: string;
  lastName: string;
  middleName: string;
  position: string;
  documents: DocumentsType[];
}
