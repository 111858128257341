import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format, isAfter, parseISO } from 'date-fns';

import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getCertificates,
  ResponseData,
  RequestData
} from 'entities/Cabinet/InternalAgent/model/actions/getCertificates';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { TableRow } from './styled';

interface StateToProps {
  certificates: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCertificates: (data: RequestData) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Certificates: React.FC<Props> = ({
  getCertificates,
  certificates,
  status,
  error,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.inn) {
        getCertificates({ companyInn: match.params.inn });
      }
    },
    [match.params.inn]
  );

  const certificateStatus = (certUntil: string, keyUntil: string) => {
    const certValidUntil = parseISO(certUntil);
    const keyValidUntil = parseISO(keyUntil);
    const now = new Date();

    const isCertValid = isAfter(certValidUntil, now);
    const isKeyValid = isAfter(keyValidUntil, now);

    return isCertValid && isKeyValid ? 'Действующий' : 'Истёк';
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Сертификаты</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="20%">Фамилия Имя Отчество</TableThStyled>
                <TableThStyled width="15%">Должность</TableThStyled>
                <TableThStyled width="15%">Срок действия</TableThStyled>
                <TableThStyled width="10%" />
                <TableThStyled width="10%" />
                <TableThStyled width="15%">Тип подписи</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {certificates.items.map(certificate => (
                <TableRow
                  key={certificate.id}
                  expired={
                    certificateStatus(
                      certificate.certValidUntil,
                      certificate.keyValidUntil
                    ) === 'Истёк'
                  }
                >
                  <td>{`${certificate.personSurNameSN} ${
                    certificate.personNameG
                  }`}</td>
                  <td>
                    {certificate.positionT
                      ? certificate.positionT
                      : 'не заполнено'}
                  </td>
                  <td>{`${format(
                    new Date(certificate.certValidFrom),
                    'dd.MM.yyyy'
                  )} - ${format(
                    new Date(certificate.certValidUntil),
                    'dd.MM.yyyy'
                  )}`}</td>
                  <td>
                    {certificateStatus(
                      certificate.certValidUntil,
                      certificate.keyValidUntil
                    )}
                  </td>
                  <td>
                    {certificate.personFullnameGenitive &&
                    certificate.positionGenitive
                      ? 'Заполнено'
                      : ''}
                  </td>
                  <td>
                    {certificate.type === 1
                      ? 'Подпись ЮЛ'
                      : certificate.type === 2 && 'Подпись ФЛ'}
                  </td>
                </TableRow>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={certificates} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  certificates: InternalAgentRole.getCertificates.data,
  status: InternalAgentRole.getCertificates.status,
  error: InternalAgentRole.getCertificates.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCertificates }, dispatch);

const CertificatesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Certificates)
);

export { CertificatesConnect as Certificates };
