import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type DeleteBorrowerCompanyDataStoreState = Reducer;

export interface RequestDataType {
  id: number;
}

export enum DELETE_BORROWER_COMPANY {
  REQ = 'DELETE_BORROWER_COMPANY_REQUEST',
  GOT = 'DELETE_BORROWER_COMPANY_GOT',
  ERR = 'DELETE_BORROWER_COMPANY_ERROR'
}

export const initialState: DeleteBorrowerCompanyDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deleteBorrowerCompany = (
  state: DeleteBorrowerCompanyDataStoreState = initialState,
  action: {
    type: DELETE_BORROWER_COMPANY;
    error: {};
  }
): DeleteBorrowerCompanyDataStoreState => {
  switch (action.type) {
    case DELETE_BORROWER_COMPANY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_BORROWER_COMPANY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_BORROWER_COMPANY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteBorrowerCompany;
