/* RobotoFonts */

export const rt = 'Roboto-Thin';
export const rl = 'Roboto-Light';
export const rr = 'Roboto-Regular';
export const rm = 'Roboto-Medium';
export const rb = 'Roboto-Bold';

/* Colors */

export const bgColor = '#f7f8fc';
export const lighterGray = 'rgba(250, 250, 250, 1)';
export const lightGray = 'rgba(226, 228, 232, 1)';
export const gray = 'rgba(159, 166, 178, 1)';
export const darkGray = 'rgba(6, 36 ,60, 1)';
export const lightBlue = 'rgba(127, 194, 254, 1)';
export const lighterBlue = 'rgba(241, 248, 255, 1)';
export const blue = 'rgba(0, 133, 254, 1)';
export const darkBlue = 'rgba(1, 96, 182, 1)';
export const darkestBlue = 'rgba(0, 75, 143, 1)';
export const navyBlue = 'rgba(63, 78, 101, 1)';
export const green = 'rgba(95, 199, 152, 1)';
export const orange = 'rgba(252, 241, 224, 1);';
export const yellow = 'rgba(247, 241, 177, 1);';

export const black = '#292a2b';
export const darkBlack = '#06243c';
export const red = 'rgba(245, 55, 55, 1)';

/* Size */

export const SidebarWidth = '280px';
export const HeaderHeight = '82px';
