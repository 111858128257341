import { combineReducers } from "redux";
import setFactoringLimitFormData, { SetFactoringLimitDataStoreState } from "./setFactoringLimitFormData";
import getFactoringLimit, { GetFactoringLimitStoreState } from "./getFactoringLimit";
import getFactoringLimitsList, { GetFactoringLimitsListStoreState } from "./getFactoringLimitsList";
import getFactoringLimitsByDebtorList, { GetFactoringLimitsByDebtorListStoreState } from "./getFactoringLimitsByDebtorList";

export interface FactoringLimitsReducers {
  setFactoringLimitFormData: SetFactoringLimitDataStoreState;
  getFactoringLimit: GetFactoringLimitStoreState;
  getFactoringLimitsList: GetFactoringLimitsListStoreState;
  getFactoringLimitsByDebtorList: GetFactoringLimitsByDebtorListStoreState;
}

export const FactoringLimits  = combineReducers({
  setFactoringLimitFormData,
  getFactoringLimit,
  getFactoringLimitsList,
  getFactoringLimitsByDebtorList
});
