import * as React from 'react';
import { format, isAfter } from 'date-fns';

import { CertificateGenitive as CertificateType } from '../../../../../../../../types/certificate';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableRowStyled,
  TableThStyled,
  DeleteIcon
} from 'src/features/SCF/UI/CertificatesTableViewStyled';
import { AddDataBtn } from '../styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { onRowClick, onAddDataClick } from './ClickHandlers';
import './styles/style.css';

interface Props {
  list: CertificateType[];
  onCertificateClick: (e: Event, index: number) => void;
}

export const CertificatesTableView: React.FC<Props> = ({
  list,
  onCertificateClick
}) => {
  return (
    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
      <TableHeaderStyled>
        <tr>
          <TableThStyled width="30%">Фамилия Имя Отчество</TableThStyled>
          <TableThStyled width="25%">Должность</TableThStyled>
          <TableThStyled width="25%">Срок действия</TableThStyled>
          <TableThStyled width="10%">Статус</TableThStyled>
          <TableThStyled width="18%" />
          <TableThStyled width="6%" />
        </tr>
      </TableHeaderStyled>
      <tbody>
        {list.map((item: CertificateType, key) => (
          <React.Fragment key={key}>
            {isAfter(new Date(), new Date(item.certValidUntil)) ? (
              <TableRowStyled
                className="table-row"
                key={key}
                onClick={() => onRowClick(item.guid)}
              >
                <td style={{ color: '#FF0000' }}>
                  {item.personSurNameSN + ' ' + item.personNameG}
                </td>
                <td style={{ color: '#FF0000' }}>{item.positionT}</td>
                <td style={{ color: '#FF0000' }}>
                  {format(new Date(item.certValidFrom), 'dd.MM.yyyy') +
                    ' - ' +
                    format(new Date(item.certValidUntil), 'dd.MM.yyyy')}
                </td>
                <td style={{ color: '#FF0000' }}>Истек</td>
                <td style={{ color: '#FF0000' }}>
                  {item.personFullnameGenitive && item.positionGenitive ? (
                    'Заполнено'
                  ) : (
                    <AddDataBtn
                      onClick={(e: Event) => onAddDataClick(e, item.guid)}
                    >
                      <p>+ Дозаполнить данные</p>
                    </AddDataBtn>
                  )}
                </td>
                <td className="delete-btn-td">
                  <DeleteIcon
                    className="delete-btn"
                    onClick={(e: Event) => onCertificateClick(e, key)}
                  />
                </td>
              </TableRowStyled>
            ) : (
              <TableRowStyled
                className="table-row"
                key={key}
                onClick={() => onRowClick(item.guid)}
              >
                <td>{item.personSurNameSN + ' ' + item.personNameG}</td>
                <td>{item.positionT}</td>
                <td>
                  {format(new Date(item.certValidFrom), 'dd.MM.yyyy') +
                    ' - ' +
                    format(new Date(item.certValidUntil), 'dd.MM.yyyy')}
                </td>
                <td>Действует</td>
                <td>
                  {item.personFullnameGenitive && item.positionGenitive ? (
                    'Заполнено'
                  ) : (
                    <AddDataBtn
                      onClick={(e: Event) => onAddDataClick(e, item.guid)}
                    >
                      <p>+ Дозаполнить данные</p>
                    </AddDataBtn>
                  )}
                </td>
                <td className="delete-btn-td">
                  <DeleteIcon
                    className="delete-btn"
                    onClick={(e: Event) => onCertificateClick(e, key)}
                  />
                </td>
              </TableRowStyled>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </TableStyled>
  );
};
