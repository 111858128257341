import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface SignedEarlyPaymentApplicationStoreState extends Reducer {
  data: any[];
}

export enum SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE {
  REQ = 'SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_REQ',
  GOT = 'SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_GOT',
  ERR = 'SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_ERR',
  RESET = 'SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_RESET'
}

export const initialState: SignedEarlyPaymentApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const signedEarlyPaymentApplication = (
  state: SignedEarlyPaymentApplicationStoreState = initialState,
  action: {
    type: SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE;
    data: { success: boolean }[];
    error: {};
  }
): SignedEarlyPaymentApplicationStoreState => {
  switch (action.type) {
    case SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SIGNED_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default signedEarlyPaymentApplication;
