import styled from 'styled-components';
import {
  blue,
  gray,
  lightGray,
  lighterBlue,
  rl,
  rr
} from 'shared/styled';

export const INNContainer = styled.div`
  margin: 40px 0;

  & > div {
    display: flex;
    column-gap: 40px;
  }

  & > div:first-child {
    margin-bottom: 20px;
  }
`;

export const INNField = styled.div`
  width: 450px;
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div:first-of-type {
    p:last-child {
      color: ${blue};
      font-family: ${rl};
      font-style: italic;
      cursor: pointer;
    }
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-left: 10px;

    & > p {
      margin: 8px 0;
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;

export const TDStyled = styled.td`
  font-family: ${rr} !important;
`;

export const CheckboxStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : gray)};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    left: 30px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;
