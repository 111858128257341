import styled from 'styled-components';

export const ClientInfoContainer = styled.div`
  margin-bottom: 40px;

  div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 165px);

  h3 {
    font-size: 24px;
  }
`;
