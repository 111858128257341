import styled from 'styled-components';
import { navyBlue, rr } from 'shared/styled';

export const ProductNameWrapper = styled.div`
  div {
    font-size: 16px;
    font-family: ${rr};
  }

  div:last-child {
    font-family: ${rr};
    font-size: 12px;
  }
`;

export const ExpirationDateStyled = styled.div`
  color: ${({ dateOut }) => (dateOut ? 'red' : '')};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 25px !important;
    height: 25px;
    padding: 5px;
    color: white;
    background-color: ${({ colorIcon }) => colorIcon};
    border-radius: 5px;
  }

  button {
    cursor: pointer;
    color: ${navyBlue};
    background: none;
    border: none;
  }
`;

export const TDStatusContainer = styled.td`
  div {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  div > div {
    display: flex;
    align-items: center;
    column-gap: 0;
  }

  p {
    cursor: pointer;
    margin: 0;
  }
`;
