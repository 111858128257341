import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import {
  req as getMonetaryClaimsEarlyPaymentApplications,
  RequestDataType,
  Calc as ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  ContractsPageStyled,
  ListSection,
  FullList,
  ListSectionOne,
  FullListOne
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { ApplicationCalcBaseVatSum } from './components/ApplicationCalcBaseVatSum';
import { ApplicationCalcFactorPayer } from './components/ApplicationCalcFactorPayer';
import { ApplicationCalcNoBaseVatSum } from './components/ApplicationCalcNoBaseVatSum';
import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
  statusPostCreateAgreementSupplierRole: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaimsEarlyPaymentApplications: (data: RequestDataType) => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationCalc: React.FC<Props> = ({
  getInvestorOneDebtor,
  investorOneDebtor,
  getMonetaryClaimsEarlyPaymentApplications,
  monetaryClaimsEarlyPaymentApplications,
  status,
  statusPostCreateAgreementSupplierRole,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.id) {
        getInvestorOneDebtor({ guid: match.params.id });
        getMonetaryClaimsEarlyPaymentApplications({ guid: match.params.id });
      }
    },
    [match.params.id]
  );

  return (
    <ContractsPageStyled>
      <CreateLimitsNudge>
        {investorOneDebtor.payerType == 1 ? (
          <h2>Расчет размера дисконта:</h2>
        ) : (
          <h2>Предварительный расчет по факторингу:</h2>
        )}
      </CreateLimitsNudge>
      {investorOneDebtor.payerType == 1 ? (
        <ListSectionOne>
          <FullListOne>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    ДЕБИТОР
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    СУММА СЧЕТОВ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ДАТА РАННЕЙ ОПЛАТЫ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="18%">
                    ID ЗАЯВКИ
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td>
                    {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString(
                      'ru',
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>

                  {investorOneDebtor.payDate != null ? (
                    <td>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                  <td>
                    {investorOneDebtor.guid.substring(
                      investorOneDebtor.guid.length - 5
                    )}
                  </td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          </FullListOne>
        </ListSectionOne>
      ) : (
        <ListSection>
          <FullList style={{ width: '900px' }}>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    ДЕБИТОР
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ФАКТОРА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    СУММА СЧЕТОВ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ДАТА РАННЕЙ ОПЛАТЫ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="18%">
                    ID ЗАЯВКИ
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td>{investorOneDebtor.payerName}</td>
                  <td>
                    {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString(
                      'ru',
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>

                  {investorOneDebtor.payDate != null ? (
                    <td>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                  <td>
                    {investorOneDebtor.guid.substring(
                      investorOneDebtor.guid.length - 5
                    )}
                  </td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          </FullList>
        </ListSection>
      )}

      {statusPostCreateAgreementSupplierRole === REQUEST_STATUSES.REQUEST && (
        <Loader />
      )}

      {status === REQUEST_STATUSES.GOT &&
      monetaryClaimsEarlyPaymentApplications[0].baseVatPecentage !== 0 &&
      investorOneDebtor.payerType === 1 ? (
        <ApplicationCalcBaseVatSum />
      ) : status === REQUEST_STATUSES.GOT &&
      monetaryClaimsEarlyPaymentApplications[0].baseVatPecentage === 0 &&
      investorOneDebtor.payerType === 1 ? (
        <ApplicationCalcNoBaseVatSum />
      ) : (
        <ApplicationCalcFactorPayer />
      )}
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  statusPostCreateAgreementSupplierRole:
    SCF.postCreateAgreementSupplierRole.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      getMonetaryClaimsEarlyPaymentApplications
    },
    dispatch
  );

const ApplicationCalcConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationCalc)
);

export { ApplicationCalcConnect as ApplicationCalc };
