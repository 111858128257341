import { GET_NEW_FINANCIAL_STATEMENTS_ALL } from '../reducers/getNewFinancialStatements';
import { FinancialStatementsAllType } from '../types';

export interface ResponseType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: FinancialStatementsAllType[];
}

export interface RequestType {
  companyinn: string;
  TaxPeriod?: number;
  TaxYear?: number;
}

export const req = ({ companyinn, TaxPeriod, TaxYear }: RequestType) => ({
  type: GET_NEW_FINANCIAL_STATEMENTS_ALL.REQ,
  companyinn,
  TaxPeriod,
  TaxYear
});

export const got = (data: ResponseType) => ({
  type: GET_NEW_FINANCIAL_STATEMENTS_ALL.GOT,
  data
});

export const remove = (TaxPeriod: number, TaxYear: number) => ({
  type: GET_NEW_FINANCIAL_STATEMENTS_ALL.REMOVE,
  TaxPeriod,
  TaxYear
});

export const err = (error: {}) => ({
  type: GET_NEW_FINANCIAL_STATEMENTS_ALL.ERR,
  error
});
