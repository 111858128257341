import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchUpdateCompanyBlankStoreState extends Reducer {}

export enum PATCH_UPDATE_COMPANY_BLANK {
  REQ = 'PATCH_UPDATE_COMPANY_BLANK_REQ',
  GOT = 'PATCH_UPDATE_COMPANY_BLANK_GOT',
  ERR = 'PATCH_UPDATE_COMPANY_BLANK_ERROR'
}

export const initialState: PatchUpdateCompanyBlankStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchUpdateCompanyBlank = (
  state: PatchUpdateCompanyBlankStoreState = initialState,
  action: {
    type: PATCH_UPDATE_COMPANY_BLANK;
    error: {};
  }
): PatchUpdateCompanyBlankStoreState => {
  switch (action.type) {
    case PATCH_UPDATE_COMPANY_BLANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_UPDATE_COMPANY_BLANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_UPDATE_COMPANY_BLANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchUpdateCompanyBlank;
