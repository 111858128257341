import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/Person/actions/getBeneficiariesOfCompanies';
import { GET_BENEFICIARIES_OF_COMPANIES_PERSON } from 'src/features/Person/reducers/getBeneficiariesOfCompanies';
import PersonApi from 'src/features/Person/api';

function* getBeneficiariesOfCompaniesPerson(action: {
  type: GET_BENEFICIARIES_OF_COMPANIES_PERSON;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      PersonApi.getBeneficiariesOfCompanies,
      action.inn
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBeneficiariesOfCompaniesPersonSaga(): Generator {
  yield takeLatest(
    GET_BENEFICIARIES_OF_COMPANIES_PERSON.REQ,
    getBeneficiariesOfCompaniesPerson
  );
}
