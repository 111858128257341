import styled from 'styled-components';
import { rr, blue } from 'shared/styled';

export const RequsitesFormStyled = styled.section`
  margin-top: 20px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RequsitesFormInputItemStyled = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 500px;
    height: 35px;
    display: flex;

    border: 1px solid #a6a8ad;
    padding: 5px 10px;
  }
`;

export const RequsitesFormInputDescriptionStyled = styled.p`
  width: 160px;
  display: block;
  margin-right: 50px;
  font-family: ${rr};
  font-size: 1.4rem;
`;

export const RequsitesFormInputValuesFormStyled = styled.div`
  width: 500px;
  height: 30px;
  display: flex;
`;

export const RequsitesFormInputValuesStyled = styled.p`
  padding: 5px 10px 5px 10px;
  background-color: #f7f8fc;
  border: 1px solid #a6a8ad;
  width: 100%;
`;

export const ChangeButton = styled.div`
  margin-top: 10px;
  width: 150px;
  height: 45px;
  display: flex;

  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #005db1;
  }
  p {
    color: #ffffff;
  }
`;
