import styled from 'styled-components';

export const NoData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  text-align: center;

  p {
    margin: 0;
    font-size: 22px;
  }

  button {
    width: fit-content;
    height: 45px;
  }
`;
