import { APPLICATION_STATUSES } from 'Application/types';
import {
  ACTION_BUTTON_TEMPLATE,
  OwnProps as ActionButtonProps
} from 'Application/components';

export const externalAgentBtnConstructor = (
  status: APPLICATION_STATUSES
): ActionButtonProps[] => {
  switch (status) {
    case APPLICATION_STATUSES.DRAFT:
      return [
        {
          label: 'Удалить черновик',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DELETE
        },
        {
          label: 'Отправить на платформу',
          actionWarning: "Пожалуйста убедитесь что вы загрузили все нужные документы!",
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_AGENT
        }
      ];
    case APPLICATION_STATUSES.TRANSFERRED_TO_AGENT:
    case APPLICATION_STATUSES.IN_WORK:
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.DOCS_REQUEST:
      return [
        {
          label: 'Отозвать заявку',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.AGENT_REJECT
        }
      ];
    case APPLICATION_STATUSES.AWAITING:
      return [
        {
          label: 'Отозвать заявку',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.AGENT_REJECT
        },
        {
          label: 'Отправить на платформу',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.IN_WORK
        }
      ];
    case APPLICATION_STATUSES.APPROVED:
      return [
        {
          label: 'Отозвать заявку',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.AGENT_REJECT
        }
      ];
    case APPLICATION_STATUSES.AGENT_APPROVED:
      return [
        {
          label: 'Принять предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.SUCCESS
        },
        {
          label: 'Oтклонить предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DENIAL,
          actionStatus: APPLICATION_STATUSES.USER_DENIAL
        }
      ];
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.SUCCESS:
      return [];
    default:
      return [];
  }
};
