import { GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT } from 'entities/SCF/Debtor/model/reducers/getDocumentFormBySupplyContract';

export interface ResponseDataType {
  name: string;
  guid: string;
  isDefault: boolean;
}

export const req = (guid: string) => ({
  type: GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.REQ,
  guid
});

export const got = (data: ResponseDataType) => ({
  type: GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.ERR,
  error
});
