import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetPledge';

import { PATCH_CABINET_PLEDGE } from '../reducers/patchCabinetPledge';
import PledgeApi from '../api';

function* patchCabinetPledge(action: {
  type: PATCH_CABINET_PLEDGE;
  guid: string;
  data: any;
}): Generator {
  try {
    yield call(PledgeApi.patchCabinetPledge, action.guid, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetPledgeSaga(): Generator {
  yield takeLatest(PATCH_CABINET_PLEDGE.REQ, patchCabinetPledge);
}
