import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import { GET_MONETARY_CLAIMS_SUPPLIES_ROLE } from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsSuppliesRole';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getMonetaryClaimsSuppliesRole(action: {
  type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getMonetaryClaimsSuppliesRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimsSuppliesRoleSaga() {
  yield takeLatest(
    GET_MONETARY_CLAIMS_SUPPLIES_ROLE.REQ,
    getMonetaryClaimsSuppliesRole
  );
}
