import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { DOCUMENT_UPLOAD_TYPE } from 'Application/types';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { setGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';

import { got, err } from 'Application/actions/deleteDocument';
import {
  DELETE_DOCUMENT,
  SagaRequestDataType
} from 'Application/reducers/deleteDocument';

import ApplicationApi from 'Application/api';
import setPropertyFinancingData from '../reducers/setPropertyFinancingFormData';
import { setContractFinancingFormData } from '../actions/setContractFinancingFormData';
import { setPropertyFinancingFormData } from '../actions/setPropertyFinancingFormData';

function* deleteDocument(action: {
  type: DELETE_DOCUMENT;
  data: SagaRequestDataType;
}): Generator {
  const deleteDocument = documents =>
    documents.filter(document => document.id !== action.data.id);

  try {
    const res:any = yield call(ApplicationApi.deleteDocument, {
      id: action.data.id,
      type: action.data.type
    });

    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.AGREEMENT) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setInitializationFormData.agreementDocuments
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(
        setInitializationFormData({
          agreementDocuments: [...newDocuments]
        })
      );
    }

    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setInitializationFormData.groupDocuments
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(
        setInitializationFormData({
          groupDocuments: [...newDocuments]
        })
      );
    }

    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.FACTORING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) => Application.setFactoringFormData.documents
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(setFactoringFormData({ documents: [...newDocuments] }));
    }

    // TODO duplication
    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.GUARANTEE_CONTRACT) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setGuaranteesFormData.contractDocuments
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(
        setGuaranteesFormData({ contractDocuments: [...newDocuments] })
      );
    }

    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setContractFinancingFormData.contractDocuments
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(
        setContractFinancingFormData({ contractDocuments: [...newDocuments] })
      );
    }

    // TODO duplication refactor
    if (action.data.subType === DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setPropertyFinancingFormData.propertyFinancingDocuments
      );
      const newDocuments = deleteDocument(oldDocuments);

      yield put(
        setPropertyFinancingFormData({
propertyFinancingDocuments: [...newDocuments] })
      );
    }

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteDocumentSaga(): Generator {
  yield takeLatest(DELETE_DOCUMENT.REQ, deleteDocument);
}
