import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from 'entities/SCF/Supplier/api';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import { GET_BY_MONETARY_CLAIM_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/getByMonetaryClaimSupplier';

function* getByMonetaryClaimSupplier(action: {
  type: GET_BY_MONETARY_CLAIM_SUPPLIER;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFApi.getByMonetaryClaimSupplier, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getByMonetaryClaimSupplierSaga() {
  yield takeLatest(
    GET_BY_MONETARY_CLAIM_SUPPLIER.REQ,
    getByMonetaryClaimSupplier
  );
}
