import * as React from 'react';
import { Col } from 'react-grid-system';

import { BorrowerCompany } from 'Application/types';
import { Error } from 'shared/ui/Error';
import { FileUploadRow } from '../Common/FileUploadRow';
import {
  getFinancialUpTo500DocumentsFileRow,
  getLegalDocumentsFileRow
} from './Util';
import { FileUploadHeader } from './styles';

interface OwnProps {
  isMainCompany: boolean;
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

type Props = OwnProps;

export const UpTo500ThousandsBorrowerCompanyFileUploadBlock: React.FC<
  Props
> = ({ borrowerCompany, errors }) => {
  return (
    <>
      <Col lg={12}>
        <FileUploadHeader>Финансовые документы</FileUploadHeader>
        <FileUploadRow
          borrowerCompany={borrowerCompany}
          errors={errors}
          blocks={getFinancialUpTo500DocumentsFileRow(borrowerCompany)}
        />
        <FileUploadHeader>Юридические документы</FileUploadHeader>
        <FileUploadRow
          borrowerCompany={borrowerCompany}
          errors={errors}
          blocks={getLegalDocumentsFileRow(borrowerCompany)}
        />
      </Col>

      <Error error={errors.documents} />
    </>
  );
};
