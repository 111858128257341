import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface AddParticipantStoreState extends Reducer {}

export enum ADD_PARTICIPANT {
  REQ = 'ADD_PARTICIPANT_REQ',
  GOT = 'ADD_PARTICIPANT_GOT',
  ERR = 'ADD_PARTICIPANT_ERR',
  RESET = 'ADD_PARTICIPANT_RESET'
}

export const initialState: AddParticipantStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const addParticipant = (
  state: AddParticipantStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): AddParticipantStoreState => {
  switch (action.type) {
    case ADD_PARTICIPANT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case ADD_PARTICIPANT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case ADD_PARTICIPANT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case ADD_PARTICIPANT.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE
      };
    default:
      return state;
  }
};

export default addParticipant;
