import ApiRequest from 'src/shared/utils/ApiRequest';

export default class QuestionnaireApi {
  static getAgent(inn: string) {
    return ApiRequest(`/api/agent/inn/${inn}`);
  }

  static patchAgent(inn: string, data: any) {
    return ApiRequest(`/api/agent/inn/${inn}`, 'patch', data);
  }
}
