import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getApplicationsCompany';

import { GET_APPLICATIONS_COMPANY } from '../reducers/getApplicationsCompany';
import ApplicationForCompaniesApi from '../api';

function* getApplicationsCompany(action: {
  type: GET_APPLICATIONS_COMPANY;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      ApplicationForCompaniesApi.getApplicationsCompany,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationsSaga(): Generator {
  yield takeLatest(GET_APPLICATIONS_COMPANY.REQ, getApplicationsCompany);
}
