import { DebtorsData } from '../actions/setDebtorFormData';
import { RequestData as GetDebtorsListRequestData } from '../actions/getDebtorsList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class DebtorsApi {
  static sendDebtorData(data: DebtorsData) {
    return ApiRequest('/api/debtor', 'post', data);
  }

  static getDebtorByINN(inn: string) {
    return ApiRequest(`/api/debtor/inn/${inn}`);
  }

  static getAllDebtors(data: GetDebtorsListRequestData) {
    return ApiRequest('/api/debtor/all', 'get', data);
  }
}
