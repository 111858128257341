import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { ValidatorMessages } from 'shared/constants';
import { SetPropertyFinancingFormData } from 'src/features/Application/reducers/setPropertyFinancingFormData';

export const createValidaton = () =>
  new Validator<SetPropertyFinancingFormData>({
    amount: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],

    term: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
