import { GET_DISCOUNTING_SETTINGS } from '../reducers/getDiscountingSettings';
import { DiscountingSettingsWrite } from './setDiscountingSettings';

export interface ResponseDataType {
  guid: string;
  status: string;
  enableDiscounting: boolean;
  enableFactoring: boolean;
  discountLimitPolicy: number;
  manualMaxLimitSize: number;
  limitUsage: number;
  autoLimitPolicy: number;
  fixedSubstractionSum: number;
  substractionPeriodInDays: number;
  discountRatePolicy: number;
  discountRate: number;
  rateIsSurplusToKeyRate: boolean;
  autoRateSubstraction: number;
  factoringOfferPolicy: 1 | 2;
  signatureFilename: string;
  signatureFileIdentifier: string;
  accountBalanceIsIntegrated: boolean;
  factorsExchangeIsIntegrated: boolean;
  minimalDiscount: number;
  paydays: string;
  paydayOffset: number;
  suppliesDataSourcePolicy: number;
  verificationPolicy: number;
  requestFilename: string;
  requestFileIdentifier: string;
  holidayPaydays: boolean;
  epaMcPolicy: number;
  factoringFormatPolicy: 1 | 2;
  factoringNotificationPolicy: 1 | 2 | 3;
  agreementFormFileIdentifier: string;
  agreementFormFilename: string;
  protocolsPolicy: boolean;
}

export const req = () => ({
  type: GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_REQ
});

export const got = (data: DiscountingSettingsWrite) => ({
  type: GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_ERR,
  error
});

export const reset = () => ({
  type: GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_RESET
});
