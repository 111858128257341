import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getInteractionSummary';

export interface GetInteractionSummaryStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_INTERACTION_SUMMARY {
  REQ = 'GET_INTERACTION_SUMMARY_REQ',
  GOT = 'GET_INTERACTION_SUMMARY_GOT',
  ERR = 'GET_INTERACTION_SUMMARY_ERR',
  RESET = 'GET_INTERACTION_SUMMARY_RESET'
}

export const initialState: GetInteractionSummaryStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getInteractionSummary = (
  state: GetInteractionSummaryStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetInteractionSummaryStoreState => {
  switch (action.type) {
    case GET_INTERACTION_SUMMARY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_INTERACTION_SUMMARY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_INTERACTION_SUMMARY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_INTERACTION_SUMMARY.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getInteractionSummary;
