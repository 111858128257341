import {
  RequestDataType,
  POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE
} from 'entities/SCF/Debtor/model/reducers/postAgreementSignatureDebtorRole';

export const req = (data: RequestDataType) => ({
  type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.REQ,
  data
});

export const got = () => ({
  type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.GOT
});

export const err = (error: {}) => ({
  type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.ERR,
  error
});

export const reset = () => ({
  type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.RESET
});
