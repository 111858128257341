import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ContractForProductsApi {
  static patchContract(id: string, data: any) {
    return ApiRequest(`/api/product/id/${id}`, 'patch', data);
  }

  static patchCabinetContractLogic(guid: string, data: any) {
    return ApiRequest(`/api/bank_role/product/id/${guid}`, 'patch', data);
  }
}
