import styled from 'styled-components';

import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { lighterBlue } from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const BorrowerCompaniesBlock = styled.div`
  width: 100%;
  margin-bottom: 0;
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  margin-bottom: 20px;

  & > ${InnPopupStyled} {
    top: 75px;
    left: 20px;
  }

  & > * {
    &:first-child {
      flex: 1;
      margin-right: 20px;
    }

    &:last-child {
      flex: 2;
    }
  }
`;

export const CheckboxGroupStyled = styled(CheckboxGroup)`
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;

  background-color: ${lighterBlue};
`;
