import getCompanySaga from './getCompany';
import getCompaniesListSaga from './getCompaniesList';
import getCompaniesByHoldingListSaga from './getCompaniesByHoldingList';
import getCompaniesWithFiltersListSaga from './getCompaniesWithFilters';
import getMainCounterSaga from './getMainCounter';
import getListInternalAgentsSaga from './getListInternalAgents';

export default [
  getCompanySaga,
  getCompaniesListSaga,
  getCompaniesByHoldingListSaga,
  getCompaniesWithFiltersListSaga,
  getMainCounterSaga,
  getListInternalAgentsSaga
];
