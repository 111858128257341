import { DiscountingSettings } from '../actions/setDiscountingSettings';

export interface SetDiscountingSettingsStoreState extends Partial<DiscountingSettings> { }

export enum SET_DISCOUNTING_SETTINGS {
  SET = 'SET_DISCOUNTING_SETTINGS_SET',
  RESET = 'SET_DISCOUNTING_SETTINGS_RESET',
}

export const initialState: SetDiscountingSettingsStoreState = {
  enableDiscounting: false,
  enableFactroing: false,
  discountLimitPolicy: 0,
  manualMaxLimitSize: 0,
  autoLimitPolicy: 0,
  fixedSubstractionSum: 0,
  substractionInPeriodDays: 0,
  discountRatePolicy: 0,
  discountRate: 0,
  autoRateSubstraction: 0,
  factoringOfferPolicy: 0,
  minimalDiscount: null,
  payWeekdays: null,
  holidayPaydays: false,
  suppliesDataSourcePolicy: 0,
};

const setDiscountingSettings = (
  state: SetDiscountingSettingsStoreState = initialState,
  action: {
    type: string;
    data: DiscountingSettings;
  }
): SetDiscountingSettingsStoreState => {
  switch (action.type) {
    case SET_DISCOUNTING_SETTINGS.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_DISCOUNTING_SETTINGS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setDiscountingSettings;
