import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/postEarlyPaymentApplicationSupplierRole';

import {
  POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/postEarlyPaymentApplicationSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* postEarlyPaymentApplicationSupplierRole(action: {
  type: POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postEarlyPaymentApplicationSupplierRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postEarlyPaymentApplicationSupplierRoleSaga() {
  yield takeLatest(
    POST_EARLY_PAYMENT_APPLICATION_SUPPLIER_ROLE.REQ,
    postEarlyPaymentApplicationSupplierRole
  );
}
