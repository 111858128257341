import { GuaranteesApplicationWrite, GUARANTEES_TYPE } from 'Application/types';

export type SetGuaranteesApplicationType = Partial<GuaranteesApplicationWrite>;

export enum GUARANTEES_FORM_ACTIONS {
  SET = 'SET_GUARANTEES_FORM_DATA',
  RESET = 'RESET_GUARANTEES_FORM_DATA'
}

export const initialState: GuaranteesApplicationWrite = {
  guid: null,
  guaranteesType: GUARANTEES_TYPE['44-FZ'],
  tender: null,
  tax: null,
  commercial: null,
  tenderLink: '',
  tenderComment: '',
  desiredBanks: '',
  typeComment: '',
  contractDocuments: [],
  refinancing: false,
  startPrice: null,
  sumFinal: null,
  lotCode: null,
  subject: '',
  isClosedAuction: null,
  isSingleSupplier: null,
  purchaseLink: ''
};

const setGuaranteesFormData = (
  state: GuaranteesApplicationWrite = initialState,
  action: {
    type: GUARANTEES_FORM_ACTIONS;
    data: SetGuaranteesApplicationType;
  }
): GuaranteesApplicationWrite => {
  switch (action.type) {
    case GUARANTEES_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case GUARANTEES_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setGuaranteesFormData;
