import { combineReducers } from 'redux';
import { Reducer } from 'globaltypes';

import signIn from './signIn';
import signInByLink, { SignInByLinkStoreState } from './signInByLink';
import getUserData, { GetUserDataStoreState } from './getUserData';
import confirmAccount from './confirmAccount';
import reConfirmAccount from './reConfirmAccount';
import sendResetPasswordLetter from './sendResetPasswordLetter';
import resetPassword from './resetPassword';

export interface UserReducers {
  signIn: Reducer;
  signInByLink: SignInByLinkStoreState;
  getUserData: GetUserDataStoreState;
  confirmAccount: Reducer;
  reConfirmAccount: Reducer;
  sendResetPasswordLetter: Reducer;
  resetPassword: Reducer;
}

export const User = combineReducers({
  getUserData,
  signIn,
  signInByLink,
  confirmAccount,
  reConfirmAccount,
  sendResetPasswordLetter,
  resetPassword
});
