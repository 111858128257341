import { GET_DOCUMENT_FORMS } from 'entities/SCF/Debtor/model/reducers/getDocumentForms';

export interface ResponseDataType {
  guid: string;
  name: string;
  exampleFilename: string;
  isMain: boolean;
}

export interface RequestData {
  type: number;
}

export const req = (data?: RequestData) => ({
  type: GET_DOCUMENT_FORMS.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_DOCUMENT_FORMS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DOCUMENT_FORMS.ERR,
  error
});
