import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import {
  createApplicationsListCabinetComponent,
  showQuantity
} from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { ApplicationsList } from 'src/features/Application/components';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { CabinetComponent } from '../EachPage';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

export const CreatedMeSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Созданные мною</SectionSeparatorStyled>
    ),
    hasPermission: permissions =>
      permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS)
  },
  {
    path: '/cabinet/client/api_apps/all',
    hasPermission: permissions =>
      [USER_PERMISSIONS.LEADOGENERATOR].isIn(permissions),
    render: () => (
      <ApplicationsList
        title="Интеграционные заявки"
        description="В этом разделе отображаются заявки, полученное платформой FINFACTORY
          автоматически по ключу API"
        listType={APPLICATIONS_LIST_TYPE.INTEGRATION_APPLICATIONS}
      />
    ),
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={<FontAwesomeIcon icon={faUpload} fixedWidth={true} />}
        to={{
          pathname: '/cabinet/client/api_apps/all',
          search: '?page=1'
        }}
        label="Интеграционные заявки"
        labelInfo={showQuantity('apiApps')}
      />
    )
  },
  {
    hasPermission: permissions =>
      [
        USER_PERMISSIONS.INTERNAL_AGENT,
        USER_PERMISSIONS.CLIENT,
        USER_PERMISSIONS.EXTERNAL_AGENT,
        USER_PERMISSIONS.CREATE_APPLICATIONS
      ].isIn(permissions),

    ...createApplicationsListCabinetComponent({
      path: '/cabinet/client/my-applications',
      type: APPLICATIONS_LIST_TYPE.MY,
      link: {
        icon: 'pen',
        label: 'Мои заявки',
        countName: 'my'
      }
    })
  },
  {
    hasPermission: permissions =>
      [
        USER_PERMISSIONS.INTERNAL_AGENT,
        USER_PERMISSIONS.CLIENT,
        USER_PERMISSIONS.EXTERNAL_AGENT,
        USER_PERMISSIONS.BANK_MANAGER,
        USER_PERMISSIONS.CREATE_APPLICATIONS
      ].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/client/my-deals',
      type: APPLICATIONS_LIST_TYPE.DEAL,
      link: {
        icon: 'docs',
        label: 'Мои сделки',
        countName: 'deals'
      }
    })
  },
  {
    hasPermission: permissions =>
      permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/client/drafts',
      type: APPLICATIONS_LIST_TYPE.DRAFT,
      link: {
        icon: 'clock',
        label: (
          <React.Fragment>
            Заявки на <br /> заполнении
          </React.Fragment>
        ),
        countName: 'drafts'
      }
    })
  },
  {
    path: '/cabinet/client/trash',
    hasPermission: permissions =>
      [
        USER_PERMISSIONS.INTERNAL_AGENT,
        USER_PERMISSIONS.EXTERNAL_AGENT,
        USER_PERMISSIONS.CLIENT,
        USER_PERMISSIONS.BANK_MANAGER,
        USER_PERMISSIONS.CREATE_APPLICATIONS
      ].isIn(permissions),
    render: location => (
      <ApplicationsList listType={APPLICATIONS_LIST_TYPE.TRASH} />
    ),
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="trash"
        to={{
          pathname: '/cabinet/client/trash',
          search: '?page=1'
        }}
        label="Корзина"
        labelInfo={showQuantity('trashed')}
      />
    )
  }
];
