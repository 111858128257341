import { LeadWrite } from "../../actions/setLeadFormData";
import Validator from 'instant-validation';
import { innValidationRule } from "src/shared/utils/ValidationRules";
import { ValidatorMessages } from "shared/constants";

export const createValidation = () =>
  new Validator<LeadWrite>({
    inn:[
      {
        rule: (v: string) => {
          if (v)
            return innValidationRule(v);
          return true;
        },
        message: ValidatorMessages.codeValid
      }
    ]
  })
