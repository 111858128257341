import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from '../actions/delegateToInternalAgent';

export default class InternalAgentsManagerApi {
  static getInternalAgentsList() {
    return ApiRequest('/api/internalAgentsManagers/listInternalAgents', 'get');
  }
  static delegateToInternalAgent(data: RequestData){
    return ApiRequest(`/api/internalAgentsManagers/delegateToInternalAgent/${data.userId}/${data.applicationId}`, 'post')
  }
}
