import styled from 'styled-components';
import { rl, gray } from 'shared/styled';

export const GeneralDocumentsBlockStyled = styled.section`
  margin-top: 30px;

  h2 {
    margin: 0 0 12px 0;
    font-family: ${rl};
    font-size: 1.4rem;
  }
`;

export const ApplicationDownloadAllStyled = styled.div`
height:15px;
padding: 10px;
span{
margin-right: 10px;
}
`
