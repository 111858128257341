import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { REQUEST_STATUSES } from 'globaltypes';
import { APPLICATION_STATUSES } from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { ActionButtonStyled} from'./styles';
import { Button } from 'shared/ui/Button';
import { navyBlue, blue, red } from 'shared/styled';

export enum ACTION_BUTTON_TEMPLATE {
  REQUEST = 'REQUEST',
  TRANSFER = 'TRANSFER',
  REJECT = 'REJECT',
  DENIAL = 'DENIAL',
  DELETE = 'DELETE'
}

export interface OwnProps {
  label: string;
  actionWarning?: string;
  actionTemplate: ACTION_BUTTON_TEMPLATE;
  actionStatus?: APPLICATION_STATUSES;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
}

type Props = OwnProps & DispatchToProps;

const ActionButton: React.FC<Props> = ({
  label,
  actionWarning,
  actionStatus,
  actionTemplate,
  openModal
}: Props) => {
  const props = {
    label,
    onClick: () =>
      openModal({
        payload: {
          label,
          actionWarning,
          actionStatus,
          actionTemplate
        },
        name:
          actionStatus === APPLICATION_STATUSES.TRANSFERRED_TO_BANK
            ? MODAL_NAMES.CHOOSE_BANKS
            : MODAL_NAMES.APPLICATION_ACTION
      })
  };

  switch (actionTemplate) {
    case ACTION_BUTTON_TEMPLATE.REQUEST:
      return <Button backgroundColor={navyBlue} {...props} />;
    case ACTION_BUTTON_TEMPLATE.TRANSFER:
      return <Button backgroundColor={blue} {...props} />;
    case ACTION_BUTTON_TEMPLATE.REJECT:
      return <Button backgroundColor={red} {...props} />;
    case ACTION_BUTTON_TEMPLATE.DENIAL:
      return <Button backgroundColor={red} w="250px" {...props} />;
    case ACTION_BUTTON_TEMPLATE.DELETE:
      return <Button backgroundColor={red} {...props} />;
    default:
      return <ActionButtonStyled {...props} />;
  }
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ openModal }, dispatch);

const ActionButtonConnect = connect<null, DispatchToProps>(
  null,
  mapDispatchToProps
)(ActionButton);

export { ActionButtonConnect as ActionButton };
