import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/getInternalFormFile';
import { GET_INTERNAL_FORM_FILE } from 'entities/Cabinet/InternalAgent/model/reducers/getInternalFormFile';

function* getInternalFormFile(action: {
  type: GET_INTERNAL_FORM_FILE;
  guid: string;
  name: string;
}) {
  try {
    const res: any = yield call(
      fetch,
      `/api/document_forms/internal_role/${action.guid}/get_form_file`
    );
    const file: Blob = yield res.blob();
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${action.name}.docx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    yield put(got(file));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInternalFormFileSaga() {
  yield takeLatest(GET_INTERNAL_FORM_FILE.REQ, getInternalFormFile);
}
