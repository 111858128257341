import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchRegionForbidden extends Reducer {}

export enum PATCH_SET_FORBIDDEN_REGION {
  REQ = 'PATCH_SET_FORBIDDEN_REGION_REQ',
  GOT = 'PATCH_SET_FORBIDDEN_REGION_GOT',
  ERR = 'PATCH_SET_FORBIDDEN_REGION_ERR'
}

export const initialState: PatchRegionForbidden = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchRegionForbidden = (
  state: PatchRegionForbidden = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchRegionForbidden => {
  switch (action.type) {
    case PATCH_SET_FORBIDDEN_REGION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_SET_FORBIDDEN_REGION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_SET_FORBIDDEN_REGION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchRegionForbidden;
