import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { COMMENT_TYPES, Comment } from 'Comments/types';

export interface PostReplyStoreState extends Reducer {
  data: ResponseDataType;
}

export interface RequestDataType {
  id: number;
  applicationId: number;
  type: COMMENT_TYPES;
  files: any[];
  body: {
    text: string;
  };
}

export type ResponseDataType = Comment;

export enum POST_REPLY {
  REQ = 'POST_REPLY_REQUEST',
  GOT = 'POST_REPLY_GOT',
  ERR = 'POST_REPLY_ERROR'
}

export const initialState: PostReplyStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: undefined,
  error: {}
};

const postReply = (
  state: PostReplyStoreState = initialState,
  action: {
    type: POST_REPLY;
    data: ResponseDataType;
    error: {};
  }
): PostReplyStoreState => {
  switch (action.type) {
    case POST_REPLY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_REPLY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case POST_REPLY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postReply;
