import { GET_BY_MONETARY_CLAIM_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/getByMonetaryClaimSupplier';

export interface ResponseData {
  guid: string;
  monetaryClaimGuid: string;
  documentName: string;
  documentNumber: string;
  documentDate: string;
  maturityDate: string;
  baseSumm: number;
  baseVatSumm: number;
  baseFullSumm: number;
  noErrors: null;
  isMain: boolean;
}

export const req = (guid: string) => ({
  type: GET_BY_MONETARY_CLAIM_SUPPLIER.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_BY_MONETARY_CLAIM_SUPPLIER.GOT,
  data
});

export const reset = () => ({
  type: GET_BY_MONETARY_CLAIM_SUPPLIER.RESET
});

export const err = (error: {}) => ({
  type: GET_BY_MONETARY_CLAIM_SUPPLIER.ERR,
  error
});
