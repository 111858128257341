import {
  EXTERNAL_AGENT_STATUS_ACTIONS,
  ResponseData
} from 'ExternalAgent/reducers/externalAgentStatus';

export const req = () => ({
  type: EXTERNAL_AGENT_STATUS_ACTIONS.REQ
});

export const got = (data: ResponseData) => ({
  type: EXTERNAL_AGENT_STATUS_ACTIONS.GOT,
  data
});

export const err = (error: string) => ({
  type: EXTERNAL_AGENT_STATUS_ACTIONS.ERR,
  error
});
