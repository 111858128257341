import { DebtorsData } from '../actions/setDebtorFormData';

export interface SetDebtorDataStoreState extends Partial<DebtorsData> {}

export enum SET_DEBTOR_DATA {
  SET = 'SET_DEBTOR_DATA_SET',
  RESET = 'SET_DEBTOR_DATA_RESET',
}

export const initialState: SetDebtorDataStoreState = {
  //
};

const setDebtorFormData = (
  state: SetDebtorDataStoreState = initialState,
  action: {
    type: string;
    data: DebtorsData;
  }
): SetDebtorDataStoreState => {
  switch (action.type) {
    case SET_DEBTOR_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_DEBTOR_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setDebtorFormData;
