import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { history } from 'src/shared/utils/History';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import { capitalize } from 'src/shared/utils/Utils';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';

import {
  HeaderStyled,
  LogoSCF,
  Logo,
  AuthorizedBlock,
  UserName,
  LogOutBtn,
  HeaderLink,
  SettingsArea,
  SettingsAreaIcon,
  SettingsAreaText
} from './styles';

interface StateToProps {
  name: string;
  status: REQUEST_STATUSES;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  exit: () => void;
  resetUserData: () => void;
}

type Props = StateToProps & DispatchToProps;

class Header extends React.Component<Props> {
  logOut = () => {
    const { exit, resetUserData } = this.props;
    exit();
    resetUserData();
  };

  settingsButton = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/requisites');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/requisites');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/requisites');
    }
  };
  render() {
    const { name, isAuthorized, status, permissions } = this.props;
    return (
      <HeaderStyled>
        <LogoSCF>
          <Logo />
          <h1>|</h1>
          <p style={{ marginLeft: '30px' }}>SCF</p>
          {permissions &&
            permissions.includes(USER_PERMISSIONS.SUPPLIER) && <p>–</p>}
          {permissions &&
            permissions.includes(USER_PERMISSIONS.SUPPLIER) && (
              <p> Кабинет поставщика</p>
            )}
          {permissions &&
            permissions.includes(USER_PERMISSIONS.DEBTOR) && <p>–</p>}
          {permissions &&
            permissions.includes(USER_PERMISSIONS.DEBTOR) && (
              <p> Кабинет покупателя</p>
            )}
          {permissions &&
            permissions.includes(USER_PERMISSIONS.FACTOR) && <p>–</p>}
          {permissions &&
            permissions.includes(USER_PERMISSIONS.FACTOR) && (
              <p> Кабинет фактора</p>
            )}
        </LogoSCF>
        {isAuthorized &&
          status === REQUEST_STATUSES.GOT && (
            <AuthorizedBlock>
              {/* {permissions && permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) &&
                            <HeaderLink
                                onClick={() => history.push('/cabinet/apikeys')}
                            >API</HeaderLink>
                        }  */}
              <SettingsArea onClick={this.settingsButton}>
                <SettingsAreaIcon />
                <SettingsAreaText>Настройки и реквизиты</SettingsAreaText>
              </SettingsArea>
              <UserName>{name}</UserName>
              <LogOutBtn onClick={this.logOut} />
            </AuthorizedBlock>
          )}
      </HeaderStyled>
    );
  }
}

const mapStateToProps = ({ User, Cached }: STORE) => {
  const { firstName, lastName } = User.getUserData.data;
  return {
    name: `${capitalize(firstName)} ${capitalize(lastName)}`,
    status: User.getUserData.status,
    permissions: User.getUserData.data.permissions,
    isAuthorized: Cached.authorized
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ exit, resetUserData }, dispatch);

const HeaderConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export { HeaderConnect as HeaderSCF };
