import styled from 'styled-components';

import { navyBlue, gray } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const ActionModalStyled = styled.div`
  min-width: 300px;

  p {
    line-height: 1.7;
    color: ${gray};
  }
`;

export const ActionWarning = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: red !important;
`;

export const BtnsBlock = styled.div`
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    width: auto;
  }
`;

export const DontCancelBtn = styled(ButtonStyled)`
  color: ${gray};
  background-color: transparent;

  &&:hover {
    color: ${navyBlue};
    background-color: transparent;
  }
`;
