import getProductSaga from './getProduct';
import getProductsListSaga from './getProductsList';
import getProductsByBankListSaga from './getProductsByBankList';
import sendProductDataSaga from './sendProductData';
import postCalculateRatingSaga from './postCalculateRating';

export default [
  getProductSaga,
  getProductsListSaga,
  getProductsByBankListSaga,
  sendProductDataSaga,
  postCalculateRatingSaga
];
