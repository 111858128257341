import styled from 'styled-components';
import { Checkbox } from 'shared/ui/Checkbox';
import { blue } from 'shared/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { PopupContent } from 'src/features/Contacts/components/ContactsList/styled';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { ButtonStyled } from 'src/shared/ui/Button/styled';
import { Form } from 'shared/ui/Form';
import { CrossBtn } from 'shared/styled';
import { darkGray, lightGray } from 'shared/styled';

export const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > div {
    min-width: 400px;
  }

  & > button {
    min-width: fit-content;
    height: 40px;
    margin-top: 20px;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ClientList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0;
  overflow: auto;
`;

export const PopupContentClients = styled(PopupContent)`
  width: 550px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  *:nth-last-child(1) {
    margin-top: auto;
  }

  button {
    width: fit-content;
    width: 170px;
    height: 20px;
    font-size: 14px;
    justify-content: center;
  }
`;

export const TableRow = styled(TableRowStyled)`
  background-color: ${({ isSelected }) => isSelected && blue};
  pointer-events: ${({ isSelected }) => isSelected && 'none'};

  & > td {
    color: ${({ isSelected }) => isSelected && 'white'};
  }
`;

export const CloseApplicationBtn = styled(CrossBtn)`
  width: 20px;
  height: 20px;

  top: 0;
  right: 0;
  position: absolute;
`;

export const ApplicationFormContrainer = styled.section`
  width: 100%;
  height: auto;
  padding: 0 70px;

  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const ApplicationFormStyled = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
`;

export const ApplicationStepTitle = styled.h1`
  margin-bottom: 50px;
  color: ${darkGray};
`;

export const ApplicationFormContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ApplicationInputsColumn = styled.div`
  margin-right: 16vw;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ApplicationForm = styled(Form)`
  width: 100%;

  & > ${InputContainerStyled} {
    width: 400px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > ${ButtonStyled} {
    width: fit-content;
    margin-top: 40px;

    :disabled {
      background-color: ${lightGray};
    }
  }
`;

export const ApplicationBtnsWrapper = styled.div`
  width: 100%;
  margin-top: 40px;

  display: flex;
  flex-wrap: wrap;

  ${ButtonStyled} {
    &:first-child {
      flex: 1;
    }

    &:last-child {
      flex: 2;
    }
  }
`;
