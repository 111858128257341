import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { history } from 'src/shared/utils/History';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ResponseData } from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';
import { ScrollTopComponent } from 'src/features/Common';
import { CheckboxStyle, TDStyled, TooltipBoxStyle } from './styled';

interface Props {
  buyersToSupplierLinkList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export const BuyersToSupplierLinkList: React.FC<Props> = ({
  buyersToSupplierLinkList,
  status,
  error
}) => {
  const navigateToSupplierLink = (debtor_inn: string, supplier_inn: string) => {
    if (debtor_inn && supplier_inn) {
      history.push(
        `/crm/buyers_to_suppliers_links/${debtor_inn}/${supplier_inn}`
      );
    }
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Дебитор</TableThStyled>
                <TableThStyled width="7%">Подтверждено дебитором</TableThStyled>
                <TableThStyled width="15%">Поставщик</TableThStyled>
                <TableThStyled width="10%">
                  Подтверждено поставщиком
                </TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {buyersToSupplierLinkList.items.map(buyer => (
                <TableRowStyled
                  key={buyer.id}
                  onClick={() =>
                    navigateToSupplierLink(buyer.debtorInn, buyer.supplierInn)
                  }
                >
                  <TDStyled>
                    <div>{buyer.debtorName}</div>
                    <div>{buyer.debtorInn}</div>
                  </TDStyled>
                  <td>
                    {buyer.confirmedByDebtor ? (
                      <TooltipBoxStyle textVerify={'Подтверждено дебитором'}>
                        <CheckboxStyle verify={buyer.confirmedByDebtor}>
                          <FontAwesomeIcon icon={faCheck} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    ) : (
                      <TooltipBoxStyle textVerify={'Не подтверждено дебитором'}>
                        <CheckboxStyle verify={buyer.confirmedByDebtor}>
                          <FontAwesomeIcon icon={faMinus} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    )}
                  </td>
                  <td>
                    <div>{buyer.supplierName}</div>
                    <div>{buyer.supplierInn}</div>
                  </td>
                  <td>
                    {buyer.confirmedBySupplier ? (
                      <TooltipBoxStyle textVerify={'Подтверждено поставщиком'}>
                        <CheckboxStyle verify={buyer.confirmedBySupplier}>
                          <FontAwesomeIcon icon={faCheck} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    ) : (
                      <TooltipBoxStyle
                        textVerify={'Не подтверждено поставщиком'}
                      >
                        <CheckboxStyle verify={buyer.confirmedBySupplier}>
                          <FontAwesomeIcon icon={faMinus} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    )}
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={buyersToSupplierLinkList} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};
