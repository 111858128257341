import * as React from 'react';
import { Col } from 'react-grid-system';

import { BorrowerCompany } from 'Application/types';
import { Error } from 'shared/ui/Error';
import { FileUploadRow } from '../Common/FileUploadRow';
import { getLegalDocumentsFileRow, getUpperDocumentsRequired } from './Util';
import { FileUploadHeader } from './styles';

interface Props {
  isMainCompany: boolean;
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const From30MilTo100MilFileUploadBlock: React.FC<Props> = ({
  borrowerCompany,
  errors
}) => (
  <>
    <Col lg={12}>
      <FileUploadRow
        borrowerCompany={borrowerCompany}
        errors={errors}
        blocks={getUpperDocumentsRequired(borrowerCompany, true)}
      />
      <FileUploadHeader>Юридические документы</FileUploadHeader>

      <FileUploadRow
        borrowerCompany={borrowerCompany}
        errors={errors}
        blocks={getLegalDocumentsFileRow(borrowerCompany)}
      />
    </Col>

    <Error error={errors.documents} />
  </>
);
