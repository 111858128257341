import * as React from 'react';
import { REQUEST_STATUSES, CRM, ResponseError } from 'src/globaltypes';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { ScrollTopComponent } from 'src/features/Common';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { BeneficiariesTypes } from '../types';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import { NoData } from './styled';

interface Props {
  beneficiaries: BeneficiariesTypes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export const Beneficiaries: React.FC<Props> = ({
  beneficiaries,
  status,
  error
}) => {
  const [filtredBeneficiaries, setFiltredBeneficiaries] = React.useState<
    BeneficiariesTypes[]
  >([]);

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        const sortedData = beneficiaries.sort(
          (a, b) => a.beneficiaryType - b.beneficiaryType
        );
        setFiltredBeneficiaries(sortedData);
      }
    },
    [status]
  );

  const navigateToCompany = (companyInn: string) => {
    window.open(`/cabinet/parner/clients/inn/${companyInn}`, '_blank');
  };

  const navigateToPersons = (personInn: string) => {
    window.open(`/person/${personInn}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <div>
        <CompanySectionTitle>Бенефициары:</CompanySectionTitle>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT &&
          (beneficiaries.length ? (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">Тип</TableThStyled>
                  <TableThStyled width="15%">Инн</TableThStyled>
                  <TableThStyled width="15%">Имя</TableThStyled>
                  <TableThStyled width="15%">
                    Доля владения, ЕГРЮЛ
                  </TableThStyled>
                  <TableThStyled width="15%">Капитал</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {filtredBeneficiaries.map((beneficiar, key) => (
                  <TableRowStyled
                    key={key}
                    onClick={
                      beneficiar.beneficiaryType === 1
                        ? () => navigateToPersons(beneficiar.personInn)
                        : () => navigateToCompany(beneficiar.headCompanyInn)
                    }
                  >
                    <td>{beneficiar.beneficiaryType === 1 ? 'ФЛ' : 'ЮЛ'}</td>
                    <td>
                      {beneficiar.beneficiaryType === 1
                        ? beneficiar.personInn
                        : beneficiar.headCompanyInn}
                    </td>
                    <td>
                      {beneficiar.beneficiaryType === 1
                        ? `${beneficiar.lastName} ${beneficiar.firstName} ${
                            beneficiar.middleName
                          }`
                        : beneficiar.companyShortName}
                    </td>
                    <td>{beneficiar.ownershipShareDeJure} %</td>
                    <td>{formSumStringThousands(beneficiar.capitalShare)}</td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>попробуйте обновить данные из ЕГРЮЛ</p>
            </NoData>
          ))}
      </div>

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};
