import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { NonFinancialDocumentsData } from '../types';

export interface GetNonFinancialDocumentsStoreState extends Reducer {
  data: NonFinancialDocumentsData[];
}

export enum GET_NON_FINANCIAL_DOCUMENTS {
  REQ = 'GET_NON_FINANCIAL_DOCUMENTS_REQ',
  GOT = 'GET_NON_FINANCIAL_DOCUMENTS_GOT',
  ERR = 'GET_NON_FINANCIAL_DOCUMENTS_ERR'
}

export const initialState: GetNonFinancialDocumentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getNonFinancialDocuments = (
  state: GetNonFinancialDocumentsStoreState = initialState,
  action: {
    type: string;
    data: NonFinancialDocumentsData[];
    error: {};
  }
): GetNonFinancialDocumentsStoreState => {
  switch (action.type) {
    case GET_NON_FINANCIAL_DOCUMENTS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_NON_FINANCIAL_DOCUMENTS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_NON_FINANCIAL_DOCUMENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getNonFinancialDocuments;
