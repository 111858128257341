import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getDocumentFormBySupplyContract';
import { GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT } from 'entities/SCF/Debtor/model/reducers/getDocumentFormBySupplyContract';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDocumentFormBySupplyContract(action: {
  type: GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT;
  guid: string;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getDocumentFormBySupplyContract,
      action.guid
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentFormBySupplyContractSaga() {
  yield takeLatest(
    GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.REQ,
    getDocumentFormBySupplyContract
  );
}
