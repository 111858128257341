import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFiltersForApplicationListType';

import {
  GET_FILTERS_FOR_APPLICATION_LIST_TYPE,
} from '../reducers/getFiltersForApplicationListType';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import StatusFilterApi from '../api';
import { STORE } from 'src/globaltypes';
import { select } from 'redux-saga-test-plan/matchers';


function* getFiltersForApplicationListType(action: {
  type: GET_FILTERS_FOR_APPLICATION_LIST_TYPE;
  listType: APPLICATIONS_LIST_TYPE;
}): Generator {
  try {

    const res:any = yield call(StatusFilterApi.getFiltersForApplicationListType,action.listType);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFiltersForApplicationListTypeSaga(): Generator {
  yield takeLatest(GET_FILTERS_FOR_APPLICATION_LIST_TYPE.REQ, getFiltersForApplicationListType);
}
