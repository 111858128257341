import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/createDraft';
import { CREATE_DRAFT } from '../reducers/createDraft';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from '../reducers/setInitializationFormData';

import Application from 'Application/api';

function* createDraft(action: {
  type: CREATE_DRAFT;
  data: SetInitializationApplicationType;
}): Generator {
  try {
    const res: any = yield call(Application.makeApplicationDraft, action.data);
    yield put(got());
    yield put(setInitializationFormData(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createDraftSaga(): Generator {
  yield takeLatest(CREATE_DRAFT.REQ, createDraft);
}
