import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetBasicParams';

import { PATCH_CABINET_BASIS_PARAMS } from '../reducers/patchCabinetBasisParams';
import BasisParamsApi from '../api';

function* patchCabinetBasisParams(action: {
  type: PATCH_CABINET_BASIS_PARAMS;
  guid: string;
  data: any;
}): Generator {
  try {
    yield call(
      BasisParamsApi.patchCabinetBasicParams,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetBasisParamsSaga(): Generator {
  yield takeLatest(PATCH_CABINET_BASIS_PARAMS.REQ, patchCabinetBasisParams);
}
