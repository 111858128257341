import {
  SET_APPLICATION_PROCESS_MODE,
  RequestDataType
} from 'Application/reducers/setApplicationProcessMode';

export const set = (data: RequestDataType) => ({
  type: SET_APPLICATION_PROCESS_MODE.SET,
  data
});

export const reset = () => ({
  type: SET_APPLICATION_PROCESS_MODE.RESET
});
