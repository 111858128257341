/**
 * Сформировать URL — подставляет в url, поля из объекта с данными.
 * Попутно удаляет из объекта поля, которые были добавлены в url.
 * @param {string} url Адрес запроса
 * @param {object} [data] Hashmap данных запроса, если не задано,
 *                        то url останется без изменений
 * @return {object} {url: сформированный url, data: обновленный набор данных}
 */
export default (
  url: string,
  data?: object | null
): { url: string; data?: object | null } => {
  const replacedFields = {};
  if (!data) {
    return { url, data };
  }

  const newUrl = url.replace(/{([a-z_]+)}/gi, (match, field) => {
    if (typeof data[field] !== 'undefined' && data[field] !== null) {
      replacedFields[field] = true;

      return data[field];
    } else {
      return field;
    }
  });

  const newData = {};
  for (const field in data) {
    if (!replacedFields[field]) {
      newData[field] = data[field];
    }
  }

  return { url: newUrl, data: newData };
};
