import styled from 'styled-components';
import { HeaderClients } from 'src/features/ClientsForAgent/components/styled';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';

export const ClientHeader = styled(HeaderClients)`
  h2 {
    margin: 0;
  }
`;

export const InnInputContainer = styled(INNFilterContainerStyled)`
  margin: 30px 0;
`;

export const TDCompanyName = styled.td`
  font-style: ${({ isFilled }) => !isFilled && 'italic'};
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  row-gap: 10px;
`;

export const PreApprovalTH = styled(TableThStyled)`
  position: relative;
  overflow: visible !important;

  & > div {
    position: absolute;
    top: -20px;
    right: -60px;
    font-size: 14px;
  }
`;

export const PreApproval = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;
