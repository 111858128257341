import { CREATE_BANK_ACCOUNT } from '../reducers/createBankAccount';

export interface RequestData {
  clientInn: string;
  bankBik: string;
  bankName: string;
  accountNumber: string;
  correspondentAccountNumber: string;
}

export const req = (data: RequestData) => ({
  type: CREATE_BANK_ACCOUNT.REQ,
  data
});

export const got = () => ({
  type: CREATE_BANK_ACCOUNT.GOT
});

export const reset = () => ({
  type: CREATE_BANK_ACCOUNT.RESET
});

export const err = (error: {}) => ({
  type: CREATE_BANK_ACCOUNT.ERR,
  error
});
