import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { DescriptionArea, Description, InputArea, InputTitle } from './styles';
import { STORE } from 'src/globaltypes';
import { PageStyled } from 'src/features/SCF/UI/ScfStyles';

import { req as sendBuyers } from 'src/features/SCF/actions/sendBuyers';
import { RequestDataType as SendBuyersDataType } from 'src/features/SCF/reducers/sendBuyers';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {}

type OwnState = {
  innList: string;
};

interface DispatchToProps {
  sendBuyers: (data: SendBuyersDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class DebtorsAddPage extends React.Component<Props, OwnState> {
  constructor(props) {
    super(props);
    this.state = {
      innList: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ innList: event.target.value });
  }
  onAddBuyersClick = () => {
    this.props.sendBuyers({
      buyerInnList: this.state.innList
    });
    history.replace('/supplier/debtors?page=1');
  };

  render() {
    return (
      <PageStyled>
        <h2>Добавить покупателей</h2>
        <DescriptionArea>
          <Description>
            Можно ввести не более 1000 покупателей (ИНН) за 1 запрос.
            <br />
            Разделителем может быть любой нечисловой символ.
          </Description>
        </DescriptionArea>
        <InputArea>
          <InputTitle>Список покупателей (ИНН):</InputTitle>
          <textarea
            value={this.state.innList}
            placeholder="1111111111, 2222222222, 3333333333, ...&#10;&#10;или&#10;&#10;1111111111&#10;2222222222&#10;3333333333"
            onChange={this.handleChange}
          />
        </InputArea>
        {this.state.innList !== '' ? (
          this.state.innList.length > 110000 ? (
            <Button
              label="+ Добавить"
              backgroundColor="#FF0000"
              w="fit-content"
            />
          ) : (
            <Button
              label="+ Добавить"
              onClick={this.onAddBuyersClick}
              w="fit-content"
            />
          )
        ) : (
          <Button
            label="+ Добавить"
            backgroundColor="#9FA6B2"
            w="fit-content"
          />
        )}
      </PageStyled>
    );
  }
}
const mapStateToProps = ({ SCF }: STORE) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendBuyers
    },
    dispatch
  );

const DebtorsAddConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorsAddPage)
);

export { DebtorsAddConnect as DebtorsAddPage };
