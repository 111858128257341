import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'src/features/Application/actions/getAddTenderByEruz';
import { GET_ADD_TENDER_BY_ERUZ } from 'src/features/Application/reducers/getAddTenderByEruz';

import ApplicationApi from 'Application/api';

function* getAddTenderByEruz(action: {
  type: GET_ADD_TENDER_BY_ERUZ;
  id: string;
  eruz: string;
}) {
  try {
    yield call(ApplicationApi.getAddTenderByEruz, action.id, action.eruz);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAddTenderByEruzSaga() {
  yield takeLatest(GET_ADD_TENDER_BY_ERUZ.REQ, getAddTenderByEruz);
}
