import { RE_CONFIRM_ACCOUNT } from 'User/reducers/reConfirmAccount';

export const req = (userId: string) => ({
  type: RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_REQ,
  userId
});
export const got = () => ({
  type: RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_GOT
});
export const err = (error: {}) => ({
  type: RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_ERR,
  error
});
