import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postExternalCredential';

import { POST_EXTERNAL_CREDENTIAL } from '../reducers/postExternalCredential';
import ProductsApi from '../api';

function* postExternalCredential(action: {
  type: POST_EXTERNAL_CREDENTIAL;
  externalCredentialsId: number;
  productId: string;
}): Generator {
  try {
    const res: any = yield call(
      ProductsApi.postExternalCredential,
      action.externalCredentialsId,
      action.productId
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postExternalCredentialSaga(): Generator {
  yield takeLatest(POST_EXTERNAL_CREDENTIAL.REQ, postExternalCredential);
}
