import { Pledges } from 'src/features/BankProducts/types';
import { GET_PLEDGES_BANK } from '../reducers/getPledges';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Pledges[];
}

export const req = (clientInn: string) => ({
  type: GET_PLEDGES_BANK.REQ,
  clientInn
});

export const got = (data: ResponseData) => ({
  type: GET_PLEDGES_BANK.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_PLEDGES_BANK.ERR,
  err
});
