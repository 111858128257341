import { CREATE_CONTACT } from '../reducers/createContact';

export const req = (userData: string) => ({
  type: CREATE_CONTACT.REQ,
  data: userData
});

export const reset = () => ({
  type: CREATE_CONTACT.RESET
});

export const got = data => ({
  type: CREATE_CONTACT.GOT,
  data
});

export const err = (error: {}) => ({
  type: CREATE_CONTACT.ERR,
  error
});
