// TODO: implement these logic in original ApiRequest
function ApiUploadFiles(url: string, data?) {
  return new Promise((resolve, reject) => {
    const params: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: data
    };

    fetch(url, params)
      .then(response => {
        if (response.ok) {
          // Ideally, the server shouldn't return an invalid (empty) response body
          // to an application/json request, especially because it responded with HTTP 200.
          // https://github.com/github/fetch/issues/268#issuecomment-176544728
          response.json().then(data => {
            resolve(data);
          });
        } else {
          reject({ code: response.status, message: response.statusText });
        }
      })
      .catch(error => reject({ error }));
  });
}

export default ApiUploadFiles;
