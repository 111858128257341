import ApiRequest from 'shared/utils/ApiRequest';
import ApiPutFiles from 'shared/utils/ApiPutFiles';
import { RequestDataType as RequestDataTypeSupplier } from 'entities/SCF/Supplier/model/actions/SCFRegisterSupplier';
import { RequestDataType as GetFactoringConnectionsSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/actions/getFactoringConnectionsSupplierRole';
import { RequestDataType as GetFactoringContractsSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/actions/getFactoringContractsSupplierRole';
import { RequestDataType as GetFactoringContractSupplierRoleByGuidRequestDataType } from 'entities/SCF/Supplier/model/actions/getFactoringContractSupplierRoleByGuid';
import { RequestDataType as GetEarlyPaymentApplicationsSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/actions/getEarlyPaymentApplicationsSupplierRole';
import { RequestDataType as GetMonetaryClaimsSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsSupplierRole';
import { RequestDataType as GetInvestorsListSupplierRoleByGuidRequestDataType } from 'entities/SCF/Supplier/model/actions/getInvestorsListSupplierRoleByGuid';
import { RequestDataType as GetInvestorOneDebtorSupplierRoleByGuidRequestDataType } from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import { RequestDataType as GetMonetaryClaimsEarlyPaymentApplicationsByGuidRequestDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import { RequestData as ReqConfirmContractBySupplier } from 'entities/SCF/Supplier/model/actions/postConfirmContractBySupplier';
import { RequestDataType as PostFactoringConnectionSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/reducers/postFactoringConnectionSupplierRole';
import { RequestDataType as PostEarlyDatePickRequestDataType } from 'entities/SCF/Supplier/model/reducers/postEarlyDatePick';
import { RequestDataType as PostEarlyPaymentApplicationSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/reducers/postEarlyPaymentApplicationSupplierRole';
import { RequestDataType as AddInvestorSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/reducers/addInvestorSupplierRole';
import { RequestDataType as UploadSupplierContractDocumentRequestDataType } from 'entities/SCF/Supplier/model/reducers/uploadSupplierContractDocument';
import { RequestDataType as PutPurchaseContractSignatureSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/reducers/putPurchaseContractSignatureSupplierRole';
import { RequestDataType as PostSupplierNotificationSettingsRequestDataType } from 'entities/SCF/Supplier/model/reducers/postSupplierNotificationSettings';
import { RequestDataType as PatchPurchaseContract } from 'entities/SCF/Supplier/model/reducers/patchPurchaseContractSupplier';
import { RequestDataType as PostAgreementSignatureSupplierRoleRequestDataType } from 'entities/SCF/Supplier/model/reducers/postAgreementSignatureSupplierRole';

export default class SCFSupplierApi {
  static registerSupplier(data: RequestDataTypeSupplier) {
    return ApiRequest('/api/account/supplier/register', 'post', data);
  }

  static getStatus() {
    return ApiRequest('/api/supplier/status');
  }

  static postFactoringConnectionSupplierRole(
    data: PostFactoringConnectionSupplierRoleRequestDataType
  ) {
    return ApiRequest(`/api/factoring_connection/supplier_role`, 'post', data);
  }

  static getFactoringConnectionsSupplierRole(
    requestData: GetFactoringConnectionsSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/factoring_connection/supplier_role/my',
      'get',
      requestData
    );
  }

  static putFactoringConnectionSupplierRoleConfirm(id: string) {
    return ApiRequest(
      `/api/factoring_connection/supplier_role/confirm/${id}`,
      'put',
      id
    );
  }

  static putFactoringConnectionSupplierRoleDecline(id: string) {
    return ApiRequest(
      `/api/factoring_connection/supplier_role/decline/${id}`,
      'put',
      id
    );
  }

  static getFactoringContractsSupplierRole(
    requestData: GetFactoringContractsSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/factoring_contract/supplier_role/my',
      'get',
      requestData
    );
  }

  static getFactoringContractSupplierRoleByGuid(
    requestData: GetFactoringContractSupplierRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_contract/supplier_role/id/${requestData.id}`,
      'get'
    );
  }

  static getEarlyPaymentApplicationsSupplierRole(
    requestData: GetEarlyPaymentApplicationsSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/early_payment_applications/supplier_role/my',
      'get',
      requestData
    );
  }

  static getMonetaryClaimsSupplierRole(
    requestData: GetMonetaryClaimsSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/monetary_claim/supplier_role/my',
      'get',
      requestData
    );
  }

  static postEarlyDatePick(data: PostEarlyDatePickRequestDataType) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${
        data.guid
      }/early_date_pick`,
      'post',
      data.body
    );
  }

  static postEarlyPaymentApplicationSupplierRole(
    data: PostEarlyPaymentApplicationSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/create`,
      'post',
      data
    );
  }

  static getInvestorsListSupplierRoleByGuid(
    data: GetInvestorsListSupplierRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${
        data.guid
      }/investors_list`,
      'get',
      data.guid
    );
  }

  static getInvestorOneDebtorSupplierRoleByGuid(
    requestData: GetInvestorOneDebtorSupplierRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${
        requestData.guid
      }/get_one`,
      'get',
      requestData.guid
    );
  }

  static addInvestorSupplierRole(data: AddInvestorSupplierRoleRequestDataType) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${
        data.guid
      }/add_investor/${data.inn}/${data.payerType}`,
      'post',
      data
    );
  }

  static getMonetaryClaimsEarlyPaymentApplicationsByGuid(
    data: GetMonetaryClaimsEarlyPaymentApplicationsByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/monetary_claim/supplier_role/early_payment_application/${
        data.guid
      }`,
      'get',
      data.guid
    );
  }

  static createAgreementSupplierRole(guid: string) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${guid}/create_agreement`,
      'post'
    );
  }

  static downloadAgreementFileSupplierRole(guid: string): string {
    return `/api/early_payment_applications/supplier_role/${guid}/get_agreement_file`;
  }

  static downloadArchiveFileSupplier(guid: string) {
    return `/api/early_payment_applications/supplier_role/${guid}/get_archive_file`;
  }

  static postAgreementSignatureSupplierRole(
    data: PostAgreementSignatureSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${
        data.guid
      }/accept_agreement`,
      'post',
      data
    );
  }

  static postOfferDiscount(guid: string, data: any) {
    return ApiRequest(
      `/api/early_payment_applications/supplier_role/${guid}/offer_discount`,
      'post',
      data
    );
  }

  static uploadSupplierContractDocument(
    data: UploadSupplierContractDocumentRequestDataType
  ) {
    return ApiPutFiles(
      `/api/purchase_contract/supplier_role/${data.guid}/upload`,
      data.file
    );
  }

  static downloadSupplierPurchaseContractSignatureFile(guid: string): string {
    return `/api/purchase_contract/supplier_role/${guid}/get_signature_file`;
  }

  static putPurchaseContractSignatureSupplierRole(
    data: PutPurchaseContractSignatureSupplierRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/purchase_contract/supplier_role/${data.guid}/sign`,
      'put',
      data
    );
  }

  static getStartRateSupplierRole() {
    return ApiRequest('/api/users/supplier_role/scf_start');
  }

  static getSupplierNotificationSettings() {
    return ApiRequest(
      '/api/supplier_user_notification_settings/supplier_role/current'
    );
  }

  static postSupplierNotificationSettings(
    data: PostSupplierNotificationSettingsRequestDataType
  ) {
    return ApiRequest(
      '/api/supplier_user_notification_settings/supplier_role/new',
      'post',
      data
    );
  }

  static getSignatureFile(guid: string) {
    return ApiRequest(
      `/api/purchase_contract/supplier_role/${guid}/get_signature_file`
    );
  }

  static getSupplierCounterSidebar() {
    return ApiRequest(`/api/users/supplier_role/counter`);
  }

  static getByMonetaryClaimSupplier(guid: string) {
    return ApiRequest(
      `/api/supply_documents_of_monetary_claims/supplier_role/claim/${guid}`
    );
  }

  static patchPurchaseContractSupplier(
    guid: string,
    data: PatchPurchaseContract
  ) {
    return ApiRequest(
      `/api/purchase_contract/supplier_role/${guid}`,
      'patch',
      data
    );
  }

  static getContractBySupplier(guid: string) {
    return ApiRequest(
      `/api/bank_account/supplier_role/get_by_contract/${guid}`
    );
  }

  static postAddToContractBySupplier(contractAccount: string, guid: string) {
    return ApiRequest(
      `/api/bank_account/supplier_role/${contractAccount}/add_to_contract/${guid}?type=2`,
      'post'
    );
  }

  static postConfirmContractBySupplier(
    accountGuid: string,
    contractGuid: string,
    data: ReqConfirmContractBySupplier
  ) {
    return ApiRequest(
      `/api/bank_account/supplier_role/${accountGuid}/confirm_for_contract/${contractGuid}`,
      'post',
      data
    );
  }

  static putAddSignee(guid: string, signeeGuid: string) {
    return ApiRequest(
      `/api/early_payment_applications/supplier/${guid}/add_signee/${signeeGuid}`,
      'put'
    );
  }

  static putAddPoaFile(data: FormData) {
    return ApiPutFiles(`/api/poas/scf/add_poa_file`, data);
  }
}
