import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/postApplicationPrescoring';

import { POST_APPLICATION_PRESCORING } from '../reducers/postApplicationPrescoring';
import ApplicationApi from '../api';

function* postApplicationPrescoring(action: {
  type: POST_APPLICATION_PRESCORING;
  data: RequestData;
}): Generator {
  try {
    yield call(ApplicationApi.postApplicationPrescoring, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postApplicationPrescoringSaga(): Generator {
  yield takeLatest(POST_APPLICATION_PRESCORING.REQ, postApplicationPrescoring);
}
