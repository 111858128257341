import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Beneficiaries } from '../Sections/Beneficiaries/Beneficiaries';
import { Managers } from '../Sections/Managers/Managers';
import { req as getPerson } from 'src/features/Persons/actions/getPerson';
import { PERSONS_TABS, PersonType } from '../../types';
import {
  HeadContainer,
  NotFound,
  PersonContainer,
  SectionContainer
} from './styled';
import { Loader, Menu } from 'src/features/Layouts/components';
import { PassportData } from '../PassportData/PassportData';

interface StateToProps {
  person: PersonType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusEditPerson: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPerson: (inn: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Persons: React.FC<Props> = ({
  getPerson,
  person,
  status,
  statusEditPerson,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(
    () => {
      if (match.params.inn || statusEditPerson === REQUEST_STATUSES.GOT) {
        getPerson(match.params.inn);
      }
    },
    [match.params.inn, statusEditPerson]
  );

  React.useEffect(() => {
    initFetch();
  }, []);

  const initFetch = () => {
    if (match.params.tab) {
      setActiveTab(match.params.tab.toUpperCase());
    } else if (match.params.tab === 'passport') {
      setActiveTab(match.params.tab.toUpperCase());
    }
  };

  const handleActiveTab = (tabName: string) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let pathname: string;

    if (tabName === 'DEFAULT') {
      pathname = baseUrl;
    } else {
      pathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace({ pathname });
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <PersonContainer>
          <HeadContainer>
            <ApplicationId>ИНН {match.params.inn}</ApplicationId>
          </HeadContainer>
          <ApplicationTitle>
            {person.lastName} {person.firstName} {person.middleName}
          </ApplicationTitle>

          <Menu
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            tabs={PERSONS_TABS}
          />

          {activeTab === 'DEFAULT' && (
            <SectionContainer>
              <Beneficiaries />
              <Managers />
            </SectionContainer>
          )}

          {activeTab === 'PASSPORT' && (
            <PassportData
              inn={match.params.inn}
              person={person}
              status={status}
            />
          )}
        </PersonContainer>
      )}

      {status === REQUEST_STATUSES.ERROR && (
        <NotFound>
          <h2>Данные физического лица не найдены</h2>
        </NotFound>
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Persons }: CRM) => ({
  person: Persons.getPerson.data,
  status: Persons.getPerson.status,
  error: Persons.getPerson.error,
  statusEditPerson: Persons.patchEditPerson.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getPerson }, dispatch);

const PersonsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Persons)
);

export { PersonsConnect as Persons };
