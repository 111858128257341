import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getBuyersToSupplierLinkList,
  ResponseData,
  RequestData
} from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';
import {
  req as patchSetDiscountMargin,
  RequestData as ReqSetDiscountMargin
} from 'entities/Cabinet/InternalAgent/model/actions/patchSetDiscountMargin';
import { ScrollTopComponent } from 'src/features/Common';
import {
  CheckboxStyle,
  DiscountMargin,
  INNContainer,
  INNField,
  LinkNotSet,
  TDStyled,
  TooltipBoxStyle
} from './styled';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  buyersToSupplierLinkList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusSetDiscountMargin: REQUEST_STATUSES;
}

interface DispatchToProps {
  getBuyersToSupplierLinkList: (data: RequestData) => void;
  patchSetDiscountMargin: (id: number, data: ReqSetDiscountMargin) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const SupplierList: React.FC<Props> = ({
  getBuyersToSupplierLinkList,
  buyersToSupplierLinkList,
  status,
  error,
  patchSetDiscountMargin,
  statusSetDiscountMargin,
  history,
  match
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [discountMargin, setDiscountMargin] = React.useState('');
  const [selectDiscount, setSelectDiscount] = React.useState({});

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const supplierInn = searchParams.get('supplierInn') || '';

      setSupplierInn(supplierInn);

      getBuyersToSupplierLinkList({
        page,
        pageSize: 20,
        DebtorInnToFilterBy: match.params.inn,
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn })
      });
    },
    [history.location.search, statusSetDiscountMargin]
  );

  const navigateToSupplierLink = (debtor_inn: string, supplier_inn: string) => {
    if (debtor_inn && supplier_inn) {
      history.push(
        `/crm/buyers_to_suppliers_links/${debtor_inn}/${supplier_inn}`
      );
    }
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <ScrollTopComponent>
      <INNContainer>
        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Дебитор</TableThStyled>
                <TableThStyled width="7%">Подтверждено дебитором</TableThStyled>
                <TableThStyled width="15%">Поставщик</TableThStyled>
                <TableThStyled width="10%">
                  Подтверждено поставщиком
                </TableThStyled>
                <TableThStyled width="10%">
                  Дополнительная маржа дисконта
                </TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {buyersToSupplierLinkList.items.map((buyer, key) => (
                <TableRowStyled
                  key={buyer.id}
                  onClick={() =>
                    navigateToSupplierLink(buyer.debtorInn, buyer.supplierInn)
                  }
                >
                  <TDStyled>
                    <div>{buyer.debtorName}</div>
                    <div>{buyer.debtorInn}</div>
                  </TDStyled>
                  <TDStyled>
                    {buyer.confirmedByDebtor ? (
                      <TooltipBoxStyle textVerify={'Подтверждено дебитором'}>
                        <CheckboxStyle verify={buyer.confirmedByDebtor}>
                          <FontAwesomeIcon icon={faCheck} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    ) : (
                      <TooltipBoxStyle textVerify={'Не подтверждено дебитором'}>
                        <CheckboxStyle verify={buyer.confirmedByDebtor}>
                          <FontAwesomeIcon icon={faMinus} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    )}
                  </TDStyled>
                  <td>
                    <div>{buyer.supplierName}</div>
                    <div>{buyer.supplierInn}</div>
                  </td>
                  <td>
                    {buyer.confirmedBySupplier ? (
                      <TooltipBoxStyle textVerify={'Подтверждено поставщиком'}>
                        <CheckboxStyle verify={buyer.confirmedBySupplier}>
                          <FontAwesomeIcon icon={faCheck} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    ) : (
                      <TooltipBoxStyle
                        textVerify={'Не подтверждено поставщиком'}
                      >
                        <CheckboxStyle verify={buyer.confirmedBySupplier}>
                          <FontAwesomeIcon icon={faMinus} />
                        </CheckboxStyle>
                      </TooltipBoxStyle>
                    )}
                  </td>
                  <td>
                    {!!buyer.discountMargin ? (
                      `+ ${buyer.discountMargin} % годовых`
                    ) : selectDiscount[key] && selectDiscount[key].selected ? (
                      <DiscountMargin
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => e.stopPropagation()}
                      >
                        <Input
                          name="discountMargin"
                          value={discountMargin}
                          onChange={e => {
                            const newValue = e.currentTarget.value;
                            if (/^\d*[.,]?\d{0,4}$/.test(newValue)) {
                              setDiscountMargin(newValue.replace(',', '.'));
                            }
                          }}
                        />
                        <Button
                          label="Сохранить"
                          disabled={!discountMargin}
                          onClick={() =>
                            patchSetDiscountMargin(buyer.id, {
                              margin: +discountMargin
                            })
                          }
                          w="fit-content"
                          h="40px"
                        />
                        <div onClick={() => setSelectDiscount({})}>&times;</div>
                      </DiscountMargin>
                    ) : (
                      <LinkNotSet
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          setDiscountMargin('');
                          setSelectDiscount({ [key]: { selected: true } });
                        }}
                      >
                        не установлена
                      </LinkNotSet>
                    )}
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={buyersToSupplierLinkList} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({
  BuyersToSupplierLink,
  InternalAgentRole
}: STORE & CRM) => ({
  buyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.data,
  status: BuyersToSupplierLink.getBuyersToSupplierLinkList.status,
  error: BuyersToSupplierLink.getBuyersToSupplierLinkList.error,
  statusSetDiscountMargin: InternalAgentRole.patchSetDiscountMargin.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getBuyersToSupplierLinkList, patchSetDiscountMargin },
    dispatch
  );

const SupplierListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SupplierList)
);

export { SupplierListConnect as SupplierList };
