import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { CompanyLimits } from '../types';

export interface GetLimitsStoreState extends Reducer {
  data: CompanyLimits[];
}

export enum GET_LIMITS {
  REQ = 'GET_LIMITS_REQ',
  GOT = 'GET_LIMITS_GOT',
  ERR = 'GET_LIMITS_ERR',
  RESET = 'GET_LIMITS_RESET'
}

export const initialState: GetLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getLimits = (
  state: GetLimitsStoreState = initialState,
  action: {
    type: string;
    data: CompanyLimits[];
    error: {};
  }
): GetLimitsStoreState => {
  switch (action.type) {
    case GET_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_LIMITS.RESET:
      return {
        ...initialState
      };
    case GET_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getLimits;
