import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import {
  CreateContractPageStyled,
  InnFilter,
  InnContainer,
  InnArea,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  InnPopup,
  InfoField
} from './styles';
import {
  SupplyContract,
  setSupplyContract
} from 'src/features/SCF/actions/setSupplyContract';
import {
  req as sendSupplyContract,
  RequestData as SendSupplyContract
} from 'src/features/SCF/actions/sendSupplyContract';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { REQUEST_STATUSES, STORE, ResponseError } from 'src/globaltypes';
import { SetSupplyContractStoreState } from 'src/features/SCF/reducers/setSupplyContract';
import './styles/style.css';
import { Button } from 'shared/ui/Button';

interface StateToProps extends Partial<SupplyContract> {
  isSendingData: boolean;
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  sendSupplyContract: (data: SendSupplyContract) => void;
  setSupplyContract: (data: SetSupplyContractStoreState) => void;
  getCompanyByInn: (data: RequestDataType) => void;
}

interface State {
  isPerpetualState: boolean;
  debtorInn: string;
  companyByInnPre: ResponseDataType;
  isPaymentDefermentFixed: boolean;
  paymentDeferment: number;
}

type Props = StateToProps & DispatchToProps;

class ContractsCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        isPerpetualState: false,
        isPaymentDefermentFixed: false,
        paymentDeferment: 0,
        debtorInn: '',
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      };
      this.handlePerpetualChange = this.handlePerpetualChange.bind(this);
      this.handleDebtorInnChange = this.handleDebtorInnChange.bind(this);
    }
  }

  handlePerpetualChange() {
    this.setState({ isPerpetualState: !this.state.isPerpetualState });
    this.props.isPerpetual == this.state.isPerpetualState;
  }

  handleDebtorInnChange(event) {
    this.setState({ debtorInn: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  debtorFetch = async () => {
    this.setState({
      companyByInnPre: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    });
    this.props.getCompanyByInn({ inn: this.state.debtorInn });
    await this.delay(1100);
    {
      this.props.error.code === 404 &&
      this.props.status === REQUEST_STATUSES.ERROR
        ? this.setState({
            companyByInnPre: {
              inn: '',
              TaxSystem: null,
              companyShortName: '',
              companyAddress: '',
              companyFullName: '',
              ogrn: '',
              regDate: null,
              kpp: '',
              pfr: '',
              fss: '',
              startCapital: null,
              okvedCode: '',
              okvedName: '',
              okvedPic: '',
              employees: null,
              employeesYear: null,
              taxSystemName: '',
              taxSystemCode: null,
              taxes: { taxYear: null, taxInfo: [] }
            }
          })
        : this.setState({
            companyByInnPre: {
              inn: this.props.companyByInn.inn,
              TaxSystem: this.props.companyByInn.TaxSystem,
              companyShortName: this.props.companyByInn.companyShortName,
              companyAddress: this.props.companyByInn.companyAddress,
              companyFullName: this.props.companyByInn.companyFullName,
              ogrn: this.props.companyByInn.ogrn,
              regDate: this.props.companyByInn.regDate,
              kpp: this.props.companyByInn.kpp,
              pfr: this.props.companyByInn.pfr,
              fss: this.props.companyByInn.fss,
              startCapital: this.props.companyByInn.startCapital,
              okvedCode: this.props.companyByInn.okvedCode,
              okvedName: this.props.companyByInn.okvedName,
              okvedPic: this.props.companyByInn.okvedPic,
              employees: this.props.companyByInn.employees,
              employeesYear: this.props.companyByInn.employeesYear,
              taxSystemName: this.props.companyByInn.taxSystemName,
              taxSystemCode: this.props.companyByInn.taxSystemCode,
              taxes: this.props.companyByInn.taxes
            }
          });
      document.getElementById('supplierPopup').style.display = 'flex';
    }
  };

  onSubmit = async () => {
    const {
      debtorName,
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate
    } = this.props;
    this.props.sendSupplyContract({
      debtorInn: this.state.debtorInn,
      debtorName,
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      isPerpetual: this.state.isPerpetualState,
      paymentDefermentFixed: this.state.isPaymentDefermentFixed,
      paymentDeferment: this.state.paymentDeferment
    });
    await this.delay(700);
    history.replace('/supplier/supply_contracts?page=1');
  };

  componentDidUpdate(prevProps, prevState: State) {
    if (
      (this.state.debtorInn !== prevState.debtorInn &&
        this.state.debtorInn.length === 10) ||
      this.state.debtorInn === null
    ) {
      this.debtorFetch();
    } else if (
      this.state.debtorInn !== prevState.debtorInn &&
      this.state.debtorInn.length < 10
    ) {
      document.getElementById('supplierInfo').style.display = 'none';
      this.setState({
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      });
      document.getElementById('supplierPopup').style.display = 'none';
    }
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setSupplyContract({
      [name]: value
    });
  };

  onPerpetualFalseClick() {
    this.props.isPerpetual === !this.props.isPerpetual;
  }
  onPerpetualTrueClick() {
    this.props.isPerpetual == true;
  }

  onSupplierPopupClick = e => {
    document.getElementById('supplierPopup').style.display = 'none';
    document.getElementById('supplierInfo').style.display = 'flex';
  };

  handlePaymentDefermentChange = () => {
    this.setState(prevState => ({
      ...prevState,
      isPaymentDefermentFixed: !prevState.isPaymentDefermentFixed
    }));
  };

  render() {
    const {
      debtorInn,
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      status,
      companyByInn,
      error
    } = this.props;
    return (
      <CreateContractPageStyled>
        <h2>+ Новый договор</h2>
        <InnArea>
          <InnFilter>
            <p>Покупатель:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnChange}
                className="input-inn"
              />
            </InnContainer>
            {this.state.companyByInnPre.inn === '' ? (
              <InnPopup
                id="supplierPopup"
                style={{ display: 'none', marginTop: '10px' }}
              >
                <h2>
                  Компания с таким ИНН не найдена в системе ЕГРЮЛ, но вы можете
                  создать контракт с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              <InnPopup
                className="inn-popup"
                id="supplierPopup"
                onClick={e => this.onSupplierPopupClick(e)}
                style={{ display: 'none' }}
              >
                <h2>{this.state.companyByInnPre.companyFullName}</h2>
                {this.state.companyByInnPre.companyAddress.length > 3 ? (
                  <p>{this.state.companyByInnPre.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {this.state.companyByInnPre.inn}</p>
                <p>КПП {this.state.companyByInnPre.kpp}</p>
              </InnPopup>
            )}

            <InfoField id="supplierInfo">
              <h2>{companyByInn.companyFullName}</h2>
              {companyByInn.companyAddress.length > 3 ? (
                <p>{companyByInn.companyAddress}</p>
              ) : (
                ''
              )}
              <p>ИНН {companyByInn.inn}</p>
              <p>КПП {companyByInn.kpp}</p>
            </InfoField>
          </InnFilter>
        </InnArea>

        <ContractsInfoArea>
          <h1>Реквизиты договора:</h1>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Наименование договора
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-input-name"
              type="text"
              value={contractName}
              name="contractName"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Номер договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="text"
              value={contractNumber}
              name="contractNumber"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Дата договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Срок действия договора
            </ContractsInfoItemTitle>
            <p>c</p>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '20px' }}>по</p>
            <input
              className="contract-input"
              type="date"
              value={contractEndDate}
              name="contractEndDate"
              onChange={this.onChange}
            />
            {this.state.isPerpetualState == true ? (
              <SelectContainerActive onClick={this.handlePerpetualChange}>
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive onClick={this.handlePerpetualChange}>
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>бессрочный</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>

        <ContractsInfoArea>
          <h1>Условия оплаты:</h1>
          <p>Отсрочка платежа</p>
          <ContractsInfoItemContainer>
            {this.state.isPaymentDefermentFixed === true ? (
              <SelectContainerActive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>фиксированная</p>
            {this.state.isPaymentDefermentFixed && (
              <>
                <input
                  className="short-input"
                  type="number"
                  value={this.state.paymentDeferment}
                  onChange={e => {
                    this.setState({
                      paymentDeferment: +e.currentTarget.value
                    });
                  }}
                />
                <p>дней</p>
              </>
            )}
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            {this.state.isPaymentDefermentFixed === false ? (
              <SelectContainerActive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                onClick={this.handlePaymentDefermentChange}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>плавающая</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>

        {this.state.debtorInn.length < 10 ||
        contractName.length < 1 ||
        contractNumber.length < 1 ||
        contractStartDate.length < 1 ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : this.state.isPerpetualState == false && contractEndDate ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : (
          <Button
            type="submit"
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        )}
      </CreateContractPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  isSendingData: SCF.sendSupplyContract.status === REQUEST_STATUSES.REQUEST,
  ...SCF.setSupplyContract
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendSupplyContract,
      setSupplyContract,
      getCompanyByInn
    },
    dispatch
  );

const ContractsCreateConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContractsCreate);

export { ContractsCreateConnect as ContractsCreate };
