import { combineReducers } from 'redux';
import { Reducer } from 'globaltypes';

import addBankManager from './AddBankManagers/reducers/addBankManager';

import getExternalAgentsList, {
  GetExternalAgentsListStoreState
} from './ManageExternalAgents/reducers/getExternalAgentsList';
import confirmExternalAgent, {
  ConfirmExternalAgentStoreState
} from './ManageExternalAgents/reducers/confirmExternalAgent';
import toggleLeadogeneratorPermission, { ToggleLeadogeneratorPermissionStoreState } from './ManageExternalAgents/reducers/toggleLeadogeneratorPermission';

export interface PermissionsReducers {
  addBankManager: Reducer;
  getExternalAgentsList: GetExternalAgentsListStoreState;
  confirmExternalAgent: ConfirmExternalAgentStoreState;
  toggleLeadogeneratorPermission: ToggleLeadogeneratorPermissionStoreState;
}

// TODO extract each permission to features
export const Permissions = combineReducers({
  addBankManager,
  getExternalAgentsList,
  confirmExternalAgent,
  toggleLeadogeneratorPermission
});
