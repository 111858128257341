import styled, { css } from 'styled-components';

import { APPLICATION_LIST_VIEWS } from 'Application/reducers/setApplicationListView';
import { navyBlue, gray } from 'shared/styled';

export const ToggleBtnStyled = styled.button.attrs({
  type: 'button',
  ['aria-label']: 'toggle-view-button'
})`
  width: 80px;
  height: 32px;

  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  cursor: pointer;
  outline: none;
  border: none;
  background-color: #e0e6f1;
  border-radius: 6px;

  &::before {
    content: '';

    width: 50%;
    height: 100%;

    display: block;

    top: 0;
    left: 0;
    z-index: 0;
    position: absolute;

    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

    transition: transform 0.2s;
    transform: translateX(
      ${({ viewType }) => {
        switch (viewType) {
          case APPLICATION_LIST_VIEWS.TILE:
            return '0';
          case APPLICATION_LIST_VIEWS.TABLE:
            return '100%';
          default:
            return '0';
        }
      }}
    );
  }
`;

export const ToggleBtnPart = styled.i`
  width: 50%;
  height: 32px;

  z-index: 1;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;

  transition: background-image 0.2s;
`;

export const TableViewBtnPart = styled(ToggleBtnPart)`
  background-image:url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <style>
        path {
          transition: stroke .2s;
        }

        ellipse {
          transition: fill .2s;
        }
      </style>
      
      <g transform="translate(3 4)" stroke-width="1.217" stroke-linecap="round" cx="1.217">
        ${({ viewType }) => {
          const color =
            viewType === APPLICATION_LIST_VIEWS.TABLE ? navyBlue : gray;
          return css`
              <path stroke="${color}" d="M5.478 6H14"/>
              <ellipse cy="6" fill="${color}" rx="1.217" ry="1.2"/>
              <path stroke="${color}" d="M5.478 1.2H14"/>
              <ellipse cy="1.2" fill="${color}" rx="1.217" ry="1.2"/>
              <path stroke="${color}" d="M5.478 10.8H14"/>
              <ellipse cy="10.8" fill="${color}" rx="1.217" ry="1.2"/>
            `;
        }}
      </g>
    </svg>')
`;

export const TileViewBtnPart = styled(ToggleBtnPart)`
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <style>
        path {
          transition: fill .2s;
        }
      </style>
    
      ${({ viewType }) => {
        const color =
          viewType === APPLICATION_LIST_VIEWS.TILE ? navyBlue : gray;
        return css`<path fill="${color}" d="M9.364 8.09c0 .704-.57 1.274-1.273 1.274H4.273C3.57 9.364 3 8.794 3 8.09V4.273C3 3.57 3.57 3 4.273 3H8.09c.703 0 1.273.57 1.273 1.273V8.09zm2.545 1.274c-.703 0-1.273-.57-1.273-1.273V4.273c0-.703.57-1.273 1.273-1.273h3.818C16.43 3 17 3.57 17 4.273V8.09c0 .703-.57 1.273-1.273 1.273H11.91zm-3.818 1.272c.703 0 1.273.57 1.273 1.273v3.818c0 .703-.57 1.273-1.273 1.273H4.273C3.57 17 3 16.43 3 15.727V11.91c0-.703.57-1.273 1.273-1.273H8.09zm2.545 1.273c0-.703.57-1.273 1.273-1.273h3.818c.703 0 1.273.57 1.273 1.273v3.818C17 16.43 16.43 17 15.727 17H11.91c-.703 0-1.273-.57-1.273-1.273V11.91z"/>`;
      }}
    </svg>
  ')
`;
