import { combineReducers } from 'redux';
import getSupplyContractsList, {
  GetSupplyContractsListStoreState
} from './getSupplyContractsList';
import getSupplyContractInternal, {
  GetSupplyContractInternalStoreState
} from './getSupplyContractInternal';
import getContractByInternal, {
  GetContractByInternalStoreState
} from './getContractByInternal';

export interface SupplyContractsReducers {
  getSupplyContractsList: GetSupplyContractsListStoreState;
  getSupplyContractInternal: GetSupplyContractInternalStoreState;
  getContractByInternal: GetContractByInternalStoreState;
}

export const SupplyContracts = combineReducers({
  getSupplyContractsList,
  getSupplyContractInternal,
  getContractByInternal
});
