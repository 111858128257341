import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { STORE, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { InnPlaceholders } from 'shared/constants';
import { COMPANY_LIST_TYPE } from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getCompaniesList,
  ResponseData as CompaniesListResponseData,
  RequestData as CompaniesListRequestData
} from '../../actions/getCompaniesList';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { TDNegativeContainer } from 'src/features/Companies/components/CompaniesList/styled';
import { MatchingFilters } from 'src/features/Companies/components/ManagerList/styled';
import { Input } from 'shared/ui/Input';
import {
  Image,
  InnInputContainer,
  TDInnStyled,
  TableRowCustomStyled
} from 'src/features/Companies/components/AnalystList/styled';
import { CompanyRead } from '../../actions/setCompanyFormData';

interface StateToProps {
  companies: CompaniesListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCompaniesList: (
    listType: COMPANY_LIST_TYPE,
    data: CompaniesListRequestData
  ) => void;
}

interface OwnProps {
  listType: COMPANY_LIST_TYPE;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const AnalystList: React.FC<Props> = ({
  getCompaniesList,
  companies,
  status,
  error,
  history,
  listType
}) => {
  const [companyInn, setCompanyInn] = React.useState('');
  const [icons, setIcons] = React.useState<{ [key: string]: string }>({});

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;

      const inn = searchParams.get('companyInn') || '';

      setCompanyInn(inn);

      getCompaniesList(listType, {
        page,
        pageSize: 20,
        AnalystID: 'my',
        ...(inn && { inn })
      });
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        companies.items.forEach(company => {
          loadIcon(company);
        });
      }
    },
    [status]
  );

  const onFilterByInn = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToCompany = (inn: string) => {
    window.open(`/crm/companies/${inn}`, '_blank');
  };

  const loadIcon = async (company: CompanyRead) => {
    if (company.okvedCode) {
      const code = parseInt(company.okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setIcons(prevIcons => ({
          ...prevIcons,
          [company.id]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setIcons(prevIcons => ({
          ...prevIcons,
          [company.id]: undefined
        }));
      }
    }
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Организации, где я аналитик</ApplicationTitle>

      <MatchingFilters>
        Соответствуют фильтрам: <span>{companies.totalItems}</span> компаний
      </MatchingFilters>

      <InnInputContainer>
        <Input
          value={companyInn}
          label="ИНН Компании"
          name="companyInn"
          placeholder={InnPlaceholders.entity}
          onChange={onFilterByInn}
        />
      </InnInputContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="6%">ИНН</TableThStyled>
              <TableThStyled width="12%">Наименование</TableThStyled>
              <TableThStyled width="9%">Холдинг</TableThStyled>
              <TableThStyled width="9%">Выручка</TableThStyled>
              <TableThStyled width="9%">Прибыль</TableThStyled>
              <TableThStyled width="9%">Капитал и резервы</TableThStyled>
              <TableThStyled width="5%">Фин. год</TableThStyled>
              <TableThStyled width="8%">Контакты</TableThStyled>
              <TableThStyled width="8%">Кредитные лимиты</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {companies.items.map(company => (
              <TableRowCustomStyled
                key={company.inn}
                onClick={() => navigateToCompany(company.inn)}
              >
                <TDInnStyled>
                  <div>{company.inn}</div>
                  <div>
                    {company.okvedCode} {company.okvedName}
                  </div>
                </TDInnStyled>
                <td>{company.companyShortName}</td>
                <td>{company.holdingName}</td>
                <TDNegativeContainer negative={company.revenue < 0}>
                  {company.revenue === null
                    ? 'Нет данных'
                    : company.revenue.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <TDNegativeContainer negative={company.netIncome < 0}>
                  {company.netIncome === null
                    ? 'Нет данных'
                    : company.netIncome.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <TDNegativeContainer negative={company.capital < 0}>
                  {company.capital === null
                    ? 'Нет данных'
                    : company.capital.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <td>{company.financialsYear}</td>
                <td>
                  {company.hasContacts ? 'Есть контакты' : 'Нет контактов'}
                </td>
                <td>
                  {company.hasOpenLimitsInfo ? 'Есть лимиты' : 'Нет данных'}
                  <Image src={icons[company.id]} alt={company.okvedName} />
                </td>
              </TableRowCustomStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={companies} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Companies }: STORE) => ({
  companies: Companies.getCompaniesList.data,
  status: Companies.getCompaniesList.status,
  error: Companies.getCompaniesList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompaniesList }, dispatch);

const AnalystListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AnalystList)
);

export { AnalystListConnect as AnalystList };
