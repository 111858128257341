import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { history } from 'src/shared/utils/History';
import { STORE, USER_PERMISSIONS } from 'globaltypes';

import { APPLICATION_STATUSES } from 'Application/types';

import { req as changeApplicationStatus } from 'Application/actions/changeApplicationStatus';
import { req as deleteApplication } from 'Application/actions/deleteApplication';
import { RequestDataType as ChangeApplicationStatusStoreState } from 'Application/reducers/changeApplicationStatus';
import { RequestDataType as DeleteApplicationRequestDataType } from 'Application/reducers/deleteApplication';

import { ClickOutsideWrapper } from 'Common';

import {
  clientSubmenuConstructor,
  agentSubmenuConstructor,
  agentAsClientSubmenuConstructor
} from './submenuConstructor';

import {
  SubmenuContainer,
  OpenSubmenuBtn,
  SubmenuBtn,
  Submenu
} from './styles';

export const enum SUBMENU_CLICK_ACTIONS {
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
  REJECT = 'REJECT'
}

interface State {
  isActive: boolean;
}

interface OwnProps {
  id: number;
  status: APPLICATION_STATUSES;
  isAuthor: boolean;
}

interface StateToProps {
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  changeApplicationStatus: (data: ChangeApplicationStatusStoreState) => void;
  deleteApplication: (data: DeleteApplicationRequestDataType) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ApplicationSubmenu extends React.Component<Props, State> {
  state = {
    isActive: false
  };

  toggleSubmenu = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();

    this.setState({
      isActive: !this.state.isActive
    });
  };

  defineSubmenuBtns = () => {
    const { permissions, status, isAuthor } = this.props;

    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT))
      return isAuthor
        ? agentAsClientSubmenuConstructor(status)
        : agentSubmenuConstructor(status);

    if (permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS))
      return clientSubmenuConstructor(status);

    return [];
  };

  defineSubmenuBtnClicks = (
    e: React.FormEvent<HTMLInputElement>,
    action: SUBMENU_CLICK_ACTIONS
  ) => {
    e.stopPropagation();

    const { id, deleteApplication, changeApplicationStatus } = this.props;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    const actions = {
      [SUBMENU_CLICK_ACTIONS.EDIT]: () =>
        history.push(`/cabinet/${role}/application-form/edit/${id}`),
      [SUBMENU_CLICK_ACTIONS.REMOVE]: () => deleteApplication({ id }),
      [SUBMENU_CLICK_ACTIONS.REJECT]: () =>
        changeApplicationStatus({
          id,
          status: APPLICATION_STATUSES.USER_REJECT
        })
    };

    return actions[action]();
  };

  myClickOutsideHandler = () => {
    this.setState({
      isActive: false
    });
  };

  render() {
    const { isActive } = this.state;
    const submenuBtns = this.defineSubmenuBtns();

    return (
      <ClickOutsideWrapper onClickOutside={this.myClickOutsideHandler}>
        <SubmenuContainer>
          <OpenSubmenuBtn
            onClick={this.toggleSubmenu}
            menuOpened={isActive}
            disabled={submenuBtns.length === 0}
          />

          {isActive && (
            <Submenu>
              {submenuBtns.map(({ name, action }, key) => (
                <SubmenuBtn
                  key={key}
                  onClick={e => this.defineSubmenuBtnClicks(e, action)}
                  name={action as SUBMENU_CLICK_ACTIONS}
                >
                  {name}
                </SubmenuBtn>
              ))}
            </Submenu>
          )}
        </SubmenuContainer>
      </ClickOutsideWrapper>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeApplicationStatus,
      deleteApplication
    },
    dispatch
  );

const ApplicationSubmenuConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSubmenu);

export { ApplicationSubmenuConnect as ApplicationSubmenu };
