import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getListInternalAgents';

import { GET_LIST_INTERNAL_AGENTS } from '../reducers/getListInternalAgents';
import CompaniesApi from '../api';

function* getListInternalAgents(action: {
  type: GET_LIST_INTERNAL_AGENTS;
}): Generator {
  try {
    const res: any = yield call(CompaniesApi.getListInternalAgents);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getListInternalAgentsSaga(): Generator {
  yield takeLatest(GET_LIST_INTERNAL_AGENTS.REQ, getListInternalAgents);
}
