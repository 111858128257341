import * as React from 'react';

import {
  NavigationLinkStyled,
  NavigationLinkFontAwesome,
  NavigationLinkPlanet,
  NavigationLinkDocs,
  NavigationLinkDesk,
  NavigationLinkClock,
  NavigationLinkBag,
  NavigationLinkPen,
  NavigationLinkTrash
} from './styles';

export type Template =
  | 'default'
  | 'planet'
  | 'docs'
  | 'desk'
  | 'clock'
  | 'bag'
  | 'pen'
  | 'fontawesome'
  | 'trash';

interface NavigationLinkProps {
  template?: Template;
  label?: string | JSX.Element;
  labelInfo?: number | JSX.Element;
  to: any;
  fontAwesomeIcon?: JSX.Element;
}

const defaultProps = {
  label: '',
  template: 'default' as Template
};

const NavigationLink: React.FC<NavigationLinkProps> = ({
  template,
  to,
  fontAwesomeIcon,
  label,
  labelInfo
}: NavigationLinkProps) => {
  return (
    <React.Fragment>
      {template === 'default' && (
        <NavigationLinkStyled to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkStyled>
      )}
      {template === 'planet' && (
        <NavigationLinkPlanet to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkPlanet>
      )}
      {template === 'desk' && (
        <NavigationLinkDesk to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkDesk>
      )}
      {template === 'docs' && (
        <NavigationLinkDocs to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkDocs>
      )}
      {template === 'clock' && (
        <NavigationLinkClock to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkClock>
      )}
      {template === 'bag' && (
        <NavigationLinkBag to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkBag>
      )}
      {template === 'pen' && (
        <NavigationLinkPen to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkPen>
      )}
      {template === 'trash' && (
        <NavigationLinkTrash to={to}>
          <p>{label}</p> {labelInfo}
        </NavigationLinkTrash>
      )}
      {template === 'fontawesome' && (
        <NavigationLinkFontAwesome to={to}>
          {fontAwesomeIcon} <p>{label}</p> {labelInfo}
        </NavigationLinkFontAwesome>
      )}
    </React.Fragment>
  );
};

NavigationLink.defaultProps = defaultProps;

export { NavigationLink };
