import { ResponseCreditProducts } from 'src/features/BankProducts/types';
import { GET_CREDIT_PRODUCT } from '../reducers/getCreditProduct';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ResponseCreditProducts[];
}

export const req = (inn: string) => ({
  type: GET_CREDIT_PRODUCT.REQ,
  inn
});

export const got = (data: any) => ({
  type: GET_CREDIT_PRODUCT.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_CREDIT_PRODUCT.ERR,
  err
});
