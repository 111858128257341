import * as React from 'react';
import { connect } from 'react-redux';

import { STORE } from 'globaltypes';
import { ResponseDataType as GetCommentsListResponseDataType } from 'Comments/reducers/getCommentsList';

import { EachComment, RepliesContainer } from 'Comments/components';

import { CommentsListStyled, CommentsListLabel } from './styles';

interface StateToProps {
  commentsList: GetCommentsListResponseDataType;
}

const CommentsList: React.FC<StateToProps> = ({ commentsList }) =>
  commentsList.length > 0 && (
    <CommentsListStyled>
      <CommentsListLabel label="Комментарии" />

      {commentsList.map(comment => (
        <React.Fragment key={comment.id}>
          <EachComment comment={comment} />
          <RepliesContainer replies={comment.replies} />
        </React.Fragment>
      ))}
    </CommentsListStyled>
  );

const mapStateToProps = ({ Comment }: STORE) => ({
  commentsList: Comment.getCommentsList.data
});

const CommentsListConnect = connect<StateToProps>(mapStateToProps)(
  CommentsList
);

export { CommentsListConnect as CommentsList };
