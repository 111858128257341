import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getExternalCredentialsList,
  ResponseData as ExternalCredentialsListData,
  RequestData as ExternalCredentialsListRequest
} from 'src/features/ExternalCredentialsOfProducts/actions/getProductsListByExternalCredential';
import { req as getExternalCredential } from 'src/features/ExternalCredentials/actions/getExternalCredential';
import { ExternalCredentialRead } from 'src/features/ExternalCredentials/actions/setExternalCredentialFormData';
import {
  Comment,
  HeaderNameWrapper,
  InfoContainer,
  InfoWrapper,
  NameSeparator,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/ExternalCredentials/components/ExternalCredentialView/styled';

interface StateToProps {
  externalCredential: ExternalCredentialRead;
  externalCredentialsList: ExternalCredentialsListData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  getExternalCredentialsList: (data: ExternalCredentialsListRequest) => void;
  getExternalCredential: (id: string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ExternalCredentialView extends React.Component<Props, State> {
  componentDidMount() {
    const {
      getExternalCredentialsList,
      getExternalCredential,
      match
    } = this.props;

    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    getExternalCredentialsList({
      page,
      pageSize: 20,
      externalCredentialId: +match.params.id
    });
    getExternalCredential(match.params.id);
  }

  navigateToProduct = (productId: number) => {
    history.push(`/crm/products/${productId}`);
  };

  render() {
    const {
      externalCredential,
      externalCredentialsList,
      status,
      error
    } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <HeaderNameWrapper>
              <ApplicationTitle>
                {externalCredential.platformName}
              </ApplicationTitle>
              <NameSeparator />
              <p>{externalCredential.ourCompany}</p>
            </HeaderNameWrapper>

            <InfoContainer>
              <InfoWrapper>
                <p>Ссылка:</p>
                <p>{externalCredential.website}</p>
              </InfoWrapper>
              <InfoWrapper>
                <p>Логин:</p>
                <p>{externalCredential.login}</p>
              </InfoWrapper>
              <InfoWrapper>
                <p>Пароль:</p>
                <p>{externalCredential.password}</p>
              </InfoWrapper>

              <ToggleButtonContainer>
                <ToggleButtonNo
                  disabled={externalCredential.isBankPlatform === true}
                  active={externalCredential.isBankPlatform}
                >
                  нет
                </ToggleButtonNo>
                <ToggleButtonYes
                  disabled={externalCredential.isBankPlatform === false}
                  active={externalCredential.isBankPlatform}
                >
                  да
                </ToggleButtonYes>
                <p>Принадлежит банку</p>
              </ToggleButtonContainer>

              <ToggleButtonContainer>
                <ToggleButtonNo
                  disabled={externalCredential.isCompetitorPlatform === true}
                  active={externalCredential.isCompetitorPlatform}
                >
                  нет
                </ToggleButtonNo>
                <ToggleButtonYes
                  disabled={externalCredential.isCompetitorPlatform === false}
                  active={externalCredential.isCompetitorPlatform}
                >
                  да
                </ToggleButtonYes>
                <p>Принадлежит конкуренту</p>
              </ToggleButtonContainer>
            </InfoContainer>

            <p>Комментарий:</p>
            <Comment>{externalCredential.comment}</Comment>

            <ApplicationId>Продукты на этой платформе:</ApplicationId>

            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">ID</TableThStyled>
                  <TableThStyled width="10%">Название</TableThStyled>
                  <TableThStyled width="10%">Тип</TableThStyled>
                  <TableThStyled width="15%">Cashback</TableThStyled>
                  <TableThStyled width="10%">Через FF</TableThStyled>
                  <TableThStyled width="10%">МСП</TableThStyled>
                  <TableThStyled width="10%">КСБ</TableThStyled>
                  <TableThStyled width="10%">Мин. лимит</TableThStyled>
                  <TableThStyled width="10%">Макс. лимит</TableThStyled>
                  <TableThStyled width="10%">Риск-аппетит</TableThStyled>
                  <TableThStyled width="10%">Сложность</TableThStyled>
                  <TableThStyled width="10%">Эксклюзивность</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {externalCredentialsList.items.map(credential => (
                  <TableRowStyled
                    key={credential.id}
                    onClick={() => this.navigateToProduct(credential.productId)}
                  >
                    <td>{credential.id}</td>
                    <td>{credential.bankName}</td>
                    <td>{credential.financeTypeName}</td>
                    <td>{credential.cashbackPromotion}</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{credential.riskAppetite}</td>
                    <td />
                    <td />
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({
  ExternalCredentialsOfProducts,
  ExternalCredentials
}: CRM) => ({
  externalCredential:
    ExternalCredentials.getExternalCredential.externalCredential,
  externalCredentialsList:
    ExternalCredentialsOfProducts.getProductsListByExternalCredential.data,
  status:
    ExternalCredentialsOfProducts.getProductsListByExternalCredential.status,
  error: ExternalCredentialsOfProducts.getProductsListByExternalCredential.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getExternalCredentialsList, getExternalCredential },
    dispatch
  );

const ExternalCredentialViewConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ExternalCredentialView)
);

export { ExternalCredentialViewConnect as ExternalCredentialView };
