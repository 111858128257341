import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  req as getActiveDDSOfDebtor,
  ResponseData as ResActiveDDSOfDebtor
} from 'entities/Cabinet/InternalAgent/model/actions/getActiveDDSOfDebtor';
import {
  ContractsInfoItemLink,
  DiscountingSettingsPageStyled,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  ContractsContainer,
  DaysContainer,
  DaysItem,
  DaysItemRight,
  DaysItemActive,
  DaysItemRightActive,
  SubTitle,
  FormOfTheAgreement
} from './styled';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import './style.css';

interface StateToProps {
  discountingSettings: ResActiveDDSOfDebtor | null;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getActiveDDSOfDebtor: (inn: string) => void;
}

interface MatchParams {
  inn: string;
}

interface OwnProps {
  inn: string;
  subTitle: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

const DiscountingInfo: React.FC<Props> = ({
  inn,
  subTitle,
  getActiveDDSOfDebtor,
  discountingSettings,
  status,
  history,
  match
}) => {
  React.useEffect(
    () => {
      if (inn) {
        getActiveDDSOfDebtor(inn);
      }
    },
    [inn]
  );

  const onRegistriesSettingsClick = () => {
    history.push(`/internal/debtors/${match.params.inn}/registry_settings`);
  };

  return (
    <DiscountingSettingsPageStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <>
          <ContractsContainer>
            <ContractsInfoArea>
              <SubTitle>{subTitle}</SubTitle>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Дисконтирование (ранняя оплата):
                </ContractsInfoItemTitle>
                {discountingSettings.enableDiscounting == true ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            {discountingSettings.enableDiscounting == true ? (
              <ContractsInfoArea>
                <h1>Установление максимального размера лимита:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountLimitPolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную</p>
                  {(discountingSettings.discountLimitPolicy == 1) == true ? (
                    <input
                      className="contract-input"
                      disabled={true}
                      value={discountingSettings.manualMaxLimitSize.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.discountLimitPolicy == 1 ? (
                    <p style={{ marginLeft: '10px' }}>руб.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountLimitPolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Остаток на счетах</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer
                  style={{ paddingTop: '10px', marginLeft: '50px' }}
                >
                  {discountingSettings.autoLimitPolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Уменьшать на фиксированную сумму</p>
                  {(discountingSettings.autoLimitPolicy == 1) == true ? (
                    <input
                      className="contract-input"
                      disabled={true}
                      value={discountingSettings.fixedSubstractionSum.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.autoLimitPolicy == 1 ? (
                    <p style={{ marginLeft: '10px' }}>руб.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer
                  style={{ paddingTop: '10px', marginLeft: '50px' }}
                >
                  {discountingSettings.autoLimitPolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Уменьшать на прогноз оплат за</p>
                  {(discountingSettings.autoLimitPolicy == 2) == true ? (
                    <input
                      className="contract-input input-short"
                      disabled={true}
                      value={discountingSettings.substractionPeriodInDays}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.autoLimitPolicy == 2 ? (
                    <p style={{ marginLeft: '10px' }}>дн.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer
                  style={{ marginTop: '20px', marginLeft: '30px' }}
                >
                  <ContractsInfoItemTitle>
                    Итоговый лимит:
                  </ContractsInfoItemTitle>
                  {discountingSettings.manualMaxLimitSize > 0 ? (
                    <p style={{ width: '150px', textAlign: 'end' }}>
                      {discountingSettings.manualMaxLimitSize.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    </p>
                  ) : (
                    <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                  )}

                  <p>руб.</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                  <ContractsInfoItemTitle>Использовано:</ContractsInfoItemTitle>
                  {discountingSettings.limitUsage > 0 ? (
                    <p style={{ width: '150px', textAlign: 'end' }}>
                      {discountingSettings.limitUsage.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </p>
                  ) : (
                    <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                  )}
                  <p>руб.</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                  <ContractsInfoItemTitle>
                    Остаток лимита:
                  </ContractsInfoItemTitle>
                  <p style={{ width: '150px', textAlign: 'end' }}>
                    {(
                      discountingSettings.manualMaxLimitSize -
                      discountingSettings.limitUsage
                    ).toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </p>
                  <p>руб.</p>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}
            {discountingSettings.enableDiscounting == true ? (
              <ContractsInfoArea>
                <h1>Размер дисконта:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountRatePolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную</p>
                  {(discountingSettings.discountRatePolicy == 1) == true ? (
                    <input
                      className="contract-input input-short"
                      disabled={true}
                      value={discountingSettings.discountRate}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.discountRatePolicy == 1 ? (
                    <p style={{ marginLeft: '10px' }}>% годовых</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountRatePolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Эквивалентен лучшему предложению, но не ниже</p>
                  {discountingSettings.discountRatePolicy == 2 ? (
                    <input
                      className="contract-input input-short"
                      disabled={true}
                      value={discountingSettings.minimalDiscount}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.discountRatePolicy == 2 ? (
                    <p style={{ marginLeft: '10px' }}>% годовых</p>
                  ) : (
                    ''
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.autoRateSubstraction > 0 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Уменьшать на фиксированную долю</p>
                  {discountingSettings.autoRateSubstraction > 0 ? (
                    <input
                      className="contract-input input-short"
                      disabled={true}
                      value={discountingSettings.autoRateSubstraction}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.autoRateSubstraction > 0 ? (
                    <p style={{ marginLeft: '10px' }}>% годовых</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}

            {discountingSettings.enableDiscounting == true &&
            discountingSettings.payWeekdays ? (
              <ContractsInfoArea>
                <h1>Платежные дни</h1>
                <DaysContainer>
                  {atob(discountingSettings.payWeekdays).charAt(0) == '1' ? (
                    <DaysItemActive>
                      <p>Пн</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Пн</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(1) == '1' ? (
                    <DaysItemActive>
                      <p>Вт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Вт</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(2) == '1' ? (
                    <DaysItemActive>
                      <p>Ср</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Ср</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(3) == '1' ? (
                    <DaysItemActive>
                      <p>Чт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Чт</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(4) == '1' ? (
                    <DaysItemActive>
                      <p>Пт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Пт</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(5) == '1' ? (
                    <DaysItemActive>
                      <p>Сб</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Сб</p>
                    </DaysItem>
                  )}
                  {atob(discountingSettings.payWeekdays).charAt(6) == '1' ? (
                    <DaysItemRightActive>
                      <p>Вс</p>
                    </DaysItemRightActive>
                  ) : (
                    <DaysItemRight>
                      <p>Вс</p>
                    </DaysItemRight>
                  )}
                </DaysContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}

            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <p>Дата оплаты не раньше, чем через</p>
                <input
                  className="contract-input input-short"
                  disabled={true}
                  value={discountingSettings.paydayOffset}
                />
                <p style={{ marginLeft: 10 }}>дн.</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>

            {discountingSettings.enableDiscounting &&
            discountingSettings.suppliesDataSourcePolicy ? (
              <ContractsInfoArea>
                <h1>Источник данных о поставках:</h1>
                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy === 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Загрузка реестров поставки</p>
                    <ContractsInfoItemLink onClick={onRegistriesSettingsClick}>
                      Настройки файла реестра
                    </ContractsInfoItemLink>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}

                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy !== 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Интеграция баз данных</p>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}

                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy !== 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Ручной ввод поставщиком</p>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}
              </ContractsInfoArea>
            ) : (
              ''
            )}
          </ContractsContainer>

          <ContractsInfoArea>
            <FormOfTheAgreement>
              <h1 style={{ marginTop: '20px', marginBottom: '20px' }}>
                Подписание документов:
              </h1>

              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Формировать протоколы:
                </ContractsInfoItemTitle>

                {!!discountingSettings &&
                discountingSettings.protocolsPolicy == true ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </FormOfTheAgreement>
          </ContractsInfoArea>
        </>
      )}
    </DiscountingSettingsPageStyled>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE, ownProps: OwnProps) => {
  const debtorData = InternalAgentRole.getActiveDDSOfDebtor[ownProps.inn] || {
    status: REQUEST_STATUSES.NONE,
    data: null,
    error: {}
  };

  return {
    discountingSettings: debtorData.data,
    status: debtorData.status,
    error: debtorData.error
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getActiveDDSOfDebtor }, dispatch);

const DiscountingInfoConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DiscountingInfo)
);

export { DiscountingInfoConnect as DiscountingInfo };
