import { PUT_MONETARY_CLAIM_DELETE_TO_AUCTION } from 'src/features/SCF/reducers/putMonetaryClaimDeleteToAuction';

export const req = (guid: string, auction_guid: string) => ({
  type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.REQ,
  guid,
  auction_guid
});

export const got = () => ({
  type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.GOT
});

export const err = (error: {}) => ({
  type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.ERR,
  error
});

export const reset = () => ({
  type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.RESET
});
