import { CREATE_ACTIVITIES } from '../reducers/createActivities';

export const req = data => ({
  type: CREATE_ACTIVITIES.REQ,
  data
});

export const reset = () => ({
  type: CREATE_ACTIVITIES.RESET
});

export const got = data => ({
  type: CREATE_ACTIVITIES.GOT,
  data
});

export const err = (error: {}) => ({
  type: CREATE_ACTIVITIES.ERR,
  error
});
