import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { blue, rr } from 'shared/styled';

export const AddLimitsPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 900px;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const DescriptionArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;

  justify-content: flex-start;
  border-radius: 10px;
  background-color: #d0e9ff;
  transition: 0.2s;
`;

export const Description = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 1em;
  padding: 0 20px;
  line-height: 130%;
`;

export const AddBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 150px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const AddBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InputArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  textarea {
    width: 500px;
    padding: 10px 20px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const InputTitle = styled.p`
  font-family: ${rr};
  font-weight: 400;
  font-size: 1.2em;
`;

export const LimitsHeader = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const BackBtn = styled.div`
  padding-left: 15px;
  margin-left: 100px;
  margin-bottom: 20px;
  width: 100px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 10px;
  color: #3f4e65;
  margin: 0;
`;
