import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faPlusSquare,
  faMinusSquare,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  AskSection,
  AskStyled,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/CooperationForProducts/components/styled';
import { req as patchCustomerRequirements } from '../actions/patchCustomerRequirements';
import { req as patchSetForbiddenRegion } from '../actions/patchSetForbiddenRegion';
import { req as patchSetAvailableRegion } from '../actions/patchSetAvailableRegion';
import { req as patchCabinetCustomerRequirements } from 'src/features/CustomerRequirements/actions/patchCabinetCustomerRequirements';
import { req as patchCabinetSetAvailableRegion } from 'src/features/CustomerRequirements/actions/patchCabinetSetAvailableRegion';
import { req as patchCabinetSetForbiddenRegion } from 'src/features/CustomerRequirements/actions/patchCabinetSetForbiddenRegion';
import { req as patchCabinetSetAvailableRegionAll } from 'src/features/CustomerRequirements/actions/patchCabinetSetAvailableRegionAll';
import { req as patchCabinetSetForbiddenRegionAll } from 'src/features/CustomerRequirements/actions/patchCabinetSetForbiddenRegionAll';
import { req as patchSetAvailableRegionAll } from 'src/features/CustomerRequirements/actions/patchSetAvailableRegionAll';
import { req as patchSetForbiddenRegionAll } from 'src/features/CustomerRequirements/actions/patchSetForbiddenRegionAll';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  ProductMinimalCompanyAge,
  ProductMinimalCompanyAgeContainer,
  RegionContainer,
  SubjectPolicyContainer
} from 'src/features/CustomerRequirements/components/styled';
import {
  Field,
  Input,
  ProductItem,
  ProductSizeContainer,
  ProgressBar,
  ProgressStep,
  Square,
  Text,
  Tooltip
} from 'src/features/BasicParametersForProducts/components/styled';
import {
  SUBJECT_POLICY,
  tabData
} from 'src/features/CustomerRequirements/types';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

export interface State {
  isForMicro: boolean | null;
  isForMedium: boolean | null;
  isForSmall: boolean | null;
  isForLarge: boolean | null;
  isForCommercialCompanies: boolean | null;
  isForStateCompanies: boolean | null;
  isForEnterpreneurs: boolean | null;
  isForSelfEmployed: boolean | null;
  isForIndividuals: boolean | null;
  isForNonResident: boolean | null;
  mspSubjectPolicy: SUBJECT_POLICY | number;
  activeSubjectPolicy: boolean;
  minimalCompanyAge: any;
  activeMinimalCompanyAge: boolean;
  activeMinClientRevenue: boolean;
  activeMinComfortClientRevenue: boolean;
  activeMaxComfortClientRevenue: boolean;
  activeMaxClientRevenue: boolean;
  minClientRevenue: any;
  minComfortClientRevenue: any;
  maxComfortClientRevenue: any;
  maxClientRevenue: any;
  isAdminMinClientRevenue: boolean;
  isAdminMinComfortClientRevenue: boolean;
  isAdminMaxComfortClientRevenue: boolean;
  isAdminMaxClientRevenue: boolean;
  isAdminMinimalCompanyAge: boolean;
  isAdminMspSubjectPolicy: boolean;
  selectedRegion: number;
  bits: number[];
}

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchCustomerRequirements: (id: string, data: any) => void;
  patchCabinetCustomerRequirements: (guid: string, data: any) => void;
  patchSetForbiddenRegion: (id: string, region: string) => void;
  patchSetAvailableRegion: (id: string, region: string) => void;
  patchCabinetSetAvailableRegion: (id: string, region: string) => void;
  patchCabinetSetForbiddenRegion: (id: string, region: string) => void;
  patchCabinetSetAvailableRegionAll: (id: string) => void;
  patchCabinetSetForbiddenRegionAll: (id: string) => void;
  patchSetAvailableRegionAll: (id: string) => void;
  patchSetForbiddenRegionAll: (id: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class CustomerRequirementsForProduct extends React.Component<Props, State> {
  state: State = {
    isForMicro: null,
    isForMedium: null,
    isForSmall: null,
    isForLarge: null,
    isForCommercialCompanies: null,
    isForStateCompanies: null,
    isForEnterpreneurs: null,
    isForSelfEmployed: null,
    isForIndividuals: null,
    isForNonResident: null,
    mspSubjectPolicy: null,
    activeSubjectPolicy: false,
    minimalCompanyAge: null,
    activeMinimalCompanyAge: false,
    activeMaxClientRevenue: false,
    activeMaxComfortClientRevenue: false,
    activeMinClientRevenue: false,
    activeMinComfortClientRevenue: false,
    minClientRevenue: null,
    minComfortClientRevenue: null,
    maxComfortClientRevenue: null,
    maxClientRevenue: null,
    isAdminMinClientRevenue: false,
    isAdminMinComfortClientRevenue: false,
    isAdminMaxComfortClientRevenue: false,
    isAdminMaxClientRevenue: false,
    isAdminMinimalCompanyAge: false,
    isAdminMspSubjectPolicy: false,
    selectedRegion: null,
    bits: []
  };

  componentDidMount() {
    if (this.props.product.minimalCompanyAge !== null) {
      this.setState({
        minimalCompanyAge: this.props.product.minimalCompanyAge,
        activeMinimalCompanyAge: true
      });
    }
    if (this.props.product.minClientRevenue !== null) {
      this.setState({
        minClientRevenue: this.props.product.minClientRevenue,
        activeMinClientRevenue: true
      });
    }
    if (this.props.product.minComfortClientRevenue !== null) {
      this.setState({
        minComfortClientRevenue: this.props.product.minComfortClientRevenue,
        activeMinComfortClientRevenue: true
      });
    }
    if (this.props.product.maxComfortClientRevenue !== null) {
      this.setState({
        maxComfortClientRevenue: this.props.product.maxComfortClientRevenue,
        activeMaxComfortClientRevenue: true
      });
    }
    if (this.props.product.maxClientRevenue !== null) {
      this.setState({
        maxClientRevenue: this.props.product.maxClientRevenue,
        activeMaxClientRevenue: true
      });
    }
    if (this.props.product.mspSubjectPolicy !== null) {
      this.setState({
        mspSubjectPolicy: this.props.product.mspSubjectPolicy,
        activeSubjectPolicy: true
      });
    }
    this.setState({ ...this.props.product } as any);
    this.binareDecode();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.minClientRevenue !== this.state.minClientRevenue) {
      this.setState({ isAdminMinClientRevenue: false });
    }
    if (
      prevState.minComfortClientRevenue !== this.state.minComfortClientRevenue
    ) {
      this.setState({ isAdminMinComfortClientRevenue: false });
    }
    if (
      prevState.maxComfortClientRevenue !== this.state.maxComfortClientRevenue
    ) {
      this.setState({ isAdminMaxComfortClientRevenue: false });
    }
    if (prevState.maxClientRevenue !== this.state.maxClientRevenue) {
      this.setState({ isAdminMaxClientRevenue: false });
    }
    if (prevState.minimalCompanyAge !== this.state.minimalCompanyAge) {
      this.setState({ isAdminMinimalCompanyAge: false });
    }
    if (prevState.mspSubjectPolicy !== this.state.mspSubjectPolicy) {
      this.setState({ isAdminMspSubjectPolicy: false });
    }
  }

  binareDecode() {
    if (this.props.product.forbiddenRegions === null) return;

    const decodedString = atob(this.props.product.forbiddenRegions);

    const bits = [];
    for (let i = 0; i < decodedString.length; i++) {
      const char = decodedString.charAt(i);
      const binary = char
        .charCodeAt(0)
        .toString(2)
        .padStart(8, '0');
      bits.push(binary);
    }

    let currentIndex = 0;
    const result = bits.reduce((acc, bit) => {
      const reversedBit = bit
        .split('')
        .reverse()
        .join('');
      const indexes = reversedBit.split('').reduce((indexesAcc, cur, idx) => {
        if (cur === '1') {
          indexesAcc.push(currentIndex + idx + 1);
        }
        return indexesAcc;
      }, []);
      currentIndex += reversedBit.length;
      return acc.concat(indexes);
    }, []);

    this.setState({ bits: result });
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    const patchObject: any = {};

    switch (key) {
      case 'activeSubjectPolicy':
        patchObject.mspSubjectPolicy = +this.state.mspSubjectPolicy[0];
        break;
      case 'activeMinimalCompanyAge':
        patchObject.minimalCompanyAge = +this.state.minimalCompanyAge;
        break;
      case 'activeMinClientRevenue':
        patchObject.minClientRevenue = +this.state.minClientRevenue;
        break;
      case 'activeMinComfortClientRevenue':
        patchObject.minComfortClientRevenue = +this.state
          .minComfortClientRevenue;
        break;
      case 'activeMaxComfortClientRevenue':
        patchObject.maxComfortClientRevenue = +this.state
          .maxComfortClientRevenue;
        break;
      case 'activeMaxClientRevenue':
        patchObject.maxClientRevenue = +this.state.maxClientRevenue;
        break;
      default:
        patchObject[key] = value;
    }

    this.setState({ [key]: value } as Pick<any, keyof State>);

    if (this.props.roles.includes('Bank')) {
      this.props.patchCabinetCustomerRequirements(id, patchObject);
    } else {
      this.props.patchCustomerRequirements(id, patchObject);
    }
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, false)
          }
          disabled={!isAdmin && this.state[buttonName] === true}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, true)
          }
          disabled={!isAdmin && this.state[buttonName] === false}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  handleProductSubjectPolicyClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    if (!this.state.activeSubjectPolicy || isAdmin) {
      this.setState({ mspSubjectPolicy: item });
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  handleRegionClick = (index: number) => {
    this.setState({ selectedRegion: index });
  };

  getIcon(ids) {
    const hasAllIds = ids.every(id => this.state.bits.includes(id));
    if (hasAllIds) {
      return <FontAwesomeIcon icon={faSquare} />;
    } else if (ids.some(id => this.state.bits.includes(id))) {
      return <FontAwesomeIcon icon={faMinusSquare} />;
    } else {
      return <FontAwesomeIcon icon={faPlusSquare} />;
    }
  }

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    const allActive =
      this.state.activeMinClientRevenue &&
      this.state.activeMinComfortClientRevenue &&
      this.state.activeMaxComfortClientRevenue &&
      this.state.activeMaxClientRevenue;

    return (
      <ApplicationViewStyled>
        <AskSection>
          <AskStyled>Сегменты клиентов:</AskStyled>
          {this.renderToggleButton('isForMicro', 'Микробизнес')}
          {this.renderToggleButton('isForSmall', 'Малый бизнес')}
          {this.renderToggleButton('isForMedium', 'Средний бизнес')}
          {this.renderToggleButton('isForLarge', 'Крупный бизнес')}
        </AskSection>

        <AskSection>
          <AskStyled>Юридические формы клиентов:</AskStyled>
          {this.renderToggleButton(
            'isForCommercialCompanies',
            'Коммерческие компании'
          )}
          {this.renderToggleButton('isForStateCompanies', 'Госкомпании')}
          {this.renderToggleButton('isForEnterpreneurs', 'ИП')}
          {this.renderToggleButton('isForSelfEmployed', 'Самозанятые')}
          {!roles.includes('Bank') &&
            this.renderToggleButton(
              'isForIndividuals',
              'Физлица (бенефициары)'
            )}
        </AskSection>

        <AskSection>
          <AskStyled>Особенности клиента:</AskStyled>
          {this.renderToggleButton('isForNonResident', 'Нерезидент')}
        </AskSection>

        <AskStyled>Выручка клиента:</AskStyled>

        <ProgressBar
          one={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue < this.state.maxClientRevenue
          }
          two={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue < this.state.maxClientRevenue
          }
          three={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxClientRevenue == 0
          }
          four={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.maxComfortClientRevenue == 0 &&
            this.state.maxClientRevenue == 0
          }
          five={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minComfortClientRevenue == 0 &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue < this.state.maxClientRevenue
          }
          six={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minComfortClientRevenue == 0 &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue == this.state.maxClientRevenue
          }
          seven={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minComfortClientRevenue == 0 &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxClientRevenue == 0
          }
          eight={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minComfortClientRevenue == 0 &&
            this.state.maxComfortClientRevenue == 0 &&
            this.state.maxClientRevenue == 0
          }
          nine={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxClientRevenue == 0
          }
          ten={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxClientRevenue == 0
          }
          eleven={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue == this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue < this.state.maxClientRevenue
          }
          twelve={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue == this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxClientRevenue == 0
          }
          thirteen={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue == this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue == this.state.maxClientRevenue
          }
          fourteen={
            allActive &&
            this.state.minClientRevenue > 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.minComfortClientRevenue <
              this.state.maxComfortClientRevenue &&
            this.state.maxComfortClientRevenue == this.state.maxClientRevenue
          }
          fifteen={
            allActive &&
            this.state.minClientRevenue == 0 &&
            this.state.minClientRevenue < this.state.minComfortClientRevenue &&
            this.state.maxComfortClientRevenue == 0 &&
            this.state.maxComfortClientRevenue < this.state.maxClientRevenue
          }
        >
          {[...Array(5)].map(step => (
            <ProgressStep key={step} />
          ))}
        </ProgressBar>

        <ProductSizeContainer>
          <Field>
            {!isAdmin &&
            this.state.activeMinClientRevenue &&
            (this.state.minClientRevenue == '0' ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxClientRevenue == 0)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinClientRevenue}
                name="minClientRevenue"
                value={this.state.minClientRevenue}
                onChange={this.handleInputChange}
                disabled={!isAdmin && this.state.activeMinClientRevenue}
              />
            )}
            <Tooltip>Минимальная выручка клиента</Tooltip>
            {(!this.state.activeMinClientRevenue || isAdmin) &&
              !!this.state.minClientRevenue &&
              this.state.minClientRevenue !==
                this.props.product.minClientRevenue &&
              !this.state.isAdminMinClientRevenue && (
                <Button
                  label="Сохранить"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinClientRevenue: true });
                    }
                    this.handleButtonClick('activeMinClientRevenue', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minClientRevenue)}
                  h='40px'
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMinComfortClientRevenue &&
            (this.state.minComfortClientRevenue == '0' ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue ==
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue <
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue ==
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxClientRevenue == 0) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue ==
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinComfortClientRevenue}
                name="minComfortClientRevenue"
                value={this.state.minComfortClientRevenue}
                onChange={this.handleInputChange}
                disabled={!isAdmin && this.state.activeMinComfortClientRevenue}
              />
            )}
            <Tooltip>Комфортная нижняя граница</Tooltip>
            {(!this.state.activeMinComfortClientRevenue || isAdmin) &&
              !!this.state.minComfortClientRevenue &&
              this.state.minComfortClientRevenue !==
                this.props.product.minComfortClientRevenue &&
              !this.state.isAdminMinComfortClientRevenue && (
                <Button
                  label="Сохранить"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinComfortClientRevenue: true });
                    }
                    this.handleButtonClick(
                      'activeMinComfortClientRevenue',
                      true
                    );
                  }}
                  disabled={!/^\d+$/.test(this.state.minComfortClientRevenue)}
                  h='40px'
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxComfortClientRevenue &&
            (this.state.maxComfortClientRevenue == '0' ||
              (allActive &&
                this.state.minClientRevenue == 0 &&
                this.state.minComfortClientRevenue == 0 &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue == 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue ==
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxComfortClientRevenue}
                name="maxComfortClientRevenue"
                value={this.state.maxComfortClientRevenue}
                onChange={this.handleInputChange}
                disabled={!isAdmin && this.state.activeMaxComfortClientRevenue}
              />
            )}
            <Tooltip>Комфортная верхняя граница</Tooltip>
            {(!this.state.activeMaxComfortClientRevenue || isAdmin) &&
              !!this.state.maxComfortClientRevenue &&
              this.state.maxComfortClientRevenue !==
                this.props.product.maxComfortClientRevenue &&
              !this.state.isAdminMaxComfortClientRevenue && (
                <>
                  <Button
                    label="Сохранить"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxComfortClientRevenue: true });
                      }
                      this.handleButtonClick(
                        'activeMaxComfortClientRevenue',
                        true
                      );
                    }}
                    disabled={!/^\d+$/.test(this.state.maxComfortClientRevenue)}
                    h='40px'
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxClientRevenue &&
            (this.state.maxClientRevenue == '0' ||
              (allActive &&
                this.state.minClientRevenue == 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxClientRevenue == 0) ||
              (allActive &&
                this.state.minClientRevenue == 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue <
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue) ||
              (allActive &&
                this.state.minClientRevenue > 0 &&
                this.state.minClientRevenue ==
                  this.state.minComfortClientRevenue &&
                this.state.minComfortClientRevenue <
                  this.state.maxComfortClientRevenue &&
                this.state.maxComfortClientRevenue ==
                  this.state.maxClientRevenue)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxClientRevenue}
                name="maxClientRevenue"
                value={this.state.maxClientRevenue}
                onChange={this.handleInputChange}
                disabled={!isAdmin && this.state.activeMaxClientRevenue}
              />
            )}
            <Tooltip>Максимальная выручка клиента</Tooltip>
            {(!this.state.activeMaxClientRevenue || isAdmin) &&
              !!this.state.maxClientRevenue &&
              this.state.maxClientRevenue !==
                this.props.product.maxClientRevenue &&
              !this.state.isAdminMaxClientRevenue && (
                <>
                  <Button
                    label="Сохранить"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxClientRevenue: true });
                      }
                      this.handleButtonClick('activeMaxClientRevenue', true);
                    }}
                    disabled={!/^\d+$/.test(this.state.maxClientRevenue)}
                    h='40px'
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
        </ProductSizeContainer>

        <ProductMinimalCompanyAgeContainer>
          <AskStyled>Срок продукта (месяцев):</AskStyled>
          <ProductMinimalCompanyAge>
            <p>Минимальный срок жизни компании (месяцев):</p>
            <Input
              active={this.state.activeMinimalCompanyAge}
              type="text"
              name="minimalCompanyAge"
              value={
                this.state.activeMinimalCompanyAge && !isAdmin
                  ? `${this.state.minimalCompanyAge} мес.`
                  : this.state.minimalCompanyAge
              }
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMinimalCompanyAge}
            />{' '}
            {(!this.state.activeMinimalCompanyAge || isAdmin) &&
              !!this.state.minimalCompanyAge &&
              this.state.minimalCompanyAge !==
                this.props.product.minimalCompanyAge &&
              !this.state.isAdminMinimalCompanyAge && (
                <Button
                  label="Сохранить"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinimalCompanyAge: true });
                    }
                    this.handleButtonClick('activeMinimalCompanyAge', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minimalCompanyAge)}
                  h='40px'
                />
              )}
          </ProductMinimalCompanyAge>
        </ProductMinimalCompanyAgeContainer>

        <SubjectPolicyContainer
          product={this.state.mspSubjectPolicy}
          active={this.state.activeSubjectPolicy}
        >
          <AskStyled>Политика в отношении субъектов МСП:</AskStyled>
          {Object.keys(SUBJECT_POLICY).map((item, index) => (
            <div key={index}>
              <ProductItem
                onClick={() => this.handleProductSubjectPolicyClick(item)}
              >
                <Square
                  active={
                    this.state.mspSubjectPolicy === item ||
                    this.state.mspSubjectPolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.mspSubjectPolicy === item &&
                (!this.state.activeSubjectPolicy || isAdmin) &&
                !this.state.isAdminMspSubjectPolicy && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeSubjectPolicy}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMspSubjectPolicy: true });
                      }
                      this.handleButtonClick('activeSubjectPolicy', true);
                    }}
                    h='40px'
                  />
                )}
            </div>
          ))}
        </SubjectPolicyContainer>

        <RegionContainer>
          <AskStyled>Регионы:</AskStyled>

          <div>
            <button
              onClick={() => {
                this.props.roles.includes('Bank')
                  ? this.props.patchCabinetSetAvailableRegionAll(
                      this.props.match.params.id
                    )
                  : this.props.patchSetAvailableRegionAll(
                      this.props.match.params.id
                    );

                this.setState({ bits: [] });
              }}
            >
              Разрешить все
            </button>
            <button
              onClick={() => {
                this.props.roles.includes('Bank')
                  ? this.props.patchCabinetSetForbiddenRegionAll(
                      this.props.match.params.id
                    )
                  : this.props.patchSetForbiddenRegionAll(
                      this.props.match.params.id
                    );

                const allIds = tabData
                  .map(region => region.cities.map(city => +city.id))
                  .flat();

                this.setState({ bits: allIds });
              }}
            >
              Запретить все
            </button>
          </div>

          {tabData.map(({ title, cities }, index: number) => (
            <React.Fragment key={index}>
              <div>
                <div>
                  {this.getIcon(cities.map(city => parseInt(city.id)))}
                  <p>{title}</p>
                </div>
                <FontAwesomeIcon
                  icon={
                    this.state.selectedRegion === index
                      ? faAngleUp
                      : faAngleDown
                  }
                  onClick={
                    this.state.selectedRegion === index
                      ? () => this.handleRegionClick(null)
                      : () => this.handleRegionClick(index)
                  }
                />
              </div>
              {this.state.selectedRegion !== null &&
                this.state.selectedRegion === index && (
                  <ul>
                    {tabData[this.state.selectedRegion].cities.map(
                      ({ id, city }, index) => (
                        <li key={index}>
                          {this.state.bits.includes(+id) ? (
                            <FontAwesomeIcon
                              icon={faSquare}
                              onClick={() => {
                                const updatedBits = this.state.bits.filter(
                                  item => item !== +id
                                );
                                this.setState({ bits: updatedBits });
                                {
                                  this.props.roles.includes('Bank')
                                    ? this.props.patchCabinetSetAvailableRegion(
                                        this.props.match.params.id,
                                        id
                                      )
                                    : this.props.patchSetAvailableRegion(
                                        this.props.match.params.id,
                                        id
                                      );
                                }
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckSquare}
                              onClick={() => {
                                this.setState({
                                  bits: [...this.state.bits, +id]
                                });
                                {
                                  this.props.roles.includes('Bank')
                                    ? this.props.patchCabinetSetForbiddenRegion(
                                        this.props.match.params.id,
                                        id
                                      )
                                    : this.props.patchSetForbiddenRegion(
                                        this.props.match.params.id,
                                        id
                                      );
                                }
                              }}
                            />
                          )}
                          <p>
                            {id} - {city}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                )}
            </React.Fragment>
          ))}
        </RegionContainer>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ CustomerRequirements }: CRM) => ({
  status: CustomerRequirements.patchCustomerRequirements.status,
  error: CustomerRequirements.patchCustomerRequirements.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      patchCustomerRequirements,
      patchCabinetCustomerRequirements,
      patchSetForbiddenRegion,
      patchSetAvailableRegion,
      patchCabinetSetAvailableRegion,
      patchCabinetSetForbiddenRegion,
      patchCabinetSetAvailableRegionAll,
      patchCabinetSetForbiddenRegionAll,
      patchSetAvailableRegionAll,
      patchSetForbiddenRegionAll
    },
    dispatch
  );

const CustomerRequirementsForProductConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerRequirementsForProduct)
);

export {
  CustomerRequirementsForProductConnect as CustomerRequirementsForProduct
};
