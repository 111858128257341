import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from 'src/shared/utils/History';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import { capitalize } from 'src/shared/utils/Utils';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';

import {
  HeaderStyled,
  Logo,
  AuthorizedBlock,
  UserName,
  LogOutBtn,
  HeaderLink,
  LogoContainer,
  LogoContainerExternalAgent,
  LogoContainerClient,
  SettingsArea,
  SettingsAreaIcon,
  SettingsAreaText,
  SupportInfo
} from './styles';
import { ClickOutsideWrapper } from 'src/features/Common';

interface State {
  isOpenSections: boolean;
  currentTime: Date;
}

interface StateToProps {
  name: string;
  status: REQUEST_STATUSES;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
  roles: string[];
}

interface DispatchToProps {
  exit: () => void;
  resetUserData: () => void;
}

type Props = StateToProps & DispatchToProps;

const Header: React.FC<Props> = ({
  exit,
  isAuthorized,
  name,
  permissions,
  roles,
  resetUserData,
  status
}) => {
  const [isOpenSections, setIsOpenSections] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const moscowTime = new Date(
    currentTime.toLocaleString('en-US', { timeZone: 'Europe/Moscow' })
  );
  const hours = moscowTime.getHours();

  let content;

  if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
    !permissions.includes(USER_PERMISSIONS.DEBTOR)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
    permissions.includes(USER_PERMISSIONS.DEBTOR)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.CLIENT) &&
    !permissions.includes(USER_PERMISSIONS.SUPPLIER)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.CLIENT) &&
    permissions.includes(USER_PERMISSIONS.SUPPLIER)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
    !permissions.includes(USER_PERMISSIONS.FACTOR)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else if (
    hours >= 10 &&
    hours < 19 &&
    permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
    permissions.includes(USER_PERMISSIONS.FACTOR)
  ) {
    content = (
      <SupportInfo>
        Ваш менеджер <br /> Адильев Айнур <br /> +7 917 250-72-88 <br />
        a.adilev@finfactory.one
      </SupportInfo>
    );
  } else {
    content = (
      <SupportInfo>
        Поддержка: <br /> support@finfactory.one
      </SupportInfo>
    );
  }

  const logOut = () => {
    exit();
    resetUserData();
  };

  const settingsButton = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/requisites');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/requisites');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/requisites');
    }
  };

  const handleOpenSection = () => {
    setIsOpenSections(prevState => !prevState);
  };

  const handleNavigateToSection = (sectionName: string) => {
    const role = roles.includes('Bank')
      ? 'bank'
      : roles.includes('Applicant')
        ? 'client'
        : roles.includes('InternalAgent') && 'internal';

    if (sectionName === 'kyc') {
      history.push(`/business`);
    } else if (sectionName === 'cabinet') {
      history.push(`/${sectionName}/${role}`);
    } else {
      history.push(`/${sectionName}`);
    }
    setIsOpenSections(false);
  };

  const navigateToProfile = () => {
    if (history.location.pathname.includes('cabinet')) {
      const segments = location.pathname.split('/');
      const roleIndex = segments.indexOf('cabinet') + 1;
      const role = segments[roleIndex];

      history.push(`/cabinet/${role}/profile`);
    }
  };

  const segments = location.pathname.split('/');
  const roleIndex = segments.indexOf('cabinet') + 1;
  const role = segments[roleIndex];

  return (
    <HeaderStyled>
      {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) ? (
        <ClickOutsideWrapper onClickOutside={() => setIsOpenSections(false)}>
          <LogoContainer>
            <Logo onClick={handleOpenSection} />
            <span />
            {isAuthorized &&
            (history.location.pathname.includes('/cabinet') ||
              history.location.pathname.includes('/internal')) ? (
              <p>Кабинет сотрудника</p>
            ) : isAuthorized && history.location.pathname.includes('/crm') ? (
              <p>CRM</p>
            ) : (
              isAuthorized &&
              (history.location.pathname.includes('/business') ||
                history.location.pathname.includes('/person')) && (
                <p>Проверка контрагентов</p>
              )
            )}

            {isOpenSections && (
              <nav>
                <div onClick={() => handleNavigateToSection('cabinet')}>
                  <p>Кабинет</p>
                  <p>
                    Основной раздел с заявками на классические банковские
                    продукты
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('crm')}>
                  <p>CRM</p>
                  <p>
                    База знаний по клиентам, агентам, банкам и банковским
                    продуктам
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('internal')}>
                  <p>SCF</p>
                  <p>
                    Раздел с интегрированными финансовыми продуктами для цепочек
                    поставок
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('kyc')}>
                  <p>KYC</p>
                  <p>Проверка контрагента по данным открытых источников</p>
                </div>
              </nav>
            )}
          </LogoContainer>
        </ClickOutsideWrapper>
      ) : permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
      !permissions.includes(USER_PERMISSIONS.DEBTOR) ? (
        <LogoContainer>
          <Logo onClick={() => history.push('/cabinet')} />
          <span />
          {isAuthorized &&
            history.location.pathname.includes('/cabinet') && (
              <p>Кабинет партнера</p>
            )}
        </LogoContainer>
      ) : permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
      permissions.includes(USER_PERMISSIONS.DEBTOR) ? (
        <ClickOutsideWrapper onClickOutside={() => setIsOpenSections(false)}>
          <LogoContainerExternalAgent>
            <Logo onClick={handleOpenSection} />
            <span />
            {isAuthorized && history.location.pathname.includes('/cabinet') ? (
              <p>Кабинет партнера</p>
            ) : (
              isAuthorized &&
              history.location.pathname.includes('/debtor') && (
                <p>SCF Кабинет покупателя</p>
              )
            )}

            {isOpenSections && (
              <nav>
                <div onClick={() => handleNavigateToSection('cabinet')}>
                  <p>Кабинет партнера</p>
                  <p>
                    Раздел с заявками на классические продукты (кредиты и
                    банковские гарантии)
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('debtor')}>
                  <p>SCF Кабинет покупателя</p>
                  <p>
                    Раздел с финансовыми продуктами цепочек поставок (ранняя
                    оплата, динамический дисконт, факторинг)
                  </p>
                </div>
              </nav>
            )}
          </LogoContainerExternalAgent>
        </ClickOutsideWrapper>
      ) : permissions.includes(USER_PERMISSIONS.CLIENT) &&
      !permissions.includes(USER_PERMISSIONS.SUPPLIER) ? (
        <LogoContainer>
          <Logo onClick={handleOpenSection} />
          <span />
          {isAuthorized &&
            history.location.pathname.includes('/cabinet') && (
              <p>Кабинет клиента</p>
            )}
        </LogoContainer>
      ) : permissions.includes(USER_PERMISSIONS.CLIENT) &&
      permissions.includes(USER_PERMISSIONS.SUPPLIER) ? (
        <ClickOutsideWrapper onClickOutside={() => setIsOpenSections(false)}>
          <LogoContainerClient>
            <Logo onClick={handleOpenSection} />
            <span />
            {isAuthorized && history.location.pathname.includes('/cabinet') ? (
              <p>Кабинет клиента</p>
            ) : (
              isAuthorized &&
              history.location.pathname.includes('/supplier') && (
                <p>SCF Кабинет поставщика</p>
              )
            )}

            {isOpenSections && (
              <nav>
                <div onClick={() => handleNavigateToSection('cabinet')}>
                  <p>Кабинет клиента</p>
                  <p>
                    Раздел с заявками на классические продукты (кредиты и
                    банковские гарантии)
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('supplier')}>
                  <p>SCF Кабинет поставщика</p>
                  <p>
                    Раздел с финансовыми продуктами цепочек поставок (ранняя
                    оплата, динамический дисконт, факторинг)
                  </p>
                </div>
              </nav>
            )}
          </LogoContainerClient>
        </ClickOutsideWrapper>
      ) : permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
      !permissions.includes(USER_PERMISSIONS.FACTOR) ? (
        <LogoContainer>
          <Logo onClick={handleOpenSection} />
          <span />
          {isAuthorized &&
            history.location.pathname.includes('/cabinet') && (
              <p>Кабинет кредитора</p>
            )}
        </LogoContainer>
      ) : permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
      permissions.includes(USER_PERMISSIONS.FACTOR) ? (
        <ClickOutsideWrapper onClickOutside={() => setIsOpenSections(false)}>
          <LogoContainerClient>
            <Logo onClick={handleOpenSection} />
            <span />
            {isAuthorized && history.location.pathname.includes('/cabinet') ? (
              <p>Кабинет кредитора</p>
            ) : (
              isAuthorized &&
              history.location.pathname.includes('/factor') && (
                <p>SCF Кабинет фактора</p>
              )
            )}

            {isOpenSections && (
              <nav>
                <div onClick={() => handleNavigateToSection('cabinet')}>
                  <p>Кабинет кредитора</p>
                  <p>
                    Раздел с заявками на классические продукты (кредиты и
                    банковские гарантии)
                  </p>
                </div>
                <div onClick={() => handleNavigateToSection('factor')}>
                  <p>SCF Кабинет фактора</p>
                  <p>
                    Раздел с финансовыми продуктами цепочек поставок (факторинг,
                    верификация)
                  </p>
                </div>
              </nav>
            )}
          </LogoContainerClient>
        </ClickOutsideWrapper>
      ) : (
        <Logo onClick={() => history.push('/cabinet')} />
      )}

      {isAuthorized &&
        status === REQUEST_STATUSES.GOT && (
          <AuthorizedBlock>
            {content}

            {permissions &&
              permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) && (
                <HeaderLink
                  onClick={() => history.push(`/cabinet/${role}/apikeys`)}
                >
                  API
                </HeaderLink>
              )}
            {window.location.pathname.includes('factor') ||
            window.location.pathname.includes('debtor') ||
            window.location.pathname.includes('supplier') ? (
              <SettingsArea onClick={settingsButton}>
                <SettingsAreaIcon />
                <SettingsAreaText>Настройки и реквизиты</SettingsAreaText>
              </SettingsArea>
            ) : (
              ''
            )}

            <UserName onClick={navigateToProfile}>{name}</UserName>
            <LogOutBtn onClick={logOut} />
          </AuthorizedBlock>
        )}
    </HeaderStyled>
  );
};

const mapStateToProps = ({ User, Cached }: STORE) => {
  const { firstName, lastName } = User.getUserData.data;
  return {
    name: `${capitalize(firstName)} ${capitalize(lastName)}`,
    status: User.getUserData.status,
    permissions: User.getUserData.data.permissions,
    roles: User.getUserData.data.roles,
    isAuthorized: Cached.authorized
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ exit, resetUserData }, dispatch);

const HeaderConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export { HeaderConnect as Header };
