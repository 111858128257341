import {
  FinancingApplicationWrite,
  FINANCING_CREDIT_TYPE,
  FINANCING_PAYMENT_CONFIRMATION
} from 'Application/types';

export type SetFinancingApplicationType = Partial<FinancingApplicationWrite>;

export enum FINANCING_FORM_ACTIONS {
  SET = 'SET_FINANCING_FORM_DATA',
  RESET = 'RESET_FINANCING_FORM_DATA'
}

export const initialState: FinancingApplicationWrite = {
  creditType: FINANCING_CREDIT_TYPE.PROJECT_FINANCE,

  equity: 0,
  termInvestmentPhase: 1,
  isAgriculture: false,
  paymentConfirmationInvestmentPhase:
    FINANCING_PAYMENT_CONFIRMATION.BILL_OF_EXCHANGE
};

const setFinancingFormData = (
  state: FinancingApplicationWrite = initialState,
  action: {
    type: FINANCING_FORM_ACTIONS;
    data: SetFinancingApplicationType;
  }
): FinancingApplicationWrite => {
  switch (action.type) {
    case FINANCING_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case FINANCING_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setFinancingFormData;
