import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getUserFactorList';

export interface GetUserFactorListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_USER_FACTOR_LIST {
  REQ = 'GET_USER_FACTOR_LIST_REQ',
  GOT = 'GET_USER_FACTOR_LIST_GOT',
  ERR = 'GET_USER_FACTOR_LIST_ERR'
}

export const initialState: GetUserFactorListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getUserDebtorList = (
  state: GetUserFactorListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetUserFactorListStoreState => {
  switch (action.type) {
    case GET_USER_FACTOR_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_USER_FACTOR_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_USER_FACTOR_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getUserDebtorList;
