import {
  SET_APPLICATION_LIST_TYPE,
  RequestDataType
} from 'Application/reducers/setApplicationListType';

export const set = (data: RequestDataType) => ({
  type: SET_APPLICATION_LIST_TYPE.SET,
  data
});

export const reset = () => ({
  type: SET_APPLICATION_LIST_TYPE.RESET
});
