import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Leadogenerator } from '../types';

export interface GetLeadogeneratorsStoreState extends Reducer {
  data: Leadogenerator[];
}

export enum GET_LEADOGENERATORS {
  REQ = 'GET_LEADOGENERATORS_REQ',
  GOT = 'GET_LEADOGENERATORS_GOT',
  ERR = 'GET_LEADOGENERATORS_ERR'
}

export const initialState: GetLeadogeneratorsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getLeadogenerators = (
  state: GetLeadogeneratorsStoreState = initialState,
  action: {
    type: string;
    data: Leadogenerator[];
    error: {};
  }
): GetLeadogeneratorsStoreState => {
  switch (action.type) {
    case GET_LEADOGENERATORS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LEADOGENERATORS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_LEADOGENERATORS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getLeadogenerators;
