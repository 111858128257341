import { UPDATE_INITIALIZATION_FORM_DATA } from 'Application/reducers/updateInitializationFormData';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

export const req = (data: SetInitializationApplicationType) => ({
  type: UPDATE_INITIALIZATION_FORM_DATA.REQ,
  data
});

export const got = () => ({
  type: UPDATE_INITIALIZATION_FORM_DATA.GOT
});

export const err = (error: {}) => ({
  type: UPDATE_INITIALIZATION_FORM_DATA.ERR,
  error
});

export const reset = () => ({
  type: UPDATE_INITIALIZATION_FORM_DATA.RESET
});
