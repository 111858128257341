import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import {
  req as getCompanyByInn,
  ResponseDataType as CompanyByInnRes,
  RequestDataType as getCompanyByInnReq,
  reset as resetStateCompanyByInn
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getFinancialDocuments,
  ResponseData
} from 'entities/Cabinet/Client/model/actions/getFinancialDocuments';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Menu,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import { ClientInfoContainer } from './styled';
import { PreApproval } from '../PreApproval/PreApproval';
import { Documents } from './Tabs/Documents/Documents';
import { Beneficiaries } from '../Beneficiaries/Beneficiaries';

interface StateToProps {
  documents: ResponseData[];
  statusDocuments: REQUEST_STATUSES;
  errorDocuments: ResponseError;
  companyByInn: CompanyByInnRes;
  statusCompanyByInn: REQUEST_STATUSES;
  errorCompanyByInn: ResponseError;
}

interface DispatchToProps {
  getCompanyByInn: (data: getCompanyByInnReq) => void;
  resetStateCompanyByInn: () => void;
  getFinancialDocuments: (inn: string) => void;
}

export enum COMPANY_TABS {
  DEFAULT = 'Предодобрения',
  APPLICATIONS = 'Заявки',
  USERS = 'Пользователи',
  FINANCIAL = 'Финансы',
  BENEFICIARIES = 'Бенефициары',
  DOCUMENTS = 'Документы'
}

interface MatchParams {
  inn: string;
  tab: keyof typeof COMPANY_TABS;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CompanyView: React.FC<Props> = ({
  getCompanyByInn,
  resetStateCompanyByInn,
  companyByInn,
  statusCompanyByInn,
  errorCompanyByInn,
  getFinancialDocuments,
  documents,
  statusDocuments,
  errorDocuments,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof COMPANY_TABS>(
    'DEFAULT'
  );

  React.useEffect(
    () => {
      if (match.params.inn) {
        getCompanyByInn({ inn: match.params.inn });
        getFinancialDocuments(match.params.inn);
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (match.params.tab === undefined) {
        setActiveTab('DEFAULT');
      } else {
        setActiveTab(
          match.params.tab.toUpperCase() as keyof typeof COMPANY_TABS
        );
      }
    },
    [match.url, match.params.tab]
  );

  const handleActiveTab = (tabName: keyof typeof COMPANY_TABS) => {
    setActiveTab(tabName);

    const { tab } = match.params;

    const baseUrl = history.location.pathname.replace(
      `/${tab && tab.toLowerCase()}`,
      ''
    );

    if (tabName !== 'DEFAULT') {
      const url = baseUrl + `/${tabName.toLowerCase()}`;
      history.push(url);
    } else {
      history.push(baseUrl);
    }
  };

  return (
    <ApplicationViewStyled>
      {statusCompanyByInn === REQUEST_STATUSES.GOT && (
        <>
          <ClientInfoContainer>
            <div>
              <ApplicationId>ИНН {companyByInn.inn},</ApplicationId>
              <ApplicationId>ОГРН {companyByInn.ogrn},</ApplicationId>
              <ApplicationId>КПП {companyByInn.kpp}</ApplicationId>
            </div>

            <ApplicationTitle>{companyByInn.companyShortName}</ApplicationTitle>
          </ClientInfoContainer>

          <Menu
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
            tabs={COMPANY_TABS}
          />

          {activeTab === 'DEFAULT' && <PreApproval />}

          {activeTab === 'APPLICATIONS' && (
            <>
              <CompanySectionTitle>Заявки</CompanySectionTitle>
            </>
          )}

          {activeTab === 'BENEFICIARIES' && <Beneficiaries />}

          {activeTab === 'DOCUMENTS' && (
            <>
              <CompanySectionTitle>Финансовые документы</CompanySectionTitle>
              <Documents
                fin_documents={documents}
                status={statusDocuments}
                error={errorDocuments}
              />
            </>
          )}
        </>
      )}

      <ActionResponseBlock
        showIn={
          errorCompanyByInn &&
          errorCompanyByInn.code === 403 &&
          statusCompanyByInn === REQUEST_STATUSES.ERROR
        }
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={
          errorCompanyByInn &&
          errorCompanyByInn.code === 404 &&
          statusCompanyByInn === REQUEST_STATUSES.ERROR
        }
        template={ACTION_RESPONSE_TEMPLATE.NOT_FOUND}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ SCF, ClientRole }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusCompanyByInn: SCF.getCompanyByInnThirdParty.status,
  errorCompanyByInn: SCF.getCompanyByInnThirdParty.error,
  documents: ClientRole.getFinancialDocuments.data,
  statusDocuments: ClientRole.getFinancialDocuments.status,
  errorDocuments: ClientRole.getFinancialDocuments.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      resetStateCompanyByInn,
      getFinancialDocuments
    },
    dispatch
  );

const CompanyViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyView)
);

export { CompanyViewConnect as CompanyView };
