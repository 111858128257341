import getApplicationsListSaga from './getApplicationsList';
import getApplicationsQuantitySaga from './getApplicationsQuantity';

import { getApplicationSaga, getInBankApplicationSaga } from './getApplication';
import createApplicationSaga from './createApplication';
import createDraftSaga from './createDraft';
import changheApplicationStatusSaga from './changheApplicationStatus';
import deleteApplicationSaga from './deleteApplication';

import prepareForApplicationEditSaga from './prepareForApplicationEdit';
import updateInitializationFormDataSaga from './updateInitializationFormData';
import sendApplicationDataSaga from './sendApplicationData';

import deleteDocumentSaga from './deleteDocument';
import uploadDocumentsSaga from './uploadDocuments';

import getBanksListSaga from './getBanksList';
import sendApplicationToBanksSaga from './sendApplicationToBanks';
import sendInitialAndGuaranteesDataSaga from './sendInitialAndGuaranteesData';

import toggleExternalVisibilitySaga from 'Application/sagas/toggleExternalVisibility';
import getApplicationFlagsSaga from './getApplicationFlags';
import getCompanyHintsSaga from './getCompanyHints';
import getProductApplicationSaga from './getProductApplication';
import postSelectApplicationSaga from './postSelectApplication';
import putSentToBankSaga from './putSendToBank';
import putSetPreapprovedSaga from './putSetPreapproved';
import putSetApprovedSaga from './putSetApproved';
import getActivitiesByApplicationSaga from './getActivitiesByApplication';
import postExternalApiAlfaBankSaga from './postExternalApiAlfaBank';
import postExternalApiPSBSaga from './postExternalApiPSB';
import getFinancialsSummarySaga from './getFinancialsSummary';
import getExternalApiGIR_BOSaga from './getExternalApiGIR_BO';
import putRejectedSaga from './putRejected';
import getOpenLoanLimitsSaga from './getOpenLoanLimits';
import getCompanyByInnSaga from './getCompanyByInn';
import getAllBanksListSaga from './getAllBanksList';
import patchUpdateCompanyBlankSaga from './patchUpdateCompanyBlank';
import getTenderTeledocSaga from './getTenderTeledoc';
import getAddTenderByEruzSaga from './getAddTenderByEruz';
import getTenderByApplicationSaga from './getTenderByApplication';
import getAllOffersSaga from './getAllOffers';
import postApplicationPrescoringSaga from './postApplicationPrescoring';
import postOfferSendSaga from './postOfferSend';
import postCreateTenderSaga from './postCreateTender';
import postAddTenderByIdSaga from './postAddTenderById';
import postDocumentsOfDocumentListSaga from './postDocumentsOfDocumentList';

export default [
  getApplicationsListSaga,
  getInBankApplicationSaga,
  getApplicationsQuantitySaga,

  getApplicationSaga,
  createApplicationSaga,
  createDraftSaga,
  changheApplicationStatusSaga,
  deleteApplicationSaga,

  prepareForApplicationEditSaga,
  updateInitializationFormDataSaga,
  sendApplicationDataSaga,

  deleteDocumentSaga,
  uploadDocumentsSaga,

  getBanksListSaga,
  sendApplicationToBanksSaga,
  sendInitialAndGuaranteesDataSaga,

  toggleExternalVisibilitySaga,
  getApplicationFlagsSaga,

  getCompanyHintsSaga,
  getProductApplicationSaga,
  postSelectApplicationSaga,
  putSentToBankSaga,
  putSetPreapprovedSaga,
  putSetApprovedSaga,
  getActivitiesByApplicationSaga,
  postExternalApiAlfaBankSaga,
  postExternalApiPSBSaga,
  getFinancialsSummarySaga,
  getExternalApiGIR_BOSaga,
  putRejectedSaga,
  getOpenLoanLimitsSaga,
  getCompanyByInnSaga,
  getAllBanksListSaga,
  patchUpdateCompanyBlankSaga,
  getTenderTeledocSaga,
  getAddTenderByEruzSaga,
  getTenderByApplicationSaga,
  getAllOffersSaga,
  postApplicationPrescoringSaga,
  postOfferSendSaga,
  postCreateTenderSaga,
  postAddTenderByIdSaga,
  postDocumentsOfDocumentListSaga
];
