import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import { REJECT_SUBJECT } from 'src/features/Application/types/RejectType';
import { red } from 'shared/styled';
import { CloseButton, OverlayRejectModal } from './styles';

interface StateToProps {}

interface MatchParams {
  id: string;
}

interface AnyProps {
  isVisible: boolean;
  productId: number;
  setIsVisible: (visible: boolean) => void;
  putRejected: (id: number, { rejectSubject, rejectReason }) => void;
}

type Props = Partial<RouteComponentProps<MatchParams>> &
  StateToProps &
  AnyProps;

export const rejectReasons = {
  1: [
    '11 – КЛИЕНТ. Отозвано клиентом',
    '12 – КЛИЕНТ. Не предоставил документы',
    '13 – КЛИЕНТ. Отказ по условиям',
    '14 – КЛИЕНТ. Отказ заключить договор'
  ],
  2: ['21 – АГЕНТ. Отозвано агентом', '22 – АГЕНТ. Отказ по условиям'],
  3: [
    '31 – БАНК. Закреплено за другим агентом',
    '32 – БАНК. Уже клиент банка',
    '33 – БАНК. Не прошел по портрету',
    '34 – БАНК. Не прошел по прескорингу',
    '35 – БАНК. Не прошел на комитете'
  ],
  4: [
    '41 – ПЛАТФОРМА. Отозвано платформой',
    '42 – ПЛАТФОРМА. Не проходит по портрету'
  ]
};

const RejectReasonModal: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  putRejected,
  productId
}) => {
  const [selectedRejectSubject, setSelectedRejectSubject] = React.useState('');
  const [selectedRejectReason, setSelectedRejectReason] = React.useState('');

  const onClick = () => {
    setIsVisible(false);
    setSelectedRejectSubject('');
    setSelectedRejectReason('');

    if (selectedRejectSubject !== '') {
      putRejected(productId, {
        rejectSubject: Number(selectedRejectSubject),
        rejectReason: Number(
          `${Number(selectedRejectSubject)}${Number(selectedRejectReason) + 1}`
        )
      });
    }
  };

  const handleChangeRejectObject = (value: string) => {
    setSelectedRejectSubject(value);
    setSelectedRejectReason('');
  };

  const handleChangeRejectReason = (value: string) => {
    setSelectedRejectReason(value);
  };

  function getFilteredOptions() {
    return Object.entries(rejectReasons)
      .map(([_, value]) => {
        return value.map((item, index) => ({
          id: index.toString(),
          name: item
        }));
      })
      .filter(option => option !== null);
  }

  return (
    <>
      {isVisible && (
        <OverlayRejectModal>
          <div>
            <CloseButton onClick={() => setIsVisible(false)}>
              &times;
            </CloseButton>
            <Select
              options={Object.entries(REJECT_SUBJECT).map(([key, value]) => ({
                id: value,
                name: key
              }))}
              placeholder="Выберите отказавшуюся сторону"
              name="selectedOption"
              value={selectedRejectSubject}
              onChange={e => handleChangeRejectObject(e.currentTarget.value)}
            />
            <Select
              options={
                getFilteredOptions()[
                  selectedRejectSubject === ''
                    ? 0
                    : Number(selectedRejectSubject) - 1
                ]
              }
              placeholder="Выберите причину отказа"
              name="selectedOption"
              value={selectedRejectReason}
              disabled={selectedRejectSubject.length === 0}
              onChange={e => handleChangeRejectReason(e.currentTarget.value)}
            />
            <Button backgroundColor={red} onClick={onClick} label="Отказать" />
          </div>
        </OverlayRejectModal>
      )}
    </>
  );
};

export { RejectReasonModal };
