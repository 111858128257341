import * as React from 'react';

import { FormStyled } from './styles';

interface FormProps {
  className?: string;
  name?: string;
  onSubmit?: () => void;
  autoComplete?: "on" | "off";
  children: any;
}

export class Form extends React.Component<FormProps> {
  static defaultProps = {
    name: 'unknown-form'
  };

  onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const { className, name, autoComplete } = this.props;
    return (
      <FormStyled
        autoComplete={autoComplete}
        className={!!className ? className : ''}
        onSubmit={this.onSubmitHandler}
        name={name}
      >
        {this.props.children}
      </FormStyled>
    );
  }
}
