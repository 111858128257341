import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeletePropertyDocumentStoreState extends Reducer {
}

export enum DELETE_PROPERTY_DOCUMENT {
  REQ = 'DELETE_PROPERTY_DOCUMENT_REQ',
  GOT = 'DELETE_PROPERTY_DOCUMENT_GOT',
  ERR = 'DELETE_PROPERTY_DOCUMENT_ERR'
}

export const initialState: DeletePropertyDocumentStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deletePropertyDocument = (
  state: DeletePropertyDocumentStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): DeletePropertyDocumentStoreState => {
  switch (action.type) {
    case DELETE_PROPERTY_DOCUMENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_PROPERTY_DOCUMENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case DELETE_PROPERTY_DOCUMENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deletePropertyDocument;
