import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'src/features/ExternalCredentials/actions/getCompanyByInn';

export interface GetCompanyByInnStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_COMPANY_BY_INN {
  REQ = 'GET_COMPANY_BY_INN_REQ',
  GOT = 'GET_COMPANY_BY_INN_GOT',
  ERR = 'GET_COMPANY_BY_INN_ERR',
  RESET = 'GET_COMPANY_BY_INN_RESET'
}

export const initialState: GetCompanyByInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    inn: '',
    companyShortName: '',
    companyFullName: '',
    ogrn: '',
    regDate: null,
    kpp: '',
    pfr: '',
    fss: '',
    startCapital: null,
    companyAddress: ''
  },
  error: {}
};

const getCompanyByInn = (
  state: GetCompanyByInnStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyByInnStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_COMPANY_BY_INN.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInn;
