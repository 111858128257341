import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getExternalCredential';

import { GET_EXTERNAL_CREDENTIAL_PLATFORM } from '../reducers/getExternalCredential';
import ProductsApi from '../api';

function* getExternalCredential(action: {
  type: GET_EXTERNAL_CREDENTIAL_PLATFORM;
}): Generator {
  try {
    const res: any = yield call(ProductsApi.getExternalCredential);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalCredentialSaga(): Generator {
  yield takeLatest(GET_EXTERNAL_CREDENTIAL_PLATFORM.REQ, getExternalCredential);
}
