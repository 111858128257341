import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface SendApplicationToBanksDataStoreState extends Reducer {
  data: RequestDataType;
}

export interface RequestDataType {
  id: number;
  body: {
    banks: number[];
  };
}

export enum SEND_APPLICATION_TO_BANKS {
  REQ = 'SEND_APPLICATION_TO_BANKS_REQUEST',
  GOT = 'SEND_APPLICATION_TO_BANKS_GOT',
  ERR = 'SEND_APPLICATION_TO_BANKS_ERROR'
}

export const initialState: SendApplicationToBanksDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: null,
    body: {
      banks: []
    }
  },
  error: {}
};

const sendApplicationToBanks = (
  state: SendApplicationToBanksDataStoreState = initialState,
  action: {
    type: SEND_APPLICATION_TO_BANKS;
    data: RequestDataType;
    error: {};
  }
): SendApplicationToBanksDataStoreState => {
  switch (action.type) {
    case SEND_APPLICATION_TO_BANKS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case SEND_APPLICATION_TO_BANKS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_APPLICATION_TO_BANKS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendApplicationToBanks;
