import styled from 'styled-components';
import { Form } from 'shared/ui/Form';
import { rb } from 'shared/styled';

export const ForgotPasswordModalStyled = styled.div`
  min-width: 490px;
  min-height: 250px;

  padding: 0 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 24px 0;

    opacity: 0.7;
    line-height: 1.71;
    text-align: center;

    b {
      opacity: 1;
      font-family: ${rb};
    }
  }
`;

export const ForgotPasswordForm = styled(Form)`
  min-width: 370px;
`;
