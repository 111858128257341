export interface StatusesFilterData {
  statuses: string[];
}

export enum FILTER_STATUSES_ACTIONS {
  ADD = 'ADD_FILTER_STATUSES_DATA',
  REMOVE = 'REMOVE_FILTER_STATUSES_DATA'
}

export const initialState: StatusesFilterData = {
  statuses: []
};

const updateStatusesFilter = (
  state: StatusesFilterData = initialState,
  action: {
    type: FILTER_STATUSES_ACTIONS;
    status: string;
    index: number;
  }
): StatusesFilterData => {
  switch (action.type) {
    case FILTER_STATUSES_ACTIONS.ADD:
      const combined = [...state.statuses, action.status];
      return {
        ...state,
        statuses: combined
      };
    case FILTER_STATUSES_ACTIONS.REMOVE:
      const filtered = state.statuses.filter((value,index) => index !== action.index);
      return {
        ...initialState,
        statuses: filtered
      };
    default:
      return state;
  }
};

export default updateStatusesFilter;
