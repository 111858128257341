import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/ProductsForBank/actions/getProduct';

import { GET_PRODUCT_BANK } from 'src/features/ProductsForBank/reducers/getProduct';
import ProductsApi from '../api';

function* getProductBank(action: {
  type: GET_PRODUCT_BANK;
  id: number;
}): Generator {
  try {
    const res: any = yield call(ProductsApi.getProduct, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductBankSaga(): Generator {
  yield takeLatest(GET_PRODUCT_BANK.REQ, getProductBank);
}
