import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'Application/actions/getExternalApiGIR_BO';

export interface GetExternalApiGIR_BOStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_EXTERNAL_API_GIR_BO {
  REQ = 'GET_EXTERNAL_API_GIR_BO_REQ',
  GOT = 'GET_EXTERNAL_API_GIR_BO_GOT',
  ERR = 'GET_EXTERNAL_API_GIR_BO_ERR'
}

export const initialState: GetExternalApiGIR_BOStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getExternalApiGIR_BO = (
  state: GetExternalApiGIR_BOStoreState = initialState,
  action: {
    type: GET_EXTERNAL_API_GIR_BO;
    data: any;
    error: {};
  }
): GetExternalApiGIR_BOStoreState => {
  switch (action.type) {
    case GET_EXTERNAL_API_GIR_BO.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EXTERNAL_API_GIR_BO.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EXTERNAL_API_GIR_BO.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getExternalApiGIR_BO;
