import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface ApproveEarlyPaymentApplicationDebtorRoleStoreState
  extends Reducer {
  data: any[];
}

export enum APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE {
  REQ = 'APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_REQ',
  GOT = 'APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_GOT',
  ERR = 'APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_ERR',
  RESET = 'APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE_RESET'
}

export interface RequestDataType {
  guid: string | string[];
  body: {
    debtorInspectorComment: string;
  };
}

export const initialState: ApproveEarlyPaymentApplicationDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const approveEarlyPaymentApplicationDebtorRole = (
  state: ApproveEarlyPaymentApplicationDebtorRoleStoreState = initialState,
  action: {
    type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE;
    data: { success: boolean }[];
    error: {};
  }
): ApproveEarlyPaymentApplicationDebtorRoleStoreState => {
  switch (action.type) {
    case APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default approveEarlyPaymentApplicationDebtorRole;
