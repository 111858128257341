import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getCompaniesList';

export interface GetCompaniesListCRMStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_COMPANIES_LIST_CRM {
  REQ = 'GET_COMPANIES_LIST_CRM_REQ',
  GOT = 'GET_COMPANIES_LIST_CRM_GOT',
  ERR = 'GET_COMPANIES_LIST_CRM_ERR'
}

export const initialState: GetCompaniesListCRMStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getCompaniesListCRM = (
  state: GetCompaniesListCRMStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetCompaniesListCRMStoreState => {
  switch (action.type) {
    case GET_COMPANIES_LIST_CRM.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANIES_LIST_CRM.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANIES_LIST_CRM.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompaniesListCRM;
