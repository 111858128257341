import { GET_SUPPLIER_LIST } from '../reducers/getSupplierList';
import { SupplierType } from 'src/features/UserSuppliers/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: SupplierType[];
}

export interface RequestData {
  page: number;
  pageSize: number;
}

export const req = (data: RequestData) => ({
  type: GET_SUPPLIER_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_SUPPLIER_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_SUPPLIER_LIST.ERR,
  error
});
