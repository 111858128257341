import { GET_ALL_OF_BANK_BANK_ADMIN } from 'entities/Cabinet/Bank/model/reducers/getAllOfBank';

export interface ResponseData {
  email: string;
  phoneNumber: string;
  emailConfirmed: boolean;
  normalizedUserName: string;
  firstName: string;
  lastName: string;
  isBlocked: boolean;
  lastLoginAt: string;
  createdDateTime: string;
  userGuid: string;
  permissions: string[];
}

export const req = () => ({
  type: GET_ALL_OF_BANK_BANK_ADMIN.REQ
});

export const got = (data: ResponseData[]) => ({
  type: GET_ALL_OF_BANK_BANK_ADMIN.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_OF_BANK_BANK_ADMIN.ERR,
  error
});
