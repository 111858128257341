import { call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/postDocumentsFormFill';
import { POST_DOCUMENTS_FORM_FILL } from 'entities/Cabinet/InternalAgent/model/reducers/postDocumentsFormFill';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* postDocumentsFormFill(action: {
  type: POST_DOCUMENTS_FORM_FILL;
  guid: string;
  companyInn: string;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentRoleApi.postDocumentsFormFill,
      action.guid,
      action.companyInn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDocumentsFormFillSaga(): Generator {
  yield takeEvery(POST_DOCUMENTS_FORM_FILL.REQ, postDocumentsFormFill);
}
