import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { CertificatesFormStyled, AddButton } from './styles';
import { CertificatesList } from './components/CertificatesList/CertificatesList';

import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';

interface StateToProps {}

interface DispatchToProps {}

type Props = StateToProps & DispatchToProps;

class CertificatesForm extends React.Component<Props> {
  componentDidMount(): void {}

  addButtonClick = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/certificates/edit');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/certificates/edit');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/certificates/edit');
    }
  };

  render() {
    return (
      <CertificatesFormStyled>
        <CertificatesList />
        <AddButton onClick={this.addButtonClick}>
          <p>+ Добавить</p>
        </AddButton>
      </CertificatesFormStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const CertificatesFormConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CertificatesForm);

export { CertificatesFormConnect as CertificatesForm };
