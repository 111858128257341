import styled from 'styled-components';
import {
  blue,
  darkestBlue,
  gray,
  lightGray,
  lighterBlue,
  rr
} from 'shared/styled';

export const TabsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 15px 0 35px;
`;

export const Tab = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  background-color: ${({ isSubMenu, active }) =>
    isSubMenu && active ? `${lighterBlue}` : ''};
  :not(:last-child) {
    margin-right: 50px;
  }

  p {
    cursor: pointer;
    padding: ${({ isSubMenu }) => (isSubMenu ? '7px 35px' : '')};
    border-radius: ${({ isSubMenu }) => (isSubMenu ? '5px' : '')};
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: ${({ active }) => (active ? blue : gray)};
    :hover {
      color: ${darkestBlue};
      background-color: ${({ isSubMenu }) =>
        isSubMenu ? `${lighterBlue}` : ''};
    }

    &:after {
      position: absolute;
      font-weight: 400;
      font-size: 12px;
      content: '${({ count }) => count}';
      top: -10px;
      right: -15px;
    }
  }
`;

export const OtherMenu = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  p,
  svg {
    cursor: pointer;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: ${gray};
  }

  :hover {
    p,
    svg {
      color: ${darkestBlue};
    }
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 10px;
  top: 100%;
  right: 2%;
  margin-top: 25px;
`;

export const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
`;

export const CircleBackground = styled.circle`
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 4;
`;

export const CircleProgress = styled.circle`
  fill: none;
  stroke: #3498db;
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s ease;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  width: fit-content;
  cursor: pointer;

  &:hover:before {
    content: '${({ text }) => text}';
    font-family: ${rr};
    position: absolute;
    width: max-content;
    bottom: 25px;
    left: 100%;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;
