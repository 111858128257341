import { REQUEST_STATUSES, Reducer } from 'src/globaltypes';
import { ExternalCredentialsData } from '../actions/setExternalCredentialFormData';

export interface SetExternalCredentialDataStoreState extends Reducer {}

export enum SET_EXTERNAL_CREDENTIAL_DATA {
  SET = 'SET_EXTERNAL_CREDENTIAL_DATA_SET',
  RESET = 'SET_EXTERNAL_CREDENTIAL_DATA_RESET',
  GOT = 'SET_EXTERNAL_CREDENTIAL_DATA_GOT',
  ERR = 'SET_EXTERNAL_CREDENTIAL_DATA_ERR'
}

export const initialState: SetExternalCredentialDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const setExternalCredentialFormData = (
  state: SetExternalCredentialDataStoreState = initialState,
  action: {
    type: string;
    data: ExternalCredentialsData;
    error: {};
  }
): SetExternalCredentialDataStoreState => {
  switch (action.type) {
    case SET_EXTERNAL_CREDENTIAL_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_EXTERNAL_CREDENTIAL_DATA.RESET:
      return {
        ...initialState
      };
    case SET_EXTERNAL_CREDENTIAL_DATA.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SET_EXTERNAL_CREDENTIAL_DATA.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default setExternalCredentialFormData;
