import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE } from 'globaltypes';
import {
  ApplicationTypes,
  FederalDebtors,
  InternationalDebtors,
  DebtorsType
} from 'shared/constants';

import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';

import { TRANSITION_DIRECTIONS } from 'Common';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  DEBTOR_TYPE,
  PLEDGE_TYPE,
  FactoringApplicationWrite
} from 'Application/types';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { MultiSelect } from 'shared/ui/MultiSelect';
import { MultiInput } from 'shared/ui/MultiInput';
import { Input } from 'shared/ui/Input';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationInputsColumn,
  ApplicationFormContent,
  ApplicationForm,
  ApplicationBtnsWrapper
} from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps extends SetFactoringApplicationType {
  internalApplication: ResInternalApplication;
}

interface DispatchToProps {
  setFactoringFormData: (data: SetFactoringApplicationType) => void;
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class FactoringFormStep2 extends React.Component<Props> {
  Validator = createValidaton();

  navigateFromTo = (
    from: string,
    to: string,
    transition: TRANSITION_DIRECTIONS
  ) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS[transition]
    });

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const debtorsTypeChanged = name === 'debtorType';

    this.props.setFactoringFormData({
      [name]: value,
      debtors: debtorsTypeChanged ? [] : this.props.debtors
    } as { [key in keyof FactoringApplicationWrite]: any });
  };

  onMultiAdd = (debtorName: string) => {
    const isDebtorSame =
      this.props.debtors.map(item => item.name).indexOf(debtorName) !== -1;

    !isDebtorSame &&
      this.props.setFactoringFormData({
        debtors: [
          ...this.props.debtors,
          {
            name: debtorName,
            limit: 0,
            postponement: 1,
            pledgeType: PLEDGE_TYPE.NO_REGRESS
          }
        ]
      });
  };

  onMultiRemove = () => {
    const newDebtors = [...this.props.debtors];
    this.props.setFactoringFormData({ debtors: newDebtors });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();

    if (!this.Validator.isFormValid()) {
      return;
    }

    this.props.debtors.length > 0
      ? this.navigateFromTo(
          this.props.location.pathname,
          'limits',
          TRANSITION_DIRECTIONS.FORWARD
        )
      : this.navigateFromTo(
          this.props.location.pathname,
          'documents',
          TRANSITION_DIRECTIONS.FORWARD
        );
  };

  render() {
    const { internalApplication, debtorType, debtors, openModal } = this.props;

    const RadioChildrenProps = {
      label: 'Дебиторы',
      name: 'debtors',
      value: '',
      selected: debtors.map(debtor => debtor.name),
      onMultiAdd: this.onMultiAdd,
      onMultiRemove: this.onMultiRemove
    };

    const RadioChildren = {
      [DEBTOR_TYPE.FEDERAL]: {
        component: (
          <MultiSelect
            {...RadioChildrenProps}
            options={FederalDebtors}
            placeholder="Выберите дебиторов"
          />
        )
      },
      [DEBTOR_TYPE.INTERNATIONAL]: {
        component: (
          <MultiSelect
            {...RadioChildrenProps}
            options={InternationalDebtors}
            placeholder="Выберите дебиторов"
          />
        )
      },
      [DEBTOR_TYPE.OTHER]: {
        component: (
          <MultiInput
            {...RadioChildrenProps}
            placeholder="Напишите дебиторов (нажимте Enter чтобы выбрать)"
          />
        )
      }
    };

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>
          Выбор дебиторов / покупателей
        </ApplicationStepTitle>

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="factoring-application-form-step-1"
            >
              <Input
                value={ApplicationTypes[internalApplication.financingType]}
                name="applicationType"
                label="Тип заявки"
                disabled={true}
              />

              <RadioGroup
                name="debtorType"
                label="Тип дебиторов"
                required={true}
                keyValue={debtorType}
                onChange={this.onChange}
                radioBtns={Object.keys(DebtorsType).map(type => ({
                  value: type,
                  label: DebtorsType[type],
                  children: RadioChildren[type].component
                }))}
              />

              <ApplicationBtnsWrapper>
                <Button
                  template="backBtn"
                  label="Назад"
                  onClick={() =>
                    this.navigateFromTo(
                      this.props.location.pathname,
                      'options',
                      TRANSITION_DIRECTIONS.BACKWARDS
                    )
                  }
                />
                <Button template="nextBtn" label="Продолжить" type="submit" />
              </ApplicationBtnsWrapper>
            </ApplicationForm>
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application, InternalAgentRole }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  debtorType: Application.setFactoringFormData.debtorType,
  debtors: Application.setFactoringFormData.debtors
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setFactoringFormData, sendApplicationDataReq, openModal },
    dispatch
  );

const FactoringFormStep2Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep2)
);

export { FactoringFormStep2Connect as FactoringFormStep2 };
