import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from '../api';

import { got, err } from './../actions/getBankAccount';
import { BANK_ACCOUNT_ACTIONS } from './../reducers/getBankAccount';

function* getBankAccountData(action: { type: BANK_ACCOUNT_ACTIONS }) {
  try {
    const res: any = yield call(SCFApi.getBankAccount);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBankAccountDataSaga() {
  yield takeLatest(BANK_ACCOUNT_ACTIONS.REQ, getBankAccountData);
}
