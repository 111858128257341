export enum GET_DOWNLOAD_DOCUMENT_BY_BANK {
  REQ = 'GET_DOWNLOAD_DOCUMENT_BY_BANK_REQ',
  GOT = 'GET_DOWNLOAD_DOCUMENT_BY_BANK_GOT',
  ERR = 'GET_DOWNLOAD_DOCUMENT_BY_BANK_ERR'
}

export interface ResponseData {
  fileStream: {
    canRead: boolean;
    canSeek: boolean;
    canWrite: boolean;
    capacity: number;
    length: number;
    position: number;
    canTimeout: boolean;
  };
}

export const req = (guid: string, docGuid: string) => ({
  type: GET_DOWNLOAD_DOCUMENT_BY_BANK.REQ,
  guid,
  docGuid
});

export const got = (data: ResponseData) => ({
  type: GET_DOWNLOAD_DOCUMENT_BY_BANK.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_DOWNLOAD_DOCUMENT_BY_BANK.ERR,
  error
});
