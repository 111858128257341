import * as React from 'react';
import { CRMComponent } from '../EachCRM';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { SectionSeparatorStyled } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { AGENT_LIST_TYPE } from 'src/features/Agents/types';
import { AgentList } from 'src/features/Agents/components/AgentsList/AgentsList';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const AgentsSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Агенты</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: AgentList,
      path: '/crm/agents/all',
      label: 'Организации',
      icon: <FontAwesomeIcon icon={faSuitcase} fixedWidth={true} />,
      listType: AGENT_LIST_TYPE
    })
  }
];
