import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  AddSuppliersPageStyled,
  DescriptionArea,
  Description,
  InputArea,
  InputTitle,
} from './styles';

import { PageStyled, CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';

import { AddBtn, AddBtnText } from 'src/features/SCF/UI/ButtonsStyles';
import { STORE } from 'src/globaltypes';

import { req as sendSuppliers } from 'src/features/SCF/actions/sendSuppliers';
import { RequestDataType as SendSuppliersDataType } from 'src/features/SCF/reducers/sendSuppliers';

interface StateToProps {

}

type OwnState = {
  innList: string
}

interface DispatchToProps {
  sendSuppliers: (data: SendSuppliersDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class SuppliersAddPage extends React.Component<Props, OwnState> {
  constructor(props) {
    super(props)
    this.state = {
      innList: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ innList: event.target.value })
  }
  onAddBuyersClick = () => {
    this.props.sendSuppliers({
      supplierInnList: this.state.innList
    })
    history.replace('/debtor/suppliers?page=1');
  }

  render() {
    return (
      <PageStyled>
        <h2>Добавить поставщиков</h2>
        <DescriptionArea>
          <Description>Можно ввести не более 1000 покупателей (ИНН) за 1 запрос.<br></br>Разделителем может быть любой нечисловой символ.</Description>
        </DescriptionArea>
        <InputArea>
          <InputTitle>Список поставщиков (ИНН):</InputTitle>
          <textarea
            value={this.state.innList}
            placeholder='1111111111, 2222222222, 3333333333, ...&#10;&#10;или&#10;&#10;1111111111&#10;2222222222&#10;3333333333'
            onChange={this.handleChange}
          />
        </InputArea>
        {this.state.innList !== '' ? this.state.innList.length > 110000 ?
          <AddBtn style={{ backgroundColor: '#FF0000', cursor: 'not-allowed' }}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn>
          :
          <AddBtn onClick={this.onAddBuyersClick}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn> :
          <AddBtn style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn>
        }
      </PageStyled>

    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendSuppliers,
    },
    dispatch
  );

const SuppliersAddConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SuppliersAddPage)
);

export { SuppliersAddConnect as SuppliersAddPage };
