import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Activities/actions/getParticipantsOfActivity';

export interface GetParticipantsOfActivityStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_PARTICIPANTS_OF_ACTIVITY {
  REQ = 'GET_PARTICIPANTS_OF_ACTIVITY_REQ',
  GOT = 'GET_PARTICIPANTS_OF_ACTIVITY_GOT',
  ERR = 'GET_PARTICIPANTS_OF_ACTIVITY_ERR'
}

export const initialState: GetParticipantsOfActivityStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getParticipantsOfActivity = (
  state: GetParticipantsOfActivityStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetParticipantsOfActivityStoreState => {
  switch (action.type) {
    case GET_PARTICIPANTS_OF_ACTIVITY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PARTICIPANTS_OF_ACTIVITY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PARTICIPANTS_OF_ACTIVITY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getParticipantsOfActivity;
