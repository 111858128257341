import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSlidersH,
  faSitemap,
  faLink,
  faClipboard,
  faListAlt
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { CRMComponent } from '../EachCRM';
import { FACTORING_LIMIT_LIST_TYPE } from 'src/features/FactoringLimits/types';
import { DEBTOR_LIST_TYPE } from 'src/features/Debtors/types';
import { SectionSeparatorStyled } from '../styles';
import { FactoringLimitsList } from 'src/features/FactoringLimits/components/FactoringLimitsList/FactoringLimitsList';
import { DebtorsList } from 'src/features/Debtors/components/DebtorsList/DebtorsList';
import { BuyersToSupplierLink } from 'src/features/BuyersToSupplierLink/components/BuyersToSupplierLink';
import { SupplyContract } from 'src/features/SupplyContracts/components/SupplyContract/SupplyContract';
import { FactoringContract } from 'src/features/FactoringContracts/components/FactoringContract/FactoringContract';
import { MonetaryClaimsList } from 'src/features/MonetaryClaims/components/MonetaryClaims';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const DebtorsSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Дебиторы</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: FactoringLimitsList,
      path: '/crm/factoring_limits/all',
      label: 'Факторинговые лимиты',
      icon: <FontAwesomeIcon icon={faSlidersH} fixedWidth={true} />,
      listType: FACTORING_LIMIT_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: DebtorsList,
      path: '/crm/debtors/all',
      label: 'Дебиторы',
      icon: <FontAwesomeIcon icon={faSitemap} fixedWidth={true} />,
      listType: DEBTOR_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: BuyersToSupplierLink,
      path: '/crm/buyers_to_suppliers_links',
      label: 'Связки Дебитор - Поставщик',
      icon: <FontAwesomeIcon icon={faLink} fixedWidth={true} />,
      listType: DEBTOR_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: SupplyContract,
      path: '/crm/supply_contracts',
      label: 'Договоры поставки',
      icon: <FontAwesomeIcon icon={faClipboard} fixedWidth={true} />,
      listType: DEBTOR_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: FactoringContract,
      path: '/crm/factoring_contracts',
      label: 'Договоры факторинга',
      icon: <FontAwesomeIcon icon={faClipboard} fixedWidth={true} />,
      listType: DEBTOR_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: MonetaryClaimsList,
      path: '/crm/monetary_claims',
      label: 'Денежные требования',
      icon: <FontAwesomeIcon icon={faListAlt} fixedWidth={true} />,
      listType: DEBTOR_LIST_TYPE.ALL
    })
  }
];
