import { ApplicationRead } from 'Application/types';

export enum CREDITING_PLEDGE {
  HAS_PLEDGE = 'HAS_PLEDGE',
  NO_PLEDGE = 'NO_PLEDGE'
}

export enum CREDITING_GUARANTOR {
  HAS_GUARANTOR = 'HAS_GUARANTOR',
  NO_GUARANTOR = 'NO_GUARANTOR'
}

export enum CREDITING_CREDIT_TYPE {
  CREDIT = 'CREDIT',
  OVERDRAFT = 'OVERDRAFT',
  REVOLVING_CREDIT = 'REVOLVING_CREDIT'
}

export enum CREDITING_PLEDGE_TYPE {
  REAL_ESTATE = 'REAL_ESTATE',
  MOVABLE_ESTATE = 'MOVABLE_ESTATE',
  OTHER = 'OTHER'
}

export enum CREDITING_GUARANTOR_TYPE {
  BENEFICIARY = 'BENEFICIARY',
  COMPANIES = 'COMPANIES'
}

export interface CreditingApplicationWrite {
  creditingType: CREDITING_CREDIT_TYPE;
  beneficiaryGuarantee: boolean;
  companiesGuarantee: boolean;
  movableEstate: boolean;
  otherEstate: '';
  overdraft: boolean;
  realEstate: boolean;
  revolvingCredit: boolean;
  guarantee: boolean;
  credit: boolean;

  pledge: CREDITING_PLEDGE;
  pledges: CREDITING_PLEDGE_TYPE[];
  otherPledgeEstate: string;

  guarantor: CREDITING_GUARANTOR;
  guarantors: CREDITING_GUARANTOR_TYPE[];
}

export type CreditingApplicationRead = ApplicationRead &
  CreditingApplicationWrite;
