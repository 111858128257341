import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Users/actions/getAllUsersForExternalAgent';

export interface GetAllUsersForExternalAgentStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ALL_USERS_FOR_EXTERNAL_AGENT {
  REQ = 'GET_ALL_USERS_FOR_EXTERNAL_AGENT_REQ',
  GOT = 'GET_ALL_USERS_FOR_EXTERNAL_AGENT_GOT',
  ERR = 'GET_ALL_USERS_FOR_EXTERNAL_AGENT_ERR'
}

export const initialState: GetAllUsersForExternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getAllUsersForExternalAgent = (
  state: GetAllUsersForExternalAgentStoreState = initialState,
  action: {
    type: GET_ALL_USERS_FOR_EXTERNAL_AGENT;
    data: any;
    error: {};
  }
): GetAllUsersForExternalAgentStoreState => {
  switch (action.type) {
    case GET_ALL_USERS_FOR_EXTERNAL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ALL_USERS_FOR_EXTERNAL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ALL_USERS_FOR_EXTERNAL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAllUsersForExternalAgent;
