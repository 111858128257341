import { POST_ADD_TO_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/postAddToContractByDebtor';

export const req = (contractAccount: string, guid: string) => ({
  type: POST_ADD_TO_CONTRACT_BY_DEBTOR.REQ,
  guid,
  contractAccount
});

export const got = () => ({
  type: POST_ADD_TO_CONTRACT_BY_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_TO_CONTRACT_BY_DEBTOR.ERR,
  error
});
