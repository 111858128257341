import styled from 'styled-components';
import {
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/CooperationForProducts/components/styled';

export const ToggleButtonContainerUser = styled(ToggleButtonContainer)``;

export const ToggleButtonNoUser = styled(ToggleButtonNo)`
  cursor: pointer;
`;

export const ToggleButtonYesUser = styled(ToggleButtonYes)`
  cursor: pointer;
`;

export const RoleAndPermission = styled.p`
  margin-left: 10px;
  margin-bottom: 35px;

  p {
    font-weight: 700;
    color: black;
  }
`;
