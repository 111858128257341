import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import { BankManagerForm } from 'Permissions/AddBankManagers/components/BankManagerForm/BankManagerForm';
import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { ResponseData as GetApplicationsQuantityDataType } from 'Application/reducers/getApplicationsQuantity';

import { Sidebar } from 'Layouts/components';

import { ApiKeyBlock } from 'src/features/ApiKeys/components/ApiKeyBlock';
import { NewClient } from 'src/features/Clients/components/NewClient/NewClient';
import { ClientView } from 'src/features/Clients/components/ClientView/ClientView';
import { NewProduct } from 'src/features/ProductsForBank/components/NewProduct';
import { Profile } from 'src/features/Profile/components/Profile';
import { ProductsForCabinetBankView } from 'src/features/ProductsForBank/components/ProductsForCabinetBankView';
import { ApplicationCreate, ApplicationView } from 'Application/components';
import { ApplicationEdit } from 'Application/components';
import { CabinetSection } from 'shared/styled';
import { CreatedMeSection } from './Sections/CreatedMe';
import { BankManagerSection } from './Sections/BankManager';
import { CRMSection } from './Sections/CRM';
import { AdministrationSection } from './Sections/Administration';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';

import { GuaranteesView } from './Pages/ApplicationView/GuaranteesView/GuaranteesView';
import { ContractFinancingView } from './Pages/ApplicationView/ContractFinancingView/ContractFinancingView';
import { CreditingView } from './Pages/ApplicationView/CreditingView/CreditingView';
import { FactoringView } from './Pages/ApplicationView/FactoringView/FactoringView';
import { Button } from 'shared/ui/Button';

export interface CabinetComponent {
  path?: string;
  whenToActivate?: boolean;
  hasPermission: (permissions: USER_PERMISSIONS[]) => boolean;
  component?: any;
  render?: (location: any) => JSX.Element;
  renderSidebarLink?: (key: any) => JSX.Element;
  userInn?: string;
}

interface OwnProps {
  cabinetLists?: APPLICATIONS_LIST_TYPE[];
}

interface StateToProps {
  applicationQuantity: GetApplicationsQuantityDataType;
  applicationQuantityStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  userCompanyInn: string;
  canCreateApplication: boolean;
}

interface DispatchToProps {
  getApplicationsQuantity: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps & OwnProps;

class EachPage extends React.Component<Props> {
  componentDidMount() {
    const { getApplicationsQuantity } = this.props;
    getApplicationsQuantity();
  }

  render() {
    const { permissions, canCreateApplication, history } = this.props;

    const componentsList: CabinetComponent[] = [
      {
        path: '/cabinet/bank/apikeys',
        render: location => <ApiKeyBlock />,
        hasPermission: () => true
      },
      {
        path: '/cabinet/bank/addBankManager',
        hasPermission: permissions =>
          permissions.includes(USER_PERMISSIONS.ADD_BANK_MANAGERS),
        renderSidebarLink: key => (
          <Button
            label="Добавить менеджера банка"
            key={key}
            template="addBtn"
            backgroundColor="#1ca9c9"
            h="49px"
            disabled={
              history.location.pathname.indexOf('/addBankManager') !== -1
            }
            onClick={() => history.push('/cabinet/bank/addBankManager')}
          />
        ),
        render: location => <BankManagerForm />
      },
      {
        path: '/cabinet/bank/application-form/create',
        component: ApplicationCreate,
        hasPermission: permissions =>
          permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS),
        renderSidebarLink: key => (
          <Button
            key={key}
            label="Создать заявку"
            template="addBtn"
            disabled={
              history.location.pathname.indexOf('/application-form') !== -1
            }
            onClick={() =>
              history.push('/cabinet/bank/application-form/create')
            }
            w="100%"
            h="50px"
          />
        )
      },

      {
        hasPermission: permissions =>
          [USER_PERMISSIONS.EXTERNAL_AGENT].isIn(permissions),
        path: '/cabinet/bank/clients/add',
        render: () => <NewClient />
      },

      {
        hasPermission: permissions =>
          [USER_PERMISSIONS.EXTERNAL_AGENT, USER_PERMISSIONS.BANK_MANAGER].isIn(
            permissions
          ),
        path: '/cabinet/bank/clients/inn/:inn/:tab?',
        render: () => <ClientView />
      },

      {
        path: '/cabinet/bank/application-form/edit/:id/:inBankId?',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <ApplicationEdit />
      },

      ...AdministrationSection,
      ...BankManagerSection,
      ...CreatedMeSection,
      ...CRMSection
    ];

    return (
      <React.Fragment>
        <Sidebar>
          {componentsList.map(
            (component, key) =>
              component.hasPermission(permissions) &&
              component.renderSidebarLink &&
              component.renderSidebarLink(key)
          )}
        </Sidebar>

        <CabinetSection>
          <Switch>
            {canCreateApplication && (
              <Route
                path="/cabinet/bank/application-form/create"
                component={ApplicationCreate}
              />
            )}

            {componentsList.map(
              (component, key) =>
                component.path &&
                component.hasPermission(permissions) && (
                  <Route
                    key={key}
                    path={component.path}
                    render={
                      component.render
                        ? ({ location }) => component.render(location)
                        : null
                    }
                    component={component.component}
                  />
                )
            )}

            <Route
              exact={true}
              path="/cabinet/bank/application/:id/:inBankId?"
              component={ApplicationView}
            />

            <Route
              exact={true}
              path="/cabinet/bank/application-guarantees/:id"
              component={GuaranteesView}
            />

            <Route
              exact={true}
              path="/cabinet/bank/application-contract-financing/:id"
              component={ContractFinancingView}
            />

            <Route
              exact={true}
              path="/cabinet/bank/application-crediting/:id"
              component={CreditingView}
            />

            <Route
              exact={true}
              path="/cabinet/bank/application-factoring/:id"
              component={FactoringView}
            />

            <Route
              exact={true}
              path="/cabinet/bank/products/new"
              component={() => <NewProduct />}
            />

            <Route
              exact={true}
              path="/cabinet/bank/products/:id/:tab?"
              component={() => <ProductsForCabinetBankView />}
            />

            <Route
              exact={true}
              path="/cabinet/bank/profile"
              component={() => <Profile />}
            />
          </Switch>
        </CabinetSection>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ Application, User }: STORE) => ({
  applicationQuantity: Application.getApplicationsQuantity.data,
  applicationQuantityStatus: Application.getApplicationsQuantity.status,
  permissions: User.getUserData.data.permissions,
  userCompanyInn: User.getUserData.data.companyInn,
  canCreateApplication: User.getUserData.data.permissions.includes(
    USER_PERMISSIONS.CREATE_APPLICATIONS
  )
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getApplicationsQuantity }, dispatch);

const EachPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachPage)
);

export { EachPageConnect as EachPage };
