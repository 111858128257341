import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  PropertyRead,
  PROPERTY_SUB_TYPES,
  PROPERTY_TYPES
} from 'src/features/Properties/types/Property';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { InputRange, INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { PropertySubTypes, PropertyTypes } from 'shared/constants';
import { LighterBlueContainer } from 'shared/styled/layouts';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import { req as updateProperty } from 'Properties/actions/updateProperty';
import { req as uploadPropertyDocuments } from 'Properties/actions/uploadPropertyDocuments';
import { req as deletePropertyDocument } from 'Properties/actions/deletePropertyDocument';
import { PropertyDocumentsRequestData } from 'Properties/reducers/uploadPropertyDocuments';
import { AutocompleteInput } from 'shared/ui/AutocompleteInput';
import { completions } from './completions';
import Popup from './Popup';
import {
  FileRow,
  FileRowLabelContainer
} from 'src/features/BorrowerCompanies/components/BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/styles';
import {
  BigLabelWithTopMargin,
  LargerCrossBtn
} from 'src/shared/ui/InputSingleFile/styles';
import ExampleDocument from 'src/features/BorrowerCompanies/components/BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/ExampleDocument/ExampleDocument';
import { createValidation } from './validator';
import { updateLocalProperty } from 'Properties/actions/localProperties';
import { PullRight } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ProfileInfoPopup/styles';
import { req as deleteProperty } from 'Properties/actions/deleteProperty';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  documentsUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  updateProperty: (property: Partial<PropertyRead>) => void;
  uploadPropertyDocuments: (data: PropertyDocumentsRequestData) => void;
  deletePropertyDocument: (fileId: number, propertyId: number) => void;
  updateLocalProperty: (property: Partial<PropertyRead>) => void;
  deleteProperty: (propertyId: number) => void;
}

interface OwnProps {
  property?: Partial<PropertyRead>;
  onSave?: () => void;
  onDelete?: () => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

// TODO refactor
class EditProperty extends React.Component<Props> {
  Validator = createValidation();
  /* state: Partial<PropertyRead> = {
  *       type: PROPERTY_TYPES.NONE,
  *       subType: PROPERTY_SUB_TYPES.NONE,
  *       price: 0,
  *       region: "",
  *       address: "",
  *       hasEvaluation: false,
  *       documents: []
  *   }; */

  save = () => {
    const {
      type,
      subType,
      region,
      address,
      price,
      hasEvaluation,
      isRented,
      id
    } = this.props.property;
    this.props.updateProperty({
      id,
      type,
      subType,
      region,
      address,
      price,
      hasEvaluation,
      isRented
    });
    this.props.onSave();
  };

  updateLocalProperty = (fields: any) => {
    this.props.updateLocalProperty({ id: this.props.property.id, ...fields });
  };

  // TODO extract
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber = name === 'price';
    const isBoolean = name === 'hasEvaluation' || name === 'isRented';
    const isCheckbox = type === 'checkbox';
    const floated = toFloatFormatter(value);
    this.updateLocalProperty({
      [name]: isCheckbox
        ? checked
        : isToNumber
          ? floated
          : isBoolean
            ? JSON.parse(value)
            : value
    });
  };

  componentWillMount() {
    this.Validator.setInitialValues(this.props.property);
    this.Validator.showAllErrors();
  }

  // TODO duplication extract
  findDocumentByMetaInfo = (metaInfo: string) => {
    if (this.props.property.documents)
      return this.props.property.documents.find(
        doc => doc.metaInfo === metaInfo
      );
  };

  onFileUploadWithMetaInfo = (metaInfo: string, file) => {
    file.metaInfo = metaInfo;
    this.onFileUpload(file);
  };

  // TODO refactor
  onFileUpload = file => {
    const { uploadPropertyDocuments } = this.props;
    uploadPropertyDocuments({
      propertyId: this.props.property.id,
      files: [file]
    });
  };

  onFileRemove = file => {
    const { deletePropertyDocument } = this.props;
    deletePropertyDocument(file.id, this.props.property.id);
  };

  render() {
    // TODO extract or put defaults inside inputrange
    const amountRange = {
      min: 0,
      max: 3000000000
    };
    const {
      type,
      subType,
      region,
      address,
      price,
      hasEvaluation,
      isRented
    } = this.props.property;

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max]
    }).validate({ ...this.props.property });

    return (
      <LighterBlueContainer>
        <PullRight>
          <LargerCrossBtn
            onClick={() => {
              this.props.deleteProperty(this.props.property.id);
              this.props.onDelete();
            }}
          />
        </PullRight>
        <p>Сведения об объекте недвижимости</p>
        <RadioButtonGroup
          name="type"
          keyValue={type}
          error={errors.type}
          onChange={this.onChange}
          radioBtns={Object.keys(PropertyTypes).map(type => ({
            value: type,
            label: PropertyTypes[type]
          }))}
        />
        {type !== PROPERTY_TYPES.NONE && (
          <>
            {type === PROPERTY_TYPES.RESIDENTIAL_PROPERTY && (
              <RadioButtonGroup
                name="subType"
                keyValue={subType}
                error={errors.subType}
                onChange={this.onChange}
                radioBtns={Object.keys(PropertySubTypes)
                  .without([
                    PROPERTY_SUB_TYPES.OFFICE,
                    PROPERTY_SUB_TYPES.PRODUCTION_BUILDINGS,
                    PROPERTY_SUB_TYPES.RETAIL_SPACE,
                    PROPERTY_SUB_TYPES.WAREHOUSE,
                    PROPERTY_SUB_TYPES.HOTEL
                  ])
                  .map(type => ({
                    value: type,
                    label: PropertySubTypes[type]
                  }))}
              />
            )}
            {type === PROPERTY_TYPES.COMMERCIAL_PROPERTY && (
              <RadioButtonGroup
                name="subType"
                error={errors.subType}
                keyValue={subType}
                onChange={this.onChange}
                radioBtns={Object.keys(PropertySubTypes)
                  .without([
                    PROPERTY_SUB_TYPES.FLAT,
                    PROPERTY_SUB_TYPES.LAND,
                    PROPERTY_SUB_TYPES.HOUSE,
                    PROPERTY_SUB_TYPES.TOWN_HOUSE
                  ])
                  .map(type => ({
                    value: type,
                    label: PropertySubTypes[type]
                  }))}
              />
            )}
            <AutocompleteInput
              label="Регион"
              completions={completions}
              required={true}
              onChange={this.onChange}
              error={errors.region}
              name="region"
              value={region}
            />

            <Input
              value={address}
              error={errors.address}
              label="Адрес местонахождения объекта"
              name="address"
              required={true}
              onChange={this.onChange}
            />

            <InputRange
              value={price.toFixed(2)}
              error={errors.price}
              label="Примерная стоимость объекта"
              name="price"
              placeholder="Введите сумму"
              step={1000}
              min={amountRange.min}
              max={amountRange.max}
              sliderType={RANGE_SLIDER_TYPE.SUM}
              valueType={INPUT_RANGE_VALUE_TYPE.SUM}
              disableSlider={true}
              required={true}
              onChange={this.onChange}
            />
            <RadioButtonGroup
              name="hasEvaluation"
              label="Имеется отчет об оценке?"
              required={true}
              error={errors.hasEvaluation}
              keyValue={hasEvaluation}
              onChange={this.onChange}
              radioBtns={[
                {
                  value: true,
                  label: 'Да'
                },
                {
                  value: false,
                  label: 'Нет'
                }
              ]}
            />
            {hasEvaluation && (
              <FileRow>
                <FileRowLabelContainer>
                  <BigLabelWithTopMargin label="Отчет об оценке" />
                </FileRowLabelContainer>
                <InputSingleFile
                  required={true}
                  renameFileTo="Отчет_об_оценке"
                  file={this.findDocumentByMetaInfo('evaluation')}
                  uploadStatus={this.props.documentsUploadStatus}
                  onFileUpload={file =>
                    this.onFileUploadWithMetaInfo('evaluation', file)
                  }
                  onFileRemove={this.onFileRemove}
                />
              </FileRow>
            )}

            {type === PROPERTY_TYPES.COMMERCIAL_PROPERTY &&
              hasEvaluation && (
                <RadioButtonGroup
                  name="isRented"
                  label="Сдается в аренду"
                  error={errors.isRented}
                  required={true}
                  keyValue={isRented}
                  onChange={this.onChange}
                  radioBtns={[
                    {
                      value: true,
                      label: 'Да'
                    },
                    {
                      value: false,
                      label: 'Нет'
                    }
                  ]}
                />
              )}

            {isRented && (
              <FileRow>
                <FileRowLabelContainer>
                  <BigLabelWithTopMargin label="Реестр арендаторов" />
                  <ExampleDocument
                    name={'Скачать ФОРМУ реестра'}
                    link={'#'}
                    size={39}
                  />
                </FileRowLabelContainer>
                <InputSingleFile
                  renameFileTo="Реестр_Арендаторов"
                  file={this.findDocumentByMetaInfo('renters')}
                  uploadStatus={this.props.documentsUploadStatus}
                  onFileUpload={file =>
                    this.onFileUploadWithMetaInfo('renters', file)
                  }
                  onFileRemove={this.onFileRemove}
                />
              </FileRow>
            )}
            <p>Документы по объекту недвижимости:</p>
            <FileRow>
              <FileRowLabelContainer>
                <BigLabelWithTopMargin
                  label="Свидетельство о праве собственности или Выписка ЕГРН"
                  required={true}
                />
              </FileRowLabelContainer>
              <InputSingleFile
                required={true}
                renameFileTo="Право_Собственности"
                file={this.findDocumentByMetaInfo('egrn')}
                error={errors.documents}
                uploadStatus={this.props.documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('egrn', file)
                }
                onFileRemove={this.onFileRemove}
              />
            </FileRow>
            <FileRow>
              <FileRowLabelContainer>
                <BigLabelWithTopMargin
                  label={
                    <div>
                      Документы-основания <br />
                      <Popup
                        text="Что это?"
                        popupText={
                          <div>
                            Документы, подтверждающие права на объект
                            недвижимости.
                            <br />
                            Например, Договор купли-продажи.
                            <br />
                            Документы-основания указаны в Свидетельстве и
                            Выписке ЕГРН <br />
                          </div>
                        }
                      />
                    </div>
                  }
                />
              </FileRowLabelContainer>
              <InputSingleFile
                required={true}
                renameFileTo="Документы_основания"
                file={this.findDocumentByMetaInfo('foundation_documents')}
                uploadStatus={this.props.documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('foundation_documents', file)
                }
                onFileRemove={this.onFileRemove}
              />
            </FileRow>

            <Button
              label="Готово"
              disabled={!this.Validator.isFormValid()}
              onClick={this.save}
            />
          </>
        )}
      </LighterBlueContainer>
    );
  }
}

const mapStateToProps = ({ Properties }: STORE) => ({
  documentsUploadStatus: Properties.uploadPropertyDocuments.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteProperty,
      updateProperty,
      updateLocalProperty,
      deletePropertyDocument,
      uploadPropertyDocuments
    },
    dispatch
  );

const EditPropertyConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(EditProperty);

export { EditPropertyConnect as EditProperty };
