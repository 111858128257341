import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { toFloatFormatter } from 'src/shared/utils/Utils';

import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { STORE, REQUEST_STATUSES, ResponseError, CRM } from 'globaltypes';
import { ApplicationTypes, InnPlaceholders } from 'shared/constants';

import { APPLICATION_TYPES, FactoringApplicationRead } from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  req as getCompanyExternal,
  ResponseData as ResCompanyExternal
} from 'entities/Cabinet/ExternalAgent/model/actions/getCompanyExternal';
import {
  req as createNewClient,
  RequestData as ReqNewClient
} from 'src/features/Clients/actions/postNewClient';
import {
  req as getClientsList,
  ResponseData as ClientsData,
  RequestData as ReqClientsList
} from 'src/features/Clients/actions/getClientsList';
import {
  req as sendApplicationData,
  reset as resetApplicationData
} from 'Application/actions/sendApplicationData';
import { RequestDataType as sendApplicationDataReq } from 'Application/reducers/sendApplicationData';

import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationFormContent,
  ApplicationInputsColumn,
  ApplicationForm,
  ClientList,
  InputContainer,
  ModalContainer,
  PopupContentClients,
  TableRow
} from './styled';
import { FactoringChecker } from 'src/features/FactoringChecker/components/FactoringChecker';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { PopupContainer } from 'src/features/Layouts/components/InnPopup/styled';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { Pagination } from 'src/features/Layouts/components';
import { ClientsListType } from 'src/features/Clients/types';
import { Button } from 'shared/ui/Button';

interface State {
  isInfoPopup: boolean;
  clientsIsVisible: boolean;
  code: string;
  clientCompanyName: string;
  selectedRow: number;
  amount: number;
  term: number;
}

interface StateToProps extends Partial<FactoringApplicationRead> {
  roles: string[];
  userCompanyInn: string;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  clients: ClientsData;
  statusClients: REQUEST_STATUSES;
  statusInternalApplication: REQUEST_STATUSES;
  statusSendApplication: REQUEST_STATUSES;
  companyExternal: ResCompanyExternal;
}

interface DispatchToProps {
  sendApplicationData: (data: sendApplicationDataReq) => void;
  resetApplicationData: () => void;
  openModal: (data: OpenModalDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getClientsList: (data?: ReqClientsList) => void;
  getCompanyExternal: (inn: string) => void;
  createNewClient: (data: ReqNewClient) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class FactoringFormStep1 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    isInfoPopup: false,
    clientsIsVisible: false,
    code: '',
    clientCompanyName: '',
    selectedRow: null,
    term: 0,
    amount: 0
  };

  clientsModalRef = React.createRef();

  componentDidMount() {
    if (this.props.statusInternalApplication === REQUEST_STATUSES.GOT) {
      this.setState({
        code: this.props.code,
        clientCompanyName: this.props.clientCompanyName,
        amount: this.props.amount,
        term: this.props.term
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.code !== this.state.code) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.code !== this.state.code &&
      this.state.code &&
      this.state.code.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.code });
    }
    if (
      this.props.statusSendApplication !== prevProps.statusSendApplication &&
      this.props.statusSendApplication === REQUEST_STATUSES.GOT
    ) {
      this.props.resetApplicationData();
    }
  }

  componentWillUnmount(): void {
    this.props.resetApplicationData();

    this.setState({
      isInfoPopup: false,
      clientsIsVisible: false,
      code: '',
      clientCompanyName: '',
      selectedRow: null,
      term: 0,
      amount: 0
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber =
      name === 'amount' ||
      name === 'term' ||
      name === 'startPrice' ||
      name === 'sumFinal';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (name === 'code' && sanitizedValue.length > 12) {
      return;
    } else if (name === 'code' && sanitizedValue.length <= 12) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
      const floated = toFloatFormatter(sanitizedValue);

      this.setState({
        [name]: isToNumber ? floated : name === 'code' ? sanitizedValue : value
      } as any);
    } else {
      const floated = toFloatFormatter(value);

      this.setState({
        [name]: isCheckbox ? checked : isToNumber ? floated : value
      } as any);
    }
  };

  onSubmit = () => {
    this.Validator.showAllErrors();

    if (!this.isFormValid()) return;

    this.props.sendApplicationData({
      id: +this.props.match.params.id,
      code: this.state.code,
      clientCompanyName: this.state.clientCompanyName,
      amount: this.state.amount,
      term: this.state.term
    });

    this.navigateFromTo(this.props.location.pathname, 'debtors');
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
    this.setState({
      clientCompanyName: this.props.companyByInn.companyShortName
    });
    this.props.getCompanyExternal(this.state.code);
    this.props.createNewClient({
      companyinn: this.state.code,
      companyshortname: this.props.companyByInn.companyShortName
        ? this.props.companyByInn.companyShortName
        : this.state.clientCompanyName
    });
  };

  handleOpenModal = () => {
    this.setState({ clientsIsVisible: true });
    this.props.getClientsList();
  };

  handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.clientsModalRef.current &&
      !(this.clientsModalRef.current as Element).contains(target)
    ) {
      this.setState({ clientsIsVisible: false });
    }
  };

  handleSelectClient = (client: ClientsListType, index: number) => {
    this.setState({
      selectedRow: index,
      code: client.companyINN,
      clientCompanyName: client.companyName
    });
  };

  isFormValid = (): boolean => {
    const { code, clientCompanyName, amount, term } = this.state;

    return !!code && !!clientCompanyName && !!amount && !!term;
  };

  render() {
    const {
      openModal,
      financingType,
      statusInn,
      companyByInn,
      code: inn
    } = this.props;

    const { amount, code, clientCompanyName, term } = this.state;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    const amountRange = {
      min: 0,
      max:
        this.props.location.state &&
        this.props.location.state.from === `/cabinet/${role}/preapproved_limits`
          ? this.props.location.state.amount
          : 100000000000
    };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max]
    }).validate({ ...this.state });

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        {!!inn ? (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        ) : (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="initialize-application-form"
            >
              <Select
                options={Object.keys(ApplicationTypes).map(type => ({
                  id: type,
                  name: ApplicationTypes[type]
                }))}
                value={financingType}
                label="Тип продукта"
                name="financingType"
                placeholder="Выберите тип продукта"
                required={true}
                disabled={true}
                error={errors.financingType}
                onChange={this.onChange}
              />

              <PopupContainer>
                <InputContainer>
                  <Input
                    value={code}
                    label="ИНН"
                    name="code"
                    placeholder={InnPlaceholders.entity}
                    required={true}
                    error={errors.code}
                    onChange={this.onChange}
                  />

                  {this.props.roles.includes('ExternalAgent') && (
                    <Button
                      label="Выбрать из моих клиентов"
                      onClick={this.handleOpenModal}
                    />
                  )}
                </InputContainer>

                {this.props.code &&
                this.props.code.length < 10 ? null : statusInn ===
                REQUEST_STATUSES.ERROR ? (
                  <InnPopup>
                    <h2>
                      Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                      можете создать связку с введенным ИНН
                    </h2>
                  </InnPopup>
                ) : (
                  !this.state.isInfoPopup && (
                    <InnPopup
                      companyInfo={companyByInn}
                      onPopupClick={this.onPopupClick}
                    />
                  )
                )}
              </PopupContainer>

              <Input
                value={clientCompanyName}
                label="Компания заемщика"
                name="clientCompanyName"
                placeholder="Введите краткое наименование клиента"
                required={true}
                error={errors.clientCompanyName}
                onChange={this.onChange}
              />

              <InputRange
                value={amount.toFixed(2)}
                label="Сумма"
                name="amount"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                required={true}
                error={errors.amount}
                onChange={this.onChange}
              />

              <InputRange
                value={term.toString()}
                label="Срок"
                name="term"
                placeholder="Введите срок"
                min={1}
                max={10 * 12}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                disabled={
                  this.props.location.state &&
                  this.props.location.state.from ===
                    `/cabinet/${role}/preapproved_limits`
                }
                disableSlider={
                  this.props.location.state &&
                  this.props.location.state.from ===
                    `/cabinet/${role}/preapproved_limits`
                }
                error={errors.term}
                onChange={this.onChange}
              />

              <Button
                type="submit"
                label="Настроить параметры заявки"
                template="nextBtn"
              />
            </ApplicationForm>
          </ApplicationInputsColumn>
          {financingType === APPLICATION_TYPES.FACTORING && (
            <ApplicationInputsColumn>
              <FactoringChecker />
            </ApplicationInputsColumn>
          )}
        </ApplicationFormContent>

        <ModalContainer
          visible={this.state.clientsIsVisible}
          onClick={this.handleOutsideClick}
        >
          <PopupContentClients ref={this.clientsModalRef}>
            <h2>Выберите компанию или ИП для заявки</h2>

            {this.props.statusClients === REQUEST_STATUSES.GOT && (
              <ClientList>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="50%">Наименование</TableThStyled>
                      <TableThStyled width="50%">ИНН</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {this.props.clients.items.map((client, index) => (
                      <TableRow
                        key={index}
                        isSelected={this.state.selectedRow === index}
                        onClick={() => this.handleSelectClient(client, index)}
                      >
                        <td>{client.companyName}</td>
                        <td>{client.companyINN}</td>
                      </TableRow>
                    ))}
                  </tbody>
                </TableStyled>
              </ClientList>
            )}
            <Pagination list={this.props.clients} />
            <Button
              label="Выбрать клиента"
              disabled={!this.state.code && !this.state.clientCompanyName}
              onClick={() => {
                this.setState({ clientsIsVisible: false });
                this.setState({
                  code: this.state.code,
                  clientCompanyName: this.state.clientCompanyName
                });
              }}
            />
          </PopupContentClients>
        </ModalContainer>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({
  Application,
  ExternalAgentRole,
  User,
  SCF,
  Clients
}: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  userCompanyInn: User.getUserData.data.companyInn,
  roles: User.getUserData.data.roles,
  clients: Clients.getClientsList.data,
  statusClients: Clients.getClientsList.status,
  statusSendApplication: Application.sendApplicationData.status,
  ...ExternalAgentRole.getApplicationExternal.data,
  statusInternalApplication: ExternalAgentRole.getApplicationExternal.status,
  companyExternal: ExternalAgentRole.getCompanyExternal.data,
  createStatus: Clients.postNewClient.status,
  createError: Clients.postNewClient.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationData,
      resetApplicationData,
      openModal,
      getCompanyByInn,
      getClientsList,
      getCompanyExternal,
      createNewClient
    },
    dispatch
  );

const FactoringFormStep1Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep1)
);

export { FactoringFormStep1Connect as FactoringFormStep1 };
