import { BorrowerCompany } from 'Application/types';
import {
  RequestDataType,
  GET_BORROWER_COMPANIES_LIST
} from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';

export const req = (data: RequestDataType) => ({
  type: GET_BORROWER_COMPANIES_LIST.REQ,
  data
});

export const got = (data: BorrowerCompany[]) => ({
  type: GET_BORROWER_COMPANIES_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_BORROWER_COMPANIES_LIST.ERR,
  error
});
