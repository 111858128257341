import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import {
  APPLICATION_STATUSES,
  ContractFinancingRead,
  CreditingApplicationRead
} from 'Application/types';
import { COMMENT_TYPES } from 'Comments/types';

import { req as getCommentsList } from 'Comments/actions/getCommentsList';
import { req as postComment } from 'Comments/actions/postComment';
import { req as postReply } from 'Comments/actions/postReply';
import { RequestDataType as GetCommentsListRequestDataType } from 'Comments/reducers/getCommentsList';
import { RequestDataType as PostCommentRequestDataType } from 'Comments/reducers/postComment';
import { RequestDataType as PostReplyRequestDataType } from 'Comments/reducers/postReply';

import { Comments, IPostComment, IPostReply } from 'Comments/components';
import { Tabs, Spinner } from 'Layouts/components';

import {
  clientWithAgent,
  agentWithClient,
  bankWithAgent
} from './commentDisableConditions';

import { CommentsContainer, LoaderContainerStyled } from './styles';

interface StateToProps {
  clientApplication: Partial<ContractFinancingRead>;
  commentsListStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getCommentsList: (data: GetCommentsListRequestDataType) => void;
  postComment: (data: PostCommentRequestDataType) => void;
  postReply: (data: PostReplyRequestDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CommentTabs: React.FC<Props> = ({
  clientApplication,
  postComment,
  postReply,
  getCommentsList,
  commentsListStatus,
  permissions,
  match
}) => {
  const getCommentsListHandler = (type: COMMENT_TYPES) => {
    getCommentsList({
      id: +match.params.id,
      type
    });
  };

  const postCommentHandler = ({ type, files, comment }: IPostComment) => {
    postComment({
      id: +match.params.id,
      type,
      files,
      body: { text: comment }
    });
  };

  const postReplyHandler = ({ type, files, comment, parentId }: IPostReply) => {
    postReply({
      id: parentId,
      applicationId: +match.params.id,
      type,
      files,
      body: { text: comment }
    });
  };

  const tabsConstructor = () => {
    if (permissions.includes(USER_PERMISSIONS.BANK_MANAGER))
      return [
        {
          label: 'Комментарий аналитику платформы FINFACTORY',
          type: COMMENT_TYPES.WITH_BANK
        }
      ];
    if (
      permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) ||
      permissions.includes(USER_PERMISSIONS.SEE_ALL_APPLICATIONS)
    )
      return clientApplication.isAuthor ||
        clientApplication.mainApplicationId !== 0
        ? [
            {
              label: 'Комментарий банку',
              type: COMMENT_TYPES.WITH_BANK
            }
          ]
        : [
            {
              label: 'Комментарий клиенту',
              type: COMMENT_TYPES.WITH_CLIENT
            },
            {
              label: 'Комментарий банку',
              type: COMMENT_TYPES.WITH_BANK
            }
          ];
    if (permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS))
      return clientApplication.mainApplicationId === 0
        ? [
            {
              label: 'Комментарий аналитику платформы FINFACTORY',
              type: COMMENT_TYPES.WITH_CLIENT
            }
          ]
        : [];
    return [];
  };

  const disableComments = (type: COMMENT_TYPES) => {
    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
      if (type === COMMENT_TYPES.WITH_CLIENT) {
        return agentWithClient(clientApplication.status);
      }
    } else if (permissions.includes(USER_PERMISSIONS.SEE_ALL_APPLICATIONS))
      return true;
    if (permissions.includes(USER_PERMISSIONS.BANK_MANAGER))
      return bankWithAgent(clientApplication.status);

    if (permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS))
      return clientWithAgent(clientApplication.status);

    return false;
  };

  const tabs = tabsConstructor(); // TODO refactor

  if (tabs.length === 0) {
    return null;
  }

  React.useEffect(
    () => {
      const listType = tabs[0].type;
      getCommentsListHandler(listType);
    },
    [match.params.id]
  );

  return (
    clientApplication.status !== APPLICATION_STATUSES.DRAFT && (
      <CommentsContainer>
        <Tabs
          tabs={tabs.map(({ label, type }) => ({
            label,
            component: (
              <Comments
                disabled={disableComments(type)}
                postComment={data =>
                  postCommentHandler({
                    ...data,
                    type
                  })
                }
                postReply={postReplyHandler}
              />
            ),
            onTabClick: () => getCommentsListHandler(type)
          }))}
        />

        {commentsListStatus === REQUEST_STATUSES.REQUEST && (
          <LoaderContainerStyled>
            <Spinner size={50} />
          </LoaderContainerStyled>
        )}
      </CommentsContainer>
    )
  );
};

const mapStateToProps = ({ ClientRole, Comment, User }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data,
  commentsListStatus: Comment.getCommentsList.status,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCommentsList, postComment, postReply }, dispatch);

const CommentTabsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CommentTabs)
);

export { CommentTabsConnect as CommentTabs };
