import { SET_CERTIFICATE_DATA } from '../reducers/setCertificate';

export interface CertificateWrite {
  companyInn?: string;
  companyOgrnOrOgrnIP?: string;
  companyName_O?: string;
  companyName_CN?: string;
  companyCountryC?: string;
  companyRegionS?: string;
  companyLocationL?: string;
  companyAddressStreet?: string;
  positionT?: string;
  personSurNameSN?: string;
  personNameG?: string;
  personInn?: string;
  personEmailE?: string;
  personSnils?: string;
  certValidFrom?: Date;
  certValidUntil?: Date;
  keyValidFrom?: Date;
  keyValidUntil?: Date;
  issuerInn?: string;
  issuerOGRN?: string;
  issuerName_O?: string;
  issuerName_CN?: string;
  issuerTypeOU?: string;
  issuerCountryC?: string;
  issuerRegionS?: string;
  issuerLocationL?: string;
  issuerAddressStreet?: string;
  issuerEmailE?: string;
  serialNumber?: string;
}

export interface CertificateData extends Partial<CertificateWrite> { }

export const setCertificateData = (data: CertificateData) => ({
  type: SET_CERTIFICATE_DATA.SET,
  data
});

export const resetCertificateData = () => ({
  type: SET_CERTIFICATE_DATA.RESET
});
