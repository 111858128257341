import { combineReducers } from 'redux';
import getLimits, { GetLimitsStoreState } from './getLimits';
import putOpenLoanLimitsSetActive, {
  PutOpenLoanLimitsSetActiveStoreState
} from './putOpenLoanLimitsSetActive';
import putOpenLoanLimitsSetInActive, {
  PutOpenLoanLimitsSetInActiveStoreState
} from './putOpenLoanLimitsSetInactive';

export interface LimitsForBankReducers {
  getLimits: GetLimitsStoreState;
  putOpenLoanLimitsSetActive: PutOpenLoanLimitsSetActiveStoreState;
  putOpenLoanLimitsSetInActive: PutOpenLoanLimitsSetInActiveStoreState;
}

export const LimitsForBank = combineReducers({
  getLimits,
  putOpenLoanLimitsSetActive,
  putOpenLoanLimitsSetInActive
});
