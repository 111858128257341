import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { history } from 'src/shared/utils/History';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ContainerInputs,
  ErrorMsg,
  InputDetail,
  WrapperInput,
  WrapperInputDetail
} from 'src/features/Contacts/components/CreateNewContact/styled';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as createActivities,
  reset as resetActivitiesState
} from '../../actions/createActivities';
import {
  req as getActivitiesType,
  ResponseData
} from '../../actions/getActivitiesType';
import {
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/Activities/components/CreateActivities/styled';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'src/shared/ui/Button';

interface State {
  companyinn: string;
  companyName: string;
  result: string;
  comment: string;
  applicationId: string;
  leadId: string;
  extComment: string;
  activityTypeId: number;
  isInfoPopup: boolean;
  showToAgent: boolean;
  errors: {
    companyinn: string;
    companyName: string;
    result: string;
    comment: string;
    applicationId: string;
    leadId: string;
    extComment: string;
  };
}

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  createStatus: REQUEST_STATUSES;
  activitiesTypes: ResponseData;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  createActivities: (data: any) => void;
  getActivitiesType: () => void;
  resetActivitiesState: () => void;
  resetStateCompanyByInn: () => void;
}

type Props = StateToProps & DispatchToProps;

class CreateActivities extends React.Component<Props, State> {
  state: State = {
    companyinn: '',
    companyName: '',
    result: '',
    comment: '',
    applicationId: '',
    extComment: '',
    leadId: '',
    activityTypeId: 1,
    isInfoPopup: false,
    showToAgent: false,
    errors: {
      companyinn: '',
      companyName: '',
      result: '',
      comment: '',
      applicationId: '',
      extComment: '',
      leadId: ''
    }
  };

  componentDidMount() {
    this.props.getActivitiesType();

    if (JSON.parse(localStorage.getItem('applicationState'))) {
      const { applicationCode, inn } = JSON.parse(
        localStorage.getItem('applicationState')
      );

      if (applicationCode && applicationCode.length) {
        this.setState({ companyinn: inn ? inn : '', leadId: applicationCode });
        localStorage.removeItem('applicationState');
      }
    }

    if (history.location.state && history.location.state.inn) {
      this.setState({ companyinn: history.location.state.inn });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetActivitiesState();
      history.push(`/crm/companies/${this.state.companyinn}/activities`);
    }
    if (prevState.companyinn !== this.state.companyinn) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.companyinn !== this.state.companyinn &&
      this.state.companyinn.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.companyinn });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopup !== this.state.isInfoPopup
    ) {
      this.setState({ companyName: this.props.companyByInn.companyShortName });
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value } as any);
  };

  handleCreateActivities = event => {
    event.preventDefault();

    const innRegex = /^[a-zA-Z0-9]+$/;

    const errors: State['errors'] = {
      companyinn: '',
      companyName: '',
      result: '',
      comment: '',
      applicationId: '',
      extComment: '',
      leadId: ''
    };

    if (
      this.state.companyinn !== null &&
      !innRegex.test(this.state.companyinn)
    ) {
      errors.companyinn = 'Введите корректный ИНН';
    }

    if (this.state.extComment.length < 10 && this.state.showToAgent) {
      errors.extComment = 'Комментарий короче 10 символов';
    }

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, isInfoPopup, ...data } = this.state;
      this.props.createActivities(data);
    }
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  handleButtonClick = (key: keyof State, value: boolean) => {
    this.setState({ [key]: value } as Pick<any, keyof State>);
  };

  renderToggleButton = (buttonName: keyof State, description?: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          type="button"
          onClick={() => this.handleButtonClick(buttonName, false)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          type="button"
          onClick={() => this.handleButtonClick(buttonName, true)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  render() {
    const { companyByInn, statusInn } = this.props;
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание активности</ApplicationTitle>

        <ContainerInputs onSubmit={this.handleCreateActivities}>
          <WrapperInput>
            <WrapperInputDetail>
              <p>ИНН:</p>
              <InputDetail
                name="companyinn"
                value={this.state.companyinn}
                onChange={this.handleInputChange}
              />

              {this.state.companyinn &&
              this.state.companyinn.length < 10 ? null : statusInn ===
              REQUEST_STATUSES.ERROR ? (
                <InnPopup>
                  <h2>
                    Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                    можете создать связку с введенным ИНН
                  </h2>
                </InnPopup>
              ) : (
                statusInn === REQUEST_STATUSES.GOT &&
                !this.state.isInfoPopup && (
                  <InnPopup
                    companyInfo={companyByInn}
                    onPopupClick={this.onPopupClick}
                  />
                )
              )}
            </WrapperInputDetail>

            {this.state.errors.companyinn && (
              <ErrorMsg>{this.state.errors.companyinn}</ErrorMsg>
            )}

            <WrapperInputDetail>
              <p>Наименование:</p>
              <InputDetail
                name="companyName"
                value={this.state.companyName}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>

            {this.state.errors.companyName && (
              <ErrorMsg>{this.state.errors.companyName}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Тип активности:</p>
              <Select
                options={this.props.activitiesTypes.items.map(type => ({
                  id: type.id.toString(),
                  name: type.name
                }))}
                placeholder="Тип активности"
                name="activityTypeId"
                value={this.state.activityTypeId.toString()}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            <WrapperInputDetail>
              <div>
                <p>Результат:</p>
                <span>Отображается в списке активностей</span>
              </div>
              <Textarea
                value={this.state.result}
                name="result"
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>

            {this.state.errors.result && (
              <ErrorMsg>{this.state.errors.result}</ErrorMsg>
            )}

            <WrapperInputDetail>
              <div>
                <p>Комментарий:</p>
                <span>Дополнительная информация при необходимости</span>
              </div>
              <Textarea
                value={this.state.comment}
                name="comment"
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>

            {this.state.errors.comment && (
              <ErrorMsg>{this.state.errors.comment}</ErrorMsg>
            )}

            <Button type="submit" label="Создать" w="100px" />
          </WrapperInput>
          <WrapperInput>
            <WrapperInputDetail>
              <div>
                <p>Заявка:</p>
              </div>
              <Textarea
                value={this.state.applicationId}
                name="applicationId"
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>

            <WrapperInputDetail>
              <div>
                <p>Лид:</p>
              </div>
              <Textarea
                value={this.state.leadId}
                name="leadId"
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>

            <WrapperInputDetail>
              <div>
                <p>Показывать агенту:</p>
              </div>
              {this.renderToggleButton('showToAgent')}
            </WrapperInputDetail>

            {this.state.showToAgent && (
              <WrapperInputDetail>
                <div>
                  <p>Комментарий для агента:</p>
                  <span>Агент увидит этот комментарий</span>
                </div>
                <Textarea
                  value={this.state.extComment}
                  name="extComment"
                  onChange={this.handleInputChange}
                />
              </WrapperInputDetail>
            )}
            {this.state.errors.extComment && (
              <ErrorMsg>{this.state.errors.extComment}</ErrorMsg>
            )}
          </WrapperInput>
        </ContainerInputs>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ SCF, Activities }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  createStatus: Activities.createActivities.status,
  activitiesTypes: Activities.getActivitiesType.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      createActivities,
      getActivitiesType,
      resetActivitiesState,
      resetStateCompanyByInn
    },
    dispatch
  );

const CreateActivitiesConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateActivities);

export { CreateActivitiesConnect as CreateActivities };
