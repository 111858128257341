import { call, put, takeLatest } from 'redux-saga/effects';


import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getFactoringContractFactorRoleByGuid';
import { GET_FACTORING_CONTRACT_FACTOR_ROLE_BY_GUID } from 'entities/SCF/Factor/model/reducers/getFactoringContractFactorRoleByGuid';
import SCFApi from 'entities/SCF/Factor/api';

function* getFactoringContractFactorRoleByGuid(action: {
  type: GET_FACTORING_CONTRACT_FACTOR_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getFactoringContractFactorRoleByGuid, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractFactorRoleByGuidSaga() {
  yield takeLatest(
    GET_FACTORING_CONTRACT_FACTOR_ROLE_BY_GUID.GET_FACTORING_CONTRACT_FACTOR_ROLE_BY_GUID_REQ,
    getFactoringContractFactorRoleByGuid
  );
}
