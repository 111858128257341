import getFinancialStatementsSaga from './getFinancialStatements';
import getFinancialStatementsAllSaga from './getFinancialStatementsAll';
import getNewFinancialStatementsAllSaga from './getNewFinancialStatementsAll';
import postFinancialStatementsAllSaga from './postFinancialStatementsAll';
import getExternalAgentPeriodListSaga from './getExternalAgentPeriodList';
import getExternalAgentSelectPeriodSaga from './getExternalAgentSelectPeriod';
import postExternalAgentFinancialStatementsAllSaga from './postExternalAgentFinancialStatementsAll';
import getFinancialQualitySaga from './getFinancialQuality';

export default [
  getFinancialStatementsSaga,
  getFinancialStatementsAllSaga,
  getNewFinancialStatementsAllSaga,
  postFinancialStatementsAllSaga,
  getExternalAgentPeriodListSaga,
  getExternalAgentSelectPeriodSaga,
  postExternalAgentFinancialStatementsAllSaga,
  getFinancialQualitySaga
];
