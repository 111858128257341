import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/putCertificateFill';

import {
  PUT_CERTIFICATE_FILL,
  RequestDataType
} from 'src/features/SCF/reducers/putCertificateFill';

import SCFApi from 'src/features/SCF/api';

function* putCertificateFill(action: {
  type: PUT_CERTIFICATE_FILL;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.putCertificateFill, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putCertificateFillSaga() {
  yield takeLatest(PUT_CERTIFICATE_FILL.REQ, putCertificateFill);
}
