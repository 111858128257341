import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendSupplyContract';

export interface SendSupplyContractStoreState extends Reducer { }

export enum SEND_SUPPLY_CONTRACT {
  REQ = 'SEND_SUPPLY_CONTRACT_REQ',
  GOT = 'SEND_SUPPLY_CONTRACT_GOT',
  ERR = 'SEND_SUPPLY_CONTRACT_ERR'
}

export const initialState: SendSupplyContractStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendSupplyContract = (
  state: SendSupplyContractStoreState = initialState,
  action: {
    type: string;
    data: RequestData
    error: {};
  }
): SendSupplyContractStoreState => {
  switch (action.type) {
    case SEND_SUPPLY_CONTRACT.REQ:
      return {
        ...state,
        ...action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_SUPPLY_CONTRACT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case SEND_SUPPLY_CONTRACT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendSupplyContract;
