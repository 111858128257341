import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { CompanyInfoRead } from '../actions/setCompanyInfo';

export interface GetCompanyInfoStoreState extends Reducer {
  data: CompanyInfoRead;
}

export enum GET_COMPANY_INFO {
  REQ = 'GET_COMPANY_INFO_REQ',
  GOT = 'GET_COMPANY_INFO_GOT',
  ERR = 'GET_COMPANY_INFO_ERR'
}

export const initialState: GetCompanyInfoStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: 0,
    inn: null,
    companyShortName: null,
    companyFullName: null,
    kpp: null,
    companyPhone: null,
    modifiedDateTime: null,
    companyAddress: null,
    companyEmail: null
  },
  error: {}
};

const getCompanyInfo = (
  state: GetCompanyInfoStoreState = initialState,
  action: {
    type: GET_COMPANY_INFO;
    data: CompanyInfoRead;
    error: {};
  }
): GetCompanyInfoStoreState => {
  switch (action.type) {
    case GET_COMPANY_INFO.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_INFO.GOT:

      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_INFO.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyInfo;
