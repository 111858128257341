import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface postAllInListRefreshStoreState extends Reducer {
  id: string;
}

export enum POST_ALL_IN_LIST_REFRESH {
  REQ = 'POST_ALL_IN_LIST_REFRESH_REQ',
  GOT = 'POST_ALL_IN_LIST_REFRESH_GOT',
  ERR = 'POST_ALL_IN_LIST_REFRESH_ERR'
}

export const initialState: postAllInListRefreshStoreState = {
  status: REQUEST_STATUSES.NONE,
  id: null,
  error: {}
};

const postAllInListRefresh = (
  state: postAllInListRefreshStoreState = initialState,
  action: {
    type: string;
    id: string;
    error: {};
  }
): postAllInListRefreshStoreState => {
  switch (action.type) {
    case POST_ALL_IN_LIST_REFRESH.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ALL_IN_LIST_REFRESH.GOT:
      return {
        ...state,
        id: action.id,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ALL_IN_LIST_REFRESH.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAllInListRefresh;
