import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import {
  RequsitesFormInputDescriptionStyled,
  RequsitesFormInputItemStyled,
  RequsitesFormStyled,
  ChangeButton
} from './styles';
import { req as getCompanyInfo } from './../../../../../actions/getCompanyInfo';
import { CompanyInfoRead } from './../../../../../actions/setCompanyInfo';
import { req as getCompanyInn } from './../../../../../actions/getCompanyInn';
import { ResponseDataType as GetCompanyInnResponseDataType } from './../../../../../reducers/getCompanyInn';
import './styles/style.css';
import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';
import './styles/style.css';

interface StateToProps {
  companyInfo: CompanyInfoRead;
  companyInn: GetCompanyInnResponseDataType;
}

interface DispatchToProps {
  getCompanyInfo: () => void;
  getCompanyInn: () => void;
}

type Props = StateToProps & DispatchToProps;

class RequisitesForm extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getCompanyInfo();
    this.props.getCompanyInn();
  }

  requisitesEditOnClick = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/edit');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/edit');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/edit');
    }
  };

  render() {
    return (
      <RequsitesFormStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            Полное наименование
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="Full name"
            disabled
            placeholder={this.props.companyInfo.companyFullName}
          />
        </RequsitesFormInputItemStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            Краткое Наименование
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="Short name"
            disabled
            placeholder={this.props.companyInfo.companyShortName}
          />
        </RequsitesFormInputItemStyled>
        {this.props.companyInfo.companyNameEng && (
          <RequsitesFormInputItemStyled>
            <RequsitesFormInputDescriptionStyled>
              Наименование <br />
              на англ. языке
            </RequsitesFormInputDescriptionStyled>
            <input
              aria-label="Eng name"
              disabled
              placeholder={this.props.companyInfo.companyNameEng}
            />
          </RequsitesFormInputItemStyled>
        )}
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            ИНН
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="ИНН"
            disabled
            placeholder={this.props.companyInn.companyInn}
          />
        </RequsitesFormInputItemStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            КПП
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="КПП"
            disabled
            placeholder={this.props.companyInfo.kpp}
          />
        </RequsitesFormInputItemStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            Адрес регистрации
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="Адрес регистрации"
            disabled
            placeholder={this.props.companyInfo.companyAddress}
          />
        </RequsitesFormInputItemStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            Телефон
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="Телефон"
            disabled
            placeholder={this.props.companyInfo.companyPhone}
          />
        </RequsitesFormInputItemStyled>
        <RequsitesFormInputItemStyled>
          <RequsitesFormInputDescriptionStyled>
            Email
          </RequsitesFormInputDescriptionStyled>
          <input
            aria-label="Email"
            disabled
            placeholder={this.props.companyInfo.companyEmail}
          />
        </RequsitesFormInputItemStyled>
        <ChangeButton onClick={this.requisitesEditOnClick}>
          <p>Редактировать</p>
        </ChangeButton>
      </RequsitesFormStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyInfo: SCF.getCompanyInfo.data,
  companyInn: SCF.getCompanyInn.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyInfo,
      getCompanyInn
    },
    dispatch
  );

const RequisitesFormConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RequisitesForm);

export { RequisitesFormConnect as RequisitesForm };
