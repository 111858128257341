import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getUser';

import { GET_USER } from '../reducers/getUser';
import UserApi from '../api';

function* getUser(action: { type: GET_USER; id: string }): Generator {
  try {
    const res: any = yield call(UserApi.getUser, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserSaga(): Generator {
  yield takeLatest(GET_USER.REQ, getUser);
}
