import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/getStartRateFactorRole';
import { START_RATE_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getStartRateFactorRole';

import SCFApi from 'entities/SCF/Factor/api';

function* getStartRateFactorRoleData(action: { type: START_RATE_FACTOR_ROLE }) {
  try {
    const res: any = yield call(SCFApi.getStartRateFactorRole);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getStartRateFactorRoleDataSaga() {
  yield takeLatest(START_RATE_FACTOR_ROLE.REQ, getStartRateFactorRoleData);
}
