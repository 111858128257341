import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RadioGroup } from 'shared/ui/RadioGroup';
import {
  HAS_CASHBACK,
  HAS_CONTRACT,
  IN_PLATFORM
} from 'src/features/Filters/Products/FilterByCashback/types';
import { RadioBtnState } from 'src/features/Products/types';

interface Props {
  radioBtnState: RadioBtnState;
  handleRadioBtn: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterByCashback: React.FC<Props> = ({
  radioBtnState,
  handleRadioBtn
}) => {
  return (
    <>
      <RadioGroup
        label="Наличие кэшбека:"
        name="cashback"
        keyValue={radioBtnState.hasCashback}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(HAS_CASHBACK).map(value => ({
          value,
          label: HAS_CASHBACK[value]
        }))}
      />
      <RadioGroup
        label="Через платформу FF:"
        name="inPlatform"
        keyValue={radioBtnState.inPlatform}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(IN_PLATFORM).map(value => ({
          value,
          label: IN_PLATFORM[value]
        }))}
      />
      <RadioGroup
        label="Прямой договор с банком:"
        name="hasContract"
        keyValue={radioBtnState.hasContract}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(HAS_CONTRACT).map(value => ({
          value,
          label: HAS_CONTRACT[value]
        }))}
      />
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByCashbackConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByCashback);

export { FilterByCashbackConnect as FilterByCashback };
