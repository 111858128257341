import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as sendResetPasswordLetterRequest,
  reset as sendResetPasswordLetterReset
} from 'User/actions/sendResetPasswordLetter';

import { Spinner } from 'Layouts/components';
import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';

import { SuccessIcon, ErrorIcon } from 'shared/styled';
import {
  ForgotPasswordModalStyled,
  ForgotPasswordForm
} from 'src/features/Profile/components/ResetPasswordModal/styled';
import { Button } from 'shared/ui/Button';

export interface State {
  email: string;
}

interface AnyProps {
  email: string;
}

interface StateToProps {
  emailSendStatus: REQUEST_STATUSES;
  emailSendError: ResponseError;
}

interface DispatchToProps {
  sendResetPasswordLetterRequest: (email: string) => void;
  sendResetPasswordLetterReset: () => void;
}

type Props = StateToProps & DispatchToProps & AnyProps;

const ResetPasswordModal: React.FC<Props> = ({
  emailSendError,
  emailSendStatus,
  email,
  sendResetPasswordLetterReset,
  sendResetPasswordLetterRequest
}) => {
  React.useEffect(() => {
    sendResetPasswordLetterReset();
  }, []);

  const onSubmit = () => {
    sendResetPasswordLetterRequest(email);
  };

  const renderErrorMessage = () => {
    switch (emailSendError.code) {
      case 606:
        return (
          <>
            <ErrorIcon size={92} />
            <p>
              Пожалуйста, убедитесь в корректности введенного Вами email.
              Указанный email не зарегистрирован в системе
            </p>
          </>
        );
      default:
        return (
          <>
            <ErrorIcon size={92} />
            <p>Что-то пошло не так. Пожалуйста, попробуйте позже.</p>
          </>
        );
    }
  };

  const renderModalView = () => {
    switch (emailSendStatus) {
      case REQUEST_STATUSES.NONE:
        return (
          <>
            <h2>Подтвердите сброс пароля</h2>
            <p>
              На указанный вами адрес электронной почты будет отправлена ссылка
              для сброса пароля
            </p>

            <ForgotPasswordForm onSubmit={onSubmit} name="reset-password-form">
              <Button type="submit" label="Сбросить пароль" />
            </ForgotPasswordForm>
          </>
        );
      case REQUEST_STATUSES.GOT:
        return (
          <>
            <SuccessIcon size={92} />
            <p>
              Инструкция по сбросу пароля отправлена на адрес <b>{email}</b>
            </p>
          </>
        );
      case REQUEST_STATUSES.ERROR:
        return renderErrorMessage();
      default:
        return <Spinner />;
    }
  };

  return (
    <Modal name={MODAL_NAMES.FORGOT_PASSWORD}>
      <ForgotPasswordModalStyled>{renderModalView()}</ForgotPasswordModalStyled>
    </Modal>
  );
};

const mapStateToProps = ({ User }: STORE) => ({
  emailSendStatus: User.sendResetPasswordLetter.status,
  emailSendError: User.sendResetPasswordLetter.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { sendResetPasswordLetterRequest, sendResetPasswordLetterReset },
    dispatch
  );

const ResetPasswordModalConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordModal);

export { ResetPasswordModalConnect as ResetPasswordModal };
