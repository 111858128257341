import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getExternalAgentPeriodList';

import { GET_EXTERNAL_AGENT_PERIOD_LIST } from '../reducers/getExternalAgentPeriodList';
import FinancialStatementsApi from '../api';

function* getExternalAgentPeriodList(action: {
  type: GET_EXTERNAL_AGENT_PERIOD_LIST;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      FinancialStatementsApi.getExternalAgentPeriodList,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalAgentPeriodListSaga(): Generator {
  yield takeLatest(
    GET_EXTERNAL_AGENT_PERIOD_LIST.REQ,
    getExternalAgentPeriodList
  );
}
