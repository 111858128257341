import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from 'src/features/EarlyPaymentApplications/actions/getEarlyPayment';

export default class EarlyPaymentApplicationsApi {
  static getEarlyPayment(data: RequestData) {
    return ApiRequest(
      `/api/early_payment_applications/internal_role/all`,
      'get',
      data
    );
  }

  static getEarlyPaymentByGuid(guid: string) {
    return ApiRequest(
      `/api/early_payment_applications/internal_role/${guid}/get_one`
    );
  }
}
