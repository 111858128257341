import styled from 'styled-components';
import { blue, rr } from 'shared/styled';

export const RegistriesAddStyled = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

export const AddBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 190px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const AddBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;
