import styled, { css } from 'styled-components';
import { navyBlue } from 'shared/styled';

export const TableRowStyled = styled.tr`
  height: 60px;
  width: 1200px;
  td {
    width: 150px;
    font-size: 14px;
    color: ${navyBlue};
    &:nth-child(1) {
      padding-right: 10px;
      p {
        cursor: pointer;
        margin-left: 15px;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        padding: 1px;
        float: left;
      }
    }
    &:nth-child(2) {
      padding-right: 10px;
    }
    &:nth-child(3) {
      padding-right: 10px;
    }
    &:nth-child(4) {
      padding-right: 10px;
    }
  }
`;

export const TableThStyled = styled.th`
  ${({ width }) =>
    width
      ? css`
          width: ${width};
        `
      : ''};
`;
export const DeleteIcon = styled.div`
  display: flex;
  height: 18px;
  width: 18px;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 20px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(0,133,254)" d="M1.52 18.824L9.392 10.952L17.216 18.824L18.512 17.528L10.64 9.608L18.512 1.688L17.216 0.391999L9.392 8.312L1.52 0.391999L0.224 1.688L8.096 9.608L0.224 17.528L1.52 18.824Z"/>
  </svg>
  ');
`;

export const MainAccountIcon = styled.div`
  display: flex;
  height: 18px;
  width: 18px;
  padding-bottom: 2px;
  margin-left: 22px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(64,165,77)" d="M3.25 0C2.38805 0 1.5614 0.34241 0.951903 0.951903C0.34241 1.5614 0 2.38805 0 3.25V14.75C0 15.612 0.34241 16.4386 0.951903 17.0481C1.5614 17.6576 2.38805 18 3.25 18H14.75C15.612 18 16.4386 17.6576 17.0481 17.0481C17.6576 16.4386 18 15.612 18 14.75V3.25C18 2.38805 17.6576 1.5614 17.0481 0.951903C16.4386 0.34241 15.612 0 14.75 0H3.25ZM14.28 6.28L7.526 13.027C7.38537 13.1675 7.19475 13.2463 6.996 13.2463C6.79725 13.2463 6.60662 13.1675 6.466 13.027L3.72 10.28C3.58752 10.1378 3.5154 9.94978 3.51883 9.75548C3.52225 9.56118 3.60097 9.37579 3.73838 9.23838C3.87579 9.10097 4.06118 9.02225 4.25548 9.01882C4.44978 9.0154 4.63783 9.08752 4.78 9.22L6.997 11.436L13.22 5.219C13.2897 5.1494 13.3725 5.09422 13.4635 5.0566C13.5546 5.01898 13.6522 4.99967 13.7507 4.99976C13.8492 4.99985 13.9468 5.01935 14.0378 5.05714C14.1287 5.09493 14.2114 5.15027 14.281 5.22C14.3506 5.28973 14.4058 5.37249 14.4434 5.46355C14.481 5.55461 14.5003 5.65218 14.5002 5.75071C14.5001 5.84923 14.4807 5.94677 14.4429 6.03776C14.4051 6.12875 14.3497 6.2114 14.28 6.281V6.28Z"/>
  </svg>
  ');
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
`;
