import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum RE_CONFIRM_ACCOUNT {
  RE_CONFIRM_ACCOUNT_REQ = 'RE_CONFIRM_ACCOUNT_REQ',
  RE_CONFIRM_ACCOUNT_GOT = 'RE_CONFIRM_ACCOUNT_GOT',
  RE_CONFIRM_ACCOUNT_ERR = 'RE_CONFIRM_ACCOUNT_ERR'
}

export const initialState: Reducer = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const reConfirmAccount = (
  state: Reducer = initialState,
  action: {
    type: string;
    error: {};
  }
): Reducer => {
  switch (action.type) {
    case RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case RE_CONFIRM_ACCOUNT.RE_CONFIRM_ACCOUNT_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default reConfirmAccount;
