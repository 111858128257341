import * as React from 'react';
import { Route, Switch } from 'react-router';
import { RegistrySettingsInfoPage } from './RegistrySettingsInfo/RegistrySettingsInfo';
import {
  RegistrySettingsPageStyled,
} from './styles';

class DebtorRegistrySettingsPage extends React.Component {
  render() {
    return (
      <RegistrySettingsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={"/debtor/settings/debtor_registry_settings"}
            render={() => <RegistrySettingsInfoPage />}
          />
        </Switch>
      </RegistrySettingsPageStyled>
    );
  }
}

export default DebtorRegistrySettingsPage;
