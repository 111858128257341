import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { STORE } from 'globaltypes';
import { APPLICATION_STATUSES } from 'Application/types';
import { MODAL_NAMES } from 'Modal/types';

import { closeModal } from 'Modal/actions/toggleModal';
import { req as changeApplicationStatus } from 'Application/actions/changeApplicationStatus';
import { req as deleteApplication } from 'Application/actions/deleteApplication';
import { RequestDataType as ChangeApplicationStatusStoreState } from 'Application/reducers/changeApplicationStatus';
import { RequestDataType as DeleteApplicationRequestDataType } from 'Application/reducers/deleteApplication';

import { ACTION_BUTTON_TEMPLATE } from 'Application/components';
import { Modal } from 'Common';

import {
  ActionModalStyled,
  DontCancelBtn,
  BtnsBlock,
  ActionWarning
} from './styles';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  modalPayload: {
    label?: string;
    actionWarning?: string;
    actionStatus?: APPLICATION_STATUSES;
    actionTemplate?: ACTION_BUTTON_TEMPLATE;
  };
}

interface DispatchToProps {
  closeModal: () => void;
  deleteApplication: (data: DeleteApplicationRequestDataType) => void;
  changeApplicationStatus: (data: ChangeApplicationStatusStoreState) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ActionModal: React.FC<Props> = ({
  modalPayload,
  closeModal,
  deleteApplication,
  changeApplicationStatus,
  history,
  match
}) => {
  const yesClickHandler = () => {
    closeModal();

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    if (modalPayload.actionTemplate === ACTION_BUTTON_TEMPLATE.DELETE) {
      if (match.params.id) {
        deleteApplication({ id: +match.params.id });
      }
      history.push(`/cabinet/${role}/drafts?page=1`);
    } else {
      if (match.params.id) {
        changeApplicationStatus({
          id: +match.params.id,
          status: modalPayload.actionStatus
        });
      }
    }
  };

  const question = !!modalPayload.label ? modalPayload.label.toLowerCase() : '';

  return (
    <Modal name={MODAL_NAMES.APPLICATION_ACTION}>
      <ActionModalStyled>
        <h2>Внимание!</h2>
        <p>{`Вы действительно хотите ${question}?`}</p>
        {modalPayload.actionWarning && (
          <ActionWarning>{modalPayload.actionWarning}</ActionWarning>
        )}
        <BtnsBlock>
          <DontCancelBtn onClick={closeModal}>Нет</DontCancelBtn>
          <Button label="Да" onClick={yesClickHandler} />
        </BtnsBlock>
      </ActionModalStyled>
    </Modal>
  );
};

// NOTE weird TODO extract
const mapStateToProps = ({ Modal }: STORE) => ({
  modalPayload: Modal.toggleModal.data.payload
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { closeModal, deleteApplication, changeApplicationStatus },
    dispatch
  );

const ActionModalConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ActionModal)
);

export { ActionModalConnect as ActionModal };
