import { GET_OPEN_LOAN_LIMITS } from 'Application/reducers/getOpenLoanLimits';
import { OpenLoanLimitType } from '../types/LoanLimits';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: OpenLoanLimitType[];
}

export const req = (inn: string) => ({
  type: GET_OPEN_LOAN_LIMITS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_OPEN_LOAN_LIMITS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_OPEN_LOAN_LIMITS.ERR,
  error
});
