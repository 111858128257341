import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getTenderByClient';

export interface GetTenderByClientStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_TENDER_BY_CLIENT {
  REQ = 'GET_TENDER_BY_CLIENT_REQ',
  GOT = 'GET_TENDER_BY_CLIENT_GOT',
  ERR = 'GET_TENDER_BY_CLIENT_ERR',
  RESET = 'GET_TENDER_BY_CLIENT_RESET'
}

export const initialState: GetTenderByClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getTenderByClient = (
  state: GetTenderByClientStoreState = initialState,
  action: {
    type: GET_TENDER_BY_CLIENT;
    data: ResponseData[];
    error: {};
  }
): GetTenderByClientStoreState => {
  switch (action.type) {
    case GET_TENDER_BY_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_TENDER_BY_CLIENT.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_TENDER_BY_CLIENT.RESET:
      return {
        ...initialState
      };
    case GET_TENDER_BY_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getTenderByClient;
