import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { format, parseISO, subMinutes } from 'date-fns';

import { history } from 'src/shared/utils/History';
import {
  getCertificate,
  getAllUserCertificates,
  Certificate
} from 'crypto-pro-js';
import { Certificate as CertificateType } from './../../../../../types/certificate';
import {
  req as sendCertificateData,
  RequestData as SendCertificateData
} from './../../../../../actions/sendCertificateData';
import { SetCertificateDataStoreState } from 'src/features/SCF/reducers/setCertificate';
import {
  CertificateData as CertificateDataType,
  setCertificateData
} from 'src/features/SCF/actions/setCertificate';
import { ResponseDataType as GetCompanyInnResponseDataType } from './../../../../../reducers/getCompanyInn';
import { req as getCompanyInn } from './../../../../../actions/getCompanyInn';

import './styles/style.css';
import {
  CertificatesEditStyled,
  CertificatesToAdd,
  CertificateField,
  CertificateID,
  CertificateData,
  InfoIcon,
  CertificateChosenArea,
  CertificateChosen,
  CertificateTitle,
  CertificateMeta,
  CertificateMetaTitle,
  CertificateMetaData,
  AddBtn,
  AddBtnText,
  INNError
} from './styles';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

interface StateToProps extends Partial<CertificateDataType> {
  isSendingData: REQUEST_STATUSES;
  companyInnInner: GetCompanyInnResponseDataType;
  roles: string[];
}

interface DispatchToProps {
  sendCertificateData: (data: SendCertificateData) => void;
  setCertificateData: (data: SetCertificateDataStoreState) => void;
  getCompanyInn: () => void;
}

interface State {
  validateInnError: any;
  certificates: Certificate[];
  certificatesError: Certificate[];
  certificate: Certificate;
  certificateDetails: any;
  detailsError: Certificate;
}

type Props = StateToProps & DispatchToProps;

class CertificateCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      validateInnError: null,
      certificates: [],
      certificatesError: [],
      certificate: null,
      certificateDetails: null,
      detailsError: null
    };
  }

  async loadCertificateDetails(thumbprint) {
    try {
      const certificate = await getCertificate(thumbprint, false);

      this.setState({
        certificateDetails: {
          name: certificate.name,
          validFrom: certificate.validFrom,
          validTo: certificate.validTo,
          serialNumber: certificate.thumbprint,
          ownerInfo: await certificate.getOwnerInfo(),
          issuerInfo: await certificate.getIssuerInfo()
        }
      });
    } catch (error) {
      this.setState({ detailsError: error });
    }
  }

  certificateToAdd(certificateDetails) {
    if (certificateDetails === null) return;

    const viewItem: CertificateType = {
      id: 0,
      companyInn:
        certificateDetails.ownerInfo.find(
          x => x.title === 'ИНН организации'
        ) !== undefined
          ? certificateDetails.ownerInfo.find(
              x => x.title === 'ИНН организации'
            ).description
          : '',
      companyOgrnOrOgrnIP:
        certificateDetails.ownerInfo.find(x => x.title === 'ОГРН') !== undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'ОГРН')
              .description
          : '',
      companyName_O:
        certificateDetails.ownerInfo.find(x => x.title === 'Компания') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Компания')
              .description
          : '',
      companyName_CN:
        certificateDetails.ownerInfo.find(x => x.title === 'Владелец') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Владелец')
              .description
          : '',
      companyCountryC:
        certificateDetails.ownerInfo.find(x => x.title === 'Страна') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Страна')
              .description
          : '',
      companyRegionS:
        certificateDetails.ownerInfo.find(x => x.title === 'Регион') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Регион')
              .description
          : '',
      companyLocationL:
        certificateDetails.ownerInfo.find(x => x.title === 'Город') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Город')
              .description
          : '',
      companyAddressStreet:
        certificateDetails.ownerInfo.find(x => x.title === 'Адрес') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Адрес')
              .description
          : '',
      positionT:
        certificateDetails.ownerInfo.find(x => x.title === 'Должность') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Должность')
              .description
          : '',
      personSurNameSN:
        certificateDetails.ownerInfo.find(x => x.title === 'Фамилия') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Фамилия')
              .description
          : '',
      personNameG:
        certificateDetails.ownerInfo.find(x => x.title === 'Имя Отчество') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Имя Отчество')
              .description
          : '',
      personInn:
        certificateDetails.ownerInfo.find(x => x.title === 'ИНН') !== undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'ИНН')
              .description
          : '',
      personEmailE:
        certificateDetails.ownerInfo.find(x => x.title === 'Email') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'Email')
              .description
          : '',
      personSnils:
        certificateDetails.ownerInfo.find(x => x.title === 'СНИЛС') !==
        undefined
          ? certificateDetails.ownerInfo.find(x => x.title === 'СНИЛС')
              .description
          : '',
      certValidFrom: parseISO(certificateDetails.validFrom),
      certValidUntil: parseISO(certificateDetails.validTo),
      keyValidFrom: parseISO(certificateDetails.validFrom),
      keyValidUntil: subMinutes(parseISO(certificateDetails.validTo), 10),
      issuerInn:
        certificateDetails.issuerInfo.find(
          x => x.title === 'ИНН организации'
        ) !== undefined
          ? certificateDetails.issuerInfo.find(
              x => x.title === 'ИНН организации'
            ).description
          : '',
      issuerOGRN:
        certificateDetails.issuerInfo.find(x => x.title === 'ОГРН') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'ОГРН')
              .description
          : '',
      issuerName_O:
        certificateDetails.issuerInfo.find(x => x.title === 'Компания') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Компания')
              .description
          : '',
      issuerName_CN:
        certificateDetails.issuerInfo.find(
          x => x.title === 'Удостоверяющий центр'
        ) !== undefined
          ? certificateDetails.issuerInfo.find(
              x => x.title === 'Удостоверяющий центр'
            ).description
          : '',
      issuerTypeOU:
        certificateDetails.issuerInfo.find(x => x.title === 'Тип') !== undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Тип')
              .description
          : '',
      issuerCountryC:
        certificateDetails.issuerInfo.find(x => x.title === 'Страна') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Страна')
              .description
          : '',
      issuerRegionS:
        certificateDetails.issuerInfo.find(x => x.title === 'Регион') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Регион')
              .description
          : '',
      issuerLocationL:
        certificateDetails.issuerInfo.find(x => x.title === 'Город') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Город')
              .description
          : '',
      issuerAddressStreet:
        certificateDetails.issuerInfo.find(x => x.title === 'Адрес') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Адрес')
              .description
          : '',
      issuerEmailE:
        certificateDetails.issuerInfo.find(x => x.title === 'Email') !==
        undefined
          ? certificateDetails.issuerInfo.find(x => x.title === 'Email')
              .description
          : '',
      serialNumber: certificateDetails.serialNumber,
      isDeleted: false,
      isAvailable: certificateDetails.isAvailable
    };
    return viewItem;
  }

  selectCertificate(event, value) {
    const certificate = this.state.certificates.find(
      ({ thumbprint }) => thumbprint === value
    );

    this.setState({ certificate: certificate });
    this.loadCertificateDetails(certificate.thumbprint);
  }

  validateInn = (isValid: boolean) => {
    this.setState({ validateInnError: isValid });
  };

  onSubmit = () => {
    if (
      !this.certificateToAdd(this.state.certificateDetails).companyInn ||
      this.props.companyInnInner.companyInn ===
        this.certificateToAdd(this.state.certificateDetails).companyInn
    ) {
      this.props.sendCertificateData({
        //companyInn: this.certificateToAdd(this.state.certificateDetails).companyInn,
        companyInn: this.certificateToAdd(this.state.certificateDetails)
          .companyInn,
        //companyInn: this.props.companyInnInner.companyInn,
        companyOgrnOrOgrnIP: this.certificateToAdd(
          this.state.certificateDetails
        ).companyOgrnOrOgrnIP,
        companyName_O: this.certificateToAdd(this.state.certificateDetails)
          .companyName_O,
        companyName_CN: this.certificateToAdd(this.state.certificateDetails)
          .companyName_CN,
        companyCountryC: this.certificateToAdd(this.state.certificateDetails)
          .companyCountryC,
        companyRegionS: this.certificateToAdd(this.state.certificateDetails)
          .companyRegionS,
        companyLocationL: this.certificateToAdd(this.state.certificateDetails)
          .companyLocationL,
        companyAddressStreet: this.certificateToAdd(
          this.state.certificateDetails
        ).companyAddressStreet,
        positionT: this.certificateToAdd(this.state.certificateDetails)
          .positionT,
        personSurNameSN: this.certificateToAdd(this.state.certificateDetails)
          .personSurNameSN,
        personNameG: this.certificateToAdd(this.state.certificateDetails)
          .personNameG,
        personInn: this.certificateToAdd(this.state.certificateDetails)
          .personInn,
        personEmailE: this.certificateToAdd(this.state.certificateDetails)
          .personEmailE,
        personSnils: this.certificateToAdd(this.state.certificateDetails)
          .personSnils,
        certValidFrom: this.certificateToAdd(this.state.certificateDetails)
          .certValidFrom,
        certValidUntil: this.certificateToAdd(this.state.certificateDetails)
          .certValidUntil,
        keyValidFrom: this.certificateToAdd(this.state.certificateDetails)
          .keyValidFrom,
        keyValidUntil: this.certificateToAdd(this.state.certificateDetails)
          .keyValidUntil,
        issuerInn: this.certificateToAdd(this.state.certificateDetails)
          .issuerInn,
        issuerOGRN: this.certificateToAdd(this.state.certificateDetails)
          .issuerOGRN,
        issuerName_O: this.certificateToAdd(this.state.certificateDetails)
          .issuerName_O,
        issuerName_CN: this.certificateToAdd(this.state.certificateDetails)
          .issuerName_CN,
        issuerTypeOU: this.certificateToAdd(this.state.certificateDetails)
          .issuerTypeOU,
        issuerCountryC: this.certificateToAdd(this.state.certificateDetails)
          .issuerCountryC,
        issuerRegionS: this.certificateToAdd(this.state.certificateDetails)
          .issuerRegionS,
        issuerLocationL: this.certificateToAdd(this.state.certificateDetails)
          .issuerLocationL,
        issuerAddressStreet: this.certificateToAdd(
          this.state.certificateDetails
        ).issuerAddressStreet,
        issuerEmailE: this.certificateToAdd(this.state.certificateDetails)
          .issuerEmailE,
        serialNumber: this.certificateToAdd(this.state.certificateDetails)
          .serialNumber
      });
    }
  };

  componentDidMount(): void {
    (async () => {
      try {
        this.setState({ certificates: await getAllUserCertificates() });
      } catch (error) {
        this.setState({ certificatesError: error.message });
      }
    })();
    this.props.getCompanyInn();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isSendingData === REQUEST_STATUSES.REQUEST &&
      this.props.isSendingData === REQUEST_STATUSES.ERROR
    ) {
      alert('проверьте, не был ли данный сертификат добавлен ранее');
    }
    if (
      prevProps.isSendingData === REQUEST_STATUSES.REQUEST &&
      this.props.isSendingData === REQUEST_STATUSES.GOT
    ) {
      if (window.location.pathname.includes('supplier')) {
        history.replace('/supplier/settings/certificates');
      }
      if (window.location.pathname.includes('debtor')) {
        history.replace('/debtor/settings/certificates');
      }
      if (window.location.pathname.includes('factor')) {
        history.replace('/factor/settings/certificates');
      }
    }
  }

  render() {
    return (
      <CertificatesEditStyled>
        <CertificatesToAdd>
          {this.state.certificates.map(({ name, thumbprint, validTo }) => (
            <CertificateField
              className="certificate-field"
              id="certificate"
              key={thumbprint}
              value={thumbprint}
              onClick={event => this.selectCertificate(event, thumbprint)}
            >
              <CertificateID> </CertificateID>
              <CertificateData>
                {name +
                  ' (действителен до: ' +
                  format(new Date(validTo), 'yyyy.MM.dd - HH:mm') +
                  ')'}
              </CertificateData>
              <InfoIcon className="info-btn" />
            </CertificateField>
          ))}
        </CertificatesToAdd>
        <CertificateChosenArea>
          {this.state.certificateDetails ? (
            <CertificateChosen>
              <CertificateTitle>
                {this.state.certificateDetails.name}
              </CertificateTitle>
              {this.certificateToAdd(this.state.certificateDetails)
                .companyName_CN ? (
                <CertificateMeta>
                  <CertificateMetaTitle>Владелец</CertificateMetaTitle>
                  <CertificateMetaData>
                    {
                      this.certificateToAdd(this.state.certificateDetails)
                        .companyName_CN
                    }
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .issuerName_O ? (
                <CertificateMeta>
                  <CertificateMetaTitle>Издатель</CertificateMetaTitle>
                  <CertificateMetaData>
                    {
                      this.certificateToAdd(this.state.certificateDetails)
                        .issuerName_O
                    }
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .companyOgrnOrOgrnIP ? (
                <CertificateMeta>
                  <CertificateMetaTitle>ОГРН</CertificateMetaTitle>
                  <CertificateMetaData>
                    {
                      this.certificateToAdd(this.state.certificateDetails)
                        .companyOgrnOrOgrnIP
                    }
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .companyInn ? (
                <CertificateMeta>
                  <CertificateMetaTitle>ИНН Юридический</CertificateMetaTitle>
                  <CertificateMetaData>
                    {
                      this.certificateToAdd(this.state.certificateDetails)
                        .companyInn
                    }
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .personInn ? (
                <CertificateMeta>
                  <CertificateMetaTitle>ИНН</CertificateMetaTitle>
                  <CertificateMetaData>
                    {
                      this.certificateToAdd(this.state.certificateDetails)
                        .personInn
                    }
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .certValidUntil ? (
                <CertificateMeta>
                  <CertificateMetaTitle>Действителен до</CertificateMetaTitle>
                  <CertificateMetaData>
                    {format(
                      new Date(
                        this.certificateToAdd(
                          this.state.certificateDetails
                        ).certValidUntil
                      ),
                      'yyyy.MM.dd - HH:mm'
                    )}
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .certValidFrom ? (
                <CertificateMeta>
                  <CertificateMetaTitle>Выдан</CertificateMetaTitle>
                  <CertificateMetaData>
                    {format(
                      new Date(
                        this.certificateToAdd(
                          this.state.certificateDetails
                        ).certValidFrom
                      ),
                      'yyyy.MM.dd - HH:mm'
                    )}
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
              {this.certificateToAdd(this.state.certificateDetails)
                .personNameG ||
              this.certificateToAdd(this.state.certificateDetails)
                .personSurNameSN ? (
                <CertificateMeta>
                  <CertificateMetaTitle>Имя владельца</CertificateMetaTitle>
                  <CertificateMetaData>
                    {this.certificateToAdd(this.state.certificateDetails)
                      .personNameG +
                      ' ' +
                      this.certificateToAdd(this.state.certificateDetails)
                        .personSurNameSN}
                  </CertificateMetaData>
                </CertificateMeta>
              ) : null}
            </CertificateChosen>
          ) : (
            ''
          )}

          {(this.state.certificateDetails &&
            this.certificateToAdd(this.state.certificateDetails).companyInn !==
              this.props.companyInnInner.companyInn) ||
          (this.state.certificateDetails &&
            this.certificateToAdd(this.state.certificateDetails).personInn) ? (
            <>
              <AddBtn onClick={this.onSubmit}>
                <AddBtnText>+ Добавить</AddBtnText>
              </AddBtn>
              <INNError>
                <p>
                  Сертификат не содержит ИНН компании. Для работы потребуется
                  машиночитаемая доверенность
                </p>
              </INNError>
            </>
          ) : (
            <INNError>
              <p>Нельзя добавить</p>
              <p>ИНН аккаунта и ИНН в сертификате не совпадают</p>
            </INNError>
          )}
        </CertificateChosenArea>
      </CertificatesEditStyled>
    );
  }
}

const mapStateToProps = ({ SCF, User }: STORE) => ({
  isSendingData: SCF.sendCertificateData.status,
  companyInnInner: SCF.getCompanyInn.data,
  roles: User.getUserData.data.roles,
  ...SCF.setCertificateData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyInn,
      setCertificateData,
      sendCertificateData
    },
    dispatch
  );

const CertificateCreateConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CertificateCreate);

export { CertificateCreateConnect as CertificateCreate };
