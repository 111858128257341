import { GET_AGENT } from 'src/features/Questionnaire/reducers/getAgentByInn';
import { Agent } from 'src/features/Questionnaire/types';

export const req = (inn: string) => ({
  type: GET_AGENT.REQ,
  inn
});

export const got = (data: Agent) => ({
  type: GET_AGENT.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_AGENT.ERR,
  error
});
