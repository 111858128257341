import { GET_CLIENTS_FOR_AGENT } from '../reducers/getClients';
import { Clients } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Clients[];
}

export const req = (agentInn: string) => ({
  type: GET_CLIENTS_FOR_AGENT.REQ,
  agentInn
});

export const got = (data: ResponseData) => ({
  type: GET_CLIENTS_FOR_AGENT.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_CLIENTS_FOR_AGENT.ERR,
  error
});
