import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { RejectReasonModal } from './RejectReasonModal/RejectReasonModal';
import { ErrorRejectReasonModal } from './RejectReasonModal/ErrorModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { LEAD_STATUSES } from 'src/features/Leads/types';
import {
  req as changeLeadStatus,
  RequestData as changeLeadStatusRequestData
} from '../../../actions/changeLeadStatus';
import { LeadRead } from 'src/features/Leads/actions/setLeadFormData';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'src/features/Application/reducers/setInitializationFormData';
import { history } from 'src/shared/utils/History';
import { APPLICATION_TYPES } from 'src/features/Application/types';
import { ActionButtonsBlockStyled, ButtonContainer } from './styled';
import { Button } from 'shared/ui/Button';
import { green, red } from 'src/shared/styled';

interface StateToProps {
  leadStatusCode: number;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  changeLeadStatus: (data: changeLeadStatusRequestData) => void;
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
}

interface OwnProps {
  lead: LeadRead;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class LeadActionButtons extends React.Component<Props> {
  createApplicationFrom(lead: LeadRead) {
    const { setInitializationFormData } = this.props;
    setInitializationFormData({
      code: lead.inn,
      financingType: lead.applicationFinancingType,
      amount: lead.amount,
      clientCompanyName: lead.clientName,
      termStartDate: lead.termStartDate,
      termEndDate: lead.termEndDate
    });

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    if (lead.applicationFinancingType === APPLICATION_TYPES.EMPTY)
      history.push(`/cabinet/${role}/application-form/create/choice_screen`);
    else if (lead.applicationFinancingType === APPLICATION_TYPES.GUARANTEES)
      history.push(
        `/cabinet/${role}/application-form/create/${
          lead.applicationFinancingType
        }`
      );
    else history.push(`/cabinet/${role}/application-form/create/initialize`);
  }

  render() {
    const { lead, leadStatusCode, changeLeadStatus } = this.props;
    return (
      <>
        <RejectReasonModal />
        {leadStatusCode === 400 && <ErrorRejectReasonModal />}
        <ActionButtonsBlockStyled>
          {lead.status === LEAD_STATUSES.IN_WORK && (
            <>
              <Button
                label="Создать заявку"
                onClick={() => this.createApplicationFrom(lead)}
              />
              <Button
                label="Отказ"
                onClick={() =>
                  this.props.openModal({
                    name: MODAL_NAMES.REJECT_REASON_MODAL
                  })
                }
                backgroundColor={red}
              />
            </>
          )}
          {lead.status === LEAD_STATUSES.CREATED && (
            <ButtonContainer>
              <Button
                label="Взять в работу"
                onClick={() => this.toInWork(lead)}
                w="220px"
              />
              <Button
                label="Отказ"
                onClick={() =>
                  this.props.openModal({
                    name: MODAL_NAMES.REJECT_REASON_MODAL
                  })
                }
                w="220px"
                backgroundColor={red}
              />
            </ButtonContainer>
          )}
          {lead.status === LEAD_STATUSES.REJECT && (
            <Button
              label="Вернуть в работу"
              onClick={() => this.backToWork(lead)}
              backgroundColor={green}
            />
          )}
          {(lead.status === LEAD_STATUSES.IN_WORK ||
            lead.status === LEAD_STATUSES.APPROVED ||
            lead.status === LEAD_STATUSES.FILLED) && (
            <Button
              label="Сделка заключена"
              onClick={() => this.dealIsDone(lead)}
              backgroundColor={green}
            />
          )}
        </ActionButtonsBlockStyled>
      </>
    );
  }

  private toInWork(lead: LeadRead) {
    return this.props.changeLeadStatus({
      leadId: lead.id,
      status: LEAD_STATUSES.IN_WORK
    });
  }

  private dealIsDone(lead: LeadRead) {
    return this.props.changeLeadStatus({
      leadId: lead.id,
      status: LEAD_STATUSES.DEAL_SUCCEED
    });
  }

  private backToWork(lead: LeadRead) {
    return this.props.changeLeadStatus({
      leadId: lead.id,
      status: LEAD_STATUSES.CREATED
    });
  }

  private toReject(lead: LeadRead) {
    // TODO just create another action
    return this.props.changeLeadStatus({
      leadId: lead.id,
      status: LEAD_STATUSES.REJECT
    });
  }
}

const mapStateToProps = ({ Leads }: STORE) => ({
  leadStatusCode: Leads.changeLeadStatus.error.code
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      changeLeadStatus,
      setInitializationFormData
    },
    dispatch
  );

const LeadActionButtonsConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeadActionButtons);

export { LeadActionButtonsConnect as LeadActionButtons };
