import * as React from 'react';

import { FormattedApplicationObj } from 'Application/components';
import {
  ApplicationTileStyled,
  TileHeader,
  TileSection,
  InfoBlock,
  StatusBlock
} from './styles';

interface Props {
  data: FormattedApplicationObj;
  onTileClick: () => void;
}

export const ApplicationTile: React.FC<Props> = ({ data, onTileClick }) => (
  <ApplicationTileStyled>
    <InfoBlock onClick={!!onTileClick && onTileClick}>
      <TileHeader>
        <h4>{data.companyName}</h4>
        {data.submenu}
      </TileHeader>

      <TileSection>
        <span>Тип финансирования</span>
        <p>{data.financingType}</p>
      </TileSection>

      <TileSection>
        <span>Сумма</span>
        <p>{data.amount}</p>
      </TileSection>

      <TileSection>
        <span>Дата изменения</span>
        <p>{data.modifiedAt}</p>
      </TileSection>
    </InfoBlock>

    <StatusBlock>{data.status}</StatusBlock>
  </ApplicationTileStyled>
);
