import styled from 'styled-components';
import { lighterBlue } from 'shared/styled';

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;
`;

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;
