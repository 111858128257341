import { APPLICATION_STATUSES } from 'Application/types';
import {
  ACTION_BUTTON_TEMPLATE,
  OwnProps as ActionButtonProps
} from 'Application/components';

export const agentAsClientBtnConstructor = (
  status: APPLICATION_STATUSES,
  mainApplicationId: number
): ActionButtonProps[] => {
  switch (status) {
    case APPLICATION_STATUSES.DRAFT:
      return [
        {
          label: 'Удалить черновик',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DELETE
        },
        {
          label: 'Отправить в банк',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_BANK
        }
      ];
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      if (mainApplicationId === 0)
        return [
          {
            label: 'Отправить в банк',
            actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
            actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_BANK
          },
          {
            label: 'Отозвать заявку',
            actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
            actionStatus: APPLICATION_STATUSES.USER_REJECT
          }
        ];
    case APPLICATION_STATUSES.REVIEW:
      return [
        {
          label: 'Отозвать заявку',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.USER_REJECT
        }
      ];

    case APPLICATION_STATUSES.DOCS_REQUEST:
      return [
        {
          label: 'Отправить в банк',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.REVIEW
        },
        {
          label: 'Отозвать заявку',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.USER_REJECT
        }
      ];

    case APPLICATION_STATUSES.APPROVED:
      return [
        {
          label: 'Принять предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.SUCCESS
        },
        {
          label: 'Oтклонить предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DENIAL,
          actionStatus: APPLICATION_STATUSES.USER_DENIAL
        }
      ];

    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
      return [];
    default:
      return [];
  }
};
