import {
  DELETE_APPLICATION,
  RequestDataType
} from 'Application/reducers/deleteApplication';

export const req = (data: RequestDataType) => ({
  type: DELETE_APPLICATION.REQ,
  data
});

export const got = () => ({
  type: DELETE_APPLICATION.GOT
});

export const err = (error: {}) => ({
  type: DELETE_APPLICATION.ERR,
  error
});
