import { GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER } from '../reducers/getOneDiscountAuctionBySupplier';

export interface ResponseData {
  guid: string;
  debtorName: string;
  debtorInn: string;
  resultsDateTime: string;
  payDate: string;
  targetDiscountRate: number;
  minDiscountRate: number;
}

export const req = (guid: string) => ({
  type: GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER.REQ,
  guid
});

export const got = (data: ResponseData) => ({
  type: GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER.ERR,
  error
});

export const reset = () => ({
  type: GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER.RESET
});
