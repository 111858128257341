import * as React from 'react';
import { Route, Switch } from 'react-router';

import { ContractsList } from './PurchaseContractsList/PurchaseContractsList';
import { ContractsInfoPage } from './PurchaseContractInfo/PurchaseContractInfo';
import { ContractsCreate } from './PurchaseContractCreate/PurchaseContractCreate';
import { PurchaseContractsPageStyled } from './styles';

class PurchaseContractsPage extends React.Component {
  render() {
    return (
      <PurchaseContractsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={'/debtor/purchase_contracts'}
            render={() => <ContractsList />}
          />
          <Route
            exact={true}
            path={'/debtor/purchase_contracts/add'}
            render={() => <ContractsCreate />}
          />
          <Route
            exact={true}
            path={'/debtor/purchase_contracts/:guid'}
            render={() => <ContractsInfoPage />}
          />
        </Switch>
      </PurchaseContractsPageStyled>
    );
  }
}

export default PurchaseContractsPage;
