import { POST_DOCUMENTS_FORM_FILL } from 'entities/Cabinet/InternalAgent/model/reducers/postDocumentsFormFill';

export const req = (guid: string, companyInn: string) => ({
  type: POST_DOCUMENTS_FORM_FILL.REQ,
  guid,
  companyInn
});

export const got = (data: string) => ({
  type: POST_DOCUMENTS_FORM_FILL.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_DOCUMENTS_FORM_FILL.ERR,
  error
});
