import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getInvestorListBySupplier';
import { GET_INVESTOR_LIST_BY_SUPPLIER } from '../reducers/getInvestorListBySupplier';
import SCFApi from '../api';

function* getInvestorListBySupplier(action: {
  type: GET_INVESTOR_LIST_BY_SUPPLIER;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFApi.getInvestorListBySupplier, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInvestorListBySupplierSaga() {
  yield takeLatest(
    GET_INVESTOR_LIST_BY_SUPPLIER.REQ,
    getInvestorListBySupplier
  );
}
