import { DATE_ACTION_TYPES } from '../reducers/updateDateFilter';

export const updateDateFromFilter = (dateFrom: string) => ({
  type: DATE_ACTION_TYPES.SET_DATE_FROM,
  dateFrom
});

export const updateDateUntilFilter = (dateUntil: string) => ({
  type: DATE_ACTION_TYPES.SET_DATE_TO,
  dateUntil
});
