import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/ExternalAgent/model/actions/patchCompanyExternal';

import { PATCH_COMPANY_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/patchCompanyExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* patchCompanyExternal(action: {
  type: PATCH_COMPANY_EXTERNAL;
  data: RequestData;
  inn: string;
}): Generator {
  try {
    yield call(
      ExternalAgentRoleApi.patchCompanyExternal,
      action.inn,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCompanyExternalSaga(): Generator {
  yield takeLatest(PATCH_COMPANY_EXTERNAL.REQ, patchCompanyExternal);
}
