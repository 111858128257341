import { GET_PERSONS_OF_COMPANIES } from 'Business/reducers/getPersonsOfCompanies';
import { PersonsOfCompanies } from '../types';

export const req = (inn: string) => ({
  type: GET_PERSONS_OF_COMPANIES.REQ,
  inn
});

export const got = (data: PersonsOfCompanies[]) => ({
  type: GET_PERSONS_OF_COMPANIES.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_PERSONS_OF_COMPANIES.ERR,
  error
});
