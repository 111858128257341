import { GET_DEBTOR_REGISTRY_FILES } from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFiles';

export interface RegistryFile {
  id: number;
  debtorInn: number;
  createdDateTime: Date;
  isDeleted: boolean;
  registryFilename: string;
  signatureFilename: string;
  creatorName: string;
  size: number;
  status: number;
  errorsCount: number;
  errorRowsCount: number;
  registryFactorSubDocumentInfo: SubRegistry[];
}

export interface SubRegistry {
  id: string;
  isDeleted: boolean;
  debtorRegistryId: number;
  debtorCertificateId: number;
  debtorSignatureName: string;
  debtorSignaturePosition: string;
  debtorInn: string;
  factorInn: string;
  registryFilename: string;
  registryFileIdentifier: string;
  signatureFilename: string;
  signatureFileIdentifier: string;
  fileSize: string;
  downloadedByFactor: boolean;
  createdDateTime: Date;
  modifiedDateTime: Date;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: RegistryFile[];
}

export interface RequestDataType {
  page: number;
  pageSize: number;
  dateFrom: string;
  dateUntil: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_ERR,
  error
});
