import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/postContactByProduct';

import { POST_CONTACT_BY_PRODUCT } from '../reducers/postContactByProduct';
import ContactsOfProductsApi from '../api';

function* postContactByProduct(action: {
  type: POST_CONTACT_BY_PRODUCT;
  data: RequestData;
}): Generator {
  try {
    yield call(
      ContactsOfProductsApi.postContactByProduct,
      action.data.productId,
      action.data.contactId
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postContactByProductSaga(): Generator {
  yield takeLatest(POST_CONTACT_BY_PRODUCT.REQ, postContactByProduct);
}
