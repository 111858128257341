import { combineReducers } from 'redux';
import getActivities, { GetActivitiesStoreState } from './getActivities';
import getActivitiesById, {
  GetActivitiesByIdStoreState
} from './getActivitiesById';
import createActivities, {
  CreateActivitiesStoreState
} from './createActivities';
import getActivitiesType, {
  GetActivitiesTypeStoreState
} from './getActivitiesType';
import getContactsOfActivities, {
  GetContactsOfActivitiesStoreState
} from './getContactsOfActivities';
import getParticipantsOfActivity, {
  GetParticipantsOfActivityStoreState
} from './getParticipantsOfActivity';
import addContact, { AddContactStoreState } from './addContact';
import addParticipant, { AddParticipantStoreState } from './addParticipant';

export interface ActivitiesReducers {
  getActivities: GetActivitiesStoreState;
  createActivities: CreateActivitiesStoreState;
  getActivitiesType: GetActivitiesTypeStoreState;
  getActivitiesById: GetActivitiesByIdStoreState;
  getContactsOfActivities: GetContactsOfActivitiesStoreState;
  getParticipantsOfActivity: GetParticipantsOfActivityStoreState;
  addContact: AddContactStoreState;
  addParticipant: AddParticipantStoreState;
}

export const Activities = combineReducers({
  getActivities,
  createActivities,
  getActivitiesType,
  getActivitiesById,
  getContactsOfActivities,
  getParticipantsOfActivity,
  addContact,
  addParticipant
});
