import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getAllOffers';
import { GET_ALL_OFFERS } from 'Application/reducers/getAllOffers';

import ApplicationApi from 'Application/api';

function* getAllOffers(action: { type: GET_ALL_OFFERS; id: string }) {
  try {
    const res: any = yield call(ApplicationApi.getAllOffers, action.id);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllOffersSaga() {
  yield takeLatest(GET_ALL_OFFERS.REQ, getAllOffers);
}
