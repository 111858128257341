import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/deleteFactoringFeesOfLimits';

import { DELETE_FACTORING_FEES_OF_LIMITS } from 'entities/SCF/Factor/model/reducers/deleteFactoringFeesOfLimits';
import SCFApi from 'entities/SCF/Factor/api';

function* deleteFactoringFeesOfLimits(action: {
  type: DELETE_FACTORING_FEES_OF_LIMITS;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(
      SCFApi.deleteFactoringFeesOfLimits,
      action.guid
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteFactoringFeesOfLimitsSaga(): Generator {
  yield takeLatest(
    DELETE_FACTORING_FEES_OF_LIMITS.REQ,
    deleteFactoringFeesOfLimits
  );
}
