import * as React from 'react';
import PopupItem from 'reactjs-popup';
import { ProfileInfoPopupStyled } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ProfileInfoPopup/styles';
import { PopupTrigger } from './styles';

interface PopupProps {
    text: string;
    popupText: string | JSX.Element;
}

const Popup: React.FC<PopupProps> = ({ text, popupText }) => (
    <PopupItem
        position="top center"
        trigger={<PopupTrigger>{text}</PopupTrigger>}
    >
        <ProfileInfoPopupStyled>
            <p>{popupText}</p>
        </ProfileInfoPopupStyled>

    </PopupItem>
);

export default Popup;
