import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'Business/actions/getSubsidiariesOfCompanies';
import { GET_SUBSIDIARIES_OF_COMPANIES } from 'Business/reducers/getSubsidiariesOfCompanies';
import BusinessApi from 'Business/api';

function* getSubsidiariesOfCompanies(action: {
  type: GET_SUBSIDIARIES_OF_COMPANIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BusinessApi.getSubsidiaries, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSubsidiariesOfCompaniesSaga(): Generator {
  yield takeLatest(
    GET_SUBSIDIARIES_OF_COMPANIES.REQ,
    getSubsidiariesOfCompanies
  );
}
