import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getCertificate';

export interface GetCertificateDataStoreState extends Reducer {
    data: ResponseDataType;
}

export enum CERTIFICATE_ACTIONS {
    REQ = 'CERTIFICATE_REQ',
    GOT = 'CERTIFICATE_GOT',
    ERR = 'CERTIFICATE_ERR',
}

const initialState: GetCertificateDataStoreState = {
    status: REQUEST_STATUSES.NONE,
    data: {
        page: 0,
        pageSize: 0,
        totalItems: 0,
        items: [],
    },
    error: {}
};

const getCertificate = (
    state: GetCertificateDataStoreState = initialState,
    action: {
        type: string;
        data: ResponseDataType;
        error: {};
    }
): GetCertificateDataStoreState => {
    switch (action.type) {
        case CERTIFICATE_ACTIONS.REQ:
            return {
                ...state,
                status: REQUEST_STATUSES.REQUEST
            };

        case CERTIFICATE_ACTIONS.GOT:
            return {
                ...state,
                data: action.data,
                status: REQUEST_STATUSES.GOT
            };

        case CERTIFICATE_ACTIONS.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };

        default:
            return state;
    }
};

export default getCertificate;
