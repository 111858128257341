import styled from 'styled-components';
import { ButtonStyled } from 'src/shared/ui/Button/styled';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { Form } from 'shared/ui/Form';
import { blue, lightGray, rr } from 'shared/styled';

export const RequsitesFormStyled = styled.section`
  width: auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RequsitesFormInputItemStyled = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;

  input {
    margin-top: 10px;
    width: 500px;
    height: 30px;
    display: flex;

    border: 1px solid #a6a8ad;
    padding: 5px 10px;
  }
`;

export const RequsitesFormInputDescriptionStyled = styled.p`
  width: 160px;
  display: block;
  margin-right: 50px;
  padding-top: 4px;
`;

export const RequsitesFormInputValuesFormStyled = styled.div`
  width: 500px;
  height: 30px;
  display: flex;
`;

export const RequsitesFormInputValuesStyled = styled.p`
  padding: 5px 10px 5px 10px;
  background-color: #f7f8fc;
  border: 1px solid #a6a8ad;
  width: 100%;
`;

export const ButtonsBlock = styled.div`
  margin-top: 8px;
  display: flex;
  width: 400px;
  justify-content: space-between;
`;

export const ChangeButton = styled.div`
  width: 150px;
  height: 60px;
  display: flex;

  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #005db1;
  }
  p {
    color: #ffffff;
  }
`;

export const StatusButton = styled.div`
  margin-right: 20px;
  width: 300px;
  height: 50px;
  display: flex;

  justify-content: center;
  background-color: #9fa6b2;
  border-radius: 5px;
  cursor: pointer;
  p {
    padding-top: 2px;
    color: #ffffff;
  }
`;

export const Status = styled.div`
  margin-top: 8px;
  display: flex;
  p {
    color: #000000;
    font-size: 12px;
  }
`;

export const ApplicationStepTitle = styled.p`
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
`;

export const ApplicationForm = styled(Form)`
  width: 100%;

  & > ${InputContainerStyled} {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & > ${ButtonStyled} {
    margin-top: 20px;
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  margin-top: 20px;

  svg{
    cursor: pointer;
    font-size: 18px;
    color: ${blue};
  }

  &:hover:before {
    content: '${({ text }) => text}';
    position: absolute;
    width: max-content;
    font-family: ${rr};
    bottom: 15px;
    left: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }`;

export const TooltipContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  & > ${InputContainerStyled} {
    width: 400px;
  }
`;
