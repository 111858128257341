import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { req as getCertificateGenitive } from 'src/features/SCF/actions/getCertificateGenitive';
import { RequestDataType as certificateFillProps } from 'src/features/SCF/reducers/putCertificateFill';
import {
  req as sendCertificateFill,
  reset as certificateFillReset
} from 'src/features/SCF/actions/putCertificateFill';
import { DisplayHeader } from '../DisplayHeader/DisplayHeader';
import {
  HeaderStyled,
  CetrificatesEditInfoContentBlock,
  AdditionalBlock,
  AdditionalContainer,
  AdditionalRow,
  Text,
  TextBig,
  TextContainer,
  InputStyled,
  BtnsBlock,
  TextLittle,
  AuthorityButtons,
  TextHintContainer,
  TooltipBoxStyle
} from '../styles';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { getCertificateById } from '../ulitls/getCertificateById';
import {
  EditBtn,
  EditBtnText,
  EditBtnDisabled
} from 'src/features/SCF/UI/ButtonsStyles';
import { onRowClick } from '../CertificatesForm/components/CertificatesList/CertificatesTableView/ClickHandlers';

interface StateToProps {
  certificateGenitiveData;
  certificateGenitiveStatus: REQUEST_STATUSES;
  certificateFillStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCertificateGenitive: () => void;
  sendCertificateFill: (data: certificateFillProps) => void;
  certificateFillReset: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CertificateFill: React.FC<Props> = ({
  getCertificateGenitive,
  certificateGenitiveData,
  certificateGenitiveStatus,
  certificateFillReset,
  sendCertificateFill,
  certificateFillStatus,
  match
}) => {
  React.useEffect(() => {
    getCertificateGenitive();

    return () => {
      certificateFillReset();
    };
  }, []);

  const specificCertificate = getCertificateById(
    match.params.id,
    certificateGenitiveData
  );

  React.useEffect(
    () => {
      if (certificateFillStatus === REQUEST_STATUSES.GOT) {
        onRowClick(match.params.id);
      }
    },
    [certificateFillStatus]
  );

  const [fillCertificateState, setFillCertificateState] = React.useState<
    certificateFillProps
  >({
    guid: match.params.id,
    body: {
      authority: specificCertificate ? specificCertificate.authority : null,
      authorityGenitive: specificCertificate
        ? specificCertificate.authorityGenitive
        : null,
      personFullnameGenitive: specificCertificate
        ? specificCertificate.personFullnameGenitive
        : null,
      positionGenitive: specificCertificate
        ? specificCertificate.positionGenitive
        : null,
      personFullnameEng: specificCertificate
        ? specificCertificate.personFullnameEng
        : null,
      positionEng: specificCertificate ? specificCertificate.positionEng : null,
      authorityEng: specificCertificate
        ? specificCertificate.authorityEng
        : null
    }
  });

  const handleCertificateChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.currentTarget;
    setFillCertificateState(prevState => ({
      ...prevState,
      body: { ...prevState.body, [name]: value }
    }));
  };

  const saveClick = (e: Event) => {
    sendCertificateFill(fillCertificateState);
  };

  const handleChangeAuthority = (
    name: 'attorney' | 'regulation' | 'machineAttorney'
  ) => {
    if (name === 'regulation') {
      setFillCertificateState(prevState => ({
        ...prevState,
        body: {
          ...prevState.body,
          authority: 'Устав',
          authorityGenitive: 'Устава',
          authorityEng: 'Charter'
        }
      }));
    } else if (name === 'attorney') {
      setFillCertificateState(prevState => ({
        ...prevState,
        body: {
          ...prevState.body,
          authority: 'Доверенность № **** от **.**.**** г.”',
          authorityGenitive: 'Доверенности № **** от **.**.**** г.',
          authorityEng: 'Power of Attorney № **** from **.**.****'
        }
      }));
    } else if (name === 'machineAttorney') {
      setFillCertificateState(prevState => ({
        ...prevState,
        body: {
          ...prevState.body,
          authority: 'МЧД № **** (рег.№ ****) от **.**.**** г.',
          authorityGenitive: 'МЧД № **** (рег.№ ****)  от **.**.**** г.',
          authorityEng: 'FPOA No. **** (reg. No. ****) from **.**.****'
        }
      }));
    }
  };

  return (
    <PageNoMarginStyled>
      {certificateGenitiveStatus === REQUEST_STATUSES.REQUEST && <Loader />}
      {certificateGenitiveStatus === REQUEST_STATUSES.GOT && (
        <React.Fragment>
          <HeaderStyled>
            <p>{specificCertificate.guid}</p>
            <h2>СЕРТИФИКАТ</h2>
          </HeaderStyled>
          <CetrificatesEditInfoContentBlock>
            <AdditionalContainer>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Владелец сертификата:</TextBig>
                  </TextContainer>
                  <TextBig>
                    {specificCertificate.personSurNameSN +
                      ' ' +
                      specificCertificate.personNameG}
                  </TextBig>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputStyled
                    name="personFullnameGenitive"
                    value={fillCertificateState.body.personFullnameGenitive}
                    placeholder={'не заполнено'}
                    style={{ width: '300px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextHintContainer>
                    <Text>
                      Фамилия Имя <br />
                      на англ. языке
                    </Text>
                    <TooltipBoxStyle
                      text={'Используется при наличии договоров на двух языках'}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </TooltipBoxStyle>
                  </TextHintContainer>
                  <InputStyled
                    name="personFullnameEng"
                    value={fillCertificateState.body.personFullnameEng}
                    placeholder={'не заполнено'}
                    style={{ width: '300px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
              </AdditionalBlock>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Должность</TextBig>
                  </TextContainer>
                  <TextBig>{specificCertificate.positionT}</TextBig>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputStyled
                    name="positionGenitive"
                    value={fillCertificateState.body.positionGenitive}
                    placeholder={'не заполнено'}
                    style={{ width: '250px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextHintContainer>
                    <Text>
                      Должность
                      <br /> на англ. языке
                    </Text>
                    <TooltipBoxStyle
                      text={'Используется при наличии договоров на двух языках'}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </TooltipBoxStyle>
                  </TextHintContainer>
                  <InputStyled
                    name="positionEng"
                    value={fillCertificateState.body.positionEng}
                    placeholder={'не заполнено'}
                    style={{ width: '250px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Основание полномочий</TextBig>
                  </TextContainer>
                  <AuthorityButtons>
                    <p onClick={() => handleChangeAuthority('regulation')}>
                      Устав
                    </p>
                    <p onClick={() => handleChangeAuthority('attorney')}>
                      Доверенность
                    </p>
                    <p onClick={() => handleChangeAuthority('machineAttorney')}>
                      Машиночитаемая доверенность
                    </p>
                  </AuthorityButtons>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в именительном падеже:</Text>
                  </TextContainer>
                  <InputStyled
                    name="authority"
                    value={fillCertificateState.body.authority}
                    placeholder={'не заполнено'}
                    style={{ width: '450px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputStyled
                    name="authorityGenitive"
                    value={fillCertificateState.body.authorityGenitive}
                    placeholder={'не заполнено'}
                    style={{ width: '450px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextHintContainer style={{ height: '40px' }}>
                    <Text>
                      Основания полномочий <br />
                      на англ. языке
                    </Text>
                    <TooltipBoxStyle
                      text={'Используется при наличии договоров на двух языках'}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </TooltipBoxStyle>
                  </TextHintContainer>
                  <InputStyled
                    name="authorityEng"
                    value={fillCertificateState.body.authorityEng}
                    placeholder={'не заполнено'}
                    style={{ width: '450px' }}
                    onChange={handleCertificateChange}
                  />
                </AdditionalRow>
              </AdditionalBlock>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Срок действия</TextBig>
                  </TextContainer>
                  <TextBig>
                    {format(
                      new Date(specificCertificate.certValidFrom),
                      'dd.MM.yyyy'
                    ) +
                      ' - ' +
                      format(
                        new Date(specificCertificate.certValidUntil),
                        'dd.MM.yyyy'
                      )}
                  </TextBig>
                </AdditionalRow>
              </AdditionalBlock>
              <BtnsBlock>
                <EditBtnDisabled>
                  <EditBtnText>Редактировать</EditBtnText>
                </EditBtnDisabled>
                {fillCertificateState.body.authority &&
                fillCertificateState.body.authorityGenitive &&
                fillCertificateState.body.personFullnameGenitive &&
                fillCertificateState.body.positionGenitive ? (
                  <EditBtn onClick={saveClick}>
                    <EditBtnText>Сохранить</EditBtnText>
                  </EditBtn>
                ) : (
                  <EditBtnDisabled>
                    <EditBtnText>Сохранить</EditBtnText>
                  </EditBtnDisabled>
                )}
              </BtnsBlock>
              <TextLittle>
                Последнее изменение:{' '}
                {format(
                  new Date(specificCertificate.modifiedDateTime),
                  'dd.MM.yyyy'
                )}
              </TextLittle>
            </AdditionalContainer>
            <DisplayHeader
              positionGenitive={fillCertificateState.body.positionGenitive}
              personFullnameGenitive={
                fillCertificateState.body.personFullnameGenitive
              }
              authorityGenitive={fillCertificateState.body.authorityGenitive}
            />
          </CetrificatesEditInfoContentBlock>
        </React.Fragment>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  certificateGenitiveData: SCF.getCertificateGenitive.data,
  certificateGenitiveStatus: SCF.getCertificateGenitive.status,
  certificateFillStatus: SCF.putCertificateFill.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCertificateGenitive,
      certificateFillReset,
      sendCertificateFill
    },
    dispatch
  );

const CertificateFillConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CertificateFill)
);

export { CertificateFillConnect as CertificateFill };
