import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader
} from 'src/features/Layouts/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { req as getLimits } from 'src/features/PreapprovedLimits/actions/getLimits';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'src/features/Application/reducers/setInitializationFormData';
import { setGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';
import { SetGuaranteesApplicationType } from 'src/features/Application/reducers/setGuaranteesFormData';
import { APPLICATION_TYPES } from 'src/features/Application/types';
import { CompanyLimits } from '../types';
import {
  Description,
  NotFoundContainer,
  ProductNameContainer,
  TDProductNameContainer
} from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  userCompanyInn: string;
  limits: CompanyLimits[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getLimits: (inn: string) => void;
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  setGuaranteesFormData: (data: SetGuaranteesApplicationType) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const PreapprovedLimits: React.FC<Props> = ({
  getLimits,
  userCompanyInn,
  limits,
  status,
  error,
  setInitializationFormData,
  setGuaranteesFormData,
  history
}) => {
  React.useEffect(
    () => {
      if (!!userCompanyInn.length) {
        getLimits(userCompanyInn);
      }
    },
    [userCompanyInn]
  );

  const navigateToCrediting = (limit: CompanyLimits) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/application-form/create/initialize`, {
      from: history.location.pathname,
      amount: limit.volume
    });
    setInitializationFormData({
      financingType: APPLICATION_TYPES.CREDITING,
      term: limit.term,
      amount: limit.volume,
      code: userCompanyInn
    });
    setGuaranteesFormData({
      desiredBanks: `${limit.bankShortName}, Продукт: ${
        limit.productName
      }, id: ${limit.guid}`
    });
  };

  const segments = location.pathname.split('/');
  const roleIndex = segments.indexOf('cabinet') + 1;
  const role = segments[roleIndex];

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Предодобренные предложения</ApplicationTitle>
      <Description>
        В данном разделе отображаются предложения банков, которые уже
        предварительно одобрены для вас банками по данным вашей компании из
        открытых источников. Чтобы воспользоваться предложением, подайте заявку.
      </Description>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="15%">Продукт</TableThStyled>
              <TableThStyled width="15%">Сумма</TableThStyled>
              <TableThStyled width="15%">Предварительная ставка</TableThStyled>
              <TableThStyled width="10%">Срок</TableThStyled>
              <TableThStyled width="15%">Банк</TableThStyled>
              <TableThStyled width="15%" />
            </tr>
          </TableHeaderStyled>
          <tbody>
            {limits.map(limit => (
              <TableRowStyled key={limit.guid}>
                <td>{limit.guid.slice(-7)}</td>
                <TDProductNameContainer>
                  <ProductNameContainer>
                    <p>{limit.productName}</p>
                    <p>{limit.productDescription}</p>
                  </ProductNameContainer>
                </TDProductNameContainer>
                <td>до {formSumStringThousands(limit.volume)} руб.</td>
                <td>{limit.rate} % годовых</td>
                <td>до {limit.term} мес.</td>
                <td>{limit.bankShortName}</td>
                <td>
                  <Button
                    label="Воспользоваться"
                    onClick={() => navigateToCrediting(limit)}
                    w="fit-content"
                  />
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      {error.code === 404 && (
        <NotFoundContainer>
          <p>Предодобренные предложения отсутствуют.</p>
          <p>
            Это означает, что банкам нужны дополнительные документы, чтобы
            проанализировать вашу компанию.
          </p>
          <p>Подайте заявку на платформе, чтобы получить другие предложения.</p>
          <Button
            label="Создать заявку"
            onClick={() =>
              history.push(`/cabinet/${role}/application-form/create`)
            }
          />
        </NotFoundContainer>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ User, PreapprovedLimits }: CRM) => ({
  limits: PreapprovedLimits.getLimits.data,
  status: PreapprovedLimits.getLimits.status,
  error: PreapprovedLimits.getLimits.error,
  userCompanyInn: User.getUserData.data.companyInn
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getLimits, setInitializationFormData, setGuaranteesFormData },
    dispatch
  );

const PreapprovedLimitsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PreapprovedLimits)
);

export { PreapprovedLimitsConnect as PreapprovedLimits };
