import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Factor/model/actions/getCurrentFactoringSettingsByDebtorInn';

export interface GetCurrentFactoringSettingsByDebtorInnStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN {
  GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ = 'GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ',
  GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_GOT = 'GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_GOT',
  GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_ERR = 'GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_ERR'
}

export const initialState: GetCurrentFactoringSettingsByDebtorInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    debtorInn: '',
    status: '',
    volume: 0,
    limitUsage: 0,
    autoLimitPolicy: 0,
    factoringBaseRate: 0,
    vatSize: 0,
    factoringFullRate: 0,
    factualUsageOnly: false,
    gracePeriodBaseRate: 0,
    regressPeriodBaseRate: 0,
    regressPeriodFullRate: 0,
    defaultGracePeriodDays: 0,
    defaultRegressPeriodDays: 0,
    regressPeriodIsInfinite: false,
    defaultMaximalClaimDaysToMaturity: 0,
    defaultMaximalClaimSum: 0,
    defaultMaximalShareOfFunding: 0,
    defaultMaximalCessionShare: 0,
    defaultMinimalClaimDaysToMaturity: 0,
    defaultMinimalShareOfFunding: 0,
    defaultMinimalCessionShare: 0,
    fundingSharePolicy: 0,
    isRegressed: false,
    isNotHidden: false,
    isForOnboardedClientsOnly: false,
    signatureFilename: '',
    signatureFileIdentifier: '',
    requestFilename: '',
    requestFileIdentifier: '',
    gracePeriodFullRate: 0,
    rateIsSurplusToKeyRate: false
  },
  error: {}
};

export interface RequestDataType {
  debtorInn: string;
}

const getCurrentFactoringSettingsByDebtorInn = (
  state: GetCurrentFactoringSettingsByDebtorInnStoreState = initialState,
  action: {
    type: GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN;
    data: ResponseDataType;
    error: {};
  }
): GetCurrentFactoringSettingsByDebtorInnStoreState => {
  switch (action.type) {
    case GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCurrentFactoringSettingsByDebtorInn;
