import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CRMComponent } from '../EachCRM';
import {
  faGlobe,
  faSuitcase,
  faChartArea,
  faAddressCard,
  faChartBar,
  faUsers,
  faList
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { COMPANY_LIST_TYPE } from 'src/features/Companies/types';
import { HOLDING_LIST_TYPE } from 'src/features/Holdings/types';
import { SectionSeparatorStyled } from '../styles';
import { CompaniesList } from 'src/features/Companies/components/CompaniesList/CompaniesList';
import { HoldingsList } from 'src/features/Holdings/components/HoldingsList/HoldingsList';
import { ManagerList } from 'src/features/Companies/components/ManagerList/ManagerList';
import { AnalystList } from 'src/features/Companies/components/AnalystList/AnalystList';
import { PersonsList } from 'src/features/Persons/components/PersonsList/PersonsList';
import { CompaniesListCRM } from 'src/features/CompaniesLists/components/CompaniesListCRM/CompaniesLists';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const CompaniesSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Организации</SectionSeparatorStyled>
    )
  },
  {
    // TODO refactor
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: CompaniesList,
      path: '/crm/companies/all',
      label: 'Все организации',
      icon: <FontAwesomeIcon icon={faSuitcase} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: CompaniesListCRM,
      path: '/crm/companies_lists/all',
      label: 'Списки компаний',
      icon: <FontAwesomeIcon icon={faList} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: ManagerList,
      path: '/crm/companies/manager',
      label: 'Я менеджер',
      icon: <FontAwesomeIcon icon={faAddressCard} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: AnalystList,
      path: '/crm/companies/analyst',
      label: 'Я аналитик',
      icon: <FontAwesomeIcon icon={faChartBar} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: HoldingsList,
      path: '/crm/holdings/all',
      label: 'Холдинги',
      icon: <FontAwesomeIcon icon={faGlobe} fixedWidth={true} />,
      listType: HOLDING_LIST_TYPE.ALL
    })
  },
  // TODO - future release, target companies (good financials etc.)
  //{
  //  hasPermission: permissions =>
  //    [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //  ...createCompaniesListCRMComponent({
  //    path: '/crm/companies/targets',
  //    label: 'Целевые клиенты',
  //    icon: <FontAwesomeIcon icon={faBookmark} fixedWidth={true} />,
  //    listType: COMPANY_LIST_TYPE.TARGETS
  //  })
  //},
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: CompaniesList,
      path: '/crm/companies/backbone',
      label: 'Системообразующие',
      icon: <FontAwesomeIcon icon={faChartArea} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.BACKBONE
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: PersonsList,
      path: '/crm/persons',
      label: 'Бенефициары и руководители',
      icon: <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
      listType: COMPANY_LIST_TYPE.ALL
    })
  }
];
