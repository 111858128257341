import { HoldingsData } from '../actions/setHoldingFormData';

export interface SetHoldingDataStoreState extends Partial<HoldingsData> {}

export enum SET_HOLDING_DATA {
  SET = 'SET_HOLDING_DATA_SET',
  RESET = 'SET_HOLDING_DATA_RESET'
}

export const initialState: SetHoldingDataStoreState = {
  // amount: 0,
  // sourceUserId: 0,
};

const setHoldingFormData = (
  state: SetHoldingDataStoreState = initialState,
  action: {
    type: string;
    data: HoldingsData;
  }
): SetHoldingDataStoreState => {
  switch (action.type) {
    case SET_HOLDING_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_HOLDING_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setHoldingFormData;
