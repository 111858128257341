// TODO move

import styled from 'styled-components';

import { bgColor, lightGray, rm } from 'shared/styled';

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  row-gap: 10px;
`;

export const Title = styled.h2`
  margin-bottom: 15px;
  font-family: ${rm};
  color: #06243c;
`;
