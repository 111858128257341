import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getApiKey';

import { GET_API_KEY } from '../reducers/getApiKey';
import ApiKeysApi from '../api';

function* getApiKey(action: { type: GET_API_KEY }): Generator {
  try {
    const res: any = yield call(ApiKeysApi.getApiKey);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApiKeySaga(): Generator {
  yield takeLatest(GET_API_KEY.REQ, getApiKey);
}
