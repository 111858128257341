import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
} from '../actions/getDiscountingSettings';
import { GET_DISCOUNTING_SETTINGS } from '../reducers/getDiscountingSettings';
import SCFApi from '../api';

function* getDiscountingSettings(action: {
  type: GET_DISCOUNTING_SETTINGS;
  data: null;
}) {
  try {
    const res: any = yield call(SCFApi.getDiscountingSettings, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDiscountingSettingsSaga() {
  yield takeLatest(
    GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_REQ,
    getDiscountingSettings
  );
}
