import * as React from 'react';
import styled from 'styled-components';

import { LoaderBar } from 'Layouts/components/LoaderBar/LoaderBar';
import { Textarea } from 'shared/ui/Textarea';
import { Label } from 'shared/ui/Label';
import { getBgImage, getBorderColor } from 'shared/ui/InputFile/styles';

import { rr, rm, lightGray, lighterBlue, navyBlue } from 'shared/styled';

export const getBgColor = ({ isDragReject, isDragActive, isEmpty }) => {
  switch (true) {
    case isDragReject:
      return 'rgba(245, 55, 55, 0.05)';
    case isDragActive:
      return lighterBlue;
    default:
      return '#fff';
  }
};

export const CommentTextarea = styled(Textarea)`
  padding: 0;
  border: none;
  background-color: inherit;
`;

export const AddCommentContainer = styled.div`
  margin: 20px 0;

  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const DisabledBlock = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;

  background-color: rgba(255, 255, 255, 0.8);

  p {
    margin: 0;
    margin-top: -50px;
    padding-bottom: 10px;

    font-family: ${rr};
    font-size: 1.6rem;
    text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.1);
    color: ${navyBlue};
  }
`;

export const DropArea = styled.div`
  width: inherit;
  height: auto;
  outline: none;
`;

export const AddCommentBlockStyled = styled.div`
  width: 100%;
  height: auto;
  border-radius: 6px;

  border: 1px solid
    ${props => (!props.isEmpty ? lightGray : getBorderColor(props))};
  background-color: ${props => getBgColor(props)};
  background-image: ${props => (!props.isEmpty ? 'none' : getBgImage(props))};

  padding: ${({ isEmpty }) => (isEmpty ? '15px 20px 60px 15px' : '15px 20px')};
  outline: none;

  position: relative;
`;

export const AddCommentLabel = styled(Label)`
  margin-bottom: 20px;
  font-family: ${rm};
  font-size: 1.6rem;
  color: ${navyBlue};
`;

export const AddCommentLoaderBar = styled(({ isFiles, ...props }) => (
  <LoaderBar
    className={props.className}
    status={props.status}
    processText={props.processText}
  />
))`
  width: 100%;
  margin-bottom: ${({ isFiles }) => (isFiles ? '0' : '-50px')};
  position: relative;
`;
