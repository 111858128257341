import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE } from 'globaltypes';

import { setApplicationListView } from 'Application/actions/setApplicationListView';
import {
  RequestDataType as SetApplicatioListViewRequestDataType,
  APPLICATION_LIST_VIEWS
} from 'Application/reducers/setApplicationListView';

import { ToggleBtnStyled, TileViewBtnPart, TableViewBtnPart } from './styles';

interface StateToProps {
  viewType: APPLICATION_LIST_VIEWS;
}

interface DispatchToProps {
  setApplicationListView: (data: SetApplicatioListViewRequestDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class ApplicationViewToggleBtn extends React.Component<Props> {
  toggleView = (): void => {
    const { viewType, setApplicationListView } = this.props;

    viewType === APPLICATION_LIST_VIEWS.TABLE
      ? setApplicationListView({
          view: APPLICATION_LIST_VIEWS.TILE
        })
      : setApplicationListView({
          view: APPLICATION_LIST_VIEWS.TABLE
        });
  };

  render() {
    const { viewType } = this.props;
    return (
      <ToggleBtnStyled onClick={this.toggleView} viewType={viewType}>
        <TileViewBtnPart viewType={viewType} />
        <TableViewBtnPart viewType={viewType} />
      </ToggleBtnStyled>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  viewType: Application.setApplicationListView.view
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setApplicationListView
    },
    dispatch
  );

const ApplicationViewToggleBtnConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationViewToggleBtn);

export { ApplicationViewToggleBtnConnect as ApplicationViewToggleBtn };
