import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { history } from 'src/shared/utils/History';

import { Loader } from 'Layouts/components';

import {
  req as getFactoringContract,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getFactoringContractFactorRoleByGuid';

import {
  ContractsInfoPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  ContractsContainer,
  SelectContainerActive,
  SelectActive,
  MovingContainer,
  SelectContainerInactive,
  BackBtn,
  BackBtnText,
  ContractsHeader
} from './styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringContract: ResponseDataType;
}

interface DispatchToProps {
  getFactoringContract: (data: RequestDataType) => void;
}

interface State {
  pageUrl: string;
}

type Props = StateToProps & DispatchToProps;

class ContractsInfoPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: window.location.href
          .split('/')
          .filter(x => x.length > 0)
          .pop()
      };
      this.handlePageChange = this.handlePageChange.bind(this);
    }
  }

  handlePageChange(newHref: string) {
    newHref = window.location.href
      .split('/')
      .filter(x => x.length > 0)
      .pop();
    this.setState({ pageUrl: newHref });
  }

  initFetch = () => {
    const { getFactoringContract } = this.props;
    getFactoringContract({ id: this.state.pageUrl });
  };

  componentDidMount() {
    this.initFetch();
  }

  onBackClick = () => {
    history.push(`/factor/contracts?page=1`);
  };

  render() {
    const { status, factoringContract, error } = this.props;
    return (
      <ContractsInfoPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <ContractsContainer>
            <ContractsHeader>
              <h1>
                Договор № {factoringContract.contractNumber} от{' '}
                {format(
                  new Date(factoringContract.contractStartDate),
                  'dd.MM.yyyy'
                )}
              </h1>
              <BackBtn onClick={() => this.onBackClick()}>
                <BackBtnText>Назад</BackBtnText>
              </BackBtn>
            </ContractsHeader>

            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Поставщик:</ContractsInfoItemTitle>
                <p>{factoringContract.supplierInn}</p>
                <p>{factoringContract.supplierName}</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Дебитор:</ContractsInfoItemTitle>
                <p>{factoringContract.debtorInn}</p>
                <p>{factoringContract.debtorName}</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Реквизиты договора:</h1>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Наименование договора
                </ContractsInfoItemTitle>
                <p>{factoringContract.contractName}</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Номер договора</ContractsInfoItemTitle>
                <p>{factoringContract.contractNumber}</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Дата договора</ContractsInfoItemTitle>
                <p>
                  {format(
                    new Date(factoringContract.contractStartDate),
                    'dd.MM.yyyy'
                  )}
                </p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Срок действия договора
                </ContractsInfoItemTitle>
                {factoringContract.isPerpetual == true ? (
                  <p>Бессрочный</p>
                ) : (
                  <p>
                    {format(
                      new Date(factoringContract.contractStartDate),
                      'dd.MM.yyyy'
                    )}{' '}
                    -{' '}
                    {format(
                      new Date(factoringContract.contractEndDate),
                      'dd.MM.yyyy'
                    )}
                  </p>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Тип факторинга:</h1>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Без регресса</ContractsInfoItemTitle>
                {factoringContract.isRegressed == true ? (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>нет</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>да</p>
                    </TypeYesInactive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>нет</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>да</p>
                    </TypeYesActive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Открытый</ContractsInfoItemTitle>
                {factoringContract.isNotHidden == false ? (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>нет</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>да</p>
                    </TypeYesInactive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>нет</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>да</p>
                    </TypeYesActive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Параметры финансирования:</h1>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Минимальная доля уступки:
                </ContractsInfoItemTitle>
                <p>{factoringContract.cessionMinimalShare} %</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Максимальная доля уступки:
                </ContractsInfoItemTitle>
                <p>{factoringContract.cessionMaximalShare} %</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Минимальная доля финансирования:
                </ContractsInfoItemTitle>
                <p>{factoringContract.financeMinimalShare} %</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Максимальная доля финансирования:
                </ContractsInfoItemTitle>
                <p>{factoringContract.financeMaximalShare} %</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Льготный период:
                </ContractsInfoItemTitle>
                <p style={{ width: '100px' }}>
                  {factoringContract.gracePeriod} дней
                </p>
                {factoringContract.moveGracePeriodToBusinessDay == true ? (
                  <MovingContainer>
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                    <p>переносить на рабочий день</p>
                  </MovingContainer>
                ) : (
                  <MovingContainer>
                    <SelectContainerInactive />
                    <p>переносить на рабочий день</p>
                  </MovingContainer>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Период регресса</ContractsInfoItemTitle>
                <p style={{ width: '100px' }}>
                  {factoringContract.regressPeriod} дней
                </p>
                {factoringContract.moveRegressPeriodToBusinessDay == true ? (
                  <MovingContainer>
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                    <p>переносить на рабочий день</p>
                  </MovingContainer>
                ) : (
                  <MovingContainer>
                    <SelectContainerInactive />
                    <p>переносить на рабочий день</p>
                  </MovingContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          </ContractsContainer>
        )}
      </ContractsInfoPageStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  factoringContract: SCFFactor.getFactoringContractFactorRoleByGuid.data,
  status: SCFFactor.getFactoringContractFactorRoleByGuid.status,
  error: SCFFactor.getFactoringContractFactorRoleByGuid.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringContract
    },
    dispatch
  );

const ContractsInfoPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContractsInfoPage);

export { ContractsInfoPageConnect as ContractsInfoPage };
