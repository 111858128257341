export enum GET_SUPPLY_CONTRACT_AGREEMENT {
  REQ = 'GET_SUPPLY_CONTRACT_AGREEMENT_REQ',
  GOT = 'GET_SUPPLY_CONTRACT_AGREEMENT_GOT',
  ERR = 'GET_SUPPLY_CONTRACT_AGREEMENT_ERR'
}

export const req = (guid: string) => ({
  type: GET_SUPPLY_CONTRACT_AGREEMENT.REQ,
  guid
});

export const got = () => ({
  type: GET_SUPPLY_CONTRACT_AGREEMENT.GOT
});

export const err = (error = {}) => ({
  type: GET_SUPPLY_CONTRACT_AGREEMENT.ERR,
  error
});
