import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  AskStyled,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/CooperationForProducts/components/styled';
import { req as patchStopFactors } from '../actions/patchStopFactors';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  ProductPolicyContainer,
  ProductPolicyNegativeContainer
} from 'src/features/StopFactors/components/styled';
import {
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import {
  POLICY_NEGATIVE_EQUITY,
  POLICY_UNPROFITABLE_COMPANIES
} from 'src/features/StopFactors/types';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  isForCaucasusClient: boolean;
  isForCaucasusCustomer: boolean;
  isForCrimeanClient: boolean;
  isForCrimeanCustomer: boolean;
  withoutCreditHistory: boolean;
  unprofitableCompanyPolicy: POLICY_UNPROFITABLE_COMPANIES;
  activeUnprofitableCompanyPolicy: boolean;
  negativeCapitalPolicy: POLICY_NEGATIVE_EQUITY;
  activeNegativeCapitalPolicy: boolean;
  isAdminUnprofitableCompanyPolicy: boolean;
  isAdminNegativeCapitalPolicy: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchStopFactors: (id: string, data: any) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class StopFactorsForProduct extends React.Component<Props, State> {
  state: State = {
    isForCaucasusClient: null,
    isForCaucasusCustomer: null,
    isForCrimeanClient: null,
    isForCrimeanCustomer: null,
    withoutCreditHistory: null,
    unprofitableCompanyPolicy: null,
    activeUnprofitableCompanyPolicy: false,
    negativeCapitalPolicy: null,
    activeNegativeCapitalPolicy: false,
    isAdminUnprofitableCompanyPolicy: false,
    isAdminNegativeCapitalPolicy: false
  };

  componentDidMount() {
    if (this.props.product.unprofitableCompanyPolicy !== null) {
      this.setState({
        unprofitableCompanyPolicy: this.props.product.unprofitableCompanyPolicy,
        activeUnprofitableCompanyPolicy: true
      });
    }
    if (this.props.product.negativeCapitalPolicy !== null) {
      this.setState({
        negativeCapitalPolicy: this.props.product.negativeCapitalPolicy,
        activeNegativeCapitalPolicy: true
      });
    }
    this.setState({ ...this.props.product } as any);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      prevState.unprofitableCompanyPolicy !==
      this.state.unprofitableCompanyPolicy
    ) {
      this.setState({ isAdminUnprofitableCompanyPolicy: false });
    }
    if (prevState.negativeCapitalPolicy !== this.state.negativeCapitalPolicy) {
      this.setState({ isAdminNegativeCapitalPolicy: false });
    }
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    this.setState({ [key]: value } as Pick<any, keyof State>);
    if (key === 'activeUnprofitableCompanyPolicy') {
      this.props.patchStopFactors(id, {
        unprofitableCompanyPolicy: +this.state.unprofitableCompanyPolicy[0]
      });
    } else if (key === 'activeNegativeCapitalPolicy') {
      this.props.patchStopFactors(id, {
        negativeCapitalPolicy: +this.state.negativeCapitalPolicy[0]
      });
    } else {
      this.props.patchStopFactors(id, { [key]: value });
    }
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, false)
          }
          disabled={!isAdmin && this.state[buttonName] === true}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, true)
          }
          disabled={!isAdmin && this.state[buttonName] === false}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  handleProductPolicyUnprofitableClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeUnprofitableCompanyPolicy || isAdmin) {
      this.setState({ unprofitableCompanyPolicy: item });
    }
  };

  handleProductPolicyNegativeClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeNegativeCapitalPolicy || isAdmin) {
      this.setState({ negativeCapitalPolicy: item });
    }
  };

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    return (
      <ApplicationViewStyled>
        <AskStyled>Регион:</AskStyled>
        {this.renderToggleButton(
          'isForCaucasusClient',
          'Северный Кавказ, клиент'
        )}
        {this.renderToggleButton(
          'isForCaucasusCustomer',
          'Северный Кавказ, заказчик'
        )}
        {this.renderToggleButton('isForCrimeanClient', 'Крым, клиент')}
        {this.renderToggleButton('isForCrimeanCustomer', 'Крым, заказчик')}

        <ProductPolicyContainer
          product={this.state.unprofitableCompanyPolicy}
          active={this.state.activeUnprofitableCompanyPolicy}
        >
          <AskStyled>Политика в отношении убыточных компаний:</AskStyled>
          {Object.keys(POLICY_UNPROFITABLE_COMPANIES).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductPolicyUnprofitableClick(item)}
              >
                <Square
                  active={
                    this.state.unprofitableCompanyPolicy === item ||
                    this.state.unprofitableCompanyPolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.unprofitableCompanyPolicy === item &&
                (!this.state.activeUnprofitableCompanyPolicy || isAdmin) &&
                !this.state.isAdminUnprofitableCompanyPolicy && (
                  <Button
                    label="Сохранить"
                    disabled={
                      !isAdmin && this.state.activeUnprofitableCompanyPolicy
                    }
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({
                          isAdminUnprofitableCompanyPolicy: true
                        });
                      }
                      this.handleButtonClick(
                        'activeUnprofitableCompanyPolicy',
                        true
                      );
                    }}
                    h="20px"
                  />
                )}
            </div>
          ))}
        </ProductPolicyContainer>

        <ProductPolicyNegativeContainer
          product={this.state.negativeCapitalPolicy}
          active={this.state.activeNegativeCapitalPolicy}
        >
          <AskStyled>
            Политика в отношении отрицательного собственного капитала:
          </AskStyled>
          {Object.keys(POLICY_NEGATIVE_EQUITY).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductPolicyNegativeClick(item)}
              >
                <Square
                  active={
                    this.state.negativeCapitalPolicy === item ||
                    this.state.negativeCapitalPolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.negativeCapitalPolicy === item &&
                (!this.state.activeNegativeCapitalPolicy || isAdmin) &&
                !this.state.isAdminNegativeCapitalPolicy && (
                  <Button
                    label="Сохранить"
                    disabled={
                      !isAdmin && this.state.activeNegativeCapitalPolicy
                    }
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminNegativeCapitalPolicy: true });
                      }
                      this.handleButtonClick(
                        'activeNegativeCapitalPolicy',
                        true
                      );
                    }}
                    h="20px"
                  />
                )}
            </div>
          ))}
        </ProductPolicyNegativeContainer>

        <AskStyled>Кредитная история:</AskStyled>
        {this.renderToggleButton('withoutCreditHistory', 'Не проверяется')}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ StopFactors }: CRM) => ({
  status: StopFactors.patchStopFactors.status,
  error: StopFactors.patchStopFactors.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchStopFactors }, dispatch);

const StopFactorsForProductConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(StopFactorsForProduct)
);

export { StopFactorsForProductConnect as StopFactorsForProduct };
