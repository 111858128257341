import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/Person/actions/getSummaryPerson';
import { GET_SUMMARY_PERSON } from 'src/features/Person/reducers/getSummaryPerson';
import PersonApi from 'src/features/Person/api';

function* getSummaryPerson(action: {
  type: GET_SUMMARY_PERSON;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(PersonApi.getSummaryPerson, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSummaryPersonSaga(): Generator {
  yield takeLatest(GET_SUMMARY_PERSON.REQ, getSummaryPerson);
}
