import styled from 'styled-components';

import { bgColor , lightGray} from 'shared/styled';

export const ProfileInfoPopupStyled = styled.div`
background: white;
padding: 15px;
box-shadow: 0px -1px 2px 0 rgba(0,0,0,0.3);
`;

export const WrapWords = styled.div`
word-wrap: word;
`
export const PullRight = styled.div`
&>* {
float:right
}
`

export const PopupTrigger = styled.div`
padding: 8px;
border: 1px solid ${lightGray};
border-radius: 5px;
overflow:hidden;
text-overflow:ellipsis;
`
