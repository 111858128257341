import { ApplicationRead, Document } from 'Application/types';

export enum PROPERTY_FINANCING_CLIENT_TYPE {
  NONE = "",
  INDIVIDUAL = "INDIVIDUAL",
  ENTITY = "ENTITY",
  SOLE_PROPRIETOR = "SOLE_PROPRIETOR"
}

export enum PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE {
  NONE = "",
  NO_CONFIRMATION = "NO_CONFIRMATION",
  TWO_NDFL = "TWO_NDFL",
  THREE_NDFL = "THREE_NDFL",
  TURNOVER_51_REPORT = "TURNOVER_51_REPORT",
  BANK_CARD_REPORT = "BANK_CARD_REPORT",
  EMPLOYER_NOTE = "EMPLOYER_NOTE",
}


export interface PropertyFinancingWrite {
  term: number;
  inn: string;
  amount: number;
  clientType: PROPERTY_FINANCING_CLIENT_TYPE;
  clientAverageMonthlyIncome: number;
  clientIncomeConfirmationType: PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE;
  client: string;
  propertyFinancingDocuments: Document[];
  isRenting?: boolean;
  hasEvaluation?: boolean;
}
export type PropertyFinancingRead = PropertyFinancingWrite & ApplicationRead;
