import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getFactoringContractDebtorRoleByGuid';
import { GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID } from '../reducers/getFactoringContractDebtorRoleByGuid';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getFactoringContractDebtorRoleByGuid(action: {
  type: GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getFactoringContractDebtorRoleByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractDebtorRoleByGuidSaga() {
  yield takeLatest(
    GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID.GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_REQ,
    getFactoringContractDebtorRoleByGuid
  );
}
