import styled from 'styled-components';
import { blue, gray, rb, rr } from 'shared/styled';

export const CopyBlock = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 10;
    bottom: -20px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  :hover > p {
    color: ${blue};
  }
`;

export const PassportForm = styled.form`
  & > button {
    width: fit-content;
    height: 40px;
    margin-top: 30px;

    &:disabled {
      background-color: ${gray};
    }
  }
`;

export const PassportContainer = styled.div`
  display: flex;
  column-gap: 40px;

  & > div:nth-child(2) {
    width: 28%;
    height: 80%;
    height: 600px;

    textarea {
      max-width: 420px;
    }
  }
`;

export const PassportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 650px;
  background-color: #ece3e7;
  padding: 20px;
`;

export const PassportSerialNumberStyled = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  column-gap: 20px;

  p:nth-child(2) {
    border-bottom: 1.5px solid #a79897;
    padding-bottom: 2px;
    width: 50%;
  }

  input {
    height: 40px;
  }

  p {
    font-size: 18px;
    font-family: ${rb};
    color: #a79897;
    white-space: nowrap;
  }
`;

export const PassportIssuerNameStyled = styled.div`
  p {
    color: #a79897;
  }

  & > div > p {
    color: black;
    text-transform: uppercase;
  }
`;

export const PassportIssuanceStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 50px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;

    & > div {
      p {
        color: black;
        border-bottom: 1.5px solid #a79897;
        padding-bottom: 2px;
      }
    }

    input {
      height: 40px;
    }

    p {
      color: #a79897;
      white-space: nowrap;
    }
  }
`;

export const PassportNameStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 65%;
  align-self: flex-end;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;

    & > div {
      width: 50%;
      p {
        color: black;
        border-bottom: 1.5px solid #a79897;
        padding-bottom: 3px;
        text-transform: uppercase;
      }
    }

    & > p:nth-child(1) {
      width: 25%;
    }

    input {
      width: 90%;
      height: 40px;
    }

    p {
      color: #a79897;
      white-space: nowrap;
    }
  }
`;

export const PassportSexAndCodeStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 65%;
  align-self: flex-end;

  & > div:first-of-type > div {
    width: 130px;
    select {
      height: 40px;
    }
  }

  ${CopyBlock} {
    width: 100% !important;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;

    & > div {
      p {
        color: black;
        border-bottom: 1.5px solid #a79897;
        padding-bottom: 2px;
      }
    }

    input {
      height: 40px;
    }

    p {
      color: #a79897;
    }
  }
`;

export const PassportPlaceOfBirthStyled = styled.div`
  width: 65%;
  align-self: flex-end;

  textarea {
    max-width: 400px;
    height: 100px;
  }

  & > div > p {
    color: black;
    text-transform: uppercase;
  }

  p {
    color: #a79897;
  }
`;

export const PassportFullAddressStyled = styled.div`
  & > div > p {
    color: black;
    text-transform: uppercase;
  }

  textarea {
    max-width: 400px;
    height: 100px;
  }

  & > p {
    font-family: ${rb};
    text-align: center;
    color: #a79897;
  }

  p {
    color: #a79897;
  }
`;

export const InputArea = styled.textarea`
  max-width: 600px;
  width: 100%;
  padding: 10px;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #9fa6b2;
  font-family: ${rr};
`;

export const Divider = styled.div`
  width: 650px;
  align-self: center;
  background-color: #a79897;
  height: 1px;
`;
