import { PUT_REJECTED } from '../reducers/putRejected';

export const req = (id: number, { rejectSubject, rejectReason }) => ({
  type: PUT_REJECTED.REQ,
  id,
  rejectReason,
  rejectSubject
});

export const got = () => ({
  type: PUT_REJECTED.GOT
});

export const err = (error: {}) => ({
  type: PUT_REJECTED.ERR,
  error
});
