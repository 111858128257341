import ApiRequest from 'shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';
import ApiPutFiles from 'src/shared/utils/ApiPutFiles';

import { RequestDataType as UploadRegistriesRequestDataType } from 'entities/SCF/Debtor/model/reducers/uploadRegistries';
import { RequestDataType as SendSignatureRequestDataType } from 'entities/SCF/Debtor/model/reducers/sendSignature';
import { RequestDataType as SendSubSignatureRequestDataType } from 'entities/SCF/Debtor/model/reducers/sendSubSignature';
import { RequestDataType as PutEarlyPaymentApplicationDeclineRequestDataType } from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';
import { RequestDataType as PostAgreementSignatureDebtorRoleRequestDataType } from 'entities/SCF/Debtor/model/reducers/postAgreementSignatureDebtorRole';
import { RequestDataType as UploadDebtorContractDocumentRequestDataType } from 'entities/SCF/Debtor/model/reducers/uploadDebtorContractDocument';
import { RequestDataType as PutPurchaseContractSignatureDebtorRoleRequestDataType } from 'entities/SCF/Debtor/model/reducers/putPurchaseContractSignatureDebtorRole';
import { RequestDataType as PostDebtorNotificationSettingsRequestDataType } from 'entities/SCF/Debtor/model/reducers/postDebtorNotificationSettings';
import { RequestDataType as ApproveEarlyPaymentApplicationDebtorRoleRequestDataType } from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';
import { RequestDataType as PostAddFormToSupplyContracts } from 'entities/SCF/Debtor/model/actions/postAddFormToSupplyContracts';
import { RequestData as ReqGetDocumentForms } from 'entities/SCF/Debtor/model/actions/getDocumentForms';
import { RequestData as ReqSetDiscountMargin } from 'entities/SCF/Debtor/model/actions/patchSetDiscountMargin';
import { RequestData as ReqConfirmContractByDebtor } from 'entities/SCF/Debtor/model/actions/postConfirmContractByDebtor';
import { RequestDataType as GetSubRegistriesRequestDataType } from 'entities/SCF/Debtor/model/actions/getSubRegistries';
import { RequestDataType as GetEarlyPaymentApplicationsDebtorRoleRequestDataType } from 'entities/SCF/Debtor/model/actions/getEarlyPaymentApplicationsDebtorRole';
import { RequestDataType as GetOneDebtorRoleByGuidRequestDataType } from 'entities/SCF/Debtor/model/actions/getOneDebtorRoleByGuid';
import { RequestDataType as GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidRequestDataType } from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import { RequestDataType as GetFactoringLimitByDebtorInnRequestDataType } from 'entities/SCF/Debtor/model/actions/getFactoringLimitByDebtorInn';
import { RequestDataType as GetFactoringContractDebtorRoleByGuidRequestDataType } from 'entities/SCF/Debtor/model/actions/getFactoringContractDebtorRoleByGuid';
import { RequestDataType as RequestDataTypeDebtor } from 'entities/SCF/Debtor/model/actions/SCFRegisterDebtor';
import { RequestDataType as GetDebtorRegistryFilesRequestDataType } from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFiles';
import { RequestDataType as GetFactoringContractsDebtorRoleRequestDataType } from 'entities/SCF/Debtor/model/actions/getFactoringContractsDebtorRole';

export default class SCFDebtorApi {
  static registerDebtor(data: RequestDataTypeDebtor) {
    return ApiRequest('/api/account/debtor/register', 'post', data);
  }

  static putRejectDebtor(inn: string) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/supplier_role/reject_debtor/${inn}`,
      'put'
    );
  }

  static putRejectSupplier(inn: string) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/debtor_role/reject_supplier/${inn}`,
      'put'
    );
  }

  static uploadRegistries(data: UploadRegistriesRequestDataType) {
    return ApiUploadFiles(
      `/api/debtor_role/registry/upload_document`,
      data.file
    );
  }

  static sendSignature(data: SendSignatureRequestDataType) {
    return ApiRequest(
      '/api/debtor_role/registry/upload_signature',
      'post',
      data
    );
  }

  static sendSubSignature(data: SendSubSignatureRequestDataType) {
    return ApiRequest(
      '/api/debtor_role/subregistry/upload_signature',
      'post',
      data
    );
  }

  static getDebtorRegistryFiles(
    requestData: GetDebtorRegistryFilesRequestDataType
  ) {
    return ApiRequest(
      '/api/debtor_role/registry/show_files_list',
      'get',
      requestData
    );
  }

  static getDownloadRegistry(id: number): string {
    return `/api/debtor_role/registry/download_document/${id}`;
  }

  static getDownloadSignature(id: number): string {
    return `/api/debtor_role/registry/download_signature/${id}`;
  }

  static getSubRegistries(requestData: GetSubRegistriesRequestDataType) {
    return ApiRequest(
      `/api/debtor_role/factor_registry/registry/${requestData.id}`,
      'get'
    );
  }

  static downloadDebtorSubRegistry(documentId: string): string {
    return `/api/debtor_role/subregistry/download_document/${documentId}`;
  }

  static downloadDebtorSubSignature(documentId: string): string {
    return `/api/debtor_role/subregistry/download_signature/${documentId}`;
  }

  static getFactoringContractsDebtorRole(
    requestData: GetFactoringContractsDebtorRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/factoring_contract/debtor_role/my',
      'get',
      requestData
    );
  }

  static getFactoringContractDebtorRoleByGuid(
    requestData: GetFactoringContractDebtorRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_contract/debtor_role/id/${requestData.id}`,
      'get'
    );
  }

  static getEarlyPaymentApplicationsDebtorRole(
    requestData: GetEarlyPaymentApplicationsDebtorRoleRequestDataType
  ) {
    return ApiRequest(
      '/api/early_payment_applications/debtor_role/my',
      'get',
      requestData
    );
  }

  static putEarlyPaymentApplicationDecline(
    data: PutEarlyPaymentApplicationDeclineRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/debtor_role/${data.guid}/decline`,
      'put',
      data.body
    );
  }

  static downloadDebtorAgreementFile(guid: string): string {
    return `/api/early_payment_applications/debtor_role/${guid}/get_file?fileType=agreement`;
  }

  static downloadArchiveFileDebtor(guid: string) {
    return `/api/early_payment_applications/debtor_role/${guid}/get_file?fileType=archive`;
  }

  static downloadDebtorSignatureFile(guid: string): string {
    return `/api/early_payment_applications/debtor_role/${guid}/get_file?fileType=my_signature`;
  }

  static downloadSupplierSignatureFile(guid: string): string {
    return `/api/early_payment_applications/debtor_role/${guid}/get_file?fileType=supplier_signature`;
  }

  static postAgreementSignatureDebtorRole(
    data: PostAgreementSignatureDebtorRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/debtor_role/${
        data.guid
      }/accept_agreement`,
      'post',
      data
    );
  }

  static getOneDebtorRoleByGuid(
    requestData: GetOneDebtorRoleByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/debtor_role/${requestData.guid}/get_one`,
      'get',
      requestData.guid
    );
  }

  static getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid(
    data: GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidRequestDataType
  ) {
    return ApiRequest(
      `/api/monetary_claim/debtor_role/early_payment_application/${data.guid}`,
      'get',
      data.guid
    );
  }

  static getFactoringLimitByDebtorInn(
    data: GetFactoringLimitByDebtorInnRequestDataType
  ) {
    return ApiRequest(
      `/api/factoring_limit/debtor_role/${data.debtorInn}`,
      'get',
      data.debtorInn
    );
  }

  static getDebtorRegistryFileSettingsCurrent() {
    return ApiRequest(
      '/api/debtor_registry_file_settings/debtor_role/current',
      'get'
    );
  }

  static uploadDebtorContractDocument(
    data: UploadDebtorContractDocumentRequestDataType
  ) {
    return ApiPutFiles(
      `/api/purchase_contract/debtor_role/${data.guid}/upload`,
      data.file
    );
  }

  static downloadDebtorPurchaseContractSignatureFile(guid: string): string {
    return `/api/purchase_contract/debtor_role/${guid}/get_signature_file`;
  }

  static putPurchaseContractSignatureDebtorRole(
    data: PutPurchaseContractSignatureDebtorRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/purchase_contract/debtor_role/${data.guid}/sign`,
      'put',
      data
    );
  }

  static getStartRateDebtorRole() {
    return ApiRequest('/api/users/debtor_role/scf_start');
  }

  static getDebtorNotificationSettings() {
    return ApiRequest(
      '/api/debtor_user_notification_settings/debtor_role/current'
    );
  }

  static postDebtorNotificationSettings(
    data: PostDebtorNotificationSettingsRequestDataType
  ) {
    return ApiRequest(
      '/api/debtor_user_notification_settings/debtor_role/new',
      'post',
      data
    );
  }

  static getMonetaryClaimsSuppliesRole(data: any) {
    return ApiRequest(`/api/monetary_claim/debtor_role/my`, 'get', data);
  }

  static approveEarlyPaymentApplicationDebtorRole(
    data: ApproveEarlyPaymentApplicationDebtorRoleRequestDataType
  ) {
    return ApiRequest(
      `/api/early_payment_applications/debtor_role/${data.guid}/approve`,
      'put',
      data.body
    );
  }

  static getDebtorSignatureFile(guid: string) {
    return ApiRequest(
      `/api/purchase_contract/debtor_role/${guid}/get_signature_file`
    );
  }

  static getDebtorCounterSidebar() {
    return ApiRequest(`/api/users/debtor_role/counter`);
  }

  static putAcceptByDebtor(guid: string) {
    return ApiRequest(
      `/api/purchase_contract/debtor_role/${guid}/accept`,
      'put'
    );
  }

  static putDeclineByDebtor(guid: string) {
    return ApiRequest(
      `/api/purchase_contract/debtor_role/${guid}/decline`,
      'put'
    );
  }

  static getByMonetaryClaimDebtor(guid: string) {
    return ApiRequest(
      `/api/supply_documents_of_monetary_claims/debtor_role/claim/${guid}`
    );
  }

  static getDocumentForms(data: ReqGetDocumentForms) {
    return ApiRequest('/api/document_forms/debtor_role/my', 'get', data);
  }

  static getFormFile(guid: string) {
    return ApiRequest(`/api/document_forms/debtor_role/${guid}/get_form_file`);
  }

  static getDocumentFormBySupplyContract(guid: string) {
    return ApiRequest(
      `/api/document_forms_of_supply_contracts/debtor_role/supply_contract/${guid}`
    );
  }

  static postAddFormToSupplyContracts(data: PostAddFormToSupplyContracts) {
    return ApiRequest(
      `/api/document_forms_of_supply_contracts/debtor_role/add`,
      'post',
      data
    );
  }

  static patchSetDiscountMargin(inn: string, data: ReqSetDiscountMargin) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/debtor_role/${inn}`,
      'patch',
      data
    );
  }

  static getContractByDebtor(guid: string) {
    return ApiRequest(`/api/bank_account/debtor_role/get_by_contract/${guid}`);
  }

  static postAddToContractByDebtor(contractAccount: string, guid: string) {
    return ApiRequest(
      `/api/bank_account/debtor_role/${contractAccount}/add_to_contract/${guid}?type=1`,
      'post'
    );
  }

  static patchPurchaseContractDebtor(
    guid: string,
    data: {
      paymentDeferment: number;
      paymentDefermentFixed: boolean;
    }
  ) {
    return ApiRequest(
      `/api/purchase_contract/debtor_role/${guid}`,
      'patch',
      data
    );
  }

  static postConfirmContractByDebtor(
    accountGuid: string,
    contractGuid: string,
    data: ReqConfirmContractByDebtor
  ) {
    return ApiRequest(
      `/api/bank_account/debtor_role/${accountGuid}/confirm_for_contract/${contractGuid}`,
      'post',
      data
    );
  }
}
