import { combineReducers } from 'redux';
import updateTypeFilter, {
  TypeFilterData
} from './TypeFilter/reducers/updateTypeFilter';
import updateStatusesFilter, {
  StatusesFilterData
} from './StatusFilter/reducers/updateStatusesFilter';
import updateInternalAgentsFilter, {
  InternalAgentsFilterData
} from './InternalAgentsFilter/reducers/updateInternalAgentsFilter';
import getFiltersForApplicationListType, {
  GetFiltersForApplicationListTypeStoreState
} from './StatusFilter/reducers/getFiltersForApplicationListType';
import updateINNFilter, {
  INNFilterData
} from './INNFilter/reducers/updateINNFilter';
import updateDateFilter, {
  DateFilterData
} from './DateFilter/reducers/updateDateFilter';
import updateClientNameFilter, {
  ClientNameFilterData
} from './NameClientFilter/reducers/updateClientNameFilter';
import updateStatusFilter, {
  StatusFilterData
} from './Status/reducers/updateStatusFilter';

export interface FiltersReducers {
  updateTypeFilter: TypeFilterData;
  updateStatusesFilter: StatusesFilterData;
  updateInternalAgentsFilter: InternalAgentsFilterData;
  getFiltersForApplicationListType: GetFiltersForApplicationListTypeStoreState;
  updateINNFilter: INNFilterData;
  updateDateFilter: DateFilterData;
  updateClientNameFilter: ClientNameFilterData;
  updateStatusFilter: StatusFilterData;
}

export const Filters = combineReducers({
  updateTypeFilter,
  updateStatusesFilter,
  updateInternalAgentsFilter,
  getFiltersForApplicationListType,
  updateINNFilter,
  updateDateFilter,
  updateClientNameFilter,
  updateStatusFilter
});
