import getCreditProductsSaga from './getCreditProducts';
import getPledgesSaga from './getPledges';
import getClientsListSaga from './getClientsList';
import postNewClientSaga from './postNewClient';
import getBeneficiariesSaga from './getBeneficiaries';
import getFinancialDocumentsSaga from './getFinancialDocuments';
import getSubsidariesClientSaga from './getSubsidariesClient';

export default [
  getCreditProductsSaga,
  getPledgesSaga,
  getClientsListSaga,
  postNewClientSaga,
  getBeneficiariesSaga,
  getFinancialDocumentsSaga,
  getSubsidariesClientSaga
];
