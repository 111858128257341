import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postAddTenderById';

import { POST_ADD_TENDER_BY_ID } from '../reducers/postAddTenderById';
import ApplicationApi from '../api';

function* postAddTenderById(action: {
  type: POST_ADD_TENDER_BY_ID;
  id: string;
  tenderId: number;
}): Generator {
  try {
    yield call(ApplicationApi.postAddTenderById, action.id, action.tenderId);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddTenderByIdSaga(): Generator {
  yield takeLatest(POST_ADD_TENDER_BY_ID.REQ, postAddTenderById);
}
