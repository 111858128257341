export enum FACTORING_LIMIT_LIST_TYPE {
  ALL = 'ALL'
}

export interface FactoringLimitType {
  id: number;
  debtorINN: string;
  debtorShortName: string;
  factorINN: string;
  factorShortName: string;
  volume: number;
  setDate: Date;
  isActual: boolean;
  productId: number;
  productName: string;
  isDeleted: boolean;
}
