import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestData
} from 'Application/actions/patchUpdateCompanyBlank';
import { PATCH_UPDATE_COMPANY_BLANK } from 'src/features/Application/reducers/patchUpdateCompanyBlank';

import ApplicationApi from 'Application/api';

function* patchUpdateCompanyBlank(action: {
  type: PATCH_UPDATE_COMPANY_BLANK;
  inn: string;
  data: RequestData;
}) {
  try {
    yield call(ApplicationApi.patchUpdateCompanyBlank, action.inn, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchUpdateCompanyBlankSaga() {
  yield takeLatest(PATCH_UPDATE_COMPANY_BLANK.REQ, patchUpdateCompanyBlank);
}
