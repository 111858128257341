import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  BarLinksFactoring,
  PuzzleIcon,
  SlidersIcon,
  ClipboardIcon,
  LinkIcon,
  ListAltIcon,
  InboxIcon,
  BarLinkCustom,
  GavelIcon
} from './styles';
import {
  BarSectionStyled,
  BarLinks,
  BarLinkText,
  BarTitleArea,
  BarTitle,
  CounterBox
} from 'src/features/SCF/UI/BarSection';
import { ResponseData } from 'entities/SCF/Supplier/model/reducers/getSupplierCounterSidebar';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { Spinner } from 'src/features/Layouts/components';
import './styles/style.css';
import { NavigationLinkStyled } from 'src/features/Layouts/components/Sidebar/NavigationLink/styles';

interface OwnProps {
  type: string | JSX.Element;
}

interface StateToProps {
  counter: ResponseData;
  status: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

type Props = RouteComponentProps & OwnProps & StateToProps;

const LabelInfo = ({ info }) => !!info && <CounterBox>{info}</CounterBox>;

const BarSection: React.FC<Props> = ({ counter, status, permissions }) => {
  function showQuantity(key: string): React.ReactNode | number {
    return status === REQUEST_STATUSES.REQUEST ? (
      <Spinner size={22} />
    ) : (
      counter[key]
    );
  }

  return (
    <BarSectionStyled>
      <BarLinks>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/debtors',
              search: '?page=1'
            }}
          >
            <PuzzleIcon className="link-icon-puzzle" />
            <BarLinkText className="link-text">Мои покупатели</BarLinkText>
            <LabelInfo info={showQuantity('newBuyersQty')} />
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/supply_contracts',
              search: '?page=1'
            }}
          >
            <ClipboardIcon className="link-icon-clipboard" />
            <BarLinkText className="link-text">Договоры поставки</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/claims',
              search: '?page=1'
            }}
          >
            <ListAltIcon className="link-icon-list-alt" />
            <BarLinkText className="link-text">Денежные требования</BarLinkText>
            <LabelInfo info={showQuantity('monetaryClaimsQty')} />
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/discount_auctions',
              search: '?page=1'
            }}
          >
            <GavelIcon className="link-icon-gavel" />
            <BarLinkText className="link-text">Мои аукционы</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/early_payment_applications',
              search: '?page=1'
            }}
          >
            <InboxIcon className="link-icon-inbox" />
            <BarLinkText className="link-text">
              Заявки ранней оплаты
            </BarLinkText>
            <LabelInfo info={showQuantity('epaQty')} />
          </NavigationLinkStyled>
        </BarLinkCustom>
      </BarLinks>
      <BarTitleArea>
        <BarTitle>Факторинг</BarTitle>
      </BarTitleArea>
      <BarLinksFactoring>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/check_limits'
            }}
          >
            <SlidersIcon className="link-icon-sliders" />
            <BarLinkText className="link-text">Проверить лимиты</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/connections',
              search: '?page=1'
            }}
          >
            <LinkIcon className="link-icon-link" />
            <BarLinkText className="link-text">Мои связки</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/supplier/factoring_contracts',
              search: '?page=1'
            }}
          >
            <ClipboardIcon className="link-icon-clipboard" />
            <BarLinkText className="link-text">Договоры факторинга</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
      </BarLinksFactoring>
    </BarSectionStyled>
  );
};

const mapStateToProps = ({ SCFSupplier, User }: STORE) => ({
  counter: SCFSupplier.getSupplierCounterSidebar.data,
  status: SCFSupplier.getSupplierCounterSidebar.status,
  permissions: User.getUserData.data.permissions
});

const BarSectionConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(BarSection)
);

export { BarSectionConnect as BarSection };
