import styled from 'styled-components';
import { blue, lighterBlue, rr } from 'shared/styled';

export const GroupFilters = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FilterCategory = styled.button`
  cursor: pointer;
  border: none;
  font-family: ${rr};
  padding: 10px;
  background: ${({ active }) => (active ? `${blue}` : `${lighterBlue}`)};
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  border-radius: 5px;
  font-size: 12px;

  & > svg {
    margin-right: 5px;
  }

  :hover {
    background: ${({ active }) => (active ? '#0160B6' : '#D0E9FF')};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 20px;
  background-color: ${lighterBlue};
`;

export const ContainerProductFilter = styled(Container)`
  display: flex;
`;

export const ContainerClientFilter = styled(Container)`
  max-width: 1000px;
`;

export const ContainerContractFilter = styled(Container)`
  max-width: 260px;

  & > fieldset {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    max-height: 100px;
  }

  & > fieldset > :nth-child(5) {
    margin-top: 23px;
  }
`;

export const ContainerStopFactorFilter = styled(Container)`
  column-gap: 30px;
`;

export const ContainerCashbackFilter = styled(Container)`
  column-gap: 10px;
`;

export const ProgressWrap = styled.div`
  position: relative;
  background: radial-gradient(
      circle closest-side,
      white 0,
      white 70%,
      transparent 55%,
      transparent 100%,
      white 0
    ),
    ${({ percent }) =>
      `conic-gradient(${
        percent < 40
          ? '#F53737'
          : percent < 60
            ? '#FF7023'
            : percent < 80
              ? '#FFA823'
              : percent >= 80 && '#1CCA94'
      } 0, ${
        percent < 40
          ? '#F53737'
          : percent < 60
            ? '#FF7023'
            : percent < 80
              ? '#FFA823'
              : percent >= 80 && '#1CCA94'
      } ${percent}%, #e8e8e8 0, #e8e8e8 100%)`};
  width: 45px;
  height: 45px;
  margin: 0 auto;

  & > figcaption {
    position: absolute;
    left: 51%;
    top: 53%;
    transform: translate(-50%, -50%);
    font-size: 10px;
  }
`;

export const TermContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;

  & > p {
    margin: 20px 0 0 10px;
  }
`;

export const PreferentialContainer = styled.div`
  margin-left: 20px;
`;

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
`;

export const TableContainer = styled.div`
  margin-bottom: 20px;
`;
