import styled from 'styled-components';
import { Input } from 'shared/ui/Input';

export const WrapperInputDetail = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > p {
    min-width: 100px;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const InputDetail = styled(Input)`
  max-width: 200px;
`;
