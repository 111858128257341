import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getSubsidiaries';

import { GET_SUBSIDIARIES } from '../reducers/getSubsidiaries';
import BeneficiariesApi from '../api';

function* getSubsidiaries(action: {
  type: GET_SUBSIDIARIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BeneficiariesApi.getSubsidiaries, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSubsidiariesSaga(): Generator {
  yield takeLatest(GET_SUBSIDIARIES.REQ, getSubsidiaries);
}
