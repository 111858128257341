import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { got, err } from 'Application/actions/updateInitializationFormData';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { UPDATE_INITIALIZATION_FORM_DATA } from 'Application/reducers/updateInitializationFormData';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import { APPLICATION_PROCESS_MODES } from 'Application/reducers/setApplicationProcessMode';

import ApplicationApi from 'Application/api';

function* updateInitializationFormData(action: {
  type: UPDATE_INITIALIZATION_FORM_DATA;
  data: SetInitializationApplicationType;
}) {
  try {
    const applicationProcessMode = yield select(
      ({ Application }: STORE) => Application.setApplicationProcessMode.mode
    );

    if (applicationProcessMode === APPLICATION_PROCESS_MODES.CREATE) {
      const res: { id: number } = yield call(ApplicationApi.createApplication);

      yield call(ApplicationApi.makeApplicationDraft, {
        ...action.data,
        id: res.id
      });
      yield put(setInitializationFormData({ id: res.id }));
      yield put(getApplicationsQuantity());
    }

    if (applicationProcessMode === APPLICATION_PROCESS_MODES.EDIT) {
      yield call(ApplicationApi.updateInitializationStepData, action.data);
    }

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* updateInitializationFormDataSaga() {
  yield takeLatest(
    UPDATE_INITIALIZATION_FORM_DATA.REQ,
    updateInitializationFormData
  );
}
