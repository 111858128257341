import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  DOCUMENT_UPLOAD_TYPE,
  BorrowerCompany,
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES,
  GuaranteesApplicationRead
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { reset as resetApplication } from 'Application/actions/getApplication';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';

import { req as getBorrowerCompaniesList } from 'BorrowerCompanies/actions/getBorrowerCompaniesList';
import { req as saveBorrowerCompaniesList } from 'BorrowerCompanies/actions/saveBorrowerCompaniesList';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import { RequestDataType as GetBorrowerCompaniesListRequestDataType } from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';
import { RequestDataType as SaveBorrowerCompaniesListDataType } from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';

import { TRANSITION_DIRECTIONS } from 'Common';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationBtnsWrapper
} from 'Application/components/ApplicationForm/ApplicationProcess/styles';
import { UploadTypeButton, UploadTypeButtonSlim } from './styles';

import { createValidaton } from './validator';
import { FILE_UPLOAD_BLOCK_TYPE } from '../BorrowerCompanyFileUploadBlock';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import { setFileUploadBlock } from 'Application/actions/setFileUploadBlock';
import { SetFileUploadBlockType } from 'src/features/Application/reducers/setFileUploadBlockType';
import { BorrowerCompaniesTabs } from '../BorrowerCompaniesTabs/BorrowerCompaniesTabs';
import { Button } from 'src/shared/ui/Button';

interface OwnProps {
  goBack: () => void;
  borrowerCompanyFileUploadBlock?: any; // TODO create interface for this
  borrowerCompanyFileUploadBlockType?: FILE_UPLOAD_BLOCK_TYPE;
  disableApplicationDocumentsUpload?: boolean;
}

interface StateToProps {
  externalApplication: Partial<GuaranteesApplicationRead>;
  companies: BorrowerCompany[];
  borrowerCompaniesUploadStatus: REQUEST_STATUSES;
  borrowerCompaniesDeleteStatus: REQUEST_STATUSES;
  allowFlags: GetApplicationFlagsResponseDataType;
  createStatus: REQUEST_STATUSES;
  deleteStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  setFileUploadBlock: (data: SetFileUploadBlockType) => void;
  getBorrowerCompaniesList: (
    data: GetBorrowerCompaniesListRequestDataType
  ) => void;
  saveBorrowerCompaniesList: (data: SaveBorrowerCompaniesListDataType) => void;
  resetApplication: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  OwnProps &
  StateToProps &
  DispatchToProps;

const BorrowerCompanies: React.FC<Props> = ({
  resetApplication,
  externalApplication,
  getBorrowerCompaniesList,
  companies,
  createStatus,
  deleteStatus,
  borrowerCompanyFileUploadBlockType,
  borrowerCompanyFileUploadBlock,
  borrowerCompaniesUploadStatus,
  borrowerCompaniesDeleteStatus,
  saveBorrowerCompaniesList,
  disableApplicationDocumentsUpload,
  goBack,
  setFileUploadBlock,
  allowFlags,
  history,
  match
}) => {
  const [dynamicValidatiorFields, setDynamicValidatiorFields] = React.useState(
    {}
  );

  let Validator = createValidaton([]);

  React.useEffect(
    () => {
      getBorrowerCompaniesList({ applicationId: +match.params.id });
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      formDynamicValidatiorFields(companies);
    },
    [match.params.id, companies]
  );

  React.useEffect(
    () => {
      if (
        borrowerCompaniesUploadStatus === REQUEST_STATUSES.GOT ||
        borrowerCompaniesDeleteStatus === REQUEST_STATUSES.GOT ||
        createStatus === REQUEST_STATUSES.GOT ||
        deleteStatus === REQUEST_STATUSES.GOT
      ) {
        getBorrowerCompaniesList({ applicationId: +match.params.id });
      }
    },
    [
      borrowerCompaniesUploadStatus,
      borrowerCompaniesDeleteStatus,
      createStatus,
      deleteStatus
    ]
  );

  const slimClick = () => {
    const btnSlim = document.getElementById('slimUploadBtn');
    const btnDefault = document.getElementById('defaultUploadBtn');
    btnSlim.style.background = '#F1F8FF';
    btnSlim.style.color = '#3F4E65';
    btnDefault.style.background = 'none';
    btnDefault.style.color = '#9FA6B2';
  };

  const defaultClick = () => {
    const btnSlim = document.getElementById('slimUploadBtn');
    const btnDefault = document.getElementById('defaultUploadBtn');
    btnSlim.style.background = 'none';
    btnDefault.style.background = '#F1F8FF';
    btnDefault.style.color = '#3F4E65';
    btnSlim.style.color = '#9FA6B2';
  };

  const formDynamicValidatiorFields = (companies: BorrowerCompany[]) => {
    const dynamicValidatiorFields = companies.reduce((fieldsObj, company) => {
      fieldsObj[`name-${company.id}`] = company.name;
      fieldsObj[`individualNumber-${company.id}`] = company.individualNumber;
      fieldsObj[`documents-${company.id}`] = company.documents;
      fieldsObj[`roles-${company.id}`] = company.roles;
      return fieldsObj;
    }, {});

    setDynamicValidatiorFields(dynamicValidatiorFields);

    Validator = createValidaton(Object.keys(dynamicValidatiorFields));
  };

  const onGroupFilesUpload = files => {
    uploadDocuments({
      id: +match.params.id,
      files,
      type: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });

    formDynamicValidatiorFields(companies);
  };

  const onGroupFilesRemove = (data: DeleteDocumentRequestDataType) => {
    deleteDocument({
      ...data,
      subType: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });

    formDynamicValidatiorFields(companies);
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const [fieldName, companyId] = name.split('-');

    const checkRoles = ({ roles }, value) =>
      roles.includes(value)
        ? roles.filter(item => item !== value)
        : [...roles, value];

    const newCompanies = companies.map(item => {
      if (item.id === parseInt(companyId, 10)) {
        item[fieldName] =
          fieldName === 'roles' ? checkRoles(item, value) : value;
      }
      return item;
    });

    formDynamicValidatiorFields(newCompanies);
  };

  const onSubmit = () => {
    const { id } = match.params;
    const { financingType } = externalApplication;

    Validator.showAllErrors();

    if (allowFlags.allowBlankFields === false && !Validator.isFormValid())
      return;

    companies.length !== 0 &&
      saveBorrowerCompaniesList({ borrowerCompanies: companies });

    resetApplication();

    if (!!id) {
      history.push({
        pathname: `/cabinet/partner/application-${APPLICATION_TYPE_ROUTES[
          financingType
        ].replace('_', '-')}/${id}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    }
  };

  const { errors } = Validator.insertArgs({
    disableApplicationDocumentsUpload: [disableApplicationDocumentsUpload],
    borrowerCompanyFileUploadBlockType: [borrowerCompanyFileUploadBlockType]
  }).validate({
    ...dynamicValidatiorFields
  });

  const {
    groupDocuments: groupDocumentsError,
    ...dynamicValidatiorFieldErrors
  } = errors;
  const dynamicValidatiorFieldErrorsObject = Object.keys(
    dynamicValidatiorFieldErrors
  ).reduce((errorsObj, field) => {
    const [fieldName, companyId] = field.split('-');

    errorsObj[companyId] = {
      name: dynamicValidatiorFieldErrors[`name-${companyId}`],
      individualNumber:
        dynamicValidatiorFieldErrors[`individualNumber-${companyId}`],
      documents: dynamicValidatiorFieldErrors[`documents-${companyId}`],
      roles: dynamicValidatiorFieldErrors[`roles-${companyId}`]
    };

    const isValid =
      Object.values(errorsObj[companyId]).filter(error => !!error).length === 0;
    errorsObj[companyId]['isValid'] = isValid;

    return errorsObj;
  }, {});

  return (
    <ApplicationFormStyled>
      <CloseApplicationBtn
        onClick={() =>
          openModal({
            name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS
          })
        }
      />

      <ApplicationStepTitle>
        Компании
        {externalApplication.financingType === APPLICATION_TYPES.GUARANTEES
          ? ' Принципала'
          : ' заемщика'}
      </ApplicationStepTitle>
      {allowFlags.allowSimpleProcess &&
        externalApplication.amount < 125000000 &&
        externalApplication.financingType === APPLICATION_TYPES.GUARANTEES && (
          <div>
            <UploadTypeButton
              id="defaultUploadBtn"
              onClick={() => {
                setFileUploadBlock({ type: 'default' });
                defaultClick();
              }}
            >
              Стандартная заявка
            </UploadTypeButton>
            <UploadTypeButtonSlim
              id="slimUploadBtn"
              onClick={() => {
                setFileUploadBlock({ type: 'slim' });
                slimClick();
              }}
            >
              Упрощенная заявка
            </UploadTypeButtonSlim>
          </div>
        )}

      <BorrowerCompaniesTabs
        tabs={companies.map((company, index) => ({
          label: !!company.name ? company.name : `Компания №${index + 1}`,
          data: company,
          errors: dynamicValidatiorFieldErrorsObject[company.id]
        }))}
        borrowerCompanyFileUploadBlock={borrowerCompanyFileUploadBlock}
        onChange={onChange}
      />

      <ApplicationBtnsWrapper>
        <Button template="backBtn" label="Назад" onClick={goBack} />
        <Button template="nextBtn" label="Продолжить" onClick={onSubmit} />
      </ApplicationBtnsWrapper>
    </ApplicationFormStyled>
  );
};

const mapStateToProps = ({
  Application,
  ExternalAgentRole,
  BorrowerCompanies
}: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  companies: BorrowerCompanies.getBorrowerCompaniesList.data,
  borrowerCompaniesUploadStatus:
    BorrowerCompanies.uploadBorrowerCompanyDocuments.status,
  borrowerCompaniesDeleteStatus:
    BorrowerCompanies.deleteBorrowerCompanyDocument.status,
  createStatus: BorrowerCompanies.createBorrowerCompany.status,
  deleteStatus: BorrowerCompanies.deleteBorrowerCompany.status,
  allowFlags: Application.getApplicationFlags.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBorrowerCompaniesList,
      saveBorrowerCompaniesList,
      setFileUploadBlock,
      resetApplication
    },
    dispatch
  );

const BorrowerCompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompanies)
);

export { BorrowerCompaniesConnect as BorrowerCompanies };
