import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getProductsList';

import { GET_PRODUCTS_LIST_BANK } from '../reducers/getProductsList';
import ProductsApi from '../api';

function* getProductsListBank(action: {
  type: GET_PRODUCTS_LIST_BANK;
  data;
}): Generator {
  try {
    let res;

    res = yield call(ProductsApi.getAllProducts, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductsListBankSaga(): Generator {
  yield takeLatest(GET_PRODUCTS_LIST_BANK.REQ, getProductsListBank);
}
