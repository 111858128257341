import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getPurchaseContractByGuid';
import { GET_PURCHASE_CONTRACT_BY_GUID } from '../reducers/getPurchaseContractByGuid';
import SCFApi from '../api';

function* getPurchaseContractByGuid(action: {
  type: GET_PURCHASE_CONTRACT_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getPurchaseContractByGuid, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPurchaseContractByGuidSaga() {
  yield takeLatest(
    GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_REQ,
    getPurchaseContractByGuid
  );
}
