import styled from 'styled-components';

import { blue } from 'shared/styled';

export const ApplicationListEmptyStyled = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    line-height: 25px;
    text-align: center;
    white-space: pre-line;

    a {
      color: ${blue};
      text-decoration: none;
    }
  }
`;
