import styled from 'styled-components';
import { blue, rr } from 'shared/styled';

export const TDCopyTooltip = styled.td`
  position: relative;
  overflow: visible !important;

  div:first-of-type {
    margin-bottom: 5px;
  }
`;

export const CopyBlock = styled.div`
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};
  text-overflow: ellipsis;
  overflow: hidden;

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 10;
    bottom: -20px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  :hover{
    color: ${blue};
  }
`;
