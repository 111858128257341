import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutSendToClientStoreState extends Reducer {}

export enum PUT_SEND_TO_CLIENT {
  REQ = 'PUT_SEND_TO_CLIENT_REQ',
  GOT = 'PUT_SEND_TO_CLIENT_GOT',
  ERR = 'PUT_SEND_TO_CLIENT_ERR'
}

export const initialState: PutSendToClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putSendToClient = (
  state: PutSendToClientStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PutSendToClientStoreState => {
  switch (action.type) {
    case PUT_SEND_TO_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_SEND_TO_CLIENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_SEND_TO_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putSendToClient;
