import * as React from 'react';
import { Col } from 'react-grid-system';

import { BorrowerCompany } from 'Application/types';
import { Error } from 'shared/ui/Error';
import { getLegalDocumentsFileRow, getUpperDocumentsRequired } from './Util';
import { FileUploadRow } from '../Common/FileUploadRow';
import { FileUploadHeader } from './styles';

export const From500To30MilRequired = {
  financial_statements_year: 'financial_statements_year',
  financial_statements_quarter: 'financial_statements_quarter',
  osv_60_year: 'osv_60_year',
  osv_60_quarter: 'osv_60_quarter',
  osv_62_year: 'osv_62_year',
  osv_62_quarter: 'osv_62_quarter',
  registry: 'registry',
  charter: 'charter',
  directorPassport: 'director_passport',
  directorDecision: 'director_decision',
  constitutorPassports: 'constitutor_passports',
  rentOrOwnershipContract: 'rent_or_ownership_contract',
  requisites: 'requisites'
};

interface Props {
  isMainCompany: boolean;
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const From500To30MilFileUploadBlock: React.FC<Props> = ({
  borrowerCompany,
  errors
}) => {
  return (
    <>
      <Col lg={12}>
        <FileUploadRow
          borrowerCompany={borrowerCompany}
          errors={errors}
          blocks={getUpperDocumentsRequired(borrowerCompany)}
        />
        <FileUploadHeader>Юридические документы</FileUploadHeader>

        <FileUploadRow
          borrowerCompany={borrowerCompany}
          errors={errors}
          blocks={getLegalDocumentsFileRow(borrowerCompany)}
        />
      </Col>

      <Error error={errors.documents} />
    </>
  );
};
