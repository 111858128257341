import { GET_COMPANIES_LIST } from '../reducers/getCompaniesList';
import { CompanyRead } from './setCompanyFormData';
import { COMPANY_LIST_TYPE } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CompanyRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  minRevenue?: number;
  maxRevenue?: number;
  inn?: string;
  maxIncome?: number;
  minIncome?: number;
  hasContacts?: boolean;
  tenderWinner?: boolean;
  isCustomer44?: boolean;
  isCustomer223?: boolean;
  locked?: boolean;
  ManagerID?: string;
  AnalystID?: string;
  roleId?: {};
  pledgeInfo?: boolean;
  govSupportInfo?: boolean;
  creditInfo?: boolean;
  lastActivityDate?: string;
}

export const req = (companyListType: COMPANY_LIST_TYPE, data: RequestData) => ({
  type: GET_COMPANIES_LIST.REQ,
  data,
  companyListType
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_LIST.ERR,
  error
});
