import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Activities/actions/getActivities';

export interface GetActivitiesOfCompaniesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ACTIVITIES_COMPANIES {
  REQ = 'GET_ACTIVITIES_COMPANIES_REQ',
  GOT = 'GET_ACTIVITIES_COMPANIES_GOT',
  ERR = 'GET_ACTIVITIES_COMPANIES_ERR'
}

export const initialState: GetActivitiesOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: null
  },
  error: {}
};

const getActivitiesOfCompanies = (
  state: GetActivitiesOfCompaniesStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetActivitiesOfCompaniesStoreState => {
  switch (action.type) {
    case GET_ACTIVITIES_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ACTIVITIES_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ACTIVITIES_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getActivitiesOfCompanies;
