import { combineReducers } from 'redux';
import patchCustomerRequirements, {
  PatchCustomerRequirements
} from './patchCustomerRequirements';
import patchCabinetCustomerRequirements, {
  PatchCabinetCustomerRequirements
} from './patchCabinetCustomerRequirements';
import patchRegionForbidden, {
  PatchRegionForbidden
} from './patchSetForbiddenRegion';
import patchRegionAvailable, {
  PatchRegionAvailable
} from './patchSetAvailableRegion';
import patchCabinetRegionAvailable, {
  PatchCabinetRegionAvailable
} from './patchCabinetSetAvailableRegion';
import patchCabinetRegionForbidden, {
  PatchCabinetRegionForbidden
} from './patchCabinetSetForbiddenRegion';
import patchCabinetRegionAvailableAll, {
  PatchCabinetRegionAvailableAll
} from './patchCabinetSetAvailableRegionAll';
import patchCabinetRegionForbiddenAll, {
  PatchCabinetRegionForbiddenAll
} from './patchCabinetSetForbiddenRegionAll';
import patchRegionAvailableAll, {
  PatchRegionAvailableAll
} from './patchSetAvailableRegionAll';
import patchRegionForbiddenAll, {
  PatchRegionForbiddenAll
} from './patchSetForbiddenRegionAll';

export interface CustomerRequirementsReducers {
  patchCustomerRequirements: PatchCustomerRequirements;
  patchCabinetCustomerRequirements: PatchCabinetCustomerRequirements;
  patchRegionForbidden: PatchRegionForbidden;
  patchRegionAvailable: PatchRegionAvailable;
  patchCabinetRegionAvailable: PatchCabinetRegionAvailable;
  patchCabinetRegionForbidden: PatchCabinetRegionForbidden;
  patchCabinetRegionAvailableAll: PatchCabinetRegionAvailableAll;
  patchCabinetRegionForbiddenAll: PatchCabinetRegionForbiddenAll;
  patchRegionAvailableAll: PatchRegionAvailableAll;
  patchRegionForbiddenAll: PatchRegionForbiddenAll;
}

export const CustomerRequirements = combineReducers({
  patchCustomerRequirements,
  patchCabinetCustomerRequirements,
  patchRegionForbidden,
  patchRegionAvailable,
  patchCabinetRegionAvailable,
  patchCabinetRegionForbidden,
  patchCabinetRegionAvailableAll,
  patchCabinetRegionForbiddenAll,
  patchRegionAvailableAll,
  patchRegionForbiddenAll
});
