import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchCabinetRegionForbiddenAll extends Reducer {}

export enum PATCH_CABINET_SET_FORBIDDEN_REGION_ALL {
  REQ = 'PATCH_CABINET_SET_FORBIDDEN_REGION_ALL_REQ',
  GOT = 'PATCH_CABINET_SET_FORBIDDEN_REGION_ALL_GOT',
  ERR = 'PATCH_CABINET_SET_FORBIDDEN_REGION_ALL_ERR'
}

export const initialState: PatchCabinetRegionForbiddenAll = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCabinetRegionForbiddenAll = (
  state: PatchCabinetRegionForbiddenAll = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchCabinetRegionForbiddenAll => {
  switch (action.type) {
    case PATCH_CABINET_SET_FORBIDDEN_REGION_ALL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_CABINET_SET_FORBIDDEN_REGION_ALL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_CABINET_SET_FORBIDDEN_REGION_ALL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchCabinetRegionForbiddenAll;
