import styled from 'styled-components';
import { rr } from 'shared/styled';

export const SectionTitle = styled.h2`
  font-family: ${rr};
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const BlockSpace = styled.div`
  margin-bottom: 50px;
`;
