import styled from 'styled-components';

export const ClientInfoContainer = styled.div`
  margin-bottom: 40px;

  div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

export const ClientSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
`;

export const BeneficiariesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
