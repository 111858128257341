import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { BorrowerCompanyRole } from 'shared/constants';

import { BorrowerCompany } from 'Application/types';

import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';

import { DocumentsTable, IRow } from 'Layouts/components';
import {
  ApplicationViewConsumer,
  EachApplicationProps,
  WarningBlock
} from 'Application/components';

import {
  CompanyDocumentsTitle,
  CompanyProperty,
  ViewCompanyTabItem
} from './styles';

import { EditDocumentsLink } from '../../styles';
import ApplicationApi from 'src/features/Application/api';
import { DownloadLink } from 'shared/styled';
import { TitleTabContainer } from '../../../styles';

interface Props {
  activeCompany: BorrowerCompany;
  rows: IRow[];
  documentList: ResDocumentListByApplicationId;
}

export const BorrowerCompanyDocumentTab: React.FC<Props> = ({
  activeCompany,
  rows,
  documentList
}: Props) => {
  const [
    isSectionOldDocumentList,
    setIsSectionOldDocumentList
  ] = React.useState(true);

  return (
    <ApplicationViewConsumer>
      {({
        canEdit,
        editDocsRoute
      }: EachApplicationProps<GetApplicationResponseDataType>) => (
        <ViewCompanyTabItem>
          <CompanyDocumentsTitle>Документы компании</CompanyDocumentsTitle>
          <CompanyProperty>
            ИНН: <span>{activeCompany.individualNumber}</span>
          </CompanyProperty>
          <CompanyProperty>
            Роль:{' '}
            {activeCompany.roles.map((role, key) => (
              <span key={key}>
                {BorrowerCompanyRole[role]}
                {key === activeCompany.roles.length - 1 ? '' : ', '}
              </span>
            ))}
          </CompanyProperty>

          {activeCompany.documents.length !== 0 &&
            documentList.items.length === 0 && (
              <>
                <TitleTabContainer>
                  <p>Список документов (старый формат)</p>
                  <FontAwesomeIcon
                    icon={
                      isSectionOldDocumentList ? faChevronUp : faChevronDown
                    }
                    onClick={() =>
                      setIsSectionOldDocumentList(prevState => !prevState)
                    }
                  />
                </TitleTabContainer>

                {isSectionOldDocumentList &&
                  (activeCompany.documents.length !== 0 ? (
                    <DocumentsTable
                      headers={[
                        '№',
                        'Название',
                        'Дата добавления',
                        'Размер файла',
                        <DownloadLink
                          href={ApplicationApi.getDownloadBorrowerCompanyDocumentsLink(
                            activeCompany.id
                          )}
                        />
                      ]}
                      rows={rows}
                    />
                  ) : (
                    activeCompany.documents.length === 0 &&
                    documentList.items.length === 0 && (
                      <WarningBlock
                        text={
                          canEdit
                            ? [
                                'Вы не загрузили необходимые документы компании.',
                                <React.Fragment>
                                  Чтобы загрузить документы, нажмите "
                                  <EditDocumentsLink to={editDocsRoute}>
                                    Редактировать документы
                                  </EditDocumentsLink>
                                  ".
                                </React.Fragment>,
                                `Заявки без документов компаний заемщика банками к рассмотрению не принимаются.`
                              ]
                            : [
                                'Вы не загрузили необходимые документы компании.',
                                `Заявки без документов компаний заемщика банками к рассмотрению не принимаются.`
                              ]
                        }
                      />
                    )
                  ))}
              </>
            )}
        </ViewCompanyTabItem>
      )}
    </ApplicationViewConsumer>
  );
};
