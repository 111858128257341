import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Loader } from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { req as getPersonOfCompanies } from 'src/features/Person/actions/getPersonOfCompanies';
import { req as getBeneficiariesOfCompanies } from 'src/features/Person/actions/getBeneficiariesOfCompanies';
import {
  req as getCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  BeneficiariesOfCompaniesType,
  PersonOfCompaniesType
} from 'src/features/Person/types';
import { BlockSpace, SectionTitle } from './styled';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  persons: PersonOfCompaniesType[];
  statusPerson: REQUEST_STATUSES;
  beneficiaries: BeneficiariesOfCompaniesType[];
  statusBeneficiaries: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  getPersonOfCompanies: (inn: string) => void;
  getBeneficiariesOfCompanies: (inn: string) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const LinksWithCompanies: React.FC<Props> = ({
  getCompanyByInn,
  statusInn,
  getPersonOfCompanies,
  persons,
  statusPerson,
  getBeneficiariesOfCompanies,
  beneficiaries,
  statusBeneficiaries,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.inn) {
        getBeneficiariesOfCompanies(match.params.inn);
        getPersonOfCompanies(match.params.inn);
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (statusInn === REQUEST_STATUSES.GOT) {
        getBeneficiariesOfCompanies(match.params.inn);
        getPersonOfCompanies(match.params.inn);
      }
    },
    [statusInn]
  );

  //   const navigateToBeneficiaries = (companyInn: string) => {
  //     window.open(`/crm/companies/${companyInn}/beneficiaries`, '_blank');
  //   };

  return (
    <ScrollTopComponent>
      <BlockSpace>
        <SectionTitle>Бенефициар в:</SectionTitle>

        {statusBeneficiaries === REQUEST_STATUSES.REQUEST && <Loader />}
        {statusBeneficiaries === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Инн</TableThStyled>
                <TableThStyled width="15%">Наименование</TableThStyled>
                <TableThStyled width="5%">Доля владения, ЕГРЮЛ</TableThStyled>
                <TableThStyled width="10%">Капитал</TableThStyled>
                <TableThStyled width="5%">
                  Доля юридическая полная
                </TableThStyled>
                <TableThStyled width="10%">Год отчетности</TableThStyled>
                <TableThStyled width="15%">Выручка, тыс.</TableThStyled>
                <TableThStyled width="15%">Прибыль, тыс.</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {beneficiaries.map((beneficiar, index) => (
                <TableRowStyled key={index}>
                  <td>{beneficiar.companyInn}</td>
                  {Object.keys(beneficiar).length === 1 &&
                  beneficiar.hasOwnProperty('companyInn') ? (
                    <Button
                      label="Обновить данные ЕГРЮЛ"
                      disabled={statusInn === REQUEST_STATUSES.REQUEST}
                      onClick={() =>
                        getCompanyByInn({
                          inn: beneficiar.companyInn,
                          force: true
                        })
                      }
                      w="fit-content"
                    />
                  ) : (
                    <>
                      <td>{beneficiar.companyShortName}</td>
                      <td>{`${beneficiar.ownershipShareDeJure} %`}</td>
                      <td>{formSumStringThousands(beneficiar.capitalShare)}</td>
                      <td>
                        {beneficiar.ownershipShareDeJureFull
                          ? `${beneficiar.ownershipShareDeJureFull} %`
                          : 'неизв.'}
                      </td>
                      <td>
                        {beneficiar.financialsYear
                          ? beneficiar.financialsYear
                          : 'неизв.'}
                      </td>
                      <td>
                        {beneficiar.revenue ? beneficiar.revenue : 'неизв.'}
                      </td>
                      <td>
                        {beneficiar.netIncome ? beneficiar.netIncome : 'неизв.'}
                      </td>
                    </>
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
      </BlockSpace>

      <BlockSpace>
        <SectionTitle>Руководитель в:</SectionTitle>

        {statusPerson === REQUEST_STATUSES.REQUEST && <Loader />}
        {statusPerson === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">Инн</TableThStyled>
                <TableThStyled width="10%">Наименование</TableThStyled>
                <TableThStyled width="15%">Должность</TableThStyled>
                <TableThStyled width="15%">Тип позиции ЕГРЮЛ</TableThStyled>
                <TableThStyled width="10%">Год отчетности</TableThStyled>
                <TableThStyled width="15%">Выручка, тыс.</TableThStyled>
                <TableThStyled width="15%">Прибыль, тыс.</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {persons.map((person, index) => (
                <TableRowStyled
                  key={index}
                  //   onClick={() => navigateToBeneficiaries(person.companyInn)}
                >
                  <td>{person.companyInn}</td>
                  {Object.keys(person).length === 1 &&
                  person.hasOwnProperty('companyInn') ? (
                    <Button
                      label="Обновить данные ЕГРЮЛ"
                      disabled={statusInn === REQUEST_STATUSES.REQUEST}
                      onClick={() =>
                        getCompanyByInn({ inn: person.companyInn, force: true })
                      }
                      w="fit-content"
                    />
                  ) : (
                    <>
                      <td>{person.companyShortName}</td>
                      <td>{`${person.position}, с ${new Date(
                        person.positionStartDate
                      ).toLocaleDateString()}`}</td>
                      <td>{person.positionType}</td>
                      <td>
                        {person.financialsYear
                          ? person.financialsYear
                          : 'неизв.'}
                      </td>
                      <td>{person.revenue ? person.revenue : 'неизв.'}</td>
                      <td>{person.netIncome ? person.netIncome : 'неизв.'}</td>
                    </>
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
      </BlockSpace>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF, Person }: CRM & STORE) => ({
  statusInn: SCF.getCompanyByInnThirdParty.status,
  persons: Person.getPersonOfCompanies.data,
  statusPerson: Person.getPersonOfCompanies.status,
  beneficiaries: Person.getBeneficiariesOfCompaniesPerson.data,
  statusBeneficiaries: Person.getBeneficiariesOfCompaniesPerson.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCompanyByInn, getPersonOfCompanies, getBeneficiariesOfCompanies },
    dispatch
  );

const LinksWithCompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LinksWithCompanies)
);

export { LinksWithCompaniesConnect as LinksWithCompanies };
