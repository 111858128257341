import styled from 'styled-components';
import { gray, rr, blue, darkBlue } from 'shared/styled';

export const BtnsContainer = styled.div`
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BtnSubBlock = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
`;

export const BackBtn = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 24px;
  color: #3f4e65;
  margin: 0;
  text-decoration: none;
`;

export const AddBtn = styled.div`
  margin-top: 20px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const AddBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  margin: 0;
  font-size: 1.1em;
  padding: 15px 20px;
  color: #ffffff;
`;

export const CreateBtn = styled.div`
  width: auto;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 5px 10px;
  margin: 0;
  color: #ffffff;
`;

export const DisabledBtn = styled.div`
  width: auto;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${gray};
  transition: 0.2s;
  cursor: not-allowed;
  p {
    font-family: ${rr};
    font-weight: 600;
    font-size: 1.1em;
    padding: 5px 10px;
    margin: 0;
    color: #ffffff;
  }
`;

export const BtnsBlock = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const BtnsBlockSignBtn = styled.div`
  width: 240px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: ${rr};
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.3s;
  &:hover {
    background-color: ${darkBlue};
  }
`;

export const BtnsBlockDownloadDocument = styled.a`
  width: 240px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #3f4e65;
  font-family: ${rr};
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BtnsBlockDownloadSignature = styled.a`
  width: 240px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #3f4e65;
  font-family: ${rr};
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BtnsBlockDownloadSignatureTopContainer = styled.a`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const BtnsBlockDownloadSignatureSubInfo = styled.div`
  display: none;
  width: 100%;
  height: auto;
  padding: 10px 10px 10px;
  line-height: 150%;
  color: #3f4e65;
  transition: all 0.3s ease;
  cursor: auto;
`;

export const EditBtn = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.3s;
  &:hover {
    background-color: ${darkBlue};
  }
`;

export const EditBtnDisabled = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  border-radius: 5px;
  background-color: ${gray};
`;

export const EditBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  margin: 0;
  font-size: 1.1em;
  padding: 8px 12px;
  color: #ffffff;
`;
