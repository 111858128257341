import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getOpenLoanLimits';

export interface GetOpenLoanLimitsExternalStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_OPEN_LOAN_LIMITS_EXTERNAL {
  REQ = 'GET_OPEN_LOAN_LIMITS_EXTERNAL_REQ',
  GOT = 'GET_OPEN_LOAN_LIMITS_EXTERNAL_GOT',
  ERR = 'GET_OPEN_LOAN_LIMITS_EXTERNAL_ERR'
}

export const initialState: GetOpenLoanLimitsExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getOpenLoanLimitsExternal = (
  state: GetOpenLoanLimitsExternalStoreState = initialState,
  action: {
    type: GET_OPEN_LOAN_LIMITS_EXTERNAL;
    platforms: ResponseData[];
    error: {};
  }
): GetOpenLoanLimitsExternalStoreState => {
  switch (action.type) {
    case GET_OPEN_LOAN_LIMITS_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_OPEN_LOAN_LIMITS_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.platforms
      };
    case GET_OPEN_LOAN_LIMITS_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getOpenLoanLimitsExternal;
