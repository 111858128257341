import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetFiltersForApplicationListTypeStoreState extends Reducer {
  data: string[];
}

export enum GET_FILTERS_FOR_APPLICATION_LIST_TYPE {
  REQ = 'GET_FILTERS_FOR_APPLICATION_LIST_TYPE_REQ',
  GOT = 'GET_FILTERS_FOR_APPLICATION_LIST_TYPE_GOT',
  ERR = 'GET_FILTERS_FOR_APPLICATION_LIST_TYPE_ERR'
}

export const initialState: GetFiltersForApplicationListTypeStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getFiltersForApplicationListType = (
  state: GetFiltersForApplicationListTypeStoreState = initialState,
  action: {
    type: string;
    data: string[];
    error: {};
  }
): GetFiltersForApplicationListTypeStoreState => {
  switch (action.type) {
    case GET_FILTERS_FOR_APPLICATION_LIST_TYPE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FILTERS_FOR_APPLICATION_LIST_TYPE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FILTERS_FOR_APPLICATION_LIST_TYPE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFiltersForApplicationListType;
