import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCounterparties';

import { GET_COUNTERPARTIES } from '../reducers/getCounterparties';
import CounterpartiesApi from '../api';

function* getCounterparties(action: {
  type: GET_COUNTERPARTIES;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      CounterpartiesApi.getCounterparties,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCounterpartiesSaga(): Generator {
  yield takeLatest(GET_COUNTERPARTIES.REQ, getCounterparties);
}
