import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Normalize } from 'styled-normalize';

import store from 'store';

import { App } from 'App/components';
import { Main, CssAnimations } from 'shared/styled';
import * as Sentry from '@sentry/browser';
import 'shared/styled/main.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://dd8030a4a09a4e12b53d25fbc7c830a3@o349462.ingest.sentry.io/5424496'
  });
}

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <Normalize />
      <CssAnimations />
      <Main />
      <App />
    </React.Fragment>
  </Provider>,
  document.getElementById('root')
);
