import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postSelectApplication';

import { POST_SELECT_APPLICATION } from '../reducers/postSelectApplication';
import ApplicationApi from '../api';

function* postSelectApplication(action: {
  type: POST_SELECT_APPLICATION;
  data: any;
}): Generator {
  try {
    const res: any = yield call(
      ApplicationApi.postSelectApplication,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postSelectApplicationSaga(): Generator {
  yield takeLatest(POST_SELECT_APPLICATION.REQ, postSelectApplication);
}
