import { POST_APPLICATION_PRESCORING } from '../reducers/postApplicationPrescoring';

export interface RequestData {
  applicationID: number;
}

export const req = (data: RequestData) => ({
  type: POST_APPLICATION_PRESCORING.REQ,
  data
});

export const got = () => ({
  type: POST_APPLICATION_PRESCORING.GOT
});

export const err = (error: {}) => ({
  type: POST_APPLICATION_PRESCORING.ERR,
  error
});

export const reset = () => ({
  type: POST_APPLICATION_PRESCORING.RESET
});
