import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchCabinetRegionAvailable extends Reducer {}

export enum PATCH_CABINET_SET_AVAILABLE_REGION {
  REQ = 'PATCH_CABINET_SET_AVAILABLE_REGION_REQ',
  GOT = 'PATCH_CABINET_SET_AVAILABLE_REGION_GOT',
  ERR = 'PATCH_CABINET_SET_AVAILABLE_REGION_ERR'
}

export const initialState: PatchCabinetRegionAvailable = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCabinetRegionAvailable = (
  state: PatchCabinetRegionAvailable = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchCabinetRegionAvailable => {
  switch (action.type) {
    case PATCH_CABINET_SET_AVAILABLE_REGION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_CABINET_SET_AVAILABLE_REGION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_CABINET_SET_AVAILABLE_REGION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchCabinetRegionAvailable;
