import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getNewFinancialStatementsAll';

import { GET_NEW_FINANCIAL_STATEMENTS_ALL } from '../reducers/getNewFinancialStatements';
import FinancialStatementsApi from '../api';

function* getNewFinancialStatementsAll(action: {
  type: GET_NEW_FINANCIAL_STATEMENTS_ALL;
  companyinn: string;
  TaxYear: number;
  TaxPeriod: number;
}): Generator {
  try {
    const res: any = yield call(
      FinancialStatementsApi.getFinancialStatementsAll,
      {
        companyinn: action.companyinn,
        TaxPeriod: action.TaxPeriod,
        TaxYear: action.TaxYear
      }
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinancialStatementsAllSaga(): Generator {
  yield takeLatest(
    GET_NEW_FINANCIAL_STATEMENTS_ALL.REQ,
    getNewFinancialStatementsAll
  );
}
