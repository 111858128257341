import {
  GET_APPLICATION_FLAGS,
  ResponseDataType
} from 'Application/reducers/getApplicationFlags';

export const req = () => ({
  type: GET_APPLICATION_FLAGS.REQ
});

export const got = (data: ResponseDataType) => ({
  type: GET_APPLICATION_FLAGS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATION_FLAGS.ERR,
  error
});
