import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getManagersAndSignatories';

import { GET_MANAGERS_AND_SIGNATORIES } from '../reducers/getManagersAndSignatories';
import BeneficiariesApi from '../api';

function* getManagersAndSignatories(action: {
  type: GET_MANAGERS_AND_SIGNATORIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      BeneficiariesApi.getManagersAndSignatories,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getManagersAndSignatoriesSaga(): Generator {
  yield takeLatest(GET_MANAGERS_AND_SIGNATORIES.REQ, getManagersAndSignatories);
}
