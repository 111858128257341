import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFinbank';

import {
  GET_FINBANK,
} from '../reducers/getFinbank';
import FinbanksApi from '../api';

function* getFinbank(action: {
  type: GET_FINBANK;
  inn: string
}): Generator {
  try {

    const res:any = yield call(FinbanksApi.getFinbankByINN, action.inn);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinbankSaga(): Generator {
  yield takeLatest(GET_FINBANK.REQ, getFinbank);
}
