import styled from 'styled-components';
import {
  green,
  lightGray,
  lighterBlue,
  red,
  rr
} from 'shared/styled';

export const SupplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  div {
    font-family: ${rr};

    &:last-child {
      white-space: pre-wrap;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: stretch;
  column-gap: 10px;
  margin-right: 25px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 75%;
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 5px;
  white-space: pre-wrap;
  color: ${({ status }) =>
    (status === 'Отклонен дебитором' ||
      status === 'Подтвержден дебитором' ||
      status === 'Еще не подтвержден дебитором') &&
    'white'};
  background-color: ${({ status }) =>
    status === 'Не загружен' ||
    status === 'Не подписан поставщиком' ||
    status === 'Не подписан дебитором'
      ? lightGray
      : status === 'Отклонен дебитором' ||
        status === 'Еще не подтвержден дебитором'
        ? red
        : status === 'Подтвержден дебитором'
          ? green
          : lighterBlue};
`;

export const TDStyled = styled.td`
  overflow: visible !important;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 5px;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  cursor: pointer;

  &:hover{
    svg {
      color: #0085FE;
    }
  }

  svg {
    color: #9FA6B2;
  }

  &:hover:before {
    content: '${({ text }) => text}';
    width: max-content;
    position: absolute;
    bottom: 4px;
    left: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const Postponement = styled.div`
  button {
    width: fit-content;
    cursor: default;
    background-color: red;
    height: 40px;
    padding: 13px;

    &:not([disabled]):hover {
      background-color: red;
    }

    &:hover {
      background-color: red;
    }
  }
`;

export const PostponementItem = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
`;

export const TextRed = styled.div`
  color: red;
  text-align: center;
`;

export const DebtorAgreementForm = styled.div`
  width: 70%;
  text-align: center;
`;

export const PaymentDeferment = styled.div`
  font-size: 12px;
`;
