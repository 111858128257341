import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getFactoringLimitByDebtorInn';
import { GET_FACTORING_LIMIT_BY_DEBTOR_INN } from 'entities/SCF/Debtor/model/reducers/getFactoringLimitByDebtorInn';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getFactoringLimitByDebtorInn(action: {
  type: GET_FACTORING_LIMIT_BY_DEBTOR_INN;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getFactoringLimitByDebtorInn,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringLimitByDebtorInnSaga() {
  yield takeLatest(
    GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_REQ,
    getFactoringLimitByDebtorInn
  );
}
