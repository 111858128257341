import { GET_POAS } from '../reducers/getPOAS';

export interface ResponseData {
  taxAuthorityGuid: string;
  startDate: string;
  endDate: string;
  status: number;
  statusDate: string;
  personInn: string;
}

export const req = (inn: string) => ({
  type: GET_POAS.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_POAS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_POAS.ERR,
  error
});
