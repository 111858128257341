import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import {
  inRangeRule,
  singleElementArrayRule,
  alwaysTrueRule,
  innValidationRule
} from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

export const createValidaton = () =>
  new Validator<SetInitializationApplicationType>({
    clientCompanyName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    code: [
      {
        rule: innValidationRule,
        message: ValidatorMessages.codeValid
      },
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    amount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    term: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
