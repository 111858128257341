import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetCompanyInnStoreState extends Reducer {
  data: ResponseDataType;
}

export interface CompanyInn {
  companyInn: string;
}

export type ResponseDataType = CompanyInn;

export enum GET_COMPANY_INN {
  REQ = 'GET_COMPANY_INN _REQ',
  GOT = 'GET_COMPANY_INN _GOT',
  ERR = 'GET_COMPANY_INN _ERR'
}

export const initialState: GetCompanyInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    companyInn: null
  },
  error: {}
};

const getCompanyInn = (
  state: GetCompanyInnStoreState = initialState,
  action: {
    type: GET_COMPANY_INN;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyInnStoreState => {
  switch (action.type) {
    case GET_COMPANY_INN.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_INN.GOT:

      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_INN.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyInn;
