import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'Application/actions/getTenderByApplication';

export interface GetTenderByApplicationStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_TENDER_BY_APPLICATION {
  REQ = 'GET_TENDER_BY_APPLICATION_REQ',
  GOT = 'GET_TENDER_BY_APPLICATION_GOT',
  ERR = 'GET_TENDER_BY_APPLICATION_ERR',
  RESET = 'GET_TENDER_BY_APPLICATION_RESET'
}

export const initialState: GetTenderByApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getTenderByApplication = (
  state: GetTenderByApplicationStoreState = initialState,
  action: {
    type: GET_TENDER_BY_APPLICATION;
    data: ResponseData[];
    error: {};
  }
): GetTenderByApplicationStoreState => {
  switch (action.type) {
    case GET_TENDER_BY_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_TENDER_BY_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_TENDER_BY_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_TENDER_BY_APPLICATION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getTenderByApplication;
