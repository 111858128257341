import styled from 'styled-components';

import { UploadFileIcon } from 'shared/styled';
import { rr, gray, navyBlue, blue } from 'shared/styled';

export const DocumentStyled = styled.a.attrs({
  download: true
})`
  width: 245px;
  height: 50px;

  padding: 13px;
  margin: 0 10px 10px 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;
  border-radius: 4px;
  background-color: #fbfbfb;

  text-decoration: none;

  & > ${UploadFileIcon} {
    width: 100%;
    max-width: 20px;
    margin-right: 15px;
    background-size: contain;
  }

  &::before {
    content: 'Скачать';

    padding-left: 35px;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    opacity: 0;

    font-family: ${rr};
    font-size: 14px;
    color: ${blue};

    border-radius: 4px;
    background-position: calc(50% - 35px) 50%;
    background-repeat: no-repeat;
    background-color: rgba(226, 228, 232, 0.8);
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="%230085FE" d="M12.8 14.14v3.85l2-1.566.737-.578a.666.666 0 0 1 .922.1l.205.25c.213.26.17.64-.096.847l-.068.055-4 3.13a.814.814 0 0 1-1 0l-3.999-3.131-.068-.054a.594.594 0 0 1-.096-.847l.205-.25a.666.666 0 0 1 .922-.1l.737.578L11.2 17.99v-3.85h-6c-1.546 0-2.8-1.226-2.8-2.739 0-1.171.752-2.171 1.809-2.563A2.296 2.296 0 0 1 4 7.878C4 6.582 5.075 5.53 6.4 5.53c.395 0 .768.094 1.097.26.714-1.975 2.64-3.39 4.903-3.39 2.163 0 4.018 1.292 4.801 3.13 2.43.001 4.399 1.928 4.399 4.305s-1.97 4.304-4.4 4.304h-4.4zm0 0V9.385a.794.794 0 0 0-.085-.405.575.575 0 0 0-.242-.237.842.842 0 0 0-.414-.083h-.118c-.19 0-.31.029-.414.083a.575.575 0 0 0-.242.237.794.794 0 0 0-.085.405v4.753h1.6z"/>
      </svg>
    ');

    transition: opacity 0.2s;
  }

  &:hover::before {
    opacity: 1;
  }
`;

export const DocumentInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-family: ${rr};
  font-size: 12px;

  & > span:first-child {
    color: ${navyBlue};
  }

  & > span:last-child {
    color: ${gray};
  }
`;
