import {
  RequestDataType,
  SEND_BUYERS
} from 'src/features/SCF/reducers/sendBuyers';

export const req = (data: RequestDataType) => ({
  type: SEND_BUYERS.REQ,
  data
});

export const got = () => ({
  type: SEND_BUYERS.GOT
});

export const err = (error: {}) => ({
  type: SEND_BUYERS.ERR,
  error
});
