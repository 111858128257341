import getApplicationExternalSaga from './getApplicationExternal';
import getQtySidebarSaga from './getQtySidebar';
import getUserStatsSaga from './getUserStats';
import getExternalLeadsSaga from './getExternalLeads';
import getTenderByApplicationExternalSaga from './getTenderByApplicationExternal';
import getDocumentListExternalSaga from './getDocumentListExternal';
import postFinancialDocumentExternalSaga from './postFinancialDocumentExternal';
import postNonFinancialDocumentExternalSaga from './postNonFinancialDocumentExternal';
import patchCompanyExternalSaga from './patchCompanyExternal';
import getCompanyExternalSaga from './getCompanyExternal';
import postCreateTenderExternalSaga from './postCreateTenderExternal';
import postAddTenderByEruzSaga from './postAddTenderByEruz';
import postDocumentListExternalSaga from './postDocumentListExternal';
import getBeneficiariesOfCompaniesSaga from './getBeneficiariesOfCompanies';

export default [
  getApplicationExternalSaga,
  getQtySidebarSaga,
  getUserStatsSaga,
  getExternalLeadsSaga,
  getTenderByApplicationExternalSaga,
  getDocumentListExternalSaga,
  postFinancialDocumentExternalSaga,
  postNonFinancialDocumentExternalSaga,
  patchCompanyExternalSaga,
  getCompanyExternalSaga,
  postCreateTenderExternalSaga,
  postAddTenderByEruzSaga,
  postDocumentListExternalSaga,
  getBeneficiariesOfCompaniesSaga
];
