import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { russiaPhoneMask } from 'shared/utils/InputMasks';
import { LegalTypes, InnPlaceholders } from 'shared/constants';
import { LEGAL_TYPES, REQUEST_STATUSES, STORE } from 'globaltypes';

import {
  req as externalAgentRegister,
  RequestDataType
} from 'ExternalAgent/actions/externalAgentRegister';
import {
  req as getCompanyFastInfo,
  ResponseDataType as ResponseCompanyFastInfo
} from 'src/features/SCF/actions/getCompanyFastInfo';

import { InputPassword } from 'shared/ui/InputPassword';
import { PasswordHint } from 'shared/ui/Hint';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { Select } from 'shared/ui/Select';

import { createValidation } from './validator';
import { CheckboxStyled, InfoField, PopupContainer, PopupInfo } from './styles';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'src/shared/ui/Button';

export interface Fields {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  legalForm: string;
  code: string;
  companyName: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface State extends Fields {
  rulesAgreement: boolean;
  submitClicked: boolean;
  isInfoPopup: boolean;
}

interface StateToProps {
  companyFastInfo: ResponseCompanyFastInfo;
  statusCompanyFastInfo: REQUEST_STATUSES;
}

interface DispatchToProps {
  externalAgentRegister: (data: RequestDataType) => void;
  getCompanyFastInfo: (inn: string) => void;
}

type Props = StateToProps & DispatchToProps;

class ExternalAgentRegistrationForm extends React.Component<Props, State> {
  Validator = createValidation();
  state: State = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    legalForm: '',
    code: '',
    companyName: '',
    email: '',
    password: '',
    repeatPassword: '',
    rulesAgreement: false,
    submitClicked: false,
    isInfoPopup: false
  };

  componentDidUpdate(prevProps, prevState: State): void {
    if (prevState.code !== this.state.code && this.state.code.length >= 10) {
      this.props.getCompanyFastInfo(this.state.code);
      this.setState({ isInfoPopup: false });
    }
  }

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    const { errors } = this.Validator.validate(this.state);

    function checkFields() {
      for (const key in errors) {
        if (key !== 'code' && errors[key] !== '') {
          return false;
        }
      }
      return true;
    }

    const errorEmpty = checkFields();

    if (
      (errors.code === 'Введенный ИНН может быть некорректным' ||
        errors.code === '') &&
      errorEmpty
    ) {
      const {
        repeatPassword,
        rulesAgreement,
        submitClicked,
        ...dataForRequest
      } = this.state;

      this.props.externalAgentRegister(dataForRequest);
    }
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isFieldToTrim =
      name === 'email' ||
      name === 'phoneNumber' ||
      name === 'password' ||
      name === 'repeatPassword';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (name === 'code' && sanitizedValue.length > 12) {
      return;
    } else if (name === 'code' && sanitizedValue.length <= 12) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');

      this.setState({ code: sanitizedValue });
    } else {
      this.setState({
        [name]: isCheckbox
          ? checked
          : isFieldToTrim
            ? value.replace(/\s+/g, '')
            : value
      } as { [key in keyof Fields]: string });
    }
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
    if (
      this.props.statusCompanyFastInfo === REQUEST_STATUSES.GOT &&
      !!this.props.companyFastInfo.shortName.length
    ) {
      this.setState({ companyName: this.props.companyFastInfo.shortName });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      legalForm,
      code,
      companyName,
      email,
      password,
      repeatPassword,
      rulesAgreement,
      isInfoPopup
    } = this.state;

    const { errors, fields } = this.Validator.insertArgs({
      passwordEqual: [password],
      innRule: [legalForm],
      companyNameRule: [legalForm]
    }).validate(this.state);

    return (
      <Form onSubmit={this.onSubmit} name="external-agent-registration-form">
        <FormSection>
          <Input
            value={firstName}
            label="Ваше имя"
            name="firstName"
            placeholder="Имя"
            required={true}
            error={errors.firstName}
            onChange={this.onChange}
          />
          <Input
            value={lastName}
            label="Ваша фамилия"
            name="lastName"
            placeholder="Фамилия"
            required={true}
            error={errors.lastName}
            onChange={this.onChange}
          />
          <Input
            type="tel"
            value={phoneNumber}
            label="Номер телефона"
            name="phoneNumber"
            placeholder="+7(___)___-__-__"
            mask={russiaPhoneMask}
            required={true}
            error={errors.phoneNumber}
            onChange={this.onChange}
          />
          <Select
            options={Object.keys(LegalTypes).map(type => ({
              id: type,
              name: LegalTypes[type]
            }))}
            value={legalForm}
            label="Тип"
            name="legalForm"
            placeholder="Выберите тип"
            required={true}
            error={errors.legalForm}
            onChange={this.onChange}
          />

          <PopupContainer>
            <Input
              value={code}
              required
              label="ИНН"
              name="code"
              placeholder={
                legalForm === LEGAL_TYPES.ENTITY
                  ? InnPlaceholders.entity
                  : legalForm === LEGAL_TYPES.INDIVIDUAL
                    ? InnPlaceholders.individual
                    : 'Введите ИНН'
              }
              warning={
                (code.length === 10 || code.length === 12) &&
                !!errors.code.length &&
                this.props.statusCompanyFastInfo === REQUEST_STATUSES.GOT
                  ? null
                  : code.length === 10 &&
                    errors.code.length === 0 &&
                    this.props.statusCompanyFastInfo === REQUEST_STATUSES.ERROR
                    ? 'Компания с таким ИНН не найдена'
                    : code.length === 12 &&
                      errors.code.length === 0 &&
                      this.props.statusCompanyFastInfo ===
                        REQUEST_STATUSES.ERROR
                      ? 'ИП с таким ИНН не найден'
                      : (code.length === 10 || code.length === 12) &&
                        !!errors.code.length &&
                        this.props.statusCompanyFastInfo ===
                          REQUEST_STATUSES.ERROR
                        ? 'Введенный ИНН может быть некорректным'
                        : errors.code
              }
              onChange={this.onChange}
            />

            {this.props.statusCompanyFastInfo === REQUEST_STATUSES.GOT &&
              code &&
              code.length >= 10 &&
              !isInfoPopup && (
                <InnPopup onPopupClick={this.onPopupClick}>
                  <PopupInfo>
                    <p>ИНН {this.props.companyFastInfo.inn}</p>
                    <p>
                      {this.props.companyFastInfo.shortName
                        ? this.props.companyFastInfo.shortName
                        : this.props.companyFastInfo.fullName}
                    </p>
                    <p>{this.props.companyFastInfo.address}</p>
                  </PopupInfo>
                </InnPopup>
              )}

            {this.state.isInfoPopup && (
              <InfoField>
                <p>ИНН {this.props.companyFastInfo.inn}</p>
                <p>{this.props.companyFastInfo.shortName}</p>
                <p>{this.props.companyFastInfo.address}</p>
              </InfoField>
            )}
          </PopupContainer>

          <Input
            value={companyName}
            label="Наименование организации"
            name="companyName"
            placeholder="Название компании"
            required={legalForm === LEGAL_TYPES.ENTITY}
            error={errors.companyName}
            onChange={this.onChange}
          />
        </FormSection>

        <FormSection>
          <Input
            value={email}
            label="Email"
            name="email"
            placeholder="Email"
            required={true}
            error={errors.email}
            onChange={this.onChange}
          />
          <InputPassword
            value={password}
            label="Пароль"
            name="password"
            placeholder="Пароль"
            required={true}
            error={errors.password}
            onChange={this.onChange}
            hint={{
              validatable: true,
              position: 'left',
              component: (
                <PasswordHint validationStatuses={fields.password.statuses} />
              )
            }}
          />
          <InputPassword
            value={repeatPassword}
            label="Подтвердите пароль"
            name="repeatPassword"
            placeholder="Пароль"
            required={true}
            error={errors.repeatPassword}
            onChange={this.onChange}
          />
          <CheckboxStyled
            template="agreementCheckbox"
            name="rulesAgreement"
            checked={rulesAgreement}
            error={errors.rulesAgreement}
            onChange={this.onChange}
          />
        </FormSection>
        <Button type="submit" label="Зарегистрироваться" template="nextBtn" />
      </Form>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyFastInfo: SCF.getCompanyFastInfo.data,
  statusCompanyFastInfo: SCF.getCompanyFastInfo.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ externalAgentRegister, getCompanyFastInfo }, dispatch);

const ExternalAgentRegistrationFormConnect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAgentRegistrationForm);

export {
  ExternalAgentRegistrationFormConnect as ExternalAgentRegistrationForm
};
