import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getInvestorListBySupplier';

export interface GetInvestorListBySupplierStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_INVESTOR_LIST_BY_SUPPLIER {
  REQ = 'GET_INVESTOR_LIST_BY_SUPPLIER_REQ',
  GOT = 'GET_INVESTOR_LIST_BY_SUPPLIER_GOT',
  ERR = 'GET_INVESTOR_LIST_BY_SUPPLIER_ERR',
  RESET = 'GET_INVESTOR_LIST_BY_SUPPLIER_RESET'
}

export const initialState: GetInvestorListBySupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getInvestorListBySupplier = (
  state: GetInvestorListBySupplierStoreState = initialState,
  action: {
    type: GET_INVESTOR_LIST_BY_SUPPLIER;
    data: ResponseData[];
    error: {};
  }
): GetInvestorListBySupplierStoreState => {
  switch (action.type) {
    case GET_INVESTOR_LIST_BY_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_INVESTOR_LIST_BY_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_INVESTOR_LIST_BY_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_INVESTOR_LIST_BY_SUPPLIER.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default getInvestorListBySupplier;
