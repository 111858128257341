import * as React from 'react';

import { history } from 'src/shared/utils/History';

import { StepsIndicatorContainer, StepIndicator, StepName } from './styles';

interface Route {
  path: string;
  component: any;
  step?: string;
}

interface Props {
  routes: Route[];
}

export const StepsIndicator: React.FC<Props> = ({ routes }) => {
  const lastPathSegment = history.location.pathname.split('/').pop();

  const activeRoute = routes.find(route => route.path === lastPathSegment);

  const activeRouteIndex = routes.indexOf(activeRoute);

  return (
    <StepsIndicatorContainer>
      {routes.map((route, key) => (
        <div key={key}>
          <StepIndicator
            isActive={key <= activeRouteIndex || route.path === ''}
          />
          <StepName isActive={key <= activeRouteIndex || route.path === ''}>
            {route.step}
          </StepName>
        </div>
      ))}
    </StepsIndicatorContainer>
  );
};
