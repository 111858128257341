import { TOGGLE_LEADOGENERATOR_PERMISSION } from '../reducers/toggleLeadogeneratorPermission';

export const req = (userId: number) => ({
  type: TOGGLE_LEADOGENERATOR_PERMISSION.REQ,
  userId
});

export const got = () => ({
  type: TOGGLE_LEADOGENERATOR_PERMISSION.GOT,
});

export const err = (error: {}) => ({
  type: TOGGLE_LEADOGENERATOR_PERMISSION.ERR,
  error
});
