import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCube,
  faTachometerAlt,
  faSlidersH
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { ProductsListBank } from 'src/features/ProductsForBank/components/ProductsList';
import { FactoringLimitsBank } from 'src/features/FactoringLimitsBank/components/FactoringLimitsBank';
import { LimitsForBankList } from 'src/features/LimitsForBank/components/LimitsForBank';
import { showQuantity } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { CabinetComponent } from '../EachPage';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

interface Props {
  path: string;
  listType: any;
  icon: any;
  label: string;
  countName: string;
  Component: any;
}

function createCRMListCabinetComponent({
  path,
  listType,
  icon,
  label,
  countName,
  Component
}: Props) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        labelInfo={showQuantity(countName)}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const CRMSection: CabinetComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>CRM</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions),
    ...createCRMListCabinetComponent({
      Component: ProductsListBank,
      path: '/cabinet/bank/products/all',
      label: 'Мои продукты',
      icon: <FontAwesomeIcon icon={faCube} fixedWidth={true} />,
      listType: null,
      countName: 'products'
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions),
    ...createCRMListCabinetComponent({
      Component: LimitsForBankList,
      path: '/cabinet/bank/limits/all',
      label: 'Лимиты',
      icon: <FontAwesomeIcon icon={faTachometerAlt} fixedWidth={true} />,
      listType: null,
      countName: 'limits'
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions),
    ...createCRMListCabinetComponent({
      Component: FactoringLimitsBank,
      path: '/cabinet/bank/factoring_limits/all',
      label: 'Лимиты факторинг',
      icon: <FontAwesomeIcon icon={faSlidersH} fixedWidth={true} />,
      listType: null,
      countName: 'factoring_limits'
    })
  }
];
