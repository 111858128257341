export interface TypeFilterData {
  types: string[];
}

export enum TYPE_FILTER_ACTIONS {
  ADD = 'ADD_TYPE_FILTER',
  REMOVE = 'REMOVE_TYPE_FILTER'
}

export const initialState: TypeFilterData = {
  types: []
};

const updateTypeFilter = (
  state: TypeFilterData = initialState,
  action: {
    type: TYPE_FILTER_ACTIONS;
    typeToFilterBy: string;
    index: number;
  }
): TypeFilterData => {
  switch (action.type) {
    case TYPE_FILTER_ACTIONS.ADD:
      const combined = [...state.types, action.typeToFilterBy];
      return {
        ...state,
        types: combined
      };
    case TYPE_FILTER_ACTIONS.REMOVE:
      const filtered = state.types.filter((value,index) => index !== action.index);
      return {
        ...initialState,
        types: filtered
      };
    default:
      return state;
  }
};

export default updateTypeFilter;
