import { POST_EXTERNAL_API_ALFA_BANK_LEAD } from '../reducers/postExternalApiAlfaBank';

export const req = (LeadID: number) => ({
  type: POST_EXTERNAL_API_ALFA_BANK_LEAD.REQ,
  LeadID
});

export const got = () => ({
  type: POST_EXTERNAL_API_ALFA_BANK_LEAD.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_API_ALFA_BANK_LEAD.ERR,
  error
});
