import styled from 'styled-components';
import { lighterBlue, rr, rl, rt, blue, gray, black } from 'shared/styled';


export const Container = styled.div`
  margin-top: 50px;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${lighterBlue};
  padding: 20px 20px 15px 25px;
  overflow: hidden;
`;

export const Block = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

export const NormalText = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${gray};
  margin: 0;
`;

export const BoldText = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${black};
  margin: 0;
`;

export const BoldTextItalic = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${black};
  margin: 0;
  font-style: italic;
`;

export const Title = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${gray};
  text-align: start;
  width: 100%;
  margin: 0 0 16px;
`;
