import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  req as getActivities,
  ResponseData
} from 'src/features/ActivitiesOfCompanies/actions/getActivities';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  BtnAddActivity,
  NoData,
  TDTextStyled
} from 'src/features/ActivitiesOfCompanies/components/styled';

interface StateToProps {
  activities: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getActivities: (companyId: string) => void;
}

interface OwnProps {
  companyInn: string;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

const ActivitiesListOfCompanies: React.FC<Props> = ({
  companyInn,
  getActivities,
  activities,
  status,
  error,
  match,
  history
}) => {
  React.useEffect(() => {
    getActivities(companyInn);
  }, []);

  const navigateToAddContact = () => {
    history.push('/crm/activities/new', { inn: match.params.inn });
  };

  const navigateToActivity = (id: number) => {
    history.push(`/crm/activities/${id}`);
  };

  const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);

    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');

    return `${dd}.${mm}.${yyyy} ${hh}:${min}:${ss}`;
  };

  return (
    <ScrollTopComponent>
      <BtnAddActivity onClick={navigateToAddContact}>
        Добавить активность
      </BtnAddActivity>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT &&
        (activities.items.length ? (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">ID</TableThStyled>
                  <TableThStyled width="10%">Тип активности</TableThStyled>
                  <TableThStyled width="10%">Дата и время</TableThStyled>
                  <TableThStyled width="15%">Инициатор</TableThStyled>
                  <TableThStyled width="10%">Результат</TableThStyled>
                  <TableThStyled width="10%">Комментарий</TableThStyled>
                  <TableThStyled width="10%">
                    Комментарий для агента
                  </TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {activities.items.map(activity => (
                  <TableRowStyled
                    key={activity.id}
                    onClick={() => navigateToActivity(activity.id)}
                  >
                    <td>{activity.id}</td>
                    <td>{activity.activityTypeName}</td>
                    <td>{formatDate(activity.createdDateTime)}</td>
                    <td>{activity.initiatorUserID}</td>
                    <TDTextStyled>{activity.result}</TDTextStyled>
                    <TDTextStyled>{activity.comment}</TDTextStyled>
                    <TDTextStyled>
                      {activity.showToAgent
                        ? activity.extComment
                        : 'Активность не видна агенту'}
                    </TDTextStyled>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={activities} />
          </>
        ) : (
          <NoData>
            <h3>НЕТ ДАННЫХ</h3>
            <p>на платформе пока нет активностей компании</p>
            <p>добавьте первую активность</p>
          </NoData>
        ))}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ ActivitiesOfCompanies }: CRM) => ({
  activities: ActivitiesOfCompanies.getActivitiesOfCompanies.data,
  status: ActivitiesOfCompanies.getActivitiesOfCompanies.status,
  error: ActivitiesOfCompanies.getActivitiesOfCompanies.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getActivities }, dispatch);

const ActivitiesListOfCompaniesConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActivitiesListOfCompanies)
);

export { ActivitiesListOfCompaniesConnect as ActivitiesListOfCompanies };
