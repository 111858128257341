export const DebtorsType = {
  FEDERAL: 'Федеральные сети',
  INTERNATIONAL: 'Крупные российские и международные компании',
  OTHER: 'Прочие'
};

export const PledgeType = {
  NO_REGRESS: 'Безрегресс',
  REGRESS: 'Регресс'
};

export const FederalDebtors = [
  { id: 'Х5 Retail Group N.V.', name: 'Х5 Retail Group N.V.' },
  {
    id: 'АШАН (ООО «АШАН», ООО «АТАК», ООО «АШАН ФЛАЙ»)',
    name: 'АШАН (ООО «АШАН», ООО «АТАК», ООО «АШАН ФЛАЙ»)'
  },
  { id: 'Лента (ООО «Лента»)', name: 'Лента (ООО «Лента»)' },
  {
    id: 'Мetro Group  (ООО «МЕТРО Кэш энд Керри»)',
    name: 'Мetro Group  (ООО «МЕТРО Кэш энд Керри»)'
  },
  { id: 'Магнит (АО «ТАНДЕР»)', name: 'Магнит (АО «ТАНДЕР»)' },
  { id: 'ООО «О"Кей»', name: 'ООО «О"Кей»' },
  {
    id: 'ГК Азбука вкуса (ООО Городской супермаркет»)',
    name: 'ГК Азбука вкуса (ООО Городской супермаркет»)'
  },
  { id: 'ООО «Элемент-Трейд»', name: 'ООО «Элемент-Трейд»' },
  { id: 'ООО «Гиперглобус»', name: 'ООО «Гиперглобус»' },
  { id: 'ООО «КЕЙ»', name: 'ООО «КЕЙ»' },
  {
    id: 'ООО «Леруа Мерлен Восток»',
    name: 'ООО «Леруа Мерлен Восток»'
  }
];

export const InternationalDebtors = [
  { id: 'ООО «Юнилевер Русь»', name: 'ООО «Юнилевер Русь»' },
  {
    id: 'М. Видео Менеджмент (ООО «М. Видео Менеджмент»),  ООО «Эльдорадо»',
    name: 'М. Видео Менеджмент (ООО «М. Видео Менеджмент»),  ООО «Эльдорадо»'
  },
  { id: 'АО «Связной Логистика»', name: 'АО «Связной Логистика»' },
  {
    id: 'ГК ЛУКОЙЛ',
    name: 'ГК ЛУКОЙЛ'
  },
  { id: 'ГК РОСНЕФТЬ', name: 'ГК РОСНЕФТЬ' },
  {
    id: 'ГК СИБУР  (АО «СИБУРТЮМЕНЬГАЗ»,  АО «СИБУР-ХИМПРОМ»)',
    name: 'ГК СИБУР  (АО «СИБУРТЮМЕНЬГАЗ»,  АО «СИБУР-ХИМПРОМ»)'
  },
  {
    id: 'ГК ЕВРАЗ ГРУПП (АО «ЕВРАЗ ЗСМК», АО «ЕВРАЗ КГОК»)',
    name: 'ГК ЕВРАЗ ГРУПП (АО «ЕВРАЗ ЗСМК», АО «ЕВРАЗ КГОК»)'
  },
  {
    id: 'ГК СЕВЕРСТАЛЬ (ПАО «Северсталь»)',
    name: 'ГК СЕВЕРСТАЛЬ (ПАО «Северсталь»)'
  },
  { id: 'ГК ММК (ПАО «ММК»)', name: 'ГК ММК (ПАО «ММК»)' },
  {
    id: 'ГК НОРИЛЬСКИЙ НИКЕЛЬ (ПАО «ГМК «Норильский никель»)',
    name: 'ГК НОРИЛЬСКИЙ НИКЕЛЬ (ПАО «ГМК «Норильский никель»)'
  },
  {
    id: 'ГК НЛМК (ПАО «Новолипецкий металлургический комбинат»)',
    name: 'ГК НЛМК (ПАО «Новолипецкий металлургический комбинат»)'
  },
  {
    id: 'ГК ДЕТСКИЙ МИР (ПАО «Детский мир»)',
    name: 'ГК ДЕТСКИЙ МИР (ПАО «Детский мир»)'
  },
  {
    id: 'ГК ЭКФО (АО «ЭФИРНОЕ»)',
    name: 'ГК ЭКФО (АО «ЭФИРНОЕ»)'
  },
  {
    id: 'ГК ДАНОН (ООО «Данон Индустрия» ООО «Данон Россия»)',
    name: 'ГК ДАНОН (ООО «Данон Индустрия» ООО «Данон Россия»)'
  }
];
