import * as React from 'react';
import { TENDER_TYPE, GUARANTEES_TYPE, TAX_TYPE } from 'Application/types';
import { TaxType, TenderType, GuaranteesType } from 'shared/constants';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { SetGuaranteesApplicationType } from 'src/features/Application/reducers/setGuaranteesFormData';
import { RadioButtonGroupContainer } from './styles';
import { PurchaseLinkInput } from '../styles';

interface OwnProps {
  required: boolean;
  tenderLinkRequired: boolean;
  errors: SetGuaranteesApplicationType;
  isTeledoc?: boolean;
  commercial: TENDER_TYPE;
  tax: TAX_TYPE;
  guaranteesType: GUARANTEES_TYPE;
  tender: TENDER_TYPE;
  typeComment: string;
  tenderLink: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

type Props = OwnProps;

export const GuaranteesTypeRadioGroup: React.FC<Props> = ({
  commercial,
  guaranteesType,
  tax,
  tender,
  tenderLink,
  typeComment,
  tenderLinkRequired,
  required,
  errors,
  isTeledoc,
  onChange
}) => {
  const isSubTypeVisible = () => {
    return (
      guaranteesType === GUARANTEES_TYPE['44-FZ'] ||
      guaranteesType === GUARANTEES_TYPE['223-FZ'] ||
      guaranteesType === GUARANTEES_TYPE['615-P']
    );
  };

  // const isTextFieldVisible = () => {
  //   return tax === TAX_TYPE.OTHER || tender === TENDER_TYPE.OTHER;
  // };

  const filterOptions = (options: object, exclude: string[]) => {
    return Object.keys(options)
      .filter(key => !exclude.includes(key))
      .map(key => ({ value: key, label: options[key] }));
  };

  const filteredGuaranteesBtns = filterOptions(GuaranteesType, [
    GUARANTEES_TYPE.TAX,
    GUARANTEES_TYPE.TENDER,
    ...(isTeledoc ? [GUARANTEES_TYPE.LIMIT] : [])
  ]);

  const filteredTenderBtns = filterOptions(TenderType, [
    ...(isTeledoc ? [TENDER_TYPE.OTHER] : [])
  ]);

  const filteredCommercialBtns = filterOptions(TenderType, [
    ...(isTeledoc ? [TENDER_TYPE.OTHER] : [])
  ]);

  const filteredTaxBtns = filterOptions(TaxType, [
    ...(isTeledoc ? [TAX_TYPE.OTHER] : [])
  ]);

  return (
    <RadioButtonGroupContainer>
      <RadioButtonGroup
        required={required}
        name="guaranteesType"
        label="Тип гарантии"
        keyValue={guaranteesType}
        onChange={onChange}
        radioBtns={filteredGuaranteesBtns}
      />

      {isSubTypeVisible() && (
        <RadioButtonGroup
          error={errors.tender}
          onChange={onChange}
          name="tender"
          keyValue={tender}
          radioBtns={filteredTenderBtns}
        />
      )}

      {guaranteesType === GUARANTEES_TYPE.COMMERCIAL && (
        <RadioButtonGroup
          error={errors.commercial}
          onChange={onChange}
          name="commercial"
          keyValue={commercial}
          radioBtns={filteredCommercialBtns}
        />
      )}

      {guaranteesType === GUARANTEES_TYPE.OTHER && (
        <RadioButtonGroup
          error={errors.tax}
          onChange={onChange}
          name="tax"
          keyValue={tax}
          radioBtns={filteredTaxBtns}
        />
      )}
      {/* {isTextFieldVisible() && (
        <Textarea
          onChange={onChange}
          name="typeComment"
          value={typeComment}
          placeholder="Ваш комментарий …"
        />
      )} */}
      {(guaranteesType === GUARANTEES_TYPE['223-FZ'] ||
        guaranteesType === GUARANTEES_TYPE['44-FZ'] ||
        guaranteesType === GUARANTEES_TYPE['615-P'] ||
        guaranteesType === GUARANTEES_TYPE['COMMERCIAL']) && (
        <PurchaseLinkInput
          onChange={onChange}
          value={tenderLink}
          label="Ссылка на закупку"
          name="tenderLink"
          required={tenderLinkRequired}
          error={errors.tenderLink}
          placeholder="Прикрепите ссылку на закупку"
        />
      )}
    </RadioButtonGroupContainer>
  );
};
