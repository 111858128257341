import styled from 'styled-components';
import { BoxStyled } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const TitleStyled = styled.h2`
  margin-bottom: 32px;
`;

export const ResetPasswordBox = styled(BoxStyled)`
  width: 490px;
`;

export const GoToLoginBtn = styled(ButtonStyled)`
  margin-top: 20px;
  width: 150px !important;
`;
