import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/ProductsForBank/actions/sendProductData';

import { SEND_PRODUCT_BANK } from 'src/features/ProductsForBank/reducers/sendProductData';
import ProductsApi from '../api';

function* sendProductBankData(action: {
  type: SEND_PRODUCT_BANK;
  data: any;
}): Generator {
  try {
    let res: any;

    res = yield call(ProductsApi.sendProductData, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendProductBankDataSaga(): Generator {
  yield takeLatest(SEND_PRODUCT_BANK.REQ, sendProductBankData);
}
