import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface SCFStatusStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  status: SCF_STATUSES;
}

export enum SCF_STATUSES {
  NONE = '',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum SCF_STATUS_ACTIONS {
  REQ = 'SCF_STATUS_REQ',
  GOT = 'SCF_STATUS_GOT',
  ERR = 'SCF_STATUS_ERR'
}

export const initialState: SCFStatusStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    status: SCF_STATUSES.NONE
  },
  error: {}
};

const SCFStatus = (
  state: SCFStatusStoreState = initialState,
  action: {
    type: SCF_STATUS_ACTIONS;
    data: ResponseData;
    error: {};
  }
): SCFStatusStoreState => {
  switch (action.type) {
    case SCF_STATUS_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SCF_STATUS_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case SCF_STATUS_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default SCFStatus;
