import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getFactoringLimitsByDebtorList';

import {
  GET_FACTORING_LIMITS_BY_DEBTOR_LIST,
} from '../reducers/getFactoringLimitsByDebtorList';
import FactoringLimitsApi from '../api';

function* getFactoringLimitsByDebtorList(action: {
  type: GET_FACTORING_LIMITS_BY_DEBTOR_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(FactoringLimitsApi.getFactoringLimitsByDebtorList, action.data.debtorInn);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringLimitsByDebtorListSaga(): Generator {
  yield takeLatest(GET_FACTORING_LIMITS_BY_DEBTOR_LIST.REQ, getFactoringLimitsByDebtorList);
}
