import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getUserDebtorsList';

import { GET_USER_DEBTOR_LIST } from '../reducers/getUserDebtorList';
import UserDebtorsApi from '../api';

function* getUserDebtorList(action: {
  type: GET_USER_DEBTOR_LIST;
  data: RequestData;
}): Generator {
  try {
    let res = yield call(UserDebtorsApi.getAllUserDebtors, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserDebtorListSaga(): Generator {
  yield takeLatest(GET_USER_DEBTOR_LIST.REQ, getUserDebtorList);
}
