import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/postFinancialDocumentExternal';

import { POST_FINANCIAL_DOCUMENT_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postFinancialDocumentExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* postFinancialDocumentExternal(action: {
  type: POST_FINANCIAL_DOCUMENT_EXTERNAL;
  guid: string;
  data: FormData;
}): Generator {
  try {
    yield call(
      ExternalAgentRoleApi.postFinancialDocumentExternal,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFinancialDocumentExternalSaga(): Generator {
  yield takeLatest(
    POST_FINANCIAL_DOCUMENT_EXTERNAL.REQ,
    postFinancialDocumentExternal
  );
}
