import { SET_PRODUCT_DATA } from '../reducers/setProductFormData';

export interface ProductWrite {
  applicationFormRequiredToStart?: boolean;
  authorityLevel?: number;
  bankAccountPolicy?: null;
  bankINN?: string;
  bankName?: string;
  cashbackBase?: number;
  cashbackComment?: null;
  ceoGuaranteePolicy?: number;
  clientAnchoring?: null;
  clientControl?: boolean;
  comfortPledgeShare?: null;
  commercialBeneficiariesListPolicy?: null;
  commonDocsQtyForTermsheet?: number;
  complexFieldsQtyForTermsheet?: number;
  complexity?: null;
  curatorsDescription?: null;
  currencyPolicy?: number;
  dataQuality?: number;
  descriptiveComment?: null;
  digitalSignatureRequiredToStart?: boolean;
  ebitdaComfortMultiplyer?: null;
  ebitdaMaximalMultiplyer?: null;
  emptyCompanyPolicy?: null;
  exclusivity?: null;
  financeTypeId?: number;
  financeTypeName?: string;
  hasCashback?: null;
  hasContract?: null;
  hasPreferentials?: null;
  id?: number;
  incomeComfortDividor?: null;
  incomeMinimalDividor?: null;
  incommensurableCompanyPolicy?: null;
  isBankProduct?: boolean;
  isDeleted?: boolean;
  isFor44?: boolean;
  isFor223?: boolean;
  isFor615?: boolean;
  isForCaucasusClient?: boolean;
  isForCaucasusCustomer?: boolean;
  isForCommercialCompanies?: boolean;
  isForCommercialContracts?: boolean;
  isForCrimeanClient?: boolean;
  isForCrimeanCustomer?: boolean;
  isForEnterpreneurs?: boolean;
  isForGoz?: boolean;
  isForIndividuals?: boolean;
  isForLarge?: boolean;
  isForMedium?: boolean;
  isForMicro?: boolean;
  isForNonResident?: boolean;
  isForSelfEmployed?: boolean;
  isForSmall?: boolean;
  isForStateCompanies?: boolean;
  isInPlatform?: boolean;
  isVisibleToBank?: boolean;
  maxCashbackPerDeal?: null;
  maxCashbackPerMonth?: null;
  maxClientRevenue?: null;
  maxComfortClientRevenue?: null;
  maxComfortSize?: number;
  maxOverallCashback?: null;
  maxRate?: null;
  maxSize?: number;
  maxTermInMonthes?: number;
  meanRate?: null;
  minClientRevenue?: null;
  minComfortClientRevenue?: null;
  minComfortSize?: number;
  minPriceInRub?: null;
  minRate?: null;
  minSize?: number;
  minTermInMonthes?: null;
  minimalCompanyAge?: null;
  minimalPledgeShare?: null;
  mspSubjectPolicy?: null;
  negativeCapitalPolicy?: null;
  ownerGuaranteePolicy?: number;
  productManagerId?: number;
  productName?: string;
  productNameForBank?: null;
  productRating?: number;
  rateIsSurplusToKeyRate?: null;
  riskAppetite?: null;
  simpleFieldsQtyForTermsheet?: number;
  specificDocsQtyForTermsheet?: number;
  standardCashback?: number;
  unprofitableCompanyPolicy?: null;
  withoutCreditHistory?: null;
  forbiddenRegions?: string;
}

export interface ProductRead extends ProductWrite {}

export interface ProductsData extends Partial<ProductWrite> {}

export const setProductFormData = (data: ProductsData) => ({
  type: SET_PRODUCT_DATA.SET,
  data
});

export const resetProductFormData = () => ({
  type: SET_PRODUCT_DATA.RESET
});
