import { CertificateData } from '../actions/setCertificate';

export interface SetCertificateDataStoreState extends Partial<CertificateData> { }

export enum SET_CERTIFICATE_DATA {
    SET = 'SET_CERTIFICATE_DATA_SET',
    RESET = 'SET_CERTIFICATE_DATA_RESET',
}

export const initialState: SetCertificateDataStoreState = {
    companyInn: null,
    companyOgrnOrOgrnIP: null,
    companyName_O: null,
    companyName_CN: null,
    companyCountryC: null,
    companyRegionS: null,
    companyLocationL: null,
    companyAddressStreet: null,
    positionT: null,
    personSurNameSN: null,
    personNameG: null,
    personInn: null,
    personEmailE: null,
    personSnils: null,
    certValidFrom: null,
    certValidUntil: null,
    keyValidFrom: null,
    keyValidUntil: null,
    issuerInn: null,
    issuerOGRN: null,
    issuerName_O: null,
    issuerName_CN: null,
    issuerTypeOU: null,
    issuerCountryC: null,
    issuerRegionS: null,
    issuerLocationL: null,
    issuerAddressStreet: null,
    issuerEmailE: null,
    serialNumber: null,
};

const setCertificateData = (
    state: SetCertificateDataStoreState = initialState,
    action: {
        type: string;
        data: CertificateData;
    }
): SetCertificateDataStoreState => {
    switch (action.type) {
        case SET_CERTIFICATE_DATA.SET:
            return {
                ...state,
                ...action.data
            };
        case SET_CERTIFICATE_DATA.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default setCertificateData;
