import { SIGNIN_ACTIONS } from 'User/reducers/signIn';

export interface RequestDataType {
  email: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: SIGNIN_ACTIONS.REQ,
  data
});

export const got = () => ({
  type: SIGNIN_ACTIONS.GOT
});

export const err = (error: {}) => ({
  type: SIGNIN_ACTIONS.ERR,
  error
});

export const reset = () => ({
  type: SIGNIN_ACTIONS.RESET
});
