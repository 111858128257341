import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getAllDocumentForms,
  RequestData as ReqGetDocumentForms,
  ResponseData as DocumentsFormsRes
} from 'entities/Cabinet/InternalAgent/model/actions/getAllDocumentForms';
import { req as getFormFile } from 'entities/Cabinet/InternalAgent/model/actions/getInternalFormFile';
import { ResponseData as ResActiveDDSOfDebtor } from 'entities/Cabinet/InternalAgent/model/actions/getActiveDDSOfDebtor';
import { Loader, Menu } from 'src/features/Layouts/components';
import { DEBTORS_TABS } from './types';
import {
  DiscountingContainer,
  SupplierInfo,
  SupplierInfoArea,
  NoData
} from './styled';
import { EarlyPaymentApplications } from 'src/features/EarlyPaymentApplications/components/EarlyPaymentApplications';
import { DiscountingInfo } from '../DiscountingInfo/DiscountingInfo';
import { Certificates } from '../Certificates/Certificates';
import {
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  DownloadBtn,
  FormOfTheAgreement,
  TDMain,
  TooltipBoxStyle,
  TypeContainer,
  TypeNoActive,
  TypeNoInactive,
  TypeYesActive,
  TypeYesInactive
} from '../DiscountingInfo/styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SupplierList } from '../SupplierList/SupplierList';

interface StateToProps {
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  activeDDS: ResActiveDDSOfDebtor;
  statusActiveDDS: REQUEST_STATUSES;
  documents: DocumentsFormsRes[];
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  getAllDocumentForms: (data: ReqGetDocumentForms) => void;
  getFormFile: (guid: string, name: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const DebtorView: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  status,
  activeDDS,
  statusActiveDDS,
  getAllDocumentForms,
  getFormFile,
  documents,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof DEBTORS_TABS>(
    (match.params.tab &&
      (match.params.tab.toUpperCase() as keyof typeof DEBTORS_TABS)) ||
      'DEFAULT'
  );

  React.useEffect(
    () => {
      if (match.params.inn) {
        getCompanyByInn({ inn: match.params.inn });
        getAllDocumentForms({ type: 1, owner: match.params.inn });
      }
    },
    [match.params.inn]
  );

  const handleActiveTab = (tabName: keyof typeof DEBTORS_TABS) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.push(newPathname);
    setActiveTab(tabName as keyof typeof DEBTORS_TABS);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <SupplierInfoArea>
          <p>Покупатель</p>
          <h1>{companyByInn.companyFullName}</h1>
          <SupplierInfo>
            <div>
              <p>
                ИНН:{' '}
                {companyByInn.inn ? companyByInn.inn : Number(match.params.inn)}
              </p>
              <p>ОГРН: {companyByInn.ogrn}</p>
              <p>КПП: {companyByInn.kpp}</p>
            </div>
            <p>Адрес: {companyByInn.companyAddress}</p>
          </SupplierInfo>
        </SupplierInfoArea>
      )}

      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={DEBTORS_TABS}
      />

      {activeTab === 'DEFAULT' && <EarlyPaymentApplications />}

      {activeTab === 'CERTIFICATES' && <Certificates />}

      {activeTab === 'DISCOUNTING_SETTINGS' && (
        <DiscountingContainer>
          <h1>Политика дисконтирования</h1>
          <div>
            <DiscountingInfo inn={match.params.inn} subTitle="Дебитор" />
            <DiscountingInfo inn="7704468253" subTitle="Эталон" />
          </div>

          <ContractsInfoArea>
            <FormOfTheAgreement>
              <h1>Формы соглашений для сделок дисконтирования:</h1>

              {!!documents.length ? (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="5%">ID</TableThStyled>
                      <TableThStyled width="10%">Наименование</TableThStyled>
                      <TableThStyled width="5%">Основная</TableThStyled>
                      <TableThStyled width="20%" />
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {documents.map(doc => (
                      <TableRowStyled key={doc.guid}>
                        <td>{doc.guid.slice(-7)}</td>
                        <td>{doc.name}</td>
                        <TDMain>
                          {doc.isMain && (
                            <TooltipBoxStyle
                              textVerify={
                                'Используется по умолчанию, если для договора поставки не установлена форма'
                              }
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </TooltipBoxStyle>
                          )}
                        </TDMain>
                        <td>
                          <DownloadBtn
                            onClick={() =>
                              getFormFile(doc.guid, doc.exampleFilename)
                            }
                          >
                            Скачать
                          </DownloadBtn>
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              ) : (
                <NoData>
                  <h2>ФОРМЫ НА ПЛАТФОРМЕ ОТСУТСТВУЮТ</h2>
                </NoData>
              )}
            </FormOfTheAgreement>
          </ContractsInfoArea>

          <ContractsInfoArea>
            <FormOfTheAgreement>
              <h1 style={{ marginBottom: '20px' }}>Подписание документов:</h1>

              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Формировать протоколы:
                </ContractsInfoItemTitle>

                {!!activeDDS && activeDDS.protocolsPolicy == true ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </FormOfTheAgreement>
          </ContractsInfoArea>

          {statusActiveDDS === REQUEST_STATUSES.ERROR && (
            <NoData>
              <h2>
                Действующие настройки дисконтирования у этого дебитора не
                найдены
              </h2>
            </NoData>
          )}
        </DiscountingContainer>
      )}

      {activeTab === 'SUPPLIERS' && <SupplierList />}
    </ScrollTopComponent>
  );
};

const mapStateToProps = (
  { SCF, InternalAgentRole }: STORE,
  ownProps: Props
) => {
  const debtorData = InternalAgentRole.getActiveDDSOfDebtor[
    ownProps.match.params.inn
  ] || {
    status: REQUEST_STATUSES.NONE,
    data: null,
    error: {}
  };

  return {
    companyByInn: SCF.getCompanyByInnThirdParty.data,
    status: SCF.getCompanyByInnThirdParty.status,
    error: SCF.getCompanyByInnThirdParty.error,
    activeDDS: debtorData.data,
    statusActiveDDS: debtorData.status,
    documents: InternalAgentRole.getAllDocumentsForms.data
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCompanyByInn, getAllDocumentForms, getFormFile },
    dispatch
  );

const DebtorViewConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorView)
);

export { DebtorViewConnect as DebtorView };
