import { call, put, takeEvery } from 'redux-saga/effects';
import { got, err, RequestData } from 'entities/Cabinet/InternalAgent/model/actions/patchSetDiscountMargin';
import { PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/patchSetDiscountMargin';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* patchSetDiscountMargin(action: {
  type: PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL;
  id: number;
  data: RequestData;
}): Generator {
  try {
    yield call(
      InternalAgentRoleApi.patchSetDiscountMargin,
      action.id,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchSetDiscountMarginSaga(): Generator {
  yield takeEvery(
    PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL.REQ,
    patchSetDiscountMargin
  );
}
