import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getPOAS';

export interface GetPOASStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_POAS {
  REQ = 'GET_POAS_REQ',
  GOT = 'GET_POAS_GOT',
  ERR = 'GET_POAS_ERR'
}

export const initialState: GetPOASStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getPOAS = (
  state: GetPOASStoreState = initialState,
  action: {
    type: GET_POAS;
    data: ResponseData[];
    error: {};
  }
): GetPOASStoreState => {
  switch (action.type) {
    case GET_POAS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_POAS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_POAS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPOAS;
