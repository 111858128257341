import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetDocumentListExternalStoreState extends Reducer {
  data: ResponseData;
}

export interface DocumentList {
  guid: string;
  companyINN: string;
  companyShortName: string;
  companyRole: string;
  personINN: string;
  personName: string;
  personComment: string;
  documentTypeId: number;
  documentCategory: number;
  documentName: string;
  taxYear: number;
  taxPeriodId: number;
  taxPeriodName: string;
  isMandatory: boolean;
  isUploaded: boolean;
}

export interface ResponseData {
  guid: string;
  items: DocumentList[];
}

export enum GET_DOCUMENT_LIST_EXTERNAL {
  REQ = 'GET_DOCUMENT_LIST_EXTERNAL_REQ',
  GOT = 'GET_DOCUMENT_LIST_EXTERNAL_GOT',
  ERR = 'GET_DOCUMENT_LIST_EXTERNAL_ERR',
  RESET = 'GET_DOCUMENT_LIST_EXTERNAL_RESET'
}

export const initialState: GetDocumentListExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    items: []
  },
  error: {}
};

const getDocumentListExternal = (
  state: GetDocumentListExternalStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetDocumentListExternalStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_DOCUMENT_LIST_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_DOCUMENT_LIST_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    case GET_DOCUMENT_LIST_EXTERNAL.RESET:
      return { ...initialState };

    default:
      return state;
  }
};

export default getDocumentListExternal;
