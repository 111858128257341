import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/toggleLeadogeneratorPermission';

import {
  TOGGLE_LEADOGENERATOR_PERMISSION,
} from '../reducers/toggleLeadogeneratorPermission';
import ManageExternalAgentsApi from '../api';

function* toggleLeadogeneratorPermission(action: {
  type: TOGGLE_LEADOGENERATOR_PERMISSION;
  userId: number;
}): Generator {
  try {

    yield call(ManageExternalAgentsApi.toggleLeadogeneratorPermission,action.userId);

    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* toggleLeadogeneratorPermissionSaga(): Generator {
  yield takeLatest(TOGGLE_LEADOGENERATOR_PERMISSION.REQ, toggleLeadogeneratorPermission);
}
