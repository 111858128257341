import {
  RequestDataType,
  DELETE_BORROWER_COMPANY
} from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

export const req = (data: RequestDataType) => ({
  type: DELETE_BORROWER_COMPANY.REQ,
  data
});

export const got = () => ({
  type: DELETE_BORROWER_COMPANY.GOT
});

export const err = (error: {}) => ({
  type: DELETE_BORROWER_COMPANY.ERR,
  error
});
