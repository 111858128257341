import * as React from 'react';
import { connect } from 'react-redux';
import {
  STORE,
  ResponseError,
  REQUEST_STATUSES,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { LeadStatuses, ApplicationTypes } from 'shared/constants';
import { Dispatch, bindActionCreators } from 'redux';
import { format, parse } from 'date-fns';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getLeadsList,
  ResponseData as ResLeadsList,
  RequestData as ReqLeadsList
} from 'entities/Cabinet/ExternalAgent/model/actions/getExternalLeads';
import { TransferApplicationBtn } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ApplicationStatus/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as changeLeadStatus,
  RequestData as changeLeadStatusRequestData
} from 'src/features/Leads/actions/changeLeadStatus';
import { formSumString } from 'src/shared/utils/Utils';
import { TypeFilter } from 'src/features/Filters/TypeFilter/components/TypeFilter';
import { INNFilter } from 'src/features/Filters/INNFilter/components/INNFilter';
import {
  ApplicationChecklist,
  BgParametsChecklist,
  BkiChecklist,
  ChecklistContainer,
  Content,
  DealChecklist,
  DocChecklist,
  FilterWrapper,
  TDChecklist
} from 'src/features/Leads/components/LeadsList/styled';
import { DateFilter } from 'src/features/Filters/DateFilter/components/DateFilter';
import { NameClientFilter } from 'src/features/Filters/NameClientFilter/components/NameClientFilter';
import { StatusFilter } from 'src/features/Filters/Status/components/Status';
import { LEAD_LIST_TYPE, LEAD_STATUSES } from 'src/features/Leads/types';
import { LeadStatusStyled } from 'src/features/Leads/components/LeadView/styled';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  leads: ResLeadsList;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getLeadsList: (data: ReqLeadsList) => void;
  changeLeadStatus: (data: changeLeadStatusRequestData) => void;
}

interface OwnProps {
  listType: LEAD_LIST_TYPE;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const ExternalLeadsList: React.FC<Props> = ({
  getLeadsList,
  leads,
  status,
  error,
  changeLeadStatus,
  permissions,
  listType,
  history
}) => {
  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;

      getLeadsList({ page, pageSize: 20 });
    },
    [history.location.search]
  );

  const onLeadClick = (id: number) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/lead/${id}`);
  };

  const renderStatus = (lead: any) => {
    return lead.status === LEAD_STATUSES.CREATED &&
      listType !== LEAD_LIST_TYPE.MY ? (
      <TransferApplicationBtn
        onClick={() =>
          changeLeadStatus({ leadId: lead.id, status: LEAD_STATUSES.IN_WORK })
        }
      >
        Взять в работу
      </TransferApplicationBtn>
    ) : (
      <LeadStatusStyled status={lead.status}>
        {LeadStatuses[lead.status]}
      </LeadStatusStyled>
    );
  };

  return (
    <ScrollTopComponent>
      {(listType === LEAD_LIST_TYPE.ALL ||
        listType === LEAD_LIST_TYPE.IN_WORK ||
        listType === LEAD_LIST_TYPE.DEAL_SUCCEED) && (
        <FilterWrapper>
          <TypeFilter />
          <INNFilter label="ИНН клиента" />
          <NameClientFilter />
          <DateFilter label="Дата поступления" from="С" until="По" />
          <StatusFilter statusList={LeadStatuses} />
        </FilterWrapper>
      )}
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="3%">Id</TableThStyled>
                <TableThStyled width="10%">Клиент</TableThStyled>
                <TableThStyled width="10%">Тип финансирования</TableThStyled>
                <TableThStyled width="5%">Дата создания</TableThStyled>
                <TableThStyled width="5%">Сумма</TableThStyled>
                <TableThStyled width="5%">Статус</TableThStyled>
                <TableThStyled width="5%">Контакт</TableThStyled>
                <TableThStyled width="8%">Причина отказа</TableThStyled>
                <TableThStyled width="8%">Чек-лист</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {leads.items.map(lead => (
                <TableRowStyled
                  key={lead.id}
                  onClick={() => onLeadClick(lead.id)}
                >
                  <td>{lead.id}</td>
                  <td>{lead.clientName}</td>
                  <td>{ApplicationTypes[lead.applicationFinancingType]}</td>
                  <td>
                    {format(
                      parse(
                        lead.createdDateTime,
                        'MM/dd/yyyy HH:mm:ss',
                        new Date()
                      ),
                      'dd.MM.yyyy'
                    )}
                  </td>
                  <td>{formSumString(lead.amount)}</td>
                  <td>{renderStatus(lead)}</td>
                  <td />
                  <td>{lead.rejectReason}</td>
                  {[
                    USER_PERMISSIONS.INTERNAL_AGENT,
                    USER_PERMISSIONS.EXTERNAL_AGENT
                  ].isIn(permissions) && (
                    <TDChecklist>
                      <ChecklistContainer>
                        {!!lead.docsChecklist && (
                          <DocChecklist type={lead.docsChecklist}>
                            <Content>
                              <div>
                                <p>Список документов</p>
                                <p>
                                  {lead.docsChecklist === 2
                                    ? 'Документы запрошены'
                                    : lead.docsChecklist === 4
                                      ? 'Предоставлены частично'
                                      : lead.docsChecklist === 6
                                        ? 'Предоставлены только обязательные'
                                        : lead.docsChecklist === 8
                                          ? 'Предоставлены все документы'
                                          : 'Документы еще не запрошены'}
                                </p>
                              </div>
                              <DocChecklist type={lead.docsChecklist} />
                            </Content>
                          </DocChecklist>
                        )}
                        {!!lead.bkiChecklist && (
                          <BkiChecklist type={lead.bkiChecklist}>
                            <Content>
                              <div>
                                <p>Согласие перс.данных и БКИ</p>
                                <p>
                                  {lead.bkiChecklist === 2
                                    ? 'Ссылка направлена, но не подписана'
                                    : lead.bkiChecklist === 4
                                      ? 'Ссылка подписана'
                                      : 'Согласие еще не запрошено'}
                                </p>
                              </div>
                              <BkiChecklist type={lead.bkiChecklist} />
                            </Content>
                          </BkiChecklist>
                        )}
                        {!!lead.bgParametersChecklist && (
                          <BgParametsChecklist
                            type={lead.bgParametersChecklist}
                          >
                            <Content>
                              <div>
                                <p>Согласование БГ с заказчиком</p>
                                <p>
                                  {lead.bgParametersChecklist === 2
                                    ? 'Макет направлен для согласования'
                                    : lead.bgParametersChecklist === 4
                                      ? 'Согласовано с заказчиком'
                                      : 'Макет еще не направлен'}
                                </p>
                              </div>
                              <BgParametsChecklist
                                type={lead.bgParametersChecklist}
                              />
                            </Content>
                          </BgParametsChecklist>
                        )}
                        {!!lead.applicationToBankCheckList && (
                          <ApplicationChecklist
                            type={lead.applicationToBankCheckList}
                          >
                            <Content>
                              <div>
                                <p>Подача заявки в банк</p>
                                <p>
                                  {lead.applicationToBankCheckList === 2
                                    ? 'Ссылка банка направлена для подписания'
                                    : lead.applicationToBankCheckList === 4
                                      ? 'Заявка подана в банк'
                                      : 'Ссылка банка еще не направлена'}
                                </p>
                              </div>
                              <ApplicationChecklist
                                type={lead.applicationToBankCheckList}
                              />
                            </Content>
                          </ApplicationChecklist>
                        )}
                        {!!lead.dealToBankChecklist && (
                          <DealChecklist type={lead.dealToBankChecklist}>
                            <Content>
                              <div>
                                <p>Подписание кредитной документации</p>
                                <p>
                                  {lead.dealToBankChecklist === 2
                                    ? 'Ссылка банка направлена для подписания'
                                    : lead.dealToBankChecklist === 4
                                      ? 'Документация с банком подписана'
                                      : 'Ссылка банка еще не направлена'}
                                </p>
                              </div>
                              <DealChecklist type={lead.dealToBankChecklist} />
                            </Content>
                          </DealChecklist>
                        )}
                      </ChecklistContainer>
                    </TDChecklist>
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={leads} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ User, ExternalAgentRole }: STORE) => ({
  leads: ExternalAgentRole.getExternalLeads.data,
  status: ExternalAgentRole.getExternalLeads.status,
  error: ExternalAgentRole.getExternalLeads.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getLeadsList, changeLeadStatus }, dispatch);

const ExternalLeadsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ExternalLeadsList)
);

export { ExternalLeadsListConnect as ExternalLeadsList };
