import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { req as getActivitiesById } from 'src/features/Activities/actions/getActivitiesById';
import {
  req as getContactsOfActivities,
  ResponseData as ContactsOfActivitiesData
} from 'src/features/Activities/actions/getContactsOfActivity';
import {
  req as getParticipantsOfActivities,
  ResponseData as ParticipantsOfActivitiesData
} from 'src/features/Activities/actions/getParticipantsOfActivity';
import {
  req as getContactList,
  ResponseData as ContactsData
} from 'src/features/Contacts/actions/getContactsList';
import {
  req as addContact,
  reset as resetAddContact
} from 'src/features/Activities/actions/addContact';
import {
  req as addParticipant,
  reset as resetAddParticipant
} from 'src/features/Activities/actions/addParticipant';
import {
  req as getUsers,
  RequestData as UsersReqData,
  ResponseData as UsersResData
} from 'src/features/Users/actions/getUserList';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Pagination
} from 'src/features/Layouts/components';
import {
  CardCompany,
  ContactList,
  Container,
  PopupContentContacts
} from 'src/features/Activities/components/ActivitiesView/styled';
import { Activities } from 'src/features/Activities/types';
import { Button } from 'shared/ui/Button';
import {
  PopupContainer,
  PopupTitle
} from 'src/features/Contacts/components/ContactsList/styled';

interface StateToProps {
  activitiesById: Activities;
  status: REQUEST_STATUSES;
  error: ResponseError;
  contactsActivities: ContactsOfActivitiesData;
  statusContacts: REQUEST_STATUSES;
  errorContacts: ResponseError;
  participants: ParticipantsOfActivitiesData;
  statusParticipants: REQUEST_STATUSES;
  errorParticipants: ResponseError;
  contacts: ContactsData;
  users: UsersResData;
  statusAddedParticipants: REQUEST_STATUSES;
  statusAddedContacts: REQUEST_STATUSES;
}

interface State {
  contactsIsVisible: boolean;
  participantsIsVisible: boolean;
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  getActivitiesById: (id: string) => void;
  getContactsOfActivities: (id: string) => void;
  getParticipantsOfActivities: (id: string) => void;
  getContactList: (inn: string) => void;
  addContact: (activityId: string, contactId: number) => void;
  addParticipant: (activityId: string, contactId: number) => void;
  getUsers: (data: UsersReqData) => void;
  resetAddContact: () => void;
  resetAddParticipant: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ActivitiesView extends React.Component<Props, State> {
  state: State = {
    contactsIsVisible: false,
    participantsIsVisible: false
  };

  componentDidMount() {
    this.props.getActivitiesById(this.props.match.params.id);
    this.props.getContactsOfActivities(this.props.match.params.id);
    this.props.getParticipantsOfActivities(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.statusAddedParticipants === REQUEST_STATUSES.GOT) {
      this.props.getParticipantsOfActivities(this.props.match.params.id);
      this.props.resetAddParticipant();
    }
    if (this.props.statusAddedContacts === REQUEST_STATUSES.GOT) {
      this.props.getContactsOfActivities(this.props.match.params.id);
      this.props.resetAddContact();
    }
  }

  contactModalRef = React.createRef();
  participantsModalRef = React.createRef();

  navigateToCompany = () => {
    history.push(`/crm/companies/${this.props.activitiesById.companyInn}`);
  };

  openModalContact = e => {
    e.stopPropagation();
    this.setState({ contactsIsVisible: true });
    this.props.getContactList(this.props.activitiesById.companyInn);
  };

  openModalParticipants = e => {
    e.stopPropagation();

    this.setState({ participantsIsVisible: true });

    this.props.getUsers({
      page: 1,
      pageSize: 20,
      role: 'INTERNALAGENT',
      isBlocked: false
    });
  };

  handleAddContact = (contactId: number) => {
    this.props.addContact(this.props.match.params.id, contactId);
    this.setState({ contactsIsVisible: false });
  };

  handleAddParticipant = (participantId: number) => {
    this.props.addParticipant(this.props.match.params.id, participantId);
    this.setState({ participantsIsVisible: false });
  };

  navigateToCreateContact = () => {
    history.push('/crm/contacts/new', {
      inn: this.props.activitiesById.companyInn
    });
  };

  handleOutsideClickContact = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.contactModalRef.current &&
      !(this.contactModalRef.current as Element).contains(target)
    ) {
      this.setState({ contactsIsVisible: false });
    }
  };

  handleOutsideClickParticipants = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.participantsModalRef.current &&
      !(this.participantsModalRef.current as Element).contains(target)
    ) {
      this.setState({ participantsIsVisible: false });
    }
  };

  render() {
    const {
      activitiesById,
      status,
      error,
      contactsActivities,
      participants,
      contacts,
      users
    } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ApplicationTitle>Звонок</ApplicationTitle>

            {activitiesById.companyName && (
              <CardCompany onClick={this.navigateToCompany}>
                <span>Компания</span>
                <span>{activitiesById.companyName}</span>
              </CardCompany>
            )}

            <Container>
              <div>
                <p>Результат:</p>
                {activitiesById.result ? (
                  <p>{activitiesById.result}</p>
                ) : (
                  <span>Вы не заполняли поле Результат</span>
                )}
              </div>

              <div>
                <p>Комментарий (внутренний):</p>
                {activitiesById.comment ? (
                  <p>{activitiesById.comment}</p>
                ) : (
                  <span>
                    Вы не заполняли комментарий для внутреннего пользования
                  </span>
                )}
              </div>

              <div>
                <p>Комментарий (для агента):</p>
                {activitiesById.extComment ? (
                  <p>{activitiesById.extComment}</p>
                ) : (
                  <span>Вы не заполняли комментарий для агента</span>
                )}
              </div>

              <div>
                <div>
                  <p>Участники клиента</p>
                  <Button
                    label={'+ Добавить'}
                    onClick={this.openModalContact}
                    w="110px"
                    h="20px"
                  />
                </div>
                <ul>
                  {contactsActivities.items.map(contact => (
                    <li key={contact.id}>
                      {contact.contactLastName} {contact.contactFirstName}{' '}
                      {contact.contactMiddleName}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <div>
                  <p>Участники FINFACTORY</p>
                  <Button
                    label={'+ Добавить'}
                    onClick={this.openModalParticipants}
                    w="110px"
                    h="20px"
                  />
                </div>
                <ul>
                  {participants.items.map(participant => (
                    <li key={participant.id}>
                      {participant.userLastName} {participant.userFirstName}
                    </li>
                  ))}
                </ul>
              </div>
            </Container>

            <PopupContainer
              visible={this.state.contactsIsVisible}
              onClick={this.handleOutsideClickContact}
            >
              <PopupContentContacts ref={this.contactModalRef}>
                <PopupTitle>Выберите контакт клиента</PopupTitle>
                <ContactList>
                  {contacts.items.map(contact => (
                    <li
                      key={contact.id}
                      onClick={() => this.handleAddContact(contact.id)}
                    >
                      {contact.lastName} {contact.firstName}{' '}
                      {contact.middleName} {contact.position}
                    </li>
                  ))}
                </ContactList>
                <div>
                  <p>Нет в списке?</p>
                  <Button
                    label={'+ Создать новый контакт'}
                    onClick={this.navigateToCreateContact}
                    w="170px"
                    h="20px"
                  />
                </div>
                <Pagination list={contacts} />
              </PopupContentContacts>
            </PopupContainer>

            <PopupContainer
              visible={this.state.participantsIsVisible}
              onClick={this.handleOutsideClickParticipants}
            >
              <PopupContentContacts ref={this.participantsModalRef}>
                <PopupTitle>Выберите участника</PopupTitle>
                <ContactList>
                  {users.items.map(user => (
                    <li
                      key={user.id}
                      onClick={() => this.handleAddParticipant(user.id)}
                    >
                      {user.lastName} {user.firstName}
                    </li>
                  ))}
                </ContactList>
                <Pagination list={contacts} />
              </PopupContentContacts>
            </PopupContainer>
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Activities, Contacts, Users }: CRM) => ({
  activitiesById: Activities.getActivitiesById.data,
  status: Activities.getActivitiesById.status,
  error: Activities.getActivitiesById.error,
  contactsActivities: Activities.getContactsOfActivities.data,
  statusContacts: Activities.getContactsOfActivities.status,
  errorContacts: Activities.getContactsOfActivities.error,
  participants: Activities.getParticipantsOfActivity.data,
  statusParticipants: Activities.getParticipantsOfActivity.status,
  errorParticipants: Activities.getParticipantsOfActivity.error,
  contacts: Contacts.getContactsList.data,
  users: Users.getUsersList.data,
  statusAddedParticipants: Activities.addParticipant.status,
  statusAddedContacts: Activities.addContact.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getActivitiesById,
      getContactsOfActivities,
      getParticipantsOfActivities,
      getContactList,
      addContact,
      addParticipant,
      getUsers,
      resetAddContact,
      resetAddParticipant
    },
    dispatch
  );

const ActivitiesViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ActivitiesView)
);

export { ActivitiesViewConnect as ActivitiesView };
