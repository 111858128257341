import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CrossCircleBtn, LoaderContainer } from 'shared/styled';
import {
  rl,
  rr,
  gray,
  navyBlue,
  lighterBlue,
  blue,
  lightGray
} from 'shared/styled';
import { CheckboxStyle } from 'src/features/Leads/components/LeadView/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const TenderComment = styled.span`
  width: auto;
  max-width: 800px;

  margin-top: 10px;

  display: block;
  word-break: break-word;
`;

export const TenderLink = styled.a.attrs({
  target: '_blank'
})`
  color: ${blue};
`;

export const ApplicationViewStyled = styled.section`
  height: inherit;
  position: relative;
  overflow: hidden;
`;

export const LoaderContainerStyled = styled(LoaderContainer)`
  padding-top: 40vh;
  align-items: flex-start;
`;

export const ApplicationSubtitle = styled.h3`
  margin: 0 0 12px 0;
  font-family: ${rl};
  font-size: 1.4rem;
  a {
    margin-left: 5px;
  }
`;

export const RejectionToggle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  p {
    font-size: 18px !important;
  }
`;

export const CompanyHead = styled.div`
  margin: 30px 0 20px;

  & > p:first-child {
    font-size: 14px;
  }

  & > p:last-child {
    font-size: 12px;
  }
`;

export const TableWrapper = styled.div`
  margin-left: 25px;
`;

export const Period = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 170px auto;
`;

export const IsNecessarily = styled.div`
  color: ${({ isUploaded }) => (isUploaded ? '' : 'red')};
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
`;

export const IsUploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 35px;
  background-color: ${({ isUploaded }) => (isUploaded ? '#1CCA94' : '#FFA552')};
  border-radius: 5px;
  padding: 13px 24px;
  font-size: 16px;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;

    p {
      color: white;
      margin: 0;
    }
  }
`;

export const DownloadFile = styled.p`
  cursor: pointer;
  margin: 0;
  color: ${blue};
`;

export const AddCompanyBtn = styled(({}) => CrossCircleBtn)`
  margin-right: 10px;
  transform: rotate(45deg);
  transform-origin: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const PlatformContainer = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  column-gap: 15px;
  width: fit-content;
`;

export const TooltipBoxStyle = styled.div`
  display: none;
  position: absolute;
  bottom: 15px;
  left: 300px;
  z-index: 100;
  background-color: ${lightGray};
  color: black;
  padding: 10px;
  border-radius: 4px;
  white-space: nowrap;
`;

export const TDDocumentName = styled.td`
  position: relative;
  overflow: visible !important;

  div {
    font-family: ${rr};
  }

  div:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover ${TooltipBoxStyle} {
      display: block !important;
    }
  }

  & > div:first-child {
    font-size: ${({ text }) => text === 'Иной документ' && '12px'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NotDocumentList = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const EditApplicationBtn = styled.button.attrs({
  type: 'button'
})`
  width: auto;
  height: 50px;
  
  margin-left: auto;
  padding: 0 25px;

  outline: none;
  border: none;
  cursor: pointer;

  font-size: 1.4rem;
  font-family: ${rr};
  color: ${gray};

  background-repeat: no-repeat;
  background-position: 0 50%;
  background-color: transparent;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="${gray}" d="M2.5 14.375V17.5h3.125l9.215-9.215-3.125-3.125L2.5 14.375zm14.756-8.507a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.174 0L12.607 4.27l3.124 3.124 1.525-1.525z"/>
    </svg>
  ');

  &:disabled {
    opacity: 0.1;
  }

  &:not([disabled]):hover {
    color: ${navyBlue};
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="${navyBlue}" d="M2.5 14.375V17.5h3.125l9.215-9.215-3.125-3.125L2.5 14.375zm14.756-8.507a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.174 0L12.607 4.27l3.124 3.124 1.525-1.525z"/>
      </svg>
    ');
  }

`;

export const GoBackBtn = styled(ButtonStyled).attrs({
  template: 'backBtn'
})`
  width: 100px;
  font-size: 1.4rem;

  background-size: 20%;
  background-position: 0px 50%;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
      <path fill="${navyBlue}" d="M93.74,45.17H21.8l21.82-21.8a.93.93,0,0,0,.27-.63V12.53a.9.9,0,0,0-1.54-.63L5.27,49a.91.91,0,0,0-.27.63.9.9,0,0,0,.27.64L42.34,87.31a.91.91,0,0,0,1.55-.63V76.47a.85.85,0,0,0-.27-.63L21.82,54H93.74A1.25,1.25,0,0,0,95,52.78V46.43A1.25,1.25,0,0,0,93.74,45.17Z"/>
    </svg>
  ');

  &:active {
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
        <path fill="${gray}" d="M93.74,45.17H21.8l21.82-21.8a.93.93,0,0,0,.27-.63V12.53a.9.9,0,0,0-1.54-.63L5.27,49a.91.91,0,0,0-.27.63.9.9,0,0,0,.27.64L42.34,87.31a.91.91,0,0,0,1.55-.63V76.47a.85.85,0,0,0-.27-.63L21.82,54H93.74A1.25,1.25,0,0,0,95,52.78V46.43A1.25,1.25,0,0,0,93.74,45.17Z"/>
      </svg>
    ');
  }
`;

const ViewSeparator = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #e2e4e8;
`;

export const ViewSeparatorTop = styled(ViewSeparator)`
  margin-bottom: 40px;
`;

export const ViewSeparatorBottom = styled(ViewSeparator)`
  margin-bottom: 20px;
  justify-content: flex-end;
`;

export const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;

  button {
    width: fit-content;
  }
`;

export const SentToBankStyled = styled(ButtonStyled)`
  height: auto;
  font-size: 14px;
  background-color: #f69a0f;

  & > svg {
    margin-right: 5px;
  }
`;

export const ShowToAgentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  p {
    margin-left: 35px;
  }
`;

export const CheckboxStyleShowToAgent = styled(CheckboxStyle)`
  svg path {
    fill: black;
  }
`;

export const ProductApiContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin: 20px 0 30px 0;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;

    button {
      max-width: fit-content;
      :disabled {
        background-color: #0160b6;
      }
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;

export const Limit = styled.div`
  position: absolute;
  left: 50%;
  margin-top: -15px;

  p {
    margin: 0;
    :first-child {
      text-align: center;
      margin-bottom: 5px;
    }
  }
`;

export const UploadButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  column-gap: 10px;
  padding: 10px 15px;

  :disabled {
    background-color: ${gray};

    :hover {
      background-color: ${gray};
    }
  }

  p {
    font-size: 16px;
    color: white;
    margin: 0;
  }
`;

export const OverlayRejectModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    width: 400px;

    & > div:first-of-type {
      margin-bottom: 10px;
    }

    & > div:last-of-type {
      margin-bottom: 20px;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  z-index: 100;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const Rejection = styled.p`
  white-space: pre-wrap;
`;

export const OpenLoanLimitsContainer = styled.div`
  margin: 35px 0;
`;

export const TitleTabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${lighterBlue};
  border-radius: 5px;
  padding: 5px 20px;
  margin: 20px 0;
  height: 60px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }

  p {
    margin: 0;
    font-size: 24px;
    color: ${navyBlue};
  }

  svg {
    cursor: pointer;
  }
`;
