import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Factor/model/actions/getFactoringConnectionById';

export interface GetFactoringConnectionsByIdStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_FACTORING_CONNECTIONS_BY_ID {
  REQ = 'GET_FACTORING_CONNECTIONS_BY_ID_REQ',
  GOT = 'GET_FACTORING_CONNECTIONS_BY_ID_GOT',
  ERR = 'GET_FACTORING_CONNECTIONS_BY_ID_ERR'
}

export const initialState: GetFactoringConnectionsByIdStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getFactoringConnectionsById = (
  state: GetFactoringConnectionsByIdStoreState = initialState,
  action: {
    type: GET_FACTORING_CONNECTIONS_BY_ID;
    data: ResponseDataType;
    error: {};
  }
): GetFactoringConnectionsByIdStoreState => {
  switch (action.type) {
    case GET_FACTORING_CONNECTIONS_BY_ID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_CONNECTIONS_BY_ID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_CONNECTIONS_BY_ID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactoringConnectionsById;
