import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getStartRateDebtorRole';
import { START_RATE_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/getStartRateDebtorRole';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getStartRateDebtorRoleData(action: { type: START_RATE_DEBTOR_ROLE }) {
  try {
    const res: any = yield call(SCFDebtorApi.getStartRateDebtorRole);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getStartRateDebtorRoleDataSaga() {
  yield takeLatest(START_RATE_DEBTOR_ROLE.REQ, getStartRateDebtorRoleData);
}
