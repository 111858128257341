import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

interface Props {
  onClickOutside: () => void;
  children: any;
}

class ClickOutsideWrapper extends React.Component<Props> {
  myClickOutsideHandler = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onClickOutside();
  };

  render() {
    return this.props.children;
  }
}

const clickOutsideConfig = {
  handleClickOutside: instance => instance.myClickOutsideHandler,
  eventTypes: 'click'
};

const ClickOutside = onClickOutside(ClickOutsideWrapper, clickOutsideConfig);

export { ClickOutside as ClickOutsideWrapper };
