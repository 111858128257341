import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { APPLICATION_STATUSES } from 'Application/types';
import {
  rl,
  rr,
  blue,
  gray,
  lighterGray,
  black,
  navyBlue,
  lightGray
} from 'shared/styled';

export const SwitchBanksContainer = styled.section`
  width: 100%;
  min-height: 100px;
  height: auto;

  margin-bottom: 40px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const styleLink = status => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      return css`
        color: ${gray};
      `;
    case APPLICATION_STATUSES.REVIEW:
      return css`
        color: ${black};
      `;
    case APPLICATION_STATUSES.DOCS_REQUEST:
      return css`
        background-color: rgb(240, 240, 240);
        color: ${blue};
      `;
    case APPLICATION_STATUSES.APPROVED:
      return css`
        background-color: ${blue};
        color: #fff;
      `;
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
      return css`
        background-color: #1cca94;
        color: #fff;
      `;
    case APPLICATION_STATUSES.BANK_REJECT:
      return css`
        opacity: 0.2;
        background-color: #ffd0d0;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
      return css`
        opacity: 0.2;
        background-color: #e4e4e4;
        color: ${navyBlue};
      `;
    default:
      return css`
        color: ${gray};
      `;
  }
};

export const BankLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  margin: 5px 20px 10px 0;
  padding: 0 2px;

  span {
    margin-top: 5px;
    font-size: 12px;
    align-self: flex-start;
  }

  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  font-family: ${rr};
  font-size: 1.6rem;
  text-decoration: none;

  ${({ status }) => styleLink(status)};

  position: relative;

  &:hover {
    color: ${black};
  }

  &::after {
    position: absolute;
    content: '';

    width: 100%;
    height: 2px;

    left: 0;
    top: 43px;

    background-color: ${blue};

    transform: scaleX(0);
    transform-origin: 0 0;
  }

  &.active::after {
    transform: scaleX(1);
    transition: transform 0.4s;
  }
`;

export const LinksBlock = styled.div`
  height: 100%;
  padding: 20px 20px 10px 20px;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${lighterGray};
  border-radius: 4px;

  & > p {
    margin: 0;
    font-family: ${rl};
    color: ${gray};
  }
`;

export const ClientBlock = styled(LinksBlock)`
  height: 100px;
  margin-right: 10px;
  align-items: center;
  row-gap: 5px;
  justify-content: normal;

  ${BankLink} {
    margin: 10px 20px;
    color: ${black};
  }
`;

export const BanksList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const BanksBlock = styled(LinksBlock)`
  flex: 1;

  height: auto;
  min-height: 100px;
  max-height: 100px;

  transition: all 0.2s;

  p {
    cursor: pointer;
    margin-left: 15px;
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 0;

      display: block;

      top: 25%;
      left: -15px;
      position: absolute;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid ${gray};

      transition: transform 0.2s;
      transform-origin: center;
      transform: rotateZ(-90deg);
    }
  }

  ${({ open }) =>
    !!open &&
    css`
      max-height: 10000px;

      p::before {
        transform: rotateZ(0);
      }
    `};
`;

export const Tooltip = styled.span`
  visibility: hidden;
  background-color: #ffffee;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  white-space: pre;
  font-family: Roboto-Regular;
  color: #666;
  top: -20px;
  font-size: 1.2rem;
`;

export const ToggleBanksListIcon = styled.button.attrs({
  type: 'button',
  tabIndex: '-1',
  ['aria-hidden']: true
})`
  width: 20px;
  height: 20px;

  padding: 0;

  display: ${({ hidden }) => (hidden ? 'none' : 'block')};

  float: left;

  cursor: pointer;
  outline: none;

  &:hover {
    span {
      visibility: visible;
    }
  }

  margin: 16px 0 10px 0;

  transform: translateY(-50%);

  border: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

  background-image: ${({ isFocused }) => `url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="rgba(159,166,178,1)"  d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
        <path fill="rgba(159,166,178,1)"  d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>')`};
`;

export const ToggleBanksListIconSlash = styled.button.attrs({
  type: 'button',
  tabIndex: '-1',
  ['aria-hidden']: true
})`
  width: 20px;
  height: 20px;

  padding: 0;

  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  float: left;

  cursor: pointer;
  outline: none;

  &:hover {
    span {
      visibility: visible;
    }
  }

  margin: 16px 0 10px 0;

  transform: translateY(-50%);

  border: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

  background-image: ${({ isFocused }) => `url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="rgba(159,166,178,1)" d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
        <path fill="rgba(159,166,178,1)" d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
    </svg>')`};
`;
