import styled from 'styled-components';
import { rb } from 'shared/styled';

export const IncorrectAuthModalStyled = styled.div`
  min-width: 490px;
  min-height: 250px;

  padding: 0 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 20px 0 40px 0;

    p {
      margin: 0;
      opacity: 0.7;
      line-height: 1.71;
      text-align: center;
    }
  }
`;
