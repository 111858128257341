import { APPLICATION_STATUSES } from 'Application/types';
import { SUBMENU_CLICK_ACTIONS } from 'Application/components';

export const agentSubmenuConstructor = (status: APPLICATION_STATUSES) => {
  switch (status) {
    case APPLICATION_STATUSES.IN_WORK:
    case APPLICATION_STATUSES.DOCS_REQUEST:
      return [
        {
          name: 'Редактировать',
          action: SUBMENU_CLICK_ACTIONS.EDIT
        }
      ];

    case APPLICATION_STATUSES.AWAITING:
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
    case APPLICATION_STATUSES.REVIEW:
    case APPLICATION_STATUSES.APPROVED:
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.SUCCESS:
      return [];

    default:
      return [];
  }
};
