import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PutPurchaseContractSignatureDebtorRoleStoreState = Reducer;

export enum PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE {
  REQ = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE_REQ',
  GOT = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE_GOT',
  ERR = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE_ERR'
}

export interface RequestDataType {
  guid: string;
  signature: string;
  serial: string;
}

export const initialState: PutPurchaseContractSignatureDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putPurchaseContractSignatureDebtorRole = (
  state: PutPurchaseContractSignatureDebtorRoleStoreState = initialState,
  action: {
    type: PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE;
    error: {};
  }
): PutPurchaseContractSignatureDebtorRoleStoreState => {
  switch (action.type) {
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putPurchaseContractSignatureDebtorRole;
