import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'BorrowerCompanies/actions/saveBorrowerCompaniesList';

import {
  SAVE_BORROWER_COMPANIES_LIST,
  RequestDataType
} from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* saveBorrowerCompaniesList(action: {
  type: SAVE_BORROWER_COMPANIES_LIST;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(
      BorrowerCompaniesApi.saveBorrowerCompaniesList,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* saveBorrowerCompaniesListSaga() {
  yield takeLatest(SAVE_BORROWER_COMPANIES_LIST.REQ, saveBorrowerCompaniesList);
}
