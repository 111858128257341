import styled, { keyframes } from 'styled-components';
import { blue, rl, rr } from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';

export const ApplicationView = styled(ApplicationViewStyled)`
  height: auto;
`;

export const MainSection = styled.main`
  margin: 160px 90px 50px;
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 40%;
  left: 50%;
  transform: translate(-50%, 0);

  & > ${InnPopupStyled} {
    top: 50px;
  }
`;

export const CopyBlock = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};

  p{
    margin: 0;
  }

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 1;
    bottom: -7px;
    left: 112%;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  & > p:hover, h2:hover{
    color: ${blue};
  }
`;

export const HeadData = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const PersonsContainer = styled.div`
  margin-top: 50px;

  h2 {
    width: 50%;
    font-size: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 35px;
  }
`;

export const PersonName = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${blue};
    cursor: pointer;
    border-bottom: 1px solid ${blue};

    &:not(:last-child) {
      padding-right: 5px;
    }
  }
`;

export const NotFound = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 48px;
    font-family: ${rl};
  }
`;

export const PersonInfoContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    img {
      font-size: 10px;
      width: 70px;
      filter: invert(0.9);
    }
  }
`;

export const ImageIcon = styled.img`
  filter: none;
`;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  div:last-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  p {
    margin: 0;
  }
`;

export const PersonInfoDirector = styled(PersonInfo)`
  div:first-of-type {
    column-gap: 0;
  }
`;
