import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData as AgentsData } from 'src/features/Counterparties/actions/getAgents';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';

interface OwnProps {
  agents: AgentsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

type Props = RouteComponentProps & OwnProps;

const AgentsList: React.FC<Props> = ({ agents, status, error, history }) => {
  const navigateToAgent = (inn: string) => {
    if (inn) {
      history.push(`/crm/agents/${inn}`);
    }
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="2%">ИНН</TableThStyled>
                <TableThStyled width="10%">Наименование</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {agents.items.map((agent, index) => (
                <TableRowStyled
                  key={index}
                  onClick={() => navigateToAgent(agent.agentINN)}
                >
                  <td>{agent.agentINN}</td>
                  <td>{agent.agentName}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
          <Pagination list={agents} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const AgentsListConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AgentsList)
);

export { AgentsListConnect as AgentsList };
