import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt
} from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ResponseData as MonetaryClaimInternalAgent } from 'entities/SCF/Internal/model/actions/getMonetaryClaimsInternalAgent';
import { ResponseDataType as MonetaryClaimRes } from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import {
  req as getByMonetaryClaimHint,
  reset as resetByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimHint
} from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  DocumentHint,
  TDInvoiceDocumenContainer,
  SkeletonWrapper,
  InvoiceDocumentDate,
  ModalButtonSelected,
  SortContainer
} from './styled';
import { formSumStringThousands } from 'shared/utils/Utils';
import { navyBlue } from 'shared/styled';

interface StateToProps {
  monetaryClaimsHint?: ResponseMonetaryClaimHint[];
  statusMonetaryClaimsHint?: REQUEST_STATUSES;
}

interface DispatchToProps {
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
}

interface SelectedItems {
  id: string;
  baseFullSumm: number;
}

interface AnyProps {
  monetaryClaims: MonetaryClaimRes | MonetaryClaimInternalAgent;
  status: REQUEST_STATUSES;
  activeIds: SelectedItems[];
  toggleRecordState: (id: string, baseFullSumm: number) => void;
  isSummSort: boolean;
  handleSortedSumm: React.Dispatch<React.SetStateAction<boolean>>;
  isMaturityDate: boolean;
  handleSortedMaturityDate: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = StateToProps & DispatchToProps & AnyProps;

const ModalMonetaryClaims: React.FC<Props> = ({
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  monetaryClaims,
  status,
  activeIds,
  toggleRecordState,
  isSummSort,
  handleSortedSumm,
  isMaturityDate,
  handleSortedMaturityDate
}) => {
  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="3%" />
                <TableThStyled width="5%">ID</TableThStyled>
                <TableThStyled width="15%">Поставщик</TableThStyled>
                <TableThStyled width="6%">Договор</TableThStyled>
                <TableThStyled width="10%">
                  <SortContainer>
                    <div>Сумма к оплате</div>
                    <FontAwesomeIcon
                      icon={
                        isSummSort ? faSortAmountUpAlt : faSortAmountDownAlt
                      }
                      color={isSummSort !== null && navyBlue}
                      onClick={() => {
                        handleSortedMaturityDate(null);
                        handleSortedSumm(prevState => !prevState);
                      }}
                    />
                  </SortContainer>
                </TableThStyled>
                <TableThStyled width="6%">Дата счета</TableThStyled>
                <TableThStyled width="6%">
                  <SortContainer>
                    <div>Дата оплаты</div>
                    <FontAwesomeIcon
                      icon={
                        isMaturityDate ? faSortAmountUpAlt : faSortAmountDownAlt
                      }
                      color={isMaturityDate !== null && navyBlue}
                      onClick={() => {
                        handleSortedSumm(null);
                        handleSortedMaturityDate(prevState => !prevState);
                      }}
                    />
                  </SortContainer>
                </TableThStyled>
                <TableThStyled width="12%">Закрывающие документы</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {monetaryClaims.items.map(claim => (
                <TableRowStyled key={claim.guid}>
                  <td>
                    <ModalButtonSelected
                      isActive={activeIds.some(
                        selected => selected.id === claim.guid
                      )}
                      onClick={() =>
                        toggleRecordState(claim.guid, claim.baseFullSumm)
                      }
                    />
                  </td>
                  <td>{claim.guid.slice(-6)}</td>
                  <td>
                    <p>{claim.creditorInn}</p>
                    <p>{claim.creditorName}</p>
                  </td>
                  <td>{claim.supplyContractNumber}</td>
                  <td>{formSumStringThousands(claim.baseFullSumm)}</td>
                  <td>{new Date(claim.emergenceDate).toLocaleDateString()}</td>
                  <td>{new Date(claim.maturityDate).toLocaleDateString()}</td>
                  <TDInvoiceDocumenContainer>
                    <div>
                      <div>
                        {claim.invoiceDocumentName}{' '}
                        {claim.invoiceDocumentNumber}
                      </div>
                      <InvoiceDocumentDate>
                        <p>
                          {new Date(
                            claim.invoiceDocumentDate
                          ).toLocaleDateString()}
                        </p>
                        {claim.invoiceDocsQty &&
                          claim.invoiceDocsQty > 1 && (
                            <p
                              onMouseEnter={() =>
                                getByMonetaryClaimHint(claim.guid)
                              }
                              onMouseLeave={resetByMonetaryClaimHint}
                            >
                              и ещё {claim.invoiceDocsQty - 1}
                            </p>
                          )}
                      </InvoiceDocumentDate>
                    </div>
                    {!!monetaryClaimsHint &&
                      claim.invoiceDocsQty &&
                      claim.invoiceDocsQty > 1 && (
                        <DocumentHint>
                          {!!monetaryClaimsHint &&
                            monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                          <div>
                            <p>ИТОГО</p>{' '}
                            <p>{formSumStringThousands(claim.baseFullSumm)}</p>
                          </div>
                        </DocumentHint>
                      )}
                  </TDInvoiceDocumenContainer>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={monetaryClaims} />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint
    },
    dispatch
  );

const ModalMonetaryClaimsConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ModalMonetaryClaims);

export { ModalMonetaryClaimsConnect as ModalMonetaryClaims };
