import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import {
  req as getPurchaseContracts,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getPurchaseContracts';
import { req as getContractFile } from 'src/features/SCF/actions/getContractFile';
import { req as getSignatureFile } from 'entities/SCF/Supplier/model/actions/getSignatureFile';
import { req as getDebtorSignatureFile } from 'entities/SCF/Debtor/model/actions/getDebtorSignatureFile';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Icon } from 'shared/ui/Icon';
import SignatureImg from 'src/assets/icons/signature.svg';
import {
  DownloadContractContainer,
  NoData,
  Status,
  TDDocument,
  TableTRStyled,
  TooltipBoxStyle
} from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  contracts: ResponseDataType;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPurchaseContracts: (data: RequestDataType) => void;
  getContractFile: (guid: string) => void;
  getSignatureFile: (guid: string) => void;
  getDebtorSignatureFile: (guid: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Contracts: React.FC<Props> = ({
  getPurchaseContracts,
  contracts,
  status,
  getContractFile,
  getSignatureFile,
  getDebtorSignatureFile,
  history,
  match
}) => {
  React.useEffect(() => {
    if (match.params.id) {
      const urlParams = new URLSearchParams(history.location.search);
      const page = parseInt(urlParams.get('page'), 10);

      getPurchaseContracts({
        page,
        pageSize: 20,
        SuplierInnToFilterBy: match.params.id
      });
    }
  }, []);

  const onRawClick = (guid: string) => {
    history.push(`/debtor/purchase_contracts/${guid}`);
  };

  const navigateToPurchaseContract = () => {
    history.push('/debtor/purchase_contracts/add', { inn: match.params.id });
  };

  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="15%">Инн поставщика</TableThStyled>
                <TableThStyled width="30%">
                  Наименование поставщика
                </TableThStyled>
                <TableThStyled width="30%">Реквизиты договора</TableThStyled>
                <TableThStyled width="15%">Статус подписания</TableThStyled>
                <TableThStyled width="20%">Документы</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {contracts.items.map(contract => (
                <TableTRStyled
                  key={contract.id}
                  onClick={() => onRawClick(contract.id)}
                >
                  <td>{contract.supplierInn}</td>
                  <td>{contract.supplierName}</td>
                  {contract.contractNumber !== '' ||
                  contract.contractStartDate !== '' ? (
                    <td>
                      № {contract.contractNumber} от{' '}
                      {format(
                        new Date(contract.contractStartDate),
                        'dd.MM.yyyy'
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                  <td>
                    <Status
                      status={
                        contract.contraceFileName === null
                          ? 'Не загружен'
                          : !!contract.contraceFileName &&
                            contract.supplierSignatureFileName === null
                            ? 'Не подписан поставщиком'
                            : !!contract.contraceFileName &&
                              !!contract.supplierSignatureFileName &&
                              contract.debtorSignatureFileName === null
                              ? 'Ожидает вашего подписания'
                              : !!contract.contraceFileName &&
                                !!contract.supplierSignatureFileName &&
                                !!contract.debtorSignatureFileName &&
                                'Подписан'
                      }
                    >
                      {contract.contraceFileName === null
                        ? 'Не загружен'
                        : !!contract.contraceFileName &&
                          contract.supplierSignatureFileName === null
                          ? 'Не подписан поставщиком'
                          : !!contract.contraceFileName &&
                            !!contract.supplierSignatureFileName &&
                            contract.debtorSignatureFileName === null
                            ? 'Ожидает вашего подписания'
                            : !!contract.contraceFileName &&
                              !!contract.supplierSignatureFileName &&
                              !!contract.debtorSignatureFileName &&
                              'Подписан'}
                    </Status>
                  </td>
                  <TDDocument>
                    {contract.contraceFileName && (
                      <DownloadContractContainer>
                        <a
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            getContractFile(contract.id);
                          }}
                        >
                          Скачать контракт
                        </a>

                        {contract.supplierSignatureFileName && (
                          <TooltipBoxStyle textVerify="Скачать подпись поставщика">
                            <Icon
                              iconLink={SignatureImg}
                              width={20}
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                getSignatureFile(contract.id);
                              }}
                            />
                          </TooltipBoxStyle>
                        )}

                        {contract.debtorSignatureFileName && (
                          <TooltipBoxStyle textVerify="Скачать мою подпись">
                            <Icon
                              iconLink={SignatureImg}
                              width={20}
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                getDebtorSignatureFile(contract.id);
                              }}
                            />
                          </TooltipBoxStyle>
                        )}
                      </DownloadContractContainer>
                    )}
                  </TDDocument>
                </TableTRStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={contracts} />
        </>
      )}

      {contracts.items.length === 0 && (
        <NoData>
          <div>
            <h2>ДОГОВОРЫ ПОСТАВЩИКА НЕ НАЙДЕНЫ</h2>
            <p>
              дождитесь, пока поствщик создаст договор, или создайте его
              самостоятельно
            </p>
            <Button
              label="Создать договор с поставщиком"
              onClick={navigateToPurchaseContract}
            />
          </div>
        </NoData>
      )}
    </>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  contracts: SCF.getPurchaseContracts.data,
  status: SCF.getPurchaseContracts.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPurchaseContracts,
      getContractFile,
      getSignatureFile,
      getDebtorSignatureFile
    },
    dispatch
  );

const ContractsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Contracts)
);

export { ContractsConnect as Contracts };
