import * as React from 'react';
import { BankAccount as BankAccountType } from './../../../../../../../../types/bankAccount';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableRowStyled, TableThStyled, DeleteIcon, MainAccountIcon } from './styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import './styles/styles.css'
interface Props {
    list: BankAccountType[];
    onBankAccountClick: (index: number) => void;
}

export class BankAccountsTableView extends React.Component<Props> {

    render() {
        const { onBankAccountClick } = this.props;
        return (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                    <tr>
                        <TableThStyled width="30%">Номер счета</TableThStyled>
                        <TableThStyled width="15%">Валюта</TableThStyled>
                        <TableThStyled width="20%">Банк</TableThStyled>
                        <TableThStyled width="15%">Основной</TableThStyled>
                        <TableThStyled width="5%"></TableThStyled>

                    </tr>
                </TableHeaderStyled>
                <tbody>
                    {this.props.list.map((item: BankAccountType, key) =>
                    (<React.Fragment key={key}>
                        <TableRowStyled
                        className='table-row'
                            key={key}
                            >
                            <td>{item.accountNumber}</td>
                            <td>{item.currencyCode}</td>
                            <td>{item.bankName}</td>
                            <td>{item.isMain ? <MainAccountIcon /> : <p></p>}</td>
                            <td className='delete-btn-td'><DeleteIcon className='delete-btn' onClick={() => onBankAccountClick(key)}/></td>
                            <td className='lorem'>lorem</td>
                        </TableRowStyled>
                    </React.Fragment>
                    ))}
                </tbody>
            </TableStyled>
        )
    }
}
