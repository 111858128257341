import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GUARANTEES_TYPE } from 'Application/types';
import { STORE } from 'globaltypes';

import { TRANSITION_DIRECTIONS } from 'Common';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import { SetFileUploadBlockType } from 'src/features/Application/reducers/setFileUploadBlockType';
import {
  ResponseData as ResInternalApplication,
  reset as resetInternalApplication
} from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { reset as resetDocumentListByApplicationId } from 'BorrowerCompanies/actions/getDocumentListByApplicationId';
import { BorrowerCompanies as BorrowerCompaniesStep } from '../Borrower/BorrowerCompanies/BorrowerCompanies';
import {
  BorrowerCompanyDefaultFileUploadBlock,
  BorrowerCompanySlimFileUploadBlock
} from '../Borrower/BorrowerCompanyFileUploadBlock';

interface OwnProps {
  path: string;
}

interface StateToProps {
  inernalApplication: ResInternalApplication;
  allowFlags: GetApplicationFlagsResponseDataType;
  fileUploadBlockType: SetFileUploadBlockType;
}

interface DispatchToProps {
  resetInternalApplication: () => void;
  resetDocumentListByApplicationId: () => void;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const GuaranteesFormStep2: React.FC<Props> = ({
  inernalApplication,
  fileUploadBlockType,
  history,
  match,
  path
}) => {
  React.useEffect(() => {
    fileUploadBlockType.type = 'default';
  }, []);

  const navigateFromTo = (from: string, to: string) =>
    match.url.replace(from, to);

  const goBack = () => {
    history.push({
      pathname: navigateFromTo(path, 'options'),
      state: TRANSITION_DIRECTIONS.BACKWARDS
    });
  };

  const shouldBeSlim = () => {
    const { amount, guaranteesType } = inernalApplication;
    return (
      amount <= 125000000 &&
      (guaranteesType === GUARANTEES_TYPE.COMMERCIAL ||
        guaranteesType === GUARANTEES_TYPE['44-FZ'] ||
        guaranteesType === GUARANTEES_TYPE['223-FZ'] ||
        guaranteesType === GUARANTEES_TYPE['615-P'])
    );
  };

  return (
    <BorrowerCompaniesStep
      borrowerCompanyFileUploadBlock={
        shouldBeSlim() && fileUploadBlockType.type === 'default'
          ? BorrowerCompanySlimFileUploadBlock
          : BorrowerCompanyDefaultFileUploadBlock
      }
      disableApplicationDocumentsUpload={shouldBeSlim()}
      goBack={goBack}
    />
  );
};

const mapStateToProps = ({ Application, InternalAgentRole }: STORE) => ({
  inernalApplication: InternalAgentRole.getInternalApplication.data,
  allowFlags: Application.getApplicationFlags.data,
  fileUploadBlockType: Application.setFileUploadBlock
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { resetInternalApplication, resetDocumentListByApplicationId },
    dispatch
  );

const GuaranteesFormStep2Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(GuaranteesFormStep2)
);

export { GuaranteesFormStep2Connect as GuaranteesFormStep2 };
