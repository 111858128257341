import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getLeads';

import { GET_LEADS } from '../reducers/getLeads';
import ApplicationForCompaniesApi from '../api';

function* getLeads(action: { type: GET_LEADS; inn: string }): Generator {
  try {
    const res: any = yield call(
      ApplicationForCompaniesApi.getLeads,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadsSaga(): Generator {
  yield takeLatest(GET_LEADS.REQ, getLeads);
}
