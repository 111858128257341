import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getSupplyContractsList';

import { GET_SUPPLY_CONTRACTS_LIST } from '../reducers/getSupplyContractsList';
import SupplyApi from '../api';

function* getSupplyContractsList(action: {
  type: GET_SUPPLY_CONTRACTS_LIST;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(SupplyApi.getSupplyContractsList, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractsListSaga(): Generator {
  yield takeLatest(GET_SUPPLY_CONTRACTS_LIST.REQ, getSupplyContractsList);
}
