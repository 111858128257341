import { GET_SEARCH_PERSON } from 'src/features/Person/reducers/getSearchPerson';
import { FormData, Person } from '../types/SearchPerson';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Person[];
}

export const req = (data: FormData) => ({
  type: GET_SEARCH_PERSON.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_SEARCH_PERSON.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_SEARCH_PERSON.ERR,
  error
});
