import { GET_API_KEY } from '../reducers/getApiKey';

export const req = () => ({
  type: GET_API_KEY.REQ
});

export const got = (key: string) => ({
  type: GET_API_KEY.GOT,
  key
});

export const err = (error: {}) => ({
  type: GET_API_KEY.ERR,
  error
});
