import { BUSINESS_REGISTER } from 'Business/reducers/businessRegister';

export interface RequestDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const req = (data: RequestDataType, headers?: { apiKey: string }) => ({
  type: BUSINESS_REGISTER.REQ,
  data,
  headers
});

export const got = (data: RequestDataType) => ({
  type: BUSINESS_REGISTER.GOT,
  data
});

export const err = (error: {}) => ({
  type: BUSINESS_REGISTER.ERR,
  error
});

export const reset = () => ({
  type: BUSINESS_REGISTER.RESET
});
