import { GET_ALL_DOCUMENTS_FORMS } from 'entities/Cabinet/InternalAgent/model/reducers/getAllDocumentsForms';

export interface RequestData {
  type?: number;
  owner?: string;
  counterparty?: string;
}

export interface ResponseData {
  id: number;
  isDeleted: boolean;
  guid: string;
  ownerINN: string;
  name: string;
  documentFormFileIdentifier: string;
  documentFormFilename: string;
  exampleFileIdentifier: string;
  exampleFilename: string;
  isMain: boolean;
  counterpartyInn: string;
  formType: number;
  isOpen: boolean;
}

export const req = (data: RequestData) => ({
  type: GET_ALL_DOCUMENTS_FORMS.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_DOCUMENTS_FORMS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_DOCUMENTS_FORMS.ERR,
  error
});
