import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostEarlyDatePickStoreState extends Reducer {}

export enum PUT_EARLY_DATE_PICK {
  REQ = 'PUT_EARLY_DATE_PICK_REQ',
  GOT = 'PUT_EARLY_DATE_PICK_GOT',
  ERR = 'PUT_EARLY_DATE_PICK_ERR'
}

export interface RequestDataType {
  guid: string;
  body: { datePick: string };
}

export const initialState: PostEarlyDatePickStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putEarlyDatePick = (
  state: PostEarlyDatePickStoreState = initialState,
  action: {
    type: PUT_EARLY_DATE_PICK;
    data: RequestDataType;
    error: {};
  }
): PostEarlyDatePickStoreState => {
  switch (action.type) {
    case PUT_EARLY_DATE_PICK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_EARLY_DATE_PICK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_EARLY_DATE_PICK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putEarlyDatePick;
