import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import {
  CreateContractPageStyled,
  InnFilter,
  InnContainer,
  InnArea,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  InfoField,
  InnPopup
} from './styles';
import {
  FactoringContract,
  setFactoringContract
} from 'src/features/SCF/actions/setFactoringContract';
import {
  req as sendFactoringContract,
  RequestData as SendFactoringContract
} from 'src/features/SCF/actions/sendFactoringContract';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, STORE, ResponseError } from 'src/globaltypes';
import { SetFactoringContractStoreState } from 'src/features/SCF/reducers/setFactoringContract';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getSecondCompanyByInn,
  RequestDataType as RequestDataTypeSecondCompany,
  ResponseDataType as ResponseDataTypeSecondCompany
} from 'src/features/SCF/actions/getSecondCompanyByInnThirdParty';
import { Button } from 'shared/ui/Button';
import './styles/style.css';

interface StateToProps extends Partial<FactoringContract> {
  isSendingData: boolean;
  status: REQUEST_STATUSES;
  secondStatus: REQUEST_STATUSES;
  sendStatus: REQUEST_STATUSES;
  error: ResponseError;
  secondError: ResponseError;
  companyByInn: ResponseDataType;
  secondCompanyByInn: ResponseDataTypeSecondCompany;
}

interface DispatchToProps {
  sendFactoringContract: (data: SendFactoringContract) => void;
  setFactoringContract: (data: SetFactoringContractStoreState) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getSecondCompanyByInn: (data: RequestDataTypeSecondCompany) => void;
}

interface State {
  isPerpetualState: boolean;
  isRegressedState: boolean;
  isNotHiddenState: boolean;
  moveGracePeriodState: boolean;
  moveRegressPeriodState: boolean;
  supplierInn: string;
  debtorInn: string;
  companyByInnPre: ResponseDataType;
  companyByInnPreSecond: ResponseDataType;
}

type Props = StateToProps & DispatchToProps;

class ContractsCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        isPerpetualState: false,
        isRegressedState: undefined,
        isNotHiddenState: undefined,
        moveGracePeriodState: false,
        moveRegressPeriodState: false,
        supplierInn: '',
        debtorInn: '',
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        },
        companyByInnPreSecond: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      };
      this.handlePerpetualChange = this.handlePerpetualChange.bind(this);
      this.handleGracePeriodChange = this.handleGracePeriodChange.bind(this);
      this.handleRegressPeriodChange = this.handleRegressPeriodChange.bind(
        this
      );
      this.handleRegressChangeTrue = this.handleRegressChangeTrue.bind(this);
      this.handleRegressChangeFalse = this.handleRegressChangeFalse.bind(this);
      this.handleHiddenChangeTrue = this.handleHiddenChangeTrue.bind(this);
      this.handleHiddenChangeFalse = this.handleHiddenChangeFalse.bind(this);
      this.handleDebtorInnChange = this.handleDebtorInnChange.bind(this);
      this.handleSupplierInnChange = this.handleSupplierInnChange.bind(this);
    }
  }

  handleSupplierInnChange(event) {
    this.setState({ supplierInn: event.target.value });
  }

  handleDebtorInnChange(event) {
    this.setState({ debtorInn: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  supplierFetch = async () => {
    this.setState({
      companyByInnPre: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    });
    this.props.getCompanyByInn({ inn: this.state.supplierInn });
    await this.delay(1100);
    {
      this.props.error.code === 404 &&
      this.props.status === REQUEST_STATUSES.ERROR
        ? this.setState({
            companyByInnPre: {
              inn: '',
              TaxSystem: null,
              companyShortName: '',
              companyAddress: '',
              companyFullName: '',
              ogrn: '',
              regDate: null,
              kpp: '',
              pfr: '',
              fss: '',
              startCapital: null,
              okvedCode: '',
              okvedName: '',
              okvedPic: '',
              employees: null,
              employeesYear: null,
              taxSystemName: '',
              taxSystemCode: null,
              taxes: { taxYear: null, taxInfo: [] }
            }
          })
        : this.setState({
            companyByInnPre: {
              inn: this.props.companyByInn.inn,
              TaxSystem: this.props.companyByInn.TaxSystem,
              companyShortName: this.props.companyByInn.companyShortName,
              companyAddress: this.props.companyByInn.companyAddress,
              companyFullName: this.props.companyByInn.companyFullName,
              ogrn: this.props.companyByInn.ogrn,
              regDate: this.props.companyByInn.regDate,
              kpp: this.props.companyByInn.kpp,
              pfr: this.props.companyByInn.pfr,
              fss: this.props.companyByInn.fss,
              startCapital: this.props.companyByInn.startCapital,
              okvedCode: this.props.secondCompanyByInn.okvedCode,
              okvedName: this.props.secondCompanyByInn.okvedName,
              okvedPic: this.props.secondCompanyByInn.okvedPic,
              employees: this.props.secondCompanyByInn.employees,
              employeesYear: this.props.secondCompanyByInn.employeesYear,
              taxSystemName: this.props.secondCompanyByInn.taxSystemName,
              taxSystemCode: this.props.secondCompanyByInn.taxSystemCode,
              taxes: this.props.secondCompanyByInn.taxes
            }
          });
      document.getElementById('supplierPopup').style.display = 'flex';
    }
  };

  debtorFetch = async () => {
    this.setState({
      companyByInnPreSecond: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    });
    this.props.getSecondCompanyByInn({ inn: this.state.debtorInn });
    await this.delay(1100);
    {
      this.props.secondError.code === 404 &&
      this.props.secondStatus === REQUEST_STATUSES.ERROR
        ? this.setState({
            companyByInnPreSecond: {
              inn: '',
              TaxSystem: null,
              companyShortName: '',
              companyAddress: '',
              companyFullName: '',
              ogrn: '',
              regDate: null,
              kpp: '',
              pfr: '',
              fss: '',
              startCapital: null,
              okvedCode: '',
              okvedName: '',
              okvedPic: '',
              employees: null,
              employeesYear: null,
              taxSystemName: '',
              taxSystemCode: null,
              taxes: { taxYear: null, taxInfo: [] }
            }
          })
        : this.setState({
            companyByInnPreSecond: {
              inn: this.props.secondCompanyByInn.inn,
              TaxSystem: null,
              companyShortName: this.props.secondCompanyByInn.companyShortName,
              companyAddress: this.props.secondCompanyByInn.companyAddress,
              companyFullName: this.props.secondCompanyByInn.companyFullName,
              ogrn: this.props.secondCompanyByInn.ogrn,
              regDate: this.props.secondCompanyByInn.regDate,
              kpp: this.props.secondCompanyByInn.kpp,
              pfr: this.props.secondCompanyByInn.pfr,
              fss: this.props.secondCompanyByInn.fss,
              startCapital: this.props.secondCompanyByInn.startCapital,
              okvedCode: this.props.secondCompanyByInn.okvedCode,
              okvedName: this.props.secondCompanyByInn.okvedName,
              okvedPic: this.props.secondCompanyByInn.okvedPic,
              employees: this.props.secondCompanyByInn.employees,
              employeesYear: this.props.secondCompanyByInn.employeesYear,
              taxSystemName: this.props.secondCompanyByInn.taxSystemName,
              taxSystemCode: this.props.secondCompanyByInn.taxSystemCode,
              taxes: this.props.secondCompanyByInn.taxes
            }
          });
      document.getElementById('debtorPopup').style.display = 'flex';
    }
  };

  handlePerpetualChange(event) {
    this.setState({ isPerpetualState: !this.state.isPerpetualState });
    this.props.isPerpetual == this.state.isPerpetualState;
  }

  handleGracePeriodChange(event) {
    this.setState({ moveGracePeriodState: !this.state.moveGracePeriodState });
    this.props.moveGracePeriodToBusinessDay == this.state.moveGracePeriodState;
  }

  handleRegressPeriodChange(event) {
    this.setState({
      moveRegressPeriodState: !this.state.moveRegressPeriodState
    });
    this.props.moveRegressPeriodToBusinessDay ==
      this.state.moveRegressPeriodState;
  }

  handleRegressChangeTrue(event) {
    this.setState({ isRegressedState: true });
    this.props.isRegressed == this.state.isRegressedState;
  }

  handleRegressChangeFalse(event) {
    this.setState({ isRegressedState: false });
    this.props.isRegressed == this.state.isRegressedState;
  }

  handleHiddenChangeTrue(event) {
    this.setState({ isNotHiddenState: true });
  }

  handleHiddenChangeFalse(event) {
    this.setState({ isNotHiddenState: false });
  }

  onSubmit = async () => {
    const {
      id,
      supplierName,
      debtorName,
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      cessionMinimalShare,
      cessionMaximalShare,
      financeMinimalShare,
      financeMaximalShare,
      gracePeriod,
      regressPeriod,
      contraceFileIdentifyer,
      contraceFileName,
      supplierSignatureIdentifier,
      supplierSignatureFileName,
      debtorSignatureIdentifier,
      debtorSignatureFileName,
      factorSignatureIdentifier,
      factorSignatureFileName
    } = this.props;
    this.props.sendFactoringContract({
      id,
      supplierInn: this.state.supplierInn,
      supplierName,
      debtorInn: this.state.debtorInn,
      debtorName,
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      isPerpetual: this.state.isPerpetualState,
      cessionMinimalShare,
      cessionMaximalShare,
      financeMinimalShare,
      financeMaximalShare,
      isRegressed: this.state.isRegressedState,
      isNotHidden: this.state.isNotHiddenState,
      gracePeriod,
      moveGracePeriodToBusinessDay: this.state.moveGracePeriodState,
      regressPeriod,
      moveRegressPeriodToBusinessDay: this.state.moveRegressPeriodState,
      contraceFileIdentifyer,
      contraceFileName,
      supplierSignatureIdentifier,
      supplierSignatureFileName,
      debtorSignatureIdentifier,
      debtorSignatureFileName,
      factorSignatureIdentifier,
      factorSignatureFileName
    });
    await this.delay(700);
    history.replace('/factor/contracts?page=1');
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setFactoringContract({
      [name]: value
    });
  };

  onPerpetualFalseClick() {
    this.props.isPerpetual === !this.props.isPerpetual;
  }
  onPerpetualTrueClick() {
    this.props.isPerpetual == true;
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      (this.state.debtorInn !== prevState.debtorInn &&
        this.state.debtorInn.length === 10) ||
      this.state.debtorInn === null
    ) {
      this.debtorFetch();
    } else if (
      (this.state.supplierInn !== prevState.supplierInn &&
        this.state.supplierInn.length == 10) ||
      this.state.supplierInn === null
    ) {
      this.supplierFetch();
    } else if (
      this.state.debtorInn !== prevState.debtorInn &&
      this.state.debtorInn.length < 10
    ) {
      document.getElementById('debtorInfo').style.display = 'none';
      this.setState({
        companyByInnPreSecond: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      });
      document.getElementById('debtorPopup').style.display = 'none';
    } else if (
      this.state.supplierInn !== prevState.supplierInn &&
      this.state.supplierInn.length < 10
    ) {
      document.getElementById('supplierInfo').style.display = 'none';
      this.setState({
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      });
      document.getElementById('supplierPopup').style.display = 'none';
    }
  }

  onSupplierPopupClick = e => {
    document.getElementById('supplierPopup').style.display = 'none';
    document.getElementById('supplierInfo').style.display = 'flex';
  };
  onDebtorPopupClick = e => {
    document.getElementById('debtorPopup').style.display = 'none';
    document.getElementById('debtorInfo').style.display = 'flex';
  };

  render() {
    const {
      contractName,
      contractNumber,
      contractStartDate,
      contractEndDate,
      cessionMinimalShare,
      cessionMaximalShare,
      financeMinimalShare,
      financeMaximalShare,
      gracePeriod,
      regressPeriod,
      companyByInn,
      secondCompanyByInn
    } = this.props;
    return (
      <CreateContractPageStyled>
        <h2>+ Новый договор</h2>
        <InnArea>
          <InnFilter>
            <p>Поставщик:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleSupplierInnChange}
                className="input-inn"
              />
            </InnContainer>
            {this.state.companyByInnPre.inn === '' ? (
              <InnPopup
                id="supplierPopup"
                style={{ display: 'none', marginTop: '10px' }}
              >
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать договор с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              <InnPopup
                className="inn-popup"
                id="supplierPopup"
                onClick={e => this.onSupplierPopupClick(e)}
                style={{ display: 'none' }}
              >
                <h2>{this.state.companyByInnPre.companyFullName}</h2>
                {this.state.companyByInnPre.companyAddress.length > 3 ? (
                  <p>{this.state.companyByInnPre.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {this.state.companyByInnPre.inn}</p>
                <p>КПП {this.state.companyByInnPre.kpp}</p>
              </InnPopup>
            )}

            <InfoField id="supplierInfo">
              <h2>{companyByInn.companyFullName}</h2>
              {companyByInn.companyAddress.length > 3 ? (
                <p>{companyByInn.companyAddress}</p>
              ) : (
                ''
              )}
              <p>ИНН {companyByInn.inn}</p>
              <p>КПП {companyByInn.kpp}</p>
            </InfoField>
          </InnFilter>
          <InnFilter>
            <p>Дебитор:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnChange}
                className="input-inn"
              />
            </InnContainer>
            {this.state.companyByInnPreSecond.inn === '' ? (
              <InnPopup
                id="debtorPopup"
                style={{ display: 'none', marginTop: '10px' }}
              >
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать договор с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              <InnPopup
                className="inn-popup"
                id="debtorPopup"
                onClick={e => this.onDebtorPopupClick(e)}
                style={{ display: 'none' }}
              >
                <h2>{this.state.companyByInnPreSecond.companyFullName}</h2>
                {this.state.companyByInnPreSecond.companyAddress.length > 3 ? (
                  <p>{this.state.companyByInnPreSecond.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {this.state.companyByInnPreSecond.inn}</p>
                <p>КПП {this.state.companyByInnPreSecond.kpp}</p>
              </InnPopup>
            )}

            <InfoField id="debtorInfo">
              <h2>{secondCompanyByInn.companyFullName}</h2>
              {secondCompanyByInn.companyAddress.length > 3 ? (
                <p>{secondCompanyByInn.companyAddress}</p>
              ) : (
                ''
              )}
              <p>ИНН {secondCompanyByInn.inn}</p>
              <p>КПП {secondCompanyByInn.kpp}</p>
            </InfoField>
          </InnFilter>
        </InnArea>

        <ContractsInfoArea>
          <h1>Реквизиты договора:</h1>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Наименование договора
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-input-name"
              type="text"
              value={contractName}
              name="contractName"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Номер договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="text"
              value={contractNumber}
              name="contractNumber"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Дата договора</ContractsInfoItemTitle>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Срок действия договора
            </ContractsInfoItemTitle>
            <p>c</p>
            <input
              className="contract-input"
              type="date"
              value={contractStartDate}
              name="contractStartDate"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '20px' }}>по</p>
            <input
              className="contract-input"
              type="date"
              value={contractEndDate}
              name="contractEndDate"
              onChange={this.onChange}
            />
            {this.state.isPerpetualState == true ? (
              <SelectContainerActive
                value={this.state.isPerpetualState}
                name="isPerpetual"
                onClick={e => this.handlePerpetualChange(e)}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                value={this.state.isPerpetualState}
                name="isPerpetual"
                onClick={e => this.handlePerpetualChange(e)}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>бессрочный</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>
        <ContractsInfoArea>
          <h1>Тип факторинга:</h1>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Без регресса</ContractsInfoItemTitle>
            {this.state.isRegressedState == undefined ? (
              <TypeContainer>
                <TypeNoInactive onClick={this.handleRegressChangeTrue}>
                  <p>нет</p>
                </TypeNoInactive>
                <TypeYesInactive onClick={this.handleRegressChangeFalse}>
                  <p>да</p>
                </TypeYesInactive>
              </TypeContainer>
            ) : this.state.isRegressedState == false ? (
              <TypeContainer>
                <TypeNoInactive onClick={this.handleRegressChangeTrue}>
                  <p>нет</p>
                </TypeNoInactive>
                <TypeYesActive onClick={this.handleRegressChangeFalse}>
                  <p>да</p>
                </TypeYesActive>
              </TypeContainer>
            ) : (
              <TypeContainer>
                <TypeNoActive onClick={this.handleRegressChangeTrue}>
                  <p>нет</p>
                </TypeNoActive>
                <TypeYesInactive onClick={this.handleRegressChangeFalse}>
                  <p>да</p>
                </TypeYesInactive>
              </TypeContainer>
            )}
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Открытый</ContractsInfoItemTitle>
            {this.state.isNotHiddenState == undefined ? (
              <TypeContainer>
                <TypeNoInactive onClick={this.handleHiddenChangeFalse}>
                  <p>нет</p>
                </TypeNoInactive>
                <TypeYesInactive onClick={this.handleHiddenChangeTrue}>
                  <p>да</p>
                </TypeYesInactive>
              </TypeContainer>
            ) : this.state.isNotHiddenState == true ? (
              <TypeContainer>
                <TypeNoInactive onClick={this.handleHiddenChangeFalse}>
                  <p>нет</p>
                </TypeNoInactive>
                <TypeYesActive onClick={this.handleHiddenChangeTrue}>
                  <p>да</p>
                </TypeYesActive>
              </TypeContainer>
            ) : (
              <TypeContainer>
                <TypeNoActive onClick={this.handleHiddenChangeFalse}>
                  <p>нет</p>
                </TypeNoActive>
                <TypeYesInactive onClick={this.handleHiddenChangeTrue}>
                  <p>да</p>
                </TypeYesInactive>
              </TypeContainer>
            )}
          </ContractsInfoItemContainer>
        </ContractsInfoArea>
        <ContractsInfoArea>
          <h1>Параметры финансирования:</h1>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Минимальная доля уступки
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={cessionMinimalShare}
              name="cessionMinimalShare"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>%</p>
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Максимальная доля уступки
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={cessionMaximalShare}
              name="cessionMaximalShare"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>%</p>
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Минимальная доля финансирования
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={financeMinimalShare}
              name="financeMinimalShare"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>%</p>
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>
              Максимальная доля финансирования
            </ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={financeMaximalShare}
              name="financeMaximalShare"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>%</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>
        <ContractsInfoArea>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Льготный период:</ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={gracePeriod}
              name="gracePeriod"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>дней</p>
            {this.state.moveGracePeriodState == true ? (
              <SelectContainerActive
                value={this.state.moveGracePeriodState}
                name="moveGracePeriodToBusinessDay"
                onClick={e => this.handleGracePeriodChange(e)}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                value={this.state.moveGracePeriodState}
                name="moveGracePeriodToBusinessDay"
                onClick={e => this.handleGracePeriodChange(e)}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>переносить на рабочий день</p>
          </ContractsInfoItemContainer>
          <ContractsInfoItemContainer>
            <ContractsInfoItemTitle>Период регресса:</ContractsInfoItemTitle>
            <input
              className="contract-input contract-percent"
              type="number"
              min={0}
              step={1}
              onKeyPress={e => {
                if (
                  e.key === 'e' ||
                  e.key === '-' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                }
              }}
              value={regressPeriod}
              name="regressPeriod"
              onChange={this.onChange}
            />
            <p style={{ marginLeft: '10px' }}>дней</p>
            {this.state.moveRegressPeriodState == true ? (
              <SelectContainerActive
                value={this.state.moveRegressPeriodState}
                name="moveRegressPeriodToBusinessDay"
                onClick={e => this.handleRegressPeriodChange(e)}
              >
                <SelectActive />
              </SelectContainerActive>
            ) : (
              <SelectContainerInactive
                value={this.state.moveRegressPeriodState}
                name="moveRegressPeriodToBusinessDay"
                onClick={e => this.handleRegressPeriodChange(e)}
              >
                <SelectInactive />
              </SelectContainerInactive>
            )}
            <p>переносить на рабочий день</p>
          </ContractsInfoItemContainer>
        </ContractsInfoArea>
        {this.state.supplierInn.length < 10 ||
        this.state.debtorInn.length < 10 ||
        contractName.length < 1 ||
        contractNumber.length < 1 ||
        contractStartDate.length < 1 ||
        cessionMinimalShare == null ||
        cessionMaximalShare == null ||
        financeMinimalShare == null ||
        financeMaximalShare == null ||
        gracePeriod == null ||
        this.state.isRegressedState == undefined ||
        this.state.isNotHiddenState == undefined ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : this.state.isPerpetualState == false && contractEndDate ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : this.state.isRegressedState == true && regressPeriod == null ? (
          <Button
            type="submit"
            disabled={true}
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        ) : (
          <Button
            type="submit"
            label="Создать"
            onClick={this.onSubmit}
            w="fit-content"
          />
        )}
      </CreateContractPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  secondCompanyByInn: SCF.getSecondCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  secondStatus: SCF.getSecondCompanyByInnThirdParty.status,
  sendStatus: SCFSupplier.postFactoringConnectionSupplierRole.status,
  error: SCF.getCompanyByInnThirdParty.error,
  secondError: SCF.getSecondCompanyByInnThirdParty.error,
  isSendingData: SCF.sendFactoringContract.status === REQUEST_STATUSES.REQUEST,
  ...SCF.setFactoringContract
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      getSecondCompanyByInn,
      sendFactoringContract,
      setFactoringContract
    },
    dispatch
  );

const ContractsCreateConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContractsCreate);

export { ContractsCreateConnect as ContractsCreate };
