import ApiRequest from 'src/shared/utils/ApiRequest';

import { RequestDataType as ResetPasswordRequestDataType } from 'User/actions/resetPassword';
import { RequestDataType as ConfirmAccountRequestDataType } from 'User/actions/confirmAccount';
import { RequestDataType as SignInRequestDataType } from 'User/actions/signIn';

export default class UserApi {
  static signIn(data: SignInRequestDataType) {
    return ApiRequest('/api/account/login', 'post', data);
  }
  static signInByLink(key: string) {
    return ApiRequest(`/api/account/login-link/${key}`, 'post');
  }
  static getUser() {
    return ApiRequest('/api/account/profile');
  }
  static confirmAccount(data: ConfirmAccountRequestDataType) {
    return ApiRequest('/api/account/confirm', 'post', data);
  }
  static reConfirmAccount(userId: string) {
    return ApiRequest(`/api/account/confirm/${userId}`);
  }
  static sendResetPasswordLetter(email: string) {
    return ApiRequest('/api/account/send_reset_list', 'post', { email });
  }
  static resetPassword(data: ResetPasswordRequestDataType) {
    return ApiRequest('/api/account/reset_password', 'post', data);
  }
}
