import {
  GET_TENDER_BY_APPLICATION_EXTERNAL,
  ResponseData
} from 'entities/Cabinet/ExternalAgent/model/reducers/getTenderByApplicationExternal';

export const req = (id: string) => ({
  type: GET_TENDER_BY_APPLICATION_EXTERNAL.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_TENDER_BY_APPLICATION_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_TENDER_BY_APPLICATION_EXTERNAL.ERR,
  error
});
