import * as React from 'react';
import { USER_PERMISSIONS } from 'src/globaltypes';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { CabinetComponent } from '../EachPage';
import { createApplicationsListCabinetComponent } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

export const BankManagerSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Банк</SectionSeparatorStyled>
    ),
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions)
  },

  {
    hasPermission: permissions =>
      ![USER_PERMISSIONS.BANK_ADMIN].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/bank/general-applications',
      type: APPLICATIONS_LIST_TYPE.GENERAL,
      link: {
        icon: 'desk',
        label: 'Новые заявки на распределение',
        countName: 'general'
      }
    })
  },

  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_MANAGER].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/bank/work-applications',
      type: APPLICATIONS_LIST_TYPE.WORK,
      link: {
        icon: 'bag',
        label: 'Мои заявки',
        countName: 'work'
      }
    })
  }
];
