import {
  INITIALIZATION_FORM_ACTIONS,
  SetInitializationApplicationType
} from 'Application/reducers/setInitializationFormData';

export const setInitializationFormData = (
  data: SetInitializationApplicationType
) => ({
  type: INITIALIZATION_FORM_ACTIONS.SET,
  data
});

export const resetInitializationFormData = () => ({
  type: INITIALIZATION_FORM_ACTIONS.RESET
});
