import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCooperation';

import { PATCH_COOPERATION_ACTIONS } from '../reducers/patchCooperation';
import CooperationApi from '../api';

function* patchCooperation(action: {
  type: PATCH_COOPERATION_ACTIONS;
  id: string;
  data: any;
}): Generator {
  try {
    yield call(CooperationApi.patchCooperation, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCooperationSaga(): Generator {
  yield takeLatest(PATCH_COOPERATION_ACTIONS.REQ, patchCooperation);
}
