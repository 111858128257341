import { combineReducers } from 'redux';
import setCompanyFormData, {
  SetCompanyDataStoreState
} from './setCompanyFormData';
import getCompany, { GetCompanyStoreState } from './getCompany';
import getCompaniesList, {
  GetCompaniesListStoreState
} from './getCompaniesList';
import getCompaniesByHoldingList, {
  GetCompaniesByHoldingListStoreState
} from './getCompaniesByHoldingList';
import getMainCounter, { GetMainCounterStoreState } from './getMainCounter';
import getListInternalAgents, {
  GetListInternalAgentsStoreState
} from './getListInternalAgents';

export interface CompaniesReducers {
  setCompanyFormData: SetCompanyDataStoreState;
  getCompany: GetCompanyStoreState;
  getCompaniesList: GetCompaniesListStoreState;
  getCompaniesByHoldingList: GetCompaniesByHoldingListStoreState;
  getMainCounter: GetMainCounterStoreState;
  getListInternalAgents: GetListInternalAgentsStoreState;
}

export const Companies = combineReducers({
  setCompanyFormData,
  getCompany,
  getCompaniesList,
  getCompaniesByHoldingList,
  getMainCounter,
  getListInternalAgents
});
