import styled from 'styled-components';
import { gray, rr } from 'shared/styled';

export const ActionButtonsBlockStyled = styled.div`
  margin: 20px 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;

  button {
    padding: 10px;
    font-family: ${rr};
    font-size: 1.4rem;
    justify-content: center;

    &:first-child {
      color: ${gray};
    }
  }
`;
