import styled, { css } from 'styled-components';
import { rr, red, navyBlue, gray } from 'shared/styled';

export const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  font-family: ${rr};
  font-size: 12px;
  white-space: pre;
  color: ${navyBlue};

  &::after {
    margin-bottom: -15px;
    margin-left: 3px;

    font-size: 14px;
    color: ${red};
    transform: translateY(-50%);
  }
  & > span > small {
    display: block;
    color: ${gray};
  }

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
      }
    `};
`;
