import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsSupplierRole';
import { GET_MONETARY_CLAIMS_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsSupplierRole';
import SCFApi from 'entities/SCF/Supplier/api';

function* getMonetaryClaimsSupplierRole(action: {
  type: GET_MONETARY_CLAIMS_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getMonetaryClaimsSupplierRole, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimsSupplierRoleSaga() {
  yield takeLatest(
    GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_REQ,
    getMonetaryClaimsSupplierRole
  );
}
