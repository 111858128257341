import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  req as getCheckSignature,
  reset as resetCheckSignature,
  RequestData as ReqCheckSignature,
  ResponseData as ResCheckSignature
} from 'entities/Cabinet/InternalAgent/model/actions/getCheckSignature';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import {
  BlockSpace,
  ButtonContainer,
  InputContainer,
  Result,
  SignatureInfo,
  Title
} from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'src/features/Layouts/components';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  signatureInfo: ResCheckSignature;
  statusFiles: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCheckSignature: (data: ReqCheckSignature) => void;
  resetCheckSignature: () => void;
}

type Props = StateToProps & DispatchToProps;

const CheckSignature: React.FC<Props> = ({
  getCheckSignature,
  resetCheckSignature,
  signatureInfo,
  statusFiles
}) => {
  const [isSending, setIsSending] = React.useState(false);
  const [files, setFiles] = React.useState({
    documentFile: null,
    signatureFile: null
  });

  React.useEffect(
    () => {
      setIsSending(false);
    },
    [statusFiles]
  );

  React.useEffect(() => {
    return () => resetCheckSignature();
  }, []);

  const handleDownload = () => {
    window.open(signatureInfo.pdfLink, '_blank');
  };

  const handleCheckSignature = () => {
    getCheckSignature(files);
    setIsSending(true);
  };

  return (
    <ApplicationViewStyled>
      <Title>Проверить электронную подпись</Title>

      <BlockSpace>
        <InputContainer>
          <p>Выберите файл документа:</p>
          <InputSingleFile
            file={files.documentFile}
            onFileUpload={file =>
              setFiles(prevState => ({ ...prevState, documentFile: file }))
            }
            onFileRemove={() =>
              setFiles(prevState => ({ ...prevState, documentFile: null }))
            }
          />
        </InputContainer>
      </BlockSpace>

      <BlockSpace>
        <InputContainer>
          <p>Выберите файл подписи:</p>
          <InputSingleFile
            file={files.signatureFile}
            onFileUpload={file =>
              setFiles(prevState => ({ ...prevState, signatureFile: file }))
            }
            onFileRemove={() =>
              setFiles(prevState => ({ ...prevState, signatureFile: null }))
            }
          />
        </InputContainer>
      </BlockSpace>

      <ButtonContainer>
        <Button
          w="fit-content"
          label="Проверить"
          disabled={
            statusFiles === REQUEST_STATUSES.REQUEST ||
            (!files.documentFile || !files.signatureFile)
          }
          onClick={handleCheckSignature}
        />
        {statusFiles === REQUEST_STATUSES.REQUEST && <Spinner size={40} />}
      </ButtonContainer>

      {statusFiles === REQUEST_STATUSES.GOT && !isSending ? (
        <Result result={signatureInfo.result}>
          <p>Результат проверки:</p>
          {signatureInfo.result ? (
            <>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} />
                <p>
                  Подпись соответствует файлу.{' '}
                  <span onClick={handleDownload}>Скачать протокол</span>
                </p>
              </div>

              <SignatureInfo>
                {signatureInfo.companyName && (
                  <p>{signatureInfo.companyName}</p>
                )}
                {signatureInfo.inn && (
                  <div>
                    <p>ИНН</p>
                    <p>{signatureInfo.inn}</p>
                  </div>
                )}
                {signatureInfo.lastName &&
                  signatureInfo.name && (
                    <div>
                      <p>ФИО</p>
                      <p>{`${signatureInfo.lastName} ${signatureInfo.name}`}</p>
                    </div>
                  )}
                {signatureInfo.position && (
                  <div>
                    <p>Должность</p>
                    <p>{signatureInfo.position}</p>
                  </div>
                )}
                {signatureInfo.personInn && (
                  <div>
                    <p>ИНН ФЛ</p>
                    <p>{signatureInfo.personInn}</p>
                  </div>
                )}
              </SignatureInfo>
            </>
          ) : (
            <div>
              <FontAwesomeIcon icon={faTimesCircle} />
              <p>Подпись не соответствует файлу</p>
            </div>
          )}
        </Result>
      ) : (
        statusFiles === REQUEST_STATUSES.ERROR &&
        !isSending && (
          <Result result={false}>
            <p>Результат проверки:</p>
            <div>
              <FontAwesomeIcon icon={faTimesCircle} />
              <p>Файл подписи не соответствует файлу документа</p>
            </div>
          </Result>
        )
      )}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  signatureInfo: InternalAgentRole.getCheckSignature.data,
  statusFiles: InternalAgentRole.getCheckSignature.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCheckSignature, resetCheckSignature }, dispatch);

const CheckSignatureConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckSignature);

export { CheckSignatureConnect as CheckSignature };
