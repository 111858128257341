export enum AGENT_LIST_TYPE {
  ALL = 'ALL'
}

export enum AGENTS_TABS {
  DEFAULT = 'Заявки агентские',
  CONTACTS = 'Контакты',
  USERS = 'Пользователи',
  ACTIVITIES = 'Активности',
  QUESTIONNAIRE = 'Опросник',
  CLIENTS = 'Клиенты'
}

export enum AGENT_TYPE {
  ISBROKER = 'Брокер',
  ISCONSULTANT = 'Проф. консультант',
  ISAPPRAISER = 'Оценщик',
  ISBANK = 'Банк',
  ISENGINEERINGEXPERT = 'Инжиниринговая компания',
  ISINSURER = 'Страховая компания',
  ISASSOCIATION = 'Ассоциация',
  NOT_CHECK = 'Не фильтровать'
}

export enum HAS_CONTACT {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть контакты',
  FALSE = 'Нет контактов'
}

export enum HAS_USERS {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть контакты',
  FALSE = 'Нет контактов'
}

export enum HAS_CONTRACT {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть договор',
  FALSE = 'Нет договора'
}

export interface AgentFilter {
  page: number;
  pageSize: number;
  agentInn?: string;
  hasUsers?: boolean;
  hasContacts?: boolean;
  hasContract?: boolean;
  sortBy?:
    | 'lastLoginAsc'
    | 'lastLoginDesc'
    | 'lastActivityAsc'
    | 'lastActivityDesc'
    | 'lastOurActivityAsc'
    | 'lastOurActivityDesc';
}
