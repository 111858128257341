import { call, put, takeLatest } from 'redux-saga/effects';
import { err } from '../actions/patchDetail';

import { PATCH_DETAIL } from '../reducers/patchDetail';
import ContactDetailApi from '../api';

function* patchContactDetail(action: {
  type: PATCH_DETAIL;
  id: number;
  data: any;
}): Generator {
  try {
    yield call(ContactDetailApi.patchDetail, action.id, action.data);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContactsSaga(): Generator {
  yield takeLatest(PATCH_DETAIL.REQ, patchContactDetail);
}
