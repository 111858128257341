import ApiRequest from 'shared/utils/ApiRequest';

import { RequestData as ReqMonetaryClaimsInternalAgent } from 'entities/SCF/Internal/model/actions/getMonetaryClaimsInternalAgent';
import { RequestData as ReqDebtorRegistries } from 'entities/SCF/Internal/model/actions/getDebtorRegistries';

export default class SCFInternalApi {
  static getMonetaryClaimsAll(data: ReqMonetaryClaimsInternalAgent) {
    return ApiRequest(`/api/monetary_claim/internal_role/all`, 'get', data);
  }

  static getDebtorRegistries(data: ReqDebtorRegistries) {
    return ApiRequest(`/api/debtor_registries/all`, 'get', data);
  }

  static getMonetaryClaimOne(guid: string) {
    return ApiRequest(
      `/api/monetary_claim/internal_role/early_payment_application/${guid}`
    );
  }

  static getEarlyPaymentFile(
    guid: string,
    filetype:
      | 'agreement'
      | 'archive'
      | 'supplier_signature'
      | 'supplier_protocol'
      | 'debtor_signature'
      | 'debtor_protocol'
      | 'agreement_pdf'
  ) {
    return `/api/early_payment_applications/internal_role/${guid}/get_file?filetype=${filetype}`;
  }
}
