import styled from 'styled-components';
import {
  ProductColumnContainer,
  Square,
  Text,
  ProductItem
} from 'src/features/BasicParametersForProducts/components/styled';
import { blue, gray, rr } from 'shared/styled';

export const ProductMinimalCompanyAgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 35px 0;
`;

export const ProductMinimalCompanyAge = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  & > input {
    width: 100px;
  }
`;

export const RegionContainer = styled.div`
  margin-top: 80px;
  color: gray;

  svg {
    font-size: 1.25em;
  }

  ul {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      column-gap: 15px;
      font-size: 14px;
      font-family: ${rr};
      :not(:last-child) {
        margin-bottom: 15px;
      }

      p {
        margin: 0;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;

    svg:last-child {
      cursor: pointer;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      color: ${blue};
      font-size: 1.05em;
      font-family: ${rr};
      font-style: italic;
    }

    p {
      margin: 0;
    }

    :hover > div > p {
      color: ${blue};
    }
  }

  & > div > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const SubjectPolicyContainer = styled(ProductColumnContainer)`
  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Клиент должен быть субъектом МСП' &&
        product !== 3 &&
        active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Клиент должен быть субъектом МСП' &&
        product !== 3 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - для любых клиентов' && product !== 2 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - для любых клиентов' && product !== 2 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Клиент не должен быть субъектом МСП' &&
        product !== 1 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Клиент не должен быть субъектом МСП' &&
        product !== 1 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '3 - Клиент должен быть субъектом МСП' || product === 3) &&
      'white'};
  }
`;
