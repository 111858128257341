import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'src/features/Layouts/components';
import { CabinetComponent } from '../EachPage';
import { PreapprovedLimits } from 'src/features/PreapprovedLimits/components/PreapprovedLimits';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

interface Props {
  path: string;
  listType: any;
  icon: any;
  label: string;
  countName: string;
  Component: any;
}

function createProductAndLimitsCabinetComponent({
  path,
  listType,
  icon,
  label,
  Component
}: Props) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path
        }}
        label={label}
      />
    )
  };
}

export const ProductsAndLimitsSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>
        Продукты и лимиты
      </SectionSeparatorStyled>
    ),
    hasPermission: permissions => permissions.includes(USER_PERMISSIONS.CLIENT)
  },
  {
    hasPermission: permissions => [USER_PERMISSIONS.CLIENT].isIn(permissions),
    ...createProductAndLimitsCabinetComponent({
      Component: PreapprovedLimits,
      countName: null,
      path: '/cabinet/preapproved_limits',
      label: 'Предодобренные предложения',
      icon: <FontAwesomeIcon icon={faCheckSquare} fixedWidth={true} />,
      listType: null
    })
  }
];
