import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutAcceptByDebtorStoreState extends Reducer {}

export enum PUT_ACCEPT_BY_DEBTOR {
  REQ = 'PUT_ACCEPT_BY_DEBTOR_REQ',
  GOT = 'PUT_ACCEPT_BY_DEBTOR_GOT',
  ERR = 'PUT_ACCEPT_BY_DEBTOR_ERR'
}

export const initialState: PutAcceptByDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putAcceptByDebtor = (
  state: PutAcceptByDebtorStoreState = initialState,
  action: {
    type: PUT_ACCEPT_BY_DEBTOR;
    error: {};
  }
): PutAcceptByDebtorStoreState => {
  switch (action.type) {
    case PUT_ACCEPT_BY_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_ACCEPT_BY_DEBTOR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_ACCEPT_BY_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putAcceptByDebtor;
