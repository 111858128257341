import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getPOAS';
import { GET_POAS } from '../reducers/getPOAS';
import SCFApi from '../api';

function* getPOAS(action: { type: GET_POAS; inn: string }) {
  try {
    const res: any = yield call(SCFApi.getPOAS, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPOASSaga() {
  yield takeLatest(GET_POAS.REQ, getPOAS);
}
