import { DEBTOR_NOTIFICATION_SETTINGS } from 'entities/SCF/Debtor/model/reducers/getDebtorNotificationSettings';

export interface ResponseDataType {
  guid: string;
  newBuyerToSupplierLinkEvent: boolean;
  newSupplyContractLinkEvent: boolean;
  newEarlyPaymentApplicationEvent: boolean;
  newApprovedEpaEvent: boolean;
  newSignedEpaEvent: boolean;
  getSignedEpaDocuments: boolean;
}

export const req = () => ({ type: DEBTOR_NOTIFICATION_SETTINGS.REQ });
export const got = (data: ResponseDataType) => ({
  type: DEBTOR_NOTIFICATION_SETTINGS.GOT,
  data
});
export const err = (error: {}) => ({
  type: DEBTOR_NOTIFICATION_SETTINGS.ERR,
  error
});
