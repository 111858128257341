import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  ApplicationForm,
  ApplicationFormStyled,
  ApplicationInputsColumn,
  ApplicationStepTitle
} from '../../styles';
import { APPLICATION_PROCESS_MODES } from 'src/features/Application/reducers/setApplicationProcessMode';
import { InputRange, INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Select } from 'shared/ui/Select';
import { ApplicationTypes } from 'shared/constants';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { SetInitializationApplicationType } from 'src/features/Application/reducers/setInitializationFormData';
import { req as updateInitializationFormData } from 'Application/actions/updateInitializationFormData';
import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';
import { SetPropertyFinancingFormData } from 'src/features/Application/reducers/setPropertyFinancingFormData';
import {
  resetPropertyFinancingFormData,
  setPropertyFinancingFormData
} from 'src/features/Application/actions/setPropertyFinancingFormData';
import { createValidaton } from './validator';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import {
  APPLICATION_STATUSES,
  APPLICATION_TYPES
} from 'src/features/Application/types';
import { Document } from 'src/features/Application/types';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import { SagaRequestDataType as DeleteDocumentSagaRequestDataType } from 'Application/reducers/deleteDocument';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import { ClientData } from './ClientData/ClientData';
import { EditProperties } from 'src/features/Properties/components/EditProperties/EditProperties';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import { history } from 'src/shared/utils/History';
import { resetInitializationFormData } from 'Application/actions/setInitializationFormData';
import { resetLocalProperties } from 'Properties/actions/localProperties';
import { Loader } from 'src/features/Layouts/components';
import { Button } from 'shared/ui/Button';

interface StateToProps extends SetPropertyFinancingFormData {
  applicationProcessMode: APPLICATION_PROCESS_MODES;
  applicationId: number;
  applicationDocuments: Document[];
  documentsUploadStatus: REQUEST_STATUSES;
  sendApplicationDataStatus: REQUEST_STATUSES;
  financingType: string;
}

// TODO refactor
interface DispatchToProps {
  updateInitializationFormData: (
    data: SetInitializationApplicationType
  ) => void;
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  setPropertyFinancingFormData: (data: SetPropertyFinancingFormData) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
  resetPropertyFinancingFormData: () => void;
  resetInitializationFormData: () => void;
  resetLocalProperties: () => void;
}

type Props = StateToProps & DispatchToProps;

class PropertyFinancingFormStep1 extends React.Component<Props> {
  state = {
    isEditingClientData: this.props.client ? false : true,
    isEditingProperties: false
  };
  Validator = createValidaton();

  componentDidMount() {
    this.props.updateInitializationFormData({
      id: this.props.applicationId,
      financingType: APPLICATION_TYPES.PROPERTY_FINANCING,
      status: APPLICATION_STATUSES.EMPTY // TODO refactor
    });
  }

  onSubmit = () => {
    const {
      inn,
      amount,
      clientType,
      clientIncomeConfirmationType,
      clientAverageMonthlyIncome,
      client,
      term
    } = this.props;

    this.props.sendApplicationDataReq({
      id: this.props.applicationId,
      propertyFinancing: {
        inn,
        amount,
        clientType,
        clientIncomeConfirmationType,
        clientAverageMonthlyIncome,
        client,
        term
      }
    });
  };
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.sendApplicationDataStatus !==
        prevProps.sendApplicationDataStatus &&
      this.props.sendApplicationDataStatus === REQUEST_STATUSES.GOT
    ) {
      const segments = location.pathname.split('/');
      const roleIndex = segments.indexOf('cabinet') + 1;
      const role = segments[roleIndex];

      history.push({
        pathname: `/cabinet/${role}/application/${this.props.applicationId}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    }
  }

  isSubmitButtonDisabled() {
    return (
      !this.Validator.isFormValid() ||
      this.state.isEditingClientData ||
      this.state.isEditingProperties
    );
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber = name === 'amount' || name === 'term';
    const isCheckbox = type === 'checkbox';
    const floated = toFloatFormatter(value);
    this.props.setPropertyFinancingFormData({
      [name]: isCheckbox ? checked : isToNumber ? floated : value
    });
  };

  render(): JSX.Element {
    const {
      applicationProcessMode,
      applicationId,
      sendApplicationDataStatus,
      amount,
      term,
      financingType
    } = this.props;

    const amountRange = {
      min: 0,
      max: 100000000000
    };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max]
    }).validate(this.props);

    return (
      <ApplicationFormStyled>
        {applicationProcessMode === APPLICATION_PROCESS_MODES.CREATE && (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        {applicationProcessMode === APPLICATION_PROCESS_MODES.EDIT && (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        )}
        {sendApplicationDataStatus === REQUEST_STATUSES.REQUEST ? (
          <Loader />
        ) : (
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="initialize-application-form"
            >
              <Select
                options={Object.keys(ApplicationTypes).map(type => ({
                  id: type,
                  name: ApplicationTypes[type]
                }))}
                value={financingType}
                label="Тип продукта"
                name="financingType"
                placeholder="Выберите тип продукта"
                required={true}
                disabled={true}
                error={errors.financingType}
                onChange={this.onChange}
              />

              <InputRange
                value={amount.toFixed(2)}
                label="Сумма кредита"
                name="amount"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                disableSlider={true}
                required={true}
                error={errors.amount}
                onChange={this.onChange}
              />

              <InputRange
                value={term.toString()}
                name="term"
                placeholder="Введите срок"
                min={1}
                max={10 * 12}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                error={errors.term}
                onChange={this.onChange}
              />
              <ClientData
                isEditing={this.state.isEditingClientData}
                setIsEditing={(isEditingClientData: boolean) =>
                  this.setState({ isEditingClientData })
                }
              />

              <EditProperties
                isEditing={this.state.isEditingProperties}
                setIsEditing={(isEditingProperties: boolean) =>
                  this.setState({ isEditingProperties })
                }
                applicationId={applicationId}
              />

              <Button
                type="submit"
                label="Создать заявку"
                disabled={this.isSubmitButtonDisabled()}
                template="nextBtn"
              />
            </ApplicationForm>
          </ApplicationInputsColumn>
        )}
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  applicationProcessMode: Application.setApplicationProcessMode.mode,
  applicationId: Application.setInitializationFormData.id,
  financingType: Application.setInitializationFormData.financingType,

  applicationDocuments:
    Application.setPropertyFinancingFormData.propertyFinancingDocuments,
  documentsUploadStatus: Application.uploadDocuments.status,
  sendApplicationDataStatus: Application.sendApplicationData.status,
  ...Application.setPropertyFinancingFormData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationDataReq,
      setPropertyFinancingFormData,
      resetPropertyFinancingFormData,
      updateInitializationFormData,
      resetInitializationFormData,
      resetLocalProperties,
      uploadDocuments,
      deleteDocument
    },
    dispatch
  );

const PropertyFinancingFormStep1Connect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(PropertyFinancingFormStep1);

export { PropertyFinancingFormStep1Connect as PropertyFinancingFormStep1 };
