import * as React from 'react';
import { Route, Switch } from 'react-router';
import { DiscountingInfoPage } from './DiscountingInfo/DiscountingInfo'
import { FactoringInfoPage } from './FactoringInfo/FactoringInfo';
import { DiscountingEdit } from './DiscountingEdit/DiscountingEdit'
import { FactoringEdit } from './FactoringEdit/FactoringEdit';
import {
  DiscountingPageStyled,
} from './styles';

class DiscountingPage extends React.Component {
  render() {
    return (
      <DiscountingPageStyled>
        <Switch>
          <Route
            exact={true}
            path={"/debtor/settings/discounting"}
            render={() => <DiscountingInfoPage />}
          />
          <Route
            exact={true}
            path={"/debtor/settings/discounting/edit"}
            render={() => <DiscountingEdit />}
          />
          <Route
            exact={true}
            path={"/debtor/settings/factoring"}
            render={() => <FactoringInfoPage />}
          />
          <Route
            exact={true}
            path={"/debtor/settings/factoring/edit"}
            render={() => <FactoringEdit />}
          />
        </Switch>
      </DiscountingPageStyled>
    );
  }
}

export default DiscountingPage;
