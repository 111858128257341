import Validator from 'instant-validation';
import { ValidatorMessages } from 'shared/constants';
import { passwordRules } from 'src/shared/utils/ValidationPatterns';
import { equalValuesRule } from 'src/shared/utils/ValidationRules';
import { ResetPasswordFields } from 'User/components';

export const createValidation = () =>
  new Validator<ResetPasswordFields>({
    password: passwordRules,
    repeatPassword: [
      {
        rule: (passwordRepeat, passwordOriginal) =>
          passwordRepeat === passwordOriginal,
        message: ValidatorMessages.passwordsEquality,
        ruleId: 'passwordEqual'
      }
    ]
  });
