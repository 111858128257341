import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {
  req as getFactoringLimits,
  reset as resetFactoringLimits,
  ResponseData
} from 'entities/Cabinet/InternalAgent/model/actions/getFactoringLimits';
import {
  req as getFactoringLimitByDebtor,
  reset as resetFactoringLimitByDebtor,
  RequestDataType as ReqFactoringLimitByDebtorInn,
  ResponseDataType as ResFactoringLimitByDebtorInn
} from 'entities/SCF/Debtor/model/actions/getFactoringLimitByDebtorInn';
import { LighterBlueContainer } from 'shared/styled/layouts';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';

import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { SuccessIcon } from 'shared/styled';
import { InnPlaceholders } from 'shared/constants';
import { createValidation } from './validator';

import { Loader } from 'src/features/Layouts/components';

import { FactoringCheckerSubmitButton, FactorList } from './styles';

interface StateToProps {
  factoringLimits: ResponseData;
  factoringLimitsByDebtor: ResFactoringLimitByDebtorInn;
  statusFactoringLimits: REQUEST_STATUSES;
}

interface DispatchToProps {
  getFactoringLimitByDebtor: (data: ReqFactoringLimitByDebtorInn) => void;
  resetFactoringLimitByDebtor: () => void;
  getFactoringLimits: (inn: string) => void;
  resetFactoringLimits: () => void;
}

type Props = StateToProps & DispatchToProps;

const FactoringChecker: React.FC<Props> = ({
  getFactoringLimits,
  resetFactoringLimits,
  factoringLimits,
  statusFactoringLimits,
  getFactoringLimitByDebtor,
  resetFactoringLimitByDebtor,
  factoringLimitsByDebtor
}) => {
  const [debtorINN, setDebtorINN] = React.useState('');

  React.useEffect(() => {
    return () => {
      resetFactoringLimits();
      resetFactoringLimitByDebtor();
    };
  }, []);

  const Validator = createValidation();

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setDebtorINN(value);
  };

  const onSubmit = () => {
    if (!Validator.isFormValid()) return;

    getFactoringLimitByDebtor({ page: 1, debtorInn: debtorINN });
  };

  const { errors } = Validator.validate({ debtorINN });

  return (
    <>
      <LighterBlueContainer>
        {statusFactoringLimits === REQUEST_STATUSES.REQUEST && <Loader />}

        <Form onSubmit={onSubmit}>
          <p>
            Проверьте дебитора (контрагента клиента) на возможность факторинга:
          </p>
          <Input
            value={debtorINN}
            label="ИНН"
            placeholder={InnPlaceholders.entity}
            name="debtorINN"
            required={true}
            error={errors.debtorINN}
            onChange={onChange}
          />

          <FactoringCheckerSubmitButton
            type="submit"
            disabled={!Validator.isFormValid()}
            onClick={() => getFactoringLimits(debtorINN)}
            label="Проверить"
          />
        </Form>

        {factoringLimits.hasLimit === true ? (
          <>
            <Row align="center" justify="center">
              <Col lg={4}>
                <SuccessIcon size={96} />
              </Col>
              <Col lg={8}>
                <p>На дебитора можно установить лимит</p>
                <p>
                  Для дальнейшего рассмотрения сделки продолжите заведение
                  заявки
                </p>
              </Col>
            </Row>

            <FactorList>
              <p>Доступные лимиты:</p>

              <div>
                {factoringLimitsByDebtor.items.map(factor => (
                  <div>{factor.factorShortName}</div>
                ))}
              </div>
            </FactorList>
          </>
        ) : (
          factoringLimits.hasLimit === false && (
            <Row align="center" justify="center">
              <Col lg={4}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  color="orange"
                  size="8x"
                />
              </Col>
              <Col lg={8}>
                <p>
                  Лимиты по факторингу для данного дебитора отсутствуют либо
                  исчерпаны
                </p>
                <p>Попробуйте проверить другого дебитора</p>
              </Col>
            </Row>
          )
        )}
      </LighterBlueContainer>
    </>
  );
};

const mapStateToProps = ({ SCFDebtor, InternalAgentRole }: STORE) => ({
  factoringLimitsByDebtor: SCFDebtor.getFactoringLimitByDebtorInn.data,
  factoringLimits: InternalAgentRole.getFactoringLimits.data,
  statusFactoringLimits: InternalAgentRole.getFactoringLimits.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringLimits,
      resetFactoringLimitByDebtor,
      getFactoringLimitByDebtor,
      resetFactoringLimits
    },
    dispatch
  );

const FactoringCheckerConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringChecker);

export { FactoringCheckerConnect as FactoringChecker };
