import { combineReducers } from 'redux';
import getLimits, { GetLimitsStoreState } from './getLimits';

export interface PreapprovedLimitsReducers {
  getLimits: GetLimitsStoreState;
}

export const PreapprovedLimits = combineReducers({
  getLimits
});
