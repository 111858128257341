import { STATUS_FILTER_ACTIONS } from '../reducers/updateStatusFilter';

export const addStatusFilter = (status: string) => ({
  type: STATUS_FILTER_ACTIONS.SET,
  status
});

export const removeStatusFilter = (indexToRemove: number) => ({
  type: STATUS_FILTER_ACTIONS.REMOVE,
  index: indexToRemove
});
