import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getCounterparties';

export interface GetCounterpartiesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_COUNTERPARTIES {
  REQ = 'GET_COUNTERPARTIES_REQ',
  GOT = 'GET_COUNTERPARTIES_GOT',
  ERR = 'GET_COUNTERPARTIES_ERR'
}

export const initialState: GetCounterpartiesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getCounterparties = (
  state: GetCounterpartiesStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetCounterpartiesStoreState => {
  switch (action.type) {
    case GET_COUNTERPARTIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COUNTERPARTIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COUNTERPARTIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCounterparties;
