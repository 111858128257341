import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { ScrollTopComponent } from 'src/features/Common';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';

import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import { SubsidiariesTypes } from '../types';
import { NegativeValue, NoData } from './styled';

interface Props {
  subsidiaries: SubsidiariesTypes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export const Subsidiaries: React.FC<Props> = ({
  subsidiaries,
  status,
  error
}) => {
  const navigateToCompany = (companyInn: string) => {
    window.open(`/crm/companies/${companyInn}/beneficiaries`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <div>
        <CompanySectionTitle>Дочерние компании:</CompanySectionTitle>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT &&
          (subsidiaries.length ? (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="10%">Инн</TableThStyled>
                  <TableThStyled width="15%">Наименование</TableThStyled>
                  <TableThStyled width="10%">
                    Доля владения, ЕГРЮЛ
                  </TableThStyled>
                  <TableThStyled width="15%">Капитал</TableThStyled>
                  <TableThStyled width="10%">
                    Доля юридическая полная
                  </TableThStyled>
                  <TableThStyled width="10%">Год отчетности</TableThStyled>
                  <TableThStyled width="15%">Выручка, тыс.</TableThStyled>
                  <TableThStyled width="15%">Прибыль, тыс.</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {subsidiaries.map((subsidaries, key) => (
                  <TableRowStyled
                    key={key}
                    onClick={() => navigateToCompany(subsidaries.companyInn)}
                  >
                    <td>{subsidaries.companyInn}</td>
                    <td>{subsidaries.companyShortName}</td>
                    <td>{subsidaries.ownershipShareDeJure} %</td>
                    <td>{formSumStringThousands(subsidaries.capitalShare)}</td>
                    <td>
                      {subsidaries.ownershipShareDeJureFull === null
                        ? 'Неизв.'
                        : `${subsidaries.ownershipShareDeJureFull} %`}
                    </td>
                    <td>
                      {subsidaries.financialsYear === null
                        ? 'Неизв.'
                        : subsidaries.financialsYear}
                    </td>
                    <td>
                      {subsidaries.revenue === null ? (
                        'Неизв.'
                      ) : (
                        <NegativeValue isNegative={subsidaries.revenue < 0}>
                          {formSumStringThousands(subsidaries.revenue)}
                        </NegativeValue>
                      )}
                    </td>
                    <td>
                      {subsidaries.netIncome === null ? (
                        'Неизв.'
                      ) : (
                        <NegativeValue isNegative={subsidaries.netIncome < 0}>
                          {formSumStringThousands(subsidaries.netIncome)}
                        </NegativeValue>
                      )}
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>попробуйте обновить данные из ЕГРЮЛ</p>
            </NoData>
          ))}
      </div>

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};
