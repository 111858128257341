import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import {
  req as getBuyersToSupplierLinkList,
  ResponseData,
  RequestData
} from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';
import {
  req as patchSetDiscountMargin,
  RequestData as ReqSetDiscountMargin
} from 'entities/Cabinet/InternalAgent/model/actions/patchSetDiscountMargin';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import {
  INNContainer,
  INNField,
  TDStyled,
  TooltipBoxStyle,
  Status,
  StatusContainer,
  SupplierInfo,
  DebtorInfo,
  DiscountMargin,
  LinkNotSet
} from './styled';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { green, red } from 'shared/styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  buyersToSupplierLinkList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusSetDiscountMargin: REQUEST_STATUSES;
}

interface DispatchToProps {
  getBuyersToSupplierLinkList: (data: RequestData) => void;
  patchSetDiscountMargin: (id: number, data: ReqSetDiscountMargin) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BuyersToSupplierLink: React.FC<Props> = ({
  getBuyersToSupplierLinkList,
  buyersToSupplierLinkList,
  status,
  error,
  patchSetDiscountMargin,
  statusSetDiscountMargin,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');

  const [discountMargin, setDiscountMargin] = React.useState('');
  const [selectDiscount, setSelectDiscount] = React.useState({});

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';

      setDebtorInn(debtorInn);
      setSupplierInn(supplierInn);

      getBuyersToSupplierLinkList({
        page,
        pageSize: 20,
        ...(debtorInn && { DebtorInnToFilterBy: debtorInn }),
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn })
      });
    },
    [history.location.search, statusSetDiscountMargin]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Связки Дебитор - Поставщик</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="6%">ID</TableThStyled>
                <TableThStyled width="15%">Поставщик</TableThStyled>
                <TableThStyled width="15%">Дебитор</TableThStyled>
                <TableThStyled width="15%" style={{ textAlign: 'center' }}>
                  Статус связки
                </TableThStyled>
                <TableThStyled width="30%" style={{ paddingLeft: '20px' }}>
                  Доп.маржа дисконтирования
                </TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {buyersToSupplierLinkList.items.map((buyer, key) => (
                <TableRowStyled key={buyer.id}>
                  <TDStyled>{buyer.id}</TDStyled>
                  <td>
                    <SupplierInfo>
                      {buyer.supplierInn}
                      <TooltipBoxStyle textVerify={'Открыть в новом окне'}>
                        <FontAwesomeIcon
                          icon={faExternalLinkSquareAlt}
                          onClick={() =>
                            history.push(
                              `/internal/suppliers/${buyer.debtorInn}`
                            )
                          }
                        />
                      </TooltipBoxStyle>
                    </SupplierInfo>
                    <div>{buyer.supplierName}</div>
                  </td>
                  <td>
                    <DebtorInfo>
                      {buyer.debtorInn}
                      <TooltipBoxStyle textVerify={'Открыть в новом окне'}>
                        <FontAwesomeIcon
                          icon={faExternalLinkSquareAlt}
                          onClick={() =>
                            history.push(`/internal/debtors/${buyer.debtorInn}`)
                          }
                        />
                      </TooltipBoxStyle>
                    </DebtorInfo>
                    <div>{buyer.debtorName}</div>
                  </td>
                  <td>
                    <StatusContainer>
                      {buyer.confirmedBySupplier === true ? (
                        <Status status={green}>Подтверждена поставщиком</Status>
                      ) : (buyer.confirmedBySupplier === null &&
                        buyer.rejectedBySupplier === true) ||
                      buyer.confirmedBySupplier === false ? (
                        <Status status={'rgba(253, 241, 224, 1)'}>
                          Отклонена поставщиком
                        </Status>
                      ) : (
                        buyer.confirmedBySupplier === null &&
                        buyer.rejectedBySupplier === null && (
                          <Status status={red}>
                            Еще не подтверждена поставщиком
                          </Status>
                        )
                      )}

                      {buyer.confirmedByDebtor === true ? (
                        <Status status={green}>Подтверждена дебитором</Status>
                      ) : (buyer.confirmedByDebtor === null &&
                        buyer.rejectedByDebtor === true) ||
                      buyer.confirmedByDebtor === false ? (
                        <Status status={'rgba(253, 241, 224, 1)'}>
                          Отклонена дебитором
                        </Status>
                      ) : (
                        buyer.confirmedByDebtor === null &&
                        buyer.rejectedByDebtor === null && (
                          <Status status={red}>
                            Еще не подтверждена дебитором
                          </Status>
                        )
                      )}
                    </StatusContainer>
                  </td>
                  <td>
                    {!!buyer.discountMargin ? (
                      <DiscountMargin>
                        + {buyer.discountMargin} % годовых
                      </DiscountMargin>
                    ) : selectDiscount[key] && selectDiscount[key].selected ? (
                      <DiscountMargin
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => e.stopPropagation()}
                      >
                        <Input
                          name="discountMargin"
                          value={discountMargin}
                          onChange={e => {
                            const newValue = e.currentTarget.value;
                            if (/^\d*[.,]?\d{0,4}$/.test(newValue)) {
                              setDiscountMargin(newValue.replace(',', '.'));
                            }
                          }}
                        />
                        <Button
                          label="Сохранить"
                          disabled={!discountMargin}
                          onClick={() =>
                            patchSetDiscountMargin(buyer.id, {
                              margin: +discountMargin
                            })
                          }
                          w="fit-content"
                          h="40px"
                        />
                        <div onClick={() => setSelectDiscount({})}>&times;</div>
                      </DiscountMargin>
                    ) : (
                      <LinkNotSet
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          setDiscountMargin('');
                          setSelectDiscount({ [key]: { selected: true } });
                        }}
                      >
                        не установлена
                      </LinkNotSet>
                    )}
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={buyersToSupplierLinkList} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({
  BuyersToSupplierLink,
  InternalAgentRole
}: CRM & STORE) => ({
  buyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.data,
  status: BuyersToSupplierLink.getBuyersToSupplierLinkList.status,
  error: BuyersToSupplierLink.getBuyersToSupplierLinkList.error,
  statusSetDiscountMargin: InternalAgentRole.patchSetDiscountMargin.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getBuyersToSupplierLinkList, patchSetDiscountMargin },
    dispatch
  );

const BuyersToSupplierLinkConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BuyersToSupplierLink)
);

export { BuyersToSupplierLinkConnect as BuyersToSupplierLink };
