import styled from 'styled-components';
import { blue, lighterBlue, rl } from 'shared/styled';

export const Container = styled.div`
  width: 100%;
`;

export const INNContainer = styled.div`
  margin: 40px 0;

  & > div {
    display: flex;
    column-gap: 40px;
  }

  & > div:first-child {
    margin-bottom: 20px;
  }
`;

export const INNField = styled.div`
  width: 450px;
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-left: 10px;

    & > p {
      margin: 8px 0;
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }

  button {
    width: fit-content;
    height: 40px;
  }
`;
