import styled from 'styled-components';
import { blue, rt } from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const MainConainer = styled.div`
  padding: 160px 90px 50px;
`;

export const InputContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 70px;

  & > ${InnPopupStyled} {
    top: 50px;
  }
`;

export const HeadContainer = styled.div`
  width: 250px;
  margin-bottom: 30px;

  h2 {
    font-family: ${rt};
    line-height: 1.4em;
  }

  p {
    font-size: 16px;
    color: ${blue};
    cursor: pointer;
  }
`;
