import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'ExternalAgent/actions/externalAgentRegister';
import { EXTERNAL_AGENT_REGISTER } from 'ExternalAgent/reducers/externalAgentRegister';
import ExternalAgentApi from 'ExternalAgent/api';

function* externalAgentRegister(action: {
  type: EXTERNAL_AGENT_REGISTER;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(ExternalAgentApi.register, action.data);
    yield put(got(action.data));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* externalAgentRegisterSaga() {
  yield takeLatest(
    EXTERNAL_AGENT_REGISTER.EXTERNAL_AGENT_REGISTER_REQ,
    externalAgentRegister
  );
}
