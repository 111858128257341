import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/getContractBySupplier';
import { GET_CONTRACT_BY_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/getContractBySupplier';

import SCFApi from 'entities/SCF/Supplier/api';

function* getContractBySupplier(action: {
  type: GET_CONTRACT_BY_SUPPLIER;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFApi.getContractBySupplier, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContractBySupplierSaga() {
  yield takeLatest(GET_CONTRACT_BY_SUPPLIER.REQ, getContractBySupplier);
}
