import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { req as getPersonOfCompanies } from 'src/features/Person/actions/getPersonOfCompanies';
import { req as getBeneficiariesOfCompanies } from 'src/features/Person/actions/getBeneficiariesOfCompanies';
import { req as getCompanyByInnConfirmedBeneficiaries } from 'src/features/Person/actions/getCompanyByInnConfirmedBeneficiaries';
import { req as getCompanyByInnConfirmedPerson } from 'src/features/Person/actions/getCompanyByInnConfirmedPerson';
import {
  req as getSummaryPerson,
  reset as resetSummaryPerson
} from 'src/features/Person/actions/getSummaryPerson';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  BeneficiariesOfCompaniesType,
  PersonOfCompaniesType,
  SummaryPerson
} from '../../types';
import {
  ApplicationView,
  CopyBlock,
  HeadData,
  InputContainer,
  MainSection,
  NotFound,
  PersonInfo,
  PersonInfoContainer,
  PersonInfoDirector,
  PersonName,
  PersonsContainer,
  SkeletonWrapper
} from './styled';
import { Spinner } from 'src/features/Layouts/components';

interface StateToProps {
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  person: PersonOfCompaniesType[];
  statusPerson: REQUEST_STATUSES;
  beneficiaries: BeneficiariesOfCompaniesType[];
  statusBeneficiaries: REQUEST_STATUSES;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
  summaryPerson: SummaryPerson;
  statusSummaryPerson: REQUEST_STATUSES;
  errorSummaryPerson: ResponseError;
  confirmedCompanyBeneficiaries: ResponseDataType[];
  statusConfirmedCompanyBeneficiaries: REQUEST_STATUSES;
  confirmedCompanyPerson: ResponseDataType[];
  statusConfirmedCompanyPerson: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  getPersonOfCompanies: (inn: string) => void;
  getBeneficiariesOfCompanies: (inn: string) => void;
  getSummaryPerson: (inn: string) => void;
  resetSummaryPerson: () => void;
  getCompanyByInnConfirmedBeneficiaries: (data: string[]) => void;
  getCompanyByInnConfirmedPerson: (data: string[]) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const PersonView: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  status,
  getPersonOfCompanies,
  person,
  statusPerson,
  getBeneficiariesOfCompanies,
  beneficiaries,
  statusBeneficiaries,
  getSummaryPerson,
  resetSummaryPerson,
  resetStateCompanyByInn,
  summaryPerson,
  statusSummaryPerson,
  errorSummaryPerson,
  getCompanyByInnConfirmedBeneficiaries,
  statusConfirmedCompanyBeneficiaries,
  getCompanyByInnConfirmedPerson,
  statusConfirmedCompanyPerson,
  isAuthorized,
  permissions,
  history,
  match
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [isRequest, setIsRequest] = React.useState(0);
  const [beneficiariesIcon, setBeneficiariesIcon] = React.useState<{
    [key: string]: string;
  }>({});
  const [personsIcon, setPersonsIcon] = React.useState<{
    [key: string]: string;
  }>({});

  React.useEffect(
    () => {
      if (statusBeneficiaries === REQUEST_STATUSES.GOT) {
        beneficiaries.forEach((user, index) => {
          loadBeneficiariesIcon(user.okvedCode, user.okvedPic, index);
        });
      }
    },
    [statusBeneficiaries]
  );

  React.useEffect(
    () => {
      if (statusPerson === REQUEST_STATUSES.GOT) {
        person.forEach((user, index) => {
          loadPersonsIcon(user.okvedCode, user.okvedPic, index);
        });
      }
    },
    [statusPerson]
  );

  React.useEffect(
    () => {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain.toLowerCase() === 'my') {
        return;
      } else if (
        !isAuthorized ||
        (permissions.length && !permissions.includes(USER_PERMISSIONS.ADMIN))
      ) {
        window.location.href = `https://my.finfactory.one/person/${
          match.params.inn
        }`;
      }
    },
    [isAuthorized, permissions, match.params.inn, window.location.hostname]
  );

  React.useEffect(
    () => {
      if (match.params.inn) {
        getSummaryPerson(match.params.inn);
      }

      return () => resetSummaryPerson();
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (searchValue.length >= 10) {
        getCompanyByInn({ inn: searchValue });
      }

      return () => resetStateCompanyByInn();
    },
    [searchValue]
  );

  React.useEffect(
    () => {
      if (statusSummaryPerson === REQUEST_STATUSES.GOT && isRequest < 2) {
        getPersonOfCompanies(match.params.inn);
        getBeneficiariesOfCompanies(match.params.inn);
        setIsRequest(prev => prev + 1);
      }
      if (statusSummaryPerson === REQUEST_STATUSES.GOT) {
        document.title = `${summaryPerson.personFullName}, ИНН ${
          summaryPerson.personINN
        }`;

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = `${summaryPerson.personFullName}, ИНН ${
          summaryPerson.personINN
        } - информация о персоне и связанных компаниях`;
        document.head.appendChild(metaDescription);

        const prevTitle = document.title;
        return () => {
          document.title = prevTitle;
          if (metaDescription) {
            document.head.removeChild(metaDescription);
          }
        };
      }
    },
    [
      statusSummaryPerson,
      statusConfirmedCompanyBeneficiaries,
      statusConfirmedCompanyPerson
    ]
  );

  React.useEffect(
    () => {
      if (
        statusPerson === REQUEST_STATUSES.GOT &&
        person.length &&
        isRequest === 1
      ) {
        const companyInns = person
          .filter(item => Object.keys(item).length === 1)
          .map(item => item.companyInn);

        if (companyInns.length > 0) {
          getCompanyByInnConfirmedPerson(companyInns);
        }
      }
    },
    [statusPerson, person]
  );

  React.useEffect(
    () => {
      if (
        statusBeneficiaries === REQUEST_STATUSES.GOT &&
        beneficiaries.length &&
        isRequest === 1
      ) {
        const companyInns = beneficiaries
          .filter(item => Object.keys(item).length === 1)
          .map(item => item.companyInn);

        if (companyInns.length > 0) {
          getCompanyByInnConfirmedBeneficiaries(companyInns);
        }
      }
    },
    [statusBeneficiaries, beneficiaries]
  );

  const loadBeneficiariesIcon = async (
    okvedCode: string,
    okvedPic: string,
    index: number
  ) => {
    if (okvedCode) {
      const code = parseInt(okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setBeneficiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setBeneficiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: undefined
        }));
      }
    } else if (okvedPic) {
      const icon = await import(`../../../../assets/icons/${okvedPic}.png`);
      setBeneficiariesIcon(prevIcons => ({
        ...prevIcons,
        [index]: icon.default
      }));
    }
  };

  const loadPersonsIcon = async (
    okvedCode: string,
    okvedPic: string,
    index: number
  ) => {
    if (okvedCode) {
      const code = parseInt(okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setPersonsIcon(prevIcons => ({
          ...prevIcons,
          [index]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setPersonsIcon(prevIcons => ({
          ...prevIcons,
          [index]: undefined
        }));
      }
    } else if (okvedPic) {
      const icon = await import(`../../../../assets/icons/${okvedPic}.png`);
      setPersonsIcon(prevIcons => ({
        ...prevIcons,
        [index]: icon.default
      }));
    }
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const navigateToBusiness = (inn?: string) => {
    if (!!inn) {
      history.push(`/business/${inn}`);
    } else {
      history.push(`/business/${companyByInn.inn}`);
    }
    setSearchValue('');
  };

  return (
    <ApplicationView>
      <MainSection itemScope itemType="http://schema.org/Person">
        <InputContainer>
          <Input
            name="searchInn"
            value={searchValue}
            onChange={e => handleSetSearchValue(e.currentTarget.value)}
            icon={faSearch}
            placeholder="Поиск по ИНН"
          />

          {searchValue.length >= 10 &&
            status === REQUEST_STATUSES.GOT && (
              <InnPopup
                companyInfo={companyByInn}
                onPopupClick={navigateToBusiness}
              />
            )}
        </InputContainer>

        {statusSummaryPerson !== REQUEST_STATUSES.ERROR && (
          <>
            <HeadData>
              <div>
                <p>ИНН:</p>
                <CopyBlock
                  text={
                    summaryPerson.personINN && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(summaryPerson.personINN)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusPerson === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p itemProp="taxID">{summaryPerson.personINN}</p>
                  )}
                </CopyBlock>
              </div>
            </HeadData>

            <CopyBlock
              text={
                summaryPerson.personFullName && !isCopied
                  ? 'Скопировать'
                  : isCopied && 'Скопировано!'
              }
              onClick={() => handleCopyClick(summaryPerson.personFullName)}
              onMouseLeave={() => setIsCopied(false)}
            >
              {statusPerson === REQUEST_STATUSES.REQUEST ? (
                <SkeletonWrapper width={'250px'} height={'35px'} />
              ) : (
                <ApplicationTitle itemProp="name">
                  {summaryPerson.personFullName}
                </ApplicationTitle>
              )}
            </CopyBlock>

            {statusPerson === REQUEST_STATUSES.REQUEST && (
              <PersonsContainer>
                <ApplicationTitle>
                  {statusPerson === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'175px'} height={'20px'} />
                  ) : companyByInn.companyShortName ? (
                    companyByInn.companyShortName
                  ) : (
                    companyByInn.companyFullName
                  )}
                </ApplicationTitle>

                <div>
                  <PersonInfoContainer>
                    <div>
                      <Spinner />
                      <div>
                        {statusPerson === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <p />
                        )}
                        {statusPerson === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <p />
                        )}
                        {statusPerson === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </PersonInfoContainer>
                </div>
              </PersonsContainer>
            )}

            {!!person.length && (
              <PersonsContainer>
                <ApplicationTitle>Руководитель в:</ApplicationTitle>

                <div>
                  {person
                    .filter(obj => Object.keys(obj).length > 1)
                    .map((user, index) => (
                      <PersonInfoContainer key={index}>
                        <div>
                          {statusConfirmedCompanyPerson ===
                          REQUEST_STATUSES.REQUEST ? (
                            <Spinner />
                          ) : isAuthorized ? (
                            <img
                              src={personsIcon[index]}
                              alt={user.okvedName}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faBriefcase} />
                          )}

                          <PersonInfoDirector>
                            {user.position && (
                              <p itemProp="jobTitle">
                                {user.position.charAt(0).toUpperCase() +
                                  user.position.slice(1).toLowerCase()}
                              </p>
                            )}
                            {statusConfirmedCompanyPerson ===
                            REQUEST_STATUSES.REQUEST ? (
                              <SkeletonWrapper
                                width={'175px'}
                                height={'20px'}
                              />
                            ) : (
                              <PersonName>
                                <p
                                  itemProp="worksFor"
                                  onClick={() =>
                                    navigateToBusiness(user.companyInn)
                                  }
                                >
                                  {user.companyShortName
                                    ? user.companyShortName
                                    : user.companyFullName}
                                </p>
                              </PersonName>
                            )}
                            <div>
                              <p>ИНН</p>
                              <CopyBlock
                                text={
                                  user.companyInn && !isCopied
                                    ? 'Скопировать'
                                    : isCopied && 'Скопировано!'
                                }
                                onClick={() => handleCopyClick(user.companyInn)}
                                onMouseLeave={() => setIsCopied(false)}
                              >
                                <p itemProp="taxID">{user.companyInn}</p>
                              </CopyBlock>
                            </div>
                          </PersonInfoDirector>
                        </div>
                      </PersonInfoContainer>
                    ))}
                </div>
              </PersonsContainer>
            )}

            {!!beneficiaries.length && (
              <PersonsContainer>
                <ApplicationTitle>Учредитель в:</ApplicationTitle>

                <div>
                  {beneficiaries
                    .filter(obj => Object.keys(obj).length > 1)
                    .map((beneficiar, index) => (
                      <PersonInfoContainer key={index}>
                        <div>
                          {statusConfirmedCompanyBeneficiaries ===
                          REQUEST_STATUSES.REQUEST ? (
                            <Spinner />
                          ) : isAuthorized ? (
                            <img
                              src={beneficiariesIcon[index]}
                              alt={beneficiar.okvedName}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faBriefcase} />
                          )}

                          <PersonInfo>
                            <div>
                              <div>
                                <p>Доля в УК:</p>
                                {statusConfirmedCompanyBeneficiaries ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'30px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  <p>
                                    {beneficiar.ownershipShareDeJure
                                      ? `${beneficiar.ownershipShareDeJure}%`
                                      : '-'}
                                  </p>
                                )}
                              </div>
                              {statusConfirmedCompanyBeneficiaries ===
                              REQUEST_STATUSES.REQUEST ? (
                                <SkeletonWrapper
                                  width={'30px'}
                                  height={'20px'}
                                />
                              ) : (
                                <div>
                                  <p>
                                    {beneficiar.capitalShare
                                      ? `${formSumStringThousands(
                                          beneficiar.capitalShare
                                        )} руб.`
                                      : '-'}
                                  </p>
                                </div>
                              )}
                            </div>
                            {statusConfirmedCompanyBeneficiaries ===
                            REQUEST_STATUSES.REQUEST ? (
                              <SkeletonWrapper
                                width={'175px'}
                                height={'20px'}
                              />
                            ) : (
                              <PersonName>
                                <p
                                  itemProp="affiliation"
                                  onClick={() =>
                                    navigateToBusiness(beneficiar.companyInn)
                                  }
                                >
                                  {beneficiar.companyShortName
                                    ? beneficiar.companyShortName
                                    : beneficiar.companyFullName}
                                </p>
                              </PersonName>
                            )}
                            <div>
                              <p>ИНН</p>
                              <CopyBlock
                                text={
                                  beneficiar.companyInn && !isCopied
                                    ? 'Скопировать'
                                    : isCopied && 'Скопировано!'
                                }
                                onClick={() =>
                                  handleCopyClick(beneficiar.companyInn)
                                }
                                onMouseLeave={() => setIsCopied(false)}
                              >
                                <p itemProp="taxID">{beneficiar.companyInn}</p>
                              </CopyBlock>
                            </div>
                          </PersonInfo>
                        </div>
                      </PersonInfoContainer>
                    ))}
                </div>
              </PersonsContainer>
            )}
          </>
        )}
      </MainSection>

      {errorSummaryPerson.code === 404 && (
        <NotFound>
          <h2>СТРАНИЦА НЕ НАЙДЕНА</h2>
        </NotFound>
      )}
    </ApplicationView>
  );
};

const mapStateToProps = ({ User, SCF, Cached, Person }: STORE & CRM) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  person: Person.getPersonOfCompanies.data,
  statusPerson: Person.getPersonOfCompanies.status,
  beneficiaries: Person.getBeneficiariesOfCompaniesPerson.data,
  statusBeneficiaries: Person.getBeneficiariesOfCompaniesPerson.status,
  summaryPerson: Person.getSummaryPerson.data,
  statusSummaryPerson: Person.getSummaryPerson.status,
  errorSummaryPerson: Person.getSummaryPerson.error,
  isAuthorized: Cached.authorized,
  permissions: User.getUserData.data.permissions,
  confirmedCompanyBeneficiaries:
    Person.getCompanyByInnConfirmedBeneficiaries.data,
  statusConfirmedCompanyBeneficiaries:
    Person.getCompanyByInnConfirmedBeneficiaries.status,
  confirmedCompanyPerson: Person.getCompanyByInnConfirmedPerson.data,
  statusConfirmedCompanyPerson: Person.getCompanyByInnConfirmedPerson.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      resetStateCompanyByInn,
      getPersonOfCompanies,
      getBeneficiariesOfCompanies,
      getSummaryPerson,
      resetSummaryPerson,
      getCompanyByInnConfirmedBeneficiaries,
      getCompanyByInnConfirmedPerson
    },
    dispatch
  );

const PersonConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PersonView)
);

export { PersonConnect as PersonView };
