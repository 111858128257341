import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import {
  FactoringApplicationRead,
  GuaranteesApplicationRead,
  CreditingApplicationRead,
  FinancingApplicationRead
} from 'Application/types';

export interface RequestDataType {
  id: number;
}

export type ResponseDataType = Partial<
  | FactoringApplicationRead
  | GuaranteesApplicationRead
  | CreditingApplicationRead
  | FinancingApplicationRead
>;

export enum GET_IN_BANK_APPLICATION {
  REQ = 'GET_IN_BANK_APPLICATION_REQ',
  GOT = 'GET_IN_BANK_APPLICATION_GOT',
  ERR = 'GET_IN_BANK_APPLICATION_ERR',
  RESET = 'GET_IN_BANK_APPLICATION_RESET'
}

export interface GetInBankApplicationStoreState extends Reducer {
  data: ResponseDataType;
}

export const initialState: GetInBankApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {},
  error: {}
};

export const getInBankApplication = (
  state: GetInBankApplicationStoreState = initialState,
  action: {
    type: GET_IN_BANK_APPLICATION;
    data: ResponseDataType;
    error: {};
  }
): GetInBankApplicationStoreState => {
  switch (action.type) {
    case GET_IN_BANK_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_IN_BANK_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_IN_BANK_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_IN_BANK_APPLICATION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
