import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostUploadFinDocInternalStoreState extends Reducer {}

export enum POST_UPLOAD_FIN_DOC_INTERNAL {
  REQ = 'POST_UPLOAD_FIN_DOC_INTERNAL_REQ',
  GOT = 'POST_UPLOAD_FIN_DOC_INTERNAL_GOT',
  ERR = 'POST_UPLOAD_FIN_DOC_INTERNAL_ERR'
}

export const initialState: PostUploadFinDocInternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postUploadFinDocInternal = (
  state: PostUploadFinDocInternalStoreState = initialState,
  action: {
    type: POST_UPLOAD_FIN_DOC_INTERNAL;
    error: {};
  }
): PostUploadFinDocInternalStoreState => {
  switch (action.type) {
    case POST_UPLOAD_FIN_DOC_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_UPLOAD_FIN_DOC_INTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_UPLOAD_FIN_DOC_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postUploadFinDocInternal;
