export enum BANK_PRODUCT_TABS {
  DEFAULT = 'Кредитные продукты',
  PLEDGES = 'Залоги',
  BANK_ACCOUNTS = 'Расчетные счета',
  OPEN_LOAN_LIMITS = 'Предодобренные лимиты'
}

export interface Pledges {
  clientInn: string;
  clientName: string;
  bankInn: string;
  bankName: string;
  firstPledgeContractDate: null;
  lastPledgeContractDate: null;
  isActive: boolean;
  source: null;
}

export interface ResponseCreditProducts {
  agentInn: string;
  agentName: string;
  amount: null;
  bankInn: string;
  bankName: string;
  clientInn: string;
  clientName: string;
  comment: string;
  currencyCode: null;
  currencyNumber: null;
  isActive: boolean;
  productEndDate: null;
  productID: null;
  productName: string;
  productStartDate: null;
  productTypeID: number;
  rate: null;
  source: null;
  termInMonthes: null;
}

export interface ResponseBankAccount {
  accountNumber: string;
  bankBik: string;
  bankInn: string;
  bankName: string;
  clientInn: string;
  correspondentAccountNumber: string;
  creatorId: number;
  currencyCode: string;
  currencyNumber: string;
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  isMain: boolean;
}
