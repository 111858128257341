import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type SendSubSignatureStoreState = Reducer;

export enum SEND_SUB_SIGNATURE {
  REQ = 'SEND_SUB_SIGNATURE_REQ',
  GOT = 'SEND_SUB_SIGNATURE_GOT',
  ERR = 'SEND_SUB_SIGNATURE_ERR'
}

export interface RequestDataType {
  id: string;
  signature: string;
  thumbprint: string;
}

export const initialState: SendSubSignatureStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendSubSignature = (
  state: SendSubSignatureStoreState = initialState,
  action: {
    type: SEND_SUB_SIGNATURE;
    error: {};
  }
): SendSubSignatureStoreState => {
  switch (action.type) {
    case SEND_SUB_SIGNATURE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_SUB_SIGNATURE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_SUB_SIGNATURE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendSubSignature;
