import { GET_COMPANY_BY_INN_CONFIRMED_SUBSIDIARIES } from 'src/features/Business/reducers/getCompanyByInnConfirmedSubsidiaries';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export const req = (data: string[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_SUBSIDIARIES.REQ,
  data
});

export const got = (data: ResponseDataType[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_SUBSIDIARIES.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_SUBSIDIARIES.ERR,
  error
});
