import { HoldingsData } from '../actions/setHoldingFormData';
import { RequestData as GetHoldingsListRequestData } from '../actions/getHoldingsList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class HoldingsApi {
  //static sendHoldingData(data: HoldingsData) {
  //  return ApiRequest('/api/holdings', 'post', data);
  //}

  static getHolding(id: number) {
    return ApiRequest(`/api/holding/id/${id}`);
  }

  static getAllHoldings(data: GetHoldingsListRequestData) {
    return ApiRequest('/api/holding/all', 'get', data);
  }
}
