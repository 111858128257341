import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getBeneficiariesOfCompanies';

import { GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getBeneficiariesOfCompanies';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getBeneficiariesOfCompanies(action: {
  type: GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      ExternalAgentRoleApi.getBeneficiariesOfCompanies,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBeneficiariesOfCompaniesSaga(): Generator {
  yield takeLatest(
    GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.REQ,
    getBeneficiariesOfCompanies
  );
}
