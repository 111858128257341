import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getProductsListByContact';

import { GET_PRODUCTS_LIST_BY_CONTACT } from '../reducers/getProductsListByContact';
import ContactsOfProductsApi from '../api';

function* getProductsListByContact(action: {
  type: GET_PRODUCTS_LIST_BY_CONTACT;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(
      ContactsOfProductsApi.getProductsListByContact,
      action.data.contactId
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductsListByContactSaga(): Generator {
  yield takeLatest(GET_PRODUCTS_LIST_BY_CONTACT.REQ, getProductsListByContact);
}
