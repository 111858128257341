import * as React from 'react';
import { Route } from 'react-router';
import RequisitesBankAccounts from './RequisitesBankAccounts/RequisitesBankAccounts'
import { RequisitesEdit } from './RequisitesEdit/RequisitesEdit';
import {
  RequsitesStyled,
} from './styles';

const RequisitesPage = () => {
    return (
      <RequsitesStyled>
        <Route
          path={"/supplier/settings/requisites"}
          render={() => <RequisitesBankAccounts />}
        />
        <Route
          path={"/supplier/settings/edit"}
          render={() => <RequisitesEdit />}
        />

        <Route
          path={"/debtor/settings/requisites"}
          render={() => <RequisitesBankAccounts />}
        />
        <Route
          path={"/debtor/settings/edit"}
          render={() => <RequisitesEdit />}
        />

        <Route
          path={"/factor/settings/requisites"}
          render={() => <RequisitesBankAccounts />}
        />
        <Route
          path={"/factor/settings/edit"}
          render={() => <RequisitesEdit />}
        />
      </RequsitesStyled>

    );
}

export default RequisitesPage;
