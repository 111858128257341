import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faMinus,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileArchive,
  faFile
} from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData } from 'src/features/SupplyContracts/actions/getSupplyContractsList';
import {
  CheckboxStyle,
  BlockStyled,
  TooltipBoxStyle,
  TDStyled,
  FileContainer
} from './styled';
import { history } from 'src/shared/utils/History';

interface Props {
  supplyContracts: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

export const SupplyList: React.FC<Props> = ({
  supplyContracts,
  status,
  error
}) => {
  const getIconByFileType = (fileName: string, guid?: string) => {
    if (!fileName) return;

    const matchResult = fileName.match(/\.(\w+)$/);
    if (matchResult) {
      const fileExtension = matchResult[1];
      if (fileExtension === 'doc' || fileExtension === 'docx') {
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'pdf') {
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        return (
          <FontAwesomeIcon
            icon={faFileExcel}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'zip' || fileExtension === 'rar') {
        return (
          <FontAwesomeIcon
            icon={faFileArchive}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else {
        return <FontAwesomeIcon icon={faFile} size="lg" />;
      }
    }
    return null;
  };

  const navigateToContract = (guid: string) => {
    history.push(`/crm/supply_contracts/${guid}`);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Дебитор</TableThStyled>
                <TableThStyled width="10%">Поставщик</TableThStyled>
                <TableThStyled width="15%">Наименование</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {supplyContracts.items.map(contract => (
                <TableRowStyled key={contract.id}>
                  <TDStyled>
                    <BlockStyled>
                      <div>
                        <div>{contract.debtorName}</div>
                        <div>{contract.debtorInn}</div>
                      </div>
                      {contract.debtorSignatureIdentifier ? (
                        <TooltipBoxStyle>
                          <CheckboxStyle
                            textVerify={'Подтверждено дебитором'}
                            verify={contract.debtorSignatureIdentifier}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      ) : (
                        <TooltipBoxStyle>
                          <CheckboxStyle
                            textVerify={'Не подтверждено дебитором'}
                            verify={contract.debtorSignatureIdentifier}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      )}
                    </BlockStyled>
                  </TDStyled>
                  <TDStyled>
                    <BlockStyled>
                      <div>
                        <div>{contract.supplierName}</div>
                        <div>{contract.supplierInn}</div>
                      </div>
                      {contract.supplierSignatureIdentifier ? (
                        <TooltipBoxStyle>
                          <CheckboxStyle
                            textVerify={'Подтверждено поставщиком'}
                            verify={contract.supplierSignatureIdentifier}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      ) : (
                        <TooltipBoxStyle>
                          <CheckboxStyle
                            textVerify={'Не подтверждено поставщиком'}
                            verify={contract.supplierSignatureIdentifier}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      )}
                    </BlockStyled>
                  </TDStyled>
                  <td>
                    <FileContainer>
                      <div>
                        <p>
                          {contract.contractName} № {contract.contractNumber}
                        </p>
                        <p>
                          {contract.isPerpetual && !!contract.contractEndDate
                            ? `от ${new Date(
                                contract.contractStartDate
                              ).toLocaleDateString()} - бессрочн.`
                            : `${new Date(
                                contract.contractStartDate
                              ).toLocaleDateString()} ${new Date(
                                contract.contractEndDate
                              ).toLocaleDateString()}`}
                        </p>
                      </div>
                      <div>
                        {contract.contraceFileName &&
                          getIconByFileType(contract.contraceFileName)}
                        {contract.debtorSignatureFileName &&
                          getIconByFileType(contract.debtorSignatureFileName)}
                        {contract.supplierSignatureFileName &&
                          getIconByFileType(contract.supplierSignatureFileName)}
                      </div>
                    </FileContainer>
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={supplyContracts} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};
