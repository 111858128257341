import { combineReducers } from 'redux';
import patchStopFactors, { PatchStopFactors } from './patchStopFactors';

export interface StopFactorsReducers {
  patchStopFactors: PatchStopFactors;
}

export const StopFactors = combineReducers({
  patchStopFactors
});
