import styled from 'styled-components';
import { lighterBlue } from 'shared/styled';

export const NameClientFilterContainerStyled = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  align-items: end;

  border-radius: 4px;
  background-color: ${lighterBlue};

  p {
    margin-right: 10px;
    :not(:first-child) {
      margin-left: 20px;
    }
  }
`;
