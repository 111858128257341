import { combineReducers } from 'redux';

import postComment, { PostCommentStoreState } from './postComment';
import postReply, { PostReplyStoreState } from './postReply';
import getCommentsList, { GetCommentsListStoreState } from './getCommentsList';

export interface CommentReducers {
  postComment: PostCommentStoreState;
  postReply: PostReplyStoreState;
  getCommentsList: GetCommentsListStoreState;
}

export const Comment = combineReducers({
  postComment,
  postReply,
  getCommentsList
});
