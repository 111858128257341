import { Document } from 'src/features/Application/types';

export const convertKbToMb = (size: number): string => {
  /*
    Forms number in KB to MB format
    e.g. 479119 -> '467.9 МБ'
    or 1000 -> '1000 КБ', etc
  */

  const sizeKb = size / 1000;
  const sizeMb = sizeKb / 1000;
  return sizeMb >= 1 ? `${sizeMb.toFixed(1)} МБ` : `${sizeKb.toFixed(1)} КБ`;
};

export const formDateString = (ms: number): string => {
  /*
    Forms number in ms to day/month/year format
    e.g. 1544683242000 -> '13/11/2018'
  */
  const date = new Date(ms);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formBackendDateString = (dateAndTime: string): string => {
  /*
    Forms number in ms to day/month/year format
    e.g. '2/14/19 10:48:02 AM' -> '14/2/19'
  */

  const dateArray = dateAndTime.split(' ')[0].split('/');

  const formDigitString = (digitStr: string): string =>
    parseInt(digitStr, 10) < 10 ? `0${digitStr}` : digitStr;

  const day = formDigitString(dateArray[1]);
  const month = formDigitString(dateArray[0]);
  const year = formDigitString(dateArray[2]);

  return `${day}/${month}/${year}`;
};

/**
 * Sum to float: 234 234,12 -> 234234.12
 */
// TODO add tests here
export const toFloatFormatter = (sum: string): number => {
  if (typeof sum === 'string') {
    return !!sum
      ? parseFloat(
          sum
            .replace(/\s/g, '')
            .replace(/₽/g, '')
            .replace(/,/g, '.')
        )
      : 0;
  } else {
    throw new Error('toFloatFormatter argument is not a string');
  }
};

export function isValidINN(i: any) {
  if (i.match(/\D/)) return false;

  const inn = i.match(/(\d)/g);

  if (inn.length === 10) {
    return (
      inn[9] ===
      String(
        ((2 * inn[0] +
          4 * inn[1] +
          10 * inn[2] +
          3 * inn[3] +
          5 * inn[4] +
          9 * inn[5] +
          4 * inn[6] +
          6 * inn[7] +
          8 * inn[8]) %
          11) %
          10
      )
    );
  } else if (inn.length === 12) {
    return (
      inn[10] ===
        String(
          ((7 * inn[0] +
            2 * inn[1] +
            4 * inn[2] +
            10 * inn[3] +
            3 * inn[4] +
            5 * inn[5] +
            9 * inn[6] +
            4 * inn[7] +
            6 * inn[8] +
            8 * inn[9]) %
            11) %
            10
        ) &&
      inn[11] ===
        String(
          ((3 * inn[0] +
            7 * inn[1] +
            2 * inn[2] +
            4 * inn[3] +
            10 * inn[4] +
            3 * inn[5] +
            5 * inn[6] +
            9 * inn[7] +
            4 * inn[8] +
            6 * inn[9] +
            8 * inn[10]) %
            11) %
            10
        )
    );
  }

  return false;
}

// TODO add tests here
export const formSumString = (sum: number) => {
  /*
    Forms number to XXX XXX XXX format
    e.g. 1234567890 -> '1 234 567 890 ₽'
    or 123456789 -> '123 456 789 ₽', etc
  */

  // const sumStr = sum
  //   .toFixed(2)
  //   .split('')
  //   .reverse()
  //   .join('')
  //   .match(/.{1,3}/g)
  //   .reduce(
  //     (arr, item) =>
  //       (arr = [
  //         ...arr,
  //         item
  //           .split('')
  //           .reverse()
  //           .join('')
  //       ]),
  //     []
  //   )
  //   .reverse()
  //   .join(' ');

  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  });
  return formatter.format(sum);
  // const sumStr = sum.toFixed(2);
  // const split = sumStr.split("");
  // let resultString = "";

  // for (var i = split.length-1; i >= 0; i--) {
  //   if (i % 3 === 0 && split[i] !== ".")
  //     resultString += " "
  //   resultString += split[i];
  // }

  // return `${resultString} ₽`;
};

export function formatNumberWithComma(input: number) {
  if (typeof input !== 'number') {
    throw new Error('Input must be a number');
  }

  const roundedInput = input.toFixed(4);

  const formattedNumber = parseFloat(roundedInput)
    .toString()
    .replace('.', ',');

  if (!formattedNumber.includes(',')) {
    return `${formattedNumber},0`;
  }

  return formattedNumber;
}

export const formSumStringThousands = (
  sum: number,
  options?: {
    maxFractionDigits?: number;
    minFractionDigits?: number;
  }
): string => {
  /*
    Forms number to XXX XXX XXX format
    e.g. 1234567890 -> '1 234 567 890'
    or 123456789 -> '123 456 789', etc
  */

  const { maxFractionDigits = 2, minFractionDigits = 2 } = options || {};

  const formatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits
  });

  return formatter.format(sum);
};

/**
 * Склонение слов (окончаний)
 * @param {array} strings Массив строк:
 *                        - [0] – для значений 1, 21, 31…
 *                        - [1] — для значений 2, 3, 4, 22, 23…
 *                        - [2] — для всех остальных значений
 */
export const pluralFormatter = (value: number, strings: string[]): string => {
  const modulo = value % 10;
  const isException = value >= 11 && value <= 19;
  if (!(strings instanceof Array) || strings.length <= 0) {
    return '';
  }

  return modulo === 1 && !isException
    ? strings[0]
    : modulo >= 2 && modulo <= 4 && !isException
      ? strings[1]
      : strings[2];
};

const defineYearsUnitStr = (yearsQuantity: number) => {
  return pluralFormatter(yearsQuantity, ['год', 'года', 'лет']);
};

const defineMonthsUnitStr = (monthsQuantity: number) => {
  return pluralFormatter(monthsQuantity, ['месяц', 'месяца', 'месяцев']);
};

export const formTermString = term => {
  /*
    Forms number to 'X лет X месяцев' format
    e.g. 13 -> '1 год 1 месяц'
    or 66 -> '6 лет 6 месяцев', etc
  */

  const yearsQuantity = Math.floor(term / 12);
  const monthsQuantity = term % 12;

  if (yearsQuantity === 0) {
    return `${monthsQuantity} ${defineMonthsUnitStr(monthsQuantity)}`;
  }

  if (monthsQuantity === 0) {
    return `${yearsQuantity} ${defineYearsUnitStr(yearsQuantity)}`;
  }

  return `${yearsQuantity} ${defineYearsUnitStr(
    yearsQuantity
  )} ${monthsQuantity} ${defineMonthsUnitStr(monthsQuantity)}`;
};

export const formTermDate = (term: number) => {
  term = Math.abs(term);
  term = Math.floor(term);

  const lastDigit = term % 10;
  const lastTwoDigits = term % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${term} дней`;
  }

  switch (lastDigit) {
    case 1:
      return `${term} день`;
    case 2:
    case 3:
    case 4:
      return `${term} дня`;
    default:
      return `${term} дней`;
  }
};

export const capitalize = (text: string) => {
  return !!text && text.charAt(0).toUpperCase() + text.slice(1);
};

declare global {
  interface Array<T> {
    isIn(otherArray: any[]): boolean;
    contains(otherArray: any[]): boolean;
    without(otherArray: any[]): any[];
  }
  interface Number {
    pad(size: number): string;
  }
}

// TODO naming
Array.prototype.isIn = function(otherArray: any[]) {
  for (const item of this) if (otherArray.includes(item)) return true;
  return false;
};

Array.prototype.without = function(otherArray: any[]) {
  return this.filter(el => {
    return otherArray.indexOf(el) < 0;
  });
};

Array.prototype.contains = function(otherArray: any[]) {
  for (const item of this) if (!otherArray.includes(item)) return false;
  return true;
};

Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

export function shadeColor(color, percent) {
  // Проверяем, является ли цвет rgba
  if (color.startsWith('rgba')) {
    const rgba = color.match(/\d+/g);
    let r = parseInt(rgba[0]);
    let g = parseInt(rgba[1]);
    let b = parseInt(rgba[2]);
    let a = parseFloat(rgba[3]);

    // Осветляем цвета
    r = Math.floor((r * (100 + percent)) / 100);
    g = Math.floor((g * (100 + percent)) / 100);
    b = Math.floor((b * (100 + percent)) / 100);

    r = r < 255 ? r : 255;
    g = g < 255 ? g : 255;
    b = b < 255 ? b : 255;

    // Возвращаем новый цвет в формате rgba
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor((R * (100 + percent)) / 100);
    G = Math.floor((G * (100 + percent)) / 100);
    B = Math.floor((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    const GG =
      G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    const BB =
      B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }
}

// Dates calculation

export function getYearlyReportYear(date = new Date()): number {
  const year = date.getFullYear();

  const march = new Date(year, 3, 1);
  if (date < march) return year - 2;

  // const april = new Date(year, 3, 1);
  const december = new Date(year + 1, 0, 1);
  if (date >= march && date <= december) return year - 1;
}

interface QuarterReportBoundary {
  quarter?: number;
  year?: number;
}

export function getQuarterReportBoundary(
  date = new Date()
): QuarterReportBoundary {
  const year = date.getFullYear();

  const march = new Date(year, 3, 1);
  if (date <= march)
    return {
      year: year - 1,
      quarter: 3
    };
  // const aprilFirst = new Date(year, 3, 1);
  // const aprilLast = new Date(year, 3, 30);
  const may = new Date(year, 4, 1);
  if (date > march && date <= may) return {};
  const july = new Date(year, 7, 1);
  if (date > may && date <= july) return { quarter: 1, year };
  const august = new Date(year, 7, 1);
  const october = new Date(year, 10, 1);
  if (date >= august && date <= october) return { quarter: 2, year };
  const november = new Date(year, 10, 1);
  const december = new Date(year + 1, 0, 1);
  if (date >= november && date <= december) return { quarter: 3, year };
}

export function isQuarterRequired() {
  const quarterBoundaries = getQuarterReportBoundary();
  return quarterBoundaries.quarter && quarterBoundaries.year;
}

export function findDocumentByMetaInfo(
  documents: Document[],
  metaInfo: string
) {
  return documents.find(doc => doc.metaInfo === metaInfo);
}
