import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { FactoringLimitRead } from '../actions/setFactoringLimitFormData';

export interface GetFactoringLimitStoreState extends Reducer {
  factoringLimit: FactoringLimitRead;
}

export enum GET_FACTORING_LIMIT {
  REQ = 'GET_FACTORING_LIMIT_REQ',
  GOT = 'GET_FACTORING_LIMIT_GOT',
  ERR = 'GET_FACTORING_LIMIT_ERR'
}

export const initialState: GetFactoringLimitStoreState = {
  status: REQUEST_STATUSES.NONE,
  factoringLimit: {
    id: 0
  },
  error: {}
};

const getFactoringLimit = (
  state: GetFactoringLimitStoreState = initialState,
  action: {
    type: string;
    factoringLimit: FactoringLimitRead;
    error: {};
  }
): GetFactoringLimitStoreState => {
  switch (action.type) {
    case GET_FACTORING_LIMIT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_LIMIT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        factoringLimit: action.factoringLimit
      };
    case GET_FACTORING_LIMIT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactoringLimit;
