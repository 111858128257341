import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TabsStyled,
  TabsHeader,
  TabHeaderItem,
  TabItem,
  TabBody
} from 'Layouts/components/Tabs/styles';
import { CrossCircleBtn, LoaderContainer } from 'shared/styled';
import {
  blue,
  lighterBlue,
  rl,
  gray,
  rr,
  lightGray,
  navyBlue
} from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';
import { LighterBlueContainer } from 'shared/styled/layouts';

export const WarningAddCompanyBtn = styled.button.attrs({
  type: 'button'
})`
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  color: ${blue};
`;

export const CompanyTabsStyled = styled(TabsStyled)`
  position: relative;
`;

export const CompanyTabsHeader = styled(TabsHeader)`
  height: 90px;
  justify-content: flex-start;
  border-bottom: none;
`;

export const CompanyTabHeaderItem = styled(TabHeaderItem)`
  width: 230px;
  flex: none;
  background: ${({ selected }) => (selected ? blue : lighterBlue)};
  transition: opacity 0.2s;

  & > button {
    margin-left: 10px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &::after {
    content: none;
  }

  &:hover {
    opacity: 0.85;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    row-gap: 7px;

    & > p:first-of-type {
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      font-size: 16px;
      font-family: ${rl};
      color: ${({ selected }) => (selected ? 'white' : 'black')};
      margin: 0;
    }
  }
`;

export const CompanyTabHeaderRole = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    font-family: ${rl};
    font-size: 10px;
    color: ${({ selected }) => (selected ? 'white' : 'black')};
  }
`;

export const AddCompanyHeaderItem = styled(CompanyTabHeaderItem)`
  width: fit-content;
  opacity: 1;
  color: ${blue};
  background-color: transparent;
  padding: 0 10px 0 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);

  &::after {
    display: none;
  }
`;

export const RemoveCompanyBtn = styled(FontAwesomeIcon)`
  position: absolute;
  top: 3px;
  right: 4px;
  font-family: ${rl};
  color: ${gray};
  font-size: 14px;
`;

export const CompanyTabBody = styled(TabBody)`
  min-height: 500px;
`;

export const CompanyTabItem = styled(TabItem)`
  margin: 0;
  padding-left: 0;
`;

export const LoaderContainerStyled = styled(LoaderContainer)`
  height: 100%;
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

export const PopupContentParticipants = styled(PopupContent)`
  width: 600px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  overflow: auto;
  row-gap: 20px;

  & > span {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 30px;
    font-family: ${rl};
  }

  & > div:first-of-type {
    width: 40%;
  }

  button {
    width: fit-content !important;
    width: 170px;
    height: 20px;
    font-size: 14px;
    justify-content: center;
    :disabled {
      background-color: ${lightGray};
    }
  }
`;

export const WrapperInputDetail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  max-width: 500px;

  span {
    font-size: 12px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
  }

  & > ${InnPopupStyled} {
    top: 75px;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  & > button:first-of-type {
    background-color: red;

    :hover {
      background-color: red;
    }
  }

  & > button:last-of-type {
    background-color: ${lightGray};

    :hover {
      background-color: ${lightGray};
    }
  }
`;

export const ParticipantsInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

export const TitleTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${lighterBlue};
  border-radius: 5px;
  padding: 5px 20px;
  margin: 30px 0 20px -40px;
  height: 60px;

  p {
    margin: 0;
    font-size: 24px;
    color: ${navyBlue};
  }

  svg {
    cursor: pointer;
  }
`;

export const CompanyHead = styled.div`
  margin: 30px 0 20px;

  & > p:first-child {
    font-size: 14px;
  }

  & > p:last-child {
    font-size: 12px;
  }
`;

export const TableWrapper = styled.div`
  margin-left: 25px;
`;

export const Period = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const IsNecessarily = styled.div`
  color: ${({ isUploaded }) => (isUploaded ? '' : 'red')};
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
`;

export const IsUploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 35px;
  background-color: ${({ isUploaded }) => (isUploaded ? '#1CCA94' : '#FFA552')};
  border-radius: 5px;
  padding: 13px 24px;
  font-size: 16px;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;

    p {
      color: white;
      margin: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const UploadButton = styled(ButtonStyled)`
  width: fit-content;
  height: 35px;
  column-gap: 10px;
  padding: 5px 15px;
  background-color: ${({ status }) => status && gray};

  :disabled {
    background-color: ${gray};

    :hover {
      background-color: ${gray};
    }
  }

  p {
    color: white;
    margin: 0;
  }
`;

export const DocumentType = styled(Period)`
  div {
    font-family: ${rr};
  }
`;

export const TooltipBoxStyle = styled.div`
  font-family: ${rr};
  text-overflow: ellipsis;
  overflow: hidden;

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 300px;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  :hover{
    color: ${blue};
  }
`;

export const TDDocumentName = styled.td`
  position: relative;
  overflow: visible !important;

  div {
    font-family: ${rr};
  }

  & > div:first-child {
    font-size: ${({ text }) => text === 'Иной документ' && '12px'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PopupContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 400px;

  & > ${InnPopupStyled} {
    top: 75px !important;
  }

  & > button {
    min-width: fit-content;
    height: 40px;
    margin-top: 20px;
  }
`;

export const BlueContainer = styled(LighterBlueContainer)`
  width: 300px;
`;

export const DownloadFile = styled.p`
  cursor: pointer;
  margin: 0;
  color: ${blue};
`;

export const AddCompanyBtn = styled(({ className }) => (
  <CrossCircleBtn className={className} color={blue} />
))`
  margin-right: 10px;
  transform: rotate(45deg);
  transform-origin: center;
`;
