import styled from 'styled-components';
import { gray, darkGray, lightGray , rr} from 'shared/styled';

export const SectionSeparatorStyled = styled.div`
  padding-top: 5px;
  margin: 5px;
  padding-bottom:5px;
  border-bottom: 1px solid #686F86;

  font-family: ${rr};
  font-size: 14px;
  text-decoration: none;
  color: #686F86;
`
