import * as React from 'react';
import { Title, List, ListItem } from './styles';

interface Props {
  validationStatuses: boolean[];
}

export const PasswordHint: React.FC<Props> = ({
  validationStatuses
}: Props) => {
  const passwordCharEnum = validationStatuses[1];
  const passwordCharCase = validationStatuses[2];
  const passwordLength = validationStatuses[3] && validationStatuses[4];

  return (
    <React.Fragment>
      <Title>Требования к паролю:</Title>
      <List>
        <ListItem isRulePassed={passwordLength}>
          Пароль 8 – 14 символов
        </ListItem>
        <ListItem isRulePassed={passwordCharCase}>
          Строчные и заглавные буквы
        </ListItem>
        <ListItem isRulePassed={passwordCharEnum}>
          Хотя бы один символ: <br /> ( . , : ; ? ! * + % - &#60; &#62; @ [ ] /
          \ _ &#123; &#125; $ # )
        </ListItem>
      </List>
    </React.Fragment>
  );
};
