import { POST_CONFIRM_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/postConfirmContractByDebtor';

export interface RequestData {
  type: number;
  action: 'confirm' | 'reject';
}

export const req = (
  accountGuid: string,
  contractGuid: string,
  data: RequestData
) => ({
  type: POST_CONFIRM_CONTRACT_BY_DEBTOR.REQ,
  accountGuid,
  contractGuid,
  data
});

export const got = () => ({
  type: POST_CONFIRM_CONTRACT_BY_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: POST_CONFIRM_CONTRACT_BY_DEBTOR.ERR,
  error
});
