import { POST_COMPANIES_OF_COMPANIES } from '../reducers/postCompaniesOfCompanies';

export const req = (id: string, companies: string) => ({
  type: POST_COMPANIES_OF_COMPANIES.REQ,
  id,
  companies
});

export const got = () => ({
  type: POST_COMPANIES_OF_COMPANIES.GOT
});

export const err = (error: {}) => ({
  type: POST_COMPANIES_OF_COMPANIES.ERR,
  error
});
