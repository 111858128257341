import styled from 'styled-components';

import { HeaderHeight } from 'Layouts/components/Header/styles';
import { lightGray, blue, rl } from 'shared/styled';

export const StepsIndicatorContainer = styled.div`
  width: 100%;

  top: ${HeaderHeight};
  left: 0;
  z-index: 2;
  position: fixed;

  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
`;

export const StepIndicator = styled.div`
  width: 120px;
  height: 2px;

  margin-right: 4px;

  border-radius: 2px;
  background-color: ${({ isActive }) => (isActive ? blue : lightGray)};
`;

export const StepName = styled.p`
  font-family: ${rl};
  max-width: 110px;
  text-align: center;
  margin: 0;
  color: ${({ isActive }) => (isActive ? blue : lightGray)};
`;
