import { POST_EXTERNAL_CREDENTIAL } from '../reducers/postExternalCredential';

export const req = ({ externalCredentialsId, productId }) => ({
  type: POST_EXTERNAL_CREDENTIAL.REQ,
  externalCredentialsId,
  productId
});

export const got = () => ({
  type: POST_EXTERNAL_CREDENTIAL.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_CREDENTIAL.ERR,
  error
});
