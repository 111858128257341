import { call, put, takeLatest } from 'redux-saga/effects';
import { history } from 'src/shared/utils/History';

import { got, err } from 'Application/actions/deleteApplication';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { req as getApplicationsList } from 'Application/actions/getApplicationsList';

import {
  DELETE_APPLICATION,
  RequestDataType
} from 'Application/reducers/deleteApplication';
import ApplicationApi from 'Application/api';

function* deleteApplication(action: {
  type: DELETE_APPLICATION;
  data: RequestDataType;
}): Generator {
  try {
    const res:any = yield call(ApplicationApi.deleteApplication, action.data);

    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    yield put(got());
    yield put(getApplicationsList({ page, pageSize: 20 }));
    yield put(getApplicationsQuantity());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteApplicationSaga(): Generator {
  yield takeLatest(DELETE_APPLICATION.REQ, deleteApplication);
}
