import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import {
  RequestDataType as DeleteDocumentRequestDataType,
  SagaRequestDataType as DeleteDocumentSagaRequestDataType
} from 'Application/reducers/deleteDocument';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import { DOCUMENT_UPLOAD_TYPE } from 'Application/types';

import { TRANSITION_DIRECTIONS } from 'Common';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationInputsColumn,
  ApplicationFormContent,
  ApplicationBtnsWrapper
} from '../../styles';
import { FactoringFileUploadRow } from 'src/features/BorrowerCompanies/components/BorrowerCompanyFileUploadBlock/Common/FactoringFileUploadRow';
import { getQuarterReportBoundary, getYearlyReportYear } from 'src/shared/utils/Utils';
import { SetInitializationApplicationType } from 'src/features/Application/reducers/setInitializationFormData';
import debts_example_doc from 'assets/documents/ФОРМА Расшифровка Кредиты и Займы.xlsx';
import { Button } from 'shared/ui/Button';

interface State {
  submitClicked: boolean;
}

interface OwnProps {
  path: string;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

interface StateToProps extends SetFactoringApplicationType {
  id: number;
  uploadStatus: REQUEST_STATUSES;
  application: SetInitializationApplicationType;
  permissions: USER_PERMISSIONS[];
  roles: string[];
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
}

type Props = RouteComponentProps<MatchParams> &
  OwnProps &
  StateToProps &
  DispatchToProps;

export const FactoringDocumentsRequired = {
  financial_statements_year: 'financial_statements_year',
  financial_statements_quarter: 'financial_statements_quarter',
  osv_60_year: 'osv_60_year',
  osv_60_quarter: 'osv_60_quarter',
  osv_62_year: 'osv_62_year',
  osv_62_quarter: 'osv_62_quarter',
  osv_66_year: 'osv_66_year',
  osv_66_quarter: 'osv_66_quarter',
  osv_67_year: 'osv_67_year',
  osv_67_quarter: 'osv_67_quarter',
  osv_76_year: 'osv_76_year',
  osv_76_quarter: 'osv_76_quarter',
  factoring_documents: 'factoring_documents',
  debts_form: 'debts_form',
  account_card: 'account_card'
};

const FactoringDocuments = {
  ...FactoringDocumentsRequired,
  other: 'other'
};

class FactoringFormStep3 extends React.Component<Props, State> {
  Validator = createValidaton();

  state = {
    submitClicked: false
  };

  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  getActualId = () => {
    const { id, inBankId } = this.props.match.params;

    if (inBankId) {
      return inBankId;
    } else if (id) {
      return id;
    } else {
      return this.props.id;
    }
  };

  onFilesUpload = files => {
    this.props.uploadDocuments({
      id: +this.getActualId(),
      files,
      type: DOCUMENT_UPLOAD_TYPE.FACTORING
    });
  };

  onFilesRemove = (data: DeleteDocumentRequestDataType) => {
    this.props.deleteDocument({
      ...data,
      subType: DOCUMENT_UPLOAD_TYPE.FACTORING
    });
  };

  goBack = () => {
    this.props.history.push({
      pathname:
        this.props.debtors.length > 0
          ? this.navigateFromTo(this.props.path, 'debtors')
          : this.navigateFromTo(this.props.path, 'options'),
      state: TRANSITION_DIRECTIONS.BACKWARDS
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    const { id, inBankId } = this.props.match.params;
    const { history } = this.props;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    if (inBankId) {
      history.push({
        pathname: `/cabinet/${role}/application/${id}/${inBankId}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    } else if (id) {
      history.push({
        pathname: `/cabinet/${role}/application/${id}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    } else {
      history.push({
        pathname: `/cabinet/${role}/application/${this.props.id}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    }
  };

  getBlocks = () => {
    const quarterBoundaries = getQuarterReportBoundary();
    const quarterRequired = quarterBoundaries.quarter && quarterBoundaries.year;
    const { application, permissions } = this.props;

    return [
      {
        label: 'Бухгалтерская отчетность',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            label: `${getYearlyReportYear()} год `,
            metaInfo: FactoringDocuments.financial_statements_year,
            filename: `${
              application.code
            }_Бух_Годовая_отчетность_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                label: `${quarterBoundaries.quarter} квартал ${
                  quarterBoundaries.year
                } года `,
                metaInfo: FactoringDocuments.financial_statements_quarter,
                filename: `${application.code}_Бух_Отчетность_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'ОСВ 60 счета',
        subLabel: 'по субсчетам и контрагентам с указанием ИНН',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.osv_60_year,
            filename: `${application.code}_ОСВ60_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                metaInfo: FactoringDocuments.osv_60_quarter,
                filename: `${application.code}_ОСВ60_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'ОСВ 62 счета',
        subLabel: 'по субсчетам и контрагентам с указанием ИНН',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.osv_62_year,
            filename: `${application.code}_ОСВ62_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                metaInfo: FactoringDocuments.osv_62_quarter,
                filename: `${application.code}_ОСВ62_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'ОСВ 66 счета',
        subLabel: 'по субсчетам и контрагентам с указанием ИНН',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.osv_66_year,
            filename: `${application.code}_ОСВ66_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                metaInfo: FactoringDocuments.osv_66_quarter,
                filename: `${application.code}_ОСВ66_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'ОСВ 67 счета',
        subLabel: 'по субсчетам и контрагентам с указанием ИНН',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.osv_67_year,
            filename: `${application.code}_ОСВ67_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                metaInfo: FactoringDocuments.osv_67_quarter,
                filename: `${application.code}_ОСВ67_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'ОСВ 76 счета',
        subLabel: 'по субсчетам и контрагентам с указанием ИНН',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.osv_76_year,
            filename: `${application.code}_ОСВ76_${getYearlyReportYear()}`
          },
          quarterRequired
            ? {
                metaInfo: FactoringDocuments.osv_76_quarter,
                filename: `${application.code}_ОСВ76_${
                  quarterBoundaries.year
                }-${quarterBoundaries.quarter}_кв`
              }
            : {}
        ]
      },
      {
        label: 'Договоры, по которым требуется факторинг',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            multifile: true,
            metaInfo: FactoringDocuments.factoring_documents
          }
        ]
      },
      {
        label: 'Расшифровка кредитов и займов по форме',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.debts_form,
            filename: `${application.code}_Кредиты_и_займы`
          }
        ],
        exampleDocument: {
          name: 'ФОРМА расшифровки',
          link: debts_example_doc
        }
      },
      {
        label: 'Карточка 51 счета за последние 12 месяцев',
        required: permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
          ? false
          : true,
        fileInputs: [
          {
            metaInfo: FactoringDocuments.account_card,
            filename: `${application.code}_Карточка_51сч_12месяцев`
          }
        ]
      },
      {
        label: 'Другие документы',
        subLabel: 'Необязательное поле',
        fileInputs: [
          {
            metaInfo: FactoringDocuments.other,
            multifile: true
          }
        ]
      }
    ];
  };
  render() {
    const { openModal, permissions, roles } = this.props;
    const { errors } = this.Validator.insertArgs({
      permissionsAndRoles: [permissions, roles]
    }).validate(this.props);

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>Документы компании зaёмщика</ApplicationStepTitle>

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <p>Финансовые документы: </p>
            <FactoringFileUploadRow blocks={this.getBlocks()} errors={errors} />
            <ApplicationBtnsWrapper>
              <Button template="backBtn" label="Назад" onClick={this.goBack} />
              <Button
                template="nextBtn"
                label="Оформить заявку"
                onClick={this.onSubmit}
              />
            </ApplicationBtnsWrapper>
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ User, Application }: STORE) => ({
  id: Application.setInitializationFormData.id,
  application: Application.setInitializationFormData,
  debtors: Application.setFactoringFormData.debtors,
  documents: Application.setFactoringFormData.documents,
  uploadStatus: Application.uploadDocuments.status,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      uploadDocuments,
      deleteDocument
    },
    dispatch
  );

const FactoringFormStep3Connect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep3)
);

export { FactoringFormStep3Connect as FactoringFormStep3 };
