import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { SetPropertyFinancingFormData } from 'src/features/Application/reducers/setPropertyFinancingFormData';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import { SagaRequestDataType as DeleteDocumentSagaRequestDataType } from 'Application/reducers/deleteDocument';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import { setPropertyFinancingFormData } from 'src/features/Application/actions/setPropertyFinancingFormData';
import {
  Document,
  DOCUMENT_UPLOAD_TYPE,
  PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE,
  PROPERTY_FINANCING_CLIENT_TYPE
} from 'src/features/Application/types';
import { LighterBlueContainer } from 'shared/styled/layouts';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { InputRange, INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import {
  InnPlaceholders,
  PropertyFinancingClientIncomeConfirmationType,
  PropertyFinancingClientType
} from 'shared/constants';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { createValidaton } from './validator';
import { Button } from 'src/shared/ui/Button';

interface StateToProps extends SetPropertyFinancingFormData {
  applicationDocuments: Document[];
  documentsUploadStatus: REQUEST_STATUSES;
  applicationId: number;
}

interface State {}

interface OwnProps {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

interface DispatchToProps {
  setPropertyFinancingFormData: (data: SetPropertyFinancingFormData) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ClientData extends React.Component<Props, State> {
  Validator = createValidaton();

  // TODO duplication extract
  findDocumentByMetaInfo = (metaInfo: string) => {
    return this.props.applicationDocuments.find(
      doc => doc.metaInfo === metaInfo
    );
  };

  onFileUploadWithMetaInfo = (metaInfo: string, file) => {
    file.metaInfo = metaInfo;
    this.onFileUpload(file);
  };

  // TODO refactor
  onFileUpload = file => {
    const { uploadDocuments, applicationId } = this.props;
    uploadDocuments({
      id: applicationId,
      files: [file],
      type: DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING
    });
  };

  onFileRemove = file => {
    const { deleteDocument } = this.props;
    deleteDocument({
      id: file.id,
      type: file.type,
      subType: DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING
    });
  };
  componentWillMount() {
    this.Validator.showAllErrors();
  }

  // TODO refactor
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber = name === 'clientAverageMonthlyIncome';
    const isCheckbox = type === 'checkbox';
    const floated = toFloatFormatter(value);
    this.props.setPropertyFinancingFormData({
      [name]: isCheckbox ? checked : isToNumber ? floated : value
    });
  };

  getClientIncomeConfirmationTypeRadioBtns = () => {
    const { clientType } = this.props;
    let removedFields = [];
    if (clientType === PROPERTY_FINANCING_CLIENT_TYPE.INDIVIDUAL)
      removedFields = [
        PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.THREE_NDFL,
        PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TURNOVER_51_REPORT
      ];
    if (clientType === PROPERTY_FINANCING_CLIENT_TYPE.SOLE_PROPRIETOR)
      removedFields = [
        PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.BANK_CARD_REPORT,
        PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.EMPLOYER_NOTE,
        PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TWO_NDFL
      ];
    return Object.keys(PropertyFinancingClientIncomeConfirmationType).without(
      removedFields
    );
  };
  stopEditing = () => {
    this.props.setIsEditing(false);
  };

  startEditing = () => {
    this.props.setIsEditing(true);
  };

  render() {
    const {
      inn,
      clientType,
      clientIncomeConfirmationType,
      clientAverageMonthlyIncome,
      client,
      documentsUploadStatus
    } = this.props;

    const amountRange = {
      min: 0,
      max: 100000000000
    };
    this.Validator.showAllErrors();
    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max],
      clientType,
      clientIncomeConfirmationType
    }).validate(this.props);

    return this.props.isEditing ? (
      <LighterBlueContainer>
        <p>Сведения о заемщике *</p>
        <RadioButtonGroup
          name="clientType"
          keyValue={clientType}
          onChange={this.onChange}
          radioBtns={Object.keys(PropertyFinancingClientType).map(type => ({
            value: type,
            label: PropertyFinancingClientType[type]
          }))}
        />
        {clientType !== PROPERTY_FINANCING_CLIENT_TYPE.NONE && (
          <>
            <Input
              value={client}
              label={
                [
                  PROPERTY_FINANCING_CLIENT_TYPE.INDIVIDUAL,
                  PROPERTY_FINANCING_CLIENT_TYPE.SOLE_PROPRIETOR
                ].includes(clientType)
                  ? 'ФИО клиента'
                  : 'Наименование клиента'
              }
              name="client"
              error={errors.client}
              required={true}
              onChange={this.onChange}
            />

            {clientType !== PROPERTY_FINANCING_CLIENT_TYPE.INDIVIDUAL && (
              <Input
                value={inn}
                error={errors.inn}
                label="ИНН"
                name="inn"
                placeholder={
                  clientType === PROPERTY_FINANCING_CLIENT_TYPE.ENTITY
                    ? InnPlaceholders.entity
                    : InnPlaceholders.individual
                }
                required={true}
                onChange={this.onChange}
              />
            )}

            {[
              PROPERTY_FINANCING_CLIENT_TYPE.INDIVIDUAL,
              PROPERTY_FINANCING_CLIENT_TYPE.SOLE_PROPRIETOR
            ].includes(clientType) && (
              <InputRange
                value={clientAverageMonthlyIncome.toFixed(2)}
                label="Среднемесячный доход"
                error={errors.clientAverageMonthlyIncome}
                name="clientAverageMonthlyIncome"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                disableSlider={true}
                required={true}
                onChange={this.onChange}
              />
            )}

            {clientType !== PROPERTY_FINANCING_CLIENT_TYPE.ENTITY && (
              <RadioButtonGroup
                name="clientIncomeConfirmationType"
                keyValue={clientIncomeConfirmationType}
                error={errors.clientIncomeConfirmationType}
                onChange={this.onChange}
                radioBtns={this.getClientIncomeConfirmationTypeRadioBtns().map(
                  type => ({
                    value: type,
                    label: PropertyFinancingClientIncomeConfirmationType[type]
                  })
                )}
              />
            )}

            {clientIncomeConfirmationType ===
              PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TWO_NDFL && (
              <InputSingleFile
                label="Справка 2-НДФЛ"
                renameFileTo={`${client}_Справка_2НДФЛ`}
                required={true}
                error={errors.propertyFinancingDocuments}
                file={this.findDocumentByMetaInfo('two_ndfl')}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('two_ndfl', file)
                }
                onFileRemove={this.onFileRemove}
              />
            )}

            {clientIncomeConfirmationType ===
              PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.EMPLOYER_NOTE && (
              <InputSingleFile
                label="Справка работодателя"
                error={errors.propertyFinancingDocuments}
                required={true}
                renameFileTo={`${client}_Справка_Работодателя`}
                file={this.findDocumentByMetaInfo('employer_note')}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('employer_note', file)
                }
                onFileRemove={this.onFileRemove}
              />
            )}

            {clientIncomeConfirmationType ===
              PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.THREE_NDFL && (
              <InputSingleFile
                label="Справка 3-НДФЛ"
                required={true}
                error={errors.propertyFinancingDocuments}
                renameFileTo={`${inn}_Справка_3НДФЛ`}
                file={this.findDocumentByMetaInfo('three_ndfl')}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('three_ndfl', file)
                }
                onFileRemove={this.onFileRemove}
              />
            )}

            {(clientIncomeConfirmationType ===
              PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TURNOVER_51_REPORT ||
              clientType === PROPERTY_FINANCING_CLIENT_TYPE.ENTITY) && (
              <InputSingleFile
                label="Карточка 51 счета за последние 12 месяцев в формате Excel"
                required={true}
                error={errors.propertyFinancingDocuments}
                renameFileTo={`${inn}_Карточка_51счета`}
                file={this.findDocumentByMetaInfo('turnover')}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('turnover', file)
                }
                onFileRemove={this.onFileRemove}
              />
            )}

            {clientIncomeConfirmationType ===
              PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.BANK_CARD_REPORT && (
              <InputSingleFile
                label="Выписка по банковской карте за последние 12 месяцев"
                required={true}
                error={errors.propertyFinancingDocuments}
                file={this.findDocumentByMetaInfo('bank_card_report')}
                uploadStatus={documentsUploadStatus}
                onFileUpload={file =>
                  this.onFileUploadWithMetaInfo('bank_card_report', file)
                }
                onFileRemove={this.onFileRemove}
              />
            )}

            <Button
              label="Готово"
              template="nextBtn"
              disabled={!this.Validator.isFormValid()}
              onClick={() => this.stopEditing()}
            />
          </>
        )}
      </LighterBlueContainer>
    ) : (
      <LighterBlueContainer>
        <p>Сведения о заемщике</p>
        <p>{client}</p>
        <Button label="Редактировать" onClick={() => this.startEditing()} />
      </LighterBlueContainer>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  applicationDocuments:
    Application.setPropertyFinancingFormData.propertyFinancingDocuments,
  documentsUploadStatus: Application.uploadDocuments.status,
  applicationId: Application.setInitializationFormData.id,
  ...Application.setPropertyFinancingFormData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPropertyFinancingFormData,
      uploadDocuments,
      deleteDocument
    },
    dispatch
  );

const ClientDataConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ClientData);

export { ClientDataConnect as ClientData };
