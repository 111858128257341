import { GET_ACTIVITIES_BY_ID } from 'src/features/Activities/reducers/getActivitiesById';
import { Activities } from 'src/features/Activities/types';

export const req = (id: string) => ({
  type: GET_ACTIVITIES_BY_ID.REQ,
  id
});

export const got = (data: Activities) => ({
  type: GET_ACTIVITIES_BY_ID.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_ACTIVITIES_BY_ID.ERR,
  error
});
