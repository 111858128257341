import { GET_EXTERNAL_API_GIR_BO } from 'Application/reducers/getExternalApiGIR_BO';
import { GIR_BO_ITEMS } from '../types/ExternalApiGIR_BO';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: GIR_BO_ITEMS[];
}

export const req = (inn: string, year: number) => ({
  type: GET_EXTERNAL_API_GIR_BO.REQ,
  inn,
  year
});

export const got = (data: ResponseData) => ({
  type: GET_EXTERNAL_API_GIR_BO.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_API_GIR_BO.ERR,
  error
});
