import {
  GET_SUPPLIER_COUNTER_SIDEBAR,
  ResponseData
} from 'entities/SCF/Supplier/model/reducers/getSupplierCounterSidebar';

export const req = () => ({
  type: GET_SUPPLIER_COUNTER_SIDEBAR.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_SUPPLIER_COUNTER_SIDEBAR.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_SUPPLIER_COUNTER_SIDEBAR.ERR,
  error
});
