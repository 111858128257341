import { DELETE_CONTACT } from '../reducers/deleteContact';

export const req = (id: number, reason: string) => ({
  type: DELETE_CONTACT.REQ,
  id,
  reason
});

export const got = data => ({
  type: DELETE_CONTACT.GOT,
  data
});

export const err = (error: {}) => ({
  type: DELETE_CONTACT.ERR,
  error
});
