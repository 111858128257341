import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/postUploadFinDocClient';

import { POST_UPLOAD_FIN_DOC_CLIENT } from 'entities/Cabinet/Client/model/reducers/postUploadFinDocClient';
import ClientApi from 'entities/Cabinet/Client/api';

function* postUploadFinDocClient(action: {
  type: POST_UPLOAD_FIN_DOC_CLIENT;
  data: FormData;
  guid: string;
}): Generator {
  try {
    yield call(ClientApi.postUploadFinDocClient, action.guid, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postUploadFinDocClientSaga(): Generator {
  yield takeLatest(POST_UPLOAD_FIN_DOC_CLIENT.REQ, postUploadFinDocClient);
}
