import * as React from 'react';
import { LabelStyled } from './styles';

interface LabelProps {
  name?: string;
  label?: string | JSX.Element;
  id?: string;
  required?: boolean;
  className?: string;
  children?: any;
}

export const Label = ({
  name,
  label,
  id,
  required,
  className,
  children
}: LabelProps) => (
  <React.Fragment>
    {!!label && (
      <LabelStyled
        id={id}
        htmlFor={name}
        required={required}
        className={!!className ? className : ''}
      >
        {!!children && children}
        {label}
      </LabelStyled>
    )}
  </React.Fragment>
);
