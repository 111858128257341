import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { convertKbToMb } from 'src/shared/utils/Utils';
import { ApplicationTypes } from 'shared/constants';
import {
  APPLICATION_STATUSES,
  APPLICATION_TYPE_ROUTES,
  BorrowerCompany,
  ContractFinancingRead,
  Document
} from 'Application/types';
import ApplicationApi from 'Application/api';

import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';

import { animation, StepsTransitionGroup, TRANSITION_DIRECTIONS } from 'Common';

import { DownloadLink } from 'shared/styled';
import {
  EditApplicationBtn,
  ViewSeparatorBottom
} from 'Application/components/ApplicationView/EachApplicationView/styles';

import { CompanyTabsStyled } from 'BorrowerCompanies/components/BorrowerCompaniesTabs/styles';

import { CompanyDocumentsTtitle, EditDocumentsLink } from '../styles';
import { CompanyDocumentsBlockStyled, ViewCompanyTabBody } from './styles';
import { BorrowerCompanyDocumentHeader } from './BorrowerCompanyDocumentHeader/BorrowerCompanyDocumentHeader';
import { BorrowerCompanyDocumentTab } from './BorrowerCompanyDocumentTab/BorrowerCompanyDocumentTab';
import { WarningBlock } from '../WarningBlock/WarningBlock';

export interface State {
  activeTabIndex: number;
  animationDirection: TRANSITION_DIRECTIONS;
}

interface StateToProps {
  clientApplication: Partial<ContractFinancingRead>;
}

interface OwnProps {
  companies: BorrowerCompany[];
  permissions: USER_PERMISSIONS[];
  documentList: ResDocumentListByApplicationId;
}

type Props = RouteComponentProps & StateToProps & OwnProps;

const BorrowerCompanyDocumentsBlock: React.FC<Props> = ({
  clientApplication,
  companies,
  documentList,
  permissions,
  history
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [animationDirection, setAnimationDirection] = React.useState(
    TRANSITION_DIRECTIONS.FORWARD
  );

  const changeTab = (index: number) => {
    setActiveTabIndex(index);
    setAnimationDirection(
      index < activeTabIndex
        ? TRANSITION_DIRECTIONS.BACKWARDS
        : TRANSITION_DIRECTIONS.FORWARD
    );
  };

  const formTableRows = (documents: Document[]) =>
    documents.map((document, index) => ({
      id: document.id,
      data: [
        index + 1,
        document.name,
        format(new Date(document.createdDate), 'dd/MM/yyyy'),
        convertKbToMb(document.size),
        <DownloadLink href={ApplicationApi.getDownloadDocumentLink(document)} />
      ]
    }));

  const canEdit = (): boolean => {
    if (permissions.includes(USER_PERMISSIONS.CONTROL_ANY_APPLICATION)) {
      if (
        clientApplication.status === APPLICATION_STATUSES.DRAFT ||
        clientApplication.status === APPLICATION_STATUSES.DOCS_REQUEST ||
        clientApplication.status === APPLICATION_STATUSES.IN_WORK
      )
        return true;
    } else {
      if (clientApplication.isAuthor) {
        if (
          clientApplication.status === APPLICATION_STATUSES.DRAFT ||
          clientApplication.status === APPLICATION_STATUSES.AWAITING
        )
          return true;
      }
    }
    return false;
  };

  const activeCompany = companies[activeTabIndex];
  const rows =
    companies.length > 0 ? formTableRows(activeCompany.documents) : [];

  return companies.length > 0 ? (
    <CompanyDocumentsBlockStyled>
      <CompanyTabsStyled>
        <BorrowerCompanyDocumentHeader
          activeTabIndex={activeTabIndex}
          animationDirection={animationDirection}
          companies={companies}
          changeTab={changeTab}
        />

        {!!activeCompany && (
          <StepsTransitionGroup
            {...animation(animationDirection, { duration: 200 })}
            component={ViewCompanyTabBody}
            pageKey={activeTabIndex}
          >
            <BorrowerCompanyDocumentTab
              activeCompany={activeCompany}
              rows={rows}
              documentList={documentList}
            />
          </StepsTransitionGroup>
        )}
      </CompanyTabsStyled>
    </CompanyDocumentsBlockStyled>
  ) : (
    <React.Fragment>
      <ViewSeparatorBottom>
        <EditApplicationBtn
          onClick={() =>
            history.push(
              `/cabinet/client/application-form-${APPLICATION_TYPE_ROUTES[
                clientApplication.financingType
              ].replace('_', '-')}/edit/${clientApplication.id}/documents`
            )
          }
        >
          Редактировать документы
        </EditApplicationBtn>
      </ViewSeparatorBottom>

      {canEdit() && (
        <React.Fragment>
          <CompanyDocumentsTtitle>
            Документы компаний заемщиков
          </CompanyDocumentsTtitle>

          <WarningBlock
            text={[
              'Вы не добавили ни одной компании заемщика.',
              <React.Fragment>
                Чтобы добавить компанию, нажмите "
                <EditDocumentsLink
                  to={`/cabinet/client/application-form-${APPLICATION_TYPE_ROUTES[
                    clientApplication.financingType
                  ].replace('_', '-')}/edit/${clientApplication.id}/documents`}
                >
                  Редактировать документы
                </EditDocumentsLink>
                ".
              </React.Fragment>,
              `Заявки типа "${
                ApplicationTypes[clientApplication.financingType]
              }"
                          без компаний заемщика банками к рассмотрению не
                          принимаются.`
            ]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ ClientRole }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data
});

const BorrowerCompanyDocumentsBlockConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(BorrowerCompanyDocumentsBlock)
);

export {
  BorrowerCompanyDocumentsBlockConnect as BorrowerCompanyDocumentsBlock
};
