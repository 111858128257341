import styled from 'styled-components';
import { blue, darkBlue, rl, rr } from 'shared/styled';

export const ContentContainer = styled.section`
  margin: 100px 0 0 150px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const MainTitle = styled.h2`
  font-family: ${rl};
  font-size: 28px;
  margin-left: 50px;
`;

export const ItemsBlock = styled.div`
  width: auto;
  height: auto;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ItemRow = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const ItemRowText = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  p {
    font-size: 18px;
    font-family: ${rl};
  }
`;

export const RowLink = styled.p`
  display: inline-block;
  cursor: pointer;
  font-family: ${rr};
  color: ${blue};
  transition: 0.3s;
  &:hover {
    color: ${darkBlue};
  }
`;

export const IconContainer = styled.div`
  width: 69px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
