import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ResponseData as ResponseCreditProducts } from 'src/features/BankProducts/actions/getCreditProduct';
import { ApplicationTypes } from 'shared/constants';
import { TRWrapper } from 'src/features/BankProducts/components/Tabs/CreditProducts/styled';

interface StateToProps {
  creditProducts: ResponseCreditProducts;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {}

interface DispatchToProps {}

interface MatchParams {}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class CreditProducts extends React.Component<Props, State> {
  navigateToCompaniesProduct = (clientInn: string) => {
    window.open(`/crm/companies/${clientInn}/bank_products`, '_blank');
  };

  render() {
    const { creditProducts, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="10%">ИНН клиента</TableThStyled>
                  <TableThStyled width="10%">Наименование</TableThStyled>
                  <TableThStyled width="10%">Продукт</TableThStyled>
                  <TableThStyled width="50%">Тип продукта</TableThStyled>
                  <TableThStyled width="10%">Ставка</TableThStyled>
                  <TableThStyled width="10%">Источник данных</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {creditProducts.items.map((product, index) => (
                  <TRWrapper
                    key={index}
                    isActive={product.isActive}
                    onClick={() =>
                      this.navigateToCompaniesProduct(product.clientInn)
                    }
                  >
                    <td>{product.clientInn}</td>
                    <td>{product.clientName}</td>
                    <td>{product.productName}</td>
                    <td>
                      {
                        Object.values(ApplicationTypes)[
                          product.productTypeID - 1
                        ]
                      }
                    </td>
                    <td>{product.rate ? product.rate : 'Неизвестно'}</td>
                    <td>{product.source}</td>
                  </TRWrapper>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={creditProducts} />
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const CreditProductsConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreditProducts)
);

export { CreditProductsConnect as CreditProducts };
