export enum GET_DOWNLOAD_NON_FIN_DOCUMENTS {
  REQ = 'GET_DOWNLOAD_NON_FIN_DOCUMENTS_REQ',
  GOT = 'GET_DOWNLOAD_NON_FIN_DOCUMENTS_GOT',
  ERR = 'GET_DOWNLOAD_NON_FIN_DOCUMENTS_ERR'
}

export interface RequestData {
  fileStream: {
    canRead: boolean;
    canSeek: boolean;
    canWrite: boolean;
    capacity: number;
    length: number;
    position: number;
    canTimeout: boolean;
  };
}

export const req = (guid: string) => ({
  type: GET_DOWNLOAD_NON_FIN_DOCUMENTS.REQ,
  guid
});

export const got = (data: RequestData) => ({
  type: GET_DOWNLOAD_NON_FIN_DOCUMENTS.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_DOWNLOAD_NON_FIN_DOCUMENTS.ERR,
  error
});
