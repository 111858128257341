import styled from 'styled-components';
import { rr } from 'shared/styled';
import { Button } from 'shared/ui/Button';

export const FactoringCheckerSubmitButton = styled(Button)`
  margin-top: 25px;
`;

export const FactorList = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-left: 20px;
    font-family: ${rr};
  }
`;
