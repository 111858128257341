import { ResponseDataType, GET_COMPANY_INN } from './../reducers/getCompanyInn';

export const req = () => ({
  type: GET_COMPANY_INN.REQ
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMPANY_INN.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_INN.ERR,
  error
});
