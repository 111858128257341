import styled from 'styled-components';
import { blue, lighterBlue, rr } from 'shared/styled';

export const InnFilter = styled.div`
  width: auto;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 7px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const ListSection = styled.section`
  margin-top: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StatusArea = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const StatusAreaRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LinkNotSet = styled.div`
  color: ${blue};
  text-decoration: underline;
`;

export const DiscountMargin = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  button {
    width: fit-content;
    height: 40px;
  }

  & > div:first-of-type {
    width: 25%;
  }

  input {
    height: 40px;
  }

  & > div:last-of-type {
    font-size: 34px;
    color: ${blue};
  }
`;

export const CheckIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(28, 202, 148)" d="M10.75 18.25L19.5625 9.4375L17.8125 7.6875L10.75 14.75L7.1875 11.1875L5.4375 12.9375L10.75 18.25ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3592 4.78125 22.4688 3.65625 21.3438C2.53125 20.2188 1.64083 18.8958 0.985 17.375C0.328333 15.8542 0 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64083 6.10417 2.53125 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64042 7.625 0.98375C9.14583 0.327917 10.7708 0 12.5 0C14.2292 0 15.8542 0.327917 17.375 0.98375C18.8958 1.64042 20.2188 2.53125 21.3438 3.65625C22.4688 4.78125 23.3592 6.10417 24.015 7.625C24.6717 9.14583 25 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3592 18.8958 22.4688 20.2188 21.3438 21.3438C20.2188 22.4688 18.8958 23.3592 17.375 24.015C15.8542 24.6717 14.2292 25 12.5 25Z" />
  </svg>
  ');
`;

export const IconPopup = styled.div`
  position: absolute;
  margin-top: 9px;
  margin-left: 30px;
  background-color: #fafafa;
  padding: 5px 10px 5px 10px;
  border: 1px solid #e3e4e9;
  border-radius: 5px;
  z-index: 3;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    font-family: ${rr};
    font-size: 0.8em;
    width: auto;
  }
`;

export const GreyIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <circle fill="rgb(217, 217, 217)" cx="12.5" cy="12.5" r="12.5" />
  </svg>
  ');
`;

export const TimesIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 0, 0)" d="M15 0C6.71371 0 0 6.71371 0 15C0 23.2863 6.71371 30 15 30C23.2863 30 30 23.2863 30 15C30 6.71371 23.2863 0 15 0ZM22.3548 18.9375C22.6391 19.2218 22.6391 19.6815 22.3548 19.9657L19.9597 22.3548C19.6754 22.6391 19.2157 22.6391 18.9315 22.3548L15 18.3871L11.0625 22.3548C10.7782 22.6391 10.3185 22.6391 10.0343 22.3548L7.64516 19.9597C7.36089 19.6754 7.36089 19.2157 7.64516 18.9315L11.6129 15L7.64516 11.0625C7.36089 10.7782 7.36089 10.3185 7.64516 10.0343L10.0403 7.63911C10.3246 7.35484 10.7843 7.35484 11.0685 7.63911L15 11.6129L18.9375 7.64516C19.2218 7.36089 19.6815 7.36089 19.9657 7.64516L22.3609 10.0403C22.6452 10.3246 22.6452 10.7843 22.3609 11.0685L18.3871 15L22.3548 18.9375Z" />
  </svg>
  ');
`;

export const ConfirmContainer = styled.div`
  display: flex;
  height: 30px;
  width: 90px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #1cca94;
  cursor: pointer;
  transition: 0.3s;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    color: #ffffff;
  }
  &:hover {
    background-color: #3de394;
  }
`;

export const DeclineContainer = styled.div`
  display: flex;
  height: 30px;
  width: 90px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f53737;
  cursor: pointer;
  transition: 0.3s;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    color: #ffffff;
  }
  &:hover {
    background-color: #b33737;
  }
`;

export const HandleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;
