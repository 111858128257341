import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { formBackendDateString, formSumString } from 'src/shared/utils/Utils';
import { ApplicationTypes } from 'shared/constants';
import { STORE, USER_PERMISSIONS } from 'globaltypes';
import { ResponseDataType as ApplicationsListDataType } from 'Application/actions/getApplicationsList';
import { APPLICATION_LIST_VIEWS } from 'Application/reducers/setApplicationListView';
import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';
import {
  APPLICATION_STATUSES,
  APPLICATION_TYPE_ROUTES,
  ApplicationRead,
  BankApplication
} from 'Application/types';
import {
  ApplicationsTableView,
  ApplicationStatus,
  ApplicationsTileView,
  ApplicationSubmenu,
  ApplicationViewToggleBtn,
  ProfileInfoPopup
} from 'Application/components';

interface OwnProps {
  listType: APPLICATIONS_LIST_TYPE;
  applicationsList: ApplicationsListDataType;
}

interface StateToProps {
  viewType: APPLICATION_LIST_VIEWS;
  permissions: USER_PERMISSIONS[];
}

export interface FormattedApplicationObj {
  id: string;
  client?: JSX.Element;
  internalAgent?: JSX.Element | string;
  companyName: string;
  financingType: string;
  amount: string;
  modifiedAt: string;
  status: JSX.Element;
  submenu: JSX.Element;
  bankApplications: BankApplication[];
  bankApplicationsFormatted: FormattedBankApplication[];
}

export interface FormattedBankApplication {
  readonly id: number;
  name: string;
  status: JSX.Element;
  externalVisibility: number;
  amount: string;
  modifiedAt: string;
}

type Props = RouteComponentProps & OwnProps & StateToProps;

const ApplicationsViewRenderer: React.FC<Props> = ({
  applicationsList,
  viewType,
  listType,
  permissions,
  location,
  history
}) => {
  const onApplicationClick = (id: string, applicationType: string): void => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    const typeIndex = Object.values(ApplicationTypes).indexOf(applicationType);
    const applicationRoute = Object.keys(ApplicationTypes)[typeIndex];

    if (
      APPLICATION_TYPE_ROUTES[applicationRoute] !==
        APPLICATION_TYPE_ROUTES.GUARANTEES &&
      APPLICATION_TYPE_ROUTES[applicationRoute] !==
        APPLICATION_TYPE_ROUTES.CONTRACT_FINANCING &&
      APPLICATION_TYPE_ROUTES[applicationRoute] !==
        APPLICATION_TYPE_ROUTES.CREDITING &&
      APPLICATION_TYPE_ROUTES[applicationRoute] !==
        APPLICATION_TYPE_ROUTES.FACTORING
    ) {
      history.push(`/cabinet/${role}/application/${id}`);
    } else {
      history.push(
        `/cabinet/${role}/application-${APPLICATION_TYPE_ROUTES[
          applicationRoute
        ].replace('_', '-')}/${id}`
      );
    }
  };

  const formApplicationsData = (): FormattedApplicationObj[] => {
    return applicationsList.items.map(applicationToView);
  };

  const applicationToView = (application: ApplicationRead) => {
    const viewItem: FormattedApplicationObj = {
      id: application.id.toString(),
      companyName: application.clientCompanyName,
      financingType: ApplicationTypes[application.financingType],
      amount: formSumString(application.amount),
      bankApplications: application.hasOwnProperty('bankApplications')
        ? [...application.bankApplications]
        : undefined,
      bankApplicationsFormatted: application.hasOwnProperty('bankApplications')
        ? application.bankApplications.map(bank => {
            const bankFormatted: FormattedBankApplication = {
              id: bank.id,
              name: bank.name,
              externalVisibility: bank.externalVisibility,
              amount: formSumString(bank.amount),
              modifiedAt: formBackendDateString(bank.modifiedAt),
              status: (
                <ApplicationStatus
                  id={bank.id}
                  status={chooseStatus(bank.status, [])}
                  financingType={application.financingType}
                  listType={listType}
                  onlyStatus={listType === APPLICATIONS_LIST_TYPE.MY}
                />
              )
            };
            return bankFormatted;
          })
        : undefined,
      modifiedAt:
        application.modifiedAt !== null &&
        formBackendDateString(
          !!application.modifiedAt
            ? application.modifiedAt
            : application.createdAt
        ),
      status: (
        <ApplicationStatus
          id={application.id}
          financingType={application.financingType}
          status={chooseStatus(
            application.status,
            application.bankApplications
          )}
          listType={listType}
          onlyStatus={listType === APPLICATIONS_LIST_TYPE.MY}
        />
      ),
      submenu: (
        <ApplicationSubmenu
          id={application.id}
          status={application.status}
          isAuthor={application.isAuthor}
        />
      )
    };

    if (application.client) {
      viewItem.client = (
        <ProfileInfoPopup showRole={true} person={application.client} />
      );
    }

    if (application.internalAgent && shouldSeeInternalAgent(permissions)) {
      viewItem.internalAgent = application.internalAgent ? (
        <ProfileInfoPopup person={application.internalAgent} />
      ) : (
        'Не назначен'
      );
    }

    return viewItem;
  };

  const shouldSeeInternalAgent = (permissions: USER_PERMISSIONS[]) => {
    return (
      [
        USER_PERMISSIONS.SEE_ALL_APPLICATIONS,
        USER_PERMISSIONS.EXTERNAL_AGENT
      ].isIn(permissions) ||
      (!permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
        permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS))
    );
  };

  const chooseStatus = (originalStatus, bankApplications) => {
    if (!bankApplications) return originalStatus;

    const { status } = bankApplications.reduce(
      (obj, application) => {
        switch (application.status) {
          case APPLICATION_STATUSES.DOCS_REQUEST:
            obj.docsRequest = true;
            obj.status = APPLICATION_STATUSES.DOCS_REQUEST;
            return obj;
          case APPLICATION_STATUSES.SUCCESS:
            obj.approved = true;
            if (!obj.docsRequest) {
              obj.status = APPLICATION_STATUSES.SUCCESS;
            }
            break;
          case APPLICATION_STATUSES.AGENT_APPROVED:
            obj.approved = true;
            if (!obj.docsRequest) {
              obj.status = APPLICATION_STATUSES.AGENT_APPROVED;
            }
            break;
          case APPLICATION_STATUSES.REVIEW:
          case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
            obj.transferredToBank = true;

            if (!obj.docsRequest && !obj.approved) {
              obj.status = APPLICATION_STATUSES.TRANSFERRED_TO_BANK;
            }

            break;
          case APPLICATION_STATUSES.BANK_REJECT:
            obj.bankReject = true;
            if (!obj.docsRequest && !obj.approved && !obj.transferredToBank) {
              obj.status = APPLICATION_STATUSES.BANK_REJECT;
            }
        }

        return obj;
      },
      {
        docsRequest: false,
        approved: false,
        transferredToBank: false,
        bankReject: false,
        status: null
      }
    );

    return !!status ? status : originalStatus;
  };

  return (
    <React.Fragment>
      <ApplicationViewToggleBtn />
      {viewType === APPLICATION_LIST_VIEWS.TILE && (
        <ApplicationsTileView
          list={formApplicationsData()}
          onApplicationClick={onApplicationClick}
        />
      )}

      {viewType === APPLICATION_LIST_VIEWS.TABLE && (
        <ApplicationsTableView
          list={formApplicationsData()}
          permissions={permissions}
          onApplicationClick={onApplicationClick}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ Application, User }: STORE) => ({
  viewType: Application.setApplicationListView.view,
  permissions: User.getUserData.data.permissions
});

const ApplicationsViewRendererConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(ApplicationsViewRenderer)
);

export {
  ApplicationsViewRendererConnect as ApplicationsViewRenderer,
  ApplicationsViewRenderer as ApplicationsViewRendererForTesting
};
