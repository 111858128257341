export enum GET_DOWNLOAD_DEBTOR_REGISTRIES {
  REQ = 'GET_DOWNLOAD_DEBTOR_REGISTRIES_REQ',
  GOT = 'GET_DOWNLOAD_DEBTOR_REGISTRIES_GOT',
  ERR = 'GET_DOWNLOAD_DEBTOR_REGISTRIES_ERR'
}

export const req = (id: number) => ({
  type: GET_DOWNLOAD_DEBTOR_REGISTRIES.REQ,
  id
});

export const got = () => ({
  type: GET_DOWNLOAD_DEBTOR_REGISTRIES.GOT
});

export const err = (error = {}) => ({
  type: GET_DOWNLOAD_DEBTOR_REGISTRIES.ERR,
  error
});
