import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import {
  SetInitializationApplicationType,
  initialState as SetInitializationApplicationTypeInitialState
} from 'Application/reducers/setInitializationFormData';

export interface UpdateInitializationFormDataStoreState extends Reducer {
  data: SetInitializationApplicationType;
}

export enum UPDATE_INITIALIZATION_FORM_DATA {
  REQ = 'UPDATE_INITIALIZATION_FORM_DATA_REQUEST',
  GOT = 'UPDATE_INITIALIZATION_FORM_DATA_GOT',
  ERR = 'UPDATE_INITIALIZATION_FORM_DATA_ERROR',
  RESET = 'UPDATE_INITIALIZATION_FORM_DATA_RESET'
}

export const initialState: UpdateInitializationFormDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: SetInitializationApplicationTypeInitialState,
  error: {}
};

const updateInitializationFormData = (
  state: UpdateInitializationFormDataStoreState = initialState,
  action: {
    type: UPDATE_INITIALIZATION_FORM_DATA;
    data: SetInitializationApplicationType;
    error: {};
  }
): UpdateInitializationFormDataStoreState => {
  switch (action.type) {
    case UPDATE_INITIALIZATION_FORM_DATA.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case UPDATE_INITIALIZATION_FORM_DATA.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case UPDATE_INITIALIZATION_FORM_DATA.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case UPDATE_INITIALIZATION_FORM_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default updateInitializationFormData;
