import styled from 'styled-components';
import { gray, rr } from 'shared/styled';

export const ProductNameWrapper = styled.div`
  div {
    font-size: 16px;
    font-family: ${rr};
  }

  div:last-child {
    font-family: ${rr};
    font-size: 12px;
  }
`;

export const ProductRevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    p:last-child {
      color: ${gray};
    }

    p {
      margin: 0;
    }
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;

  svg:last-child {
    position: absolute;
    left: 65%;
    top: 55%;
  }
`;

export const ProductClientTypeWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 15px;

  svg {
    cursor: pointer;
  }

  svg:last-child {
    stroke: white;
    stroke-width: 20px;
  }
`;

export const TooltipText = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0.9;
  z-index: 1;
  font-size: 14px;
  visibility: visible;
  transition: visibility 0.2s ease-in-out;
`;

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
`;
