import { GET_ACTIVE_DDS_OF_DEBTOR } from 'entities/Cabinet/InternalAgent/model/reducers/getActiveDDSOfDebtor';

export interface ResponseData {
  guid: string;
  debtorInn: string;
  enableDiscounting: boolean;
  enableFactoring: boolean;
  accountBalanceIsIntegrated: any;
  protocolsPolicy: boolean;
  discountLimitPolicy: number;
  manualMaxLimitSize: number;
  limitUsage: number;
  autoLimitPolicy: any;
  fixedSubstractionSum: any;
  substractionPeriodInDays: any;
  discountRatePolicy: number;
  discountRate: number;
  autoRateSubstraction: any;
  factoringOfferPolicy: number;
  signatureFilename: string;
  signatureFileIdentifier: string;
  requestFilename: string;
  requestFileIdentifier: string;
  minimalDiscount: number;
  payWeekdays: string;
  holidayPaydays: any;
  suppliesDataSourcePolicy: any;
  verificationPolicy: number;
  epaMcPolicy: number;
  discountingFee: number;
  minFee: number;
  factoringFormatPolicy: number;
  factoringNotificationPolicy: any;
  agreementFormFileIdentifier: any;
  agreementFormFilename: string;
  factoringAssignmentOfRightPolicy: any;
  assignmentOfRightsFileIdentifyer: any;
  assignmentOfRightsFileName: any;
  paydayOffset: number;
  interalDiscountineSettings: {
    id: number;
    debtorInn: string;
    accountBalanceIsIntegrated: boolean;
    factorsExchangeIsIntegrated: boolean;
    agreementFormFileIdentifier: string;
    agreementFormFilename: string;
    assignmentOfRightsFileIdentifyer: any;
    assignmentOfRightsFileName: string;
  };
}

export const req = (inn: string) => ({
  type: GET_ACTIVE_DDS_OF_DEBTOR.REQ,
  inn
});

export const got = (inn: string, data: ResponseData) => ({
  type: GET_ACTIVE_DDS_OF_DEBTOR.GOT,
  inn,
  data
});

export const err = (inn: string, error: {}) => ({
  type: GET_ACTIVE_DDS_OF_DEBTOR.ERR,
  inn,
  error
});
