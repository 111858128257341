import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostDiscountingSettingsSignatureStoreState = Reducer;

export enum POST_DISCOUNTING_SETTINGS_SIGNATURE {
  REQ = 'POST_DISCOUNTING_SETTINGS_SIGNATURE_REQ',
  GOT = 'POST_DISCOUNTING_SETTINGS_SIGNATURE_GOT',
  ERR = 'POST_DISCOUNTING_SETTINGS_SIGNATURE_ERR'
}

export interface RequestDataType {
  guid: string;
  request: string;
  signature: string;
}

export const initialState: PostDiscountingSettingsSignatureStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postDiscountingSettingsSignature = (
  state: PostDiscountingSettingsSignatureStoreState = initialState,
  action: {
    type: POST_DISCOUNTING_SETTINGS_SIGNATURE;
    error: {};
  }
): PostDiscountingSettingsSignatureStoreState => {
  switch (action.type) {
    case POST_DISCOUNTING_SETTINGS_SIGNATURE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_DISCOUNTING_SETTINGS_SIGNATURE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_DISCOUNTING_SETTINGS_SIGNATURE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postDiscountingSettingsSignature;
