import styled from 'styled-components';
import { lighterBlue, rr, blue, lightGray, gray } from 'shared/styled';

export const ContractsPageStyled = styled.section`
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const InnFilterArea = styled.div`
  width: 430px;
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const ListSection = styled.section`
  margin-top: 30px;
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FullList = styled.section`
  margin-top: 30px;
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const PeriodFilter = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const PeriodFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 30px;
  margin-bottom: 15px;
  background-color: #f1f8ff;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const DateFilterStyled = styled.div`
  width: 550px;
  margin-bottom: 30px;
  padding: 16px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0px;
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
  input {
    padding: 5px 10px;
    height: 30px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const SignBtns = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SignBtn = styled.div`
  width: 100px;
  height: 25px;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 17px;
  background-color: ${blue};
  transition: 0.2s;
  margin-bottom: 12px;
  z-index: 100;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const SignBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 0 10px;
  line-height: 10%;
  color: #ffffff;
`;

export const DatePopup = styled.div`
  cursor: auto;
  padding: 50px 20px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 0;
  z-index: 30;
  margin-top: -15px;
  margin-left: 45vw;
  display: none;
  background-color: #ffffff;
  border: 1px solid ${lighterBlue};
`;

export const DatePopupBtns = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  width: 120px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const CancelBtn = styled.div`
  margin-top: 20px;
  width: 90px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f53737;
  transition: 0.2s;
  margin-left: 20px;
  &:hover {
    background-color: #c12323;
  }
`;

export const CancelBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const GotBtn = styled.div`
  max-width: 150px;
  height: auto;
  display: flex;

  justify-content: center;
  border-radius: 5px;
  background-color: #d0e9ff;
`;

export const GotBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 5px 10px;
  margin: 0;
  color: #3f4e65;
`;

export const PaidBtn = styled.div`
  width: 150px;
  height: auto;
  display: flex;

  justify-content: center;
  border-radius: 5px;
  background-color: #1cca94;
`;

export const PaidBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 5px 10px;
  margin: 0;
  color: #ffffff;
`;

export const TextThrough = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;

    svg {
      cursor: pointer;
      color: ${blue};
      font-size: 18px;
    }

    p {
      margin: 0;
      color: ${gray};
      font-size: 12px;
      text-decoration: line-through;
    }
  }

  button {
    width: fit-content;
    height: 20px;
    font-size: 14px;
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ text }) => text}';
    position: absolute;
    width: max-content;
    font-family: ${rr};
    bottom: 15px;
    left: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }`;

export const TDPayment = styled.td`
  overflow: visible !important;
`;

export const ErrorMessage = styled.div`
  font-family: ${rr};
  font-size: 18px;
`;
