import styled from 'styled-components';
import { CrossBtn } from 'shared/styled';

export const ModalBlockStyled = styled.div`
  width: auto;
  min-width: 490px;
  height: auto;

  padding: 40px 60px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);

  ${CrossBtn} {
    width: 20px;
    height: 20px;

    top: 20px;
    right: 20px;
    position: absolute;
  }
`;
