import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  addStatusFilter,
  removeStatusFilter
} from 'src/features/Filters/Status/actions/updateStatusFilter';
import { STORE } from 'globaltypes';
import { MultiSelect } from 'shared/ui/MultiSelect';

interface DispatchProps {
  addStatusFilter: (status: string) => void;
  removeStatusFilter: (indexToRemove: number) => void;
}

interface StateToProps {
  status: string[];
}

interface OwnProps {
  statusList: any;
}

type Props = DispatchProps & StateToProps & OwnProps;

class StatusFilter extends React.Component<Props> {
  onMultiAdd = (type: string) => {
    const isUnique = this.props.status.includes(type);
    if (!isUnique) {
      this.props.addStatusFilter(type);
    }
  };

  onMultiRemove = (index: number) => {
    this.props.removeStatusFilter(index);
  };

  initialTypes() {
    return Object.keys(this.props.statusList)
      .map(key => {
        return { id: key, name: this.props.statusList[key] };
      })
      .filter(item => item.name !== '');
  }

  render() {
    const initialTypes = this.initialTypes();
    return (
      <MultiSelect
        name="status"
        placeholder="Статус"
        label="Фильтрация по статусу"
        value=""
        selected={this.props.status.map(key => this.props.statusList[key])}
        onMultiAdd={this.onMultiAdd}
        onMultiRemove={this.onMultiRemove}
        options={initialTypes}
      />
    );
  }
}

const mapStateToProps = ({ Filters }: STORE) => ({
  status: Filters.updateStatusFilter.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ addStatusFilter, removeStatusFilter }, dispatch);

const StatusConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusFilter);

export { StatusConnect as StatusFilter };
