import {
  CREDITING_FORM_ACTIONS,
  SetCreditingApplicationType
} from 'Application/reducers/setCreditingFormData';

export const setCreditingFormData = (data: SetCreditingApplicationType) => ({
  type: CREDITING_FORM_ACTIONS.SET,
  data
});

export const resetCreditingFormData = () => ({
  type: CREDITING_FORM_ACTIONS.RESET
});
