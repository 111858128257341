import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getDebtor';

import {
  GET_DEBTOR,
} from '../reducers/getDebtor';
import DebtorsApi from '../api';

function* getDebtor(action: {
  type: GET_DEBTOR;
  inn: string
}): Generator {
  try {

    const res:any = yield call(DebtorsApi.getDebtorByINN, action.inn);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorSaga(): Generator {
  yield takeLatest(GET_DEBTOR.REQ, getDebtor);
}
