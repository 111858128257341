import styled, { keyframes } from 'styled-components';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import {
  blue,
  darkestBlue,
  gray,
  lightBlue,
  lightGray,
  rm,
  rr
} from 'shared/styled';
import { Button } from 'shared/ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shadeColor } from 'src/shared/utils/Utils';

export const InnInputContainer = styled(INNFilterContainerStyled)`
  margin: 10px 0 0 0;
  max-width: fit-content;
`;

export const FiltersStyled = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  row-gap: 10px;
  margin-bottom: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  & > div {
    &:first-of-type {
      display: flex;
      align-items: end;
      column-gap: 30px;

      h2 {
        margin: 0;
      }
    }

    &:last-of-type {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  button {
    width: fit-content;
    height: 40px;
  }
`;

export const PreApprovalTH = styled.div`
  text-align: center;
  padding-bottom: 10px;
`;

export const TableThWrapper = styled.th`
  position: relative;
  width: 30%;
`;

export const PreApproval = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  & > div:first-of-type {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const FormModal = styled.div`
  p {
    font-size: 18px;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const ButtonForm = styled(Button)`
  background-color: ${({ warning }) => warning && 'red'};
  width: fit-content;
  height: 40px;
  margin-top: 30px;

  :disabled {
    background-color: ${lightGray};
  }
`;

export const DescriptionArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: #d0e9ff;
  transition: 0.2s;
`;

export const Description = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 1em;
  padding: 0 20px;
  line-height: 130%;

  p {
    margin: 10px 0;
  }
`;

export const InputArea = styled.div`
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  textarea {
    width: 500px;
    padding: 10px 20px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;

export const DownloadButton = styled.a.attrs({
  download: true
})`
  width: fit-content;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 24px;

  font-family: ${rm};
  font-size: 16px;
  color: ${blue};
  text-decoration: none;

  &:disabled {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? shadeColor(backgroundColor, 30) : lightBlue};
  }

  &:active {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? shadeColor(backgroundColor, -60) : darkestBlue};
    color: rgb(170, 215, 255);
  }
`;

export const LowerNum = styled.div`
  color: red;
`;

export const DataRow = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 5px;

  & > p:first-of-type {
    text-align: right;
  }

  & > p {
    margin: 0;
  }
`;

export const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const ActivitiesQtyContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  svg {
    color: ${({ color1 }) => color1 && 'red'};
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    p {
      margin: 0;
    }

    & > p:nth-child(2) {
      color: ${({ color2 }) => color2 && 'red'};
    }
  }
`;

export const LastContactContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  svg {
    color: ${({ color1 }) => color1 && 'red'};
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    p {
      margin: 0;
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
