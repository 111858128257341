import { BorrowerCompany } from 'Application/types';
import {
  getQuarterReportBoundary,
  getYearlyReportYear,
  isQuarterRequired
} from 'src/shared/utils/Utils';
import registry_example_doc from 'assets/documents/ФОРМА_Реестр_исполненных_и_действующих_контрактов.xls';
import debts_example_doc from 'assets/documents/ФОРМА Расшифровка Кредиты и Займы.xlsx';

// TODO refactor
const UpperDocumentsMetaInfoRequired = {
  financial_statements_year: 'financial_statements_year',
  financial_statements_quarter: 'financial_statements_quarter',
  osv_60_year: 'osv_60_year',
  osv_60_quarter: 'osv_60_quarter',
  osv_62_year: 'osv_62_year',
  osv_62_quarter: 'osv_62_quarter',
  registry: 'registry',

  account_card: 'account_card'
};

export function getUpperDocumentsMetaInfoRequired() {
  const UpperDocumentsMetaInfo = {
    financial_statements_year: 'financial_statements_year',
    osv_60_year: 'osv_60_year',
    osv_62_year: 'osv_62_year',
    registry: 'registry',
    account_card: 'account_card'
  };
  if (isQuarterRequired()) {
    UpperDocumentsMetaInfo['osv_60_quarter'] = 'osv_60_quarter';
    UpperDocumentsMetaInfo['osv_62_quarter'] = 'osv_62_quarter';
    UpperDocumentsMetaInfo['financial_statements_quarter'] =
      'financial_statements_quarter';
  }
  return UpperDocumentsMetaInfo;
}

export function getUpperDocumentsMetaInfoExtendedRequired() {
  const UpperDocumentsMetaInfo = {
    financial_statements_year: 'financial_statements_year',
    osv_60_year: 'osv_60_year',
    tax_returns_nds: 'tax_returns_nds',
    debts_form: 'debts_form',
    osv_62_year: 'osv_62_year',
    registry: 'registry',
    account_card: 'account_card'
  };
  if (isQuarterRequired()) {
    UpperDocumentsMetaInfo['osv_60_quarter'] = 'osv_60_quarter';
    UpperDocumentsMetaInfo['osv_62_quarter'] = 'osv_62_quarter';
    UpperDocumentsMetaInfo['financial_statements_quarter'] =
      'financial_statements_quarter';
    UpperDocumentsMetaInfo['tax_returns_quarter'] = 'tax_returns_quarter';
  }
  return UpperDocumentsMetaInfo;
}

export const UpperDocumentsMetaInfoExtendedRequired = {
  ...UpperDocumentsMetaInfoRequired,
  tax_returns_quarter: 'tax_returns_quarter',
  tax_returns_nds: 'tax_returns_nds',
  debts_form: 'debts_form'
};

// TODO refactor flag, rename
export function getUpperDocumentsRequired(
  borrowerCompany: BorrowerCompany,
  extended?: boolean
) {
  const quarterBoundaries = getQuarterReportBoundary();
  const quarterRequired = quarterBoundaries.quarter && quarterBoundaries.year;

  return [
    {
      label: 'Бухгалтерская отчетность',
      required: true,
      fileInputs: [
        {
          label: `${getYearlyReportYear()} год `,
          metaInfo: UpperDocumentsMetaInfoRequired.financial_statements_year,
          filename: `${
            borrowerCompany.individualNumber
          }_Бух_Годовая_отчетность_${getYearlyReportYear()}`
        },
        quarterRequired
          ? {
              label: `${quarterBoundaries.quarter} квартал ${
                quarterBoundaries.year
              } года `,
              metaInfo:
                UpperDocumentsMetaInfoRequired.financial_statements_quarter,
              filename: `${borrowerCompany.individualNumber}_Бух_Отчетность_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`
            }
          : {}
      ]
    },

    quarterRequired && extended
      ? {
          label: 'Налоговая декларация по налогу на прибыль',
          required: true,
          fileInputs: [
            {
              metaInfo:
                UpperDocumentsMetaInfoExtendedRequired.tax_returns_quarter,
              filename: `${borrowerCompany.individualNumber}_ДекларацияНП_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`,
              padded: true
            }
          ]
        }
      : null,
    quarterRequired && extended
      ? {
          label: 'Налоговая декларация НДС',
          required: true,
          fileInputs: [
            {
              metaInfo: UpperDocumentsMetaInfoExtendedRequired.tax_returns_nds,
              filename: `${borrowerCompany.individualNumber}_ДекларацияНДС_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`,
              padded: true
            }
          ]
        }
      : null,
    {
      label: 'ОСВ 60 счета',
      subLabel: 'по субсчетам и контрагентам с указанием ИНН',
      required: true,
      fileInputs: [
        {
          metaInfo: UpperDocumentsMetaInfoRequired.osv_60_year,
          filename: `${
            borrowerCompany.individualNumber
          }_ОСВ60_${getYearlyReportYear()}`
        },
        quarterRequired
          ? {
              metaInfo: UpperDocumentsMetaInfoRequired.osv_60_quarter,
              filename: `${borrowerCompany.individualNumber}_ОСВ60_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`
            }
          : {}
      ]
    },
    {
      label: 'ОСВ 62 счета',
      subLabel: 'по субсчетам и контрагентам с указанием ИНН',
      required: true,
      fileInputs: [
        {
          metaInfo: UpperDocumentsMetaInfoRequired.osv_62_year,
          filename: `${
            borrowerCompany.individualNumber
          }_ОСВ62_${getYearlyReportYear()}`
        },
        quarterRequired
          ? {
              metaInfo: UpperDocumentsMetaInfoRequired.osv_62_quarter,
              filename: `${borrowerCompany.individualNumber}_ОСВ62_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`
            }
          : {}
      ]
    },
    {
      label: 'Реестр действующих и исполненных контрактов',
      required: true,
      fileInputs: [
        {
          metaInfo: UpperDocumentsMetaInfoRequired.registry,
          filename: `${borrowerCompany.individualNumber}_Реестр_контрактов`
        },
        { padded: true }
      ],
      exampleDocument: {
        name: 'Форма реестра',
        link: registry_example_doc
      }
    },
    extended
      ? {
          label: 'Расшифровка кредитов и займов по форме',
          required: true,
          fileInputs: [
            {
              metaInfo: UpperDocumentsMetaInfoExtendedRequired.debts_form,
              filename: `${borrowerCompany.individualNumber}_Кредиты_и_займы`
            }
          ],
          exampleDocument: {
            name: 'ФОРМА расшифровки',
            link: debts_example_doc
          }
        }
      : null,
    {
      label: 'Карточка 51 счета за последние 12 месяцев',
      required: true,
      fileInputs: [
        {
          metaInfo: UpperDocumentsMetaInfoRequired.account_card,
          filename: `${
            borrowerCompany.individualNumber
          }_Карточка_51сч_12месяцев`
        }
      ]
    }
  ];
}

export const CommonDocumentsRequiredMetaInfo = {
  charter: 'charter',
  directorPassport: 'director_passport',
  directorDecision: 'director_decision',
  constitutorPassports: 'constitutor_passports',
  rentOrOwnershipContract: 'rent_or_ownership_contract',
  requisites: 'requisites'
};

export function getLegalDocumentsFileRow(borrowerCompany: BorrowerCompany) {
  return [
    {
      label: 'Устав',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.charter,
          filename: `${borrowerCompany.individualNumber}_Устав`
        }
      ]
    },
    {
      label: 'Паспорт генерального директора',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.directorPassport,
          filename: `${borrowerCompany.individualNumber}_Паспорт_ГД`
        }
      ]
    },
    {
      label: 'Паспорта учредителей',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.constitutorPassports,
          filename: `${borrowerCompany.individualNumber}_Паспорта_УЧ`
        }
      ]
    },
    {
      label: 'Решение на генерального директора',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.directorDecision,
          filename: `${borrowerCompany.individualNumber}_Решение_ГД`
        }
      ]
    },
    {
      label: 'Карточка с реквизитами',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.requisites,
          filename: `${borrowerCompany.individualNumber}_Реквизиты`
        }
      ]
    },
    {
      label: 'Договор аренды или собственности офиса',
      required: true,
      fileInputs: [
        {
          metaInfo: CommonDocumentsRequiredMetaInfo.rentOrOwnershipContract,
          filename: `${borrowerCompany.individualNumber}_ДокументыНаАдрес`
        }
      ]
    }
  ];
}

export const FinancialUpTo500Required = {
  yearReport: 'year_report',
  registry: 'registry',
  charter: 'charter',
  directorPassport: 'director_passport',
  directorDecision: 'director_decision',
  constitutorPassports: 'constitutor_passports',
  rentOrOwnershipContract: 'rent_or_ownership_contract',
  requisites: 'requisites'
};

const FinancialUpTo500 = {
  contract: 'contract',
  quarterReport: 'quarter_report',
  account_card: 'account_card',
  ...FinancialUpTo500Required
};

export function getFinancialUpTo500DocumentsFileRow(
  borrowerCompany: BorrowerCompany
) {
  const quarterBoundaries = getQuarterReportBoundary();
  const quarterRequired = quarterBoundaries.quarter && quarterBoundaries.year;
  return [
    {
      label: `Отчетность за ${getYearlyReportYear()}`,
      required: true,
      fileInputs: [
        {
          metaInfo: FinancialUpTo500Required.yearReport,
          filename: `${
            borrowerCompany.individualNumber
          }_Годовая_отчетность_${getYearlyReportYear()}`
        }
      ]
    },
    quarterRequired
      ? {
          label: `Квартальная отчетность за ${
            quarterBoundaries.quarter
          } квартал ${quarterBoundaries.year} года`,
          fileInputs: [
            {
              metaInfo: FinancialUpTo500.quarterReport,
              filename: `${borrowerCompany.individualNumber}_Отчетность_${
                quarterBoundaries.year
              }-${quarterBoundaries.quarter}_кв`
            }
          ]
        }
      : null,
    {
      label: 'Реестр действующих и исполненных контрактов',
      fileInputs: [
        {
          metaInfo: FinancialUpTo500.registry
        }
      ]
    },
    {
      label: 'Карточка 51 счета за последние 12 месяцев',
      fileInputs: [
        {
          metaInfo: FinancialUpTo500.account_card
        }
      ]
    }
  ];
}
