import styled, { css } from 'styled-components';
import { APPLICATION_STATUSES } from 'Application/types';
import { rr, blue, navyBlue } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const styleStatus = status => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_AGENT:
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      return css`
        background-color: #beefe6;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.IN_WORK:
    case APPLICATION_STATUSES.REVIEW:
      return css`
        background-color: #d0e9ff;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.AWAITING:
    case APPLICATION_STATUSES.DOCS_REQUEST:
      return css`
        background-color: #fff0de;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.AGENT_REJECT:
    case APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
      return css`
        background-color: #e4e4e4;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.BANK_REJECT:
      return css`
        background-color: #ffd0d0;
        color: ${navyBlue};
      `;
    case APPLICATION_STATUSES.APPROVED:
      return css`
        background-color: ${blue};
        color: #fff;
      `;
    case APPLICATION_STATUSES.AGENT_APPROVED:
    case APPLICATION_STATUSES.SUCCESS:
      return css`
        background-color: #1cca94;
        color: #fff;
      `;
    default:
      return css`
        background-color: #fff0de;
        color: ${navyBlue};
      `;
  }
};

export const ApplicationStatusStyled = styled.span`
  padding: 8px 20px;
  border-radius: 6px;
  font-family: ${rr};
  font-size: 12px;
  text-align: center;

  ${({ status }) => styleStatus(status)};
`;

export const EditApplicationBtn = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  
  font-family: ${rr};
  font-size: 12px;
  color: ${blue};
  background-color: transparent;

  &::before {
    content: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" x="0px" y="0px" viewBox="0 0 58 88">
        <path fill="${blue}" d="M39,1l-5.5,9.5c-0.5,0.9-0.2,2.2,0.7,2.7l15.3,8.8c0.9,0.5,2.2,0.2,2.8-0.7l5.5-9.5c0.5-0.9,0.2-2.2-0.7-2.7L41.7,0.3C40.6-0.2,39.6-0.2,39,1z M31.7,15.1c-0.6,0.1-1.2,0.4-1.6,1L2,64.5c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,1.1-0.5,5.7-0.9,10.2c-0.4,4.5-0.8,9-0.9,10.3c0,0.7,0.4,1.5,1,1.8c0.6,0.4,1.5,0.3,2.1-0.1c1.1-0.7,4.8-3.3,8.6-5.9c3.7-2.6,7.5-5.3,8.5-5.9c0.3-0.2,0.5-0.4,0.6-0.7l28.2-48.3c0.5-0.9,0.2-2.2-0.8-2.8l-15.3-8.8C32.6,15.2,32.1,15.1,31.7,15.1L31.7,15.1z M17.6,72.8c-1,1-4.6,3.2-8.2,5.7C7.2,80,6,80.8,4.3,82c0.2-2,0.3-3.3,0.5-6c0.4-4.3,0.7-8.2,0.8-9.8C4.6,65.9,18.6,72.9,17.6,72.8L17.6,72.8z" />
      </svg>');
      
    margin-right: 10px;
  }
`;

// TODO extract
export const TransferApplicationBtn = styled(ButtonStyled)`
  padding: 8px 20px;
  font-family: ${rr};
  font-size: 12px;
  justify-content: center;
`;
