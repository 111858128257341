import { POST_EXTERNAL_API_ALFA_BANK_APPLICATION } from '../reducers/postExternalApiAlfaBank';

export const req = (ApplicationID: number) => ({
  type: POST_EXTERNAL_API_ALFA_BANK_APPLICATION.REQ,
  ApplicationID
});

export const got = () => ({
  type: POST_EXTERNAL_API_ALFA_BANK_APPLICATION.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_API_ALFA_BANK_APPLICATION.ERR,
  error
});
