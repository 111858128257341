import { combineReducers } from 'redux';
import setContactsOfProductsFormData, {
  SetContactsOfProductsDataStoreState
} from './setContactsOfProductsFormData';
import getProductsListByContact, {
  GetProductsListByContactStoreState
} from './getProductsListByContact';
import getContactsListByProduct, {
  GetContactsListByProductStoreState
} from './getContactsListByProduct';
import postContactByProduct, {
  PostContactByProductStoreState
} from './postContactByProduct';

export interface ContactsOfProductsReducers {
  setContactsOfProductsFormData: SetContactsOfProductsDataStoreState;
  getProductsListByContact: GetProductsListByContactStoreState;
  getContactsListByProduct: GetContactsListByProductStoreState;
  postContactByProduct: PostContactByProductStoreState;
}

export const ContactsOfProducts = combineReducers({
  setContactsOfProductsFormData,
  getProductsListByContact,
  getContactsListByProduct,
  postContactByProduct
});
