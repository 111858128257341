import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';
import { GET_FACTORING_FEES_OF_LIMITS } from 'entities/SCF/Factor/model/reducers/getFactoringFeesOfLimits';
import SCFApi from 'entities/SCF/Factor/api';

function* getFactoringFeesOfLimits(action: {
  type: GET_FACTORING_FEES_OF_LIMITS;
  inn: string;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getFactoringFeesOfLimits,
      action.inn,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringFeesOfLimitsSaga() {
  yield takeLatest(GET_FACTORING_FEES_OF_LIMITS.REQ, getFactoringFeesOfLimits);
}
