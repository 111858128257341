import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getHoldingsList';

import { GET_HOLDINGS_LIST } from '../reducers/getHoldingsList';
import HoldingsApi from '../api';
import { HOLDING_LIST_TYPE } from '../types';

function* getHoldingsList(action: {
  type: GET_HOLDINGS_LIST;
  data: RequestData;
  holdingListType: HOLDING_LIST_TYPE;
}): Generator {
  try {
    let res;
    switch (action.holdingListType) {
      case HOLDING_LIST_TYPE.ALL:
        res = yield call(HoldingsApi.getAllHoldings, action.data);
        break;
    }

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getHoldingsListSaga(): Generator {
  yield takeLatest(GET_HOLDINGS_LIST.REQ, getHoldingsList);
}
