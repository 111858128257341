import styled from 'styled-components';
import { rr, gray, navyBlue, lighterBlue } from 'shared/styled';

export const DebtorsContainer = styled.div`
  margin-top: -20px;

  display: flex;
  flex-wrap: wrap;
`;

export const DebtorBlock = styled.div`
  width: 350px;

  margin: 0 12px 12px 0;
  padding: 20px 52px 20px 20px;

  background-color: ${lighterBlue};

  p {
    margin: 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    font-family: ${rr};
    font-size: 14px;
    color: ${gray};

    span {
      color: ${navyBlue};
    }

    &:not(:last-child) {
      margin: 0 0 12px 0;
    }
  }
`;
