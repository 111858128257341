import { GET_USER_STATS } from 'entities/Cabinet/ExternalAgent/model/reducers/getUserStats';

export interface ResponseData {
  thisNewUsers: number;
  prevNewUsers: number;
  thisNewClients: number;
  prevNewClients: number;
  thisNewAppsQty: {
    qty: number;
    sum: number;
  };
  prevNewApps: {
    qty: number;
    sum: number;
  };
  thisNewExtAppsQty: {
    qty: number;
    sum: number;
  };
  prevNewExtAppsQty: {
    qty: number;
    sum: number;
  };
  thisLeadsQty: {
    qty: number;
    sum: number;
  };
  prevLeadsQty: {
    qty: number;
    sum: number;
  };
}

export const req = () => ({
  type: GET_USER_STATS.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_USER_STATS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_USER_STATS.ERR,
  error
});
