import { GET_COMPANIES_LIST_CRM } from '../reducers/getCompaniesList';

export interface CompaniesList {
  id: number;
  guid: string;
  name: string;
  type: number;
  companyINN: string;
  creatorUserId: number;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CompaniesList[];
}

export interface RequestData {
  page: number;
}

export const req = (data: RequestData) => ({
  type: GET_COMPANIES_LIST_CRM.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_LIST_CRM.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_LIST_CRM.ERR,
  error
});
