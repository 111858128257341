import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { history } from 'src/shared/utils/History';
import { formSumString, toFloatFormatter } from 'src/shared/utils/Utils';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  ApplicationTypes,
  InnPlaceholders,
  FinancingCreditType,
  FinancingPaymentConfirmation
} from 'shared/constants';

import {
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES,
  DOCUMENT_UPLOAD_TYPE
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';

import { req as updateInitializationFormData } from 'Application/actions/updateInitializationFormData';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

import { setFinancingFormData } from 'Application/actions/setFinancingFormData';
import { SetFinancingApplicationType } from 'Application/reducers/setFinancingFormData';

import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { RadioGroup } from 'shared/ui/RadioGroup';
import {
  InputRange,
  INPUT_RANGE_VALUE_TYPE
} from 'shared/ui/InputRange/InputRange';
import {
  TRANSITION_DIRECTIONS,
  TransitionWrapper,
  ANIMATION_NAMES
} from 'Common';
import { EquityRatioProportion } from 'Application/components';

import { createValidaton } from './validator';
import { formula } from './formula';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationBtnsWrapper,
  ApplicationInputsColumn
} from '../../styles';

import {
  CheckboxStyled,
  InputRangeStyled,
  InputStyled,
  EquityRatioContainer,
  EquityRatioText,
  ApplicationFormRow,
  ApplicationFormContentColumn
} from './styles';
import { Button } from 'shared/ui/Button';

interface State {
  submitClicked: boolean;
}

interface OwnProps {
  path: string;
}

interface WithRouterProps {
  match: any;
}

type StateToProps = SetFinancingApplicationType &
  SetInitializationApplicationType;

interface DispatchToProps {
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  setFinancingFormData: (data: SetFinancingApplicationType) => void;
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  updateInitializationFormData: (
    data: SetInitializationApplicationType
  ) => void;
  openModal: (data: OpenModalDataType) => void;
}

type Props = OwnProps & WithRouterProps & StateToProps & DispatchToProps;

class FinancingFormStep1 extends React.Component<Props, State> {
  Validator = createValidaton();

  state = {
    submitClicked: false
  };

  componentWillUnmount() {
    const {
      sendApplicationDataReq,
      id,
      creditType,
      equity,
      termInvestmentPhase,
      isAgriculture,
      paymentConfirmationInvestmentPhase
    } = this.props;

    sendApplicationDataReq({
      id,
      financing: {
        creditType,
        equity,
        termInvestmentPhase,
        isAgriculture,
        paymentConfirmationInvestmentPhase
      }
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;

    const isInitData = name === 'amount' || name === 'term';
    const isToNumber = ['amount', 'term', 'equity', 'termInvestmentPhase'];
    const isCheckbox = type === 'checkbox';

    const setValue = () =>
      isCheckbox
        ? checked
        : isToNumber.includes(name)
          ? toFloatFormatter(value)
          : value;

    isInitData
      ? this.props.setInitializationFormData({
          [name]: setValue()
        } as { [key in keyof SetInitializationApplicationType]: any })
      : this.props.setFinancingFormData({
          [name]: setValue()
        } as { [key in keyof SetFinancingApplicationType]: any });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    const {
      id,
      financingType,
      refinancing,
      clientCompanyName,
      code,
      amount,
      term,
      path
    } = this.props;

    this.props.updateInitializationFormData({
      id,
      financingType,
      refinancing,
      clientCompanyName,
      code,
      amount,
      term
    });

    history.push({
      pathname: this.navigateFromTo(path, 'documents'),
      state: TRANSITION_DIRECTIONS.FORWARD
    });
  };

  render() {
    const {
      openModal,
      amount,
      term,
      creditType,
      equity,
      termInvestmentPhase,
      isAgriculture,
      paymentConfirmationInvestmentPhase
    } = this.props;

    const amountRange = {
      min: 0,
      max: 100000000000
    };

    const equityRatio = isAgriculture
      ? {
          percentage: 20,
          proportion: [10, 10, 80],
          position: (equity * 100) / amount
        }
      : {
          percentage: 30,
          proportion: [15, 15, 70],
          position: (equity * 100) / amount
        };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max],
      equityRange: [amountRange.min, amount],
      termInvestmentPhaseRange: [1, term]
    }).validate(this.props);

    const {
      investmentCredit,
      mezzanineCredit,
      investmentPhaseAmount,
      projectInvestments
    } = formula({
      amount,
      equity,
      termInvestmentPhase,
      isAgriculture,
      paymentConfirmationInvestmentPhase
    });

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>Параметры кредитования </ApplicationStepTitle>

        <ApplicationFormContentColumn>
          <ApplicationInputsColumn>
            <RadioGroup
              label="Тип кредита"
              name="creditType"
              keyValue={creditType}
              onChange={this.onChange}
              radioBtns={Object.keys(FinancingCreditType).map(type => ({
                value: type,
                label: FinancingCreditType[type]
              }))}
            />

            <CheckboxStyled
              name="isAgriculture"
              label="По программе министерства сельского хозяйства"
              checked={isAgriculture}
              onChange={this.onChange}
            />
          </ApplicationInputsColumn>

          <ApplicationFormRow
            onSubmit={this.onSubmit}
            name="initialize-application-form"
          >
            <ApplicationInputsColumn>
              <InputRangeStyled
                value={amount.toString()}
                label="Бюджет проекта"
                name="amount"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                required={true}
                error={errors.amount}
                onChange={this.onChange}
              />

              <InputRangeStyled
                value={term.toString()}
                label="Срок"
                name="term"
                placeholder="Введите срок"
                min={1}
                max={10 * 12}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                onChange={this.onChange}
              />

              <InputRange
                value={equity.toString()}
                label="Собственные средства"
                name="equity"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amount}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                required={true}
                error={errors.equity}
                onChange={this.onChange}
              />

              <EquityRatioContainer>
                <p>
                  Рекомендуемая доля собственных средств -{' '}
                  {equityRatio.percentage}%
                </p>

                <EquityRatioProportion {...equityRatio} />

                <EquityRatioText {...equityRatio}>
                  {equityRatio.position < equityRatio.percentage / 2
                    ? 'Реализация сделки маловероятна. Рекомендуем увеличить размер собственных средств'
                    : equityRatio.position < equityRatio.percentage
                      ? 'Собственные средства ниже минимально рекомендуемого значения. Недостаток собственных средств может быть покрыт мезонинным финансированием'
                      : 'Размер собственных средств соотвествует  рекомендуемому значению'}
                </EquityRatioText>
              </EquityRatioContainer>

              <InputRangeStyled
                value={termInvestmentPhase.toString()}
                label="Срок инвестиционной фазы"
                name="termInvestmentPhase"
                placeholder="Введите срок"
                min={1}
                max={term}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                error={errors.termInvestmentPhase}
                onChange={this.onChange}
              />

              <ApplicationBtnsWrapper>
                <Button
                  template="backBtn"
                  label="Назад"
                  onClick={() =>
                    history.push({
                      pathname: this.navigateFromTo(`/${this.props.path}`, ''),
                      state: TRANSITION_DIRECTIONS.BACKWARDS
                    })
                  }
                />
                <Button template="nextBtn" label="Продолжить" type="submit" />
              </ApplicationBtnsWrapper>
            </ApplicationInputsColumn>

            <ApplicationInputsColumn>
              <InputStyled
                value={formSumString(Math.round(investmentCredit))}
                name="investmentCredit"
                label="Инвестиционный кредит"
                disabled={true}
              />

              <TransitionWrapper
                in={Math.round(mezzanineCredit) > 0}
                animationName={ANIMATION_NAMES.FADE}
              >
                <InputStyled
                  value={formSumString(Math.round(mezzanineCredit))}
                  name="mezzanineCredit"
                  label="Мезонинный кредит"
                  disabled={true}
                />
              </TransitionWrapper>

              <InputStyled
                value={formSumString(Math.round(investmentPhaseAmount))}
                name="investmentPhaseAmount"
                label="Сумма процентов на инвестиционной фазе"
                disabled={true}
              />

              <RadioGroup
                label="Форма подтверждения оплаты процентов на инвест. фазе"
                name="paymentConfirmationInvestmentPhase"
                keyValue={paymentConfirmationInvestmentPhase}
                onChange={this.onChange}
                radioBtns={Object.keys(FinancingPaymentConfirmation).map(
                  type => ({
                    value: type,
                    label: FinancingPaymentConfirmation[type]
                  })
                )}
              />

              <InputStyled
                value={formSumString(Math.round(projectInvestments))}
                name="projectInvestments"
                label="Собственные вложения для реализации проекта"
                disabled={true}
              />
            </ApplicationInputsColumn>
          </ApplicationFormRow>
        </ApplicationFormContentColumn>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  ...Application.setInitializationFormData,
  ...Application.setFinancingFormData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationDataReq,
      setInitializationFormData,
      updateInitializationFormData,
      setFinancingFormData,
      openModal
    },
    dispatch
  );

const FinancingFormStep1Connect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FinancingFormStep1)
);

export { FinancingFormStep1Connect as FinancingFormStep1 };
