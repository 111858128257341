import { GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT } from 'entities/SCF/Internal/model/reducers/getMonetaryClaimOne';

export interface ResponseData {
  id: number;
  debtorInn: string;
  debtorName: string;
  creditorInn: string;
  creditorName: string;
  currencyID: number;
  currencyCode: string;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  baseFullSumm: number;
  emergenceDate: string;
  maturityDate: string;
  paidStatus: number;
  cessionProfileId: number;
  cessedMonetaryClaimID: number;
  monetaryClaimId: number;
  lastRegistryId: number;
  cessedMonetaryClaimGuid: string;
  baseMonetaryClaimId: number;
  baseMonetaryClaimGuid: string;
  cessionProfileGuid: string;
  guid: string;
  isDeleted: boolean;
  firstDebtorRegistryOccurence: null;
  lastDebtorRegistryOccurence: null;
  supplyContractNumber: number;
  invoiceDocumentName: string;
  invoiceDocumentNumber: number;
  invoiceDocumentDate: string;
  invoiceDocsQty: number;
  supplyContractGuid: string;
  status: any;
  earlyPaymentAvailable: boolean;
  sourceType: any;
  bankAccountToPay: any;
  cessedToBankInn: string;
  visibleToBank: any;
  visibleToSupplier: any;
  auctionGuid: string;
  auctionPayDate: any;
  auctionResultsDateTime: any;
}

export const req = (guid: string) => ({
  type: GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.ERR,
  error
});
