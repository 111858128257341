import * as React from 'react';
import { SubscriptionsHeaderStyled, BtnsBlock } from '../styles';
import { history } from 'src/shared/utils/History';
import { Route } from 'react-router';
import {
  EditBtn,
  
  EditBtnText,
  EditBtnDisabled,
  BackBtn,
  BackBtnText
} from 'src/features/SCF/UI/ButtonsStyles';

const EditClick = (e: Event, path: string) => {
  e.preventDefault();
  history.push(`/${path}`);
};

const SubscriptionsHeader = ({onSaveClick}) => {
  return (
    <SubscriptionsHeaderStyled>
      <h2>Настройки уведомлений</h2>
      <BtnsBlock>
        <Route
          path={'/supplier/settings/subscriptions'}
          exact={true}
          render={() => (
            <EditBtn
              onClick={e =>
                EditClick(e, 'supplier/settings/subscriptions/edit')
              }
            >
              <EditBtnText>Редактировать</EditBtnText>
            </EditBtn>
          )}
        />
        <Route
          path={'/supplier/settings/subscriptions/edit'}
          render={() => (
            <EditBtnDisabled>
              <EditBtnText>Редактировать</EditBtnText>
            </EditBtnDisabled>
          )}
        />
        <Route
          path={'/supplier/settings/subscriptions/edit'}
          render={() => (
            <EditBtn onClick={onSaveClick}>
              <EditBtnText>Сохранить</EditBtnText>
            </EditBtn>
          )}
        />
        <Route
          path={'/supplier/settings/subscriptions/edit'}
          render={() => (
            <BackBtn
              onClick={e => EditClick(e, 'supplier/settings/subscriptions')}
            >
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
          )}
        />
        <Route
          path={'/debtor/settings/subscriptions'}
          exact={true}
          render={() => (
            <EditBtn
              onClick={e => EditClick(e, 'debtor/settings/subscriptions/edit')}
            >
              <EditBtnText>Редактировать</EditBtnText>
            </EditBtn>
          )}
        />
        <Route
          path={'/debtor/settings/subscriptions/edit'}
          render={() => (
            <EditBtnDisabled>
              <EditBtnText>Редактировать</EditBtnText>
            </EditBtnDisabled>
          )}
        />
        <Route
          path={'/debtor/settings/subscriptions/edit'}
          render={() => (
            <EditBtn onClick={onSaveClick}>
              <EditBtnText>Сохранить</EditBtnText>
            </EditBtn>
          )}
        />
        <Route
          path={'/debtor/settings/subscriptions/edit'}
          render={() => (
            <BackBtn
              onClick={e => EditClick(e, 'debtor/settings/subscriptions')}
            >
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
          )}
        />
      </BtnsBlock>
    </SubscriptionsHeaderStyled>
  );
};

export default SubscriptionsHeader;
