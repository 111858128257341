import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostNewCompaniesStoreState extends Reducer {}

export enum POST_NEW_COMPANIES {
  REQ = 'POST_NEW_COMPANIES_REQ',
  GOT = 'POST_NEW_COMPANIES_GOT',
  ERR = 'POST_NEW_COMPANIES_ERR',
  RESET = 'POST_NEW_COMPANIES_RESET'
}

export const initialState: PostNewCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postNewCompanies = (
  state: PostNewCompaniesStoreState = initialState,
  action: {
    type: POST_NEW_COMPANIES;
    error: {};
  }
): PostNewCompaniesStoreState => {
  switch (action.type) {
    case POST_NEW_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_NEW_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_NEW_COMPANIES.RESET:
      return {
        ...initialState
      };
    case POST_NEW_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postNewCompanies;
