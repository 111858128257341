import styled from 'styled-components';
import { lightBlue, lightGray, rr } from 'shared/styled';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';

export const FormContainer = styled.div`
  width: 400px;

  button {
    width: fit-content;
    height: 40px;

    :disabled {
      background-color: ${lightGray};
    }
  }
`;

export const FormItemContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 20px 0 40px 0;

  & > p {
    width: 200px;
  }

  ${InputContainerStyled} {
    width: 80%;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${rr};

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active === null && lightBlue};
    color: ${({ active }) => active === null && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active === null && lightBlue};
    color: ${({ active }) => active === null && lightBlue};
  }
`;
