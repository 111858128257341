import {
  RequestDataType,
  ResponseDataType,
  GET_COMMENTS_LIST
} from 'Comments/reducers/getCommentsList';

export const req = (data: RequestDataType) => ({
  type: GET_COMMENTS_LIST.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMMENTS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMMENTS_LIST.ERR,
  error
});
