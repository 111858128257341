import styled from 'styled-components';

import { BoxStyled } from 'shared/styled';
import { rl, gray, red } from 'shared/styled';

export const LoaderBarStyled = styled(BoxStyled)`
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 15px 25px;
`;

export const DefaultFeedbackText = styled.span`
  margin-left: 30px;
  font-family: ${rl};
  font-size: 16px;
  color: ${gray};
`;

export const ErrorFeedbackText = styled(DefaultFeedbackText)`
  color: ${red};
`;
