import styled from 'styled-components';

import { ApplicationSubtitle } from '../../styled';
import { rl } from 'shared/styled';

export const CompanyDocumentsBlockStyled = styled.section`
  margin-top: 30px;
`;

export const CompanyDocumentsTtitle = styled(ApplicationSubtitle)`
  margin: 0 0 52px 0;
`;
