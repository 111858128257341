import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/addInvestorSupplierRole';

import {
  ADD_INVESTOR_SUPPLIER_ROLE,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/addInvestorSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* addInvestorSupplierRole(action: {
  type: ADD_INVESTOR_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.addInvestorSupplierRole, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addInvestorSupplierRoleSaga() {
  yield takeLatest(ADD_INVESTOR_SUPPLIER_ROLE.REQ, addInvestorSupplierRole);
}
