import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/putRejected';
import { PUT_REJECTED } from 'src/features/Application/reducers/putRejected';

import ApplicationApi from 'Application/api';

function* putRejected(action: {
  type: PUT_REJECTED;
  id: number;
  rejectSubject: number;
  rejectReason: number;
}) {
  try {
    yield call(ApplicationApi.putRejected, action.id, {
      rejectSubject: action.rejectSubject,
      rejectReason: action.rejectReason
    });

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putRejectedSaga() {
  yield takeLatest(PUT_REJECTED.REQ, putRejected);
}
