import * as React from 'react';

import { Input, Props as InputProps } from 'shared/ui/Input';
import { ToggleHintIcon } from './styles';

interface State {
  isHintShown: boolean;
}

export class InputHint extends React.Component<InputProps, State> {
  state = {
    isHintShown: false
  };

  toggleHintVisibility = () => {
    this.setState({
      isHintShown: !this.state.isHintShown
    });
  };

  render() {
    const { isHintShown } = this.state;

    return (
      <Input
        {...this.props}
        hint={isHintShown && this.props.hint}
        renderHintToggleIcon={(focusInput, isFocused) => (
          <ToggleHintIcon
            isFocused={isFocused}
            onClick={() => {
              this.toggleHintVisibility();
              focusInput(true);
            }}
          />
        )}
      />
    );
  }
}
