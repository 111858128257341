import deletePropertyDocument from "./deletePropertyDocument";

import deleteProperty from "./deleteProperty";

import addProperty from "./addProperty";

import updateProperty from "./updateProperty";

import uploadPropertyDocuments from "./uploadPropertyDocuments";

import getProperties from "./getProperties";


export default [
deletePropertyDocument,

deleteProperty,

addProperty,

updateProperty,

uploadPropertyDocuments,

getProperties,
];
