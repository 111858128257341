import { POST_NEW_COMPANIES } from 'entities/Cabinet/Client/model/reducers/postNewCompanies';

export const req = (companyInn: string) => ({
  type: POST_NEW_COMPANIES.REQ,
  companyInn
});

export const got = () => ({
  type: POST_NEW_COMPANIES.GOT
});

export const reset = () => ({
  type: POST_NEW_COMPANIES.RESET
});

export const err = (error: {}) => ({
  type: POST_NEW_COMPANIES.ERR,
  error
});
