import styled from 'styled-components';
import { lighterBlue, navyBlue, rr } from 'shared/styled';

export const FileContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  font-family: ${rr};
  column-gap: 10px;

  div:first-of-type {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div:last-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  svg,
  img {
    cursor: pointer;
    height: auto;
  }
`;

export const FinancialDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const Year = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${lighterBlue};
  padding: 15px 20px;
  border-radius: 5px;

  p {
    margin: 0;
    font-size: 24px;
    color: ${navyBlue};
  }

  svg {
    cursor: pointer;
  }
`;

export const QuarterHead = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 20px 10px;

  p {
    margin: 0;
    font-size: 20px;
    color: ${navyBlue};
  }

  svg {
    cursor: pointer;
  }
`;
