import styled from 'styled-components';
import {
  ProductColumnContainer,
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import { blue } from 'shared/styled';

export const ProductMinimumProvisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
`;

export const ProductMinimumProvision = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  & > input {
    width: 90px;
  }
`;

export const PolicyBeneficiaryContainer = styled(ProductColumnContainer)`
  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Поручительство собственника не требуется' &&
        product != 4 &&
        active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Поручительство собственника не требуется' &&
        product != 4 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Частая практика отменять поручительство соственника' &&
        product != 3 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Частая практика отменять поручительство соственника' &&
        product != 3 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '2 - В исключительных случаях без поручительства собственника' &&
        product != 2 &&
        active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '2 - В исключительных случаях без поручительства собственника' &&
        product != 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Строго с поручительством собственника' &&
        product !== 1 &&
        active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Строго с поручительством собственника' &&
        product !== 1 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '4 - Поручительство собственника не требуется' ||
        product == 4) &&
      'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Строго с поручительством собственника' ||
        product == 1) &&
      'white'};
  }
`;

export const PolicyGuaranteeContainer = styled(ProductColumnContainer)`
  margin: 35px 0;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Поручительство генерального директора не требуется' &&
        product != 4 &&
        active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Поручительство генерального директора не требуется' &&
        product != 4 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '3 - Частая практика отменять поручительство генерального директора' &&
        product != 3 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '3 - Частая практика отменять поручительство генерального директора' &&
        product != 3 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '2 - В исключительных случаях без поручительства генерального директора' &&
        product != 2 &&
        active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '2 - В исключительных случаях без поручительства генерального директора' &&
        product != 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Строго с поручительством генерального директора' &&
        product != 1 &&
        active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Строго с поручительством генерального директора' &&
        product != 1 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '4 - Поручительство генерального директора не требуется' ||
        product == 4) &&
      'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Строго с поручительством генерального директора' ||
        product == 1) &&
      'white'};
  }
`;

export const PolicyOpeningContainer = styled(ProductColumnContainer)`
  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 – можно не открывать счет' && product != 3 && active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 – можно не открывать счет' && product != 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '2 – обязательно открыть счет и можно не проводить обороты' &&
        product != 2 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '2 – обязательно открыть счет и можно не проводить обороты' &&
        product != 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '1 – обязательно открыть счет и проводить обороты, но без залога прав выручки' &&
        product != 1 &&
        active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '1 – обязательно открыть счет и проводить обороты, но без залога прав выручки' &&
        product != 1 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '0 - обязательно открыть счет, проводить обороты и передать права выручки в залог' &&
        product != 0 &&
        active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '0 - обязательно открыть счет, проводить обороты и передать права выручки в залог' &&
        product != 0 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '3 – можно не открывать счет' || product == 3) && 'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product ===
        '0 - обязательно открыть счет, проводить обороты и передать права выручки в залог' ||
        product == 0) &&
      'white'};
  }
`;
