import styled from 'styled-components';
import { lightGray } from 'shared/styled';
import { Button } from 'shared/ui/Button';

export const PrepaymentTypeWrapper = styled.div`
  margin-top: 20px;
`;

export const ButtonStyle = styled(Button)`
  width: 400px;

  background-color: ${({ notValid }) => notValid && lightGray};

  :hover {
    background-color: ${({ notValid }) => notValid && lightGray} !important;
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  height: 19px;

  img {
    height: 19px;
  }

  ${({ isWarning, text }) =>
    isWarning &&
    `
    &:hover:before {
      content: '${text}';
      position: absolute;
      bottom: 20px;
      left: 275px;
      z-index: 100;
      background-color: ${lightGray};
      color: black;
      padding: 10px;
      border-radius: 4px;
    }
  `};
`;
