import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Agents/actions/getAllAgent';

export interface GetAllAgentStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ALL_AGENT {
  REQ = 'GET_ALL_AGENT_REQ',
  GOT = 'GET_ALL_AGENT_GOT',
  ERR = 'GET_ALL_AGENT_ERR'
}

export const initialState: GetAllAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getAllAgent = (
  state: GetAllAgentStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetAllAgentStoreState => {
  switch (action.type) {
    case GET_ALL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ALL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ALL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAllAgent;
