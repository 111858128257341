import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getDocumentListClient';

export interface GetDocumentListClientStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DOCUMENT_LIST_CLIENT {
  REQ = 'GET_DOCUMENT_LIST_CLIENT_REQ',
  GOT = 'GET_DOCUMENT_LIST_CLIENT_GOT',
  ERR = 'GET_DOCUMENT_LIST_CLIENT_ERR'
}

export const initialState: GetDocumentListClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    items: []
  },
  error: {}
};

const getDocumentListClient = (
  state: GetDocumentListClientStoreState = initialState,
  action: {
    type: GET_DOCUMENT_LIST_CLIENT;
    data: ResponseData;
    error: {};
  }
): GetDocumentListClientStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DOCUMENT_LIST_CLIENT.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_DOCUMENT_LIST_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDocumentListClient;
