import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ScrollTopComponent } from 'src/features/Common';
import { history } from 'src/shared/utils/History';
import { CreditProducts } from 'src/features/Clients/components/Tabs/CreditProducts/CreditProducts';
import { AccountProducts } from 'src/features/Clients/components/Tabs/AccountProducts/AccountProducts';
import {
  req as getCreditProducts,
  ResponseData as CreditProductsData
} from 'src/features/Clients/actions/getCreditProducts';
import {
  req as getPledges,
  ResponseData as PledgesData
} from 'src/features/Clients/actions/getPledges';
import { CLIENTS_PRODUCT_TABS } from 'src/features/Clients/types';
import { Pledges } from 'src/features/Pledges/components/Pledges';
import { Menu } from 'src/features/Layouts/components';

interface StateToProps {
  creditProducts: CreditProductsData;
  statusCreditProducts: REQUEST_STATUSES;
  errorCreditProducts: ResponseError;
  pledges: PledgesData;
  statusPledges: REQUEST_STATUSES;
  errorPledges: ResponseError;
}

interface State {
  activeTab: string;
}

interface DispatchToProps {
  getCreditProducts: (bankInn: string) => void;
  getPledges: (bankInn: string) => void;
}

interface OwnProps {
  bankInn: string;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

class Clients extends React.Component<Props, State> {
  state = {
    activeTab: 'DEFAULT'
  };

  componentDidMount() {
    this.initFetch();
  }

  initFetch = () => {
    const { getCreditProducts, getPledges } = this.props;

    if (this.props.match.params.applicationTab) {
      this.setState({
        activeTab: this.props.match.params.applicationTab.toUpperCase()
      });
    }

    getCreditProducts(this.props.match.params.inn);
    getPledges(this.props.match.params.inn);
  };

  handleActiveTab = (tabName: string) => {
    const { pathname } = this.props.location;
    const baseUrl = pathname.replace(
      `/${this.state.activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    this.setState({ activeTab: tabName });
  };

  render() {
    const {
      creditProducts,
      statusCreditProducts,
      errorCreditProducts,
      pledges,
      statusPledges,
      errorPledges
    } = this.props;
    return (
      <ScrollTopComponent>
        <Menu
          activeTab={this.state.activeTab}
          handleActiveTab={this.handleActiveTab}
          tabs={CLIENTS_PRODUCT_TABS}
          isSubMenu
        />

        {this.state.activeTab === 'DEFAULT' && (
          <CreditProducts
            creditProducts={creditProducts}
            status={statusCreditProducts}
            error={errorCreditProducts}
          />
        )}

        {this.state.activeTab === 'PLEDGES' && (
          <Pledges
            pledges={pledges}
            status={statusPledges}
            error={errorPledges}
          />
        )}

        {this.state.activeTab === 'ACCOUNTS' && (
          <AccountProducts
            pledges={pledges}
            status={statusPledges}
            error={errorPledges}
          />
        )}
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Clients }: CRM) => ({
  creditProducts: Clients.getCreditProducts.data,
  statusCreditProducts: Clients.getCreditProducts.status,
  errorCreditProducts: Clients.getCreditProducts.error,
  pledges: Clients.getPledges.data,
  statusPledges: Clients.getPledges.status,
  errorPledges: Clients.getPledges.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCreditProducts, getPledges }, dispatch);

const ClientsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Clients)
);

export { ClientsListConnect as ClientsList };
