import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getLeadsList';

import { GET_LEADS_LIST } from '../reducers/getLeadsList';
import LeadsApi from '../api';
import { LEAD_LIST_TYPE } from '../types';

function* getLeadsList(action: {
  type: GET_LEADS_LIST;
  data: RequestData;
  leadListType: LEAD_LIST_TYPE;
}): Generator {
  try {
    let res;
    switch (action.leadListType) {
      case LEAD_LIST_TYPE.ALL:
        res = yield call(LeadsApi.getLeadList, action.data);
        break;
      case LEAD_LIST_TYPE.NEW:
        res = yield call(LeadsApi.getNewLeads, action.data);
        break;
      case LEAD_LIST_TYPE.DEAL_SUCCEED:
        res = yield call(LeadsApi.getLeadList, {
          ...action.data,
          status: 'deal_succeed'
        });
        break;
      case LEAD_LIST_TYPE.IN_WORK:
        res = yield call(LeadsApi.getLeadList, {
          ...action.data,
          status: 'in_work',
          user: 'my'
        });
        break;
      case LEAD_LIST_TYPE.TRASHED:
        res = yield call(LeadsApi.getTrashedLeads, action.data);
        break;
      case LEAD_LIST_TYPE.MY:
        res = yield call(LeadsApi.getMyLeads, action.data);
        break;
    }

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadsListSaga(): Generator {
  yield takeLatest(GET_LEADS_LIST.REQ, getLeadsList);
}
