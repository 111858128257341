import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInYears, format, parseISO } from 'date-fns';
import { REQUEST_STATUSES, CRM, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Loader,
  Pagination,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getCompaniesListById,
  ResponseData
} from 'src/features/CompaniesLists/actions/getCompaniesListById';
import {
  req as getInteractionSummary,
  reset as resetInteractionSummary,
  ResponseData as ResInteractionSummary
} from 'src/features/CompaniesLists/actions/getInteractionSummary';
import {
  req as getCompaniesOfCompanies,
  ResponseData as ResGetCompaniesOfCompanies,
  RequestData as ReqGetCompaniesOfCompanies
} from 'src/features/CompaniesLists/actions/getCompaniesOfCompanies';
import { req as postCompaniesOfCompanies } from 'src/features/CompaniesLists/actions/postCompaniesOfCompanies';
import { req as postAllInListRefresh } from 'src/features/CompaniesLists/actions/postAllInListRefresh';
import { Modal, ScrollTopComponent } from 'src/features/Common';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { openModal, closeModal } from 'Modal/actions/toggleModal';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  ActivitiesQtyContainer,
  ActivityContainer,
  ButtonForm,
  Center,
  DataRow,
  Description,
  DescriptionArea,
  DownloadButton,
  FiltersStyled,
  FormModal,
  HeaderContainer,
  InnInputContainer,
  InputArea,
  LastContactContainer,
  LowerNum,
  PreApproval,
  PreApprovalTH,
  Spinner,
  TableThWrapper
} from './styled';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import {
  faAddressBook,
  faBriefcase,
  faEnvelopeOpen,
  faPhone,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  companiesList: ResGetCompaniesOfCompanies;
  statusCompaniesList: REQUEST_STATUSES;
  errorCompaniesList: ResponseError;
  company: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusPostCompaniesOfCompanies: REQUEST_STATUSES;
  interactionSummary: ResInteractionSummary[];
  statusInteractionSummary: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompaniesListById: (id: string) => void;
  getCompaniesOfCompanies: (
    id: string,
    data: ReqGetCompaniesOfCompanies
  ) => void;
  postCompaniesOfCompanies: (id: string, companies: string) => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  postAllInListRefresh: (id: string) => void;
  getInteractionSummary: (inn: string[]) => void;
  resetInteractionSummary: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CompaniesListById: React.FC<Props> = ({
  getCompaniesOfCompanies,
  company,
  status,
  error,
  getCompaniesListById,
  companiesList,
  statusCompaniesList,
  postCompaniesOfCompanies,
  statusPostCompaniesOfCompanies,
  openModal,
  closeModal,
  postAllInListRefresh,
  getInteractionSummary,
  resetInteractionSummary,
  interactionSummary,
  statusInteractionSummary,
  history,
  match
}) => {
  const [companyInn, setCompanyInn] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [innList, setInnList] = React.useState('');
  const [isRefresh, setIsRefresh] = React.useState(false);

  React.useEffect(
    () => {
      if (match.params.id) {
        getCompaniesListById(match.params.id);
      }
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      if (statusCompaniesList === REQUEST_STATUSES.REQUEST) {
        resetInteractionSummary();
      }
    },
    [statusCompaniesList]
  );

  React.useEffect(
    () => {
      if (match.params.id) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;
        const inn = searchParams.get('companyInn') || '';
        const companyName = searchParams.get('companyName') || '';

        setCompanyInn(inn);
        setCompanyName(companyName);

        getCompaniesOfCompanies(match.params.id, { page });
      }
    },
    [match.params.id, history.location.search, statusPostCompaniesOfCompanies]
  );

  React.useEffect(
    () => {
      if (statusPostCompaniesOfCompanies === REQUEST_STATUSES.GOT) {
        closeModal();
      }
    },
    [statusPostCompaniesOfCompanies]
  );

  const handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handlePostCompanies = () => {
    const concatInn = innList.split(',').map(item => item.trim());
    const resultString = concatInn.join(' ');

    setInnList('');
    postCompaniesOfCompanies(match.params.id, resultString);
  };

  const allInListRefresh = () => {
    setIsRefresh(true);
    postAllInListRefresh(match.params.id);
    openModal({ name: MODAL_NAMES.ALL_IN_LIST_REFRESH });
  };

  const handleSetInteractionSummary = () => {
    const allInn = companiesList.items.map(item => item.companyINN);
    getInteractionSummary(allInn);
  };

  return (
    <ScrollTopComponent>
      <HeaderContainer>
        <div>
          <div>
            <p>Список #{match.params.id}</p>
            {status === REQUEST_STATUSES.GOT && (
              <ApplicationTitle>{company.name}</ApplicationTitle>
            )}
          </div>

          <Button
            label="Обновить скоринг"
            onClick={allInListRefresh}
            disabled={isRefresh}
          />
        </div>

        <div>
          {companiesList.items.length > 0 && (
            <DownloadButton
              href={`/api/companies_of_companies_list/${
                match.params.id
              }/download`}
            >
              Скачать в формате Excel
            </DownloadButton>
          )}

          <Button
            label="Добавить в список"
            onClick={() => openModal({ name: MODAL_NAMES.INN_COMPANIES_ADDED })}
          />
        </div>
      </HeaderContainer>

      <FiltersStyled>
        <InnInputContainer>
          <Input
            value={companyInn}
            label="ИНН Компании"
            name="companyInn"
            placeholder={InnPlaceholders.entity}
            onChange={handleFilter}
          />
        </InnInputContainer>

        <InnInputContainer>
          <Input
            value={companyName}
            label="Наименование клиента:"
            name="companyName"
            onChange={handleFilter}
          />
        </InnInputContainer>
      </FiltersStyled>

      {statusCompaniesList === REQUEST_STATUSES.REQUEST && <Loader />}
      {statusCompaniesList === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="4%">ИНН</TableThStyled>
              <TableThStyled width="10%">Наименование</TableThStyled>
              <TableThStyled width="3%">Год</TableThStyled>
              <TableThStyled width="10%">
                <div>Выручка</div>
                <div>Чистая прибыль</div>
                <div>Капитал и резервы</div>
              </TableThStyled>
              <TableThWrapper colSpan="3">
                <PreApprovalTH>
                  Предварительные одобрения кредитования без залога
                </PreApprovalTH>

                <TableThStyled width="10%">Самый дешевый</TableThStyled>
                <TableThStyled width="10%">Максимальный лимит</TableThStyled>
                <TableThStyled width="10%">Максимальный срок</TableThStyled>
              </TableThWrapper>
              <TableThStyled width="5%">Активности</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {companiesList.items.map((company, key) => (
              <TableRowStyled key={key}>
                <td>{company.companyINN}</td>
                <td>
                  {company.companyName ? company.companyName : 'не заполнено'}
                </td>
                <td>{company.financialsYear ? company.financialsYear : '-'}</td>
                <td>
                  <DataRow>
                    {!!company.revenue ? (
                      <>
                        <p>{company.revenue.toLocaleString('ru-RU')}</p>
                        <p>тыс. руб.</p>
                      </>
                    ) : (
                      'нет данных'
                    )}
                  </DataRow>
                  <DataRow>
                    {company.netIncome && company.netIncome >= 0 ? (
                      <>
                        <p>{company.netIncome.toLocaleString('ru-RU')}</p>
                        <p>тыс. руб.</p>
                      </>
                    ) : company.netIncome < 0 ? (
                      <LowerNum>
                        {company.netIncome.toLocaleString('ru-RU')} тыс. руб.
                      </LowerNum>
                    ) : (
                      'нет данных'
                    )}
                  </DataRow>
                  <DataRow>
                    {company.capital && company.capital >= 0 ? (
                      <>
                        <p>{company.capital.toLocaleString('ru-RU')}</p>
                        <p> тыс. руб.</p>
                      </>
                    ) : company.capital < 0 ? (
                      <LowerNum>
                        {company.capital.toLocaleString('ru-RU')} тыс. руб.
                      </LowerNum>
                    ) : (
                      'нет данных'
                    )}
                  </DataRow>
                </td>
                <td>
                  <PreApproval>
                    <div>
                      {company.lowestRateName === null
                        ? '-'
                        : company.lowestRateName}
                    </div>
                    <div>
                      {company.lowestRateVolume === null
                        ? '-'
                        : formSumStringThousands(company.lowestRateVolume)}{' '}
                      руб.
                    </div>
                    <div>
                      {`${
                        company.lowestRate === null
                          ? '-'
                          : company.lowestRate.toFixed(1)
                      } % годовых,`}{' '}
                      {company.lowestRateTerm === null
                        ? '-'
                        : company.lowestRateTerm}{' '}
                      мес.
                    </div>
                  </PreApproval>
                </td>
                <td>
                  <PreApproval>
                    <div>
                      {company.maxVolumeName === null
                        ? '-'
                        : company.maxVolumeName}
                    </div>
                    <div>
                      {company.maxVolume === null
                        ? '-'
                        : formSumStringThousands(company.maxVolume)}{' '}
                      руб.
                    </div>
                    <div>
                      {`${
                        company.maxVolumeRate === null
                          ? '-'
                          : company.maxVolumeRate.toFixed(1)
                      } % годовых,`}{' '}
                      {company.maxVolumeTerm === null
                        ? '-'
                        : company.maxVolumeTerm}{' '}
                      мес.
                    </div>
                  </PreApproval>
                </td>
                <td>
                  <PreApproval>
                    <div>
                      {company.longestTermName === null
                        ? '-'
                        : company.longestTermName}
                    </div>
                    <div>
                      {company.longestTermVolume === null
                        ? '-'
                        : formSumStringThousands(
                            company.longestTermVolume
                          )}{' '}
                      руб.
                    </div>
                    <div>
                      {`${
                        company.longestTermRate === null
                          ? '-'
                          : company.longestTermRate.toFixed(1)
                      } % годовых,`}{' '}
                      {company.longestTerm === null ? '-' : company.longestTerm}{' '}
                      мес.
                    </div>
                  </PreApproval>
                </td>
                <td>
                  {key === 0 &&
                    statusInteractionSummary === REQUEST_STATUSES.NONE && (
                      <Button
                        label="Загрузить"
                        onClick={handleSetInteractionSummary}
                      />
                    )}
                  {statusInteractionSummary === REQUEST_STATUSES.GOT ? (
                    <ActivityContainer>
                      <ActivitiesQtyContainer
                        color1={
                          interactionSummary[key].activitiesQty === 0 ||
                          differenceInYears(
                            new Date(),
                            parseISO(interactionSummary[key].lastActivity)
                          ) >= 1
                        }
                        color2={
                          differenceInYears(
                            new Date(),
                            parseISO(interactionSummary[key].lastActivity)
                          ) >= 1
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            interactionSummary[key].lastActivityType === 1 ||
                            !interactionSummary[key].lastActivityType
                              ? faPhone
                              : interactionSummary[key].lastActivityType === 2
                                ? faBriefcase
                                : interactionSummary[key].lastActivityType ===
                                    3 && faEnvelopeOpen
                          }
                        />
                        <div>
                          <p>{interactionSummary[key].activitiesQty}</p>
                          {interactionSummary[key].activitiesQty > 0 && (
                            <p>
                              {format(
                                new Date(interactionSummary[key].lastActivity),
                                'dd.MM.yyyy'
                              )}
                            </p>
                          )}
                        </div>
                      </ActivitiesQtyContainer>
                      <LastContactContainer
                        color1={interactionSummary[key].contactsQty === 0}
                      >
                        <FontAwesomeIcon icon={faAddressBook} />
                        <div>
                          <p>{interactionSummary[key].contactsQty}</p>
                          {interactionSummary[key].contactsQty > 0 && (
                            <p>
                              {format(
                                new Date(interactionSummary[key].lastContact),
                                'dd.MM.yyyy'
                              )}
                            </p>
                          )}
                        </div>
                      </LastContactContainer>
                    </ActivityContainer>
                  ) : (
                    statusInteractionSummary === REQUEST_STATUSES.REQUEST && (
                      <Center>
                        <Spinner icon={faSpinner} />
                      </Center>
                    )
                  )}
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={companiesList} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />

      <Modal name={MODAL_NAMES.INN_COMPANIES_ADDED}>
        <FormModal>
          <p>Введите ИНН добавляемых компаний</p>
          <DescriptionArea>
            <Description>
              <p>Можно ввести не более 1 000 компаний (ИНН) за 1 запрос.</p>
              <p>Разделителем может быть любой нечисловой символ</p>
            </Description>
          </DescriptionArea>

          <InputArea>
            <textarea
              value={innList}
              placeholder="1111111111, 2222222222, 3333333333, ...&#10;&#10;или&#10;&#10;1111111111&#10;2222222222&#10;3333333333"
              onChange={e => setInnList(e.currentTarget.value)}
            />
          </InputArea>

          <div>
            <ButtonForm
              label="+ Добавить"
              onClick={handlePostCompanies}
              warning={innList.length > 110000}
              disabled={!innList}
            />
            {statusPostCompaniesOfCompanies === REQUEST_STATUSES.REQUEST && (
              <Spinner icon={faSpinner} />
            )}
          </div>
        </FormModal>
      </Modal>

      <Modal name={MODAL_NAMES.ALL_IN_LIST_REFRESH}>
        <FormModal>
          <DescriptionArea>
            <Description>
              Обновление данных запущено в фоновом режиме и займет около{' '}
              {Math.ceil(companiesList.totalItems / 100)} часов
            </Description>
          </DescriptionArea>
        </FormModal>
      </Modal>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ CompaniesListCRM }: CRM) => ({
  companiesList: CompaniesListCRM.getCompaniesOfCompanies.data,
  statusCompaniesList: CompaniesListCRM.getCompaniesOfCompanies.status,
  errorCompaniesList: CompaniesListCRM.getCompaniesOfCompanies.error,
  company: CompaniesListCRM.getCompaniesListById.data,
  status: CompaniesListCRM.getCompaniesListById.status,
  error: CompaniesListCRM.getCompaniesListById.error,
  statusPostCompaniesOfCompanies:
    CompaniesListCRM.postCompaniesOfCompanies.status,
  interactionSummary: CompaniesListCRM.getInteractionSummary.data,
  statusInteractionSummary: CompaniesListCRM.getInteractionSummary.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompaniesListById,
      getCompaniesOfCompanies,
      postCompaniesOfCompanies,
      openModal,
      closeModal,
      postAllInListRefresh,
      getInteractionSummary,
      resetInteractionSummary
    },
    dispatch
  );

const CompaniesListByIdConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompaniesListById)
);

export { CompaniesListByIdConnect as CompaniesListById };
