import { combineReducers } from 'redux';
import {
  getApplicationBank,
  GetApplicationBankStoreState
} from './getApplicationBank';
import getDocumentList, {
  GetDocumentListByBankStoreState
} from './getDocumentList';
import getAllOfBank, { GetAllOfBankStoreState } from './getAllOfBank';

export interface BankRoleReducers {
  getApplicationBank: GetApplicationBankStoreState;
  getDocumentList: GetDocumentListByBankStoreState;
  getAllOfBank: GetAllOfBankStoreState;
}

export const BankRole = combineReducers({
  getApplicationBank,
  getDocumentList,
  getAllOfBank
});
