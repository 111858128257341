import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  req as getLeads,
  ResponseData as LeadsData
} from 'src/features/ApplicationForCompanies/actions/getLeads';
import {
  req as getApplications,
  ResponseData as ApplicationsData
} from 'src/features/ApplicationForCompanies/actions/getApplications';
import { ScrollTopComponent } from 'src/features/Common';
import { AGENT_APPLICATION_TABS } from 'src/features/ApplicationForCompanies/types';
import { LeadsList } from 'src/features/ApplicationForCompanies/components/Tabs/Leads/Leads';
import { ApplicationsList } from 'src/features/ApplicationForCompanies/components/Tabs/Applications/Applications';
import { Menu } from 'src/features/Layouts/components';

interface StateToProps {
  applications: ApplicationsData;
  statusApplications: REQUEST_STATUSES;
  errorApplications: ResponseError;
  leads: LeadsData;
  statusLeads: REQUEST_STATUSES;
  errorLeads: ResponseError;
}

interface DispatchToProps {
  getLeads: (inn: string) => void;
  getApplications: (inn: number | string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const AgentApplicationList: React.FC<Props> = ({
  getApplications,
  applications,
  statusApplications,
  errorApplications,
  getLeads,
  leads,
  statusLeads,
  errorLeads,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(() => {
    initFetch();
  }, []);

  const initFetch = () => {
    if (match.params.applicationTab) {
      setActiveTab(match.params.applicationTab.toUpperCase());
    } else if (match.params.tab === 'requests') {
      setActiveTab(match.params.tab.toUpperCase());
    }

    getLeads(match.params.inn);
    getApplications(match.params.inn);
  };

  const handleActiveTab = (tabName: string) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace({ pathname: newPathname, search: 'page=1' });
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={AGENT_APPLICATION_TABS}
        isSubMenu
      />

      {activeTab === 'DEFAULT' && (
        <LeadsList leads={leads} status={statusLeads} error={errorLeads} />
      )}

      {activeTab === 'REQUESTS' && (
        <ApplicationsList
          applications={applications}
          status={statusApplications}
          error={errorApplications}
        />
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ LeadsOfCompanies }: CRM) => ({
  applications: LeadsOfCompanies.getApplications.data,
  statusApplications: LeadsOfCompanies.getApplications.status,
  errorApplications: LeadsOfCompanies.getApplications.error,
  leads: LeadsOfCompanies.getLeads.data,
  statusLeads: LeadsOfCompanies.getLeads.status,
  errorLeads: LeadsOfCompanies.getLeads.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getLeads, getApplications }, dispatch);

const AgentApplicationListConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AgentApplicationList)
);

export { AgentApplicationListConnect as AgentApplicationList };
