import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { Document } from 'Application/types';

import {
  notEmptyArrayRule,
  innValidationEntityRule
} from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import {
  SlimFilesRequired,
  FILE_UPLOAD_BLOCK_TYPE
} from '../BorrowerCompanyFileUploadBlock';
import {
  FinancialUpTo500Required,
  getUpperDocumentsMetaInfoExtendedRequired,
  getUpperDocumentsMetaInfoRequired
} from '../BorrowerCompanyFileUploadBlock/ContractFinancingFileUploadBlocks/Util';

export function checkIfDocumentsContainMetaInfoFrom(
  documents: Document[],
  obj
) {
  const metaInfo = documents.map(d => d.metaInfo);
  return Object.values(obj).contains(metaInfo);
}

export const createValidaton = (dynamicValidatiorFields: string[]) => {
  const validationRules = dynamicValidatiorFields.reduce(
    (validatorObj, field) => {
      const fieldName = field.split('-')[0];

      const setValidationRules = () => {
        switch (fieldName) {
          case 'toString()':
            return [
              {
                rule: requiredRule,
                message: ValidatorMessages.requiredField
              }
            ];
          case 'individualNumber':
            return [
              {
                rule: innValidationEntityRule,
                message: ValidatorMessages.codeValid
              },
              {
                rule: requiredRule,
                message: ValidatorMessages.requiredField
              }
            ];
          case 'roles':
            return [
              {
                rule: notEmptyArrayRule,
                message: ValidatorMessages.borrowerCompanyRoles
              }
            ];
          case 'documents':
            return [
              {
                // TODO refactor if not Connect wrapped and fix for production build where names are the same
                rule: (
                  documents,
                  fileUploadBlockType: FILE_UPLOAD_BLOCK_TYPE
                ) => {
                  switch (fileUploadBlockType) {
                    case FILE_UPLOAD_BLOCK_TYPE.GUARANTEES_SLIM:
                      return checkIfDocumentsContainMetaInfoFrom(
                        documents,
                        SlimFilesRequired
                      );
                    case FILE_UPLOAD_BLOCK_TYPE.CONTRACT_UP_TO_500_THOUSANDS:
                      return checkIfDocumentsContainMetaInfoFrom(
                        documents,
                        FinancialUpTo500Required
                      );
                    case FILE_UPLOAD_BLOCK_TYPE.CONTRACT_FROM_30MIL_TO_100MIL:
                      return checkIfDocumentsContainMetaInfoFrom(
                        documents,
                        getUpperDocumentsMetaInfoExtendedRequired()
                      );
                    case FILE_UPLOAD_BLOCK_TYPE.CONTRACT_FROM_500THOUSANDS_TO_30MIL:
                      return checkIfDocumentsContainMetaInfoFrom(
                        documents,
                        getUpperDocumentsMetaInfoRequired()
                      );
                    case FILE_UPLOAD_BLOCK_TYPE.DEFAULT:
                      return notEmptyArrayRule(documents);
                  }
                },
                message: ValidatorMessages.applicationFilesEmpty,
                ruleId: 'borrowerCompanyFileUploadBlockType'
              }
            ];
          default:
            return [];
        }
      };

      validatorObj[field] = setValidationRules();

      return validatorObj;
    },
    {}
  );

  return new Validator<any>({
    // groupDocuments: [
    //   {
    //     rule: (v: any[], disableApplicationDocumentsUpload: boolean) =>
    //       disableApplicationDocumentsUpload ? true : notEmptyArrayRule(v),
    //     message: ValidatorMessages.applicationFilesEmpty,
    //     ruleId: "disableApplicationDocumentsUpload"
    //   }
    // ],
    ...validationRules
  });
};
