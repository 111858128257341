import * as React from 'react';
import { Error } from 'shared/ui/Error';
import { Label } from 'shared/ui/Label';
import { RadioButton } from './RadioButton/RadioButton';
import { RadioButtonGroupStyled, RadioContainer } from './styles';

interface RadioBtn {
  value: any;
  label: string;
  children?: any;
}

interface Props {
  radioBtns: RadioBtn[];
  name: string;
  label?: string;
  required?: boolean;
  // TODO rename
  keyValue: any;
  className?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: string;
}

export const RadioButtonGroup: React.FC<Props> = ({
  name,
  label = '',
  required = false,
  keyValue,
  onChange,
  className,
  radioBtns,
  error
}: Props) => (
  <RadioButtonGroupStyled
    legendId={name}
    className={!!className ? className : ''}
  >
    <Label name={name} label={label} required={required} />
    <RadioContainer>
      {radioBtns.map((item, key) => (
        <RadioButton
          key={key}
          name={name}
          aria-labelledby={name}
          value={item.value}
          label={item.label}
          keyValue={keyValue}
          onChange={onChange}
        >
          {!!item.children && item.children}
        </RadioButton>
      ))}
    </RadioContainer>
    <Error error={error} />
  </RadioButtonGroupStyled>
);
