import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostCompaniesListCRMStoreState extends Reducer {
  id: string;
}

export enum POST_COMPANIES_LIST_CRM {
  REQ = 'POST_COMPANIES_LIST_CRM_REQ',
  GOT = 'POST_COMPANIES_LIST_CRM_GOT',
  ERR = 'POST_COMPANIES_LIST_CRM_ERR'
}

export const initialState: PostCompaniesListCRMStoreState = {
  status: REQUEST_STATUSES.NONE,
  id: null,
  error: {}
};

const postCompaniesListCRM = (
  state: PostCompaniesListCRMStoreState = initialState,
  action: {
    type: string;
    id: string;
    error: {};
  }
): PostCompaniesListCRMStoreState => {
  switch (action.type) {
    case POST_COMPANIES_LIST_CRM.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_COMPANIES_LIST_CRM.GOT:
      return {
        ...state,
        id: action.id,
        status: REQUEST_STATUSES.GOT
      };
    case POST_COMPANIES_LIST_CRM.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postCompaniesListCRM;
