import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetDebtorCounterSidebarStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  supplyContractsQty: number;
  epaNewQty: number;
  epaInspectedQty: number;
  newSuppliersQty: number;
  factoring: boolean;
  discounting: boolean;
  discountLimit: number;
}

export enum GET_DEBTOR_COUNTER_SIDEBAR {
  REQ = 'GET_DEBTOR_COUNTER_SIDEBAR_REQ',
  GOT = 'GET_DEBTOR_COUNTER_SIDEBAR_GOT',
  ERR = 'GET_DEBTOR_COUNTER_SIDEBAR_ERR'
}

export const initialState: GetDebtorCounterSidebarStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    supplyContractsQty: 0,
    epaNewQty: 0,
    epaInspectedQty: 0,
    newSuppliersQty: 0,
    factoring: false,
    discounting: false,
    discountLimit: 0
  },
  error: {}
};

const getDebtorCounterSidebar = (
  state: GetDebtorCounterSidebarStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetDebtorCounterSidebarStoreState => {
  switch (action.type) {
    case GET_DEBTOR_COUNTER_SIDEBAR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_DEBTOR_COUNTER_SIDEBAR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_DEBTOR_COUNTER_SIDEBAR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getDebtorCounterSidebar;
