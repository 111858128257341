import styled from 'styled-components';
import { rr, navyBlue, gray } from 'shared/styled';

export const RegistrationLinksBlock = styled.p`
  margin: 15px 0 -10px 0;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  font-family: ${rr};
  font-size: 1.2rem;
  color: ${gray};

  a {
    color: ${navyBlue};
  }
`;
