import styled from 'styled-components';
import { lighterBlue, rr } from 'shared/styled';

export const ListSection = styled.section`
  margin-top: 35px;
  width: 70vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListTitles = styled.div`
  font-family: ${rr};
  margin-bottom: 5px;
  width: 1190px;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const ListTitlesItem = styled.p`
  width: 170px;
  font-family: ${rr};
  font-size: 0.9em;
  color: #7a7d83;
`;

export const ListItemsRow = styled.div`
  font-family: ${rr};
  margin-top: 10px;
  padding-bottom: 5px;
  width: 1190px;
  height: auto;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e3e4e9;
  &:last-child {
    border-bottom: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 170px;
  height: auto;
`;

export const ListItemP = styled.p`
  width: 100px;
  font-family: ${rr};
  font-size: 1em;
  margin: 0;
`;

export const DownloadBtn = styled.button.attrs({
  type: 'button',
  ['aria-hidden']: true
})`
  width: 14px;
  height: 17px;
  margin-right: 10px;
  padding: 0;

  border: none;
  outline: none;
  cursor: pointer;

  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
    <svg viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
      <path fill="rgb(63, 78, 101);" opacity="0.8" d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z"/>
    </svg>
  ');
`;

export const DownloadLink = styled.a.attrs({
  ['aria-label']: 'download-document',
  download: true
})`
  height: 15px;
  width: 15px;

  display: inline-block;

  padding: 0;

  cursor: pointer;
  outline: none;
  border: none;

  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 17">
      <path fill="rgb(63,78,101)" d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z"/>
    </svg>
  ');
`;

export const FilterArea = styled.div`
  width: 550px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const DateFilterStyled = styled.div`
  width: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: fex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const Dates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-beetween;
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
  p {
    font-family: ${rr};

    margin: 0 10px 0 0;
  }
  input {
    padding: 10px 10px;
    height: 40px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;
