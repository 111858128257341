import styled from 'styled-components';

export const ActionButtonsBlockStyled = styled.section`
  width: 100%;

  margin-top: 30px;
  padding: 20px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
`;
