import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { toFloatFormatter } from 'src/shared/utils/Utils';

import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import {
  STORE,
  REQUEST_STATUSES,
  USER_PERMISSIONS,
  ResponseError,
  CRM
} from 'globaltypes';
import { ApplicationTypes, InnPlaceholders } from 'shared/constants';

import {
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES,
  DOCUMENT_UPLOAD_TYPE,
  GuaranteesApplicationWrite
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { req as updateInitializationFormData } from 'Application/actions/updateInitializationFormData';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import {
  req as getClientsList,
  ResponseData as ClientsData,
  RequestData as ReqClientsList
} from 'src/features/Clients/actions/getClientsList';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import {
  RequestDataType as DeleteDocumentRequestDataType,
  SagaRequestDataType as DeleteDocumentSagaRequestDataType
} from 'Application/reducers/deleteDocument';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { setGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';
import { SetGuaranteesApplicationType } from 'src/features/Application/reducers/setGuaranteesFormData';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import { APPLICATION_PROCESS_MODES } from 'Application/reducers/setApplicationProcessMode';

import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationFormContent,
  ApplicationInputsColumn,
  ApplicationForm
} from '../../styles';
import {
  CheckboxStyled,
  ClientList,
  InputContainer,
  ModalContainer,
  PopupContentClients,
  TableRow
} from './styles';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { PopupContainer } from 'src/features/Layouts/components/InnPopup/styled';
import { StaticContext } from 'react-router';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { Pagination } from 'src/features/Layouts/components';
import { ClientsListType } from 'src/features/Clients/types';
import { Button } from 'shared/ui/Button';

interface State {
  submitClicked: boolean;
  isInfoPopup: boolean;
  clientsIsVisible: boolean;
  code: string;
  clientCompanyName: string;
  selectedRow: number;
}

interface LocationStateProps {
  financingType: APPLICATION_TYPES;
  from: string;
  amount: number;
}

interface OwnProps {
  path: string;
}

interface StateToProps
  extends SetInitializationApplicationType,
    SetGuaranteesApplicationType {
  allowFlags: GetApplicationFlagsResponseDataType;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  userCompanyInn: string;
  applicationProcessMode: APPLICATION_PROCESS_MODES;
  uploadStatus: REQUEST_STATUSES;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  clients: ClientsData;
  statusClients: REQUEST_STATUSES;
}

interface DispatchToProps {
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  updateInitializationFormData: (
    data: SetInitializationApplicationType
  ) => void;
  setGuaranteesFormData: (data: SetGuaranteesApplicationType) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
  openModal: (data: OpenModalDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getClientsList: (data?: ReqClientsList) => void;
}

type Props = RouteComponentProps<{}, StaticContext, LocationStateProps> &
  StateToProps &
  DispatchToProps &
  OwnProps;

class InitializationFormStep1 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    submitClicked: false,
    isInfoPopup: false,
    clientsIsVisible: false,
    code: '',
    clientCompanyName: '',
    selectedRow: null
  };

  clientsModalRef = React.createRef();

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.financingType) {
      this.props.setInitializationFormData({
        financingType: this.props.location.state.financingType
      });
    }
    if (this.props.code && this.props.code.length >= 10) {
      this.props.getCompanyByInn({ inn: this.props.code });
    }
    if (
      this.props.roles.includes('Applicant') ||
      this.props.roles.includes('Client')
    ) {
      this.props.setInitializationFormData({ code: this.props.userCompanyInn });

      if (!!this.props.userCompanyInn && !!this.props.userCompanyInn.length) {
        this.props.getCompanyByInn({ inn: this.props.userCompanyInn });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.code !== this.props.code) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevProps.code !== this.props.code &&
      this.props.code &&
      this.props.code.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.props.code });
    }
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber = name === 'amount' || name === 'term';
    const isCheckbox = type === 'checkbox';
    const floated = toFloatFormatter(value);
    this.props.setInitializationFormData({
      [name]: isCheckbox ? checked : isToNumber ? floated : value
    });
  };

  onChangeGuarantees = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setGuaranteesFormData({
      [name]: value
    } as { [key in keyof GuaranteesApplicationWrite]: any });
  };

  onFilesUpload = files => {
    this.props.uploadDocuments({
      id: this.props.id,
      files,
      type: DOCUMENT_UPLOAD_TYPE.AGREEMENT
    });
  };

  onFilesRemove = (data: DeleteDocumentRequestDataType) => {
    this.props.deleteDocument({
      ...data,
      subType: DOCUMENT_UPLOAD_TYPE.AGREEMENT
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    this.props.updateInitializationFormData({
      ...{
        id: this.props.id,
        refinancing: this.props.refinancing,
        clientCompanyName: this.props.clientCompanyName,
        code: this.props.code,
        amount: this.props.amount,
        term: this.props.term
      },
      ...(this.props.applicationProcessMode ===
        APPLICATION_PROCESS_MODES.CREATE && {
        financingType: this.props.financingType
      })
    });

    this.props.history.push({
      pathname: `${this.props.location.pathname}/options`,
      state: TRANSITION_DIRECTIONS.FORWARD
    });
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
    this.props.setInitializationFormData({
      clientCompanyName: this.props.companyByInn.companyShortName
    });
  };

  handleOpenModal = () => {
    this.setState({ clientsIsVisible: true });
    this.props.getClientsList();
  };

  handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.clientsModalRef.current &&
      !(this.clientsModalRef.current as Element).contains(target)
    ) {
      this.setState({ clientsIsVisible: false });
    }
  };

  handleSelectClient = (client: ClientsListType, index: number) => {
    this.setState({
      selectedRow: index,
      code: client.companyINN,
      clientCompanyName: client.companyName
    });
  };

  render() {
    const {
      uploadStatus,
      agreementDocuments,
      openModal,
      applicationProcessMode,
      financingType,
      refinancing,
      clientCompanyName,
      code,
      amount,
      term,
      permissions,
      companyByInn,
      statusInn,
      allowFlags: { allowBlankFields },
      desiredBanks
    } = this.props;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    const amountRange = {
      min: 0,
      max:
        this.props.location.state &&
        this.props.location.state.from === `/cabinet/${role}/preapproved_limits`
          ? this.props.location.state.amount
          : 100000000000
    };
    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max]
    }).validate({
      ...this.props,
      code: this.props.code ? this.props.code : null
    });

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        {applicationProcessMode === APPLICATION_PROCESS_MODES.CREATE && (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        {applicationProcessMode === APPLICATION_PROCESS_MODES.EDIT && (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        )}

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="initialize-application-form"
            >
              <Select
                options={Object.keys(ApplicationTypes).map(type => ({
                  id: type,
                  name: ApplicationTypes[type]
                }))}
                value={financingType}
                label="Тип продукта"
                name="financingType"
                placeholder="Выберите тип продукта"
                required={true}
                disabled={true}
                error={errors.financingType}
                onChange={this.onChange}
              />

              <CheckboxStyled
                name="refinancing"
                label="Рефинансирование"
                checked={refinancing}
                onChange={this.onChange}
              />

              <PopupContainer>
                <InputContainer>
                  <Input
                    value={code}
                    label="ИНН"
                    name="code"
                    placeholder={InnPlaceholders.entity}
                    required={true}
                    error={errors.code}
                    onChange={this.onChange}
                  />

                  {this.props.roles.includes('ExternalAgent') && (
                    <Button
                      label="Выбрать из моих клиентов"
                      onClick={this.handleOpenModal}
                    />
                  )}
                </InputContainer>

                {this.props.code &&
                this.props.code.length < 10 ? null : statusInn ===
                REQUEST_STATUSES.ERROR ? (
                  <InnPopup>
                    <h2>
                      Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                      можете создать связку с введенным ИНН
                    </h2>
                  </InnPopup>
                ) : (
                  !this.state.isInfoPopup && (
                    <InnPopup
                      companyInfo={companyByInn}
                      onPopupClick={this.onPopupClick}
                    />
                  )
                )}
              </PopupContainer>

              <Input
                value={clientCompanyName}
                label="Компания заемщика"
                name="clientCompanyName"
                placeholder="Введите краткое наименование клиента"
                required={true}
                error={errors.clientCompanyName}
                onChange={this.onChange}
              />

              <InputRange
                value={amount.toFixed(2)}
                label="Сумма"
                name="amount"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                required={true}
                error={errors.amount}
                onChange={this.onChange}
              />

              <InputRange
                value={term.toString()}
                label="Срок"
                name="term"
                placeholder="Введите срок"
                min={1}
                max={10 * 12}
                step={1}
                sliderType={RANGE_SLIDER_TYPE.TERM}
                valueType={INPUT_RANGE_VALUE_TYPE.TERM}
                required={true}
                disabled={
                  this.props.location.state &&
                  this.props.location.state.from ===
                    `/cabinet/${role}/preapproved_limits`
                }
                disableSlider={
                  this.props.location.state &&
                  this.props.location.state.from ===
                    `/cabinet/${role}/preapproved_limits`
                }
                error={errors.term}
                onChange={this.onChange}
              />

              {!permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
                <Textarea
                  onChange={this.onChangeGuarantees}
                  label="Пожелания по банкам"
                  required={!allowBlankFields}
                  error={allowBlankFields ? null : errors.desiredBanks}
                  name="desiredBanks"
                  value={desiredBanks}
                  placeholder="Напр., “любые банки” или “строго только банк XXX”"
                />
              )}

              <Button
                type="submit"
                label="Настроить параметры заявки"
                template="nextBtn"
                w="400px"
              />
            </ApplicationForm>
          </ApplicationInputsColumn>
        </ApplicationFormContent>

        <ModalContainer
          visible={this.state.clientsIsVisible}
          onClick={this.handleOutsideClick}
        >
          <PopupContentClients ref={this.clientsModalRef}>
            <h2>Выберите компанию или ИП для заявки</h2>

            {this.props.statusClients === REQUEST_STATUSES.GOT && (
              <ClientList>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="50%">Наименование</TableThStyled>
                      <TableThStyled width="50%">ИНН</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {this.props.clients.items.map((client, index) => (
                      <TableRow
                        key={index}
                        isSelected={this.state.selectedRow === index}
                        onClick={() => this.handleSelectClient(client, index)}
                      >
                        <td>{client.companyName}</td>
                        <td>{client.companyINN}</td>
                      </TableRow>
                    ))}
                  </tbody>
                </TableStyled>
              </ClientList>
            )}
            <Pagination list={this.props.clients} />
            <Button
              label="Выбрать клиента"
              disabled={!this.state.code && !this.state.clientCompanyName}
              onClick={() => {
                this.setState({ clientsIsVisible: false });
                this.props.setInitializationFormData({
                  code: this.state.code,
                  clientCompanyName: this.state.clientCompanyName
                });
              }}
            />
          </PopupContentClients>
        </ModalContainer>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application, User, SCF, Clients }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  uploadStatus: Application.uploadDocuments.status,
  applicationProcessMode: Application.setApplicationProcessMode.mode,
  permissions: User.getUserData.data.permissions,
  userCompanyInn: User.getUserData.data.companyInn,
  roles: User.getUserData.data.roles,
  clients: Clients.getClientsList.data,
  statusClients: Clients.getClientsList.status,
  ...Application.setInitializationFormData,
  ...Application.setGuaranteesFormData,
  allowFlags: Application.getApplicationFlags.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInitializationFormData,
      setGuaranteesFormData,
      updateInitializationFormData,
      uploadDocuments,
      deleteDocument,
      openModal,
      getCompanyByInn,
      getClientsList
    },
    dispatch
  );

const InitializationFormStep1Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InitializationFormStep1)
);

export { InitializationFormStep1Connect as InitializationFormStep1 };
