import { POST_ADD_TENDER_BY_ERUZ_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postAddTenderByEruzExternal';

export const req = (id: string, eruzId: string) => ({
  type: POST_ADD_TENDER_BY_ERUZ_EXTERNAL.REQ,
  id,
  eruzId
});

export const got = () => ({
  type: POST_ADD_TENDER_BY_ERUZ_EXTERNAL.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_TENDER_BY_ERUZ_EXTERNAL.ERR,
  error
});
