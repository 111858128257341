import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResponseError, REQUEST_STATUSES, CRM } from 'src/globaltypes';
import {
  req as getTenders,
  ResponseData
} from 'src/features/Tenders/actions/getTenders';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { ContainerCompaniesFilter } from 'src/features/Companies/components/CompaniesList/styled';
import { FilterByClient } from 'src/features/Filters/Tenders/FilterByClient/components/FilterByClient';
import { FilterBySupplier } from 'src/features/Filters/Tenders/FilterBySupplier/components/FilterBySupplier';
import { FilterByEruz } from 'src/features/Filters/Tenders/FilterByEruz/FilterByEruz';
import { TendersList } from './TendersList/TendersList';
import { TendersContainer } from './styled';

interface StateToProps {
  tenders: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getTenders: (data?) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const Tenders: React.FC<Props> = ({
  getTenders,
  tenders,
  status,
  error,
  history
}) => {
  const [customerInn, setCustomerInn] = React.useState('');
  const [supplierInn, setSupplierInn] = React.useState('');
  const [eruz, setEruz] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const customerInn = searchParams.get('customerInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';
      const eruz = searchParams.get('eruz') || '';

      setCustomerInn(customerInn);
      setSupplierInn(supplierInn);
      setEruz(eruz);

      getTenders({
        ...(customerInn && { customerInn }),
        ...(supplierInn && { supplierInn }),
        ...(eruz && { eruz })
      });
    },
    [history.location.search]
  );

  const updateFiltersInUrl = (name, value) => {
    const searchParams = new URLSearchParams(history.location.search);

    const inputToFilterMap = {
      customerInn: 'customerInn',
      supplierInn: 'supplierInn',
      eruz: 'eruz'
    };

    const filterKey = inputToFilterMap[name];

    if (filterKey) {
      if (value) {
        searchParams.set(filterKey, value);
      } else {
        searchParams.delete(filterKey);
      }

      history.push({ search: searchParams.toString() });
    }
  };

  return (
    <ScrollTopComponent>
      <TendersContainer>
        <ApplicationTitle>Закупки и тендеры</ApplicationTitle>

        <FiltersStyled direction="row">
          <ContainerCompaniesFilter>
            <FilterByClient
              inn={customerInn}
              onFilterByInn={({ name, inn }) => updateFiltersInUrl(name, inn)}
            />
          </ContainerCompaniesFilter>

          <ContainerCompaniesFilter>
            <FilterBySupplier
              inn={supplierInn}
              onFilterByInn={({ name, inn }) => updateFiltersInUrl(name, inn)}
            />
          </ContainerCompaniesFilter>

          <ContainerCompaniesFilter>
            <FilterByEruz
              eruz={eruz}
              onFilterByEruz={({ name, eruz }) =>
                updateFiltersInUrl(name, eruz)
              }
            />
          </ContainerCompaniesFilter>
        </FiltersStyled>

        <TendersList tenders={tenders} status={status} error={error} />
      </TendersContainer>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Tenders }: CRM) => ({
  tenders: Tenders.getTenders.data,
  status: Tenders.getTenders.status,
  error: Tenders.getTenders.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getTenders }, dispatch);

const TendersConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Tenders)
);

export { TendersConnect as Tenders };
