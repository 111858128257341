import { SET_PROPERTY_FINANCING_DATA_ACTIONS, SetPropertyFinancingFormData } from "../reducers/setPropertyFinancingFormData";

export const setPropertyFinancingFormData = (data: SetPropertyFinancingFormData) => ({
  type: SET_PROPERTY_FINANCING_DATA_ACTIONS.SET,
  data
});

export const resetPropertyFinancingFormData = () => ({
  type: SET_PROPERTY_FINANCING_DATA_ACTIONS.RESET
});
