import { APPLICATION_STATUSES } from 'Application/types';
import {
  ACTION_BUTTON_TEMPLATE,
  OwnProps as ActionButtonProps
} from 'Application/components';

export const agentBtnConstructor = (
  status: APPLICATION_STATUSES,
  mainApplicationId: number
): ActionButtonProps[] => {
  switch (status) {
    case APPLICATION_STATUSES.TRANSFERRED_TO_AGENT:
      return [
        {
          label: 'Взять в работу',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.IN_WORK
        },
        {
          label: 'Отказать',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT
        }
      ];
    case APPLICATION_STATUSES.AWAITING:
      return [
        {
          label: 'Отказать',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT
        }
      ];
    case APPLICATION_STATUSES.IN_WORK:
      return [
        {
          label: 'Отправить клиенту',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REQUEST,
          actionStatus: APPLICATION_STATUSES.AWAITING
        },
        {
          label: 'Отправить в банк',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus:
            mainApplicationId === 0
              ? APPLICATION_STATUSES.TRANSFERRED_TO_BANK
              : APPLICATION_STATUSES.REVIEW
        },
        {
          label: 'Отдать другому аналитику',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_AGENT
        },
        {
          label: 'Отказать',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT
        }
      ];
    case APPLICATION_STATUSES.TRANSFERRED_TO_BANK:
      if (mainApplicationId === 0)
        return [
          {
            label: 'Отправить в банк',
            actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
            actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_BANK
          },
          {
            label: 'Отказать',
            actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
            actionStatus: APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT
          }
        ];
    case APPLICATION_STATUSES.REVIEW:
      return [
        {
          label: 'Отказаться от заявки',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.USER_REJECT
        }
      ];
    case APPLICATION_STATUSES.DOCS_REQUEST:
      return [
        {
          label: 'Отправить клиенту',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REQUEST,
          actionStatus: APPLICATION_STATUSES.AWAITING
        },
        {
          label: 'Отправить в банк',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.REVIEW
        },
        {
          label: 'Отказать',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.EXTERNAL_AGENT_REJECT
        },
        {
          label: 'Отдать другому аналитику',
          actionTemplate: ACTION_BUTTON_TEMPLATE.REJECT,
          actionStatus: APPLICATION_STATUSES.TRANSFERRED_TO_AGENT
        }
      ];
    case APPLICATION_STATUSES.APPROVED:
      return [
        {
          label: 'Принять предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.TRANSFER,
          actionStatus: APPLICATION_STATUSES.AGENT_APPROVED
        },
        {
          label: 'Oтклонить предложение',
          actionTemplate: ACTION_BUTTON_TEMPLATE.DENIAL,
          actionStatus: APPLICATION_STATUSES.USER_DENIAL
        }
      ];
    case APPLICATION_STATUSES.BANK_REJECT:
    case APPLICATION_STATUSES.USER_REJECT:
    case APPLICATION_STATUSES.USER_DENIAL:
    case APPLICATION_STATUSES.SUCCESS:
      return [];
    default:
      return [];
  }
};
