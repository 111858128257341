import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCard,
  faBookmark,
  faTrashAlt,
  faLeaf,
  faMapSigns,
  faMoneyBillAlt
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { LEAD_LIST_TYPE } from 'src/features/Leads/types';
import { showQuantity } from 'pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { CabinetComponent } from '../EachPage';
import DifferenceBetweenLeadsAndApplicationsInfoPopup from 'src/features/Leads/components/DifferenceBetweenLeadsAndApplicationsInfoPopup/DifferenceBetweenLeadsAndApplicationsInfoPopup';
import { history } from 'src/shared/utils/History';
import { LeadsForm } from 'src/features/Leads/components/LeadsForm/LeadsForm';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';
import { ExternalLeadsList } from '../Pages/Leads/Leads';
import { Button } from 'shared/ui/Button';

function createLeadsListCabinetComponent({
  path,
  listType,
  icon,
  label,
  countName,
  Component
}) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        labelInfo={showQuantity(countName)}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const LeadButtonsSection: CabinetComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.CREATE_LEAD].isIn(permissions),
    renderSidebarLink: key => (
      <DifferenceBetweenLeadsAndApplicationsInfoPopup key={key} />
    )
  },
  {
    path: '/cabinet/partner/createLead',
    hasPermission: permissions =>
      [USER_PERMISSIONS.CREATE_LEAD].isIn(permissions),
    renderSidebarLink: key => (
      <Button
        label="Создать лид"
        key={key}
        template="addBtn"
        backgroundColor="#F69A0F"
        onClick={() => history.push('/cabinet/partner/createLead')}
        w="100%"
        h="50px"
      />
    ),
    render: location => <LeadsForm key={location.key} />
  }
];

export const LeadsSection: CabinetComponent[] = [
  {
    hasPermission: permissions =>
      [
        USER_PERMISSIONS.LEADS_MANAGER,
        USER_PERMISSIONS.LEADOGENERATOR,
        USER_PERMISSIONS.CREATE_LEAD
      ].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Лиды</SectionSeparatorStyled>
    )
  },
  // {
  //   hasPermission: permissions =>
  //     [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //   ...createLeadsListCabinetComponent({
  //     Component: LeadsList,
  //     path: '/cabinet/partner/leads/all',
  //     label: 'Все лиды',
  //     icon: <FontAwesomeIcon icon={faMapSigns} fixedWidth={true} />,
  //     listType: LEAD_LIST_TYPE.ALL,
  //     countName: 'allDeals'
  //   })
  // },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.EXTERNAL_AGENT].isIn(permissions),
    ...createLeadsListCabinetComponent({
      Component: ExternalLeadsList,
      path: '/cabinet/partner/leads/my',
      label: 'Мои лиды',
      icon: <FontAwesomeIcon icon={faLeaf} fixedWidth={true} />,
      listType: LEAD_LIST_TYPE.MY,
      countName: 'myLeadsQty'
    })
  }
  // {
  //   hasPermission: permissions =>
  //     [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //   ...createLeadsListCabinetComponent({
  //     Component: LeadsList,
  //     path: '/cabinet/partner/leads/new',
  //     label: 'Новые',
  //     icon: <FontAwesomeIcon icon={faIdCard} fixedWidth={true} />,
  //     listType: LEAD_LIST_TYPE.NEW,
  //     countName: 'newLeads'
  //   })
  // },
  // {
  //   hasPermission: permissions =>
  //     [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //   ...createLeadsListCabinetComponent({
  //     Component: LeadsList,
  //     path: '/cabinet/partner/leads/inwork',
  //     label: 'В работе',
  //     icon: <FontAwesomeIcon icon={faBookmark} fixedWidth={true} />,
  //     listType: LEAD_LIST_TYPE.IN_WORK,
  //     countName: 'inWorkLeads'
  //   })
  // },
  // {
  //   hasPermission: permissions =>
  //     [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //   ...createLeadsListCabinetComponent({
  //     Component: LeadsList,
  //     path: '/cabinet/partner/leads/deals',
  //     label: 'Успешные сделки',
  //     icon: <FontAwesomeIcon icon={faMoneyBillAlt} fixedWidth={true} />,
  //     listType: LEAD_LIST_TYPE.DEAL_SUCCEED,
  //     countName: 'deals'
  //   })
  // },
  // {
  //   hasPermission: permissions =>
  //     [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //   ...createLeadsListCabinetComponent({
  //     Component: LeadsList,
  //     path: '/cabinet/partner/leads/trashed',
  //     label: 'Корзина',
  //     icon: <FontAwesomeIcon icon={faTrashAlt} fixedWidth={true} />,
  //     listType: LEAD_LIST_TYPE.TRASHED,
  //     countName: 'trashedLeads'
  //   })
  // }
];
