import { GET_FACTORING_CONNECTIONS_BY_ID } from 'entities/SCF/Factor/model/reducers/getFactoringConnectionsById';

export interface ResponseDataType {
  id: string;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  factorInn: string;
  factorName: string;
  factoringContractId: any;
  factoringNotificationId: any;
  statusId: any;
  confirmedBySupplier: number;
  confirmedByFactor: number;
  visibleToDebtor: boolean;
  readyForFactorFinance: boolean;
}

export const req = (guid: string) => ({
  type: GET_FACTORING_CONNECTIONS_BY_ID.REQ,
  guid
});

export const got = (data: ResponseDataType) => ({
  type: GET_FACTORING_CONNECTIONS_BY_ID.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_CONNECTIONS_BY_ID.ERR,
  error
});
