import { SEND_PRODUCT_BANK } from 'src/features/ProductsForBank/reducers/sendProductData';

export const req = data => ({
  type: SEND_PRODUCT_BANK.REQ,
  data
});

export const reset = () => ({
  type: SEND_PRODUCT_BANK.RESET
});

export const got = () => ({
  type: SEND_PRODUCT_BANK.GOT
});

export const err = (error: {}) => ({
  type: SEND_PRODUCT_BANK.ERR,
  error
});
