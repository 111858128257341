import * as React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { history } from 'src/shared/utils/History';
import {
  ButtonsBlock,
  StatusButton,
  Status,
  ApplicationStepTitle,
  ApplicationForm,
  TooltipContainer,
  TooltipBoxStyle
} from './styles';
import {
  ApplicationFormStyled,
  ApplicationInputsColumn,
  ApplicationFormContent
} from 'src/features/Application/components/ApplicationForm/ApplicationProcess/styles';
import { Loader } from 'src/features/Layouts/components';
import {
  CompanyInfoRead,
  CompanyInfoData,
  setCompanyInfoData
} from './../../../../../actions/setCompanyInfo';
import {
  req as sendCompanyInfoData,
  RequestData as SendCompanyInfoData
} from './../../../../../actions/sendCompanyInfoData';
import { req as getCompanyInfo } from './../../../../../actions/getCompanyInfo';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { SetCompanyInfoDataStoreState } from 'src/features/SCF/reducers/setCompanyInfo';
import { Input } from 'shared/ui/Input';
import { ResponseDataType as GetCompanyInnResponseDataType } from './../../../../../reducers/getCompanyInn';
import { req as getCompanyInn } from './../../../../../actions/getCompanyInn';
import './styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'shared/ui/Button';

interface StateToProps extends Partial<CompanyInfoData> {
  companyInfo: CompanyInfoRead;
  isSendingData: boolean;
  companyInn: GetCompanyInnResponseDataType;
}

interface DispatchToProps {
  getCompanyInfo: () => void;
  sendCompanyInfoData: (data: SendCompanyInfoData) => void;
  setCompanyInfoData: (data: SetCompanyInfoDataStoreState) => void;
  getCompanyInn: () => void;
}

type Props = StateToProps & DispatchToProps;

class RequisitesEdit extends React.Component<Props> {
  componentDidMount(): void {
    const { setCompanyInfoData } = this.props;
    setCompanyInfoData({
      id: this.props.companyInfo.id,
      inn: this.props.companyInn.companyInn,
      companyShortName: this.props.companyInfo.companyShortName,
      companyFullName: this.props.companyInfo.companyFullName,
      companyPhone: this.props.companyInfo.companyPhone,
      companyAddress: this.props.companyInfo.companyAddress,
      kpp: this.props.companyInfo.kpp,
      companyEmail: this.props.companyInfo.companyEmail
    });
    this.props.getCompanyInfo();
    this.props.getCompanyInn();
  }
  onSubmit = () => {
    const {
      id,
      inn,
      companyShortName,
      companyFullName,
      companyPhone,
      companyAddress,
      kpp,
      companyEmail,
      companyNameEng
    } = this.props;
    this.props.sendCompanyInfoData({
      id,
      inn,
      companyShortName,
      companyFullName,
      companyPhone,
      companyAddress,
      kpp,
      companyEmail,
      ...(companyNameEng && { companyNameEng })
    });
    if (window.location.pathname.includes('supplier')) {
      history.replace('/supplier/settings/requisites');
    }
    if (window.location.pathname.includes('debtor')) {
      history.replace('/debtor/settings/requisites');
    }
    if (window.location.pathname.includes('factor')) {
      history.replace('/factor/settings/requisites');
    }
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    if (name === 'companyNameEng' && !/^[a-zA-Z ,.()]*$/.test(value)) {
      return;
    }

    this.props.setCompanyInfoData({
      [name]: value
    });
  };

  render() {
    const {
      id,
      inn,
      companyShortName,
      companyFullName,
      companyPhone,
      companyAddress,
      kpp,
      companyEmail,
      companyNameEng
    } = this.props;
    return (
      <ApplicationFormStyled>
        <ApplicationStepTitle>
          {' '}
          {this.props.companyInfo.id
            ? `Редактировать реквизиты компании ${
                this.props.companyInfo.companyFullName
              }`
            : `Создать компанию`}{' '}
        </ApplicationStepTitle>
        <ApplicationFormContent>
          <ApplicationInputsColumn>
            {this.props.isSendingData ? (
              <Loader />
            ) : (
              <ApplicationForm
                onSubmit={this.onSubmit}
                name="initialize-company-form"
              >
                <Input
                  value={companyFullName}
                  placeholder={this.props.companyInfo.companyFullName}
                  label="Полное имя компании"
                  name="companyFullName"
                  onChange={this.onChange}
                />
                <Input
                  value={companyShortName}
                  placeholder={this.props.companyInfo.companyShortName}
                  label="Краткое имя компании"
                  name="companyShortName"
                  onChange={this.onChange}
                />
                <TooltipContainer>
                  <Input
                    value={companyNameEng}
                    placeholder={this.props.companyInfo.companyNameEng}
                    label="Наименование на англ. языке"
                    name="companyNameEng"
                    onChange={this.onChange}
                  />
                  <TooltipBoxStyle
                    text={'Используется при наличии договоров на двух языках'}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </TooltipBoxStyle>
                </TooltipContainer>
                <Input
                  className="inn"
                  disabled
                  value={inn}
                  placeholder={this.props.companyInn.companyInn}
                  label="ИНН"
                  name="inn"
                  onChange={this.onChange}
                />
                <Input
                  value={kpp}
                  placeholder={this.props.companyInfo.kpp}
                  label="КПП"
                  name="kpp"
                  onChange={this.onChange}
                />
                <Input
                  value={companyAddress}
                  placeholder={this.props.companyInfo.companyAddress}
                  label="Адрес компании"
                  name="companyAddress"
                  onChange={this.onChange}
                />
                <Input
                  value={companyPhone}
                  placeholder={this.props.companyInfo.companyPhone}
                  label="Телефон компании"
                  name="companyPhone"
                  onChange={this.onChange}
                />
                <Input
                  value={companyEmail}
                  placeholder={this.props.companyInfo.companyEmail}
                  label="Email компании"
                  name="companyEmail"
                  onChange={this.onChange}
                />
                <ButtonsBlock>
                  <StatusButton>
                    <p>Редактируется</p>
                  </StatusButton>
                  <Button
                    type="submit"
                    label={this.props.companyInfo.id ? 'Сохранить' : 'Создать'}
                    template="defaultBtn"
                  />
                </ButtonsBlock>
                <Status>
                  <p>
                    Последнее изменение:{' '}
                    {format(
                      new Date(this.props.companyInfo.modifiedDateTime),
                      'yyyy.MM.dd - HH:mm'
                    )}
                  </p>
                </Status>
              </ApplicationForm>
            )}
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyInfo: SCF.getCompanyInfo.data,
  companyInn: SCF.getCompanyInn.data,
  isSendingData: SCF.sendCompanyInfoData.status === REQUEST_STATUSES.REQUEST,
  ...SCF.setCompanyInfoData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyInfo,
      getCompanyInn,
      sendCompanyInfoData,
      setCompanyInfoData
    },
    dispatch
  );

const RequisitesEditConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RequisitesEdit);

export { RequisitesEditConnect as RequisitesEdit };
