import * as React from 'react';

import { RegistryDocument } from 'Application/types';

import { CrossBtn } from 'shared/styled';

import { FileListTable } from './styles';

export interface FileListProps {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  files: RegistryDocument[];
  multiple: boolean;
}

const RemoveRegistry = () => {
  window.location.reload();
};

export const FilesList: React.FC<FileListProps> = React.memo(
  ({ isDragActive, isDragAccept, isDragReject, files }) => {
    const formTableRows = () =>
      files.map(file => ({
        id: file.size,
        data: [file.name, <CrossBtn onClick={() => RemoveRegistry()} />]
      }));

    return (
      <FileListTable
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        headers={['Название', '']}
        rows={formTableRows()}
        sizes={['90%', '5%']}
      />
    );
  }
);
