import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type SendSuppliersStoreState = Reducer;

export enum SEND_SUPPLIERS {
  REQ = 'SEND_SUPPLIERS_REQ',
  GOT = 'SEND_SUPPLIERS_GOT',
  ERR = 'SEND_SUPPLIERS_ERR'
}

export interface RequestDataType {
    supplierInnList: string;
}

export const initialState: SendSuppliersStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendSuppliers = (
  state: SendSuppliersStoreState = initialState,
  action: {
    type: SEND_SUPPLIERS;
    error: {};
  }
): SendSuppliersStoreState => {
  switch (action.type) {
    case SEND_SUPPLIERS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_SUPPLIERS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_SUPPLIERS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendSuppliers;
