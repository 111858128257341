import * as React from 'react';
import { connect } from 'react-redux';
import { CRM } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  WrapperInputDetail,
  InputDetail,
  ContainerInputs
} from 'src/features/ContactDetailsForContact/components/styled';
import { ContactRead } from 'src/features/Contacts/actions/setContactFormData';
import { Button } from 'shared/ui/Button';

interface StateToProps {}

interface AnyProps {
  contact: ContactRead;
  handlePatchContact: (name: string, value: string) => void;
}

interface State {
  firstName: string;
  lastName: string;
  middleName: string;
  position: string;
  phoneNumber: string;
  phoneContact: string;
  phoneStationary: string;
  email: string;
}

interface DispatchToProps {}

type Props = StateToProps & DispatchToProps & AnyProps;

class ContactDetails extends React.Component<Props, State> {
  state = {
    firstName: '',
    lastName: '',
    middleName: '',
    position: '',
    phoneNumber: '',
    phoneContact: '',
    phoneStationary: '',
    email: ''
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value } as Pick<State, keyof State>);
  };

  formatPhoneNumber(phoneNumberString: string) {
    if (phoneNumberString.length > 11) {
      return phoneNumberString;
    }

    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return (
        '+' +
        match[1] +
        ' (' +
        match[2] +
        ') ' +
        match[3] +
        '-' +
        match[4] +
        '-' +
        match[5]
      );
    }
    return phoneNumberString;
  }

  render() {
    const regex = /^[а-яА-Я\s\-]+$/;
    const emailRegex = /^[A-Za-z@._-]*$/;

    return (
      <ApplicationViewStyled>
        <ContainerInputs>
          <WrapperInputDetail>
            <p>Фамилия:</p>
            {!this.props.contact.lastName ? (
              <>
                <InputDetail
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                />
                {!!this.state.lastName.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'lastName',
                        this.state.lastName
                      )
                    }
                    disabled={!regex.test(this.state.lastName)}
                  />
                )}
              </>
            ) : (
              <p>{this.props.contact.lastName}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Имя:</p>
            {!this.props.contact.firstName ? (
              <>
                <InputDetail
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                />
                {!!this.state.firstName.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'firstName',
                        this.state.firstName
                      )
                    }
                    disabled={!regex.test(this.state.firstName)}
                  />
                )}
              </>
            ) : (
              <p>{this.props.contact.firstName}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Отчество:</p>
            {!this.props.contact.middleName ? (
              <>
                <InputDetail
                  name="middleName"
                  value={this.state.middleName}
                  onChange={this.handleInputChange}
                />
                {!!this.state.middleName.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'middleName',
                        this.state.middleName
                      )
                    }
                    disabled={!regex.test(this.state.middleName)}
                  />
                )}
              </>
            ) : (
              <p>{this.props.contact.middleName}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Должность:</p>
            {!this.props.contact.position ? (
              <>
                <InputDetail
                  name="position"
                  value={this.state.position}
                  onChange={this.handleInputChange}
                />
                {!!this.state.position.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'position',
                        this.state.position
                      )
                    }
                    disabled={!regex.test(this.state.position)}
                  />
                )}
              </>
            ) : (
              <p>{this.props.contact.position}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Телефон 1:</p>
            {!this.props.contact.phoneNumber ? (
              <>
                <InputDetail
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleInputChange}
                />
                {!!this.state.phoneNumber.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'phoneNumber',
                        this.state.phoneNumber
                      )
                    }
                    disabled={this.state.phoneNumber.length < 11}
                  />
                )}
              </>
            ) : (
              <p>{this.formatPhoneNumber(this.props.contact.phoneNumber)}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Телефон 2:</p>
            {!this.props.contact.phoneContact ? (
              <>
                <InputDetail
                  name="phoneContact"
                  value={this.state.phoneContact}
                  onChange={this.handleInputChange}
                />
                {!!this.state.phoneContact.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'phoneContact',
                        this.state.phoneContact
                      )
                    }
                    disabled={this.state.phoneContact.length < 11}
                  />
                )}
              </>
            ) : (
              <p>{this.formatPhoneNumber(this.props.contact.phoneContact)}</p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Телефон 3:</p>
            {!this.props.contact.phoneStationary ? (
              <>
                <InputDetail
                  name="phoneStationary"
                  value={this.state.phoneStationary}
                  onChange={this.handleInputChange}
                />
                {!!this.state.phoneStationary.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact(
                        'phoneStationary',
                        this.state.phoneStationary
                      )
                    }
                    disabled={this.state.phoneStationary.length < 11}
                  />
                )}
              </>
            ) : (
              <p>
                {this.formatPhoneNumber(this.props.contact.phoneStationary)}
              </p>
            )}
          </WrapperInputDetail>
          <WrapperInputDetail>
            <p>Email:</p>
            {!this.props.contact.email ? (
              <>
                <InputDetail
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
                {!!this.state.email.length && (
                  <Button
                    label="Сохранить"
                    onClick={() =>
                      this.props.handlePatchContact('email', this.state.email)
                    }
                    disabled={!emailRegex.test(this.state.email)}
                  />
                )}
              </>
            ) : (
              <p>{this.props.contact.email}</p>
            )}
          </WrapperInputDetail>
        </ContainerInputs>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({  }: CRM) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ContactDetailsConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetails);

export { ContactDetailsConnect as ContactDetails };
