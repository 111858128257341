import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/postUploadNonFinDocClient';

import { POST_UPLOAD_NON_FIN_DOC_CLIENT } from 'entities/Cabinet/Client/model/reducers/postUploadNonFinDocClient';
import ClientApi from 'entities/Cabinet/Client/api';

function* postUploadNonFinDocClient(action: {
  type: POST_UPLOAD_NON_FIN_DOC_CLIENT;
  data: FormData;
  guid: string;
}): Generator {
  try {
    yield call(ClientApi.postUploadFinDocClient, action.guid, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postUploadNonFinDocClientSaga(): Generator {
  yield takeLatest(
    POST_UPLOAD_NON_FIN_DOC_CLIENT.REQ,
    postUploadNonFinDocClient
  );
}
