import { GET_PRODUCTS_LIST_BY_CONTACT } from '../reducers/getProductsListByContact';
import { ContactsOfProductsRead } from './setContactsOfProductsFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ContactsOfProductsRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  contactId: number;
}

export const req = (data: RequestData) => ({
  type: GET_PRODUCTS_LIST_BY_CONTACT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_LIST_BY_CONTACT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_LIST_BY_CONTACT.ERR,
  error
});
