import * as React from 'react';
import { Route, Switch } from 'react-router';
import SettingsPage from 'src/features/SCF/components/Settings/Settings';
import {
  BarSection,
  MainPage,
  BuyersPage,
  CheckLimits,
  ClaimsPage,
  DebtorsAddPage,
  BuyerInfoPage,
  ConnectionsPage,
  SupplyContractsPage,
  SupplierFactoringContractsPage,
  EarlyPaymentApplicationsPage,
  DiscountAuctionsPage
} from 'pages/SCF/Supplier/components';
import { SupplierCabinetStyled } from './styles';

interface Props {
  type: string | JSX.Element;
}

const SupplierCabinet: React.FC<Props> = ({ type }) => (
  <SupplierCabinetStyled>
    <BarSection type={''} />
    <Switch>
      <Route exact path={'/supplier'} render={() => <MainPage />} />
      <Route path={'/supplier/settings'} render={() => <SettingsPage />} />
      <Route exact path={'/supplier/debtors'} render={() => <BuyersPage />} />
      <Route
        exact={true}
        path={'/supplier/debtors/add'}
        render={() => <DebtorsAddPage />}
      />
      <Route
        exact
        path={'/supplier/check_limits'}
        render={() => <CheckLimits />}
      />
      <Route
        exact={true}
        path={'/supplier/debtors/:inn'}
        render={() => <BuyerInfoPage />}
      />
      <Route
        path={'/supplier/connections'}
        render={() => <ConnectionsPage />}
      />

      <Route path={'/supplier/claims'} render={() => <ClaimsPage />} />

      <Route
        path={'/supplier/supply_contracts'}
        render={() => <SupplyContractsPage />}
      />
      <Route
        path={'/supplier/factoring_contracts'}
        render={() => <SupplierFactoringContractsPage />}
      />
      <Route
        path={'/supplier/early_payment_applications'}
        render={() => <EarlyPaymentApplicationsPage />}
      />
      <Route
        path={'/supplier/discount_auctions'}
        render={() => <DiscountAuctionsPage />}
      />
    </Switch>
  </SupplierCabinetStyled>
);

export { SupplierCabinet };
