import { ContactsData } from '../actions/setContactFormData';

export interface SetContactDataStoreState extends Partial<ContactsData> {}

export enum SET_CONTACT_DATA {
  SET = 'SET_CONTACT_DATA_SET',
  RESET = 'SET_CONTACT_DATA_RESET',
}

export const initialState: SetContactDataStoreState = {
  // amount: 0,
  // sourceUserId: 0,
  // inn: null
};

const setContactFormData = (
  state: SetContactDataStoreState = initialState,
  action: {
    type: string;
    data: ContactsData;
  }
): SetContactDataStoreState => {
  switch (action.type) {
    case SET_CONTACT_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_CONTACT_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setContactFormData;
