import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addMilliseconds, format, parse } from 'date-fns';
import { history } from 'src/shared/utils/History';

import { InputDate } from 'shared/ui/InputDate';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getEarlyPaymentApplications,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getEarlyPaymentApplicationsFactorRole';
import {
  CreateLimitsNudge,
  CreateBtn,
  CreateBtnContainer,
  InnFilterArea,
  InnFilter,
  InnContainer,
  ListSection,
  FullList,
  PeriodFilter,
  PeriodFilterItem,
  DateFilterStyled,
  DatesContainer,
  DatePopup,
  DatePopupBtns,
  CancelBtn,
  CancelBtnText,
  GotBtn,
  GotBtnText,
  CheckedIcon,
  CrossIcon,
  CheckContainer,
  DeclineContainer,
  StatusContainer,
  PaidBtnText
} from './styles';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError,
  PaginationWrapper,
  PageNoMarginStyled
} from 'src/features/SCF/UI/ScfStyles';
import './styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { Href } from 'history';
import { RequestDataType as PutEarlyPaymentApplicationDeclineDataType } from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';
import { req as putEarlyPaymentApplicationDecline } from 'entities/SCF/Factor/model/actions/putEarlyPaymentApplicationDeclineFactorRole';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  earlyPaymentApplications: ResponseDataType;
}

interface DispatchToProps {
  getEarlyPaymentApplications: (data: RequestDataType) => void;
  putEarlyPaymentApplicationDecline: (
    data: PutEarlyPaymentApplicationDeclineDataType
  ) => void;
}

interface State {
  pageUrl: Href;
  supplierInnToFilter: string;
  updateChecker: number;
  dateFrom: string;
  dateUntil: string;
  decisionState: string;
  commentState: string;
  guidState: string;
}

type Props = StateToProps & DispatchToProps;

class ApplicationsList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        supplierInnToFilter: '',
        updateChecker: 0,
        decisionState: 'ACTION_REQUIRED',
        dateFrom: format(
          parse('01/01/2023', 'dd/MM/yyyy', new Date()),
          'dd/MM/yyyy'
        ),
        dateUntil: format(
          parse('01/01/2053', 'dd/MM/yyyy', new Date()),
          'dd/MM/yyyy'
        ),
        commentState: '',
        guidState: null
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleSupplierInnToFilterChange = this.handleSupplierInnToFilterChange.bind(
        this
      );
      this.handleDateFromChange = this.handleDateFromChange.bind(this);
      this.handleDateUntilChange = this.handleDateUntilChange.bind(this);
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
      this.handleDecisionStateRequired = this.handleDecisionStateRequired.bind(
        this
      );
      this.handleDecisionStateAll = this.handleDecisionStateAll.bind(this);
      this.handleCommentStateChange = this.handleCommentStateChange.bind(this);
    }
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  handleSupplierInnToFilterChange(event) {
    this.setState({ supplierInnToFilter: event.target.value });
  }

  handleCommentStateChange(event) {
    this.setState({ commentState: event.target.value });
  }

  handleGuidStateChange(guid: string) {
    this.setState({ guidState: guid });
    console.log(this.state.guidState);
  }

  handleDateFromChange(date: string) {
    this.setState({ dateFrom: date });
  }

  handleDateUntilChange(date: string) {
    this.setState({ dateUntil: date });
  }

  handleDecisionStateRequired() {
    this.setState({ decisionState: 'ACTION_REQUIRED' });
    console.log(this.state.decisionState);
  }

  handleDecisionStateAll() {
    this.setState({ decisionState: 'ALL' });
    console.log(this.state.decisionState);
  }

  handleUpdateCheckerChange() {
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  }

  initFetch = () => {
    const { getEarlyPaymentApplications } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.supplierInnToFilter == ''
        ? getEarlyPaymentApplications({
            page,
            pageSize: 20,
            StartDate: this.state.dateFrom,
            EndDate: this.state.dateUntil,
            ActionType: this.state.decisionState
          })
        : getEarlyPaymentApplications({
            page: 1,
            SupplierInnToFilterBy: this.state.supplierInnToFilter,
            StartDate: this.state.dateFrom,
            EndDate: this.state.dateUntil,
            ActionType: this.state.decisionState
          });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.supplierInnToFilter !== prevState.supplierInnToFilter ||
      this.state.updateChecker !== prevState.updateChecker ||
      this.state.dateFrom !== prevState.dateFrom ||
      this.state.dateUntil !== prevState.dateUntil ||
      this.state.decisionState !== prevState.decisionState
    ) {
      this.initFetch();
    }
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  onTodayClick = () => {
    var dateOffset = 24 * 60 * 60 * 1000;
    var myDate = new Date();
    let allTime = document.getElementById('allTime');
    let allTimeP = document.getElementById('allTime-p');
    let period = document.getElementById('period');
    let periodP = document.getElementById('period-p');
    let dateFilter = document.getElementById('dateFilter');
    allTime.style.backgroundColor = '#F1F8FF';
    allTimeP.style.color = '#3F4E65';
    period.style.background = 'none';
    periodP.style.color = '#9FA6B2';
    dateFilter.style.display = 'none';
    this.setState({ dateFrom: format(new Date(myDate), 'dd/MM/yyyy') });
    this.setState({
      dateUntil: format(
        addMilliseconds(new Date(myDate), dateOffset),
        'dd/MM/yyyy'
      )
    });
  };

  onPeriodClick = () => {
    let allTime = document.getElementById('allTime');
    let allTimeP = document.getElementById('allTime-p');
    let period = document.getElementById('period');
    let periodP = document.getElementById('period-p');
    let dateFilter = document.getElementById('dateFilter');
    period.style.backgroundColor = '#F1F8FF';
    allTime.style.background = 'none';
    periodP.style.color = '#3F4E65';
    allTimeP.style.color = '#9FA6B2';
    dateFilter.style.display = 'flex';
  };

  onActionRequiredClick = () => {
    let all = document.getElementById('all');
    let allP = document.getElementById('all-p');
    let waiting = document.getElementById('waiting');
    let waitingP = document.getElementById('waiting-p');
    waiting.style.backgroundColor = '#F1F8FF';
    all.style.background = 'none';
    waitingP.style.color = '#3F4E65';
    allP.style.color = '#9FA6B2';
    this.handleDecisionStateRequired();
  };

  onAllClick = () => {
    let all = document.getElementById('all');
    let allP = document.getElementById('all-p');
    let waiting = document.getElementById('waiting');
    let waitingP = document.getElementById('waiting-p');
    all.style.backgroundColor = '#F1F8FF';
    allP.style.color = '#3F4E65';
    waiting.style.background = 'none';
    waitingP.style.color = '#9FA6B2';
    this.handleDecisionStateAll();
  };

  onDeclineClick = (guid: string) => {
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'flex';
  };

  onCancelClick = (guid: string) => {
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
  };

  onDeclineBtnClick = async (guid: string) => {
    let datePopup = document.getElementById(guid + 'datePopup');
    await this.delay(700);
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
    this.handleUpdateCheckerChange();
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  onSignClick = guid => {
    this.handleGuidStateChange(guid);
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    history.push({
      pathname: `/factor/settings/certificates/edit`
    });
  };

  onRawClick = (guid: string) => {
    history.push(`/factor/factoring_applications/${guid}`);
  };

  render() {
    const { status, earlyPaymentApplications, error } = this.props;
    return (
      <PageNoMarginStyled>
        <CreateLimitsNudge>
          <h2>Заявки на финансирование</h2>
        </CreateLimitsNudge>
        <PeriodFilter>
          <PeriodFilterItem
            style={{ background: 'none', cursor: 'not-allowed' }}
          >
            <p style={{ color: '#9FA6B2' }}>Одобрять все автоматически</p>
          </PeriodFilterItem>
          <PeriodFilterItem>
            <p>Одобрять вручную</p>
          </PeriodFilterItem>
        </PeriodFilter>
        <PeriodFilter>
          <PeriodFilterItem id="waiting" onClick={this.onActionRequiredClick}>
            <p id="waiting-p">Ожидающие решения</p>
          </PeriodFilterItem>
          <PeriodFilterItem
            id="all"
            onClick={this.onAllClick}
            style={{ background: 'none' }}
          >
            <p id="all-p" style={{ color: '#9FA6B2' }}>
              Все заявки
            </p>
          </PeriodFilterItem>
        </PeriodFilter>
        <PeriodFilter>
          <PeriodFilterItem id="allTime" onClick={this.onTodayClick}>
            <p id="allTime-p">За сегодня</p>
          </PeriodFilterItem>
          <PeriodFilterItem
            id="period"
            onClick={this.onPeriodClick}
            style={{ background: 'none' }}
          >
            <p id="period-p" style={{ color: '#9FA6B2' }}>
              За период
            </p>
          </PeriodFilterItem>
        </PeriodFilter>
        <DateFilterStyled id="dateFilter" style={{ display: 'none' }}>
          <p>Дата заявки:</p>
          <DatesContainer>
            <p>с</p>
            <InputDate
              onDateChange={this.handleDateFromChange}
              dateValue={this.state.dateFrom}
            />
          </DatesContainer>
          <DatesContainer>
            <p>по</p>
            <InputDate
              onDateChange={this.handleDateUntilChange}
              dateValue={this.state.dateUntil}
            />
          </DatesContainer>
        </DateFilterStyled>
        <InnFilterArea>
          <InnFilter>
            <p>ИНН Поставщика</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleSupplierInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
        </InnFilterArea>

        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="7%">ID</TableHeaderCellStyled>
                    <TableHeaderCellStyled width="25%">
                      ДЕБИТОР
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="25%">
                      ПОСТАВЩИК
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="15%">
                      ДАТА ОПЛАТЫ
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      СУММА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      СТАТУС
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="10%" />
                    <TableHeaderCellStyled width="0%" />
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {earlyPaymentApplications.items.map((item, key) => (
                    <TableRowStyled key={key} className="table-row">
                      <td
                        onClick={() => this.onRawClick(item.guid)}
                        style={{ fontFamily: 'Roboto-Regular' }}
                      >
                        {item.guid.substring(item.guid.length - 5)}
                      </td>
                      <td onClick={() => this.onRawClick(item.guid)}>
                        {item.debtorName}
                      </td>
                      <td onClick={() => this.onRawClick(item.guid)}>
                        {item.supplierName}
                      </td>
                      <td onClick={() => this.onRawClick(item.guid)}>
                        {format(new Date(item.createdDateTime), 'dd.MM.yyyy')}
                      </td>
                      <td onClick={() => this.onRawClick(item.guid)}>
                        {item.monetaryClaimsTotalFullSum.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        руб.
                      </td>
                      {item.statusDebtor == 'На рассмотрении' ? (
                        <td onClick={() => this.onRawClick(item.guid)}>
                          <GotBtn style={{ backgroundColor: '#D0E9FF' }}>
                            <GotBtnText>{item.statusDebtor}</GotBtnText>
                          </GotBtn>
                        </td>
                      ) : item.statusDebtor == 'Отказана' ? (
                        <td onClick={() => this.onRawClick(item.guid)}>
                          <GotBtn style={{ backgroundColor: '#FF0000' }}>
                            <PaidBtnText>{item.statusDebtor}</PaidBtnText>
                          </GotBtn>
                        </td>
                      ) : (
                        <td onClick={() => this.onRawClick(item.guid)}>
                          <GotBtn>
                            <GotBtnText>{item.statusDebtor}</GotBtnText>
                          </GotBtn>
                        </td>
                      )}
                      {item.statusDebtor == 'На рассмотрении' ? (
                        <td>
                          <StatusContainer>
                            <CheckContainer>
                              <CheckedIcon
                                onClick={() => this.onSignClick(item.guid)}
                              />
                            </CheckContainer>
                            <DeclineContainer
                              onClick={() => this.onDeclineClick(item.guid)}
                            >
                              <CrossIcon />
                            </DeclineContainer>
                          </StatusContainer>
                        </td>
                      ) : (
                        <td onClick={() => this.onRawClick(item.guid)} />
                      )}
                      <td>
                        <DatePopup id={item.guid + 'datePopup'}>
                          <DatesContainer>
                            <textarea
                              value={this.state.commentState}
                              onChange={this.handleCommentStateChange}
                              placeholder="Ваша причина отказа"
                              style={{
                                width: '400px',
                                paddingTop: '10px',
                                paddingLeft: '15px',
                                minHeight: '100px'
                              }}
                            />
                          </DatesContainer>
                          <DatePopupBtns>
                            {this.state.commentState.length < 10 ? (
                              <CreateBtn
                                style={{
                                  backgroundColor: '#9FA6B2',
                                  cursor: 'not-allowed'
                                }}
                              >
                                <CancelBtnText>Отправить</CancelBtnText>
                              </CreateBtn>
                            ) : (
                              <CreateBtn
                                onClick={() =>
                                  this.props.putEarlyPaymentApplicationDecline({
                                    guid: item.guid,
                                    body: {
                                      rejectReason: this.state.commentState
                                    }
                                  })
                                }
                              >
                                <CreateBtnContainer
                                  onClick={() =>
                                    this.onDeclineBtnClick(item.guid)
                                  }
                                >
                                  <CancelBtnText>Отправить</CancelBtnText>
                                </CreateBtnContainer>
                              </CreateBtn>
                            )}
                            <CancelBtn
                              onClick={() => this.onCancelClick(item.guid)}
                            >
                              <CancelBtnText>Отменить</CancelBtnText>
                            </CancelBtn>
                          </DatePopupBtns>
                        </DatePopup>
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
        </ListSection>
        <PaginationWrapper onClick={this.handlePageChange}>
          <Pagination list={this.props.earlyPaymentApplications} />
        </PaginationWrapper>
        <CertificatesPopup id="certificatesPopup">
          <PopupExit>
            <p onClick={this.onCloseIconClick}>X</p>
          </PopupExit>
          <h1>Список сертификатов в системе</h1>
          <CertificatesArea>
            <CertificatesListPopup
              guid={this.state.guidState}
              pageUrl={this.state.guidState}
            />
          </CertificatesArea>
          <NudgeArea>
            <p>Нет нужного сертификата? </p>
            <Nudge onClick={this.onNudgeClick}>
              Перейдите в настройки добаления
            </Nudge>
          </NudgeArea>
        </CertificatesPopup>
        <CertificatesPopupError id="certificatesPopupError">
          <PopupExitError>
            <p onClick={this.onCloseErrorIconClick}>X</p>
          </PopupExitError>
          <h1>
            Невозможно подписать выбранным сертификатом
            <br />
            (не найден на устройстве)
          </h1>
        </CertificatesPopupError>
      </PageNoMarginStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  earlyPaymentApplications:
    SCFFactor.getEarlyPaymentApplicationsFactorRole.data,
  status: SCFFactor.getEarlyPaymentApplicationsFactorRole.status,
  error: SCFFactor.getEarlyPaymentApplicationsFactorRole.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEarlyPaymentApplications,
      putEarlyPaymentApplicationDecline
    },
    dispatch
  );

const ApplicationsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsList);

export { ApplicationsListConnect as ApplicationsList };
