import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostAgreementSignatureDebtorRoleStoreState = Reducer;

export enum POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE {
  REQ = 'POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE_REQ',
  GOT = 'POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE_GOT',
  ERR = 'POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE_ERR',
  RESET = 'POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE_RESET'
}

export interface RequestDataType {
  guid: string;
  signature: string;
  serial: string;
}

export const initialState: PostAgreementSignatureDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAgreementSignatureDebtorRole = (
  state: PostAgreementSignatureDebtorRoleStoreState = initialState,
  action: {
    type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE;
    error: {};
  }
): PostAgreementSignatureDebtorRoleStoreState => {
  switch (action.type) {
    case POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default postAgreementSignatureDebtorRole;
