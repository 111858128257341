import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSpinner,
  faChevronUp,
  faChevronDown,
  faCheck,
  faUpload
} from '@fortawesome/free-solid-svg-icons';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS, CRM } from 'globaltypes';
import { BorrowerCompany, GuaranteesApplicationRead } from 'Application/types';

import { req as getDocumentListExternal } from 'entities/Cabinet/ExternalAgent/model/actions/getDocumentListExternal';
import {
  ResponseData as ResDocumentListExternal,
  DocumentList
} from 'entities/Cabinet/ExternalAgent/model/reducers/getDocumentListExternal';
import { req as postFinFile } from 'entities/Cabinet/ExternalAgent/model/actions/postFinancialDocumentExternal';
import { req as postNotFinFile } from 'entities/Cabinet/ExternalAgent/model/actions/postNonFinancialDocumentExternal';
import { req as postDocumentListExternal } from 'entities/Cabinet/ExternalAgent/model/actions/postDocumentListExternal';
import {
  req as createBorrowerCompany,
  reset as resetCreateBorrowerCompany
} from 'BorrowerCompanies/actions/createBorrowerCompany';
import {
  req as createNewClient,
  RequestData as ReqPostNewClient
} from 'src/features/Clients/actions/postNewClient';
import { req as deleteBorrowerCompany } from 'BorrowerCompanies/actions/deleteBorrowerCompany';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { RequestDataType as CreateBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/createBorrowerCompany';
import { RequestDataType as DeleteBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

import { Spinner } from 'Layouts/components';
import { animation, StepsTransitionGroup, TRANSITION_DIRECTIONS } from 'Common';
import { WarningBlock } from 'Application/components';

import { Input } from 'shared/ui/Input';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import CompaniesApi from 'src/features/Companies/api';
import {
  WarningAddCompanyBtn,
  CompanyTabsStyled,
  CompanyTabsHeader,
  CompanyTabHeaderItem,
  CompanyTabBody,
  CompanyTabItem,
  AddCompanyHeaderItem,
  AddCompanyBtn,
  RemoveCompanyBtn,
  LoaderContainerStyled,
  CompanyTabHeaderRole,
  ModalContainer,
  PopupContentParticipants,
  WrapperInputDetail,
  ButtonWrapper,
  ParticipantsInfo,
  Spinner as SpinnerApi,
  BlueContainer,
  TitleTabContainer,
  CompanyHead,
  TableWrapper,
  TDDocumentName,
  TooltipBoxStyle,
  Period,
  IsNecessarily,
  FileContainer,
  IsUploadContainer,
  ButtonContainer,
  UploadButton
} from './styles';
import { BorrowerCompanyBlock } from '../BorrowerCompanyBlock/BorrowerCompanyBlock';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { PersonDocumentsType } from 'src/features/DocumentList/types';
import { Button } from 'shared/ui/Button';

interface Tab {
  label: string;
  data: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
    isValid: boolean;
  };
}

interface OwnProps {
  tabs: Tab[];
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  borrowerCompanyFileUploadBlock: any;
}

interface MatchParams {
  id: string;
}

interface StateToProps {
  externalApplication: Partial<GuaranteesApplicationRead>;
  statusExternalApplication: REQUEST_STATUSES;
  companies: BorrowerCompany[];
  createStatus: REQUEST_STATUSES;
  deleteStatus: REQUEST_STATUSES;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  permissions: USER_PERMISSIONS[];
  documentList: ResDocumentListExternal;
  statusDocumentList: REQUEST_STATUSES;
  statusFinDocUpload: REQUEST_STATUSES;
  statusNonFinDocUpload: REQUEST_STATUSES;
  statusPostCreateDocument: REQUEST_STATUSES;
}

interface DispatchToProps {
  createBorrowerCompany: (data: CreateBorrowerCompanyDataType) => void;
  deleteBorrowerCompany: (data: DeleteBorrowerCompanyDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  resetCreateBorrowerCompany: () => void;
  createNewClient: (data: ReqPostNewClient) => void;
  getDocumentListExternal: (id: string) => void;
  postFinFile: (guid: string, data: FormData) => void;
  postNotFinFile: (guid: string, data: FormData) => void;
  postDocumentListExternal: (guid: string) => void;
}

type Props = OwnProps &
  RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps;

const BorrowerCompaniesTabs: React.FC<Props> = ({
  externalApplication,
  statusExternalApplication,
  getCompanyByInn,
  resetStateCompanyByInn,
  companyByInn,
  borrowerCompanyFileUploadBlock,
  createBorrowerCompany,
  deleteBorrowerCompany,
  resetCreateBorrowerCompany,
  createStatus,
  deleteStatus,
  permissions,
  tabs,
  statusInn,
  createNewClient,
  companies,
  getDocumentListExternal,
  documentList,
  statusDocumentList,
  postFinFile,
  statusFinDocUpload,
  postNotFinFile,
  statusNonFinDocUpload,
  postDocumentListExternal,
  statusPostCreateDocument,
  match,
  location
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [animationDirection, setAnimationDirection] = React.useState<
    TRANSITION_DIRECTIONS
  >(TRANSITION_DIRECTIONS.FORWARD);
  const [isModal, setIsModal] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [individualNumber, setIndividualNumber] = React.useState('');
  const [companyINN, setCompanyINN] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [roles, setRoles] = React.useState<string[]>([]);
  const [isInfoPopup, setIsInfoPopup] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteItem, setDeleteItem] = React.useState<BorrowerCompany>(null);
  const [file, setFile] = React.useState({ id: null, file: null });
  const [isSectionDocumentList, setIsSectionDocumentList] = React.useState(
    true
  );
  const [
    isSectionOldDocumentList,
    setIsSectionOldDocumentList
  ] = React.useState(false);
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentList[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);

  const participantsModalRef = React.useRef();
  const deleteModalRef = React.useRef();

  const Role = !location.pathname.includes('guarantees')
    ? {
        BORROWER: 'Заемщик',
        PLEDGER: 'Залогодатель',
        GUARANTOR: 'Поручитель'
      }
    : {
        PRINCIPAL: 'Принципал',
        PLEDGER: 'Залогодатель',
        GUARANTOR: 'Поручитель'
      };

  React.useEffect(() => {
    return () => resetStateCompanyByInn();
  }, []);

  React.useEffect(
    () => {
      if (statusExternalApplication === REQUEST_STATUSES.GOT) {
        getDocumentListExternal(match.params.id);
      }
    },
    [statusExternalApplication]
  );

  React.useEffect(
    () => {
      if (
        statusFinDocUpload === REQUEST_STATUSES.GOT ||
        statusNonFinDocUpload === REQUEST_STATUSES.GOT
      ) {
        getDocumentListExternal(match.params.id);
      }
    },
    [statusFinDocUpload, statusNonFinDocUpload]
  );

  React.useEffect(
    () => {
      if (statusDocumentList === REQUEST_STATUSES.ERROR) {
        postDocumentListExternal(match.params.id);
      }

      if (
        statusDocumentList === REQUEST_STATUSES.GOT &&
        !!documentList.items.length
      ) {
        setCompanyINN(documentList.items[0].companyINN);
        setCompanyName(documentList.items[0].companyShortName);

        const filtredInnDocuments = documentList.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        documentList.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            guid,
            documentCategory,
            documentTypeId
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                guid,
                documentCategory,
                documentTypeId
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [statusDocumentList]
  );

  React.useEffect(
    () => {
      if (
        statusPostCreateDocument === REQUEST_STATUSES.GOT &&
        statusExternalApplication === REQUEST_STATUSES.GOT
      ) {
        getDocumentListExternal(match.params.id);
      }
    },
    [statusPostCreateDocument, statusExternalApplication]
  );

  React.useEffect(
    () => {
      if (!!companies.length && !!companies[activeTabIndex]) {
        setRoles(companies[activeTabIndex].roles);
      }
    },
    [companies, activeTabIndex]
  );

  React.useEffect(
    () => {
      if (individualNumber.length >= 10) {
        getCompanyByInn({ inn: individualNumber });
      }
      setIsInfoPopup(false);
    },
    [individualNumber]
  );

  React.useEffect(
    () => {
      if (statusInn === REQUEST_STATUSES.GOT) {
        setCompanyName(companyByInn.companyShortName);
      }
    },
    [statusInn]
  );

  React.useEffect(
    () => {
      if (tabs.length > 0) {
        setActiveTabIndex(tabs.length - 1);
        setAnimationDirection(TRANSITION_DIRECTIONS.FORWARD);
      }
    },
    [tabs.length]
  );

  React.useEffect(
    () => {
      if (createStatus === REQUEST_STATUSES.GOT) {
        resetCreateBorrowerCompany();
        setIsModal(false);
      }
    },
    [createStatus]
  );

  const changeTab = (index: number, id: number) => {
    setDeleteId(id);
    setActiveTabIndex(index);
    setAnimationDirection(
      index < activeTabIndex
        ? TRANSITION_DIRECTIONS.BACKWARDS
        : TRANSITION_DIRECTIONS.FORWARD
    );
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      participantsModalRef.current &&
      !(participantsModalRef.current as Element).contains(target)
    ) {
      setIsModal(false);
    }
    if (
      deleteModalRef.current &&
      !(deleteModalRef.current as Element).contains(target)
    ) {
      setIsDeleteModal(false);
    }
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    if (name === 'roles') {
      setRoles(prevState => {
        if (!prevState.includes(value)) {
          return [...prevState, value];
        } else {
          return prevState.filter(role => role !== value);
        }
      });
    } else if (name === 'individualNumber') {
      setIndividualNumber(value);
    } else if (name === 'companyName') {
      setCompanyName(value);
    }
  };

  const onPopupClick = () => {
    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
      CompaniesApi.putLockAndUnlockCompany(
        individualNumber,
        'as_analyst',
        true
      );
    }

    setIsInfoPopup(true);
  };

  const onSubmit = () => {
    createBorrowerCompany({
      applicationId: +match.params.id,
      individualNumber,
      name: companyName,
      roles
    });

    createNewClient({
      companyinn: individualNumber,
      companyshortname: companyName
    });

    setIndividualNumber('');
    setCompanyName('');
    setRoles([]);
  };

  const handleSetFile = (guid: string, file) => {
    setFile({ id: guid, file });
  };

  const postFile = (docCategory: number, guid: string) => {
    if (file && externalApplication) {
      const formData = new FormData();
      formData.append('file', file.file);

      if (guid) {
        setFile({ id: null, file: null });

        if (docCategory === 1) {
          postFinFile(guid, formData);
        } else if (docCategory === 2) {
          postNotFinFile(guid, formData);
        }
      }
    }
  };

  return (
    <CompanyTabsStyled>
      <p>Участники заявки:</p>
      <CompanyTabsHeader>
        {tabs.map(({ label, data }, key) => (
          <React.Fragment key={key}>
            <CompanyTabHeaderItem
              selected={key === activeTabIndex}
              animationDirection={animationDirection}
              onClick={() => changeTab(key, data.id)}
            >
              {/* User can't delete first (main) borrower company,
                  because it was put there automatically */}
              {key > 0 && (
                <RemoveCompanyBtn
                  icon={faTimes}
                  onClick={() => {
                    setIsDeleteModal(true);
                    setDeleteItem(data);
                  }}
                />
              )}

              <div>
                <p>{label}</p>
                <p>ИНН {data.individualNumber}</p>
                <CompanyTabHeaderRole selected={key === activeTabIndex}>
                  {data.roles.map((role, index) => (
                    <p key={index}>
                      {role === 'PRINCIPAL'
                        ? 'Принципал'
                        : role === 'GUARANTOR'
                          ? 'Поручитель'
                          : role === 'PLEDGER'
                            ? 'Залогодатель'
                            : role === 'BORROWER' && 'Заемщик'}
                      {index !== data.roles.length - 1 && ','}
                    </p>
                  ))}
                </CompanyTabHeaderRole>
              </div>
            </CompanyTabHeaderItem>
          </React.Fragment>
        ))}

        <AddCompanyHeaderItem onClick={() => setIsModal(true)}>
          <AddCompanyBtn />
          Добавить участника
        </AddCompanyHeaderItem>
      </CompanyTabsHeader>

      <TitleTabContainer>
        <p>Список документов</p>
        <FontAwesomeIcon
          icon={isSectionDocumentList ? faChevronUp : faChevronDown}
          onClick={() => setIsSectionDocumentList(prevState => !prevState)}
        />
      </TitleTabContainer>

      {isSectionDocumentList && (
        <>
          {statusDocumentList === REQUEST_STATUSES.ERROR && (
            <p>Ошибка: не удалось создать список документов автоматически</p>
          )}

          <CompanyHead>
            <p>{companyName}</p>
            <p>ИНН: {companyINN}</p>
          </CompanyHead>

          <TableWrapper>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="12%">Тип документа</TableThStyled>
                  <TableThStyled width="8%">Период</TableThStyled>
                  <TableThStyled width="10%" />
                  <TableThStyled width="25%" />
                </tr>
              </TableHeaderStyled>
              <tbody>
                {filtredInnDocuments.map(document => (
                  <TableRowStyled key={document.guid}>
                    <TDDocumentName text={document.documentName}>
                      <div>{document.documentName}</div>
                      {/* {document.documentCategory === 2 &&
                        document.documentTypeId === 19 && (
                          <TooltipBoxStyle text={document.extTypeDescription}>
                            {document.extTypeDescription}
                          </TooltipBoxStyle>
                        )} */}
                    </TDDocumentName>
                    <td>
                      <Period>
                        <p>
                          {document.taxYear && document.taxPeriodName
                            ? `${document.taxYear}, ${document.taxPeriodName}`
                            : '-'}
                        </p>
                      </Period>
                    </td>
                    <td>
                      {document.isMandatory && (
                        <IsNecessarily isUploaded={document.isUploaded}>
                          *Обязательный
                        </IsNecessarily>
                      )}
                    </td>
                    <td>
                      <FileContainer>
                        <IsUploadContainer isUploaded={document.isUploaded}>
                          {document.isUploaded ? (
                            <div>
                              <FontAwesomeIcon icon={faCheck} />
                              <p>Загружен</p>
                            </div>
                          ) : (
                            <div>
                              <FontAwesomeIcon icon={faTimes} />
                              <p>Не загружен</p>
                            </div>
                          )}
                        </IsUploadContainer>
                        {!document.isUploaded && (
                          <input
                            type="file"
                            onChange={e =>
                              handleSetFile(document.guid, e.target.files[0])
                            }
                          />
                        )}
                        {file.id === document.guid && (
                          <ButtonContainer>
                            <UploadButton
                              status={
                                statusFinDocUpload === REQUEST_STATUSES.REQUEST
                              }
                              disabled={
                                statusFinDocUpload === REQUEST_STATUSES.REQUEST
                              }
                              onClick={() =>
                                postFile(
                                  document.documentCategory,
                                  document.guid
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faUpload} />
                              <p>Загрузить</p>
                            </UploadButton>
                            {statusFinDocUpload ===
                              REQUEST_STATUSES.REQUEST && (
                              <SpinnerApi icon={faSpinner} />
                            )}
                          </ButtonContainer>
                        )}
                      </FileContainer>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          </TableWrapper>

          {filtredPersonDocuments.map((person, index) => (
            <React.Fragment key={index}>
              <CompanyHead>
                <p>{person.personName}</p>
                <p>{person.personComment}</p>
              </CompanyHead>

              <TableWrapper>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="12%">Тип документа</TableThStyled>
                      <TableThStyled width="8%" />
                      <TableThStyled width="25%" />
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {person.documents.map((document, index) => (
                      <TableRowStyled key={index}>
                        <TDDocumentName>
                          <div>{document.documentName}</div>
                          {document.documentCategory === 2 &&
                            document.documentTypeId === 19 && (
                              <TooltipBoxStyle
                                text={document.extTypeDescription}
                              >
                                {document.extTypeDescription}
                              </TooltipBoxStyle>
                            )}
                        </TDDocumentName>
                        <td>
                          {document.isMandatory && (
                            <IsNecessarily isUploaded={document.isUploaded}>
                              *Обязательный
                            </IsNecessarily>
                          )}
                        </td>
                        <td>
                          <FileContainer>
                            <IsUploadContainer isUploaded={document.isUploaded}>
                              {document.isUploaded ? (
                                <div>
                                  <FontAwesomeIcon icon={faCheck} />
                                  <p>Загружен</p>
                                </div>
                              ) : (
                                <div>
                                  <FontAwesomeIcon icon={faTimes} />
                                  <p>Не загружен</p>
                                </div>
                              )}
                            </IsUploadContainer>
                            {!document.isUploaded && (
                              <input
                                type="file"
                                onChange={e =>
                                  handleSetFile(
                                    document.guid,
                                    e.target.files[0]
                                  )
                                }
                              />
                            )}
                            {file.id === document.guid && (
                              <ButtonContainer>
                                <UploadButton
                                  status={
                                    statusNonFinDocUpload ===
                                    REQUEST_STATUSES.REQUEST
                                  }
                                  disabled={
                                    statusNonFinDocUpload ===
                                    REQUEST_STATUSES.REQUEST
                                  }
                                  onClick={() =>
                                    postFile(
                                      document.documentCategory,
                                      document.documentGuid
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faUpload} />
                                  <p>Загрузить</p>
                                </UploadButton>
                                {statusNonFinDocUpload ===
                                  REQUEST_STATUSES.REQUEST && (
                                  <SpinnerApi icon={faSpinner} />
                                )}
                              </ButtonContainer>
                            )}
                          </FileContainer>
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              </TableWrapper>
            </React.Fragment>
          ))}
        </>
      )}

      <TitleTabContainer>
        <p>Список документов (старый формат)</p>
        <FontAwesomeIcon
          icon={isSectionOldDocumentList ? faChevronUp : faChevronDown}
          onClick={() => setIsSectionOldDocumentList(prevState => !prevState)}
        />
      </TitleTabContainer>

      {isSectionOldDocumentList &&
        (!!tabs[activeTabIndex] ? (
          <StepsTransitionGroup
            {...animation(animationDirection, { duration: 200 })}
            component={CompanyTabBody}
            pageKey={activeTabIndex}
          >
            <CompanyTabItem>
              <BorrowerCompanyBlock
                borrowerCompany={tabs[activeTabIndex].data}
                errors={tabs[activeTabIndex].errors}
                fileUploadBlock={borrowerCompanyFileUploadBlock}
              />
              {/* TODO refactor */}
            </CompanyTabItem>
          </StepsTransitionGroup>
        ) : (
          <WarningBlock
            text={[
              'Вы не добавили ни одной компании заемщика.',
              <React.Fragment>
                Чтобы добавить компанию, нажмите "
                <WarningAddCompanyBtn onClick={() => setIsModal(true)}>
                  Добавить
                </WarningAddCompanyBtn>
                ".
              </React.Fragment>,
              `Заявки без документов компаний заемщика банками к рассмотрению не принимаются.`
            ]}
          />
        ))}

      {(createStatus === REQUEST_STATUSES.REQUEST ||
        deleteStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={50} />
        </LoaderContainerStyled>
      )}

      <ModalContainer visible={isModal} onClick={handleOutsideClick}>
        <PopupContentParticipants ref={participantsModalRef}>
          <h2>Добавление участника сделки</h2>
          <span onClick={() => setIsModal(false)}>X</span>

          <WrapperInputDetail>
            <Input
              required
              label="ИНН"
              name="individualNumber"
              value={individualNumber}
              onChange={onFilterChange}
            />

            {statusInn === REQUEST_STATUSES.REQUEST && (
              <SpinnerApi icon={faSpinner} size={20} />
            )}

            {individualNumber &&
            individualNumber.length < 10 ? null : statusInn ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              statusInn === REQUEST_STATUSES.GOT &&
              !isInfoPopup && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={onPopupClick}
                />
              )
            )}
          </WrapperInputDetail>

          <Input
            required
            label="Компания"
            name="companyName"
            value={companyName}
            onChange={onFilterChange}
          />

          <CheckboxGroup
            required
            name="roles"
            label="Роль"
            checkboxes={Object.keys(Role).map(type => ({
              label: Role[type],
              value: type,
              checked: roles.includes(type),
              onChange: onFilterChange
            }))}
          />

          <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
            <Button
              label="+ Добавить"
              onClick={onSubmit}
              disabled={!companyName || !individualNumber || !roles.length}
            />
            {createStatus === REQUEST_STATUSES.REQUEST && (
              <SpinnerApi icon={faSpinner} />
            )}
          </div>
        </PopupContentParticipants>
      </ModalContainer>

      <ModalContainer visible={isDeleteModal} onClick={handleOutsideClick}>
        <PopupContentParticipants ref={deleteModalRef}>
          <h2>Подтвердите удаление участника из заявки:</h2>
          <span onClick={() => setIsDeleteModal(false)}>X</span>

          {!!deleteItem && (
            <ParticipantsInfo>
              <p>{deleteItem.name}</p>
              <p>ИНН {deleteItem.individualNumber}</p>
              <div>
                {deleteItem.roles.map((role, index) => (
                  <p key={index}>
                    {role === 'PRINCIPAL'
                      ? 'Принципал'
                      : role === 'GUARANTOR'
                        ? 'Поручитель'
                        : role === 'PLEDGER' && 'Залогодатель'}
                    {index !== deleteItem.roles.length - 1 && ','}
                  </p>
                ))}
              </div>
            </ParticipantsInfo>
          )}
          <ButtonWrapper>
            <Button
              label="Удалить"
              onClick={() => {
                setIsDeleteModal(false);
                deleteBorrowerCompany({ id: deleteId });
              }}
            />
            <Button label="Отмена" onClick={() => setIsDeleteModal(false)} />
          </ButtonWrapper>
        </PopupContentParticipants>
      </ModalContainer>
    </CompanyTabsStyled>
  );
};

const mapStateToProps = ({
  ExternalAgentRole,
  BorrowerCompanies,
  SCF,
  User
}: STORE & CRM) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  statusExternalApplication: ExternalAgentRole.getApplicationExternal.status,
  companies: BorrowerCompanies.getBorrowerCompaniesList.data,
  createStatus: BorrowerCompanies.createBorrowerCompany.status,
  deleteStatus: BorrowerCompanies.deleteBorrowerCompany.status,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  permissions: User.getUserData.data.permissions,
  documentList: ExternalAgentRole.getDocumentListExternal.data,
  statusDocumentList: ExternalAgentRole.getDocumentListExternal.status,
  statusFinDocUpload: ExternalAgentRole.postFinancialDocumentExternal.status,
  statusNonFinDocUpload:
    ExternalAgentRole.postNonFinancialDocumentExternal.status,
  statusPostCreateDocument: ExternalAgentRole.postDocumentListExternal.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createBorrowerCompany,
      resetCreateBorrowerCompany,
      deleteBorrowerCompany,
      getCompanyByInn,
      resetStateCompanyByInn,
      createNewClient,
      getDocumentListExternal,
      postFinFile,
      postNotFinFile,
      postDocumentListExternal
    },
    dispatch
  );

const BorrowerCompaniesTabsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompaniesTabs)
);

export { BorrowerCompaniesTabsConnect as BorrowerCompaniesTabs };
