import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetFinancialsSummaryStoreState extends Reducer {
  data: ResponseData[];
}

export interface ResponseData {
  ccc: number;
  debt: number;
  debt_to_ebitda: number;
  dio: number;
  dpo: number;
  dso: number;
  ebitda: number;
  id: number;
  net_debt: number;
  pc: number;
  row1150: number;
  row1200: number;
  row1210: number;
  row1230: number;
  row1250: number;
  row1300: number;
  row1410: number;
  row1500: number;
  row1510: number;
  row1520: number;
  row1600: number;
  row2110: number;
  row2120: number;
  row2200: number;
  row2400: number;
  sok: number;
  year: number;
}

export enum GET_FINANCIALS_SUMMARY {
  REQ = 'GET_FINANCIALS_SUMMARY_REQ',
  GOT = 'GET_FINANCIALS_SUMMARY_GOT',
  ERR = 'GET_FINANCIALS_SUMMARY_ERR',
  RESET = 'GET_FINANCIALS_SUMMARY_RESET'
}

export const initialState: GetFinancialsSummaryStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getFinancialsSummary = (
  state: GetFinancialsSummaryStoreState = initialState,
  action: {
    type: GET_FINANCIALS_SUMMARY;
    data: ResponseData[];
    error: {};
  }
): GetFinancialsSummaryStoreState => {
  switch (action.type) {
    case GET_FINANCIALS_SUMMARY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINANCIALS_SUMMARY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FINANCIALS_SUMMARY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_FINANCIALS_SUMMARY.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getFinancialsSummary;
