import { GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL } from '../reducers/getProductsListByExternalCredential';
import { ExternalCredentialsOfProductsRead } from './setExternalCredentialsOfProductsFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ExternalCredentialsOfProductsRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  externalCredentialId: number;
}

export const req = (data: RequestData) => ({
  type: GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL.ERR,
  error
});
