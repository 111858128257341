import styled from 'styled-components';

import { navyBlue, gray } from 'shared/styled';
import { ButtonStyled } from 'src/shared/ui/Button/styled';

export const CancelApplicationProcessModalStyled = styled.div`
  p {
    line-height: 1.7;
    color: ${gray};
  }
`;

export const BtnsBlock = styled.div`
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    width: 170px;
  }
`;

export const DontCancelBtn = styled(ButtonStyled)`
  color: ${gray};
  background-color: transparent;

  &&:hover {
    color: ${navyBlue};
    background-color: transparent;
  }
`;
