import { PurchaseContract } from '../actions/setPurchaseContract';

export interface SetPurchaseContractStoreState extends Partial<PurchaseContract> { }

export enum SET_PURCHASE_CONTRACT {
  SET = 'SET_PURCHASE_CONTRACT_SET',
  RESET = 'SET_PURCHASE_CONTRACT_RESET',
}

export const initialState: SetPurchaseContractStoreState = {
  supplierInn: '',
  supplierName: '',
  contractName: '',
  contractNumber: '',
  contractStartDate: '',
  contractEndDate: null,
  isPerpetual: false,
};

const setPurchaseContract = (
  state: SetPurchaseContractStoreState = initialState,
  action: {
    type: string;
    data: PurchaseContract;
  }
): SetPurchaseContractStoreState => {
  switch (action.type) {
    case SET_PURCHASE_CONTRACT.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_PURCHASE_CONTRACT.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setPurchaseContract;
