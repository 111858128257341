import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/postAddSupplier';

import { POST_ADD_SUPPLIER } from 'src/features/SCF/reducers/postAddSupplier';

import SCFApi from 'src/features/SCF/api';

function* postAddSupplier(action: { type: POST_ADD_SUPPLIER; inn: string }) {
  try {
    yield call(SCFApi.postAddSupplier, action.inn);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddSupplierSaga() {
  yield takeLatest(POST_ADD_SUPPLIER.REQ, postAddSupplier);
}
