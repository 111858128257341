import styled from 'styled-components';
import {
  blue,
  gray,
  lighterBlue,
  lightGray,
  rr
} from 'shared/styled';

export const UserRoleContainerStyled = styled.div`
  max-width: 200px;
  background-color: ${lighterBlue};
`;

export const TDEmailStyle = styled.td`
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible !important;
`;

export const CheckboxStyleEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : 'orange')};
  }
`;

export const CheckboxStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : gray)};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    right: 24px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TDCopyTooltip = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const CopyBlock = styled.div`
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};
  text-overflow: ellipsis;
  overflow: hidden;

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 10;
    bottom: -20px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  :hover{
    color: ${blue};
  }
`;
