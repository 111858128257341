import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/putRejectSupplier';

import { PUT_REJECT_SUPPLIER } from 'entities/SCF/Debtor/model/reducers/putRejectSupplier';
import SCFApi from 'entities/SCF/Debtor/api';

function* putRejectSupplier(action: {
  type: PUT_REJECT_SUPPLIER;
  inn: string;
}) {
  try {
    yield call(SCFApi.putRejectSupplier, action.inn);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putRejectSupplierSaga() {
  yield takeLatest(PUT_REJECT_SUPPLIER.REQ, putRejectSupplier);
}
