import styled from 'styled-components';
import { rl } from 'shared/styled';
import { Button } from 'shared/ui/Button';

export const AddInvoiceButton = styled(Button)`
  width: fit-content;
  height: 40px;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;
