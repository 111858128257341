import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFileSettingsCurrent';
import { GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT } from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFileSettingsCurrent';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDebtorRegistryFileSettingsCurrent(action: {
  type: GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT;
  data: null;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getDebtorRegistryFileSettingsCurrent,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorRegistryFileSettingsCurrentSaga() {
  yield takeLatest(
    GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT.GET_DEBTOR_REGISTRY_FILE_SETTINGS_CURRENT_REQ,
    getDebtorRegistryFileSettingsCurrent
  );
}
