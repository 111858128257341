import { GET_PRODUCTS_BY_BANK_LIST } from '../reducers/getProductsByBankList';
import { ProductRead } from './setProductFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ProductRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  bankInn: string;
}

export const req = (data: RequestData) => ({
  type: GET_PRODUCTS_BY_BANK_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_BY_BANK_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_BY_BANK_LIST.ERR,
  error
});
