import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  req as getSupplyContractsList,
  RequestData,
  ResponseData
} from 'src/features/SupplyContracts/actions/getSupplyContractsList';
import {
  req as getFactoringContractsList,
  RequestData as FactoringListRequestData,
  ResponseData as FactoringListResponseData
} from 'src/features/FactoringContracts/actions/getFactoringContractsList';
import {
  req as getBuyersToSupplierLinkList,
  ResponseData as BuyersToSupplierLinkListResponseData,
  RequestData as BuyersToSupplierLinkListRequestData
} from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';
import SCFApi from 'src/features/SCF/api';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { INNContainer, INNField, NoData } from './styled';
import { Menu } from 'src/features/Layouts/components';
import { BUYERS_TO_SUPPLIER_TABS } from '../../types';
import { SupplyList } from 'src/features/SupplyContracts/components/SupplyList/SupplyList';
import { FactoringList } from 'src/features/FactoringContracts/components/FactoringContractsList/FactoringContractsList';
import { BuyersToSupplierLinkList } from '../BuyersToSupplierLinkList/BuyersToSupplierLinkList';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  supplyContracts: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringContracts: FactoringListResponseData;
  statusFactoringContracts: REQUEST_STATUSES;
  errorFactoringContracts: ResponseError;
  buyersToSupplierLinkList: BuyersToSupplierLinkListResponseData;
  statusBuyersToSupplierLinkList: REQUEST_STATUSES;
  errorBuyersToSupplierLinkList: ResponseError;
}

interface DispatchToProps {
  getSupplyContractsList: (data: RequestData) => void;
  getFactoringContractsList: (data: FactoringListRequestData) => void;
  getBuyersToSupplierLinkList: (
    data: BuyersToSupplierLinkListRequestData
  ) => void;
}

interface MatchProps {
  tab: string;
  debtor_inn: string;
  supplier_inn: string;
}

type Props = RouteComponentProps<MatchProps> & StateToProps & DispatchToProps;

const BuyersToSupplierLinkView: React.FC<Props> = ({
  getSupplyContractsList,
  supplyContracts,
  status,
  getFactoringContractsList,
  factoringContracts,
  statusFactoringContracts,
  errorFactoringContracts,
  error,
  getBuyersToSupplierLinkList,
  buyersToSupplierLinkList,
  statusBuyersToSupplierLinkList,
  errorBuyersToSupplierLinkList,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState(
    (match.params.tab && match.params.tab.toUpperCase()) || 'DEFAULT'
  );
  const [supplierCompanyInfo, setSupplierCompanyInfo] = React.useState<
    ResponseDataType
  >(null);
  const [debtorCompanyInfo, setDebtorCompanyInfo] = React.useState<
    ResponseDataType
  >(null);

  React.useEffect(() => {
    getSupplyContractsList({
      page: 1,
      pageSize: 20,
      DebtorInnToFilterBy: match.params.debtor_inn,
      SupplierInnToFilterBy: match.params.supplier_inn
    });

    getFactoringContractsList({
      page: 1,
      pageSize: 20,
      DebtorInnToFilterBy: match.params.debtor_inn,
      SupplierInnToFilterBy: match.params.supplier_inn
    });

    getBuyersToSupplierLinkList({
      page: 1,
      pageSize: 20,
      DebtorInnToFilterBy: match.params.debtor_inn,
      SupplierInnToFilterBy: match.params.supplier_inn
    });

    SCFApi.getCompanyByInnThirdParty({ inn: match.params.debtor_inn }).then(
      (res: ResponseDataType) => setDebtorCompanyInfo(res)
    );

    SCFApi.getCompanyByInnThirdParty({ inn: match.params.supplier_inn }).then(
      (res: ResponseDataType) => setSupplierCompanyInfo(res)
    );
  }, []);

  const handleActiveTab = (tabName: string) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace({ pathname: newPathname, search: 'page=1' });
    setActiveTab(tabName);
  };

  const navigateToCreateSupplierLink = () => {
    history.push('/crm/buyers_to_suppliers_links/new', {
      supplierInn: match.params.debtor_inn,
      debtorInn: match.params.supplier_inn
    });
  };

  const navigateToCompanyBuyers = () => {
    window.open(
      `/crm/companies/${supplierCompanyInfo.inn}/counterparties/buyers`,
      '_blank'
    );
  };

  const navigateToCompanySuppliers = () => {
    window.open(
      `/crm/companies/${debtorCompanyInfo.inn}/counterparties`,
      '_blank'
    );
  };

  return (
    <ApplicationViewStyled>
      <ApplicationTitle>
        Связка {match.params.debtor_inn} - {match.params.supplier_inn}
      </ApplicationTitle>
      <INNContainer>
        <div>
          {supplierCompanyInfo && (
            <INNField>
              <p>Поставщик:</p>
              <div>
                <p>ИНН:</p>
                <p>{supplierCompanyInfo.inn}</p>
                <p onClick={navigateToCompanyBuyers}>открыть в CRM</p>
              </div>
              <div>
                <p>Наименование:</p>
                <p>{supplierCompanyInfo.companyShortName}</p>
              </div>
            </INNField>
          )}
          {debtorCompanyInfo && (
            <INNField>
              <p>Дебитор:</p>
              <div>
                <p>ИНН:</p>
                <p>{debtorCompanyInfo.inn}</p>
                <p onClick={navigateToCompanySuppliers}>открыть в CRM</p>
              </div>
              <div>
                <p>Наименование:</p>
                <p>{debtorCompanyInfo.companyShortName}</p>
              </div>
            </INNField>
          )}
        </div>
      </INNContainer>

      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={BUYERS_TO_SUPPLIER_TABS}
      />

      {activeTab === 'DEFAULT' &&
        (buyersToSupplierLinkList.items.length === 1 ? (
          <SupplyList
            supplyContracts={supplyContracts}
            status={status}
            error={error}
          />
        ) : buyersToSupplierLinkList.items.length === 0 &&
        statusBuyersToSupplierLinkList === REQUEST_STATUSES.GOT ? (
          <NoData>
            <h3>СВЯЗКА НЕ НАЙДЕНА</h3>
            <p>на платформе нет такой связки поставщика и дебитора</p>
            <Button
              label={'Создать связку'}
              onClick={navigateToCreateSupplierLink}
              w="fit-content"
              h="40px"
            />
          </NoData>
        ) : (
          buyersToSupplierLinkList.items.length > 1 && (
            <>
              <NoData>
                <h3>ОШИБКА</h3>
                <p>найдено несколько одинаковых связок</p>
              </NoData>
              <BuyersToSupplierLinkList
                buyersToSupplierLinkList={buyersToSupplierLinkList}
                status={statusBuyersToSupplierLinkList}
                error={errorBuyersToSupplierLinkList}
              />
            </>
          )
        ))}

      {activeTab === 'FACTORING_CONTRACTS' && (
        <FactoringList
          factoringContracts={factoringContracts}
          status={statusFactoringContracts}
          error={errorFactoringContracts}
        />
      )}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  SupplyContracts,
  FactoringContracts,
  BuyersToSupplierLink
}: CRM) => ({
  supplyContracts: SupplyContracts.getSupplyContractsList.data,
  status: SupplyContracts.getSupplyContractsList.status,
  error: SupplyContracts.getSupplyContractsList.error,
  factoringContracts: FactoringContracts.getFactoringContractsList.data,
  statusFactoringContracts: FactoringContracts.getFactoringContractsList.status,
  errorFactoringContracts: FactoringContracts.getFactoringContractsList.error,
  buyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.data,
  statusBuyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.status,
  errorBuyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringContractsList,
      getSupplyContractsList,
      getBuyersToSupplierLinkList
    },
    dispatch
  );

const BuyersToSupplierLinkViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BuyersToSupplierLinkView)
);

export { BuyersToSupplierLinkViewConnect as BuyersToSupplierLinkView };
