import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getTenderTeledoc';

import { GET_TENDER_TELEDOC } from '../reducers/getTenderTeledoc';
import ApplicationApi from '../api';

function* getTenderTeledoc(action: {
  type: GET_TENDER_TELEDOC;
  id: string;
  eis: string;
}): Generator {
  try {
    const res: any = yield call(
      ApplicationApi.getTenderTeledoc,
      action.id,
      action.eis
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getTenderTeledocSaga(): Generator {
  yield takeLatest(GET_TENDER_TELEDOC.REQ, getTenderTeledoc);
}
