import styled from 'styled-components';

export const SFCLinkButtonStyled = styled.button.attrs({
  to: '',
  role: 'link'
})`
  height: 50vh;
  width: 300px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  a {
    height: 50vh;
    width: 300px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    text-decoration: none;
    :hover {
      background-color: #F8F8F8;
    }
  }
  h1 {
    margin-bottom: 7px;
    font-size: 28px;
  }
`;
