import { GET_PARTICIPANTS_OF_ACTIVITY } from 'src/features/Activities/reducers/getParticipantsOfActivity';
import { ParticipantsOfActivity } from 'src/features/Activities/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ParticipantsOfActivity[];
}

export const req = (id: string) => ({
  type: GET_PARTICIPANTS_OF_ACTIVITY.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_PARTICIPANTS_OF_ACTIVITY.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_PARTICIPANTS_OF_ACTIVITY.ERR,
  error
});
