import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getNonFinancialDocuments';

import { GET_NON_FINANCIAL_DOCUMENTS } from '../reducers/getNonFinancialDocuments';
import DossierApi from '../api';

function* getNonFinancialDocuments(action: {
  type: GET_NON_FINANCIAL_DOCUMENTS;
  name: string;
  inn: string;
}): Generator {
  try {
    let res: any;

    res = yield call(
      DossierApi.getNonFinancialDocuments,
      action.name,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDossierSaga(): Generator {
  yield takeLatest(GET_NON_FINANCIAL_DOCUMENTS.REQ, getNonFinancialDocuments);
}
