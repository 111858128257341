import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'InternalAgentsManager/actions/getInternalAgentsList';
import InternalAgentsManagerApi from 'InternalAgentsManager/api';
import { GET_INTERNAL_AGENTS_LIST } from '../reducers/getInternalAgentsList';

function* getInternalAgentsList(action: {
  type: GET_INTERNAL_AGENTS_LIST}) {
  try {
    const res:any = yield call(InternalAgentsManagerApi.getInternalAgentsList);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInternalAgentsSaga() {
  yield takeLatest(
    GET_INTERNAL_AGENTS_LIST.REQ,
    getInternalAgentsList
  );
}
