import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from './../actions/getCompanyInn';
import { GET_COMPANY_INN } from './../reducers/getCompanyInn';

import SCFApi from '../api';

function* getCompanyInn(action: { type: GET_COMPANY_INN }) {
  try {
    const res:any = yield call(SCFApi.getCompanyInn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyInnSaga() {
  yield takeLatest(GET_COMPANY_INN.REQ, getCompanyInn);
}
