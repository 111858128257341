import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getSupplyContractByGuid';

export interface GetSupplyContractByGuidStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_SUPPLY_CONTRACT_BY_GUID {
  GET_SUPPLY_CONTRACT_BY_GUID_REQ = 'GET_SUPPLY_CONTRACT_BY_GUID_REQ',
  GET_SUPPLY_CONTRACT_BY_GUID_GOT = 'GET_SUPPLY_CONTRACT_BY_GUID_GOT',
  GET_SUPPLY_CONTRACT_BY_GUID_ERR = 'GET_SUPPLY_CONTRACT_BY_GUID_ERR'
}

export const initialState: GetSupplyContractByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getSupplyContractByGuid = (
  state: GetSupplyContractByGuidStoreState = initialState,
  action: {
    type: GET_SUPPLY_CONTRACT_BY_GUID;
    data: ResponseDataType;
    error: {};
  }
): GetSupplyContractByGuidStoreState => {
  switch (action.type) {
    case GET_SUPPLY_CONTRACT_BY_GUID.GET_SUPPLY_CONTRACT_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUPPLY_CONTRACT_BY_GUID.GET_SUPPLY_CONTRACT_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUPPLY_CONTRACT_BY_GUID.GET_SUPPLY_CONTRACT_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSupplyContractByGuid;
