import { GET_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/getContractByDebtor';

export interface ResponseData {
  guid: string;
  contractGuid: string;
  bankAccountGuid: string;
  type: number;
  status: number;
  confirmedByDebtor: number;
  debtorConfirmUserId: any;
  debtorConfirmDateTime: string;
  confirmedBySupplier: any;
  confirmedByFactor: any;
  bankAccounts: {
    id: number;
    clientInn: string;
    bankInn: string;
    bankBik: string;
    bankName: string;
    accountNumber: string;
    correspondentAccountNumber: string;
    currencyNumber: string;
    currencyCode: string;
    guid: string;
  };
}

export const req = (guid: string) => ({
  type: GET_CONTRACT_BY_DEBTOR.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_CONTRACT_BY_DEBTOR.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CONTRACT_BY_DEBTOR.ERR,
  error
});
