import {
  GET_ACTIVITIES_BY_LEAD_EXTERNAL,
  ResponseDataExternal
} from '../reducers/getActivitiesByLeadExternal';

export const req = (leadId: string) => ({
  type: GET_ACTIVITIES_BY_LEAD_EXTERNAL.REQ,
  leadId
});

export const got = (data: ResponseDataExternal[]) => ({
  type: GET_ACTIVITIES_BY_LEAD_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ACTIVITIES_BY_LEAD_EXTERNAL.ERR,
  error
});
