import { GET_EXTERNAL_AGENT_PERIOD_LIST } from '../reducers/getExternalAgentPeriodList';
import { PeriodListType } from '../types';

export interface ResponseType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: PeriodListType[];
}

export const req = (inn: string) => ({
  type: GET_EXTERNAL_AGENT_PERIOD_LIST.REQ,
  inn
});

export const got = (data: ResponseType) => ({
  type: GET_EXTERNAL_AGENT_PERIOD_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_AGENT_PERIOD_LIST.ERR,
  error
});

export const reset = () => ({
  type: GET_EXTERNAL_AGENT_PERIOD_LIST.RESET
});
