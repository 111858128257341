import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseType } from '../actions/getFinancialStatements';

export interface GetFinancialStatementsStoreState extends Reducer {
  data: ResponseType;
}

export enum GET_FINANCIAL_STATEMENTS {
  REQ = 'GET_FINANCIAL_STATEMENTS_REQ',
  GOT = 'GET_FINANCIAL_STATEMENTS_GOT',
  ERR = 'GET_FINANCIAL_STATEMENTS_ERR'
}

export const initialState: GetFinancialStatementsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getFinancialStatements = (
  state: GetFinancialStatementsStoreState = initialState,
  action: {
    type: string;
    data: ResponseType;
    error: {};
  }
): GetFinancialStatementsStoreState => {
  switch (action.type) {
    case GET_FINANCIAL_STATEMENTS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINANCIAL_STATEMENTS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FINANCIAL_STATEMENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFinancialStatements;
