import { SET_LEAD_DATA } from '../reducers/setLeadFormData';
import { Leadogenerator } from '../types';
// TODO rename
import { APPLICATION_TYPES } from 'src/features/Application/types';

export interface LeadWrite {
  amount: number;
  applicationFinancingType: APPLICATION_TYPES;
  clientName: string;
  comment: string;
  contactEmail: string;
  contactFirstName: string;
  contactId: number;
  contactLastName: string;
  contactMiddleName: string;
  contactPhoneNumber: string;
  creatorId: number;
  id: number;
  inn: string;
  isDeleted: boolean;
  modifiedAt: string;
  purchaseNumber: null;
  rejectReason: null;
  sourceLink: null;
  sourceUserId: number;
  status: string;
  termEndDate: string;
  termStartDate: string;
  docsChecklist: number;
  bkiChecklist: number;
  bgParametersChecklist: number;
  applicationToBankCheckList: number;
  dealToBankChecklist: number;
}

export interface LeadRead extends LeadWrite {
  sourceUser: Leadogenerator;
}

export interface LeadsData extends Partial<LeadWrite> {}

export const setLeadFormData = (data: LeadsData) => ({
  type: SET_LEAD_DATA.SET,
  data
});

export const resetLeadFormData = () => ({
  type: SET_LEAD_DATA.RESET
});
