import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { req as uploadRegistries } from 'entities/SCF/Debtor/model/actions/uploadRegistries';
import { SagaRequestDataType as UploadRegistriesRequestDataType } from 'entities/SCF/Debtor/model/reducers/uploadRegistries';
import { InputFile } from 'src/features/SCF/components/InputFile/InputFile';
import { REQUEST_STATUSES, STORE } from 'globaltypes';
import { RegistriesAddStyled, AddBtn, AddBtnText } from './styles';

interface StateToProps {
  documentsUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  uploadRegistries: (data: UploadRegistriesRequestDataType) => void;
}

type OwnState = {
  filesDisplay: any[];
};

type Props = StateToProps & DispatchToProps & OwnState;

class RegistriesAdd extends React.Component<Props, OwnState> {
  constructor(props) {
    super(props);
    this.state = {
      filesDisplay: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(file) {
    this.setState({ filesDisplay: file });
  }

  onFilesUpload = registries => {
    this.handleChange(registries);
  };

  sendRegistry = () => {
    const { uploadRegistries } = this.props;
    uploadRegistries({
      files: this.state.filesDisplay
    });
    this.handleChange(null);
  };

  render() {
    const { documentsUploadStatus } = this.props;

    return (
      <RegistriesAddStyled>
        <InputFile
          label=""
          name="registry"
          required={false}
          files={
            this.state.filesDisplay !== null
              ? this.state.filesDisplay
              : undefined
          }
          multiple={true}
          uploadStatus={documentsUploadStatus}
          onFilesUpload={this.onFilesUpload}
          inputFileTypesDescription="файл реестра"
          inputFileFormatDescription="Excel или CSV"
        />
        {this.state.filesDisplay !== null ? (
          <AddBtn onClick={this.sendRegistry}>
            <AddBtnText>+ Добавить реестр</AddBtnText>
          </AddBtn>
        ) : (
          <AddBtn style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}>
            <AddBtnText>+ Добавить реестр</AddBtnText>
          </AddBtn>
        )}
      </RegistriesAddStyled>
    );
  }
}

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  documentsUploadStatus: SCFDebtor.uploadRegistries.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadRegistries
    },
    dispatch
  );

const RegistriesAddConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RegistriesAdd);

export { RegistriesAddConnect as RegistriesAdd };
