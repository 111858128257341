import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';
import { req as getApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/actions/getApplicationExternal';
import { RequestDataType as ReqApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';

import { EachForm } from 'Application/components/ApplicationForm/ApplicationProcess/ReusableComponents/EachForm/EachForm';
import { CreditingFormStep1 } from './CreditingFormStep1/CreditingFormStep1';
import { CreditingFormStep2 } from './CreditingFormStep2/CreditingFormStep2';
import { CreditingFormStep0 } from './CreditingFormStep0/CreditingFormStep0';
import { Loader } from 'src/features/Layouts/components';
import { ApplicationFormContrainer } from './styled';

interface StateToProps {
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getApplicationExternal: (data: ReqApplicationExternal) => void;
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & DispatchToProps & StateToProps;

const CreditingForm: React.FC<Props> = ({
  getApplicationExternal,
  status,
  setApplicationProcessMode,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.id) {
        getApplicationExternal({ id: match.params.id });
      }

      return () =>
        setApplicationProcessMode({ mode: APPLICATION_PROCESS_MODES.NONE });
    },
    [match.params.id, location.pathname]
  );

  if (status !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <ApplicationFormContrainer>
      <EachForm
        routes={[
          {
            path: 'options',
            component: CreditingFormStep0,
            step: 'Параметры заявки'
          },
          {
            path: 'credit_options',
            component: CreditingFormStep1,
            step: 'Параметры кредитования'
          },
          {
            path: 'documents',
            component: CreditingFormStep2,
            step: 'Документы по заявке'
          }
        ]}
      />
    </ApplicationFormContrainer>
  );
};

const mapStateToProps = ({ ExternalAgentRole }: STORE) => ({
  status: ExternalAgentRole.getApplicationExternal.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getApplicationExternal, setApplicationProcessMode },
    dispatch
  );

const CreditingFormConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CreditingForm)
);

export { CreditingFormConnect as CreditingForm };
