import { combineReducers } from 'redux';
import getApiKey, { GetApiKeyStoreState } from './getApiKey';
import getApiKeyPublic, { GetApiKeyPublicStoreState } from './getApiKeyPublic';
import refreshApiKey, { RefreshApiKeyStoreState } from './refreshApiKey';
import refreshApiKeyPublic, {
  RefreshApiKeyPublicStoreState
} from './refreshApiKeyPublic';
import getApiKeyInfo, { GetApiKeyInfoStoreState } from './getApiKeyInfo';

export interface ApiKeysReducers {
  getApiKey: GetApiKeyStoreState;
  getApiKeyPublic: GetApiKeyPublicStoreState;
  refreshApiKey: RefreshApiKeyStoreState;
  refreshApiKeyPublic: RefreshApiKeyPublicStoreState;
  getApiKeyInfo: GetApiKeyInfoStoreState;
}

export const ApiKeys = combineReducers({
  getApiKey,
  getApiKeyPublic,
  refreshApiKey,
  refreshApiKeyPublic,
  getApiKeyInfo
});
