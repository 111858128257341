export const russiaPhoneMask = [
  '+',
  '7',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

