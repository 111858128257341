import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RadioGroup } from 'shared/ui/RadioGroup';
import {
  CREDIT_GOZ,
  CREDIT_PRODUCTS,
  HAS_PLEDGES,
  OPEN_LIMITS
} from 'src/features/Filters/Companies/FilterByBankAndCredit/types';
import { RadioBtnState } from 'src/features/Companies/types';

interface Props {
  radioBtnState: RadioBtnState;
  handleRadioBtn: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterByBankAndCredit: React.FC<Props> = ({
  radioBtnState,
  handleRadioBtn
}) => {
  return (
    <>
      <RadioGroup
        label="Наличие кредитных продуктов:"
        name="creditInfo"
        keyValue={radioBtnState.creditInfo}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(CREDIT_PRODUCTS).map(value => ({
          value,
          label: CREDIT_PRODUCTS[value]
        }))}
      />
      <RadioGroup
        label="Наличие залогов:"
        name="pledgeInfo"
        keyValue={radioBtnState.pledgeInfo}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(HAS_PLEDGES).map(value => ({
          value,
          label: HAS_PLEDGES[value]
        }))}
      />
      <RadioGroup
        label="Информация о кредитной господдержке:"
        name="govSupportInfo"
        keyValue={radioBtnState.govSupportInfo}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(CREDIT_GOZ).map(value => ({
          value,
          label: CREDIT_GOZ[value]
        }))}
      />
      <RadioGroup
        label="Информация о предодобах:"
        name="openLimitsInfo"
        keyValue={radioBtnState.openLimitsInfo}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(OPEN_LIMITS).map(value => ({
          value,
          label: OPEN_LIMITS[value]
        }))}
      />
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByBankAndCreditConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByBankAndCredit);

export { FilterByBankAndCreditConnect as FilterByBankAndCredit };
