import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ProductsApi {
  static getAllProducts(data) {
    return ApiRequest('/api/bank_role/product/all', 'get', data);
  }

  static getProduct(id: number) {
    return ApiRequest(`/api/bank_role/product/id/${id}`);
  }

  static sendProductData(data: any) {
    return ApiRequest('/api/bank_role/product', 'post', data);
  }
}
