import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendFactoringSettingsByDebtorInn';
import { RequestDebtorInn as ResponseDataType } from '../actions/sendFactoringSettingsByDebtorInn';

export interface SendFactoringSettingsByDebtorInnStoreState extends Reducer {
  data: ResponseDataType;
}

export enum SEND_FACTORING_SETTINGS_BY_DEBTOR_INN {
  REQ = 'SEND_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ',
  GOT = 'SEND_FACTORING_SETTINGS_BY_DEBTOR_INN_GOT',
  ERR = 'SEND_FACTORING_SETTINGS_BY_DEBTOR_INN_ERR'
}

export const initialState: SendFactoringSettingsByDebtorInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    debtorInn: '',
  },
  error: {}
};

const sendFactoringSettingsByDebtorInn = (
  state: SendFactoringSettingsByDebtorInnStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType
    error: {};
  }
): SendFactoringSettingsByDebtorInnStoreState => {
  switch (action.type) {
    case SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.REQ:
      return {
        ...state,
        ...action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data,
      };
    case SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendFactoringSettingsByDebtorInn;
