import { GET_FACTORING_LIMIT } from '../reducers/getFactoringLimit';
import { FactoringLimitWrite } from './setFactoringLimitFormData';

export const req = (id: number) => ({
  type: GET_FACTORING_LIMIT.REQ,
  id
});

export const got = (factoringLimit: FactoringLimitWrite) => ({
  type: GET_FACTORING_LIMIT.GOT,
  factoringLimit
});

export const err = (error: {}) => ({
  type: GET_FACTORING_LIMIT.ERR,
  error
});
