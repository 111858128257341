import { combineReducers } from 'redux';
import getFactoringContractsList, {
  GetFactoringContractsListStoreState
} from './getFactoringContractsList';

export interface FactoringContractsReducers {
  getFactoringContractsList: GetFactoringContractsListStoreState;
}

export const FactoringContracts = combineReducers({
  getFactoringContractsList
});
