import { PUT_SET_PREAPPROVED } from '../reducers/putSetPreapproved';

export const req = (id: number) => ({
  type: PUT_SET_PREAPPROVED.REQ,
  id
});

export const got = () => ({
  type: PUT_SET_PREAPPROVED.GOT
});

export const err = (error: {}) => ({
  type: PUT_SET_PREAPPROVED.ERR,
  error
});
