import * as React from 'react';

import { ClickOutsideWrapper } from 'Common';

import { CrossBtn, scaleClassName } from 'shared/styled';
import { ModalBlockStyled } from './styles';

interface Props {
  className: string;
  animationState: string;
  children: any;
  closeModal: () => void;
}

export const ModalBlock: React.FC<Props> = ({
  children,
  className,
  animationState,
  closeModal
}) => (
  <ClickOutsideWrapper onClickOutside={closeModal}>
    <ModalBlockStyled
      className={`${className} ${scaleClassName(animationState)}`}
    >
      <CrossBtn onClick={closeModal} />
      {children}
    </ModalBlockStyled>
  </ClickOutsideWrapper>
);
