import { combineReducers } from 'redux';
import getTenders, { GetTendersStoreState } from './getTenders';

export interface TendersReducers {
  getTenders: GetTendersStoreState;
}

export const Tenders = combineReducers({
  getTenders
});
