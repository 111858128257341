import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gray, rr } from 'shared/styled';
import { Card } from 'src/features/Products/components/ProductView/styled';

export const CompanyMainInfoContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;
  margin-bottom: 10px;
`;

export const CompanyRoleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;

  & > div p {
    font-size: 18px;
  }
`;

export const CompanyRoleName = styled.p`
  font-weight: 300;
  color: #7a7d83;
  margin: 0 0 5px 0;
`;

export const OwnerCompanyRole = styled.p`
  font-weight: 700;
  color: #7a7d83;
  margin: 0;
`;

export const ButtonRoleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;
  margin-left: 100px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
`;

export const CardHolding = styled(Card)``;

export const CompanySectionTitle = styled.h2`
  font-family: ${rr};
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const SelectRoleContainer = styled.div`
  p {
    margin: 0;
  }

  select {
    width: 200px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;

    button {
      width: fit-content;
      height: 40px;
      :disabled {
        background-color: ${gray};
      }
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;
