import styled from 'styled-components';

import { Table } from 'Layouts/components';
import { TableRowStyled } from 'Layouts/components/Table/TableRow/styles';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { ApplicationBtnsWrapper } from '../../styles';

export const DebtorsTable = styled(Table)`
  ${InputContainerStyled} {
    max-width: 270px;
  }

  ${TableRowStyled} {
    height: 120px;
  }

  td {
    padding-right: 20px;

    &:nth-child(2),
    &:nth-child(3) {
      overflow: visible;
    }

    &:last-child {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

export const ApplicationBtnsWrapperStyled = styled(ApplicationBtnsWrapper)`
  width: 50%;
  max-width: 500px;
`;
