import { PUT_SEND_TO_BANK } from '../reducers/putSendToBank';

export const req = (id: number) => ({
  type: PUT_SEND_TO_BANK.REQ,
  id
});

export const got = () => ({
  type: PUT_SEND_TO_BANK.GOT
});

export const err = (error: {}) => ({
  type: PUT_SEND_TO_BANK.ERR,
  error
});

export const reset = () => ({
  type: PUT_SEND_TO_BANK.RESET
});
