import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getActivitiesType';

import { GET_ACTIVITIES_TYPE } from '../reducers/getActivitiesType';
import ActivitiesTypeApi from '../api';

function* getActivitiesType(action: { type: GET_ACTIVITIES_TYPE }): Generator {
  try {
    const res: any = yield call(ActivitiesTypeApi.getActivitiesType);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getActivitiesTypeSaga(): Generator {
  yield takeLatest(GET_ACTIVITIES_TYPE.REQ, getActivitiesType);
}
