import * as React from 'react';

import {
  OpenFileUploaderText,
  UploaderDescriptionBlock,
  UploaderDescriptionText,
  UploaderHintsBlock
} from './styles';

interface Props {
  openUploader: () => void;
}
export const FileUploadInfoBlock: React.FC<Props> = ({ openUploader }) => (
  <React.Fragment>
    <UploaderDescriptionBlock>
      <UploaderDescriptionText>
        Перетащите в эту область <br /> подписанные документы для загрузки
        <br /> или{' '}
        <OpenFileUploaderText onClick={openUploader}>
          добавьте файлы
        </OpenFileUploaderText>
      </UploaderDescriptionText>
    </UploaderDescriptionBlock>

    <UploaderHintsBlock>
      <p>
        Максимальный размер одного файла
        <b> 50 МБ</b>
      </p>
      <p>
        Недопустима загрузка файлов:
        <b> видео, аудио, *.exe, *.bat</b>
      </p>
    </UploaderHintsBlock>
  </React.Fragment>
);
