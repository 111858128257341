import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getSupplyContracts'

export interface GetSupplyContractsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_SUPPLY_CONTRACTS {
  GET_SUPPLY_CONTRACTS_REQ = 'GET_SUPPLY_CONTRACTS_REQ',
  GET_SUPPLY_CONTRACTS_GOT = 'GET_SUPPLY_CONTRACTS_GOT',
  GET_SUPPLY_CONTRACTS_ERR = 'GET_SUPPLY_CONTRACTS_ERR'
}

export const initialState: GetSupplyContractsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getSupplyContracts = (
  state: GetSupplyContractsStoreState = initialState,
  action: {
    type: GET_SUPPLY_CONTRACTS;
    data: ResponseDataType;
    error: {};
  }
): GetSupplyContractsStoreState => {
  switch (action.type) {
    case GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSupplyContracts;
