import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';
import { got, err, RequestDataType } from 'User/actions/resetPassword';
import { RESET_PASSWORD } from 'User/reducers/resetPassword';

function* resetPassword(action: {
  type: RESET_PASSWORD;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(UserApi.resetPassword, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD.RESET_PASSWORD_REQ, resetPassword);
}
