export const LeadStatuses = {
  EMPTY: '',
  // TODO rename CREATED to NEW
  CREATED: 'Новый',
  IN_WORK: 'В работе',
  REJECT: 'Отказ',
  FILLED: 'Оформлена заявка',
  APPROVED: 'Одобрено',
  DEAL_SUCCEED: 'Сделка успешна'
};
