import { all, call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getInteractionSummary';
import { GET_INTERACTION_SUMMARY } from '../reducers/getInteractionSummary';
import CompaniesApi from '../api';

function* getInteractionSummary(action: {
  type: GET_INTERACTION_SUMMARY;
  key: number;
  inn: string[];
}): Generator {
  try {
    const tasks = action.inn.map(inn =>
      call(CompaniesApi.getInteractionSummary, inn)
    );

    const results = yield all(tasks);

    yield put(got(results));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInteractionSummarySaga(): Generator {
  yield takeLatest(GET_INTERACTION_SUMMARY.REQ, getInteractionSummary);
}
