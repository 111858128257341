import { POST_SELECT_PRODUCT_LEAD } from '../reducers/postSelectProductLead';

export const req = (data: any) => ({
  type: POST_SELECT_PRODUCT_LEAD.REQ,
  data
});

export const reset = () => ({
  type: POST_SELECT_PRODUCT_LEAD.RESET
});

export const got = data => ({
  type: POST_SELECT_PRODUCT_LEAD.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_SELECT_PRODUCT_LEAD.ERR,
  error
});
