import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/InternalAgent/model/actions/getCheckSignature';

export interface GetCheckSignatureStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_CHECK_SIGNATURE_INTERNAL {
  REQ = 'GET_CHECK_SIGNATURE_INTERNAL_REQ',
  GOT = 'GET_CHECK_SIGNATURE_INTERNAL_GOT',
  ERR = 'GET_CHECK_SIGNATURE_INTERNAL_ERR',
  RESET = 'GET_CHECK_SIGNATURE_INTERNAL_RESET'
}

const initialState: GetCheckSignatureStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    pdfLink: null,
    result: null,
    signerCertificate: null,
    companyName: '',
    inn: '',
    lastName: '',
    name: '',
    personInn: '',
    position: ''
  },
  error: {}
};

const getCheckSignature = (
  state: GetCheckSignatureStoreState = initialState,
  action: {
    type: GET_CHECK_SIGNATURE_INTERNAL;
    data: ResponseData;
    error: {};
  }
): GetCheckSignatureStoreState => {
  switch (action.type) {
    case GET_CHECK_SIGNATURE_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CHECK_SIGNATURE_INTERNAL.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_CHECK_SIGNATURE_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_CHECK_SIGNATURE_INTERNAL.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getCheckSignature;
