import styled from 'styled-components';

import { Checkbox } from 'shared/ui/Checkbox';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { FormSection } from 'shared/ui/FormSection';

import { rr, gray, lighterBlue, navyBlue } from 'shared/styled';
import { ApplicationForm, ApplicationFormContent } from '../../styles';

export const CheckboxStyled = styled(Checkbox)`
  width: 100%;
  padding: 15px;

  margin: 20px 0;

  background-color: ${lighterBlue};
`;

export const InputRangeStyled = styled(InputRange)`
  margin-bottom: 20px;
`;

export const InputStyled = styled(Input)`
  margin-bottom: 20px;

  &:last-child {
    margin-top: 20px;
  }
`;

export const ApplicationFormRow = styled(ApplicationForm)`
  margin-bottom: 100px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ApplicationFormContentColumn = styled(ApplicationFormContent)`
  flex-direction: column;
`;

export const EquityRatioContainer = styled(FormSection)`
  margin-bottom: 20px;

  & > p {
    margin-top: 30px;
    font-size: 1.2rem;
    color: ${gray};
  }
`;

export const EquityRatioText = styled.div`
  height: 100px;
  padding: 20px 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: ${rr};
  font-size: 1.2rem;
  color: ${navyBlue};

  background-color: ${({ position, percentage }) =>
    position < percentage / 2
      ? 'rgba(253, 73, 116, 0.31)'
      : position < percentage
        ? 'rgba(243, 220, 46, 0.46)'
        : 'rgba(184, 241, 235, 0.49)'};
`;
