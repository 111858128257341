import {
  RequestDataType,
  PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE
} from 'entities/SCF/Supplier/model/reducers/putPurchaseContractSignatureSupplierRole';

export const req = (data: RequestDataType) => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.REQ,
  data
});

export const got = () => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.GOT
});

export const err = (error: {}) => ({
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.ERR,
  error
});
