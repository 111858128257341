import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchPledge';

import { PATCH_PLEDGE_ACTIONS } from '../reducers/patchPledge';
import PledgeApi from '../api';

function* patchPledge(action: {
  type: PATCH_PLEDGE_ACTIONS;
  id: string;
  data: any;
}): Generator {
  try {
    yield call(PledgeApi.patchPledge, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchPledgeSaga(): Generator {
  yield takeLatest(PATCH_PLEDGE_ACTIONS.REQ, patchPledge);
}
