import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getPerson';

import { GET_PERSON } from '../reducers/getPerson';
import PersonsApi from '../api';

function* getPerson(action: { type: GET_PERSON; inn: string }): Generator {
  try {
    const res: any = yield call(PersonsApi.getPerson, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPersonSaga(): Generator {
  yield takeLatest(GET_PERSON.REQ, getPerson);
}
