import { DELETE_PROPERTY_DOCUMENT } from '../reducers/deletePropertyDocument';

export const req = (fileId:number,propertyId:number) => ({
  type: DELETE_PROPERTY_DOCUMENT.REQ,
  fileId,
  propertyId
});

export const got = () => ({
  type: DELETE_PROPERTY_DOCUMENT.GOT,
});

export const err = (error: {}) => ({
  type: DELETE_PROPERTY_DOCUMENT.ERR,
  error
});
