import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getApplications';

export interface GetApplicationsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_APPLICATIONS {
  REQ = 'GET_APPLICATIONS_REQ',
  GOT = 'GET_APPLICATIONS_GOT',
  ERR = 'GET_APPLICATIONS_ERR'
}

export const initialState: GetApplicationsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getApplications = (
  state: GetApplicationsStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetApplicationsStoreState => {
  switch (action.type) {
    case GET_APPLICATIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_APPLICATIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_APPLICATIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getApplications;
