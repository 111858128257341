import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getFactoringContractsDebtorRole';
import { GET_FACTORING_CONTRACTS_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/getFactoringContractsDebtorRole';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getFactoringContractsDebtorRole(action: {
  type: GET_FACTORING_CONTRACTS_DEBTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getFactoringContractsDebtorRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractsDebtorRoleSaga() {
  yield takeLatest(
    GET_FACTORING_CONTRACTS_DEBTOR_ROLE.GET_FACTORING_CONTRACTS_DEBTOR_ROLE_REQ,
    getFactoringContractsDebtorRole
  );
}
