import * as React from 'react';

import {
  OpenFileUploaderText,
  UploaderDescriptionBlock,
  UploaderDescriptionText,
  UploaderHintsBlock
} from './styles';

interface Props {
  openUploader: () => void;
  fileTypeDescription: string;
  fileFormatDescription: string;
}

export const FileUploadInfoBlock: React.FC<Props> = ({ openUploader, fileTypeDescription, fileFormatDescription }) => (
  <React.Fragment>
    <UploaderDescriptionBlock>
      <UploaderDescriptionText>
        Перетащите в эту область <br /> {fileFormatDescription}{' '} в формате <br /> {fileTypeDescription}{' '}
       или{' '}
        <OpenFileUploaderText onClick={openUploader}>
          добавьте файл
        </OpenFileUploaderText>
      </UploaderDescriptionText>
    </UploaderDescriptionBlock>

    <UploaderHintsBlock>
      <p>
        Максимальный размер одного файла
        <b> 50 МБ</b>
      </p>
    </UploaderHintsBlock>
  </React.Fragment>
);
