import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getSupplyContract';

import { GET_SUPPLY_CONTRACT_INTERNAL } from '../reducers/getSupplyContractInternal';
import SupplyApi from '../api';

function* getSupplyContractInternal(action: {
  type: GET_SUPPLY_CONTRACT_INTERNAL;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(SupplyApi.getSupplyContract, action.guid);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractInternalSaga(): Generator {
  yield takeLatest(GET_SUPPLY_CONTRACT_INTERNAL.REQ, getSupplyContractInternal);
}
