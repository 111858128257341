import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { DOCUMENT_UPLOAD_TYPE } from 'Application/types';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { setGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';

import { got, err } from 'Application/actions/uploadDocuments';
import {
  SagaRequestDataType,
  UPLOAD_DOCUMENTS
} from 'Application/reducers/uploadDocuments';

import ApplicationApi from 'Application/api';
import { setContractFinancingFormData } from '../actions/setContractFinancingFormData';
import { setPropertyFinancingFormData } from '../actions/setPropertyFinancingFormData';

// TODO create single uploadDocument saga
function* uploadDocuments(action: {
  type: UPLOAD_DOCUMENTS;
  data: SagaRequestDataType;
}) {
  try {
    const { files, ...apiRequestData } = action.data;

    const newDocuments = yield all(
      files.map(function*(file) {
        const formData = new FormData();
        formData.append('file', file);

        const requestData = {
          ...apiRequestData,
          metaInfo: file.metaInfo,
          file: formData
        };

        return yield call(ApplicationApi.uploadDocuments, requestData);
      })
    );

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.AGREEMENT) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setInitializationFormData.agreementDocuments
      );
      yield put(
        setInitializationFormData({
          agreementDocuments: [...oldDocuments, ...newDocuments]
        })
      );
    }

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setInitializationFormData.groupDocuments
      );
      yield put(
        setInitializationFormData({
          groupDocuments: [...oldDocuments, ...newDocuments]
        })
      );
    }

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.FACTORING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) => Application.setFactoringFormData.documents
      );
      yield put(
        setFactoringFormData({ documents: [...oldDocuments, ...newDocuments] })
      );
    }

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.GUARANTEE_CONTRACT) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setGuaranteesFormData.contractDocuments
      );
      yield put(
        setGuaranteesFormData({
          contractDocuments: [...oldDocuments, ...newDocuments]
        })
      );
    }

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setContractFinancingFormData.contractDocuments
      );
      yield put(
        setContractFinancingFormData({
          contractDocuments: [...oldDocuments, ...newDocuments]
        })
      );
    }

    if (action.data.type === DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING) {
      const oldDocuments = yield select(
        ({ Application }: STORE) =>
          Application.setPropertyFinancingFormData.propertyFinancingDocuments
      );
      yield put(
        setPropertyFinancingFormData({
          propertyFinancingDocuments: [...oldDocuments, ...newDocuments]
        })
      );
    }

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* uploadDocumentsSaga() {
  yield takeLatest(UPLOAD_DOCUMENTS.REQ, uploadDocuments);
}
