import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { Loader } from 'src/features/Layouts/components';
import {
  req as getUserStats,
  ResponseData
} from 'entities/Cabinet/InternalAgent/model/actions/getUserStats';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Container } from './styled';

interface StateToProps {
  quantity: ResponseData;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getUserStats: () => void;
}

type Props = StateToProps & DispatchToProps;

const MainPage: React.FC<Props> = ({ getUserStats, quantity, status }) => {
  React.useEffect(() => {
    getUserStats();
  }, []);

  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <Container>
          <div>
            <ApplicationTitle>В этом месяце:</ApplicationTitle>

            <div>
              <p>Новые пользователи:</p>
              <p>{quantity.thisNewUsers}</p>
            </div>

            <div>
              <p>Новые клиенты от агентов:</p>
              <p>{quantity.thisNewClients}</p>
            </div>

            <div>
              <div>
                <p>Поступили заявки:</p>
                <p>{quantity.thisNewAppsQty.qty} шт.</p>
              </div>

              <div>
                <p>на сумму:</p>
                <p>
                  {quantity.thisNewAppsQty.sum.toLocaleString('ru-RU')} руб.
                </p>
              </div>
            </div>

            <div>
              <div>
                <p>Поступили лиды:</p>
                <p>{quantity.thisLeadsQty.qty} шт.</p>
              </div>

              <div>
                <p>на сумму:</p>
                <p>{quantity.thisLeadsQty.sum.toLocaleString('ru-RU')} руб.</p>
              </div>
            </div>
          </div>

          <div>
            <ApplicationTitle>В прошлом месяце:</ApplicationTitle>

            <div>
              <p>Новые пользователи:</p>
              <p>{quantity.prevNewUsers}</p>
            </div>

            <div>
              <p>Новые клиенты от агентов:</p>
              <p>{quantity.prevNewClients}</p>
            </div>

            <div>
              <div>
                <p>Поступили заявки:</p>
                <p>{quantity.prevNewApps.qty} шт.</p>
              </div>

              <div>
                <p>на сумму:</p>
                <p>{quantity.prevNewApps.sum.toLocaleString('ru-RU')} руб.</p>
              </div>
            </div>

            <div>
              <div>
                <p>Поступили лиды:</p>
                <p>{quantity.prevLeadsQty.qty} шт.</p>
              </div>

              <div>
                <p>на сумму:</p>
                <p>{quantity.prevLeadsQty.sum.toLocaleString('ru-RU')} руб.</p>
              </div>
            </div>
          </div>
        </Container>
      )}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ InternalAgentRole }: STORE) => ({
  quantity: InternalAgentRole.getUserStats.data,
  status: InternalAgentRole.getUserStats.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserStats }, dispatch);

const MainPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(MainPage);

export { MainPageConnect as MainPage };
