import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getKeyRates';

export interface GetKeyRatesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_KEY_RATES {
  REQ = 'GET_KEY_RATES_REQ',
  GOT = 'GET_KEY_RATES_GOT',
  ERR = 'GET_KEY_RATES_ERR'
}

export const initialState: GetKeyRatesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: { keyRate: 0 },
  error: {}
};

const getKeyRates = (
  state: GetKeyRatesStoreState = initialState,
  action: {
    type: GET_KEY_RATES;
    data: ResponseData;
    error: {};
  }
): GetKeyRatesStoreState => {
  switch (action.type) {
    case GET_KEY_RATES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_KEY_RATES.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_KEY_RATES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getKeyRates;
