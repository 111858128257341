import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendPurchaseContract';

import { SEND_PURCHASE_CONTRACT } from '../reducers/sendPurchaseContract';
import SCFApi from '../api';
import {
  PurchaseContract,
  resetPurchaseContract
} from '../actions/setPurchaseContract';

function* sendPurchaseContract(action: {
  type: SEND_PURCHASE_CONTRACT;
  data: PurchaseContract;
}): Generator {
  try {
    yield call(SCFApi.sendPurchaseContract, action.data);
    yield put(got());
    yield put(resetPurchaseContract());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendPurchaseContractSaga(): Generator {
  yield takeLatest(SEND_PURCHASE_CONTRACT.REQ, sendPurchaseContract);
}
