import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeleteFactoringFeesOfLimitsStoreState extends Reducer {}

export interface RequestDataType {
  guid: string;
}

export enum DELETE_FACTORING_FEES_OF_LIMITS {
  REQ = 'DELETE_FACTORING_FEES_OF_LIMITS_REQ',
  GOT = 'DELETE_FACTORING_FEES_OF_LIMITS_GOT',
  ERR = 'DELETE_FACTORING_FEES_OF_LIMITS_ERR'
}

export const initialState: DeleteFactoringFeesOfLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deleteFactoringFeesOfLimits = (
  state: DeleteFactoringFeesOfLimitsStoreState = initialState,
  action: {
    type: DELETE_FACTORING_FEES_OF_LIMITS;
    data: RequestDataType;
    error: {};
  }
): DeleteFactoringFeesOfLimitsStoreState => {
  switch (action.type) {
    case DELETE_FACTORING_FEES_OF_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_FACTORING_FEES_OF_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_FACTORING_FEES_OF_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteFactoringFeesOfLimits;
