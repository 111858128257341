import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostCreateTenderStoreState extends Reducer {
  id: number;
}

export enum POST_CREATE_TENDER {
  REQ = 'POST_CREATE_TENDER_REQ',
  GOT = 'POST_CREATE_TENDER_GOT',
  ERR = 'POST_CREATE_TENDER_ERR'
}

export const initialState: PostCreateTenderStoreState = {
  status: REQUEST_STATUSES.NONE,
  id: null,
  error: {}
};

const postCreateTender = (
  state: PostCreateTenderStoreState = initialState,
  action: {
    type: string;
    id: number;
    error: {};
  }
): PostCreateTenderStoreState => {
  switch (action.type) {
    case POST_CREATE_TENDER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_CREATE_TENDER.GOT:
      return {
        ...state,
        id: action.id,
        status: REQUEST_STATUSES.GOT
      };
    case POST_CREATE_TENDER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postCreateTender;
