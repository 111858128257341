import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import {
  req as getPeriodList,
  ResponseType as PeriodListData
} from 'src/features/FinancialForCompany/actions/getFinancialStatements';
import {
  req as getFinancialStatementsAll,
  remove as removeFinancialStatementsAll,
  reset as resetFinancialStatementsAll,
  ResponseType as financialStatementsAllData,
  RequestType
} from 'src/features/FinancialForCompany/actions/getFinancialStatementsAll';
import {
  req as getNewFinancialStatementsAll,
  remove as removeNewFinancialStatementsAll,
  RequestType as NewFinancialStatementsData
} from 'src/features/FinancialForCompany/actions/getNewFinancialStatementsAll';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import {
  req as postFinancialStatementsAll,
  RequestType as postFinancialStatementsAllData,
  reset as resetPostFinancialStatementsAll
} from 'src/features/FinancialForCompany/actions/postFinancialStatementsAll';
import { ApplicationId } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  BalanceContainer,
  Cell,
  Container,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Spinner,
  ButtonWrapper,
  Input,
  SubmitButtonWrapper
} from './styled';
import {
  FINANCILASTATEMENTS_TABS,
  FinancialStatementsAllType,
  OPIY,
  PeriodListType,
  financialStatementActiveLabels,
  financialStatementPassiveLabels
} from '../types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { ErrorModal } from './ErrorModal/ErrorModal';
import {
  calculateValue,
  filtredUtil,
  getColor,
  getTaxPeriodCode,
  sortPeriod,
  transformTaxPeriodCode,
  transformYear
} from '../utils';
import { Menu } from 'src/features/Layouts/components';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  periodList: PeriodListData;
  statusPeriodList: REQUEST_STATUSES;
  errorPeriodList: ResponseError;
  financialStatementsAll: financialStatementsAllData;
  statusFinancialStatementsAll: REQUEST_STATUSES;
  newFinancialStatementsAll: FinancialStatementsAllType[];
  statusNewFinancialStatementsAll: REQUEST_STATUSES;
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusPostFinancialStatementsAll: REQUEST_STATUSES;
  errorPostFinancialStatementsAll: ResponseError;
}

export type FiltredData = {
  year: number;
  taxPeriodCode: number;
  active: {};
  passive: {};
  OPIY: {};
};

interface DispatchToProps {
  getPeriodList: (inn: string) => void;
  getFinancialStatementsAll: (data: RequestType) => void;
  removeFinancialStatementsAll: (TaxPeriod: number, TaxYear: number) => void;
  getNewFinancialStatementsAll: (data: NewFinancialStatementsData) => void;
  removeNewFinancialStatementsAll: (TaxPeriod: number, TaxYear: number) => void;
  resetFinancialStatementsAll: () => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  postFinancialStatementsAll: (data: postFinancialStatementsAllData) => void;
  openModal: (data: OpenModalDataType) => void;
  resetPostFinancialStatementsAll: (error?) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FinancialForCompany: React.FC<Props> = ({
  periodList,
  statusPeriodList,
  errorPeriodList,
  getPeriodList,
  getFinancialStatementsAll,
  removeFinancialStatementsAll,
  resetFinancialStatementsAll,
  financialStatementsAll,
  statusFinancialStatementsAll,
  getNewFinancialStatementsAll,
  removeNewFinancialStatementsAll,
  statusNewFinancialStatementsAll,
  newFinancialStatementsAll,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  postFinancialStatementsAll,
  openModal,
  errorPostFinancialStatementsAll,
  statusPostFinancialStatementsAll,
  resetPostFinancialStatementsAll,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<string>('DEFAULT');
  const [
    filtredFinancialStatementsAll,
    setFiltredFinancialStatementsAll
  ] = React.useState<FiltredData[]>([]);
  const [periods, setPeriods] = React.useState<Array<PeriodListType>[]>([]);

  const [selectedCells, setSelectedCells] = React.useState<
    Array<{
      year: number;
      taxPeriodCode: number;
      color: string;
    }>[]
  >([]);
  const [inputRowValue, setInputRowValue] = React.useState({});
  const [years, setYears] = React.useState([]);
  const [latestData, setLatestData] = React.useState<FiltredData[]>([]);
  const [errorFields, setErrorFields] = React.useState([]);

  React.useEffect(
    () => {
      if (statusExternalApiGIR_BO !== REQUEST_STATUSES.ERROR) {
        getFinancialStatementsAll({ companyinn: match.params.inn });
        getPeriodList(match.params.inn);
      }
    },
    [statusExternalApiGIR_BO]
  );

  React.useEffect(
    () => {
      if (statusPostFinancialStatementsAll === REQUEST_STATUSES.GOT) {
        getFinancialStatementsAll({ companyinn: match.params.inn });
        setInputRowValue('');
        setLatestData([]);

        for (const key in inputRowValue) {
          const [year, taxPeriodCode] = key.split('-').slice(2);
          const yearNumber = parseInt(year);
          const taxPeriodNumber = parseInt(taxPeriodCode);

          setSelectedCells(prevSelectedCells =>
            prevSelectedCells.map(row =>
              row.map(
                cellItem =>
                  cellItem.year === yearNumber &&
                  cellItem.taxPeriodCode === taxPeriodNumber
                    ? { ...cellItem, color: '#0085FE' }
                    : cellItem
              )
            )
          );
        }
      }
    },
    [statusPostFinancialStatementsAll]
  );

  React.useEffect(
    () => {
      if (statusFinancialStatementsAll === REQUEST_STATUSES.GOT) {
        const result = filtredFinancialStatementsAll.map(oldItem => {
          const newItem = financialStatementsAll.items.find(
            newItem =>
              newItem.year === oldItem.year &&
              newItem.taxPeriodCode === oldItem.taxPeriodCode
          );

          if (newItem) {
            const active = {};
            const passive = {};
            const OPIY = {};

            for (const key in newItem) {
              if (key.startsWith('row')) {
                const rowNumber = parseInt(key.substring(3), 10);
                if (
                  (rowNumber >= 1100 && rowNumber <= 1299) ||
                  rowNumber === 1600
                ) {
                  active[key] = newItem[key];
                } else if (
                  (rowNumber >= 1300 && rowNumber <= 1599) ||
                  rowNumber === 1700
                ) {
                  passive[key] = newItem[key];
                } else if (rowNumber >= 2100 && rowNumber <= 2410) {
                  OPIY[key] = newItem[key];
                }
              }
            }

            return {
              ...oldItem,
              active: { ...oldItem.active, ...active },
              passive: { ...oldItem.passive, ...passive },
              OPIY: { ...oldItem.OPIY, ...OPIY }
            };
          } else {
            return oldItem;
          }
        });

        const filteredData = filtredUtil(financialStatementsAll);

        const sortedData = sortPeriod(filteredData).filter(
          item => item.taxPeriodCode === 34
        );

        const sortedData2 = sortPeriod([...sortedData, ...result]);

        const uniqueObjects = [] as FiltredData[];
        const uniqueKeys = new Set();

        for (const obj of sortedData2) {
          const key = `${obj.year}_${obj.taxPeriodCode}`;
          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            uniqueObjects.push(obj);
          }
        }

        setFiltredFinancialStatementsAll(uniqueObjects);

        return () => resetFinancialStatementsAll();
      }
    },
    [statusFinancialStatementsAll]
  );

  React.useEffect(
    () => {
      if (statusNewFinancialStatementsAll === REQUEST_STATUSES.GOT) {
        const filteredData = filtredUtil({ items: newFinancialStatementsAll });

        const sortedData = sortPeriod([
          ...filtredFinancialStatementsAll,
          ...filteredData
        ]);

        const isUnique = (item, index, array) => {
          return (
            array.findIndex(
              obj =>
                obj.year === item.year &&
                obj.taxPeriodCode === item.taxPeriodCode
            ) === index
          );
        };

        const uniqueData = sortedData.filter(isUnique);

        setFiltredFinancialStatementsAll(uniqueData);
      }
    },
    [statusNewFinancialStatementsAll, newFinancialStatementsAll]
  );

  React.useEffect(
    () => {
      if (statusPeriodList === REQUEST_STATUSES.GOT) {
        const currentYear = new Date().getFullYear();
        const taxPeriodCodes = [34, 33, 31, 21];
        const periods: Array<PeriodListType>[] = Array.from(
          { length: 4 },
          () => []
        );

        for (let j = 0; j < 4; j++) {
          const yearOffset = currentYear - j;

          for (let i = 0; i < 4; i++) {
            const taxPeriodCode = taxPeriodCodes[i];
            const foundItem = periodList.items.find(
              item =>
                item.taxPeriodCode === taxPeriodCode && item.year === yearOffset
            ) || {
              isEdit: true,
              year: yearOffset,
              taxPeriodCode: taxPeriodCode
            };

            periods[j].push(foundItem);
          }
        }

        setPeriods(periods);

        setSelectedCells(
          periods.map(arr =>
            arr.map(item => ({
              year: item.year,
              taxPeriodCode: item.taxPeriodCode,
              color: getColor(item)
            }))
          )
        );
      }
    },
    [statusPeriodList]
  );

  React.useEffect(
    () => {
      setYears(
        filtredFinancialStatementsAll.map(item => ({
          year: item.year,
          taxPeriodCode: item.taxPeriodCode
        }))
      );
    },
    [filtredFinancialStatementsAll]
  );

  const currentYear = new Date().getFullYear();
  const year = new Date().getFullYear() - 1;

  const handleActiveTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const selectPeriod = (
    item: PeriodListType,
    offset: number,
    index: number,
    taxPeriodCode: number
  ) => {
    const currentColor = selectedCells[offset][index].color;
    const finannceYear = currentYear - offset;

    if (currentColor === '#1CCA94') {
      getNewFinancialStatementsAll({
        companyinn: match.params.inn,
        TaxPeriod: taxPeriodCode,
        TaxYear: finannceYear ? finannceYear : undefined
      });
    } else if (currentColor === '#0085FE') {
      const filteredData = filtredFinancialStatementsAll.filter(
        fin =>
          fin.year !== item.year || fin.taxPeriodCode !== item.taxPeriodCode
      );

      const sortedData = sortPeriod(filteredData);
      setFiltredFinancialStatementsAll(sortedData);

      removeNewFinancialStatementsAll(taxPeriodCode, finannceYear);
      removeFinancialStatementsAll(taxPeriodCode, finannceYear);
    }

    if (item.isEdit) {
      const updatedState = [...periods];

      updatedState.forEach(innerArray => {
        innerArray.forEach(cell => {
          if (
            cell.year === item.year &&
            cell.taxPeriodCode === item.taxPeriodCode &&
            cell.hasOwnProperty('isEdit')
          ) {
            item.isEdit = false;
          }
        });
      });

      setPeriods(updatedState);

      const data2 = {
        year: transformYear(offset),
        taxPeriodCode: transformTaxPeriodCode(`${offset}${index}`),
        active: {},
        passive: {},
        OPIY: {}
      };

      const hasDuplicate = (dataArray, targetObject): boolean => {
        return dataArray.some(
          item =>
            item.year === targetObject.year &&
            item.taxPeriodCode === targetObject.taxPeriodCode
        );
      };

      const isDuplicate = hasDuplicate(filtredFinancialStatementsAll, data2);

      if (!isDuplicate) {
        const sortedData = sortPeriod([
          ...filtredFinancialStatementsAll,
          data2
        ]);
        setLatestData(prevLatestData => [...prevLatestData, data2]);
        setFiltredFinancialStatementsAll(sortedData);
      }
      setActiveTab('EMPTY_FIELDS');
    }

    setSelectedCells(prevCellColors => {
      const updatedColors = [...prevCellColors];
      const currentColor = updatedColors[offset][index].color;

      if (currentColor === '#9FA6B2') {
        updatedColors[offset][index].color = '#f0cd74';
      } else if (currentColor === '#1CCA94') {
        updatedColors[offset][index].color = '#0085FE';
      } else if (currentColor === '#0085FE') {
        updatedColors[offset][index].color = '#1CCA94';
      }

      return updatedColors;
    });
  };

  const activeRows = {};
  const passiveRows = {};
  const OPIYRows = {};

  filtredFinancialStatementsAll.forEach(item => {
    if (item.active) {
      Object.keys(item.active).forEach(rowKey => {
        activeRows[`row${1100}`] = true;
        activeRows[`row${1200}`] = true;
        activeRows[rowKey] = true;
      });
    }

    if (item.passive) {
      Object.keys(item.passive).forEach(rowKey => {
        passiveRows[`row${1300}`] = true;
        passiveRows[`row${1400}`] = true;
        passiveRows[`row${1500}`] = true;
        passiveRows[rowKey] = true;
      });
    }

    if (item.OPIY) {
      Object.keys(item.OPIY).forEach(rowKey => {
        OPIYRows[rowKey] = true;
      });
    }
  });

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(match.params.inn, year));
    });
  };

  const emptyFieldActive = financialStatementActiveLabels.filter(
    item => activeRows[`row${item.code}`]
  );

  const emptyFieldPassive = financialStatementPassiveLabels.filter(
    item => passiveRows[`row${item.code}`]
  );

  const emptyFieldOPIY = OPIY.filter(item => OPIYRows[`row${item.code}`]);

  const activeMatchingCode = emptyFieldActive.find(item1 =>
    financialStatementActiveLabels.some(item2 => item1.code === item2.code)
  );

  const passiveMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(item2 => item1.code === item2.code)
  );

  const currentAssetsMatchingCode = emptyFieldActive.find(item1 =>
    financialStatementActiveLabels.some(
      item2 =>
        item1.code >= 1200 && item1.code <= 1260 && item1.code === item2.code
    )
  );

  const longTermObligationsMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(
      item2 =>
        item1.code >= 1400 && item1.code <= 1450 && item1.code === item2.code
    )
  );

  const shortTermLiabilitiesMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(
      item2 =>
        item1.code >= 1500 && item1.code <= 1550 && item1.code === item2.code
    )
  );

  const handleInputChange = event => {
    const { name, value } = event.currentTarget;

    if (/^[0-9]*$/.test(value)) {
      setInputRowValue({ ...inputRowValue, [name]: +value });
    }
  };

  const handlePostFinancialStatementsAll = () => {
    const groupedData = {};

    for (const key in inputRowValue) {
      if (inputRowValue.hasOwnProperty(key)) {
        const [row, year, taxPeriod] = key.split('-').slice(1);
        const value = inputRowValue[key];

        const groupKey = `${year}-${taxPeriod}`;

        if (!groupedData[groupKey]) {
          groupedData[groupKey] = {
            year: parseInt(year),
            taxPeriodCode: parseInt(taxPeriod),
            rows: {}
          };
        }

        groupedData[groupKey].rows[`row${row}`] = value;
      }
    }

    for (const key in groupedData) {
      const item = groupedData[key];
      if (!item.rows) {
        item.rows = {};
      }

      const calculatedRows = calculateValue(item.rows);

      for (const calculatedKey in calculatedRows) {
        if (!item.rows[calculatedKey]) {
          item.rows[calculatedKey] = calculatedRows[calculatedKey];
        }
      }
    }

    for (const groupKey in groupedData) {
      if (groupedData.hasOwnProperty(groupKey)) {
        const group = groupedData[groupKey];

        postFinancialStatementsAll({
          companyInn: match.params.inn,
          year: group.year,
          taxperiodcode: group.taxPeriodCode,
          ...group.rows
        });
      }
    }
  };

  const addNumbers = (start, end, step): number[] => {
    const numbers = [];
    for (let i = start; i <= end; i += step) {
      numbers.push(i);
    }
    return numbers;
  };

  React.useEffect(
    () => {
      if (statusPostFinancialStatementsAll === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_FINANCIAL_COMPANY_MODAL });
        resetPostFinancialStatementsAll();

        const updatedArray: number[] = [];

        for (var i = 0; i < errorPostFinancialStatementsAll.body.length; i++) {
          if (errorPostFinancialStatementsAll.body[i] === false) {
            if (i === 0) {
              updatedArray.push(...addNumbers(1100, 1190, 10));
            } else if (i === 1) {
              updatedArray.push(...addNumbers(1200, 1260, 10));
            } else if (i === 2) {
              updatedArray.push(...addNumbers(1300, 1370, 10));
            } else if (i === 3) {
              updatedArray.push(...addNumbers(1400, 1450, 10));
            } else if (i === 4) {
              updatedArray.push(...addNumbers(1500, 1550, 10));
            } else if (i === 5) {
              updatedArray.push(1100, 1200, 1600);
            } else if (i === 6) {
              updatedArray.push(1600, 1700);
            }
          }
        }

        setErrorFields(updatedArray);
      }
    },
    [handlePostFinancialStatementsAll]
  );

  return (
    <ApplicationViewStyled>
      <CompanySectionTitle>Финансы</CompanySectionTitle>

      <ButtonWrapper>
        <Button
          label="Загрузить отчетность из налоговой"
          disabled={statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST}
          onClick={() => sendRequestsGIR_BO(year)}
          w="fit-content"
        />
        {statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST && (
          <Spinner icon={faSpinner} />
        )}
      </ButtonWrapper>

      <ApplicationId>Доступные периоды:</ApplicationId>
      <Container>
        {periods.map((arr, offset) => (
          <div key={offset}>
            <p>{currentYear - offset}</p>
            {arr
              .map((item, index) => {
                const cellColor =
                  !!selectedCells.length && selectedCells[offset][index].color;

                return (
                  <Cell
                    key={index}
                    bgColor={cellColor}
                    onClick={() =>
                      selectPeriod(
                        item,
                        offset,
                        index,
                        item.year === currentYear - offset && item.taxPeriodCode
                      )
                    }
                  >
                    {4 - index} кв
                  </Cell>
                );
              })
              .reverse()}
          </div>
        ))}
      </Container>

      <CompanySectionTitle>Баланс</CompanySectionTitle>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={FINANCILASTATEMENTS_TABS}
        isSubMenu
      />

      {activeTab === 'EMPTY_FIELDS' ? (
        <BalanceContainer>
          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {financialStatementActiveLabels.map(({ name, code }) => (
                <TableRow key={code}>
                  <TableCell is1600={code === 1600}>
                    {code === 1110 && (
                      <div>
                        <h3>АКТИВ</h3>
                        <p>I. ВНЕОБОРОТНЫЕ АКТИВЫ</p>
                      </div>
                    )}
                    {code === 1210 && (
                      <div>
                        <p>II. ОБОРОТНЫЕ АКТИВЫ</p>
                      </div>
                    )}
                    {name}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => {
                    const isNewData = latestData.some(
                      dataItem =>
                        dataItem.year === item.year &&
                        dataItem.taxPeriodCode === item.taxPeriodCode
                    );
                    const inputKey = `${code}-${item.year}-${
                      item.taxPeriodCode
                    }`;

                    let sum1 = 0;
                    let sum2 = 0;

                    for (const key in inputRowValue) {
                      if (inputRowValue.hasOwnProperty(key)) {
                        const match = key.match(/^input-(\d+)-/);
                        const value = key.split('-');
                        const taxPeriodCode = +value[value.length - 1];
                        const year = +value[value.length - 2];
                        if (
                          match &&
                          year === item.year &&
                          taxPeriodCode === item.taxPeriodCode
                        ) {
                          const number = parseInt(match[1]);
                          if (number >= 1110 && number <= 1190) {
                            sum1 += inputRowValue[key];
                          }
                          if (number >= 1210 && number <= 1260) {
                            sum2 += inputRowValue[key];
                          }
                        }
                      }
                    }

                    let total = sum1 + sum2;

                    return (
                      <TableCell
                        key={inputKey}
                        is1100={code === 1100}
                        is1200={code === 1200}
                        is1600={code === 1600}
                      >
                        {!isNewData ? (
                          item.active[`row${code}`] ? (
                            formSumStringThousands(item.active[`row${code}`])
                          ) : null
                        ) : (
                          <Input
                            error={errorFields.includes(code)}
                            name={`input-${inputKey}`}
                            value={
                              inputRowValue[`input-${inputKey}`] === 0
                                ? ''
                                : inputRowValue[`input-${inputKey}`]
                                  ? inputRowValue[`input-${inputKey}`]
                                  : code === 1100
                                    ? sum1 === 0
                                      ? ''
                                      : sum1
                                    : code === 1200
                                      ? sum2 === 0
                                        ? ''
                                        : sum2
                                      : code === 1600
                                        ? total === 0
                                          ? ''
                                          : total
                                        : ''
                            }
                            onChange={handleInputChange}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {financialStatementPassiveLabels.map(({ name, code }) => (
                <TableRow key={code}>
                  <TableCell is1700={code === 1700}>
                    {code === 1310 && (
                      <div>
                        <h3>ПАССИВ</h3>
                        <p>III. КАПИТАЛ И РЕЗЕРВЫ</p>
                      </div>
                    )}
                    {code === 1410 && (
                      <div>
                        <p>IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                      </div>
                    )}
                    {code === 1510 && (
                      <div>
                        <p>V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                      </div>
                    )}
                    {name}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => {
                    const isNewData = latestData.some(
                      dataItem =>
                        dataItem.year === item.year &&
                        dataItem.taxPeriodCode === item.taxPeriodCode
                    );
                    const inputKey = `${code}-${item.year}-${
                      item.taxPeriodCode
                    }`;

                    let sum1 = 0;
                    let sum2 = 0;
                    let sum3 = 0;

                    for (const key in inputRowValue) {
                      if (inputRowValue.hasOwnProperty(key)) {
                        const match = key.match(/^input-(\d+)-/);
                        const value = key.split('-');
                        const taxPeriodCode = +value[value.length - 1];
                        const year = +value[value.length - 2];
                        if (
                          match &&
                          year === item.year &&
                          taxPeriodCode === item.taxPeriodCode
                        ) {
                          const number = parseInt(match[1]);
                          if (number >= 1310 && number <= 1370) {
                            sum1 += inputRowValue[key];
                          }
                          if (number >= 1410 && number <= 1450) {
                            sum2 += inputRowValue[key];
                          }
                          if (number >= 1510 && number <= 1550) {
                            sum3 += inputRowValue[key];
                          }
                        }
                      }
                    }

                    let total = sum1 + sum2 + sum3;

                    return (
                      <TableCell
                        key={inputKey}
                        is1300={code === 1300}
                        is1400={code === 1400}
                        is1500={code === 1500}
                        is1700={code === 1700}
                      >
                        {!isNewData ? (
                          item.passive[`row${code}`] ? (
                            formSumStringThousands(item.passive[`row${code}`])
                          ) : null
                        ) : (
                          <Input
                            error={errorFields.includes(code)}
                            name={`input-${inputKey}`}
                            value={
                              inputRowValue[`input-${inputKey}`] === 0
                                ? ''
                                : inputRowValue[`input-${inputKey}`]
                                  ? inputRowValue[`input-${inputKey}`]
                                  : code === 1300
                                    ? sum1 === 0
                                      ? ''
                                      : sum1
                                    : code === 1400
                                      ? sum2 === 0
                                        ? ''
                                        : sum2
                                      : code === 1500
                                        ? sum3 === 0
                                          ? ''
                                          : sum3
                                        : code === 1700
                                          ? total === 0
                                            ? ''
                                            : total
                                          : ''
                            }
                            onChange={handleInputChange}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <section>
            <CompanySectionTitle>ОПиУ</CompanySectionTitle>
            <TableContainer>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Наименование показателя</TableHeaderCell>
                  <TableHeaderCell>Код</TableHeaderCell>
                  {!!years &&
                    years.map((item, index) => (
                      <TableHeaderCell key={index}>
                        {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                      </TableHeaderCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {OPIY.map(({ name, code }) => (
                  <TableRow key={code}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    {filtredFinancialStatementsAll.map(item => {
                      const isNewData = latestData.some(
                        dataItem =>
                          dataItem.year === item.year &&
                          dataItem.taxPeriodCode === item.taxPeriodCode
                      );
                      const inputKey = `${code}-${item.year}-${
                        item.taxPeriodCode
                      }`;

                      return (
                        <TableCell key={inputKey}>
                          {!isNewData ? (
                            item.OPIY[`row${code}`] ? (
                              formSumStringThousands(item.OPIY[`row${code}`])
                            ) : null
                          ) : (
                            <Input
                              name={`input-${inputKey}`}
                              value={inputRowValue[`input-${inputKey}`] || ''}
                              onChange={handleInputChange}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
            {!Object.values(inputRowValue).every(value => value === 0) && (
              <SubmitButtonWrapper>
                <Button
                  label="Сохранить"
                  onClick={handlePostFinancialStatementsAll}
                  w="fit-content"
                />
              </SubmitButtonWrapper>
            )}
          </section>

          <ErrorModal
            code={errorPostFinancialStatementsAll.code}
            message={errorPostFinancialStatementsAll.message}
          />
        </BalanceContainer>
      ) : (
        <BalanceContainer>
          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {emptyFieldActive.map(({ name, code }) => (
                <TableRow key={code}>
                  <TableCell is1600={code === 1600}>
                    {code === activeMatchingCode.code && (
                      <div>
                        <h3>АКТИВ</h3>
                        <p>I. ВНЕОБОРОТНЫЕ АКТИВЫ</p>
                      </div>
                    )}
                    {code === currentAssetsMatchingCode.code && (
                      <div>
                        <p>II. ОБОРОТНЫЕ АКТИВЫ</p>
                      </div>
                    )}
                    {name}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => (
                    <TableCell
                      key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      is1100={code === 1100}
                      is1200={code === 1200}
                      is1600={code === 1600}
                    >
                      {item.active[`row${code}`]
                        ? formSumStringThousands(item.active[`row${code}`])
                        : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {emptyFieldPassive.map(({ name, code }) => (
                <TableRow key={code}>
                  <TableCell is1700={code === 1700}>
                    {code === passiveMatchingCode.code && (
                      <div>
                        <h3>ПАССИВ</h3>
                        <p>III. КАПИТАЛ И РЕЗЕРВЫ</p>
                      </div>
                    )}
                    {code === longTermObligationsMatchingCode.code && (
                      <div>
                        <p>IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                      </div>
                    )}
                    {code === shortTermLiabilitiesMatchingCode.code && (
                      <div>
                        <p>V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                      </div>
                    )}
                    {name}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => (
                    <TableCell
                      key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      is1300={code === 1300}
                      is1400={code === 1400}
                      is1500={code === 1500}
                      is1700={code === 1700}
                    >
                      {item.passive[`row${code}`]
                        ? formSumStringThousands(item.passive[`row${code}`])
                        : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <section>
            <CompanySectionTitle>ОПиУ</CompanySectionTitle>
            <TableContainer>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Наименование показателя</TableHeaderCell>
                  <TableHeaderCell>Код</TableHeaderCell>
                  {!!years &&
                    years.map((item, index) => (
                      <TableHeaderCell key={index}>
                        {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                      </TableHeaderCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {emptyFieldOPIY.map(({ name, code }) => (
                  <TableRow key={code}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    {filtredFinancialStatementsAll.map(item => (
                      <TableCell
                        key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      >
                        {item.OPIY[`row${code}`]
                          ? formSumStringThousands(item.OPIY[`row${code}`])
                          : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </section>
        </BalanceContainer>
      )}
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ FinancialStatements, Application }: CRM) => ({
  periodList: FinancialStatements.getFinancialStatements.data,
  statusPeriodList: FinancialStatements.getFinancialStatements.status,
  errorPeriodList: FinancialStatements.getFinancialStatements.error,
  financialStatementsAll: FinancialStatements.getFinancialStatementsAll.data,
  newFinancialStatementsAll:
    FinancialStatements.getNewFinancialStatementsAll.data,
  statusNewFinancialStatementsAll:
    FinancialStatements.getNewFinancialStatementsAll.status,
  statusFinancialStatementsAll:
    FinancialStatements.getFinancialStatementsAll.status,
  statusPostFinancialStatementsAll:
    FinancialStatements.postFinancialStatementsAll.status,
  errorPostFinancialStatementsAll:
    FinancialStatements.postFinancialStatementsAll.error,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPeriodList,
      getFinancialStatementsAll,
      removeFinancialStatementsAll,
      getNewFinancialStatementsAll,
      removeNewFinancialStatementsAll,
      resetFinancialStatementsAll,
      getExternalApiGIR_BO,
      postFinancialStatementsAll,
      openModal,
      resetPostFinancialStatementsAll
    },
    dispatch
  );

const FinancialForCompanyConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FinancialForCompany)
);

export { FinancialForCompanyConnect as FinancialForCompany };
