import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getCreditProduct';

import { GET_CREDIT_PRODUCT } from '../reducers/getCreditProduct';
import BankProductApi from '../api';

function* getCreditProduct(action: {
  type: GET_CREDIT_PRODUCT;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(BankProductApi.getCreditProduct, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCreditProductSaga(): Generator {
  yield takeLatest(GET_CREDIT_PRODUCT.REQ, getCreditProduct);
}
