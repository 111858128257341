import singInSaga from './signIn';
import singInByLinkSaga from './signInByLink';
import getUserDataSaga from './getUserData';
import confirmAccountSaga from './confirmAccount';
import reConfirmAccountSaga from './reConfirmAccount';
import sendResetPasswordLetterSaga from './sendResetPasswordLetter';
import resetPasswordSaga from './resetPassword';

export default [
  singInSaga,
  singInByLinkSaga,
  getUserDataSaga,
  confirmAccountSaga,
  reConfirmAccountSaga,
  sendResetPasswordLetterSaga,
  resetPasswordSaga
];
