import { GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS } from 'Business/reducers/getCompanyByInnConfirmedFounders';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export const req = (data: string[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.REQ,
  data
});

export const got = (data: ResponseDataType[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.ERR,
  error
});
