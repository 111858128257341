import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  updateDateFromFilter,
  updateDateUntilFilter
} from 'src/features/Filters/DateFilter/actions/updateDateFilter';
import { InputDate } from 'shared/ui/InputDate';
import { DateFilterContainerStyled } from 'src/features/Filters/DateFilter/components/styled';
import { format, parse } from 'date-fns';

interface StateToProps {
  dateFrom: string;
  dateUntil: string;
}

interface AnyProps {
  label?: string;
  from?: string;
  until?: string;
}

interface DispatchToProps {
  updateDateFromFilter: (date: string) => void;
  updateDateUntilFilter: (date: string) => void;
}

type Props = StateToProps & DispatchToProps & AnyProps;

class DateFilter extends React.Component<Props> {
  onChangeDateFrom = (date: string) => {
    this.props.updateDateFromFilter(date);
  };

  onChangeDateUntil = (date: string) => {
    this.props.updateDateUntilFilter(date);
  };

  render() {
    return (
      <DateFilterContainerStyled>
        <p>{this.props.from}</p>
        <InputDate
          label={this.props.label}
          onDateChange={this.onChangeDateFrom}
          dateValue={this.props.dateFrom}
        />
        <p>{this.props.until}</p>
        <InputDate
          label=""
          onDateChange={this.onChangeDateUntil}
          dateValue={this.props.dateUntil}
        />
      </DateFilterContainerStyled>
    );
  }
}

const mapStateToProps = ({ Filters }: STORE) => ({
  dateFrom: Filters.updateDateFilter.dateFrom,
  dateUntil: Filters.updateDateFilter.dateUntil
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ updateDateFromFilter, updateDateUntilFilter }, dispatch);

const DateFilterConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(DateFilter);

export { DateFilterConnect as DateFilter };
