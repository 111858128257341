import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { req as getAgent } from 'src/features/Questionnaire/actions/getAgentByInn';
import { req as patchAgent } from 'src/features/Questionnaire/actions/patchAgent';
import { Agent } from 'src/features/Questionnaire/types';
import {
  AskStyled,
  ButtonWrapper,
  SectionContainer,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/Questionnaire/components/styled';

interface StateToProps {
  agent: Agent;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {
  isBroker: boolean;
  isConsultant: boolean;
  isBank: boolean;
  isAppraiser: boolean;
  isEngineeringExpert: boolean;
  isInsurer: boolean;
  isAssociation: boolean;
  hasContacts: boolean;
  hasContract: boolean;
  hasUsers: boolean;
}

interface DispatchToProps {
  getAgent: (inn: string) => void;
  patchAgent: (inn: string, data: any) => void;
}

interface AnyProps {
  companyInn: string;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  AnyProps;

class Questionnaire extends React.Component<Props, State> {
  state: State = {
    isBroker: null,
    isConsultant: null,
    isBank: null,
    isAppraiser: null,
    isEngineeringExpert: null,
    isInsurer: null,
    isAssociation: null,
    hasContacts: null,
    hasContract: null,
    hasUsers: null
  };

  componentDidMount() {
    const { getAgent, companyInn } = this.props;

    getAgent(companyInn);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.agent !== this.props.agent) {
      this.setState({
        isBroker: this.props.agent.isBroker,
        isConsultant: this.props.agent.isConsultant,
        isBank: this.props.agent.isBank,
        isAppraiser: this.props.agent.isAppraiser,
        isEngineeringExpert: this.props.agent.isEngineeringExpert,
        isInsurer: this.props.agent.isInsurer,
        isAssociation: this.props.agent.isAssociation,
        hasContacts: this.props.agent.hasContacts,
        hasContract: this.props.agent.hasContract,
        hasUsers: this.props.agent.hasUsers
      });
    }
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    this.setState({ [key]: value } as Pick<any, keyof State>);

    this.props.patchAgent(this.props.companyInn, { [key]: value });
  };

  renderToggleButton = (buttonName: keyof State, description?: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() =>
            this.state[buttonName] === null &&
            this.handleButtonClick(buttonName, false)
          }
          active={this.state[buttonName]}
          disabled={this.state[buttonName] === true}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() =>
            this.state[buttonName] === null &&
            this.handleButtonClick(buttonName, true)
          }
          disabled={this.state[buttonName] === false}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  render() {
    const { status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <SectionContainer>
            <AskStyled>Тип агента:</AskStyled>
            <ButtonWrapper>
              {this.renderToggleButton('isBroker', 'Брокер')}
              {this.renderToggleButton('isConsultant', 'Проф. консультант')}
              {this.renderToggleButton('isAppraiser', 'Оценщик')}
              {this.renderToggleButton('isBank', 'Банк')}
              {this.renderToggleButton(
                'isEngineeringExpert',
                'Инжиниринговая компания'
              )}
              {this.renderToggleButton('isInsurer', 'Страховая компания')}
              {this.renderToggleButton('isAssociation', 'Ассоциация')}
            </ButtonWrapper>

            <AskStyled>Сотрудничество:</AskStyled>
            <ButtonWrapper>
              {this.renderToggleButton('hasContacts', 'Есть контакты')}
              {this.renderToggleButton('hasContract', 'Есть договор')}
              {this.renderToggleButton('hasUsers', 'Есть пользователи')}
            </ButtonWrapper>
          </SectionContainer>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Questionnaire }: CRM) => ({
  agent: Questionnaire.getAgent.data,
  status: Questionnaire.getAgent.status,
  error: Questionnaire.getAgent.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getAgent, patchAgent }, dispatch);

const QuestionnaireConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Questionnaire)
);

export { QuestionnaireConnect as Questionnaire };
