import { POST_CREATE_TENDER } from '../reducers/postCreateTender';
import { GuaranteesApplicationWrite } from '../types';

export interface RequestData extends Partial<GuaranteesApplicationWrite> {
  amount?: number;
  clientCompanyName?: string;
  customerInn?: string;
  customerName?: string;
  supplierinn?: string;
  applicationId?: number;
  PublicationDate?: string;
  EruzId?: string;
  StartPrice?: number;
  TenderType?: number;
  LotCode?: string;
  TenderSegment?: number;
  ProtocolDate?: string;
  HasWinner?: boolean;
  ContractConcluded?: boolean;
  ContractGuid?: string;
  TenderCompleted?: boolean;
  TenderFailed?: boolean;
  sumFinal?: number;
  term?: number;
  termStartDate?: string;
  termEndDate?: string;
}

export const req = (data: RequestData) => ({
  type: POST_CREATE_TENDER.REQ,
  data
});

export const got = (id: number) => ({
  type: POST_CREATE_TENDER.GOT,
  id
});

export const err = (error: {}) => ({
  type: POST_CREATE_TENDER.ERR,
  error
});
