import styled from 'styled-components';
import { black, blue, gray, lightGray, rl, rr } from 'shared/styled';

export const CertificatesStyled = styled.section`
  width: auto;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderStyled = styled.div`
  width: auto;
  height: auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-size: 28px;
    font-family: ${rr};
  }
  p {
    font-size: 14px;
    font-family: ${rl};
    color: ${gray};
  }
`;

export const CetrificatesEditInfoContentBlock = styled.div`
  width: auto;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AdditionalContainer = styled.div`
  width: auto;
  height: auto;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
`;

export const AdditionalBlock = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AdditionalRow = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const TextBig = styled.p`
  font-size: 18px;
  font-family: ${rl};
  color: ${black};
`;

export const Text = styled.p`
  font-size: 14px;
  font-family: ${rl};
  color: ${black};
  padding-left: 30px;
`;

export const TextLittle = styled.p`
  font-size: 10px;
  font-family: ${rl};
  color: ${black};
`;

export const TextContainer = styled.div`
  width: auto;
  display: block;
  width: 300px;
`;

export const TextHintContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 300px;

  svg {
    cursor: pointer;
    font-size: 18px;
    color: ${blue};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  &:hover:before {
    content: '${({ text }) => text}';
    position: absolute;
    width: max-content;
    font-family: ${rr};
    bottom: 15px;
    left: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }`;

export const InputDisabled = styled.input`
  background-color: #eff1f9 !important;
  height: 38px;
  border: 1px solid #7a7d83;
  border-radius: 5px;
  font-size: 14px;
  font-family: ${rl};
  color: ${black};
  padding-left: 10px;
  &::placeholder {
    font-style: italic;
  }
`;

export const InputStyled = styled.input`
  background-color: #f7f8fc !important;
  height: 38px;
  border: 1px solid #7a7d83;
  border-radius: 5px;
  font-size: 14px;
  font-family: ${rl};
  color: ${black};
  padding-left: 10px;
  &::placeholder {
    font-style: italic;
  }
  &:focus {
    border: 1px solid transparent;
    outline: 2px solid rgba(127, 194, 254, 1) !important;
  }
`;

export const BtnsBlock = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;

export const AuthorityButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding-top: 25px;

  p {
    cursor: pointer;
    margin: 0;
    text-decoration: underline;
    color: ${blue};
  }
`;

export const PowerAttorney = styled.div`
  margin-top: 25px;

  button {
    width: fit-content;
    height: 40px;
  }
`;

export const Status = styled.div`
  span {
    font-size: 12px;
    color: ${gray};
  }
`;

export const PowerAttorneyModal = styled.div`
  width: 100%;
  font-family: ${rr};

  & > div:first-of-type {
    margin-top: 20px;
  }

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }

  & > button {
    width: fit-content;
    height: 40px;
  }
`;

export const ErrorAddPoa = styled.div`
  p {
    color: red;
  }
`;

export const FileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const Label = styled.label`
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  background: #f9f9f9;
  &:hover {
    background: #e0e0e0;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RemoveButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${gray};
  font-size: 16px;
`;
