export const FinancingCreditType = {
  PROJECT_FINANCE: 'Проектное финансирование',
  FIXED_ASSETS: 'Инвестиции в основные средства',
  CONSTRUCTION_FINANCING: 'Финансирование строительства жилой недвижимости',
  REAL_ESTATE_FINANCING: 'Финансирование коммерческой недвижимости'
};

export const FinancingPaymentConfirmation = {
  BILL_OF_EXCHANGE: 'Векселя',
  COMPANY_GUARANTEES: 'Поручительства платежеспособных компаний'
};
