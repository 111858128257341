import styled from 'styled-components';
import { rr } from 'shared/styled';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ImageStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const MatchingFilters = styled.p`
  margin: 20px 0 20px 20px;

  span {
    font-weight: 800;
    color: black;
  }
`;

export const InnInputContainer = styled(INNFilterContainerStyled)``;

export const TableRowCustomStyled = styled(TableRowStyled)`
  position: relative;
  height: 70px;
`;

export const Image = styled(ImageStyled)`
  z-index: 1;
  width: 50px;
  bottom: 10%;
`;

export const TDInnStyled = styled.td`
  position: relative;
  overflow: visible !important;

  div:last-of-type {
    position: absolute;
    bottom: 0;
    left: 5px;
    font-family: ${rr};
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;
