import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchSetAvailableRegionAll';

import { PATCH_SET_AVAILABLE_REGION_ALL } from '../reducers/patchSetAvailableRegionAll';
import CustomerRequirementsApi from '../api';

function* patchSetAvailableRegionAll(action: {
  type: PATCH_SET_AVAILABLE_REGION_ALL;
  id: string;
}): Generator {
  try {
    yield call(CustomerRequirementsApi.patchSetAvailableRegionAll, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchSetAvailableRegionAllSaga(): Generator {
  yield takeLatest(
    PATCH_SET_AVAILABLE_REGION_ALL.REQ,
    patchSetAvailableRegionAll
  );
}
