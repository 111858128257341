import * as React from 'react';

import {
  EachForm,
  FinancingFormStep1,
  FinancingFormStep2,
  InitializationFormStep1
} from 'Application/components';

export class FinancingForm extends React.Component {
  render() {
    const routes = [
      {
        path: '',
        component: InitializationFormStep1
      },
      {
        path: 'options',
        component: FinancingFormStep1
      },
      {
        path: 'documents',
        component: FinancingFormStep2
      }
    ];

    return <EachForm routes={routes} />;
  }
}
