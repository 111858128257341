import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendDiscountingSettings';

import { SEND_DISCOUNTING_SETTINGS } from '../reducers/sendDiscountingSettings';
import SCFApi from '../api';
import {
  DiscountingSettings,
  resetDiscountingSettings
} from '../actions/setDiscountingSettings';

function* sendDiscountingSettings(action: {
  type: SEND_DISCOUNTING_SETTINGS;
  data: DiscountingSettings;
}): Generator {
  try {
    const res: any = yield call(SCFApi.sendDiscountingSettings, action.data);
    yield put(got(res));
    yield put(resetDiscountingSettings());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendDiscountingSettingsSaga(): Generator {
  yield takeLatest(SEND_DISCOUNTING_SETTINGS.REQ, sendDiscountingSettings);
}
