import styled from 'styled-components';
import { gray, lightGray, lighterBlue, lighterGray, rr } from 'shared/styled';

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;
`;

export const BackBtn = styled.div`
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  background-color: ${lighterBlue};
`;

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;

export const PayDate = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: end;

  & > div:first-of-type {
    text-decoration: line-through;
    font-size: 12px;
  }
`;

export const MonetaryClaims = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: end;

  & > div:first-of-type {
    width: 110px;
    text-align: end;
    text-decoration: line-through;
    font-size: 12px;
  }
`;

export const PlatformFee = styled.div`
  width: 100px;
  text-align: end;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  cursor: pointer;

  &:hover{
    svg {
      color: #0085FE;
    }
  }

  svg {
    color: #9FA6B2;
  }

  &:hover:before {
    content: '${({ text }) => text}';
    width: max-content;
    position: absolute;
    bottom: 4px;
    left: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TDStyled = styled.td`
  overflow: visible !important;
  white-space: normal !important;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 5px;

  p {
    margin: 0;
  }
`;

export const Status = styled.div`
  position: relative;
  cursor: ${({ status }) =>
    status === 16 || status === 14 ? 'default' : 'pointer'};
  margin: 5px 0;
  padding: 10px;
  border-radius: 6px;
  text-align: ${({ status }) =>
    status === 16 || status === 14 ? 'center' : 'left'};
  background-color: ${({ status }) =>
    status === 16 ? '#5fc798' : status === 14 ? 'red' : lighterBlue};

  & > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    color: ${({ status }) => (status === 16 || status === 14) && 'white'};
    line-height: 1.2;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 300px;
  top: 0;
  right: calc(100% + 5px);
  background-color: ${lighterBlue};
  border-radius: 6px;
  padding: 5px;
  z-index: 10;
`;

export const StatusList = styled.ul`
  padding: 15px 20px;
  margin: 0;
  list-style: none;

  & > li {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const DocumentHint = styled.div`
  width: 300px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  top: 0;
  left: 100%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }

    & > div {
      & > p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ConfirmSection = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const DocumentSection = styled.div`
  margin-top: 30px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  p {
    padding: 0 10px;
    align-self: center;
  }
`;

export const DownloadLink = styled.a.attrs({
  ['aria-label']: 'download-document',
  download: true
})`
  display: flex;
  width: fit-content;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const NoDocument = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  margin-bottom: 10px;
  background-color: ${lightGray};
  border-radius: 5px;
  font-family: ${rr};
  color: ${gray};
  padding: 0 10px;
`;

export const ConfirmSectionRightPart = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DiscountItem = styled.div`
  width: 420px;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  & > div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 300px;
    font-family: ${rr};

    h2 {
      font-family: ${rr};
    }
  }

  & > div:last-of-type {
    margin: 25px 0;
    display: flex;
    align-items: center;
    column-gap: 20px;
    font-family: ${rr};

    & > div {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      & > div:first-of-type {
        color: ${gray};
      }
    }
  }

  ${Status} {
    width: 240px;
  }
`;
