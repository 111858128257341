import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCompaniesList';

import { GET_COMPANIES_LIST } from '../reducers/getCompaniesList';
import CompaniesApi from '../api';
import { COMPANY_LIST_TYPE } from '../types';

function* getCompaniesList(action: {
  type: GET_COMPANIES_LIST;
  data: RequestData;
  companyListType: COMPANY_LIST_TYPE;
}): Generator {
  try {
    let res;
    switch (action.companyListType) {
      case COMPANY_LIST_TYPE.ALL:
        res = yield call(CompaniesApi.getAllCompanies, action.data);
        break;
      case COMPANY_LIST_TYPE.TARGETS:
        res = yield call(CompaniesApi.getTargetCompanies, action.data);
        break;
      case COMPANY_LIST_TYPE.BACKBONE:
        res = yield call(CompaniesApi.getBackboneCompanies, action.data);
        break;
      case COMPANY_LIST_TYPE.MY:
        res = yield call(CompaniesApi.getMyCompanies, action.data);
        break;
    }

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesListSaga(): Generator {
  yield takeLatest(GET_COMPANIES_LIST.REQ, getCompaniesList);
}
