import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  req as getExternalCredential,
  ResponseData
} from 'src/features/PlatformsForProducts/actions/getExternalCredential';
import { history } from 'src/shared/utils/History';
import { BtnAddCredential } from 'src/features/ExternalCredentials/components/ExternalCredentialList/styled';

interface StateToProps {
  externalCredential: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface State {}

interface MatchParams {}

interface DispatchToProps {
  getExternalCredential: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ExternalCredentialList extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getExternalCredential();
  }

  navigateToPlatform = (id: number) => {
    history.push(`/crm/external_credentials/${id}`);
  };

  navigateToCreateCredential = () => {
    history.push(`/crm/external_credentials/new`);
  };

  render() {
    const { externalCredential, status, error } = this.props;
    return (
      <ScrollTopComponent>
        <ApplicationTitle>Доступы к площадкам</ApplicationTitle>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">Платформа</TableThStyled>
                <TableThStyled width="10%">Ссылка</TableThStyled>
                <TableThStyled width="10%">Компания</TableThStyled>
                <TableThStyled width="15%">Логин</TableThStyled>
                <TableThStyled width="10%">Пароль</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {externalCredential.items.map(credential => (
                <TableRowStyled
                  key={credential.id}
                  onClick={() => this.navigateToPlatform(credential.id)}
                >
                  <td>{credential.platformName}</td>
                  <td>{credential.website}</td>
                  <td>{credential.ourCompany}</td>
                  <td>{credential.login}</td>
                  <td>{credential.password}</td>
                </TableRowStyled>
              ))}
            </tbody>
            <BtnAddCredential onClick={this.navigateToCreateCredential}>
              + Добавить
            </BtnAddCredential>
          </TableStyled>
        )}

        <Pagination list={externalCredential} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Platforms }: CRM) => ({
  externalCredential: Platforms.getExternalCredential.data,
  status: Platforms.getExternalCredential.status,
  error: Platforms.getExternalCredential.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getExternalCredential }, dispatch);

const ExternalCredentialListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ExternalCredentialList)
);

export { ExternalCredentialListConnect as ExternalCredentialList };
