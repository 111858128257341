import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/ExternalAgent/model/actions/getExternalLeads';

import { GET_EXTERNAL_LEADS } from 'entities/Cabinet/ExternalAgent/model/reducers/getExternalLeads';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getExternalLeads(action: {
  type: GET_EXTERNAL_LEADS;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      ExternalAgentRoleApi.getExternalLeads,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalLeadsSaga(): Generator {
  yield takeLatest(GET_EXTERNAL_LEADS.REQ, getExternalLeads);
}
