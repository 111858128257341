import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFiles';
import { GET_DEBTOR_REGISTRY_FILES } from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFiles';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDebtorRegistryFiles(action: {
  type: GET_DEBTOR_REGISTRY_FILES;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getDebtorRegistryFiles,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorRegistryFilesSaga() {
  yield takeLatest(
    GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_REQ,
    getDebtorRegistryFiles
  );
}
