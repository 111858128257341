import {
  RequestDataType,
  SEND_SUPPLIERS
} from 'src/features/SCF/reducers/sendSuppliers';

export const req = (data: RequestDataType) => ({
  type: SEND_SUPPLIERS.REQ,
  data
});

export const got = () => ({
  type: SEND_SUPPLIERS.GOT
});

export const err = (error: {}) => ({
  type: SEND_SUPPLIERS.ERR,
  error
});
