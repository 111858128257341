import { GET_EXTERNAL_AGENT_SELECT_PERIOD } from '../reducers/getExternalAgentSelectPeriod';
import { FinancialStatementsAllType } from '../types';

export interface SelectPeriodRequest {
  taxYear: number;
  TaxPeriod: number;
}

export const req = (inn: string, data: SelectPeriodRequest[]) => ({
  type: GET_EXTERNAL_AGENT_SELECT_PERIOD.REQ,
  inn,
  data
});

export const got = (data: FinancialStatementsAllType[]) => ({
  type: GET_EXTERNAL_AGENT_SELECT_PERIOD.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_AGENT_SELECT_PERIOD.ERR,
  error
});

export const reset = () => ({
  type: GET_EXTERNAL_AGENT_SELECT_PERIOD.RESET
});
