import styled from 'styled-components';
import { gray, lighterGray, lightGray, rr } from 'shared/styled';
import { LoaderBar } from 'Layouts/components/LoaderBar/LoaderBar';
import { CrossBtn } from 'shared/styled';
import { Label } from 'shared/ui/Label';

export const DropArea = styled.div`
  height: 75px;
  background-color: ${lighterGray};
  position: relative;
  width: ${({ dropAreaWidth }) => dropAreaWidth};
  display: flex;

  border: 1px solid ${lightGray};
`;

export const SizeWarning = styled.div`
  color: red;
  font-family: ${rr};
  margin-top: 10px;
`;

export const InputSingleFileContainer = styled.div`
  position: relative;
`;

export const FileUploadInfoBlock = styled.div`
  margin: 0 auto;
  align-items: center;
  display: flex;
  &::before {
    ${({ withIcon }) =>
      withIcon
        ? `
    width: 40px;
    height: 40px;

    margin-right: 15px;
    display: block;

    content: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path fill="none" stroke="${gray}" stroke-linecap="round" stroke-width="2.526" d="M23.399 13.59l-7.405 7.872c-1.325 1.41-1.325 3.694 0 5.103 1.325 1.409 3.474 1.409 4.8 0L30 16.779c2.319-2.466 2.319-6.464 0-8.93-2.32-2.465-6.08-2.465-8.399 0L11.487 18.601c-3.313 3.523-3.313 9.234 0 12.757 3.313 3.523 8.686 3.523 11.999 0l7.114-7.564"/>
    </svg>
    ');
    `
        : ``};
  }
`;
export const FileUploadInfoBlockText = styled.p`
  font-family: ${rr};
  font-size: 14px;
  line-height: 1.71;
  color: ${gray};
`;

export const SingleFileLoaderBar = styled(LoaderBar)`
  width: ${({ dropAreaWidth }) => dropAreaWidth};
`;
export const FileInfoStyled = styled.div`
  display: flex;
  width: 100%;
  p {
    padding: 0px 2px;
  }
`;

export const LargerCrossBtn = styled(CrossBtn)`
  width: 25px;
  height: 25px;
`;

// TODO remove absolute
export const BigCrossBtn = styled(CrossBtn)`
  width: 18px;
  height: 18px;
  position: absolute;
  right: 4px;
  top: 4px;
`;
export const BigLabelWithTopMargin = styled(Label)`
  font-family: ${rr};
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
`;
