import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Col } from 'react-grid-system';

import { DocumentsInfoBlock } from 'Application/components';
import { REQUEST_STATUSES, STORE } from 'globaltypes';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import {
  APPLICATION_TYPES,
  BorrowerCompany,
  ContractFinancingRead
} from 'Application/types';
import { req as deleteBorrowerCompanyDocument } from 'BorrowerCompanies/actions/deleteBorrowerCompanyDocument';
import { req as uploadBorrowerCompanyDocuments } from 'BorrowerCompanies/actions/uploadBorrowerCompanyDocuments';
import { SagaRequestDataType as DeleteBorrowerCompanyDocumentRequestDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';
import { InputFile } from 'shared/ui/InputFile';

interface OwnProps {
  isMainCompany: boolean;
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

interface StateToProps {
  externalApplication: Partial<ContractFinancingRead>;
  documentsUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  uploadBorrowerCompanyDocuments: (
    data: UploadDocumentsRequestDataType
  ) => void;
  deleteBorrowerCompanyDocument: (
    data: DeleteBorrowerCompanyDocumentRequestDataType
  ) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class BorrowerCompanyDefaultFileUploadBlock extends React.Component<Props> {
  onFilesUpload = files => {
    const { uploadBorrowerCompanyDocuments, borrowerCompany } = this.props;

    uploadBorrowerCompanyDocuments({
      id: borrowerCompany.id,
      files
    });
  };

  onFilesRemove = (documentData: DeleteDocumentRequestDataType) => {
    const { deleteBorrowerCompanyDocument, borrowerCompany } = this.props;

    deleteBorrowerCompanyDocument({
      id: documentData.id,
      companyId: borrowerCompany.id
    });
  };

  render() {
    const {
      isMainCompany,
      errors,
      borrowerCompany,
      onChange,
      documentsUploadStatus,
      externalApplication: { financingType }
    } = this.props;

    return (
      <>
        <Col lg={12} style={{ marginBottom: 15 }}>
          <Row>
            <Col lg={4}>
              <DocumentsInfoBlock
                label="Необходимые документы:"
                data={
                  isMainCompany && financingType === APPLICATION_TYPES.FINANCING
                    ? [
                        {
                          title: 'Приложите следующие документы по проекту:',
                          list: [
                            'Бизнес-план/Резюме о проекте',
                            'Финансовая модель'
                          ]
                        }
                      ]
                    : [
                        {
                          title:
                            'Приложите отчетность за последние  5 отчетных периодов (кварталов):',
                          list: [
                            'Бухгалтерский баланс форма 1',
                            'Отчет о прибылях и убытках  форма 2',
                            'Расшифровку дебиторской и кредиторской задолженности',
                            'Расшифровку прочих доходов и расходов'
                          ]
                        },
                        {
                          title: 'Приложите на текущую дату:',
                          list: [
                            'Расшифровку кредитов и займов',
                            'Расшифровку фин. вложений'
                          ]
                        }
                      ]
                }
              />
            </Col>

            <Col lg={4}>
              <InputFile
                label="Поле для загрузки документов компании"
                name="file"
                error={errors.documents}
                files={borrowerCompany.documents}
                multiple={true}
                uploadStatus={documentsUploadStatus}
                onFilesUpload={this.onFilesUpload}
                onFilesRemove={this.onFilesRemove}
              />
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = ({ ExternalAgentRole, BorrowerCompanies }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  documentsUploadStatus: BorrowerCompanies.uploadBorrowerCompanyDocuments.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadBorrowerCompanyDocuments,
      deleteBorrowerCompanyDocument
    },
    dispatch
  );

const BorrowerCompanyFileUploadBlockConnect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(BorrowerCompanyDefaultFileUploadBlock);

export {
  BorrowerCompanyFileUploadBlockConnect as BorrowerCompanyDefaultFileUploadBlock
};
