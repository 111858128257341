import styled from 'styled-components';

export const ModalStyled = styled.div`
  width: 100%;
  height: 100vh;

  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;

  overflow: scroll;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(16, 16, 16, 0.5);
`;
