import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import {
  DOCUMENT_UPLOAD_TYPE,
  DOCUMENT_DOWNLOAD_TYPE
} from 'Application/types';

export interface DeleteDocumentStoreState extends Reducer {
  data: RequestDataType;
}

export interface RequestDataType {
  id: number;
  type: DOCUMENT_DOWNLOAD_TYPE;
  subType?: DOCUMENT_UPLOAD_TYPE;
}

export interface SagaRequestDataType {
  id: number;
  type: DOCUMENT_DOWNLOAD_TYPE;
  subType: DOCUMENT_UPLOAD_TYPE;
}

export enum DELETE_DOCUMENT {
  REQ = 'DELETE_DOCUMENT_REQ',
  GOT = 'DELETE_DOCUMENT_GOT',
  ERR = 'DELETE_DOCUMENT_ERR'
}

export const initialState: DeleteDocumentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: null,
    type: DOCUMENT_DOWNLOAD_TYPE.NONE
  },
  error: {}
};

const deleteDocument = (
  state: DeleteDocumentStoreState = initialState,
  action: {
    type: DELETE_DOCUMENT;
    data: RequestDataType;
    error: {};
  }
): DeleteDocumentStoreState => {
  switch (action.type) {
    case DELETE_DOCUMENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case DELETE_DOCUMENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_DOCUMENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteDocument;
