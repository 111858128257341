import { SEND_PRODUCT } from '../reducers/sendProductData';

export const req = data => ({
  type: SEND_PRODUCT.REQ,
  data
});

export const reset = () => ({
  type: SEND_PRODUCT.RESET
});

export const got = () => ({
  type: SEND_PRODUCT.GOT
});

export const err = (error: {}) => ({
  type: SEND_PRODUCT.ERR,
  error
});
