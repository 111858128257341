import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { reset as resetApplication } from 'Application/actions/getApplication';
import {
  req as sendApplicationData,
  reset as resetApplicationData
} from 'Application/actions/sendApplicationData';
import { RequestDataType as sendApplicationDataReq } from 'Application/reducers/sendApplicationData';
import {
  req as getTenderByClient,
  reset as resetTenderByClient,
  ResponseData as ResTenderByClient
} from 'entities/Cabinet/Client/model/actions/getTenderByClient';
import {
  req as getCompaniesOfUser,
  ResponseData as ResCompaniesOfUser,
  RequestData
} from 'entities/Cabinet/Client/model/actions/getCompaniesOfUser';
import { req as postNewCompanies } from 'entities/Cabinet/Client/model/actions/postNewCompanies';
import {
  req as patchUpdateCompanyBlank,
  RequestData as patchUpdateCompanyBlankReq
} from 'Application/actions/patchUpdateCompanyBlank';
import { req as getCompany } from 'src/features/Companies/actions/getCompany';
import {
  req as postOfferSend,
  reset as resetOfferSend
} from 'Application/actions/postOfferSend';
import {
  req as getTenderTeledoc,
  ResponseData as TenderTeledocRes
} from 'src/features/Application/actions/getTenderTeledoc';
import {
  req as postTenderByClient,
  RequestData as ReqTenderByClient
} from 'entities/Cabinet/Client/model/actions/postTenderByClient';
import {
  req as patchCompanyClient,
  RequestData as ReqCompanyClient
} from 'entities/Cabinet/Client/model/actions/patchCompanyClient';
import {
  req as getCompanyClient,
  ResponseData as ResCompanyClient
} from 'entities/Cabinet/Client/model/actions/getCompanyClient';
import { req as postAddTenderByEruz } from 'entities/Cabinet/Client/model/actions/postAddTenderByEruz';
import {
  APPLICATION_TYPES,
  GUARANTEES_TYPE,
  GuaranteesApplicationRead,
  TAX_TYPE,
  TENDER_SEGMENT,
  TENDER_TYPE
} from 'Application/types';
import {
  ApplicationTypes,
  InnPlaceholders,
  TaxSystemType
} from 'shared/constants';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { Checkbox } from 'shared/ui/Checkbox';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'globaltypes';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { GuaranteesTerm, TERM_TYPE } from './GuaranteesTerm/GuaranteesTerm';
import { GuaranteesTypeRadioGroup } from './GuaranteesTypeRadioGroup/GuaranteesTypeRadioGroup';
import { createValidaton } from './validator';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType,
  reset as resetStateCompanyByInn
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  ResponseDataType as CompanyByCustomerInnResData,
  req as getCompanyByCustomerInn,
  RequestDataType as CompanyByCustomerInnReqData
} from 'src/features/Application/actions/getCompanyByInn';

import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { PopupContainer } from 'src/features/Layouts/components/InnPopup/styled';
import {
  ApplicationForm,
  ApplicationFormContent,
  ApplicationFormStyled,
  ApplicationInputsColumn,
  ApplicationStepTitle,
  CloseApplicationBtn
} from '../styled';
import {
  ButtonContainer,
  ButtonStyle,
  CheckboxContainer,
  ClientList,
  EISContainer,
  FiltersStyled,
  InnInputContainer,
  InputContainer,
  ModalContainer,
  PopupContentClients,
  PurchaseContent,
  TableRow,
  TaxSystemContainer,
  TitleTabContainer,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes,
  TooltipBoxStyle
} from './styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ClientsListType } from 'src/features/Clients/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'shared/ui/Button';

interface OwnProps {
  path: string;
}

interface State {
  initializationStarted: boolean;
  isInfoPopupCompanyInn: boolean;
  isInfoPopupCustomerInn: boolean;
  clientsIsVisible: boolean;
  code: string;
  clientCompanyName: string;
  eis: string;
  selectedRow: number;
  clientName: string;
  clientInn: string;
  errors: {};
  isCode: boolean;
  isCustomerInn: boolean;
  isSectionClientInfo: boolean;
  isSectionGuarantees: boolean;
  isSectionContract: boolean;
  amount: number;
  tender: TENDER_TYPE;
  tax: TAX_TYPE;
  commercial: TENDER_TYPE;
  customerInn: string;
  customerName: string;
  term: number;
  tenderLink: string;
  termEndDate: string;
  termStartDate: string;
  guaranteesType: GUARANTEES_TYPE;
  financingType: APPLICATION_TYPES;
  desiredBanks: string;
  startPrice: number;
  sumFinal: number;
  lotCode: number;
  subject: string;
  isClosedAuction: boolean;
  isSingleSupplier: boolean;
  isSendApiError: boolean;
  offerId: number;
  isApplicationPrescoring: boolean;
  isRejection: boolean;
  taxSystem: string;
  isTaxSystem: boolean;
  refinancingGuarantees: boolean;
  purchaseIsVisible: boolean;
}

interface StateToProps extends Partial<GuaranteesApplicationRead> {
  applicationId: number;
  statusCreateEmpty: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  uploadStatus: REQUEST_STATUSES;
  allowFlags: GetApplicationFlagsResponseDataType;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  companyByCustomerInn: CompanyByCustomerInnResData;
  statusCompanyByCustomerInn: REQUEST_STATUSES;
  roles: string[];
  userCompanyInn: string;
  companies: ResCompaniesOfUser;
  statusCompanies: REQUEST_STATUSES;
  errorCompanies: ResponseError;
  companyInn: string;
  companyShortName: string;
  statusClientApplication: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  sendApiError: string;
  statusSendApplication: REQUEST_STATUSES;
  statusPostApplicationPrescoring: REQUEST_STATUSES;
  errorPostApplicationPrescoring: ResponseError;
  companyTaxSystem: number;
  statusCompanyTaxSystem: REQUEST_STATUSES;
  companyClient: ResCompanyClient;
  tenderByApplication: ResTenderByClient[];
  statusTenderByApplication: REQUEST_STATUSES;
  statusCreateTender: REQUEST_STATUSES;
  tenderTeledoc: TenderTeledocRes[];
  statusTenderTeledoc: REQUEST_STATUSES;
  statusAddTenderByEruz: REQUEST_STATUSES;
}

interface DispatchToProps {
  sendApplicationData: (data: sendApplicationDataReq) => void;
  resetApplication: () => void;
  openModal: (data: OpenModalDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getCompanyByCustomerInn: (data: CompanyByCustomerInnReqData) => void;
  postNewCompanies: (companyInn: string) => void;
  getCompaniesOfUser: (data?: RequestData) => void;
  resetStateCompanyByInn: () => void;
  patchUpdateCompanyBlank: (
    inn: string,
    data: patchUpdateCompanyBlankReq
  ) => void;
  getCompany: (inn: string) => void;
  postOfferSend: (id: number) => void;
  resetOfferSend: () => void;
  patchCompanyClient: (inn: string, data: ReqCompanyClient) => void;
  getCompanyClient: (inn: string) => void;
  getTenderByClient: (id: string) => void;
  getTenderTeledoc: (id: string, eis: string) => void;
  postTenderByClient: (data: ReqTenderByClient) => void;
  postAddTenderByEruz: (id: string, eruzId: string) => void;
  resetTenderByClient: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

// TODO refactor
// TODO extract base step class
class GuaranteesFormStep0 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    financingType: null,
    initializationStarted: false,
    isInfoPopupCompanyInn: false,
    isInfoPopupCustomerInn: false,
    clientsIsVisible: false,
    code: '',
    clientCompanyName: '',
    eis: '',
    selectedRow: null,
    clientName: '',
    clientInn: '',
    amount: 0,
    tender: null,
    tax: null,
    commercial: null,
    desiredBanks: '',
    errors: {},
    isCode: false,
    isCustomerInn: false,
    isSectionClientInfo: true,
    isSectionGuarantees: true,
    isSectionContract: true,
    customerInn: '',
    customerName: '',
    term: 0,
    termEndDate: '',
    termStartDate: '',
    tenderLink: '',
    guaranteesType: GUARANTEES_TYPE['44-FZ'],
    startPrice: 0,
    sumFinal: 0,
    lotCode: null,
    subject: '',
    isClosedAuction: null,
    isSingleSupplier: null,
    isSendApiError: false,
    offerId: null,
    isApplicationPrescoring: false,
    isRejection: false,
    taxSystem: '',
    isTaxSystem: false,
    refinancingGuarantees: false,
    purchaseIsVisible: false
  };

  clientsModalRef = React.createRef();
  purchaseModalRef = React.createRef();

  componentDidMount() {
    if (this.props.statusClientApplication === REQUEST_STATUSES.GOT) {
      if (!!this.props.code) {
        this.props.getCompany(this.props.code);
        this.props.getTenderByClient(this.props.match.params.id);
      }
      this.setState({
        financingType: this.props.financingType,
        code: this.props.code,
        clientCompanyName: this.props.clientCompanyName,
        amount: this.props.amount,
        tender: this.props.tender,
        commercial: this.props.commercial,
        tax: this.props.tax,
        guaranteesType: this.props.guaranteesType,
        tenderLink: this.props.purchaseLink,
        termStartDate: this.props.termStartDate,
        termEndDate: this.props.termEndDate,
        term: this.props.term,
        desiredBanks: this.props.desiredBanks,
        customerInn: this.props.customerInn,
        customerName: this.props.customerName,
        refinancingGuarantees: this.props.refinancing
      });
    }
    if (this.props.location.state && this.props.location.state.financingType) {
      this.setState({ financingType: this.props.location.state.financingType });
    }
    if (
      this.props.userCompanyInn &&
      this.props.permissions.includes(USER_PERMISSIONS.CLIENT)
    ) {
      this.setState({ code: this.props.userCompanyInn });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.code !== prevState.code) {
      this.setState({ isInfoPopupCompanyInn: false });
    }
    if (this.state.customerInn !== prevState.customerInn) {
      this.setState({ isInfoPopupCustomerInn: false });
    }
    if (this.state.code !== prevState.code && this.state.code.length >= 10) {
      this.props.getCompanyByInn({ inn: this.state.code });
    }
    if (
      this.props.statusTenderByApplication !==
        prevProps.statusTenderByApplication &&
      this.props.statusTenderByApplication === REQUEST_STATUSES.GOT
    ) {
      this.setState({
        subject: this.props.tenderByApplication[0].tender.subject,
        lotCode: +this.props.tenderByApplication[0].tender.lotCode,
        sumFinal: this.props.tenderByApplication[0].tender.sumFinal,
        startPrice: this.props.tenderByApplication.reduce(
          (sum, item) => sum + item.tender.startPrice,
          0
        ),
        customerName: this.props.tenderByApplication[0].tender.customerName,
        customerInn: this.props.tenderByApplication[0].tender.customerINN,
        tenderLink: this.props.tenderByApplication[0].tender.tenderLink,
        isClosedAuction: this.props.tenderByApplication[0].tender
          .isClosedAuction,
        isSingleSupplier: this.props.tenderByApplication[0].tender
          .isSingleSupplier
      });
    }
    if (
      this.props.statusAddTenderByEruz !== prevProps.statusAddTenderByEruz &&
      this.props.statusAddTenderByEruz === REQUEST_STATUSES.GOT
    ) {
      this.setState({
        customerName: this.props.tenderTeledoc[0].customerName,
        customerInn: this.props.tenderTeledoc[0].customerINN,
        guaranteesType:
          this.props.tenderTeledoc[0].tenderSegment === 1
            ? GUARANTEES_TYPE['44-FZ']
            : this.props.tenderTeledoc[0].tenderSegment === 2
              ? GUARANTEES_TYPE['223-FZ']
              : this.props.tenderTeledoc[0].tenderSegment === 3
                ? GUARANTEES_TYPE['615-P']
                : GUARANTEES_TYPE['COMMERCIAL'],
        tenderLink: this.props.tenderTeledoc[0].tenderLink,
        startPrice: this.props.tenderTeledoc.reduce(
          (sum, item) => sum + item.startPrice,
          0
        ),
        sumFinal: this.props.tenderTeledoc[0].sumFinal,
        subject: this.props.tenderTeledoc[0].subject,
        lotCode: +this.props.tenderTeledoc[0].lotCode,
        isSingleSupplier: this.props.tenderTeledoc[0].isSingleSupplier,
        isClosedAuction: this.props.tenderTeledoc[0].isClosedAuction
      });
    }
    if (this.props.statusTenderTeledoc !== prevProps.statusTenderTeledoc) {
      this.setState({ purchaseIsVisible: true });
    }
    if (
      this.props.statusCreateTender !== prevProps.statusCreateTender &&
      this.props.statusCreateTender === REQUEST_STATUSES.GOT
    ) {
      // this.props.postAddTenderById(
      //   this.props.match.params.id,
      //   this.props.idCreateTender
      // );
      this.props.getTenderByClient(this.props.match.params.id);
    }
    if (
      prevState.customerInn !== this.state.customerInn &&
      this.state.customerInn &&
      this.state.customerInn.length >= 10
    ) {
      this.props.getCompanyByCustomerInn({ inn: this.state.customerInn });
    }
    if (
      this.props.statusSendApi !== prevProps.statusSendApi &&
      this.props.statusSendApi === REQUEST_STATUSES.ERROR
    ) {
      this.setState({ isSendApiError: true });
    }
    if (
      this.props.statusSendApplication !== prevProps.statusSendApplication &&
      this.props.statusSendApplication === REQUEST_STATUSES.GOT
    ) {
      this.props.resetApplication();
      if (this.state.offerId) {
        this.props.postOfferSend(this.state.offerId);
        this.setState({ offerId: null });
      }
    }
    if (
      this.props.statusPostApplicationPrescoring !==
        prevProps.statusPostApplicationPrescoring &&
      this.props.statusPostApplicationPrescoring === REQUEST_STATUSES.ERROR
    ) {
      this.setState({ isApplicationPrescoring: true });
    }
    if (
      this.props.statusCompanyTaxSystem !== prevProps.statusCompanyTaxSystem &&
      this.props.statusCompanyTaxSystem === REQUEST_STATUSES.GOT
    ) {
      this.setState({
        taxSystem: this.props.companyTaxSystem
          ? this.props.companyTaxSystem.toString()
          : '',
        clientCompanyName: this.props.companyInn
          ? this.props.companyShortName
          : '',
        code: this.props.companyShortName ? this.props.companyInn : ''
      });
    }
  }

  componentWillUnmount(): void {
    this.props.resetApplication();
    this.props.resetOfferSend();
    this.props.resetTenderByClient();
    this.setState({
      financingType: null,
      initializationStarted: false,
      isInfoPopupCompanyInn: false,
      isInfoPopupCustomerInn: false,
      clientsIsVisible: false,
      code: '',
      clientCompanyName: '',
      eis: '',
      selectedRow: null,
      clientName: '',
      clientInn: '',
      amount: 0,
      tender: null,
      tax: null,
      commercial: null,
      desiredBanks: '',
      errors: {},
      isCode: false,
      isCustomerInn: false,
      isSectionClientInfo: true,
      isSectionGuarantees: true,
      isSectionContract: true,
      customerInn: '',
      customerName: '',
      term: 0,
      termEndDate: '',
      termStartDate: '',
      tenderLink: '',
      guaranteesType: GUARANTEES_TYPE['44-FZ'],
      startPrice: 0,
      sumFinal: 0,
      lotCode: null,
      subject: '',
      isClosedAuction: null,
      isSingleSupplier: null,
      isSendApiError: false,
      offerId: null,
      isApplicationPrescoring: false,
      taxSystem: '',
      isTaxSystem: false,
      refinancingGuarantees: false,
      purchaseIsVisible: false
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  onChangeBaseFields = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber =
      name === 'amount' ||
      name === 'term' ||
      name === 'startPrice' ||
      name === 'sumFinal';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (name === 'code') {
      this.setState({ isCode: true });
    } else if (name === 'customerInn') {
      this.setState({ isCustomerInn: true });
    }

    if (
      (name === 'code' || name === 'customerInn') &&
      sanitizedValue.length > 12
    ) {
      return;
    } else if (
      (name === 'code' || name === 'customerInn') &&
      sanitizedValue.length <= 12
    ) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
      const floated = toFloatFormatter(sanitizedValue);

      this.setState({
        [name]: isToNumber
          ? floated
          : name === 'code' || name === 'customerInn'
            ? sanitizedValue
            : value
      } as any);
    } else {
      const floated = toFloatFormatter(value);

      if (name === 'taxSystem') {
        this.setState({ isTaxSystem: true });
      }

      this.setState({
        [name]: isCheckbox ? checked : isToNumber ? floated : value
      } as any);
    }
  };

  onDateChange = (name: string, date: string) => {
    this.setState({ [name]: date } as any);
  };

  onTermTypeChange = (name: string, value: any) => {
    this.setState({ [name]: value } as any);
  };

  sendData = () => {
    this.props.sendApplicationData({
      id: +this.props.match.params.id,
      code: this.state.code,
      clientCompanyName: this.state.clientCompanyName,
      customerInn: this.state.customerInn,
      customerName: this.state.customerName,
      amount: this.state.amount,
      term: this.state.term,
      termStartDate: this.state.termStartDate,
      termEndDate: this.state.termEndDate,
      guarantees: {
        guaranteesType: this.state.guaranteesType,
        commercial: this.state.commercial,
        tax: this.state.tax,
        tender: this.state.tender,
        desiredBanks: this.state.desiredBanks,
        tenderComment: this.props.tenderComment,
        purchaseLink: this.state.tenderLink
      }
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    if (!this.isFormValid()) return;

    this.setState({ initializationStarted: true });

    this.props.resetStateCompanyByInn();
    this.sendData();

    this.navigateFromTo(this.props.path, 'documents');
  };

  sendContract = () => {
    this.props.postTenderByClient({
      applicationId: +this.props.match.params.id,
      supplierinn: this.state.code,
      TenderSegment:
        this.state.guaranteesType === GUARANTEES_TYPE['44-FZ']
          ? 1
          : this.state.guaranteesType === GUARANTEES_TYPE['223-FZ']
            ? 2
            : this.state.guaranteesType === GUARANTEES_TYPE['615-P']
              ? 3
              : this.state.guaranteesType === GUARANTEES_TYPE['COMMERCIAL'] ||
                this.state.guaranteesType === GUARANTEES_TYPE['OTHER']
                ? 5
                : null,
      customerinn: this.state.customerInn,
      StartPrice: this.state.startPrice,
      SumFinal: +this.state.sumFinal,
      LotCode: this.state.lotCode.toString()
    });
  };

  onPopupClick = (name: string) => {
    if (name === 'isInfoPopupCompanyInn') {
      this.setState({ isInfoPopupCompanyInn: true, isCode: false });

      this.setState({
        clientCompanyName: this.props.companyByInn.companyShortName
      });

      this.props.postNewCompanies(this.props.userCompanyInn);

      this.props.getCompanyClient(this.state.code);
    } else if (name === 'isInfoPopupCustomerInn') {
      this.setState({ isInfoPopupCustomerInn: true, isCustomerInn: false });
      this.setState({
        customerName: this.props.companyByCustomerInn.companyShortName
      });
    }
  };

  renderToggleButton = (buttonName: keyof State, description?: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonYes
          type="button"
          onClick={() => this.setState({ [buttonName]: true } as any)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <ToggleButtonNo
          type="button"
          onClick={() => this.setState({ [buttonName]: false } as any)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  handleOpenModalClient = () => {
    this.setState({ clientsIsVisible: true });
    this.props.getCompaniesOfUser();
  };

  handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      this.setState({ clientsIsVisible: false });
      this.setState({ purchaseIsVisible: false });
      this.setState({ isSendApiError: false });
      this.setState({ isApplicationPrescoring: false });
    }
  };

  handleSelectClient = (client: ClientsListType, index: number) => {
    this.setState({
      selectedRow: index,
      code: client.companyINN,
      clientCompanyName: client.companyName
    });
  };

  handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);

    if (name === 'clientInn') {
      this.props.getCompaniesOfUser({ companyInn: value });
    } else if (name === 'clientName') {
      this.props.getCompaniesOfUser({ companyName: value });
    }
  };

  isFormValid = (): boolean => {
    const {
      code,
      clientCompanyName,
      amount,
      guaranteesType,
      tender,
      tax,
      commercial,
      term,
      termStartDate,
      termEndDate,
      tenderLink,
      taxSystem,
      startPrice,
      sumFinal,
      lotCode,
      subject,
      isClosedAuction,
      isSingleSupplier
    } = this.state;

    const { financingType } = this.props;

    const isGuaranteesType = [
      GUARANTEES_TYPE['223-FZ'],
      GUARANTEES_TYPE['44-FZ'],
      GUARANTEES_TYPE['615-P']
    ].includes(guaranteesType);

    const isTermValid =
      (TERM_TYPE.DATES && termStartDate && termEndDate) ||
      (TERM_TYPE.PERIOD && term);

    const isTenderLinkValid = isGuaranteesType
      ? !!tenderLink
      : !isGuaranteesType;

    const isTenderTypeValid =
      tender !== null || tax !== null || commercial !== null
        ? true
        : guaranteesType === GUARANTEES_TYPE.LIMIT && true;

    return !!financingType &&
      code &&
      clientCompanyName &&
      amount &&
      !!startPrice &&
      !!sumFinal &&
      !!lotCode &&
      !!subject &&
      !!isClosedAuction &&
      !!isSingleSupplier &&
      isTermValid &&
      isTenderLinkValid &&
      isTenderTypeValid &&
      this.props.companyByInn.TaxSystem !== 'FILLED'
      ? !!taxSystem
      : true;
  };

  render() {
    const {
      openModal,
      companyByInn,
      statusInn,
      financingType,
      allowFlags: { allowBlankFields },
      statusCompanyByCustomerInn,
      companyByCustomerInn
    } = this.props;

    const {
      code,
      clientCompanyName,
      isSectionClientInfo,
      isCode,
      isInfoPopupCompanyInn,
      isSectionGuarantees,
      isSectionContract,
      clientsIsVisible,
      clientName,
      clientInn,
      selectedRow,
      eis,
      amount,
      tender,
      tax,
      commercial,
      customerName,
      customerInn,
      isCustomerInn,
      term,
      termStartDate,
      termEndDate,
      guaranteesType,
      startPrice,
      sumFinal,
      lotCode,
      subject,
      desiredBanks,
      tenderLink,
      taxSystem,
      isTaxSystem,
      refinancingGuarantees,
      purchaseIsVisible
    } = this.state;

    const amountRange = {
      min: 0,
      max: 100000000000
    };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max],
      guaranteesType: [{ guaranteesType }]
    }).validate({ ...this.state });

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        {!!code ? (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        ) : (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        {this.props.statusCreateEmpty === REQUEST_STATUSES.REQUEST && (
          <Loader />
        )}

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="initialize-application-form"
            >
              <Select
                options={Object.keys(ApplicationTypes).map(type => ({
                  id: type,
                  name: ApplicationTypes[type]
                }))}
                value={financingType}
                label="Тип продукта"
                name="financingType"
                placeholder="Выберите тип продукта"
                required={true}
                disabled={true}
                error={errors.financingType}
                onChange={this.onChangeBaseFields}
              />

              <TitleTabContainer>
                <p>Информация о клиенте (Принципале)</p>
                <FontAwesomeIcon
                  icon={isSectionClientInfo ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionClientInfo: !prevState.isSectionClientInfo
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionClientInfo && (
                <>
                  <PopupContainer>
                    <InputContainer>
                      <Input
                        value={code}
                        label="ИНН принципала (клиента)"
                        name="code"
                        placeholder={InnPlaceholders.entity}
                        required={true}
                        warning={errors.code}
                        onChange={this.onChangeBaseFields}
                      />

                      <Button
                        label="Выбрать из моих компаний"
                        onClick={this.handleOpenModalClient}
                      />
                    </InputContainer>

                    {statusInn === REQUEST_STATUSES.GOT &&
                      isCode &&
                      code &&
                      code.length >= 10 &&
                      !isInfoPopupCompanyInn && (
                        <InnPopup
                          companyInfo={companyByInn}
                          onPopupClick={this.onPopupClick}
                          name="isInfoPopupCompanyInn"
                        />
                      )}
                  </PopupContainer>

                  <Input
                    value={clientCompanyName}
                    label="Наименование принципала (клиента)"
                    name="clientCompanyName"
                    placeholder="Введите крактое наименование клиента"
                    required={true}
                    error={errors.clientCompanyName}
                    onChange={this.onChangeBaseFields}
                  />

                  {companyByInn.TaxSystem !== 'FILLED' && (
                    <TaxSystemContainer>
                      <Select
                        options={Object.keys(TaxSystemType).map(type => ({
                          id: type,
                          name: TaxSystemType[type]
                        }))}
                        value={taxSystem}
                        label="Система налогообложения"
                        name="taxSystem"
                        placeholder="не заполнено"
                        required
                        error={errors.taxSystem}
                        onChange={this.onChangeBaseFields}
                      />

                      {isTaxSystem &&
                        code && (
                          <Button
                            label="Сохранить"
                            onClick={() => {
                              this.setState({ isTaxSystem: false });
                              this.props.patchCompanyClient(code, {
                                taxSystem: +taxSystem
                              });
                            }}
                          />
                        )}
                    </TaxSystemContainer>
                  )}
                </>
              )}

              <TitleTabContainer>
                <p>Информация о банковской гарантии</p>
                <FontAwesomeIcon
                  icon={isSectionGuarantees ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionGuarantees: !prevState.isSectionGuarantees
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionGuarantees && (
                <>
                  {this.props.statusTenderByApplication !==
                    REQUEST_STATUSES.GOT && (
                    <EISContainer>
                      <Input
                        name="eis"
                        label="Номер ЕИС(не ссылка)"
                        placeholder="не заполнено"
                        value={eis}
                        onChange={e =>
                          this.setState({ eis: e.currentTarget.value })
                        }
                      />

                      {eis && (
                        <Button
                          label="Найти в ЕИС"
                          onClick={() => this.props.getTenderTeledoc('7', eis)}
                        />
                      )}
                    </EISContainer>
                  )}

                  <InputRange
                    value={amount.toFixed(2)}
                    label="Сумма"
                    name="amount"
                    placeholder="Введите сумму"
                    min={amountRange.min}
                    max={amountRange.max}
                    step={1000}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    disableSlider={true}
                    required={true}
                    error={errors.amount}
                    onChange={this.onChangeBaseFields}
                  />

                  <GuaranteesTypeRadioGroup
                    required={true}
                    commercial={commercial}
                    tax={tax}
                    tender={tender}
                    errors={errors}
                    guaranteesType={guaranteesType}
                    tenderLink={tenderLink}
                    onChange={this.onChangeBaseFields}
                    tenderLinkRequired={[
                      GUARANTEES_TYPE['223-FZ'],
                      GUARANTEES_TYPE['44-FZ'],
                      GUARANTEES_TYPE['615-P']
                    ].includes(guaranteesType)}
                  />

                  {[
                    GUARANTEES_TYPE['223-FZ'],
                    GUARANTEES_TYPE['44-FZ'],
                    GUARANTEES_TYPE['615-P'],
                    GUARANTEES_TYPE['COMMERCIAL']
                  ].includes(guaranteesType) && (
                    <>
                      <PopupContainer>
                        <InputContainer>
                          <Input
                            value={customerInn}
                            label="ИНН бенефициара (заказчика)"
                            name="customerInn"
                            placeholder={InnPlaceholders.entity}
                            warning={errors.customerInn}
                            onChange={this.onChangeBaseFields}
                          />

                          {statusCompanyByCustomerInn ===
                            REQUEST_STATUSES.GOT &&
                            isCustomerInn &&
                            customerInn &&
                            customerInn.length >= 10 &&
                            !this.state.isInfoPopupCustomerInn && (
                              <InnPopup
                                companyInfo={companyByCustomerInn}
                                onPopupClick={this.onPopupClick}
                                name="isInfoPopupCustomerInn"
                              />
                            )}
                        </InputContainer>
                      </PopupContainer>

                      <Input
                        label="Наименование бенефициара (заказчика)"
                        name="customerName"
                        value={customerName}
                        placeholder="Введите краткое наименование бенефициара (заказчика)"
                        onChange={this.onChangeBaseFields}
                      />
                    </>
                  )}

                  <GuaranteesTerm
                    errors={errors}
                    term={term}
                    termStartDate={termStartDate}
                    termEndDate={termEndDate}
                    onChangeBaseFields={this.onChangeBaseFields}
                    onDateChange={this.onDateChange}
                    onTermTypeChange={this.onTermTypeChange}
                    required={true}
                  />

                  <Textarea
                    label="Пожелания по банкам"
                    name="desiredBanks"
                    value={desiredBanks}
                    onChange={this.onChangeBaseFields}
                    required={!allowBlankFields}
                    placeholder="Напр., “любые банки” или “строго только банк XXX”"
                  />

                  <CheckboxContainer>
                    <Checkbox
                      name="refinancing"
                      label="Переобеспечение"
                      checked={refinancingGuarantees}
                      onChange={() =>
                        this.setState(prevState => ({
                          refinancingGuarantees: !prevState.refinancingGuarantees
                        }))
                      }
                    />
                  </CheckboxContainer>
                </>
              )}

              <TitleTabContainer>
                <p>Информация о контракте</p>
                <FontAwesomeIcon
                  icon={isSectionContract ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionContract: !prevState.isSectionContract
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionContract && (
                <>
                  <InputRange
                    value={startPrice.toFixed(2)}
                    step={1000}
                    min={amountRange.min}
                    max={amountRange.max}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    disableSlider={true}
                    required
                    name="startPrice"
                    label="Начальная цена контракта"
                    onChange={this.onChangeBaseFields}
                  />

                  <InputRange
                    value={sumFinal.toFixed(2)}
                    step={1000}
                    min={amountRange.min}
                    max={amountRange.max}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    disableSlider={true}
                    required
                    name="sumFinal"
                    label="Итоговая цена контракта"
                    onChange={this.onChangeBaseFields}
                  />

                  <Input
                    required
                    name="lotCode"
                    label="Номер лота"
                    value={lotCode}
                    onChange={this.onChangeBaseFields}
                  />

                  <Input
                    required
                    name="subject"
                    label="Предмет контракта"
                    value={subject}
                    placeholder="Введите предмет контракта"
                    onChange={this.onChangeBaseFields}
                  />

                  {this.renderToggleButton(
                    'isClosedAuction',
                    'Закрытый аукцион'
                  )}
                  {this.renderToggleButton(
                    'isSingleSupplier',
                    'Закупка у единственного поставщика'
                  )}

                  {this.state.guaranteesType !== GUARANTEES_TYPE['LIMIT'] &&
                    this.props.statusTenderByApplication !==
                      REQUEST_STATUSES.GOT && (
                      <Button
                        label="Сохранить контракт"
                        onClick={this.sendContract}
                        disabled={!this.isFormValid()}
                      />
                    )}
                </>
              )}

              <ButtonContainer>
                <TooltipBoxStyle
                  isWarning={!this.isFormValid()}
                  text="не заполнены все обязательные поля"
                >
                  <ButtonStyle
                    notValid={!this.isFormValid()}
                    type="submit"
                    label="Настроить параметры заявки"
                    template="nextBtn"
                  />
                </TooltipBoxStyle>
              </ButtonContainer>
            </ApplicationForm>
          </ApplicationInputsColumn>
        </ApplicationFormContent>

        <ModalContainer
          visible={purchaseIsVisible}
          onClick={e => this.handleOutsideClick(e, this.purchaseModalRef)}
        >
          <PurchaseContent ref={this.purchaseModalRef}>
            {this.props.statusTenderTeledoc === REQUEST_STATUSES.GOT ? (
              <>
                <h2>Найдена закупка</h2>

                <p>
                  Тип:{' '}
                  {!!this.props.tenderTeledoc.length &&
                    TENDER_SEGMENT[this.props.tenderTeledoc[0].tenderSegment]}
                </p>
                <div>
                  <p>Предмет контракта:</p>
                  <p>
                    {!!this.props.tenderTeledoc.length &&
                      this.props.tenderTeledoc[0].subject}
                  </p>
                </div>
                <div>
                  <p>Заказчик:</p>
                  <p>
                    {!!this.props.tenderTeledoc.length &&
                      this.props.tenderTeledoc[0].customerName}
                  </p>
                </div>

                <Button
                  label="Добавить в заявку"
                  onClick={() => {
                    this.setState({ purchaseIsVisible: false });
                    this.props.postAddTenderByEruz(
                      this.props.id.toString(),
                      !!this.props.tenderTeledoc.length &&
                        this.props.tenderTeledoc[0].eruzId
                    );
                  }}
                />
              </>
            ) : (
              this.props.statusTenderTeledoc === REQUEST_STATUSES.ERROR && (
                <>
                  <h2>Ошибка</h2>
                  <p>Закупка не найдена</p>
                </>
              )
            )}
          </PurchaseContent>
        </ModalContainer>

        <ModalContainer
          visible={clientsIsVisible}
          onClick={e => this.handleOutsideClick(e, this.clientsModalRef)}
        >
          <PopupContentClients ref={this.clientsModalRef}>
            <h2>Выберите компанию или ИП для заявки</h2>

            <FiltersStyled>
              <InnInputContainer>
                <Input
                  value={clientName}
                  label="Наименование компании:"
                  name="clientName"
                  onChange={this.handleFilter}
                />
              </InnInputContainer>

              <InnInputContainer>
                <Input
                  value={clientInn}
                  label="ИНН клиента:"
                  name="clientInn"
                  placeholder={InnPlaceholders.entity}
                  onChange={this.handleFilter}
                />
              </InnInputContainer>
            </FiltersStyled>

            {this.props.statusCompanies === REQUEST_STATUSES.GOT && (
              <ClientList>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="50%">Наименование</TableThStyled>
                      <TableThStyled width="50%">ИНН</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {this.props.companies.items.map((company, index) => (
                      <TableRow
                        key={index}
                        isSelected={selectedRow === index}
                        onClick={() =>
                          this.handleSelectClient(company as any, index)
                        }
                      >
                        <td>{company.companyName}</td>
                        <td>{company.companyINN}</td>
                      </TableRow>
                    ))}
                  </tbody>
                </TableStyled>

                <Pagination list={this.props.companies} />
              </ClientList>
            )}

            <Button
              label="Выбрать компанию"
              disabled={!code && !clientCompanyName}
              onClick={() => {
                this.setState({
                  clientsIsVisible: false,
                  code: this.state.code,
                  clientCompanyName: this.state.clientCompanyName
                });
              }}
            />
          </PopupContentClients>
        </ModalContainer>
      </ApplicationFormStyled>
    );
  }
}

// TODO loader on submit
const mapStateToProps = ({
  Application,
  User,
  SCF,
  ClientRole,
  Companies
}: STORE & CRM) => ({
  applicationId: Application.createApplication.id,
  statusCreateEmpty: Application.createApplication.status,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  companyByCustomerInn: Application.getCompanyByInn.data,
  statusCompanyByCustomerInn: Application.getCompanyByInn.status,
  uploadStatus: Application.uploadDocuments.status,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  userCompanyInn: User.getUserData.data.companyInn,
  companies: ClientRole.getCompaniesOfUser.data,
  statusCompanies: ClientRole.getCompaniesOfUser.status,
  errorCompanies: ClientRole.getCompaniesOfUser.error,
  allowFlags: Application.getApplicationFlags.data,
  companyInn: Companies.getCompany.company.inn,
  companyShortName: Companies.getCompany.company.companyShortName,
  companyTaxSystem: Companies.getCompany.company.taxSystem,
  statusCompanyTaxSystem: Companies.getCompany.status,
  ...ClientRole.getClientApplication.data,
  statusClientApplication: ClientRole.getClientApplication.status,
  allOffers: Application.getAllOffers.data,
  statusSendApi: Application.postOfferSend.status,
  sendApiError: Application.postOfferSend.error.body,
  statusSendApplication: Application.sendApplicationData.status,
  statusPostApplicationPrescoring: Application.postApplicationPrescoring.status,
  errorPostApplicationPrescoring:
    Application.postApplicationPrescoring.error.body,
  companyClient: ClientRole.getCompanyClient.data,
  tenderByApplication: ClientRole.getTenderByClient.data,
  statusTenderByApplication: ClientRole.getTenderByClient.status,
  statusCreateTender: ClientRole.postTenderByClient.status,
  tenderTeledoc: Application.getTenderTeledoc.data,
  statusTenderTeledoc: Application.getTenderTeledoc.status,
  statusAddTenderByEruz: ClientRole.postAddTenderByEruz.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationData,
      resetApplicationData,
      openModal,
      getCompanyByInn,
      getCompanyByCustomerInn,
      postNewCompanies,
      getCompaniesOfUser,
      resetStateCompanyByInn,
      patchUpdateCompanyBlank,
      getCompany,
      resetApplication,
      postOfferSend,
      resetOfferSend,
      patchCompanyClient,
      getCompanyClient,
      getTenderByClient,
      resetTenderByClient,
      getTenderTeledoc,
      postTenderByClient,
      postAddTenderByEruz
    },
    dispatch
  );

const GuaranteesFormStep0Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(GuaranteesFormStep0)
);

export { GuaranteesFormStep0Connect as GuaranteesFormStep0 };
