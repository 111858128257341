import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { req as getUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { BankCabinetPage } from 'pages/Cabinet/Bank/CabinetPage';
import { ClientCabinetPage } from 'pages/Cabinet/Client/CabinetPage';
import { InternalCabinetPage } from 'pages/Cabinet/InternalAgent/CabinetPage';
import { ExternalAgentCabinetPage } from 'pages/Cabinet/ExternalAgent/CabinetPage';

interface StateToProps {
  roles: string[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getUserData: () => void;
  exit: () => void;
  resetUserData: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const CabinetWrapper: React.FC<Props> = ({
  getUserData,
  exit,
  resetUserData,
  roles,
  status,
  history,
  location
}) => {
  React.useEffect(() => {
    getUserData();
  }, []);

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.ERROR) {
        exit();
        resetUserData();
      }

      if (
        status === REQUEST_STATUSES.GOT &&
        !location.pathname.includes('bank') &&
        !location.pathname.includes('client') &&
        !location.pathname.includes('internal') &&
        !location.pathname.includes('partner')
      ) {
        const role = roles.includes('Bank')
          ? 'bank'
          : roles.includes('Applicant')
            ? 'client'
            : roles.includes('InternalAgent')
              ? 'internal'
              : roles.includes('ExternalAgent') && 'partner';

        history.push(`/cabinet/${role}`);
      }
    },
    [status, location.pathname]
  );

  return (
    <div>
      <Route path="/cabinet/bank" component={BankCabinetPage} />
      <Route path="/cabinet/client" component={ClientCabinetPage} />
      <Route path="/cabinet/internal" component={InternalCabinetPage} />
      <Route path="/cabinet/partner" component={ExternalAgentCabinetPage} />
    </div>
  );
};

const mapStateToProps = ({ User }: STORE) => ({
  roles: User.getUserData.data.roles,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserData, exit, resetUserData }, dispatch);

const CabinetWrapperConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CabinetWrapper)
);

export { CabinetWrapperConnect as CabinetWrapper };
