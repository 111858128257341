import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Debtor/model/actions/sendSubSignature';

import {
  SEND_SUB_SIGNATURE,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/sendSubSignature';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* sendSubSignature(action: {
  type: SEND_SUB_SIGNATURE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFDebtorApi.sendSubSignature, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendSubSignatureSaga() {
  yield takeLatest(SEND_SUB_SIGNATURE.REQ, sendSubSignature);
}
