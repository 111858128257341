import * as React from 'react';
import { USER_PERMISSIONS } from 'src/globaltypes';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { CabinetComponent } from '../EachPage';
import { createApplicationsListCabinetComponent } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';

export const AnalystSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>
        Кредитная аналитика
      </SectionSeparatorStyled>
    ),
    hasPermission: permissions =>
      permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
      permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/internal/work-applications',
      type: APPLICATIONS_LIST_TYPE.WORK,
      link: {
        icon: 'bag',
        label: 'Внешние заявки',
        countName: 'work'
      }
    })
  }
];
