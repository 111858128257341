import { GET_ADD_TENDER_BY_ERUZ } from 'Application/reducers/getAddTenderByEruz';

export const req = (id: string, eruz: string) => ({
  type: GET_ADD_TENDER_BY_ERUZ.REQ,
  id,
  eruz
});

export const got = () => ({
  type: GET_ADD_TENDER_BY_ERUZ.GOT
});

export const err = (error: {}) => ({
  type: GET_ADD_TENDER_BY_ERUZ.ERR,
  error
});
