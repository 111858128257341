import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'src/features/SCF/actions/postAddToAuction';

import { POST_ADD_TO_AUCTION } from 'src/features/SCF/reducers/postAddToAuction';

import SCFApi from 'src/features/SCF/api';

function* postAddToAuction(action: {
  type: POST_ADD_TO_AUCTION;
  guid: string;
  data: RequestData;
}) {
  try {
    yield call(SCFApi.postAddToAuction, action.guid, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddToAuctionSaga() {
  yield takeLatest(POST_ADD_TO_AUCTION.REQ, postAddToAuction);
}
