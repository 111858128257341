import * as React from 'react';
import {
  BarSectionStyled,
  BarLinks,
  BarLink,
  BarLinkText,
  SlidersIcon,
  ClipboardIcon,
  LinkIcon,
  ArchiveIcon,
  KeyIcon,
  InboxIcon
} from './styles';
import { history } from 'src/shared/utils/History';
import './styles/style.css';
import NewRegistriesCounter from './NewRegistriesCounter';

interface Props {
  type: string | JSX.Element;
}

const LimitsClick = () => {
  history.push('/factor/limits?page=1');
};

const ConnectionsClick = () => {
  history.push('/factor/connections?page=1');
};

const RegistriesClick = () => {
  history.push('/factor/registries?page=1');
};

const ContractsClick = () => {
  history.push('/factor/contracts?page=1');
};

const KNCClick = () => {
  history.push('/factor/kyc');
};

const DiscountApplicationsClick = () => {
  history.push('/factor/factoring_applications?page=1');
};

const BarSection: React.FC<Props> = ({ type }) => (
  <BarSectionStyled>
    <BarLinks>
      <BarLink className="link-area" onClick={DiscountApplicationsClick}>
        <InboxIcon className="link-icon-inbox" />
        <BarLinkText className="link-text">Заявки на финансирование</BarLinkText>
      </BarLink>
      <BarLink className="link-area" onClick={LimitsClick}>
        <SlidersIcon className="link-icon-sliders" />
        <BarLinkText className="link-text">Мои лимиты</BarLinkText>
      </BarLink>
      <BarLink className="link-area" onClick={ConnectionsClick}>
        <LinkIcon className="link-icon-link" />
        <BarLinkText className="link-text">Мои связки</BarLinkText>
      </BarLink>
      <BarLink className="link-area" onClick={ContractsClick}>
        <ClipboardIcon className="link-icon-clipboard" />
        <BarLinkText className="link-text">Мои договоры</BarLinkText>
      </BarLink>
      <BarLink className="link-area" onClick={RegistriesClick}>
        <ArchiveIcon className="link-icon-archive" />
        <BarLinkText className="link-text">Мои реестры</BarLinkText>
        <NewRegistriesCounter />
      </BarLink>
      <BarLink className="link-area" onClick={KNCClick}>
        <KeyIcon className="link-icon-key" />
        <BarLinkText className="link-text">Know Your Customer</BarLinkText>
      </BarLink>
    </BarLinks>
  </BarSectionStyled>
);

export default BarSection;
