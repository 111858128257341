import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faExternalLinkSquareAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  req as getDebtorRegistries,
  ResponseData,
  RequestData
} from 'entities/SCF/Internal/model/actions/getDebtorRegistries';
import { req as getDownloadDebtorRegistries } from 'entities/SCF/Internal/model/actions/getDownloadDebtorRegistries';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  INNField,
  INNContainer,
  TDSupplier,
  SupplierContainer,
  TooltipBoxStyle,
  RegistryFilename
} from './styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';

interface StateToProps {
  debtorRegistries: ResponseData;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getDebtorRegistries: (data: RequestData) => void;
  getDownloadDebtorRegistries: (id: number) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Registries: React.FC<Props> = ({
  getDebtorRegistries,
  debtorRegistries,
  status,
  getDownloadDebtorRegistries,
  history
}) => {
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';

      setDebtorInn(debtorInn);

      getDebtorRegistries({
        page,
        pageSize: 20,
        ...(debtorInn && { debtorInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToDebtor = (inn: string) => {
    window.open(`/internal/debtors/${inn}`, '_blank');
  };

  return (
    <>
      <ApplicationTitle>Список реестров</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="7%">Дебитор</TableThStyled>
                <TableThStyled width="15%">Наименование файла</TableThStyled>
                <TableThStyled width="10%">Подпись</TableThStyled>
                <TableThStyled width="8%">Дата добавления</TableThStyled>
                <TableThStyled width="5%">Размер файла</TableThStyled>
                <TableThStyled width="10%">Создатель</TableThStyled>
                <TableThStyled width="5%">Статус</TableThStyled>
                <TableThStyled width="10%">Ошибки</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {debtorRegistries.items.map(debtor => (
                <TableRowStyled key={debtor.id}>
                  <TDSupplier>
                    <SupplierContainer>
                      <div>
                        <div>{debtor.debtorInn}</div>
                        {debtor.debtorInn && (
                          <TooltipBoxStyle textVerify={'Открыть в новом окне'}>
                            <FontAwesomeIcon
                              icon={faExternalLinkSquareAlt}
                              onClick={() => navigateToDebtor(debtor.debtorInn)}
                            />
                          </TooltipBoxStyle>
                        )}
                      </div>
                      <div>{debtor.debtorName}</div>
                    </SupplierContainer>
                  </TDSupplier>
                  <td>
                    <RegistryFilename>
                      {debtor.registryFilename && (
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() => getDownloadDebtorRegistries(debtor.id)}
                        />
                      )}
                      {debtor.registryFilename}
                    </RegistryFilename>
                  </td>
                  <td>{debtor.signatureFilename}</td>
                  <td>
                    <div>
                      {format(new Date(debtor.createdDateTime), 'dd.MM.yyyy')}
                    </div>
                    <div>
                      {format(new Date(debtor.createdDateTime), 'HH:mm')}
                    </div>
                  </td>
                  <td>{debtor.size} КБ</td>
                  <td>{debtor.creatorName}</td>
                  <td>
                    {debtor.status === 1
                      ? 'Обработан'
                      : debtor.status === 0 && 'В обработке'}
                  </td>
                  {debtor.errorsCount > 0 && (
                    <td>{`${debtor.errorRowsCount} строк с ошибками / ${
                      debtor.errorsCount
                    } ошибок`}</td>
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={debtorRegistries} />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ SCFInternal }: STORE) => ({
  debtorRegistries: SCFInternal.getDebtorRegistries.data,
  status: SCFInternal.getDebtorRegistries.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getDebtorRegistries, getDownloadDebtorRegistries },
    dispatch
  );

const RegistriesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Registries)
);

export { RegistriesConnect as Registries };
