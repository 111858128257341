import styled from 'styled-components';
import { rl } from 'shared/styled';

export const DebtorsContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: fit-content;
    height: 40px;
    margin-bottom: 20px;
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;
