import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostDebtorNotificationSettingsStoreState = Reducer;

export enum POST_DEBTOR_NOTIFICATION_SETTINGS {
  REQ = 'POST_DEBTOR_NOTIFICATION_SETTINGS_REQ',
  GOT = 'POST_DEBTOR_NOTIFICATION_SETTINGS_GOT',
  ERR = 'POST_DEBTOR_NOTIFICATION_SETTINGS_ERR',
  RESET = 'POST_DEBTOR_NOTIFICATION_SETTINGS_RESET'
}

export interface RequestDataType {
    newBuyerToSupplierLinkEvent: boolean;
    newSupplyContractLinkEvent: boolean;
    newEarlyPaymentApplicationEvent: boolean;
    newApprovedEpaEvent: boolean;
    newSignedEpaEvent: boolean;
    getSignedEpaDocuments: boolean;
}

export const initialState: PostDebtorNotificationSettingsStoreState = {
  status: REQUEST_STATUSES.REQUEST,
  error: {}
};

const postDebtorNotificationSettings = (
  state: PostDebtorNotificationSettingsStoreState = initialState,
  action: {
    type: POST_DEBTOR_NOTIFICATION_SETTINGS;
    error: {};
  }
): PostDebtorNotificationSettingsStoreState => {
  switch (action.type) {
    case POST_DEBTOR_NOTIFICATION_SETTINGS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_DEBTOR_NOTIFICATION_SETTINGS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_DEBTOR_NOTIFICATION_SETTINGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_DEBTOR_NOTIFICATION_SETTINGS.RESET:
        return {
          ...initialState
        };
    default:
      return state;
  }
};

export default postDebtorNotificationSettings;
