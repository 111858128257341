import { POST_CREATE_BUYERS_TO_SUPPLIER_LINK } from '../reducers/postCreateBuyersToSupplierLink';

export interface RequestType {
  supplierInn: string;
  debtorInn: string;
}

export const req = (data: RequestType) => ({
  type: POST_CREATE_BUYERS_TO_SUPPLIER_LINK.REQ,
  data
});

export const got = (data: any) => ({
  type: POST_CREATE_BUYERS_TO_SUPPLIER_LINK.GOT,
  data
});

export const reset = () => ({
  type: POST_CREATE_BUYERS_TO_SUPPLIER_LINK.RESET
});

export const err = (err: {}) => ({
  type: POST_CREATE_BUYERS_TO_SUPPLIER_LINK.ERR,
  err
});
