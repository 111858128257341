import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestDataType } from 'entities/SCF/Debtor/model/actions/SCFRegisterDebtor';

export interface SCFRegisterDebtorStoreState extends Reducer {
  data: RequestDataType;
}

export enum SCF_REGISTER_DEBTOR {
  SCF_REGISTER_DEBTOR_REQ = 'SCF_REGISTER_DEBTOR_REQ',
  SCF_REGISTER_DEBTOR_GOT = 'SCF_REGISTER_DEBTOR_GOT',
  SCF_REGISTER_DEBTOR_ERR = 'SCF_REGISTER_DEBTOR_ERR',
  SCF_REGISTER_DEBTOR_RESET = 'SCF_REGISTER_DEBTOR_RESET'
}

export const initialState: SCFRegisterDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    legalForm: '',
    code: '',
    companyName: '',
    email: '',
    password: ''
  },
  error: {}
};

const SCFRegisterDebtor = (
  state: SCFRegisterDebtorStoreState = initialState,
  action: {
    type: string;
    data: RequestDataType;
    error: {};
  }
): SCFRegisterDebtorStoreState => {
  switch (action.type) {
    case SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default SCFRegisterDebtor;
