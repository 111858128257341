import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendAgentData';

import { SEND_AGENT } from '../reducers/sendAgentData';
import ProductsApi from '../api';

function* sendAgentData(action: { type: SEND_AGENT; data: any }): Generator {
  try {
    let res: any;

    res = yield call(ProductsApi.sendAgentData, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendAgentDataSaga(): Generator {
  yield takeLatest(SEND_AGENT.REQ, sendAgentData);
}
