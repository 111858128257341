import * as React from 'react';

import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';
import { IncorrectAuthModalStyled } from './styled';
import { history } from 'src/shared/utils/History';
import { Button } from 'shared/ui/Button';

const IncorrectAuthModal: React.FC = () => {
  return (
    <Modal name={MODAL_NAMES.INCORRECT_AUTH_DATA_MODAL}>
      <IncorrectAuthModalStyled>
        <h2>Некорректные данные входа</h2>
        <div>
          <p>Ссылка для входа некорректа или истекла.</p>
          <p>Попробуйте авторизоваться снова.</p>
        </div>

        <Button
          type="button"
          label="Вернуться"
          template="nextBtn"
          onClick={() => history.push('/login')}
        />
      </IncorrectAuthModalStyled>
    </Modal>
  );
};

export { IncorrectAuthModal };
