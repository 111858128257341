import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { APPLICATION_TYPE_ROUTES, APPLICATION_TYPES } from 'Application/types';

import {
  ViewSeparatorBottom,
  EditApplicationBtn
} from 'Application/components/ApplicationView/EachApplicationView/styles';
import { USER_PERMISSIONS } from 'src/globaltypes';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { IApplicationDocuments } from '../EachApplicationView';
import { BorrowerCompanyDocumentsBlock } from './BorrowerCompanyDocumentsBlock/BorrowerCompanyDocumentsBlock';
import { CompanyDocumentsBlock } from './CompanyDocumentsBlock/CompanyDocumentsBlock';
import { GeneralDocumentsBlock } from './GeneralDocumentsBlock/GeneralDocumentsBlock';

interface OwnProps {
  application: ResInternalApplication;
  permissions: USER_PERMISSIONS[];
  documentList: ResDocumentListByApplicationId;
}

type Props = RouteComponentProps & IApplicationDocuments & OwnProps;

const DocumentsBlock: React.FC<Props> = ({
  application,
  documents,
  permissions,
  documentList,
  history
}) => (
  <React.Fragment>
    <GeneralDocumentsBlock documents={documents.general} />

    {/* Factoring Documents - Table */}
    {application.financingType === APPLICATION_TYPES.FACTORING && (
      <React.Fragment>
        <ViewSeparatorBottom>
          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <EditApplicationBtn
              onClick={() =>
                history.push(
                  `/cabinet/internal/application-form-${APPLICATION_TYPE_ROUTES[
                    application.financingType
                  ].replace('_', '-')}/edit/${application.id}/documents`
                )
              }
            >
              Редактировать документы
            </EditApplicationBtn>
          )}
        </ViewSeparatorBottom>

        {/* TODO refactor whole eachApplication block
              * documents.company in this case is application documents
            */}
        <CompanyDocumentsBlock
          documents={documents.company}
          applicationId={application.id}
        />
      </React.Fragment>
    )}

    {/* Other Documents - Table in Tabs */}
    {/* TODO refactor */}
    {![
      APPLICATION_TYPES.FACTORING,
      APPLICATION_TYPES.PROPERTY_FINANCING
    ].includes(application.financingType) && (
      <BorrowerCompanyDocumentsBlock
        companies={documents.company}
        permissions={permissions}
        documentList={documentList}
      />
    )}
  </React.Fragment>
);

const DocumentsBlockConnect = withRouter(DocumentsBlock);

export { DocumentsBlockConnect as DocumentsBlock };
