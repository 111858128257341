import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/SCF/actions/putMonetaryClaimDeleteToAuction';

import { PUT_MONETARY_CLAIM_DELETE_TO_AUCTION } from 'src/features/SCF/reducers/putMonetaryClaimDeleteToAuction';

import SCFApi from 'src/features/SCF/api';

function* putMonetaryClaimDeleteToAuction(action: {
  type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION;
  guid: string;
  auction_guid: string;
}) {
  try {
    yield call(
      SCFApi.putMonetaryClaimDeleteToAuction,
      action.guid,
      action.auction_guid
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putMonetaryClaimDeleteToAuctionSaga() {
  yield takeLatest(
    PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.REQ,
    putMonetaryClaimDeleteToAuction
  );
}
