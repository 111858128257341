import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postUploadFinDocInternal';

import { POST_UPLOAD_FIN_DOC_INTERNAL } from '../reducers/postUploadFinDocInternal';
import DocumentListApi from '../api';

function* postUploadFinDocInternal(action: {
  type: POST_UPLOAD_FIN_DOC_INTERNAL;
  data: FormData;
  guid: string;
}): Generator {
  try {
    yield call(
      DocumentListApi.postUploadFinDocInternal,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postUploadFinDocInternalSaga(): Generator {
  yield takeLatest(POST_UPLOAD_FIN_DOC_INTERNAL.REQ, postUploadFinDocInternal);
}
