import { call, put, select, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/deletePropertyDocument';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import PropertiesApi from '../api';
import { Document } from 'Application/types';

import {
  DELETE_PROPERTY_DOCUMENT,
} from '../reducers/deletePropertyDocument';
import { DOCUMENT_DOWNLOAD_TYPE, DOCUMENT_UPLOAD_TYPE } from 'src/features/Application/types';
import { updateLocalProperty } from '../actions/localProperties';
import { STORE } from 'src/globaltypes';

function* deletePropertyDocument(action: {
  type: DELETE_PROPERTY_DOCUMENT;
  fileId: number;
  propertyId: number;
}): Generator {
  try {

    yield put(deleteDocument(
      {
        id: action.fileId,
        type: DOCUMENT_DOWNLOAD_TYPE.PROPERTY_FINANCING_PROPERTY,
      }))

    const oldDocuments = (yield select(
      ({ Properties }: STORE) =>
        Properties.localProperties.properties.find(p => p.id === action.propertyId).documents
    )) as Document[];

    yield put(updateLocalProperty({ id: action.propertyId, documents: oldDocuments.filter(doc => doc.id !== action.fileId)}))

    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* deletePropertyDocumentSaga(): Generator {
  yield takeLatest(DELETE_PROPERTY_DOCUMENT.REQ, deletePropertyDocument);
}
