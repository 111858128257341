import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeletePropertyStoreState extends Reducer {
}

export enum DELETE_PROPERTY {
  REQ = 'DELETE_PROPERTY_REQ',
  GOT = 'DELETE_PROPERTY_GOT',
  ERR = 'DELETE_PROPERTY_ERR'
}

export const initialState: DeletePropertyStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deleteProperty = (
  state: DeletePropertyStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): DeletePropertyStoreState => {
  switch (action.type) {
    case DELETE_PROPERTY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_PROPERTY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case DELETE_PROPERTY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteProperty;
