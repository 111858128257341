import styled from 'styled-components';
import { blue } from 'shared/styled';

export const BankAccountsFormStyled = styled.section`
  margin-top: 25px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BankAccountsTitle = styled.p`
  color: #000000;
  font-size: 20px;
`;

export const BankAccountsTitles = styled.div`
  width: 700px;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const BankAccountsTitlesItem = styled.p`
  width: 120px;
  color: #7a7d83;
`;

export const AddButton = styled.div`
  margin-top: 15px;
  width: auto;
  height: auto;
  display: flex;

  justify-content: center;
  background-color: ${blue};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #005db1;
  }
  p {
    color: #ffffff;
    padding: 0 15px 0 15px;
  }
`;
