export interface DateFilterData {
  dateFrom: string;
  dateUntil: string;
}

export enum DATE_ACTION_TYPES {
  SET_DATE_FROM = 'SET_DATE_FROM',
  SET_DATE_TO = 'SET_DATE_TO'
}

export const initialState: DateFilterData = {
  dateFrom: '',
  dateUntil: ''
};

const updateDateFilter = (
  state: DateFilterData = initialState,
  action: {
    type: DATE_ACTION_TYPES;
    dateFrom: string;
    dateUntil: string;
  }
): DateFilterData => {
  switch (action.type) {
    case DATE_ACTION_TYPES.SET_DATE_FROM:
      return { ...state, ...action };
    case DATE_ACTION_TYPES.SET_DATE_TO:
      return { ...state, ...action };
    default:
      return state;
  }
};

export default updateDateFilter;
