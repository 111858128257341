import * as React from 'react';
import { SidebarStyled, NavList, SidebarInner } from './styles';

interface Props {
  children: any;
}

export const Sidebar: React.FC<Props> = ({ children }: Props) => (
  <SidebarStyled>
    <SidebarInner>
      <NavList>
        <ul>
          {Array.isArray(children) ? (
            children.map((child, key) => <li key={key}>{child}</li>)
          ) : (
            <li>{children}</li>
          )}
        </ul>
      </NavList>
    </SidebarInner>
  </SidebarStyled>
);
