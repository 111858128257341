import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';

import { history } from 'src/shared/utils/History';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { PledgeType } from 'shared/constants';

import { STORE } from 'globaltypes';

import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { InputRange } from 'shared/ui/InputRange';
import { TRANSITION_DIRECTIONS } from 'Common';

import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle
} from '../../styles';
import { DebtorsTable, ApplicationBtnsWrapperStyled } from './styles';
import { Button } from 'shared/ui/Button';

interface State {
  submitClicked: boolean;
}

interface OwnProps {
  path: string;
}

interface WithRouterProps {
  match: any;
}

interface StateToProps extends SetFactoringApplicationType {
  id: number;
  amount: number;
  term: number;
}

interface DispatchToProps {
  setFactoringFormData: (data: SetFactoringApplicationType) => void;
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  openModal: (data: OpenModalDataType) => void;
}

type Props = OwnProps & WithRouterProps & StateToProps & DispatchToProps;

class FactoringFormStep2 extends React.Component<Props, State> {
  Validator = createValidaton(this.props.debtors);

  state = {
    submitClicked: false
  };

  componentWillUnmount() {
    const { sendApplicationDataReq, id, debtorType, debtors } = this.props;

    sendApplicationDataReq({
      id,
      factoring: {
        debtors,
        debtorType
      }
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    const debtorIndex = parseInt(name.split('_')[0], 10);
    const debtorKey = name.split('_')[1];

    const isToNumber = debtorKey === 'limit' || debtorKey === 'postponement';

    const newDebtors = this.props.debtors.reduce((arr, item, index) => {
      if (index === debtorIndex) {
        item[debtorKey] = isToNumber ? toFloatFormatter(value) : value;
      }

      return [...arr, item];
    }, []);

    this.props.setFactoringFormData({ debtors: newDebtors } as {
      [key in keyof SetFactoringApplicationType]: any
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    history.push({
      pathname: this.navigateFromTo(this.props.path, 'documents'),
      state: TRANSITION_DIRECTIONS.FORWARD
    });
  };

  formTableRowsData = errors =>
    this.props.debtors.map((item, index) => [
      item.name,
      <InputRange
        key={index}
        value={item.limit.toString()}
        name={`${index}_limit`}
        placeholder="Введите лимит"
        min={0}
        max={this.props.amount}
        step={1000}
        sliderType={RANGE_SLIDER_TYPE.SUM}
        valueType={INPUT_RANGE_VALUE_TYPE.SUM}
        error={errors[`${index}_limit`]}
        onChange={this.onChange}
      />,
      <InputRange
        key={index}
        value={item.postponement.toString()}
        name={`${index}_postponement`}
        placeholder="Введите отсрочку"
        min={1}
        max={this.props.term}
        step={1}
        sliderType={RANGE_SLIDER_TYPE.TERM}
        valueType={INPUT_RANGE_VALUE_TYPE.TERM}
        onChange={this.onChange}
      />,
      <RadioGroup
        key={index}
        name={`${index}_pledgeType`}
        keyValue={item.pledgeType}
        onChange={this.onChange}
        radioBtns={Object.keys(PledgeType).map(type => ({
          value: type,
          label: PledgeType[type]
        }))}
      />
    ]);

  render() {
    const { openModal, amount, debtors } = this.props;

    const { errors } = this.Validator.insertArgs({
      amountRange: [-1, amount]
    }).validate(
      debtors.reduce((fieldsObj, debtor, index) => {
        fieldsObj[`${index}_limit`] = debtor.limit;
        return fieldsObj;
      }, {})
    );

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>Запрашиваемые лимиты</ApplicationStepTitle>

        <DebtorsTable
          headers={[
            'Дебитор',
            'Лимит на дебитора',
            'Отсрочка по договору',
            'Наличие залога'
          ]}
          rows={this.formTableRowsData(errors)}
          sizes={['20%', '30%', '30%', '20%']}
        />
        <ApplicationBtnsWrapperStyled>
          <Button
            template="backBtn"
            label="Назад"
            onClick={() =>
              history.push({
                pathname: this.navigateFromTo('debtors', 'options'),
                state: TRANSITION_DIRECTIONS.BACKWARDS
              })
            }
          />
          <Button
            template="nextBtn"
            label="Продолжить"
            onClick={this.onSubmit}
          />
        </ApplicationBtnsWrapperStyled>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  id: Application.setInitializationFormData.id,
  amount: Application.setInitializationFormData.amount,
  term: Application.setInitializationFormData.term,
  debtorType: Application.setFactoringFormData.debtorType,
  debtors: Application.setFactoringFormData.debtors
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setFactoringFormData, sendApplicationDataReq, openModal },
    dispatch
  );

const FactoringFormStep2Connect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep2)
);

export { FactoringFormStep2Connect as FactoringFormStep2 };
