import styled from 'styled-components';
import { blue, green, lighterBlue, navyBlue, red, rr } from 'shared/styled';
import { Button } from 'shared/ui/Button';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';

export const ContractsInfoPageStyled = styled.section`
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const ContractsTopSection = styled.section`
  width: 1100px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContractsTopLeftContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContractsContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContractsInfoArea = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: 900;
    color: #000;
  }
`;

export const ContractsHeader = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  margin-bottom: 30px;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin: 0;
  }
`;

export const ContractsInfoItemContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin-right: 20px;
    margin: 0 20px 0 0;
  }
  input {
    padding: 10px 10px;
    height: 30px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};

    &:focus-visible {
      border: 1px solid transparent;
      outline: 2px solid rgba(127, 194, 254, 1) !important;
    }
  }
`;

export const ContractsInfoItemTitle = styled.p`
  font-family: ${rr};
  font-size: 1.3em;
  width: 350px;
`;

export const MovingContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid ${blue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${blue};
  }
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const ButtonSubmit = styled(Button)`
  width: fit-content;
  height: 30px;
`;

export const TableStyledWrapper = styled(TableStyled)`
  table-layout: auto;
`;

export const AccountContainer = styled.div`
  margin-bottom: 20px;

  button {
    margin-left: 20px;
  }

  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin-left: 20px;
  }

  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 15px;
    font-weight: 900;
    color: #000;
  }
`;

export const AccountInfo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 100px auto;
  margin-bottom: 20px;

  p {
    width: fit-content;
    margin: 0;
  }
`;

export const DiscountingFormModal = styled.div`
  p {
    font-size: 18px;
    white-space: nowrap;
  }

  & > label {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 30px;
    margin: 0 0 20px 30px;
    padding: 5px;

    &:hover {
      background-color: ${lighterBlue};
    }

    & > div {
      display: flex;
      align-items: center;
      column-gap: 30px;
    }

    p {
      margin: 0;
    }

    a {
      width: fit-content;
      cursor: pointer;
      color: ${blue};
      font-family: ${rr};
      font-size: 18px;
    }
  }

  & > button {
    width: fit-content;
    height: 40px;
    margin: 30px 0 0 20px;
  }
`;

export const PayerContractDescription = styled.div`
  margin-top: 10px;
  font-family: ${rr};

  span {
    cursor: pointer;
    text-decoration: underline;
    color: ${blue};
  }
`;

export const AccountInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 30px;

  & > div:first-of-type {
    margin-top: 10px;
  }

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    & > div:first-of-type {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 5px;

      button {
        width: fit-content;
        height: 45px;
        margin: 0;

        &:first-child {
          background-color: ${green};
        }
        &:last-child {
          background-color: ${red};
        }
      }
    }
  }
`;

export const ConfirmedStatus = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  font-family: ${rr};
  font-size: 18px;
  width: 210px;
  color: ${navyBlue};
`;
