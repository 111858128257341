import styled from 'styled-components';
import {
  blue,
  rr,
} from 'shared/styled';

export const SwitcherContainer = styled.div`
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  background-color: #FAFAFA;
  border-radius: 10px;
`;

export const UnchangableOffActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f53737;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #f53737;
  border-left: 1px solid #f53737;
  border-bottom: 1px solid #f53737;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
  }
`;

export const ChangableOffActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f53737;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #f53737;
  border-left: 1px solid #f53737;
  border-bottom: 1px solid #f53737;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
  }
`;

export const UnchangableOnInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  background-color: #FDFDFD;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
  }
`;

export const ChangableOnInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  background-color: #FDFDFD;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
  }
  &:hover {
    border-top: 1px solid ${blue};
    border-right: 1px solid ${blue};
    border-bottom: 1px solid ${blue};
    border-left: 1px solid ${blue};
  }
  &:hover p {
    color: ${blue};
  }
`;

export const UnchangableOnActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #1cca94;
  border-right: 1px solid #1cca94;
  border-bottom: 1px solid #1cca94;
  background-color: #1cca94;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
  }
`;

export const ChangableOnActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #1cca94;
  border-right: 1px solid #1cca94;
  border-bottom: 1px solid #1cca94;
  background-color: #1cca94;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #ffffff;
  }
`;

export const UnchangableOffInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background-color: #FDFDFD;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
  }
`;

export const ChangableOffInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background-color: #FDFDFD;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1em;
    color: #d9d9d9;
  }
  &:hover {
    border-top: 1px solid ${blue};
    border-right: 1px solid ${blue};
    border-bottom: 1px solid ${blue};
    border-left: 1px solid ${blue};
  }
  &:hover p {
    color: ${blue};
  }
`;
