import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err,
  SelectPeriodRequest
} from '../actions/getExternalAgentSelectPeriod';

import { GET_EXTERNAL_AGENT_SELECT_PERIOD } from '../reducers/getExternalAgentSelectPeriod';
import FinancialStatementsApi from '../api';

function* getExternalAgentSelectPeriod(action: {
  type: GET_EXTERNAL_AGENT_SELECT_PERIOD;
  inn: string;
  data: SelectPeriodRequest[];
}): Generator {
  try {
    const res: any = yield all(
      action.data.map(item =>
        call(
          FinancialStatementsApi.getExternalAgentSelectPeriod,
          action.inn,
          item
        )
      )
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalAgentSelectPeriodSaga(): Generator {
  yield takeEvery(
    GET_EXTERNAL_AGENT_SELECT_PERIOD.REQ,
    getExternalAgentSelectPeriod
  );
}
