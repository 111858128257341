import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { DOCUMENT_UPLOAD_TYPE } from 'Application/types';

import {
  FactoringApplicationRead,
  GuaranteesApplicationRead,
  CreditingApplicationRead,
  FinancingApplicationRead
} from 'Application/types';

export interface RequestDataType {
  id: number;
}

export interface DocumentsRequestDataType extends RequestDataType {
  type: DOCUMENT_UPLOAD_TYPE;
}

// TODO: get rid of 'any' type
export type ResponseDataType = Partial<
  | FactoringApplicationRead
  | GuaranteesApplicationRead
  | CreditingApplicationRead
  | FinancingApplicationRead
>;

export interface GetApplicationStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_APPLICATION {
  REQ = 'GET_APPLICATION_REQ',
  GOT = 'GET_APPLICATION_GOT',
  ERR = 'GET_APPLICATION_ERR',
  RESET = 'GET_APPLICATION_RESET'
}

export const initialState: GetApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

export const getApplication = (
  state: GetApplicationStoreState = initialState,
  action: {
    type: GET_APPLICATION;
    data: ResponseDataType;
    error: {};
  }
): GetApplicationStoreState => {
  switch (action.type) {
    case GET_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_APPLICATION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
