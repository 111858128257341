import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestDataType } from 'ExternalAgent/actions/externalAgentRegister';

export default class ExternalAgentApi {
  static register(data: RequestDataType) {
    return ApiRequest('/api/account/externalagents/register', 'post', data);
  }

  static getStatus() {
    return ApiRequest('/api/externalagents/status');
  }
}
