import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { inRangeRule, validateINN } from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

export const createValidaton = () =>
  new Validator<SetInitializationApplicationType>({
    clientCompanyName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    code: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      },
      {
        rule: value => {
          if (value && value.length < 10) {
            return;
          }
          return validateINN(value);
        },
        message: ValidatorMessages.codeWarning
      }
    ],
    customerInn: [
      {
        rule: (value, formData) => {
          if (value && value.length < 10) {
            return;
          }
          return validateINN(value);
        },
        message: ValidatorMessages.codeWarning
      }
    ],
    amount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    termEndDate: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    termStartDate: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
