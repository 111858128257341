import * as React from 'react';
import { List, Title, ListItem } from './styles';

export const CreditingPledgeHint: React.FC = () => (
  <React.Fragment>
    <Title>Укажите дополнительные виды обеспечения, например:</Title>
    <List>
      <ListItem>Гарантии МСП</ListItem>
      <ListItem>Страхование коммерческих рисков</ListItem>
      <ListItem>
        Поручительство региональных фондов поддержки предпринимательства
      </ListItem>
    </List>
  </React.Fragment>
);
