import { POST_CREATE_FOR_LEAD } from '../reducers/postCreateForLead';

export const req = (id: string) => ({
  type: POST_CREATE_FOR_LEAD.REQ,
  id
});

export const got = () => ({
  type: POST_CREATE_FOR_LEAD.GOT
});

export const err = (error: {}) => ({
  type: POST_CREATE_FOR_LEAD.ERR,
  error
});
