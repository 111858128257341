import styled from "styled-components";
import { blue, lightGray, lighterGray } from "shared/styled";

export const DifferenceBetweenLeadsAndApplicationsInfoTrigger = styled.p`
cursor:pointer;
text-align:center;
color: ${lightGray};
svg{
 color: ${blue}
}
`
export const DifferenceBetweenLeadsAndApplicationsInfoContainer = styled.div`
background-color: white;
padding:15px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
`
