import { GET_FINANCIAL_DOCUMENTS_CLIENT } from 'entities/Cabinet/Client/model/reducers/getFinancialDocuments';

export interface ResponseData {
  guid: string;
  companyInn: string;
  typeId: number;
  taxPeriodId: string;
  taxYear: number;
  periodStartDate: null;
  periodEndDate: null;
  taxPeriod: string;
  documentName: string;
  documentFileName: string;
  documentFileIdentifyer: any;
  signatureFileName: string;
  signatureFileIdentifyer: any;
}

export const req = (inn: string) => ({
  type: GET_FINANCIAL_DOCUMENTS_CLIENT.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_FINANCIAL_DOCUMENTS_CLIENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FINANCIAL_DOCUMENTS_CLIENT.ERR,
  error
});
