import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/postAddFormToSupplyContracts';

import { POST_ADD_FORM_TO_SUPPLY_CONTRACTS } from 'entities/SCF/Debtor/model/reducers/postAddFormToSupplyContracts';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* postAddFormToSupplyContracts(action: {
  type: POST_ADD_FORM_TO_SUPPLY_CONTRACTS;
  data: RequestDataType;
}) {
  try {
    yield call(SCFDebtorApi.postAddFormToSupplyContracts, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddFormToSupplyContractsSaga() {
  yield takeLatest(
    POST_ADD_FORM_TO_SUPPLY_CONTRACTS.REQ,
    postAddFormToSupplyContracts
  );
}
