import * as React from 'react';

import { InputPassword } from 'shared/ui/InputPassword';
import { PasswordHint } from 'shared/ui/Hint';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { Button } from 'shared/ui/Button';

import { createValidation } from './validator';

export interface ResetPasswordFields {
  password: string;
  repeatPassword: string;
}

interface State extends ResetPasswordFields {
  submitClicked: boolean;
}

interface Props {
  onSubmit: (password: string) => void;
}

export class ResetPasswordForm extends React.Component<Props, State> {
  Validator = createValidation();
  state: State = {
    password: '',
    repeatPassword: '',
    submitClicked: false
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    this.setState({
      [name]: value
    } as { [key in keyof ResetPasswordFields]: string });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    this.props.onSubmit(this.state.password);
  };

  render() {
    const { password, repeatPassword } = this.state;

    const { errors, fields } = this.Validator.insertArgs({
      passwordEqual: [password]
    }).validate(this.state);

    return (
      <Form name="reset-password-form" onSubmit={this.onSubmit}>
        <FormSection>
          <InputPassword
            value={password}
            label="Новый пароль"
            name="password"
            placeholder="Введите новый пароль"
            required={true}
            error={errors.password}
            onChange={this.onChange}
            hint={{
              validatable: true,
              position: 'left',
              component: (
                <PasswordHint validationStatuses={fields.password.statuses} />
              )
            }}
          />
          <InputPassword
            value={repeatPassword}
            name="repeatPassword"
            label="Подтвердите пароль"
            placeholder="Подтвердите новый пароль"
            required={true}
            error={errors.repeatPassword}
            onChange={this.onChange}
          />
        </FormSection>

        <Button type="submit" label="Сохранить изменения" template="nextBtn" />
      </Form>
    );
  }
}
