import { GET_MONETARY_CLAIMS_INTERNAL_AGENT } from 'entities/SCF/Internal/model/reducers/getMonetaryClaimsInternalAgent';

export interface RequestData {
  page: number;
  pageSize: number;
  startDate?: string;
  endDate?: string;
  debtorInn?: string;
  supplierInn?: string;
  status?: string;
}

export interface MonetaryClaimsInternalAgent {
  id: number;
  debtorInn: string;
  debtorName: string;
  creditorInn: string;
  creditorName: string;
  currencyID: number;
  currencyCode: string;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  baseFullSumm: number;
  emergenceDate: string;
  maturityDate: string;
  paidStatus: number;
  cessionProfileId: number;
  cessedMonetaryClaimID: number;
  monetaryClaimId: string;
  lastRegistryId: number;
  cessedMonetaryClaimGuid: string;
  baseMonetaryClaimId: number;
  baseMonetaryClaimGuid: string;
  cessionProfileGuid: string;
  guid: string;
  isDeleted: boolean;
  firstDebtorRegistryOccurence: number;
  lastDebtorRegistryOccurence: number;
  supplyContractNumber: string;
  invoiceDocumentName: string;
  invoiceDocumentNumber: string;
  invoiceDocumentDate: string;
  status: string;
  earlyPaymentAvailable: boolean;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  fullSum: number;
  availableChecks: number;
  items: MonetaryClaimsInternalAgent[];
}

export const req = (data: RequestData) => ({
  type: GET_MONETARY_CLAIMS_INTERNAL_AGENT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_MONETARY_CLAIMS_INTERNAL_AGENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIMS_INTERNAL_AGENT.ERR,
  error
});
