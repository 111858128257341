import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { inRangeRule } from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetContractFinancingFormData } from 'Application/reducers/setContractFinancingFormData';
import {
  CONTRACT_FINANCING_TYPE,
  CONTRACT_PREPAYMENT_TYPE
} from 'src/features/Application/types';

export const createValidaton = () =>
  new Validator<SetContractFinancingFormData>({
    contractType: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    purchaseLink: [
      {
        rule: (value, contractType) => {
          if (
            Object.values(CONTRACT_FINANCING_TYPE)
              .without([CONTRACT_FINANCING_TYPE.COMMERCIAL])
              .includes(contractType)
          )
            return requiredRule(value);
          return true;
        },
        message: ValidatorMessages.requiredField,
        ruleId: 'contractType'
      }
    ],
    contractAmount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    prepaymentAmount: [
      {
        rule: (value, prepaymentType) => {
          if (prepaymentType === CONTRACT_PREPAYMENT_TYPE.HAS_PREPAYMENT)
            return requiredRule(value); // TODO fix inrange instead of required
          return true;
        },
        message: ValidatorMessages.invalidValue,
        ruleId: 'prepaymentType'
      }
    ],
    contractSubject: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    prepaymentType: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
