import { SIGNIN_BY_LINK } from 'User/reducers/signInByLink';

export const req = (key: string) => ({
  type: SIGNIN_BY_LINK.REQ,
  key
});

export const got = (refreshToken: string) => ({
  type: SIGNIN_BY_LINK.GOT,
  refreshToken
});

export const err = (error: {}) => ({
  type: SIGNIN_BY_LINK.ERR,
  error
});
