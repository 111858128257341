import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchSetForbiddenRegion';

import { PATCH_SET_FORBIDDEN_REGION } from '../reducers/patchSetForbiddenRegion';
import CustomerRequirementsApi from '../api';

function* patchSetForbiddenRegion(action: {
  type: PATCH_SET_FORBIDDEN_REGION;
  id: string;
  region: string;
}): Generator {
  try {
    yield call(
      CustomerRequirementsApi.patchSetForbiddenRegion,
      action.id,
      action.region
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchForbiddenCustomerRequirementsSaga(): Generator {
  yield takeLatest(PATCH_SET_FORBIDDEN_REGION.REQ, patchSetForbiddenRegion);
}
