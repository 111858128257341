import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getTenders,
  ResponseData as TendersData
} from 'src/features/Tenders/actions/getTenders';
import { Menu } from 'src/features/Layouts/components';
import { TendersList } from 'src/features/Tenders/components/TendersList/TendersList';
import { TENDERS_TAB } from '../types';
import { NoData } from './styled';

interface StateToProps {
  tenders: TendersData;
  statusTenders: REQUEST_STATUSES;
  errorTenders: ResponseError;
}

interface DispatchToProps {
  getTenders: (data?) => void;
}

interface OwnProps {
  isCustomer44: boolean;
  isCustomer223: boolean;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

const TendersForCompanies: React.FC<Props> = ({
  getTenders,
  tenders,
  statusTenders,
  errorTenders,
  isCustomer44,
  isCustomer223,
  history,
  match,
  location
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(() => {
    initFetch();
  }, []);

  React.useEffect(
    () => {
      if (activeTab === 'DEFAULT') {
        getTenders({ supplierInn: match.params.inn });
      } else {
        getTenders({ customerInn: match.params.inn });
      }
    },
    [activeTab]
  );

  React.useEffect(
    () => {
      if (isCustomer44 || isCustomer223) {
        setActiveTab('CUSTOMER');
      } else {
        setActiveTab('DEFAULT');
        if (match.params.applicationTab) {
          const { pathname } = location;
          const baseUrl = pathname.replace(
            `/${match.params.applicationTab.toLowerCase()}`,
            ''
          );
          history.replace(baseUrl);
        }
      }
    },
    [isCustomer44, isCustomer223]
  );

  const initFetch = () => {
    if (match.params.applicationTab) {
      setActiveTab(match.params.applicationTab.toUpperCase());
    } else if (match.params.tab === 'customer') {
      setActiveTab(match.params.tab.toUpperCase());
    }
  };

  const handleActiveTab = (tabName: string) => {
    const { pathname } = location;
    const baseUrl = pathname.replace(`/${activeTab.toLowerCase()}`, '');

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={TENDERS_TAB}
        isSubMenu
      />

      {activeTab === 'DEFAULT' &&
        (tenders.items.length ? (
          <TendersList
            tenders={tenders}
            status={statusTenders}
            error={errorTenders}
          />
        ) : (
          <NoData>
            <h3>НЕТ ДАННЫХ</h3>
            <p>на платформе пока нет данных об участии компании в закупках</p>
          </NoData>
        ))}

      {activeTab === 'CUSTOMER' &&
        (tenders.items.length ? (
          <TendersList
            tenders={tenders}
            status={statusTenders}
            error={errorTenders}
          />
        ) : (
          <NoData>
            <h3>НЕТ ДАННЫХ</h3>
            <p>на платформе пока нет данных о закупках компании</p>
          </NoData>
        ))}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Tenders }: CRM) => ({
  tenders: Tenders.getTenders.data,
  statusTenders: Tenders.getTenders.status,
  errorTenders: Tenders.getTenders.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getTenders }, dispatch);

const TendersForCompaniesConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TendersForCompanies)
);

export { TendersForCompaniesConnect as TendersForCompanies };
