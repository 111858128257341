import ApiRequest from 'src/shared/utils/ApiRequest';

export default class CustomerRequirementsForProductsApi {
  static patchCustomerRequirements(id: string, data: any) {
    return ApiRequest(`/api/product/id/${id}`, 'patch', data);
  }

  static patchCabinetCustomerRequirements(guid: string, data: any) {
    return ApiRequest(`/api/bank_role/product/id/${guid}`, 'patch', data);
  }

  static patchSetForbiddenRegion(id: string, region: string) {
    return ApiRequest(
      `/api/product/${id}/set_region_forbidden/${region}`,
      'patch'
    );
  }

  static patchSetAvailableRegion(id: string, region: string) {
    return ApiRequest(
      `/api/product/${id}/set_region_available/${region}`,
      'patch'
    );
  }

  static patchCabinetSetForbiddenRegion(id: string, region: string) {
    return ApiRequest(
      `/api/bank_role/product/${id}/set_region_forbidden/${region}`,
      'patch'
    );
  }

  static patchCabinetSetAvailableRegion(id: string, region: string) {
    return ApiRequest(
      `/api/bank_role/product/${id}/set_region_available/${region}`,
      'patch'
    );
  }

  static patchCabinetSetAvailableRegionAll(id: string) {
    return ApiRequest(
      `/api/bank_role/product/${id}/set_region_available/all`,
      'patch'
    );
  }

  static patchCabinetSetForbiddenRegionAll(id: string) {
    return ApiRequest(
      `/api/bank_role/product/${id}/set_region_forbidden/all`,
      'patch'
    );
  }

  static patchSetAvailableRegionAll(id: string) {
    return ApiRequest(`/api/product/${id}/set_region_available/all`, 'patch');
  }

  static patchSetForbiddenRegionAll(id: string) {
    return ApiRequest(`/api/product/${id}/set_region_forbidden/all`, 'patch');
  }
}
