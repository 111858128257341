import { combineReducers } from 'redux';

import debtorRegister, {
  SCFRegisterDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/SCFRegisterDebtor';
import uploadRegistries, {
  UploadRegistriesStoreState
} from 'entities/SCF/Debtor/model/reducers/uploadRegistries';
import getDebtorRegistryFiles, {
  GetDebtorRegistryFilesStoreState
} from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFiles';
import sendSignature, {
  SendSignatureStoreState
} from 'entities/SCF/Debtor/model/reducers/sendSignature';
import getSubRegistries, {
  GetSubRegistriesStoreState
} from 'entities/SCF/Debtor/model/reducers/getSubRegistries';
import sendSubSignature, {
  SendSubSignatureStoreState
} from 'entities/SCF/Debtor/model/reducers/sendSubSignature';
import getFactoringContractsDebtorRole, {
  GetFactoringContractsDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/getFactoringContractsDebtorRole';
import getFactoringContractDebtorRoleByGuid, {
  GetFactoringContractDebtorRoleByGuidStoreState
} from 'entities/SCF/Debtor/model/reducers/getFactoringContractDebtorRoleByGuid';
import getEarlyPaymentApplicationsDebtorRole, {
  GetEarlyPaymentApplicationsDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/getEarlyPaymentApplicationsDebtorRole';
import putEarlyPaymentApplicationDecline, {
  PutEarlyPaymentApplicationDeclineStoreState
} from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';
import postAgreementSignatureDebtorRole, {
  PostAgreementSignatureDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/postAgreementSignatureDebtorRole';
import getOneDebtorRoleByGuid, {
  GetOneDebtorRoleByGuidStoreState
} from 'entities/SCF/Debtor/model/reducers/getOneDebtorRoleByGuid';
import getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid, {
  GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState
} from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import getFactoringLimitByDebtorInn, {
  GetFactoringLimitByDebtorInnStoreState
} from 'entities/SCF/Debtor/model/reducers/getFactoringLimitByDebtorInn';
import getDebtorRegistryFileSettingsCurrent, {
  GetDebtorRegistryFileSettingsCurrentStoreState
} from 'entities/SCF/Debtor/model/reducers/getDebtorRegistryFileSettingsCurrent';
import uploadDebtorContractDocument, {
  UploadDebtorContractDocumentStoreState
} from 'entities/SCF/Debtor/model/reducers/uploadDebtorContractDocument';
import putPurchaseContractSignatureDebtorRole, {
  PutPurchaseContractSignatureDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/putPurchaseContractSignatureDebtorRole';
import postDebtorNotificationSettings, {
  PostDebtorNotificationSettingsStoreState
} from 'entities/SCF/Debtor/model/reducers/postDebtorNotificationSettings';
import approveEarlyPaymentApplicationDebtorRole, {
  ApproveEarlyPaymentApplicationDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';
import getDocumentForms, {
  GetDocumentFormsStoreState
} from 'entities/SCF/Debtor/model/reducers/getDocumentForms';
import postConfirmContractByDebtor, {
  PostConfirmContractByDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/postConfirmContractByDebtor';
import getByMonetaryClaimDebtor, {
  GetByMonetaryClaimDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/getByMonetaryClaimDebtor';
import getDebtorCounterSidebar, {
  GetDebtorCounterSidebarStoreState
} from 'entities/SCF/Debtor/model/reducers/getDebtorCounterSidebar';
import getStartRateDebtorRole, {
  GetStartRateDebtorRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/getStartRateDebtorRole';
import postAddFormToSupplyContracts, {
  PostAddFormToSupplyContractsStoreState
} from 'entities/SCF/Debtor/model/reducers/postAddFormToSupplyContracts';
import patchSetDiscountMargin, {
  PatchSetDiscountMarginStoreState
} from 'entities/SCF/Debtor/model/reducers/patchSetDiscountMargin';
import getDebtorNotificationSettings, {
  GetDebtorNotificationSettingsStoreState
} from 'entities/SCF/Debtor/model/reducers/getDebtorNotificationSettings';
import getContractByDebtor, {
  GetContractByDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/getContractByDebtor';
import getDocumentFormBySupplyContract, {
  GetDocumentFormBySupplyContractStoreState
} from 'entities/SCF/Debtor/model/reducers/getDocumentFormBySupplyContract';
import getMonetaryClaimsSuppliesRole, {
  GetMonetaryClaimsSuppliesRoleStoreState
} from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsSuppliesRole';
import getDebtorSignatureFile, {
  GetDebtorSignatureFileStoreState
} from 'entities/SCF/Debtor/model/reducers/getDebtorSignatureFile';
import putRejectDebtor, {
  PutRejectDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/putRejectDebtor';
import putRejectSupplier, {
  PutRejectSupplierStoreState
} from 'entities/SCF/Debtor/model/reducers/putRejectSupplier';
import putAcceptByDebtor, {
  PutAcceptByDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/putAcceptByDebtor';
import putDeclineByDebtor, {
  PutDeclineByDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/putDeclineByDebtor';
import getFormFile, {
  GetFormFileStoreState
} from 'entities/SCF/Debtor/model/reducers/getFormFile';
import patchPurchaseContractDebtor, {
  PatchPurchaseContractDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/patchPurchaseContractDebtor';
import postAddToContractByDebtor, {
  PostAddToContractByDebtorStoreState
} from 'entities/SCF/Debtor/model/reducers/postAddToContractByDebtor';
import signedEarlyPaymentApplication, {
  SignedEarlyPaymentApplicationStoreState
} from 'entities/SCF/Debtor/model/reducers/signedEarlyPaymentApplication';

export interface SCFDebtorReducers {
  getDebtorCounterSidebar: GetDebtorCounterSidebarStoreState;
  debtorRegister: SCFRegisterDebtorStoreState;
  uploadRegistries: UploadRegistriesStoreState;
  getDebtorRegistryFiles: GetDebtorRegistryFilesStoreState;
  sendSignature: SendSignatureStoreState;
  getSubRegistries: GetSubRegistriesStoreState;
  sendSubSignature: SendSubSignatureStoreState;
  getFactoringContractsDebtorRole: GetFactoringContractsDebtorRoleStoreState;
  getFactoringContractDebtorRoleByGuid: GetFactoringContractDebtorRoleByGuidStoreState;
  getEarlyPaymentApplicationsDebtorRole: GetEarlyPaymentApplicationsDebtorRoleStoreState;
  putEarlyPaymentApplicationDecline: PutEarlyPaymentApplicationDeclineStoreState;
  postAgreementSignatureDebtorRole: PostAgreementSignatureDebtorRoleStoreState;
  getOneDebtorRoleByGuid: GetOneDebtorRoleByGuidStoreState;
  getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid: GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState;
  getFactoringLimitByDebtorInn: GetFactoringLimitByDebtorInnStoreState;
  getDebtorRegistryFileSettingsCurrent: GetDebtorRegistryFileSettingsCurrentStoreState;
  uploadDebtorContractDocument: UploadDebtorContractDocumentStoreState;
  putPurchaseContractSignatureDebtorRole: PutPurchaseContractSignatureDebtorRoleStoreState;
  postDebtorNotificationSettings: PostDebtorNotificationSettingsStoreState;
  approveEarlyPaymentApplicationDebtorRole: ApproveEarlyPaymentApplicationDebtorRoleStoreState;
  getDocumentForms: GetDocumentFormsStoreState;
  postConfirmContractByDebtor: PostConfirmContractByDebtorStoreState;
  getByMonetaryClaimDebtor: GetByMonetaryClaimDebtorStoreState;
  getStartRateDebtorRole: GetStartRateDebtorRoleStoreState;
  postAddFormToSupplyContracts: PostAddFormToSupplyContractsStoreState;
  patchSetDiscountMargin: PatchSetDiscountMarginStoreState;
  getDebtorNotificationSettings: GetDebtorNotificationSettingsStoreState;
  getContractByDebtor: GetContractByDebtorStoreState;
  getDocumentFormBySupplyContract: GetDocumentFormBySupplyContractStoreState;
  getMonetaryClaimsSuppliesRole: GetMonetaryClaimsSuppliesRoleStoreState;
  getDebtorSignatureFile: GetDebtorSignatureFileStoreState;
  putRejectDebtor: PutRejectDebtorStoreState;
  putRejectSupplier: PutRejectSupplierStoreState;
  putAcceptByDebtor: PutAcceptByDebtorStoreState;
  putDeclineByDebtor: PutDeclineByDebtorStoreState;
  getFormFile: GetFormFileStoreState;
  patchPurchaseContractDebtor: PatchPurchaseContractDebtorStoreState;
  postAddToContractByDebtor: PostAddToContractByDebtorStoreState;
  signedEarlyPaymentApplication: SignedEarlyPaymentApplicationStoreState;
}

export const SCFDebtor = combineReducers({
  getDebtorCounterSidebar,
  getEarlyPaymentApplicationsDebtorRole,
  putEarlyPaymentApplicationDecline,
  debtorRegister,
  uploadRegistries,
  getDebtorRegistryFiles,
  sendSignature,
  getSubRegistries,
  sendSubSignature,
  getFactoringContractsDebtorRole,
  getFactoringContractDebtorRoleByGuid,
  postAgreementSignatureDebtorRole,
  getOneDebtorRoleByGuid,
  getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid,
  getFactoringLimitByDebtorInn,
  getDebtorRegistryFileSettingsCurrent,
  uploadDebtorContractDocument,
  putPurchaseContractSignatureDebtorRole,
  postDebtorNotificationSettings,
  approveEarlyPaymentApplicationDebtorRole,
  getDocumentForms,
  postConfirmContractByDebtor,
  getByMonetaryClaimDebtor,
  getStartRateDebtorRole,
  postAddFormToSupplyContracts,
  patchSetDiscountMargin,
  getDebtorNotificationSettings,
  getContractByDebtor,
  getDocumentFormBySupplyContract,
  getMonetaryClaimsSuppliesRole,
  getDebtorSignatureFile,
  putRejectDebtor,
  putRejectSupplier,
  putAcceptByDebtor,
  putDeclineByDebtor,
  getFormFile,
  patchPurchaseContractDebtor,
  postAddToContractByDebtor,
  signedEarlyPaymentApplication
});
