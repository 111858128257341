import { PUT_ADD_POA_FILE } from 'entities/SCF/Supplier/model/reducers/putAddPoaFile';

export const req = (data: FormData) => ({
  type: PUT_ADD_POA_FILE.REQ,
  data
});

export const got = () => ({
  type: PUT_ADD_POA_FILE.GOT
});

export const err = (error: {}) => ({
  type: PUT_ADD_POA_FILE.ERR,
  error
});

export const reset = () => ({
  type: PUT_ADD_POA_FILE.RESET
});
