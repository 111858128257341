import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/putPurchaseContractSignatureSupplierRole';

import {
  PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/putPurchaseContractSignatureSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* putPurchaseContractSignatureSupplierRole(action: {
  type: PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.putPurchaseContractSignatureSupplierRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putPurchaseContractSignatureSupplierRoleSaga() {
  yield takeLatest(
    PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.REQ,
    putPurchaseContractSignatureSupplierRole
  );
}
