import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postFinFile';

import { POST_FIN_FILE } from '../reducers/postFinFile';
import DocumentListApi from '../api';

function* postFinFile(action: {
  type: POST_FIN_FILE;
  data: FormData;
  token: string;
}): Generator {
  try {
    // action.data.forEach());
    yield call(DocumentListApi.postFinFile, action.token, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFinFileSaga(): Generator {
  yield takeLatest(POST_FIN_FILE.REQ, postFinFile);
}
