import { ADD_PROPERTY } from '../reducers/addProperty';
import { PropertyRead, PropertyWrite } from '../types/Property';

// NOTE idk if  Partial<PropertyRead> is right
export const req = (property: Partial<PropertyRead>) => ({
  type: ADD_PROPERTY.REQ,
  property
});

export const got = () => ({
  type: ADD_PROPERTY.GOT
});

export const err = (error: {}) => ({
  type: ADD_PROPERTY.ERR,
  error
});
