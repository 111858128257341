import { GET_CONTRACT_BY_INTERNAL } from '../reducers/getContractByInternal';

export interface ResponseData {
  guid: string;
  contractGuid: string;
  bankAccountGuid: string;
  type: number;
  status: number;
  confirmedByDebtor: number;
  debtorConfirmUserId: number;
  debtorConfirmDateTime: string;
  confirmedBySupplier: any;
  confirmedByFactor: any;
  bankAccounts: {
    id: number;
    isDeleted: boolean;
    clientInn: string;
    bankInn: string;
    bankBik: string;
    bankName: string;
    accountNumber: string;
    correspondentAccountNumber: string;
    isActive: boolean;
    isMain: boolean;
    currencyNumber: string;
    currencyCode: string;
    creatorId: number;
    guid: string;
  };
}

export const req = (guid: string) => ({
  type: GET_CONTRACT_BY_INTERNAL.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_CONTRACT_BY_INTERNAL.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_CONTRACT_BY_INTERNAL.ERR,
  err
});
