import getMonetaryClaimsInternalAgentSaga from 'entities/SCF/Internal/model/sagas/getMonetaryClaimsInternalAgent';
import getDebtorRegistriesSaga from 'entities/SCF/Internal/model/sagas/getDebtorRegistries';
import getDownloadDebtorRegistriesSaga from 'entities/SCF/Internal/model/sagas/getDownloadDebtorRegistries';
import getMonetaryClaimOneSaga from 'entities/SCF/Internal/model/sagas/getMonetaryClaimOne';

export default [
  getMonetaryClaimsInternalAgentSaga,
  getDebtorRegistriesSaga,
  getDownloadDebtorRegistriesSaga,
  getMonetaryClaimOneSaga
];
