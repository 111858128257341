import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from '../api';

import { got, err } from './../actions/getAllDiscountAuctions';
import { GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR } from './../reducers/getAllDiscountAuctions';

function* getAllDiscountAuctionsByDebtor(action: {
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR;
}) {
  try {
    const res: any = yield call(SCFApi.getAllDiscountAuctionsByDebtor);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllDiscountAuctionsByDebtorSaga() {
  yield takeLatest(
    GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR.REQ,
    getAllDiscountAuctionsByDebtor
  );
}
