import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import {
  ACCREDITATION,
  ACCREDITED_STATE_SUPPORT,
  CREDITOR_TYPE
} from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getFinbanksList,
  ResponseData as getFinbanksListResponseData,
  RequestData as getFinbanksListRequestData
} from '../../actions/getFinbanksList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  AccreditationContainer,
  AccreditationStateSupportContainer,
  CreditorTypeContainer,
  FilterContainer,
  HeadWrapper,
  NameContainer
} from 'src/features/Finbanks/components/FinbanksList/styled';
import { Input } from 'shared/ui/Input';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  finbanks: getFinbanksListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getFinbanksList: (data: getFinbanksListRequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const FinbanksList: React.FC<Props> = ({
  getFinbanksList,
  finbanks,
  status,
  error,
  history
}) => {
  const [name, setName] = React.useState('');
  const [selectedState, setSelectedState] = React.useState({
    creditorType: [],
    accreditation: [],
    accreditationStateSupport: []
  });

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const name = searchParams.get('name') || '';
      const creditorType = searchParams.getAll('creditorType') || [];
      const accreditation = searchParams.getAll('accreditation') || [];
      const accreditationStateSupport =
        searchParams.getAll('accreditationStateSupport') || [];

      setName(name);
      setSelectedState({
        creditorType: creditorType.toString().split(','),
        accreditation: accreditation.toString().split(','),
        accreditationStateSupport: accreditationStateSupport
          .toString()
          .split(',')
      });

      const getFiltersAsObject = (filterArray: string[]) => {
        const filterObject = {};
        filterArray.forEach(propertyName => {
          filterObject[propertyName] = true;
        });
        return filterArray.length ? filterObject : {};
      };

      getFinbanksList({
        page,
        pageSize: 20,
        ...(name && { name }),
        ...getFiltersAsObject(creditorType),
        ...getFiltersAsObject(accreditation),
        ...getFiltersAsObject(accreditationStateSupport)
      });
    },
    [history.location.search]
  );

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    const selectedItems = selectedState[name] || [];
    const isSelected = selectedItems.includes(value);
    const searchParams = new URLSearchParams(history.location.search);

    if (isSelected) {
      selectedState[name] = selectedItems.filter(item => item !== value);

      searchParams.delete(name);

      const updatedValues = selectedState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    } else {
      selectedState[name] = [...selectedItems, value];

      const updatedValues = selectedState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    }
  };

  const filterName = (event: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToNewCreditor = () => {
    history.push('/crm/banks/new');
  };

  const onFinbankClick = (inn: string) => {
    history.push(`/crm/banks/${inn}`);
  };

  return (
    <ScrollTopComponent>
      <HeadWrapper>
        <ApplicationTitle>Все банки</ApplicationTitle>
        <Button label="+ Добавить кредитора" onClick={navigateToNewCreditor} />
      </HeadWrapper>

      <FilterContainer>
        <NameContainer>
          <Input
            label="Наименование:"
            name="name"
            value={name}
            onChange={filterName}
          />
        </NameContainer>

        <CreditorTypeContainer>
          <CheckboxGroup
            name="creditorType"
            label="Тип кредитора:"
            checkboxes={Object.keys(CREDITOR_TYPE).map(type => ({
              label: CREDITOR_TYPE[type],
              value: type,
              checked: selectedState.creditorType.includes(type),
              onChange: handleFilter
            }))}
          />
        </CreditorTypeContainer>

        <AccreditationContainer>
          <CheckboxGroup
            name="accreditation"
            label="Аккредитация:"
            checkboxes={Object.keys(ACCREDITATION).map(type => ({
              label: ACCREDITATION[type],
              value: type,
              checked: selectedState.accreditation.includes(type),
              onChange: handleFilter
            }))}
          />
        </AccreditationContainer>

        <AccreditationStateSupportContainer>
          <CheckboxGroup
            name="accreditationStateSupport"
            label="Аккредитован для господдержки:"
            checkboxes={Object.keys(ACCREDITED_STATE_SUPPORT).map(type => ({
              label: ACCREDITED_STATE_SUPPORT[type],
              value: type,
              checked: selectedState.accreditationStateSupport.includes(type),
              onChange: handleFilter
            }))}
          />
        </AccreditationStateSupportContainer>
      </FilterContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="4%">ID</TableThStyled>
              <TableThStyled width="6%">ИНН Банка</TableThStyled>
              <TableThStyled width="14%">Наименование</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {finbanks.items.map(finbank => (
              <TableRowStyled
                key={finbank.id}
                onClick={() => onFinbankClick(finbank.inn)}
              >
                <td>{finbank.id}</td>
                <td>{finbank.inn}</td>
                <td>{finbank.name}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={finbanks} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Finbanks }: CRM) => ({
  finbanks: Finbanks.getFinbanksList.data,
  status: Finbanks.getFinbanksList.status,
  error: Finbanks.getFinbanksList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFinbanksList }, dispatch);

const FinbanksListConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FinbanksList)
);

export { FinbanksListConnect as FinbanksList };
