import styled from 'styled-components';
import {
  ProductColumnContainer,
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import { blue } from 'shared/styled';

export const ProductPolicyContainer = styled(ProductColumnContainer)`
  margin-top: 35px;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Любые бенефициары' && product !== 4 && active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Любые бенефициары' && product !== 4 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Бенефициары из рейтинга ТОП-500' &&
        product !== 3 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Бенефициары из рейтинга ТОП-500' &&
        product !== 3 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - Единичные бенефициары' && product !== 2 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - Единичные бенефициары' && product !== 2 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Никто' && product !== 1 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Никто' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '4 - Любые бенефициары' || product === 4) && 'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Никто' || product === 1) && 'white'};
  }
`;
