import { CONFIRM_ACCOUNT } from 'User/reducers/confirmAccount';

export interface RequestDataType {
  userId: string;
  token: string;
}

export const req = (data: RequestDataType) => ({
  type: CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_REQ,
  data
});

export const got = () => ({
  type: CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_GOT
});

export const err = (error: {}) => ({
  type: CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_ERR,
  error
});
