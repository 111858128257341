import { red } from 'shared/styled';
import styled from 'styled-components';

export const BlockSpace = styled.div`
  margin-bottom: 50px;
`;

export const Revenue = styled.div`
  color: ${({ number }) => (number < 0 ? red : '')};
`;

export const NetIncome = styled.div`
  color: ${({ number }) => (number < 0 ? red : '')};
`;
