import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postAllInListRefresh';
import { POST_ALL_IN_LIST_REFRESH } from '../reducers/postAllInListRefresh';
import CompaniesApi from '../api';

function* postAllInListRefresh(action: {
  type: POST_ALL_IN_LIST_REFRESH;
  id: string;
}): Generator {
  try {
    yield call(CompaniesApi.postAllInListRefresh, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAllInListRefreshSaga(): Generator {
  yield takeLatest(POST_ALL_IN_LIST_REFRESH.REQ, postAllInListRefresh);
}
