import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData as ReqAllClients } from 'src/features/Clients/actions/getClientsList';
import { RequestData as ReqPostNewClient } from 'src/features/Clients/actions/postNewClient';

export default class ClientsApi {
  static getCreditProducts(bankInn: string) {
    return ApiRequest(
      `/api/credit_risk_products_of_companies/all?bankInn=${bankInn}`
    );
  }

  static getPledges(bankInn: string) {
    return ApiRequest(`/api/pledges/all?bankInn=${bankInn}`);
  }

  static getAllClients(data?: ReqAllClients) {
    return ApiRequest(`/api/partner-api/companies/all`, 'get', data);
  }

  static postNewClient(data: ReqPostNewClient) {
    return ApiRequest(`/api/partner-api/companies/add`, 'post', data);
  }

  static getBeneficiaries(inn: string) {
    return ApiRequest(
      `/api/partner-api/beneficiaries_of_companies/company/${inn}`
    );
  }

  static getFinancialDocuments(inn: string) {
    return ApiRequest(`/api/financial_document/partner-api/${inn}`);
  }

  static getSubsidaries(inn: string) {
    return ApiRequest(
      `/api/partner-api/beneficiaries_of_companies/head_company/${inn}`
    );
  }
}
