import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getContactsList,
  ResponseData as getContactsListResponseData
} from '../../actions/getContactsList';
import { req as deleteContact } from '../../actions/deleteContact';
import { history } from 'src/shared/utils/History';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  BtnAddContact,
  CloseButton,
  DeleteButton,
  DeleteButtonTD,
  NoData,
  PopupContainer,
  PopupContent,
  PopupDeleteButton,
  PopupFooter,
  PopupText,
  PopupTextarea,
  PopupTitle,
  TableRowContacts
} from 'src/features/Contacts/components/ContactsList/styled';

interface State {
  deleteId: number;
  reasonForDeletion: string;
  isVisible: boolean;
  hoveredRowIndex: number;
}

interface StateToProps {
  contacts: getContactsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  deleteStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getContactsList: (inn: string) => void;
  deleteContact: (id: number, reason: string) => void;
}

interface OwnProps {
  companyInn: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ContactsList extends React.Component<Props, State> {
  state: State = {
    deleteId: null,
    reasonForDeletion: '',
    isVisible: false,
    hoveredRowIndex: -1
  };

  componentDidMount() {
    const { companyInn, getContactsList } = this.props;

    getContactsList(companyInn);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.deleteStatus !== this.props.deleteStatus) {
      const { companyInn, getContactsList } = this.props;

      getContactsList(companyInn);
    }
  }

  onContactClick(index: number) {
    history.push(`/crm/contacts/${this.props.contacts.items[index].id}`);
  }

  handleRowMouseEnter = rowIndex => {
    this.setState({ hoveredRowIndex: rowIndex });
  };

  handleRowMouseLeave = () => {
    this.setState({ hoveredRowIndex: -1 });
  };

  openModal = (e, id: number) => {
    e.stopPropagation();
    this.setState({ isVisible: true, deleteId: id });
  };

  closeModal = () => {
    this.setState({ isVisible: false, reasonForDeletion: '' });
  };

  handleChangeReasonForDeletion = reason => {
    this.setState({ reasonForDeletion: reason });
  };

  deleteContact = () => {
    this.props.deleteContact(this.state.deleteId, this.state.reasonForDeletion);
    this.setState({ isVisible: false });
  };

  navigateToAddContact = () => {
    history.push('/crm/contacts/new', { inn: this.props.companyInn });
  };

  render() {
    const { contacts, status, error } = this.props;
    return (
      <ScrollTopComponent>
        <BtnAddContact onClick={this.navigateToAddContact}>
          Добавить контакт
        </BtnAddContact>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT &&
          (contacts.items.length ? (
            <>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="5%">ID</TableThStyled>
                    <TableThStyled width="8%">Фамилия</TableThStyled>
                    <TableThStyled width="8%">Имя</TableThStyled>
                    <TableThStyled width="8%">Отчество</TableThStyled>
                    <TableThStyled width="15%">Должность</TableThStyled>
                    <TableThStyled width="12%">
                      Контактный телефон
                    </TableThStyled>
                    <TableThStyled width="12%">Мобильный</TableThStyled>
                    <TableThStyled width="12%">Рабочий</TableThStyled>
                    <TableThStyled width="15%">Email</TableThStyled>
                    <TableThStyled width="5%" />
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {contacts.items.map((contact, key) => (
                    <TableRowContacts
                      isDeleted={contact.isDeleted}
                      onClick={() => this.onContactClick(key)}
                      key={key}
                      onMouseEnter={() => this.handleRowMouseEnter(key)}
                      onMouseLeave={this.handleRowMouseLeave}
                    >
                      <td>{contact.id}</td>
                      <td>{contact.lastName}</td>
                      <td>{contact.firstName}</td>
                      <td>{contact.middleName}</td>
                      <td>{contact.position}</td>
                      <td>{contact.phoneContact}</td>
                      <td>{contact.phoneNumber}</td>
                      <td>{contact.phoneStationary}</td>
                      <td>{contact.email}</td>
                      <DeleteButtonTD>
                        <DeleteButton
                          onClick={e => this.openModal(e, contact.id)}
                          isVisible={this.state.hoveredRowIndex === key}
                        >
                          X
                        </DeleteButton>
                      </DeleteButtonTD>
                    </TableRowContacts>
                  ))}
                </tbody>
              </TableStyled>
              <PopupContainer visible={this.state.isVisible}>
                <PopupContent>
                  <PopupTitle>
                    Вы действительно хотите удалить контакт?
                  </PopupTitle>
                  <PopupText>Введите причину удаления:</PopupText>
                  <PopupTextarea
                    value={this.state.reasonForDeletion}
                    onChange={e =>
                      this.handleChangeReasonForDeletion(e.currentTarget.value)
                    }
                  />
                  <PopupFooter>
                    <CloseButton onClick={this.closeModal}>Назад</CloseButton>
                    <PopupDeleteButton
                      disabled={this.state.reasonForDeletion.length < 10}
                      onClick={this.deleteContact}
                    >
                      Удалить
                    </PopupDeleteButton>
                  </PopupFooter>
                </PopupContent>
              </PopupContainer>
            </>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>на платформе пока нет данных о контактах компании</p>
              <p>вы можете добавить первый контакт</p>
            </NoData>
          ))}
        <Pagination list={contacts} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Contacts }: CRM) => ({
  contacts: Contacts.getContactsList.data,
  status: Contacts.getContactsList.status,
  error: Contacts.getContactsList.error,
  deleteStatus: Contacts.deleteContact.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getContactsList, deleteContact }, dispatch);

const ContactsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContactsList);

export { ContactsListConnect as ContactsList };
