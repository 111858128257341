import styled from 'styled-components';
import {
  PopupContainer,
  PopupContent
} from 'src/features/Contacts/components/ContactsList/styled';
import { lightBlue } from 'shared/styled';

export const PopupContainerBank = styled(PopupContainer)`
  display: flex;
  z-index: 3;
`;

export const PopupContentBank = styled(PopupContent)`
  min-width: 250px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  p {
    font-size: 18px;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
    height: auto;
    width: auto;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active !== false && lightBlue};
    color: ${({ active }) => active !== false && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: pointer;

  :hover {
    border-color: ${({ active }) => active !== true && lightBlue};
    color: ${({ active }) => active !== true && lightBlue};
  }
`;

export const ToggleButtonWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
