import {
  RequestDataType,
  ResponseDataType,
  POST_REPLY
} from 'Comments/reducers/postReply';

export const req = (data: RequestDataType) => ({
  type: POST_REPLY.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: POST_REPLY.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_REPLY.ERR,
  error
});
