import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface UploadBorrowerCompanyDocumentsStoreState extends Reducer {
  data: SagaRequestDataType;
}

interface RequestCommonDataType {
  id: number;
}

export interface RequestDataType extends RequestCommonDataType {
  file: FormData;
  metaInfo: string;
}

export interface SagaRequestDataType extends RequestCommonDataType {
  files: any[];
}

export enum UPLOAD_BORROWER_COMPANIES_DOCUMENTS {
  REQ = 'UPLOAD_BORROWER_COMPANIES_DOCUMENTS_REQUEST',
  GOT = 'UPLOAD_BORROWER_COMPANIES_DOCUMENTS_GOT',
  ERR = 'UPLOAD_BORROWER_COMPANIES_DOCUMENTS_ERROR'
}

export const initialState: UploadBorrowerCompanyDocumentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const uploadBorrowerCompanyDocuments = (
  state: UploadBorrowerCompanyDocumentsStoreState = initialState,
  action: {
    type: string;
    data: SagaRequestDataType;
    error: {};
  }
): UploadBorrowerCompanyDocumentsStoreState => {
  switch (action.type) {
    case UPLOAD_BORROWER_COMPANIES_DOCUMENTS.REQ:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPLOAD_BORROWER_COMPANIES_DOCUMENTS.GOT:
      return {
        ...state,
        data: null,
        status: REQUEST_STATUSES.GOT
      };
    case UPLOAD_BORROWER_COMPANIES_DOCUMENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default uploadBorrowerCompanyDocuments;
