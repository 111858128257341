import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  req as getFactoringLimit,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getFactoringLimitByDebtorInn';
import { Loader } from 'Layouts/components';
import {
  InfoBlock,
  InfoBottom,
  StatusItem,
  ActiveContainer,
  ActiveContainerHovered,
  InactiveContainer,
  InactiveContainerHovered,
  CheckedIcon,
  CrossIcon,
  HandleContainer,
  SettingsChoiceStyled,
  SettingsButtonNotChosen
} from './styles';
import { PageStyled, PageHeaderContainer } from 'src/features/SCF/UI/ScfStyles';
import '../../styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import SCFApi from 'src/features/SCF/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringLimit: ResponseDataType;
}

interface DispatchToProps {
  getFactoringLimit: (data: RequestDataType) => void;
}

interface State {
  pageUrl: Href;
  settingsState: string;
  updateChecker: number;
}

type Props = StateToProps & DispatchToProps;

class LimitInfoInactive extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: window.location.href,
        settingsState: 'Settings',
        updateChecker: 0
      };
      this.handleSettingsStateChange = this.handleSettingsStateChange.bind(
        this
      );
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handleSettingsStateChange(setting: string) {
    this.setState({ settingsState: setting });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  handleUpdateCheckerChange = async () => {
    await this.delay(700);
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  };

  initFetch = () => {
    const { getFactoringLimit } = this.props;

    getFactoringLimit({
      debtorInn: window.location.href.split('/').reverse()[1]
    });
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
  }

  render() {
    const { status, factoringLimit, error } = this.props;
    return (
      <PageStyled style={{ marginBottom: '0' }}>
        <PageHeaderContainer>
          <h2>Карточка лимита</h2>
        </PageHeaderContainer>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <InfoBlock>
            <h1>ИНН {factoringLimit.items[0].debtorINN}</h1>
            <InfoBottom>
              <h2>{factoringLimit.items[0].debtorShortName}</h2>
              {factoringLimit.items[0].isActual == true ? (
                <StatusItem className="td-status">
                  <ActiveContainer style={{ margin: '0 7px' }}>
                    <CheckedIcon style={{ margin: '0 7px' }} />
                    <p>Доступен</p>
                  </ActiveContainer>
                  <InactiveContainerHovered
                    className="inactive-status"
                    style={{ margin: '0 7px' }}
                    onClick={() =>
                      SCFApi.setFactoringLimitInactive(
                        factoringLimit.items[0].debtorINN
                      )
                    }
                  >
                    <HandleContainer
                      onClick={() => this.handleUpdateCheckerChange()}
                    >
                      <CrossIcon style={{ margin: '0 7px 0 7px' }} />
                    </HandleContainer>
                  </InactiveContainerHovered>
                  <h1 className="inactive-status">Отключить</h1>
                </StatusItem>
              ) : (
                <StatusItem className="td-status">
                  <InactiveContainer style={{ margin: '0 7px' }}>
                    <CrossIcon style={{ margin: '0 7px' }} />
                    <p>Отключен</p>
                  </InactiveContainer>
                  <ActiveContainerHovered
                    className="active-status"
                    style={{ margin: '0 7px' }}
                    onClick={() =>
                      SCFApi.setFactoringLimitActive(
                        factoringLimit.items[0].debtorINN
                      )
                    }
                  >
                    <HandleContainer
                      onClick={() => this.handleUpdateCheckerChange()}
                    >
                      <CheckedIcon style={{ marginLeft: '5px' }} />
                    </HandleContainer>
                  </ActiveContainerHovered>
                  <h1 className="active-status">Подключить</h1>
                </StatusItem>
              )}
            </InfoBottom>
          </InfoBlock>
        )}
        <SettingsChoiceStyled>
          <SettingsButtonNotChosen
            id="settingsLink"
            style={{ cursor: 'not-allowed' }}
          >
            Настройки
          </SettingsButtonNotChosen>
          <SettingsButtonNotChosen
            id="suppliersLink"
            style={{ cursor: 'not-allowed' }}
          >
            Поставщики
          </SettingsButtonNotChosen>
          <SettingsButtonNotChosen
            id="registriesLink"
            style={{ cursor: 'not-allowed' }}
          >
            Реестры
          </SettingsButtonNotChosen>
        </SettingsChoiceStyled>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  factoringLimit: SCFDebtor.getFactoringLimitByDebtorInn.data,
  status: SCFDebtor.getFactoringLimitByDebtorInn.status,
  error: SCFDebtor.getFactoringLimitByDebtorInn.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringLimit
    },
    dispatch
  );

const LimitInfoInactiveConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LimitInfoInactive);

export { LimitInfoInactiveConnect as LimitInfoInactive };
