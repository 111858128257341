import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import { Loader } from 'src/features/Layouts/components';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import {
  req as patchEditPerson,
  RequestData
} from 'src/features/Persons/actions/patchEditPerson';
import { SEX_TYPE, PersonType } from '../../types';
import {
  CopyBlock,
  Divider,
  InputArea,
  PassportContainer,
  PassportForm,
  PassportFullAddressStyled,
  PassportIssuanceStyled,
  PassportIssuerNameStyled,
  PassportNameStyled,
  PassportPlaceOfBirthStyled,
  PassportSerialNumberStyled,
  PassportSexAndCodeStyled,
  PassportWrapper
} from './styled';
import { InputDate } from 'shared/ui/InputDate';

interface StateToProps {}

interface DispatchToProps {
  patchEditPerson: (inn: string, data: RequestData) => void;
}

interface OwnProps {
  inn: string;
  person: PersonType;
  status: REQUEST_STATUSES;
}

type Props = StateToProps & DispatchToProps & OwnProps;

const requiredFields = [
  'passportSerialNumber',
  'passportIssuerName',
  'passportIssuerCode',
  'passportIssuanceDate',
  'lastName',
  'firstName',
  'middleName',
  'sex',
  'dateOfBirth',
  'placeOfBirth',
  'fullAddress'
];

const PassportData: React.FC<Props> = ({
  inn,
  person,
  status,
  patchEditPerson
}) => {
  const [formData, setFormData] = React.useState<RequestData>({
    passportSerialNumber: '',
    passportIssuerName: '',
    passportIssuanceDate: '',
    passportIssuerCode: '',
    lastName: '',
    firstName: '',
    middleName: '',
    sex: '',
    dateOfBirth: '',
    placeOfBirth: '',
    fullAddress: ''
  });
  const [isCopied, setIsCopied] = React.useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({ ...formData, [name]: value } as any);
  };

  const handleDateInputChange = (name: string, date: string) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const isAnyFieldValid = Object.values(formData).some(
    value => value.length > 5 || value === 'MAN' || value === 'WOMAN'
  );

  const isAnyFieldInvalid = requiredFields.every(field => person[field]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const dataToSend = {} as RequestData;

    Object.entries(formData).forEach(([fieldName, fieldValue]) => {
      if (fieldValue !== '') {
        if (fieldName === 'sex') {
          dataToSend[fieldName] =
            fieldValue === 'MAN' ? 1 : fieldValue === 'WOMAN' && 2;
        } else if (
          fieldName === 'passportIssuanceDate' ||
          fieldName === 'dateOfBirth'
        ) {
          dataToSend[fieldName] = fieldValue.replaceAll('/', '.');
        } else {
          dataToSend[fieldName] = fieldValue;
        }
      }
    });

    patchEditPerson(inn, dataToSend);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <PassportForm onSubmit={handleSubmit}>
          <PassportContainer>
            <PassportWrapper>
              <PassportSerialNumberStyled>
                <p>Серия и номер</p>
                {person.passportSerialNumber ? (
                  <CopyBlock
                    text={
                      person.passportSerialNumber && !isCopied
                        ? 'Скопировать'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(person.passportSerialNumber)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    <p>{person.passportSerialNumber}</p>
                  </CopyBlock>
                ) : (
                  <Input
                    name="passportSerialNumber"
                    value={formData.passportSerialNumber}
                    onChange={handleInputChange}
                  />
                )}
              </PassportSerialNumberStyled>

              <PassportIssuerNameStyled>
                <p>Паспорт выдан</p>
                {person.passportIssuerName ? (
                  <CopyBlock
                    text={
                      person.passportIssuerName && !isCopied
                        ? 'Скопировать'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(person.passportIssuerName)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    <p>{person.passportIssuerName}</p>
                  </CopyBlock>
                ) : (
                  <InputArea
                    name="passportIssuerName"
                    value={formData.passportIssuerName}
                    onChange={handleInputChange}
                  />
                )}
              </PassportIssuerNameStyled>

              <PassportIssuanceStyled>
                <div>
                  <p>Дата выдачи</p>
                  {person.passportIssuanceDate ? (
                    <CopyBlock
                      text={
                        person.passportIssuanceDate && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() =>
                        handleCopyClick(
                          new Date(
                            person.passportIssuanceDate
                          ).toLocaleDateString()
                        )
                      }
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>
                        {new Date(
                          person.passportIssuanceDate
                        ).toLocaleDateString()}
                      </p>
                    </CopyBlock>
                  ) : (
                    <InputDate
                      name="passportIssuanceDate"
                      onDateChange={date =>
                        handleDateInputChange('passportIssuanceDate', date)
                      }
                      dateValue={formData.passportIssuanceDate}
                    />
                  )}
                </div>
                <div>
                  <p>Код органа</p>
                  {person.passportIssuerCode ? (
                    <CopyBlock
                      text={
                        person.passportIssuerCode && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(person.passportIssuerCode)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>{person.passportIssuerCode}</p>
                    </CopyBlock>
                  ) : (
                    <Input
                      name="passportIssuerCode"
                      value={formData.passportIssuerCode}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </PassportIssuanceStyled>

              <Divider />

              <PassportNameStyled>
                <div>
                  <p>Фамилия</p>
                  {person.lastName ? (
                    <CopyBlock
                      text={
                        person.lastName && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(person.lastName)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>{person.lastName}</p>
                    </CopyBlock>
                  ) : (
                    <Input
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
                <div>
                  <p>Имя</p>
                  {person.firstName ? (
                    <CopyBlock
                      text={
                        person.firstName && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(person.firstName)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>{person.firstName}</p>
                    </CopyBlock>
                  ) : (
                    <Input
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
                <div>
                  <p>Отчество</p>
                  {person.middleName ? (
                    <CopyBlock
                      text={
                        person.middleName && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(person.middleName)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>{person.middleName}</p>
                    </CopyBlock>
                  ) : (
                    <Input
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </PassportNameStyled>

              <PassportSexAndCodeStyled>
                <div>
                  <p>Пол</p>
                  {person.sex ? (
                    <CopyBlock
                      text={
                        person.sex && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() =>
                        handleCopyClick(
                          person.sex === 1 ? 'МУЖ.' : person.sex === 2 && 'ЖЕН.'
                        )
                      }
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>
                        {person.sex === 1 ? 'МУЖ.' : person.sex === 2 && 'ЖЕН.'}
                      </p>
                    </CopyBlock>
                  ) : (
                    <Select
                      options={Object.entries(SEX_TYPE).map(([key, value]) => ({
                        id: key,
                        name: value
                      }))}
                      value={formData.sex ? formData.sex.toString() : ''}
                      name="sex"
                      required={true}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
                <div>
                  <p>Дата рождения</p>
                  {person.dateOfBirth ? (
                    <CopyBlock
                      text={
                        person.dateOfBirth && !isCopied
                          ? 'Скопировать'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() =>
                        handleCopyClick(
                          new Date(person.dateOfBirth).toLocaleDateString()
                        )
                      }
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      <p>{new Date(person.dateOfBirth).toLocaleDateString()}</p>
                    </CopyBlock>
                  ) : (
                    <InputDate
                      name="dateOfBirth"
                      onDateChange={date =>
                        handleDateInputChange('dateOfBirth', date)
                      }
                      dateValue={formData.dateOfBirth}
                    />
                  )}
                </div>
              </PassportSexAndCodeStyled>

              <PassportPlaceOfBirthStyled>
                <p>Место рождения</p>
                {person.placeOfBirth ? (
                  <CopyBlock
                    text={
                      person.placeOfBirth && !isCopied
                        ? 'Скопировать'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(person.placeOfBirth)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    <p>{person.placeOfBirth}</p>
                  </CopyBlock>
                ) : (
                  <InputArea
                    name="placeOfBirth"
                    value={formData.placeOfBirth}
                    onChange={handleInputChange}
                  />
                )}
              </PassportPlaceOfBirthStyled>
            </PassportWrapper>

            <PassportWrapper>
              <PassportFullAddressStyled>
                <p>Место жительства</p>
                {person.fullAddress ? (
                  <CopyBlock
                    text={
                      person.fullAddress && !isCopied
                        ? 'Скопировать'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(person.fullAddress)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    <p>{person.fullAddress}</p>
                  </CopyBlock>
                ) : (
                  <InputArea
                    name="fullAddress"
                    value={formData.fullAddress}
                    onChange={handleInputChange}
                  />
                )}
              </PassportFullAddressStyled>
            </PassportWrapper>
          </PassportContainer>
          {!isAnyFieldInvalid && (
            <Button
              label="Сохранить"
              type="submit"
              disabled={!isAnyFieldValid}
            />
          )}
        </PassportForm>
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({  }: CRM) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchEditPerson }, dispatch);

const PassportDataConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(PassportData);

export { PassportDataConnect as PassportData };
