import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/Person/actions/getSearchPerson';
import { GET_SEARCH_PERSON } from 'src/features/Person/reducers/getSearchPerson';
import { FormData } from '../types/SearchPerson';
import BusinessApi from 'src/features/Person/api';

function* getSearchPerson(action: {
  type: GET_SEARCH_PERSON;
  data: FormData;
}): Generator {
  try {
    const res: any = yield call(BusinessApi.getSearchPerson, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSearchPersonSaga(): Generator {
  yield takeLatest(GET_SEARCH_PERSON.REQ, getSearchPerson);
}
