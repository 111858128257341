import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getDebtorsList';

import { GET_DEBTORS_LIST } from '../reducers/getDebtorsList';
import DebtorApi from '../api';

function* getDebtorsList(action: {
  type: GET_DEBTORS_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(DebtorApi.getAllDebtors, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorsListSaga(): Generator {
  yield takeLatest(GET_DEBTORS_LIST.REQ, getDebtorsList);
}
