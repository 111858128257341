import * as React from 'react';

import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';
import { ErrorModalStyled } from './styled';

interface Props {
  code: number;
  message: string;
}

const ErrorModal: React.FC<Props> = ({ code, message }) => {
  const [errorData, setErrorData] = React.useState<Props>({ code, message });

  React.useEffect(
    () => {
      if (code && message) {
        setErrorData({ code, message });
      }
    },
    [code, message]
  );

  return (
    <Modal name={MODAL_NAMES.ERROR_FINANCIAL_COMPANY_MODAL}>
      <ErrorModalStyled>
        {errorData.code !== 412 && errorData.code !== 200 ? (
          <p>{errorData.message}</p>
        ) : errorData.code === 412 ? (
          <p>Отчетность не валидна. Проверьте введенные данные</p>
        ) : null}
      </ErrorModalStyled>
    </Modal>
  );
};

export { ErrorModal };
