import { SEND_PURCHASE_CONTRACT } from '../reducers/sendPurchaseContract';
import { PurchaseContractWrite } from './setPurchaseContract';

export interface RequestData extends Partial<PurchaseContractWrite> {}

export const req = (data: RequestData) => ({
  type: SEND_PURCHASE_CONTRACT.REQ,
  data
});

export const got = () => ({
  type: SEND_PURCHASE_CONTRACT.GOT
});

export const err = (error: {}) => ({
  type: SEND_PURCHASE_CONTRACT.ERR,
  error
});
