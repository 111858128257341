import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'BorrowerCompanies/actions/getDocumentListByApplicationId';
import { GET_DOCUMENT_LIST_BY_APPLICATION_ID } from 'BorrowerCompanies/reducers/getDocumentListByApplicationId';
import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* getDocumentListByApplicationId(action: {
  type: GET_DOCUMENT_LIST_BY_APPLICATION_ID;
  guid: string;
}) {
  try {
    const res = yield call(
      BorrowerCompaniesApi.getDocumentListByApplicationId,
      action.guid
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentListByApplicationIdSaga() {
  yield takeLatest(
    GET_DOCUMENT_LIST_BY_APPLICATION_ID.REQ,
    getDocumentListByApplicationId
  );
}
