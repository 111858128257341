import { id } from 'date-fns/locale';
import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from './../actions/getBankAccount';

export interface GetBankAccountDataStoreState extends Reducer {
  data: ResponseDataType;
}

export enum BANK_ACCOUNT_ACTIONS {
  REQ = 'BANK_ACCOUNT_REQ',
  GOT = 'BANK_ACCOUNT_GOT',
  ERR = 'BANK_ACCOUNT_ERR',
}

const initialState: GetBankAccountDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: [],
  },
  error: {}
};

const getBankAccount = (
  state: GetBankAccountDataStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetBankAccountDataStoreState => {
  switch (action.type) {
    case BANK_ACCOUNT_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case BANK_ACCOUNT_ACTIONS.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case BANK_ACCOUNT_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getBankAccount;
