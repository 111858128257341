import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getDiscountingSettings';

export interface GetDiscountingSettingsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_DISCOUNTING_SETTINGS {
  GET_DISCOUNTING_SETTINGS_REQ = 'GET_DISCOUNTING_SETTINGS_REQ',
  GET_DISCOUNTING_SETTINGS_GOT = 'GET_DISCOUNTING_SETTINGS_GOT',
  GET_DISCOUNTING_SETTINGS_ERR = 'GET_DISCOUNTING_SETTINGS_ERR',
  GET_DISCOUNTING_SETTINGS_RESET = 'POST_SUPPLIER_NOTIFICATION_SETTINGS_RESET'
}

export const initialState: GetDiscountingSettingsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    status: '',
    enableDiscounting: false,
    enableFactoring: false,
    discountLimitPolicy: 0,
    manualMaxLimitSize: 0,
    limitUsage: 0,
    autoLimitPolicy: 0,
    fixedSubstractionSum: 0,
    substractionPeriodInDays: 0,
    discountRatePolicy: 0,
    discountRate: 0,
    rateIsSurplusToKeyRate: false,
    autoRateSubstraction: 0,
    factoringOfferPolicy: null,
    signatureFilename: '',
    signatureFileIdentifier: '',
    accountBalanceIsIntegrated: false,
    factorsExchangeIsIntegrated: false,
    minimalDiscount: null,
    paydays: null,
    paydayOffset: null,
    suppliesDataSourcePolicy: null,
    verificationPolicy: null,
    requestFilename: null,
    requestFileIdentifier: null,
    holidayPaydays: false,
    epaMcPolicy: null,
    factoringFormatPolicy: null,
    factoringNotificationPolicy: null,
    agreementFormFileIdentifier: null,
    agreementFormFilename: null
  },
  error: {}
};

const getDiscountingSettings = (
  state: GetDiscountingSettingsStoreState = initialState,
  action: {
    type: GET_DISCOUNTING_SETTINGS;
    data: ResponseDataType;
    error: {};
  }
): GetDiscountingSettingsStoreState => {
  switch (action.type) {
    case GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_DISCOUNTING_SETTINGS.GET_DISCOUNTING_SETTINGS_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getDiscountingSettings;
