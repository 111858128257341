import {
  FINANCING_PAYMENT_CONFIRMATION,
  FinancingApplicationWrite,
  ApplicationWrite
} from 'Application/types';

export const formula = ({
  amount,
  equity,
  isAgriculture,
  termInvestmentPhase,
  paymentConfirmationInvestmentPhase
}: Partial<FinancingApplicationWrite> & Partial<ApplicationWrite>) => {
  /* 
    [Инвестиционный кредит] = [Бюджет проекта] – [Собственные средства],
    но не более 80% от бюджета, если установлен признак
    «По программе министерства сельского хозяйства» или 70% в другом случае.
  */
  const maxInvestmentCreditAmount =
    amount * (isAgriculture ? 80 / 100 : 70 / 100);
  const investmentCreditAmount = amount - equity;
  const investmentCredit =
    investmentCreditAmount > maxInvestmentCreditAmount
      ? maxInvestmentCreditAmount
      : investmentCreditAmount;

  /* 
    [Мезонинный кредит] = [Бюджет проекта] – [Собственные средства] –[Инвестиционный кредит].
  */
  const mezzanineCredit = amount - equity - investmentCredit;

  /*
    Ставка, если установлен признак
    «По программе министерства сельского хозяйства» - 5% годовых,
    для остальных - 15% годовых.
  */
  const investmentPhaseRate = isAgriculture ? 5 / 100 : 15 / 100;

  /*
    [Сумма процентов] = ([Сумма инвест кредита] * [срок инвест фазы] * ставка +
    + [Сумма мезонинного кредита] * [срок инвест фазы] * 25%) / 12 мес.
  */
  const investmentPhaseAmount =
    (investmentCredit * termInvestmentPhase * investmentPhaseRate +
      mezzanineCredit * termInvestmentPhase * (25 / 100)) /
    12;

  /*
    Если выбраны вексели в качестве формы подтверждения оплаты процентов на инвестиционной Фразе,
    то [Собственные вложения для реализации проекта] = [Собственные инвестиции] + [Сумма процентов на инвестиционной фазе],
    иначе: [Собственные вложения для реализации проекта] = [Собственные инвестиции].
  */
  const isBills =
    paymentConfirmationInvestmentPhase ===
    FINANCING_PAYMENT_CONFIRMATION.BILL_OF_EXCHANGE;
  const projectInvestments = isBills ? equity + investmentPhaseAmount : equity;

  return {
    investmentCredit,
    mezzanineCredit,
    investmentPhaseAmount,
    projectInvestments
  };
};
