import * as React from 'react';

import { CrossBtn } from 'shared/styled';
import { MultiComponentStyled, ValuesContainer, EachValue } from './styles';

interface Props {
  selected: string[];
  children: any;
  onDeleteSelected: (index: number) => void;
}

export const MultiComponent: React.FC<Props> = ({
  selected,
  children,
  onDeleteSelected
}) => (
  <MultiComponentStyled>
    {children}

    {selected.length > 0 && (
      <ValuesContainer>
        {selected.map((item, index) => (
          <EachValue key={index}>
            {item}
            <CrossBtn onClick={() => onDeleteSelected(index)} />
          </EachValue>
        ))}
      </ValuesContainer>
    )}
  </MultiComponentStyled>
);
