import {
  RequestDataType,
  POST_FACTORING_CONNECTION_FACTOR_ROLE
} from 'entities/SCF/Factor/model/reducers/postFactoringConnectionFactorRole';

export const req = (data: RequestDataType) => ({
  type: POST_FACTORING_CONNECTION_FACTOR_ROLE.REQ,
  data
});

export const got = () => ({
  type: POST_FACTORING_CONNECTION_FACTOR_ROLE.GOT
});

export const err = (error: {}) => ({
  type: POST_FACTORING_CONNECTION_FACTOR_ROLE.ERR,
  error
});
