import { GET_FINBANK } from '../reducers/getFinbank';
import { FinbankWrite } from './setFinbankFormData';

export const req = (inn: string) => ({
  type: GET_FINBANK.REQ,
  inn
});

export const got = (finbank: FinbankWrite) => ({
  type: GET_FINBANK.GOT,
  finbank
});

export const err = (error: {}) => ({
  type: GET_FINBANK.ERR,
  error
});
