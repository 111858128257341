import { LeadRead } from 'src/features/Leads/actions/setLeadFormData';
import { GET_LEADS } from '../reducers/getLeads';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: LeadRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  companyInn: string;
}

export const req = (inn: string) => ({
  type: GET_LEADS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_LEADS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_LEADS.ERR,
  error
});
