import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';

import { setSession } from 'App/actions/cached';
import { got as gotUserData } from 'User/actions/getUserData';
import { got, err } from 'User/actions/signInByLink';
import { SIGNIN_BY_LINK } from 'User/reducers/signInByLink';

function* signInByLink(action: { type: SIGNIN_BY_LINK; key: string }) {
  try {
    const res: any = yield call(UserApi.signInByLink, action.key);
    window.location.reload();
    yield put(got(res));
    yield put(gotUserData(res));
    yield put(setSession());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* signInByLinkSaga() {
  yield takeLatest(SIGNIN_BY_LINK.REQ, signInByLink);
}
