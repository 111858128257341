import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { InnPlaceholders } from 'shared/constants';
import {
  req as getFactoringContractsList,
  RequestData,
  ResponseData
} from 'src/features/FactoringContracts/actions/getFactoringContractsList';
import { INNContainer, INNField } from '../FactoringContractsList/styled';
import { FactoringList } from '../FactoringContractsList/FactoringContractsList';

interface StateToProps {
  factoringContracts: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getFactoringContractsList: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const FactoringContract: React.FC<Props> = ({
  getFactoringContractsList,
  factoringContracts,
  status,
  error,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');
  const [factorInn, setFactorInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';
      const factorInn = searchParams.get('factorInn') || '';

      setDebtorInn(debtorInn);
      setSupplierInn(supplierInn);
      setFactorInn(factorInn);

      getFactoringContractsList({
        page,
        pageSize: 20,
        ...(debtorInn && { DebtorInnToFilterBy: debtorInn }),
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn }),
        ...(factorInn && { FactorInnToFilterBy: factorInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Договоры факторинга</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Фактора"
            value={factorInn}
            name="factorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      <FactoringList
        factoringContracts={factoringContracts}
        status={status}
        error={error}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ FactoringContracts }: CRM) => ({
  factoringContracts: FactoringContracts.getFactoringContractsList.data,
  status: FactoringContracts.getFactoringContractsList.status,
  error: FactoringContracts.getFactoringContractsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFactoringContractsList }, dispatch);

const FactoringContractConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringContract)
);

export { FactoringContractConnect as FactoringContract };
