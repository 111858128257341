import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/SCF/Internal/model/actions/getDebtorRegistries';

export interface GetDebtorRegistriesInternalAgentStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DEBTOR_REGISTRIES_INTERNAL_AGENT {
  REQ = 'GET_DEBTOR_REGISTRIES_INTERNAL_AGENT_REQ',
  GOT = 'GET_DEBTOR_REGISTRIES_INTERNAL_AGENT_GOT',
  ERR = 'GET_DEBTOR_REGISTRIES_INTERNAL_AGENT_ERR'
}

export const initialState: GetDebtorRegistriesInternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 20,
    totalItems: null,
    items: []
  },
  error: {}
};

const getDebtorRegistriesInternalAgent = (
  state: GetDebtorRegistriesInternalAgentStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetDebtorRegistriesInternalAgentStoreState => {
  switch (action.type) {
    case GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDebtorRegistriesInternalAgent;
