import styled, { keyframes } from 'styled-components';
import { blue, lighterBlue, rr } from 'shared/styled';

export const ContractsPageStyled = styled.section`
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const BtnsBlock = styled.div`
  margin-top: 10px;
  width: auto;
  height: auto;
  display: flex;
`;

export const ConfirmSection = styled.div`
  margin-top: 30px;
  width: 1295px;
  height: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmSectionNarrow = styled.div`
  margin-top: 30px;
  width: 1010px;
  height: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmSectionWide = styled.div`
  margin-top: 30px;
  width: 1315px;
  height: auto;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmSectionRightPart = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ConfirmSectionCheckBox = styled.div`
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  p {
    margin-top: -1px;
    font-family: ${rr};
    width: 250px;
  }
`;

export const CreateBtn = styled.div`
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  margin: 0;
  color: #ffffff;
`;

export const BackBtn = styled.div`
  margin-right: 20px;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  color: #3f4e65;
  margin: 0;
`;

export const InnFilterArea = styled.div`
  width: 450px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const ListSection = styled.section`
  margin-top: 30px;
  width: 1300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullList = styled.section`
  margin-top: 20px;
  width: 1300px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ListSectionNarrow = styled.section`
  margin-top: 30px;
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullListNarrow = styled.section`
  margin-top: 20px;
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ListSectionWide = styled.section`
  margin-top: 30px;
  width: 1350px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullListWide = styled.section`
  margin-top: 20px;
  width: 1350px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${blue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${blue};
  }
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const ListSectionOne = styled.section`
  margin-top: 30px;
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullListOne = styled.section`
  margin-top: 20px;
  width: 700px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DiscountItem = styled.div`
  width: 420px;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

export const DocumentHint = styled.div`
  width: 300px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  top: 0;
  left: 100%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }

    & > div {
      & > p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    font-size: 14px;
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;
