import styled from 'styled-components';
import { blue } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const Login = styled(ButtonStyled)`
  background-color: transparent;
  height: 40px;
  color: ${blue};
  margin-right: 20px;
  :hover {
    background-color: transparent !important;
  }
`;

export const Register = styled(ButtonStyled)`
  height: 40px;
`;
