import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import { got, err } from 'BorrowerCompanies/actions/createBorrowerCompany';
import {
  RequestDataType,
  CREATE_BORROWER_COMPANY
} from 'BorrowerCompanies/reducers/createBorrowerCompany';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* createBorrowerCompany(action: {
  type: CREATE_BORROWER_COMPANY;
  data: RequestDataType;
}): Generator {
  try {
    const res: any = yield call(
      BorrowerCompaniesApi.createBorrowerCompany,
      action.data
    );

    const { borrowerCompanies }: any = yield select(
      ({ Application }: STORE) => ({
        borrowerCompanies:
          Application.setInitializationFormData.borrowerCompanies
      })
    );

    yield put(
      setInitializationFormData({
        borrowerCompanies: [...borrowerCompanies, res]
      })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createBorrowerCompanySaga(): Generator {
  yield takeLatest(CREATE_BORROWER_COMPANY.REQ, createBorrowerCompany);
}
