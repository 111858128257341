import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getFinancialsSummary';
import {
  GET_FINANCIALS_SUMMARY,
  ResponseData
} from 'Application/reducers/getFinancailsSummary';

import ApplicationApi from 'Application/api';

function* getFinancailsSummary(action: {
  type: GET_FINANCIALS_SUMMARY;
  inn: string;
}) {
  try {
    const res: ResponseData = yield call(
      ApplicationApi.getFinancialsSummary,
      action.inn
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinancailsSummarySaga() {
  yield takeLatest(GET_FINANCIALS_SUMMARY.REQ, getFinancailsSummary);
}
