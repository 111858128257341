import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostCreateBuyersToSupplierLinkStoreState extends Reducer {}

export enum POST_CREATE_BUYERS_TO_SUPPLIER_LINK {
  REQ = 'POST_CREATE_BUYERS_TO_SUPPLIER_LINK_REQ',
  GOT = 'POST_CREATE_BUYERS_TO_SUPPLIER_LINK_GOT',
  ERR = 'POST_CREATE_BUYERS_TO_SUPPLIER_LINK_ERR',
  RESET = 'POST_CREATE_BUYERS_TO_SUPPLIER_LINK_RESET'
}

export const initialState: PostCreateBuyersToSupplierLinkStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postCreateToBuyersToSupplierLink = (
  state: PostCreateBuyersToSupplierLinkStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PostCreateBuyersToSupplierLinkStoreState => {
  switch (action.type) {
    case POST_CREATE_BUYERS_TO_SUPPLIER_LINK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_CREATE_BUYERS_TO_SUPPLIER_LINK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_CREATE_BUYERS_TO_SUPPLIER_LINK.RESET:
      return {
        ...initialState
      };
    case POST_CREATE_BUYERS_TO_SUPPLIER_LINK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postCreateToBuyersToSupplierLink;
