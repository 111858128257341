import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RadioGroup } from 'shared/ui/RadioGroup';
import {
  GOZ223,
  GOZ44,
  WINNER
} from 'src/features/Filters/Companies/FilterByGoz/types';
import { RadioBtnState } from 'src/features/Companies/types';

interface Props {
  radioBtnState: RadioBtnState;
  handleRadioBtn: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterByGoz: React.FC<Props> = ({ radioBtnState, handleRadioBtn }) => {
  return (
    <>
      <RadioGroup
        label="Победители закупок:"
        name="tenderWinner"
        keyValue={radioBtnState.tenderWinner}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(WINNER).map(value => ({
          value,
          label: WINNER[value]
        }))}
      />
      <RadioGroup
        label="Заказчик по 44-ФЗ:"
        name="fz44"
        keyValue={radioBtnState.isCustomer44}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(GOZ44).map(value => ({
          value,
          label: GOZ44[value]
        }))}
      />
      <RadioGroup
        label="Заказчик по 223-ФЗ:"
        name="fz223"
        keyValue={radioBtnState.isCustomer223}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(GOZ223).map(value => ({
          value,
          label: GOZ223[value]
        }))}
      />
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByGozConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByGoz);

export { FilterByGozConnect as FilterByGoz };
