import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS,
  CRM
} from 'globaltypes';
import { BorrowerCompany } from 'Application/types';

import {
  req as createBorrowerCompany,
  reset as resetCreateBorrowerCompany
} from 'BorrowerCompanies/actions/createBorrowerCompany';
import { req as deleteBorrowerCompany } from 'BorrowerCompanies/actions/deleteBorrowerCompany';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { RequestDataType as CreateBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/createBorrowerCompany';
import { RequestDataType as DeleteBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

import { BorrowerCompanyBlock } from '../BorrowerCompanyBlock/BorrowerCompanyBlock';

import { Spinner } from 'Layouts/components';
import { animation, StepsTransitionGroup, TRANSITION_DIRECTIONS } from 'Common';
import { WarningBlock } from 'Application/components';

import { Input } from 'shared/ui/Input';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import CompaniesApi from 'src/features/Companies/api';
import {
  WarningAddCompanyBtn,
  CompanyTabsStyled,
  CompanyTabsHeader,
  CompanyTabHeaderItem,
  CompanyTabBody,
  CompanyTabItem,
  AddCompanyHeaderItem,
  AddCompanyBtn,
  RemoveCompanyBtn,
  LoaderContainerStyled,
  CompanyTabHeaderRole,
  ModalContainer,
  PopupContentParticipants,
  WrapperInputDetail,
  ButtonWrapper,
  ParticipantsInfo,
  Spinner as SpinnerApi
} from './styles';
import { Button } from 'shared/ui/Button';

interface Tab {
  label: string;
  data: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
    isValid: boolean;
  };
}

interface OwnProps {
  tabs: Tab[];
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  borrowerCompanyFileUploadBlock: any;
}

interface MatchParams {
  id: string;
}

interface StateToProps {
  createStatus: REQUEST_STATUSES;
  deleteStatus: REQUEST_STATUSES;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  createBorrowerCompany: (data: CreateBorrowerCompanyDataType) => void;
  deleteBorrowerCompany: (data: DeleteBorrowerCompanyDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  resetCreateBorrowerCompany: () => void;
}

type Props = OwnProps &
  RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps;

const Role = {
  BORROWER: 'Заемщик',
  PLEDGER: 'Залогодатель',
  GUARANTOR: 'Поручитель'
};

const BorrowerCompaniesTabs: React.FC<Props> = ({
  getCompanyByInn,
  resetStateCompanyByInn,
  companyByInn,
  borrowerCompanyFileUploadBlock,
  createBorrowerCompany,
  deleteBorrowerCompany,
  resetCreateBorrowerCompany,
  createStatus,
  deleteStatus,
  permissions,
  tabs,
  statusInn,
  match
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [animationDirection, setAnimationDirection] = React.useState<
    TRANSITION_DIRECTIONS
  >(TRANSITION_DIRECTIONS.FORWARD);
  const [isModal, setIsModal] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [individualNumber, setIndividualNumber] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [roles, setRoles] = React.useState<string[]>([]);
  const [isInfoPopup, setIsInfoPopup] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [deleteItem, setDeleteItem] = React.useState<BorrowerCompany>(null);

  const participantsModalRef = React.useRef();
  const deleteModalRef = React.useRef();

  React.useEffect(
    () => {
      if (individualNumber.length >= 10) {
        getCompanyByInn({ inn: individualNumber });
      }
      setIsInfoPopup(false);

      return () => resetStateCompanyByInn();
    },
    [individualNumber]
  );

  React.useEffect(
    () => {
      if (statusInn === REQUEST_STATUSES.GOT) {
        setCompanyName(companyByInn.companyShortName);
      }
    },
    [statusInn]
  );

  React.useEffect(
    () => {
      if (tabs.length > 0) {
        setActiveTabIndex(tabs.length - 1);
        setAnimationDirection(TRANSITION_DIRECTIONS.FORWARD);
      }
    },
    [tabs.length]
  );

  React.useEffect(
    () => {
      if (createStatus === REQUEST_STATUSES.GOT) {
        resetCreateBorrowerCompany();
        setIsModal(false);
      }
    },
    [createStatus]
  );

  const changeTab = (index: number, id: number) => {
    setDeleteId(id);
    setActiveTabIndex(index);
    setAnimationDirection(
      index < activeTabIndex
        ? TRANSITION_DIRECTIONS.BACKWARDS
        : TRANSITION_DIRECTIONS.FORWARD
    );
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      participantsModalRef.current &&
      !(participantsModalRef.current as Element).contains(target)
    ) {
      setIsModal(false);
    }
    if (
      deleteModalRef.current &&
      !(deleteModalRef.current as Element).contains(target)
    ) {
      setIsDeleteModal(false);
    }
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    if (name === 'roles') {
      setRoles(prevState => {
        if (!prevState.includes(value)) {
          return [...prevState, value];
        } else {
          return prevState.filter(role => role !== value);
        }
      });
    } else if (name === 'individualNumber') {
      setIndividualNumber(value);
    } else if (name === 'companyName') {
      setCompanyName(value);
    }
  };

  const onPopupClick = () => {
    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
      CompaniesApi.putLockAndUnlockCompany(
        individualNumber,
        'as_analyst',
        true
      );
    }
    setIsInfoPopup(true);
  };

  const onSubmit = () => {
    createBorrowerCompany({
      applicationId: +match.params.id,
      individualNumber,
      name: companyName,
      roles
    });
  };

  return (
    <CompanyTabsStyled>
      <p>Участники заявки:</p>
      <CompanyTabsHeader>
        {tabs.map(({ label, data }, key) => (
          <React.Fragment key={key}>
            <CompanyTabHeaderItem
              selected={key === activeTabIndex}
              animationDirection={animationDirection}
              onClick={() => changeTab(key, data.id)}
            >
              {/* User can't delete first (main) borrower company,
                  because it was put there automatically */}
              {key > 0 && (
                <RemoveCompanyBtn
                  icon={faTimes}
                  onClick={() => {
                    setIsDeleteModal(true);
                    setDeleteItem(data);
                  }}
                />
              )}

              <div>
                <p>{label}</p>
                <p>ИНН {data.individualNumber}</p>
                <CompanyTabHeaderRole selected={key === activeTabIndex}>
                  {data.roles.map((role, index) => (
                    <p key={index}>
                      {role === 'PRINCIPAL'
                        ? 'Принципал'
                        : role === 'GUARANTOR'
                          ? 'Поручитель'
                          : role === 'PLEDGER'
                            ? 'Залогодатель'
                            : role === 'BORROWER' && 'Заемщик'}
                      {index !== data.roles.length - 1 && ','}
                    </p>
                  ))}
                </CompanyTabHeaderRole>
              </div>
            </CompanyTabHeaderItem>
          </React.Fragment>
        ))}

        <AddCompanyHeaderItem onClick={() => setIsModal(true)}>
          <AddCompanyBtn />
          Добавить участника
        </AddCompanyHeaderItem>
      </CompanyTabsHeader>

      {!!tabs[activeTabIndex] ? (
        <StepsTransitionGroup
          {...animation(animationDirection, { duration: 200 })}
          component={CompanyTabBody}
          pageKey={activeTabIndex}
        >
          <CompanyTabItem>
            <BorrowerCompanyBlock
              borrowerCompany={tabs[activeTabIndex].data}
              errors={tabs[activeTabIndex].errors}
              fileUploadBlock={borrowerCompanyFileUploadBlock}
            />
            {/* TODO refactor */}
          </CompanyTabItem>
        </StepsTransitionGroup>
      ) : (
        <WarningBlock
          text={[
            'Вы не добавили ни одной компании заемщика.',
            <React.Fragment>
              Чтобы добавить компанию, нажмите "
              <WarningAddCompanyBtn onClick={() => setIsModal(true)}>
                Добавить
              </WarningAddCompanyBtn>
              ".
            </React.Fragment>,
            `Заявки без документов компаний заемщика банками к рассмотрению не принимаются.`
          ]}
        />
      )}

      {(createStatus === REQUEST_STATUSES.REQUEST ||
        deleteStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={50} />
        </LoaderContainerStyled>
      )}

      <ModalContainer visible={isModal} onClick={handleOutsideClick}>
        <PopupContentParticipants ref={participantsModalRef}>
          <h2>Добавление участника сделки</h2>
          <span onClick={() => setIsModal(false)}>X</span>

          <WrapperInputDetail>
            <Input
              required
              label="ИНН"
              name="individualNumber"
              value={individualNumber}
              onChange={onFilterChange}
            />
            {individualNumber &&
            individualNumber.length < 10 ? null : statusInn ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              statusInn === REQUEST_STATUSES.GOT &&
              !isInfoPopup && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={onPopupClick}
                />
              )
            )}
          </WrapperInputDetail>

          <Input
            required
            label="Компания"
            name="companyName"
            value={companyName}
            onChange={onFilterChange}
          />

          <CheckboxGroup
            required
            name="roles"
            label="Роль"
            checkboxes={Object.keys(Role).map(type => ({
              label: Role[type],
              value: type,
              checked: roles.includes(type),
              onChange: onFilterChange
            }))}
          />

          <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
            <Button
              label="+ Добавить"
              onClick={onSubmit}
              disabled={!companyName || !individualNumber || !roles.length}
            />
            {createStatus === REQUEST_STATUSES.REQUEST && (
              <SpinnerApi icon={faSpinner} />
            )}
          </div>
        </PopupContentParticipants>
      </ModalContainer>

      <ModalContainer visible={isDeleteModal} onClick={handleOutsideClick}>
        <PopupContentParticipants ref={deleteModalRef}>
          <h2>Подтвердите удаление участника из заявки:</h2>
          <span onClick={() => setIsDeleteModal(false)}>X</span>

          {!!deleteItem && (
            <ParticipantsInfo>
              <p>{deleteItem.name}</p>
              <p>ИНН {deleteItem.individualNumber}</p>
              <div>
                {deleteItem.roles.map((role, index) => (
                  <p key={index}>
                    {role === 'PRINCIPAL'
                      ? 'Принципал'
                      : role === 'GUARANTOR'
                        ? 'Поручитель'
                        : role === 'PLEDGER' && 'Залогодатель'}
                    {index !== deleteItem.roles.length - 1 && ','}
                  </p>
                ))}
              </div>
            </ParticipantsInfo>
          )}
          <ButtonWrapper>
            <Button
              label="Удалить"
              onClick={() => {
                setIsDeleteModal(false);
                deleteBorrowerCompany({ id: deleteId });
              }}
            />
            <Button label="Отмена" onClick={() => setIsDeleteModal(false)} />
          </ButtonWrapper>
        </PopupContentParticipants>
      </ModalContainer>
    </CompanyTabsStyled>
  );
};

const mapStateToProps = ({ BorrowerCompanies, SCF, User }: STORE & CRM) => ({
  createStatus: BorrowerCompanies.createBorrowerCompany.status,
  deleteStatus: BorrowerCompanies.deleteBorrowerCompany.status,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createBorrowerCompany,
      resetCreateBorrowerCompany,
      deleteBorrowerCompany,
      getCompanyByInn,
      resetStateCompanyByInn
    },
    dispatch
  );

const BorrowerCompaniesTabsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompaniesTabs)
);

export { BorrowerCompaniesTabsConnect as BorrowerCompaniesTabs };
