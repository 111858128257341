import { GET_PLATFORMS } from '../reducers/getPlatforms';
import { Platform } from 'src/features/PlatformsForProducts/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Platform[];
}

export const req = (id: number) => ({
  type: GET_PLATFORMS.REQ,
  id
});

export const got = (product: ResponseData) => ({
  type: GET_PLATFORMS.GOT,
  product
});

export const err = (error: {}) => ({
  type: GET_PLATFORMS.ERR,
  error
});
