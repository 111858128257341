import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { STORE } from 'src/globaltypes';
import {
  ImageStyled,
  InnPopupStyled
} from 'src/features/Layouts/components/InnPopup/styled';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { connect } from 'react-redux';

interface StateToProps {
  isAuthorized: boolean;
}

interface DispatchToProps {}

interface OwnProps {
  companyInfo?: ResponseDataType;
  name?: string;
  onPopupClick?: (name?: string) => void;
  children?: React.ReactNode;
}

type Props = OwnProps & StateToProps & DispatchToProps;

const InnPopup: React.FC<Props> = ({
  companyInfo,
  onPopupClick,
  name,
  isAuthorized,
  children
}) => {
  const [icon, setIcon] = React.useState(null);

  React.useEffect(
    () => {
      if (companyInfo) {
        loadIcon(companyInfo);
      }
    },
    [companyInfo]
  );

  const loadIcon = async (company: ResponseDataType) => {
    if (company.okvedCode) {
      const code = parseInt(company.okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setIcon(icon.default);
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setIcon(null);
      }
    }
  };

  return (
    <InnPopupStyled onClick={() => onPopupClick(name)}>
      <div>
        {children ? (
          children
        ) : (
          <>
            <p>{companyInfo.companyFullName}</p>
            {companyInfo.companyAddress &&
            companyInfo.companyAddress.length > 3 ? (
              <p>{companyInfo.companyAddress}</p>
            ) : (
              ''
            )}
            <p>ИНН {companyInfo.inn}</p>
            <p>КПП {companyInfo.kpp}</p>
            <p>
              {companyInfo.okvedCode} {companyInfo.okvedName}
            </p>
            {isAuthorized && (
              <ImageStyled src={icon} alt={companyInfo.okvedName} />
            )}
          </>
        )}
      </div>
    </InnPopupStyled>
  );
};

const mapStateToProps = ({ Cached }: STORE) => ({
  isAuthorized: Cached.authorized
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const InnPopupConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(InnPopup);

export { InnPopupConnect as InnPopup };
