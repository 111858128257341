import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/createContact';

import { CREATE_CONTACT } from '../reducers/createContact';
import ContactsApi from '../api';

function* createContact(action: {
  type: CREATE_CONTACT;
  data: any;
}): Generator {
  try {
    let res: any;

    res = yield call(ContactsApi.createContact, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createContactSaga(): Generator {
  yield takeLatest(CREATE_CONTACT.REQ, createContact);
}
