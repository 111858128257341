import {
  GUARANTEES_FORM_ACTIONS,
  SetGuaranteesApplicationType
} from 'Application/reducers/setGuaranteesFormData';

export const setGuaranteesFormData = (data: SetGuaranteesApplicationType) => ({
  type: GUARANTEES_FORM_ACTIONS.SET,
  data
});

export const resetGuaranteesFormData = () => ({
  type: GUARANTEES_FORM_ACTIONS.RESET
});
