import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData as GetUsersListRequestData } from '../actions/getUserList';

export default class UsersApi {
  static getAllUsers(data: GetUsersListRequestData) {
    return ApiRequest('/api/account/profile/all', 'get', data);
  }

  static getAllUsersForBank(bankInn: string) {
    return ApiRequest(`/api/account/profile/inn/${bankInn}`);
  }

  static getAllUsersForExternalAgent(data: any) {
    return ApiRequest(`/api/partner_role/users/all`, 'get', data);
  }

  static getUser(id: string) {
    return ApiRequest(`/api/account/profile/id/${id}`);
  }

  static postAllowProcess(id: string, typeProcess: string) {
    return ApiRequest(
      `/api/account/profile/id/${id}/simple_process/${typeProcess}`,
      'post'
    );
  }

  static postAllowFields(id: string, typeProcess: string) {
    return ApiRequest(
      `/api/account/profile/id/${id}/blank_fields/${typeProcess}`,
      'post'
    );
  }

  static getUserLeads(id: string) {
    return ApiRequest(`/api/lead/user/${id}`);
  }

  static getUserApplication(id: string) {
    return ApiRequest(`/api/application/user/${id}`);
  }
}
