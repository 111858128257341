import { put, takeLatest } from 'redux-saga/effects';

import { err } from 'entities/SCF/Debtor/model/actions/getDebtorSignatureFile';
import { GET_DEBTOR_SIGNATURE_FILE } from 'entities/SCF/Debtor/model/reducers/getDebtorSignatureFile';

function* getDebtorSignatureFile(action: {
  type: GET_DEBTOR_SIGNATURE_FILE;
  guid: string;
}) {
  try {
    const url = `/api/purchase_contract/debtor_role/${
      action.guid
    }/get_signature_file`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorSignatureFileSaga() {
  yield takeLatest(GET_DEBTOR_SIGNATURE_FILE.REQ, getDebtorSignatureFile);
}
