import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum SIGNIN_BY_LINK {
  REQ = 'SIGNIN_BY_LINK_REQ',
  GOT = 'SIGNIN_BY_LINK_GOT',
  ERR = 'SIGNIN_BY_LINK_ERR'
}

export interface SignInByLinkStoreState extends Reducer {
  data: string;
}

export const initialState: SignInByLinkStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const signInByLink = (
  state: SignInByLinkStoreState = initialState,
  action: {
    type: string;
    refreshToken: string;
    error: {};
  }
): SignInByLinkStoreState => {
  switch (action.type) {
    case SIGNIN_BY_LINK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SIGNIN_BY_LINK.GOT:
      return {
        ...state,
        data: action.refreshToken,
        status: REQUEST_STATUSES.GOT
      };
    case SIGNIN_BY_LINK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default signInByLink;
