import { GET_CLIENTS_LIST } from 'src/features/Clients/reducers/getClientsList';
import { ClientsListType } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ClientsListType[];
}

export interface RequestData {
  companyInn?: string;
  companyName?: string;
}

export const req = (data?: RequestData) => ({
  type: GET_CLIENTS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_CLIENTS_LIST.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_CLIENTS_LIST.ERR,
  error
});
