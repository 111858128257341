export enum COMPANY_LIST_TYPE {
  ALL = 'ALL',
  TARGETS = 'TARGETS',
  BACKBONE = 'BACKBONE',
  MY = 'MY',
  BY_HOLDING = 'BY_HOLDING'
}

export enum AVAILABILITY_OF_CONTACTS {
  NOT_CHECKED = 'не проверяется',
  HAS_CONTACTS = 'есть контакты',
  NO_CONTACTS = 'нет контаков'
}

export enum COMPANY_TABS {
  DEFAULT = 'Заявки по компании',
  FINANCIAL_DATA = 'Финансы',
  DOSSIER = 'Кредитное досье',
  BENEFICIARIES = 'Бенефициары',
  AGENT_APPLICATIONS = 'Заявки агентские',
  CONTACTS = 'Контакты',
  USERS = 'Пользователи',
  ACTIVITIES = 'Активности',
  COUNTERPARTIES = 'Контрагенты',
  BANK_PRODUCTS = 'Банковские продукты',
  TENDERS = 'Закупки'
}

export interface RadioBtnState {
  govSupportInfo: string;
  creditInfo: string;
  pledgeInfo: string;
  openLimitsInfo: string;
  tenderWinner: string;
  isCustomer44: string;
  isCustomer223: string;
  hasContacts: string;
  locked: string;
}

export interface CheckboxState {
  role: string[];
}
