import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  parse,
  parseISO,
  setHours,
  setMinutes
} from 'date-fns';

import { Loader, Pagination } from 'Layouts/components';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  req as getAllDiscountAuctions,
  ResponseData
} from 'src/features/SCF/actions/getAllDiscountAuctions';
import {
  req as postCreateDiscountAuctions,
  RequestData
} from 'src/features/SCF/actions/postCreateDiscountAuctions';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  AuctionHeader,
  Center,
  Flex,
  InputContainer,
  ModalStyled,
  Status
} from './styled';
import { Modal, ScrollTopComponent } from 'src/features/Common';
import { openModal } from 'Modal/actions/toggleModal';
import { closeModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';
import { Button } from 'shared/ui/Button';
import { formSumStringThousands } from 'shared/utils/Utils';
import { Input } from 'shared/ui/Input';
import { InputDate } from 'shared/ui/InputDate';
import { InputTime } from 'shared/ui/InputTime';

interface StateToProps {
  auctionList: ResponseData;
  statusAuctionList: REQUEST_STATUSES;
  statusCreateDiscountAuctions: REQUEST_STATUSES;
}

interface DispatchToProps {
  getAllDiscountAuctions: () => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  postCreateDiscountAuctions: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const DiscountAuctionsList: React.FC<Props> = ({
  getAllDiscountAuctions,
  auctionList,
  statusAuctionList,
  openModal,
  closeModal,
  postCreateDiscountAuctions,
  statusCreateDiscountAuctions,
  history
}) => {
  const [formData, setFormData] = React.useState({
    liquidityLimit: '',
    minDiscountRate: '',
    targetDiscountRate: '',
    resultsDate: '',
    resultsTime: setHours(setMinutes(new Date(), 0), 13),
    payDate: ''
  });

  React.useEffect(() => {
    getAllDiscountAuctions();
  }, []);

  React.useEffect(
    () => {
      if (statusCreateDiscountAuctions === REQUEST_STATUSES.GOT) {
        getAllDiscountAuctions();
        closeModal();
      }
    },
    [statusCreateDiscountAuctions]
  );

  const navigateToAuction = (guid: string) => {
    history.push(`/debtor/discount_auctions/${guid}`);
  };

  const handleInputChange = (name: string, value: any) => {
    if (
      name === 'resultsDate' ||
      name === 'resultsTime' ||
      name === 'payDate'
    ) {
      setFormData(prev => ({ ...prev, [name]: value }));
    } else if (name === 'liquidityLimit') {
      const rawValue = value.replace(/\s+/g, '');
      if (/^\d*$/.test(rawValue)) {
        setFormData(prev => ({ ...prev, [name]: rawValue }));
      }
    } else if (/^\d*$/.test(value)) {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleNumericChange = (name: string, value: any) => {
    const regex = /^\d*(\.\d{0,4})?$/;
    if (regex.test(value)) {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const isFormValid = () => {
    return Object.values(formData).every(
      value => value !== '' && value !== null && value !== undefined
    );
  };

  const concatDate = (): string => {
    if (!formData.resultsDate || !formData.resultsTime) return;

    const parsedDate = parse(formData.resultsDate, 'dd/MM/yyyy', new Date());

    const time = new Date(formData.resultsTime).toLocaleTimeString();
    const [hours, minutes] = time.split(':').map(Number);

    const localDateTime = new Date(parsedDate.setHours(hours, minutes));

    return format(localDateTime, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  };

  function statusView(resultsDateTimeStr: string, payDate: string) {
    const currentDate = new Date();
    const resultsDateTime = parseISO(resultsDateTimeStr);
    const payDatePlusOne = payDate ? addDays(new Date(payDate), 1) : null;

    if (isBefore(currentDate, resultsDateTime)) {
      return 'Сбор заявок';
    }

    if (
      isAfter(currentDate, resultsDateTime) &&
      payDatePlusOne &&
      isBefore(currentDate, payDatePlusOne)
    ) {
      return 'Подведение итогов';
    }

    if (payDatePlusOne && isAfter(currentDate, payDatePlusOne)) {
      return 'Аукцион завершен';
    }
  }

  return (
    <ScrollTopComponent>
      <AuctionHeader>
        <h2>Аукционы дисконта</h2>

        <Button
          label="+ Создать"
          onClick={() => openModal({ name: MODAL_NAMES.DISCOUNT_AUCTION })}
        />
      </AuctionHeader>

      {statusAuctionList === REQUEST_STATUSES.REQUEST && <Loader />}
      {statusAuctionList === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">ID</TableThStyled>
              <TableThStyled width="12%">Дата создания</TableThStyled>
              <TableThStyled width="15%">Подведение итогов</TableThStyled>
              <TableThStyled width="12%">Дата оплаты</TableThStyled>
              <TableThStyled width="20%">Лимит задолженности</TableThStyled>
              <TableThStyled width="20%">Минимальная ставка</TableThStyled>
              <TableThStyled width="20%">Целевая ставка</TableThStyled>
              <TableThStyled width="20%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {auctionList.items.map((item, key) => (
              <TableRowStyled
                key={key}
                onClick={() => navigateToAuction(item.guid)}
              >
                <td>{item.guid.substring(item.guid.length - 5)}</td>
                <td>{new Date(item.createdDateTime).toLocaleDateString()}</td>
                <td>
                  <Center w="80%">
                    <div>
                      {new Date(item.resultsDateTime).toLocaleDateString()}
                    </div>
                    <div>{format(new Date(item.createdDateTime), 'HH:mm')}</div>
                  </Center>
                </td>
                <td>
                  <Center w="70%">
                    {new Date(item.payDate).toLocaleDateString()}
                  </Center>
                </td>
                <td>
                  <Center w="70%" style={{ textAlign: 'end' }}>
                    {formSumStringThousands(item.liquidityLimit, {
                      maxFractionDigits: 0,
                      minFractionDigits: 0
                    })}{' '}
                    руб.
                  </Center>
                </td>
                <td>
                  <Center w="60%" style={{ textAlign: 'end' }}>
                    {item.minDiscountRate} % годовых
                  </Center>
                </td>
                <td>
                  <Center w="50%" style={{ textAlign: 'end' }}>
                    {item.targetDiscountRate} % годовых
                  </Center>
                </td>
                <td>
                  <Status
                    status={statusView(item.resultsDateTime, item.payDate)}
                  >
                    {statusView(item.resultsDateTime, item.payDate)}
                  </Status>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={auctionList} />

      <Modal name={MODAL_NAMES.DISCOUNT_AUCTION}>
        <ModalStyled>
          <h3>Новый аукцион</h3>

          <div>
            <p>Лимит задолженности:</p>
            <Flex>
              <InputContainer w="50%">
                <Input
                  name="liquidityLimit"
                  value={
                    formData.liquidityLimit
                      ? new Intl.NumberFormat('ru-RU').format(
                          +formData.liquidityLimit
                        )
                      : ''
                  }
                  onChange={e =>
                    handleInputChange('liquidityLimit', e.currentTarget.value)
                  }
                />
              </InputContainer>
              <p>руб.</p>
            </Flex>
          </div>

          <div>
            <p>Минимальная ставка дисконта:</p>
            <Flex>
              <InputContainer w="35%">
                <Input
                  name="minDiscountRate"
                  value={formData.minDiscountRate}
                  onChange={e =>
                    handleNumericChange(
                      'minDiscountRate',
                      e.currentTarget.value
                    )
                  }
                />
              </InputContainer>
              <p>% годовых</p>
            </Flex>
          </div>

          <div>
            <p>Целевая ставка дисконта:</p>
            <Flex>
              <InputContainer w="35%">
                <Input
                  name="targetDiscountRate"
                  value={formData.targetDiscountRate}
                  onChange={e =>
                    handleNumericChange(
                      'targetDiscountRate',
                      e.currentTarget.value
                    )
                  }
                />
              </InputContainer>
              <p>% годовых</p>
            </Flex>
          </div>

          <div>
            <p>Подведение итогов:</p>
            <Flex>
              <InputContainer w="35%">
                <InputDate
                  name="resultsDate"
                  minDate={new Date()}
                  dateValue={formData.resultsDate}
                  onDateChange={date => handleInputChange('resultsDate', date)}
                />
              </InputContainer>
              <InputContainer w="35%">
                <InputTime
                  name="resultsTime"
                  dateValue={formData.resultsTime}
                  onDateChange={date => handleInputChange('resultsTime', date)}
                />
              </InputContainer>
            </Flex>
          </div>

          <div>
            <p>Дата плановой оплаты:</p>
            <InputContainer w="35%">
              <InputDate
                name="payDate"
                minDate={new Date()}
                dateValue={formData.payDate}
                onDateChange={date => handleInputChange('payDate', date)}
              />
            </InputContainer>
          </div>

          <Button
            label="+ Добавить"
            w="fit-content"
            disabled={!isFormValid()}
            onClick={() =>
              postCreateDiscountAuctions({
                LiquidityLimit: +formData.liquidityLimit,
                MinDiscountRate: +formData.minDiscountRate,
                PayDate: format(
                  new Date(parse(formData.payDate, 'dd/MM/yyyy', new Date())),
                  "yyyy-MM-dd'T'HH:mm:ss'Z'"
                ),
                ResultsDateTime: concatDate(),
                TargetDiscountRate: +formData.targetDiscountRate
              })
            }
          />
        </ModalStyled>
      </Modal>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  auctionList: SCF.getAllDiscountAuctionsByDebtor.data,
  statusAuctionList: SCF.getAllDiscountAuctionsByDebtor.status,
  statusCreateDiscountAuctions: SCF.postCreateDiscountAuctions.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllDiscountAuctions,
      openModal,
      closeModal,
      postCreateDiscountAuctions
    },
    dispatch
  );

const DiscountAuctionsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DiscountAuctionsList)
);

export { DiscountAuctionsListConnect as DiscountAuctionsList };
