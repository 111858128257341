import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Supplier/model/actions/getEarlyPaymentApplicationsSupplierRole';

export interface GetEarlyPaymentApplicationsSupplierRoleStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE {
  REQ = 'GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE_REQ',
  GOT = 'GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE_GOT',
  ERR = 'GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE_ERR',
  RESET = 'GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE_RESET'
}

export const initialState: GetEarlyPaymentApplicationsSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getEarlyPaymentApplicationsSupplierRole = (
  state: GetEarlyPaymentApplicationsSupplierRoleStoreState = initialState,
  action: {
    type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetEarlyPaymentApplicationsSupplierRoleStoreState => {
  switch (action.type) {
    case GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default getEarlyPaymentApplicationsSupplierRole;
