import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons';

import { BoxStyled, IconWrapper } from 'shared/styled';

export const TemporarilyBlockedError: React.FC = () => (
  <React.Fragment>
    <h1>Пользователь временно заблокирован</h1>
    <IconWrapper>
      <FontAwesomeIcon icon={faUniversalAccess} />
    </IconWrapper>
    <BoxStyled>
      <p>
        Пользователь временно заблокирован из-за большого числа попыток подбора
        пароля.
        <br />
        Повторите действие чуть позже.
      </p>
    </BoxStyled>
  </React.Fragment>
);
