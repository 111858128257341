import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface CreateBankAccountStoreState extends Reducer {}

export enum CREATE_BANK_ACCOUNT {
  REQ = 'CREATE_BANK_ACCOUNT_REQ',
  GOT = 'CREATE_BANK_ACCOUNT_GOT',
  ERR = 'CREATE_BANK_ACCOUNT_ERR',
  RESET = 'CREATE_BANK_ACCOUNT_RESET'
}

export const initialState: CreateBankAccountStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const createBankAccount = (
  state: CreateBankAccountStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): CreateBankAccountStoreState => {
  switch (action.type) {
    case CREATE_BANK_ACCOUNT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CREATE_BANK_ACCOUNT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CREATE_BANK_ACCOUNT.RESET:
      return {
        ...initialState
      };
    case CREATE_BANK_ACCOUNT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default createBankAccount;
