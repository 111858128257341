import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getCompanyByInnThirdParty';

export interface GetCompanyByInnThirdPartyStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_COMPANY_BY_INN_THIRD_PARTY {
  GET_COMPANY_BY_INN_THIRD_PARTY_REQ = 'GET_COMPANY_BY_INN_THIRD_PARTY_REQ',
  GET_COMPANY_BY_INN_THIRD_PARTY_GOT = 'GET_COMPANY_BY_INN_THIRD_PARTY_GOT',
  GET_COMPANY_BY_INN_THIRD_PARTY_ERR = 'GET_COMPANY_BY_INN_THIRD_PARTY_ERR',
  GET_COMPANY_BY_INN_THIRD_PARTY_RESET = 'GET_COMPANY_BY_INN_THIRD_PARTY_RESET'
}

export const initialState: GetCompanyByInnThirdPartyStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    inn: '',
    companyShortName: '',
    companyFullName: '',
    ogrn: '',
    regDate: null,
    kpp: '',
    pfr: '',
    fss: '',
    startCapital: null,
    companyAddress: '',
    okvedCode: '',
    okvedName: '',
    okvedPic: '',
    customer223: false,
    customer44: false,
    debtor: false,
    limits: false,
    employees: null,
    employeesYear: null,
    TaxSystem: null,
    taxSystemName: '',
    taxSystemCode: null,
    taxes: { taxYear: null, taxInfo: [] }
  },
  error: {}
};

const getCompanyByInnThirdParty = (
  state: GetCompanyByInnThirdPartyStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN_THIRD_PARTY;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyByInnThirdPartyStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN_THIRD_PARTY.GET_COMPANY_BY_INN_THIRD_PARTY_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN_THIRD_PARTY.GET_COMPANY_BY_INN_THIRD_PARTY_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN_THIRD_PARTY.GET_COMPANY_BY_INN_THIRD_PARTY_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_COMPANY_BY_INN_THIRD_PARTY.GET_COMPANY_BY_INN_THIRD_PARTY_RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInnThirdParty;
