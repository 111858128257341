import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/Person/actions/getCompanyByInnConfirmedPerson';
import { GET_COMPANY_BY_INN_CONFIRMED_PERSON } from 'src/features/Person/reducers/getCompanyByInnConfirmedPerson';
import BusinessApi from 'src/features/SCF/api/index';

function* getCompanyByInnConfirmedPerson(action: {
  type: GET_COMPANY_BY_INN_CONFIRMED_PERSON;
  data: string[];
}): Generator {
  try {
    const res: any = yield all(
      action.data.map(item =>
        call(BusinessApi.getCompanyByInnThirdParty, { inn: item, force: true })
      )
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnConfirmedPersonSaga(): Generator {
  yield takeLatest(
    GET_COMPANY_BY_INN_CONFIRMED_PERSON.REQ,
    getCompanyByInnConfirmedPerson
  );
}
