import { all, call, put, takeLatest } from 'redux-saga/effects';
import CommentsApi from 'Comments/api';

interface RequestCommonDataType {
  id: number;
}

export interface RequestDataType extends RequestCommonDataType {
  file: FormData;
}

export interface SagaRequestDataType extends RequestCommonDataType {
  files: any[];
}

export function* uploadCommentDocumentsSaga(data: SagaRequestDataType) {
  const { files, ...apiRequestData } = data;

  const newDocuments = yield all(
    files.map(function*(file) {
      const formData = new FormData();
      formData.append('file', file);

      const requestData = {
        ...apiRequestData,
        file: formData
      };

      return yield call(CommentsApi.uploadCommentDocuments, requestData);
    })
  );
  return newDocuments;
}
