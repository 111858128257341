import { GET_PRODUCT_BANK } from 'src/features/ProductsForBank/reducers/getProduct';
import { ProductsListBank as ResponseData } from 'src/features/ProductsForBank/types';

export const req = (id: number) => ({
  type: GET_PRODUCT_BANK.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCT_BANK.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCT_BANK.ERR,
  error
});
