import SCFRegisterSupplierSaga from 'entities/SCF/Supplier/model/sagas/SCFRegisterSupplier';
import getFactoringConnectionsSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/getFactoringConnectionsSupplierRole';
import getFactoringContractsSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/getFactoringContractsSupplierRole';
import getFactoringContractSupplierRoleByGuidSaga from 'entities/SCF/Supplier/model/sagas/getFactoringContractSupplierRoleByGuid';
import getEarlyPaymentApplicationsSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/getEarlyPaymentApplicationsSupplierRole';
import getMonetaryClaimsSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/getMonetaryClaimsSupplierRole';
import getInverstorsListSupplierRoleByGuidSaga from 'entities/SCF/Supplier/model/sagas/getInverstorsListSupplierRoleByGuid';
import getInvestorOneDebtorSupplierRoleByGuidSaga from 'entities/SCF/Supplier/model/sagas/getInvestorOneDebtorSupplierRoleByGuid';
import getMonetaryClaimsEarlyPaymentApplicationsByGuidSaga from 'entities/SCF/Supplier/model/sagas/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import getStartRateSupplierRoleDataSaga from 'entities/SCF/Supplier/model/sagas/getStartRateSupplierRole';
import postFactoringConnectionSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/postFactoringConnectionSupplierRole';
import postEarlyPaymentApplicationSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/postEarlyPaymentApplicationSupplierRole';
import addInvestorSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/addInvestorSupplierRole';
import postAgreementSignatureSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/postAgreementSignatureSupplierRole';
import putPurchaseContractSignatureSupplierRoleSaga from 'entities/SCF/Supplier/model/sagas/putPurchaseContractSignatureSupplierRole';
import getByMonetaryClaimSupplierSaga from 'entities/SCF/Supplier/model/sagas/getByMonetaryClaimSupplier';
import checkSCFStatusSaga from 'entities/SCF/Supplier/model/sagas/SCFStatus';
import postEarlyDatePickSaga from 'src/entities/SCF/Supplier/model/sagas/postEarlyDatePick';
import uploadSupplierContractDocumentSaga from 'entities/SCF/Supplier/model/sagas/uploadSupplierContractDocument';
import getSupplierNotificationSettingsDataSaga from 'entities/SCF/Supplier/model/sagas/getSupplierNotificationSettings';
import postSupplierNotificationSettingsSaga from 'entities/SCF/Supplier/model/sagas/postSupplierNotificationSettings';
import getSignatureFileSaga from 'entities/SCF/Supplier/model/sagas/getSignatureFile';
import getSupplierCounterSidebarSaga from 'entities/SCF/Supplier/model/sagas/getSupplierCounterSidebar';
import putAddSigneeSaga from 'entities/SCF/Supplier/model/sagas/putAddSignee';
import patchPurchaseContractSupplierSaga from 'entities/SCF/Supplier/model/sagas/patchPurchaseContractSupplier';
import getContractBySupplierSaga from 'entities/SCF/Supplier/model/sagas/getContractBySupplier';
import postAddToContractBySupplierSaga from 'entities/SCF/Supplier/model/sagas/postAddToContractBySupplier';
import postConfirmContractBySupplierSaga from 'entities/SCF/Supplier/model/sagas/postConfirmContractBySupplier';
import postOfferDiscountSaga from 'entities/SCF/Supplier/model/sagas/postOfferDiscount';
import putAddPoaFileSaga from 'entities/SCF/Supplier/model/sagas/putAddPoaFile';

export default [
  SCFRegisterSupplierSaga,
  getFactoringConnectionsSupplierRoleSaga,
  getFactoringContractsSupplierRoleSaga,
  getFactoringContractSupplierRoleByGuidSaga,
  getEarlyPaymentApplicationsSupplierRoleSaga,
  getMonetaryClaimsSupplierRoleSaga,
  getInverstorsListSupplierRoleByGuidSaga,
  getInvestorOneDebtorSupplierRoleByGuidSaga,
  getMonetaryClaimsEarlyPaymentApplicationsByGuidSaga,
  getStartRateSupplierRoleDataSaga,
  postFactoringConnectionSupplierRoleSaga,
  postEarlyPaymentApplicationSupplierRoleSaga,
  addInvestorSupplierRoleSaga,
  postAgreementSignatureSupplierRoleSaga,
  putPurchaseContractSignatureSupplierRoleSaga,
  getByMonetaryClaimSupplierSaga,
  checkSCFStatusSaga,
  postEarlyDatePickSaga,
  uploadSupplierContractDocumentSaga,
  getSupplierNotificationSettingsDataSaga,
  postSupplierNotificationSettingsSaga,
  getSignatureFileSaga,
  getSupplierCounterSidebarSaga,
  putAddSigneeSaga,
  patchPurchaseContractSupplierSaga,
  getContractBySupplierSaga,
  postAddToContractBySupplierSaga,
  postConfirmContractBySupplierSaga,
  postOfferDiscountSaga,
  putAddPoaFileSaga
];
