import { POST_SELECT_APPLICATION } from '../reducers/postSelectApplication';

export const req = (data: any) => ({
  type: POST_SELECT_APPLICATION.REQ,
  data
});

export const reset = () => ({
  type: POST_SELECT_APPLICATION.RESET
});

export const got = data => ({
  type: POST_SELECT_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_SELECT_APPLICATION.ERR,
  error
});
