import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/getDocumentListClient';

import { GET_DOCUMENT_LIST_CLIENT } from 'entities/Cabinet/Client/model/reducers/getDocumentListClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* getDocumentListClient(action: {
  type: GET_DOCUMENT_LIST_CLIENT;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(
      ClientRoleApi.getDocumentListClient,
      action.guid
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentListClientSaga(): Generator {
  yield takeLatest(GET_DOCUMENT_LIST_CLIENT.REQ, getDocumentListClient);
}
