import { rr } from 'shared/styled';
import styled from 'styled-components';

export const SupplierInfoArea = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 30px;

  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin: 0;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    color: #000000;
  }
`;

export const SupplierInfo = styled.div`
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: ${rr};
    font-size: 1.3em;
    line-height: 140%;
    letter-spacing: 0.5px;
    margin-right: 20px;
  }
`;
