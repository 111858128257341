import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { SentryErrorCatcher } from 'App/components';
import { Header, HeaderSCF } from 'Layouts/components';
import { STORE } from 'globaltypes';

interface StateProps {
  authorized: boolean;
}

interface OwnProps {
  component: any;
  path: string;
}

type Props = StateProps & OwnProps;

const PublicPage = ({ authorized, component: Component, ...rest }: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        !authorized ? <Component {...props} /> : <Redirect to="/cabinet" />
      }
    />
  </SentryErrorCatcher>
);

const PublicSupplierPage = ({
  authorized,
  component: Component,
  ...rest
}: Props) => (
  <SentryErrorCatcher>
    <Route
      {...rest}
      render={props =>
        !authorized ? <Component {...props} /> : <Redirect to="/supplier" />
      }
    />
  </SentryErrorCatcher>
);

const mapStateToProps = ({ Cached }: STORE): StateProps => ({
  authorized: Cached.authorized
});

const PublicConnect = connect<StateProps>(mapStateToProps)(PublicPage);
export { PublicConnect as PublicPage };

const PublicSupplierConnect = connect<StateProps>(mapStateToProps)(
  PublicSupplierPage
);
export { PublicSupplierConnect as PublicSupplierPage };
