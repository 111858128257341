import { POST_NON_FINANCIAL_DOCUMENT_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postNonFinancialDocumentExternal';

export const req = (guid: string, data: FormData) => ({
  type: POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.REQ,
  guid,
  data
});

export const got = () => ({
  type: POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.GOT
});

export const err = (error: {}) => ({
  type: POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.ERR,
  error
});
