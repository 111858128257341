import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as postCompaniesList,
  RequestData as ReqPostCompaniesList
} from 'src/features/CompaniesLists/actions/postCompaniesList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import {
  FormContainer,
  FormItemContainer,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from './styled';
import { CRM, REQUEST_STATUSES } from 'src/globaltypes';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  id: string;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  postCompaniesList: (data: ReqPostCompaniesList) => void;
}

interface StateFields {
  name: string;
  isSupplier: boolean;
  listType: string;
  innDebtor: string;
  isDebtor: boolean;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const ListType = [
  'Простой',
  'Поставщики дебитора',
  'Покупатели поставщика',
  'Дебиторы фактора'
];

const CompaniesListNew: React.FC<Props> = ({
  postCompaniesList,
  id,
  status,
  history
}) => {
  const [fields, setFields] = React.useState<StateFields>({
    name: '',
    isSupplier: false,
    listType: '',
    innDebtor: '',
    isDebtor: false
  });

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT && id) {
        history.push({
          pathname: `/crm/companies_lists/${id}`,
          search: 'page=1'
        });
      }
    },
    [status, id]
  );

  const renderToggleButton = (buttonName: keyof StateFields) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() => handleChange(buttonName, false)}
          active={fields[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() => handleChange(buttonName, true)}
          active={fields[buttonName]}
        >
          да
        </ToggleButtonYes>
      </ToggleButtonContainer>
    );
  };

  const handleChange = (name: string, value: unknown) => {
    setFields(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Создание списка компаний</ApplicationTitle>

      <FormContainer>
        <FormItemContainer>
          <p>Название:</p>

          <Input
            name="name"
            value={fields.name}
            onChange={({ currentTarget: { name, value } }) =>
              handleChange(name, value)
            }
          />
        </FormItemContainer>

        <FormItemContainer>
          <p>Тип списка:</p>

          <Select
            options={ListType.map((type, index) => ({
              id: index.toString(),
              name: type
            }))}
            name="listType"
            value={fields.listType}
            onChange={({ currentTarget: { name, value } }) =>
              handleChange(name, value)
            }
          />
        </FormItemContainer>

        {(fields.listType === '1' ||
          fields.listType === '2' ||
          fields.listType === '3') && (
          <FormItemContainer>
            <p>
              {fields.listType === '1'
                ? 'ИНН дебитора:'
                : fields.listType === '2'
                  ? 'ИНН поставщика'
                  : fields.listType === '3' && 'ИНН фактора'}
            </p>

            <Input
              name="innDebtor"
              value={fields.innDebtor}
              onChange={({ currentTarget: { name, value } }) =>
                handleChange(name, value)
              }
            />
          </FormItemContainer>
        )}

        {(fields.listType === '1' ||
          fields.listType === '2' ||
          fields.listType === '3') && (
          <FormItemContainer>
            <p>
              {fields.listType === '1'
                ? 'Поставщики из списка будут видны дебитору'
                : fields.listType === '2'
                  ? 'Покупатели из списка будут видны поставщику'
                  : fields.listType === '3' &&
                    'Дебиторы из списка будут видны фактору'}
            </p>

            {renderToggleButton('isSupplier')}
          </FormItemContainer>
        )}

        {(fields.listType === '1' ||
          fields.listType === '2' ||
          fields.listType === '3') && (
          <FormItemContainer>
            <p>
              {fields.listType === '1'
                ? 'Дебитор будет виден поставщикам из списка'
                : fields.listType === '2'
                  ? 'Поставщик будет виден покупателям из списка'
                  : fields.listType === '3' &&
                    'Фактор будет виден дебиторам из списка'}
            </p>

            {renderToggleButton('isDebtor')}
          </FormItemContainer>
        )}

        <Button
          label="Создать"
          disabled={
            !fields.name ||
            !fields.listType ||
            ((fields.listType === '1' ||
              fields.listType === '2' ||
              fields.listType === '3') &&
              !fields.innDebtor)
          }
          onClick={() =>
            postCompaniesList({
              Name: fields.name,
              Type: fields.listType,
              CompanyInn: fields.innDebtor,
              openForCompany: fields.isSupplier,
              openForList: fields.isDebtor
            })
          }
        />
      </FormContainer>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ CompaniesListCRM }: CRM) => ({
  id: CompaniesListCRM.postCompaniesList.id,
  status: CompaniesListCRM.postCompaniesList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ postCompaniesList }, dispatch);

const CompaniesListNewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompaniesListNew)
);

export { CompaniesListNewConnect as CompaniesListNew };
