import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Comments/actions/postReply';
import { req as getCommentsList } from 'Comments/actions/getCommentsList';
import { RequestDataType as PostReplyDataType } from 'Comments/reducers/postReply';

import { RequestDataType, POST_REPLY } from 'Comments/reducers/postReply';
import { uploadCommentDocumentsSaga } from 'Comments/sagas/uploadCommentDocuments';

import CommentsApi from 'Comments/api';

import {
  ApplicationViewContext,
  ApplicationViewContextType
} from 'Application/components';
import notifyAboutCommentSaga from './notifyAboutComment';

function* postReply(action: { type: POST_REPLY; data: RequestDataType }) {
  try {
    const { id, applicationId, type, files, body } = action.data;

    const res:any = yield call(CommentsApi.postReply, {
      id,
      body
    } as PostReplyDataType);

    yield call(uploadCommentDocumentsSaga, {
      id: res.id,
      files
    });

    yield put(
      getCommentsList({
        id: applicationId,
        type
      })
    );
    yield put(got(res));
    yield call(notifyAboutCommentSaga, {commentId: res.id});
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postReplySaga() {
  yield takeLatest(POST_REPLY.REQ, postReply);
}
