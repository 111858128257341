import { combineReducers } from 'redux';
import getUsersList, {
  GetUserListStoreState
} from 'src/features/Users/reducers/getUserList';
import getUsersListForBankPage, {
  GetUserListForBankPageStoreState
} from 'src/features/Users/reducers/getUserListForBankPage';
import getUser, { GetUserStoreState } from '../reducers/getUser';
import postAllowProcess, {
  PostAllowProcessStoreState
} from '../reducers/postAllowProcess';
import postAllowFields, {
  PostAllowFieldsStoreState
} from '../reducers/postAllowFields';
import getUserLeads, { GetUserLeadsStoreState } from '../reducers/getUserLeads';
import getUserApplications, {
  GetUserApplicationsStoreState
} from '../reducers/getUserApplication';
import getAllUsersForExternalAgent, {
  GetAllUsersForExternalAgentStoreState
} from '../reducers/getAllUsersForExternalAgent';

export interface UsersReducers {
  getUsersList: GetUserListStoreState;
  getUsersListForBankPage: GetUserListForBankPageStoreState;
  getUser: GetUserStoreState;
  postAllowProcess: PostAllowProcessStoreState;
  postAllowFields: PostAllowFieldsStoreState;
  getUserLeads: GetUserLeadsStoreState;
  getUserApplications: GetUserApplicationsStoreState;
  getAllUsersForExternalAgent: GetAllUsersForExternalAgentStoreState;
}

export const Users = combineReducers({
  getUsersList,
  getUsersListForBankPage,
  getUser,
  postAllowProcess,
  postAllowFields,
  getUserLeads,
  getUserApplications,
  getAllUsersForExternalAgent
});
