export enum EXCLUSIVITY {
  '2 - Эксклюзив' = '2 - Эксклюзив',
  '1 - Несколько конкурентов' = '1 - Несколько конкурентов',
  '0 - Стандартные условия' = '0 - Стандартные условия'
}

export enum SECURING_CLIENTS {
  '3 - На клиента больше года' = '3 - На клиента больше года',
  '2 - На клиента до года' = '2 - На клиента до года',
  '1 - На заявку' = '1 - На заявку',
  '0 - Нет' = '0 - Нет'
}

export enum CASHBACK_BASE {
  '4 - Периодические процентные платежи' = '4 - Периодические процентные платежи',
  '3 - Абсолютное значение годовых' = '3 - Абсолютное значение годовых',
  '2 - Абсолютное значение от суммы сделки' = '2 - Абсолютное значение от суммы сделки',
  '1 - Валовая маржа' = '1 - Валовая маржа'
}
