import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { req as getDebtorNotificationSettings } from 'entities/SCF/Debtor/model/actions/getDebtorNotificationSettings';
import SubscriptionsHeader from './SubscriptionsHeader';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import {
  SubscriptionsSettingsBlock,
  SubscriptionsSettingsRow,
  SubscriptionsSettingsTitle,
  SubscriptionsSettingsMarginTitle
} from '../styles';
import {
  SwitcherContainer,
  UnchangableOffActive,
  UnchangableOffInactive,
  UnchangableOnActive,
  UnchangableOnInactive
} from 'src/features/SCF/UI/Switcher';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

const SubscriptionsInfoDebtor = ({
  getDebtorNotificationSettings,
  getDebtorNotificationSettingsStatus
}) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getDebtorNotificationSettings());
    },
    [dispatch]
  );

  const debtorNotificationSettings = useSelector(
    ({ SCFDebtor }) => SCFDebtor.getDebtorNotificationSettings.data
  );
  return (
    <PageNoMarginStyled>
      <SubscriptionsHeader onSaveClick={''} />
      {getDebtorNotificationSettingsStatus === REQUEST_STATUSES.REQUEST && (
        <Loader />
      )}
      {getDebtorNotificationSettingsStatus === REQUEST_STATUSES.GOT && (
        <SubscriptionsSettingsBlock>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Поставщик добавил вас как покупателя
            </SubscriptionsSettingsTitle>
            {debtorNotificationSettings.newBuyerToSupplierLinkEvent === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Поставщик добавил договор с вами
            </SubscriptionsSettingsTitle>
            {debtorNotificationSettings.newSupplyContractLinkEvent === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Постащик подал вам заявку на раннюю оплату
            </SubscriptionsSettingsTitle>
            {debtorNotificationSettings.newEarlyPaymentApplicationEvent ===
            true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Заявка на раннюю оплату согласована вашим сотрудником
            </SubscriptionsSettingsTitle>
            {debtorNotificationSettings.newApprovedEpaEvent === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Заявка на раннюю оплату подписана
            </SubscriptionsSettingsTitle>
            {debtorNotificationSettings.newSignedEpaEvent === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsMarginTitle>
              получить вместе с документами
            </SubscriptionsSettingsMarginTitle>
            {debtorNotificationSettings.getSignedEpaDocuments === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
        </SubscriptionsSettingsBlock>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  debtorNotificationSettings: SCFDebtor.getDebtorNotificationSettings.data,
  postDebtorNotificationSettingsStatus:
    SCFDebtor.postDebtorNotificationSettings.status,
  getDebtorNotificationSettingsStatus:
    SCFDebtor.getDebtorNotificationSettings.status
});

const mapDispatchToProps = {
  getDebtorNotificationSettings: getDebtorNotificationSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsInfoDebtor);
