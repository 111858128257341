import {
  RequestDataType,
  PATCH_FACTORING_FEES_OF_LIMITS
} from 'entities/SCF/Factor/model/reducers/patchFactoringFeesOfLimits';

export const req = (guid: string, data: RequestDataType) => ({
  type: PATCH_FACTORING_FEES_OF_LIMITS.REQ,
  data,
  guid
});

export const got = () => ({
  type: PATCH_FACTORING_FEES_OF_LIMITS.GOT
});

export const err = (error: {}) => ({
  type: PATCH_FACTORING_FEES_OF_LIMITS.ERR,
  error
});
