import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TRWrapper } from 'src/features/BankProducts/components/Tabs/CreditProducts/styled';
import { ResponseData as FinbankData } from 'src/features/BankProducts/actions/getBankAccounts';
import { AddInvoiceButton, NoData } from './styled';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  bankAccounts: FinbankData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface MatchProps {
  inn: string;
}

type Props = RouteComponentProps<MatchProps> & StateToProps;

const BankAccounts: React.FC<Props> = ({
  bankAccounts,
  status,
  error,
  history,
  match
}) => {
  const navigateToBankProduct = (bankInn: string) => {
    window.open(`/crm/banks/${bankInn}/clients/pledges`, '_blank');
  };

  const navigateToAddInvoice = () => {
    history.push('/crm/bank_accounts/new', { clientInn: match.params.inn });
  };

  return (
    <ScrollTopComponent>
      <AddInvoiceButton
        label={'Добавить счет'}
        onClick={navigateToAddInvoice}
      />

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT &&
        (bankAccounts.items.length ? (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="12%">Банк</TableThStyled>
                  <TableThStyled width="12%">Номер счета</TableThStyled>
                  <TableThStyled width="40%">БИК</TableThStyled>
                  <TableThStyled width="12%">
                    Корреспондентский счет
                  </TableThStyled>
                  <TableThStyled width="12%">Валюта</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {bankAccounts.items.map((bank, index) => (
                  <TRWrapper
                    key={index}
                    isActive={bank.isActive}
                    onClick={() => navigateToBankProduct(bank.bankInn)}
                  >
                    <td>{bank.bankName}</td>
                    <td>{bank.accountNumber}</td>
                    <td>{bank.bankBik}</td>
                    <td>{bank.correspondentAccountNumber}</td>
                    <td>{bank.currencyCode}</td>
                  </TRWrapper>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={bankAccounts} />
          </>
        ) : (
          <NoData>
            <h3>НЕТ ДАННЫХ</h3>
            <p>на платформе пока нет данных о счетах компании</p>
            <p>вы можете добавить счет компании</p>
          </NoData>
        ))}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const BankAccountsConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BankAccounts)
);

export { BankAccountsConnect as BankAccounts };
