import { GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsEarlyPaymentApplicationsByGuid';

export interface Calc {
  baseFullSumm: number;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  createdDateTime: string;
  currencyCode: string;
  currencyID: number;
  debtorInn: string;
  debtorName: string;
  earlyPaymentAvailable: null;
  emergenceDate: string;
  guid: string;
  invoiceDocsQty: number;
  maturityDate: string;
  paidStatus: number;
  status: null;
  supplierName: string;
  supplyContractGuid: string;
  invoiceDocumentName: string;
  invoiceDocumentNumber: number;
  invoiceDocumentDate: string;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.REQ,
  data
});

export const got = (data: Calc[]) => ({
  type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.ERR,
  error
});

export const reset = () => ({
  type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.RESET
});
