import * as React from 'react';
import { animation, StepsTransitionGroup, TRANSITION_DIRECTIONS } from 'Common';

import {
  TabsHeader,
  TabsStyled,
  TabHeaderItem,
  TabBody,
  TabItem
} from './styles';

interface State {
  activeTabIndex: number;
  animationDirection: TRANSITION_DIRECTIONS;
}

interface Tab {
  label: string;
  component: JSX.Element;
  onTabClick?: () => void;
}

interface Props {
  tabs: Tab[];
  className?: string;
}

export class Tabs extends React.Component<Props, State> {
  state: State = {
    activeTabIndex: 0,
    animationDirection: TRANSITION_DIRECTIONS.FORWARD
  };

  changeTab = index => {
    const { tabs } = this.props;
    !!tabs[index].onTabClick && tabs[index].onTabClick();

    this.setState({
      activeTabIndex: index,
      animationDirection:
        index < this.state.activeTabIndex
          ? TRANSITION_DIRECTIONS.BACKWARDS
          : TRANSITION_DIRECTIONS.FORWARD
    });
  };

  render() {
    const { tabs, className } = this.props;
    const { activeTabIndex, animationDirection } = this.state;

    return (
      <TabsStyled className={className}>
        <TabsHeader>
          {tabs.map(({ label }, key) => (
            <TabHeaderItem
              key={key}
              selected={key === activeTabIndex}
              animationDirection={animationDirection}
              onClick={() => this.changeTab(key)}
            >
              {label}
            </TabHeaderItem>
          ))}
        </TabsHeader>

        <StepsTransitionGroup
          {...animation(animationDirection, { duration: 200 })}
          component={TabBody}
          pageKey={activeTabIndex}
        >
          <TabItem>{tabs[activeTabIndex].component}</TabItem>
        </StepsTransitionGroup>
      </TabsStyled>
    );
  }
}
