import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getCurrentFactoringSettingsByDebtorInn';
import { GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN } from 'entities/SCF/Factor/model/reducers/getCurrentFactoringSettingsByDebtorInn';
import SCFApi from 'entities/SCF/Factor/api';

function* getCurrentFactoringSettingsByDebtorInn(action: {
  type: GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getCurrentFactoringSettingsByDebtorInn,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCurrentFactoringSettingsByDebtorInnSaga() {
  yield takeLatest(
    GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ,
    getCurrentFactoringSettingsByDebtorInn
  );
}
