import { PATCH_COMPANY_CLIENT } from 'entities/Cabinet/Client/model/reducers/patchCompanyClient';

export interface RequestData {
  taxSystem: number;
}

export const req = (inn: string, data: RequestData) => ({
  type: PATCH_COMPANY_CLIENT.REQ,
  inn,
  data
});

export const got = () => ({
  type: PATCH_COMPANY_CLIENT.GOT
});

export const err = (error: {}) => ({
  type: PATCH_COMPANY_CLIENT.ERR,
  error
});
