import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { Label } from 'shared/ui/Label';
import { Error } from 'shared/ui/Error';
import {
  AutocompleteContainer,
  AutocompleteItem,
  AutocompleteMenu,
  InputStyled
} from './styles';

interface AutocompleteInputProps {
  value: string;
  onChange?: (e: any) => void;
  label?: string;
  required?: boolean;
  name: string;
  completions: { label: string }[];
  error: string;
}

// TODO refactor
export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  error,
  value,
  onChange,
  name,
  label,
  completions,
  required
}) => {
  return (
    <>
      <AutocompleteContainer>
        <Label label={label} required={required} />
        <Autocomplete
          wrapperStyle={{ position: 'relative' }}
          getItemValue={item => item.label}
          shouldItemRender={(item: { label: string }, value) =>
            item.label.includes(value)
          }
          renderInput={props => (
            <>
              <InputStyled
                isError={!!error}
                {...props}
                autoComplete="nope"
                name={name}
              />
              <Error error={error} />
            </>
          )}
          items={completions}
          renderMenu={items => {
            return <AutocompleteMenu children={items} />;
          }}
          renderItem={(item, isHighlighted) => (
            <AutocompleteItem
              key={item.label}
              style={{ background: isHighlighted ? 'lightgray' : 'white' }}
            >
              {item.label}
            </AutocompleteItem>
          )}
          value={value}
          onChange={onChange}
          onSelect={val => onChange({ currentTarget: { name, value: val } })}
        />
      </AutocompleteContainer>
    </>
  );
};
