import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface CreateContactStoreState extends Reducer {}

export enum CREATE_CONTACT {
  REQ = 'CREATE_CONTACT_REQ',
  GOT = 'CREATE_CONTACT_GOT',
  ERR = 'CREATE_CONTACT_ERR',
  RESET = 'CREATE_CONTACT_RESET'
}

export const initialState: CreateContactStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const createContact = (
  state: CreateContactStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): CreateContactStoreState => {
  switch (action.type) {
    case CREATE_CONTACT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CREATE_CONTACT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CREATE_CONTACT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case CREATE_CONTACT.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE
      };
    default:
      return state;
  }
};

export default createContact;
