import { GET_BENEFICIARIES_OF_COMPANIES } from 'Business/reducers/getBenificiariesOfCompanies';
import { BeneficiariesOfCompanies } from '../types';

export const req = (inn: string) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES.REQ,
  inn
});

export const got = (data: BeneficiariesOfCompanies[]) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES.ERR,
  error
});
