import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/postAgreementSignatureDebtorRole';

import {
  POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/postAgreementSignatureDebtorRole';

import SCFApi from 'entities/SCF/Debtor/api';

function* postAgreementSignatureDebtorRole(action: {
  type: POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postAgreementSignatureDebtorRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAgreementSignatureDebtorRoleSaga() {
  yield takeLatest(
    POST_AGREEMENT_SIGNATURE_DEBTOR_ROLE.REQ,
    postAgreementSignatureDebtorRole
  );
}
