import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getMyBuyers';
import { GET_MY_BUYERS } from '../reducers/getMyBuyers';
import SCFApi from '../api';

function* getMyBuyers(action: {
  type: GET_MY_BUYERS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getMyBuyers, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMyBuyersSaga() {
  yield takeLatest(
    GET_MY_BUYERS.GET_MY_BUYERS_REQ,
    getMyBuyers
  );
}
