import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { history } from 'src/shared/utils/History';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getAllIntegrationApplications,
  ResponseData,
  RequestData
} from 'src/features/IntegrationApplications/actions/getAllIntegrationApplications';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationStatus } from 'src/features/Application/components';
import { formBackendDateString, formSumString } from 'src/shared/utils/Utils';
import { ApplicationTypes } from 'shared/constants';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';

interface StateToProps {
  integrationApplications: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getAllIntegrationApplications: (data: RequestData) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const IntegrationApplications: React.FC<Props> = ({
  getAllIntegrationApplications,
  integrationApplications,
  status,
  error,
  match
}) => {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10) || 1;

    window.history.replaceState({}, '', `?page=${page}`);

    getAllIntegrationApplications({
      page,
      pageSize: 20,
      companyInnToFilterBy: match.params.inn
    });
  }, []);

  const formApplicationsData = () => {
    return integrationApplications.items.map(applicationToView);
  };

  const applicationToView = application => {
    const viewItem = {
      id: application.id.toString(),
      companyName: application.clientCompanyName,
      financingType: ApplicationTypes[application.financingType],
      amount: formSumString(application.amount),
      modifiedAt:
        application.modifiedAt !== null &&
        formBackendDateString(
          !!application.modifiedAt
            ? application.modifiedAt
            : application.createdAt
        ),
      status: (
        <ApplicationStatus
          id={application.id}
          status={application.status}
          financingType={application.financingType}
          listType={APPLICATIONS_LIST_TYPE.INTEGRATION_APPLICATIONS}
        />
      )
    };

    return viewItem;
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Мои заявки</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="20%">ID</TableThStyled>
              <TableThStyled width="20%">Клиент</TableThStyled>
              <TableThStyled width="20%">Тип финансирования</TableThStyled>
              <TableThStyled width="10%">Сумма</TableThStyled>
              <TableThStyled width="10%">Дата изменения</TableThStyled>
              <TableThStyled width="10%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {formApplicationsData().map(application => (
              <TableRowStyled key={application.id}>
                <td>{application.id}</td>
                <td>{application.companyName}</td>
                <td>{application.financingType}</td>
                <td>{application.amount}</td>
                <td>{application.modifiedAt}</td>
                <td>{application.status}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={integrationApplications} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ IntegrationApplications }: CRM) => ({
  integrationApplications:
    IntegrationApplications.getAllIntegrationApplications.data,
  status: IntegrationApplications.getAllIntegrationApplications.status,
  error: IntegrationApplications.getAllIntegrationApplications.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getAllIntegrationApplications }, dispatch);

const IntegrationApplicationsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(IntegrationApplications)
);

export { IntegrationApplicationsConnect as IntegrationApplications };
