import styled from 'styled-components';
import { blue, lighterBlue, rr } from 'shared/styled';

export const ContractsPageStyled = styled.section`
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const CreateLimitsNudge = styled.div`
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-family: ${rr};
  }
`;

export const BtnsBlock = styled.div`
  margin-top: 10px;
  width: auto;
  height: auto;
  display: flex;
`;

export const ConfirmSection = styled.div`
  margin-top: 30px;
  width: 1035px;
  height: auto;
  display: flex;

  justify-content: flex-end;
`;

export const SignSection = styled.div`
  margin-top: 30px;
  width: 1285px;
  height: auto;
  display: flex;

  justify-content: flex-start;
  flex-direction: column;
`;

export const SignTextBlock = styled.div`
  margin-bottom: 15px;
  width: auto;
  height: auto;
  display: flex;

  justify-content: flex-start;
  flex-direction: column;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const ConfirmSectionRightPart = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`;

export const ConfirmSectionCheckBox = styled.div`
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  p {
    margin-top: -1px;
    font-family: ${rr};
    width: 250px;
  }
`;

export const CreateBtn = styled.div`
  width: 150px;
  height: 40px;
  display: flex;

  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  margin: 0;
  color: #ffffff;
`;

export const BackBtn = styled.div`
  width: auto;
  height: 40px;
  display: flex;

  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  color: #3f4e65;
  margin: 0;
`;

export const InnFilterArea = styled.div`
  width: 450px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;
export const ListSection = styled.section`
  margin-top: 30px;
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullList = styled.section`
  margin-top: 20px;
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid ${blue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${blue};
  }
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const ListSectionOne = styled.section`
  margin-top: 30px;
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullListOne = styled.section`
  margin-top: 20px;
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DiscountItem = styled.div`
  width: 420px;
  height: 30px;
  display: flex;

  justify-content: space-between;
`;

export const DownloadLink = styled.a.attrs({
  ['aria-label']: 'download-document',
  download: true
})`
  width: fit-content;
  height: 40px;
  display: flex;
  margin-bottom: 10px;

  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background-color: #c0ecff;
  }
`;
