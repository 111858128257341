import { rr } from 'shared/styled';
import styled from 'styled-components';

export const SubscriptionsHeaderStyled = styled.div`
  width: auto;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  h2 {
    font-size: 28px;
    font-family: ${rr};
    width: 380px;
  }
`;

export const BtnsBlock = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-left: 130px;
  align-items: flex-start;
  gap: 10px;
`;

export const SubscriptionsSettingsBlock = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubscriptionsSettingsRow = styled.div`
  width: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const SubscriptionsSettingsTitle = styled.p`
  width: 360px;
  font-size: 18px;
  font-family: ${rr};
  margin-right: 150px;
`;

export const SubscriptionsSettingsMarginTitle = styled.p`
  width: 360px;
  font-size: 18px;
  font-family: ${rr};
  margin-right: 150px;
  padding-left: 40px;
`;
