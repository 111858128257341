import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getAgents';

import { GET_AGENTS } from '../reducers/getAgents';
import CounterpartiesApi from '../api';

function* getAgents(action: { type: GET_AGENTS; inn: string }): Generator {
  try {
    const res: any = yield call(CounterpartiesApi.getAgents, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAgentsSaga(): Generator {
  yield takeLatest(GET_AGENTS.REQ, getAgents);
}
