import styled, { css } from 'styled-components';
import { LoaderBar } from 'Layouts/components/LoaderBar/LoaderBar';
import {
  lightGray,
  lighterGray,
  lighterBlue,
  borderDashAnimate
} from 'shared/styled';

export const getBgImage = ({ isDragActive }) => {
  switch (true) {
    case isDragActive:
      return css`url('${borderDashAnimate(
        'rgba(245, 55, 55, 0.5)',
        'rgba(0, 133, 254, 0.5)'
      )}')`;
    default:
      return 'none';
  }
};

export const getBgColor = ({ isDragReject, isDragActive, isEmpty }) => {
  switch (true) {
    case isDragReject:
      return 'rgba(245, 55, 55, 0.05)';
    case isDragActive:
      return lighterBlue;
    case isEmpty:
      return lighterGray;
    default:
      return '#fff';
  }
};

export const getBorderColor = ({ isDragReject, isDragActive, isEmpty }) => {
  switch (true) {
    case isDragReject:
      return 'transparent';
    case isDragActive:
      return 'transparent';
    default:
      return lightGray;
  }
};

export const InputFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DropAreaHeight = '210px';
export const DropArea = styled.div`
  width: 400px;
  height: ${({dropAreaHeight}) => dropAreaHeight ? dropAreaHeight : DropAreaHeight};

  margin-top: 8px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};

  overflow: hidden;
  outline: none;

  border: 1px solid
    ${props => (!props.isEmpty ? lightGray : getBorderColor(props))};
  background-color: ${props => getBgColor(props)};
  background-image: ${props => (!props.isEmpty ? 'none' : getBgImage(props))};

  transition: background-color 0.2s, background-image 0.2s;
`;

export const FileLoaderBar = styled(LoaderBar)`
  bottom: -50px;
  right: 0;
  position: absolute;
`;
