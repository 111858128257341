import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getActivitiesByLeadInternal';

import { GET_ACTIVITIES_BY_LEAD_INTERNAL } from '../reducers/getActivitiesByLeadInternal';
import LeadsApi from '../api';

function* getActivitiesByLeadInternal(action: {
  type: GET_ACTIVITIES_BY_LEAD_INTERNAL;
  leadId: string;
}): Generator {
  try {
    const res: any = yield call(
      LeadsApi.getActivitiesByLeadInternal,
      action.leadId
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(
    GET_ACTIVITIES_BY_LEAD_INTERNAL.REQ,
    getActivitiesByLeadInternal
  );
}
