import { combineReducers } from 'redux';
import getAllIntegrationApplications, {
  GetAllIntegrationApplicationsStoreState
} from 'src/features/IntegrationApplications/reducers/getAllIntegrationApplications';

export interface IntegrationApplicationsReducers {
  getAllIntegrationApplications: GetAllIntegrationApplicationsStoreState;
}

export const IntegrationApplications = combineReducers({
  getAllIntegrationApplications
});
