import * as React from 'react';
import Dropzone from 'react-dropzone';

import { REQUEST_STATUSES } from 'globaltypes';
import { RegistryDocument } from 'Application/types';

import { Label } from 'shared/ui/Label';

import { FileUploadInfoBlock } from 'src/features/SCF/components/InputFile/FileUploadInfoBlock/FileUploadInfoBlock';
import { FilesList } from 'src/features/SCF/components/InputFile/FilesList/FilesList';

import { InputFileContainer, DropArea, FileLoaderBar } from './styles';

export interface IFile {
  name: string;
  size: number;
  type: string;
  lastModified: number;
  lastModifiedDate?: any;
  webkitRelativePath?: string;
}

export interface InputFileProps {
  className?: string;
  label?: string;
  name?: string;
  required?: boolean;
  error?: string;
  files: RegistryDocument[];
  multiple?: boolean;
  uploadStatus?: REQUEST_STATUSES;
  accept?: string;
  maxSize?: number;
  dropAreaHeight?: number | string;
  onFilesUpload: (files: IFile[]) => void;
  inputFileTypesDescription: string;
  inputFileFormatDescription: string;
}

export const inputFileDefaultProps = {
  files: [],
  accept:
    '.xls, .xlsx, .csv',
  required: false,
  multiple: false,
  maxSize: 52 * 1000 * 1000,
  className: ''
};

export const InputFile: React.FC<InputFileProps> = React.memo(
  ({
    className,
    label,
    name,
    required,
    files,
    uploadStatus,
    accept,
    maxSize,
    onFilesUpload,
    dropAreaHeight,
    inputFileTypesDescription,
    inputFileFormatDescription,
  }) => (
    <Dropzone
      disableClick
      accept={accept}
      maxSize={maxSize}
      multiple={false}
      onDrop={onFilesUpload}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
      }) => (
        <InputFileContainer className={!!className ? className : ''}>
          <Label name={name} label={label} required={required} />
          <DropArea
            name={name}
            isDragActive={isDragActive}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            dropAreaHeight={dropAreaHeight}
            {...getRootProps()}
            isEmpty={files.length === 0}
          >
            <input {...getInputProps()} />

            {files.length === 0 ? (
              <FileUploadInfoBlock
                openUploader={open}
                fileTypeDescription={inputFileTypesDescription}
                fileFormatDescription={inputFileFormatDescription}
              />
            ) : (
              <FilesList
                isDragActive={isDragActive}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                files={files}
                multiple={false}
              />
            )}
          </DropArea>

          {/* <Error error={error} /> */}
          <FileLoaderBar
            status={uploadStatus == REQUEST_STATUSES.REQUEST ? uploadStatus : REQUEST_STATUSES.NONE}
            processText="Загрузка файла..."
          />
        </InputFileContainer>
      )}
    </Dropzone>
  )
);

InputFile.defaultProps = inputFileDefaultProps;
