import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type SendBuyersStoreState = Reducer;

export enum SEND_BUYERS {
  REQ = 'SEND_BUYERS_REQ',
  GOT = 'SEND_BUYERS_GOT',
  ERR = 'SEND_BUYERS_ERR'
}

export interface RequestDataType {
    buyerInnList: string;
}

export const initialState: SendBuyersStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendBuyers = (
  state: SendBuyersStoreState = initialState,
  action: {
    type: SEND_BUYERS;
    error: {};
  }
): SendBuyersStoreState => {
  switch (action.type) {
    case SEND_BUYERS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_BUYERS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_BUYERS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendBuyers;
