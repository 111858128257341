export enum APPLICATION_TABS {
  DEFAULT = 'Заявки',
  LEADS = 'Лиды'
}

export interface Application {
  id: number;
  userId: number;
  initiatorEmail: null;
  term: number;
  amount: number;
  employeeEmail: null;
  refinancing: boolean;
  code: string;
  client: string;
  internalAgentId: number;
  mainApplicationId: number;
  bankManagerId: number;
  source: null;
  termStartDate: null;
  termEndDate: null;
  currencyCode: null;
  currencySymbol: null;
  status: string;
  financingType: string;
  analystName: string;
}
