import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { UserData } from 'src/features/Users/types';

export interface GetUserStoreState extends Reducer {
  user: UserData;
}

export enum GET_USER {
  REQ = 'GET_USER_REQ',
  GOT = 'GET_USER_GOT',
  ERR = 'GET_USER_ERR'
}

export const initialState: GetUserStoreState = {
  status: REQUEST_STATUSES.NONE,
  user: null,
  error: {}
};

const getUser = (
  state: GetUserStoreState = initialState,
  action: {
    type: GET_USER;
    data: UserData;
    error: {};
  }
): GetUserStoreState => {
  switch (action.type) {
    case GET_USER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_USER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        user: action.data
      };
    case GET_USER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getUser;
