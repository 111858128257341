import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { FormContainer } from 'src/features/Products/components/NewProduct/styled';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { ErrorMsg } from 'src/features/Contacts/components/CreateNewContact/styled';
import {
  PopupContainerBank,
  PopupContentBank,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonWrapper,
  ToggleButtonYes
} from 'src/features/Finbanks/components/NewBank/styled';
import {
  PopupText,
  PopupTitle
} from 'src/features/Contacts/components/ContactsList/styled';
import {
  req as createFinbank,
  reset as resetStateFinbank
} from '../../actions/postFinbank';
import { FinbankRead } from 'src/features/Finbanks/actions/setFinbankFormData';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  companyByInn: ResponseDataType;
  statusInn: REQUEST_STATUSES;
  createStatus: REQUEST_STATUSES;
  createError: ResponseError;
}

interface State {
  inn: string;
  name: string;
  isBank: boolean;
  isFactor: boolean;
  isLeasingCompany: boolean;
  isMfo: boolean;
  isSupportFund: boolean;
  isCorporate: boolean;
  isInfoPopup: boolean;
  errors: {
    inn: string;
    name: string;
  };
}

interface MatchParams {}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  createFinbank: (data: FinbankRead) => void;
  resetStateFinbank: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class NewBank extends React.Component<Props, State> {
  state: State = {
    inn: '',
    name: '',
    isBank: null,
    isFactor: null,
    isLeasingCompany: null,
    isMfo: null,
    isSupportFund: null,
    isCorporate: null,
    isInfoPopup: false,
    errors: {
      inn: '',
      name: ''
    }
  };

  bankModalRef = React.createRef();

  componentDidMount() {
    if (history.location.state && history.location.state.inn) {
      this.setState({ inn: history.location.state.inn });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetStateFinbank();
      history.goBack();
    }
    if (prevState.inn !== this.state.inn) {
      this.setState({ isInfoPopup: false });
    }
    if (prevState.inn !== this.state.inn && this.state.inn.length >= 10) {
      this.props.getCompanyByInn({ inn: this.state.inn });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopup !== this.state.isInfoPopup
    ) {
      this.setState({ name: this.props.companyByInn.companyShortName });
    }
  }

  handleFieldNewProduct = (name: keyof State, value: string) => {
    this.setState({ [name]: value } as any);
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  handleCreateProduct = event => {
    event.preventDefault();

    const innRegex = /^[a-zA-Z0-9]+$/;

    const errors: State['errors'] = {
      inn: '',
      name: ''
    };

    if (this.state.inn !== null && !innRegex.test(this.state.inn)) {
      errors.inn = 'Введите корректный ИНН';
    }

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, isInfoPopup, ...data } = this.state;
      this.props.createFinbank(data);
    }
  };

  handleOutsideClickAgent = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      this.bankModalRef.current &&
      !(this.bankModalRef.current as Element).contains(target)
    ) {
      this.props.resetStateFinbank();
    }
  };

  handleButtonClick = (key: keyof State, value: boolean) => {
    this.setState({ [key]: value } as any);
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          type="button"
          onClick={() => this.handleButtonClick(buttonName, false)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          type="button"
          onClick={() => this.handleButtonClick(buttonName, true)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  render() {
    const { companyByInn, statusInn, createStatus } = this.props;
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание банка</ApplicationTitle>

        {createStatus === REQUEST_STATUSES.ERROR && (
          <PopupContainerBank onClick={this.handleOutsideClickAgent}>
            <PopupContentBank ref={this.bankModalRef}>
              <PopupTitle>Ошибка: {this.props.createError.code}</PopupTitle>
              {this.props.createError.code === 409 && (
                <PopupText>Такой банк уже существует</PopupText>
              )}
            </PopupContentBank>
          </PopupContainerBank>
        )}

        <FormContainer onSubmit={this.handleCreateProduct}>
          <Input
            label="ИНН:"
            name="inn"
            value={this.state.inn}
            onChange={e =>
              this.handleFieldNewProduct('inn', e.currentTarget.value)
            }
          />

          {this.state.inn && this.state.inn.length < 10 ? null : statusInn ===
          REQUEST_STATUSES.ERROR ? (
            <InnPopup>
              <h2>
                Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                создать связку с введенным ИНН
              </h2>
            </InnPopup>
          ) : (
            statusInn === REQUEST_STATUSES.GOT &&
            !this.state.isInfoPopup && (
              <InnPopup
                companyInfo={companyByInn}
                onPopupClick={this.onPopupClick}
              />
            )
          )}

          {this.state.errors.inn && (
            <ErrorMsg>{this.state.errors.inn}</ErrorMsg>
          )}

          <Input
            label="Наименование:"
            name="name"
            value={this.state.name}
            onChange={e =>
              this.handleFieldNewProduct('name', e.currentTarget.value)
            }
          />

          {this.state.errors.name && (
            <ErrorMsg>{this.state.errors.name}</ErrorMsg>
          )}

          <ToggleButtonWrapper>
            {this.renderToggleButton('isBank', 'Банк')}
            {this.renderToggleButton('isFactor', 'Факторинговая компания')}
            {this.renderToggleButton('isLeasingCompany', 'Лизинговая компания')}
            {this.renderToggleButton('isMfo', 'Микрофинансовая организация')}
            {this.renderToggleButton('isSupportFund', 'Фонд поддержки')}
            {this.renderToggleButton('isCorporate', 'Коммерческая компания')}
          </ToggleButtonWrapper>

          <Button label="Создать" type={'submit'} w='fit-content' />
        </FormContainer>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ SCF, Finbanks }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  createStatus: Finbanks.postFinbank.status,
  createError: Finbanks.postFinbank.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      resetStateCompanyByInn,
      createFinbank,
      resetStateFinbank
    },
    dispatch
  );

const NewBankConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewBank)
);

export { NewBankConnect as NewBank };
