import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/getFactoringConnectionById';
import { GET_FACTORING_CONNECTIONS_BY_ID } from 'entities/SCF/Factor/model/reducers/getFactoringConnectionsById';
import SCFApi from 'entities/SCF/Factor/api';

function* getFactoringConnectionsById(action: {
  type: GET_FACTORING_CONNECTIONS_BY_ID;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFApi.getFactoringConnectionById, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringConnectionsByIdSaga() {
  yield takeLatest(
    GET_FACTORING_CONNECTIONS_BY_ID.REQ,
    getFactoringConnectionsById
  );
}
