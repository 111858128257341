import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import { GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID } from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid(action: {
  type: GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidSaga() {
  yield takeLatest(
    GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ,
    getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid
  );
}
