import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { blue, rr } from 'shared/styled';

export const AddSuppliersPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 900px;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const DescriptionArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: #d0e9ff;
  transition: 0.2s;
`;

export const Description = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 1em;
  padding: 0 20px;
  line-height: 130%;
`;

export const InputArea = styled.div`
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  textarea {
    width: 500px;
    padding: 10px 20px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const InputTitle = styled.p`
  font-family: ${rr};
  font-weight: 400;
  font-size: 1.2em;
`;
