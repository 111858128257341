import * as React from 'react';

import { MODAL_NAMES } from 'Common/Modal/types';
import { ModalStyled, AgreementModalStyled } from './styles';

export const AgreementModal: React.FC = () => (
  <ModalStyled name={MODAL_NAMES.REGISTRATION_AGREEMENT}>
    <AgreementModalStyled>
      <h2>Согласие на обработку персональных данных</h2>
      <p>
        Я, в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О
        персональных данных», даю свое согласие ООО "ФинФэктори" на
        осуществление любых действий с персональными данными, указанными мною в
        заявлении на работу с платформой включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ),
        обезличивание, блокирование, удаление, уничтожение, с использованием
        средств автоматизации или без использования таких средств, в целях
        подачи ООО "ФинФэктори" от моего имени заявки на финансирование, участие
        в опросах, проводимых ООО "ФинФэктори" (ПАО), и осуществлении любых иных
        действий, предусмотренных законодательством Российской Федерации. Я
        согласен на предоставление мне информации посредством телефонных
        обращений и (или) в электронном виде путем направления электронных
        сообщений на адрес электронной почты, указанные в заявлении на работу с
        платформой. Настоящее согласие предоставляется со дня направления мной в
        ООО "ФинФэктори" заявления на работу с платформой. Согласие может быть
        отозвано мной в любой момент времени путем предоставления в ООО
        "ФинФэктори" письменного заявления в соответствии с требованиями
        законодательства Российской Федерации.
      </p>
      <p>
        Я согласен на предоставление мне информации посредством телефонных
        обращений и (или) в электронном виде путем направления электронных
        сообщений на адрес электронной почты, указанные в заявлении на работу с
        платформой.
      </p>
      <p>
        Настоящее согласие предоставляется со дня направления мной в ООО
        "ФинФэктори" заявления на работу с платформой. Согласие может быть
        отозвано мной в любой момент времени путем предоставления в ООО
        "ФинФэктори" письменного заявления в соответствии с требованиями
        законодательства Российской Федерации.
      </p>
    </AgreementModalStyled>
  </ModalStyled>
);
