import Validator from 'instant-validation';
import { Document } from 'src/features/Application/types';
import { requiredRule } from 'instant-validation/build/rules';

import { ValidatorMessages } from 'shared/constants';
import { PropertyRead, PROPERTY_SUB_TYPES } from 'src/features/Properties/types/Property';
import { findDocumentByMetaInfo } from 'src/shared/utils/Utils';
import { completions } from './completions';
import { notEmptyStringRule, notZeroRule } from 'src/shared/utils/ValidationRules';

export const createValidation = () =>
  new Validator<Partial<PropertyRead>>({
    type: [
      {
        rule: notEmptyStringRule,
        message: ValidatorMessages.requiredField
      }
    ],
    subType: [
      {
        // TODO extract
        rule: notEmptyStringRule,
        message: ValidatorMessages.requiredField
      }
    ],
    region: [
      {
        rule: (value:string) => completions.findIndex(v => v.label == value) !== -1,
        message: ValidatorMessages.requiredField
      }
    ],
    address: [
      {
        rule: notEmptyStringRule,
        message: ValidatorMessages.requiredField
      }
    ],
    price: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    documents: [
      {
        rule: (documents: Document[]) => {
          return !!(documents && findDocumentByMetaInfo(documents,"egrn"))
        },
        message: ValidatorMessages.requiredField,
      }
    ]
  });
