import { SET_BANK_ACCOUNT_DATA } from '../reducers/setBankAccount';

export interface BankAccountWrite {
    clientInn?: string;
    bankInn?: string;
    bankBik?: string;
    bankName?: string;
    accountNumber?: string;
    correspondentAccountNumber?: string;
}

export interface BankAccountData extends Partial<BankAccountWrite> { }

export const setBankAccountData = (data: BankAccountData) => ({
    type: SET_BANK_ACCOUNT_DATA.SET,
    data
});

export const resetBankAccountData = () => ({
    type: SET_BANK_ACCOUNT_DATA.RESET
});
