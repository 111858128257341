import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { AskStyled } from 'src/features/CooperationForProducts/components/styled';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  RoleAndPermission,
  ToggleButtonContainerUser,
  ToggleButtonNoUser,
  ToggleButtonYesUser
} from 'src/features/Users/components/BasicParametersForUser/styled';
import { req as postAllowProcess } from '../../actions/postAllowProcess';
import { req as postAllowFields } from '../../actions/postAllowFields';
import { UserData } from 'src/features/Users/types';

interface MatchParams {
  id: string;
}

interface AnyProps {
  user: UserData;
}

interface State {
  allowSimpleProcess: boolean | null;
  allowBlankFields: boolean | null;
}

interface StateToProps {
  statusProcess: REQUEST_STATUSES;
  errorProcess: ResponseError;
  statusFields: REQUEST_STATUSES;
  errorFields: ResponseError;
}

interface DispatchToProps {
  postAllowProcess: (id: string, typeProcess: string) => void;
  postAllowFields: (id: string, typeProcess: string) => void;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class BasicParametersForUser extends React.Component<Props, State> {
  state: State = {
    allowSimpleProcess: null,
    allowBlankFields: null
  };

  componentDidMount() {
    this.setState({
      allowSimpleProcess: this.props.user.allowSimpleProcess,
      allowBlankFields: this.props.user.allowBlankFields
    });
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    this.setState({ [key]: value } as Pick<any, keyof State>);
    if (key === 'allowSimpleProcess' && value === true) {
      this.props.postAllowProcess(id, 'allow');
    } else if (key === 'allowSimpleProcess' && value === false) {
      this.props.postAllowProcess(id, 'disallow');
    } else if (key === 'allowBlankFields' && value === true) {
      this.props.postAllowFields(id, 'allow');
    } else if (key === 'allowBlankFields' && value === false) {
      this.props.postAllowFields(id, 'disallow');
    }
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    return (
      <ToggleButtonContainerUser>
        <ToggleButtonNoUser
          onClick={() => this.handleButtonClick(buttonName, false)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNoUser>
        <ToggleButtonYesUser
          onClick={() => this.handleButtonClick(buttonName, true)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYesUser>
        <p>{description}</p>
      </ToggleButtonContainerUser>
    );
  };

  render() {
    const {
      statusProcess,
      errorProcess,
      statusFields,
      errorFields
    } = this.props;
    return (
      <ApplicationViewStyled>
        <p>Роли и разрешения:</p>
        <RoleAndPermission>
          {this.props.user.roles.map((role, index) => (
            <p key={index}>{role}</p>
          ))}
          {this.props.user.permissions.map((permission, index) => (
            <p key={index}>{permission}</p>
          ))}
        </RoleAndPermission>

        <AskStyled>Тип контракта:</AskStyled>
        {this.renderToggleButton(
          'allowSimpleProcess',
          'Пользователь может заводить заявки по упрощенному процессу'
        )}

        {this.renderToggleButton(
          'allowBlankFields',
          'Пользователь может не прикладывать документы'
        )}

        <ActionResponseBlock
          showIn={
            (errorProcess.code === 403 &&
              statusProcess === REQUEST_STATUSES.ERROR) ||
            (errorFields.code === 403 &&
              statusFields === REQUEST_STATUSES.ERROR)
          }
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={
            (errorProcess.code !== 403 &&
              statusProcess === REQUEST_STATUSES.ERROR) ||
            (errorFields.code !== 403 &&
              statusFields === REQUEST_STATUSES.ERROR)
          }
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Users }: CRM) => ({
  statusProcess: Users.postAllowProcess.status,
  errorProcess: Users.postAllowProcess.error,
  statusFields: Users.postAllowFields.status,
  errorFields: Users.postAllowFields.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ postAllowProcess, postAllowFields }, dispatch);

const BasicParametersForUserConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BasicParametersForUser)
);

export { BasicParametersForUserConnect as BasicParametersForUser };
