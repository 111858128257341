import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getOneDiscountAuctionBySupplier';
import { GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER } from '../reducers/getOneDiscountAuctionBySupplier';
import SCFApi from '../api';

function* getOneDiscountAuctionBySupplier(action: {
  type: GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER;
  guid: string;
}) {
  try {
    const res: any = yield call(
      SCFApi.getOneDiscountAuctionBySupplier,
      action.guid
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOneDiscountAuctionBySupplierSaga() {
  yield takeLatest(
    GET_ONE_DISCOUNT_AUCTION_BY_SUPPLIER.REQ,
    getOneDiscountAuctionBySupplier
  );
}
