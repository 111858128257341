import { GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/getEarlyPaymentApplicationsDebtorRole';

export interface EarlyPaymentApplication {
  guid: string;
  createdDateTime: Date;
  supplierInn: string;
  supplierName: string;
  debtorName: string;
  payDate: Date;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  newTotalSumOnPayday: number;
  absoluteDiscountRate: number;
  absoluteDiscount: number;
  firstBasePaymentDate: Date;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  depositRateEquivalent: number;
  firstPaymentShare: number;
  payerType: number;
  statusDebtor: string;
  agreementFileIdentifier: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  rejectSubject: number;
  rejectComment: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: EarlyPaymentApplication[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  SupplierInnToFilterBy?: string;
  StartDate?: string;
  EndDate?: string;
  ActionType?: string;
  sortBy?: string;
  auction?: string;
  minDiscount?: string;
  maxDiscount?: string;
  minDiscountAmount?: string;
  maxDiscountAmount?: string;
  minBaseSum?: string;
  maxBaseSum?: string;
  minDiscountRate?: string;
  maxDiscountRate?: string;
  minEquivalentRate?: string;
  maxEquivalentRate?: string;
  payDateAfter?: string;
  payDateBefore?: string;
  allGuids?: boolean;
}

export const req = (data: RequestDataType) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.ERR,
  error
});

export const reset = () => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.RESET
});
