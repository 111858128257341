import { GET_PERSONS_LIST } from '../reducers/getPersonsList';
import { PersonsListType } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: PersonsListType[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  inn?: string;
  firstname?: string;
  lastname?: string;
  middlename?: string;
}

export const req = (data: RequestData) => ({
  type: GET_PERSONS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_PERSONS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PERSONS_LIST.ERR,
  error
});
