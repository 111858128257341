import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getUserApplication';

import { GET_USER_APPLICATIONS } from '../reducers/getUserApplication';
import UserApi from '../api';

function* getUserApplication(action: {
  type: GET_USER_APPLICATIONS;
  id: string;
}): Generator {
  try {
    const res: any = yield call(UserApi.getUserApplication, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserApplicationsSaga(): Generator {
  yield takeLatest(GET_USER_APPLICATIONS.REQ, getUserApplication);
}
