import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getSubRegistries';
import { GET_SUB_REGISTRIES } from 'entities/SCF/Debtor/model/reducers/getSubRegistries';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getSubRegistries(action: {
  type: GET_SUB_REGISTRIES;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFDebtorApi.getSubRegistries, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSubRegistriesSaga() {
  yield takeLatest(GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_REQ, getSubRegistries);
}
