import styled, { keyframes } from 'styled-components';
import { blue, gray, rl, rm, rr } from 'shared/styled';
import {
  ImageStyled,
  InnPopupStyled
} from 'src/features/Layouts/components/InnPopup/styled';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';

export const Title = styled.h1`
  font-size: 2.4rem;
  font-family: ${rm};
  color: #06243c;
`;

export const BusinessData = styled.div`
  position: relative;

  & > div {
    display: grid;
    grid-template-columns: 230px 1fr;
    align-items: center;
  }
`;

export const MainSection = styled.main`
  margin: 160px 90px 50px;
`;

export const Image = styled(ImageStyled)`
  position: fixed;
  z-index: 1;
  width: 500px;
  bottom: -70px;
  right: -70px;
`;

export const CopyBlock = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};

  p{
    margin: 0;
  }

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 1;
    bottom: -7px;
    left: 112%;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  & > p:hover, h1:hover, h2:hover {
    color: ${blue};
  }
`;

export const NotFound = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 48px;
    font-family: ${rl};
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 40%;
  left: 50%;
  transform: translate(-50%, 0);

  & > ${InnPopupStyled} {
    top: 50px;
  }
`;

export const HeadData = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

export const CompanyNameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 15px;

  div:not(:first-child) {
    border: 1px solid ${gray};
    border-radius: 5px;
    padding: 5px 10px;
    color: ${gray};
    font-family: ${rr};
  }
`;

export const TaxesInfoContainer = styled.div`
  margin: 50px 0;

  & > h2 {
    font-size: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-left: 10px;

    div {
      position: relative;
      z-index: 2;
      display: grid;
      grid-template-columns: 100px 1fr;
      column-gap: 20px;
      align-items: center;

      p:first-of-type {
        text-align: end;
      }

      p {
        margin: 0;
      }
    }
  }
`;

export const Notification = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 550px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 2px 11px 0px rgb(0 0 0 / 20%);

  & > svg {
    position: absolute;
    right: 25px;
    top: 20px;
    color: ${blue};
    font-size: 26px;
    cursor: pointer;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    & > svg {
      font-size: 25px;
      color: ${blue};
    }

    & > p {
      font-size: 24px;
      color: ${blue};
      margin: 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0;
    line-height: 1.4em;

    span {
      color: ${blue};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const NotificationContainer = styled.div`
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`;

export const PersonsContainer = styled.div`
  margin-top: 50px;

  h2 {
    width: 50%;
    font-size: 20px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 35px;
  }
`;

export const ApplicationView = styled(ApplicationViewStyled)`
  position: static;
  height: auto;
`;

export const PersonInfoContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    img {
      font-size: 10px;
      width: 70px;
      filter: invert(0.9);
    }

    svg {
      filter: invert(0.9);
      font-size: 60px;
    }
  }
`;

export const PersonName = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${blue};
    cursor: pointer;
    border-bottom: 1px solid ${blue};

    &:not(:last-child) {
      padding-right: 5px;
    }
  }
`;

export const Subsidiaries = styled.div``;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  div:last-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  p {
    margin: 0;
  }
`;

export const PersonInfoDirector = styled(PersonInfo)`
  div:first-of-type {
    column-gap: 0;
  }
`;

export const BalanceWrapper = styled.div`
  position: absolute;
  top: 260px;
  right: 0;
  z-index: 2;
  padding-bottom: 40px;

  h2 {
    font-size: 20px;
    margin: 0;
  }

  table {
    width: 90%;
  }

  & > div {
    table {
      background-color: white;
    }
  }
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  margin-top: 25px;
`;
