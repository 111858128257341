import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  req as createBankAccount,
  reset as resetBankAccountNewState,
  RequestData
} from 'src/features/BankAccount/actions/createBankAccount';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { ContainerInputs, WrapperInput, WrapperInputDetail } from './styled';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {
  companyByInn: ResponseDataType;
  statusInn: REQUEST_STATUSES;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  createBankAccount: (data: RequestData) => void;
  resetBankAccountNewState: () => void;
  resetStateCompanyByInn: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BankAccountNew: React.FC<Props> = ({
  createBankAccount,
  status,
  getCompanyByInn,
  companyByInn,
  statusInn,
  resetStateCompanyByInn,
  resetBankAccountNewState,
  history
}) => {
  const [formData, setFormData] = React.useState({
    accountNumber: '',
    bankBik: '',
    bankName: '',
    correspondentAccountNumber: '',
    clientInn: '',
    companyName: ''
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [isInfoPopup, setIsInfoPopup] = React.useState(false);

  React.useEffect(
    () => {
      const isSubmitDisabled = () => {
        return (
          formData.accountNumber === '' ||
          formData.bankBik === '' ||
          formData.bankName === '' ||
          formData.correspondentAccountNumber === '' ||
          formData.clientInn === ''
        );
      };

      setIsButtonDisabled(isSubmitDisabled);
    },
    [formData]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        resetBankAccountNewState();
        history.goBack();
      }
    },
    [status]
  );

  React.useEffect(
    () => {
      setIsInfoPopup(false);

      if (formData.clientInn.length >= 10) {
        getCompanyByInn({ inn: formData.clientInn });
      }
    },
    [formData.clientInn]
  );

  React.useEffect(() => {
    if (history.location.state && history.location.state.clientInn) {
      setFormData({
        ...formData,
        clientInn: history.location.state.clientInn
      });
    }

    return () => resetStateCompanyByInn();
  }, []);

  React.useEffect(
    () => {
      if (statusInn === REQUEST_STATUSES.GOT && isInfoPopup) {
        setFormData({
          ...formData,
          companyName: companyByInn.companyShortName
        });
      }
    },
    [statusInn, isInfoPopup]
  );

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const isInputValid = (name: string) => {
    const value = formData[name];
    switch (name) {
      case 'accountNumber':
      case 'bankBik':
      case 'correspondentAccountNumber':
        return /^\d+$/.test(value);
      case 'bankName':
        return /^[\d\w\s\-]+$/.test(value);
      case 'clientInn':
        return /^[\d\w]+$/.test(value) && value.length >= 10;
      default:
        return true;
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    const isFormValid = Object.keys(formData).every(fieldName =>
      isInputValid(fieldName)
    );

    if (isFormValid) {
      createBankAccount(formData);
    }
  };

  const onPopupClick = () => {
    setIsInfoPopup(true);
  };

  return (
    <ApplicationViewStyled>
      <ApplicationTitle>Создать расчетный счет</ApplicationTitle>

      <ContainerInputs onSubmit={handleSubmit}>
        <WrapperInput>
          <p>Данные счета:</p>
          <WrapperInputDetail>
            <p>Номер счета*:</p>
            <Input
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleInputChange}
            />
          </WrapperInputDetail>

          <WrapperInputDetail>
            <p>БИК Банка*:</p>
            <Input
              name="bankBik"
              value={formData.bankBik}
              onChange={handleInputChange}
            />
          </WrapperInputDetail>

          <WrapperInputDetail>
            <p>Банк*:</p>
            <Input
              name="bankName"
              value={formData.bankName}
              onChange={handleInputChange}
            />
          </WrapperInputDetail>

          <WrapperInputDetail>
            <p>Корсчет*:</p>
            <Input
              name="correspondentAccountNumber"
              value={formData.correspondentAccountNumber}
              onChange={handleInputChange}
            />
          </WrapperInputDetail>

          <Button
            label="Создать"
            type="submit"
            disabled={isButtonDisabled}
            w="fit-content"
            h="40px"
          />
        </WrapperInput>

        <WrapperInput>
          <p>Данные компании:</p>
          <WrapperInputDetail>
            <p>ИНН Клиента*:</p>
            <Input
              name="clientInn"
              value={formData.clientInn}
              onChange={handleInputChange}
            />

            {formData.clientInn &&
            formData.clientInn.length < 10 ? null : statusInn ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              statusInn === REQUEST_STATUSES.GOT &&
              !isInfoPopup && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={onPopupClick}
                />
              )
            )}
          </WrapperInputDetail>

          <WrapperInputDetail>
            <p>Наименование:</p>
            <Input
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
            />
          </WrapperInputDetail>
        </WrapperInput>
      </ContainerInputs>
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ BankAccount, SCF }: CRM & STORE) => ({
  status: BankAccount.createBankAccount.status,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createBankAccount,
      resetBankAccountNewState,
      getCompanyByInn,
      resetStateCompanyByInn
    },
    dispatch
  );

const BankAccountNewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BankAccountNew)
);

export { BankAccountNewConnect as BankAccountNew };
