import * as React from 'react';
import { ApplicationsList } from 'Application/components';
import { NavigationLink, Spinner } from 'Layouts/components';
import store from 'src/store';
import { STORE, REQUEST_STATUSES } from 'src/globaltypes';
import { CounterBox } from 'src/features/SCF/UI/BarSection';

// TODO duplication  with EachCabinetFunction
export function showQuantity(key) {
  const state: STORE = store.getState();
  const quantities = state.Application.getApplicationsQuantity.data;
  const quantityStatus = state.Application.getApplicationsQuantity.status;
  return quantityStatus === REQUEST_STATUSES.REQUEST ? (
    <Spinner size={22} />
  ) : key === 'general' ? (
    <CounterBox>{quantities[key]}</CounterBox>
  ) : (
    quantities[key]
  );
}

// TODO refactor
export function createApplicationsListCabinetComponent({ path, type, link }) {
  return {
    path,
    render: location => <ApplicationsList listType={type} key={location.key} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template={link.template ? link.template : link.icon}
        fontAwesomeIcon={link.icon}
        labelInfo={showQuantity(link.countName)}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={link.label}
      />
    )
  };
}
