import { FileUploadBlock } from 'Application/types';

export type SetFileUploadBlockType = FileUploadBlock;

export enum UPLOAD_BLOCK_TYPE_ACTIONS {
  SET = 'SET_UPLOAD_BLOCK_TYPE',
  RESET = 'RESET_UPLOAD_BLOCK_TYPE'
}

export const initialState: FileUploadBlock = {
  type: 'default'
};

const setFileUploadBlock = (
  state: FileUploadBlock = initialState,
  action: {
    type: UPLOAD_BLOCK_TYPE_ACTIONS;
    data: SetFileUploadBlockType;
  }
): FileUploadBlock => {
  switch (action.type) {
    case UPLOAD_BLOCK_TYPE_ACTIONS.SET:


      return {
        ...state,
        ...action.data
      };
    case UPLOAD_BLOCK_TYPE_ACTIONS.RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default setFileUploadBlock;
