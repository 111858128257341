import styled, { css } from 'styled-components';
import {
  lightGray,
  navyBlue,
  rb,
  blue,
  lighterBlue
} from 'shared/styled';
import { shadeColor } from 'src/shared/utils/Utils';

export const TableRowStyled = styled.tr`
  height: 60px;

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;

      &:hover {
        td {
          background-color: ${({ background }) =>
            background ? background : `${lighterBlue}`};
        }
      }
    `};
  td {
    font-size: 14px;
    color: ${({ color }) => (color ? color : navyBlue)};
    &:nth-child(1) {
      font-family: ${rb};

      p {
        cursor: pointer;
        margin-left: 15px;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        padding: 1px;
        float: left;

        &::before {
          content: '';
          width: 0;
          height: 0;

          display: block;

          top: 25%;
          left: -15px;
          position: absolute;

          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid
            ${({ canExpand }) => (canExpand ? blue : lightGray)};

          transition: transform 0.2s;
          transform-origin: center;
          transform: rotateZ(-90deg);
          transform: ${({ isExpanded, canExpand }) =>
            isExpanded && canExpand ? 'rotateZ(0deg)' : 'rotateZ(-90deg)'};
        }
      }
    }
  }
  background-color: ${({ background }) =>
    background ? shadeColor(background, 5) : 'none'};
`;

export const TableThStyled = styled.th`
  ${({ width }) =>
    width
      ? css`
          width: ${width};
        `
      : ''};
`;
