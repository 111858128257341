import * as React from 'react';
import { ProfileInfo } from 'src/features/Application/types';
import Popup from 'reactjs-popup';
import { ProfileInfoPopupStyled, PullRight, PopupTrigger } from './styles';
import { CrossCircleBtn } from 'shared/styled';

interface Props {
    person: ProfileInfo;
    showRole?: boolean;
}

function getRole(roles) {
    switch (roles[0]) {
        case 'InternalAgent':
            return 'F';
        case 'ExternalAgent':
            return 'A';
        default:
            return 'K';
    }
}

function getFullRole(roles) {
    switch (roles[0]) {
        case 'InternalAgent':
            return 'Аналитик FinFactory';
        case 'ExternalAgent':
            return 'Aгент';
        default:
            return 'Kлиент';
    }
}

export const ProfileInfoPopup = (props: Props) => {
    const ref = React.useRef(null);
    const person = props.person;
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();
    const toggleTooltip = () => ref.current.toggle();
    return (
        <Popup ref={ref} position="top right"
            trigger={
                <PopupTrigger>
                    {props.showRole && getRole(person.roles)}
                    {props.showRole && ' | '}
                    {person.firstName} {person.lastName}
                </PopupTrigger>
            }>
            <ProfileInfoPopupStyled>
                <PullRight>
                    <CrossCircleBtn onClick={closeTooltip} />
                </PullRight>
                <p>{props.showRole && getFullRole(person.roles)}</p>
                <p>{person.firstName} {person.lastName}</p>
                <p>{person.email}</p>
                <p>{person.phoneNumber}</p>
            </ProfileInfoPopupStyled>
        </Popup>
    )
}
