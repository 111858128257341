import { SET_COMPANY_DATA } from '../reducers/setCompanyFormData';

export interface CompanyWrite {
  analystId?: number;
  apartment?: string;
  area?: string;
  backbone?: any;
  capital?: number;
  city?: string;
  companyAddress?: string;
  companyEmail?: any;
  companyFullName?: string;
  companyPhone?: any;
  companyShortName?: string;
  corpus?: any;
  financialsYear?: number;
  fss?: string;
  hasContacts?: any;
  holdingId?: any;
  holdingName?: any;
  house?: string;
  id?: number;
  inAction?: number;
  index?: number;
  inn?: string;
  isDeleted?: boolean;
  is_customer44?: boolean;
  is_customer223?: boolean;
  is_target_client?: boolean;
  kladr?: number;
  kpp?: string;
  lastActivity?: string;
  lastLogin?: string;
  managerId?: number;
  maxNum?: number;
  minNum?: number;
  modifiedDateTime?: string;
  netIncome?: number;
  okvedId?: number;
  okvedCode?: string;
  okvedName?: string;
  okvedVersion?: null;
  opfId?: number;
  pfr?: string;
  regDate?: string;
  region?: number;
  revenue?: number;
  segment?: any;
  settlement?: any;
  startCapital?: number;
  street?: string;
  hasOpenLimitsInfo?: string;
  taxSystem?: number;
}

export interface CompanyRead extends CompanyWrite {}

export interface CompaniesData extends Partial<CompanyWrite> {}

export const setCompanyFormData = (data: CompaniesData) => ({
  type: SET_COMPANY_DATA.SET,
  data
});

export const resetCompanyFormData = () => ({
  type: SET_COMPANY_DATA.RESET
});
