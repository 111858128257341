import {
  GUARANTEES_TYPE,
  TAX_TYPE,
  TENDER_TYPE
} from './GuaranteesApplicationType';

export enum APPLICATION_STATUSES {
  EMPTY = 'EMPTY',
  DRAFT = 'DRAFT',
  // client has transferred application to internal agent
  TRANSFERRED_TO_AGENT = 'TRANSFERRED_TO_AGENT',
  // agent took application in work
  IN_WORK = 'IN_WORK',
  // agent asked to make changes to the application
  AWAITING = 'AWAITING',
  // internal agent has transferred application to bank
  TRANSFERRED_TO_BANK = 'TRANSFERRED_TO_BANK',
  // bank took application in work
  REVIEW = 'REVIEW',
  // bank asked to make changes to the application
  DOCS_REQUEST = 'DOCS_REQUEST',
  // bank rejected an application
  BANK_REJECT = 'BANK_REJECT',
  // bank made an offer
  APPROVED = 'APPROVED',
  // agent approved application and transferred to client
  AGENT_APPROVED = 'AGENT_APPROVED',
  // client rejected an application
  USER_REJECT = 'USER_REJECT',
  // client refused the bank's conditions
  USER_DENIAL = 'USER_DENIAL',
  // application successfully completed
  SUCCESS = 'SUCCESS',

  AGENT_REJECT = 'AGENT_REJECT',

  EXTERNAL_AGENT_REJECT = 'EXTERNAL_AGENT_REJECT'
}

export enum APPLICATION_TYPES {
  NONE = '',
  EMPTY = 'EMPTY',
  FACTORING = 'FACTORING',
  GUARANTEES = 'GUARANTEES',
  FINANCING = 'FINANCING',
  CREDITING = 'CREDITING',
  CONTRACT_FINANCING = 'CONTRACT_FINANCING',
  PROPERTY_FINANCING = 'PROPERTY_FINANCING',
  LEASING = 'LEASING'
}

export enum APPLICATION_TYPE_ROUTES {
  NONE = 'initialize',
  CHOICE_SCREEN = 'choice_screen',
  FACTORING = 'factoring',
  GUARANTEES = 'guarantees',
  FINANCING = 'financing',
  CREDITING = 'crediting',
  CONTRACT_FINANCING = 'contract_financing',
  PROPERTY_FINANCING = 'property_financing'
}

export enum DOCUMENT_UPLOAD_TYPE {
  NONE = '',
  AGREEMENT = 'AGREEMENT',
  FACTORING = 'FACTORING',
  GUARANTEE_CONTRACT = 'GUARANTEE_CONTRACT',
  CONTRACT_FINANCING = 'CONTRACT_FINANCING',
  PROPERTY_FINANCING = 'PROPERTY_FINANCING',
  GROUP_SCHEME = 'GROUP_SCHEME'
}

export enum DOCUMENT_DOWNLOAD_TYPE {
  NONE = '',
  APPLICATION = 'APPLICATION',
  COMMENT = 'COMMENT',
  BORROWER_COMPANY = 'BORROWER_COMPANY',
  PROPERTY_FINANCING_PROPERTY = 'PROPERTY_FINANCING_PROPERTY'
}

export enum REGISTRY_DOWNLOAD_TYPE {
  NONE = '',
  COMMENT = 'COMMENT',
  REGISTRY = 'REGISTRY'
}

export enum BORROWER_COMPANY_ROLE {
  BORROWER = 'BORROWER',
  PLEDGER = 'PLEDGER',
  GUARANTOR = 'GUARANTOR',
  PRINCIPAL = 'PRINCIPAL'
}

export const SELECT_STATUS = {
  1: 'На рассмотрении',
  2: 'Предварительно одобрена',
  3: 'Окончательно одобрена',
  4: 'Отказ'
};

export interface Document {
  readonly id: number;
  name: string;
  size: number;
  createdDate: string;
  type: DOCUMENT_DOWNLOAD_TYPE;
  metaInfo?: string;
}

export interface RegistryDocument {
  id: number;
  name: string;
  size: number;
  createdDate: string;
  // type: REGISTRY_DOWNLOAD_TYPE;
  metaInfo?: string;
}

export interface BankApplication {
  readonly id: number;
  name: string;
  status: APPLICATION_STATUSES;
  externalVisibility: number;
  amount: number;
  modifiedAt: string;
  apiId?: number;
  apiName?: string;
}

export interface BorrowerCompany {
  id: number;
  applicationId: number;
  name: string;
  individualNumber: string;
  roles: BORROWER_COMPANY_ROLE[];
  documents: Document[];
}

export interface Registries {
  name: string;
  documents: RegistryDocument[];
}

export interface ProfileInfo {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: string[];
}

export interface ApplicationWrite {
  guid: string;
  readonly id: number;
  financingType: APPLICATION_TYPES;
  guaranteesType: GUARANTEES_TYPE;
  tender: TENDER_TYPE;
  tenderComment: string;
  commercial: TENDER_TYPE;
  purchaseLink: string;
  desiredBanks: string;
  tax: TAX_TYPE;
  guaranteesSubTypeId: number;
  refinancing: boolean;
  clientCompanyName: string;
  code: string;
  amount: number;
  term: number;
  termStartDate: string;
  termEndDate: string;
  status: APPLICATION_STATUSES;
  registry: Registries[];
  borrowerCompanies: BorrowerCompany[];
  customerInn: string;
  customerName: string;
  taxSystem: string;

  groupDocuments: Document[];
  agreementDocuments?: Document[];
  contractDocuments: Document[];
  documents: Document[];

  registries: RegistryDocument[];
}

export interface ApplicationRead extends ApplicationWrite {
  isRead: boolean;
  isAuthor: boolean;
  createdAt: string;
  modifiedAt: string;
  initiatorEmail?: string;
  source?: string;
  bankApplications: BankApplication[];
  mainApplicationId: number;
  client?: ProfileInfo;
  internalAgentId?: number;
  internalAgent?: ProfileInfo;
}
