import { PUT_CHECKLIST } from '../reducers/putChecklist';

export interface RequestData {
  DocsChecklist?: number;
  BkiChecklist?: number;
  BgParametersChecklist?: number;
  ApplicationToBankCheckList?: number;
  DealToBankChecklist?: number;
}

export const req = (id: string, data: RequestData) => ({
  type: PUT_CHECKLIST.REQ,
  id,
  data
});

export const got = () => ({
  type: PUT_CHECKLIST.GOT
});

export const err = (error: {}) => ({
  type: PUT_CHECKLIST.ERR,
  error
});

export const reset = () => ({
  type: PUT_CHECKLIST.RESET
});
