import * as React from 'react';

import { BorrowerCompanyRole } from 'shared/constants';

import {
  APPLICATION_TYPE_ROUTES,
  BorrowerCompany,
  CreditingApplicationRead
} from 'Application/types';

import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';

import { DocumentsTable, IRow } from 'Layouts/components';
import { WarningBlock } from 'Application/components';

import {
  CompanyDocumentsTitle,
  CompanyProperty,
  ViewCompanyTabItem
} from './styles';

import { EditDocumentsLink } from '../../styles';
import ApplicationApi from 'src/features/Application/api';
import { DownloadLink } from 'shared/styled';
import { STORE } from 'src/globaltypes';
import { connect } from 'react-redux';

interface StateToProps {
  clientApplication: Partial<CreditingApplicationRead>;
}

interface OwnProps {
  activeCompany: BorrowerCompany;
  rows: IRow[];
  documentList: ResDocumentListByApplicationId;
}

type Props = OwnProps & StateToProps;

const BorrowerCompanyDocumentTab: React.FC<Props> = ({
  clientApplication,
  activeCompany,
  rows,
  documentList
}: Props) => (
  <ViewCompanyTabItem>
    <CompanyDocumentsTitle>Документы компании</CompanyDocumentsTitle>
    <CompanyProperty>
      ИНН: <span>{activeCompany.individualNumber}</span>
    </CompanyProperty>
    <CompanyProperty>
      Роль:{' '}
      {activeCompany.roles.map((role, key) => (
        <span key={key}>
          {BorrowerCompanyRole[role]}
          {key === activeCompany.roles.length - 1 ? '' : ', '}
        </span>
      ))}
    </CompanyProperty>

    {activeCompany.documents.length !== 0 &&
      documentList.items.length === 0 && (
        <>
          {activeCompany.documents.length !== 0 ? (
            <DocumentsTable
              headers={[
                '№',
                'Название',
                'Дата добавления',
                'Размер файла',
                <DownloadLink
                  href={ApplicationApi.getDownloadBorrowerCompanyDocumentsLink(
                    activeCompany.id
                  )}
                />
              ]}
              rows={rows}
            />
          ) : (
            activeCompany.documents.length === 0 &&
            documentList.items.length === 0 && (
              <WarningBlock
                text={[
                  'Вы не загрузили необходимые документы компании.',
                  <React.Fragment>
                    Чтобы загрузить документы, нажмите "
                    <EditDocumentsLink
                      to={`/cabinet/client/application-form-${APPLICATION_TYPE_ROUTES[
                        clientApplication.financingType
                      ].replace('_', '-')}/edit/${
                        clientApplication.id
                      }/documents`}
                    >
                      Редактировать документы
                    </EditDocumentsLink>
                    ".
                  </React.Fragment>,
                  `Заявки без документов компаний заемщика банками к рассмотрению не принимаются.`
                ]}
              />
            )
          )}
        </>
      )}
  </ViewCompanyTabItem>
);

const mapStateToProps = ({ ClientRole }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data
});

const BorrowerCompanyDocumentTabConnect = connect<StateToProps>(
  mapStateToProps
)(BorrowerCompanyDocumentTab);

export { BorrowerCompanyDocumentTabConnect as BorrowerCompanyDocumentTab };
