import * as React from 'react';

import { convertKbToMb } from 'src/shared/utils/Utils';

import { Document as IDocument } from 'Application/types';
import ApplicationApi from 'Application/api';

import { UploadFileIcon } from 'shared/styled';
import { DocumentStyled, DocumentInfo } from './styles';

interface Props {
  document: IDocument;
}

export const Document: React.FC<Props> = ({ document }) => (
  <DocumentStyled href={ApplicationApi.getDownloadDocumentLink(document)}>
    <UploadFileIcon />
    <DocumentInfo>
      <span>{document.name}</span>
      <span>{convertKbToMb(document.size)}</span>
    </DocumentInfo>
  </DocumentStyled>
);
