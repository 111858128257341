import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum SIGNIN_ACTIONS {
  REQ = 'SIGNIN_REQ',
  GOT = 'SIGNIN_GOT',
  ERR = 'SIGNIN_ERR',
  RESET = 'SIGNIN_RESET'
}

export const initialState: Reducer = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const signIn = (
  state: Reducer = initialState,
  action: {
    type: string;
    error: {};
  }
): Reducer => {
  switch (action.type) {
    case SIGNIN_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SIGNIN_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SIGNIN_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SIGNIN_ACTIONS.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE
      };
    default:
      return state;
  }
};

export default signIn;
