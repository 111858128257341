import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getSecondCompanyByInn,
  RequestDataType as RequestDataTypeSecondCompany,
  ResponseDataType as ResponseDataTypeSecondCompany
} from 'src/features/SCF/actions/getSecondCompanyByInnThirdParty';
import { UnexpectedError } from 'Layouts/components';
import {
  CreateConnectionsPageStyled,
  CreateBtn,
  CreateBtnText,
  InnFilter,
  InnContainer,
  InnArea,
  InnPopup,
  InfoField
} from './styles';
import '../styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { req as postFactoringConnectionFactorRole } from 'entities/SCF/Factor/model/actions/postFactoringConnectionFactorRole';
import { RequestDataType as PostFactoringConnectionData } from 'entities/SCF/Factor/model/reducers/postFactoringConnectionFactorRole';

interface StateToProps {
  status: REQUEST_STATUSES;
  secondStatus: REQUEST_STATUSES;
  sendStatus: REQUEST_STATUSES;
  error: ResponseError;
  secondError: ResponseError;
  companyByInn: ResponseDataType;
  secondCompanyByInn: ResponseDataTypeSecondCompany;
}

type OwnState = {
  supplierInn: string;
  debtorInn: string;
  companyByInnPre: ResponseDataType;
  companyByInnPreSecond: ResponseDataType;
};

interface DispatchToProps {
  postFactoringConnectionFactorRole: (
    data: PostFactoringConnectionData
  ) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getSecondCompanyByInn: (data: RequestDataTypeSecondCompany) => void;
}

type Props = StateToProps & DispatchToProps;

class ConnectionsCreatePage extends React.Component<Props, OwnState> {
  constructor(props) {
    super(props);
    this.state = {
      supplierInn: '',
      debtorInn: '',
      companyByInnPre: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      },
      companyByInnPreSecond: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    };
    this.handleSupplierInnChange = this.handleSupplierInnChange.bind(this);
    this.handleDebtorInnChange = this.handleDebtorInnChange.bind(this);
  }

  handleSupplierInnChange(event) {
    this.setState({ supplierInn: event.target.value });
  }

  handleDebtorInnChange(event) {
    this.setState({ debtorInn: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  supplierFetch = async () => {
    this.setState({
      companyByInnPre: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    });
    this.props.getCompanyByInn({ inn: this.state.supplierInn });
    await this.delay(1100);
    {
      this.props.error.code === 404 &&
      this.props.status === REQUEST_STATUSES.ERROR
        ? this.setState({
            companyByInnPre: {
              inn: '',
              TaxSystem: null,
              companyShortName: '',
              companyAddress: '',
              companyFullName: '',
              ogrn: '',
              regDate: null,
              kpp: '',
              pfr: '',
              fss: '',
              startCapital: null,
              okvedCode: '',
              okvedName: '',
              okvedPic: '',
              employees: null,
              employeesYear: null,
              taxSystemName: '',
              taxSystemCode: null,
              taxes: { taxYear: null, taxInfo: [] }
            }
          })
        : this.setState({
            companyByInnPre: {
              inn: this.props.companyByInn.inn,
              TaxSystem: this.props.companyByInn.TaxSystem,
              companyShortName: this.props.companyByInn.companyShortName,
              companyAddress: this.props.companyByInn.companyAddress,
              companyFullName: this.props.companyByInn.companyFullName,
              ogrn: this.props.companyByInn.ogrn,
              regDate: this.props.companyByInn.regDate,
              kpp: this.props.companyByInn.kpp,
              pfr: this.props.companyByInn.pfr,
              fss: this.props.companyByInn.fss,
              startCapital: this.props.companyByInn.startCapital,
              okvedCode: this.props.companyByInn.okvedCode,
              okvedName: this.props.companyByInn.okvedName,
              okvedPic: this.props.companyByInn.okvedPic,
              employees: this.props.companyByInn.employees,
              employeesYear: this.props.companyByInn.employeesYear,
              taxSystemName: this.props.companyByInn.taxSystemName,
              taxSystemCode: this.props.companyByInn.taxSystemCode,
              taxes: this.props.companyByInn.taxes
            }
          });
      document.getElementById('supplierPopup').style.display = 'flex';
    }
  };

  debtorFetch = async () => {
    this.setState({
      companyByInnPreSecond: {
        inn: '',
        TaxSystem: null,
        companyShortName: '',
        companyAddress: '',
        companyFullName: '',
        ogrn: '',
        regDate: null,
        kpp: '',
        pfr: '',
        fss: '',
        startCapital: null,
        okvedCode: '',
        okvedName: '',
        okvedPic: '',
        employees: null,
        employeesYear: null,
        taxSystemName: '',
        taxSystemCode: null,
        taxes: { taxYear: null, taxInfo: [] }
      }
    });
    this.props.getSecondCompanyByInn({ inn: this.state.debtorInn });
    await this.delay(1100);
    {
      this.props.secondError.code === 404 &&
      this.props.secondStatus === REQUEST_STATUSES.ERROR
        ? this.setState({
            companyByInnPreSecond: {
              inn: '',
              TaxSystem: null,
              companyShortName: '',
              companyAddress: '',
              companyFullName: '',
              ogrn: '',
              regDate: null,
              kpp: '',
              pfr: '',
              fss: '',
              startCapital: null,
              okvedCode: '',
              okvedName: '',
              okvedPic: '',
              employees: null,
              employeesYear: null,
              taxSystemName: '',
              taxSystemCode: null,
              taxes: { taxYear: null, taxInfo: [] }
            }
          })
        : this.setState({
            companyByInnPreSecond: {
              inn: this.props.secondCompanyByInn.inn,
              TaxSystem: null,
              companyShortName: this.props.secondCompanyByInn.companyShortName,
              companyAddress: this.props.secondCompanyByInn.companyAddress,
              companyFullName: this.props.secondCompanyByInn.companyFullName,
              ogrn: this.props.secondCompanyByInn.ogrn,
              regDate: this.props.secondCompanyByInn.regDate,
              kpp: this.props.secondCompanyByInn.kpp,
              pfr: this.props.secondCompanyByInn.pfr,
              fss: this.props.secondCompanyByInn.fss,
              startCapital: this.props.secondCompanyByInn.startCapital,
              okvedCode: this.props.secondCompanyByInn.okvedCode,
              okvedName: this.props.secondCompanyByInn.okvedName,
              okvedPic: this.props.secondCompanyByInn.okvedPic,
              employees: this.props.companyByInn.employees,
              employeesYear: this.props.companyByInn.employeesYear,
              taxSystemName: this.props.secondCompanyByInn.taxSystemName,
              taxSystemCode: this.props.secondCompanyByInn.taxSystemCode,
              taxes: this.props.secondCompanyByInn.taxes
            }
          });
      document.getElementById('debtorPopup').style.display = 'flex';
    }
  };

  componentDidUpdate(prevProps, prevState: OwnState) {
    if (
      (this.state.debtorInn !== prevState.debtorInn &&
        this.state.debtorInn.length === 10) ||
      this.state.debtorInn === null
    ) {
      this.debtorFetch();
    } else if (
      (this.state.supplierInn !== prevState.supplierInn &&
        this.state.supplierInn.length == 10) ||
      this.state.supplierInn === null
    ) {
      this.supplierFetch();
    } else if (
      this.state.debtorInn !== prevState.debtorInn &&
      this.state.debtorInn.length < 10
    ) {
      document.getElementById('debtorInfo').style.display = 'none';
      this.setState({
        companyByInnPreSecond: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      });
      document.getElementById('debtorPopup').style.display = 'none';
    } else if (
      this.state.supplierInn !== prevState.supplierInn &&
      this.state.supplierInn.length < 10
    ) {
      document.getElementById('supplierInfo').style.display = 'none';
      this.setState({
        companyByInnPre: {
          inn: '',
          TaxSystem: null,
          companyShortName: '',
          companyAddress: '',
          companyFullName: '',
          ogrn: '',
          regDate: null,
          kpp: '',
          pfr: '',
          fss: '',
          startCapital: null,
          okvedCode: '',
          okvedName: '',
          okvedPic: '',
          employees: null,
          employeesYear: null,
          taxSystemName: '',
          taxSystemCode: null,
          taxes: { taxYear: null, taxInfo: [] }
        }
      });
      document.getElementById('supplierPopup').style.display = 'none';
    }
  }

  transitionError = () => {};

  onCreateConnectionClick = async () => {
    this.props.postFactoringConnectionFactorRole({
      supplierInn: this.state.supplierInn,
      supplierName: undefined,
      debtorINN: this.state.debtorInn,
      debtorName: undefined,
      visibleToDebtor: false,
      userID: undefined
    });
    await this.delay(300);
    {
      this.props.sendStatus === REQUEST_STATUSES.GOT
        ? history.replace('/factor/connections?page=1')
        : this.transitionError;
    }
    history.replace('/factor/connections?page=1');
    window.location.reload();
  };

  onSupplierPopupClick = e => {
    document.getElementById('supplierPopup').style.display = 'none';
    document.getElementById('supplierInfo').style.display = 'flex';
  };

  onDebtorPopupClick = e => {
    document.getElementById('debtorPopup').style.display = 'none';
    document.getElementById('debtorInfo').style.display = 'flex';
  };

  render() {
    const { status, companyByInn, error, secondCompanyByInn } = this.props;
    return (
      <CreateConnectionsPageStyled>
        <h2>Создать связку</h2>
        <InnArea>
          <InnFilter>
            <p>Поставщик:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleSupplierInnChange}
                className="input-inn"
              />
            </InnContainer>
            {this.state.companyByInnPre.inn === '' ? (
              <InnPopup
                id="supplierPopup"
                style={{ display: 'none', marginTop: '10px' }}
              >
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              <InnPopup
                className="inn-popup"
                id="supplierPopup"
                onClick={e => this.onSupplierPopupClick(e)}
                style={{ display: 'none' }}
              >
                <h2>{this.state.companyByInnPre.companyFullName}</h2>
                {this.state.companyByInnPre.companyAddress.length > 3 ? (
                  <p>{this.state.companyByInnPre.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {this.state.companyByInnPre.inn}</p>
                <p>КПП {this.state.companyByInnPre.kpp}</p>
              </InnPopup>
            )}

            <InfoField id="supplierInfo">
              <h2>{companyByInn.companyFullName}</h2>
              {companyByInn.companyAddress.length > 3 ? (
                <p>{companyByInn.companyAddress}</p>
              ) : (
                ''
              )}
              <p>ИНН {companyByInn.inn}</p>
              <p>КПП {companyByInn.kpp}</p>
            </InfoField>
          </InnFilter>
          <InnFilter>
            <p>Дебитор:</p>
            <InnContainer>
              <p>ИНН*</p>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnChange}
                className="input-inn"
              />
            </InnContainer>
            {this.state.companyByInnPreSecond.inn === '' ? (
              <InnPopup
                id="debtorPopup"
                style={{ display: 'none', marginTop: '10px' }}
              >
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              <InnPopup
                className="inn-popup"
                id="debtorPopup"
                onClick={e => this.onDebtorPopupClick(e)}
                style={{ display: 'none' }}
              >
                <h2>{this.state.companyByInnPreSecond.companyFullName}</h2>
                {this.state.companyByInnPreSecond.companyAddress.length > 3 ? (
                  <p>{this.state.companyByInnPreSecond.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {this.state.companyByInnPreSecond.inn}</p>
                <p>КПП {this.state.companyByInnPreSecond.kpp}</p>
              </InnPopup>
            )}

            <InfoField id="debtorInfo">
              <h2>{secondCompanyByInn.companyFullName}</h2>
              {secondCompanyByInn.companyAddress.length > 3 ? (
                <p>{secondCompanyByInn.companyAddress}</p>
              ) : (
                ''
              )}
              <p>ИНН {secondCompanyByInn.inn}</p>
              <p>КПП {secondCompanyByInn.kpp}</p>
            </InfoField>
          </InnFilter>
        </InnArea>

        {this.state.supplierInn.length === 10 &&
        this.state.debtorInn.length === 10 ? (
          <CreateBtn onClick={this.onCreateConnectionClick}>
            <CreateBtnText>Создать</CreateBtnText>
          </CreateBtn>
        ) : (
          <CreateBtn
            style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}
          >
            <CreateBtnText>Создать</CreateBtnText>
          </CreateBtn>
        )}
        {this.props.sendStatus === REQUEST_STATUSES.GOT && <p />}
        {this.props.sendStatus === REQUEST_STATUSES.ERROR && (
          <UnexpectedError />
        )}
      </CreateConnectionsPageStyled>
    );
  }
}
const mapStateToProps = ({ SCF, SCFFactor }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  secondCompanyByInn: SCF.getSecondCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  secondStatus: SCF.getSecondCompanyByInnThirdParty.status,
  sendStatus: SCFFactor.postFactoringConnectionFactorRole.status,
  error: SCF.getCompanyByInnThirdParty.error,
  secondError: SCF.getSecondCompanyByInnThirdParty.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      getSecondCompanyByInn,
      postFactoringConnectionFactorRole
    },
    dispatch
  );

const ConnectionsCreateConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectionsCreatePage)
);

export { ConnectionsCreateConnect as ConnectionsCreatePage };
