import { SET_SUPPLY_CONTRACT } from '../reducers/setSupplyContract';

export interface SupplyContractWrite {
  debtorInn: string;
  debtorName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  paymentDefermentFixed: boolean;
  paymentDeferment: number;
}

export interface SupplyContractRead extends SupplyContractWrite {
  supplierInn: string;
  supplierName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
}

export interface SupplyContract extends Partial<SupplyContractWrite> {}

export const setSupplyContract = (data: SupplyContract) => ({
  type: SET_SUPPLY_CONTRACT.SET,
  data
});

export const resetSupplyContract = () => ({
  type: SET_SUPPLY_CONTRACT.RESET
});
