import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import { GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import SCFApi from 'entities/SCF/Supplier/api';

function* getMonetaryClaimsEarlyPaymentApplicationsByGuid(action: {
  type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getMonetaryClaimsEarlyPaymentApplicationsByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimsEarlyPaymentApplicationsByGuidSaga() {
  yield takeLatest(
    GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.REQ,
    getMonetaryClaimsEarlyPaymentApplicationsByGuid
  );
}
