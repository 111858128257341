import * as React from 'react';
import { format } from 'date-fns';

import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { DownloadLink, SignBtn, SignBtnText, SignBtns } from '../styles';
import {
  RegistryFile,
  SubRegistry
} from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFiles';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

interface Props {
  list: RegistryFile[];
  onSignClick: (index: number, registryFileName: string) => void;
  onSignClickSub: (out: string[]) => void;
}

export class RegistriesSubList extends React.Component<Props> {
  state = {
    showState: this.props.list.map(item => ({
      id: item.id,
      expanded: false,
      count: 0
    }))
  };

  checkCount(subReg: SubRegistry[]) {
    let checker = 0;
    for (let i = 0; i < subReg.length; i++) {
      if (subReg[i].signatureFilename !== null) checker = checker + 1;
    }
    return checker;
  }

  makeArray(subReg: SubRegistry[]) {
    let outArr = [];
    subReg.forEach(
      el => (el.signatureFilename === null ? outArr.push(el.id) : el)
    );
    return outArr;
  }

  render() {
    return (
      <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
        <TableHeaderStyled>
          <tr>
            <TableHeaderCellStyled width="4%" />
            <TableHeaderCellStyled width="20%">
              Наименование файла
            </TableHeaderCellStyled>
            <TableHeaderCellStyled width="25%">Подпись</TableHeaderCellStyled>
            <TableHeaderCellStyled width="18%">
              Дата добавления
            </TableHeaderCellStyled>
            <TableHeaderCellStyled width="13%">
              Размер файла
            </TableHeaderCellStyled>
            <TableHeaderCellStyled width="20%">Создатель</TableHeaderCellStyled>
            <TableHeaderCellStyled width="10%">Статус</TableHeaderCellStyled>
            <TableHeaderCellStyled width="25%">Ошибки</TableHeaderCellStyled>
          </tr>
        </TableHeaderStyled>
        <tbody>
          {this.props.list.map((item, key) => (
            <React.Fragment key={key}>
              <TableRowStyled
                isExpanded={
                  this.state.showState.find(
                    el => el.id.toString() === item.id.toString()
                  ).expanded
                }
                canExpand={item.registryFactorSubDocumentInfo.length > 0}
                key={key}
              >
                <td
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <p
                    onClick={e => {
                      e.stopPropagation();
                      const expand = this.state.showState.map(
                        el =>
                          el.id.toString() === item.id.toString()
                            ? {
                                ...el,
                                expanded: !el.expanded,
                                count: this.checkCount(
                                  item.registryFactorSubDocumentInfo
                                )
                              }
                            : el
                      );
                      this.setState({
                        showState: expand
                      });
                    }}
                  />
                </td>
                <td>
                  <DownloadLink
                    href={SCFDebtorApi.getDownloadRegistry(item.id)}
                  />{' '}
                  {item.registryFilename}
                </td>
                <td>
                  {item.signatureFilename !== null ? (
                    <DownloadLink
                      href={SCFDebtorApi.getDownloadSignature(item.id)}
                    />
                  ) : (
                    <p />
                  )}
                  {item.signatureFilename !== null ? (
                    item.signatureFilename
                  ) : (
                    <SignBtn
                      onClick={() =>
                        this.props.onSignClick(item.id, item.registryFilename)
                      }
                    >
                      <SignBtnText>Подписать</SignBtnText>
                    </SignBtn>
                  )}
                </td>
                <td>
                  {format(new Date(item.createdDateTime), 'dd.MM.yyyy - HH:mm')}
                </td>
                <td>{Math.round(item.size / 1024)} кб</td>
                <td>{item.creatorName}</td>
                <td>
                  {item.status === 1 ? <p>В обработке</p> : <p>Обработан</p>}
                </td>
                <td>
                  {item.errorsCount === 0 ? (
                    <p> - </p>
                  ) : (
                    <p>
                      ошибок: {item.errorsCount}; строк с ошибками:{' '}
                      {item.errorRowsCount}{' '}
                    </p>
                  )}
                </td>
              </TableRowStyled>
              {item.registryFactorSubDocumentInfo.map(
                out =>
                  this.state.showState.find(
                    el => el.id.toString() === item.id.toString()
                  ).expanded && (
                    <TableRowStyled
                      style={{
                        borderBottom: 'none',
                        backgroundColor: '#F1F8FF'
                      }}
                      key={out.id}
                    >
                      <td />
                      <td>
                        <DownloadLink
                          href={SCFDebtorApi.downloadDebtorSubRegistry(out.id)}
                        />
                        {out.registryFilename}
                      </td>
                      <td>
                        {out.signatureFilename !== null ? (
                          <DownloadLink
                            href={SCFDebtorApi.downloadDebtorSubSignature(
                              out.id
                            )}
                          />
                        ) : (
                          <p />
                        )}
                        {out.signatureFilename !== null ||
                        this.state.showState.find(
                          el => el.id.toString() === item.id.toString()
                        ).count > 1 ? (
                          out.signatureFilename
                        ) : (
                          <SignBtns>
                            <SignBtn
                              onClick={() =>
                                this.props.onSignClickSub([out.id])
                              }
                            >
                              <SignBtnText>Подписать</SignBtnText>
                            </SignBtn>

                            {item.registryFactorSubDocumentInfo[0].id ===
                              out.id &&
                            this.state.showState.find(
                              el => el.id.toString() === item.id.toString()
                            ).count >= 1 ? (
                              <SignBtn
                                onClick={() =>
                                  this.props.onSignClickSub(
                                    this.makeArray(
                                      item.registryFactorSubDocumentInfo
                                    )
                                  )
                                }
                              >
                                <SignBtnText>Подписать все</SignBtnText>
                              </SignBtn>
                            ) : (
                              <p />
                            )}
                          </SignBtns>
                        )}
                      </td>

                      <td>
                        {format(
                          new Date(out.createdDateTime),
                          'dd.MM.yyyy - HH:mm'
                        )}
                      </td>
                      <td>{Math.round(parseInt(out.fileSize) / 1024)} кб</td>
                      <td />
                      <td />
                      <td />
                    </TableRowStyled>
                  )
              )}
            </React.Fragment>
          ))}
        </tbody>
      </TableStyled>
    );
  }
}
