import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getClients,
  ResponseData
} from 'src/features/ClientsForAgent/actions/getClients';
import {
  req as createClient,
  RequestData
} from 'src/features/ClientsForAgent/actions/postCreateClient';
import { BtnAddClient, BtnSubmit, HeaderClients } from './styled';
import { Input } from 'shared/ui/Input';

interface StateToProps {
  clients: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusCreateClient: REQUEST_STATUSES;
}

interface State {
  isInnField: boolean;
  clientInn: string;
}

interface DispatchToProps {
  getClients: (agentInn: string) => void;
  createClient: (data: RequestData) => void;
}

interface AnyProps {
  agentInn: string;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  AnyProps;

class ClientsForAgent extends React.Component<Props, State> {
  state: State = {
    isInnField: false,
    clientInn: ''
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.statusCreateClient !== prevProps.statusCreateClient) {
      this.initFetch();
    }
  }

  initFetch() {
    const { getClients, agentInn } = this.props;

    getClients(agentInn);
  }

  handleInnChange = (clientInn: string) => {
    this.setState({ clientInn });
  };

  handleCreateNewClient = () => {
    if (
      this.state.clientInn.length >= 10 &&
      new RegExp(/^[a-zA-Z0-9]+$/).test(this.state.clientInn)
    ) {
      this.props.createClient({
        companyinn: this.state.clientInn,
        agentinn: +this.props.agentInn
      });
      this.setState({ isInnField: false, clientInn: '' });
    }
  };

  navigateToCompanies = (inn: string) => {
    window.open(`/crm/companies/${inn}`, '_blank');
  };

  render() {
    const { clients, status, error } = this.props;
    return (
      <ScrollTopComponent>
        <HeaderClients>
          <h2>Клиенты от агента</h2>
          <BtnAddClient
            isActive={this.state.isInnField}
            onClick={() => this.setState({ isInnField: true })}
          >
            + Добавить клиента
          </BtnAddClient>
          {this.state.isInnField && (
            <>
              <Input
                name="clientInn"
                value={this.state.clientInn}
                onChange={e => this.handleInnChange(e.currentTarget.value)}
                placeholder="Введите ИНН клиента"
              />
              <BtnSubmit
                onClick={this.handleCreateNewClient}
                isValid={
                  this.state.clientInn.length >= 10 &&
                  new RegExp(/^[a-zA-Z0-9]+$/).test(this.state.clientInn)
                }
              >
                Сохранить
              </BtnSubmit>
            </>
          )}
        </HeaderClients>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">ИНН</TableThStyled>
                <TableThStyled width="10%">Наименование</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {clients.items.map((client, index) => (
                <TableRowStyled
                  key={index}
                  onClick={() => this.navigateToCompanies(client.companyINN)}
                >
                  <td>{client.companyINN}</td>
                  <td>{client.companyName}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
        <Pagination list={clients} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ ClientsForAgent }: CRM) => ({
  clients: ClientsForAgent.getClientsForAgent.data,
  status: ClientsForAgent.getClientsForAgent.status,
  error: ClientsForAgent.getClientsForAgent.error,
  statusCreateClient: ClientsForAgent.postCreateClient.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getClients, createClient }, dispatch);

const ClientsForAgentConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientsForAgent)
);

export { ClientsForAgentConnect as ClientsForAgent };
