import { GET_LEAD } from '../reducers/getLead';
import { LeadWrite } from './setLeadFormData';

export const req = (id: string | number) => ({
  type: GET_LEAD.REQ,
  id
});

export const got = (lead: LeadWrite) => ({
  type: GET_LEAD.GOT,
  lead
});

export const err = (error: {}) => ({
  type: GET_LEAD.ERR,
  error
});
