import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import {
  ApplicationTypes,
  GuaranteesSubTypeId,
  GuaranteesType
} from 'shared/constants';

import { FactoringApplicationRead } from 'Application/types';

import {
  req as getInBankApplication,
  reset as resetInBankApplication
} from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import { req as getApplicationBank } from 'src/entities/Cabinet/Bank/model/actions/getApplicationBank';
import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { reset as resetFinancialsSummary } from 'Application/actions/getFinancialsSummary';

import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';

import { EachApplicationView } from 'src/pages/Cabinet/Bank/Pages/ApplicationView/FactoringView/EachApplicationView';

import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  bankApplication: Partial<FactoringApplicationRead>;
  status: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getApplicationBank: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  resetInBankApplication: () => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FactoringView: React.FC<Props> = ({
  getApplicationBank,
  bankApplication,
  status,
  getInBankApplication,
  inBankApplication,
  getProductApplication,
  permissions,
  resetFinancialsSummary,
  resetInBankApplication,
  resetSelectApplicationState,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusPostSendBank,
  statusSendApi,
  statusChangeApplicationStatus,
  match
}) => {
  React.useEffect(
    () => {
      const { id } = match.params;

      if (id) {
        getApplicationBank(id);
        getInBankApplication({ id: +id });
      }

      return () => {
        resetInBankApplication();
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id } = match.params;

        if (id) {
          getApplicationBank(id);
          getInBankApplication({ id: +id });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id } = match.params;

        if (id) {
          getApplicationBank(id);
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  const setSubInfo = () => {
    //     if (bankApplication.guaranteesType === GUARANTEES_TYPE.TENDER) {
    //       switch (bankApplication.tender) {
    //         case TENDER_TYPE.OTHER:
    //           return [
    //             {
    //               name: 'Комментарий',
    //               value: (
    //                 <TenderComment>{bankApplication.tenderComment}</TenderComment>
    //               )
    //             }
    //           ];
    //         default:
    //           return [];
    //       }
    //     }
    //     return [];
  };

  const formGuaranteesString = () => {
    let str1 = '';
    let str2 = '';
    let str3 = '';

    if (bankApplication.tender) {
      str1 += 'Тендерная';
    } else if (bankApplication.commercial) {
      str1 += 'Коммерческая';
    } else if (bankApplication.tax) {
      str1 += 'Налоговая';
    } else {
      str1 += 'Другая';
    }

    if (
      !!bankApplication.guaranteesType &&
      GuaranteesType[bankApplication.guaranteesType] !==
        GuaranteesType['COMMERCIAL'] &&
      GuaranteesType[bankApplication.guaranteesType] !== GuaranteesType['OTHER']
    ) {
      str2 += ` / ${GuaranteesType[bankApplication.guaranteesType]}`;
    } else {
      str2 += '';
    }

    str3 += bankApplication.guaranteesSubTypeId
      ? ` / ${GuaranteesSubTypeId[bankApplication.guaranteesSubTypeId]}`
      : '';

    return str1 + str2 + str3;
  };

  if (status !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[bankApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(bankApplication.amount)
        },
        {
          name: 'Срок', // TODO REFACTOR
          value: bankApplication.term
            ? formTermString(bankApplication.term)
            : bankApplication.termStartDate && bankApplication.termEndDate
              ? `${bankApplication.termStartDate.replace(
                  /\//g,
                  '.'
                )} - ${bankApplication.termEndDate.replace(/\//g, '.')}`
              : undefined
        },
        {
          name: 'ИНН',
          value: bankApplication.code
        },
        {
          name: 'Рефинансирование',
          value: bankApplication.refinancing ? 'Да' : 'Нет'
        }
      ]}
      subInfo={[
        {
          name: 'Тип гарантии',
          value: formGuaranteesString()
        },
        // {
        //   name: 'Комментарии по типу гарантий',
        //   value: bankApplication.typeComment
        // },
        {
          name: 'Ссылка на закупку',
          value: bankApplication.purchaseLink
        }
        // ...(!!purchaseLink
        //   ? [
        //       {
        //         name: 'Ссылка на тендер',
        //         value: (
        //           <TenderLink href={internalApplication.purchaseLink}>
        //             {internalApplication.purchaseLink}
        //           </TenderLink>
        //         )
        //       }
        //     ]
        //   : []),
        // ...setSubInfo()
      ]}
      documents={{
        general: [
          ...(inBankApplication.agreementDocuments || []),
          ...(inBankApplication.contractDocuments || []),
          ...(inBankApplication.groupDocuments || [])
        ],
        company: inBankApplication.borrowerCompanies || []
      }}
    />
  );
};

const mapStateToProps = ({ Application, BankRole, User }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data,
  status: BankRole.getApplicationBank.status,
  inBankApplication: Application.getInBankApplication.data,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getApplicationBank,
      getInBankApplication,
      resetInBankApplication,
      getProductApplication,
      resetSelectApplicationState,
      resetFinancialsSummary
    },
    dispatch
  );

const FactoringViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringView)
);

export { FactoringViewConnect as FactoringView };
