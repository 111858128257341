import { css } from 'styled-components';

/* Style helpers */

export const borderDashAnimate = (
  color_1: string = 'rgba(0, 0, 0, 1)',
  color_2: string = 'rgba(255, 255, 255, 1)',
  strokeWidth: number = 2,
  gradientTransform: number = 45
) => `data:image/svg+xml,
<svg xmlns="http://www.w3.org/2000/svg">
  <style>
    @keyframes marching-ants {
      to { 
        stroke-dashoffset: -100px; 
      }
    }
  </style>

  <linearGradient id="g" gradientTransform="rotate(${gradientTransform})">
    <stop stop-color="${color_1}" />
    <stop stop-color="${color_2}" offset="1" />
  </linearGradient>
  
  <rect width="100%" height="100%" 
    stroke="url(%23g)" 
    stroke-width="${strokeWidth}" 
    stroke-dasharray="10 10" 
    fill="none"
    stroke-linecap="round"
    style="animation: marching-ants 1s linear infinite;" 
  />
</svg>`;

export const customUnderline = (color: string = red) => css`
  padding-bottom: 5px;
  background-repeat: repeat-x;
  background-position: 0 100%;
  background-size: auto 5px;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 20">
      <polyline points="0,4 14,16 28,4" fill="none" stroke="${color}" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  ');
`;
