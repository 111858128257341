import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faPenSquare,
  faUnlockAlt,
  faUnlock,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  req as getPeriodList,
  reset as resetPeriodList,
  ResponseType as PeriodListData
} from 'src/features/FinancialForCompany/actions/getExternalAgentPeriodList';
import {
  req as postExternalAgentFinancialStatementsAll,
  RequestType as PostExternalAgentFinancialStatementsAllData,
  reset as resetPostExternalAgentFinancialStatementsAll
} from 'src/features/FinancialForCompany/actions/postExternalAgentFinancialStatementsAll';
import {
  FINANCILASTATEMENTS_TABS,
  PeriodListType,
  OPIY,
  financialStatementActiveLabels,
  financialStatementPassiveLabels,
  FinancialStatementsAllType,
  Colors
} from '../../types';
import {
  req as getSelectPeriodList,
  reset as resetSelectPeriodList,
  SelectPeriodRequest
} from 'src/features/FinancialForCompany/actions/getExternalAgentSelectPeriod';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import {
  calculateValue,
  filtredUtil,
  getTaxPeriodCode,
  getColorExternalAgent,
  sortPeriod,
  generateUniqueId
} from '../../utils';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import {
  BalanceContainer,
  Container,
  Input,
  SubmitButtonWrapper,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../styled';
import { AcсessTypeHint, Cell } from './styled';
import { HintModal } from '../HintModal/HintModa';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import FinancialForCompanyApi from '../../api';
import { Menu } from 'src/features/Layouts/components';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  periodList: PeriodListData;
  statusPeriodList: REQUEST_STATUSES;
  errorPeriodList: ResponseError;
  statusPostExternalAgentFinancialStatementsAll: REQUEST_STATUSES;
  errorPostExternalAgentFinancialStatementsAll: ResponseError;
  selectPeriodList: FinancialStatementsAllType[];
  statusSelectPeriodList: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPeriodList: (inn: string) => void;
  resetPeriodList: () => void;
  getSelectPeriodList: (inn: string, data: SelectPeriodRequest[]) => void;
  resetSelectPeriodList: () => void;
  openModal: (data: OpenModalDataType) => void;
  postExternalAgentFinancialStatementsAll: (
    data: PostExternalAgentFinancialStatementsAllData
  ) => void;
  resetPostExternalAgentFinancialStatementsAll: (error?) => void;
}

interface MatchParams {
  inn: string;
}

type FiltredData = {
  year: number;
  taxPeriodCode: number;
  active: {};
  passive: {};
  OPIY: {};
};

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ExternalAgentFinancialForCompany: React.FC<Props> = ({
  getPeriodList,
  periodList,
  statusPeriodList,
  getSelectPeriodList,
  resetSelectPeriodList,
  selectPeriodList,
  statusSelectPeriodList,
  openModal,
  postExternalAgentFinancialStatementsAll,
  resetPostExternalAgentFinancialStatementsAll,
  statusPostExternalAgentFinancialStatementsAll,
  errorPostExternalAgentFinancialStatementsAll,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<string>('DEFAULT');
  const [
    filtredFinancialStatementsAll,
    setFiltredFinancialStatementsAll
  ] = React.useState<FiltredData[]>([]);
  const [latestData, setLatestData] = React.useState<FiltredData[]>([]);
  const [years, setYears] = React.useState([]);
  const [selectedCells, setSelectedCells] = React.useState<
    Array<{
      year: number;
      taxPeriodCode: number;
      color: Colors;
    }>[]
  >([]);
  const [periods, setPeriods] = React.useState<Array<PeriodListType>[]>([]);
  const [acсessTypeHint, setAcсessTypeHint] = React.useState<{
    index: string;
    hint: string;
  }>({
    index: null,
    hint: null
  });
  const [inputRowValue, setInputRowValue] = React.useState({});
  const [errorFields, setErrorFields] = React.useState([]);

  React.useEffect(
    () => {
      if (match.params.inn) {
        getPeriodList(match.params.inn);
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (
        statusPostExternalAgentFinancialStatementsAll === REQUEST_STATUSES.GOT
      ) {
        setErrorFields([]);
        setLatestData([]);

        for (const key in inputRowValue) {
          const [year, taxPeriodCode] = key.split('-').slice(2);
          const yearNumber = parseInt(year);
          const taxPeriodNumber = parseInt(taxPeriodCode);

          setSelectedCells(prevSelectedCells =>
            prevSelectedCells.map(row =>
              row.map(
                cellItem =>
                  cellItem.year === yearNumber &&
                  cellItem.taxPeriodCode === taxPeriodNumber
                    ? { ...cellItem, color: '#0085FE' }
                    : cellItem
              )
            )
          );
        }
      }
    },
    [statusPostExternalAgentFinancialStatementsAll]
  );

  const determineYearAndType = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    if (
      currentMonth === 1 ||
      currentMonth === 2 ||
      (currentMonth === 3 && currentDay <= 31)
    ) {
      return { year: currentYear - 1, type: 1 };
    } else if (currentMonth === 4 && currentDay <= 30) {
      return { year: currentYear - 1, type: 2 };
    } else if (
      (currentMonth === 5 && currentDay <= 31) ||
      currentMonth === 6 ||
      (currentMonth === 7 && currentDay <= 31)
    ) {
      return { year: currentYear, type: 3 };
    } else if (
      (currentMonth === 8 && currentDay <= 31) ||
      currentMonth === 9 ||
      (currentMonth === 10 && currentDay <= 31)
    ) {
      return { year: currentYear, type: 4 };
    } else if (
      (currentMonth === 11 && currentDay <= 30) ||
      currentMonth === 12
    ) {
      return { year: currentYear, type: 5 };
    }
  };

  const getOutlineClass = (type: number, offset: number, index: number) => {
    if (offset === 0) {
      if (
        (type === 1 && index === 2) ||
        (type === 2 && index === 3) ||
        (type === 3 && index === 0) ||
        (type === 4 && index === 1) ||
        (type === 5 && index === 2)
      ) {
        return 'red';
      }
    } else if (offset === 1) {
      if (
        (type === 1 && (index === 2 || index === 3)) ||
        (type === 2 && index === 3) ||
        (type === 3 && (index === 0 || index === 3)) ||
        (type === 4 && (index === 1 || index === 3)) ||
        (type === 5 && (index === 2 || index === 3))
      ) {
        return 'red';
      }
    }
    return '';
  };

  React.useEffect(
    () => {
      if (statusPeriodList === REQUEST_STATUSES.GOT) {
        const { year: startYear, type } = determineYearAndType();
        const taxPeriodCodes = [21, 31, 33, 34];
        const periods = Array.from({ length: 4 }, () => []);

        for (let j = 0; j < 4; j++) {
          const yearOffset = startYear - j;

          for (let i = 0; i < 4; i++) {
            const taxPeriodCode = taxPeriodCodes[i];
            const foundItem = periodList.items.find(
              item =>
                item.taxPeriodCode === taxPeriodCode && item.year === yearOffset
            ) || {
              id: generateUniqueId(),
              isEdit: true,
              year: yearOffset,
              taxPeriodCode: taxPeriodCode,
              acсessType: 'Open',
              type
            };

            periods[j].push({ ...foundItem, type });
          }
        }

        if (type === 3) {
          periods[0] = periods[0].slice(0, 1);
        } else if (type === 4) {
          periods[0] = periods[0].slice(0, 2);
        } else if (type === 5) {
          periods[0] = periods[0].slice(0, 3);
        }

        setPeriods(periods);

        const filteredData = periodList.items
          .filter(
            item =>
              item.acсessType === 'Open' || item.acсessType === 'Accessible'
          )
          .map(item => ({
            TaxPeriod: item.taxPeriodCode,
            taxYear: item.year
          }));

        getSelectPeriodList(match.params.inn, filteredData);

        setSelectedCells(
          periods.map(arr =>
            arr.map(item => ({
              year: item.year,
              taxPeriodCode: item.taxPeriodCode,
              color: getColorExternalAgent(item)
            }))
          )
        );
      }
    },
    [statusPeriodList]
  );

  React.useEffect(
    () => {
      if (statusSelectPeriodList === REQUEST_STATUSES.GOT) {
        const filteredData = filtredUtil({ items: selectPeriodList });
        const sortedData = sortPeriod(filteredData).filter(
          item => item.taxPeriodCode === 34
        );
        setFiltredFinancialStatementsAll(sortedData);
      }
      return () => resetSelectPeriodList();
    },
    [selectPeriodList, statusSelectPeriodList]
  );

  React.useEffect(
    () => {
      setYears(
        filtredFinancialStatementsAll.map(item => ({
          year: item.year,
          taxPeriodCode: item.taxPeriodCode
        }))
      );
    },
    [filtredFinancialStatementsAll]
  );

  const currentYear = new Date().getFullYear();

  const selectPeriod = (
    item: PeriodListType,
    offset: number,
    index: number
  ) => {
    const currentColor = selectedCells[offset][index].color;

    if (currentColor === '#0085FE') {
      const filteredData = filtredFinancialStatementsAll.filter(
        fin =>
          fin.year !== item.year || fin.taxPeriodCode !== item.taxPeriodCode
      );
      const sortedData = sortPeriod(filteredData);
      setFiltredFinancialStatementsAll(sortedData);
    }

    if (item.isEdit) {
      const updatedState = [...periods];

      updatedState.forEach(innerArray => {
        innerArray.forEach(cell => {
          if (
            cell.year === item.year &&
            cell.taxPeriodCode === item.taxPeriodCode &&
            cell.hasOwnProperty('isEdit')
          ) {
            item.isEdit = false;
          }
        });
      });

      setPeriods(updatedState);

      const data2 = {
        year: item.year,
        taxPeriodCode: item.taxPeriodCode,
        active: {},
        passive: {},
        OPIY: {}
      };

      const sortedData = sortPeriod([...filtredFinancialStatementsAll, data2]);
      setLatestData(prevLatestData => [...prevLatestData, data2]);
      setFiltredFinancialStatementsAll(sortedData);
      setActiveTab('EMPTY_FIELDS');
    }

    if (
      (item.acсessType === 'Open' || item.acсessType === 'Accessible') &&
      currentColor === '#1CCA94'
    ) {
      const foundItem = selectPeriodList.find(
        period =>
          period.year === item.year &&
          period.taxPeriodCode === item.taxPeriodCode
      );

      if (foundItem) {
        const filteredData = filtredUtil({ items: [foundItem] });
        const sortedData = sortPeriod([
          ...filtredFinancialStatementsAll,
          ...filteredData
        ]);
        setFiltredFinancialStatementsAll(sortedData);
      } else {
        FinancialForCompanyApi.getExternalAgentSelectPeriod(match.params.inn, {
          TaxPeriod: item.taxPeriodCode,
          taxYear: item.year
        }).then((res: FinancialStatementsAllType) => {
          const filteredData = filtredUtil({ items: [res] });
          const sortedData = sortPeriod([
            ...filtredFinancialStatementsAll,
            ...filteredData
          ]);
          setFiltredFinancialStatementsAll(sortedData);
        });
      }
    } else if (item.acсessType === 'Closed') {
      openModal({ name: MODAL_NAMES.HINT_FINANCIAL_COMPANY_MODAL });
    }

    setSelectedCells(prevCellColors => {
      const updatedColors = [...prevCellColors];
      const currentColor = updatedColors[offset][index].color;

      if (currentColor === '#9FA6B2') {
        updatedColors[offset][index].color = '#f0cd74';
      } else if (
        (item.acсessType === 'Open' || item.acсessType === 'Accessible') &&
        currentColor === '#1CCA94'
      ) {
        updatedColors[offset][index].color = '#0085FE';
      } else if (
        (item.acсessType === 'Open' || item.acсessType === 'Accessible') &&
        currentColor === '#0085FE'
      ) {
        updatedColors[offset][index].color = '#1CCA94';
      }

      return updatedColors;
    });
  };

  const showHint = (
    index: string,
    accessType: PeriodListType['acсessType'],
    isEdit: boolean
  ) => {
    if (accessType === 'Accessible') {
      setAcсessTypeHint({ index, hint: 'Закрытые данные доступны вам' });
    } else if (accessType === 'Closed') {
      setAcсessTypeHint({
        index,
        hint: 'Данные уже есть на платформе, но закрыты для вас'
      });
    } else if (isEdit) {
      setAcсessTypeHint({ index, hint: 'На платформе нет этой отчетности' });
    } else if (accessType === 'Open') {
      setAcсessTypeHint({ index, hint: 'Открытые данные, общедоступные' });
    }
  };

  const handleActiveTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const handlePostFinancialStatementsAll = () => {
    const groupedData = {};

    for (const key in inputRowValue) {
      if (inputRowValue.hasOwnProperty(key)) {
        const [row, year, taxPeriod] = key.split('-').slice(1);
        const value = inputRowValue[key];

        const groupKey = `${year}-${taxPeriod}`;

        if (!groupedData[groupKey]) {
          groupedData[groupKey] = {
            year: parseInt(year),
            taxPeriodCode: parseInt(taxPeriod),
            rows: {}
          };
        }

        if (!!value) {
          groupedData[groupKey].rows[`row${row}`] = value;
        }
      }
    }

    for (const key in groupedData) {
      const item = groupedData[key];
      if (!item.rows) {
        item.rows = {};
      }

      const calculatedRows = calculateValue(item.rows);

      for (const calculatedKey in calculatedRows) {
        if (!item.rows[calculatedKey]) {
          item.rows[calculatedKey] = calculatedRows[calculatedKey];
        }
      }
    }

    for (const groupKey in groupedData) {
      if (groupedData.hasOwnProperty(groupKey)) {
        const group = groupedData[groupKey];

        postExternalAgentFinancialStatementsAll({
          companyInn: match.params.inn,
          year: group.year,
          taxperiodcode: group.taxPeriodCode,
          ...group.rows
        });
      }
    }
  };

  const addNumbers = (start, end, step): number[] => {
    const numbers = [];
    for (let i = start; i <= end; i += step) {
      numbers.push(i);
    }
    return numbers;
  };

  React.useEffect(
    () => {
      if (
        statusPostExternalAgentFinancialStatementsAll === REQUEST_STATUSES.ERROR
      ) {
        openModal({ name: MODAL_NAMES.ERROR_FINANCIAL_COMPANY_MODAL });
        resetPostExternalAgentFinancialStatementsAll();

        const updatedArray: number[] = [];

        for (
          var i = 0;
          i < errorPostExternalAgentFinancialStatementsAll.body.length;
          i++
        ) {
          if (errorPostExternalAgentFinancialStatementsAll.body[i] === false) {
            if (i === 0) {
              updatedArray.push(...addNumbers(1100, 1190, 10));
            } else if (i === 1) {
              updatedArray.push(...addNumbers(1200, 1260, 10));
            } else if (i === 2) {
              updatedArray.push(...addNumbers(1300, 1370, 10));
            } else if (i === 3) {
              updatedArray.push(...addNumbers(1400, 1450, 10));
            } else if (i === 4) {
              updatedArray.push(...addNumbers(1500, 1550, 10));
            } else if (i === 5) {
              updatedArray.push(1100, 1200, 1600);
            } else if (i === 6) {
              updatedArray.push(1600, 1700);
            }
          }
        }

        setErrorFields(updatedArray);
      }
    },
    [handlePostFinancialStatementsAll]
  );

  const calculateSum = (
    inputValue,
    item: FiltredData,
    name: string,
    value: number
  ) => {
    const sums = {
      sum1: 0,
      sum2: 0,
      sum3: 0,
      sum4: 0,
      sum5: 0
    };

    for (const key in inputValue) {
      const splited = key.split('-');
      const taxPeriodCode = +splited[splited.length - 1];
      const year = +splited[splited.length - 2];
      const row = +splited[splited.length - 3];
      const codeRow = name.split('-')[1];

      if (item.year === year && item.taxPeriodCode === taxPeriodCode) {
        const sumsEdit = {
          sum1: inputValue[`input-1100-${name.slice(11)}`],
          sum2: inputValue[`input-1200-${name.slice(11)}`],
          sum3: inputValue[`input-1300-${name.slice(11)}`],
          sum4: inputValue[`input-1400-${name.slice(11)}`],
          sum5: inputValue[`input-1500-${name.slice(11)}`]
        };

        if (+codeRow === 1100) {
          sumsEdit.sum1 = value;
          return sumsEdit;
        }
        if (+codeRow === 1200) {
          sumsEdit.sum2 = value;
          return sumsEdit;
        }
        if (+codeRow === 1300) {
          sumsEdit.sum3 = value;
          return sumsEdit;
        }
        if (+codeRow === 1400) {
          sumsEdit.sum4 = value;
          return sumsEdit;
        }
        if (+codeRow === 1500) {
          sumsEdit.sum5 = value;
          return sumsEdit;
        }

        if (row >= 1110 && row <= 1190) {
          sums.sum1 += inputValue[key];
        } else if (row >= 1210 && row <= 1260) {
          sums.sum2 += inputValue[key];
        } else if (row >= 1310 && row <= 1370) {
          sums.sum3 += inputValue[key];
        } else if (row >= 1410 && row <= 1450) {
          sums.sum4 += inputValue[key];
        } else if (row >= 1510 && row <= 1550) {
          sums.sum5 += inputValue[key];
        }
      }
    }

    return sums;
  };

  const handleInputChange = (
    event: React.FormEvent<HTMLInputElement>,
    item: FiltredData
  ) => {
    const { name, value } = event.currentTarget;

    if (/^[0-9]*$/.test(value)) {
      const updatedInputRowValue = { ...inputRowValue, [name]: +value };

      if (item) {
        const sums = calculateSum(updatedInputRowValue, item, name, +value);

        let total1 = sums.sum1 + sums.sum2;
        let total2 = sums.sum3 + sums.sum4 + sums.sum5;

        updatedInputRowValue[`input-1100-${name.slice(11)}`] = sums.sum1;
        updatedInputRowValue[`input-1200-${name.slice(11)}`] = sums.sum2;
        updatedInputRowValue[`input-1300-${name.slice(11)}`] = sums.sum3;
        updatedInputRowValue[`input-1400-${name.slice(11)}`] = sums.sum4;
        updatedInputRowValue[`input-1500-${name.slice(11)}`] = sums.sum5;
        updatedInputRowValue[`input-1600-${name.slice(11)}`] = total1;
        updatedInputRowValue[`input-1700-${name.slice(11)}`] = total2;

        setInputRowValue(updatedInputRowValue);
      } else {
        setInputRowValue(updatedInputRowValue);
      }
    }
  };

  const activeRows = {};
  const passiveRows = {};
  const OPIYRows = {};

  filtredFinancialStatementsAll.forEach(item => {
    if (item.active) {
      Object.keys(item.active).forEach(rowKey => {
        activeRows[rowKey] = true;
      });
    }

    if (item.passive) {
      Object.keys(item.passive).forEach(rowKey => {
        passiveRows[rowKey] = true;
      });
    }

    if (item.OPIY) {
      Object.keys(item.OPIY).forEach(rowKey => {
        OPIYRows[rowKey] = true;
      });
    }
  });

  const emptyFieldActive = financialStatementActiveLabels.filter(
    item => item.required || activeRows[`row${item.code}`]
  );

  const emptyFieldPassive = financialStatementPassiveLabels.filter(
    item => item.required || passiveRows[`row${item.code}`]
  );

  const emptyFieldOPIY = OPIY.filter(item => OPIYRows[`row${item.code}`]);

  return (
    <ApplicationViewStyled>
      <ApplicationTitle>Финансы компании</ApplicationTitle>

      <ApplicationId>Доступные периоды:</ApplicationId>
      <Container>
        {periods.map((arr, offset) => (
          <div key={offset}>
            <p>{currentYear - offset}</p>
            {arr.map((item, index) => {
              const cellColor =
                !!selectedCells.length && selectedCells[offset][index].color;
              const cellOutline =
                (cellColor === '#9FA6B2' || cellColor === '#f0cd74') &&
                getOutlineClass(item.type, offset, index);

              return (
                <Cell
                  key={index}
                  outline={cellOutline}
                  bgColor={cellColor}
                  onClick={() => selectPeriod(item, offset, index)}
                  onMouseEnter={() =>
                    showHint(`${offset}-${index}`, item.acсessType, item.isEdit)
                  }
                  onMouseLeave={() => setAcсessTypeHint(null)}
                >
                  {index + 1} кв
                  {item.acсessType === 'Accessible' ? (
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  ) : item.acсessType === 'Open' ? (
                    <FontAwesomeIcon icon={faUnlock} />
                  ) : item.acсessType === 'Closed' ? (
                    <FontAwesomeIcon icon={faLock} />
                  ) : cellColor === '#9FA6B2' ? (
                    <FontAwesomeIcon icon={faPencilAlt} />
                  ) : (
                    <FontAwesomeIcon icon={faPenSquare} />
                  )}
                  {acсessTypeHint !== null &&
                    acсessTypeHint.index === `${offset}-${index}` && (
                      <AcсessTypeHint>
                        <p>{acсessTypeHint.hint}</p>
                      </AcсessTypeHint>
                    )}
                </Cell>
              );
            })}
          </div>
        ))}
      </Container>

      <CompanySectionTitle>Баланс</CompanySectionTitle>

      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={FINANCILASTATEMENTS_TABS}
        isSubMenu
      />

      {activeTab === 'EMPTY_FIELDS' ? (
        <BalanceContainer>
          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {financialStatementActiveLabels.map(({ name, subName, code }) => (
                <TableRow key={code}>
                  <TableCell is1600={code === 1600}>
                    {!!subName ? (
                      <div>
                        <h3>{name}</h3>
                        <p>{subName}</p>
                      </div>
                    ) : (
                      name
                    )}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => {
                    const isNewData = latestData.some(
                      dataItem =>
                        dataItem.year === item.year &&
                        dataItem.taxPeriodCode === item.taxPeriodCode
                    );
                    const inputKey = `${code}-${item.year}-${
                      item.taxPeriodCode
                    }`;

                    return (
                      <TableCell
                        key={inputKey}
                        is1100={code === 1100}
                        is1200={code === 1200}
                        is1600={code === 1600}
                      >
                        {!isNewData ? (
                          inputRowValue[`input-${inputKey}`] ? (
                            inputRowValue[`input-${inputKey}`] ? (
                              inputRowValue[`input-${inputKey}`]
                            ) : null
                          ) : item.active[`row${code}`] ? (
                            formSumStringThousands(item.active[`row${code}`])
                          ) : null
                        ) : (
                          <Input
                            error={errorFields.includes(code)}
                            name={`input-${inputKey}`}
                            value={inputRowValue[`input-${inputKey}`] || ''}
                            onChange={e => handleInputChange(e, item)}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {financialStatementPassiveLabels.map(
                ({ name, subName, code }) => (
                  <TableRow key={code}>
                    <TableCell is1700={code === 1700}>
                      {!!subName ? (
                        <div>
                          <h3>{name}</h3>
                          <p>{subName}</p>
                        </div>
                      ) : (
                        name
                      )}
                    </TableCell>
                    <TableCell>{code}</TableCell>
                    {filtredFinancialStatementsAll.map(item => {
                      const isNewData = latestData.some(
                        dataItem =>
                          dataItem.year === item.year &&
                          dataItem.taxPeriodCode === item.taxPeriodCode
                      );
                      const inputKey = `${code}-${item.year}-${
                        item.taxPeriodCode
                      }`;

                      return (
                        <TableCell
                          key={inputKey}
                          is1300={code === 1300}
                          is1400={code === 1400}
                          is1500={code === 1500}
                          is1700={code === 1700}
                        >
                          {!isNewData ? (
                            inputRowValue[`input-${inputKey}`] ? (
                              inputRowValue[`input-${inputKey}`] ? (
                                inputRowValue[`input-${inputKey}`]
                              ) : null
                            ) : item.passive[`row${code}`] ? (
                              formSumStringThousands(item.passive[`row${code}`])
                            ) : null
                          ) : (
                            <Input
                              error={errorFields.includes(code)}
                              name={`input-${inputKey}`}
                              value={inputRowValue[`input-${inputKey}`] || ''}
                              onChange={e => handleInputChange(e, item)}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
              )}
            </TableBody>
          </TableContainer>

          <section>
            <CompanySectionTitle>ОПиУ</CompanySectionTitle>
            <TableContainer>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Наименование показателя</TableHeaderCell>
                  <TableHeaderCell>Код</TableHeaderCell>
                  {!!years &&
                    years.map((item, index) => (
                      <TableHeaderCell key={index}>
                        {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                      </TableHeaderCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {OPIY.map(({ name, code }) => (
                  <TableRow key={code}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    {filtredFinancialStatementsAll.map(item => {
                      const isNewData = latestData.some(
                        dataItem =>
                          dataItem.year === item.year &&
                          dataItem.taxPeriodCode === item.taxPeriodCode
                      );
                      const inputKey = `${code}-${item.year}-${
                        item.taxPeriodCode
                      }`;

                      return (
                        <TableCell key={inputKey}>
                          {!isNewData ? (
                            item.OPIY[`row${code}`] ? (
                              formSumStringThousands(item.OPIY[`row${code}`])
                            ) : null
                          ) : (
                            <Input
                              name={`input-${inputKey}`}
                              value={inputRowValue[`input-${inputKey}`] || ''}
                              onChange={handleInputChange}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
            {!Object.values(inputRowValue).every(value => value === 0) && (
              <SubmitButtonWrapper>
                <Button
                  label="Сохранить"
                  onClick={handlePostFinancialStatementsAll}
                />
              </SubmitButtonWrapper>
            )}
          </section>

          <ErrorModal
            code={errorPostExternalAgentFinancialStatementsAll.code}
            message={errorPostExternalAgentFinancialStatementsAll.message}
          />
        </BalanceContainer>
      ) : (
        <BalanceContainer>
          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {emptyFieldActive.map(({ name, subName, code }, index) => (
                <TableRow key={index}>
                  <TableCell is1600={code === 1600}>
                    {!!subName ? (
                      <div>
                        <h3>{name}</h3>
                        <p>{subName}</p>
                      </div>
                    ) : (
                      name
                    )}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => (
                    <TableCell
                      key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      is1100={code === 1100}
                      is1200={code === 1200}
                      is1600={code === 1600}
                    >
                      {item.active[`row${code}`]
                        ? formSumStringThousands(item.active[`row${code}`])
                        : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <TableContainer>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Наименование показателя</TableHeaderCell>
                <TableHeaderCell>Код</TableHeaderCell>
                {!!years &&
                  years.map((item, index) => (
                    <TableHeaderCell key={index}>
                      {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                    </TableHeaderCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {emptyFieldPassive.map(({ name, subName, code }, index) => (
                <TableRow key={index}>
                  <TableCell is1700={code === 1700}>
                    {!!subName ? (
                      <div>
                        <h3>{name}</h3>
                        <p>{subName}</p>
                      </div>
                    ) : (
                      name
                    )}
                  </TableCell>
                  <TableCell>{code}</TableCell>
                  {filtredFinancialStatementsAll.map(item => (
                    <TableCell
                      key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      is1300={code === 1300}
                      is1400={code === 1400}
                      is1500={code === 1500}
                      is1700={code === 1700}
                    >
                      {item.passive[`row${code}`]
                        ? formSumStringThousands(item.passive[`row${code}`])
                        : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <section>
            <CompanySectionTitle>ОПиУ</CompanySectionTitle>
            <TableContainer>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Наименование показателя</TableHeaderCell>
                  <TableHeaderCell>Код</TableHeaderCell>
                  {!!years &&
                    years.map((item, index) => (
                      <TableHeaderCell key={index}>
                        {item.year}, {getTaxPeriodCode(item.taxPeriodCode)} кв
                      </TableHeaderCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {emptyFieldOPIY.map(({ name, code }) => (
                  <TableRow key={code}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    {filtredFinancialStatementsAll.map(item => (
                      <TableCell
                        key={`${code}-${item.year}-${item.taxPeriodCode}`}
                      >
                        {item.OPIY[`row${code}`]
                          ? formSumStringThousands(item.OPIY[`row${code}`])
                          : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </section>
        </BalanceContainer>
      )}

      <HintModal message="Данные уже есть на платформе, но закрыты для вас" />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ FinancialStatements }: CRM) => ({
  periodList: FinancialStatements.getExternalAgentPeriodList.data,
  statusPeriodList: FinancialStatements.getExternalAgentPeriodList.status,
  errorPeriodList: FinancialStatements.getExternalAgentPeriodList.error,
  selectPeriodList: FinancialStatements.getExternalAgentSelectPeriod.data,
  statusSelectPeriodList:
    FinancialStatements.getExternalAgentSelectPeriod.status,
  statusPostExternalAgentFinancialStatementsAll:
    FinancialStatements.postExternalAgentFinancialStatementsAll.status,
  errorPostExternalAgentFinancialStatementsAll:
    FinancialStatements.postExternalAgentFinancialStatementsAll.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPeriodList,
      resetPeriodList,
      getSelectPeriodList,
      resetSelectPeriodList,
      openModal,
      postExternalAgentFinancialStatementsAll,
      resetPostExternalAgentFinancialStatementsAll
    },
    dispatch
  );

const ExternalAgentFinancialForCompanyConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ExternalAgentFinancialForCompany)
);

export {
  ExternalAgentFinancialForCompanyConnect as ExternalAgentFinancialForCompany
};
