import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  BorrowerCompany,
  DOCUMENT_UPLOAD_TYPE
} from 'src/features/Application/types';
import {
  FileRow,
  FileRowLabelContainer,
  FileRowPaddingBlock
} from '../ContractFinancingFileUploadBlocks/styles';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import ExampleDocument from '../ContractFinancingFileUploadBlocks/ExampleDocument/ExampleDocument';
import { BigLabelWithTopMargin } from 'src/shared/ui/InputSingleFile/styles';
import {
  RequestDataType as DeleteDocumentRequestDataType,
  SagaRequestDataType as DeleteDocumentSagaRequestDataType
} from 'Application/reducers/deleteDocument';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import { Document } from 'Application/types';
import { InputFile } from 'shared/ui/InputFile';

interface FileInput {
  filename?: string;
  metaInfo?: string;
  label?: string;
  required?: boolean;
  padded?: boolean;
  multifile?: boolean;
}

interface ExampleDocumentProps {
  name: string;
  link: string;
}

// TODO rename
interface Block {
  label?: string;
  subLabel?: string;
  required?: boolean;
  fileInputs?: FileInput[];
  exampleDocument?: ExampleDocumentProps;
}

interface OwnProps {
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  // TODO rename
  blocks: Block[];
}

interface StateToProps {
  documents: Document[];
  documentsUploadStatus: REQUEST_STATUSES;
  id: number;
}

interface DispatchToProps {
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

// TODO move elsewhere
class FactoringFileUploadRow extends React.Component<Props> {
  onFileUpload = file => {
    const { uploadDocuments, id } = this.props;

    uploadDocuments({
      id,
      files: [file],
      type: DOCUMENT_UPLOAD_TYPE.FACTORING
    });
  };

  onFilesUpload = files => {
    const { uploadDocuments, id } = this.props;

    uploadDocuments({
      id,
      files,
      type: DOCUMENT_UPLOAD_TYPE.FACTORING
    });
  };

  onFileRemove = (documentData: DeleteDocumentSagaRequestDataType) => {
    const { deleteDocument } = this.props;

    deleteDocument({
      ...documentData,
      subType: DOCUMENT_UPLOAD_TYPE.FACTORING
    });
  };
  addMetaInfo(files: any, metaInfo: string) {
    return files.map(f => {
      f.metaInfo = metaInfo;
      return f;
    });
  }

  render() {
    const { errors, documentsUploadStatus, blocks, documents } = this.props;

    const findErrorByMetaInfo = (metaInfo: string) => {
      return errors.documents &&
        !documents.find(doc => doc.metaInfo === metaInfo)
        ? errors.documents
        : '';
    };

    const findDocumentByMetaInfo = (metaInfo: string) => {
      return documents.find(doc => doc.metaInfo === metaInfo);
    };

    const getLabel = (block: Block) => {
      return (
        <span>
          {block.label + (block.required ? ' *' : '')}
          <small>{block.subLabel}</small>
        </span>
      );
    };

    return blocks.map((block, index) => {
      return (
        block && (
          <FileRow key={`block-${index}`}>
            <FileRowLabelContainer>
              <BigLabelWithTopMargin label={getLabel(block)} />
              {block.exampleDocument && (
                <ExampleDocument
                  name={block.exampleDocument.name}
                  link={block.exampleDocument.link}
                  size={39}
                />
              )}
            </FileRowLabelContainer>
            {block.fileInputs.map((fileInput, fileInputIndex) => {
              return (
                fileInput && (
                  <React.Fragment key={`file-input-${index}-${fileInputIndex}`}>
                    {fileInput.padded && <FileRowPaddingBlock />}
                    {fileInput.metaInfo && fileInput.multifile ? (
                      <InputFile
                        name="file"
                        required={true}
                        error={findErrorByMetaInfo(fileInput.metaInfo)}
                        files={documents.filter(
                          d => d.metaInfo == fileInput.metaInfo
                        )}
                        multiple={true}
                        dropAreaHeight="250px"
                        uploadStatus={documentsUploadStatus}
                        onFilesUpload={files =>
                          this.onFilesUpload(
                            this.addMetaInfo(files, fileInput.metaInfo)
                          )
                        }
                        onFilesRemove={this.onFileRemove}
                      />
                    ) : (
                      <InputSingleFile
                        label={fileInput.label}
                        file={findDocumentByMetaInfo(fileInput.metaInfo)}
                        error={findErrorByMetaInfo(fileInput.metaInfo)}
                        uploadStatus={documentsUploadStatus}
                        dropAreaWidth={
                          block.fileInputs.length > 1 || fileInput.padded
                            ? '250px'
                            : undefined
                        }
                        metaInfo={fileInput.metaInfo}
                        required={fileInput.required}
                        renameFileTo={fileInput.filename}
                        onFileUpload={this.onFileUpload}
                        onFileRemove={this.onFileRemove}
                      />
                    )}
                  </React.Fragment>
                )
              );
            })}
          </FileRow>
        )
      );
    });
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  documentsUploadStatus: Application.uploadDocuments.status,
  id: Application.setInitializationFormData.id,
  documents: Application.setFactoringFormData.documents
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadDocuments,
      deleteDocument
    },
    dispatch
  );

const FactoringFileUploadRowConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringFileUploadRow);

export { FactoringFileUploadRowConnect as FactoringFileUploadRow };
