import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeleteCertificateStoreState extends Reducer {
    data: RequestDataType;
}

export interface RequestDataType {
    id: number;
}

export enum DELETE_CERTIFICATE {
    REQ = 'DELETE_CERTIFICATE_REQ',
    GOT = 'DELETE_CERTIFICATE_GOT',
    ERR = 'DELETE_CERTIFICATE_ERR'
}

export const initialState: DeleteCertificateStoreState = {
    status: REQUEST_STATUSES.NONE,
    data: {
        id: -1
    },
    error: {}
};

const deleteCertificate = (
    state: DeleteCertificateStoreState = initialState,
    action: {
        type: DELETE_CERTIFICATE;
        data: RequestDataType;
        error: {};
    }
): DeleteCertificateStoreState => {
    switch (action.type) {
        case DELETE_CERTIFICATE.REQ:
            return {
                ...state,
                status: REQUEST_STATUSES.REQUEST,
                data: action.data
            };
        case DELETE_CERTIFICATE.GOT:
            return {
                ...state,
                status: REQUEST_STATUSES.GOT
            };
        case DELETE_CERTIFICATE.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };
        default:
            return state;
    }
};

export default deleteCertificate;
