import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { TRANSITION_DIRECTIONS } from 'Common';
import { rr, blue, black } from 'shared/styled';

export const TabsStyled = styled.section`
  width: auto;
`;

const TabsHeaderHeight = '48px';
export const TabsHeader = styled.div`
  height: ${TabsHeaderHeight};

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid ${blue};
`;

export const animation = (selected, animationDirection) => {
  if (selected) {
    switch (animationDirection) {
      case TRANSITION_DIRECTIONS.FORWARD:
        return keyframes`
          from { transform: translateX(-101%); }
          to { transform: translateX(0); }
        `;
      case TRANSITION_DIRECTIONS.BACKWARDS:
        return keyframes`
          from { transform: translateX(101%); }
          to { transform: translateX(0%); }
        `;
    }
  } else {
    switch (animationDirection) {
      case TRANSITION_DIRECTIONS.FORWARD:
        return keyframes`
          from { transform: translateX(0); }
          to { transform: translateX(101%); }
        `;
      case TRANSITION_DIRECTIONS.BACKWARDS:
        return keyframes`
          from { transform: translateX(0); }
          to { transform: translateX(-101%); }
        `;
    }
  }
};

export const TabHeaderItem = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;
  cursor: pointer;

  font-family: ${rr};
  font-size: 1.6rem;
  color: ${black};

  &::after {
    content: '';

    width: 100%;
    height: 3px;

    display: block;

    bottom: 0;
    left: 0;
    position: absolute;

    background-color: ${blue};

    animation: ${({ selected, animationDirection }) =>
        animation(selected, animationDirection)}
      200ms ease-out both;

    transition: transform 0.2s;
  }
`;

export const TabBody = styled.div`
  width: 100%;
  height: auto;

  position: relative;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const TabItem = styled.div`
  width: 100%;
  height: auto;

  margin-top: ${TabsHeaderHeight};
  padding: 0 10px;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
