import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import Transition from 'react-transition-group/Transition';

import { STORE } from 'globaltypes';
import { closeModal } from 'Modal/actions/toggleModal';
import { ModalStoreState } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';
import { ModalBlock } from 'Common';

import { fadeClassName } from 'shared/styled';
import { ModalStyled } from './styles';

interface OwnProps {
  children: any;
  name?: MODAL_NAMES;
  className?: string;
}

type StateToProps = ModalStoreState;

interface DispatchToProps {
  closeModal: () => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

const Modal: React.FC<Props> = ({
  name,
  className,
  data,
  isActive,
  closeModal,
  children
}) =>
  ReactDOM.createPortal(
    <Transition
      in={!!name ? name === data.name : isActive}
      timeout={100}
      unmountOnExit
    >
      {animationState => (
        <ModalStyled className={fadeClassName(animationState)}>
          <ModalBlock
            className={!!className ? className : ''}
            closeModal={closeModal}
            animationState={animationState}
          >
            {children}
          </ModalBlock>
        </ModalStyled>
      )}
    </Transition>,
    document.getElementById('modal')
  );

const mapStateToProps = ({ Modal }: STORE) => ({
  isActive: Modal.toggleModal.isActive,
  data: Modal.toggleModal.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeModal }, dispatch);

const ModalConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(Modal);

export { ModalConnect as Modal };
