import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Bank/model/actions/getDocumentList';

export interface GetDocumentListByBankStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DOCUMENT_LIST_BY_BANK {
  REQ = 'GET_DOCUMENT_LIST_BY_BANK_REQ',
  GOT = 'GET_DOCUMENT_LIST_BY_BANK_GOT',
  ERR = 'GET_DOCUMENT_LIST_BY_BANK_ERR',
  RESET = 'GET_DOCUMENT_LIST_BY_BANK_RESET'
}

export const initialState: GetDocumentListByBankStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    items: []
  },
  error: {}
};

const getDocumentListByBank = (
  state: GetDocumentListByBankStoreState = initialState,
  action: {
    type: GET_DOCUMENT_LIST_BY_BANK;
    data: ResponseData;
    error: {};
  }
): GetDocumentListByBankStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_BY_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DOCUMENT_LIST_BY_BANK.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_DOCUMENT_LIST_BY_BANK.ERR:
      return {
        ...initialState,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_DOCUMENT_LIST_BY_BANK.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getDocumentListByBank;
