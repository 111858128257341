import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';

import {
  BuyerInfoPageStyled,
  BuyerInfoArea,
  BuyerInfo,
} from './styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  companyByInn: ResponseDataType;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
}

interface State {
  pageUrl: string;
}

type Props = StateToProps & DispatchToProps;

class BuyerInfoPage extends React.Component<Props, State> {

  constructor(props) {
    super(props)
    {
      this.state = {
        pageUrl: window.location.href.split('/').filter(x => x.length > 0).pop()
      }
      this.handlePageChange = this.handlePageChange.bind(this);
    }
  }

  handlePageChange(newHref: string) {
    newHref = window.location.href.split('/').filter(x => x.length > 0).pop();
    this.setState({ pageUrl: newHref })
  }


  initFetch = () => {
    const { getCompanyByInn } = this.props;
    getCompanyByInn({ inn: this.state.pageUrl });
  }

  componentDidMount() {
    this.initFetch();
  }

  componentWillUnmount(): void {
    const { companyByInn } = this.props;
    companyByInn.companyFullName = '';
    companyByInn.inn = '';
    companyByInn.kpp = '';
    companyByInn.companyAddress = '';
  }

  render() {
    const { status, companyByInn, error } = this.props;
    return (
      <BuyerInfoPageStyled>
        <BuyerInfoArea>
          <p>Покупатель</p>
          <h1>{companyByInn.companyFullName}</h1>
          <BuyerInfo>
            <p>ИНН: {companyByInn.inn ? companyByInn.inn : window.location.href.split('/').filter(x => x.length > 0).pop()}</p>
            <p>ОГРН: {companyByInn.ogrn}</p>
            <p>КПП: {companyByInn.kpp}</p>
            <p>Адрес: {companyByInn.companyAddress}</p>
          </BuyerInfo>
        </BuyerInfoArea>
      </BuyerInfoPageStyled>

    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    getCompanyByInn,
  },
    dispatch);

const BuyerInfoPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(BuyerInfoPage);

export { BuyerInfoPageConnect as BuyerInfoPage };
