import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postNotFinFile';

import { POST_NOT_FIN_FILE } from '../reducers/postNotFinFile';
import DocumentListApi from '../api';

function* postNotFinFile(action: {
  type: POST_NOT_FIN_FILE;
  data: FormData;
  token: string;
}): Generator {
  try {
    yield call(DocumentListApi.postNotFinFile, action.token, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postNotFinFileSaga(): Generator {
  yield takeLatest(POST_NOT_FIN_FILE.REQ, postNotFinFile);
}
