import { ProductRead } from 'src/features/Products/actions/setProductFormData';

export enum CABINET_PRODUCT_TABS {
  DEFAULT = 'Основные параметры',
  CLIENT = 'Требования к клиенту',
  PLEDGE = 'Обеспечение',
  CONTRACT = 'Закупки, Контрактная логика'
}

export interface ProductsListBank extends ProductRead {
  guid: string;
}
