import Validator from 'instant-validation';

import { Debtor } from 'Application/types';

import { inRangeRule } from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';

export const createValidaton = (data: Debtor[]) => {
  const validationRules = data.reduce((validatorObj, debtor, index) => {
    validatorObj[`${index}_limit`] = [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ];

    return validatorObj;
  }, {});

  return new Validator<any>(validationRules);
};
