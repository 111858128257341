import styled from 'styled-components';
import { rr, lightGray } from 'shared/styled';

export const RegistrySettingsPageStyled = styled.section`
  margin-top: 50px;
  width: 80vw;
  height: auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const DaysContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: 900;
    color: #000;
  }
`;

export const DaysItemLeft = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: 1px solid ${lightGray};
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemLeftActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;

export const DaysItemRight = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: 1px solid ${lightGray};
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemRightActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;

export const DaysItem = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${lightGray};
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const DaysItemActive = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 133, 254, 1);
  background-color: rgba(0, 133, 254, 1);
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
    color: white;
  }
`;
