import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export interface GetCompanyByInnConfirmedBeneficiariesStoreState
  extends Reducer {
  data: ResponseDataType[];
}

export enum GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES {
  REQ = 'GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES_REQ',
  GOT = 'GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES_GOT',
  ERR = 'GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES_ERR'
}

export const initialState: GetCompanyByInnConfirmedBeneficiariesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getCompanyByInnConfirmedBeneficiaries = (
  state: GetCompanyByInnConfirmedBeneficiariesStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES;
    data: ResponseDataType[];
    error: {};
  }
): GetCompanyByInnConfirmedBeneficiariesStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInnConfirmedBeneficiaries;
