import { SET_HOLDING_DATA } from '../reducers/setHoldingFormData';

export interface HoldingWrite {
  id?: number;
  holdingName?: string;
  industryId?: number;
}

export interface HoldingRead extends HoldingWrite {}

export interface HoldingsData extends Partial<HoldingWrite> {}

export const setHoldingFormData = (data: HoldingsData) => ({
  type: SET_HOLDING_DATA.SET,
  data
});

export const resetHoldingFormData = () => ({
  type: SET_HOLDING_DATA.RESET
});
