import {
  RequestDataType,
  ResponseDataType,
  POST_COMMENT
} from 'Comments/reducers/postComment';

export const req = (data: RequestDataType) => ({
  type: POST_COMMENT.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: POST_COMMENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_COMMENT.ERR,
  error
});
