import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/uploadSupplierContractDocument';
import {
  SagaRequestDataType,
  UPLOAD_SUPPLIER_CONTRACT_DOCUMENT
} from 'entities/SCF/Supplier/model/reducers/uploadSupplierContractDocument';

import SCFApi from 'entities/SCF/Supplier/api';

function* uploadSupplierContractDocument(action: {
  type: UPLOAD_SUPPLIER_CONTRACT_DOCUMENT;
  data: SagaRequestDataType;
}) {
  const updateRegistries = (registry, newRegestry) =>
    registry.map(registry => {
      registry.registry = [...registry.registry, ...newRegestry];
      return registry;
    });

  try {
    const { files, ...apiRequestData } = action.data;

    const newRegestry = yield all(
      files.map(function*(file) {
        const formData = new FormData();
        formData.append('file', file);

        const requestData = {
          ...apiRequestData,
          metaInfo: file.metaInfo,
          file: formData
        };

        return yield call(SCFApi.uploadSupplierContractDocument, requestData);
      })
    );

    const registries = yield select(
      ({ Application }: STORE) => Application.setInitializationFormData.registry
    );

    yield put(
      setInitializationFormData({
        registry: updateRegistries(registries, registries)
      })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* uploadSupplierContractDocumentSaga() {
  yield takeLatest(
    UPLOAD_SUPPLIER_CONTRACT_DOCUMENT.REQ,
    uploadSupplierContractDocument
  );
}
