import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { history } from 'src/shared/utils/History';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getUserListForBankPage,
  RequestData as getUsersListForBankPageRequestData,
  ResponseData as getUsersLisForBankPagetResponseData
} from '../../actions/getUsersForBankPage';
import { ScrollTopComponent } from 'src/features/Common';
import {
  TDEmailStyle,
  CheckboxStyle,
  TooltipBoxStyle
} from 'src/features/Users/components/UsersList/styled';
import { NoData } from './styled';

interface StateToProps {
  users: getUsersLisForBankPagetResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getUserListForBankPage: (data: getUsersListForBankPageRequestData) => void;
}

interface OwnProps {
  bankInn: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class UserListForBankPage extends React.Component<Props> {
  initFetch = () => {
    const { getUserListForBankPage, bankInn } = this.props;

    getUserListForBankPage({ bankInn });
  };

  componentDidMount() {
    this.initFetch();
  }

  onUserClick(index: number) {
    history.push(`/crm/users/${this.props.users.items[index].id}`);
  }

  render() {
    const { users, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT &&
          (users.items.length ? (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">ID</TableThStyled>
                  <TableThStyled width="10%">Имя</TableThStyled>
                  <TableThStyled width="10%">Телефон</TableThStyled>
                  <TableThStyled width="15%">Email</TableThStyled>
                  <TableThStyled width="10%">Дата регистрации</TableThStyled>
                  <TableThStyled width="10%">Последний вход</TableThStyled>
                  <TableThStyled width="10%">Последняя заявка</TableThStyled>
                  <TableThStyled width="10%">ИНН компании</TableThStyled>
                  <TableThStyled width="10%">Простой процесс</TableThStyled>
                  <TableThStyled width="10%">
                    Пустые поля и документы
                  </TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {users.items.map((user, index) => (
                  <TableRowStyled
                    key={user.id}
                    onClick={() => this.onUserClick(index)}
                  >
                    <td>{user.id}</td>
                    <td>
                      {user.lastName} {user.firstName}
                    </td>
                    <td>{user.phoneNumber}</td>
                    <TDEmailStyle>
                      <p>{user.email}</p>
                      {user.emailConfirmed ? (
                        <TooltipBoxStyle textVerify={'Email подтвержден'}>
                          <CheckboxStyle verify={user.emailConfirmed}>
                            <FontAwesomeIcon icon={faCheck} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      ) : (
                        <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                          <CheckboxStyle verify={user.emailConfirmed}>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </CheckboxStyle>
                        </TooltipBoxStyle>
                      )}
                    </TDEmailStyle>
                    <td>
                      {user.createdDateTime &&
                        new Date(user.createdDateTime).toLocaleDateString()}
                    </td>
                    <td>
                      {user.lastLoginAt &&
                        new Date(user.lastLoginAt).toLocaleDateString()}
                    </td>
                    <td>
                      {user.lastApplicationAt &&
                        new Date(user.lastApplicationAt).toLocaleDateString()}
                    </td>
                    <td>{user.companyInn}</td>
                    <td>
                      {user.allowSimpleProcess}
                      <CheckboxStyle verify={user.allowSimpleProcess}>
                        {user.allowSimpleProcess ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : null}
                      </CheckboxStyle>
                    </td>
                    <td>
                      {user.allowBlankFields}
                      <CheckboxStyle verify={user.allowBlankFields}>
                        {user.allowBlankFields ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : null}
                      </CheckboxStyle>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>на платформе пока нет зарегистрированных</p>
              <p>пользователей от этой компании</p>
            </NoData>
          ))}
        <Pagination list={users} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Users }: CRM) => ({
  users: Users.getUsersListForBankPage.data,
  status: Users.getUsersListForBankPage.status,
  error: Users.getUsersListForBankPage.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserListForBankPage }, dispatch);

const UserListForBankPageConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListForBankPage);

export { UserListForBankPageConnect as UserListForBankPage };
