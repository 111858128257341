import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ActivitiesApi {
  static getActivities(data) {
    return ApiRequest('/api/activity/all', 'get', data);
  }

  static createActivities(data: any) {
    return ApiRequest('/api/activity', 'post', data);
  }

  static getActivitiesType() {
    return ApiRequest('/api/activity_type/all');
  }

  static getActivitiesById(id: string) {
    return ApiRequest(`/api/activity/id/${id}`);
  }

  static getContactsOfActivity(id: string) {
    return ApiRequest(`/api/contacts_of_activity/activity/${id}`);
  }

  static getParticipantsOfActivity(id: string) {
    return ApiRequest(`/api/participants_of_activity/activity/${id}`);
  }

  static addContact(activityId: string, contactId: number) {
    return ApiRequest(
      `/api/activity/${activityId}/add_contact/${contactId}`,
      'post'
    );
  }

  static addParticipant(activityId: string, contactId: number) {
    return ApiRequest(
      `/api/activity/${activityId}/add_participant/${contactId}`,
      'post'
    );
  }
}
