export enum PRODUCT_LIST_TYPE {
  ALL = 'ALL'
}

export enum PRODUCT_TABS {
  DEFAULT = 'Основные параметры',
  MANAGERS = 'Кураторы',
  PLATFORMS = 'Площадки',
  CASHBACK = 'Сотрудничество',
  CLIENT = 'Требования к клиенту',
  CONTRACT = 'Контрактная логика',
  STOPS = 'СТОП-факторы',
  PLEDGE = 'Обеспечение'
}

export enum PREFERENTIAL {
  NOT_CHECK = 'не проверяется',
  TRUE = 'есть господдержка',
  FALSE = 'нет господдержки'
}

export interface CheckboxState {
  typeOfContract: string[];
  segmentClient: string[];
  typeClient: string[];
  stopFactorsCrimea: string[];
  stopFactorsCaucasus: string[];
  bki: boolean;
}

export interface RadioBtnState {
  preferential: string;
  hasCashback: string;
  inPlatform: string;
  hasContract: string;
  mspsubject: string;
}
