import { GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID } from 'entities/SCF/Factor/model/reducers/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';

export interface Calc {
  createdDateTime: Date;
  creditorInn: string;
  creditorName: string;
  currencyID: number;
  currencyCode: string;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  baseFullSumm: number;
  emergenceDate: Date;
  maturityDate: Date;
  montaryClaimId: string;
  guid: string;
  status: string;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ,
  data
});

export const got = (data: Calc[]) => ({
  type:
    GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR,
  error
});
