import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getUsersForBankPage';

import { GET_USER_LIST_FOR_BANK } from '../reducers/getUserListForBankPage';
import UserApi from '../api';

function* getUsersListForBank(action: {
  type: GET_USER_LIST_FOR_BANK;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(UserApi.getAllUsersForBank, action.data.bankInn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUsersListForBankSaga(): Generator {
  yield takeLatest(GET_USER_LIST_FOR_BANK.REQ, getUsersListForBank);
}
