import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/postNewClient';

import { POST_NEW_CLIENT } from '../reducers/postNewClient';
import ClientsApi from '../api';

function* postNewClient(action: {
  type: POST_NEW_CLIENT;
  data: RequestData;
}): Generator {
  try {
    yield call(ClientsApi.postNewClient, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postNewClientSaga(): Generator {
  yield takeLatest(POST_NEW_CLIENT.REQ, postNewClient);
}
