import {
  GET_COMPANY_HINTS,
  ResponseData
} from 'Application/reducers/getCompanyHints';

export const req = (inn: string) => ({
  type: GET_COMPANY_HINTS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANY_HINTS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_HINTS.ERR,
  error
});
