import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import {
  ApplicationFormStyled,
  ApplicationInputsColumn,
  ApplicationFormContent
} from 'src/features/Application/components/ApplicationForm/ApplicationProcess/styles';
import { Loader } from 'src/features/Layouts/components';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { ApplicationStepTitle, ApplicationForm } from './styles';
import {
  BankAccountData,
  setBankAccountData
} from 'src/features/SCF/actions/setBankAccount';
import { SetBankAccountDataStoreState } from 'src/features/SCF/reducers/setBankAccount';
import {
  req as sendBankAccountData,
  RequestData as SendBankAccountData
} from './../../../../../actions/sendBankAccountData';
import { ResponseDataType as GetCompanyInnResponseDataType } from './../../../../../reducers/getCompanyInn';
import { req as getCompanyInn } from './../../../../../actions/getCompanyInn';
import { Button } from 'shared/ui/Button';

interface StateToProps extends Partial<BankAccountData> {
  isSendingData: REQUEST_STATUSES;
  companyInn: GetCompanyInnResponseDataType;
}

interface DispatchToProps {
  setBankAccountData: (data: SetBankAccountDataStoreState) => void;
  sendBankAccountData: (data: SendBankAccountData) => void;
  getCompanyInn: () => void;
}

type Props = StateToProps & DispatchToProps;

class BankAccountsCreate extends React.Component<Props> {
  componentDidMount(): void {
    const { setBankAccountData } = this.props;
    setBankAccountData({
      clientInn: this.props.companyInn.companyInn,
      bankInn: null,
      bankBik: null,
      bankName: null,
      accountNumber: null,
      correspondentAccountNumber: null
    });
    this.props.getCompanyInn();
  }
  onSubmit = () => {
    const {
      clientInn,
      bankInn,
      bankBik,
      bankName,
      accountNumber,
      correspondentAccountNumber
    } = this.props;
    this.props.sendBankAccountData({
      clientInn,
      bankInn,
      bankBik,
      bankName,
      accountNumber,
      correspondentAccountNumber
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isSendingData === REQUEST_STATUSES.REQUEST &&
      this.props.isSendingData === REQUEST_STATUSES.GOT
    ) {
      if (window.location.pathname.includes('supplier')) {
        history.replace('/supplier/settings/requisites');
      }
      if (window.location.pathname.includes('debtor')) {
        history.replace('/debtor/settings/requisites');
      }
      if (window.location.pathname.includes('factor')) {
        history.replace('/factor/settings/requisites');
      }
    }
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setBankAccountData({
      [name]: value
    });
  };

  render() {
    const {
      clientInn,
      bankInn,
      bankBik,
      bankName,
      accountNumber,
      correspondentAccountNumber
    } = this.props;
    return (
      <ApplicationFormStyled>
        <ApplicationStepTitle>ДОБАВИТЬ БАНКОВСКИЙ СЧЕТ</ApplicationStepTitle>
        <ApplicationFormContent>
          <ApplicationInputsColumn>
            {this.props.isSendingData === REQUEST_STATUSES.REQUEST ? (
              <Loader />
            ) : (
              <ApplicationForm
                onSubmit={this.onSubmit}
                name="initialize-company-form"
              >
                <Input
                  value={bankInn}
                  placeholder="Пример: 6449013711"
                  label="ИНН Банка"
                  name="bankInn"
                  onChange={this.onChange}
                />
                <Input
                  value={accountNumber}
                  placeholder="Пример: 54325827849399"
                  label="Номер счета"
                  name="accountNumber"
                  onChange={this.onChange}
                />
                <Input
                  value={bankBik}
                  placeholder="Пример: 848292874"
                  label="БИК Банка"
                  name="bankBik"
                  onChange={this.onChange}
                />
                <Input
                  value={bankName}
                  placeholder="Пример: 564821947422"
                  label="Наименование Банка"
                  name="bankName"
                  onChange={this.onChange}
                />
                <Input
                  value={correspondentAccountNumber}
                  placeholder="Пример: 33274477"
                  label="Корреспондентский счет"
                  name="correspondentAccountNumber"
                  onChange={this.onChange}
                />
                <Button
                  type="submit"
                  label={'Добавить счет'}
                  template="defaultBtn"
                  //onClick={() => history.push('/supplier/settings/requisites')}
                />
              </ApplicationForm>
            )}
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  isSendingData: SCF.sendBankAccountData.status,
  companyInn: SCF.getCompanyInn.data,
  ...SCF.setBankAccountData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyInn,
      setBankAccountData,
      sendBankAccountData
    },
    dispatch
  );

const BankAccountsCreateConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountsCreate);

export { BankAccountsCreateConnect as BankAccountsCreate };
