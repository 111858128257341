import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  CONTRACT_PREPAYMENT_TYPE,
  ContractFinancingRead
} from 'src/features/Application/types';
import {
  ApplicationTypes,
  ContractFinancingType,
  ContractSubjectType,
  ContractPrepaymentType
} from 'shared/constants';
import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import { req as getApplicationBank } from 'src/entities/Cabinet/Bank/model/actions/getApplicationBank';
import {
  req as getInBankApplication,
  reset as resetInBankApplication
} from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { reset as resetFinancialsSummary } from 'src/features/Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';
import { EachApplicationView } from './EachApplicationView';
import { Loader } from 'src/features/Layouts/components';
import { format, parse } from 'date-fns';

interface StateToProps {
  bankApplication: Partial<ContractFinancingRead>;
  status: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getApplicationBank: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  resetInBankApplication: () => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ContractFinancingView: React.FC<Props> = ({
  getApplicationBank,
  bankApplication,
  status,
  getInBankApplication,
  inBankApplication,
  resetFinancialsSummary,
  resetInBankApplication,
  resetSelectApplicationState,
  getProductApplication,
  statusPostSendBank,
  statusSendApi,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusChangeApplicationStatus,
  permissions,
  match
}) => {
  React.useEffect(
    () => {
      const { id } = match.params;

      if (id) {
        getApplicationBank(id);
        getInBankApplication({ id: +id });
      }

      return () => {
        resetInBankApplication();
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id } = match.params;

        if (id) {
          getApplicationBank(id);
          getInBankApplication({ id: +id });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id } = match.params;

        if (id) {
          getApplicationBank(id);
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  if (status !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  const setSubInfo = () => {
    const subinfo = [
      {
        name: 'Тип контракта',
        value: ContractFinancingType[bankApplication.contractType]
      },
      {
        name: 'Ссылка на закупку',
        value: bankApplication.purchaseLink
      },
      {
        name: 'Цена контракта',
        value: formSumString(bankApplication.contractAmount)
      },
      {
        name: 'Предмет контракта',
        value: ContractSubjectType[bankApplication.contractSubject]
      },
      {
        name: 'Срок окончания контракта',
        value:
          bankApplication &&
          bankApplication.contractEndDate &&
          format(
            parse(bankApplication.contractEndDate, 'dd/MM/yyyy', new Date()),
            'dd.MM.yyyy'
          )
      },
      {
        name: 'Аванс по договору',
        value: ContractPrepaymentType[bankApplication.prepaymentType]
      }
    ];
    if (
      bankApplication.prepaymentType === CONTRACT_PREPAYMENT_TYPE.HAS_PREPAYMENT
    )
      subinfo.push({
        name: 'Размер аванса',
        value: bankApplication.prepaymentAmount
      });
    return subinfo;
  };

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[bankApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(bankApplication.amount)
        },
        {
          name: 'Срок кредита',
          value: bankApplication.termEndDate
            ? bankApplication.termEndDate
            : formTermString(bankApplication.term)
        },
        {
          name: 'ИНН',
          value: bankApplication.code
        }
      ]}
      subInfo={[...setSubInfo()]}
      documents={{
        general: [
          ...(inBankApplication.agreementDocuments || []),
          ...(inBankApplication.groupDocuments || []),
          ...(inBankApplication.contractDocuments || [])
        ],
        company: inBankApplication.borrowerCompanies || []
      }}
    />
  );
};

const mapStateToProps = ({ Application, BankRole, User }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data,
  status: BankRole.getApplicationBank.status,
  inBankApplication: Application.getInBankApplication.data,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getApplicationBank,
      getInBankApplication,
      resetInBankApplication,
      getProductApplication,
      resetSelectApplicationState,
      resetFinancialsSummary
    },
    dispatch
  );

const ContractFinancingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingView)
);

export { ContractFinancingViewConnect as ContractFinancingView };
