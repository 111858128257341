export const ValidatorMessages = {
  requiredField: 'Поле не должно быть пустым',
  firstNameRequire: 'Введите имя',
  lastNameRequire: 'Введите фамилию',
  phoneNumberRequire: 'Введите номер телефона',
  phoneNumberValid: 'Введите корректный номер телефона',
  legalFormRequire: 'Выберите тип лица',
  codeRequire: 'Введите номер ИНН',
  codeValid: 'Введите корректный ИНН',
  codeWarning: 'Введенный ИНН может быть некорректным',
  companyNameRequire: 'Введите название компании',
  emailRequire: 'Введите email',
  emailValid: 'Введите корректный  email',
  passwordRequire: 'Введите пароль',
  repeatPasswordRequire: 'Повторите пароль',
  passwordIncorrect: 'Проверьте введенные данные',
  passwordsEquality: 'Пароли должны совпадать',
  passwordShort: 'Пароль слишком короткий',
  passwordLong: 'Пароль слишком длинный',
  agreementRequire: 'Согласие обязательно',
  debtorsEmpty: 'Выберите хотя бы одного дебитора',
  applicationFilesEmpty: 'Загрузите необходимые документы',
  invalidValue: 'Недопустимое значение',
  borrowerCompanyRoles: 'Выберите хотя бы одну роль',
  tenderType: 'Необходимо выбрать вид гарантии'
};
