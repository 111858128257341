import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum PREPARE_FOR_APPLICATION_EDIT {
  REQ = 'PREPARE_FOR_APPLICATION_EDIT_REQ',
  GOT = 'PREPARE_FOR_APPLICATION_EDIT_GOT',
  ERR = 'PREPARE_FOR_APPLICATION_EDIT_ERR',
  RESET = 'PREPARE_FOR_APPLICATION_EDIT_RESET'
}

export interface RequestDataType {
  id: number;
}

export type PrepareForApplicationEditStoreState = Reducer;

export const initialState: Reducer = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const prepareForApplicationEdit = (
  state: PrepareForApplicationEditStoreState = initialState,
  action: {
    type: PREPARE_FOR_APPLICATION_EDIT;
    error: {};
  }
): PrepareForApplicationEditStoreState => {
  switch (action.type) {
    case PREPARE_FOR_APPLICATION_EDIT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PREPARE_FOR_APPLICATION_EDIT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PREPARE_FOR_APPLICATION_EDIT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PREPARE_FOR_APPLICATION_EDIT.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default prepareForApplicationEdit;
