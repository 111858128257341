import { GET_SUB_REGISTRIES } from 'entities/SCF/Debtor/model/reducers/getSubRegistries';

export interface SubRegistry {
  id: string;
  isDeleted: boolean;
  debtorRegistryId: number;
  debtorCertificateId: number;
  debtorSignatureName: string;
  debtorSignaturePosition: string;
  debtorInn: string;
  factorInn: string;
  registryFilename: string;
  registryFileIdentifier: string;
  signatureFilename: string;
  signatureFileIdentifier: string;
  fileSize: string;
  downloadedByFactor: boolean;
  createdDateTime: Date;
  modifiedDateTime: Date;
}

export interface ResponseDataType {
  id: string;
  debtorRegistryId: number;
  debtorCertificateId: number;
  debtorSignatureName: string;
  debtorSignaturePosition: string;
  debtorInn: string;
  factorInn: string;
  registryFilename: string;
  registryFileIdentifier: string;
  signatureFilename: string;
  signatureFileIdentifier: string;
  fileSize: string;
  downloadedByFactor: boolean;
  createdDateTime: Date;
  modifiedDateTime: Date;
}

export interface RequestDataType {
  id: number;
}

export const req = (data: RequestDataType) => ({
  type: GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_SUB_REGISTRIES.GET_SUB_REGISTRIES_ERR,
  error
});
