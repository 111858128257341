import styled from 'styled-components';
import { rl } from 'shared/styled';
import { BtnAddContact } from 'src/features/Contacts/components/ContactsList/styled';

export const BtnAddActivity = styled(BtnAddContact)``;

export const TDTextStyled = styled.td`
  white-space: pre-wrap !important;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;
