import { SET_PURCHASE_CONTRACT } from '../reducers/setPurchaseContract';

export interface PurchaseContractWrite {
  supplierInn: string;
  supplierName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  paymentDefermentFixed: boolean;
  paymentDeferment: number;
}

export interface PurchaseContractRead extends PurchaseContractWrite {
  supplierInn: string;
  supplierName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
}

export interface PurchaseContract extends Partial<PurchaseContractWrite> {}

export const setPurchaseContract = (data: PurchaseContract) => ({
  type: SET_PURCHASE_CONTRACT.SET,
  data
});

export const resetPurchaseContract = () => ({
  type: SET_PURCHASE_CONTRACT.RESET
});
