import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { GET_INTERNAL_APPLICATION } from 'entities/Cabinet/InternalAgent/model/reducers/getInternalApplication';

import ApplicationApi from 'Application/api';

function* getInternalApplication(action: {
  type: GET_INTERNAL_APPLICATION;
  id: string;
}) {
  try {
    const res = yield call(ApplicationApi.getInternalApplication, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInternalApplicationSaga() {
  yield takeLatest(GET_INTERNAL_APPLICATION.REQ, getInternalApplication);
}
