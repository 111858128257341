import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { DebtorRead } from '../../actions/setDebtorFormData';
import { req as getDebtor } from '../../actions/getDebtor';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  DebtorsData,
  setDebtorFormData
} from '../../actions/setDebtorFormData';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { ContactsList } from 'src/features/Contacts/components/ContactsList/ContactsList';
import { FactoringLimitsByDebtorList } from 'src/features/FactoringLimits/components/FactoringLimitsList/FactoringLimitsListForDebtorPage';

interface StateToProps {
  debtor: DebtorRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface MatchParams {
  inn: string;
}

interface DispatchToProps {
  getDebtor: (inn: string) => void;
  setDebtorFormData: (data: DebtorsData) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class DebtorView extends React.Component<Props> {
  componentDidMount() {
    this.props.getDebtor(this.props.match.params.inn);
  }

  /* TODO extract ApplicationViewStyled and others */
  render() {
    const { debtor, status, error, permissions } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ApplicationId>Дебитор</ApplicationId>
            <ApplicationId>ИНН {debtor.inn}</ApplicationId>
            <ApplicationTitle>{debtor.shortName}</ApplicationTitle>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Холдинг</span>
                  <span>- {debtor.holdingName} -</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Выручка, тыс. руб.</span>
                  <span>{formSumStringThousands(debtor.revenue)}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Кредиторка, тыс. руб.</span>
                  <span>{formSumStringThousands(debtor.accountPayables)}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Себестоимость продаж, тыс. руб.</span>
                  <span>{formSumStringThousands(debtor.costOfSales)}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Отсрочка, дней</span>
                  <span>{debtor.paymentPostponement}</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Действующие факторинговые лимиты</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <FactoringLimitsByDebtorList debtorInn={debtor.inn} />

            <ViewSeparatorTop />

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Контакты</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <ContactsList companyInn={debtor.inn} />

            <ViewSeparatorTop />
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Debtors, User }: CRM) => ({
  debtor: Debtors.getDebtor.debtor,
  status: Debtors.getDebtor.status,
  error: Debtors.getDebtor.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDebtor,
      setDebtorFormData
    },
    dispatch
  );

const DebtorViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorView)
);

export { DebtorViewConnect as DebtorView };
