import * as React from 'react';

import { BoxStyled, SuccessIcon } from 'shared/styled';

interface Props {
  email: string;
}

export const RegistrationSucess: React.FC<Props> = ({ email }: Props) => (
  <React.Fragment>
    <h1>Заявка на регистрацию создана успешно</h1>
    <SuccessIcon />
    <BoxStyled>
      <h2>Требуется подтверждение адреса электронной почты</h2>
      <p>
        Подтверждающее сообщение отправлено на адрес электронной почты{' '}
        <span>{email}</span>. Для завершения процесса регистрации в личном
        кабинете, пожалуйста, выполните действия указанные в письме.
      </p>
    </BoxStyled>
  </React.Fragment>
);
