import { combineReducers } from 'redux';
import getEarlyPayment, { GetEarlyPaymentStoreState } from './getEarlyPayment';
import getEarlyPaymentByGuid, {
  GetEarlyPaymentByGuidStoreState
} from './getEarlyPaymentByGuid';

export interface EarlyPaymentReducers {
  getEarlyPayment: GetEarlyPaymentStoreState;
  getEarlyPaymentByGuid: GetEarlyPaymentByGuidStoreState;
}

export const EarlyPayment = combineReducers({
  getEarlyPayment,
  getEarlyPaymentByGuid
});
