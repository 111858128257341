import { GET_PROPERTIES } from '../reducers/getProperties';

export const req = (applicationId:number) => ({
  type: GET_PROPERTIES.REQ,
  applicationId
});

export const got = () => ({
  type: GET_PROPERTIES.GOT,
});

export const err = (error: {}) => ({
  type: GET_PROPERTIES.ERR,
  error
});
