import { GET_ONE_FACTOR_ROLE_BY_GUID } from 'entities/SCF/Factor/model/reducers/getOneFactorRoleByGuid';

export interface ResponseDataType {
  guid: string;
  createdDateTime: Date;
  debtorName: string;
  supplierInn: string;
  supplierName: string;
  payDate: Date;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: Date;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  statusDebtor: string;
  agreementFileIdentifier: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  rejectSubject: number;
  rejectComment: string;
  contractId: number;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_ONE_FACTOR_ROLE_BY_GUID.GET_ONE_FACTOR_ROLE_BY_GUID_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_ONE_FACTOR_ROLE_BY_GUID.GET_ONE_FACTOR_ROLE_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ONE_FACTOR_ROLE_BY_GUID.GET_ONE_FACTOR_ROLE_BY_GUID_ERR,
  error
});
