export const GuaranteesType = {
  TAX: 'Налоговая',
  TENDER: 'Тендерная',
  FOURTY_FOUR_FZ: '44-ФЗ',
  TWO_TWO_THREE_FZ: '223-ФЗ',
  SIX_ONE_FIVE_P: '615-П',
  COMMERCIAL: 'Коммерческая',
  LIMIT: 'Лимит',
  OTHER: 'Другая'
};

export const TenderType = {
  PARTICIPATION: 'Участие',
  OBLIGATIONS: 'Исполнение обязательств',
  ADVANCE_RETURNS: 'Возврат аванса',
  QUALITY: 'Качества',
  PAYMENT: 'Платежная',
  OTHER: 'Другая'
};

export const TaxType = {
  CUSTOMS: 'Таможенная гарантия',
  NDS: 'Гарантия возмещения НДС',
  REGULATION: 'РосАлкогольРегулирование',
  OTHER: 'Другая'
};

export const TaxSystemType = {
  '1': 'Общая',
  '2': 'Упрощенная',
  '3':
    'Патентная система налогообложения   (ПСН для ИП с определенным видом деятельности)',
  '4': 'Единый   сельскохозяйственный налог (ЕСХН)',
  '5': 'Единый налог на   вмененный доход (ЕНВД)',
  '6': 'Налог на профессиональную деятельность (НПД) для самозанятых граждан'
};

export const GuaranteesSubTypeId = {
  1: 'Гарантия возврата аванса',
  2: 'Гарантия исполнения обязательств',
  3: 'Гаратий качества (гаранийных обязательств',
  4: 'Другая',
  5: 'Гарантия для участия',
  6: 'Платежная гарантия',
  7: 'Гарантия возмещения НДС',
  8: 'Таможенная гарантия',
  9: 'Гарантия Росалкогольрегулирование'
};

// TODO remove those
export const GuaranteesTaxType = {
  NDS: 'Гарантия возмещения НДС',
  CUSTOMS: 'Таможенная гарантия',
  REGULATION: 'РосАлкогольРегулирование'
};

export const GuaranteesTenderType = {
  AVANCE: 'Гарантия возврата аванса',
  OBLIGATION: 'Гарантия исполнения обязательств',
  QUALITY: 'Гарантия качества',
  OTHER: 'Другая'
};

export const GuaranteesCommercialType = {
  AVANCE: 'Гарантия возврата аванса',
  OBLIGATION: 'Гарантия исполнения обязательств',
  QUALITY: 'Гарантия качества',
  OTHER: 'Другая'
};
