import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getContactsListByProduct';

import {
  GET_CONTACTS_LIST_BY_PRODUCT,
} from '../reducers/getContactsListByProduct';
import ContactsOfProductsApi from '../api';

function* getContactsListByProduct(action: {
  type: GET_CONTACTS_LIST_BY_PRODUCT;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(ContactsOfProductsApi.getContactsListByProduct, action.data.productId);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContactsListByProductSaga(): Generator {
  yield takeLatest(GET_CONTACTS_LIST_BY_PRODUCT.REQ, getContactsListByProduct);
}
