import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getFactoringLimitsByDebtorList,
  ResponseData as getFactoringLimitsByDebtorListResponseData,
  RequestData as getFactoringLimitsByDebtorListRequestData
} from '../../actions/getFactoringLimitsByDebtorList';
import { history } from 'src/shared/utils/History';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import {
  ApplicationStatusStyled,
  TransferApplicationBtn
} from 'src/features/Application/components/ApplicationsList/ReusableComponents/ApplicationStatus/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { FactoringLimitRead } from '../../actions/setFactoringLimitFormData';
//import ContactPopup from './ContactPopup/ContactPopup';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  factoringLimits: getFactoringLimitsByDebtorListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getFactoringLimitsByDebtorList: (
    data: getFactoringLimitsByDebtorListRequestData
  ) => void;
}

interface OwnProps {
  debtorInn: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class FactoringLimitsByDebtorList extends React.Component<Props> {
  componentDidMount() {
    const { debtorInn, getFactoringLimitsByDebtorList } = this.props;

    // TODO duplication with applicationslist
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getFactoringLimitsByDebtorList({
      page,
      pageSize: 20,
      debtorInn: debtorInn
    });
  }

  onFactoringLimitClick(index: number) {
    history.push(
      `/crm/factoring_limits/${this.props.factoringLimits.items[index].id}`
    );
  }

  render() {
    const { factoringLimits, status, error, debtorInn } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Фактор</TableThStyled>
                <TableThStyled width="8%">ИНН Фактора</TableThStyled>
                <TableThStyled width="8%">Продукт</TableThStyled>
                <TableThStyled width="8%">Дата установки</TableThStyled>
                <TableThStyled width="8%">Размер лимита</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {factoringLimits.items.map((factoringLimit, key) => (
                <TableRowStyled
                  onClick={() => this.onFactoringLimitClick(key)}
                  key={key}
                >
                  <td>{factoringLimit.factorShortName}</td>
                  <td>{factoringLimit.factorINN}</td>
                  <td>{factoringLimit.productName}</td>
                  <td>{factoringLimit.setDate}</td>
                  <td>{factoringLimit.volume}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
        <Pagination list={factoringLimits} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ FactoringLimits, User }: CRM) => ({
  factoringLimits: FactoringLimits.getFactoringLimitsByDebtorList.data,
  status: FactoringLimits.getFactoringLimitsByDebtorList.status,
  error: FactoringLimits.getFactoringLimitsByDebtorList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringLimitsByDebtorList //,
      //changeContactStatus
    },
    dispatch
  );

const FactoringLimitsByDebtorListConnect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringLimitsByDebtorList);

export { FactoringLimitsByDebtorListConnect as FactoringLimitsByDebtorList };
