import styled from 'styled-components';
import { Checkbox } from 'shared/ui/Checkbox';
import { FormSection } from 'shared/ui/FormSection';
import { Error } from 'src/shared/ui/Error/Error';

export const CheckboxGroupStyled = styled(FormSection)`
  margin: 0;
  position: relative;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin: 10px 0 0 0;
`;

export const CheckboxGroupError = styled(Error)`
  margin-top: 5px;
  position: absolute;
`;
