import { PATCH_SET_DISCOUNT_MARGIN } from 'entities/SCF/Debtor/model/reducers/patchSetDiscountMargin';

export interface RequestData {
  margin: number;
}

export const req = (inn: string, data: RequestData) => ({
  type: PATCH_SET_DISCOUNT_MARGIN.REQ,
  inn,
  data
});

export const got = () => ({
  type: PATCH_SET_DISCOUNT_MARGIN.GOT
});

export const err = (error: {}) => ({
  type: PATCH_SET_DISCOUNT_MARGIN.ERR,
  error
});
