import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'ExternalAgent/actions/externalAgentStatus';
import { EXTERNAL_AGENT_STATUS_ACTIONS } from 'ExternalAgent/reducers/externalAgentStatus';
import ExternalAgentApi from 'ExternalAgent/api';

function* checkExternalAgentStatus() {
  try {
    const res:any = yield call(ExternalAgentApi.getStatus);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* checkExternalAgentStatusSaga() {
  yield takeLatest(EXTERNAL_AGENT_STATUS_ACTIONS.REQ, checkExternalAgentStatus);
}
