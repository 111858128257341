import { SEND_INITIAL_AND_GUARANTEES_DATA } from '../reducers/sendInitialAndGuaranteesData';
import { SetInitializationApplicationType } from '../reducers/setInitializationFormData';

export const req = () => ({
  type: SEND_INITIAL_AND_GUARANTEES_DATA.REQ
});

export const got = () => ({
  type: SEND_INITIAL_AND_GUARANTEES_DATA.GOT
});

export const err = (error: {}) => ({
  type: SEND_INITIAL_AND_GUARANTEES_DATA.ERR,
  error
});
