import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Loader } from 'Layouts/components';
import {
  req as getSupplyContract,
  ResponseData as ResSupplyContract
} from 'src/features/SupplyContracts/actions/getSupplyContract';
import { req as getSupplyContractSupplierSignatureFile } from 'src/features/SupplyContracts/actions/getSupplyContractSupplierSignatureFile';
import { req as getSupplyContractDebtorSignatureFile } from 'src/features/SupplyContracts/actions/getSupplyContractDebtorSignatureFile';
import { req as getSupplyContractAgreementFile } from 'src/features/SupplyContracts/actions/getSupplyContractAgreementFile';
import {
  req as getContractByInternal,
  ResponseData as ResContractByInternal
} from 'src/features/SupplyContracts/actions/getContractByInternal';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { closeModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import {
  ContractsInfoPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  ContractsContainer,
  ContractsHeader,
  ContractsTopSection,
  ContractsTopLeftContainer,
  AccountContainer,
  AccountInfo,
  ButtonSubmit,
  AccountInfoContainer,
  ConfirmedStatus
} from './styled';
import {
  BackBtn,
  BackBtnText,
  BtnsBlock,
  BtnsBlockDownloadDocument,
  BtnsBlockDownloadSignature,
  BtnsBlockDownloadSignatureTopContainer,
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError,
  InactiveButton
} from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError, CRM } from 'globaltypes';
import { req as uploadContractDocument } from 'entities/SCF/Supplier/model/actions/uploadSupplierContractDocument';
import { SagaRequestDataType as UploadContractDocumentRequestDataType } from 'entities/SCF/Supplier/model/reducers/uploadSupplierContractDocument';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { RegistryDocument } from 'src/features/Application/types';
import { lightGray, orange } from 'shared/styled';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  supplyContract: ResSupplyContract;
  documentsUploadStatus: REQUEST_STATUSES;
  internalContract: ResContractByInternal[];
}

interface DispatchToProps {
  getSupplyContract: (guid: string) => void;
  uploadContractDocument: (data: UploadContractDocumentRequestDataType) => void;
  getSupplyContractSupplierSignatureFile: (guid: string) => void;
  getSupplyContractDebtorSignatureFile: (guid: string) => void;
  getSupplyContractAgreementFile: (guid: string) => void;
  getContractByInternal: (guid: string) => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
}

interface State {
  pageUrl: string;
  filesDisplay: RegistryDocument[];
  isPaymentDefermentFixed: boolean;
  paymentDeferment: number;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class SupplyContracts extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: this.props.match.params.guid,
        filesDisplay: null,
        isPaymentDefermentFixed: null,
        paymentDeferment: 0
      };
      this.handleFilesChange = this.handleFilesChange.bind(this);
    }
  }

  handleFilesChange(file) {
    this.setState({ filesDisplay: file });
  }

  onFilesUpload = contract => {
    this.handleFilesChange(contract);
  };

  sendContractDocument = () => {
    const { uploadContractDocument } = this.props;
    uploadContractDocument({
      files: this.state.filesDisplay,
      guid: this.props.supplyContract.id
    });
    this.handleFilesChange(null);
  };

  initFetch = () => {
    const { getSupplyContract, getContractByInternal } = this.props;
    getSupplyContract(this.state.pageUrl);
    getContractByInternal(this.props.match.params.guid);

    const base64ToBlob = (base64, type = 'application/octet-stream') => {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    };

    const arrayBufferToBase64 = buffer => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    fetch(
      `/api/supply_contract/internal_role/${
        this.props.match.params.guid
      }/get_agreement_file`
    )
      .then(res => res.arrayBuffer())
      .then(data => {
        var base64Str = arrayBufferToBase64(data);
        const blob = base64ToBlob(base64Str, 'application/pdf');
        const url = URL.createObjectURL(blob);
        var outputObject = document.createElement('iframe');
        var outputText = document.createElement('p');
        outputText.style.margin = '30px 0 8px 0';
        outputText.style.fontFamily = 'Roboto-Regular';
        outputText.innerHTML = '* Превью договора';
        outputObject.src = url + '#toolbar=0';
        outputObject.style.width = '1200px';
        outputObject.style.height = '1000px';
        outputObject.style.background = '#262B41';
        document.getElementById('documentObject').appendChild(outputText);
        document.getElementById('documentObject').appendChild(outputObject);
      });
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.status === REQUEST_STATUSES.GOT &&
      this.props.documentsUploadStatus !== prevProps.documentsUploadStatus
    ) {
      this.initFetch();
      this.setState({ paymentDeferment: 0, isPaymentDefermentFixed: null });
    }
  }

  onBackClick = () => {
    this.props.history.push(`/internal/supply_contracts?page=1`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
  };

  onSignClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    this.props.history.push({
      pathname: `/supplier/settings/certificates/edit`
    });
  };

  handlePaymentDefermentChange = (name: string) => {
    if (name === 'active') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: true
      }));
    } else if (name === 'inActive') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: false
      }));
    }
  };

  render() {
    const {
      status,
      supplyContract,
      documentsUploadStatus,
      internalContract
    } = this.props;

    return (
      <ContractsInfoPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {documentsUploadStatus === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <ContractsContainer>
            <ContractsTopSection>
              <ContractsTopLeftContainer>
                <ContractsHeader>
                  <h1>
                    Договор № {supplyContract.contractNumber} от{' '}
                    {format(
                      new Date(supplyContract.contractStartDate),
                      'dd.MM.yyyy'
                    )}
                  </h1>
                  <BackBtn onClick={() => this.onBackClick()}>
                    <BackBtnText>Назад</BackBtnText>
                  </BackBtn>
                </ContractsHeader>
                <ContractsInfoArea>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle style={{ width: '150px' }}>
                      Покупатель:
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.debtorInn}</p>
                    <p>{supplyContract.debtorName}</p>
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>
                <ContractsInfoArea>
                  <h1>Реквизиты договора:</h1>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Наименование договора
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.contractName}</p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Номер договора
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.contractNumber}</p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Дата договора
                    </ContractsInfoItemTitle>
                    <p>
                      {format(
                        new Date(supplyContract.contractStartDate),
                        'dd.MM.yyyy'
                      )}
                    </p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Срок действия договора
                    </ContractsInfoItemTitle>
                    {supplyContract.isPerpetual == true ? (
                      <p>Бессрочный</p>
                    ) : (
                      <p>
                        {format(
                          new Date(supplyContract.contractStartDate),
                          'dd.MM.yyyy'
                        )}{' '}
                        -{' '}
                        {format(
                          new Date(supplyContract.contractEndDate),
                          'dd.MM.yyyy'
                        )}
                      </p>
                    )}
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>

                <ContractsInfoArea>
                  <h1>Условия оплаты:</h1>

                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Отсрочка платежа
                    </ContractsInfoItemTitle>
                    <p>
                      {supplyContract.paymentDeferment
                        ? `Фиксированная, ${
                            supplyContract.paymentDeferment
                          } дн.`
                        : 'Плавающая'}
                    </p>
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>

                <AccountContainer>
                  <h1>Счет плательщика:</h1>

                  {internalContract.find(item => item.type === 1) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 1)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 1)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 1)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 1)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {internalContract.find(item => item.type === 1)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Покупатель подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 1)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Поставщик подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 1)
                          .confirmedBySupplier === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Поставщик еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 1)
                          .confirmedBySupplier === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Поставщик отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <p>Плательщик не добавил реквизиты счета</p>
                  )}
                </AccountContainer>

                <AccountContainer>
                  <h1>Счет получателя:</h1>

                  {internalContract.find(item => item.type === 2) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 2)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 2)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 2)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              internalContract.find(item => item.type === 2)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {internalContract.find(item => item.type === 2)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Поставщик подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 2)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Покупатель подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 2)
                          .confirmedByDebtor === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Покупатель еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {internalContract.find(item => item.type === 2)
                          .confirmedByDebtor === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Покупатель отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <p>Получатель не добавил реквизиты счета</p>
                  )}
                </AccountContainer>
              </ContractsTopLeftContainer>

              <BtnsBlock>
                {supplyContract.contraceFileIdentifyer ? (
                  <BtnsBlockDownloadDocument
                    onClick={() =>
                      this.props.getSupplyContractAgreementFile(
                        this.props.match.params.guid
                      )
                    }
                  >
                    Скачать документ
                  </BtnsBlockDownloadDocument>
                ) : (
                  <InactiveButton>Контракт не загружен</InactiveButton>
                )}
                {supplyContract.debtorSignatureFileName ? (
                  <BtnsBlockDownloadSignature>
                    <BtnsBlockDownloadSignatureTopContainer
                      onClick={() =>
                        this.props.getSupplyContractDebtorSignatureFile(
                          this.props.match.params.guid
                        )
                      }
                    >
                      <p>Скачать подпись покупателя</p>
                    </BtnsBlockDownloadSignatureTopContainer>
                  </BtnsBlockDownloadSignature>
                ) : (
                  <InactiveButton>Нет подписи дебитора</InactiveButton>
                )}
                {supplyContract.supplierSignatureFileName ? (
                  <BtnsBlockDownloadSignature>
                    <BtnsBlockDownloadSignatureTopContainer
                      onClick={() =>
                        this.props.getSupplyContractSupplierSignatureFile(
                          this.props.match.params.guid
                        )
                      }
                    >
                      <p>Скачать мою подпись</p>
                    </BtnsBlockDownloadSignatureTopContainer>
                  </BtnsBlockDownloadSignature>
                ) : (
                  <InactiveButton>Нет подписи поставщика</InactiveButton>
                )}
              </BtnsBlock>
            </ContractsTopSection>

            {supplyContract.contraceFileIdentifyer && (
              <div
                id="documentObject"
                style={{ width: '900px', height: '1000px' }}
              />
            )}

            <CertificatesPopup id="certificatesPopup">
              <PopupExit>
                <p onClick={this.onCloseIconClick}>X</p>
              </PopupExit>
              <h1>Список сертификатов в системе</h1>
              <CertificatesArea>
                <CertificatesListPopup
                  guid={this.state.pageUrl}
                  pageUrl={this.state.pageUrl}
                />
              </CertificatesArea>
              <NudgeArea>
                <p>Нет нужного сертификата? </p>
                <Nudge onClick={this.onNudgeClick}>
                  Перейдите в настройки добаления
                </Nudge>
              </NudgeArea>
            </CertificatesPopup>
            <CertificatesPopupError id="certificatesPopupError">
              <PopupExitError>
                <p onClick={this.onCloseErrorIconClick}>X</p>
              </PopupExitError>
              <h1>
                Невозможно подписать выбранным сертификатом
                <br />
                (не найден на устройстве)
              </h1>
            </CertificatesPopupError>
          </ContractsContainer>
        )}
      </ContractsInfoPageStyled>
    );
  }
}

const mapStateToProps = ({
  SCF,
  SCFSupplier,
  SupplyContracts
}: STORE & CRM) => ({
  supplyContract: SupplyContracts.getSupplyContractInternal.data,
  status: SupplyContracts.getSupplyContractInternal.status,
  error: SupplyContracts.getSupplyContractInternal.error,
  documentsUploadStatus: SCFSupplier.uploadSupplierContractDocument.status,
  internalContract: SupplyContracts.getContractByInternal.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplyContract,
      uploadContractDocument,
      getSupplyContractSupplierSignatureFile,
      getSupplyContractDebtorSignatureFile,
      getSupplyContractAgreementFile,
      getContractByInternal,
      openModal,
      closeModal
    },
    dispatch
  );

const SupplyContractsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SupplyContracts)
);

export { SupplyContractsConnect as SupplyContracts };
