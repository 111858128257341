import * as React from 'react';
import { CRMComponent } from '../EachCRM';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { USER_LIST_TYPE } from 'src/features/Users/types';
import { SectionSeparatorStyled } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTty } from '@fortawesome/free-solid-svg-icons';
import { Activities } from 'src/features/Activities/components/ActivitiesList/Activities';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const ActivitiesSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Активности</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: Activities,
      path: '/crm/activities',
      label: 'Все активности',
      icon: <FontAwesomeIcon icon={faTty} fixedWidth={true} />,
      listType: USER_LIST_TYPE.ALL
    })
  }
];
