import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutCertificateFillStoreState extends Reducer {
  data: RequestDataType;
}

export enum PUT_CERTIFICATE_FILL {
  REQ = 'PUT_CERTIFICATE_FILL_REQ',
  GOT = 'PUT_CERTIFICATE_FILL_GOT',
  ERR = 'PUT_CERTIFICATE_FILL_ERR',
  RESET = 'PUT_CERTIFICATE_FILL_RESET'
}

export interface RequestDataType {
  guid: string;
  body: {
    personFullnameGenitive: string;
    positionGenitive: string;
    authority: string;
    authorityGenitive: string;
    personFullnameEng: string;
    positionEng: string;
    authorityEng: string;
  };
}

export const initialState: PutCertificateFillStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    body: {
      personFullnameGenitive: null,
      positionGenitive: null,
      authority: null,
      authorityGenitive: null,
      personFullnameEng: null,
      positionEng: null,
      authorityEng: null
    }
  },
  error: {}
};

const putCertificateFill = (
  state: PutCertificateFillStoreState = initialState,
  action: {
    type: PUT_CERTIFICATE_FILL;
    data: RequestDataType;
    error: {};
  }
): PutCertificateFillStoreState => {
  switch (action.type) {
    case PUT_CERTIFICATE_FILL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case PUT_CERTIFICATE_FILL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_CERTIFICATE_FILL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PUT_CERTIFICATE_FILL.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default putCertificateFill;
