import { UPDATE_PROPERTY } from '../reducers/updateProperty';
import { PropertyRead } from '../types/Property';

export const req = (property: Partial<PropertyRead>) => ({
  type: UPDATE_PROPERTY.REQ,
  property
});

export const got = () => ({
  type: UPDATE_PROPERTY.GOT,
});

export const err = (error: {}) => ({
  type: UPDATE_PROPERTY.ERR,
  error
});
