import styled from 'styled-components';
import { lighterBlue, rr } from 'shared/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const ClientContactStyled = styled.div`
  background-color: ${lighterBlue};
  padding: 25px;
  & > * {
    margin-bottom: 15px;
  }
`;
export const DatesContainerStyled = styled.div`
  display: flex;
  & > * {
    margin: 5px;
  }
`;

export const WrapperInputDetail = styled.div`
  position: relative;
  margin-bottom: 20px;

  & > ${InnPopupStyled} {
    top: 70px;
  }
`;
