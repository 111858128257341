import * as React from 'react';

import { Modal } from 'Common';
import { MODAL_NAMES } from 'Modal/types';
import { HintModalStyled } from './styled';

interface Props {
  message: string;
}

const HintModal: React.FC<Props> = ({ message }) => {
  return (
    <Modal name={MODAL_NAMES.HINT_FINANCIAL_COMPANY_MODAL}>
      <HintModalStyled>
        <p>{message}</p>
      </HintModalStyled>
    </Modal>
  );
};

export { HintModal };
