import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostFactoringConnectionFactorRoleStoreState = Reducer;

export enum POST_FACTORING_CONNECTION_FACTOR_ROLE {
  REQ = 'POST_FACTORING_CONNECTION_FACTOR_ROLE_REQ',
  GOT = 'POST_FACTORING_CONNECTION_FACTOR_ROLE_GOT',
  ERR = 'POST_FACTORING_CONNECTION_FACTOR_ROLE_ERR'
}

export interface RequestDataType {
  supplierInn: string;
  supplierName: string;
  debtorINN: string;
  debtorName: string;
  visibleToDebtor: boolean;
  userID: 0;
}

export const initialState: PostFactoringConnectionFactorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFactoringConnectionFactorRole = (
  state: PostFactoringConnectionFactorRoleStoreState = initialState,
  action: {
    type: POST_FACTORING_CONNECTION_FACTOR_ROLE;
    error: {};
  }
): PostFactoringConnectionFactorRoleStoreState => {
  switch (action.type) {
    case POST_FACTORING_CONNECTION_FACTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FACTORING_CONNECTION_FACTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_FACTORING_CONNECTION_FACTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postFactoringConnectionFactorRole;
