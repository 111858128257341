import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getExternalAgentsList';
import { GET_EXTERNAL_AGENTS_LIST } from '../reducers/getExternalAgentsList';
import ManageExternalAgentsApi from '../api';

function* getExternalAgentsList(action: {
  type: GET_EXTERNAL_AGENTS_LIST;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(ManageExternalAgentsApi.getExternalAgentsList, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalAgentsSaga() {
  yield takeLatest(
    GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_REQ,
    getExternalAgentsList
  );
}
