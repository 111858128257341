import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/entities/SCF/Supplier/model/actions/postEarlyDatePick';

import {
  PUT_EARLY_DATE_PICK,
  RequestDataType
} from 'src/entities/SCF/Supplier/model/reducers/postEarlyDatePick';

import SCFApi from 'entities/SCF/Supplier/api';

function* putEarlyDatePick(action: {
  type: PUT_EARLY_DATE_PICK;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.postEarlyDatePick, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putEarlyDatePickSaga() {
  yield takeLatest(PUT_EARLY_DATE_PICK.REQ, putEarlyDatePick);
}
