import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetCustomerRequirements';

import { PATCH_CABINET_CUSTOMER_REQUIREMENTS } from '../reducers/patchCabinetCustomerRequirements';
import CustomerRequirementsApi from '../api';

function* patchCabinetCustomerRequirements(action: {
  type: PATCH_CABINET_CUSTOMER_REQUIREMENTS;
  guid: string;
  data: any;
}): Generator {
  try {
    yield call(
      CustomerRequirementsApi.patchCabinetCustomerRequirements,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetCustomerRequirementsSaga(): Generator {
  yield takeLatest(
    PATCH_CABINET_CUSTOMER_REQUIREMENTS.REQ,
    patchCabinetCustomerRequirements
  );
}
