import * as React from 'react';
import { history } from 'src/shared/utils/History';
import {
  FormattedApplicationObj,
  ApplicationTile
} from 'Application/components';
import { ApplicationTileViewStyled } from './styles';

interface Props {
  list: FormattedApplicationObj[];
  onApplicationClick: (id: string, applicationType: string) => void;
}

export const ApplicationsTileView: React.FC<Props> = ({
  list,
  onApplicationClick
}) => (
  <ApplicationTileViewStyled>
    {list.map(applicationData => (
      <ApplicationTile
        key={applicationData.id}
        data={applicationData}
        onTileClick={() =>
          history.location.pathname.includes('/cabinet/api_apps')
            ? null
            : onApplicationClick(applicationData.id, applicationData.financingType)
        }
      />
    ))}
  </ApplicationTileViewStyled>
);
