import { combineReducers } from 'redux';

import createBorrowerCompany, {
  CreateBorrowerCompanyDataStoreState
} from './createBorrowerCompany';

import deleteBorrowerCompany, {
  DeleteBorrowerCompanyDataStoreState
} from './deleteBorrowerCompany';

import getBorrowerCompaniesList, {
  GetBorrowerCompaniesListStoreState
} from './getBorrowerCompaniesList';

import saveBorrowerCompaniesList, {
  SaveBorrowerCompaniesListStoreState
} from './saveBorrowerCompaniesList';

import uploadBorrowerCompanyDocuments, {
  UploadBorrowerCompanyDocumentsStoreState
} from './uploadBorrowerCompanyDocuments';

import deleteBorrowerCompanyDocument, {
  DeleteBorrowerCompanyDocumentStoreState
} from './deleteBorrowerCompanyDocument';

import getDocumentListByApplicationId, {
  GetDocumentListByApplicationIdStoreState
} from './getDocumentListByApplicationId';

import postUploadFinDocInternal, {
  PostUploadFinDocInternalStoreState
} from './postUploadFinDocInternal';

import postUploadNonFinDocInternal, {
  PostUploadNonFinDocInternalStoreState
} from './postUploadNonFinDocInternal';

import postDocumentListByApplicationId, {
  PostDocumentListByApplicationIdStoreState
} from './postDocumentListByApplicationId';

export interface BorrowerCompaniesReducers {
  createBorrowerCompany: CreateBorrowerCompanyDataStoreState;
  deleteBorrowerCompany: DeleteBorrowerCompanyDataStoreState;
  getBorrowerCompaniesList: GetBorrowerCompaniesListStoreState;
  saveBorrowerCompaniesList: SaveBorrowerCompaniesListStoreState;
  uploadBorrowerCompanyDocuments: UploadBorrowerCompanyDocumentsStoreState;
  deleteBorrowerCompanyDocument: DeleteBorrowerCompanyDocumentStoreState;
  getDocumentListByApplicationId: GetDocumentListByApplicationIdStoreState;
  postUploadFinDocInternal: PostUploadFinDocInternalStoreState;
  postUploadNonFinDocInternal: PostUploadNonFinDocInternalStoreState;
  postDocumentListByApplicationId: PostDocumentListByApplicationIdStoreState;
}

export const BorrowerCompanies = combineReducers({
  createBorrowerCompany,
  deleteBorrowerCompany,
  getBorrowerCompaniesList,
  saveBorrowerCompaniesList,
  uploadBorrowerCompanyDocuments,
  deleteBorrowerCompanyDocument,
  getDocumentListByApplicationId,
  postUploadFinDocInternal,
  postUploadNonFinDocInternal,
  postDocumentListByApplicationId
});
