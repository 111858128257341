import { GET_COMPANY_BY_INN_APPLICATION } from 'src/features/Application/reducers/getCompanyByInn';
import { TaxInfo } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export interface ResponseDataType {
  companyAddress: string;
  companyFullName: string;
  companyShortName: string;
  customer223?: boolean;
  customer44?: boolean;
  debtor?: boolean;
  limits?: boolean;
  fss: string;
  inn: string;
  kpp: string;
  ogrn: string;
  okvedCode: string;
  okvedName: string;
  okvedPic: string;
  pfr: string;
  regDate: string;
  startCapital: string;
  employees: number;
  employeesYear: number;
  TaxSystem: 'FILLED' | null;
  taxSystemCode: number;
  taxSystemName: string;
  taxes: {
    taxYear: number;
    taxInfo: TaxInfo[];
  };
}

export interface RequestDataType {
  inn: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_COMPANY_BY_INN_APPLICATION.REQ,
  data
});

export const reset = () => ({
  type: GET_COMPANY_BY_INN_APPLICATION.RESET
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMPANY_BY_INN_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_BY_INN_APPLICATION.ERR,
  error
});
