import styled from 'styled-components';
import { gray, lighterBlue, rr } from 'shared/styled';

export const INNContainer = styled.div`
  margin-top: 40px;

  & > div {
    display: flex;
    column-gap: 40px;
    margin-bottom: 20px;
  }

  button {
    width: fit-content;
    height: 40px;

    :disabled {
      background-color: ${gray};
    }
  }
`;

export const INNField = styled.div`
  position: relative;
  width: 400px;
  height: fit-content;
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
`;

export const InfoField = styled.div`
  width: 350px;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;

  h2 {
    font-family: ${rr};
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    font-family: ${rr};
    font-size: 0.9em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;
