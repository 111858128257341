import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFinancialQuality';

import { GET_FINANCIAL_QUALITY } from '../reducers/getFinancialQuality';
import FinancialStatementsApi from '../api';

function* getFinancialQuality(action: {
  type: GET_FINANCIAL_QUALITY;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      FinancialStatementsApi.getFinancialQuality,
      action.inn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinancialQualitySaga(): Generator {
  yield takeLatest(GET_FINANCIAL_QUALITY.REQ, getFinancialQuality);
}
