import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format, parse } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { STORE, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { COMPANY_LIST_TYPE, CheckboxState } from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getCompaniesList,
  ResponseData as CompaniesListResponseData,
  RequestData as CompaniesListRequestData
} from '../../actions/getCompaniesList';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ContainerCompaniesFilter,
  Image,
  MatchFilters,
  TDCompanyNameStyled,
  TDInnStyled,
  TDNegativeContainer,
  TableRowCustomStyled
} from 'src/features/Companies/components/CompaniesList/styled';
import {
  FilterCategory,
  GroupFilters
} from 'src/features/Products/components/ProductsList/styled';
import { CompanyRead } from '../../actions/setCompanyFormData';
import { FilterByClient } from 'src/features/Filters/Companies/FilterByClient/components/ClientFilter';
import { FilterByFinance } from 'src/features/Filters/Companies/FilterByFinance/components/FinanceFilter';
import { FilterByGoz } from 'src/features/Filters/Companies/FilterByGoz/components/GozFilter';
import { FilterByCooperation } from 'src/features/Filters/Companies/FilterByCooperation/components/CooperationFilter';
import { FilterByBankAndCredit } from 'src/features/Filters/Companies/FilterByBankAndCredit/components/BankAndCreditFilters';

interface StateToProps {
  companies: CompaniesListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCompaniesList: (
    listType: COMPANY_LIST_TYPE,
    data: CompaniesListRequestData
  ) => void;
}

interface OwnProps {
  listType: COMPANY_LIST_TYPE;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const groupFiltersArr = [
  { id: 1, label: 'Клиент' },
  { id: 2, label: 'Финансы' },
  { id: 3, label: 'Госзаказ' },
  { id: 4, label: 'Сотрудничество' },
  { id: 5, label: 'Банки и кредиты' }
];

const CompaniesList: React.FC<Props> = ({
  getCompaniesList,
  companies,
  status,
  error,
  listType,
  history
}) => {
  const [icons, setIcons] = React.useState<{ [key: string]: string }>({});
  const [activeId, setActiveId] = React.useState([1]);
  const [minRevenue, setMinRevenue] = React.useState(0);
  const [maxRevenue, setMaxRevenue] = React.useState(0);
  const [inn, setInn] = React.useState('');
  const [minIncome, setMinIncome] = React.useState(0);
  const [maxIncome, setMaxIncome] = React.useState(0);
  const [minLoans, setMinLoans] = React.useState(0);
  const [lastActivityDate, setLastActivityDate] = React.useState('');
  const [checkboxState, setCheckboxState] = React.useState<CheckboxState>({
    role: []
  });
  const [radioBtnState, setRadioBtnState] = React.useState({
    govSupportInfo: 'NOT_CHECK',
    creditInfo: 'NOT_CHECK',
    pledgeInfo: 'NOT_CHECK',
    openLimitsInfo: 'NOT_CHECK',
    tenderWinner: 'NOT_CHECK',
    isCustomer44: 'NOT_CHECK',
    isCustomer223: 'NOT_CHECK',
    hasContacts: 'NOT_CHECK',
    locked: 'NOT_CHECK'
  });

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        companies.items.forEach(company => {
          loadIcon(company);
        });
      }
    },
    [status]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const inn = searchParams.get('companyInn') || '';
    const creditInfo = searchParams.get('creditInfo') || 'NOT_CHECK';
    const pledgeInfo = searchParams.get('pledgeInfo') || 'NOT_CHECK';
    const govSupportInfo = searchParams.get('govSupportInfo') || 'NOT_CHECK';
    const openLimitsInfo = searchParams.get('openLimitsInfo') || 'NOT_CHECK';
    const tenderWinner = searchParams.get('tenderWinner') || 'NOT_CHECK';
    const isCustomer44 = searchParams.get('fz44') || 'NOT_CHECK';
    const isCustomer223 = searchParams.get('fz223') || 'NOT_CHECK';
    const minRevenue = +searchParams.get('minRevenue') || 0;
    const maxRevenue = +searchParams.get('maxRevenue') || 0;
    const minIncome = +searchParams.get('minIncome') || 0;
    const maxIncome = +searchParams.get('maxIncome') || 0;
    const minLoans = +searchParams.get('minLoans') || 0;
    const hasContacts = searchParams.get('contacts') || 'NOT_CHECK';
    const locked = searchParams.get('locked') || 'NOT_CHECK';
    const lastActivityDate = searchParams.get('lastActivityDate') || '';
    const role = searchParams.getAll('role') || [];

    setInn(inn);
    setMinRevenue(minRevenue);
    setMaxRevenue(maxRevenue);
    setMinIncome(minIncome);
    setMaxIncome(maxIncome);
    setMinLoans(minLoans);
    setLastActivityDate(lastActivityDate);
    setCheckboxState({ role: role.toString().split(',') });

    setRadioBtnState({
      creditInfo,
      pledgeInfo,
      govSupportInfo,
      openLimitsInfo,
      tenderWinner,
      isCustomer44,
      isCustomer223,
      hasContacts,
      locked
    });

    const getFiltersAsObject = (filterArray: string[]) => {
      const filterObject = {};
      filterArray.forEach(propertyName => {
        filterObject[propertyName] = 'my';
      });
      return filterArray.length ? filterObject : {};
    };

    const parsedDate =
      lastActivityDate && parse(lastActivityDate, 'dd/MM/yyyy', new Date());
    const formattedDate = parsedDate && format(parsedDate, 'yyyy-MM-dd');

    getCompaniesList(listType, {
      page,
      pageSize: 20,
      ...(inn && { inn }),
      ...(minRevenue && { minRevenue }),
      ...(maxRevenue && { maxRevenue }),
      ...(minIncome && { minIncome }),
      ...(maxIncome && { maxIncome }),
      ...(minLoans && { minLoans }),
      ...(lastActivityDate && { lastActivityDate: formattedDate }),
      ...(creditInfo === 'TRUE' && { creditInfo: true }),
      ...(pledgeInfo === 'TRUE' && { pledgeInfo: true }),
      ...(govSupportInfo === 'TRUE' && { govSupportInfo: true }),
      ...(openLimitsInfo === 'TRUE' && { openLimitsInfo: true }),
      ...(tenderWinner === 'TRUE'
        ? { tenderWinner: true }
        : tenderWinner === 'FALSE' && { tenderWinner: false }),
      ...(isCustomer44 === 'TRUE'
        ? { isCustomer44: true }
        : isCustomer44 === 'FALSE' && { isCustomer44: false }),
      ...(isCustomer223 === 'TRUE'
        ? { isCustomer223: true }
        : isCustomer223 === 'FALSE' && { isCustomer223: false }),
      ...(hasContacts === 'TRUE'
        ? { hasContacts: true }
        : hasContacts === 'FALSE' && { hasContacts: false }),
      ...(locked === 'TRUE'
        ? { locked: true }
        : locked === 'FALSE' && { locked: false }),
      ...getFiltersAsObject(role)
    });
  };

  const handleRadioBtn = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value === 'NOT_CHECK') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    history.push({ search: searchParams.toString() });
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleNum = (event: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;
    const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));

    const searchParams = new URLSearchParams(history.location.search);

    if (!isNaN(numericValue)) {
      searchParams.set(name, numericValue.toString());
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleLastActivityDateChange = (date: string) => {
    const searchParams = new URLSearchParams(history.location.search);

    if (date) {
      searchParams.set('lastActivityDate', date);
    } else {
      searchParams.delete('lastActivityDate');
    }

    history.push({ search: searchParams.toString() });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    const searchParams = new URLSearchParams(history.location.search);

    const selectedItems = checkboxState[name] || [];
    const isSelected = selectedItems.includes(value);

    if (isSelected) {
      checkboxState[name] = selectedItems.filter(item => item !== value);

      searchParams.delete(name);

      const updatedValues = checkboxState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    } else {
      checkboxState[name] = [...selectedItems, value];

      const updatedValues = checkboxState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    }
  };

  const handleActiveId = (id: number) => {
    if (activeId.includes(id)) {
      setActiveId(activeId.filter(itemId => itemId !== id));
    } else {
      setActiveId([...activeId, id]);
    }
  };

  const navigateToCompany = (inn: string) => {
    window.open(`/crm/companies/${inn}`, '_blank');
  };

  const loadIcon = async (company: CompanyRead) => {
    if (company.okvedCode) {
      const code = parseInt(company.okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setIcons(prevIcons => ({
          ...prevIcons,
          [company.id]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setIcons(prevIcons => ({
          ...prevIcons,
          [company.id]: undefined
        }));
      }
    }
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все организации</ApplicationTitle>

      <GroupFilters>
        {groupFiltersArr.map(({ id, label }) => (
          <FilterCategory
            key={id}
            active={activeId.includes(id)}
            onClick={() => handleActiveId(id)}
          >
            <FontAwesomeIcon
              icon={activeId.includes(id) ? faEye : faEyeSlash}
            />
            {label}
          </FilterCategory>
        ))}
        <MatchFilters>
          Соответствуют фильтрам: <span>{companies.totalItems}</span> компаний
        </MatchFilters>
      </GroupFilters>

      <FiltersStyled direction="column">
        {activeId.includes(1) && (
          <ContainerCompaniesFilter>
            <FilterByClient inn={inn} onFilterByInn={onFilterChange} />
          </ContainerCompaniesFilter>
        )}

        {activeId.includes(2) && (
          <ContainerCompaniesFilter>
            <FilterByFinance
              minRevenue={minRevenue}
              maxRevenue={maxRevenue}
              minIncome={minIncome}
              maxIncome={maxIncome}
              minLoans={minLoans}
              handleNum={handleNum}
            />
          </ContainerCompaniesFilter>
        )}

        {activeId.includes(3) && (
          <ContainerCompaniesFilter>
            <FilterByGoz
              radioBtnState={radioBtnState}
              handleRadioBtn={handleRadioBtn}
            />
          </ContainerCompaniesFilter>
        )}

        {activeId.includes(4) && (
          <ContainerCompaniesFilter>
            <FilterByCooperation
              radioBtnState={radioBtnState}
              handleRadioBtn={handleRadioBtn}
              checkboxState={checkboxState}
              handleCheckbox={handleFilter}
              lastActivityDate={lastActivityDate}
              handleLastActivityDateChange={handleLastActivityDateChange}
            />
          </ContainerCompaniesFilter>
        )}

        {activeId.includes(5) && (
          <ContainerCompaniesFilter>
            <FilterByBankAndCredit
              radioBtnState={radioBtnState}
              handleRadioBtn={handleRadioBtn}
            />
          </ContainerCompaniesFilter>
        )}
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="6%">ИНН</TableThStyled>
              <TableThStyled width="12%">Наименование</TableThStyled>
              <TableThStyled width="9%">Холдинг</TableThStyled>
              <TableThStyled width="9%">Выручка</TableThStyled>
              <TableThStyled width="9%">Прибыль</TableThStyled>
              <TableThStyled width="9%">Капитал и резервы</TableThStyled>
              <TableThStyled width="5%">Фин. год</TableThStyled>
              <TableThStyled width="8%">Контакты</TableThStyled>
              <TableThStyled width="8%">Кредитные лимиты</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {companies.items.map(company => (
              <TableRowCustomStyled
                key={company.id}
                onClick={() => navigateToCompany(company.inn)}
              >
                <TDInnStyled isOkved={company.okvedCode || company.okvedName}>
                  <div>{company.inn}</div>
                  <div>
                    {company.okvedCode} {company.okvedName}
                  </div>
                </TDInnStyled>
                <TDCompanyNameStyled
                  isOkved={company.okvedCode || company.okvedName}
                >
                  <div>{company.companyShortName}</div>
                </TDCompanyNameStyled>
                <td>{company.holdingName}</td>
                <TDNegativeContainer negative={company.revenue < 0}>
                  {company.revenue === null
                    ? 'Нет данных'
                    : company.revenue.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <TDNegativeContainer negative={company.netIncome < 0}>
                  {company.netIncome === null
                    ? 'Нет данных'
                    : company.netIncome.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <TDNegativeContainer negative={company.capital < 0}>
                  {company.capital === null
                    ? 'Нет данных'
                    : company.capital.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0
                      })}
                </TDNegativeContainer>
                <td>{company.financialsYear}</td>
                <td>
                  {company.hasContacts ? 'Есть контакты' : 'Нет контактов'}
                </td>
                <td>
                  {company.hasOpenLimitsInfo ? 'Есть лимиты' : 'Нет данных'}
                  <Image src={icons[company.id]} alt={company.okvedName} />
                </td>
              </TableRowCustomStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={companies} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Companies }: STORE) => ({
  companies: Companies.getCompaniesList.data,
  status: Companies.getCompaniesList.status,
  error: Companies.getCompaniesList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompaniesList }, dispatch);

const CompaniesListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompaniesList)
);

export { CompaniesListConnect as CompaniesList };
