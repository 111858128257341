import { POST_ADD_BUYER } from 'src/features/SCF/reducers/postAddBuyer';

export const req = (inn: string) => ({
  type: POST_ADD_BUYER.REQ,
  inn
});

export const got = () => ({
  type: POST_ADD_BUYER.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_BUYER.ERR,
  error
});
