import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getCertificateGenitive';

export interface GetCertificateGenitiveDataStoreState extends Reducer {
    data: ResponseDataType;
}

export enum CERTIFICATE_GENITIVE_ACTIONS {
    REQ = 'CERTIFICATE_GENETIVE_REQ',
    GOT = 'CERTIFICATE_GENETIVE_GOT',
    ERR = 'CERTIFICATE_GENETIVE_ERR',
}

const initialState: GetCertificateGenitiveDataStoreState = {
    status: REQUEST_STATUSES.NONE,
    data: {
        page: 0,
        pageSize: 0,
        totalItems: 0,
        items: [],
    },
    error: {}
};

const getCertificateGenitive = (
    state: GetCertificateGenitiveDataStoreState = initialState,
    action: {
        type: string;
        data: ResponseDataType;
        error: {};
    }
): GetCertificateGenitiveDataStoreState => {
    switch (action.type) {
        case CERTIFICATE_GENITIVE_ACTIONS.REQ:
            return {
                ...state,
                status: REQUEST_STATUSES.REQUEST
            };

        case CERTIFICATE_GENITIVE_ACTIONS.GOT:
            return {
                ...state,
                data: action.data,
                status: REQUEST_STATUSES.GOT
            };

        case CERTIFICATE_GENITIVE_ACTIONS.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };

        default:
            return state;
    }
};

export default getCertificateGenitive;
