import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getApiKeyInfo';

import { GET_API_KEY_INFO } from '../reducers/getApiKeyInfo';
import ApiKeysApi from '../api';

function* getApiKeyInfo(action: {
  type: GET_API_KEY_INFO;
  key: string;
}): Generator {
  try {
    const res: any = yield call(ApiKeysApi.getApiKeyInfo, action.key);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApiKeyInfoSaga(): Generator {
  yield takeLatest(GET_API_KEY_INFO.REQ, getApiKeyInfo);
}
