import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getFactoringLimitsList';

import { GET_FACTORING_LIMITS_LIST } from '../reducers/getFactoringLimitsList';
import FactoringLimitApi from '../api';

function* getFactoringLimitsList(action: {
  type: GET_FACTORING_LIMITS_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(FactoringLimitApi.getAllFactoringLimits, action.data);

    //case FACTORING_LIST_TYPE.BY_DEBTOR:
    //  res = yield call(FactoringLimitsApi.getFactoringLimitsByDebtorINN, action.data);
    //  break;
    //case FACTORING_LIST_TYPE.BY_FACTOR:
    //  res = yield call(FactoringLimitsApi.getFactoringLimitsByFactorINN, action.data);
    //  break;

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringLimitsListSaga(): Generator {
  yield takeLatest(GET_FACTORING_LIMITS_LIST.REQ, getFactoringLimitsList);
}
