import { combineReducers } from 'redux';
import {
  getClientApplication,
  GetClientApplicationStoreState
} from './getClientApplication';
import getCompaniesOfUser, {
  GetCompaniesOfUserStoreState
} from './getCompaniesOfUser';
import postNewCompanies, {
  PostNewCompaniesStoreState
} from './postNewCompanies';
import getOpenLoanLimits, {
  GetOpenLoanLimitsStoreState
} from './getOpenLoanLimits';
import getFinancialDocuments, {
  GetFinancialDocumentsStoreState
} from './getFinancialDocuments';
import patchCompanyClient, {
  PatchCompanyClientStoreState
} from './patchCompanyClient';
import getCompanyClient, {
  GetCompanyClientStoreState
} from './getCompanyClient';
import getTenderByClient, {
  GetTenderByClientStoreState
} from './getTenderByClient';
import postTenderByClient, {
  PostTenderByClientStoreState
} from './postTenderByClient';
import postAddTenderByEruz, {
  PostAddTenderByEruzClientStoreState
} from './postAddTenderByEruz';
import postUploadFinDocClient, {
  PostUploadFinDocClientStoreState
} from './postUploadFinDocClient';
import postUploadNonFinDocClient, {
  PostUploadNonFinDocClientStoreState
} from './postUploadNonFinDocClient';
import getDocumentListClient, {
  GetDocumentListClientStoreState
} from './getDocumentListClient';
import postDocumentListClient, {
  PostDocumentListClientStoreState
} from './postDocumentListClient';

export interface ClientRoleReducers {
  getCompaniesOfUser: GetCompaniesOfUserStoreState;
  postNewCompanies: PostNewCompaniesStoreState;
  getOpenLoanLimits: GetOpenLoanLimitsStoreState;
  getFinancialDocuments: GetFinancialDocumentsStoreState;
  getClientApplication: GetClientApplicationStoreState;
  patchCompanyClient: PatchCompanyClientStoreState;
  getCompanyClient: GetCompanyClientStoreState;
  getTenderByClient: GetTenderByClientStoreState;
  postTenderByClient: PostTenderByClientStoreState;
  postAddTenderByEruz: PostAddTenderByEruzClientStoreState;
  postUploadFinDocClient: PostUploadFinDocClientStoreState;
  postUploadNonFinDocClient: PostUploadNonFinDocClientStoreState;
  getDocumentListClient: GetDocumentListClientStoreState;
  postDocumentListClient: PostDocumentListClientStoreState;
}

export const ClientRole = combineReducers({
  getClientApplication,
  getCompaniesOfUser,
  postNewCompanies,
  getOpenLoanLimits,
  getFinancialDocuments,
  patchCompanyClient,
  getCompanyClient,
  getTenderByClient,
  postTenderByClient,
  postAddTenderByEruz,
  postUploadFinDocClient,
  postUploadNonFinDocClient,
  getDocumentListClient,
  postDocumentListClient
});
