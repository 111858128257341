import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getBanksList } from 'Application/actions/getBanksList';
import { STORE } from 'src/globaltypes';
import { Select } from 'shared/ui/Select';
import { ResponseDataType as GetBanksListResponseDataType } from 'Application/reducers/getBanksList';

interface State {
  banks: {
    [key: string]: boolean;
  };
}

interface BankSelectProps {
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

interface StateToProps {
  banksList: GetBanksListResponseDataType;
}

interface DispatchToProps {
  getBanksList: () => void;
}

type Props = StateToProps & DispatchToProps & BankSelectProps;

class BankSelect extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getBanksList();
  }

  render() {
    return (
      <Select
        options={this.props.banksList
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map(bank => ({
            id: bank.id.toString(),
            name: bank.name
          }))}
        placeholder="Viberite bank"
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  banksList: Application.getBanksList.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getBanksList }, dispatch);

const BankSelectConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(BankSelect);

export { BankSelectConnect as BankSelect };
