import * as React from 'react';
import { format, isAfter } from 'date-fns';

import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableRowStyled,
  TableThStyled,
  DeleteIcon
} from 'src/features/SCF/UI/CertificatesTableViewStyled';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import './styles/style.css';

interface Props {
  list: CertificateType[];
  onCertificateClick: (index: number) => void;
}

export const CertificatesTableView: React.FC<Props> = ({
  list,
  onCertificateClick
}) => {
  return (
    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
      <TableHeaderStyled>
        <tr>
          <TableThStyled width="30%">Компания</TableThStyled>
          <TableThStyled width="30%">Фамилия Имя Отчество</TableThStyled>
          <TableThStyled width="25%">Должность</TableThStyled>
          <TableThStyled width="25%">Срок действия</TableThStyled>
          <TableThStyled width="10%">Статус</TableThStyled>
        </tr>
      </TableHeaderStyled>
      <tbody id="block">
        {list.map((item: CertificateType, key) => (
          <React.Fragment key={key}>
            {isAfter(new Date(), new Date(item.certValidUntil)) ? (
              <TableRowStyled className="table-row" key={key}>
                <td style={{ color: '#FF0000' }}>{item.companyName_CN}</td>
                <td style={{ color: '#FF0000' }}>
                  {item.personSurNameSN + ' ' + item.personNameG}
                </td>
                <td style={{ color: '#FF0000' }}>{item.positionT}</td>
                <td style={{ color: '#FF0000' }}>
                  {format(new Date(item.certValidFrom), 'dd.MM.yyyy') +
                    ' - ' +
                    format(new Date(item.certValidUntil), 'dd.MM.yyyy')}
                </td>
                <td style={{ color: '#FF0000' }}>Истек</td>
                <td className="delete-btn-td">
                  <DeleteIcon
                    className="delete-btn"
                    onClick={() => onCertificateClick(key)}
                  />
                </td>
              </TableRowStyled>
            ) : (
              <TableRowStyled
                onClick={() => onCertificateClick(item.id)}
                style={{ backgroungColor: '#FF0000' }}
                className="table-row"
                key={key}
              >
                <td>{item.companyName_CN}</td>
                <td>{item.personSurNameSN + ' ' + item.personNameG}</td>
                <td>{item.positionT}</td>
                <td>
                  {format(new Date(item.certValidFrom), 'dd.MM.yyyy') +
                    ' - ' +
                    format(new Date(item.certValidUntil), 'dd.MM.yyyy')}
                </td>
                <td>Действует</td>
              </TableRowStyled>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </TableStyled>
  );
};
