import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { InnPlaceholders } from 'shared/constants';
import { Input } from 'shared/ui/Input';
import {
  req as postCreateBuyersToSupplierLink,
  reset as resetCreateBuyersToSupplierLink,
  RequestType
} from 'src/features/BuyersToSupplierLink/actions/postCreateBuyersToSupplierLink';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import SCFApi from 'src/features/SCF/api';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { INNContainer, INNField, InfoField } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  createStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  postCreateBuyersToSupplierLink: (data: RequestType) => void;
  resetCreateBuyersToSupplierLink: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BuyersToSupplierLinkNew: React.FC<Props> = ({
  postCreateBuyersToSupplierLink,
  createStatus,
  resetCreateBuyersToSupplierLink,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');
  const [supplierCompanyInfo, setSupplierCompanyInfo] = React.useState<
    ResponseDataType
  >(null);
  const [supplierStatus, setSupplierStatus] = React.useState(false);
  const [debtorCompanyInfo, setDebtorCompanyInfo] = React.useState<
    ResponseDataType
  >(null);
  const [debtorStatus, setDebtorStatus] = React.useState(false);
  const [isInfoPopup, setIsInfoPopup] = React.useState({
    debtorInn: false,
    supplierInn: false
  });

  React.useEffect(() => {
    if (history.location.state) {
      if (history.location.state.debtorInn) {
        setSupplierInn(history.location.state.debtorInn);
      }
      if (history.location.state.supplierInn) {
        setDebtorInn(history.location.state.supplierInn);
      }
    }
  }, []);

  React.useEffect(
    () => {
      setIsInfoPopup({ debtorInn: isInfoPopup.debtorInn, supplierInn: false });

      if (supplierInn.length >= 10) {
        SCFApi.getCompanyByInnThirdParty({ inn: supplierInn }).then(
          (res: ResponseDataType) => {
            setSupplierCompanyInfo(res);
            setSupplierStatus(true);
          }
        );
      }
    },
    [supplierInn]
  );

  React.useEffect(
    () => {
      setIsInfoPopup({
        debtorInn: false,
        supplierInn: isInfoPopup.supplierInn
      });

      if (debtorInn.length >= 10) {
        SCFApi.getCompanyByInnThirdParty({ inn: debtorInn }).then(
          (res: ResponseDataType) => {
            setDebtorCompanyInfo(res);
            setDebtorStatus(true);
          }
        );
      }
    },
    [debtorInn]
  );

  React.useEffect(
    () => {
      if (createStatus === REQUEST_STATUSES.GOT) {
        resetCreateBuyersToSupplierLink();
        history.goBack();
      }
    },
    [createStatus]
  );

  const createBuyersToSupplierLink = () => {
    postCreateBuyersToSupplierLink({ debtorInn, supplierInn });
  };

  const onPopupClick = (name: 'supplier' | 'debtor') => {
    if (name === 'supplier') {
      setIsInfoPopup({ debtorInn: isInfoPopup.debtorInn, supplierInn: true });
    } else if (name === 'debtor') {
      setIsInfoPopup({ supplierInn: isInfoPopup.supplierInn, debtorInn: true });
    }
  };

  return (
    <>
      <ApplicationTitle>Создать связку</ApplicationTitle>
      <INNContainer>
        <div>
          <INNField>
            <p>Поставщик:</p>
            <div>
              <p>ИНН</p>
              <Input
                value={supplierInn}
                name="supplierInn"
                placeholder={InnPlaceholders.entity}
                onChange={e => setSupplierInn(e.currentTarget.value)}
              />
            </div>

            {supplierInn.length < 10
              ? null
              : supplierStatus === true &&
                !isInfoPopup.supplierInn && (
                  <InnPopup
                    companyInfo={supplierCompanyInfo}
                    name="supplier"
                    onPopupClick={onPopupClick}
                  />
                )}

            {isInfoPopup.supplierInn && (
              <InfoField>
                <h2>{supplierCompanyInfo.companyFullName}</h2>
                {supplierCompanyInfo.companyAddress &&
                supplierCompanyInfo.companyAddress.length > 3 ? (
                  <p>{supplierCompanyInfo.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {supplierCompanyInfo.inn}</p>
                <p>КПП {supplierCompanyInfo.kpp}</p>
                <p>
                  {supplierCompanyInfo.okvedCode}{' '}
                  {supplierCompanyInfo.okvedName}
                </p>
              </InfoField>
            )}
          </INNField>

          <INNField>
            <p>Дебитор:</p>
            <div>
              <p>ИНН</p>
              <Input
                value={debtorInn}
                name="debtorInn"
                placeholder={InnPlaceholders.entity}
                onChange={e => setDebtorInn(e.currentTarget.value)}
              />
            </div>

            {debtorInn.length < 10
              ? null
              : debtorStatus === true &&
                !isInfoPopup.debtorInn && (
                  <InnPopup
                    companyInfo={debtorCompanyInfo}
                    name="debtor"
                    onPopupClick={onPopupClick}
                  />
                )}

            {isInfoPopup.debtorInn && (
              <InfoField>
                <h2>{debtorCompanyInfo.companyFullName}</h2>
                {debtorCompanyInfo.companyAddress &&
                debtorCompanyInfo.companyAddress.length > 3 ? (
                  <p>{debtorCompanyInfo.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {debtorCompanyInfo.inn}</p>
                <p>КПП {debtorCompanyInfo.kpp}</p>
                <p>
                  {supplierCompanyInfo.okvedCode}{' '}
                  {supplierCompanyInfo.okvedName}
                </p>
              </InfoField>
            )}
          </INNField>
        </div>

        <Button
          label="Создать"
          disabled={!supplierInn.length || !debtorInn.length}
          onClick={createBuyersToSupplierLink}
        />
      </INNContainer>
    </>
  );
};

const mapStateToProps = ({ BuyersToSupplierLink }: CRM & STORE) => ({
  createStatus: BuyersToSupplierLink.postCreateBuyersToSupplierLink.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { postCreateBuyersToSupplierLink, resetCreateBuyersToSupplierLink },
    dispatch
  );

const BuyersToSupplierLinkNewConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BuyersToSupplierLinkNew)
);

export { BuyersToSupplierLinkNewConnect as BuyersToSupplierLinkNew };
