import ApiRequest from 'src/shared/utils/ApiRequest';

export default class BasisParamsForProductsApi {
  static patchBasisParams(id: string, data: any) {
    return ApiRequest(`/api/product/id/${id}`, 'patch', data);
  }

  static patchCabinetBasicParams(guid: string, data: any) {
    return ApiRequest(`/api/bank_role/product/id/${guid}`, 'patch', data);
  }
}
