import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/SCF/Supplier/model/actions/postConfirmContractBySupplier';

import { POST_CONFIRM_CONTRACT_BY_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/postConfirmContractBySupplier';

import SCFApi from 'entities/SCF/Supplier/api';

function* postConfirmContractBySupplier(action: {
  type: POST_CONFIRM_CONTRACT_BY_SUPPLIER;
  accountGuid: string;
  contractGuid: string;
  data: RequestData;
}) {
  try {
    const res: any = yield call(
      SCFApi.postConfirmContractBySupplier,
      action.accountGuid,
      action.contractGuid,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postConfirmContractBySupplierSaga() {
  yield takeLatest(
    POST_CONFIRM_CONTRACT_BY_SUPPLIER.REQ,
    postConfirmContractBySupplier
  );
}
