import { GET_FORM_FILE } from 'entities/SCF/Debtor/model/reducers/getFormFile';

export const req = (guid: string, name: string) => ({
  type: GET_FORM_FILE.REQ,
  guid,
  name
});

export const got = (data: Blob) => ({
  type: GET_FORM_FILE.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FORM_FILE.ERR,
  error
});
