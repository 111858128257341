import {
  DELETE_BORROWER_COMPANY_DOCUMENT,
  RequestDataType
} from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';

export const req = (data: RequestDataType) => ({
  type: DELETE_BORROWER_COMPANY_DOCUMENT.REQ,
  data
});

export const got = () => ({
  type: DELETE_BORROWER_COMPANY_DOCUMENT.GOT
});

export const err = (error: {}) => ({
  type: DELETE_BORROWER_COMPANY_DOCUMENT.ERR,
  error
});
