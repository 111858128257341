import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/postCompaniesList';
import { POST_COMPANIES_LIST_CRM } from '../reducers/postCompaniesList';
import CompaniesApi from '../api';

function* postCompaniesListCRM(action: {
  type: POST_COMPANIES_LIST_CRM;
  data: RequestData;
}): Generator {
  try {
    let res;
    res = yield call(CompaniesApi.postCompaniesList, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCompaniesListCRMSaga(): Generator {
  yield takeLatest(POST_COMPANIES_LIST_CRM.REQ, postCompaniesListCRM);
}
