import * as React from 'react';

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct, radius }) => {
  const r = radius;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={r*1.43}
      cy={r*1.43}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth={r / 4}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      // strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily='Roboto-Light'
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

export const Pie = ({ percentage, colour, radius, textSize }) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={radius * 2.86} height={radius * 2.86}>
      <g transform={`rotate(-90 ${radius*1.43} ${radius*1.43})`}>
        <Circle colour="lightgrey" pct={undefined} radius={radius} />
        <Circle colour={colour} pct={pct} radius={radius} />
      </g>
      <Text percentage={pct} textSize={textSize}/>
    </svg>
  );
};

export default Pie;
