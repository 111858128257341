import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'BorrowerCompanies/actions/getBorrowerCompaniesList';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import {
  GET_BORROWER_COMPANIES_LIST,
  RequestDataType
} from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* getBorrowerCompaniesList(action: {
  type: GET_BORROWER_COMPANIES_LIST;
  data: RequestDataType;
}) {
  try {
    const borrowerCompanies = yield call(
      BorrowerCompaniesApi.getBorrowerCompaniesList,
      action.data
    );

    yield put(
      setInitializationFormData({
        borrowerCompanies
      })
    );

    yield put(got(borrowerCompanies));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBorrowerCompaniesListSaga() {
  yield takeLatest(GET_BORROWER_COMPANIES_LIST.REQ, getBorrowerCompaniesList);
}
