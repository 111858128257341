import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Loader, UnexpectedError } from 'Layouts/components';
import {
  req as getInvestorsList,
  RequestDataType,
  Investor as ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorsListSupplierRoleByGuid';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  req as addInvestorSupplierRole,
  reset as resetInvestorSupplierRole
} from 'entities/SCF/Supplier/model/actions/addInvestorSupplierRole';
import {
  ContractsPageStyled,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  ListSection,
  FullList,
  ListSectionOne,
  FullListOne,
  BackBtn,
  BackBtnText,
  InlineTd
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import './styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { RequestDataType as AddInvestorRequestDataType } from 'entities/SCF/Supplier/model/reducers/addInvestorSupplierRole';

interface StateToProps {
  status: REQUEST_STATUSES;
  addInvestorStatus: REQUEST_STATUSES;
  addInvestorError: ResponseError;
  error: ResponseError;
  investorsList: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
}

interface DispatchToProps {
  getInvestorsList: (data: RequestDataType) => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  addInvestorSupplierRole: (data: AddInvestorRequestDataType) => void;
  resetInvestorSupplierRole: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const PickCreditor: React.FC<Props> = ({
  getInvestorOneDebtor,
  investorOneDebtor,
  getInvestorsList,
  investorsList,
  status,
  history,
  match,
  addInvestorSupplierRole,
  resetInvestorSupplierRole,
  addInvestorStatus,
  error
}) => {
  const [investorPicker, setInvestorPicker] = React.useState<
    AddInvestorRequestDataType
  >({
    guid: match.params.id,
    inn: '',
    payerType: null
  });
  const [selectedInvestor, setSelectedInvestor] = React.useState<number>(null);

  const handleInvestorPickerChange = (
    newInn: string,
    newPayerType: number,
    key: number
  ) => {
    setSelectedInvestor(key);
    setInvestorPicker({
      guid: match.params.id,
      inn: newInn,
      payerType: newPayerType
    });
  };

  React.useEffect(
    () => {
      if (match.params.id) {
        getInvestorOneDebtor({ guid: match.params.id });
        getInvestorsList({ guid: match.params.id });
      }
    },
    [match.params.id]
  );

  React.useEffect(
    () => {
      if (addInvestorStatus === REQUEST_STATUSES.GOT) {
        history.push(
          `/supplier/early_payment_applications/${match.params.id}/pick_signee`
        );

        return () => resetInvestorSupplierRole();
      }
    },
    [addInvestorStatus]
  );

  const onBack = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const onRedirectToCalc = () => {
    history.push(
      `/supplier/early_payment_applications/${match.params.id}/calc`
    );
  };

  const onNextStepClick = () => {
    addInvestorSupplierRole({
      guid: investorPicker.guid,
      inn: investorPicker.inn,
      payerType: investorPicker.payerType
    });
  };

  return (
    <ContractsPageStyled>
      <CreateLimitsNudge>
        <h2>Котировка ставок ранней оплаты:</h2>
      </CreateLimitsNudge>
      <ListSectionOne>
        <FullListOne>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableHeaderCellStyled width="20%">
                  ДЕБИТОР
                </TableHeaderCellStyled>
                <TableHeaderCellStyled width="20%">
                  СУММА СЧЕТОВ
                </TableHeaderCellStyled>
                <TableHeaderCellStyled width="20%">
                  ДАТА РАННЕЙ ОПЛАТЫ
                </TableHeaderCellStyled>
                <TableHeaderCellStyled width="18%">
                  ID ЗАЯВКИ
                </TableHeaderCellStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              <TableRowStyled style={{ height: '20px' }}>
                <td style={{ fontFamily: 'Roboto-Regular' }}>
                  {investorOneDebtor.debtorName}
                </td>
                <td>
                  {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString(
                    'ru',
                    { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                  )}
                </td>

                {investorOneDebtor.firstBasePaymentDate != null ? (
                  <td>
                    {format(new Date(investorOneDebtor.payDate), 'dd.MM.yyyy')}
                  </td>
                ) : (
                  <td />
                )}
                <td>
                  {investorOneDebtor.guid.substring(
                    investorOneDebtor.guid.length - 5
                  )}
                </td>
              </TableRowStyled>
            </tbody>
          </TableStyled>
        </FullListOne>
      </ListSectionOne>

      <ListSection>
        <p>Выберете поставщика финансирования:</p>
        <FullList>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {error.code === 404 && onBack()}
          {error.code === 409 && onRedirectToCalc()}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="12%">
                    СТАВКА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ПРОДУКТ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ПОСТАВЩИК
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="18%">
                    ОПЛАТА СРАЗУ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="25%">
                    ОПЛАТА В КОНЦЕ СРОКА
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {investorsList.map((item, key) => (
                  <TableRowStyled
                    key={key}
                    className="table-row"
                    onClick={() =>
                      handleInvestorPickerChange(
                        item.payerInn,
                        item.payerType,
                        key
                      )
                    }
                  >
                    {selectedInvestor === key ? (
                      <td
                        style={{
                          backgroundColor: '#DAF4FF',
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.rate.toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                            %
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            без НДС
                          </span>
                        </InlineTd>
                      </td>
                    ) : (
                      <td style={{ fontFamily: 'Roboto-Regular' }}>
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.rate.toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                            %
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            без НДС
                          </span>
                        </InlineTd>
                      </td>
                    )}
                    {selectedInvestor === key && item.payerType == 1 ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>
                        Ранняя оплата с дисконтом
                      </td>
                    ) : selectedInvestor === key &&
                    item.payerType == 2 &&
                    item.isRegressed == false ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>
                        Факторинг без регресса
                      </td>
                    ) : selectedInvestor !== key &&
                    item.payerType == 2 &&
                    item.isRegressed == false ? (
                      <td>Факторинг без регресса</td>
                    ) : selectedInvestor === key &&
                    item.payerType == 2 &&
                    item.isRegressed == true ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>
                        Факторинг с регрессом
                      </td>
                    ) : selectedInvestor !== key &&
                    item.payerType == 2 &&
                    item.isRegressed == true ? (
                      <td>Факторинг с регрессом</td>
                    ) : (
                      <td>Ранняя оплата с дисконтом</td>
                    )}
                    {selectedInvestor === key ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>
                        {item.payerName}
                      </td>
                    ) : (
                      <td>{item.payerName}</td>
                    )}
                    {selectedInvestor === key &&
                    item.payerType == 2 &&
                    item.firstPaymentShare == 100 ? (
                      <td
                        style={{
                          backgroundColor: '#DAF4FF',
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.firstPaymentShare.toLocaleString('ru')}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом комиссии
                          </span>
                        </InlineTd>
                      </td>
                    ) : selectedInvestor !== key &&
                    item.payerType == 2 &&
                    item.firstPaymentShare == 100 ? (
                      <td
                        style={{
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.firstPaymentShare.toLocaleString('ru')}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом комиссии
                          </span>
                        </InlineTd>
                      </td>
                    ) : selectedInvestor === key && item.payerType == 2 ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>
                        {item.firstPaymentShare.toLocaleString('ru')}%
                      </td>
                    ) : selectedInvestor !== key && item.payerType == 2 ? (
                      <td>{item.firstPaymentShare.toLocaleString('ru')}%</td>
                    ) : selectedInvestor === key && item.payerType == 1 ? (
                      <td
                        style={{
                          backgroundColor: '#DAF4FF',
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.firstPaymentShare.toLocaleString('ru')}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом дисконта
                          </span>
                        </InlineTd>
                      </td>
                    ) : (
                      <td
                        style={{
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {item.firstPaymentShare.toLocaleString('ru')}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом дисконта
                          </span>
                        </InlineTd>
                      </td>
                    )}
                    {selectedInvestor === key && item.payerType == 1 ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>{'-'}</td>
                    ) : selectedInvestor === key &&
                    item.payerType == 2 &&
                    item.firstPaymentShare == 100 ? (
                      <td style={{ backgroundColor: '#DAF4FF' }}>{'-'}</td>
                    ) : selectedInvestor !== key &&
                    item.payerType == 2 &&
                    item.firstPaymentShare == 100 ? (
                      <td>{'-'}</td>
                    ) : selectedInvestor === key && item.payerType == 2 ? (
                      <td
                        style={{
                          backgroundColor: '#DAF4FF',
                          fontFamily: 'Roboto-Regular'
                        }}
                      >
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {(100 - item.firstPaymentShare).toFixed(2)}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом комиссии
                          </span>
                        </InlineTd>
                      </td>
                    ) : selectedInvestor !== key && item.payerType == 2 ? (
                      <td>
                        <InlineTd>
                          <span
                            style={{ color: '#3F4E65', fontWeight: 'normal' }}
                          >
                            {(100 - item.firstPaymentShare).toFixed(2)}%
                          </span>
                          <span
                            style={{
                              color: '#9FA6B2',
                              fontSize: '12px',
                              marginLeft: '5px',
                              marginTop: '2px'
                            }}
                          >
                            за минусом комиссии
                          </span>
                        </InlineTd>
                      </td>
                    ) : (
                      <td>-</td>
                    )}
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          )}
        </FullList>
      </ListSection>
      <BtnsBlock>
        <BackBtn onClick={onBack}>
          <BackBtnText>Назад</BackBtnText>
        </BackBtn>
        {investorPicker.inn == '' ? (
          <CreateBtn
            style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}
          >
            <CreateBtnText>Далее</CreateBtnText>
          </CreateBtn>
        ) : (
          <CreateBtn onClick={onNextStepClick}>
            <CreateBtnText>Далее</CreateBtnText>
          </CreateBtn>
        )}
      </BtnsBlock>

      {addInvestorStatus === REQUEST_STATUSES.ERROR && <UnexpectedError />}
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  investorsList: SCFSupplier.getInvestorsListSupplierRoleByGuid.data,
  status: SCFSupplier.getInvestorsListSupplierRoleByGuid.status,
  error: SCFSupplier.getInvestorsListSupplierRoleByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  addInvestorStatus: SCFSupplier.addInvestorSupplierRole.status,
  addInvestorError: SCFSupplier.addInvestorSupplierRole.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      getInvestorsList,
      addInvestorSupplierRole,
      resetInvestorSupplierRole
    },
    dispatch
  );

const PickCreditorConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PickCreditor)
);

export { PickCreditorConnect as PickCreditor };
