import * as React from 'react';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';

interface Props {
  inn: string;
  onFilterByInn: ({ name, inn }) => void;
}

export const FilterByClient: React.FC<Props> = ({ inn, onFilterByInn }) => {
  return (
    <Input
      value={inn}
      label="ИНН Заказчика"
      name="customerInn"
      placeholder={InnPlaceholders.entity}
      onChange={e =>
        onFilterByInn({
          name: e.currentTarget.name,
          inn: e.currentTarget.value
        })
      }
    />
  );
};
