import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestType } from 'src/features/FinancialForCompany/actions/getFinancialStatementsAll';
import { SelectPeriodRequest } from '../actions/getExternalAgentSelectPeriod';
import { RequestType as postExternalAgentData } from 'src/features/FinancialForCompany/actions/postExternalAgentFinancialStatementsAll';

export default class FinancialForCompanyApi {
  static getFinancialStatements(inn: string) {
    return ApiRequest(
      `/api/financial_statements_of_companies/inn/${inn}/periods_list`
    );
  }

  static getFinancialStatementsAll(data: RequestType) {
    return ApiRequest(
      `/api/financial_statements_of_companies/all`,
      'get',
      data
    );
  }

  static postFinancialStatementsAll(data) {
    return ApiRequest(
      '/api/financial_statements_of_companies/create',
      'post',
      data
    );
  }

  static getExternalAgentPeriodList(inn: string) {
    return ApiRequest(
      `/api/partner-api/financial_statements_of_companies/inn/${inn}/periods_list`
    );
  }

  static getExternalAgentSelectPeriod(inn: string, data: SelectPeriodRequest) {
    return ApiRequest(
      `/api/partner-api/financial_statements_of_companies/inn/${inn}/`,
      'get',
      data
    );
  }

  static postExternalAgentFinancialStatementsAll(data: postExternalAgentData) {
    return ApiRequest(
      '/api/partner-api/financial_statements/create',
      'post',
      data
    );
  }

  static getFinancialQuality(inn: string) {
    return ApiRequest(`/api/company/partner_api/${inn}/data_quality`);
  }
}
