import styled from 'styled-components';
import { lighterBlue, rr, blue } from 'shared/styled';

export const CreateConnectionsPageStyled = styled.section`
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 110px;
  height: auto;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InnArea = styled.div`
  width: 800px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
`;

export const InnFilter = styled.div`
  width: 370px;
  height: auto;
  margin-top: 10px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 20px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0 20px 0 0;
  }
`;

export const InnPopup = styled.div`
  position: relative;
  z-index: 3;
  width: 230px;
  margin-top: -19px;
  margin-left: 60px;

  padding: 10px 5px 10px 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
  h2 {
    font-family: ${rr};
    font-size: 1em;
    width: 100%;
  }
  p {
    font-family: ${rr};
    font-size: 0.8em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;

export const InfoField = styled.div`
  width: 350px;
  min-height: 100px;
  margin-top: 30px;
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  h2 {
    font-family: ${rr};
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    font-family: ${rr};
    font-size: 0.9em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;
