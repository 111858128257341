import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { BorrowerCompany } from 'Application/types';

export interface GetBorrowerCompaniesListStoreState extends Reducer {
  data: BorrowerCompany[];
}

export enum GET_BORROWER_COMPANIES_LIST {
  REQ = 'GET_BORROWER_COMPANIES_LIST_REQ',
  GOT = 'GET_BORROWER_COMPANIES_LIST_GOT',
  ERR = 'GET_BORROWER_COMPANIES_LIST_ERR'
}

export interface RequestDataType {
  applicationId: number;
}

export const initialState: GetBorrowerCompaniesListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getBorrowerCompaniesList = (
  state: GetBorrowerCompaniesListStoreState = initialState,
  action: {
    type: GET_BORROWER_COMPANIES_LIST;
    data: BorrowerCompany[];
    error: {};
  }
): GetBorrowerCompaniesListStoreState => {
  switch (action.type) {
    case GET_BORROWER_COMPANIES_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BORROWER_COMPANIES_LIST.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_BORROWER_COMPANIES_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBorrowerCompaniesList;
