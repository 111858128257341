import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { rr } from 'shared/styled';

export const SupplierInfoPageStyled = styled.section`
  width: 80vw;
  height: auto;
  margin: ${HeaderHeight} 0 40px 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SupplierInfoArea = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 30px;

  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin: 0;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    color: #000000;
  }
`;

export const SupplierInfo = styled.div`
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-family: ${rr};
    font-size: 1.3em;
    line-height: 140%;
    letter-spacing: 0.5px;
    margin-right: 20px;
  }
`;

export const ErrorDeleteDiscount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  font-size: 20px;
`;
