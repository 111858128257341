import * as React from 'react';

import { Error } from 'shared/ui/Error';
import { Label } from 'shared/ui/Label';

import { InputContainerStyled } from 'src/shared/ui/Input/styles';
import { SelectStyled } from './styles';

interface Option {
  id: string;
  name: string;
  disabled?: boolean;
}

interface SelectProps {
  options: Option[];
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<SelectProps> = ({
  options,
  label,
  name,
  value,
  placeholder,
  required,
  disabled = false,
  error,
  onChange,
  onClick
}: SelectProps) => (
  <InputContainerStyled>
    <Label name={name} label={label} required={required} />
    <SelectStyled
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      isError={!!error}
    >
      <option disabled={true} value="">
        {placeholder}
      </option>

      {options.map(({ id, disabled, name }, key) => (
        <option value={id} key={key} disabled={disabled}>
          {name}
        </option>
      ))}
    </SelectStyled>
    <Error error={error} />
  </InputContainerStyled>
);
