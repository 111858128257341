import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Loader } from 'Layouts/components';

import {
  req as getDiscountingSettings,
  ResponseDataType
} from 'src/features/SCF/actions/getDiscountingSettings';

import {
  DiscountingSettingsPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  ContractsContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  EditBtn,
  EditBtnText,
  HistoryBtn,
  HistoryBtnText,
  ContractsHeader,
  InfoHover,
  HeaderWithHover
} from '../DiscountingInfo/styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import SCFApi from 'src/features/SCF/api';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  discountingSettings: ResponseDataType;
}

interface DispatchToProps {
  getDiscountingSettings: () => void;
}

interface State {
  updateChecker: number;
}

type Props = StateToProps & DispatchToProps;

class FactoringInfoPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        updateChecker: 0
      };
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  handleUpdateCheckerChange = async () => {
    await this.delay(400);
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  };

  onReleaseClick = () => {
    SCFApi.postReleaseLimit();
    this.handleUpdateCheckerChange();
  };

  initFetch = () => {
    const { getDiscountingSettings } = this.props;
    getDiscountingSettings();
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
  }

  onEditClick = () => {
    history.push(`/debtor/settings/factoring/edit`);
  };

  onRegistriesSettingsClick = () => {
    history.push(`/debtor/settings/debtor_registry_settings`);
  };

  onHistoryClick = () => {
    history.push(`/debtor/settings/discounting/history`);
  };

  render() {
    const { status, discountingSettings } = this.props;
    return (
      <DiscountingSettingsPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.ERROR && (
          <ContractsContainer>
            <ContractsHeader>
              <h2>Политика в отношении факторинга</h2>
              <EditBtn onClick={() => this.onEditClick()}>
                <EditBtnText>Редактировать</EditBtnText>
              </EditBtn>
              <HistoryBtn onClick={() => this.onHistoryClick()}>
                <HistoryBtnText>История изменений</HistoryBtnText>
              </HistoryBtn>
            </ContractsHeader>
            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Факторинг:</ContractsInfoItemTitle>
                <TypeContainer>
                  <TypeNoActive>
                    <p>ВЫКЛ</p>
                  </TypeNoActive>
                  <TypeYesInactive>
                    <p>ВКЛ</p>
                  </TypeYesInactive>
                </TypeContainer>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          </ContractsContainer>
        )}
        {status === REQUEST_STATUSES.GOT && (
          <ContractsContainer>
            <ContractsHeader>
              <h1>Политика в отношении факторинга</h1>
              <EditBtn onClick={() => this.onEditClick()}>
                <EditBtnText>Редактировать</EditBtnText>
              </EditBtn>
              <HistoryBtn onClick={() => this.onHistoryClick()}>
                <HistoryBtnText>История изменений</HistoryBtnText>
              </HistoryBtn>
            </ContractsHeader>
            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Факторинг:</ContractsInfoItemTitle>
                {discountingSettings.enableFactoring ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            {discountingSettings.enableFactoring && (
              <ContractsInfoArea>
                <h1>Предложения банков:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringOfferPolicy === 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Показывать поставщикам всегда</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringOfferPolicy === 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>
                    Показывать поставщикам при отсутствии лимита дисконтирования
                  </p>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            )}
            {discountingSettings.enableFactoring && (
              <ContractsInfoArea>
                <h1>Формат работы по факторингу:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringFormatPolicy === 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Биржа</p>
                  <InfoHover>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="rgba(0, 133, 254, 1)"
                      size="lg"
                    />
                    <div>
                      <p>
                        Поставщик может выбирать разных факторов для разных
                        поставок
                      </p>
                    </div>
                  </InfoHover>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringFormatPolicy === 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Постоянный фактор</p>
                  <InfoHover>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="rgba(0, 133, 254, 1)"
                      size="lg"
                    />
                    <div>
                      <p>
                        Заключив договор с фактором, Поставщик не сможет выбрать
                        другого фактора, пока не расторгнет договор с предыдущим
                        фактором
                      </p>
                    </div>
                  </InfoHover>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            )}
            {discountingSettings.enableFactoring && (
              <ContractsInfoArea>
                <HeaderWithHover>
                  <h1>Подписание трехсторонних уступок прав:</h1>
                  <InfoHover style={{ marginLeft: '20px' }}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="rgba(0, 133, 254, 1)"
                      size="lg"
                    />
                    <div>
                      <p>
                        Трехсторонние уведомления между Поставщиком, Покупателем
                        и Фактором об уступке прав требования
                      </p>
                    </div>
                  </InfoHover>
                </HeaderWithHover>

                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringNotificationPolicy === 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>По форме фактора</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringNotificationPolicy === 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>По моей форме</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.factoringNotificationPolicy === 3 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Не подписываю</p>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            )}
            {discountingSettings.enableFactoring && (
              <ContractsInfoArea>
                <h1>Верификация поставок:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.verificationPolicy == 4 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Автоматически по поставщикам</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.verificationPolicy == 3 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную по реестру</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.verificationPolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную по поставщику</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.verificationPolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную по поставке</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.verificationPolicy == 5 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Выключена</p>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            )}

            {/* {discountingSettings.factoringNotificationPolicy === 2 && discountingSettings.ass (
              <ContractsInfoArea>
                <h1>Форма трехстороннего уведомления:</h1>
              </ContractsInfoArea>
            )} */}
          </ContractsContainer>
        )}
      </DiscountingSettingsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  discountingSettings: SCF.getDiscountingSettings.data,
  status: SCF.getDiscountingSettings.status,
  error: SCF.getDiscountingSettings.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getDiscountingSettings }, dispatch);

const FactoringInfoPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringInfoPage);

export { FactoringInfoPageConnect as FactoringInfoPage };
