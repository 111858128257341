import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { ValidatorMessages } from 'shared/constants';
import { innValidationRule } from 'src/shared/utils/ValidationRules';

export const createValidation = () =>
  new Validator({
    debtorINN: [
      {
        rule: innValidationRule,
        message: ValidatorMessages.codeValid
      },
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ]
  });
