import styled from 'styled-components';
import {
  lightBlue,
  lighterBlue,
  navyBlue,
  rl
} from 'shared/styled';

export const Status = styled.div`
  width: 70%;
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  color: ${navyBlue};
  background-color: ${({ status }) =>
    status === 1
      ? 'rgba(253, 241, 224, 1)'
      : status === 10 && 'rgba(213, 232, 253, 1)'};
`;

export const ActionAuction = styled.p`
  font-family: ${rl};
  margin: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${lightBlue};
  }
`;

export const NoData = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 32px;
      font-family: ${rl};
    }
  }
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${lighterBlue};
  column-gap: 40px;
  width: 850px;
  margin-bottom: 20px;

  & > div:nth-child(2) {
    width: 200%;
  }
`;
