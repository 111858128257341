import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getMySuppliers';
import { GET_MY_SUPPLIERS } from '../reducers/getMySuppliers';
import SCFApi from '../api';

function* getMySuppliers(action: {
  type: GET_MY_SUPPLIERS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getMySuppliers, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMySuppliersSaga() {
  yield takeLatest(
    GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_REQ,
    getMySuppliers
  );
}
