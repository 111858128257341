import { combineReducers } from 'redux';
import getCompaniesList, {
  GetCompaniesListCRMStoreState
} from './getCompaniesList';
import postCompaniesList, {
  PostCompaniesListCRMStoreState
} from './postCompaniesList';
import getCompaniesListById, {
  GetCompaniesListByIdCRMStoreState
} from './getCompaniesListById';
import getCompaniesOfCompanies, {
  GetCompaniesOfCompaniesStoreState
} from './getCompaniesOfCompanies';
import postCompaniesOfCompanies, {
  PostCompaniesOfCompaniesStoreState
} from './postCompaniesOfCompanies';
import postAllInListRefresh, {
  postAllInListRefreshStoreState
} from './postAllInListRefresh';
import getInteractionSummary, {
  GetInteractionSummaryStoreState
} from './getInteractionSummary';

export interface CompaniesListCRMReducers {
  getCompaniesList: GetCompaniesListCRMStoreState;
  postCompaniesList: PostCompaniesListCRMStoreState;
  getCompaniesListById: GetCompaniesListByIdCRMStoreState;
  getCompaniesOfCompanies: GetCompaniesOfCompaniesStoreState;
  postCompaniesOfCompanies: PostCompaniesOfCompaniesStoreState;
  postAllInListRefresh: postAllInListRefreshStoreState;
  getInteractionSummary: GetInteractionSummaryStoreState;
}

export const CompaniesListCRM = combineReducers({
  getCompaniesList,
  postCompaniesList,
  getCompaniesListById,
  getCompaniesOfCompanies,
  postCompaniesOfCompanies,
  postAllInListRefresh,
  getInteractionSummary
});
