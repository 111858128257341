import { CompaniesData } from '../actions/setCompanyFormData';

export interface SetCompanyDataStoreState extends Partial<CompaniesData> {}

export enum SET_COMPANY_DATA {
  SET = 'SET_COMPANY_DATA_SET',
  RESET = 'SET_COMPANY_DATA_RESET',
}

export const initialState: SetCompanyDataStoreState = {
  // amount: 0,
  // sourceUserId: 0,
  // inn: null
};

const setCompanyFormData = (
  state: SetCompanyDataStoreState = initialState,
  action: {
    type: string;
    data: CompaniesData;
  }
): SetCompanyDataStoreState => {
  switch (action.type) {
    case SET_COMPANY_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_COMPANY_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setCompanyFormData;
