import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Calc as ResponseDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';

export interface GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState
  extends Reducer {
  data: ResponseDataType[];
}

export enum GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID {
  REQ = 'GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ',
  GOT = 'GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT',
  ERR = 'GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR',
  RESET = 'GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID_RESET'
}

export const initialState: GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getMonetaryClaimsEarlyPaymentApplicationsByGuid = (
  state: GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState = initialState,
  action: {
    type: GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID;
    data: ResponseDataType[];
    error: {};
  }
): GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_MONETARY_CLAIMS_EARLY_PAYMENT_APPLICATIONS_BY_GUID.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default getMonetaryClaimsEarlyPaymentApplicationsByGuid;
