import { GET_FACTORING_CONNECTION_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getFactoringConnectionsSupplierRole';

export interface FactoringConnection {
  id: string;
  debtorInn: string;
  debtorName: string;
  factorInn: string;
  factorName: string;
  factoringContractId: number;
  factoringNotificationId: number;
  statusId: number;
  confirmedBySupplier: number;
  confirmedByFactor: number;
  visibleToDebtor: boolean;
  readyForFactorFinance: boolean;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: FactoringConnection[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  DebtorInnToFilterBy?: string;
  FactorInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_FACTORING_CONNECTION_SUPPLIER_ROLE.GET_FACTORING_CONNECTION_SUPPLIER_ROLE_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_FACTORING_CONNECTION_SUPPLIER_ROLE.GET_FACTORING_CONNECTION_SUPPLIER_ROLE_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_FACTORING_CONNECTION_SUPPLIER_ROLE.GET_FACTORING_CONNECTION_SUPPLIER_ROLE_ERR,
  error
});
