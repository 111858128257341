import { GET_PRODUCTS_LIST } from '../reducers/getProductsList';
import { ProductRead } from './setProductFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ProductRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  size?: number;
  typesToFilterBy?: any;
  revenue?: number;
  age?: number;
  hasCashback?: boolean;
  term?: number;
  hasContract?: boolean;
  mspsubject?: boolean;
  bki?: boolean;
  preferential?: boolean;
  inPlatform?: boolean;
  crimeanClient?: boolean;
  crimeanCustomer?: boolean;
  caucasusClient?: boolean;
  caucasusCustomer?: boolean;
}

export const req = (data: RequestData) => ({
  type: GET_PRODUCTS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_LIST.ERR,
  error
});
