import styled from 'styled-components';
import { lighterBlue, rr, blue } from 'shared/styled';

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 130px;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;
export const InnFilterArea = styled.div`
  width: 450px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;
export const ListSection = styled.section`
  margin-top: 30px;
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FullList = styled.section`
  margin-top: 30px;
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const FactoringCheck = styled.div`
  display: flex;
  width: 295px;
  min-height: 60px;

  justify-content: space-between;
  height: auto;
  p {
    font-family: ${rr};
    font-size: 0.9em;
    height: 100%;
    margin-top: 23px;
  }
`;

export const FactoringBtn = styled.div`
  width: auto;
  height: 25px;
  display: flex;
  margin-top: 17px;
  margin-right: 10px;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;
export const FactoringBtnWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const FactoringBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 0 10px;
  margin-top: 6px !important;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 10;
`;

export const CheckIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  margin-top: 20px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(28, 202, 148)" d="M10.75 18.25L19.5625 9.4375L17.8125 7.6875L10.75 14.75L7.1875 11.1875L5.4375 12.9375L10.75 18.25ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3592 4.78125 22.4688 3.65625 21.3438C2.53125 20.2188 1.64083 18.8958 0.985 17.375C0.328333 15.8542 0 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64083 6.10417 2.53125 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64042 7.625 0.98375C9.14583 0.327917 10.7708 0 12.5 0C14.2292 0 15.8542 0.327917 17.375 0.98375C18.8958 1.64042 20.2188 2.53125 21.3438 3.65625C22.4688 4.78125 23.3592 6.10417 24.015 7.625C24.6717 9.14583 25 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3592 18.8958 22.4688 20.2188 21.3438 21.3438C20.2188 22.4688 18.8958 23.3592 17.375 24.015C15.8542 24.6717 14.2292 25 12.5 25Z" />
  </svg>
  ');
`;

export const CrossIcon = styled.div`
  display: block;
  height: 20px;
  width: 16px;
  margin-left: 10px;
  margin-right: 9px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 255, 255)" d="M19.6209 2.20773C19.741 2.08779 19.8363 1.94537 19.9013 1.7886C19.9664 1.63183 19.9999 1.46378 20 1.29405C20.0001 1.12432 19.9668 0.956236 19.9019 0.799388C19.8371 0.642539 19.742 0.5 19.622 0.37991C19.5021 0.25982 19.3596 0.16453 19.2029 0.0994814C19.0461 0.0344326 18.8781 0.000898753 18.7083 0.000794432C18.5386 0.000690112 18.3705 0.0340174 18.2137 0.0988734C18.0568 0.163729 17.9143 0.258844 17.7942 0.378786L9.99983 8.17314L2.20773 0.378786C1.9652 0.136253 1.63625 -2.5555e-09 1.29326 0C0.950265 2.5555e-09 0.621319 0.136253 0.378786 0.378786C0.136253 0.621319 2.5555e-09 0.950265 0 1.29326C-2.5555e-09 1.63625 0.136253 1.9652 0.378786 2.20773L8.17314 9.99983L0.378786 17.7919C0.258696 17.912 0.163435 18.0546 0.0984432 18.2115C0.0334509 18.3684 0 18.5366 0 18.7064C0 18.8762 0.0334509 19.0444 0.0984432 19.2013C0.163435 19.3582 0.258696 19.5008 0.378786 19.6209C0.621319 19.8634 0.950265 19.9997 1.29326 19.9997C1.46309 19.9997 1.63126 19.9662 1.78817 19.9012C1.94507 19.8362 2.08764 19.741 2.20773 19.6209L9.99983 11.8265L17.7942 19.6209C18.0367 19.8631 18.3655 19.9991 18.7083 19.9989C19.0511 19.9987 19.3798 19.8623 19.622 19.6198C19.8642 19.3772 20.0002 19.0484 20 18.7056C19.9998 18.3628 19.8634 18.0342 19.6209 17.7919L11.8265 9.99983L19.6209 2.20773Z"  />
  </svg>
  ');
`;

export const TimesIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  margin-top: 20px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 0, 0)" d="M15 0C6.71371 0 0 6.71371 0 15C0 23.2863 6.71371 30 15 30C23.2863 30 30 23.2863 30 15C30 6.71371 23.2863 0 15 0ZM22.3548 18.9375C22.6391 19.2218 22.6391 19.6815 22.3548 19.9657L19.9597 22.3548C19.6754 22.6391 19.2157 22.6391 18.9315 22.3548L15 18.3871L11.0625 22.3548C10.7782 22.6391 10.3185 22.6391 10.0343 22.3548L7.64516 19.9597C7.36089 19.6754 7.36089 19.2157 7.64516 18.9315L11.6129 15L7.64516 11.0625C7.36089 10.7782 7.36089 10.3185 7.64516 10.0343L10.0403 7.63911C10.3246 7.35484 10.7843 7.35484 11.0685 7.63911L15 11.6129L18.9375 7.64516C19.2218 7.36089 19.6815 7.36089 19.9657 7.64516L22.3609 10.0403C22.6452 10.3246 22.6452 10.7843 22.3609 11.0685L18.3871 15L22.3548 18.9375Z" />
  </svg>
  ');
`;

export const GreyIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  margin-top: 20px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <circle fill="rgb(217, 217, 217)" cx="12.5" cy="12.5" r="12.5" />
  </svg>
  ');
`;

export const IconPopup = styled.div`
  position: absolute;
  margin-top: 9px;
  margin-left: 30px;
  background-color: #fafafa;
  padding: 5px 10px 5px 10px;
  border: 1px solid #e3e4e9;
  border-radius: 5px;
  z-index: 3;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  p {
    font-family: ${rr};
    font-size: 0.8em;
    width: auto;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusAreaRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  height: 30px;
  width: 90px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  background-color: #1cca94;
  cursor: pointer;
  transition: 0.3s;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    color: #ffffff;
  }
  &:hover {
    background-color: #3de394;
  }
`;

export const DeclineContainer = styled.div`
  display: flex;
  height: 30px;
  width: 90px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: #f53737;
  cursor: pointer;
  transition: 0.3s;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    color: #ffffff;
  }
  &:hover {
    background-color: #b33737;
  }
`;

export const HandleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ActiveContainer = styled.div`
  display: flex;
  width: 105px;
  height: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
  margin-top: 20px;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    margin-right: 10px;
  }
`;

export const InactiveContainer = styled.div`
  display: flex;
  width: 105px;
  height: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
  margin-top: 20px;
  p {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    color: #ff0000;
  }
`;

export const ActiveContainerHovered = styled.div`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 20px;
  background-color: #1cca94;
  transition: 0.3s;
  &:hover {
    background-color: #3de394;
  }
`;

export const InactiveContainerHovered = styled.div`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 20px;
  background-color: #f53737;
  transition: 0.3s;
  &:hover {
    background-color: #b33737;
  }
`;

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    display: none;
  }
`;

export const CheckedIcon = styled.div`
  display: block;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 5px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 255, 255)" d="M6.41256 11.0762L16.9955 0.493273C17.3244 0.164424 17.7429 0 18.2511 0C18.7593 0 19.1779 0.164424 19.5067 0.493273C19.8356 0.822122 20 1.24066 20 1.74888C20 2.2571 19.8356 2.67564 19.5067 3.00448L7.66816 14.843C7.30942 15.2018 6.89088 15.3812 6.41256 15.3812C5.93423 15.3812 5.51569 15.2018 5.15695 14.843L0.493273 10.1794C0.164424 9.85052 0 9.43199 0 8.92376C0 8.41554 0.164424 7.99701 0.493273 7.66816C0.822122 7.33931 1.24066 7.17489 1.74888 7.17489C2.2571 7.17489 2.67564 7.33931 3.00448 7.66816L6.41256 11.0762Z"  />
  </svg>
  ');
`;
