import * as React from 'react';

interface Props {
  children: any;
}

export class ScrollTopComponent extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return this.props.children;
  }
}
