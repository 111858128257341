import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { ApplicationTypes, CreditingPledge } from 'shared/constants';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import { req as getInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as getInBankApplication } from 'Application/actions/getInBankApplication';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { ResponseDataType as ResInBankApplication } from 'Application/reducers/getInBankApplication';

import { req as getCompanyHints } from 'Application/actions/getCompanyHints';
import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { req as getActivitiesByApplication } from 'src/features/Application/actions/getActivitiesByApplication';
import {
  req as getFinancialsSummary,
  reset as resetFinancialsSummary
} from 'src/features/Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';

import { Loader } from 'src/features/Layouts/components';
import { EachApplicationView } from './EachApplicationView';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  internalApplication: ResInternalApplication;
  internalStatus: REQUEST_STATUSES;
  inBankApplication: ResInBankApplication;
  statusInBankApplication: REQUEST_STATUSES;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  getCompanyHints: (inn: string) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  getActivitiesByApplication: (leadId: string) => void;
  getFinancialsSummary: (inn: string) => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CreditingView: React.FC<Props> = ({
  getInternalApplication,
  internalApplication,
  internalStatus,
  getInBankApplication,
  inBankApplication,
  statusInBankApplication,
  resetFinancialsSummary,
  resetSelectApplicationState,
  getActivitiesByApplication,
  getCompanyHints,
  getFinancialsSummary,
  getProductApplication,
  statusPostSendBank,
  statusSendApi,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusChangeApplicationStatus,
  permissions,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getInternalApplication(id);
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getInternalApplication(inBankId);
        getInBankApplication({ id: +inBankId });
      }

      if (
        !permissions.includes(USER_PERMISSIONS.CLIENT) &&
        !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
        !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
        !inBankId
      ) {
        getProductApplication(id);
        getActivitiesByApplication(id);
      }

      return () => {
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getInternalApplication(inBankId);
          getInBankApplication({ id: +inBankId });
        }

        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
          !inBankId
        ) {
          getProductApplication(id);
          getActivitiesByApplication(id);
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
        } else if (inBankId) {
          getInternalApplication(inBankId);
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  React.useEffect(
    () => {
      if (internalStatus === REQUEST_STATUSES.GOT) {
        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
        ) {
          getCompanyHints(internalApplication.code);
          getFinancialsSummary(internalApplication.code);
        }
      }
    },
    [internalStatus]
  );

  const setSubInfo = () => [
    {
      name: 'Тип кредита',
      value: internalApplication.revolvingCredit
        ? 'Возобновляемая кредитная линия'
        : internalApplication.overdraft
          ? 'Овердрафт'
          : internalApplication.credit
            ? 'Кредит'
            : 'не заполнено'
    },
    {
      name: 'Наличие залога',
      value:
        CreditingPledge[internalApplication.pledge] === 'Есть'
          ? `Да / ${[
              internalApplication.realEstate ? 'Недвижимое имущество' : '',
              internalApplication.movableEstate ? 'Движимое имущество' : '',
              internalApplication.otherEstate
                ? `Иное ${internalApplication.otherEstate}`
                : ''
            ]
              .filter(Boolean)
              .join(', ')}`
          : CreditingPledge[internalApplication.pledge] === 'Нет'
            ? 'Нет'
            : 'не заполнено'
    },
    // ...(data.pledges && data.pledges.length > 0
    //   ? [
    //       {
    //         name: 'Тип залога',
    //         value: data.pledges
    //           .map(item => CreditingPledgeType[item])
    //           .join(' / ')
    //       }
    //     ]
    //   : []),
    // ...(!!data.otherPledgeEstate
    //   ? [
    //       {
    //         name: 'Дополнительные виды обеспечения',
    //         value: data.otherPledgeEstate
    //       }
    //     ]
    //   : []),
    {
      name: 'Есть ли поручительства',
      value:
        internalApplication.guarantee === true
          ? `Да / ${
              internalApplication.beneficiaryGuarante
                ? 'Поручительства бенефициаров,'
                : ''
            }, ${
              internalApplication.companiesGuarantee
                ? 'Поручительства других компаний группы'
                : ''
            }`.replace(/\/\s*,\s*$/, '')
          : internalApplication.guarantee === false
            ? 'Нет'
            : 'не заполнено'
    }
    // ...(data.guarantors && data.guarantors.length > 0
    //   ? [
    //       {
    //         name: 'Поручительства',
    //         value: data.guarantors
    //           .map(item => CreditingGuarantorType[item])
    //           .join(' / ')
    //       }
    //     ]
    //   : [])
  ];

  if (
    internalStatus !== REQUEST_STATUSES.GOT ||
    statusInBankApplication !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[internalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(internalApplication.amount)
        },
        {
          name: 'Срок',
          value: formTermString(internalApplication.term)
        },
        {
          name: 'ИНН',
          value: internalApplication.code
        },
        {
          name: 'Рефинансирование',
          value: internalApplication.refinancing ? 'Да' : 'Нет'
        }
      ]}
      subInfo={[...setSubInfo()]}
      documents={{
        general: [
          ...(inBankApplication.agreementDocuments || []),
          ...(inBankApplication.groupDocuments || [])
        ],
        company: inBankApplication.borrowerCompanies || []
      }}
    />
  );
};

const mapStateToProps = ({ Application, InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  internalStatus: InternalAgentRole.getInternalApplication.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInternalApplication,
      getInBankApplication,
      getCompanyHints,
      getProductApplication,
      resetSelectApplicationState,
      getActivitiesByApplication,
      getFinancialsSummary,
      resetFinancialsSummary
    },
    dispatch
  );

const CreditingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CreditingView)
);

export { CreditingViewConnect as CreditingView };
