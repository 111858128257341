import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getContractByInternal';
import { GET_CONTRACT_BY_INTERNAL } from '../reducers/getContractByInternal';
import SupplyApi from '../api';

function* getContractByInternal(action: {
  type: GET_CONTRACT_BY_INTERNAL;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(SupplyApi.getContractByInternal, action.guid);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContractByInternalSaga(): Generator {
  yield takeLatest(GET_CONTRACT_BY_INTERNAL.REQ, getContractByInternal);
}
