import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getUserList';

export interface GetUserListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_USER_LIST {
  REQ = 'GET_USERS_LIST_REQ',
  GOT = 'GET_USERS_LIST_GOT',
  ERR = 'GET_USERS_LIST_ERR'
}

export const initialState: GetUserListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getUsersList = (
  state: GetUserListStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetUserListStoreState => {
  switch (action.type) {
    case GET_USER_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_USER_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_USER_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getUsersList;
