import deletePropertyDocument, { DeletePropertyDocumentStoreState } from "./deletePropertyDocument";

import deleteProperty, { DeletePropertyStoreState } from "./deleteProperty";

import addProperty, { AddPropertyStoreState } from "./addProperty";

import updateProperty, { UpdatePropertyStoreState } from "./updateProperty";

import uploadPropertyDocuments, { UploadPropertyDocumentsStoreState } from "./uploadPropertyDocuments";

import getProperties, { GetPropertiesStoreState } from "./getProperties";


import { combineReducers } from "redux";
import localProperties , { LocalPropertiesData } from "./localProperties";

export interface PropertiesReducers {
deletePropertyDocument: DeletePropertyDocumentStoreState;

deleteProperty: DeletePropertyStoreState;

addProperty: AddPropertyStoreState;

updateProperty: UpdatePropertyStoreState;

uploadPropertyDocuments: UploadPropertyDocumentsStoreState;

getProperties: GetPropertiesStoreState;

localProperties: LocalPropertiesData;


}

export const Properties  = combineReducers({
deletePropertyDocument,

deleteProperty,

addProperty,

updateProperty,

uploadPropertyDocuments,

getProperties,
localProperties,


});
