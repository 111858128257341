import { combineReducers } from 'redux';
import getAgent, { GetAgentStoreState } from './getAgentByInn';
import patchAgent, { PatchAgentStoreState } from './patchAgent';

export interface QuestionnaireReducers {
  getAgent: GetAgentStoreState;
  patchAgent: PatchAgentStoreState;
}

export const Questionnaire = combineReducers({ getAgent, patchAgent });
