import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getEarlyPayment';

import { GET_EARLY_PAYMENT } from '../reducers/getEarlyPayment';
import DocumentListApi from '../api';

function* getEarlyPayment(action: {
  type: GET_EARLY_PAYMENT;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(DocumentListApi.getEarlyPayment, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getEarlyPaymentSaga(): Generator {
  yield takeLatest(GET_EARLY_PAYMENT.REQ, getEarlyPayment);
}
