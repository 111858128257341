import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import {
  req as getCompaniesOfUser,
  ResponseData,
  RequestData
} from 'entities/Cabinet/Client/model/actions/getCompaniesOfUser';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { HeadContainer, InnInputContainer } from './styled';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  companies: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCompaniesOfUser: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const Companies: React.FC<Props> = ({
  getCompaniesOfUser,
  companies,
  status,
  error,
  history
}) => {
  const [companyInn, setCompanyInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const companyInn = searchParams.get('companyInn') || '';

      setCompanyInn(companyInn);

      getCompaniesOfUser({
        page,
        pageSize: 20,
        ...(companyInn && { companyInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToClientCompany = (inn: string) => {
    history.push(`/cabinet/client/companies/inn/${inn}`);
  };

  const navigateToAddClient = () => {
    history.push('/cabinet/client/companies/add');
  };

  return (
    <ScrollTopComponent>
      <HeadContainer>
        <ApplicationTitle>Мои компании</ApplicationTitle>
        <Button
          label="+ Добавить компанию или ИП"
          onClick={navigateToAddClient}
          w="fit-content"
          h="30px"
        />
      </HeadContainer>

      <InnInputContainer>
        <Input
          value={companyInn}
          label="ИНН Компании"
          name="companyInn"
          placeholder={InnPlaceholders.entity}
          onChange={onFilterChange}
        />
      </InnInputContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">ИНН</TableThStyled>
                <TableThStyled width="50%">Наименование</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {companies.items.map((company, index) => (
                <TableRowStyled
                  key={index}
                  onClick={() => navigateToClientCompany(company.companyINN)}
                >
                  <td>{company.companyINN}</td>
                  <td>{company.companyName}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={companies} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ ClientRole }: STORE) => ({
  companies: ClientRole.getCompaniesOfUser.data,
  status: ClientRole.getCompaniesOfUser.status,
  error: ClientRole.getCompaniesOfUser.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompaniesOfUser }, dispatch);

const CompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Companies)
);

export { CompaniesConnect as Companies };
