import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getContact';

import { GET_CONTACT } from '../reducers/getContact';
import ContactsApi from '../api';

function* getContact(action: { type: GET_CONTACT; id: number }): Generator {
  try {
    let res: any;

    res = yield call(ContactsApi.getContact, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContactsSaga(): Generator {
  yield takeLatest(GET_CONTACT.REQ, getContact);
}
