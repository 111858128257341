import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_BANK_MANAGER } from '../reducers/addBankManager';
import { got, err,RequestData } from '../actions/addBankManager';
import AddBankManagersApi from '../api';

function* addBankManagerSaga(action: {
  type: ADD_BANK_MANAGER;
  data: RequestData;
}){
  try {
    yield call(AddBankManagersApi.addBankManager, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addBankManager(){
  yield takeLatest(
    ADD_BANK_MANAGER.REQ,
    addBankManagerSaga);
}
