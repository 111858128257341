export enum SET_APPLICATION_PROCESS_MODE {
  SET = 'SET_APPLICATION_PROCESS_MODE',
  RESET = 'RESET_APPLICATION_PROCESS_MODE'
}

export enum APPLICATION_PROCESS_MODES {
  NONE = 'NONE',
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export interface RequestDataType {
  mode: APPLICATION_PROCESS_MODES;
}

export const initialState: RequestDataType = {
  mode: APPLICATION_PROCESS_MODES.NONE
};

const setApplicationProcessMode = (
  state: RequestDataType = initialState,
  action: {
    type: SET_APPLICATION_PROCESS_MODE;
    data: RequestDataType;
  }
): RequestDataType => {
  switch (action.type) {
    case SET_APPLICATION_PROCESS_MODE.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_APPLICATION_PROCESS_MODE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setApplicationProcessMode;
