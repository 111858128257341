import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/PreapprovedLimits/actions/getLimits';

import { GET_LIMITS } from 'src/features/PreapprovedLimits/reducers/getLimits';
import PreapprovedLimitsApi from '../api';

function* getLimits(action: { type: GET_LIMITS; inn: string }): Generator {
  try {
    const res: any = yield call(PreapprovedLimitsApi.getLimits, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLimitsSaga(): Generator {
  yield takeLatest(GET_LIMITS.REQ, getLimits);
}
