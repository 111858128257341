import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface UpdatePropertyStoreState extends Reducer {
}

export enum UPDATE_PROPERTY {
  REQ = 'UPDATE_PROPERTY_REQ',
  GOT = 'UPDATE_PROPERTY_GOT',
  ERR = 'UPDATE_PROPERTY_ERR'
}

export const initialState: UpdatePropertyStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const updateProperty = (
  state: UpdatePropertyStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): UpdatePropertyStoreState => {
  switch (action.type) {
    case UPDATE_PROPERTY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPDATE_PROPERTY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case UPDATE_PROPERTY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default updateProperty;
