import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface PatchCompanyExternalStoreState extends Reducer {}

export enum PATCH_COMPANY_EXTERNAL {
  REQ = 'PATCH_COMPANY_EXTERNAL_REQ',
  GOT = 'PATCH_COMPANY_EXTERNAL_GOT',
  ERR = 'PATCH_COMPANY_EXTERNAL_ERR'
}

export const initialState: PatchCompanyExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCompanyExternal = (
  state: PatchCompanyExternalStoreState = initialState,
  action: {
    type: string;
    data: PATCH_COMPANY_EXTERNAL;
    error: {};
  }
): PatchCompanyExternalStoreState => {
  switch (action.type) {
    case PATCH_COMPANY_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case PATCH_COMPANY_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };

    case PATCH_COMPANY_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default patchCompanyExternal;
