import { GET_SUBSIDIARIES } from '../reducers/getSubsidiaries';
import { SubsidiariesTypes } from '../types';

export const req = (inn: string) => ({
  type: GET_SUBSIDIARIES.REQ,
  inn
});

export const got = (data: SubsidiariesTypes[]) => ({
  type: GET_SUBSIDIARIES.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_SUBSIDIARIES.ERR,
  err
});
