import { GET_INVESTOR_LIST_BY_SUPPLIER } from '../reducers/getInvestorListBySupplier';

export interface ResponseData {}

export const req = (guid: string) => ({
  type: GET_INVESTOR_LIST_BY_SUPPLIER.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_INVESTOR_LIST_BY_SUPPLIER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INVESTOR_LIST_BY_SUPPLIER.ERR,
  error
});

export const reset = () => ({
  type: GET_INVESTOR_LIST_BY_SUPPLIER.RESET
});
