import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { AvailableLimits as ResponseDataType } from '../actions/checkAvailableLimits';

export interface CheckAvailableLimitsStoreState extends Reducer {
  data: ResponseDataType[];
}

export enum CHECK_AVAILABLE_LIMITS {
  CHECK_AVAILABLE_LIMITS_REQ = 'CHECK_AVAILABLE_LIMITS_REQ',
  CHECK_AVAILABLE_LIMITS_GOT = 'CHECK_AVAILABLE_LIMITS_GOT',
  CHECK_AVAILABLE_LIMITS_ERR = 'CHECK_AVAILABLE_LIMITS_ERR'
}

export const initialState: CheckAvailableLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [{
    inn: null,
    companyName: null,
    hasLimit: false
  }],
  error: {}
};

const checkAvailableLimits = (
  state: CheckAvailableLimitsStoreState = initialState,
  action: {
    type: CHECK_AVAILABLE_LIMITS;
    data: ResponseDataType[];
    error: {};
  }
): CheckAvailableLimitsStoreState => {
  switch (action.type) {
    case CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default checkAvailableLimits;
