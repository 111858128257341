import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Supplier/model/actions/putAddPoaFile';

import { PUT_ADD_POA_FILE } from 'entities/SCF/Supplier/model/reducers/putAddPoaFile';

import SCFApi from 'entities/SCF/Supplier/api';

function* putAddPoaFile(action: { type: PUT_ADD_POA_FILE; data: FormData }) {
  try {
    yield call(SCFApi.putAddPoaFile, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putAddPoaFileSaga() {
  yield takeLatest(PUT_ADD_POA_FILE.REQ, putAddPoaFile);
}
