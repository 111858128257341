import { POST_SEND_COMPANY_TO_SCORING } from '../reducers/postSendCompanyToScoring';

export const req = (inn: string) => ({
  type: POST_SEND_COMPANY_TO_SCORING.REQ,
  inn
});

export const reset = () => ({
  type: POST_SEND_COMPANY_TO_SCORING.RESET
});

export const got = data => ({
  type: POST_SEND_COMPANY_TO_SCORING.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_SEND_COMPANY_TO_SCORING.ERR,
  error
});
