import { GET_COMPANIES_OF_USER } from 'entities/Cabinet/Client/model/reducers/getCompaniesOfUser';

interface Companies {
  companyINN: string;
  companyName: string;
  userId: number;
  comment: string;
  isActive: boolean;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: Companies[];
}

export interface RequestData {
  page?: number;
  pageSize?: number;
  companyInn?: string;
  companyName?: string;
}

export const req = (data: RequestData) => ({
  type: GET_COMPANIES_OF_USER.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_OF_USER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_OF_USER.ERR,
  error
});
