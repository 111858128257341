import { GET_EARLY_PAYMENT } from '../reducers/getEarlyPayment';

interface EarlyPayment {
  id: number;
  guid: string;
  createdDateTime: string;
  modifiedDateTime: null;
  isDeleted: boolean;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  payerInn: string;
  payerName: string;
  payDate: string;
  newTotalSumOnPayday: number;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: string;
  lastBasePaymentDate: string;
  baseDiscountRate: number;
  discountPeriod: number;
  absoluteDiscount: number;
  firstPaymentShare: number;
  payerType: number;
  status: number;
  agreementFileIdentifier: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  debtorSignatureFilename: string;
  factorSignatureFileIdentifier: string;
  factorSignatureFilename: string;
  rejectSubject: null;
  rejectComment: string;
  contractId: null;
  supplierCertificateSerial: string;
  supplierSigneeInn: string;
  supplierSigneePersonInn: string;
  investorCertificateSerial: null;
  investorSigneeInn: string;
  investorSigneePersonInn: string;
  keyRate: number;
  keyRateIncome: number;
  discountingFee: number;
  minFee: number;
  platformFee: number;
  debtorInspectorUserId: null;
  supplyContractGuid: string;
  debtorInspectorComment: string;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: EarlyPayment[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  StartDate?: string;
  EndDate?: string;
  SupplierInn?: string;
  InvestorInn?: string;
  DebtorInn?: string;
  payerType?: number;
}

export const req = (data: RequestData) => ({
  type: GET_EARLY_PAYMENT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_EARLY_PAYMENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EARLY_PAYMENT.ERR,
  error
});
