import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { COMMENT_TYPES, Comment } from 'Comments/types';

export interface PostCommentStoreState extends Reducer {
  data: ResponseDataType;
}

export interface RequestDataType {
  id: number;
  type: COMMENT_TYPES;
  files: any[];
  body: {
    text: string;
  };
}

export type ResponseDataType = Comment;

export enum POST_COMMENT {
  REQ = 'POST_COMMENT_REQUEST',
  GOT = 'POST_COMMENT_GOT',
  ERR = 'POST_COMMENT_ERROR'
}

export const initialState: PostCommentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: undefined,
  error: {}
};

const postComment = (
  state: PostCommentStoreState = initialState,
  action: {
    type: POST_COMMENT;
    data: ResponseDataType;
    error: {};
  }
): PostCommentStoreState => {
  switch (action.type) {
    case POST_COMMENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_COMMENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case POST_COMMENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postComment;
