import { GET_LIST_INTERNAL_AGENTS } from '../reducers/getListInternalAgents';

export interface ResponseData {
  id: number;
  email: string;
  name: string;
  status: null;
}

export const req = () => ({
  type: GET_LIST_INTERNAL_AGENTS.REQ
});

export const got = (data: ResponseData[]) => ({
  type: GET_LIST_INTERNAL_AGENTS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_LIST_INTERNAL_AGENTS.ERR,
  error
});
