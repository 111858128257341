import { POST_DOCUMENT_LIST_BY_APPLICATION_ID } from '../reducers/postDocumentListByApplicationId';

export const req = (guid: string) => ({
  type: POST_DOCUMENT_LIST_BY_APPLICATION_ID.REQ,
  guid
});

export const got = () => ({
  type: POST_DOCUMENT_LIST_BY_APPLICATION_ID.GOT
});

export const err = (error: {}) => ({
  type: POST_DOCUMENT_LIST_BY_APPLICATION_ID.ERR,
  error
});
