import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loader } from 'Layouts/components';

import {
  req as getDiscountingSettings,
  ResponseDataType,
  reset as discountingSettingsReset
} from 'src/features/SCF/actions/getDiscountingSettings';
import {
  req as getDocumentForms,
  RequestData as ReqGetDocumentForms,
  ResponseDataType as DocumentsFormsRes
} from 'entities/SCF/Debtor/model/actions/getDocumentForms';
import { req as getFormFile } from 'entities/SCF/Debtor/model/actions/getFormFile';

import {
  DiscountingSettingsPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  ContractsContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  EditBtn,
  EditBtnText,
  HistoryBtn,
  HistoryBtnText,
  ContractsHeader,
  NullBtn,
  NullBtnText,
  NullBtnHandler,
  DaysContainer,
  DaysItem,
  DaysItemRight,
  DaysItemActive,
  DaysItemRightActive,
  ContractsInfoItemLink,
  FormOfTheAgreement,
  TooltipBoxStyle,
  TDMain,
  DownloadBtn,
  NoData,
  ManualDiscountBlock,
  LinkedToKS,
  AbsoluteValue
} from './styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import SCFApi from 'src/features/SCF/api';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  discountingSettings: ResponseDataType;
  documents: DocumentsFormsRes[];
}

interface DispatchToProps {
  getDiscountingSettings: () => void;
  discountingSettingsReset: () => void;
  getDocumentForms: (data: ReqGetDocumentForms) => void;
  getFormFile: (guid: string, name: string) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const DiscountingInfoPage: React.FC<Props> = ({
  getDiscountingSettings,
  getDocumentForms,
  getFormFile,
  discountingSettings,
  discountingSettingsReset,
  documents,
  status,
  history
}) => {
  React.useEffect(() => {
    getDiscountingSettings();
    discountingSettingsReset();
    getDocumentForms({ type: 1 });
  }, []);

  const onEditClick = () => {
    history.push(`/debtor/settings/discounting/edit`);
  };

  const onRegistriesSettingsClick = () => {
    history.push(`/debtor/settings/debtor_registry_settings`);
  };

  const onHistoryClick = () => {
    history.push(`/debtor/settings/discounting/history`);
  };

  return (
    <DiscountingSettingsPageStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.ERROR && (
        <ContractsContainer>
          <ContractsHeader>
            <h2>Политика дисконтирования</h2>
            <EditBtn onClick={onEditClick}>
              <EditBtnText>Редактировать</EditBtnText>
            </EditBtn>
            <HistoryBtn onClick={onHistoryClick}>
              <HistoryBtnText>История изменений</HistoryBtnText>
            </HistoryBtn>
          </ContractsHeader>
          <ContractsInfoArea>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Дисконтирование (ранняя оплата):
              </ContractsInfoItemTitle>
              <TypeContainer>
                <TypeNoActive>
                  <p>ВЫКЛ</p>
                </TypeNoActive>
                <TypeYesInactive>
                  <p>ВКЛ</p>
                </TypeYesInactive>
              </TypeContainer>
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
        </ContractsContainer>
      )}
      {status === REQUEST_STATUSES.GOT && (
        <React.Fragment>
          <ContractsContainer>
            <ContractsHeader>
              <h1>Политика дисконтирования</h1>
              <EditBtn onClick={onEditClick}>
                <EditBtnText>Редактировать</EditBtnText>
              </EditBtn>
              <HistoryBtn onClick={onHistoryClick}>
                <HistoryBtnText>История изменений</HistoryBtnText>
              </HistoryBtn>
            </ContractsHeader>
            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Дисконтирование (ранняя оплата):
                </ContractsInfoItemTitle>
                {discountingSettings.enableDiscounting == true ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            {discountingSettings.enableDiscounting == true ? (
              <ContractsInfoArea>
                <h1>Установление максимального размера лимита:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountLimitPolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Вручную</p>
                  {(discountingSettings.discountLimitPolicy == 1) == true ? (
                    <input
                      className="contract-input"
                      disabled={true}
                      value={discountingSettings.manualMaxLimitSize.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.discountLimitPolicy == 1 ? (
                    <p style={{ marginLeft: '10px' }}>руб.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountLimitPolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Остаток на счетах</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer
                  style={{ paddingTop: '10px', marginLeft: '50px' }}
                >
                  {discountingSettings.autoLimitPolicy == 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Уменьшать на фиксированную сумму</p>
                  {(discountingSettings.autoLimitPolicy == 1) == true ? (
                    <input
                      className="contract-input"
                      disabled={true}
                      value={discountingSettings.fixedSubstractionSum.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.autoLimitPolicy == 1 ? (
                    <p style={{ marginLeft: '10px' }}>руб.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer
                  style={{ paddingTop: '10px', marginLeft: '50px' }}
                >
                  {discountingSettings.autoLimitPolicy == 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <p>Уменьшать на прогноз оплат за</p>
                  {(discountingSettings.autoLimitPolicy == 2) == true ? (
                    <input
                      className="contract-input input-short"
                      disabled={true}
                      value={discountingSettings.substractionPeriodInDays}
                    />
                  ) : (
                    <p />
                  )}
                  {discountingSettings.autoLimitPolicy == 2 ? (
                    <p style={{ marginLeft: '10px' }}>дн.</p>
                  ) : (
                    <p />
                  )}
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer
                  style={{ marginTop: '20px', marginLeft: '30px' }}
                >
                  <ContractsInfoItemTitle>
                    Итоговый лимит:
                  </ContractsInfoItemTitle>
                  {discountingSettings.manualMaxLimitSize > 0 ? (
                    <p style={{ width: '150px', textAlign: 'end' }}>
                      {discountingSettings.manualMaxLimitSize.toLocaleString(
                        'ru',
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      )}
                    </p>
                  ) : (
                    <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                  )}

                  <p>руб.</p>
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                  <ContractsInfoItemTitle>Использовано:</ContractsInfoItemTitle>
                  {discountingSettings.limitUsage > 0 ? (
                    <p style={{ width: '150px', textAlign: 'end' }}>
                      {discountingSettings.limitUsage.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </p>
                  ) : (
                    <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                  )}
                  <p>руб.</p>
                  {discountingSettings.limitUsage > 0 ? (
                    <NullBtn onClick={() => SCFApi.postReleaseLimit()}>
                      <NullBtnHandler onClick={getDiscountingSettings}>
                        <NullBtnText>Обнулить</NullBtnText>
                      </NullBtnHandler>
                    </NullBtn>
                  ) : (
                    <NullBtn
                      style={{
                        backgroundColor: '#9FA6B2',
                        cursor: 'not-allowed'
                      }}
                    >
                      <NullBtnText>Обнулить</NullBtnText>
                    </NullBtn>
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                  <ContractsInfoItemTitle>
                    Остаток лимита:
                  </ContractsInfoItemTitle>
                  <p style={{ width: '150px', textAlign: 'end' }}>
                    {(
                      discountingSettings.manualMaxLimitSize -
                      discountingSettings.limitUsage
                    ).toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </p>
                  <p>руб.</p>
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}
            {discountingSettings.enableDiscounting == true ? (
              <ContractsInfoArea>
                <h1>Размер дисконта:</h1>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountRatePolicy === 1 ? (
                    <ManualDiscountBlock>
                      <div>
                        <SelectContainerActive>
                          <SelectActive />
                        </SelectContainerActive>
                        <p>Вручную</p>
                      </div>

                      <div>
                        <AbsoluteValue>
                          {discountingSettings.rateIsSurplusToKeyRate ===
                          false ? (
                            <>
                              <SelectContainerActive>
                                <SelectActive />
                              </SelectContainerActive>
                              <p>Абсолютное значение</p>
                              <input
                                className="contract-input input-short"
                                disabled={true}
                                value={discountingSettings.discountRate}
                              />
                              <p style={{ marginLeft: '10px' }}>% годовых</p>
                            </>
                          ) : (
                            <>
                              <SelectContainerInactive>
                                <SelectInactive />
                              </SelectContainerInactive>
                              <p>Абсолютное значение</p>
                            </>
                          )}
                        </AbsoluteValue>

                        <LinkedToKS>
                          {discountingSettings.rateIsSurplusToKeyRate ===
                          true ? (
                            <>
                              <SelectContainerActive>
                                <SelectActive />
                              </SelectContainerActive>
                              <p>Привязаное к КС</p>
                              <div>
                                <p>Ключ +</p>
                                <input
                                  className="contract-input input-short"
                                  disabled={true}
                                  value={discountingSettings.discountRate}
                                />
                                <p style={{ marginLeft: '10px' }}>% годовых</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <SelectContainerInactive>
                                <SelectInactive />
                              </SelectContainerInactive>
                              <p>Привязаное к КС</p>
                            </>
                          )}
                        </LinkedToKS>
                      </div>
                    </ManualDiscountBlock>
                  ) : (
                    <>
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                      <p>Вручную</p>
                    </>
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.discountRatePolicy === 2 ? (
                    <>
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                      <p>Эквивалентен лучшему предложению, но не ниже</p>
                      <input
                        className="contract-input input-short"
                        disabled={true}
                        value={discountingSettings.minimalDiscount}
                      />
                      <p style={{ marginLeft: '10px' }}>% годовых</p>
                    </>
                  ) : (
                    <>
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                      <p>Эквивалентен лучшему предложению, но не ниже</p>
                    </>
                  )}
                </ContractsInfoItemContainer>
                <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                  {discountingSettings.autoRateSubstraction > 0 ? (
                    <>
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                      <p>Уменьшать на фиксированную долю</p>
                      <input
                        className="contract-input input-short"
                        disabled={true}
                        value={discountingSettings.autoRateSubstraction}
                      />
                      <p style={{ marginLeft: '10px' }}>% годовых</p>
                    </>
                  ) : (
                    <>
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                      <p>Уменьшать на фиксированную долю</p>
                    </>
                  )}
                </ContractsInfoItemContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}
            {discountingSettings.enableDiscounting == true &&
            discountingSettings.paydays ? (
              <ContractsInfoArea>
                <h1>Платежные дни</h1>
                <DaysContainer>
                  {discountingSettings.paydays.charAt(0) == '1' ? (
                    <DaysItemActive>
                      <p>Пн</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Пн</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(1) == '1' ? (
                    <DaysItemActive>
                      <p>Вт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Вт</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(2) == '1' ? (
                    <DaysItemActive>
                      <p>Ср</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Ср</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(3) == '1' ? (
                    <DaysItemActive>
                      <p>Чт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Чт</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(4) == '1' ? (
                    <DaysItemActive>
                      <p>Пт</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Пт</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(5) == '1' ? (
                    <DaysItemActive>
                      <p>Сб</p>
                    </DaysItemActive>
                  ) : (
                    <DaysItem>
                      <p>Сб</p>
                    </DaysItem>
                  )}
                  {discountingSettings.paydays.charAt(6) == '1' ? (
                    <DaysItemRightActive>
                      <p>Вс</p>
                    </DaysItemRightActive>
                  ) : (
                    <DaysItemRight>
                      <p>Вс</p>
                    </DaysItemRight>
                  )}
                </DaysContainer>
              </ContractsInfoArea>
            ) : (
              ''
            )}

            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <p>Дата оплаты не раньше, чем через</p>
                <input
                  className="contract-input input-short"
                  disabled={true}
                  value={discountingSettings.paydayOffset}
                />
                <p style={{ marginLeft: 10 }}>дн.</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>

            {discountingSettings.enableDiscounting &&
            discountingSettings.suppliesDataSourcePolicy ? (
              <ContractsInfoArea>
                <h1>Источник данных о поставках:</h1>
                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy === 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Загрузка реестров поставки</p>
                    <ContractsInfoItemLink onClick={onRegistriesSettingsClick}>
                      Настройки файла реестра
                    </ContractsInfoItemLink>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}

                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy !== 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Интеграция баз данных</p>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}

                {discountingSettings.suppliesDataSourcePolicy ? (
                  <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                    {discountingSettings.suppliesDataSourcePolicy !== 1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <p>Ручной ввод поставщиком</p>
                  </ContractsInfoItemContainer>
                ) : (
                  ''
                )}
              </ContractsInfoArea>
            ) : (
              ''
            )}
          </ContractsContainer>

          <ContractsInfoArea>
            <FormOfTheAgreement>
              <h1>Формы соглашений для сделок дисконтирования:</h1>

              {!!documents.length ? (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="5%">ID</TableThStyled>
                      <TableThStyled width="10%">Наименование</TableThStyled>
                      <TableThStyled width="5%">Основная</TableThStyled>
                      <TableThStyled width="20%" />
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {documents.map(doc => (
                      <TableRowStyled key={doc.guid}>
                        <td>{doc.guid.slice(-7)}</td>
                        <td>{doc.name}</td>
                        <TDMain>
                          {doc.isMain && (
                            <TooltipBoxStyle
                              textVerify={
                                'Используется по умолчанию, если для договора поставки не установлена форма'
                              }
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </TooltipBoxStyle>
                          )}
                        </TDMain>
                        <td>
                          <DownloadBtn
                            onClick={() =>
                              getFormFile(doc.guid, doc.exampleFilename)
                            }
                          >
                            Скачать
                          </DownloadBtn>
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              ) : (
                <NoData>
                  <h2>
                    ОБРАТИТЕСЬ К КУРАТОРУ <br />
                    ДЛЯ ФОРМИРОВАНИЯ ФОРМЫ СОГЛАШЕНИЯ
                  </h2>
                </NoData>
              )}
            </FormOfTheAgreement>
          </ContractsInfoArea>

          <ContractsInfoArea>
            <FormOfTheAgreement>
              <h1 style={{ marginBottom: '20px' }}>Подписание документов:</h1>

              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Формировать протоколы:
                </ContractsInfoItemTitle>

                {discountingSettings.protocolsPolicy == true ? (
                  <TypeContainer>
                    <TypeNoInactive>
                      <p>ВЫКЛ</p>
                    </TypeNoInactive>
                    <TypeYesActive>
                      <p>ВКЛ</p>
                    </TypeYesActive>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoActive>
                      <p>ВЫКЛ</p>
                    </TypeNoActive>
                    <TypeYesInactive>
                      <p>ВКЛ</p>
                    </TypeYesInactive>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </FormOfTheAgreement>
          </ContractsInfoArea>
        </React.Fragment>
      )}
    </DiscountingSettingsPageStyled>
  );
};

const mapStateToProps = ({ SCF, SCFDebtor }: STORE) => ({
  discountingSettings: SCF.getDiscountingSettings.data,
  status: SCF.getDiscountingSettings.status,
  error: SCF.getDiscountingSettings.error,
  documents: SCFDebtor.getDocumentForms.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDiscountingSettings,
      discountingSettingsReset,
      getDocumentForms,
      getFormFile
    },
    dispatch
  );

const DiscountingInfoPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DiscountingInfoPage)
);

export { DiscountingInfoPageConnect as DiscountingInfoPage };
