import { POST_DOCUMENTS_OF_DOCUMENT_LIST } from '../reducers/postDocumentsOfDocumentList';

export const req = (documentListGuid: string) => ({
  type: POST_DOCUMENTS_OF_DOCUMENT_LIST.REQ,
  documentListGuid
});

export const got = () => ({
  type: POST_DOCUMENTS_OF_DOCUMENT_LIST.GOT
});

export const err = (error: {}) => ({
  type: POST_DOCUMENTS_OF_DOCUMENT_LIST.ERR,
  error
});
