import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileArchive,
  faFile,
  faPlus,
  faMinus,
  faCaretDown,
  faCaretUp,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { Icon } from 'shared/ui/Icon';
import SignatureImg from 'src/assets/icons/signature.svg';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getFinancialDocuments';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader
} from 'src/features/Layouts/components';
import {
  FileContainer,
  FinancialDocumentsContainer,
  QuarterHead,
  TooltipBoxStyle,
  Year
} from './styled';

interface StateToProps {
  fin_documents: ResponseData[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {}

interface QuartersType {
  guid: string;
  signatureFileName: string;
  taxYear: number;
  taxPeriod: string;
  documentName: string;
  documentFileName: string;
  periodStartDate: string;
  periodEndDate: string;
}

interface FiltredData {
  taxYear: number;
  quarters: {
    [key: number]: QuartersType[];
  };
}

type Props = StateToProps & DispatchToProps;

const Documents: React.FC<Props> = ({ fin_documents, status, error }) => {
  const [filtredData, setFiltredData] = React.useState<FiltredData[]>([]);
  const [tabVisibilityYear, setTabVisibilityYear] = React.useState({});
  const [tabVisibilityQuarters, setTabVisibilityQuarters] = React.useState({});

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        const result = {} as FiltredData[];

        fin_documents.forEach(item => {
          const {
            periodStartDate,
            periodEndDate,
            guid,
            signatureFileName,
            taxYear,
            taxPeriodId,
            taxPeriod,
            documentName,
            documentFileName
          } = item;

          if (!taxYear) return;

          let quarter;
          if (
            taxPeriodId === '21' ||
            taxPeriodId === '25' ||
            taxPeriodId === '51'
          ) {
            quarter = '1';
          } else if (
            taxPeriodId === '31' ||
            taxPeriodId === '22' ||
            taxPeriodId === '26' ||
            taxPeriodId === '52'
          ) {
            quarter = '2';
          } else if (
            taxPeriodId === '33' ||
            taxPeriodId === '23' ||
            taxPeriodId === '27' ||
            taxPeriodId === '53'
          ) {
            quarter = '3';
          } else if (taxPeriodId === '34' || taxPeriodId === '24') {
            quarter = '4';
          }

          if (quarter) {
            if (!result[taxYear]) {
              result[taxYear] = {
                taxYear,
                quarters: {}
              };
            }

            if (!result[taxYear].quarters[quarter]) {
              result[taxYear].quarters[quarter] = [];
            }

            result[taxYear].quarters[quarter].push({
              guid,
              signatureFileName,
              taxYear,
              taxPeriod,
              documentName,
              documentFileName,
              periodStartDate,
              periodEndDate
            });
          }

          if (!!periodStartDate && !!periodEndDate) {
            const startDateYear = new Date(periodStartDate).getFullYear();
            const endDateYear = new Date(periodEndDate).getFullYear();

            if (!isNaN(startDateYear) && !isNaN(endDateYear)) {
              for (let year = startDateYear; year <= endDateYear; year++) {
                if (!result[year]) {
                  result[year] = {
                    taxYear: year,
                    quarters: {
                      '5': [
                        {
                          guid,
                          signatureFileName,
                          taxYear: year,
                          taxPeriod,
                          documentName,
                          documentFileName,
                          periodStartDate,
                          periodEndDate
                        }
                      ]
                    }
                  };
                } else {
                  if (!result[year].quarters['5']) {
                    result[year].quarters['5'] = [];
                  }
                  result[year].quarters['5'].push({
                    guid,
                    signatureFileName,
                    taxYear: year,
                    taxPeriod,
                    documentName,
                    documentFileName,
                    periodStartDate,
                    periodEndDate
                  });
                }
              }
            }
          }
        });

        setFiltredData(
          Object.values(result).sort((a, b) => b.taxYear - a.taxYear)
        );

        const initialTabVisibility = {};

        const currentYear = new Date().getFullYear();

        Object.values(result).forEach(item => {
          if (
            currentYear === item.taxYear ||
            currentYear - 1 === item.taxYear
          ) {
            initialTabVisibility[item.taxYear] = true;
          }
        });

        setTabVisibilityYear(initialTabVisibility);

        const initialQuarterVisibility = {};

        Object.values(result).forEach(item => {
          const quarters = item.quarters;
          initialQuarterVisibility[item.taxYear] = {};
          for (const quarter in quarters) {
            initialQuarterVisibility[item.taxYear][quarter] = true;
          }
        });

        setTabVisibilityQuarters(initialQuarterVisibility);
      }
    },
    [fin_documents, status]
  );

  const getIconByFileType = (fileName: string, guid: string) => {
    if (!fileName) return;

    const matchResult = fileName.match(/\.(\w+)$/);
    if (matchResult) {
      const fileExtension = matchResult[1];
      if (fileExtension === 'doc' || fileExtension === 'docx') {
        return (
          <FontAwesomeIcon
            icon={faFileWord}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'pdf') {
        return (
          <FontAwesomeIcon
            icon={faFilePdf}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        return (
          <FontAwesomeIcon
            icon={faFileExcel}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else if (fileExtension === 'zip' || fileExtension === 'rar') {
        return (
          <FontAwesomeIcon
            icon={faFileArchive}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faFile}
            size="lg"
            // onClick={() => getDownloadDocumets(guid)}
          />
        );
      }
    }
    return null;
  };

  const reverseQuarter = (arr: string[]) => {
    return arr.sort((a, b) => {
      if (a === '5') return 1;
      if (b === '5') return -1;
      return +b - +a;
    });
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <FinancialDocumentsContainer>
          {filtredData.map(item => (
            <div key={item.taxYear}>
              <Year>
                <p>{item.taxYear}</p>
                <FontAwesomeIcon
                  icon={tabVisibilityYear[item.taxYear] ? faMinus : faPlus}
                  onClick={() =>
                    setTabVisibilityYear(prevState => ({
                      ...prevState,
                      [item.taxYear]: !prevState[item.taxYear]
                    }))
                  }
                />
              </Year>

              {tabVisibilityYear[item.taxYear] &&
                reverseQuarter(Object.keys(item.quarters)).map(
                  (quarter, index) => (
                    <React.Fragment key={index}>
                      <QuarterHead>
                        <p>
                          {quarter === '5' ? 'Прочие' : `${quarter} квартал`}
                        </p>
                        <FontAwesomeIcon
                          icon={
                            tabVisibilityQuarters[item.taxYear][quarter]
                              ? faCaretUp
                              : faCaretDown
                          }
                          onClick={() =>
                            setTabVisibilityQuarters(prevState => ({
                              ...prevState,
                              [item.taxYear]: {
                                ...prevState[item.taxYear],
                                [quarter]: !prevState[item.taxYear][quarter]
                              }
                            }))
                          }
                        />
                      </QuarterHead>
                      {tabVisibilityQuarters[item.taxYear][quarter] && (
                        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                          <TableHeaderStyled>
                            <tr>
                              <TableThStyled width="10%">
                                Наименование файла
                              </TableThStyled>
                              <TableThStyled width="10%">
                                Тип документа
                              </TableThStyled>
                              <TableThStyled width="10%">Период</TableThStyled>
                            </tr>
                          </TableHeaderStyled>
                          <tbody>
                            <>
                              {item.quarters[quarter].map(
                                (quarterItem: QuartersType) => (
                                  <TableRowStyled key={quarterItem.guid}>
                                    <td>
                                      <FileContainer>
                                        {quarterItem.documentFileName}
                                        <div>
                                          {getIconByFileType(
                                            quarterItem.documentFileName,
                                            quarterItem.guid
                                          )}
                                          {quarterItem.signatureFileName ===
                                          null ? (
                                            <TooltipBoxStyle text="Загрузите файл документа">
                                              <FontAwesomeIcon
                                                icon={faUpload}
                                                width={20}
                                              />
                                            </TooltipBoxStyle>
                                          ) : (
                                            quarterItem.signatureFileName &&
                                            !!quarterItem.signatureFileName
                                              .length && (
                                              <Icon
                                                iconLink={SignatureImg}
                                                width={20}
                                                // onClick={() =>
                                                //   getDownloadSignature(
                                                //     quarterItem.guid
                                                //   )
                                                // }
                                              />
                                            )
                                          )}
                                        </div>
                                      </FileContainer>
                                    </td>
                                    <td>{quarterItem.documentName}</td>
                                    <td>
                                      {quarterItem.taxYear &&
                                      quarterItem.taxPeriod
                                        ? `${quarterItem.taxYear}, ${
                                            quarterItem.taxPeriod
                                          }`
                                        : `${new Date(
                                            quarterItem.periodStartDate
                                          ).toLocaleDateString()} - ${new Date(
                                            quarterItem.periodEndDate
                                          ).toLocaleDateString()}`}
                                    </td>
                                  </TableRowStyled>
                                )
                              )}
                            </>
                          </tbody>
                        </TableStyled>
                      )}
                    </React.Fragment>
                  )
                )}
            </div>
          ))}
        </FinancialDocumentsContainer>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({  }: CRM) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const DocumentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);

export { DocumentsConnect as Documents };
