import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postAllowProcess';

import { POST_USER_ALLOW_PROCESS } from '../reducers/postAllowProcess';
import UserApi from '../api';

function* postAllowProcess(action: {
  type: POST_USER_ALLOW_PROCESS;
  id: string;
  typeProcess: string;
}): Generator {
  try {
    const res: any = yield call(
      UserApi.postAllowProcess,
      action.id,
      action.typeProcess
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAllowProcessSaga(): Generator {
  yield takeLatest(POST_USER_ALLOW_PROCESS.REQ, postAllowProcess);
}
