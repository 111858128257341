import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';

import { BoxStyled, IconWrapper } from 'shared/styled';

export const UnconfirmedEmailError: React.FC = () => (
  <React.Fragment>
    <h1>Вы еще не подтвердили email</h1>
    <IconWrapper>
      <FontAwesomeIcon icon={faEnvelopeOpen} />
    </IconWrapper>
    <BoxStyled>
      <p>
        При регистрации вам было направлено письмо для подтверждения электронной
        почты.
        <br />
        Пройдите по ссылке в письме для подтверждения аккаунта.
      </p>
    </BoxStyled>
  </React.Fragment>
);
