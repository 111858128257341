import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'src/features/Application/actions/getCompanyByInn';

export interface GetCompanyByInnApplicationStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_COMPANY_BY_INN_APPLICATION {
  REQ = 'GET_COMPANY_BY_INN_APPLICATION_REQ',
  GOT = 'GET_COMPANY_BY_INN_APPLICATION_GOT',
  ERR = 'GET_COMPANY_BY_INN_APPLICATION_ERR',
  RESET = 'GET_COMPANY_BY_INN_APPLICATION_RESET'
}

export const initialState: GetCompanyByInnApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    inn: '',
    companyShortName: '',
    companyFullName: '',
    ogrn: '',
    regDate: null,
    kpp: '',
    pfr: '',
    fss: '',
    startCapital: null,
    companyAddress: '',
    okvedCode: '',
    okvedName: '',
    okvedPic: '',
    customer223: false,
    customer44: false,
    debtor: false,
    limits: false,
    employees: null,
    employeesYear: null,
    TaxSystem: null,
    taxSystemCode: null,
    taxSystemName: '',
    taxes: {
      taxYear: null,
      taxInfo: []
    }
  },
  error: {}
};

const getCompanyByInnApplication = (
  state: GetCompanyByInnApplicationStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN_APPLICATION;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyByInnApplicationStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_COMPANY_BY_INN_APPLICATION.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInnApplication;
