import {
  RequestDataType,
  POST_SUPPLIER_NOTIFICATION_SETTINGS
} from 'entities/SCF/Supplier/model/reducers/postSupplierNotificationSettings';

export const req = (data: RequestDataType) => ({
  type: POST_SUPPLIER_NOTIFICATION_SETTINGS.REQ,
  data
});

export const got = () => ({
  type: POST_SUPPLIER_NOTIFICATION_SETTINGS.GOT
});

export const err = (error: {}) => ({
  type: POST_SUPPLIER_NOTIFICATION_SETTINGS.ERR,
  error
});

export const reset = () => ({
  type: POST_SUPPLIER_NOTIFICATION_SETTINGS.RESET
});
