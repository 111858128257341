import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCompaniesList';
import { GET_COMPANIES_LIST_CRM } from '../reducers/getCompaniesList';
import CompaniesApi from '../api';

function* getCompaniesListCRM(action: {
  type: GET_COMPANIES_LIST_CRM;
  data: RequestData;
}): Generator {
  try {
    let res;
    res = yield call(CompaniesApi.getCompaniesList, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesListCRMSaga(): Generator {
  yield takeLatest(GET_COMPANIES_LIST_CRM.REQ, getCompaniesListCRM);
}
