import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { APPLICATION_TYPES, CreditingApplicationRead } from 'Application/types';

import { ViewSeparatorBottom } from 'Application/components/ApplicationView/EachApplicationView/styles';
import { STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { IApplicationDocuments } from '../EachApplicationView';
import { BorrowerCompanyDocumentsBlock } from './BorrowerCompanyDocumentsBlock/BorrowerCompanyDocumentsBlock';
import { CompanyDocumentsBlock } from './CompanyDocumentsBlock/CompanyDocumentsBlock';
import { GeneralDocumentsBlock } from './GeneralDocumentsBlock/GeneralDocumentsBlock';

interface StateToProps {
  clientApplication: Partial<CreditingApplicationRead>;
}

interface OwnProps {
  permissions: USER_PERMISSIONS[];
  documentList: ResDocumentListByApplicationId;
}

type Props = RouteComponentProps &
  StateToProps &
  IApplicationDocuments &
  OwnProps;

const DocumentsBlock: React.FC<Props> = ({
  clientApplication,
  documents,
  permissions,
  documentList,
  history
}) => (
  <React.Fragment>
    <GeneralDocumentsBlock documents={documents.general} />

    {/* Factoring Documents - Table */}
    {clientApplication.financingType === APPLICATION_TYPES.FACTORING && (
      <React.Fragment>
        <ViewSeparatorBottom />

        {/* TODO refactor whole eachApplication block
              * documents.company in this case is application documents
            */}
        <CompanyDocumentsBlock
          documents={documents.company}
          applicationId={clientApplication.id}
        />
      </React.Fragment>
    )}

    {/* Other Documents - Table in Tabs */}
    {/* TODO refactor */}
    {![
      APPLICATION_TYPES.FACTORING,
      APPLICATION_TYPES.PROPERTY_FINANCING
    ].includes(clientApplication.financingType) && (
      <BorrowerCompanyDocumentsBlock
        companies={documents.company}
        permissions={permissions}
        documentList={documentList}
      />
    )}
  </React.Fragment>
);

const mapStateToProps = ({ ClientRole }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data
});

const DocumentsBlockConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(DocumentsBlock)
);

export { DocumentsBlockConnect as DocumentsBlock };
