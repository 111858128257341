import styled, { css, createGlobalStyle } from 'styled-components';

import {
  HeaderHeight,
  SidebarWidth,
  lightGray,
  gray,
  blue,
  rr,
  lighterBlue,
  navyBlue,
  bgColor,
  black
} from 'shared/styled';

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576
};

// Iterate through the sizes and create a media template
export const media: any = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const Main = createGlobalStyle`
  #root {
    height: 100vh;
    position: relative;
  }

  html {
    font-size: 10px;
    box-sizing: border-box;
    
    *, *::before, *::after {
      box-sizing: inherit;
    }
  }

  body {
    min-width: 1400px;

    font-size: 1.4rem;
    background-color: ${bgColor};
    color: ${black};
  }
  
  h1,h2,h3,h4 { 
    margin: 0;
    font-family: ${rr};
    color: ${navyBlue};
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.4rem;
    font-family: ${rr};
    color: ${navyBlue};
  }

  button {
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const PageWrapper = styled.section`
  width: 80%;
  max-width: 1200px;
  min-height: 100%;

  padding: 50px 40px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    width: 100%;
    margin: 0;
    padding: 30px 20px;
  `};

  ${media.phone`
    padding: 15px;
  `};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;

  background-color: rgba(255, 255, 255, 0.8);
`;

export const BoxStyled = styled.div`
  width: ${({ width }) => (!!width ? `${width}px` : '100%')};
  padding: ${({ padding }) => (!!padding ? `${padding}` : '40px 60px')};
  background-color: #ffffff;
  ${({ margin }) =>
    !!margin
      ? `margin:${margin};`
      : ''} box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
`;

export const IconWrapper = styled.div`
  margin-bottom: 45px;

  svg {
    font-size: 10rem;
    color: rgb(217, 220, 224);
  }
`;

export const CenteringBlock = styled.div`
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const CabinetSection = styled.main`
  width: calc(100vw - ${SidebarWidth});
  margin: ${HeaderHeight} 0 0 ${SidebarWidth};
  padding: 20px 20px 60px;
  background-color: #fff;
`;

export const LighterBlueContainer = styled.div`
  background-color: ${lighterBlue};
  margin-top: 15px;
  padding: 15px;
  & > * {
    margin-bottom: 20px;
  }
`;

export const CrossBtn = styled.button.attrs({
  type: 'button',
  ['aria-label']: 'close-or-delete-button'
})`
  width: 10px;
  height: 10px;

  padding: 0;
  opacity: inherit;

  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="${gray}" d="M14.045 12l5.483 5.483c.224.224.354.437.422.658.067.222.067.449 0 .67-.068.222-.198.435-.422.659l-.058.058c-.224.224-.437.354-.659.422a1.139 1.139 0 0 1-.67 0c-.221-.068-.434-.198-.658-.422L12 14.045l-5.483 5.483c-.224.224-.437.354-.658.422a1.139 1.139 0 0 1-.67 0c-.222-.068-.435-.198-.659-.422l-.058-.058c-.224-.224-.354-.437-.422-.659a1.139 1.139 0 0 1 0-.67c.068-.221.198-.434.422-.658L9.955 12 4.472 6.517c-.224-.224-.354-.437-.422-.658a1.139 1.139 0 0 1 0-.67c.068-.222.198-.435.422-.659l.058-.058c.224-.224.437-.354.659-.422.221-.067.448-.067.67 0 .221.068.434.198.658.422L12 9.955l5.483-5.483c.224-.224.437-.354.658-.422.222-.067.449-.067.67 0 .222.068.435.198.659.422l.058.058c.224.224.354.437.422.659.067.221.067.448 0 .67-.068.221-.198.434-.422.658L14.045 12z"/>
    </svg>
  ');
`;

export const CrossCircleBtn = styled(CrossBtn)`
  width: 20px;
  height: 20px;

  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 125 125">
      <path fill="${({ color = gray }) =>
        color}" d="M62.5,19.5c-23.7,0-43,19.3-43,43s19.3,43,43,43s43-19.3,43-43S86.2,19.5,62.5,19.5z M78.8,74.5c1.1,1.2,1.1,3.1,0,4.2  c-0.5,0.6-1.3,0.9-2.1,0.9s-1.5-0.3-2.1-0.9l-12-12l-12,12c-0.5,0.6-1.3,0.9-2.1,0.9c-1.7,0-3-1.3-3-3c0-0.8,0.3-1.5,0.9-2.1l12-12  l-12-12c-1.2-1.2-1.1-3.1,0.1-4.2c1.2-1.1,2.9-1.1,4.1,0l12,12l12-12c1.2-1.2,3.1-1.1,4.2,0.1c1.1,1.2,1.1,2.9,0,4.1l-12,12  L78.8,74.5z"/>
    </svg>
  ');
`;

export const AttachFileButton = styled.button.attrs(({ title }) => ({
  alt: title,
  type: 'button',
  ['aria-label']: 'attach-document'
}))`
  width: ${({ size = 30 }) => `${size}px`};
  height: ${({ size = 30 }) => `${size}px`};

  cursor: pointer;
  outline: none;
  padding: 0;

  border: none;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" x="0px" y="0px">
      <path fill="${gray}" d="M19.0710678,4.92893219 C21.0236893,6.88155365 21.0236893,10.0473785 19.0710678,12 L9.87867966,21.1923882 L9.17157288,20.4852814 L18.363961,11.2928932 C19.9260582,9.73079605 19.9260582,7.19813614 18.363961,5.63603897 C16.8018639,4.0739418 14.2692039,4.0739418 12.7071068,5.63603897 L4.5753788,13.767767 C3.59906807,14.7440777 3.59906807,16.3269901 4.5753788,17.3033009 C5.55168953,18.2796116 7.13460197,18.2796116 8.1109127,17.3033009 L16.2426407,9.17157288 C16.633165,8.78104858 16.633165,8.1478836 16.2426407,7.75735931 C15.8521164,7.36683502 15.2189514,7.36683502 14.8284271,7.75735931 L7.75735931,14.8284271 L7.05025253,14.1213203 L14.1213203,7.05025253 C14.9023689,6.26920395 16.1686989,6.26920395 16.9497475,7.05025253 C17.7307961,7.83130112 17.7307961,9.09763107 16.9497475,9.87867966 L8.81801948,18.0104076 C7.45118446,19.3772427 5.23510704,19.3772427 3.86827202,18.0104076 C2.501437,16.6435726 2.501437,14.4274952 3.86827202,13.0606602 L12,4.92893219 C13.9526215,2.97631073 17.1184464,2.97631073 19.0710678,4.92893219 Z"/>
    </svg>
  ');

  &:disabled {
    cursor: not-allowed;
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" x="0px" y="0px">
        <path fill="${lightGray}" d="M19.0710678,4.92893219 C21.0236893,6.88155365 21.0236893,10.0473785 19.0710678,12 L9.87867966,21.1923882 L9.17157288,20.4852814 L18.363961,11.2928932 C19.9260582,9.73079605 19.9260582,7.19813614 18.363961,5.63603897 C16.8018639,4.0739418 14.2692039,4.0739418 12.7071068,5.63603897 L4.5753788,13.767767 C3.59906807,14.7440777 3.59906807,16.3269901 4.5753788,17.3033009 C5.55168953,18.2796116 7.13460197,18.2796116 8.1109127,17.3033009 L16.2426407,9.17157288 C16.633165,8.78104858 16.633165,8.1478836 16.2426407,7.75735931 C15.8521164,7.36683502 15.2189514,7.36683502 14.8284271,7.75735931 L7.75735931,14.8284271 L7.05025253,14.1213203 L14.1213203,7.05025253 C14.9023689,6.26920395 16.1686989,6.26920395 16.9497475,7.05025253 C17.7307961,7.83130112 17.7307961,9.09763107 16.9497475,9.87867966 L8.81801948,18.0104076 C7.45118446,19.3772427 5.23510704,19.3772427 3.86827202,18.0104076 C2.501437,16.6435726 2.501437,14.4274952 3.86827202,13.0606602 L12,4.92893219 C13.9526215,2.97631073 17.1184464,2.97631073 19.0710678,4.92893219 Z"/>
      </svg>
    ');
  }

`;

export const SendMessageButton = styled.button.attrs(({ title }) => ({
  alt: title,
  type: 'button',
  ['aria-label']: 'send-message'
}))`
  width: ${({ size = 30 }) => `${size}px`};
  height: ${({ size = 30 }) => `${size}px`};

  cursor: pointer;
  outline: none;
  padding: 0;

  border: none;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 128 128">
      <g fill="${gray}">
        <path  d="M48,94.5l-10.2,5.7c-0.5,0.3-1.2-0.1-1.1-0.7l1.8-12.5c0-0.3,0.4-0.5,0.7-0.3l8.8,7.2C48.3,94,48.3,94.3,48,94.5z"/><path d="M123.9,23.8l-0.7,1.2l-48.1,82.6c-0.6,1-1.9,1.2-2.7,0.5L40.7,82.7l0.1-0.8l49.5-37c0.6-0.5,0.8-1.3,0.3-1.9l0,0   c-0.4-0.6-1.3-0.8-1.9-0.4L34.4,77l0,0.2l-2.8,19.2c-0.1,0.7-1,0.7-1.2,0L25,69.7l-4.1-3.4L4.3,52.9C3.2,51.9,3.6,50,5,49.7   l102.5-27.3l13.2-2.9c0.2-0.1,0.4-0.1,0.6-0.1C123.6,19.4,125.1,21.8,123.9,23.8z"/>
      </g>
    </svg>
  ');

  &:disabled {
    cursor: not-allowed;
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 128 128">
        <g fill="${lightGray}">
          <path d="M48,94.5l-10.2,5.7c-0.5,0.3-1.2-0.1-1.1-0.7l1.8-12.5c0-0.3,0.4-0.5,0.7-0.3l8.8,7.2C48.3,94,48.3,94.3,48,94.5z"/><path d="M123.9,23.8l-0.7,1.2l-48.1,82.6c-0.6,1-1.9,1.2-2.7,0.5L40.7,82.7l0.1-0.8l49.5-37c0.6-0.5,0.8-1.3,0.3-1.9l0,0   c-0.4-0.6-1.3-0.8-1.9-0.4L34.4,77l0,0.2l-2.8,19.2c-0.1,0.7-1,0.7-1.2,0L25,69.7l-4.1-3.4L4.3,52.9C3.2,51.9,3.6,50,5,49.7   l102.5-27.3l13.2-2.9c0.2-0.1,0.4-0.1,0.6-0.1C123.6,19.4,125.1,21.8,123.9,23.8z"/>
        </g>
      </svg>
    ');
  }
`;

export const DownloadLink = styled.a.attrs({
  ['aria-label']: 'download-document',
  download: true
})`
  height: 15px;
  width: 15px;

  display: inline-block;

  padding: 0;

  cursor: pointer;
  outline: none;
  border: none;

  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 17">
      <path fill="rgb(63,78,101)" d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z"/>
    </svg>
  ');
`;

export const OpenFileUploaderButton = styled.button.attrs({
  type: 'button'
})`
  
  width: 195px;
  padding: 8px 10px 8px 0;
  
  cursor: pointer;
  outline: none;
  
  font-family: ${rr};
  font-size: 1.2rem;
  text-align: end;
  color: ${blue};

  border-radius: 6px;
  border: 1px solid ${blue};
  background-color: #fff;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 5px 50%;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path fill="none" stroke="${blue}" stroke-linecap="round" stroke-width="2.526" d="M23.399 13.59l-7.405 7.872c-1.325 1.41-1.325 3.694 0 5.103 1.325 1.409 3.474 1.409 4.8 0L30 16.779c2.319-2.466 2.319-6.464 0-8.93-2.32-2.465-6.08-2.465-8.399 0L11.487 18.601c-3.313 3.523-3.313 9.234 0 12.757 3.313 3.523 8.686 3.523 11.999 0l7.114-7.564"/>
    </svg>
  ');


  &:disabled {
    color: ${gray};
    cursor: not-allowed;

    border-color: ${gray};
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path fill="none" stroke="${gray}" stroke-linecap="round" stroke-width="2.526" d="M23.399 13.59l-7.405 7.872c-1.325 1.41-1.325 3.694 0 5.103 1.325 1.409 3.474 1.409 4.8 0L30 16.779c2.319-2.466 2.319-6.464 0-8.93-2.32-2.465-6.08-2.465-8.399 0L11.487 18.601c-3.313 3.523-3.313 9.234 0 12.757 3.313 3.523 8.686 3.523 11.999 0l7.114-7.564"/>
      </svg>
    ');
  }
`;
