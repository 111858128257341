import * as React from 'react';
import { connect } from 'react-redux';

import { STORE, USER_PERMISSIONS } from 'globaltypes';

import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';

import {
  clientBtnConstructor,
  agentBtnConstructor,
  agentAsClientBtnConstructor,
  bankEmplpyeeBtnConstructor
} from './actionBtnConstructors';

import { ActionButtonsBlockStyled } from './styles';
import { externalAgentBtnConstructor } from './actionBtnConstructors/externalAgentBtnConstructor';
import { ActionButton } from './ActionButton/ActionButton';

interface StateToProps {
  internalApplication: ResInternalApplication;
  permissions: USER_PERMISSIONS[];
}

const ActionButtonsBlock: React.FC<StateToProps> = ({
  internalApplication: { isAuthor, status, mainApplicationId },
  permissions
}) => {
  const btnsConstructor = () => {
    let buttons = [];
    if (permissions.includes(USER_PERMISSIONS.BANK_MANAGER)) {
      buttons = buttons.concat(bankEmplpyeeBtnConstructor(status));
    } else if (
      permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) ||
      permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS)
    ) {
      buttons = isAuthor
        ? buttons.concat(agentAsClientBtnConstructor(status, mainApplicationId))
        : buttons.concat(agentBtnConstructor(status, mainApplicationId));
    } else if (
      permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS) &&
      isAuthor
    ) {
      buttons = buttons.concat(clientBtnConstructor(status));
    }

    return buttons;
  };

  const actionButtons = btnsConstructor();

  return (
    actionButtons.length > 0 && (
      <ActionButtonsBlockStyled>
        {actionButtons.map((props, key) => (
          <ActionButton key={key} {...props} />
        ))}
      </ActionButtonsBlockStyled>
    )
  );
};

const mapStateToProps = ({ InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  permissions: User.getUserData.data.permissions
});

const ActionButtonsBlockConnect = connect<StateToProps>(mapStateToProps)(
  ActionButtonsBlock
);

export { ActionButtonsBlockConnect as ActionButtonsBlock };
