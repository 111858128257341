import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from '../actions/createBankAccount';

export default class BankAccountApi {
  static getBankAccountList() {
    return ApiRequest(`/api/bank_account/all`);
  }

  static createBankAccount(data: RequestData) {
    return ApiRequest('/api/bank_account', 'post', data);
  }
}
