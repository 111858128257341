import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';
import { got, err } from 'User/actions/sendResetPasswordLetter';
import { SEND_RESET_PASSWORD_LETTER } from 'User/reducers/sendResetPasswordLetter';

function* sendResetPasswordLetter(action: {
  type: SEND_RESET_PASSWORD_LETTER;
  email: string;
}) {
  try {
    const res:any = yield call(UserApi.sendResetPasswordLetter, action.email);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendResetPasswordLetterSaga() {
  yield takeLatest(SEND_RESET_PASSWORD_LETTER.REQ, sendResetPasswordLetter);
}
