import { GET_APPLICATIONS_COMPANY } from '../reducers/getApplicationsCompany';
import { ApplicationCompanyType } from '../types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalQty: number;
  items: ApplicationCompanyType[];
}

export const req = (inn: string) => ({
  type: GET_APPLICATIONS_COMPANY.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_APPLICATIONS_COMPANY.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATIONS_COMPANY.ERR,
  error
});
