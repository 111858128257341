import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getPledge';

export interface GetPledgesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_PLEDGES_BANK {
  REQ = 'GET_PLEDGES_BANK_REQ',
  GOT = 'GET_PLEDGES_BANK_GOT',
  ERR = 'GET_PLEDGES_BANK_ERR'
}

export const initialState: GetPledgesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getPledges = (
  state: GetPledgesStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetPledgesStoreState => {
  switch (action.type) {
    case GET_PLEDGES_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PLEDGES_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PLEDGES_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPledges;
