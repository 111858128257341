import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Business/actions/businessStatus';
import { BUSINESS_STATUS_ACTIONS } from 'Business/reducers/businessStatus';
import BusinessApi from 'Business/api';
import {
  ResponseData
} from 'Business/reducers/businessStatus';

function* checkBusinessStatus(): Generator {
  try {
    const res:any = yield call(BusinessApi.getStatus);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* checkBusinessStatusSaga(): Generator {
  yield takeLatest(BUSINESS_STATUS_ACTIONS.REQ, checkBusinessStatus);
}
