import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';

import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Button } from 'shared/ui/Button';

interface MatchParams {
  inn: string;
}

interface StateToProps {
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
}

type Props = RouteComponentProps<MatchParams> & DispatchToProps & StateToProps;

const NotFound: React.FC<Props> = ({
  match,
  getCompanyByInn,
  resetStateCompanyByInn,
  status
}) => {
  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        window.location.reload();

        return () => resetStateCompanyByInn();
      }
    },
    [status]
  );

  return (
    <>
      <h1>Компания отсутствует в базе FINFACTORY</h1>

      <Button
        label="Проверить в ЕГРЮЛ"
        disabled={status === REQUEST_STATUSES.REQUEST}
        onClick={() => getCompanyByInn({ inn: match.params.inn })}
        w="fit-content"
      />
    </>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  status: SCF.getCompanyByInnThirdParty.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompanyByInn, resetStateCompanyByInn }, dispatch);

const NotFoundConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NotFound)
);

export { NotFoundConnect as NotFound };
