import { POST_ADD_SUPPLIER } from 'src/features/SCF/reducers/postAddSupplier';

export const req = (inn: string) => ({
  type: POST_ADD_SUPPLIER.REQ,
  inn
});

export const got = () => ({
  type: POST_ADD_SUPPLIER.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_SUPPLIER.ERR,
  error
});
