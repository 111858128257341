import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Loader } from 'src/features/Layouts/components';
import { ResponseDataType as GetCertificateResponseDataType } from '../../../../../../../actions/getCertificateGenitive';
import { req as getCertificate } from '../../../../../../../actions/getCertificateGenitive';
import { CertificateGenitive as CertificateType } from './../../../../../../../types/certificate';
import { CertificatesTableView } from './CertificatesTableView/CertificatesTableView';
import { req as deleteCertificate } from './../../../../../../../actions/deleteCertificate';
import { RequestDataType as DeleteCertificateRequestDataType } from './../../../../../../../reducers/deleteCertificate';

interface StateToProps {
  certificate: GetCertificateResponseDataType;
  status: REQUEST_STATUSES;
  deleteStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCertificate: () => void;
  deleteCertificate: (data: DeleteCertificateRequestDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class CertificatesList extends React.Component<Props> {
  componentDidMount() {
    this.props.getCertificate();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.deleteStatus === REQUEST_STATUSES.REQUEST && this.props.deleteStatus === REQUEST_STATUSES.GOT) {
      this.props.getCertificate();
    }
  }

  getCertificateId = (index: number): number => {
    const item = this.props.certificate.items[index];
    return !!item && item.id;
  };

  onCertificateClick = (e: Event, index: number): void => {
    e.stopPropagation();
    var id: number,
      id = this.getCertificateId(index);
    this.props.deleteCertificate({ id });
  };

  formCertificateData = (): CertificateType[] => {
    return this.props.certificate.items.map(this.certificateToView);
  };

  certificateToView = (certificate: CertificateType) => {
    const viewItem: CertificateType = {
      id: certificate.id,
      companyInn: certificate.companyInn,
      companyOgrnOrOgrnIP: certificate.companyOgrnOrOgrnIP,
      companyName_O: certificate.companyName_O,
      companyName_CN: certificate.companyName_CN,
      companyCountryC: certificate.companyCountryC,
      companyRegionS: certificate.companyRegionS,
      companyLocationL: certificate.companyLocationL,
      companyAddressStreet: certificate.companyAddressStreet,
      positionT: certificate.positionT,
      personSurNameSN: certificate.personSurNameSN,
      personNameG: certificate.personNameG,
      personInn: certificate.personInn,
      personEmailE: certificate.personEmailE,
      personSnils: certificate.personSnils,
      certValidFrom: certificate.certValidFrom,
      certValidUntil: certificate.certValidUntil,
      keyValidFrom: certificate.keyValidFrom,
      keyValidUntil: certificate.keyValidUntil,
      issuerInn: certificate.issuerInn,
      issuerOGRN: certificate.issuerOGRN,
      issuerName_O: certificate.issuerName_O,
      issuerName_CN: certificate.issuerName_CN,
      issuerTypeOU: certificate.issuerTypeOU,
      issuerCountryC: certificate.issuerCountryC,
      issuerRegionS: certificate.issuerRegionS,
      issuerLocationL: certificate.issuerLocationL,
      issuerAddressStreet: certificate.issuerAddressStreet,
      issuerEmailE: certificate.issuerEmailE,
      serialNumber: certificate.serialNumber,
      isDeleted: certificate.isDeleted,
      guid: certificate.guid,
      personFullnameGenitive: certificate.personFullnameGenitive,
      positionGenitive: certificate.positionGenitive,
      authority: certificate.authority,
      authorityGenitive: certificate.authorityGenitive,
      isAvailable: certificate.isAvailable
    };
    return viewItem;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.status === REQUEST_STATUSES.REQUEST && <Loader />}
        {this.props.status === REQUEST_STATUSES.GOT && (
          <CertificatesTableView
            list={this.formCertificateData()}
            onCertificateClick={this.onCertificateClick}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  certificate: SCF.getCertificateGenitive.data,
  status: SCF.getCertificateGenitive.status,
  deleteStatus: SCF.deleteCertificate.status,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCertificate,
      deleteCertificate
    },
    dispatch
  );

const CertificatesListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CertificatesList);

export { CertificatesListConnect as CertificatesList };
