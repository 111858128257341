import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { MAIN_COUNTER } from 'src/features/Companies/types/mainCounter';

export interface GetMainCounterStoreState extends Reducer {
  data: MAIN_COUNTER;
}

export enum GET_MAIN_COUNTER {
  REQ = 'GET_MAIN_COUNTER_REQ',
  GOT = 'GET_MAIN_COUNTER_GOT',
  ERR = 'GET_MAIN_COUNTER_ERR'
}

export const initialState: GetMainCounterStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    activitiesCounter: 0,
    agentApplicationsCounter: 0,
    agentLeadsCounter: 0,
    applicationsCounter: 0,
    contactsCounter: 0,
    leadsCounter: 0,
    productsCounter: 0,
    usersCounter: 0
  },
  error: {}
};

const getCompany = (
  state: GetMainCounterStoreState = initialState,
  action: {
    type: string;
    data: MAIN_COUNTER;
    error: {};
  }
): GetMainCounterStoreState => {
  switch (action.type) {
    case GET_MAIN_COUNTER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MAIN_COUNTER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MAIN_COUNTER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompany;
