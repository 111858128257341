import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/SCF/actions/postCreateAgreementSupplierRole';

import { POST_CREATE_AGREEMENT_SUPPLIER_ROLE } from 'src/features/SCF/reducers/postCreateAgreementSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* postCreateAgreementSupplierRole(action: {
  type: POST_CREATE_AGREEMENT_SUPPLIER_ROLE;
  guid: string;
}) {
  try {
    yield call(SCFApi.createAgreementSupplierRole, action.guid);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateAgreementSupplierRoleSaga() {
  yield takeLatest(
    POST_CREATE_AGREEMENT_SUPPLIER_ROLE.REQ,
    postCreateAgreementSupplierRole
  );
}
