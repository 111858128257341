import { call, put, takeLatest } from 'redux-saga/effects';

import SCFApi from '../api';

import { got, err } from './../actions/getCertificate';
import { CERTIFICATE_ACTIONS } from './../reducers/getCertificate';

function* getCertificateData(action: { type: CERTIFICATE_ACTIONS }) {
  try {
    const res: any = yield call(SCFApi.getCertificate);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCertificateDataSaga() {
  yield takeLatest(CERTIFICATE_ACTIONS.REQ, getCertificateData);
}
