import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/postFactoringFeesOfLimits';

import {
  POST_FACTORING_FEES_OF_LIMITS,
  RequestDataType
} from 'entities/SCF/Factor/model/reducers/postFactoringFeesOfLimits';

import SCFApi from 'entities/SCF/Factor/api';

function* postFactoringFeesOfLimits(action: {
  type: POST_FACTORING_FEES_OF_LIMITS;
  data: RequestDataType;
  inn: string;
}) {
  try {
    yield call(SCFApi.postFactoringFeesOfLimits, action.inn, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFactoringFeesOfLimitsSaga() {
  yield takeLatest(
    POST_FACTORING_FEES_OF_LIMITS.REQ,
    postFactoringFeesOfLimits
  );
}
