import sendLeadDataSaga from './sendLeadData';
import getLeadogeneratorsSaga from './getLeadogenerators';
import getLeadSaga from './getLead';
import changeLeadStatusSaga from './changeLeadStatus';
import getLeadsListSaga from './getLeadsList';
import getProductAppliactionLeadsSaga from './getProductApplicationLead';
import postSelectProductLeadSaga from './postSelectProductLead';
import getActivitiesByLeadInternalSaga from './getActivitiesByLeadInternal';
import getActivitiesByLeadExternalSaga from './getActivitiesByLeadExternal';
import postExternalApiAlfaBankSaga from './postExternalApiAlfaBank';
import postExternalApiPSBSaga from './postExternalApiPSB';
import getLeadDocumentListSaga from './getLeadDocumentList';
import postCreateForLeadSaga from './postCreateForLead';
import putSendToClientSaga from './putSendToClient';
import postSendCompanyToScoringSaga from './postSendCompanyToScoring';
import putChecklistSaga from './putChecklist';

export default [
  sendLeadDataSaga,
  getLeadogeneratorsSaga,
  getLeadSaga,
  changeLeadStatusSaga,
  getLeadsListSaga,
  getProductAppliactionLeadsSaga,
  postSelectProductLeadSaga,
  getActivitiesByLeadInternalSaga,
  getActivitiesByLeadExternalSaga,
  postExternalApiAlfaBankSaga,
  postExternalApiPSBSaga,
  getLeadDocumentListSaga,
  postCreateForLeadSaga,
  putSendToClientSaga,
  postSendCompanyToScoringSaga,
  putChecklistSaga
];
