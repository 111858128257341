import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/Client/model/actions/patchCompanyClient';

import { PATCH_COMPANY_CLIENT } from 'entities/Cabinet/Client/model/reducers/patchCompanyClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* patchCompanyClient(action: {
  type: PATCH_COMPANY_CLIENT;
  inn: string;
  data: RequestData;
}): Generator {
  try {
    yield call(ClientRoleApi.patchCompanyClient, action.inn, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCompanyClientSaga(): Generator {
  yield takeLatest(PATCH_COMPANY_CLIENT.REQ, patchCompanyClient);
}
