export enum SegmentClient {
  micro = 'Микро',
  small = 'Малый',
  medium = 'Средний',
  large = 'Крупный'
}

export enum TypeClient {
  CommercialCompanies = 'Коммерческая компания',
  StateCompanies = 'Госкомпания',
  EnterPreneurs = 'ИП',
  SelfEmployed = 'Самозанятый',
  Individuals = 'Физлицо',
  NonResident = 'Нерезидент'
}

export enum MspSubject {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'входит в список МСП',
  FALSE = 'не входит в список МСП'
}
