import { GET_MAIN_COUNTER } from '../reducers/getMainCounter';
import { MAIN_COUNTER } from 'src/features/Companies/types/mainCounter';

export const req = (inn: string) => ({
  type: GET_MAIN_COUNTER.REQ,
  inn
});

export const got = (data: MAIN_COUNTER) => ({
  type: GET_MAIN_COUNTER.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MAIN_COUNTER.ERR,
  error
});
