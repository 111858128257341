import { put, takeLatest } from 'redux-saga/effects';
import { GET_DOWNLOAD_SIGNATURE, err } from '../actions/getDownloadSignature';

function* getDownloadSignature(action: {
  type: GET_DOWNLOAD_SIGNATURE;
  guid: string;
}): Generator {
  try {
    const url = `/api/internal-agent/financial-document/download_signature/${
      action.guid
    }`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.sig');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDossierSaga(): Generator {
  yield takeLatest(GET_DOWNLOAD_SIGNATURE.REQ, getDownloadSignature);
}
