import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getSupplierAuction';

export interface GetSuppluerAuctionStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_SUPPLIER_AUCTION {
  REQ = 'GET_SUPPLIER_AUCTION_REQ',
  GOT = 'GET_SUPPLIER_AUCTION_GOT',
  ERR = 'GET_SUPPLIER_AUCTION_ERR',
  RESET = 'GET_SUPPLIER_AUCTION_RESET'
}

export const initialState: GetSuppluerAuctionStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 20,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getSupplierAuction = (
  state: GetSuppluerAuctionStoreState = initialState,
  action: {
    type: GET_SUPPLIER_AUCTION;
    data: ResponseData;
    error: {};
  }
): GetSuppluerAuctionStoreState => {
  switch (action.type) {
    case GET_SUPPLIER_AUCTION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUPPLIER_AUCTION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUPPLIER_AUCTION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_SUPPLIER_AUCTION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getSupplierAuction;
