import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestDataType } from 'Business/actions/businessRegister';

export interface BusinessRegisterStoreState extends Reducer {
  data: RequestDataType;
}

export enum BUSINESS_REGISTER {
  REQ = 'BUSINESS_REGISTER_REQUEST',
  GOT = 'BUSINESS_REGISTER_GOT',
  ERR = 'BUSINESS_REGISTER_ERROR',
  RESET = 'BUSINESS_REGISTER_RESET'
}

export const initialState: BusinessRegisterStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: ''
  },
  error: {}
};

const businessRegister = (
  state: BusinessRegisterStoreState = initialState,
  action: {
    type: string;
    data: RequestDataType;
    error: {};
  }
): BusinessRegisterStoreState => {
  switch (action.type) {
    case BUSINESS_REGISTER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case BUSINESS_REGISTER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case BUSINESS_REGISTER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case BUSINESS_REGISTER.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default businessRegister;
