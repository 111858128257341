import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface ChangeLeadStatusStoreState extends Reducer {}

export enum CHANGE_LEAD_STATUS {
  REQ = 'CHANGE_LEAD_STATUS_REQ',
  GOT = 'CHANGE_LEAD_STATUS_GOT',
  ERR = 'CHANGE_LEAD_STATUS_ERR'
}

export const initialState: ChangeLeadStatusStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const changeLeadStatus = (
  state: ChangeLeadStatusStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): ChangeLeadStatusStoreState => {
  switch (action.type) {
    case CHANGE_LEAD_STATUS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CHANGE_LEAD_STATUS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case CHANGE_LEAD_STATUS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default changeLeadStatus;
