import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { format, parse } from 'date-fns';
import { history } from 'src/shared/utils/History';

import { InputDate } from 'shared/ui/InputDate';
import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Pagination
} from 'Layouts/components';
import {
  RegistriesListStyled,
  ListSection,
  DateFilterStyled,
  DatesContainer
} from './styles';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError,
  PaginationWrapper
} from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as getDebtorRegistryFiles,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFiles';
import {
  req as getSubRegistries,
  RequestDataType as getSubRegistriesRequestDataType
} from 'entities/SCF/Debtor/model/actions/getSubRegistries';
import { ScrollTopComponent } from 'Common';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { CertificatesListForSubRegistriesPopup } from './CertificatesListPopup/CertificatesListPopupForSubRegistries';
import { Href } from 'history';
import { RegistriesSubList } from './RegistrySubList/RegistriesSubList';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  debtorRegistryFiles: ResponseDataType;
  documentsUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getDebtorRegistryFiles: (data: RequestDataType) => void;
  getSubRegistries: (data: getSubRegistriesRequestDataType) => void;
}

interface State {
  registryId: number;
  registryIdSub: string[];
  dateFrom: string;
  dateUntil: string;
  pageUrl: Href;
  fileName: string;
}

type Props = StateToProps & DispatchToProps;

class RegistriesList extends React.Component<Props, State> {
  componentDidMount() {
    this.initFetch();
  }

  constructor(props) {
    super(props);
    {
      this.state = {
        registryId: null,
        dateFrom: format(
          parse('01/01/2023', 'dd/MM/yyyy', new Date()),
          'dd/MM/yyyy'
        ),
        dateUntil: format(new Date(), 'dd/MM/yyyy'),
        pageUrl: '',
        fileName: null,
        registryIdSub: null
      };
      this.handleSubRegistryChange = this.handleSubRegistryChange.bind(this);
      this.handleRegistryChange = this.handleRegistryChange.bind(this);
      this.handleDateFromChange = this.handleDateFromChange.bind(this);
      this.handleDateUntilChange = this.handleDateUntilChange.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
    }
  }

  handleSubRegistryChange(id: string[]) {
    this.setState({ registryIdSub: id });
  }

  handleRegistryChange(id: number) {
    this.setState({ registryId: id });
  }

  handleDateFromChange(date: string) {
    this.setState({ dateFrom: date });
  }

  handleDateUntilChange(date: string) {
    this.setState({ dateUntil: date });
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  initFetch = () => {
    const {
      getDebtorRegistryFiles,
      debtorRegistryFiles,
      getSubRegistries
    } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getDebtorRegistryFiles({
      page,
      pageSize: 20,
      dateFrom: this.state.dateFrom,
      dateUntil: this.state.dateUntil
    });
  };

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.dateFrom !== prevState.dateFrom ||
      this.state.dateUntil !== prevState.dateUntil ||
      this.state.pageUrl !== prevState.pageUrl ||
      this.props.documentsUploadStatus !== prevProps.documentsUploadStatus
    ) {
      this.initFetch();
    }
  }

  onSignClick = (id: number, registryFileName: string) => {
    this.setState({ registryId: id });
    this.setState({ fileName: registryFileName });
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onSignClickSub = (id: string[]) => {
    this.setState({ registryIdSub: id });
    const sign = document.getElementById('certificatesSubPopup');
    sign.style.display = 'flex';
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
  };

  onCloseIconClickSub = () => {
    const sign = document.getElementById('certificatesSubPopup');
    sign.style.display = 'none';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    history.push({
      pathname: `/debtor/settings/certificates/edit`
    });
  };

  render() {
    const { status, error } = this.props;
    return (
      <RegistriesListStyled>
        <h2>Список реестров</h2>
        <DateFilterStyled>
          <p>Дата добавления:</p>
          <DatesContainer>
            <p>с</p>
            <InputDate
              onDateChange={this.handleDateFromChange}
              dateValue={this.state.dateFrom}
              placeholder="c"
            />
          </DatesContainer>
          <DatesContainer>
            <p>по</p>
            <InputDate
              onDateChange={this.handleDateUntilChange}
              dateValue={this.state.dateUntil}
              placeholder="по"
            />
          </DatesContainer>
        </DateFilterStyled>
        <ScrollTopComponent>
          <ListSection>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <RegistriesSubList
                list={this.props.debtorRegistryFiles.items}
                onSignClick={this.onSignClick}
                onSignClickSub={this.onSignClickSub}
              />
            )}
            <PaginationWrapper onClick={this.handlePageChange}>
              <Pagination list={this.props.debtorRegistryFiles} />
            </PaginationWrapper>

            <ActionResponseBlock
              showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
              template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
            />

            <ActionResponseBlock
              showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
              template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
            />
          </ListSection>
        </ScrollTopComponent>
        <CertificatesPopup id="certificatesPopup">
          <PopupExit>
            <p onClick={this.onCloseIconClick}>X</p>
          </PopupExit>
          <h1>Список сертификатов в системе</h1>
          <CertificatesArea>
            <CertificatesListPopup
              registryId={this.state.registryId}
              fileName={this.state.fileName}
            />
          </CertificatesArea>
          <NudgeArea>
            <p>Нет нужного сертификата? </p>
            <Nudge onClick={this.onNudgeClick}>
              Перейдите в настройки добаления
            </Nudge>
          </NudgeArea>
        </CertificatesPopup>
        <CertificatesPopup id="certificatesSubPopup">
          <PopupExit>
            <p onClick={this.onCloseIconClickSub}>X</p>
          </PopupExit>
          <h1>Список сертификатов в системе</h1>
          <CertificatesArea>
            <CertificatesListForSubRegistriesPopup
              registryIdSub={this.state.registryIdSub}
            />
          </CertificatesArea>
          <NudgeArea>
            <p>Нет нужного сертификата? </p>
            <Nudge onClick={this.onNudgeClick}>
              Перейдите в настройки добаления
            </Nudge>
          </NudgeArea>
        </CertificatesPopup>
        <CertificatesPopupError id="certificatesPopupError">
          <PopupExitError>
            <p onClick={this.onCloseErrorIconClick}>X</p>
          </PopupExitError>
          <h1>
            Невозможно подписать выбранным сертификатом
            <br />
            (не найден на устройстве)
          </h1>
        </CertificatesPopupError>
      </RegistriesListStyled>
    );
  }
}

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  debtorRegistryFiles: SCFDebtor.getDebtorRegistryFiles.data,
  status: SCFDebtor.getDebtorRegistryFiles.status,
  error: SCFDebtor.getDebtorRegistryFiles.error,
  documentsUploadStatus: SCFDebtor.uploadRegistries.status,
  subRegistry: SCFDebtor.getSubRegistries.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDebtorRegistryFiles,
      getSubRegistries
    },
    dispatch
  );

const RegistriesListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RegistriesList);

export { RegistriesListConnect as RegistriesList };
