import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  column-gap: 100px;

  p {
    font-size: 18px;
    margin: 0;
  }

  h2 {
    font-size: 24px;
  }

  & > div > p {
    margin-bottom: 20px;
  }

  & > div > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: 15px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
