import {
  SET_APPLICATION_LIST_VIEW,
  RequestDataType
} from 'Application/reducers/setApplicationListView';

export const setApplicationListView = (data: RequestDataType) => ({
  type: SET_APPLICATION_LIST_VIEW.SET,
  data
});

