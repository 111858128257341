import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostExternalApiPSBStoreState extends Reducer {}

export enum POST_EXTERNAL_API_PSB_APPLICATION {
  REQ = 'POST_EXTERNAL_API_PSB_APPLICATION_REQ',
  GOT = 'POST_EXTERNAL_API_PSB_APPLICATION_GOT',
  ERR = 'POST_EXTERNAL_API_PSB_APPLICATION_ERR'
}

export const initialState: PostExternalApiPSBStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postExternalApiPSB = (
  state: PostExternalApiPSBStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): PostExternalApiPSBStoreState => {
  switch (action.type) {
    case POST_EXTERNAL_API_PSB_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_EXTERNAL_API_PSB_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_EXTERNAL_API_PSB_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postExternalApiPSB;
