import * as React from 'react';
import { connect } from 'react-redux';

import {
  CreditingApplicationRead,
  Document as IDocument
} from 'Application/types';
import { DocumentsBlock } from 'Layouts/components';
import { DownloadLink } from 'shared/styled';
import { STORE } from 'src/globaltypes';

import ApplicationApi from 'src/features/Application/api';
import { ApplicationSubtitle } from '../../styles';
import { GeneralDocumentsBlockStyled } from './styles';

interface OwnProps {
  documents: IDocument[];
}

interface StateToProps {
  bankApplication: Partial<CreditingApplicationRead>;
}

type Props = OwnProps & StateToProps;

const GeneralDocumentsBlock: React.FC<Props> = ({
  bankApplication,
  documents
}) =>
  documents.length > 0 && (
    <GeneralDocumentsBlockStyled>
      <ApplicationSubtitle>
        Общие документы
        <DownloadLink
          href={ApplicationApi.getDownloadApplicationDocumentsLink(
            bankApplication.id
          )}
        />
      </ApplicationSubtitle>
      <DocumentsBlock documents={documents} />
    </GeneralDocumentsBlockStyled>
  );

const mapStateToProps = ({ BankRole }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data
});

const GeneralDocumentsBlockConnect = connect<StateToProps>(mapStateToProps)(
  GeneralDocumentsBlock
);

export { GeneralDocumentsBlockConnect as GeneralDocumentsBlock };
