import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'Business/actions/getBeneficiariesOfCompanies';
import { GET_BENEFICIARIES_OF_COMPANIES } from 'Business/reducers/getBenificiariesOfCompanies';
import BusinessApi from 'Business/api';

function* getBeneficiariesOfCompanies(action: {
  type: GET_BENEFICIARIES_OF_COMPANIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(
      BusinessApi.getBeneficiariesOfCompanies,
      action.inn
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBeneficiariesOfCompaniesSaga(): Generator {
  yield takeLatest(
    GET_BENEFICIARIES_OF_COMPANIES.REQ,
    getBeneficiariesOfCompanies
  );
}
