import { GET_SUBSIDARIES_CLIENT } from 'src/features/Clients/reducers/getSubsidariesClient';
import { SubsidiariesTypes } from 'src/features/Beneficiaries/types';

export interface ResponseData extends SubsidiariesTypes {
  personInn: string;
  companyInn: string;
  ownershipShareDeJure: number;
  ownershipShareDeFacto: number;
  capitalShare: number;
  beneficiaryType: number;
  companyShortName: string;
  companyFullName: string;
  revenue: number;
  inAction: number;
  netIncome: number;
  financialsYear: number;
  capital: number;
  headCompanyInn: string;
  okvedCode: string;
  okvedName: string;
  okvedPic: any;
}

export const req = (inn: string) => ({
  type: GET_SUBSIDARIES_CLIENT.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_SUBSIDARIES_CLIENT.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_SUBSIDARIES_CLIENT.ERR,
  error
});
