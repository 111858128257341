import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE, REQUEST_STATUSES } from 'globaltypes';

import { closeModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { APPLICATION_PROCESS_MODES } from 'Application/reducers/setApplicationProcessMode';

import { Modal } from 'Common';

import {
  CancelApplicationProcessModalStyled,
  DontCancelBtn,
  BtnsBlock
} from './styles';
import { Button } from 'shared/ui/Button';

interface OwnProps {
  cancelProcess: () => void;
}

interface StateToProps {
  mode: APPLICATION_PROCESS_MODES;
  isApplicationDraft: boolean;
}

interface DispatchToProps {
  closeModal: () => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class CancelApplicationProcessModal extends React.Component<Props> {
  defineModalText = () => {
    switch (this.props.mode) {
      case APPLICATION_PROCESS_MODES.CREATE:
        return (
          <React.Fragment>
            <h2>Внимание!</h2>
            {this.props.isApplicationDraft ? (
              <p>Вы уверены, что хотите покинуть данную страницу?</p>
            ) : (
              <p>
                Введенные данные не будут сохранены. <br /> Вы уверены, что
                хотите покинуть данную страницу?
              </p>
            )}
          </React.Fragment>
        );
      case APPLICATION_PROCESS_MODES.EDIT:
        return (
          <React.Fragment>
            <h2>Вы покидаете страницу</h2>
            <p>Завершить редактирование заявки?</p>
          </React.Fragment>
        );
      default:
        return <h2>Вы уверены, что хотите покинуть данную страницу?</h2>;
    }
  };

  render() {
    return (
      <Modal name={MODAL_NAMES.CLOSE_APPLICATION_PROCESS}>
        <CancelApplicationProcessModalStyled>
          {this.defineModalText()}

          <BtnsBlock>
            <DontCancelBtn
              onClick={this.props.closeModal}
              template="defaultBtn"
            >
              Остаться
            </DontCancelBtn>

            <Button
              onClick={() => {
                this.props.closeModal();
                this.props.cancelProcess();
              }}
              label="Покинуть"
              template="nextBtn"
            />
          </BtnsBlock>
        </CancelApplicationProcessModalStyled>
      </Modal>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  mode: Application.setApplicationProcessMode.mode,
  isApplicationDraft:
    Application.updateInitializationFormData.status === REQUEST_STATUSES.GOT
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeModal }, dispatch);

const CancelApplicationProcessModalConnect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(CancelApplicationProcessModal);

export {
  CancelApplicationProcessModalConnect as CancelApplicationProcessModal
};
