import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArchive,
  faClipboard,
  faFileAlt,
  faGavel,
  faInbox,
  faLink,
  faPuzzlePiece,
  faTruck,
  faUniversity,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { EarlyPaymentApplications } from 'src/features/EarlyPaymentApplications/components/EarlyPaymentApplications';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'globaltypes';
import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';

import {
  req as getQtySidebar,
  ResponseData
} from 'src/features/SCF/actions/getQtySidebar';
import { NavigationLink, Sidebar, Spinner } from 'Layouts/components';
import { CounterBox, CounterBoxGreen } from './styles';
import { DocumentForms } from '../DocumentForms/DocumentForms';
import { CounterBoxStyle } from 'pages/SCF/Debtor/components/BarSection/styles';
import { Supplies } from '../Supplies/Supplies';
import { Registries } from '../Registries/Registries';
import { ContractSupplies } from '../ContractSupplies/ContractSupplies';

export interface CRMComponent {
  path?: string;
  whenToActivate?: boolean;
  hasPermission: (permissions: USER_PERMISSIONS[]) => boolean;
  component?: any;
  render?: (location: any) => JSX.Element;
  renderSidebarLink?: (key: any) => JSX.Element;
}
interface OwnProps {
  cabinetLists?: APPLICATIONS_LIST_TYPE[];
}

interface StateToProps {
  counter: ResponseData;
  status: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getQtySidebar: () => void;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

interface SidebarProps {
  path: string;
  Component: any;
  listType: string;
  icon: any;
  label: string;
  LabelInfo?: JSX.Element;
}

function createSidebarComponent({
  path,
  Component,
  listType,
  icon,
  label,
  LabelInfo
}: SidebarProps) {
  return {
    path: path,
    render: () => <Component listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
        labelInfo={LabelInfo}
      />
    )
  };
}

const LabelInfo = ({ info }) => !!info && <CounterBox>{info}</CounterBox>;

const BarSection: React.FC<Props> = ({
  getQtySidebar,
  counter,
  status,
  permissions
}) => {
  React.useEffect(() => {
    getQtySidebar();
  }, []);

  const showQuantity = (key: string) => {
    return status === REQUEST_STATUSES.REQUEST ? (
      <Spinner size={22} />
    ) : (
      counter[key]
    );
  };

  const componentsList = [
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: Registries,
        path: '/internal/registries',
        label: 'Реестры',
        icon: <FontAwesomeIcon icon={faArchive} fixedWidth={true} />,
        listType: null,
        LabelInfo: showQuantity('registriesQty') > 0 && (
          <CounterBoxStyle>{showQuantity('registriesQty')}</CounterBoxStyle>
        )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: Supplies,
        path: '/internal/monetary_claims',
        label: 'Поставки',
        icon: <FontAwesomeIcon icon={faTruck} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('activeMonetaryClaimsQty') > 0 ? (
            <CounterBoxGreen>
              {showQuantity('activeMonetaryClaimsQty')}
            </CounterBoxGreen>
          ) : (
            showQuantity('monetaryClaimsQty') > 0 && (
              <CounterBoxStyle>
                {showQuantity('monetaryClaimsQty')}
              </CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: ContractSupplies,
        path: '/internal/supply_contracts',
        label: 'Договоры поставки',
        icon: <FontAwesomeIcon icon={faClipboard} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('newSupplyContractsQty') > 0 ? (
            <LabelInfo info={showQuantity('newSupplyContractsQty')} />
          ) : (
            showQuantity('supplyContractsQty') > 0 && (
              <CounterBoxStyle>
                {showQuantity('supplyContractsQty')}
              </CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: EarlyPaymentApplications,
        path: '/internal/early_payment_applications',
        label: 'Заявки ранней оплаты',
        icon: <FontAwesomeIcon icon={faInbox} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('epaInWorkQty') > 0 ? (
            <LabelInfo info={showQuantity('epaInWorkQty')} />
          ) : (
            showQuantity('epaQty') > 0 && (
              <CounterBoxStyle>{showQuantity('epaQty')}</CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: React.Fragment,
        path: '/internal/discount_auctions',
        label: 'Аукционы дисконтирования',
        icon: <FontAwesomeIcon icon={faGavel} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('emptyAuctionsQty') > 0 ? (
            <LabelInfo info={showQuantity('emptyAuctionsQty')} />
          ) : showQuantity('activeAuctionsQty') > 0 ? (
            <CounterBoxGreen info={showQuantity('activeAuctionsQty')} />
          ) : (
            showQuantity('auctionsQty') > 0 && (
              <CounterBoxStyle>{showQuantity('auctionsQty')}</CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: DocumentForms,
        path: '/internal/document_forms',
        label: 'Формы документов',
        icon: <FontAwesomeIcon icon={faFileAlt} fixedWidth={true} />,
        listType: null
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: React.Fragment,
        path: '/internal/suppliers',
        label: 'Поставщики',
        icon: <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
        listType: null,
        LabelInfo: showQuantity('supplierUsersQty') > 0 && (
          <LabelInfo info={showQuantity('supplierUsersQty')} />
        )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: React.Fragment,
        path: '/internal/debtors',
        label: 'Дебиторы',
        icon: <FontAwesomeIcon icon={faPuzzlePiece} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('newDebtorUsersQty') > 0 ? (
            <LabelInfo info={showQuantity('newDebtorUsersQty')} />
          ) : (
            showQuantity('debtorUsersQty') > 0 && (
              <CounterBoxStyle>
                {showQuantity('debtorUsersQty')}
              </CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: React.Fragment,
        path: '/internal/buyers_to_suppliers_links',
        label: 'Связи поставщик - дебитор',
        icon: <FontAwesomeIcon icon={faLink} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('newBuyersToSuppliersLinksQty') > 0 ? (
            <LabelInfo info={showQuantity('newBuyersToSuppliersLinksQty')} />
          ) : (
            showQuantity('buyersToSuppliersLinksQty') > 0 && (
              <CounterBoxStyle>
                {showQuantity('buyersToSuppliersLinksQty')}
              </CounterBoxStyle>
            )
          )
      })
    },
    {
      hasPermission: permissions =>
        [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
      ...createSidebarComponent({
        Component: React.Fragment,
        path: '/internal/factors',
        label: 'Факторы',
        icon: <FontAwesomeIcon icon={faUniversity} fixedWidth={true} />,
        listType: null,
        LabelInfo:
          showQuantity('newFactorUsersQty') > 0 ? (
            <LabelInfo info={showQuantity('newFactorUsersQty')} />
          ) : (
            showQuantity('factorUsersQty') > 0 && (
              <CounterBoxStyle>
                {showQuantity('factorUsersQty')}
              </CounterBoxStyle>
            )
          )
      })
    }
  ];

  return (
    <React.Fragment>
      <Sidebar>
        {componentsList.map(
          (component, key) =>
            component.hasPermission(permissions) &&
            component.renderSidebarLink &&
            component.renderSidebarLink(key)
        )}
      </Sidebar>
    </React.Fragment>
  );
};

const mapStateToProps = ({ SCF, User }: STORE) => ({
  counter: SCF.getQtySidebar.data,
  status: SCF.getQtySidebar.status,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getQtySidebar }, dispatch);

const BarSectionConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BarSection)
);

export { BarSectionConnect as BarSection };
