import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendBankAccountData';

import { SEND_BANK_ACCOUNT_DATA } from '../reducers/sendBankAccountData';
import SCFApi from '../api';
import {
  BankAccountData,
  resetBankAccountData
} from '../actions/setBankAccount';

function* sendBankAccountData(action: {
  type: SEND_BANK_ACCOUNT_DATA;
  data: BankAccountData;
}): Generator {
  try {
    yield call(SCFApi.sendBankAccountData, action.data);
    yield put(got());
    yield put(resetBankAccountData());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendBankAccountDataSaga(): Generator {
  yield takeLatest(SEND_BANK_ACCOUNT_DATA.REQ, sendBankAccountData);
}
