import Validator from 'instant-validation';
import { requiredRule, lengthRule } from 'instant-validation/build/rules';

import {
  inRangeRule,
  singleElementArrayRule,
  alwaysTrueRule,
  innValidationRule
} from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import { TERM_TYPE } from './ContractFinancingFormStep1';

export const createValidaton = () =>
  new Validator<SetInitializationApplicationType>({
    clientCompanyName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    code: [
      {
        rule: innValidationRule,
        message: ValidatorMessages.codeValid
      },
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    amount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    termEndDate: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    term: [
      {
        rule: (term: string, termType: TERM_TYPE) => {
          if (termType === TERM_TYPE.DATE) return true;
          return requiredRule(term);
        },
        message: ValidatorMessages.invalidValue,
        ruleId: 'termType'
      }
    ]
  });
