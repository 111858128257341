import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postCalculateRating';

import { POST_CALCULATE_RATING } from '../reducers/postCalculateRating';
import { CalculateData } from 'src/features/Products/types';
import ProductsApi from '../api';

function* postCalculateRating(action: {
  type: POST_CALCULATE_RATING;
  id: number;
  data: CalculateData;
}): Generator {
  try {
    yield call(ProductsApi.postCalculateRating, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCalculateRatingSaga(): Generator {
  yield takeLatest(POST_CALCULATE_RATING.REQ, postCalculateRating);
}
