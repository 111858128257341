import styled from 'styled-components';
import { lighterBlue, lightGray, rr } from 'shared/styled';

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;
`;

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;

export const TDSupplier = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const SupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  & > div:nth-child(2) {
    display: block;
    font-family: ${rr};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    margin: 0;

    &:last-child {
      white-space: pre-wrap;
    }
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  cursor: pointer;

  &:hover{
    svg {
      color: #0085FE;
    }
  }

  svg {
    color: #9FA6B2;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    right: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const RegistryFilename = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;
