import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/sendSuppliers';

import {
  SEND_SUPPLIERS,
  RequestDataType
} from 'src/features/SCF/reducers/sendSuppliers';

import SCFApi from 'src/features/SCF/api';

function* sendSuppliers(action: {
  type: SEND_SUPPLIERS;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.sendSuppliersList, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendSuppliersSaga() {
  yield takeLatest(SEND_SUPPLIERS.REQ, sendSuppliers);
}
