import getClientApplicationSaga from './getClientApplication';
import getCompaniesOfUserSaga from './getCompaniesOfUser';
import postNewCompaniesSaga from './postNewCompanies';
import getOpenLoanLimitsSaga from './getOpenLoanLimits';
import getFinancialDocumentsSaga from './getFinancialDocuments';
import patchCompanyClientSaga from './patchCompanyClient';
import getCompanyClientSaga from './getCompanyClient';
import getTenderByClientSaga from './getTenderByClient';
import postTenderByClientSaga from './postTenderByClient';
import postAddTenderByEruzSaga from './postAddTenderByEruz';
import postUploadFinDocClientSaga from './postUploadFinDocClient';
import postUploadNonFinDocClientSaga from './postUploadNonFinDocClient';
import getDocumentListClientSaga from './getDocumentListClient';
import postDocumentListClientSaga from './postDocumentListClient';

export default [
  getClientApplicationSaga,
  getCompaniesOfUserSaga,
  postNewCompaniesSaga,
  getOpenLoanLimitsSaga,
  getFinancialDocumentsSaga,
  patchCompanyClientSaga,
  getCompanyClientSaga,
  getTenderByClientSaga,
  postTenderByClientSaga,
  postAddTenderByEruzSaga,
  postUploadFinDocClientSaga,
  postUploadNonFinDocClientSaga,
  getDocumentListClientSaga,
  postDocumentListClientSaga
];
