import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSpinner,
  faTimes,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Spinner as SpinnerApi } from 'Application/components/ApplicationView/EachApplicationView/styles';
import {
  req as getDocumentList,
  ResponseData as DocumentListResponseData
} from 'src/features/DocumentList/actions/getDocumentList';
import { req as postFinFile } from 'src/features/DocumentList/actions/postFinFile';
import { req as postNotFinFile } from 'src/features/DocumentList/actions/postNotFinFile';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { DocumentListType, PersonDocumentsType } from '../types';
import {
  IsUploadContainer,
  CompanyHead,
  DocumentType,
  FileContainer,
  IsNecessarily,
  MainSection,
  Period,
  UploadButton,
  TableWrapper,
  ButtonContainer,
  ProgressBarContainer,
  ProgressText,
  ProgressFill,
  TitleRegister,
  DocumentSection,
  PopupContainer,
  PopupContent
} from './styled';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  documents: DocumentListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusFinDocUpload: REQUEST_STATUSES;
  statusNotFinDocUpload: REQUEST_STATUSES;
}

interface DispatchToProps {
  getDocumentList: (token: string) => void;
  postFinFile: (token: string, data: FormData) => void;
  postNotFinFile: (token: string, data: FormData) => void;
}

interface MatchParams {
  token: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const DocumentList: React.FC<Props> = ({
  getDocumentList,
  documents,
  status,
  error,
  postFinFile,
  postNotFinFile,
  statusFinDocUpload,
  statusNotFinDocUpload,
  history,
  match
}) => {
  const [companyINN, setCompanyINN] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentListType[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);
  const [file, setFile] = React.useState({ id: null, file: null });
  const [isErrorIsVisible, setIsErrorIsVisible] = React.useState(false);

  const errorModalRef = React.useRef(null);

  React.useEffect(
    () => {
      if (match.params.token) {
        getDocumentList(match.params.token);
      }
    },
    [match.params.token]
  );

  React.useEffect(
    () => {
      if (
        (statusFinDocUpload === REQUEST_STATUSES.ERROR ||
          statusNotFinDocUpload === REQUEST_STATUSES.ERROR) &&
        match.params.token
      ) {
        setIsErrorIsVisible(true);
      }
    },
    [statusFinDocUpload, statusNotFinDocUpload]
  );

  React.useEffect(
    () => {
      if (
        (statusFinDocUpload === REQUEST_STATUSES.GOT ||
          statusNotFinDocUpload === REQUEST_STATUSES.GOT) &&
        match.params.token
      ) {
        getDocumentList(match.params.token);
        setFile({ id: null, file: null });
      }
    },
    [statusFinDocUpload, statusNotFinDocUpload, match.params.token]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        setCompanyINN(documents.items[0].companyINN);
        setCompanyName(documents.items[0].companyShortName);

        const filtredInnDocuments = documents.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        documents.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            guid,
            documentCategory
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                guid,
                documentCategory
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [status]
  );

  const mandatoryUploaded = documents.items.filter(
    doc => doc.isMandatory && doc.isUploaded
  );

  const optionalUploaded = documents.items.filter(
    doc => !doc.isMandatory && doc.isUploaded
  );

  const allMandatory = documents.items.filter(doc => doc.isMandatory);

  const progress =
    (mandatoryUploaded.length + optionalUploaded.length) / allMandatory.length;

  const handleSetFile = (guid: string, file) => {
    setFile({ id: guid, file });
  };

  const postFile = (docCategory: number, guid: string) => {
    if (file) {
      const formData = new FormData();
      formData.append('guid', guid);
      formData.append('file', file.file);

      if (docCategory === 1) {
        postFinFile(match.params.token, formData);
      } else if (docCategory === 2) {
        postNotFinFile(match.params.token, formData);
      }
    }
  };

  const navigateToRegister = () => {
    history.push(`/registration/business/${companyINN}`);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      errorModalRef.current &&
      !(errorModalRef.current as Element).contains(target)
    ) {
      setIsErrorIsVisible(false);
    }
  };

  return (
    <MainSection>
      <ApplicationViewStyled>
        <ApplicationTitle>Список документов для вашей заявки:</ApplicationTitle>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ProgressBarContainer>
              <ProgressFill value={progress} max={1} />
              <ProgressText>{Math.round(progress * 100)}%</ProgressText>
            </ProgressBarContainer>

            <CompanyHead>
              <p>{companyName}</p>
              <p>ИНН: {companyINN}</p>
            </CompanyHead>

            <TableWrapper>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="12%">Тип документа</TableThStyled>
                    <TableThStyled width="8%">Период</TableThStyled>
                    <TableThStyled width="10%" />
                    <TableThStyled width="25%" />
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {filtredInnDocuments.map(document => (
                    <TableRowStyled key={document.guid}>
                      <td>{document.documentName}</td>
                      <td>
                        <Period>
                          <p>
                            {document.taxYear && document.taxPeriodName
                              ? `${document.taxYear}, ${document.taxPeriodName}`
                              : '-'}
                          </p>
                        </Period>
                      </td>
                      <td>
                        {document.isMandatory && (
                          <IsNecessarily isUploaded={document.isUploaded}>
                            *Обязательный
                          </IsNecessarily>
                        )}
                      </td>
                      <td>
                        <FileContainer>
                          <IsUploadContainer isUploaded={document.isUploaded}>
                            {document.isUploaded ? (
                              <div>
                                <FontAwesomeIcon icon={faCheck} />
                                <p>Загружен</p>
                              </div>
                            ) : (
                              <div>
                                <FontAwesomeIcon icon={faTimes} />
                                <p>Не загружен</p>
                              </div>
                            )}
                          </IsUploadContainer>
                          {!document.isUploaded && (
                            <input
                              type="file"
                              onChange={e =>
                                handleSetFile(document.guid, e.target.files[0])
                              }
                            />
                          )}
                          {file.id === document.guid && (
                            <ButtonContainer>
                              <UploadButton
                                status={
                                  statusFinDocUpload ===
                                  REQUEST_STATUSES.REQUEST
                                }
                                disabled={
                                  statusFinDocUpload ===
                                  REQUEST_STATUSES.REQUEST
                                }
                                onClick={() =>
                                  postFile(
                                    document.documentCategory,
                                    document.guid
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faUpload} />
                                <p>Загрузить</p>
                              </UploadButton>
                              {statusFinDocUpload ===
                                REQUEST_STATUSES.REQUEST && (
                                <SpinnerApi icon={faSpinner} />
                              )}
                            </ButtonContainer>
                          )}
                        </FileContainer>
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </TableWrapper>

            {filtredPersonDocuments.map((person, index) => (
              <React.Fragment key={index}>
                <CompanyHead>
                  <p>{person.personName}</p>
                  <p>{person.personComment}</p>
                </CompanyHead>

                <TableWrapper>
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="12%">Тип документа</TableThStyled>
                        <TableThStyled width="8%" />
                        <TableThStyled width="25%" />
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {person.documents.map((document, index) => (
                        <TableRowStyled key={index}>
                          <td>
                            <DocumentType>{document.documentName}</DocumentType>
                          </td>
                          <td>
                            {document.isMandatory && (
                              <IsNecessarily isUploaded={document.isUploaded}>
                                *Обязательный
                              </IsNecessarily>
                            )}
                          </td>
                          <td>
                            <FileContainer>
                              <IsUploadContainer
                                isUploaded={document.isUploaded}
                              >
                                {document.isUploaded ? (
                                  <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                    <p>Загружен</p>
                                  </div>
                                ) : (
                                  <div>
                                    <FontAwesomeIcon icon={faTimes} />
                                    <p>Не загружен</p>
                                  </div>
                                )}
                              </IsUploadContainer>
                              {!document.isUploaded && (
                                <input
                                  type="file"
                                  onChange={e =>
                                    handleSetFile(
                                      document.guid,
                                      e.target.files[0]
                                    )
                                  }
                                />
                              )}
                              {file.id === document.guid && (
                                <ButtonContainer>
                                  <UploadButton
                                    status={
                                      statusNotFinDocUpload ===
                                      REQUEST_STATUSES.REQUEST
                                    }
                                    disabled={
                                      statusNotFinDocUpload ===
                                      REQUEST_STATUSES.REQUEST
                                    }
                                    onClick={() =>
                                      postFile(
                                        document.documentCategory,
                                        document.guid
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Загрузить</p>
                                  </UploadButton>
                                  {statusNotFinDocUpload ===
                                    REQUEST_STATUSES.REQUEST && (
                                    <SpinnerApi icon={faSpinner} />
                                  )}
                                </ButtonContainer>
                              )}
                            </FileContainer>
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>
                </TableWrapper>
              </React.Fragment>
            ))}

            {!!documents.limits.length && (
              <DocumentSection>
                <ApplicationTitle>
                  Предварительно одобренные продукты:
                </ApplicationTitle>

                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="12%">Продукт</TableThStyled>
                      <TableThStyled width="8%">Сумма</TableThStyled>
                      <TableThStyled width="10%">
                        Предварительная ставка
                      </TableThStyled>
                      <TableThStyled width="25%">Срок</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {documents.limits.map((document, index) => (
                      <TableRowStyled key={index}>
                        <td>{document.productTypeName}</td>
                        <td>
                          {document.volume
                            ? `до ${formSumStringThousands(
                                document.volume
                              )} руб.`
                            : 'неизв.'}
                        </td>
                        <td>
                          {document.rate
                            ? `${formSumStringThousands(
                                document.rate
                              )} % годовых`
                            : 'неизв.'}
                        </td>
                        <td>
                          {document.term
                            ? `до ${document.term} мес.`
                            : 'неизв.'}
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              </DocumentSection>
            )}

            <TitleRegister>
              <span onClick={navigateToRegister}>Зарегистрируйтесь</span>, чтобы
              следить за статусом заявки{' '}
              {!!documents.limits.length &&
                'или воспользоваться предварительными одобрениями'}
            </TitleRegister>
          </>
        )}

        <PopupContainer visible={isErrorIsVisible} onClick={handleOutsideClick}>
          <PopupContent ref={errorModalRef}>
            <h2>Ошибка</h2>
            <p>Закупка не найдена</p>
          </PopupContent>
        </PopupContainer>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    </MainSection>
  );
};

const mapStateToProps = ({ DocumentList }: CRM) => ({
  documents: DocumentList.getDocumentList.data,
  status: DocumentList.getDocumentList.status,
  error: DocumentList.getDocumentList.error,
  statusFinDocUpload: DocumentList.postFinFile.status,
  statusNotFinDocUpload: DocumentList.postNotFinFile.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getDocumentList, postFinFile, postNotFinFile },
    dispatch
  );

const DocumentListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentList)
);

export { DocumentListConnect as DocumentList };
