import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from 'src/shared/utils/History';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { ProductRead } from '../../actions/setProductFormData';
import { req as getProduct } from '../../actions/getProduct';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu
} from 'src/features/Layouts/components';
import {
  ProductsData,
  setProductFormData
} from '../../actions/setProductFormData';
import { req as postCalculateRating } from 'src/features/Products/actions/postCalculateRating';
import { CalculateData, PRODUCT_TABS } from 'src/features/Products/types';
import {
  CardBank,
  CloseButton,
  HeaderContainer,
  PopupContainer,
  PopupContent,
  ProductRating,
  ProductRatingName,
  ProductRatingNameContainer
} from 'src/features/Products/components/ProductView/styled';
import { ContactsListByProduct } from 'src/features/ContactsOfProducts/components/ContactsOfProductsList/ContactsForProductPage';
import { CooperationForProducts } from 'src/features/CooperationForProducts/components/CooperationForProducts';
import { CustomerRequirementsForProduct } from 'src/features/CustomerRequirements/components/CustomerRequirements';
import { ContractLogigForProduct } from 'src/features/ContractLogic/components/ContractLogic';
import { StopFactorsForProduct } from 'src/features/StopFactors/components/StopFactors';
import { BasicParametersForProducts } from 'src/features/BasicParametersForProducts/components/BasicParametersForProducts';
import { PledgeForProducts } from 'src/features/ProductPledge/components/ProductPledge';
import { PlatformsForProducts } from 'src/features/PlatformsForProducts/components/PlatformsForProducts';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  product: ProductRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
  roles: string[];
}

interface State {
  activeTab: string;
  isVisibleRating: boolean;
}

interface MatchParams {
  id: string;
  tab: string;
}

interface DispatchToProps {
  getProduct: (id: string) => void;
  setProductFormData: (data: ProductsData) => void;
  postCalculateRating: (id: number, data: CalculateData) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ProductView extends React.Component<Props, State> {
  tab = this.props.match.params.tab;
  state = {
    activeTab: (this.tab && this.tab.toUpperCase()) || 'DEFAULT',
    isVisibleRating: false
  };

  componentDidMount() {
    this.props.getProduct(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      } else {
        this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
      }
    }
    if (prevState.activeTab !== this.state.activeTab) {
      const { tab } = this.props.match.params;
      const baseUrl = history.location.pathname.replace(
        `/${tab && tab.toLowerCase()}`,
        ''
      );
      if (this.state.activeTab !== 'DEFAULT') {
        const url = baseUrl + `/${this.state.activeTab.toLowerCase()}`;
        history.push(url);
      } else {
        history.push(baseUrl);
      }
    }
  }

  navigateToBank(bankInn: string) {
    if (bankInn) {
      history.push(`/crm/banks/${bankInn}`);
    }
  }

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  openModalRating = () => {
    this.setState({ isVisibleRating: true });
  };

  closePopup = () => {
    this.setState({ isVisibleRating: false });
  };

  /* TODO extract ApplicationViewStyled and others */
  render() {
    const { product, status, error, roles, permissions } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <HeaderContainer>
              <div>
                <ApplicationId>Продукт #{product.id}</ApplicationId>
                <ApplicationTitle>{product.productName}</ApplicationTitle>
              </div>
              <div>
                <ProductRatingNameContainer>
                  <ProductRatingName>Рейтинг продукта</ProductRatingName>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    onClick={this.openModalRating}
                  />
                </ProductRatingNameContainer>
                <ProductRating>
                  <p>{formSumStringThousands(product.productRating)}</p>
                  <Button
                    label="Пересчитать"
                    h="30px"
                    // onClick={() =>
                    //   this.props.postCalculateRating(product.id, {
                    //     cashbackBase: product.cashbackBase,
                    //     clientAnchoring: product.clientAnchoring,
                    //     clientControl: product.clientControl,
                    //     exclusivity: product.exclusivity,
                    //     minRate: product.minRate,
                    //     standartCashBack: product.standardCashback
                    //   })
                    // }
                  />
                </ProductRating>
              </div>
            </HeaderContainer>

            <PopupContainer visible={this.state.isVisibleRating}>
              <PopupContent>
                <CloseButton onClick={this.closePopup}>&times;</CloseButton>
                <p>
                  “Рейтинг - это общая оценка качества продукта. Чем выше
                  рейтинг - тем привлекательнее продукт. Основные веса рейтинга
                  - стоимость для клиента и наша доходность. На рейтинг также
                  влияют эксклюзивность, наличие прямых договоров с банками и
                  другие показатели.”
                </p>
              </PopupContent>
            </PopupContainer>

            <ApplicationId>Общая информация</ApplicationId>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Тип финансирования</span>
                  <span>{product.financeTypeName}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <CardBank
                    onClick={() => this.navigateToBank(product.bankINN)}
                  >
                    <span>Банк</span>
                    <span>{product.bankName}</span>
                  </CardBank>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Минимальная сумма</span>
                  <span>{formSumStringThousands(product.minSize)}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Максимальная сумма</span>
                  <span>
                    {product.maxSize === 0
                      ? 'Без ограничений'
                      : product.maxSize === null
                        ? 'Нет данных'
                        : formSumStringThousands(product.maxSize)}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>МСП</span>
                  <span>{product.isForMicro ? 'да' : '-'}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>КСБ</span>
                  <span>{product.isForMedium ? 'да' : '-'}</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <Menu
              activeTab={this.state.activeTab}
              handleActiveTab={this.handleActiveTab}
              tabs={PRODUCT_TABS}
            />

            {this.state.activeTab === 'DEFAULT' && (
              <BasicParametersForProducts
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'MANAGERS' && (
              <>
                <ApplicationId>Кураторы</ApplicationId>
                <ContactsListByProduct
                  productId={product.id}
                  bankInn={product.bankINN}
                />
              </>
            )}

            {this.state.activeTab === 'PLATFORMS' && (
              <PlatformsForProducts productId={product.id} />
            )}

            {this.state.activeTab === 'CASHBACK' && (
              <CooperationForProducts
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'CLIENT' && (
              <CustomerRequirementsForProduct
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'CONTRACT' && (
              <ContractLogigForProduct
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'STOPS' && (
              <StopFactorsForProduct
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}

            {this.state.activeTab === 'PLEDGE' && (
              <PledgeForProducts
                product={product}
                roles={roles}
                permissions={permissions}
              />
            )}
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Products, User }: CRM) => ({
  product: Products.getProduct.product,
  status: Products.getProduct.status,
  error: Products.getProduct.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getProduct, setProductFormData, postCalculateRating },
    dispatch
  );

const ProductViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ProductView)
);

export { ProductViewConnect as ProductView };
