import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/postFactoringConnectionFactorRole';

import {
  POST_FACTORING_CONNECTION_FACTOR_ROLE,
  RequestDataType
} from 'entities/SCF/Factor/model/reducers/postFactoringConnectionFactorRole';

import SCFApi from 'entities/SCF/Factor/api';

function* postFactoringConnectionFactorRole(action: {
  type: POST_FACTORING_CONNECTION_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postFactoringConnectionFactorRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFactoringConnectionFactorRoleSaga() {
  yield takeLatest(
    POST_FACTORING_CONNECTION_FACTOR_ROLE.REQ,
    postFactoringConnectionFactorRole
  );
}
