import * as React from 'react';
import { connect } from 'react-redux';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { ApplicationTypes, DebtorsType, PledgeType } from 'shared/constants';
import { FactoringApplicationRead } from 'Application/types';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';

import {
  ApplicationViewConsumer,
  EachApplicationProps,
  EachApplicationView
} from 'Application/components';
import { Loader } from 'src/features/Layouts/components';

import { DebtorsContainer, DebtorBlock } from './styles';

interface StateToProps {
  inBankApplication: GetApplicationResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
}

type Props = StateToProps;

const FactoringView: React.FC<Props> = ({
  inBankApplication,
  statusInBankApplication
}) => {
  if (statusInBankApplication !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <ApplicationViewConsumer>
      {({ data }: EachApplicationProps<FactoringApplicationRead>) => (
        <EachApplicationView
          mainInfo={[
            {
              name: 'Тип финансирования',
              value: ApplicationTypes[data.financingType]
            },
            {
              name: 'Сумма сделки',
              value: formSumString(data.amount)
            },
            {
              name: 'Срок',
              value: formTermString(data.term)
            },
            {
              name: 'ИНН',
              value: data.code
            },
            {
              name: 'Рефинансирование',
              value: data.refinancing ? 'Да' : 'Нет'
            }
          ]}
          subInfo={[
            {
              name: 'Тип дебиторов',
              value: DebtorsType[data.debtorType]
            }
          ]}
          documents={{
            general: inBankApplication.agreementDocuments,
            company: inBankApplication.documents
          }}
        >
          {data.debtors.length > 0 && (
            <DebtorsContainer>
              {data.debtors.map((debtor, key) => (
                <DebtorBlock key={key}>
                  <p>
                    Дебитор: <span>{debtor.name}</span>
                  </p>
                  <p>
                    Лимит на дебитора:{' '}
                    <span>{formSumString(debtor.limit)}</span>
                  </p>
                  <p>
                    Отсрочка по договору:{' '}
                    <span>{formTermString(debtor.postponement)}</span>
                  </p>
                  <p>
                    Наличие залога: <span>{PledgeType[debtor.pledgeType]}</span>
                  </p>
                </DebtorBlock>
              ))}
            </DebtorsContainer>
          )}
        </EachApplicationView>
      )}
    </ApplicationViewConsumer>
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status
});

const FactoringViewConnect = connect<StateToProps>(mapStateToProps)(
  FactoringView
);

export { FactoringViewConnect as FactoringView };
