import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchAgent';

import { PATCH_AGENT } from '../reducers/patchAgent';
import QuestionnaireApi from '../api';

function* patchAgent(action: {
  type: PATCH_AGENT;
  inn: string;
  data: any;
}): Generator {
  try {
    yield call(QuestionnaireApi.patchAgent, action.inn, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchAgentSaga(): Generator {
  yield takeLatest(PATCH_AGENT.REQ, patchAgent);
}
