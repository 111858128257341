import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/changeApplicationStatus';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';

import {
  CHANGE_APPLICATION_STATUS,
  RequestDataType
} from 'Application/reducers/changeApplicationStatus';
import ApplicationApi from 'Application/api';

function* changheApplicationStatus(action: {
  type: CHANGE_APPLICATION_STATUS;
  data: RequestDataType;
}): Generator {
  try {
    yield call(ApplicationApi.changeApplicationStatus, action.data);

    yield put(got());
    // TODO: add condition:
    // only certain action.status and user role can run getApplicationsQuantity
    yield put(getApplicationsQuantity());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* changheApplicationStatusSaga(): Generator {
  yield takeLatest(CHANGE_APPLICATION_STATUS.REQ, changheApplicationStatus);
}
