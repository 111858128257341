import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/sendApplicationToBanks';
import { req as getApplication } from 'Application/actions/getApplication';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';

import {
  RequestDataType,
  SEND_APPLICATION_TO_BANKS
} from 'Application/reducers/sendApplicationToBanks';

import ApplicationApi from 'Application/api';

function* sendApplicationToBanks(action: {
  type: SEND_APPLICATION_TO_BANKS;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(ApplicationApi.sendApplicationToBanks, action.data);
    yield put(got());
    yield put(getApplicationsQuantity());
    yield put(getApplication({ id: action.data.id }));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendApplicationToBanksSaga() {
  yield takeLatest(SEND_APPLICATION_TO_BANKS.REQ, sendApplicationToBanks);
}
