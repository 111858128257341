import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/SCF/Debtor/model/actions/getContractByDebtor';

export interface GetContractByDebtorStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_CONTRACT_BY_DEBTOR {
  REQ = 'GET_CONTRACT_BY_DEBTOR_REQ',
  GOT = 'GET_CONTRACT_BY_DEBTOR_GOT',
  ERR = 'GET_CONTRACT_BY_DEBTOR_ERR'
}

export const initialState: GetContractByDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getContractByDebtor = (
  state: GetContractByDebtorStoreState = initialState,
  action: {
    type: GET_CONTRACT_BY_DEBTOR;
    data: ResponseData[];
    error: {};
  }
): GetContractByDebtorStoreState => {
  switch (action.type) {
    case GET_CONTRACT_BY_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTRACT_BY_DEBTOR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_CONTRACT_BY_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContractByDebtor;
