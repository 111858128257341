import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/InternalAgent/model/actions/getCheckSignature';
import { GET_CHECK_SIGNATURE_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/getCheckSignature';

import ApplicationApi from 'entities/Cabinet/InternalAgent/api';

function* getCheckSignature(action: {
  type: GET_CHECK_SIGNATURE_INTERNAL;
  data: RequestData;
}) {
  try {
    const formData = new FormData();
    formData.append('documentFile', action.data.documentFile);
    formData.append('signatureFile', action.data.signatureFile);

    const res = yield call(ApplicationApi.getCheckSignature, formData);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCheckSignatureSaga() {
  yield takeLatest(GET_CHECK_SIGNATURE_INTERNAL.REQ, getCheckSignature);
}
