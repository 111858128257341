import * as React from 'react';

import { Document } from 'Application/types';
import { formBackendDateString } from 'src/shared/utils/Utils';

import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';

import { DocumentsTable } from 'Layouts/components';

import { CrossBtn, OpenFileUploaderButton } from 'shared/styled';

import { FileListTable, TableFooter } from './styles';

export interface FileListProps {
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  files: Document[];
  multiple: boolean;
  openUploader: () => void;
  onFilesRemove: (data: DeleteDocumentRequestDataType) => void;
}

export const FilesList: React.FC<FileListProps> = React.memo(
  ({
    isDragActive,
    isDragAccept,
    isDragReject,
    files,
    multiple,
    openUploader,
    onFilesRemove
  }) => {
    const formTableRows = () =>
      files.map((file, index) => ({
        id: file.id,
        data: [
          `${index + 1}.`,
          file.name,
          formBackendDateString(file.createdDate),
          <CrossBtn
            onClick={() =>
              onFilesRemove({
                id: file.id,
                type: file.type
              })
            }
          />
        ]
      }));

    return (
      <FileListTable
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        headers={['№', 'Название', 'Дата добавления', '']}
        rows={formTableRows()}
        sizes={['8%', '57%', '30%', '5%']}
        footer={
          <TableFooter>
            <tr>
              <td>
                <OpenFileUploaderButton
                  onClick={openUploader}
                  disabled={!multiple && files.length > 0}
                >
                  Загрузить файлы
                </OpenFileUploaderButton>
              </td>
              <td>
                Мак.размер одного файла 50 МБ <br />
                Форматы файлов: zip, rar, jpeg, png, gif, doc, xls, pdf
              </td>
            </tr>
          </TableFooter>
        }
      />
    );
  }
);
