import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col } from 'react-grid-system';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  DOCUMENT_UPLOAD_TYPE,
  BorrowerCompany,
  Document
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';

import { req as getBorrowerCompaniesList } from 'BorrowerCompanies/actions/getBorrowerCompaniesList';
import { req as saveBorrowerCompaniesList } from 'BorrowerCompanies/actions/saveBorrowerCompaniesList';
import { req as createBorrowerCompany } from 'BorrowerCompanies/actions/createBorrowerCompany';
import { req as deleteBorrowerCompany } from 'BorrowerCompanies/actions/deleteBorrowerCompany';

import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import {
  RequestDataType as DeleteDocumentRequestDataType,
  SagaRequestDataType as DeleteDocumentSagaRequestDataType
} from 'Application/reducers/deleteDocument';
import { RequestDataType as GetBorrowerCompaniesListRequestDataType } from 'BorrowerCompanies/reducers/getBorrowerCompaniesList';
import { RequestDataType as SaveBorrowerCompaniesListDataType } from 'BorrowerCompanies/reducers/saveBorrowerCompaniesList';
import { RequestDataType as CreateBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/createBorrowerCompany';
import { RequestDataType as DeleteBorrowerCompanyDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

import { TRANSITION_DIRECTIONS } from 'Common';
import { InputFile } from 'shared/ui/InputFile';
import { BorrowerCompanyDefaultFileUploadBlock } from '../BorrowerCompanyFileUploadBlock/BorrowerCompanyDefaultFileUploadBlock/BorrowerCompanyDefaultFileUploadBlock';

import { ApplicationFormStyled } from 'Application/components/ApplicationForm/ApplicationProcess/styles';

import { FILE_UPLOAD_BLOCK_TYPE } from '../BorrowerCompanyFileUploadBlock';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import { setFileUploadBlock } from 'Application/actions/setFileUploadBlock';
import { SetFileUploadBlockType } from 'src/features/Application/reducers/setFileUploadBlockType';

interface State {
  submitClicked: boolean;
  dynamicValidatiorFields: any;
}

interface OwnProps {
  goBack: () => void;
  borrowerCompanyFileUploadBlock?: any; // TODO create interface for this
  borrowerCompanyFileUploadBlockType?: FILE_UPLOAD_BLOCK_TYPE;
  disableApplicationDocumentsUpload: boolean;
}

interface StateToProps {
  groupDocuments: Document[];
  companies: BorrowerCompany[];
  uploadStatus: REQUEST_STATUSES;
  borrowerCompaniesUploadStatus: REQUEST_STATUSES;
  allowFlags: GetApplicationFlagsResponseDataType;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
  setFileUploadBlock: (data: SetFileUploadBlockType) => void;

  getBorrowerCompaniesList: (
    data: GetBorrowerCompaniesListRequestDataType
  ) => void;
  saveBorrowerCompaniesList: (data: SaveBorrowerCompaniesListDataType) => void;
  createBorrowerCompany: (data: CreateBorrowerCompanyDataType) => void;
  deleteBorrowerCompany: (data: DeleteBorrowerCompanyDataType) => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> &
  OwnProps &
  StateToProps &
  DispatchToProps;

class BorrowerCompanies extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    borrowerCompanyFileUploadBlock: BorrowerCompanyDefaultFileUploadBlock
    //borrowerCompanyFileUploadBlockType: FILE_UPLOAD_BLOCK_TYPE.DEFAULT
  };

  state = {
    submitClicked: false,
    dynamicValidatiorFields: {}
  };

  componentDidMount() {
    const { companies, getBorrowerCompaniesList, match } = this.props;

    companies.length === 0 &&
      getBorrowerCompaniesList({ applicationId: +match.params.id });
  }

  getDocumentsCount(companies) {
    let count = 0;
    for (const company of companies) count += company.documents.length;
    return count;
  }

  onGroupFilesUpload = files => {
    this.props.uploadDocuments({
      id: +this.props.match.params.id,
      files,
      type: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });
  };

  onGroupFilesRemove = (data: DeleteDocumentRequestDataType) => {
    this.props.deleteDocument({
      ...data,
      subType: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
    });
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { setInitializationFormData, companies } = this.props;
    const { name, value, checked } = e.currentTarget;
    const [fieldName, companyId] = name.split('-');

    const checkRoles = ({ roles }, value) =>
      roles.includes(value)
        ? roles.filter(item => item !== value)
        : [...roles, value];

    const newCompanies = companies.map((item, index) => {
      if (item.id === parseInt(companyId, 10)) {
        item[fieldName] =
          fieldName === 'roles' ? checkRoles(item, value) : value;
      }
      return item;
    });

    setInitializationFormData({
      borrowerCompanies: newCompanies
    });
  };

  onSubmit = () => {
    const { companies, saveBorrowerCompaniesList, match, history } = this.props;

    const { id, inBankId } = match.params;

    this.setState({ submitClicked: true });

    companies.length !== 0 &&
      saveBorrowerCompaniesList({ borrowerCompanies: companies });

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    // id and inBankId availability means that existing application is editing
    if (!!inBankId) {
      history.push({
        pathname: `/cabinet/${role}/application/${id}/${inBankId}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });

      return;
    }

    if (!!id) {
      history.push({
        pathname: `/cabinet/${role}/application/${id}`,
        state: TRANSITION_DIRECTIONS.FORWARD
      });
    }
  };

  render() {
    const { groupDocuments, uploadStatus } = this.props;

    return (
      <ApplicationFormStyled>
        <Col lg={4}>
          <InputFile
            label="Прочие документы"
            name="file"
            required={false}
            files={groupDocuments}
            multiple={true}
            uploadStatus={uploadStatus}
            onFilesUpload={this.onGroupFilesUpload}
            onFilesRemove={this.onGroupFilesRemove}
          />
        </Col>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application, BorrowerCompanies }: STORE) => ({
  companies: Application.setInitializationFormData.borrowerCompanies,
  groupDocuments: Application.setInitializationFormData.groupDocuments,
  uploadStatus: Application.uploadDocuments.status,
  borrowerCompaniesUploadStatus:
    BorrowerCompanies.uploadBorrowerCompanyDocuments.status,
  allowFlags: Application.getApplicationFlags.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      setInitializationFormData,
      uploadDocuments,
      deleteDocument,
      getBorrowerCompaniesList,
      saveBorrowerCompaniesList,
      createBorrowerCompany,
      deleteBorrowerCompany,
      setFileUploadBlock
    },
    dispatch
  );

const BorrowerCompaniesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BorrowerCompanies)
);

export { BorrowerCompaniesConnect as BorrowerCompaniesOtherDocuments };
