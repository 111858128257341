import styled, { keyframes } from 'styled-components';
import {
  lighterBlue,
  rr,
  rl,
  blue,
  gray,
  black,
  navyBlue
} from 'shared/styled';

export const CreateLimitsNudge = styled.div`
  position: relative;
  width: 1000px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-family: ${rr};
    font-weight: 400;
  }
`;

export const BtnsBlock = styled.div`
  margin-top: 10px;
  width: auto;
  height: auto;
  display: flex;
`;

export const ConfirmSection = styled.div`
  margin-top: 30px;
  width: 1060px;
  height: auto;
  display: flex;
  justify-content: flex-end;
`;

export const SignSection = styled.div`
  margin-top: 30px;
  width: 1285px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SignTextBlock = styled.div`
  margin-bottom: 15px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    margin: 0;
  }
`;

export const ConfirmSectionRightPart = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ConfirmSectionCheckBox = styled.div`
  margin-top: 30px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  p {
    margin-top: -1px;
    font-family: ${rr};
    width: 250px;
  }
`;

export const BackBtn = styled.div`
  width: auto;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  color: #3f4e65;
  margin: 0;
`;

export const InnFilterArea = styled.div`
  width: 450px;
  margin-top: 50px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;
export const ListSection = styled.section`
  margin-top: 40px;
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullList = styled.section`
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${blue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${blue};
  }
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const ListSectionOne = styled.section`
  margin-top: 30px;
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    width: 180px;
  }
`;

export const FullListOne = styled.section`
  margin-top: 20px;
  width: 500px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DiscountItem = styled.div`
  width: 420px;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

export const DatePopup = styled.div`
  padding: 30px 20px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  z-index: 30;
  margin-top: 45vh;
  margin-left: 45vw;
  display: none;
  background-color: #ffffff;
  border: 1px solid ${lighterBlue};
`;

export const DatePopupBtns = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const CreateBtnPopup = styled.div`
  margin-top: 20px;
  width: 120px;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const CreateBtnPopupText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const CreateBtn = styled.div`
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
  margin: 0;
  color: #ffffff;
`;

export const CancelBtn = styled.div`
  margin-top: 20px;
  width: 90px;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f53737;
  transition: 0.2s;
  margin-left: 20px;
  &:hover {
    background-color: #c12323;
  }
`;

export const CancelBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 0px;
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
`;

export const DownloadLink = styled.a.attrs({
  ['aria-label']: 'download-document',
  download: true
})`
  width: fit-content;
  margin-bottom: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const ListAndEffect = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
`;

export const LeftContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const EconomyEffectContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${lighterBlue};
  padding: 20px 20px 15px 25px;
  overflow: hidden;
`;

export const EconomyEffectBlock = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const EconomyEffectRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 350px;
`;

export const EconomyEffectDescAndValue = styled.div`
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 4px;
`;

export const EconomyEffectText = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${gray};
  margin: 0;
`;

export const EconomyEffectTextMiddle = styled.p`
  font-family: ${rl};
  font-size: 14px;
  color: ${black};
  margin: 0;
`;

export const EconomyEffectTextLarge = styled.p`
  font-family: ${rl};
  font-size: 16px;
  color: ${black};
  margin: 0;
`;

export const EconomyEffectTitle = styled.p`
  font-family: ${rl};
  font-size: 16px;
  color: ${black};
  text-align: start;
  width: 100%;
  margin: 0 0 16px;
`;

export const ApprovedMessages = styled.p`
  font-family: ${rl};
  font-size: 12px;
  color: ${gray};
  margin: 0 0 10px;
`;

export const ApprovedMessagesContainer = styled.div`
  margin-top: 16px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const DocumentHint = styled.div`
  width: 300px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  top: 0;
  left: 100%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }

    & > div {
      & > p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const CopyBlock = styled.div`
  cursor: pointer;
  width: fit-content;
  font-family: ${rr};

  &::after {
    content: '${({ text }) => text}';
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 150px;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    box-shadow: -1px 6px 17px 1px rgba(0, 0, 0, 0.16);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:hover::after {
    opacity: ${({ text }) => (text ? 1 : null)};
    visibility: ${({ text }) => (text ? 'visible' : null)};
  }

  :hover{
    color: ${blue};
  }
`;

export const Status = styled.div`
  width: fit-content;
  margin: 10px 0 0 0;
  border-radius: 6px;
  padding: 13px 24px;
  color: ${({ status }) => (status === 'Подписана' ? 'white' : navyBlue)};
  font-family: ${rr};
  font-size: 16px;

  background-color: ${({ status }) =>
    status === 'Ожидает согласования' || status === 'Ожидает подписания'
      ? 'rgba(213, 232, 253, 1)'
      : status === 'Отклонена'
        ? 'rgba(253, 241, 224, 1)'
        : status === 'Подписана' && '#1CCA94'};
`;
