import styled from 'styled-components';

import { rl, rr, rm, gray, black, navyBlue } from 'shared/styled';
import { TabItem } from 'Layouts/components/Tabs/styles';

export const CompanyDocumentsTitle = styled.h3`
  margin: 20px 0;
  font-family: ${rl};
  font-size: 1.2rem;
  color: ${gray};
`;

export const CompanyProperty = styled.p`
  margin: 0 0 20px 0;

  font-family: ${rr};
  font-size: 1.2rem;
  color: ${navyBlue};

  span {
    font-family: ${rm};
    color: ${black};
  }
`;

export const ViewCompanyTabItem = styled(TabItem)`
  margin-top: 0;
  padding: 0;

  flex-direction: column;
  align-items: flex-start;
`;
