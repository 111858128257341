import {
  GET_DOCUMENT_LIST_EXTERNAL,
  ResponseData
} from 'entities/Cabinet/ExternalAgent/model/reducers/getDocumentListExternal';

export const req = (id: string) => ({
  type: GET_DOCUMENT_LIST_EXTERNAL.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_DOCUMENT_LIST_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DOCUMENT_LIST_EXTERNAL.ERR,
  error
});

export const reset = () => ({
  type: GET_DOCUMENT_LIST_EXTERNAL.RESET
});
