import { GET_NON_FINANCIAL_DOCUMENTS } from '../reducers/getNonFinancialDocuments';
import { NonFinancialDocumentsData } from '../types';

export const req = (name: string, inn: string) => ({
  type: GET_NON_FINANCIAL_DOCUMENTS.REQ,
  name,
  inn
});

export const got = (data: NonFinancialDocumentsData) => ({
  type: GET_NON_FINANCIAL_DOCUMENTS.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_NON_FINANCIAL_DOCUMENTS.ERR,
  error
});
