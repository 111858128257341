import * as React from 'react';
import { LoaderStyled, LoadingIndicator } from './styles';
const arr = ['', '', '', '', ''];

interface Props {
  className?: string;
}

export const Loader: React.FC<Props> = ({ className }: Props) => (
  <LoaderStyled className={!!className ? className : ''}>
    <LoadingIndicator>
      {arr.map((item, key) => (
        <div className={`bar-${key + 1}`} key={key} />
      ))}
    </LoadingIndicator>

    <p>Загрузка страницы …</p>
  </LoaderStyled>
);
