import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchStopFactors';

import { PATCH_STOP_FACTORS_ACTIONS } from '../reducers/patchStopFactors';
import StopFactorsApi from '../api';

function* patchStopFactors(action: {
  type: PATCH_STOP_FACTORS_ACTIONS;
  id: string;
  data: any;
}): Generator {
  try {
    yield call(StopFactorsApi.patchStopFactors, action.id, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchStopFactorsSaga(): Generator {
  yield takeLatest(PATCH_STOP_FACTORS_ACTIONS.REQ, patchStopFactors);
}
