import { call, put, takeEvery } from 'redux-saga/effects';
import { got, err, RequestData } from 'entities/Cabinet/InternalAgent/model/actions/patchTenderUpdateByInternal';
import { PATCH_TENDER_UPDATE_BY_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/patchTenderUpdateByInternal';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* patchTenderUpdateByInternal(action: {
  type: PATCH_TENDER_UPDATE_BY_INTERNAL;
  id: string;
  data: RequestData;
}): Generator {
  try {
    yield call(
      InternalAgentRoleApi.patchTenderUpdateByInternal,
      action.id,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchTenderUpdateByInternalSaga(): Generator {
  yield takeEvery(
    PATCH_TENDER_UPDATE_BY_INTERNAL.REQ,
    patchTenderUpdateByInternal
  );
}
