import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';
import { APPLICATION_TYPES } from 'Application/types';

import { req as getInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as getApplication } from 'Application/actions/getApplication';
import { RequestDataType } from 'Application/reducers/getApplication';

import {
  req as getInBankApplication,
  reset as resetInBankApplication
} from 'Application/actions/getInBankApplication';

import { req as getCompanyHints } from 'Application/actions/getCompanyHints';
import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { req as getActivitiesByApplication } from 'src/features/Application/actions/getActivitiesByApplication';
import {
  req as getFinancialsSummary,
  reset as resetFinancialsSummary
} from 'src/features/Application/actions/getFinancialsSummary';

import {
  RequestDataType as GetApplicationRequestDataType,
  ResponseDataType as GetApplicationResponseDataType
} from 'Application/reducers/getApplication';

import {
  ApplicationViewProvider,
  ApplicationViewConsumer,
  ApplicationViewContextType,
  FactoringView,
  FinancingView
} from 'Application/components';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { PropertyFinancingView } from './PropertyFinancingView/PropertyFinancingView';
import { redirectToApplication } from 'shared/lib/routing/redirectToApplication';

export interface EachApplicationProps<T> {
  data: T;
  editDataRoute?: string;
  editDocsRoute?: string;
  canEdit: boolean;
}

interface StateToProps {
  internalApplication: any;
  internalStatus: REQUEST_STATUSES;
  internalError: ResponseError;
  application: GetApplicationResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusSelectApplication: REQUEST_STATUSES;
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  getApplication: (data: RequestDataType) => void;
  resetInBankApplication: () => void;
  getCompanyHints: (inn: string) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  getActivitiesByApplication: (leadId: string) => void;
  getFinancialsSummary: (inn: string) => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationView: React.FC<Props> = ({
  getInternalApplication,
  internalApplication,
  internalStatus,
  internalError,
  getApplication,
  application,
  status,
  error,
  getCompanyHints,
  getActivitiesByApplication,
  getFinancialsSummary,
  getProductApplication,
  permissions,
  getInBankApplication,
  resetFinancialsSummary,
  resetInBankApplication,
  resetSelectApplicationState,
  statusExternalApiGIR_BO,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusPostSendBank,
  statusSendApi,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;
      const segments = location.pathname.split('/');
      const roleIndex = segments.indexOf('cabinet') + 1;
      const role = segments[roleIndex];

      if (id && !inBankId) {
        getInBankApplication({ id: parseInt(id, 10) });

        if (role === 'internal') {
          getInternalApplication(id);
        } else {
          getApplication({ id: +id });
        }
      } else if (inBankId) {
        getInBankApplication({ id: parseInt(inBankId, 10) });

        if (role === 'internal') {
          getInternalApplication(inBankId);
        } else {
          getApplication({ id: +inBankId });
        }
      }

      if (
        !permissions.includes(USER_PERMISSIONS.CLIENT) &&
        !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
        !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
        !inBankId
      ) {
        getProductApplication(id);
        getActivitiesByApplication(id);
      }

      return () => {
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
        } else if (inBankId) {
          getInternalApplication(inBankId);
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
        ) {
          getCompanyHints(application.code);
          getFinancialsSummary(application.code);
        }
      }
    },
    [status]
  );

  React.useEffect(
    () => {
      if (internalStatus === REQUEST_STATUSES.GOT) {
        redirectToApplication(internalApplication);

        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
        ) {
          getCompanyHints(internalApplication.code);
          getFinancialsSummary(internalApplication.code);
        }
      }
    },
    [internalStatus]
  );

  React.useEffect(
    () => {
      if (statusExternalApiGIR_BO === REQUEST_STATUSES.GOT) {
        getFinancialsSummary(application.code);
      }
    },
    [statusExternalApiGIR_BO]
  );

  const defineApplicationType = (financingType: APPLICATION_TYPES) => {
    switch (financingType) {
      case APPLICATION_TYPES.FACTORING:
        return <FactoringView />;
      case APPLICATION_TYPES.FINANCING:
        return <FinancingView />;
      case APPLICATION_TYPES.PROPERTY_FINANCING:
        return <PropertyFinancingView />;
    }
  };

  return (
    <React.Fragment>
      {(status === REQUEST_STATUSES.REQUEST ||
        internalStatus === REQUEST_STATUSES.REQUEST) && <Loader />}
      {(status === REQUEST_STATUSES.GOT ||
        internalStatus === REQUEST_STATUSES.GOT) && (
        <ApplicationViewProvider>
          <ApplicationViewConsumer>
            {(context: ApplicationViewContextType) =>
              defineApplicationType(context.data.financingType)
            }
          </ApplicationViewConsumer>
        </ApplicationViewProvider>
      )}

      <ActionResponseBlock
        showIn={
          (error.code === 403 || internalError.code === 403) &&
          (status === REQUEST_STATUSES.ERROR ||
            internalStatus === REQUEST_STATUSES.ERROR)
        }
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={
          (error.code === 403 || internalError.code === 403) &&
          (status === REQUEST_STATUSES.ERROR ||
            internalStatus === REQUEST_STATUSES.ERROR)
        }
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ Application, InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  internalStatus: InternalAgentRole.getInternalApplication.status,
  internalError: InternalAgentRole.getInternalApplication.error,
  application: Application.getApplication.data,
  status: Application.getApplication.status,
  error: Application.getApplication.error,
  statusSelectApplication: Application.postSelectApplication.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInternalApplication,
      getApplication,
      getInBankApplication,
      resetInBankApplication,
      getCompanyHints,
      getProductApplication,
      resetSelectApplicationState,
      getActivitiesByApplication,
      getFinancialsSummary,
      resetFinancialsSummary
    },
    dispatch
  );

const ApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationView)
);

export { ApplicationViewConnect as ApplicationView };
