import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/postSupplierNotificationSettings';

import {
  POST_SUPPLIER_NOTIFICATION_SETTINGS,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/postSupplierNotificationSettings';

import SCFApi from 'entities/SCF/Supplier/api';

function* postSupplierNotificationSettings(action: {
  type: POST_SUPPLIER_NOTIFICATION_SETTINGS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postSupplierNotificationSettings,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postSupplierNotificationSettingsSaga() {
  yield takeLatest(
    POST_SUPPLIER_NOTIFICATION_SETTINGS.REQ,
    postSupplierNotificationSettings
  );
}
