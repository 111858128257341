import * as React from 'react';
import * as d3 from 'd3';

interface Props {
  data: number[];
  svgRef: React.MutableRefObject<any>;
  setSelectYear: (index: number) => void;
  selectedBar: number;
}

export const BarChart: React.FC<Props> = ({
  data,
  selectedBar,
  setSelectYear,
  svgRef
}) => {
  React.useEffect(
    () => {
      if (!data) return;

      const svg = d3.select(svgRef.current);

      svg.selectAll('*').remove();

      const height = 40;

      const yScale = d3
        .scaleLinear()
        .domain([Math.min(d3.min(data), 0), Math.max(d3.max(data), 0)])
        .range([height, 0]);

      const bars = svg.append('g').attr('transform', `translate(10, 0)`);

      const invisibleBars = svg
        .append('g')
        .attr('transform', `translate(10, 0)`);

      invisibleBars
        .selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10)
        .attr('y', 0)
        .attr('width', 8)
        .attr('height', height)
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === selectedBar ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });

      bars
        .selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10) // Равномерное распределение столбцов по горизонтали
        .attr('y', d => (d >= 0 ? yScale(d) : yScale(0)))
        .attr('width', 8) // Ширина столбцов
        .attr('height', d => Math.abs(yScale(0) - yScale(d)))
        .attr('fill', (d, i) => {
          if (i === selectedBar) {
            return '#0f285a';
          }
          if (d < 0) {
            return '#FDCBC2';
          } else {
            return '#87aad2';
          }
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === selectedBar ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });
    },
    [data, setSelectYear, selectedBar]
  );

  return <svg ref={svgRef} width="50" height="40" />;
};
