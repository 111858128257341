import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddedMonetaryClaimByDebtorStoreState extends Reducer {}

export enum POST_ADDED_MONETARY_CLAIM_BY_DEBTOR {
  REQ = 'POST_ADDED_MONETARY_CLAIM_BY_DEBTOR_REQ',
  GOT = 'POST_ADDED_MONETARY_CLAIM_BY_DEBTOR_GOT',
  ERR = 'POST_ADDED_MONETARY_CLAIM_BY_DEBTOR_ERR'
}

export const initialState: PostAddedMonetaryClaimByDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddedMonetaryClaimByDebtor = (
  state: PostAddedMonetaryClaimByDebtorStoreState = initialState,
  action: {
    type: POST_ADDED_MONETARY_CLAIM_BY_DEBTOR;
    error: {};
  }
): PostAddedMonetaryClaimByDebtorStoreState => {
  switch (action.type) {
    case POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddedMonetaryClaimByDebtor;
