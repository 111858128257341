import { createGlobalStyle } from 'styled-components';

export const CssAnimations = createGlobalStyle`
  .fade-default {
    visibility: hidden;
    opacity: 0;

    transition: opacity 0.2s, visibility 0.2s;
    
    &.fade-entered {
      visibility: visible;
      opacity: 1;
    }
  }

  .scale-default {
    transform: scale(0.9);
    transition: transform 0.2s;

    &.scale-entered {
      transform: scale(1);
    }
  }
`;

export const fadeClassName = animationState =>
  `fade-default fade-${animationState}`;

export const scaleClassName = animationState =>
  `scale-default scale-${animationState}`;
