import styled from 'styled-components';
import { rm } from 'shared/styled';

export const ProductNameContainer = styled.div`
  p:first-child {
    font-family: ${rm};
  }
`;

export const TDProductNameContainer = styled.td`
  white-space: normal !important;
`;

export const Description = styled.p`
  max-width: 40%;
  margin: 30px 0;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85%;

  p:first-child {
    font-size: 18px;
    font-family: ${rm};
  }

  button {
    width: fit-content;
    margin-top: 15px;
    padding: 13px 24px 13px 40px;
  }
`;
