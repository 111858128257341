import * as React from 'react';
import { MultiComponent } from 'shared/ui/MultiComponent';
import { Select } from 'shared/ui/Select';

interface Option {
  id: string;
  name: string;
  disabled?: boolean;
}

interface SelectProps {
  options: Option[];
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
}

interface Props extends SelectProps {
  selected: string[];
  onMultiAdd: (item: string) => void;
  onMultiRemove: (index: number) => void;
}

export class MultiSelect extends React.PureComponent<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const { selected, onMultiAdd } = this.props;

    if (selected.indexOf(value) === -1) {
      onMultiAdd(value);
    }
  };

  render() {
    const { selected, onMultiRemove } = this.props;
    const updatedProps = { ...this.props, onChange: this.onChange };

    return (
      <MultiComponent selected={selected} onDeleteSelected={onMultiRemove}>
        <Select {...updatedProps} />
      </MultiComponent>
    );
  }
}
