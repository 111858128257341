import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { history } from 'src/shared/utils/History';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

import {
  req as resetPasswordRequest,
  RequestDataType
} from 'User/actions/resetPassword';

import { ActionResponseBlock } from 'Layouts/components';
import { ResetPasswordForm } from 'User/components';

import { CenteringBlock, SuccessIcon, ErrorIcon } from 'shared/styled';
import { TitleStyled, ResetPasswordBox, GoToLoginBtn } from './styles';

interface State {
  userId: string;
  token: string;
}

interface StateToProps {
  resetPasswordStatus: REQUEST_STATUSES;
  resetPasswordError: ResponseError;
}

interface DispatchToProps {
  resetPasswordRequest: (data: RequestDataType) => void;
}

type Props = StateToProps & DispatchToProps;

class ResetPasswordPage extends React.Component<Props, State> {
  state = {
    userId: '',
    token: ''
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(location.search);

    this.setState({
      userId: searchParams.get('userId'),
      token: searchParams.get('token')
    });
  }

  onSubmit = password => {
    this.props.resetPasswordRequest({
      userId: this.state.userId,
      token: this.state.token,
      password
    });
  };

  render() {
    const { resetPasswordStatus } = this.props;

    const resetProcess = resetPasswordStatus === REQUEST_STATUSES.NONE;
    const resetComplete = resetPasswordStatus === REQUEST_STATUSES.GOT;
    const resetFail = resetPasswordStatus === REQUEST_STATUSES.ERROR;

    return (
      <CenteringBlock>
        {resetProcess && (
          <CenteringBlock>
            <ResetPasswordBox>
              <TitleStyled>Смена пароля</TitleStyled>
              <ResetPasswordForm onSubmit={this.onSubmit} />
            </ResetPasswordBox>
          </CenteringBlock>
        )}

        <ActionResponseBlock
          showIn={resetComplete}
          text="Пароль был успешно изменен"
          icon={<SuccessIcon />}
          button={
            <GoToLoginBtn
              onClick={() => history.push('/login')}
              template="nextBtn"
            >
              Войти
            </GoToLoginBtn>
          }
        />

        <ActionResponseBlock
          showIn={resetFail}
          text="К сожалению, восстановление пароля прошло неуспешно. Пожалуйста, попробуйте восстановить пароль еще раз."
          icon={<ErrorIcon />}
          button={
            <GoToLoginBtn
              onClick={() => history.push('/login')}
              template="backBtn"
            >
              Вернуться
            </GoToLoginBtn>
          }
        />
      </CenteringBlock>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  resetPasswordStatus: User.resetPassword.status,
  resetPasswordError: User.resetPassword.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ resetPasswordRequest }, dispatch);

const ResetPasswordPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage);

export { ResetPasswordPageConnect as ResetPasswordPage };
