import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getOneDebtorRoleByGuid';

export interface GetOneDebtorRoleByGuidStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_ONE_DEBTOR_ROLE_BY_GUID {
  GET_ONE_DEBTOR_ROLE_BY_GUID_REQ = 'GET_ONE_DEBTOR_ROLE_BY_GUID_REQ',
  GET_ONE_DEBTOR_ROLE_BY_GUID_GOT = 'GET_ONE_DEBTOR_ROLE_BY_GUID_GOT',
  GET_ONE_DEBTOR_ROLE_BY_GUID_ERR = 'GET_ONE_DEBTOR_ROLE_BY_GUID_ERR'
}

export const initialState: GetOneDebtorRoleByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    createdDateTime: null,
    debtorName: '',
    supplierInn: '',
    supplierName: '',
    payDate: null,
    monetaryClaimsTotalBaseSum: 0,
    monetaryClaimsTotalVatSum: 0,
    monetaryClaimsTotalFullSum: 0,
    firstBasePaymentDate: null,
    lastBasePaymentDate: null,
    baseDiscountRate: 0,
    firstPaymentShare: 0,
    payerType: null,
    statusDebtor: '',
    archiveFileIdentifier: '',
    agreementFileIdentifier: '',
    agreementFilename: '',
    supplierSignatureFileIdentifier: '',
    supplierSignatureFilename: '',
    debtorSignatureFileIdentifier: '',
    debtorSignatureFilename: '',
    rejectSubject: null,
    rejectComment: '',
    contractId: null,
    supplierCertificateSerial: null,
    supplierSigneeInn: null,
    supplierSigneePersonInn: null,
    investorCertificateSerial: null,
    investorSigneeInn: null,
    investorSigneePersonInn: null,
    keyRate: null,
    keyRateIncome: null,
    platformFee: null,
    debtorInspectorUserId: null,
    debtorInspectorComment: null,
    debtorSigneeName: null,
    status: null
  },
  error: {}
};

const getOneDebtorRoleByGuid = (
  state: GetOneDebtorRoleByGuidStoreState = initialState,
  action: {
    type: GET_ONE_DEBTOR_ROLE_BY_GUID;
    data: ResponseDataType;
    error: {};
  }
): GetOneDebtorRoleByGuidStoreState => {
  switch (action.type) {
    case GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getOneDebtorRoleByGuid;
