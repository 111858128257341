import { FactoringLimitsData } from '../actions/setFactoringLimitFormData';
import { RequestData as GetFactoringLimitsListRequestData } from '../actions/getFactoringLimitsList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class FactoringLimitsApi {
  static sendFactoringLimitData(data: FactoringLimitsData) {
    return ApiRequest('/api/factoring_limit', 'post', data);
  }

  static getFactoringLimit(id: number) {
    return ApiRequest(`/api/factoring_limit/id/${id}`);
  }

  static getAllFactoringLimits(data: GetFactoringLimitsListRequestData) {
    return ApiRequest('/api/factoring_limit/all', 'get', data);
  }

  static getFactoringLimitsByDebtorList(debtorInn: string) {
    return ApiRequest(`/api/factoring_limit/debtor/${debtorInn}`);
  }
}
