import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err, RequestDataType } from 'Business/actions/businessRegister';
import { BUSINESS_REGISTER } from 'Business/reducers/businessRegister';
import BusinessApi from 'Business/api';

function* businessRegister(action: {
  type: BUSINESS_REGISTER;
  data: RequestDataType;
  headers?: { apiKey: string };
}) {
  try {
    if (action.headers && action.headers.apiKey) {
      yield call(BusinessApi.register, action.data, {
        apiKey: action.headers.apiKey
      });
    } else {
      yield call(BusinessApi.register, action.data);
    }

    yield put(got(action.data));
  } catch (e) {
    console.log(e);
    yield put(err(e));
  }
}

export default function* businessRegisterSaga() {
  yield takeLatest(BUSINESS_REGISTER.REQ, businessRegister);
}
