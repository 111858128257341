import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostCreateDiscountAuctionsStoreState = Reducer;

export enum POST_CREATE_DISCOUNT_AUCTIONS {
  REQ = 'POST_CREATE_DISCOUNT_AUCTIONS_REQ',
  GOT = 'POST_CREATE_DISCOUNT_AUCTIONS_GOT',
  ERR = 'POST_CREATE_DISCOUNT_AUCTIONS_ERR'
}

export const initialState: PostCreateDiscountAuctionsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postCreateDiscountAuctions = (
  state: PostCreateDiscountAuctionsStoreState = initialState,
  action: {
    type: POST_CREATE_DISCOUNT_AUCTIONS;
    error: {};
  }
): PostCreateDiscountAuctionsStoreState => {
  switch (action.type) {
    case POST_CREATE_DISCOUNT_AUCTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_CREATE_DISCOUNT_AUCTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_CREATE_DISCOUNT_AUCTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postCreateDiscountAuctions;
