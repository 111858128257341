import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_DOWNLOAD_NON_FIN_SIGNATURE,
  err
} from '../actions/getDonwloadNonFinDocumentSignature';

function* getDownloadNonFinSignature(action: {
  type: GET_DOWNLOAD_NON_FIN_SIGNATURE;
  guid: string;
}): Generator {
  try {
    const url = `/api/internal-agent/nonfinancial-document/download_signature/${
      action.guid
    }`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.sig');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDossierSaga(): Generator {
  yield takeLatest(
    GET_DOWNLOAD_NON_FIN_SIGNATURE.REQ,
    getDownloadNonFinSignature
  );
}
