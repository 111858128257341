import styled, { keyframes } from 'styled-components';
import { gray, lightGray, navyBlue, red, rl } from 'shared/styled';

export const SummContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 30% 55%;
  column-gap: 30px;

  div {
    text-align: end;
  }

  p {
    color: ${gray};
    margin: 0;
  }
`;

export const SummDataContainer = styled.div`
  div {
    display: grid;
    grid-template-columns: 1fr 60px;
    column-gap: 10px;

    p:last-of-type {
      text-align: start;
    }
  }
`;

export const NoDataSummContainer = styled.div`
  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const SupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  p {
    margin: 0;

    &:last-child {
      white-space: pre-wrap;
    }
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  cursor: pointer;

  &:hover{
    svg {
      color: #0085FE;
    }
  }

  svg {
    color: #9FA6B2;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    width: max-content;
    bottom: 4px;
    right: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TDSupplier = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const TDSupplyContract = styled.td`
  position: relative;
  overflow: visible !important;
  text-align: center !important;
  white-space: pre-wrap !important;

  & > div {
    justify-content: center;
  }
`;

export const SupplyContract = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DocumentHint = styled.div`
  min-width: 250px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  bottom: 0;
  left: 80%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const Status = styled.div`
  width: 185px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ status }) =>
    status === 10
      ? 'rgba(213, 232, 253, 1)'
      : status === 20
        ? lightGray
        : status === 11
          ? 'rgba(253, 241, 224, 1)'
          : 'transparent'};
`;

export const NoData = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 32px;
      font-family: ${rl};
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const StatusAuction = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 5px;
  padding: 7px 5px;
  border: 1px solid #e99f3b;
  transition: background-color 0.7s ease, width 0.5s ease;

  ${TooltipBoxStyle}:hover > {
    svg {
      color: ${navyBlue} !important;
    }
  }

  &:hover {
    background-color: #e99f3b;

    p,
    svg {
      color: white !important;
    }
  }

  svg:hover {
    color: ${navyBlue};
  }

  p {
    margin: 0;
  }

  svg {
    font-size: 16px;
    transition: color 0.3s ease;
  }
`;

export const SupplyContractNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;

  p {
    margin: 0;
  }

  & > p:nth-child(2) {
    color: ${red};
    font-size: 12px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;
