import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/Person/actions/getPersonOfCompanies';
import { GET_PERSON_OF_COMPANIES } from 'src/features/Person/reducers/getPersonOfCompanies';
import PersonApi from 'src/features/Person/api';

function* getPersonOfCompanies(action: {
  type: GET_PERSON_OF_COMPANIES;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(PersonApi.getPersonOfCompanies, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPersonOfCompaniesSaga(): Generator {
  yield takeLatest(GET_PERSON_OF_COMPANIES.REQ, getPersonOfCompanies);
}
