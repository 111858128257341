import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'entities/SCF/Internal/model/actions/getMonetaryClaim';

import { GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT } from 'entities/SCF/Internal/model/reducers/getMonetaryClaimOne';
import InternalAgentApi from 'entities/SCF/Internal/api';

function* getMonetaryClaimOne(action: {
  type: GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentApi.getMonetaryClaimOne,
      action.guid
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMonetaryClaimOneSaga(): Generator {
  yield takeLatest(
    GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.REQ,
    getMonetaryClaimOne
  );
}
