import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchStopFactors extends Reducer {}

export enum PATCH_STOP_FACTORS_ACTIONS {
  REQ = 'PATCH_STOP_FACTORS_REQ',
  GOT = 'PATCH_STOP_FACTORS_GOT',
  ERR = 'PATCH_STOP_FACTORS_ERR'
}

export const initialState: PatchStopFactors = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchStopFactors = (
  state: PatchStopFactors = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchStopFactors => {
  switch (action.type) {
    case PATCH_STOP_FACTORS_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_STOP_FACTORS_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_STOP_FACTORS_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchStopFactors;
