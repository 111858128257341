import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostFactoringConnectionSupplierRoleStoreState = Reducer;

export enum POST_FACTORING_CONNECTION_SUPPLIER_ROLE {
  REQ = 'POST_FACTORING_CONNECTION_SUPPLIER_ROLE_REQ',
  GOT = 'POST_FACTORING_CONNECTION_SUPPLIER_ROLE_GOT',
  ERR = 'POST_FACTORING_CONNECTION_SUPPLIER_ROLE_ERR'
}

export interface RequestDataType {
  debtorINN: string;
  debtorName: string;
  factorINN: string;
  factorName: string;
  visibleToDebtor: boolean;
  userID: number;
}

export const initialState: PostFactoringConnectionSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFactoringConnectionSupplierRole = (
  state: PostFactoringConnectionSupplierRoleStoreState = initialState,
  action: {
    type: POST_FACTORING_CONNECTION_SUPPLIER_ROLE;
    error: {};
  }
): PostFactoringConnectionSupplierRoleStoreState => {
  switch (action.type) {
    case POST_FACTORING_CONNECTION_SUPPLIER_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FACTORING_CONNECTION_SUPPLIER_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_FACTORING_CONNECTION_SUPPLIER_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postFactoringConnectionSupplierRole;
