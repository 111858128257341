import styled from 'styled-components';
import { HeaderHeight, SidebarWidth } from 'shared/styled';

export const SidebarStyled = styled.aside`
  width: ${SidebarWidth};
  height: 100%;

  z-index: 2;
  position: fixed;

  background-color: #252b40;
`;

export const SidebarInner = styled.div`
  height: 100%;
  margin-top: ${HeaderHeight};
  padding: 20px 20px;
`;

export const NavList = styled.nav`
  width: 100%;
  height: 90%;

  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      button {
        margin-bottom: 10px;
      }
    }
  }
`;
