import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { STORE } from 'src/globaltypes';
import { req as getCertificate } from 'src/features/SCF/actions/getCertificate';
import { ResponseDataType as GetCertificateResponseDataType } from 'src/features/SCF/actions/getCertificate';
import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import { CertificatesTableView } from './CertificatesTableView/CertificatesTableView';

interface OwnProps {
  setSignedCertificates: (guid: string) => void;
  setIsSignedCertificates: (isActive: boolean) => void;
  setIsCertificatesError: (isActive: boolean) => void;
  setIsSigningApplicationModal: (isActive: boolean) => void;
}

interface StateToProps {
  certificate: GetCertificateResponseDataType;
}

interface DispatchToProps {
  getCertificate: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps & OwnProps;

class SignedCertificatesList extends React.Component<Props> {
  componentDidMount() {
    this.props.getCertificate();
  }

  onCertificateClick = (guid: string) => {
    this.props.setSignedCertificates(guid);
    this.props.setIsSignedCertificates(false);
    this.props.setIsSigningApplicationModal(true);
  };

  formCertificateData = () => {
    return this.props.certificate.items.map(this.certificateToView);
  };

  certificateToView = (certificate: CertificateType) => {
    const viewItem: CertificateType = {
      guid: certificate.guid,
      id: certificate.id,
      companyInn: certificate.companyInn,
      companyOgrnOrOgrnIP: certificate.companyOgrnOrOgrnIP,
      companyName_O: certificate.companyName_O,
      companyName_CN: certificate.companyName_CN,
      companyCountryC: certificate.companyCountryC,
      companyRegionS: certificate.companyRegionS,
      companyLocationL: certificate.companyLocationL,
      companyAddressStreet: certificate.companyAddressStreet,
      positionT: certificate.positionT,
      personSurNameSN: certificate.personSurNameSN,
      personNameG: certificate.personNameG,
      personInn: certificate.personInn,
      personEmailE: certificate.personEmailE,
      personSnils: certificate.personSnils,
      certValidFrom: certificate.certValidFrom,
      certValidUntil: certificate.certValidUntil,
      keyValidFrom: certificate.keyValidFrom,
      keyValidUntil: certificate.keyValidUntil,
      issuerInn: certificate.issuerInn,
      issuerOGRN: certificate.issuerOGRN,
      issuerName_O: certificate.issuerName_O,
      issuerName_CN: certificate.issuerName_CN,
      issuerTypeOU: certificate.issuerTypeOU,
      issuerCountryC: certificate.issuerCountryC,
      issuerRegionS: certificate.issuerRegionS,
      issuerLocationL: certificate.issuerLocationL,
      issuerAddressStreet: certificate.issuerAddressStreet,
      issuerEmailE: certificate.issuerEmailE,
      serialNumber: certificate.serialNumber,
      isDeleted: certificate.isDeleted,
      isAvailable: certificate.isAvailable
    };
    return viewItem;
  };

  render() {
    return (
      <CertificatesTableView
        list={this.formCertificateData()}
        onCertificateClick={this.onCertificateClick}
      />
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  certificate: SCF.getCertificate.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCertificate }, dispatch);

const SignedCertificatesListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SignedCertificatesList)
);

export { SignedCertificatesListConnect as SignedCertificatesListPopup };
