import { START_RATE_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getStartRateSupplierRole';

export interface ResponseDataType {
  regData: number;
  bankAccountsData: number;
  certificateData: number;
  debtorData: number;
  supplyContractsData: number;
}

export const req = () => ({ type: START_RATE_SUPPLIER_ROLE.REQ });
export const got = (data: ResponseDataType) => ({
  type: START_RATE_SUPPLIER_ROLE.GOT,
  data
});
export const err = (error: {}) => ({
  type: START_RATE_SUPPLIER_ROLE.ERR,
  error
});
