import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetSupplierCounterSidebarStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  monetaryClaimsQty: number;
  epaQty: number;
  newBuyersQty: number;
}

export enum GET_SUPPLIER_COUNTER_SIDEBAR {
  REQ = 'GET_SUPPLIER_COUNTER_SIDEBAR_REQ',
  GOT = 'GET_SUPPLIER_COUNTER_SIDEBAR_GOT',
  ERR = 'GET_SUPPLIER_COUNTER_SIDEBAR_ERR'
}

export const initialState: GetSupplierCounterSidebarStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    epaQty: 0,
    monetaryClaimsQty: 0,
    newBuyersQty: 0
  },
  error: {}
};

const getSupplierCounterSidebar = (
  state: GetSupplierCounterSidebarStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetSupplierCounterSidebarStoreState => {
  switch (action.type) {
    case GET_SUPPLIER_COUNTER_SIDEBAR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_SUPPLIER_COUNTER_SIDEBAR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_SUPPLIER_COUNTER_SIDEBAR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getSupplierCounterSidebar;
