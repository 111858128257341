import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES } from 'globaltypes';

import { req as checkStatus } from 'ExternalAgent/actions/externalAgentStatus'; // ЛИШНЯЯ СТРОКА ОТНОСЯЩАЯСЯ К ПРОВЕРКЕ ТИПА АГЕНТА
//import { EXTERNAL_AGENT_STATUSES } from 'ExternalAgent/reducers/externalAgentStatus';

import { CRMApproved } from 'InternalAgent/components';
import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import { PageWrapper } from 'shared/styled';

interface StateToProps {
  requestStatus: REQUEST_STATUSES;
  //agentStatus: EXTERNAL_AGENT_STATUSES;
}

interface DispatchToProps {
  checkStatus: () => void;
}

type Props = StateToProps & DispatchToProps;

class InternalAgentCRM extends React.Component<Props> {
  componentDidMount() {
    this.props.checkStatus();
  }

  showContent = () => {
    //switch (this.props.agentStatus) {
    //  case EXTERNAL_AGENT_STATUSES.NOT_APPROVED:
    //    return (
    //      <PageWrapper>
    //        <ActionResponseBlock
    //          title={
    //            <React.Fragment>
    //              Учетная запись еще не подтверждена
    //              <br /> нашим менеджером
    //            </React.Fragment>
    //          }
    //          text="Как только наш менеджер проверит все данные, вы обязательно получите уведомление, про изменения статуса заявки на ваш адрес электроной почты"
    //        />
    //      </PageWrapper>
    //    );

    //  case EXTERNAL_AGENT_STATUSES.REJECTED:
    //    return (
    //      <PageWrapper>
    //        <ActionResponseBlock
    //          title="Ваша учетная запись отклонена"
    //          text=" Команда FinFactory рассмотрела Ваш запрос на регистрацию в качестве внешнего агента.  К сожалению, Ваша заявка отклонена."
    //        />
    //      </PageWrapper>
    //    );

    //  case EXTERNAL_AGENT_STATUSES.APPROVED:
        return <CRMApproved />;

    //  default:
    //    return (
    //      <ActionResponseBlock
    //        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
    //      />
    //    );
    //}
  };

  render() {
    //const { requestStatus, agentStatus } = this.props;
    const { requestStatus } = this.props;

    return (
      <React.Fragment>
        {requestStatus === REQUEST_STATUSES.REQUEST && <Loader />}
        {requestStatus === REQUEST_STATUSES.GOT && this.showContent()}
        <ActionResponseBlock
          showIn={requestStatus === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ ExternalAgent }: STORE) => ({
  requestStatus: ExternalAgent.externalAgentStatus.status,
  agentStatus: ExternalAgent.externalAgentStatus.data.status
});

const mapDispatchToProps = (dispatch: Dispatch) => // ЛИШНЯЯ СТРОКА ОТНОСЯЩАЯСЯ К ПРОВЕРКЕ ТИПА АГЕНТА
  bindActionCreators({ checkStatus }, dispatch); // ЛИШНЯЯ СТРОКА ОТНОСЯЩАЯСЯ К ПРОВЕРКЕ ТИПА АГЕНТА

const InternalAgentCRMConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps // ЛИШНЯЯ СТРОКА ОТНОСЯЩАЯСЯ К ПРОВЕРКЕ ТИПА АГЕНТА
  )(InternalAgentCRM)
);

export { InternalAgentCRMConnect as InternalAgentCRM };
