import { GET_HOLDING } from '../reducers/getHolding';
import { HoldingWrite } from './setHoldingFormData';

export const req = (id: string | number) => ({
  type: GET_HOLDING.REQ,
  id
});

export const got = (holding: HoldingWrite) => ({
  type: GET_HOLDING.GOT,
  holding
});

export const err = (error: {}) => ({
  type: GET_HOLDING.ERR,
  error
});
