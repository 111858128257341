import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postCompaniesOfCompanies';
import { POST_COMPANIES_OF_COMPANIES } from '../reducers/postCompaniesOfCompanies';
import CompaniesApi from '../api';

function* postCompaniesOfCompanies(action: {
  type: POST_COMPANIES_OF_COMPANIES;
  id: string;
  companies: string;
}): Generator {
  try {
    yield call(
      CompaniesApi.postCompaniesOfCompanies,
      action.id,
      action.companies
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCompaniesOfCompaniesSaga(): Generator {
  yield takeLatest(POST_COMPANIES_OF_COMPANIES.REQ, postCompaniesOfCompanies);
}
