import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getLeadsList';

export interface GetLeadsListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_LEADS_LIST {
  REQ = 'GET_LEADS_LIST_REQ',
  GOT = 'GET_LEADS_LIST_GOT',
  ERR = 'GET_LEADS_LIST_ERR'
}

export const initialState: GetLeadsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getLeadsList = (
  state: GetLeadsListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetLeadsListStoreState => {
  switch (action.type) {
    case GET_LEADS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LEADS_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_LEADS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getLeadsList;
