import * as React from 'react';
import { Route } from 'react-router';
import { CertificatesForm } from './CertificatesForm/CertificatesForm';
import { CertificateCreate } from './CertificatesEdit/CertificatesEdit';
import CertificatesInfo from './CertificatesInfo/CertificatesInfo';
import { CertificateFill } from './CertificateFill/CertificateFill';
import { CertificatesStyled } from './styles';

class CertificatesPage extends React.Component {
  render() {
    return (
      <CertificatesStyled>
        <Route
          exact
          path={'/supplier/settings/certificates'}
          render={() => <CertificatesForm />}
        />
        <Route
          exact
          path={'/supplier/settings/certificates/edit'}
          render={() => <CertificateCreate />}
        />
        <Route
          exact
          path={'/supplier/settings/certificates/:id/edit'}
          render={() => <CertificateFill />}
        />
        <Route
          exact
          path={'/supplier/settings/certificates/:id'}
          render={props => {
            const { id } = props.match.params;
            if (id && id !== 'edit') {
              return <CertificatesInfo />;
            } else {
              return null;
            }
          }}
        />
        <Route
          exact
          path={'/debtor/settings/certificates'}
          component={CertificatesForm}
        />
        <Route
          exact={true}
          path={'/debtor/settings/certificates/edit'}
          component={CertificateCreate}
        />
        <Route
          exact
          path={'/debtor/settings/certificates/:id/edit'}
          render={() => <CertificateFill />}
        />
        <Route
          exact
          path={'/debtor/settings/certificates/:id'}
          render={props => {
            const { id } = props.match.params;
            if (id && id !== 'edit') {
              return <CertificatesInfo />;
            } else {
              return null;
            }
          }}
        />
        <Route
          exact
          path={'/factor/settings/certificates'}
          render={() => <CertificatesForm />}
        />
        <Route
          exact
          path={'/factor/settings/certificates/edit'}
          render={() => <CertificateCreate />}
        />
        <Route
          exact
          path={'/factor/settings/certificates/:id/edit'}
          render={() => <CertificateFill />}
        />
        <Route
          exact
          path={'/factor/settings/certificates/:id'}
          render={props => {
            const { id } = props.match.params;
            if (id && id !== 'edit') {
              return <CertificatesInfo />;
            } else {
              return null;
            }
          }}
        />
      </CertificatesStyled>
    );
  }
}

export default CertificatesPage;
