import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { CheckListContainer, ListChecklist } from '../LeadView/styled';
import { RequestData as ReqPutChecklist } from 'src/features/Leads/actions/putChecklist';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  BlockComponent: React.FC<{ type: number }>;
  type: number;
  title: string;
  description: string;
  listItems: string[];
  putChecklist: (id: string, data: ReqPutChecklist) => void;
}

const ChecklistItem: React.FC<Props> = ({
  BlockComponent,
  type,
  title,
  description,
  listItems,
  putChecklist,
  match
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePutChecklist = (index: number) => {
    setIsOpen(false);

    if (title === 'Список документов') {
      putChecklist(match.params.id, {
        DocsChecklist: index
      });
    } else if (title === 'Согласие перс.данных и БКИ') {
      putChecklist(match.params.id, {
        BkiChecklist: index
      });
    } else if (title === 'Согласование БГ с заказчиком') {
      putChecklist(match.params.id, {
        BgParametersChecklist: index
      });
    } else if (title === 'Подача заявки в банк') {
      putChecklist(match.params.id, {
        ApplicationToBankCheckList: index
      });
    } else if (title === 'Подписание кредитной документации') {
      putChecklist(match.params.id, {
        DealToBankChecklist: index
      });
    }
  };

  return (
    <CheckListContainer ref={ref}>
      <BlockComponent type={type} />
      <div>
        <p>{title}</p>
        <div>
          <p>{description}</p>
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={() => setIsOpen(prevState => !prevState)}
          />
        </div>
      </div>
      <ListChecklist isOpen={isOpen}>
        {listItems.map((item, index) => (
          <p key={index} onClick={() => handlePutChecklist(index * 2)}>
            {item}
          </p>
        ))}
      </ListChecklist>
    </CheckListContainer>
  );
};

const ChecklistItemWithRouter = withRouter(ChecklistItem);
export { ChecklistItemWithRouter as ChecklistItem };
