import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getContactsList';

import { GET_CONTACTS_LIST } from '../reducers/getContactsList';
import ContactsApi from '../api';

function* getContactsList(action: {
  type: GET_CONTACTS_LIST;
  inn: string;
}): Generator {
  try {
    let res;

    res = yield call(ContactsApi.getContactsList, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContactsListSaga(): Generator {
  yield takeLatest(GET_CONTACTS_LIST.REQ, getContactsList);
}
