import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { updateINNFilter } from 'src/features/Filters/INNFilter/actions/updateINNFilter';
import { INNFilterContainerStyled } from './styled';

interface StateToProps {
  inn: string;
}

interface AnyProps {
  label?: string;
}

interface DispatchToProps {
  updateINNFilter: (inn: string) => void;
}

type Props = StateToProps & DispatchToProps & AnyProps;

class INNFilter extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    this.props.updateINNFilter(value);
  };

  render() {
    return (
      <INNFilterContainerStyled>
        <Input
          value={this.props.inn}
          label={this.props.label ? this.props.label : 'ИНН'}
          name="inn"
          placeholder={InnPlaceholders.entity}
          onChange={this.onChange}
        />
      </INNFilterContainerStyled>
    );
  }
}

const mapStateToProps = ({ Filters }: STORE) => ({
  inn: Filters.updateINNFilter.inn
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ updateINNFilter }, dispatch);

const INNFilterConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(INNFilter);

export { INNFilterConnect as INNFilter };
