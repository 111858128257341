import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getMonetaryClaimsList';

export interface GetMonetaryClaimsListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_MONETARY_CLAIMS_LIST {
  REQ = 'GET_MONETARY_CLAIMS_LIST_REQ',
  GOT = 'GET_MONETARY_CLAIMS_LIST_GOT',
  ERR = 'GET_MONETARY_CLAIMS_LIST_ERR'
}

export const initialState: GetMonetaryClaimsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    fullSum: 0,
    totalItems: 0,
    availableChecks: 0,
    items: []
  },
  error: {}
};

const getMonetaryClaimsList = (
  state: GetMonetaryClaimsListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetMonetaryClaimsListStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsList;
