import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getUserLeads';

import { GET_USER_LEADS } from '../reducers/getUserLeads';
import UserApi from '../api';

function* getUserLeads(action: {
  type: GET_USER_LEADS;
  id: string;
}): Generator {
  try {
    const res: any = yield call(UserApi.getUserLeads, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserLeadsSaga(): Generator {
  yield takeLatest(GET_USER_LEADS.REQ, getUserLeads);
}
