import { SEND_LEAD_DATA } from '../reducers/sendLeadData';
import { LeadWrite } from './setLeadFormData';

export interface RequestData extends Partial<LeadWrite> {}

export const req = (data: RequestData) => ({
  type: SEND_LEAD_DATA.REQ,
  data
});

export const got = () => ({
  type: SEND_LEAD_DATA.GOT
});

export const err = (error: {}) => ({
  type: SEND_LEAD_DATA.ERR,
  error
});
