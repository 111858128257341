import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { BeneficiariesOfCompaniesType } from '../types';

export interface GetBeneficiariesOfCompaniesPersonStoreState extends Reducer {
  data: BeneficiariesOfCompaniesType[];
}

export enum GET_BENEFICIARIES_OF_COMPANIES_PERSON {
  REQ = 'GET_BENEFICIARIES_OF_COMPANIES_PERSON_REQ',
  GOT = 'GET_BENEFICIARIES_OF_COMPANIES_PERSON_GOT',
  ERR = 'GET_BENEFICIARIES_OF_COMPANIES_PERSON_ERR'
}

export const initialState: GetBeneficiariesOfCompaniesPersonStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getBeneficiariesOfCompaniesPerson = (
  state: GetBeneficiariesOfCompaniesPersonStoreState = initialState,
  action: {
    type: GET_BENEFICIARIES_OF_COMPANIES_PERSON;
    data: BeneficiariesOfCompaniesType[];
    error: {};
  }
): GetBeneficiariesOfCompaniesPersonStoreState => {
  switch (action.type) {
    case GET_BENEFICIARIES_OF_COMPANIES_PERSON.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BENEFICIARIES_OF_COMPANIES_PERSON.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_BENEFICIARIES_OF_COMPANIES_PERSON.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBeneficiariesOfCompaniesPerson;
