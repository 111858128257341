import { GET_CONTACT } from '../reducers/getContact';
import { ContactWrite } from './setContactFormData';

export const req = (id: string | number) => ({
  type: GET_CONTACT.REQ,
  id
});

export const got = (contact: ContactWrite) => ({
  type: GET_CONTACT.GOT,
  contact
});

export const err = (error: {}) => ({
  type: GET_CONTACT.ERR,
  error
});
