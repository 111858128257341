import { GET_FACTORING_FEES_OF_LIMITS } from 'entities/SCF/Factor/model/reducers/getFactoringFeesOfLimits';

export interface Limit {
  debtorINN: string;
  feeBase: number;
  feeBaseSumm: number;
  guid: string;
  momentOfPayment: number;
  name: string;
  vatPercentage: number;
  status?: 1 | 2 | 3;
  changed?: boolean;
}

export interface RequestDataType {
  page?: number;
  pageSize?: number;
}

export const req = (inn: string, data: RequestDataType) => ({
  type: GET_FACTORING_FEES_OF_LIMITS.REQ,
  inn,
  data
});

export const got = (data: Limit[]) => ({
  type: GET_FACTORING_FEES_OF_LIMITS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_FEES_OF_LIMITS.ERR,
  error
});
