import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { differenceInDays, format, isLeapYear } from 'date-fns';
import { Modal, ScrollTopComponent } from 'src/features/Common';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import {
  req as getMonetaryClaimsDebtorEarlyPaymentApplications,
  RequestDataType,
  Calc as ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  req as postOfferDiscount,
  RequestData as ReqOfferDiscount
} from 'entities/SCF/Supplier/model/actions/postOfferDiscount';
import {
  req as getByMonetaryClaimHint,
  reset as resetByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import {
  req as getOneDiscountAuction,
  ResponseData as ResOneDiscountAuction
} from 'src/features/SCF/actions/getOneDiscountAuctionBySupplier';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { openModal } from 'Modal/actions/toggleModal';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import {
  BackBtn,
  BackBtnText,
  BtnsBlock,
  Circle,
  ConfirmSectionRightPart,
  CreateBtn,
  CreateBtnText,
  DiscountItem,
  DocumentHint,
  HeaderInfo,
  InvoiceDocumentDate,
  MonetaryClaimsContainer,
  SkeletonWrapper,
  SummarySection,
  TargetDiscountRate,
  TDInvoiceDocumenContainer
} from './styled';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { formSumStringThousands } from 'shared/utils/Utils';
import { TdCentred } from 'src/features/SCF/UI/Table/TableRow/styles';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';

interface StateToProps {
  monetaryClaims: ResponseDataType[];
  status: REQUEST_STATUSES;
  error: ResponseError;
  investorOneDebtor: OneDebtorResponseDataType;
  statusInvestorOneDebtor: REQUEST_STATUSES;
  monetaryClaimsHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
  auctionInfo: ResOneDiscountAuction;
  statusAuctionInfo: REQUEST_STATUSES;
  statusPostOfferDiscount: REQUEST_STATUSES;
  errorPostOfferDiscount: any;
}

interface DispatchToProps {
  getMonetaryClaimsDebtorEarlyPaymentApplications: (
    data: RequestDataType
  ) => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
  getOneDiscountAuction: (guid: string) => void;
  postOfferDiscount: (guid: string, data: ReqOfferDiscount) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const MakeOffer: React.FC<Props> = ({
  getMonetaryClaimsDebtorEarlyPaymentApplications,
  monetaryClaims,
  status,
  error,
  getInvestorOneDebtor,
  investorOneDebtor,
  statusInvestorOneDebtor,
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  getOneDiscountAuction,
  auctionInfo,
  statusAuctionInfo,
  postOfferDiscount,
  statusPostOfferDiscount,
  errorPostOfferDiscount,
  history,
  openModal,
  match
}) => {
  const [offerRate, setOfferRate] = React.useState({
    baseDiscountRate: '',
    absoluteDiscount: '',
    absoluteDiscountRate: '',
    notRoundedAbsoluteDiscountRate: 0,
    selectedCheckbox: 'baseDiscountRate'
  });

  React.useEffect(
    () => {
      if (statusPostOfferDiscount === REQUEST_STATUSES.GOT) {
        history.push(
          `/supplier/early_payment_applications/${
            match.params.guid
          }/pick_signee`
        );
      } else if (statusPostOfferDiscount === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_OFFER_DISCOUNT });
      }
    },
    [statusPostOfferDiscount]
  );

  React.useEffect(
    () => {
      if (statusAuctionInfo === REQUEST_STATUSES.GOT) {
        setOfferRate(prevState => ({
          ...prevState,
          baseDiscountRate: formatDecimalPart(
            auctionInfo.targetDiscountRate.toString()
          )
        }));
      }
    },
    [statusAuctionInfo]
  );

  React.useEffect(
    () => {
      if (offerRate.selectedCheckbox === 'baseDiscountRate') {
        setOfferRate(prevState => ({
          ...prevState,
          absoluteDiscount: formatNumber(
            (
              (((+offerRate.baseDiscountRate *
                investorOneDebtor.monetaryClaimsTotalFullSum) /
                100) *
                differenceInDays(
                  new Date(investorOneDebtor.firstBasePaymentDate),
                  new Date(investorOneDebtor.payDate)
                )) /
              365
            ).toString()
          ),
          absoluteDiscountRate: formatDecimalPart(
            (
              (+offerRate.baseDiscountRate *
                differenceInDays(
                  new Date(investorOneDebtor.firstBasePaymentDate),
                  new Date(investorOneDebtor.payDate)
                )) /
              365
            ).toString()
          ),
          notRoundedAbsoluteDiscountRate:
            (+offerRate.baseDiscountRate *
              differenceInDays(
                new Date(investorOneDebtor.firstBasePaymentDate),
                new Date(investorOneDebtor.payDate)
              )) /
            365
        }));
      } else if (offerRate.selectedCheckbox === 'absoluteDiscount') {
        setOfferRate(prevState => ({
          ...prevState,
          baseDiscountRate: (
            (((clearSpace(offerRate.absoluteDiscount) /
              investorOneDebtor.monetaryClaimsTotalFullSum) *
              365) /
              differenceInDays(
                new Date(investorOneDebtor.firstBasePaymentDate),
                new Date(investorOneDebtor.payDate)
              )) *
            100
          ).toFixed(4),
          absoluteDiscountRate: (
            (+offerRate.baseDiscountRate *
              differenceInDays(
                new Date(investorOneDebtor.firstBasePaymentDate),
                new Date(investorOneDebtor.payDate)
              )) /
            365
          ).toFixed(4),
          notRoundedAbsoluteDiscountRate:
            (+offerRate.baseDiscountRate *
              differenceInDays(
                new Date(investorOneDebtor.firstBasePaymentDate),
                new Date(investorOneDebtor.payDate)
              )) /
            365
        }));
      } else if (offerRate.selectedCheckbox === 'absoluteDiscountRate') {
        setOfferRate(prevState => ({
          ...prevState,
          baseDiscountRate: formatDecimalPart(
            (
              (+offerRate.absoluteDiscountRate * 365) /
              differenceInDays(
                new Date(investorOneDebtor.firstBasePaymentDate),
                new Date(investorOneDebtor.payDate)
              )
            ).toString()
          ),
          absoluteDiscount: formatNumber(
            (
              (((+offerRate.baseDiscountRate *
                investorOneDebtor.monetaryClaimsTotalFullSum) /
                100) *
                differenceInDays(
                  new Date(investorOneDebtor.firstBasePaymentDate),
                  new Date(investorOneDebtor.payDate)
                )) /
              365
            ).toString()
          )
        }));
      }
    },
    [
      offerRate.baseDiscountRate,
      offerRate.absoluteDiscount,
      offerRate.absoluteDiscountRate
    ]
  );

  React.useEffect(
    () => {
      if (match.params.guid) {
        getInvestorOneDebtor({ guid: match.params.guid });
        getMonetaryClaimsDebtorEarlyPaymentApplications({
          guid: match.params.guid
        });
      }
    },
    [match.params.guid]
  );

  React.useEffect(
    () => {
      if (statusInvestorOneDebtor === REQUEST_STATUSES.GOT) {
        getOneDiscountAuction(investorOneDebtor.auctionGuid);
      }
    },
    [statusInvestorOneDebtor]
  );

  if (status === REQUEST_STATUSES.ERROR && error.code === 409) {
    history.push(
      `/supplier/early_payment_applications/${match.params.guid}/calc`
    );
  } else if (status === REQUEST_STATUSES.ERROR && error.code === 404) {
    history.push(`/supplier/early_payment_applications`);
  }

  const formatNumber = (value: string) => {
    let cleanValue = value.replace(/[^0-9.,]/g, '').replace(',', '.');

    let [integerPart, decimalPart] = cleanValue.split('.');

    if (decimalPart) decimalPart = decimalPart.slice(0, 2);

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return decimalPart !== undefined
      ? `${integerPart}.${decimalPart}`
      : integerPart;
  };

  const formatDecimalPart = (value: string) => {
    let cleanValue = value.replace(/[^0-9.,]/g, '').replace(',', '.');
    let [integerPart, decimalPart] = cleanValue.split('.');
    if (decimalPart) decimalPart = decimalPart.slice(0, 4);

    return decimalPart !== undefined
      ? `${integerPart}.${decimalPart}`
      : integerPart;
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.currentTarget;

    if (type === 'text') {
      if (name === 'absoluteDiscount') {
        setOfferRate(prevState => ({
          ...prevState,
          [name]: formatNumber(value)
        }));
      } else {
        setOfferRate(prevState => ({
          ...prevState,
          [name]: formatDecimalPart(value)
        }));
      }
    } else if (type === 'checkbox') {
      setOfferRate(prevState => ({ ...prevState, selectedCheckbox: name }));
    }
  };

  const DiscountCounter = monetaryClaims.reduce((total, claim) => {
    return total + claim.baseFullSumm;
  }, 0);

  const discount = (maturityDate: string) => {
    if (offerRate.selectedCheckbox === 'absoluteDiscountRate') {
      return +offerRate.absoluteDiscountRate;
    } else {
      const daysInYear = () => (isLeapYear(new Date()) ? 366 : 365);

      return (
        (+offerRate.baseDiscountRate *
          differenceInDays(
            new Date(maturityDate),
            new Date(investorOneDebtor.payDate)
          )) /
        daysInYear()
      );
    }
  };

  const clearSpace = (str: string) => {
    const cleanedStr = str.replace(/\s+/g, '');
    return parseFloat(cleanedStr);
  };

  const amountReceived = (baseFullSumm: number) => {
    if (offerRate.selectedCheckbox === 'absoluteDiscount') {
      return baseFullSumm - clearSpace(offerRate.absoluteDiscount);
    } else {
      return (
        baseFullSumm * (1 - offerRate.notRoundedAbsoluteDiscountRate / 100)
      );
    }
  };

  const totalAmountReceived = monetaryClaims.reduce((total, claim) => {
    return total + amountReceived(claim.baseFullSumm);
  }, 0);

  const discountRate = (maturityDate: string) => {
    return offerRate.selectedCheckbox === 'baseDiscountRate' ||
      offerRate.selectedCheckbox === 'absoluteDiscount'
      ? +offerRate.baseDiscountRate
      : offerRate.selectedCheckbox === 'absoluteDiscountRate' &&
          (+offerRate.absoluteDiscountRate * 365) /
            differenceInDays(
              new Date(maturityDate),
              new Date(investorOneDebtor.payDate)
            );
  };

  const onBack = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const onNextStepClick = () => {
    if (+offerRate.baseDiscountRate < auctionInfo.minDiscountRate) return;

    postOfferDiscount(match.params.guid, {
      ...(offerRate.selectedCheckbox === 'baseDiscountRate' && {
        baseDiscountRate: offerRate.baseDiscountRate.toString()
      }),
      ...(offerRate.selectedCheckbox === 'absoluteDiscount' && {
        absoluteDiscount: clearSpace(offerRate.absoluteDiscount).toString()
      }),
      ...(offerRate.selectedCheckbox === 'absoluteDiscountRate' && {
        absoluteDiscountRate: offerRate.absoluteDiscountRate.toString()
      })
    });
  };

  return (
    <ScrollTopComponent>
      <CreateLimitsNudge>
        <h2>Предложите ставку ранней оплаты:</h2>
      </CreateLimitsNudge>

      <HeaderInfo>
        <div>
          <p>Дебитор</p>
          <p>{investorOneDebtor.debtorName}</p>
        </div>
        <div>
          <p>Сумма счетов</p>
          <p>
            {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString('ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </p>
        </div>
        <div>
          <p>Дата ранней оплаты</p>
          <p>{format(new Date(investorOneDebtor.payDate), 'dd.MM.yyyy')}</p>
        </div>
        <div>
          <p>ID заявки</p>
          <p>
            {investorOneDebtor.guid.substring(
              investorOneDebtor.guid.length - 5
            )}
          </p>
        </div>
      </HeaderInfo>

      <TargetDiscountRate>
        <p>Введите ставку предложения:</p>

        <div>
          <Checkbox
            label="в % годовых"
            name="baseDiscountRate"
            onChange={handleChangeDiscount}
            checked={offerRate.selectedCheckbox === 'baseDiscountRate'}
          />
          {offerRate.selectedCheckbox === 'baseDiscountRate' ? (
            <Input
              name="baseDiscountRate"
              value={offerRate.baseDiscountRate}
              onChange={handleChangeDiscount}
            />
          ) : (
            <p>{offerRate.baseDiscountRate}</p>
          )}

          <span>% годовых</span>

          <div>
            <Circle
              value={offerRate.baseDiscountRate}
              minRate={auctionInfo.minDiscountRate}
              targetDiscountRate={auctionInfo.targetDiscountRate}
            />
            <p>
              {+offerRate.baseDiscountRate >= auctionInfo.targetDiscountRate
                ? ''
                : +offerRate.baseDiscountRate >= auctionInfo.minDiscountRate &&
                  +offerRate.baseDiscountRate < auctionInfo.targetDiscountRate
                  ? 'Для повышения вероятности заключения сделки увеличьте ставку предложения'
                  : 'Предложение не соответствует минимальным требованиям, увеличьте ставку предложения'}
            </p>
          </div>
        </div>

        <div>
          <Checkbox
            label="абсолютное значение в рублях"
            name="absoluteDiscount"
            onChange={handleChangeDiscount}
            checked={offerRate.selectedCheckbox === 'absoluteDiscount'}
          />
          {offerRate.selectedCheckbox === 'absoluteDiscount' ? (
            <Input
              name="absoluteDiscount"
              value={offerRate.absoluteDiscount}
              onChange={handleChangeDiscount}
            />
          ) : (
            <p>{offerRate.absoluteDiscount}</p>
          )}
          <span>руб.</span>
        </div>

        <div>
          <Checkbox
            label="в % от суммы денежных требований"
            name="absoluteDiscountRate"
            onChange={handleChangeDiscount}
            checked={offerRate.selectedCheckbox === 'absoluteDiscountRate'}
          />
          {offerRate.selectedCheckbox === 'absoluteDiscountRate' ? (
            <Input
              name="absoluteDiscountRate"
              value={offerRate.absoluteDiscountRate}
              onChange={handleChangeDiscount}
            />
          ) : (
            <p>{offerRate.absoluteDiscountRate}</p>
          )}
          <span>%</span>
        </div>
      </TargetDiscountRate>

      <h3>Индикативный расчет размера дисконта:</h3>

      {status === REQUEST_STATUSES.GOT &&
        statusInvestorOneDebtor === REQUEST_STATUSES.GOT && (
          <MonetaryClaimsContainer>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="6%">ID</TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    Сумма с НДС
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled
                    width="12%"
                    style={{ textAlign: 'center' }}
                  >
                    Документы
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled
                    width="8%"
                    style={{ textAlign: 'center' }}
                  >
                    Дата оплаты
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled
                    width="12%"
                    style={{ textAlign: 'center' }}
                  >
                    Срок дисконта дн.
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="8%">
                    <div>Ставка дисконта</div>
                    <div
                      style={{ textTransform: 'lowercase', fontSize: '12px' }}
                    >
                      % годовых
                    </div>
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled
                    width="12%"
                    style={{ textAlign: 'center' }}
                  >
                    Дисконт
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="8%">
                    Сумма к получению
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaims.map((item, key) => (
                  <TableRowStyled key={key}>
                    <td style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </td>

                    <td>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </td>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          margin: '0'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>
                    <td>
                      {formSumStringThousands(discountRate(item.maturityDate), {
                        minFractionDigits: 4,
                        maxFractionDigits: 4
                      })}{' '}
                      %
                    </td>
                    <TdCentred>
                      {formSumStringThousands(discount(item.maturityDate), {
                        minFractionDigits: 4,
                        maxFractionDigits: 4
                      })}{' '}
                      %
                    </TdCentred>
                    <td>
                      {formSumStringThousands(
                        amountReceived(item.baseFullSumm)
                      )}
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <SummarySection style={{ marginTop: '10px' }}>
              <ConfirmSectionRightPart>
                <DiscountItem>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    Сумма требований без дисконта
                  </p>
                  <p
                    className="discount-1"
                    id="discount-1"
                    style={{ color: '#9FA6B2', fontSize: '14px' }}
                  >
                    {formSumStringThousands(DiscountCounter)} руб.
                  </p>
                </DiscountItem>
                <DiscountItem>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    Дисконт за раннюю оплату
                  </p>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    {offerRate.selectedCheckbox === 'baseDiscountRate' ||
                    offerRate.selectedCheckbox === 'absoluteDiscountRate'
                      ? formSumStringThousands(
                          DiscountCounter - totalAmountReceived
                        )
                      : offerRate.selectedCheckbox === 'absoluteDiscount' &&
                        formSumStringThousands(
                          clearSpace(offerRate.absoluteDiscount)
                        )}{' '}
                    руб.
                  </p>
                </DiscountItem>
                <DiscountItem>
                  <p style={{ fontSize: '16px' }}>Сумма к получению</p>
                  <p style={{ fontSize: '16px' }}>
                    {formSumStringThousands(totalAmountReceived)} руб.
                  </p>
                </DiscountItem>
              </ConfirmSectionRightPart>
            </SummarySection>

            <BtnsBlock>
              <BackBtn onClick={onBack}>
                <BackBtnText>Назад</BackBtnText>
              </BackBtn>
              <CreateBtn
                disabled={
                  +offerRate.baseDiscountRate < auctionInfo.minDiscountRate
                }
                onClick={onNextStepClick}
              >
                <CreateBtnText>Далее</CreateBtnText>
              </CreateBtn>
            </BtnsBlock>
          </MonetaryClaimsContainer>
        )}

      <Modal name={MODAL_NAMES.ERROR_OFFER_DISCOUNT}>
        <p>{errorPostOfferDiscount}</p>
      </Modal>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  monetaryClaims:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  statusInvestorOneDebtor:
    SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.status,
  monetaryClaimsHint: SCFSupplier.getByMonetaryClaimSupplier.data,
  statusMonetaryClaimsHint: SCFSupplier.getByMonetaryClaimSupplier.status,
  auctionInfo: SCF.getOneDiscountAuctionBySupplier.data,
  statusAuctionInfo: SCF.getOneDiscountAuctionBySupplier.status,
  statusPostOfferDiscount: SCFSupplier.postOfferDiscount.status,
  errorPostOfferDiscount: SCFSupplier.postOfferDiscount.error.body
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMonetaryClaimsDebtorEarlyPaymentApplications,
      getInvestorOneDebtor,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint,
      getOneDiscountAuction,
      postOfferDiscount,
      openModal
    },
    dispatch
  );

const MakeOfferConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(MakeOffer)
);

export { MakeOfferConnect as MakeOffer };
