import { SCF_REGISTER_FACTOR } from 'entities/SCF/Factor/model/reducers/SCFRegisterFactor';

export interface RequestDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  legalForm: string;
  code: string;
  companyName: string;
  email: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_REQ,
  data
});
export const got = (data: RequestDataType) => ({
  type: SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_GOT
});
export const err = (error: {}) => ({
  type: SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_ERR,
  error
});
export const reset = () => ({
  type: SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_RESET
});
