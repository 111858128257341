import { CHECK_AVAILABLE_LIMITS } from '../reducers/checkAvailableLimits';

export interface AvailableLimits {
  inn: string;
  companyName: string;
  hasLimit: boolean;
}

export interface RequestDataType {
  innlist: string;
}

export const req = (data: RequestDataType) => ({
  type: CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_REQ,
  data
});

export const got = (data: AvailableLimits[]) => ({
  type: CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_GOT,
  data
});

export const err = (error: {}) => ({
  type: CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_ERR,
  error
});
