import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchCabinetRegionAvailableAll extends Reducer {}

export enum PATCH_CABINET_SET_AVAILABLE_REGION_ALL {
  REQ = 'PATCH_CABINET_SET_AVAILABLE_REGION_ALL_REQ',
  GOT = 'PATCH_CABINET_SET_AVAILABLE_REGION_ALL_GOT',
  ERR = 'PATCH_CABINET_SET_AVAILABLE_REGION_ALL_ERR'
}

export const initialState: PatchCabinetRegionAvailableAll = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCabinetRegionAvailableAll = (
  state: PatchCabinetRegionAvailableAll = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchCabinetRegionAvailableAll => {
  switch (action.type) {
    case PATCH_CABINET_SET_AVAILABLE_REGION_ALL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_CABINET_SET_AVAILABLE_REGION_ALL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_CABINET_SET_AVAILABLE_REGION_ALL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchCabinetRegionAvailableAll;
