import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getOpenLoanLimits,
  ResponseData
} from 'src/features/PreApproval/actions/getOpenLoanLimits';
import {
  req as createNewClient,
  reset as resetClientState,
  RequestData as ReqPostNewClient
} from 'src/features/Clients/actions/postNewClient';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { NoData } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  openLoanLimits: ResponseData[];
  status: REQUEST_STATUSES;
  error: ResponseError;
  createStatus: REQUEST_STATUSES;
  companyShortName: string;
}

interface DispatchToProps {
  getOpenLoanLimits: (inn: string) => void;
  createNewClient: (data: ReqPostNewClient) => void;
  resetClientState: () => void;
}

interface MatchParams {
  inn: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const PreApproval: React.FC<Props> = ({
  getOpenLoanLimits,
  openLoanLimits,
  status,
  error,
  createNewClient,
  resetClientState,
  createStatus,
  companyShortName,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.inn) {
        getOpenLoanLimits(match.params.inn);
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (createStatus === REQUEST_STATUSES.GOT) {
        window.location.reload();
        resetClientState();
      }
    },
    [createStatus]
  );

  const handleCreateNewClient = () => {
    createNewClient({
      companyinn: match.params.inn,
      companyshortname: companyShortName
    });
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && !!openLoanLimits.length ? (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="20%">Продукт</TableThStyled>
                <TableThStyled width="15%">Сумма лимита</TableThStyled>
                <TableThStyled width="15%">Срок продукта</TableThStyled>
                <TableThStyled width="15%">Индикативная ставка</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {openLoanLimits.map(limit => (
                <TableRowStyled key={limit.guid}>
                  <td>{limit.productName}</td>
                  <td>
                    {limit.volume
                      ? formSumStringThousands(limit.volume)
                      : 'неизв.'}
                  </td>
                  <td>{limit.term ? `до ${limit.term} мес.` : 'неизв.'}</td>
                  <td>{limit.rate ? `${limit.rate} % годовых` : 'неизв.'}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={openLoanLimits} />
        </>
      ) : (
        status === REQUEST_STATUSES.GOT &&
        openLoanLimits.length === 0 && (
          <NoData>
            <p>Предварительные одобрения не найдены</p>
            <p>
              Это означает, что клиент может получить финансирование при
              дополнительном анализе документов по запросу платформы
            </p>
          </NoData>
        )
      )}

      {error.code === 404 && (
        <NoData>
          <p>
            Вы не добавляли эту компанию в качестве вашего клиента, поэтому
            предварительные одобрения не показываются
          </p>
          <p>
            Для того, чтобы увидеть предварительные одобрения, вы можете
            добавить компанию как клиента
          </p>
          <Button label="+ Добавить клиента" onClick={handleCreateNewClient} />
        </NoData>
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ PreApproval, Clients, SCF }: CRM & STORE) => ({
  openLoanLimits: PreApproval.getOpenLoanLimitsExternal.data,
  status: PreApproval.getOpenLoanLimitsExternal.status,
  error: PreApproval.getOpenLoanLimitsExternal.error,
  companyShortName: SCF.getCompanyByInnThirdParty.data.companyShortName,
  createStatus: Clients.postNewClient.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOpenLoanLimits,
      createNewClient,
      resetClientState
    },
    dispatch
  );

const PreApprovalConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PreApproval)
);

export { PreApprovalConnect as PreApproval };
