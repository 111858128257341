import styled from 'styled-components';

export const FileUploadHeader = styled.h3`
  margin-bottom: 25px;
  margin-top: 50px;
`;
export const FileRow = styled.div`
  display: flex;
  align-items: center;
  & > * {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-right: 5px;
  }
`;
export const FileRowPaddingBlock = styled.div`
  height: 75px;
  margin-left: 250px;
`;
export const FileRowLabelContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  & > label {
    word-break: break-word;
    white-space: pre-line;
  }
`;
