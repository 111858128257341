import { GET_DEBTORS_LIST } from '../reducers/getDebtorsList';
import { DebtorRead } from './setDebtorFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: DebtorRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  debtorinnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_DEBTORS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_DEBTORS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DEBTORS_LIST.ERR,
  error
});
