import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Col } from 'react-grid-system';
import { REQUEST_STATUSES, STORE } from 'globaltypes';
import { FinancingTypeBlock } from './FinancingTypeBlock/FinancingTypeBlock';
import { RowStyled } from './styled';
import { req as createApplication } from 'Application/actions/createApplication';
import {
  APPLICATION_TYPE_ROUTES,
  APPLICATION_TYPES
} from 'src/features/Application/types';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';
import { SetInitializationApplicationType } from 'src/features/Application/reducers/setInitializationFormData';
import MoneyIcon from 'src/assets/icons/money.svg';
import ContractIcon from 'src/assets/icons/contract.svg';
import BriefcaseIcon from 'src/assets/icons/briefcase.svg';
import GraphIcon from 'src/assets/icons/graph.svg';
import SavingsIcon from 'src/assets/icons/savings.svg';
import BankIcon from 'src/assets/icons/bank.svg';
import LeasingIcon from 'src/assets/icons/leasing.svg';
import { Icon } from 'shared/ui/Icon';
import { FinancingTemporaryTypeBlock } from './FinancingTypeBlock/FinancingTemporary';

interface StateToProps {
  applicationId: number;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  setInitializationFormData: (data: SetInitializationApplicationType) => void;
  createApplication: (financingType: APPLICATION_TYPES) => void;
}

interface RouterProps {
  match: any;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps & RouterProps;

const FinancingTypeChoiceScreen: React.FC<Props> = ({
  setInitializationFormData,
  createApplication,
  applicationId,
  status,
  history,
  match
}) => {
  const [application, setApplication] = React.useState('');

  const segments = location.pathname.split('/');
  const roleIndex = segments.indexOf('cabinet') + 1;
  const role = segments[roleIndex];

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        history.push(
          `/cabinet/${role}/application-form-${application}/edit/${applicationId}`
        );
      }
    },
    [status]
  );

  const navigateFromTo = (from: string, to: string) =>
    history.push(match.url.replace(from, to));

  const setFinancingType = (type: APPLICATION_TYPES) => {
    setInitializationFormData({ financingType: type });
  };

  const setFinancingTypeAndNextStep = (type: APPLICATION_TYPES) => {
    setInitializationFormData({
      financingType: type
    });
    navigateFromTo(
      APPLICATION_TYPE_ROUTES.CHOICE_SCREEN,
      APPLICATION_TYPE_ROUTES[type]
    );
  };

  const goToFinancingTypeForm = (type: APPLICATION_TYPES) => {
    setFinancingType(APPLICATION_TYPES[type]);
    navigateFromTo(
      APPLICATION_TYPE_ROUTES.CHOICE_SCREEN,
      APPLICATION_TYPE_ROUTES[type]
    );
  };

  const goToGuaranteesEdit = () => {
    createApplication(APPLICATION_TYPES.GUARANTEES);
    setFinancingType(APPLICATION_TYPES.GUARANTEES);
    setApplication('guarantees');
  };

  const goToFinancingEdit = () => {
    createApplication(APPLICATION_TYPES.CONTRACT_FINANCING);
    setFinancingType(APPLICATION_TYPES.CONTRACT_FINANCING);
    setApplication('contract-financing');
  };

  const goToCreditingEdit = () => {
    createApplication(APPLICATION_TYPES.CREDITING);
    setFinancingType(APPLICATION_TYPES.CREDITING);
    setApplication('crediting');
  };

  const goToFactoringEdit = () => {
    createApplication(APPLICATION_TYPES.FACTORING);
    setFinancingType(APPLICATION_TYPES.FACTORING);
    setApplication('factoring');
  };

  return (
    <Container>
      <RowStyled>
        <Col lg={3}>
          <FinancingTypeBlock
            type="Банковские гарантии"
            icon={<Icon iconLink={ContractIcon} />}
            youtubeLink="https://www.youtube.com/embed/D2Tia83p-DQ?si=CabYbq_XmIkwhDqH"
            onClick={goToGuaranteesEdit}
          />
        </Col>
        <Col lg={3}>
          <FinancingTypeBlock
            type="Финансирование исполнения контракта"
            icon={<Icon iconLink={BriefcaseIcon} />}
            youtubeLink="https://www.youtube.com/embed/zwOlCdPin1E?si=JJFCjsUXycgrIVUm"
            onClick={goToFinancingEdit}
          />
        </Col>
        <Col lg={3}>
          <FinancingTypeBlock
            type="Кредитование текущей деятельности"
            icon={<Icon iconLink={MoneyIcon} />}
            onClick={goToCreditingEdit}
            youtubeLink="https://www.youtube.com/embed/hqDDKMXIdVg?si=vOYTiw0qZUgXNU1P"
          />
        </Col>
        <Col lg={3}>
          <FinancingTemporaryTypeBlock
            isDisabled={true}
            type="Финансирование под залог недвижимости"
            disabledText="Продукт скоро станет доступен"
            // onClick={() =>
            //   goToFinancingTypeForm(APPLICATION_TYPES.PROPERTY_FINANCING)
            // }
            youtubeLink="https://www.youtube.com/embed/vsICSYakApE?si=DdA5c4R_f41u8DyE"
            icon={<Icon iconLink={BankIcon} />}
          />
        </Col>
      </RowStyled>
      <RowStyled>
        <Col lg={3}>
          <FinancingTypeBlock
            type="Проектно-инвестиционное финансирование"
            onClick={() =>
              setFinancingTypeAndNextStep(APPLICATION_TYPES.FINANCING)
            }
            youtubeLink="https://www.youtube.com/embed/toejMIK59w0?si=E5JEdSelaYIU0zis"
            icon={<Icon iconLink={GraphIcon} />}
          />
        </Col>
        <Col lg={3}>
          <FinancingTypeBlock
            type="Факторинг"
            onClick={goToFactoringEdit}
            youtubeLink="https://www.youtube.com/embed/fiFv-iki3Yw?si=zfdQJyoUQGKxre2h"
            icon={<Icon iconLink={SavingsIcon} />}
          />
        </Col>
        <Col lg={3}>
          <FinancingTypeBlock
            isDisabled={true}
            type="Лизинг"
            disabledText="Продукт скоро станет доступен"
            // onClick={() =>
            //   setFinancingTypeAndRedirectToInitializationStep(
            //     APPLICATION_TYPES.LEASING
            //   )
            // }
            icon={<Icon iconLink={LeasingIcon} />}
          />
        </Col>
      </RowStyled>
    </Container>
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  applicationId: Application.createApplication.id,
  status: Application.createApplication.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setInitializationFormData, createApplication },
    dispatch
  );

const FinancingTypeChoiceScreenConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FinancingTypeChoiceScreen)
);

export { FinancingTypeChoiceScreenConnect as FinancingTypeChoiceScreen };
