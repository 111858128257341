import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getFactoringConnections,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getFactoringConnectionsFactorRole';
import {
  CreateBtn,
  CreateBtnText,
  InnFilterArea,
  InnFilter,
  InnContainer,
  ListSection,
  FullList,
  CheckIcon,
  CrossIcon,
  TimesIcon,
  GreyIcon,
  IconPopup,
  StatusArea,
  StatusAreaRow,
  ConfirmContainer,
  DeclineContainer,
  HandleContainer,
  InactiveContainer,
  InactiveContainerHovered,
  ActiveContainer,
  ActiveContainerHovered,
  StatusItem,
  CheckedIcon
} from './styles';
import {
  CreateLimitsNudge,
  PaginationWrapper,
  PageNoMarginStyled
} from 'src/features/SCF/UI/ScfStyles';
import '../styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'entities/SCF/Factor/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  factoringConnections: ResponseDataType;
}

interface DispatchToProps {
  getFactoringConnections: (data: RequestDataType) => void;
}

interface State {
  pageUrl: Href;
  debtorInnToFilter: string;
  supplierInnToFilter: string;
  updateChecker: ResponseDataType;
}

type Props = StateToProps & DispatchToProps;

class ConnectionsList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        debtorInnToFilter: '',
        supplierInnToFilter: '',
        updateChecker: undefined
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleDebtorInnToFilterChange = this.handleDebtorInnToFilterChange.bind(
        this
      );
      this.handleFactorInnToFilterChange = this.handleFactorInnToFilterChange.bind(
        this
      );
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  handleDebtorInnToFilterChange(event) {
    this.setState({ debtorInnToFilter: event.target.value });
  }

  handleFactorInnToFilterChange(event) {
    this.setState({ supplierInnToFilter: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  handleUpdateCheckerChange = async () => {
    await this.delay(200);
    this.setState({ updateChecker: this.props.factoringConnections });
  };

  initFetch = () => {
    const { getFactoringConnections } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.debtorInnToFilter == '' && this.state.supplierInnToFilter == ''
        ? getFactoringConnections({ page, pageSize: 10 })
        : this.state.debtorInnToFilter != '' &&
          this.state.supplierInnToFilter == ''
          ? getFactoringConnections({
              page: 1,
              DebtorInnToFilterBy: this.state.debtorInnToFilter
            })
          : this.state.debtorInnToFilter == '' &&
            this.state.supplierInnToFilter != ''
            ? getFactoringConnections({
                page: 1,
                SupplierInnToFilterBy: this.state.supplierInnToFilter
              })
            : getFactoringConnections({
                page: 1,
                DebtorInnToFilterBy: this.state.debtorInnToFilter,
                SupplierInnToFilterBy: this.state.supplierInnToFilter
              });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.debtorInnToFilter !== prevState.debtorInnToFilter ||
      this.state.supplierInnToFilter !== prevState.supplierInnToFilter ||
      this.state.updateChecker !== prevState.updateChecker
    ) {
      this.initFetch();
    }
  }

  CreateLimitsClick = () => {
    history.push('/factor/connections/add');
  };

  navigateToConnections = (guid: string) => {
    window.open(`/factor/connections/${guid}`, '_blank');
  };

  render() {
    const { status, factoringConnections, error } = this.props;
    return (
      <PageNoMarginStyled>
        <CreateLimitsNudge>
          <h2>Факторинговые связки</h2>
          <CreateBtn onClick={this.CreateLimitsClick}>
            <CreateBtnText>+ Создать</CreateBtnText>
          </CreateBtn>
        </CreateLimitsNudge>
        <InnFilterArea>
          <InnFilter>
            <p>ИНН Дебитора</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
          <InnFilter>
            <p>ИНН Поставщика</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleFactorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
        </InnFilterArea>

        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="10%">
                      ИНН ДЕБИТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="18%">
                      ДЕБИТОР
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="10%">
                      ИНН ПОСТАВЩИКА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="18%">
                      ПОСТАВЩИК
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="25%">
                      СТАТУС
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      ФИНАНСИРОВАНИЕ
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {factoringConnections.items.map((item, key) => (
                    <TableRowStyled
                      key={key}
                      onClick={() => this.navigateToConnections(item.id)}
                    >
                      <td>{item.debtorInn}</td>
                      <td>{item.debtorName}</td>
                      <td>{item.supplierInn}</td>
                      <td>{item.supplierName}</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        {item.confirmedBySupplier == 1 ? (
                          <StatusArea className="icon-to-show-popup">
                            <CheckIcon />
                            <IconPopup className="icon-popup">
                              <p>Подтверждено поставщиком</p>
                            </IconPopup>
                          </StatusArea>
                        ) : item.confirmedBySupplier == 0 ? (
                          <StatusArea className="icon-to-show-popup">
                            <GreyIcon />
                            <IconPopup className="icon-popup">
                              <p>Не подтверждено поставщиком</p>
                            </IconPopup>
                          </StatusArea>
                        ) : (
                          <StatusArea className="icon-to-show-popup">
                            <TimesIcon />
                            <IconPopup className="icon-popup">
                              <p>Отклонено поставщиком</p>
                            </IconPopup>
                          </StatusArea>
                        )}
                        {item.confirmedByFactor == 1 ? (
                          <StatusArea className="icon-to-show-popup">
                            <CheckIcon />
                            <IconPopup className="icon-popup">
                              <p>Вы подтвердили связку</p>
                            </IconPopup>
                          </StatusArea>
                        ) : item.confirmedByFactor == 0 ? (
                          <StatusAreaRow>
                            <ConfirmContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionFactorRoleConfirm(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Подтвердить</p>
                              </HandleContainer>
                            </ConfirmContainer>
                            <DeclineContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionFactorRoleDecline(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Отклонить</p>
                              </HandleContainer>
                            </DeclineContainer>
                          </StatusAreaRow>
                        ) : (
                          <StatusAreaRow>
                            <StatusArea className="icon-to-show-popup">
                              <TimesIcon style={{ marginTop: '22px' }} />
                              <IconPopup className="icon-popup">
                                <p>Вы отклонили связку</p>
                              </IconPopup>
                            </StatusArea>
                            <ConfirmContainer
                              onClick={() =>
                                SCFApi.putFactoringConnectionFactorRoleConfirm(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <p>Подтвердить</p>
                              </HandleContainer>
                            </ConfirmContainer>
                          </StatusAreaRow>
                        )}
                      </td>
                      <td className="td-status">
                        {item.readyForFactorFinance == true ? (
                          <StatusItem>
                            <ActiveContainer>
                              <p>Включено</p>
                            </ActiveContainer>
                            <InactiveContainerHovered
                              className="inactive-status"
                              onClick={() =>
                                SCFApi.putFactoringConnectionFactorRoleDisableFinance(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <CrossIcon style={{ margin: '0 7px 0 7px' }} />
                              </HandleContainer>
                            </InactiveContainerHovered>
                            <h1 className="inactive-status">Отключить</h1>
                          </StatusItem>
                        ) : (
                          <StatusItem>
                            <InactiveContainer>
                              <p>Приостановлено</p>
                            </InactiveContainer>
                            <ActiveContainerHovered
                              className="active-status"
                              onClick={() =>
                                SCFApi.putFactoringConnectionFactorRoleEnableFinance(
                                  item.id
                                )
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <CheckedIcon style={{ marginLeft: '5px' }} />
                              </HandleContainer>
                            </ActiveContainerHovered>
                            <h1 className="active-status">Включить</h1>
                          </StatusItem>
                        )}
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
        </ListSection>
        <PaginationWrapper onClick={this.handlePageChange}>
          <Pagination list={this.props.factoringConnections} />
        </PaginationWrapper>
      </PageNoMarginStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  factoringConnections: SCFFactor.getFactoringConnectionsFactorRole.data,
  status: SCFFactor.getFactoringConnectionsFactorRole.status,
  error: SCFFactor.getFactoringConnectionsFactorRole.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getFactoringConnections }, dispatch);

const ConnectionsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionsList);

export { ConnectionsListConnect as ConnectionsList };
