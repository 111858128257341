import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'src/features/Application/actions/getActivitiesByApplication';
import { GET_ACTIVITIES_BY_APPLICATION } from 'src/features/Application/reducers/getActivitiesByApplication';

import ApplicationApi from 'Application/api';

function* getActivitiesByApplication(action: {
  type: GET_ACTIVITIES_BY_APPLICATION;
  applicationId: string;
}) {
  try {
    const res: any = yield call(
      ApplicationApi.getActivitiesByLead,
      action.applicationId
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getBanksListSaga() {
  yield takeLatest(
    GET_ACTIVITIES_BY_APPLICATION.REQ,
    getActivitiesByApplication
  );
}
