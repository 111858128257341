import { GET_KEY_RATES } from '../reducers/getKeyRates';

export interface ResponseData {
  keyRate: number;
}

export const req = () => ({
  type: GET_KEY_RATES.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_KEY_RATES.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_KEY_RATES.ERR,
  error
});
