import { GET_FACTORING_LIMITS } from 'entities/Cabinet/InternalAgent/model/reducers/getFactoringLimits';

export interface ResponseData {
  companyName: string;
  hasLimit: boolean;
  inn: string;
}

export const req = (inn: string) => ({
  type: GET_FACTORING_LIMITS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_FACTORING_LIMITS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTORING_LIMITS.ERR,
  error
});

export const reset = () => ({
  type: GET_FACTORING_LIMITS.RESET
});
