import { SEND_BANK_ACCOUNT_DATA } from '../reducers/sendBankAccountData';
import { BankAccountWrite } from './setBankAccount';

export interface RequestData extends Partial<BankAccountWrite> { }

export const req = (data: RequestData) => ({
    type: SEND_BANK_ACCOUNT_DATA.REQ,
    data
});

export const got = () => ({
    type: SEND_BANK_ACCOUNT_DATA.GOT
});

export const err = (error: {}) => ({
    type: SEND_BANK_ACCOUNT_DATA.ERR,
    error
});
