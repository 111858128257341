import {
  RequestDataType,
  PUT_EARLY_DATE_PICK
} from 'src/entities/SCF/Supplier/model/reducers/postEarlyDatePick';

export const req = (data: RequestDataType) => ({
  type: PUT_EARLY_DATE_PICK.REQ,
  data
});

export const got = () => ({
  type: PUT_EARLY_DATE_PICK.GOT
});

export const err = (error: {}) => ({
  type: PUT_EARLY_DATE_PICK.ERR,
  error
});
