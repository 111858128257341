import styled from 'styled-components';
import { lighterBlue, rr } from 'shared/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ImageStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const FiltersContainerStyled = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  background-color: ${lighterBlue};

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 20px;
  background-color: ${lighterBlue};
`;

export const ContainerCompaniesFilter = styled(Container)`
  > :last-of-type {
    margin-left: 15px;
  }
`;

export const MatchFilters = styled.p`
  margin-left: 35px;

  & > span {
    color: black;
    font-weight: bold;
  }
`;

export const TDNegativeContainer = styled.td`
  color: ${({ negative }) => negative && '#F53737'} !important;
`;

export const TDInnStyled = styled.td`
  position: relative;
  overflow: visible !important;

  div:first-of-type {
    position: ${({ isOkved }) => isOkved && 'absolute'};
    top: ${({ isOkved }) => isOkved && '15px'};
  }

  div:last-of-type {
    position: absolute;
    bottom: 15px;
    left: 5px;
    font-family: ${rr};
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`;

export const TDCompanyNameStyled = styled.td`
  div {
    position: ${({ isOkved }) => isOkved && 'absolute'};
    top: ${({ isOkved }) => isOkved && '15px'};
  }
`;

export const TableRowCustomStyled = styled(TableRowStyled)`
  position: relative;
  height: 70px;
`;

export const Image = styled(ImageStyled)`
  z-index: 1;
  width: 55px;
  bottom: 13%;
  right: 5px;
`;
