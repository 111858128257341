import styled, { css } from 'styled-components';

const positionHint = position => {
  switch (position) {
    case 'left':
      return css`
        right: calc(100% + 10px);
        &::before {
          right: 0;
          box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.06);
          transform: translate(50%, -50%) rotate(45deg);
        }
      `;
    case 'right':
      return css`
        left: calc(100% + 10px);
        &::before {
          left: 0;
          box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.06);
          transform: translate(-50%, -50%) rotate(45deg);
        }
      `;
  }
};

export const HintStyled = styled.div`
  width: auto;
  height: 135px;

  padding: 15px;

  top: 50%;
  z-index: 1;
  position: absolute;
  transform: translateY(-50%);

  background-color: white;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

  &::before {
    content: '';

    width: 12px;
    height: 12px;

    top: 50%;
    z-index: -1;
    position: absolute;

    background-color: #fff;
    transform-origin: center;
  }

  ${({ position }) => positionHint(position)};
`;
