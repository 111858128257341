import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { ApplicationTypes, CreditingPledge } from 'shared/constants';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import { req as getApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/actions/getApplicationExternal';
import { RequestDataType as ReqApplicationExternal } from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';
import { req as getInBankApplication } from 'Application/actions/getInBankApplication';
import { ResponseDataType as ResInBankApplication } from 'Application/reducers/getInBankApplication';

import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { reset as resetFinancialsSummary } from 'src/features/Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';

import { Loader } from 'src/features/Layouts/components';
import { EachApplicationView } from './EachApplicationView';
import { CreditingApplicationRead } from 'src/features/Application/types';

interface StateToProps {
  externalApplication: Partial<CreditingApplicationRead>;
  status: REQUEST_STATUSES;
  inBankApplication: ResInBankApplication;
  statusInBankApplication: REQUEST_STATUSES;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getApplicationExternal: (data: ReqApplicationExternal) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CreditingView: React.FC<Props> = ({
  getApplicationExternal,
  externalApplication,
  status,
  getInBankApplication,
  inBankApplication,
  statusInBankApplication,
  resetFinancialsSummary,
  resetSelectApplicationState,
  getProductApplication,
  statusPostSendBank,
  statusSendApi,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusChangeApplicationStatus,
  permissions,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getApplicationExternal({ id });
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getApplicationExternal({ id: inBankId });
        getInBankApplication({ id: +inBankId });
      }

      return () => resetFinancialsSummary();
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getApplicationExternal({ id });
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getApplicationExternal({ id: inBankId });
          getInBankApplication({ id: +inBankId });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getApplicationExternal({ id });
        } else if (inBankId) {
          getApplicationExternal({ id: inBankId });
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  const setSubInfo = () => [
    {
      name: 'Тип кредита',
      value: externalApplication.revolvingCredit
        ? 'Возобновляемая кредитная линия'
        : externalApplication.overdraft
          ? 'Овердрафт'
          : externalApplication.credit
            ? 'Кредит'
            : 'не заполнено'
    },
    {
      name: 'Наличие залога',
      value:
        CreditingPledge[externalApplication.pledge] === 'Есть'
          ? `Да / ${[
              externalApplication.realEstate ? 'Недвижимое имущество' : '',
              externalApplication.movableEstate ? 'Движимое имущество' : '',
              externalApplication.otherEstate
                ? `Иное ${externalApplication.otherEstate}`
                : ''
            ]
              .filter(Boolean)
              .join(', ')}`
          : CreditingPledge[externalApplication.pledge] === 'Нет'
            ? 'Нет'
            : 'не заполнено'
    },
    // ...(data.pledges && data.pledges.length > 0
    //   ? [
    //       {
    //         name: 'Тип залога',
    //         value: data.pledges
    //           .map(item => CreditingPledgeType[item])
    //           .join(' / ')
    //       }
    //     ]
    //   : []),
    // ...(!!data.otherPledgeEstate
    //   ? [
    //       {
    //         name: 'Дополнительные виды обеспечения',
    //         value: data.otherPledgeEstate
    //       }
    //     ]
    //   : []),
    {
      name: 'Есть ли поручительства',
      value:
        externalApplication.guarantee === true
          ? `Да / ${
              externalApplication.beneficiaryGuarantee
                ? 'Поручительства бенефициаров,'
                : ''
            }, ${
              externalApplication.companiesGuarantee
                ? 'Поручительства других компаний группы'
                : ''
            }`.replace(/\/\s*,\s*$/, '')
          : externalApplication.guarantee === false
            ? 'Нет'
            : 'не заполнено'
    }
    // ...(data.guarantors && data.guarantors.length > 0
    //   ? [
    //       {
    //         name: 'Поручительства',
    //         value: data.guarantors
    //           .map(item => CreditingGuarantorType[item])
    //           .join(' / ')
    //       }
    //     ]
    //   : [])
  ];

  if (
    status !== REQUEST_STATUSES.GOT ||
    statusInBankApplication !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[externalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(externalApplication.amount)
        },
        {
          name: 'Срок',
          value: formTermString(externalApplication.term)
        },
        {
          name: 'ИНН',
          value: externalApplication.code
        },
        {
          name: 'Рефинансирование',
          value: externalApplication.refinancing ? 'Да' : 'Нет'
        }
      ]}
      subInfo={[...setSubInfo()]}
      documents={{
        general: [
          ...inBankApplication.agreementDocuments,
          ...inBankApplication.groupDocuments
        ],
        company: inBankApplication.borrowerCompanies
      }}
    />
  );
};

const mapStateToProps = ({ Application, ExternalAgentRole, User }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  status: ExternalAgentRole.getApplicationExternal.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getApplicationExternal,
      getInBankApplication,
      getProductApplication,
      resetSelectApplicationState,
      resetFinancialsSummary
    },
    dispatch
  );

const CreditingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CreditingView)
);

export { CreditingViewConnect as CreditingView };
