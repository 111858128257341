import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/getTenderByClient';

import { GET_TENDER_BY_CLIENT } from 'entities/Cabinet/Client/model/reducers/getTenderByClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* getTenderByClient(action: {
  type: GET_TENDER_BY_CLIENT;
  id: string;
}): Generator {
  try {
    const res: any = yield call(ClientRoleApi.getTenderByClient, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getTenderByClientSaga(): Generator {
  yield takeLatest(GET_TENDER_BY_CLIENT.REQ, getTenderByClient);
}
