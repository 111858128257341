import { GET_CREDIT_PRODUCTS } from 'src/features/Clients/reducers/getCreditProducts';
import { CreditProducts } from 'src/features/Clients/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CreditProducts[];
}

export const req = (bankInn: string) => ({
  type: GET_CREDIT_PRODUCTS.REQ,
  bankInn
});

export const got = (data: ResponseData) => ({
  type: GET_CREDIT_PRODUCTS.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_CREDIT_PRODUCTS.ERR,
  error
});
