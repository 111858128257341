import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { FinbankRead } from '../actions/setFinbankFormData';

export interface PostFinbankStoreState extends Reducer {
  data: FinbankRead;
}

export enum POST_FINBANK {
  REQ = 'POST_FINBANK_REQ',
  GOT = 'POST_FINBANK_GOT',
  ERR = 'POST_FINBANK_ERR',
  RESET = 'POST_FINBANK_RESET'
}

export const initialState: PostFinbankStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {},
  error: {}
};

const postFinbank = (
  state: PostFinbankStoreState = initialState,
  action: {
    type: string;
    data: FinbankRead;
    error: {};
  }
): PostFinbankStoreState => {
  switch (action.type) {
    case POST_FINBANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FINBANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case POST_FINBANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_FINBANK.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: {}
      };
    default:
      return state;
  }
};

export default postFinbank;
