import { GET_FACTORING_CONTRACTS_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getFactoringContractsFactorRole';

export interface FactoringContract {
  id: string;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  cessionMinimalShare: number;
  cessionMaximalShare: number;
  financeMinimalShare: number;
  financeMaximalShare: number;
  isRegressed: boolean;
  isNotHidden: boolean;
  gracePeriod: number;
  moveGracePeriodToBusinessDay: boolean;
  regressPeriod: number;
  moveRegressPeriodToBusinessDay: boolean;
  contraceFileIdentifyer: any;
  contraceFileName: string;
  supplierSignatureIdentifier: any;
  supplierSignatureFileName: string;
  debtorSignatureIdentifier: any;
  debtorSignatureFileName: string;
  factorSignatureIdentifier: any;
  factorSignatureFileName: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: FactoringContract[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  SupplierInnToFilterBy?: string;
  DebtorInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_FACTORING_CONTRACTS_FACTOR_ROLE.GET_FACTORING_CONTRACTS_FACTOR_ROLE_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_FACTORING_CONTRACTS_FACTOR_ROLE.GET_FACTORING_CONTRACTS_FACTOR_ROLE_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_FACTORING_CONTRACTS_FACTOR_ROLE.GET_FACTORING_CONTRACTS_FACTOR_ROLE_ERR,
  error
});
