import { GET_TENDER_BY_CLIENT } from 'entities/Cabinet/Client/model/reducers/getTenderByClient';

export interface ResponseData {
  applicationId: number;
  tender: {
    id: number;
    createdDateTime: string;
    modifiedDateTime: string;
    customerINN: string;
    customerName: string;
    supplierINN: string;
    supplierName: string;
    publicationDate: string;
    startPrice: number;
    tenderType: number;
    tenderSegment: number;
    eruzId: string;
    protocolDate: string;
    hasWinner: boolean;
    contractConcluded: boolean;
    contractGuid: string;
    tenderCompleted: boolean;
    tenderFailed: boolean;
    tenderLink: string;
    writeoffRequest: any;
    ikz: any;
    subject: any;
    purchaseRegion: any;
    supplierDeterminationMethod: any;
    platformName: string;
    platformLink: string;
    status: any;
    isClosedAuction: boolean;
    isSingleSupplier: boolean;
    isPublic: boolean;
    sumFinal: number;
    auctionDate: any;
    customerEmail: string;
    customerPhone: string;
    isDeleted: boolean;
    lotCode: string;
    subjectType: any;
  };
}

export const req = (id: string) => ({
  type: GET_TENDER_BY_CLIENT.REQ,
  id
});

export const got = (data: ResponseData[]) => ({
  type: GET_TENDER_BY_CLIENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_TENDER_BY_CLIENT.ERR,
  error
});

export const reset = () => ({
  type: GET_TENDER_BY_CLIENT.RESET
});
