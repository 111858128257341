import { GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR } from '../reducers/getAllDiscountAuctions';

export interface AuctionItem {
  guid: string;
  createdDateTime: string;
  liquidityLimit: number;
  resultsDateTime: string;
  payDate: string;
  targetDiscountRate: number;
  status: any;
  minDiscountRate: number;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: AuctionItem[];
}

export const req = () => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_DISCOUNT_AUCTIONS_BY_DEBTOR.ERR,
  error
});
