import { GET_INTERNAL_AGENTS_LIST } from 'InternalAgentsManager/reducers/getInternalAgentsList';

// NOTE naming
export interface InternalAgent {
  id: number;
  name: string;
  email: string;
  status: string;
}

export const req = () => ({
  type: GET_INTERNAL_AGENTS_LIST.REQ
});

export const got = (data: InternalAgent[]) => ({
  type: GET_INTERNAL_AGENTS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INTERNAL_AGENTS_LIST.ERR,
  error
});
