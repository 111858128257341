import {
  GET_CLIENT_APPLICATION,
  ResponseData,
  RequestDataType
} from 'entities/Cabinet/Client/model/reducers/getClientApplication';

export const req = (data: RequestDataType) => ({
  type: GET_CLIENT_APPLICATION.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_CLIENT_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CLIENT_APPLICATION.ERR,
  error
});
