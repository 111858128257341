import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getCompanyByInn';
import { GET_COMPANY_BY_INN } from '../reducers/getCompanyByInn';
import SCFApi from '../api';

function* getCompanyByInn(action: {
  type: GET_COMPANY_BY_INN;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getCompanyByInn, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnSaga() {
  yield takeLatest(
    GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_REQ,
    getCompanyByInn
  );
}
