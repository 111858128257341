import { APPLICATION_TYPES } from 'src/features/Application/types';
import { history } from 'shared/utils/History';

/**
 * Перенаправляет пользователя на нужный маршрут в зависимости от типа финансирования.
 *
 * @param {Object} application - Данные заявки.
 * @param {Object} matchParams - Объект с параметрами URL (id, inBankId).
 */

export const redirectToApplication = application => {
  for (const [type, route] of Object.entries(APPLICATION_TYPES)) {
    if (application.financingType === type) {
      const id = application.id;
      const mainId =
        application.mainApplicationId > 0 ? application.mainApplicationId : id;

      const url = `/cabinet/internal/${
        route === APPLICATION_TYPES.FINANCING
          ? 'application'
          : `application-${route.replace('_', '-')}`
      }/${mainId}${
        application.mainApplicationId > 0 ? `/${id}` : ''
      }`.toLowerCase();

      history.push(url);
      return;
    }
  }
};
