import { SEND_CERTIFICATE_DATA } from '../reducers/sendCertificateData';
import { CertificateWrite } from './setCertificate';

export interface RequestData extends Partial<CertificateWrite> { }

export const req = (data: RequestData) => ({
    type: SEND_CERTIFICATE_DATA.REQ,
    data
});

export const got = () => ({
    type: SEND_CERTIFICATE_DATA.GOT
});

export const err = (error: {}) => ({
    type: SEND_CERTIFICATE_DATA.ERR,
    error
});
