import { GET_USER_LIST } from '../reducers/getUserList';
import { USER_LIST_TYPE } from '../types';

export interface UserRead {
  id?: number;
  inn?: string;
  createdDateTime?: string;
  modifiedDateTime?: string | null;
  lockoutEnd?: unknown;
  accessFailedCount?: number;
  email?: string;
  emailConfirmed?: boolean;
  firstName?: string;
  lastName?: string;
  isBlocked?: boolean;
  phoneNumber?: string;
  companyInn?: string;
  accountStatus?: number;
  allowSimpleProcess?: boolean;
  allowBlankFields?: boolean;
  lastLoginAt?: string | null;
  lastApplicationAt?: string | null;
}

export interface UserWrite {
  id?: number;
  inn?: string;
  shortName?: string;
  holdingId?: number;
  holdingName?: string;
  equityOwnershipType?: number;
  isActive?: boolean;
  priority?: number;
  revenue?: number;
  costOfSales?: number;
  accountPayables?: number;
  paymentPostponement?: number;
  hasBuyerPlatform?: boolean;
  hasSellerPlatform?: boolean;
  hasDynamicDiscounting?: boolean;
  factoringMaturityLevel?: number;
  hasLoansForSellers?: boolean;
  isDeleted?: boolean;
}

export interface UserRead extends UserWrite {}

export interface UserData extends Partial<UserWrite> {}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: UserRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  companyInnToFilterBy?: string;
  role?: string;
  isBlocked?: boolean;
}

export const req = (data: RequestData) => ({
  type: GET_USER_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_USER_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_USER_LIST.ERR,
  error
});
