import { combineReducers } from 'redux';
import createBankAccount, {
  CreateBankAccountStoreState
} from './createBankAccount';
import getBankAccountList, {
  GetBankAccountListStoreState
} from './getBankAccountList';

export interface BankAccountReducers {
  createBankAccount: CreateBankAccountStoreState;
  getBankAccountList: GetBankAccountListStoreState;
}

export const BankAccount = combineReducers({
  createBankAccount,
  getBankAccountList
});
