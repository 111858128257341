export interface BuyersToSupplierLinkListType {
  id: number;
  isDeleted: boolean;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  confirmedBySupplier: boolean;
  confirmedByDebtor: boolean;
  supplierConfirmationDate: null;
  debtorConfirmationDate: null;
  supplierConfirmUserId: number;
  debtorConfirmUserId: number;
  rejectedBySupplier: null;
  rejectedByDebtor: null;
  supplierInAction: null;
  supplierFactorable: null;
  supplierHoldingId: null;
  supplierHoldingName: string;
  debtorInAction: boolean;
  debtorFactorable: boolean;
  debtorHoldingId: null;
  debtorHoldingName: string;
  discountMargin: number;
}

export enum BUYERS_TO_SUPPLIER_TABS {
  DEFAULT = 'Договоры поставки',
  FACTORING_CONTRACTS = 'Договоры факторинга'
}
