import { GET_EXTERNAL_CREDENTIAL } from '../reducers/getExternalCredential';
import { ExternalCredentialWrite } from './setExternalCredentialFormData';

export const req = (id: string | number) => ({
  type: GET_EXTERNAL_CREDENTIAL.REQ,
  id
});

export const got = (externalCredential: ExternalCredentialWrite) => ({
  type: GET_EXTERNAL_CREDENTIAL.GOT,
  externalCredential
});

export const err = (error: {}) => ({
  type: GET_EXTERNAL_CREDENTIAL.ERR,
  error
});
