import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getFactoringContractsFactorRole';
import { GET_FACTORING_CONTRACTS_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getFactoringContractsFactorRole';
import SCFApi from 'entities/SCF/Factor/api';

function* getFactoringContractsFactorRole(action: {
  type: GET_FACTORING_CONTRACTS_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getFactoringContractsFactorRole, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractsFactorRoleSaga() {
  yield takeLatest(
    GET_FACTORING_CONTRACTS_FACTOR_ROLE.GET_FACTORING_CONTRACTS_FACTOR_ROLE_REQ,
    getFactoringContractsFactorRole
  );
}
