import {
  GET_QTY_SIDEBAR,
  ResponseData
} from 'entities/Cabinet/ExternalAgent/model/reducers/getQtySidebar';

export const req = () => ({
  type: GET_QTY_SIDEBAR.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_QTY_SIDEBAR.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_QTY_SIDEBAR.ERR,
  error
});
