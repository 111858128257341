import { RESET_PASSWORD } from 'User/reducers/resetPassword';

export interface RequestDataType {
  userId: string;
  token: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_REQ,
  data
});

export const got = () => ({
  type: RESET_PASSWORD.RESET_PASSWORD_GOT
});

export const err = (error: {}) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_ERR,
  error
});
