import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postAllowFields';

import { POST_USER_ALLOW_FIELDS } from '../reducers/postAllowFields';
import UserApi from '../api';

function* postAllowFields(action: {
  type: POST_USER_ALLOW_FIELDS;
  id: string;
  typeProcess: string;
}): Generator {
  try {
    const res: any = yield call(
      UserApi.postAllowFields,
      action.id,
      action.typeProcess
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAllowProcessSaga(): Generator {
  yield takeLatest(POST_USER_ALLOW_FIELDS.REQ, postAllowFields);
}
