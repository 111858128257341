import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { CRM, REQUEST_STATUSES, STORE } from 'globaltypes';
import { ResponseData as DocumentListRes } from 'src/features/DocumentList/actions/getDocumentList';
import { req as getUserData } from 'User/actions/getUserData';
import { Header } from '../Header/Header';
import { AuthorizedBlock, HeaderStyled, Logo } from '../Header/styles';
import { Login, Register } from './styled';

interface StateToProps {
  isAuthorized: boolean;
  documents: DocumentListRes;
  statusDocuments: REQUEST_STATUSES;
}

interface DispatchToProps {
  getUserData: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const HeaderDocuments: React.FC<Props> = ({
  getUserData,
  isAuthorized,
  documents,
  statusDocuments,
  history
}) => {
  const [companyInn, setCompanyInn] = React.useState('');

  React.useEffect(
    () => {
      if (isAuthorized) {
        getUserData();
      }
    },
    [isAuthorized]
  );

  React.useEffect(
    () => {
      if (statusDocuments === REQUEST_STATUSES.GOT) {
        setCompanyInn(documents.items[0].companyINN);
      }
    },
    [statusDocuments]
  );

  const navigateToLogin = () => {
    history.push('/login');
  };

  const navigateToBusiness = () => {
    if (companyInn) {
      history.push(`/registration/business/${companyInn}`);
    }
  };

  return isAuthorized ? (
    <Header />
  ) : (
    <HeaderStyled>
      <Logo />
      <AuthorizedBlock>
        <Login onClick={navigateToLogin}>Войти</Login>
        <Register onClick={navigateToBusiness}>Зарегистрироваться</Register>
      </AuthorizedBlock>
    </HeaderStyled>
  );
};

const mapStateToProps = ({ Cached, DocumentList }: CRM & STORE) => ({
  isAuthorized: Cached.authorized,
  documents: DocumentList.getDocumentList.data,
  statusDocuments: DocumentList.getDocumentList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserData }, dispatch);

const HeaderDocumentsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderDocuments)
);

export { HeaderDocumentsConnect as HeaderDocuments };
