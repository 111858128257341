import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import { STORE } from 'globaltypes';
import { APPLICATION_TYPE_ROUTES, APPLICATION_TYPES } from 'Application/types';

import { reset as resetCreateApplication } from 'Application/actions/createApplication';
import { resetInitializationFormData } from 'Application/actions/setInitializationFormData';
import { resetFactoringFormData } from 'Application/actions/setFactoringFormData';
import { resetGuaranteesFormData } from 'Application/actions/setGuaranteesFormData';
import { resetCreditingFormData } from 'Application/actions/setCreditingFormData';
import { resetFinancingFormData } from 'Application/actions/setFinancingFormData';
import { reset as resetUpdateInitializationFormData } from 'Application/actions/updateInitializationFormData';
import { reset as resetApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import { resetLocalProperties } from 'Properties/actions/localProperties';
import { resetPropertyFinancingFormData } from 'src/features/Application/actions/setPropertyFinancingFormData';
import { resetContractFinancingFormData } from 'src/features/Application/actions/setContractFinancingFormData';

import { APPLICATION_PROCESS_MODES } from 'Application/reducers/setApplicationProcessMode';

import {
  InitializationFormStep1,
  CancelApplicationProcessModal,
  FactoringForm,
  FinancingForm,
  FinancingTypeChoiceScreen
} from 'Application/components';

import { PropertyFinancingForm } from './PropertyFinancing/PropertyFinancingForm';
import { ApplicationFormContrainer } from './styles';

interface OwnProps {
  cancelProcess: () => void;
}

interface StateToProps {
  applicationProcessMode: APPLICATION_PROCESS_MODES;
  applicationType: APPLICATION_TYPES;
}

interface DispatchToProps {
  resetApplicationProcessMode: () => void;
  resetUpdateInitializationFormData: () => void;
  resetInitializationFormData: () => void;
  resetPropertyFinancingFormData: () => void;
  resetContractFinancingFormData: () => void;
  resetFactoringFormData: () => void;
  resetGuaranteesFormData: () => void;
  resetCreditingFormData: () => void;
  resetFinancingFormData: () => void;
  resetLocalProperties: () => void;
  resetCreateApplication: () => void;
}

type Props = RouteComponentProps & OwnProps & DispatchToProps & StateToProps;

const ApplicationProcess: React.FC<Props> = ({
  resetApplicationProcessMode,
  resetContractFinancingFormData,
  resetCreditingFormData,
  resetFactoringFormData,
  resetFinancingFormData,
  resetGuaranteesFormData,
  resetInitializationFormData,
  resetLocalProperties,
  resetPropertyFinancingFormData,
  resetUpdateInitializationFormData,
  resetCreateApplication,
  applicationProcessMode,
  applicationType,
  cancelProcess,
  match
}) => {
  React.useEffect(() => {
    return () => {
      resetFactoringFormData();
      resetGuaranteesFormData();
      resetCreditingFormData();
      resetFinancingFormData();
      resetContractFinancingFormData();
      resetPropertyFinancingFormData();
      resetCreateApplication();
    };
  }, []);

  const matchPath = match.path;

  return (
    <ApplicationFormContrainer>
      <CancelApplicationProcessModal cancelProcess={cancelProcess} />

      <Switch>
        <Route
          path={`${matchPath}/${APPLICATION_TYPE_ROUTES.CHOICE_SCREEN}`}
          component={FinancingTypeChoiceScreen}
        />

        <Route
          path={`${matchPath}/${APPLICATION_TYPE_ROUTES.FACTORING}`}
          component={FactoringForm}
        />

        <Route
          path={`${matchPath}/${APPLICATION_TYPE_ROUTES.NONE}`}
          component={InitializationFormStep1}
        />

        <Route
          path={`${matchPath}/${APPLICATION_TYPE_ROUTES.FINANCING}`}
          component={FinancingForm}
        />

        <Route
          path={`${matchPath}/${APPLICATION_TYPE_ROUTES.PROPERTY_FINANCING}`}
          component={PropertyFinancingForm}
        />

        {applicationProcessMode === APPLICATION_PROCESS_MODES.CREATE && (
          <Redirect
            to={`${matchPath}/${APPLICATION_TYPE_ROUTES.CHOICE_SCREEN}`}
          />
        )}

        {applicationProcessMode === APPLICATION_PROCESS_MODES.EDIT &&
          applicationType === APPLICATION_TYPES.PROPERTY_FINANCING && (
            <Redirect
              to={`${matchPath}/${APPLICATION_TYPE_ROUTES.PROPERTY_FINANCING}`}
            />
          )}

        {applicationProcessMode === APPLICATION_PROCESS_MODES.EDIT && (
          <Redirect to={`${matchPath}/${APPLICATION_TYPE_ROUTES.NONE}`} />
        )}
      </Switch>
    </ApplicationFormContrainer>
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  applicationProcessMode: Application.setApplicationProcessMode.mode,
  applicationType: Application.setInitializationFormData.financingType
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetApplicationProcessMode,
      resetUpdateInitializationFormData,
      resetInitializationFormData,
      resetFactoringFormData,
      resetGuaranteesFormData,
      resetCreditingFormData,
      resetFinancingFormData,
      resetPropertyFinancingFormData,
      resetLocalProperties,
      resetContractFinancingFormData,
      resetCreateApplication
    },
    dispatch
  );

const ApplicationProcessConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationProcess)
);

export { ApplicationProcessConnect as ApplicationProcess };
