import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getContractByDebtor';
import { GET_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/getContractByDebtor';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getContractByDebtor(action: {
  type: GET_CONTRACT_BY_DEBTOR;
  guid: string;
}) {
  try {
    const res: any = yield call(SCFDebtorApi.getContractByDebtor, action.guid);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContractByDebtorSaga() {
  yield takeLatest(GET_CONTRACT_BY_DEBTOR.REQ, getContractByDebtor);
}
