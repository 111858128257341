import { LOCAL_PROPERTIES_ACTIONS } from "../reducers/localProperties";
import { PropertyRead } from "../types/Property";

export const addLocalProperty = (property: Partial<PropertyRead>) => ({
  type: LOCAL_PROPERTIES_ACTIONS.ADD,
  property
});

export const deleteLocalProperty = (propertyId:number) => ({
  type: LOCAL_PROPERTIES_ACTIONS.DELETE,
  propertyId
});

export const updateLocalProperty = (property: Partial<PropertyRead>) => ({
  type: LOCAL_PROPERTIES_ACTIONS.UPDATE,
  property
});
export const setLocalProperties = (properties: Partial<PropertyRead>[]) => ({
  type: LOCAL_PROPERTIES_ACTIONS.SET,
  properties
});

export const resetLocalProperties = () => ({
  type: LOCAL_PROPERTIES_ACTIONS.RESET
});
