import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  AnyApplication,
  APPLICATION_TYPES,
  DOCUMENT_UPLOAD_TYPE
} from 'Application/types';

import {
  got as getApplicationGot,
  err as getApplicationErr
} from 'Application/actions/getApplication';

import {
  got as getInBankApplicationGot,
  err as getInBankApplicationErr
} from 'Application/actions/getInBankApplication';

import {
  RequestDataType,
  GET_APPLICATION
} from 'Application/reducers/getApplication';

import { GET_IN_BANK_APPLICATION } from 'Application/reducers/getInBankApplication';

import ApplicationApi from 'Application/api';
import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* getAnyApplication(
  action: {
    type: GET_APPLICATION | GET_IN_BANK_APPLICATION;
    data: RequestDataType;
  },
  actionCreators: { got; err }
): Generator {
  try {
    const applicationData: AnyApplication = location.pathname.includes(
      'internal'
    )
      ? yield call(
          ApplicationApi.getInternalApplication,
          action.data.id.toString()
        )
      : yield call(ApplicationApi.getApplication, action.data);

    const [
      agreementDocuments,
      groupDocuments,
      borrowerCompanies
    ]: any = yield all([
      call(ApplicationApi.getDocumentsList, {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.AGREEMENT
      }),
      call(ApplicationApi.getDocumentsList, {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.GROUP_SCHEME
      }),
      call(BorrowerCompaniesApi.getBorrowerCompaniesList, {
        applicationId: action.data.id
      })
    ]);

    const application = {
      ...applicationData,
      agreementDocuments,
      groupDocuments,
      borrowerCompanies
    };

    if (applicationData.financingType === APPLICATION_TYPES.FACTORING) {
      const factoringDocuments = yield call(ApplicationApi.getDocumentsList, {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.FACTORING
      });

      yield put(
        actionCreators.got({
          ...application,
          documents: factoringDocuments
        })
      );
    }

    if (applicationData.financingType === APPLICATION_TYPES.GUARANTEES) {
      const contractDocuments = yield call(ApplicationApi.getDocumentsList, {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.GUARANTEE_CONTRACT
      });

      yield put(
        actionCreators.got({
          ...application,
          contractDocuments
        })
      );
    }

    if (
      applicationData.financingType === APPLICATION_TYPES.CONTRACT_FINANCING
    ) {
      const contractDocuments = yield call(ApplicationApi.getDocumentsList, {
        id: action.data.id,
        type: DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING
      });

      yield put(
        actionCreators.got({
          ...application,
          contractDocuments
        })
      );
    }

    // TODO refactor
    if (
      applicationData.financingType === APPLICATION_TYPES.PROPERTY_FINANCING
    ) {
      const propertyFinancingDocuments = yield call(
        ApplicationApi.getDocumentsList,
        {
          id: action.data.id,
          type: DOCUMENT_UPLOAD_TYPE.PROPERTY_FINANCING
        }
      );

      yield put(
        actionCreators.got({
          ...application,
          propertyFinancingDocuments
        })
      );
    }

    if (applicationData.financingType === APPLICATION_TYPES.CREDITING) {
      yield put(actionCreators.got(application));
    }

    if (applicationData.financingType === APPLICATION_TYPES.FINANCING) {
      yield put(actionCreators.got(application));
    }
  } catch (e) {
    yield put(actionCreators.err(e));
  }
}

function* getApplication(action: {
  type: GET_APPLICATION;
  data: RequestDataType;
}): Generator {
  yield getAnyApplication(action, {
    got: getApplicationGot,
    err: getApplicationErr
  });
}

export function* getApplicationSaga(): Generator {
  yield takeLatest(GET_APPLICATION.REQ, getApplication);
}

function* getInBankApplication(action: {
  type: GET_IN_BANK_APPLICATION;
  data: RequestDataType;
}): Generator {
  yield getAnyApplication(action, {
    got: getInBankApplicationGot,
    err: getInBankApplicationErr
  });
}

export function* getInBankApplicationSaga(): Generator {
  yield takeLatest(GET_IN_BANK_APPLICATION.REQ, getInBankApplication);
}
