import { SET_FINBANK_DATA } from '../reducers/setFinbankFormData';

export interface FinbankWrite {
  id?: number;
  name?: string;
  hasBankManagers?: boolean;
  inn?: string;
  isBank?: boolean;
  isFactor?: boolean;
  isMfo?: boolean;
  isLeasingCompany?: boolean;
  loanPortfolioVolume?: number;
  loanPortfolioRating?: number;
  bgPortfolioVolume?: number;
  bgPortfolioRating?: number;
  factoringPortfolioVolume?: number;
  factoringPortfolioRating?: number;
  leasingPortfolioVolume?: number;
  leasingPortfolioRating?: number;
  lastActivity?: string;
  lastLoginAt?: string;
  lastApplicationAt?: string;
  hasContacts?: boolean;
  hasUsers?: boolean;
  isCorporate?: boolean;
  isSupportFund?: boolean;
  basicBankLicense?: boolean;
  universalBankLicense?: boolean;
  accreditedFor44?: boolean;
  accreditedFor223?: boolean;
  accreditedForSpecialAccounts?: boolean;
  accreditedMinfin?: boolean;
  accreditedMinagro?: boolean;
  productsQty?: number;
  usersQty?: number;
}

export interface FinbankRead extends FinbankWrite {}

export interface FinbanksData extends Partial<FinbankWrite> {}

export const setFinbankFormData = (data: FinbanksData) => ({
  type: SET_FINBANK_DATA.SET,
  data
});

export const resetFinbankFormData = () => ({
  type: SET_FINBANK_DATA.RESET
});
