import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getManagers';

import { GET_MANAGERS } from '../reducers/getManagers';
import PersonsApi from '../api';

function* getManagers(action: { type: GET_MANAGERS; inn: string }): Generator {
  try {
    const res: any = yield call(PersonsApi.getManagers, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getManagersSaga(): Generator {
  yield takeLatest(GET_MANAGERS.REQ, getManagers);
}
