import * as React from 'react';

import { formSumString, formTermString } from 'src/shared/utils/Utils';

import { RangeSliderStyled } from './styles';

export enum RANGE_SLIDER_TYPE {
  SUM = 'SUM',
  TERM = 'TERM',
  NONE = ''
}

const defineLabelForm = (label: 'min' | 'max', min, max, sliderType) => {
  switch (sliderType) {
    case RANGE_SLIDER_TYPE.SUM:
      return formSumString(label === 'min' ? min : max);
    case RANGE_SLIDER_TYPE.TERM:
      // TODO: add new formation function for only years or months and replace formTermStringOld
      return label === 'min'
        ? formTermString(min).split('лет ')[1]
        : formTermString(max).split(' 0')[0];
    default:
      return label === 'min' ? min : max;
  }
};

export interface Props {
  value: string;
  name: string;
  min: number;
  max: number;
  step: number;
  sliderType: string; // TODO refactor InputRange
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const RangeSlider: React.FC<Props> = ({
  value,
  name,
  min,
  max,
  step,
  sliderType,
  onChange
}: Props) => {
  return (
    <RangeSliderStyled
      value={value}
      name={name}
      min={min}
      max={max}
      step={step}
      minLabel={defineLabelForm('min', min, max, sliderType)}
      maxLabel={defineLabelForm('max', min, max, sliderType)}
      onChange={onChange}
    />
  );
};

export { RangeSlider };
