import styled from 'styled-components';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  blue,
  lightBlue,
  lightGray,
  lighterBlue,
  navyBlue,
  rm
} from 'shared/styled';
import { Button } from 'shared/ui/Button';
import { ContractsPageStyled } from '../ApplicationsList/styles';

export const ContractsPage = styled(ContractsPageStyled)`
  height: 100%;
`;

export const TableRow = styled(TableRowStyled)`
  background-color: ${({ isSelected }) => isSelected && blue};

  td {
    color: ${({ untilColor, isSelected }) =>
      isSelected ? 'white' : untilColor};
  }

  &:hover {
    td {
      color: ${({ isSelected, untilColor }) =>
        isSelected ? 'white' : untilColor !== '' ? '' : navyBlue};
      background-color: ${({ isSelected, background, untilColor }) =>
        background
          ? background
          : isSelected
            ? blue
            : untilColor === ''
              ? `${lighterBlue}`
              : 'transparent'};
    }
  }
`;

export const ButtonContainer = styled(Button)`
  width: fit-content;
  height: 30px;
  margin-top: 20px;
  background-color: ${blue};

  &:disabled {
    background-color: ${lightGray};
  }

  &:not([disabled]):hover {
    background-color: ${lightBlue};
  }
`;

export const NoData = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  justify-content: center;

  div {
    font-size: 24px;
    font-family: ${rm};
    color: ${navyBlue};
  }

  span {
    cursor: pointer;
    color: ${blue};
  }
`;

export const TableContainer = styled.div`
  margin-bottom: 20px;
`;
