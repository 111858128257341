import styled, { css } from 'styled-components';

import { rr, black, darkBlue, customUnderline } from 'shared/styled';

const RepliesContainerHeight = '200px';
const ShowRepliesButtonHeight = '20px';
export const RepliesContainerStyled = styled.div`
  width: calc(100% - 40px);
  height: auto;
  max-height: calc(${RepliesContainerHeight} + ${ShowRepliesButtonHeight});

  align-self: flex-end;

  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;

    display: block;

    top: 0;
    left: 0;
    position: absolute;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.001) 0%,
      rgba(255, 255, 255, 1) 80%
    );
  }

  ${({ isRepliesShown }) =>
    isRepliesShown &&
    css`
      max-height: 10000px;

      &::after {
        display: none;
        visibility: hidden;
      }
    `};
`;

export const ShowRepliesButton = styled.button.attrs({
  type: 'button'
})`
  height: 20px;

  top: calc(${RepliesContainerHeight} - ${ShowRepliesButtonHeight} / 2);
  left: 0;
  z-index: 2;
  position: absolute;

  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  font-family: ${rr};
  font-size: 1.2rem;
  color: ${darkBlue};

  ${customUnderline(darkBlue)};
`;
