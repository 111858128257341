import { GET_COMPANIES_LIST_BY_ID_CRM } from '../reducers/getCompaniesListById';

export interface ResponseData {
  id: number;
  guid: string;
  name: string;
  type: number;
  companyINN: string;
  creatorUserId: number;
}

export const req = (id: string) => ({
  type: GET_COMPANIES_LIST_BY_ID_CRM.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_LIST_BY_ID_CRM.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_LIST_BY_ID_CRM.ERR,
  error
});
