import styled, { css } from 'styled-components';
import { blue, gray, rr } from 'shared/styled';

export const RangeSliderStyled = styled.input.attrs(props => ({
  type: 'range',
  ['aria-label']: props.name
}))`

  -webkit-appearance: none;
  margin-bottom: 20px;

  width: 98.5%;
  
  top: -2px;
  position: relative;
  
  cursor: pointer;
  outline: none;

  /* Track Styling */
  
  ${['::-webkit-slider-runnable-track', '::-moz-range-track'].map(
    selector =>
      css`
        &${selector} {
          width: 98.5%;
          height: 2px;

          display: flex;

          cursor: pointer;
          outline: none;

          background-color: ${blue};
          border-bottom-left-radius: 10000px 3600px;
          border-bottom-right-radius: 10000px 3600px;
        }
      `
  )};

  /* Thumb Styling */

  ${['::-webkit-slider-thumb', '::-moz-range-thumb', '::-ms-thumb'].map(
    selector =>
      css`
        &${selector} {
          -webkit-appearance: none;

          width: 12px;
          height: 12px;

          transform-origin: center;
          transform: translateY(${selector !== '::-ms-thumb' ? '-50%' : '0'});

          cursor: grab;

          box-sizing: border-box;

          border: 4px solid ${blue};
          border-radius: 50%;
          background-color: #fff;
        }
      `
  )};



  /* IE/EDGE Track Styling */

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    top: -7px;
  }

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */ 
    top: -7px;
  }

  &::-ms-track {
    width: 100%;
    height: 2px;
    
    /* Hides the slider so custom styles can be added */

    background-color: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background-color: ${blue};
    border-bottom-left-radius: 10000px 3600px;
  }
  
  &::-ms-fill-upper {
    background-color: ${blue};
    border-bottom-right-radius: 10000px 3600px;
  }

  &::before,
  &::after {
    bottom: -20px;
    position: absolute;

    opacity: 0.9;

    font-family: ${rr};
    font-size: 12px;
    color: ${gray};
  }

  &::before {
    content: "${({ minLabel }) => minLabel}";
    left: 0;
  }

  &::after {
    content: "${({ maxLabel }) => maxLabel}";
    right: 0;
  }
`;
