import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import SCFApi from 'src/features/SCF/api';
import {
  CreateDiscountingSettingsPageStyled,
  ContractsHeader,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  ContractsContainer,
  NullBtn,
  NullBtnHandler,
  NullBtnText,
  SignBtn,
  SignBtnText,
  BackBtn,
  BackBtnText
} from './styles';
import {
  CertificatesArea,
  CertificatesPopupDisplay,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'src/features/Layouts/components';
import {
  DiscountingSettings,
  setDiscountingSettings
} from 'src/features/SCF/actions/setDiscountingSettings';
import {
  DaysContainer,
  DaysItem,
  DaysItemLeft,
  DaysItemRight,
  DaysItemActive,
  DaysItemLeftActive,
  DaysItemRightActive
} from '../styles';
import {
  req as sendDiscountingSettings,
  RequestData as SendDiscountingSettings,
  ResponseGuid
} from 'src/features/SCF/actions/sendDiscountingSettings';
import {
  req as getDiscountingSettings,
  ResponseDataType
} from 'src/features/SCF/actions/getDiscountingSettings';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { SetDiscountingSettingsStoreState } from 'src/features/SCF/reducers/setDiscountingSettings';
import './styles/style.css';
import { InputRange } from 'shared/ui/InputRange';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import {
  AbsoluteValue,
  LinkedToKS,
  ManualDiscountBlock
} from '../DiscountingInfo/styles';

interface StateToProps extends Partial<DiscountingSettings> {
  isSendingData: boolean;
  discountingSettings: ResponseDataType;
  status: REQUEST_STATUSES;
  responseGuidStatus: REQUEST_STATUSES;
  responseGuidError: ResponseError;
  error: ResponseError;
  responseGuid: ResponseGuid;
}

interface DispatchToProps {
  sendDiscountingSettings: (data: SendDiscountingSettings) => void;
  setDiscountingSettings: (data: SetDiscountingSettingsStoreState) => void;
  getDiscountingSettings: () => void;
}

interface State {
  enableDiscountingState: boolean;
  discountLimitPolicyState: number;
  manualMaxLimitSizeState: string;
  autoLimitPolicyState: number;
  fixedSubstractionSum: string;
  substractionPeriodInDays: number;
  discountRatePolicyState: number;
  autoRateSubstractionState: number;
  updateChecker: number;
  minimalDiscountState: number;
  payWeekdaysState: string;
  paydayOffsetState: number;
  suppliesDataSourcePolicyState: number;
  rateIsSurplusToKeyRate: boolean;
  discountRate: number;
  protocolsPolicy: boolean;
}

type Props = StateToProps & DispatchToProps;

class DiscountingEdit extends React.Component<Props, State> {
  initFetch = () => {
    const { getDiscountingSettings } = this.props;
    getDiscountingSettings();
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
  }

  constructor(props) {
    super(props);
    {
      this.state = {
        enableDiscountingState: this.props.discountingSettings
          .enableDiscounting,
        discountLimitPolicyState: this.props.discountingSettings
          .discountLimitPolicy,
        autoLimitPolicyState: this.props.discountingSettings.autoLimitPolicy,
        manualMaxLimitSizeState: this.props.discountingSettings
          .manualMaxLimitSize
          ? this.props.discountingSettings.manualMaxLimitSize.toString()
          : null,
        fixedSubstractionSum: this.props.discountingSettings
          .fixedSubstractionSum
          ? this.props.discountingSettings.fixedSubstractionSum.toString()
          : null,
        substractionPeriodInDays: this.props.discountingSettings
          .substractionPeriodInDays,
        discountRatePolicyState: this.props.discountingSettings
          .discountRatePolicy,
        autoRateSubstractionState: this.props.discountingSettings
          .autoRateSubstraction,
        minimalDiscountState: this.props.discountingSettings.minimalDiscount,
        suppliesDataSourcePolicyState: 1,
        payWeekdaysState: this.props.discountingSettings.paydays || '0000000',
        paydayOffsetState: this.props.discountingSettings.paydayOffset || 0,
        updateChecker: 0,
        rateIsSurplusToKeyRate: this.props.discountingSettings
          .rateIsSurplusToKeyRate,
        discountRate: this.props.discountingSettings.discountRate,
        protocolsPolicy: this.props.discountingSettings.protocolsPolicy
      };
    }
  }

  handleEnableDiscountingChangeTrue = () => {
    this.setState({ enableDiscountingState: true });
  };

  handleEnableDiscountingChangeFalse = () => {
    this.setState({ enableDiscountingState: false });
    // this.setState({ payWeekdaysState: '1111111' });
  };

  handleEnableProtocolsPolicyChangeTrue = () => {
    this.setState({ protocolsPolicy: true });
  };

  handleEnableProtocolsPolicyChangeFalse = () => {
    this.setState({ protocolsPolicy: false });
  };

  handleManualMaxLimitSizeStateChange = event => {
    this.setState({
      manualMaxLimitSizeState: event.target.value.replace(' ₽', '')
    });
  };

  handleFixedSubstractionChange = event => {
    this.setState({ fixedSubstractionSum: event.target.value });
  };

  handleSubstractionPeriodChange = event => {
    this.setState({ substractionPeriodInDays: event.target.value });
  };

  handleDiscountRateChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value } as any);
  };

  handleAutoRateSubstractionStateChange = event => {
    this.setState({ autoRateSubstractionState: event.target.value });
  };

  handleAutoRateSubstractionStateNullChange = () => {
    this.setState({ autoRateSubstractionState: 0 });
  };

  handleAutoRateSubstractionStateInitialChange = () => {
    this.setState({ autoRateSubstractionState: 0.01 });
  };

  handleDiscountLimitPolicyStateOneChange = () => {
    this.setState({ discountLimitPolicyState: 1 });
    this.setState({
      manualMaxLimitSizeState: this.props.discountingSettings.manualMaxLimitSize.toLocaleString(
        'ru'
      )
    });
    this.setState({ autoLimitPolicyState: undefined });
    this.setState({ fixedSubstractionSum: '0' });
    this.setState({ substractionPeriodInDays: 0 });
  };

  handleDiscountLimitPolicyStateTwoChange = () => {
    this.setState({ discountLimitPolicyState: 2 });
    this.setState({ manualMaxLimitSizeState: '0' });
  };

  handleAutoLimitPolicyStateOneChange = () => {
    this.setState({ autoLimitPolicyState: 1 });
    this.setState({
      fixedSubstractionSum: this.props.discountingSettings.fixedSubstractionSum.toLocaleString(
        'ru'
      )
    });
    this.setState({ substractionPeriodInDays: undefined });
  };

  handleAutoLimitPolicyStateTwoChange = () => {
    this.setState({ autoLimitPolicyState: 2 });
    this.setState({ fixedSubstractionSum: '0' });
    this.setState({
      substractionPeriodInDays: this.props.discountingSettings
        .substractionPeriodInDays
    });
  };

  handleDiscountRatePolicyStateOneChange = () => {
    this.setState({ discountRatePolicyState: 1 });
    this.setState({ discountRate: 0 });
    this.setState({ autoRateSubstractionState: 0 });
    this.setState({ minimalDiscountState: 0 });
  };

  handleDiscountRatePolicyStateTwoChange = () => {
    this.setState({ discountRatePolicyState: 2 });
    this.setState({ discountRate: undefined });
  };

  handleDiscountRateManual = () => {
    this.setState(prevState => ({
      ...prevState,
      rateIsSurplusToKeyRate: !prevState.rateIsSurplusToKeyRate
    }));
  };

  handleMinimalDiscountStateChange = event => {
    this.setState({ minimalDiscountState: event.target.value });
  };

  handlePaydayOffsetStateChange = event => {
    const value = event.target.value;

    if (!/^[0-9]*$/.test(value)) return;

    if (value >= 0) {
      this.setState({ paydayOffsetState: value });
    }
  };

  replaceCharacter = (str, index, replacement) => {
    return (
      str.slice(0, index) + replacement + str.slice(index + replacement.length)
    );
  };

  handlePayWeekdaysStateOneChange = (event, index: number) => {
    this.setState({
      payWeekdaysState: this.replaceCharacter(
        this.state.payWeekdaysState,
        index,
        '1'
      )
    });
  };

  handlePayWeekdaysStateZeroChange = (event, index: number) => {
    this.setState({
      payWeekdaysState: this.replaceCharacter(
        this.state.payWeekdaysState,
        index,
        '0'
      )
    });
  };

  handleSuppliesDataSourcePolicyStateOneChange = event => {
    this.setState({ suppliesDataSourcePolicyState: 1 });
  };

  delay = ms => new Promise(res => setTimeout(res, ms));

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setDiscountingSettings({
      [name]: value
    });
  };

  handleUpdateCheckerChange = async () => {
    await this.delay(400);
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  };

  onBackClick = () => {
    history.push(`/debtor/settings/discounting`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  onCloseIconClickSub = () => {
    const sign = document.getElementById('certificatesSubPopup');
    sign.style.display = 'none';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    history.push({
      pathname: `/debtor/settings/certificates/edit`
    });
  };

  onSignClick = () => {
    this.props.sendDiscountingSettings({
      enableDiscounting: this.state.enableDiscountingState
        ? this.state.enableDiscountingState
        : null,
      enableFactroing: this.props.discountingSettings.enableFactoring
        ? this.props.discountingSettings.enableFactoring
        : null,
      discountLimitPolicy: this.state.discountLimitPolicyState
        ? this.state.discountLimitPolicyState
        : null,
      manualMaxLimitSize: this.state.manualMaxLimitSizeState
        ? parseFloat(this.state.manualMaxLimitSizeState.replaceAll(' ', ''))
        : null,
      autoLimitPolicy: this.state.autoLimitPolicyState
        ? this.state.autoLimitPolicyState
        : null,
      fixedSubstractionSum: this.state.autoLimitPolicyState
        ? parseFloat(this.state.fixedSubstractionSum.replaceAll(' ', ''))
        : null,
      substractionInPeriodDays: this.state.substractionPeriodInDays
        ? this.state.substractionPeriodInDays
        : null,
      discountRatePolicy: this.state.discountRatePolicyState
        ? this.state.discountRatePolicyState
        : null,
      discountRate: this.state.discountRate ? this.state.discountRate : null,
      autoRateSubstraction: this.state.autoRateSubstractionState
        ? this.state.autoRateSubstractionState
        : null,
      factoringOfferPolicy: this.props.discountingSettings.factoringOfferPolicy
        ? this.props.discountingSettings.factoringOfferPolicy
        : null,
      minimalDiscount: this.state.minimalDiscountState
        ? this.state.minimalDiscountState
        : null,
      payWeekdays: this.state.enableDiscountingState
        ? this.state.payWeekdaysState
        : '1111111',
      paydayOffset: this.state.paydayOffsetState
        ? +this.state.paydayOffsetState
        : null,
      suppliesDataSourcePolicy: this.props.discountingSettings
        .suppliesDataSourcePolicy
        ? this.props.discountingSettings.suppliesDataSourcePolicy
        : null,
      verificationPolicy: this.props.discountingSettings.verificationPolicy
        ? this.props.discountingSettings.verificationPolicy
        : 5,
      epaMcPolicy: this.props.discountingSettings.epaMcPolicy
        ? this.props.discountingSettings.epaMcPolicy
        : null,
      factoringFormatPolicy: this.props.discountingSettings
        .factoringFormatPolicy
        ? this.props.discountingSettings.factoringFormatPolicy
        : null,
      factoringNotificationPolicy: this.props.discountingSettings
        .factoringNotificationPolicy
        ? this.props.discountingSettings.factoringNotificationPolicy
        : null,
      holidayPaydays: this.props.discountingSettings.holidayPaydays
        ? this.props.discountingSettings.holidayPaydays
        : null,
      rateIsSurplusToKeyRate: this.state.rateIsSurplusToKeyRate,
      protocolsPolicy: this.state.protocolsPolicy
        ? this.state.protocolsPolicy
        : null
    });
  };

  render() {
    const {
      status,
      discountingSettings,
      responseGuid,
      responseGuidStatus
    } = this.props;

    return (
      <CreateDiscountingSettingsPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        <ContractsContainer>
          <ContractsHeader>
            <h2>Политика дисконтирования</h2>
            <SignBtn
              style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}
            >
              <SignBtnText>Редактируется</SignBtnText>
            </SignBtn>
            <SignBtn onClick={() => this.onSignClick()}>
              <SignBtnText>Подписать ЭЦП</SignBtnText>
            </SignBtn>
            <BackBtn onClick={() => this.onBackClick()}>
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
          </ContractsHeader>
          <ContractsInfoArea style={{ marginTop: '40px' }}>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Дисконтирование (ранняя оплата):
              </ContractsInfoItemTitle>
              {this.state.enableDiscountingState == true ? (
                <TypeContainer>
                  <TypeNoInactive
                    onClick={this.handleEnableDiscountingChangeFalse}
                  >
                    <p>ВЫКЛ</p>
                  </TypeNoInactive>
                  <TypeYesActive
                    onClick={this.handleEnableDiscountingChangeTrue}
                  >
                    <p>ВКЛ</p>
                  </TypeYesActive>
                </TypeContainer>
              ) : (
                <TypeContainer>
                  <TypeNoActive
                    onClick={this.handleEnableDiscountingChangeFalse}
                  >
                    <p>ВЫКЛ</p>
                  </TypeNoActive>
                  <TypeYesInactive
                    onClick={this.handleEnableDiscountingChangeTrue}
                  >
                    <p>ВКЛ</p>
                  </TypeYesInactive>
                </TypeContainer>
              )}
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
          {this.state.enableDiscountingState == true ? (
            <ContractsInfoArea>
              <h2>Установление максимального размера лимита:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                {this.state.discountLimitPolicyState == 1 ? (
                  <SelectContainerActive
                    onClick={this.handleDiscountLimitPolicyStateOneChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleDiscountLimitPolicyStateOneChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p style={{ marginRight: '10px' }}>Вручную</p>
                {this.state.discountLimitPolicyState == 1 ? (
                  <InputRange
                    className="contract-input-name"
                    value={this.state.manualMaxLimitSizeState}
                    name="manualMaxLimitSize"
                    placeholder="Введите сумму"
                    min={0}
                    max={10000000000000}
                    step={0}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    required={true}
                    disableSlider={true}
                    onChange={this.handleManualMaxLimitSizeStateChange}
                  />
                ) : (
                  <p />
                )}

                {/* {this.state.discountLimitPolicyState == 1 ?
                    <p style={{ marginLeft: "10px" }}>руб.</p> :
                    <p></p>
                  } */}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                {this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleDiscountLimitPolicyStateTwoChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleDiscountLimitPolicyStateTwoChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Остаток на счетах</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer
                style={{ paddingTop: '10px', marginLeft: '50px' }}
              >
                {this.state.autoLimitPolicyState == 1 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleAutoLimitPolicyStateOneChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : this.state.autoLimitPolicyState == undefined &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateOneChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : this.state.autoLimitPolicyState == 0 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateOneChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : this.state.autoLimitPolicyState == 2 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateOneChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : (
                  <SelectContainerInactive>
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Уменьшать на фиксированную сумму</p>
                {this.state.autoLimitPolicyState == 1 ? (
                  <InputRange
                    className="contract-input-name"
                    value={this.state.fixedSubstractionSum}
                    name="fixedSubstractionSum"
                    placeholder="Введите сумму"
                    min={0}
                    max={10000000000000}
                    step={0}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    required={true}
                    disableSlider={true}
                    onChange={this.handleFixedSubstractionChange}
                  />
                ) : (
                  <p />
                )}
                {/* {this.state.autoLimitPolicyState == 1 ?
                    <p style={{ marginLeft: "10px" }}>руб.</p> :
                    <p></p>
                  } */}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer
                style={{ paddingTop: '10px', marginLeft: '50px' }}
              >
                {this.state.autoLimitPolicyState == 2 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleAutoLimitPolicyStateTwoChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : this.state.autoLimitPolicyState == undefined &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateTwoChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : this.state.autoLimitPolicyState == 0 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateTwoChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : this.state.autoLimitPolicyState == 1 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <SelectContainerInactive
                    onClick={this.handleAutoLimitPolicyStateTwoChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : (
                  <SelectContainerInactive>
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Уменьшать на прогноз оплат за</p>
                {this.state.autoLimitPolicyState == 2 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <input
                    className="contract-input input-short"
                    type="number"
                    min={0}
                    onKeyPress={e => {
                      if (e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    value={this.state.substractionPeriodInDays}
                    name="substractionInPeriodDays"
                    onChange={this.handleSubstractionPeriodChange}
                  />
                ) : (
                  <p />
                )}
                {this.state.autoLimitPolicyState == 2 &&
                this.state.discountLimitPolicyState == 2 ? (
                  <p style={{ marginLeft: '10px' }}>дн.</p>
                ) : (
                  <p />
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer
                style={{ marginTop: '20px', marginLeft: '30px' }}
              >
                <ContractsInfoItemTitle>Итоговый лимит:</ContractsInfoItemTitle>
                {discountingSettings.manualMaxLimitSize > 0 ? (
                  <p style={{ width: '150px', textAlign: 'end' }}>
                    {discountingSettings.manualMaxLimitSize.toLocaleString(
                      'ru'
                    )}
                  </p>
                ) : (
                  <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                )}

                <p>руб.</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                <ContractsInfoItemTitle>Использовано:</ContractsInfoItemTitle>
                {discountingSettings.limitUsage > 0 ? (
                  <p style={{ width: '150px', textAlign: 'end' }}>
                    {discountingSettings.limitUsage.toLocaleString('ru')}
                  </p>
                ) : (
                  <p style={{ width: '150px', textAlign: 'end' }}>{0}</p>
                )}
                <p>руб.</p>
                {discountingSettings.limitUsage > 0 ? (
                  <NullBtn onClick={() => SCFApi.postReleaseLimit()}>
                    <NullBtnHandler
                      onClick={() => this.handleUpdateCheckerChange()}
                    >
                      <NullBtnText>Обнулить</NullBtnText>
                    </NullBtnHandler>
                  </NullBtn>
                ) : (
                  <NullBtn
                    style={{
                      backgroundColor: '#9FA6B2',
                      cursor: 'not-allowed'
                    }}
                  >
                    <NullBtnText>Обнулить</NullBtnText>
                  </NullBtn>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '30px' }}>
                <ContractsInfoItemTitle>Остаток лимита:</ContractsInfoItemTitle>
                <p style={{ width: '150px', textAlign: 'end' }}>
                  {(
                    discountingSettings.manualMaxLimitSize -
                    discountingSettings.limitUsage
                  ).toLocaleString('ru')}
                </p>
                <p>руб.</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {this.state.enableDiscountingState == true ? (
            <ContractsInfoArea>
              <h2>Размер дисконта:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                {this.state.discountRatePolicyState == 1 ? (
                  <ManualDiscountBlock>
                    <div>
                      <SelectContainerActive
                        onClick={this.handleDiscountRatePolicyStateOneChange}
                      >
                        <SelectActive />
                      </SelectContainerActive>
                      <p>Вручную</p>
                    </div>

                    <div>
                      <AbsoluteValue>
                        {this.state.rateIsSurplusToKeyRate === false ? (
                          <>
                            <SelectContainerActive
                              onClick={this.handleDiscountRateManual}
                            >
                              <SelectActive />
                            </SelectContainerActive>
                            <p>Абсолютное значение</p>
                            <input
                              className="contract-input input-short"
                              type="number"
                              min={0.0}
                              onKeyPress={e => {
                                if (e.key === 'e' || e.key === '-') {
                                  e.preventDefault();
                                }
                              }}
                              value={this.state.discountRate}
                              name="discountRate"
                              onChange={this.handleDiscountRateChange}
                            />
                            <p style={{ marginLeft: '10px' }}>% годовых</p>
                          </>
                        ) : (
                          <>
                            <SelectContainerInactive
                              onClick={this.handleDiscountRateManual}
                            >
                              <SelectInactive />
                            </SelectContainerInactive>
                            <p>Абсолютное значение</p>
                          </>
                        )}
                      </AbsoluteValue>

                      <LinkedToKS>
                        {this.state.rateIsSurplusToKeyRate === true ? (
                          <>
                            <SelectContainerActive
                              onClick={this.handleDiscountRateManual}
                            >
                              <SelectActive />
                            </SelectContainerActive>
                            <p>Привязаное к КС</p>
                            <div>
                              <p>Ключ +</p>
                              <input
                                className="contract-input input-short"
                                type="number"
                                min={0.0}
                                onKeyPress={e => {
                                  if (e.key === 'e' || e.key === '-') {
                                    e.preventDefault();
                                  }
                                }}
                                value={this.state.discountRate}
                                name="discountRate"
                                onChange={this.handleDiscountRateChange}
                              />
                              <p style={{ marginLeft: '10px' }}>% годовых</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <SelectContainerInactive
                              onClick={this.handleDiscountRateManual}
                            >
                              <SelectInactive />
                            </SelectContainerInactive>
                            <p>Привязаное к КС</p>
                          </>
                        )}
                      </LinkedToKS>
                    </div>
                  </ManualDiscountBlock>
                ) : (
                  <>
                    <SelectContainerInactive
                      onClick={this.handleDiscountRatePolicyStateOneChange}
                    >
                      <SelectInactive />
                    </SelectContainerInactive>
                    <p>Вручную</p>
                  </>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ paddingTop: '10px' }}>
                {this.state.discountRatePolicyState == 2 ? (
                  <>
                    <SelectContainerActive
                      onClick={this.handleDiscountRatePolicyStateTwoChange}
                    >
                      <SelectActive />
                    </SelectContainerActive>
                    <p>Эквивалентен лучшему предложению, но не ниже</p>
                    <input
                      className="contract-input input-short"
                      type="number"
                      min={0.0}
                      onKeyPress={e => {
                        if (e.key === 'e' || e.key === '-') {
                          e.preventDefault();
                        }
                      }}
                      value={this.state.minimalDiscountState}
                      name="minimalDiscount"
                      onChange={this.handleMinimalDiscountStateChange}
                    />
                    <p style={{ marginLeft: '10px' }}>% годовых</p>
                  </>
                ) : (
                  <>
                    <SelectContainerInactive
                      onClick={this.handleDiscountRatePolicyStateTwoChange}
                    >
                      <SelectInactive />
                    </SelectContainerInactive>
                    <p>Эквивалентен лучшему предложению, но не ниже</p>
                  </>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer
                style={{ paddingTop: '10px', marginLeft: '50px' }}
              >
                {this.state.discountRatePolicyState == 2 &&
                this.state.autoRateSubstractionState > 0 ? (
                  <SelectContainerActive
                    onClick={this.handleAutoRateSubstractionStateNullChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : this.state.discountRatePolicyState == 1 ? (
                  <SelectContainerInactive>
                    <SelectInactive />
                  </SelectContainerInactive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleAutoRateSubstractionStateInitialChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Уменьшать на фиксированную долю</p>
                {this.state.discountRatePolicyState == 2 ? (
                  <input
                    className="contract-input input-short"
                    type="number"
                    min={0.0}
                    onKeyPress={e => {
                      if (e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    value={this.state.autoRateSubstractionState}
                    name="autoRateSubstraction"
                    onChange={this.handleAutoRateSubstractionStateChange}
                  />
                ) : (
                  ''
                )}
                {this.state.discountRatePolicyState == 2 ? (
                  <p style={{ marginLeft: '10px' }}>% годовых</p>
                ) : (
                  ''
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {this.state.enableDiscountingState == true ? (
            <ContractsInfoArea>
              <h2>Платежные дни</h2>
              <DaysContainer>
                {this.state.payWeekdaysState.charAt(0) == '1' ? (
                  <DaysItemLeftActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 0)
                    }
                  >
                    <p>Пн</p>
                  </DaysItemLeftActive>
                ) : (
                  <DaysItemLeft
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 0)
                    }
                  >
                    <p>Пн</p>
                  </DaysItemLeft>
                )}
                {this.state.payWeekdaysState.charAt(1) == '1' ? (
                  <DaysItemActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 1)
                    }
                  >
                    <p>Вт</p>
                  </DaysItemActive>
                ) : (
                  <DaysItem
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 1)
                    }
                  >
                    <p>Вт</p>
                  </DaysItem>
                )}
                {this.state.payWeekdaysState.charAt(2) == '1' ? (
                  <DaysItemActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 2)
                    }
                  >
                    <p>Ср</p>
                  </DaysItemActive>
                ) : (
                  <DaysItem
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 2)
                    }
                  >
                    <p>Ср</p>
                  </DaysItem>
                )}
                {this.state.payWeekdaysState.charAt(3) == '1' ? (
                  <DaysItemActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 3)
                    }
                  >
                    <p>Чт</p>
                  </DaysItemActive>
                ) : (
                  <DaysItem
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 3)
                    }
                  >
                    <p>Чт</p>
                  </DaysItem>
                )}
                {this.state.payWeekdaysState.charAt(4) == '1' ? (
                  <DaysItemActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 4)
                    }
                  >
                    <p>Пт</p>
                  </DaysItemActive>
                ) : (
                  <DaysItem
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 4)
                    }
                  >
                    <p>Пт</p>
                  </DaysItem>
                )}
                {this.state.payWeekdaysState.charAt(5) == '1' ? (
                  <DaysItemActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 5)
                    }
                  >
                    <p>Сб</p>
                  </DaysItemActive>
                ) : (
                  <DaysItem
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 5)
                    }
                  >
                    <p>Сб</p>
                  </DaysItem>
                )}
                {this.state.payWeekdaysState.charAt(6) == '1' ? (
                  <DaysItemRightActive
                    onClick={() =>
                      this.handlePayWeekdaysStateZeroChange(event, 6)
                    }
                  >
                    <p>Вс</p>
                  </DaysItemRightActive>
                ) : (
                  <DaysItemRight
                    onClick={() =>
                      this.handlePayWeekdaysStateOneChange(event, 6)
                    }
                  >
                    <p>Вс</p>
                  </DaysItemRight>
                )}
              </DaysContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}

          <ContractsInfoArea>
            <ContractsInfoItemContainer>
              <p>Дата оплаты не раньше, чем через</p>
              <input
                className="contract-input input-short"
                value={this.state.paydayOffsetState}
                onChange={this.handlePaydayOffsetStateChange}
              />
              <p style={{ marginLeft: 10 }}>дн.</p>
            </ContractsInfoItemContainer>
          </ContractsInfoArea>

          <ContractsInfoArea style={{ marginTop: '40px' }}>
            <h2 style={{ marginBottom: '20px' }}>Подписание документов:</h2>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>
                Формировать протоколы:
              </ContractsInfoItemTitle>
              {this.state.protocolsPolicy == true ? (
                <TypeContainer>
                  <TypeNoInactive
                    onClick={this.handleEnableProtocolsPolicyChangeFalse}
                  >
                    <p>ВЫКЛ</p>
                  </TypeNoInactive>
                  <TypeYesActive
                    onClick={this.handleEnableProtocolsPolicyChangeTrue}
                  >
                    <p>ВКЛ</p>
                  </TypeYesActive>
                </TypeContainer>
              ) : (
                <TypeContainer>
                  <TypeNoActive
                    onClick={this.handleEnableProtocolsPolicyChangeFalse}
                  >
                    <p>ВЫКЛ</p>
                  </TypeNoActive>
                  <TypeYesInactive
                    onClick={this.handleEnableProtocolsPolicyChangeTrue}
                  >
                    <p>ВКЛ</p>
                  </TypeYesInactive>
                </TypeContainer>
              )}
            </ContractsInfoItemContainer>
          </ContractsInfoArea>

          {this.state.enableDiscountingState == true ? (
            <ContractsInfoArea style={{ marginTop: '30px' }}>
              <h2>Источник данных о поставках:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                {this.state.suppliesDataSourcePolicyState == 1 ? (
                  <SelectContainerActive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleFactoringOfferPolicyOneStateChange}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleSuppliesDataSourcePolicyStateOneChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Загрузка реестров поставки</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.suppliesDataSourcePolicyState == 2 ? (
                  <SelectContainerActive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleFactoringOfferPolicyOneStateChange(2)}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleSuppliesDataSourcePolicyStateChange(2)}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Интеграция баз данных</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.suppliesDataSourcePolicyState == 3 ? (
                  <SelectContainerActive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleFactoringOfferPolicyOneStateChange(2)}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    style={{ cursor: 'not-allowed' }}
                    // onClick={this.handleSuppliesDataSourcePolicyStateChange(2)}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Ручной ввод поставщиком</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {responseGuidStatus == REQUEST_STATUSES.GOT && (
            <CertificatesPopupDisplay id="certificatesPopup">
              <PopupExit>
                <p onClick={this.onCloseIconClick}>X</p>
              </PopupExit>
              <h1>Список сертификатов в системе</h1>
              <CertificatesArea>
                <CertificatesListPopup
                  guid={responseGuid.guid}
                  request={`{"enableDiscounting": "${
                    this.state.enableDiscountingState
                      ? this.state.enableDiscountingState
                      : null
                  }",
                  "enableFactroing": "${
                    this.props.discountingSettings.enableFactoring
                      ? this.props.discountingSettings.enableFactoring
                      : null
                  }",
                  "discountLimitPolicy": "${
                    this.state.discountLimitPolicyState
                      ? this.state.discountLimitPolicyState
                      : null
                  }",
                  "manualMaxLimitSize": "${
                    this.state.manualMaxLimitSizeState
                      ? parseFloat(
                          this.state.manualMaxLimitSizeState.replaceAll(' ', '')
                        )
                      : null
                  }",
                  "autoLimitPolicy": "${
                    this.state.autoLimitPolicyState
                      ? this.state.autoLimitPolicyState
                      : null
                  }",
                  "fixedSubstractionSum": "${
                    this.state.fixedSubstractionSum
                      ? parseFloat(
                          this.state.fixedSubstractionSum.replaceAll(' ', '')
                        )
                      : null
                  }",
                  "substractionInPeriodDays": "${
                    this.state.substractionPeriodInDays
                      ? this.state.substractionPeriodInDays
                      : null
                  }",
                  "discountRatePolicy": "${
                    this.state.discountRatePolicyState
                      ? this.state.discountRatePolicyState
                      : null
                  }",
                  "discountRate": "${
                    this.state.discountRate ? this.state.discountRate : null
                  }",
                  "autoRateSubstraction": "${
                    this.state.autoRateSubstractionState
                      ? this.state.autoRateSubstractionState
                      : null
                  }",
                  "factoringOfferPolicy": "${
                    this.props.discountingSettings.factoringOfferPolicy
                      ? this.props.discountingSettings.factoringOfferPolicy
                      : null
                  }",
                  "minimalDiscount": "${
                    this.state.minimalDiscountState
                      ? this.state.minimalDiscountState
                      : null
                  }",
                  "payWeekdays": "${
                    this.state.enableDiscountingState
                      ? this.state.payWeekdaysState
                      : '1111111'
                  }",
                  "suppliesDataSourcePolicy": "${
                    this.state.suppliesDataSourcePolicyState
                      ? this.state.suppliesDataSourcePolicyState
                      : null
                  }",
                  "verificationPolicy": "${
                    this.props.discountingSettings.verificationPolicy
                      ? this.props.discountingSettings.verificationPolicy
                      : 5
                  }",
                  "epaMcPolicy": "${
                    this.props.discountingSettings.epaMcPolicy
                      ? this.props.discountingSettings.epaMcPolicy
                      : null
                  }",
                  "factoringFormatPolicy": "${
                    this.props.discountingSettings.factoringFormatPolicy
                      ? this.props.discountingSettings.factoringFormatPolicy
                      : null
                  }",
                  "factoringNotificationPolicy": "${
                    this.props.discountingSettings.factoringNotificationPolicy
                      ? this.props.discountingSettings
                          .factoringNotificationPolicy
                      : null
                  }",
                  "holidayPaydays": "${
                    this.props.discountingSettings.holidayPaydays
                      ? this.props.discountingSettings.holidayPaydays
                      : null
                  }"
                }`}
                />
              </CertificatesArea>
              <NudgeArea>
                <p>Нет нужного сертификата? </p>
                <Nudge onClick={this.onNudgeClick}>
                  Перейдите в настройки добаления
                </Nudge>
              </NudgeArea>
            </CertificatesPopupDisplay>
          )}

          <CertificatesPopupError id="certificatesPopupError">
            <PopupExitError>
              <p onClick={this.onCloseErrorIconClick}>X</p>
            </PopupExitError>
            <h1>
              Невозможно подписать выбранным сертификатом
              <br />
              (не найден на устройстве)
            </h1>
          </CertificatesPopupError>
        </ContractsContainer>
      </CreateDiscountingSettingsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  isSendingData:
    SCF.sendDiscountingSettings.status === REQUEST_STATUSES.REQUEST,
  discountingSettings: SCF.getDiscountingSettings.data,
  status: SCF.getDiscountingSettings.status,
  error: SCF.getDiscountingSettings.error,
  responseGuid: SCF.sendDiscountingSettings.data,
  responseGuidStatus: SCF.sendDiscountingSettings.status,
  responseGuidError: SCF.sendDiscountingSettings.error,
  ...SCF.setDiscountingSettings
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendDiscountingSettings,
      setDiscountingSettings,
      getDiscountingSettings
    },
    dispatch
  );

const DiscountingEditConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(DiscountingEdit);

export { DiscountingEditConnect as DiscountingEdit };
