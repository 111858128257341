import * as React from 'react';
import { RadioStyled, RadioLabel, RadioChildren } from './styles';

interface Props {
  label?: string;
  name: string;
  value: string;
  keyValue?: string;
  checked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  children?: any;
}

export const Radio: React.FC<Props> = ({
  label,
  name,
  value,
  keyValue,
  checked,
  onChange,
  children
}: Props) => (
  <React.Fragment>
    <RadioLabel label={label}>
      <RadioStyled
        name={name}
        value={value}
        aria-labelledby={name}
        checked={keyValue === value}
        onChange={onChange}
      />
    </RadioLabel>

    {!!children &&
      keyValue === value && <RadioChildren>{children}</RadioChildren>}
  </React.Fragment>
);
