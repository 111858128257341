import { LeadRead } from 'src/features/Leads/actions/setLeadFormData';
import { GET_USER_LEADS } from '../reducers/getUserLeads';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: LeadRead[];
}

export const req = (id: string) => ({
  type: GET_USER_LEADS.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_USER_LEADS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_USER_LEADS.ERR,
  error
});
