import { SEND_SUPPLY_CONTRACT } from '../reducers/sendSupplyContract';
import { SupplyContractWrite } from './setSupplyContract';

export interface RequestData extends Partial<SupplyContractWrite> {}

export const req = (data: RequestData) => ({
  type: SEND_SUPPLY_CONTRACT.REQ,
  data
});

export const got = () => ({
  type: SEND_SUPPLY_CONTRACT.GOT
});

export const err = (error: {}) => ({
  type: SEND_SUPPLY_CONTRACT.ERR,
  error
});
