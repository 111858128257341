export interface PersonsTypes {
  id: number;
  personInn: string;
  companyInn: string;
  ownershipShareDeJure: number;
  ownershipShareDeFacto: number;
  capitalShare: number;
  sourceUserId: null;
  ownershipShareDeJureFull: number;
  beneficiaryType: number;
  companyShortName: string;
  companyFullName: string;
  revenue: number;
  inAction: number;
  netIncome: number;
  financialsYear: number;
  headCompanyInn: string;
}

export interface ManagersTypes {
  id: number;
  personInn: string;
  companyInn: string;
  position: string;
  positionType: string;
  positionCode: number;
  positionStartDate: string;
  companyShortName: string;
  companyFullName: string;
  revenue: number;
  inAction: number;
  netIncome: number;
  financialsYear: number;
  capital: number;
}

export interface PersonType {
  id: string;
  personINN: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: null;
  placeOfBirth: null;
  sex: number;
  familyStatus: null;
  citizenship: null;
  snils: null;
  passportSerialNumber: null;
  passportIssuerName: null;
  passportIssuerCode: null;
  passportIssuanceDate: null;
  apartment: null;
  area: null;
  city: string;
  corpus: null;
  house: null;
  index: null;
  kladr: null;
  region: null;
  settlement: null;
  street: string;
  fullAddress: string;
  phoneContact: null;
  phoneStationary: null;
  email: string;
  personDataSource: string;
  personDataSourceUser: null;
}

export interface PersonsListType {
  id: string;
  personINN: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: null;
  placeOfBirth: null;
  sex: number;
  familyStatus: null;
  citizenship: null;
  snils: null;
  passportSerialNumber: null;
  passportIssuerName: null;
  passportIssuerCode: null;
  passportIssuanceDate: null;
  apartment: null;
  area: null;
  city: null;
  corpus: null;
  house: null;
  index: null;
  kladr: null;
  region: null;
  settlement: null;
  street: string;
  fullAddress: string;
  phoneContact: null;
  phoneStationary: null;
  email: string;
  personDataSource: string;
  personDataSourceUser: null;
}

export enum PERSONS_TABS {
  DEFAULT = 'Связи с компаниями',
  PASSPORT = 'Паспортные данные'
}

export enum SEX_TYPE {
  MAN = 'Мужчина',
  WOMAN = 'Женщина'
}
