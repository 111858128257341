import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/postAddBuyer';

import { POST_ADD_BUYER } from 'src/features/SCF/reducers/postAddBuyer';

import SCFApi from 'src/features/SCF/api';

function* postAddBuyer(action: { type: POST_ADD_BUYER; inn: string }) {
  try {
    yield call(SCFApi.postAddBuyer, action.inn);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddBuyerSaga() {
  yield takeLatest(POST_ADD_BUYER.REQ, postAddBuyer);
}
