import { FactoringContract } from '../actions/setFactoringContract';

export interface SetFactoringContractStoreState extends Partial<FactoringContract> { }

export enum SET_FACTORING_CONTRACT {
  SET = 'SET_FACTORING_CONTRACT_SET',
  RESET = 'SET_FACTORING_CONTRACT_RESET',
}

export const initialState: SetFactoringContractStoreState = {
  id: '',
  supplierInn: '',
  supplierName: '',
  debtorInn: '',
  debtorName: '',
  contractName: '',
  contractNumber: '',
  contractStartDate: '',
  contractEndDate: null,
  isPerpetual: false,
  cessionMinimalShare: null,
  cessionMaximalShare: null,
  financeMinimalShare: null,
  financeMaximalShare: null,
  isRegressed: undefined,
  isNotHidden: undefined,
  gracePeriod: null,
  moveGracePeriodToBusinessDay: undefined,
  regressPeriod: null,
  moveRegressPeriodToBusinessDay: undefined,
  contraceFileIdentifyer: undefined,
  contraceFileName: '',
  supplierSignatureIdentifier: undefined,
  supplierSignatureFileName: '',
  debtorSignatureIdentifier: undefined,
  debtorSignatureFileName: '',
  factorSignatureIdentifier: undefined,
  factorSignatureFileName: '',
};

const setFactoringContract = (
  state: SetFactoringContractStoreState = initialState,
  action: {
    type: string;
    data: FactoringContract;
  }
): SetFactoringContractStoreState => {
  switch (action.type) {
    case SET_FACTORING_CONTRACT.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_FACTORING_CONTRACT.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setFactoringContract;
