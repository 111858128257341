import * as React from 'react';

import { BoxStyled, ErrorIcon } from 'shared/styled';

export const UnexpectedError: React.FC = () => (
  <React.Fragment>
    <h1>Произошла непредвиденная ошибка</h1>
    <ErrorIcon />
    <BoxStyled>
      <p>
        Мы уже работаем над исправлением ошибки.
        <br />
        Повторите действие чуть позже.
      </p>
    </BoxStyled>
  </React.Fragment>
);
