import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import { Label } from 'shared/ui/Label';

import { rr, red, darkGray, lightGray, gray, navyBlue } from 'shared/styled';

export const TextareaContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const TextareaLabel = styled(Label)`
  margin-bottom: 10px;
`;

export const TextareaStyled = styled(TextareaAutosize)`
  width: inherit;
  min-height: 60px;

  padding: 10px;
  resize: none;
  outline: none;

  border: 1px solid ${gray};
  border-radius: 6px;
  background-color: #fff;

  font-family: ${rr};
  font-size: 1.2rem;
  line-height: 1.6;
  color: ${darkGray};

  ${[
    '::-webkit-input-placeholder',
    '::-moz-placeholder',
    ':-ms-input-placeholder',
    ':-moz-placeholder'
  ].map(
    selector =>
      css`
        text-overflow: ellipsis;

        &:not([disabled])${selector} {
          color: ${gray};
        }

        &:not([disabled]):focus${selector} {
          color: ${navyBlue};
        }

        &[disabled]${selector} {
          color: ${lightGray};
        }
      `
  )};
`;
