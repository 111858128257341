import styled from 'styled-components';

export const CardContactCompany = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 10px;
  background-color: #fff;

  transition: box-shadow 0.2s;

  &:hover {
    background: repeating-linear-gradient(
      -45deg,
      #f1f8ff -5%,
      #fff 25%,
      #f1f8fe 0%
    );

    @supports not (background: repeating-linear-gradient) {
      background-color: #fff;
    }
  }
`;
