import * as React from 'react';
import { PropertySubTypes } from 'shared/constants';
import { PropertyRead } from 'src/features/Properties/types/Property';
import { LighterBlueContainer } from 'shared/styled/layouts';
import { Button } from 'shared/ui/Button';

interface PreviewPropertyProps {
  property: Partial<PropertyRead>;
  onClick?: () => void;
}

const PreviewProperty: React.FC<PreviewPropertyProps> = ({
  property,
  onClick
}) => (
  <LighterBlueContainer>
    <div>Сведения об объекте недвижимости</div>
    <div>{PropertySubTypes[property.subType]}</div>
    <div>{property.region}</div>
    <div>{property.address}</div>
    {onClick && <Button label="Редактировать" onClick={onClick} />}
  </LighterBlueContainer>
);

export default PreviewProperty;
