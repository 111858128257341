import { GET_PERSONS } from '../reducers/getPersons';
import { PersonsTypes } from 'src/features/Persons/types';

export const req = (inn: string) => ({
  type: GET_PERSONS.REQ,
  inn
});

export const got = (data: PersonsTypes[]) => ({
  type: GET_PERSONS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PERSONS.ERR,
  error
});
