import { GET_SUPPLY_CONTRACTS_LIST } from '../reducers/getSupplyContractsList';
import { SupplyListTypes } from '../types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: SupplyListTypes[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  SupplierInnToFilterBy?: string;
  DebtorInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_SUPPLY_CONTRACTS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_SUPPLY_CONTRACTS_LIST.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_SUPPLY_CONTRACTS_LIST.ERR,
  err
});
