import { PATCH_EDIT_PERSON } from '../reducers/patchEditPerson';

export interface RequestData {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  sex: number | string;
  passportSerialNumber: string;
  passportIssuerName: string;
  passportIssuerCode: string;
  passportIssuanceDate: string;
  fullAddress: string;
}

export const req = (inn: string, data: RequestData) => ({
  type: PATCH_EDIT_PERSON.REQ,
  data,
  inn
});

export const got = () => ({
  type: PATCH_EDIT_PERSON.GOT
});

export const err = (error: {}) => ({
  type: PATCH_EDIT_PERSON.ERR,
  error
});
