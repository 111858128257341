import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/getCompanyClient';

import { GET_COMPANY_CLIENT } from 'entities/Cabinet/Client/model/reducers/getCompanyClient';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* getCompanyClient(action: {
  type: GET_COMPANY_CLIENT;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(ClientRoleApi.getCompanyClient, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyClientSaga(): Generator {
  yield takeLatest(GET_COMPANY_CLIENT.REQ, getCompanyClient);
}
