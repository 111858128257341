import styled from 'styled-components';
import { lighterBlue, rr, blue, lightGray, navyBlue, red } from 'shared/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';

export const ContractsPageStyled = styled.section`
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 130px;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InnFilterArea = styled.div`
  width: auto;
  margin: 50px 0 30px 0;
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const SupplierInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  div:last-child {
    font-family: ${rr};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: stretch;
  column-gap: 5px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  color: ${({ status }) => (status === red ? 'white' : navyBlue)};
  background-color: ${({ status }) => status};
`;

export const DownloadContractContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  a {
    color: ${blue};
  }
`;

export const TDDocument = styled.td`
  padding-left: 20px !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: visible !important;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  height: 19px;

  img{
    height: 19px !important;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    right: 20px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const TableTRStyled = styled(TableRowStyled)`
  height: 85px;

  td {
    color: ${({ decline }) => decline && 'red'};
  }
`;

export const Postponement = styled.div`
  button {
    width: fit-content;
    cursor: default;
    background-color: red;
    height: 40px;
    padding: 13px;

    &:not([disabled]):hover {
      background-color: red;
    }

    &:hover {
      background-color: red;
    }
  }
`;

export const PostponementItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
`;

export const PaymentDeferment = styled.div`
  font-size: 12px;
`;

export const TextRed = styled.div`
  color: red;
  text-align: center;
`;

export const FormAgreement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
