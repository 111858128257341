import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { BorrowerCompany } from 'src/features/Application/types';
import { SagaRequestDataType as DeleteBorrowerCompanyDocumentRequestDataType } from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';
import { req as deleteBorrowerCompanyDocument } from 'BorrowerCompanies/actions/deleteBorrowerCompanyDocument';
import { req as uploadBorrowerCompanyDocuments } from 'BorrowerCompanies/actions/uploadBorrowerCompanyDocuments';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import {
  FileRow,
  FileRowLabelContainer,
  FileRowPaddingBlock
} from '../ContractFinancingFileUploadBlocks/styles';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import ExampleDocument from '../ContractFinancingFileUploadBlocks/ExampleDocument/ExampleDocument';
import { BigLabelWithTopMargin } from 'src/shared/ui/InputSingleFile/styles';

interface FileInput {
  filename?: string;
  metaInfo?: string;
  label?: string;
  required?: boolean;
  padded?: boolean;
}

interface ExampleDocumentProps {
  name: string;
  link: string;
}

interface Block {
  label?: string;
  subLabel?: string;
  required?: boolean;
  fileInputs?: FileInput[];
  exampleDocument?: ExampleDocumentProps;
}

interface OwnProps {
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  blocks: Block[];
}

interface StateToProps {
  documentsUploadStatus: REQUEST_STATUSES;
  documentsUploadData: UploadDocumentsRequestDataType;
}

interface DispatchToProps {
  uploadBorrowerCompanyDocuments: (
    data: UploadDocumentsRequestDataType
  ) => void;
  deleteBorrowerCompanyDocument: (
    data: DeleteBorrowerCompanyDocumentRequestDataType
  ) => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

const FileUploadRow: React.FC<Props> = ({
  borrowerCompany,
  errors,
  blocks,
  deleteBorrowerCompanyDocument,
  documentsUploadData,
  documentsUploadStatus,
  uploadBorrowerCompanyDocuments
}) => {
  const onFileUpload = (file: File) => {
    uploadBorrowerCompanyDocuments({
      id: borrowerCompany.id,
      files: [file]
    });
  };

  const onFileRemove = (documentData: DeleteDocumentRequestDataType) => {
    deleteBorrowerCompanyDocument({
      id: documentData.id,
      companyId: borrowerCompany.id
    });
  };

  const findErrorByMetaInfo = metaInfo => {
    return errors.documents &&
      !borrowerCompany.documents.find(doc => doc.metaInfo === metaInfo)
      ? errors.documents
      : '';
  };

  const findDocumentByMetaInfo = metaInfo => {
    return borrowerCompany.documents.find(doc => doc.metaInfo === metaInfo);
  };

  const getLabel = (block: Block) => {
    return (
      <span>
        {block.label}
        <small>{block.subLabel}</small>
      </span>
    );
  };

  if (blocks.length === 0) return;

  return (
    <React.Fragment>
      {blocks.map(
        (block, index) =>
          block && (
            <FileRow key={index}>
              <FileRowLabelContainer>
                <BigLabelWithTopMargin label={getLabel(block)} />
                {block.exampleDocument && (
                  <ExampleDocument
                    name={block.exampleDocument.name}
                    link={block.exampleDocument.link}
                    size={39}
                  />
                )}
              </FileRowLabelContainer>
              {block.fileInputs.map((fileInput, index2) => {
                return (
                  fileInput && (
                    <React.Fragment key={index2}>
                      {fileInput.padded && <FileRowPaddingBlock />}
                      {fileInput.metaInfo && (
                        <InputSingleFile
                          label={fileInput.label}
                          file={findDocumentByMetaInfo(fileInput.metaInfo)}
                          error={findErrorByMetaInfo(fileInput.metaInfo)}
                          uploadStatus={
                            documentsUploadStatus ===
                              REQUEST_STATUSES.REQUEST &&
                            documentsUploadData.files.some(
                              item => item.metaInfo === fileInput.metaInfo
                            )
                              ? REQUEST_STATUSES.REQUEST
                              : REQUEST_STATUSES.GOT
                          }
                          dropAreaWidth={
                            block.fileInputs.length > 1 || fileInput.padded
                              ? '250px'
                              : undefined
                          }
                          metaInfo={fileInput.metaInfo}
                          required={fileInput.required}
                          renameFileTo={fileInput.filename}
                          onFileUpload={onFileUpload}
                          onFileRemove={onFileRemove}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </FileRow>
          )
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ BorrowerCompanies }: STORE) => ({
  documentsUploadData: BorrowerCompanies.uploadBorrowerCompanyDocuments.data,
  documentsUploadStatus: BorrowerCompanies.uploadBorrowerCompanyDocuments.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadBorrowerCompanyDocuments,
      deleteBorrowerCompanyDocument
    },
    dispatch
  );

const FileUploadRowConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadRow);

export { FileUploadRowConnect as FileUploadRow };
