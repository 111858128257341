import { ResponseDataType, IS_DEBTOR_FACTORABLE } from '../reducers/isDebtorFactorable';

export const req = (debtorINN: string, clientINN: string) => ({
  type: IS_DEBTOR_FACTORABLE.REQ,
  debtorINN,
  clientINN
});

export const got = (data: ResponseDataType) => ({
  type: IS_DEBTOR_FACTORABLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: IS_DEBTOR_FACTORABLE.ERR,
  error
});
