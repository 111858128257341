import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'src/features/SCF/actions/postCreateDiscountAuctions';

import { POST_CREATE_DISCOUNT_AUCTIONS } from 'src/features/SCF/reducers/postCreateDiscountAuctions';

import SCFApi from 'src/features/SCF/api';

function* postCreateDiscountAuctions(action: {
  type: POST_CREATE_DISCOUNT_AUCTIONS;
  data: RequestData;
}) {
  try {
    yield call(SCFApi.postCreateDiscountAuctions, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateDiscountAuctionsSaga() {
  yield takeLatest(
    POST_CREATE_DISCOUNT_AUCTIONS.REQ,
    postCreateDiscountAuctions
  );
}
