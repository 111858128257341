import { GET_MY_BUYERS } from '../reducers/getMyBuyers';

export interface MyBuyer {
  debtorInn: string;
  debtorName: string;
  confirmedBySupplier: boolean;
  rejectedByDebtor: boolean;
  confirmedByDebtor: boolean;
  rejectedBySupplier: boolean;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: MyBuyer[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  debtorInnToFilterBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MY_BUYERS.GET_MY_BUYERS_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_MY_BUYERS.GET_MY_BUYERS_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MY_BUYERS.GET_MY_BUYERS_ERR,
  error
});
