import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/putOpenLoanLimitsSetActive';

import { PUT_OPEN_LOAN_LIMITS_SET_ACTIVE } from '../reducers/putOpenLoanLimitsSetActive';
import LimitsApi from '../api';

function* putOpenLoanLimitsSetActive(action: {
  type: PUT_OPEN_LOAN_LIMITS_SET_ACTIVE;
  id: string;
}): Generator {
  try {
    yield call(LimitsApi.putOpenLoanLimitsSetActive, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putOpenLoanLimitsSetActiveSaga(): Generator {
  yield takeLatest(
    PUT_OPEN_LOAN_LIMITS_SET_ACTIVE.REQ,
    putOpenLoanLimitsSetActive
  );
}
