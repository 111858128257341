import ApiRequest from 'src/shared/utils/ApiRequest';
import { FormData } from '../types/SearchPerson';

export default class PersonApi {
  static getSummaryPerson(inn: string) {
    return ApiRequest(`/api/person/open-user/summary/${inn}`);
  }

  static getPersonOfCompanies(inn: string) {
    return ApiRequest(`/api/persons_of_companies/open-user/person/${inn}`);
  }

  static getBeneficiariesOfCompanies(inn: string) {
    return ApiRequest(
      `/api/beneficiaries_of_companies/open-user/person/${inn}`
    );
  }

  static getSearchPerson(data: FormData) {
    return ApiRequest(`/api/person/open-user/all`, 'get', data);
  }
}
