import * as React from 'react';

import {
  EachForm,
  FactoringFormStep1,
  FactoringFormStep2,
  FactoringFormStep3,
  InitializationFormStep1
} from 'Application/components';

export class FactoringForm extends React.Component {
  render() {
    const routes = [
      {
        path: '',
        component: InitializationFormStep1,
        step: 'Параметры заявки'
      },
      {
        path: 'options',
        component: FactoringFormStep1,
        step: 'Выбор дебиторов'
      },
      {
        path: 'debtors',
        component: FactoringFormStep2,
        step: 'Параметры лимитов'
      },
      {
        path: 'documents',
        component: FactoringFormStep3,
        step: 'Документы по заявке'
      }
    ];

    return <EachForm routes={routes} />;
  }
}
