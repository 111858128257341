import { GET_DEBTOR_REGISTRIES_INTERNAL_AGENT } from 'entities/SCF/Internal/model/reducers/getDebtorRegistries';

export interface DebtorRegistries {
  id: number;
  debtorInn: string;
  debtorName: string;
  createdDateTime: string;
  isDeleted: boolean;
  registryFilename: string;
  signatureFilename: string;
  creatorName: string;
  size: string;
  status: number;
  errorsCount: number;
  errorRowsCount: number;
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: DebtorRegistries[];
}

export interface RequestData {
  page: number;
  pageSize?: number;
  debtorInn?: string;
}

export const req = (data: RequestData) => ({
  type: GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_DEBTOR_REGISTRIES_INTERNAL_AGENT.ERR,
  error
});
