import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as toggleExternalVisibility } from 'src/features/Application/actions/toggleExternalVisibility';

import { STORE, USER_PERMISSIONS } from 'globaltypes';
import { BankVisibilityTooltip } from 'shared/constants';

import {
  SwitchBanksContainer,
  ClientBlock,
  BanksBlock,
  BanksList,
  BankLink,
  ToggleBanksListIcon,
  ToggleBanksListIconSlash,
  Tooltip
} from './styles';

interface StateToProps {
  internalApplication: ResInternalApplication;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  toggleExternalVisibility: (appId: number) => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const SwitchBanksBlock: React.FC<Props> = ({
  internalApplication,
  toggleExternalVisibility,
  permissions,
  match
}) => {
  const [isOpened, setIsOpened] = React.useState(false);

  const toggle = () => {
    setIsOpened(prevState => !prevState);
  };

  const hide = () => {
    setIsOpened(false);
  };

  const inBankId = parseInt(match.params.inBankId, 10);
  const currentBank = internalApplication.bankApplications.find(
    ({ id }) => id === inBankId
  );
  const bankApplications = [...internalApplication.bankApplications];

  return (
    bankApplications.length > 0 && (
      <SwitchBanksContainer>
        <ClientBlock>
          <p>Заявка</p>
          <BankLink
            exact={true}
            onClick={hide}
            to={`/cabinet/internal/application-guarantees/${match.params.id}`}
          >
            Клиент
          </BankLink>
        </ClientBlock>

        <BanksBlock open={isOpened}>
          <p onClick={toggle}>Вы отправили заявку в следующие банки:</p>

          <BanksList>
            {!!match.params.inBankId && (
              <div>
                {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                  !!!currentBank.externalVisibility && (
                    <ToggleBanksListIconSlash
                      onClick={() => {
                        toggleExternalVisibility(currentBank.id);
                        currentBank.externalVisibility =
                          1 - currentBank.externalVisibility;
                      }}
                    >
                      <Tooltip>{BankVisibilityTooltip.BANK_INVISIBLE}</Tooltip>
                    </ToggleBanksListIconSlash>
                  )}
                {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                  !!currentBank.externalVisibility && (
                    <ToggleBanksListIcon
                      onClick={() => {
                        toggleExternalVisibility(currentBank.id);
                        currentBank.externalVisibility =
                          1 - currentBank.externalVisibility;
                      }}
                    >
                      <Tooltip>{BankVisibilityTooltip.BANK_VISIBLE}</Tooltip>
                    </ToggleBanksListIcon>
                  )}
                <BankLink
                  status={currentBank.status}
                  to={`/cabinet/internal/application-guarantees/${
                    match.params.id
                  }/${currentBank.id}`}
                >
                  {currentBank.name}
                  {currentBank.apiName && (
                    <span>API {currentBank.apiName}</span>
                  )}
                </BankLink>
              </div>
            )}

            {bankApplications.map(
              ({ id, status, name, apiName, externalVisibility }) => (
                <div key={id}>
                  {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                    !!!externalVisibility && (
                      <ToggleBanksListIconSlash
                        hidden={inBankId === id}
                        onClick={() => {
                          toggleExternalVisibility(id);
                          externalVisibility = 1 - externalVisibility;
                          bankApplications.find(
                            obj => obj.id === id
                          ).externalVisibility = externalVisibility;
                        }}
                      >
                        <Tooltip>
                          {BankVisibilityTooltip.BANK_INVISIBLE}
                        </Tooltip>
                      </ToggleBanksListIconSlash>
                    )}
                  {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                    !!externalVisibility && (
                      <ToggleBanksListIcon
                        hidden={inBankId === id}
                        onClick={() => {
                          toggleExternalVisibility(id);
                          externalVisibility = 1 - externalVisibility;
                          bankApplications.find(
                            obj => obj.id === id
                          ).externalVisibility = externalVisibility;
                        }}
                      >
                        <Tooltip>{BankVisibilityTooltip.BANK_VISIBLE}</Tooltip>
                      </ToggleBanksListIcon>
                    )}
                  <BankLink
                    status={status}
                    onClick={hide}
                    hidden={inBankId === id}
                    to={`/cabinet/internal/application-guarantees/${
                      match.params.id
                    }/${id}`}
                  >
                    {name}
                    {apiName && <span>API {apiName}</span>}
                  </BankLink>
                </div>
              )
            )}
          </BanksList>
        </BanksBlock>
      </SwitchBanksContainer>
    )
  );
};

const mapStateToProps = ({ InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ toggleExternalVisibility }, dispatch);

const SwitchBanksBlockConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SwitchBanksBlock)
);

export { SwitchBanksBlockConnect as SwitchBanksBlock };
