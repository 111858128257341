import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestType
} from '../actions/postCreateBuyersToSupplierLink';

import { POST_CREATE_BUYERS_TO_SUPPLIER_LINK } from '../reducers/postCreateBuyersToSupplierLink';
import BuyersToSupplierLinkApi from '../api';

function* postCreateBuyersToSupplierLink(action: {
  type: POST_CREATE_BUYERS_TO_SUPPLIER_LINK;
  data: RequestType;
}): Generator {
  try {
    const res: any = yield call(
      BuyersToSupplierLinkApi.postCreateBuyersToSupplierLink,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateBuyersToSupplierLinkSaga(): Generator {
  yield takeLatest(
    POST_CREATE_BUYERS_TO_SUPPLIER_LINK.REQ,
    postCreateBuyersToSupplierLink
  );
}
