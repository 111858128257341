import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Users/actions/getUserLeads';

export interface GetUserLeadsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_USER_LEADS {
  REQ = 'GET_USER_LEADS_REQ',
  GOT = 'GET_USER_LEADS_GOT',
  ERR = 'GET_USER_LEADS_ERR'
}

export const initialState: GetUserLeadsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getUserLeads = (
  state: GetUserLeadsStoreState = initialState,
  action: {
    type: GET_USER_LEADS;
    data: ResponseData;
    error: {};
  }
): GetUserLeadsStoreState => {
  switch (action.type) {
    case GET_USER_LEADS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_USER_LEADS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_USER_LEADS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getUserLeads;
