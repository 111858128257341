import { GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN } from 'entities/SCF/Factor/model/reducers/getCurrentFactoringSettingsByDebtorInn';

export interface ResponseDataType {
  guid?: string;
  debtorInn?: string;
  status?: string;
  volume?: number;
  limitUsage?: number;
  autoLimitPolicy?: number;
  factoringBaseRate?: number;
  vatSize?: number;
  factoringFullRate?: number;
  factualUsageOnly?: boolean;
  gracePeriodBaseRate?: number;
  regressPeriodBaseRate?: number;
  regressPeriodFullRate?: number;
  defaultGracePeriodDays?: number;
  defaultRegressPeriodDays?: number;
  regressPeriodIsInfinite?: boolean;
  defaultMaximalClaimDaysToMaturity?: number;
  defaultMaximalClaimSum?: number;
  defaultMaximalShareOfFunding?: number;
  defaultMaximalCessionShare?: number;
  defaultMinimalClaimDaysToMaturity?: number;
  defaultMinimalShareOfFunding?: number;
  defaultMinimalCessionShare?: number;
  fundingSharePolicy?: number;
  isRegressed?: boolean;
  isNotHidden?: boolean;
  isForOnboardedClientsOnly?: boolean;
  signatureFilename?: string;
  signatureFileIdentifier?: string;
  requestFilename?: string;
  requestFileIdentifier?: string;
  factoringRateBase?: '1' | '2' | '3' | null;
  gracePeriodFullRate?: number;
  rateIsSurplusToKeyRate?: boolean;
}

export interface RequestDataType {
  page?: number;
  pageSize?: number;
  debtorInn: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN.GET_CURRENT_FACTORING_SETTINGS_BY_DEBTOR_INN_ERR,
  error
});
