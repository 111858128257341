import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import {
  req as getApiKeyInfo,
  ResponseData as ReqApiKeyInfo
} from 'src/features/ApiKeys/actions/getApiKeyInfo';
import { reset as businessRegisterationReset } from 'Business/actions/businessRegister';

import { RegistrationPage } from 'User/components';
import { BusinessRegistrationForm } from 'Business/components';

import {
  RegistrationPageStyled,
  RegistrationFormBox,
  InvitedUserInfo,
  Container
} from './styles';
import { RouteComponentProps, withRouter } from 'react-router';

interface StateToProps {
  registrationStatus: REQUEST_STATUSES;
  registrationError: ResponseError;
  email: string;
  userInfo: ReqApiKeyInfo;
}

interface DispatchToProps {
  businessRegisterationReset: () => void;
  getApiKeyInfo: (key: string) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const BusinessRegistrationPage: React.FC<Props> = ({
  email,
  registrationStatus,
  registrationError,
  businessRegisterationReset,
  getApiKeyInfo,
  userInfo,
  match
}) => {
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const key =
      searchParams.get('partner') ||
      location.pathname.split(`/registration/business/${match.params.inn}/`)[1];

    if (key) {
      getApiKeyInfo(key);
    }
  }, []);

  return (
    <RegistrationPage
      email={email}
      registrationStatus={registrationStatus}
      registrationError={registrationError}
      registrationReset={businessRegisterationReset}
    >
      <Container>
        {userInfo && (
          <InvitedUserInfo>
            <h3>Вас приглашает на платформу:</h3>
            <p>
              {userInfo.lastName} {userInfo.firstName}
            </p>
            <p>{userInfo.companyName}</p>
          </InvitedUserInfo>
        )}

        <RegistrationPageStyled>
          <RegistrationFormBox>
            <BusinessRegistrationForm />
          </RegistrationFormBox>
        </RegistrationPageStyled>
      </Container>
    </RegistrationPage>
  );
};

const mapStateToProps = ({ Business, ApiKeys }: STORE) => ({
  registrationStatus: Business.businessRegister.status,
  registrationError: Business.businessRegister.error,
  email: Business.businessRegister.data.email,
  userInfo: ApiKeys.getApiKeyInfo.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ businessRegisterationReset, getApiKeyInfo }, dispatch);

const BusinessRegistrationPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessRegistrationPage)
);

export { BusinessRegistrationPageConnect as BusinessRegistrationPage };
