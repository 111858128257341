import {
  RequestDataType,
  POST_FACTORING_FEES_OF_LIMITS
} from 'entities/SCF/Factor/model/reducers/postFactoringFeesOfLimits';

export const req = (inn: string, data: RequestDataType) => ({
  type: POST_FACTORING_FEES_OF_LIMITS.REQ,
  data,
  inn
});

export const got = () => ({
  type: POST_FACTORING_FEES_OF_LIMITS.GOT
});

export const reset = () => ({
  type: POST_FACTORING_FEES_OF_LIMITS.RESET
});

export const err = (error: {}) => ({
  type: POST_FACTORING_FEES_OF_LIMITS.ERR,
  error
});
