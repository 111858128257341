import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Agent } from 'src/features/Questionnaire/types';

export interface GetAgentStoreState extends Reducer {
  data: Agent;
}

export enum GET_AGENT {
  REQ = 'GET_AGENT_REQ',
  GOT = 'GET_AGENT_GOT',
  ERR = 'GET_AGENT_ERR'
}

export const initialState: GetAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getAgent = (
  state: GetAgentStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetAgentStoreState => {
  switch (action.type) {
    case GET_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAgent;
