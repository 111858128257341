import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ScrollTopComponent } from 'Common';
import { history } from 'src/shared/utils/History';
import { red, navyBlue } from 'shared/styled';
import Popup from 'reactjs-popup';

import {
  req as confirmExternalAgent,
  RequestDataType as ConfirmExternalAgentRequestDataType
} from '../../actions/confirmExternalAgent';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as getExternalAgentsList,
  RequestDataType,
  ResponseDataType
} from '../../actions/getExternalAgentsList';

import {
  Loader,
  Pagination,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'Layouts/components';
import {
  ConfirmButtonContainer,
  ExternalAgentsListStyled,
  ExternalAgentStatusStyled
} from './styles';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { EXTERNAL_AGENT_STATUSES } from 'src/features/ExternalAgent/reducers/externalAgentStatus';
import { req as toggleLeadogeneratorPermission } from '../../actions/toggleLeadogeneratorPermission';
import { InnPlaceholders } from 'shared/constants';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { Button } from 'shared/ui/Button';

interface State {
  innToFilterBy: string;
  emailToFilterBy: string;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  externalAgentsList: ResponseDataType;
  isRequestingStatusChange: boolean;
}

interface DispatchToProps {
  getExternalAgentsList: (data: RequestDataType) => void;
  confirmExternalAgent: (data: ConfirmExternalAgentRequestDataType) => void;
  toggleLeadogeneratorPermission: (userId: number) => void;
}

type Props = StateToProps & DispatchToProps;

class ExternalAgentsList extends React.Component<Props, State> {
  state = {
    innToFilterBy: '',
    emailToFilterBy: ''
  };

  initFetch = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    this.props.getExternalAgentsList({ page, pageSize: 20, ...this.state });
  };

  onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.props.isRequestingStatusChange ||
      prevState.emailToFilterBy !== this.state.emailToFilterBy ||
      prevState.innToFilterBy !== this.state.innToFilterBy
    )
      this.initFetch();
  }

  componentDidMount() {
    this.initFetch();
  }

  renderConfirmButton(agentId: number) {
    return (
      <ConfirmButtonContainer>
        <Button
          label="Подтвердить"
          h="49px"
          onClick={() =>
            this.props.confirmExternalAgent({
              id: agentId,
              status: EXTERNAL_AGENT_STATUSES.APPROVED
            })
          }
        />
        <Button
          backgroundColor={red}
          label="Отказать"
          h="49px"
          onClick={() =>
            this.props.confirmExternalAgent({
              id: agentId,
              status: EXTERNAL_AGENT_STATUSES.REJECTED
            })
          }
        />
      </ConfirmButtonContainer>
    );
  }

  renderStatus(status: EXTERNAL_AGENT_STATUSES) {
    switch (status) {
      case EXTERNAL_AGENT_STATUSES.APPROVED:
        return (
          <ExternalAgentStatusStyled backgroundColor="#1cca94" color={navyBlue}>
            Подтвержден
          </ExternalAgentStatusStyled>
        );
      case EXTERNAL_AGENT_STATUSES.REJECTED:
        return (
          <ExternalAgentStatusStyled backgroundColor="#ffd0d0" color={navyBlue}>
            Отклонен
          </ExternalAgentStatusStyled>
        );
      case EXTERNAL_AGENT_STATUSES.NOT_APPROVED:
        return (
          <ExternalAgentStatusStyled backgroundColor="#fff0de" color={navyBlue}>
            На рассмотрении
          </ExternalAgentStatusStyled>
        );
    }
  }

  render() {
    const { status, externalAgentsList, error } = this.props;
    return (
      <ExternalAgentsListStyled>
        <FiltersStyled>
          <INNFilterContainerStyled>
            <Input
              value={this.state.innToFilterBy}
              label="ИНН"
              name="innToFilterBy"
              placeholder={InnPlaceholders.entity}
              onChange={this.onFilterChange}
            />
          </INNFilterContainerStyled>
          <INNFilterContainerStyled>
            <Input
              value={this.state.emailToFilterBy}
              label="Email"
              name="emailToFilterBy"
              placeholder="E-mail"
              onChange={this.onFilterChange}
            />
          </INNFilterContainerStyled>
        </FiltersStyled>

        <h2>Список внешних агентов</h2>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <ScrollTopComponent>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="8%">Имя</TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    Email
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    Название компании
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="5%">ИНН</TableHeaderCellStyled>
                  <TableHeaderCellStyled width="5%">
                    Номер телефона
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="6%">
                    Статус
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    Подтвердить/Отклонить
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="10%">
                    Лидогенератор?
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {externalAgentsList.items.map((item, key) => (
                  <TableRowStyled key={key}>
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td>
                      <Popup
                        trigger={<div>{item.companyName}</div>}
                        position="top right"
                        on={['hover']}
                      >
                        {' '}
                        {item.companyName}{' '}
                      </Popup>
                    </td>
                    <td>{item.code}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{this.renderStatus(item.status)}</td>
                    <td>
                      {item.status === EXTERNAL_AGENT_STATUSES.NOT_APPROVED &&
                        this.renderConfirmButton(item.id)}
                    </td>
                    {/* TODO fix blank space label */}
                    <td>
                      <Checkbox
                        label={item.isLeadogenerator ? 'Да' : 'Нет'}
                        name="isLeadogenerator"
                        onChange={() =>
                          this.props.toggleLeadogeneratorPermission(item.userId)
                        }
                        checked={item.isLeadogenerator}
                      />
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={externalAgentsList} />
          </ScrollTopComponent>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ExternalAgentsListStyled>
    );
  }
}

const mapStateToProps = ({ Permissions }: STORE) => ({
  externalAgentsList: Permissions.getExternalAgentsList.data,
  status: Permissions.getExternalAgentsList.status,
  error: Permissions.getExternalAgentsList.error,
  isRequestingStatusChange:
    Permissions.confirmExternalAgent.status === REQUEST_STATUSES.REQUEST ||
    Permissions.toggleLeadogeneratorPermission.status ===
      REQUEST_STATUSES.REQUEST
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getExternalAgentsList,
      confirmExternalAgent,
      toggleLeadogeneratorPermission
    },
    dispatch
  );

const ExternalAgentsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAgentsList);

export { ExternalAgentsListConnect as ExternalAgentsList };
