import { combineReducers } from 'redux';
import getCounterparties, {
  GetCounterpartiesStoreState
} from './getCounterparties';
import getAgents, { GetAgentsStoreState } from './getAgents';

export interface CounterpartiesReducers {
  getCounterparties: GetCounterpartiesStoreState;
  getAgents: GetAgentsStoreState;
}

export const Counterparties = combineReducers({
  getCounterparties,
  getAgents
});
