import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import {
  req as getBuyersToSupplierLinkList,
  ResponseData,
  RequestData
} from 'src/features/BuyersToSupplierLink/actions/getBuyersToSupplierLinkList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import {
  INNContainer,
  INNField
} from '../components/BuyersToSupplierLinkList/styled';
import { BuyersToSupplierLinkList } from './BuyersToSupplierLinkList/BuyersToSupplierLinkList';
import { HeadContainer } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  buyersToSupplierLinkList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getBuyersToSupplierLinkList: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BuyersToSupplierLink: React.FC<Props> = ({
  getBuyersToSupplierLinkList,
  buyersToSupplierLinkList,
  status,
  error,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';

      setDebtorInn(debtorInn);
      setSupplierInn(supplierInn);

      getBuyersToSupplierLinkList({
        page,
        pageSize: 20,
        ...(debtorInn && { DebtorInnToFilterBy: debtorInn }),
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToBuyersSupplierLinkNew = () => {
    history.push('/crm/buyers_to_suppliers_links/new');
  };

  return (
    <ScrollTopComponent>
      <HeadContainer>
        <ApplicationTitle>Связки Дебитор - Поставщик</ApplicationTitle>
        <Button
          label="Создать новую связку"
          onClick={navigateToBuyersSupplierLinkNew}
        />
      </HeadContainer>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      <BuyersToSupplierLinkList
        buyersToSupplierLinkList={buyersToSupplierLinkList}
        status={status}
        error={error}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ BuyersToSupplierLink }: CRM) => ({
  buyersToSupplierLinkList:
    BuyersToSupplierLink.getBuyersToSupplierLinkList.data,
  status: BuyersToSupplierLink.getBuyersToSupplierLinkList.status,
  error: BuyersToSupplierLink.getBuyersToSupplierLinkList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getBuyersToSupplierLinkList }, dispatch);

const BuyersToSupplierLinkConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BuyersToSupplierLink)
);

export { BuyersToSupplierLinkConnect as BuyersToSupplierLink };
