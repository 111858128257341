import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData as SuppliersData } from 'src/features/Counterparties/actions/getCounterparties';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { NoData, SuppliersContainer } from './styled';
import { Button } from 'shared/ui/Button';

interface OwnProps {
  suppliers: SuppliersData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & OwnProps;

const SuppliersList: React.FC<Props> = ({
  suppliers,
  status,
  error,
  history,
  match
}) => {
  const navigateToCompany = (inn: string) => {
    if (inn) {
      history.push(`/crm/companies/${inn}`);
    }
  };

  const navigateToBuyersToSupplierLink = () => {
    history.push('/crm/buyers_to_suppliers_links/new', {
      debtorInn: match.params.inn
    });
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <SuppliersContainer>
          <Button
            label="Добавить покупателя"
            onClick={navigateToBuyersToSupplierLink}
          />

          {suppliers.items.length ? (
            <>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="2%">ИНН</TableThStyled>
                    <TableThStyled width="10%">Наименование</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {suppliers.items.map(company => (
                    <TableRowStyled
                      key={company.id}
                      onClick={() => navigateToCompany(company.supplierInn)}
                    >
                      <td>{company.debtorInn}</td>
                      <td>{company.debtorName}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
              <Pagination list={suppliers} />
            </>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>на платформе пока нет данных о контрагентах компании</p>
              <p>вы можете добавить покупателей самостоятельно</p>
            </NoData>
          )}
        </SuppliersContainer>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const SuppliersListConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SuppliersList)
);

export { SuppliersListConnect as SuppliersList };
