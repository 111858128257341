import styled from 'styled-components';

import { rr, gray } from 'shared/styled';

export const Title = styled.span`
  font-family: ${rr};
  font-size: 12px;
  color: ${gray};
`;

export const List = styled.ul`
  width: 360px;
  margin: 10px 0;
  padding-left: 15px;
  line-height: 20px;
`;

export const ListItem = styled.li`
  font-family: ${rr};
  font-size: 1.2rem;
  color: ${gray};
`;
