import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getBankAccountList,
  ResponseData
} from 'src/features/BankAccount/actions/getBankAccountList';
import { CopyBlock, INNContainer, INNField, TDCopyTooltip } from './styled';

interface StateToProps {
  bankAccountList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getBankAccountList: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BankAccountList: React.FC<Props> = ({
  getBankAccountList,
  bankAccountList,
  status,
  error,
  history
}) => {
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    getBankAccountList();
  }, []);

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Банковские счета</ApplicationTitle>

      {/* <INNContainer>
        <INNField>
          <Input
            label="ИНН компании"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="Номер счета"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer> */}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">ИНН Клиента</TableThStyled>
                <TableThStyled width="7%">Номер счета</TableThStyled>
                <TableThStyled width="15%">Банк</TableThStyled>
                <TableThStyled width="10%">Номер корр.счета</TableThStyled>
                <TableThStyled width="10%">Валюта</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {bankAccountList.items.map(user => (
                <TableRowStyled key={user.id}>
                  <TDCopyTooltip>
                    <div />
                    <CopyBlock
                      text={
                        user.clientInn && !isCopied
                          ? 'Скопировать ИНН'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(user.clientInn)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      {user.clientInn}
                    </CopyBlock>
                  </TDCopyTooltip>
                  <TDCopyTooltip>
                    <CopyBlock
                      text={
                        user.accountNumber && !isCopied
                          ? 'Скопировать номер'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(user.accountNumber)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      {user.accountNumber}
                    </CopyBlock>
                  </TDCopyTooltip>
                  <TDCopyTooltip>
                    <CopyBlock
                      text={
                        user.bankBik && !isCopied
                          ? 'Скопировать БИК'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(user.bankBik)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      {user.bankBik}
                    </CopyBlock>
                    <CopyBlock
                      text={
                        user.bankName && !isCopied
                          ? 'Скопировать банк'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() => handleCopyClick(user.bankName)}
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      {user.bankName}
                    </CopyBlock>
                  </TDCopyTooltip>
                  <TDCopyTooltip>
                    <CopyBlock
                      text={
                        user.correspondentAccountNumber && !isCopied
                          ? 'Скопировать корр.счет'
                          : isCopied && 'Скопировано!'
                      }
                      onClick={() =>
                        handleCopyClick(user.correspondentAccountNumber)
                      }
                      onMouseLeave={() => setIsCopied(false)}
                    >
                      {user.correspondentAccountNumber}
                    </CopyBlock>
                  </TDCopyTooltip>
                  <td>{user.currencyCode}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={bankAccountList} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ BankAccount }: CRM) => ({
  bankAccountList: BankAccount.getBankAccountList.data,
  status: BankAccount.getBankAccountList.status,
  error: BankAccount.getBankAccountList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getBankAccountList }, dispatch);

const BankAccountListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BankAccountList)
);

export { BankAccountListConnect as BankAccountList };
