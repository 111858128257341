import { GET_SUBSIDIARIES_OF_COMPANIES } from 'Business/reducers/getSubsidiariesOfCompanies';
import { SubsidiariesType } from '../types';

export const req = (inn: string) => ({
  type: GET_SUBSIDIARIES_OF_COMPANIES.REQ,
  inn
});

export const got = (data: SubsidiariesType[]) => ({
  type: GET_SUBSIDIARIES_OF_COMPANIES.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_SUBSIDIARIES_OF_COMPANIES.ERR,
  error
});
