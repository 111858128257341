import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddToContractByDebtorStoreState extends Reducer {}

export enum POST_ADD_TO_CONTRACT_BY_DEBTOR {
  REQ = 'POST_ADD_TO_CONTRACT_BY_DEBTOR_REQ',
  GOT = 'POST_ADD_TO_CONTRACT_BY_DEBTOR_GOT',
  ERR = 'POST_ADD_TO_CONTRACT_BY_DEBTOR_ERR'
}

export const initialState: PostAddToContractByDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddToContractByDebtor = (
  state: PostAddToContractByDebtorStoreState = initialState,
  action: {
    type: POST_ADD_TO_CONTRACT_BY_DEBTOR;
    error: {};
  }
): PostAddToContractByDebtorStoreState => {
  switch (action.type) {
    case POST_ADD_TO_CONTRACT_BY_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADD_TO_CONTRACT_BY_DEBTOR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADD_TO_CONTRACT_BY_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddToContractByDebtor;
