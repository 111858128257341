import { GET_DEBTOR_SIGNATURE_FILE } from 'entities/SCF/Debtor/model/reducers/getDebtorSignatureFile';

export const req = (guid: string) => ({
  type: GET_DEBTOR_SIGNATURE_FILE.REQ,
  guid
});

export const got = () => ({
  type: GET_DEBTOR_SIGNATURE_FILE.GOT
});

export const err = (error: {}) => ({
  type: GET_DEBTOR_SIGNATURE_FILE.ERR,
  error
});
