import { POST_CREATE_DISCOUNT_AUCTIONS } from 'src/features/SCF/reducers/postCreateDiscountAuctions';

export interface RequestData {
  LiquidityLimit: number;
  ResultsDateTime: string;
  PayDate: string;
  TargetDiscountRate: number;
  MinDiscountRate: number;
}

export const req = (data: RequestData) => ({
  type: POST_CREATE_DISCOUNT_AUCTIONS.REQ,
  data
});

export const got = () => ({
  type: POST_CREATE_DISCOUNT_AUCTIONS.GOT
});

export const err = (error: {}) => ({
  type: POST_CREATE_DISCOUNT_AUCTIONS.ERR,
  error
});
