import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Debtor/model/actions/putAcceptByDebtor';

import { PUT_ACCEPT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/putAcceptByDebtor';

import SCFApi from 'entities/SCF/Debtor/api';

function* putAcceptByDebtor(action: {
  type: PUT_ACCEPT_BY_DEBTOR;
  guid: string;
}) {
  try {
    yield call(SCFApi.putAcceptByDebtor, action.guid);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putAcceptByDebtorSaga() {
  yield takeLatest(PUT_ACCEPT_BY_DEBTOR.REQ, putAcceptByDebtor);
}
