import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getByMonetaryClaimSupplier';

export interface GetByMonetaryClaimSupplierStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_BY_MONETARY_CLAIM_SUPPLIER {
  REQ = 'GET_BY_MONETARY_CLAIM_SUPPLIER_REQ',
  GOT = 'GET_BY_MONETARY_CLAIM_SUPPLIER_GOT',
  ERR = 'GET_BY_MONETARY_CLAIM_SUPPLIER_ERR',
  RESET = 'GET_BY_MONETARY_CLAIM_SUPPLIER_RESET'
}

const initialState: GetByMonetaryClaimSupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getByMonetaryClaimSupplier = (
  state: GetByMonetaryClaimSupplierStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetByMonetaryClaimSupplierStoreState => {
  switch (action.type) {
    case GET_BY_MONETARY_CLAIM_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_BY_MONETARY_CLAIM_SUPPLIER.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case GET_BY_MONETARY_CLAIM_SUPPLIER.RESET:
      return {
        ...initialState
      };

    case GET_BY_MONETARY_CLAIM_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getByMonetaryClaimSupplier;
