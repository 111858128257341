import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/postCreateTender';

import { POST_CREATE_TENDER } from '../reducers/postCreateTender';
import ApplicationApi from '../api';

function* postCreateTender(action: {
  type: POST_CREATE_TENDER;
  data: RequestData;
}): Generator {
  try {
    const id: any = yield call(ApplicationApi.postCreateTender, action.data);

    yield put(got(id));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateTenderSaga(): Generator {
  yield takeLatest(POST_CREATE_TENDER.REQ, postCreateTender);
}
