import styled from 'styled-components';
import { ButtonStyled } from 'shared/ui/Button/styled';
import {
  PopupContent,
  PopupContainer
} from 'src/features/Contacts/components/ContactsList/styled';

export const BtnAddPlatform = styled(ButtonStyled)`
  width: 140px;
  height: 40px;
`;

export const PopupContainerPlatform = styled(PopupContainer)``;

export const PopupContentPlatform = styled(PopupContent)`
  max-width: 900px;
  min-width: 250px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }
`;
