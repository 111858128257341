import * as React from 'react';
import { Document as IDocument } from 'Application/types';
import { Document } from 'Layouts/components';

import { DocumentsBlockStyled } from './styles';

export interface Props {
  documents: IDocument[];
}

export const DocumentsBlock: React.FC<Props> = ({ documents }) => (
  <DocumentsBlockStyled>
    {documents.map((document, key) => (
      <Document key={key} document={document} />
    ))}
  </DocumentsBlockStyled>
);
