import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rr, rb, navyBlue, gray } from 'shared/styled';
import { Button } from 'shared/ui/Button';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 30px;
`;

export const ButtonExternalApiGIR_BO = styled(Button)`
  max-width: fit-content;

  :disabled {
    background-color: ${gray};
  }
`;

export const Container = styled.div`
  margin: 25px 0 60px 0;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 10px;
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: ${rr};
  background-color: ${({ bgColor }) => bgColor};
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  margin-top: 40px;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 60%;
  margin-top: 10px;
`;

export const TableContainer = styled.table`
  width: 60%;
  border-collapse: collapse;
  font-family: ${rr};
  color: ${navyBlue};

  td,
  th {
    padding: 5px;
    border: 1px solid black;
  }
`;

export const TableHeader = styled.thead`
  border-bottom: 1px solid black;
`;

export const TableHeaderCell = styled.th`
  text-align: left;
  padding-bottom: 5px;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  td:not(:first-child) {
    text-align: center;
  }

  th:first-child {
    width: 450px;
  }
`;

export const Input = styled.input`
  width: 70px;
  border: 1px solid;
  border-color: ${({ error }) => (error ? 'red' : '#d9d9d9')};
  background-color: ${({ error }) => (error ? '#FFD0D0' : 'transaprent')};
`;

export const TableCell = styled.td`
  vertical-align: bottom;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3,
    p {
      font-family: ${rb};
    }
  }

  font-family: ${({ is1100, is1200, is1600, is1300, is1400, is1500, is1700 }) =>
    is1100
      ? rb
      : is1200
        ? rb
        : is1600
          ? rb
          : is1300
            ? rb
            : is1400
              ? rb
              : is1500
                ? rb
                : is1700
                  ? rb
                  : null};
`;
