import { ADD_PARTICIPANT } from '../reducers/addParticipant';

export const req = (activityId: string, contactId: number) => ({
  type: ADD_PARTICIPANT.REQ,
  activityId,
  contactId
});

export const reset = () => ({
  type: ADD_PARTICIPANT.RESET
});

export const got = data => ({
  type: ADD_PARTICIPANT.GOT,
  data
});

export const err = (error: {}) => ({
  type: ADD_PARTICIPANT.ERR,
  error
});
