import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';
import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import {
  got,
  err
} from 'BorrowerCompanies/actions/deleteBorrowerCompanyDocument';
import {
  DELETE_BORROWER_COMPANY_DOCUMENT,
  SagaRequestDataType
} from 'BorrowerCompanies/reducers/deleteBorrowerCompanyDocument';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* deleteBorrowerCompanyDocument(action: {
  type: DELETE_BORROWER_COMPANY_DOCUMENT;
  data: SagaRequestDataType;
}): Generator {
  const deleteDocument = documents =>
    documents.filter(document => document.id !== action.data.id);

  const updateDocuments = borrowerCompanies =>
    borrowerCompanies.map(company => {
      if (company.id === action.data.companyId) {
        company.documents = deleteDocument(company.documents);
      }

      return company;
    });

  try {
    const res:any = yield call(BorrowerCompaniesApi.deleteBorrowerCompanyDocument, {
      id: action.data.id
    });

    const borrowerCompanies = yield select(
      ({ Application }: STORE) =>
        Application.setInitializationFormData.borrowerCompanies
    );

    yield put(
      setInitializationFormData({
        borrowerCompanies: updateDocuments(borrowerCompanies)
      })
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteBorrowerCompanyDocumentSaga(): Generator {
  yield takeLatest(
    DELETE_BORROWER_COMPANY_DOCUMENT.REQ,
    deleteBorrowerCompanyDocument
  );
}
