import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { FinbankRead } from '../actions/setFinbankFormData';

export interface GetFinbankStoreState extends Reducer {
  finbank: FinbankRead;
}

export enum GET_FINBANK {
  REQ = 'GET_FINBANK_REQ',
  GOT = 'GET_FINBANK_GOT',
  ERR = 'GET_FINBANK_ERR'
}

export const initialState: GetFinbankStoreState = {
  status: REQUEST_STATUSES.NONE,
  finbank: {
    inn: ''
  },
  error: {}
};

const getFinbank = (
  state: GetFinbankStoreState = initialState,
  action: {
    type: string;
    finbank: FinbankRead;
    error: {};
  }
): GetFinbankStoreState => {
  switch (action.type) {
    case GET_FINBANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINBANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        finbank: action.finbank
      };
    case GET_FINBANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFinbank;
