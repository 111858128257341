import styled from 'styled-components';
import { blue, gray, rl, rr } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const DocumentSection = styled.section`
  margin: 50px 0;
`;

export const MainSection = styled.main`
  padding: 160px 90px 50px;
`;

export const IsNecessarily = styled.div`
  color: ${({ isUploaded }) => (isUploaded ? '' : 'red')};
`;

export const Period = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const CompanyHead = styled.div`
  margin: 50px 0 20px;

  & > p:first-child {
    font-size: 14px;
  }

  & > p:last-child {
    font-size: 12px;
  }
`;

export const DocumentType = styled(Period)`
  div {
    font-family: ${rr};
  }
`;

export const IsUploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 35px;
  background-color: ${({ isUploaded }) => (isUploaded ? '#1CCA94' : '#FFA552')};
  border-radius: 5px;
  padding: 13px 24px;
  font-size: 16px;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;

    p {
      color: white;
      margin: 0;
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
`;

export const UploadButton = styled(ButtonStyled)`
  width: fit-content;
  height: 35px;
  column-gap: 10px;
  padding: 5px 15px;
  background-color: ${({ status }) => status && gray};

  :disabled {
    background-color: ${gray};

    :hover {
      background-color: ${gray};
    }
  }

  p {
    color: white;
    margin: 0;
  }
`;

export const TableWrapper = styled.div`
  margin-left: 25px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  margin: 20px 0;
`;

export const ProgressText = styled.span`
  margin-left: 15px;
  font-family: ${rl};
  color: ${blue};
`;

export const ProgressFill = styled.progress`
  width: 100%;
  height: 25px;
`;

export const TitleRegister = styled.h3`
  width: 36%;
  margin-top: 30px;
  font-size: 24px;
  color: black;
  font-family: ${rl};

  span {
    cursor: pointer;
    color: ${blue};
    text-decoration: underline;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const PopupContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;
