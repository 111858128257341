import { GET_LEAD_DOCUMENT_LIST } from '../reducers/getLeadDocumentList';
import { DocumentListType } from '../types';

export const req = (id: string) => ({
  type: GET_LEAD_DOCUMENT_LIST.REQ,
  id
});

export const got = (data: DocumentListType) => ({
  type: GET_LEAD_DOCUMENT_LIST.GOT,
  data
});

export const reset = () => ({
  type: GET_LEAD_DOCUMENT_LIST.RESET
});

export const err = (error: {}) => ({
  type: GET_LEAD_DOCUMENT_LIST.ERR,
  error
});
