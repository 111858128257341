import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/Client/model/actions/getOpenLoanLimits';

import { GET_OPEN_LOAN_LIMITS_CLIENT } from 'entities/Cabinet/Client/model/reducers/getOpenLoanLimits';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* getOpenLoanLimitsClient(action: {
  type: GET_OPEN_LOAN_LIMITS_CLIENT;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      ClientRoleApi.getOpenLoanLimits,
      action.data.companyInn
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOpenLoanLimitsClientSaga(): Generator {
  yield takeLatest(GET_OPEN_LOAN_LIMITS_CLIENT.REQ, getOpenLoanLimitsClient);
}
