import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/deleteContact';

import { DELETE_CONTACT } from '../reducers/deleteContact';
import ContactsApi from '../api';

function* deleteContact(action: {
  type: DELETE_CONTACT;
  id: number;
  reason: string;
}): Generator {
  try {
    let res: any;

    res = yield call(ContactsApi.deleteContact, action.id, action.reason);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteContactSaga(): Generator {
  yield takeLatest(DELETE_CONTACT.REQ, deleteContact);
}
