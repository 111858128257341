import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';

export interface GetByMonetaryClaimDebtorStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_BY_MONETARY_CLAIM_DEBTOR {
  REQ = 'GET_BY_MONETARY_CLAIM_DEBTOR_REQ',
  GOT = 'GET_BY_MONETARY_CLAIM_DEBTOR_GOT',
  ERR = 'GET_BY_MONETARY_CLAIM_DEBTOR_ERR',
  RESET = 'GET_BY_MONETARY_CLAIM_DEBTOR_RESET'
}

const initialState: GetByMonetaryClaimDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getByMonetaryClaimDebtor = (
  state: GetByMonetaryClaimDebtorStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetByMonetaryClaimDebtorStoreState => {
  switch (action.type) {
    case GET_BY_MONETARY_CLAIM_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_BY_MONETARY_CLAIM_DEBTOR.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case GET_BY_MONETARY_CLAIM_DEBTOR.RESET:
      return {
        ...initialState
      };

    case GET_BY_MONETARY_CLAIM_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getByMonetaryClaimDebtor;
