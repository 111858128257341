import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getPledges';

import { GET_PLEDGES } from '../reducers/getPledges';
import ClientsApi from '../api';

function* getPledges(action: {
  type: GET_PLEDGES;
  bankInn: string;
}): Generator {
  try {
    const res: any = yield call(ClientsApi.getPledges, action.bankInn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPledgesSaga(): Generator {
  yield takeLatest(GET_PLEDGES.REQ, getPledges);
}
