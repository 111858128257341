import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/InternalAgent/model/actions/getFactoringLimits';

export interface GetFactoringLimitsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_FACTORING_LIMITS {
  REQ = 'GET_FACTORING_LIMITS_REQ',
  GOT = 'GET_FACTORING_LIMITS_GOT',
  ERR = 'GET_FACTORING_LIMITS_ERR',
  RESET = 'GET_FACTORING_LIMITS_RESET'
}

export const initialState: GetFactoringLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    companyName: '',
    hasLimit: null,
    inn: ''
  },
  error: {}
};

const getFactoringLimits = (
  state: GetFactoringLimitsStoreState = initialState,
  action: {
    type: GET_FACTORING_LIMITS;
    data: ResponseData;
    error: {};
  }
): GetFactoringLimitsStoreState => {
  switch (action.type) {
    case GET_FACTORING_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_FACTORING_LIMITS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getFactoringLimits;
