import styled from 'styled-components';
import { gray } from 'shared/styled';

export const TableHeaderStyled = styled.thead`
  height: 40px;

  tr {
    th {
      font-size: 12px;
      color: ${gray};
    }
  }
`;

