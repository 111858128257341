import * as React from 'react';

import { history } from 'src/shared/utils/History';
import { BoxStyled, SandClockIcon } from 'shared/styled';
import { Button } from 'shared/ui/Button';

interface Props {
  email: string;
}

export const RegistrationEmailFail: React.FC<Props> = ({ email }: Props) => (
  <React.Fragment>
    <h1>Этот адрес электронной почты уже используется</h1>
    <SandClockIcon />
    <BoxStyled>
      <p>
        Указанный Вами адрес электронной почты - <span>{email}</span> уже был
        ранее зарегистрирован в системе FinFactory. Для завершения процесса
        регистрации, пожалуйста, авторизуйтесь либо введите новый адрес
        электронной почты.
      </p>
      <Button
        label="Войти в личный кабинет"
        onClick={() => history.push('/login')}
      />
    </BoxStyled>
  </React.Fragment>
);
