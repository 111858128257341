import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  req as getAllDocumentForms,
  RequestData as ReqGetDocumentForms,
  ResponseData as DocumentsFormsRes
} from 'entities/Cabinet/InternalAgent/model/actions/getAllDocumentForms';
import { req as getFormFile } from 'entities/Cabinet/InternalAgent/model/actions/getInternalFormFile';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { InnPlaceholders } from 'shared/constants';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import {
  INNField,
  INNContainer,
  TDMain,
  TooltipBoxStyle,
  DownloadBtn,
  FormContainer,
  WrapperInputDetail,
  FormDataField,
  Spinner as SpinnerApi,
  FinancialInfoContainer,
  FinDataCircle
} from './styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faSpinner,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { darkBlue } from 'shared/styled';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { Modal } from 'src/features/Common';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import SCFApi from 'src/features/SCF/api';
import ApplicationApi from 'src/features/Application/api';
import InternalApi from 'entities/Cabinet/InternalAgent/api';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  documents: DocumentsFormsRes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
  companyByInn: ResponseDataType;
  statusInn: REQUEST_STATUSES;
  errorInn: ResponseError;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  getAllDocumentForms: (data?: ReqGetDocumentForms) => void;
  getFormFile: (guid: string, name: string) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FormTypes = {
  5: 'Анкета банка',
  6: 'Справка аналитика'
};

const DocumentFormsList: React.FC<Props> = ({
  getAllDocumentForms,
  documents,
  status,
  error,
  getFormFile,
  openModal,
  getCompanyByInn,
  resetStateCompanyByInn,
  companyByInn,
  statusInn,
  history
}) => {
  const [ownnerInn, setOwnerInn] = React.useState('');
  const [contractorInn, setContractorInn] = React.useState('');
  const [formType, setFormType] = React.useState(null);
  const [selectFormData, setSelectFormData] = React.useState({
    guid: '',
    name: '',
    formType: ''
  });
  const [companyInn, setCompanyInn] = React.useState('');
  const [isInfoPopup, setIsInfoPopup] = React.useState(false);
  const [
    statusPostDocumentsFormFill,
    setStatusPostDocumentsFormFill
  ] = React.useState(REQUEST_STATUSES.NONE);
  const [
    resPostDocumentsFormFill,
    setResPostDocumentsFormFill
  ] = React.useState('');
  const [isFinancialView, setIsFinancialView] = React.useState(false);
  const [isOlderFinDataView, setIsOlderFinDataView] = React.useState(false);
  const [requestStatus, setRequestStatus] = React.useState({
    companiesRequset: REQUEST_STATUSES.NONE,
    finStatementRequest1: REQUEST_STATUSES.NONE,
    finStatementRequest2: REQUEST_STATUSES.NONE,
    finStatementRequest3: REQUEST_STATUSES.NONE
  });

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const ownnerInn = searchParams.get('ownnerInn') || '';
      const contractorInn = searchParams.get('contractorInn') || '';
      const formType = +searchParams.get('formType') || 6;

      setOwnerInn(ownnerInn);
      setContractorInn(contractorInn);
      setFormType(formType);

      getAllDocumentForms({
        type: formType,
        ...(contractorInn && { counterparty: contractorInn }),
        ...(ownnerInn && { owner: ownnerInn })
      });
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      setIsInfoPopup(false);

      if (companyInn.length >= 10) {
        getCompanyByInn({ inn: companyInn });
      }
    },
    [companyInn]
  );

  React.useEffect(
    () => {
      if (statusPostDocumentsFormFill === REQUEST_STATUSES.GOT) {
        openModal({ name: MODAL_NAMES.RESPONSE_DOCUMENT_FORM });
        setIsFinancialView(false);
      }
    },
    [statusPostDocumentsFormFill]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const onPopupClick = () => {
    setIsInfoPopup(true);
  };

  const handleFillForm = async () => {
    const updateStatus = (key: string, status: REQUEST_STATUSES) => {
      setRequestStatus(prevStatuses => ({ ...prevStatuses, [key]: status }));
    };

    setIsFinancialView(true);
    setStatusPostDocumentsFormFill(REQUEST_STATUSES.REQUEST);

    try {
      updateStatus('companiesRequset', REQUEST_STATUSES.REQUEST);
      await SCFApi.getCompanyByInnThirdParty({
        inn: companyByInn.inn,
        force: true
      });
      updateStatus('companiesRequset', REQUEST_STATUSES.GOT);

      let response2: number;

      try {
        updateStatus('finStatementRequest1', REQUEST_STATUSES.REQUEST);
        await ApplicationApi.getExternalApiGIR_BO(
          companyByInn.inn,
          new Date().getFullYear() - 1
        );
        updateStatus('finStatementRequest1', REQUEST_STATUSES.GOT);
      } catch (error) {
        updateStatus('finStatementRequest1', REQUEST_STATUSES.ERROR);
        response2 = error.code;
        setIsOlderFinDataView(response2 !== 200);
      }

      try {
        updateStatus('finStatementRequest2', REQUEST_STATUSES.REQUEST);
        await ApplicationApi.getExternalApiGIR_BO(
          companyByInn.inn,
          new Date().getFullYear() - 2
        );
        updateStatus('finStatementRequest2', REQUEST_STATUSES.GOT);
      } catch (error) {
        updateStatus('finStatementRequest2', REQUEST_STATUSES.ERROR);
      }

      if (response2 !== undefined) {
        try {
          updateStatus('finStatementRequest3', REQUEST_STATUSES.REQUEST);
          await ApplicationApi.getExternalApiGIR_BO(
            companyByInn.inn,
            new Date().getFullYear() - 3
          );
          updateStatus('finStatementRequest3', REQUEST_STATUSES.GOT);
        } catch (error) {
          updateStatus('finStatementRequest3', REQUEST_STATUSES.ERROR);
        }
      }

      try {
        const res = await InternalApi.postDocumentsFormFill(
          selectFormData.guid,
          companyByInn.inn
        );
        setResPostDocumentsFormFill(res as string);
        setStatusPostDocumentsFormFill(REQUEST_STATUSES.GOT);
      } catch (error) {
        setStatusPostDocumentsFormFill(REQUEST_STATUSES.ERROR);
      }

      resetStateCompanyByInn();
      setCompanyInn('');
    } catch (e) {
      updateStatus('companiesRequset', REQUEST_STATUSES.ERROR);
      setStatusPostDocumentsFormFill(REQUEST_STATUSES.ERROR);
    }
  };

  return (
    <>
      <ApplicationTitle>Формы документов</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Select
            options={Object.keys(FormTypes).map(type => ({
              id: type,
              name: FormTypes[type]
            }))}
            value={formType}
            label="Тип формы"
            name="formType"
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Владельца формы"
            value={ownnerInn}
            name="ownnerInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Контрагента"
            value={contractorInn}
            name="contractorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">ID</TableThStyled>
                <TableThStyled width="15%">Наименование</TableThStyled>
                <TableThStyled width="15%">Тип формы</TableThStyled>
                <TableThStyled width="5%">Основная</TableThStyled>
                <TableThStyled width="10%" />
                <TableThStyled width="10%">Владелец формы</TableThStyled>
                <TableThStyled width="10%">Форма по контрагенту</TableThStyled>
                <TableThStyled width="5%" />
              </tr>
            </TableHeaderStyled>
            <tbody>
              {documents.map(doc => (
                <TableRowStyled key={doc.id}>
                  <td>{doc.guid.slice(-7)}</td>
                  <td>{doc.name}</td>
                  <td>
                    {doc.formType === 5
                      ? 'Анкета банка'
                      : doc.formType === 6 && 'Справка аналитика'}
                  </td>
                  <TDMain>
                    {doc.isMain && (
                      <TooltipBoxStyle
                        textVerify={
                          'Используется по умолчанию, если для договора поставки не установлена форма'
                        }
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </TooltipBoxStyle>
                    )}
                  </TDMain>
                  <td>
                    <DownloadBtn
                      onClick={() => getFormFile(doc.guid, doc.exampleFilename)}
                    >
                      Скачать
                    </DownloadBtn>
                  </td>
                  <td>
                    <div
                      onClick={() =>
                        window.open(`/crm/companies/${doc.ownerINN}`, '_blank')
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {doc.ownerINN}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() =>
                        window.open(
                          `/crm/companies/${doc.counterpartyInn}`,
                          '_blank'
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {doc.counterpartyInn}
                    </div>
                  </td>
                  <td
                    onClick={() => {
                      openModal({ name: MODAL_NAMES.DOCUMENT_FORM });
                      setSelectFormData({
                        guid: doc.guid,
                        name: doc.name,
                        formType:
                          doc.formType === 5
                            ? 'Анкета банка'
                            : doc.formType === 6 && 'Справка аналитика'
                      });
                    }}
                    style={{ color: darkBlue, cursor: 'pointer' }}
                  >
                    Заполнить
                  </td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>

          <Pagination list={documents} />
        </>
      )}

      <Modal name={MODAL_NAMES.DOCUMENT_FORM}>
        <FormContainer>
          <h2>Заполнение формы</h2>

          <div>
            <p>{selectFormData.name}</p>
            <p>{selectFormData.formType}</p>
          </div>

          <WrapperInputDetail>
            <Input
              label="ИНН"
              name="formInn"
              value={companyInn}
              onChange={e => setCompanyInn(e.currentTarget.value)}
              required
            />

            {companyInn && companyInn.length < 10 ? null : statusInn ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              statusInn === REQUEST_STATUSES.GOT &&
              !isInfoPopup && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={onPopupClick}
                />
              )
            )}
          </WrapperInputDetail>

          {isInfoPopup && (
            <FormDataField>
              <p>ИНН: {companyByInn.inn}</p>
              <p>Наименование: {companyByInn.companyShortName}</p>
            </FormDataField>
          )}

          {isFinancialView && (
            <FinancialInfoContainer>
              <div>
                {requestStatus.companiesRequset === REQUEST_STATUSES.REQUEST ? (
                  <SpinnerApi icon={faSpinner} />
                ) : requestStatus.companiesRequset === REQUEST_STATUSES.GOT ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="rgba(28, 202, 148, 1)"
                    size="lg"
                  />
                ) : requestStatus.companiesRequset ===
                REQUEST_STATUSES.ERROR ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="#ff0000"
                    size="lg"
                  />
                ) : (
                  <FinDataCircle />
                )}
                <p>Получение данных из ЕГРЮЛ</p>
              </div>
              <div>
                {requestStatus.finStatementRequest1 ===
                REQUEST_STATUSES.REQUEST ? (
                  <SpinnerApi icon={faSpinner} />
                ) : requestStatus.finStatementRequest1 ===
                REQUEST_STATUSES.GOT ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="rgba(28, 202, 148, 1)"
                    size="lg"
                  />
                ) : requestStatus.finStatementRequest1 ===
                REQUEST_STATUSES.ERROR ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="#ff0000"
                    size="lg"
                  />
                ) : (
                  <FinDataCircle />
                )}
                <p>
                  Получение отчетности из налоговой за{' '}
                  {new Date().getFullYear() - 1} год
                </p>
              </div>

              <div>
                {requestStatus.finStatementRequest2 ===
                REQUEST_STATUSES.REQUEST ? (
                  <SpinnerApi icon={faSpinner} />
                ) : requestStatus.finStatementRequest2 ===
                REQUEST_STATUSES.GOT ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color="rgba(28, 202, 148, 1)"
                    size="lg"
                  />
                ) : requestStatus.finStatementRequest2 ===
                REQUEST_STATUSES.ERROR ? (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="#ff0000"
                    size="lg"
                  />
                ) : (
                  <FinDataCircle />
                )}
                <p>
                  Получение отчетности из налоговой за{' '}
                  {new Date().getFullYear() - 2} год
                </p>
              </div>

              {isOlderFinDataView && (
                <div>
                  {requestStatus.finStatementRequest3 ===
                  REQUEST_STATUSES.REQUEST ? (
                    <SpinnerApi icon={faSpinner} />
                  ) : requestStatus.finStatementRequest3 ===
                  REQUEST_STATUSES.GOT ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="rgba(28, 202, 148, 1)"
                      size="lg"
                    />
                  ) : requestStatus.finStatementRequest3 ===
                  REQUEST_STATUSES.ERROR ? (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="#ff0000"
                      size="lg"
                    />
                  ) : (
                    <FinDataCircle />
                  )}
                  <p>
                    Получение отчетности из налоговой за{' '}
                    {new Date().getFullYear() - 3} год
                  </p>
                </div>
              )}
            </FinancialInfoContainer>
          )}

          <div>
            <Button
              label="Заполнить форму"
              disabled={companyInn.length < 10}
              onClick={handleFillForm}
            />
            {statusPostDocumentsFormFill === REQUEST_STATUSES.REQUEST && (
              <SpinnerApi icon={faSpinner} />
            )}
          </div>
        </FormContainer>
      </Modal>

      <Modal name={MODAL_NAMES.RESPONSE_DOCUMENT_FORM}>
        <p>{resPostDocumentsFormFill}</p>
      </Modal>

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </>
  );
};

const mapStateToProps = ({ InternalAgentRole, SCF }: STORE) => ({
  documents: InternalAgentRole.getAllDocumentsForms.data,
  status: InternalAgentRole.getAllDocumentsForms.status,
  error: InternalAgentRole.getAllDocumentsForms.error,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  errorInn: SCF.getCompanyByInnThirdParty.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllDocumentForms,
      getFormFile,
      openModal,
      getCompanyByInn,
      resetStateCompanyByInn
    },
    dispatch
  );

const DocumentFormsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentFormsList)
);

export { DocumentFormsListConnect as DocumentFormsList };
