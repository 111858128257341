import { REQUEST_STATUSES, Reducer } from 'globaltypes';
import { ResponseData } from 'src/features/SCF/actions/getQtySidebar';

export interface GetInternalCounterSidebarStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_INTERNAL_COUNTER_SIDEBAR {
  REQ = 'GET_INTERNAL_COUNTER_SIDEBAR_REQ',
  GOT = 'GET_INTERNAL_COUNTER_SIDEBAR_GOT',
  ERR = 'GET_INTERNAL_COUNTER_SIDEBAR_ERR'
}

export const initialState: GetInternalCounterSidebarStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    supplyContractsQty: null,
    newSupplyContractsQty: null,
    epaQty: null,
    epaInWorkQty: null,
    buyersToSuppliersLinksQty: null,
    newBuyersToSuppliersLinksQty: null,
    debtorUsersQty: null,
    newDebtorUsersQty: null,
    factorUsersQty: null,
    newFactorUsersQty: null,
    supplierUsersQty: null,
    registriesQty: null,
    auctionsQty: null,
    activeAuctionsQty: null,
    emptyAuctionsQty: null,
    monetaryClaimsQty: null,
    activeMonetaryClaimsQty: null
  },
  error: {}
};

const getInternalCounterSidebar = (
  state: GetInternalCounterSidebarStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetInternalCounterSidebarStoreState => {
  switch (action.type) {
    case GET_INTERNAL_COUNTER_SIDEBAR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_INTERNAL_COUNTER_SIDEBAR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_INTERNAL_COUNTER_SIDEBAR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getInternalCounterSidebar;
