import { BankAccountData } from '../actions/setBankAccount';

export interface SetBankAccountDataStoreState extends Partial<BankAccountData> { }

export enum SET_BANK_ACCOUNT_DATA {
    SET = 'SET_BANK_ACCOUNT_DATA_SET',
    RESET = 'SET_BANK_ACCOUNT_DATA_RESET',
}

export const initialState: SetBankAccountDataStoreState = {
    clientInn: null,
    bankInn: null,
    bankBik: null,
    bankName: null,
    accountNumber: null,
    correspondentAccountNumber: null,
};

const setBankAccountData = (
    state: SetBankAccountDataStoreState = initialState,
    action: {
        type: string;
        data: BankAccountData;
    }
): SetBankAccountDataStoreState => {
    switch (action.type) {
        case SET_BANK_ACCOUNT_DATA.SET:
            return {
                ...state,
                ...action.data
            };
        case SET_BANK_ACCOUNT_DATA.RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default setBankAccountData;
