import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/BankProducts/actions/getBankAccounts';

export interface GetBankAccountsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_BANK_ACCOUNTS {
  REQ = 'GET_BANK_ACCOUNTS_REQ',
  GOT = 'GET_BANK_ACCOUNTS_GOT',
  ERR = 'GET_BANK_ACCOUNTS_ERR'
}

export const initialState: GetBankAccountsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getBankAccounts = (
  state: GetBankAccountsStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetBankAccountsStoreState => {
  switch (action.type) {
    case GET_BANK_ACCOUNTS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BANK_ACCOUNTS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_BANK_ACCOUNTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBankAccounts;
