export enum SUPPLIER_LIST_TYPE {
  ALL = 'ALL'
}

export interface SupplierType {
  accessFailedCount: number;
  accountStatus: number;
  allowBlankFields: boolean;
  allowSimpleProcess: boolean;
  companyInn: string;
  createdDateTime: null;
  email: string;
  emailConfirmed: boolean;
  firstName: string;
  id: number;
  isBlocked: boolean;
  lastApplicationAt: null;
  lastLoginAt: null;
  lastName: string;
  lockoutEnd: null;
  modifiedDateTime: null;
  permissions: string[];
  phoneNumber: string;
  roles: string[];
}
