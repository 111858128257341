import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendCertificateData';

import { SEND_CERTIFICATE_DATA } from '../reducers/sendCertificateData';
import SCFApi from '../api';
import {
  CertificateData,
  resetCertificateData
} from '../actions/setCertificate';
import { req as getCertificate } from './../actions/getCertificate';

function* sendCertificateData(action: {
  type: SEND_CERTIFICATE_DATA;
  data: CertificateData;
}): Generator {
  try {
    yield call(SCFApi.sendCertificateData, action.data);
    yield put(got());
    yield put(resetCertificateData());
    yield put(getCertificate());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendCertificateDataSaga(): Generator {
  yield takeLatest(SEND_CERTIFICATE_DATA.REQ, sendCertificateData);
}
