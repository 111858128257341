import * as React from 'react';
import { history } from 'src/shared/utils/History';
import { Route } from 'react-router';
import RequisitesPage from './components/Requisites/Requisites';
import { BankAccountsCreate } from './components/Requisites/BankAccountsCreate/BankAccountsCreate';
import CertificatesPage from './components/Certificates/Certificates';
import DiscountingPage from './components/Discounting/Discounting';
import DebtorRegistrySettingsPage from './components/RegistrySettings/RegistrySettings';
import SubscriptionsPage from './components/Subscriptions/Subscriptions';
import {
  SettingsPageStyled,
  SettingsChoiceStyled,
  SettingsButtonChosen,
  SettingsButtonNotChosen
} from './styles';

class SettingsPage extends React.Component {
  RequisitesButton = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/requisites');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/requisites');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/requisites');
    }
  };

  CertificatesButton = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/certificates');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/certificates');
    }
    if (window.location.pathname.includes('factor')) {
      history.push('/factor/settings/certificates');
    }
  };

  DiscountingButton = () => {
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/discounting');
    }
  };

  FactoringButton = () => {
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/factoring');
    }
  };

  RegistriesButton = () => {
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/debtor_registry_settings');
    }
  };

  SubscriptionsButton = () => {
    if (window.location.pathname.includes('supplier')) {
      history.push('/supplier/settings/subscriptions');
    }
    if (window.location.pathname.includes('debtor')) {
      history.push('/debtor/settings/subscriptions');
    }
  };

  render() {
    return (
      <SettingsPageStyled>
        <h2>НАСТРОЙКИ</h2>
        <SettingsChoiceStyled>
          <Route
            path={'/supplier/settings/requisites'}
            render={() => (
              <SettingsButtonChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/supplier/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/certificates'}
            render={() => (
              <SettingsButtonChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/supplier/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/supplier/settings/subscriptions'}
            render={() => (
              <SettingsButtonChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonChosen>
            )}
          />

          <Route
            path={'/factor/settings/requisites'}
            render={() => (
              <SettingsButtonChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/factor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/factor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/factor/settings/certificates'}
            render={() => (
              <SettingsButtonChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonChosen>
            )}
          />

          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/requisites'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/certificates'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/discounting'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/factoring'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonChosen>
            )}
          />
          <Route
            path={'/debtor/settings/debtor_registry_settings'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RequisitesButton}>
                Реквизиты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.CertificatesButton}>
                Сертификаты
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.DiscountingButton}>
                Дисконтирование
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.FactoringButton}>
                Факторинг
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonNotChosen onClick={this.RegistriesButton}>
                Настройки реестра
              </SettingsButtonNotChosen>
            )}
          />
          <Route
            path={'/debtor/settings/subscriptions'}
            render={() => (
              <SettingsButtonChosen onClick={this.SubscriptionsButton}>
                Уведомления
              </SettingsButtonChosen>
            )}
          />
        </SettingsChoiceStyled>
        <Route
          path={'/supplier/settings/requisites'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/supplier/settings/edit'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/supplier/settings/createBankAccount'}
          render={() => <BankAccountsCreate />}
        />
        <Route
          path={'/supplier/settings/certificates'}
          render={() => <CertificatesPage />}
        />

        <Route
          path={'/supplier/settings/subscriptions'}
          render={() => <SubscriptionsPage />}
        />

        <Route
          path={'/debtor/settings/subscriptions'}
          render={() => <SubscriptionsPage />}
        />

        <Route
          path={'/factor/settings/requisites'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/factor/settings/edit'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/factor/settings/createBankAccount'}
          render={() => <BankAccountsCreate />}
        />
        <Route
          path={'/factor/settings/certificates'}
          render={() => <CertificatesPage />}
        />

        <Route
          path={'/debtor/settings/requisites'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/debtor/settings/edit'}
          render={() => <RequisitesPage />}
        />
        <Route
          path={'/debtor/settings/createBankAccount'}
          render={() => <BankAccountsCreate />}
        />
        <Route
          path={'/debtor/settings/certificates'}
          render={() => <CertificatesPage />}
        />
        <Route
          path={'/debtor/settings/discounting'}
          render={() => <DiscountingPage />}
        />
        <Route
          path={'/debtor/settings/factoring'}
          render={() => <DiscountingPage />}
        />
        <Route
          path={'/debtor/settings/debtor_registry_settings'}
          render={() => <DebtorRegistrySettingsPage />}
        />
      </SettingsPageStyled>
    );
  }
}

export default SettingsPage;
