import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faTruck,
  faUniversity,
  faPuzzlePiece
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { USER_LIST_TYPE } from 'src/features/Users/types';
import { SUPPLIER_LIST_TYPE } from 'src/features/UserSuppliers/types';
import { USER_DEBTOR_LIST_TYPE } from 'src/features/UserDebtors/types';
import { CRMComponent } from '../EachCRM';
import { SectionSeparatorStyled } from '../styles';
import { UserList } from 'src/features/Users/components/UsersList/UsersList';
import { SupplierList } from 'src/features/UserSuppliers/components/SupplierList/SupplierList';
import { UserDebtorList } from 'src/features/UserDebtors/components/UserDebtors';
import { UserFactorList } from 'src/features/UserFactors/components/UserFactorList';

function createCRMComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const UsersSection: CRMComponent[] = [
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Пользователи</SectionSeparatorStyled>
    )
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: UserList,
      path: '/crm/users/all',
      label: 'Все пользователи',
      icon: <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
      listType: USER_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: SupplierList,
      path: '/crm/users/suppliers',
      label: 'Поставщики',
      icon: <FontAwesomeIcon icon={faTruck} fixedWidth={true} />,
      listType: SUPPLIER_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: UserDebtorList,
      path: '/crm/users/debtors',
      label: 'Дебиторы',
      icon: <FontAwesomeIcon icon={faPuzzlePiece} fixedWidth={true} />,
      listType: USER_DEBTOR_LIST_TYPE.ALL
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
    ...createCRMComponent({
      Component: UserFactorList,
      path: '/crm/users/factors',
      label: 'Факторы',
      icon: <FontAwesomeIcon icon={faUniversity} fixedWidth={true} />,
      listType: USER_LIST_TYPE.ALL
    })
  }
];
