import * as React from 'react';
import { format, parse } from 'date-fns';
import { FormattedApplicationObj } from 'Application/components';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { APPLICATION_STATUSES } from 'src/features/Application/types';
import { USER_PERMISSIONS } from 'src/globaltypes';
import { history } from 'shared/utils/History';

interface Props {
  list: FormattedApplicationObj[];
  permissions: USER_PERMISSIONS[];
  onApplicationClick: (id: string, applicationType: string) => void;
}

export const ApplicationsTableView: React.FC<Props> = ({
  onApplicationClick,
  list,
  permissions
}) => {
  const [showState, setShowState] = React.useState(
    list.map(item => ({ id: item.id, expanded: false }))
  );

  const listItemNotNull = identificator => {
    for (const item of list) {
      if (item[identificator] != null) return true;
    }
    return false;
  };

  const getHighlightColorDependingOn = (status: APPLICATION_STATUSES) => {
    switch (status) {
      case APPLICATION_STATUSES.IN_WORK:
        if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT))
          return '#d0e9ff';
        break;
      case APPLICATION_STATUSES.AWAITING:
        if (
          !permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
          permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS)
        )
          return '#fff0de';
        break;
    }
  };

  return (
    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
      <TableHeaderStyled>
        <tr>
          {!permissions.includes(USER_PERMISSIONS.BANK_MANAGER) && (
            <TableThStyled width="2%" />
          )}
          <TableThStyled
            width={
              history.location.pathname.includes('/cabinet/api_apps')
                ? '20%'
                : '5%'
            }
          >
            Id
          </TableThStyled>
          <TableThStyled width="25%">Клиент</TableThStyled>
          <TableThStyled width="20%">Тип финансирования</TableThStyled>
          <TableThStyled width="10%">Сумма</TableThStyled>
          <TableThStyled width="10%">Дата изменения</TableThStyled>
          <TableThStyled width="10%">Статус</TableThStyled>
          {listItemNotNull('client') && (
            <TableThStyled>Инициатор</TableThStyled>
          )}
          {listItemNotNull('internalAgent') && (
            <TableThStyled>Сопроводит заявку</TableThStyled>
          )}
        </tr>
      </TableHeaderStyled>
      <tbody>
        {list.map((item: FormattedApplicationObj) => (
          <React.Fragment key={item.id}>
            <TableRowStyled
              isExpanded={showState.find(el => el.id === item.id).expanded}
              canExpand={
                item.bankApplications
                  ? item.bankApplications.length > 0
                  : undefined
              }
              background={getHighlightColorDependingOn(
                item.status.props.status
              )}
              onClick={() =>
                history.location.pathname.includes('/cabinet/api_apps')
                  ? null
                  : onApplicationClick(item.id, item.financingType)
              }
            >
              {!permissions.includes(USER_PERMISSIONS.BANK_MANAGER) && (
                <td>
                  <p />
                </td>
              )}
              <td>{item.id}</td>
              <td>{item.companyName}</td>
              <td>{item.financingType}</td>
              <td>{item.amount}</td>
              <td>
                {item.modifiedAt &&
                  format(
                    parse(item.modifiedAt, 'dd/MM/yy', new Date()),
                    'dd.MM.yyyy'
                  )}
              </td>
              <td>{item.status}</td>
              {item.client && <td>{item.client}</td>}
              {item.internalAgent && <td>{item.internalAgent}</td>}
            </TableRowStyled>
            {item.bankApplicationsFormatted &&
              item.bankApplicationsFormatted.map(
                bank =>
                  showState.find(el => el.id === item.id).expanded && (
                    <TableRowStyled key={bank.id}>
                      <td />
                      <td>{bank.id}</td>
                      <td>{bank.name}</td>
                      <td />
                      <td>{bank.amount}</td>
                      <td>{bank.modifiedAt}</td>
                      <td>{bank.status}</td>
                      {item.client && <td />}
                      {item.internalAgent && <td />}
                    </TableRowStyled>
                  )
              )}
          </React.Fragment>
        ))}
      </tbody>
    </TableStyled>
  );
};
