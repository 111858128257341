import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getPlatforms';

export interface GetPlatformsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_PLATFORMS {
  REQ = 'GET_PLATFORMS_REQ',
  GOT = 'GET_PLATFORMS_GOT',
  ERR = 'GET_PLATFORMS_ERR'
}

export const initialState: GetPlatformsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getPlatforms = (
  state: GetPlatformsStoreState = initialState,
  action: {
    type: string;
    product: ResponseData;
    error: {};
  }
): GetPlatformsStoreState => {
  switch (action.type) {
    case GET_PLATFORMS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PLATFORMS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.product
      };
    case GET_PLATFORMS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPlatforms;
