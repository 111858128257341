import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface ExternalAgentStatusStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  status: EXTERNAL_AGENT_STATUSES;
}

export enum EXTERNAL_AGENT_STATUSES {
  NONE = '',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum EXTERNAL_AGENT_STATUS_ACTIONS {
  REQ = 'EXTERNAL_AGENT_STATUS_REQ',
  GOT = 'EXTERNAL_AGENT_STATUS_GOT',
  ERR = 'EXTERNAL_AGENT_STATUS_ERR'
}

export const initialState: ExternalAgentStatusStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    status: EXTERNAL_AGENT_STATUSES.NONE
  },
  error: {}
};

const externalAgentStatus = (
  state: ExternalAgentStatusStoreState = initialState,
  action: {
    type: EXTERNAL_AGENT_STATUS_ACTIONS;
    data: ResponseData;
    error: {};
  }
): ExternalAgentStatusStoreState => {
  switch (action.type) {
    case EXTERNAL_AGENT_STATUS_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case EXTERNAL_AGENT_STATUS_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case EXTERNAL_AGENT_STATUS_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default externalAgentStatus;
