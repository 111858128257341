import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import { ErrorIcon, SuccessIcon } from 'shared/styled';
import { russiaPhoneMask } from 'shared/utils/InputMasks';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { createValidation } from './validator';
import { BankSelect } from './BankSelect/BankSelect';
import {
  req as addBankManager,
  RequestData
} from '../../actions/addBankManager';
import { ActionResponseBlock, Loader } from 'src/features/Layouts/components';
import { Button } from 'shared/ui/Button';

export interface BankManagerFormFields {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  bankId: string;
}

export interface State {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  bankId: string;
  submitClicked: boolean;
}

export interface StateToProps {
  addBankManagerStatus: REQUEST_STATUSES;
  addBankManagerError: ResponseError;
}

export interface DispatchToProps {
  addBankManager: (data: RequestData) => void;
}

type Props = StateToProps & DispatchToProps;

class BankManagerForm extends React.Component<Props, State> {
  Validator = createValidation();
  initialState: State = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    bankId: '19',
    submitClicked: false
  };

  state: State = this.initialState;

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    this.setState({
      [name]: value
    } as { [key in keyof BankManagerFormFields]: string });
  };

  // TODO resettings state triggers validation errors
  resetState() {
    this.setState(this.initialState);
  }

  renderStatus() {
    if (!this.state.submitClicked) return;

    if (this.props.addBankManagerStatus === REQUEST_STATUSES.REQUEST)
      return <Loader />;

    if (this.props.addBankManagerStatus === REQUEST_STATUSES.ERROR) {
      return this.renderError();
    }

    if (this.props.addBankManagerStatus === REQUEST_STATUSES.GOT) {
      return this.renderSuccess();
    }
  }

  renderError() {
    switch (this.props.addBankManagerError.code) {
      case 666:
        return (
          <ActionResponseBlock
            title="Email Уже существует"
            icon={<ErrorIcon />}
            button={
              <Button
                template="nextBtn"
                label="Попробовать другой"
                onClick={this.resetState}
              />
            }
          />
        );
      default:
        return (
          <ActionResponseBlock
            title="Ошибка сервера"
            button={
              <Button
                template="nextBtn"
                label="Попробовать еще раз"
                onClick={this.resetState}
              />
            }
            icon={<ErrorIcon />}
          />
        );
    }
  }

  renderSuccess() {
    return (
      <ActionResponseBlock
        title={`${this.state.email} Успешно добавлен`}
        button={
          <Button
            template="nextBtn"
            label="Добавить еще"
            onClick={this.resetState}
          />
        }
        icon={<SuccessIcon />}
      />
    );
  }

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }
    this.props.addBankManager(this.state);
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      bankId,
      submitClicked
    } = this.state;
    const { errors } = this.Validator.validate(this.state);
    return (
      <div>
        {!submitClicked && (
          <Form onSubmit={this.onSubmit}>
            <FormSection>
              <Input
                value={email}
                name="email"
                placeholder="Email"
                label="Email"
                required={true}
                error={errors.email}
                onChange={this.onChange}
              />
              <Input
                value={firstName}
                label="Ваше имя"
                name="firstName"
                placeholder="Имя"
                required={true}
                error={errors.firstName}
                onChange={this.onChange}
              />
              <Input
                value={lastName}
                label="Ваша фамилия"
                name="lastName"
                placeholder="Фамилия"
                required={true}
                error={errors.lastName}
                onChange={this.onChange}
              />
              <Input
                type="tel"
                value={phoneNumber}
                error={errors.phoneNumber}
                label="Номер телефона"
                name="phoneNumber"
                placeholder="+7(___)___-__-__"
                mask={russiaPhoneMask}
                required={true}
                onChange={this.onChange}
              />
              <BankSelect
                name="bankId"
                required={true}
                value={bankId.toString()}
                onChange={this.onChange}
              />
            </FormSection>
            <Button
              template="nextBtn"
              type="submit"
              label="Создать"
              disabled={!this.Validator.isFormValid()}
            />
          </Form>
        )}
        {this.renderStatus()}
      </div>
    );
  }
}

const mapStateToProps = ({ Permissions }: STORE) => ({
  addBankManagerError: Permissions.addBankManager.error,
  addBankManagerStatus: Permissions.addBankManager.status
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addBankManager }, dispatch);

const BankManagerFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(BankManagerForm);

export { BankManagerFormConnect as BankManagerForm };
