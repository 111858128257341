import styled from 'styled-components';
import { rl } from 'shared/styled';

export const NegativeValue = styled.p`
  margin: 0;
  color: ${({ isNegative }) => (isNegative ? '#F53737' : '')};
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;
