import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface PostCreateTenderExternalStoreState extends Reducer {}

export enum POST_CREATE_TENDER_EXTERNAL {
  REQ = 'POST_CREATE_TENDER_EXTERNAL_REQ',
  GOT = 'POST_CREATE_TENDER_EXTERNAL_GOT',
  ERR = 'POST_CREATE_TENDER_EXTERNAL_ERR'
}

export const initialState: PostCreateTenderExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postCreateTenderExternal = (
  state: PostCreateTenderExternalStoreState = initialState,
  action: {
    type: string;
    data: POST_CREATE_TENDER_EXTERNAL;
    error: {};
  }
): PostCreateTenderExternalStoreState => {
  switch (action.type) {
    case POST_CREATE_TENDER_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case POST_CREATE_TENDER_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };

    case POST_CREATE_TENDER_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default postCreateTenderExternal;
