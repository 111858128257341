import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface SendToBankDataStoreState extends Reducer {}

export enum PUT_SEND_TO_BANK {
  REQ = 'PUT_SEND_TO_BANK_REQ',
  GOT = 'PUT_SEND_TO_BANK_GOT',
  ERR = 'PUT_SEND_TO_BANK_ERROR',
  RESET = 'PUT_SEND_TO_BANK_RESET'
}

export const initialState: SendToBankDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putSendToBank = (
  state: SendToBankDataStoreState = initialState,
  action: {
    type: PUT_SEND_TO_BANK;
    error: {};
  }
): SendToBankDataStoreState => {
  switch (action.type) {
    case PUT_SEND_TO_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_SEND_TO_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_SEND_TO_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PUT_SEND_TO_BANK.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default putSendToBank;
