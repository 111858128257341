import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchTenderUpdateByInternalStoreState extends Reducer {}

export enum PATCH_TENDER_UPDATE_BY_INTERNAL {
  REQ = 'PATCH_TENDER_UPDATE_BY_INTERNAL_REQ',
  GOT = 'PATCH_TENDER_UPDATE_BY_INTERNAL_GOT',
  ERR = 'PATCH_TENDER_UPDATE_BY_INTERNAL_ERR'
}

const initialState: PatchTenderUpdateByInternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchTenderUpdateByInternal = (
  state: PatchTenderUpdateByInternalStoreState = initialState,
  action: {
    type: PATCH_TENDER_UPDATE_BY_INTERNAL;
    error: {};
  }
): PatchTenderUpdateByInternalStoreState => {
  switch (action.type) {
    case PATCH_TENDER_UPDATE_BY_INTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_TENDER_UPDATE_BY_INTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_TENDER_UPDATE_BY_INTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR
      };
    default:
      return state;
  }
};

export default patchTenderUpdateByInternal;
