import { FactoringLimitsData } from '../actions/setFactoringLimitFormData';

export interface SetFactoringLimitDataStoreState extends Partial<FactoringLimitsData> {}

export enum SET_FACTORING_LIMIT_DATA {
  SET = 'SET_FACTORING_LIMIT_DATA_SET',
  RESET = 'SET_FACTORING_LIMIT_DATA_RESET',
}

export const initialState: SetFactoringLimitDataStoreState = {
  //
};

const setFactoringLimitFormData = (
  state: SetFactoringLimitDataStoreState = initialState,
  action: {
    type: string;
    data: FactoringLimitsData;
  }
): SetFactoringLimitDataStoreState => {
  switch (action.type) {
    case SET_FACTORING_LIMIT_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_FACTORING_LIMIT_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setFactoringLimitFormData;
