import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getApplicationsQuantity';
import { GET_APPLICATIONS_QUANTITY } from 'Application/reducers/getApplicationsQuantity';

import Application from 'Application/api';

function* getApplicationsQuantity(): Generator {
  try {
    const res:any = yield call(Application.getApplicationsQuantity);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationsQuantitySaga(): Generator {
  yield takeLatest(GET_APPLICATIONS_QUANTITY.REQ, getApplicationsQuantity);
}
