import styled from 'styled-components';
import { Label } from 'shared/ui/Label';
import { rm, navyBlue } from 'shared/styled';

export const CommentsListStyled = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CommentsListLabel = styled(Label)`
  margin-bottom: 30px;
  font-family: ${rm};
  font-size: 1.6rem;
  color: ${navyBlue};
`;
