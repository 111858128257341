import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type UploadRegistriesStoreState = Reducer;

export interface RequestDataType {
  file: FormData;
  metaInfo: string;
}

export interface SagaRequestDataType {
  files: any[];
}

export enum UPLOAD_REGISTRIES {
  REQ = 'UPLOAD_REGISTRIES_REQUEST',
  GOT = 'UPLOAD_REGISTRIES_GOT',
  ERR = 'UPLOAD_REGISTRIES_ERROR'
}

export const initialState: UploadRegistriesStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const uploadRegistries = (
  state: UploadRegistriesStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): UploadRegistriesStoreState => {
  switch (action.type) {
    case UPLOAD_REGISTRIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPLOAD_REGISTRIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case UPLOAD_REGISTRIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default uploadRegistries;
