import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getSubsidaries';

import { GET_SUBSIDARIES_CLIENT } from '../reducers/getSubsidariesClient';
import ClientsApi from '../api';

function* getSubsidariesClient(action: {
  type: GET_SUBSIDARIES_CLIENT;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(ClientsApi.getSubsidaries, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSubsidariesClientSaga(): Generator {
  yield takeLatest(GET_SUBSIDARIES_CLIENT.REQ, getSubsidariesClient);
}
