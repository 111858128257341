import { CONFIRM_EXTERNAL_AGENT } from '../reducers/confirmExternalAgent';
import { ExternalAgentObj } from '../actions/getExternalAgentsList';

export interface RequestDataType {
  id: number;
  status: string;
}

export type ResponseDataType = ExternalAgentObj;

export const req = (data: RequestDataType) => ({
  type: CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_GOT,
  data
});

export const err = (error: {}) => ({
  type: CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_ERR,
  error
});
