import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_DOWNLOAD_DEBTOR_REGISTRIES,
  err
} from 'entities/SCF/Internal/model/actions/getDownloadDebtorRegistries';

function* getDownloadDebtorRegistries(action: {
  type: GET_DOWNLOAD_DEBTOR_REGISTRIES;
  id: number;
}): Generator {
  try {
    const url = `/api/debtor_registries/${action.id}/download_registry_file`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDownloadDebtorRegistriesSaga(): Generator {
  yield takeLatest(
    GET_DOWNLOAD_DEBTOR_REGISTRIES.REQ,
    getDownloadDebtorRegistries
  );
}
