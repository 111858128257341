import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCompaniesList';
import { GET_COMPANIES_WITH_FILTERS_LIST } from '../reducers/getCompaniesList';
import CompaniesApi from '../api';

function* getCompaniesWithFilters(action: {
  type: GET_COMPANIES_WITH_FILTERS_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(CompaniesApi.getAllCompaniesWithFilter, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesWithFiltersListSaga(): Generator {
  yield takeLatest(
    GET_COMPANIES_WITH_FILTERS_LIST.REQ,
    getCompaniesWithFilters
  );
}
