import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import { history } from 'src/shared/utils/History';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import {
  ConatinerInputs,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes,
  WrapperInput,
  WrapperInputPlatform
} from 'src/features/ExternalCredentials/components/NewExternalCredential/styled';
import {
  setExternalCredentialFormData,
  ExternalCredentialsData,
  resetExternalCredentialFormData
} from 'src/features/ExternalCredentials/actions/setExternalCredentialFormData';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  ResponseDataType as PlatformInnResponseData,
  req as getCompanyByPlatformInn,
  reset as resetStateCompanyByInnPlatform,
  RequestDataType as CompanyByInnRequestData
} from 'src/features/ExternalCredentials/actions/getCompanyByInn';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  createStatus: REQUEST_STATUSES;
  companyByInnPlatform: PlatformInnResponseData;
  statusCompanyByInnPlatform: REQUEST_STATUSES;
  errorCompanyByInnPlatform: ResponseError;
}

interface State {
  platformName: string;
  operatesPlatformName: string;
  ourCompanyInn: string;
  ourCompany: string;
  platformInn: string;
  website: string;
  login: string;
  password: string;
  comment: string;
  isBankPlatform: boolean | null;
  isCompetitorPlatform: boolean | null;
  isInfoPopupOurCompany: boolean;
  isInfoPopupPlatform: boolean;
  errors: {
    platformName: string;
    ourCompanyInn: string;
    ourCompany: string;
    platformInn: string;
    website: string;
    login: string;
    password: string;
    comment: string;
  };
}

interface MatchParams {}

interface DispatchToProps {
  setExternalCredentialFormData: (data: ExternalCredentialsData) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetExternalCredentialFormData: () => void;
  resetStateCompanyByInn: () => void;
  getCompanyByPlatformInn: (data: CompanyByInnRequestData) => void;
  resetStateCompanyByInnPlatform: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class NewExternalCredential extends React.Component<Props, State> {
  state: State = {
    platformName: '',
    operatesPlatformName: '',
    ourCompanyInn: '',
    ourCompany: '',
    platformInn: '',
    website: '',
    login: '',
    password: '',
    comment: '',
    isBankPlatform: null,
    isCompetitorPlatform: null,
    isInfoPopupOurCompany: false,
    isInfoPopupPlatform: false,
    errors: {
      platformName: '',
      ourCompanyInn: '',
      ourCompany: '',
      platformInn: '',
      website: '',
      login: '',
      password: '',
      comment: ''
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetExternalCredentialFormData();
      history.goBack();
    }
    if (prevState.ourCompanyInn !== this.state.ourCompanyInn) {
      this.setState({ isInfoPopupOurCompany: false });
    }
    if (prevState.platformInn !== this.state.platformInn) {
      this.setState({ isInfoPopupPlatform: false });
    }
    if (
      prevState.ourCompanyInn !== this.state.ourCompanyInn &&
      this.state.ourCompanyInn.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.ourCompanyInn });
    }
    if (
      prevState.platformInn !== this.state.platformInn &&
      this.state.platformInn.length >= 10
    ) {
      this.props.getCompanyByPlatformInn({ inn: this.state.platformInn });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopupOurCompany !== this.state.isInfoPopupOurCompany
    ) {
      this.setState({ ourCompany: this.props.companyByInn.companyShortName });
    }
    if (
      this.props.statusCompanyByInnPlatform === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopupPlatform !== this.state.isInfoPopupPlatform
    ) {
      this.setState({
        operatesPlatformName: this.props.companyByInnPlatform.companyShortName
      });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
    this.props.resetStateCompanyByInnPlatform();
  }

  handleInputChange = (name: keyof State, value: string | boolean) => {
    this.setState({ [name]: value } as any);
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          type={'button'}
          onClick={() => this.handleInputChange(buttonName, false)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          type={'button'}
          onClick={() => this.handleInputChange(buttonName, true)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  handleCreateCredential = e => {
    e.preventDefault();

    const innRegex = /^[a-zA-Z0-9]+$/;

    const errors: State['errors'] = {
      platformName: '',
      ourCompanyInn: '',
      ourCompany: '',
      platformInn: '',
      website: '',
      login: '',
      password: '',
      comment: ''
    };

    if (
      this.state.platformInn !== null &&
      !innRegex.test(this.state.platformInn)
    ) {
      errors.platformInn = 'Введите корректный ИНН';
    }

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, ...data } = this.state;
      this.props.setExternalCredentialFormData(data);
    }
  };

  onPopupClick = (name: keyof State) => {
    this.setState({ [name]: true } as any);
  };

  render() {
    const {
      companyByInn,
      statusInn,
      companyByInnPlatform,
      statusCompanyByInnPlatform
    } = this.props;
    return (
      <ScrollTopComponent>
        <ApplicationTitle>Создание доступа к площадке</ApplicationTitle>

        <ConatinerInputs onSubmit={this.handleCreateCredential}>
          <WrapperInput>
            <p>Название площадки:</p>
            <Input
              name="platformName"
              value={this.state.platformName}
              onChange={e =>
                this.handleInputChange('platformName', e.currentTarget.value)
              }
            />
          </WrapperInput>

          <p>Наша компания:</p>
          <WrapperInput>
            <p>ИНН:</p>
            <Input
              name="ourCompanyInn"
              value={this.state.ourCompanyInn}
              onChange={e =>
                this.handleInputChange('ourCompanyInn', e.currentTarget.value)
              }
            />

            {this.state.ourCompanyInn &&
            this.state.ourCompanyInn.length < 10 ? null : statusInn ===
            REQUEST_STATUSES.ERROR ? (
              <InnPopup>
                <h2>
                  Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                  создать связку с введенным ИНН
                </h2>
              </InnPopup>
            ) : (
              statusInn === REQUEST_STATUSES.GOT &&
              !this.state.isInfoPopupOurCompany && (
                <InnPopup
                  companyInfo={companyByInn}
                  onPopupClick={this.onPopupClick}
                  name="isInfoPopupOurCompany"
                />
              )
            )}
          </WrapperInput>

          <WrapperInput>
            <p>Наименование:</p>
            <Input
              name="ourCompany"
              value={this.state.ourCompany}
              onChange={e =>
                this.handleInputChange('ourCompany', e.currentTarget.value)
              }
            />
          </WrapperInput>

          <p>Оперирует платформой:</p>
          <WrapperInput>
            <WrapperInputPlatform>
              <div>
                <p>ИНН:</p>
                <Input
                  name="platformInn"
                  value={this.state.platformInn}
                  onChange={e =>
                    this.handleInputChange('platformInn', e.currentTarget.value)
                  }
                />
              </div>

              {this.state.platformInn &&
              this.state.platformInn.length <
                10 ? null : statusCompanyByInnPlatform ===
              REQUEST_STATUSES.ERROR ? (
                <InnPopup>
                  <h2>
                    Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                    можете создать связку с введенным ИНН
                  </h2>
                </InnPopup>
              ) : (
                statusCompanyByInnPlatform === REQUEST_STATUSES.GOT &&
                !this.state.isInfoPopupPlatform && (
                  <InnPopup
                    companyInfo={companyByInn}
                    onPopupClick={this.onPopupClick}
                    name="isInfoPopupPlatform"
                  />
                )
              )}

              <div>
                <p>Наименование:</p>
                <Input
                  name="operatesPlatformName"
                  disabled
                  value={this.state.operatesPlatformName}
                />
              </div>
              {this.renderToggleButton('isBankPlatform', 'Принадлежит банку')}
              {this.renderToggleButton(
                'isCompetitorPlatform',
                'Принадлежит конкуренту'
              )}
            </WrapperInputPlatform>
          </WrapperInput>

          <p>Доступ:</p>
          <WrapperInput>
            <p>Ссылка:</p>
            <Input
              name="website"
              value={this.state.website}
              onChange={e =>
                this.handleInputChange('website', e.currentTarget.value)
              }
            />
          </WrapperInput>
          <WrapperInput>
            <p>Логин:</p>
            <Input
              name="login"
              value={this.state.login}
              onChange={e =>
                this.handleInputChange('login', e.currentTarget.value)
              }
            />
          </WrapperInput>
          <WrapperInput>
            <p>Пароль:</p>
            <Input
              name="password"
              value={this.state.password}
              onChange={e =>
                this.handleInputChange('password', e.currentTarget.value)
              }
            />
          </WrapperInput>

          <WrapperInput>
            <div>
              <p>Комментарий:</p>
              <span>Дополнительная информация при необходимости</span>
            </div>
            <Textarea
              name="comment"
              value={this.state.comment}
              onChange={e =>
                this.handleInputChange('comment', e.currentTarget.value)
              }
            />
          </WrapperInput>

          <Button label="Создать" type="submit" />
        </ConatinerInputs>
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ SCF, ExternalCredentials }: STORE & CRM) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  createStatus: ExternalCredentials.setExternalCredentialFormData.status,
  companyByInnPlatform: ExternalCredentials.getCompanyByInn.data,
  statusCompanyByInnPlatform: ExternalCredentials.getCompanyByInn.status,
  errorCompanyByInnPlatform: ExternalCredentials.getCompanyByInn.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setExternalCredentialFormData,
      getCompanyByInn,
      resetStateCompanyByInn,
      resetExternalCredentialFormData,
      getCompanyByPlatformInn,
      resetStateCompanyByInnPlatform
    },
    dispatch
  );

const NewExternalCredentialConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewExternalCredential)
);

export { NewExternalCredentialConnect as NewExternalCredential };
