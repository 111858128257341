import { ResponseBankAccount } from 'src/features/BankProducts/types';
import { GET_BANK_ACCOUNTS } from '../reducers/getBankAccounts';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ResponseBankAccount[];
}

export const req = (inn: string) => ({
  type: GET_BANK_ACCOUNTS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_BANK_ACCOUNTS.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_BANK_ACCOUNTS.ERR,
  err
});
