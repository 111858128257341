import {
  RequestDataType,
  ResponseDataType,
  GET_APPLICATION
} from 'Application/reducers/getApplication';

export const req = (data: RequestDataType) => ({
  type: GET_APPLICATION.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATION.ERR,
  error
});

export const reset = () => ({
  type: GET_APPLICATION.RESET
});
