import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { format } from 'date-fns';

import { history } from 'src/shared/utils/History';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getSupplyContracts,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getSupplyContracts';
import {
  ContractsPageStyled,
  CreateBtn,
  CreateBtnText,
  InnFilterArea,
  InnFilter,
  InnContainer,
  ListSection,
  PaginationWrapper,
  FullList
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import 'src/features/SCF/UI/simpleCSS/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  supplyContracts: ResponseDataType;
}

interface DispatchToProps {
  getSupplyContracts: (data: RequestDataType) => void;
}

interface State {
  pageUrl: Href;
  debtorInnToFilter: string;
  updateChecker: ResponseDataType;
  contractGuid: string;
}

type Props = StateToProps & DispatchToProps;

class ContractsList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        debtorInnToFilter: '',
        updateChecker: undefined,
        contractGuid: ''
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleDebtorInnToFilterChange = this.handleDebtorInnToFilterChange.bind(
        this
      );
    }
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  handleDebtorInnToFilterChange(event) {
    this.setState({ debtorInnToFilter: event.target.value });
  }

  initFetch = () => {
    const { getSupplyContracts } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.debtorInnToFilter == ''
        ? getSupplyContracts({ page, pageSize: 10 })
        : getSupplyContracts({
            page: 1,
            DebtorInnToFilterBy: this.state.debtorInnToFilter
          });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.debtorInnToFilter !== prevState.debtorInnToFilter ||
      this.state.updateChecker !== prevState.updateChecker
    ) {
      this.initFetch();
    }
  }

  CreateContractsClick = () => {
    history.push('/supplier/supply_contracts/add');
  };

  onRawClick = (guid: string) => {
    const { getSupplyContracts } = this.props;
    this.setState({ contractGuid: guid });
    history.push(`/supplier/supply_contracts/${guid}`);
  };

  render() {
    const { status, supplyContracts, error } = this.props;
    return (
      <ContractsPageStyled>
        <CreateLimitsNudge>
          <h2>Договоры поставки</h2>
          <CreateBtn onClick={this.CreateContractsClick}>
            <CreateBtnText>+ Создать</CreateBtnText>
          </CreateBtn>
        </CreateLimitsNudge>
        <InnFilterArea>
          <InnFilter>
            <p>ИНН Дебитора</p>
            <InnContainer>
              <input
                type="text"
                placeholder="XXXXXXXXXX"
                maxLength={10}
                onChange={this.handleDebtorInnToFilterChange}
                className="input-inn"
              />
            </InnContainer>
          </InnFilter>
        </InnFilterArea>

        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="20%">
                      ИНН ДЕБИТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="45%">
                      НАИМЕНОВАНИЕ ДЕБИТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="35%">
                      РЕКВИЗИТЫ ДОГОВОРА
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {supplyContracts.items.map((item, key) => (
                    <TableRowStyled
                      key={key}
                      className="table-row"
                      onClick={() => this.onRawClick(item.id)}
                    >
                      <td>{item.debtorInn}</td>
                      <td>{item.debtorName}</td>
                      {item.contractNumber !== '' ||
                      item.contractStartDate !== '' ? (
                        <td>
                          № {item.contractNumber} от{' '}
                          {format(
                            new Date(item.contractStartDate),
                            'dd.MM.yyyy'
                          )}
                        </td>
                      ) : (
                        <td />
                      )}
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
        </ListSection>
        <PaginationWrapper onClick={this.handlePageChange}>
          <Pagination list={this.props.supplyContracts} />
        </PaginationWrapper>
      </ContractsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  supplyContracts: SCF.getSupplyContracts.data,
  status: SCF.getSupplyContracts.status,
  error: SCF.getSupplyContracts.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplyContracts
    },
    dispatch
  );

const ContractsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ContractsList);

export { ContractsListConnect as ContractsList };
