import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getCompany';

import {
  GET_COMPANY,
} from '../reducers/getCompany';
import CompaniesApi from '../api';

function* getCompany(action: {
  type: GET_COMPANY;
  inn: string
}): Generator {
  try {

    const res:any = yield call(CompaniesApi.getCompany, action.inn);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanySaga(): Generator {
  yield takeLatest(GET_COMPANY.REQ, getCompany);
}
