import * as React from 'react';
import { connect } from 'react-redux';
import {
  STORE,
  ResponseError,
  REQUEST_STATUSES,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { LeadStatuses, ApplicationTypes } from 'shared/constants';
import { Dispatch, bindActionCreators } from 'redux';
import { format, parse } from 'date-fns';
import { LEAD_LIST_TYPE, LEAD_STATUSES } from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getLeadsList,
  ResponseData as getLeadsListResponseData,
  RequestData as getLeadsListRequestData
} from '../../actions/getLeadsList';
import { history } from 'src/shared/utils/History';
import { TransferApplicationBtn } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ApplicationStatus/styles';
import { LeadStatusStyled } from '../LeadView/styled';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as changeLeadStatus,
  RequestData as changeLeadStatusRequestData
} from '../../actions/changeLeadStatus';
import { LeadRead } from '../../actions/setLeadFormData';
import ContactPopup from './ContactPopup/ContactPopup';
import { formSumString } from 'src/shared/utils/Utils';
import { TypeFilter } from 'src/features/Filters/TypeFilter/components/TypeFilter';
import { INNFilter } from 'src/features/Filters/INNFilter/components/INNFilter';
import {
  ApplicationChecklist,
  BgParametsChecklist,
  BkiChecklist,
  ChecklistContainer,
  Content,
  DealChecklist,
  DocChecklist,
  FilterWrapper,
  TDChecklist
} from 'src/features/Leads/components/LeadsList/styled';
import { DateFilter } from 'src/features/Filters/DateFilter/components/DateFilter';
import { updateINNFilter } from 'src/features/Filters/INNFilter/actions/updateINNFilter';
import {
  updateDateFromFilter,
  updateDateUntilFilter
} from 'src/features/Filters/DateFilter/actions/updateDateFilter';
import { addTypeFilter } from 'src/features/Filters/TypeFilter/actions/updateTypeFilter';
import { NameClientFilter } from 'src/features/Filters/NameClientFilter/components/NameClientFilter';
import { updateClientNameFilter } from 'src/features/Filters/NameClientFilter/actions/updateNameClientFilter';
import { StatusFilter } from 'src/features/Filters/Status/components/Status';
import { addStatusFilter } from 'src/features/Filters/Status/actions/updateStatusFilter';

interface StateToProps {
  leads: getLeadsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  inn: string;
  typesToFilterBy: string[];
  dateFrom: string;
  dateUntil: string;
  clientName: string;
  statusType: string[];
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getLeadsList: (
    listType: LEAD_LIST_TYPE,
    data: getLeadsListRequestData
  ) => void;
  changeLeadStatus: (data: changeLeadStatusRequestData) => void;
  updateINNFilter: (inn: string) => void;
  updateDateFromFilter: (dateFrom: string) => void;
  updateDateUntilFilter: (dateUntil: string) => void;
  addTypeFilter: (type: string) => void;
  updateClientNameFilter: (clientName: string) => void;
  addStatusFilter: (status: string) => void;
}

interface OwnProps {
  listType: LEAD_LIST_TYPE;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class LeadsList extends React.Component<Props> {
  componentDidMount() {
    const { listType, getLeadsList } = this.props;

    // TODO duplication with applicationslist
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    urlParams.forEach((val, key) => {
      if (key === 'clientInn') {
        this.props.updateINNFilter(val);
      }
      if (key === 'dateFrom') {
        this.props.updateDateFromFilter(val);
      }
      if (key === 'dateUntil') {
        this.props.updateDateUntilFilter(val);
      }
      if (key === 'type') {
        const elements = val.split(',');
        const allElementsExist = elements.every(element =>
          this.props.typesToFilterBy.includes(element)
        );

        if (!allElementsExist) {
          val.split(',').forEach(item => {
            this.props.addTypeFilter(item);
          });
        }
      }
      if (key === 'clientName') {
        this.props.updateClientNameFilter(val);
      }
      if (key === 'status') {
        const elements = val.split(',');
        const allElementsExist = elements.every(element =>
          this.props.statusType.includes(element)
        );

        if (!allElementsExist) {
          val.split(',').forEach(item => {
            this.props.addStatusFilter(item);
          });
        }
      }
    });

    getLeadsList(listType, {
      page,
      pageSize: 20,
      companyInn: this.props.inn ? this.props.inn : undefined,
      dateFrom: this.props.dateFrom
        ? format(
            parse(this.props.dateFrom, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        : undefined,
      dateUntil: this.props.dateUntil
        ? format(
            parse(this.props.dateUntil, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        : undefined,
      product: this.props.typesToFilterBy.length
        ? this.props.typesToFilterBy.join(',')
        : undefined,
      clientName: this.props.clientName ? this.props.clientName : undefined,
      status: this.props.statusType.length
        ? this.props.statusType.join(',')
        : undefined
    });
  }

  componentDidUpdate(prevProps: Props, prevState: any) {
    if (
      prevProps.inn !== this.props.inn ||
      prevProps.typesToFilterBy !== this.props.typesToFilterBy ||
      prevProps.dateFrom !== this.props.dateFrom ||
      prevProps.dateUntil !== this.props.dateUntil ||
      prevProps.clientName !== this.props.clientName ||
      prevProps.statusType !== this.props.statusType
    ) {
      const { listType, getLeadsList } = this.props;
      const urlParams = new URLSearchParams(history.location.search);
      const page = parseInt(urlParams.get('page'), 10);

      window.history.pushState(
        {},
        '',
        `?page=${page}${this.props.inn ? `&clientInn=${this.props.inn}` : ''}${
          this.props.typesToFilterBy.length
            ? `&type=${this.props.typesToFilterBy}`
            : ''
        }${this.props.dateFrom ? `&dateFrom=${this.props.dateFrom}` : ''}${
          this.props.dateUntil ? `&dateUntil=${this.props.dateUntil}` : ''
        }${
          this.props.clientName ? `&clientName=${this.props.clientName}` : ''
        }${
          this.props.statusType.length ? `&status=${this.props.statusType}` : ''
        }`
      );

      getLeadsList(listType, {
        page,
        pageSize: 20,
        companyInn: this.props.inn ? this.props.inn : undefined,
        dateFrom: this.props.dateFrom
          ? format(
              parse(this.props.dateFrom, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          : undefined,
        dateUntil: this.props.dateUntil
          ? format(
              parse(this.props.dateUntil, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          : undefined,
        product: this.props.typesToFilterBy.length
          ? this.props.typesToFilterBy.join(',')
          : undefined,
        clientName: this.props.clientName ? this.props.clientName : undefined,
        status: this.props.statusType.length
          ? this.props.statusType.join(',')
          : undefined
      });
    }
  }

  onLeadClick(id: number) {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/lead/${id}`);
  }

  render() {
    const { leads, status, error } = this.props;

    return (
      <ScrollTopComponent>
        {(this.props.listType === LEAD_LIST_TYPE.ALL ||
          this.props.listType === LEAD_LIST_TYPE.IN_WORK ||
          this.props.listType === LEAD_LIST_TYPE.DEAL_SUCCEED) && (
          <FilterWrapper>
            <TypeFilter />
            <INNFilter label="ИНН клиента" />
            <NameClientFilter />
            <DateFilter label="Дата поступления" from="С" until="По" />
            <StatusFilter statusList={LeadStatuses} />
          </FilterWrapper>
        )}
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="3%">Id</TableThStyled>
                <TableThStyled width="10%">Клиент</TableThStyled>
                <TableThStyled width="10%">Тип финансирования</TableThStyled>
                <TableThStyled width="5%">Дата изменения</TableThStyled>
                <TableThStyled width="5%">Сумма</TableThStyled>
                <TableThStyled width="5%">Статус</TableThStyled>
                <TableThStyled width="5%">Контакт</TableThStyled>
                <TableThStyled width="8%">Причина отказа</TableThStyled>
                {[
                  USER_PERMISSIONS.INTERNAL_AGENT,
                  USER_PERMISSIONS.EXTERNAL_AGENT
                ].isIn(this.props.permissions) && (
                  <TableThStyled width="8%">Чек-лист</TableThStyled>
                )}
              </tr>
            </TableHeaderStyled>
            <tbody>
              {leads.items.map(lead => (
                <TableRowStyled
                  key={lead.id}
                  onClick={() => this.onLeadClick(lead.id)}
                >
                  <td>{lead.id}</td>
                  <td>{lead.clientName}</td>
                  <td>{ApplicationTypes[lead.applicationFinancingType]}</td>
                  <td>
                    {format(
                      parse(lead.modifiedAt, 'M/d/yy h:mm:ss a', new Date()),
                      'dd.MM.yyyy'
                    )}
                  </td>
                  <td>{formSumString(lead.amount)}</td>
                  <td>{this.renderStatus(lead)}</td>
                  <td>
                    <ContactPopup lead={lead} />
                  </td>
                  <td>{lead.rejectReason}</td>
                  {[
                    USER_PERMISSIONS.INTERNAL_AGENT,
                    USER_PERMISSIONS.EXTERNAL_AGENT
                  ].isIn(this.props.permissions) && (
                    <TDChecklist>
                      <ChecklistContainer>
                        {Object(lead).hasOwnProperty('docsChecklist') && (
                          <DocChecklist type={lead.docsChecklist}>
                            <Content>
                              <div>
                                <p>Список документов</p>
                                <p>
                                  {lead.docsChecklist === 2
                                    ? 'Документы запрошены'
                                    : lead.docsChecklist === 4
                                      ? 'Предоставлены частично'
                                      : lead.docsChecklist === 6
                                        ? 'Предоставлены только обязательные'
                                        : lead.docsChecklist === 8
                                          ? 'Предоставлены все документы'
                                          : 'Документы еще не запрошены'}
                                </p>
                              </div>
                              <DocChecklist type={lead.docsChecklist} />
                            </Content>
                          </DocChecklist>
                        )}
                        {Object(lead).hasOwnProperty('bkiChecklist') && (
                          <BkiChecklist type={lead.bkiChecklist}>
                            <Content>
                              <div>
                                <p>Согласие перс.данных и БКИ</p>
                                <p>
                                  {lead.bkiChecklist === 2
                                    ? 'Ссылка направлена, но не подписана'
                                    : lead.bkiChecklist === 4
                                      ? 'Ссылка подписана'
                                      : 'Согласие еще не запрошено'}
                                </p>
                              </div>
                              <BkiChecklist type={lead.bkiChecklist} />
                            </Content>
                          </BkiChecklist>
                        )}
                        {Object(lead).hasOwnProperty(
                          'bgParametersChecklist'
                        ) && (
                          <BgParametsChecklist
                            type={lead.bgParametersChecklist}
                          >
                            <Content>
                              <div>
                                <p>Согласование БГ с заказчиком</p>
                                <p>
                                  {lead.bgParametersChecklist === 2
                                    ? 'Макет направлен для согласования'
                                    : lead.bgParametersChecklist === 4
                                      ? 'Согласовано с заказчиком'
                                      : 'Макет еще не направлен'}
                                </p>
                              </div>
                              <BgParametsChecklist
                                type={lead.bgParametersChecklist}
                              />
                            </Content>
                          </BgParametsChecklist>
                        )}
                        {Object(lead).hasOwnProperty(
                          'applicationToBankCheckList'
                        ) && (
                          <ApplicationChecklist
                            type={lead.applicationToBankCheckList}
                          >
                            <Content>
                              <div>
                                <p>Подача заявки в банк</p>
                                <p>
                                  {lead.applicationToBankCheckList === 2
                                    ? 'Ссылка банка направлена для подписания'
                                    : lead.applicationToBankCheckList === 4
                                      ? 'Заявка подана в банк'
                                      : 'Ссылка банка еще не направлена'}
                                </p>
                              </div>
                              <ApplicationChecklist
                                type={lead.applicationToBankCheckList}
                              />
                            </Content>
                          </ApplicationChecklist>
                        )}
                        {Object(lead).hasOwnProperty('dealToBankChecklist') && (
                          <DealChecklist type={lead.dealToBankChecklist}>
                            <Content>
                              <div>
                                <p>Подписание кредитной документации</p>
                                <p>
                                  {lead.dealToBankChecklist === 2
                                    ? 'Ссылка банка направлена для подписания'
                                    : lead.dealToBankChecklist === 4
                                      ? 'Документация с банком подписана'
                                      : 'Ссылка банка еще не направлена'}
                                </p>
                              </div>
                              <DealChecklist type={lead.dealToBankChecklist} />
                            </Content>
                          </DealChecklist>
                        )}
                      </ChecklistContainer>
                    </TDChecklist>
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
        <Pagination list={leads} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }

  renderStatus(lead: LeadRead) {
    const { changeLeadStatus, listType } = this.props;
    return lead.status === LEAD_STATUSES.CREATED &&
      listType !== LEAD_LIST_TYPE.MY ? (
      <TransferApplicationBtn
        onClick={() =>
          changeLeadStatus({ leadId: lead.id, status: LEAD_STATUSES.IN_WORK })
        }
      >
        Взять в работу
      </TransferApplicationBtn>
    ) : (
      <LeadStatusStyled status={lead.status}>
        {LeadStatuses[lead.status]}
      </LeadStatusStyled>
    );
  }
}

const mapStateToProps = ({ User, Leads, Filters }: STORE) => ({
  leads: Leads.getLeadsList.data,
  status: Leads.getLeadsList.status,
  error: Leads.getLeadsList.error,
  inn: Filters.updateINNFilter.inn,
  typesToFilterBy: Filters.updateTypeFilter.types,
  dateFrom: Filters.updateDateFilter.dateFrom,
  dateUntil: Filters.updateDateFilter.dateUntil,
  clientName: Filters.updateClientNameFilter.clientName,
  statusType: Filters.updateStatusFilter.status,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getLeadsList,
      changeLeadStatus,
      updateINNFilter,
      updateDateFromFilter,
      updateDateUntilFilter,
      addTypeFilter,
      updateClientNameFilter,
      addStatusFilter
    },
    dispatch
  );

const LeadsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeadsList);

export { LeadsListConnect as LeadsList };
