import { POST_FINANCIAL_DOCUMENT_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postFinancialDocumentExternal';

export const req = (guid: string, data: FormData) => ({
  type: POST_FINANCIAL_DOCUMENT_EXTERNAL.REQ,
  data,
  guid
});

export const got = () => ({
  type: POST_FINANCIAL_DOCUMENT_EXTERNAL.GOT
});

export const err = (error: {}) => ({
  type: POST_FINANCIAL_DOCUMENT_EXTERNAL.ERR,
  error
});
