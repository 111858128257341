import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from 'shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import { FormContainer } from 'src/features/Products/components/NewProduct/styled';
import { ApplicationTypes } from 'shared/constants';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as createProduct,
  reset as resetProductState
} from 'src/features/Products/actions/sendProductData';
import { ErrorMsg } from 'src/features/Contacts/components/CreateNewContact/styled';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  createStatus: REQUEST_STATUSES;
}

interface State {
  bankINN: string;
  bankName: string;
  productName: string;
  financeTypeId: string;
  isInfoPopup: boolean;
  errors: {
    bankINN: string;
    bankName: string;
    productName: string;
  };
}

interface MatchParams {}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  createProduct: (data: any) => void;
  resetProductState: () => void;
  resetStateCompanyByInn: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class NewProduct extends React.Component<Props, State> {
  state: State = {
    bankINN: '',
    bankName: '',
    productName: '',
    financeTypeId: '',
    isInfoPopup: false,
    errors: {
      bankINN: '',
      productName: '',
      bankName: ''
    }
  };

  componentDidMount() {
    if (history.location.state && history.location.state.inn) {
      this.setState({ bankINN: history.location.state.inn });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetProductState();
      history.goBack();
    }
    if (prevState.bankINN !== this.state.bankINN) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.bankINN !== this.state.bankINN &&
      this.state.bankINN.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.bankINN });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopup !== this.state.isInfoPopup
    ) {
      this.setState({ bankName: this.props.companyByInn.companyShortName });
    }
  }

  handleFieldNewProduct = (name: keyof State, value: string) => {
    this.setState({ [name]: value } as any);
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  handleCreateProduct = event => {
    event.preventDefault();

    const innRegex = /^[a-zA-Z0-9]+$/;

    const errors: State['errors'] = {
      bankINN: '',
      productName: '',
      bankName: ''
    };

    if (this.state.bankINN !== null && !innRegex.test(this.state.bankINN)) {
      errors.bankINN = 'Введите корректный ИНН';
    }

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, isInfoPopup, ...data } = this.state;
      this.props.createProduct({ ...data, financeTypeId: +data.financeTypeId });
    }
  };

  render() {
    const { companyByInn, statusInn } = this.props;
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание продукта</ApplicationTitle>

        <FormContainer onSubmit={this.handleCreateProduct}>
          <Input
            label="ИНН:"
            name="bankINN"
            value={this.state.bankINN}
            onChange={e =>
              this.handleFieldNewProduct('bankINN', e.currentTarget.value)
            }
          />

          {this.state.bankINN &&
          this.state.bankINN.length < 10 ? null : statusInn ===
          REQUEST_STATUSES.ERROR ? (
            <InnPopup>
              <h2>
                Компания с таким инн не найдена в системе ЕГРЮЛ, но вы можете
                создать связку с введенным ИНН
              </h2>
            </InnPopup>
          ) : (
            statusInn === REQUEST_STATUSES.GOT &&
            !this.state.isInfoPopup && (
              <InnPopup
                companyInfo={companyByInn}
                onPopupClick={this.onPopupClick}
              />
            )
          )}

          {this.state.errors.bankINN && (
            <ErrorMsg>{this.state.errors.bankINN}</ErrorMsg>
          )}

          <Input
            label="Наименование:"
            name="bankName"
            value={this.state.bankName}
            onChange={e =>
              this.handleFieldNewProduct('bankName', e.currentTarget.value)
            }
          />

          {this.state.errors.bankName && (
            <ErrorMsg>{this.state.errors.bankName}</ErrorMsg>
          )}

          <Select
            options={Object.entries(ApplicationTypes).map(
              ([key, value], index) => ({
                id: (index + 1).toString(),
                name: value
              })
            )}
            placeholder="Тип продукта"
            label="Тип продукта:"
            name="financeTypeId"
            value={this.state.financeTypeId}
            onChange={e =>
              this.handleFieldNewProduct('financeTypeId', e.currentTarget.value)
            }
          />

          <Input
            label="Краткое название продукта:"
            name="productName"
            value={this.state.productName}
            onChange={e =>
              this.handleFieldNewProduct('productName', e.currentTarget.value)
            }
          />

          {this.state.errors.productName && (
            <ErrorMsg>{this.state.errors.productName}</ErrorMsg>
          )}

          <Button label="Создать" type={'submit'} w="fit-content" h="40px" />
        </FormContainer>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Products, SCF }: CRM & STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  createStatus: Products.sendProductData.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      createProduct,
      resetProductState,
      resetStateCompanyByInn
    },
    dispatch
  );

const NewProductConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewProduct)
);

export { NewProductConnect as NewProduct };
