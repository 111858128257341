import { GET_MONETARY_CLAIMS_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsSupplierRole';

export interface MonataryClaim {
  auctionGuid: string;
  auctionResultsDateTime: string;
  baseFullSumm: number;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  createdDateTime: string;
  currencyCode: string;
  currencyID: number;
  debtorInn: string;
  debtorName: string;
  earlyPaymentAvailable: boolean;
  emergenceDate: string;
  guid: string;
  invoiceDocsQty: number;
  invoiceDocumentDate: string;
  invoiceDocumentName: string;
  invoiceDocumentNumber: string;
  maturityDate: string;
  paidStatus: number;
  status: string;
  supplierName: string;
  supplyContractGuid: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: MonataryClaim[];
  fullSum: number;
  availableChecks: number;
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  DebtorInn?: string;
  StartDate?: string;
  EndDate?: string;
  status?: string;
  auction?: string;
  sortBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIER_ROLE.GET_MONETARY_CLAIMS_SUPPLIER_ROLE_ERR,
  error
});
