import styled from 'styled-components';
import { FormSection } from 'shared/ui/FormSection';

export const RadioButtonGroupStyled = styled(FormSection)`
  margin: 0;
  position: relative;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  word-wrap: break-word;

  & > label {
    margin-right: 20px;
    text-align: center;
    word-wrap: break-word;
    display: block;
    min-width: 125px;
  }
`;
