import { GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getBeneficiariesOfCompanies';

export interface ResponseData {
  personInn: string;
  companyInn: string;
  ownershipShareDeJure: number;
  ownershipShareDeFacto: number;
  ownershipShareDeJureFull: number;
  capitalShare: number;
  beneficiaryType: number;
  companyShortName: string;
  companyFullName: string;
  revenue: number;
  inAction: number;
  netIncome: number;
  financialsYear: string;
  capital: number;
  headCompanyInn: string;
  okvedCode: string;
  okvedName: string;
  okvedPic: string;
}

export const req = (inn: string) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.ERR,
  error
});
