import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { PledgeType } from 'shared/constants';

import { STORE } from 'globaltypes';

import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import {
  req as sendApplicationData,
  reset as resetApplicationData
} from 'Application/actions/sendApplicationData';
import { RequestDataType as sendApplicationDataReq } from 'Application/reducers/sendApplicationData';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { InputRange } from 'shared/ui/InputRange';
import { TRANSITION_DIRECTIONS } from 'Common';

import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  DebtorsTable,
  ApplicationBtnsWrapperStyled
} from './styled';
import { FactoringApplicationRead } from 'src/features/Application/types';
import { Button } from 'shared/ui/Button';

interface StateToProps extends SetFactoringApplicationType {
  externalApplication: Partial<FactoringApplicationRead>;
}

interface DispatchToProps {
  sendApplicationData: (data: sendApplicationDataReq) => void;
  resetApplicationData: () => void;
  setFactoringFormData: (data: SetFactoringApplicationType) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class FactoringFormStep3 extends React.Component<Props> {
  Validator = createValidaton(this.props.debtors);

  navigateFromTo = (
    from: string,
    to: string,
    transition: TRANSITION_DIRECTIONS
  ) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS[transition]
    });

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    const debtorIndex = parseInt(name.split('_')[0], 10);
    const debtorKey = name.split('_')[1];

    const isToNumber = debtorKey === 'limit' || debtorKey === 'postponement';

    const newDebtors = this.props.debtors.reduce((arr, item, index) => {
      if (index === debtorIndex) {
        item[debtorKey] = isToNumber ? toFloatFormatter(value) : value;
      }

      return [...arr, item];
    }, []);

    this.props.setFactoringFormData({ debtors: newDebtors } as {
      [key in keyof SetFactoringApplicationType]: any
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) return;

    this.props.sendApplicationData({
      id: this.props.match.params.inBankId
        ? +this.props.match.params.inBankId
        : +this.props.match.params.id,
      factoring: {
        debtors: this.props.debtors,
        debtorType: this.props.debtorType,
        documents: this.props.documents
      }
    });

    this.navigateFromTo(
      this.props.location.pathname,
      'documents',
      TRANSITION_DIRECTIONS.FORWARD
    );
  };

  formTableRowsData = errors =>
    this.props.debtors.map((item, index) => [
      item.name,
      <InputRange
        key={index}
        value={item.limit.toString()}
        name={`${index}_limit`}
        placeholder="Введите лимит"
        min={0}
        max={this.props.externalApplication.amount}
        step={1000}
        sliderType={RANGE_SLIDER_TYPE.SUM}
        valueType={INPUT_RANGE_VALUE_TYPE.SUM}
        error={errors[`${index}_limit`]}
        onChange={this.onChange}
      />,
      <InputRange
        key={index}
        value={item.postponement.toString()}
        name={`${index}_postponement`}
        placeholder="Введите отсрочку"
        min={1}
        max={this.props.externalApplication.term}
        step={1}
        sliderType={RANGE_SLIDER_TYPE.TERM}
        valueType={INPUT_RANGE_VALUE_TYPE.TERM}
        onChange={this.onChange}
      />,
      <RadioGroup
        key={index}
        name={`${index}_pledgeType`}
        keyValue={item.pledgeType}
        onChange={this.onChange}
        radioBtns={Object.keys(PledgeType).map(type => ({
          value: type,
          label: PledgeType[type]
        }))}
      />
    ]);

  render() {
    const { openModal, externalApplication, debtors } = this.props;

    const { errors } = this.Validator.insertArgs({
      amountRange: [-1, externalApplication.amount]
    }).validate(
      debtors.reduce((fieldsObj, debtor, index) => {
        fieldsObj[`${index}_limit`] = debtor.limit;
        return fieldsObj;
      }, {})
    );

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>Запрашиваемые лимиты</ApplicationStepTitle>

        <DebtorsTable
          headers={[
            'Дебитор',
            'Лимит на дебитора',
            'Отсрочка по договору',
            'Наличие залога'
          ]}
          rows={this.formTableRowsData(errors)}
          sizes={['20%', '30%', '30%', '20%']}
        />
        <ApplicationBtnsWrapperStyled>
          <Button
            template="backBtn"
            label="Назад"
            onClick={() =>
              this.navigateFromTo(
                this.props.location.pathname,
                'debtors',
                TRANSITION_DIRECTIONS.BACKWARDS
              )
            }
          />
          <Button
            template="nextBtn"
            label="Продолжить"
            onClick={this.onSubmit}
          />
        </ApplicationBtnsWrapperStyled>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application, ExternalAgentRole }: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  debtorType: Application.setFactoringFormData.debtorType,
  debtors: Application.setFactoringFormData.debtors
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFactoringFormData,
      sendApplicationData,
      resetApplicationData,
      openModal
    },
    dispatch
  );

const FactoringFormStep3Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep3)
);

export { FactoringFormStep3Connect as FactoringFormStep3 };
