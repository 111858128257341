import { CHANGE_LEAD_STATUS } from '../reducers/changeLeadStatus';
import { LEAD_STATUSES } from '../types';

export interface RequestData {
  rejectReason?: string;
  leadId: number | string;
  status: LEAD_STATUSES;
}

export const req = (data: RequestData) => ({
  type: CHANGE_LEAD_STATUS.REQ,
  data
});

export const got = () => ({
  type: CHANGE_LEAD_STATUS.GOT
});

export const err = (error: {}) => ({
  type: CHANGE_LEAD_STATUS.ERR,
  error
});
