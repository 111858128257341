export interface ClientNameFilterData {
  clientName: string;
}

export enum FILTER_CLIENT_NAME_ACTIONS {
  SET = 'SET_CLIENT_NAME'
}

export const initialState: ClientNameFilterData = {
  clientName: ''
};

const updateDateFilter = (
  state: ClientNameFilterData = initialState,
  action: {
    type: FILTER_CLIENT_NAME_ACTIONS;
    dateFrom: string;
    dateUntil: string;
  }
): ClientNameFilterData => {
  switch (action.type) {
    case FILTER_CLIENT_NAME_ACTIONS.SET:
      return { ...state, ...action };
    default:
      return state;
  }
};

export default updateDateFilter;
