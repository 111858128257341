import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'BorrowerCompanies/actions/uploadBorrowerCompanyDocuments';
import {
  SagaRequestDataType,
  UPLOAD_BORROWER_COMPANIES_DOCUMENTS
} from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* uploadBorrowerCompanyDocuments(action: {
  type: UPLOAD_BORROWER_COMPANIES_DOCUMENTS;
  data: SagaRequestDataType;
}) {
  try {
    const { files, ...apiRequestData } = action.data;

    yield all(
      files.map(function*(file) {
        const formData = new FormData();
        formData.append('file', file);

        const requestData = {
          ...apiRequestData,
          metaInfo: file.metaInfo,
          file: formData
        };

        return yield call(
          BorrowerCompaniesApi.uploadBorrowerCompanyDocuments,
          requestData
        );
      })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* uploadBorrowerCompanyDocumentsSaga() {
  yield takeLatest(
    UPLOAD_BORROWER_COMPANIES_DOCUMENTS.REQ,
    uploadBorrowerCompanyDocuments
  );
}
