import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { PersonOfCompaniesType } from '../types';

export interface GetPersonOfCompaniesStoreState extends Reducer {
  data: PersonOfCompaniesType[];
}

export enum GET_PERSON_OF_COMPANIES {
  REQ = 'GET_PERSON_OF_COMPANIES_REQ',
  GOT = 'GET_PERSON_OF_COMPANIES_GOT',
  ERR = 'GET_PERSON_OF_COMPANIES_ERR'
}

export const initialState: GetPersonOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getPersonOfCompanies = (
  state: GetPersonOfCompaniesStoreState = initialState,
  action: {
    type: GET_PERSON_OF_COMPANIES;
    data: PersonOfCompaniesType[];
    error: {};
  }
): GetPersonOfCompaniesStoreState => {
  switch (action.type) {
    case GET_PERSON_OF_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PERSON_OF_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PERSON_OF_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPersonOfCompanies;
