import { PUT_OPEN_LOAN_LIMITS_SET_ACTIVE } from '../reducers/putOpenLoanLimitsSetActive';

export const req = (id: string) => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_ACTIVE.REQ,
  id
});

export const got = () => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_ACTIVE.GOT
});

export const err = (error: {}) => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_ACTIVE.ERR,
  error
});
