export enum HAS_CASHBACK {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть кэшбек',
  FALSE = 'Нет кэшбека'
}

export enum IN_PLATFORM {
  NOT_CHECK = 'не проверяется',
  TRUE = 'есть на FINFACTORY',
  FALSE = 'нет на FINFACTORY'
}

export enum HAS_CONTRACT {
  NOT_CHECK = 'не проверяется',
  TRUE = 'имеется',
  FALSE = 'отсутствует'
}
