import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getDebtorNotificationSettings';

export interface GetDebtorNotificationSettingsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum DEBTOR_NOTIFICATION_SETTINGS {
  REQ = 'DEBTOR_NOTIFICATION_SETTINGS_REQ',
  GOT = 'DEBTOR_NOTIFICATION_SETTINGS_GOT',
  ERR = 'DEBTOR_NOTIFICATION_SETTINGS_ERR'
}

const initialState: GetDebtorNotificationSettingsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    newBuyerToSupplierLinkEvent: false,
    newSupplyContractLinkEvent: false,
    newEarlyPaymentApplicationEvent: false,
    newApprovedEpaEvent: false,
    newSignedEpaEvent: false,
    getSignedEpaDocuments: false
  },
  error: {}
};

const getDebtorNotificationSettings = (
  state: GetDebtorNotificationSettingsStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetDebtorNotificationSettingsStoreState => {
  switch (action.type) {
    case DEBTOR_NOTIFICATION_SETTINGS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case DEBTOR_NOTIFICATION_SETTINGS.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case DEBTOR_NOTIFICATION_SETTINGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getDebtorNotificationSettings;
