import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  ApplicationTypes,
  CreditingPledge,
  CreditingGuarantor,
  CreditingCreditType,
  CreditingPledgeType,
  CreditingGuarantorType
} from 'shared/constants';

import { req as sendApplicationData } from 'Application/actions/sendApplicationData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';

import { STORE } from 'globaltypes';
import {
  CREDITING_PLEDGE,
  CREDITING_GUARANTOR,
  CREDITING_PLEDGE_TYPE,
  CREDITING_CREDIT_TYPE,
  CreditingApplicationRead
} from 'Application/types';

import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  TransitionWrapper,
  ANIMATION_NAMES,
  TRANSITION_DIRECTIONS
} from 'Common';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { CreditingPledgeHint } from 'shared/ui/Hint';
import { Select } from 'shared/ui/Select';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationInputsColumn,
  ApplicationForm,
  ApplicationFormContent,
  ApplicationBtnsWrapper
} from '../styled';

import {
  CreditingFormSection,
  CheckboxGroupStyled,
  InputHintStyled
} from './styles';
import { Button } from 'shared/ui/Button';

interface MatchParams {
  id: string;
}

interface StateToProps {
  clientApplication: Partial<CreditingApplicationRead>;
}

interface DispatchToProps {
  sendApplicationData: (data: SendApplicationRequestDataType) => void;
  openModal: (data: OpenModalDataType) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CreditingFormStep1: React.FC<Props> = ({
  clientApplication,
  sendApplicationData,
  openModal,
  history,
  match,
  location
}) => {
  const [fields, setFields] = React.useState({
    creditingType: CREDITING_CREDIT_TYPE.CREDIT,
    pledge: CREDITING_PLEDGE.HAS_PLEDGE,
    pledges: [],
    guarantor: CREDITING_GUARANTOR.HAS_GUARANTOR,
    guarantors: [],
    otherPledgeEstate: ''
  });

  React.useEffect(() => {
    setFields(prevState => ({
      ...prevState,
      creditingType: CREDITING_CREDIT_TYPE.CREDIT,
      pledge: clientApplication.pledge
    }));
  }, []);

  React.useEffect(
    () => {
      if (fields.pledge === 'NO_PLEDGE') {
        setFields(prevState => ({
          ...prevState,
          pledges: [],
          otherPledgeEstate: ''
        }));
      }
      if (fields.guarantor === 'NO_GUARANTOR') {
        setFields(prevState => ({ ...prevState, guarantors: [] }));
      }
    },
    [fields.pledge, fields.guarantor]
  );

  const navigateFromTo = (from: string, to: string) =>
    history.push({
      pathname: match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.currentTarget;
    const isCheckbox = type === 'checkbox';

    if (name === 'guarantors' || name === 'pledges') {
      setFields(prevState => ({
        ...prevState,
        [name]: prevState[name].includes(value)
          ? prevState[name].filter((role: string) => role !== value)
          : [...prevState[name], value]
      }));
    } else {
      setFields(prevState => ({
        ...prevState,
        [name]: isCheckbox ? checked : value
      }));
    }
  };

  const onSubmit = () => {
    sendApplicationData({
      id: +match.params.id,
      crediting: {
        creditingType: fields.creditingType,
        pledge: fields.pledge,
        pledges: fields.pledges,
        guarantor: fields.guarantor,
        guarantors: fields.guarantors,
        otherPledgeEstate: fields.otherPledgeEstate
      }
    });

    navigateFromTo(location.pathname, 'documents');
  };

  return (
    <ApplicationFormStyled>
      <CloseApplicationBtn
        onClick={() =>
          openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
        }
      />

      <ApplicationStepTitle>Параметры кредитования</ApplicationStepTitle>

      <ApplicationFormContent>
        <ApplicationInputsColumn>
          <ApplicationForm
            onSubmit={onSubmit}
            name="crediting-application-form-step-1"
          >
            <Select
              options={Object.keys(ApplicationTypes).map(type => ({
                id: type,
                name: ApplicationTypes[type]
              }))}
              value={clientApplication.financingType}
              label="Тип заявки"
              name="financingType"
              placeholder="Выберите тип продукта"
              required={true}
              disabled={true}
            />

            <RadioGroup
              name="creditingType"
              label="Тип кредита"
              keyValue={fields.creditingType}
              onChange={onChange}
              radioBtns={Object.keys(CreditingCreditType).map(type => ({
                value: type,
                label: CreditingCreditType[type]
              }))}
            />

            <CreditingFormSection>
              <React.Fragment>
                <RadioGroup
                  name="pledge"
                  label="Наличие залога"
                  keyValue={fields.pledge}
                  onChange={onChange}
                  template="row"
                  radioBtns={Object.keys(CreditingPledge).map(type => ({
                    value: type,
                    label: CreditingPledge[type]
                  }))}
                />

                <TransitionWrapper
                  in={fields.pledge === CREDITING_PLEDGE.HAS_PLEDGE}
                  animationName={ANIMATION_NAMES.FADE}
                >
                  <CheckboxGroupStyled
                    name="pledges"
                    label="Тип залога"
                    checkboxes={Object.keys(CreditingPledgeType).map(type => ({
                      label: CreditingPledgeType[type],
                      value: type,
                      checked:
                        !!fields.pledges &&
                        fields.pledges.includes(type as CREDITING_PLEDGE_TYPE),
                      onChange: onChange,
                      children: type === CREDITING_PLEDGE_TYPE.OTHER && (
                        <InputHintStyled
                          value={fields.otherPledgeEstate}
                          name="otherPledgeEstate"
                          placeholder="Укажите дополнительные виды обеспечения"
                          onChange={onChange}
                          hint={{
                            validatable: false,
                            position: 'right',
                            component: <CreditingPledgeHint />
                          }}
                        />
                      )
                    }))}
                  />
                </TransitionWrapper>
              </React.Fragment>
            </CreditingFormSection>

            <CreditingFormSection>
              <React.Fragment>
                <RadioGroup
                  name="guarantor"
                  label="Возможны ли поручительства"
                  keyValue={fields.guarantor}
                  onChange={onChange}
                  template="row"
                  radioBtns={Object.keys(CreditingGuarantor).map(type => ({
                    value: type,
                    label: CreditingGuarantor[type]
                  }))}
                />

                <TransitionWrapper
                  in={fields.guarantor === CREDITING_GUARANTOR.HAS_GUARANTOR}
                  animationName={ANIMATION_NAMES.FADE}
                >
                  <CheckboxGroupStyled
                    name="guarantors"
                    label="Поручительства"
                    checkboxes={Object.keys(CreditingGuarantorType).map(
                      type => ({
                        label: CreditingGuarantorType[type],
                        value: type,
                        checked: fields.guarantors.includes(type),
                        onChange: onChange
                      })
                    )}
                  />
                </TransitionWrapper>
              </React.Fragment>
            </CreditingFormSection>

            <ApplicationBtnsWrapper>
              <Button
                template="backBtn"
                label="Назад"
                onClick={() => navigateFromTo(location.pathname, 'options')}
              />
              <Button template="nextBtn" label="Продолжить" type="submit" />
            </ApplicationBtnsWrapper>
          </ApplicationForm>
        </ApplicationInputsColumn>
      </ApplicationFormContent>
    </ApplicationFormStyled>
  );
};

const mapStateToProps = ({ ClientRole }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationData,
      openModal
    },
    dispatch
  );

const CreditingFormStep1Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CreditingFormStep1)
);

export { CreditingFormStep1Connect as CreditingFormStep1 };
