import * as React from 'react';
import { REQUEST_STATUSES, USER_PERMISSIONS } from 'src/globaltypes';

import {
  DataBlock,
  IOwnProps as IEachApplicationData
} from 'Application/components';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { ResponseData } from 'Application/reducers/getCompanyHints';
import { ResponseData as productOfApplication } from 'Application/actions/getProductApplication';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { GuaranteesApplicationRead } from 'src/features/Application/types';
import { DocumentsBlock } from '../DocumentsBlock/DocumentsBlock';

interface Permission {
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: productOfApplication[];
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  postSelectApplication: (data: any) => void;
  scrollToProduct: () => void;
  sendRequestsGIR_BO: (year: number) => void;
  statusFinancailsSummaryData: REQUEST_STATUSES;
}

interface OwnProps {
  application: ResInternalApplication | Partial<GuaranteesApplicationRead>;
  documentList: ResDocumentListByApplicationId;
}

type Props = IEachApplicationData & Permission & OwnProps;

export const ContentContainer: React.FC<Props> = ({
  application,
  mainInfo,
  subInfo,
  documents,
  permissions,
  roles,
  companyHints,
  productOfApplication,
  postSelectApplication,
  scrollToProduct,
  sendRequestsGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  documentList,
  children
}: Props) => (
  <React.Fragment>
    {/* Application data block,
        contains company name, status, whole info etc */}
    {/* TODO unnecessary abstraction */}
    <DataBlock
      head={{
        title: application.clientCompanyName,
        initiatorEmail: application.initiatorEmail,
        applicationSource: application.source,
        status: application.status,
        id: application.id,
        inn: application.code
      }}
      data={{
        main: mainInfo,
        sub: subInfo
      }}
      permissions={permissions}
      roles={roles}
      companyHints={companyHints}
      productOfApplication={productOfApplication}
      postSelectApplication={postSelectApplication}
      scrollToProduct={scrollToProduct}
      sendRequestsGIR_BO={sendRequestsGIR_BO}
      financailsSummaryData={financailsSummaryData}
      statusFinancailsSummaryData={statusFinancailsSummaryData}
      statusExternalApiGIR_BO={statusExternalApiGIR_BO}
    />

    {/* Custom application elements passed through children props
        e.g. Debtors Block from FactoringView component */}
    {!!children && children}

    {/* Application documents block,
        contains ViewSeparator with edit btn and company + general documents */}
    <DocumentsBlock
      application={application}
      documents={documents}
      permissions={permissions}
      documentList={documentList}
    />
  </React.Fragment>
);
