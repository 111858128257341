import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Application/actions/getActivitiesByApplication';

export interface GetActivitiesByApplicationStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ACTIVITIES_BY_APPLICATION {
  REQ = 'GET_ACTIVITIES_BY_APPLICATION_REQ',
  GOT = 'GET_ACTIVITIES_BY_APPLICATION_GOT',
  ERR = 'GET_ACTIVITIES_BY_APPLICATION_ERR'
}

export const initialState: GetActivitiesByApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getActivitiesByApplicationList = (
  state: GetActivitiesByApplicationStoreState = initialState,
  action: {
    type: GET_ACTIVITIES_BY_APPLICATION;
    data: any;
    error: {};
  }
): GetActivitiesByApplicationStoreState => {
  switch (action.type) {
    case GET_ACTIVITIES_BY_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ACTIVITIES_BY_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ACTIVITIES_BY_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getActivitiesByApplicationList;
