import {
  SagaRequestDataType,
  UPLOAD_REGISTRIES
} from 'entities/SCF/Debtor/model/reducers/uploadRegistries';

export const req = (data: SagaRequestDataType) => ({
  type: UPLOAD_REGISTRIES.REQ,
  data
});

export const got = () => ({
  type: UPLOAD_REGISTRIES.GOT
});

export const err = (error: {}) => ({
  type: UPLOAD_REGISTRIES.ERR,
  error
});
