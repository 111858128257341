import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutSetApprovedDataStoreState extends Reducer {}

export enum PUT_SET_APPROVED {
  REQ = 'PUT_SET_APPROVED_REQ',
  GOT = 'PUT_SET_APPROVED_GOT',
  ERR = 'PUT_SET_APPROVED_ERROR'
}

export const initialState: PutSetApprovedDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putSetApproved = (
  state: PutSetApprovedDataStoreState = initialState,
  action: {
    type: PUT_SET_APPROVED;
    error: {};
  }
): PutSetApprovedDataStoreState => {
  switch (action.type) {
    case PUT_SET_APPROVED.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_SET_APPROVED.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_SET_APPROVED.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putSetApproved;
