import * as React from 'react';
import { reset as resetUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';

import {
  TransitionWrapper,
  ANIMATION_NAMES,
  Props as TransitionWrapperProps
} from 'Common';

import {
  UnexpectedError,
  SessionError,
  Forbidden,
  RegistrationEmailFail,
  RegistrationSucess,
  UserBlockedError,
  UnconfirmedEmailError,
  TemporarilyBlockedError
} from 'Layouts/components';

import { BoxStyled } from 'shared/styled';
import { ActionResponseBlockStyled } from './styles';
import { Redirect } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { NotFound } from './NotFound/NotFound';

export enum ACTION_RESPONSE_TEMPLATE {
  NONE = 'NONE',
  BANNED = 'BANNED',
  UNCONFIRMED = 'UNCONFIRMED',
  TEMPORARILY_BLOCKED = 'TEMPORARILY_BLOCKED',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  SESSION_ERROR = 'SESSION_ERROR',
  REGISTRATION_EMAIL_FAIL = 'REGISTRATION_EMAIL_FAIL',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND'
}

interface DispatchToProps {
  resetUserData: () => void;
  exit: () => void;
}

interface StateToProps extends Partial<TransitionWrapperProps> {
  showIn?: boolean;
  template?: ACTION_RESPONSE_TEMPLATE;
  templateData?: string;
  title?: JSX.Element | string;
  subtitle?: string;
  text?: JSX.Element | string;
  icon?: JSX.Element;
  button?: JSX.Element;
}

const defaultProps = {
  showIn: true,
  template: ACTION_RESPONSE_TEMPLATE.NONE
};

type Props = StateToProps & DispatchToProps;

const ActionResponseBlock: React.FC<Props> = ({
  showIn,
  template,
  templateData,
  title,
  subtitle,
  text,
  icon,
  button,
  exit,
  resetUserData
}: Props) => {
  const logOut = () => {
    exit();
    resetUserData();
  };
  return (
    <TransitionWrapper in={showIn} animationName={ANIMATION_NAMES.FADE}>
      <ActionResponseBlockStyled>
        {template === ACTION_RESPONSE_TEMPLATE.NONE && (
          <React.Fragment>
            {!!title && <h1>{title}</h1>}
            {icon}
            <BoxStyled>
              {!!subtitle && <h2>{subtitle}</h2>}
              {!!text && <p>{text}</p>}
              {button}
            </BoxStyled>
          </React.Fragment>
        )}
        {template === ACTION_RESPONSE_TEMPLATE.SESSION_ERROR && logOut()}

        {template === ACTION_RESPONSE_TEMPLATE.FORBIDDEN && <Forbidden />}
        {template === ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR && (
          <UnexpectedError />
        )}
        {template === ACTION_RESPONSE_TEMPLATE.BANNED && <UserBlockedError />}
        {template === ACTION_RESPONSE_TEMPLATE.UNCONFIRMED && (
          <UnconfirmedEmailError />
        )}
        {template === ACTION_RESPONSE_TEMPLATE.TEMPORARILY_BLOCKED && (
          <TemporarilyBlockedError />
        )}

        {template === ACTION_RESPONSE_TEMPLATE.NOT_FOUND && <NotFound />}

        {template === ACTION_RESPONSE_TEMPLATE.REGISTRATION_EMAIL_FAIL && (
          <RegistrationEmailFail email={templateData} />
        )}

        {template === ACTION_RESPONSE_TEMPLATE.REGISTRATION_SUCCESS && (
          <RegistrationSucess email={templateData} />
        )}
      </ActionResponseBlockStyled>
    </TransitionWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ exit, resetUserData }, dispatch);
const ActionResponseBlockConnect = connect<StateToProps, DispatchToProps>(
  null,
  mapDispatchToProps
)(ActionResponseBlock);

ActionResponseBlock.defaultProps = defaultProps;

export { ActionResponseBlockConnect as ActionResponseBlock };
