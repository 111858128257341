import styled from 'styled-components';

export const RequsitesBankAccountsStyled = styled.section`
  width: 900px;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
