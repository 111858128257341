import { SEND_FACTORING_CONTRACT } from '../reducers/sendFactoringContract';
import { FactoringContractWrite } from './setFactoringContract';

export interface RequestData extends Partial<FactoringContractWrite> {}

export const req = (data: RequestData) => ({
  type: SEND_FACTORING_CONTRACT.REQ,
  data
});

export const got = () => ({
  type: SEND_FACTORING_CONTRACT.GOT
});

export const err = (error: {}) => ({
  type: SEND_FACTORING_CONTRACT.ERR,
  error
});
