import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Row, Col, Container } from 'react-grid-system';
import { STORE, REQUEST_STATUSES } from 'src/globaltypes';
import { req as getApiKey } from '../actions/getApiKey';
import { req as getApiKeyPublic } from '../actions/getApiKeyPublic';
import { req as refreshApiKey } from '../actions/refreshApiKey';
import { req as refreshPublicApiKey } from '../actions/refreshPublicApiKey';
import { Input } from 'shared/ui/Input';
import { Button } from 'shared/ui/Button';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { TitleContainer } from './styles';

interface StateToProps {
  apiKey: string;
  apiKeyPublic: string;
  refreshStatus: REQUEST_STATUSES;
  refreshStatusPublic: REQUEST_STATUSES;
}

interface DispatchToProps {
  getApiKey: () => void;
  getApiKeyPublic: () => void;
  refreshApiKey: () => void;
  refreshPublicApiKey: () => void;
}

type Props = StateToProps & DispatchToProps;

const ApiKeyBlock: React.FC<Props> = ({
  getApiKey,
  apiKey,
  getApiKeyPublic,
  apiKeyPublic,
  refreshApiKey,
  refreshStatus,
  refreshPublicApiKey,
  refreshStatusPublic
}) => {
  React.useEffect(
    () => {
      getApiKey();
    },
    [refreshStatus]
  );

  React.useEffect(
    () => {
      getApiKeyPublic();
    },
    [refreshStatusPublic]
  );

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Container>
      <TitleContainer>
        <ApplicationTitle>Закрытый ключ API</ApplicationTitle>
        <p>
          Сгенерируйте ключ API для интеграции с платформой FINFACTORY. НЕ
          ИСПОЛЬЗУЙТЕ закрытый ключ в явном виде на сайте, во внешних ссылках,
          для отправки запросов внешними пользователями непосредственно из
          браузера.
        </p>
      </TitleContainer>
      <Row>
        <Col lg={6} md={12}>
          <Input disabled={true} name="API KEY" value={apiKey} />
        </Col>
        <Col lg={3} md={6}>
          <Button
            label="Скопировать"
            onClick={() => handleCopyClick(apiKey)}
            w="100%"
          />
        </Col>
        <Col lg={3} md={6}>
          <Button label="Обновить ключ" onClick={refreshApiKey} w="100%" />
        </Col>
      </Row>

      <TitleContainer>
        <ApplicationTitle>Открытый ключ API</ApplicationTitle>
        <p>
          Сгенерируйте ключ API для использования в партнерской программе и во
          внешних ссылках.
        </p>
      </TitleContainer>
      <Row>
        <Col lg={6} md={12}>
          <Input disabled={true} name="API KEY" value={apiKeyPublic} />
        </Col>
        <Col lg={3} md={6}>
          <Button
            label="Скопировать"
            onClick={() => handleCopyClick(apiKeyPublic)}
            w="100%"
          />
        </Col>
        <Col lg={3} md={6}>
          <Button
            label="Обновить ключ"
            onClick={refreshPublicApiKey}
            w="100%"
          />
        </Col>
      </Row>

      <TitleContainer>
        <ApplicationTitle>Приглашение к регистрации</ApplicationTitle>
        <p>
          Отправьте ссылку на регистрацию вашему контрагенту, чтобы он мог
          работать по заявкам на платформе самостоятельно.
        </p>
      </TitleContainer>
      <Row>
        <Col lg={6} md={12}>
          <Input
            disabled={true}
            name="API KEY"
            value={`${
              location.origin
            }/registration/business?partner=${apiKeyPublic}`}
          />
        </Col>
        <Col lg={3} md={6}>
          <Button
            label="Скопировать"
            onClick={() =>
              handleCopyClick(
                `${
                  location.origin
                }/registration/business?partner=${apiKeyPublic}`
              )
            }
            w="100%"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ ApiKeys }: STORE) => ({
  apiKey: ApiKeys.getApiKey.key,
  apiKeyPublic: ApiKeys.getApiKeyPublic.key,
  refreshStatus: ApiKeys.refreshApiKey.status,
  refreshStatusPublic: ApiKeys.refreshApiKeyPublic.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getApiKey, getApiKeyPublic, refreshApiKey, refreshPublicApiKey },
    dispatch
  );

const ApiKeyBlockConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeyBlock);

export { ApiKeyBlockConnect as ApiKeyBlock };
