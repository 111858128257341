import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getPurchaseContracts'

export interface GetPurchaseContractsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_PURCHASE_CONTRACTS {
  GET_PURCHASE_CONTRACTS_REQ = 'GET_PURCHASE_CONTRACTS_REQ',
  GET_PURCHASE_CONTRACTS_GOT = 'GET_PURCHASE_CONTRACTS_GOT',
  GET_PURCHASE_CONTRACTS_ERR = 'GET_PURCHASE_CONTRACTS_ERR'
}

export const initialState: GetPurchaseContractsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getPurchaseContracts = (
  state: GetPurchaseContractsStoreState = initialState,
  action: {
    type: GET_PURCHASE_CONTRACTS;
    data: ResponseDataType;
    error: {};
  }
): GetPurchaseContractsStoreState => {
  switch (action.type) {
    case GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PURCHASE_CONTRACTS.GET_PURCHASE_CONTRACTS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPurchaseContracts;
