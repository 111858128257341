import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getFactoringLimits'

export interface GetMyLimitsStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_MY_LIMITS {
  GET_MY_LIMITS_REQ = 'GET_MY_LIMITS_REQ',
  GET_MY_LIMITS_GOT = 'GET_MY_LIMITS_GOT',
  GET_MY_LIMITS_ERR = 'GET_MY_LIMITS_ERR'
}

export const initialState: GetMyLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getMyLimits = (
  state: GetMyLimitsStoreState = initialState,
  action: {
    type: GET_MY_LIMITS;
    data: ResponseDataType;
    error: {};
  }
): GetMyLimitsStoreState => {
  switch (action.type) {
    case GET_MY_LIMITS.GET_MY_LIMITS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MY_LIMITS.GET_MY_LIMITS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MY_LIMITS.GET_MY_LIMITS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMyLimits;
