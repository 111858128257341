import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { history } from 'src/shared/utils/History';

import { STORE } from 'globaltypes';
import {
  ApplicationTypes,
  FederalDebtors,
  InternationalDebtors,
  DebtorsType
} from 'shared/constants';

import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { setFactoringFormData } from 'Application/actions/setFactoringFormData';
import { SetFactoringApplicationType } from 'Application/reducers/setFactoringFormData';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';

import { TRANSITION_DIRECTIONS } from 'Common';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';

import {
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES,
  DEBTOR_TYPE,
  PLEDGE_TYPE,
  FactoringApplicationWrite
} from 'Application/types';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { MultiSelect } from 'shared/ui/MultiSelect';
import { MultiInput } from 'shared/ui/MultiInput';
import { Input } from 'shared/ui/Input';

import { createValidaton } from './validator';

import {
  CloseApplicationBtn,
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationInputsColumn,
  ApplicationFormContent,
  ApplicationForm,
  ApplicationBtnsWrapper
} from '../../styles';
import { Button } from 'shared/ui/Button';

interface State {
  submitClicked: boolean;
}

interface OwnProps {
  path: string;
}

interface WithRouterProps {
  match: any;
}

interface StateToProps extends SetFactoringApplicationType {
  id: number;
  applicationType: APPLICATION_TYPES;
}

interface DispatchToProps {
  setFactoringFormData: (data: SetFactoringApplicationType) => void;
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  openModal: (data: OpenModalDataType) => void;
}

type Props = OwnProps & WithRouterProps & StateToProps & DispatchToProps;

class FactoringFormStep1 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    submitClicked: false
  };

  componentWillUnmount() {
    const { sendApplicationDataReq, id, debtorType, debtors } = this.props;

    sendApplicationDataReq({
      id,
      factoring: {
        debtors,
        debtorType
      }
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.match.url.replace(from, to);

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const debtorsTypeChanged = name === 'debtorType';

    this.props.setFactoringFormData({
      [name]: value,
      debtors: debtorsTypeChanged ? [] : this.props.debtors
    } as { [key in keyof FactoringApplicationWrite]: any });
  };

  onMultiAdd = (debtorName: string) => {
    const isDebtorSame =
      this.props.debtors.map(item => item.name).indexOf(debtorName) !== -1;

    !isDebtorSame &&
      this.props.setFactoringFormData({
        debtors: [
          ...this.props.debtors,
          {
            name: debtorName,
            limit: 0,
            postponement: 1,
            pledgeType: PLEDGE_TYPE.NO_REGRESS
          }
        ]
      });
  };

  onMultiRemove = (index: number) => {
    const newDebtors = [...this.props.debtors];
    const removedDebtor = newDebtors.splice(index, 1);
    this.props.setFactoringFormData({ debtors: newDebtors });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    history.push({
      pathname:
        this.props.debtors.length > 0
          ? this.navigateFromTo(this.props.path, 'debtors')
          : this.navigateFromTo(this.props.path, 'documents'),
      state: TRANSITION_DIRECTIONS.FORWARD
    });
  };

  render() {
    const { applicationType, debtorType, debtors, openModal } = this.props;

    const { errors } = this.Validator.validate({
      debtorType
    });

    const RadioChildrenProps = {
      label: 'Дебиторы',
      name: 'debtors',
      value: '',
      selected: debtors.map(debtor => debtor.name),
      onMultiAdd: this.onMultiAdd,
      onMultiRemove: this.onMultiRemove
    };

    const RadioChildren = {
      [DEBTOR_TYPE.FEDERAL]: {
        component: (
          <MultiSelect
            {...RadioChildrenProps}
            options={FederalDebtors}
            placeholder="Выберите дебиторов"
          />
        )
      },
      [DEBTOR_TYPE.INTERNATIONAL]: {
        component: (
          <MultiSelect
            {...RadioChildrenProps}
            options={InternationalDebtors}
            placeholder="Выберите дебиторов"
          />
        )
      },
      [DEBTOR_TYPE.OTHER]: {
        component: (
          <MultiInput
            {...RadioChildrenProps}
            placeholder="Напишите дебиторов (нажимте Enter чтобы выбрать)"
          />
        )
      }
    };

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        <ApplicationStepTitle>
          Выбор дебиторов / покупателей
        </ApplicationStepTitle>

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="factoring-application-form-step-1"
            >
              <Input
                value={ApplicationTypes[applicationType]}
                name="applicationType"
                label="Тип заявки"
                disabled={true}
              />

              <RadioGroup
                name="debtorType"
                label="Тип дебиторов"
                required={true}
                keyValue={debtorType}
                onChange={this.onChange}
                radioBtns={Object.keys(DebtorsType).map(type => ({
                  value: type,
                  label: DebtorsType[type],
                  children: RadioChildren[type].component
                }))}
              />

              <ApplicationBtnsWrapper>
                <Button
                  template="backBtn"
                  label="Назад"
                  onClick={() =>
                    history.push({
                      pathname: this.navigateFromTo(`/${this.props.path}`, ''),
                      state: TRANSITION_DIRECTIONS.BACKWARDS
                    })
                  }
                />
                <Button template="nextBtn" label="Продолжить" type="submit" />
              </ApplicationBtnsWrapper>
            </ApplicationForm>
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }
}

const mapStateToProps = ({ Application }: STORE) => ({
  id: Application.setInitializationFormData.id,
  applicationType: Application.setInitializationFormData.financingType,
  debtorType: Application.setFactoringFormData.debtorType,
  debtors: Application.setFactoringFormData.debtors
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setFactoringFormData, sendApplicationDataReq, openModal },
    dispatch
  );

const FactoringFormStep1Connect = withRouter<any>(
  connect<StateToProps, DispatchToProps, void>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringFormStep1)
);

export { FactoringFormStep1Connect as FactoringFormStep1 };
