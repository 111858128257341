import {
  PATCH_PURCHASE_CONTRACT_SUPPLIER,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/patchPurchaseContractSupplier';

export const req = (guid: string, data: RequestDataType) => ({
  type: PATCH_PURCHASE_CONTRACT_SUPPLIER.REQ,
  guid,
  data
});

export const got = () => ({
  type: PATCH_PURCHASE_CONTRACT_SUPPLIER.GOT
});

export const err = (error: {}) => ({
  type: PATCH_PURCHASE_CONTRACT_SUPPLIER.ERR,
  error
});
