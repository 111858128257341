import * as React from 'react';

import { Document as IDocument } from 'Application/types';
import { DocumentsBlock } from 'Layouts/components';

import { ApplicationSubtitle } from '../../styles';
import { GeneralDocumentsBlockStyled } from './styles';
import { ApplicationViewConsumer } from '../../../ApplicationViewProvider';
import { EachApplicationProps } from '../../../ApplicationView';
import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';
import ApplicationApi from 'src/features/Application/api';
import { DownloadLink } from 'shared/styled';

interface Props {
  documents: IDocument[];
}

export const GeneralDocumentsBlock: React.FC<Props> = ({ documents }) =>
  documents.length > 0 && (
    <ApplicationViewConsumer>
      {({
        data,
        canEdit,
        editDocsRoute
      }: EachApplicationProps<GetApplicationResponseDataType>) => (
        <GeneralDocumentsBlockStyled>
          <ApplicationSubtitle>
            Общие документы
            <DownloadLink
              href={ApplicationApi.getDownloadApplicationDocumentsLink(data.id)}
            />
          </ApplicationSubtitle>
          <DocumentsBlock documents={documents} />
        </GeneralDocumentsBlockStyled>
      )}
    </ApplicationViewConsumer>
  );
