import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getDebtorNotificationSettings';
import { DEBTOR_NOTIFICATION_SETTINGS } from 'entities/SCF/Debtor/model/reducers/getDebtorNotificationSettings';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDebtorNotificationSettingsData(action: {
  type: DEBTOR_NOTIFICATION_SETTINGS;
}) {
  try {
    const res: any = yield call(SCFDebtorApi.getDebtorNotificationSettings);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorNotificationSettingsDataSaga() {
  yield takeLatest(
    DEBTOR_NOTIFICATION_SETTINGS.REQ,
    getDebtorNotificationSettingsData
  );
}
