import { SET_FACTORING_CONTRACT } from '../reducers/setFactoringContract';

export interface FactoringContractWrite {
  id: string;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  cessionMinimalShare: number;
  cessionMaximalShare: number;
  financeMinimalShare: number;
  financeMaximalShare: number;
  isRegressed: boolean;
  isNotHidden: boolean;
  gracePeriod: number;
  moveGracePeriodToBusinessDay: boolean;
  regressPeriod: number;
  moveRegressPeriodToBusinessDay: boolean;
  contraceFileIdentifyer: any;
  contraceFileName: string;
  supplierSignatureIdentifier: any;
  supplierSignatureFileName: string;
  debtorSignatureIdentifier: any;
  debtorSignatureFileName: string;
  factorSignatureIdentifier: any;
  factorSignatureFileName: string;
}

export interface FactoringContractRead extends FactoringContractWrite {
  id: string;
  supplierInn: string;
  supplierName: string;
  debtorInn: string;
  debtorName: string;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  contractEndDate: string;
  isPerpetual: boolean;
  cessionMinimalShare: number;
  cessionMaximalShare: number;
  financeMinimalShare: number;
  financeMaximalShare: number;
  isRegressed: boolean;
  isNotHidden: boolean;
  gracePeriod: number;
  moveGracePeriodToBusinessDay: boolean;
  regressPeriod: number;
  moveRegressPeriodToBusinessDay: boolean;
  contraceFileIdentifyer: any;
  contraceFileName: string;
  supplierSignatureIdentifier: any;
  supplierSignatureFileName: string;
  debtorSignatureIdentifier: any;
  debtorSignatureFileName: string;
  factorSignatureIdentifier: any;
  factorSignatureFileName: string;
}


export interface FactoringContract extends Partial<FactoringContractWrite> { }

export const setFactoringContract = (data: FactoringContract) => ({
  type: SET_FACTORING_CONTRACT.SET,
  data
});

export const resetFactoringContract = () => ({
  type: SET_FACTORING_CONTRACT.RESET
});
