import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/SCF/Internal/model/actions/getMonetaryClaim';

export interface GetMonetaryClaimOneInternalAgentStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT {
  REQ = 'GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT_REQ',
  GOT = 'GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT_GOT',
  ERR = 'GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT_ERR'
}

export const initialState: GetMonetaryClaimOneInternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getMonetaryClaimOneInternalAgent = (
  state: GetMonetaryClaimOneInternalAgentStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetMonetaryClaimOneInternalAgentStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIM_ONE_INTERNAL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimOneInternalAgent;
