import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';

import {
  Document,
  APPLICATION_STATUSES,
  GuaranteesApplicationRead
} from 'Application/types';

import {
  Spinner,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import {
  ApplicationViewStyled,
  ButtonContainer,
  CompanyHead,
  DownloadFile,
  FileContainer,
  IsNecessarily,
  IsUploadContainer,
  LoaderContainerStyled,
  NotDocumentList,
  Period,
  PlatformContainer,
  TableWrapper,
  TDDocumentName,
  TitleTabContainer,
  TooltipBoxStyle,
  UploadButton
} from './styles';
import {
  req as getDocumentList,
  DocumentListItems,
  ResponseData as ResDocumentList
} from 'entities/Cabinet/Bank/model/actions/getDocumentList';
import { req as getDownloadDocument } from 'entities/Cabinet/Bank/model/actions/getDownloadDocument';
import { PersonDocumentsType } from 'src/features/DocumentList/types';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import { RequestDataType as SetApplicationProcessModeRequestDataType } from 'Application/reducers/setApplicationProcessMode';
import { ResponseData } from 'Application/reducers/getCompanyHints';
import { ResponseData as ResponseProductOfApplication } from 'Application/actions/getProductApplication';
import { req as postSelectApplication } from 'Application/actions/postSelectApplication';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import {
  ModalContainer,
  PurchaseContent
} from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import { DelegateInternalAgentToApplicationSelect } from 'src/features/Application/components/ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { ContentContainer } from './ContentContainer/ContentContainer';
import { ActionModal } from './ActionModal/ActionModal';
import { ActionButtonsBlock } from './ActionButtonsBlock/ActionButtonsBlock';
import { CommentTabs } from './CommentTabs/CommentTabs';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ErrorIcon } from 'shared/styled';

export interface Info {
  name: string;
  value: JSX.Element | string;
}

export interface IApplicationInfo {
  mainInfo: Info[];
  subInfo: Info[];
}

export interface IApplicationDocuments {
  documents?: {
    general: Document[];
    company: Document[] | any;
  };
}

export type IApplicationData = IApplicationInfo & IApplicationDocuments;

export interface IOwnProps extends IApplicationData {
  children?: any;
}

interface StateToProps {
  bankApplication: Partial<GuaranteesApplicationRead>;
  statusApplication: REQUEST_STATUSES;
  switchBankStatus: REQUEST_STATUSES;
  sendToBankStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  sendApiError: string;
  documentList: ResDocumentList;
  statusDocumentList: REQUEST_STATUSES;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  postSelectApplication: (data: any) => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  getDocumentList: (guid: string) => void;
  getDownloadDocument: (guid: string, docGuid: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  IOwnProps &
  StateToProps &
  DispatchToProps;

const EachApplicationView: React.FC<Props> = ({
  setApplicationProcessMode,
  bankApplication,
  statusApplication,
  switchBankStatus,
  sendToBankStatus,
  permissions,
  roles,
  mainInfo,
  subInfo,
  documents,
  companyHints,
  productOfApplication,
  postSelectApplication,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  sendApiError,
  getDocumentList,
  documentList,
  statusDocumentList,
  getDownloadDocument,
  match,
  children
}: Props) => {
  const productRef = React.useRef<HTMLDivElement>(null);
  const sendApiErrorModalRef = React.useRef<HTMLDivElement>(null);
  const [isSendApiError, setIsSendApiError] = React.useState(false);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState([]);
  const [isSectionDocumentList, setIsSectionDocumentList] = React.useState(
    true
  );
  const [companyName, setCompanyName] = React.useState('');
  const [companyINN, setCompanyINN] = React.useState('');
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentListItems[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);

  React.useEffect(
    () => {
      if (statusApplication === REQUEST_STATUSES.GOT) {
        getDocumentList(bankApplication.guid);
      }
    },
    [bankApplication, statusApplication]
  );

  React.useEffect(
    () => {
      if (
        statusDocumentList === REQUEST_STATUSES.GOT &&
        !!documentList.items.length
      ) {
        setCompanyINN(documentList.items[0].companyINN);
        setCompanyName(documentList.items[0].companyShortName);

        const filtredInnDocuments = documentList.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        documentList.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            isUploadedExternal,
            guid,
            documentCategory,
            documentTypeId,
            extTypeDescription,
            documentGuid
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                isUploadedExternal,
                guid,
                documentCategory,
                documentTypeId,
                extTypeDescription,
                documentGuid
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [statusDocumentList]
  );

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        setFiltredFinancailsSummaryData(
          financailsSummaryData.sort((a, b) => a.year - b.year)
        );
      }
    },
    [financailsSummaryData]
  );

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      setIsSendApiError(false);
    }
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(bankApplication.code, year));
    });
  };

  const handleDownloadDocument = (docGuid: string) => {
    getDownloadDocument(bankApplication.guid, docGuid);
  };

  return (
    <ApplicationViewStyled>
      {/* Application modals, opens when you click on ActionButtons */}
      <ActionModal />
      {/* <ChooseBanksModal /> */}

      {/* Change application id block.
        Visible only in if application was transfered to banks  */}
      {/* <SwitchBanksBlock /> */}

      {/* Application content */}
      <ContentContainer
        application={bankApplication}
        mainInfo={mainInfo}
        subInfo={subInfo}
        documents={documents}
        permissions={permissions}
        roles={roles}
        companyHints={companyHints}
        productOfApplication={productOfApplication}
        postSelectApplication={postSelectApplication}
        scrollToProduct={scrollToProduct}
        sendRequestsGIR_BO={sendRequestsGIR_BO}
        financailsSummaryData={filtredFinancailsSummaryData}
        statusFinancailsSummaryData={statusFinancailsSummaryData}
        statusExternalApiGIR_BO={statusExternalApiGIR_BO}
        documentList={{ guid: '', items: [] }}
      >
        {children}
      </ContentContainer>

      {/* Application action buttons block,
        contains specific set of buttons, which changes application status */}
      <ActionButtonsBlock />

      {permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
        bankApplication.status ===
          APPLICATION_STATUSES.TRANSFERRED_TO_AGENT && (
          <DelegateInternalAgentToApplicationSelect
            applicationId={bankApplication.id}
          />
        )}
      {/* Application Comments, not visible in drafts */}
      <CommentTabs />

      <TitleTabContainer>
        <div>
          <p>Список документов</p>
        </div>

        <FontAwesomeIcon
          icon={isSectionDocumentList ? faChevronUp : faChevronDown}
          onClick={() => setIsSectionDocumentList(prevState => !prevState)}
        />
      </TitleTabContainer>

      {isSectionDocumentList &&
        (!!documentList.items.length &&
        bankApplication.status !== APPLICATION_STATUSES.TRANSFERRED_TO_BANK ? (
          <>
            <CompanyHead>
              <p>{companyName}</p>
              <p>ИНН: {companyINN}</p>
            </CompanyHead>

            <TableWrapper>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="10%">Тип документа</TableThStyled>
                    <TableThStyled width="10%">Период</TableThStyled>
                    <TableThStyled width="30%">На платформе FF</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {filtredInnDocuments.map(document => (
                    <TableRowStyled key={document.guid}>
                      <TDDocumentName text={document.documentName}>
                        <div>{document.documentName}</div>
                        {document.documentCategory === 2 &&
                          document.documentTypeId === 19 && (
                            <div>
                              {document.extTypeDescription}
                              <TooltipBoxStyle>
                                {document.extTypeDescription}
                              </TooltipBoxStyle>
                            </div>
                          )}
                      </TDDocumentName>
                      <td>
                        <Period>
                          <p>
                            {document.taxYear && document.taxPeriodName
                              ? `${document.taxYear}, ${document.taxPeriodName}`
                              : '-'}
                          </p>
                          {document.isMandatory && (
                            <IsNecessarily isUploaded={document.isUploaded}>
                              <p>*Обязательный</p>
                            </IsNecessarily>
                          )}
                        </Period>
                      </td>
                      <td>
                        <PlatformContainer>
                          <FileContainer>
                            <IsUploadContainer isUploaded={document.isUploaded}>
                              {document.isUploaded ? (
                                <div>
                                  <FontAwesomeIcon icon={faCheck} />
                                  <p>Загружен</p>
                                </div>
                              ) : (
                                <div>
                                  <FontAwesomeIcon icon={faTimes} />
                                  <p>Не загружен</p>
                                </div>
                              )}
                            </IsUploadContainer>
                            {document.isUploaded && (
                              <DownloadFile
                                onClick={() =>
                                  handleDownloadDocument(document.documentGuid)
                                }
                              >
                                Скачать
                              </DownloadFile>
                            )}
                          </FileContainer>
                        </PlatformContainer>
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            </TableWrapper>

            {filtredPersonDocuments.map((person, index) => (
              <React.Fragment key={index}>
                <CompanyHead>
                  <p>{person.personName}</p>
                  <p>{person.personComment}</p>
                </CompanyHead>

                <TableWrapper>
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="12%">Тип документа</TableThStyled>
                        <TableThStyled width="25%">
                          На платформе FF
                        </TableThStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {person.documents.map((document, index) => (
                        <TableRowStyled key={index}>
                          <TDDocumentName>
                            <div>{document.documentName}</div>
                            {document.documentCategory === 2 &&
                              document.documentTypeId === 19 && (
                                <div>
                                  {document.extTypeDescription}
                                  <TooltipBoxStyle>
                                    {document.extTypeDescription}
                                  </TooltipBoxStyle>
                                </div>
                              )}
                          </TDDocumentName>
                          <td>
                            <PlatformContainer>
                              {document.isMandatory && (
                                <IsNecessarily isUploaded={document.isUploaded}>
                                  <p>*Обязательный</p>
                                </IsNecessarily>
                              )}

                              <FileContainer>
                                <IsUploadContainer
                                  isUploaded={document.isUploaded}
                                >
                                  {document.isUploaded ? (
                                    <div>
                                      <FontAwesomeIcon icon={faCheck} />
                                      <p>Загружен</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <FontAwesomeIcon icon={faTimes} />
                                      <p>Не загружен</p>
                                    </div>
                                  )}
                                </IsUploadContainer>
                                {document.isUploaded && (
                                  <DownloadFile
                                    onClick={() =>
                                      handleDownloadDocument(
                                        document.documentGuid
                                      )
                                    }
                                  >
                                    Скачать
                                  </DownloadFile>
                                )}
                              </FileContainer>
                            </PlatformContainer>
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>
                </TableWrapper>
              </React.Fragment>
            ))}
          </>
        ) : (
          <NotDocumentList>
            <ErrorIcon />
            <p>
              Список документов станет доступен, если вы возьмете заявку в
              работу
            </p>
          </NotDocumentList>
        ))}

      <ModalContainer
        visible={isSendApiError}
        onClick={e => handleOutsideClick(e, sendApiErrorModalRef)}
      >
        <PurchaseContent ref={sendApiErrorModalRef}>
          <p>{sendApiError}</p>
        </PurchaseContent>
      </ModalContainer>

      {(switchBankStatus === REQUEST_STATUSES.REQUEST ||
        sendToBankStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={100} />
        </LoaderContainerStyled>
      )}

      <ActionResponseBlock
        showIn={switchBankStatus === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ Application, BankRole, User, Leads }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data,
  statusApplication: BankRole.getApplicationBank.status,
  companyHints: Application.getCompanyHints.data,
  switchBankStatus: Application.getInBankApplication.status,
  sendToBankStatus: Application.sendApplicationToBanks.status,
  productOfApplication: Application.getProductApplication.data,
  statusProductOfApplication: Application.getProductApplication.status,
  errorProductOfApplication: Application.getProductApplication.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  activities: Application.getActivitiesByApplication.data,
  statusActivities: Application.getActivitiesByApplication.status,
  errorActivities: Application.getActivitiesByApplication.error,
  statusPostAlfaBank: Application.postExternakApiAlfaBank.status,
  statusPostPSB: Application.postExternakApiPSB.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  errorSendToBank: Application.putSendToBank.error,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  sendApiError: Application.postOfferSend.error.body,
  documentList: BankRole.getDocumentList.data,
  statusDocumentList: BankRole.getDocumentList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setApplicationProcessMode,
      postSelectApplication,
      getExternalApiGIR_BO,
      getDocumentList,
      getDownloadDocument
    },
    dispatch
  );

const EachApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachApplicationView)
);

export { EachApplicationViewConnect as EachApplicationView };
