import { GET_BENEFICIARIES } from '../reducers/getBeneficiaries';
import { BeneficiariesTypes } from '../types';

export const req = (inn: string) => ({
  type: GET_BENEFICIARIES.REQ,
  inn
});

export const got = (data: BeneficiariesTypes[]) => ({
  type: GET_BENEFICIARIES.GOT,
  data
});

export const err = (err: {}) => ({
  type: GET_BENEFICIARIES.ERR,
  err
});
