import { FactoringApplicationWrite, DEBTOR_TYPE } from 'Application/types';

export type SetFactoringApplicationType = Partial<FactoringApplicationWrite>;

export enum FACTORING_FORM_ACTIONS {
  SET = 'SET_FACTORING_FORM_DATA',
  RESET = 'RESET_FACTORING_FORM_DATA'
}

export const initialState: FactoringApplicationWrite = {
  debtorType: DEBTOR_TYPE.FEDERAL,
  debtors: [],
  documents: []
};

const setFactoringFormData = (
  state: FactoringApplicationWrite = initialState,
  action: {
    type: FACTORING_FORM_ACTIONS;
    data: SetFactoringApplicationType;
  }
): FactoringApplicationWrite => {
  switch (action.type) {
    case FACTORING_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case FACTORING_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default setFactoringFormData;
