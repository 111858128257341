import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getSecondCompanyByInnThirdParty';
import { GET_SECOND_COMPANY_BY_INN_THIRD_PARTY } from '../reducers/getSecondCompanyByInnThirdParty';
import SCFApi from '../api';

function* getSecondCompanyByInnThirdParty(action: {
  type: GET_SECOND_COMPANY_BY_INN_THIRD_PARTY;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getSecondCompanyByInnThirdParty, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSecondCompanyByInnThirdPartySaga() {
  yield takeLatest(
    GET_SECOND_COMPANY_BY_INN_THIRD_PARTY.GET_SECOND_COMPANY_BY_INN_THIRD_PARTY_REQ,
    getSecondCompanyByInnThirdParty
  );
}
