import { ApplicationRead } from 'Application/types';

export enum FINANCING_CREDIT_TYPE {
  PROJECT_FINANCE = 'PROJECT_FINANCE',
  FIXED_ASSETS = 'FIXED_ASSETS',
  CONSTRUCTION_FINANCING = 'CONSTRUCTION_FINANCING',
  REAL_ESTATE_FINANCING = 'REAL_ESTATE_FINANCING'
}

export enum FINANCING_PAYMENT_CONFIRMATION {
  BILL_OF_EXCHANGE = 'BILL_OF_EXCHANGE',
  COMPANY_GUARANTEES = 'COMPANY_GUARANTEES'
}

export interface FinancingApplicationWrite {
  creditType: FINANCING_CREDIT_TYPE;

  equity: number;
  termInvestmentPhase: number;
  isAgriculture: boolean;
  paymentConfirmationInvestmentPhase: FINANCING_PAYMENT_CONFIRMATION;
}

export type FinancingApplicationRead = ApplicationRead &
  FinancingApplicationWrite;
