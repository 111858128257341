import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import { BankManagerForm } from 'Permissions/AddBankManagers/components/BankManagerForm/BankManagerForm';
import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { ResponseData as GetApplicationsQuantityDataType } from 'Application/reducers/getApplicationsQuantity';

import { Sidebar } from 'Layouts/components';

import { ApiKeyBlock } from 'src/features/ApiKeys/components/ApiKeyBlock';
import { ApplicationCreate, ApplicationView } from 'Application/components';
import { ApplicationEdit } from 'Application/components';
import { CabinetSection } from 'shared/styled';
import { ManagementSection } from './Sections/Managments';
import { AnalystSection } from './Sections/Analyst';
import { CreatedMeSection } from './Sections/CreatedMe';
import { LeadButtonsSection, LeadsSection } from './Sections/Leads';
import { LeadView } from 'src/features/Leads/components/LeadView/LeadView';
import { NewProduct } from 'src/features/ProductsForBank/components/NewProduct';
import { Profile } from 'src/features/Profile/components/Profile';
import { SuppliesSection } from './Sections/Supplies';
import { MainPage } from './Pages/MainPage/MainPage';
import { AssistiveSection } from './Sections/Assistive';

import { GuaranteesForm } from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesForm';
import { ContractFinancingForm } from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/ContractFinancingForm/ContractFinancingForm';
import { CreditingForm } from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/CreditingForm/CreditingForm';
import { FactoringForm } from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/FactoringForm/FactoringForm';

import { GuaranteesView } from './Pages/ApplicationView/GuaranteesView/GuaranteesView';
import { ContractFinancingView } from './Pages/ApplicationView/ContractFinancingView/ContractFinancingView';
import { CreditingView } from './Pages/ApplicationView/CreditingView/CreditingView';
import { FactoringView } from './Pages/ApplicationView/FactoringView/FactoringView';
import { Button } from 'shared/ui/Button';

export interface CabinetComponent {
  path?: string;
  whenToActivate?: boolean;
  hasPermission: (permissions: USER_PERMISSIONS[]) => boolean;
  component?: any;
  render?: (location: any) => JSX.Element;
  renderSidebarLink?: (key: any) => JSX.Element;
  userInn?: string;
}

interface StateToProps {
  applicationQuantity: GetApplicationsQuantityDataType;
  applicationQuantityStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  userCompanyInn: string;
  canCreateApplication: boolean;
}

interface DispatchToProps {
  getApplicationsQuantity: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

class EachPage extends React.Component<Props> {
  componentDidMount() {
    const { getApplicationsQuantity } = this.props;
    getApplicationsQuantity();
  }

  render() {
    const { permissions, canCreateApplication, history } = this.props;

    const componentsList: CabinetComponent[] = [
      {
        path: '/cabinet/internal/apikeys',
        render: () => <ApiKeyBlock />,
        hasPermission: () => true
      },
      {
        path: '/cabinet/internal/addBankManager',
        hasPermission: permissions =>
          permissions.includes(USER_PERMISSIONS.ADD_BANK_MANAGERS),
        renderSidebarLink: key => (
          <Button
            label="Добавить менеджера банка"
            key={key}
            backgroundColor="#1ca9c9"
            template="addBtn"
            h="49px"
            disabled={
              history.location.pathname.indexOf('/addBankManager') !== -1
            }
            onClick={() => history.push('/cabinet/internal/addBankManager')}
          />
        ),
        render: location => <BankManagerForm />
      },
      {
        path: '/cabinet/internal/application-form/create',
        component: ApplicationCreate,
        hasPermission: permissions =>
          permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS),
        renderSidebarLink: key => (
          <Button
            key={key}
            label="Создать заявку"
            template="addBtn"
            disabled={
              !canCreateApplication ||
              history.location.pathname.indexOf('/application-form') !== -1
            }
            onClick={() =>
              history.push('/cabinet/internal/application-form/create')
            }
            w="100%"
            h="50px"
          />
        )
      },

      {
        path: '/cabinet/internal/application-form/edit/:id/:inBankId?',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <ApplicationEdit />
      },
      {
        path: '/cabinet/internal/application-form-guarantees/edit/:id',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <GuaranteesForm />
      },
      {
        path: '/cabinet/internal/application-form-contract-financing/edit/:id',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <ContractFinancingForm />
      },
      {
        path: '/cabinet/internal/application-form-crediting/edit/:id',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <CreditingForm />
      },
      {
        path: '/cabinet/internal/application-form-factoring/edit/:id',
        hasPermission: permissions =>
          [
            USER_PERMISSIONS.CONTROL_ANY_APPLICATION,
            USER_PERMISSIONS.CREATE_APPLICATIONS
          ].isIn(permissions),
        render: () => <FactoringForm />
      },

      ...LeadButtonsSection,
      ...SuppliesSection,
      ...ManagementSection,
      ...AnalystSection,
      ...CreatedMeSection,
      ...LeadsSection,
      ...AssistiveSection
    ];

    return (
      <React.Fragment>
        <Sidebar>
          {componentsList.map(
            (component, key) =>
              component.hasPermission(permissions) &&
              component.renderSidebarLink &&
              component.renderSidebarLink(key)
          )}
        </Sidebar>

        <CabinetSection>
          <Switch>
            {componentsList.map(
              (component, key) =>
                component.path &&
                component.hasPermission(permissions) && (
                  <Route
                    key={key}
                    path={component.path}
                    render={
                      component.render
                        ? ({ location }) => component.render(location)
                        : null
                    }
                    component={component.component}
                  />
                )
            )}

            <Route
              exact={true}
              path="/cabinet/internal"
              render={() => <MainPage />}
            />

            <Route
              exact={true}
              path="/cabinet/internal/application/:id/:inBankId?"
              component={ApplicationView}
            />

            <Route
              exact={true}
              path="/cabinet/internal/application-guarantees/:id/:inBankId?"
              component={GuaranteesView}
            />

            <Route
              exact={true}
              path="/cabinet/internal/application-contract-financing/:id/:inBankId?"
              component={ContractFinancingView}
            />

            <Route
              exact={true}
              path="/cabinet/internal/application-crediting/:id/:inBankId?"
              component={CreditingView}
            />

            <Route
              exact={true}
              path="/cabinet/internal/application-factoring/:id/:inBankId?"
              component={FactoringView}
            />

            <Route
              exact={true}
              path="/cabinet/internal/lead/:id"
              component={() => <LeadView />}
            />

            <Route
              exact={true}
              path="/cabinet/internal/profile"
              component={() => <Profile />}
            />

            <Route
              exact={true}
              path="/cabinet/internal/products/new"
              component={() => <NewProduct />}
            />
          </Switch>
        </CabinetSection>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ Application, User }: STORE) => ({
  applicationQuantity: Application.getApplicationsQuantity.data,
  applicationQuantityStatus: Application.getApplicationsQuantity.status,
  permissions: User.getUserData.data.permissions,
  userCompanyInn: User.getUserData.data.companyInn,
  canCreateApplication: User.getUserData.data.permissions.includes(
    USER_PERMISSIONS.CREATE_APPLICATIONS
  )
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getApplicationsQuantity }, dispatch);

const EachPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachPage)
);

export { EachPageConnect as EachPage };
