import { combineReducers } from 'redux';
import getFinancialDocuments, {
  GetFinancialDocumentsStoreState
} from './getFinancialDocuments';
import getNonFinancialDocuments, {
  GetNonFinancialDocumentsStoreState
} from './getNonFinancialDocuments';

export interface DossierReducers {
  getFinancialDocuments: GetFinancialDocumentsStoreState;
  getNonFinancialDocuments: GetNonFinancialDocumentsStoreState;
}

export const Dossier = combineReducers({
  getFinancialDocuments,
  getNonFinancialDocuments
});
