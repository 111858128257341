import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/confirmExternalAgent';
import { EXTERNAL_AGENT_STATUSES } from 'src/features/ExternalAgent/reducers/externalAgentStatus';

export interface ConfirmExternalAgentStoreState extends Reducer {
  data: ResponseDataType;
}

export enum CONFIRM_EXTERNAL_AGENT {
  CONFIRM_EXTERNAL_AGENT_REQ = 'CONFIRM_EXTERNAL_AGENT_REQ',
  CONFIRM_EXTERNAL_AGENT_GOT = 'CONFIRM_EXTERNAL_AGENT_GOT',
  CONFIRM_EXTERNAL_AGENT_ERR = 'CONFIRM_EXTERNAL_AGENT_ERR'
}

export const initialState: ConfirmExternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: 0,
    userId:0,
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    status: EXTERNAL_AGENT_STATUSES.NONE,
    email: '',
    legalForm: '',
    code: '',
    isLeadogenerator: false
  },
  error: {}
};

const confirmExternalAgent = (
  state: ConfirmExternalAgentStoreState = initialState,
  action: {
    type: CONFIRM_EXTERNAL_AGENT;
    data: ResponseDataType;
    error: {};
  }
): ConfirmExternalAgentStoreState => {
  switch (action.type) {
    case CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case CONFIRM_EXTERNAL_AGENT.CONFIRM_EXTERNAL_AGENT_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default confirmExternalAgent;
