import * as React from 'react';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from 'src/shared/utils/History';
import {
  ApplicationId,
  ApplicationTitle
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ContainerInputs,
  ErrorMsg,
  InputDetail,
  WrapperInput,
  WrapperInputDetail
} from 'src/features/Contacts/components/CreateNewContact/styled';
import {
  req as createContact,
  reset as resetStateContact
} from '../../actions/createContact';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { InfoField } from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import { Button } from 'src/shared/ui/Button';

interface State {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  phoneContact: string;
  phoneStationary: string;
  email: string;
  companyInn: string;
  companyName: string;
  position: string;
  isInfoPopup: boolean;
  errors: {
    lastName: string;
    firstName: string;
    middleName: string;
    email: string;
    phoneNumber: string;
    phoneContact: string;
    phoneStationary: string;
    companyInn: string;
    companyName: string;
    position: string;
    requiredFields: string;
  };
}

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  createStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  createContact: (data: any) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateContact: () => void;
}

interface OwnProps {}

type Props = OwnProps & StateToProps & DispatchToProps;

class CreateNewContact extends React.Component<Props, State> {
  state: State = {
    firstName: '',
    lastName: '',
    middleName: '',
    phoneNumber: '',
    phoneContact: '',
    phoneStationary: '',
    email: '',
    companyInn: '',
    companyName: '',
    position: '',
    isInfoPopup: false,
    errors: {
      lastName: '',
      firstName: '',
      middleName: '',
      email: '',
      phoneNumber: '',
      phoneContact: '',
      phoneStationary: '',
      companyInn: '',
      companyName: '',
      position: '',
      requiredFields: ''
    }
  };

  componentDidMount() {
    if (history.location.state && history.location.state.inn) {
      this.setState({ companyInn: history.location.state.inn });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetStateContact();
      history.goBack();
    }
    if (prevState.companyInn !== this.state.companyInn) {
      this.setState({ isInfoPopup: false });
    }
    if (
      prevState.companyInn !== this.state.companyInn &&
      this.state.companyInn.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.state.companyInn });
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value } as any);
  };

  handleCreateContact = event => {
    event.preventDefault();

    const regex = /^[а-яА-Я\s\-]*$/;
    const innRegex = /^[a-zA-Z0-9]+$/;
    const emailRegex = /^[A-Za-z0-9@._-]*$/;

    const errors: State['errors'] = {
      lastName: '',
      firstName: '',
      middleName: '',
      email: '',
      phoneNumber: '',
      phoneContact: '',
      phoneStationary: '',
      companyInn: '',
      companyName: '',
      position: '',
      requiredFields: ''
    };

    if (this.state.lastName !== null && !regex.test(this.state.lastName)) {
      errors.lastName = 'Введите корректную фамилию';
    }

    if (this.state.firstName !== null && !regex.test(this.state.firstName)) {
      errors.firstName = 'Введите корректное имя';
    }

    if (this.state.middleName !== null && !regex.test(this.state.middleName)) {
      errors.middleName = 'Введите корректное отчество';
    }

    if (this.state.email !== null && !emailRegex.test(this.state.email)) {
      errors.email = 'Введите корректный email';
    }

    if (
      this.state.companyInn !== null &&
      !innRegex.test(this.state.companyInn)
    ) {
      errors.companyInn = 'Введите корректный ИНН';
    }

    if (this.state.position !== null && !regex.test(this.state.position)) {
      errors.position = 'Введите корректную должность';
    }

    this.setState({ errors });

    if (
      !(
        this.state.firstName ||
        this.state.phoneNumber.length ||
        this.state.email
      )
    ) {
      errors.requiredFields = 'Введите имя, номер телефона или email';
    }

    if (Object.values(errors).every(error => error === '')) {
      const { errors, isInfoPopup, companyName, ...data } = this.state;
      this.props.createContact(data);
    }
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  render() {
    const { companyByInn, statusInn } = this.props;
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание контакта</ApplicationTitle>

        <ContainerInputs onSubmit={this.handleCreateContact}>
          <WrapperInput>
            <ApplicationId>Контактные данные</ApplicationId>
            <WrapperInputDetail>
              <p>Фамилия:</p>
              <InputDetail
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.lastName && (
              <ErrorMsg>{this.state.errors.lastName}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Имя:</p>
              <InputDetail
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.firstName && (
              <ErrorMsg>{this.state.errors.firstName}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Отчество:</p>
              <InputDetail
                name="middleName"
                value={this.state.middleName}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.middleName && (
              <ErrorMsg>{this.state.errors.middleName}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Телефон 1:</p>
              <InputDetail
                name="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.phoneNumber && (
              <ErrorMsg>{this.state.errors.phoneNumber}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Телефон 2:</p>
              <InputDetail
                name="phoneContact"
                value={this.state.phoneContact}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.phoneContact && (
              <ErrorMsg>{this.state.errors.phoneContact}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Телефон 3:</p>
              <InputDetail
                name="phoneStationary"
                value={this.state.phoneStationary}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.phoneStationary && (
              <ErrorMsg>{this.state.errors.phoneStationary}</ErrorMsg>
            )}
            <WrapperInputDetail>
              <p>Email:</p>
              <InputDetail
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.email && (
              <ErrorMsg>{this.state.errors.email}</ErrorMsg>
            )}
            <Button type="submit" label="Создать" w="100px" />
            {this.state.errors.requiredFields && (
              <ErrorMsg>{this.state.errors.requiredFields}</ErrorMsg>
            )}
          </WrapperInput>
          <WrapperInput>
            <ApplicationId>Данные компании</ApplicationId>
            <WrapperInputDetail>
              <p>ИНН:</p>
              <InputDetail
                name="companyInn"
                value={this.state.companyInn}
                onChange={this.handleInputChange}
              />

              {this.state.companyInn &&
              this.state.companyInn.length < 10 ? null : statusInn ===
              REQUEST_STATUSES.ERROR ? (
                <InnPopup>
                  <h2>
                    Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                    можете создать связку с введенным ИНН
                  </h2>
                </InnPopup>
              ) : (
                statusInn === REQUEST_STATUSES.GOT &&
                !this.state.isInfoPopup && (
                  <InnPopup
                    companyInfo={companyByInn}
                    onPopupClick={this.onPopupClick}
                  />
                )
              )}
            </WrapperInputDetail>

            {this.state.isInfoPopup && (
              <InfoField>
                <h2>{companyByInn.companyFullName}</h2>
                {companyByInn.companyAddress &&
                companyByInn.companyAddress.length > 3 ? (
                  <p>{companyByInn.companyAddress}</p>
                ) : (
                  ''
                )}
                <p>ИНН {companyByInn.inn}</p>
                <p>КПП {companyByInn.kpp}</p>
              </InfoField>
            )}

            {this.state.errors.companyInn && (
              <ErrorMsg>{this.state.errors.companyInn}</ErrorMsg>
            )}

            {(this.props.statusInn !== REQUEST_STATUSES.GOT ||
              !this.props.companyByInn.inn.length ||
              this.state.companyInn.length < 10) && (
              <WrapperInputDetail>
                <p>Наименование:</p>
                <InputDetail
                  name="companyName"
                  value={this.state.companyName}
                  onChange={this.handleInputChange}
                />
              </WrapperInputDetail>
            )}

            {this.state.errors.companyName && (
              <ErrorMsg>{this.state.errors.companyName}</ErrorMsg>
            )}

            <WrapperInputDetail>
              <p>Должность:</p>
              <InputDetail
                name="position"
                value={this.state.position}
                onChange={this.handleInputChange}
              />
            </WrapperInputDetail>
            {this.state.errors.position && (
              <ErrorMsg>{this.state.errors.position}</ErrorMsg>
            )}
          </WrapperInput>
        </ContainerInputs>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ SCF, Contacts }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  createStatus: Contacts.createContact.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { createContact, getCompanyByInn, resetStateContact },
    dispatch
  );

const CreateNewContactConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewContact);

export { CreateNewContactConnect as CreateNewContact };
