import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostOfferDiscountStoreState = Reducer;

export enum POST_OFFER_DISCOUNT {
  REQ = 'POST_OFFER_DISCOUNT_REQ',
  GOT = 'POST_OFFER_DISCOUNT_GOT',
  ERR = 'POST_OFFER_DISCOUNT_ERR',
  RESET = 'POST_OFFER_DISCOUNT_RESET'
}

export const initialState: PostOfferDiscountStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postOfferDiscount = (
  state: PostOfferDiscountStoreState = initialState,
  action: {
    type: POST_OFFER_DISCOUNT;
    error: {};
  }
): PostOfferDiscountStoreState => {
  switch (action.type) {
    case POST_OFFER_DISCOUNT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_OFFER_DISCOUNT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_OFFER_DISCOUNT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_OFFER_DISCOUNT.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default postOfferDiscount;
