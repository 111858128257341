import { POST_UPLOAD_NON_FIN_DOC_CLIENT } from 'entities/Cabinet/Client/model/reducers/postUploadNonFinDocClient';

export const req = (guid: string, data: FormData) => ({
  type: POST_UPLOAD_NON_FIN_DOC_CLIENT.REQ,
  data,
  guid
});

export const got = () => ({
  type: POST_UPLOAD_NON_FIN_DOC_CLIENT.GOT
});

export const err = (error: {}) => ({
  type: POST_UPLOAD_NON_FIN_DOC_CLIENT.ERR,
  error
});
