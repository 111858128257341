import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { differenceInDays, format } from 'date-fns';

import {
  TableHeaderCellCenteredStyled,
  TableHeaderCellStyled,
  TableStyled,
  TableStyledWrapped
} from 'src/features/SCF/UI/Table/styles';
import {
  TableRowStyled,
  TdCentred
} from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import {
  req as getMonetaryClaimsEarlyPaymentApplications,
  RequestDataType as ReqMonetaryClaimsEarlyPayment,
  Calc as ResMonetaryClaimsEarlyPayment
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  ContractsPageStyled,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  BackBtn,
  BackBtnText,
  ConfirmSection,
  DownloadLink,
  TDInvoiceDocumenContainer,
  InvoiceDocumentDate,
  DocumentHint,
  SkeletonWrapper,
  MonetaryClaimsContainer,
  ConfirmSectionRightPart,
  SummarySection,
  ListSection,
  FullList,
  TableContainer,
  Spinner as SpinnerApi
} from './styles';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import { REQUEST_STATUSES, ResponseError, STORE } from 'globaltypes';
import SCFApi from 'entities/SCF/Supplier/api';
import { Loader } from 'src/features/Layouts/components';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { DiscountItem } from '../ApplicationCalc/styles';

interface StateToProps {
  investorOneDebtor: OneDebtorResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResMonetaryClaimsEarlyPayment[];
  monetaryClaimsHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
  statusInvestorOneDebtor: REQUEST_STATUSES;
  statusCreateAgreement: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  getMonetaryClaimsEarlyPaymentApplications: (
    data: ReqMonetaryClaimsEarlyPayment
  ) => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Agreement: React.FC<Props> = ({
  getByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  resetByMonetaryClaimHint,
  getInvestorOneDebtor,
  investorOneDebtor,
  statusInvestorOneDebtor,
  getMonetaryClaimsEarlyPaymentApplications,
  monetaryClaimsEarlyPaymentApplications,
  status,
  statusCreateAgreement,
  history,
  match
}) => {
  const [isActiveSubscribe, setIsActiveSubscribe] = React.useState(false);
  const [isCertificates, setIsCertificates] = React.useState(false);
  const [isCertificatesLoading, setIsCertificatesLoading] = React.useState(
    false
  );
  const [isCertificatesError, setIsCertificatesError] = React.useState(false);

  React.useEffect(
    () => {
      if (match.params.id) {
        getInvestorOneDebtor({ guid: match.params.id });
        getMonetaryClaimsEarlyPaymentApplications({ guid: match.params.id });

        const base64ToBlob = (base64, type = 'application/octet-stream') => {
          const binStr = atob(base64);
          const len = binStr.length;
          const arr = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          return new Blob([arr], { type: type });
        };

        const arrayBufferToBase64 = buffer => {
          let binary = '';
          const bytes = new Uint8Array(buffer);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        };

        fetch(
          `/api/early_payment_applications/supplier_role/${
            match.params.id
          }/get_agreement_html_file`
        )
          .then(res => res.arrayBuffer())
          .then(data => {
            var base64Str = arrayBufferToBase64(data);
            const blob = base64ToBlob(base64Str, 'application/pdf');
            const url = URL.createObjectURL(blob);
            var outputObject = document.createElement('iframe');
            var outputText = document.createElement('p');
            outputText.style.margin = '30px 0 8px 0';
            outputText.style.fontFamily = 'Roboto-Regular';
            outputText.innerHTML = '* Превью договора';
            outputObject.src = url + '#toolbar=0';
            outputObject.style.width = '1200px';
            outputObject.style.height = '1000px';
            outputObject.style.background = '#262B41';
            document.getElementById('documentObject').appendChild(outputText);
            document.getElementById('documentObject').appendChild(outputObject);
            setIsActiveSubscribe(true);
          })
          .catch(() => setIsActiveSubscribe(false));
      }
    },
    [match.params.id]
  );

  const onBackClick = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const onCloseIconClick = () => {
    setIsCertificates(false);
  };

  const onSignClick = () => {
    setIsCertificates(true);
  };

  const onCloseErrorIconClick = () => {
    setIsCertificatesError(false);
  };

  const onNudgeClick = () => {
    history.push(`/supplier/settings/certificates/edit`);
  };

  const DiscountCounter = () => {
    let discountCounter: number;
    discountCounter = 0;
    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      discountCounter += monetaryClaimsEarlyPaymentApplications[i].baseFullSumm;
    }
    return discountCounter;
  };

  const DiscountCounterToGet = () => {
    let discountCounterToGet: number;
    discountCounterToGet = 0;
    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      discountCounterToGet += summToGet(i);
    }
    return discountCounterToGet;
  };

  const index = (): number => {
    if (monetaryClaimsEarlyPaymentApplications[0].baseSumm === 0) {
      return 1;
    }
    return 1.2;
  };

  const intermediateDiscount = (): number => {
    if (monetaryClaimsEarlyPaymentApplications[0].baseSumm > 0) {
      return Math.round(investorOneDebtor.baseDiscountRate * 1.2 * 1000) / 1000;
    }
    return Math.round(investorOneDebtor.baseDiscountRate * 1000) / 1000;
  };

  const fullRate = (i: number): number => {
    return (
      Math.round(
        intermediateDiscount() *
          (differenceInDays(
            new Date(monetaryClaimsEarlyPaymentApplications[i].maturityDate),
            new Date(investorOneDebtor.payDate)
          ) /
            365) *
          10000
      ) /
      10000 /
      100
    );
  };

  const summToGet = (i: number): number => {
    return (
      monetaryClaimsEarlyPaymentApplications[i].baseFullSumm * (1 - fullRate(i))
    );
  };

  return (
    <ContractsPageStyled>
      {((status === REQUEST_STATUSES.REQUEST &&
        statusInvestorOneDebtor === REQUEST_STATUSES.REQUEST) ||
        statusCreateAgreement === REQUEST_STATUSES.REQUEST) && <Loader />}

      <CreateLimitsNudge>
        <h2>Соглашение о ранней оплате:</h2>
      </CreateLimitsNudge>
      <ListSection>
        <FullList>
          {investorOneDebtor.payerType === 1 ? (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    ПОКУПАТЕЛЬ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ПОСТАВЩИК
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td>{investorOneDebtor.supplierName}</td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          ) : (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    ДЕБИТОР
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ФАКТОРА
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    СУММА СЧЕТОВ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="20%">
                    ДАТА РАННЕЙ ОПЛАТЫ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="18%">
                    ID ЗАЯВКИ
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td>{investorOneDebtor.payerName}</td>
                  <td>
                    {investorOneDebtor.monetaryClaimsTotalFullSum.toLocaleString(
                      'ru',
                      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    )}
                  </td>

                  {investorOneDebtor.payDate != null ? (
                    <td>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                  <td>
                    {investorOneDebtor.guid.substring(
                      investorOneDebtor.guid.length - 5
                    )}
                  </td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          )}
        </FullList>
      </ListSection>

      <TableContainer>
        {status === REQUEST_STATUSES.GOT &&
        statusInvestorOneDebtor === REQUEST_STATUSES.GOT &&
        investorOneDebtor.payerType === 1 ? (
          <MonetaryClaimsContainer>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellCenteredStyled width="6%">
                    ID
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДОКУМЕНТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДАТА ОПЛАТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="16%">
                    СРОК ДИСКОНТА ДН.
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellStyled
                    width="16%"
                    style={{ paddingTop: '14px' }}
                  >
                    СТАВКА ДИСКОНТА
                    <br />
                    %годовых
                  </TableHeaderCellStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДИСКОНТ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА К ПОЛУЧЕНИЮ
                  </TableHeaderCellCenteredStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                  <TableRowStyled key={key}>
                    <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </TdCentred>

                    <TdCentred>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          textAlign: 'center',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>
                    {item.baseVatSumm !== 0 && item.baseVatSumm !== null ? (
                      <TdCentred>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px'
                          }}
                        >
                          {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              width: 'auto'
                            }}
                          >
                            без НДС
                          </p>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px'
                          }}
                        >
                          {(
                            investorOneDebtor.baseDiscountRate * index()
                          ).toFixed(3)}
                          %{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              width: 'auto'
                            }}
                          >
                            с НДС
                          </p>
                        </p>
                      </TdCentred>
                    ) : (
                      <td style={{ paddingLeft: '20px' }}>
                        {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                      </td>
                    )}
                    <TdCentred>
                      {(fullRate(key) * 100).toLocaleString('ru', {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}
                      %
                    </TdCentred>
                    <TdCentred>
                      {summToGet(key).toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <SummarySection style={{ marginTop: '10px' }}>
              <ConfirmSectionRightPart>
                <DiscountItem>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    Сумма требований без дисконта
                  </p>
                  <p
                    className="discount-1"
                    id="discount-1"
                    style={{ color: '#9FA6B2', fontSize: '14px' }}
                  >
                    {DiscountCounter().toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    руб.
                  </p>
                </DiscountItem>
                <DiscountItem>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    Дисконт за раннюю оплату
                  </p>
                  <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                    {(
                      DiscountCounter() - DiscountCounterToGet()
                    ).toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    руб.
                  </p>
                </DiscountItem>
                <DiscountItem>
                  <p style={{ fontSize: '16px' }}>Сумма к получению</p>
                  <p style={{ fontSize: '16px' }}>
                    {DiscountCounterToGet().toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    руб.
                  </p>
                </DiscountItem>
              </ConfirmSectionRightPart>
            </SummarySection>
          </MonetaryClaimsContainer>
        ) : (
          status === REQUEST_STATUSES.GOT &&
          statusInvestorOneDebtor === REQUEST_STATUSES.GOT &&
          investorOneDebtor.payerType === 2 && (
            <TableStyledWrapped sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellCenteredStyled width="7%">
                    ID
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="15%">
                    СУММА БЕЗ НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="8%">
                    НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА С НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДОКУМЕНТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="16%">
                    ДОЛЯ ФИНАНСИРОВАНИЯ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДАТА ОПЛАТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="17%">
                    СРОК ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="20%">
                    СТАВКА ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    ПРЕДВАРИТЕЛЬНАЯ СТОИМОСТЬ ФАКТОРИНГА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="28%">
                    ПРОГНОЗ К ПОЛУЧЕНИЮ
                  </TableHeaderCellCenteredStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                  <TableRowStyled key={key}>
                    <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </TdCentred>
                    <TdCentred>
                      {item.baseSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    {item.baseVatPecentage != null ? (
                      <TdCentred>
                        {item.baseVatPecentage.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                        %
                      </TdCentred>
                    ) : (
                      <TdCentred />
                    )}
                    <TdCentred>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      {Math.min(
                        investorOneDebtor.firstPaymentShare,
                        100 /
                          (1 +
                            (investorOneDebtor.baseDiscountRate *
                              1.2 *
                              (differenceInDays(
                                new Date(item.maturityDate),
                                new Date(investorOneDebtor.payDate)
                              ) /
                                365)) /
                              100)
                      ).toLocaleString('ru', {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}{' '}
                      %
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          textAlign: 'center',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {investorOneDebtor.baseDiscountRate &&
                          investorOneDebtor.baseDiscountRate.toLocaleString(
                            'ru',
                            {
                              maximumFractionDigits: 3,
                              minimumFractionDigits: 3
                            }
                          )}
                        %{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          без НДС
                        </p>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          investorOneDebtor.baseDiscountRate * 1.2
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })}
                        %{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          с НДС
                        </p>
                      </p>
                    </TdCentred>
                    <TdCentred>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          investorOneDebtor.baseDiscountRate *
                          1.2 *
                          (differenceInDays(
                            new Date(item.maturityDate),
                            new Date(investorOneDebtor.payDate)
                          ) /
                            365)
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4
                        })}
                        %
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'center',
                          width: 'auto'
                        }}
                      >
                        {(
                          ((investorOneDebtor.baseDiscountRate *
                            1.2 *
                            (differenceInDays(
                              new Date(item.maturityDate),
                              new Date(investorOneDebtor.payDate)
                            ) /
                              365)) /
                            100) *
                          ((item.baseFullSumm *
                            Math.min(
                              investorOneDebtor.firstPaymentShare,
                              100 /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    (differenceInDays(
                                      new Date(item.maturityDate),
                                      new Date(investorOneDebtor.payDate)
                                    ) /
                                      365)) /
                                    100)
                            )) /
                            100)
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </p>
                    </TdCentred>
                    <td>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '5px 0',
                          justifyContent: 'space-between'
                        }}
                      >
                        {(
                          Math.round(
                            Math.min(
                              (item.baseFullSumm *
                                investorOneDebtor.firstPaymentShare) /
                                100,
                              item.baseFullSumm /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    Math.round(
                                      (differenceInDays(
                                        new Date(item.maturityDate),
                                        new Date(investorOneDebtor.payDate)
                                      ) /
                                        365) *
                                        10000
                                    )) /
                                    10000 /
                                    100)
                            ) * 100
                          ) / 100
                        ).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            marginLeft: '5px'
                          }}
                        >
                          {format(
                            new Date(investorOneDebtor.payDate),
                            'dd.MM.yyyy'
                          )}
                        </p>
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '5px 0',
                          justifyContent: 'space-between'
                        }}
                      >
                        {item.baseFullSumm -
                          (item.baseFullSumm *
                            Math.min(
                              investorOneDebtor.firstPaymentShare,
                              100 /
                                (1 +
                                  (investorOneDebtor.baseDiscountRate *
                                    1.2 *
                                    (differenceInDays(
                                      new Date(item.maturityDate),
                                      new Date(investorOneDebtor.payDate)
                                    ) /
                                      365)) /
                                    100)
                            )) /
                            100 -
                          ((investorOneDebtor.baseDiscountRate *
                            1.2 *
                            (differenceInDays(
                              new Date(item.maturityDate),
                              new Date(investorOneDebtor.payDate)
                            ) /
                              365)) /
                            100) *
                            ((item.baseFullSumm *
                              Math.min(
                                investorOneDebtor.firstPaymentShare,
                                100 /
                                  (1 +
                                    (investorOneDebtor.baseDiscountRate *
                                      1.2 *
                                      (differenceInDays(
                                        new Date(item.maturityDate),
                                        new Date(investorOneDebtor.payDate)
                                      ) /
                                        365)) /
                                      100)
                              )) /
                              100) <
                        0.001
                          ? (0).toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })
                          : (
                              item.baseFullSumm -
                              (item.baseFullSumm *
                                Math.min(
                                  investorOneDebtor.firstPaymentShare,
                                  100 /
                                    (1 +
                                      (investorOneDebtor.baseDiscountRate *
                                        1.2 *
                                        (differenceInDays(
                                          new Date(item.maturityDate),
                                          new Date(investorOneDebtor.payDate)
                                        ) /
                                          365)) /
                                        100)
                                )) /
                                100 -
                              ((investorOneDebtor.baseDiscountRate *
                                1.2 *
                                (differenceInDays(
                                  new Date(item.maturityDate),
                                  new Date(investorOneDebtor.payDate)
                                ) /
                                  365)) /
                                100) *
                                ((item.baseFullSumm *
                                  Math.min(
                                    investorOneDebtor.firstPaymentShare,
                                    100 /
                                      (1 +
                                        (investorOneDebtor.baseDiscountRate *
                                          1.2 *
                                          (differenceInDays(
                                            new Date(item.maturityDate),
                                            new Date(investorOneDebtor.payDate)
                                          ) /
                                            365)) /
                                          100)
                                  )) /
                                  100)
                            ).toLocaleString('ru', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            marginLeft: '5px'
                          }}
                        >
                          {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                        </p>
                      </p>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyledWrapped>
          )
        )}
      </TableContainer>

      <ConfirmSection>
        <BtnsBlock>
          <CreateBtn>
            <CreateBtnText
              onClick={onSignClick}
              isActive={isActiveSubscribe && !isCertificatesLoading}
            >
              Подписать ЭЦП
            </CreateBtnText>
            {isCertificatesLoading && <SpinnerApi icon={faSpinner} />}
          </CreateBtn>
          <DownloadLink
            href={SCFApi.downloadAgreementFileSupplierRole(match.params.id)}
          >
            <BackBtnText>Скачать</BackBtnText>
          </DownloadLink>
        </BtnsBlock>
        <BackBtn onClick={onBackClick}>
          <BackBtnText>Назад</BackBtnText>
        </BackBtn>
      </ConfirmSection>
      <div id="documentObject" style={{ width: '900px', height: '1000px' }} />

      <CertificatesPopup isActive={isCertificates}>
        <PopupExit>
          <p onClick={onCloseIconClick}>X</p>
        </PopupExit>
        <h1>Список сертификатов в системе</h1>
        <CertificatesArea>
          <CertificatesListPopup
            setIsCertificates={setIsCertificates}
            setIsCertificatesError={setIsCertificatesError}
            setIsCertificatesLoading={setIsCertificatesLoading}
          />
        </CertificatesArea>
        <NudgeArea>
          <p>Нет нужного сертификата? </p>
          <Nudge onClick={onNudgeClick}>Перейдите в настройки добаления</Nudge>
        </NudgeArea>
      </CertificatesPopup>

      <CertificatesPopupError isActive={isCertificatesError}>
        <PopupExitError>
          <p onClick={onCloseErrorIconClick}>X</p>
        </PopupExitError>
        <h1>
          Невозможно подписать выбранным сертификатом
          <br />
          (не найден на устройстве)
        </h1>
      </CertificatesPopupError>
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCF, SCFSupplier, SCFDebtor }: STORE) => ({
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  statusInvestorOneDebtor:
    SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.status,
  monetaryClaimsEarlyPaymentApplications:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status,
  statusCreateAgreement: SCF.postCreateAgreementSupplierRole.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      getMonetaryClaimsEarlyPaymentApplications,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint
    },
    dispatch
  );

const AgreementConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Agreement)
);

export { AgreementConnect as Agreement };
