import {
  RequestDataType,
  CREATE_BORROWER_COMPANY
} from 'BorrowerCompanies/reducers/createBorrowerCompany';

export const req = (data: RequestDataType) => ({
  type: CREATE_BORROWER_COMPANY.REQ,
  data
});

export const got = () => ({
  type: CREATE_BORROWER_COMPANY.GOT
});

export const err = (error: {}) => ({
  type: CREATE_BORROWER_COMPANY.ERR,
  error
});

export const reset = () => ({
  type: CREATE_BORROWER_COMPANY.RESET
});
