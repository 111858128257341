import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/IntegrationApplications/actions/getAllIntegrationApplications';

export interface GetAllIntegrationApplicationsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ALL_INTEGRATION_APPLICATIONS {
  REQ = 'GET_ALL_INTEGRATION_APPLICATIONS_REQ',
  GOT = 'GET_ALL_INTEGRATION_APPLICATIONS_GOT',
  ERR = 'GET_ALL_INTEGRATION_APPLICATIONS_ERR'
}

export const initialState: GetAllIntegrationApplicationsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getAllIntegrationApplications = (
  state: GetAllIntegrationApplicationsStoreState = initialState,
  action: {
    type: GET_ALL_INTEGRATION_APPLICATIONS;
    data: any;
    error: {};
  }
): GetAllIntegrationApplicationsStoreState => {
  switch (action.type) {
    case GET_ALL_INTEGRATION_APPLICATIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ALL_INTEGRATION_APPLICATIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ALL_INTEGRATION_APPLICATIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAllIntegrationApplications;
