import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getSupplyContractByGuid';
import { GET_SUPPLY_CONTRACT_BY_GUID } from '../reducers/getSupplyContractByGuid';
import SCFApi from '../api';

function* getSupplyContractByGuid(action: {
  type: GET_SUPPLY_CONTRACT_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getSupplyContractByGuid, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractByGuidSaga() {
  yield takeLatest(
    GET_SUPPLY_CONTRACT_BY_GUID.GET_SUPPLY_CONTRACT_BY_GUID_REQ,
    getSupplyContractByGuid
  );
}
