import * as React from 'react';
import { RequisitesForm } from './../RequisitesForm/RequisitesForm';
import { BankAccountsForm } from './../BankAccountsForm/BankAccountsForm';
import {
  RequsitesBankAccountsStyled,
} from './styles';

class RequisitesBankAccounts extends React.Component {
  render() {
    return (
      <RequsitesBankAccountsStyled>
        <RequisitesForm />
        <BankAccountsForm />
      </RequsitesBankAccountsStyled>
    );
  }
}

export default RequisitesBankAccounts;
