import * as React from 'react';
import * as d3 from 'd3';
import {
  FinancialsSummaryContainer,
  FinancialsSummaryTitle,
  FinancialsSummarySum,
  FinancialsSummaryFooter,
  FinancialsSummaryProgressContainer,
  FinancialsSummaryProgressDIO,
  FinancialsSummaryProgressDSO,
  FinancialsSummaryProgressDPO,
  FinancialsSummaryProgressCCC,
  FinancialsSummaryProgressMinusCCC,
  FinancialsSummaryDays,
  FinancialsSummary
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import { BarChart } from 'src/features/BarChart/components/BarChart';

interface Props {
  revenueSaleData: number[];
  netProfitData: number[];
  capitalData: number[];
  profitSaleData: number[];
  EBITDAData: number[];
  DEBTEBITDAData: number[];
  netDebtData: number[];
  longTermBorrowedFundsData: number[];
  shortTermBorrowedFundsData: number[];
  totalBorrowedFundsData: number[];
  totalAssetsData: number[];
  SOKData: number[];
  sumDIO_DSO: number;
  DIO: number;
  DSO: number;
  DPO: number;
  CCC: number;
  filtredFinancailsSummaryData: FinancailsSummaryData[];
  selectYear: number;
  setSelectYear: (year: number) => void;
}

const FinancialsSummaryView: React.FC<Props> = props => {
  const svgRefRevenueSale = React.useRef(null);
  const svgRefCapital = React.useRef(null);
  const svgRefNetProfit = React.useRef(null);
  const svgRefProfitSale = React.useRef(null);
  const svgRefEBITDA = React.useRef(null);
  const svgRefDEBTEBITDA = React.useRef(null) as React.MutableRefObject<any>;
  const svgRefNetDebt = React.useRef(null);
  const svgRefLongTermBorrowedFunds = React.useRef(null);
  const svgRefShortTermBorrowedFunds = React.useRef(null);
  const svgRefTotalBorrowedFunds = React.useRef(null);
  const svgRefTotalAssets = React.useRef(null);
  const svgRefSOK = React.useRef(null);

  React.useEffect(
    () => {
      if (!props.DEBTEBITDAData) return;

      const svg = d3.select(svgRefDEBTEBITDA.current);

      svg.selectAll('*').remove();

      const height = 40;

      const yScale = d3
        .scaleLinear()
        .domain([
          Math.min(d3.min(props.DEBTEBITDAData), 0),
          Math.max(d3.max(props.DEBTEBITDAData), 0)
        ])
        .range([height, 0]);

      const bars = svg.append('g').attr('transform', `translate(10, 0)`);

      const invisibleBars = svg
        .append('g')
        .attr('transform', `translate(10, 0)`);

      invisibleBars
        .selectAll('rect')
        .data(props.DEBTEBITDAData)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10)
        .attr('y', 0)
        .attr('width', 8)
        .attr('height', height)
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          props.setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === props.selectYear ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });

      bars
        .selectAll('rect')
        .data(props.DEBTEBITDAData)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10) // Равномерное распределение столбцов по горизонтали
        .attr('y', d => (d >= 0 ? yScale(d) : yScale(0)))
        .attr('width', 8) // Ширина столбцов
        .attr('height', d => Math.abs(yScale(0) - yScale(d)))
        .attr('fill', (d, i) => {
          if (i === props.selectYear) {
            return '#0f285a';
          }
          if (d <= 2.5) {
            return '#6CDCD0';
          } else if (d <= 4) {
            return '#F7CC64';
          } else {
            return '#F53737';
          }
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          props.setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === props.selectYear ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });
    },
    [props.filtredFinancailsSummaryData, props.setSelectYear, props.selectYear]
  );

  function formatValue(type: keyof FinancailsSummaryData): number {
    const selectedYear =
      props.selectYear !== null
        ? props.selectYear
        : props.filtredFinancailsSummaryData.length - 1;

    const rowValue =
      props.filtredFinancailsSummaryData[selectedYear] &&
      props.filtredFinancailsSummaryData[selectedYear][type] != null
        ? props.filtredFinancailsSummaryData[selectedYear][type]
        : null;

    return rowValue !== null ? rowValue : null;
  }

  function activeYear(): number {
    return props.filtredFinancailsSummaryData[
      props.selectYear !== null
        ? props.selectYear
        : props.filtredFinancailsSummaryData.length - 1
    ].year;
  }

  return (
    <FinancialsSummaryContainer>
      <FinancialsSummary>
        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row2110') < 0}>
            <FinancialsSummaryTitle>Выручка от продажи</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row2110')
                  ? formatValue('row2110').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.revenueSaleData}
            svgRef={svgRefRevenueSale}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row2200') < 0}>
            <FinancialsSummaryTitle>Прибыль от продаж</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row2200')
                  ? formatValue('row2200').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.profitSaleData}
            svgRef={svgRefProfitSale}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row2400') < 0}>
            <FinancialsSummaryTitle>Чистая прибыль</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row2400')
                  ? formatValue('row2400').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.netProfitData}
            svgRef={svgRefNetProfit}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('ebitda') < 0}>
            <FinancialsSummaryTitle>EBITDA</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('ebitda')
                  ? formatValue('ebitda').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.EBITDAData}
            svgRef={svgRefEBITDA}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum
            isNegativeNum={
              props.filtredFinancailsSummaryData[
                props.selectYear !== null
                  ? props.selectYear
                  : props.filtredFinancailsSummaryData.length - 1
              ].debt_to_ebitda < 0
            }
          >
            <FinancialsSummaryTitle>Debt / EBITDA</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {props.filtredFinancailsSummaryData[
                  props.selectYear !== null
                    ? props.selectYear
                    : props.filtredFinancailsSummaryData.length - 1
                ].debt_to_ebitda.toFixed(2)}
              </p>
            </div>
          </FinancialsSummarySum>
          <svg ref={svgRefDEBTEBITDA} width="50" height="40" />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('net_debt') < 0}>
            <FinancialsSummaryTitle>Net Debt</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('net_debt')
                  ? formatValue('net_debt').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.netDebtData}
            svgRef={svgRefNetDebt}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row1300') < 0}>
            <FinancialsSummaryTitle>Капитал и резервы</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row1300')
                  ? formatValue('row1300').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.capitalData}
            svgRef={svgRefCapital}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row1410') < 0}>
            <FinancialsSummaryTitle>
              Долгосрочные заемные средства
            </FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row1410')
                  ? formatValue('row1410').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.longTermBorrowedFundsData}
            svgRef={svgRefLongTermBorrowedFunds}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row1510') < 0}>
            <FinancialsSummaryTitle>
              Краткосрочные заемные средства
            </FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row1510')
                  ? formatValue('row1510').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.shortTermBorrowedFundsData}
            svgRef={svgRefShortTermBorrowedFunds}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('debt') < 0}>
            <FinancialsSummaryTitle>
              Итого заемные средства
            </FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('debt')
                  ? formatValue('debt').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.totalBorrowedFundsData}
            svgRef={svgRefTotalBorrowedFunds}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('row1600') < 0}>
            <FinancialsSummaryTitle>Итого активы</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('row1600')
                  ? formatValue('row1600').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.totalAssetsData}
            svgRef={svgRefTotalAssets}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>

        <div>
          <FinancialsSummarySum isNegativeNum={formatValue('sok') < 0}>
            <FinancialsSummaryTitle>СОК</FinancialsSummaryTitle>
            <div>
              <p>{activeYear()}</p>
              <p>
                {formatValue('sok')
                  ? formatValue('sok').toLocaleString() + ' 000 ₽'
                  : null}
              </p>
            </div>
          </FinancialsSummarySum>
          <BarChart
            data={props.SOKData}
            svgRef={svgRefSOK}
            setSelectYear={selectYear => props.setSelectYear(selectYear)}
            selectedBar={props.selectYear}
          />
        </div>
      </FinancialsSummary>

      <FinancialsSummaryFooter>
        <div>
          <FinancialsSummaryProgressContainer>
            <FinancialsSummaryProgressDIO
              widthDIO={Math.round((props.DIO / props.sumDIO_DSO) * 100)}
            >
              {props.DIO}
            </FinancialsSummaryProgressDIO>
            <FinancialsSummaryProgressDSO
              widthDSO={Math.round((props.DSO / props.sumDIO_DSO) * 100)}
            >
              {props.DSO}
            </FinancialsSummaryProgressDSO>
          </FinancialsSummaryProgressContainer>
          <FinancialsSummaryProgressContainer>
            <FinancialsSummaryProgressDPO
              widthDPO={Math.round((props.DPO / props.sumDIO_DSO) * 100)}
            >
              {props.DPO}
            </FinancialsSummaryProgressDPO>
            {props.CCC > 0 && (
              <FinancialsSummaryProgressCCC
                widthCCC={Math.round((props.CCC / props.sumDIO_DSO) * 100)}
              >
                {props.CCC}
              </FinancialsSummaryProgressCCC>
            )}
          </FinancialsSummaryProgressContainer>
          {props.CCC < 0 && (
            <FinancialsSummaryProgressMinusCCC>
              {props.CCC}
            </FinancialsSummaryProgressMinusCCC>
          )}
        </div>

        <div>
          <FinancialsSummaryDays>
            <p>Производственный цикл:</p>
            <p>{props.DIO} дней</p>
          </FinancialsSummaryDays>
          <FinancialsSummaryDays>
            <p>Отсрочка платежа:</p>
            <p>{props.DSO} дней</p>
          </FinancialsSummaryDays>
          <FinancialsSummaryDays>
            <p>Отсрочка поставщикам:</p>
            <p>{props.DPO} дней</p>
          </FinancialsSummaryDays>
          <FinancialsSummaryDays>
            <p>Финансовый цикл:</p>
            <p>{props.CCC} дней</p>
          </FinancialsSummaryDays>
        </div>
      </FinancialsSummaryFooter>
    </FinancialsSummaryContainer>
  );
};

export { FinancialsSummaryView };
