import { REQUEST_STATUSES, Reducer } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/ExternalAgent/model/actions/getCompanyExternal';

export interface GetCompanyExternalStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_COMPANY_EXTERNAL {
  REQ = 'GET_COMPANY_EXTERNAL_REQ',
  GOT = 'GET_COMPANY_EXTERNAL_GOT',
  ERR = 'GET_COMPANY_EXTERNAL_ERR'
}

export const initialState: GetCompanyExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getCompanyExternal = (
  state: GetCompanyExternalStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetCompanyExternalStoreState => {
  switch (action.type) {
    case GET_COMPANY_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyExternal;
