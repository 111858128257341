import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';

import {
  got,
  err,
  RequestDataType
} from 'Application/actions/getApplicationsList';
import { set as setApplicationListType } from 'Application/actions/setApplicationListType';

import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';
import {
  GET_APPLICATIONS_LIST,
  initialState
} from 'Application/reducers/getApplicationsList';

import ApplicationApi from 'Application/api';

function* getApplicationsList(action: {
  type: GET_APPLICATIONS_LIST;
  data: RequestDataType;
}) {
  const applicationListType = yield select(
    ({ Application }: STORE) => Application.setApplicationListType.listType
  );
  function* defineApiCall() {
    switch (applicationListType) {
      case APPLICATIONS_LIST_TYPE.GENERAL:
        return yield call(
          ApplicationApi.getGeneralApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.MY:
        return yield call(ApplicationApi.getMyApplicationsList, action.data);
      case APPLICATIONS_LIST_TYPE.DRAFT:
        return yield call(ApplicationApi.getDraftApplicationsList, action.data);
      case APPLICATIONS_LIST_TYPE.DEAL:
        return yield call(ApplicationApi.getDealApplicationsList, action.data);
      case APPLICATIONS_LIST_TYPE.WORK:
        return yield call(ApplicationApi.getWorkApplicationsList, action.data);
      case APPLICATIONS_LIST_TYPE.TRASH:
        return yield call(
          ApplicationApi.getTrashedApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.INTEGRATION_APPLICATIONS:
        return yield call(
          ApplicationApi.getIntegrationApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.TO_DISTRIBUTE:
        return yield call(
          ApplicationApi.getGeneralApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.ALL_IN_WORK:
        return yield call(
          ApplicationApi.getAllInWorkApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.ALL_DEALS:
        return yield call(
          ApplicationApi.getAllDealsApplicationsList,
          action.data
        );
      case APPLICATIONS_LIST_TYPE.ALL_TRASHED:
        return yield call(
          ApplicationApi.getAllTrashedApplicationsList,
          action.data
        );

      default:
        return initialState;
    }
  }

  try {
    const res: any = yield defineApiCall();
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationsListSaga() {
  yield takeLatest(GET_APPLICATIONS_LIST.REQ, getApplicationsList);
}
