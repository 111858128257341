import { REQUEST_STATUSES, Reducer } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/ExternalAgent/model/actions/getBeneficiariesOfCompanies';

export interface GetBeneficiariesOfCompaniesStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL {
  REQ = 'GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL_REQ',
  GOT = 'GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL_GOT',
  ERR = 'GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL_ERR'
}

export const initialState: GetBeneficiariesOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getBeneficiariesOfCompanies = (
  state: GetBeneficiariesOfCompaniesStoreState = initialState,
  action: {
    type: string;
    data: ResponseData[];
    error: {};
  }
): GetBeneficiariesOfCompaniesStoreState => {
  switch (action.type) {
    case GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_BENEFICIARIES_OF_COMPANIES_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getBeneficiariesOfCompanies;
