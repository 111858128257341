import React from 'react';
import styled from 'styled-components';

interface IconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  iconLink: string;
  onClick?: (event: React.MouseEvent) => void;
}

const ImgStyled = styled.img`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const defaultProps: Partial<IconProps> = {
  width: 75,
  height: 75
};

export const Icon: React.FC<IconProps> = ({
  width,
  height,
  color,
  iconLink,
  onClick
}) => (
  <ImgStyled
    src={iconLink}
    alt="icon"
    style={{
      width,
      height,
      backgroundColor: color
    }}
    onClick={onClick}
  />
);

Icon.defaultProps = defaultProps;
