import styled from 'styled-components';

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;

  h2 {
    margin: 0;
  }

  button {
    width: fit-content;
    height: 40px;
  }
`;
