import styled from 'styled-components';

import { DocumentsTable } from 'Layouts/components/DocumentsTable/DocumentsTable';
import { TableHeaderHeight } from 'Layouts/components/DocumentsTable/styles';

import { gray, lightGray, lighterGray } from 'shared/styled';
import { getBgImage } from 'shared/ui//InputFile/styles';

export const TableFooterHeight = '60px';

export const FileListTable = styled(DocumentsTable)`
  table {
    background-color: transparent;
    background-image: ${props => getBgImage(props)};
  }

  thead {
    background-color: #fff;
    border-bottom: 1px solid ${lightGray};
  }

  tbody {
    height: calc(100% - ${TableFooterHeight});
    max-height: calc(100% - ${TableFooterHeight});
  }
`;

export const TableFooter = styled.tfoot`
  height: ${TableFooterHeight};

  bottom: ${TableHeaderHeight};
  left: 0;
  position: absolute;

  border-top: 1px solid ${lightGray};
  background: radial-gradient(${lighterGray} 50%, transparent);

  tr {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: none;

    td {
      width: auto !important;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:last-child {
        padding: 0 0 0 20px;
        color: ${gray};
      }
    }
  }
`;
