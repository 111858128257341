import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { LeadRead } from '../actions/setLeadFormData';

export interface GetLeadStoreState extends Reducer {
  lead: LeadRead;
}

export enum GET_LEAD {
  REQ = 'GET_LEAD_REQ',
  GOT = 'GET_LEAD_GOT',
  ERR = 'GET_LEAD_ERR'
}

export const initialState: GetLeadStoreState = {
  status: REQUEST_STATUSES.NONE,
  lead: null,
  error: {}
};

const getLead = (
  state: GetLeadStoreState = initialState,
  action: {
    type: string;
    lead: LeadRead;
    error: {};
  }
): GetLeadStoreState => {
  switch (action.type) {
    case GET_LEAD.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LEAD.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        lead: action.lead
      };
    case GET_LEAD.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getLead;
