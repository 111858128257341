import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { CompanyRead } from '../../actions/setCompanyFormData';
import { ResponseDataType as UserData } from 'src/features/User/reducers/getUserData';
import { req as getSubsidiaries } from 'src/features/Beneficiaries/actions/getSubsidiaries';
import { req as getCompany } from '../../actions/getCompany';
import { req as getMainCounter } from '../../actions/getMainCounter';
import {
  req as getListInternalAgents,
  ResponseData as ListInternalAgentData
} from '../../actions/getListInternalAgents';
import { req as getBeneficiaries } from 'src/features/Beneficiaries/actions/getBeneficiaries';
import {
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Menu,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { setCompanyFormData } from '../../actions/setCompanyFormData';
import {
  ButtonRoleContainer,
  CardHolding,
  CompanyMainInfoContainer,
  CompanyRoleContainer,
  CompanyRoleName,
  CompanySectionTitle,
  OwnerCompanyRole,
  SectionContainer,
  SelectRoleContainer,
  Spinner
} from 'src/features/Companies/components/CompanyView/styled';
import { COMPANY_TABS, Role } from 'src/features/Companies/types';
import CompaniesApi from 'src/features/Companies/api';
import UserAccountsApi from 'src/features/Users/api';
import { MAIN_COUNTER } from 'src/features/Companies/types/mainCounter';
import { Select } from 'shared/ui/Select';
import {
  BeneficiariesTypes,
  SubsidiariesTypes
} from 'src/features/Beneficiaries/types';

import { UserListForBankPage } from 'src/features/Users/components/UserListForBankPage/UserListForBankPage';
import { ActivitiesListOfCompanies } from 'src/features/ActivitiesOfCompanies/components/ActivitiesOfCompanies';
import { FinancialForCompany } from 'src/features/FinancialForCompany/components/FinancialForCompany';
import { AgentApplicationList } from 'src/features/ApplicationForCompanies/components/AgentApplication';
import { Beneficiaries } from 'src/features/Beneficiaries/components/Beneficiaries';
import { Subsidiaries } from 'src/features/Beneficiaries/components/Subsidiaries';
import { Counterparties } from 'src/features/Counterparties/components/Counterparties';
import { BankProducts } from 'src/features/BankProducts/components/BankProducts';
import { DossierForCompany } from 'src/features/DossierForCompany/components/DossierForCompany';
import { ManagersAndSignatories } from 'src/features/Beneficiaries/components/ManagersAndSignatories';
import { TendersForCompanies } from 'src/features/TendersForCompanies/components/TendersForCompanies';
import { CompanyApplicationList } from 'src/features/ApplicationForCompanies/components/CompanyApplication';
import { ContactsList } from 'src/features/Contacts/components/ContactsList/ContactsList';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  mainCounter: MAIN_COUNTER;
  company: CompanyRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  listInternalAgent: ListInternalAgentData[];
  user: UserData;
  permissions: USER_PERMISSIONS[];
  beneficiaries: BeneficiariesTypes[];
  statusBeneficiaries: REQUEST_STATUSES;
  errorBeneficiaries: ResponseError;
  statusCompanyByInn: REQUEST_STATUSES;
  subsidiaries: SubsidiariesTypes[];
  statusSubsidiaries: REQUEST_STATUSES;
  errorSubsidiaries: ResponseError;
}

interface State {
  activeTab: string;
  manager: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  analyst: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

interface DispatchToProps {
  getCompany: (inn: string) => void;
  getMainCounter: (inn: string) => void;
  getListInternalAgents: () => void;
  getBeneficiaries: (inn: string) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
  getSubsidiaries: (inn: string) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class CompanyView extends React.Component<Props, State> {
  state: State = {
    activeTab: 'DEFAULT',
    manager: {
      id: null,
      firstName: null,
      lastName: null,
      email: null
    },
    analyst: {
      id: null,
      firstName: null,
      lastName: null,
      email: null
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.company.managerId !== this.props.company.managerId ||
      prevProps.company.analystId !== this.props.company.analystId
    ) {
      this.getNameByManager();
      this.getNameByAnalyst();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      }
    }
    if (this.props.statusCompanyByInn !== prevProps.statusCompanyByInn) {
      window.location.reload();
    }
    if (this.props.match.params.inn !== prevProps.match.params.inn) {
      this.initFetch();
    }
  }

  initFetch() {
    if (
      this.props.match.params.tab &&
      this.props.match.params.tab !== 'leads'
    ) {
      this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
    }

    this.props.getSubsidiaries(this.props.match.params.inn);
    this.props.getCompany(this.props.match.params.inn);
    this.props.getMainCounter(this.props.match.params.inn);
    this.props.getListInternalAgents();
    this.props.getBeneficiaries(this.props.match.params.inn);
  }

  getNameByManager = async () => {
    if (!!this.props.company.managerId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.managerId.toString()
      );
      this.setState({
        manager: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        }
      });
    }
  };

  getNameByAnalyst = async () => {
    if (!!this.props.company.analystId) {
      const user: any = await UserAccountsApi.getUser(
        this.props.company.analystId.toString()
      );
      this.setState({
        analyst: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        }
      });
    }
  };

  navigateToHolding = (holdingId: number) => {
    if (holdingId) {
      const { history } = this.props;
      history.push(`/crm/holdings/${holdingId}`);
    }
  };

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });

    const { history } = this.props;
    const { tab, applicationTab } = this.props.match.params;

    const baseUrl = history.location.pathname
      .replace(`/${tab && tab.toLowerCase()}`, '')
      .replace(`/${applicationTab}`, '');

    if (tabName !== 'DEFAULT') {
      const url = baseUrl + `/${tabName.toLowerCase()}`;
      history.push(url);
    } else {
      history.push(baseUrl);
    }
  };

  handleSetRole = async (
    inn: string,
    role: Role,
    locked: boolean,
    {
      managerUserId,
      analystUserId
    }: { managerUserId?: number; analystUserId?: number }
  ) => {
    await CompaniesApi.putLockAndUnlockCompany(
      inn,
      role,
      locked,
      managerUserId,
      analystUserId
    );
    this.initFetch();
  };

  getCountByKey = (key: string): number | null => {
    const { mainCounter } = this.props;

    if (key === 'DEFAULT') {
      const count = mainCounter.applicationsCounter + mainCounter.leadsCounter;
      return count > 0 ? count : null;
    } else if (key === 'AGENT_APPLICATIONS') {
      const count =
        mainCounter.agentApplicationsCounter + mainCounter.agentLeadsCounter;
      return count > 0 ? count : null;
    }

    const count = +mainCounter[`${key.toLowerCase()}Counter`];

    return count > 0 ? count : null;
  };

  render() {
    const { company, status, error, listInternalAgent, user } = this.props;

    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <CompanyMainInfoContainer>
              <div>
                <ApplicationId>ИНН {company.inn}</ApplicationId>
                <ApplicationTitle>{company.companyShortName}</ApplicationTitle>
              </div>
              <CompanyRoleContainer>
                {!!this.props.company.managerId && (
                  <div>
                    <CompanyRoleName>Менеджер</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.manager.firstName}{' '}
                      {this.state.manager.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}

                {this.props.permissions.includes(USER_PERMISSIONS.ADMIN) &&
                  !this.props.company.managerId && (
                    <SelectRoleContainer>
                      <CompanyRoleName>Менеджер</CompanyRoleName>
                      <Select
                        options={listInternalAgent.map(agent => ({
                          id: agent.id.toString(),
                          name: agent.name
                        }))}
                        placeholder="Выберите менеджера"
                        name="selectManager"
                        value={''}
                        onChange={e => {
                          const managerUserId = +e.currentTarget.value;
                          this.handleSetRole(company.inn, 'admin', true, {
                            managerUserId
                          });
                        }}
                      />
                    </SelectRoleContainer>
                  )}

                {this.props.permissions.includes(USER_PERMISSIONS.ADMIN) &&
                  !this.props.company.analystId && (
                    <SelectRoleContainer>
                      <CompanyRoleName>Аналитик</CompanyRoleName>
                      <Select
                        options={listInternalAgent.map(agent => ({
                          id: agent.id.toString(),
                          name: agent.name
                        }))}
                        placeholder="Выберите аналитика"
                        name="selectAnalyst"
                        value={''}
                        onChange={e => {
                          const analystUserId = +e.currentTarget.value;
                          this.handleSetRole(company.inn, 'admin', true, {
                            analystUserId
                          });
                        }}
                      />
                    </SelectRoleContainer>
                  )}

                {!!this.props.company.analystId && (
                  <div>
                    <CompanyRoleName>Аналитик</CompanyRoleName>
                    <OwnerCompanyRole>
                      {this.state.analyst.firstName}{' '}
                      {this.state.analyst.lastName}
                    </OwnerCompanyRole>
                  </div>
                )}
                <ButtonRoleContainer>
                  <div>
                    {company.managerId === null && (
                      <Button
                        label="Закрепиться как менеджер"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(
                            company.inn,
                            'as_manager',
                            true,
                            {}
                          )
                        }
                      />
                    )}
                    {company.analystId === null && (
                      <Button
                        label="Закрепиться как аналитик"
                        w="fit-content"
                        onClick={() =>
                          this.handleSetRole(
                            company.inn,
                            'as_analyst',
                            true,
                            {}
                          )
                        }
                      />
                    )}
                  </div>
                  <div>
                    {company.managerId !== null &&
                      user.email === this.state.manager.email && (
                        <Button
                          label="Открепиться как менеджер"
                          onClick={() =>
                            this.handleSetRole(
                              company.inn,
                              'as_manager',
                              false,
                              {}
                            )
                          }
                        />
                      )}
                    {company.analystId !== null &&
                      user.email === this.state.analyst.email && (
                        <Button
                          label="Открепиться как аналитик"
                          onClick={() =>
                            this.handleSetRole(
                              company.inn,
                              'as_analyst',
                              false,
                              {}
                            )
                          }
                        />
                      )}
                  </div>
                </ButtonRoleContainer>
              </CompanyRoleContainer>
            </CompanyMainInfoContainer>
            <ViewSeparatorTop>
              <MainInfoBlock>
                {company.holdingId && (
                  <MainInfoItem>
                    <CardHolding
                      onClick={() => this.navigateToHolding(company.holdingId)}
                    >
                      <span>Холдинг</span>
                      <span>- {company.holdingName} -</span>
                    </CardHolding>
                  </MainInfoItem>
                )}
                <MainInfoItem>
                  <span>Выручка, тыс. руб.</span>
                  <span>
                    {company.revenue === null
                      ? 'Нет данных'
                      : company.revenue.toLocaleString('ru-RU', {
                          minimumFractionDigits: 0
                        })}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Чистая прибыль</span>
                  <span>
                    {company.netIncome === null
                      ? 'Нет данных'
                      : company.netIncome.toLocaleString('ru-RU', {
                          minimumFractionDigits: 0
                        })}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Капитал и резервы</span>
                  <span>
                    {company.capital === null
                      ? 'Нет данных'
                      : company.capital.toLocaleString('ru-RU', {
                          minimumFractionDigits: 0
                        })}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Отчетный год</span>
                  <span>
                    {company.financialsYear === null
                      ? 'Нет данных'
                      : company.financialsYear}
                  </span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <Menu
              activeTab={this.state.activeTab}
              getCountByKey={this.getCountByKey}
              handleActiveTab={this.handleActiveTab}
              tabs={COMPANY_TABS}
            />

            {this.state.activeTab === 'DEFAULT' && (
              <>
                <CompanySectionTitle>Заявки по клиенту</CompanySectionTitle>
                <CompanyApplicationList />
              </>
            )}

            {this.state.activeTab === 'CONTACTS' && (
              <>
                <CompanySectionTitle>Контакты</CompanySectionTitle>
                <ContactsList companyInn={company.inn} />
              </>
            )}

            {this.state.activeTab === 'FINANCIAL_DATA' && (
              <>
                <FinancialForCompany />
              </>
            )}

            {this.state.activeTab === 'DOSSIER' && (
              <>
                <CompanySectionTitle>Кредитное досье</CompanySectionTitle>
                <DossierForCompany />
              </>
            )}

            {this.state.activeTab === 'BENEFICIARIES' && (
              <SectionContainer>
                <div>
                  <Button
                    label={'Загрузить данные ЕГРЮЛ'}
                    disabled={
                      this.props.statusCompanyByInn === REQUEST_STATUSES.REQUEST
                    }
                    onClick={() =>
                      this.props.getCompanyByInn({
                        inn: this.props.match.params.inn,
                        force: true
                      })
                    }
                  />
                  {this.props.statusCompanyByInn ===
                    REQUEST_STATUSES.REQUEST && <Spinner icon={faSpinner} />}
                </div>

                <ManagersAndSignatories />
                <Beneficiaries
                  beneficiaries={this.props.beneficiaries}
                  status={this.props.statusBeneficiaries}
                  error={this.props.errorBeneficiaries}
                />
                <Subsidiaries
                  subsidiaries={this.props.subsidiaries}
                  status={this.props.statusSubsidiaries}
                  error={this.props.errorSubsidiaries}
                />
              </SectionContainer>
            )}

            {this.state.activeTab === 'USERS' && (
              <>
                <CompanySectionTitle>Пользователи</CompanySectionTitle>
                <UserListForBankPage bankInn={company.inn} />
              </>
            )}

            {this.state.activeTab === 'AGENT_APPLICATIONS' && (
              <>
                <CompanySectionTitle>Агентские заявки</CompanySectionTitle>
                <AgentApplicationList />
              </>
            )}

            {this.state.activeTab === 'ACTIVITIES' && (
              <>
                <CompanySectionTitle>Активности</CompanySectionTitle>
                <ActivitiesListOfCompanies companyInn={company.inn} />
              </>
            )}

            {this.state.activeTab === 'COUNTERPARTIES' && (
              <>
                <CompanySectionTitle>Контрагенты</CompanySectionTitle>
                <Counterparties />
              </>
            )}

            {this.state.activeTab === 'BANK_PRODUCTS' && (
              <>
                <CompanySectionTitle>Банковские продукты</CompanySectionTitle>
                <BankProducts />
              </>
            )}

            {this.state.activeTab === 'TENDERS' && (
              <>
                <CompanySectionTitle>Опыт закупок</CompanySectionTitle>
                <TendersForCompanies
                  isCustomer44={company.is_customer44}
                  isCustomer223={company.is_customer223}
                />
              </>
            )}
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code === 404 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.NOT_FOUND}
        />

        <ActionResponseBlock
          showIn={
            error.code !== 403 &&
            error.code !== 404 &&
            status === REQUEST_STATUSES.ERROR
          }
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({
  Companies,
  User,
  Beneficiaries,
  SCF
}: CRM & STORE) => ({
  mainCounter: Companies.getMainCounter.data,
  company: Companies.getCompany.company,
  status: Companies.getCompany.status,
  error: Companies.getCompany.error,
  listInternalAgent: Companies.getListInternalAgents.data,
  user: User.getUserData.data,
  permissions: User.getUserData.data.permissions,
  beneficiaries: Beneficiaries.getBeneficiaries.data,
  statusBeneficiaries: Beneficiaries.getBeneficiaries.status,
  errorBeneficiaries: Beneficiaries.getBeneficiaries.error,
  statusCompanyByInn: SCF.getCompanyByInnThirdParty.status,
  subsidiaries: Beneficiaries.getSubsidiaries.data,
  statusSubsidiaries: Beneficiaries.getSubsidiaries.status,
  errorSubsidiaries: Beneficiaries.getSubsidiaries.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompany,
      setCompanyFormData,
      getMainCounter,
      getListInternalAgents,
      getBeneficiaries,
      getCompanyByInn,
      resetStateCompanyByInn,
      getSubsidiaries
    },
    dispatch
  );

const CompanyViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyView)
);

export { CompanyViewConnect as CompanyView };
