import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/sendApplicationData';
import {
  RequestDataType,
  SEND_APPLICATION_DATA
} from 'Application/reducers/sendApplicationData';

import ApplicationApi from 'Application/api';

function* sendApplicationData(action: {
  type: SEND_APPLICATION_DATA;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(ApplicationApi.sendApplicationData, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendApplicationDataSaga() {
  yield takeLatest(SEND_APPLICATION_DATA.REQ, sendApplicationData);
}
