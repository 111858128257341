import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/Client/model/actions/postNewCompanies';

import { POST_NEW_COMPANIES } from 'entities/Cabinet/Client/model/reducers/postNewCompanies';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* postNewCompanies(action: {
  type: POST_NEW_COMPANIES;
  companyInn: string;
}): Generator {
  try {
    yield call(ClientRoleApi.postNewCompanies, action.companyInn);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postNewCompaniesSaga(): Generator {
  yield takeLatest(POST_NEW_COMPANIES.REQ, postNewCompanies);
}
