import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorsListSupplierRoleByGuid';
import { GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getInvestorsListSupplierRoleByGuid';
import SCFApi from 'entities/SCF/Supplier/api';

function* getInvestorsListSupplierRoleByGuid(action: {
  type: GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getInvestorsListSupplierRoleByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInvestorsListSupplierRoleByGuidSaga() {
  yield takeLatest(
    GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_REQ,
    getInvestorsListSupplierRoleByGuid
  );
}
