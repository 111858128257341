import styled from 'styled-components';
import { lightGray, navyBlue } from 'shared/styled';

export const TogglePasswordIcon = styled.button.attrs({
  type: 'button',
  tabIndex: '-1',
  ['aria-hidden']: true
})`
  width: 20px;
  height: 20px;

  padding: 0;

  display: block;
  cursor: pointer;
  outline: none;

  top: 50%;
  right: 20px;
  position: absolute;

  transform: translateY(-50%);

  border: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

  background-image: ${({ isFocused }) => `url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path fill="${
        isFocused ? navyBlue : lightGray
      }" d="M7.556 6.123a7.825 7.825 0 0 1 2.762-.498c2.733 0 5.202 1.367 7.407 4.1.363.451.363 1.099 0 1.55-.835 1.035-1.708 1.874-2.619 2.517l1.465 1.487c.2.203.2.533 0 .736l-.181.184a.506.506 0 0 1-.724 0L4.71 5.072a.526.526 0 0 1 0-.736l.181-.184a.506.506 0 0 1 .724 0l1.94 1.971zm5.903 5.996c.244-.486.381-1.036.381-1.619 0-1.974-1.576-3.575-3.52-3.575-.574 0-1.116.14-1.594.387l.729.74a2.52 2.52 0 0 1 .865-.152c1.414 0 2.56 1.164 2.56 2.6 0 .308-.053.604-.15.879l.73.74zM5.488 7.237l1.668 1.694A3.606 3.606 0 0 0 6.8 10.5c0 1.974 1.576 3.575 3.52 3.575.554 0 1.078-.13 1.544-.362l1.164 1.183c-.872.32-1.776.479-2.71.479-2.734 0-5.203-1.367-7.407-4.1a1.237 1.237 0 0 1 0-1.55c.822-1.019 1.681-1.848 2.577-2.488zm2.403 2.44l3.239 3.29a2.522 2.522 0 0 1-.81.133c-1.414 0-2.56-1.164-2.56-2.6 0-.287.046-.564.13-.822z" />
    </svg>')`};
`;
