import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getFinancialDocuments';

export interface GetFinancialDocumentsStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_FINANCIAL_DOCUMENTS_CLIENT {
  REQ = 'GET_FINANCIAL_DOCUMENTS_CLIENT_REQ',
  GOT = 'GET_FINANCIAL_DOCUMENTS_CLIENT_GOT',
  ERR = 'GET_FINANCIAL_DOCUMENTS_CLIENT_ERR'
}

export const initialState: GetFinancialDocumentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getFinancialDocuments = (
  state: GetFinancialDocumentsStoreState = initialState,
  action: {
    type: GET_FINANCIAL_DOCUMENTS_CLIENT;
    data: ResponseData[];
    error: {};
  }
): GetFinancialDocumentsStoreState => {
  switch (action.type) {
    case GET_FINANCIAL_DOCUMENTS_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINANCIAL_DOCUMENTS_CLIENT.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_FINANCIAL_DOCUMENTS_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFinancialDocuments;
