import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutEarlyPaymentApplicationDeclineFactorRoleStoreState extends Reducer {
  data: RequestDataType;
}

export enum PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE {
  REQ = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE_REQ',
  GOT = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE_GOT',
  ERR = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE_ERR'
}

export interface RequestDataType {
  guid: string;
  body: {
    rejectReason: string;
  };
}

export const initialState: PutEarlyPaymentApplicationDeclineFactorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    body: {
      rejectReason: null
    }
  },
  error: {}
};

const putEarlyPaymentApplicationDeclineFactorRole = (
  state: PutEarlyPaymentApplicationDeclineFactorRoleStoreState = initialState,
  action: {
    type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE;
    data: RequestDataType;
    error: {};
  }
): PutEarlyPaymentApplicationDeclineFactorRoleStoreState => {
  switch (action.type) {
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putEarlyPaymentApplicationDeclineFactorRole;
