import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResponseError, REQUEST_STATUSES, CRM } from 'src/globaltypes';
import { InnPlaceholders } from 'shared/constants';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { Input } from 'shared/ui/Input';
import { ScrollTopComponent } from 'src/features/Common';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import {
  req as getMonetaryClaimsList,
  ResponseData,
  RequestType
} from 'src/features/MonetaryClaims/actions/getMonetaryClaimsList';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { CopyBlock, TDCopyTooltip } from './styled';

interface StateToProps {
  monetaryClaims: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getMonetaryClaimsList: (data: RequestType) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const MonetaryClaimsList: React.FC<Props> = ({
  getMonetaryClaimsList,
  monetaryClaims,
  status,
  error,
  history
}) => {
  const [debtorInn, setDebtorInn] = React.useState('');
  const [supplierInn, setSupplierInn] = React.useState('');
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const debtorInn = searchParams.get('debtorInn') || '';
    const supplierInn = searchParams.get('supplierInn') || '';

    setDebtorInn(debtorInn);
    setSupplierInn(supplierInn);

    getMonetaryClaimsList({
      page,
      pageSize: 20,
      ...(debtorInn && { debtorInn }),
      ...(supplierInn && { supplierInn })
    });
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleCopyClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    text: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const navigateToCompany = (inn: string) => {
    window.open(`/crm/companies/${inn}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Денежные требования</ApplicationTitle>

      <FiltersStyled>
        <INNFilterContainerStyled>
          <Input
            value={debtorInn}
            label="ИНН Дебитора"
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNFilterContainerStyled>
        <INNFilterContainerStyled>
          <Input
            value={supplierInn}
            label="ИНН поставщика"
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNFilterContainerStyled>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="6%">Дебитор</TableThStyled>
              <TableThStyled width="12%">Поставщик</TableThStyled>
              <TableThStyled width="9%">Сумма</TableThStyled>
              <TableThStyled width="9%">Дата счета</TableThStyled>
              <TableThStyled width="9%">Дата оплаты</TableThStyled>
              <TableThStyled width="5%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {monetaryClaims.items.map(claim => (
              <TableRowStyled key={claim.id}>
                <TDCopyTooltip>
                  <CopyBlock
                    text={claim.debtorName && `${claim.debtorName} в CRM`}
                    onClick={() => navigateToCompany(claim.debtorInn)}
                  >
                    {claim.debtorName}
                  </CopyBlock>
                  <CopyBlock
                    text={
                      claim.debtorInn && !isCopied
                        ? 'Скопировать ИНН'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e => handleCopyClick(e, claim.debtorInn)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    ИНН: {claim.debtorInn}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDCopyTooltip>
                  <CopyBlock
                    text={claim.creditorName && `${claim.creditorName} в CRM`}
                    onClick={() => navigateToCompany(claim.creditorInn)}
                  >
                    {claim.creditorName}
                  </CopyBlock>
                  <CopyBlock
                    text={
                      claim.creditorInn && !isCopied
                        ? 'Скопировать ИНН'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e => handleCopyClick(e, claim.creditorInn)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    ИНН: {claim.creditorInn}
                  </CopyBlock>
                </TDCopyTooltip>
                <td>{formSumStringThousands(claim.baseFullSumm)}</td>
                <td>{new Date(claim.emergenceDate).toLocaleDateString()}</td>
                <td>{new Date(claim.maturityDate).toLocaleDateString()}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={monetaryClaims} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ MonetaryClaims }: CRM) => ({
  monetaryClaims: MonetaryClaims.getMonetaryClaimsList.data,
  status: MonetaryClaims.getMonetaryClaimsList.status,
  error: MonetaryClaims.getMonetaryClaimsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getMonetaryClaimsList }, dispatch);

const MonetaryClaimsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(MonetaryClaimsList)
);

export { MonetaryClaimsListConnect as MonetaryClaimsList };
