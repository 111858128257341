import { put, takeLatest, take } from 'redux-saga/effects';
import { got, err } from '../actions/sendInitialAndGuaranteesData';
import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import {
  req as updateInitializationFormData,
  got as gotUpdateInitializationFormDataResult
} from 'Application/actions/updateInitializationFormData';

import { SEND_INITIAL_AND_GUARANTEES_DATA } from '../reducers/sendInitialAndGuaranteesData';
import { STORE } from 'src/globaltypes';
import { select } from 'redux-saga-test-plan/matchers';
import { APPLICATION_PROCESS_MODES } from '../reducers/setApplicationProcessMode';
import {
  ApplicationWrite,
  GUARANTEES_TYPE,
  GuaranteesApplicationWrite
} from '../types';

function* sendInitialAndGuaranteesData(action: {
  type: SEND_INITIAL_AND_GUARANTEES_DATA;
}): Generator {
  try {
    const initializationFormData: Partial<ApplicationWrite> = yield select(
      (store: STORE) => store.Application.setInitializationFormData
    );
    const guaranteesFormData: Partial<
      GuaranteesApplicationWrite
    > = yield select((store: STORE) => store.Application.setGuaranteesFormData);
    const applicationProcessMode = yield select(
      ({ Application }: STORE) => Application.setApplicationProcessMode.mode
    );

    yield put(
      updateInitializationFormData({
        ...{
          id: initializationFormData.id,
          refinancing: initializationFormData.refinancing,
          clientCompanyName: initializationFormData.clientCompanyName,
          code: initializationFormData.code,
          customerInn: initializationFormData.customerInn,
          customerName: initializationFormData.customerName,
          amount: initializationFormData.amount,
          term: initializationFormData.term,
          termStartDate: initializationFormData.termStartDate,
          termEndDate: initializationFormData.termEndDate
        },
        ...(applicationProcessMode === APPLICATION_PROCESS_MODES.CREATE && {
          financingType: initializationFormData.financingType
        })
      })
    );
    yield take(gotUpdateInitializationFormDataResult().type);

    yield put(
      sendApplicationDataReq({
        id: initializationFormData.id,
        refinancing: guaranteesFormData.refinancing,
        guarantees: {
          commercial: guaranteesFormData.commercial,
          contractDocuments: guaranteesFormData.contractDocuments,
          desiredBanks: guaranteesFormData.desiredBanks,
          guaranteesType: guaranteesFormData.guaranteesType,
          tax: guaranteesFormData.tax,
          tender: guaranteesFormData.tender,
          tenderComment: guaranteesFormData.tenderComment,
          typeComment: guaranteesFormData.typeComment,
          sumFinal: guaranteesFormData.sumFinal,
          subject: guaranteesFormData.subject,
          startPrice: guaranteesFormData.startPrice,
          lotCode: guaranteesFormData.lotCode,
          isSingleSupplier: guaranteesFormData.isSingleSupplier,
          isClosedAuction: guaranteesFormData.isClosedAuction,
          ...([
            GUARANTEES_TYPE['223-FZ'],
            GUARANTEES_TYPE['44-FZ'],
            GUARANTEES_TYPE['615-P'],
            GUARANTEES_TYPE['COMMERCIAL']
          ].includes(guaranteesFormData.guaranteesType) && {
            tenderLink: guaranteesFormData.tenderLink
          })
        }
      })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendInitialAndGuaranteesDataSaga(): Generator {
  yield takeLatest(
    SEND_INITIAL_AND_GUARANTEES_DATA.REQ,
    sendInitialAndGuaranteesData
  );
}
