import { POST_FINBANK } from '../reducers/postFinbank';
import {
  FinbankRead,
  FinbanksData
} from 'src/features/Finbanks/actions/setFinbankFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: FinbankRead[];
}

export const req = (data: FinbanksData) => ({
  type: POST_FINBANK.REQ,
  data
});

export const reset = () => ({
  type: POST_FINBANK.RESET
});

export const got = (data: ResponseData) => ({
  type: POST_FINBANK.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_FINBANK.ERR,
  error
});
