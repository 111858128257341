import { combineReducers } from 'redux';
import getActiveDDSOfDebtor, {
  GetActiveDDSOfDebtorStoreState
} from './getActiveDDSOfDebtor';
import getCertificates, { GetCertificatesStoreState } from './getCertificates';
import getUserStats, { GetUserStatsStoreState } from './getUserStats';
import getAllDocumentsForms, {
  GetAllDocumentFormsStoreState
} from './getAllDocumentsForms';
import getInternalFormFile, {
  GetInternalFormFileStoreState
} from './getInternalFormFile';
import postDocumentsFormFill, {
  PostDocumentsFormFillStoreState
} from './postDocumentsFormFill';
import getInternalApplication, {
  GetInternalApplicationStoreState
} from './getInternalApplication';
import getFactoringLimits, {
  GetFactoringLimitsStoreState
} from './getFactoringLimits';
import patchTenderUpdateByInternal, {
  PatchTenderUpdateByInternalStoreState
} from './patchTenderUpdateByInternal';
import patchSetDiscountMargin, {
  PatchSetDiscountMarginInternalStoreState
} from './patchSetDiscountMargin';
import getCheckSignature, {
  GetCheckSignatureStoreState
} from './getCheckSignature';

export interface InternalAgentRoleReducers {
  getActiveDDSOfDebtor: GetActiveDDSOfDebtorStoreState;
  getCertificates: GetCertificatesStoreState;
  getUserStats: GetUserStatsStoreState;
  getAllDocumentsForms: GetAllDocumentFormsStoreState;
  getInternalFormFile: GetInternalFormFileStoreState;
  postDocumentsFormFill: PostDocumentsFormFillStoreState;
  getInternalApplication: GetInternalApplicationStoreState;
  getFactoringLimits: GetFactoringLimitsStoreState;
  patchTenderUpdateByInternal: PatchTenderUpdateByInternalStoreState;
  patchSetDiscountMargin: PatchSetDiscountMarginInternalStoreState;
  getCheckSignature: GetCheckSignatureStoreState;
}

export const InternalAgentRole = combineReducers({
  getActiveDDSOfDebtor,
  getCertificates,
  getUserStats,
  getAllDocumentsForms,
  getInternalFormFile,
  postDocumentsFormFill,
  getInternalApplication,
  getFactoringLimits,
  patchTenderUpdateByInternal,
  patchSetDiscountMargin,
  getCheckSignature
});
