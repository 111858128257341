import { SCF_REGISTER_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/SCFRegisterSupplier';

export interface RequestDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  legalForm: string;
  code: string;
  companyName: string;
  email: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_REQ,
  data
});
export const got = (data: RequestDataType) => ({
  type: SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_GOT
});
export const err = (error: {}) => ({
  type: SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_ERR,
  error
});
export const reset = () => ({
  type: SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_RESET
}); SCF_REGISTER_SUPPLIER
