import * as React from 'react';

import { Input, Props as InputProps } from 'shared/ui/Input';
import { TogglePasswordIcon } from './styles';

type Props = InputProps;

export const InputPassword: React.FC<Props> = props => {
  const [isValueShow, setIsValueShow] = React.useState(false);

  const togglePasswordVisibility = () => {
    setIsValueShow(prevState => !prevState);
  };

  return (
    <Input
      {...props}
      type={isValueShow ? 'text' : 'password'}
      renderPasswordToggleIcon={(focusInput, isFocused) =>
        props.value !== '' && (
          <TogglePasswordIcon
            isFocused={isFocused}
            onClick={() => {
              togglePasswordVisibility();
              focusInput(true);
            }}
          />
        )
      }
    />
  );
};
