import * as React from 'react';

import { convertKbToMb, formBackendDateString } from 'src/shared/utils/Utils';
import { Document } from 'Application/types';
import ApplicationApi from 'Application/api';

import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';

import { DocumentsTable } from 'Layouts/components';
import {
  ApplicationViewConsumer,
  EachApplicationProps,
  WarningBlock
} from 'Application/components';

import { DownloadLink, ErrorIcon } from 'shared/styled';
import { CompanyDocumentsBlockStyled, CompanyDocumentsTtitle } from './styles';
import { EditDocumentsLink } from '../styles';

interface Props {
  documents: Document[];
  applicationId: number;
}

export class CompanyDocumentsBlock extends React.Component<Props> {
  formTableRows = () =>
    this.props.documents.map((document, index) => ({
      id: document.id,
      data: [
        index + 1,
        document.name,
        document.createdDate
          ? formBackendDateString(document.createdDate)
          : '-',
        convertKbToMb(document.size),
        <DownloadLink href={ApplicationApi.getDownloadDocumentLink(document)} />
      ]
    }));

  render() {
    const { documents, applicationId } = this.props;

    return documents.length > 0 ? (
      <CompanyDocumentsBlockStyled>
        <CompanyDocumentsTtitle />
        <DocumentsTable
          headers={[
            '№',
            'Название',
            'Дата добавления',
            'Размер файла',
            <DownloadLink
              href={ApplicationApi.getDownloadApplicationDocumentsLink(
                applicationId
              )}
            />
          ]}
          rows={this.formTableRows()}
        />
      </CompanyDocumentsBlockStyled>
    ) : (
      <ApplicationViewConsumer>
        {({
          canEdit,
          editDocsRoute
        }: EachApplicationProps<GetApplicationResponseDataType>) =>
          canEdit && (
            <React.Fragment>
              <CompanyDocumentsTtitle>
                Документы компании
              </CompanyDocumentsTtitle>

              <WarningBlock
                icon={<ErrorIcon size={90} />}
                text={[
                  'Вы не загрузили необходимые документы.',
                  <React.Fragment>
                    Чтобы загрузить документы нажмите "
                    <EditDocumentsLink to={editDocsRoute}>
                      Редактировать документы
                    </EditDocumentsLink>
                    ".
                  </React.Fragment>,
                  'Заявки без приложенных документов банками к рассмотрению не принимаются.'
                ]}
              />
            </React.Fragment>
          )
        }
      </ApplicationViewConsumer>
    );
  }
}
