import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format } from 'date-fns';
import { history } from 'src/shared/utils/History';

import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';

import { Loader } from 'Layouts/components';
import {
  req as getMonetaryClaimsFactorEarlyPaymentApplications,
  RequestDataType,
  Calc as ResponseDataType
} from 'entities/SCF/Factor/model/actions/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Factor/model/actions/getOneFactorRoleByGuid';
import { RequestDataType as PutEarlyPaymentApplicationDeclineDataType } from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';
import { req as putEarlyPaymentApplicationDecline } from 'entities/SCF/Factor/model/actions/putEarlyPaymentApplicationDeclineFactorRole';
import {
  ContractsPageStyled,
  CreateLimitsNudge,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  ListSection,
  FullList,
  ListSectionOne,
  FullListOne,
  BackBtn,
  BackBtnText,
  ConfirmSection,
  ConfirmSectionRightPart,
  DiscountItem,
  SignSection,
  DatePopup,
  DatePopupBtns,
  DatesContainer,
  CancelBtn,
  CancelBtnText,
  CreateBtnPopup,
  CreateBtnContainer,
  DownloadLink
} from './styles';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'entities/SCF/Factor/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
}

interface DispatchToProps {
  getMonetaryClaimsFactorEarlyPaymentApplications: (
    data: RequestDataType
  ) => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  putEarlyPaymentApplicationDecline: (
    data: PutEarlyPaymentApplicationDeclineDataType
  ) => void;
}

interface State {
  pageUrl: Href;
  commentState: string;
  updateChecker: number;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ApplicationInfo extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: window.location.href.split('/').reverse()[0],
        commentState: '',
        updateChecker: 0
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleCommentStateChange = this.handleCommentStateChange.bind(this);
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handlePageChange(newHref: string) {
    newHref = window.location.href.split('/').reverse()[0];
    this.setState({ pageUrl: newHref });
  }

  handleCommentStateChange(event) {
    this.setState({ commentState: event.target.value });
  }

  handleUpdateCheckerChange() {
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  }

  initFetch = () => {
    const {
      getInvestorOneDebtor,
      getMonetaryClaimsFactorEarlyPaymentApplications
    } = this.props;
    getInvestorOneDebtor({
      guid: this.state.pageUrl
    });
    getMonetaryClaimsFactorEarlyPaymentApplications({
      guid: this.state.pageUrl
    });
  };

  componentDidMount() {
    this.initFetch();

    function base64ToBlob(base64, type = 'application/octet-stream') {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    }

    function arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    try {
      fetch(
        `/api/early_payment_applications/factor_role/${
          this.props.match.params.guid
        }/get_agreement_html_file`
      )
        .then(res => res.arrayBuffer())
        .then(data => {
          var base64Str = arrayBufferToBase64(data);
          const blob = base64ToBlob(base64Str, 'application/pdf');
          const url = URL.createObjectURL(blob);
          var outputObject = document.createElement('iframe');
          var outputText = document.createElement('p');
          outputText.style.margin = '30px 0 8px 0';
          outputText.style.fontFamily = 'Roboto-Regular';
          outputText.innerHTML = '* Превью договора';
          outputObject.src = url + '#toolbar=0';
          outputObject.style.width = '1200px';
          outputObject.style.height = '1000px';
          outputObject.style.background = '#262B41';
          document.getElementById('documentObject').appendChild(outputText);
          document.getElementById('documentObject').appendChild(outputObject);
        });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
  }

  componentWillUnmount(): void {
    this.setState({ pageUrl: '' });
  }

  onBackClick = () => {
    history.push(`/factor/factoring_applications?page=1`);
  };

  onCancelClick = () => {
    let datePopup = document.getElementById('datePopup');
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
  };

  onDeclineClick = () => {
    let datePopup = document.getElementById('datePopup');
    datePopup.style.display = 'flex';
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  onSignClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    history.push({
      pathname: `/factor/settings/certificates/edit`
    });
  };

  delay = ms => new Promise(res => setTimeout(res, ms));

  onDeclineBtnClick = async () => {
    let datePopup = document.getElementById('datePopup');
    await this.delay(700);
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
    this.handleUpdateCheckerChange();
  };

  intermediateDiscount(): number {
    if (this.props.monetaryClaimsEarlyPaymentApplications[0].baseSumm > 0) {
      return (
        Math.round(this.props.investorOneDebtor.baseDiscountRate * 1.2 * 1000) /
        1000
      );
    }
    return (
      Math.round(this.props.investorOneDebtor.baseDiscountRate * 1000) / 1000
    );
  }

  fullRate(i: number): number {
    return (
      Math.round(
        this.intermediateDiscount() *
          (differenceInDays(
            new Date(
              this.props.monetaryClaimsEarlyPaymentApplications[i].maturityDate
            ),
            new Date(this.props.investorOneDebtor.payDate)
          ) /
            365) *
          10000
      ) /
      10000 /
      100
    );
  }

  summToGet(i: number): number {
    return (
      this.props.monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
      (1 - this.fullRate(i))
    );
  }

  DiscountCounter() {
    let discountCounter: number;
    discountCounter = 0;
    for (
      let i = 0;
      i < this.props.monetaryClaimsEarlyPaymentApplications.length;
      i++
    ) {
      discountCounter += this.props.monetaryClaimsEarlyPaymentApplications[i]
        .baseFullSumm;
    }
    return discountCounter;
  }

  DiscountCounterToGet() {
    let discountCounterToGet: number;
    discountCounterToGet = 0;
    for (
      let i = 0;
      i < this.props.monetaryClaimsEarlyPaymentApplications.length;
      i++
    ) {
      discountCounterToGet += this.summToGet(i);
    }
    return discountCounterToGet;
  }

  render() {
    const {
      status,
      monetaryClaimsEarlyPaymentApplications,
      error,
      investorOneDebtor
    } = this.props;
    return (
      <ContractsPageStyled>
        <CreateLimitsNudge>
          <h2>
            Заявка #
            {investorOneDebtor.guid
              .substring(investorOneDebtor.guid.length - 5)
              .toLocaleUpperCase()}
          </h2>
          <BackBtn onClick={() => this.onBackClick()}>
            <BackBtnText>Назад</BackBtnText>
          </BackBtn>
        </CreateLimitsNudge>
        <ListSectionOne>
          <FullListOne>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="50%">
                    ПОКУПАТЕЛЬ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="50%">
                    ПОСТАВЩИК
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.supplierName}
                  </td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          </FullListOne>
        </ListSectionOne>

        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="7%">ID</TableHeaderCellStyled>
                    <TableHeaderCellStyled width="15%">
                      СУММА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="12%">
                      ДАТА СЧЕТА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="12%">
                      ДАТА ОПЛАТЫ
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      СРОК ФИНАНСИРОВАНИЯ ДН.
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="20%">
                      СТАВКА ФАКТОРИНГА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="12%">
                      КОМИССИЯ
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="18%">
                      СУММА К ВЫПЛАТЕ
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                    <TableRowStyled key={key}>
                      <td style={{ fontFamily: 'Roboto-Regular' }}>
                        {item.guid.substring(item.guid.length - 5)}
                      </td>

                      <td>
                        {item.baseFullSumm.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </td>
                      <td>
                        {format(new Date(item.createdDateTime), 'dd.MM.yyyy')}
                      </td>
                      <td>
                        <p
                          style={{
                            textDecoration: 'line-through',
                            color: '#9FA6B2',
                            fontSize: '12px',
                            margin: '5px 0'
                          }}
                        >
                          {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                        </p>
                        {format(
                          new Date(investorOneDebtor.payDate),
                          'dd.MM.yyyy'
                        )}
                      </td>
                      <td>
                        {differenceInDays(
                          new Date(item.maturityDate),
                          new Date(investorOneDebtor.payDate)
                        )}
                      </td>

                      <td>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '5px 0'
                          }}
                        >
                          {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              marginLeft: '5px'
                            }}
                          >
                            без НДС
                          </p>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '5px 0'
                          }}
                        >
                          {(investorOneDebtor.baseDiscountRate * 1.2).toFixed(
                            3
                          )}
                          %{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              marginLeft: '5px'
                            }}
                          >
                            с НДС
                          </p>
                        </p>
                      </td>
                      <td>
                        {(this.fullRate(key) * 100).toLocaleString('ru', {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4
                        })}
                        %
                      </td>
                      <td>
                        {this.summToGet(key).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
        </ListSection>

        {this.props.status == REQUEST_STATUSES.GOT && (
          <ConfirmSection style={{ marginTop: '10px' }}>
            <ConfirmSectionRightPart>
              <DiscountItem>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  Сумма первоначального требования
                </p>
                <p
                  className="discount-1"
                  id="discount-1"
                  style={{ color: '#9FA6B2', fontSize: '14px' }}
                >
                  {this.DiscountCounter().toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                  руб.
                </p>
              </DiscountItem>
              <DiscountItem>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  Расчетная сумма комиссий
                </p>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  {(
                    this.DiscountCounter() - this.DiscountCounterToGet()
                  ).toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                  руб.
                </p>
              </DiscountItem>
              <DiscountItem>
                <p style={{ fontSize: '16px' }}>Сумма к выплате</p>
                <p style={{ fontSize: '16px' }}>
                  {this.DiscountCounterToGet().toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                  руб.
                </p>
              </DiscountItem>
            </ConfirmSectionRightPart>
          </ConfirmSection>
        )}
        {investorOneDebtor.statusDebtor == 'На рассмотрении' ? (
          <ConfirmSection>
            <ConfirmSectionRightPart>
              <BtnsBlock>
                <CreateBtn
                  style={{ marginRight: '15px' }}
                  onClick={this.onSignClick}
                >
                  <CreateBtnText>Одобрить</CreateBtnText>
                </CreateBtn>
                <BackBtn
                  style={{ width: '150px' }}
                  onClick={this.onDeclineClick}
                >
                  <BackBtnText>Отклонить</BackBtnText>
                </BackBtn>
              </BtnsBlock>
            </ConfirmSectionRightPart>
          </ConfirmSection>
        ) : (
          ''
        )}

        <ConfirmSection style={{ marginTop: '-100px', width: '400px' }}>
          <SignSection>
            {investorOneDebtor.agreementFileIdentifier != null ? (
              <DownloadLink
                style={{ width: '180px', marginBottom: '10px' }}
                href={SCFApi.downloadFactorAgreementFile(this.state.pageUrl)}
              >
                <BackBtnText>Скачать документ</BackBtnText>
              </DownloadLink>
            ) : (
              ''
            )}

            {investorOneDebtor.supplierSignatureFileIdentifier != null ? (
              <DownloadLink
                style={{ width: '270px', marginBottom: '10px' }}
                href={SCFApi.downloadSignatureFile(this.state.pageUrl)}
              >
                <BackBtnText>Скачать подпись поставщика</BackBtnText>
              </DownloadLink>
            ) : (
              ''
            )}

            {investorOneDebtor.debtorSignatureFileIdentifier != null ? (
              <DownloadLink
                style={{ width: '205px', marginBottom: '10px' }}
                href={SCFApi.downloadFactorSignatureFile(this.state.pageUrl)}
              >
                <BackBtnText>Скачать мою подпись</BackBtnText>
              </DownloadLink>
            ) : (
              ''
            )}
          </SignSection>
        </ConfirmSection>

        <div id="documentObject" style={{ width: '900px', height: '1000px' }} />

        <CertificatesPopup id="certificatesPopup">
          <PopupExit>
            <p onClick={this.onCloseIconClick}>X</p>
          </PopupExit>
          <h1>Список сертификатов в системе</h1>
          <CertificatesArea>
            <CertificatesListPopup guid={this.state.pageUrl} />
          </CertificatesArea>
          <NudgeArea>
            <p>Нет нужного сертификата? </p>
            <Nudge onClick={this.onNudgeClick}>
              Перейдите в настройки добаления
            </Nudge>
          </NudgeArea>
        </CertificatesPopup>
        <CertificatesPopupError id="certificatesPopupError">
          <PopupExitError>
            <p onClick={this.onCloseErrorIconClick}>X</p>
          </PopupExitError>
          <h1>
            Невозможно подписать выбранным сертификатом
            <br />
            (не найден на устройстве)
          </h1>
        </CertificatesPopupError>
        <DatePopup
          id={'datePopup'}
          style={{
            position: 'relative',
            marginTop: '-150px',
            marginLeft: '600px'
          }}
        >
          <DatesContainer>
            <textarea
              value={this.state.commentState}
              onChange={this.handleCommentStateChange}
              placeholder="Ваша причина отказа"
              style={{
                width: '400px',
                paddingTop: '10px',
                paddingLeft: '15px',
                minHeight: '100px'
              }}
            />
          </DatesContainer>
          <DatePopupBtns>
            {this.state.commentState.length < 10 ? (
              <CreateBtnPopup
                style={{
                  backgroundColor: '#9FA6B2',
                  cursor: 'not-allowed'
                }}
              >
                <CancelBtnText>Отправить</CancelBtnText>
              </CreateBtnPopup>
            ) : (
              <CreateBtnPopup
                onClick={() =>
                  this.props.putEarlyPaymentApplicationDecline({
                    guid: this.state.pageUrl,
                    body: {
                      rejectReason: this.state.commentState
                    }
                  })
                }
              >
                <CreateBtnContainer onClick={this.onDeclineBtnClick}>
                  <CancelBtnText>Отправить</CancelBtnText>
                </CreateBtnContainer>
              </CreateBtnPopup>
            )}
            <CancelBtn onClick={this.onCancelClick}>
              <CancelBtnText>Отменить</CancelBtnText>
            </CancelBtn>
          </DatePopupBtns>
        </DatePopup>
      </ContractsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCFFactor }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFFactor.getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid.data,
  status:
    SCFFactor.getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid.status,
  error: SCFFactor.getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFFactor.getOneFactorRoleByGuid.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      getMonetaryClaimsFactorEarlyPaymentApplications,
      putEarlyPaymentApplicationDecline
    },
    dispatch
  );

const ApplicationInfoConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationInfo)
);

export { ApplicationInfoConnect as ApplicationInfo };