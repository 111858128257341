import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/getDebtorCounterSidebar';
import { GET_DEBTOR_COUNTER_SIDEBAR } from 'entities/SCF/Debtor/model/reducers/getDebtorCounterSidebar';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getDebtorCounterSidebar(): Generator {
  try {
    const res: any = yield call(SCFDebtorApi.getDebtorCounterSidebar);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDebtorCounterSidebarSaga(): Generator {
  yield takeLatest(GET_DEBTOR_COUNTER_SIDEBAR.REQ, getDebtorCounterSidebar);
}
