import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { FactoringLimitRead } from '../../actions/setFactoringLimitFormData';
import { req as getFactoringLimit } from '../../actions/getFactoringLimit';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  FactoringLimitsData,
  setFactoringLimitFormData
} from '../../actions/setFactoringLimitFormData';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  factoringLimit: FactoringLimitRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getFactoringLimit: (id: number | string) => void;
  setFactoringLimitFormData: (data: FactoringLimitsData) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FactoringLimitView: React.FC<Props> = ({
  getFactoringLimit,
  factoringLimit,
  status,
  error,
  history,
  match
}) => {
  React.useEffect(
    () => {
      getFactoringLimit(match.params.id);
    },
    [match.params.id]
  );

  const navigateToProduct = () => {
    history.push(`/crm/products/${factoringLimit.productId}`);
  };

  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.GOT && (
        <>
          <ApplicationTitle>Лимит #{factoringLimit.id}</ApplicationTitle>

          <ViewSeparatorTop>
            <MainInfoBlock>
              <MainInfoItem>
                <span>ИНН Дебитора</span>
                <span>{factoringLimit.debtorINN}</span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Дебитор</span>
                <span>{factoringLimit.debtorShortName}</span>
              </MainInfoItem>
              <MainInfoItem>
                <span>ИНН Фактора</span>
                <span>{factoringLimit.factorINN}</span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Фактор</span>
                <span>{factoringLimit.factorShortName}</span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Продукт</span>
                <span onClick={navigateToProduct}>
                  {factoringLimit.productName}
                </span>
              </MainInfoItem>
              <MainInfoItem>
                <span>Размер лимита</span>
                <span>{formSumStringThousands(factoringLimit.volume)}</span>
              </MainInfoItem>
            </MainInfoBlock>
          </ViewSeparatorTop>
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ FactoringLimits }: CRM) => ({
  factoringLimit: FactoringLimits.getFactoringLimit.factoringLimit,
  status: FactoringLimits.getFactoringLimit.status,
  error: FactoringLimits.getFactoringLimit.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getFactoringLimit, setFactoringLimitFormData },
    dispatch
  );

const FactoringLimitViewConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringLimitView)
);

export { FactoringLimitViewConnect as FactoringLimitView };
