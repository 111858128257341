import { GET_API_KEY_INFO } from '../reducers/getApiKeyInfo';

export interface ResponseData {
  firstName: 'Александр';
  lastName: 'Жаров';
  companyName: 'ООО "ФИНФЭКТОРИ"';
}

export const req = (key: string) => ({
  type: GET_API_KEY_INFO.REQ,
  key
});

export const got = (data: ResponseData) => ({
  type: GET_API_KEY_INFO.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_API_KEY_INFO.ERR,
  error
});
