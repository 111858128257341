import styled from 'styled-components';
import { rr, blue } from 'shared/styled';

export const ContractsInfoPageStyled = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const ContractsContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const ContractsInfoArea = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: 900;
    color: #000;
  }
`;

export const ContractsHeader = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const ContractsInfoItemContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin-right: 20px;
    margin: 0 20px 0 0;
  }
`;

export const ContractsInfoItemTitle = styled.p`
  font-family: ${rr};
  font-size: 1.3em;
  width: 350px;
`;

export const TypeContainer = styled.div`
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 5px;
`;

export const TypeNoActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  background-color: #f53737;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #f53737;
  border-left: 1px solid #f53737;
  border-bottom: 1px solid #f53737;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px 0 0 0;
  }
`;

export const BackBtn = styled.div`
  padding-left: 15px;
  margin-left: 100px;
  margin-bottom: 20px;
  width: 100px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 10px;
  color: #3f4e65;
  margin: 0;
`;

export const TypeYesInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #d9d9d9;
    margin: 5px 0 0 0;
  }
`;

export const TypeYesActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #1cca94;
  border-right: 1px solid #1cca94;
  border-bottom: 1px solid #1cca94;
  background-color: #1cca94;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px 0 0 0;
  }
`;

export const TypeNoInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #d9d9d9;
    margin: 5px 0 0 0;
  }
`;

export const MovingContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #d9d9d9;
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #d9d9d9;
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;
