import * as React from 'react';
import { RegistriesAdd } from './components/RegistriesAdd/RegistriesAdd';
import { RegistriesList } from './components/RegistriesList/RegistriesList';
import {
  RegistriesPageStyled,
} from './styles';

class RegistriesPage extends React.Component {
  render() {
    return (
      <RegistriesPageStyled>
        <h2>РЕЕСТРЫ</h2>
        <RegistriesAdd filesDisplay={[]} />
        <RegistriesList />
      </RegistriesPageStyled>
    );
  }
}

export default RegistriesPage;
