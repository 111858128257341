import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';

import { inRangeRule } from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import { SetFinancingApplicationType } from 'Application/reducers/setFinancingFormData';

export const createValidaton = () =>
  new Validator<SetInitializationApplicationType>({
    amount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    equity: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'equityRange'
      }
    ],
    termInvestmentPhase: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'termInvestmentPhaseRange'
      }
    ]
  });
