import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { COMMENT_TYPES, Comment } from 'Comments/types';

export interface GetCommentsListStoreState extends Reducer {
  data: ResponseDataType;
}

export interface RequestDataType {
  id: number;
  type: COMMENT_TYPES;
}

export type ResponseDataType = Comment[];

export enum GET_COMMENTS_LIST {
  REQ = 'GET_COMMENTS_LIST_REQUEST',
  GOT = 'GET_COMMENTS_LIST_GOT',
  ERR = 'GET_COMMENTS_LIST_ERROR'
}

export const initialState: GetCommentsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getCommentsList = (
  state: GetCommentsListStoreState = initialState,
  action: {
    type: GET_COMMENTS_LIST;
    data: ResponseDataType;
    error: {};
  }
): GetCommentsListStoreState => {
  switch (action.type) {
    case GET_COMMENTS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMMENTS_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMMENTS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCommentsList;
