import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { Loader } from 'Layouts/components';
import './styles/style.css';

import {
  req as getDebtorRegistryFileSettings,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFileSettingsCurrent';

import {
  RegistrySettingsPageStyled,
  Block,
  BlockContainer,
  TitleContainer,
  NoSelectTitle,
  SelectActive,
  SelectContainerActive,
  SelectContainerInactive,
  SelectInactive,
  SelectTitle,
  PresenseContainer,
  ColumnContainer,
  RightTitle
} from './styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  debtorRegistryFileSettings: ResponseDataType;
}

interface DispatchToProps {
  getDebtorRegistryFileSettings: () => void;
}

type Props = StateToProps & DispatchToProps;

class RegistrySettingsInfoPage extends React.Component<Props> {
  initFetch = () => {
    const { getDebtorRegistryFileSettings } = this.props;
    getDebtorRegistryFileSettings();
  };

  componentDidMount() {
    this.initFetch();
  }

  onEditClick = () => {
    history.push(`/debtor/settings/discounting/edit`);
  };

  onHistoryClick = () => {
    history.push(`/debtor/settings/discounting/history`);
  };

  render() {
    const { status, debtorRegistryFileSettings, error } = this.props;
    return (
      <div>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.ERROR && <RegistrySettingsPageStyled />}
        {status === REQUEST_STATUSES.GOT && (
          <RegistrySettingsPageStyled>
            <Block>
              <h2>Файл реестра</h2>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Формат файла</NoSelectTitle>
                  <input
                    className="contract-input"
                    disabled={true}
                    value={debtorRegistryFileSettings.fileResolution}
                  />
                </TitleContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  {debtorRegistryFileSettings.skipFirstRow === true ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <SelectTitle>Пропускать шапку в первой строке</SelectTitle>
                </TitleContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Начинать обработку реестра:</NoSelectTitle>
                  <p>
                    {debtorRegistryFileSettings.parserStartPolicy === 1
                      ? 'после загрузки'
                      : debtorRegistryFileSettings.parserStartPolicy === 2 &&
                        'после подписания реестра ЭЦП'}
                  </p>
                </TitleContainer>
              </BlockContainer>
            </Block>
            <Block>
              <h2>Идентификация поставщика</h2>
              <BlockContainer>
                <TitleContainer>
                  {debtorRegistryFileSettings.supplierIdentificationPolicy ===
                  1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <SelectTitle>
                    По колонке ИНН поставщика из реестра
                  </SelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.supplierInnPolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.supplierInnPolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      // className='contract-input'
                      disabled={true}
                      value={debtorRegistryFileSettings.supplierInnColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  {debtorRegistryFileSettings.supplierIdentificationPolicy ===
                  2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <SelectTitle>По номеру договора из реестра</SelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    // className='contract-input'
                    disabled={true}
                    value={debtorRegistryFileSettings.supplyContractIdColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
            </Block>
            <Block>
              <h2>Идентификация документа о поставке</h2>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Наименование документа</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={debtorRegistryFileSettings.invoiceDocumentNameColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Дата документа</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={debtorRegistryFileSettings.invoiceDocumentDateColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Номер документа</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={
                      debtorRegistryFileSettings.invoiceDocumentNumberColumn
                    }
                  />
                </ColumnContainer>
              </BlockContainer>
            </Block>
            <Block>
              <h2>Данные поставки</h2>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Идентификатор поставки</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={debtorRegistryFileSettings.monetaryClaimIdColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Базовая сумма без НДС</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.baseSumPolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.baseSumPolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={debtorRegistryFileSettings.baseSumColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Сумма налога НДС</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.baseVatSumPolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.baseVatSumPolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={debtorRegistryFileSettings.baseVatSumColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Полная сумма с НДС</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.baseFullSumPolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.baseFullSumPolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={debtorRegistryFileSettings.baseFullSumColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Идентификатор валюты</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.currencyIdPolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.currencyIdPolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={debtorRegistryFileSettings.currencyIdColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Код валюты</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>
                    {debtorRegistryFileSettings.currencyCodePolicy === true
                      ? 'Присутствует'
                      : 'Отсутствует'}{' '}
                    в реестре
                  </p>
                </PresenseContainer>
                {debtorRegistryFileSettings.currencyCodePolicy === true ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={debtorRegistryFileSettings.currencyCodeColumn}
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Дата возникновения требования</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={debtorRegistryFileSettings.emergenceDateColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
              <BlockContainer>
                <TitleContainer>
                  <NoSelectTitle>Плановая дата оплаты</NoSelectTitle>
                </TitleContainer>
                <PresenseContainer>
                  <p>*Обязательное поле</p>
                </PresenseContainer>
                <ColumnContainer>
                  <p>Столбец</p>
                  <input
                    disabled={true}
                    value={debtorRegistryFileSettings.maturityDateColumn}
                  />
                </ColumnContainer>
              </BlockContainer>
            </Block>
            <Block>
              <h2>Обработка статуса поставки</h2>
              <BlockContainer>
                <TitleContainer>
                  {debtorRegistryFileSettings.statusPolicy === 1 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <SelectTitle>Статус хранится в отдельной колонке</SelectTitle>
                </TitleContainer>
                {debtorRegistryFileSettings.statusPolicy === 1 ? (
                  <ColumnContainer>
                    <p>Столбец</p>
                    <input
                      disabled={true}
                      value={
                        debtorRegistryFileSettings.monetaryClaimStatusColumn
                      }
                    />
                  </ColumnContainer>
                ) : null}
              </BlockContainer>
              {debtorRegistryFileSettings.statusPolicy === 1 ? (
                <BlockContainer>
                  <TitleContainer>
                    <RightTitle>Значение оплаченного статуса</RightTitle>
                  </TitleContainer>
                  <ColumnContainer>
                    <input
                      style={{ width: '100px' }}
                      disabled={true}
                      value={debtorRegistryFileSettings.monetaryClaimPaidStatus}
                    />
                  </ColumnContainer>
                </BlockContainer>
              ) : null}
              {debtorRegistryFileSettings.statusPolicy === 1 ? (
                <BlockContainer>
                  <TitleContainer>
                    <RightTitle>Значение неоплаченного статуса</RightTitle>
                  </TitleContainer>
                  <ColumnContainer>
                    <input
                      style={{ width: '100px' }}
                      disabled={true}
                      value={
                        debtorRegistryFileSettings.monetaryClaimUnpaidStatus
                      }
                    />
                  </ColumnContainer>
                </BlockContainer>
              ) : null}
              <BlockContainer>
                <TitleContainer>
                  {debtorRegistryFileSettings.statusPolicy === 2 ? (
                    <SelectContainerActive>
                      <SelectActive />
                    </SelectContainerActive>
                  ) : (
                    <SelectContainerInactive>
                      <SelectInactive />
                    </SelectContainerInactive>
                  )}
                  <SelectTitle>Статус не хранится в реестре</SelectTitle>
                </TitleContainer>
              </BlockContainer>
              {debtorRegistryFileSettings.statusPolicy === 2 ? (
                <BlockContainer style={{ marginLeft: '90px' }}>
                  <TitleContainer>
                    {debtorRegistryFileSettings.treatRegistryEntriesPolicy ===
                    1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <SelectTitle>
                      Считать все переданные поставки неоплаченными
                    </SelectTitle>
                  </TitleContainer>
                </BlockContainer>
              ) : null}
              {debtorRegistryFileSettings.statusPolicy === 2 ? (
                <BlockContainer style={{ marginLeft: '90px' }}>
                  <TitleContainer>
                    {debtorRegistryFileSettings.treatRegistryExcludedPolicy ===
                    1 ? (
                      <SelectContainerActive>
                        <SelectActive />
                      </SelectContainerActive>
                    ) : (
                      <SelectContainerInactive>
                        <SelectInactive />
                      </SelectContainerInactive>
                    )}
                    <SelectTitle>
                      Считать все непереданные поставки оплаченными
                    </SelectTitle>
                  </TitleContainer>
                </BlockContainer>
              ) : null}
            </Block>
          </RegistrySettingsPageStyled>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ SCFDebtor }: STORE) => ({
  debtorRegistryFileSettings:
    SCFDebtor.getDebtorRegistryFileSettingsCurrent.data,
  status: SCFDebtor.getDebtorRegistryFileSettingsCurrent.status,
  error: SCFDebtor.getDebtorRegistryFileSettingsCurrent.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDebtorRegistryFileSettings
    },
    dispatch
  );

const RegistrySettingsInfoPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(RegistrySettingsInfoPage);

export { RegistrySettingsInfoPageConnect as RegistrySettingsInfoPage };
