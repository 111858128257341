import { ApplicationRead, Document } from 'Application/types';

export enum CONTRACT_FINANCING_TYPE {
  NOT_SET = '',
  '44-FZ' = 'FOURTY_FOUR_FZ',
  '223-FZ' = 'TWO_TWO_THREE_FZ',
  '615-P' = 'SIX_ONE_FIVE_P',
  GOZ = 'ГОЗ',
  COMMERCIAL = 'COMMERCIAL'
}

export enum CONTRACT_SUBJECT_TYPE {
  NONE = '',
  SUPPLY = 'SUPPLY',
  SERVICES = 'SERVICES'
}

export enum CONTRACT_PREPAYMENT_TYPE {
  // TODO RENAME NONE TO NOT_SET
  NOT_SET = '',
  HAS_PREPAYMENT = 'HAS_PREPAYMENT',
  NO_PREPAYMENT = 'NO_PREPAYMENT'
}

export interface ContractFinancingWrite {
  guid: string;
  contractType: CONTRACT_FINANCING_TYPE;
  purchaseLink: string;
  contractAmount: number;
  contractSubject: CONTRACT_SUBJECT_TYPE;
  contractEndDate: string;
  prepaymentType: CONTRACT_PREPAYMENT_TYPE;
  prepaymentAmount: number;
  contractDocuments: Document[];
}

export type ContractFinancingRead = ContractFinancingWrite & ApplicationRead;
