import { GET_BANK_ACCOUNT_LIST } from '../reducers/getBankAccountList';
import { BankAccountListType } from '../types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: BankAccountListType[];
}

export const req = () => ({
  type: GET_BANK_ACCOUNT_LIST.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_BANK_ACCOUNT_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_BANK_ACCOUNT_LIST.ERR,
  error
});
