import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetFormFileStoreState extends Reducer {
  data: Blob;
}

export enum GET_FORM_FILE {
  REQ = 'GET_FORM_FILE_REQ',
  GOT = 'GET_FORM_FILE_GOT',
  ERR = 'GET_FORM_FILE_ERR'
}

export const initialState: GetFormFileStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getFormFile = (
  state: GetFormFileStoreState = initialState,
  action: {
    type: GET_FORM_FILE;
    data: Blob;
    error: {};
  }
): GetFormFileStoreState => {
  switch (action.type) {
    case GET_FORM_FILE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FORM_FILE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FORM_FILE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFormFile;
