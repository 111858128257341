import styled from 'styled-components';
import { lighterBlue, navyBlue, rl, rr } from 'shared/styled';
import { Card } from 'src/features/Products/components/ProductView/styled';
import { PopupContent } from 'src/features/Contacts/components/ContactsList/styled';

export const CardCompany = styled(Card)`
  width: 200px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  button {
    font-size: 10px;
    justify-content: center;
  }

  & > div {
    span {
      color: ${navyBlue};
      font-family: ${rl};
      font-style: italic;
    }
  }

  div:nth-last-child(1),
  div:nth-last-child(2) {
    div {
      display: flex;
      align-items: center;
      column-gap: 15px;
    }

    ul {
      list-style-type: none;
      li {
        font-size: 1.4rem;
        font-family: ${rr};
      }
      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ContactList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0;

  li {
    cursor: pointer;
    padding: 3px 5px;
    list-style-type: none;
    font-size: 1.4rem;
    font-family: ${rr};

    &:hover {
      background-color: ${lighterBlue};
    }
  }
`;

export const PopupContentContacts = styled(PopupContent)`
  min-width: 700px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  *:nth-last-child(1) {
    margin-top: auto;
  }

  button {
    font-size: 10px;
    justify-content: center;
  }
`;
