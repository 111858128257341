import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { STORE, USER_PERMISSIONS } from 'globaltypes';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';

import { Sidebar } from 'Layouts/components';

import { CabinetSection } from 'shared/styled';

import { CompaniesSection } from './Sections/Companies';
import { BanksSection } from './Sections/Banks';
import { DebtorsSection } from './Sections/Debtors';
import { AgentsSection } from './Sections/Agents';
import { UsersSection } from './Sections/Users';
import { ActivitiesSection } from './Sections/Activities';
import { TendersSection } from './Sections/Tenders';
import { CompanyView } from 'src/features/Companies/components/CompanyView/CompanyView';
import { UserView } from 'src/features/Users/components/UserView/UserView';
import { FinbankView } from 'src/features/Finbanks/components/FinbankView/FinbankView';
import { ProductView } from 'src/features/Products/components/ProductView/ProductView';
import { AgentView } from 'src/features/Agents/components/AgentView/AgentView';
import { ContactView } from 'src/features/Contacts/components/ContactView/ContactView';
import { BuyersToSupplierLinkView } from 'src/features/BuyersToSupplierLink/components/BuyersToSupplierLinkView/BuyersToSupplierLinkView';
import { UserAccountView } from 'src/features/UserAccounts/components/UserAccountView/UserAccountView';
import { FactoringLimitView } from 'src/features/FactoringLimits/components/FactoringLimitView/FactoringLimitView';
import { DebtorView } from 'src/features/Debtors/components/DebtorView/DebtorView';
import { HoldingView } from 'src/features/Holdings/components/HoldingView/HoldingView';
import { CreateNewContact } from 'src/features/Contacts/components/CreateNewContact/CreateNewContact';
import { CreateActivities } from 'src/features/Activities/components/CreateActivities/CreateActivities';
import { ActivitiesView } from 'src/features/Activities/components/ActivitiesView/ActivitiesView';
import { NewProduct } from 'src/features/Products/components/NewProduct/NewProduct';
import { NewAgent } from 'src/features/Agents/components/NewAgent/NewAgent';
import { NewBank } from 'src/features/Finbanks/components/NewBank/NewBank';
import { NewExternalCredential } from 'src/features/ExternalCredentials/components/NewExternalCredential/NewExternalCredential';
import { ExternalCredentialView } from 'src/features/ExternalCredentials/components/ExternalCredentialView/ExternalCredentialView';
import { Persons } from 'src/features/Persons/components/PersonsView/Persons';
import { BuyersToSupplierLinkNew } from 'src/features/BuyersToSupplierLink/components/BuyersToSupplierLinkNew/BuyersToSupplierLinkNew';
import { BankAccountNew } from 'src/features/BankAccount/components/BankAccountNew/BankAccountNew';
import { CompaniesListNew } from 'src/features/CompaniesLists/components/CompaniesListNew/CompaniesListNew';
import { CompaniesListById } from 'src/features/CompaniesLists/components/CompaniesListById/CompaniesListById';

export interface CRMComponent {
  path?: string;
  whenToActivate?: boolean;
  hasPermission: (permissions: USER_PERMISSIONS[]) => boolean;
  component?: any;
  render?: (location: any) => JSX.Element;
  renderSidebarLink?: (key: any) => JSX.Element;
}
interface OwnProps {
  cabinetLists?: APPLICATIONS_LIST_TYPE[];
}

interface StateToProps {
  //applicationQuantity: GetApplicationsQuantityDataType;
  //applicationQuantityStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  //canCreateApplication: boolean;
}

interface DispatchToProps {
  getApplicationsQuantity: () => void;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class EachCRM extends React.Component<Props> {
  componentDidMount() {
    //const { getApplicationsQuantity } = this.props;
    //getApplicationsQuantity();
  }

  render() {
    const {
      permissions
      //canCreateApplication,
    } = this.props;

    // TODO: show only in changed list

    // TODO extract

    const componentsList: CRMComponent[] = [
      ...UsersSection,
      ...CompaniesSection,
      ...BanksSection,
      ...DebtorsSection,
      ...ActivitiesSection,
      ...AgentsSection,
      ...TendersSection
    ];

    // TODO refactor <div key>
    // TODO refactor canCreateaAppliction routes
    return (
      <React.Fragment>
        <Sidebar>
          {componentsList.map((component, key) => {
            if (
              component.hasPermission(permissions) &&
              component.renderSidebarLink
            ) {
              return component.renderSidebarLink(key);
            }
            return null;
          })}
        </Sidebar>

        <CabinetSection>
          <Switch>
            {/*canCreateApplication && (
              <Route
                path="/cabinet/application-form/create"
                component={ApplicationCreate}
              />
            )*/}

            {componentsList.map(
              (component, key) =>
                component.path &&
                component.hasPermission(permissions) && (
                  <Route
                    exact
                    key={key}
                    path={component.path}
                    render={
                      component.render ? () => component.render({}) : null
                    }
                  />
                )
            )}

            <Route
              exact={false}
              path="/crm/activities/new"
              component={() => <CreateActivities />}
            />

            <Route
              exact={false}
              path="/crm/activities/:id"
              component={() => <ActivitiesView />}
            />

            <Route
              exact={false}
              path="/crm/agents/new"
              component={() => <NewAgent />}
            />

            <Route
              exact={false}
              path="/crm/agents/:inn/:tab?/:applicationTab?"
              component={AgentView}
            />

            <Route
              exact={false}
              path="/crm/users/:id/:tab?/:applicationTab?"
              component={UserView}
            />

            <Route
              exact={false}
              path="/crm/companies/:inn/:tab?/:applicationTab?"
              component={CompanyView}
            />

            <Route
              exact={true}
              path="/crm/contacts/new"
              component={() => <CreateNewContact />}
            />

            <Route
              exact={true}
              path="/crm/contacts/:id/:tab?"
              component={ContactView}
            />

            <Route
              exact={true}
              path="/crm/user_accounts/:id"
              component={() => <UserAccountView />}
            />

            <Route
              exact={true}
              path="/crm/factoring_limits/:id"
              component={() => <FactoringLimitView />}
            />

            <Route
              exact={true}
              path="/crm/products/new"
              component={() => <NewProduct />}
            />

            <Route
              exact={true}
              path="/crm/products/:id/:tab?"
              component={ProductView}
            />

            <Route
              exact={true}
              path="/crm/banks/new"
              component={() => <NewBank />}
            />

            <Route
              exact={true}
              path="/crm/banks/:inn/:tab?/:applicationTab?"
              component={FinbankView}
            />

            <Route
              exact={true}
              path="/crm/debtors/:inn"
              component={() => <DebtorView />}
            />

            <Route
              exact={true}
              path="/crm/holdings/:id"
              component={() => <HoldingView />}
            />

            <Route
              exact={false}
              path="/crm/external_credentials/new"
              component={() => <NewExternalCredential />}
            />

            <Route
              exact={false}
              path="/crm/external_credentials/:id"
              component={() => <ExternalCredentialView />}
            />

            <Route
              exact={false}
              path="/crm/buyers_to_suppliers_links/new"
              component={() => <BuyersToSupplierLinkNew />}
            />

            <Route
              exact={false}
              path="/crm/buyers_to_suppliers_links/:debtor_inn/:supplier_inn/:tab?"
              component={BuyersToSupplierLinkView}
            />

            <Route
              exact={false}
              path="/crm/bank_accounts/new"
              component={() => <BankAccountNew />}
            />

            <Route
              exact={true}
              path="/crm/persons/:inn/:tab?"
              component={() => <Persons />}
            />

            <Route
              exact={true}
              path="/crm/companies_lists/new"
              component={CompaniesListNew}
            />

            <Route
              exact={true}
              path="/crm/companies_lists/:id"
              component={CompaniesListById}
            />
          </Switch>
        </CabinetSection>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getApplicationsQuantity }, dispatch);

const EachCRMConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(EachCRM);

export { EachCRMConnect as EachCRM };
