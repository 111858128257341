import { FiltredData } from '../components/FinancialForCompany';
import { FinancialStatementsAllType } from '../types';

export function generateUniqueId(): string {
  const randomNumber = Math.floor(Math.random() * Date.now()).toString(16);
  const uniqueId = `${Date.now()}-${randomNumber}`;
  return uniqueId;
}

export function getColor(item) {
  if (!item.hasOwnProperty('id')) {
    return '#9FA6B2';
  } else if (item.taxPeriodCode === 34) {
    return '#0085FE';
  } else {
    return '#1CCA94';
  }
}

export function getColorExternalAgent(item) {
  if (item.hasOwnProperty('id')) {
    return '#9FA6B2';
  } else if (item.taxPeriodCode === 34) {
    return '#0085FE';
  } else {
    return '#1CCA94';
  }
}

export const calculateValue = rows => {
  const intervals = [
    { start: 1110, end: 1190, key: 'row1100' },
    { start: 1210, end: 1260, key: 'row1200' },
    { start: 1310, end: 1370, key: 'row1300' },
    { start: 1410, end: 1450, key: 'row1400' },
    { start: 1510, end: 1550, key: 'row1500' }
  ];

  const calculatedRows = {};

  for (const interval of intervals) {
    const { start, end, key } = interval;
    let sum = 0;

    for (let i = start; i <= end; i += 10) {
      const rowKey = `row${i}`;
      if (
        rows[rowKey] !== undefined &&
        rows[rowKey] !== null &&
        rows[rowKey] !== 0
      ) {
        sum += rows[rowKey];
      }
    }

    if (sum !== 0) {
      calculatedRows[key] = sum;
    }
  }

  const row1600Sum =
    (calculatedRows['row1100'] || 0) + (calculatedRows['row1200'] || 0);
  if (row1600Sum !== 0) {
    calculatedRows['row1600'] = row1600Sum;
  }

  const row1700Sum =
    (calculatedRows['row1300'] || 0) +
    (calculatedRows['row1400'] || 0) +
    (calculatedRows['row1500'] || 0);
  if (row1700Sum !== 0) {
    calculatedRows['row1700'] = row1700Sum;
  }

  return calculatedRows;
};

export const sortPeriod = (sortItem: FiltredData[]) => {
  const sortedArray = sortItem.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return a.taxPeriodCode - b.taxPeriodCode;
    }
  });

  return sortedArray;
};

export const filtredUtil = (filtredItem: {
  items: FinancialStatementsAllType[];
}): FiltredData[] => {
  const filteredData = filtredItem.items.map(item => {
    const active = {};
    const passive = {};
    const OPIY = {};

    for (const key in item) {
      const keyValue = item[key];

      if (keyValue !== null && keyValue !== 0) {
        if ((key >= 'row1100' && key <= 'row1299') || key === 'row1600') {
          active[key] = keyValue;
        } else if (
          (key >= 'row1300' && key <= 'row1590') ||
          key === 'row1700'
        ) {
          passive[key] = keyValue;
        } else if (key >= 'row2100' && key <= 'row2410') {
          OPIY[key] = keyValue;
        }
      }
    }

    return {
      year: item.year,
      taxPeriodCode: item.taxPeriodCode,
      active,
      passive,
      OPIY
    };
  });

  return filteredData;
};

export const getTaxPeriodCode = (index: number): number => {
  switch (index) {
    case 21:
      return 1;
    case 31:
      return 2;
    case 33:
      return 3;
    case 34:
      return 4;
    default:
      return undefined;
  }
};

export const transformTaxPeriodCode = (index: string): number => {
  switch (index) {
    case '03':
      return 21;
    case '02':
      return 31;
    case '01':
      return 33;
    case '00':
      return 34;
    case '13':
      return 21;
    case '12':
      return 31;
    case '11':
      return 33;
    case '10':
      return 34;
    case '23':
      return 21;
    case '22':
      return 31;
    case '21':
      return 33;
    case '20':
      return 34;
    case '33':
      return 21;
    case '32':
      return 31;
    case '31':
      return 33;
    case '30':
      return 34;
    default:
      return undefined;
  }
};

export const transformYear = (index: number): number => {
  switch (index) {
    case 3:
      return 2020;
    case 2:
      return 2021;
    case 1:
      return 2022;
    case 0:
      return 2023;
    default:
      return undefined;
  }
};
