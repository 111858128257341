import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postUploadNonFinDocInternal';

import { POST_UPLOAD_NON_FIN_DOC_INTERNAL } from '../reducers/postUploadNonFinDocInternal';
import DocumentListApi from '../api';

function* postUploadNonFinDocInternal(action: {
  type: POST_UPLOAD_NON_FIN_DOC_INTERNAL;
  data: FormData;
  guid: string;
}): Generator {
  try {
    yield call(
      DocumentListApi.postUploadNonFinDocInternal,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postUploadNonFinDocInternalSaga(): Generator {
  yield takeLatest(
    POST_UPLOAD_NON_FIN_DOC_INTERNAL.REQ,
    postUploadNonFinDocInternal
  );
}
