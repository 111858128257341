import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface SendProductBankStoreState extends Reducer {}

export enum SEND_PRODUCT_BANK {
  REQ = 'SEND_PRODUCT_BANK_REQ',
  GOT = 'SEND_PRODUCT_BANK_GOT',
  ERR = 'SEND_PRODUCT_BANK_ERR',
  RESET = 'SEND_PRODUCT_BANK_RESET'
}

export const initialState: SendProductBankStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendProductBank = (
  state: SendProductBankStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): SendProductBankStoreState => {
  switch (action.type) {
    case SEND_PRODUCT_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_PRODUCT_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_PRODUCT_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SEND_PRODUCT_BANK.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE
      };
    default:
      return state;
  }
};

export default sendProductBank;
