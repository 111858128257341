import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { history } from 'src/shared/utils/History';
import {
  req as getCertificateGenitive,
  ResponseDataType as ResCertificateGenitive
} from 'src/features/SCF/actions/getCertificateGenitive';
import { req as putAddPoaFile } from 'entities/SCF/Supplier/model/actions/putAddPoaFile';
import {
  req as getPOAS,
  ResponseData as ResPOAS
} from 'src/features/SCF/actions/getPOAS';
import {
  req as putAddPoa,
  RequestData as ReqAddPoa
} from 'src/features/SCF/actions/putAddPoa';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { closeModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'Modal/types';
import { DisplayHeader } from '../DisplayHeader/DisplayHeader';
import {
  HeaderStyled,
  CetrificatesEditInfoContentBlock,
  AdditionalBlock,
  AdditionalContainer,
  AdditionalRow,
  Text,
  TextBig,
  TextContainer,
  InputDisabled,
  BtnsBlock,
  TextLittle,
  PowerAttorney,
  Status,
  PowerAttorneyModal,
  ErrorAddPoa,
  Label,
  HiddenInput,
  FileInfo,
  RemoveButton
} from '../styles';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'Layouts/components';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { getCertificateById } from '../ulitls/getCertificateById';
import { EditBtn, EditBtnText } from 'src/features/SCF/UI/ButtonsStyles';
import { TableStyled } from 'Layouts/components/Table/styles';
import { TableHeaderStyled } from 'Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'Layouts/components/Table/TableRow/styles';
import { Modal } from 'Common';
import { Button } from 'shared/ui/Button';
import { gray } from 'shared/styled';

interface StateToProps {
  certificateGenitive: ResCertificateGenitive;
  certificateGenitiveStatus: REQUEST_STATUSES;
  POAS: ResPOAS[];
  statusAddPoaFile: REQUEST_STATUSES;
  errorAddPoaFile: ResponseError;
}

interface DispatchToProps {
  getCertificateGenitive: () => void;
  getPOAS: (inn: string) => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  putAddPoa: (data: ReqAddPoa) => void;
  putAddPoaFile: (data: FormData) => void;
}

type Props = StateToProps & DispatchToProps;

const CertificatesInfo: React.FC<Props> = ({
  getCertificateGenitive,
  certificateGenitive,
  certificateGenitiveStatus,
  getPOAS,
  POAS,
  openModal,
  closeModal,
  putAddPoaFile,
  statusAddPoaFile,
  errorAddPoaFile
}) => {
  const [poaFile, setFile] = React.useState<File>(null);

  React.useEffect(() => {
    getCertificateGenitive();
  }, []);

  React.useEffect(
    () => {
      if (certificateGenitiveStatus === REQUEST_STATUSES.GOT) {
        getPOAS(specificCertificate.personInn);
      }
    },
    [certificateGenitiveStatus]
  );

  React.useEffect(
    () => {
      if (statusAddPoaFile === REQUEST_STATUSES.GOT) {
        closeModal();
        getPOAS(specificCertificate.personInn);
      }
    },
    [statusAddPoaFile]
  );

  const specificCertificate = getCertificateById(
    window.location.href
      .split('/')
      .filter(x => x.length > 0)
      .pop(),
    certificateGenitive
  );

  const sortedPoa = POAS.sort((a, b) => {
    if (a.status !== b.status) {
      return a.status - b.status;
    } else {
      return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
    }
  });

  const handleSetFile = (file: File) => setFile(file);

  const handleRemoveFile = () => setFile(null);

  const handleSendPowerAttorney = () => {
    if (poaFile) {
      const formData = new FormData();
      formData.append('file', poaFile);
      formData.append('PersonInn', specificCertificate.personInn);
      putAddPoaFile(formData);
    }
  };

  return (
    <PageNoMarginStyled>
      {certificateGenitiveStatus === REQUEST_STATUSES.REQUEST && <Loader />}
      {certificateGenitiveStatus === REQUEST_STATUSES.GOT && (
        <React.Fragment>
          <HeaderStyled>
            <p>{specificCertificate.guid}</p>
            <h2>СЕРТИФИКАТ</h2>
          </HeaderStyled>
          <CetrificatesEditInfoContentBlock>
            <AdditionalContainer>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Владелец сертификата:</TextBig>
                  </TextContainer>
                  <TextBig>
                    {specificCertificate.personSurNameSN +
                      ' ' +
                      specificCertificate.personNameG}
                  </TextBig>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputDisabled
                    value={
                      specificCertificate.personFullnameGenitive
                        ? specificCertificate.personFullnameGenitive
                        : null
                    }
                    placeholder={'не заполнено'}
                    disabled={true}
                    style={{ width: '300px' }}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>Фамилия Имя на англ. языке</Text>
                  </TextContainer>
                  <InputDisabled
                    name="personFullnameEng"
                    value={specificCertificate.personFullnameEng}
                    placeholder={'не заполнено'}
                    disabled={true}
                    style={{ width: '300px' }}
                  />
                </AdditionalRow>
              </AdditionalBlock>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Должность</TextBig>
                  </TextContainer>
                  <TextBig>{specificCertificate.positionT}</TextBig>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputDisabled
                    value={
                      specificCertificate.positionGenitive
                        ? specificCertificate.positionGenitive
                        : null
                    }
                    placeholder={'не заполнено'}
                    disabled={true}
                    style={{ width: '250px' }}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>Должность на англ. языке</Text>
                  </TextContainer>
                  <InputDisabled
                    name="positionEng"
                    value={specificCertificate.positionEng}
                    placeholder={'не заполнено'}
                    style={{ width: '250px' }}
                    disabled={true}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Основание полномочий</TextBig>
                  </TextContainer>
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в именительном падеже:</Text>
                  </TextContainer>
                  <InputDisabled
                    value={
                      specificCertificate.authority
                        ? specificCertificate.authority
                        : null
                    }
                    placeholder={'не заполнено'}
                    disabled={true}
                    style={{ width: '450px' }}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>в родительном падеже:</Text>
                  </TextContainer>
                  <InputDisabled
                    value={
                      specificCertificate.authorityGenitive
                        ? specificCertificate.authorityGenitive
                        : null
                    }
                    placeholder={'не заполнено'}
                    disabled={true}
                    style={{ width: '450px' }}
                  />
                </AdditionalRow>
                <AdditionalRow>
                  <TextContainer>
                    <Text>Основания полномочий на англ. языке</Text>
                  </TextContainer>
                  <InputDisabled
                    name="authorityEng"
                    value={specificCertificate.authorityEng}
                    placeholder={'не заполнено'}
                    style={{ width: '450px' }}
                    disabled={true}
                  />
                </AdditionalRow>
              </AdditionalBlock>
              <AdditionalBlock>
                <AdditionalRow>
                  <TextContainer>
                    <TextBig>Срок действия</TextBig>
                  </TextContainer>
                  <TextBig>
                    {format(
                      new Date(specificCertificate.certValidFrom),
                      'dd.MM.yyyy'
                    ) +
                      ' - ' +
                      format(
                        new Date(specificCertificate.certValidUntil),
                        'dd.MM.yyyy'
                      )}
                  </TextBig>
                </AdditionalRow>
              </AdditionalBlock>
              <BtnsBlock>
                <EditBtn
                  onClick={() =>
                    history.push(
                      `${window.location.href
                        .split('/')
                        .filter(x => x.length > 0)
                        .pop()}/edit`
                    )
                  }
                >
                  <EditBtnText>Редактировать</EditBtnText>
                </EditBtn>
              </BtnsBlock>
              <TextLittle>
                Последнее изменение:{' '}
                {format(
                  new Date(specificCertificate.modifiedDateTime),
                  'dd.MM.yyyy'
                )}
              </TextLittle>
            </AdditionalContainer>
            <DisplayHeader
              positionGenitive={specificCertificate.positionGenitive}
              personFullnameGenitive={
                specificCertificate.personFullnameGenitive
              }
              authorityGenitive={specificCertificate.authorityGenitive}
            />
          </CetrificatesEditInfoContentBlock>

          <PowerAttorney>
            <TextBig>Машиночитаемые доверенности:</TextBig>

            {!!POAS.length ? (
              <>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="30%">Идентификатор</TableThStyled>
                      <TableThStyled width="20%">Срок действия</TableThStyled>
                      <TableThStyled width="60%">Статус</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {sortedPoa.map((item, index) => (
                      <TableRowStyled
                        key={index}
                        color={item.status === 0 && 'red'}
                      >
                        <td>{item.taxAuthorityGuid}</td>
                        <td>{`${new Date(
                          item.startDate
                        ).toLocaleDateString()} - ${new Date(
                          item.endDate
                        ).toLocaleDateString()}`}</td>
                        <td>
                          <Status>
                            {item.status === 1 ? (
                              <>
                                <div>Действует</div>
                                <span>
                                  Последнее обновление:{' '}
                                  {new Date(
                                    item.statusDate
                                  ).toLocaleDateString()}
                                </span>
                              </>
                            ) : (
                              item.status === 0 && <div>Не действует</div>
                            )}
                          </Status>
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>

                <Button
                  label="Добавить"
                  onClick={() => {
                    openModal({ name: MODAL_NAMES.POWER_ATTORNEY });
                  }}
                />
              </>
            ) : (
              <>
                <p>Не добавлены</p>
                <Button
                  label="Добавить"
                  onClick={() => {
                    openModal({ name: MODAL_NAMES.POWER_ATTORNEY });
                  }}
                />
              </>
            )}
          </PowerAttorney>

          <Modal name={MODAL_NAMES.POWER_ATTORNEY}>
            <PowerAttorneyModal>
              <TextBig>Добавление доверенности</TextBig>

              <div>
                <p>ФИО</p>
                {`${specificCertificate.personSurNameSN} ${
                  specificCertificate.personNameG
                }`}
              </div>

              <div>
                <p>ИНН ФЛ</p>
                {specificCertificate.personInn}
              </div>

              <div>
                <p>Файл</p>
                <div
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '5px'
                  }}
                >
                  <Label>
                    Выбрать файл
                    <HiddenInput
                      type="file"
                      onChange={e => handleSetFile(e.target.files[0])}
                      accept="*"
                    />
                  </Label>
                  {!!poaFile && (
                    <FileInfo>
                      <span>{poaFile.name}</span>
                      <RemoveButton onClick={handleRemoveFile}>X</RemoveButton>
                    </FileInfo>
                  )}
                </div>
              </div>

              <Button
                label="+ Добавить"
                disabled={!poaFile}
                onClick={handleSendPowerAttorney}
              />

              <ErrorAddPoa>
                {statusAddPoaFile === REQUEST_STATUSES.ERROR &&
                  (errorAddPoaFile.code === 404 ? (
                    <>
                      <p>ОШИБКА!</p>
                      <p>
                        Доверенность не найдена в системе. Проверьте правильно
                        указания идентификатора и пароля.
                      </p>
                    </>
                  ) : (
                    errorAddPoaFile.code !== 404 &&
                    errorAddPoaFile.code !== 200 && (
                      <>
                        <p>ОШИБКА {errorAddPoaFile.code}</p>
                        <p>{errorAddPoaFile.body || errorAddPoaFile.message}</p>
                      </>
                    )
                  ))}
              </ErrorAddPoa>
            </PowerAttorneyModal>
          </Modal>
        </React.Fragment>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  certificateGenitive: SCF.getCertificateGenitive.data,
  certificateGenitiveStatus: SCF.getCertificateGenitive.status,
  POAS: SCF.getPOAS.data,
  errorAddPoa: SCF.putAddPoa.error,
  statusAddPoa: SCF.putAddPoa.status,
  statusAddPoaFile: SCFSupplier.putAddPoaFile.status,
  errorAddPoaFile: SCFSupplier.putAddPoaFile.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCertificateGenitive,
      getPOAS,
      openModal,
      closeModal,
      putAddPoa,
      putAddPoaFile
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificatesInfo);
