import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  req as getLimits,
  ResponseData as LimitsData
} from 'src/features/LimitsForBank/actions/getLimits';
import { req as putOpenLoanLimitsSetActive } from 'src/features/LimitsForBank/actions/putOpenLoanLimitsSetActive';
import { req as putOpenLoanLimitsSetInActive } from 'src/features/LimitsForBank/actions/putOpenLoanLimitsSetInactive';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ExpirationDateStyled,
  ProductNameWrapper,
  StatusWrapper,
  TDStatusContainer
} from './styled';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

interface StateToProps {
  limits: LimitsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusPutOpenLoanLimitsSetActive: REQUEST_STATUSES;
  statusPutOpenLoanLimitsSetInActive: REQUEST_STATUSES;
}

interface DispatchToProps {
  getLimits: () => void;
  putOpenLoanLimitsSetActive: (id: string) => void;
  putOpenLoanLimitsSetInActive: (id: string) => void;
}

type Props = StateToProps & DispatchToProps;

const LimitsForBankList: React.FC<Props> = ({
  limits,
  status,
  error,
  getLimits,
  putOpenLoanLimitsSetActive,
  putOpenLoanLimitsSetInActive,
  statusPutOpenLoanLimitsSetActive,
  statusPutOpenLoanLimitsSetInActive
}) => {
  const [hoveredStatus, setHoveredStatus] = React.useState({
    status: null,
    key: null
  });

  React.useEffect(
    () => {
      getLimits();
      setHoveredStatus({ status: null, key: null });
    },
    [statusPutOpenLoanLimitsSetActive, statusPutOpenLoanLimitsSetInActive]
  );

  const currentDate = new Date();

  const expirationDateConvert = (targetDate: Date) => {
    if (targetDate.getFullYear() < currentDate.getFullYear()) {
      return <ExpirationDateStyled>Неизв.</ExpirationDateStyled>;
    } else if (
      targetDate.getFullYear() === currentDate.getFullYear() &&
      targetDate.getDay() < currentDate.getDay() &&
      targetDate.getMonth() < currentDate.getMonth()
    ) {
      return (
        <ExpirationDateStyled dateOut={true}>
          {targetDate.toLocaleDateString()}
        </ExpirationDateStyled>
      );
    } else if (targetDate > currentDate) {
      return (
        <ExpirationDateStyled>
          {targetDate.toLocaleDateString()}
        </ExpirationDateStyled>
      );
    }
  };

  const handleMouseEnter = (status: boolean, key: number) => {
    setHoveredStatus({ status, key });
  };

  const renderButton = (id: string) => {
    if (hoveredStatus.status === true) {
      return (
        <StatusWrapper
          colorIcon="red"
          onClick={() => putOpenLoanLimitsSetInActive(id)}
        >
          <FontAwesomeIcon icon={faTimes} />
          <button>Отключить</button>
        </StatusWrapper>
      );
    } else if (hoveredStatus.status === false) {
      return (
        <StatusWrapper
          colorIcon="#1CCA94"
          onClick={() => putOpenLoanLimitsSetActive(id)}
        >
          <FontAwesomeIcon icon={faCheck} />
          <button>Сделать активным</button>
        </StatusWrapper>
      );
    }
    return null;
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Лимиты</ApplicationTitle>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">Клиент</TableThStyled>
              <TableThStyled width="8%">Продукт</TableThStyled>
              <TableThStyled width="8%">Лимит</TableThStyled>
              <TableThStyled width="8%">Дата окончания</TableThStyled>
              <TableThStyled width="8%">Статус</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {limits.items.map((limit, key) => (
              <TableRowStyled key={key}>
                <td>
                  <ProductNameWrapper>
                    <div>{limit.companyShortName}</div>
                    <div> {limit.companyINN}</div>
                  </ProductNameWrapper>
                </td>
                <td>
                  {limit.productName === null
                    ? 'Не установлен'
                    : limit.productName}
                </td>
                <td>
                  {limit.volume === 0 || limit.volume === null
                    ? 'Неизв.'
                    : limit.volume}
                </td>
                <td>{expirationDateConvert(new Date(limit.expirationDate))}</td>
                <TDStatusContainer>
                  <div>
                    {limit.isActual === true &&
                    new Date(limit.expirationDate) > currentDate ? (
                      <p onMouseEnter={() => handleMouseEnter(true, key)}>
                        Действующий
                      </p>
                    ) : limit.isActual === false ? (
                      <p onMouseEnter={() => handleMouseEnter(false, key)}>
                        Недействующий
                      </p>
                    ) : limit.isActual === null ||
                    (limit.isActual === true &&
                      new Date(limit.expirationDate) <= currentDate) ? (
                      <p onMouseEnter={() => handleMouseEnter(false, key)}>
                        Неизв.
                      </p>
                    ) : null}
                    {hoveredStatus.key === key && renderButton(limit.guid)}
                  </div>
                </TDStatusContainer>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={limits} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ LimitsForBank }: CRM) => ({
  limits: LimitsForBank.getLimits.data,
  status: LimitsForBank.getLimits.status,
  error: LimitsForBank.getLimits.error,
  statusPutOpenLoanLimitsSetActive:
    LimitsForBank.putOpenLoanLimitsSetActive.status,
  statusPutOpenLoanLimitsSetInActive:
    LimitsForBank.putOpenLoanLimitsSetInActive.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getLimits, putOpenLoanLimitsSetActive, putOpenLoanLimitsSetInActive },
    dispatch
  );

const LimitsForBankListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LimitsForBankList);

export { LimitsForBankListConnect as LimitsForBankList };
