import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostDocumentsFormFillStoreState extends Reducer {
  data: string;
}

export enum POST_DOCUMENTS_FORM_FILL {
  REQ = 'POST_DOCUMENTS_FORM_FILL_REQ',
  GOT = 'POST_DOCUMENTS_FORM_FILL_GOT',
  ERR = 'POST_DOCUMENTS_FORM_FILL_ERR'
}

const initialState: PostDocumentsFormFillStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: '',
  error: {}
};

const postDocumentsFormFill = (
  state: PostDocumentsFormFillStoreState = initialState,
  action: {
    type: POST_DOCUMENTS_FORM_FILL;
    data: string;
    error: {};
  }
): PostDocumentsFormFillStoreState => {
  switch (action.type) {
    case POST_DOCUMENTS_FORM_FILL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_DOCUMENTS_FORM_FILL.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case POST_DOCUMENTS_FORM_FILL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postDocumentsFormFill;
