import { PATCH_SET_FORBIDDEN_REGION_ALL } from '../reducers/patchSetForbiddenRegionAll';

export const req = (id: string) => ({
  type: PATCH_SET_FORBIDDEN_REGION_ALL.REQ,
  id
});

export const got = () => ({
  type: PATCH_SET_FORBIDDEN_REGION_ALL.GOT
});

export const err = (error: {}) => ({
  type: PATCH_SET_FORBIDDEN_REGION_ALL.ERR,
  error
});
