import { POST_OFFER_DISCOUNT } from 'entities/SCF/Supplier/model/reducers/postOfferDiscount';

export interface RequestData {
  baseDiscountRate?: string;
  absoluteDiscount?: string;
  absoluteDiscountRate?: string;
}

export const req = (guid: string, data: RequestData) => ({
  type: POST_OFFER_DISCOUNT.REQ,
  guid,
  data
});

export const got = () => ({
  type: POST_OFFER_DISCOUNT.GOT
});

export const err = (error: {}) => ({
  type: POST_OFFER_DISCOUNT.ERR,
  error
});

export const reset = () => ({
  type: POST_OFFER_DISCOUNT.RESET
});
