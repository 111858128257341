import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  req as getEarlyPayment,
  ResponseData,
  RequestData
} from 'src/features/EarlyPaymentApplications/actions/getEarlyPayment';
import { ScrollTopComponent } from 'src/features/Common';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import {
  FlexContainer,
  INNContainer,
  INNField,
  MonetaryClaims,
  PayDate,
  PlatformFee,
  Status,
  StatusList,
  TDStyled,
  Tooltip,
  TooltipBoxStyle
} from './styled';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  formatNumberWithComma,
  formSumStringThousands
} from 'src/shared/utils/Utils';

interface StateToProps {
  payments: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getEarlyPayment: (data: RequestData) => void;
}

interface MatchParams {
  inn?: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const statusMessages = {
  0: 'Поставщик еще не выбрал дату оплаты и кредитора',
  4: 'Поставщик еще не выбрал подписанта',
  6: 'Поставщик еще не согласился с условиями расчета',
  8: 'Поставщик еще не подписал соглашение',
  12: 'Дебитор еще не подтвердил и не подписал соглашение',
  13: 'Дебитор подтвердил, но еще не подписал соглашение',
  14: 'Отказ дебитора',
  16: 'Сделка заключена'
};

const stagesByStatus = {
  0: [
    'Поставщик еще не выбрал дату оплаты и кредитора',
    'Поставщик еще не выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  4: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик еще не выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  6: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  8: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  12: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  13: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик подписал соглашение',
    'Дебитор подтвердил соглашение',
    'Дебитор еще не подписал соглашение'
  ]
};

const getStageStyle = (status: number, index: number) => {
  if (status === 0 && index > 0) {
    return { color: '#9FA6B2' };
  } else if (status === 4 && index > 1) {
    return { color: '#9FA6B2' };
  } else if (status === 6 && index > 2) {
    return { color: '#9FA6B2' };
  } else if (status === 8 && index > 3) {
    return { color: '#9FA6B2' };
  } else if (status === 12 && index > 4) {
    return { color: '#9FA6B2' };
  } else if (status === 13 && index > 5) {
    return { color: '#9FA6B2' };
  }

  // Зачёркивание всех завершённых этапов
  if (
    (status === 4 && index === 0) ||
    (status === 6 && index <= 1) ||
    (status === 8 && index <= 2) ||
    (status === 12 && index <= 3) ||
    (status === 13 && index <= 4)
  ) {
    return { textDecoration: 'line-through' };
  }

  return {};
};

const EarlyPaymentApplications: React.FC<Props> = ({
  getEarlyPayment,
  payments,
  status,
  error,
  history,
  match
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');
  const [investorInn, setInvestorInn] = React.useState('');
  const [hoveredStatus, setHoveredStatus] = React.useState(null);

  const handleMouseEnter = (status: number) => {
    if ([0, 4, 6, 8, 12, 13].includes(status)) {
      setHoveredStatus(status);
    }
  };

  const handleMouseLeave = () => {
    setHoveredStatus(null);
  };

  const isDebtor = history.location.pathname.includes('debtors');
  const isSupplier = history.location.pathname.includes('suppliers');
  const isInvestor = history.location.pathname.includes('factors');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';
      const investorInn = searchParams.get('investorInn') || '';

      setDebtorInn(debtorInn);
      setSupplierInn(supplierInn);
      setInvestorInn(investorInn);

      const currentInn = match.params.inn;

      getEarlyPayment({
        page,
        pageSize: 20,
        ...(isInvestor && { payerType: 2 }),
        ...(isDebtor
          ? { DebtorInn: currentInn }
          : debtorInn && { DebtorInn: debtorInn }),
        ...(isSupplier
          ? { SupplierInn: currentInn }
          : supplierInn && { SupplierInn: supplierInn }),
        ...(isInvestor
          ? { InvestorInn: currentInn }
          : investorInn && { InvestorInn: investorInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const navigateToDebtor = (inn: string) => {
    window.open(`/internal/debtors/${inn}`, '_blank');
  };

  const navigateToSupplier = (inn: string) => {
    window.open(`/internal/suppliers/${inn}`, '_blank');
  };

  const navigateToInvestor = (debtorInn: string, investorInn: string) => {
    if (investorInn === debtorInn) {
      window.open(`/internal/debtors/${investorInn}`, '_blank');
    } else {
      window.open(`/internal/factors/${investorInn}`, '_blank');
    }
  };

  const navigateToPayment = (guid: string) => {
    window.open(`/internal/early_payment_applications/${guid}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Заявки на раннюю оплату</ApplicationTitle>

      <INNContainer>
        {!isDebtor && (
          <INNField>
            <Input
              label="ИНН Дебитора"
              value={debtorInn}
              name="debtorInn"
              placeholder={InnPlaceholders.entity}
              onChange={onFilterChange}
            />
          </INNField>
        )}

        {!isSupplier && (
          <INNField>
            <Input
              label="ИНН Поставщика"
              value={supplierInn}
              name="supplierInn"
              placeholder={InnPlaceholders.entity}
              onChange={onFilterChange}
            />
          </INNField>
        )}

        {!isInvestor && (
          <INNField>
            <Input
              label="ИНН Инвестора"
              value={investorInn}
              name="investorInn"
              placeholder={InnPlaceholders.entity}
              onChange={onFilterChange}
            />
          </INNField>
        )}
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%" />
                {!isDebtor && (
                  <TableThStyled width="15%">Дебитор</TableThStyled>
                )}
                {!isSupplier && (
                  <TableThStyled width="15%">Поставщик</TableThStyled>
                )}
                {!isInvestor && (
                  <TableThStyled width="15%">Инвестор</TableThStyled>
                )}
                <TableThStyled width="10%">Дата оплаты</TableThStyled>
                <TableThStyled width="10%">Сумма к оплате</TableThStyled>
                <TableThStyled width="10%">Дисконт</TableThStyled>
                <TableThStyled width="10%">Комиссия FF</TableThStyled>
                <TableThStyled width="15%">Статус</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {payments.items.map(payment => (
                <TableRowStyled
                  key={payment.id}
                  onClick={() => navigateToPayment(payment.guid)}
                >
                  <td>{payment.guid.slice(-7)}</td>
                  {!isDebtor && (
                    <TDStyled>
                      <FlexContainer>
                        <p>{payment.debtorInn}</p>
                        {payment.debtorInn && (
                          <TooltipBoxStyle text="Открыть в новом окне">
                            <FontAwesomeIcon
                              icon={faExternalLinkSquareAlt}
                              onClick={e => {
                                e.stopPropagation();
                                navigateToDebtor(payment.debtorInn);
                              }}
                            />
                          </TooltipBoxStyle>
                        )}
                      </FlexContainer>
                      <div>{payment.debtorName}</div>
                    </TDStyled>
                  )}
                  {!isSupplier && (
                    <TDStyled>
                      {payment.supplierName !== null ? (
                        <>
                          <FlexContainer>
                            <p>{payment.supplierInn}</p>
                            {payment.supplierInn && (
                              <TooltipBoxStyle text="Открыть в новом окне">
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  onClick={e => {
                                    e.stopPropagation();
                                    navigateToSupplier(payment.supplierInn);
                                  }}
                                />
                              </TooltipBoxStyle>
                            )}
                          </FlexContainer>
                          <div>{payment.supplierName}</div>
                        </>
                      ) : (
                        <FlexContainer>
                          <p>{payment.supplierInn}</p>
                          {payment.supplierInn && (
                            <TooltipBoxStyle text="Открыть в новом окне">
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                onClick={e => {
                                  e.stopPropagation();
                                  navigateToSupplier(payment.supplierInn);
                                }}
                              />
                            </TooltipBoxStyle>
                          )}
                        </FlexContainer>
                      )}
                    </TDStyled>
                  )}
                  {!isInvestor && (
                    <TDStyled>
                      {payment.payerName !== null ? (
                        <>
                          <FlexContainer>
                            <p>{payment.payerInn}</p>
                            {payment.payerInn && (
                              <TooltipBoxStyle text="Открыть в новом окне">
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  onClick={e => {
                                    e.stopPropagation();
                                    navigateToInvestor(
                                      payment.debtorInn,
                                      payment.payerInn
                                    );
                                  }}
                                />
                              </TooltipBoxStyle>
                            )}
                          </FlexContainer>
                          <div>{payment.payerName}</div>
                        </>
                      ) : (
                        <div>Не выбран</div>
                      )}
                    </TDStyled>
                  )}
                  <td>
                    {payment.payDate !== null ? (
                      <PayDate>
                        <div>
                          {new Date(
                            payment.firstBasePaymentDate
                          ).toLocaleDateString()}
                        </div>
                        <div>
                          {new Date(payment.payDate).toLocaleDateString()}
                        </div>
                      </PayDate>
                    ) : (
                      <div>Не выбрана</div>
                    )}
                  </td>
                  <td>
                    <MonetaryClaims>
                      <div>
                        {formSumStringThousands(
                          payment.monetaryClaimsTotalFullSum
                        )}{' '}
                        руб.
                      </div>
                      <div>
                        {payment.newTotalSumOnPayday
                          ? `${formSumStringThousands(
                              payment.newTotalSumOnPayday
                            )} руб.`
                          : 'не заполнено'}
                      </div>
                    </MonetaryClaims>
                  </td>
                  <td>
                    <div>
                      {payment.baseDiscountRate !== null
                        ? formatNumberWithComma(payment.baseDiscountRate)
                        : '-'}{' '}
                      % годовых
                    </div>
                    <div>
                      {payment.discountPeriod ? payment.discountPeriod : '-'}{' '}
                      дней
                    </div>
                    <div>
                      {payment.absoluteDiscount
                        ? formSumStringThousands(payment.absoluteDiscount)
                        : '-'}{' '}
                      руб.
                    </div>
                  </td>
                  <td>
                    <PlatformFee>
                      {payment.platformFee !== null ? (
                        <>{formSumStringThousands(payment.platformFee)} руб.</>
                      ) : (
                        <div>-</div>
                      )}
                    </PlatformFee>
                  </td>
                  <TDStyled>
                    <Status
                      status={payment.status}
                      onMouseEnter={() => handleMouseEnter(payment.status)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <p>{statusMessages[payment.status]}</p>

                      <Tooltip isVisible={hoveredStatus === payment.status}>
                        <StatusList>
                          {stagesByStatus[payment.status] &&
                            stagesByStatus[payment.status].map(
                              (stage: string, index: number) => (
                                <li
                                  key={index}
                                  style={getStageStyle(payment.status, index)}
                                >
                                  {stage}
                                </li>
                              )
                            )}
                        </StatusList>
                      </Tooltip>
                    </Status>
                  </TDStyled>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        </>
      )}
      <Pagination list={payments} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ EarlyPayment }: STORE) => ({
  payments: EarlyPayment.getEarlyPayment.data,
  status: EarlyPayment.getEarlyPayment.status,
  error: EarlyPayment.getEarlyPayment.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getEarlyPayment }, dispatch);

const EarlyPaymentApplicationsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EarlyPaymentApplications)
);

export { EarlyPaymentApplicationsConnect as EarlyPaymentApplications };
