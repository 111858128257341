import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { history } from 'src/shared/utils/History';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import { FormContainer } from 'src/features/Products/components/NewProduct/styled';
import { ApplicationTypes } from 'shared/constants';
import { ErrorMsg } from 'src/features/Contacts/components/CreateNewContact/styled';
import {
  req as createProduct,
  reset as resetProductState
} from 'src/features/ProductsForBank/actions/sendProductData';

interface StateToProps {
  createStatus: REQUEST_STATUSES;
}

interface State {
  productName: string;
  financeTypeId: string;
  errors: {
    productName: string;
  };
}

interface MatchParams {}

interface DispatchToProps {
  createProduct: (data: any) => void;
  resetProductState: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class NewProduct extends React.Component<Props, State> {
  state: State = {
    productName: '',
    financeTypeId: '',
    errors: {
      productName: ''
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.createStatus === REQUEST_STATUSES.GOT) {
      this.props.resetProductState();
      history.goBack();
    }
  }

  handleInput = (name: keyof State, value: string) => {
    this.setState({ [name]: value } as any);
  };

  handleCreateProduct = event => {
    event.preventDefault();

    const errors: State['errors'] = {
      productName: ''
    };

    this.setState({ errors });

    if (Object.values(errors).every(error => error === '')) {
      const { errors, ...data } = this.state;
      this.props.createProduct({ ...data, financeTypeId: +data.financeTypeId });
    }
  };

  render() {
    return (
      <ApplicationViewStyled>
        <ApplicationTitle>Создание продукта</ApplicationTitle>

        <FormContainer onSubmit={this.handleCreateProduct}>
          <Select
            options={Object.entries(ApplicationTypes).map(
              ([key, value], index) => ({
                id: (index + 1).toString(),
                name: value
              })
            )}
            placeholder="Тип продукта"
            label="Тип продукта:"
            name="financeTypeId"
            value={this.state.financeTypeId}
            onChange={e =>
              this.handleInput('financeTypeId', e.currentTarget.value)
            }
          />

          <Input
            label="Краткое название продукта:"
            name="productName"
            value={this.state.productName}
            onChange={e =>
              this.handleInput('productName', e.currentTarget.value)
            }
          />

          {this.state.errors.productName && (
            <ErrorMsg>{this.state.errors.productName}</ErrorMsg>
          )}

          <Button label="Создать" type={'submit'} w="fit-content" h="40px" />
        </FormContainer>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ ProductsBank }: CRM & STORE) => ({
  createStatus: ProductsBank.sendProductBankData.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ createProduct, resetProductState }, dispatch);

const NewProductConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(NewProduct)
);

export { NewProductConnect as NewProduct };
