import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/SCF/Internal/model/actions/getMonetaryClaimsInternalAgent';

export interface GetMonetaryClaimsInternalAgentStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_MONETARY_CLAIMS_INTERNAL_AGENT {
  REQ = 'GET_MONETARY_CLAIMS_INTERNAL_AGENT_REQ',
  GOT = 'GET_MONETARY_CLAIMS_INTERNAL_AGENT_GOT',
  ERR = 'GET_MONETARY_CLAIMS_INTERNAL_AGENT_ERR'
}

export const initialState: GetMonetaryClaimsInternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 20,
    totalItems: null,
    availableChecks: null,
    fullSum: null,
    items: []
  },
  error: {}
};

const getMonetaryClaimsInternalAgent = (
  state: GetMonetaryClaimsInternalAgentStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetMonetaryClaimsInternalAgentStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_INTERNAL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_INTERNAL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_INTERNAL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsInternalAgent;
