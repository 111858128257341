import { GET_CERTIFICATES } from 'entities/Cabinet/InternalAgent/model/reducers/getCertificates';

interface Certificate {
  id: number;
  createdDateTime: string;
  modifiedDateTime: string;
  companyInn: string;
  companyOgrnOrOgrnIP: string;
  companyName_O: string;
  companyName_CN: string;
  companyCountryC: string;
  companyRegionS: string;
  companyLocationL: string;
  companyAddressStreet: string;
  positionT: string;
  personSurNameSN: string;
  personNameG: string;
  personInn: string;
  personEmailE: string;
  personSnils: string;
  certValidFrom: string;
  certValidUntil: string;
  keyValidFrom: string;
  keyValidUntil: string;
  issuerInn: string;
  issuerOGRN: string;
  issuerName_O: string;
  issuerName_CN: string;
  issuerTypeOU: string;
  issuerCountryC: string;
  issuerRegionS: string;
  issuerLocationL: string;
  issuerAddressStreet: string;
  issuerEmailE: string;
  serialNumber: string;
  guid: string;
  personFullnameGenitive: string;
  positionGenitive: string;
  authority: string;
  authorityGenitive: string;
  isMain: boolean;
  type: number;
  isAvailable: boolean;
  ifnsPoaId: any;
  personFullnameEng: string;
  authorityEng: string;
  positionEng: string;
  isDeleted: boolean;
}

export interface RequestData {
  companyInn: string;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Certificate[];
}

export const req = (data: RequestData) => ({
  type: GET_CERTIFICATES.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_CERTIFICATES.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_CERTIFICATES.ERR,
  error
});
