import * as React from 'react';
import Popup from 'reactjs-popup';
// TODO extract from ApplicationList styled
import { PopupTrigger, ProfileInfoPopupStyled, PullRight } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ProfileInfoPopup/styles';
import { CrossCircleBtn } from 'shared/styled';
import { LeadRead } from 'src/features/Leads/actions/setLeadFormData';

interface ContactPopupProps {
  // TODO extract Contact from LeadRead
  lead: LeadRead;
}

// TODO duplication With ApplicationsList Popup
const ContactPopup: React.FC<ContactPopupProps> = (props) => {
  const ref = React.useRef(null);
  const lead = props.lead;
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();
  const toggleTooltip = () => ref.current.toggle();
    return (
        <Popup ref={ref} position="top right"
            trigger={
                <PopupTrigger>
                    {lead.contactFirstName} {lead.contactLastName}
                </PopupTrigger>
            }>
            <ProfileInfoPopupStyled>
                <PullRight>
                    <CrossCircleBtn onClick={closeTooltip} />
                </PullRight>
                <p>{lead.contactFirstName} {lead.contactLastName}</p>
                <p>{lead.contactEmail}</p>
                <p>{lead.contactPhoneNumber}</p>
            </ProfileInfoPopupStyled>
        </Popup>
    )

};

export default ContactPopup;
