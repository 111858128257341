import {
  FINANCING_FORM_ACTIONS,
  SetFinancingApplicationType
} from 'Application/reducers/setFinancingFormData';

export const setFinancingFormData = (data: SetFinancingApplicationType) => ({
  type: FINANCING_FORM_ACTIONS.SET,
  data
});

export const resetFinancingFormData = () => ({
  type: FINANCING_FORM_ACTIONS.RESET
});
