import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddTenderByIdStoreState extends Reducer {}

export enum POST_ADD_TENDER_BY_ID {
  REQ = 'POST_ADD_TENDER_BY_ID_REQ',
  GOT = 'POST_ADD_TENDER_BY_ID_GOT',
  ERR = 'POST_ADD_TENDER_BY_ID_ERR'
}

export const initialState: PostAddTenderByIdStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddTenderById = (
  state: PostAddTenderByIdStoreState = initialState,
  action: {
    type: POST_ADD_TENDER_BY_ID;
    error: {};
  }
): PostAddTenderByIdStoreState => {
  switch (action.type) {
    case POST_ADD_TENDER_BY_ID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADD_TENDER_BY_ID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADD_TENDER_BY_ID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddTenderById;
