import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'src/entities/Cabinet/Bank/model/actions/getAllOfBank';
import { GET_ALL_OF_BANK_BANK_ADMIN } from 'entities/Cabinet/Bank/model/reducers/getAllOfBank';

import ApplicationApi from 'entities/Cabinet/Bank/api';

function* getAllOfBank(action: { type: GET_ALL_OF_BANK_BANK_ADMIN }) {
  try {
    const res = yield call(ApplicationApi.getAllOfBank);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllOfBankSaga() {
  yield takeLatest(GET_ALL_OF_BANK_BANK_ADMIN.REQ, getAllOfBank);
}
