import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { CONTRACT_PREPAYMENT_TYPE } from 'src/features/Application/types';
import {
  ApplicationTypes,
  ContractFinancingType,
  ContractSubjectType,
  ContractPrepaymentType
} from 'shared/constants';
import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import { req as getInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import {
  req as getInBankApplication,
  reset as resetInBankApplication
} from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import { req as getCompanyHints } from 'Application/actions/getCompanyHints';
import { req as getProductApplication } from 'Application/actions/getProductApplication';
import { reset as resetSelectApplicationState } from 'Application/actions/postSelectApplication';
import { req as getActivitiesByApplication } from 'src/features/Application/actions/getActivitiesByApplication';
import {
  req as getFinancialsSummary,
  reset as resetFinancialsSummary
} from 'src/features/Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';
import { ResponseData as ResInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { EachApplicationView } from './EachApplicationView';
import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  internalApplication: ResInternalApplication;
  internalStatus: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
  statusSelectApplication: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  statusReject: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusPostSendBank: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  statusChangeApplicationStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  resetInBankApplication: () => void;
  getCompanyHints: (inn: string) => void;
  getProductApplication: (id: string) => void;
  resetSelectApplicationState: () => void;
  getActivitiesByApplication: (leadId: string) => void;
  getFinancialsSummary: (inn: string) => void;
  resetFinancialsSummary: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ContractFinancingView: React.FC<Props> = ({
  getInternalApplication,
  internalApplication,
  internalStatus,
  getInBankApplication,
  inBankApplication,
  statusInBankApplication,
  resetFinancialsSummary,
  resetInBankApplication,
  resetSelectApplicationState,
  getActivitiesByApplication,
  getCompanyHints,
  getFinancialsSummary,
  getProductApplication,
  statusPostSendBank,
  statusSendApi,
  statusReject,
  statusSelectApplication,
  statusSendToBank,
  statusChangeApplicationStatus,
  permissions,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getInternalApplication(id);
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getInternalApplication(inBankId);
        getInBankApplication({ id: +inBankId });
      }

      if (
        !permissions.includes(USER_PERMISSIONS.CLIENT) &&
        !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
        !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
        !inBankId
      ) {
        getProductApplication(id);
        getActivitiesByApplication(id);
      }

      return () => {
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getInternalApplication(inBankId);
          getInBankApplication({ id: +inBankId });
        }

        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER) &&
          !inBankId
        ) {
          getProductApplication(id);
          getActivitiesByApplication(id);
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (
        statusPostSendBank === REQUEST_STATUSES.GOT ||
        statusSendApi === REQUEST_STATUSES.GOT
      ) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
        } else if (inBankId) {
          getInternalApplication(inBankId);
        }
      }
    },
    [statusPostSendBank, statusSendApi]
  );

  React.useEffect(
    () => {
      if (
        statusSelectApplication === REQUEST_STATUSES.GOT ||
        statusSendToBank === REQUEST_STATUSES.GOT ||
        statusReject === REQUEST_STATUSES.GOT
      ) {
        resetSelectApplicationState();
        getProductApplication(match.params.id);
      }
    },
    [statusReject, statusSelectApplication, statusSendToBank]
  );

  React.useEffect(
    () => {
      if (internalStatus === REQUEST_STATUSES.GOT) {
        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
        ) {
          getCompanyHints(internalApplication.code);
          getFinancialsSummary(internalApplication.code);
        }
      }
    },
    [internalStatus]
  );

  if (
    internalStatus !== REQUEST_STATUSES.GOT ||
    statusInBankApplication !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  const setSubInfo = () => {
    const subinfo = [
      {
        name: 'Тип контракта',
        value: ContractFinancingType[internalApplication.contractType]
      },
      {
        name: 'Ссылка на закупку',
        value: internalApplication.purchaseLink
      },
      {
        name: 'Цена контракта',
        value: formSumString(internalApplication.contractAmount)
      },
      {
        name: 'Предмет контракта',
        value: ContractSubjectType[internalApplication.contractSubject]
      },
      {
        name: 'Срок окончания контракта',
        value: internalApplication.contractEndDate
      },
      {
        name: 'Аванс по договору',
        value: ContractPrepaymentType[internalApplication.prepaymentType]
      }
    ];
    if (
      internalApplication.prepaymentType ===
      CONTRACT_PREPAYMENT_TYPE.HAS_PREPAYMENT
    )
      subinfo.push({
        name: 'Размер аванса',
        value: internalApplication.prepaymentAmount
      });
    return subinfo;
  };

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[internalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(internalApplication.amount)
        },
        {
          name: 'Срок кредита',
          value: internalApplication.termEndDate
            ? internalApplication.termEndDate
            : formTermString(internalApplication.term)
        },
        {
          name: 'ИНН',
          value: internalApplication.code
        }
      ]}
      subInfo={[...setSubInfo()]}
      documents={{
        general: [
          ...(inBankApplication.agreementDocuments || []),
          ...(inBankApplication.groupDocuments || []),
          ...(inBankApplication.contractDocuments || [])
        ],
        company: inBankApplication.borrowerCompanies || []
      }}
    />
  );
};

const mapStateToProps = ({ Application, InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  internalStatus: InternalAgentRole.getInternalApplication.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  statusSelectApplication: Application.postSelectApplication.status,
  statusSendToBank: Application.putSendToBank.status,
  statusReject: Application.putRejected.status,
  permissions: User.getUserData.data.permissions,
  statusPostSendBank: Application.sendApplicationToBanks.status,
  statusSendApi: Application.postOfferSend.status,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInternalApplication,
      getInBankApplication,
      resetInBankApplication,
      getCompanyHints,
      getProductApplication,
      resetSelectApplicationState,
      getActivitiesByApplication,
      getFinancialsSummary,
      resetFinancialsSummary
    },
    dispatch
  );

const ContractFinancingViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingView)
);

export { ContractFinancingViewConnect as ContractFinancingView };
