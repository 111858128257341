import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getProductsListByExternalCredential';

import {
  GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL,
} from '../reducers/getProductsListByExternalCredential';
import ExternalCredentialsOfProductsApi from '../api';

function* getProductsListByExternalCredential(action: {
  type: GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(ExternalCredentialsOfProductsApi.getProductsListByExternalCredential, action.data.externalCredentialId);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductsListByExternalCredentialSaga(): Generator {
  yield takeLatest(GET_PRODUCTS_LIST_BY_EXTERNAL_CREDENTIAL.REQ, getProductsListByExternalCredential);
}
