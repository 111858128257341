import * as React from 'react';
import { Link } from 'react-router-dom';

import { APPLICATIONS_LIST_TYPE } from 'Application/reducers/setApplicationListType';

import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import {
  EmptyApplicationListGeneral,
  EmptyApplicationListDraft,
  EmptyApplicationListDeal
} from 'shared/styled';
import { ApplicationListEmptyStyled } from './styles';

interface Props {
  listType: APPLICATIONS_LIST_TYPE;
}

export const ApplicationListEmpty: React.FC<Props> = ({ listType }: Props) => {
  const segments = location.pathname.split('/');
  const roleIndex = segments.indexOf('cabinet') + 1;
  const role = segments[roleIndex];

  const renderEmptyList = () => {
    switch (listType) {
      case APPLICATIONS_LIST_TYPE.GENERAL:
        return (
          <React.Fragment>
            <EmptyApplicationListGeneral />
            <p>К сожалению, на платформе пока отсутствуют поданыe заявки.</p>
          </React.Fragment>
        );
      case APPLICATIONS_LIST_TYPE.WORK:
        return (
          <React.Fragment>
            <EmptyApplicationListDraft />
            <p>
              К сожалению, у Вас еще нет заявок принятых в работу.{' '}
              <Link to={`/cabinet/${role}/general-applications?page=1`}>
                Взять
              </Link>
            </p>
          </React.Fragment>
        );
      case APPLICATIONS_LIST_TYPE.MY:
        return (
          <React.Fragment>
            <EmptyApplicationListGeneral />
            <p>
              У вас нет заявок.{' '}
              <Link to={`/cabinet/${role}/application-form/create`}>
                Создайте
              </Link>{' '}
              первую форму заявки
            </p>
          </React.Fragment>
        );
      case APPLICATIONS_LIST_TYPE.DRAFT:
        return (
          <React.Fragment>
            <EmptyApplicationListDraft />
            <p>
              Список заполняемых заявок пуст. <br />
              Сохраните заявку во время заполнения и она появится на этой
              странице.
            </p>
          </React.Fragment>
        );
      case APPLICATIONS_LIST_TYPE.DEAL:
        return (
          <React.Fragment>
            <EmptyApplicationListGeneral />
            <p>Список успешных заявок пуст.</p>
          </React.Fragment>
        );
      case APPLICATIONS_LIST_TYPE.TRASH:
        return (
          <React.Fragment>
            <EmptyApplicationListGeneral />
            <p>Корзина пуста</p>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <EmptyApplicationListGeneral />
            <p>Пусто</p>
          </React.Fragment>
        );
    }
  };
  // TODO custom icon for deals & trash
  return (
    <ApplicationListEmptyStyled>{renderEmptyList()}</ApplicationListEmptyStyled>
  );
};
