import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/sendBuyers';

import {
  SEND_BUYERS,
  RequestDataType
} from 'src/features/SCF/reducers/sendBuyers';

import SCFApi from 'src/features/SCF/api';

function* sendBuyers(action: { type: SEND_BUYERS; data: RequestDataType }) {
  try {
    yield call(SCFApi.sendBuyersList, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendBuyersSaga() {
  yield takeLatest(SEND_BUYERS.REQ, sendBuyers);
}
