export const completions = [
  { label: "01 Республика Адыгея (Адыгея)" },
  { label: "02 Республика Башкортостан" },
  { label: "03 Республика Бурятия" },
  { label: "04 Республика Алтай" },
  { label: "05 Республика Дагестан" },
  { label: "06 Республика Ингушетия" },
  { label: "07 Кабардино-Балкарская Республика" },
  { label: "08 Республика Калмыкия" },
  { label: "09 Карачаево-Черкесская Республика" },
  { label: "10 Республика Карелия" },
  { label: "11 Республика Коми" },
  { label: "12 Республика Марий Эл" },
  { label: "13 Республика Мордовия" },
  { label: "14 Республика Саха (Якутия)" },
  { label: "15 Республика Северная Осетия - Алания" },
  { label: "16 Республика Татарстан (Татарстан)" },
  { label: "17 Республика Тыва" },
  { label: "18 Удмуртская Республика" },
  { label: "19 Республика Хакасия" },
  { label: "20 Чеченская Республика" },
  { label: "21 Чувашская Республика - Чувашия" },
  { label: "22 Алтайский край" },
  { label: "23 Краснодарский край" },
  { label: "24 Красноярский край" },
  { label: "25 Приморский край" },
  { label: "26 Ставропольский край" },
  { label: "27 Хабаровский край" },
  { label: "28 Амурская область" },
  { label: "29 Архангельская область" },
  { label: "30 Астраханская область" },
  { label: "31 Белгородская область" },
  { label: "32 Брянская область" },
  { label: "33 Владимирская область" },
  { label: "34 Волгоградская область" },
  { label: "35 Вологодская область" },
  { label: "36 Воронежская область" },
  { label: "37 Ивановская область" },
  { label: "38 Иркутская область" },
  { label: "39 Калининградская область" },
  { label: "40 Калужская область" },
  { label: "41 Камчатский край" },
  { label: "42 Кемеровская область" },
  { label: "43 Кировская область" },
  { label: "44 Костромская область" },
  { label: "45 Курганская область" },
  { label: "46 Курская область" },
  { label: "47 Ленинградская область" },
  { label: "48 Липецкая область" },
  { label: "49 Магаданская область" },
  { label: "50 Московская область" },
  { label: "51 Мурманская область" },
  { label: "52 Нижегородская область" },
  { label: "53 Новгородская область" },
  { label: "54 Новосибирская область" },
  { label: "55 Омская область" },
  { label: "56 Оренбургская область" },
  { label: "57 Орловская область" },
  { label: "58 Пензенская область" },
  { label: "59 Пермский край" },
  { label: "60 Псковская область" },
  { label: "61 Ростовская область" },
  { label: "62 Рязанская область" },
  { label: "63 Самарская область" },
  { label: "64 Саратовская область" },
  { label: "65 Сахалинская область" },
  { label: "66 Свердловская область" },
  { label: "67 Смоленская область" },
  { label: "68 Тамбовская область" },
  { label: "69 Тверская область" },
  { label: "70 Томская область" },
  { label: "71 Тульская область" },
  { label: "72 Тюменская область" },
  { label: "73 Ульяновская область" },
  { label: "74 Челябинская область" },
  { label: "75 Забайкальский край" },
  { label: "76 Ярославская область" },
  { label: "77 г. Москва" },
  { label: "78 Санкт-Петербург" },
  { label: "79 Еврейская автономная область" },
  { label: "83 Ненецкий автономный округ" },
  { label: "86 Ханты-Мансийский автономный округ - Югра" },
  { label: "87 Чукотский автономный округ" },
  { label: "89 Ямало-Ненецкий автономный округ" },
  { label: "99 Иные территории, включая город и космодром Байконур" },
]
