import styled, { css } from 'styled-components';
import { gray, lightGray, lighterGray, rr, rl } from 'shared/styled';

export const TableHeaderHeight = '40px';

export const DocumentsTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: ${TableHeaderHeight};
`;

export const Table = styled.table`
  width: inherit;
  height: calc(100% - ${TableHeaderHeight});
  display: block;

  border-collapse: collapse;

  font-family: ${rr};
  font-size: 12px;
  line-height: 1;

  thead,
  tbody,
  tfoot {
    width: inherit;
    padding: 0 20px;
  }

  tr {
    margin: 0 auto;

    display: flex;
    justify-content: flex-start;

    &:not(:last-child) {
      border-bottom: 1px solid ${lightGray};
    }

    td,
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      ${({ sizes }) =>
        sizes.map(
          (size, index) => css`
            &:${`nth-child(${index + 1})`} {
              width: ${size};
            }
          `
        )};
    }
  }
`;

export const TableHead = styled.thead`
  height: ${TableHeaderHeight};
  top: -${TableHeaderHeight};
  position: absolute;

  background-color: ${lighterGray};

  tr {
    width: inherit;
    height: inherit;

    padding: inherit;
    padding: 0;

    display: flex;
    align-items: center;

    font-family: ${rl};
    font-size: 12px;
    color: ${gray};
    th{
      height:20px;
    }
  }
`;

export const TableBody = styled.tbody`
  height: auto;
  max-height: 224px;

  display: block;
  overflow: auto;

  tr {
    padding: 15px 0;
    z-index: 1;
    position: relative;

    td {
      &:first-child {
        color: ${gray};
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }
    }

    &::before {
      content: '';
      width: calc(100% + ${TableHeaderHeight});
      height: 100%;

      top: 0;
      left: 0;
      z-index: -1;
      position: absolute;

      background-color: transparent;
      transform: translateX(-20px);
    }

    &:hover::before {
      background-color: ${lighterGray};
    }
  }
`;
