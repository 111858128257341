import { lighterBlue } from 'shared/styled';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 20px;
  background-color: ${lighterBlue};
  width: 100%;
`;

export const CreditorTypeContainer = styled(Container)`
  max-width: 450px;

  & > fieldset {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    max-height: 100px;
  }

  & > fieldset > :nth-child(5) {
    margin-top: 23px;
  }
`;

export const AccreditationContainer = styled(Container)``;

export const AccreditationStateSupportContainer = styled(Container)``;

export const NameContainer = styled(Container)``;

export const FilterContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }

  button {
    width: 200px;
    height: 30px;
    font-size: 14px;
  }
`;
