import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_DOWNLOAD_DOCUMENT_BY_BANK,
  err
} from 'entities/Cabinet/Bank/model/actions/getDownloadDocument';

function* getDownloadDocuments(action: {
  type: GET_DOWNLOAD_DOCUMENT_BY_BANK;
  guid: string;
  docGuid: string;
}): Generator {
  try {
    const url = `/api/document_list/bank_role/application/${
      action.guid
    }/document/${action.docGuid}/download?type=document`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.docx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDownloadDocumentsSaga(): Generator {
  yield takeLatest(GET_DOWNLOAD_DOCUMENT_BY_BANK.REQ, getDownloadDocuments);
}
