import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from './../actions/deleteBankAccount';

import {
  DELETE_BANK_ACCOUNT,
  RequestDataType
} from './../reducers/deleteBankAccount';
import SCFApi from './../api';
import { req as getBankAccount } from './../actions/getBankAccount';

function* deleteBankAccount(action: {
  type: DELETE_BANK_ACCOUNT;
  data: RequestDataType;
}): Generator {
  try {
    yield call(SCFApi.deleteBankAccount, action.data);
    yield put(got());
    yield put(getBankAccount());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteBankAccountSaga(): Generator {
  yield takeLatest(DELETE_BANK_ACCOUNT.REQ, deleteBankAccount);
}
