import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import { BoxStyled, IconWrapper } from 'shared/styled';

export const UserBlockedError: React.FC = () => (
  <React.Fragment>
    <h1>Пользователь заблокирован</h1>
    <IconWrapper>
      <FontAwesomeIcon icon={faBan} />
    </IconWrapper>
    <BoxStyled>
      <p>
        Пользователь с таким email был заблокирован на платформе FINFACTORY.
        <br />
        Если это произошло по ошибке - обратитесь в техническую поддержку
        <br />
        support@finfactory.one
      </p>
    </BoxStyled>
  </React.Fragment>
);
