import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getDebtorRegistryFiles';

export interface GetDebtorRegistryFilesStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_DEBTOR_REGISTRY_FILES {
  GET_DEBTOR_REGISTRY_FILES_REQ = 'GET_DEBTOR_REGISTRY_FILES_REQ',
  GET_DEBTOR_REGISTRY_FILES_GOT = 'GET_DEBTOR_REGISTRY_FILES_GOT',
  GET_DEBTOR_REGISTRY_FILES_ERR = 'GET_DEBTOR_REGISTRY_FILES_ERR'
}

export const initialState: GetDebtorRegistryFilesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getDebtorRegistryFiles = (
  state: GetDebtorRegistryFilesStoreState = initialState,
  action: {
    type: GET_DEBTOR_REGISTRY_FILES;
    data: ResponseDataType;
    error: {};
  }
): GetDebtorRegistryFilesStoreState => {
  switch (action.type) {
    case GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DEBTOR_REGISTRY_FILES.GET_DEBTOR_REGISTRY_FILES_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDebtorRegistryFiles;
