import { CREATE_DRAFT } from '../reducers/createDraft';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';

export const req = (data: SetInitializationApplicationType) => ({
  type: CREATE_DRAFT.REQ,
  data
});

export const got = () => ({
  type: CREATE_DRAFT.GOT
});

export const err = (error: {}) => ({
  type: CREATE_DRAFT.ERR,
  error
});
