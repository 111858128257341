import * as React from 'react';

import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import {
  req as getMyLimits,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getFactoringLimits';
import { Loader, Pagination } from 'Layouts/components';
import {
  AddBtn,
  AddBtnText,
  InnFilter,
  InnContainer,
  ListSection,
  FullList,
  ActiveContainer,
  InactiveContainer,
  CheckedIcon,
  CrossIcon,
  StatusItem,
  ActiveContainerHovered,
  InactiveContainerHovered,
  HandleContainer
} from './styles';
import {
  PaginationWrapper,
  CreateLimitsNudge,
  PageStyled
} from 'src/features/SCF/UI/ScfStyles';
import './styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'src/features/SCF/api';
import { Href } from 'history';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  myLimits: ResponseDataType;
}

interface DispatchToProps {
  getMyLimits: (data: RequestDataType) => void;
}

interface State {
  pageUrl: Href;
  innToFilter: string;
  limitID: string;
  updateChecker: ResponseDataType;
}

type Props = StateToProps & DispatchToProps;

class LimitsPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        innToFilter: '',
        limitID: '',
        updateChecker: undefined
      };
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleInnToFilterChange = this.handleInnToFilterChange.bind(this);
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handlePageChange(newHref: Href) {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  }

  handleInnToFilterChange(event) {
    this.setState({ innToFilter: event.target.value });
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  handleUpdateCheckerChange = async () => {
    await this.delay(200);
    this.setState({ updateChecker: this.props.myLimits });
  };

  AddLimitsClick = () => {
    history.push('/factor/limits/add');
  };

  onRawClick = (inn: string) => {
    history.push(`/factor/limits/${inn}`);
  };

  initFetch = () => {
    const { getMyLimits } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.innToFilter == ''
        ? getMyLimits({ page, pageSize: 10 })
        : getMyLimits({ page: 1, debtorInn: this.state.innToFilter });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.innToFilter !== prevState.innToFilter ||
      this.state.updateChecker !== prevState.updateChecker
    ) {
      this.initFetch();
    }
  }

  render() {
    const { status, myLimits, error } = this.props;
    return (
      <PageStyled>
        <CreateLimitsNudge>
          <h2>Мои лимиты (факторинг)</h2>
          <AddBtn onClick={this.AddLimitsClick}>
            <AddBtnText>+ Добавить лимит</AddBtnText>
          </AddBtn>
        </CreateLimitsNudge>
        <InnFilter>
          <p>ИНН Дебитора</p>
          <InnContainer>
            <input
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={this.handleInnToFilterChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>
        <ListSection>
          <FullList>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            {status === REQUEST_STATUSES.GOT && (
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="20%">
                      ИНН ДЕБИТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="40%">
                      НАИМЕНОВАНИЕ ДЕБИТОРА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="40%">
                      СТАТУС
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="25%">
                      ОБЪЕМ ЛИМИТА
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="25%">
                      БАЗОВАЯ СТАВКА, БЕЗ НДС
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {myLimits.items.map((item, key) => (
                    <TableRowStyled
                      style={{ backgroungColor: '#FF0000' }}
                      key={key}
                      className="table-row"
                    >
                      {item.isActual == true ? (
                        <td onClick={() => this.onRawClick(item.debtorINN)}>
                          {item.debtorINN}
                        </td>
                      ) : (
                        <td
                          style={{ color: 'rgba(159, 166, 178, 1)' }}
                          onClick={() => this.onRawClick(item.debtorINN)}
                        >
                          {item.debtorINN}
                        </td>
                      )}
                      {item.isActual == true ? (
                        <td
                          style={{ color: 'rgb(63,78,101)' }}
                          onClick={() => this.onRawClick(item.debtorINN)}
                        >
                          {item.debtorShortName}
                        </td>
                      ) : (
                        <td
                          style={{ color: 'rgba(159, 166, 178, 1)' }}
                          onClick={() => this.onRawClick(item.debtorINN)}
                        >
                          {item.debtorShortName}
                        </td>
                      )}
                      <td className="td-status">
                        {item.isActual == true ? (
                          <StatusItem>
                            <ActiveContainer>
                              <CheckedIcon />
                              <p>Доступен</p>
                            </ActiveContainer>
                            <InactiveContainerHovered
                              className="inactive-status"
                              onClick={() =>
                                SCFApi.setFactoringLimitInactive(item.debtorINN)
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <CrossIcon style={{ margin: '0 7px 0 7px' }} />
                              </HandleContainer>
                            </InactiveContainerHovered>
                            <h1 className="inactive-status">Отключить</h1>
                          </StatusItem>
                        ) : (
                          <StatusItem>
                            <InactiveContainer>
                              <CrossIcon />
                              <p>Отключен</p>
                            </InactiveContainer>
                            <ActiveContainerHovered
                              className="active-status"
                              onClick={() =>
                                SCFApi.setFactoringLimitActive(item.debtorINN)
                              }
                            >
                              <HandleContainer
                                onClick={() => this.handleUpdateCheckerChange()}
                              >
                                <CheckedIcon style={{ marginLeft: '5px' }} />
                              </HandleContainer>
                            </ActiveContainerHovered>
                            <h1 className="active-status">Подключить</h1>
                          </StatusItem>
                        )}
                      </td>
                      {item.volume == null && item.isActual == true ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgb(63,78,101)' }}
                        >
                          Неизвестно
                        </td>
                      ) : item.volume != null && item.isActual == true ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgb(63,78,101)' }}
                        >
                          {item.volume}
                        </td>
                      ) : item.volume == null && item.isActual == false ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgba(159, 166, 178, 1)' }}
                        >
                          Неизвестно
                        </td>
                      ) : (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgba(159, 166, 178, 1)' }}
                        >
                          {item.volume} рублей
                        </td>
                      )}

                      {item.rate == null && item.isActual == false ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{
                            color: 'rgba(159, 166, 178, 1)'
                          }}
                        >
                          Неизвестно
                        </td>
                      ) : item.rate == null && item.isActual == true ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgb(63,78,101)' }}
                        >
                          Неизвестно
                        </td>
                      ) : item.rate !== null && item.isActual == true ? (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{ color: 'rgb(63,78,101)' }}
                        >
                          {item.rate.toLocaleString('ru', {
                            maximumFractionDigits: 4,
                            minimumFractionDigits: 4
                          })}{' '}
                          %
                        </td>
                      ) : (
                        <td
                          onClick={() => this.onRawClick(item.debtorINN)}
                          style={{
                            color: 'rgba(159, 166, 178, 1)'
                          }}
                        >
                          {item.rate !== null && item.rate !== undefined
                            ? item.rate.toLocaleString('ru', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 4
                              })
                            : ''}{' '}
                          {item.rate !== null ? '%' : ''}
                        </td>
                      )}
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
            )}
          </FullList>
          <PaginationWrapper onClick={this.handlePageChange}>
            <Pagination list={this.props.myLimits} />
          </PaginationWrapper>
        </ListSection>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  myLimits: SCF.getMyLimits.data,
  status: SCF.getMyLimits.status,
  error: SCF.getMyLimits.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMyLimits
    },
    dispatch
  );

const LimitsPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LimitsPage);

export { LimitsPageConnect as LimitsPage };
