import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFinancialDocuments';

import { GET_FINANCIAL_DOCUMENTS } from '../reducers/getFinancialDocuments';
import DossierApi from '../api';

function* getFinancialDocuments(action: {
  type: GET_FINANCIAL_DOCUMENTS;
  inn: string;
}): Generator {
  try {
    let res: any;

    res = yield call(DossierApi.getFinancialDocuments, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDossierSaga(): Generator {
  yield takeLatest(GET_FINANCIAL_DOCUMENTS.REQ, getFinancialDocuments);
}
