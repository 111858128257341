import styled from 'styled-components';
import { lightBlue } from 'shared/styled';

export const ButtonWrapper = styled.div`
  max-height: 250px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
  column-gap: 100px;
`;

export const AskStyled = styled.h4`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  cursor: ${({ active }) => (active !== null ? 'not-allowed' : 'pointer')};

  :hover {
    border-color: ${({ active }) => active === null && lightBlue};
    color: ${({ active }) => active === null && lightBlue};
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  cursor: ${({ active }) => (active !== null ? 'not-allowed' : 'pointer')};

  :hover {
    border-color: ${({ active }) => active === null && lightBlue};
    color: ${({ active }) => active === null && lightBlue};
  }
`;

export const SectionContainer = styled.div`
  div {
    margin-bottom: 20px;
  }
`;
