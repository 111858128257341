import { ProductsData } from '../actions/setProductFormData';

export interface SetProductDataStoreState extends Partial<ProductsData> {}

export enum SET_PRODUCT_DATA {
  SET = 'SET_PRODUCT_DATA_SET',
  RESET = 'SET_PRODUCT_DATA_RESET'
}

export const initialState: SetProductDataStoreState = {
  // amount: 0,
  // sourceUserId: 0,
};

const setProductFormData = (
  state: SetProductDataStoreState = initialState,
  action: {
    type: string;
    data: ProductsData;
  }
): SetProductDataStoreState => {
  switch (action.type) {
    case SET_PRODUCT_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_PRODUCT_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setProductFormData;
