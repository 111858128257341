import { DELETE_FACTORING_FEES_OF_LIMITS } from 'entities/SCF/Factor/model/reducers/deleteFactoringFeesOfLimits';

export const req = (guid: string) => ({
  type: DELETE_FACTORING_FEES_OF_LIMITS.REQ,
  guid
});

export const got = () => ({
  type: DELETE_FACTORING_FEES_OF_LIMITS.GOT
});

export const err = (error: {}) => ({
  type: DELETE_FACTORING_FEES_OF_LIMITS.ERR,
  error
});
