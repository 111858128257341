import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';

export interface GetDocumentListByApplicationIdStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DOCUMENT_LIST_BY_APPLICATION_ID {
  REQ = 'GET_DOCUMENT_LIST_BY_APPLICATION_ID_REQ',
  GOT = 'GET_DOCUMENT_LIST_BY_APPLICATION_ID_GOT',
  ERR = 'GET_DOCUMENT_LIST_BY_APPLICATION_ID_ERR',
  RESET = 'GET_DOCUMENT_LIST_BY_APPLICATION_ID_RESET'
}

export const initialState: GetDocumentListByApplicationIdStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: null,
    items: []
  },
  error: {}
};

const getDocumentListByApplicationId = (
  state: GetDocumentListByApplicationIdStoreState = initialState,
  action: {
    type: GET_DOCUMENT_LIST_BY_APPLICATION_ID;
    data: ResponseData;
    error: {};
  }
): GetDocumentListByApplicationIdStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_BY_APPLICATION_ID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DOCUMENT_LIST_BY_APPLICATION_ID.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_DOCUMENT_LIST_BY_APPLICATION_ID.ERR:
      return {
        ...initialState,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_DOCUMENT_LIST_BY_APPLICATION_ID.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getDocumentListByApplicationId;
