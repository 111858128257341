import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getCompanyByInn';

export interface GetCompanyByInnStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_COMPANY_BY_INN {
  GET_COMPANY_BY_INN_REQ = 'GET_COMPANY_BY_INN_REQ',
  GET_COMPANY_BY_INN_GOT = 'GET_COMPANY_BY_INN_GOT',
  GET_COMPANY_BY_INN_ERR = 'GET_COMPANY_BY_INN_ERR'
}

export const initialState: GetCompanyByInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: null,
    inn: '',
    companyShortName: '',
    companyFullName: '',
    companyPhone: '',
    companyAddress: '',
    modifiedDateTime: null,
    kpp: '',
    holdingId: null,
    holdingName: '',
    revenue: null,
    netIncome: null,
    capital: null,
    financialsYear: null,
    hasContacts: false,
    segment: '',
    backbone: null,
    inAction: null,
    is_customer223: false,
    is_customer44: false,
    is_target_client: false,
    regDate: null,
    minNum: null,
    maxNum: null,
    opfId: null,
    okvedId: null,
    companyEmail: '',
    pfr: '',
    fss: '',
    startCapital: null,
    kladr: null,
    index: null,
    region: null,
    area: '',
    city: '',
    settlement: '',
    street: '',
    house: '',
    corpus: '',
    apartment: '',
    lastActivity: null,
    lastLogin: null,
    isDeleted: false,
  },
  error: {}
};

const getCompanyByInn = (
  state: GetCompanyByInnStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN;
    data: ResponseDataType;
    error: {};
  }
): GetCompanyByInnStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInn;
