import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
  faMinus
} from '@fortawesome/free-solid-svg-icons';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getUserList,
  UserRead,
  RequestData as UsersListRequestData,
  ResponseData as UsersListResponseData
} from '../../actions/getUserList';
import { req as postAllowProcess } from 'src/features/Users/actions/postAllowProcess';
import { req as postAllowFields } from 'src/features/Users/actions/postAllowFields';
import { ScrollTopComponent } from 'src/features/Common';
import { InnPlaceholders } from 'shared/constants';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { Input } from 'shared/ui/Input';
import { USERS_ROLE_TYPE } from 'src/features/Users/types';
import {
  UserRoleContainerStyled,
  TDEmailStyle,
  CheckboxStyle,
  TooltipBoxStyle,
  CheckboxStyleEmail,
  TDCopyTooltip,
  CopyBlock
} from 'src/features/Users/components/UsersList/styled';

interface StateToProps {
  users: UsersListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusPostAllowProcess: REQUEST_STATUSES;
  statusPostAllowFields: REQUEST_STATUSES;
}

interface DispatchToProps {
  getUserList: (data: UsersListRequestData) => void;
  postAllowProcess: (id: string, typeProcess: string) => void;
  postAllowFields: (id: string, typeProcess: string) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const UserList: React.FC<Props> = ({
  postAllowFields,
  postAllowProcess,
  statusPostAllowFields,
  statusPostAllowProcess,
  getUserList,
  users,
  status,
  error,
  history
}) => {
  const [companyInn, setCompanyInn] = React.useState('');
  const [userRole, setUserRole] = React.useState('Все пользователи');
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      if (
        statusPostAllowFields === REQUEST_STATUSES.GOT ||
        statusPostAllowProcess === REQUEST_STATUSES.GOT
      ) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;
        const companyInn = searchParams.get('companyInn') || '';
        const role = searchParams.get('role') || '';

        getUserList({
          page,
          pageSize: 20,
          ...(role && { role }),
          ...(companyInn && { companyInnToFilterBy: companyInn })
        });
      }
    },
    [statusPostAllowFields, statusPostAllowProcess]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const companyInn = searchParams.get('companyInn') || '';
    const role = searchParams.get('role') || '';

    setCompanyInn(companyInn);

    if (role) {
      setUserRole(role);
    } else {
      setUserRole(USERS_ROLE_TYPE['Все пользователи']);
    }

    getUserList({
      page,
      pageSize: 20,
      ...(role && { role }),
      ...(companyInn && { companyInnToFilterBy: companyInn })
    });
  };

  const onFilterByInn = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set('companyInn', value);
    } else {
      searchParams.delete('companyInn');
    }

    history.push({ search: searchParams.toString() });
  };

  const onRoleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: role } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (role === USERS_ROLE_TYPE['Все пользователи']) {
      searchParams.delete('role');
    } else {
      searchParams.set('role', role);
    }

    history.push({ search: searchParams.toString() });
  };

  const onDebtorClick = (id: number) => {
    history.push(`/crm/users/${id}`);
  };

  const handleCopyClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    text: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleAllowSimpleProccess = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    user: UserRead
  ) => {
    e.stopPropagation();
    user.allowSimpleProcess
      ? postAllowProcess(user.id.toString(), 'disallow')
      : postAllowProcess(user.id.toString(), 'allow');
  };

  const handleAllowBlankField = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    user: UserRead
  ) => {
    e.stopPropagation();
    user.allowBlankFields
      ? postAllowFields(user.id.toString(), 'disallow')
      : postAllowFields(user.id.toString(), 'allow');
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Все пользователи</ApplicationTitle>

      <FiltersStyled>
        <INNFilterContainerStyled>
          <Input
            value={companyInn}
            label="ИНН Компании"
            name="companyInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterByInn}
          />
        </INNFilterContainerStyled>
        <UserRoleContainerStyled>
          <RadioGroup
            name="userRoleType"
            keyValue={userRole}
            onChange={onRoleChange}
            radioBtns={Object.keys(USERS_ROLE_TYPE).map(value => ({
              value,
              label: USERS_ROLE_TYPE[value]
            }))}
          />
        </UserRoleContainerStyled>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="10%">Имя</TableThStyled>
              <TableThStyled width="10%">Телефон</TableThStyled>
              <TableThStyled width="15%">Email</TableThStyled>
              <TableThStyled width="10%">Дата регистрации</TableThStyled>
              <TableThStyled width="10%">Последний вход</TableThStyled>
              <TableThStyled width="10%">Последняя заявка</TableThStyled>
              <TableThStyled width="10%">ИНН компании</TableThStyled>
              <TableThStyled width="10%">Простой процесс</TableThStyled>
              <TableThStyled width="10%">Пустые поля и документы</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {users.items.map(user => (
              <TableRowStyled
                key={user.id}
                onClick={() => onDebtorClick(user.id)}
              >
                <td>{user.id}</td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      user.firstName && !isCopied
                        ? 'Скопировать имя'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e =>
                      handleCopyClick(e, `${user.lastName} ${user.firstName}`)
                    }
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {user.lastName} {user.firstName}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      user.phoneNumber && !isCopied
                        ? 'Скопировать номер'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e => handleCopyClick(e, user.phoneNumber)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {user.phoneNumber}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDEmailStyle>
                  <CopyBlock
                    text={
                      user.email && !isCopied
                        ? 'Скопировать почту'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e => handleCopyClick(e, user.email)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {user.email}
                  </CopyBlock>
                  {user.emailConfirmed ? (
                    <TooltipBoxStyle textVerify={'Email подтвержден'}>
                      <CheckboxStyleEmail verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faCheck} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  ) : (
                    <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                      <CheckboxStyleEmail verify={user.emailConfirmed}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  )}
                </TDEmailStyle>
                <td>
                  {user.createdDateTime &&
                    new Date(user.createdDateTime).toLocaleDateString()}
                </td>
                <td>
                  {user.lastLoginAt &&
                    new Date(user.lastLoginAt).toLocaleDateString()}
                </td>
                <td>
                  {user.lastApplicationAt &&
                    new Date(user.lastApplicationAt).toLocaleDateString()}
                </td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      user.companyInn && !isCopied
                        ? 'Скопировать ИНН'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={e => handleCopyClick(e, user.companyInn)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {user.companyInn}
                  </CopyBlock>
                </TDCopyTooltip>
                <td>
                  {user.allowSimpleProcess}
                  <CheckboxStyle
                    verify={user.allowSimpleProcess}
                    onClick={e => handleAllowSimpleProccess(e, user)}
                  >
                    {user.allowSimpleProcess ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
                <td>
                  {user.allowBlankFields}
                  <CheckboxStyle
                    verify={user.allowBlankFields}
                    onClick={e => handleAllowBlankField(e, user)}
                  >
                    {user.allowBlankFields ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={users} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Users }: CRM) => ({
  users: Users.getUsersList.data,
  status: Users.getUsersList.status,
  error: Users.getUsersList.error,
  statusPostAllowProcess: Users.postAllowProcess.status,
  statusPostAllowFields: Users.postAllowFields.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserList,
      postAllowFields,
      postAllowProcess
    },
    dispatch
  );

const UserListConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserList)
);

export { UserListConnect as UserList };
