import styled from 'styled-components';

import { rr, gray } from 'shared/styled';

export const AttachedDocumentsLabel = styled.h2`
  margin: 20px 0 10px 0;
  font-family: ${rr};
  font-size: 1.2rem;
  font-weight: lighter;
  color: ${gray};
`;
