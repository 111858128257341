import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetApplicationFlagsStoreState extends Reducer {
  data: ResponseDataType;
}

export interface Flags {
  allowSimpleProcess: boolean;
  allowBlankFields: boolean;
}

export type ResponseDataType = Flags;

export enum GET_APPLICATION_FLAGS {
  REQ = 'GET_APPLICATION_FLAGS_REQ',
  GOT = 'GET_APPLICATION_FLAGS_GOT',
  ERR = 'GET_APPLICATION_FLAGS_ERR'
}

export const initialState: GetApplicationFlagsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    allowSimpleProcess: null,
    allowBlankFields: null
  },
  error: {}
};

const getApplicationFlags = (
  state: GetApplicationFlagsStoreState = initialState,
  action: {
    type: GET_APPLICATION_FLAGS;
    data: ResponseDataType;
    error: {};
  }
): GetApplicationFlagsStoreState => {
  switch (action.type) {
    case GET_APPLICATION_FLAGS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_APPLICATION_FLAGS.GOT:

      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_APPLICATION_FLAGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getApplicationFlags;
