import * as React from 'react';

import { Route, Switch } from 'react-router';
import { ContractsList } from './ContractsList/ContractsList';
import { ContractsInfoPage } from './ContractsInfo/ContractsInfo';
import { ContractsCreate } from './ContractsCreate/ContractsCreate';

import { ContractsPageStyled } from './styles';

class FactorContractsPage extends React.Component {
  render() {
    return (
      <ContractsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={'/factor/contracts'}
            render={() => <ContractsList />}
          />
          <Route
            exact={true}
            path={'/factor/contracts/add'}
            render={() => <ContractsCreate />}
          />
          <Route
            exact={true}
            path={'/factor/contracts/:guid'}
            render={() => <ContractsInfoPage />}
          />
        </Switch>
      </ContractsPageStyled>
    );
  }
}

export default FactorContractsPage;
