import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/deleteProperty';
import { deleteLocalProperty } from '../actions/localProperties';
import PropertiesApi from '../api';

import {
  DELETE_PROPERTY,
} from '../reducers/deleteProperty';

function* deleteProperty(action: {
  type: DELETE_PROPERTY;
  propertyId:number;
}): Generator {
  try {

    yield call(PropertiesApi.deleteProperty,action.propertyId);
    yield put(deleteLocalProperty(action.propertyId));
    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* deletePropertySaga(): Generator {
  yield takeLatest(DELETE_PROPERTY.REQ, deleteProperty);
}
