import { POST_EXTERNAL_API_PSB_APPLICATION } from '../reducers/postExternalApiPSB';

export const req = (ApplicationID: number) => ({
  type: POST_EXTERNAL_API_PSB_APPLICATION.REQ,
  ApplicationID
});

export const got = () => ({
  type: POST_EXTERNAL_API_PSB_APPLICATION.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_API_PSB_APPLICATION.ERR,
  error
});
