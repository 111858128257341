export interface StatusFilterData {
  status: string[];
}

export enum STATUS_FILTER_ACTIONS {
  SET = 'SET_STATUS_FILTER',
  REMOVE = 'REMOVE_STATUS_FILTER'
}

export const initialState: StatusFilterData = {
  status: []
};

const updateTypeFilter = (
  state: StatusFilterData = initialState,
  action: {
    type: STATUS_FILTER_ACTIONS;
    status: string;
    index: number;
  }
): StatusFilterData => {
  switch (action.type) {
    case STATUS_FILTER_ACTIONS.SET:
      const combined = [...state.status, action.status];
      return {
        ...state,
        status: combined
      };
    case STATUS_FILTER_ACTIONS.REMOVE:
      const filtered = state.status.filter(
        (value, index) => index !== action.index
      );
      return {
        ...initialState,
        status: filtered
      };
    default:
      return state;
  }
};

export default updateTypeFilter;
