import * as React from 'react';
import { SupplierCabinetStyled, MultiPageStyled } from './styles';
import BarSection from './BarSection/BarSection';
import SettingsPage from '../../../../features/SCF/components/Settings/Settings';
import { Route, Switch } from 'react-router';
import { LimitsPage } from './Limits/Limits';
import { LimitInfo } from './Limits/components/LimitInfo/LimitInfo';
import { LimitInfoInactive } from './Limits/components/LimitInfo/LimitInfoInactive';
import { LimitsAddPage } from './Limits/components/LimitsAdd/LimitsAdd';
import FactorConnectionsPage from './Connections/Connections';
import { RegistriesList } from './RegistriesList/RegistriesList';
import FactorContractsPage from './Contracts/Contracts';
import { LimitSettingsEdit } from './Limits/components/LimitInfo/LimitSettingsEdit';
import { KnowYourCustomerPage } from './KnowYourCustomer/KnowYourCustomer';
import FactoringApplicationsPage from './FactoringApplications/FactoringApplications';
import MainPage from './MainPage/MainPage';

interface Props {
  type: string | JSX.Element;
}

const FactorCabinet: React.FC<Props> = ({ type }) => (
  <SupplierCabinetStyled>
    <BarSection type={''} />
    <Switch>
      <Route exact path={'/factor'} render={() => <MainPage />} />
      <Route path={'/factor/settings'} render={() => <SettingsPage />} />

      <Route
        exact={true}
        path={'/factor/limits'}
        render={() => <LimitsPage />}
      />

      <Route
        exact
        path={'/factor/limits/add'}
        render={() => <LimitsAddPage />}
      />
      <Route
        exact={true}
        path={'/factor/limits/:id'}
        render={() => <LimitInfo />}
      />
      <Route
        exact={true}
        path={'/factor/limits/:id/edit'}
        render={() => (
          <MultiPageStyled>
            <LimitInfoInactive />
            <LimitSettingsEdit />
          </MultiPageStyled>
        )}
      />
      <Route
        path={'/factor/connections'}
        render={() => <FactorConnectionsPage />}
      />
      <Route
        path={'/factor/contracts'}
        render={() => <FactorContractsPage />}
      />
      <Route
        exact={true}
        path={'/factor/kyc'}
        render={() => <KnowYourCustomerPage />}
      />
      <Route path={'/factor/registries'} render={() => <RegistriesList />} />
      <Route
        path={'/factor/factoring_applications'}
        render={() => <FactoringApplicationsPage />}
      />
    </Switch>
  </SupplierCabinetStyled>
);

export { FactorCabinet };
