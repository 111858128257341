import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/InternalAgent/model/actions/getCertificates';

export interface GetCertificatesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_CERTIFICATES {
  REQ = 'GET_CERTIFICATES_REQ',
  GOT = 'GET_CERTIFICATES_GOT',
  ERR = 'GET_CERTIFICATES_ERR'
}

const initialState: GetCertificatesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: null,
    totalItems: null,
    items: []
  },
  error: {}
};

const getCertificates = (
  state: GetCertificatesStoreState = initialState,
  action: {
    type: GET_CERTIFICATES;
    data: ResponseData;
    error: {};
  }
): GetCertificatesStoreState => {
  switch (action.type) {
    case GET_CERTIFICATES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CERTIFICATES.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_CERTIFICATES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCertificates;
