import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getTenders';

import { GET_TENDERS } from '../reducers/getTenders';
import TendersApi from '../api';

function* getTenders(action: { type: GET_TENDERS; data }): Generator {
  try {
    const res: any = yield call(TendersApi.getTenders, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getTendersSaga(): Generator {
  yield takeLatest(GET_TENDERS.REQ, getTenders);
}
