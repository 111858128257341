import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/putEarlyPaymentApplicationDecline';
import {
  PUT_EARLY_PAYMENT_APPLICATION_DECLINE,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';

import SCFDebtorApi from 'entities/SCF/Debtor/api';
import { STORE } from 'src/globaltypes';

function* handleRequest(guid: string, rejectReason: string) {
  try {
    yield call(SCFDebtorApi.putEarlyPaymentApplicationDecline, {
      guid,
      body: { rejectReason }
    });

    const currentResults: { success: boolean }[] = yield select(
      ({ SCFDebtor }: STORE) => SCFDebtor.putEarlyPaymentApplicationDecline.data
    );

    yield put(got([...currentResults, { success: true }]));
  } catch (e) {
    console.log(e);
    const currentResults: { success: boolean }[] = yield select(
      ({ SCFDebtor }: STORE) => SCFDebtor.putEarlyPaymentApplicationDecline.data
    );
    yield put(got([...currentResults, { success: false }]));
  }
}

function* putEarlyPaymentApplicationDecline(action: {
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE;
  data: RequestDataType;
  reqType?: 'array' | null;
}) {
  try {
    if (action.reqType === 'array' && Array.isArray(action.data.guid)) {
      for (const guid of action.data.guid) {
        yield call(handleRequest, guid, action.data.body.rejectReason);
      }
    } else {
      yield call(SCFDebtorApi.putEarlyPaymentApplicationDecline, action.data);
      yield put(got());
    }
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putEarlyPaymentApplicationDeclineSaga() {
  yield takeLatest(
    PUT_EARLY_PAYMENT_APPLICATION_DECLINE.REQ,
    putEarlyPaymentApplicationDecline
  );
}
