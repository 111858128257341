import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/LimitsForBank/actions/getLimits';

export interface GetLimitsStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_LIMITS_BANK {
  REQ = 'GET_LIMITS_BANK_REQ',
  GOT = 'GET_LIMITS_BANK_GOT',
  ERR = 'GET_LIMITS_BANK_ERR'
}

export const initialState: GetLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getLimitsBank = (
  state: GetLimitsStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetLimitsStoreState => {
  switch (action.type) {
    case GET_LIMITS_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_LIMITS_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_LIMITS_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getLimitsBank;
