import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getUsersForBankPage';

export interface GetUserListForBankPageStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_USER_LIST_FOR_BANK {
  REQ = 'GET_USER_LIST_FOR_BANK_REQ',
  GOT = 'GET_USER_LIST_FOR_BANK_GOT',
  ERR = 'GET_USER_LIST_FOR_BANK_ERR'
}

export const initialState: GetUserListForBankPageStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getUsersListForBankPage = (
  state: GetUserListForBankPageStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetUserListForBankPageStoreState => {
  switch (action.type) {
    case GET_USER_LIST_FOR_BANK.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_USER_LIST_FOR_BANK.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_USER_LIST_FOR_BANK.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getUsersListForBankPage;
