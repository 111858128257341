import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/SCF/Debtor/model/actions/postConfirmContractByDebtor';

import { POST_CONFIRM_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/postConfirmContractByDebtor';

import SCFApi from 'entities/SCF/Debtor/api';

function* postConfirmContractByDebtor(action: {
  type: POST_CONFIRM_CONTRACT_BY_DEBTOR;
  accountGuid: string;
  contractGuid: string;
  data: RequestData;
}) {
  try {
    const res: any = yield call(
      SCFApi.postConfirmContractByDebtor,
      action.accountGuid,
      action.contractGuid,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postConfirmContractByDebtorSaga() {
  yield takeLatest(
    POST_CONFIRM_CONTRACT_BY_DEBTOR.REQ,
    postConfirmContractByDebtor
  );
}
