import { PropertyRead } from "../types/Property";

export type LocalPropertiesData = {
  properties: Partial<PropertyRead>[]
};

export enum LOCAL_PROPERTIES_ACTIONS {
  ADD = 'ADD_LOCAL_PROPERTIES',
  DELETE = 'DELETE_LOCAL_PROPERTIES',
  UPDATE = 'UPDATE_LOCAL_PROPERTIES',
  SET = 'SET_LOCAL_PROPERTIES',
  RESET = 'RESET_LOCAL_PROPERTIES'
}

export const initialState: LocalPropertiesData = {
  properties: []
};

const localProperties = (
  state: LocalPropertiesData = initialState,
  action: {
    type: LOCAL_PROPERTIES_ACTIONS;
    property?: Partial<PropertyRead>;
    properties?: Partial<PropertyRead>[];
    propertyId?: number;
  }
): LocalPropertiesData => {
  switch (action.type) {
    case LOCAL_PROPERTIES_ACTIONS.ADD:
      return {
        ...state,
        properties: [...state.properties, action.property]
      };
    case LOCAL_PROPERTIES_ACTIONS.UPDATE:
      const oldProperty = state.properties.find(p => p.id === action.property.id);
      const newProperty = { ...oldProperty, ...action.property };
      return {
        ...state,
        properties: state.properties.map((property) => property.id === action.property.id ? newProperty : property)
      };
    case LOCAL_PROPERTIES_ACTIONS.DELETE:
      return {
        ...state,
        properties: state.properties.filter(p => p.id !== action.propertyId)
      };
    case LOCAL_PROPERTIES_ACTIONS.SET:
      return {
        ...state,
        properties: action.properties
      };
    case LOCAL_PROPERTIES_ACTIONS.RESET:
      return {...initialState };
    default:
      return state;
  }
};

export default localProperties;
