import { CompanyInfoData } from '../actions/setCompanyInfo';

export interface SetCompanyInfoDataStoreState
  extends Partial<CompanyInfoData> {}

export enum SET_COMPANY_INFO_DATA {
  SET = 'SET_COMPANY_INFO_DATA_SET',
  RESET = 'SET_COMPANY_INFO_DATA_RESET'
}

export const initialState: SetCompanyInfoDataStoreState = {
  id: 0,
  inn: null,
  companyShortName: null,
  companyFullName: null,
  companyPhone: null,
  companyAddress: null,
  holdingId: 0,
  holdingName: null,
  revenue: 0,
  netIncome: 0,
  capital: 0,
  financialsYear: 0,
  hasContacts: true,
  segment: null,
  backbone: 0,
  inAction: 0,
  kpp: null,
  is_customer223: true,
  is_customer44: true,
  is_target_client: true,
  isDeleted: true,
  regDate: new Date(),
  minNum: 0,
  maxNum: 0,
  opfId: 0,
  okvedId: 0,
  companyEmail: null,
  pfr: null,
  fss: null,
  startCapital: 0,
  kladr: 0,
  index: 0,
  region: 0,
  area: null,
  city: null,
  settlement: null,
  street: null,
  house: null,
  corpus: null,
  apartment: null,
  lastActivity: new Date(),
  lastLogin: new Date(),
  companyNameEng: null
};

const setCompanyInfoData = (
  state: SetCompanyInfoDataStoreState = initialState,
  action: {
    type: string;
    data: CompanyInfoData;
  }
): SetCompanyInfoDataStoreState => {
  switch (action.type) {
    case SET_COMPANY_INFO_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_COMPANY_INFO_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setCompanyInfoData;
