import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { InfoHover, HeaderWithHover } from '../DiscountingInfo/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CreateDiscountingSettingsPageStyled,
  ContractsHeader,
  ContractsInfoArea,
  ContractsInfoItemTitle,
  ContractsInfoItemContainer,
  TypeContainer,
  TypeNoActive,
  TypeYesInactive,
  TypeYesActive,
  TypeNoInactive,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  ContractsContainer,
  SignBtn,
  SignBtnText,
  BackBtn,
  BackBtnText,
  SelectContainerInactiveNotAllowed
} from '../DiscountingEdit/styles';
import {
  CertificatesArea,
  CertificatesPopupDisplay,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { Loader } from 'src/features/Layouts/components';
import {
  DiscountingSettings,
  setDiscountingSettings
} from 'src/features/SCF/actions/setDiscountingSettings';
import {
  req as sendDiscountingSettings,
  RequestData as SendDiscountingSettings,
  ResponseGuid
} from 'src/features/SCF/actions/sendDiscountingSettings';
import {
  req as getDiscountingSettings,
  ResponseDataType
} from 'src/features/SCF/actions/getDiscountingSettings';
import { bindActionCreators, Dispatch } from 'redux';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { SetDiscountingSettingsStoreState } from 'src/features/SCF/reducers/setDiscountingSettings';
import './styles/style.css';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';

interface StateToProps extends Partial<DiscountingSettings> {
  isSendingData: boolean;
  discountingSettings: ResponseDataType;
  status: REQUEST_STATUSES;
  responseGuidStatus: REQUEST_STATUSES;
  responseGuidError: ResponseError;
  error: ResponseError;
  responseGuid: ResponseGuid;
}

interface DispatchToProps {
  sendDiscountingSettings: (data: SendDiscountingSettings) => void;
  setDiscountingSettings: (data: SetDiscountingSettingsStoreState) => void;
  getDiscountingSettings: () => void;
}

interface State {
  enableFactroingState: boolean;
  factoringOfferPolicyState: number;
  updateChecker: number;
  verificationPolicyState: number;
  factoringFormatPolicyState: number;
  factoringNotificationPolicyState: number;
}

type Props = StateToProps & DispatchToProps;

class FactoringEdit extends React.Component<Props, State> {
  initFetch = () => {
    const { getDiscountingSettings } = this.props;
    getDiscountingSettings();
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
  }

  constructor(props) {
    super(props);
    {
      this.state = {
        enableFactroingState: this.props.discountingSettings.enableFactoring,
        factoringOfferPolicyState: this.props.discountingSettings
          .factoringOfferPolicy,
        verificationPolicyState: this.props.discountingSettings
          .verificationPolicy,
        factoringFormatPolicyState: this.props.discountingSettings
          .factoringFormatPolicy,
        factoringNotificationPolicyState: this.props.discountingSettings
          .factoringNotificationPolicy,
        updateChecker: 0
      };
      this.handleEnableFactroingChangeTrue = this.handleEnableFactroingChangeTrue.bind(
        this
      );
      this.handleEnableFactroingChangeFalse = this.handleEnableFactroingChangeFalse.bind(
        this
      );
      this.handleFactoringOfferPolicyOne = this.handleFactoringOfferPolicyOne.bind(
        this
      );
      this.handleFactoringOfferPolicyTwo = this.handleFactoringOfferPolicyTwo.bind(
        this
      );
      this.handleVerificationPolicyOne = this.handleVerificationPolicyOne.bind(
        this
      );
      this.handleVerificationPolicyTwo = this.handleVerificationPolicyTwo.bind(
        this
      );
      this.handleVerificationPolicyThree = this.handleVerificationPolicyThree.bind(
        this
      );
      this.handleVerificationPolicyFour = this.handleVerificationPolicyFour.bind(
        this
      );
      this.handleVerificationPolicyFive = this.handleVerificationPolicyFive.bind(
        this
      );
      this.handleFactoringFormatPolicyOne = this.handleFactoringFormatPolicyOne.bind(
        this
      );
      this.handleFactoringFormatPolicyTwo = this.handleFactoringFormatPolicyTwo.bind(
        this
      );
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
      this.handleFactoringNotificationPolicyOne = this.handleFactoringNotificationPolicyOne.bind(
        this
      );
      this.handleFactoringNotificationPolicyTwo = this.handleFactoringNotificationPolicyTwo.bind(
        this
      );
      this.handleFactoringNotificationPolicyThree = this.handleFactoringNotificationPolicyThree.bind(
        this
      );
    }
  }

  handleEnableFactroingChangeTrue(event) {
    this.setState({ enableFactroingState: true });
  }

  handleEnableFactroingChangeFalse(event) {
    this.setState({ enableFactroingState: false });
  }

  handleFactoringOfferPolicyOne(event) {
    this.setState({ factoringOfferPolicyState: 1 });
  }

  handleFactoringOfferPolicyTwo(event) {
    this.setState({ factoringOfferPolicyState: 2 });
  }

  handleVerificationPolicyOne(event) {
    this.setState({ verificationPolicyState: 1 });
  }

  handleVerificationPolicyTwo(event) {
    this.setState({ verificationPolicyState: 2 });
  }

  handleVerificationPolicyThree(event) {
    this.setState({ verificationPolicyState: 3 });
  }

  handleVerificationPolicyFour(event) {
    this.setState({ verificationPolicyState: 4 });
  }

  handleVerificationPolicyFive(event) {
    this.setState({ verificationPolicyState: 5 });
  }

  handleFactoringFormatPolicyOne(event) {
    this.setState({ factoringFormatPolicyState: 1 });
  }

  handleFactoringFormatPolicyTwo(event) {
    this.setState({ factoringFormatPolicyState: 2 });
  }

  handleFactoringNotificationPolicyOne(event) {
    this.setState({ factoringNotificationPolicyState: 1 });
  }

  handleFactoringNotificationPolicyTwo(event) {
    this.setState({ factoringNotificationPolicyState: 2 });
  }

  handleFactoringNotificationPolicyThree(event) {
    this.setState({ factoringNotificationPolicyState: 3 });
  }

  replaceCharacter(str, index, replacement) {
    return (
      str.slice(0, index) + replacement + str.slice(index + replacement.length)
    );
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.props.setDiscountingSettings({
      [name]: value
    });
  };

  handleUpdateCheckerChange = async () => {
    await this.delay(400);
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  };

  onBackClick = () => {
    history.push(`/debtor/settings/factoring`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  onCloseIconClickSub = () => {
    const sign = document.getElementById('certificatesSubPopup');
    sign.style.display = 'none';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    history.push({
      pathname: `/debtor/settings/certificates/edit`
    });
  };

  onSignClick = () => {
    this.props.sendDiscountingSettings({
      rateIsSurplusToKeyRate: this.props.discountingSettings
        .rateIsSurplusToKeyRate
        ? this.props.discountingSettings.rateIsSurplusToKeyRate
        : null,
      enableDiscounting: this.props.discountingSettings.enableDiscounting
        ? this.props.discountingSettings.enableDiscounting
        : null,
      enableFactroing: this.state.enableFactroingState
        ? this.state.enableFactroingState
        : null,
      discountLimitPolicy: this.props.discountingSettings.discountLimitPolicy
        ? this.props.discountingSettings.discountLimitPolicy
        : null,
      manualMaxLimitSize: this.props.discountingSettings.manualMaxLimitSize
        ? this.props.discountingSettings.manualMaxLimitSize
        : null,
      autoLimitPolicy: this.props.discountingSettings.autoLimitPolicy
        ? this.props.discountingSettings.autoLimitPolicy
        : null,
      fixedSubstractionSum: this.props.discountingSettings.fixedSubstractionSum
        ? this.props.discountingSettings.fixedSubstractionSum
        : null,
      substractionInPeriodDays: this.props.discountingSettings
        .substractionPeriodInDays
        ? this.props.discountingSettings.substractionPeriodInDays
        : null,
      discountRatePolicy: this.props.discountingSettings.discountRatePolicy
        ? this.props.discountingSettings.discountRatePolicy
        : null,
      discountRate: this.props.discountingSettings.discountRate
        ? this.props.discountingSettings.discountRate
        : null,
      autoRateSubstraction: this.props.discountingSettings.autoRateSubstraction
        ? this.props.discountingSettings.autoRateSubstraction
        : null,
      factoringOfferPolicy: this.state.factoringOfferPolicyState
        ? this.state.factoringOfferPolicyState
        : null,
      minimalDiscount: this.props.discountingSettings.minimalDiscount
        ? this.props.discountingSettings.minimalDiscount
        : null,
      payWeekdays: this.props.discountingSettings.paydays
        ? this.props.discountingSettings.paydays
        : '0000000',
      paydayOffset: this.props.discountingSettings.paydayOffset
        ? this.props.discountingSettings.paydayOffset
        : null,
      suppliesDataSourcePolicy: this.props.discountingSettings
        .suppliesDataSourcePolicy
        ? this.props.discountingSettings.suppliesDataSourcePolicy
        : null,
      verificationPolicy: this.state.verificationPolicyState
        ? this.state.verificationPolicyState
        : 5,
      epaMcPolicy: this.props.discountingSettings.epaMcPolicy
        ? this.props.discountingSettings.epaMcPolicy
        : null,
      factoringFormatPolicy: this.state.factoringFormatPolicyState
        ? this.state.factoringFormatPolicyState
        : null,
      factoringNotificationPolicy: this.state.factoringNotificationPolicyState
        ? this.state.factoringNotificationPolicyState
        : null,
      holidayPaydays: this.props.discountingSettings.holidayPaydays
        ? this.props.discountingSettings.holidayPaydays
        : null
    });
  };

  render() {
    const {
      status,
      discountingSettings,
      responseGuid,
      responseGuidStatus
    } = this.props;
    return (
      <CreateDiscountingSettingsPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        <ContractsContainer>
          <ContractsHeader>
            <h2>Политика дисконтирования</h2>
            <SignBtn
              style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}
            >
              <SignBtnText>Редактируется</SignBtnText>
            </SignBtn>
            <SignBtn onClick={() => this.onSignClick()}>
              <SignBtnText>Подписать ЭЦП</SignBtnText>
            </SignBtn>
            <BackBtn onClick={() => this.onBackClick()}>
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
          </ContractsHeader>
          <ContractsInfoArea style={{ marginTop: '40px' }}>
            <ContractsInfoItemContainer>
              <ContractsInfoItemTitle>Факторинг:</ContractsInfoItemTitle>
              {this.state.enableFactroingState == true ? (
                <TypeContainer>
                  <TypeNoInactive
                    onClick={this.handleEnableFactroingChangeFalse}
                  >
                    <p>ВЫКЛ</p>
                  </TypeNoInactive>
                  <TypeYesActive onClick={this.handleEnableFactroingChangeTrue}>
                    <p>ВКЛ</p>
                  </TypeYesActive>
                </TypeContainer>
              ) : (
                <TypeContainer>
                  <TypeNoActive onClick={this.handleEnableFactroingChangeFalse}>
                    <p>ВЫКЛ</p>
                  </TypeNoActive>
                  <TypeYesInactive
                    onClick={this.handleEnableFactroingChangeTrue}
                  >
                    <p>ВКЛ</p>
                  </TypeYesInactive>
                </TypeContainer>
              )}
            </ContractsInfoItemContainer>
          </ContractsInfoArea>
          {this.state.enableFactroingState == true ? (
            <ContractsInfoArea>
              <h2>Предложения банков:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {this.state.factoringOfferPolicyState == 1 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringOfferPolicyOne}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringOfferPolicyOne}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Показывать поставщикам всегда</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.factoringOfferPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringOfferPolicyTwo}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringOfferPolicyTwo}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>
                  Показывать поставщикам при отсутствии лимита дисконтирования
                </p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {this.state.enableFactroingState == true ? (
            <ContractsInfoArea>
              <h2>Формат работы по факторингу:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {this.state.factoringFormatPolicyState == 1 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringFormatPolicyOne}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringFormatPolicyOne}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Биржа</p>
                <InfoHover>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="rgba(0, 133, 254, 1)"
                    size="lg"
                  />
                  <div style={{ paddingLeft: '20px' }}>
                    <p>
                      Поставщик может выбирать разных факторов для разных
                      поставок
                    </p>
                  </div>
                </InfoHover>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.factoringFormatPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringFormatPolicyTwo}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringFormatPolicyTwo}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Постоянный фактор</p>
                <InfoHover>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="rgba(0, 133, 254, 1)"
                    size="lg"
                  />
                  <div style={{ paddingLeft: '20px' }}>
                    <p>
                      Заключив договор с фактором, Поставщик не сможет выбрать
                      другого фактора, пока не расторгнет договор с предыдущим
                      фактором
                    </p>
                  </div>
                </InfoHover>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {this.state.enableFactroingState == true ? (
            <ContractsInfoArea>
              <HeaderWithHover>
                <h1>Подписание уведомлений:</h1>
                <InfoHover style={{ marginLeft: '20px' }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="rgba(0, 133, 254, 1)"
                    size="lg"
                  />
                  <div>
                    <p>
                      Трехсторонние уведомления между Поставщиком, Покупателем и
                      Фактором об уступке прав требования
                    </p>
                  </div>
                </InfoHover>
              </HeaderWithHover>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {this.state.factoringNotificationPolicyState == 1 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringNotificationPolicyOne}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringNotificationPolicyOne}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>По форме фактора</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.factoringNotificationPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringNotificationPolicyTwo}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringNotificationPolicyTwo}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>По моей форме</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.factoringNotificationPolicyState == 3 ? (
                  <SelectContainerActive
                    onClick={this.handleFactoringNotificationPolicyThree}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleFactoringNotificationPolicyThree}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Не подписываю</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}
          {this.state.enableFactroingState == true ? (
            <ContractsInfoArea>
              <h2>Подписание уведомлений:</h2>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {this.state.verificationPolicyState == 4 ? (
                  <SelectContainerActive
                    onClick={this.handleVerificationPolicyFour}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleVerificationPolicyFour}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Автоматически по поставщикам</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {/* {this.state.verificationPolicyState == 3 ? (
                  <SelectContainerActive
                    onClick={this.handleVerificationPolicyThree}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleVerificationPolicyThree}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )} */}
                <SelectContainerInactiveNotAllowed>
                  <SelectInactive />
                </SelectContainerInactiveNotAllowed>
                <p>Вручную по реестру</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {/* {this.state.verificationPolicyState == 2 ? (
                  <SelectContainerActive
                    onClick={this.handleVerificationPolicyTwo}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleVerificationPolicyTwo}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )} */}
                <SelectContainerInactiveNotAllowed>
                  <SelectInactive />
                </SelectContainerInactiveNotAllowed>
                <p>Вручную по поставщику</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {/* {this.state.verificationPolicyState == 1 ? (
                  <SelectContainerActive
                    onClick={this.handleVerificationPolicyOne}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleVerificationPolicyOne}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )} */}
                <SelectContainerInactiveNotAllowed>
                  <SelectInactive />
                </SelectContainerInactiveNotAllowed>
                <p>Вручную по поставке</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '10px' }}>
                {this.state.verificationPolicyState == 5 ? (
                  <SelectContainerActive
                    onClick={this.handleVerificationPolicyFive}
                  >
                    <SelectActive />
                  </SelectContainerActive>
                ) : (
                  <SelectContainerInactive
                    onClick={this.handleVerificationPolicyFive}
                  >
                    <SelectInactive />
                  </SelectContainerInactive>
                )}
                <p>Выключена</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
          ) : (
            ''
          )}

          {responseGuidStatus == REQUEST_STATUSES.GOT && (
            <CertificatesPopupDisplay id="certificatesPopup">
              <PopupExit>
                <p onClick={this.onCloseIconClick}>X</p>
              </PopupExit>
              <h1>Список сертификатов в системе</h1>
              <CertificatesArea>
                <CertificatesListPopup
                  guid={responseGuid.guid}
                  request={`{"enableDiscounting":"${
                    this.props.discountingSettings.enableDiscounting
                      ? this.props.discountingSettings.enableDiscounting
                      : null
                  }",
                  "enableFactroing": "${
                    this.state.enableFactroingState
                      ? this.state.enableFactroingState
                      : null
                  }",
                  "discountLimitPolicy": "${
                    this.props.discountingSettings.discountLimitPolicy
                      ? this.props.discountingSettings.discountLimitPolicy
                      : null
                  }",
                  "manualMaxLimitSize": "${
                    this.props.discountingSettings.manualMaxLimitSize
                      ? this.props.discountingSettings.manualMaxLimitSize
                      : null
                  }",
                  "autoLimitPolicy": "${
                    this.props.discountingSettings.autoLimitPolicy
                      ? this.props.discountingSettings.autoLimitPolicy
                      : null
                  }",
                  "fixedSubstractionSum": "${
                    this.props.discountingSettings.fixedSubstractionSum
                      ? this.props.discountingSettings.fixedSubstractionSum
                      : null
                  }",
                  "substractionInPeriodDays": "${
                    this.props.discountingSettings.substractionPeriodInDays
                      ? this.props.discountingSettings.substractionPeriodInDays
                      : null
                  }",
                  "discountRatePolicy": "${
                    this.props.discountingSettings.discountRatePolicy
                      ? this.props.discountingSettings.discountRatePolicy
                      : null
                  }",
                  "discountRate": "${
                    this.props.discountingSettings.discountRate
                      ? this.props.discountingSettings.discountRate
                      : null
                  }",
                  "autoRateSubstraction": "${
                    this.props.discountingSettings.autoRateSubstraction
                      ? this.props.discountingSettings.autoRateSubstraction
                      : null
                  }",
                  "factoringOfferPolicy": "${
                    this.state.factoringOfferPolicyState
                      ? this.state.factoringOfferPolicyState
                      : null
                  }",
                  "minimalDiscount": "${
                    this.props.discountingSettings.minimalDiscount
                      ? this.props.discountingSettings.minimalDiscount
                      : null
                  }",
                  "payWeekdays": "${
                    this.props.discountingSettings.paydays
                      ? this.props.discountingSettings.paydays
                      : '0000000'
                  }",
                  "suppliesDataSourcePolicy": "${
                    this.props.discountingSettings.suppliesDataSourcePolicy
                      ? this.props.discountingSettings.suppliesDataSourcePolicy
                      : null
                  }",
                  "verificationPolicy": "${
                    this.state.verificationPolicyState
                      ? this.state.verificationPolicyState
                      : 5
                  }",
                  "epaMcPolicy": "${
                    this.props.discountingSettings.epaMcPolicy
                      ? this.props.discountingSettings.epaMcPolicy
                      : null
                  }",
                  "factoringFormatPolicy": "${
                    this.state.factoringFormatPolicyState
                      ? this.state.factoringFormatPolicyState
                      : null
                  }",
                  "factoringNotificationPolicy": "${
                    this.state.factoringNotificationPolicyState
                      ? this.state.factoringNotificationPolicyState
                      : null
                  }"
                  "holidayPaydays": "${
                    this.props.discountingSettings.holidayPaydays
                      ? this.props.discountingSettings.holidayPaydays
                      : null
                  }"
                }`}
                />
              </CertificatesArea>
              <NudgeArea>
                <p>Нет нужного сертификата? </p>
                <Nudge onClick={this.onNudgeClick}>
                  Перейдите в настройки добаления
                </Nudge>
              </NudgeArea>
            </CertificatesPopupDisplay>
          )}

          <CertificatesPopupError id="certificatesPopupError">
            <PopupExitError>
              <p onClick={this.onCloseErrorIconClick}>X</p>
            </PopupExitError>
            <h1>
              Невозможно подписать выбранным сертификатом
              <br />
              (не найден на устройстве)
            </h1>
          </CertificatesPopupError>
        </ContractsContainer>
      </CreateDiscountingSettingsPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  isSendingData:
    SCF.sendDiscountingSettings.status === REQUEST_STATUSES.REQUEST,
  discountingSettings: SCF.getDiscountingSettings.data,
  status: SCF.getDiscountingSettings.status,
  error: SCF.getDiscountingSettings.error,
  responseGuid: SCF.sendDiscountingSettings.data,
  responseGuidStatus: SCF.sendDiscountingSettings.status,
  responseGuidError: SCF.sendDiscountingSettings.error,
  ...SCF.setDiscountingSettings
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendDiscountingSettings,
      setDiscountingSettings,
      getDiscountingSettings
    },
    dispatch
  );

const FactoringEditConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FactoringEdit);

export { FactoringEditConnect as FactoringEdit };
