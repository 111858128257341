import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/FinancialForCompany/actions/getFinancialQuality';

export interface GetFinancialQualityStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_FINANCIAL_QUALITY {
  REQ = 'GET_FINANCIAL_QUALITY_REQ',
  GOT = 'GET_FINANCIAL_QUALITY_GOT',
  ERR = 'GET_FINANCIAL_QUALITY_ERR'
}

export const initialState: GetFinancialQualityStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getFinancialQuality = (
  state: GetFinancialQualityStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetFinancialQualityStoreState => {
  switch (action.type) {
    case GET_FINANCIAL_QUALITY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINANCIAL_QUALITY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FINANCIAL_QUALITY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFinancialQuality;
