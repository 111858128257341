import * as React from 'react';
import { connect } from 'react-redux';

import {
  ApplicationViewConsumer,
  EachApplicationView,
  EachApplicationProps
} from '../..';

import { PropertyFinancingRead } from 'src/features/Application/types';
import {
  ApplicationTypes,
  PropertyFinancingClientType,
  PropertyFinancingClientIncomeConfirmationType
} from 'shared/constants';
import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { ViewProperties } from 'src/features/Properties/components/ViewProperties/ViewProperties';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';

import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';

interface StateToProps {
  inBankApplication: GetApplicationResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
}

type Props = StateToProps;

const PropertyFinancingView: React.FC<Props> = ({
  inBankApplication,
  statusInBankApplication
}) => {
  if (statusInBankApplication !== REQUEST_STATUSES.GOT) {
    return <Loader />;
  }

  return (
    <ApplicationViewConsumer>
      {({ data }: EachApplicationProps<PropertyFinancingRead>) => {
        return (
          <EachApplicationView
            mainInfo={[
              {
                name: 'Тип финансирования',
                value: ApplicationTypes[data.financingType]
              },
              {
                name: 'Сумма сделки',
                value: formSumString(data.amount)
              },
              {
                name: 'Срок кредита',
                value: data.termEndDate
                  ? data.termEndDate
                  : formTermString(data.term)
              },
              {
                name: 'ИНН',
                value: data.code
              }
            ]}
            subInfo={[
              {
                name: 'Тип клиента',
                value: PropertyFinancingClientType[data.clientType]
              },
              {
                name: 'Среднемесячный доход',
                value: formSumString(data.clientAverageMonthlyIncome)
              },
              {
                name: 'Тип подтверждения дохода',
                value:
                  PropertyFinancingClientIncomeConfirmationType[
                    data.clientIncomeConfirmationType
                  ]
              }
            ]}
            documents={{
              general: [
                ...inBankApplication.agreementDocuments,
                ...inBankApplication.groupDocuments
                // ...inBankApplication.propertyFinancingDocuments
              ],
              company: []
            }}
          >
            <ViewProperties applicationId={data.id} />
          </EachApplicationView>
        );
      }}
    </ApplicationViewConsumer>
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status
});

const PropertyFinancingViewConnect = connect<StateToProps>(mapStateToProps)(
  PropertyFinancingView
);

export { PropertyFinancingViewConnect as PropertyFinancingView };
