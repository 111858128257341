import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES } from 'src/globaltypes';
import {
  ApplicationFormStyled,
  CloseApplicationBtn,
  ApplicationStepTitle,
  ApplicationForm,
  ApplicationInputsColumn,
  ApplicationBtnsWrapper
} from '../styled';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { createValidaton } from './validator';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { InputRange, INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { ContractSubjectType, ContractPrepaymentType } from 'shared/constants';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { InputDate } from 'shared/ui/InputDate';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import {
  CONTRACT_PREPAYMENT_TYPE,
  CONTRACT_FINANCING_TYPE,
  CONTRACT_SUBJECT_TYPE,
  DOCUMENT_UPLOAD_TYPE,
  Document
} from 'src/features/Application/types';
import { SagaRequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import { SagaRequestDataType as DeleteDocumentSagaRequestDataType } from 'Application/reducers/deleteDocument';
import { ResponseData as ResGetInternalApplication } from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import { req as sendApplicationDataReq } from 'Application/actions/sendApplicationData';
import { req as uploadDocuments } from 'Application/actions/uploadDocuments';
import { req as deleteDocument } from 'Application/actions/deleteDocument';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';
import { LighterBlueContainer } from 'shared/styled/layouts';
import InputSingleFile from 'src/shared/ui/InputSingleFile/InputSingleFile';
import { ButtonStyle, PrepaymentTypeWrapper, TooltipBoxStyle } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps extends ResGetInternalApplication {
  applicationDocuments: Document[];
  documentsUploadStatus: REQUEST_STATUSES;
  documentsUploadData: UploadDocumentsRequestDataType;
  statusInternal: REQUEST_STATUSES;
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
  sendApplicationDataReq: (data: SendApplicationRequestDataType) => void;
  uploadDocuments: (data: UploadDocumentsRequestDataType) => void;
  deleteDocument: (data: DeleteDocumentSagaRequestDataType) => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ContractFinancingType = {
  FZ44: '44-ФЗ',
  FZ223: '223-ФЗ',
  P615: '615-П',
  GOZ: 'ГОЗ',
  COMMERCIAL: 'Коммерция'
};

const ContractFinancingFormStep2: React.FC<Props> = ({
  sendApplicationDataReq,
  openModal,
  contractAmount,
  contractEndDate,
  contractSubject,
  contractType,
  prepaymentAmount,
  prepaymentType,
  purchaseLink,
  code,
  statusInternal,
  uploadDocuments,
  deleteDocument,
  applicationDocuments,
  documentsUploadData,
  documentsUploadStatus,
  match,
  history
}) => {
  const Validator = createValidaton();
  const [fields, setFields] = React.useState({
    contractType: CONTRACT_FINANCING_TYPE.NOT_SET,
    purchaseLink: '',
    contractAmount: 0,
    contractSubject: CONTRACT_SUBJECT_TYPE.NONE,
    contractEndDate: '',
    prepaymentType: CONTRACT_PREPAYMENT_TYPE.NOT_SET,
    prepaymentAmount: 0
  });

  React.useEffect(
    () => {
      if (statusInternal === REQUEST_STATUSES.GOT) {
        setFields({
          contractAmount,
          contractEndDate,
          contractSubject,
          contractType,
          prepaymentAmount,
          prepaymentType,
          purchaseLink
        });
      }
    },
    [statusInternal]
  );

  const navigateFromTo = (from: string, to: string) =>
    history.push({
      pathname: match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isToNumber =
      name === 'contractAmount' ||
      name === 'prepaymentAmount' ||
      name === 'term';
    const isCheckbox = type === 'checkbox';
    const floated = toFloatFormatter(value);

    setFields(prevState => ({
      ...prevState,
      [name]: isCheckbox ? checked : isToNumber ? floated : value
    }));
  };

  const onContractEndDateChange = (contractEndDate: string) => {
    setFields(prevState => ({ ...prevState, contractEndDate }));
  };

  const onSubmit = () => {
    Validator.showAllErrors();
    if (!Validator.isFormValid()) return;

    sendApplicationDataReq({
      id: +match.params.id,
      contractFinancing: {
        contractAmount: fields.contractAmount,
        contractEndDate: fields.contractEndDate,
        contractSubject: fields.contractSubject,
        contractType: fields.contractType,
        prepaymentAmount: fields.prepaymentAmount,
        prepaymentType: fields.prepaymentType,
        purchaseLink: fields.purchaseLink,
        contractDocuments: applicationDocuments
      }
    });

    navigateFromTo(location.pathname, 'documents');
  };

  const notCommercialOrEmpty = () => {
    return (
      fields.contractType !== CONTRACT_FINANCING_TYPE.COMMERCIAL &&
      fields.contractType !== CONTRACT_FINANCING_TYPE.NOT_SET
    );
  };

  const findDocumentByMetaInfo = (metaInfo: string) => {
    return applicationDocuments.find(doc => doc.metaInfo === metaInfo);
  };

  const onFileUploadWithMetaInfo = (metaInfo: string, file) => {
    if (file) {
      file.metaInfo = metaInfo;
      onFileUpload(file);
    }
  };

  const onFileUpload = (file: File) => {
    uploadDocuments({
      id: +match.params.id,
      files: [file],
      type: DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING
    });
  };

  const onFileRemove = file => {
    deleteDocument({
      id: file.id,
      type: file.type,
      subType: DOCUMENT_UPLOAD_TYPE.CONTRACT_FINANCING
    });
  };

  const goBack = () => {
    navigateFromTo(location.pathname, 'options');
  };

  const isFormValid = (): boolean => {
    return (
      !!fields.contractType &&
      !!fields.contractAmount &&
      !!fields.prepaymentType
    );
  };

  // TODO extract
  const amountRange = {
    min: 0,
    max: 100000000000
  };

  const { errors } = Validator.insertArgs({
    amountRange: [amountRange.min, amountRange.max],
    contractType: fields.contractType,
    prepaymentType: fields.prepaymentType
  }).validate({
    contractAmount: fields.contractAmount,
    contractEndDate: fields.contractEndDate,
    contractSubject: fields.contractSubject,
    contractType: fields.contractType,
    prepaymentAmount: fields.prepaymentAmount,
    prepaymentType: fields.prepaymentType,
    purchaseLink: fields.purchaseLink
  });

  return (
    <ApplicationFormStyled>
      <CloseApplicationBtn
        onClick={() =>
          openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
        }
      />

      {!!code ? (
        <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
      ) : (
        <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
      )}

      <ApplicationInputsColumn>
        <ApplicationForm onSubmit={onSubmit} name="initialize-application-form">
          <LighterBlueContainer>
            <RadioButtonGroup
              required
              name="contractType"
              label="Тип контракта"
              keyValue={fields.contractType}
              onChange={onChange}
              radioBtns={Object.keys(ContractFinancingType).map(type => ({
                value: type,
                label: ContractFinancingType[type]
              }))}
            />

            <Input
              label="Ссылка на закупку"
              name="purchaseLink"
              error={errors.purchaseLink}
              placeholder="Ссылка на закупку"
              value={fields.purchaseLink}
              onChange={onChange}
            />

            <InputRange
              value={fields.contractAmount.toFixed(2)}
              label="Цена контракта"
              name="contractAmount"
              placeholder="Введите сумму"
              min={amountRange.min}
              max={amountRange.max}
              step={1000}
              sliderType={RANGE_SLIDER_TYPE.SUM}
              valueType={INPUT_RANGE_VALUE_TYPE.SUM}
              disableSlider={true}
              required
              error={errors.contractAmount}
              onChange={onChange}
            />

            <RadioButtonGroup
              name="contractSubject"
              label="Предмет контракта"
              keyValue={fields.contractSubject}
              onChange={onChange}
              error={errors.contractSubject}
              radioBtns={Object.keys(ContractSubjectType).map(type => ({
                value: type,
                label: ContractSubjectType[type]
              }))}
            />

            <InputDate
              label="Срок окончания контракта"
              onDateChange={onContractEndDateChange}
              error={errors.contractEndDate}
              dateValue={fields.contractEndDate}
              placeholder="По Датy"
            />

            <PrepaymentTypeWrapper>
              <RadioButtonGroup
                required
                name="prepaymentType"
                label="Аванс по договору"
                keyValue={fields.prepaymentType}
                onChange={onChange}
                error={errors.prepaymentType}
                radioBtns={Object.keys(ContractPrepaymentType).map(type => ({
                  value: type,
                  label: ContractPrepaymentType[type]
                }))}
              />
            </PrepaymentTypeWrapper>

            {/* TODO disable sliderType if slider is disabled */}
            {fields.prepaymentType ===
              CONTRACT_PREPAYMENT_TYPE.HAS_PREPAYMENT && (
              <InputRange
                value={fields.prepaymentAmount.toFixed(2)}
                label="Сумма аванса"
                name="prepaymentAmount"
                placeholder="Введите сумму"
                min={amountRange.min}
                max={amountRange.max}
                step={1000}
                sliderType={RANGE_SLIDER_TYPE.SUM}
                valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                disableSlider={true}
                error={errors.prepaymentAmount}
                onChange={onChange}
              />
            )}

            {notCommercialOrEmpty() && (
              <InputSingleFile
                label="Банковская гарантия исполнения контракта"
                name="bank_contract_guarantee"
                file={findDocumentByMetaInfo('bank_contract_guarantee')}
                uploadStatus={
                  documentsUploadStatus === REQUEST_STATUSES.REQUEST &&
                  documentsUploadData.files.some(
                    item => item.metaInfo === 'bank_contract_guarantee'
                  )
                    ? REQUEST_STATUSES.REQUEST
                    : REQUEST_STATUSES.GOT
                }
                onFileUpload={file =>
                  onFileUploadWithMetaInfo('bank_contract_guarantee', file)
                }
                onFileRemove={onFileRemove}
              />
            )}

            <InputSingleFile
              label="Подписанный контракт или его драфт"
              file={findDocumentByMetaInfo('bank_contract_or_draft')}
              uploadStatus={
                documentsUploadStatus === REQUEST_STATUSES.REQUEST &&
                documentsUploadData.files.some(
                  item => item.metaInfo === 'bank_contract_or_draft'
                )
                  ? REQUEST_STATUSES.REQUEST
                  : REQUEST_STATUSES.GOT
              }
              onFileUpload={file =>
                onFileUploadWithMetaInfo('bank_contract_or_draft', file)
              }
              onFileRemove={onFileRemove}
            />
          </LighterBlueContainer>

          <ApplicationBtnsWrapper>
            <Button template="backBtn" label="Назад" onClick={goBack} />

            <TooltipBoxStyle
              isWarning={!isFormValid()}
              text="не заполнены все обязательные поля"
            >
              <ButtonStyle
                notValid={!isFormValid()}
                type="submit"
                label="Продолжить"
                template="nextBtn"
              />
            </TooltipBoxStyle>
          </ApplicationBtnsWrapper>
        </ApplicationForm>
      </ApplicationInputsColumn>
    </ApplicationFormStyled>
  );
};

const mapStateToProps = ({ Application, InternalAgentRole }: STORE) => ({
  documentsUploadData: Application.uploadDocuments.data,
  documentsUploadStatus: Application.uploadDocuments.status,
  applicationDocuments:
    Application.setContractFinancingFormData.contractDocuments,
  ...InternalAgentRole.getInternalApplication.data,
  statusInternal: InternalAgentRole.getInternalApplication.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      sendApplicationDataReq,
      uploadDocuments,
      deleteDocument
    },
    dispatch
  );

const ContractFinancingFormStep1Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractFinancingFormStep2)
);

export { ContractFinancingFormStep1Connect as ContractFinancingFormStep2 };
