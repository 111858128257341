import createBorrowerCompanySaga from './createBorrowerCompany';
import deleteBorrowerCompanySaga from './deleteBorrowerCompany';
import getBorrowerCompaniesListSaga from './getBorrowerCompaniesList';
import saveBorrowerCompaniesListSaga from './saveBorrowerCompaniesList';
import uploadBorrowerCompanyDocumentsSaga from './uploadBorrowerCompanyDocuments';
import deleteBorrowerCompanyDocumentSaga from './deleteBorrowerCompanyDocument';
import getDocumentListByApplicationIdSaga from './getDocumentListByApplicationId';
import postUploadFinDocInternalSaga from './postUploadFinDocInternal';
import postUploadNonFinDocInternalSaga from './postUploadNonFinDocInternal';
import postDocumentListByApplicationIdSaga from './postDocumentListByApplicationId';

export default [
  createBorrowerCompanySaga,
  deleteBorrowerCompanySaga,
  getBorrowerCompaniesListSaga,
  saveBorrowerCompaniesListSaga,
  uploadBorrowerCompanyDocumentsSaga,
  deleteBorrowerCompanyDocumentSaga,
  getDocumentListByApplicationIdSaga,
  postUploadFinDocInternalSaga,
  postUploadNonFinDocInternalSaga,
  postDocumentListByApplicationIdSaga
];
