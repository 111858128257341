import * as React from 'react';
import { differenceInDays, parse } from 'date-fns';

import { RadioGroup } from 'shared/ui/RadioGroup';
import { InputRange } from 'shared/ui/InputRange';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { formTermDate } from 'src/shared/utils/Utils';
import { InputDate } from 'shared/ui/InputDate';
import { InputDateContainerStyled, GuaranteesTermContainer } from './styles';

interface OwnProps {
  onChangeBaseFields: (e: React.FormEvent<HTMLInputElement>) => void;
  onDateChange: (name: string, date: string) => void;
  onTermTypeChange: (name: string, value: any) => void;
  required: boolean;
  errors: any;
  isTeledoc?: boolean;
  term: number;
  termStartDate: string;
  termEndDate: string;
}

export enum TERM_TYPE {
  DATES = 'DATES',
  PERIOD = 'PERIOD'
}

type Props = OwnProps;

export const GuaranteesTerm: React.FC<Props> = ({
  onTermTypeChange,
  onChangeBaseFields,
  onDateChange,
  term,
  termStartDate,
  termEndDate,
  required,
  errors,
  isTeledoc
}) => {
  const [termType, setTermType] = React.useState<TERM_TYPE>(TERM_TYPE.DATES);

  React.useEffect(
    () => {
      if (isTeledoc) {
        setTermType(TERM_TYPE.DATES);
      }
    },
    [isTeledoc]
  );

  React.useEffect(
    () => {
      if (term === 0) {
        setTermType(TERM_TYPE.DATES);
      } else {
        setTermType(TERM_TYPE.PERIOD);
      }
    },
    [term]
  );

  const onTypeChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value: termType } = e.currentTarget;

    if (isTeledoc) return;

    if (termType === TERM_TYPE.DATES) {
      onTermTypeChange('term', 0);
    }
    if (termType === TERM_TYPE.PERIOD) {
      onTermTypeChange('termStartDate', null);
      onTermTypeChange('termEndDate', null);
      onTermTypeChange('term', 1);
    }

    setTermType(termType as TERM_TYPE);
  };

  const types = [
    { value: TERM_TYPE.DATES, label: 'Точные даты' },
    { value: TERM_TYPE.PERIOD, label: 'Период' }
  ];

  const startDate = parse(termStartDate, 'dd/MM/yyyy', new Date());
  const endDate = parse(termEndDate, 'dd/MM/yyyy', new Date());

  return (
    <GuaranteesTermContainer>
      <RadioGroup
        required={required}
        name="term_type"
        label="Срок"
        template="row"
        keyValue={termType}
        onChange={onTypeChange}
        radioBtns={types}
      />
      {termType === TERM_TYPE.PERIOD && (
        <InputRange
          value={term.toString()}
          name="term"
          placeholder="Введите срок"
          min={1}
          max={10 * 12}
          step={1}
          sliderType={RANGE_SLIDER_TYPE.TERM}
          valueType={INPUT_RANGE_VALUE_TYPE.TERM}
          required={required}
          error={errors.term}
          onChange={onChangeBaseFields}
        />
      )}
      {termType === TERM_TYPE.DATES && (
        <InputDateContainerStyled>
          <InputDate
            required={required}
            name="termStartDate"
            dateValue={termStartDate}
            onDateChange={date => onDateChange('termStartDate', date)}
            error={errors.termStartDate}
            minDate={new Date()}
            placeholder="Дата начала"
          />
          <InputDate
            required={required}
            name="termEndDate"
            dateValue={termEndDate}
            onDateChange={date => onDateChange('termEndDate', date)}
            minDate={new Date()}
            error={errors.termEndDate}
            placeholder="Дата окончания"
          />

          {termStartDate &&
            termEndDate && (
              <p>{formTermDate(differenceInDays(endDate, startDate))}</p>
            )}
        </InputDateContainerStyled>
      )}
    </GuaranteesTermContainer>
  );
};
