import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getCounterparties,
  ResponseData as CounterpartiesData,
  RequestData as CounterpartiesRequestData
} from 'src/features/Counterparties/actions/getCounterparties';
import {
  req as getAgents,
  ResponseData as AgentsData
} from 'src/features/Counterparties/actions/getAgents';
import { COUNTERPARTIES_TABS } from 'src/features/Counterparties/types';
import { DebtorsList } from 'src/features/Counterparties/components/Tabs/Debtors/Debtors';
import { SuppliersList } from 'src/features/Counterparties/components/Tabs/Suppliers/Suppliers';
import { AgentsList } from './Tabs/Agents/Agents';
import { Menu } from 'src/features/Layouts/components';

interface StateToProps {
  counterparties: CounterpartiesData;
  statusCounterparties: REQUEST_STATUSES;
  errorCounterparties: ResponseError;
  agents: AgentsData;
  statusAgents: REQUEST_STATUSES;
  errorAgents: ResponseError;
}

interface DispatchToProps {
  getCounterparties: (data: CounterpartiesRequestData) => void;
  getAgents: (inn: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Counterparties: React.FC<Props> = ({
  getAgents,
  getCounterparties,
  agents,
  statusAgents,
  errorAgents,
  counterparties,
  statusCounterparties,
  errorCounterparties,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(() => {
    initFetch();
  }, []);

  React.useEffect(
    () => {
      if (activeTab === 'DEFAULT') {
        getCounterparties({
          debtorInnToFilterBy: match.params.inn
        });
      } else if (activeTab === 'BUYERS') {
        getCounterparties({
          SupplierInnToFilterBy: match.params.inn
        });
      }
    },
    [activeTab]
  );

  const initFetch = () => {
    if (match.params.applicationTab) {
      setActiveTab(match.params.applicationTab.toUpperCase());
    } else if (match.params.tab === 'supplier') {
      setActiveTab(match.params.tab.toUpperCase());
    }

    getAgents(match.params.inn);
  };

  const handleActiveTab = (tabName: string) => {
    const { pathname } = location;
    const baseUrl = pathname.replace(`/${activeTab.toLowerCase()}`, '');

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={COUNTERPARTIES_TABS}
        isSubMenu
      />

      {activeTab === 'DEFAULT' && (
        <DebtorsList
          debtors={counterparties}
          status={statusCounterparties}
          error={errorCounterparties}
        />
      )}

      {activeTab === 'BUYERS' && (
        <SuppliersList
          suppliers={counterparties}
          status={statusCounterparties}
          error={errorCounterparties}
        />
      )}

      {activeTab === 'AGENTS' && (
        <AgentsList agents={agents} status={statusAgents} error={errorAgents} />
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Counterparties }: CRM) => ({
  counterparties: Counterparties.getCounterparties.data,
  statusCounterparties: Counterparties.getCounterparties.status,
  errorCounterparties: Counterparties.getCounterparties.error,
  agents: Counterparties.getAgents.data,
  statusAgents: Counterparties.getAgents.status,
  errorAgents: Counterparties.getAgents.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCounterparties, getAgents }, dispatch);

const CounterpartiesConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Counterparties)
);

export { CounterpartiesConnect as Counterparties };
