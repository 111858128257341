import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendCompanyInfoData';

import { SEND_COMPANY_INFO_DATA } from '../reducers/sendCompanyInfoData';
import SCFApi from '../api';
import {
  CompanyInfoData,
  resetCompanyInfoData
} from '../actions/setCompanyInfo';
import { req as getCompanyInfo } from './../actions/getCompanyInfo';

function* sendCompanyInfoData(action: {
  type: SEND_COMPANY_INFO_DATA;
  data: CompanyInfoData;
}): Generator {
  try {
    yield call(SCFApi.sendCompanyInfoData, action.data);
    yield put(got());
    yield put(resetCompanyInfoData());
    yield put(getCompanyInfo());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendCompanyInfoDataSaga(): Generator {
  yield takeLatest(SEND_COMPANY_INFO_DATA.REQ, sendCompanyInfoData);
}
