import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';

import { clearSession, setSession } from 'App/actions/cached';
import { got as gotUserData } from 'User/actions/getUserData';
import { got, err, RequestDataType } from 'User/actions/signIn';
import { SIGNIN_ACTIONS } from 'User/reducers/signIn';

function* signIn(action: { type: SIGNIN_ACTIONS; data: RequestDataType }) {
  try {
    const res: any = yield call(UserApi.signIn, action.data);
    window.location.reload();
    yield put(got());
    yield put(gotUserData(res));
    yield put(setSession());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* signInSaga() {
  yield takeLatest(SIGNIN_ACTIONS.REQ, signIn);
}
