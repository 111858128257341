import { GET_LEADOGENERATORS } from '../reducers/getLeadogenerators';
import { Leadogenerator } from '../types';


export const req = () => ({
  type: GET_LEADOGENERATORS.REQ
});

export const got = (data: Leadogenerator[]) => ({
  type: GET_LEADOGENERATORS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_LEADOGENERATORS.ERR,
  error
});
