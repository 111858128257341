import { combineReducers } from 'redux';
import patchContract, { PatchContract } from './patchContract';
import patchCabinetContract, {
  PatchCabinetContract
} from './patchCabinetContract';

export interface ContractLogicReducers {
  patchContract: PatchContract;
  patchCabinetContract: PatchCabinetContract;
}

export const Contract = combineReducers({
  patchContract,
  patchCabinetContract
});
