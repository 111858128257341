import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { ResponseData as DebtorsData } from 'src/features/Counterparties/actions/getCounterparties';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { DebtorsContainer, NoData } from './styled';
import { Button } from 'shared/ui/Button';

interface OwnProps {
  debtors: DebtorsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & OwnProps;

const DebtorsList: React.FC<Props> = ({
  debtors,
  status,
  error,
  history,
  match
}) => {
  const navigateToCompany = (inn: string) => {
    if (inn) {
      history.push(`/crm/companies/${inn}`);
    }
  };

  const navigateToBuyersToSupplierLink = () => {
    history.push('/crm/buyers_to_suppliers_links/new', {
      supplierInn: match.params.inn
    });
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <DebtorsContainer>
          <Button
            label="Добавить поставщика"
            onClick={navigateToBuyersToSupplierLink}
          />

          {debtors.items.length ? (
            <>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="2%">ИНН</TableThStyled>
                    <TableThStyled width="10%">Наименование</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {debtors.items.map(company => (
                    <TableRowStyled
                      key={company.id}
                      onClick={() => navigateToCompany(company.debtorInn)}
                    >
                      <td>{company.supplierInn}</td>
                      <td>{company.supplierName}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>
              <Pagination list={debtors} />
            </>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>на платформе пока нет данных о контрагентах компании</p>
              <p>вы можете добавить поставщиков самостоятельно</p>
            </NoData>
          )}
        </DebtorsContainer>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const DebtorsListConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DebtorsList)
);

export { DebtorsListConnect as DebtorsList };
