import businessRegisterSaga from './businessRegister';
import checkBusinessStatusSaga from './businessStatus';
import getPersonsOfCompaniesSaga from './getPersonsOfCompanies';
import getBenificiariesOfCompaniesSaga from './getBenificiariesOfCompanies';
import getSubsidiariesOfCompaniesSaga from './getSubsidiariesOfCompanies';
import getCompanyByInnConfirmedFoundersSaga from './getCompanyByInnConfirmedFounders';
import getCompanyByInnConfirmedSubsidiariesSaga from './getCompanyByInnConfirmedSubsidiaries';

export default [
  businessRegisterSaga,
  checkBusinessStatusSaga,
  getPersonsOfCompaniesSaga,
  getBenificiariesOfCompaniesSaga,
  getSubsidiariesOfCompaniesSaga,
  getCompanyByInnConfirmedFoundersSaga,
  getCompanyByInnConfirmedSubsidiariesSaga
];
