import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { req as getStartRate } from 'entities/SCF/Supplier/model/actions/getStartRateSupplierRole';
import { PageStyled } from 'src/features/SCF/UI/ScfStyles';
import { Redirect } from 'react-router';
import { history } from 'src/shared/utils/History';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Pie from './Pie';
import {
  ContentContainer,
  MainTitle,
  ItemsBlock,
  ItemRow,
  ItemRowText,
  RowLink,
  IconContainer
} from './styles';

const calculateAverage = (data: Record<string, number>): number => {
  const values = Object.values(data);
  const sum = values.reduce((acc, value) => acc + value, 0);
  return values.length > 0 ? sum / values.length : 0;
};

const LinkClick = (e: Event, path: string) => {
  e.preventDefault();
  history.push(`/supplier/${path}`);
};

export const MainPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getStartRate());
    },
    [dispatch]
  );

  const startRate = useSelector(
    ({ SCFSupplier }) => SCFSupplier.getStartRateSupplierRole.data
  );
  const shouldRedirect = Object.values(startRate).every(value => value === 100);

  const average = calculateAverage(startRate);

  if (shouldRedirect) {
    return <Redirect to="supplier/claims?page=1" />;
  }

  return (
    <PageStyled>
      <MainTitle>Начало работы</MainTitle>
      <ContentContainer>
        <Pie
          percentage={average}
          colour={'rgba(0, 133, 254, 1)'}
          radius={150}
          textSize={70}
        />
        <ItemsBlock>
          {startRate.regData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Заполните реквизиты компании</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.regData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Заполните</p>
                <RowLink onClick={e => LinkClick(e, 'settings/requisites')}>
                  реквизиты
                </RowLink>
                <p>компании</p>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.bankAccountsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте реквизиты ваших банковских счетов</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.bankAccountsData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте реквизиты ваших </p>
                <RowLink
                  onClick={e => LinkClick(e, 'settings/createBankAccount')}
                >
                  банковских счетов
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.certificateData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте сертификат для подписания документов</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.certificateData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте</p>
                <RowLink onClick={e => LinkClick(e, 'settings/certificates')}>
                  сертификат
                </RowLink>
                <p>для подписания документов</p>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.debtorData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте первого покупателя</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.debtorData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте первого</p>
                <RowLink onClick={e => LinkClick(e, 'debtors/add')}>
                  покупателя
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.supplyContractsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Добавьте и загрузите договор поставки</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.supplyContractsData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Добавьте и загрузите</p>
                <RowLink onClick={e => LinkClick(e, 'supply_contracts/add')}>
                  договор поставки
                </RowLink>
              </ItemRowText>
            </ItemRow>
          )}
          {startRate.subscriptionsData === 100 ? (
            <ItemRow>
              <IconContainer>
                <FontAwesomeIcon
                  icon={faCheck}
                  color="rgba(0, 133, 254, 1)"
                  size="lg"
                />
              </IconContainer>
              <ItemRowText>
                <p>Настройте уведомления по электронной почте</p>
              </ItemRowText>
            </ItemRow>
          ) : (
            <ItemRow>
              <Pie
                percentage={startRate.subscriptionsData}
                colour={'rgba(0, 133, 254, 1)'}
                radius={24}
                textSize={16}
              />
              <ItemRowText>
                <p>Настройте</p>
                <RowLink onClick={e => LinkClick(e, 'settings/subscriptions')}>
                  уведомления
                </RowLink>
                <p>по электронной почте</p>
              </ItemRowText>
            </ItemRow>
          )}
        </ItemsBlock>
      </ContentContainer>
    </PageStyled>
  );
};
