import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from './../actions/deleteCertificate';

import {
  DELETE_CERTIFICATE,
  RequestDataType
} from './../reducers/deleteCertificate';
import SCFApi from './../api';
import { req as getCertificate } from './../actions/getCertificate';

function* deleteCertificate(action: {
  type: DELETE_CERTIFICATE;
  data: RequestDataType;
}): Generator {
  try {
    yield call(SCFApi.deleteCertificate, action.data);
    yield put(got());
    yield put(getCertificate());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteBankAccountSaga(): Generator {
  yield takeLatest(DELETE_CERTIFICATE.REQ, deleteCertificate);
}
