import { GET_COMPANY_BY_INN } from 'src/features/ExternalCredentials/reducers/getCompanyByInn';

export interface ResponseDataType {
  inn: string;
  companyShortName: string;
  companyFullName: string;
  ogrn: string;
  regDate: Date;
  kpp: string;
  pfr: string;
  fss: string;
  startCapital: number;
  companyAddress: string;
}

export interface RequestDataType {
  inn: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_COMPANY_BY_INN.REQ,
  data
});

export const reset = () => ({
  type: GET_COMPANY_BY_INN.RESET
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMPANY_BY_INN.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_BY_INN.ERR,
  error
});
