import * as React from 'react';
import { connect } from 'react-redux';
import {
  STORE,
  REQUEST_STATUSES,
  USER_PERMISSIONS,
  ResponseError
} from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  ApplicationFormStyled,
  ApplicationStepTitle,
  ApplicationForm,
  ApplicationInputsColumn,
  ApplicationFormContent
} from 'src/features/Application/components/ApplicationForm/ApplicationProcess/styles';
import { InnPlaceholders, ApplicationTypes } from 'shared/constants';
import { InputRange, INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { Label } from 'shared/ui/Label';
import { Button } from 'shared/ui/Button';
import { LeadsData } from '../../actions/setLeadFormData';
import { SetLeadDataStoreState } from '../../reducers/setLeadFormData';
import { setLeadFormData } from '../../actions/setLeadFormData';
import {
  req as sendLeadFormData,
  RequestData as SendLeadData
} from '../../actions/sendLeadData';
import {
  ResponseDataType as CompanyByInnRes,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  ClientContactStyled,
  DatesContainerStyled,
  WrapperInputDetail
} from './styled';
import { russiaPhoneMask } from 'shared/utils/InputMasks';
import { InputDate } from 'shared/ui/InputDate';
import { APPLICATION_TYPES } from 'src/features/Application/types';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { toFloatFormatter } from 'src/shared/utils/Utils';
import { req as getLeadogenerators } from '../../actions/getLeadogenerators';
import { Loader } from 'src/features/Layouts/components';
import { createValidation } from './validator';
import { Leadogenerator } from '../../types';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';

interface StateToProps extends Partial<LeadsData> {
  leadogenerators: Leadogenerator[];
  permissions: USER_PERMISSIONS[];
  isSendingData: boolean;
  companyByInn: CompanyByInnRes;
  statusInn: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  setLeadFormData: (data: SetLeadDataStoreState) => void;
  getLeadogenerators: () => void;
  sendLeadFormData: (data: SendLeadData) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  resetStateCompanyByInn: () => void;
}

interface State {
  companyName: string;
  isInfoPopup: boolean;
}

type Props = StateToProps & DispatchToProps;

class LeadsForm extends React.Component<Props, State> {
  Validator = createValidation();
  state: State = {
    companyName: '',
    isInfoPopup: false
  };

  componentDidMount() {
    this.props.getLeadogenerators();
    this.Validator.showAllErrors();
    if (this.props.inn) {
      this.props.getCompanyByInn({ inn: this.props.inn });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      prevProps.inn !== this.props.inn &&
      this.props.inn &&
      this.props.inn.length >= 10
    ) {
      this.props.getCompanyByInn({ inn: this.props.inn });
    }
    if (prevProps.inn !== this.props.inn) {
      this.setState({ isInfoPopup: false });
    }
    if (
      this.props.statusInn === REQUEST_STATUSES.GOT &&
      prevState.isInfoPopup !== this.state.isInfoPopup
    ) {
      this.setState({ companyName: this.props.companyByInn.companyShortName });
    }
    if (this.props.clientName !== prevProps.clientName) {
      this.setState({ companyName: this.props.clientName });
    }
  }

  componentWillUnmount() {
    this.props.resetStateCompanyByInn();
  }

  onSubmit = () => {
    this.Validator.showAllErrors();

    if (!this.Validator.isFormValid()) return;

    const {
      id,
      contactFirstName,
      contactLastName,
      contactMiddleName,
      contactEmail,
      contactPhoneNumber,
      inn,
      applicationFinancingType,
      amount,
      termStartDate,
      termEndDate,
      purchaseNumber,
      comment,
      sourceLink
    } = this.props;
    this.props.sendLeadFormData({
      id,
      contactFirstName,
      contactLastName,
      contactMiddleName,
      contactEmail,
      contactPhoneNumber,
      inn,
      clientName: this.state.companyName,
      applicationFinancingType,
      amount,
      termStartDate,
      termEndDate,
      purchaseNumber,
      comment,
      sourceLink
    });
  };

  // TODO extract
  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const isAmount = name === 'amount';
    if (isAmount) var floated = toFloatFormatter(value);

    this.props.setLeadFormData({
      [name]: isAmount ? floated : value
    });
  };

  onTermDateStartChange = (termStartDate: string) => {
    this.props.setLeadFormData({ termStartDate });
  };

  onTermDateEndChange = (termEndDate: string) => {
    this.props.setLeadFormData({ termEndDate });
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  render() {
    const {
      id,
      contactFirstName,
      contactLastName,
      contactMiddleName,
      contactEmail,
      contactPhoneNumber,
      inn,
      clientName,
      applicationFinancingType,
      amount,
      termStartDate,
      termEndDate,
      purchaseNumber,
      comment,
      sourceUserId,
      sourceLink,
      permissions,
      companyByInn,
      statusInn
    } = this.props;
    const { errors } = this.Validator.validate(this.props as any);
    const amountRange = {
      min: 0,
      max: 3000000000
    };

    return (
      <ApplicationFormStyled>
        <ApplicationStepTitle>
          {id ? `Редактировать лид №${id}` : `Создать лид`}{' '}
        </ApplicationStepTitle>
        <ApplicationFormContent>
          <ApplicationInputsColumn>
            {this.props.isSendingData ? (
              <Loader />
            ) : (
              <ApplicationForm
                onSubmit={this.onSubmit}
                name="initialize-lead-form"
              >
                <WrapperInputDetail>
                  <Input
                    value={inn}
                    label="ИНН"
                    error={errors.inn}
                    name="inn"
                    placeholder={InnPlaceholders.entity}
                    onChange={this.onChange}
                  />

                  {inn && inn.length < 10 ? null : statusInn ===
                  REQUEST_STATUSES.ERROR ? (
                    <InnPopup>
                      <h2>
                        Компания с таким инн не найдена в системе ЕГРЮЛ, но вы
                        можете создать связку с введенным ИНН
                      </h2>
                    </InnPopup>
                  ) : (
                    statusInn === REQUEST_STATUSES.GOT &&
                    !this.state.isInfoPopup && (
                      <InnPopup
                        companyInfo={companyByInn}
                        onPopupClick={this.onPopupClick}
                      />
                    )
                  )}
                </WrapperInputDetail>

                <Input
                  value={this.state.companyName}
                  label="Компания заемщика"
                  name="clientName"
                  placeholder="Введите краткое наименование клиента"
                  onChange={this.onChange}
                />
                <ClientContactStyled>
                  <p>Контакт клиента</p>
                  <Input
                    value={contactLastName}
                    label="Фамилия"
                    name="contactLastName"
                    placeholder="Иванов"
                    onChange={this.onChange}
                  />
                  <Input
                    value={contactFirstName}
                    label="Имя"
                    name="contactFirstName"
                    placeholder="Иван"
                    onChange={this.onChange}
                  />
                  <Input
                    value={contactMiddleName}
                    label="Отчество"
                    name="contactMiddleName"
                    placeholder="Иванович"
                    onChange={this.onChange}
                  />
                  <Input
                    value={contactEmail}
                    label="Email"
                    name="contactEmail"
                    placeholder="ivan@example.com"
                    onChange={this.onChange}
                  />
                  <Input
                    value={contactPhoneNumber}
                    label="Контактный номер телефона"
                    name="contactPhoneNumber"
                    placeholder="+7(___)___-__-__"
                    mask={russiaPhoneMask}
                    onChange={this.onChange}
                  />
                </ClientContactStyled>

                {/*  TODO refactor ? : */}
                <Select
                  options={this.getFinancingTypes()}
                  value={
                    applicationFinancingType ? applicationFinancingType : ''
                  }
                  placeholder="Тип финансирования"
                  onChange={this.onChange}
                  name="applicationFinancingType"
                />

                <Label label="Срок" />
                <DatesContainerStyled>
                  <InputDate
                    onDateChange={this.onTermDateStartChange}
                    dateValue={termStartDate}
                    placeholder="Дата начала"
                  />
                  <InputDate
                    onDateChange={this.onTermDateEndChange}
                    dateValue={termEndDate}
                    placeholder="Дата конца"
                  />
                </DatesContainerStyled>

                {(applicationFinancingType ===
                  APPLICATION_TYPES.PROPERTY_FINANCING ||
                  applicationFinancingType === APPLICATION_TYPES.FACTORING ||
                  applicationFinancingType ===
                    APPLICATION_TYPES.GUARANTEES) && (
                  <Input
                    value={purchaseNumber}
                    label="Номер закупки"
                    name="purchaseNumber"
                    placeholder=""
                    onChange={this.onChange}
                  />
                )}
                {permissions.includes(USER_PERMISSIONS.LEADS_MANAGER) && (
                  <Select
                    options={this.getLeadogeneratorOptions()}
                    placeholder="Выберите лидогенератора"
                    value={sourceUserId === 0 ? '' : sourceUserId.toString()}
                    onChange={this.onChange}
                    name="sourceUserId"
                  />
                )}
                <InputRange
                  value={amount.toFixed(2)}
                  label="Сумма"
                  name="amount"
                  placeholder="Введите сумму"
                  min={amountRange.min}
                  max={amountRange.max}
                  step={1000}
                  sliderType={RANGE_SLIDER_TYPE.SUM}
                  valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                  disableSlider={true}
                  onChange={this.onChange}
                />

                <Textarea
                  onChange={this.onChange}
                  label="Дополнительный комментарий"
                  name="comment"
                  value={comment}
                  placeholder=""
                />

                <Button
                  type="submit"
                  label={id ? 'Редактировать лид' : 'Создать лид'}
                  w="fit-content"
                />
              </ApplicationForm>
            )}
          </ApplicationInputsColumn>
        </ApplicationFormContent>
      </ApplicationFormStyled>
    );
  }

  private getLeadogeneratorOptions() {
    const { leadogenerators } = this.props;
    return leadogenerators.map(leadogenerator => {
      return {
        id: leadogenerator.userId.toString(),
        name: `${leadogenerator.inn} ${leadogenerator.firstName} ${
          leadogenerator.lastName
        }`
      };
    });
  }

  private getFinancingTypes() {
    return Object.keys(ApplicationTypes).map(key => {
      return { id: key, name: ApplicationTypes[key] };
    });
  }
}

/* TODO extract into just FormStyled */
const mapStateToProps = ({ Leads, User, SCF }: STORE) => ({
  leadogenerators: Leads.getLeadogenerators.data,
  isSendingData: Leads.sendLeadData.status === REQUEST_STATUSES.REQUEST,
  permissions: User.getUserData.data.permissions,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  ...Leads.setLeadFormData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendLeadFormData,
      setLeadFormData,
      getLeadogenerators,
      getCompanyByInn,
      resetStateCompanyByInn
    },
    dispatch
  );

const LeadsFormConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeadsForm);

export { LeadsFormConnect as LeadsForm };
