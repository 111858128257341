import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type CreateBorrowerCompanyDataStoreState = Reducer;

export interface RequestDataType {
  applicationId: number;
  name: string;
  individualNumber: string;
  roles: string[];
}

export enum CREATE_BORROWER_COMPANY {
  REQ = 'CREATE_BORROWER_COMPANY_REQUEST',
  GOT = 'CREATE_BORROWER_COMPANY_GOT',
  ERR = 'CREATE_BORROWER_COMPANY_ERROR',
  RESET = 'CREATE_BORROWER_COMPANY_RESET'
}

export const initialState: CreateBorrowerCompanyDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const createBorrowerCompany = (
  state: CreateBorrowerCompanyDataStoreState = initialState,
  action: {
    type: CREATE_BORROWER_COMPANY;
    error: {};
  }
): CreateBorrowerCompanyDataStoreState => {
  switch (action.type) {
    case CREATE_BORROWER_COMPANY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CREATE_BORROWER_COMPANY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CREATE_BORROWER_COMPANY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case CREATE_BORROWER_COMPANY.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default createBorrowerCompany;
