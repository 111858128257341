import { call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/InternalAgent/model/actions/getAllDocumentForms';
import { GET_ALL_DOCUMENTS_FORMS } from 'entities/Cabinet/InternalAgent/model/reducers/getAllDocumentsForms';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* getAllDocumentForms(action: {
  type: GET_ALL_DOCUMENTS_FORMS;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentRoleApi.getAllDocumentForms,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllDocumentFormsSaga(): Generator {
  yield takeEvery(GET_ALL_DOCUMENTS_FORMS.REQ, getAllDocumentForms);
}
