import styled from 'styled-components';
import {
  rr,
  red,
  lighterGray,
  lightGray,
  gray,
  black,
  blue,
  navyBlue
} from 'shared/styled';

export const SelectStyled = styled.select.attrs(props => ({
  ['aria-label']: props.name
}))`
  width: 100%;
  height: 50px;

  margin-top: 8px;

  padding-left: 15px;
  outline: none;

  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid ${({ isError }) => (isError ? red : gray)};

  font-family: ${rr};
  font-size: 14px;
  color: ${({ isError }) => (isError ? red : black)};
  -webkit-appearance: none;

  background-size: 15px;
  background-position: 95% 55%;
  background-repeat: no-repeat;

  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 25.75">
      <polyline 
        fill="none" 
        stroke="${gray}" 
        stroke-width="4"
        stroke-linecap="round" 
        stroke-linejoin="round" 
        points="2,2 18.9,18.6 36,2 "/>
    </svg>
  ');
  
  &:active,
  &:focus ,
  &:hover {
    border-color: ${blue};
    color: ${navyBlue};
  }


  &:disabled {
    background-color:${lighterGray};
    background-image: none;
    border-color: ${lightGray};
    color: ${gray};
  }
`;
