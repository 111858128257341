import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeleteFromAuctionStoreState extends Reducer {}

export enum DELETE_FROM_AUCTION {
  REQ = 'DELETE_FROM_AUCTION_REQ',
  GOT = 'DELETE_FROM_AUCTION_GOT',
  ERR = 'DELETE_FROM_AUCTION_ERR'
}

export const initialState: DeleteFromAuctionStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const deleteFromAuction = (
  state: DeleteFromAuctionStoreState = initialState,
  action: {
    type: DELETE_FROM_AUCTION;
    error: {};
  }
): DeleteFromAuctionStoreState => {
  switch (action.type) {
    case DELETE_FROM_AUCTION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELETE_FROM_AUCTION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_FROM_AUCTION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteFromAuction;
