import * as React from 'react';

import { Comment } from 'Comments/types';
import {
  AddReplyBlock,
  AttachedDocumentsBlock,
  CommentsContextType,
  CommentsContext
} from 'Comments/components';

import {
  EachCommentStyled,
  CommentHead,
  CommentBody,
  Avatar,
  CommentInfo,
  CommentText,
  ReplyButton,
  ShowFullCommentButton,
  CommentTextHeight
} from './styles';

interface Props {
  comment: Comment;
  isActive?: boolean;
}

interface CommentTextRef {
  current: HTMLElement;
}

export const EachComment: React.FC<Props> = ({
  comment,
  isActive = true
}: Props) => {
  const commentText: CommentTextRef = React.createRef();
  const {
    activeCommentId,
    setActiveCommentId,
    disabled
  }: CommentsContextType = React.useContext(CommentsContext);
  const [isFullView, showFullComment] = React.useState<boolean>(true);

  React.useEffect(() => {
    commentText.current.clientHeight > CommentTextHeight &&
      showFullComment(false);
  }, []);

  return (
    <React.Fragment>
      <EachCommentStyled>
        <CommentHead>
          <Avatar isAuthor={comment.isAuthor}>
            {comment.firstName.charAt(0).toUpperCase()}
          </Avatar>

          <CommentInfo>
            <span>{`${comment.firstName} ${comment.lastName}`}</span>
            <p>{comment.createdAt}</p>
          </CommentInfo>
        </CommentHead>

        <CommentBody>
          <CommentText isFullView={isFullView} ref={commentText}>
            {comment.text}
          </CommentText>

          {disabled !== true && (
            <ReplyButton
              onClick={() => setActiveCommentId(comment.id)}
              isActive={isActive}
            >
              Ответить
            </ReplyButton>
          )}

          {!isFullView && (
            <ShowFullCommentButton onClick={() => showFullComment(true)}>
              Ещё
            </ShowFullCommentButton>
          )}
        </CommentBody>

        <AttachedDocumentsBlock documents={comment.documents} />
      </EachCommentStyled>

      {activeCommentId === comment.id && (
        <AddReplyBlock type={comment.type} parentId={comment.id} />
      )}
    </React.Fragment>
  );
};
