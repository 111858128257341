import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import { GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getInvestorOneDebtorSupplierRoleByGuid';
import SCFApi from 'entities/SCF/Supplier/api';

function* getInvestorOneDebtorSupplierRoleByGuid(action: {
  type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getInvestorOneDebtorSupplierRoleByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getInvestorOneDebtorSupplierRoleByGuidSaga() {
  yield takeLatest(
    GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.REQ,
    getInvestorOneDebtorSupplierRoleByGuid
  );
}
