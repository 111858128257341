import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/Client/model/actions/getCompanyClient';

export interface GetCompanyClientStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_COMPANY_CLIENT {
  REQ = 'GET_COMPANY_CLIENT_REQ',
  GOT = 'GET_COMPANY_CLIENT_GOT',
  ERR = 'GET_COMPANY_CLIENT_ERR'
}

export const initialState: GetCompanyClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getCompanyClient = (
  state: GetCompanyClientStoreState = initialState,
  action: {
    type: GET_COMPANY_CLIENT;
    data: ResponseData;
    error: {};
  }
): GetCompanyClientStoreState => {
  switch (action.type) {
    case GET_COMPANY_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_CLIENT.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_COMPANY_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyClient;
