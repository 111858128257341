import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from './../actions/getAllDiscountAuctionsBySupplier';

export interface GetAllDiscountAuctionsBySupplierStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER {
  REQ = 'GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER_REQ',
  GOT = 'GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER_GOT',
  ERR = 'GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER_ERR'
}

const initialState: GetAllDiscountAuctionsBySupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getAllDiscountAuctionsBySupplier = (
  state: GetAllDiscountAuctionsBySupplierStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetAllDiscountAuctionsBySupplierStoreState => {
  switch (action.type) {
    case GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case GET_ALL_DISCOUNT_AUCTIONS_BY_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getAllDiscountAuctionsBySupplier;
