import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getEarlyPaymentApplicationsDebtorRole';
import { GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/getEarlyPaymentApplicationsDebtorRole';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* getEarlyPaymentApplicationsDebtorRole(action: {
  type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getEarlyPaymentApplicationsDebtorRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getEarlyPaymentApplicationsDebtorRoleSaga() {
  yield takeLatest(
    GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.REQ,
    getEarlyPaymentApplicationsDebtorRole
  );
}
