import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getExternalApiGIR_BO';
import { GET_EXTERNAL_API_GIR_BO } from 'Application/reducers/getExternalApiGIR_BO';
import { ResponseData } from 'Application/actions/getExternalApiGIR_BO';

import ApplicationApi from 'Application/api';

function* getExternalApiGIR_BO(action: {
  type: GET_EXTERNAL_API_GIR_BO;
  inn: string;
  year: number;
}) {
  try {
    const res: ResponseData = yield call(
      ApplicationApi.getExternalApiGIR_BO,
      action.inn,
      action.year
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getExternalApiGIR_BOSaga() {
  yield takeLatest(GET_EXTERNAL_API_GIR_BO.REQ, getExternalApiGIR_BO);
}
