import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postFinancialStatementsAll';

import { POST_FINANCIAL_STATEMENTS_ALL } from '../reducers/postFinancialStatementsAll';
import FinancialStatementsApi from '../api';

function* postFinancialStatementsAll(action: {
  type: POST_FINANCIAL_STATEMENTS_ALL;
  data;
}): Generator {
  try {
    yield call(FinancialStatementsApi.postFinancialStatementsAll, action.data);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFinancialStatementsAllSaga(): Generator {
  yield takeLatest(
    POST_FINANCIAL_STATEMENTS_ALL.REQ,
    postFinancialStatementsAll
  );
}
