import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getFactoringConnectionsFactorRole';
import { GET_FACTORING_CONNECTION_FACTOR_ROLE } from 'entities/SCF/Factor/model/reducers/getFactoringConnectionsFactorRole';

import SCFApi from 'entities/SCF/Factor/api';

function* getFactoringConnectionsFactorRole(action: {
  type: GET_FACTORING_CONNECTION_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getFactoringConnectionsFactorRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringConnectionsFactorRoleSaga() {
  yield takeLatest(
    GET_FACTORING_CONNECTION_FACTOR_ROLE.GET_FACTORING_CONNECTION_FACTOR_ROLE_REQ,
    getFactoringConnectionsFactorRole
  );
}
