import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'shared/utils/ApiUploadFiles';
import { RequestData as getCompaniesOfUserReq } from 'entities/Cabinet/Client/model/actions/getCompaniesOfUser';
import { RequestData as ReqCompanyClient } from 'entities/Cabinet/Client/model/actions/patchCompanyClient';

export default class ClientApi {
  static getCompaniesOfUser(data: getCompaniesOfUserReq) {
    return ApiRequest('/api/companies_of_users/client_role/all', 'get', data);
  }

  static postNewCompanies(companyInn: string) {
    return ApiRequest('/api/companies_of_users/client_role/add', 'post', {
      companyInn
    });
  }

  static getOpenLoanLimits(inn: string) {
    return ApiRequest(`/api/loan_limit/applicant/company/${inn}`);
  }

  static getFinancialDocuments(inn: string) {
    return ApiRequest(`/api/financial_document/client_role/${inn}`);
  }

  static getClientApplication(id: string) {
    return ApiRequest(`/api/application/client_role/${id}`);
  }

  static patchCompanyClient(inn: string, data: ReqCompanyClient) {
    return ApiRequest(`/api/company/client_role/${inn}`, 'patch', data);
  }

  static getCompanyClient(inn: string) {
    return ApiRequest(`/api/company/client_role/inn/${inn}`);
  }

  static getTenderByClient(id: string) {
    return ApiRequest(
      `/api/tenders_of_application/client_role/application/${id}`
    );
  }

  static postTenderByClient(data: any) {
    return ApiRequest(`/api/tender/client_role/new`, 'post', data);
  }

  static postAddTenderByEruzClient(id: string, eruzId: string) {
    return ApiRequest(
      `/api/application/client_role/${id}/add_tender_by_eruz/${eruzId}`,
      'post'
    );
  }

  static postUploadFinDocClient(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/financial_document/client_role/${guid}/upload_file`,
      data
    );
  }

  static postUploadNonFinDocClient(guid: string, data: FormData) {
    return ApiUploadFiles(
      `/api/nonfinancial_document/client_role/${guid}/upload_file`,
      data
    );
  }

  static getDocumentListClient(guid: string) {
    return ApiRequest(`/api/document_list/client_role/application/${guid}`);
  }

  static postDocumentListClient(guid: string) {
    return ApiRequest(
      `/api/document_list/client_role/application/${guid}`,
      'post'
    );
  }
}
