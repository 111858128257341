import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PutEarlyPaymentApplicationDeclineStoreState extends Reducer {
  data: any[];
}

export enum PUT_EARLY_PAYMENT_APPLICATION_DECLINE {
  REQ = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_REQ',
  GOT = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_GOT',
  ERR = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_ERR',
  RESET = 'PUT_EARLY_PAYMENT_APPLICATION_DECLINE_RESET'
}

export interface RequestDataType {
  guid: string | string[];
  body: {
    rejectReason: string;
  };
}

export const initialState: PutEarlyPaymentApplicationDeclineStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const putEarlyPaymentApplicationDecline = (
  state: PutEarlyPaymentApplicationDeclineStoreState = initialState,
  action: {
    type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE;
    data: { success: boolean }[];
    error: {};
  }
): PutEarlyPaymentApplicationDeclineStoreState => {
  switch (action.type) {
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PUT_EARLY_PAYMENT_APPLICATION_DECLINE.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default putEarlyPaymentApplicationDecline;
