import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format } from 'date-fns';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getEarlyPaymentByGuid,
  ResponseData
} from 'src/features/EarlyPaymentApplications/actions/getEarlyPaymentByGuid';
import {
  req as getMonetaryClaim,
  ResponseData as ResMonetaryClaim
} from 'entities/SCF/Internal/model/actions/getMonetaryClaim';
import {
  BackBtn,
  ConfirmSection,
  ConfirmSectionRightPart,
  DiscountItem,
  DocumentSection,
  DownloadLink,
  Header,
  InvoiceDocumentDate,
  NoDocument,
  Status,
  StatusList,
  TDInvoiceDocumenContainer,
  Tooltip
} from './styled';
import { Loader } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TdCentred } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderCellCenteredStyled } from 'src/features/SCF/UI/Table/styles';
import SCFInternalApi from 'entities/SCF/Internal/api';

interface StateToProps {
  payment: ResponseData;
  status: REQUEST_STATUSES;
  monetaryClaim: ResMonetaryClaim[];
  statusMonetaryClaim: REQUEST_STATUSES;
}

interface DispatchToProps {
  getEarlyPaymentByGuid: (guid: string) => void;
  getMonetaryClaim: (guid: string) => void;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const statusMessages = {
  0: 'Поставщик еще не выбрал дату оплаты и кредитора',
  4: 'Поставщик еще не выбрал подписанта',
  6: 'Поставщик еще не согласился с условиями расчета',
  8: 'Поставщик еще не подписал соглашение',
  12: 'Дебитор еще не подтвердил и не подписал соглашение',
  13: 'Дебитор подтвердил, но еще не подписал соглашение',
  14: 'Отказ дебитора',
  16: 'Сделка заключена'
};

const stagesByStatus = {
  0: [
    'Поставщик еще не выбрал дату оплаты и кредитора',
    'Поставщик еще не выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  4: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик еще не выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  6: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик еще не согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  8: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик еще не подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  12: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик подписал соглашение',
    'Дебитор еще не подтвердил и не подписал соглашение',
    'Дебитор подтвердил, но еще не подписал соглашение'
  ],
  13: [
    'Поставщик выбрал дату оплаты и кредитора',
    'Поставщик выбрал подписанта',
    'Поставщик согласился с условиями расчета',
    'Поставщик подписал соглашение',
    'Дебитор подтвердил соглашение',
    'Дебитор еще не подписал соглашение'
  ]
};

const getStageStyle = (status: number, index: number) => {
  if (status === 0 && index > 0) {
    return { color: '#9FA6B2' };
  } else if (status === 4 && index > 1) {
    return { color: '#9FA6B2' };
  } else if (status === 6 && index > 2) {
    return { color: '#9FA6B2' };
  } else if (status === 8 && index > 3) {
    return { color: '#9FA6B2' };
  } else if (status === 12 && index > 4) {
    return { color: '#9FA6B2' };
  } else if (status === 13 && index > 5) {
    return { color: '#9FA6B2' };
  }

  // Зачёркивание всех завершённых этапов
  if (
    (status === 4 && index === 0) ||
    (status === 6 && index <= 1) ||
    (status === 8 && index <= 2) ||
    (status === 12 && index <= 3) ||
    (status === 13 && index <= 4)
  ) {
    return { textDecoration: 'line-through' };
  }

  return {};
};

const EarlyPaymentApplicationView: React.FC<Props> = ({
  getEarlyPaymentByGuid,
  payment,
  status,
  getMonetaryClaim,
  monetaryClaim,
  statusMonetaryClaim,
  history,
  match
}) => {
  const [hoveredStatus, setHoveredStatus] = React.useState(null);

  React.useEffect(
    () => {
      if (match.params.guid) {
        getEarlyPaymentByGuid(match.params.guid);
        getMonetaryClaim(match.params.guid);
      }
    },
    [match.params.guid]
  );

  React.useEffect(() => {
    const base64ToBlob = (base64, type = 'application/octet-stream') => {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    };

    const arrayBufferToBase64 = buffer => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    fetch(
      SCFInternalApi.getEarlyPaymentFile(match.params.guid, 'agreement_pdf')
    )
      .then(res => res.arrayBuffer())
      .then(data => {
        var base64Str = arrayBufferToBase64(data);
        const blob = base64ToBlob(base64Str, 'application/pdf');
        const url = URL.createObjectURL(blob);
        var outputObject = document.createElement('iframe');
        var outputText = document.createElement('p');
        outputText.style.margin = '30px 0 8px 0';
        outputText.style.fontFamily = 'Roboto-Regular';
        outputText.innerHTML = '* Превью договора';
        outputObject.src = url + '#toolbar=0';
        outputObject.style.width = '1200px';
        outputObject.style.height = '1000px';
        outputObject.style.background = '#262B41';
        document.getElementById('documentObject').appendChild(outputText);
        document.getElementById('documentObject').appendChild(outputObject);
      });
  }, []);

  const onBackClick = () => history.goBack();

  const handleMouseEnter = (status: number) => {
    if ([0, 4, 6, 8, 12, 13].includes(status)) {
      setHoveredStatus(status);
    }
  };

  const handleMouseLeave = () => {
    setHoveredStatus(null);
  };

  const DiscountCounter = () => {
    let discountCounter: number;
    discountCounter = 0;
    for (let i = 0; i < monetaryClaim.length; i++) {
      discountCounter += monetaryClaim[i].baseFullSumm;
    }
    return discountCounter;
  };

  const DiscountCounterToGet = () => {
    let discountCounterToGet: number;
    discountCounterToGet = 0;
    for (let i = 0; i < monetaryClaim.length; i++) {
      discountCounterToGet += summToGet(i);
    }
    return discountCounterToGet;
  };

  const index = (): number => {
    if (monetaryClaim[0].baseSumm === 0) {
      return 1;
    }
    return 1.2;
  };

  const intermediateDiscount = (): number => {
    if (monetaryClaim[0].baseSumm > 0) {
      return Math.round(payment.baseDiscountRate * 1.2 * 1000) / 1000;
    }
    return Math.round(payment.baseDiscountRate * 1000) / 1000;
  };

  const fullRate = (i: number): number => {
    return (
      Math.round(
        intermediateDiscount() *
          (differenceInDays(
            new Date(monetaryClaim[i].maturityDate),
            new Date(payment.payDate)
          ) /
            365) *
          10000
      ) /
      10000 /
      100
    );
  };

  const summToGet = (i: number): number => {
    return monetaryClaim[i].baseFullSumm * (1 - fullRate(i));
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <Header>
          <div>
            <div>
              <h2>Заявка #{payment.guid.substring(payment.guid.length - 6)}</h2>
              <Status
                status={payment.status}
                onMouseEnter={() => handleMouseEnter(payment.status)}
                onMouseLeave={handleMouseLeave}
              >
                <p>{statusMessages[payment.status]}</p>

                <Tooltip isVisible={hoveredStatus === payment.status}>
                  <StatusList>
                    {stagesByStatus[payment.status] &&
                      stagesByStatus[payment.status].map(
                        (stage: string, index: number) => (
                          <li
                            key={index}
                            style={getStageStyle(payment.status, index)}
                          >
                            {stage}
                          </li>
                        )
                      )}
                  </StatusList>
                </Tooltip>
              </Status>
            </div>

            <BackBtn onClick={onBackClick}>Назад</BackBtn>
          </div>

          <div>
            <div>
              <div>Покупатель</div>
              <div>{payment.debtorName}</div>
            </div>
            <div>
              <div>Поставщик</div>
              <div>{payment.supplierName}</div>
            </div>
            {payment.payerType === 2 && (
              <div>
                <div>Фактор</div>
                <div>{payment.payerName}</div>
              </div>
            )}
          </div>
        </Header>
      )}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableHeaderCellCenteredStyled width="6%">
                ID
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="18%">
                СУММА
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="12%">
                ДОКУМЕНТЫ
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="12%">
                ДАТА ОПЛАТЫ
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="16%">
                СРОК ДИСКОНТА ДН.
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled
                dTableHeaderCellCenteredStyled
                width="16%"
                style={{ paddingTop: '14px' }}
              >
                СТАВКА ДИСКОНТА
                <br />
                %годовых
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="12%">
                ДИСКОНТ
              </TableHeaderCellCenteredStyled>
              <TableHeaderCellCenteredStyled width="18%">
                СУММА К ПОЛУЧЕНИЮ
              </TableHeaderCellCenteredStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {monetaryClaim.map((item, key) => (
              <TableRowStyled key={key}>
                <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                  {item.guid.substring(item.guid.length - 5)}
                </TdCentred>

                <TdCentred>
                  {item.baseFullSumm.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </TdCentred>
                <TDInvoiceDocumenContainer>
                  <div>
                    <div>
                      <p>
                        {item.invoiceDocumentName} {item.invoiceDocumentNumber}
                      </p>
                    </div>
                    <InvoiceDocumentDate>
                      <p>
                        {new Date(
                          item.invoiceDocumentDate
                        ).toLocaleDateString()}
                      </p>
                      {item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <p
                          // onMouseEnter={() =>
                          //   getByMonetaryClaimHint(item.guid)
                          // }
                          // onMouseLeave={resetByMonetaryClaimHint}
                          >
                            и ещё {item.invoiceDocsQty - 1}
                          </p>
                        )}
                    </InvoiceDocumentDate>
                  </div>
                  {/* {!!monetaryClaimsHint &&
                    item.invoiceDocsQty &&
                    item.invoiceDocsQty > 1 && (
                      <DocumentHint>
                        {monetaryClaimsHint.map(hint => (
                          <div key={hint.guid}>
                            <div>
                              <div>
                                {statusMonetaryClaimsHint ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'140px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.GOT &&
                                  `${hint.documentName} ${hint.documentNumber}`
                                )}
                              </div>
                              <div>
                                {statusMonetaryClaimsHint ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'120px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.GOT &&
                                  `${new Date(
                                    hint.documentDate
                                  ).toLocaleDateString()}`
                                )}
                              </div>
                            </div>
                            <div>
                              {statusMonetaryClaimsHint ===
                              REQUEST_STATUSES.REQUEST ? (
                                <SkeletonWrapper
                                  width={'120px'}
                                  height={'20px'}
                                />
                              ) : (
                                statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.GOT &&
                                `${formSumStringThousands(hint.baseFullSumm)}`
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <p>ИТОГО</p>{' '}
                          <p>{formSumStringThousands(item.baseFullSumm)}</p>
                        </div>
                      </DocumentHint>
                    )} */}
                </TDInvoiceDocumenContainer>
                <TdCentred>
                  <p
                    style={{
                      textDecoration: 'line-through',
                      color: '#9FA6B2',
                      fontSize: '12px',
                      textAlign: 'center',
                      margin: '0',
                      width: 'auto'
                    }}
                  >
                    {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                  </p>
                  {format(new Date(payment.payDate), 'dd.MM.yyyy')}
                </TdCentred>
                <TdCentred>
                  {differenceInDays(
                    new Date(item.maturityDate),
                    new Date(payment.payDate)
                  )}
                </TdCentred>
                {item.baseVatSumm !== 0 && item.baseVatSumm !== null ? (
                  <TdCentred>
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px'
                      }}
                    >
                      {payment.baseDiscountRate.toFixed(3)}%{' '}
                      <p
                        style={{
                          color: '#9FA6B2',
                          fontSize: '14px',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        без НДС
                      </p>
                    </p>
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px'
                      }}
                    >
                      {(payment.baseDiscountRate * index()).toFixed(3)}%{' '}
                      <p
                        style={{
                          color: '#9FA6B2',
                          fontSize: '14px',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        с НДС
                      </p>
                    </p>
                  </TdCentred>
                ) : (
                  <TdCentred>{payment.baseDiscountRate.toFixed(3)} %</TdCentred>
                )}
                <TdCentred>
                  {(fullRate(key) * 100).toLocaleString('ru', {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4
                  })}
                  %
                </TdCentred>
                <TdCentred>
                  {summToGet(key).toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </TdCentred>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      {statusMonetaryClaim == REQUEST_STATUSES.GOT &&
        status === REQUEST_STATUSES.GOT && (
          <ConfirmSection>
            <ConfirmSectionRightPart>
              <DiscountItem>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  Сумма требований без дисконта
                </p>
                <p
                  className="discount-1"
                  id="discount-1"
                  style={{ color: '#9FA6B2', fontSize: '14px' }}
                >
                  {DiscountCounter().toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                  руб.
                </p>
              </DiscountItem>
              <DiscountItem>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  Дисконт за раннюю оплату
                </p>
                <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                  {(DiscountCounter() - DiscountCounterToGet()).toLocaleString(
                    'ru',
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )}{' '}
                  руб.
                </p>
              </DiscountItem>
              <DiscountItem>
                <p style={{ fontSize: '16px' }}>Сумма к получению</p>
                <p style={{ fontSize: '16px' }}>
                  {DiscountCounterToGet().toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                  руб.
                </p>
              </DiscountItem>
            </ConfirmSectionRightPart>
          </ConfirmSection>
        )}

      {status === REQUEST_STATUSES.GOT && (
        <DocumentSection>
          {payment.archiveFileIdentifyer ? (
            <DownloadLink
              href={SCFInternalApi.getEarlyPaymentFile(
                match.params.guid,
                'archive'
              )}
            >
              <p>Скачать архив</p>
            </DownloadLink>
          ) : (
            <NoDocument>Архив еще не сформирован</NoDocument>
          )}

          {payment.agreementFileIdentifier ? (
            <DownloadLink
              href={SCFInternalApi.getEarlyPaymentFile(
                match.params.guid,
                'agreement'
              )}
            >
              <p>Скачать документ</p>
            </DownloadLink>
          ) : (
            <NoDocument>Документ еще не сформирован</NoDocument>
          )}

          <div>
            {payment.supplierSignatureFileIdentifier ? (
              <DownloadLink
                href={SCFInternalApi.getEarlyPaymentFile(
                  match.params.guid,
                  'supplier_signature'
                )}
              >
                <p>Скачать подпись поставщика</p>
              </DownloadLink>
            ) : (
              <NoDocument>Поставщик еще не подписал</NoDocument>
            )}

            {payment.supplierProtocolFileIdentifyer ? (
              <DownloadLink
                href={SCFInternalApi.getEarlyPaymentFile(
                  match.params.guid,
                  'supplier_protocol'
                )}
              >
                <p>Скачать протокол поставщика</p>
              </DownloadLink>
            ) : (
              <NoDocument>Протокол поставщика не сформирован</NoDocument>
            )}
          </div>

          <div>
            {payment.debtorSignatureFileIdentifier ? (
              <DownloadLink
                href={SCFInternalApi.getEarlyPaymentFile(
                  match.params.guid,
                  'debtor_signature'
                )}
              >
                <p>Скачать подпись покупателя</p>
              </DownloadLink>
            ) : (
              <NoDocument>Покупатель еще не подписал</NoDocument>
            )}

            {payment.debtorProtocolFileIdentifyer ? (
              <DownloadLink
                href={SCFInternalApi.getEarlyPaymentFile(
                  match.params.guid,
                  'debtor_protocol'
                )}
              >
                <p>Скачать протокол покупателя</p>
              </DownloadLink>
            ) : (
              <NoDocument>Протокол покупателя не сформирован</NoDocument>
            )}
          </div>
        </DocumentSection>
      )}

      <div id="documentObject" style={{ width: '900px', height: '1000px' }} />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCFInternal, EarlyPayment }: STORE) => ({
  payment: EarlyPayment.getEarlyPaymentByGuid.data,
  status: EarlyPayment.getEarlyPaymentByGuid.status,
  monetaryClaim: SCFInternal.getMonetaryClaimOne.data,
  statusMonetaryClaim: SCFInternal.getMonetaryClaimOne.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getEarlyPaymentByGuid, getMonetaryClaim }, dispatch);

const EarlyPaymentApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EarlyPaymentApplicationView)
);

export { EarlyPaymentApplicationViewConnect as EarlyPaymentApplicationView };
