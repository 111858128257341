import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getEarlyPaymentByGuid';

import { GET_EARLY_PAYMENT_BY_GUID } from '../reducers/getEarlyPaymentByGuid';
import DocumentListApi from '../api';

function* getEarlyPaymentByGuid(action: {
  type: GET_EARLY_PAYMENT_BY_GUID;
  guid: string;
}): Generator {
  try {
    const res: any = yield call(
      DocumentListApi.getEarlyPaymentByGuid,
      action.guid
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getEarlyPaymentByGuidSaga(): Generator {
  yield takeLatest(GET_EARLY_PAYMENT_BY_GUID.REQ, getEarlyPaymentByGuid);
}
