import { START_RATE_DEBTOR_ROLE } from 'entities/SCF/Debtor/model/reducers/getStartRateDebtorRole';

export interface ResponseDataType {
  regData: number;
  bankAccountsData: number;
  certificateData: number;
  subscriptionsData: number;
}

export const req = () => ({ type: START_RATE_DEBTOR_ROLE.REQ });
export const got = (data: ResponseDataType) => ({
  type: START_RATE_DEBTOR_ROLE.GOT,
  data
});
export const err = (error: {}) => ({
  type: START_RATE_DEBTOR_ROLE.ERR,
  error
});
