import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { req as postSendCompanyToScoring } from 'src/features/Leads/actions/postSendCompanyToScoring';
import { ResponseData as OpenLoanLimitsData } from 'Application/actions/getOpenLoanLimits';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { UploadButton, Spinner as SpinnerApi } from './styled';
import { RouteComponentProps, withRouter } from 'react-router';

interface AnyProps {
  openLoanLimits: OpenLoanLimitsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface StateToProps {
  statusCompanyToScoring: REQUEST_STATUSES;
}

interface DispatchToProps {
  postSendCompanyToScoring: (inn: string) => void;
}

interface MatchProps {
  inn: string;
}

type Props = RouteComponentProps<MatchProps> &
  StateToProps &
  DispatchToProps &
  AnyProps;

class OpenLoanLimits extends React.Component<Props> {
  onProductClick(id: number) {
    window.open(`/crm/products/${id}`, '_blank');
  }

  sendCompanyToScoring = () => {
    this.props.postSendCompanyToScoring(this.props.match.params.inn);
  };

  render() {
    const {
      openLoanLimits,
      status,
      error,
      statusCompanyToScoring
    } = this.props;
    return (
      <ScrollTopComponent>
        <UploadButton
          disabled={statusCompanyToScoring === REQUEST_STATUSES.REQUEST}
          onClick={this.sendCompanyToScoring}
        >
          <p>Обновить скоринг Альфа-Банка</p>
          {statusCompanyToScoring === REQUEST_STATUSES.REQUEST && (
            <SpinnerApi icon={faSpinner} />
          )}
        </UploadButton>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="10%">Банк</TableThStyled>
                  <TableThStyled width="10%">Продукт</TableThStyled>
                  <TableThStyled width="5%">Лимит</TableThStyled>
                  <TableThStyled width="7%">Срок, мес.</TableThStyled>
                  <TableThStyled width="7%">Ставка</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {openLoanLimits.items.map((limit, key) => (
                  <TableRowStyled
                    onClick={() => this.onProductClick(limit.productId)}
                    key={key}
                  >
                    <td>{limit.bankShortName}</td>
                    <td>{limit.productName}</td>
                    <td>
                      {limit.volume === null
                        ? 'Неизв.'
                        : formSumStringThousands(limit.volume)}
                    </td>
                    <td>
                      {limit.term === null ? 'Неизв.' : `До ${limit.term} мес.`}
                    </td>
                    <td>
                      {limit.rate === null
                        ? 'Неизв.'
                        : `${limit.rate}% годовых`}
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={openLoanLimits} />
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Leads }: STORE) => ({
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ postSendCompanyToScoring }, dispatch);

const OpenLoanLimitsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(OpenLoanLimits)
);

export { OpenLoanLimitsConnect as OpenLoanLimits };
