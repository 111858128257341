import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Hint, IHint } from 'shared/ui/Hint';
import { Error } from 'shared/ui/Error';
import { Label } from 'shared/ui/Label';

import {
  InputContainerStyled,
  InputWrapperStyled,
  InputStyled,
  WarningMessage
} from './styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type InputType = 'text' | 'password' | 'tel';

export interface Props {
  className?: string;
  value: string | number;
  name: string;
  type?: InputType;
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  autoComplete?: string;
  mask?: any[] | (() => void);
  maxLength?: number;
  icon?: IconProp;
  renderPasswordToggleIcon?: (
    focusInput: (v: boolean) => void,
    isFocused: boolean
  ) => JSX.Element | React.ReactNode;
  renderHintToggleIcon?: (
    focusInput: (v: boolean) => void,
    isFocused: boolean
  ) => JSX.Element | React.ReactNode;
  renderRangeSlider?: JSX.Element | React.ReactNode;
  hint?: IHint;
  warning?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface InputState {
  isFocused: boolean;
}

interface InputRef {
  current: HTMLElement;
}

const defaultProps = {
  maxLength: '200',
  type: 'text',
  autoComplete: 'off'
};

export class Input extends React.Component<Props, InputState> {
  static defaultProps = defaultProps;
  input: InputRef = React.createRef();

  state = {
    isFocused: false
  };

  onKeyPress = (e: KeyboardEvent) => {
    const { onKeyPress } = this.props;
    onKeyPress && onKeyPress(e);
  };

  focusInput = isFocused => {
    this.setState({ isFocused: true });
    this.input.current.focus();
  };

  onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true });
    onFocus && onFocus(e);
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    this.setState({ isFocused: false });
    onBlur && onBlur(e);
  };

  render() {
    const {
      className,
      value,
      name,
      type,
      placeholder,
      label,
      required,
      disabled,
      error,
      autoComplete,
      mask,
      maxLength,
      renderPasswordToggleIcon,
      renderHintToggleIcon,
      renderRangeSlider,
      hint,
      warning,
      icon,
      onChange
    } = this.props;

    const { isFocused } = this.state;

    const sharedInputProps = {
      ref: this.input,
      value,
      name,
      type,
      placeholder,
      maxLength,
      disabled,
      isFocused,
      autoComplete,
      onChange,
      isError: !!error,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyPress: this.onKeyPress
    };

    return (
      <InputContainerStyled className={className}>
        <Label name={name} label={label} required={required} />

        <InputWrapperStyled isLabel={!!label}>
          {/* Input Hint - div with input's data explanation */}
          {hint &&
            (hint.validatable ? (
              !!error && <Hint {...hint} />
            ) : (
              <Hint {...hint} />
            ))}

          {!!mask ? (
            <MaskedInput
              {...sharedInputProps}
              mask={mask}
              render={(ref, props) => <InputStyled ref={ref} {...props} />}
            />
          ) : (
            <>
              <InputStyled {...sharedInputProps} />
              {!!warning && <WarningMessage>{warning}</WarningMessage>}
            </>
          )}

          {icon && <FontAwesomeIcon icon={icon} />}

          {/* InputPassword Component feature */}
          {!!renderPasswordToggleIcon &&
            renderPasswordToggleIcon(this.focusInput, isFocused)}

          {/* InputHint Component feature */}
          {!!renderHintToggleIcon &&
            renderHintToggleIcon(this.focusInput, isFocused)}

          {/* InputRange Component feature */}
          {!!renderRangeSlider && renderRangeSlider}
        </InputWrapperStyled>

        <Error error={error} />
      </InputContainerStyled>
    );
  }
}
