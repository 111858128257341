import styled from 'styled-components';
import { rr, blue } from 'shared/styled';

export const RegistrySettingsPageStyled = styled.section`
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const Block = styled.div`
  width: 1050px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  h2 {
    font-size: 18px;
    font-family: ${rr};
    margin-bottom: 12px;
  }
`;

export const BlockContainer = styled.div`
  width: 1000px;
  margin-left: 50px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    padding: 10px 10px;
    height: 30px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
    text-align: center;
    box-sizing: border-box;
  }
`;

export const TitleContainer = styled.div`
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NoSelectTitle = styled.p`
  width: auto;
  height: auto;
  margin-left: 40px;
  font-family: ${rr};
  margin-right: 10px;
`;

export const SelectTitle = styled.p`
  width: auto;
  height: auto;
  font-family: ${rr};
`;

export const RightTitle = styled.p`
  width: auto;
  height: auto;
  font-family: ${rr};
  margin-left: 70px;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 17px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${blue};
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 17px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  transition: 0.3s;
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #d9d9d9;
`;

export const PresenseContainer = styled.div`
  width: 270px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    width: auto;
    height: auto;
    font-family: ${rr};
  }
`;

export const ColumnContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: auto;
    height: auto;
    font-family: ${rr};
    margin-right: 10px;
  }
`;
