import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Loader } from 'src/features/Layouts/components';
import { PageStyled } from 'src/features/SCF/UI/ScfStyles';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import {
  req as putMonetaryClaimDeleteToAuction,
  reset as resetMonetaryClaimDeleteToAuction
} from 'src/features/SCF/actions/putMonetaryClaimDeleteToAuction';
import {
  req as getMonetaryClaims,
  reset as resetMonetaryClaims,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimHint,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { ErrorDeleteDiscount, ContainerFilter } from './styled';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { InnPlaceholders } from 'shared/constants';
import MonetaryClaims from '../Suppliers/SupplierInfo/Tabs/MonetaryClaims/MonetaryClaims';
import { StatusTypes } from './types';
import { Modal } from 'src/features/Common';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { InputDate } from 'shared/ui/InputDate';
import { format, parse } from 'date-fns';

interface StateToProps {
  monetaryClaims: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsHint: ResponseMonetaryClaimHint[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
  statusDeleteAuction: REQUEST_STATUSES;
  errorDeleteAuction: ResponseError;
}

interface DispatchToProps {
  getMonetaryClaims: (data: RequestDataType) => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetMonetaryClaims: () => void;
  resetByMonetaryClaimHint: () => void;
  putMonetaryClaimDeleteToAuction: (guid: string, auction_guid: string) => void;
  resetMonetaryClaimDeleteToAuction: () => void;
  openModal: (data: OpenModalDataType) => void;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

const SuppliesList: React.FC<Props> = ({
  getMonetaryClaims,
  monetaryClaims,
  status,
  getByMonetaryClaimHint,
  resetMonetaryClaims,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  putMonetaryClaimDeleteToAuction,
  resetMonetaryClaimDeleteToAuction,
  statusDeleteAuction,
  errorDeleteAuction,
  openModal,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [supplierStatus, setSupplierStatus] = React.useState('');
  const [minBaseSum, setMinBaseSum] = React.useState('');
  const [maxBaseSum, setMaxBaseSum] = React.useState('');
  const [minMaturityDate, setMinMaturityDate] = React.useState('');
  const [maxMaturityDate, setMaxMaturityDate] = React.useState('');

  const [isSummSort, setIsSummSort] = React.useState(null);
  const [isMaturityDateSort, setIsMaturityDateSort] = React.useState(null);

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const supplierInn = searchParams.get('supplierInn') || '';
      const supplierStatus = searchParams.get('supplierStatus') || '';
      const minBaseSum = searchParams.get('minBaseSum') || '';
      const maxBaseSum = searchParams.get('maxBaseSum') || '';
      const minMaturityDate = searchParams.get('minMaturityDate') || '';
      const maxMaturityDate = searchParams.get('maxMaturityDate') || '';

      setSupplierInn(supplierInn);
      setSupplierStatus(supplierStatus);
      setMinBaseSum(minBaseSum);
      setMaxBaseSum(maxBaseSum);
      setMinMaturityDate(minMaturityDate);
      setMaxMaturityDate(maxMaturityDate);

      getMonetaryClaims({
        page,
        pageSize: 20,
        ...(supplierInn && { supplierInn }),
        ...(supplierStatus && { status: supplierStatus }),
        ...(minBaseSum && { minBaseSum }),
        ...(maxBaseSum && { maxBaseSum }),
        ...(minMaturityDate && {
          minMaturityDate: format(
            parse(minMaturityDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        }),
        ...(maxMaturityDate && {
          maxMaturityDate: format(
            parse(maxMaturityDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        }),
        ...((isSummSort !== null || isMaturityDateSort !== null) && {
          sortBy:
            isSummSort === true
              ? 'summDesc'
              : isSummSort === false
                ? 'summAsc'
                : isMaturityDateSort === true
                  ? 'maturityDateDesc'
                  : isMaturityDateSort === false && 'maturityDateAsc'
        })
      });

      return () => {
        resetMonetaryClaims();
        resetMonetaryClaimDeleteToAuction();
      };
    },
    [isSummSort, isMaturityDateSort, , history.location.search]
  );

  React.useEffect(
    () => {
      if (statusDeleteAuction === REQUEST_STATUSES.GOT) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;
        const supplierInn = searchParams.get('supplierInn') || '';
        const supplierStatus = searchParams.get('supplierStatus') || '';

        setSupplierInn(supplierInn);
        setSupplierStatus(supplierStatus);

        getMonetaryClaims({
          page,
          pageSize: 20,
          ...(supplierInn && { supplierInn }),
          ...(supplierStatus && { status: supplierStatus })
        });
      }
      if (statusDeleteAuction === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_DELETE_AUCTION });
      }
    },
    [statusDeleteAuction]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const onDateChange = (name: string, date: string) => {
    const searchParams = new URLSearchParams(history.location.search);

    if (name === 'minMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else if (name === 'maxMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <PageStyled>
      <ApplicationTitle>Поставки</ApplicationTitle>

      <ContainerFilter>
        <Input
          label="ИНН Поставщика"
          value={supplierInn}
          name="supplierInn"
          placeholder={InnPlaceholders.entity}
          onChange={onFilterChange}
        />

        <Select
          options={Object.keys(StatusTypes).map(type => ({
            id: type,
            name: StatusTypes[type]
          }))}
          label="Статус"
          placeholder="Показать все"
          name="supplierStatus"
          value={supplierStatus}
          onChange={onFilterChange}
        />

        <Input
          value={minBaseSum}
          label="Сумма денежного требования"
          name="minBaseSum"
          onChange={onFilterChange}
        />
        <span>-</span>
        <Input value={maxBaseSum} name="maxBaseSum" onChange={onFilterChange} />
        <InputDate
          dateValue={minMaturityDate}
          label="Дата плановой оплаты"
          name="minMaturityDate"
          onDateChange={date => onDateChange('minMaturityDate', date)}
        />
        <span>-</span>
        <InputDate
          dateValue={maxMaturityDate}
          name="maxMaturityDate"
          onDateChange={date => onDateChange('maxMaturityDate', date)}
        />
      </ContainerFilter>

      {!!Object.keys(errorDeleteAuction).length && (
        <Modal name={MODAL_NAMES.ERROR_DELETE_AUCTION}>
          <ErrorDeleteDiscount>
            <div>Ошибка: {errorDeleteAuction.code}</div>
            <div>{errorDeleteAuction.body}</div>
          </ErrorDeleteDiscount>
        </Modal>
      )}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <MonetaryClaims
          monetaryClaims={monetaryClaims}
          status={status}
          type={['scf', 'debtor']}
          putMonetaryClaimDeleteToAuction={putMonetaryClaimDeleteToAuction}
          getByMonetaryClaimHint={getByMonetaryClaimHint}
          resetByMonetaryClaimHint={resetByMonetaryClaimHint}
          monetaryClaimsHint={monetaryClaimsHint}
          statusMonetaryClaimsHint={statusMonetaryClaimsHint}
          isSummSort={isSummSort}
          handleSortedSumm={setIsSummSort}
          isMaturityDate={isMaturityDateSort}
          handleSortedMaturityDate={setIsMaturityDateSort}
          noData={{
            title: 'ПОСТАВКИ НЕ НАЙДЕНЫ',
            text: 'поставки от этого поставщика не найдены'
          }}
        />
      )}
    </PageStyled>
  );
};

const mapStateToProps = ({ SCF, SCFDebtor }: STORE) => ({
  monetaryClaims: SCFDebtor.getMonetaryClaimsSuppliesRole.data,
  status: SCFDebtor.getMonetaryClaimsSuppliesRole.status,
  error: SCFDebtor.getMonetaryClaimsSuppliesRole.error,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status,
  statusDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.status,
  errorDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMonetaryClaims,
      getByMonetaryClaimHint,
      resetMonetaryClaims,
      resetByMonetaryClaimHint,
      putMonetaryClaimDeleteToAuction,
      resetMonetaryClaimDeleteToAuction,
      openModal
    },
    dispatch
  );

const SuppliesListConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SuppliesList)
);

export { SuppliesListConnect as SuppliesList };
