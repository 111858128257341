import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';

import {
  req as getMySuppliers,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getMySuppliers';
import { req as postAddSupplier } from 'src/features/SCF/actions/postAddSupplier';
import { req as putRejectSupplier } from 'entities/SCF/Debtor/model/actions/putRejectSupplier';
import {
  req as patchSetDiscountMargin,
  RequestData as ReqSetDiscountMargin
} from 'entities/SCF/Debtor/model/actions/patchSetDiscountMargin';

import { Loader, Pagination } from 'Layouts/components';
import {
  InnFilter,
  InnContainer,
  ListSection,
  StatusArea,
  CheckIcon,
  IconPopup,
  GreyIcon,
  TimesIcon,
  StatusAreaRow,
  ConfirmContainer,
  HandleContainer,
  DeclineContainer,
  StatusContainer,
  LinkNotSet,
  DiscountMargin
} from './styles';
import {
  PaginationWrapper,
  PageStyled,
  CreateLimitsNudge
} from 'src/features/SCF/UI/ScfStyles';

import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { AddBtn, AddBtnText } from 'src/features/SCF/UI/ButtonsStyles';
import './styles/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { Href } from 'history';
import { Input } from 'shared/ui/Input';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  mySuppliers: ResponseDataType;
  suppliersUploadStatus: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMySuppliers: (data: RequestDataType) => void;
  postAddSupplier: (inn: string) => void;
  putRejectSupplier: (inn: string) => void;
  patchSetDiscountMargin: (inn: string, data: ReqSetDiscountMargin) => void;
}

interface State {
  pageUrl: Href;
  innToFilter: string;
  mySuppliers: ResponseDataType['items'];
  discountMargin: string;
  selectDiscount: {};
}

type Props = StateToProps & DispatchToProps;

class SuppliersPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        innToFilter: '',
        mySuppliers: [],
        selectDiscount: {},
        discountMargin: ''
      };
    }
  }

  handlePageChange = (newHref: Href) => {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  };

  handleInnToFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ innToFilter: event.target.value });
  };

  AddSuppliersClick = () => {
    history.push('/debtor/suppliers/add');
  };

  onRawClick = (inn: string) => {
    history.push(`/debtor/suppliers/${inn}`);
  };

  initFetch = () => {
    const { getMySuppliers } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.innToFilter == ''
        ? getMySuppliers({ page, pageSize: 10 })
        : getMySuppliers({
            page: 1,
            supplierInnToFilterBy: this.state.innToFilter
          });
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.suppliersUploadStatus !== prevProps.suppliersUploadStatus ||
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.innToFilter !== prevState.innToFilter
    ) {
      this.initFetch();
    }
    if (prevProps.status !== this.props.status) {
      this.setState({ mySuppliers: this.props.mySuppliers.items });
    }
  }

  acceptSupplier = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inn: string,
    index: number
  ) => {
    e.stopPropagation();
    const newData = [...this.state.mySuppliers];
    newData[index].confirmedByDebtor = true;
    this.setState({ mySuppliers: newData });
    this.props.postAddSupplier(inn);
  };

  declineSupplier = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inn: string
  ) => {
    e.stopPropagation();
    const newData = [...this.state.mySuppliers];
    const filtredData = newData.filter(
      supplier => supplier.supplierInn !== inn
    );
    this.setState({ mySuppliers: filtredData });
    this.props.putRejectSupplier(inn);
  };

  render() {
    const { status } = this.props;
    const { mySuppliers, selectDiscount, discountMargin } = this.state;

    return (
      <PageStyled>
        <CreateLimitsNudge>
          <h2>Мои поставщики</h2>
          <AddBtn onClick={this.AddSuppliersClick}>
            <AddBtnText>+ Добавить поставщиков</AddBtnText>
          </AddBtn>
        </CreateLimitsNudge>
        <InnFilter>
          <p>ИНН Поставщика</p>
          <InnContainer>
            <input
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={this.handleInnToFilterChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>

        <ListSection>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <>
              <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableHeaderCellStyled width="10%">
                      Инн поставщика
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="15%">
                      Наименование поставщика
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="30%">
                      Статус
                    </TableHeaderCellStyled>
                    <TableHeaderCellStyled width="30%">
                      Доп.маржа дисконтирования
                    </TableHeaderCellStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {mySuppliers.map((supplier, key) => (
                    <TableRowStyled
                      key={key}
                      className="table-row"
                      onClick={() => this.onRawClick(supplier.supplierInn)}
                    >
                      <td>{supplier.supplierInn}</td>
                      <td>{supplier.supplierName}</td>
                      <td>
                        <StatusContainer>
                          {supplier.confirmedByDebtor &&
                          supplier.confirmedBySupplier ? (
                            <>
                              <StatusArea className="icon-to-show-popup">
                                <CheckIcon />
                                <IconPopup className="icon-popup">
                                  <p>Подтверждено поставщиком</p>
                                </IconPopup>
                              </StatusArea>
                              <StatusArea className="icon-to-show-popup">
                                <CheckIcon />
                                <IconPopup className="icon-popup">
                                  <p>Подтверждено вами</p>
                                </IconPopup>
                              </StatusArea>
                            </>
                          ) : supplier.confirmedBySupplier &&
                          !supplier.confirmedByDebtor &&
                          !supplier.rejectedByDebtor ? (
                            <>
                              <StatusArea className="icon-to-show-popup">
                                <CheckIcon />
                                <IconPopup className="icon-popup">
                                  <p>Подтверждено поставщиком</p>
                                </IconPopup>
                              </StatusArea>
                              <StatusAreaRow>
                                <ConfirmContainer>
                                  <HandleContainer
                                    onClick={e =>
                                      this.acceptSupplier(
                                        e,
                                        supplier.supplierInn,
                                        key
                                      )
                                    }
                                  >
                                    <p>Подтвердить</p>
                                  </HandleContainer>
                                </ConfirmContainer>
                                <DeclineContainer>
                                  <HandleContainer
                                    onClick={e =>
                                      this.declineSupplier(
                                        e,
                                        supplier.supplierInn
                                      )
                                    }
                                  >
                                    <p>Отклонить</p>
                                  </HandleContainer>
                                </DeclineContainer>
                              </StatusAreaRow>
                            </>
                          ) : !supplier.confirmedBySupplier &&
                          supplier.confirmedByDebtor &&
                          supplier.rejectedBySupplier ? (
                            <>
                              <StatusArea className="icon-to-show-popup">
                                <CheckIcon />
                                <IconPopup className="icon-popup">
                                  <p>Подтверждено вами</p>
                                </IconPopup>
                              </StatusArea>
                              <StatusArea className="icon-to-show-popup">
                                <TimesIcon />
                                <IconPopup className="icon-popup">
                                  <p>Отклонено поставщиком</p>
                                </IconPopup>
                              </StatusArea>
                            </>
                          ) : (
                            !supplier.confirmedBySupplier &&
                            supplier.confirmedByDebtor &&
                            !supplier.rejectedBySupplier && (
                              <>
                                <StatusArea className="icon-to-show-popup">
                                  <CheckIcon />
                                  <IconPopup className="icon-popup">
                                    <p>Подтверждено вами</p>
                                  </IconPopup>
                                </StatusArea>
                                <StatusArea className="icon-to-show-popup">
                                  <GreyIcon />
                                  <IconPopup className="icon-popup">
                                    <p>Еще не подтверждено поставщиком</p>
                                  </IconPopup>
                                </StatusArea>
                              </>
                            )
                          )}
                        </StatusContainer>
                      </td>
                      <td>
                        {!!supplier.discountMargin ? (
                          `+ ${supplier.discountMargin} % годовых`
                        ) : selectDiscount[key] &&
                        selectDiscount[key].selected ? (
                          <DiscountMargin
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => e.stopPropagation()}
                          >
                            <Input
                              name="discountMargin"
                              value={discountMargin}
                              onChange={e => {
                                const newValue = e.currentTarget.value;
                                if (/^\d*[.,]?\d{0,4}$/.test(newValue)) {
                                  this.setState({
                                    discountMargin: newValue.replace(',', '.')
                                  });
                                }
                              }}
                            />
                            <Button
                              label="Сохранить"
                              disabled={!discountMargin}
                              onClick={() =>
                                this.props.patchSetDiscountMargin(
                                  supplier.supplierInn,
                                  { margin: +discountMargin }
                                )
                              }
                            />
                            <div
                              onClick={() =>
                                this.setState({ selectDiscount: {} })
                              }
                            >
                              &times;
                            </div>
                          </DiscountMargin>
                        ) : (
                          <LinkNotSet
                            onClick={(
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>
                            ) => {
                              e.stopPropagation();
                              this.setState({
                                discountMargin: '',
                                selectDiscount: { [key]: { selected: true } }
                              });
                            }}
                          >
                            не установлена
                          </LinkNotSet>
                        )}
                      </td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyled>

              <PaginationWrapper onClick={this.handlePageChange}>
                <Pagination list={mySuppliers} />
              </PaginationWrapper>
            </>
          )}
        </ListSection>
      </PageStyled>
    );
  }
}
const mapStateToProps = ({ SCF }: STORE) => ({
  mySuppliers: SCF.getMySuppliers.data,
  status: SCF.getMySuppliers.status,
  error: SCF.getMySuppliers.error,
  suppliersUploadStatus: SCF.sendSuppliers.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMySuppliers,
      postAddSupplier,
      putRejectSupplier,
      patchSetDiscountMargin
    },
    dispatch
  );

const SuppliersPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(SuppliersPage);

export { SuppliersPageConnect as SuppliersPage };
