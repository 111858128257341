import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Calc as ResponseDataType } from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';

export interface GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState
  extends Reducer {
  data: ResponseDataType[];
}

export enum GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID {
  GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ = 'GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ',
  GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT = 'GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT',
  GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR = 'GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR'
}

export const initialState: GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [
    {
      baseFullSumm: null,
      baseMonetaryClaimGuid: '',
      baseSumm: null,
      baseVatPecentage: null,
      baseVatSumm: null,
      cessedMonetaryClaimGuid: '',
      createdDateTime: '',
      creditorInn: '',
      creditorName: '',
      currencyCode: '',
      currencyID: null,
      emergenceDate: '',
      firstDebtorRegistryOccurence: null,
      guid: '',
      invoiceDocsQty: null,
      invoiceDocumentDate: '',
      invoiceDocumentName: '',
      invoiceDocumentNumber: '',
      lastDebtorRegistryOccurence: null,
      maturityDate: '',
      montaryClaimId: null,
      paidStatus: null,
      status: null,
      supplierName: '',
      supplyContractGuid: '',
      supplyContractNumber: ''
    }
  ],
  error: {}
};

const getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid = (
  state: GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState = initialState,
  action: {
    type: GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID;
    data: ResponseDataType[];
    error: {};
  }
): GetMonetaryClaimsDebtorEarlyPaymentApplicationsByGuidStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_DEBTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid;
