import { GET_ACTIVITIES_BY_APPLICATION } from 'Application/reducers/getActivitiesByApplication';
import { LeadActivities } from 'src/features/Application/types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: LeadActivities[];
}

export const req = (applicationId: string) => ({
  type: GET_ACTIVITIES_BY_APPLICATION.REQ,
  applicationId
});

export const got = (data: ResponseData) => ({
  type: GET_ACTIVITIES_BY_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ACTIVITIES_BY_APPLICATION.ERR,
  error
});
