import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { CheckboxState, PREFERENTIAL, RadioBtnState } from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getProductsList,
  ResponseData as ProductsListResponseData,
  RequestData as ProductsListRequestData
} from '../../actions/getProductsList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { Input } from 'shared/ui/Input';
import { ScrollTopComponent } from 'src/features/Common';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { FiltersStyled } from 'src/features/Application/components/ApplicationsList/styles';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';
import { TypeFilter } from 'Filters/TypeFilter/components/TypeFilter';
import {
  GroupFilters,
  FilterCategory,
  ContainerProductFilter,
  ContainerClientFilter,
  ContainerContractFilter,
  ContainerStopFactorFilter,
  ContainerCashbackFilter,
  ProgressWrap,
  TermContainer,
  PreferentialContainer,
  HeadContainer
} from 'src/features/Products/components/ProductsList/styled';
import { FilterByClient } from 'src/features/Filters/Products/FilterByClient/components/FilterByClient';
import { FilterByContract } from 'src/features/Filters/Products/FilterByContract/components/FilterByContract';
import { FilterByStopFactors } from 'src/features/Filters/Products/FilterByStopFactors/components/FilterByStopFactors';
import { FilterByCashback } from 'src/features/Filters/Products/FilterByCashback/components/FilterByCashback';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  products: ProductsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  typesToFilterBy: string[];
}

interface DispatchToProps {
  getProductsList: (data: ProductsListRequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const groupFiltersArr = [
  { id: 1, label: 'Продукты' },
  { id: 2, label: 'Клиент' },
  { id: 3, label: 'Контракт' },
  { id: 4, label: 'Стоп-факторы' },
  { id: 5, label: 'Cashback' }
];

const ProductsList: React.FC<Props> = ({
  getProductsList,
  products,
  status,
  error,
  typesToFilterBy,
  history
}) => {
  const [activeId, setActiveId] = React.useState([1]);
  const [checkboxState, setCheckboxState] = React.useState<CheckboxState>({
    typeOfContract: [],
    segmentClient: [],
    typeClient: [],
    stopFactorsCrimea: [],
    stopFactorsCaucasus: [],
    bki: false
  });
  const [transactionAmount, setTransactionAmount] = React.useState(0);
  const [age, setAge] = React.useState(0);
  const [term, setTerm] = React.useState(0);
  const [revenue, setRevenue] = React.useState(0);
  const [radioBtnState, setRadioBtnState] = React.useState<RadioBtnState>({
    preferential: 'NOT_CHECK',
    hasCashback: 'NOT_CHECK',
    inPlatform: 'NOT_CHECK',
    hasContract: 'NOT_CHECK',
    mspsubject: 'NOT_CHECK'
  });

  React.useEffect(
    () => {
      initFetch();
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);

      if (typesToFilterBy.length) {
        searchParams.set('typesToFilterBy', typesToFilterBy.toString());
      } else {
        searchParams.delete('typesToFilterBy');
      }

      history.push({ search: searchParams.toString() });
    },
    [typesToFilterBy]
  );

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;
    const size = +searchParams.get('size') || 0;
    const term = +searchParams.get('term') || 0;
    const revenue = +searchParams.get('revenue') || 0;
    const ageOfCompany = +searchParams.get('age') || 0;
    const segmentClient = searchParams.getAll('segmentClient') || [];
    const typeClient = searchParams.getAll('typeClient') || [];
    const typeOfContract = searchParams.getAll('typeOfContract') || [];
    const stopFactorsCrimea = searchParams.getAll('stopFactorsCrimea') || [];
    const stopFactorsCaucasus =
      searchParams.getAll('stopFactorsCaucasus') || [];
    const bki = Boolean(searchParams.get('bki')) || false;
    const preferential = searchParams.get('preferential') || 'NOT_CHECK';
    const mspsubject = searchParams.get('mspsubject') || 'NOT_CHECK';
    const hasCashback = searchParams.get('cashback') || 'NOT_CHECK';
    const inPlatform = searchParams.get('inPlatform') || 'NOT_CHECK';
    const hasContract = searchParams.get('hasContract') || 'NOT_CHECK';
    const typesToFilterBy = searchParams.getAll('typesToFilterBy') || [];

    setTransactionAmount(size);
    setTerm(term);
    setRevenue(revenue);
    setCheckboxState({
      segmentClient: segmentClient.toString().split(','),
      typeClient: typeClient.toString().split(','),
      typeOfContract: typeOfContract.toString().split(','),
      stopFactorsCrimea: stopFactorsCrimea.toString().split(','),
      stopFactorsCaucasus: stopFactorsCaucasus.toString().split(','),
      bki
    });
    setAge(ageOfCompany);
    setRadioBtnState({
      inPlatform,
      hasCashback,
      hasContract,
      mspsubject,
      preferential
    });

    const getFiltersAsObject = (filterArray: string[]) => {
      const filterObject = {};
      filterArray.forEach(propertyName => {
        filterObject[propertyName] = true;
      });
      return filterArray.length ? filterObject : {};
    };

    const typesToFilterByObject = {};

    if (typesToFilterBy.length > 0) {
      const types = typesToFilterBy[0].split(',');
      for (let i = 0; i < types.length; i++) {
        typesToFilterByObject[`typesToFilterBy[${i}]`] = types[i];
      }
    }

    getProductsList({
      page,
      pageSize: 20,
      ...typesToFilterByObject,
      ...(age && { age }),
      ...(revenue && { revenue }),
      ...(size && { size }),
      ...(term && { term }),
      ...(bki && { bki }),
      ...(hasCashback === 'TRUE'
        ? { hasCashback: true }
        : hasCashback === 'FALSE' && { hasCashback: false }),
      ...(hasContract === 'TRUE'
        ? { hasContract: true }
        : hasContract === 'FALSE' && { hasContract: false }),
      ...(inPlatform === 'TRUE'
        ? { inPlatform: true }
        : inPlatform === 'FALSE' && { inPlatform: false }),
      ...(mspsubject === 'TRUE'
        ? { mspsubject: true }
        : mspsubject === 'FALSE' && { mspsubject: false }),
      ...(preferential === 'TRUE'
        ? { preferential: true }
        : preferential === 'FALSE' && { preferential: false }),
      ...getFiltersAsObject(segmentClient),
      ...getFiltersAsObject(typeClient),
      ...getFiltersAsObject(typeOfContract),
      ...getFiltersAsObject(stopFactorsCrimea),
      ...getFiltersAsObject(stopFactorsCaucasus)
    });
  };

  const handleSetActiveId = (id: number) => {
    if (activeId.includes(id)) {
      setActiveId(activeId.filter(itemId => itemId !== id));
    } else {
      setActiveId([...activeId, id]);
    }
  };

  const handleNum = (event: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget;
    const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));

    const searchParams = new URLSearchParams(history.location.search);

    if (!isNaN(numericValue)) {
      searchParams.set(name, numericValue.toString());
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    const searchParams = new URLSearchParams(history.location.search);

    if (name === 'bki') {
      if (!checkboxState.bki) {
        searchParams.set(name, `${!checkboxState.bki}`);
      } else {
        searchParams.delete(name);
      }

      history.push({ search: searchParams.toString() });
      return;
    }

    const selectedItems = checkboxState[name] || [];
    const isSelected = selectedItems.includes(value);

    if (isSelected) {
      checkboxState[name] = selectedItems.filter(item => item !== value);

      searchParams.delete(name);

      const updatedValues = checkboxState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    } else {
      checkboxState[name] = [...selectedItems, value];

      const updatedValues = checkboxState[name].filter(val => val !== '');
      if (updatedValues.length > 0) {
        searchParams.set(name, updatedValues.join(','));
      }

      history.push({ search: searchParams.toString() });
    }
  };

  const handleRadioBtn = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value === 'NOT_CHECK') {
      searchParams.delete(name);
    } else {
      searchParams.set(name, value);
    }

    history.push({ search: searchParams.toString() });
  };

  const onProductClick = (id: number) => {
    window.open(`/crm/products/${id}`, '_blank');
  };

  const navigateToAddProduct = () => {
    history.push('/crm/products/new');
  };

  return (
    <ScrollTopComponent>
      <HeadContainer>
        <ApplicationTitle>Все продукты</ApplicationTitle>
        <Button
          label="+ Добавить продукт"
          onClick={navigateToAddProduct}
          h="30px"
        />
      </HeadContainer>

      <GroupFilters>
        {groupFiltersArr.map(({ id, label }) => (
          <FilterCategory
            key={id}
            active={activeId.includes(id)}
            onClick={() => handleSetActiveId(id)}
          >
            <FontAwesomeIcon
              icon={activeId.includes(id) ? faEye : faEyeSlash}
            />
            {label}
          </FilterCategory>
        ))}
      </GroupFilters>

      <FiltersStyled direction="column">
        {activeId.includes(1) && (
          <ContainerProductFilter>
            <TypeFilter />
            <INNFilterContainerStyled>
              <Input
                value={transactionAmount.toLocaleString('ru')}
                label="Сумма сделки"
                name="size"
                placeholder="0"
                onChange={handleNum}
              />
            </INNFilterContainerStyled>
            <TermContainer>
              <Input
                value={term}
                label="Срок"
                name="term"
                placeholder="0"
                onChange={handleNum}
              />
              <p>мес.</p>
            </TermContainer>
            <PreferentialContainer>
              <RadioGroup
                label="Наличие господдержки:"
                name="preferential"
                keyValue={radioBtnState.preferential}
                onChange={handleRadioBtn}
                radioBtns={Object.keys(PREFERENTIAL).map(value => ({
                  value,
                  label: PREFERENTIAL[value]
                }))}
              />
            </PreferentialContainer>
          </ContainerProductFilter>
        )}
        {activeId.includes(2) && (
          <ContainerClientFilter>
            <FilterByClient
              revenue={revenue}
              handleRevenue={handleNum}
              checkboxState={checkboxState}
              handleFilter={handleFilter}
              radioBtnState={radioBtnState}
              handleRadioBtn={handleRadioBtn}
            />
          </ContainerClientFilter>
        )}
        {activeId.includes(3) && (
          <ContainerContractFilter>
            <FilterByContract
              checkboxState={checkboxState}
              handleFilter={handleFilter}
            />
          </ContainerContractFilter>
        )}
        {activeId.includes(4) && (
          <ContainerStopFactorFilter>
            <FilterByStopFactors
              checkboxState={checkboxState}
              handleFilter={handleFilter}
              ageOfCompany={age}
              handleAgeOfCompany={handleNum}
            />
          </ContainerStopFactorFilter>
        )}
        {activeId.includes(5) && (
          <ContainerCashbackFilter>
            <FilterByCashback
              radioBtnState={radioBtnState}
              handleRadioBtn={handleRadioBtn}
            />
          </ContainerCashbackFilter>
        )}
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="1%">ID</TableThStyled>
              <TableThStyled width="7%">Название</TableThStyled>
              <TableThStyled width="7%">Тип</TableThStyled>
              <TableThStyled width="7%">Банк</TableThStyled>
              <TableThStyled width="4%">Cashback</TableThStyled>
              <TableThStyled width="4%">Через FF</TableThStyled>
              <TableThStyled width="2%">МСП</TableThStyled>
              <TableThStyled width="2%">КСБ</TableThStyled>
              <TableThStyled width="6%">Мин. лимит</TableThStyled>
              <TableThStyled width="6%">Макс. лимит</TableThStyled>
              <TableThStyled width="5%">Риск-Аппетит</TableThStyled>
              <TableThStyled width="6%">Сложность</TableThStyled>
              <TableThStyled width="7%">Эксклюзив</TableThStyled>
              <TableThStyled width="7%">Качество данных</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {products.items.map(product => (
              <TableRowStyled
                key={product.id}
                onClick={() => onProductClick(product.id)}
              >
                <td>{product.id}</td>
                <td>{product.productName}</td>
                <td>{product.financeTypeName}</td>
                <td>{product.bankName}</td>
                <td>{product.hasCashback ? 'да' : ''}</td>
                <td>{product.isInPlatform ? 'да' : ''}</td>
                <td>{product.isForMicro ? 'да' : ''}</td>
                <td>{product.isForMedium ? 'да' : ''}</td>
                <td>
                  {product.minSize > 0
                    ? formSumStringThousands(product.minSize)
                    : '-'}
                </td>
                <td>
                  {product.maxSize > 0
                    ? formSumStringThousands(product.maxSize)
                    : '-'}
                </td>
                <td>{product.riskAppetite}</td>
                <td>{product.complexity}</td>
                <td>{product.exclusivity}</td>
                <td>
                  <ProgressWrap percent={product.dataQuality}>
                    <figcaption>{product.dataQuality}%</figcaption>
                  </ProgressWrap>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={products} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Products, Filters }: CRM) => ({
  products: Products.getProductsList.data,
  status: Products.getProductsList.status,
  error: Products.getProductsList.error,
  typesToFilterBy: Filters.updateTypeFilter.types
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProductsList }, dispatch);

const ProductsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ProductsList)
);

export { ProductsListConnect as ProductsList };
