import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getKeyRates';
import { GET_KEY_RATES } from '../reducers/getKeyRates';
import SCFApi from '../api';

function* getKeyRates() {
  try {
    const res: any = yield call(SCFApi.getKeyRates);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getKeyRatesSaga() {
  yield takeLatest(GET_KEY_RATES.REQ, getKeyRates);
}
