import {
  RequestDataType,
  PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE
} from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';

export const req = (data: RequestDataType) => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.REQ,
  data
});

export const got = () => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.GOT
});

export const err = (error: {}) => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.ERR,
  error
});
