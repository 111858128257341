import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface BusinessStatusStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  approved: boolean;
}

export enum BUSINESS_STATUS_ACTIONS {
  REQ = 'BUSINESS_STATUS_REQ',
  GOT = 'BUSINESS_STATUS_GOT',
  ERR = 'BUSINESS_STATUS_ERR'
}

export const initialState: BusinessStatusStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    approved: false
  },
  error: {}
};

const businessStatus = (
  state: BusinessStatusStoreState = initialState,
  action: {
    type: BUSINESS_STATUS_ACTIONS;
    data: ResponseData;
    error: {};
  }
): BusinessStatusStoreState => {
  switch (action.type) {
    case BUSINESS_STATUS_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case BUSINESS_STATUS_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case BUSINESS_STATUS_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default businessStatus;
