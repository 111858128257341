import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from '../actions/getSupplyContractsList';

export default class SupplyApi {
  static getSupplyContractsList(data: RequestData) {
    return ApiRequest(`/api/supply_contract/all`, 'get', data);
  }

  static getSupplyContract(guid: string) {
    return ApiRequest(`/api/supply_contract/internal_role/${guid}`);
  }

  static getContractByInternal(guid: string) {
    return ApiRequest(
      `/api/bank_account/internal_role/get_by_contract/${guid}`
    );
  }
}
