import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';
import { ResponseData as PledgesData } from 'src/features/BankProducts/actions/getPledge';
import { TRWrapper } from 'src/features/BankProducts/components/Tabs/CreditProducts/styled';

interface StateToProps {
  pledges: PledgesData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

type Props = StateToProps;

class PledgesBank extends React.Component<Props> {
  navigateToBankProduct = (bankInn: string) => {
    window.open(`/crm/banks/${bankInn}/clients/pledges`, '_blank');
  };

  render() {
    const { pledges, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}

        {status === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="12%">Банк</TableThStyled>
                  <TableThStyled width="12%">
                    Дата самого раннего залога
                  </TableThStyled>
                  <TableThStyled width="40%">
                    Дата последнего договора залога
                  </TableThStyled>
                  <TableThStyled width="12%">Источник данных</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {pledges.items.map((product, index) => (
                  <TRWrapper
                    key={index}
                    isActive={product.isActive}
                    onClick={() => this.navigateToBankProduct(product.bankInn)}
                  >
                    <td>{product.bankName}</td>
                    <td>
                      {product.firstPledgeContractDate &&
                        new Date(
                          product.firstPledgeContractDate
                        ).toLocaleDateString()}
                    </td>
                    <td>
                      {product.lastPledgeContractDate &&
                        new Date(
                          product.lastPledgeContractDate
                        ).toLocaleDateString()}
                    </td>
                    <td>{product.source}</td>
                  </TRWrapper>
                ))}
              </tbody>
            </TableStyled>
            <Pagination list={pledges} />
          </>
        )}

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const PledgesBankConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PledgesBank);

export { PledgesBankConnect as PledgesBank };
