import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getUserFactorList';

import { GET_USER_FACTOR_LIST } from '../reducers/getUserFactorList';
import UserDebtorsApi from '../api';

function* getUserFactorList(action: {
  type: GET_USER_FACTOR_LIST;
  data: RequestData;
}): Generator {
  try {
    let res = yield call(UserDebtorsApi.getAllUserFactors, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserFactorListSaga(): Generator {
  yield takeLatest(GET_USER_FACTOR_LIST.REQ, getUserFactorList);
}
