import { GET_COMPANY_BY_INN } from '../reducers/getCompanyByInn';

export interface ResponseDataType {
  id: number;
  inn: string;
  companyShortName: string;
  companyFullName: string;
  companyPhone: string;
  companyAddress: string;
  modifiedDateTime: Date;
  kpp: string;
  holdingId: number;
  holdingName: string;
  revenue: number;
  netIncome: number;
  capital: number;
  financialsYear: number;
  hasContacts: boolean;
  segment: string;
  backbone: number;
  inAction: number;
  is_customer223: boolean;
  is_customer44: boolean;
  is_target_client: boolean;
  regDate: Date;
  minNum: number;
  maxNum: number;
  opfId: number;
  okvedId: number;
  companyEmail: string;
  pfr: string;
  fss: string;
  startCapital: number;
  kladr: number;
  index: number;
  region: number;
  area: string;
  city: string;
  settlement: string;
  street: string;
  house: string;
  corpus: string;
  apartment: string;
  lastActivity: Date;
  lastLogin: Date;
  isDeleted: boolean;
}


export interface RequestDataType {
  inn: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANY_BY_INN.GET_COMPANY_BY_INN_ERR,
  error
});
