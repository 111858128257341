import {
  RequestDataType,
  PUT_CERTIFICATE_FILL
} from 'src/features/SCF/reducers/putCertificateFill';

export const req = (data: RequestDataType) => ({
  type: PUT_CERTIFICATE_FILL.REQ,
  data
});

export const got = () => ({
  type: PUT_CERTIFICATE_FILL.GOT
});

export const err = (error: {}) => ({
  type: PUT_CERTIFICATE_FILL.ERR,
  error
});

export const reset = () => ({
  type: PUT_CERTIFICATE_FILL.RESET
});
