import * as React from 'react';

import { history } from 'src/shared/utils/History';
import { BorrowerCompany } from 'Application/types';

import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';

import {
  ApplicationViewConsumer,
  EachApplicationProps,
  State as BorrowerCompanyDocumentsTabState
} from 'Application/components';

import { EditApplicationBtn } from 'Application/components/ApplicationView/EachApplicationView/styles';
import {
  CompanyTabHeaderRole,
  CompanyTabsHeader
} from 'BorrowerCompanies/components/BorrowerCompaniesTabs/styles';

import { CompanyName, ViewCompanyTabHeaderItem } from './styles';

interface Props extends BorrowerCompanyDocumentsTabState {
  companies: BorrowerCompany[];
  changeTab: (index: number) => void;
}

export const BorrowerCompanyDocumentHeader: React.FC<Props> = ({
  companies,
  changeTab,
  activeTabIndex,
  animationDirection
}: Props) => (
  <ApplicationViewConsumer>
    {({
      data,
      canEdit
    }: EachApplicationProps<GetApplicationResponseDataType>) => (
      <>
        <p>Участники заявки:</p>
        <CompanyTabsHeader>
          <>
            {companies.map((company, key) => (
              <ViewCompanyTabHeaderItem
                key={key}
                selected={key === activeTabIndex}
                animationDirection={animationDirection}
                onClick={() => changeTab(key)}
              >
                <div>
                  <CompanyName>
                    {!!company.name ? company.name : `Компания №${key + 1}`}
                  </CompanyName>
                  <p>ИНН {company.individualNumber}</p>
                  <CompanyTabHeaderRole selected={key === activeTabIndex}>
                    {company.roles.map((role, index) => (
                      <p key={index}>
                        {role === 'PRINCIPAL'
                          ? 'Принципал'
                          : role === 'GUARANTOR'
                            ? 'Поручитель'
                            : role === 'PLEDGER'
                              ? 'Залогодатель'
                              : role === 'BORROWER' && 'Заемщик'}
                        {index !== company.roles.length - 1 && ','}
                      </p>
                    ))}
                  </CompanyTabHeaderRole>
                </div>
              </ViewCompanyTabHeaderItem>
            ))}

            {canEdit && (
              <EditApplicationBtn
                onClick={() => {
                  const segments = location.pathname.split('/');
                  const roleIndex = segments.indexOf('cabinet') + 1;
                  const role = segments[roleIndex];

                  history.push(
                    `/cabinet/${role}/application-form/edit/${
                      data.id
                    }/documents`
                  );
                }}
              >
                Редактировать документы
              </EditApplicationBtn>
            )}
          </>
        </CompanyTabsHeader>
      </>
    )}
  </ApplicationViewConsumer>
);
