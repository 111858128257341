import {
    DELETE_BANK_ACCOUNT,
    RequestDataType
} from './../reducers/deleteBankAccount';

export const req = (data: RequestDataType) => ({
    type: DELETE_BANK_ACCOUNT.REQ,
    data
});

export const got = () => ({
    type: DELETE_BANK_ACCOUNT.GOT
});

export const err = (error: {}) => ({
    type: DELETE_BANK_ACCOUNT.ERR,
    error
});
