import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getFactoringContractsDebtorRole';

export interface GetFactoringContractsDebtorRoleStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_FACTORING_CONTRACTS_DEBTOR_ROLE {
  GET_FACTORING_CONTRACTS_DEBTOR_ROLE_REQ = 'GET_FACTORING_CONTRACTS_DEBTOR_ROLE_REQ',
  GET_FACTORING_CONTRACTS_DEBTOR_ROLE_GOT = 'GET_FACTORING_CONTRACTS_DEBTOR_ROLE_GOT',
  GET_FACTORING_CONTRACTS_DEBTOR_ROLE_ERR = 'GET_FACTORING_CONTRACTS_DEBTOR_ROLE_ERR'
}

export const initialState: GetFactoringContractsDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getFactoringContractsDebtorRole = (
  state: GetFactoringContractsDebtorRoleStoreState = initialState,
  action: {
    type: GET_FACTORING_CONTRACTS_DEBTOR_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetFactoringContractsDebtorRoleStoreState => {
  switch (action.type) {
    case GET_FACTORING_CONTRACTS_DEBTOR_ROLE.GET_FACTORING_CONTRACTS_DEBTOR_ROLE_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_CONTRACTS_DEBTOR_ROLE.GET_FACTORING_CONTRACTS_DEBTOR_ROLE_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_CONTRACTS_DEBTOR_ROLE.GET_FACTORING_CONTRACTS_DEBTOR_ROLE_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactoringContractsDebtorRole;
