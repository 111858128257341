export const ContractFinancingType = {
  FZ44: '44-ФЗ',
  FZ223: '223-ФЗ',
  P615: '615-П',
  GOZ: 'ГОЗ',
  COMMERCIAL: 'Коммерция'
};

export const PropertyFinancingClientType = {
  INDIVIDUAL: 'Физическое лицо',
  SOLE_PROPRIETOR: 'Индивидуальный предприниматель',
  ENTITY: 'Юридическое лицо'
};

export const PropertyFinancingClientIncomeConfirmationType = {
  NO_CONFIRMATION: 'Доход не подтвержден',
  TWO_NDFL: 'Справка 2-НДФЛ',
  THREE_NDFL: 'Справка 3-НДФЛ',
  TURNOVER_51_REPORT: 'Выписка оборотов по 51 счету',
  BANK_CARD_REPORT: 'Выписка по банковской карте',
  EMPLOYER_NOTE: 'Справка от работодателя'
};

export const PropertyTypes = {
  RESIDENTIAL_PROPERTY: 'Жилая недвижимость',
  COMMERCIAL_PROPERTY: 'Коммерческая недвижимость'
};

export const PropertySubTypes = {
  FLAT: 'Квартира',
  HOUSE: 'Дом',
  TOWN_HOUSE: 'Таунхаус',
  LAND: 'Участок',
  OFFICE: 'Офис',
  WAREHOUSE: 'Склад',
  RETAIL_SPACE: 'Торговые помещения',
  PRODUCTION_BUILDINGS: 'Производственные помещения',
  HOTEL: 'Гостиница'
};

export const ContractSubjectType = {
  SUPPLY: 'Поставка',
  SERVICES: 'Работы / услуги'
};

export const ContractPrepaymentType = {
  NO_PREPAYMENT: 'Отсутствует',
  HAS_PREPAYMENT: 'Предусмотрен'
};
