import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Calc as ResponseDataType } from 'entities/SCF/Factor/model/actions/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';

export interface GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState
  extends Reducer {
  data: ResponseDataType[];
}

export enum GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID {
  GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ = 'GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ',
  GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT = 'GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT',
  GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR = 'GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR'
}

export const initialState: GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [
    {
      createdDateTime: null,
      creditorInn: '',
      creditorName: '',
      currencyID: null,
      currencyCode: '',
      baseSumm: null,
      baseVatPecentage: null,
      baseVatSumm: null,
      baseFullSumm: null,
      emergenceDate: null,
      maturityDate: null,
      montaryClaimId: null,
      guid: '',
      status: null
    }
  ],
  error: {}
};

const getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid = (
  state: GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState = initialState,
  action: {
    type: GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID;
    data: ResponseDataType[];
    error: {};
  }
): GetMonetaryClaimsFactorEarlyPaymentApplicationsByGuidStoreState => {
  switch (action.type) {
    case GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID.GET_MONETARY_CLAIMS_FACTOR_EARLY_PAYMENT_APPLICATIONS_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid;
