import { combineReducers } from 'redux';
import getCreditProduct, {
  GetCreditProductStoreState
} from './getCreditProduct';
import getPledges, { GetPledgesStoreState } from './getPledges';
import getBankAccounts, { GetBankAccountsStoreState } from './getBankAccounts';

export interface BankProductsReducers {
  getCreditProduct: GetCreditProductStoreState;
  getPledges: GetPledgesStoreState;
  getBankAccounts: GetBankAccountsStoreState;
}

export const BankProducts = combineReducers({
  getCreditProduct,
  getPledges,
  getBankAccounts
});
