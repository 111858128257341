import { CalculateData } from 'src/features/Products/types';
import { POST_CALCULATE_RATING } from '../reducers/postCalculateRating';

export const req = (id: number, data: CalculateData) => ({
  type: POST_CALCULATE_RATING.REQ,
  id,
  data
});

export const got = () => ({
  type: POST_CALCULATE_RATING.GOT
});

export const err = (error: {}) => ({
  type: POST_CALCULATE_RATING.ERR,
  error
});
