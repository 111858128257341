import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Application/actions/getAllOffers';

export interface GetAllOffersStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_ALL_OFFERS {
  REQ = 'GET_ALL_OFFERS_REQ',
  GOT = 'GET_ALL_OFFERS_GOT',
  ERR = 'GET_ALL_OFFERS_ERR',
  RESET = 'GET_ALL_OFFERS_RESET'
}

export const initialState: GetAllOffersStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getAllOffers = (
  state: GetAllOffersStoreState = initialState,
  action: {
    type: GET_ALL_OFFERS;
    data: ResponseData[];
    error: {};
  }
): GetAllOffersStoreState => {
  switch (action.type) {
    case GET_ALL_OFFERS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ALL_OFFERS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ALL_OFFERS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_ALL_OFFERS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getAllOffers;
