import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendBankAccountData';

export interface SendBankAccountDataStoreState extends Reducer { }

export enum SEND_BANK_ACCOUNT_DATA {
    REQ = 'SEND_BANK_ACCOUNT_DATA_REQ',
    GOT = 'SEND_BANK_ACCOUNT_DATA_GOT',
    ERR = 'SEND_BANK_ACCOUNT_DATA_ERR'
}

export const initialState: SendBankAccountDataStoreState = {
    status: REQUEST_STATUSES.NONE,
    error: {}
};

const sendBankAccountData = (
    state: SendBankAccountDataStoreState = initialState,
    action: {
        type: string;
        data: RequestData
        error: {};
    }
): SendBankAccountDataStoreState => {
    switch (action.type) {
        case SEND_BANK_ACCOUNT_DATA.REQ:
            return {
                ...state,
                ...action.data,
                status: REQUEST_STATUSES.REQUEST
            };
        case SEND_BANK_ACCOUNT_DATA.GOT:
            return {
                ...state,
                status: REQUEST_STATUSES.GOT,
            };
        case SEND_BANK_ACCOUNT_DATA.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };
        default:
            return state;
    }
};

export default sendBankAccountData;
