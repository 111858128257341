import { SEND_FACTORING_SETTINGS_BY_DEBTOR_INN } from '../reducers/sendFactoringSettingsByDebtorInn';
import { FactoringSettingsByDebtorInnWrite } from './setFactoringSettingsByDebtorInn';

export interface RequestData extends Partial<FactoringSettingsByDebtorInnWrite> {
}

export interface RequestDebtorInn {
  debtorInn: string;
  guid: string;
}

export interface ResponseGuid {
  guid: string;
}

export const req = (data: RequestData) => ({
  type: SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.REQ,
  data
});

export const got = (data: RequestDebtorInn) => ({
  type: SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.GOT,
  data
});

export const err = (error: {}) => ({
  type: SEND_FACTORING_SETTINGS_BY_DEBTOR_INN.ERR,
  error
});
