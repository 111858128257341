import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseType } from '../actions/getFinancialStatementsAll';

export interface GetFinancialStatementsAllStoreState extends Reducer {
  data: ResponseType;
}

export enum GET_FINANCIAL_STATEMENTS_ALL {
  REQ = 'GET_FINANCIAL_STATEMENTS_ALL_REQ',
  GOT = 'GET_FINANCIAL_STATEMENTS_ALL_GOT',
  ERR = 'GET_FINANCIAL_STATEMENTS_ALL_ERR',
  REMOVE = 'GET_FINANCIAL_STATEMENTS_ALL_REMOVE',
  RESET = 'RESET_FINANCIAL_STATEMENTS_ALL'
}

export const initialState: GetFinancialStatementsAllStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getFinancialStatementsAll = (
  state: GetFinancialStatementsAllStoreState = initialState,
  action: {
    type: string;
    data: any;
    companyinn: string;
    TaxPeriod?: number;
    TaxYear?: number;
    error: {};
  }
): GetFinancialStatementsAllStoreState => {
  switch (action.type) {
    case GET_FINANCIAL_STATEMENTS_ALL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FINANCIAL_STATEMENTS_ALL.GOT:
      const isItemExists = action.data.items.every(item =>
        state.data.items.some(element => element.id === item.id)
      );

      if (isItemExists) {
        return state;
      } else {
        return {
          ...state,
          status: REQUEST_STATUSES.GOT,
          data: {
            ...state.data,
            items: action.data.items
          }
        };
      }
    case GET_FINANCIAL_STATEMENTS_ALL.REMOVE:
      const newData = state.data.items.filter(
        item =>
          !(
            item.year === action.TaxYear &&
            item.taxPeriodCode === action.TaxPeriod
          )
      );
      return {
        ...state,
        data: { ...state.data, items: newData }
      };
    case GET_FINANCIAL_STATEMENTS_ALL.RESET:
      return {
        ...initialState
      };
    case GET_FINANCIAL_STATEMENTS_ALL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFinancialStatementsAll;
