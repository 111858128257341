import { TOGGLE_EXTERNAL_VISIBILITY_ACTIONS } from '../reducers/toggleExternalVisibility';

export const req = (appId: number) => ({
  type: TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.REQ,
  appId
});

export const got = () => ({
  type: TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.GOT,
});

export const err = (error: {}) => ({
  type: TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.ERR,
  error
});
