import styled from 'styled-components';
import { PurchaseLinkInput } from '../styles';

export const RadioButtonGroupContainer = styled.div`
  & > * {
    margin-top: 5px;
  }
  margin-bottom: 10px;

  & > ${PurchaseLinkInput} {
    width: 65%;
  }
`;
