import * as React from 'react';
import {
  ButtonAdd,
  ButtonBack,
  ButtonNext,
  ButtonReg,
  ButtonStyled
} from './styled';

type ButtonType = 'submit' | 'button' | 'reg';
type Template = 'defaultBtn' | 'nextBtn' | 'addBtn' | 'backBtn' | 'regBtn';

interface Props {
  type?: ButtonType;
  label: string | JSX.Element;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | null;
  title?: string;
  backgroundColor?: string;
  w?: string;
  h?: string;
  template?: Template;
  className?: string;
}

export const Button: React.FC<Props> = ({
  type,
  label,
  backgroundColor,
  disabled,
  onClick,
  title,
  w,
  h,
  className,
  template = 'defaultBtn'
}: Props) => {
  const sharedBtnProps = {
    type,
    onClick,
    disabled,
    className,
    title,
    backgroundColor,
    alt: title
  };

  return (
    <>
      {template === 'defaultBtn' && (
        <ButtonStyled w={w} h={h} {...sharedBtnProps}>
          {label}
        </ButtonStyled>
      )}
      {template === 'nextBtn' && (
        <ButtonNext w={w} h={h} {...sharedBtnProps}>
          {label}
        </ButtonNext>
      )}
      {template === 'addBtn' && (
        <ButtonAdd w={w} h={h} {...sharedBtnProps}>
          {label}
        </ButtonAdd>
      )}
      {template === 'backBtn' && (
        <ButtonBack w={w} h={h} {...sharedBtnProps}>
          {label}
        </ButtonBack>
      )}
      {template === 'regBtn' && (
        <ButtonReg w={w} h={h} {...sharedBtnProps}>
          {label}
        </ButtonReg>
      )}
    </>
  );
};
