import styled, { css, keyframes } from 'styled-components';
import { LEAD_STATUSES } from '../../types';
import {
  navyBlue,
  rr,
  lighterBlue,
  gray,
  lightGray,
  blue,
  rl
} from 'shared/styled';
import { Button } from 'shared/ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopupContent } from 'src/features/Contacts/components/ContactsList/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

const styleStatus = status => {
  switch (status) {
    case LEAD_STATUSES.CREATED || LEAD_STATUSES.FILLED:
      return css`
        background-color: #fff0de;
        color: ${navyBlue};
      `;
    case LEAD_STATUSES.IN_WORK:
      return css`
        background-color: #d0e9ff;
        color: ${navyBlue};
      `;
    case LEAD_STATUSES.REJECT:
      return css`
        background-color: #e4e4e4;
        color: ${navyBlue};
      `;
    case LEAD_STATUSES.APPROVED:
      return css`
        background-color: #beefe6;
        color: ${navyBlue};
      `;
    case LEAD_STATUSES.DEAL_SUCCEED:
      return css`
        background-color: #1cca94;
        color: ${navyBlue};
      `;
    default:
      return css`
        background-color: #fff0de;
        color: ${navyBlue};
      `;
  }
};

export const LeadStatusStyled = styled.span`
  padding: 8px 20px;
  border-radius: 6px;
  font-family: ${rr};
  font-size: 12px;
  text-align: center;
  display: inline-block;

  ${({ status }) => styleStatus(status)};
`;

export const BlueVerticalInfoBlock = styled.div`
  margin: 10px;
  padding: 15px;
  background-color: ${lighterBlue};
  font-family: ${rr};
  p {
    color: ${gray};
    span {
      color: #000;
    }
  }
`;
export const BlueVerticalInfoBlockHeader = styled.div``;

export const LeadCommentBlockStyled = styled.div``;

export const ButtonPickUpProducts = styled(Button)`
  background-color: red;
`;

export const ButtonExternalApiGIR_BO = styled(Button)`
  :disabled {
    background-color: ${gray};
  }
`;

export const CheckboxStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: ${lightGray};
  border-radius: 3px;

  svg path {
    fill: ${({ verify }) => (verify ? '#1a9c00' : 'orange')};
  }
`;

export const TooltipBoxStyle = styled.div`
  position: absolute;
  &:hover::before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 15px;
    right: -395px;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;

  button {
    width: fit-content;
  }
`;

export const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  h2 {
    margin: 0;
  }

  button {
    width: fit-content;
    height: 40px;
  }
`;

export const LeadApiContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin: 20px 0 30px 0;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  button {
    max-width: fit-content;
    :disabled {
      background-color: #0160b6;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
`;

export const ActivityContainer = styled.div`
  margin: 10px 0;
`;

export const Limit = styled.div`
  position: absolute;
  left: 58%;
  margin-top: -15px;

  p {
    margin: 0;
    :first-child {
      text-align: center;
      margin-bottom: 5px;
    }
  }
`;

export const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 10px 0;

  button {
    width: fit-content;
    height: 40px;
  }
`;

export const Period = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const IsNecessarily = styled.div`
  color: ${({ isUploaded }) => (isUploaded ? '' : 'red')};
`;

export const IsUploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  height: 35px;
  background-color: ${({ isUploaded }) => (isUploaded ? '#1CCA94' : '#FFA552')};
  border-radius: 5px;
  padding: 13px 24px;
  font-size: 16px;

  div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: white;

    p {
      color: white;
      margin: 0;
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
`;

export const DocumentType = styled(Period)`
  div {
    font-family: ${rr};
  }
`;

export const CompanyHead = styled.div`
  & > p:first-of-type {
    font-size: 14px;
  }

  & > p:last-of-type {
    font-size: 12px;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const PopupContentContacts = styled(PopupContent)`
  min-width: 700px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  button {
    width: fit-content;
    height: 20px;
    font-size: 14px;
  }

  table {
    table-layout: auto;
    tr {
      &:hover {
        background-color: ${blue};

        button {
          visibility: visible;
        }

        & > td {
          color: white;

          button {
            color: white;
          }
        }
      }

      td {
        button {
          visibility: hidden;
          cursor: pointer;
          width: fit-content;
          height: 25px;
          border: 1px solid white;
          border-radius: 5px;
          outline: none;
          padding: 0 5px;
          background-color: transparent;
          margin-right: 10px;
          font-size: 14px;
          font-family: ${rr};
          color: ${navyBlue};
        }
      }
    }
  }
`;

export const PopupTitle = styled.h2`
  margin-bottom: 25px;
  color: #7a7d83;
`;

export const TableWrapper = styled.div`
  margin-left: 25px;
`;

export const SectionSeparator = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e4e8;
`;

export const OpenLoanLimitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

export const UploadButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  column-gap: 10px;

  :disabled {
    background-color: ${gray};

    :hover {
      background-color: ${gray};
    }
  }

  p {
    font-size: 16px;
    color: white;
    margin: 0;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  button {
    width: fit-content;
    height: 40px;
  }
`;

export const DataContainer = styled.div`
  position: relative;
  display: ${({ isData }) => (isData ? 'grid' : 'block')};
  grid-template-columns: 1fr 1fr;
  column-gap: 70px;

  & > div:first-child {
    display: flex;
    flex-direction: column;

    & > div:first-child {
      display: flex;
      align-items: center;
      column-gap: 30px;
      margin-bottom: 20px;
    }
  }
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-of-type {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
`;

export const CheckListContainer = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;

  p {
    margin: 0;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 10px;

      svg {
        cursor: pointer;
      }
    }

    & > p:first-of-type {
      color: ${gray};
    }
  }
`;

export const ListChecklist = styled.div`
  position: ${({ isOpen }) => (isOpen ? 'absolute' : 'none')};
  top: 50px;
  left: 0;
  z-index: 1;
  width: max-content;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;

  & > p {
    padding: 5px 0;

    &:hover {
      cursor: pointer;
      background-color: ${lighterBlue};
    }
  }
`;

export const PopupContainerChecklist = styled(PopupContainer)`
  display: flex;
  z-index: 3;
`;

export const PopupContentChecklist = styled(PopupContent)`
  min-width: 250px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & > span {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-family: ${rl};
  }

  h2 {
    color: red;
    margin-bottom: 5px;
  }

  p {
    font-size: 18px;
  }
`;

export const TDComment = styled.td`
  white-space: pre-wrap !important;
`;
