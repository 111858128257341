import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetTenderByApplicationExternalStoreState extends Reducer {
  data: ResponseData[];
}

export interface ResponseData {
  applicationId: number;
  tender: {
    id: number;
    createdDateTime: string;
    modifiedDateTime: string;
    customerINN: string;
    customerName: string;
    supplierINN: string;
    supplierName: string;
    publicationDate: string;
    startPrice: number;
    tenderType: any;
    tenderSegment: number;
    eruzId: string;
    protocolDate: string;
    hasWinner: boolean;
    contractConcluded: any;
    contractGuid: string;
    tenderCompleted: any;
    tenderFailed: any;
    tenderLink: string;
    writeoffRequest: any;
    ikz: string;
    subject: string;
    purchaseRegion: string;
    supplierDeterminationMethod: string;
    platformName: string;
    platformLink: string;
    status: string;
    isClosedAuction: boolean;
    isSingleSupplier: boolean;
    isPublic: boolean;
    sumFinal: number;
    auctionDate: string;
    customerEmail: string;
    customerPhone: string;
    isDeleted: boolean;
    lotCode: string;
    subjectType: any;
  };
}

export enum GET_TENDER_BY_APPLICATION_EXTERNAL {
  REQ = 'GET_TENDER_BY_APPLICATION_EXTERNAL_REQ',
  GOT = 'GET_TENDER_BY_APPLICATION_EXTERNAL_GOT',
  ERR = 'GET_TENDER_BY_APPLICATION_EXTERNAL_ERR'
}

export const initialState: GetTenderByApplicationExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getTenderByApplicationExternal = (
  state: GetTenderByApplicationExternalStoreState = initialState,
  action: {
    type: GET_TENDER_BY_APPLICATION_EXTERNAL;
    data: ResponseData[];
    error: {};
  }
): GetTenderByApplicationExternalStoreState => {
  switch (action.type) {
    case GET_TENDER_BY_APPLICATION_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_TENDER_BY_APPLICATION_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_TENDER_BY_APPLICATION_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getTenderByApplicationExternal;
