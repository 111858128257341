export enum POLICY_BENEFICIARY {
  '4 - Поручительство собственника не требуется' = '4 - Поручительство собственника не требуется',
  '3 - Частая практика отменять поручительство соственника' = '3 - Частая практика отменять поручительство соственника',
  '2 - В исключительных случаях без поручительства собственника' = '2 - В исключительных случаях без поручительства собственника',
  '1 - Строго с поручительством собственника' = '1 - Строго с поручительством собственника'
}

export enum POLICY_GUARANTEE {
  '4 - Поручительство генерального директора не требуется' = '4 - Поручительство генерального директора не требуется',
  '3 - Частая практика отменять поручительство генерального директора' = '3 - Частая практика отменять поручительство генерального директора',
  '2 - В исключительных случаях без поручительства генерального директора' = '2 - В исключительных случаях без поручительства генерального директора',
  '1 - Строго с поручительством генерального директора' = '1 - Строго с поручительством генерального директора'
}

export enum POLICY_OPENING {
  '3 – можно не открывать счет' = '3 – можно не открывать счет',
  '2 – обязательно открыть счет и можно не проводить обороты' = '2 – обязательно открыть счет и можно не проводить обороты',
  '1 – обязательно открыть счет и проводить обороты, но без залога прав выручки' = '1 – обязательно открыть счет и проводить обороты, но без залога прав выручки',
  '0 - обязательно открыть счет, проводить обороты и передать права выручки в залог' = '0 - обязательно открыть счет, проводить обороты и передать права выручки в залог'
}
