import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestDataType } from 'entities/SCF/Factor/model/actions/SCFRegisterFactor';

export interface SCFRegisterFactorStoreState extends Reducer {
  data: RequestDataType;
}

export enum SCF_REGISTER_FACTOR {
  SCF_REGISTER_FACTOR_REQ = 'SCF_REGISTER_FACTOR_REQ',
  SCF_REGISTER_FACTOR_GOT = 'SCF_REGISTER_FACTOR_GOT',
  SCF_REGISTER_FACTOR_ERR = 'SCF_REGISTER_FACTOR_ERR',
  SCF_REGISTER_FACTOR_RESET = 'SCF_REGISTER_FACTOR_RESET'
}

export const initialState: SCFRegisterFactorStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    legalForm: '',
    code: '',
    companyName: '',
    email: '',
    password: ''
  },
  error: {}
};

const SCFRegisterFactor = (
  state: SCFRegisterFactorStoreState = initialState,
  action: {
    type: string;
    data: RequestDataType;
    error: {};
  }
): SCFRegisterFactorStoreState => {
  switch (action.type) {
    case SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default SCFRegisterFactor;
