import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/SCF/actions/postDiscountingSettingsSignature';

import {
  POST_DISCOUNTING_SETTINGS_SIGNATURE,
  RequestDataType
} from 'src/features/SCF/reducers/postDiscountingSettingsSignature';

import SCFApi from 'src/features/SCF/api';

function* postDiscountingSettingsSignature(action: {
  type: POST_DISCOUNTING_SETTINGS_SIGNATURE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.postDiscountingSettingsSignature, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDiscountingSettingsSignatureSaga() {
  yield takeLatest(
    POST_DISCOUNTING_SETTINGS_SIGNATURE.REQ,
    postDiscountingSettingsSignature
  );
}
