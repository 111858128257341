import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/postNonFinancialDocumentExternal';

import { POST_NON_FINANCIAL_DOCUMENT_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/postNonFinancialDocumentExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* postNonFinancialDocumentExternal(action: {
  type: POST_NON_FINANCIAL_DOCUMENT_EXTERNAL;
  guid: string;
  data: FormData;
}): Generator {
  try {
    yield call(
      ExternalAgentRoleApi.postNonFinancialDocumentExternal,
      action.guid,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postNonFinancialDocumentExternalSaga(): Generator {
  yield takeLatest(
    POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.REQ,
    postNonFinancialDocumentExternal
  );
}
