import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum CONFIRM_ACCOUNT {
  CONFIRM_ACCOUNT_REQ = 'CONFIRM_ACCOUNT_REQ',
  CONFIRM_ACCOUNT_GOT = 'CONFIRM_ACCOUNT_GOT',
  CONFIRM_ACCOUNT_ERR = 'CONFIRM_ACCOUNT_ERR'
}

export const initialState: Reducer = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const confirmAccount = (
  state: Reducer = initialState,
  action: {
    type: string;
    error: {};
  }
): Reducer => {
  switch (action.type) {
    case CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default confirmAccount;
