import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getDocumentFormBySupplyContract';

export interface GetDocumentFormBySupplyContractStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT {
  REQ = 'GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT_REQ',
  GOT = 'GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT_GOT',
  ERR = 'GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT_ERR'
}

export const initialState: GetDocumentFormBySupplyContractStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    guid: '',
    name: '',
    isDefault: false
  },
  error: {}
};

const getDocumentFormBySupplyContract = (
  state: GetDocumentFormBySupplyContractStoreState = initialState,
  action: {
    type: GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT;
    data: ResponseDataType;
    error: {};
  }
): GetDocumentFormBySupplyContractStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DOCUMENT_FORM_BY_SUPPLY_CONTRACT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDocumentFormBySupplyContract;
