import ApiRequest from 'src/shared/utils/ApiRequest';

export default class DossierApi {
  static getFinancialDocuments(inn: string) {
    return ApiRequest(`/api/internal-agent/financial-document/${inn}`);
  }

  static getNonFinancialDocuments(name: string, inn: string) {
    return ApiRequest(
      `/api/internal-agent/nonfinancial-document?${name}=${inn}&subjects=FULL`
    );
  }
}
