import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postDocumentsOfDocumentLists';

import { POST_DOCUMENTS_OF_DOCUMENT_LIST } from '../reducers/postDocumentsOfDocumentList';
import ApplicationApi from '../api';

function* postDocumentsOfDocumentList(action: {
  type: POST_DOCUMENTS_OF_DOCUMENT_LIST;
  documentListGuid: string;
}): Generator {
  try {
    yield call(
      ApplicationApi.postDocumentsOfDocumentLists,
      action.documentListGuid
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDocumentsOfDocumentListSaga(): Generator {
  yield takeLatest(
    POST_DOCUMENTS_OF_DOCUMENT_LIST.REQ,
    postDocumentsOfDocumentList
  );
}
