import * as React from 'react';
import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { AgreementModal } from 'User/components';

import { PageWrapper } from 'shared/styled';

interface Props {
  registrationStatus: REQUEST_STATUSES;
  registrationError: ResponseError;
  email: string;
  registrationReset: () => void;
  children: any;
}

export class RegistrationPage extends React.Component<Props> {
  componentWillUnmount() {
    this.props.registrationReset();
  }

  defineErrorBlock = () => {
    switch (this.props.registrationError.code) {
      case 666:
        return (
          <ActionResponseBlock
            template={ACTION_RESPONSE_TEMPLATE.REGISTRATION_EMAIL_FAIL}
            templateData={this.props.email}
          />
        );
      default:
        return (
          <ActionResponseBlock
            template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
          />
        );
    }
  };

  render() {
    const { registrationStatus } = this.props;

    const registrationProcess = registrationStatus === REQUEST_STATUSES.NONE;
    const registrationRequest = registrationStatus === REQUEST_STATUSES.REQUEST;
    const registrationComplete = registrationStatus === REQUEST_STATUSES.GOT;
    const registrationFailed = registrationStatus === REQUEST_STATUSES.ERROR;

    return (
      <PageWrapper>
        {registrationProcess && (
          <React.Fragment>
            <AgreementModal />
            {this.props.children}
          </React.Fragment>
        )}

        {registrationRequest && <Loader />}
        {registrationFailed && this.defineErrorBlock()}

        <ActionResponseBlock
          showIn={registrationComplete}
          template={ACTION_RESPONSE_TEMPLATE.REGISTRATION_SUCCESS}
          templateData={this.props.email}
        />
      </PageWrapper>
    );
  }
}
