import { UPLOAD_PROPERTY_DOCUMENTS,  PropertyDocumentsRequestData } from '../reducers/uploadPropertyDocuments';

export const req = (documents: PropertyDocumentsRequestData) => ({
  type: UPLOAD_PROPERTY_DOCUMENTS.REQ,
  documents
});

export const got = () => ({
  type: UPLOAD_PROPERTY_DOCUMENTS.GOT,
});

export const err = (error: {}) => ({
  type: UPLOAD_PROPERTY_DOCUMENTS.ERR,
  error
});
