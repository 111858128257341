import {
  SagaRequestDataType,
  UPLOAD_DEBTOR_CONTRACT_DOCUMENT
} from 'entities/SCF/Debtor/model/reducers/uploadDebtorContractDocument';

export const req = (data: SagaRequestDataType) => ({
  type: UPLOAD_DEBTOR_CONTRACT_DOCUMENT.REQ,
  data
});

export const got = () => ({
  type: UPLOAD_DEBTOR_CONTRACT_DOCUMENT.GOT
});

export const err = (error: {}) => ({
  type: UPLOAD_DEBTOR_CONTRACT_DOCUMENT.ERR,
  error
});
