import {
    RequestDataType,
    SEND_SUB_SIGNATURE
} from 'entities/SCF/Debtor/model/reducers/sendSubSignature';

export const req = (data: RequestDataType) => ({
    type: SEND_SUB_SIGNATURE.REQ,
    data
});

export const got = () => ({
    type: SEND_SUB_SIGNATURE.GOT
});

export const err = (error: {}) => ({
    type: SEND_SUB_SIGNATURE.ERR,
    error
});
