import { MODAL_NAMES } from 'Modal/types';

export enum MODAL_ACTIONS {
  OPEN = 'MODAL_OPEN',
  CLOSE = 'MODAL_CLOSE'
}

export interface OpenModalDataType {
  name?: MODAL_NAMES;
  payload?: {
    [key: string]: any;
  };
}

export interface ModalStoreState {
  isActive: boolean;
  data: OpenModalDataType;
}

export const initialState: ModalStoreState = {
  isActive: false,
  data: {
    name: MODAL_NAMES.EMPTY,
    payload: {}
  }
};

const toggleModal = (
  state: ModalStoreState = initialState,
  action: {
    type: MODAL_ACTIONS;
    data: OpenModalDataType;
  }
) => {
  switch (action.type) {
    case MODAL_ACTIONS.OPEN:
      return {
        ...state,
        isActive: true,
        data: action.data
      };
    case MODAL_ACTIONS.CLOSE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default toggleModal;
