import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCustomerRequirements';

import { PATCH_CUSTOMER_REQUIREMENTS_ACTIONS } from '../reducers/patchCustomerRequirements';
import CustomerRequirementsApi from '../api';

function* patchCustomerRequirements(action: {
  type: PATCH_CUSTOMER_REQUIREMENTS_ACTIONS;
  id: string;
  data: any;
}): Generator {
  try {
    yield call(
      CustomerRequirementsApi.patchCustomerRequirements,
      action.id,
      action.data
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCustomerRequirementsSaga(): Generator {
  yield takeLatest(
    PATCH_CUSTOMER_REQUIREMENTS_ACTIONS.REQ,
    patchCustomerRequirements
  );
}
