import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { ValidatorMessages } from 'shared/constants';
import { passwordRules } from 'src/shared/utils/ValidationPatterns';
import { phoneRule, emailRule, validateINN } from 'src/shared/utils/ValidationRules';
import { Fields } from 'Business/components';

export const createValidation = () =>
  new Validator<Fields>({
    firstName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.firstNameRequire
      }
    ],
    lastName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.lastNameRequire
      }
    ],
    phoneNumber: [
      {
        rule: requiredRule,
        message: ValidatorMessages.phoneNumberRequire
      },
      {
        rule: phoneRule,
        message: ValidatorMessages.phoneNumberValid
      }
    ],
    email: [
      {
        rule: requiredRule,
        message: ValidatorMessages.emailRequire
      },
      {
        rule: emailRule,
        message: ValidatorMessages.emailValid
      }
    ],
    code: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      },
      {
        rule: (value, formData) => {
          if (value && value.length < 10) {
            return;
          }
          return validateINN(value);
        },
        message: ValidatorMessages.codeWarning
      }
    ],
    password: passwordRules,
    repeatPassword: [
      {
        rule: (passwordRepeat, passwordOriginal) =>
          passwordRepeat === passwordOriginal,
        message: ValidatorMessages.passwordsEquality,
        ruleId: 'passwordEqual'
      }
    ],
    rulesAgreement: [
      {
        rule: requiredRule,
        message: ValidatorMessages.agreementRequire
      }
    ]
  });
