import { combineReducers } from 'redux';
import getMonetaryClaimsInternalAgent, {
  GetMonetaryClaimsInternalAgentStoreState
} from './getMonetaryClaimsInternalAgent';
import getDebtorRegistries, {
  GetDebtorRegistriesInternalAgentStoreState
} from './getDebtorRegistries';
import getMonetaryClaimOne, {
  GetMonetaryClaimOneInternalAgentStoreState
} from './getMonetaryClaimOne';

export interface SCFInternalReducers {
  getMonetaryClaimsInternalAgent: GetMonetaryClaimsInternalAgentStoreState;
  getDebtorRegistries: GetDebtorRegistriesInternalAgentStoreState;
  getMonetaryClaimOne: GetMonetaryClaimOneInternalAgentStoreState;
}

export const SCFInternal = combineReducers({
  getMonetaryClaimsInternalAgent,
  getDebtorRegistries,
  getMonetaryClaimOne
});
