import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getTenderByApplication';

import { GET_TENDER_BY_APPLICATION } from '../reducers/getTenderByApplication';
import ApplicationApi from '../api';

function* getTenderByApplication(action: {
  type: GET_TENDER_BY_APPLICATION;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      ApplicationApi.getTenderByApplication,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getTenderByApplicationSaga(): Generator {
  yield takeLatest(GET_TENDER_BY_APPLICATION.REQ, getTenderByApplication);
}
