import { SCF_REGISTER_DEBTOR } from 'entities/SCF/Debtor/model/reducers/SCFRegisterDebtor';

export interface RequestDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  legalForm: string;
  code: string;
  companyName: string;
  email: string;
  password: string;
}

export const req = (data: RequestDataType) => ({
  type: SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_REQ,
  data
});
export const got = (data: RequestDataType) => ({
  type: SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_GOT
});
export const err = (error: {}) => ({
  type: SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_ERR,
  error
});
export const reset = () => ({
  type: SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_RESET
});
