import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationsList } from './ApplicationsList/ApplicationsList';
import { ApplicationInfo } from './ApplicationInfo/ApplicationInfo';
import {
  ContractsPageStyled,
} from './styles';

class FactoringApplicationsPage extends React.Component {
  render() {
    return (
      <ContractsPageStyled>
        <Switch>
          <Route
            exact={true}
            path={"/factor/factoring_applications"}
            render={() => <ApplicationsList />}
          />
          <Route
            exact={true}
            path={"/factor/factoring_applications/:guid"}
            render={() => <ApplicationInfo />}
          />
        </Switch>
      </ContractsPageStyled>
    );
  }
}

export default FactoringApplicationsPage;
