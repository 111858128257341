import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/sendSupplyContract';

import { SEND_SUPPLY_CONTRACT } from '../reducers/sendSupplyContract';
import SCFApi from '../api';
import {
  SupplyContract,
  resetSupplyContract
} from '../actions/setSupplyContract';

function* sendSupplyContract(action: {
  type: SEND_SUPPLY_CONTRACT;
  data: SupplyContract;
}): Generator {
  try {
    yield call(SCFApi.sendSupplyContract, action.data);
    yield put(got());
    yield put(resetSupplyContract());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendSupplyContractSaga(): Generator {
  yield takeLatest(SEND_SUPPLY_CONTRACT.REQ, sendSupplyContract);
}
