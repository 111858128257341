import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { AskStyled } from 'src/features/CooperationForProducts/components/styled';
import {
  Input,
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import {
  POLICY_BENEFICIARY,
  POLICY_GUARANTEE,
  POLICY_OPENING
} from 'src/features/ProductPledge/types';
import {
  PolicyBeneficiaryContainer,
  PolicyGuaranteeContainer,
  PolicyOpeningContainer,
  ProductMinimumProvision,
  ProductMinimumProvisionContainer
} from 'src/features/ProductPledge/components/styled';
import { req as patchPledge } from '../actions/patchPledge';
import { req as patchCabinetPledge } from '../actions/patchCabinetPledge';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  ownerGuaranteePolicy: POLICY_BENEFICIARY | number;
  activeOwnerGuaranteePolicy: boolean;
  ceoGuaranteePolicy: POLICY_GUARANTEE | number;
  activeCeoGuaranteePolicy: boolean;
  bankAccountPolicy: POLICY_OPENING | number;
  activeBankAccountPolicy: boolean;
  minimalPledgeShare: any;
  activeMinimalPledgeShare: boolean;
  comfortPledgeShare: any;
  activeComfortPledgeShare: boolean;
  isAdminMinimalPledgeShare: boolean;
  isAdminComfortPledgeShare: boolean;
  isAdminOwnerGuaranteePolicy: boolean;
  isAdminCeoGuaranteePolicy: boolean;
  isAdminBankAccountPolicy: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchPledge: (id: string, data: any) => void;
  patchCabinetPledge: (guid: string, data: any) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class PledgeForProducts extends React.Component<Props, State> {
  state: State = {
    ownerGuaranteePolicy: null,
    activeOwnerGuaranteePolicy: false,
    ceoGuaranteePolicy: null,
    activeCeoGuaranteePolicy: false,
    bankAccountPolicy: null,
    activeBankAccountPolicy: false,
    minimalPledgeShare: null,
    activeMinimalPledgeShare: false,
    comfortPledgeShare: null,
    activeComfortPledgeShare: false,
    isAdminMinimalPledgeShare: false,
    isAdminComfortPledgeShare: false,
    isAdminOwnerGuaranteePolicy: false,
    isAdminCeoGuaranteePolicy: false,
    isAdminBankAccountPolicy: false
  };

  componentDidMount() {
    if (this.props.product.minimalPledgeShare !== null) {
      this.setState({
        minimalPledgeShare: this.props.product.minimalPledgeShare,
        activeMinimalPledgeShare: true
      });
    }
    if (this.props.product.comfortPledgeShare !== null) {
      this.setState({
        comfortPledgeShare: this.props.product.comfortPledgeShare,
        activeComfortPledgeShare: true
      });
    }
    if (this.props.product.ownerGuaranteePolicy !== null) {
      this.setState({
        ownerGuaranteePolicy: this.props.product.ownerGuaranteePolicy,
        activeOwnerGuaranteePolicy: true
      });
    }
    if (this.props.product.ceoGuaranteePolicy !== null) {
      this.setState({
        ceoGuaranteePolicy: this.props.product.ceoGuaranteePolicy,
        activeCeoGuaranteePolicy: true
      });
    }
    if (this.props.product.bankAccountPolicy !== null) {
      this.setState({
        bankAccountPolicy: this.props.product.bankAccountPolicy,
        activeBankAccountPolicy: true
      });
    }
    this.setState({ ...this.props.product } as any);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.minimalPledgeShare !== this.state.minimalPledgeShare) {
      this.setState({ isAdminMinimalPledgeShare: false });
    }
    if (prevState.comfortPledgeShare !== this.state.comfortPledgeShare) {
      this.setState({ isAdminComfortPledgeShare: false });
    }
    if (prevState.ownerGuaranteePolicy !== this.state.ownerGuaranteePolicy) {
      this.setState({ isAdminOwnerGuaranteePolicy: false });
    }
    if (prevState.ceoGuaranteePolicy !== this.state.ceoGuaranteePolicy) {
      this.setState({ isAdminCeoGuaranteePolicy: false });
    }
    if (prevState.bankAccountPolicy !== this.state.bankAccountPolicy) {
      this.setState({ isAdminBankAccountPolicy: false });
    }
  }

  handleProductPolicyBeneficiaryClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    if (!this.state.activeOwnerGuaranteePolicy || isAdmin) {
      this.setState({ ownerGuaranteePolicy: item });
    }
  };

  handleProductPolicyGuaranteeClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    if (!this.state.activeCeoGuaranteePolicy || isAdmin) {
      this.setState({ ceoGuaranteePolicy: item });
    }
  };

  handleProductPolicyOpeningClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    if (!this.state.activeBankAccountPolicy || isAdmin) {
      this.setState({ bankAccountPolicy: item });
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    const patchObject: any = {};
    switch (key) {
      case 'activeOwnerGuaranteePolicy':
        patchObject.ownerGuaranteePolicy = +this.state.ownerGuaranteePolicy[0];
        break;
      case 'activeCeoGuaranteePolicy':
        patchObject.ceoGuaranteePolicy = +this.state.ceoGuaranteePolicy[0];
        break;
      case 'activeBankAccountPolicy':
        patchObject.bankAccountPolicy = +this.state.bankAccountPolicy[0];
        break;
      case 'activeMinimalPledgeShare':
        patchObject.minimalPledgeShare = +this.state.minimalPledgeShare;
        break;
      case 'activeComfortPledgeShare':
        patchObject.comfortPledgeShare = +this.state.comfortPledgeShare;
        break;
      default:
        patchObject[key] = value;
    }

    this.setState({ [key]: value } as Pick<any, keyof State>);

    if (this.props.roles.includes('Bank')) {
      this.props.patchCabinetPledge(id, patchObject);
    } else {
      this.props.patchPledge(id, patchObject);
    }
  };

  render() {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    return (
      <ApplicationViewStyled>
        <ProductMinimumProvisionContainer>
          <ProductMinimumProvision>
            <p>Минимальная доля обеспечения (%):</p>
            <Input
              active={this.state.activeMinimalPledgeShare}
              type="text"
              name="minimalPledgeShare"
              value={
                this.state.activeMinimalPledgeShare && !isAdmin
                  ? `${this.state.minimalPledgeShare} %.`
                  : this.state.minimalPledgeShare
              }
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMinimalPledgeShare}
            />
            {(!this.state.activeMinimalPledgeShare || isAdmin) &&
              !!this.state.minimalPledgeShare &&
              this.state.minimalPledgeShare !==
                this.props.product.minimalPledgeShare &&
              !this.state.isAdminMinimalPledgeShare && (
                <Button
                  label="Сохранить"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinimalPledgeShare: true });
                    }
                    this.handleButtonClick('activeMinimalPledgeShare', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minimalPledgeShare)}
                  h="40px"
                />
              )}
          </ProductMinimumProvision>
          <ProductMinimumProvision>
            <p>Комфортная доля обеспечения (%):</p>
            <Input
              active={this.state.activeComfortPledgeShare}
              type="text"
              name="comfortPledgeShare"
              value={
                this.state.activeComfortPledgeShare && !isAdmin
                  ? `${this.state.comfortPledgeShare} %.`
                  : this.state.comfortPledgeShare
              }
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeComfortPledgeShare}
            />
            {(!this.state.activeComfortPledgeShare || isAdmin) &&
              !!this.state.comfortPledgeShare &&
              this.state.comfortPledgeShare !==
                this.props.product.comfortPledgeShare &&
              !this.state.isAdminComfortPledgeShare && (
                <Button
                  label="Сохранить"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminComfortPledgeShare: true });
                    }
                    this.handleButtonClick('activeComfortPledgeShare', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.comfortPledgeShare)}
                  h="40px"
                />
              )}
          </ProductMinimumProvision>
        </ProductMinimumProvisionContainer>

        <PolicyBeneficiaryContainer
          product={this.state.ownerGuaranteePolicy}
          active={this.state.activeOwnerGuaranteePolicy}
        >
          <AskStyled>
            Политика в отношении поручительства бенефициара:
          </AskStyled>
          {Object.keys(POLICY_BENEFICIARY).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductPolicyBeneficiaryClick(item)}
              >
                <Square
                  active={
                    this.state.ownerGuaranteePolicy === item ||
                    this.state.ownerGuaranteePolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.ownerGuaranteePolicy === item &&
                (!this.state.activeOwnerGuaranteePolicy || isAdmin) &&
                !this.state.isAdminOwnerGuaranteePolicy && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeOwnerGuaranteePolicy}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminOwnerGuaranteePolicy: true });
                      }
                      this.handleButtonClick(
                        'activeOwnerGuaranteePolicy',
                        true
                      );
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </PolicyBeneficiaryContainer>

        <PolicyGuaranteeContainer
          product={this.state.ceoGuaranteePolicy}
          active={this.state.activeCeoGuaranteePolicy}
        >
          <AskStyled>
            Политика в отношении поручительства генерального директора:
          </AskStyled>
          {Object.keys(POLICY_GUARANTEE).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductPolicyGuaranteeClick(item)}
              >
                <Square
                  active={
                    this.state.ceoGuaranteePolicy === item ||
                    this.state.ceoGuaranteePolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.ceoGuaranteePolicy === item &&
                (!this.state.activeCeoGuaranteePolicy || isAdmin) &&
                !this.state.isAdminCeoGuaranteePolicy && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeCeoGuaranteePolicy}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminCeoGuaranteePolicy: true });
                      }
                      this.handleButtonClick('activeCeoGuaranteePolicy', true);
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </PolicyGuaranteeContainer>

        <PolicyOpeningContainer
          product={this.state.bankAccountPolicy}
          active={this.state.activeBankAccountPolicy}
        >
          <AskStyled>
            Политика в отношении открытия расчетного счета в банке:
          </AskStyled>
          {Object.keys(POLICY_OPENING).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductPolicyOpeningClick(item)}
              >
                <Square
                  active={
                    this.state.bankAccountPolicy === item ||
                    this.state.bankAccountPolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.bankAccountPolicy === item &&
                (!this.state.activeBankAccountPolicy || isAdmin) &&
                !this.state.isAdminBankAccountPolicy && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeBankAccountPolicy}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminBankAccountPolicy: true });
                      }
                      this.handleButtonClick('activeBankAccountPolicy', true);
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </PolicyOpeningContainer>
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Pledge }: CRM) => ({
  status: Pledge.patchPledge.status,
  error: Pledge.patchPledge.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchPledge, patchCabinetPledge }, dispatch);

const PledgeForProductsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(PledgeForProducts)
);

export { PledgeForProductsConnect as PledgeForProducts };
