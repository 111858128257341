import { GET_HOLDINGS_LIST } from '../reducers/getHoldingsList';
import { HoldingRead } from './setHoldingFormData';
import { HOLDING_LIST_TYPE } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: HoldingRead[];
}

export interface RequestData {
  page: number;
  pageSize: number;
}

export const req = (holdingListType: HOLDING_LIST_TYPE, data: RequestData) => ({
  type: GET_HOLDINGS_LIST.REQ,
  data,
  holdingListType
});

export const got = (data: ResponseData) => ({
  type: GET_HOLDINGS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_HOLDINGS_LIST.ERR,
  error
});
