import { SET_CONTACT_DATA } from '../reducers/setContactFormData';

export interface ContactWrite {
  additionalInfo?: null;
  companyINN?: string;
  deleteComment?: string;
  deleteInitiator?: number;
  email?: null;
  firstName?: string;
  id?: number;
  isDeleted?: boolean;
  lastName?: string;
  loansRaci?: null;
  loansSales?: null;
  middleName?: string;
  partnerRaci?: null;
  phoneContact?: null;
  phoneNumber?: null;
  phoneStationary?: null;
  position?: string;
  refusalMonetize?: null;
  riskRaci?: null;
  salesRaci?: null;
  source?: null;
  suppliesRaci?: null;
  tendersRaci?: null;
  transformationRaci?: null;
  treasuryRaci?: null;
}

export interface ContactRead extends ContactWrite {}

export interface ContactsData extends Partial<ContactWrite> {}

export const setContactFormData = (data: ContactsData) => ({
  type: SET_CONTACT_DATA.SET,
  data
});

export const resetContactFormData = () => ({
  type: SET_CONTACT_DATA.RESET
});
