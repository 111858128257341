import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Loader } from 'Layouts/components';
import {
  AddCommissionButton,
  CheckedIcon,
  CommissionInput,
  CommissionSelect,
  CrossIcon,
  FlexContainer,
  PageBlock,
  SuppliersRate,
  DeclineContainer,
  CheckContainer,
  SavedCommission,
  ManualDiscountBlock,
  AbsoluteValue,
  LinkedToKS
} from './styles';
import {
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  TypeContainer,
  TypeNoActiveChangable,
  TypeYesActiveChangable,
  TypeNoInactiveChangable,
  SelectActive,
  SelectInactive,
  SelectContainerActiveChangable,
  SelectContainerInactiveChangable,
  TypeYesInactiveChangable,
  SelectContainerActive,
  SelectContainerInactive
} from './settingsStyles';
import {
  BtnsContainer,
  BackBtn,
  BackBtnText,
  CreateBtn,
  CreateBtnText,
  DisabledBtn,
  BtnSubBlock,
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  PopupExit,
  PopupExitError,
  NudgeArea,
  Nudge,
  CreateBtnContainer
} from 'src/features/SCF/UI/ScfStyles';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { req as getKeyRates } from 'src/features/SCF/actions/getKeyRates';
import {
  req as getFactoringSettings,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Factor/model/actions/getCurrentFactoringSettingsByDebtorInn';
import {
  req as getFactoringFeesOfLimits,
  Limit,
  RequestDataType as factoringFeesOfLimitsReq
} from 'entities/SCF/Factor/model/actions/getFactoringFeesOfLimits';
import {
  req as sendFactoringSettings,
  RequestData as SendFactoringSettings,
  ResponseGuid
} from 'src/features/SCF/actions/sendFactoringSettingsByDebtorInn';
import {
  req as postFactoringFeesOfLimits,
  reset as resetFactoringFeesOfLimits
} from 'entities/SCF/Factor/model/actions/postFactoringFeesOfLimits';
import { RequestDataType as postFactoringFeesOfLimitsReq } from 'entities/SCF/Factor/model/reducers/postFactoringFeesOfLimits';
import { req as patchFactoringFeesOfLimitsReq } from 'entities/SCF/Factor/model/actions/patchFactoringFeesOfLimits';
import { RequestDataType as patchFactoringFeesOfLimitsRes } from 'entities/SCF/Factor/model/reducers/patchFactoringFeesOfLimits';
import { req as deleteFactoringFeesOfLimits } from 'entities/SCF/Factor/model/actions/deleteFactoringFeesOfLimits';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { navyBlue } from 'shared/styled';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import '../../styles/style.css';

interface StateToProps {
  status: REQUEST_STATUSES;
  sendStatus: REQUEST_STATUSES;
  error: ResponseError;
  factoringSettings: ResponseDataType;
  responseGuid: ResponseGuid;
  responseGuidStatus: REQUEST_STATUSES;
  responseGuidError: ResponseError;
  commissions: Limit[];
  statusCommission: REQUEST_STATUSES;
  statusPostCommission: REQUEST_STATUSES;
  keyRate: number;
}

interface DispatchToProps {
  getFactoringSettings: (data: RequestDataType) => void;
  sendFactoringSettings: (data: SendFactoringSettings) => void;
  getFactoringFeesOfLimits: (
    inn: string,
    data?: factoringFeesOfLimitsReq
  ) => void;
  postFactoringFeesOfLimits: (
    inn: string,
    data: postFactoringFeesOfLimitsReq
  ) => void;
  resetFactoringFeesOfLimits: () => void;
  patchFactoringFeesOfLimitsReq: (
    guid: string,
    data: patchFactoringFeesOfLimitsRes
  ) => void;
  deleteFactoringFeesOfLimits: (guid: string) => void;
  getKeyRates: () => void;
}

interface State {
  volumeState: string;
  autoLimitPolicyState: number;
  limitUsageState: number;
  defaultMaximalClaimSumState: number;
  isRegressedState: boolean;
  isNotHiddenState: boolean;
  defaultMinimalClaimDaysToMaturityState: number;
  defaultMaximalClaimDaysToMaturityState: number;
  factoringBaseRateState: number;
  vatSizeState: number;
  factoringFullRateState: number;
  factualUsageOnlyState: boolean;
  defaultGracePeriodDaysState: number;
  gracePeriodBaseRateState: number;
  gracePeriodFullRate: number;
  defaultRegressPeriodDaysState: number;
  regressPeriodBaseRateState: number;
  regressPeriodFullRate: number;
  defaultMinimalCessionShareState: number;
  defaultMaximalCessionShareState: number;
  defaultMinimalShareOfFundingState: number;
  defaultMaximalShareOfFundingState: number;
  regressPeriodIsInfiniteState: boolean;
  monetaryClaim: number;
  remainingPayment: number;
  factoringRateBase: '1' | '2' | '3';
  commissions: Limit[];
  isCommissionDelete: number;
  rateIsSurplusToKeyRate: boolean;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class LimitSettingsEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        volumeState: this.props.factoringSettings.volume.toString(),
        autoLimitPolicyState: this.props.factoringSettings.autoLimitPolicy,
        limitUsageState: this.props.factoringSettings.limitUsage,
        defaultMaximalClaimSumState: this.props.factoringSettings
          .defaultMaximalClaimSum,
        isRegressedState: this.props.factoringSettings.isRegressed,
        isNotHiddenState: this.props.factoringSettings.isNotHidden,
        defaultMaximalClaimDaysToMaturityState: this.props.factoringSettings
          .defaultMaximalClaimDaysToMaturity,
        defaultMinimalClaimDaysToMaturityState: this.props.factoringSettings
          .defaultMinimalClaimDaysToMaturity,
        factoringBaseRateState: this.props.factoringSettings.factoringBaseRate,
        vatSizeState: this.props.factoringSettings.vatSize,
        factoringFullRateState: this.props.factoringSettings.factoringFullRate,
        factualUsageOnlyState: this.props.factoringSettings.factualUsageOnly,
        defaultGracePeriodDaysState: this.props.factoringSettings
          .defaultGracePeriodDays,
        gracePeriodBaseRateState: this.props.factoringSettings
          .gracePeriodBaseRate,
        defaultRegressPeriodDaysState: this.props.factoringSettings
          .defaultRegressPeriodDays,
        regressPeriodBaseRateState: this.props.factoringSettings
          .regressPeriodBaseRate,
        regressPeriodFullRate: this.props.factoringSettings
          .regressPeriodFullRate,
        defaultMinimalCessionShareState: this.props.factoringSettings
          .defaultMinimalCessionShare,
        defaultMaximalCessionShareState: this.props.factoringSettings
          .defaultMaximalCessionShare,
        defaultMinimalShareOfFundingState: this.props.factoringSettings
          .defaultMinimalShareOfFunding,
        defaultMaximalShareOfFundingState: this.props.factoringSettings
          .defaultMaximalShareOfFunding,
        regressPeriodIsInfiniteState: this.props.factoringSettings
          .regressPeriodIsInfinite,
        factoringRateBase: this.props.factoringSettings.factoringRateBase,
        monetaryClaim: 1000000,
        remainingPayment: 60,
        commissions: this.props.commissions.map(commission => ({
          ...commission,
          status: 1
        })),
        isCommissionDelete: null,
        gracePeriodFullRate: this.props.factoringSettings.gracePeriodFullRate,
        rateIsSurplusToKeyRate:
          this.props.factoringSettings.rateIsSurplusToKeyRate || false
      };

      this.autoLimitPolicyStateChange = this.autoLimitPolicyStateChange.bind(
        this
      );
    }
  }

  initFetch = () => {
    const {
      getKeyRates,
      getFactoringSettings,
      getFactoringFeesOfLimits,
      match
    } = this.props;
    if (match.params.id) {
      getFactoringSettings({ debtorInn: match.params.id });
      getFactoringFeesOfLimits(match.params.id);
      getKeyRates();
    }
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.sendStatus === REQUEST_STATUSES.GOT &&
      prevProps.sendStatus !== REQUEST_STATUSES.GOT
    ) {
      this.openSignPopup();
    }
    if (prevProps.statusCommission !== this.props.statusCommission) {
      this.setState({
        commissions: this.props.commissions.map(commission => ({
          ...commission,
          status: 1
        }))
      });
    }
  }

  autoLimitPolicyStateChange() {
    {
      this.state.autoLimitPolicyState === 2
        ? this.setState({ autoLimitPolicyState: 1 })
        : this.setState({ autoLimitPolicyState: 2 });
    }
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({ [name]: value } as any);
  };

  handleInputChange = (index: number, e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    const updatedCommissions = [...this.state.commissions];

    if (updatedCommissions[index].status === 1) {
      updatedCommissions[index].status = 2;
    }
    updatedCommissions[index].changed = true;

    if (name === 'feeBaseSumm' || name === 'vatPercentage') {
      updatedCommissions[index][name] = +value.replace(/[^\d.]/g, '');
    } else {
      updatedCommissions[index][name] = value;
    }

    this.setState({ commissions: updatedCommissions });
  };

  handleCheckboxChange = (name: keyof State) => {
    this.setState(
      prevState => ({ ...prevState, [name]: !prevState[name] } as any)
    );
  };

  handleChangeFactoringRateBase = (value: '1' | '2' | '3') => {
    if (this.state.factoringRateBase === value) {
      this.setState({ factoringRateBase: null });
    }
    this.setState({ factoringRateBase: value });
  };

  onBackClick = () => {
    const { history, match } = this.props;
    history.push(`/factor/limits/${match.params.id}`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  openSignPopup = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseIconClickSub = () => {
    const sign = document.getElementById('certificatesSubPopup');
    sign.style.display = 'none';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    this.props.history.push({ pathname: `/factor/settings/certificates/edit` });
  };

  onSignClick = () => {
    this.props.sendFactoringSettings({
      debtorInn: window.location.href.split('/').reverse()[1],
      volume: parseFloat(this.state.volumeState.replaceAll(' ', '')),
      autoLimitPolicy: this.state.autoLimitPolicyState,
      factoringBaseRate: this.state.factoringBaseRateState,
      factoringFullRate: this.state.factoringFullRateState,
      vatSize: this.state.vatSizeState,
      factualUsageOnly: this.state.factualUsageOnlyState,
      gracePeriodBaseRate: this.state.gracePeriodBaseRateState,
      gracePeriodFullRate: this.state.gracePeriodFullRate,
      regressPeriodBaseRate: this.state.regressPeriodBaseRateState,
      regressPeriodFullRate: this.state.regressPeriodFullRate,
      defaultGracePeriodDays: this.state.defaultGracePeriodDaysState,
      defaultRegressPeriodDays:
        this.state.defaultRegressPeriodDaysState || null,
      regressPeriodIsInfinite: this.state.regressPeriodIsInfiniteState,
      defaultMaximalClaimDaysToMaturity: this.state
        .defaultMaximalClaimDaysToMaturityState,
      defaultMaximalClaimSum: this.state.defaultMaximalClaimSumState,
      defaultMaximalShareOfFunding: this.state
        .defaultMaximalShareOfFundingState,
      defaultMaximalCessionShare: this.state.defaultMaximalCessionShareState,
      defaultMinimalClaimDaysToMaturity: this.state
        .defaultMinimalClaimDaysToMaturityState,
      defaultMinimalShareOfFunding: this.state
        .defaultMinimalShareOfFundingState,
      defaultMinimalCessionShare: this.state.defaultMinimalCessionShareState,
      isRegressed: this.state.isRegressedState,
      isNotHidden: this.state.isNotHiddenState,
      factoringRateBase: this.state.factoringRateBase,
      rateIsSurplusToKeyRate: this.state.rateIsSurplusToKeyRate
    });
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({ [name]: value } as any);
  };

  concededClaim = (): number => {
    return (
      (this.state.monetaryClaim * this.state.defaultMaximalCessionShareState) /
      100
    );
  };

  amountOfFinancing = (): number => {
    return (
      (this.concededClaim() * this.state.defaultMaximalShareOfFundingState) /
      100
    );
  };

  actualAmountOfFinancing = (): number => {
    return (
      this.amountOfFinancing() -
      this.commissionAtTheTime() -
      this.VATonCommissionsAtTheTime()
    );
  };

  interestPaymentsExcludingVAT = (): number => {
    const factoringBaseRate =
      this.state.rateIsSurplusToKeyRate === false
        ? this.state.factoringBaseRateState
        : this.props.keyRate + this.state.factoringBaseRateState;

    return this.state.factoringRateBase === '1'
      ? this.state.monetaryClaim *
          (this.state.remainingPayment / 365) *
          (factoringBaseRate / 100)
      : this.state.factoringRateBase === '2'
        ? this.concededClaim() *
          (this.state.remainingPayment / 365) *
          (factoringBaseRate / 100)
        : this.state.factoringRateBase === '3' &&
          this.amountOfFinancing() *
            (this.state.remainingPayment / 365) *
            (factoringBaseRate / 100);
  };

  VATOnInterestPayments = (): number => {
    return (
      (this.interestPaymentsExcludingVAT() * this.state.vatSizeState) / 100
    );
  };

  fullAmountExcludingVAT = (): number => {
    return (
      this.interestPaymentsExcludingVAT() +
      this.commissionsAtTheTimeOfRepayment() +
      this.commissionAtTheTime()
    );
  };

  VATOnPaymentsAndCommissions = (): number => {
    return (
      this.VATOnInterestPayments() +
      this.VATOnCommissionsAtTheTimeOfRepayment() +
      this.VATonCommissionsAtTheTime()
    );
  };

  addCommission = () => {
    this.setState(prevState => ({
      commissions: [
        ...prevState.commissions,
        {
          guid: '',
          name: '',
          debtorINN: '',
          feeBase: 1,
          feeBaseSumm: null,
          momentOfPayment: 1,
          vatPercentage: null,
          status: 3,
          changed: false
        }
      ]
    }));
  };

  handleSaveCommission = (data: Limit, index: number) => {
    const {
      postFactoringFeesOfLimits,
      patchFactoringFeesOfLimitsReq,
      match
    } = this.props;

    if (data.status === 3) {
      const { debtorINN, guid, status, changed, ...rest } = data;
      postFactoringFeesOfLimits(match.params.id, rest);
    } else if (
      data.status === 2 &&
      data.changed &&
      data.feeBaseSumm &&
      data.feeBaseSumm > 0
    ) {
      const { status, guid, changed, ...rest } = data;
      patchFactoringFeesOfLimitsReq(guid, rest);
    }

    const updatedCommissions = [...this.state.commissions];
    updatedCommissions[index].changed = false;
    updatedCommissions[index].status = 1;
    this.setState({ commissions: updatedCommissions });
  };

  handleDeleteCommission = (data: Limit, findIndex: number) => {
    const { deleteFactoringFeesOfLimits } = this.props;

    this.setState({
      commissions: this.state.commissions.filter((_, i) => i !== findIndex)
    });

    if (!!data.guid) {
      deleteFactoringFeesOfLimits(data.guid);
    }
  };

  commissionAtTheTime = (): number => {
    const sum = this.state.commissions.reduce((total, item) => {
      if (item.momentOfPayment == 1) {
        if (item.feeBase == 4) {
          return total + item.feeBaseSumm;
        } else if (item.feeBase == 3) {
          return total + (this.amountOfFinancing() * item.feeBaseSumm) / 100;
        } else if (item.feeBase == 2) {
          return total + (this.concededClaim() * item.feeBaseSumm) / 100;
        } else if (item.feeBase == 1) {
          return total + (this.state.monetaryClaim * item.feeBaseSumm) / 100;
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  VATonCommissionsAtTheTime = (): number => {
    const sum = this.state.commissions.reduce((total, item) => {
      if (item.momentOfPayment == 1) {
        if (item.feeBase == 4) {
          return total + (item.feeBaseSumm * item.vatPercentage) / 100;
        } else if (item.feeBase == 3) {
          return (
            total +
            (((this.amountOfFinancing() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase == 2) {
          return (
            total +
            (((this.concededClaim() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase == 1) {
          return (
            total +
            (((this.state.monetaryClaim * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  commissionsAtTheTimeOfRepayment = (): number => {
    const sum = this.state.commissions.reduce((total, item) => {
      if (item.momentOfPayment == 2) {
        if (item.feeBase == 4) {
          return total + item.feeBaseSumm;
        } else if (item.feeBase == 3) {
          return total + (this.amountOfFinancing() * item.feeBaseSumm) / 100;
        } else if (item.feeBase == 2) {
          return total + (this.concededClaim() * item.feeBaseSumm) / 100;
        } else if (item.feeBase == 1) {
          return total + (this.state.monetaryClaim * item.feeBaseSumm) / 100;
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  VATOnCommissionsAtTheTimeOfRepayment = (): number => {
    const sum = this.state.commissions.reduce((total, item) => {
      if (item.momentOfPayment == 2) {
        if (item.feeBase == 4) {
          return total + (item.feeBaseSumm * item.vatPercentage) / 100;
        } else if (item.feeBase == 3) {
          return (
            total +
            (((this.amountOfFinancing() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase == 2) {
          return (
            total +
            (((this.concededClaim() * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        } else if (item.feeBase == 1) {
          return (
            total +
            (((this.state.monetaryClaim * item.feeBaseSumm) / 100) *
              item.vatPercentage) /
              100
          );
        }
      } else {
        return total;
      }
    }, 0);

    return sum;
  };

  render() {
    const {
      status,
      factoringSettings,
      responseGuidStatus,
      responseGuid
    } = this.props;

    return (
      <>
        <PageBlock style={{ marginLeft: '50px' }}>
          <div>
            {status === REQUEST_STATUSES.REQUEST && <Loader />}
            <p>
              Указанные здесь настройки будут отображаться по умолчанию для
              клиентов, с которыми еще не заключен договор факторинга.
              <br />
              Для клиентов с заключенными договорами факторинга будут
              отображаться условия соответствующих договоров. <br />
              Для поставщиков, исключенных из работы банком, условия
              отображаться не будут.
            </p>

            <BtnsContainer>
              <DisabledBtn>
                <p>Редактируется</p>
              </DisabledBtn>
              <BtnSubBlock>
                <CreateBtnContainer
                  changed={this.state.commissions.some(
                    item => item.status === 2 || item.status === 3
                  )}
                >
                  <CreateBtn
                    onClick={this.onSignClick}
                    disabled={this.state.commissions.some(
                      item => item.status === 2 || item.status === 3
                    )}
                  >
                    <CreateBtnText>Подписать ЭЦП</CreateBtnText>
                  </CreateBtn>
                  {this.state.commissions.some(
                    item => item.status === 2 || item.status === 3
                  ) && <p>Есть несохраненные комиссии</p>}
                </CreateBtnContainer>
                <BackBtn onClick={this.onBackClick}>
                  <BackBtnText>Назад</BackBtnText>
                </BackBtn>
              </BtnSubBlock>
            </BtnsContainer>

            <ContractsInfoArea>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Объем лимита:</ContractsInfoItemTitle>
                <InputRange
                  className="contract-input"
                  value={this.state.volumeState || ''}
                  name="volumeState"
                  placeholder="Введите сумму"
                  min={0}
                  max={10000000000000}
                  step={0}
                  sliderType={RANGE_SLIDER_TYPE.SUM}
                  valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                  required={true}
                  disableSlider={true}
                  onChange={this.handleChange}
                />
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                {this.state.autoLimitPolicyState == 2 ? (
                  <SelectContainerActiveChangable
                    onClick={this.autoLimitPolicyStateChange}
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={this.autoLimitPolicyStateChange}
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>Автоматически уменьшать на сумму использования лимита</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Использование лимита:
                </ContractsInfoItemTitle>
                {factoringSettings.limitUsage > 0 ? (
                  <p style={{ width: '150px', textAlign: 'end' }}>
                    {factoringSettings.limitUsage.toLocaleString('ru', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </p>
                ) : (
                  <p style={{ width: '150px', textAlign: 'end' }}>0</p>
                )}
                <p>руб.</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Свободный остаток:
                </ContractsInfoItemTitle>
                <p style={{ width: '150px', textAlign: 'end' }}>
                  {(
                    factoringSettings.defaultMaximalClaimSum -
                    factoringSettings.limitUsage
                  ).toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </p>
                <p>руб.</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Тип факторинга:</h1>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Без регресса</ContractsInfoItemTitle>
                {this.state.isRegressedState == true ? (
                  <TypeContainer>
                    <TypeNoActiveChangable>
                      <p>нет</p>
                    </TypeNoActiveChangable>
                    <TypeYesInactiveChangable
                      onClick={() =>
                        this.handleCheckboxChange('isRegressedState')
                      }
                    >
                      <p>да</p>
                    </TypeYesInactiveChangable>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoInactiveChangable
                      onClick={() =>
                        this.handleCheckboxChange('isRegressedState')
                      }
                    >
                      <p>нет</p>
                    </TypeNoInactiveChangable>
                    <TypeYesActiveChangable>
                      <p>да</p>
                    </TypeYesActiveChangable>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Открытый</ContractsInfoItemTitle>
                {this.state.isNotHiddenState == false ? (
                  <TypeContainer>
                    <TypeNoActiveChangable>
                      <p>нет</p>
                    </TypeNoActiveChangable>
                    <TypeYesInactiveChangable
                      onClick={() =>
                        this.handleCheckboxChange('isNotHiddenState')
                      }
                    >
                      <p>да</p>
                    </TypeYesInactiveChangable>
                  </TypeContainer>
                ) : (
                  <TypeContainer>
                    <TypeNoInactiveChangable
                      onClick={() =>
                        this.handleCheckboxChange('isNotHiddenState')
                      }
                    >
                      <p>нет</p>
                    </TypeNoInactiveChangable>
                    <TypeYesActiveChangable>
                      <p>да</p>
                    </TypeYesActiveChangable>
                  </TypeContainer>
                )}
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Параметры финансирования:</h1>
              <ContractsInfoItemContainer>
                <p>Срок отсрочки от</p>
                <input
                  className="short-input"
                  type="number"
                  min={0}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  step={1}
                  name="defaultMinimalClaimDaysToMaturityState"
                  value={
                    this.state.defaultMinimalClaimDaysToMaturityState || ''
                  }
                  onChange={this.handleChange}
                />
                <p>до</p>
                <input
                  className="short-input"
                  type="number"
                  min={0}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  step={1}
                  name="defaultMaximalClaimDaysToMaturityState"
                  value={
                    this.state.defaultMaximalClaimDaysToMaturityState || ''
                  }
                  onChange={this.handleChange}
                />
                <p>дней</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                <ContractsInfoItemTitle>
                  Ставка в период отсрочки:
                </ContractsInfoItemTitle>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>
                  База расчета для ставки:
                </ContractsInfoItemTitle>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
                {this.state.factoringRateBase === '3' ? (
                  <SelectContainerActiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('3')}
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('3')}
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>Сумма финансирования</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
                {this.state.factoringRateBase === '2' ? (
                  <SelectContainerActiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('2')}
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('2')}
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>Сумма уступленного денежного требования</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
                {this.state.factoringRateBase === '1' ? (
                  <SelectContainerActiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('1')}
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() => this.handleChangeFactoringRateBase('1')}
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>Базовая сумма денежного требования</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ManualDiscountBlock>
                  <ContractsInfoItemTitle>
                    Базовая ставка факторинга
                  </ContractsInfoItemTitle>

                  <div>
                    <AbsoluteValue>
                      {this.state.rateIsSurplusToKeyRate === false ? (
                        <>
                          <SelectContainerActiveChangable
                            onClick={() =>
                              this.handleCheckboxChange(
                                'rateIsSurplusToKeyRate'
                              )
                            }
                          >
                            <SelectActive />
                          </SelectContainerActiveChangable>
                          <p>Абсолютное значение</p>
                          <input
                            className="short-input"
                            type="number"
                            min={0}
                            onKeyPress={e => {
                              if (e.key === 'e' || e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            step={0.05}
                            name="factoringBaseRateState"
                            value={this.state.factoringBaseRateState || ''}
                            onChange={this.handleChange}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactiveChangable
                            onClick={() =>
                              this.handleCheckboxChange(
                                'rateIsSurplusToKeyRate'
                              )
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactiveChangable>
                          <p>Абсолютное значение</p>
                        </>
                      )}
                    </AbsoluteValue>

                    <LinkedToKS>
                      {this.state.rateIsSurplusToKeyRate === true ? (
                        <>
                          <SelectContainerActiveChangable
                            onClick={() =>
                              this.handleCheckboxChange(
                                'rateIsSurplusToKeyRate'
                              )
                            }
                          >
                            <SelectActive />
                          </SelectContainerActiveChangable>
                          <p>Привязаное к КС</p>
                          <div>
                            <p>Ключ +</p>
                            <input
                              className="short-input"
                              type="number"
                              min={0}
                              onKeyPress={e => {
                                if (e.key === 'e' || e.key === '-') {
                                  e.preventDefault();
                                }
                              }}
                              step={0.05}
                              name="factoringBaseRateState"
                              value={this.state.factoringBaseRateState || ''}
                              onChange={this.handleChange}
                            />
                            <p style={{ marginLeft: '10px' }}>% годовых</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactiveChangable
                            onClick={() =>
                              this.handleCheckboxChange(
                                'rateIsSurplusToKeyRate'
                              )
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactiveChangable>
                          <p>Привязаное к КС</p>
                        </>
                      )}
                    </LinkedToKS>
                  </div>
                </ManualDiscountBlock>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
                <input
                  className="short-input"
                  type="number"
                  min={0}
                  onKeyPress={e => {
                    if (e.key === 'e' || e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  step={0.05}
                  name="vatSizeState"
                  value={this.state.vatSizeState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>
                  Полная ставка факторинга
                </ContractsInfoItemTitle>
                <p>
                  {(this.state.rateIsSurplusToKeyRate === false
                    ? this.state.factoringBaseRateState *
                      (1 + this.state.vatSizeState / 100)
                    : (this.props.keyRate + this.state.factoringBaseRateState) *
                      (1 + this.state.vatSizeState / 100)
                  ).toFixed(2)}
                </p>
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                {this.state.factualUsageOnlyState === true ? (
                  <SelectContainerActiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('factualUsageOnlyState')
                    }
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('factualUsageOnlyState')
                    }
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>Оплата только за фактическое использование</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                <ContractsInfoItemTitle>
                  Льготный период:
                </ContractsInfoItemTitle>
                <input
                  style={{ marginLeft: '40px' }}
                  className="short-input"
                  type="number"
                  min={0}
                  step={1}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="defaultGracePeriodDaysState"
                  value={this.state.defaultGracePeriodDaysState || ''}
                  onChange={this.handleChange}
                />
                <p>дней</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ManualDiscountBlock>
                  <ContractsInfoItemTitle>
                    Базовая ставка льготного периода
                  </ContractsInfoItemTitle>

                  <div>
                    <AbsoluteValue>
                      {this.state.rateIsSurplusToKeyRate === false ? (
                        <>
                          <SelectContainerInactive>
                            <SelectActive />
                          </SelectContainerInactive>
                          <p>Абсолютное значение</p>
                          <input
                            className="short-input"
                            type="number"
                            min={0}
                            onKeyPress={e => {
                              if (e.key === 'e' || e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            step={0.05}
                            name="gracePeriodBaseRateState"
                            value={this.state.gracePeriodBaseRateState || ''}
                            onChange={this.handleChange}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactive>
                            <SelectInactive />
                          </SelectContainerInactive>
                          <p>Абсолютное значение</p>
                        </>
                      )}
                    </AbsoluteValue>

                    <LinkedToKS>
                      {this.state.rateIsSurplusToKeyRate === true ? (
                        <>
                          <SelectContainerInactive>
                            <SelectActive />
                          </SelectContainerInactive>
                          <p>Привязаное к КС</p>
                          <div>
                            <p>Ключ +</p>
                            <input
                              className="short-input"
                              type="number"
                              min={0}
                              onKeyPress={e => {
                                if (e.key === 'e' || e.key === '-') {
                                  e.preventDefault();
                                }
                              }}
                              step={0.05}
                              name="gracePeriodBaseRateState"
                              value={this.state.gracePeriodBaseRateState || ''}
                              onChange={this.handleChange}
                            />
                            <p style={{ marginLeft: '10px' }}>% годовых</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactive>
                            <SelectInactive />
                          </SelectContainerInactive>
                          <p>Привязаное к КС</p>
                        </>
                      )}
                    </LinkedToKS>
                  </div>
                </ManualDiscountBlock>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
                <input
                  className="short-input"
                  type="number"
                  min={0}
                  onKeyPress={e => {
                    if (e.key === 'e' || e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  step={0.05}
                  name="vatSizeState"
                  value={this.state.vatSizeState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>
                  Полная ставка льготного периода
                </ContractsInfoItemTitle>
                <p>
                  {(this.state.rateIsSurplusToKeyRate === false
                    ? this.state.gracePeriodBaseRateState *
                      (1 + this.state.vatSizeState / 100)
                    : (this.props.keyRate +
                        this.state.gracePeriodBaseRateState) *
                      (1 + this.state.vatSizeState / 100)
                  ).toFixed(2)}
                </p>
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginTop: '15px' }}>
                <ContractsInfoItemTitle>
                  Период регресса:
                </ContractsInfoItemTitle>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                {this.state.regressPeriodIsInfiniteState === false ? (
                  <SelectContainerActiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('regressPeriodIsInfiniteState')
                    }
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('regressPeriodIsInfiniteState')
                    }
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>не более</p>
                {this.state.regressPeriodIsInfiniteState === false ? (
                  <input
                    className="short-input"
                    type="number"
                    min={0}
                    onKeyPress={e => {
                      if (e.key === 'e' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    name="defaultRegressPeriodDaysState"
                    value={this.state.defaultRegressPeriodDaysState || ''}
                    onChange={this.handleChange}
                  />
                ) : (
                  <input className="short-input" disabled={true} value={'-'} />
                )}
                <p>дней</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                {this.state.regressPeriodIsInfiniteState === true ? (
                  <SelectContainerActiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('regressPeriodIsInfiniteState')
                    }
                  >
                    <SelectActive />
                  </SelectContainerActiveChangable>
                ) : (
                  <SelectContainerInactiveChangable
                    onClick={() =>
                      this.handleCheckboxChange('regressPeriodIsInfiniteState')
                    }
                  >
                    <SelectInactive />
                  </SelectContainerInactiveChangable>
                )}
                <p>не ограничен до даты погашения</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ManualDiscountBlock>
                  <ContractsInfoItemTitle>
                    Базовая ставка периода регресса
                  </ContractsInfoItemTitle>

                  <div>
                    <AbsoluteValue>
                      {this.state.rateIsSurplusToKeyRate === false ? (
                        <>
                          <SelectContainerInactive>
                            <SelectActive />
                          </SelectContainerInactive>
                          <p>Абсолютное значение</p>
                          <input
                            className="short-input"
                            type="number"
                            min={0}
                            onKeyPress={e => {
                              if (e.key === 'e' || e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            step={0.05}
                            name="regressPeriodBaseRateState"
                            value={this.state.regressPeriodBaseRateState || ''}
                            onChange={this.handleChange}
                          />
                          <p style={{ marginLeft: '10px' }}>% годовых</p>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactive>
                            <SelectInactive />
                          </SelectContainerInactive>
                          <p>Абсолютное значение</p>
                        </>
                      )}
                    </AbsoluteValue>

                    <LinkedToKS>
                      {this.state.rateIsSurplusToKeyRate === true ? (
                        <>
                          <SelectContainerInactive>
                            <SelectActive />
                          </SelectContainerInactive>
                          <p>Привязаное к КС</p>
                          <div>
                            <p>Ключ +</p>
                            <input
                              className="short-input"
                              type="number"
                              min={0}
                              onKeyPress={e => {
                                if (e.key === 'e' || e.key === '-') {
                                  e.preventDefault();
                                }
                              }}
                              step={0.05}
                              name="regressPeriodBaseRateState"
                              value={
                                this.state.regressPeriodBaseRateState || ''
                              }
                              onChange={this.handleChange}
                            />
                            <p style={{ marginLeft: '10px' }}>% годовых</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <SelectContainerInactive>
                            <SelectInactive />
                          </SelectContainerInactive>
                          <p>Привязаное к КС</p>
                        </>
                      )}
                    </LinkedToKS>
                  </div>
                </ManualDiscountBlock>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>НДС</ContractsInfoItemTitle>
                <input
                  className="short-input"
                  type="number"
                  min={0}
                  onKeyPress={e => {
                    if (e.key === 'e' || e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  step={0.05}
                  name="vatSizeState"
                  value={this.state.vatSizeState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer style={{ marginLeft: '40px' }}>
                <ContractsInfoItemTitle>
                  Полная ставка периода регресса
                </ContractsInfoItemTitle>
                <p>
                  {(this.state.rateIsSurplusToKeyRate === false
                    ? this.state.regressPeriodBaseRateState *
                      (1 + this.state.vatSizeState / 100)
                    : (this.props.keyRate +
                        this.state.regressPeriodBaseRateState) *
                      (1 + this.state.vatSizeState / 100)
                  ).toFixed(2)}
                </p>
                <p>%</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            <ContractsInfoArea>
              <h1>Параметры уступки:</h1>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Минимальная доля уступки
                </ContractsInfoItemTitle>
                <input
                  style={{ marginLeft: '40px' }}
                  className="short-input"
                  type="number"
                  min={0}
                  step={0.05}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="defaultMinimalCessionShareState"
                  value={this.state.defaultMinimalCessionShareState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Максимальная доля уступки
                </ContractsInfoItemTitle>
                <input
                  style={{ marginLeft: '40px' }}
                  className="short-input"
                  type="number"
                  min={0}
                  step={0.05}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="defaultMaximalCessionShareState"
                  value={this.state.defaultMaximalCessionShareState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Минимальная доля финансирования
                </ContractsInfoItemTitle>
                <input
                  style={{ marginLeft: '40px' }}
                  className="short-input"
                  type="number"
                  min={0}
                  step={0.05}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="defaultMinimalShareOfFundingState"
                  value={this.state.defaultMinimalShareOfFundingState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>
                  Максимальная доля финансирования
                </ContractsInfoItemTitle>
                <input
                  style={{ marginLeft: '40px' }}
                  className="short-input"
                  type="number"
                  min={0}
                  step={0.05}
                  onKeyPress={e => {
                    if (
                      e.key === 'e' ||
                      e.key === '-' ||
                      e.key === '.' ||
                      e.key === ','
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="defaultMaximalShareOfFundingState"
                  value={this.state.defaultMaximalShareOfFundingState || ''}
                  onChange={this.handleChange}
                />
                <p>%</p>
              </ContractsInfoItemContainer>
            </ContractsInfoArea>
            {responseGuidStatus == REQUEST_STATUSES.GOT && (
              <CertificatesPopup id="certificatesPopup">
                <PopupExit>
                  <p onClick={this.onCloseIconClick}>X</p>
                </PopupExit>
                <h1>Список сертификатов в системе</h1>
                <CertificatesArea>
                  <CertificatesListPopup
                    guid={responseGuid.guid}
                    request={`{"debtorInn":
                  "${window.location.href.split('/').reverse()[1]}",
                  "volume": "${parseFloat(
                    this.state.volumeState.replaceAll(' ', '')
                  )}",
                  "autoLimitPolicy": "${this.state.autoLimitPolicyState}",
                  "factoringBaseRate": "${this.state.factoringBaseRateState}",
                  "autoLimitPolicy": "${this.state.autoLimitPolicyState}",
                  "vatSize": "${this.state.vatSizeState}",
                  "factualUsageOnly": "${this.state.factualUsageOnlyState}",
                  "factoringRateBase": "${this.state.factoringRateBase}",
                  "gracePeriodBaseRate": "${
                    this.state.gracePeriodBaseRateState
                  }",
                  "gracePeriodFullRate": "${this.state.gracePeriodFullRate}",
                  "regressPeriodBaseRate": "${
                    this.state.regressPeriodBaseRateState
                  }",
                  "defaultGracePeriodDays": "${
                    this.state.defaultGracePeriodDaysState
                  }",
                  "defaultRegressPeriodDays": "${
                    this.state.defaultRegressPeriodDaysState
                  }",
                  "regressPeriodIsInfinite": "${
                    this.state.regressPeriodIsInfiniteState
                  }",
                  "defaultMaximalClaimDaysToMaturity": "${
                    this.state.defaultMaximalClaimDaysToMaturityState
                  }",
                  "defaultMaximalClaimSum": "${
                    this.state.defaultMaximalClaimSumState
                  }",
                  "defaultMaximalShareOfFunding": "${
                    this.state.defaultMaximalShareOfFundingState
                  }",
                  "defaultMaximalCessionShare": "${
                    this.state.defaultMaximalCessionShareState
                  }",
                  "defaultMinimalClaimDaysToMaturity": "${
                    this.state.defaultMinimalClaimDaysToMaturityState
                  }",
                  "defaultMinimalShareOfFunding": "${
                    this.state.defaultMaximalShareOfFundingState
                  }",
                  "defaultMinimalCessionShare": "${
                    this.state.defaultMinimalCessionShareState
                  }",
                  "isRegressed": "${this.state.isRegressedState}",
                  "isNotHidden": "${this.state.isNotHiddenState}",
                  "fundingSharePolicy": "",
                  "isForOnboardedClientsOnly": "",
                  }`}
                  />
                </CertificatesArea>
                <NudgeArea>
                  <p>Нет нужного сертификата? </p>
                  <Nudge onClick={this.onNudgeClick}>
                    Перейдите в настройки добаления
                  </Nudge>
                </NudgeArea>
              </CertificatesPopup>
            )}

            <CertificatesPopupError id="certificatesPopupError">
              <PopupExitError>
                <p onClick={this.onCloseErrorIconClick}>X</p>
              </PopupExitError>
              <h1>
                Невозможно подписать выбранным сертификатом
                <br />
                (не найден на устройстве)
              </h1>
            </CertificatesPopupError>
          </div>

          {(this.state.factoringRateBase !== '3' ||
            !!this.state.commissions.length) && (
            <SuppliersRate>
              <ContractsInfoItemTitle>
                Какую ставку увидит поставщик?
              </ContractsInfoItemTitle>

              <ContractsInfoItemTitle>Исходные данные:</ContractsInfoItemTitle>

              <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
                <ContractsInfoItemTitle>
                  Денежное требование:
                </ContractsInfoItemTitle>
                <input
                  className="short-input"
                  type="number"
                  value={this.state.monetaryClaim}
                  name="monetaryClaim"
                  onChange={this.onChange}
                />
                <p>руб.</p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer style={{ marginLeft: '60px' }}>
                <ContractsInfoItemTitle>
                  Оставшаяся отсрочка платежа:
                </ContractsInfoItemTitle>
                <input
                  className="short-input"
                  type="number"
                  value={this.state.remainingPayment}
                  name="remainingPayment"
                  onChange={this.onChange}
                />
                <p>дней</p>
              </ContractsInfoItemContainer>

              <ContractsInfoItemContainer>
                <ContractsInfoItemTitle>Расчет:</ContractsInfoItemTitle>
              </ContractsInfoItemContainer>

              <div>
                <ContractsInfoItemContainer>
                  <p>Уступленное требование:</p>
                  <p>{formSumStringThousands(this.concededClaim())} руб.</p>
                </ContractsInfoItemContainer>

                {this.commissionAtTheTime() > 0 && (
                  <ContractsInfoItemContainer>
                    <p>Сумма финансирования:</p>
                    <p>
                      {formSumStringThousands(this.amountOfFinancing())} руб.
                    </p>
                  </ContractsInfoItemContainer>
                )}

                {this.commissionAtTheTime() > 0 && (
                  <ContractsInfoItemContainer>
                    <p>Комиссии в момент выдачи, без НДС:</p>
                    <p>
                      {formSumStringThousands(this.commissionAtTheTime())} руб.
                    </p>
                  </ContractsInfoItemContainer>
                )}

                {this.VATonCommissionsAtTheTime() > 0 && (
                  <ContractsInfoItemContainer>
                    <p>НДС по комиссиям в момент выдачи</p>
                    <p>
                      {formSumStringThousands(this.VATonCommissionsAtTheTime())}{' '}
                      руб.
                    </p>
                  </ContractsInfoItemContainer>
                )}

                <ContractsInfoItemContainer>
                  <p style={{ color: navyBlue }}>
                    Реальная сумма финансирования:
                  </p>
                  <p style={{ color: navyBlue }}>
                    {formSumStringThousands(this.actualAmountOfFinancing())}{' '}
                    руб.
                  </p>
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer>
                  <p>Процентные платежи, без НДС:</p>
                  <p>
                    {formSumStringThousands(
                      this.interestPaymentsExcludingVAT()
                    )}{' '}
                    руб.
                  </p>
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer>
                  <p>НДС по процентным платежам</p>
                  <p>
                    {formSumStringThousands(this.VATOnInterestPayments())} руб.
                  </p>
                </ContractsInfoItemContainer>

                {this.commissionsAtTheTimeOfRepayment() > 0 && (
                  <ContractsInfoItemContainer>
                    <p>Комиссии в момент погашения, без НДС:</p>
                    <p>
                      {formSumStringThousands(
                        this.commissionsAtTheTimeOfRepayment()
                      )}{' '}
                      руб.
                    </p>
                  </ContractsInfoItemContainer>
                )}

                {this.VATOnCommissionsAtTheTimeOfRepayment() > 0 && (
                  <ContractsInfoItemContainer>
                    <p>НДС по комиссиям в момент погашения:</p>
                    <p>
                      {formSumStringThousands(
                        this.VATOnCommissionsAtTheTimeOfRepayment()
                      )}{' '}
                      руб.
                    </p>
                  </ContractsInfoItemContainer>
                )}

                <ContractsInfoItemContainer>
                  <p style={{ color: navyBlue }}>
                    Полная сумма платежей и комиссий, без НДС:
                  </p>
                  <p style={{ color: navyBlue }}>
                    {formSumStringThousands(this.fullAmountExcludingVAT())} руб.
                  </p>
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer>
                  <p style={{ color: navyBlue }}>НДС по платежам и комиссиям</p>
                  <p style={{ color: navyBlue }}>
                    {formSumStringThousands(this.VATOnPaymentsAndCommissions())}{' '}
                    руб.
                  </p>
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer style={{ marginTop: '40px' }}>
                  <p style={{ color: navyBlue }}>
                    Расчетная полная ставка, без НДС:
                  </p>
                  <p style={{ color: navyBlue }}>
                    {!isNaN(
                      ((this.fullAmountExcludingVAT() * 365) /
                        this.actualAmountOfFinancing() /
                        this.state.remainingPayment) *
                        100
                    )
                      ? (
                          ((this.fullAmountExcludingVAT() * 365) /
                            this.actualAmountOfFinancing() /
                            this.state.remainingPayment) *
                          100
                        ).toFixed(4)
                      : 0}{' '}
                    % годовых
                  </p>
                </ContractsInfoItemContainer>

                <ContractsInfoItemContainer>
                  <p style={{ color: navyBlue }}>
                    Расчетная полная ставка, с НДС:
                  </p>
                  <p style={{ color: navyBlue }}>
                    {!isNaN(
                      (((this.fullAmountExcludingVAT() +
                        this.VATOnPaymentsAndCommissions()) *
                        365) /
                        this.actualAmountOfFinancing() /
                        this.state.remainingPayment) *
                        100
                    )
                      ? (
                          (((this.fullAmountExcludingVAT() +
                            this.VATOnPaymentsAndCommissions()) *
                            365) /
                            this.actualAmountOfFinancing() /
                            this.state.remainingPayment) *
                          100
                        ).toFixed(4)
                      : 0}{' '}
                    % годовых
                  </p>
                </ContractsInfoItemContainer>
              </div>
            </SuppliersRate>
          )}
        </PageBlock>

        <ContractsInfoArea style={{ marginLeft: '50px' }}>
          <h1>Дополнительные комиссии:</h1>
          <p>Рекомендуется минимизировать количество используемых комиссий</p>

          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="10%">Название комиссии</TableThStyled>
                <TableThStyled width="15%">Момент списания</TableThStyled>
                <TableThStyled width="15%">База расчета</TableThStyled>
                <TableThStyled width="10%">
                  Размер комиссии без НДС
                </TableThStyled>
                <TableThStyled width="10%">НДС</TableThStyled>
                <TableThStyled width="5%" />
                <TableThStyled width="10%" />
              </tr>
            </TableHeaderStyled>
            <tbody>
              {this.state.commissions.map((commission, index) => (
                <TableRowStyled
                  key={index}
                  onMouseEnter={() =>
                    this.setState({ isCommissionDelete: index })
                  }
                  onMouseLeave={() =>
                    this.setState({ isCommissionDelete: null })
                  }
                >
                  <td>
                    <FlexContainer>
                      <Input
                        name="name"
                        value={commission.name}
                        onChange={e => this.handleInputChange(index, e)}
                      />
                    </FlexContainer>
                  </td>
                  <td>
                    <CommissionSelect>
                      <Select
                        name="momentOfPayment"
                        value={commission.momentOfPayment.toString()}
                        onChange={e => this.handleInputChange(index, e)}
                        options={[
                          {
                            id: '1',
                            name: 'В начале, при подаче заявки'
                          },
                          {
                            id: '2',
                            name: 'В конце, при поступлении от дебитора'
                          }
                        ]}
                      />
                    </CommissionSelect>
                  </td>
                  <td>
                    <CommissionSelect>
                      <Select
                        name="feeBase"
                        value={commission.feeBase.toString()}
                        onChange={e => this.handleInputChange(index, e)}
                        options={[
                          {
                            id: '1',
                            name: 'Доля от суммы денежного требования, %'
                          },
                          {
                            id: '2',
                            name: 'Доля от суммы уступленного требования, %'
                          },
                          { id: '3', name: 'Доля от суммы финансирования, %' },
                          { id: '4', name: 'Абсолютное значение в рублях' }
                        ]}
                      />
                    </CommissionSelect>
                  </td>
                  <td>
                    <FlexContainer>
                      <CommissionInput
                        name="feeBaseSumm"
                        type="number"
                        onChange={e => this.handleInputChange(index, e)}
                        value={+commission.feeBaseSumm}
                      />
                      <p>{commission.feeBase == 4 ? 'руб.' : '%'}</p>
                    </FlexContainer>
                  </td>
                  <td>
                    <FlexContainer>
                      <CommissionInput
                        name="vatPercentage"
                        onChange={e => this.handleInputChange(index, e)}
                        value={
                          (commission.vatPercentage &&
                            commission.vatPercentage
                              .toString()
                              .replace('.', ',')) ||
                          0
                        }
                      />
                      <p>%</p>
                    </FlexContainer>
                  </td>
                  <td>
                    <SavedCommission
                      saved={commission.status === 1 && !commission.changed}
                    >
                      {commission.status === 1 && !commission.changed
                        ? 'Сохранено'
                        : (commission.status === 2 ||
                            commission.status === 3 ||
                            commission.changed) &&
                          'Сохраните изменения'}
                    </SavedCommission>
                  </td>
                  <td>
                    <FlexContainer>
                      {(commission.status !== 1 || commission.changed) && (
                        <CheckContainer
                          onClick={() =>
                            this.handleSaveCommission(commission, index)
                          }
                        >
                          <CheckedIcon />
                        </CheckContainer>
                      )}
                      {index === this.state.isCommissionDelete &&
                      (commission.status === 1 || commission.status === 2) ? (
                        <DeclineContainer
                          onClick={() => {
                            this.handleDeleteCommission(commission, index);
                          }}
                        >
                          <CrossIcon />
                        </DeclineContainer>
                      ) : (
                        commission.status === 3 && (
                          <DeclineContainer
                            onClick={() => {
                              this.handleDeleteCommission(commission, index);
                            }}
                          >
                            <CrossIcon />
                          </DeclineContainer>
                        )
                      )}
                    </FlexContainer>
                  </td>
                </TableRowStyled>
              ))}
            </tbody>

            <AddCommissionButton
              label="+ Добавить"
              onClick={this.addCommission}
            />
          </TableStyled>
        </ContractsInfoArea>
      </>
    );
  }
}

const mapStateToProps = ({ SCF, SCFFactor }: STORE) => ({
  factoringSettings: SCFFactor.getCurrentFactoringSettingsByDebtorInn.data,
  status: SCFFactor.getCurrentFactoringSettingsByDebtorInn.status,
  error: SCFFactor.getCurrentFactoringSettingsByDebtorInn.error,
  sendStatus: SCF.sendFactoringSettingsByDebtorInn.status,
  responseGuid: SCF.sendFactoringSettingsByDebtorInn.data,
  responseGuidStatus: SCF.sendFactoringSettingsByDebtorInn.status,
  responseGuidError: SCF.sendFactoringSettingsByDebtorInn.error,
  commissions: SCFFactor.getFactoringFeesOfLimits.data,
  statusCommission: SCFFactor.getFactoringFeesOfLimits.status,
  statusPostCommission: SCFFactor.postFactoringFeesOfLimits.status,
  keyRate: SCF.getKeyRates.data.keyRate
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFactoringSettings,
      sendFactoringSettings,
      getFactoringFeesOfLimits,
      postFactoringFeesOfLimits,
      resetFactoringFeesOfLimits,
      patchFactoringFeesOfLimitsReq,
      deleteFactoringFeesOfLimits,
      getKeyRates
    },
    dispatch
  );

const LimitSettingsEditConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LimitSettingsEdit)
);

export { LimitSettingsEditConnect as LimitSettingsEdit };
