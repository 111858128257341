import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MultiSelect } from 'shared/ui/MultiSelect';
import {
  req as getApplicationsList,
  RequestDataType as ApplicationListRequestDataType,
  ResponseDataType as ApplicationsListResponseDataType
} from 'Application/actions/getApplicationsList';
import {
  addInternalAgentsFilter,
  removeInternalAgentsFilter
} from '../actions/updateInternalAgentsFilter';
import {
  APPLICATIONS_LIST_TYPE,
  RequestDataType as SetApplicationListTypeRequestDataType
} from 'Application/reducers/setApplicationListType';
import { set as setApplicationListType } from 'Application/actions/setApplicationListType';
import { STORE } from 'globaltypes';
import { InternalAgent } from 'src/features/InternalAgentsManager/actions/getInternalAgentsList';
import { req as getInternalAgentsList } from 'InternalAgentsManager/actions/getInternalAgentsList';

interface DispatchProps {
  getApplicationsList: (data: ApplicationListRequestDataType) => void;
  getInternalAgentsList: () => void;
  addInternalAgentsFilter: (internalAgent: number) => void;
  removeInternalAgentsFilter: (indexToRemove: number) => void;
  setApplicationListType: (data: SetApplicationListTypeRequestDataType) => void;
}

interface StateToProps {
  internalAgents: number[];
  initialInternalAgentsList: InternalAgent[];
}

type Props = DispatchProps & StateToProps;

class InternalAgentsFilter extends React.Component<Props> {
  componentDidMount() {
    this.props.getInternalAgentsList();
  }

  onMultiAdd = (item: string) => {
    this.props.addInternalAgentsFilter(Number(item));
  };

  onMultiRemove = (index: number) => {
    this.props.removeInternalAgentsFilter(index);
  };

  getInternalAgentsList() {
    return this.props.initialInternalAgentsList.map(item => {
      return { id: item.id.toString(), name: item.name };
    });
  }

  // TODO refactor selected
  render() {
    return (
      <MultiSelect
        name="name"
        label="Фильтрация по агентам"
        placeholder="Агент"
        value=""
        selected={this.props.internalAgents.map(
          item =>
            this.props.initialInternalAgentsList.find(
              agent => agent.id === item
            ).name
        )}
        onMultiAdd={this.onMultiAdd}
        onMultiRemove={this.onMultiRemove}
        options={this.getInternalAgentsList()}
      />
    );
  }
}

const mapStateToProps = ({ Filters, InternalAgentsManager }: STORE) => {
  return {
    internalAgents: Filters.updateInternalAgentsFilter.internalAgents,
    initialInternalAgentsList: InternalAgentsManager.getInternalAgentsList.data
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getApplicationsList,
      addInternalAgentsFilter,
      setApplicationListType,
      removeInternalAgentsFilter,
      getInternalAgentsList
    },
    dispatch
  );

const InternalAgentsFilterConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalAgentsFilter);

export { InternalAgentsFilterConnect as InternalAgentsFilter };
