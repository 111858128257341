import { GET_BY_MONETARY_CLAIM_DEBTOR } from 'entities/SCF/Debtor/model/reducers/getByMonetaryClaimDebtor';

export interface ResponseData {
  guid: string;
  monetaryClaimGuid: string;
  documentName: string;
  documentNumber: string;
  documentDate: string;
  maturityDate: string;
  baseSumm: number;
  baseVatSumm: number;
  baseFullSumm: number;
  noErrors: null;
  isMain: boolean;
}

export const req = (guid: string) => ({
  type: GET_BY_MONETARY_CLAIM_DEBTOR.REQ,
  guid
});

export const got = (data: ResponseData[]) => ({
  type: GET_BY_MONETARY_CLAIM_DEBTOR.GOT,
  data
});

export const reset = () => ({
  type: GET_BY_MONETARY_CLAIM_DEBTOR.RESET
});

export const err = (error: {}) => ({
  type: GET_BY_MONETARY_CLAIM_DEBTOR.ERR,
  error
});
