import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getLeadogenerators';

import {
  GET_LEADOGENERATORS,
} from '../reducers/getLeadogenerators';
import LeadsApi from '../api';
import { Leadogenerator } from '../types';

function* getLeadogenerators(action: {
  type: GET_LEADOGENERATORS;
}): Generator {
  try {

    const res:any = yield call(LeadsApi.getLeadogenerators);
    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadogeneratorsSaga(): Generator {
  yield takeLatest(GET_LEADOGENERATORS.REQ, getLeadogenerators);
}
