import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CRM,
  REQUEST_STATUSES,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  req as getSearchPerson,
  ResponseData
} from 'src/features/Person/actions/getSearchPerson';
import { FormData } from '../../types/SearchPerson';
import {
  Container,
  FormContainer,
  HeadContainer,
  MainConainer,
  PersonInfo,
  SkeletonWrapper,
  SubmitButton,
  UsersContainer
} from './styled';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

interface StateToProps {
  persons: ResponseData;
  statusPersons: REQUEST_STATUSES;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getSearchPerson: (data: FormData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const SearchPerson: React.FC<Props> = ({
  getSearchPerson,
  persons,
  statusPersons,
  isAuthorized,
  permissions,
  history
}) => {
  const [formData, setFormData] = React.useState<FormData>({
    firstName: '',
    lastName: '',
    middleName: ''
  });

  React.useEffect(
    () => {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain.toLowerCase() === 'my') {
        return;
      } else if (
        !isAuthorized ||
        (permissions.length && !permissions.includes(USER_PERMISSIONS.ADMIN))
      ) {
        window.location.href = 'https://my.finfactory.one/business';
      }
    },
    [permissions, window.location.hostname]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getSearchPerson(formData);
  };

  const navigateToBusinessSearch = () => {
    history.push(`/business`);
  };

  const navigateToPerson = (inn: string) => {
    if (isAuthorized) {
      window.open(`/person/${inn}`, '_blank');
    }
  };

  const navigateToRegistration = () => {
    if (!isAuthorized) {
      history.push('https://my.finfactory.one/registration/business');
    }
  };

  const isButtonDisabled = () => {
    return !(
      formData.firstName.trim().length >= 3 ||
      formData.lastName.trim().length >= 3 ||
      formData.middleName.trim().length >= 3
    );
  };

  return (
    <ApplicationViewStyled>
      <MainConainer>
        <HeadContainer>
          <ApplicationTitle>Поиск по персонам:</ApplicationTitle>
          <p onClick={navigateToBusinessSearch}>Искать по компаниям</p>
        </HeadContainer>

        <Container>
          <FormContainer onSubmit={handleSubmit}>
            <Input
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Фамилия"
            />

            <Input
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Имя"
            />

            <Input
              name="middleName"
              value={formData.middleName}
              onChange={handleChange}
              placeholder="Отчество"
            />

            <SubmitButton type="submit" disabled={isButtonDisabled()}>
              Искать
            </SubmitButton>
          </FormContainer>

          <UsersContainer>
            {statusPersons === REQUEST_STATUSES.GOT && (
              <p>Найдено: {persons.totalItems} человек</p>
            )}

            <div>
              {persons.items.map((person, index) => (
                <div key={index}>
                  <FontAwesomeIcon icon={faUserCircle} />
                  <PersonInfo isAuth={isAuthorized}>
                    <p onClick={() => navigateToPerson(person.personINN)}>
                      {person.lastName} {person.firstName} {person.middleName}
                    </p>
                    <div>
                      <p>ИНН</p>{' '}
                      {statusPersons === REQUEST_STATUSES.REQUEST ? (
                        <SkeletonWrapper width={'100px'} height={'20px'} />
                      ) : (
                        <p onClick={navigateToRegistration}>
                          {person.personINN}
                        </p>
                      )}
                    </div>
                  </PersonInfo>
                </div>
              ))}
            </div>
          </UsersContainer>
        </Container>
      </MainConainer>
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ User, Cached, Person }: STORE & CRM) => ({
  persons: Person.getSearchPerson.data,
  statusPersons: Person.getSearchPerson.status,
  isAuthorized: Cached.authorized,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getSearchPerson }, dispatch);

const SearchPersonConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchPerson)
);

export { SearchPersonConnect as SearchPerson };
