import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard,
  faInbox,
  faTruck
} from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { NavigationLink } from 'Layouts/components';
import { CabinetComponent } from '../EachPage';

function createSidebarComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: () => <Component listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const SuppliesSection: CabinetComponent[] = [
  //   {
  //     hasPermission: permissions =>
  //       [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //     renderSidebarLink: key => (
  //       <SectionSeparatorStyled key={key}>
  //         Закупки и тендеры
  //       </SectionSeparatorStyled>
  //     )
  //   },
  //   {
  //     hasPermission: permissions =>
  //       [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //     ...createSidebarComponent({
  //       Component: Supplies,
  //       path: '/internal/monetary_claims',
  //       label: 'Поставки',
  //       icon: <FontAwesomeIcon icon={faTruck} fixedWidth={true} />,
  //       listType: null
  //     })
  //   },
  //   {
  //     hasPermission: permissions =>
  //       [USER_PERMISSIONS.INTERNAL_AGENT].isIn(permissions),
  //     ...createSidebarComponent({
  //       Component: ContractSupplies,
  //       path: '/internal/supply_contracts',
  //       label: 'Договоры поставки',
  //       icon: <FontAwesomeIcon icon={faClipboard} fixedWidth={true} />,
  //       listType: null
  //     })
  //   },
];
