export enum APPLICATIONS_LIST_TYPE {
  NONE = 'NONE',
  GENERAL = 'GENERAL', // TODO General are only for bank managers now rename
  WORK = 'WORK',
  MY = 'MY',
  DRAFT = 'DRAFT',
  DEAL = 'DEAL',
  TRASH = 'TRASH',
  TO_DISTRIBUTE = 'TO_DISTRIBUTE',
  ALL_IN_WORK = 'ALL_IN_WORK',
  ALL_DEALS = 'ALL_DEALS',
  ALL_TRASHED = 'ALL_TRASHED',
  INTEGRATION_APPLICATIONS = 'INTEGRATION_APPLICATIONS'
}

export enum SET_APPLICATION_LIST_TYPE {
  SET = 'SET_APPLICATION_LIST_TYPE_REQ',
  RESET = 'SET_APPLICATION_LIST_TYPE_RESET'
}

export interface RequestDataType {
  listType: APPLICATIONS_LIST_TYPE;
}

export const initialState: RequestDataType = {
  listType: APPLICATIONS_LIST_TYPE.NONE
};

const setApplicationListType = (
  state: RequestDataType = initialState,
  action: {
    type: SET_APPLICATION_LIST_TYPE;
    data: RequestDataType;
  }
): RequestDataType => {
  switch (action.type) {
    case SET_APPLICATION_LIST_TYPE.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_APPLICATION_LIST_TYPE.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setApplicationListType;
