import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/getOneDebtorRoleByGuid';
import { GET_ONE_DEBTOR_ROLE_BY_GUID } from 'entities/SCF/Debtor/model/reducers/getOneDebtorRoleByGuid';
import SCFDebtorApi from 'entities/SCF/Debtor/api/';

function* getOneDebtorRoleByGuid(action: {
  type: GET_ONE_DEBTOR_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.getOneDebtorRoleByGuid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOneDebtorRoleByGuidSaga() {
  yield takeLatest(
    GET_ONE_DEBTOR_ROLE_BY_GUID.GET_ONE_DEBTOR_ROLE_BY_GUID_REQ,
    getOneDebtorRoleByGuid
  );
}
