import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getDocumentListExternal';

import { GET_DOCUMENT_LIST_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getDocumentListExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getDocumentListExternal(action: {
  type: GET_DOCUMENT_LIST_EXTERNAL;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      ExternalAgentRoleApi.getDocumentListExternal,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentListExternalSaga(): Generator {
  yield takeLatest(GET_DOCUMENT_LIST_EXTERNAL.REQ, getDocumentListExternal);
}
