export enum WINNER {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Побеждал',
  FALSE = 'Не побеждал'
}

export enum GOZ44 {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Заказчик',
  FALSE = 'Не заказчик'
}

export enum GOZ223 {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Заказчик',
  FALSE = 'Не заказчик'
}
