import styled from 'styled-components';

import { UploadFileIcon } from 'shared/styled';
import {
  rr,
  navyBlue,
  gray,
  lightGray,
  lighterBlue
} from 'shared/styled';
import { ApplicationInputsColumn } from 'Application/components/ApplicationForm/ApplicationProcess/styles';

export const GroupInfoBlock = styled.div`
  margin-bottom: 100px;

  display: flex;
  align-items: baseline;
  justify-content: flex-start;
`;

export const ColumnStyled = styled(ApplicationInputsColumn)`
  width: 520px;
  margin: 10px 100px 10px 0;
`;

export const ExampleDocumentBlock = styled.div`
  width: 100%;
  height: 50px;
  max-width: 325px;

  padding: 13px;
  margin: 10px 0;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 6px;
  border: solid 1px ${lightGray};

  & > ${UploadFileIcon}, & > svg {
    width: 100%;
    max-width: 20px;
    margin-right: 15px;
    background-size: contain;
  }
`;

export const ExampleDocumentInfo = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-family: ${rr};
  font-size: 12px;

  & > span:first-child {
    color: ${navyBlue};
  }

  & > span:last-child {
    color: ${gray};
  }
`;

export const DownloadButton = styled.a.attrs({
  download: true
})`
  width: 79px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  background-color: ${navyBlue};

  font-family: ${rr};
  font-size: 12px;
  color: #fff;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const UploadTypeButton = styled.button`
  width: 160px;
  height: 36px;
  margin-bottom: 40px;
  background: ${lighterBlue};
  font-family: ${rr};
  border: 0px;
  border-radius: 5px;
  color: ${navyBlue};

  &:hover {
    background: #f8fbff;
  }
`;

export const UploadTypeButtonSlim = styled.button`
  width: 160px;
  height: 36px;
  font-family: ${rr};

  background: none;
  border: 0px;
  border-radius: 5px;
  color: #9fa6b2;

  &:hover {
    background: #f8fbff;
  }
`;
