import * as React from 'react';

import { EachForm } from 'Application/components';
import { PropertyFinancingFormStep1 } from './PropertyFinancingStep1/PropertyFinancingFormStep1';

export class PropertyFinancingForm extends React.Component {
  render() {
    const routes = [
      {
        path: 'initialization',
        component: PropertyFinancingFormStep1
      }
    ];
    return <EachForm routes={routes} />;
  }
}
