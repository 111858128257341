import * as React from 'react';
import { connect } from 'react-redux';

import { STORE, USER_PERMISSIONS } from 'globaltypes';

import {
  clientBtnConstructor,
  agentBtnConstructor,
  agentAsClientBtnConstructor,
  bankEmplpyeeBtnConstructor
} from './actionBtnConstructors';

import { ActionButtonsBlockStyled } from './styles';
import { externalAgentBtnConstructor } from './actionBtnConstructors/externalAgentBtnConstructor';
import { ActionButton } from './ActionButton/ActionButton';
import { CreditingApplicationRead } from 'src/features/Application/types';

interface StateToProps {
  bankApplication: Partial<CreditingApplicationRead>;
  permissions: USER_PERMISSIONS[];
}

const ActionButtonsBlock: React.FC<StateToProps> = ({
  bankApplication: { isAuthor, status, mainApplicationId },
  permissions
}) => {
  const btnsConstructor = () => {
    let buttons = [];
    if (permissions.includes(USER_PERMISSIONS.BANK_MANAGER))
      buttons = buttons.concat(bankEmplpyeeBtnConstructor(status));

    if (
      permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) ||
      permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS)
    )
      // TODO extract this permission
      buttons = isAuthor
        ? buttons.concat(agentAsClientBtnConstructor(status, mainApplicationId))
        : buttons.concat(agentBtnConstructor(status, mainApplicationId));
    else if (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) && isAuthor)
      buttons = buttons.concat(externalAgentBtnConstructor(status));
    else if (
      permissions.includes(USER_PERMISSIONS.CREATE_APPLICATIONS) &&
      isAuthor
    )
      buttons = buttons.concat(clientBtnConstructor(status));

    return buttons;
  };

  const actionButtons = btnsConstructor();

  return (
    actionButtons.length > 0 && (
      <ActionButtonsBlockStyled>
        {actionButtons.map((props, key) => (
          <ActionButton key={key} {...props} />
        ))}
      </ActionButtonsBlockStyled>
    )
  );
};

const mapStateToProps = ({ BankRole, User }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data,
  permissions: User.getUserData.data.permissions
});

const ActionButtonsBlockConnect = connect<StateToProps>(mapStateToProps)(
  ActionButtonsBlock
);

export { ActionButtonsBlockConnect as ActionButtonsBlock };
