import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/putOpenLoanLimitsSetInactive';

import { PUT_OPEN_LOAN_LIMITS_SET_INACTIVE } from '../reducers/putOpenLoanLimitsSetInactive';
import LimitsApi from '../api';

function* putOpenLoanLimitsSetInActive(action: {
  type: PUT_OPEN_LOAN_LIMITS_SET_INACTIVE;
  id: string;
}): Generator {
  try {
    yield call(LimitsApi.putOpenLoanLimitsSetInactive, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putOpenLoanLimitsSetInActiveSaga(): Generator {
  yield takeLatest(
    PUT_OPEN_LOAN_LIMITS_SET_INACTIVE.REQ,
    putOpenLoanLimitsSetInActive
  );
}
