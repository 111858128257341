import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getMySuppliers'

export interface GetMySuppliersStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_MY_SUPPLIERS {
  GET_MY_SUPPLIERS_REQ = 'GET_MY_SUPPLIERS_REQ',
  GET_MY_SUPPLIERS_GOT = 'GET_MY_SUPPLIERS_GOT',
  GET_MY_SUPPLIERS_ERR = 'GET_MY_SUPPLIERS_ERR'
}

export const initialState: GetMySuppliersStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getMySuppliers = (
  state: GetMySuppliersStoreState = initialState,
  action: {
    type: GET_MY_SUPPLIERS;
    data: ResponseDataType;
    error: {};
  }
): GetMySuppliersStoreState => {
  switch (action.type) {
    case GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_MY_SUPPLIERS.GET_MY_SUPPLIERS_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getMySuppliers;
