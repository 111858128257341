import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DeleteApplicationStoreState extends Reducer {
  data: RequestDataType;
}

export interface RequestDataType {
  id: number;
}

export enum DELETE_APPLICATION {
  REQ = 'DELETE_APPLICATION_REQ',
  GOT = 'DELETE_APPLICATION_GOT',
  ERR = 'DELETE_APPLICATION_ERR'
}

export const initialState: DeleteApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: -1
  },
  error: {}
};

const deleteApplication = (
  state: DeleteApplicationStoreState = initialState,
  action: {
    type: DELETE_APPLICATION;
    data: RequestDataType;
    error: {};
  }
): DeleteApplicationStoreState => {
  switch (action.type) {
    case DELETE_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST,
        data: action.data
      };
    case DELETE_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELETE_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default deleteApplication;
