import * as qs from 'qs';
import formatUrl from 'src/shared/utils/FormatUrl';

type Method = 'get' | 'post' | 'patch' | 'put' | 'delete';

function ApiRequest(url: string, method: Method = 'get', data?, headers?) {
  if (method === 'get') {
    const dataString = qs.stringify(data || {});
    url = `${url}${dataString ? '?' + dataString : ''}`;
  }

  const request = formatUrl(url, data);
  const payload =
    request.data && method !== 'get' ? JSON.stringify(request.data) : undefined;

  return new Promise((resolve, reject) => {
    const params: RequestInit = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
      },
      method: method.toUpperCase(),
      credentials: 'include',
      body: payload
    };

    fetch(url, params)
      .then(async response => {
        if (response.ok) {
          // Ideally, the server shouldn't return an invalid (empty) response body
          // to an application/json request, especially because it responded with HTTP 200.
          // https://github.com/github/fetch/issues/268#issuecomment-176544728

          const text = await response.text();
          resolve(text ? JSON.parse(text) : {});
        } else {
          const errorResponse = {
            code: response.status,
            message: response.statusText,
            body: await response.json()
          };
          reject(errorResponse);
        }
      })
      .catch(error => reject({ error }));
  });
}

export default ApiRequest;
