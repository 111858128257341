import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface DelegateInternalAgentStoreState extends Reducer {}

export enum DELEGATE_TO_INTERNAL_AGENT {
  REQ = 'DELEGATE_TO_INTERNAL_AGENT_REQ',
  GOT = 'DELEGATE_TO_INTERNAL_AGENT_GOT',
  ERR = 'DELEGATE_TO_INTERNAL_AGENT_ERR'
}

export const initialState: DelegateInternalAgentStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const delegateToInternalAgent = (
  state: DelegateInternalAgentStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): DelegateInternalAgentStoreState => {
  switch (action.type) {
    case DELEGATE_TO_INTERNAL_AGENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case DELEGATE_TO_INTERNAL_AGENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case DELEGATE_TO_INTERNAL_AGENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default delegateToInternalAgent;
