import { CERTIFICATE_GENITIVE_ACTIONS } from '../reducers/getCertificateGenitive';
import { CertificateGenitive } from '../types/certificate';

export interface ResponseDataType {
    page: number;
    pageSize: number;
    totalItems: number;
    items: CertificateGenitive[];
}

export const req = () => ({ type: CERTIFICATE_GENITIVE_ACTIONS.REQ });
export const got = (data: ResponseDataType) => ({ type: CERTIFICATE_GENITIVE_ACTIONS.GOT, data });
export const err = (error: {}) => ({
    type: CERTIFICATE_GENITIVE_ACTIONS.ERR,
    error
});


