import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getParticipantsOfActivity';

import { GET_PARTICIPANTS_OF_ACTIVITY } from '../reducers/getParticipantsOfActivity';
import ActivitiesApi from '../api';

function* getParticipantsOfActivity(action: {
  type: GET_PARTICIPANTS_OF_ACTIVITY;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      ActivitiesApi.getParticipantsOfActivity,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getParticipantsOfActivitySaga(): Generator {
  yield takeLatest(GET_PARTICIPANTS_OF_ACTIVITY.REQ, getParticipantsOfActivity);
}
