import { combineReducers } from 'redux';
import getAllAgent, { GetAllAgentStoreState } from './getAllAgent';
import sendAgentData, { SendAgentStoreState } from './sendAgentData';

export interface AgentsReducers {
  getAllAgent: GetAllAgentStoreState;
  sendAgentData: SendAgentStoreState;
}

export const Agents = combineReducers({ getAllAgent, sendAgentData });
