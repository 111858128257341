import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import {
  IncomeContainer,
  LoansContainer,
  RevenueContainer
} from 'src/features/Filters/Companies/FilterByFinance/components/styled';

interface Props {
  minRevenue: number;
  maxRevenue: number;
  minIncome: number;
  maxIncome: number;
  minLoans: number;
  handleNum: (e: React.FormEvent<HTMLInputElement>) => void;
}

const FilterByFinance: React.FC<Props> = ({
  maxIncome,
  maxRevenue,
  minIncome,
  minLoans,
  minRevenue,
  handleNum
}) => {
  return (
    <>
      <RevenueContainer>
        <p>от</p>
        <Input
          value={minRevenue.toLocaleString('ru')}
          label="Выручка, тыс. руб."
          name="minRevenue"
          placeholder={InnPlaceholders.entity}
          onChange={handleNum}
        />

        <p>до</p>
        <Input
          value={maxRevenue.toLocaleString('ru')}
          name="maxRevenue"
          placeholder={InnPlaceholders.entity}
          onChange={handleNum}
        />
      </RevenueContainer>
      <IncomeContainer>
        <p>от</p>
        <Input
          value={minIncome.toLocaleString('ru')}
          label="Прибыль, тыс. руб."
          name="minIncome"
          placeholder={InnPlaceholders.entity}
          onChange={handleNum}
        />

        <p>до</p>
        <Input
          value={maxIncome.toLocaleString('ru')}
          name="maxIncome"
          placeholder={InnPlaceholders.entity}
          onChange={handleNum}
        />
      </IncomeContainer>
      <LoansContainer>
        <p>от</p>
        <Input
          value={minLoans.toLocaleString('ru')}
          label="Займы и кредиты, тыс. руб."
          name="minLoans"
          placeholder={InnPlaceholders.entity}
          onChange={handleNum}
        />
      </LoansContainer>
    </>
  );
};

const mapStateToProps = ({  }: STORE) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByFinanceConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByFinance);

export { FilterByFinanceConnect as FilterByFinance };
