import { combineReducers } from 'redux';

import sendCompanyInfoData, {
  SendCompanyInfoDataStoreState
} from './sendCompanyInfoData';
import setCompanyInfoData, {
  SetCompanyInfoDataStoreState
} from './setCompanyInfo';
import getCompanyInfo, { GetCompanyInfoStoreState } from './getCompanyInfo';
import getCompanyInn, { GetCompanyInnStoreState } from './getCompanyInn';
import getBankAccount, { GetBankAccountDataStoreState } from './getBankAccount';
import setBankAccountData, {
  SetBankAccountDataStoreState
} from './setBankAccount';
import sendBankAccountData, {
  SendBankAccountDataStoreState
} from './sendBankAccountData';
import deleteBankAccount, {
  DeleteBankAccountStoreState
} from './deleteBankAccount';
import getCertificate, { GetCertificateDataStoreState } from './getCertificate';
import getCertificateGenitive, {
  GetCertificateGenitiveDataStoreState
} from './getCertificateGenitive';
import deleteCertificate, {
  DeleteCertificateStoreState
} from './deleteCertificate';
import sendCertificateData, {
  SendCertificateDataStoreState
} from './sendCertificateData';
import setCertificateData, {
  SetCertificateDataStoreState
} from './setCertificate';
import sendBuyers, { SendBuyersStoreState } from './sendBuyers';
import sendSuppliers, { SendSuppliersStoreState } from './sendSuppliers';
import checkAvailableLimits, {
  CheckAvailableLimitsStoreState
} from './checkAvailableLimits';
import getMySuppliers, { GetMySuppliersStoreState } from './getMySuppliers';
import getCompanyByInn, { GetCompanyByInnStoreState } from './getCompanyByInn';
import getMyBuyers, { GetMyBuyersStoreState } from './getMyBuyers';
import getMyLimits, { GetMyLimitsStoreState } from './getFactoringLimits';
import getCompanyByInnThirdParty, {
  GetCompanyByInnThirdPartyStoreState
} from './getCompanyByInnThirdParty';
import getSecondCompanyByInnThirdParty, {
  GetSecondCompanyByInnThirdPartyStoreState
} from './getSecondCompanyByInnThirdParty';
import sendFactoringContract, {
  SendFactoringContractStoreState
} from './sendFactoringContract';
import setFactoringContract, {
  SetFactoringContractStoreState
} from './setFactoringContract';
import getPurchaseContracts, {
  GetPurchaseContractsStoreState
} from './getPurchaseContracts';
import getPurchaseContractByGuid, {
  GetPurchaseContractByGuidStoreState
} from './getPurchaseContractByGuid';
import sendPurchaseContract, {
  SendPurchaseContractStoreState
} from './sendPurchaseContract';
import setPurchaseContract, {
  SetPurchaseContractStoreState
} from './setPurchaseContract';
import getSupplyContracts, {
  GetSupplyContractsStoreState
} from './getSupplyContracts';
import getSupplyContractByGuid, {
  GetSupplyContractByGuidStoreState
} from './getSupplyContractByGuid';
import sendSupplyContract, {
  SendSupplyContractStoreState
} from './sendSupplyContract';
import setSupplyContract, {
  SetSupplyContractStoreState
} from './setSupplyContract';
import addListOfLimits, { AddListOfLimitsStoreState } from './addListOfLimits';
import sendDiscountingSettings, {
  SendDiscountingSettingsStoreState
} from './sendDiscountingSettings';
import setDiscountingSettings, {
  SetDiscountingSettingsStoreState
} from './setDiscountingSettings';
import getDiscountingSettings, {
  GetDiscountingSettingsStoreState
} from './getDiscountingSettings';
import postDiscountingSettingsSignature, {
  PostDiscountingSettingsSignatureStoreState
} from './postDiscountingSettingsSignature';
import sendFactoringSettingsByDebtorInn, {
  SendFactoringSettingsByDebtorInnStoreState
} from './sendFactoringSettingsByDebtorInn';
import setFactoringSettingsByDebtorInn, {
  SetFactoringSettingsByDebtorInnStoreState
} from './setFactoringSettingsByDebtorInn';
import postFactoringSettingsSignature, {
  PostFactoringSettingsSignatureStoreState
} from './postFactoringSettingsSignature';
import putCertificateFill, {
  PutCertificateFillStoreState
} from './putCertificateFill';
import getCompanyFastInfo, {
  GetCompanyFastInfoStoreState
} from './getCompanyFastInfo';
import getContractFile, { GetContractFileStoreState } from './getContractFile';
import postAddSupplier, { PostAddSupplierStoreState } from './postAddSupplier';
import postAddBuyer, { PostAddBuyerStoreState } from './postAddBuyer';
import getKeyRates, { GetKeyRatesStoreState } from './getKeyRates';
import postAddToContractBySupplier, {
  PostAddToContractBySupplierStoreState
} from './postAddToContractBySupplier';
import getPOAS, { GetPOASStoreState } from './getPOAS';
import putAddPoa, { PutAddPoaStoreState } from './putAddPoa';
import postCreateAgreementSupplierRole, {
  PostCreateAgreementSupplierRoleStoreState
} from './postCreateAgreementSupplierRole';
import getAllDiscountAuctionsByDebtor, {
  GetAllDiscountAuctionsByDebtorStoreState
} from './getAllDiscountAuctions';
import postCreateDiscountAuctions, {
  PostCreateDiscountAuctionsStoreState
} from './postCreateDiscountAuctions';
import getOneDiscountAuction, {
  GetOneDiscountAuctionStoreState
} from './getOneDiscountAuction';
import getSuppluerAuction, {
  GetSuppluerAuctionStoreState
} from './getSuppluerAuction';
import postAddToAuction, {
  PostAddToAuctionStoreState
} from './postAddToAuction';
import deleteFromAuction, {
  DeleteFromAuctionStoreState
} from './deleteFromAuction';
import getAllDiscountAuctionsBySupplier, {
  GetAllDiscountAuctionsBySupplierStoreState
} from './getAllDiscountAuctionsBySupplier';
import getOneDiscountAuctionBySupplier, {
  GetOneDiscountAuctionBySupplierStoreState
} from './getOneDiscountAuctionBySupplier';
import getInvestorListBySupplier, {
  GetInvestorListBySupplierStoreState
} from './getInvestorListBySupplier';
import putMonetaryClaimDeleteToAuction, {
  PutMonetaryClaimDeleteToAuctionStoreState
} from './putMonetaryClaimDeleteToAuction';
import postAddedMonetaryClaimByDebtor, {
  PostAddedMonetaryClaimByDebtorStoreState
} from './postAddedMonetaryClaimByDebtor';
import getQtySidebar, {
  GetInternalCounterSidebarStoreState
} from './getInternalCounterSidebar';

export interface SCFReducers {
  sendCompanyInfoData: SendCompanyInfoDataStoreState;
  setCompanyInfoData: SetCompanyInfoDataStoreState;
  getCompanyInfo: GetCompanyInfoStoreState;
  getCompanyInn: GetCompanyInnStoreState;
  getBankAccount: GetBankAccountDataStoreState;
  sendBankAccountData: SendBankAccountDataStoreState;
  setBankAccountData: SetBankAccountDataStoreState;
  deleteBankAccount: DeleteBankAccountStoreState;
  getCertificate: GetCertificateDataStoreState;
  getCertificateGenitive: GetCertificateGenitiveDataStoreState;
  deleteCertificate: DeleteCertificateStoreState;
  sendCertificateData: SendCertificateDataStoreState;
  setCertificateData: SetCertificateDataStoreState;
  sendBuyers: SendBuyersStoreState;
  sendSuppliers: SendSuppliersStoreState;
  checkAvailableLimits: CheckAvailableLimitsStoreState;
  getMySuppliers: GetMySuppliersStoreState;
  getCompanyByInn: GetCompanyByInnStoreState;
  getMyBuyers: GetMyBuyersStoreState;
  getMyLimits: GetMyLimitsStoreState;
  getCompanyByInnThirdParty: GetCompanyByInnThirdPartyStoreState;
  getSecondCompanyByInnThirdParty: GetSecondCompanyByInnThirdPartyStoreState;
  sendFactoringContract: SendFactoringContractStoreState;
  setFactoringContract: SetFactoringContractStoreState;
  getPurchaseContracts: GetPurchaseContractsStoreState;
  getPurchaseContractByGuid: GetPurchaseContractByGuidStoreState;
  sendPurchaseContract: SendPurchaseContractStoreState;
  setPurchaseContract: SetPurchaseContractStoreState;
  getSupplyContracts: GetSupplyContractsStoreState;
  getSupplyContractByGuid: GetSupplyContractByGuidStoreState;
  sendSupplyContract: SendSupplyContractStoreState;
  setSupplyContract: SetSupplyContractStoreState;
  addListOfLimits: AddListOfLimitsStoreState;
  sendDiscountingSettings: SendDiscountingSettingsStoreState;
  setDiscountingSettings: SetDiscountingSettingsStoreState;
  getDiscountingSettings: GetDiscountingSettingsStoreState;
  postDiscountingSettingsSignature: PostDiscountingSettingsSignatureStoreState;
  sendFactoringSettingsByDebtorInn: SendFactoringSettingsByDebtorInnStoreState;
  setFactoringSettingsByDebtorInn: SetFactoringSettingsByDebtorInnStoreState;
  postFactoringSettingsSignature: PostFactoringSettingsSignatureStoreState;
  putCertificateFill: PutCertificateFillStoreState;
  getCompanyFastInfo: GetCompanyFastInfoStoreState;
  getContractFile: GetContractFileStoreState;
  postAddSupplier: PostAddSupplierStoreState;
  postAddBuyer: PostAddBuyerStoreState;
  getKeyRates: GetKeyRatesStoreState;
  postAddToContractBySupplier: PostAddToContractBySupplierStoreState;
  getPOAS: GetPOASStoreState;
  putAddPoa: PutAddPoaStoreState;
  postCreateAgreementSupplierRole: PostCreateAgreementSupplierRoleStoreState;
  getAllDiscountAuctionsByDebtor: GetAllDiscountAuctionsByDebtorStoreState;
  postCreateDiscountAuctions: PostCreateDiscountAuctionsStoreState;
  getOneDiscountAuction: GetOneDiscountAuctionStoreState;
  getSuppluerAuction: GetSuppluerAuctionStoreState;
  postAddToAuction: PostAddToAuctionStoreState;
  deleteFromAuction: DeleteFromAuctionStoreState;
  getAllDiscountAuctionsBySupplier: GetAllDiscountAuctionsBySupplierStoreState;
  getOneDiscountAuctionBySupplier: GetOneDiscountAuctionBySupplierStoreState;
  getInvestorListBySupplier: GetInvestorListBySupplierStoreState;
  putMonetaryClaimDeleteToAuction: PutMonetaryClaimDeleteToAuctionStoreState;
  postAddedMonetaryClaimByDebtor: PostAddedMonetaryClaimByDebtorStoreState;
  getQtySidebar: GetInternalCounterSidebarStoreState;
}

export const SCF = combineReducers({
  getCompanyInfo,
  sendCompanyInfoData,
  setCompanyInfoData,
  getCompanyInn,
  getBankAccount,
  sendBankAccountData,
  setBankAccountData,
  deleteBankAccount,
  getCertificate,
  deleteCertificate,
  sendCertificateData,
  setCertificateData,
  sendBuyers,
  sendSuppliers,
  checkAvailableLimits,
  getMySuppliers,
  getCompanyByInn,
  getMyBuyers,
  getMyLimits,
  getCompanyByInnThirdParty,
  getSecondCompanyByInnThirdParty,
  sendFactoringContract,
  setFactoringContract,
  getPurchaseContracts,
  getPurchaseContractByGuid,
  setPurchaseContract,
  sendPurchaseContract,
  getSupplyContracts,
  getSupplyContractByGuid,
  sendSupplyContract,
  setSupplyContract,
  addListOfLimits,
  sendDiscountingSettings,
  setDiscountingSettings,
  getDiscountingSettings,
  postDiscountingSettingsSignature,
  sendFactoringSettingsByDebtorInn,
  setFactoringSettingsByDebtorInn,
  postFactoringSettingsSignature,
  getCertificateGenitive,
  putCertificateFill,
  getCompanyFastInfo,
  getContractFile,
  postAddSupplier,
  postAddBuyer,
  getKeyRates,
  postAddToContractBySupplier,
  getPOAS,
  putAddPoa,
  postCreateAgreementSupplierRole,
  getAllDiscountAuctionsByDebtor,
  postCreateDiscountAuctions,
  getOneDiscountAuction,
  getSuppluerAuction,
  postAddToAuction,
  deleteFromAuction,
  getAllDiscountAuctionsBySupplier,
  getOneDiscountAuctionBySupplier,
  getInvestorListBySupplier,
  putMonetaryClaimDeleteToAuction,
  postAddedMonetaryClaimByDebtor,
  getQtySidebar
});
