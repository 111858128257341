import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TRANSITION_DIRECTIONS } from 'Common';
import { BorrowerCompanies as BorrowerCompaniesStep } from '../Borrower/BorrowerCompanies/BorrowerCompanies';

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

const CreditingFormStep2: React.FC<Props> = ({ history, match, location }) => {
  const navigateFromTo = (from: string, to: string) =>
    history.push({
      pathname: match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  const goBack = () => navigateFromTo(location.pathname, 'credit_options');

  return <BorrowerCompaniesStep goBack={goBack} />;
};

const WithCreditingFormStep2 = withRouter(CreditingFormStep2);

export { WithCreditingFormStep2 as CreditingFormStep2 };
