import * as React from 'react';
import { PropertySubTypes, PropertyTypes } from 'shared/constants';
import { convertKbToMb, formBackendDateString, formSumString } from 'src/shared/utils/Utils';
import { DownloadLink } from 'shared/styled';
import { LighterBlueContainer } from 'shared/styled/layouts';
import { PropertyRead } from '../../types/Property';
import { Document } from 'Application/types';
import ApplicationApi from 'src/features/Application/api';
import { DocumentsBlock, DocumentsTable } from 'src/features/Layouts/components';
import { PropertyInformationStyled } from './styles';

interface PropertyViewProps {
    property: Partial<PropertyRead>;
}

const PropertyView: React.FC<PropertyViewProps> = ({ property }) => {
    return (
        <LighterBlueContainer>
            <PropertyInformationStyled>
                <p>Тип: <span>{PropertyTypes[property.type]}</span></p>
                <p>Регион: <span>{property.region}</span></p>
                <p>Адрес: <span>{property.address}</span></p>
                <p>Примерная стоимость объекта: <span>{formSumString(property.price)}</span></p>
                <p>Подтип: <span>{PropertySubTypes[property.subType]}</span></p>
                <p>Есть Оценка: <span>{property.hasEvaluation ? "Да" : "Нет"}</span></p>
                {property.hasEvaluation &&
                    <>
                        <p>Сдается в аренду?: <span>{property.isRented ? "Да" : "Нет"}</span></p>
                    </>}
            </PropertyInformationStyled>
            <DocumentsBlock documents={property.documents} />

        </LighterBlueContainer>)
};

export default PropertyView;
