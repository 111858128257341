import { Agents } from 'src/features/Counterparties/types';
import { GET_AGENTS } from '../reducers/getAgents';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Agents[];
}

export const req = (inn: string) => ({
  type: GET_AGENTS.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_AGENTS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_AGENTS.ERR,
  error
});
