import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'src/features/SCF/actions/addListOfLimits';

import {
  ADD_LIST_OF_LIMITS,
  RequestDataType
} from 'src/features/SCF/reducers/addListOfLimits';

import SCFApi from 'src/features/SCF/api';

function* addListOfLimits(action: {
  type: ADD_LIST_OF_LIMITS;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.addListOfLimits, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addListOfLimitsSaga() {
  yield takeLatest(ADD_LIST_OF_LIMITS.REQ, addListOfLimits);
}
