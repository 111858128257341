import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export interface GetCompanyByInnConfirmedFoundersStoreState extends Reducer {
  data: ResponseDataType[];
}

export enum GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS {
  REQ = 'GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS_REQ',
  GOT = 'GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS_GOT',
  ERR = 'GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS_ERR'
}

export const initialState: GetCompanyByInnConfirmedFoundersStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getCompanyByInnConfirmedFounders = (
  state: GetCompanyByInnConfirmedFoundersStoreState = initialState,
  action: {
    type: GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS;
    data: ResponseDataType[];
    error: {};
  }
): GetCompanyByInnConfirmedFoundersStoreState => {
  switch (action.type) {
    case GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_COMPANY_BY_INN_CONFIRMED_FOUNDERS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getCompanyByInnConfirmedFounders;
