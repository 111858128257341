import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import {
  AddLimitsPageStyled,
  LimitsHeader,
  BackBtn,
  BackBtnText,
  DescriptionArea,
  Description,
  InputArea,
  InputTitle,
  AddBtn,
  AddBtnText
} from './styles';
import { STORE } from 'src/globaltypes';
import { req as addLimits } from 'src/features/SCF/actions/addListOfLimits';
import { RequestDataType as AddListOfLimitsDataType } from 'src/features/SCF/reducers/addListOfLimits';

interface StateToProps {
}

interface DispatchToProps {
  addLimits: (data: AddListOfLimitsDataType) => void;
}

type OwnState = {
  innList: string
}

type Props = StateToProps & DispatchToProps;

class LimitsAddPage extends React.Component<Props, OwnState> {

  constructor(props) {
    super(props)
    this.state = {
      innList: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ innList: event.target.value })
  }
  onAddListOfLimitsClick = async() => {
    this.props.addLimits({
      innList: this.state.innList
    })
    await this.delay(700);
    history.replace('/factor/limits?page=1');
  }

  BackClick = () => {
    history.push('/factor/limits?page=1');
  }

  delay = ms => new Promise(res => setTimeout(res, ms))

  render() {
    return (
      <AddLimitsPageStyled>
        <LimitsHeader>
          <h1>Добавить лимиты:</h1>
          <BackBtn onClick={this.BackClick}>
            <BackBtnText>Назад</BackBtnText>
          </BackBtn>
        </LimitsHeader>
        <DescriptionArea>
          <Description>Можно ввести не более 1000 покупателей (ИНН) за 1 запрос.<br></br>Разделителем может быть любой нечисловой символ.</Description>
        </DescriptionArea>
        <InputArea>
          <InputTitle>Список покупателей (ИНН):</InputTitle>
          <textarea
            value={this.state.innList}
            placeholder='1111111111, 2222222222, 3333333333, ...&#10;&#10;или&#10;&#10;1111111111&#10;2222222222&#10;3333333333'
            onChange={this.handleChange}
          />
        </InputArea>
        {this.state.innList !== '' ? this.state.innList.length > 110000 ?
          <AddBtn style={{ backgroundColor: '#FF0000', cursor: 'not-allowed' }}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn>
          :
          <AddBtn onClick={this.onAddListOfLimitsClick}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn> :
          <AddBtn style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}>
            <AddBtnText>+ Добавить</AddBtnText>
          </AddBtn>
        }
      </AddLimitsPageStyled>

    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({

});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addLimits,
    },
    dispatch
  );

const LimitsAddConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LimitsAddPage)
);

export { LimitsAddConnect as LimitsAddPage };
