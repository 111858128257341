import styled from 'styled-components';
import { lighterBlue } from 'shared/styled';

export const FilterStyled = styled.div`
  max-width: 200px;
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};
`;
