import { GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES } from 'src/features/Person/reducers/getCompanyByInnConfirmedBeneficiaries';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';

export const req = (data: string[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.REQ,
  data
});

export const got = (data: ResponseDataType[]) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.GOT,
  data
});

export const err = (error: string) => ({
  type: GET_COMPANY_BY_INN_CONFIRMED_BENEFICIARIES.ERR,
  error
});
