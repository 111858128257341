import * as React from 'react';
import { connect } from 'react-redux';

import { convertKbToMb, formBackendDateString } from 'src/shared/utils/Utils';
import {
  APPLICATION_TYPE_ROUTES,
  CreditingApplicationRead,
  Document
} from 'Application/types';
import ApplicationApi from 'Application/api';

import { DocumentsTable } from 'Layouts/components';
import { WarningBlock } from 'Application/components';

import { STORE } from 'src/globaltypes';
import { DownloadLink, ErrorIcon } from 'shared/styled';
import { CompanyDocumentsBlockStyled, CompanyDocumentsTtitle } from './styles';
import { EditDocumentsLink } from '../styles';

interface StateToProps {
  clientApplication: Partial<CreditingApplicationRead>;
}

interface OwnProps {
  documents: Document[];
  applicationId: number;
}

type Props = OwnProps & StateToProps;

const CompanyDocumentsBlock: React.FC<Props> = ({
  clientApplication,
  documents,
  applicationId
}) => {
  const formTableRows = () =>
    documents.map((document, index) => ({
      id: document.id,
      data: [
        index + 1,
        document.name,
        formBackendDateString(document.createdDate),
        convertKbToMb(document.size),
        <DownloadLink href={ApplicationApi.getDownloadDocumentLink(document)} />
      ]
    }));

  return documents.length > 0 ? (
    <CompanyDocumentsBlockStyled>
      <CompanyDocumentsTtitle />
      <DocumentsTable
        headers={[
          '№',
          'Название',
          'Дата добавления',
          'Размер файла',
          <DownloadLink
            href={ApplicationApi.getDownloadApplicationDocumentsLink(
              applicationId
            )}
          />
        ]}
        rows={formTableRows()}
      />
    </CompanyDocumentsBlockStyled>
  ) : (
    <React.Fragment>
      <CompanyDocumentsTtitle>Документы компании</CompanyDocumentsTtitle>

      <WarningBlock
        icon={<ErrorIcon size={90} />}
        text={[
          'Вы не загрузили необходимые документы.',
          <React.Fragment>
            Чтобы загрузить документы нажмите "
            <EditDocumentsLink
              to={`/cabinet/client/application-form-${APPLICATION_TYPE_ROUTES[
                clientApplication.financingType
              ].replace('_', '-')}/edit/${clientApplication.id}/documents`}
            >
              Редактировать документы
            </EditDocumentsLink>
            ".
          </React.Fragment>,
          'Заявки без приложенных документов банками к рассмотрению не принимаются.'
        ]}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ ClientRole }: STORE) => ({
  clientApplication: ClientRole.getClientApplication.data
});

const CompanyDocumentsBlockConnect = connect<StateToProps>(mapStateToProps)(
  CompanyDocumentsBlock
);

export { CompanyDocumentsBlockConnect as CompanyDocumentsBlock };
