import { call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/InternalAgent/model/actions/getCertificates';
import { GET_CERTIFICATES } from 'entities/Cabinet/InternalAgent/model/reducers/getCertificates';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* getCertificates(action: {
  type: GET_CERTIFICATES;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      InternalAgentRoleApi.getCertificates,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCertificatesSaga(): Generator {
  yield takeEvery(GET_CERTIFICATES.REQ, getCertificates);
}
