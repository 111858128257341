import * as React from 'react';
import DatePicker from 'react-datepicker';
import { format, isEqual, isValid, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { DatePickerWrapperStyles, DatePickerWrapper } from './styles';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  onDateChange: (date: string) => void;
  dateValue: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  disabledDays?: any;
  error?: string;
  name?: string;
  label?: string;
  required?: boolean;
  highlightDates?: Date[];
  lastDate?: Date;
}

export const InputDate: React.FC<Props> = ({
  dateValue,
  onDateChange,
  placeholder,
  minDate,
  maxDate,
  disabledDays,
  highlightDates,
  error,
  name,
  label,
  required,
  lastDate = null
}) => {
  const calendar = React.useRef<DatePicker>(null);

  const formatDateForState = (input: string) => {
    const cleanInput = input.replace(/ г\.$/, '');
    const parsed = parse(cleanInput, 'dd.MM.yyyy', new Date());
    if (isValid(parsed)) {
      onDateChange(format(parsed, 'dd/MM/yyyy'));
    }
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));

  return (
    <DatePickerWrapper label={!!label} error={error}>
      <Label name={name} label={label} required={required} />
      <DatePicker
        ref={calendar}
        locale={ru}
        highlightDates={highlightDates || undefined}
        selected={dateValue && parse(dateValue, 'dd/MM/yyyy', new Date())}
        minDate={minDate}
        maxDate={maxDate}
        filterDate={disabledDays}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        wrapperClassName="date_picker"
        dayClassName={currentDate =>
          isEqual(currentDate, lastDate) ? 'paymentDate' : ''
        }
        customInput={
          <Input
            value={dateValue}
            name={name}
            error={error}
            mask={[
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              ' ',
              'г',
              '.'
            ]}
          />
        }
        onChange={(selectedDate: Date, event) => {
          event.preventDefault();

          if (selectedDate) {
            onDateChange(format(selectedDate, 'dd/MM/yyyy'));
          }
        }}
        onChangeRaw={async (e: any) => {
          e.preventDefault();
          let inputVal = e.target && (e.target.value as string);

          if (!inputVal) {
            onDateChange('');
            return;
          }

          inputVal = inputVal.replace(/_/g, '');

          if (inputVal.length !== 13) return;

          formatDateForState(inputVal);

          calendar.current.setOpen(false);

          await delay(100);

          calendar.current.setOpen(true);
        }}
      />
      <DatePickerWrapperStyles />
    </DatePickerWrapper>
  );
};
