import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getProperties';
import { setLocalProperties } from '../actions/localProperties';
import PropertiesApi from '../api';

import {
  GET_PROPERTIES,
} from '../reducers/getProperties';
import { PropertyRead } from '../types/Property';

function* getProperties(action: {
  type: GET_PROPERTIES;
  applicationId: number;
}): Generator {
  try {

    const res = yield call(PropertiesApi.getProperties,
                           action.applicationId);

    yield put(setLocalProperties(res as Partial<PropertyRead>[]));
    yield put(got());

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPropertiesSaga(): Generator {
  yield takeLatest(GET_PROPERTIES.REQ, getProperties);
}
