import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format } from 'date-fns';

import { Loader } from 'Layouts/components';
import {
  req as getMonetaryClaimsEarlyPaymentApplications,
  reset as resetMonetaryClaimsEarlyPaymentApplications,
  RequestDataType,
  Calc as ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import { reset as resetEarlyPaymentApplications } from 'entities/SCF/Supplier/model/actions/getEarlyPaymentApplicationsSupplierRole';
import {
  req as getInvestorOneDebtor,
  reset as resetInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import {
  ContractsPageStyled,
  CreateLimitsNudge,
  ListSection,
  FullList,
  ListSectionOne,
  FullListOne,
  BackBtn,
  BackBtnText,
  ConfirmSection,
  ConfirmSectionRightPart,
  DiscountItem,
  SignSection,
  DownloadLink
} from './styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled,
  TableHeaderCellCenteredStyled
} from 'src/features/SCF/UI/Table/styles';
import {
  TableRowStyled,
  TdCentred
} from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import {
  DocumentHint,
  InvoiceDocumentDate,
  SkeletonWrapper,
  TDInvoiceDocumenContainer
} from '../ApplicationCalc/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import SCFDebtorApi from 'entities/SCF/Debtor/api';
import SCFSupplierApi from 'entities/SCF/Supplier/api';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
  statusInvestorOneDebtor: REQUEST_STATUSES;
  monetaryClaimsHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaimsEarlyPaymentApplications: (data: RequestDataType) => void;
  resetMonetaryClaimsEarlyPaymentApplications: () => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  resetInvestorOneDebtor: () => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
  resetEarlyPaymentApplications: () => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationInfo: React.FC<Props> = ({
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  getInvestorOneDebtor,
  resetInvestorOneDebtor,
  investorOneDebtor,
  statusInvestorOneDebtor,
  getMonetaryClaimsEarlyPaymentApplications,
  resetMonetaryClaimsEarlyPaymentApplications,
  monetaryClaimsEarlyPaymentApplications,
  resetEarlyPaymentApplications,
  status,
  history,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.id) {
        getInvestorOneDebtor({ guid: match.params.id });
        getMonetaryClaimsEarlyPaymentApplications({ guid: match.params.id });

        const base64ToBlob = (base64, type = 'application/octet-stream') => {
          const binStr = atob(base64);
          const len = binStr.length;
          const arr = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          return new Blob([arr], { type: type });
        };

        const arrayBufferToBase64 = buffer => {
          let binary = '';
          const bytes = new Uint8Array(buffer);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          return window.btoa(binary);
        };

        fetch(
          `/api/early_payment_applications/supplier_role/${
            match.params.id
          }/get_agreement_html_file`
        )
          .then(res => res.arrayBuffer())
          .then(data => {
            var base64Str = arrayBufferToBase64(data);
            const blob = base64ToBlob(base64Str, 'application/pdf');
            const url = URL.createObjectURL(blob);
            var outputObject = document.createElement('iframe');
            var outputText = document.createElement('p');
            outputText.style.margin = '30px 0 8px 0';
            outputText.style.fontFamily = 'Roboto-Regular';
            outputText.innerHTML = '* Превью договора';
            outputObject.src = url + '#toolbar=0';
            outputObject.style.width = '1200px';
            outputObject.style.height = '1000px';
            outputObject.style.background = '#262B41';
            document.getElementById('documentObject').appendChild(outputText);
            document.getElementById('documentObject').appendChild(outputObject);
          });
      }

      return () => {
        resetEarlyPaymentApplications();
        resetInvestorOneDebtor();
        resetMonetaryClaimsEarlyPaymentApplications();
      };
    },
    [match.params.id]
  );

  const onBackClick = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const DiscountCounter = () => {
    let discountCounter: number;
    discountCounter = 0;
    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      discountCounter += monetaryClaimsEarlyPaymentApplications[i].baseFullSumm;
    }
    return discountCounter;
  };

  const index = (): number => {
    if (monetaryClaimsEarlyPaymentApplications[0].baseSumm === 0) {
      return 1;
    }
    return 1.2;
  };

  const intermediateDiscount = (): number => {
    if (monetaryClaimsEarlyPaymentApplications[0].baseSumm > 0) {
      return Math.round(investorOneDebtor.baseDiscountRate * 1.2 * 1000) / 1000;
    }
    return Math.round(investorOneDebtor.baseDiscountRate * 1000) / 1000;
  };

  const fullRate = (i: number): number => {
    return (
      Math.round(
        intermediateDiscount() *
          (differenceInDays(
            new Date(monetaryClaimsEarlyPaymentApplications[i].maturityDate),
            new Date(investorOneDebtor.payDate)
          ) /
            365) *
          10000
      ) /
      10000 /
      100
    );
  };

  const summToGet = (i: number): number => {
    return (
      monetaryClaimsEarlyPaymentApplications[i].baseFullSumm * (1 - fullRate(i))
    );
  };

  const DiscountCounterToGet = () => {
    let discountCounterToGet: number;
    discountCounterToGet = 0;
    for (let i = 0; i < monetaryClaimsEarlyPaymentApplications.length; i++) {
      discountCounterToGet += summToGet(i);
    }
    return discountCounterToGet;
  };

  if (statusInvestorOneDebtor === REQUEST_STATUSES.REQUEST) {
    return <Loader />;
  }

  return (
    <ContractsPageStyled>
      <CreateLimitsNudge>
        <h2>
          Заявка #
          {investorOneDebtor.guid
            .substring(investorOneDebtor.guid.length - 5)
            .toLocaleUpperCase()}
        </h2>
        <BackBtn onClick={() => onBackClick()}>
          <BackBtnText>Назад</BackBtnText>
        </BackBtn>
      </CreateLimitsNudge>
      <ListSectionOne>
        <FullListOne>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableHeaderCellStyled width="50%">
                  ПОКУПАТЕЛЬ
                </TableHeaderCellStyled>
                <TableHeaderCellStyled width="50%">
                  ПОСТАВЩИК
                </TableHeaderCellStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              <TableRowStyled style={{ height: '20px' }}>
                <td style={{ fontFamily: 'Roboto-Regular' }}>
                  {investorOneDebtor.debtorName}
                </td>
                <td style={{ fontFamily: 'Roboto-Regular' }}>
                  {investorOneDebtor.supplierName}
                </td>
              </TableRowStyled>
            </tbody>
          </TableStyled>
        </FullListOne>
      </ListSectionOne>
      <ListSection>
        <FullList>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellCenteredStyled width="6%">
                    ID
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДОКУМЕНТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДАТА ОПЛАТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="16%">
                    СРОК ДИСКОНТА ДН.
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellStyled
                    width="16%"
                    style={{ paddingTop: '14px' }}
                  >
                    СТАВКА ДИСКОНТА
                    <br />
                    %годовых
                  </TableHeaderCellStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДИСКОНТ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА К ПОЛУЧЕНИЮ
                  </TableHeaderCellCenteredStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                  <TableRowStyled key={key}>
                    <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </TdCentred>

                    <TdCentred>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          textAlign: 'center',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>
                    {item.baseVatSumm !== 0 && item.baseVatSumm !== null ? (
                      <TdCentred>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px'
                          }}
                        >
                          {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              width: 'auto'
                            }}
                          >
                            без НДС
                          </p>
                        </p>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5px'
                          }}
                        >
                          {(
                            investorOneDebtor.baseDiscountRate * index()
                          ).toFixed(3)}
                          %{' '}
                          <p
                            style={{
                              color: '#9FA6B2',
                              fontSize: '14px',
                              margin: '0',
                              width: 'auto'
                            }}
                          >
                            с НДС
                          </p>
                        </p>
                      </TdCentred>
                    ) : (
                      <td style={{ paddingLeft: '20px' }}>
                        {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                      </td>
                    )}
                    <TdCentred>
                      {(fullRate(key) * 100).toLocaleString('ru', {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}
                      %
                    </TdCentred>
                    <TdCentred>
                      {summToGet(key).toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          )}
        </FullList>
      </ListSection>

      {status == REQUEST_STATUSES.GOT && (
        <ConfirmSection style={{ marginTop: '10px' }}>
          <ConfirmSectionRightPart>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Сумма требований без дисконта
              </p>
              <p
                className="discount-1"
                id="discount-1"
                style={{ color: '#9FA6B2', fontSize: '14px' }}
              >
                {DiscountCounter().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}{' '}
                руб.
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Дисконт за раннюю оплату
              </p>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                {(DiscountCounter() - DiscountCounterToGet()).toLocaleString(
                  'ru',
                  {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }
                )}{' '}
                руб.
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ fontSize: '16px' }}>Сумма к получению</p>
              <p style={{ fontSize: '16px' }}>
                {DiscountCounterToGet().toLocaleString('ru', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}{' '}
                руб.
              </p>
            </DiscountItem>
          </ConfirmSectionRightPart>
        </ConfirmSection>
      )}

      <ConfirmSection style={{ width: '500px', marginTop: '-70px' }}>
        <SignSection>
          {!!investorOneDebtor.archiveFileIdentifyier && (
            <DownloadLink
              href={SCFSupplierApi.downloadArchiveFileSupplier(match.params.id)}
            >
              <BackBtnText>Скачать архив</BackBtnText>
            </DownloadLink>
          )}

          {investorOneDebtor.agreementFileIdentifier !== null && (
            <DownloadLink
              href={SCFSupplierApi.downloadAgreementFileSupplierRole(
                match.params.id
              )}
            >
              <BackBtnText>Скачать документ</BackBtnText>
            </DownloadLink>
          )}

          {investorOneDebtor.debtorSignatureFileIdentifier !== null && (
            <DownloadLink
              href={SCFDebtorApi.downloadDebtorSignatureFile(match.params.id)}
            >
              <BackBtnText>Скачать подпись покупателя</BackBtnText>
            </DownloadLink>
          )}

          {investorOneDebtor.supplierSignatureFileIdentifier !== null && (
            <DownloadLink
              href={SCFDebtorApi.downloadSupplierSignatureFile(match.params.id)}
            >
              <BackBtnText>Скачать мою подпись</BackBtnText>
            </DownloadLink>
          )}
        </SignSection>
      </ConfirmSection>
      <div id="documentObject" style={{ width: '900px', height: '1000px' }} />
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier, SCFDebtor }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  statusInvestorOneDebtor:
    SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.status,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      resetInvestorOneDebtor,
      getMonetaryClaimsEarlyPaymentApplications,
      resetMonetaryClaimsEarlyPaymentApplications,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint,
      resetEarlyPaymentApplications
    },
    dispatch
  );

const ApplicationInfoConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationInfo)
);

export { ApplicationInfoConnect as ApplicationInfo };
