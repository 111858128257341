import * as React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectionsList } from './ConnectionsList/ConnectionsList';
import { ConnectionsCreatePage } from './ConnectionsCreate/ConnectionsCreate';
import { ConnectionView } from './ConnectionView/ConnectionView';
import { ConnectionsPageStyled } from './styles';

class FactorConnectionsPage extends React.Component {
  render() {
    return (
      <ConnectionsPageStyled>
        <Switch>
          <Route
            exact
            path={'/factor/connections'}
            render={() => <ConnectionsList />}
          />
          <Route
            exact
            path={'/factor/connections/add'}
            render={() => <ConnectionsCreatePage />}
          />
          <Route
            exact
            path={'/factor/connections/:guid'}
            render={() => <ConnectionView />}
          />
        </Switch>
      </ConnectionsPageStyled>
    );
  }
}

export default FactorConnectionsPage;
