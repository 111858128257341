import * as React from 'react';
import { ExampleDocumentBlock, ExampleDocumentInfo, DownloadButton } from '../../../BorrowerCompanies/styles';
import { UploadFileIcon } from 'shared/styled';
import { convertKbToMb } from 'src/shared/utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV, faFileExcel } from '@fortawesome/free-solid-svg-icons';

interface ExampleDocumentProps {
  name:string;
  size:number;
  link:string;
}

// TODO expand to include other icons
const ExampleDocument: React.FC<ExampleDocumentProps> = ({ name, size, link }) => (
    <ExampleDocumentBlock >
        {/* <UploadFileIcon /> */}
        <FontAwesomeIcon icon={faFileExcel} size="lg" color="green" />
        <ExampleDocumentInfo>
            <span>{name}</span>
            <span>{convertKbToMb(size * 1000)}</span>
        </ExampleDocumentInfo>
        <DownloadButton href={link}>Скачать</DownloadButton>
    </ExampleDocumentBlock>
);

export default ExampleDocument;
