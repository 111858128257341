import { PUT_REJECT_SUPPLIER } from 'entities/SCF/Debtor/model/reducers/putRejectSupplier';

export const req = (inn: string) => ({
  type: PUT_REJECT_SUPPLIER.REQ,
  inn
});

export const got = () => ({
  type: PUT_REJECT_SUPPLIER.GOT
});

export const err = (error: {}) => ({
  type: PUT_REJECT_SUPPLIER.ERR,
  error
});
