import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { CheckboxState } from 'src/features/Products/types';
import { ContractFinancingType } from '../types';

interface Props {
  checkboxState: CheckboxState;
  handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterByContract: React.FC<Props> = ({ handleFilter, checkboxState }) => {
  return (
    <CheckboxGroup
      name="typeOfContract"
      label="Тип контракта:"
      checkboxes={Object.keys(ContractFinancingType).map(type => ({
        label: ContractFinancingType[type],
        value: type,
        checked: checkboxState.typeOfContract.includes(type),
        onChange: handleFilter
      }))}
    />
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByContractConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByContract);

export { FilterByContractConnect as FilterByContract };
