import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getUserStats';

import { GET_USER_STATS } from 'entities/Cabinet/ExternalAgent/model/reducers/getUserStats';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getUserStats(action: { type: GET_USER_STATS }): Generator {
  try {
    const res: any = yield call(ExternalAgentRoleApi.getUserStats);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserStatsSaga(): Generator {
  yield takeLatest(GET_USER_STATS.REQ, getUserStats);
}
