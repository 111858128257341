import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResponseError, REQUEST_STATUSES, CRM } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  req as getCompaniesList,
  ResponseData,
  RequestData
} from 'src/features/CompaniesLists/actions/getCompaniesList';
import { ScrollTopComponent } from 'src/features/Common';
import { HeaderContainer } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  companiesList: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCompaniesList: (data: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const CompaniesListCRM: React.FC<Props> = ({
  getCompaniesList,
  companiesList,
  status,
  error,
  history
}) => {
  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;

      getCompaniesList({ page });
    },
    [history.location.search]
  );

  const navigateToCompany = (id: number) => {
    history.push({ pathname: `/crm/companies_lists/${id}`, search: 'page=1' });
  };

  const navigateToCompanyNew = () => {
    history.push('/crm/companies_lists/new');
  };

  return (
    <ScrollTopComponent>
      <HeaderContainer>
        <ApplicationTitle>Списки компаний</ApplicationTitle>
        <Button label="Создать новый список" onClick={navigateToCompanyNew} />
      </HeaderContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="50%">Название</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {companiesList.items.map(company => (
              <TableRowStyled
                key={company.id}
                onClick={() => navigateToCompany(company.id)}
              >
                <td>{company.id}</td>
                <td>{company.name}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={companiesList} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ CompaniesListCRM }: CRM) => ({
  companiesList: CompaniesListCRM.getCompaniesList.data,
  status: CompaniesListCRM.getCompaniesList.status,
  error: CompaniesListCRM.getCompaniesList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompaniesList }, dispatch);

const CompaniesListCRMConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CompaniesListCRM)
);

export { CompaniesListCRMConnect as CompaniesListCRM };
