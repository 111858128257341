import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface GetApplicationsQuantityStoreState extends Reducer {
  data: ResponseData;
}

export interface ResponseData {
  general?: number;
  work?: number;
  my?: number;
  drafts?: number;
  deals?: number;
  distribution?: number;
  allInWork?: number;
  allDeals?: number;
  allTrashed?: number;
}

export enum GET_APPLICATIONS_QUANTITY {
  REQ = 'GET_APPLICATIONS_QUANTITY_REQ',
  GOT = 'GET_APPLICATIONS_QUANTITY_GOT',
  ERR = 'GET_APPLICATIONS_QUANTITY_ERR'
}

export const initialState: GetApplicationsQuantityStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    general: null,
    work: null,
    my: null,
    drafts: null,
    distribution: null,
    allInWork: null,
    allDeals: null,
    allTrashed: null
  },
  error: {}
};

const getApplicationsQuantity = (
  state: GetApplicationsQuantityStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetApplicationsQuantityStoreState => {
  switch (action.type) {
    case GET_APPLICATIONS_QUANTITY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case GET_APPLICATIONS_QUANTITY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };

    case GET_APPLICATIONS_QUANTITY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getApplicationsQuantity;
