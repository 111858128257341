import { combineReducers } from 'redux';

import externalAgentRegister, {
  ExternalAgentRegisterStoreState
} from './externalAgentRegister';
import externalAgentStatus, {
  ExternalAgentStatusStoreState
} from './externalAgentStatus';

export interface ExternalAgentReducers {
  externalAgentRegister: ExternalAgentRegisterStoreState;
  externalAgentStatus: ExternalAgentStatusStoreState;
}

export const ExternalAgent = combineReducers({
  externalAgentRegister,
  externalAgentStatus
});
