import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  REQUEST_STATUSES,
  CRM,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getClientsList,
  RequestData
} from 'src/features/Clients/actions/getClientsList';
import { ResponseData as ClientsData } from 'src/features/Clients/actions/getClientsList';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { BtnAddClient } from 'src/features/ClientsForAgent/components/styled';
import {
  PreApproval,
  ClientHeader,
  FiltersStyled,
  InnInputContainer,
  TDCompanyName,
  PreApprovalTH
} from './styled';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  clients: ClientsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getClientsList: (data?: RequestData) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const ClientsList: React.FC<Props> = ({
  getClientsList,
  clients,
  status,
  error,
  permissions,
  history
}) => {
  const [companyInn, setCompanyInn] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const companyInn = searchParams.get('companyInn') || '';
      const companyName = searchParams.get('companyName') || '';

      setCompanyInn(companyInn);
      setCompanyName(companyName);

      getClientsList({
        ...(companyInn && { companyInn }),
        ...(companyName && { companyName })
      });
    },
    [history.location.search]
  );

  const onClientsClick = (companyInn: string) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push({ pathname: `/cabinet/${role}/clients/inn/${companyInn}` });
  };

  const handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleAddClient = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/clients/add`);
  };

  return (
    <ScrollTopComponent>
      <ClientHeader>
        <ApplicationTitle>Мои клиенты</ApplicationTitle>
        <Button
          label="+ Добавить клиента"
          onClick={handleAddClient}
          w="fit-content"
          h="40px"
        />
      </ClientHeader>

      <FiltersStyled>
        <InnInputContainer>
          <Input
            value={companyInn}
            label="ИНН Компании"
            name="companyInn"
            placeholder={InnPlaceholders.entity}
            onChange={handleFilter}
          />
        </InnInputContainer>

        <InnInputContainer>
          <Input
            value={companyName}
            label="Наименование клиента:"
            name="companyName"
            onChange={handleFilter}
          />
        </InnInputContainer>
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">ИНН</TableThStyled>
              <TableThStyled width="15%">Наименование</TableThStyled>
              <TableThStyled width="20%">Комментарий</TableThStyled>
              {permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) && (
                <>
                  <TableThStyled width="10%">Самый дешевый</TableThStyled>
                  <PreApprovalTH width="10%">
                    <div>Предварительные одобрения кредитования без залога</div>
                    Максимальный лимит
                  </PreApprovalTH>
                  <TableThStyled width="10%">Максимальный срок</TableThStyled>
                </>
              )}
            </tr>
          </TableHeaderStyled>
          <tbody>
            {clients.items.map((client, key) => (
              <TableRowStyled
                onClick={() => onClientsClick(client.companyINN)}
                key={key}
              >
                <td>{client.companyINN}</td>
                <TDCompanyName isFilled={client.companyName}>
                  {client.companyName ? client.companyName : 'не заполнено'}
                </TDCompanyName>
                <td>{client.comment}</td>
                {permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) && (
                  <>
                    <td>
                      <PreApproval>
                        <div>
                          {client.lowestRateName === null
                            ? '-'
                            : client.lowestRateName}
                        </div>
                        <div>
                          {client.lowestRateVolume === null
                            ? '-'
                            : formSumStringThousands(
                                client.lowestRateVolume
                              )}{' '}
                          руб.
                        </div>
                        <div>
                          {`${
                            client.lowestRate === null
                              ? '-'
                              : client.lowestRate.toFixed(1)
                          } % годовых,`}{' '}
                          {client.lowestRateTerm === null
                            ? '-'
                            : client.lowestRateTerm}{' '}
                          мес.
                        </div>
                      </PreApproval>
                    </td>
                    <td>
                      <PreApproval>
                        <div>
                          {client.maxVolumeName === null
                            ? '-'
                            : client.maxVolumeName}
                        </div>
                        <div>
                          {client.maxVolume === null
                            ? '-'
                            : formSumStringThousands(client.maxVolume)}{' '}
                          руб.
                        </div>
                        <div>
                          {`${
                            client.maxVolumeRate === null
                              ? '-'
                              : client.maxVolumeRate.toFixed(1)
                          } % годовых,`}{' '}
                          {client.maxVolumeTerm === null
                            ? '-'
                            : client.maxVolumeTerm}{' '}
                          мес.
                        </div>
                      </PreApproval>
                    </td>
                    <td>
                      <PreApproval>
                        <div>
                          {client.longestTermName === null
                            ? '-'
                            : client.longestTermName}
                        </div>
                        <div>
                          {client.longestTermVolume === null
                            ? '-'
                            : formSumStringThousands(
                                client.longestTermVolume
                              )}{' '}
                          руб.
                        </div>
                        <div>
                          {`${
                            client.longestTermRate === null
                              ? '-'
                              : client.longestTermRate.toFixed(1)
                          } % годовых,`}{' '}
                          {client.longestTerm === null
                            ? '-'
                            : client.longestTerm}{' '}
                          мес.
                        </div>
                      </PreApproval>
                    </td>
                  </>
                )}
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={clients} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ User, Clients }: CRM) => ({
  clients: Clients.getClientsList.data,
  status: Clients.getClientsList.status,
  error: Clients.getClientsList.error,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getClientsList }, dispatch);

const ClientsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ClientsList)
);

export { ClientsListConnect as ClientsList };
