import styled from 'styled-components';

export const RejectReasonModalStyled = styled.div`
  width: 400px;
  & > * {
    padding: 15px;
  }
`;

export const ErrorModal = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 500;
  max-width: 700px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;
