import * as React from 'react';
import { history } from 'src/shared/utils/History';
import { RequestDataType } from 'User/actions/signIn';
import { InputPassword } from 'shared/ui/InputPassword';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { Button } from 'shared/ui/Button';
import { ForgotPasswordBtn } from 'User/components';
import { createValidation } from './validator';
import { RegistrationLinksBlock } from './styles';
import './styles/style.css';

export interface AuthenticationFields {
  email: string;
  password: string;
}

interface State extends AuthenticationFields {
  submitClicked: boolean;
}

interface Props {
  onSubmit: (data: RequestDataType) => void;
}

export class AuthenticationFormSupplier extends React.Component<Props, State> {
  Validator = createValidation();
  state: State = {
    email: '',
    password: '',
    submitClicked: false
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    this.setState({
      [name]: value
    } as { [key in keyof AuthenticationFields]: string });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.setState({ submitClicked: true });

    if (!this.Validator.isFormValid()) {
      return;
    }

    const { email, password } = this.state;
    this.props.onSubmit({ email, password });
  };

  render() {
    const { email, password } = this.state;
    const { errors } = this.Validator.validate(this.state);

    return (
      <Form name="user-login-form" onSubmit={this.onSubmit}>
        <FormSection>
          <Input
            value={email}
            name="email"
            placeholder="Email"
            label="Email"
            required={true}
            error={errors.email}
            onChange={this.onChange}
          />
          <InputPassword
            value={password}
            name="password"
            placeholder="Пароль"
            label="Пароль"
            required={true}
            error={errors.password}
            onChange={this.onChange}
          />

          <RegistrationLinksBlock>
            <ForgotPasswordBtn />
          </RegistrationLinksBlock>
        </FormSection>
        <Button
          template="nextBtn"
          type="submit"
          label="Войти"
          disabled={!this.Validator.isFormValid()}
        />
        <div
          className="scf-reg-button"
          onClick={() => history.push('/scf/supplier/registration')}
        >
          <p className="scf-reg-button-a">Зарегистрироваться</p>
        </div>
      </Form>
    );
  }
}
