import { PUT_ADD_POA } from 'src/features/SCF/reducers/putAddPoa';

export interface RequestData {
  PoaGuid: string;
  PersonInn: number;
  Password: string;
}

export const req = (data: RequestData) => ({
  type: PUT_ADD_POA.REQ,
  data
});

export const got = () => ({
  type: PUT_ADD_POA.GOT
});

export const err = (error: {}) => ({
  type: PUT_ADD_POA.ERR,
  error
});
