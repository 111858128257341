import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Debtor/model/actions/SCFRegisterDebtor';
import { SCF_REGISTER_DEBTOR } from 'entities/SCF/Debtor/model/reducers/SCFRegisterDebtor';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* SCFRegisterDebtor(action: {
  type: SCF_REGISTER_DEBTOR;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFDebtorApi.registerDebtor, action.data);
    yield put(got(action.data));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* SCFRegisterDebtorSaga() {
  yield takeLatest(
    SCF_REGISTER_DEBTOR.SCF_REGISTER_DEBTOR_REQ,
    SCFRegisterDebtor
  );
}
