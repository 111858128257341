import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchCabinetRegionForbidden extends Reducer {}

export enum PATCH_CABINET_SET_FORBIDDEN_REGION {
  REQ = 'PATCH_CABINET_SET_FORBIDDEN_REGION_REQ',
  GOT = 'PATCH_CABINET_SET_FORBIDDEN_REGION_GOT',
  ERR = 'PATCH_CABINET_SET_FORBIDDEN_REGION_ERR'
}

export const initialState: PatchCabinetRegionForbidden = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchCabinetRegionForbidden = (
  state: PatchCabinetRegionForbidden = initialState,
  action: {
    type: string;
    error: {};
  }
): PatchCabinetRegionForbidden => {
  switch (action.type) {
    case PATCH_CABINET_SET_FORBIDDEN_REGION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_CABINET_SET_FORBIDDEN_REGION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_CABINET_SET_FORBIDDEN_REGION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchCabinetRegionForbidden;
