import * as React from 'react';
import { connect } from 'react-redux';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getProductsByBankList,
  ResponseData as getProductsByBankListResponseData,
  RequestData as getProductsByBankListRequestData
} from '../../actions/getProductsByBankList';
import { history } from 'src/shared/utils/History';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  ProgressWrap,
  TableContainer
} from 'src/features/Products/components/ProductsList/styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  products: getProductsByBankListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getProductsByBankList: (data: getProductsByBankListRequestData) => void;
}

interface OwnProps {
  bankInn: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ProductsByBankList extends React.Component<Props> {
  componentDidMount() {
    const { bankInn, getProductsByBankList } = this.props;

    // TODO duplication with applicationslist
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getProductsByBankList({ page, pageSize: 20, bankInn: bankInn });
  }

  onProductClick(index: number) {
    history.push(`/crm/products/${this.props.products.items[index].id}`);
  }

  navigateToAddProduct = () => {
    history.push(`/crm/products/new`, { inn: this.props.bankInn });
  };

  render() {
    const { products, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableContainer>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="1%">ID</TableThStyled>
                  <TableThStyled width="7%">Название</TableThStyled>
                  <TableThStyled width="7%">Тип</TableThStyled>
                  <TableThStyled width="4%">Cashback</TableThStyled>
                  <TableThStyled width="4%">Через FF</TableThStyled>
                  <TableThStyled width="2%">МСП</TableThStyled>
                  <TableThStyled width="2%">КСБ</TableThStyled>
                  <TableThStyled width="6%">Мин. лимит</TableThStyled>
                  <TableThStyled width="6%">Макс. лимит</TableThStyled>
                  <TableThStyled width="5%">Риск-Аппетит</TableThStyled>
                  <TableThStyled width="6%">Сложность</TableThStyled>
                  <TableThStyled width="8%">Эксклюзивность</TableThStyled>
                  <TableThStyled width="8%">Качество данных</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {products.items.map((product, key) => (
                  <TableRowStyled
                    onClick={() => this.onProductClick(key)}
                    key={key}
                  >
                    <td>{product.id}</td>
                    <td>{product.productName}</td>
                    <td>{product.financeTypeName}</td>
                    <td>{product.hasCashback ? 'да' : ''}</td>
                    <td>{product.isInPlatform ? 'да' : ''}</td>
                    <td>{product.isForMicro ? 'да' : ''}</td>
                    <td>{product.isForMedium ? 'да' : ''}</td>
                    <td>
                      {product.minSize > 0
                        ? formSumStringThousands(product.minSize)
                        : '-'}
                    </td>
                    <td>
                      {product.maxSize > 0
                        ? formSumStringThousands(product.maxSize)
                        : '-'}
                    </td>
                    <td>{product.riskAppetite}</td>
                    <td>{product.complexity}</td>
                    <td>{product.exclusivity}</td>
                    <td>
                      <ProgressWrap percent={product.dataQuality}>
                        <figcaption>{product.dataQuality}%</figcaption>
                      </ProgressWrap>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          </TableContainer>
        )}

        <Button
          label="Добавить продукт"
          onClick={this.navigateToAddProduct}
          h="40px"
        />
        <Pagination list={products} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }
}

const mapStateToProps = ({ Products, User }: CRM) => ({
  products: Products.getProductsByBankList.data,
  status: Products.getProductsByBankList.status,
  error: Products.getProductsByBankList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getProductsByBankList }, dispatch);

const ProductsByBankListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsByBankList);

export { ProductsByBankListConnect as ProductsByBankList };
