import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  faGavel,
  faSearch,
  faSlidersH,
  faGem,
  faTimes,
  faUserCircle,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import {
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { Input } from 'shared/ui/Input';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import {
  ResponseDataType,
  req as getCompanyByInn,
  reset as resetStateCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { req as getPersonsOfCompanies } from 'Business/actions/getPersonsOfCompanies';
import { req as getBeneficiariesOfCompanies } from 'Business/actions/getBeneficiariesOfCompanies';
import { req as getSubsidiariesOfCompanies } from 'Business/actions/getSubsidiariesOfCompanies';
import { req as getCompanyByInnConfirmedFounders } from 'src/features/Business/actions/getCompanyByInnConfirmedFounders';
import { req as getCompanyByInnConfirmedSubsidiaries } from 'src/features/Business/actions/getCompanyByInnConfirmedSubsidiaries';
import {
  req as getSecondCompanyByInn,
  RequestDataType as RequestDataTypeSecondCompany,
  ResponseDataType as ResponseDataTypeSecondCompany
} from 'src/features/SCF/actions/getSecondCompanyByInnThirdParty';
import {
  req as getCompanyFastInfo,
  ResponseDataType as ResponseCompanyFastInfo
} from 'src/features/SCF/actions/getCompanyFastInfo';
import {
  req as getFinancialStatementsAll,
  ResponseType as financialStatementsAllData
} from 'src/features/FinancialForCompany/actions/getFinancialStatementsAll';
import {
  ApplicationView,
  BalanceContainer,
  BalanceWrapper,
  BusinessData,
  CompanyNameContainer,
  CopyBlock,
  HeadData,
  Image,
  InputContainer,
  MainSection,
  NotFound,
  Notification,
  NotificationContainer,
  PersonInfo,
  PersonInfoContainer,
  PersonInfoDirector,
  PersonName,
  PersonsContainer,
  SkeletonWrapper,
  TaxesInfoContainer,
  Title
} from './styled';
import YandexMetrica from 'src/shared/utils/YandexMetrica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APPLICATION_TYPES } from 'src/features/Application/types';
import {
  BeneficiariesOfCompanies,
  PersonsOfCompanies,
  SubsidiariesType
} from '../../types';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { Spinner } from 'src/features/Layouts/components';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'src/features/FinancialForCompany/components/styled';
import {
  OPIY,
  financialStatementActiveLabels,
  financialStatementPassiveLabels
} from 'src/features/FinancialForCompany/types';
import {
  filtredUtil,
  getTaxPeriodCode,
  sortPeriod
} from 'src/features/FinancialForCompany/utils';
import { FiltredData } from 'src/features/FinancialForCompany/components/FinancialForCompany';
import FinancialForCompanyApi from 'src/features/FinancialForCompany/api';
import ApplicationApi from 'src/features/Application/api';

interface StateToProps {
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
  secondCompanyByInn: ResponseDataTypeSecondCompany;
  secondStatus: REQUEST_STATUSES;
  persons: PersonsOfCompanies[];
  statusPersons: REQUEST_STATUSES;
  beneficiaries: BeneficiariesOfCompanies[];
  statusBeneficiaries: REQUEST_STATUSES;
  errorBeneficiarieserrorPersons: ResponseError;
  subsidiaries: SubsidiariesType[];
  statusSubsidiaries: REQUEST_STATUSES;
  errorSubsidiaries: ResponseError;
  confirmedCompanyFounders: ResponseDataType[];
  statusConfirmedCompanyFounders: REQUEST_STATUSES;
  confirmedCompanySubsidiaries: ResponseDataType[];
  statusConfirmedCompanySubsidiaries: REQUEST_STATUSES;
  companyFastInfo: ResponseCompanyFastInfo;
  statusCompanyFastInfo: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  getSecondCompanyByInn: (data: RequestDataTypeSecondCompany) => void;
  resetStateCompanyByInn: () => void;
  getPersonsOfCompanies: (inn: string) => void;
  getBeneficiariesOfCompanies: (inn: string) => void;
  getSubsidiariesOfCompanies: (inn: string) => void;
  getCompanyByInnConfirmedFounders: (data: string[]) => void;
  getCompanyByInnConfirmedSubsidiaries: (data: string[]) => void;
  getCompanyFastInfo: (inn: string) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const BusinessView: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  status,
  error,
  getSecondCompanyByInn,
  secondCompanyByInn,
  secondStatus,
  getPersonsOfCompanies,
  persons,
  statusPersons,
  getBeneficiariesOfCompanies,
  beneficiaries,
  statusBeneficiaries,
  getSubsidiariesOfCompanies,
  subsidiaries,
  statusSubsidiaries,
  getCompanyByInnConfirmedFounders,
  statusConfirmedCompanyFounders,
  getCompanyByInnConfirmedSubsidiaries,
  statusConfirmedCompanySubsidiaries,
  getCompanyFastInfo,
  companyFastInfo,
  statusCompanyFastInfo,
  isAuthorized,
  permissions,
  history,
  match
}) => {
  const [companyIcon, setCompanyIcon] = React.useState(null);
  const [beneficiariesIcon, setBeneficiariesIcon] = React.useState<{
    [key: string]: string;
  }>({});
  const [subsidiariesIcon, setSubsidiariesIcon] = React.useState<{
    [key: string]: string;
  }>({});
  const [isCopied, setIsCopied] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [isVisibleNotification, setIsVisibleNotification] = React.useState<
    number[]
  >([]);
  const [isRequest, setIsRequest] = React.useState(0);
  const [
    filtredFinancialStatementsAll,
    setFiltredFinancialStatementsAll
  ] = React.useState<FiltredData[]>([]);
  const [years, setYears] = React.useState([]);
  const [financialStatementsAll, setFinancialStatementsAll] = React.useState<
    financialStatementsAllData
  >({ page: 1, pageSize: 0, totalItems: 0, items: [] });

  React.useEffect(
    () => {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain.toLowerCase() === 'my') {
        return;
      } else if (
        !isAuthorized ||
        (permissions.length && !permissions.includes(USER_PERMISSIONS.ADMIN))
      ) {
        window.location.href = `https://my.finfactory.one/business/${
          match.params.inn
        }`;
      }
    },
    [isAuthorized, permissions, match.params.inn, window.location.hostname]
  );

  React.useEffect(
    () => {
      if (match.params.inn) {
        getCompanyFastInfo(match.params.inn);
        getCompanyByInn({ inn: match.params.inn, force: true });
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT && isRequest < 2) {
        const linkElement = document.createElement('link');
        const pageLink = window.location.href;
        linkElement.rel = 'canonical';
        linkElement.href = pageLink;

        const headElement =
          document.head || document.getElementsByTagName('head')[0];

        if (companyByInn.inn.length === 10 || companyByInn.inn.length === 12) {
          headElement.appendChild(linkElement);
        }

        getPersonsOfCompanies(match.params.inn);
        getBeneficiariesOfCompanies(match.params.inn);
        getSubsidiariesOfCompanies(match.params.inn);
        setIsRequest(prev => prev + 1);

        return () => {
          const existingLink = headElement.querySelector(
            'link[rel="canonical"]'
          );
          if (existingLink) {
            headElement.removeChild(existingLink);
          }
        };
      }
      if (statusCompanyFastInfo === REQUEST_STATUSES.GOT) {
        document.title = `ИНН ${companyFastInfo.inn} - ${
          companyFastInfo.shortName
        } - ОГРН ${companyFastInfo.ogrn}`;
        const linkElement = document.createElement('link');
        const pageLink = `/business/${companyFastInfo.inn}`;
        linkElement.rel = 'canonical';
        linkElement.href = pageLink;

        const headElement =
          document.head || document.getElementsByTagName('head')[0];

        if (companyByInn.inn.length !== 10 && companyByInn.inn.length !== 12) {
          headElement.appendChild(linkElement);
        }

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = `Информация об ${
          companyFastInfo.shortName
        }, находящейся по адресу ${companyFastInfo.address}, ИНН ${
          companyFastInfo.inn
        }, ОГРН ${companyFastInfo.ogrn} - реквизиты, финансы, руководство`;
        document.head.appendChild(metaDescription);

        const prevTitle = document.title;
        return () => {
          document.title = prevTitle;
          if (metaDescription) {
            document.head.removeChild(metaDescription);
          }
          const existingLink = headElement.querySelector(
            'link[rel="canonical"]'
          );
          if (existingLink) {
            headElement.removeChild(existingLink);
          }
        };
      }
    },
    [status, statusConfirmedCompanyFounders, statusConfirmedCompanySubsidiaries]
  );

  React.useEffect(
    () => {
      if (
        statusBeneficiaries === REQUEST_STATUSES.GOT &&
        beneficiaries.length &&
        isRequest === 1
      ) {
        const companyInns = beneficiaries
          .filter(item => Object.keys(item).length === 1)
          .map(item => item.companyInn);

        if (companyInns.length > 0) {
          getCompanyByInnConfirmedFounders(companyInns);
        }
      }
    },
    [statusBeneficiaries, beneficiaries]
  );

  React.useEffect(
    () => {
      if (
        statusSubsidiaries === REQUEST_STATUSES.GOT &&
        subsidiaries.length &&
        isRequest === 1
      ) {
        const companyInns = subsidiaries
          .filter(item => Object.keys(item).length === 1)
          .map(item => item.companyInn);

        if (companyInns.length > 0) {
          getCompanyByInnConfirmedSubsidiaries(companyInns);
        }
      }
    },
    [statusSubsidiaries, subsidiaries]
  );

  React.useEffect(
    () => {
      if (searchValue.length >= 10) {
        getSecondCompanyByInn({ inn: searchValue });
      }
    },
    [searchValue]
  );

  React.useEffect(
    () => {
      const fetchData = async () => {
        const year = new Date().getFullYear();

        const updateData = [] as financialStatementsAllData[];

        const fetchDataForYear = async (targetYear: number) => {
          try {
            const financialData = (await FinancialForCompanyApi.getFinancialStatementsAll(
              {
                companyinn: match.params.inn,
                TaxYear: targetYear
              }
            )) as financialStatementsAllData;

            if (!financialData.totalItems) {
              await ApplicationApi.getExternalApiGIR_BO(
                match.params.inn,
                targetYear
              );
              updateData.push(
                (await FinancialForCompanyApi.getFinancialStatementsAll({
                  companyinn: match.params.inn,
                  TaxYear: targetYear
                })) as financialStatementsAllData
              );
            }

            return financialData;
          } catch (error) {
            console.error(`Ошибка за ${targetYear} год:`, error);
            return null;
          }
        };

        const data1 = await fetchDataForYear(year - 1);

        const data2 = await fetchDataForYear(year - 2);

        const data3 = await fetchDataForYear(year - 3);

        if (data1 || data2 || data3) {
          setFinancialStatementsAll({
            page: 1,
            pageSize: 20,
            totalItems:
              ((data1 && data1.totalItems) || 0) +
              ((data2 && data2.totalItems) || 0) +
              ((data3 && data3.totalItems) || 0),
            items: [].concat(
              (data1 && data1.items) || [],
              (data2 && data2.items) || [],
              (data3 && data3.items) || []
            )
          });
        } else if (updateData.length > 0) {
          window.location.reload();
        }
      };

      if (!!match.params.inn) {
        fetchData();
      }
    },
    [match.params.inn]
  );

  React.useEffect(
    () => {
      setYears(
        filtredFinancialStatementsAll.map(item => ({
          year: item.year,
          taxPeriodCode: item.taxPeriodCode
        }))
      );
    },
    [filtredFinancialStatementsAll]
  );

  React.useEffect(
    () => {
      if (financialStatementsAll.totalItems) {
        const result = filtredFinancialStatementsAll.map(oldItem => {
          const newItem = financialStatementsAll.items.find(
            newItem =>
              newItem.year === oldItem.year &&
              newItem.taxPeriodCode === oldItem.taxPeriodCode
          );

          if (newItem) {
            const active = {};
            const passive = {};
            const OPIY = {};

            for (const key in newItem) {
              if (key.startsWith('row')) {
                const rowNumber = parseInt(key.substring(3), 10);
                if (
                  (rowNumber >= 1100 && rowNumber <= 1299) ||
                  rowNumber === 1600
                ) {
                  active[key] = newItem[key];
                } else if (
                  (rowNumber >= 1300 && rowNumber <= 1599) ||
                  rowNumber === 1700
                ) {
                  passive[key] = newItem[key];
                } else if (rowNumber >= 2100 && rowNumber <= 2410) {
                  OPIY[key] = newItem[key];
                }
              }
            }

            return {
              ...oldItem,
              active: { ...oldItem.active, ...active },
              passive: { ...oldItem.passive, ...passive },
              OPIY: { ...oldItem.OPIY, ...OPIY }
            };
          } else {
            return oldItem;
          }
        });

        const filteredData = filtredUtil(financialStatementsAll);

        const sortedData = sortPeriod(filteredData).filter(
          item => item.taxPeriodCode === 34
        );

        const sortedData2 = sortPeriod([...sortedData, ...result]);

        const uniqueObjects = [] as FiltredData[];
        const uniqueKeys = new Set();

        for (const obj of sortedData2) {
          const key = `${obj.year}_${obj.taxPeriodCode}`;
          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            uniqueObjects.push(obj);
          }
        }

        setFiltredFinancialStatementsAll(uniqueObjects);
      }
    },
    [financialStatementsAll]
  );

  React.useEffect(
    () => {
      if (statusBeneficiaries === REQUEST_STATUSES.GOT) {
        beneficiaries.forEach((company, index) => {
          loadBeneficiariesIcon(company.okvedCode, company.okvedPic, index);
        });
      }
    },
    [statusBeneficiaries]
  );

  React.useEffect(
    () => {
      if (statusSubsidiaries === REQUEST_STATUSES.GOT) {
        subsidiaries.forEach((company, index) => {
          loadSubsidiariesIcon(company.okvedCode, company.okvedPic, index);
        });
      }
    },
    [statusSubsidiaries]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        loadCompanyIcon(companyByInn.okvedCode, companyByInn.okvedPic);
      }
    },
    [status]
  );

  const activeRows = {};
  const passiveRows = {};
  const OPIYRows = {};

  filtredFinancialStatementsAll.forEach(item => {
    if (item.active) {
      Object.keys(item.active).forEach(rowKey => {
        activeRows[`row${1100}`] = true;
        activeRows[`row${1200}`] = true;
        activeRows[rowKey] = true;
      });
    }

    if (item.passive) {
      Object.keys(item.passive).forEach(rowKey => {
        passiveRows[`row${1300}`] = true;
        passiveRows[`row${1400}`] = true;
        passiveRows[`row${1500}`] = true;
        passiveRows[rowKey] = true;
      });
    }

    if (item.OPIY) {
      Object.keys(item.OPIY).forEach(rowKey => {
        OPIYRows[rowKey] = true;
      });
    }
  });

  const emptyFieldActive = financialStatementActiveLabels.filter(
    item => activeRows[`row${item.code}`]
  );

  const emptyFieldPassive = financialStatementPassiveLabels.filter(
    item => passiveRows[`row${item.code}`]
  );

  const emptyFieldOPIY = OPIY.filter(item => OPIYRows[`row${item.code}`]);

  const activeMatchingCode = emptyFieldActive.find(item1 =>
    financialStatementActiveLabels.some(item2 => item1.code === item2.code)
  );

  const currentAssetsMatchingCode = emptyFieldActive.find(item1 =>
    financialStatementActiveLabels.some(
      item2 =>
        item1.code >= 1200 && item1.code <= 1260 && item1.code === item2.code
    )
  );

  const passiveMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(item2 => item1.code === item2.code)
  );

  const longTermObligationsMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(
      item2 =>
        item1.code >= 1400 && item1.code <= 1450 && item1.code === item2.code
    )
  );

  const shortTermLiabilitiesMatchingCode = emptyFieldPassive.find(item1 =>
    financialStatementPassiveLabels.some(
      item2 =>
        item1.code >= 1500 && item1.code <= 1550 && item1.code === item2.code
    )
  );

  const loadCompanyIcon = async (okvedCode: string, okvedPic: string) => {
    if (okvedPic) {
      const icon = await import(`../../../../assets/icons/${okvedPic}.png`);
      setCompanyIcon(icon.default);
    } else if (okvedCode) {
      const code = parseInt(okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setCompanyIcon(icon.default);
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setCompanyIcon(null);
      }
    }
  };

  const loadSubsidiariesIcon = async (
    okvedCode: string,
    okvedPic: string,
    index: number
  ) => {
    if (okvedCode) {
      const code = parseInt(okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setSubsidiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setSubsidiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: undefined
        }));
      }
    } else if (okvedPic) {
      const icon = await import(`../../../../assets/icons/${okvedPic}.png`);
      setSubsidiariesIcon(prevIcons => ({
        ...prevIcons,
        [index]: icon.default
      }));
    }
  };

  const loadBeneficiariesIcon = async (
    okvedCode: string,
    okvedPic: string,
    index: number
  ) => {
    if (okvedCode) {
      const code = parseInt(okvedCode);
      const formattedCode = code < 10 ? `0${code}` : code.toString();

      try {
        const icon = await import(`../../../../assets/icons/${formattedCode}.png`);
        setBeneficiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: icon.default
        }));
      } catch (error) {
        console.error(`Icon not found for OKVED code: ${formattedCode}`, error);
        setBeneficiariesIcon(prevIcons => ({
          ...prevIcons,
          [index]: undefined
        }));
      }
    } else if (okvedPic) {
      const icon = await import(`../../../../assets/icons/${okvedPic}.png`);
      setBeneficiariesIcon(prevIcons => ({
        ...prevIcons,
        [index]: icon.default
      }));
    }
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const navigateToBusiness = (inn?: string) => {
    if (!!inn) {
      history.push(`/business/${inn}`);
    } else {
      history.push(`/business/${secondCompanyByInn.inn}`);
    }
    setSearchValue('');
  };

  const navigateToLogin = () => {
    history.push('/login');
  };

  const navigateToRegister = () => {
    history.push(`/registration/business/${companyByInn.inn}`);
  };

  const navigateToCabinet = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/application-form/create/choice_screen`);
  };

  const navigateToInitializeCabinet = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/application-form/create/initialize`, {
      financingType: APPLICATION_TYPES.FACTORING
    });
  };

  const navigateToGuarantees = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(
      `/cabinet/${role}/application-form/create/guarantees/options`,
      {
        financingType: APPLICATION_TYPES.GUARANTEES
      }
    );
  };

  const navigateToFinancing = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(
      `/cabinet/${role}/application-form/create/contract_financing/initialization`,
      { financingType: APPLICATION_TYPES.CONTRACT_FINANCING }
    );
  };

  const navigateToPerson = (inn: string) => {
    history.push(`/person/${inn}`);
  };

  const handleSetIsVisibleNotification = (contentIndex: number) => {
    setIsVisibleNotification([...isVisibleNotification, contentIndex]);
  };

  return (
    <ApplicationView>
      <MainSection itemScope itemType="http://schema.org/Organization">
        <InputContainer>
          <Input
            name="searchInn"
            value={searchValue}
            onChange={e => handleSetSearchValue(e.currentTarget.value)}
            icon={faSearch}
            placeholder="Поиск по ИНН"
          />

          {searchValue.length >= 10 &&
            secondStatus === REQUEST_STATUSES.GOT && (
              <InnPopup
                companyInfo={secondCompanyByInn}
                onPopupClick={navigateToBusiness}
              />
            )}
        </InputContainer>

        {(status !== REQUEST_STATUSES.ERROR ||
          statusCompanyFastInfo !== REQUEST_STATUSES.ERROR) && (
          <>
            <HeadData>
              <div>
                <p>ИНН:</p>
                <CopyBlock
                  text={
                    companyFastInfo.inn && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.inn)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p itemProp="taxID">{companyFastInfo.inn}</p>
                  )}
                </CopyBlock>
              </div>
              <div>
                <p>ОГРН:</p>
                <CopyBlock
                  text={
                    companyFastInfo.ogrn && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.ogrn)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p itemProp="identifier">{companyFastInfo.ogrn}</p>
                  )}
                </CopyBlock>
              </div>
            </HeadData>

            <CompanyNameContainer>
              <CopyBlock
                text={
                  companyFastInfo.shortName && !isCopied
                    ? 'Скопировать'
                    : isCopied && 'Скопировано!'
                }
                onClick={() => handleCopyClick(companyFastInfo.shortName)}
                onMouseLeave={() => setIsCopied(false)}
              >
                {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                  <SkeletonWrapper width={'250px'} height={'35px'} />
                ) : (
                  <Title itemProp="legalName">
                    {companyFastInfo.shortName}
                  </Title>
                )}
              </CopyBlock>

              {statusCompanyFastInfo === REQUEST_STATUSES.GOT &&
                companyFastInfo.endDate &&
                new Date(companyFastInfo.endDate) < new Date() && (
                  <div>Не действует</div>
                )}
            </CompanyNameContainer>

            <BusinessData>
              <div>
                <p>Полное наименование:</p>
                <CopyBlock
                  text={
                    companyFastInfo.fullName && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.fullName)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'300px'} height={'20px'} />
                  ) : (
                    <p>{companyFastInfo.fullName}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>Краткое наименование:</p>
                <CopyBlock
                  text={
                    companyFastInfo.shortName && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.shortName)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p itemProp="alternateName">{companyFastInfo.shortName}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>Адрес:</p>
                <CopyBlock
                  text={
                    companyFastInfo.address && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.address)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'300px'} height={'20px'} />
                  ) : (
                    <p itemProp="address">{companyFastInfo.address}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>Основной ОКВЭД:</p>
                <CopyBlock
                  text={
                    companyByInn.okvedCode && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() =>
                    handleCopyClick(
                      `${companyByInn.okvedCode} ${companyByInn.okvedName}`
                    )
                  }
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {status === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'300px'} height={'20px'} />
                  ) : (
                    <p itemProp="isicV4">
                      {companyByInn.okvedCode} {companyByInn.okvedName}
                    </p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>Дата регистрации:</p>
                <CopyBlock
                  text={
                    companyFastInfo.regDate && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() =>
                    handleCopyClick(
                      new Date(companyFastInfo.regDate).toLocaleString()
                    )
                  }
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'100px'} height={'20px'} />
                  ) : (
                    <p>
                      {new Date(companyFastInfo.regDate).toLocaleDateString()}
                    </p>
                  )}
                </CopyBlock>
              </div>

              {companyFastInfo.endDate && (
                <div>
                  <p>Дата окончания:</p>
                  <CopyBlock
                    text={
                      companyFastInfo.endDate && !isCopied
                        ? 'Скопировать'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() =>
                      handleCopyClick(
                        new Date(companyFastInfo.endDate).toLocaleString()
                      )
                    }
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                      <SkeletonWrapper width={'100px'} height={'20px'} />
                    ) : (
                      <p>
                        {new Date(companyFastInfo.endDate).toLocaleDateString()}
                      </p>
                    )}
                  </CopyBlock>
                </div>
              )}

              <div>
                <p>ОГРН:</p>
                <CopyBlock
                  text={
                    companyFastInfo.ogrn && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.ogrn)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p itemProp="identifier">{companyFastInfo.ogrn}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>ИНН:</p>
                <CopyBlock
                  text={
                    companyFastInfo.inn && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.inn)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'125px'} height={'20px'} />
                  ) : (
                    <p itemProp="taxID">{companyFastInfo.inn}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>КПП:</p>
                <CopyBlock
                  text={
                    companyFastInfo.kpp && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyFastInfo.kpp)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {statusCompanyFastInfo === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'100px'} height={'20px'} />
                  ) : (
                    <p>{companyFastInfo.kpp}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>ПФР:</p>
                <CopyBlock
                  text={
                    companyByInn.pfr && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyByInn.pfr)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {status === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'150px'} height={'20px'} />
                  ) : (
                    <p>{companyByInn.pfr}</p>
                  )}
                </CopyBlock>
              </div>

              <div>
                <p>ФСС:</p>
                <CopyBlock
                  text={
                    companyByInn.fss && !isCopied
                      ? 'Скопировать'
                      : isCopied && 'Скопировано!'
                  }
                  onClick={() => handleCopyClick(companyByInn.fss)}
                  onMouseLeave={() => setIsCopied(false)}
                >
                  {status === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'175px'} height={'20px'} />
                  ) : (
                    <p>{companyByInn.fss}</p>
                  )}
                </CopyBlock>
              </div>
            </BusinessData>

            {statusPersons === REQUEST_STATUSES.REQUEST && (
              <PersonsContainer>
                <ApplicationTitle>
                  {persons.length === 1 ? 'Руководитель' : 'Руководители'}{' '}
                  {statusPersons === REQUEST_STATUSES.REQUEST ? (
                    <SkeletonWrapper width={'175px'} height={'20px'} />
                  ) : companyByInn.companyShortName ? (
                    companyByInn.companyShortName
                  ) : (
                    companyByInn.companyFullName
                  )}
                </ApplicationTitle>

                <div>
                  <PersonInfoContainer>
                    <div>
                      <FontAwesomeIcon icon={faUserCircle} />
                      <div>
                        {statusPersons === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <p />
                        )}
                        {statusPersons === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <p />
                        )}
                        {statusPersons === REQUEST_STATUSES.REQUEST ? (
                          <SkeletonWrapper width={'175px'} height={'20px'} />
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </PersonInfoContainer>
                </div>
              </PersonsContainer>
            )}

            {!!persons.length && (
              <PersonsContainer>
                <ApplicationTitle>
                  {persons.length === 1 ? 'Руководитель' : 'Руководители'}{' '}
                  {companyByInn.companyShortName
                    ? companyByInn.companyShortName
                    : companyByInn.companyFullName}
                </ApplicationTitle>

                <div>
                  {persons.map((person, index) => (
                    <PersonInfoContainer
                      key={index}
                      itemScope
                      itemType="http://schema.org/Person"
                    >
                      <div>
                        <FontAwesomeIcon icon={faUserCircle} />
                        <PersonInfoDirector>
                          <p itemProp="jobTitle">
                            {person.position.charAt(0).toUpperCase() +
                              person.position.slice(1).toLowerCase()}
                          </p>
                          <PersonName
                            onClick={() => navigateToPerson(person.personInn)}
                          >
                            <p itemProp="familyName">
                              {person.lastName
                                .toLowerCase()
                                .split(' ')
                                .map(
                                  word =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(' ')}
                            </p>
                            <p itemProp="givenName">
                              {person.firstName
                                .toLowerCase()
                                .split(' ')
                                .map(
                                  word =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(' ')}
                            </p>
                            {!!person.middleName.length && (
                              <p itemProp="additionalName">
                                {person.middleName
                                  .toLowerCase()
                                  .split(' ')
                                  .map(
                                    word =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(' ')}
                              </p>
                            )}
                          </PersonName>
                          <div>
                            <p>ИНН</p>
                            <CopyBlock
                              text={
                                person.personInn && !isCopied
                                  ? 'Скопировать'
                                  : isCopied && 'Скопировано!'
                              }
                              onClick={() => handleCopyClick(person.personInn)}
                              onMouseLeave={() => setIsCopied(false)}
                            >
                              <p itemProp="taxID">{person.personInn}</p>
                            </CopyBlock>
                          </div>
                        </PersonInfoDirector>
                      </div>
                    </PersonInfoContainer>
                  ))}
                </div>
              </PersonsContainer>
            )}

            {!!beneficiaries.length && (
              <PersonsContainer>
                <ApplicationTitle>
                  {beneficiaries.length === 1 ? 'Учредитель' : 'Учредители'}{' '}
                  {companyByInn.companyShortName
                    ? companyByInn.companyShortName
                    : companyByInn.companyFullName}
                </ApplicationTitle>

                <div>
                  {beneficiaries.filter(obj => Object.keys(obj).length > 1).map(
                    (beneficiar, index) =>
                      beneficiar.beneficiaryType === 1 ? (
                        <PersonInfoContainer
                          key={index}
                          itemScope
                          itemType="http://schema.org/Person"
                        >
                          <div>
                            {statusConfirmedCompanyFounders ===
                            REQUEST_STATUSES.REQUEST ? (
                              <Spinner />
                            ) : (
                              <FontAwesomeIcon icon={faUserCircle} />
                            )}

                            <PersonInfo>
                              <div>
                                <div>
                                  <p>Доля в УК:</p>
                                  {statusConfirmedCompanyFounders ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'175px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    <p>
                                      {beneficiar.ownershipShareDeJure
                                        ? `${beneficiar.ownershipShareDeJure}%`
                                        : '-'}
                                    </p>
                                  )}
                                </div>
                                {statusConfirmedCompanyFounders ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'175px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  <div>
                                    <p>
                                      {beneficiar.capitalShare
                                        ? `${formSumStringThousands(
                                            beneficiar.capitalShare
                                          )} руб.`
                                        : '-'}
                                    </p>
                                  </div>
                                )}
                              </div>
                              {statusConfirmedCompanyFounders ===
                              REQUEST_STATUSES.REQUEST ? (
                                <SkeletonWrapper
                                  width={'175px'}
                                  height={'20px'}
                                />
                              ) : (
                                <PersonName
                                  onClick={() =>
                                    navigateToPerson(beneficiar.personInn)
                                  }
                                >
                                  <p itemProp="familyName">
                                    {beneficiar.lastName
                                      .toLowerCase()
                                      .split(' ')
                                      .map(
                                        word =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')}
                                  </p>
                                  <p itemProp="givenName">
                                    {beneficiar.firstName
                                      .toLowerCase()
                                      .split(' ')
                                      .map(
                                        word =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(' ')}
                                  </p>
                                  {!!beneficiar.middleName.length && (
                                    <p itemProp="additionalName">
                                      {beneficiar.middleName
                                        .toLowerCase()
                                        .split(' ')
                                        .map(
                                          word =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(' ')}
                                    </p>
                                  )}
                                </PersonName>
                              )}
                              <div>
                                <p>ИНН</p>
                                <CopyBlock
                                  text={
                                    beneficiar.personInn && !isCopied
                                      ? 'Скопировать'
                                      : isCopied && 'Скопировано!'
                                  }
                                  onClick={() =>
                                    handleCopyClick(beneficiar.personInn)
                                  }
                                  onMouseLeave={() => setIsCopied(false)}
                                >
                                  <p itemProp="taxID">{beneficiar.personInn}</p>
                                </CopyBlock>
                              </div>
                            </PersonInfo>
                          </div>
                        </PersonInfoContainer>
                      ) : (
                        beneficiar.beneficiaryType === 2 && (
                          <PersonInfoContainer
                            key={index}
                            itemScope
                            itemType="http://schema.org/ParentOrganization"
                          >
                            <div>
                              {statusConfirmedCompanyFounders ===
                              REQUEST_STATUSES.REQUEST ? (
                                <Spinner />
                              ) : isAuthorized ? (
                                <img
                                  src={beneficiariesIcon[index]}
                                  alt={beneficiar.okvedName}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faBriefcase} />
                              )}

                              <PersonInfo>
                                <div>
                                  <div>
                                    <p>Доля в УК:</p>
                                    {statusConfirmedCompanyFounders ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'175px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      <p>
                                        {beneficiar.ownershipShareDeJure
                                          ? `${
                                              beneficiar.ownershipShareDeJure
                                            }%`
                                          : '-'}
                                      </p>
                                    )}
                                  </div>
                                  {statusConfirmedCompanyFounders ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'175px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    <div>
                                      <p>
                                        {beneficiar.capitalShare
                                          ? `${formSumStringThousands(
                                              beneficiar.capitalShare
                                            )} руб.`
                                          : '-'}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {statusConfirmedCompanyFounders ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'175px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  <PersonName>
                                    <p
                                      itemProp="legalName"
                                      onClick={() =>
                                        navigateToBusiness(
                                          beneficiar.headCompanyInn
                                        )
                                      }
                                    >
                                      {beneficiar.companyShortName
                                        ? beneficiar.companyShortName
                                        : beneficiar.companyFullName}
                                    </p>
                                  </PersonName>
                                )}
                                <div>
                                  <p>ИНН</p>
                                  <CopyBlock
                                    text={
                                      beneficiar.headCompanyInn && !isCopied
                                        ? 'Скопировать'
                                        : isCopied && 'Скопировано!'
                                    }
                                    onClick={() =>
                                      handleCopyClick(beneficiar.headCompanyInn)
                                    }
                                    onMouseLeave={() => setIsCopied(false)}
                                  >
                                    <p itemProp="taxID">
                                      {beneficiar.headCompanyInn}
                                    </p>
                                  </CopyBlock>
                                </div>
                              </PersonInfo>
                            </div>
                          </PersonInfoContainer>
                        )
                      )
                  )}
                </div>
              </PersonsContainer>
            )}

            {!!subsidiaries.length && (
              <PersonsContainer>
                <ApplicationTitle>
                  {subsidiaries.length === 1
                    ? 'Дочерняя компания'
                    : 'Дочерние компании'}{' '}
                  {companyByInn.companyShortName
                    ? companyByInn.companyShortName
                    : companyByInn.companyFullName}
                </ApplicationTitle>

                <div>
                  {subsidiaries
                    .filter(obj => Object.keys(obj).length > 1)
                    .map((user, index) => (
                      <PersonInfoContainer key={index}>
                        <div>
                          {statusConfirmedCompanySubsidiaries ===
                          REQUEST_STATUSES.REQUEST ? (
                            <Spinner />
                          ) : isAuthorized ? (
                            <img
                              src={subsidiariesIcon[index]}
                              alt={user.okvedName}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faBriefcase} />
                          )}

                          <PersonInfo>
                            <div>
                              <div>
                                <p>Доля в УК:</p>
                                {statusConfirmedCompanySubsidiaries ===
                                REQUEST_STATUSES.REQUEST ? (
                                  <SkeletonWrapper
                                    width={'30px'}
                                    height={'20px'}
                                  />
                                ) : (
                                  <p>
                                    {user.ownershipShareDeJure
                                      ? `${user.ownershipShareDeJure}%`
                                      : '-'}
                                  </p>
                                )}
                              </div>
                              {statusConfirmedCompanySubsidiaries ===
                              REQUEST_STATUSES.REQUEST ? (
                                <SkeletonWrapper
                                  width={'30px'}
                                  height={'20px'}
                                />
                              ) : (
                                <div>
                                  <p>
                                    {user.capitalShare
                                      ? `${formSumStringThousands(
                                          user.capitalShare
                                        )} руб.`
                                      : '-'}
                                  </p>
                                </div>
                              )}
                            </div>
                            <PersonName>
                              <p
                                onClick={() =>
                                  navigateToBusiness(user.companyInn)
                                }
                              >
                                {user.companyShortName
                                  ? user.companyShortName
                                  : user.companyFullName}
                              </p>
                            </PersonName>
                            <div>
                              <p>ИНН</p>
                              <CopyBlock
                                text={
                                  user.headCompanyInn && !isCopied
                                    ? 'Скопировать'
                                    : isCopied && 'Скопировано!'
                                }
                                onClick={() =>
                                  handleCopyClick(user.headCompanyInn)
                                }
                                onMouseLeave={() => setIsCopied(false)}
                              >
                                <p>{user.headCompanyInn}</p>
                              </CopyBlock>
                            </div>
                          </PersonInfo>
                        </div>
                      </PersonInfoContainer>
                    ))}
                </div>
              </PersonsContainer>
            )}

            {companyByInn.taxes &&
              companyByInn.taxes.taxYear &&
              companyByInn.taxes.taxInfo.length > 0 && (
                <TaxesInfoContainer>
                  <ApplicationTitle>
                    Информация об уплаченных налогах:
                  </ApplicationTitle>

                  <p>За {companyByInn.taxes.taxYear} год:</p>
                  <div>
                    {companyByInn.taxes.taxInfo.map((tax, index) => (
                      <div key={index}>
                        <p>{formSumStringThousands(tax.taxAmount)}</p>
                        <p>{tax.taxName}</p>
                      </div>
                    ))}
                  </div>
                </TaxesInfoContainer>
              )}

            {companyByInn.employees !== null &&
              companyByInn.employeesYear !== null &&
              companyByInn.employees !== 0 &&
              companyByInn.employeesYear !== 0 && (
                <p>
                  Среднесписочная численность за {companyByInn.employeesYear} г:{' '}
                  {formSumStringThousands(companyByInn.employees)} чел.
                </p>
              )}

            {!!financialStatementsAll.items.length && (
              <BalanceWrapper>
                <ApplicationTitle>Баланс</ApplicationTitle>

                <BalanceContainer>
                  <TableContainer>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>
                          Наименование показателя
                        </TableHeaderCell>
                        <TableHeaderCell>Код</TableHeaderCell>
                        {!!years &&
                          years.map((item, index) => (
                            <TableHeaderCell key={index}>
                              {item.year},{' '}
                              {getTaxPeriodCode(item.taxPeriodCode)} кв
                            </TableHeaderCell>
                          ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {emptyFieldActive.map(({ name, code }) => (
                        <TableRow key={code}>
                          <TableCell is1600={code === 1600}>
                            {code === activeMatchingCode.code && (
                              <div>
                                <h3>АКТИВ</h3>
                                <p>I. ВНЕОБОРОТНЫЕ АКТИВЫ</p>
                              </div>
                            )}
                            {code === currentAssetsMatchingCode.code && (
                              <div>
                                <p>II. ОБОРОТНЫЕ АКТИВЫ</p>
                              </div>
                            )}
                            {name}
                          </TableCell>
                          <TableCell>{code}</TableCell>
                          {filtredFinancialStatementsAll.map(item => (
                            <TableCell
                              key={`${code}-${item.year}-${item.taxPeriodCode}`}
                              is1100={code === 1100}
                              is1200={code === 1200}
                              is1600={code === 1600}
                            >
                              {item.active[`row${code}`]
                                ? formSumStringThousands(
                                    item.active[`row${code}`]
                                  )
                                : ''}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableContainer>

                  <TableContainer>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell>
                          Наименование показателя
                        </TableHeaderCell>
                        <TableHeaderCell>Код</TableHeaderCell>
                        {!!years &&
                          years.map((item, index) => (
                            <TableHeaderCell key={index}>
                              {item.year},{' '}
                              {getTaxPeriodCode(item.taxPeriodCode)} кв
                            </TableHeaderCell>
                          ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {emptyFieldPassive.map(({ name, code }) => (
                        <TableRow key={code}>
                          <TableCell is1700={code === 1700}>
                            {code === passiveMatchingCode.code && (
                              <div>
                                <h3>ПАССИВ</h3>
                                <p>III. КАПИТАЛ И РЕЗЕРВЫ</p>
                              </div>
                            )}
                            {code === longTermObligationsMatchingCode.code && (
                              <div>
                                <p>IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                              </div>
                            )}
                            {code === shortTermLiabilitiesMatchingCode.code && (
                              <div>
                                <p>V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА</p>
                              </div>
                            )}
                            {name}
                          </TableCell>
                          <TableCell>{code}</TableCell>
                          {filtredFinancialStatementsAll.map(item => (
                            <TableCell
                              key={`${code}-${item.year}-${item.taxPeriodCode}`}
                              is1300={code === 1300}
                              is1400={code === 1400}
                              is1500={code === 1500}
                              is1700={code === 1700}
                            >
                              {item.passive[`row${code}`]
                                ? formSumStringThousands(
                                    item.passive[`row${code}`]
                                  )
                                : ''}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableContainer>

                  <section>
                    <CompanySectionTitle>ОПиУ</CompanySectionTitle>
                    <TableContainer>
                      <TableHeader>
                        <TableRow>
                          <TableHeaderCell>
                            Наименование показателя
                          </TableHeaderCell>
                          <TableHeaderCell>Код</TableHeaderCell>
                          {!!years &&
                            years.map((item, index) => (
                              <TableHeaderCell key={index}>
                                {item.year},{' '}
                                {getTaxPeriodCode(item.taxPeriodCode)} кв
                              </TableHeaderCell>
                            ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {emptyFieldOPIY.map(({ name, code }) => (
                          <TableRow key={code}>
                            <TableCell>{name}</TableCell>
                            <TableCell>{code}</TableCell>
                            {filtredFinancialStatementsAll.map(item => (
                              <TableCell
                                key={`${code}-${item.year}-${
                                  item.taxPeriodCode
                                }`}
                              >
                                {item.OPIY[`row${code}`]
                                  ? formSumStringThousands(
                                      item.OPIY[`row${code}`]
                                    )
                                  : ''}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </TableContainer>
                  </section>
                </BalanceContainer>
              </BalanceWrapper>
            )}

            {isAuthorized && (
              <Image src={companyIcon} alt={companyByInn.okvedName} />
            )}

            <NotificationContainer>
              {!isVisibleNotification.includes(1) &&
                (companyByInn.debtor && !isAuthorized ? (
                  <Notification>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleSetIsVisibleNotification(1)}
                    />
                    <div>
                      <FontAwesomeIcon icon={faGem} />
                      <p>Особенный заказчик</p>
                    </div>
                    <p>
                      При работе с заказчиком вам может быть доступно
                      финансирование по факторингу. Для подачи заявки{' '}
                      <span onClick={navigateToLogin}>войдите</span> или{' '}
                      <span onClick={navigateToRegister}>
                        зарегистрируйтесь
                      </span>
                      на платформе.
                    </p>
                  </Notification>
                ) : (
                  companyByInn.debtor &&
                  isAuthorized &&
                  (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) ||
                    permissions.includes(USER_PERMISSIONS.CLIENT) ||
                    permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) && (
                    <Notification>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleSetIsVisibleNotification(1)}
                      />
                      <div>
                        <FontAwesomeIcon icon={faGem} />
                        <p>Особенный заказчик</p>
                      </div>
                      <p>
                        При работе с заказчиком вам может быть доступно
                        финансирование по факторингу. Для подачи заявки{' '}
                        <span onClick={navigateToInitializeCabinet}>
                          перейдите в раздел Кабинет
                        </span>
                        .
                      </p>
                    </Notification>
                  )
                ))}

              {!isVisibleNotification.includes(2) &&
                (companyByInn.customer223 && !isAuthorized ? (
                  <Notification>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleSetIsVisibleNotification(2)}
                    />
                    <div>
                      <FontAwesomeIcon icon={faGavel} />
                      <p>Заказчик по 223-ФЗ</p>
                    </div>
                    <p>
                      При работе с заказчиком вам могут быть доступны банковские
                      гарантии и финансирование на индивидуальных условиях. Для
                      подачи заявки{' '}
                      <span onClick={navigateToLogin}>войдите</span> или{' '}
                      <span onClick={navigateToRegister}>
                        зарегистрируйтесь
                      </span>{' '}
                      на платформе.
                    </p>
                  </Notification>
                ) : (
                  companyByInn.customer223 &&
                  isAuthorized &&
                  (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) ||
                    permissions.includes(USER_PERMISSIONS.CLIENT) ||
                    permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) && (
                    <Notification>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleSetIsVisibleNotification(2)}
                      />
                      <div>
                        <FontAwesomeIcon icon={faGavel} />
                        <p>Заказчик по 223-ФЗ</p>
                      </div>
                      <p>
                        При работе с заказчиком вам могут быть доступны{' '}
                        <span onClick={navigateToGuarantees}>
                          банковские гарантии
                        </span>{' '}
                        и{' '}
                        <span onClick={navigateToFinancing}>
                          финансирование
                        </span>{' '}
                        на индивидуальных условиях. Для подачи заявки{' '}
                        <span onClick={navigateToCabinet}>
                          перейдите в раздел Кабинет
                        </span>
                        .
                      </p>
                    </Notification>
                  )
                ))}

              {!isVisibleNotification.includes(3) &&
                (companyByInn.customer44 && !isAuthorized ? (
                  <Notification>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleSetIsVisibleNotification(3)}
                    />
                    <div>
                      <FontAwesomeIcon icon={faGavel} />
                      <p>Заказчик по 44-ФЗ</p>
                    </div>
                    <p>
                      При работе с заказчиком вам могут быть доступны банковские
                      гарантии и финансирование на индивидуальных условиях. Для
                      подачи заявки{' '}
                      <span onClick={navigateToLogin}>войдите</span> или{' '}
                      <span onClick={navigateToRegister}>
                        зарегистрируйтесь
                      </span>{' '}
                      на платформе.
                    </p>
                  </Notification>
                ) : (
                  companyByInn.customer44 &&
                  isAuthorized &&
                  (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) ||
                    permissions.includes(USER_PERMISSIONS.CLIENT) ||
                    permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) && (
                    <Notification>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleSetIsVisibleNotification(3)}
                      />
                      <div>
                        <FontAwesomeIcon icon={faGavel} />
                        <p>Заказчик по 44-ФЗ</p>
                      </div>
                      <p>
                        При работе с заказчиком вам могут быть доступны{' '}
                        <span onClick={navigateToGuarantees}>
                          банковские гарантии
                        </span>{' '}
                        и{' '}
                        <span onClick={navigateToFinancing}>
                          финансирование
                        </span>{' '}
                        на индивидуальных условиях. Для подачи заявки{' '}
                        <span onClick={navigateToCabinet}>
                          перейдите в раздел Кабинет
                        </span>
                        .
                      </p>
                    </Notification>
                  )
                ))}

              {!isVisibleNotification.includes(4) &&
                (companyByInn.limits && !isAuthorized ? (
                  <Notification>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleSetIsVisibleNotification(4)}
                    />
                    <div>
                      <FontAwesomeIcon icon={faSlidersH} />
                      <p>Ваша компания?</p>
                    </div>
                    <p>
                      На платформе вам может быть доступно
                      экспресс-финансирование на индивидуальных условиях. Для
                      подачи заявки{' '}
                      <span onClick={navigateToLogin}>войдите</span> или
                      <span onClick={navigateToRegister}>
                        зарегистрируйтесь
                      </span>{' '}
                      на платформе.
                    </p>
                  </Notification>
                ) : (
                  companyByInn.limits &&
                  isAuthorized &&
                  (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) ||
                    permissions.includes(USER_PERMISSIONS.CLIENT) ||
                    permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) && (
                    <Notification>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleSetIsVisibleNotification(4)}
                      />
                      <div>
                        <FontAwesomeIcon icon={faSlidersH} />
                        <p>Ваша компания?</p>
                      </div>
                      <p>
                        На платформе вам может быть доступно
                        экспресс-финансирование на индивидуальных условиях. Для
                        подачи заявки{' '}
                        <span onClick={navigateToCabinet}>
                          перейдите в раздел Кабинет
                        </span>
                        .
                      </p>
                    </Notification>
                  )
                ))}
            </NotificationContainer>

            <YandexMetrica counterId={95566938} />
          </>
        )}
      </MainSection>

      {error &&
        error.code === 404 && (
          <NotFound>
            <h2>КОМПАНИЯ НЕ НАЙДЕНА</h2>
          </NotFound>
        )}
    </ApplicationView>
  );
};

const mapStateToProps = ({ User, SCF, Cached, Business }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  isAuthorized: Cached.authorized,
  permissions: User.getUserData.data.permissions,
  secondCompanyByInn: SCF.getSecondCompanyByInnThirdParty.data,
  secondStatus: SCF.getSecondCompanyByInnThirdParty.status,
  persons: Business.getPersonsOfCompanies.data,
  statusPersons: Business.getPersonsOfCompanies.status,
  beneficiaries: Business.getBenificiariesOfCompanies.data,
  statusBeneficiaries: Business.getBenificiariesOfCompanies.status,
  errorBeneficiarieserrorPersons: Business.getBenificiariesOfCompanies.error,
  subsidiaries: Business.getSubsidiariesOfCompanies.data,
  statusSubsidiaries: Business.getSubsidiariesOfCompanies.status,
  errorSubsidiaries: Business.getSubsidiariesOfCompanies.error,
  confirmedCompanyFounders: Business.getCompanyByInnConfirmedFounders.data,
  statusConfirmedCompanyFounders:
    Business.getCompanyByInnConfirmedFounders.status,
  confirmedCompanySubsidiaries:
    Business.getCompanyByInnConfirmedSubsidiaries.data,
  statusConfirmedCompanySubsidiaries:
    Business.getCompanyByInnConfirmedSubsidiaries.status,
  companyFastInfo: SCF.getCompanyFastInfo.data,
  statusCompanyFastInfo: SCF.getCompanyFastInfo.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      resetStateCompanyByInn,
      getSecondCompanyByInn,
      getPersonsOfCompanies,
      getBeneficiariesOfCompanies,
      getSubsidiariesOfCompanies,
      getCompanyByInnConfirmedFounders,
      getCompanyByInnConfirmedSubsidiaries,
      getFinancialStatementsAll,
      getCompanyFastInfo
    },
    dispatch
  );

const BusinessViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessView)
);

export { BusinessViewConnect as BusinessView };
