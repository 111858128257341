import styled from 'styled-components';
import { BoxStyled } from 'shared/styled';

export const TitleStyled = styled.h2`
  margin-bottom: 32px;
`;

export const LoginBox = styled(BoxStyled)`
  width: 490px;
`;
