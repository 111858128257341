import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getOneFactorRoleByGuid';
import { GET_ONE_FACTOR_ROLE_BY_GUID } from 'entities/SCF/Factor/model/reducers/getOneFactorRoleByGuid';
import SCFApi from 'entities/SCF/Factor/api';

function* getOneFactorRoleByGuid(action: {
  type: GET_ONE_FACTOR_ROLE_BY_GUID;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getOneFactorRoleByGuid, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getOneFactorRoleByGuidSaga() {
  yield takeLatest(
    GET_ONE_FACTOR_ROLE_BY_GUID.GET_ONE_FACTOR_ROLE_BY_GUID_REQ,
    getOneFactorRoleByGuid
  );
}
