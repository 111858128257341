import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/approveEarlyPaymentApplicationDebtorRole';
import {
  APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';

import SCFDebtorApi from 'entities/SCF/Debtor/api';
import { STORE } from 'src/globaltypes';

function* handleRequest(guid: string, comment: string) {
  try {
    yield call(SCFDebtorApi.approveEarlyPaymentApplicationDebtorRole, {
      guid,
      body: { debtorInspectorComment: comment }
    });

    const currentResults: { success: boolean }[] = yield select(
      ({ SCFDebtor }: STORE) =>
        SCFDebtor.approveEarlyPaymentApplicationDebtorRole.data
    );

    yield put(got([...currentResults, { success: true }]));
  } catch (e) {
    const currentResults: { success: boolean }[] = yield select(
      ({ SCFDebtor }: STORE) =>
        SCFDebtor.approveEarlyPaymentApplicationDebtorRole.data
    );
    yield put(got([...currentResults, { success: false }]));
  }
}

function* approveEarlyPaymentApplicationDebtorRole(action: {
  type: APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE;
  data: RequestDataType;
  reqType?: 'array' | null;
}) {
  try {
    if (action.reqType === 'array' && Array.isArray(action.data.guid)) {
      for (const guid of action.data.guid) {
        yield fork(
          handleRequest,
          guid,
          action.data.body.debtorInspectorComment
        );
      }
    } else {
      yield call(
        SCFDebtorApi.approveEarlyPaymentApplicationDebtorRole,
        action.data
      );
      yield put(got());
    }
  } catch (e) {
    yield put(err(e));
  }
}

export default function* approveEarlyPaymentApplicationDebtorRoleSaga() {
  yield takeLatest(
    APPROVE_EARLY_PAYMENT_APPLICATION_DEBTOR_ROLE.REQ,
    approveEarlyPaymentApplicationDebtorRole
  );
}
