import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostAgreementSignatureFactorRoleStoreState = Reducer;

export enum POST_AGREEMENT_SIGNATURE_FACTOR_ROLE {
  REQ = 'POST_AGREEMENT_SIGNATURE_FACTOR_ROLE_REQ',
  GOT = 'POST_AGREEMENT_SIGNATURE_FACTOR_ROLE_GOT',
  ERR = 'POST_AGREEMENT_SIGNATURE_FACTOR_ROLE_ERR'
}

export interface RequestDataType {
  guid: string;
  signature: string;
  serial: string;
}

export const initialState: PostAgreementSignatureFactorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAgreementSignatureFactorRole = (
  state: PostAgreementSignatureFactorRoleStoreState = initialState,
  action: {
    type: POST_AGREEMENT_SIGNATURE_FACTOR_ROLE;
    error: {};
  }
): PostAgreementSignatureFactorRoleStoreState => {
  switch (action.type) {
    case POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAgreementSignatureFactorRole;
