import { combineReducers } from 'redux';
import getFinancialStatements, {
  GetFinancialStatementsStoreState
} from './getFinancialStatements';
import getFinancialStatementsAll, {
  GetFinancialStatementsAllStoreState
} from './getFinancialStatementsAll';
import getNewFinancialStatementsAll, {
  GetNewFinancialStatementsAllStoreState
} from './getNewFinancialStatements';
import postFinancialStatementsAll, {
  PostFinancialStatementsAllStoreState
} from './postFinancialStatementsAll';
import getExternalAgentPeriodList, {
  GetExternalAgentPeriodListStoreState
} from './getExternalAgentPeriodList';
import getExternalAgentSelectPeriod, {
  GetExternalAgentSelectPeriodStoreState
} from './getExternalAgentSelectPeriod';
import postExternalAgentFinancialStatementsAll, {
  PostExternalAgentFinancialStatementsAllStoreState
} from './postExternalAgentFinancialStatementsAll';
import getFinancialQuality, {
  GetFinancialQualityStoreState
} from './getFinancialQuality';

export interface FinancialStatementsReducers {
  getFinancialStatements: GetFinancialStatementsStoreState;
  getFinancialStatementsAll: GetFinancialStatementsAllStoreState;
  getNewFinancialStatementsAll: GetNewFinancialStatementsAllStoreState;
  postFinancialStatementsAll: PostFinancialStatementsAllStoreState;
  getExternalAgentPeriodList: GetExternalAgentPeriodListStoreState;
  getExternalAgentSelectPeriod: GetExternalAgentSelectPeriodStoreState;
  postExternalAgentFinancialStatementsAll: PostExternalAgentFinancialStatementsAllStoreState;
  getFinancialQuality: GetFinancialQualityStoreState;
}

export const FinancialStatements = combineReducers({
  getFinancialStatements,
  getFinancialStatementsAll,
  getNewFinancialStatementsAll,
  postFinancialStatementsAll,
  getExternalAgentPeriodList,
  getExternalAgentSelectPeriod,
  postExternalAgentFinancialStatementsAll,
  getFinancialQuality
});
