import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/Cabinet/Client/model/actions/getCompaniesOfUser';

import { GET_COMPANIES_OF_USER } from 'entities/Cabinet/Client/model/reducers/getCompaniesOfUser';
import ClientRoleApi from 'entities/Cabinet/Client/api';

function* getCompaniesOfUserList(action: {
  type: GET_COMPANIES_OF_USER;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(ClientRoleApi.getCompaniesOfUser, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesOfUserListSaga(): Generator {
  yield takeLatest(GET_COMPANIES_OF_USER.REQ, getCompaniesOfUserList);
}
