import isDebtorFactorable, { IsDebtorFactorableStoreState } from "./isDebtorFactorable";

import { combineReducers } from "redux";

export interface FactoringCheckerReducers {
isDebtorFactorable: IsDebtorFactorableStoreState;

}

export const FactoringChecker  = combineReducers({
isDebtorFactorable,

});
