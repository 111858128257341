import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Factor/model/actions/getEarlyPaymentApplicationsFactorRole';

export interface GetEarlyPaymentApplicationsFactorRoleStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE {
  GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_REQ = 'GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_REQ',
  GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_GOT = 'GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_GOT',
  GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_ERR = 'GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_ERR'
}

export const initialState: GetEarlyPaymentApplicationsFactorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getEarlyPaymentApplicationsFactorRole = (
  state: GetEarlyPaymentApplicationsFactorRoleStoreState = initialState,
  action: {
    type: GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetEarlyPaymentApplicationsFactorRoleStoreState => {
  switch (action.type) {
    case GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE.GET_EARLY_PAYMENT_APPLICATIONS_FACTOR_ROLE_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getEarlyPaymentApplicationsFactorRole;
