import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as getApplicationsList,
  RequestDataType as ApplicationListRequestDataType,
  ResponseDataType as ApplicationsListResponseDataType
} from 'Application/actions/getApplicationsList';
import { set as setApplicationListType } from 'Application/actions/setApplicationListType';
import {
  APPLICATIONS_LIST_TYPE,
  RequestDataType as SetApplicationListTypeRequestDataType
} from 'Application/reducers/setApplicationListType';
import { InternalAgentsFilter } from 'Filters/InternalAgentsFilter/components/InternalAgentsFilter';
import { ScrollTopComponent } from 'Common';
import {
  Loader,
  Pagination,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import {
  ApplicationListEmpty,
  ApplicationsViewRenderer
} from 'Application/components';
import { StatusFilter } from 'Filters/StatusFilter/components/StatusFilter';
import { TypeFilter } from 'Filters/TypeFilter/components/TypeFilter';
import { FiltersStyled, Title } from './styles';
import { INNFilter } from 'src/features/Filters/INNFilter/components/INNFilter';

interface OwnProps {
  title?: string;
  description?: string;
  listType: APPLICATIONS_LIST_TYPE;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  applicationsList: ApplicationsListResponseDataType;
  isApplicationRequested: boolean;
  statusesToFilterBy: string[];
  typesToFilterBy: string[]; // TODO maybe encapsulate
  internalAgentsToFilterBy: number[];
  innToFilterBy: string;
}

interface DispatchToProps {
  getApplicationsList: (data: ApplicationListRequestDataType) => void;
  setApplicationListType: (data: SetApplicationListTypeRequestDataType) => void;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const ApplicationsList: React.FC<Props> = ({
  getApplicationsList,
  applicationsList,
  status,
  error,
  listType,
  statusesToFilterBy,
  typesToFilterBy,
  internalAgentsToFilterBy,
  innToFilterBy,
  isApplicationRequested,
  setApplicationListType,
  title,
  description,
  history,
  match
}) => {
  const initFetch = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    setApplicationListType({ listType });

    if (listType === APPLICATIONS_LIST_TYPE.INTEGRATION_APPLICATIONS) {
      getApplicationsList({
        page,
        pageSize: 20,
        statusesToFilterBy,
        typesToFilterBy,
        internalAgentsToFilterBy,
        companyInnToFilterBy: innToFilterBy ? innToFilterBy : undefined
      });
    } else {
      getApplicationsList({
        page,
        pageSize: 20,
        statusesToFilterBy,
        typesToFilterBy,
        internalAgentsToFilterBy,
        innToFilterBy
      });
    }
  };

  React.useEffect(
    () => {
      initFetch();
    },
    [
      listType,
      statusesToFilterBy,
      typesToFilterBy,
      internalAgentsToFilterBy,
      innToFilterBy,
      isApplicationRequested,
      history.location.search
    ]
  );

  return (
    <React.Fragment>
      {title && <Title>{title}</Title>}
      {description && <p>{description}</p>}

      <FiltersStyled>
        {listType === APPLICATIONS_LIST_TYPE.INTEGRATION_APPLICATIONS ? null : (
          <StatusFilter listType={listType} />
        )}
        <TypeFilter />
        <INNFilter />
        {(listType === APPLICATIONS_LIST_TYPE.ALL_IN_WORK ||
          listType === APPLICATIONS_LIST_TYPE.ALL_DEALS ||
          listType === APPLICATIONS_LIST_TYPE.ALL_TRASHED) && (
          <InternalAgentsFilter />
        )}
      </FiltersStyled>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT &&
        (applicationsList.totalItems > 0 ? (
          <ScrollTopComponent>
            <ApplicationsViewRenderer
              listType={listType}
              applicationsList={applicationsList}
            />

            <Pagination list={applicationsList} />

            {applicationsList.items.length === 0 && (
              <Redirect to={`${match.url}?page=1`} />
            )}
          </ScrollTopComponent>
        ) : (
          <ApplicationListEmpty listType={listType} />
        ))}

      <ActionResponseBlock
        showIn={status === REQUEST_STATUSES.ERROR && error.code === 403}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({ Application, Filters }: STORE) => ({
  applicationsList: Application.getApplicationsList.data,
  status: Application.getApplicationsList.status,
  error: Application.getApplicationsList.error,
  isApplicationRequested:
    Application.getApplication.status === REQUEST_STATUSES.REQUEST,
  statusesToFilterBy: Filters.updateStatusesFilter.statuses,
  typesToFilterBy: Filters.updateTypeFilter.types,
  internalAgentsToFilterBy: Filters.updateInternalAgentsFilter.internalAgents,
  innToFilterBy: Filters.updateINNFilter.inn
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getApplicationsList, setApplicationListType }, dispatch);

const ApplicationsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationsList)
);

export { ApplicationsListConnect as ApplicationsList };
