import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { inRangeRule, validateINN } from 'src/shared/utils/ValidationRules';
import { ValidatorMessages } from 'shared/constants';
import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import {
  TENDER_TYPE,
  TAX_TYPE,
  GUARANTEES_TYPE
} from 'src/features/Application/types';

export const createValidaton = () =>
  new Validator<SetInitializationApplicationType>({
    clientCompanyName: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    code: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      },
      {
        rule: value => {
          if (value && value.length < 10) {
            return;
          }
          return validateINN(value);
        },
        message: ValidatorMessages.codeWarning
      }
    ],
    customerInn: [
      {
        rule: (value, formData) => {
          if (value && value.length < 10) {
            return;
          }
          return validateINN(value);
        },
        message: ValidatorMessages.codeWarning
      }
    ],
    amount: [
      {
        rule: inRangeRule,
        message: ValidatorMessages.invalidValue,
        ruleId: 'amountRange'
      }
    ],
    taxSystem: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    tenderLink: [
      {
        rule: (
          v: string,
          { guaranteesType }: { guaranteesType: GUARANTEES_TYPE }
        ) =>
          guaranteesType === GUARANTEES_TYPE['223-FZ'] ||
          guaranteesType === GUARANTEES_TYPE['44-FZ'] ||
          guaranteesType === GUARANTEES_TYPE['615-P']
            ? requiredRule(v)
            : true,
        message: ValidatorMessages.requiredField,
        ruleId: 'guaranteesType'
      }
    ],
    termEndDate: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    termStartDate: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    tender: [
      {
        rule: (
          _,
          {
            guaranteesType,
            tender
          }: {
            guaranteesType: GUARANTEES_TYPE;
            tender: TENDER_TYPE;
          }
        ) =>
          [
            GUARANTEES_TYPE['223-FZ'],
            GUARANTEES_TYPE['44-FZ'],
            GUARANTEES_TYPE['615-P']
          ].includes(guaranteesType) &&
          [
            TENDER_TYPE.OBLIGATIONS,
            TENDER_TYPE.ADVANCE_RETURNS,
            TENDER_TYPE.OTHER,
            TENDER_TYPE.PARTICIPATION,
            TENDER_TYPE.PAYMENT,
            TENDER_TYPE.QUALITY
          ].includes(tender)
            ? true
            : false,
        message: ValidatorMessages.tenderType,
        ruleId: 'guaranteesType'
      }
    ],
    commercial: [
      {
        rule: (
          _,
          {
            guaranteesType,
            commercial
          }: {
            guaranteesType: GUARANTEES_TYPE;
            commercial: TENDER_TYPE;
          }
        ) =>
          guaranteesType === GUARANTEES_TYPE['COMMERCIAL'] &&
          [
            TENDER_TYPE.OBLIGATIONS,
            TENDER_TYPE.ADVANCE_RETURNS,
            TENDER_TYPE.PARTICIPATION,
            TENDER_TYPE.QUALITY,
            TENDER_TYPE.PAYMENT,
            TENDER_TYPE.OTHER
          ].includes(commercial)
            ? true
            : false,
        message: ValidatorMessages.tenderType,
        ruleId: 'guaranteesType'
      }
    ],
    tax: [
      {
        rule: (
          _,
          {
            guaranteesType,
            tax
          }: {
            guaranteesType: GUARANTEES_TYPE;
            tax: TAX_TYPE;
          }
        ) =>
          guaranteesType === GUARANTEES_TYPE['OTHER'] &&
          [
            TAX_TYPE.CUSTOMS,
            TAX_TYPE.NDS,
            TAX_TYPE.OTHER,
            TAX_TYPE.REGULATION
          ].includes(tax)
            ? true
            : false,
        message: ValidatorMessages.tenderType,
        ruleId: 'guaranteesType'
      }
    ]
  });
