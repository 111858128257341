import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { STORE, REQUEST_STATUSES } from 'globaltypes';

import { req as checkStatus } from 'Business/actions/businessStatus';
import { ResponseData } from 'Business/reducers/businessStatus';
import { CabinetApproved } from 'Business/components';

import { ErrorIcon } from 'shared/styled';

interface StateToProps {
  requestStatus: REQUEST_STATUSES;
  businessStatus: ResponseData;
}

interface DispatchToProps {
  checkStatus: () => void;
}

type Props = StateToProps & DispatchToProps;

class BusinessCabinet extends React.Component<Props> {
  componentDidMount() {
    this.props.checkStatus();
  }

  renderCabinet = () => {
    switch (this.props.businessStatus.approved) {
      case true:
        return <CabinetApproved />;

      case false:
        return (
          <ActionResponseBlock
            title="Вы не подтвердили свой электронный адрес"
            text="Перейдите по ссылке в письме, чтобы подтвердить учетную запись"
          />
        );
      default:
        return (
          <ActionResponseBlock
            template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
          />
        );
    }
  };

  render() {
    const { requestStatus } = this.props;
    return (
      <React.Fragment>
        {requestStatus === REQUEST_STATUSES.REQUEST && <Loader />}
        {requestStatus === REQUEST_STATUSES.GOT && this.renderCabinet()}

        <ActionResponseBlock
          showIn={requestStatus === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ Business }: STORE) => ({
  requestStatus: Business.businessStatus.status,
  businessStatus: Business.businessStatus.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ checkStatus }, dispatch);

const BusinessCabinetConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessCabinet)
);

export { BusinessCabinetConnect as BusinessCabinet };
