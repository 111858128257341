import { combineReducers } from 'redux';
import getActivitiesOfCompanies, {
  GetActivitiesOfCompaniesStoreState
} from './getActivities';

export interface ActivitieOfCompaniesReducers {
  getActivitiesOfCompanies: GetActivitiesOfCompaniesStoreState;
}

export const ActivitiesOfCompanies = combineReducers({
  getActivitiesOfCompanies
});
