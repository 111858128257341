import { GET_PRODUCT_APPLICATION } from '../reducers/getProductApplication';

export interface ResponseData {
  apiComment: string;
  bankName: string;
  complexity: string;
  hasCashback: boolean;
  hasContract: boolean;
  id: number;
  maxRate: number;
  meanRate: number;
  minRate: number;
  offerActualTill: null;
  offerRate: null;
  offerSize: null;
  offerTime: null;
  productId: number;
  productName: string;
  rating: number;
  rejectReason: null;
  rejectSubject: null;
  riskAppetite: string;
  sentToBank: boolean;
  status: number;
}

export const req = (id: string) => ({
  type: GET_PRODUCT_APPLICATION.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCT_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCT_APPLICATION.ERR,
  error
});
