import ApiRequest from 'src/shared/utils/ApiRequest';

export default class AgentApi {
  static getAllAgent(filters?: any) {
    return ApiRequest(`/api/agent/all`, 'get', filters);
  }

  static sendAgentData(data: any) {
    return ApiRequest('/api/agent', 'post', data);
  }
}
