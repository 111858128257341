import patchCustomerRequirementsSaga from './patchCustomerRequirements';
import patchSetForbiddenRegionSaga from './patchSetForbiddenRegion';
import patchSetAvailableRegionSaga from './patchSetAvailableRegion';
import patchCabinetCustomerRequirementsSaga from './patchCabinetCustomerRequirements';
import patchCabinetSetAvailableRegionSaga from './patchCabinetSetAvailableRegion';
import patchCabinetSetForbiddenRegionSaga from './patchCabinetSetForbiddenRegion';
import patchCabinetSetAvailableRegionAllSaga from './patchCabinetSetAvailableRegionAll';
import patchCabinetSetForbiddenRegionAllSaga from './patchCabinetSetForbiddenRegionAll';
import patchSetAvailableRegionAllSaga from './patchSetAvailableRegionAll';
import patchSetForbiddenRegionAllSaga from './patchSetForbiddenRegionAll';

export default [
  patchCustomerRequirementsSaga,
  patchCabinetCustomerRequirementsSaga,
  patchSetForbiddenRegionSaga,
  patchSetAvailableRegionSaga,
  patchCabinetSetAvailableRegionSaga,
  patchCabinetSetForbiddenRegionSaga,
  patchCabinetSetAvailableRegionAllSaga,
  patchCabinetSetForbiddenRegionAllSaga,
  patchSetAvailableRegionAllSaga,
  patchSetForbiddenRegionAllSaga
];
