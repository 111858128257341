import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getProductApplication';

import { GET_PRODUCT_APPLICATION } from '../reducers/getProductApplication';
import ApplicationApi from '../api';

function* getProductApplication(action: {
  type: GET_PRODUCT_APPLICATION;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      ApplicationApi.getProductApplication,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductApplicationSaga(): Generator {
  yield takeLatest(GET_PRODUCT_APPLICATION.REQ, getProductApplication);
}
