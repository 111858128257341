import { REFRESH_API_KEY } from '../reducers/refreshApiKey';

export const req = () => ({
  type: REFRESH_API_KEY.REQ
});

export const got = (key: string ) => ({
  type: REFRESH_API_KEY.GOT,
  key
});

export const err = (error: {}) => ({
  type: REFRESH_API_KEY.ERR,
  error
});
