import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getInternalAgentsList } from 'InternalAgentsManager/actions/getInternalAgentsList';
import {
  req as delegateToInternalAgent,
  RequestData as delegateToInternalAgentRequestData
} from 'InternalAgentsManager/actions/delegateToInternalAgent';
import { Select } from 'shared/ui/Select';
import { DelegateInternalAgentToApplicationSelectStyled } from './styles';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { InternalAgent } from 'src/features/InternalAgentsManager/actions/getInternalAgentsList';

interface OwnProps {
  applicationId: number;
  label?: string;
  placeholder?: string;
  disableLabel?: boolean;
}

interface StateToProps {
  internalAgentsList: InternalAgent[];
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalAgentsList: () => void;
  delegateToInternalAgent: (data: delegateToInternalAgentRequestData) => void;
}

interface State {
  selectedId: string;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class DelegateInternalAgentToApplicationSelect extends React.Component<
  Props,
  State
> {
  componentWillMount() {
    if (
      this.props.status !== REQUEST_STATUSES.REQUEST &&
      this.props.status !== REQUEST_STATUSES.GOT
    )
      this.props.getInternalAgentsList();
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.props.delegateToInternalAgent({
      applicationId: this.props.applicationId,
      userId: Number(value)
    });
  };

  render() {
    const { disableLabel, label, placeholder } = this.props;
    return (
      <DelegateInternalAgentToApplicationSelectStyled
        onClick={e => e.stopPropagation()}
      >
        <Select
          options={this.props.internalAgentsList.map(internalAgent => ({
            id: internalAgent.id.toString(),
            name: internalAgent.name
          }))}
          value=""
          label={label || disableLabel ? label : 'Назначить внутреннего агента'}
          name="chooseInternalAgent"
          onChange={this.onChange}
          placeholder={
            placeholder ? placeholder : 'Выберите внутреннего агента'
          }
        />
      </DelegateInternalAgentToApplicationSelectStyled>
    );
  }
}

const mapStateToProps = ({ InternalAgentsManager }: STORE) => ({
  internalAgentsList: InternalAgentsManager.getInternalAgentsList.data,
  status: InternalAgentsManager.getInternalAgentsList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getInternalAgentsList, delegateToInternalAgent },
    dispatch
  );

const DelegateInternalAgentToApplicationSelectConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(DelegateInternalAgentToApplicationSelect);

export {
  DelegateInternalAgentToApplicationSelectConnect as DelegateInternalAgentToApplicationSelect
};
