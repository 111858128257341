import { POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL } from '../reducers/postExternalAgentFinancialStatementsAll';

export interface RequestType {
  companyInn: string;
  year: number;
  taxperiodcode: number;
}

export const req = (data: RequestType) => ({
  type: POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL.REQ,
  data
});

export const got = () => ({
  type: POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL.GOT
});

export const err = (error: {}) => ({
  type: POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL.ERR,
  error
});

export const reset = () => ({
  type: POST_EXTERNAL_AGENT_FINANCIAL_STATEMENTS_ALL.RESET
});
