import { GET_API_KEY_PUBLIC } from '../reducers/getApiKeyPublic';

export const req = () => ({
  type: GET_API_KEY_PUBLIC.REQ
});

export const got = (key: string) => ({
  type: GET_API_KEY_PUBLIC.GOT,
  key
});

export const err = (error: {}) => ({
  type: GET_API_KEY_PUBLIC.ERR,
  error
});
