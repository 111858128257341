import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getEarlyPaymentApplicationsDebtorRole';

export interface GetEarlyPaymentApplicationsDebtorRoleStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE {
  REQ = 'GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE_REQ',
  GOT = 'GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE_GOT',
  ERR = 'GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE_ERR',
  RESET = 'GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE_RESET'
}

export const initialState: GetEarlyPaymentApplicationsDebtorRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getEarlyPaymentApplicationsDebtorRole = (
  state: GetEarlyPaymentApplicationsDebtorRoleStoreState = initialState,
  action: {
    type: GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE;
    data: ResponseDataType;
    error: {};
  }
): GetEarlyPaymentApplicationsDebtorRoleStoreState => {
  switch (action.type) {
    case GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_EARLY_PAYMENT_APPLICATIONS_DEBTOR_ROLE.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default getEarlyPaymentApplicationsDebtorRole;
