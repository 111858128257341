import Validator from 'instant-validation';
import { requiredRule } from 'instant-validation/build/rules';
import { Document, PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE } from 'src/features/Application/types';
import { ValidatorMessages } from 'shared/constants';
import { SetPropertyFinancingFormData } from 'src/features/Application/reducers/setPropertyFinancingFormData';
import { PROPERTY_FINANCING_CLIENT_TYPE } from 'src/features/Application/types';
import { innValidationEntityRule, innValidationIndividualRule, innValidationRule } from 'src/shared/utils/ValidationRules';
import { findDocumentByMetaInfo } from 'src/shared/utils/Utils';

export const createValidaton = () =>
  new Validator<SetPropertyFinancingFormData>({
    clientType: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    client: [
      {
        rule: requiredRule,
        message: ValidatorMessages.requiredField
      }
    ],
    inn: [
      {
        rule: (value, clientType: PROPERTY_FINANCING_CLIENT_TYPE) => {
          if ([PROPERTY_FINANCING_CLIENT_TYPE.ENTITY,
          PROPERTY_FINANCING_CLIENT_TYPE.SOLE_PROPRIETOR].includes(clientType)) {
            if (clientType === PROPERTY_FINANCING_CLIENT_TYPE.ENTITY)
              return innValidationEntityRule(value)
            if (clientType === PROPERTY_FINANCING_CLIENT_TYPE.SOLE_PROPRIETOR)
              return innValidationIndividualRule(value);
          }
          return true;
        },
        message: ValidatorMessages.codeValid,
        ruleId: "clientType"
      }
    ],
    clientAverageMonthlyIncome: [
      {
        rule: (value, clientType: PROPERTY_FINANCING_CLIENT_TYPE) => {
          if (clientType !== PROPERTY_FINANCING_CLIENT_TYPE.ENTITY)
            return requiredRule(value);
          return true;
        },
        message: ValidatorMessages.requiredField,
        ruleId: "clientType"
      }
    ],
    clientIncomeConfirmationType: [
      {
        rule: (value, clientType: PROPERTY_FINANCING_CLIENT_TYPE) => {
          if (clientType !== PROPERTY_FINANCING_CLIENT_TYPE.ENTITY)
            return requiredRule(value);
          return true;
        },
        message: ValidatorMessages.requiredField,
        ruleId: "clientType"
      }
    ],
    propertyFinancingDocuments: [
      {
        rule: (documents: Document[], clientIncomeConfirmationType: PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE, clientType) => {
          if (documents.length > 0 && (!clientIncomeConfirmationType || clientIncomeConfirmationType.length === 0))
            return true;
          switch (clientIncomeConfirmationType) {
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.NO_CONFIRMATION:
              return true;
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.EMPLOYER_NOTE:
              return true;
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TWO_NDFL:
              return !!findDocumentByMetaInfo(documents, "two_ndfl");
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.THREE_NDFL:
              return !!findDocumentByMetaInfo(documents, "three_ndfl");
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.TURNOVER_51_REPORT:
              return !!findDocumentByMetaInfo(documents, "turnover");
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.BANK_CARD_REPORT:
              return !!findDocumentByMetaInfo(documents, "bank_card_report");
            case PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.EMPLOYER_NOTE:
              return !!findDocumentByMetaInfo(documents, "employer_note");
            default:
              return false
          }
        },
        message: ValidatorMessages.requiredField,
        ruleId: "clientIncomeConfirmationType",
      },
      {
        rule: (documents: Document[], clientType: PROPERTY_FINANCING_CLIENT_TYPE) => {
          if (clientType === PROPERTY_FINANCING_CLIENT_TYPE.ENTITY)
            return !!findDocumentByMetaInfo(documents, "turnover");
          return true;
        },
        ruleId: "clientType",
        message: ValidatorMessages.requiredField,
      }
    ]

  });
