import styled from 'styled-components';
import { rl, rr } from 'shared/styled';
import { NavigationLinkStyled } from 'src/features/Layouts/components/Sidebar/NavigationLink/styles';

export const BarLinks = styled.ul`
  padding: 0 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const BarSectionStyled = styled.nav`
  width: 263px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #252b40;

  & > ul:first-of-type {
    margin-top: 120px;
  }
`;

export const BarLink = styled.li`
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #656c82;
  }

  & > a {
    padding-left: calc(24px + 30px);
    font-size: 1.2em;

    &:hover {
      color: white;
    }

    & > svg {
      font-size: 18px;
    }
  }
`;

export const BarLinkText = styled.p`
  max-width: 140px;
  color: #656c82;
  font-family: ${rr};
  font-size: 1.2em;
  transition: 0.3s;
`;

export const BarTitleArea = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 20px;
  width: 220px;
  margin-left: 20px;
  border-bottom: 2px solid #656c82;
`;

export const BarTitle = styled.p`
  color: #656c82;
  font-family: ${rr};
  font-size: 1.2em;
`;

export const CounterBox = styled.span`
  position: absolute;
  right: 7px;
  font-family: ${rl};
  background-color: #e99f3b;
  padding: 9px 11px 8px 11px;
  color: black;
  border-radius: 6px;
`;

export const NavigationLink = styled(NavigationLinkStyled)`
  padding: 0 13px;

  ${CounterBox} {
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
  }
`;
