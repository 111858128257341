import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err, RequestData } from '../actions/getSupplierAuction';
import { GET_SUPPLIER_AUCTION } from '../reducers/getSuppluerAuction';
import SCFApi from '../api';

function* getSupplierAuction(action: {
  type: GET_SUPPLIER_AUCTION;
  guid: string;
  data: RequestData;
}) {
  try {
    const res: any = yield call(
      SCFApi.getSupplierAuction,
      action.guid,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplierAuctionSaga() {
  yield takeLatest(GET_SUPPLIER_AUCTION.REQ, getSupplierAuction);
}
