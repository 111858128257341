import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/SupplyContracts/actions/getSupplyContractsList';

export interface GetSupplyContractsListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_SUPPLY_CONTRACTS_LIST {
  REQ = 'GET_SUPPLY_CONTRACTS_LIST_REQ',
  GOT = 'GET_SUPPLY_CONTRACTS_LIST_GOT',
  ERR = 'GET_SUPPLY_CONTRACTS_LIST_ERR'
}

export const initialState: GetSupplyContractsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getSupplyContractsList = (
  state: GetSupplyContractsListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetSupplyContractsListStoreState => {
  switch (action.type) {
    case GET_SUPPLY_CONTRACTS_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUPPLY_CONTRACTS_LIST.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_SUPPLY_CONTRACTS_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSupplyContractsList;
