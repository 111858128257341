import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Activities/actions/getContactsOfActivity';

export interface GetContactsOfActivitiesStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_CONTACTS_OF_ACTIVITIES {
  REQ = 'GET_CONTACTS_OF_ACTIVITIES_REQ',
  GOT = 'GET_CONTACTS_OF_ACTIVITIES_GOT',
  ERR = 'GET_CONTACTS_OF_ACTIVITIES_ERR'
}

export const initialState: GetContactsOfActivitiesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getContactsOfActivities = (
  state: GetContactsOfActivitiesStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetContactsOfActivitiesStoreState => {
  switch (action.type) {
    case GET_CONTACTS_OF_ACTIVITIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTACTS_OF_ACTIVITIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_CONTACTS_OF_ACTIVITIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContactsOfActivities;
