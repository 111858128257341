import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Factor/model/actions/getFactorRegistryFiles';

export interface GetFactorRegistryFilesStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_FACTOR_REGISTRY_FILES {
  GET_FACTOR_REGISTRY_FILES_REQ = 'GET_FACTOR_REGISTRY_FILES_REQ',
  GET_FACTOR_REGISTRY_FILES_GOT = 'GET_FACTOR_REGISTRY_FILES_GOT',
  GET_FACTOR_REGISTRY_FILES_ERR = 'GET_FACTOR_REGISTRY_FILES_ERR'
}

export const initialState: GetFactorRegistryFilesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getFactorRegistryFiles = (
  state: GetFactorRegistryFilesStoreState = initialState,
  action: {
    type: GET_FACTOR_REGISTRY_FILES;
    data: ResponseDataType;
    error: {};
  }
): GetFactorRegistryFilesStoreState => {
  switch (action.type) {
    case GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactorRegistryFiles;
