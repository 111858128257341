import { combineReducers } from 'redux';
import getUserDebtorList, {
  GetUserDebtorListStoreState
} from 'src/features/UserDebtors/reducers/getUserDebtorList';

export interface UserDebtorsReducers {
  getUserDebtorList: GetUserDebtorListStoreState;
}

export const UserDebtors = combineReducers({ getUserDebtorList });
