import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE, USER_PERMISSIONS } from 'globaltypes';

import { req as changeApplicationStatus } from 'Application/actions/changeApplicationStatus';
import { RequestDataType as ChangeApplicationStatusStoreState } from 'Application/reducers/changeApplicationStatus';

import { ApplicationStatuses } from 'shared/constants';
import { APPLICATION_STATUSES, APPLICATION_TYPES } from 'Application/types';

import {
  ApplicationStatusStyled,
  EditApplicationBtn,
  TransferApplicationBtn
} from './styles';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { DelegateInternalAgentToApplicationSelect } from '../../../ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { RouteComponentProps, withRouter } from 'react-router';

interface OwnProps {
  id: number;
  status: APPLICATION_STATUSES;
  onlyStatus?: boolean;
  className?: string;
  listType: APPLICATIONS_LIST_TYPE;
  financingType: APPLICATION_TYPES;
}

interface StateToProps {
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  changeApplicationStatus: (data: ChangeApplicationStatusStoreState) => void;
}

type Props = RouteComponentProps & OwnProps & StateToProps & DispatchToProps;

const ApplicationStatus: React.FC<Props> = ({
  id,
  status,
  onlyStatus = false,
  listType,
  permissions,
  changeApplicationStatus,
  financingType,
  history
}: Props) => {
  const renderStatus = () => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    if (!onlyStatus) {
      if (status === APPLICATION_STATUSES.DRAFT)
        return (
          <EditApplicationBtn
            onClick={e => {
              e.stopPropagation();
              history.push(
                `/cabinet/${role}/application-form/edit/${id}/${financingType.toLowerCase()}`
              );
            }}
          >
            Продолжить оформление
          </EditApplicationBtn>
        );

      if (status === APPLICATION_STATUSES.TRANSFERRED_TO_AGENT) {
        if (isApplicationsDistributionList(listType, permissions)) {
          return (
            <DelegateInternalAgentToApplicationSelect
              disableLabel={true}
              placeholder="Назначить"
              applicationId={id}
            />
          );
        }

        if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT))
          return (
            <TransferApplicationBtn
              onClick={e => {
                e.stopPropagation();
                changeApplicationStatus({
                  id,
                  status: APPLICATION_STATUSES.IN_WORK
                });
              }}
            >
              Взять в работу
            </TransferApplicationBtn>
          );
      }

      if (
        status === APPLICATION_STATUSES.TRANSFERRED_TO_BANK &&
        permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
      )
        return (
          <TransferApplicationBtn
            onClick={e => {
              e.stopPropagation();
              changeApplicationStatus({
                id,
                status: APPLICATION_STATUSES.REVIEW
              });
            }}
          >
            Взять в работу
          </TransferApplicationBtn>
        );
    }
    return (
      <ApplicationStatusStyled status={status}>
        {ApplicationStatuses[status]}
      </ApplicationStatusStyled>
    );
  };

  const isApplicationsDistributionList = (
    listType: APPLICATIONS_LIST_TYPE,
    permissions: USER_PERMISSIONS[]
  ) => {
    return (
      listType === APPLICATIONS_LIST_TYPE.TO_DISTRIBUTE &&
      permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS)
    );
  };

  return renderStatus();
};

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ changeApplicationStatus }, dispatch);

const ApplicationStatusConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationStatus)
);

export { ApplicationStatusConnect as ApplicationStatus };
