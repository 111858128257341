import styled from 'styled-components';
import { gray, lighterBlue } from 'shared/styled';

export const FilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TDChecklist = styled.td`
  overflow: visible !important;
`;

export const Content = styled.div`
  display: none;
  align-items: center;
  column-gap: 15px;
  position: absolute;
  top: 35px;
  right: 0;
  background-color: ${lighterBlue};
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    & > p:first-of-type {
      color: ${gray};
    }
  }

  p {
    margin: 0;
  }
`;

export const ChecklistContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: fit-content;
`;

export const ChecklistStyled = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  &:hover ${Content} {
    display: flex;
  }
`;

export const DocChecklist = styled(ChecklistStyled)`
  background-color: ${({ type }) =>
    type === 2 || type === 4
      ? '#F69A0F'
      : type === 6 || type === 8
        ? '#1CCA94'
        : '#D9D9D9'};
`;

export const BkiChecklist = styled(ChecklistStyled)`
  background-color: ${({ type }) =>
    type === 2 ? '#F69A0F' : type === 4 ? '#1CCA94' : '#D9D9D9'};
`;

export const BgParametsChecklist = styled(ChecklistStyled)`
  background-color: ${({ type }) =>
    type === 2 ? '#F69A0F' : type === 4 ? '#1CCA94' : '#D9D9D9'};
`;

export const ApplicationChecklist = styled(ChecklistStyled)`
  background-color: ${({ type }) =>
    type === 2 ? '#F69A0F' : type === 4 ? '#1CCA94' : '#D9D9D9'};
`;

export const DealChecklist = styled(ChecklistStyled)`
  background-color: ${({ type }) =>
    type === 2 ? '#F69A0F' : type === 4 ? '#1CCA94' : '#D9D9D9'};
`;
