import styled from 'styled-components';
import { blue, lighterBlue } from 'shared/styled';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';

export const InnInputContainer = styled(INNFilterContainerStyled)``;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  padding: 10px 20px;
  background-color: ${lighterBlue};
`;

export const AgentTypeContainer = styled(Container)`
  margin-right: 200px;
  max-width: 450px;

  & > fieldset {
    div {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      max-height: 115px;
    }
  }

  & > fieldset > :nth-child(5) {
    margin-top: 23px;
  }
`;

export const FilterWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${({ active }) => (active === null ? '#9FA6B2' : blue)};
`;

export const ErrorTD = styled.p`
  color: red;
`;

export const FilterContainer = styled(Container)`
  align-items: baseline;
  padding: 0;
  margin-top: 20px;
`;
