import { GET_COMPANIES_BY_HOLDING_LIST } from '../reducers/getCompaniesByHoldingList';
import { CompanyRead } from './setCompanyFormData';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: CompanyRead[];
}


export interface RequestData {
  page: number;
  pageSize: number;
  holdingId: number;
}

export const req = (data: RequestData) => ({
  type: GET_COMPANIES_BY_HOLDING_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_COMPANIES_BY_HOLDING_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_COMPANIES_BY_HOLDING_LIST.ERR,
  error
});
