import { TYPE_FILTER_ACTIONS } from "../reducers/updateTypeFilter";

export const addTypeFilter = (newStatus: string) => ({
  type: TYPE_FILTER_ACTIONS.ADD,
  typeToFilterBy: newStatus
});

export const removeTypeFilter = (indexToRemove: number) => ({
  type: TYPE_FILTER_ACTIONS.REMOVE,
  index: indexToRemove
});
