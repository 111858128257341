import * as React from 'react';

import { TableHeader } from 'Layouts/components';

import { BoxStyled } from 'shared/styled';
import { TableStyled } from './styles';
import { TableHeaderStyled } from './TableHeader/styles';
import { TableRow } from './TableRow/TableRow';

interface Props {
  headers: string[];
  rows: Array<Array<string | JSX.Element>>;
  sizes: string[];
  className?: string;
  onRowClick?: (index: number) => void;
  onRowMouseEnter?: (index: number) => void;
  onRowMouseLeave?: () => void;
}

export const Table: React.FC<Props> = ({
  headers,
  rows,
  sizes,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  className
}: Props) => (
    <BoxStyled padding="10px 30px" margin="10px 0">
      <TableStyled sizes={sizes} cellSpacing="0" cellPadding="0">
          <TableHeader headers={headers} />
          <tbody>
              {rows.map((item,key) => (
                  <TableRow
                      key={key}
                      data={item}
                      onClick={!!onRowClick ? () => onRowClick(key) : undefined}
                      onMouseEnter={
                      !!onRowMouseEnter ? () => onRowMouseEnter(key) : undefined
                      }
                      onMouseLeave={
                      !!onRowMouseLeave ? () => onRowMouseLeave() : undefined
                      } />
              ))}
          </tbody>
      </TableStyled>
    </BoxStyled>
);
