import styled from 'styled-components';
import { lighterBlue, rr, blue } from 'shared/styled';

export const CreateContractPageStyled = styled.section`
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 110px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InnArea = styled.div`
  width: 800px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
`;

export const InnFilter = styled.div`
  width: 370px;
  height: auto;
  margin-top: 10px;
  padding: 20px 5px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 20px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0 20px 0 0;
  }
`;

export const ContractsInfoArea = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h1 {
    font-family: ${rr};
    font-size: 1.5em;
    margin-bottom: 25px;
    font-weight: 900;
    color: #000;
  }
`;

export const ContractsInfoItemContainer = styled.div`
  width: auto;
  min-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin-right: 20px;
    margin: 0 20px 0 0;
  }
  input {
    padding: 10px 10px;
    height: 30px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const ContractsInfoItemTitle = styled.p`
  font-family: ${rr};
  font-size: 1.3em;
  width: 350px;
`;

export const TypeContainer = styled.div`
  width: 100px;
  height: 36px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 5px;
`;

export const TypeNoActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  background-color: #f53737;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #f53737;
  border-left: 1px solid #f53737;
  border-bottom: 1px solid #f53737;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px 0 0 0;
  }
`;

export const TypeYesInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #d9d9d9;
    margin: 5px 0 0 0;
  }
  &:hover {
    border-top: 1px solid ${blue};
    border-right: 1px solid ${blue};
    border-bottom: 1px solid ${blue};
    border-left: 1px solid ${blue};
  }
  &:hover p {
    color: ${blue};
  }
`;

export const TypeYesActive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #1cca94;
  border-right: 1px solid #1cca94;
  border-bottom: 1px solid #1cca94;
  background-color: #1cca94;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px 0 0 0;
  }
`;

export const TypeNoInactive = styled.div`
  width: 49px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  cursor: pointer;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    color: #d9d9d9;
    margin: 5px 0 0 0;
  }
  &:hover {
    border-top: 1px solid ${blue};
    border-right: 1px solid ${blue};
    border-bottom: 1px solid ${blue};
    border-left: 1px solid ${blue};
  }
  &:hover p {
    color: ${blue};
  }
`;

export const MovingContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

export const SelectContainerActive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid ${blue};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export const SelectActive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: ${blue};
`;

export const SelectContainerInactive = styled.div`
  width: 23px;
  height: 23px;
  display: flex;
  margin-right: 10px;
  margin-left: 30px;
  justify-content: center;

  border-radius: 5px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid ${blue};
  }
`;

export const SelectInactive = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d9d9d9;
`;

export const InnPopup = styled.div`
  position: relative;
  z-index: 3;
  width: 230px;
  margin-top: -19px;
  margin-left: 60px;

  padding: 10px 5px 10px 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
  h2 {
    font-family: ${rr};
    font-size: 1em;
    width: 100%;
  }
  p {
    font-family: ${rr};
    font-size: 0.8em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;

export const InfoField = styled.div`
  width: 350px;
  min-height: 100px;
  margin-top: 30px;
  display: none;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  h2 {
    font-family: ${rr};
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    font-family: ${rr};
    font-size: 0.9em;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
  }
`;
