import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { req as getSupplierNotificationSettings } from 'entities/SCF/Supplier/model/actions/getSupplierNotificationSettings';
import SubscriptionsHeader from './SubscriptionsHeader';
import { PageNoMarginStyled } from 'src/features/SCF/UI/ScfStyles';
import {
  SubscriptionsSettingsBlock,
  SubscriptionsSettingsRow,
  SubscriptionsSettingsTitle
} from '../styles';
import {
  SwitcherContainer,
  UnchangableOffActive,
  UnchangableOffInactive,
  UnchangableOnActive,
  UnchangableOnInactive
} from 'src/features/SCF/UI/Switcher';
import { Loader } from 'src/features/Layouts/components';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';

const SubscriptionsInfoSupplier = ({
  getSupplierNotificationSettings,
  getSupplierNotificationSettingsStatus
}) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(getSupplierNotificationSettings());
    },
    [dispatch]
  );

  const supplierNotificationSettings = useSelector(
    ({ SCFSupplier }) => SCFSupplier.getSupplierNotificationSettings.data
  );
  return (
    <PageNoMarginStyled>
      <SubscriptionsHeader onSaveClick={''} />
      {getSupplierNotificationSettingsStatus === REQUEST_STATUSES.REQUEST && (
        <Loader />
      )}
      {getSupplierNotificationSettingsStatus === REQUEST_STATUSES.GOT && (
        <SubscriptionsSettingsBlock>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Покупатель добавил вас как поставщика
            </SubscriptionsSettingsTitle>
            {supplierNotificationSettings.newBuyerToSupplierLinkEvent ===
            true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : supplierNotificationSettings.newBuyerToSupplierLinkEvent ===
            false ? (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Покупатель добавил договор с вами
            </SubscriptionsSettingsTitle>
            {supplierNotificationSettings.newSupplyContractLinkEvent ===
            true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : supplierNotificationSettings.newSupplyContractLinkEvent ===
            false ? (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Появилось новое денежное требование для ранней оплаты
            </SubscriptionsSettingsTitle>
            {supplierNotificationSettings.newMonetaryClaimsEvent === true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : supplierNotificationSettings.newMonetaryClaimsEvent ===
            false ? (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
          <SubscriptionsSettingsRow>
            <SubscriptionsSettingsTitle>
              Изменился статус по вашей заявке на раннюю оплату
            </SubscriptionsSettingsTitle>
            {supplierNotificationSettings.earlyPaymentApplicationStatusEvent ===
            true ? (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnActive>
                  <p>ВКЛ</p>
                </UnchangableOnActive>
              </SwitcherContainer>
            ) : supplierNotificationSettings.earlyPaymentApplicationStatusEvent ===
            false ? (
              <SwitcherContainer>
                <UnchangableOffActive>
                  <p>ВЫКЛ</p>
                </UnchangableOffActive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            ) : (
              <SwitcherContainer>
                <UnchangableOffInactive>
                  <p>ВЫКЛ</p>
                </UnchangableOffInactive>
                <UnchangableOnInactive>
                  <p>ВКЛ</p>
                </UnchangableOnInactive>
              </SwitcherContainer>
            )}
          </SubscriptionsSettingsRow>
        </SubscriptionsSettingsBlock>
      )}
    </PageNoMarginStyled>
  );
};

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  supplierNotificationSettings:
    SCFSupplier.getSupplierNotificationSettings.data,
  postSupplierNotificationSettingsStatus:
    SCFSupplier.postSupplierNotificationSettings.status,
  getSupplierNotificationSettingsStatus:
    SCFSupplier.getSupplierNotificationSettings.status
});

const mapDispatchToProps = {
  getSupplierNotificationSettings: getSupplierNotificationSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsInfoSupplier);
