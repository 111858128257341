export const ApplicationStatuses = {
  EMPTY: '',
  DRAFT: 'Черновик',
  TRANSFERRED_TO_AGENT: 'Не распределена',
  IN_WORK: 'Принята в работу',
  AWAITING: 'Дозапрос информации',
  TRANSFERRED_TO_BANK: 'Передана в банк',
  REVIEW: 'На рассмотрении',
  DOCS_REQUEST: 'Дозапрос документов',
  BANK_REJECT: 'Отказ банка',
  USER_DENIAL: 'Отказ от сделки',
  APPROVED: 'Принятие решения',
  SUCCESS: 'Сделка успешна',
  AGENT_APPROVED: 'Предложение банка',
  AGENT_REJECT: 'Отклонено агентом',
  USER_REJECT: 'Отказ клиента',
  EXTERNAL_AGENT_REJECT: 'Отказ'
};
