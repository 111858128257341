import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getActivitiesById';

import { GET_ACTIVITIES_BY_ID } from '../reducers/getActivitiesById';
import ActivitiesApi from '../api';

function* getActivitiesById(action: {
  type: GET_ACTIVITIES_BY_ID;
  id: string;
}): Generator {
  try {
    const res: any = yield call(ActivitiesApi.getActivitiesById, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getActivitiesByIdSaga(): Generator {
  yield takeLatest(GET_ACTIVITIES_BY_ID.REQ, getActivitiesById);
}
