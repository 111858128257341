import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { AskStyled } from 'src/features/CooperationForProducts/components/styled';
import {
  ProductSizeContainer,
  Field,
  Input,
  ProgressBar,
  ProgressStep,
  Tooltip,
  ProductTermContainer,
  ProductComplexityContainer,
  Text,
  Square,
  ProductTerm,
  ProductItem,
  ProductRiskAppetiteContainer,
  PricingContainer,
  RateContainer,
  Rate,
  MinimumCost,
  FormatPrice,
  ErrorMsg
} from 'src/features/BasicParametersForProducts/components/styled';
import {
  PRODUCT_COMPLEXITY,
  RISK_APPETITE
} from 'src/features/BasicParametersForProducts/types';
import { req as patchBasisParams } from '../actions/patchBasisParams';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  minSize: number;
  minComfortSize: number;
  maxComfortSize: number;
  maxSize: number;
  activeMinSize: boolean;
  activeMinComfortSize: boolean;
  activeMaxComfortSize: boolean;
  activeMaxSize: boolean;
  minTermInMonthes: any;
  activeMinTermInMonthes: boolean;
  maxTermInMonthes: any;
  activeMaxTermInMonthes: boolean;
  complexity: any;
  activeComplexity: boolean;
  riskAppetite: any;
  activeRiskAppetite: boolean;
  minRate: string;
  activeMinRate: boolean;
  meanRate: string;
  activeMeanRate: boolean;
  maxRate: string;
  activeMaxRate: boolean;
  minPriceInRub: any;
  activeMinPriceInRub: boolean;
  rateIsSurplusToKeyRate: boolean;
  activeRateIsSurplusToKeyRate: boolean;
  isTooltipRate: boolean;
  isAdminMinSize: boolean;
  isAdminMinComfortSize: boolean;
  isAdminMaxComfortSize: boolean;
  isAdminMaxSize: boolean;
  isAdminMinRate: boolean;
  isAdminMeanRate: boolean;
  isAdminMaxRate: boolean;
  isAdminMinTermInMonthes: boolean;
  isAdminMaxTermInMonthes: boolean;
  isAdminComplexity: boolean;
  isAdminRiskAppetite: boolean;
  isAdminMinPriceInRub: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchBasisParams: (id: string, data: any) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  AnyProps;

class BasicParametersForProducts extends React.Component<Props, State> {
  state = {
    minSize: null,
    minComfortSize: null,
    maxComfortSize: null,
    maxSize: null,
    activeMinSize: false,
    activeMinComfortSize: false,
    activeMaxComfortSize: false,
    activeMaxSize: false,
    minTermInMonthes: null,
    activeMinTermInMonthes: false,
    maxTermInMonthes: null,
    activeMaxTermInMonthes: false,
    complexity: null,
    activeComplexity: false,
    riskAppetite: null,
    activeRiskAppetite: false,
    minRate: '',
    activeMinRate: false,
    meanRate: '',
    activeMeanRate: false,
    maxRate: '',
    activeMaxRate: false,
    minPriceInRub: null,
    activeMinPriceInRub: false,
    rateIsSurplusToKeyRate: null,
    activeRateIsSurplusToKeyRate: false,
    isTooltipRate: false,
    isAdminMinSize: false,
    isAdminMinComfortSize: false,
    isAdminMaxComfortSize: false,
    isAdminMaxSize: false,
    isAdminMinRate: false,
    isAdminMeanRate: false,
    isAdminMaxRate: false,
    isAdminMinTermInMonthes: false,
    isAdminMaxTermInMonthes: false,
    isAdminComplexity: false,
    isAdminRiskAppetite: false,
    isAdminMinPriceInRub: false
  };

  componentDidMount() {
    this.setState({ ...this.props.product } as any);

    if (this.props.product.minRate !== null) {
      this.setState({
        minRate: this.props.product.minRate,
        activeMinRate: true
      });
    }
    if (this.props.product.meanRate !== null) {
      this.setState({
        meanRate: this.props.product.meanRate,
        activeMeanRate: true
      });
    }
    if (this.props.product.maxRate !== null) {
      this.setState({
        maxRate: this.props.product.maxRate,
        activeMaxRate: true
      });
    }
    if (this.props.product.minPriceInRub !== null) {
      this.setState({
        minPriceInRub: this.props.product.minPriceInRub,
        activeMinPriceInRub: true
      });
    }
    if (this.props.product.minTermInMonthes !== null) {
      this.setState({
        minTermInMonthes: this.props.product.minTermInMonthes,
        activeMinTermInMonthes: true
      });
    }
    if (this.props.product.maxTermInMonthes !== null) {
      this.setState({
        maxTermInMonthes: this.props.product.maxTermInMonthes,
        activeMaxTermInMonthes: true
      });
    }
    if (this.props.product.minSize !== null) {
      this.setState({
        minSize: this.props.product.minSize,
        activeMinSize: true
      });
    }
    if (this.props.product.minComfortSize !== null) {
      this.setState({
        minComfortSize: this.props.product.minComfortSize,
        activeMinComfortSize: true
      });
    }
    if (this.props.product.maxComfortSize !== null) {
      this.setState({
        maxComfortSize: this.props.product.maxComfortSize,
        activeMaxComfortSize: true
      });
    }
    if (this.props.product.maxSize !== null) {
      this.setState({
        maxSize: this.props.product.maxSize,
        activeMaxSize: true
      });
    }
    if (this.props.product.complexity !== null) {
      this.setState({
        complexity: this.props.product.complexity,
        activeComplexity: true
      });
    }
    if (this.props.product.riskAppetite !== null) {
      this.setState({
        riskAppetite: this.props.product.riskAppetite,
        activeRiskAppetite: true
      });
    }
    if (this.props.product.rateIsSurplusToKeyRate !== null) {
      this.setState({
        rateIsSurplusToKeyRate: this.props.product.rateIsSurplusToKeyRate,
        activeRateIsSurplusToKeyRate: true
      });
    }
    if (this.props.product.minRate === null) {
      this.setState({ minRate: '' });
    }
    if (this.props.product.meanRate === null) {
      this.setState({ meanRate: '' });
    }
    if (this.props.product.maxRate === null) {
      this.setState({ maxRate: '' });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.minSize !== this.state.minSize) {
      this.setState({ isAdminMinSize: false });
    }
    if (prevState.minComfortSize !== this.state.minComfortSize) {
      this.setState({ isAdminMinComfortSize: false });
    }
    if (prevState.maxComfortSize !== this.state.maxComfortSize) {
      this.setState({ isAdminMaxComfortSize: false });
    }
    if (prevState.maxSize !== this.state.maxSize) {
      this.setState({ isAdminMaxSize: false });
    }
    if (prevState.minRate !== this.state.minRate) {
      this.setState({ isAdminMinRate: false });
    }
    if (prevState.meanRate !== this.state.meanRate) {
      this.setState({ isAdminMeanRate: false });
    }
    if (prevState.maxRate !== this.state.maxRate) {
      this.setState({ isAdminMaxRate: false });
    }
    if (prevState.minTermInMonthes !== this.state.minTermInMonthes) {
      this.setState({ isAdminMinTermInMonthes: false });
    }
    if (prevState.maxTermInMonthes !== this.state.maxTermInMonthes) {
      this.setState({ isAdminMaxTermInMonthes: false });
    }
    if (prevState.complexity !== this.state.complexity) {
      this.setState({ isAdminComplexity: false });
    }
    if (prevState.riskAppetite !== this.state.riskAppetite) {
      this.setState({ isAdminRiskAppetite: false });
    }
    if (prevState.minPriceInRub !== this.state.minPriceInRub) {
      this.setState({ isAdminMinPriceInRub: false });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  handleChangeRate = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (/^[0-9.]+$/.test(value) || value === '') {
      this.setState({ [name]: value, isTooltipRate: false } as any);
    } else {
      this.setState({ isTooltipRate: true });
      setTimeout(() => {
        this.setState({ isTooltipRate: false });
      }, 3000);
    }
  };

  handleChangeSizeProduct = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value.replace(/\s/g, '') } as any);
  };

  formatNumber = number => {
    if (number !== null) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    const values = {
      activeComplexity: { complexity: this.state.complexity },
      activeRiskAppetite: { riskAppetite: this.state.riskAppetite },
      activeMinTermInMonthes: {
        minTermInMonthes: +this.state.minTermInMonthes
      },
      activeMaxTermInMonthes: {
        maxTermInMonthes: +this.state.maxTermInMonthes
      },
      activeMinSize: { minSize: +this.state.minSize },
      activeMinComfortSize: { minComfortSize: +this.state.minComfortSize },
      activeMaxComfortSize: { maxComfortSize: +this.state.maxComfortSize },
      activeMaxSize: { maxSize: +this.state.maxSize },
      activeMinRate: { minRate: +this.state.minRate },
      activeMeanRate: { meanRate: +this.state.meanRate },
      activeMaxRate: { maxRate: +this.state.maxRate },
      activeMinPriceInRub: { minPriceInRub: +this.state.minPriceInRub },
      rateIsSurplusToKeyRate: { rateIsSurplusToKeyRate: value }
    };
    const patchParams = values[key] || { [key]: value };
    this.setState({ [key]: value } as Pick<any, keyof State>);
    this.props.patchBasisParams(id, patchParams);
  };

  handleProductComplexityClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeComplexity || isAdmin) {
      this.setState({ complexity: item });
    }
  };

  handleProductRiskAppetiteClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeRiskAppetite || isAdmin) {
      this.setState({ riskAppetite: item });
    }
  };

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');
    const allActive =
      this.state.activeMinSize &&
      this.state.activeMinComfortSize &&
      this.state.activeMaxComfortSize &&
      this.state.activeMaxSize;

    return (
      <ApplicationViewStyled>
        <AskStyled>Размер продукта:</AskStyled>

        <ProgressBar
          one={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          two={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          three={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          four={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          five={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          six={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize == this.state.maxSize
          }
          seven={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          eight={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          nine={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          ten={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          eleven={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          twelve={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          thirteen={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          fourteen={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize == this.state.maxSize
          }
          fifteen={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxComfortSize < this.state.maxSize
          }
        >
          {[...Array(5)].map(step => (
            <ProgressStep key={step} />
          ))}
        </ProgressBar>

        <ProductSizeContainer>
          <Field>
            {!isAdmin &&
            this.state.activeMinSize &&
            (this.state.minSize == '0' ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinSize}
                name="minSize"
                value={this.formatNumber(this.state.minSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMinSize}
              />
            )}
            <Tooltip>Минимальная сумма продукта</Tooltip>
            {(!this.state.activeMinSize || isAdmin) &&
              !!this.state.minSize &&
              this.state.minSize !== this.props.product.minSize &&
              !this.state.isAdminMinSize && (
                <Button
                  label="Сохранить"
                  w="fit-content"
                  h="30px"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinSize: true });
                    }
                    this.handleButtonClick('activeMinSize', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minSize)}
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMinComfortSize &&
            (this.state.minComfortSize == '0' ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize < this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinComfortSize}
                name="minComfortSize"
                value={this.formatNumber(this.state.minComfortSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMinComfortSize}
              />
            )}
            <Tooltip>Комфортная нижняя граница</Tooltip>
            {(!this.state.activeMinComfortSize || isAdmin) &&
              !!this.state.minComfortSize &&
              this.state.minComfortSize !== this.props.product.minComfortSize &&
              !this.state.isAdminMinComfortSize && (
                <Button
                  label="Сохранить"
                  w="fit-content"
                  h="30px"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinComfortSize: true });
                    }
                    this.handleButtonClick('activeMinComfortSize', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minComfortSize)}
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxComfortSize &&
            (this.state.maxComfortSize == '0' ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minComfortSize == 0 &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxComfortSize}
                name="maxComfortSize"
                value={this.formatNumber(this.state.maxComfortSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMaxComfortSize}
              />
            )}
            <Tooltip>Комфортная верхняя граница</Tooltip>
            {(!this.state.activeMaxComfortSize || isAdmin) &&
              !!this.state.maxComfortSize &&
              this.state.maxComfortSize !== this.props.product.maxComfortSize &&
              !this.state.isAdminMaxComfortSize && (
                <>
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxComfortSize: true });
                      }
                      this.handleButtonClick('activeMaxComfortSize', true);
                    }}
                    disabled={!/^\d+$/.test(this.state.maxComfortSize)}
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxSize &&
            (this.state.maxSize == '0' ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0) ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxSize}
                name="maxSize"
                value={this.formatNumber(this.state.maxSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMaxSize}
              />
            )}
            <Tooltip>Максимальная сумма продукта</Tooltip>
            {(!this.state.activeMaxSize || isAdmin) &&
              !!this.state.maxSize &&
              this.state.maxSize !== this.props.product.maxSize &&
              !this.state.isAdminMaxSize && (
                <>
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxSize: true });
                      }
                      this.handleButtonClick('activeMaxSize', true);
                    }}
                    disabled={!/^\d+$/.test(this.state.maxSize)}
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
        </ProductSizeContainer>

        <ProductTermContainer>
          <AskStyled>Срок продукта (месяцев):</AskStyled>
          <ProductTerm>
            <p>Минимальный:</p>
            <Input
              active={this.state.activeMinTermInMonthes}
              type="text"
              name="minTermInMonthes"
              value={this.state.minTermInMonthes}
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMinTermInMonthes}
            />
            <span>мес.</span>
            {(!this.state.activeMinTermInMonthes || isAdmin) &&
              !!this.state.minTermInMonthes &&
              this.state.minTermInMonthes !==
                this.props.product.minTermInMonthes &&
              !this.state.isAdminMinTermInMonthes && (
                <Button
                  label="Сохранить"
                  w="fit-content"
                  h="30px"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinTermInMonthes: true });
                    }
                    this.handleButtonClick('activeMinTermInMonthes', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minTermInMonthes)}
                />
              )}
          </ProductTerm>
          <ProductTerm>
            <p>Максимальный:</p>
            <Input
              active={this.state.activeMaxTermInMonthes}
              type="text"
              name="maxTermInMonthes"
              value={this.state.maxTermInMonthes}
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMaxTermInMonthes}
            />
            <span>мес.</span>
            {(!this.state.activeMaxTermInMonthes || isAdmin) &&
              !!this.state.maxTermInMonthes &&
              this.state.maxTermInMonthes !==
                this.props.product.maxTermInMonthes &&
              !this.state.isAdminMaxTermInMonthes && (
                <Button
                  label="Сохранить"
                  w="fit-content"
                  h="30px"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMaxTermInMonthes: true });
                    }
                    this.handleButtonClick('activeMaxTermInMonthes', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.maxTermInMonthes)}
                />
              )}
          </ProductTerm>
        </ProductTermContainer>

        <PricingContainer>
          <AskStyled>Ценообразование:</AskStyled>
          <RateContainer>
            <AskStyled>Формат цены:</AskStyled>
            <FormatPrice active={this.state.rateIsSurplusToKeyRate !== null}>
              <Checkbox
                label="ставка"
                name="rate"
                onChange={() =>
                  this.handleButtonClick('rateIsSurplusToKeyRate', false)
                }
                checked={this.state.rateIsSurplusToKeyRate === false}
                disabled={
                  (this.state.activeRateIsSurplusToKeyRate ||
                    this.state.rateIsSurplusToKeyRate !== null) &&
                  !isAdmin
                }
              />
              <Checkbox
                label="ключ + ставка"
                name="keyPlusRate"
                onChange={() =>
                  this.handleButtonClick('rateIsSurplusToKeyRate', true)
                }
                checked={this.state.rateIsSurplusToKeyRate === true}
                disabled={
                  (this.state.activeRateIsSurplusToKeyRate ||
                    this.state.rateIsSurplusToKeyRate !== null) &&
                  !isAdmin
                }
              />
            </FormatPrice>
            <AskStyled>Ставка:</AskStyled>
            <Rate>
              <p>Минимальная:</p>
              <Input
                active={this.state.activeMinRate}
                name="minRate"
                value={this.state.minRate}
                onChange={this.handleChangeRate}
                disabled={!isAdmin && this.state.activeMinRate}
              />
              <span>%</span>
              {(!this.state.activeMinRate || isAdmin) &&
                !!this.state.minRate &&
                this.state.minRate !== this.props.product.minRate &&
                !this.state.isAdminMinRate && (
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMinRate: true });
                      }
                      this.handleButtonClick('activeMinRate', true);
                    }}
                    disabled={!/^\d*\.?\d+$/.test(this.state.minRate)}
                  />
                )}
            </Rate>
            <Rate>
              <p>Средняя:</p>
              <Input
                active={this.state.activeMeanRate}
                name="meanRate"
                value={this.state.meanRate}
                onChange={this.handleChangeRate}
                disabled={!isAdmin && this.state.activeMeanRate}
              />
              <span>%</span>
              {(!this.state.activeMeanRate || isAdmin) &&
                !!this.state.meanRate &&
                this.state.meanRate !== this.props.product.meanRate &&
                !this.state.isAdminMeanRate && (
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMeanRate: true });
                      }
                      this.handleButtonClick('activeMeanRate', true);
                    }}
                    disabled={!/^\d*\.?\d+$/.test(this.state.meanRate)}
                  />
                )}
            </Rate>
            <Rate>
              <p>Максимальная:</p>
              <Input
                active={this.state.activeMaxRate}
                name="maxRate"
                value={this.state.maxRate}
                onChange={this.handleChangeRate}
                disabled={!isAdmin && this.state.activeMaxRate}
              />
              <span>%</span>
              {(!this.state.activeMaxRate || isAdmin) &&
                !!this.state.maxRate &&
                this.state.maxRate !== this.props.product.maxRate &&
                !this.state.isAdminMaxRate && (
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxRate: true });
                      }
                      this.handleButtonClick('activeMaxRate', true);
                    }}
                    disabled={!/^\d*\.?\d+$/.test(this.state.maxRate)}
                  />
                )}
            </Rate>
            {this.state.isTooltipRate && (
              <ErrorMsg>Можно ввести только цифру и точку</ErrorMsg>
            )}
          </RateContainer>
          <MinimumCost>
            <p>Минимальная стоимость:</p>
            <Input
              active={this.state.activeMinPriceInRub}
              type="text"
              name="minPriceInRub"
              value={this.state.minPriceInRub}
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMinPriceInRub}
            />
            <span>руб.</span>
            {(!this.state.activeMinPriceInRub || isAdmin) &&
              !!this.state.minPriceInRub &&
              this.state.minPriceInRub !== this.props.product.minPriceInRub &&
              !this.state.isAdminMinPriceInRub && (
                <Button
                  label="Сохранить"
                  w="fit-content"
                  h="30px"
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinPriceInRub: true });
                    }
                    this.handleButtonClick('activeMinPriceInRub', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minPriceInRub)}
                />
              )}
          </MinimumCost>
        </PricingContainer>

        <ProductComplexityContainer
          product={this.state.complexity}
          active={this.state.activeComplexity}
        >
          <AskStyled>Сложность продукта:</AskStyled>
          {Object.keys(PRODUCT_COMPLEXITY).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductComplexityClick(item)}
              >
                <Square active={this.state.complexity === item} />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.complexity === item &&
                (!this.state.activeComplexity || isAdmin) &&
                this.state.complexity !== this.props.product.complexity &&
                !this.state.isAdminComplexity && (
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    disabled={!isAdmin && this.state.activeComplexity}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminComplexity: true });
                      }
                      this.handleButtonClick('activeComplexity', true);
                    }}
                  />
                )}
            </div>
          ))}
        </ProductComplexityContainer>

        <ProductRiskAppetiteContainer
          product={this.state.riskAppetite}
          active={this.state.activeRiskAppetite}
        >
          <AskStyled>Аппетит к риску:</AskStyled>
          {Object.keys(RISK_APPETITE).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductRiskAppetiteClick(item)}
              >
                <Square active={this.state.riskAppetite === item} />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.riskAppetite === item &&
                (!this.state.activeRiskAppetite || isAdmin) &&
                this.state.riskAppetite !== this.props.product.riskAppetite &&
                !this.state.isAdminRiskAppetite && (
                  <Button
                    label="Сохранить"
                    w="fit-content"
                    h="30px"
                    disabled={!isAdmin && this.state.activeRiskAppetite}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminRiskAppetite: true });
                      }
                      this.handleButtonClick('activeRiskAppetite', true);
                    }}
                  />
                )}
            </div>
          ))}
        </ProductRiskAppetiteContainer>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ BasisParams }: CRM) => ({
  status: BasisParams.patchBasisParams.status,
  error: BasisParams.patchBasisParams.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchBasisParams }, dispatch);

const BasicParametersForProductsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BasicParametersForProducts)
);

export { BasicParametersForProductsConnect as BasicParametersForProducts };
