import styled from 'styled-components';

export const SFCPageStyled = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3vw;
  align-items: center;
  p {
    margin-bottom: 110px;
  }
`;
