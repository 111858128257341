import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/patchPurchaseContractDebtor';

import {
  PATCH_PURCHASE_CONTRACT_DEBTOR,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/patchPurchaseContractDebtor';

import SCFApi from 'entities/SCF/Debtor/api';

function* patchPurchaseContractDebtor(action: {
  type: PATCH_PURCHASE_CONTRACT_DEBTOR;
  guid: string;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.patchPurchaseContractDebtor, action.guid, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchPurchaseContractDebtorSaga() {
  yield takeLatest(
    PATCH_PURCHASE_CONTRACT_DEBTOR.REQ,
    patchPurchaseContractDebtor
  );
}
