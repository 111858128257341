import { GET_PRODUCTS_LIST_BANK } from '../reducers/getProductsList';
import { ProductsListBank } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ProductsListBank[];
}

export const req = () => ({
  type: GET_PRODUCTS_LIST_BANK.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_PRODUCTS_LIST_BANK.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PRODUCTS_LIST_BANK.ERR,
  error
});
