import { PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/patchSetDiscountMargin';

export interface RequestData {
  margin: number;
}

export const req = (id: number, data: RequestData) => ({
  type: PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL.REQ,
  id,
  data
});

export const got = () => ({
  type: PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL.GOT
});

export const err = (error: {}) => ({
  type: PATCH_SET_DISCOUNT_MARGIN_BY_INTERNAL.ERR,
  error
});
