import { PATCH_AGENT } from 'src/features/Questionnaire/reducers/patchAgent';

export const req = (inn: string, data: any) => ({
  type: PATCH_AGENT.REQ,
  inn,
  data
});

export const got = () => ({
  type: PATCH_AGENT.GOT
});

export const err = (error = {}) => ({
  type: PATCH_AGENT.ERR,
  error
});
