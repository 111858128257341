import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/InternalAgent/model/actions/getAllDocumentForms';

export interface GetAllDocumentFormsStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_ALL_DOCUMENTS_FORMS {
  REQ = 'GET_ALL_DOCUMENTS_FORMS_REQ',
  GOT = 'GET_ALL_DOCUMENTS_FORMS_GOT',
  ERR = 'GET_ALL_DOCUMENTS_FORMS_ERR'
}

const initialState: GetAllDocumentFormsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getAllDocumentForms = (
  state: GetAllDocumentFormsStoreState = initialState,
  action: {
    type: GET_ALL_DOCUMENTS_FORMS;
    data: ResponseData[];
    error: {};
  }
): GetAllDocumentFormsStoreState => {
  switch (action.type) {
    case GET_ALL_DOCUMENTS_FORMS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ALL_DOCUMENTS_FORMS.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };
    case GET_ALL_DOCUMENTS_FORMS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getAllDocumentForms;
