import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/postAgreementSignatureFactorRole';

import {
  POST_AGREEMENT_SIGNATURE_FACTOR_ROLE,
  RequestDataType
} from 'entities/SCF/Factor/model/reducers/postAgreementSignatureFactorRole';

import SCFApi from 'entities/SCF/Factor/api';

function* postAgreementSignatureFactorRole(action: {
  type: POST_AGREEMENT_SIGNATURE_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postAgreementSignatureFactorRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAgreementSignatureFactorRoleSaga() {
  yield takeLatest(
    POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.REQ,
    postAgreementSignatureFactorRole
  );
}
