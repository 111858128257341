import { POST_ADDED_MONETARY_CLAIM_BY_DEBTOR } from 'src/features/SCF/reducers/postAddedMonetaryClaimByDebtor';

export interface RequestData {
  Guids: string[];
}

export const req = (auctionGuid: string, data: RequestData) => ({
  type: POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.REQ,
  auctionGuid,
  data
});

export const got = () => ({
  type: POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.GOT
});

export const err = (error: {}) => ({
  type: POST_ADDED_MONETARY_CLAIM_BY_DEBTOR.ERR,
  error
});
