import styled from 'styled-components';
import {
  PopupContent,
  PopupContainer
} from 'src/features/Contacts/components/ContactsList/styled';

export const PopupContainerAgent = styled(PopupContainer)`
  display: flex;
  z-index: 3;
`;

export const PopupContentAgent = styled(PopupContent)`
  min-width: 250px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  p {
    font-size: 18px;
  }
`;
