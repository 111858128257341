import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/checkAvailableLimits';
import { CHECK_AVAILABLE_LIMITS } from '../reducers/checkAvailableLimits';
import SCFApi from '../api';

function* checkAvailableLimits(action: {
  type: CHECK_AVAILABLE_LIMITS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.checkAvailableLimits, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* checkAvailableLimitsSaga() {
  yield takeLatest(
    CHECK_AVAILABLE_LIMITS.CHECK_AVAILABLE_LIMITS_REQ,
    checkAvailableLimits
  );
}
