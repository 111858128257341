import {
  GET_FINANCIALS_SUMMARY,
  ResponseData
} from 'Application/reducers/getFinancailsSummary';

export const req = (inn: string) => ({
  type: GET_FINANCIALS_SUMMARY.REQ,
  inn
});

export const got = (data: ResponseData) => ({
  type: GET_FINANCIALS_SUMMARY.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FINANCIALS_SUMMARY.ERR,
  error
});

export const reset = () => ({
  type: GET_FINANCIALS_SUMMARY.RESET
});
