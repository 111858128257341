import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/postFactoringConnectionSupplierRole';

import {
  POST_FACTORING_CONNECTION_SUPPLIER_ROLE,
  RequestDataType
} from 'entities/SCF/Supplier/model/reducers/postFactoringConnectionSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* postFactoringConnectionSupplierRole(action: {
  type: POST_FACTORING_CONNECTION_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.postFactoringConnectionSupplierRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFactoringConnectionSupplierRoleSaga() {
  yield takeLatest(
    POST_FACTORING_CONNECTION_SUPPLIER_ROLE.REQ,
    postFactoringConnectionSupplierRole
  );
}
