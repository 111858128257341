import { PUT_OPEN_LOAN_LIMITS_SET_INACTIVE } from '../reducers/putOpenLoanLimitsSetInactive';

export const req = (id: string) => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_INACTIVE.REQ,
  id
});

export const got = () => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_INACTIVE.GOT
});

export const err = (error: {}) => ({
  type: PUT_OPEN_LOAN_LIMITS_SET_INACTIVE.ERR,
  error
});
