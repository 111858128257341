import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getQtySidebar';

import { GET_QTY_SIDEBAR } from 'entities/Cabinet/ExternalAgent/model/reducers/getQtySidebar';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getQtySidebar(action: { type: GET_QTY_SIDEBAR }): Generator {
  try {
    const res: any = yield call(ExternalAgentRoleApi.getQtySidebar);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getQtySidebarSaga(): Generator {
  yield takeLatest(GET_QTY_SIDEBAR.REQ, getQtySidebar);
}
