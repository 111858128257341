import { GET_ALL_USERS_FOR_EXTERNAL_AGENT } from '../reducers/getAllUsersForExternalAgent';

export interface UserData {
  id: string;
  lastName: string;
  firstName: string;
  createdDateTime: string;
  lastLoginAt: string;
  email: string;
  phoneNumber: string;
  companyInn: string;
}

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: UserData[];
}

export interface RequestData {
  page: number;
  pageSize: number;
  companyInnToFilterBy?: string;
}

export const req = (data: RequestData) => ({
  type: GET_ALL_USERS_FOR_EXTERNAL_AGENT.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_ALL_USERS_FOR_EXTERNAL_AGENT.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ALL_USERS_FOR_EXTERNAL_AGENT.ERR,
  error
});
