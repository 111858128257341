import * as React from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USER_PERMISSIONS } from 'src/globaltypes';
import { CabinetComponent } from '../EachPage';
import { createApplicationsListCabinetComponent } from 'src/pages/Cabinet/ListCabinetComponent/ListCabinetComponent';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';
import { APPLICATIONS_LIST_TYPE } from 'src/features/Application/reducers/setApplicationListType';
import { UserList } from 'pages/Cabinet/Bank/Pages/Users/Users';
import { NavigationLink } from 'Layouts/components';

function createComponent({ path, Component, listType, icon, label }) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const AdministrationSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>
        Администрирование
      </SectionSeparatorStyled>
    ),
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_ADMIN].isIn(permissions)
  },

  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_ADMIN].isIn(permissions),
    ...createApplicationsListCabinetComponent({
      path: '/cabinet/bank/general-applications',
      type: APPLICATIONS_LIST_TYPE.GENERAL,
      link: {
        icon: 'desk',
        label: 'Новые заявки на распределение',
        countName: 'general'
      }
    })
  },
  {
    hasPermission: permissions =>
      [USER_PERMISSIONS.BANK_ADMIN].isIn(permissions),
    ...createComponent({
      Component: UserList,
      path: '/cabinet/bank/users',
      label: 'Пользователи',
      icon: <FontAwesomeIcon icon={faUsers} fixedWidth={true} />,
      listType: null
    })
  }
];
