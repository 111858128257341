import { combineReducers } from 'redux';
import getPersonOfCompanies, {
  GetPersonOfCompaniesStoreState
} from './getPersonOfCompanies';
import getBeneficiariesOfCompaniesPerson, {
  GetBeneficiariesOfCompaniesPersonStoreState
} from './getBeneficiariesOfCompanies';
import getSummaryPerson, {
  GetSummaryPersonStoreState
} from './getSummaryPerson';
import getCompanyByInnConfirmedBeneficiaries, {
  GetCompanyByInnConfirmedBeneficiariesStoreState
} from './getCompanyByInnConfirmedBeneficiaries';
import getCompanyByInnConfirmedPerson, {
  GetCompanyByInnConfirmedPersonStoreState
} from './getCompanyByInnConfirmedPerson';
import getSearchPerson, { GetSearchPersonStoreState } from './getSearchPerson';

export interface PersonReducers {
  getPersonOfCompanies: GetPersonOfCompaniesStoreState;
  getBeneficiariesOfCompaniesPerson: GetBeneficiariesOfCompaniesPersonStoreState;
  getSummaryPerson: GetSummaryPersonStoreState;
  getCompanyByInnConfirmedBeneficiaries: GetCompanyByInnConfirmedBeneficiariesStoreState;
  getCompanyByInnConfirmedPerson: GetCompanyByInnConfirmedPersonStoreState;
  getSearchPerson: GetSearchPersonStoreState;
}

export const Person = combineReducers({
  getPersonOfCompanies,
  getBeneficiariesOfCompaniesPerson,
  getSummaryPerson,
  getCompanyByInnConfirmedPerson,
  getCompanyByInnConfirmedBeneficiaries,
  getSearchPerson
});
