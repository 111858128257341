import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postExternalApiPSB';

import { POST_EXTERNAL_API_PSB_APPLICATION } from '../reducers/postExternalApiPSB';
import ApplicationApi from '../api';

function* postExternalApiPSB(action: {
  type: POST_EXTERNAL_API_PSB_APPLICATION;
  ApplicationID: number;
}): Generator {
  try {
    yield call(ApplicationApi.postExternalApiPSB, action.ApplicationID);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postExternalApiPSBSaga(): Generator {
  yield takeLatest(POST_EXTERNAL_API_PSB_APPLICATION.REQ, postExternalApiPSB);
}
