import * as React from 'react';

import { REQUEST_STATUSES } from 'globaltypes';
import { TransitionWrapper, ANIMATION_NAMES } from 'Common';
import { Spinner } from 'Layouts/components';
import { ErrorIcon } from 'shared/styled';
import {
  LoaderBarStyled,
  DefaultFeedbackText,
  ErrorFeedbackText
} from './styles';

interface Props {
  className?: string;
  status: REQUEST_STATUSES;
  processText: string;
  errorText?: string;
}

export const LoaderBar: React.FC<Props> = ({
  status,
  processText,
  errorText = 'Возникла ошибка! Попробуйте позже.',
  className
}) => (
  <React.Fragment>
    <TransitionWrapper
      in={status === REQUEST_STATUSES.REQUEST}
      animationName={ANIMATION_NAMES.FADE}
      className={className}
    >
      <LoaderBarStyled status={status}>
        <Spinner size={30} />
        <DefaultFeedbackText>{processText}</DefaultFeedbackText>
      </LoaderBarStyled>
    </TransitionWrapper>

    <TransitionWrapper
      in={status === REQUEST_STATUSES.ERROR}
      animationName={ANIMATION_NAMES.FADE}
      className={className}
    >
      <LoaderBarStyled status={status}>
        <ErrorIcon size={30} />
        <ErrorFeedbackText>{errorText}</ErrorFeedbackText>
      </LoaderBarStyled>
    </TransitionWrapper>
  </React.Fragment>
);
