import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { SubsidiariesType } from '../types';

export interface GetSubsidiariesOfCompaniesStoreState extends Reducer {
  data: SubsidiariesType[];
}

export enum GET_SUBSIDIARIES_OF_COMPANIES {
  REQ = 'GET_SUBSIDIARIES_OF_COMPANIES_REQ',
  GOT = 'GET_SUBSIDIARIES_OF_COMPANIES_GOT',
  ERR = 'GET_SUBSIDIARIES_OF_COMPANIES_ERR'
}

export const initialState: GetSubsidiariesOfCompaniesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getSubsidiariesOfCompanies = (
  state: GetSubsidiariesOfCompaniesStoreState = initialState,
  action: {
    type: GET_SUBSIDIARIES_OF_COMPANIES;
    data: SubsidiariesType[];
    error: {};
  }
): GetSubsidiariesOfCompaniesStoreState => {
  switch (action.type) {
    case GET_SUBSIDIARIES_OF_COMPANIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUBSIDIARIES_OF_COMPANIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUBSIDIARIES_OF_COMPANIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSubsidiariesOfCompanies;
