import { POST_ADD_TENDER_BY_ID } from '../reducers/postAddTenderById';

export const req = (id: string, tenderId: number) => ({
  type: POST_ADD_TENDER_BY_ID.REQ,
  id,
  tenderId
});

export const got = () => ({
  type: POST_ADD_TENDER_BY_ID.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_TENDER_BY_ID.ERR,
  error
});
