import { GET_PURCHASE_CONTRACT_BY_GUID } from '../reducers/getPurchaseContractByGuid';

export interface ResponseDataType {
  confirmedByDebtor: boolean;
  contraceFileIdentifyer: string;
  contraceFileName: string;
  contractEndDate: any;
  contractName: string;
  contractNumber: string;
  contractStartDate: string;
  debtorCertificateSerial: null;
  debtorSignatureFileName: string;
  debtorSignatureIdentifier: null;
  debtorSigneeInn: string;
  debtorSigneePersonInn: string;
  id: string;
  isPerpetual: boolean;
  paymentDeferment: number;
  paymentDefermentFixed: boolean;
  supplierCertificateSerial: string;
  supplierInn: string;
  supplierName: string;
  supplierSignatureFileName: string;
  supplierSignatureIdentifier: string;
  supplierSigneeInn: string;
  supplierSigneePersonInn: string;
}

export interface RequestDataType {
  id: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_ERR,
  error
});
