import {
  BUSINESS_STATUS_ACTIONS,
  ResponseData
} from 'Business/reducers/businessStatus';

export const req = () => ({
  type: BUSINESS_STATUS_ACTIONS.REQ
});

export const got = (data: ResponseData) => ({
  type: BUSINESS_STATUS_ACTIONS.GOT,
  data
});

export const err = (error: string) => ({
  type: BUSINESS_STATUS_ACTIONS.ERR,
  error
});
