import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getPersons';

import { GET_PERSONS } from '../reducers/getPersons';
import PersonsApi from '../api';

function* getPersons(action: { type: GET_PERSONS; inn: string }): Generator {
  try {
    const res: any = yield call(PersonsApi.getPersons, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPersonsSaga(): Generator {
  yield takeLatest(GET_PERSONS.REQ, getPersons);
}
