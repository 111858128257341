import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  req as getMonetaryClaims,
  ResponseData as MonetaryClaimRes,
  RequestData
} from 'entities/SCF/Internal/model/actions/getMonetaryClaimsInternalAgent';

import { STORE, REQUEST_STATUSES } from 'globaltypes';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Loader } from 'src/features/Layouts/components';
import MonetaryClaims from 'pages/SCF/Debtor/components/Suppliers/SupplierInfo/Tabs/MonetaryClaims/MonetaryClaims';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { INNField, INNContainer } from './styled';

interface StateToProps {
  monetaryClaims: MonetaryClaimRes;
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaims: (data: RequestData) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Supplies: React.FC<Props> = ({
  getMonetaryClaims,
  monetaryClaims,
  status,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const supplierInn = searchParams.get('supplierInn') || '';
      const debtorInn = searchParams.get('debtorInn') || '';

      setSupplierInn(supplierInn);
      setDebtorInn(debtorInn);

      getMonetaryClaims({
        page,
        pageSize: 20,
        ...(supplierInn && { supplierInn }),
        ...(debtorInn && { debtorInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <ApplicationTitle>Поставки</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <MonetaryClaims
          monetaryClaims={monetaryClaims}
          status={status}
          type={['internal']}
          noData={{
            title: 'ПОСТАВКИ НЕ НАЙДЕНЫ',
            text: 'поставки от этого поставщика не найдены'
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ SCFInternal }: STORE) => ({
  monetaryClaims: SCFInternal.getMonetaryClaimsInternalAgent.data,
  status: SCFInternal.getMonetaryClaimsInternalAgent.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getMonetaryClaims }, dispatch);

const SuppliesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Supplies)
);

export { SuppliesConnect as Supplies };
