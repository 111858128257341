import getActivitiesSaga from './getActivities';
import createActivitiesSaga from './createActivities';
import getActivitiesTypeSaga from './getActivitiesType';
import getActivitiesByIdSaga from './getActivitiesById';
import getContactsOfActivitiesSaga from './getContactsOfActivities';
import getParticipantsOfActivitySaga from './getParticipantsOfActivity';
import addContactSaga from './addContact';
import addParticipantSaga from './addParticipant';

export default [
  getActivitiesSaga,
  createActivitiesSaga,
  getActivitiesTypeSaga,
  getActivitiesByIdSaga,
  getContactsOfActivitiesSaga,
  getParticipantsOfActivitySaga,
  addContactSaga,
  addParticipantSaga
];
