import { GET_OPEN_LOAN_LIMITS_EXTERNAL } from '../reducers/getOpenLoanLimitsExternal';

export interface ResponseData {
  volume: number;
  productName: string;
  guid: string;
  term: number;
  rate: any;
}

export const req = (inn: string) => ({
  type: GET_OPEN_LOAN_LIMITS_EXTERNAL.REQ,
  inn
});

export const got = (platforms: ResponseData) => ({
  type: GET_OPEN_LOAN_LIMITS_EXTERNAL.GOT,
  platforms
});

export const err = (error: {}) => ({
  type: GET_OPEN_LOAN_LIMITS_EXTERNAL.ERR,
  error
});
