import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestDataType
} from 'src/features/ExternalCredentials/actions/getCompanyByInn';
import { GET_COMPANY_BY_INN } from 'src/features/ExternalCredentials/reducers/getCompanyByInn';
import SCFApi from 'src/features/SCF/api/index';

function* getCompanyByInn(action: {
  type: GET_COMPANY_BY_INN;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getCompanyByInnThirdParty, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyByInnSaga() {
  yield takeLatest(GET_COMPANY_BY_INN.REQ, getCompanyByInn);
}
