import styled from 'styled-components';

import { TextareaContainerStyled } from 'src/shared/ui/Textarea/styles';

import { lightGray } from 'shared/styled';

import {
  AddCommentContainer,
  CommentTextarea,
  AddCommentBlockStyled,
  AddCommentLoaderBar
} from '../styles';

export const ActionButtonsBlock = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  top: 14px;
  right: 20px;
  position: absolute;
`;

export const ReplyTextarea = styled(CommentTextarea)`
  padding: 10px;
  padding-right: 100px;
`;

export const AddReplyContainer = styled(AddCommentContainer)`
  margin: 0 0 20px 0;
`;

export const AddReplyBlockStyled = styled(AddCommentBlockStyled)`
  padding: 0;
  border: none;

  ${TextareaContainerStyled} {
    border-radius: 6px;
    border: 1px solid ${lightGray};
  }
`;

export const AddReplyLoaderBar = styled(AddCommentLoaderBar)`
  width: 98%;
  margin-bottom: 10px;
  left: 1%;
`;
