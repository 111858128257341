import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { InputFile } from 'src/features/SCF/components/InputFile/InputFile';
import SCFDebtorApi from 'entities/SCF/Debtor/api';
import SCFApi from 'src/features/SCF/api';
import SCFSupplierApi from 'entities/SCF/Supplier/api';
import { Loader } from 'Layouts/components';
import {
  req as getSupplyContract,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getSupplyContractByGuid';
import { req as patchPurchaseContract } from 'entities/SCF/Supplier/model/actions/patchPurchaseContractSupplier';
import { RequestDataType as ReqPatchPurchaseContract } from 'entities/SCF/Supplier/model/reducers/patchPurchaseContractSupplier';
import {
  req as getContractBySupplier,
  ResponseData as ResContractBySupplier
} from 'entities/SCF/Supplier/model/actions/getContractBySupplier';
import { req as getBankAccount } from 'src/features/SCF/actions/getBankAccount';
import { req as postAddToContractBySupplier } from 'entities/SCF/Supplier/model/actions/postAddToContractBySupplier';
import { ResponseDataType as GetBankAccountResponseDataType } from 'src/features/SCF/actions/getBankAccount';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { closeModal } from 'Modal/actions/toggleModal';
import {
  req as postConfirmContractBySupplier,
  RequestData as ReqConfirmContractBySupplier
} from 'entities/SCF/Supplier/model/actions/postConfirmContractBySupplier';
import { OpenModalDataType } from 'src/features/Common/Modal/reducers/toggleModal';
import {
  ContractsInfoPageStyled,
  ContractsInfoArea,
  ContractsInfoItemContainer,
  ContractsInfoItemTitle,
  ContractsContainer,
  ContractsHeader,
  ContractsTopSection,
  ContractsTopLeftContainer,
  SelectContainerActive,
  SelectActive,
  SelectContainerInactive,
  SelectInactive,
  AccountContainer,
  AccountInfo,
  DiscountingFormModal,
  TableStyledWrapper,
  PayerContractDescription,
  AccountInfoContainer,
  ConfirmedStatus
} from './styles';
import {
  BackBtn,
  BackBtnText,
  AddBtn,
  AddBtnText,
  BtnsBlock,
  BtnsBlockSignBtn,
  BtnsBlockDownloadDocument,
  BtnsBlockDownloadSignature,
  BtnsBlockDownloadSignatureTopContainer,
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError
} from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { req as uploadContractDocument } from 'entities/SCF/Supplier/model/actions/uploadSupplierContractDocument';
import { SagaRequestDataType as UploadContractDocumentRequestDataType } from 'entities/SCF/Supplier/model/reducers/uploadSupplierContractDocument';
import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { RegistryDocument } from 'src/features/Application/types';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { Modal } from 'src/features/Common';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { lightGray, orange } from 'shared/styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  supplyContract: ResponseDataType;
  documentsUploadStatus: REQUEST_STATUSES;
  statusPatchPurchaseContract: REQUEST_STATUSES;
  supplierContract: ResContractBySupplier[];
  statusSupplierContract: REQUEST_STATUSES;
  bankAccount: GetBankAccountResponseDataType;
  statusAddContract: REQUEST_STATUSES;
  statusConfirmContractSupplier: REQUEST_STATUSES;
}

interface DispatchToProps {
  getSupplyContract: (data: RequestDataType) => void;
  uploadContractDocument: (data: UploadContractDocumentRequestDataType) => void;
  patchPurchaseContract: (guid: string, data: ReqPatchPurchaseContract) => void;
  getContractBySupplier: (guid: string) => void;
  getBankAccount: () => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  postAddToContractBySupplier: (contractAccount: string, guid: string) => void;
  postConfirmContractBySupplier: (
    accountGuid: string,
    contractGuid: string,
    data: ReqConfirmContractBySupplier
  ) => void;
}

interface State {
  pageUrl: string;
  filesDisplay: RegistryDocument[];
  isPaymentDefermentFixed: boolean;
  paymentDeferment: number;
  selectedPayerContract: string;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ContractsInfoPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: this.props.match.params.guid,
        filesDisplay: null,
        isPaymentDefermentFixed: null,
        paymentDeferment: 0,
        selectedPayerContract: null
      };
      this.handleFilesChange = this.handleFilesChange.bind(this);
    }
  }

  handleFilesChange(file) {
    this.setState({ filesDisplay: file });
  }

  onFilesUpload = contract => {
    this.handleFilesChange(contract);
  };

  sendContractDocument = () => {
    const { uploadContractDocument } = this.props;
    uploadContractDocument({
      files: this.state.filesDisplay,
      guid: this.props.supplyContract.id
    });
    this.handleFilesChange(null);
  };

  initFetch = () => {
    const { getSupplyContract, getContractBySupplier } = this.props;
    getSupplyContract({ id: this.state.pageUrl });
    getContractBySupplier(this.props.match.params.guid);

    function base64ToBlob(base64, type = 'application/octet-stream') {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    }

    function arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    }

    fetch(`/api/purchase_contract/${this.state.pageUrl}/get_agreement_file`)
      .then(res => res.arrayBuffer())
      .then(data => {
        const base64Str = arrayBufferToBase64(data);
        const blob = base64ToBlob(base64Str, 'application/pdf');
        const url = URL.createObjectURL(blob);
        const outputObject = document.createElement('iframe');
        const outputText = document.createElement('p');
        outputText.style.margin = '30px 0 8px 0';
        outputText.style.fontFamily = 'Roboto-Regular';
        outputText.innerHTML = '* Превью договора';
        outputObject.src = url + '#toolbar=0';
        outputObject.style.width = '1200px';
        outputObject.style.height = '1000px';
        outputObject.style.background = '#262B41';
        const container = document.getElementById('documentObject');
        if (container) {
          container.innerHTML = '';
          container.appendChild(outputText);
          container.appendChild(outputObject);
        }
      });
  };

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (this.props.status === REQUEST_STATUSES.GOT &&
        this.props.documentsUploadStatus !== prevProps.documentsUploadStatus) ||
      (this.props.statusPatchPurchaseContract === REQUEST_STATUSES.GOT &&
        this.props.statusPatchPurchaseContract !==
          prevProps.statusPatchPurchaseContract)
    ) {
      this.initFetch();
      this.setState({ paymentDeferment: 0, isPaymentDefermentFixed: null });
    }
    if (
      this.props.statusConfirmContractSupplier !==
        prevProps.statusConfirmContractSupplier &&
      this.props.statusConfirmContractSupplier === REQUEST_STATUSES.GOT
    ) {
      this.props.getContractBySupplier(this.props.match.params.guid);
    }
    if (
      this.props.statusAddContract !== prevProps.statusAddContract &&
      this.props.statusAddContract === REQUEST_STATUSES.GOT
    ) {
      this.props.getContractBySupplier(this.props.match.params.guid);
    }
  }

  onBackClick = () => {
    this.props.history.push(`/supplier/supply_contracts?page=1`);
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
  };

  onSignClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    this.props.history.push({
      pathname: `/supplier/settings/certificates/edit`
    });
  };

  handlePaymentDefermentChange = (name: string) => {
    if (name === 'active') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: true
      }));
    } else if (name === 'inActive') {
      this.setState(prevState => ({
        ...prevState,
        isPaymentDefermentFixed: false
      }));
    }
  };

  render() {
    const {
      status,
      supplyContract,
      documentsUploadStatus,
      supplierContract,
      bankAccount
    } = this.props;

    return (
      <ContractsInfoPageStyled>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {documentsUploadStatus === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <ContractsContainer>
            <ContractsTopSection>
              <ContractsTopLeftContainer>
                <ContractsHeader>
                  <h1>
                    Договор № {supplyContract.contractNumber} от{' '}
                    {format(
                      new Date(supplyContract.contractStartDate),
                      'dd.MM.yyyy'
                    )}
                  </h1>
                  <BackBtn onClick={() => this.onBackClick()}>
                    <BackBtnText>Назад</BackBtnText>
                  </BackBtn>
                </ContractsHeader>
                <ContractsInfoArea>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle style={{ width: '150px' }}>
                      Покупатель:
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.debtorInn}</p>
                    <p>{supplyContract.debtorName}</p>
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>
                <ContractsInfoArea>
                  <h1>Реквизиты договора:</h1>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Наименование договора
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.contractName}</p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Номер договора
                    </ContractsInfoItemTitle>
                    <p>{supplyContract.contractNumber}</p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Дата договора
                    </ContractsInfoItemTitle>
                    <p>
                      {format(
                        new Date(supplyContract.contractStartDate),
                        'dd.MM.yyyy'
                      )}
                    </p>
                  </ContractsInfoItemContainer>
                  <ContractsInfoItemContainer>
                    <ContractsInfoItemTitle>
                      Срок действия договора
                    </ContractsInfoItemTitle>
                    {supplyContract.isPerpetual == true ? (
                      <p>Бессрочный</p>
                    ) : (
                      <p>
                        {format(
                          new Date(supplyContract.contractStartDate),
                          'dd.MM.yyyy'
                        )}{' '}
                        -{' '}
                        {format(
                          new Date(supplyContract.contractEndDate),
                          'dd.MM.yyyy'
                        )}
                      </p>
                    )}
                  </ContractsInfoItemContainer>
                </ContractsInfoArea>

                <ContractsInfoArea>
                  <h1>Условия оплаты:</h1>
                  {supplyContract.paymentDefermentFixed !== null ? (
                    <ContractsInfoItemContainer>
                      <ContractsInfoItemTitle>
                        Отсрочка платежа
                      </ContractsInfoItemTitle>
                      <p>
                        {supplyContract.paymentDeferment
                          ? `Фиксированная, ${
                              supplyContract.paymentDeferment
                            } дн.`
                          : 'Плавающая'}
                      </p>
                    </ContractsInfoItemContainer>
                  ) : (
                    <>
                      <ContractsInfoItemTitle>
                        Отсрочка платежа
                      </ContractsInfoItemTitle>
                      <ContractsInfoItemContainer style={{ height: '25px' }}>
                        {this.state.isPaymentDefermentFixed === true ? (
                          <SelectContainerActive>
                            <SelectActive />
                          </SelectContainerActive>
                        ) : (
                          <SelectContainerInactive
                            onClick={() =>
                              this.handlePaymentDefermentChange('active')
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactive>
                        )}
                        <p>фиксированная</p>
                        {this.state.isPaymentDefermentFixed && (
                          <>
                            <input
                              className="short-input"
                              type="number"
                              value={this.state.paymentDeferment}
                              onChange={e => {
                                this.setState({
                                  paymentDeferment: +e.currentTarget.value
                                });
                              }}
                            />
                            <p>дней</p>
                          </>
                        )}
                      </ContractsInfoItemContainer>
                      <ContractsInfoItemContainer style={{ height: '25px' }}>
                        {this.state.isPaymentDefermentFixed === false ? (
                          <SelectContainerActive>
                            <SelectActive />
                          </SelectContainerActive>
                        ) : (
                          <SelectContainerInactive
                            onClick={() =>
                              this.handlePaymentDefermentChange('inActive')
                            }
                          >
                            <SelectInactive />
                          </SelectContainerInactive>
                        )}
                        <p>плавающая</p>
                      </ContractsInfoItemContainer>
                    </>
                  )}

                  {this.state.isPaymentDefermentFixed !== null && (
                    <Button
                      label="Сохранить"
                      onClick={() =>
                        this.props.patchPurchaseContract(
                          this.props.match.params.guid,
                          {
                            paymentDeferment: this.state.paymentDeferment,
                            paymentDefermentFixed: this.state
                              .isPaymentDefermentFixed
                          }
                        )
                      }
                      disabled={
                        this.state.isPaymentDefermentFixed === true &&
                        !this.state.paymentDeferment
                      }
                      h="30px"
                    />
                  )}
                </ContractsInfoArea>

                <AccountContainer>
                  <h1>Счет плательщика:</h1>

                  {supplierContract.find(item => item.type === 1) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 1)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 1)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 1)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 1)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {supplierContract.find(item => item.type === 1)
                          .confirmedBySupplier !== 2 && (
                          <div>
                            <>
                              <Button
                                label="Подтвердить счет"
                                onClick={() =>
                                  this.props.postConfirmContractBySupplier(
                                    supplierContract.find(
                                      item => item.type === 1
                                    ).bankAccountGuid,
                                    this.props.match.params.guid,
                                    { type: 1, action: 'confirm' }
                                  )
                                }
                              />
                              <Button
                                label="Отклонить счет"
                                onClick={() =>
                                  this.props.postConfirmContractBySupplier(
                                    supplierContract.find(
                                      item => item.type === 1
                                    ).bankAccountGuid,
                                    this.props.match.params.guid,
                                    { type: 1, action: 'reject' }
                                  )
                                }
                              />
                            </>
                          </div>
                        )}

                        {supplierContract.find(item => item.type === 1)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Вы подтвердили счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 1)
                          .confirmedBySupplier === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Вы отклонили счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 1)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Покупатель подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 1)
                          .confirmedByDebtor === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Покупатель еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 1)
                          .confirmedByDebtor === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Покупатель отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <p>Плательщик не добавил реквизиты счета</p>
                  )}
                </AccountContainer>

                <AccountContainer>
                  <h1>Счет получателя:</h1>

                  {supplierContract.find(item => item.type === 2) ? (
                    <AccountInfoContainer>
                      <div>
                        <AccountInfo>
                          <p>р/с</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 2)
                                .bankAccounts.accountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>в</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 2)
                                .bankAccounts.bankName
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>к/с</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 2)
                                .bankAccounts.correspondentAccountNumber
                            }
                          </p>
                        </AccountInfo>
                        <AccountInfo>
                          <p>БИК</p>
                          <p>
                            {
                              supplierContract.find(item => item.type === 2)
                                .bankAccounts.bankBik
                            }
                          </p>
                        </AccountInfo>
                      </div>

                      <div>
                        {supplierContract.find(item => item.type === 2)
                          .confirmedBySupplier === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Вы подтвердили счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 2)
                          .confirmedByDebtor === 2 && (
                          <ConfirmedStatus bgColor={'#d0e9ff'}>
                            Покупатель подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 2)
                          .confirmedByDebtor === null && (
                          <ConfirmedStatus bgColor={lightGray}>
                            Покупатель еще не подтвердил счет
                          </ConfirmedStatus>
                        )}
                        {supplierContract.find(item => item.type === 2)
                          .confirmedByDebtor === 1 && (
                          <ConfirmedStatus bgColor={orange}>
                            Покупатель отклонил счет
                          </ConfirmedStatus>
                        )}
                      </div>
                    </AccountInfoContainer>
                  ) : (
                    <Button
                      label="Добавить"
                      onClick={() => {
                        this.props.openModal({
                          name: MODAL_NAMES.PAYER_ACCOUNT
                        });
                        this.props.getBankAccount();
                      }}
                    />
                  )}
                </AccountContainer>
              </ContractsTopLeftContainer>

              {supplyContract.contraceFileIdentifyer && (
                <BtnsBlock>
                  {supplyContract.supplierSignatureFileName ? (
                    ''
                  ) : (
                    <BtnsBlockSignBtn onClick={this.onSignClick}>
                      Подписать ЭЦП
                    </BtnsBlockSignBtn>
                  )}
                  <BtnsBlockDownloadDocument
                    href={SCFApi.downloadSupplyContractAgreementFile(
                      this.state.pageUrl
                    )}
                  >
                    Скачать документ
                  </BtnsBlockDownloadDocument>
                  {supplyContract.debtorSignatureFileName && (
                    <BtnsBlockDownloadSignature>
                      <BtnsBlockDownloadSignatureTopContainer
                        href={SCFDebtorApi.downloadDebtorPurchaseContractSignatureFile(
                          this.state.pageUrl
                        )}
                      >
                        <p>Скачать подпись покупателя</p>
                      </BtnsBlockDownloadSignatureTopContainer>
                    </BtnsBlockDownloadSignature>
                  )}
                  {supplyContract.supplierSignatureFileName && (
                    <BtnsBlockDownloadSignature>
                      <BtnsBlockDownloadSignatureTopContainer
                        href={SCFSupplierApi.downloadSupplierPurchaseContractSignatureFile(
                          this.state.pageUrl
                        )}
                      >
                        <p>Скачать мою подпись</p>
                      </BtnsBlockDownloadSignatureTopContainer>
                    </BtnsBlockDownloadSignature>
                  )}
                </BtnsBlock>
              )}
            </ContractsTopSection>

            {supplyContract.contraceFileIdentifyer ? (
              <div
                id="documentObject"
                style={{ width: '900px', height: '1000px' }}
              />
            ) : (
              <div>
                <InputFile
                  label=""
                  name="registry"
                  required={false}
                  files={
                    this.state.filesDisplay !== null
                      ? this.state.filesDisplay
                      : undefined
                  }
                  multiple={true}
                  uploadStatus={documentsUploadStatus}
                  onFilesUpload={this.onFilesUpload}
                  inputFileTypesDescription="PDF"
                  inputFileFormatDescription="файл договора"
                  accept=".pdf"
                />
                {this.state.filesDisplay !== null ? (
                  <AddBtn onClick={this.sendContractDocument}>
                    <AddBtnText>Загрузить договор</AddBtnText>
                  </AddBtn>
                ) : (
                  <AddBtn
                    style={{
                      backgroundColor: '#9FA6B2',
                      cursor: 'not-allowed'
                    }}
                  >
                    <AddBtnText>Загрузить договор</AddBtnText>
                  </AddBtn>
                )}
              </div>
            )}

            <CertificatesPopup id="certificatesPopup">
              <PopupExit>
                <p onClick={this.onCloseIconClick}>X</p>
              </PopupExit>
              <h1>Список сертификатов в системе</h1>
              <CertificatesArea>
                <CertificatesListPopup
                  guid={this.state.pageUrl}
                  pageUrl={this.state.pageUrl}
                />
              </CertificatesArea>
              <NudgeArea>
                <p>Нет нужного сертификата? </p>
                <Nudge onClick={this.onNudgeClick}>
                  Перейдите в настройки добаления
                </Nudge>
              </NudgeArea>
            </CertificatesPopup>
            <CertificatesPopupError id="certificatesPopupError">
              <PopupExitError>
                <p onClick={this.onCloseErrorIconClick}>X</p>
              </PopupExitError>
              <h1>
                Невозможно подписать выбранным сертификатом
                <br />
                (не найден на устройстве)
              </h1>
            </CertificatesPopupError>
          </ContractsContainer>
        )}

        <Modal name={MODAL_NAMES.PAYER_ACCOUNT}>
          <DiscountingFormModal>
            <p>Выберите счет получателя по договору</p>

            <ContractsInfoArea>
              <h1>Банковские счета:</h1>

              <TableStyledWrapper sizes={[]} cellSpacing="0" cellPadding="0">
                <TableHeaderStyled>
                  <tr>
                    <TableThStyled width="65%">Номер счета</TableThStyled>
                    <TableThStyled width="35%">Банк</TableThStyled>
                  </tr>
                </TableHeaderStyled>
                <tbody>
                  {bankAccount.items.map(bank => (
                    <TableRowStyled
                      key={bank.id}
                      onClick={() => {
                        this.setState({ selectedPayerContract: bank.guid });
                      }}
                    >
                      <td>{bank.accountNumber}</td>
                      <td>{bank.bankName}</td>
                    </TableRowStyled>
                  ))}
                </tbody>
              </TableStyledWrapper>
            </ContractsInfoArea>

            <Button
              label="+ Добавить"
              disabled={this.state.selectedPayerContract === null}
              onClick={() => {
                this.props.closeModal();
                this.setState({ selectedPayerContract: null });
                this.props.postAddToContractBySupplier(
                  this.state.selectedPayerContract,
                  this.props.match.params.guid
                );
              }}
            />

            <PayerContractDescription>
              Нет нужного счета?{' '}
              <span onClick={() => window.open(`/supplier/settings`, '_blank')}>
                Добавьте счет в настройках
              </span>
            </PayerContractDescription>
          </DiscountingFormModal>
        </Modal>
      </ContractsInfoPageStyled>
    );
  }
}

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  supplyContract: SCF.getSupplyContractByGuid.data,
  status: SCF.getSupplyContractByGuid.status,
  error: SCF.getSupplyContractByGuid.error,
  documentsUploadStatus: SCFSupplier.uploadSupplierContractDocument.status,
  statusPatchPurchaseContract: SCFSupplier.patchPurchaseContractSupplier.status,
  supplierContract: SCFSupplier.getContractBySupplier.data,
  statusSupplierContract: SCFSupplier.getContractBySupplier.status,
  bankAccount: SCF.getBankAccount.data,
  statusAddContract: SCF.postAddToContractBySupplier.status,
  statusConfirmContractSupplier:
    SCFSupplier.postConfirmContractBySupplier.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplyContract,
      uploadContractDocument,
      patchPurchaseContract,
      getContractBySupplier,
      postAddToContractBySupplier,
      getBankAccount,
      openModal,
      closeModal,
      postConfirmContractBySupplier
    },
    dispatch
  );

const ContractsInfoPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractsInfoPage)
);

export { ContractsInfoPageConnect as ContractsInfoPage };
