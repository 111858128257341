import { POST_ADD_FORM_TO_SUPPLY_CONTRACTS } from 'entities/SCF/Debtor/model/reducers/postAddFormToSupplyContracts';

export interface RequestDataType {
  SupplyContractGuid: string;
  DocumentFormGuid: string;
}

export const req = (data: RequestDataType) => ({
  type: POST_ADD_FORM_TO_SUPPLY_CONTRACTS.REQ,
  data
});

export const got = () => ({
  type: POST_ADD_FORM_TO_SUPPLY_CONTRACTS.GOT
});

export const err = (error: {}) => ({
  type: POST_ADD_FORM_TO_SUPPLY_CONTRACTS.ERR,
  error
});
