import * as React from 'react';
import { connect } from 'react-redux';
import { STORE, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { HOLDING_LIST_TYPE } from '../../types';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getHoldingsList,
  ResponseData as getHoldingsListResponseData,
  RequestData as getHoldingsListRequestData
} from '../../actions/getHoldingsList';
import { history } from 'src/shared/utils/History';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import { HoldingRead } from '../../actions/setHoldingFormData';

interface StateToProps {
  holdings: getHoldingsListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getHoldingsList: (
    listType: HOLDING_LIST_TYPE,
    data: getHoldingsListRequestData
  ) => void;
}

interface OwnProps {
  listType: HOLDING_LIST_TYPE;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class HoldingsList extends React.Component<Props> {
  componentDidMount() {
    const { listType, getHoldingsList } = this.props;

    // TODO duplication with applicationslist
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    getHoldingsList(listType, { page, pageSize: 20 });
  }

  onHoldingClick(index: number) {
    history.push(`/crm/holdings/${this.props.holdings.items[index].id}`);
  }

  render() {
    const { holdings, status, error } = this.props;
    return (
      <ScrollTopComponent>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="6%">id</TableThStyled>
                <TableThStyled width="12%">Наименование</TableThStyled>
                <TableThStyled width="9%">Отрасль</TableThStyled>
                <TableThStyled width="40%">Иная информация</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {holdings.items.map((holding, key) => (
                <TableRowStyled
                  onClick={() => this.onHoldingClick(key)}
                  key={holding.id}
                >
                  <td>{holding.id}</td>
                  <td>{holding.holdingName}</td>
                  <td>{holding.industryId}</td>
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
        <Pagination list={holdings} />

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ScrollTopComponent>
    );
  }

  renderStatus(holding: HoldingRead) {}
}

const mapStateToProps = ({ Holdings, User }: STORE) => ({
  holdings: Holdings.getHoldingsList.data,
  status: Holdings.getHoldingsList.status,
  error: Holdings.getHoldingsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getHoldingsList
    },
    dispatch
  );

const HoldingsListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(HoldingsList);

export { HoldingsListConnect as HoldingsList };
