import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import { history } from 'src/shared/utils/History';
import {
  req as confirmAccountRequest,
  RequestDataType
} from 'User/actions/confirmAccount';
import { req as reConfirmAccountRequest } from 'User/actions/reConfirmAccount';

import { SuccessIcon, SandClockIcon } from 'shared/styled';
import { ConfirmAccountPageStyled } from './styles';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  confirmStatus: REQUEST_STATUSES;
  reConfirmStatus: REQUEST_STATUSES;
  confirmError: ResponseError;
  reConfirmError: ResponseError;
}

interface DispatchToProps {
  confirmAccountRequest: (data: RequestDataType) => void;
  reConfirmAccountRequest: (userId: string) => void;
}

class ConfirmAccountPage extends React.Component<
  DispatchToProps & StateToProps
> {
  componentDidMount() {
    const searchParams = new URLSearchParams(location.search);

    this.props.confirmAccountRequest({
      userId: searchParams.get('userId'),
      token: searchParams.get('token')
    });
  }

  reConfirmAccount = () => {
    const searchParams = new URLSearchParams(location.search);
    this.props.reConfirmAccountRequest(searchParams.get('userId'));
  };

  defineReConfirmResponseScreen = () => {
    const { reConfirmStatus, reConfirmError } = this.props;

    if (reConfirmStatus === REQUEST_STATUSES.GOT) {
      return (
        <ActionResponseBlock
          title="Время подтверждения регистрации истекло"
          text={
            <React.Fragment>
              Время на подтверждение адреса электронной почты истекло. <br />
              Можете повторно отправить запрос на подтверждение вашей <br />
              электронной почты.
            </React.Fragment>
          }
          icon={<SandClockIcon />}
          button={
            <Button template="nextBtn" label="Отправлено" disabled={true} />
          }
        />
      );
    }

    if (reConfirmStatus === REQUEST_STATUSES.ERROR) {
      switch (reConfirmError.code) {
        case 400:
        case 500:
          return (
            <ActionResponseBlock
              template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
            />
          );
        case 602:
          return (
            <ActionResponseBlock
              title="Учетная запись уже подтверждена"
              text={
                <React.Fragment>
                  Ваш адрес электронной почты уже подтвержден. <br />
                  Теперь войдите в ваш личный кабинет.
                </React.Fragment>
              }
              button={
                <Button
                  template="nextBtn"
                  label="Войти в личный кабинет"
                  onClick={() => history.push('/login')}
                />
              }
            />
          );
      }
    }
  };

  defineConfirmResponseScreen = () => {
    const { confirmStatus, confirmError } = this.props;

    if (confirmStatus === REQUEST_STATUSES.GOT) {
      return (
        <ActionResponseBlock
          title="Ваша учетная запись успешно подтверждена"
          text={
            <React.Fragment>
              Спасибо за подтверждение вашего адреса.
              <br />
              Теперь войдите в ваш личный кабинет.
            </React.Fragment>
          }
          icon={<SuccessIcon />}
          button={
            <Button
              template="nextBtn"
              label="Войти в личный кабинет"
              onClick={() => history.push('/login')}
            />
          }
        />
      );
    }

    if (confirmStatus === REQUEST_STATUSES.ERROR) {
      switch (confirmError.code) {
        case 400:
        case 500:
          return (
            <ActionResponseBlock
              template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
            />
          );
        case 602:
          return (
            <ActionResponseBlock
              title="Учетная запись уже подтверждена"
              text={
                <React.Fragment>
                  Ваш адрес электронной почты уже подтвержден. <br />
                  Теперь войдите в ваш личный кабинет.
                </React.Fragment>
              }
              button={
                <Button
                  template="nextBtn"
                  label="Войти в личный кабинет"
                  onClick={() => history.push('/login')}
                />
              }
            />
          );
        case 603:
          return (
            <ActionResponseBlock
              title="Время подтверждения регистрации истекло"
              text={
                <React.Fragment>
                  Время на подтверждение адреса электронной почты истекло.{' '}
                  <br />
                  Можете повторно отправить запрос на подтверждение вашей <br />
                  электронной почты.
                </React.Fragment>
              }
              icon={<SandClockIcon />}
              button={
                <Button
                  template="nextBtn"
                  label="Отправить повторно"
                  onClick={this.reConfirmAccount}
                />
              }
            />
          );
      }
    }
  };

  render() {
    const { confirmStatus, reConfirmStatus } = this.props;

    const showConfirmScreen =
      reConfirmStatus === REQUEST_STATUSES.NONE &&
      confirmStatus !== REQUEST_STATUSES.NONE;
    const showReConfirmScreen = reConfirmStatus !== REQUEST_STATUSES.NONE;

    return (
      <ConfirmAccountPageStyled>
        {(confirmStatus === REQUEST_STATUSES.REQUEST ||
          reConfirmStatus === REQUEST_STATUSES.REQUEST) && <Loader />}

        {showConfirmScreen && this.defineConfirmResponseScreen()}
        {showReConfirmScreen && this.defineReConfirmResponseScreen()}
      </ConfirmAccountPageStyled>
    );
  }
}

const mapStateToProps = ({ User }: STORE) => ({
  confirmStatus: User.confirmAccount.status,
  confirmError: User.confirmAccount.error,
  reConfirmStatus: User.reConfirmAccount.status,
  reConfirmError: User.reConfirmAccount.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { confirmAccountRequest, reConfirmAccountRequest },
    dispatch
  );

const ConfirmAccountPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAccountPage);

export { ConfirmAccountPageConnect as ConfirmAccountPage };
