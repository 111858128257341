import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Supplier/model/actions/putAddSignee';

import { PUT_ADD_SIGNEE } from 'entities/SCF/Supplier/model/reducers/putAddSignee';

import SCFApi from 'entities/SCF/Supplier/api';

function* putAddSignee(action: {
  type: PUT_ADD_SIGNEE;
  guid: string;
  signeeGuid: string;
}) {
  try {
    yield call(SCFApi.putAddSignee, action.guid, action.signeeGuid);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putAddSigneeSaga() {
  yield takeLatest(PUT_ADD_SIGNEE.REQ, putAddSignee);
}
