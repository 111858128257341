import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getFactoringContractDebtorRoleByGuid';

export interface GetFactoringContractDebtorRoleByGuidStoreState
  extends Reducer {
  data: ResponseDataType;
}

export enum GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID {
  GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_REQ = 'GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_REQ',
  GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_GOT = 'GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_GOT',
  GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_ERR = 'GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_ERR'
}

export const initialState: GetFactoringContractDebtorRoleByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    id: '',
    supplierInn: '',
    supplierName: '',
    factorInn: '',
    factorName: '',
    contractName: '',
    contractNumber: '',
    contractStartDate: '',
    contractEndDate: '',
    isPerpetual: false,
    cessionMinimalShare: null,
    cessionMaximalShare: null,
    financeMinimalShare: null,
    financeMaximalShare: null,
    isRegressed: false,
    isNotHidden: false,
    gracePeriod: null,
    moveGracePeriodToBusinessDay: false,
    regressPeriod: null,
    moveRegressPeriodToBusinessDay: false,
    contraceFileIdentifyer: undefined,
    contraceFileName: '',
    supplierSignatureIdentifier: undefined,
    supplierSignatureFileName: '',
    debtorSignatureIdentifier: undefined,
    debtorSignatureFileName: '',
    factorSignatureIdentifier: undefined,
    factorSignatureFileName: ''
  },
  error: {}
};

const getFactoringContractDebtorRoleByGuid = (
  state: GetFactoringContractDebtorRoleByGuidStoreState = initialState,
  action: {
    type: GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID;
    data: ResponseDataType;
    error: {};
  }
): GetFactoringContractDebtorRoleByGuidStoreState => {
  switch (action.type) {
    case GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID.GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID.GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID.GET_FACTORING_CONTRACT_DEBTOR_ROLE_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getFactoringContractDebtorRoleByGuid;
