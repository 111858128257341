import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/changeLeadStatus';
import { RequestData } from '../actions/changeLeadStatus';
import { history } from 'src/shared/utils/History';

import { CHANGE_LEAD_STATUS } from '../reducers/changeLeadStatus';
import LeadsApi from '../api';
import { req as getLead } from '../actions/getLead';

function* changeLeadStatus(action: {
  type: CHANGE_LEAD_STATUS;
  data: RequestData;
}): Generator {
  try {
    yield call(LeadsApi.changeLeadStatus, action.data);

    yield put(got());
    yield put(getLead(action.data.leadId));

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    history.push(`/cabinet/${role}/lead/${action.data.leadId}`);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* changeLeadStatusSaga(): Generator {
  yield takeLatest(CHANGE_LEAD_STATUS.REQ, changeLeadStatus);
}
