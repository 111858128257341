export enum POLICY_UNPROFITABLE_COMPANIES {
  '6 - Может быть одобрено без залога и поручительств при большом убытке' = '6 - Может быть одобрено без залога и поручительств при большом убытке',
  '5 - Может быть одобрено без залога и поручительств при небольшом убытке' = '5 - Может быть одобрено без залога и поручительств при небольшом убытке',
  '4 - Да, достаточно поручительства действующего бизнеса' = '4 - Да, достаточно поручительства действующего бизнеса',
  '3 - Да, достаточно залога' = '3 - Да, достаточно залога',
  '2 - Да, с залогом и поручительством действующего бизнеса' = '2 - Да, с залогом и поручительством действующего бизнеса',
  '1 - Строго нет' = '1 - Строго нет'
}

export enum POLICY_NEGATIVE_EQUITY {
  '6 - Может быть одобрено без залога и поручительств при большом отрицательном капитале' = '6 - Может быть одобрено без залога и поручительств при большом отрицательном капитале',
  '5 - Может быть одобрено без залога и поручительств при небольшом отрицательном капитале' = '5 - Может быть одобрено без залога и поручительств при небольшом отрицательном капитале',
  '4 - Да, достаточно поручительства действующего бизнеса' = '4 - Да, достаточно поручительства действующего бизнеса',
  '3 - Да, достаточно залога' = '3 - Да, достаточно залога',
  '2 - Да, с залогом и поручительством действующего бизнеса' = '2 - Да, с залогом и поручительством действующего бизнеса',
  '1 - Строго нет' = '1 - Строго нет'
}
