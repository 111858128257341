import { PATCH_CABINET_PLEDGE } from '../reducers/patchCabinetPledge';

export const req = (guid: string, data: any) => ({
  type: PATCH_CABINET_PLEDGE.REQ,
  guid,
  data
});

export const got = () => ({
  type: PATCH_CABINET_PLEDGE.GOT
});

export const err = (error: {}) => ({
  type: PATCH_CABINET_PLEDGE.ERR,
  error
});
