import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PutRejectSupplierStoreState = Reducer;

export enum PUT_REJECT_SUPPLIER {
  REQ = 'PUT_REJECT_SUPPLIER_REQ',
  GOT = 'PUT_REJECT_SUPPLIER_GOT',
  ERR = 'PUT_REJECT_SUPPLIER_ERR'
}

export const initialState: PutRejectSupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putRejectSupplier = (
  state: PutRejectSupplierStoreState = initialState,
  action: {
    type: PUT_REJECT_SUPPLIER;
    error: {};
  }
): PutRejectSupplierStoreState => {
  switch (action.type) {
    case PUT_REJECT_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_REJECT_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_REJECT_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putRejectSupplier;
