import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getActivitiesByLeadExternal';

import { GET_ACTIVITIES_BY_LEAD_EXTERNAL } from '../reducers/getActivitiesByLeadExternal';
import LeadsApi from '../api';

function* getActivitiesByLeadExternal(action: {
  type: GET_ACTIVITIES_BY_LEAD_EXTERNAL;
  leadId: string;
}): Generator {
  try {
    const res: any = yield call(
      LeadsApi.getActivitiesByLeadExternal,
      action.leadId
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(
    GET_ACTIVITIES_BY_LEAD_EXTERNAL.REQ,
    getActivitiesByLeadExternal
  );
}
