import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getPledge';

import { GET_PLEDGES_BANK } from '../reducers/getPledges';
import BankProductApi from '../api';

function* getPledges(action: {
  type: GET_PLEDGES_BANK;
  clientInn: string;
}): Generator {
  try {
    const res: any = yield call(BankProductApi.getPledge, action.clientInn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getPledgesSaga(): Generator {
  yield takeLatest(GET_PLEDGES_BANK.REQ, getPledges);
}
