import { call, put, takeLatest } from 'redux-saga/effects';
import UserApi from 'User/api';
import { got, err } from 'User/actions/getUserData';
import { USER_ACTIONS } from 'User/reducers/getUserData';

function* getUserData(action: { type: USER_ACTIONS }): Generator {
  try {
    const res: any = yield call(UserApi.getUser);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserDataSaga(): Generator {
  yield takeLatest(USER_ACTIONS.USER_REQ, getUserData);
}
