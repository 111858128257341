import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostNotFinFileStoreState extends Reducer {}

export enum POST_NOT_FIN_FILE {
  REQ = 'POST_NOT_FIN_FILE_REQ',
  GOT = 'POST_NOT_FIN_FILE_GOT',
  ERR = 'POST_NOT_FIN_FILE_ERR'
}

export const initialState: PostNotFinFileStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postNotFinFile = (
  state: PostNotFinFileStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): PostNotFinFileStoreState => {
  switch (action.type) {
    case POST_NOT_FIN_FILE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_NOT_FIN_FILE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_NOT_FIN_FILE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postNotFinFile;
