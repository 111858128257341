import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  AskStyled,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/CooperationForProducts/components/styled';
import { req as patchContract } from '../actions/patchContract';
import { req as patchCabinetContract } from '../actions/patchCabinetContract';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { POLICY } from 'src/features/ContractLogic/types';
import {
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import { ProductPolicyContainer } from 'src/features/ContractLogic/components/styled';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  isFor44: boolean | null;
  isFor223: boolean | null;
  isFor615: boolean | null;
  isForGoz: boolean | null;
  isForCommercialContracts: boolean | null;
  commercialBeneficiariesListPolicy: POLICY | number;
  activeCommercialBeneficiariesListPolicy: boolean;
  isAdminCommercialBeneficiariesListPolicy: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchContract: (id: string, data: any) => void;
  patchCabinetContract: (guid: string, data: any) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class ContractLogigForProduct extends React.Component<Props, State> {
  state: State = {
    isFor44: null,
    isFor223: null,
    isFor615: null,
    isForGoz: null,
    isForCommercialContracts: null,
    commercialBeneficiariesListPolicy: null,
    activeCommercialBeneficiariesListPolicy: false,
    isAdminCommercialBeneficiariesListPolicy: false
  };

  componentDidMount() {
    if (this.props.product.commercialBeneficiariesListPolicy) {
      this.setState({
        commercialBeneficiariesListPolicy: this.props.product
          .commercialBeneficiariesListPolicy,
        activeCommercialBeneficiariesListPolicy: true
      });
    }
    this.setState({ ...this.props.product } as any);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      prevState.commercialBeneficiariesListPolicy !==
      this.state.commercialBeneficiariesListPolicy
    ) {
      this.setState({ isAdminCommercialBeneficiariesListPolicy: false });
    }
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    this.setState({ [key]: value } as Pick<any, keyof State>);
    if (
      this.props.roles.includes('Bank') &&
      key === 'activeCommercialBeneficiariesListPolicy'
    ) {
      this.props.patchCabinetContract(id, {
        commercialBeneficiariesListPolicy: +this.state
          .commercialBeneficiariesListPolicy[0]
      });
    } else if (this.props.roles.includes('Bank')) {
      this.props.patchCabinetContract(id, { [key]: value });
    } else if (key === 'activeCommercialBeneficiariesListPolicy') {
      this.props.patchContract(id, {
        commercialBeneficiariesListPolicy: +this.state
          .commercialBeneficiariesListPolicy[0]
      });
    } else {
      this.props.patchContract(id, { [key]: value });
    }
  };

  handleProductPolicyClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    if (!this.state.activeCommercialBeneficiariesListPolicy || isAdmin) {
      this.setState({ commercialBeneficiariesListPolicy: item });
    }
  };

  renderToggleButton = (buttonName: keyof State, description: string) => {
    const { roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, false)
          }
          disabled={!isAdmin && this.state[buttonName] === true}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, true)
          }
          disabled={!isAdmin && this.state[buttonName] === false}
          active={this.state[buttonName]}
          isAdmin={isAdmin}
        >
          да
        </ToggleButtonYes>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    return (
      <ApplicationViewStyled>
        <AskStyled>Тип контракта:</AskStyled>
        {this.renderToggleButton('isFor44', '44-ФЗ')}
        {this.renderToggleButton('isFor223', '223-ФЗ')}
        {this.renderToggleButton('isFor615', '615-п')}
        {this.renderToggleButton('isForGoz', 'ГосОборонЗаказ')}
        {this.renderToggleButton('isForCommercialContracts', 'Коммерческий')}

        <ProductPolicyContainer
          product={this.state.commercialBeneficiariesListPolicy}
          active={this.state.activeCommercialBeneficiariesListPolicy}
        >
          <AskStyled>Политика в отношении коммерческих бенефициаров:</AskStyled>
          {Object.keys(POLICY).map(item => (
            <div key={item}>
              <ProductItem onClick={() => this.handleProductPolicyClick(item)}>
                <Square
                  active={
                    this.state.commercialBeneficiariesListPolicy === item ||
                    this.state.commercialBeneficiariesListPolicy == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.commercialBeneficiariesListPolicy === item &&
                (!this.state.activeCommercialBeneficiariesListPolicy ||
                  isAdmin) &&
                !this.state.isAdminCommercialBeneficiariesListPolicy && (
                  <Button
                    label="Сохранить"
                    disabled={
                      !isAdmin &&
                      this.state.activeCommercialBeneficiariesListPolicy
                    }
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({
                          isAdminCommercialBeneficiariesListPolicy: true
                        });
                      }
                      this.handleButtonClick(
                        'activeCommercialBeneficiariesListPolicy',
                        true
                      );
                    }}
                    h='40px'
                  />
                )}
            </div>
          ))}
        </ProductPolicyContainer>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Contract }: CRM) => ({
  status: Contract.patchContract.status,
  error: Contract.patchContract.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchContract, patchCabinetContract }, dispatch);

const ContractLogigForProductConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractLogigForProduct)
);

export { ContractLogigForProductConnect as ContractLogigForProduct };
