import { call, put, takeLatest } from 'redux-saga/effects';

import UserApi from 'User/api';
import { got, err, RequestDataType } from 'User/actions/confirmAccount';
import { CONFIRM_ACCOUNT } from 'User/reducers/confirmAccount';

function* confirmAccount(action: {
  type: CONFIRM_ACCOUNT;
  data: RequestDataType;
}) {
  try {
    const res:any = yield call(UserApi.confirmAccount, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* confirmAccountSaga() {
  yield takeLatest(CONFIRM_ACCOUNT.CONFIRM_ACCOUNT_REQ, confirmAccount);
}
