import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getProperties } from "Properties/actions/getProperties";
import { PropertyRead } from '../../types/Property';
import { Col, Row } from 'react-grid-system';
import PropertyView from '../PropertyView/PropertyView';

interface StateToProps {
    properties: Partial<PropertyRead>[];
}

interface DispatchToProps {
    getProperties: (applicationId: number) => void;
}

interface OwnProps {
    applicationId: number;
}

type Props = OwnProps & StateToProps & DispatchToProps;

class ViewProperties extends React.Component<Props> {
    componentDidMount() {
        this.props.getProperties(this.props.applicationId);
    }
    render() {
        const { properties } = this.props;
        return (
            <Row>
                {properties.map((property) =>
                (<Col lg={6} xl={2} sm={12}>
                    <PropertyView
                        property={property} />
                </Col>))}
            </Row>
        )
    }
}

const mapStateToProps = ({ Properties }: STORE) => ({
    properties: Properties.localProperties.properties,
    getPropertiesStatus: Properties.getProperties.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getProperties,
        }, dispatch);

const ViewPropertiesConnect = connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
)(ViewProperties);

export { ViewPropertiesConnect as ViewProperties };
