import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getApplicationsCompany';

export interface GetApplicationsCompanyStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_APPLICATIONS_COMPANY {
  REQ = 'GET_APPLICATIONS_COMPANY_REQ',
  GOT = 'GET_APPLICATIONS_COMPANY_GOT',
  ERR = 'GET_APPLICATIONS_COMPANY_ERR'
}

export const initialState: GetApplicationsCompanyStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalQty: 0,
    items: []
  },
  error: {}
};

const getApplicationsCompany = (
  state: GetApplicationsCompanyStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetApplicationsCompanyStoreState => {
  switch (action.type) {
    case GET_APPLICATIONS_COMPANY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_APPLICATIONS_COMPANY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_APPLICATIONS_COMPANY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getApplicationsCompany;
