import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/putEarlyPaymentApplicationDeclineFactorRole';

import {
  PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE,
  RequestDataType
} from 'entities/SCF/Factor/model/reducers/putEarlyPaymentApplicationDeclineFactorRole';

import SCFApi from 'entities/SCF/Factor/api';

function* putEarlyPaymentApplicationDeclineFactorRole(action: {
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.putEarlyPaymentApplicationDeclineFactorRole,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putEarlyPaymentApplicationDeclineFactorRoleSaga() {
  yield takeLatest(
    PUT_EARLY_PAYMENT_APPLICATION_DECLINE_FACTOR_ROLE.REQ,
    putEarlyPaymentApplicationDeclineFactorRole
  );
}
