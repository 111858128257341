import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getCompaniesByHoldingList';

import {
  GET_COMPANIES_BY_HOLDING_LIST,
} from '../reducers/getCompaniesByHoldingList';
import CompaniesApi from '../api';

function* getCompaniesByHoldingList(action: {
  type: GET_COMPANIES_BY_HOLDING_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(CompaniesApi.getCompaniesByHoldingList, action.data.holdingId);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompaniesByHoldingListSaga(): Generator {
  yield takeLatest(GET_COMPANIES_BY_HOLDING_LIST.REQ, getCompaniesByHoldingList);
}
