import { CERTIFICATE_ACTIONS } from '../reducers/getCertificate';
import { Certificate } from '../types/certificate';

export interface ResponseDataType {
    page: number;
    pageSize: number;
    totalItems: number;
    items: Certificate[];
}

export const req = () => ({ type: CERTIFICATE_ACTIONS.REQ });
export const got = (data: ResponseDataType) => ({ type: CERTIFICATE_ACTIONS.GOT, data });
export const err = (error: {}) => ({
    type: CERTIFICATE_ACTIONS.ERR,
    error
});


