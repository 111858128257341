import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import {
  req as signIn,
  reset as resetSignIn,
  RequestDataType
} from 'User/actions/signIn';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { ForgotPasswordModal } from 'User/components';
import { AuthenticationForm } from './AuthenticationForm/AuthenticationForm';
import { PageWrapper, CenteringBlock, ErrorIcon } from 'shared/styled';
import { TitleStyled, LoginBox } from './styles';
import { Button } from 'src/shared/ui/Button';

interface State {
  submitClicked: boolean;
}

interface StateProps {
  signInStatus: REQUEST_STATUSES;
  signInError: ResponseError;
}

interface DispatchToProps {
  signIn: (data: RequestDataType) => void;
  resetSignIn: () => void;
}

type Props = StateProps & DispatchToProps;

class AuthenticationPageSCF extends React.Component<Props, State> {
  state = {
    submitClicked: false
  };

  componentWillUnmount() {
    this.props.resetSignIn();
  }

  defineErrorBlock = () => {
    switch (this.props.signInError.code) {
      case 600:
        return (
          <ActionResponseBlock
            title="Неправильный логин или пароль"
            text={
              <React.Fragment>
                Вы указали неправильный логин или пароль. <br />
                Пожалуйста, повторите ввод еще раз.
              </React.Fragment>
            }
            icon={<ErrorIcon />}
            button={
              <Button
                label="Войти в личный кабинет"
                onClick={this.props.resetSignIn}
              />
            }
          />
        );
      case 601:
        return (
          <ActionResponseBlock
            title="Аккаунт заблокирован"
            text={
              <React.Fragment>
                Убедитесь в правильности введенного логина, <br />
                либо обратитесь на горячую линию.
              </React.Fragment>
            }
            icon={<ErrorIcon />}
          />
        );
      default:
        return (
          <ActionResponseBlock
            template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
          />
        );
    }
  };

  render() {
    const { signIn, signInStatus } = this.props;
    const signInProcess = signInStatus === REQUEST_STATUSES.NONE;
    const signInRequest = signInStatus === REQUEST_STATUSES.REQUEST;
    const signInFailed = signInStatus === REQUEST_STATUSES.ERROR;

    return (
      <PageWrapper>
        {signInProcess && (
          <React.Fragment>
            <ForgotPasswordModal />

            <CenteringBlock>
              <LoginBox>
                <TitleStyled>Вход в личный кабинет</TitleStyled>
                <AuthenticationForm onSubmit={signIn} />
              </LoginBox>
            </CenteringBlock>
          </React.Fragment>
        )}

        {signInRequest && <Loader />}
        {signInFailed && this.defineErrorBlock()}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ User }: STORE): StateProps => ({
  signInStatus: User.signIn.status,
  signInError: User.signIn.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ signIn, resetSignIn }, dispatch);

const AuthenticationPageSupplierConnect = connect<StateProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationPageSCF);

export { AuthenticationPageSupplierConnect as AuthenticationPageFactor };
