import { POST_CREATE_CLIENT } from '../reducers/postCreateClient';

export interface RequestData {
  agentinn: number;
  companyinn: string;
  comment?: string;
}

export const req = ({ agentinn, companyinn, comment }: RequestData) => ({
  type: POST_CREATE_CLIENT.REQ,
  agentinn,
  companyinn,
  comment
});

export const got = () => ({
  type: POST_CREATE_CLIENT.GOT
});

export const err = (error = {}) => ({
  type: POST_CREATE_CLIENT.ERR,
  error
});
