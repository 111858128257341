import {
  RequestDataType,
  POST_AGREEMENT_SIGNATURE_FACTOR_ROLE
} from 'entities/SCF/Factor/model/reducers/postAgreementSignatureFactorRole';

export const req = (data: RequestDataType) => ({
  type: POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.REQ,
  data
});

export const got = () => ({
  type: POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.GOT
});

export const err = (error: {}) => ({
  type: POST_AGREEMENT_SIGNATURE_FACTOR_ROLE.ERR,
  error
});
