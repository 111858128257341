import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/ExternalAgent/model/actions/getTenderByApplicationExternal';

import { GET_TENDER_BY_APPLICATION_EXTERNAL } from 'entities/Cabinet/ExternalAgent/model/reducers/getTenderByApplicationExternal';
import ExternalAgentRoleApi from 'entities/Cabinet/ExternalAgent/api';

function* getTenderByApplicationExternal(action: {
  type: GET_TENDER_BY_APPLICATION_EXTERNAL;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      ExternalAgentRoleApi.getTenderByApplicationExternal,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getTenderByApplicationExternalSaga(): Generator {
  yield takeLatest(
    GET_TENDER_BY_APPLICATION_EXTERNAL.REQ,
    getTenderByApplicationExternal
  );
}
