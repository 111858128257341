import { ExternalCredentialsOfProductsData } from '../actions/setExternalCredentialsOfProductsFormData';
//import { RequestData as GetFactoringLimitsListRequestData } from '../actions/getFactoringLimitsList';
import ApiRequest from 'src/shared/utils/ApiRequest';

export default class ExternalCredentialsOfProductsApi {
  static sendExternalCredentialsOfProductsData(data: ExternalCredentialsOfProductsData) {
    return ApiRequest('/api/external_credentials_of_products', 'post', data);
  }

  static getExternalCredentialsOfProductsLink(id: number) {
    return ApiRequest(`/api/external_credentials_of_products/id/${id}`);
  }

  static getExternalCredentialsListByProduct(productId: number) {
    return ApiRequest(`/api/external_credentials_of_products/product/${productId}`);
  }

  static getProductsListByExternalCredential(externalCredentialId: number) {
    return ApiRequest(`/api/external_credentials_of_products/external_credential/${externalCredentialId}`);
  }
}
