import { POST_DOCUMENT_LIST_CLIENT } from 'entities/Cabinet/Client/model/reducers/postDocumentListClient';

export const req = (guid: string) => ({
  type: POST_DOCUMENT_LIST_CLIENT.REQ,
  guid
});

export const got = () => ({
  type: POST_DOCUMENT_LIST_CLIENT.GOT
});

export const err = (error: {}) => ({
  type: POST_DOCUMENT_LIST_CLIENT.ERR,
  error
});
