import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/DocumentList/actions/getDocumentList';

export interface GetDocumentListStoreState extends Reducer {
  data: ResponseData;
}

export enum GET_DOCUMENT_LIST {
  REQ = 'GET_DOCUMENT_LIST_REQ',
  GOT = 'GET_DOCUMENT_LIST_GOT',
  ERR = 'GET_DOCUMENT_LIST_ERR'
}

export const initialState: GetDocumentListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 1,
    pageSize: 0,
    totalItems: 0,
    items: [],
    limits: []
  },
  error: {}
};

const getDocumentList = (
  state: GetDocumentListStoreState = initialState,
  action: {
    type: string;
    data: ResponseData;
    error: {};
  }
): GetDocumentListStoreState => {
  switch (action.type) {
    case GET_DOCUMENT_LIST.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DOCUMENT_LIST.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_DOCUMENT_LIST.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDocumentList;
