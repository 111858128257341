import {
    RequestDataType,
    ADD_LIST_OF_LIMITS
  } from 'src/features/SCF/reducers/addListOfLimits';
  
  export const req = (data: RequestDataType) => ({
    type: ADD_LIST_OF_LIMITS.REQ,
    data
  });
  
  export const got = () => ({
    type: ADD_LIST_OF_LIMITS.GOT
  });
  
  export const err = (error: {}) => ({
    type: ADD_LIST_OF_LIMITS.ERR,
    error
  });
  