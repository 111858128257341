import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import {
  CircleBackground,
  CircleProgress,
  CircleWrapper,
  Dropdown,
  OtherMenu,
  Tab,
  TabsContainer,
  TooltipBoxStyle
} from './styled';

interface Props {
  handleActiveTab: (key: string) => void;
  getCountByKey?: (key: string) => void;
  activeTab: string;
  tabs: {};
  isSubMenu?: boolean;
  progressData?: Record<string, number>;
}

export const Menu: React.FC<Props> = ({
  activeTab,
  handleActiveTab,
  getCountByKey,
  tabs,
  isSubMenu,
  progressData
}) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const maxVisibleTabs = 8;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const visibleTabs = Object.entries(tabs).slice(0, maxVisibleTabs);
  const hiddenTabs = Object.entries(tabs).slice(maxVisibleTabs);

  const radius = 12;
  const circumference = 2 * Math.PI * radius;

  return (
    <TabsContainer>
      {visibleTabs.map(([key, val], index) => {
        const percentage = progressData ? progressData[key] : null;
        const offset =
          percentage !== null &&
          circumference - (percentage / 100) * circumference;

        return (
          <Tab
            key={index}
            onClick={() => {
              setShowMenu(false);
              handleActiveTab(key);
            }}
            active={activeTab === key}
            count={getCountByKey && getCountByKey(key)}
            isSubMenu={isSubMenu}
          >
            <p>{val}</p>
            {!!percentage && (
              <TooltipBoxStyle
                text={'Заполните отсутствующие данные финансовой отчетности'}
              >
                <CircleWrapper>
                  <svg width="30" height="30">
                    <CircleBackground r={radius} cx="15" cy="15" />
                    <CircleProgress
                      r={radius}
                      cx="15"
                      cy="15"
                      strokeDasharray={circumference}
                      strokeDashoffset={offset}
                    />
                  </svg>
                </CircleWrapper>
              </TooltipBoxStyle>
            )}
          </Tab>
        );
      })}

      {hiddenTabs.length > 0 && (
        <OtherMenu onClick={toggleMenu}>
          <p>Остальное меню</p>
          <FontAwesomeIcon icon={faCaretDown} />
        </OtherMenu>
      )}

      <Dropdown isOpen={showMenu}>
        {hiddenTabs.map(([key, val], index) => (
          <Tab
            key={index}
            onClick={() => {
              setShowMenu(false);
              handleActiveTab(key);
            }}
            active={activeTab === key}
            count={getCountByKey && getCountByKey(key)}
          >
            <p>{val}</p>
          </Tab>
        ))}
      </Dropdown>
    </TabsContainer>
  );
};
