import styled from 'styled-components';
import { rl } from 'shared/styled';
import { Button } from 'shared/ui/Button';

export const PersonContainer = styled.div`
  & > h2 {
    margin-bottom: 50px;
  }
`;

export const HeadContainer = styled.div`
  margin-bottom: 10px;
`;

export const RefreshDataButton = styled(Button)`
  height: 40px;
  justify-content: center;
  font-size: 14px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

export const NotFound = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 30px;
    font-family: ${rl};
  }
`;
