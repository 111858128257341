import { combineReducers } from "redux";
import setDebtorFormData, { SetDebtorDataStoreState } from "./setDebtorFormData";
import getDebtor, { GetDebtorStoreState } from "./getDebtor";
import getDebtorsList, { GetDebtorsListStoreState } from "./getDebtorsList";

export interface DebtorsReducers {
  setDebtorFormData: SetDebtorDataStoreState;
  getDebtor: GetDebtorStoreState;
  getDebtorsList: GetDebtorsListStoreState;
}

export const Debtors  = combineReducers({
  setDebtorFormData,
  getDebtor,
  getDebtorsList
});
