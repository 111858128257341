import { GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getInvestorsListSupplierRoleByGuid';

export interface Investor {
  payerInn: string;
  payerName: string;
  rate: number;
  firstPaymentShare: number;
  payerType: number;
  isRegressed?: boolean;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_REQ,
  data
});

export const got = (data: Investor[]) => ({
  type: GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID.GET_INVESTORS_LIST_SUPPLIER_ROLE_BY_GUID_ERR,
  error
});
