import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  req as getSupplyContractsList,
  RequestData,
  ResponseData
} from 'src/features/SupplyContracts/actions/getSupplyContractsList';

import { REQUEST_STATUSES, CRM, ResponseError } from 'globaltypes';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Loader } from 'src/features/Layouts/components';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { INNContainer, INNField } from './styled';
import InternalSupplyList from 'src/features/SupplyContracts/components/InternalSupplyList/InternalSupplyList';

interface StateToProps {
  supplyContracts: ResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getSupplyContractsList: (data: RequestData) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ContractSupplies: React.FC<Props> = ({
  getSupplyContractsList,
  supplyContracts,
  status,
  error,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [debtorInn, setDebtorInn] = React.useState('');

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const supplierInn = searchParams.get('supplierInn') || '';

      setDebtorInn(debtorInn);
      setSupplierInn(supplierInn);

      getSupplyContractsList({
        page,
        pageSize: 20,
        ...(debtorInn && { DebtorInnToFilterBy: debtorInn }),
        ...(supplierInn && { SupplierInnToFilterBy: supplierInn })
      });
    },
    [history.location.search]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <ApplicationTitle>Договоры поставки</ApplicationTitle>

      <INNContainer>
        <INNField>
          <Input
            label="ИНН Поставщика"
            value={supplierInn}
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>

        <INNField>
          <Input
            label="ИНН Дебитора"
            value={debtorInn}
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
        </INNField>
      </INNContainer>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <InternalSupplyList
          supplyContracts={supplyContracts}
          status={status}
          error={error}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ SupplyContracts }: CRM) => ({
  supplyContracts: SupplyContracts.getSupplyContractsList.data,
  status: SupplyContracts.getSupplyContractsList.status,
  error: SupplyContracts.getSupplyContractsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getSupplyContractsList }, dispatch);

const ContractSuppliesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractSupplies)
);

export { ContractSuppliesConnect as ContractSupplies };
