import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostSelectApplicationStoreState extends Reducer {
  data: any;
}

export enum POST_SELECT_APPLICATION {
  REQ = 'POST_SELECT_APPLICATION_REQ',
  GOT = 'POST_SELECT_APPLICATION_GOT',
  ERR = 'POST_SELECT_APPLICATION_ERR',
  RESET = 'POST_SELECT_APPLICATION_RESET'
}

export const initialState: PostSelectApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const selectApplication = (
  state: PostSelectApplicationStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): PostSelectApplicationStoreState => {
  switch (action.type) {
    case POST_SELECT_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_SELECT_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case POST_SELECT_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_SELECT_APPLICATION.RESET:
      return {
        ...state,
        status: REQUEST_STATUSES.NONE,
        error: {}
      };
    default:
      return state;
  }
};

export default selectApplication;
