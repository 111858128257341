import { PUT_SET_APPROVED } from '../reducers/putSetApproved';

export const req = (id: number) => ({
  type: PUT_SET_APPROVED.REQ,
  id
});

export const got = () => ({
  type: PUT_SET_APPROVED.GOT
});

export const err = (error: {}) => ({
  type: PUT_SET_APPROVED.ERR,
  error
});
