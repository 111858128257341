import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { Activities } from 'src/features/Activities/types';

export interface GetActivitiesByIdStoreState extends Reducer {
  data: Activities;
}

export enum GET_ACTIVITIES_BY_ID {
  REQ = 'GET_ACTIVITIES_BY_ID_REQ',
  GOT = 'GET_ACTIVITIES_BY_ID_GOT',
  ERR = 'GET_ACTIVITIES_BY_ID_ERR'
}

export const initialState: GetActivitiesByIdStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getActivitiesById = (
  state: GetActivitiesByIdStoreState = initialState,
  action: {
    type: string;
    data: any;
    error: {};
  }
): GetActivitiesByIdStoreState => {
  switch (action.type) {
    case GET_ACTIVITIES_BY_ID.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_ACTIVITIES_BY_ID.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_ACTIVITIES_BY_ID.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getActivitiesById;
