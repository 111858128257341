import { GET_FINANCIAL_DOCUMENTS_EXTERNAL } from 'src/features/Clients/reducers/getFinancialDocuments';

export interface ResponseData {
  guid: string;
  companyInn: string;
  typeId: number;
  taxPeriodId: string;
  taxYear: number;
  periodStartDate: string;
  periodEndDate: string;
  taxPeriod: string;
  documentName: string;
  signatureFileName: string;
  documentFileName: string;
}

export const req = (inn: string) => ({
  type: GET_FINANCIAL_DOCUMENTS_EXTERNAL.REQ,
  inn
});

export const got = (data: ResponseData[]) => ({
  type: GET_FINANCIAL_DOCUMENTS_EXTERNAL.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_FINANCIAL_DOCUMENTS_EXTERNAL.ERR,
  error
});
