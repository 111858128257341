import { Reducer, REQUEST_STATUSES } from 'globaltypes';

// tslint:disable-next-line:no-empty-interface
export interface ToggleExternalVisibility extends Reducer {
}



export enum TOGGLE_EXTERNAL_VISIBILITY_ACTIONS {
  REQ = 'TOGGLE_EXTERNAL_VISIBILITY_REQ',
  GOT = 'TOGGLE_EXTERNAL_VISIBILITY_GOT',
  ERR = 'TOGGLE_EXTERNAL_VISIBILITY_ERR'
}

export const initialState: ToggleExternalVisibility = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const toggleExternalVisibility = (
  state: ToggleExternalVisibility = initialState,
  action: {
    type: string;
    error: {};
  }
): ToggleExternalVisibility => {
  switch (action.type) {
    case TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case TOGGLE_EXTERNAL_VISIBILITY_ACTIONS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default toggleExternalVisibility;
