import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PutPurchaseContractSignatureSupplierRoleStoreState = Reducer;

export enum PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE {
  REQ = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE_REQ',
  GOT = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE_GOT',
  ERR = 'PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE_ERR'
}

export interface RequestDataType {
  guid: string;
  signature: string;
  serial: string;
}

export const initialState: PutPurchaseContractSignatureSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putPurchaseContractSignatureSupplierRole = (
  state: PutPurchaseContractSignatureSupplierRoleStoreState = initialState,
  action: {
    type: PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE;
    error: {};
  }
): PutPurchaseContractSignatureSupplierRoleStoreState => {
  switch (action.type) {
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_PURCHASE_CONTRACTS_SIGNATURE_SUPPLIER_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default putPurchaseContractSignatureSupplierRole;
