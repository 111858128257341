import styled from 'styled-components';

export const RevenueContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 20px 10px 0 0;
  }

  & > p:nth-of-type(2) {
    margin-left: 10px;
  }

  & > div:nth-of-type(2) {
    margin-top: 22px;
  }
`;

export const IncomeContainer = styled(RevenueContainer)`
  & > p:nth-of-type(1) {
    margin-left: 10px;
  }
`;

export const LoansContainer = styled(RevenueContainer)`
  & > p:nth-of-type(1) {
    margin-left: 10px;
  }
`;
