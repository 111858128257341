import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getFactoringContractsList';

import { GET_FACTORING_CONTRACTS_LIST } from '../reducers/getFactoringContractsList';
import FactoringContractsApi from '../api';

function* getFactoringContractsList(action: {
  type: GET_FACTORING_CONTRACTS_LIST;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      FactoringContractsApi.getFactoringContractsList,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringContractsListSaga(): Generator {
  yield takeLatest(GET_FACTORING_CONTRACTS_LIST.REQ, getFactoringContractsList);
}
