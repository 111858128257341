import { combineReducers } from 'redux';
import supplierRegister, {
  SCFRegisterSupplierStoreState
} from 'entities/SCF/Supplier/model/reducers/SCFRegisterSupplier';
import getFactoringConnectionsSupplierRole, {
  GetFactoringConnectionsSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/getFactoringConnectionsSupplierRole';
import getFactoringContractsSupplierRole, {
  GetFactoringContractsSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/getFactoringContractsSupplierRole';
import getFactoringContractSupplierRoleByGuid, {
  GetFactoringContractSupplierRoleByGuidStoreState
} from 'entities/SCF/Supplier/model/reducers/getFactoringContractSupplierRoleByGuid';
import getEarlyPaymentApplicationsSupplierRole, {
  GetEarlyPaymentApplicationsSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/getEarlyPaymentApplicationsSupplierRole';
import getMonetaryClaimsSupplierRole, {
  GetMonetaryClaimsSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsSupplierRole';
import getInvestorsListSupplierRoleByGuid, {
  GetInvestorsListSupplierRoleByGuidStoreState
} from 'entities/SCF/Supplier/model/reducers/getInvestorsListSupplierRoleByGuid';
import getInvestorOneDebtorSupplierRoleByGuid, {
  GetInvestorOneDebtorSupplierRoleByGuidStoreState
} from 'entities/SCF/Supplier/model/reducers/getInvestorOneDebtorSupplierRoleByGuid';
import getMonetaryClaimsEarlyPaymentApplicationsByGuid, {
  GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState
} from 'entities/SCF/Supplier/model/reducers/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import getStartRateSupplierRole, {
  GetStartRateSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/getStartRateSupplierRole';
import postEarlyDatePick, {
  PostEarlyDatePickStoreState
} from 'src/entities/SCF/Supplier/model/reducers/postEarlyDatePick';
import postEarlyPaymentApplicationSupplierRole, {
  PostEarlyPaymentApplicationSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/postEarlyPaymentApplicationSupplierRole';
import addInvestorSupplierRole, {
  AddInvestorSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/addInvestorSupplierRole';
import postAgreementSignatureSupplierRole, {
  PostAgreementSignatureSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/postAgreementSignatureSupplierRole';
import uploadSupplierContractDocument, {
  UploadSupplierContractDocumentStoreState
} from 'entities/SCF/Supplier/model/reducers/uploadSupplierContractDocument';
import putPurchaseContractSignatureSupplierRole, {
  PutPurchaseContractSignatureSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/putPurchaseContractSignatureSupplierRole';
import postSupplierNotificationSettings, {
  PostSupplierNotificationSettingsStoreState
} from 'entities/SCF/Supplier/model/reducers/postSupplierNotificationSettings';
import patchPurchaseContractSupplier, {
  PatchPurchaseContractSupplierStoreState
} from 'entities/SCF/Supplier/model/reducers/patchPurchaseContractSupplier';
import postFactoringConnectionSupplierRole, {
  PostFactoringConnectionSupplierRoleStoreState
} from 'entities/SCF/Supplier/model/reducers/postFactoringConnectionSupplierRole';
import getSignatureFile, {
  GetSignatureFileStoreState
} from 'entities/SCF/Supplier/model/reducers/getSignatureFile';
import getSupplierCounterSidebar, {
  GetSupplierCounterSidebarStoreState
} from 'entities/SCF/Supplier/model/reducers/getSupplierCounterSidebar';
import getByMonetaryClaimSupplier, {
  GetByMonetaryClaimSupplierStoreState
} from 'entities/SCF/Supplier/model/reducers/getByMonetaryClaimSupplier';
import getContractBySupplier, {
  GetContractBySupplierStoreState
} from 'entities/SCF/Supplier/model/reducers/getContractBySupplier';
import getSupplierNotificationSettings, {
  GetSupplierNotificationSettingsStoreState
} from 'entities/SCF/Supplier/model/reducers/getSupplierNotificationSettings';
import SCFStatus, {
  SCFStatusStoreState
} from 'entities/SCF/Supplier/model/reducers/SCFStatus';
import putAddSignee, {
  PutAddSigneeStoreState
} from 'entities/SCF/Supplier/model/reducers/putAddSignee';
import postConfirmContractBySupplier, {
  PostConfirmContractBySupplierStoreState
} from 'entities/SCF/Supplier/model/reducers/postConfirmContractBySupplier';
import postOfferDiscount, {
  PostOfferDiscountStoreState
} from 'entities/SCF/Supplier/model/reducers/postOfferDiscount';
import putAddPoaFile, {
  PutAddPoaFileStoreState
} from 'entities/SCF/Supplier/model/reducers/putAddPoaFile';

export interface SCFSupplierReducers {
  supplierRegister: SCFRegisterSupplierStoreState;
  postEarlyDatePick: PostEarlyDatePickStoreState;
  postEarlyPaymentApplicationSupplierRole: PostEarlyPaymentApplicationSupplierRoleStoreState;
  addInvestorSupplierRole: AddInvestorSupplierRoleStoreState;
  postAgreementSignatureSupplierRole: PostAgreementSignatureSupplierRoleStoreState;
  uploadSupplierContractDocument: UploadSupplierContractDocumentStoreState;
  putPurchaseContractSignatureSupplierRole: PutPurchaseContractSignatureSupplierRoleStoreState;
  postSupplierNotificationSettings: PostSupplierNotificationSettingsStoreState;
  getFactoringConnectionsSupplierRole: GetFactoringConnectionsSupplierRoleStoreState;
  getFactoringContractsSupplierRole: GetFactoringContractsSupplierRoleStoreState;
  getFactoringContractSupplierRoleByGuid: GetFactoringContractSupplierRoleByGuidStoreState;
  getEarlyPaymentApplicationsSupplierRole: GetEarlyPaymentApplicationsSupplierRoleStoreState;
  getMonetaryClaimsSupplierRole: GetMonetaryClaimsSupplierRoleStoreState;
  getInvestorsListSupplierRoleByGuid: GetInvestorsListSupplierRoleByGuidStoreState;
  getInvestorOneDebtorSupplierRoleByGuid: GetInvestorOneDebtorSupplierRoleByGuidStoreState;
  getMonetaryClaimsEarlyPaymentApplicationsByGuid: GetMonetaryClaimsEarlyPaymentApplicationsByGuidStoreState;
  getStartRateSupplierRole: GetStartRateSupplierRoleStoreState;
  patchPurchaseContractSupplier: PatchPurchaseContractSupplierStoreState;
  postFactoringConnectionSupplierRole: PostFactoringConnectionSupplierRoleStoreState;
  getSupplierNotificationSettings: GetSupplierNotificationSettingsStoreState;
  getSignatureFile: GetSignatureFileStoreState;
  getSupplierCounterSidebar: GetSupplierCounterSidebarStoreState;
  getByMonetaryClaimSupplier: GetByMonetaryClaimSupplierStoreState;
  getContractBySupplier: GetContractBySupplierStoreState;
  externalAgentStatus: SCFStatusStoreState;
  putAddSignee: PutAddSigneeStoreState;
  postConfirmContractBySupplier: PostConfirmContractBySupplierStoreState;
  postOfferDiscount: PostOfferDiscountStoreState;
  putAddPoaFile: PutAddPoaFileStoreState;
}

export const SCFSupplier = combineReducers({
  supplierRegister,
  postFactoringConnectionSupplierRole,
  postEarlyDatePick,
  postEarlyPaymentApplicationSupplierRole,
  addInvestorSupplierRole,
  postAgreementSignatureSupplierRole,
  uploadSupplierContractDocument,
  putPurchaseContractSignatureSupplierRole,
  postSupplierNotificationSettings,
  patchPurchaseContractSupplier,
  getFactoringConnectionsSupplierRole,
  getFactoringContractsSupplierRole,
  getFactoringContractSupplierRoleByGuid,
  getEarlyPaymentApplicationsSupplierRole,
  getMonetaryClaimsSupplierRole,
  getInvestorsListSupplierRoleByGuid,
  getInvestorOneDebtorSupplierRoleByGuid,
  getMonetaryClaimsEarlyPaymentApplicationsByGuid,
  getStartRateSupplierRole,
  getSupplierNotificationSettings,
  getSignatureFile,
  getSupplierCounterSidebar,
  getByMonetaryClaimSupplier,
  getContractBySupplier,
  SCFStatus,
  putAddSignee,
  postConfirmContractBySupplier,
  postOfferDiscount,
  putAddPoaFile
});
