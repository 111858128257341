import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from 'entities/SCF/Debtor/model/actions/sendSignature';

import {
  SEND_SIGNATURE,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/sendSignature';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* sendSignature(action: {
  type: SEND_SIGNATURE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFDebtorApi.sendSignature, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* sendSignatureSaga() {
  yield takeLatest(SEND_SIGNATURE.REQ, sendSignature);
}
