export interface InternalAgentsFilterData {
  internalAgents: number[];
}

export enum FILTER_INTERNAL_AGENT_ACTIONS {
  ADD = 'ADD_FILTER_INTERNAL_AGENTS_DATA',
  REMOVE = 'REMOVE_FILTER_INTERNAL_AGENTS_DATA'
}

export const initialState: InternalAgentsFilterData = {
  internalAgents: []
};

const updateInternalAgentsFilter = (
  state: InternalAgentsFilterData = initialState,
  action: {
    type: FILTER_INTERNAL_AGENT_ACTIONS;
    internalAgent: number;
    index: number;
  }
): InternalAgentsFilterData => {
  switch (action.type) {
    case FILTER_INTERNAL_AGENT_ACTIONS.ADD:
      const combined = [...state.internalAgents, action.internalAgent];
      return {
        ...state,
        internalAgents: combined
      };
    case FILTER_INTERNAL_AGENT_ACTIONS.REMOVE:
      const filtered = state.internalAgents.filter((value,index) => index !== action.index);
      return {
        ...initialState,
        internalAgents: filtered
      };
    default:
      return state;
  }
};

export default updateInternalAgentsFilter;
