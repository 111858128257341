import styled from 'styled-components';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const HeaderClients = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;

  h2 {
    font-size: 30px;
    font-weight: 400;
  }

  div {
    width: 250px;
    height: 40px;
  }
`;

export const BtnAddClient = styled(ButtonStyled)`
  width: auto;
  max-height: 40px;
  justify-content: center;

  background-color: ${({ isActive }) => isActive && '#7A7D83'};

  :hover {
    background-color: ${({ isActive }) => isActive && '#7A7D83 !important'};
  }
`;

export const BtnSubmit = styled(ButtonStyled)`
  width: auto;
  height: 40px;
  justify-content: center;

  background-color: ${({ isValid }) => !isValid && '#7A7D83'};

  :hover {
    background-color: ${({ isValid }) => !isValid && '#7A7D83 !important'};
  }
`;
