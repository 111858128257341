import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STORE } from 'globaltypes';

import { setInitializationFormData } from 'Application/actions/setInitializationFormData';

import { got, err } from 'BorrowerCompanies/actions/deleteBorrowerCompany';
import {
  RequestDataType,
  DELETE_BORROWER_COMPANY
} from 'BorrowerCompanies/reducers/deleteBorrowerCompany';

import BorrowerCompaniesApi from 'BorrowerCompanies/api';

function* deleteBorrowerCompany(action: {
  type: DELETE_BORROWER_COMPANY;
  data: RequestDataType;
}): Generator {
  try {
    const res:any = yield call(BorrowerCompaniesApi.deleteBorrowerCompany, {
      id: action.data.id
    });

    const { borrowerCompanies }:any = yield select(({ Application }: STORE) => ({
      borrowerCompanies: Application.setInitializationFormData.borrowerCompanies
    }));

    const newBorrowerCompanies = borrowerCompanies.filter(
      company => company.id !== action.data.id
    );

    yield put(
      setInitializationFormData({ borrowerCompanies: newBorrowerCompanies })
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* deleteBorrowerCompanySaga(): Generator {
  yield takeLatest(DELETE_BORROWER_COMPANY.REQ, deleteBorrowerCompany);
}
