import { POST_USER_ALLOW_FIELDS } from '../reducers/postAllowFields';

export const req = (id: string, typeProcess: string) => ({
  type: POST_USER_ALLOW_FIELDS.REQ,
  id,
  typeProcess
});

export const got = (data: any) => ({
  type: POST_USER_ALLOW_FIELDS.GOT,
  data
});

export const err = (error: {}) => ({
  type: POST_USER_ALLOW_FIELDS.ERR,
  error
});
