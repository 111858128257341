import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/getFactorRegistryFiles';
import { GET_FACTOR_REGISTRY_FILES } from 'entities/SCF/Factor/model/reducers/getFactorRegistryFiles';
import SCFApi from 'entities/SCF/Factor/api';

function* getFactorRegistryFiles(action: {
  type: GET_FACTOR_REGISTRY_FILES;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getFactorRegistryFiles, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactorRegistryFilesSaga() {
  yield takeLatest(
    GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_REQ,
    getFactorRegistryFiles
  );
}
