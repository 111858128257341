import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiSelect } from 'shared/ui/MultiSelect';
import { ApplicationTypes } from 'shared/constants';
import {
  removeTypeFilter,
  addTypeFilter
} from 'src/features/Filters/TypeFilter/actions/updateTypeFilter';
import { STORE } from 'globaltypes';

interface StateToProps {
  types: string[];
}

interface DispatchProps {
  addTypeFilter: (type: string) => void;
  removeTypeFilter: (indexToRemove: number) => void;
}

interface AnyProps {
  filterTypes?: string[];
  handleTypeFilter?: (type: string[]) => void;
  handleDeleteType?: (index: number) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchProps & AnyProps;

const TypeFilter: React.FC<Props> = ({
  types,
  addTypeFilter,
  removeTypeFilter,
  handleDeleteType,
  history
}) => {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);

    urlParams.get('typesToFilterBy') &&
      urlParams
        .get('typesToFilterBy')
        .split(',')
        .forEach(type => {
          if (!types.includes(type)) {
            addTypeFilter(type);
          }
        });
  }, []);

  const onMultiAdd = (type: string) => {
    const isUnique = types.includes(type);
    if (!isUnique) {
      addTypeFilter(type);
    }
  };

  const onMultiRemove = (index: number) => {
    removeTypeFilter(index);
    handleDeleteType && handleDeleteType(index);
  };

  const initialTypes = () => {
    return Object.keys(ApplicationTypes).map(key => {
      return { id: key, name: ApplicationTypes[key] };
    });
  };

  return (
    <MultiSelect
      name="name"
      placeholder="Тип"
      label="Фильтрация по типу"
      value=""
      selected={types.map(key => ApplicationTypes[key])}
      onMultiAdd={onMultiAdd}
      onMultiRemove={onMultiRemove}
      options={initialTypes()}
    />
  );
};

const mapStateToProps = ({ Filters }: STORE) => ({
  types: Filters.updateTypeFilter.types
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ addTypeFilter, removeTypeFilter }, dispatch);

const TypeFilterConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TypeFilter)
);

export { TypeFilterConnect as TypeFilter };
