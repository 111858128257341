import { ApplicationRead, Document } from 'Application/types';

export enum DEBTOR_TYPE {
  FEDERAL = 'FEDERAL',
  INTERNATIONAL = 'INTERNATIONAL',
  OTHER = 'OTHER'
}

export enum PLEDGE_TYPE {
  REGRESS = 'REGRESS',
  NO_REGRESS = 'NO_REGRESS'
}

export interface Debtor {
  name: string;
  limit: number;
  postponement: number;
  pledgeType: PLEDGE_TYPE;
}

export interface FactoringApplicationWrite {
  debtorType: DEBTOR_TYPE;
  debtors: Debtor[];
  documents: Document[];
}

export type FactoringApplicationRead = ApplicationRead &
  FactoringApplicationWrite;
