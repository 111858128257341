import styled from 'styled-components';

import { ApplicationStatus } from 'Application/components';
import { rr, rm, gray, navyBlue } from 'shared/styled';
import { Card } from 'src/features/Products/components/ProductView/styled';

export const ApplicationSource = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
`;

export const ApplicationInitiatorEmail = styled.h3`
  font-weight: 100;
  margin-bottom: 15px;
`;

export const ApplicationId = styled.h3`
  font-weight: 100;
  color: ${gray};
`;

export const ApplicationTitle = styled.h2`
  margin-bottom: 15px;
  font-family: ${rm};
  color: #06243c;
`;

export const ApplicationStatusStyled = styled(ApplicationStatus)`
  margin-bottom: 25px;
  display: inline-block;
`;

export const MainInfoBlock = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MainInfoItem = styled.div`
  padding-right: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &:not(:last-child) {
    border-right: 1px solid #e2e4e8;
  }

  &:not(:first-child) {
    padding-left: 24px;
  }

  span:first-child {
    margin-bottom: 8px;

    font-family: ${rr};
    font-size: 1.4rem;
    color: ${gray};
  }

  span:last-child {
    font-family: ${rm};
    font-size: 1.6rem;
    color: ${navyBlue};
  }
`;

export const SubInfoBlock = styled.section`
  margin: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  p {
    font-family: ${rr};
    font-size: 1.4rem;
    color: ${gray};

    span {
      color: ${navyBlue};
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const CabinetCard = styled(Card)`
  width: 150px;
  color: #7a7d83;
  padding: 12px;
`;

export const HintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 120px;
`;

const getTooltipColor = code => {
  switch (code) {
    case 4:
      return 'rgb(255, 208, 208)';
    case 3:
      return 'rgb(255, 240, 222)';
    case 2:
      return 'rgb(208, 233, 255)';
    case 1:
      return 'rgb(190, 239, 230)';
    default:
      return 'transparent';
  }
};

export const Hint = styled.div`
  max-width: 350px;
  background-color: ${({ code }) => getTooltipColor(code)};
  padding: 15px;
  border-radius: 5px;

  pre {
    font-family: ${rr};
    font-size: 1.6rem;
    color: rgba(63, 78, 101, 1);
    white-space: pre-wrap;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 15px;
  flex-wrap: wrap;
  margin-left: auto;
  width: 450px;
`;

export const ContainerSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 10px;
`;

export const FinancialsSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;

  p {
    margin: 0;
  }

  div > p:first-child {
    color: ${gray};
  }
`;

export const FinancialsSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 440px;
  row-gap: 25px;
  column-gap: 50px;

  & > div {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
  }
`;

export const FinancialsSummarySum = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  div {
    display: flex;
    align-items: center;
    column-gap: 15px;

    & > p:last-child {
      color: ${({ isNegativeNum }) => (isNegativeNum ? '#f53737' : '')};
    }
  }
`;

export const FinancialsSummaryTitle = styled.p`
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: ${gray};
`;

export const FinancialsSummaryDays = styled.div`
  display: grid !important;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  margin-bottom: 5px;
  p {
    color: ${gray};
  }
`;

export const FinancialsSummaryFooter = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  justify-content: flex-start;

  & > div:first-child {
    width: 180px;
  }
`;

export const FinancialsSummaryProgressContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 10px;
`;

export const FinancialsSummaryProgressDIO = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ widthDIO }) => (widthDIO ? `${widthDIO}%` : 0)};
  height: 20px;
  background-color: #6cdcd0;
`;
export const FinancialsSummaryProgressDSO = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ widthDSO }) => (widthDSO ? `${widthDSO}%` : 0)};
  height: 20px;
  background-color: #f7cc64;
`;
export const FinancialsSummaryProgressDPO = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ widthDPO }) => (widthDPO ? `${widthDPO}%` : 0)};
  height: 20px;
  background-color: #f7cc64;
`;
export const FinancialsSummaryProgressCCC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ widthCCC }) => (widthCCC ? `${widthCCC}%` : 0)};
  height: 20px;
  background-color: #6cdcd0;
`;
export const FinancialsSummaryProgressMinusCCC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 20px;
  background-color: #6cdcd0;
  margin-left: auto;
`;
