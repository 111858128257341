import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/putSendToBank';
import { PUT_SEND_TO_BANK } from 'src/features/Application/reducers/putSendToBank';

import ApplicationApi from 'Application/api';

function* putSentToBank(action: { type: PUT_SEND_TO_BANK; id: number }) {
  try {
    yield call(ApplicationApi.putSentToBank, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putSentToBankSaga() {
  yield takeLatest(PUT_SEND_TO_BANK.REQ, putSentToBank);
}
