import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getSupplyContracts';
import { GET_SUPPLY_CONTRACTS } from '../reducers/getSupplyContracts';
import SCFApi from '../api';

function* getSupplyContracts(action: {
  type: GET_SUPPLY_CONTRACTS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getSupplyContracts, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractsSaga() {
  yield takeLatest(
    GET_SUPPLY_CONTRACTS.GET_SUPPLY_CONTRACTS_REQ,
    getSupplyContracts
  );
}
