import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostSupplierNotificationSettingsStoreState = Reducer;

export enum POST_SUPPLIER_NOTIFICATION_SETTINGS {
  REQ = 'POST_SUPPLIER_NOTIFICATION_SETTINGS_REQ',
  GOT = 'POST_SUPPLIER_NOTIFICATION_SETTINGS_GOT',
  ERR = 'POST_SUPPLIER_NOTIFICATION_SETTINGS_ERR',
  RESET = 'POST_SUPPLIER_NOTIFICATION_SETTINGS_RESET'
}

export interface RequestDataType {
  newBuyerToSupplierLinkEvent: boolean;
  newSupplyContractLinkEvent: boolean;
  newMonetaryClaimsEvent: boolean;
  earlyPaymentApplicationStatusEvent: boolean;
}

export const initialState: PostSupplierNotificationSettingsStoreState = {
  status: REQUEST_STATUSES.REQUEST,
  error: {}
};

const postSupplierNotificationSettings = (
  state: PostSupplierNotificationSettingsStoreState = initialState,
  action: {
    type: POST_SUPPLIER_NOTIFICATION_SETTINGS;
    error: {};
  }
): PostSupplierNotificationSettingsStoreState => {
  switch (action.type) {
    case POST_SUPPLIER_NOTIFICATION_SETTINGS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_SUPPLIER_NOTIFICATION_SETTINGS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_SUPPLIER_NOTIFICATION_SETTINGS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case POST_SUPPLIER_NOTIFICATION_SETTINGS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default postSupplierNotificationSettings;
