import { combineReducers } from 'redux';
import getProductsListBank, {
  GetProductsListBankStoreState
} from './getProductsList';
import getProductBank, { GetProductBankStoreState } from './getProduct';
import sendProductBankData, {
  SendProductBankStoreState
} from './sendProductData';

export interface ProductsBankReducers {
  getProductsListBank: GetProductsListBankStoreState;
  getProductBank: GetProductBankStoreState;
  sendProductBankData: SendProductBankStoreState;
}

export const ProductsBank = combineReducers({
  getProductsListBank,
  getProductBank,
  sendProductBankData
});
