import { GET_PERSON } from '../reducers/getPerson';
import { PersonType } from '../types';

export const req = (inn: string) => ({
  type: GET_PERSON.REQ,
  inn
});

export const got = (data: PersonType) => ({
  type: GET_PERSON.GOT,
  data
});

export const reset = () => ({
  type: GET_PERSON.RESET
});

export const err = (error: {}) => ({
  type: GET_PERSON.ERR,
  error
});
