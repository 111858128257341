export enum CONTACTS {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Есть контакты',
  FALSE = 'Нет контактов'
}

export enum LOCKED {
  NOT_CHECK = 'Не проверяется',
  TRUE = 'Закреплен',
  FALSE = 'Не закреплен'
}

export enum ROLE {
  ANALYSTID = 'Я аналитик',
  MANAGERID = 'Я менеджер'
}
