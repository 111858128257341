import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/postAddToContractBySupplier';

import { POST_ADD_TO_CONTRACT_BY_SUPPLIER } from 'src/features/SCF/reducers/postAddToContractBySupplier';

import SCFApi from 'entities/SCF/Supplier/api';

function* postAddToContractBySupplier(action: {
  type: POST_ADD_TO_CONTRACT_BY_SUPPLIER;
  guid: string;
  contractAccount: string;
}) {
  try {
    yield call(
      SCFApi.postAddToContractBySupplier,
      action.contractAccount,
      action.guid
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddToContractBySupplierSaga() {
  yield takeLatest(
    POST_ADD_TO_CONTRACT_BY_SUPPLIER.REQ,
    postAddToContractBySupplier
  );
}
