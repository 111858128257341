import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { FinancialStatementsAllType } from '../types';

export interface GetNewFinancialStatementsAllStoreState extends Reducer {
  data: FinancialStatementsAllType[];
}

export enum GET_NEW_FINANCIAL_STATEMENTS_ALL {
  REQ = 'GET_NEW_FINANCIAL_STATEMENTS_ALL_REQ',
  GOT = 'GET_NEW_FINANCIAL_STATEMENTS_ALL_GOT',
  ERR = 'GET_NEW_FINANCIAL_STATEMENTS_ALL_ERR',
  REMOVE = 'GET_NEW_FINANCIAL_STATEMENTS_ALL_REMOVE'
}

export const initialState: GetNewFinancialStatementsAllStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [] as FinancialStatementsAllType[],
  error: {}
};

const getNewFinancialStatementsAll = (
  state: GetNewFinancialStatementsAllStoreState = initialState,
  action: {
    type: string;
    data: any;
    TaxPeriod?: number;
    TaxYear?: number;
    error: {};
  }
): GetNewFinancialStatementsAllStoreState => {
  switch (action.type) {
    case GET_NEW_FINANCIAL_STATEMENTS_ALL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_NEW_FINANCIAL_STATEMENTS_ALL.GOT:
      const isItemExists = action.data.items.every(item =>
        state.data.some(element => element.id === item.id)
      );

      if (isItemExists) {
        return state;
      } else {
        return {
          ...state,
          status: REQUEST_STATUSES.GOT,
          data: [...state.data, ...action.data.items]
        };
      }
    case GET_NEW_FINANCIAL_STATEMENTS_ALL.REMOVE:
      const newData = state.data.filter(
        item =>
          !(
            item.year === action.TaxYear &&
            item.taxPeriodCode === action.TaxPeriod
          )
      );

      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: newData
      };
    case GET_NEW_FINANCIAL_STATEMENTS_ALL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getNewFinancialStatementsAll;
