import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
  faMinus
} from '@fortawesome/free-solid-svg-icons';
import { CRM, ResponseError, REQUEST_STATUSES } from 'src/globaltypes';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import {
  req as getUserFactorList,
  RequestData as getUserFactorListRequestData,
  ResponseData as getUserFactorListResponseData
} from 'src/features/UserFactors/actions/getUserFactorList';
import { req as postAllowProcess } from 'src/features/Users/actions/postAllowProcess';
import { req as postAllowFields } from 'src/features/Users/actions/postAllowFields';
import { ScrollTopComponent } from 'src/features/Common';
import {
  TDEmailStyle,
  CheckboxStyle,
  TooltipBoxStyle,
  CheckboxStyleEmail,
  TDCopyTooltip,
  CopyBlock
} from './styled';

interface StateToProps {
  userFactors: getUserFactorListResponseData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  statusPostAllowProcess: REQUEST_STATUSES;
  statusPostAllowFields: REQUEST_STATUSES;
}

interface DispatchToProps {
  getUserFactorList: (data: getUserFactorListRequestData) => void;
  postAllowProcess: (id: string, typeProcess: string) => void;
  postAllowFields: (id: string, typeProcess: string) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const Factors: React.FC<Props> = ({
  postAllowFields,
  postAllowProcess,
  statusPostAllowFields,
  statusPostAllowProcess,
  getUserFactorList,
  userFactors,
  status,
  error,
  history
}) => {
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    getUserFactorList({ page, pageSize: 20 });
  }, []);

  React.useEffect(
    () => {
      if (
        statusPostAllowFields === REQUEST_STATUSES.GOT ||
        statusPostAllowProcess === REQUEST_STATUSES.GOT
      ) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;

        getUserFactorList({ page, pageSize: 20 });
      }
    },
    [statusPostAllowFields, statusPostAllowProcess]
  );

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Факторы (пользователи)</ApplicationTitle>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="5%">ID</TableThStyled>
              <TableThStyled width="10%">Имя</TableThStyled>
              <TableThStyled width="10%">Телефон</TableThStyled>
              <TableThStyled width="15%">Email</TableThStyled>
              <TableThStyled width="10%">Дата регистрации</TableThStyled>
              <TableThStyled width="10%">Последний вход</TableThStyled>
              <TableThStyled width="10%">Последняя заявка</TableThStyled>
              <TableThStyled width="10%">ИНН компании</TableThStyled>
              <TableThStyled width="10%">Простой процесс</TableThStyled>
              <TableThStyled width="10%">Пустые поля и документы</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {userFactors.items.map(factor => (
              <TableRowStyled
                key={factor.id}
                onClick={() =>
                  factor.companyInn &&
                  history.push(`/internal/factors/${factor.companyInn}`)
                }
              >
                <td>{factor.id}</td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      factor.firstName && !isCopied
                        ? 'Скопировать имя'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() =>
                      handleCopyClick(`${factor.lastName} ${factor.firstName}`)
                    }
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {factor.lastName} {factor.firstName}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      factor.phoneNumber && !isCopied
                        ? 'Скопировать номер'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(factor.phoneNumber)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {factor.phoneNumber}
                  </CopyBlock>
                </TDCopyTooltip>
                <TDEmailStyle>
                  <CopyBlock
                    text={
                      factor.email && !isCopied
                        ? 'Скопировать почту'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(factor.email)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {factor.email}
                  </CopyBlock>
                  {factor.emailConfirmed ? (
                    <TooltipBoxStyle textVerify={'Email подтвержден'}>
                      <CheckboxStyleEmail verify={factor.emailConfirmed}>
                        <FontAwesomeIcon icon={faCheck} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  ) : (
                    <TooltipBoxStyle textVerify={'Email не подтвержден'}>
                      <CheckboxStyleEmail verify={factor.emailConfirmed}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </CheckboxStyleEmail>
                    </TooltipBoxStyle>
                  )}
                </TDEmailStyle>
                <td>
                  {factor.createdDateTime &&
                    new Date(factor.createdDateTime).toLocaleDateString()}
                </td>
                <td>
                  {factor.lastLoginAt &&
                    new Date(factor.lastLoginAt).toLocaleDateString()}
                </td>
                <td>
                  {factor.lastApplicationAt &&
                    new Date(factor.lastApplicationAt).toLocaleDateString()}
                </td>
                <TDCopyTooltip>
                  <CopyBlock
                    text={
                      factor.companyInn && !isCopied
                        ? 'Скопировать ИНН'
                        : isCopied && 'Скопировано!'
                    }
                    onClick={() => handleCopyClick(factor.companyInn)}
                    onMouseLeave={() => setIsCopied(false)}
                  >
                    {factor.companyInn}
                  </CopyBlock>
                </TDCopyTooltip>
                <td>
                  {factor.allowSimpleProcess}
                  <CheckboxStyle
                    verify={factor.allowSimpleProcess}
                    onClick={
                      factor.allowSimpleProcess
                        ? () =>
                            postAllowProcess(factor.id.toString(), 'disallow')
                        : () => postAllowProcess(factor.id.toString(), 'allow')
                    }
                  >
                    {factor.allowSimpleProcess ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
                <td>
                  {factor.allowBlankFields}
                  <CheckboxStyle
                    verify={factor.allowBlankFields}
                    onClick={
                      factor.allowBlankFields
                        ? () =>
                            postAllowFields(factor.id.toString(), 'disallow')
                        : () => postAllowFields(factor.id.toString(), 'allow')
                    }
                  >
                    {factor.allowBlankFields ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faMinus} />
                    )}
                  </CheckboxStyle>
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={userFactors} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ UserFactors, Users }: CRM) => ({
  userFactors: UserFactors.getUserFactorList.data,
  status: UserFactors.getUserFactorList.status,
  error: UserFactors.getUserFactorList.error,
  statusPostAllowProcess: Users.postAllowProcess.status,
  statusPostAllowFields: Users.postAllowFields.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserFactorList,
      postAllowFields,
      postAllowProcess
    },
    dispatch
  );

const FactorsConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Factors)
);

export { FactorsConnect as Factors };
