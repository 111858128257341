import styled from 'styled-components';
import { blue } from 'shared/styled';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  margin-left: 40px;
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  div {
    display: flex;
    align-items: center;
    column-gap: 15px;
    p {
      margin: 0;
    }
  }
  div:last-child {
    button {
      color: ${blue};
    }
  }
`;
