import { combineReducers } from 'redux';
import getPersons, { GetPersonsStoreState } from './getPersons';
import getManagers, { GetManagersStoreState } from './getManagers';
import getPerson, { GetPersonStoreState } from './getPerson';
import getPersonsList, { GetPersonsListStoreState } from './getPersonsList';
import patchEditPerson, { PatchEditPersonStoreState } from './patchEditPerson';

export interface PersonsReducers {
  getPersons: GetPersonsStoreState;
  getManagers: GetManagersStoreState;
  getPerson: GetPersonStoreState;
  getPersonsList: GetPersonsListStoreState;
  patchEditPerson: PatchEditPersonStoreState;
}

export const Persons = combineReducers({
  getPersons,
  getManagers,
  getPerson,
  getPersonsList,
  patchEditPerson
});
