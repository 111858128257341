import { put, takeLatest } from 'redux-saga/effects';

import { err } from 'entities/SCF/Supplier/model/actions/getSignatureFile';
import { GET_SIGNATURE_FILE } from 'entities/SCF/Supplier/model/reducers/getSignatureFile';

function* getSignatureFile(action: { type: GET_SIGNATURE_FILE; guid: string }) {
  try {
    const url = `/api/purchase_contract/supplier_role/${
      action.guid
    }/get_signature_file`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSignatureFileSaga() {
  yield takeLatest(GET_SIGNATURE_FILE.REQ, getSignatureFile);
}
