import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { Loader, Menu } from 'src/features/Layouts/components';
import { EarlyPaymentApplications } from 'src/features/EarlyPaymentApplications/components/EarlyPaymentApplications';
import { INVESTORS_TABS } from './types';
import { SupplierInfo, SupplierInfoArea } from './styled';
import { Certificates } from '../Certificates/Certificates';

interface StateToProps {
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FactorView: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  status,
  error,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof INVESTORS_TABS>(
    (match.params.tab &&
      (match.params.tab.toUpperCase() as keyof typeof INVESTORS_TABS)) ||
      'DEFAULT'
  );

  React.useEffect(
    () => {
      if (match.params.inn) {
        getCompanyByInn({ inn: match.params.inn });
      }
    },
    [match.params.inn]
  );

  const handleActiveTab = (tabName: keyof typeof INVESTORS_TABS) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.push(newPathname);
    setActiveTab(tabName as keyof typeof INVESTORS_TABS);
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <SupplierInfoArea>
          <p>Фактор</p>
          <h1>{companyByInn.companyFullName}</h1>
          <SupplierInfo>
            <div>
              <p>
                ИНН:{' '}
                {companyByInn.inn ? companyByInn.inn : Number(match.params.inn)}
              </p>
              <p>ОГРН: {companyByInn.ogrn}</p>
              <p>КПП: {companyByInn.kpp}</p>
            </div>
            <p>Адрес: {companyByInn.companyAddress}</p>
          </SupplierInfo>
        </SupplierInfoArea>
      )}

      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={INVESTORS_TABS}
      />

      {activeTab === 'DEFAULT' && <EarlyPaymentApplications />}
      {activeTab === 'CERTIFICATES' && <Certificates />}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompanyByInn }, dispatch);

const FactorViewConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FactorView)
);

export { FactorViewConnect as FactorView };
