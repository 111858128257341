import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { USER_PERMISSIONS } from 'globaltypes';
import { CabinetComponent } from '../EachPage';
import { SectionSeparatorStyled } from 'pages/Cabinet/styled';
import { NavigationLink } from 'src/features/Layouts/components';
import { Companies } from '../Pages/Companies/Companies';

interface Props {
  path: string;
  listType: any;
  icon: any;
  label: string;
  countName: string;
  Component: any;
}

function createCompaniesCabinetComponent({
  path,
  listType,
  icon,
  label,
  Component
}: Props) {
  return {
    path: path,
    render: location => <Component key={location.key} listType={listType} />,
    renderSidebarLink: key => (
      <NavigationLink
        key={key}
        template="fontawesome"
        fontAwesomeIcon={icon}
        to={{
          pathname: path,
          search: '?page=1'
        }}
        label={label}
      />
    )
  };
}

export const CompaniesSection: CabinetComponent[] = [
  {
    renderSidebarLink: key => (
      <SectionSeparatorStyled key={key}>Компании</SectionSeparatorStyled>
    ),
    hasPermission: permissions => permissions.includes(USER_PERMISSIONS.CLIENT)
  },
  {
    hasPermission: permissions => [USER_PERMISSIONS.CLIENT].isIn(permissions),
    exact: true,
    ...createCompaniesCabinetComponent({
      Component: Companies,
      countName: null,
      path: '/cabinet/client/companies',
      label: 'Мои компании',
      icon: <FontAwesomeIcon icon={faSuitcase} fixedWidth={true} />,
      listType: null
    })
  }
];
