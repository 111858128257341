import ApiRequest from 'src/shared/utils/ApiRequest';

export default class PledgeForProductsApi {
  static patchPledge(id: string, data: any) {
    return ApiRequest(`/api/product/id/${id}`, 'patch', data);
  }

  static patchCabinetPledge(guid: string, data: any) {
    return ApiRequest(`/api/bank_role/product/id/${guid}`, 'patch', data);
  }
}
