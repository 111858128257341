import styled from 'styled-components';
import { darkBlue, lightGray, lighterBlue } from 'shared/styled';

export const INNContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 30px 0;
`;

export const INNField = styled.div`
  padding: 16px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-radius: 4px;
  background-color: ${lighterBlue};

  div {
    display: flex;
    column-gap: 20px;
  }
`;

export const TDMain = styled.td`
  position: relative;
  overflow: visible !important;
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  width: fit-content;
  height: 19px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: #1cca94;
  }

  &:hover:before {
    content: '${({ textVerify }) => textVerify}';
    position: absolute;
    bottom: 4px;
    left: 100%;
    z-index: 100;
    background-color: ${lightGray};
    color: black;
    padding: 10px;
    border-radius: 4px;
  }
`;

export const DownloadBtn = styled.a.attrs({
  download: true
})`
  width: fit-content;
  cursor: pointer;
  margin: 0;
  color: ${darkBlue};
`;
