import { GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getEarlyPaymentApplicationsSupplierRole';

export interface EarlyPaymentApplication {
  guid: string;
  auctionGuid: string;
  createdDateTime: Date;
  debtorInn: string;
  debtorName: string;
  payerInn: string;
  payerName: string;
  payDate: Date;
  paydayOffset: number;
  status: number;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  firstBasePaymentDate: string;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  statusSupplier: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: EarlyPaymentApplication[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  DebtorInnToFilterBy?: string;
  PayerInnToFilterBy?: string;
  StartDate?: string;
  EndDate?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.ERR,
  error
});

export const reset = () => ({
  type: GET_EARLY_PAYMENT_APPLICATIONS_SUPPLIER_ROLE.RESET
});
