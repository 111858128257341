import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getAllUsersForExternalAgent';

import { GET_ALL_USERS_FOR_EXTERNAL_AGENT } from '../reducers/getAllUsersForExternalAgent';
import UserApi from '../api';
import { RequestData } from 'src/features/Users/actions/getUserList';

function* getAllUsersForExternalAgent(action: {
  type: GET_ALL_USERS_FOR_EXTERNAL_AGENT;
  data: RequestData;
}): Generator {
  try {
    const res: any = yield call(
      UserApi.getAllUsersForExternalAgent,
      action.data
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getAllUserForExternalAgentSaga(): Generator {
  yield takeLatest(
    GET_ALL_USERS_FOR_EXTERNAL_AGENT.REQ,
    getAllUsersForExternalAgent
  );
}
