import styled from 'styled-components';
import { blue, rr } from 'shared/styled';

export const PreCheck = styled.section`
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const DescriptionArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;

  justify-content: flex-start;
  border-radius: 10px;
  background-color: #d0e9ff;
  transition: 0.2s;
`;

export const Description = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 1em;
  padding: 0 20px;
  line-height: 130%;
`;

export const AddBtn = styled.div`
  margin-top: 20px;
  padding-left: 15px;
  width: 140px;
  height: auto;
  display: block;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const AddBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 0 10px;
  color: #ffffff;
`;

export const InputArea = styled.div`
  margin-top: 40px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  textarea {
    width: 500px;
    padding: 10px 20px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const InputTitle = styled.p`
  font-family: ${rr};
  font-weight: 400;
  font-size: 1.2em;
`;

export const CheckSection = styled.section`
  margin-left: 150px;
  margin-top: 85px;
  width: 900px;
  height: auto;
  display: none;
  flex-direction: column;
  align-items: flex-start;
`;

export const CheckTitles = styled.div`
  font-family: ${rr};
  margin-bottom: 5px;
  width: 600px;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const CheckTitlesItem = styled.p`
  width: 200px;
  font-family: ${rr};
  font-size: 1.3em;
  color: #7a7d83;
`;

export const CheckItemsRow = styled.div`
  font-family: ${rr};
  margin-top: 5px;
  width: 600px;
  height: auto;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e3e4e9;
  &:last-child {
    border-bottom: none;
  }
`;

export const CheckItem = styled.p`
  width: 200px;
  font-family: ${rr};
  font-size: 1.1em;
`;
