import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface RefreshApiKeyStoreState extends Reducer {
  key: string;
}

export enum REFRESH_API_KEY {
  REQ = 'REFRESH_API_KEY_REQ',
  GOT = 'REFRESH_API_KEY_GOT',
  ERR = 'REFRESH_API_KEY_ERR'
}

export const initialState: RefreshApiKeyStoreState = {
  status: REQUEST_STATUSES.NONE,
  key: "",
  error: {}
};

const refreshApiKey = (
  state: RefreshApiKeyStoreState = initialState,
  action: {
    type: string;
    key: string;
    error: {};
  }
): RefreshApiKeyStoreState => {
  switch (action.type) {
    case REFRESH_API_KEY.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case REFRESH_API_KEY.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        key: action.key
      };
    case REFRESH_API_KEY.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default refreshApiKey;
