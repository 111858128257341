import * as React from 'react';
import { connect } from 'react-redux';
import { STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { closeModal } from 'Modal/actions/toggleModal';
import { Modal } from 'src/features/Common';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import {
  req as changeLeadStatus,
  RequestData as ChangeLeadStatusRequestData
} from '../../../../actions/changeLeadStatus';
import { RouteComponentProps, withRouter } from 'react-router';
import { LEAD_STATUSES } from 'src/features/Leads/types';
import { RejectReasonModalStyled } from './styles';
import { red } from 'shared/styled';

interface StateToProps {}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  closeModal: () => void;
  changeLeadStatus: (data: ChangeLeadStatusRequestData) => void;
}

interface State {
  selectedOption: string;
  comment: string;
}

// TODO move form to features/Common
// TODO refactor partial
type Props = Partial<RouteComponentProps<MatchParams>> &
  StateToProps &
  DispatchToProps;

class RejectReasonModal extends React.Component<Props, State> {
  state = {
    selectedOption: '',
    comment: ''
  };

  onClick = e => {
    this.props.changeLeadStatus({
      leadId: this.props.match.params.id,
      status: LEAD_STATUSES.REJECT,
      rejectReason: this.state.selectedOption + ' ' + this.state.comment
    });
    this.props.closeModal();
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  render() {
    const { comment, selectedOption } = this.state;

    return (
      <Modal name={MODAL_NAMES.REJECT_REASON_MODAL}>
        <RejectReasonModalStyled>
          <Select
            options={[
              { id: 'Отказ клиента', name: 'Отказ клиента' },
              { id: 'Отказ платформы', name: 'Отказ платформы' },
              { id: 'Отказ банков', name: 'Отказ банков' }
            ]}
            placeholder="Выберите причину отказа"
            name="selectedOption"
            value={selectedOption}
            onChange={this.onChange}
          />
          <Textarea
            onChange={this.onChange}
            label="Комментарий"
            name="comment"
            value={comment}
            placeholder="Неактуальна,контакт не отвечает"
          />
          <Button
            label="Отказать"
            onClick={this.onClick}
            backgroundColor={red}
            w="100%"
          />
        </RejectReasonModalStyled>
      </Modal>
    );
  }
}

const mapStateToProps = ({  }: STORE) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeModal, changeLeadStatus }, dispatch);

const RejectReasonModalConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(RejectReasonModal)
);

export { RejectReasonModalConnect as RejectReasonModal };
