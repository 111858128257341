import { GET_SECOND_COMPANY_BY_INN_THIRD_PARTY } from '../reducers/getSecondCompanyByInnThirdParty';
import { TaxInfo } from './getCompanyByInnThirdParty';

export interface ResponseDataType {
  companyAddress: string;
  companyFullName: string;
  companyShortName: string;
  customer223?: boolean;
  customer44?: boolean;
  debtor?: boolean;
  limits?: boolean;
  fss: string;
  inn: string;
  kpp: string;
  ogrn: string;
  okvedCode: string;
  okvedName: string;
  okvedPic: string;
  pfr: string;
  regDate: string;
  startCapital: string;
  employees: number;
  employeesYear: number;
  taxSystemCode: number;
  taxSystemName: string;
  taxes: {
    taxYear: number;
    taxInfo: TaxInfo[];
  };
}

export interface RequestDataType {
  inn: string;
}

export const req = (data: RequestDataType) => ({
  type:
    GET_SECOND_COMPANY_BY_INN_THIRD_PARTY.GET_SECOND_COMPANY_BY_INN_THIRD_PARTY_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type:
    GET_SECOND_COMPANY_BY_INN_THIRD_PARTY.GET_SECOND_COMPANY_BY_INN_THIRD_PARTY_GOT,
  data
});

export const err = (error: {}) => ({
  type:
    GET_SECOND_COMPANY_BY_INN_THIRD_PARTY.GET_SECOND_COMPANY_BY_INN_THIRD_PARTY_ERR,
  error
});
