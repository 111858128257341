import * as React from 'react';
import { connect } from 'react-redux';
import {
  CRM,
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { ContactRead } from '../../actions/setContactFormData';
import { req as getContact } from '../../actions/getContact';
import {
  req as patchDetail,
  reset as resetStateContactDetails
} from 'src/features/ContactDetailsForContact/actions/patchDetail';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Menu
} from 'src/features/Layouts/components';
import {
  ContactsData,
  setContactFormData
} from '../../actions/setContactFormData';
import { CardContactCompany } from 'src/features/Contacts/components/ContactView/styled';
import { CONTACTS_TABS } from 'src/features/Contacts/types';
import SCFApi from 'src/features/SCF/api';
import { ResponseDataType } from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { ContactDetails } from 'src/features/ContactDetailsForContact/components/ContactDetailsForContact';

interface StateToProps {
  contact: ContactRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  patchContactStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

interface State {
  activeTab: string;
  companyName: string;
}

interface MatchParams {
  id: string;
  tab: string;
}

interface DispatchToProps {
  getContact: (id: number | string) => void;
  setContactFormData: (data: ContactsData) => void;
  patchDetail: (id: number, data: any) => void;
  resetStateContactDetails: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ContactView extends React.Component<Props, State> {
  tab = this.props.match.params.tab;
  state = {
    activeTab: (this.tab && this.tab.toUpperCase()) || 'DEFAULT',
    companyName: ''
  };

  componentDidMount() {
    this.props.getContact(this.props.match.params.id);
  }

  handleActiveTab = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.patchContactStatus !== this.props.patchContactStatus) {
      this.props.getContact(this.props.match.params.id);
      this.props.resetStateContactDetails();
    }
    if (prevProps.contact !== this.props.contact) {
      SCFApi.getCompanyByInnThirdParty({
        inn: this.props.contact.companyINN
      }).then((res: ResponseDataType) =>
        this.setState({ companyName: res.companyShortName })
      );
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.match.params.tab === undefined) {
        this.setState({ activeTab: 'DEFAULT' });
      } else {
        this.setState({ activeTab: this.props.match.params.tab.toUpperCase() });
      }
    }
    if (prevState.activeTab !== this.state.activeTab) {
      const { history } = this.props;
      const { tab } = this.props.match.params;
      const baseUrl = history.location.pathname.replace(
        `/${tab && tab.toLowerCase()}`,
        ''
      );
      if (this.state.activeTab !== 'DEFAULT') {
        const url = baseUrl + `/${this.state.activeTab.toLowerCase()}`;
        history.push(url);
      } else {
        history.push(baseUrl);
      }
    }
  }

  handlePatchContact = (name: string, value: string) => {
    this.props.patchDetail(this.props.contact.id, { [name]: value });
  };

  navigateToBank = () => {
    const { history } = this.props;
    history.push(`/crm/companies/${this.props.contact.companyINN}`);
  };

  /* TODO extract ApplicationViewStyled and others */
  render() {
    const { contact, status, error, permissions } = this.props;
    return (
      <ApplicationViewStyled>
        {status === REQUEST_STATUSES.GOT && (
          <>
            <ApplicationId>Контакт #{contact.id}</ApplicationId>
            <ApplicationTitle>
              {contact.lastName} {contact.firstName} {contact.middleName}
            </ApplicationTitle>

            <ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <CardContactCompany onClick={this.navigateToBank}>
                    <span>ИНН {contact.companyINN}</span>
                    <span>{this.state.companyName}</span>
                  </CardContactCompany>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Должность</span>
                  <span>{contact.position}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Источник</span>
                  <span>{contact.source}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Дополнительная информация</span>
                  <span>{contact.additionalInfo}</span>
                </MainInfoItem>
              </MainInfoBlock>
            </ViewSeparatorTop>

            <Menu
              activeTab={this.state.activeTab}
              handleActiveTab={this.handleActiveTab}
              tabs={CONTACTS_TABS}
            />

            {this.state.activeTab === 'DEFAULT' && (
              <ContactDetails
                contact={contact}
                handlePatchContact={this.handlePatchContact}
              />
            )}

            {/* 
            {this.state.activeTab === 'Тут название !!!' && (
              <ContactsListByProduct productId={product.id} />
            )} */}

            {/* <ApplicationId>Курирует продукты</ApplicationId>
            <ProductsListByContact contactId={сontact.id} /> */}
          </>
        )}

        {/* TODO refactor into single one? */}
        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Contacts, User, ContactDetail }: CRM & STORE) => ({
  contact: Contacts.getContact.contact,
  status: Contacts.getContact.status,
  error: Contacts.getContact.error,
  patchContactStatus: ContactDetail.patchDetail.status,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getContact, setContactFormData, patchDetail, resetStateContactDetails },
    dispatch
  );

const ContactViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContactView)
);

export { ContactViewConnect as ContactView };
