import * as React from 'react';
import { TableRowStyled } from './styles';

interface Props {
  data: Array<string | JSX.Element>;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const TableRow: React.FC<Props> = ({
    data,
    onClick,
    onMouseEnter,
    onMouseLeave}: Props) => (
    <TableRowStyled onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    {data.map((item, key) => (
        <td key={key}>{item}</td>
    ))}
    </TableRowStyled>)
