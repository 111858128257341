import { GET_TENDERS } from '../reducers/getTenders';
import { TendersTypes } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: TendersTypes[];
}

export const req = data => ({
  type: GET_TENDERS.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_TENDERS.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_TENDERS.ERR,
  error
});
