import styled from 'styled-components';
import { InputContainerStyled } from 'src/shared/ui/Input/styles';

export const InputDateContainerStyled = styled.div`
  display: flex;
  column-gap: 15px;

  & > p {
    white-space: nowrap;
  }
`;

export const GuaranteesTermContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  & > ${InputContainerStyled} {
    width: 400px;
  }
`;
