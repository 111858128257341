import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetPropertiesStoreState extends Reducer {
}

export enum GET_PROPERTIES {
  REQ = 'GET_PROPERTIES_REQ',
  GOT = 'GET_PROPERTIES_GOT',
  ERR = 'GET_PROPERTIES_ERR'
}

export const initialState: GetPropertiesStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const getProperties = (
  state: GetPropertiesStoreState = initialState,
  action: {
    type: string;
    error: {};
  }
): GetPropertiesStoreState => {
  switch (action.type) {
    case GET_PROPERTIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PROPERTIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case GET_PROPERTIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getProperties;
