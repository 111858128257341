import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { blue } from 'shared/styled';
import { ApplicationSubtitle } from '../styled';

export const EditDocumentsLink = styled(Link)`
  color: ${blue};
`;

export const CompanyDocumentsTtitle = styled(ApplicationSubtitle)`
  margin: 0 0 20px 0;
`;
