import * as React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

import { STORE, REQUEST_STATUSES } from 'globaltypes';

import { InputFileProps, inputFileDefaultProps } from 'shared/ui/InputFile';
import {
  IPostComment,
  CommentsContextType,
  CommentsContext,
  AttachedDocumentsBlock,
  ActionButtonsBlock
} from 'Comments/components';

import {
  AddCommentContainer,
  AddCommentBlockStyled,
  AddCommentLabel,
  DropArea,
  CommentTextarea,
  AddCommentLoaderBar,
  DisabledBlock
} from '../styles';

type State = Partial<IPostComment>;

interface OwnProps extends Partial<InputFileProps> {
  title?: string;
}

interface StateToProps {
  postCommentStatus: REQUEST_STATUSES;
}

type Props = OwnProps & StateToProps;

const defaultProps = {
  ...inputFileDefaultProps,
  name: 'add-comment-textarea',
  title: 'Оставить комментарий'
};

const initialState = {
  files: [],
  comment: ''
};

const AddTopicBlock: React.FC<Props> = ({
  accept,
  maxSize,
  multiple,
  name,
  title,
  postCommentStatus
}) => {
  const { postComment, disabled }: CommentsContextType = React.useContext(
    CommentsContext
  );

  // add generic State type and define why it's warning
  const [state, setState] = React.useState<any>(initialState);

  const onChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) =>
    disabled
      ? null
      : setState(prevState => ({ ...prevState, comment: currentTarget.value }));

  const onFilesUpload = files => {
    setState(prevState => ({
      ...prevState,
      files: [...state.files, ...files]
    }));
  };

  const postCommentHandler = () => postComment(state);

  React.useEffect(
    () => {
      setState(prevState => ({ ...initialState }));
    },
    [postCommentStatus === REQUEST_STATUSES.GOT]
  );

  return (
    <AddCommentContainer>
      <AddCommentLabel name={name} label={title} />

      <Dropzone
        disableClick
        accept={accept}
        maxSize={maxSize}
        multiple={multiple}
        onDrop={onFilesUpload}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          open
        }) => (
          <DropArea {...getRootProps()}>
            <AddCommentBlockStyled
              isEmpty={state.files.length === 0}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            >
              <input {...getInputProps()} />

              <CommentTextarea
                name={name}
                value={state.comment}
                onChange={onChange}
                placeholder="Напишите сообщение"
              />

              <AttachedDocumentsBlock documents={state.files} />
              <AddCommentLoaderBar
                isFiles={state.files.length > 0}
                status={postCommentStatus}
                processText="Отправка сообщения..."
              />
            </AddCommentBlockStyled>

            <ActionButtonsBlock
              openFileUploader={open}
              postCommentHandler={() => !!state.comment && postCommentHandler()}
              postEnabled={!!state.comment}
            />
          </DropArea>
        )}
      </Dropzone>

      {disabled && (
        <DisabledBlock>
          <p>В текущем статусе заявки невозможно оставить комментарий</p>
        </DisabledBlock>
      )}
    </AddCommentContainer>
  );
};

AddTopicBlock.defaultProps = defaultProps;

const mapStateToProps = ({ Comment }: STORE) => ({
  postCommentStatus: Comment.postComment.status
});

const AddTopicBlockConnect = connect<StateToProps>(mapStateToProps)(
  AddTopicBlock
);

export { AddTopicBlockConnect as AddTopicBlock };
