import styled from 'styled-components';
import { rr, navyBlue } from 'shared/styled';

export const ForgotPasswordBtnStyled = styled.button.attrs({
  type: 'button'
})`
  width: 100px;
  padding: 0;

  cursor: pointer;
  border: none;
  background-color: transparent;

  font-family: ${rr};
  font-size: 1.2rem;
  text-decoration: underline;
  color: ${navyBlue};
`;
