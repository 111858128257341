import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { format, parseISO } from 'date-fns';
import { ScrollTopComponent } from 'src/features/Common';
import { Menu } from 'src/features/Layouts/components';
import { STORE } from 'src/globaltypes';
import {
  req as getOneDiscountAuction,
  reset as resetOneDiscountAuction,
  ResponseData
} from 'src/features/SCF/actions/getOneDiscountAuction';
import { Header, Title, Info } from './styled';
import { ApplicationsCompany } from '../AuctionTabs/ApplicationsCompany/ApplicationsCompany';
import { AuctionSupplier } from '../AuctionTabs/Supplier/AuctionSupplier';
import { MonetaryClaims } from '../AuctionTabs/MonetaryClaims/MonetaryClaims';
import {
  formatNumberWithComma,
  formSumStringThousands
} from 'shared/utils/Utils';

interface StateToProps {
  auctionInfo: ResponseData;
}

interface DispatchToProps {
  getOneDiscountAuction: (guid: string) => void;
  resetOneDiscountAuction: () => void;
}

interface MatchParams {
  guid: string;
  tab?: keyof typeof AUCTIONS_TABS;
}

export enum AUCTIONS_TABS {
  DEFAULT = 'Заявки по компании',
  SUPPLIERS = 'Поставщики',
  MONETARY_CLAIMS = 'Денежные требования'
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const AuctionInfo = [
  { name: 'liquidityLimit', label: 'Лимит задолженности:', sub: 'руб.' },
  {
    name: 'minDiscountRate',
    label: 'Минимальная ставка дисконта:',
    sub: '% годовых'
  },
  {
    name: 'targetDiscountRate',
    label: 'Целевая ставка дисконта:',
    sub: '% годовых'
  },
  { name: 'resultsDateTime', label: 'Подведение итогов:' },
  { name: 'payDate', label: 'Дата плановой оплаты:' }
];

const DiscountAuctionsView: React.FC<Props> = ({
  getOneDiscountAuction,
  resetOneDiscountAuction,
  auctionInfo,
  match,
  history
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof AUCTIONS_TABS>(
    (match.params.tab &&
      (match.params.tab.toUpperCase() as keyof typeof AUCTIONS_TABS)) ||
      'DEFAULT'
  );

  React.useEffect(
    () => {
      if (match.params.guid) {
        getOneDiscountAuction(match.params.guid);
      }

      return () => resetOneDiscountAuction();
    },
    [match.params.guid]
  );

  const handleActiveTab = (tabName: keyof typeof AUCTIONS_TABS) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Header>
        <p>{match.params.guid}</p>
        <Title>
          Аукцион # {match.params.guid.substring(match.params.guid.length - 5)}
        </Title>
      </Header>

      <Info>
        {AuctionInfo.map((info, index) => (
          <div key={index}>
            <p>{info.label}</p>
            <p>
              {(info.name === 'resultsDateTime' || info.name === 'payDate') &&
              auctionInfo[info.name]
                ? format(parseISO(auctionInfo[info.name]), 'dd.MM.yyyy')
                : info.name === 'liquidityLimit'
                  ? formSumStringThousands(auctionInfo[info.name], {
                      maxFractionDigits: 0,
                      minFractionDigits: 0
                    })
                  : formatNumberWithComma(+auctionInfo[info.name])}
            </p>
            <p>
              {info.name === 'resultsDateTime' && auctionInfo[info.name]
                ? format(parseISO(auctionInfo[info.name]), 'HH:mm')
                : info.sub}
            </p>
          </div>
        ))}
      </Info>

      <Menu
        tabs={AUCTIONS_TABS}
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
      />

      {activeTab === 'DEFAULT' && <ApplicationsCompany />}

      {activeTab === 'SUPPLIERS' && <AuctionSupplier />}

      {activeTab === 'MONETARY_CLAIMS' && <MonetaryClaims />}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  auctionInfo: SCF.getOneDiscountAuction.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getOneDiscountAuction, resetOneDiscountAuction },
    dispatch
  );

const DiscountAuctionsViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(DiscountAuctionsView)
);

export { DiscountAuctionsViewConnect as DiscountAuctionsView };
