import { POST_UPLOAD_FIN_DOC_INTERNAL } from '../reducers/postUploadFinDocInternal';

export const req = (guid: string, data: FormData) => ({
  type: POST_UPLOAD_FIN_DOC_INTERNAL.REQ,
  data,
  guid
});

export const got = () => ({
  type: POST_UPLOAD_FIN_DOC_INTERNAL.GOT
});

export const err = (error: {}) => ({
  type: POST_UPLOAD_FIN_DOC_INTERNAL.ERR,
  error
});
