import { REQUEST_STATUSES, Reducer } from 'globaltypes';

export interface PostNonFinancialDocumentExternalStoreState extends Reducer {}

export enum POST_NON_FINANCIAL_DOCUMENT_EXTERNAL {
  REQ = 'POST_NON_FINANCIAL_DOCUMENT_EXTERNAL_REQ',
  GOT = 'POST_NON_FINANCIAL_DOCUMENT_EXTERNAL_GOT',
  ERR = 'POST_NON_FINANCIAL_DOCUMENT_EXTERNAL_ERR'
}

export const initialState: PostNonFinancialDocumentExternalStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postNonFinancialDocumentExternal = (
  state: PostNonFinancialDocumentExternalStoreState = initialState,
  action: {
    type: string;
    data: POST_NON_FINANCIAL_DOCUMENT_EXTERNAL;
    error: {};
  }
): PostNonFinancialDocumentExternalStoreState => {
  switch (action.type) {
    case POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };

    case POST_NON_FINANCIAL_DOCUMENT_EXTERNAL.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default postNonFinancialDocumentExternal;
