import { combineReducers } from 'redux';
import setContactFormData, {
  SetContactDataStoreState
} from './setContactFormData';
import getContactsList, { GetContactsListStoreState } from './getContactsList';
import getContact, { GetContactStoreState } from './getContact';
import deleteContact, { DeleteContactStoreState } from './deleteContact';
import createContact, { CreateContactStoreState } from './createContact';

export interface ContactsReducers {
  setContactFormData: SetContactDataStoreState;
  getContactsList: GetContactsListStoreState;
  getContact: GetContactStoreState;
  deleteContact: DeleteContactStoreState;
  createContact: CreateContactStoreState;
}

export const Contacts = combineReducers({
  getContact,
  setContactFormData,
  getContactsList,
  deleteContact,
  createContact
});
