import { GET_LIMITS_BANK } from '../reducers/getLimits';
import { LimitsResponseData } from '../types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: LimitsResponseData[];
}

export const req = () => ({
  type: GET_LIMITS_BANK.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_LIMITS_BANK.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_LIMITS_BANK.ERR,
  error
});
