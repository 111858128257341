import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { FinancialStatementsAllType } from '../types';

export interface GetExternalAgentSelectPeriodStoreState extends Reducer {
  data: FinancialStatementsAllType[];
}

export enum GET_EXTERNAL_AGENT_SELECT_PERIOD {
  REQ = 'GET_EXTERNAL_AGENT_SELECT_PERIOD_REQ',
  GOT = 'GET_EXTERNAL_AGENT_SELECT_PERIOD_GOT',
  ERR = 'GET_EXTERNAL_AGENT_SELECT_PERIOD_ERR',
  RESET = 'RESET_EXTERNAL_AGENT_SELECT_PERIOD'
}

export const initialState: GetExternalAgentSelectPeriodStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getExternalAgentSelectPeriod = (
  state: GetExternalAgentSelectPeriodStoreState = initialState,
  action: {
    type: string;
    data: FinancialStatementsAllType[];
    year: number;
    taxPeriodCode: number;
    error: {};
  }
): GetExternalAgentSelectPeriodStoreState => {
  switch (action.type) {
    case GET_EXTERNAL_AGENT_SELECT_PERIOD.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EXTERNAL_AGENT_SELECT_PERIOD.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: [...state.data, ...action.data]
      };
    case GET_EXTERNAL_AGENT_SELECT_PERIOD.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_EXTERNAL_AGENT_SELECT_PERIOD.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getExternalAgentSelectPeriod;
