import { put, takeLatest } from 'redux-saga/effects';
import { GET_DOWNLOAD_DOCUMENTS, err } from '../actions/getDownloadDocument';

function* getDownloadDocuments(action: {
  type: GET_DOWNLOAD_DOCUMENTS;
  guid: string;
}): Generator {
  try {
    const url = `/api/internal-agent/financial-document/download/${
      action.guid
    }`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.docx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDossierSaga(): Generator {
  yield takeLatest(GET_DOWNLOAD_DOCUMENTS.REQ, getDownloadDocuments);
}
