import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { BeneficiariesTypes } from 'src/features/Beneficiaries/types';

export interface GetClientBeneficiariesStoreState extends Reducer {
  data: BeneficiariesTypes[];
}

export enum GET_CLIENT_BENEFICIARIES {
  REQ = 'GET_CLIENT_BENEFICIARIES_REQ',
  GOT = 'GET_CLIENT_BENEFICIARIES_GOT',
  ERR = 'GET_CLIENT_BENEFICIARIES_ERR'
}

export const initialState: GetClientBeneficiariesStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getClientBeneficiaries = (
  state: GetClientBeneficiariesStoreState = initialState,
  action: {
    type: string;
    data: BeneficiariesTypes[];
    error: {};
  }
): GetClientBeneficiariesStoreState => {
  switch (action.type) {
    case GET_CLIENT_BENEFICIARIES.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CLIENT_BENEFICIARIES.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_CLIENT_BENEFICIARIES.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getClientBeneficiaries;
