import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { AskStyled } from 'src/features/CooperationForProducts/components/styled';
import {
  Field,
  Input,
  ProductSizeContainer,
  ProductTerm,
  ProductTermContainer,
  ProgressBar,
  ProgressStep,
  Tooltip
} from 'src/features/BasicParametersForProducts/components/styled';

import { req as patchBasisParams } from 'src/features/BasicParametersForProducts/actions/patchCabinetBasicParams';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

interface MatchParams {
  id: string;
}

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  minSize: number;
  minComfortSize: number;
  maxComfortSize: number;
  maxSize: number;
  activeMinSize: boolean;
  activeMinComfortSize: boolean;
  activeMaxComfortSize: boolean;
  activeMaxSize: boolean;
  isAdminMinSize: boolean;
  isAdminMinComfortSize: boolean;
  isAdminMaxComfortSize: boolean;
  isAdminMaxSize: boolean;
  activeMinTermInMonthes: boolean;
  minTermInMonthes: any;
  isAdminMinTermInMonthes: boolean;
  activeMaxTermInMonthes: boolean;
  maxTermInMonthes: any;
  isAdminMaxTermInMonthes: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchBasisParams: (guid: string, data: any) => void;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  AnyProps;

class BasicCabinetParametersForProducts extends React.Component<Props, State> {
  state = {
    minSize: null,
    minComfortSize: null,
    maxComfortSize: null,
    maxSize: null,
    activeMinSize: false,
    activeMinComfortSize: false,
    activeMaxComfortSize: false,
    activeMaxSize: false,
    isAdminMinSize: false,
    isAdminMinComfortSize: false,
    isAdminMaxComfortSize: false,
    isAdminMaxSize: false,
    activeMinTermInMonthes: false,
    minTermInMonthes: null,
    isAdminMinTermInMonthes: false,
    activeMaxTermInMonthes: false,
    maxTermInMonthes: null,
    isAdminMaxTermInMonthes: false
  };

  componentDidMount() {
    this.setState({ ...this.props.product } as any);

    if (this.props.product.minSize !== null) {
      this.setState({
        minSize: this.props.product.minSize,
        activeMinSize: true
      });
    }
    if (this.props.product.minComfortSize !== null) {
      this.setState({
        minComfortSize: this.props.product.minComfortSize,
        activeMinComfortSize: true
      });
    }
    if (this.props.product.maxComfortSize !== null) {
      this.setState({
        maxComfortSize: this.props.product.maxComfortSize,
        activeMaxComfortSize: true
      });
    }
    if (this.props.product.maxSize !== null) {
      this.setState({
        maxSize: this.props.product.maxSize,
        activeMaxSize: true
      });
    }
    if (this.props.product.maxTermInMonthes !== null) {
      this.setState({
        maxTermInMonthes: this.props.product.maxTermInMonthes,
        activeMaxTermInMonthes: true
      });
    }
    if (this.props.product.minTermInMonthes !== null) {
      this.setState({
        minTermInMonthes: this.props.product.minTermInMonthes,
        activeMinTermInMonthes: true
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.minSize !== this.state.minSize) {
      this.setState({ isAdminMinSize: false });
    }
    if (prevState.minComfortSize !== this.state.minComfortSize) {
      this.setState({ isAdminMinComfortSize: false });
    }
    if (prevState.maxComfortSize !== this.state.maxComfortSize) {
      this.setState({ isAdminMaxComfortSize: false });
    }
    if (prevState.maxTermInMonthes !== this.state.maxTermInMonthes) {
      this.setState({ isAdminMaxTermInMonthes: false });
    }
    if (prevState.maxSize !== this.state.maxSize) {
      this.setState({ isAdminMaxSize: false });
    }
    if (prevState.minTermInMonthes !== this.state.minTermInMonthes) {
      this.setState({ isAdminMinTermInMonthes: false });
    }
  }

  formatNumber = number => {
    if (number !== null) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    const values = {
      activeMinSize: { minSize: +this.state.minSize },
      activeMinComfortSize: { minComfortSize: +this.state.minComfortSize },
      activeMaxComfortSize: { maxComfortSize: +this.state.maxComfortSize },
      activeMaxSize: { maxSize: +this.state.maxSize },
      activeMinTermInMonthes: {
        minTermInMonthes: +this.state.minTermInMonthes
      },
      activeMaxTermInMonthes: {
        maxTermInMonthes: +this.state.maxTermInMonthes
      }
    };
    const patchParams = values[key] || { [key]: value };
    this.setState({ [key]: value } as Pick<any, keyof State>);
    this.props.patchBasisParams(id, patchParams);
  };

  handleChangeSizeProduct = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value.replace(/\s/g, '') } as any);
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin =
      roles.includes('Admin') ||
      permissions.includes('Admin') ||
      roles.includes('Bank');

    const allActive =
      this.state.activeMinSize &&
      this.state.activeMinComfortSize &&
      this.state.activeMaxComfortSize &&
      this.state.activeMaxSize;

    return (
      <ApplicationViewStyled>
        <AskStyled>Размер продукта:</AskStyled>

        <ProgressBar
          one={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          two={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          three={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          four={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          five={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          six={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize == this.state.maxSize
          }
          seven={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          eight={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minComfortSize == 0 &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          nine={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          ten={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          eleven={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize < this.state.maxSize
          }
          twelve={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxSize == 0
          }
          thirteen={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize == this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxSize == 0
          }
          fourteen={
            allActive &&
            this.state.minSize > 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.minComfortSize < this.state.maxComfortSize &&
            this.state.maxComfortSize == this.state.maxSize
          }
          fifteen={
            allActive &&
            this.state.minSize == 0 &&
            this.state.minSize < this.state.minComfortSize &&
            this.state.maxComfortSize == 0 &&
            this.state.maxComfortSize < this.state.maxSize
          }
        >
          {[...Array(5)].map(step => (
            <ProgressStep key={step} />
          ))}
        </ProgressBar>

        <ProductSizeContainer>
          <Field>
            {!isAdmin &&
            this.state.activeMinSize &&
            (this.state.minSize == '0' ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinSize}
                name="minSize"
                value={this.formatNumber(this.state.minSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMinSize}
              />
            )}
            <Tooltip>Минимальная сумма продукта</Tooltip>
            {(!this.state.activeMinSize || isAdmin) &&
              !!this.state.minSize &&
              this.state.minSize !== this.props.product.minSize &&
              !this.state.isAdminMinSize && (
                <Button
                  label="Сохранить"
                  w='fit-content'
                  h='30px'
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinSize: true });
                    }
                    this.handleButtonClick('activeMinSize', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minSize)}
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMinComfortSize &&
            (this.state.minComfortSize == '0' ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize < this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMinComfortSize}
                name="minComfortSize"
                value={this.formatNumber(this.state.minComfortSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMinComfortSize}
              />
            )}
            <Tooltip>Комфортная нижняя граница</Tooltip>
            {(!this.state.activeMinComfortSize || isAdmin) &&
              !!this.state.minComfortSize &&
              this.state.minComfortSize !== this.props.product.minComfortSize &&
              !this.state.isAdminMinComfortSize && (
                <Button
                  label="Сохранить"
                  w='fit-content'
                  h='30px'
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinComfortSize: true });
                    }
                    this.handleButtonClick('activeMinComfortSize', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minComfortSize)}
                />
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxComfortSize &&
            (this.state.maxComfortSize == '0' ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minComfortSize == 0 &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxComfortSize}
                name="maxComfortSize"
                value={this.formatNumber(this.state.maxComfortSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMaxComfortSize}
              />
            )}
            <Tooltip>Комфортная верхняя граница</Tooltip>
            {(!this.state.activeMaxComfortSize || isAdmin) &&
              !!this.state.maxComfortSize &&
              this.state.maxComfortSize !== this.props.product.maxComfortSize &&
              !this.state.isAdminMaxComfortSize && (
                <>
                  <Button
                    label="Сохранить"
                    w='fit-content'
                    h='30px'
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxComfortSize: true });
                      }
                      this.handleButtonClick('activeMaxComfortSize', true);
                    }}
                    disabled={!/^\d+$/.test(this.state.maxComfortSize)}
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
          <Field>
            {!isAdmin &&
            this.state.activeMaxSize &&
            (this.state.maxSize == '0' ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxSize == 0) ||
              (allActive &&
                this.state.minSize == 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize < this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize) ||
              (allActive &&
                this.state.minSize > 0 &&
                this.state.minSize == this.state.minComfortSize &&
                this.state.minComfortSize < this.state.maxComfortSize &&
                this.state.maxComfortSize == this.state.maxSize)) ? (
              <div />
            ) : (
              <Input
                active={this.state.activeMaxSize}
                name="maxSize"
                value={this.formatNumber(this.state.maxSize)}
                onChange={this.handleChangeSizeProduct}
                disabled={!isAdmin && this.state.activeMaxSize}
              />
            )}
            <Tooltip>Максимальная сумма продукта</Tooltip>
            {(!this.state.activeMaxSize || isAdmin) &&
              !!this.state.maxSize &&
              this.state.maxSize !== this.props.product.maxSize &&
              !this.state.isAdminMaxSize && (
                <>
                  <Button
                    label="Сохранить"
                    w='fit-content'
                    h='30px'
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminMaxSize: true });
                      }
                      this.handleButtonClick('activeMaxSize', true);
                    }}
                    disabled={!/^\d+$/.test(this.state.maxSize)}
                  />
                  <p>Подсказка: если ограничения по сумме нет, введите “0”</p>
                </>
              )}
          </Field>
        </ProductSizeContainer>

        <ProductTermContainer>
          <AskStyled>Срок продукта (месяцев):</AskStyled>
          <ProductTerm>
            <p>Минимальный:</p>
            <Input
              active={this.state.activeMinTermInMonthes}
              type="text"
              name="minTermInMonthes"
              value={this.state.minTermInMonthes}
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMinTermInMonthes}
            />
            <span>мес.</span>
            {(!this.state.activeMinTermInMonthes || isAdmin) &&
              !!this.state.minTermInMonthes &&
              this.state.minTermInMonthes !==
                this.props.product.minTermInMonthes &&
              !this.state.isAdminMinTermInMonthes && (
                <Button
                  label="Сохранить"
                  w='fit-content'
                  h='30px'
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMinTermInMonthes: true });
                    }
                    this.handleButtonClick('activeMinTermInMonthes', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.minTermInMonthes)}
                />
              )}
          </ProductTerm>
          <ProductTerm>
            <p>Максимальный:</p>
            <Input
              active={this.state.activeMaxTermInMonthes}
              type="text"
              name="maxTermInMonthes"
              value={this.state.maxTermInMonthes}
              onChange={this.handleInputChange}
              disabled={!isAdmin && this.state.activeMaxTermInMonthes}
            />
            <span>мес.</span>
            {(!this.state.activeMaxTermInMonthes || isAdmin) &&
              !!this.state.maxTermInMonthes &&
              this.state.maxTermInMonthes !==
                this.props.product.maxTermInMonthes &&
              !this.state.isAdminMaxTermInMonthes && (
                <Button
                  label="Сохранить"
                  w='fit-content'
                  h='30px'
                  onClick={() => {
                    if (isAdmin) {
                      this.setState({ isAdminMaxTermInMonthes: true });
                    }
                    this.handleButtonClick('activeMaxTermInMonthes', true);
                  }}
                  disabled={!/^\d+$/.test(this.state.maxTermInMonthes)}
                />
              )}
          </ProductTerm>
        </ProductTermContainer>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ BasisParams }: CRM) => ({
  status: BasisParams.patchCabinetBasisParams.status,
  error: BasisParams.patchCabinetBasisParams.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchBasisParams }, dispatch);

const BasicCabinetParametersForProductsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BasicCabinetParametersForProducts)
);

export {
  BasicCabinetParametersForProductsConnect as BasicCabinetParametersForProducts
};
