import { call, put, takeEvery } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/getUserStats';
import { GET_USER_STATS_INTERNAL } from 'entities/Cabinet/InternalAgent/model/reducers/getUserStats';
import InternalAgentRoleApi from 'entities/Cabinet/InternalAgent/api';

function* getUserStats(action: { type: GET_USER_STATS_INTERNAL }): Generator {
  try {
    const res: any = yield call(InternalAgentRoleApi.getUserStats);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getUserStatsSaga(): Generator {
  yield takeEvery(GET_USER_STATS_INTERNAL.REQ, getUserStats);
}
