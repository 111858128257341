import * as React from 'react';
import { MultiComponent } from 'shared/ui/MultiComponent';
import { Input, Props as InputProps } from 'shared/ui/Input';

interface Props extends InputProps {
  selected: string[];
  onMultiAdd: (debtorName: string) => void;
  onMultiRemove: (index: number) => void;
}

interface State {
  value: string;
}

export class MultiInput extends React.Component<Props, State> {
  state = {
    value: ''
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState({
      value
    });
  };

  onEnterPress = (e: KeyboardEvent) => {
    const { value } = this.state;

    if (e.key === 'Enter' && value !== '') {
      e.preventDefault();
      e.stopPropagation();

      this.setState(
        {
          value: ''
        },
        () => this.props.onMultiAdd(value)
      );
    }
  };

  render() {
    const { onMultiRemove, selected } = this.props;
    const { value } = this.state;

    const updatedProps = {
      ...this.props,
      value,
      onChange: this.onChange,
      onKeyPress: this.onEnterPress
    };

    return (
      <MultiComponent selected={selected} onDeleteSelected={onMultiRemove}>
        <Input {...updatedProps} />
      </MultiComponent>
    );
  }
}
