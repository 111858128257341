import { GET_TENDER_TELEDOC } from '../reducers/getTenderTeledoc';

export interface ResponseData {
  id: number;
  createdDateTime: string;
  modifiedDateTime: any;
  customerINN: string;
  customerName: string;
  supplierINN: string;
  supplierName: string;
  publicationDate: string;
  startPrice: number;
  tenderType: any;
  tenderSegment: number;
  eruzId: string;
  protocolDate: string;
  hasWinner: any;
  contractConcluded: any;
  contractGuid: string;
  tenderCompleted: any;
  tenderFailed: any;
  tenderLink: string;
  writeoffRequest: any;
  ikz: string;
  subject: string;
  purchaseRegion: string;
  supplierDeterminationMethod: string;
  platformName: string;
  platformLink: string;
  status: string;
  isClosedAuction: boolean;
  isSingleSupplier: boolean;
  isPublic: boolean;
  sumFinal: number;
  auctionDate: string;
  customerEmail: string;
  customerPhone: string;
  isDeleted: boolean;
  lotCode: string;
  subjectType: any;
}

export const req = (id: string, eis: string) => ({
  type: GET_TENDER_TELEDOC.REQ,
  id,
  eis
});

export const got = (data: ResponseData) => ({
  type: GET_TENDER_TELEDOC.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_TENDER_TELEDOC.ERR,
  error
});
