import * as React from 'react';

import { EachComment } from 'Comments/components';
import { Comment } from 'Comments/types';

import { RepliesContainerStyled, ShowRepliesButton } from './styles';

interface Props {
  replies: Comment[];
}

export const RepliesContainer: React.FC<Props> = ({ replies }: Props) => {
  const [isRepliesShown, showReplies] = React.useState<boolean>(
    replies.length < 3
  );

  return (
    replies.length > 0 && (
      <RepliesContainerStyled isRepliesShown={isRepliesShown}>
        {replies.map(reply => (
          <EachComment
            key={reply.id}
            comment={reply}
            isActive={isRepliesShown}
          />
        ))}

        {!isRepliesShown && (
          <ShowRepliesButton onClick={() => showReplies(true)}>
            Показать все ответы
          </ShowRepliesButton>
        )}
      </RepliesContainerStyled>
    )
  );
};
