import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getProductsApplicationLead';

import { GET_PRODUCTS_APPLICATION_LEADS } from '../reducers/getProductApplicationLead';
import LeadsApi from '../api';

function* getProductApplicationLead(action: {
  type: GET_PRODUCTS_APPLICATION_LEADS;
  id: string;
}): Generator {
  try {
    const res: any = yield call(
      LeadsApi.getProductsOfApplicationLead,
      action.id
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductApplicationLeadSaga(): Generator {
  yield takeLatest(
    GET_PRODUCTS_APPLICATION_LEADS.REQ,
    getProductApplicationLead
  );
}
