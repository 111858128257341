import { PATCH_CABINET_CONTRACT } from '../reducers/patchCabinetContract';

export const req = (guid: string, data: any) => ({
  type: PATCH_CABINET_CONTRACT.REQ,
  guid,
  data
});

export const got = () => ({
  type: PATCH_CABINET_CONTRACT.GOT
});

export const err = (error: {}) => ({
  type: PATCH_CABINET_CONTRACT.ERR,
  error
});
