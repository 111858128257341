import getSummaryPersonSaga from './getSummaryPerson';
import getPersonOfCompaniesSaga from './getPersonOfCompanies';
import getBeneficiariesOfCompaniesPersonSaga from './getBeneficiariesOfCompanies';
import getCompanyByInnConfirmedBeneficiariesSaga from './getCompanyByInnConfirmedBeneficiaries';
import getCompanyByInnConfirmedPersonSaga from './getCompanyByInnConfirmedPerson';
import getSearchPersonSaga from './getSearchPerson';

export default [
  getPersonOfCompaniesSaga,
  getBeneficiariesOfCompaniesPersonSaga,
  getSummaryPersonSaga,
  getCompanyByInnConfirmedBeneficiariesSaga,
  getCompanyByInnConfirmedPersonSaga,
  getSearchPersonSaga
];
