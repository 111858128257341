import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/SCFRegisterSupplier';
import { SCF_REGISTER_SUPPLIER } from 'entities/SCF/Supplier/model/reducers/SCFRegisterSupplier';
import SCFApi from 'entities/SCF/Supplier/api';

function* SCFRegisterSupplier(action: {
  type: SCF_REGISTER_SUPPLIER;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.registerSupplier, action.data); //TODO
    yield put(got(action.data));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* SCFRegisterSupplierSaga() {
  yield takeLatest(
    SCF_REGISTER_SUPPLIER.SCF_REGISTER_SUPPLIER_REQ,
    SCFRegisterSupplier
  );
}
