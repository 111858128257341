import { FactoringSettingsByDebtorInn } from '../actions/setFactoringSettingsByDebtorInn';

export interface SetFactoringSettingsByDebtorInnStoreState
  extends Partial<FactoringSettingsByDebtorInn> {}

export enum SET_FACTORING_SETTINGS_BY_DEBTOR_INN {
  SET = 'SET_FACTORING_SETTINGS_BY_DEBTOR_INN_SET',
  RESET = 'SET_FACTORING_SETTINGS_BY_DEBTOR_INN_RESET'
}

export const initialState: SetFactoringSettingsByDebtorInnStoreState = {
  debtorInn: '',
  volume: 0,
  autoLimitPolicy: 0,
  factoringBaseRate: 0,
  vatSize: 0,
  factualUsageOnly: false,
  gracePeriodBaseRate: 0,
  gracePeriodFullRate: 0,
  regressPeriodBaseRate: 0,
  regressPeriodFullRate: 0,
  defaultGracePeriodDays: 0,
  defaultRegressPeriodDays: 0,
  regressPeriodIsInfinite: false,
  defaultMaximalClaimDaysToMaturity: 0,
  defaultMaximalClaimSum: 0,
  defaultMaximalShareOfFunding: 0,
  defaultMaximalCessionShare: 0,
  defaultMinimalClaimDaysToMaturity: 0,
  defaultMinimalShareOfFunding: 0,
  defaultMinimalCessionShare: 0,
  fundingSharePolicy: 0,
  isRegressed: false,
  isNotHidden: false,
  isForOnboardedClientsOnly: false,
  rateIsSurplusToKeyRate: false
};

const setFactoringSettingsByDebtorInn = (
  state: SetFactoringSettingsByDebtorInnStoreState = initialState,
  action: {
    type: string;
    data: FactoringSettingsByDebtorInn;
  }
): SetFactoringSettingsByDebtorInnStoreState => {
  switch (action.type) {
    case SET_FACTORING_SETTINGS_BY_DEBTOR_INN.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_FACTORING_SETTINGS_BY_DEBTOR_INN.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setFactoringSettingsByDebtorInn;
