import { combineReducers } from 'redux';
import postCreateBuyersToSupplierLink, {
  PostCreateBuyersToSupplierLinkStoreState
} from './postCreateBuyersToSupplierLink';
import getBuyersToSupplierLinkList, {
  GetBuyersToSupplierLinkListStoreState
} from './getBuyersToSupplierLinkList';

export interface BuyersToSupplierLinkReducers {
  postCreateBuyersToSupplierLink: PostCreateBuyersToSupplierLinkStoreState;
  getBuyersToSupplierLinkList: GetBuyersToSupplierLinkListStoreState;
}

export const BuyersToSupplierLink = combineReducers({
  postCreateBuyersToSupplierLink,
  getBuyersToSupplierLinkList
});
