import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err,
  RequestData
} from 'entities/SCF/Debtor/model/actions/patchSetDiscountMargin';

import { PATCH_SET_DISCOUNT_MARGIN } from 'entities/SCF/Debtor/model/reducers/patchSetDiscountMargin';
import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* patchSetDiscountMargin(action: {
  type: PATCH_SET_DISCOUNT_MARGIN;
  inn: string;
  data: RequestData;
}) {
  try {
    yield call(SCFDebtorApi.patchSetDiscountMargin, action.inn, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchSetDiscountMarginSaga() {
  yield takeLatest(PATCH_SET_DISCOUNT_MARGIN.REQ, patchSetDiscountMargin);
}
