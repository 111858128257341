import styled from 'styled-components';

import { Checkbox } from 'shared/ui/Checkbox';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';
import { Button } from 'shared/ui/Button';

import { navyBlue, gray, lightGray, black } from 'shared/styled';

export const ChooseBanksModalStyled = styled.div`
  width: 400px;
  margin-bottom: -20px;

  h2 {
    margin-bottom: 20px;
    color: ${black};
  }
`;

export const ChooseAllCheckbox = styled(Checkbox)`
  color: ${gray};
`;

export const BanksForm = styled(Form)`
  width: calc(100% + 90px);
  height: 400px;

  margin-left: -45px;
`;

export const BanksFormSection = styled(FormSection)`
  width: 100%;
  height: 300px;

  margin: 0;

  overflow-y: scroll;

  & > label {
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding: 0 40px;
    border-top: 1px solid ${lightGray};

    &:last-child {
      border-bottom: 1px solid ${lightGray};
    }
  }
`;

export const BtnsBlock = styled.div`
  width: 70%;

  padding-right: 40px;
  margin-top: 30px;

  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;

  button[type='submit'] {
    width: 200px;
  }
`;

export const CancelButton = styled(Button)`
  width: auto;

  color: ${gray};
  background-color: transparent;

  &&:hover {
    color: ${navyBlue};
    background-color: transparent;
  }
`;
