import { ADD_BANK_MANAGER } from '../reducers/addBankManager';

export interface RequestData {
  email:string;
  firstName:string;
  lastName:string;
  phoneNumber:string;
  bankId:number|string;
}

export const req = (data: RequestData) => ({
  type: ADD_BANK_MANAGER.REQ,
  data
});

export const got = () => ({
  type: ADD_BANK_MANAGER.GOT
});

export const err = (error: {}) => ({
  type: ADD_BANK_MANAGER.ERR,
  error
});
