import { combineReducers } from 'redux';
import getClientsForAgent, { GetClientsForAgentStoreState } from './getClients';
import postCreateClient, {
  PostCreateClientStoreState
} from './postCreateClient';

export interface ClientsForAgentReducers {
  getClientsForAgent: GetClientsForAgentStoreState;
  postCreateClient: PostCreateClientStoreState;
}

export const ClientsForAgent = combineReducers({
  getClientsForAgent,
  postCreateClient
});
