import { PATCH_COOPERATION_ACTIONS } from '../reducers/patchCooperation';

export const req = (id: string, data: any) => ({
  type: PATCH_COOPERATION_ACTIONS.REQ,
  id,
  data
});

export const got = () => ({
  type: PATCH_COOPERATION_ACTIONS.GOT
});

export const err = (error: {}) => ({
  type: PATCH_COOPERATION_ACTIONS.ERR,
  error
});
