import { GET_INTERNAL_APPLICATION } from 'entities/Cabinet/InternalAgent/model/reducers/getInternalApplication';
import {
  GUARANTEES_TYPE,
  TAX_TYPE,
  TENDER_TYPE,
  CREDITING_PLEDGE,
  APPLICATION_TYPES,
  APPLICATION_STATUSES,
  CONTRACT_SUBJECT_TYPE,
  CONTRACT_FINANCING_TYPE,
  CONTRACT_PREPAYMENT_TYPE
} from 'src/features/Application/types';

interface BankApplication {
  amount: number;
  externalVisibility: number;
  id: number;
  modifiedAt: string;
  name: string;
  status: string;
  apiId?: number;
  apiName?: string;
}

interface Debtors {
  name: string;
  id: number;
  limit: number;
  postponement: number;
  pledgeType: string;
}

export interface ResponseData {
  apiId: number;
  debtorType: 'FEDERAL' | null;
  debtors: Debtors[];
  tenderComment: string;
  desiredBanks: string;
  typeComment: string;
  guaranteesType: GUARANTEES_TYPE;
  guaranteesSubTypeId: number;
  tax: TAX_TYPE;
  tender: TENDER_TYPE;
  commercial: any;
  purchaseLink: string;
  id: number;
  clientCompanyName: string;
  isAuthor: boolean;
  mainApplicationId: number;
  isRead: boolean;
  termEndDate: string;
  termStartDate: string;
  code: string;
  createdAt: string;
  contactId: any;
  clientId: number;
  client: null;
  initiatorEmail: string;
  source: string;
  internalAgentId: number;
  guid: string;
  internalAgent: any;
  modifiedAt: any;
  pledge: CREDITING_PLEDGE;
  refinancing: boolean;
  revolvingCredit?: boolean;
  overdraft?: boolean;
  credit?: boolean;
  realEstate?: boolean;
  movableEstate?: boolean;
  otherEstate?: boolean;
  guarantee?: boolean;
  beneficiaryGuarante?: boolean;
  companiesGuarantee?: boolean;
  financingType: APPLICATION_TYPES;
  amount: number;
  bankApplications: BankApplication[];
  term: number;
  groupDocuments: Document[];
  borrowerCompanies: any[];
  status: APPLICATION_STATUSES;
  customerInn: string;
  customerName: string;
  contractAmount: number;
  contractEndDate: string;
  contractSubject: CONTRACT_SUBJECT_TYPE;
  contractType: CONTRACT_FINANCING_TYPE;
  prepaymentAmount: number;
  prepaymentType: CONTRACT_PREPAYMENT_TYPE;
}

export const req = (id: string) => ({
  type: GET_INTERNAL_APPLICATION.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_INTERNAL_APPLICATION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INTERNAL_APPLICATION.ERR,
  error
});

export const reset = () => ({
  type: GET_INTERNAL_APPLICATION.RESET
});
