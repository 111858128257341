import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'src/features/Clients/actions/getSubsidaries';

export interface GetSubsidariesClientStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_SUBSIDARIES_CLIENT {
  REQ = 'GET_SUBSIDARIES_CLIENT_REQ',
  GOT = 'GET_SUBSIDARIES_CLIENT_GOT',
  ERR = 'GET_SUBSIDARIES_CLIENT_ERR'
}

export const initialState: GetSubsidariesClientStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getSubsidariesClient = (
  state: GetSubsidariesClientStoreState = initialState,
  action: {
    type: GET_SUBSIDARIES_CLIENT;
    data: ResponseData[];
    error: {};
  }
): GetSubsidariesClientStoreState => {
  switch (action.type) {
    case GET_SUBSIDARIES_CLIENT.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_SUBSIDARIES_CLIENT.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_SUBSIDARIES_CLIENT.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getSubsidariesClient;
