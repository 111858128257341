import { SUPPLIER_NOTIFICATION_SETTINGS } from 'entities/SCF/Supplier/model/reducers/getSupplierNotificationSettings';

export interface ResponseDataType {
  guid: string;
  newBuyerToSupplierLinkEvent: boolean;
  newSupplyContractLinkEvent: boolean;
  newMonetaryClaimsEvent: boolean;
  earlyPaymentApplicationStatusEvent: boolean;
}

export const req = () => ({ type: SUPPLIER_NOTIFICATION_SETTINGS.REQ });
export const got = (data: ResponseDataType) => ({ type: SUPPLIER_NOTIFICATION_SETTINGS.GOT, data });
export const err = (error: {}) => ({
  type: SUPPLIER_NOTIFICATION_SETTINGS.ERR,
  error
});
