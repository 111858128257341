import styled from 'styled-components';

import { BoxStyled } from 'shared/styled';
import { gray, rb, rr } from 'shared/styled';

export const InfoBlock = styled.div`
  padding: 24px 20px;
  height: 75%;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background: none;
  transition: background 1s;
`;

export const StatusBlock = styled.div`
  padding: 0 24px;
  height: 25%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-top: 1px solid #ebebeb;

  & button {
    width: auto;
    height: auto;
  }
`;

export const ApplicationTileStyled = styled(BoxStyled)`
  width: 333px;
  height: 260px;
  padding: 0;

  font-family: ${rr};
  margin: 0 17px 17px 0;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);

    & > ${InfoBlock} {
      background-color: rgba(241, 248, 255, 0.7);
    }

    & > ${StatusBlock} {
      background: repeating-linear-gradient(
        -45deg,
        #f1f8ff -5%,
        #fff 25%,
        #f1f8fe 0%
      );

      @supports not (background: repeating-linear-gradient) {
        background-color: #fff;
      }
    }
  }
`;

export const TileHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: ${rb};
  }
`;

export const TileSection = styled.div`
  p {
    margin: 0;
    font-size: 14px;
  }

  span {
    margin-bottom: 4px;
    display: block;
    font-size: 12px;
    color: ${gray};
  }
`;
