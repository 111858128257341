import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { req as getPersonsOfCompanies } from 'Business/actions/getPersonsOfCompanies';
import { req as getBeneficiariesOfCompanies } from 'Business/actions/getBeneficiariesOfCompanies';
import { req as getSubsidiariesOfCompanies } from 'Business/actions/getSubsidiariesOfCompanies';
import { ScrollTopComponent } from 'src/features/Common';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import {
  BeneficiariesOfCompanies,
  PersonsOfCompanies,
  SubsidiariesType
} from 'src/features/Business/types';
import { BlockSpace, NetIncome, Revenue } from './styled';

interface StateToProps {
  persons: PersonsOfCompanies[];
  statusPersons: REQUEST_STATUSES;
  beneficiaries: BeneficiariesOfCompanies[];
  statusBeneficiaries: REQUEST_STATUSES;
  subsidiaries: SubsidiariesType[];
  statusSubsidiaries: REQUEST_STATUSES;
}

interface DispatchToProps {
  getPersonsOfCompanies: (inn: string) => void;
  getBeneficiariesOfCompanies: (inn: string) => void;
  getSubsidiariesOfCompanies: (inn: string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const Beneficiaries: React.FC<Props> = ({
  getPersonsOfCompanies,
  persons,
  statusPersons,
  getBeneficiariesOfCompanies,
  beneficiaries,
  statusBeneficiaries,
  getSubsidiariesOfCompanies,
  subsidiaries,
  statusSubsidiaries,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.inn) {
        getPersonsOfCompanies(match.params.inn);
        getBeneficiariesOfCompanies(match.params.inn);
        getSubsidiariesOfCompanies(match.params.inn);
      }
    },
    [match.params.inn]
  );

  const sortedBeneficiaries = beneficiaries.sort(
    (a, b) => a.beneficiaryType - b.beneficiaryType
  );

  return (
    <ScrollTopComponent>
      <BlockSpace>
        <CompanySectionTitle>Руководители и подписанты:</CompanySectionTitle>

        {statusPersons === REQUEST_STATUSES.REQUEST && <Loader />}
        {statusPersons === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="15%">Инн</TableThStyled>
                  <TableThStyled width="15%">Имя</TableThStyled>
                  <TableThStyled width="20%">Должность</TableThStyled>
                  <TableThStyled width="20%">Тип позиции ЕГРЮЛ</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {persons.map((person, index) => (
                  <TableRowStyled key={index}>
                    <td>{person.personInn}</td>
                    <td>{`${person.lastName} ${person.firstName} ${
                      person.middleName
                    }`}</td>
                    <td>{`${person.position}, с ${new Date(
                      person.positionStartDate
                    ).toLocaleDateString()}`}</td>
                    <td>{person.positionType}</td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={persons} />
          </>
        )}
      </BlockSpace>

      <BlockSpace>
        <CompanySectionTitle>Бенефициары:</CompanySectionTitle>

        {statusBeneficiaries === REQUEST_STATUSES.REQUEST && <Loader />}
        {statusBeneficiaries === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">Тип</TableThStyled>
                  <TableThStyled width="10%">Инн</TableThStyled>
                  <TableThStyled width="15%">Имя</TableThStyled>
                  <TableThStyled width="15%">
                    Доля владения, ЕГРЮЛ
                  </TableThStyled>
                  <TableThStyled width="10%">Капитал</TableThStyled>
                  <TableThStyled width="15%">
                    Доля юридическая полная
                  </TableThStyled>
                  <TableThStyled width="15%">Доля фактическая</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {sortedBeneficiaries.map((beneficiar, index) => (
                  <TableRowStyled key={index}>
                    <td>
                      {beneficiar.beneficiaryType === 1
                        ? 'ФЛ'
                        : beneficiar.beneficiaryType === 2 && 'ЮЛ'}
                    </td>
                    <td>
                      {beneficiar.beneficiaryType === 1
                        ? beneficiar.personInn
                        : beneficiar.beneficiaryType === 2 &&
                          beneficiar.headCompanyInn}
                    </td>
                    <td>
                      {beneficiar.beneficiaryType === 1
                        ? `${beneficiar.lastName} ${beneficiar.firstName} ${
                            beneficiar.middleName
                          }`
                        : beneficiar.beneficiaryType === 2 &&
                          beneficiar.companyShortName}
                    </td>
                    <td>{`${beneficiar.ownershipShareDeJure} %`}</td>
                    <td>{formSumStringThousands(beneficiar.capitalShare)}</td>
                    <td>{}</td>
                    <td>{}</td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={beneficiaries} />
          </>
        )}
      </BlockSpace>

      <BlockSpace>
        <CompanySectionTitle>Дочерние компании:</CompanySectionTitle>

        {statusSubsidiaries === REQUEST_STATUSES.REQUEST && <Loader />}
        {statusSubsidiaries === REQUEST_STATUSES.GOT && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="15%">Инн</TableThStyled>
                  <TableThStyled width="15%">Наименование</TableThStyled>
                  <TableThStyled width="15%">
                    Доля владения, ЕГРЮЛ
                  </TableThStyled>
                  <TableThStyled width="10%">Капитал</TableThStyled>
                  <TableThStyled width="15%">
                    Доля юридическая полная
                  </TableThStyled>
                  <TableThStyled width="10%">Год отчетности</TableThStyled>
                  <TableThStyled width="15%">Выручка, тыс.</TableThStyled>
                  <TableThStyled width="15%">Прибыль, тыс.</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {subsidiaries.map((subsidiary, index) => (
                  <TableRowStyled key={index}>
                    <td>{subsidiary.companyInn}</td>
                    <td>{subsidiary.companyShortName}</td>
                    <td>{`${subsidiary.ownershipShareDeJure} %`}</td>
                    <td>{formSumStringThousands(subsidiary.capitalShare)}</td>
                    <td>
                      {subsidiary.ownershipShareDeJureFull !== null
                        ? `${subsidiary.ownershipShareDeJureFull} %`
                        : 'неизв.'}
                    </td>
                    <td>
                      {subsidiary.financialsYear
                        ? subsidiary.financialsYear
                        : 'неизв.'}
                    </td>
                    <td>
                      <Revenue number={subsidiary.revenue}>
                        {subsidiary.revenue
                          ? formSumStringThousands(subsidiary.revenue)
                          : 'неизв.'}
                      </Revenue>
                    </td>
                    <td>
                      <NetIncome number={subsidiary.netIncome}>
                        {subsidiary.netIncome
                          ? formSumStringThousands(subsidiary.netIncome)
                          : 'неизв.'}
                      </NetIncome>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={subsidiaries} />
          </>
        )}
      </BlockSpace>
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Business }: CRM & STORE) => ({
  persons: Business.getPersonsOfCompanies.data,
  statusPersons: Business.getPersonsOfCompanies.status,
  beneficiaries: Business.getBenificiariesOfCompanies.data,
  statusBeneficiaries: Business.getBenificiariesOfCompanies.status,
  subsidiaries: Business.getSubsidiariesOfCompanies.data,
  statusSubsidiaries: Business.getSubsidiariesOfCompanies.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPersonsOfCompanies,
      getBeneficiariesOfCompanies,
      getSubsidiariesOfCompanies
    },
    dispatch
  );

const BeneficiariesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(Beneficiaries)
);

export { BeneficiariesConnect as Beneficiaries };
