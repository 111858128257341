import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostFactoringFeesOfLimitsStoreState = Reducer;

export enum POST_FACTORING_FEES_OF_LIMITS {
  REQ = 'POST_FACTORING_FEES_OF_LIMITS_REQ',
  GOT = 'POST_FACTORING_FEES_OF_LIMITS_GOT',
  ERR = 'POST_FACTORING_FEES_OF_LIMITS_ERR',
  RESET = 'POST_FACTORING_FEES_OF_LIMITS_RESET'
}

export interface RequestDataType {
  name: string;
  momentOfPayment: number;
  feeBase: number;
  feeBaseSumm: number;
  vatPercentage: number;
}

export const initialState: PostFactoringFeesOfLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postFactoringFeesOfLimitsStoreState = (
  state: PostFactoringFeesOfLimitsStoreState = initialState,
  action: {
    type: POST_FACTORING_FEES_OF_LIMITS;
    error: {};
  }
): PostFactoringFeesOfLimitsStoreState => {
  switch (action.type) {
    case POST_FACTORING_FEES_OF_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_FACTORING_FEES_OF_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_FACTORING_FEES_OF_LIMITS.RESET:
      return {
        ...initialState
      };
    case POST_FACTORING_FEES_OF_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postFactoringFeesOfLimitsStoreState;
