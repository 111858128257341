import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/Cabinet/InternalAgent/model/actions/getFactoringLimits';
import { GET_FACTORING_LIMITS } from 'entities/Cabinet/InternalAgent/model/reducers/getFactoringLimits';

import ApplicationApi from 'entities/Cabinet/InternalAgent/api';

function* getFactoringLimits(action: {
  type: GET_FACTORING_LIMITS;
  inn: string;
}) {
  try {
    const res = yield call(ApplicationApi.getFactoringLimits, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringLimitsSaga() {
  yield takeLatest(GET_FACTORING_LIMITS.REQ, getFactoringLimits);
}
