import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetApiKeyPublicStoreState extends Reducer {
  key: string;
}

export enum GET_API_KEY_PUBLIC {
  REQ = 'GET_API_KEY_PUBLIC_REQ',
  GOT = 'GET_API_KEY_PUBLIC_GOT',
  ERR = 'GET_API_KEY_PUBLIC_ERR'
}

export const initialState: GetApiKeyPublicStoreState = {
  status: REQUEST_STATUSES.NONE,
  key: '',
  error: {}
};

const getApiKeyPublic = (
  state: GetApiKeyPublicStoreState = initialState,
  action: {
    type: string;
    key: string;
    error: {};
  }
): GetApiKeyPublicStoreState => {
  switch (action.type) {
    case GET_API_KEY_PUBLIC.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_API_KEY_PUBLIC.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        key: action.key
      };
    case GET_API_KEY_PUBLIC.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getApiKeyPublic;
