import { call, put, select, takeLatest } from 'redux-saga/effects';
import { STORE } from 'globaltypes';

import {
  got,
  err,
  RequestData
} from 'InternalAgentsManager/actions/delegateToInternalAgent';
import InternalAgentsManagerApi from 'InternalAgentsManager/api';
import { DELEGATE_TO_INTERNAL_AGENT } from '../reducers/delegateToInternalAgent';
import { req as getApplicationsQuantity } from 'Application/actions/getApplicationsQuantity';
import { req as getApplication } from 'Application/actions/getApplication';
import { req as getInBankApplication } from 'Application/actions/getInBankApplication';

function* delegateToInternalAgent(action: {
  type: DELEGATE_TO_INTERNAL_AGENT;
  data: RequestData;
}) {
  try {
    yield call(InternalAgentsManagerApi.delegateToInternalAgent, action.data);
    yield put(got());

    const [id, inBankId] = yield select(({ Application }: STORE) => [
      Application.getApplication.data.id,
      Application.getInBankApplication.data.id
    ]);

    yield put(getApplicationsQuantity());

    if (!!id && id === inBankId) {
      yield put(getApplication({ id }));
    } else if (!!inBankId) {
      yield put(getApplication({ id }));
      yield put(getInBankApplication({ id: inBankId }));
    } else {
      yield put(getApplication({ id: action.data.applicationId }));
    }
  } catch (e) {
    yield put(err(e));
  }
}

export default function* delegateToInternalAgentSaga() {
  yield takeLatest(DELEGATE_TO_INTERNAL_AGENT.REQ, delegateToInternalAgent);
}
