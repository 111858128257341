import * as React from 'react';
import * as d3 from 'd3';
import { REQUEST_STATUSES, USER_PERMISSIONS } from 'src/globaltypes';

import { APPLICATION_STATUSES } from 'Application/types';
import { Info } from 'Application/components';

import {
  ApplicationSource,
  ApplicationInitiatorEmail,
  ApplicationTitle,
  ApplicationStatusStyled,
  MainInfoBlock,
  MainInfoItem,
  SubInfoBlock,
  ApplicationId,
  CabinetCard,
  Hint,
  HintsContainer,
  ButtonWrapper,
  FinancialsSummaryContainer,
  FinancialsSummaryTitle,
  FinancialsSummaryDays,
  FinancialsSummaryProgressDIO,
  FinancialsSummaryProgressDSO,
  FinancialsSummaryProgressDPO,
  FinancialsSummaryProgressCCC,
  FinancialsSummaryFooter,
  FinancialsSummarySum,
  FinancialsSummaryProgressContainer,
  ContainerSubInfo,
  FinancialsSummaryProgressMinusCCC,
  InfoBlockWrapper,
  FinancialsSummary
} from './styles';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { ResponseData as ResponseProductOfApplication } from 'src/features/Application/actions/getProductApplication';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import {
  ButtonExternalApiGIR_BO,
  ButtonPickUpProducts,
  DataContainer
} from 'src/features/Leads/components/LeadView/styled';
import { Spinner } from '../styles';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BarChart } from 'src/features/BarChart/components/BarChart';
import { Button } from 'shared/ui/Button';

interface Props {
  head: {
    title: string;
    initiatorEmail: string;
    applicationSource: string;
    status: APPLICATION_STATUSES;
    id: number;
    inn: string;
  };
  data: {
    main: Info[];
    sub: Info[];
  };
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  financailsSummaryData: FinancailsSummaryData[];
  statusFinancailsSummaryData: REQUEST_STATUSES;
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  postSelectApplication: (data: any) => void;
  scrollToProduct: () => void;
  sendRequestsGIR_BO: (year: number) => void;
}

export const DataBlock: React.FC<Props> = ({
  head,
  data,
  permissions,
  roles,
  companyHints,
  productOfApplication,
  postSelectApplication,
  scrollToProduct,
  sendRequestsGIR_BO,
  financailsSummaryData,
  statusExternalApiGIR_BO,
  statusFinancailsSummaryData
}) => {
  const [showTooltips, setShowTooltips] = React.useState(false);
  const [year] = React.useState(new Date().getFullYear() - 1);
  const [selectYear, setSelectYear] = React.useState(null);
  const [sumDIO_DSO, setSumDIO_DSO] = React.useState(0);
  const [DIO, setDIO] = React.useState(0);
  const [DSO, setDSO] = React.useState(0);
  const [DPO, setDPO] = React.useState(0);
  const [CCC, setCCC] = React.useState(0);

  const svgRefRevenueSale = React.useRef(null);
  const svgRefCapital = React.useRef(null);
  const svgRefNetProfit = React.useRef(null);
  const svgRefProfitSale = React.useRef(null);
  const svgRefEBITDA = React.useRef(null);
  const svgRefDEBTEBITDA = React.useRef(null);
  const svgRefNetDebt = React.useRef(null);
  const svgRefLongTermBorrowedFunds = React.useRef(null);
  const svgRefShortTermBorrowedFunds = React.useRef(null);
  const svgRefTotalBorrowedFunds = React.useRef(null);
  const svgRefTotalAssets = React.useRef(null);
  const svgRefSOK = React.useRef(null);

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        const lastElem =
          financailsSummaryData[financailsSummaryData.length - 1];

        const sumDIO_DSO = lastElem.dio + lastElem.dso;
        setSumDIO_DSO(sumDIO_DSO);
        setDIO(lastElem.dio);
        setDSO(lastElem.dso);
        setDPO(lastElem.dpo);
        setCCC(lastElem.ccc);
        setSelectYear(financailsSummaryData.length - 1);
      }
    },
    [financailsSummaryData]
  );

  React.useEffect(
    () => {
      if (!DEBTEBITDAData) return;

      const svg = d3.select(svgRefDEBTEBITDA.current);

      svg.selectAll('*').remove();

      const height = 40;

      const yScale = d3
        .scaleLinear()
        .domain([
          Math.min(d3.min(DEBTEBITDAData), 0),
          Math.max(d3.max(DEBTEBITDAData), 0)
        ])
        .range([height, 0]);

      const bars = svg.append('g').attr('transform', `translate(10, 0)`);

      const invisibleBars = svg
        .append('g')
        .attr('transform', `translate(10, 0)`);

      invisibleBars
        .selectAll('rect')
        .data(DEBTEBITDAData)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10)
        .attr('y', 0)
        .attr('width', 8)
        .attr('height', height)
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === selectYear ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });

      bars
        .selectAll('rect')
        .data(DEBTEBITDAData)
        .enter()
        .append('rect')
        .attr('x', (d, i) => i * 10) // Равномерное распределение столбцов по горизонтали
        .attr('y', d => (d >= 0 ? yScale(d) : yScale(0)))
        .attr('width', 8) // Ширина столбцов
        .attr('height', d => Math.abs(yScale(0) - yScale(d)))
        .attr('fill', (d, i) => {
          if (i === selectYear) {
            return '#0f285a';
          }
          if (d <= 2.5) {
            return '#6CDCD0';
          } else if (d <= 4) {
            return '#F7CC64';
          } else {
            return '#F53737';
          }
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          const indexBar = d3.select(this).node().x.baseVal.value;
          setSelectYear(parseInt(indexBar.toString()[0], 10));
          d3.select(this).attr('fill', '#0f285a');
        })
        .on('mouseout', function(d, i) {
          const color = i === selectYear ? '#0f285a' : '#87aad2';
          d3.select(this).attr('fill', color);
        });
    },
    [financailsSummaryData, setSelectYear, selectYear]
  );

  const revenueSaleData = [];
  const netProfitData = [];
  const capitalData = [];
  const profitSaleData = [];
  const EBITDAData = [];
  const DEBTEBITDAData = [];
  const netDebtData = [];
  const longTermBorrowedFundsData = [];
  const shortTermBorrowedFundsData = [];
  const totalBorrowedFundsData = [];
  const totalAssetsData = [];
  const SOKData = [];

  for (const obj of financailsSummaryData) {
    revenueSaleData.push(obj.row2110);
    netProfitData.push(obj.row2400);
    capitalData.push(obj.row1300);
    profitSaleData.push(obj.row2200);
    EBITDAData.push(obj.ebitda);
    DEBTEBITDAData.push(obj.debt_to_ebitda);
    netDebtData.push(obj.net_debt);
    longTermBorrowedFundsData.push(obj.row1410);
    shortTermBorrowedFundsData.push(obj.row1510);
    totalBorrowedFundsData.push(obj.debt);
    totalAssetsData.push(obj.row1600);
    SOKData.push(obj.sok);
  }

  const navigateToCompanies = () => {
    window.open(`/crm/companies/${head.inn}`, '_blank');
  };

  const handleToggleTooltips = () => {
    setShowTooltips(prevState => !prevState);
  };

  const selectProductApplication = () => {
    postSelectApplication({ ApplicationID: head.id });
  };

  function formatValue(type: keyof FinancailsSummaryData): number {
    const selectedYear =
      selectYear !== null ? selectYear : financailsSummaryData.length - 1;

    const rowValue =
      financailsSummaryData[selectedYear] &&
      financailsSummaryData[selectedYear][type] != null
        ? financailsSummaryData[selectedYear][type]
        : null;

    return rowValue !== null ? rowValue : null;
  }

  function activeYear(): number {
    return financailsSummaryData[
      selectYear !== null ? selectYear : financailsSummaryData.length - 1
    ].year;
  }

  return (
    <React.Fragment>
      <DataContainer isData={true}>
        <div>
          <div>
            <div>
              <ApplicationId>Заявка №{head.id}</ApplicationId>
              <ApplicationTitle>{head.title}</ApplicationTitle>

              <ApplicationInitiatorEmail>
                {head.initiatorEmail}
              </ApplicationInitiatorEmail>
              <ApplicationSource>{head.applicationSource}</ApplicationSource>
              <ApplicationStatusStyled status={head.status} onlyStatus={true} />
            </div>
            {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
              <CabinetCard onClick={navigateToCompanies}>
                Перейти в CRM компании
              </CabinetCard>
            )}
          </div>

          <InfoBlockWrapper>
            <MainInfoBlock>
              {data.main.map((item, key) => (
                <MainInfoItem key={key}>
                  <span>{item.name}</span>
                  {item.value === 'не указано' ? (
                    <span style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
                      {item.value}
                    </span>
                  ) : (
                    <span style={{ whiteSpace: 'nowrap' }}>{item.value}</span>
                  )}
                </MainInfoItem>
              ))}
            </MainInfoBlock>

            <SubInfoBlock>
              {data.sub.map(
                (item, key) =>
                  (!roles.includes('Bank') ||
                    item.name !== 'Пожелания по банкам') &&
                  (item.value === 'не заполнен' ? (
                    <p>
                      {item.name}:{' '}
                      <span style={{ fontStyle: 'italic' }}>{item.value}</span>
                    </p>
                  ) : (
                    <p key={key}>
                      {item.name}: <span>{item.value}</span>
                    </p>
                  ))
              )}
            </SubInfoBlock>
          </InfoBlockWrapper>
        </div>

        <ContainerSubInfo>
          <ButtonWrapper>
            {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
              (productOfApplication.length ? (
                <Button
                  label={'Перейти к продуктам'}
                  onClick={scrollToProduct}
                />
              ) : (
                <ButtonPickUpProducts
                  label={'Подобрать продукты'}
                  onClick={selectProductApplication}
                />
              ))}
            {!!companyHints.length && (
              <Button
                onClick={handleToggleTooltips}
                label={showTooltips ? 'Скрыть подсказки' : 'Показать подсказки'}
              />
            )}
            {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
              statusFinancailsSummaryData === REQUEST_STATUSES.ERROR && (
                <>
                  <ButtonExternalApiGIR_BO
                    label="Загрузить отчетность из налоговой"
                    disabled={
                      statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST
                    }
                    onClick={() => sendRequestsGIR_BO(year)}
                  />
                  {statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST && (
                    <Spinner icon={faSpinner} />
                  )}
                </>
              )}
            {showTooltips && (
              <HintsContainer>
                {companyHints.map((hint, index) => (
                  <Hint code={hint.code} key={index}>
                    <pre>{hint.text}</pre>
                  </Hint>
                ))}
              </HintsContainer>
            )}
          </ButtonWrapper>

          {!!financailsSummaryData.length && (
            <FinancialsSummaryContainer>
              <FinancialsSummary>
                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row2110') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Выручка от продажи
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row2110').toLocaleString() + ' 000 ₽'}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={revenueSaleData}
                    svgRef={svgRefRevenueSale}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row2200') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Прибыль от продаж
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row2200')
                          ? formatValue('row2200').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={profitSaleData}
                    svgRef={svgRefProfitSale}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row2400') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Чистая прибыль
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row2400')
                          ? formatValue('row2400').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={netProfitData}
                    svgRef={svgRefNetProfit}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('ebitda') < 0}
                  >
                    <FinancialsSummaryTitle>EBITDA</FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('ebitda')
                          ? formatValue('ebitda').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={EBITDAData}
                    svgRef={svgRefEBITDA}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={
                      financailsSummaryData[
                        selectYear !== null
                          ? selectYear
                          : financailsSummaryData.length - 1
                      ].debt_to_ebitda < 0
                    }
                  >
                    <FinancialsSummaryTitle>
                      Debt / EBITDA
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {financailsSummaryData[
                          selectYear !== null
                            ? selectYear
                            : financailsSummaryData.length - 1
                        ].debt_to_ebitda.toFixed(2)}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <svg ref={svgRefDEBTEBITDA} width="50" height="40" />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('net_debt') < 0}
                  >
                    <FinancialsSummaryTitle>Net Debt</FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('net_debt')
                          ? formatValue('net_debt').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={netDebtData}
                    svgRef={svgRefNetDebt}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row1300') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Капитал и резервы
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row1300')
                          ? formatValue('row1300').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={capitalData}
                    svgRef={svgRefCapital}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row1410') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Долгосрочные заемные средства
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row1410')
                          ? formatValue('row1410').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={longTermBorrowedFundsData}
                    svgRef={svgRefLongTermBorrowedFunds}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row1510') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Краткосрочные заемные средства
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row1510')
                          ? formatValue('row1510').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={shortTermBorrowedFundsData}
                    svgRef={svgRefShortTermBorrowedFunds}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum isNegativeNum={formatValue('debt') < 0}>
                    <FinancialsSummaryTitle>
                      Итого заемные средства
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('debt')
                          ? formatValue('debt').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={totalBorrowedFundsData}
                    svgRef={svgRefTotalBorrowedFunds}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum
                    isNegativeNum={formatValue('row1600') < 0}
                  >
                    <FinancialsSummaryTitle>
                      Итого активы
                    </FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('row1600')
                          ? formatValue('row1600').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={totalAssetsData}
                    svgRef={svgRefTotalAssets}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>

                <div>
                  <FinancialsSummarySum isNegativeNum={formatValue('sok') < 0}>
                    <FinancialsSummaryTitle>СОК</FinancialsSummaryTitle>
                    <div>
                      <p>{activeYear()}</p>
                      <p>
                        {formatValue('sok')
                          ? formatValue('sok').toLocaleString() + ' 000 ₽'
                          : null}
                      </p>
                    </div>
                  </FinancialsSummarySum>
                  <BarChart
                    data={SOKData}
                    svgRef={svgRefSOK}
                    setSelectYear={setSelectYear}
                    selectedBar={selectYear}
                  />
                </div>
              </FinancialsSummary>

              <FinancialsSummaryFooter>
                <div>
                  <FinancialsSummaryProgressContainer>
                    <FinancialsSummaryProgressDIO
                      widthDIO={Math.round((DIO / sumDIO_DSO) * 100)}
                    >
                      {DIO}
                    </FinancialsSummaryProgressDIO>
                    <FinancialsSummaryProgressDSO
                      widthDSO={Math.round((DSO / sumDIO_DSO) * 100)}
                    >
                      {DSO}
                    </FinancialsSummaryProgressDSO>
                  </FinancialsSummaryProgressContainer>
                  <FinancialsSummaryProgressContainer>
                    <FinancialsSummaryProgressDPO
                      widthDPO={Math.round((DPO / sumDIO_DSO) * 100)}
                    >
                      {DPO}
                    </FinancialsSummaryProgressDPO>
                    {CCC > 0 && (
                      <FinancialsSummaryProgressCCC
                        widthCCC={Math.round((CCC / sumDIO_DSO) * 100)}
                      >
                        {CCC}
                      </FinancialsSummaryProgressCCC>
                    )}
                  </FinancialsSummaryProgressContainer>
                  {CCC < 0 && (
                    <FinancialsSummaryProgressMinusCCC>
                      {CCC}
                    </FinancialsSummaryProgressMinusCCC>
                  )}
                </div>

                <div>
                  <FinancialsSummaryDays>
                    <p>Производственный цикл:</p>
                    <p>{DIO} дней</p>
                  </FinancialsSummaryDays>
                  <FinancialsSummaryDays>
                    <p>Отсрочка платежа:</p>
                    <p>{DSO} дней</p>
                  </FinancialsSummaryDays>
                  <FinancialsSummaryDays>
                    <p>Отсрочка поставщикам:</p>
                    <p>{DPO} дней</p>
                  </FinancialsSummaryDays>
                  <FinancialsSummaryDays>
                    <p>Финансовый цикл:</p>
                    <p>{CCC} дней</p>
                  </FinancialsSummaryDays>
                </div>
              </FinancialsSummaryFooter>
            </FinancialsSummaryContainer>
          )}
        </ContainerSubInfo>
      </DataContainer>
    </React.Fragment>
  );
};
