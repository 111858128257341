import styled, { css } from 'styled-components';

import { BoxStyled } from 'shared/styled';
import { navyBlue, gray, rr, lighterBlue } from 'shared/styled';

const icons = {
  REMOVE:
    'M9.179 8l3.16 3.16a.906.906 0 0 1 .242.379.656.656 0 0 1 0 .386.906.906 0 0 1-.243.38l-.034.033a.906.906 0 0 1-.379.243.656.656 0 0 1-.386 0 .906.906 0 0 1-.38-.243L8 9.178l-3.16 3.16a.906.906 0 0 1-.379.243.656.656 0 0 1-.386 0 .906.906 0 0 1-.38-.243l-.033-.034a.906.906 0 0 1-.243-.379.656.656 0 0 1 0-.386.906.906 0 0 1 .243-.38L6.822 8l-3.16-3.16a.906.906 0 0 1-.243-.379.656.656 0 0 1 0-.386.906.906 0 0 1 .243-.38l.034-.033a.906.906 0 0 1 .379-.243.656.656 0 0 1 .386 0c.128.039.25.114.38.243L8 6.822l3.16-3.16a.906.906 0 0 1 .379-.243.656.656 0 0 1 .386 0c.128.039.25.114.38.243l.033.034a.906.906 0 0 1 .243.379.656.656 0 0 1 0 .386.906.906 0 0 1-.243.38L9.178 8z',
  REJECT:
    'M8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zM2.667 8A5.332 5.332 0 0 1 8 2.667c1.233 0 2.367.42 3.267 1.126l-7.474 7.474A5.268 5.268 0 0 1 2.667 8zM8 13.333a5.268 5.268 0 0 1-3.267-1.126l7.474-7.474A5.268 5.268 0 0 1 13.333 8 5.332 5.332 0 0 1 8 13.333z',
  EDIT:
    'M9.46 4.003l2.441 2.453-6.178 6.209-2.439-2.453 6.176-6.21zm4.293-.592l-1.088-1.094a1.076 1.076 0 0 0-1.526 0l-1.043 1.048 2.44 2.453 1.217-1.222a.84.84 0 0 0 0-1.185zM2.007 13.66c-.045.2.136.38.336.332l2.72-.663-2.44-2.453-.616 2.784z'
};

export const SubmenuContainer = styled.div`
  display: flex;
  position: relative;
`;

export const Submenu = styled(BoxStyled)`
  width: auto;
  height: auto;
  padding: 12px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  top: 100%;
  right: 0;
  z-index: 4;
  position: absolute;
`;

export const SubmenuBtn = styled.button.attrs({
  type: 'button'
})`
  width: 198px;
  height: 38px;

  padding: 0;
  padding-left: 36px;

  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 6px;

  font-family: ${rr};
  text-align: left;
  color: ${gray};

  background-position: 12px center;
  background-size: 16px;
  background-repeat: no-repeat;
  background-color: transparent;

  &:hover {
    background-color: ${lighterBlue};
    color: ${navyBlue};
  }

  ${({ name }) =>
    css`
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path fill="${gray}" d="${icons[name]}"/>
      </svg>
    ');

    &:hover {
      background-image: url('data:image/svg+xml,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path fill="${navyBlue}" d="${icons[name]}"/>
        </svg>
      ');
    }
  `};
`;

export const OpenSubmenuBtn = styled.button.attrs({
  type: 'button',
  ['aria-label']: 'open-submenu-button'
})`
  width: 30px;
  height: 20px;

  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;

  background-size: 16px 4px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 4">
      <g fill="${({ menuOpened }) =>
        menuOpened ? navyBlue : 'rgb(195,201,212)'}" cy="2" r="2">
        <circle cx="2" cy="2" r="2"/>
        <circle cx="8" cy="2" r="2"/>
        <circle cx="14" cy="2" r="2"/>
      </g>
    </svg>
  ');

  &:disabled {
    cursor: not-allowed;
    background-image: url('data:image/svg+xml,
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 4">
        <g fill="rgb(195,201,212)" fill-opacity=".2" cy="2" r="2">
          <circle cx="2" cy="2" r="2"/>
          <circle cx="8" cy="2" r="2"/>
          <circle cx="14" cy="2" r="2"/>
        </g>
      </svg>
    ');
  }
`;
