import styled from 'styled-components';
import { Cell as CellStyled } from 'src/features/FinancialForCompany/components/styled';

export const Cell = styled(CellStyled)`
  position: relative;
  width: 60px;

  svg {
    margin-left: 5px;
  }

  outline: ${({ outline }) => `2px solid ${outline}`};
`;

export const AcсessTypeHint = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 55px;
  box-shadow: -1px 3px 11px 2px rgba(34, 60, 80, 0.2);
  background-color: white;
  min-width: 180px;
  text-align: center;
  padding: 5px;
`;
