import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { Input } from 'shared/ui/Input';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { HeadContainer, InputContainer, MainConainer } from './styled';

interface StateToProps {
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  isAuthorized: boolean;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const BusinessSearch: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  statusInn,
  isAuthorized,
  permissions,
  history
}) => {
  const [searchValue, setSearchValue] = React.useState<string>('');

  React.useEffect(
    () => {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain.toLowerCase() === 'my') {
        return;
      } else if (
        !isAuthorized ||
        (permissions.length && !permissions.includes(USER_PERMISSIONS.ADMIN))
      ) {
        window.location.href = 'https://my.finfactory.one/business';
      }
    },
    [permissions, window.location.hostname]
  );

  React.useEffect(
    () => {
      if (searchValue.length >= 10) {
        getCompanyByInn({ inn: searchValue });
      }
    },
    [searchValue]
  );

  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const navigateToBusiness = () => {
    history.push(`/business/${companyByInn.inn}`);
  };

  const navigateToPersonSearch = () => {
    history.push(`/person`);
  };

  return (
    <ApplicationViewStyled>
      <MainConainer>
        <HeadContainer>
          <ApplicationTitle>Проверь любую компанию по ИНН</ApplicationTitle>
          <p onClick={navigateToPersonSearch}>Искать по персонам</p>
        </HeadContainer>

        <InputContainer>
          <Input
            name="searchInn"
            value={searchValue}
            onChange={e => handleSetSearchValue(e.currentTarget.value)}
            icon={faSearch}
          />

          {searchValue.length >= 10 &&
            statusInn === REQUEST_STATUSES.GOT && (
              <InnPopup
                companyInfo={companyByInn}
                onPopupClick={navigateToBusiness}
              />
            )}
        </InputContainer>
      </MainConainer>
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ User, SCF, Cached }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  isAuthorized: Cached.authorized,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCompanyByInn }, dispatch);

const BusinessSearchConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessSearch)
);

export { BusinessSearchConnect as BusinessSearch };
