import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  APPLICATION_TYPE_ROUTES,
  BorrowerCompany,
  CreditingApplicationRead
} from 'Application/types';

import { State as BorrowerCompanyDocumentsTabState } from 'Application/components';

import { EditApplicationBtn } from 'Application/components/ApplicationView/EachApplicationView/styles';
import {
  CompanyTabHeaderRole,
  CompanyTabsHeader
} from 'BorrowerCompanies/components/BorrowerCompaniesTabs/styles';

import { STORE } from 'src/globaltypes';
import { CompanyName, ViewCompanyTabHeaderItem } from './styles';

interface StateToProps {
  bankApplication: Partial<CreditingApplicationRead>;
}

interface OwnProps extends BorrowerCompanyDocumentsTabState {
  companies: BorrowerCompany[];
  changeTab: (index: number) => void;
}

type Props = RouteComponentProps & OwnProps & StateToProps;

const BorrowerCompanyDocumentHeader: React.FC<Props> = ({
  bankApplication,
  companies,
  changeTab,
  activeTabIndex,
  animationDirection,
  history
}: Props) => (
  <>
    <p>Участники заявки:</p>
    <CompanyTabsHeader>
      <>
        {companies.map((company, key) => (
          <ViewCompanyTabHeaderItem
            key={key}
            selected={key === activeTabIndex}
            animationDirection={animationDirection}
            onClick={() => changeTab(key)}
          >
            <div>
              <CompanyName>
                {!!company.name ? company.name : `Компания №${key + 1}`}
              </CompanyName>
              <p>ИНН {company.individualNumber}</p>
              <CompanyTabHeaderRole selected={key === activeTabIndex}>
                {company.roles.map((role, index) => (
                  <p key={index}>
                    {role === 'PRINCIPAL'
                      ? 'Принципал'
                      : role === 'GUARANTOR'
                        ? 'Поручитель'
                        : role === 'PLEDGER'
                          ? 'Залогодатель'
                          : role === 'BORROWER' && 'Заемщик'}
                    {index !== company.roles.length - 1 && ','}
                  </p>
                ))}
              </CompanyTabHeaderRole>
            </div>
          </ViewCompanyTabHeaderItem>
        ))}
      </>
    </CompanyTabsHeader>
  </>
);

const mapStateToProps = ({ BankRole }: STORE) => ({
  bankApplication: BankRole.getApplicationBank.data
});

const BorrowerCompanyDocumentHeaderConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(BorrowerCompanyDocumentHeader)
);

export {
  BorrowerCompanyDocumentHeaderConnect as BorrowerCompanyDocumentHeader
};
