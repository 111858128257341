import {
  GET_ACTIVITIES_BY_LEAD_INTERNAL,
  ResponseDataInternal
} from '../reducers/getActivitiesByLeadInternal';

export const req = (leadId: string) => ({
  type: GET_ACTIVITIES_BY_LEAD_INTERNAL.REQ,
  leadId
});

export const got = (data: ResponseDataInternal) => ({
  type: GET_ACTIVITIES_BY_LEAD_INTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ACTIVITIES_BY_LEAD_INTERNAL.ERR,
  error
});
