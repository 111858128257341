import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getLeadDocumentList';

import { GET_LEAD_DOCUMENT_LIST } from '../reducers/getLeadDocumentList';
import LeadsApi from '../api';

function* getLeadDocumentList(action: {
  type: GET_LEAD_DOCUMENT_LIST;
  id: string;
}): Generator {
  try {
    const res: any = yield call(LeadsApi.getLeadDocumentList, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadDocumentListSaga(): Generator {
  yield takeLatest(GET_LEAD_DOCUMENT_LIST.REQ, getLeadDocumentList);
}
