import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getDocumentList';

import { GET_DOCUMENT_LIST } from '../reducers/getDocumentList';
import DocumentListApi from '../api';

function* getDocumentList(action: {
  type: GET_DOCUMENT_LIST;
  token: string;
}): Generator {
  try {
    const res: any = yield call(DocumentListApi.getDocumentList, action.token);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentListSaga(): Generator {
  yield takeLatest(GET_DOCUMENT_LIST.REQ, getDocumentList);
}
