import styled from 'styled-components';
import { blue, rr } from 'shared/styled';

const progressBarColor = {
  red: '#ED446C',
  yellow: '#F7CC64',
  green: '#6CDCD0'
};

export const PricingContainer = styled.div`
  margin-bottom: 60px;
`;

export const RateContainer = styled.div`
  margin-left: 40px;
`;

export const Rate = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  & > p {
    min-width: 120px;
  }

  & > span {
    font-size: 18px;
    font-family: 'Roboto-Regular';
    margin: 0 10px;
  }

  & > input {
    width: 60px;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: red;
`;

export const MinimumCost = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;

  & > p {
    margin-right: 20px;
  }

  & > span {
    font-size: 18px;
    margin-right: 10px;
  }

  & > input {
    width: 120px;
  }
`;

export const FormatPrice = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 0 0 20px 20px;

  ${({ active }) => {
    if (active) {
      return `
      & > label {
        cursor: not-allowed !important;
      }
    
      & > label input {
        :hover {
          border-color: rgb(226, 228, 232);
        }
      } 
      `;
    }
  }};
`;

export const Tooltip = styled.div`
  display: none;
  text-align: center;
  font-size: 16px;
  overflow-wrap: break-word;
  font-family: ${rr};
`;

export const Input = styled.input`
  max-width: 150px;
  border: ${({ active }) => (active ? '0' : '1px')} solid #d9d9d9;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 20px;

  :hover + ${Tooltip} {
    display: block;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  padding: 10px 10px;
  background: ${blue};
  border-radius: 5px;

  :disabled {
    cursor: not-allowed;
    background-color: #9fa6b2;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  max-width: 150px;
  row-gap: 10px;

  & > div:nth-child(1) {
    width: 150px;
  }

  input {
    text-align: center;
  }

  p {
    margin: 0;
  }
`;

export const ProductTermContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0;
`;

export const ProductTerm = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  & > input {
    width: 50px;
  }

  & > span {
    font-size: 18px;
    font-family: 'Roboto-Regular';
  }
`;

export const ProductSizeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 70px;

  ${Field} {
    margin-right: 26px;
  }
`;

export const Square = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: ${({ active }) => (active ? 'calc(100% + 15px)' : '20px')};
  height: 40px;
  transition: width 0.5s ease-out;
`;

export const Text = styled.p`
  position: inherit;
  z-index: 2;
  font-size: 16px;
  margin: 0 0 0 30px;
`;

export const ProductItem = styled.div`
  display: inline-flex;
  align-items: center;
  height: 40px;
`;

export const ProductColumnContainer = styled.div`
  & > div {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  & > div > ${ProductItem} {
    width: fit-content;
    position: relative;
    cursor: pointer;
  }

  & > div > ${ProductItem}:hover ${Square} {
    width: calc(100% + 15px);
  }
`;

export const ProductComplexityContainer = styled(ProductColumnContainer)`
  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Проектное финансирование' && product !== 4 && active
          ? 'gray'
          : '#ed446c'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Проектное финансирование' && product !== 4 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Корпоративный' && product !== 3 && active
          ? 'gray'
          : '#f7cc64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Корпоративный' && product !== 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - Простой' && product !== 2 && active
          ? 'gray'
          : '#6cdcd0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - Простой' && product !== 2 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Скоринг' && product !== 1 && active ? 'gray' : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Скоринг' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '4 - Проектное финансирование' || product === 4) && 'white'};
  }

  & > div:nth-of-type(4) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Скоринг' || product === 1) && 'white'};
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(4) > ${ProductItem}:hover ${Text} {
    color: white;
  }
`;

export const ProductRiskAppetiteContainer = styled(ProductColumnContainer)`
  margin-top: 35px;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Высокий' && product !== 3 && active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Высокий' && product !== 3 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '2 - Средний' && product !== 2 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '2 - Средний' && product !== 2 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Низкий' && product !== 1 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Низкий' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(3) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(3) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Низкий' || product === 1) && 'white'};
  }
`;

export const ProgressBar = styled.div`
  display: inline-flex;
  margin-bottom: 5px;
  position: relative;

  ${({
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen
  }) => {
    if (eight) {
      return `
      & > div {
        background-color: ${progressBarColor.green};
      }
      `;
    } else if (two) {
      return `
      & > div:nth-child(3) {
        margin: 0 3px;
      }

      & > div:nth-child(4),
      div:nth-child(4) {
        margin-right: 3px;
      }

      & > div:nth-child(1),
      div:nth-child(2),
      div:nth-child(4) {
        background-color: ${progressBarColor.yellow};
      }
      
      & > div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (three) {
      return `
      & > div:nth-child(3) {
        margin: 0 3px;
      }

      & > div {
        background-color: ${progressBarColor.green};
      }
      
      & > div:not(:nth-child(3)) {
        background-color: ${progressBarColor.yellow};
      }
      `;
    } else if (four) {
      return `
      & > div:nth-child(2) {
        margin-right: 3px;
      }

      & > div:nth-child(1), 
      div:nth-child(2) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(3), 
      div:nth-child(4),
      div:nth-child(5) {
        background-color: ${progressBarColor.green};
      }
      `;
    } else if (five) {
      return `
      & > div:nth-child(4) {
        margin: 0 3px;
      }

      & > div:nth-child(1),
      div:nth-child(2),
      div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(4) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (six) {
      return `
      & > div:nth-child(5) {
        margin-left: 3px;
      }

      & > div:not(:last-child) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (seven) {
      return `
      & > div:nth-child(3) {
        margin-right: 3px;
      }

      & > div:nth-child(1),
      div:nth-child(2),
      div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(4),
      div:nth-child(5) {
        background-color: ${progressBarColor.yellow};
      }
      `;
    } else if (nine) {
      return `
      & > div:nth-child(2) {
        margin: 0 3px;
      }

      & > div:nth-child(3) {
        margin-right: 3px;
      }
      
      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }

      & > div:nth-child(2) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(4),
      div:nth-child(5) {
        background-color: ${progressBarColor.yellow};
      }
      `;
    } else if (ten) {
      return `
      & > div:nth-child(2) {
        margin: 0 3px;
      }

      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }

      & > div:nth-child(2) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(3),
      div:nth-child(4),
      div:nth-child(5) {
        background-color: ${progressBarColor.green};
      }
      `;
    } else if (eleven) {
      return `
      & > div:nth-child(1) {
        margin-right: 3px;
      }

      & > div:nth-child(4) {
        margin: 0 3px;
      }

      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }

      & > div:nth-child(2),
      div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(4) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (twelve) {
      return `
      & > div:nth-child(1) {
        margin-right: 3px;
      }

      & > div:nth-child(3) {
        margin-right: 3px;
      }

      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }

      & > div:nth-child(2),
      div:nth-child(3) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(4),
      div:nth-child(5) {
        background-color: ${progressBarColor.yellow};
      }
      `;
    } else if (thirteen) {
      return `
      & > div:nth-child(1) {
        margin-right: 3px;
      }

      & > div:not(:first-child) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (fourteen) {
      return `
      & > div:nth-child(2) {
        margin: 0 3px;
      }

      & > div:nth-child(5) {
        margin-left: 3px;
      }

      & > div:nth-child(1) {
        background-color: ${progressBarColor.red};
      }

      & > div:nth-child(2) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(3),
      div:nth-child(4) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (fifteen) {
      return `
      & > div:nth-child(3) {
        margin-left: 3px;
      }

      & > div:nth-child(5) {
        margin-left: 3px;
      }
      
      & > div:nth-child(1),
      div:nth-child(2) {
        background-color: ${progressBarColor.yellow};
      }

      & > div:nth-child(3),
      div:nth-child(4) {
        background-color: ${progressBarColor.green};
      }

      & > div:nth-child(5) {
        background-color: ${progressBarColor.red};
      }
      `;
    } else if (one) {
      return `
      & > div:not(:last-child) {
        margin-right: 3px;
      }

      & > div:nth-child(1),
      div:nth-child(5) {
        background-color: #ED446C;
      }

      & > div:nth-child(2),
      div:nth-child(4) {
        background-color: #F7CC64;
      }

      & > div:nth-child(3) {
        background-color: #6CDCD0;
      }
      `;
    } else {
      return `
      & > div:not(:last-child) {
        margin-right: 3px;
      }    
      `;
    }
  }};

  &:before {
    content: '0';
    position: absolute;
    top: 10px;
    left: 0px;
    font-size: 20px;
  }

  &::after {
    content: '∞';
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 20px;
  }
`;

export const ProgressStep = styled.div`
  width: 160px;
  height: 4px;
  background-color: #d9d9d9;
`;
