import { GET_CONTACTS_OF_ACTIVITIES } from 'src/features/Activities/reducers/getContactsOfActivities';
import { ContactsOfActivities } from 'src/features/Activities/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ContactsOfActivities[];
}

export const req = (id: string) => ({
  type: GET_CONTACTS_OF_ACTIVITIES.REQ,
  id
});

export const got = (data: ResponseData) => ({
  type: GET_CONTACTS_OF_ACTIVITIES.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_CONTACTS_OF_ACTIVITIES.ERR,
  error
});
