import * as React from 'react';

import {
  Counter,
} from './styles';

import './styles/style.css';

interface State {
  NewRegistries: number;
}

class NewRegistriesCounter extends React.Component<any, any> {

  constructor(props) {
    super(props)
    {
      this.state = {
        NewRegistries: undefined
      }
      this.handleNewRegistriesChange = this.handleNewRegistriesChange.bind(this);
    }
  }

  handleNewRegistriesChange(valueOfResponse: number) {
    this.setState({ NewRegistries: valueOfResponse })
  }

  componentDidMount() {
    fetch('/api/factor_role/factor_registry/counter/new')
      .then((response) => response.json())
      .then((data) => this.handleNewRegistriesChange(data));
  }

  render() {
    return (
      <Counter className='counter'>{this.state.NewRegistries}</Counter>
    );
  }
}

export default NewRegistriesCounter;
