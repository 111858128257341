import styled from 'styled-components';

import { Label } from 'shared/ui/Label';
import { gray, darkBlue, darkGray, lightGray } from 'shared/styled';

export const RadioStyled = styled.input.attrs({
  type: 'radio'
})`
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: unset;
  display: none;
  margin: 6px 8px 6px 0;

  cursor: pointer;
  outline: none;
  border-radius: 50%;
  border: 1px solid ${gray};
  background-color: #fff;
`;

export const RadioButtonLabel = styled(Label)`
  border: 1px solid ${lightGray};
  padding: 15px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: ${darkGray};
  &.checked {
    border: 1px solid ${gray};
    background: ${darkBlue};
    color: white;
  }
`;

export const RadioChildren = styled.div`
  margin: 5px 0 10px 0;
`;
