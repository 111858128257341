import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from 'src/features/SCF/actions/putAddPoa';

import { PUT_ADD_POA } from 'src/features/SCF/reducers/putAddPoa';

import SCFApi from 'src/features/SCF/api';

function* putAddPoa(action: { type: PUT_ADD_POA; data: RequestData }) {
  try {
    yield call(SCFApi.putAddPoa, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* putAddPoaSaga() {
  yield takeLatest(PUT_ADD_POA.REQ, putAddPoa);
}
