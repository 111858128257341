import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postCreateClient';

import { POST_CREATE_CLIENT } from '../reducers/postCreateClient';
import ClientsApi from '../api';

function* postCreateClient(action: {
  type: POST_CREATE_CLIENT;
  agentinn: number;
  companyinn: string;
  comment?: string;
}): Generator {
  try {
    let res: any;

    res = yield call(ClientsApi.postCreateClient, {
      agentinn: action.agentinn,
      companyinn: action.companyinn,
      comment: action.comment
    });

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateClientSaga(): Generator {
  yield takeLatest(POST_CREATE_CLIENT.REQ, postCreateClient);
}
