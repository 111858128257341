import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { HoldingRead } from '../actions/setHoldingFormData';

export interface GetHoldingStoreState extends Reducer {
  holding: HoldingRead;
}

export enum GET_HOLDING {
  REQ = 'GET_HOLDING_REQ',
  GOT = 'GET_HOLDING_GOT',
  ERR = 'GET_HOLDING_ERR'
}

export const initialState: GetHoldingStoreState = {
  status: REQUEST_STATUSES.NONE,
  holding: {
    id: 0
  },
  error: {}
};

const getHolding = (
  state: GetHoldingStoreState = initialState,
  action: {
    type: string;
    holding: HoldingRead;
    error: {};
  }
): GetHoldingStoreState => {
  switch (action.type) {
    case GET_HOLDING.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_HOLDING.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        holding: action.holding
      };
    case GET_HOLDING.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getHolding;
