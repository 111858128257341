import { combineReducers } from 'redux';
import setFinbankFormData, {
  SetFinbankDataStoreState
} from './setFinbankFormData';
import getFinbank, { GetFinbankStoreState } from './getFinbank';
import getFinbanksList, { GetFinbanksListStoreState } from './getFinbanksList';
import postFinbank, { PostFinbankStoreState } from './postFinbank';

export interface FinbanksReducers {
  setFinbankFormData: SetFinbankDataStoreState;
  getFinbank: GetFinbankStoreState;
  getFinbanksList: GetFinbanksListStoreState;
  postFinbank: PostFinbankStoreState;
}

export const Finbanks = combineReducers({
  setFinbankFormData,
  getFinbank,
  getFinbanksList,
  postFinbank
});
