import * as React from 'react';
import { Radio } from 'shared/ui/RadioGroup';
import { Label } from 'shared/ui/Label';

import { RadioGroupStyled, RadioContainer } from './styles';

interface RadioBtn {
  value: string;
  label: string;
  children?: any;
}

interface Props {
  radioBtns: RadioBtn[];
  name: string;
  label?: string;
  required?: boolean;
  keyValue: string;
  className?: string;
  template?: Template;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}

type Template = 'row' | 'column';

export const RadioGroup: React.FC<Props> = ({
  name,
  label = '',
  required = false,
  keyValue,
  onChange,
  className,
  template = 'column' as Template,
  radioBtns
}: Props) => (
  <RadioGroupStyled legendId={name} className={!!className ? className : ''}>
    <Label name={name} label={label} required={required} />
    <RadioContainer template={template}>
      {radioBtns.map((item, key) => (
        <Radio
          key={key}
          name={name}
          aria-labelledby={name}
          value={item.value}
          label={item.label}
          keyValue={keyValue}
          onChange={onChange}
        >
          {!!item.children && item.children}
        </Radio>
      ))}
    </RadioContainer>
  </RadioGroupStyled>
);
