import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { FinancingCreditType } from 'shared/constants';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ScrollTopComponent } from 'src/features/Common';
import { Status } from 'src/features/ApplicationForCompanies/components/styled';
import { ResponseData as ApplicationsData } from 'src/features/ApplicationForCompanies/actions/getApplications';
import {
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock,
  Loader,
  Pagination
} from 'src/features/Layouts/components';

interface Props {
  applications: ApplicationsData;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

const ApplicationsList: React.FC<Props> = ({ applications, status, error }) => {
  const segments = location.pathname.split('/');
  const roleIndex = segments.indexOf('cabinet') + 1;
  const role = segments[roleIndex];

  const navigateToApplication = (id: number) => {
    window.open(`/cabinet/${role}/application/${id}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <>
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="5%">ID</TableThStyled>
                <TableThStyled width="10%">Клиент</TableThStyled>
                <TableThStyled width="10%">ИНН</TableThStyled>
                <TableThStyled width="10%">Тип финансирования</TableThStyled>
                <TableThStyled width="15%">Сумма</TableThStyled>
                <TableThStyled width="15%">Дата изменения</TableThStyled>
                <TableThStyled width="10%">Статус</TableThStyled>
                <TableThStyled width="15%">Инициатор</TableThStyled>
                <TableThStyled width="15%">Сопроводит заявку</TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {applications.items.map(user => (
                <TableRowStyled
                  key={user.id}
                  onClick={() => navigateToApplication(user.id)}
                >
                  <td>{user.id}</td>
                  <td>{user.client}</td>
                  <td />
                  <td>{FinancingCreditType[user.financingType]}</td>
                  <td>{formSumStringThousands(user.amount)} ₽</td>
                  <td />
                  <td>
                    <Status>{user.status}</Status>
                  </td>
                  <td />
                  <td />
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
          <Pagination list={applications} />
        </>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ApplicationsListConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsList);

export { ApplicationsListConnect as ApplicationsList };
