import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';

import { RequestDataType as PostCommentDataType } from 'Comments/reducers/postComment';
import { RequestDataType as PostReplyDataType } from 'Comments/reducers/postReply';
import { RequestDataType as GetCommentsListDataType } from 'Comments/reducers/getCommentsList';
import { RequestDataType as UploadCommentDocumentsDataType } from 'Comments/sagas/uploadCommentDocuments';
import { RequestDataType as CommentNotification } from 'Comments/sagas/notifyAboutComment';

export default class CommentsApi {
  static postComment(data: PostCommentDataType) {
    return ApiRequest(
      `/api/comment/${data.id}/${data.type}`,
      'post',
      data.body
    );
  }

  static notifyAboutComment(data: CommentNotification){
    return ApiRequest(
      `/api/comment/notify/${data.commentId}`, 'post');
  }

  static postReply(data: PostReplyDataType) {
    return ApiRequest(`/api/comment/reply/${data.id}`, 'post', data.body);
  }

  static getCommentsList(data: GetCommentsListDataType) {
    return ApiRequest(`/api/comment/${data.id}/${data.type}`);
  }

  static uploadCommentDocuments(data: UploadCommentDocumentsDataType) {
    return ApiUploadFiles(`/api/documents/comment/${data.id}`, data.file);
  }
}
