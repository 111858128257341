import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'src/features/SCF/actions/postFactoringSettingsSignature';

import {
  POST_FACTORING_SETTINGS_SIGNATURE,
  RequestDataType
} from 'src/features/SCF/reducers/postFactoringSettingsSignature';

import SCFApi from 'src/features/SCF/api';

function* postFactoringSettingsSignature(action: {
  type: POST_FACTORING_SETTINGS_SIGNATURE;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.postFactoringSettingsSignature, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postFactoringSettingsSignatureSaga() {
  yield takeLatest(
    POST_FACTORING_SETTINGS_SIGNATURE.REQ,
    postFactoringSettingsSignature
  );
}
