import { call, put, select, takeLatest } from 'redux-saga/effects';
import { STORE } from 'src/globaltypes';
import { got, err } from '../actions/addProperty';
import { addLocalProperty } from '../actions/localProperties';
import PropertiesApi from '../api';
import { Document } from 'Application/types';

import {
  ADD_PROPERTY,
} from '../reducers/addProperty';
import { PropertyRead, PropertyWrite, PROPERTY_SUB_TYPES, PROPERTY_TYPES } from '../types/Property';

function* addProperty(action: {
  type: ADD_PROPERTY;
  property: Partial<PropertyRead>;
}): Generator {
  try {

    // TODO remove action.property from action
    const res = yield call(PropertiesApi.addProperty, action.property);
    action.property.id = res as number;

    if (action.property.type)
      yield put(addLocalProperty(action.property));
    else
      yield put(addLocalProperty({
        ...action.property,
        type: PROPERTY_TYPES.NONE,
        subType: PROPERTY_SUB_TYPES.NONE,
        price: 0,
        region: "",
        address: "",
        hasEvaluation: false,
        documents: []
      }));
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addPropertySaga(): Generator {
  yield takeLatest(ADD_PROPERTY.REQ, addProperty);
}
