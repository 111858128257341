import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from '../actions/getProductApplication';

export interface GetProductApplicationStoreState extends Reducer {
  data: ResponseData[];
}

export enum GET_PRODUCT_APPLICATION {
  REQ = 'GET_PRODUCT_APPLICATION_REQ',
  GOT = 'GET_PRODUCT_APPLICATION_GOT',
  ERR = 'GET_PRODUCT_APPLICATION_ERR'
}

export const initialState: GetProductApplicationStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: [],
  error: {}
};

const getProductApplication = (
  state: GetProductApplicationStoreState = initialState,
  action: {
    type: GET_PRODUCT_APPLICATION;
    data: ResponseData[];
    error: {};
  }
): GetProductApplicationStoreState => {
  switch (action.type) {
    case GET_PRODUCT_APPLICATION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PRODUCT_APPLICATION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PRODUCT_APPLICATION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getProductApplication;
