import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PutMonetaryClaimDeleteToAuctionStoreState = Reducer;

export enum PUT_MONETARY_CLAIM_DELETE_TO_AUCTION {
  REQ = 'PUT_MONETARY_CLAIM_DELETE_TO_AUCTION_REQ',
  GOT = 'PUT_MONETARY_CLAIM_DELETE_TO_AUCTION_GOT',
  ERR = 'PUT_MONETARY_CLAIM_DELETE_TO_AUCTION_ERR',
  RESET = 'PUT_MONETARY_CLAIM_DELETE_TO_AUCTION_RESET'
}

export const initialState: PutMonetaryClaimDeleteToAuctionStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const putMonetaryClaimDeleteToAuction = (
  state: PutMonetaryClaimDeleteToAuctionStoreState = initialState,
  action: {
    type: PUT_MONETARY_CLAIM_DELETE_TO_AUCTION;
    error: {};
  }
): PutMonetaryClaimDeleteToAuctionStoreState => {
  switch (action.type) {
    case PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case PUT_MONETARY_CLAIM_DELETE_TO_AUCTION.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default putMonetaryClaimDeleteToAuction;
