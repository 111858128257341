import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PostAddBuyerStoreState extends Reducer {}

export enum POST_ADD_BUYER {
  REQ = 'POST_ADD_BUYER_REQ',
  GOT = 'POST_ADD_BUYER_GOT',
  ERR = 'POST_ADD_BUYER_ERR'
}

export const initialState: PostAddBuyerStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const postAddBuyer = (
  state: PostAddBuyerStoreState = initialState,
  action: {
    type: POST_ADD_BUYER;
    error: {};
  }
): PostAddBuyerStoreState => {
  switch (action.type) {
    case POST_ADD_BUYER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_ADD_BUYER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_ADD_BUYER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postAddBuyer;
