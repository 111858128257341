import styled from 'styled-components';
import { blue, rl } from 'shared/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const DeleteButtonTD = styled.td`
  position: relative;
`;

export const DeleteButton = styled.span`
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 10;
  font-size: 18px;
  color: ${blue};
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const PopupContent = styled.div`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 7px 30px;
  bottom: 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: white;
`;

export const CloseButton = styled(Button)`
  background-color: #1cca94;
`;

export const PopupDeleteButton = styled(Button)`
  background-color: #f53737;

  :disabled {
    background-color: gray;
  }
`;

export const PopupFooter = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const PopupTitle = styled.h2`
  color: #7a7d83;
`;

export const PopupText = styled.p`
  color: #7a7d83;
`;

export const PopupTextarea = styled.textarea`
  min-width: 100%;
  border: 1px solid #d9d9d9;
  min-height: 80px;
  max-width: 700px;
  max-height: 200px;
`;

export const TableRowContacts = styled(TableRowStyled)`
  background-color: ${({ isDeleted }) => isDeleted && '#FAFAFA'};

  td {
    color: ${({ isDeleted }) => isDeleted && '#9FA6B2'};
  }
`;

export const BtnAddContact = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  margin: 20px 0;
  justify-content: center;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  h3 {
    font-family: ${rl};
    font-size: 24px;
  }

  p {
    font-family: ${rl};
    font-size: 14px;
  }
`;
