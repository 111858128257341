import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Row } from 'react-grid-system';
import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS,
  CRM
} from 'src/globaltypes';
import { LeadRead } from '../../actions/setLeadFormData';
import {
  req as getOpenLoanLimits,
  ResponseData as OpenLoanLimitsData
} from 'Application/actions/getOpenLoanLimits';
import { req as putSendToClient } from 'Leads/actions/putSendToClient';
import { ResponseData as DocumentListResponseData } from 'src/features/DocumentList/actions/getDocumentList';
import { req as getLead } from '../../actions/getLead';
import { req as getCompanyHints } from 'Application/actions/getCompanyHints';
import {
  req as getLeadDocumentList,
  reset as resetLeadDocumentList
} from 'Leads/actions/getLeadDocumentList';
import { req as postCreateForLead } from 'Leads/actions/postCreateForLead';
import {
  req as getContactsList,
  ResponseData as ContactsListResponseData
} from 'src/features/Contacts/actions/getContactsList';
import { req as postSendCompanyToScoring } from 'src/features/Leads/actions/postSendCompanyToScoring';
import {
  req as putChecklist,
  reset as resetPutChecklist,
  RequestData as ReqPutChecklist
} from 'src/features/Leads/actions/putChecklist';
import {
  ApplicationViewStyled,
  ViewSeparatorTop
} from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ApplicationId,
  ApplicationTitle,
  MainInfoBlock,
  MainInfoItem,
  ApplicationSource,
  CabinetCard,
  HintsContainer,
  Hint,
  ContainerSubInfo
} from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  LeadStatusStyled,
  BlueVerticalInfoBlock,
  BlueVerticalInfoBlockHeader,
  CheckboxStyle,
  ButtonWrapper,
  ActivityWrapper,
  LeadApiContainer,
  Spinner as SpinnerApi,
  ActivityContainer,
  Limit,
  Spinner,
  DocumentListContainer,
  Period,
  IsNecessarily,
  FileContainer,
  IsUploadContainer,
  DocumentType,
  CompanyHead,
  PopupContainer,
  PopupContentContacts,
  PopupTitle,
  TableWrapper,
  SectionSeparator,
  OpenLoanLimitsContainer,
  ProductsContainer,
  DataContainer,
  HeaderBlock,
  UploadButton,
  PopupContainerChecklist,
  PopupContentChecklist,
  TDComment
} from './styled';
import { LeadStatuses, ApplicationTypes } from 'shared/constants';
import {
  EachCommentStyled,
  CommentHead,
  Avatar,
  CommentInfo,
  CommentBody,
  CommentText
} from 'src/features/Comments/components/CommentsList/EachComment/styles';
import { LeadActionButtons } from './LeadActionButtons/LeadActionButtons';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE,
  Loader
} from 'src/features/Layouts/components';
import { TransitionWrapper, ANIMATION_NAMES } from 'src/features/Common';
import { EditApplicationBtn } from 'src/features/Application/components/ApplicationsList/ReusableComponents/ApplicationStatus/styles';
import { LeadsData, setLeadFormData } from '../../actions/setLeadFormData';
import {
  req as getProductOfApplicationLead,
  ResponseData as ResponseProductApplicationLead
} from 'src/features/Leads/actions/getProductsApplicationLead';
import {
  req as selectProductLead,
  reset as resetProductLeadState
} from 'src/features/Leads/actions/postSelectProductLead';
import { req as getActivitiesByLeadInternal } from 'src/features/Leads/actions/getActivitiesByLeadInternal';
import { req as getActivitiesByLeadExternal } from 'src/features/Leads/actions/getActivitiesByLeadExternal';
import { req as postExternalApiAlfaBank } from 'Leads/actions/postExternalApiAlfaBank';
import { req as postExternalApiPSB } from 'Leads/actions/postExternalApiPSB';
import { req as getUserAccount } from 'src/features/Users/actions/getUser';
import { UserData } from 'src/features/Users/types/UserView';
import {
  req as getFinancialsSummary,
  reset as resetFinancialsSummary
} from 'src/features/Application/actions/getFinancialsSummary';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import {
  ResponseDataInternal as ActivitiesDataInternal,
  InternalActivities
} from 'src/features/Leads/reducers/getActivitiesByLeadInternal';
import { ResponseDataExternal as ActivitiesDataExternal } from 'src/features/Leads/reducers/getActivitiesByLeadExternal';
import { formSumString, formSumStringThousands } from 'src/shared/utils/Utils';
import { LEAD_STATUSES } from '../../types';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { APPLICATION_TYPES } from 'src/features/Application/types';
import { FinancialsSummaryView } from '../FinancialsSummary/FinancialsSummary';
import {
  DocumentListType,
  PersonDocumentsType
} from 'src/features/DocumentList/types';
import {
  ApplicationChecklist,
  BgParametsChecklist,
  BkiChecklist,
  DealChecklist,
  DocChecklist
} from '../LeadsList/styled';
import { ChecklistItem } from '../Checklist/Checklist';
import { Button } from 'shared/ui/Button';
import { red } from 'shared/styled';

interface StateToProps {
  lead: LeadRead;
  status: REQUEST_STATUSES;
  error: ResponseError;
  permissions: USER_PERMISSIONS[];
  companyHints: ResponseData[];
  productOfApplicationLead: ResponseProductApplicationLead[];
  statusSelectProductLead: REQUEST_STATUSES;
  statusProductOfApplicationLead: REQUEST_STATUSES;
  leadActivitiesInternal: ActivitiesDataInternal;
  leadActivitiesExternal: ActivitiesDataExternal[];
  statusPostAlfaBank: REQUEST_STATUSES;
  statusPostPSB: REQUEST_STATUSES;
  userAccount: UserData;
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  openLoanLimits: OpenLoanLimitsData;
  statusOpenLoanLimits: REQUEST_STATUSES;
  errorOpenLoanLimits: ResponseError;
  leadDocuments: DocumentListResponseData;
  statusLeadDocuments: REQUEST_STATUSES;
  errorLeadDocuments: ResponseError;
  statusPostCreateForLead: REQUEST_STATUSES;
  contactLeadList: ContactsListResponseData;
  statusCompanyToScoring: REQUEST_STATUSES;
  statusPutChecklist: REQUEST_STATUSES;
}

interface MatchParams {
  id: string;
}

interface DispatchToProps {
  getLead: (id: number | string) => void;
  // TODO rename LeadsData?
  setLeadFormData: (data: LeadsData) => void;
  // TODO put inside getLead?
  getCompanyHints: (inn: string) => void;
  getProductOfApplicationLead: (id: string) => void;
  selectProductLead: (data: any) => void;
  resetProductLeadState: () => void;
  getActivitiesByLeadInternal: (leadId: string) => void;
  getActivitiesByLeadExternal: (leadId: string) => void;
  postExternalApiAlfaBank: (LeadID: number) => void;
  getUserAccount: (id: string | number) => void;
  postExternalApiPSB: (LeadID: number) => void;
  getFinancialsSummary: (inn: string) => void;
  resetFinancialsSummary: () => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  getOpenLoanLimits: (inn: string) => void;
  getLeadDocumentList: (id: string) => void;
  resetLeadDocumentList: () => void;
  postCreateForLead: (id: string) => void;
  getContactsList: (inn: string) => void;
  putSendToClient: (
    guid: string,
    { leadContactId }: { leadContactId: number }
  ) => void;
  postSendCompanyToScoring: (inn: string) => void;
  putChecklist: (id: string, data: ReqPutChecklist) => void;
  resetPutChecklist: () => void;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const LeadView: React.FC<Props> = ({
  getLead,
  lead,
  status,
  error,
  getUserAccount,
  userAccount,
  getCompanyHints,
  companyHints,
  getOpenLoanLimits,
  openLoanLimits,
  statusOpenLoanLimits,
  getActivitiesByLeadExternal,
  leadActivitiesExternal,
  getActivitiesByLeadInternal,
  leadActivitiesInternal,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  getFinancialsSummary,
  financailsSummaryData,
  statusFinancailsSummaryData,
  getContactsList,
  contactLeadList,
  getProductOfApplicationLead,
  productOfApplicationLead,
  statusProductOfApplicationLead,
  getLeadDocumentList,
  leadDocuments,
  statusLeadDocuments,
  postCreateForLead,
  postExternalApiAlfaBank,
  postExternalApiPSB,
  statusPostAlfaBank,
  statusPostCreateForLead,
  statusPostPSB,
  statusSelectProductLead,
  setLeadFormData,
  putSendToClient,
  resetLeadDocumentList,
  resetProductLeadState,
  permissions,
  history,
  match,
  selectProductLead,
  postSendCompanyToScoring,
  statusCompanyToScoring,
  putChecklist,
  resetPutChecklist,
  statusPutChecklist
}) => {
  const [
    sortedActivitiesInternal,
    setSortedActivitiesInternal
  ] = React.useState<InternalActivities[]>([]);
  const [
    sortedActivitiesExternal,
    setSortedActivitiesExternal
  ] = React.useState<ActivitiesDataExternal[]>([]);
  const [showTooltips, setShowTooltips] = React.useState(false);
  const [year] = React.useState(new Date().getFullYear() - 1);
  const [selectYear, setSelectYear] = React.useState(null);
  const [revenueSaleData, setRevenueSaleData] = React.useState([]);
  const [netProfitData, setNetProfitData] = React.useState([]);
  const [capitalData, setCapitalData] = React.useState([]);
  const [profitSaleData, setProfitSaleData] = React.useState([]);
  const [EBITDAData, setEBITDAData] = React.useState([]);
  const [DEBTEBITDAData, setDEBTEBITDAData] = React.useState([]);
  const [netDebtData, setNetDebtData] = React.useState([]);
  const [
    longTermBorrowedFundsData,
    setLongTermBorrowedFundsData
  ] = React.useState([]);
  const [
    shortTermBorrowedFundsData,
    setShortTermBorrowedFundsData
  ] = React.useState([]);
  const [totalBorrowedFundsData, setTotalBorrowedFundsData] = React.useState(
    []
  );
  const [totalAssetsData, setTotalAssetsData] = React.useState([]);
  const [SOKData, setSOKData] = React.useState([]);
  const [sumDIO_DSO, setSumDIO_DSO] = React.useState(0);
  const [DIO, setDIO] = React.useState(0);
  const [DSO, setDSO] = React.useState(0);
  const [DPO, setDPO] = React.useState(0);
  const [CCC, setCCC] = React.useState(0);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState<FinancailsSummaryData[]>([]);
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentListType[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);
  const [companyINN, setCompanyINN] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [contactsIsVisible, setContactsIsVisible] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const productRef = React.createRef<HTMLDivElement>();
  const contactModalRef = React.createRef();

  React.useEffect(() => {
    getLead(match.params.id);
    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
      getProductOfApplicationLead(match.params.id);
      getActivitiesByLeadInternal(match.params.id);
      getLeadDocumentList(match.params.id);
    }
    if (permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT)) {
      getActivitiesByLeadExternal(match.params.id);
    }

    return () => {
      resetLeadDocumentList();
    };
  }, []);

  React.useEffect(
    () => {
      if (!!lead) {
        getUserAccount(
          lead.creatorId === 0 ? lead.sourceUserId : lead.creatorId
        );
        getContactsList(lead.inn);

        if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
          getCompanyHints(lead.inn);
          getFinancialsSummary(lead.inn);
          getOpenLoanLimits(lead.inn);
        }
      }
    },
    [lead]
  );

  React.useEffect(
    () => {
      if (
        !!lead &&
        statusCompanyToScoring === REQUEST_STATUSES.GOT &&
        permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)
      ) {
        getOpenLoanLimits(lead.inn);
      }
    },
    [statusCompanyToScoring]
  );

  React.useEffect(
    () => {
      if (financailsSummaryData.length) {
        const filtredData = financailsSummaryData.sort(
          (a, b) => a.year - b.year
        );

        const revenueSaleData = [] as number[];
        const netProfitData = [] as number[];
        const capitalData = [] as number[];
        const profitSaleData = [];
        const EBITDAData = [];
        const DEBTEBITDAData = [];
        const netDebtData = [];
        const longTermBorrowedFundsData = [];
        const shortTermBorrowedFundsData = [];
        const totalBorrowedFundsData = [];
        const totalAssetsData = [];
        const SOKData = [];

        for (const obj of filtredData) {
          revenueSaleData.push(obj.row2110);
          netProfitData.push(obj.row2400);
          capitalData.push(obj.row1300);
          profitSaleData.push(obj.row2200);
          EBITDAData.push(obj.ebitda);
          DEBTEBITDAData.push(obj.debt_to_ebitda);
          netDebtData.push(obj.net_debt);
          longTermBorrowedFundsData.push(obj.row1410);
          shortTermBorrowedFundsData.push(obj.row1510);
          totalBorrowedFundsData.push(obj.debt);
          totalAssetsData.push(obj.row1600);
          SOKData.push(obj.sok);
        }

        const lastElem = filtredData[filtredData.length - 1];

        const sumDIO_DSO = lastElem.dio + lastElem.dso;

        setSumDIO_DSO(sumDIO_DSO);
        setDIO(lastElem.dio);
        setDSO(lastElem.dso);
        setDPO(lastElem.dpo);
        setCCC(lastElem.ccc);
        setFiltredFinancailsSummaryData(filtredData);
        setSelectYear(filtredData.length - 1);
        setRevenueSaleData(revenueSaleData);
        setNetProfitData(netProfitData);
        setCapitalData(capitalData);
        setProfitSaleData(profitSaleData);
        setEBITDAData(EBITDAData);
        setDEBTEBITDAData(DEBTEBITDAData);
        setNetDebtData(netDebtData);
        setLongTermBorrowedFundsData(longTermBorrowedFundsData);
        setShortTermBorrowedFundsData(shortTermBorrowedFundsData);
        setTotalBorrowedFundsData(totalBorrowedFundsData);
        setTotalAssetsData(totalAssetsData);
        setSOKData(SOKData);
      }
    },
    [financailsSummaryData]
  );

  React.useEffect(
    () => {
      if (
        statusExternalApiGIR_BO === REQUEST_STATUSES.GOT &&
        financailsSummaryData.length <= 0
      ) {
        getFinancialsSummary(lead.inn);
      }
    },
    [statusExternalApiGIR_BO]
  );

  React.useEffect(
    () => {
      if (statusSelectProductLead === REQUEST_STATUSES.GOT) {
        resetProductLeadState();
        getProductOfApplicationLead(match.params.id);
      }
    },
    [statusSelectProductLead]
  );

  React.useEffect(
    () => {
      if (leadActivitiesInternal.items.length) {
        const sortedActivitiesInternal = leadActivitiesInternal.items.sort(
          (a, b) => {
            if (a.createdDateTime < b.createdDateTime) {
              return 1;
            }
            if (a.createdDateTime > b.createdDateTime) {
              return -1;
            }
            return 0;
          }
        );
        setSortedActivitiesInternal(sortedActivitiesInternal);
      }
    },
    [leadActivitiesInternal]
  );

  React.useEffect(
    () => {
      if (leadActivitiesExternal.length) {
        const sortedActivitiesExternal = leadActivitiesExternal.sort((a, b) => {
          if (a.createdDateTime < b.createdDateTime) {
            return 1;
          }
          if (a.createdDateTime > b.createdDateTime) {
            return -1;
          }
          return 0;
        });
        setSortedActivitiesExternal(sortedActivitiesExternal);
      }
    },
    [leadActivitiesExternal]
  );

  React.useEffect(
    () => {
      if (statusPostCreateForLead === REQUEST_STATUSES.GOT) {
        getLeadDocumentList(match.params.id);
      }
    },
    [statusPostCreateForLead]
  );

  React.useEffect(
    () => {
      if (
        statusLeadDocuments === REQUEST_STATUSES.GOT &&
        leadDocuments.items.length
      ) {
        setCompanyINN(leadDocuments.items[0].companyINN);
        setCompanyName(leadDocuments.items[0].companyShortName);

        const filtredInnDocuments = leadDocuments.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        leadDocuments.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            guid
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                guid
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [statusLeadDocuments]
  );

  React.useEffect(
    () => {
      if (statusPutChecklist === REQUEST_STATUSES.GOT) {
        getLead(match.params.id);
        resetPutChecklist();
      }
    },
    [statusPutChecklist]
  );

  const navigateToCompanies = () => {
    window.open(`/crm/companies/${lead.inn}`, '_blank');
  };

  const handleToggleTooltips = () => {
    setShowTooltips(prevState => !prevState);
  };

  const prepareForLeadEdit = (lead: LeadRead) => {
    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    setLeadFormData(lead);
    history.push(`/cabinet/${role}/createLead`);
  };

  const handleSelectProductLead = () => {
    selectProductLead({ LeadID: +match.params.id });
  };

  const onProductClick = (id: number) => {
    window.open(`/crm/products/${id}`, '_blank');
  };

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigateToAddActivity = () => {
    const state = {
      inn: lead.inn,
      applicationCode: match.params.id
    };

    localStorage.setItem('applicationState', JSON.stringify(state));

    window.open('/crm/activities/new', '_blank');
  };

  const navigateToActivities = (id: number) => {
    window.open(`/crm/activities/${id}`, '_blank');
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(lead.inn, year));
    });
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Element;

    if (
      contactModalRef.current &&
      !(contactModalRef.current as Element).contains(target)
    ) {
      setContactsIsVisible(false);
    }
  };

  const handleRowClick = (id: number) => {
    if (!selectedRows.includes(id)) {
      putSendToClient(leadDocuments.guid, {
        leadContactId: id
      });
      setSelectedRows([...selectedRows, id]);
    }
  };

  const navigateToCreateContact = () => {
    history.push('/crm/contacts/new', { inn: lead.inn });
  };

  const sendCompanyToScoring = () => {
    postSendCompanyToScoring(lead.inn);
  };

  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.GOT && (
        <>
          <HeaderBlock>
            <div>
              <div>
                <ApplicationId>Лид №{lead.id}</ApplicationId>
                <ApplicationTitle>{lead.clientName}</ApplicationTitle>

                <LeadStatusStyled status={lead.status}>
                  {LeadStatuses[lead.status]}
                </LeadStatusStyled>
              </div>
              {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
                <CabinetCard onClick={navigateToCompanies}>
                  Перейти в CRM компании
                </CabinetCard>
              )}
            </div>

            <ButtonWrapper>
              {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                (productOfApplicationLead.length ? (
                  <Button
                    label="Перейти к продуктам"
                    onClick={scrollToProduct}
                  />
                ) : (
                  <Button
                    backgroundColor={red}
                    label="Подобрать продукты"
                    onClick={handleSelectProductLead}
                  />
                ))}
              {!!companyHints.length && (
                <Button
                  label={
                    showTooltips ? 'Скрыть подсказки' : 'Показать подсказки'
                  }
                  onClick={handleToggleTooltips}
                />
              )}
              {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) &&
                statusFinancailsSummaryData === REQUEST_STATUSES.ERROR && (
                  <>
                    <Button
                      label="Загрузить отчетность из налоговой"
                      disabled={
                        statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST
                      }
                      onClick={() => sendRequestsGIR_BO(year)}
                    />
                    {statusExternalApiGIR_BO === REQUEST_STATUSES.REQUEST && (
                      <Spinner icon={faSpinner} />
                    )}
                  </>
                )}
              {showTooltips && (
                <HintsContainer>
                  {companyHints.map((hint, index) => (
                    <Hint code={hint.code} key={index}>
                      <pre>{hint.text}</pre>
                    </Hint>
                  ))}
                </HintsContainer>
              )}
            </ButtonWrapper>
          </HeaderBlock>

          <DataContainer isData={!!filtredFinancailsSummaryData.length}>
            <div>
              {lead.rejectReason && (
                <ApplicationSource>{lead.rejectReason}</ApplicationSource>
              )}
              <ViewSeparatorTop>
                {(permissions.includes(USER_PERMISSIONS.LEADS_MANAGER) ||
                  lead.status === LEAD_STATUSES.CREATED) && (
                  <TransitionWrapper
                    in={true}
                    animationName={ANIMATION_NAMES.FADE}
                  >
                    <EditApplicationBtn
                      onClick={() => prepareForLeadEdit(lead)}
                    >
                      Редактировать лид
                    </EditApplicationBtn>
                  </TransitionWrapper>
                )}
              </ViewSeparatorTop>
              <MainInfoBlock>
                <MainInfoItem>
                  <span>Тип финансирования</span>
                  <span>{ApplicationTypes[lead.applicationFinancingType]}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Сумма сделки</span>
                  <span>{formSumString(lead.amount)}</span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>Срок</span>
                  <span>
                    {lead.termStartDate} - {lead.termEndDate}
                  </span>
                </MainInfoItem>
                <MainInfoItem>
                  <span>ИНН</span>
                  <span>{lead.inn}</span>
                </MainInfoItem>
              </MainInfoBlock>

              <Row>
                <Col lg={!!filtredFinancailsSummaryData.length ? 5 : 3}>
                  {/* TODO extract */}
                  <BlueVerticalInfoBlock>
                    <BlueVerticalInfoBlockHeader>
                      Контакт
                    </BlueVerticalInfoBlockHeader>
                    <p>
                      Фамилия <span>{lead.contactLastName}</span>
                    </p>
                    <p>
                      Имя <span>{lead.contactFirstName}</span>
                    </p>
                    <p>
                      Отчество <span>{lead.contactMiddleName}</span>
                    </p>
                    <p>
                      Почта <span>{lead.contactEmail}</span>
                    </p>
                    <p>
                      Телефон <span>{lead.contactPhoneNumber}</span>
                    </p>
                  </BlueVerticalInfoBlock>
                </Col>

                {lead.sourceUser && (
                  <Col lg={!!filtredFinancailsSummaryData.length ? 5 : 3}>
                    {/* TODO extract */}
                    <BlueVerticalInfoBlock>
                      <BlueVerticalInfoBlockHeader>
                        Источник (Лидогенератор)
                      </BlueVerticalInfoBlockHeader>
                      <p>
                        Имя{' '}
                        <span>
                          {lead.sourceUser.firstName} {lead.sourceUser.lastName}
                        </span>
                      </p>
                      <p>
                        Компания <span>{lead.sourceUser.companyName}</span>
                      </p>
                      <p>
                        Номер телефона{' '}
                        <span>{lead.sourceUser.phoneNumber}</span>
                      </p>
                      <p>
                        ИНН <span>{lead.sourceUser.inn}</span>
                      </p>
                      <p>
                        Ссылка источника <span>{lead.sourceLink}</span>
                      </p>
                    </BlueVerticalInfoBlock>
                  </Col>
                )}

                {userAccount && (
                  <Col lg={!!filtredFinancailsSummaryData.length ? 5 : 3}>
                    <BlueVerticalInfoBlock>
                      <BlueVerticalInfoBlockHeader>
                        Взял в работу:
                      </BlueVerticalInfoBlockHeader>
                      <p>
                        Имя{' '}
                        <span>
                          {userAccount.lastName} {userAccount.firstName}
                        </span>
                      </p>
                      <p>
                        Email <span>{userAccount.email}</span>
                      </p>
                      <p>
                        Телефон <span>{userAccount.phoneNumber}</span>
                      </p>
                    </BlueVerticalInfoBlock>
                  </Col>
                )}

                {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
                  <Col>
                    <ChecklistItem
                      BlockComponent={DocChecklist}
                      type={lead.docsChecklist}
                      putChecklist={putChecklist}
                      title="Список документов"
                      description={
                        lead.docsChecklist === 2
                          ? 'Документы запрошены'
                          : lead.docsChecklist === 4
                            ? 'Предоставлены частично'
                            : lead.docsChecklist === 6
                              ? 'Предоставлены только обязательные'
                              : lead.docsChecklist === 8
                                ? 'Предоставлены все документы'
                                : 'Документы еще не запрошены'
                      }
                      listItems={[
                        'Документы еще не запрошены',
                        'Документы запрошены',
                        'Предоставлены частично',
                        'Предоставлены только обязательные',
                        'Предоставлены все документы'
                      ]}
                    />
                    <ChecklistItem
                      BlockComponent={BkiChecklist}
                      type={lead.bkiChecklist}
                      putChecklist={putChecklist}
                      title="Согласие перс.данных и БКИ"
                      description={
                        lead.bkiChecklist === 2
                          ? 'Ссылка направлена, но не подписана'
                          : lead.bkiChecklist === 4
                            ? 'Ссылка подписана'
                            : 'Согласие еще не запрошено'
                      }
                      listItems={[
                        'Согласие еще не запрошено',
                        'Ссылка направлена, но не подписана',
                        'Ссылка подписана'
                      ]}
                    />
                    {lead.applicationFinancingType.includes(
                      APPLICATION_TYPES.GUARANTEES
                    ) && (
                      <ChecklistItem
                        BlockComponent={BgParametsChecklist}
                        type={lead.bgParametersChecklist}
                        putChecklist={putChecklist}
                        title="Согласование БГ с заказчиком"
                        description={
                          lead.bgParametersChecklist === 2
                            ? 'Макет направлен для согласования'
                            : lead.bgParametersChecklist === 4
                              ? 'Согласовано с заказчиком'
                              : 'Макет еще не направлен'
                        }
                        listItems={[
                          'Макет еще не направлен',
                          'Макет направлен для согласования',
                          'Согласовано с заказчиком'
                        ]}
                      />
                    )}
                    <ChecklistItem
                      BlockComponent={ApplicationChecklist}
                      type={lead.applicationToBankCheckList}
                      putChecklist={putChecklist}
                      title="Подача заявки в банк"
                      description={
                        lead.applicationToBankCheckList === 2
                          ? 'Ссылка банка направлена для подписания'
                          : lead.applicationToBankCheckList === 4
                            ? 'Заявка подана в банк'
                            : 'Ссылка банка еще не направлена'
                      }
                      listItems={[
                        'Ссылка банка еще не направлена',
                        'Ссылка банка направлена для подписания',
                        'Заявка подана в банк'
                      ]}
                    />
                    <ChecklistItem
                      BlockComponent={DealChecklist}
                      type={lead.dealToBankChecklist}
                      putChecklist={putChecklist}
                      title="Подписание кредитной документации"
                      description={
                        lead.dealToBankChecklist === 2
                          ? 'Ссылка банка направлена для подписания'
                          : lead.dealToBankChecklist === 4
                            ? 'Документация с банком подписана'
                            : 'Ссылка банка еще не направлена'
                      }
                      listItems={[
                        'Ссылка банка еще не направлена',
                        'Ссылка банка направлена для подписания',
                        'Документация с банком подписана'
                      ]}
                    />
                  </Col>
                )}
              </Row>
              {lead.purchaseNumber && (
                <p>Номер закупки: {lead.purchaseNumber}</p>
              )}
            </div>

            {!!filtredFinancailsSummaryData.length && (
              <ContainerSubInfo>
                <FinancialsSummaryView
                  DIO={DIO}
                  DPO={DPO}
                  DSO={DSO}
                  CCC={CCC}
                  EBITDAData={EBITDAData}
                  SOKData={SOKData}
                  capitalData={capitalData}
                  filtredFinancailsSummaryData={filtredFinancailsSummaryData}
                  longTermBorrowedFundsData={longTermBorrowedFundsData}
                  shortTermBorrowedFundsData={shortTermBorrowedFundsData}
                  netDebtData={netDebtData}
                  netProfitData={netProfitData}
                  profitSaleData={profitSaleData}
                  revenueSaleData={revenueSaleData}
                  DEBTEBITDAData={DEBTEBITDAData}
                  sumDIO_DSO={sumDIO_DSO}
                  totalAssetsData={totalAssetsData}
                  totalBorrowedFundsData={totalBorrowedFundsData}
                  selectYear={selectYear}
                  setSelectYear={selectYear => setSelectYear(selectYear)}
                />
              </ContainerSubInfo>
            )}
          </DataContainer>

          <p>Комментарий</p>
          <EachCommentStyled>
            <CommentHead>
              <Avatar isAuthor={true}>
                {lead.contactFirstName
                  ? lead.contactFirstName.charAt(0).toUpperCase()
                  : 'U'}
              </Avatar>

              <CommentInfo>
                <span>
                  {lead.contactFirstName} {lead.contactLastName}
                </span>
              </CommentInfo>
            </CommentHead>

            <CommentBody>
              <CommentText isFullView={true}>{lead.comment}</CommentText>
            </CommentBody>
          </EachCommentStyled>

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <SectionSeparator>
              <LeadActionButtons lead={lead} />
            </SectionSeparator>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <SectionSeparator>
              <DocumentListContainer>
                <ApplicationTitle>Список документов:</ApplicationTitle>
                {leadDocuments.items.length ? (
                  <>
                    {leadDocuments.items.some(
                      doc => doc.isUploaded === false
                    ) && (
                      <Button
                        label="Отправить на email клиенту"
                        onClick={() => setContactsIsVisible(true)}
                      />
                    )}

                    <CompanyHead>
                      <p>{companyName}</p>
                      <p>ИНН: {companyINN}</p>
                    </CompanyHead>

                    <TableWrapper>
                      <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                        <TableHeaderStyled>
                          <tr>
                            <TableThStyled width="12%">
                              Тип документа
                            </TableThStyled>
                            <TableThStyled width="8%">Период</TableThStyled>
                            <TableThStyled width="10%" />
                            <TableThStyled width="25%" />
                          </tr>
                        </TableHeaderStyled>
                        <tbody>
                          {filtredInnDocuments.map(document => (
                            <TableRowStyled key={document.guid}>
                              <td>{document.documentName}</td>
                              <td>
                                <Period>
                                  <p>
                                    {document.taxYear && document.taxPeriodName
                                      ? `${document.taxYear}, ${
                                          document.taxPeriodName
                                        }`
                                      : '-'}
                                  </p>
                                </Period>
                              </td>
                              <td>
                                {document.isMandatory && (
                                  <IsNecessarily
                                    isUploaded={document.isUploaded}
                                  >
                                    *Обязательный
                                  </IsNecessarily>
                                )}
                              </td>
                              <td>
                                <FileContainer>
                                  <IsUploadContainer
                                    isUploaded={document.isUploaded}
                                  >
                                    {document.isUploaded ? (
                                      <div>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>Загружен</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <FontAwesomeIcon icon={faTimes} />
                                        <p>Не загружен</p>
                                      </div>
                                    )}
                                  </IsUploadContainer>
                                </FileContainer>
                              </td>
                            </TableRowStyled>
                          ))}
                        </tbody>
                      </TableStyled>
                    </TableWrapper>

                    {filtredPersonDocuments.map((person, index) => (
                      <React.Fragment key={index}>
                        <CompanyHead>
                          <p>{person.personName}</p>
                          <p>{person.personComment}</p>
                        </CompanyHead>

                        <TableWrapper>
                          <TableStyled
                            sizes={[]}
                            cellSpacing="0"
                            cellPadding="0"
                            key={index}
                          >
                            <TableHeaderStyled>
                              <tr>
                                <TableThStyled width="10%">
                                  Тип документа
                                </TableThStyled>
                                <TableThStyled width="8%" />
                                <TableThStyled width="25%" />
                              </tr>
                            </TableHeaderStyled>
                            <tbody>
                              {person.documents.map((document, index) => (
                                <TableRowStyled key={index}>
                                  <td>
                                    <DocumentType>
                                      {document.documentName}
                                    </DocumentType>
                                  </td>
                                  <td>
                                    {document.isMandatory && (
                                      <IsNecessarily
                                        isUploaded={document.isUploaded}
                                      >
                                        *Обязательный
                                      </IsNecessarily>
                                    )}
                                  </td>
                                  <td>
                                    <FileContainer>
                                      <IsUploadContainer
                                        isUploaded={document.isUploaded}
                                      >
                                        {document.isUploaded ? (
                                          <div>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <p>Загружен</p>
                                          </div>
                                        ) : (
                                          <div>
                                            <FontAwesomeIcon icon={faTimes} />
                                            <p>Не загружен</p>
                                          </div>
                                        )}
                                      </IsUploadContainer>
                                    </FileContainer>
                                  </td>
                                </TableRowStyled>
                              ))}
                            </tbody>
                          </TableStyled>
                        </TableWrapper>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <Button
                    label="+ Сформировать базовый список"
                    onClick={() => postCreateForLead(match.params.id)}
                  />
                )}
              </DocumentListContainer>

              <PopupContainer
                visible={contactsIsVisible}
                onClick={handleOutsideClick}
              >
                <PopupContentContacts ref={contactModalRef}>
                  <PopupTitle>
                    Выберите контакт для отправки списка документов:
                  </PopupTitle>

                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="15%" />
                        <TableThStyled width="10%" />
                        <TableThStyled width="10%" />
                        <TableThStyled width="0%" />
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {contactLeadList.items.map(contact => (
                        <TableRowStyled key={contact.id}>
                          <td>
                            {contact.lastName} {contact.firstName}{' '}
                            {contact.middleName}
                          </td>
                          <td>{contact.position}</td>
                          <td>{contact.email}</td>
                          <td>
                            {!selectedRows.includes(contact.id) && (
                              <button
                                onClick={() => handleRowClick(contact.id)}
                              >
                                Отправить email
                              </button>
                            )}
                            {selectedRows.includes(contact.id) && 'Отправлено'}
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>
                  <div>
                    <p>Нет в списке?</p>
                    <Button
                      label={'+ Создать новый контакт'}
                      onClick={navigateToCreateContact}
                    />
                  </div>
                </PopupContentContacts>
              </PopupContainer>
            </SectionSeparator>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <SectionSeparator>
              <ActivityContainer>
                <ActivityWrapper>
                  <ApplicationTitle>Активности</ApplicationTitle>
                  <Button
                    label="+ Добавить активность"
                    onClick={navigateToAddActivity}
                  />
                </ActivityWrapper>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="15%">Дата</TableThStyled>
                      <TableThStyled width="15%">Тип активности</TableThStyled>
                      <TableThStyled width="15%">Результат</TableThStyled>
                      <TableThStyled width="25%">Комментарий</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {sortedActivitiesInternal.map((activity, index) => (
                      <TableRowStyled
                        key={index}
                        onClick={() => navigateToActivities(activity.id)}
                      >
                        <td>
                          {new Date(
                            activity.createdDateTime
                          ).toLocaleDateString()}
                        </td>
                        <td>{activity.activityTypeName}</td>
                        <td>{activity.result}</td>
                        <td>{activity.extComment}</td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              </ActivityContainer>
            </SectionSeparator>
          )}

          {permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
            !!leadActivitiesExternal.length && (
              <SectionSeparator>
                <ActivityContainer>
                  <ApplicationTitle>Активности</ApplicationTitle>
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="8%">Дата</TableThStyled>
                        <TableThStyled width="8%">Тип активности</TableThStyled>
                        <TableThStyled width="25%">Комментарий</TableThStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {sortedActivitiesExternal.map((activity, index) => (
                        <TableRowStyled key={index}>
                          <td>
                            {new Date(
                              activity.createdDateTime
                            ).toLocaleDateString()}
                          </td>
                          <td>{activity.activityTypeName}</td>
                          <TDComment>{activity.extComment}</TDComment>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>
                </ActivityContainer>
              </SectionSeparator>
            )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <SectionSeparator>
              <OpenLoanLimitsContainer>
                <ApplicationTitle>Предодобренные лимиты:</ApplicationTitle>

                <UploadButton
                  disabled={statusCompanyToScoring === REQUEST_STATUSES.REQUEST}
                  onClick={sendCompanyToScoring}
                >
                  <p>Обновить скоринг Альфа-Банка</p>
                  {statusCompanyToScoring === REQUEST_STATUSES.REQUEST && (
                    <SpinnerApi icon={faSpinner} />
                  )}
                </UploadButton>

                {statusOpenLoanLimits === REQUEST_STATUSES.REQUEST && (
                  <Loader />
                )}
                {statusOpenLoanLimits === REQUEST_STATUSES.GOT && (
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableThStyled width="10%">Банк</TableThStyled>
                        <TableThStyled width="10%">Продукт</TableThStyled>
                        <TableThStyled width="5%">Лимит</TableThStyled>
                        <TableThStyled width="7%">Срок, мес.</TableThStyled>
                        <TableThStyled width="7%">Ставка</TableThStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {openLoanLimits.items.map((limit, key) => (
                        <TableRowStyled
                          onClick={() => onProductClick(limit.productId)}
                          key={key}
                        >
                          <td>{limit.bankShortName}</td>
                          <td>{limit.productName}</td>
                          <td>
                            {limit.volume === null
                              ? 'Неизв.'
                              : formSumStringThousands(limit.volume)}
                          </td>
                          <td>
                            {limit.term === null
                              ? 'Неизв.'
                              : `До ${limit.term} мес`}
                          </td>
                          <td>
                            {limit.rate ? `${limit.rate}% годовых` : 'Неизв.'}
                          </td>
                        </TableRowStyled>
                      ))}
                    </tbody>
                  </TableStyled>
                )}
              </OpenLoanLimitsContainer>
            </SectionSeparator>
          )}

          {permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT) && (
            <ProductsContainer>
              <ApplicationTitle ref={productRef}>Продукты</ApplicationTitle>

              {!lead.applicationFinancingType.includes(
                APPLICATION_TYPES.GUARANTEES
              ) &&
                !lead.applicationFinancingType.includes(
                  APPLICATION_TYPES.FACTORING
                ) &&
                status === REQUEST_STATUSES.GOT && (
                  <LeadApiContainer>
                    <div>
                      <Button
                        label="API Альфа-Банк ММБ"
                        onClick={() => postExternalApiAlfaBank(lead.id)}
                        disabled={statusPostAlfaBank.includes(
                          REQUEST_STATUSES.REQUEST
                        )}
                      />
                      {statusPostAlfaBank.includes(
                        REQUEST_STATUSES.REQUEST
                      ) && <SpinnerApi icon={faSpinner} />}
                    </div>
                    <Button
                      label="API ПСБ “Без Бумаг”"
                      onClick={() => postExternalApiPSB(lead.id)}
                      disabled={statusPostPSB.includes(
                        REQUEST_STATUSES.REQUEST
                      )}
                    />
                    {statusPostPSB.includes(REQUEST_STATUSES.REQUEST) && (
                      <SpinnerApi icon={faSpinner} />
                    )}
                  </LeadApiContainer>
                )}

              {statusProductOfApplicationLead === REQUEST_STATUSES.REQUEST && (
                <Loader />
              )}
              {statusProductOfApplicationLead === REQUEST_STATUSES.GOT && (
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="1%">ID</TableThStyled>
                      <TableThStyled width="7%">Банк</TableThStyled>
                      <TableThStyled width="7%">Продукт</TableThStyled>
                      <TableThStyled width="7%">Сложность</TableThStyled>
                      <TableThStyled width="4%">Договор с банком</TableThStyled>
                      <TableThStyled width="4%">Ставка min</TableThStyled>
                      <TableThStyled width="2%">Сред</TableThStyled>
                      <TableThStyled width="2%">Max</TableThStyled>
                      <TableThStyled width="6%">Cashback</TableThStyled>
                      <TableThStyled width="6%">Рейтинг</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {productOfApplicationLead.map(product => (
                      <TableRowStyled
                        onClick={() => onProductClick(product.productId)}
                        key={product.productId}
                      >
                        <td>{product.productId}</td>
                        <td>{product.bankName}</td>
                        <td>{product.productName}</td>
                        <td>{product.complexity}</td>
                        <td>
                          {product.hasContract
                            ? 'Есть договор'
                            : 'Нет договора'}
                        </td>
                        {(product.offerSize === 0 && product.offerRate === 0) ||
                        (product.offerSize === null &&
                          product.offerRate === null) ? (
                          <>
                            <td>{product.minRate}</td>
                            <td>
                              {product.meanRate ? product.meanRate : 'н/д'}
                            </td>
                            <td>{product.maxRate}</td>
                          </>
                        ) : (
                          <>
                            <td />
                            <td>
                              <Limit>
                                <p>Индикатив:</p>
                                <p>
                                  лимит до{' '}
                                  {formSumStringThousands(product.offerSize)} р,
                                  ставка {product.offerRate}%
                                </p>
                              </Limit>
                            </td>
                            <td />
                          </>
                        )}
                        <td>
                          {product.hasCashback ? (
                            <CheckboxStyle verify={product.hasCashback}>
                              <FontAwesomeIcon icon={faCheck} />
                            </CheckboxStyle>
                          ) : (
                            'Нет'
                          )}
                        </td>
                        <td>{product.rating}</td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              )}
            </ProductsContainer>
          )}
        </>
      )}

      {statusPutChecklist === REQUEST_STATUSES.ERROR && (
        <PopupContainerChecklist>
          <PopupContentChecklist>
            <span onClick={resetPutChecklist}>X</span>

            <PopupTitle>ОШИБКА</PopupTitle>
            <p>Нет доступа к лиду</p>
          </PopupContentChecklist>
        </PopupContainerChecklist>
      )}

      {/* TODO refactor into single one? */}
      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  Leads,
  User,
  Application,
  Users,
  Contacts
}: CRM & STORE) => ({
  companyHints: Application.getCompanyHints.data,
  lead: Leads.getLead.lead,
  status: Leads.getLead.status,
  error: Leads.getLead.error,
  productOfApplicationLead: Leads.getProductApplicationLead.data,
  statusProductOfApplicationLead: Leads.getProductApplicationLead.status,
  statusSelectProductLead: Leads.postSelectProductLead.status,
  permissions: User.getUserData.data.permissions,
  leadActivitiesInternal: Leads.getActivitiesByLeadInternal.data,
  leadActivitiesExternal: Leads.getActivitiesByLeadExternal.data,
  statusPostAlfaBank: Leads.postExternalApiAlfaBankLead.status,
  statusPostPSB: Leads.postExternalApiPSBLead.status,
  userAccount: Users.getUser.user,
  statusSelectApplication: Application.postSelectApplication.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error,
  leadDocuments: Leads.getLeadDocumentList.data,
  statusLeadDocuments: Leads.getLeadDocumentList.status,
  errorLeadDocuments: Leads.getLeadDocumentList.error,
  statusPostCreateForLead: Leads.postCreateForLead.status,
  contactLeadList: Contacts.getContactsList.data,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  statusPutChecklist: Leads.putChecklist.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getLead,
      setLeadFormData,
      getCompanyHints,
      getProductOfApplicationLead,
      selectProductLead,
      resetProductLeadState,
      getActivitiesByLeadInternal,
      getActivitiesByLeadExternal,
      postExternalApiAlfaBank,
      postExternalApiPSB,
      getUserAccount,
      getFinancialsSummary,
      resetFinancialsSummary,
      getExternalApiGIR_BO,
      getOpenLoanLimits,
      getLeadDocumentList,
      resetLeadDocumentList,
      postCreateForLead,
      getContactsList,
      putSendToClient,
      postSendCompanyToScoring,
      putChecklist,
      resetPutChecklist
    },
    dispatch
  );

const LeadViewConnect = withRouter<any>(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(LeadView)
);

export { LeadViewConnect as LeadView };
