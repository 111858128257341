import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/addContact';

import { ADD_CONTACT } from '../reducers/addContact';
import ActivitiesApi from '../api';

function* addContact(action: {
  type: ADD_CONTACT;
  activityId: string;
  contactId: number;
}): Generator {
  try {
    let res: any;

    res = yield call(
      ActivitiesApi.addContact,
      action.activityId,
      action.contactId
    );

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* addContactSaga(): Generator {
  yield takeLatest(ADD_CONTACT.REQ, addContact);
}
