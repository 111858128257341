import styled from 'styled-components';
import { lightGray } from 'shared/styled';

export const AuctionHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  button {
    width: fit-content;
    height: 40px;
    margin-bottom: 20px;
  }
`;

export const Status = styled.div`
  width: 70%;
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ status }) =>
    status === 'Сбор заявок'
      ? 'rgba(253, 241, 224, 1)'
      : status === 'Подведение итогов'
        ? 'rgba(213, 232, 253, 1)'
        : lightGray};
`;

export const Center = styled.div`
  width: ${({ w }) => w || 'auto'};
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  text-align: center;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
`;

export const ModalStyled = styled.div`
  width: 100%;

  h3 {
    margin-bottom: 35px;
  }

  p {
    margin: 0;
  }

  & > div:not(:last-child) {
    margin-bottom: 35px;
  }

  & > div > p {
    margin-bottom: 10px;
  }

  & > div > div > div {
    input {
      height: 40px;
    }
  }
`;

export const InputContainer = styled.div`
  width: ${({ w }) => (w ? w : 'auto')};
`;
