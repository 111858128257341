import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gray, lighterBlue, lightGray, navyBlue, rr } from 'shared/styled';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 50px;
  margin-bottom: 20px;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: end;
  padding: 10px 20px;
  background-color: ${lighterBlue};

  span {
    align-self: center;
    margin: 20px 10px 0 10px;
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(5) {
    margin-right: 20px;
  }

  & > div:nth-child(3),
  & > div:nth-child(5),
  & > div:nth-child(6),
  & > div:nth-child(8) {
    min-width: 160px;
  }
`;

export const ModalContainerFilter = styled.div`
  display: flex;
  align-items: end;
  padding: 10px 20px;
  background-color: ${lighterBlue};

  span {
    align-self: center;
    margin: 20px 10px 0 10px;
  }

  & > div:nth-child(1),
  & > div:nth-child(4) {
    margin-right: 20px;
  }

  & > div:nth-child(1),
  & > div:nth-child(4),
  & > div:nth-child(5),
  & > div:nth-child(7) {
    min-width: 160px;
  }
`;

export const MonetaryClaimInfo = styled.div`
  p {
    color: ${gray};
  }

  & > div {
    margin-left: 25px;

    & > div > div {
      display: flex;
      align-items: center;
      column-gap: 20px;
      margin-left: 15px;

      & > div {
        p {
          margin: 0;
          color: ${navyBlue};
          text-align: end;
        }

        span {
          font-family: ${rr};
          margin: 0 5px;
          color: ${gray};
        }

        &:first-of-type {
          display: grid;
          grid-template-columns: 30px auto;
          align-items: center;
          column-gap: 5px;
        }
        &:last-of-type {
          display: grid;
          grid-template-columns: auto 100px auto;
          align-items: center;
          column-gap: 5px;
        }
      }
    }
  }
`;

export const ErrorDeleteDiscount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  font-size: 20px;
`;

export const MonetaryRequirementsContainer = styled.div`
  max-width: 1300px;

  h3 {
    font-size: 24px;
    margin-bottom: 25px;
  }

  & > div:nth-of-type(3) {
    overflow: auto;
    max-height: 400px;
  }

  & > div:last-of-type {
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
`;

export const DocumentHint = styled.div`
  min-width: 250px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  bottom: 0;
  left: -85%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    p {
      width: fit-content;
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const ButtonSelected = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid #4285f4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  p {
    margin: 0;
  }

  &::after {
    content: '';
    width: ${({ active }) =>
      active === 'allActive' || active === 'almostAllActive'
        ? '65%'
        : active === 'allInActive'
          ? '0'
          : active === 'onlyOneActive' && '45%'};
    height: ${({ active }) =>
      active === 'allActive' || active === 'almostAllActive'
        ? '65%'
        : active === 'allInActive'
          ? '0'
          : active === 'onlyOneActive' && '45%'};
    border-radius: 2px;
    background-color: ${({ active }) =>
      active === 'allActive'
        ? '#4285f4'
        : active === 'allInActive'
          ? 'white'
          : lightGray};
  }
`;

export const ModalButtonSelected = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid #4285f4;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;

  p {
    margin: 0;
  }

  &::after {
    content: '';
    width: 65%;
    height: 65%;
    border-radius: 2px;
    background-color: ${({ isActive }) => isActive && '#4285f4'};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FontAwesomeIcon)`
  animation: ${spin} 1s linear infinite;
  font-size: 20px;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;
