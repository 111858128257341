import { PropertyFinancingWrite, PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE, PROPERTY_FINANCING_CLIENT_TYPE } from 'Application/types';

export type SetPropertyFinancingFormData = Partial<PropertyFinancingWrite>;

export enum SET_PROPERTY_FINANCING_DATA_ACTIONS {
  SET = 'SET_SET_PROPERTY_FINANCING_DATA',
  RESET = 'RESET_SET_PROPERTY_FINANCING_DATA'
}

export const initialState: PropertyFinancingWrite = {
  term: 1,
  amount: 0,
  clientType: PROPERTY_FINANCING_CLIENT_TYPE.NONE,
  clientIncomeConfirmationType: PROPERTY_FINANCING_CLIENT_INCOME_CONFIRMATION_TYPE.NONE,
  clientAverageMonthlyIncome: 0,
  client: "",
  inn: "",
  propertyFinancingDocuments: []
};

const setPropertyFinancingData = (
  state: PropertyFinancingWrite = initialState,
  action: {
    type: SET_PROPERTY_FINANCING_DATA_ACTIONS;
    data: SetPropertyFinancingFormData;
  }
): PropertyFinancingWrite => {
  switch (action.type) {
    case SET_PROPERTY_FINANCING_DATA_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_PROPERTY_FINANCING_DATA_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setPropertyFinancingData;
