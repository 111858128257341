import styled, { keyframes } from 'styled-components';
import { blue, gray, rt } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const MainConainer = styled.div`
  padding: 160px 90px 50px;
`;

export const HeadContainer = styled.div`
  width: 250px;
  margin-bottom: 30px;

  h2 {
    font-family: ${rt};
    line-height: 1.4em;
  }

  p {
    font-size: 16px;
    color: ${blue};
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 25%;
  margin-left: 50px;
`;

export const UsersContainer = styled.div`
  flex-grow: 1;
  margin-left: 500px;

  & > p {
    margin: 0 0 20px 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 15px;

      svg {
        font-size: 50px;
        filter: invert(0.9);
      }
    }
  }
`;

export const PersonInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  div {
    display: flex;
    align-items: center;
    column-gap: 5px;

    p:last-child {
      cursor: ${({ isAuth }) => (isAuth ? '' : 'pointer')};

      &:hover {
        &:after {
          content: ${({ isAuth }) =>
            isAuth
              ? ''
              : 'Зарегистрируйтесь, чтобы получить расширенный доступ'};
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #fff;
          border: 1px solid #ccc;
          padding: 5px;
          z-index: 1;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  & > p:first-child {
    color: ${({ isAuth }) => (isAuth ? blue : '')};
    text-decoration: ${({ isAuth }) => (isAuth ? 'underline' : '')};
    cursor: ${({ isAuth }) => (isAuth ? 'pointer' : '')};
  }
`;

export const SubmitButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;

  :disabled {
    background-color: ${gray};
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 5s infinite linear;
`;
