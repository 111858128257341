import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err, RequestData } from '../actions/getProductsByBankList';

import {
  GET_PRODUCTS_BY_BANK_LIST,
} from '../reducers/getProductsByBankList';
import ProductsApi from '../api';

function* getProductsByBankList(action: {
  type: GET_PRODUCTS_BY_BANK_LIST;
  data: RequestData;
}): Generator {
  try {
    let res;

    res = yield call(ProductsApi.getProductsByBank, action.data.bankInn);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getProductsByBankListSaga(): Generator {
  yield takeLatest(GET_PRODUCTS_BY_BANK_LIST.REQ, getProductsByBankList);
}
