import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'entities/SCF/Supplier/model/actions/getStartRateSupplierRole';
import { START_RATE_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getStartRateSupplierRole';

import SCFApi from 'entities/SCF/Supplier/api';

function* getStartRateSupplierRoleData(action: {
  type: START_RATE_SUPPLIER_ROLE;
}) {
  try {
    const res: any = yield call(SCFApi.getStartRateSupplierRole);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getStartRateSupplierRoleDataSaga() {
  yield takeLatest(START_RATE_SUPPLIER_ROLE.REQ, getStartRateSupplierRoleData);
}
