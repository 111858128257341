import { GET_SUPPLIER_AUCTION } from '../reducers/getSuppluerAuction';

interface SupplierItem {
  guid: string;
  createdDateTime: string;
  supplierStatus: number;
  supplierInn: string;
  supplierName: string;
}

export interface RequestData {
  supplierInn: string;
  supplier: string;
  status: 'ADDED' | 'DELETED';
}

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  items: SupplierItem[];
}

export const req = (guid: string, data: RequestData) => ({
  type: GET_SUPPLIER_AUCTION.REQ,
  guid,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_SUPPLIER_AUCTION.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_SUPPLIER_AUCTION.ERR,
  error
});

export const reset = () => ({
  type: GET_SUPPLIER_AUCTION.RESET
});
