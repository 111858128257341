import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface PatchPurchaseContractSupplierStoreState extends Reducer {}

export enum PATCH_PURCHASE_CONTRACT_SUPPLIER {
  REQ = 'PATCH_PURCHASE_CONTRACT_SUPPLIER_REQ',
  GOT = 'PATCH_PURCHASE_CONTRACT_SUPPLIER_GOT',
  ERR = 'PATCH_PURCHASE_CONTRACT_SUPPLIER_ERR'
}

export interface RequestDataType {
  paymentDeferment: number;
  paymentDefermentFixed: boolean;
}

export const initialState: PatchPurchaseContractSupplierStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const patchPurchaseContractSupplier = (
  state: PatchPurchaseContractSupplierStoreState = initialState,
  action: {
    type: PATCH_PURCHASE_CONTRACT_SUPPLIER;
    error: {};
  }
): PatchPurchaseContractSupplierStoreState => {
  switch (action.type) {
    case PATCH_PURCHASE_CONTRACT_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case PATCH_PURCHASE_CONTRACT_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case PATCH_PURCHASE_CONTRACT_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default patchPurchaseContractSupplier;
