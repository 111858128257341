import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Factor/model/actions/patchFactoringFeesOfLimits';

import {
  PATCH_FACTORING_FEES_OF_LIMITS,
  RequestDataType
} from 'entities/SCF/Factor/model/reducers/patchFactoringFeesOfLimits';

import SCFApi from 'entities/SCF/Factor/api';

function* patchFactoringFeesOfLimits(action: {
  type: PATCH_FACTORING_FEES_OF_LIMITS;
  data: RequestDataType;
  guid: string;
}) {
  try {
    yield call(SCFApi.patchFactoringFeesOfLimits, action.guid, action.data);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchFactoringFeesOfLimitsSaga() {
  yield takeLatest(
    PATCH_FACTORING_FEES_OF_LIMITS.REQ,
    patchFactoringFeesOfLimits
  );
}
