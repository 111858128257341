import styled from 'styled-components';
import {
  ProductColumnContainer,
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import { blue } from 'shared/styled';

export const ProductPolicyContainer = styled(ProductColumnContainer)`
  margin-top: 35px;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '6 - Может быть одобрено без залога и поручительств при большом убытке' &&
        product != 6 &&
        active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '6 - Может быть одобрено без залога и поручительств при большом убытке' &&
        product !== 6 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '5 - Может быть одобрено без залога и поручительств при небольшом убытке' &&
        product !== 5 &&
        active
          ? 'gray'
          : '#4FCBF2'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '5 - Может быть одобрено без залога и поручительств при небольшом убытке' &&
        product !== 5 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Да, достаточно поручительства действующего бизнеса' &&
        product !== 4 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Да, достаточно поручительства действующего бизнеса' &&
        product !== 4 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Да, достаточно залога' && product !== 3 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Да, достаточно залога' && product !== 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(5) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '2 - Да, с залогом и поручительством действующего бизнеса' &&
        product !== 2 &&
        active
          ? 'gray'
          : '#FFA552'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '2 - Да, с залогом и поручительством действующего бизнеса' &&
        product !== 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(6) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Строго нет' && product !== 1 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Строго нет' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(6) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product ===
        '6 - Может быть одобрено без залога и поручительств при большом убытке' ||
        product === 6) &&
      'white'};
  }

  & > div:nth-of-type(6) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Строго нет' || product === 1) && 'white'};
  }
`;

export const ProductPolicyNegativeContainer = styled(ProductColumnContainer)`
  margin: 35px 0;

  & > div:nth-of-type(1) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '6 - Может быть одобрено без залога и поручительств при большом отрицательном капитале' &&
        product !== 6 &&
        active
          ? 'gray'
          : blue};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '6 - Может быть одобрено без залога и поручительств при большом отрицательном капитале' &&
        product !== 6 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(2) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '5 - Может быть одобрено без залога и поручительств при небольшом отрицательном капитале' &&
        product !== 5 &&
        active
          ? 'gray'
          : '#4FCBF2'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '5 - Может быть одобрено без залога и поручительств при небольшом отрицательном капитале' &&
        product !== 5 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(3) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '4 - Да, достаточно поручительства действующего бизнеса' &&
        product !== 4 &&
        active
          ? 'gray'
          : '#6CDCD0'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '4 - Да, достаточно поручительства действующего бизнеса' &&
        product !== 4 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(4) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '3 - Да, достаточно залога' && product !== 3 && active
          ? 'gray'
          : '#F7CC64'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '3 - Да, достаточно залога' && product !== 3 && active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(5) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !==
          '2 - Да, с залогом и поручительством действующего бизнеса' &&
        product !== 2 &&
        active
          ? 'gray'
          : '#FFA552'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !==
          '2 - Да, с залогом и поручительством действующего бизнеса' &&
        product !== 2 &&
        active
          ? '0.5'
          : '1'};
    }
  }

  & > div:nth-of-type(6) {
    ${Square} {
      background-color: ${({ product, active }) =>
        product !== '1 - Строго нет' && product !== 1 && active
          ? 'gray'
          : '#ED446C'};
    }
    ${Text} {
      opacity: ${({ product, active }) =>
        product !== '1 - Строго нет' && product !== 1 && active ? '0.5' : '1'};
    }
  }

  & > div:nth-of-type(1) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(6) > ${ProductItem}:hover ${Text} {
    color: white;
  }

  & > div:nth-of-type(1) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product ===
        '6 - Может быть одобрено без залога и поручительств при большом отрицательном капитале' ||
        product === 6) &&
      'white'};
  }

  & > div:nth-of-type(6) > ${ProductItem} ${Text} {
    color: ${({ product }) =>
      (product === '1 - Строго нет' || product === 1) && 'white'};
  }
`;
