import ApiRequest from 'src/shared/utils/ApiRequest';

export default class BeneficiariesApi {
  static getBeneficiaries(inn: string) {
    return ApiRequest(`/api/beneficiaries_of_companies/company/${inn}`);
  }

  static getSubsidiaries(inn: string) {
    return ApiRequest(`/api/beneficiaries_of_companies/head_company/${inn}`);
  }

  static getManagersAndSignatories(inn: string) {
    return ApiRequest(`/api/persons_of_companies/company/${inn}`);
  }
}
