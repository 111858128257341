import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import { ResponseDataType } from 'src/features/User/reducers/getUserData';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { ProfileContainer, ProfileInfoWrapper } from './styled';
import { ForgotPasswordBtnStyled } from 'src/features/User/components/AuthenticationPage/ForgotPasswordBtn/styles';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { ResetPasswordModal } from './ResetPasswordModal/ResetPasswordModal';

interface StateToProps {
  user: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  openModal: (data: OpenModalDataType) => void;
}

type Props = StateToProps & DispatchToProps;

const Profile: React.FC<Props> = ({ user, status, error, openModal }) => {
  return (
    <ApplicationViewStyled>
      {status === REQUEST_STATUSES.GOT && (
        <ProfileContainer>
          <ApplicationTitle>
            {user.lastName} {user.firstName}
          </ApplicationTitle>

          <ProfileInfoWrapper>
            <div>
              <FontAwesomeIcon icon={faMobile} size="lg" />
              <p>{user.phoneNumber}</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
              <p>{user.email}</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faKey} size="lg" />
              <ForgotPasswordBtnStyled
                onClick={() => openModal({ name: MODAL_NAMES.FORGOT_PASSWORD })}
              >
                Сбросить пароль
              </ForgotPasswordBtnStyled>
            </div>
          </ProfileInfoWrapper>

          <ResetPasswordModal email={user.email} />
        </ProfileContainer>
      )}

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({ User }: CRM) => ({
  user: User.getUserData.data,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ openModal }, dispatch);

const ProfileConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

export { ProfileConnect as Profile };
