import styled from 'styled-components';

export const HeaderNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h2,
  p {
    margin: 0;
  }

  p {
    font-size: 2.4rem;
    font-weight: 300;
  }
`;

export const NameSeparator = styled.div`
  border-left: 2px solid black;
  height: 20px;
  margin: 0 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 160px;
  width: 50%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-left: 25px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    min-width: 80px;
    font-size: 1.4rem;
    font-weight: 300;
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  & > p {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }

  & > button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
  }
`;

export const ToggleButtonNo = styled.button`
  background-color: ${({ active }) =>
    active === false ? '#F53737' : 'transparent'};
  border: 2px solid
    ${({ active }) => (active === false ? '#F53737' : '#D9D9D9')};
  color: ${({ active }) => active === false && 'white'};
  border-radius: 10px 0 0 10px;
  :disabled {
    cursor: default;
  }
`;

export const ToggleButtonYes = styled.button`
  background-color: ${({ active }) => (active ? '#1CCA94' : 'transparent')};
  border: 2px solid ${({ active }) => (active ? '#1CCA94' : '#D9D9D9')};
  color: ${({ active }) => active && 'white'};
  border-radius: 0 10px 10px 0;
  :disabled {
    cursor: default;
  }
`;

export const Comment = styled.p`
  margin: 0 0 20px 0;
  max-width: 40%;
  word-wrap: break-word;
`;
