import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_SUPPLY_CONTRACT_SUPPLIER_SIGNATURE,
  err
} from '../actions/getSupplyContractSupplierSignatureFile';

function* getSupplyContractSupplierSignatureFile(action: {
  type: GET_SUPPLY_CONTRACT_SUPPLIER_SIGNATURE;
  guid: string;
}): Generator {
  try {
    const url = `/api/supply_contract/internal_role/${
      action.guid
    }/get_supplier_signature_file`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getSupplyContractSupplierSignatureFileSaga(): Generator {
  yield takeLatest(
    GET_SUPPLY_CONTRACT_SUPPLIER_SIGNATURE.REQ,
    getSupplyContractSupplierSignatureFile
  );
}
