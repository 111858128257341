import * as React from 'react';

import { ErrorIcon } from 'shared/styled';
import { WarningBlockStyled, WarningTextBlock, WarningText } from './styles';

interface Props {
  icon?: JSX.Element;
  text: Array<string | JSX.Element>;
}

export const WarningBlock: React.FC<Props> = ({
  icon = <ErrorIcon size={90} />,
  text
}) => (
  <WarningBlockStyled>
    {!!icon && icon}
    <WarningTextBlock>
      {text.map((item, key) => (
        <WarningText key={key}>{item}</WarningText>
      ))}
    </WarningTextBlock>
  </WarningBlockStyled>
);
