import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestDataType as RegisterRequestDataType } from 'Business/actions/businessRegister';

export default class BusinessApi {
  static register(data: RegisterRequestDataType, headers?: { apiKey: string }) {
    if (headers && headers.apiKey) {
      return ApiRequest('/api/account/business/register', 'post', data, {
        apiKey: headers.apiKey
      });
    } else {
      return ApiRequest('/api/account/business/register', 'post', data);
    }
  }

  static getStatus() {
    return ApiRequest('/api/business/status');
  }

  static getPersonsOfCompanies(inn: string) {
    return ApiRequest(`/api/persons_of_companies/open-user/company/${inn}`);
  }

  static getBeneficiariesOfCompanies(inn: string) {
    return ApiRequest(
      `/api/beneficiaries_of_companies/open-user/company/${inn}`
    );
  }

  static getSubsidiaries(inn: string) {
    return ApiRequest(
      `/api/beneficiaries_of_companies/open-user/head_company/${inn}`
    );
  }
}
