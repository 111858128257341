import { POST_TENDER_BY_CLIENT } from 'entities/Cabinet/Client/model/reducers/postTenderByClient';

export interface RequestData {
  customerinn: string;
  supplierinn: string;
  applicationId: number;
  PublicationDate?: string;
  EruzId?: string;
  StartPrice: number;
  SumFinal: number;
  TenderType?: number;
  LotCode: string;
  TenderSegment: number;
  ProtocolDate?: string;
  HasWinner?: boolean;
  ContractConcluded?: boolean;
  ContractGuid?: string;
  TenderCompleted?: boolean;
  TenderFailed?: boolean;
}

export const req = (data: RequestData) => ({
  type: POST_TENDER_BY_CLIENT.REQ,
  data
});

export const got = () => ({
  type: POST_TENDER_BY_CLIENT.GOT
});

export const err = (error: {}) => ({
  type: POST_TENDER_BY_CLIENT.ERR,
  error
});
