import styled from 'styled-components';

import { rr, gray } from 'shared/styled';

export const EquityRatioProportionStyled = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg'
})`
  margin: 15px 0;
  
  text {
    font-family: ${rr}
    font-size: 3.5rem;
    color: ${gray};
  }
`;
