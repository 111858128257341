import { combineReducers } from 'redux';

import toggleModal, { ModalStoreState } from './toggleModal';

export interface ModalReducers {
  toggleModal: ModalStoreState;
}

export const Modal = combineReducers({
  toggleModal
});
