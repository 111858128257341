import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { REQUEST_STATUSES, CRM, ResponseError } from 'src/globaltypes';
import { ScrollTopComponent } from 'src/features/Common';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { req as getManagersAndSignatories } from 'src/features/Beneficiaries/actions/getManagersAndSignatories';
import { CompanySectionTitle } from 'src/features/Companies/components/CompanyView/styled';
import { ManagersAndSignatoriesTypes } from '../types';
import { NoData } from './styled';

interface StateToProps {
  managersAndSignatories: ManagersAndSignatoriesTypes[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getManagersAndSignatories: (inn: string) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ManagersAndSignatories: React.FC<Props> = ({
  getManagersAndSignatories,
  managersAndSignatories,
  status,
  error,
  match
}) => {
  React.useEffect(
    () => {
      getManagersAndSignatories(match.params.inn);
    },
    [match.params.inn]
  );

  const navigateToPersons = (personInn: string) => {
    window.open(`/crm/persons/${personInn}`, '_blank');
  };

  return (
    <ScrollTopComponent>
      <div>
        <CompanySectionTitle>Руководители и подписанты:</CompanySectionTitle>

        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT &&
          (managersAndSignatories.length ? (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="10%">Инн</TableThStyled>
                  <TableThStyled width="15%">Имя</TableThStyled>
                  <TableThStyled width="20%">Должность</TableThStyled>
                  <TableThStyled width="20%">Тип позиции ЕГРЮЛ</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {managersAndSignatories.map((user, key) => (
                  <TableRowStyled
                    key={key}
                    onClick={() => navigateToPersons(user.personInn)}
                  >
                    <td>{user.personInn}</td>
                    <td>{`${user.lastName} ${user.firstName} ${
                      user.middleName
                    }`}</td>
                    <td>{`${user.position}, с ${new Date(
                      user.positionStartDate
                    ).toLocaleDateString()}`}</td>
                    <td>{user.positionType}</td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          ) : (
            <NoData>
              <h3>НЕТ ДАННЫХ</h3>
              <p>попробуйте обновить данные из ЕГРЮЛ</p>
            </NoData>
          ))}
      </div>

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Beneficiaries }: CRM) => ({
  managersAndSignatories: Beneficiaries.getManagersAndSignatories.data,
  status: Beneficiaries.getManagersAndSignatories.status,
  error: Beneficiaries.getManagersAndSignatories.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getManagersAndSignatories }, dispatch);

const ManagersAndSignatoriesConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ManagersAndSignatories)
);

export { ManagersAndSignatoriesConnect as ManagersAndSignatories };
