import ApiRequest from 'src/shared/utils/ApiRequest';

export default class BankProductApi {
  static getCreditProduct(inn: string) {
    return ApiRequest(`/api/credit_risk_products_of_companies/all`, 'get', {
      clientInn: inn
    });
  }

  static getPledge(clientInn: string) {
    return ApiRequest(`/api/pledges/all?clientInn=${clientInn}`);
  }

  static getBankAccounts(inn: string) {
    return ApiRequest(`/api/bank_account/company/${inn}`);
  }
}
