import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CRM, REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import {
  req as getApplications,
  ResponseData as ApplicationsData
} from 'src/features/ApplicationForCompanies/actions/getApplicationsCompany';
import {
  req as getLeads,
  ResponseData as LeadsListResponseData,
  RequestData as LeadsListRequestData
} from 'src/features/Leads/actions/getLeadsList';
import { ScrollTopComponent } from 'src/features/Common';
import { COMPANY_APPLICATION_TABS } from 'src/features/ApplicationForCompanies/types';
import { LeadsList } from 'src/features/ApplicationForCompanies/components/Tabs/Leads/Leads';
import { ApplicationsList } from 'src/features/ApplicationForCompanies/components/Tabs/Applications/Applications';
import { Menu } from 'src/features/Layouts/components';
import { LEAD_LIST_TYPE } from 'src/features/Leads/types';

interface StateToProps {
  applications: ApplicationsData;
  statusApplications: REQUEST_STATUSES;
  errorApplications: ResponseError;
  leads: LeadsListResponseData;
  statusLeads: REQUEST_STATUSES;
  errorLeads: ResponseError;
}

interface DispatchToProps {
  getLeads: (listType: LEAD_LIST_TYPE, data: LeadsListRequestData) => void;
  getApplications: (inn: number | string) => void;
}

interface MatchParams {
  inn: string;
  tab: string;
  applicationTab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const CompanyApplicationList: React.FC<Props> = ({
  getApplications,
  applications,
  statusApplications,
  errorApplications,
  getLeads,
  leads,
  statusLeads,
  errorLeads,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState('DEFAULT');

  React.useEffect(() => {
    initFetch();
  }, []);

  const initFetch = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const page = +searchParams.get('page') || 1;

    if (match.params.applicationTab) {
      setActiveTab(match.params.applicationTab.toUpperCase());
    } else if (match.params.tab === 'leads') {
      setActiveTab(match.params.tab.toUpperCase());
    }

    getLeads(LEAD_LIST_TYPE.ALL, {
      page,
      pageSize: 20,
      companyInn: match.params.inn
    });
    getApplications(match.params.inn);
  };

  const handleActiveTab = (tabName: string) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace(newPathname);
    setActiveTab(tabName);
  };

  return (
    <ScrollTopComponent>
      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={COMPANY_APPLICATION_TABS}
        isSubMenu
      />

      {activeTab === 'DEFAULT' && (
        <ApplicationsList
          applications={applications}
          status={statusApplications}
          error={errorApplications}
        />
      )}

      {activeTab === 'LEADS' && (
        <LeadsList leads={leads} status={statusLeads} error={errorLeads} />
      )}
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ LeadsOfCompanies, Leads }: CRM & STORE) => ({
  applications: LeadsOfCompanies.getApplicationsCompany.data,
  statusApplications: LeadsOfCompanies.getApplicationsCompany.status,
  errorApplications: LeadsOfCompanies.getApplicationsCompany.error,
  leads: Leads.getLeadsList.data,
  statusLeads: Leads.getLeadsList.status,
  errorLeads: Leads.getLeadsList.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getApplications, getLeads }, dispatch);

const CompanyApplicationListConnect = withRouter<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyApplicationList)
);

export { CompanyApplicationListConnect as CompanyApplicationList };
