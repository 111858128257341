import * as React from 'react';
import { connect } from 'react-redux';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import {
  ApplicationTypes,
  FinancingCreditType,
  FinancingPaymentConfirmation
} from 'shared/constants';
import { FinancingApplicationRead } from 'Application/types';
import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';

import {
  ApplicationViewConsumer,
  EachApplicationProps,
  EachApplicationView
} from 'Application/components';

import { formula } from 'Application/components/ApplicationForm/ApplicationProcess/FinancingForm/FinancingFormStep1/formula';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { Loader } from 'src/features/Layouts/components';

interface StateToProps {
  inBankApplication: GetApplicationResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
}

type Props = StateToProps;

const FinancingView: React.FC<Props> = ({
  inBankApplication,
  statusInBankApplication
}) => (
  <ApplicationViewConsumer>
    {({ data }: EachApplicationProps<FinancingApplicationRead>) => {
      const {
        investmentCredit,
        mezzanineCredit,
        investmentPhaseAmount,
        projectInvestments
      } = formula({
        amount: data.amount,
        equity: data.equity,
        termInvestmentPhase: data.termInvestmentPhase,
        isAgriculture: data.isAgriculture,
        paymentConfirmationInvestmentPhase:
          data.paymentConfirmationInvestmentPhase
      });

      if (statusInBankApplication !== REQUEST_STATUSES.GOT) {
        return <Loader />;
      }

      return (
        <EachApplicationView
          mainInfo={[
            {
              name: 'Тип финансирования',
              value: ApplicationTypes[data.financingType]
            },
            {
              name: 'Бюджет проекта',
              value: formSumString(data.amount)
            },
            {
              name: 'Срок',
              value: formTermString(data.term)
            },
            {
              name: 'ИНН',
              value: data.code
            },
            {
              name: 'Рефинансирование',
              value: data.refinancing ? 'Да' : 'Нет'
            }
          ]}
          subInfo={[
            {
              name: 'Тип кредита',
              value: FinancingCreditType[data.creditType]
            },
            {
              name: 'По программе министерства сельского хозяйства',
              value: data.isAgriculture ? 'Да' : 'Нет'
            },
            {
              name: 'Собственные средства',
              value: formSumString(data.equity)
            },
            {
              name: 'Срок инвестиционной фазы',
              value: formTermString(data.termInvestmentPhase)
            },
            {
              name: 'Инвестиционный кредит',
              value: formSumString(Math.round(investmentCredit))
            },
            ...(mezzanineCredit != 0
              ? [
                  {
                    name: 'Мезонинный кредит',
                    value: formSumString(Math.round(mezzanineCredit))
                  }
                ]
              : []),
            {
              name: 'Сумма процентов на инвестиционной фазе',
              value: formSumString(Math.round(investmentPhaseAmount))
            },
            {
              name: 'Форма подтверждения оплаты процентов на инвест. фазе',
              value:
                FinancingPaymentConfirmation[
                  data.paymentConfirmationInvestmentPhase
                ]
            },
            {
              name: 'Собственные вложения для реализации проекта',
              value: formSumString(Math.round(projectInvestments))
            }
          ]}
          documents={{
            general: [
              ...inBankApplication.agreementDocuments,
              ...inBankApplication.groupDocuments
            ],
            company: data.borrowerCompanies
          }}
        />
      );
    }}
  </ApplicationViewConsumer>
);

const mapStateToProps = ({ Application }: STORE) => ({
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status
});

const FinancingViewConnect = connect<StateToProps>(mapStateToProps)(
  FinancingView
);

export { FinancingViewConnect as FinancingView };
