import * as React from 'react';

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { PageStyled, PageHeaderContainer } from 'src/features/SCF/UI/ScfStyles';
import { STORE } from 'globaltypes';

interface StateToProps {
  // status: REQUEST_STATUSES;
  // error: ResponseError;
  // factoringLimit: ResponseDataType;
}

interface DispatchToProps {
  // getFactoringLimit: (data: RequestDataType) => void;
}

interface State {}

type Props = StateToProps & DispatchToProps;

class KnowYourCustomerPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {};
    }
  }

  render() {
    return (
      <PageStyled>
        <PageHeaderContainer>
          <h2>KYC - Know Your Customer</h2>
        </PageHeaderContainer>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  // factoringLimit: SCF.getFactoringLimitByDebtorInn.data,
  // status: SCF.getFactoringLimitByDebtorInn.status,
  // error: SCF.getFactoringLimitByDebtorInn.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // getFactoringLimit,
    },
    dispatch
  );

const KnowYourCustomerPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(KnowYourCustomerPage);

export { KnowYourCustomerPageConnect as KnowYourCustomerPage };
