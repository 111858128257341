import ApiRequest from 'src/shared/utils/ApiRequest';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';

import { SetInitializationApplicationType } from 'Application/reducers/setInitializationFormData';
import {
  RequestDataType as GetApplicationRequestDataType,
  DocumentsRequestDataType as GetApplicationDocumentsRequestDataType
} from 'Application/reducers/getApplication';
import { RequestDataType as ChangeApplicationStatusStoreState } from 'Application/reducers/changeApplicationStatus';
import { RequestDataType as DeleteApplicationRequestDataType } from 'Application/reducers/deleteApplication';
import { RequestDataType as GetApplicationsListRequestDataType } from 'Application/actions/getApplicationsList';
import { RequestDataType as SendApplicationRequestDataType } from 'Application/reducers/sendApplicationData';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import { RequestDataType as UploadDocumentsRequestDataType } from 'Application/reducers/uploadDocuments';
import { RequestDataType as SendApplicationToBanksRequestDataType } from 'Application/reducers/sendApplicationToBanks';
import { RequestData as ReqPostApplicationPrescoring } from 'Application/actions/postApplicationPrescoring';
import { RequestData as ReqPostCreateTender } from 'Application/actions/postCreateTender';
import { APPLICATION_TYPES, Document } from 'Application/types';

export default class ApplicationApi {
  static getDownloadBorrowerCompanyDocumentsLink(id: number): string {
    return `/api/documents/borrowercompany/all/${id}`;
  }
  static getDownloadApplicationDocumentsLink(id: number): string {
    return `/api/documents/application/all/${id}`;
  }

  static getActivitiesByLead(applicationId: string) {
    return ApiRequest(`/api/activity/all?application=${applicationId}`);
  }

  static makeApplicationDraft(data: SetInitializationApplicationType) {
    return ApiRequest('/api/application/create/draft', 'post', data);
  }

  static createApplication(financingType?: APPLICATION_TYPES) {
    return ApiRequest(
      `/api/application/create/empty?financingType=${financingType}`
    );
  }

  static deleteApplication(data: DeleteApplicationRequestDataType) {
    return ApiRequest(`/api/application/${data.id}`, 'delete');
  }

  static changeApplicationStatus(data: ChangeApplicationStatusStoreState) {
    return ApiRequest(
      `/api/application/${data.id}/statuses/${data.status}`,
      'put'
    );
  }

  static getApplication(data: GetApplicationRequestDataType) {
    return ApiRequest(`/api/application/${data.id}`);
  }

  static updateInitializationStepData(data: SetInitializationApplicationType) {
    return ApiRequest('/api/application/create/draft', 'patch', data);
  }

  static sendApplicationData(data: SendApplicationRequestDataType) {
    return ApiRequest('/api/application/sendData', 'post', data);
  }

  static getGeneralApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/general', 'get', data);
  }

  static getAllInWorkApplicationsList(
    data: GetApplicationsListRequestDataType
  ) {
    return ApiRequest('/api/application/allinwork', 'get', data);
  }

  static getAllDealsApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/alldeals', 'get', data);
  }

  static getAllTrashedApplicationsList(
    data: GetApplicationsListRequestDataType
  ) {
    return ApiRequest('/api/application/alltrashed', 'get', data);
  }

  static getDealApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/deals', 'get', data);
  }

  static getWorkApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/work', 'get', data);
  }

  static getTrashedApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/trashed', 'get', data);
  }

  static getIntegrationApplicationsList(
    data: GetApplicationsListRequestDataType
  ) {
    return ApiRequest(`/api/partner_role/applications/all`, 'get', data);
  }

  static getMyApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/my', 'get', data);
  }

  static getDraftApplicationsList(data: GetApplicationsListRequestDataType) {
    return ApiRequest('/api/application/drafts', 'get', data);
  }

  static getApplicationsQuantity() {
    return ApiRequest('/api/application/count');
  }

  // Documents api
  static deleteDocument(data: DeleteDocumentRequestDataType) {
    return ApiRequest(`/api/documents/${data.id}/${data.type}`, 'delete');
  }

  static getDownloadDocumentLink(data: Document) {
    return `/api/documents/${data.id}/${data.type}`;
  }

  static getDocumentsList(data: GetApplicationDocumentsRequestDataType) {
    return ApiRequest(`/api/documents/application/${data.id}/${data.type}`);
  }

  // TODO: fix - ApiUploadFiles -> ApiRequest
  static uploadDocuments(data: UploadDocumentsRequestDataType) {
    return ApiUploadFiles(
      `/api/documents/application/${data.id}/${data.type}?metaInfo=${
        data.metaInfo
      }`,

      data.file
    );
  }

  // Banks api
  static getBanksList() {
    return ApiRequest('/api/banks');
  }

  static getAllBanksList() {
    return ApiRequest('/api/banks/all');
  }

  static sendApplicationToBanks(data: SendApplicationToBanksRequestDataType) {
    return ApiRequest(
      `/api/application/sendToBank/${data.id}`,
      'post',
      data.body
    );
  }

  static toggleExternalVisibility(appId: number) {
    return ApiRequest(
      `/api/application/toggleExternalVisibility/${appId}`,
      'patch'
    );
  }
  static getApplicationFlags() {
    return ApiRequest('/api/application/flags');
  }

  static getCompanyHints(inn: string) {
    return ApiRequest(`/api/company/inn/${inn}/hints`);
  }

  static getProductApplication(id: string) {
    return ApiRequest(`/api/products_of_applications/application/${id}`);
  }

  static postSelectApplication(data: any) {
    return ApiRequest(`/api/product/select`, 'post', data);
  }

  static putSentToBank(id: number) {
    return ApiRequest(
      `/api/products_of_applications/${id}/send_to_bank`,
      'put'
    );
  }

  static putSetPreapproved(id: number) {
    return ApiRequest(
      `/api/products_of_applications/${id}/set_preapproved`,
      'put'
    );
  }

  static putSetApproved(id: number) {
    return ApiRequest(
      `/api/products_of_applications/${id}/set_approved`,
      'put'
    );
  }

  static putRejected(id: number, { rejectSubject, rejectReason }) {
    return ApiRequest(`/api/products_of_applications/${id}/rejected`, 'put', {
      rejectSubject,
      rejectReason
    });
  }

  static postExternalApiAlfaBank(ApplicationID: number) {
    return ApiRequest(`/api/external-api/1-alfa-bank-mmb`, 'post', {
      ApplicationID
    });
  }

  static postExternalApiPSB(ApplicationID: number) {
    return ApiRequest(`/api/external-api/2-psb-loans`, 'post', {
      ApplicationID
    });
  }

  static getFinancialsSummary(inn: string) {
    return ApiRequest(
      `/api/financial_statements_of_companies/summary/inn/${inn}`
    );
  }

  static getExternalApiGIR_BO(inn: string, year: number) {
    return ApiRequest(
      `/api/external-api/3-gir-bo/${inn}?WriteToDb=true&year=${year}`
    );
  }

  static getOpenLoanLimits(inn: string) {
    return ApiRequest(`/api/loan_limit/all?companyinn=${inn}`);
  }

  static patchUpdateCompanyBlank(inn: string, data: any) {
    return ApiRequest(`/api/company/inn/${inn}`, 'patch', data);
  }

  static getTenderTeledoc(id: string, eis: string) {
    return ApiRequest(`/api/tender/partner_api/${id}/eis/${eis}`);
  }

  static getInternalApplication(id: string) {
    return ApiRequest(`/api/application/internal_role/${id}`);
  }

  static getAddTenderByEruz(id: string, eruzId: string) {
    return ApiRequest(
      `/api/application/internal_role/${id}/add_tender_by_eruz/${eruzId}`,
      'post'
    );
  }

  static getTenderByApplication(id: string) {
    return ApiRequest(
      `/api/tenders_of_application/internal_role/application/${id}`
    );
  }

  static getAllOffers(id: string) {
    return ApiRequest(`/api/offer/internal_role/all?application=${id}`);
  }

  static postApplicationPrescoring(data: ReqPostApplicationPrescoring) {
    return ApiRequest(
      `/api/application/internal_role/partner_api/7/prescoring`,
      'post',
      data
    );
  }

  static postOfferSend(id: number) {
    return ApiRequest(`/api/offer/internal_role/${id}/send_by_api_7`, 'post');
  }

  static postCreateTender(data: ReqPostCreateTender) {
    return ApiRequest(`/api/tender/new`, 'post', data);
  }

  static postAddTenderById(id: string, tenderId: number) {
    return ApiRequest(
      `/api/application/internal_role/${id}/add_tender_by_id/${tenderId}`,
      'post'
    );
  }

  static postDocumentsOfDocumentLists(documentListGuid: string) {
    return ApiRequest(
      `/api/documents_of_documents_lists/internal_role/partner_api/7/upload_document`,
      'post',
      { documentListGuid }
    );
  }
}
