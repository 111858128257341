import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { formSumString, formTermString } from 'src/shared/utils/Utils';
import { ApplicationTypes, DebtorsType, PledgeType } from 'shared/constants';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';

import {
  req as getInBankApplication,
  reset as resetInBankApplication
} from 'Application/actions/getInBankApplication';
import { ResponseDataType } from 'Application/reducers/getInBankApplication';

import {
  req as getInternalApplication,
  ResponseData
} from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import {
  req as getFinancialsSummary,
  reset as resetFinancialsSummary
} from 'Application/actions/getFinancialsSummary';
import { RequestDataType as GetApplicationRequestDataType } from 'Application/reducers/getApplication';
import { req as getCompanyHints } from 'Application/actions/getCompanyHints';

import { EachApplicationView } from 'pages/Cabinet/InternalAgent/Pages/ApplicationView/FactoringView/EachApplicationView';
import { Loader } from 'src/features/Layouts/components';

import { DebtorBlock, DebtorsContainer } from './styled';

interface StateToProps {
  internalApplication: ResponseData;
  status: REQUEST_STATUSES;
  inBankApplication: ResponseDataType;
  statusInBankApplication: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  statusChangeApplicationStatus: REQUEST_STATUSES;
  statusPostSendBank: REQUEST_STATUSES;
}

interface DispatchToProps {
  getInternalApplication: (id: string) => void;
  getInBankApplication: (data: GetApplicationRequestDataType) => void;
  resetInBankApplication: () => void;
  getFinancialsSummary: (inn: string) => void;
  resetFinancialsSummary: () => void;
  getCompanyHints: (inn: string) => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const FactoringView: React.FC<Props> = ({
  getInternalApplication,
  internalApplication,
  status,
  getInBankApplication,
  statusInBankApplication,
  inBankApplication,
  getCompanyHints,
  getFinancialsSummary,
  permissions,
  resetFinancialsSummary,
  resetInBankApplication,
  statusChangeApplicationStatus,
  statusPostSendBank,
  match
}) => {
  React.useEffect(
    () => {
      const { id, inBankId } = match.params;

      if (id && !inBankId) {
        getInternalApplication(id);
        getInBankApplication({ id: +id });
      } else if (inBankId) {
        getInternalApplication(inBankId);
        getInBankApplication({ id: +inBankId });
      }

      return () => {
        resetInBankApplication();
        resetFinancialsSummary();
      };
    },
    [permissions, match.params.id, match.params.inBankId]
  );

  React.useEffect(
    () => {
      if (statusChangeApplicationStatus === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
          getInBankApplication({ id: +id });
        } else if (inBankId) {
          getInternalApplication(inBankId);
          getInBankApplication({ id: +inBankId });
        }
      }
    },
    [statusChangeApplicationStatus]
  );

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT) {
        if (
          !permissions.includes(USER_PERMISSIONS.CLIENT) &&
          !permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) &&
          !permissions.includes(USER_PERMISSIONS.BANK_MANAGER)
        ) {
          getCompanyHints(internalApplication.code);
          getFinancialsSummary(internalApplication.code);
        }
      }
    },
    [status]
  );

  React.useEffect(
    () => {
      if (statusPostSendBank === REQUEST_STATUSES.GOT) {
        const { id, inBankId } = match.params;

        if (id && !inBankId) {
          getInternalApplication(id);
        } else if (inBankId) {
          getInternalApplication(inBankId);
        }
      }
    },
    [statusPostSendBank]
  );

  if (
    statusInBankApplication !== REQUEST_STATUSES.GOT ||
    status !== REQUEST_STATUSES.GOT
  ) {
    return <Loader />;
  }

  return (
    <EachApplicationView
      mainInfo={[
        {
          name: 'Тип финансирования',
          value: ApplicationTypes[internalApplication.financingType]
        },
        {
          name: 'Сумма сделки',
          value: formSumString(internalApplication.amount)
        },
        {
          name: 'Срок',
          value: formTermString(internalApplication.term)
        },
        {
          name: 'ИНН',
          value: internalApplication.code
        },
        {
          name: 'Рефинансирование',
          value: internalApplication.refinancing ? 'Да' : 'Нет'
        }
      ]}
      subInfo={[
        {
          name: 'Тип дебиторов',
          value: DebtorsType[internalApplication.debtorType]
        }
      ]}
      documents={{
        general: [
          ...(inBankApplication.agreementDocuments || []),
          ...(inBankApplication.groupDocuments || []),
          ...(inBankApplication.documents || [])
        ],
        company: inBankApplication.borrowerCompanies || []
      }}
    >
      {internalApplication.debtors &&
        internalApplication.debtors.length > 0 && (
          <DebtorsContainer>
            {internalApplication.debtors.map(debtor => (
              <DebtorBlock key={debtor.id}>
                <p>
                  Дебитор: <span>{debtor.name}</span>
                </p>
                <p>
                  Лимит на дебитора: <span>{formSumString(debtor.limit)}</span>
                </p>
                <p>
                  Отсрочка по договору:{' '}
                  <span>{formTermString(debtor.postponement)}</span>
                </p>
                <p>
                  Наличие залога: <span>{PledgeType[debtor.pledgeType]}</span>
                </p>
              </DebtorBlock>
            ))}
          </DebtorsContainer>
        )}
    </EachApplicationView>
  );
};

const mapStateToProps = ({ Application, InternalAgentRole, User }: STORE) => ({
  internalApplication: InternalAgentRole.getInternalApplication.data,
  status: InternalAgentRole.getInternalApplication.status,
  inBankApplication: Application.getInBankApplication.data,
  statusInBankApplication: Application.getInBankApplication.status,
  permissions: User.getUserData.data.permissions,
  statusChangeApplicationStatus: Application.changeApplicationStatus.status,
  statusPostSendBank: Application.sendApplicationToBanks.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInternalApplication,
      getInBankApplication,
      resetInBankApplication,
      getFinancialsSummary,
      resetFinancialsSummary,
      getCompanyHints
    },
    dispatch
  );

const FactoringViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FactoringView)
);

export { FactoringViewConnect as FactoringView };
