import { GET_ACTIVITIES_COMPANIES } from 'src/features/ActivitiesOfCompanies/reducers/getActivities';
import { Activities } from 'src/features/Activities/types/index';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: Activities[];
}

export const req = (companyInn: string) => ({
  type: GET_ACTIVITIES_COMPANIES.REQ,
  companyInn
});

export const got = (data: ResponseData) => ({
  type: GET_ACTIVITIES_COMPANIES.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_ACTIVITIES_COMPANIES.ERR,
  error
});
