import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getExternalAgentsList';

export interface GetExternalAgentsListStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_EXTERNAL_AGENTS_LIST {
  GET_EXTERNAL_AGENTS_LIST_REQ = 'GET_EXTERNAL_AGENTS_LIST_REQ',
  GET_EXTERNAL_AGENTS_LIST_GOT = 'GET_EXTERNAL_AGENTS_LIST_GOT',
  GET_EXTERNAL_AGENTS_LIST_ERR = 'GET_EXTERNAL_AGENTS_LIST_ERR'
}

export const initialState: GetExternalAgentsListStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getExternalAgentsList = (
  state: GetExternalAgentsListStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetExternalAgentsListStoreState => {
  switch (action.type) {
    case GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_EXTERNAL_AGENTS_LIST.GET_EXTERNAL_AGENTS_LIST_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getExternalAgentsList;
