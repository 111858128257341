import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/createActivities';

import { CREATE_ACTIVITIES } from '../reducers/createActivities';
import ActivitiesApi from '../api';

function* createContact(action: {
  type: CREATE_ACTIVITIES;
  data: any;
}): Generator {
  try {
    let res: any;

    res = yield call(ActivitiesApi.createActivities, action.data);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* createActivitiesSaga(): Generator {
  yield takeLatest(CREATE_ACTIVITIES.REQ, createContact);
}
