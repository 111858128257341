import { GET_FACTOR_REGISTRY_FILES } from 'entities/SCF/Factor/model/reducers/getFactorRegistryFiles';

export interface RegistryFile {
  id: string;
  debtorInn: number;
  debtorCompanyName: string;
  registryFilename: string;
  signatureFilename: string;
  createdDateTime: Date;
  debtorSignatureName: string;
  debtorSignaturePosition: string;
  isNew: boolean;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  totalItems: number;
  items: RegistryFile[];
}

export interface RequestDataType {
  page: number;
  pageSize: number;
  dateFrom?: string;
  dateUntil?: string;
  debtorInn?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_FACTOR_REGISTRY_FILES.GET_FACTOR_REGISTRY_FILES_ERR,
  error
});
