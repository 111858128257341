import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Loader, Pagination } from 'Layouts/components';
import { Icon } from 'shared/ui/Icon';
import {
  req as getPurchaseContracts,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getPurchaseContracts';
import { req as getContractFile } from 'src/features/SCF/actions/getContractFile';
import { req as getSignatureFile } from 'entities/SCF/Supplier/model/actions/getSignatureFile';
import { req as getDebtorSignatureFile } from 'entities/SCF/Debtor/model/actions/getDebtorSignatureFile';
import {
  ContractsPageStyled,
  CreateBtn,
  CreateBtnText,
  InnFilterArea,
  InnFilter,
  InnContainer,
  Status,
  DownloadContractContainer,
  TooltipBoxStyle,
  TDDocument,
  TableTRStyled,
  Postponement,
  PostponementItem,
  TextRed,
  PaymentDeferment,
  FormAgreement,
  StatusContainer,
  SupplierInfo
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import 'src/features/SCF/UI/simpleCSS/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import SignatureImg from 'src/assets/icons/signature.svg';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { Button } from 'shared/ui/Button';
import { lightGray, red } from 'shared/styled';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  purchaseContracts: ResponseDataType;
}

interface DispatchToProps {
  getPurchaseContracts: (data: RequestDataType) => void;
  getContractFile: (guid: string) => void;
  getSignatureFile: (guid: string) => void;
  getDebtorSignatureFile: (guid: string) => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

const ContractsList: React.FC<Props> = ({
  getPurchaseContracts,
  purchaseContracts,
  status,
  getContractFile,
  getDebtorSignatureFile,
  getSignatureFile,
  history
}) => {
  const [supplierInnToFilter, setSupplierInnToFilter] = React.useState('');

  React.useEffect(
    () => {
      const urlParams = new URLSearchParams(history.location.search);
      const page = parseInt(urlParams.get('page'), 10);

      getPurchaseContracts({
        page,
        pageSize: 10,
        ...(supplierInnToFilter && {
          SuplierInnToFilterBy: supplierInnToFilter
        })
      });
    },
    [supplierInnToFilter]
  );

  const handleSupplierInnToFilterChange = event => {
    setSupplierInnToFilter(event.target.value);
  };

  const createContractsClick = () => {
    history.push('/debtor/purchase_contracts/add');
  };

  const onRawClick = (guid: string) => {
    history.push(`/debtor/purchase_contracts/${guid}`);
  };

  return (
    <ContractsPageStyled>
      <CreateLimitsNudge>
        <h2>Договоры поставки</h2>
        <CreateBtn onClick={createContractsClick}>
          <CreateBtnText>+ Создать</CreateBtnText>
        </CreateBtn>
      </CreateLimitsNudge>
      <InnFilterArea>
        <InnFilter>
          <p>ИНН Поставщика</p>
          <InnContainer>
            <input
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={handleSupplierInnToFilterChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>
      </InnFilterArea>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="12%">Поставщик</TableThStyled>
              <TableThStyled width="10%">Реквизиты договора</TableThStyled>
              <TableThStyled width="15%" style={{ textAlign: 'center' }}>
                Отсрочка платежа, дней
              </TableThStyled>
              <TableThStyled width="25%" style={{ textAlign: 'center' }}>
                Статус договора
              </TableThStyled>
              <TableThStyled width="10%" style={{ paddingLeft: '20px' }}>
                Документы
              </TableThStyled>
              <TableThStyled width="15%" style={{ textAlign: 'center' }}>
                Форма соглашения о дисконте
              </TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {purchaseContracts.items.map((item, key) => (
              <TableTRStyled
                key={key}
                onClick={() => onRawClick(item.id)}
                decline={
                  !!item.contraceFileName &&
                  !!item.supplierSignatureFileName &&
                  item.debtorSignatureFileName === null &&
                  item.confirmedByDebtor === false
                }
              >
                <td>
                  <SupplierInfo>
                    <div>{item.supplierInn}</div>
                    <div>{item.supplierName}</div>
                  </SupplierInfo>
                </td>
                <td>
                  № {item.contractNumber} от{' '}
                  {format(new Date(item.contractStartDate), 'dd.MM.yyyy')}
                </td>
                <td>
                  <Postponement>
                    {item.paymentDeferment > 0 &&
                    item.paymentDefermentFixed === true ? (
                      <PostponementItem>
                        <div>{item.paymentDeferment}</div>
                        <PaymentDeferment>фиксированная</PaymentDeferment>
                      </PostponementItem>
                    ) : item.paymentDefermentFixed === false ? (
                      <PostponementItem>
                        <div>Плавающая</div>
                      </PostponementItem>
                    ) : item.paymentDefermentFixed === null ||
                    (item.paymentDefermentFixed === true &&
                      item.paymentDeferment === null) ? (
                      <PostponementItem>
                        <Button label="не заполнено" />
                      </PostponementItem>
                    ) : (
                      item.paymentDefermentFixed === true &&
                      item.paymentDeferment <= 0 && (
                        <PostponementItem>
                          <div>{item.paymentDeferment}</div>
                          <TextRed>
                            может быть <br /> некорректно
                          </TextRed>
                        </PostponementItem>
                      )
                    )}
                  </Postponement>
                </td>
                <td>
                  <StatusContainer>
                    <Status
                      status={
                        !!item.contraceFileName
                          ? 'rgba(213, 232, 253, 1)'
                          : lightGray
                      }
                    >
                      {!!item.contraceFileName ? 'Загружен' : 'Не загружен'}
                    </Status>
                    <Status
                      status={
                        !!item.supplierSignatureFileName
                          ? 'rgba(213, 232, 253, 1)'
                          : lightGray
                      }
                    >
                      {!!item.supplierSignatureFileName
                        ? 'Подписан поставщиком'
                        : 'Не подписан поставщиком'}
                    </Status>
                    <Status
                      status={
                        item.confirmedByDebtor === null &&
                        item.debtorSignatureIdentifier === null
                          ? red
                          : item.confirmedByDebtor === false
                            ? 'rgba(253, 241, 224, 1)'
                            : item.confirmedByDebtor === true &&
                              item.debtorSignatureIdentifier === null &&
                              'rgba(213, 232, 253, 1)'
                      }
                    >
                      {item.confirmedByDebtor === null &&
                      item.debtorSignatureIdentifier === null
                        ? 'Ожидает вашего подтверждения'
                        : item.confirmedByDebtor === true &&
                          item.debtorSignatureIdentifier === null
                          ? 'Подтвержден без подписи'
                          : !!item.debtorSignatureIdentifier
                            ? 'Подписан вами'
                            : item.confirmedByDebtor === false &&
                              'Отклонен вами'}
                    </Status>
                  </StatusContainer>
                </td>
                <TDDocument>
                  <DownloadContractContainer>
                    {item.contraceFileName && (
                      <a
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          getContractFile(item.id);
                        }}
                      >
                        Скачать контракт
                      </a>
                    )}

                    {item.supplierSignatureFileName && (
                      <TooltipBoxStyle textVerify="Скачать подпись поставщика">
                        <Icon
                          iconLink={SignatureImg}
                          width={20}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            getSignatureFile(item.id);
                          }}
                        />
                      </TooltipBoxStyle>
                    )}

                    {item.debtorSignatureFileName && (
                      <TooltipBoxStyle textVerify="Скачать мою подпись">
                        <Icon
                          iconLink={SignatureImg}
                          width={20}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            getDebtorSignatureFile(item.id);
                          }}
                        />
                      </TooltipBoxStyle>
                    )}
                  </DownloadContractContainer>
                </TDDocument>
                <td>
                  <FormAgreement>
                    {!item.debtorAgreementForm &&
                    item.confirmedByDebtor !== false ? (
                      <Status status={red}>Ожидает вашего выбора</Status>
                    ) : (
                      !!item.debtorAgreementForm && <p>Форма выбрана</p>
                    )}
                  </FormAgreement>
                </td>
              </TableTRStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={purchaseContracts} />
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  purchaseContracts: SCF.getPurchaseContracts.data,
  status: SCF.getPurchaseContracts.status,
  error: SCF.getPurchaseContracts.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPurchaseContracts,
      getContractFile,
      getSignatureFile,
      getDebtorSignatureFile
    },
    dispatch
  );

const ContractsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ContractsList)
);

export { ContractsListConnect as ContractsList };
