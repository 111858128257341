import postFactoringConnectionFactorRoleSaga from 'entities/SCF/Factor/model/sagas/postFactoringConnectionFactorRole';
import getFactoringConnectionsFactorRoleSaga from 'entities/SCF/Factor/model/sagas/getFactoringConnectionsFactorRole';
import getFactorRegistryFilesSaga from 'entities/SCF/Factor/model/sagas/getFactorRegistryFiles';
import getFactoringContractsFactorRoleSaga from 'entities/SCF/Factor/model/sagas/getFactoringContractsFactorRole';
import getFactoringContractFactorRoleByGuidSaga from 'entities/SCF/Factor/model/sagas/getFactoringContractFactorRoleByGuid';
import postAgreementSignatureFactorRoleSaga from 'entities/SCF/Factor/model/sagas/postAgreementSignatureFactorRole';
import getMonetaryClaimsFactorEarlyPaymentApplicationsByGuidSaga from 'entities/SCF/Factor/model/sagas/getMonetaryClaimsFactorEarlyPaymentApplicationsByGuid';
import getEarlyPaymentApplicationsFactorRoleSaga from 'entities/SCF/Factor/model/sagas/getEarlyPaymentApplicationsFactorRole';
import putEarlyPaymentApplicationDeclineFactorRoleSaga from 'entities/SCF/Factor/model/sagas/putEarlyPaymentApplicationDeclineFactorRole';
import getOneFactorRoleByGuidSaga from 'entities/SCF/Factor/model/sagas/getOneFactorRoleByGuid';
import getFactoringFeesOfLimitsSaga from 'entities/SCF/Factor/model/sagas/getFactoringFeesOfLimits';
import postFactoringFeesOfLimitsSaga from 'entities/SCF/Factor/model/sagas/postFactoringFeesOfLimits';
import SCFRegisterFactorSaga from 'entities/SCF/Factor/model/sagas/SCFRegisterFactor';
import getStartRateFactorRoleSaga from 'entities/SCF/Factor/model/sagas/getStartRateFactorRole';
import getFactoringConnectionsByIdSaga from 'entities/SCF/Factor/model/sagas/getFactoringConnectionsById';
import deleteFactoringFeesOfLimitsSaga from 'entities/SCF/Factor/model/sagas/deleteFactoringFeesOfLimits';
import patchFactoringFeesOfLimitsSaga from 'entities/SCF/Factor/model/sagas/patchFactoringFeesOfLimits';
import getCurrentFactoringSettingsByDebtorInnSaga from 'entities/SCF/Factor/model/sagas/getCurrentFactoringSettingsByDebtorInn';

export default [
  SCFRegisterFactorSaga,
  postFactoringConnectionFactorRoleSaga,
  getFactoringConnectionsFactorRoleSaga,
  getFactorRegistryFilesSaga,
  getFactoringContractsFactorRoleSaga,
  getFactoringContractFactorRoleByGuidSaga,
  postAgreementSignatureFactorRoleSaga,
  getEarlyPaymentApplicationsFactorRoleSaga,
  putEarlyPaymentApplicationDeclineFactorRoleSaga,
  getOneFactorRoleByGuidSaga,
  getMonetaryClaimsFactorEarlyPaymentApplicationsByGuidSaga,
  getFactoringFeesOfLimitsSaga,
  postFactoringFeesOfLimitsSaga,
  getStartRateFactorRoleSaga,
  getFactoringConnectionsByIdSaga,
  deleteFactoringFeesOfLimitsSaga,
  patchFactoringFeesOfLimitsSaga,
  getCurrentFactoringSettingsByDebtorInnSaga
];
