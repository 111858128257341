import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, USER_PERMISSIONS } from 'globaltypes';
import {
  APPLICATION_STATUSES,
  APPLICATION_TYPES,
  APPLICATION_TYPE_ROUTES
} from 'Application/types';
import { ResponseDataType as GetApplicationResponseDataType } from 'Application/reducers/getApplication';
import { EachApplicationProps } from 'Application/components';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
  applicationData: GetApplicationResponseDataType;
  internalApplicationData: any;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps;

const defaultValue = {
  data: {},
  canEdit: false
};

export type ApplicationViewContextType = EachApplicationProps<
  GetApplicationResponseDataType
>;
export const ApplicationViewContext = React.createContext(defaultValue);
export const ApplicationViewConsumer = ApplicationViewContext.Consumer;

const ApplicationViewProvider: React.FC<Props> = ({
  applicationData,
  internalApplicationData,
  permissions,
  match,
  children
}) => {
  const setProviderValue = () => {
    const { id, inBankId } = match.params;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    if (role === 'internal') {
      const data = {
        data: internalApplicationData,
        editDataRoute:
          internalApplicationData.financingType ===
            APPLICATION_TYPES.GUARANTEES ||
          internalApplicationData.financingType ===
            APPLICATION_TYPES.CONTRACT_FINANCING
            ? `/cabinet/${role}/application-form-${APPLICATION_TYPE_ROUTES[
                internalApplicationData.financingType
              ].replace('_', '-')}/edit/${internalApplicationData.id}`
            : `/cabinet/${role}/application-form/edit/${
                inBankId ? inBankId : id
              }/${
                APPLICATION_TYPE_ROUTES[internalApplicationData.financingType]
              }`,
        editDocsRoute:
          internalApplicationData.financingType ===
            APPLICATION_TYPES.GUARANTEES ||
          internalApplicationData.financingType ===
            APPLICATION_TYPES.CONTRACT_FINANCING
            ? `/cabinet/${role}/application-form-${APPLICATION_TYPE_ROUTES[
                internalApplicationData.financingType
              ].replace('_', '-')}/edit/${internalApplicationData.id}/documents`
            : `/cabinet/${role}/application-form/edit/${
                inBankId ? inBankId : id
              }/${
                APPLICATION_TYPE_ROUTES[internalApplicationData.financingType]
              }/documents`
      };

      return data;
    } else {
      const data = {
        data: applicationData,
        editDataRoute:
          applicationData.financingType === APPLICATION_TYPES.GUARANTEES ||
          applicationData.financingType === APPLICATION_TYPES.CONTRACT_FINANCING
            ? `/cabinet/${role}/application-form-${APPLICATION_TYPE_ROUTES[
                applicationData.financingType
              ].replace('_', '-')}/edit/${applicationData.id}`
            : `/cabinet/${role}/application-form/edit/${
                inBankId ? inBankId : id
              }/${APPLICATION_TYPE_ROUTES[applicationData.financingType]}`,
        editDocsRoute:
          applicationData.financingType === APPLICATION_TYPES.GUARANTEES ||
          applicationData.financingType === APPLICATION_TYPES.CONTRACT_FINANCING
            ? `/cabinet/${role}/application-form-${APPLICATION_TYPE_ROUTES[
                applicationData.financingType
              ].replace('_', '-')}/edit/${applicationData.id}/documents`
            : `/cabinet/${role}/application-form/edit/${
                inBankId ? inBankId : id
              }/${
                APPLICATION_TYPE_ROUTES[applicationData.financingType]
              }/documents`
      };

      return data;
    }
  };

  const canEdit = (
    permissions: USER_PERMISSIONS[],
    status: APPLICATION_STATUSES,
    isAuthor: boolean
  ): boolean => {
    if (permissions.includes(USER_PERMISSIONS.CONTROL_ANY_APPLICATION)) {
      if (
        status === APPLICATION_STATUSES.DRAFT ||
        status === APPLICATION_STATUSES.DOCS_REQUEST ||
        status === APPLICATION_STATUSES.IN_WORK
      )
        return true;
    } else {
      if (isAuthor) {
        if (
          status === APPLICATION_STATUSES.DRAFT ||
          status === APPLICATION_STATUSES.AWAITING
        )
          return true;
      }
    }
    return false;
  };

  const { data, editDataRoute, editDocsRoute } = setProviderValue();

  const value = {
    data,
    editDataRoute,
    editDocsRoute,
    canEdit: canEdit(permissions, data.status, data.isAuthor)
  };

  return (
    <ApplicationViewContext.Provider value={value}>
      {children}
    </ApplicationViewContext.Provider>
  );
};

const mapStateToProps = ({ Application, InternalAgentRole, User }: STORE) => ({
  permissions: User.getUserData.data.permissions,
  internalApplicationData: InternalAgentRole.getInternalApplication.data,
  applicationData: Application.getApplication.data
});

const ApplicationViewProviderConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(ApplicationViewProvider)
);

export { ApplicationViewProviderConnect as ApplicationViewProvider };
