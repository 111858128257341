import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Debtor/model/actions/getFactoringLimitByDebtorInn';

export interface GetFactoringLimitByDebtorInnStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_FACTORING_LIMIT_BY_DEBTOR_INN {
  GET_FACTORING_LIMIT_BY_DEBTOR_INN_REQ = 'GET_FACTORING_LIMIT_BY_DEBTOR_INN_REQ',
  GET_FACTORING_LIMIT_BY_DEBTOR_INN_GOT = 'GET_FACTORING_LIMIT_BY_DEBTOR_INN_GOT',
  GET_FACTORING_LIMIT_BY_DEBTOR_INN_ERR = 'GET_FACTORING_LIMIT_BY_DEBTOR_INN_ERR',
  GET_FACTORING_LIMIT_BY_DEBTOR_INN_RESET = 'GET_FACTORING_LIMIT_BY_DEBTOR_INN_RESET'
}

export const initialState: GetFactoringLimitByDebtorInnStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    page: 0,
    pageSize: 0,
    totalItems: 0,
    items: []
  },
  error: {}
};

const getFactoringLimitByDebtorInn = (
  state: GetFactoringLimitByDebtorInnStoreState = initialState,
  action: {
    type: GET_FACTORING_LIMIT_BY_DEBTOR_INN;
    data: ResponseDataType;
    error: {};
  }
): GetFactoringLimitByDebtorInnStoreState => {
  switch (action.type) {
    case GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    case GET_FACTORING_LIMIT_BY_DEBTOR_INN.GET_FACTORING_LIMIT_BY_DEBTOR_INN_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default getFactoringLimitByDebtorInn;
