import {
  CreditingApplicationWrite,
  CREDITING_PLEDGE,
  CREDITING_GUARANTOR,
  CREDITING_CREDIT_TYPE
} from 'Application/types';

export type SetCreditingApplicationType = Partial<CreditingApplicationWrite>;

export enum CREDITING_FORM_ACTIONS {
  SET = 'SET_CREDITING_FORM_DATA',
  RESET = 'RESET_CREDITING_FORM_DATA'
}

export const initialState: CreditingApplicationWrite = {
  beneficiaryGuarantee: null,
  companiesGuarantee: null,
  credit: null,
  guarantee: null,
  movableEstate: null,
  otherEstate: null,
  overdraft: null,
  realEstate: null,
  revolvingCredit: null,
  creditingType: CREDITING_CREDIT_TYPE.CREDIT,

  pledge: CREDITING_PLEDGE.HAS_PLEDGE,
  pledges: [],
  otherPledgeEstate: '',

  guarantor: CREDITING_GUARANTOR.HAS_GUARANTOR,
  guarantors: []
};

const setCreditingFormData = (
  state: CreditingApplicationWrite = initialState,
  action: {
    type: CREDITING_FORM_ACTIONS;
    data: SetCreditingApplicationType;
  }
): CreditingApplicationWrite => {
  switch (action.type) {
    case CREDITING_FORM_ACTIONS.SET:
      return {
        ...state,
        ...action.data
      };
    case CREDITING_FORM_ACTIONS.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setCreditingFormData;
