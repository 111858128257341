import * as React from 'react';
import { BorrowerCompany } from 'Application/types';
import { BorrowerCompaniesBlock } from './styles';

interface Props {
  borrowerCompany: BorrowerCompany;
  errors: {
    name: string;
    individualNumber: string;
    documents: string;
    roles: string;
  };
  fileUploadBlock: React.ComponentClass<any>;
}

export class BorrowerCompanyBlock extends React.Component<Props> {
  static defaultProps = {
    errors: {
      name: '',
      individualNumber: '',
      documents: '',
      roles: ''
    }
  };

  render() {
    const { errors, borrowerCompany } = this.props;

    return (
      <BorrowerCompaniesBlock>
        <p>
          Подсказка: Используйте эти формы, только если список в новом формате
          не сформирован
        </p>
        <this.props.fileUploadBlock
          borrowerCompany={borrowerCompany}
          errors={errors}
        />
      </BorrowerCompaniesBlock>
    );
  }
}
