import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import {
  CRM,
  ResponseError,
  REQUEST_STATUSES,
  STORE,
  USER_PERMISSIONS
} from 'src/globaltypes';
import { Dispatch, bindActionCreators } from 'redux';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import {
  TableThStyled,
  TableRowStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import {
  Pagination,
  Loader,
  ACTION_RESPONSE_TEMPLATE,
  ActionResponseBlock
} from 'src/features/Layouts/components';
import { ScrollTopComponent } from 'src/features/Common';
import {
  req as getUserList,
  ResponseData as UsersData,
  RequestData as UsersRequest
} from 'src/features/Users/actions/getAllUsersForExternalAgent';
import { req as getApiKeyPublic } from 'src/features/ApiKeys/actions/getApiKeyPublic';
import { Input } from 'shared/ui/Input';
import { Description } from './styled';
import { Button } from 'shared/ui/Button';

interface StateToProps {
  users: UsersData;
  status: REQUEST_STATUSES;
  error: ResponseError;
  apiKeyPublic: string;
  permissions: USER_PERMISSIONS[];
}

interface DispatchToProps {
  getUserList: (data?: UsersRequest) => void;
  getApiKeyPublic: () => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const UserListForExternalAgent: React.FC<Props> = ({
  getUserList,
  users,
  status,
  error,
  getApiKeyPublic,
  apiKeyPublic,
  permissions,
  history,
  match
}) => {
  React.useEffect(
    () => {
      const urlParams = new URLSearchParams(history.location.search);
      const page = parseInt(urlParams.get('page'), 10) || 1;

      getUserList({
        page,
        pageSize: 20,
        companyInnToFilterBy: match.params.inn
      });
      getApiKeyPublic();
    },
    [match.params.inn]
  );

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <ScrollTopComponent>
      <ApplicationTitle>Мои пользователи</ApplicationTitle>

      {location.pathname.includes('clients') ? (
        <p>Ваша ссылка для регистрации пользователей для этой компании:</p>
      ) : (
        permissions.includes(USER_PERMISSIONS.LEADOGENERATOR) && (
          <p>Ваша ссылка для регистрации клиентов:</p>
        )
      )}

      {(location.pathname.includes('clients') ||
        permissions.includes(USER_PERMISSIONS.LEADOGENERATOR)) && (
        <Row>
          <Col lg={6} md={12}>
            <Input
              disabled={true}
              name="API KEY"
              value={
                location.pathname.includes('clients')
                  ? `${location.origin}/registration/business/${
                      match.params.inn
                    }/${apiKeyPublic}`
                  : `${
                      location.origin
                    }/registration/business?partner=${apiKeyPublic}`
              }
            />
          </Col>
          <Col lg={3} md={6}>
            <Button
              label="Скопировать"
              onClick={() =>
                handleCopyClick(
                  location.pathname.includes('clients')
                    ? `${location.origin}/registration/business/${
                        match.params.inn
                      }/${apiKeyPublic}`
                    : `${
                        location.origin
                      }/registration/business?partner=${apiKeyPublic}`
                )
              }
            />
          </Col>
        </Row>
      )}

      {(location.pathname.includes('clients') ||
        permissions.includes(USER_PERMISSIONS.LEADOGENERATOR)) && (
        <Description>
          В этом разделе отображаются пользователи клиента, которых вы
          зарегистрировали на платформе
        </Description>
      )}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="10%">Имя</TableThStyled>
              <TableThStyled width="10%">Телефон</TableThStyled>
              <TableThStyled width="15%">Email</TableThStyled>
              <TableThStyled width="10%">Дата регистрации</TableThStyled>
              <TableThStyled width="10%">Последний вход</TableThStyled>
              <TableThStyled width="10%">ИНН компании</TableThStyled>
              <TableThStyled width="10%">Идентификатор</TableThStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {users.items.map(user => (
              <TableRowStyled key={user.id}>
                <td>
                  {user.lastName} {user.firstName}
                </td>
                <td>{user.phoneNumber}</td>
                <td>{user.email}</td>
                <td>{new Date(user.createdDateTime).toLocaleDateString()}</td>
                <td>
                  {user.lastLoginAt === '0001-01-01T00:00:00'
                    ? null
                    : new Date(user.lastLoginAt).toLocaleDateString()}
                </td>
                <td>{user.companyInn}</td>
                <td>{user.id}</td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
      <Pagination list={users} />

      <ActionResponseBlock
        showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
      />

      <ActionResponseBlock
        showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ScrollTopComponent>
  );
};

const mapStateToProps = ({ Users, ApiKeys, User }: CRM & STORE) => ({
  users: Users.getAllUsersForExternalAgent.data,
  status: Users.getAllUsersForExternalAgent.status,
  error: Users.getAllUsersForExternalAgent.error,
  apiKeyPublic: ApiKeys.getApiKeyPublic.key,
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserList, getApiKeyPublic }, dispatch);

const UserListForExternalAgentConnect = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserListForExternalAgent)
);

export { UserListForExternalAgentConnect as UserListForExternalAgent };
