import { put, takeLatest } from 'redux-saga/effects';

import { err } from '../actions/getContractFile';
import { GET_CONTRACT_FILE } from '../reducers/getContractFile';

function* getContractFile(action: { type: GET_CONTRACT_FILE; guid: string }) {
  try {
    const url = `/api/purchase_contract/${action.guid}/get_agreement_file`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.docx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getContractFileSaga() {
  yield takeLatest(GET_CONTRACT_FILE.REQ, getContractFile);
}
