import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postOfferSend';

import { POST_OFFER_SEND } from '../reducers/postOfferSend';
import ApplicationApi from '../api';

function* postOfferSend(action: {
  type: POST_OFFER_SEND;
  id: number;
}): Generator {
  try {
    yield call(ApplicationApi.postOfferSend, action.id);
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postOfferSendSaga(): Generator {
  yield takeLatest(POST_OFFER_SEND.REQ, postOfferSend);
}
