import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFactoringLimit';

import { GET_FACTORING_LIMIT } from '../reducers/getFactoringLimit';
import FactoringLimitsApi from '../api';

function* getFactoringLimit(action: {
  type: GET_FACTORING_LIMIT;
  id: number;
}): Generator {
  try {
    let res;

    res = yield call(FactoringLimitsApi.getFactoringLimit, action.id);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringLimitSaga(): Generator {
  yield takeLatest(GET_FACTORING_LIMIT.REQ, getFactoringLimit);
}
