import * as React from 'react';
import { FormSectionStyled, LegendStyled } from './styles';

interface Props {
  legend?: string;
  legendId?: string;
  className?: string;
  children: any;
}

export const FormSection: React.FC<Props> = ({
  legend,
  legendId,
  className,
  children
}: Props) => (
  <FormSectionStyled id={legendId} className={className}>
    {!!legend && <LegendStyled>{legend}</LegendStyled>}
    {children}
  </FormSectionStyled>
);
