import { combineReducers } from 'redux';
import patchDetail, { GetDetailStoreState } from './patchDetail';

export interface ContactDetailReducers {
  patchDetail: GetDetailStoreState;
}

export const ContactDetail = combineReducers({
  patchDetail
});
