import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { REQUEST_STATUSES, STORE, ResponseError } from 'globaltypes';
import { reset as externalAgentRegistrationReset } from 'ExternalAgent/actions/externalAgentRegister';

import { RegistrationPage } from 'User/components';
import { ExternalAgentRegistrationForm } from 'ExternalAgent/components';

import {
  RegistrationPageStyled,
  InfoBlock,
  RegistrationFormBox
} from './styles';

interface StateToProps {
  registrationStatus: REQUEST_STATUSES;
  registrationError: ResponseError;
  email: string;
}

interface DispatchToProps {
  externalAgentRegistrationReset: () => void;
}

type Props = StateToProps & DispatchToProps;

const ExternalAgentRegistrationPage: React.FC<Props> = ({
  email,
  registrationStatus,
  registrationError,
  externalAgentRegistrationReset
}) => (
  <RegistrationPage
    email={email}
    registrationStatus={registrationStatus}
    registrationError={registrationError}
    registrationReset={externalAgentRegistrationReset}
  >
    <RegistrationPageStyled>
      <InfoBlock>
        <h2>Регистрация агента</h2>
        <p>
          Чтобы стать зарегистрированным агентом FINFACTORY вам необходимо
          пройти процесс регистрации. После заполнения данной анкеты с вами
          свяжется наш сотрудник, который уточнит все необходимые детали и
          предоставит вам специальный логин и пароль. После этого вы сможете
          залогиниться в системе и добавлять в нее ваших клиентов.
        </p>
      </InfoBlock>

      <RegistrationFormBox>
        <ExternalAgentRegistrationForm />
      </RegistrationFormBox>
    </RegistrationPageStyled>
  </RegistrationPage>
);

const mapStateToProps = ({ ExternalAgent }: STORE) => ({
  registrationStatus: ExternalAgent.externalAgentRegister.status,
  registrationError: ExternalAgent.externalAgentRegister.error,
  email: ExternalAgent.externalAgentRegister.data.email
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ externalAgentRegistrationReset }, dispatch);

const ExternalAgentRegistrationPageConnect = connect<
  StateToProps,
  DispatchToProps
>(
  mapStateToProps,
  mapDispatchToProps
)(ExternalAgentRegistrationPage);

export {
  ExternalAgentRegistrationPageConnect as ExternalAgentRegistrationPage
};
