import * as React from 'react';
import { ErrorStyled } from './styles';

interface Props {
  error: string;
  className?: string;
}

export const Error: React.FC<Props> = ({ error, className }: Props) =>
  !!error && (
    <ErrorStyled className={!!className ? className : ''}>{error}</ErrorStyled>
  );
