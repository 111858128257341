import { all, call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Comments/actions/postComment';
import { req as getCommentsList } from 'Comments/actions/getCommentsList';

import { RequestDataType, POST_COMMENT } from 'Comments/reducers/postComment';
import { uploadCommentDocumentsSaga } from 'Comments/sagas/uploadCommentDocuments';

import CommentsApi from 'Comments/api';
import notifyAboutCommentSaga from './notifyAboutComment';

function* postComment(action: { type: POST_COMMENT; data: RequestDataType }) {
  try {
    const { files, ...postCommentData } = action.data;
    const res:any = yield call(CommentsApi.postComment, postCommentData);

    const responseFiles = yield call(uploadCommentDocumentsSaga, {
      id: res.id,
      files
    });


    yield put(
      getCommentsList({
        id: action.data.id,
        type: action.data.type
      })
    );
    yield put(got(res));
    yield call(notifyAboutCommentSaga, {commentId: res.id});
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCommentSaga() {
  yield takeLatest(POST_COMMENT.REQ, postComment);
}
