import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RadioGroup } from 'shared/ui/RadioGroup';
import { CheckboxGroup } from 'shared/ui/CheckboxGroup';
import { InputDate } from 'shared/ui/InputDate';
import {
  CONTACTS,
  LOCKED,
  ROLE
} from 'src/features/Filters/Companies/FilterByCooperation/types';
import { CheckboxState, RadioBtnState } from 'src/features/Companies/types';

interface Props {
  radioBtnState: RadioBtnState;
  handleRadioBtn: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxState: CheckboxState;
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lastActivityDate: string;
  handleLastActivityDateChange: (date: string) => void;
}

const FilterByCooperation: React.FC<Props> = ({
  radioBtnState,
  handleRadioBtn,
  lastActivityDate,
  handleLastActivityDateChange,
  checkboxState,
  handleCheckbox
}) => {
  return (
    <>
      <RadioGroup
        label="Наличие контактов:"
        name="contacts"
        keyValue={radioBtnState.hasContacts}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(CONTACTS).map(value => ({
          value,
          label: CONTACTS[value]
        }))}
      />
      <RadioGroup
        label="Закрепление:"
        name="locked"
        keyValue={radioBtnState.locked}
        onChange={handleRadioBtn}
        radioBtns={Object.keys(LOCKED).map(value => ({
          value,
          label: LOCKED[value]
        }))}
      />
      <CheckboxGroup
        name="role"
        checkboxes={Object.keys(ROLE).map(type => ({
          label: ROLE[type],
          value: type,
          checked: checkboxState.role.includes(type),
          onChange: handleCheckbox
        }))}
      />
      <InputDate
        onDateChange={handleLastActivityDateChange}
        maxDate={new Date()}
        dateValue={lastActivityDate}
        label="Последняя активность"
      />
    </>
  );
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FilterByCooperationConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterByCooperation);

export { FilterByCooperationConnect as FilterByCooperation };
