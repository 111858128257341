import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from '../actions/getPurchaseContractByGuid';

export interface GetPurchaseContractByGuidStoreState extends Reducer {
  data: ResponseDataType;
}

export enum GET_PURCHASE_CONTRACT_BY_GUID {
  GET_PURCHASE_CONTRACT_BY_GUID_REQ = 'GET_PURCHASE_CONTRACT_BY_GUID_REQ',
  GET_PURCHASE_CONTRACT_BY_GUID_GOT = 'GET_PURCHASE_CONTRACT_BY_GUID_GOT',
  GET_PURCHASE_CONTRACT_BY_GUID_ERR = 'GET_PURCHASE_CONTRACT_BY_GUID_ERR'
}

export const initialState: GetPurchaseContractByGuidStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const getPurchaseContractByGuid = (
  state: GetPurchaseContractByGuidStoreState = initialState,
  action: {
    type: GET_PURCHASE_CONTRACT_BY_GUID;
    data: ResponseDataType;
    error: {};
  }
): GetPurchaseContractByGuidStoreState => {
  switch (action.type) {
    case GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        data: action.data
      };
    case GET_PURCHASE_CONTRACT_BY_GUID.GET_PURCHASE_CONTRACT_BY_GUID_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getPurchaseContractByGuid;
