import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { InputPassword } from 'shared/ui/InputPassword';
import { PasswordHint } from 'shared/ui/Hint';
import { Input } from 'shared/ui/Input';
import { Form } from 'shared/ui/Form';
import { FormSection } from 'shared/ui/FormSection';

import { russiaPhoneMask } from 'shared/utils/InputMasks';

import {
  req as businessRegister,
  RequestDataType
} from 'Business/actions/businessRegister';

import { createValidation } from './validator';
import { InnPlaceholders } from 'shared/constants';
import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import {
  req as getCompanyFastInfo,
  ResponseDataType as ResponseCompanyFastInfo
} from 'src/features/SCF/actions/getCompanyFastInfo';

import { CheckboxStyled, InfoField, PopupContainer, PopupInfo } from './styles';
import { Button } from 'shared/ui/Button';

export interface Fields {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  code: string;
  password: string;
  repeatPassword: string;
}

interface State extends Fields {
  rulesAgreement: boolean;
  isInfoPopup: boolean;
}

interface StateToProps {
  companyFastInfo: ResponseCompanyFastInfo;
  statusCompanyFastInfo: REQUEST_STATUSES;
}

interface DispatchToProps {
  businessRegister: (
    data: RequestDataType,
    headers?: { apiKey?: string }
  ) => void;
  getCompanyFastInfo: (inn: string) => void;
}

interface MatchParams {
  inn: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class BusinessRegistrationForm extends React.Component<Props, State> {
  searchParams = new URLSearchParams(location.search);
  Validator = createValidation();
  state: State = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    code: this.searchParams.get('partner')
      ? ''
      : this.props.match.params.inn
        ? this.props.match.params.inn
        : '',
    password: '',
    repeatPassword: '',
    rulesAgreement: false,
    isInfoPopup: false
  };

  componentDidMount(): void {
    if (this.props.match.params.inn) {
      this.props.getCompanyFastInfo(this.props.match.params.inn);
    }
  }

  componentDidUpdate(prevProps, prevState: State): void {
    if (prevState.code !== this.state.code && this.state.code.length >= 10) {
      this.props.getCompanyFastInfo(this.state.code);
      this.setState({ isInfoPopup: false });
    }
  }

  onSubmit = () => {
    this.Validator.showAllErrors();

    const { errors } = this.Validator.validate(this.state);

    function checkFields() {
      for (const key in errors) {
        if (key !== 'code' && errors[key] !== '') {
          return false;
        }
      }
      return true;
    }

    const errorEmpty = checkFields();

    if (!this.props.match.params.inn && !!errors.code.length && errorEmpty) {
      const {
        repeatPassword,
        rulesAgreement,
        code,
        isInfoPopup,
        ...dataForRequest
      } = this.state;

      if (this.searchParams.get('partner')) {
        this.props.businessRegister(dataForRequest, {
          apiKey: this.searchParams.get('partner')
        });
      } else {
        this.props.businessRegister(dataForRequest);
      }
      return;
    } else if (
      (this.props.match.params.inn || this.searchParams.get('partner')) &&
      errorEmpty
    ) {
      const {
        repeatPassword,
        rulesAgreement,
        isInfoPopup,
        ...dataForRequest
      } = this.state;

      if (this.searchParams.get('partner')) {
        this.props.businessRegister(dataForRequest, {
          apiKey: this.searchParams.get('partner')
        });
      } else {
        this.props.businessRegister(dataForRequest, {
          apiKey: location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
          )
        });
      }
      return;
    }
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    const isFieldToTrim =
      name === 'email' ||
      name === 'phoneNumber' ||
      name === 'password' ||
      name === 'repeatPassword';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (name === 'code' && sanitizedValue.length > 12) {
      return;
    } else if (name === 'code' && sanitizedValue.length <= 12) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');

      this.setState({ code: sanitizedValue });
    } else {
      this.setState({
        [name]: isCheckbox
          ? checked
          : isFieldToTrim
            ? value.replace(/\s+/g, '')
            : value
      } as { [key in keyof Fields]: string });
    }
  };

  onPopupClick = () => {
    this.setState({ isInfoPopup: true });
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      code,
      password,
      repeatPassword,
      rulesAgreement,
      isInfoPopup
    } = this.state;

    const { errors, fields } = this.Validator.insertArgs({
      passwordEqual: [password]
    }).validate(this.state);

    return (
      <Form onSubmit={this.onSubmit} name="business-registration-form">
        <h2>Регистрация на платформе</h2>

        <FormSection>
          <Input
            value={firstName}
            label="Ваше имя"
            name="firstName"
            placeholder="Имя"
            required={true}
            error={errors.firstName}
            onChange={this.onChange}
          />
          <Input
            value={lastName}
            label="Ваша фамилия"
            name="lastName"
            placeholder="Фамилия"
            required={true}
            error={errors.lastName}
            onChange={this.onChange}
          />
          <Input
            value={phoneNumber}
            label="Контактный номер телефона"
            name="phoneNumber"
            placeholder="+7(___)___-__-__"
            mask={russiaPhoneMask}
            required={true}
            error={errors.phoneNumber}
            onChange={this.onChange}
          />
        </FormSection>

        <FormSection>
          <Input
            value={email}
            label="Email"
            name="email"
            placeholder="Email"
            required={true}
            error={errors.email}
            onChange={this.onChange}
          />

          {(this.props.match.params.inn ||
            this.searchParams.get('partner')) && (
            <PopupContainer>
              <Input
                value={code}
                label="ИНН вашей компании или ИП"
                name="code"
                placeholder={InnPlaceholders.entity}
                warning={
                  (this.state.code.length === 10 ||
                    this.state.code.length === 12) &&
                  !!errors.code.length &&
                  this.props.statusCompanyFastInfo === REQUEST_STATUSES.GOT
                    ? null
                    : this.state.code.length === 10 &&
                      errors.code.length === 0 &&
                      this.props.statusCompanyFastInfo ===
                        REQUEST_STATUSES.ERROR
                      ? 'Компания с таким ИНН не найдена'
                      : this.state.code.length === 12 &&
                        errors.code.length === 0 &&
                        this.props.statusCompanyFastInfo ===
                          REQUEST_STATUSES.ERROR
                        ? 'ИП с таким ИНН не найден'
                        : (this.state.code.length === 10 ||
                            this.state.code.length === 12) &&
                          !!errors.code.length &&
                          this.props.statusCompanyFastInfo ===
                            REQUEST_STATUSES.ERROR
                          ? 'Введенный ИНН может быть некорректным'
                          : errors.code
                }
                onChange={this.onChange}
              />

              {this.props.statusCompanyFastInfo === REQUEST_STATUSES.GOT &&
                code &&
                code.length >= 10 &&
                !isInfoPopup && (
                  <InnPopup onPopupClick={this.onPopupClick}>
                    <PopupInfo>
                      <p>ИНН {this.props.companyFastInfo.inn}</p>
                      <p>
                        {this.props.companyFastInfo.shortName
                          ? this.props.companyFastInfo.shortName
                          : this.props.companyFastInfo.fullName}
                      </p>
                      <p>{this.props.companyFastInfo.address}</p>
                    </PopupInfo>
                  </InnPopup>
                )}

              {this.state.isInfoPopup && (
                <InfoField>
                  <p>ИНН {this.props.companyFastInfo.inn}</p>
                  <p>{this.props.companyFastInfo.shortName}</p>
                  <p>{this.props.companyFastInfo.address}</p>
                </InfoField>
              )}
            </PopupContainer>
          )}

          <InputPassword
            value={password}
            label="Пароль"
            name="password"
            placeholder="Пароль"
            required={true}
            error={errors.password}
            onChange={this.onChange}
            hint={{
              validatable: true,
              position: 'left',
              component: (
                <PasswordHint validationStatuses={fields.password.statuses} />
              )
            }}
          />
          <InputPassword
            value={repeatPassword}
            label="Подтвердите пароль"
            name="repeatPassword"
            placeholder="Пароль"
            required={true}
            error={errors.repeatPassword}
            onChange={this.onChange}
          />
          <CheckboxStyled
            template="agreementCheckbox"
            name="rulesAgreement"
            checked={rulesAgreement}
            error={errors.rulesAgreement}
            onChange={this.onChange}
          />
        </FormSection>

        <Button type="submit" label="Зарегистрироваться" template="nextBtn" />
      </Form>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  companyFastInfo: SCF.getCompanyFastInfo.data,
  statusCompanyFastInfo: SCF.getCompanyFastInfo.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ businessRegister, getCompanyFastInfo }, dispatch);

const BusinessRegistrationFormConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(BusinessRegistrationForm)
);

export { BusinessRegistrationFormConnect as BusinessRegistrationForm };
