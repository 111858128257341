import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getHolding';

import {
  GET_HOLDING,
} from '../reducers/getHolding';
import HoldingsApi from '../api';

function* getHolding(action: {
  type: GET_HOLDING;
  id: number
}): Generator {
  try {

    const res:any = yield call(HoldingsApi.getHolding, action.id);

    yield put(got(res));

  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLeadSaga(): Generator {
  yield takeLatest(GET_HOLDING.REQ, getHolding);
}
