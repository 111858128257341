import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { DOCUMENT_UPLOAD_TYPE } from 'Application/types';

export interface UploadDocumentsStoreState extends Reducer {
  data: SagaRequestDataType;
}

interface RequestCommonDataType {
  id: number;
  type: DOCUMENT_UPLOAD_TYPE;
}

export interface RequestDataType extends RequestCommonDataType {
  file: FormData;
  metaInfo: string;
}

export interface SagaRequestDataType extends RequestCommonDataType {
  files: any[];
}

export enum UPLOAD_DOCUMENTS {
  REQ = 'UPLOAD_DOCUMENTS_REQUEST',
  GOT = 'UPLOAD_DOCUMENTS_GOT',
  ERR = 'UPLOAD_DOCUMENTS_ERROR'
}

export const initialState: UploadDocumentsStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: null,
  error: {}
};

const uploadDocuments = (
  state: UploadDocumentsStoreState = initialState,
  action: {
    type: string;
    data: SagaRequestDataType;
    error: {};
  }
): UploadDocumentsStoreState => {
  switch (action.type) {
    case UPLOAD_DOCUMENTS.REQ:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case UPLOAD_DOCUMENTS.GOT:
      return {
        ...state,
        data: null,
        status: REQUEST_STATUSES.GOT
      };
    case UPLOAD_DOCUMENTS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default uploadDocuments;
