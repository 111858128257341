import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { STORE, USER_PERMISSIONS } from 'src/globaltypes';
import {
  FinancingTypeBlockStyled,
  FinancingTypeBlockTextStyled,
  FinancingTypeBlockIconStyled,
  FinancingTypeBlockDisabledTextStyled,
  StyledIcon,
  InstructionStyled,
  ModalOverlay,
  Modal
} from './styles';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
}

interface OwnProps {
  type: string | JSX.Element;
  icon: JSX.Element;
  isDisabled?: boolean;
  disabledText?: string;
  youtubeLink?: string;
  onClick?: () => void;
}

type Props = StateToProps & OwnProps;

const FinancingTypeBlock: React.FC<Props> = ({
  type,
  onClick,
  isDisabled,
  disabledText,
  icon,
  youtubeLink,
  permissions
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const modalRef = React.useRef(null);

  React.useEffect(
    () => {
      if (modalIsOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    },
    [modalIsOpen]
  );

  const openModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOutsideClick = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  return (
    <>
      <FinancingTypeBlockStyled isDisabled={isDisabled} onClick={onClick}>
        {!isDisabled &&
          permissions.includes(USER_PERMISSIONS.EXTERNAL_AGENT) && (
            <InstructionStyled onClick={openModal}>
              <StyledIcon icon={faFilm} />
              <p>Видеоинструкция</p>
            </InstructionStyled>
          )}
        <FinancingTypeBlockIconStyled>{icon}</FinancingTypeBlockIconStyled>
        <FinancingTypeBlockTextStyled>{type}</FinancingTypeBlockTextStyled>
        <FinancingTypeBlockDisabledTextStyled>
          {disabledText}
        </FinancingTypeBlockDisabledTextStyled>
      </FinancingTypeBlockStyled>

      {modalIsOpen && (
        <ModalOverlay>
          <Modal ref={modalRef}>
            <iframe
              width="100%"
              height="650"
              src={youtubeLink}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
};

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const FinancingTypeBlockConnect = connect<StateToProps>(
  mapStateToProps,
  mapDispatchToProps
)(FinancingTypeBlock);

export { FinancingTypeBlockConnect as FinancingTypeBlock };
