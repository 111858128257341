import styled from 'styled-components';

export const RevenueContainer = styled.div`
  max-width: 200px;
`;

export const SegmentClientContainer = styled.div`
  margin-left: 30px;

  & > fieldset {
    display: flex;
    flex-wrap: wrap;
    max-height: 70px;
  }

  & > fieldset > :nth-child(4) {
    margin-top: 23px;
  }
`;

export const TypeClientContainer = styled.div`
  max-width: 260px;
  margin-left: 130px;

  & > fieldset {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    max-height: 100px;
  }

  & > fieldset > :nth-child(5) {
    margin-top: 23px;
  }
`;

export const MspSubjectContainer = styled.div`
  margin-left: 200px;
  margin-top: 5px;
`;
