import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  addDays,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  parse,
  parseISO,
  startOfDay,
  startOfToday,
  subDays
} from 'date-fns';

import { InputDate } from 'shared/ui/InputDate';
import { Loader, Pagination } from 'Layouts/components';
import {
  EarlyPaymentApplication,
  req as getEarlyPaymentApplications,
  RequestDataType,
  ResponseDataType
} from 'entities/SCF/Supplier/model/actions/getEarlyPaymentApplicationsSupplierRole';
import { req as postEarlyDatePick } from 'src/entities/SCF/Supplier/model/actions/postEarlyDatePick';
import { openModal } from 'Modal/actions/toggleModal';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import {
  ContractsPageStyled,
  CreateBtn,
  CreateBtnContainer,
  InnFilterArea,
  InnFilter,
  InnContainer,
  PeriodFilter,
  PeriodFilterItem,
  DateFilterStyled,
  DatesContainer,
  SignBtn,
  SignBtnText,
  DatePopup,
  DatePopupBtns,
  CancelBtn,
  CancelBtnText,
  GotBtn,
  GotBtnText,
  PaidBtn,
  PaidBtnText,
  TextThrough,
  TooltipBoxStyle,
  TDPayment,
  ErrorMessage
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import { RequestDataType as PostEarlyDatePickDateType } from 'src/entities/SCF/Supplier/model/reducers/postEarlyDatePick';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { rl } from 'shared/styled';
import { Button } from 'shared/ui/Button';
import { Modal } from 'src/features/Common';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  earlyPaymentApplications: ResponseDataType;
  statusPostEarlyDatePick: REQUEST_STATUSES;
  errorPostEarlyDatePick: ResponseError;
}

interface DispatchToProps {
  getEarlyPaymentApplications: (data: RequestDataType) => void;
  postEarlyDatePick: (data: PostEarlyDatePickDateType) => void;
  openModal: (data: OpenModalDataType) => void;
}

interface State {
  debtorInnToFilter: string;
  payerInnToFilter: string;
  dateFrom: string;
  dateUntil: string;
  earlyPaymentDate: string;
  firstAvailableDate: string;
  disabledDays: {
    payWeekdays: string;
    paydayOffset: number;
  };
  isPeriod: boolean;
  firstBasePaymentDate: string;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationsList: React.FC<Props> = ({
  getEarlyPaymentApplications,
  earlyPaymentApplications,
  status,
  postEarlyDatePick,
  statusPostEarlyDatePick,
  errorPostEarlyDatePick,
  openModal,
  history
}) => {
  const [debtorInnToFilter, setDebtorInnToFilter] = React.useState('');
  const [payerInnToFilter, setPayerInnToFilter] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState(
    format(parse('01/01/2023', 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
  );
  const [dateUntil, setDateUntil] = React.useState(
    format(parse('01/01/2053', 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
  );
  const [earlyPaymentDate, setEarlyPaymentDate] = React.useState('');
  const [disabledDays, setDisabledDays] = React.useState({
    payWeekdays: '',
    paydayOffset: null
  });
  const [isPeriod, setIsPeriod] = React.useState(false);
  const [firstBasePaymentDate, setFirstBasePaymentDate] = React.useState('');

  const handleDisabledDaysChange = (
    valueOfResponse: State['disabledDays'],
    firstBasePaymentDate: string
  ) => {
    setFirstBasePaymentDate(firstBasePaymentDate);
    setDisabledDays({
      payWeekdays:
        valueOfResponse.payWeekdays === null
          ? '1234567'
          : valueOfResponse.payWeekdays,
      paydayOffset:
        valueOfResponse.paydayOffset === null ? 3 : valueOfResponse.paydayOffset
    });
  };

  const initFetch = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);

    getEarlyPaymentApplications({
      page,
      ...(debtorInnToFilter && { DebtorInnToFilterBy: debtorInnToFilter }),
      ...(payerInnToFilter && { PayerInnToFilterBy: payerInnToFilter }),
      StartDate: dateFrom,
      EndDate: dateUntil
    });
  };

  React.useEffect(
    () => {
      if (statusPostEarlyDatePick === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_EARLY_DATE_PICK });
      }
    },
    [statusPostEarlyDatePick]
  );

  React.useEffect(
    () => {
      initFetch();
    },
    [debtorInnToFilter, payerInnToFilter, dateFrom, dateUntil]
  );

  React.useEffect(
    () => {
      const findEarlyPaymentDate = () => {
        let today = addDays(new Date(), disabledDays.paydayOffset);
        const payDaysArray = disabledDays.payWeekdays.split('').map(Number);

        for (let i = 0; i < 7; i++) {
          let currentDay = getDay(today);
          if (payDaysArray.includes(currentDay)) {
            setEarlyPaymentDate(format(today, 'dd/MM/yyyy'));
            return;
          }
          today = addDays(today, 1);
        }
      };

      findEarlyPaymentDate();
    },
    [disabledDays.payWeekdays, disabledDays.paydayOffset]
  );

  const handleDateChange = (name: keyof State, date: string) => {
    if (name === 'dateFrom') {
      setDateFrom(date);
    } else if (name === 'dateUntil') {
      setDateUntil(date);
    } else if (name === 'earlyPaymentDate') {
      setEarlyPaymentDate(date);
    }
  };

  const handleInnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (name === 'payerInnToFilter') {
      setPayerInnToFilter(value);
    } else if (name === 'debtorInnToFilter') {
      setDebtorInnToFilter(value);
    }
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const onPickEarlyDateConfirmClick = async (guid: string) => {
    let datePopup = document.getElementById(guid + 'datePopup');
    await delay(700);
    datePopup.style.display = 'none';
    setEarlyPaymentDate('');
    initFetch();
  };

  const onCancelClick = (guid: string) => {
    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'none';
  };

  const onAllTimeClick = () => {
    setIsPeriod(false);
    setDateFrom(
      format(parse('01/01/2023', 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
    );
    setDateUntil(
      format(parse('01/01/2053', 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
    );
  };

  const onPeriodClick = () => {
    setIsPeriod(true);
    setDateFrom(
      format(parse('01/01/2023', 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
    );
    setDateUntil(format(new Date(), 'dd/MM/yyyy'));
  };

  const getLastDate = (lastDate: string): Date => {
    return parseISO(lastDate);
  };

  const getFirstDate = (): Date => {
    const myDate = startOfToday();
    return addDays(myDate, disabledDays.paydayOffset);
  };

  const getMaxDate = (): Date => {
    return subDays(new Date(firstBasePaymentDate), 3);
  };

  const calculateViews = (item: EarlyPaymentApplication) => {
    const currentDate = startOfDay(new Date());
    const firstBasePaymentDate = parseISO(item.firstBasePaymentDate);
    const adjustedFirstBasePaymentDate = subDays(
      firstBasePaymentDate,
      item.paydayOffset - 3
    );
    const currentPlusOffset = startOfDay(
      addDays(currentDate, item.paydayOffset)
    );
    const payDate = startOfDay(new Date(item.payDate));

    return {
      view1:
        !item.payDate && isBefore(currentDate, adjustedFirstBasePaymentDate),
      view2:
        (!item.auctionGuid &&
          payDate &&
          (isAfter(payDate, currentPlusOffset) ||
            isEqual(payDate, currentPlusOffset)) &&
          item.status < 12 &&
          isBefore(currentDate, adjustedFirstBasePaymentDate)) ||
        (item.auctionGuid &&
          item.payDate &&
          (isBefore(currentDate, payDate) || isEqual(currentDate, payDate))),
      view3:
        item.payDate &&
        isBefore(payDate, currentPlusOffset) &&
        item.status < 12 &&
        isBefore(currentDate, adjustedFirstBasePaymentDate),
      view4:
        (!item.auctionGuid &&
          !item.payDate &&
          isAfter(currentDate, adjustedFirstBasePaymentDate)) ||
        (!item.auctionGuid &&
          item.payDate &&
          isAfter(currentDate, adjustedFirstBasePaymentDate) &&
          item.status < 12) ||
        (item.auctionGuid && item.payDate && isAfter(currentDate, payDate)),
      view5: item.payDate && item.status >= 12
    };
  };

  const handlePickEarlyPayment = (item: EarlyPaymentApplication) => {
    const { view1, view3, view4 } = calculateViews(item);

    if (view1 || view3 || view4) {
      return;
    }

    const { guid, statusSupplier, auctionGuid } = item;

    statusSupplier === 'Черновик' && !auctionGuid
      ? history.push(
          `/supplier/early_payment_applications/${guid}/pick_creditor`
        )
      : statusSupplier === 'Черновик' && !!auctionGuid
        ? history.push(
            `/supplier/early_payment_applications/${guid}/make_offer`
          )
        : statusSupplier === 'Выбран кредитор' && !auctionGuid
          ? history.push(
              `/supplier/early_payment_applications/${guid}/pick_signee`
            )
          : statusSupplier === 'Выбран кредитор' && auctionGuid
            ? history.push(
                `/supplier/early_payment_applications/${guid}/make_offer`
              )
            : statusSupplier === 'Выбран подписант'
              ? history.push(
                  `/supplier/early_payment_applications/${guid}/calc`
                )
              : statusSupplier === 'Акцептованы условия'
                ? history.push(
                    `/supplier/early_payment_applications/${guid}/agreement`
                  )
                : history.push(`/supplier/early_payment_applications/${guid}`);
  };

  const EarlyPayDate = (item: EarlyPaymentApplication): React.JSX.Element => {
    const { view1, view2, view3, view4, view5 } = calculateViews(item);

    if (view1) {
      return (
        <td>
          <SignBtn
            onClick={e =>
              handleSelectDate(
                e,
                item.guid,
                item.payDate,
                item.debtorInn,
                item.firstBasePaymentDate
              )
            }
          >
            <SignBtnText>Выбрать</SignBtnText>
          </SignBtn>
        </td>
      );
    }

    if (view2 || view5) {
      return <td>{format(new Date(item.payDate), 'dd.MM.yyyy')}</td>;
    }

    if (view3) {
      return (
        <TDPayment>
          <TextThrough>
            <div>
              <p>{format(new Date(item.payDate), 'dd.MM.yyyy')}</p>
              <TooltipBoxStyle text="Дата стала недоступной">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </TooltipBoxStyle>
            </div>
            <Button
              label="Выбрать новую дату"
              onClick={e => {
                e.stopPropagation();
                fetch(
                  `/api/early_payment_applications/supplier_role/${
                    item.debtorInn
                  }/get_pay_weekdays`
                )
                  .then(response => response.json())
                  .then(data =>
                    handleDisabledDaysChange(data, item.firstBasePaymentDate)
                  );

                let datePopup = document.getElementById(
                  item.guid + 'datePopup'
                );
                datePopup.style.display = 'flex';
              }}
            />
          </TextThrough>
        </TDPayment>
      );
    }

    if (view4) {
      return <td>истекло</td>;
    }

    return <td />;
  };

  const handlePickUp = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string,
    firstBasePaymentDate: string,
    payDate: Date
  ) => {
    e.stopPropagation();
    if (!firstBasePaymentDate || !payDate) {
      let datePopup = document.getElementById(guid + 'datePopup');
      datePopup.style.display = 'flex';
    } else {
      history.push(
        `/supplier/early_payment_applications/${guid}/pick_creditor`
      );
    }
  };

  const handleSelectDate = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    guid: string,
    date: Date,
    debtorInn: string,
    firstBasePaymentDate: string
  ) => {
    e.stopPropagation();

    let datePopup = document.getElementById(guid + 'datePopup');
    datePopup.style.display = 'flex';

    if (!date) {
      fetch(
        `/api/early_payment_applications/supplier_role/${debtorInn}/get_pay_weekdays`
      )
        .then(response => response.json())
        .then(data => handleDisabledDaysChange(data, firstBasePaymentDate));
    }
  };

  const isDayDisabled = (date: Date) => {
    const day = date.getDay();
    return disabledDays.payWeekdays.includes((day === 0 ? 7 : day).toString());
  };

  return (
    <ContractsPageStyled>
      <CreateLimitsNudge>
        <h2>Мои заявки на раннюю оплату</h2>
      </CreateLimitsNudge>
      <PeriodFilter>
        <PeriodFilterItem onClick={onAllTimeClick}>
          <p>За все время</p>
        </PeriodFilterItem>
        <PeriodFilterItem onClick={onPeriodClick}>
          <p>За период</p>
        </PeriodFilterItem>
      </PeriodFilter>
      {isPeriod && (
        <DateFilterStyled>
          <p>Дата заявки:</p>
          <DatesContainer>
            <p>с</p>
            <InputDate
              onDateChange={date => handleDateChange('dateFrom', date)}
              dateValue={dateFrom}
            />
          </DatesContainer>
          <DatesContainer>
            <p>по</p>
            <InputDate
              onDateChange={date => handleDateChange('dateUntil', date)}
              dateValue={dateUntil}
            />
          </DatesContainer>
        </DateFilterStyled>
      )}
      <InnFilterArea>
        <InnFilter>
          <p>ИНН Дебитора</p>
          <InnContainer>
            <input
              name="debtorInnToFilter"
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={handleInnChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>
        <InnFilter>
          <p>ИНН Кредитора</p>
          <InnContainer>
            <input
              name="payerInnToFilter"
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={handleInnChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>
      </InnFilterArea>

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableHeaderCellStyled width="7%">ID</TableHeaderCellStyled>
              <TableHeaderCellStyled width="25%">ДЕБИТОР</TableHeaderCellStyled>
              <TableHeaderCellStyled width="25%">
                ИНВЕСТОР
              </TableHeaderCellStyled>
              <TableHeaderCellStyled width="10%">
                ДАТА ЗАЯВКИ
              </TableHeaderCellStyled>
              <TableHeaderCellStyled width="15%">
                СУММА СЧЕТОВ
              </TableHeaderCellStyled>
              <TableHeaderCellStyled width="20%">
                ДАТА РАННЕЙ ОПЛАТЫ
              </TableHeaderCellStyled>
              <TableHeaderCellStyled width="20%">СТАТУС</TableHeaderCellStyled>
            </tr>
          </TableHeaderStyled>
          <tbody>
            {earlyPaymentApplications.items.map((item, key) => (
              <TableRowStyled
                key={key}
                className="table-row"
                background={'transparent'}
                style={{
                  backgroundColor:
                    calculateViews(item).view1 ||
                    calculateViews(item).view2 ||
                    calculateViews(item).view3
                      ? '#DAF4FF'
                      : ''
                }}
                onClick={() => handlePickEarlyPayment(item)}
              >
                <td>{item.guid.substring(item.guid.length - 5)}</td>
                <td>{item.debtorName}</td>

                {(item.statusSupplier === 'Черновик' ||
                  item.statusSupplier === 'Выбран кредитор' ||
                  item.statusSupplier === 'Акцептированы условия' ||
                  item.statusSupplier === 'Отрыт спор' ||
                  item.statusSupplier === 'Оплачена, ждет подтверждения' ||
                  item.statusSupplier === 'Оплачена, ждет подтверждения') &&
                item.payerName !== null ? (
                  <td>
                    {item.payerType === 1 && !item.auctionGuid ? (
                      <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                        Динамическое дисконтирование
                      </p>
                    ) : item.payerType === 1 && item.auctionGuid !== null ? (
                      <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                        Динамическое дисконтирование, Аукцион
                      </p>
                    ) : (
                      item.payerType === 2 && (
                        <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                          Факторинг
                        </p>
                      )
                    )}
                    <p style={{ margin: '7px 0' }}>{item.payerName}</p>
                  </td>
                ) : item.statusSupplier === 'Черновик' ||
                item.statusSupplier === 'Выбран кредитор' ||
                item.statusSupplier === 'Акцептированы условия' ||
                item.statusSupplier === 'Отрыт спор' ||
                item.statusSupplier === 'Оплачена, ждет подтверждения' ||
                item.statusSupplier === 'Оплачена, ждет подтверждения' ? (
                  <td>
                    {!item.payerInn &&
                      !(
                        (item.payDate === null &&
                          isAfter(
                            new Date(),
                            subDays(
                              new Date(item.firstBasePaymentDate),
                              item.paydayOffset - 3
                            )
                          )) ||
                        (!!item.payDate &&
                          isAfter(
                            new Date(),
                            subDays(
                              new Date(item.firstBasePaymentDate),
                              item.paydayOffset - 3
                            )
                          ) &&
                          item.status < 12)
                      ) && (
                        <SignBtn
                          onClick={e =>
                            handlePickUp(
                              e,
                              item.guid,
                              item.firstBasePaymentDate,
                              item.payDate
                            )
                          }
                        >
                          <SignBtnText>Подобрать</SignBtnText>
                        </SignBtn>
                      )}
                  </td>
                ) : (
                  <td>
                    {item.payerType === 1 && !item.auctionGuid ? (
                      <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                        Динамическое дисконтирование
                      </p>
                    ) : item.payerType === 1 && item.auctionGuid !== null ? (
                      <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                        Динамическое дисконтирование, Аукцион
                      </p>
                    ) : (
                      item.payerType === 2 && (
                        <p style={{ fontSize: 13, fontFamily: rl, margin: 0 }}>
                          Факторинг
                        </p>
                      )
                    )}
                    <p style={{ margin: '7px 0' }}>{item.payerName}</p>
                  </td>
                )}

                <td>{format(new Date(item.createdDateTime), 'dd.MM.yyyy')}</td>

                <td>{item.monetaryClaimsTotalFullSum.toLocaleString('ru')}</td>

                {EarlyPayDate(item)}

                {item.statusSupplier === 'Черновик' ||
                item.statusSupplier === 'Выбран кредитор' ||
                item.statusSupplier === 'Акцептированы условия' ||
                item.statusSupplier === 'Отрыт спор' ||
                item.statusSupplier === 'Оплачена, ждет подтверждения' ||
                item.statusSupplier === 'Оплачена, ждет подтверждения' ? (
                  <td>
                    <GotBtn style={{ backgroundColor: '#D0E9FF' }}>
                      <GotBtnText>{item.statusSupplier}</GotBtnText>
                    </GotBtn>
                  </td>
                ) : item.statusSupplier === 'Отказана' ? (
                  <td>
                    <GotBtn style={{ backgroundColor: '#FF0000' }}>
                      <PaidBtnText>{item.statusSupplier}</PaidBtnText>
                    </GotBtn>
                  </td>
                ) : (
                  <td>
                    <PaidBtn>
                      <PaidBtnText>{item.statusSupplier}</PaidBtnText>
                    </PaidBtn>
                  </td>
                )}

                <DatePopup
                  id={item.guid + 'datePopup'}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    e.stopPropagation()
                  }
                >
                  <DatesContainer>
                    <p>Выберете дату ранней оплаты:</p>
                    <InputDate
                      dateValue={earlyPaymentDate}
                      minDate={getFirstDate()}
                      maxDate={getMaxDate()}
                      lastDate={getLastDate(firstBasePaymentDate)}
                      disabledDays={isDayDisabled}
                      highlightDates={[new Date()]}
                      onDateChange={date =>
                        handleDateChange('earlyPaymentDate', date)
                      }
                      placeholder="Дата ранней оплаты"
                    />
                  </DatesContainer>

                  <DatePopupBtns>
                    {!earlyPaymentDate ? (
                      <CreateBtn
                        style={{
                          backgroundColor: '#9FA6B2',
                          cursor: 'not-allowed'
                        }}
                      >
                        <CancelBtnText>Подтвердить</CancelBtnText>
                      </CreateBtn>
                    ) : (
                      <CreateBtn
                        onClick={() => {
                          postEarlyDatePick({
                            guid: item.guid,
                            body: { datePick: earlyPaymentDate }
                          });
                        }}
                      >
                        <CreateBtnContainer
                          onClick={() => onPickEarlyDateConfirmClick(item.guid)}
                        >
                          <CancelBtnText>Подтвердить</CancelBtnText>
                        </CreateBtnContainer>
                      </CreateBtn>
                    )}
                    <div />
                    <CancelBtn onClick={() => onCancelClick(item.guid)}>
                      <CancelBtnText>Отмена</CancelBtnText>
                    </CancelBtn>
                  </DatePopupBtns>
                </DatePopup>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}

      <Pagination list={earlyPaymentApplications} />

      <Modal name={MODAL_NAMES.ERROR_EARLY_DATE_PICK}>
        <ErrorMessage>{errorPostEarlyDatePick.body}</ErrorMessage>
      </Modal>
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  earlyPaymentApplications:
    SCFSupplier.getEarlyPaymentApplicationsSupplierRole.data,
  status: SCFSupplier.getEarlyPaymentApplicationsSupplierRole.status,
  error: SCFSupplier.getEarlyPaymentApplicationsSupplierRole.error,
  statusPostEarlyDatePick: SCFSupplier.postEarlyDatePick.status,
  errorPostEarlyDatePick: SCFSupplier.postEarlyDatePick.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getEarlyPaymentApplications, postEarlyDatePick, openModal },
    dispatch
  );

const ApplicationsListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationsList)
);

export { ApplicationsListConnect as ApplicationsList };
