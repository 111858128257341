import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CRM, REQUEST_STATUSES, ResponseError } from 'src/globaltypes';
import { ApplicationViewStyled } from 'src/features/Application/components/ApplicationView/EachApplicationView/styles';
import {
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'src/features/Layouts/components';
import {
  AskSection,
  AskStyled,
  ProductCashbackBaseContainer,
  ProductExclusivityContainer,
  ProductSecuringClientsContainer,
  ProductStandardCashback,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes
} from 'src/features/CooperationForProducts/components/styled';
import { req as patchCooperation } from '../actions/patchCooperation';
import {
  Input,
  ProductItem,
  Square,
  Text
} from 'src/features/BasicParametersForProducts/components/styled';
import {
  CASHBACK_BASE,
  EXCLUSIVITY,
  SECURING_CLIENTS
} from 'src/features/CooperationForProducts/types';
import { ProductRead } from 'src/features/Products/actions/setProductFormData';
import { Button } from 'shared/ui/Button';

interface AnyProps {
  product: ProductRead;
  roles: string[];
  permissions: string[];
}

interface State {
  isInPlatform: boolean | null;
  hasContract: boolean | null;
  hasCashback: boolean | null;
  standardCashback: any;
  activeStandardCashback: boolean;
  clientControl: boolean | null;
  exclusivity: EXCLUSIVITY;
  activeExclusivity: boolean;
  clientAnchoring: SECURING_CLIENTS;
  activeClientAnchoring: boolean;
  cashbackBase: number | CASHBACK_BASE;
  activeCashbackBase: boolean;
  isAdminStandardCashback: boolean;
  isAdminCashbackBase: boolean;
  isAdminExclusivity: boolean;
  isAdminClientAnchoring: boolean;
}

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  patchCooperation: (id: string, data: any) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  DispatchToProps &
  StateToProps &
  AnyProps;

class CooperationForProducts extends React.Component<Props, State> {
  state: State = {
    isInPlatform: null,
    hasContract: null,
    hasCashback: null,
    standardCashback: null,
    activeStandardCashback: false,
    clientControl: null,
    exclusivity: null,
    activeExclusivity: false,
    clientAnchoring: null,
    activeClientAnchoring: false,
    cashbackBase: null,
    activeCashbackBase: false,
    isAdminStandardCashback: false,
    isAdminCashbackBase: false,
    isAdminExclusivity: false,
    isAdminClientAnchoring: false
  };

  componentDidMount() {
    this.setState({ ...this.props.product } as any);

    if (this.props.product.exclusivity !== null) {
      this.setState({
        exclusivity: this.props.product.exclusivity,
        activeExclusivity: true
      });
    }
    if (this.props.product.clientAnchoring !== null) {
      this.setState({
        clientAnchoring: this.props.product.clientAnchoring,
        activeClientAnchoring: true
      });
    }
    if (this.props.product.standardCashback !== null) {
      this.setState({
        standardCashback: this.props.product.standardCashback,
        activeStandardCashback: true
      });
    }
    if (this.props.product.cashbackBase !== null) {
      this.setState({
        cashbackBase: this.props.product.cashbackBase + 1,
        activeCashbackBase: true
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.standardCashback !== this.state.standardCashback) {
      this.setState({ isAdminStandardCashback: false });
    }
    if (prevState.cashbackBase !== this.state.cashbackBase) {
      this.setState({ isAdminCashbackBase: false });
    }
    if (prevState.exclusivity !== this.state.exclusivity) {
      this.setState({ isAdminExclusivity: false });
    }
    if (prevState.clientAnchoring !== this.state.clientAnchoring) {
      this.setState({ isAdminClientAnchoring: false });
    }
  }

  handleButtonClick = (key: keyof State, value: boolean) => {
    const { id } = this.props.match.params;
    const values = {
      activeExclusivity: { exclusivity: this.state.exclusivity },
      activeClientAnchoring: { clientAnchoring: this.state.clientAnchoring },
      activeStandardCashback: {
        standardCashback: +this.state.standardCashback
      },
      activeCashbackBase: {
        cashbackBase: this.state.cashbackBase
          ? +this.state.cashbackBase[0]
          : undefined
      }
    };
    const patchParams = values[key] || { [key]: value };
    this.setState({ [key]: value } as Pick<any, keyof State>);
    this.props.patchCooperation(id, patchParams);
  };

  handleProductExclusivityClick = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeExclusivity || isAdmin) {
      this.setState({ exclusivity: item });
    }
  };

  handleProductSecuringClients = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeClientAnchoring || isAdmin) {
      this.setState({ clientAnchoring: item });
    }
  };

  handleProductCashbackBase = item => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    if (!this.state.activeCashbackBase || isAdmin) {
      this.setState({ cashbackBase: item });
    }
  };

  renderToggleButton = (buttonName: keyof State) => {
    const { roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    return (
      <ToggleButtonContainer>
        <ToggleButtonNo
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, false)
          }
          active={this.state[buttonName]}
          disabled={!isAdmin && this.state[buttonName] === true}
          isAdmin={isAdmin}
        >
          нет
        </ToggleButtonNo>
        <ToggleButtonYes
          onClick={() =>
            (this.state[buttonName] === null || isAdmin) &&
            this.handleButtonClick(buttonName, true)
          }
          active={this.state[buttonName]}
          disabled={!isAdmin && this.state[buttonName] === false}
          isAdmin={isAdmin}
        >
          да
        </ToggleButtonYes>
      </ToggleButtonContainer>
    );
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value } as any);
  };

  render() {
    const { status, error, roles, permissions } = this.props;
    const isAdmin = roles.includes('Admin') || permissions.includes('Admin');

    return (
      <ApplicationViewStyled>
        <AskSection>
          <AskStyled>
            Банк принимает заявки через платформу FINFACTORY?
          </AskStyled>
          {this.renderToggleButton('isInPlatform')}
        </AskSection>

        <AskSection>
          <AskStyled>Есть договор с банком?</AskStyled>
          {this.renderToggleButton('hasContract')}
        </AskSection>

        <AskSection>
          <AskStyled>Есть кэшбек?</AskStyled>
          {this.renderToggleButton('hasCashback')}
        </AskSection>

        <ProductStandardCashback>
          <p>Стандартный кэшбек (%):</p>
          <Input
            active={this.state.activeStandardCashback}
            name="standardCashback"
            value={
              this.state.activeStandardCashback && !isAdmin
                ? `${this.state.standardCashback} %.`
                : this.state.standardCashback
            }
            onChange={this.handleInputChange}
            disabled={!isAdmin && this.state.activeStandardCashback}
          />
          {(!this.state.activeStandardCashback || isAdmin) &&
            !!this.state.standardCashback &&
            this.state.standardCashback !==
              this.props.product.standardCashback &&
            !this.state.isAdminStandardCashback && (
              <Button
                label="Сохранить"
                onClick={() => {
                  if (isAdmin) {
                    this.setState({ isAdminStandardCashback: true });
                  }
                  this.handleButtonClick('activeStandardCashback', true);
                }}
                disabled={!/^\d+$/.test(this.state.standardCashback)}
                h="40px"
              />
            )}
        </ProductStandardCashback>

        <ProductCashbackBaseContainer
          product={this.state.cashbackBase}
          active={this.state.activeCashbackBase}
        >
          <AskStyled>База кэшбека:</AskStyled>
          {Object.keys(CASHBACK_BASE).map(item => (
            <div key={item}>
              <ProductItem onClick={() => this.handleProductCashbackBase(item)}>
                <Square
                  active={
                    this.state.cashbackBase === item ||
                    this.state.cashbackBase == item[0]
                  }
                />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.cashbackBase === item &&
                (!this.state.activeCashbackBase || isAdmin) &&
                !this.state.isAdminCashbackBase && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeCashbackBase}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminCashbackBase: true });
                      }
                      this.handleButtonClick('activeCashbackBase', true);
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </ProductCashbackBaseContainer>

        <ProductExclusivityContainer
          product={this.state.exclusivity}
          active={this.state.activeExclusivity}
        >
          <AskStyled>Эксклюзивность:</AskStyled>
          {Object.keys(EXCLUSIVITY).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductExclusivityClick(item)}
              >
                <Square active={this.state.exclusivity === item} />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.exclusivity === item &&
                (!this.state.activeExclusivity || isAdmin) &&
                this.state.exclusivity !== this.props.product.exclusivity &&
                !this.state.isAdminExclusivity && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeExclusivity}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminExclusivity: true });
                      }
                      this.handleButtonClick('activeExclusivity', true);
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </ProductExclusivityContainer>

        <ProductSecuringClientsContainer
          product={this.state.clientAnchoring}
          active={this.state.activeClientAnchoring}
        >
          <AskStyled>Закрепление клиентов:</AskStyled>
          {Object.keys(SECURING_CLIENTS).map(item => (
            <div key={item}>
              <ProductItem
                onClick={() => this.handleProductSecuringClients(item)}
              >
                <Square active={this.state.clientAnchoring === item} />
                <Text>{item}</Text>
              </ProductItem>
              {this.state.clientAnchoring === item &&
                (!this.state.activeClientAnchoring || isAdmin) &&
                this.state.clientAnchoring !==
                  this.props.product.clientAnchoring &&
                !this.state.isAdminClientAnchoring && (
                  <Button
                    label="Сохранить"
                    disabled={!isAdmin && this.state.activeClientAnchoring}
                    onClick={() => {
                      if (isAdmin) {
                        this.setState({ isAdminClientAnchoring: true });
                      }
                      this.handleButtonClick('activeClientAnchoring', true);
                    }}
                    h="40px"
                  />
                )}
            </div>
          ))}
        </ProductSecuringClientsContainer>

        <AskSection>
          <AskStyled>Возможность контроля выдачи клиенту:?</AskStyled>
          {this.renderToggleButton('clientControl')}
        </AskSection>

        <ActionResponseBlock
          showIn={error.code === 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.FORBIDDEN}
        />

        <ActionResponseBlock
          showIn={error.code !== 403 && status === REQUEST_STATUSES.ERROR}
          template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
        />
      </ApplicationViewStyled>
    );
  }
}

const mapStateToProps = ({ Cooperation }: CRM) => ({
  status: Cooperation.patchCooperation.status,
  error: Cooperation.patchCooperation.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ patchCooperation }, dispatch);

const CooperationForProductsConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CooperationForProducts)
);

export { CooperationForProductsConnect as CooperationForProducts };
