import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type AddListOfLimitsStoreState = Reducer;

export enum ADD_LIST_OF_LIMITS {
  REQ = 'ADD_LIST_OF_LIMITS_REQ',
  GOT = 'ADD_LIST_OF_LIMITS_GOT',
  ERR = 'ADD_LIST_OF_LIMITS_ERR'
}

export interface RequestDataType {
    innList: string;
}

export const initialState: AddListOfLimitsStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const addListOfLimits = (
  state: AddListOfLimitsStoreState = initialState,
  action: {
    type: ADD_LIST_OF_LIMITS;
    error: {};
  }
): AddListOfLimitsStoreState => {
  switch (action.type) {
    case ADD_LIST_OF_LIMITS.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case ADD_LIST_OF_LIMITS.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case ADD_LIST_OF_LIMITS.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default addListOfLimits;
