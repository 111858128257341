import styled from 'styled-components';
import { Checkbox } from 'shared/ui/Checkbox';
import { blue, lightGray, rr } from 'shared/styled';
import { TableRowStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { PopupContent } from 'src/features/Contacts/components/ContactsList/styled';
import { Button } from 'shared/ui/Button';

export const ButtonStyle = styled(Button)`
  width: 400px;

  background-color: ${({ notValid }) => notValid && lightGray};

  :hover {
    background-color: ${({ notValid }) => notValid && lightGray} !important;
  }
`;

export const TooltipBoxStyle = styled.div`
  position: relative;
  height: 19px;

  img {
    height: 19px;
  }

  ${({ isWarning, text }) =>
    isWarning &&
    `
    &:hover:before {
      content: '${text}';
      position: absolute;
      width: max-content;
      font-family: ${rr}
      bottom: 20px;
      left: 275px;
      z-index: 100;
      background-color: ${lightGray};
      color: black;
      padding: 10px;
      border-radius: 4px;
    }
  `};
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > div {
    min-width: 400px;
  }

  & > button {
    min-width: fit-content;
    height: 40px;
    margin-top: 20px;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ClientList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0;
  overflow: auto;
`;

export const PopupContentClients = styled(PopupContent)`
  width: 550px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  h2 {
    margin-bottom: 5px;
  }

  *:nth-last-child(1) {
    margin-top: auto;
  }

  button {
    width: fit-content;
    width: 170px;
    height: 20px;
    font-size: 14px;
    justify-content: center;
  }
`;

export const TableRow = styled(TableRowStyled)`
  background-color: ${({ isSelected }) => isSelected && blue};
  pointer-events: ${({ isSelected }) => isSelected && 'none'};

  & > td {
    color: ${({ isSelected }) => isSelected && 'white'};
  }
`;
