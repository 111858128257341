import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData as ReqCertificates } from 'entities/Cabinet/InternalAgent/model/actions/getCertificates';
import { RequestData as ReqTenderUpdateByInternal } from 'entities/Cabinet/InternalAgent/model/actions/patchTenderUpdateByInternal';
import { RequestData as ReqSetDiscountMargin } from 'entities/Cabinet/InternalAgent/model/actions/patchSetDiscountMargin';
import ApiUploadFiles from 'src/shared/utils/ApiUploadFiles';

export default class InternalAgentApi {
  static getActiveDDSOfDebtor(inn: string) {
    return ApiRequest(`/api/discounting_settings/internal_role/${inn}/current`);
  }

  static getCertificates(data: ReqCertificates) {
    return ApiRequest(`/api/certificates/all`, 'get', data);
  }

  static getUserStats() {
    return ApiRequest('/api/users/internal_role/stats');
  }

  static getAllDocumentForms(data) {
    return ApiRequest('/api/document_forms/internal_role/all', 'get', data);
  }

  static getInternalFormFile(guid: string) {
    return ApiRequest(
      `/api/document_forms/internal_role/${guid}/get_form_file`
    );
  }

  static postDocumentsFormFill(guid: string, companyInn: string) {
    return ApiRequest(
      `/api/document_forms/internal_role/${guid}/fill`,
      'post',
      { companyInn }
    );
  }

  static getFactoringLimits(inn: string) {
    return ApiRequest(`/api/factoring_limit/check_available_limit/${inn}`);
  }

  static patchTenderUpdateByInternal(
    id: string,
    data: ReqTenderUpdateByInternal
  ) {
    return ApiRequest(`/api/tender/internal_role/${id}`, 'patch', data);
  }

  static patchSetDiscountMargin(id: number, data: ReqSetDiscountMargin) {
    return ApiRequest(
      `/api/buyers_to_suppliers_link/internal_role/${id}/set_margin`,
      'put',
      data
    );
  }

  static getCheckSignature(data: FormData) {
    return ApiUploadFiles(`/api/utilities/internal_role/check_signature`, data);
  }
}
