import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/patchCabinetSetAvailableRegionAll';

import { PATCH_CABINET_SET_AVAILABLE_REGION_ALL } from '../reducers/patchCabinetSetAvailableRegionAll';
import CustomerRequirementsApi from '../api';

function* patchCabinetSetAvailableRegionAll(action: {
  type: PATCH_CABINET_SET_AVAILABLE_REGION_ALL;
  id: string;
}): Generator {
  try {
    yield call(
      CustomerRequirementsApi.patchCabinetSetAvailableRegionAll,
      action.id
    );

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* patchCabinetSetAvailableRegionAllSaga(): Generator {
  yield takeLatest(
    PATCH_CABINET_SET_AVAILABLE_REGION_ALL.REQ,
    patchCabinetSetAvailableRegionAll
  );
}
