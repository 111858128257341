import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { Loader } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getSupplierAuction,
  reset as resetSupplierAuction,
  ResponseData,
  RequestData
} from 'src/features/SCF/actions/getSupplierAuction';
import {
  req as postAddToAuction,
  RequestData as ReqAddToAuction
} from 'src/features/SCF/actions/postAddToAuction';
import { req as deleteFromAuction } from 'src/features/SCF/actions/deleteFromAuction';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ActionAuction, ContainerFilter, NoData, Status } from './styled';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { Select } from 'shared/ui/Select';
import { gray } from 'shared/styled';

interface StateToProps {
  suppliers: ResponseData;
  status: REQUEST_STATUSES;
  statusAddToAuction: REQUEST_STATUSES;
  statusDeleteFromAuction: REQUEST_STATUSES;
}

interface DispatchToProps {
  getSupplierAuction: (guid: string, data?: RequestData) => void;
  resetSupplierAuction: () => void;
  postAddToAuction: (guid: string, data: ReqAddToAuction) => void;
  deleteFromAuction: (guid: string, inn: string) => void;
}

interface MatchParams {
  guid: string;
  tab?: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const StatusTypes = {
  DEFAULT: 'Показать все',
  ADDED: 'Добавлен к аукциону',
  DELETED: 'Исключен из аукциона'
};

const AuctionSupplier: React.FC<Props> = ({
  getSupplierAuction,
  resetSupplierAuction,
  suppliers,
  status,
  postAddToAuction,
  statusAddToAuction,
  deleteFromAuction,
  statusDeleteFromAuction,
  match,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [supplierName, setSupplierName] = React.useState('');
  const [supplierStatus, setSupplierStatus] = React.useState('');

  const [isFiltersVisible, setIsFiltersVisible] = React.useState(false);
  const [counterInitial, setCounterInitial] = React.useState(false);

  React.useEffect(() => {
    return () => resetSupplierAuction();
  }, []);

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.GOT && counterInitial === false) {
        setCounterInitial(true);
        if (suppliers && suppliers.items.length > 0) {
          setIsFiltersVisible(true);
        } else {
          setIsFiltersVisible(false);
        }
      }
    },
    [status, counterInitial]
  );

  React.useEffect(
    () => {
      if (match.params.guid) {
        const searchParams = new URLSearchParams(location.search);
        const supplierInn = searchParams.get('supplierInn') || '';
        const supplierName = searchParams.get('supplierName') || '';
        const supplierStatus = searchParams.get('supplierStatus') as any;

        setSupplierInn(supplierInn);
        setSupplierName(supplierName);
        setSupplierStatus(supplierStatus);

        getSupplierAuction(match.params.guid, {
          ...(supplierInn && { supplierInn }),
          ...(supplierName && { supplier: supplierName }),
          ...(supplierStatus &&
            supplierStatus !== 'DEFAULT' && { status: supplierStatus })
        });
      }
    },
    [match.params.guid, history.location.search]
  );

  React.useEffect(
    () => {
      if (statusAddToAuction === REQUEST_STATUSES.GOT) {
        getSupplierAuction(match.params.guid);
      }
    },
    [statusAddToAuction]
  );

  React.useEffect(
    () => {
      if (statusDeleteFromAuction === REQUEST_STATUSES.GOT) {
        getSupplierAuction(match.params.guid);
      }
    },
    [statusDeleteFromAuction]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      {isFiltersVisible && (
        <ContainerFilter>
          <Input
            value={supplierInn}
            label="ИНН Поставщика"
            name="supplierInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
          <Input
            value={supplierName}
            label="Наименование Поставщика"
            name="supplierName"
            onChange={onFilterChange}
          />
          <Select
            label="Статус"
            options={Object.keys(StatusTypes).map(type => ({
              id: type,
              name: StatusTypes[type]
            }))}
            name="supplierStatus"
            value={supplierStatus}
            onChange={onFilterChange}
          />
        </ContainerFilter>
      )}

      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT &&
        !!suppliers.items.length && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="15%">Поставщик</TableThStyled>
                  <TableThStyled width="15%">ИНН</TableThStyled>
                  <TableThStyled width="10%">Статус</TableThStyled>
                  <TableThStyled width="10%" />
                </tr>
              </TableHeaderStyled>
              <tbody>
                {suppliers.items.map(user => (
                  <TableRowStyled
                    key={user.guid}
                    color={user.supplierStatus === 1 && gray}
                  >
                    <td>{user.supplierName}</td>
                    <td>{user.supplierInn}</td>
                    <td>
                      <Status status={user.supplierStatus}>
                        {user.supplierStatus === 10
                          ? 'Участвует'
                          : user.supplierStatus === 1 && 'Исключен'}
                      </Status>
                    </td>
                    <td>
                      {user.supplierStatus === 1 ? (
                        <ActionAuction
                          onClick={() =>
                            postAddToAuction(match.params.guid, {
                              SupplierInn: user.supplierInn
                            })
                          }
                        >
                          Вернуть в аукцион
                        </ActionAuction>
                      ) : (
                        user.supplierStatus === 10 && (
                          <ActionAuction
                            onClick={() =>
                              deleteFromAuction(
                                match.params.guid,
                                user.supplierInn
                              )
                            }
                          >
                            Удалить из аукциона
                          </ActionAuction>
                        )
                      )}
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          </>
        )}

      {suppliers.items.length === 0 &&
        !isFiltersVisible && (
          <NoData>
            <div>
              <h2>ПОСТАВЩИКИ НЕ ДОБАВЛЕНЫ</h2>
              <p>
                поставщики добавляются автоматически при добавлении к аукциону
                денежных требований
              </p>
            </div>
          </NoData>
        )}
    </>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  suppliers: SCF.getSuppluerAuction.data,
  status: SCF.getSuppluerAuction.status,
  statusAddToAuction: SCF.postAddToAuction.status,
  statusDeleteFromAuction: SCF.deleteFromAuction.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplierAuction,
      resetSupplierAuction,
      postAddToAuction,
      deleteFromAuction
    },
    dispatch
  );

const AuctionSupplierConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AuctionSupplier)
);

export { AuctionSupplierConnect as AuctionSupplier };
