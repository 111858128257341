import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Document } from 'Application/types';
import { IFile } from '../InputFile/InputFile';
import { RequestDataType as DeleteDocumentRequestDataType } from 'Application/reducers/deleteDocument';
import { REQUEST_STATUSES } from 'globaltypes';
import { Error } from 'shared/ui/Error';
import {
  DropArea,
  FileUploadInfoBlock,
  FileUploadInfoBlockText,
  SingleFileLoaderBar,
  BigLabelWithTopMargin,
  InputSingleFileContainer,
  BigCrossBtn,
  SizeWarning
} from './styles';
import { OpenFileUploaderText } from 'shared/ui/InputFile/FileUploadInfoBlock/styles';
import { FileInfo } from 'shared/ui/InputSingleFile';

export interface InputSingleFileProps {
  file: Document;
  onFileUpload: (file: IFile) => void;
  onFileRemove: (data: DeleteDocumentRequestDataType) => void;
  className?: string;
  label?: string | JSX.Element;
  name?: string;
  required?: boolean;
  error?: string;
  uploadStatus?: REQUEST_STATUSES;
  maxSize?: number;
  accept?: string;
  dropAreaWidth?: number | string;
  renameFileTo?: string;
  metaInfo?: string; // TODO rename
}

export const InputSingleFileDefaultProps = {
  accept:
    'image/*, .pdf, .pptx, .zip, .rar, .doc, .docx, .xls, .xlsx, .txt, .tiff, .sgn',
  maxSize: 25 * 1000 * 1000
};

const InputSingleFile: React.FC<InputSingleFileProps> = ({
  file,
  required,
  label,
  className,
  uploadStatus,
  onFileUpload,
  onFileRemove,
  error,
  accept,
  dropAreaWidth,
  maxSize,
  renameFileTo,
  metaInfo
}) => {
  const renameFile = (file: any, newName: string) => {
    if (file) {
      const extension = file.name.substr(file.name.lastIndexOf('.') + 1);
      const name = newName + '.' + extension;
      return new File([file], name);
    }
  };

  const addMetaInfo = (file: any, metaInfo: string) => {
    if (file) {
      file.metaInfo = metaInfo;
      return file;
    }
  };

  return (
    <Dropzone
      disableClick
      accept={accept}
      maxSize={maxSize}
      multiple={false}
      onDrop={files =>
        onFileUpload(
          renameFileTo
            ? addMetaInfo(renameFile(files[0], renameFileTo), metaInfo)
            : files[0]
        )
      }
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        rejectedFiles,
        open
      }) => {
        const isFileTooLarge =
          rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

        return (
          <InputSingleFileContainer className={!!className ? className : ''}>
            <BigLabelWithTopMargin
              name={name}
              label={label}
              required={required}
            />
            <DropArea
              dropAreaWidth={dropAreaWidth ? dropAreaWidth : '325px'}
              name={name}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              {...getRootProps()}
              isEmpty={file}
            >
              <input {...getInputProps()} />
              {file ? (
                <FileUploadInfoBlock>
                  <FileInfo file={file} onFileRemove={onFileRemove} />
                  <BigCrossBtn
                    onClick={() =>
                      onFileRemove({ id: file.id, type: file.type })
                    }
                  />
                </FileUploadInfoBlock>
              ) : (
                <FileUploadInfoBlock withIcon={true}>
                  <FileUploadInfoBlockText>
                    Перетащите или{' '}
                    <OpenFileUploaderText onClick={open}>
                      добавьте
                    </OpenFileUploaderText>
                  </FileUploadInfoBlockText>
                </FileUploadInfoBlock>
              )}
            </DropArea>
            {isFileTooLarge && (
              <SizeWarning>
                Размер файла превышает допустимый, 25 Мб
              </SizeWarning>
            )}

            <Error error={error} />
            <SingleFileLoaderBar
              status={uploadStatus}
              dropAreaWidth={dropAreaWidth ? dropAreaWidth : '325px'}
              processText="Загрузка файла..."
            />
          </InputSingleFileContainer>
        );
      }}
    </Dropzone>
  );
};

InputSingleFile.defaultProps = InputSingleFileDefaultProps;

export default InputSingleFile;
