import {
  SagaRequestDataType,
  UPLOAD_BORROWER_COMPANIES_DOCUMENTS
} from 'BorrowerCompanies/reducers/uploadBorrowerCompanyDocuments';

export const req = (data: SagaRequestDataType) => ({
  type: UPLOAD_BORROWER_COMPANIES_DOCUMENTS.REQ,
  data
});

export const got = () => ({
  type: UPLOAD_BORROWER_COMPANIES_DOCUMENTS.GOT
});

export const err = (error: {}) => ({
  type: UPLOAD_BORROWER_COMPANIES_DOCUMENTS.ERR,
  error
});


