export interface INNFilterData {
  inn: string;
}

export enum FILTER_INTERNAL_AGENT_ACTIONS {
  SET = 'SET_INN_FILTER_DATA',
}

export const initialState: INNFilterData = {
  inn: ""
};

const updateINNFilter = (
  state: INNFilterData = initialState,
  action: {
    type: FILTER_INTERNAL_AGENT_ACTIONS;
    inn: string;
  }
): INNFilterData => {
  switch (action.type) {
    case FILTER_INTERNAL_AGENT_ACTIONS.SET:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default updateINNFilter;
