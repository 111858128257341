import { CertificateGenitive } from '../../../../../types/certificate';
import { ResponseDataType } from '../../../../../actions/getCertificateGenitive';

export function getCertificateById(guid: string, response: ResponseDataType): CertificateGenitive | undefined {
  if (response && response.items) {
      const certificate = response.items.find(item => item.guid === guid);
      if (certificate) {
          return certificate;
      }
  }
  return undefined;
}
