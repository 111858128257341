import { combineReducers } from 'redux';
import getOpenLoanLimitsExternal, {
  GetOpenLoanLimitsExternalStoreState
} from './getOpenLoanLimitsExternal';

export interface PreApprovalReducers {
  getOpenLoanLimitsExternal: GetOpenLoanLimitsExternalStoreState;
}

export const PreApproval = combineReducers({
  getOpenLoanLimitsExternal
});
