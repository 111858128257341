import { GET_PRODUCT } from '../reducers/getProduct';
import { ProductWrite } from './setProductFormData';

export const req = (id: string) => ({
  type: GET_PRODUCT.REQ,
  id
});

export const got = (product: ProductWrite) => ({
  type: GET_PRODUCT.GOT,
  product
});

export const err = (error: {}) => ({
  type: GET_PRODUCT.ERR,
  error
});
