import styled from 'styled-components';
import { gray, rb, rr } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';
import { InnPopupStyled } from 'src/features/Layouts/components/InnPopup/styled';

export const ContainerInputs = styled.form`
  display: flex;
  align-items: flex-start;
  column-gap: 100px;
  margin: 40px 0 0 20px;
`;

export const WrapperInput = styled.div`
  max-width: 40%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & > p:first-child {
    font-size: 18px;
    color: ${gray};
    font-family: ${rb};
  }
`;

export const WrapperInputDetail = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 35% 1fr;

  span {
    font-size: 12px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
  }

  & > ${InnPopupStyled} {
    top: 50px;
  }
`;

export const ButtonSaveDetail = styled(ButtonStyled)`
  width: fit-content;
  height: auto;
  justify-content: center;
  margin-top: 20px;

  :disabled {
    background-color: #9fa6b2;
  }
`;
