import { ProductsData } from '../actions/setProductFormData';
import { RequestData as GetProductsListRequestData } from '../actions/getProductsList';
import ApiRequest from 'src/shared/utils/ApiRequest';
import { CalculateData } from 'src/features/Products/types';

export default class ProductsApi {
  static sendProductData(data: ProductsData) {
    return ApiRequest('/api/product', 'post', data);
  }

  static getProduct(id: string) {
    return ApiRequest(`/api/product/id/${id}`);
  }

  static getAllProducts(data: GetProductsListRequestData) {
    return ApiRequest('/api/product/all', 'get', data);
  }

  static getProductsByBank(bankInn: string) {
    return ApiRequest(`/api/product/bank/${bankInn}`);
  }

  static postCalculateRating(id: number, data: CalculateData) {
    return ApiRequest(`/api/product/${id}/calculate_rating`, 'post', data);
  }
}
