import { combineReducers } from 'redux';
import patchCooperation, { PatchCooperation } from './patchCooperation';

export interface CooperationReducers {
  patchCooperation: PatchCooperation;
}

export const Cooperation = combineReducers({
  patchCooperation
});
