import {
  RequestDataType,
  GET_APPLICATION_EXTERNAL
} from 'entities/Cabinet/ExternalAgent/model/reducers/getApplicationExternal';
import { ContractFinancingRead } from 'src/features/Application/types';

export const req = (data: RequestDataType) => ({
  type: GET_APPLICATION_EXTERNAL.REQ,
  data
});

export const got = (data: ContractFinancingRead) => ({
  type: GET_APPLICATION_EXTERNAL.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_APPLICATION_EXTERNAL.ERR,
  error
});

export const reset = () => ({
  type: GET_APPLICATION_EXTERNAL.RESET
});
