import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getLimits';

import { GET_LIMITS_BANK } from '../reducers/getLimits';
import LimitsApi from '../api';

function* getLimitsBank(action: { type: GET_LIMITS_BANK }): Generator {
  try {
    const res: any = yield call(LimitsApi.getLimits);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getLimitsBankSaga(): Generator {
  yield takeLatest(GET_LIMITS_BANK.REQ, getLimitsBank);
}
