import { GET_SUMMARY_PERSON } from 'src/features/Person/reducers/getSummaryPerson';
import { SummaryPerson } from '../types';

export const req = (inn: string) => ({
  type: GET_SUMMARY_PERSON.REQ,
  inn
});

export const got = (data: SummaryPerson) => ({
  type: GET_SUMMARY_PERSON.GOT,
  data
});

export const reset = () => ({
  type: GET_SUMMARY_PERSON.RESET
});

export const err = (error: string) => ({
  type: GET_SUMMARY_PERSON.ERR,
  error
});
