import { SupplyContract } from '../actions/setSupplyContract';

export interface SetSupplyContractStoreState extends Partial<SupplyContract> { }

export enum SET_SUPPLY_CONTRACT {
  SET = 'SET_SUPPLY_CONTRACT_SET',
  RESET = 'SET_SUPPLY_CONTRACT_RESET',
}

export const initialState: SetSupplyContractStoreState = {
  debtorInn: '',
  debtorName: '',
  contractName: '',
  contractNumber: '',
  contractStartDate: '',
  contractEndDate: null,
  isPerpetual: false,
};

const setSupplyContract = (
  state: SetSupplyContractStoreState = initialState,
  action: {
    type: string;
    data: SupplyContract;
  }
): SetSupplyContractStoreState => {
  switch (action.type) {
    case SET_SUPPLY_CONTRACT.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_SUPPLY_CONTRACT.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setSupplyContract;
