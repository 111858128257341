import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Factor/model/actions/SCFRegisterFactor';
import { SCF_REGISTER_FACTOR } from 'entities/SCF/Factor/model/reducers/SCFRegisterFactor';
import SCFApi from 'entities/SCF/Factor/api';

function* SCFRegisterFactor(action: {
  type: SCF_REGISTER_FACTOR;
  data: RequestDataType;
}) {
  try {
    yield call(SCFApi.registerFactor, action.data);
    yield put(got(action.data));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* SCFRegisterFactorSaga() {
  yield takeLatest(
    SCF_REGISTER_FACTOR.SCF_REGISTER_FACTOR_REQ,
    SCFRegisterFactor
  );
}
