import { REQUEST_STATUSES } from 'globaltypes';
import { ResponseData } from 'entities/Cabinet/InternalAgent/model/actions/getActiveDDSOfDebtor';

export interface GetActiveDDSOfDebtorStoreState {
  [inn: string]: {
    status: REQUEST_STATUSES;
    data: ResponseData | null;
    error: {};
  };
}

export enum GET_ACTIVE_DDS_OF_DEBTOR {
  REQ = 'GET_ACTIVE_DDS_OF_DEBTOR_REQ',
  GOT = 'GET_ACTIVE_DDS_OF_DEBTOR_GOT',
  ERR = 'GET_ACTIVE_DDS_OF_DEBTOR_ERR'
}

const initialState: GetActiveDDSOfDebtorStoreState = {};

const getActiveDDSOfDebtor = (
  state: GetActiveDDSOfDebtorStoreState = initialState,
  action: {
    type: GET_ACTIVE_DDS_OF_DEBTOR;
    inn: string;
    data: ResponseData;
    error: {};
  }
): GetActiveDDSOfDebtorStoreState => {
  switch (action.type) {
    case GET_ACTIVE_DDS_OF_DEBTOR.REQ:
      return {
        ...state,
        [action.inn]: {
          status: REQUEST_STATUSES.REQUEST,
          data: null,
          error: {}
        }
      };
    case GET_ACTIVE_DDS_OF_DEBTOR.GOT:
      return {
        ...state,
        [action.inn]: {
          status: REQUEST_STATUSES.GOT,
          data: action.data || null,
          error: {}
        }
      };
    case GET_ACTIVE_DDS_OF_DEBTOR.ERR:
      return {
        ...state,
        [action.inn]: {
          status: REQUEST_STATUSES.ERROR,
          data: null,
          error: action.error || {}
        }
      };
    default:
      return state;
  }
};

export default getActiveDDSOfDebtor;
