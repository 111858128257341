import * as React from 'react';
import { TableHeaderStyled } from './styles';

interface Props {
  headers: Array<string | JSX.Element>;
}

export const TableHeader: React.FC<Props> = ({ headers }: Props) => (
        <TableHeaderStyled>
            <tr>
                {headers.map((item, key) => (
                    <th key={key}>{item}</th>
                ))}
            </tr>
        </TableHeaderStyled>);
