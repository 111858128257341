import { combineReducers } from 'redux';
import patchPledge, { PatchPledge } from './patchPledge';
import patchCabinetPledge, { PatchCabinetPledge } from './patchCabinetPledge';

export interface PledgeReducers {
  patchPledge: PatchPledge;
  patchCabinetPledge: PatchCabinetPledge;
}

export const Pledge = combineReducers({
  patchPledge,
  patchCabinetPledge
});
