import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from '../actions/getFactoringLimits';
import { GET_MY_LIMITS } from '../reducers/getFactoringLimits';
import SCFApi from '../api';

function* getMyLimits(action: {
  type: GET_MY_LIMITS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(SCFApi.getMyLimits, action.data);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getMyLimitsSaga() {
  yield takeLatest(
    GET_MY_LIMITS.GET_MY_LIMITS_REQ,
    getMyLimits
  );
}
