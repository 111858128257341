import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export interface GetContractFileStoreState extends Reducer {}

export enum GET_CONTRACT_FILE {
  REQ = 'GET_CONTRACT_FILE_REQ',
  GOT = 'GET_CONTRACT_FILE_GOT',
  ERR = 'GET_CONTRACT_FILE_ERR'
}

export const initialState: GetContractFileStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const getContractFile = (
  state: GetContractFileStoreState = initialState,
  action: {
    type: GET_CONTRACT_FILE;
    error: {};
  }
): GetContractFileStoreState => {
  switch (action.type) {
    case GET_CONTRACT_FILE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_CONTRACT_FILE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case GET_CONTRACT_FILE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getContractFile;
