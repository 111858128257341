import * as React from 'react';
import Popup from 'reactjs-popup';
import { DifferenceBetweenLeadsAndApplicationsInfoTrigger, DifferenceBetweenLeadsAndApplicationsInfoContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faInfo, faInfoCircle, faArrowsAltV } from '@fortawesome/free-solid-svg-icons';

interface DifferenceBetweenLeadsAndApplicationsInfoPopupProps {

}

const DifferenceBetweenLeadsAndApplicationsInfoPopup: React.FC<DifferenceBetweenLeadsAndApplicationsInfoPopupProps> =
    (props) => (
        <Popup position="right" trigger={<DifferenceBetweenLeadsAndApplicationsInfoTrigger>
          <FontAwesomeIcon icon={faArrowsAltV} fixedWidth={true} />
            В чем разница?
          <FontAwesomeIcon icon={faArrowsAltV} fixedWidth={true} />
      </DifferenceBetweenLeadsAndApplicationsInfoTrigger>}>
            <DifferenceBetweenLeadsAndApplicationsInfoContainer>
              <p>Создавая сделку, всё взаимодействие с клиентом и работу на платформе осуществляете Вы сами.</p>
              <p>Создавая лид, Вы передаете контакт клиента на платформу и делегируете ведение заявки клиента менеджеру FINFACTORY</p>
            </DifferenceBetweenLeadsAndApplicationsInfoContainer>
        </Popup>
    );

export default DifferenceBetweenLeadsAndApplicationsInfoPopup;
