import * as React from 'react';
import { SFCLinkButtonStyled } from './styles'
import { history } from 'src/shared/utils/History';


interface Props{
    type:string  | JSX.Element;
    onClick?: () => void;
  }

  const SupplierButton: React.FC<Props> = ({
    type,
    onClick}) => (
      <SFCLinkButtonStyled>
        <a onClick={() => history.push('/scf/supplier/login')}>
          <h1>Кабинет</h1>
          <h1>ПОСТАВЩИКА</h1>
        </a>

      </SFCLinkButtonStyled>);

  export default SupplierButton;
