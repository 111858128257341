import styled from 'styled-components';
import { INNFilterContainerStyled } from 'src/features/Filters/INNFilter/components/styled';

export const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
`;

export const InnInputContainer = styled(INNFilterContainerStyled)``;
