import { GET_MONETARY_CLAIMS_LIST } from '../reducers/getMonetaryClaimsList';
import { MonetaryClaimsListType } from '../types';

export interface ResponseData {
  page: number;
  pageSize: number;
  totalItems: number;
  fullSum: number;
  availableChecks: number;
  items: MonetaryClaimsListType[];
}

export interface RequestType {
  page: number;
  pageSize?: number;
  totalItems?: number;
  fullSum?: number;
  availableChecks?: number;
  startDate?: string;
  endDate?: string;
  debtorInn?: string;
  supplierInn?: string;
}

export const req = (data: RequestType) => ({
  type: GET_MONETARY_CLAIMS_LIST.REQ,
  data
});

export const got = (data: ResponseData) => ({
  type: GET_MONETARY_CLAIMS_LIST.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIMS_LIST.ERR,
  error
});
