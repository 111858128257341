import { FinbanksData } from '../actions/setFinbankFormData';

export interface SetFinbankDataStoreState extends Partial<FinbanksData> {}

export enum SET_FINBANK_DATA {
  SET = 'SET_FINBANK_DATA_SET',
  RESET = 'SET_FINBANK_DATA_RESET',
}

export const initialState: SetFinbankDataStoreState = {
  //
};

const setFinbankFormData = (
  state: SetFinbankDataStoreState = initialState,
  action: {
    type: string;
    data: FinbanksData;
  }
): SetFinbankDataStoreState => {
  switch (action.type) {
    case SET_FINBANK_DATA.SET:
      return {
        ...state,
        ...action.data
      };
    case SET_FINBANK_DATA.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default setFinbankFormData;
