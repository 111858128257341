function ApiPutFiles(url: string, data?) {
  return new Promise((resolve, reject) => {
    const params: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      body: data
    };

    fetch(url, params)
      .then(async response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data);
          });
        } else {
          const errorResponse = {
            code: response.status,
            message: response.statusText,
            body: await response.json()
          };
          reject(errorResponse);
        }
      })
      .catch(error => reject({ error }));
  });
}

export default ApiPutFiles;
