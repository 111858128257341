import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postExternalApiAlfaBank';

import { POST_EXTERNAL_API_ALFA_BANK_LEAD } from '../reducers/postExternalApiAlfaBank';
import ApplicationApi from '../api';

function* postExternalApiAlfaBankLead(action: {
  type: POST_EXTERNAL_API_ALFA_BANK_LEAD;
  LeadID: number;
}): Generator {
  try {
    yield call(ApplicationApi.postExternalApiAlfaBank, action.LeadID);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postExternalApiAlfaBankLeadSaga(): Generator {
  yield takeLatest(
    POST_EXTERNAL_API_ALFA_BANK_LEAD.REQ,
    postExternalApiAlfaBankLead
  );
}
