import {
  RequestDataType,
  PUT_EARLY_PAYMENT_APPLICATION_DECLINE
} from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';

export const req = (data: RequestDataType, reqType?: 'array' | null) => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE.REQ,
  reqType,
  data
});

export const got = (data?: { success: boolean }[]) => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE.GOT,
  data
});

export const err = (error: {}) => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE.ERR,
  error
});

export const reset = () => ({
  type: PUT_EARLY_PAYMENT_APPLICATION_DECLINE.RESET
});
