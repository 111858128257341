import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type PostConfirmContractBySupplierStoreState = Reducer;

export enum POST_CONFIRM_CONTRACT_BY_SUPPLIER {
  REQ = 'POST_CONFIRM_CONTRACT_BY_SUPPLIER_REQ',
  GOT = 'POST_CONFIRM_CONTRACT_BY_SUPPLIER_GOT',
  ERR = 'POST_CONFIRM_CONTRACT_BY_SUPPLIER_ERR'
}

export const initialState: PostConfirmContractBySupplierStoreState = {
  status: REQUEST_STATUSES.REQUEST,
  error: {}
};

const postConfirmContractBySupplier = (
  state: PostConfirmContractBySupplierStoreState = initialState,
  action: {
    type: POST_CONFIRM_CONTRACT_BY_SUPPLIER;
    error: {};
  }
): PostConfirmContractBySupplierStoreState => {
  switch (action.type) {
    case POST_CONFIRM_CONTRACT_BY_SUPPLIER.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case POST_CONFIRM_CONTRACT_BY_SUPPLIER.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case POST_CONFIRM_CONTRACT_BY_SUPPLIER.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default postConfirmContractBySupplier;
