import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { DebtorRead } from '../actions/setDebtorFormData';

export interface GetDebtorStoreState extends Reducer {
  debtor: DebtorRead;
}

export enum GET_DEBTOR {
  REQ = 'GET_DEBTOR_REQ',
  GOT = 'GET_DEBTOR_GOT',
  ERR = 'GET_DEBTOR_ERR'
}

export const initialState: GetDebtorStoreState = {
  status: REQUEST_STATUSES.NONE,
  debtor: {
    inn: ""
  },
  error: {}
};

const getDebtor = (
  state: GetDebtorStoreState = initialState,
  action: {
    type: string;
    debtor: DebtorRead;
    error: {};
  }
): GetDebtorStoreState => {
  switch (action.type) {
    case GET_DEBTOR.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case GET_DEBTOR.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
        debtor: action.debtor
      };
    case GET_DEBTOR.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default getDebtor;
