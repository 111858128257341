import styled from 'styled-components';
import { BoxStyled } from 'shared/styled';
import { blue, gray, rr } from 'shared/styled';

export const TitleStyled = styled.h2`
  margin-bottom: 32px;
`;

export const LoginBox = styled(BoxStyled)`
  width: 490px;
`;

export const SectionPickerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  width: 15%;

  display: flex;
  flex-direction: column;
  row-gap: 25px;

  div {
    &:not(:first-of-type) {
      cursor: pointer;
      p,
      h2 {
        color: ${gray};
      }
      &:hover > p,
      &:hover > h2 {
        color: ${blue};
      }
    }
  }

  h2 {
    font-family: ${rr};
  }
`;
