import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export enum RESET_PASSWORD {
  RESET_PASSWORD_REQ = 'RESET_PASSWORD_REQ',
  RESET_PASSWORD_GOT = 'RESET_PASSWORD_GOT',
  RESET_PASSWORD_ERR = 'RESET_PASSWORD_ERR'
}

export const initialState: Reducer = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const resetPassword = (
  state: Reducer = initialState,
  action: {
    type: string;
    error: {};
  }
): Reducer => {
  switch (action.type) {
    case RESET_PASSWORD.RESET_PASSWORD_REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case RESET_PASSWORD.RESET_PASSWORD_GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case RESET_PASSWORD.RESET_PASSWORD_ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default resetPassword;
