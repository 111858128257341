import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/postDebtorNotificationSettings';

import {
  POST_DEBTOR_NOTIFICATION_SETTINGS,
  RequestDataType
} from 'entities/SCF/Debtor/model/reducers/postDebtorNotificationSettings';

import SCFDebtorApi from 'entities/SCF/Debtor/api';

function* postDebtorNotificationSettings(action: {
  type: POST_DEBTOR_NOTIFICATION_SETTINGS;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFDebtorApi.postDebtorNotificationSettings,
      action.data
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postDebtorNotificationSettingsSaga() {
  yield takeLatest(
    POST_DEBTOR_NOTIFICATION_SETTINGS.REQ,
    postDebtorNotificationSettings
  );
}
