import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err
} from 'src/entities/Cabinet/Bank/model/actions/getDocumentList';
import { GET_DOCUMENT_LIST_BY_BANK } from 'entities/Cabinet/Bank/model/reducers/getDocumentList';

import ApplicationApi from 'entities/Cabinet/Bank/api';

function* getDocumentList(action: {
  type: GET_DOCUMENT_LIST_BY_BANK;
  guid: string;
}) {
  try {
    const res = yield call(ApplicationApi.getDocumentList, action.guid);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getDocumentListSaga() {
  yield takeLatest(GET_DOCUMENT_LIST_BY_BANK.REQ, getDocumentList);
}
