import { GET_ACTIVITIES_TYPE } from 'src/features/Activities/reducers/getActivitiesType';
import { ActivitiesTypes } from 'src/features/Activities/types';

export interface ResponseData {
  totalItems: number;
  page: number;
  pageSize: number;
  items: ActivitiesTypes[];
}

export const req = () => ({
  type: GET_ACTIVITIES_TYPE.REQ
});

export const got = (data: ResponseData) => ({
  type: GET_ACTIVITIES_TYPE.GOT,
  data
});

export const err = (error = {}) => ({
  type: GET_ACTIVITIES_TYPE.ERR,
  error
});
