import { FILTER_STATUSES_ACTIONS } from '../reducers/updateStatusesFilter';

export const addStatusFilter = (newStatus: string) => ({
  type: FILTER_STATUSES_ACTIONS.ADD,
  status: newStatus
});

export const removeStatusFilter = (indexToRemove: number) => ({
  type: FILTER_STATUSES_ACTIONS.REMOVE,
  index: indexToRemove
});
