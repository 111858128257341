import * as React from 'react';
import { Route } from 'react-router';
import SupplierButton from '../RoleButtons/SupplierButton';
import FactorButton from '../RoleButtons/FactorButton';
import DebtorButton from '../RoleButtons/DebtorButton';
import { AuthenticationPageSupplier } from 'pages/SCF/Supplier/components';
import { AuthenticationPageFactor } from 'pages/SCF/Factor/components';
import { AuthenticationPageDebtor } from 'pages/SCF/Debtor/components/AuthenticationPage';
import {
  SCFRegistrationPageSupplier,
  SCFRegistrationPageDebtor,
  SCFRegistrationPageFactor
} from 'src/features/SCF/components';
import { SFCPageStyled } from './styles';

export const SCFPage: React.FC = () => {
  return (
    <SFCPageStyled>
      <Route
        exact
        path={'/scf'}
        render={() => <SupplierButton type={'link'} />}
      />
      <Route
        exact
        path={'/scf'}
        render={() => <DebtorButton type={'link'} />}
      />
      <Route
        exact
        path={'/scf'}
        render={() => <FactorButton type={'link'} />}
      />

      <Route
        exact
        path={'/scf/supplier/login'}
        render={() => <AuthenticationPageSupplier />}
      />
      <Route
        exact
        path={'/scf/supplier/registration'}
        render={() => <SCFRegistrationPageSupplier />}
      />

      <Route
        exact
        path={'/scf/debtor/login'}
        render={() => <AuthenticationPageDebtor />}
      />
      <Route
        exact
        path={'/scf/debtor/registration'}
        render={() => <SCFRegistrationPageDebtor />}
      />

      <Route
        exact
        path={'/scf/factor/login'}
        render={() => <AuthenticationPageFactor />}
      />
      <Route
        exact
        path={'/scf/factor/registration'}
        render={() => <SCFRegistrationPageFactor />}
      />
    </SFCPageStyled>
  );
};
