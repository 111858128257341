import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/getFinancialDocuments';

import { GET_FINANCIAL_DOCUMENTS_EXTERNAL } from '../reducers/getFinancialDocuments';
import ClientsApi from '../api';

function* getFinancialDocumentsExternal(action: {
  type: GET_FINANCIAL_DOCUMENTS_EXTERNAL;
  inn: string;
}): Generator {
  try {
    const res: any = yield call(ClientsApi.getFinancialDocuments, action.inn);

    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFinancialDocumentsExternalSaga(): Generator {
  yield takeLatest(
    GET_FINANCIAL_DOCUMENTS_EXTERNAL.REQ,
    getFinancialDocumentsExternal
  );
}
