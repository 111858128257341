import styled from 'styled-components';

import { HeaderHeight } from 'Layouts/components/Header/styles';
import { media, BoxStyled } from 'shared/styled';

export const RegistrationPageStyled = styled.section`
  margin-top: ${HeaderHeight};

  h2 {
    margin-bottom: 30px;
  }
`;

export const RegistrationFormBox = styled(BoxStyled)`
  max-width: 490px;
  min-width: 400px;
  padding: 40px 60px;

  ${media.desktop`
    padding: 30px;
  `};

  ${media.tablet`
    width: 100%;
    max-width: auto;
    min-width: auto;
  `};
`;

export const InvitedUserInfo = styled.div`
  margin-top: 122px;

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
  }
`;

export const Container = styled.div`
  display: flex;
  column-gap: 50px;
`;
