import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from '../actions/getCompanyFastInfo';
import { GET_COMPANY_FAST_INFO } from '../reducers/getCompanyFastInfo';
import SCFApi from '../api';

function* getCompanyFastInfo(action: {
  type: GET_COMPANY_FAST_INFO;
  inn: string;
}) {
  try {
    const res: any = yield call(SCFApi.getCompanyFastInfo, action.inn);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getCompanyFastInfoSaga() {
  yield takeLatest(GET_COMPANY_FAST_INFO.REQ, getCompanyFastInfo);
}
