import { DELETE_PROPERTY } from '../reducers/deleteProperty';

export const req = (propertyId:number) => ({
  type: DELETE_PROPERTY.REQ,
  propertyId
});

export const got = () => ({
  type: DELETE_PROPERTY.GOT,
});

export const err = (error: {}) => ({
  type: DELETE_PROPERTY.ERR,
  error
});
