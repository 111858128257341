import { combineReducers } from 'redux';
import setExternalCredentialFormData, {
  SetExternalCredentialDataStoreState
} from './setExternalCredentialFormData';
import getExternalCredential, {
  GetExternalCredentialStoreState
} from './getExternalCredential';
import getCompanyByInn, { GetCompanyByInnStoreState } from './getCompanyByInn';

export interface ExternalCredentialsReducers {
  setExternalCredentialFormData: SetExternalCredentialDataStoreState;
  getExternalCredential: GetExternalCredentialStoreState;
  getCompanyByInn: GetCompanyByInnStoreState;
}

export const ExternalCredentials = combineReducers({
  setExternalCredentialFormData,
  getExternalCredential,
  getCompanyByInn
});
