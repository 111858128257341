import { POST_CREATE_AGREEMENT_SUPPLIER_ROLE } from 'src/features/SCF/reducers/postCreateAgreementSupplierRole';

export const req = (guid: string) => ({
  type: POST_CREATE_AGREEMENT_SUPPLIER_ROLE.REQ,
  guid
});

export const got = () => ({
  type: POST_CREATE_AGREEMENT_SUPPLIER_ROLE.GOT
});

export const err = (error: {}) => ({
  type: POST_CREATE_AGREEMENT_SUPPLIER_ROLE.ERR,
  error
});

export const reset = () => ({
  type: POST_CREATE_AGREEMENT_SUPPLIER_ROLE.RESET
});
