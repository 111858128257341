import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from 'src/features/ClientsForAgent/actions/postCreateClient';

export default class ClientForAgentApi {
  static getClients(agentInn: string) {
    return ApiRequest(`/api/companies_of_agents/all?agentinn=${agentInn}`);
  }

  static postCreateClient(data: RequestData) {
    return ApiRequest(`/api/companies_of_agents/create`, 'post', data);
  }
}
