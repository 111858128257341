import { call, put, takeLatest } from 'redux-saga/effects';

import {
  got,
  err,
  RequestDataType
} from 'entities/SCF/Supplier/model/actions/getFactoringConnectionsSupplierRole';
import { GET_FACTORING_CONNECTION_SUPPLIER_ROLE } from 'entities/SCF/Supplier/model/reducers/getFactoringConnectionsSupplierRole';
import SCFApi from 'entities/SCF/Supplier/api';

function* getFactoringConnectionsSupplierRole(action: {
  type: GET_FACTORING_CONNECTION_SUPPLIER_ROLE;
  data: RequestDataType;
}) {
  try {
    const res: any = yield call(
      SCFApi.getFactoringConnectionsSupplierRole,
      action.data
    );
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getFactoringConnectionsSupplierRoleSaga() {
  yield takeLatest(
    GET_FACTORING_CONNECTION_SUPPLIER_ROLE.GET_FACTORING_CONNECTION_SUPPLIER_ROLE_REQ,
    getFactoringConnectionsSupplierRole
  );
}
