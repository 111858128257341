import { call, put, takeLatest } from 'redux-saga/effects';
import {
  got,
  err
} from 'entities/SCF/Debtor/model/actions/postAddToContractByDebtor';

import { POST_ADD_TO_CONTRACT_BY_DEBTOR } from 'entities/SCF/Debtor/model/reducers/postAddToContractByDebtor';

import SCFApi from 'entities/SCF/Debtor/api';

function* postAddToContractByDebtor(action: {
  type: POST_ADD_TO_CONTRACT_BY_DEBTOR;
  guid: string;
  contractAccount: string;
}) {
  try {
    yield call(
      SCFApi.postAddToContractByDebtor,
      action.contractAccount,
      action.guid
    );
    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postAddToContractByDebtorSaga() {
  yield takeLatest(
    POST_ADD_TO_CONTRACT_BY_DEBTOR.REQ,
    postAddToContractByDebtor
  );
}
