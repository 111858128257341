import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData as ReqPostCompaniesList } from '../actions/postCompaniesList';
import { RequestData as ReqGetCompaniesOfCompanies } from '../actions/getCompaniesOfCompanies';
import { RequestData as ReqGetCompaniesList } from '../actions/getCompaniesList';

export default class CompaniesListApi {
  static getCompaniesList(data: ReqGetCompaniesList) {
    return ApiRequest('/api/companies_list/all', 'get', data);
  }

  static postCompaniesList(data: ReqPostCompaniesList) {
    return ApiRequest('/api/companies_list', 'post', data);
  }

  static getCompaniesListById(id: string) {
    return ApiRequest(`/api/companies_list/${id}`);
  }

  static getCompaniesOfCompanies(id: string, data: ReqGetCompaniesOfCompanies) {
    return ApiRequest(`/api/companies_of_companies_list/${id}`, 'get', data);
  }

  static postCompaniesOfCompanies(id: string, companies: string) {
    return ApiRequest(`/api/companies_of_companies_list/${id}/add`, 'post', {
      companies
    });
  }

  static postAllInListRefresh(id: string) {
    return ApiRequest(`/api/companies_of_companies_list/${id}/refresh`, 'post');
  }

  static getInteractionSummary(inn: string) {
    return ApiRequest(`/api/company/inn/${inn}/interaction_summary`);
  }
}
