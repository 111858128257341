import { Reducer, REQUEST_STATUSES } from 'globaltypes';

export type SendSignatureStoreState = Reducer;

export enum SEND_SIGNATURE {
  REQ = 'SEND_SIGNATURE_REQ',
  GOT = 'SEND_SIGNATURE_GOT',
  ERR = 'SEND_SIGNATURE_ERR'
}

export interface RequestDataType {
  id: number;
  signature: string;
  registryDocumentName: string;
}

export const initialState: SendSignatureStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendSignature = (
  state: SendSignatureStoreState = initialState,
  action: {
    type: SEND_SIGNATURE;
    error: {};
  }
): SendSignatureStoreState => {
  switch (action.type) {
    case SEND_SIGNATURE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_SIGNATURE.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT
      };
    case SEND_SIGNATURE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendSignature;
