import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendLeadData';

export interface SendLeadDataStoreState extends Reducer {}

export enum SEND_LEAD_DATA {
  REQ = 'SEND_LEAD_DATA_REQ',
  GOT = 'SEND_LEAD_DATA_GOT',
  ERR = 'SEND_LEAD_DATA_ERR'
}

export const initialState: SendLeadDataStoreState = {
  status: REQUEST_STATUSES.NONE,
  error: {}
};

const sendLeadData = (
  state: SendLeadDataStoreState = initialState,
  action: {
    type: string;
    data: RequestData
    error: {};
  }
): SendLeadDataStoreState => {
  switch (action.type) {
    case SEND_LEAD_DATA.REQ:
      return {
        ...state,
        ...action.data,
        status: REQUEST_STATUSES.REQUEST
      };
    case SEND_LEAD_DATA.GOT:
      return {
        ...state,
        status: REQUEST_STATUSES.GOT,
      };
    case SEND_LEAD_DATA.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };
    default:
      return state;
  }
};

export default sendLeadData;
