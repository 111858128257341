export const CreditingPledge = {
  HAS_PLEDGE: 'Есть',
  NO_PLEDGE: 'Нет'
};

export const CreditingGuarantor = {
  HAS_GUARANTOR: 'Да',
  NO_GUARANTOR: 'Нет'
};

export const CreditingCreditType = {
  CREDIT: 'Кредит',
  OVERDRAFT: 'Овердрафт',
  REVOLVING_CREDIT: 'Возобновляемая кредитная линия'
};

export const CreditingPledgeType = {
  REAL_ESTATE: 'Недвижимое имущество',
  MOVABLE_ESTATE: 'Движимое имущество',
  OTHER: 'Иное'
};

export const CreditingGuarantorType = {
  BENEFICIARY: 'Поручительства бенефициаров',
  COMPANIES: 'Поручительства других компаний группы'
};
