export enum FINBANK_LIST_TYPE {
  ALL = 'ALL'
}

export enum FINBANK_TABS {
  DEFAULT = 'Заявки в банке',
  INCOME_APPLICATIONS = 'Заявки от банка',
  CONTACTS = 'Контакты',
  PRODUCTS = 'Продукты',
  USERS = 'Пользователи',
  CLIENTS = 'Клиенты'
}

export enum CREDITOR_TYPE {
  ISBANK = 'Банк',
  ISMFO = 'МФО',
  ISSUPPORTFUND = 'Фонд поддержки',
  ISFACTOR = 'Факторинговая компания',
  ISLEASINGCOMPANY = 'Лизинговая компания',
  ISCORPORATE = 'Коммерческая компания'
}

export enum ACCREDITATION {
  FZ44 = '44-ФЗ',
  SPECIALACCOUNTS = 'Спецсчета'
}

export enum ACCREDITED_STATE_SUPPORT {
  MINFIN = 'МинФин',
  MINAGRO = 'Минсельхоз'
}
