import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { ResponseDataType } from 'entities/SCF/Supplier/model/actions/getStartRateSupplierRole';

export interface GetStartRateSupplierRoleStoreState extends Reducer {
  data: ResponseDataType;
}

export enum START_RATE_SUPPLIER_ROLE {
  REQ = 'START_RATE_SUPPLIER_ROLE_REQ',
  GOT = 'START_RATE_SUPPLIER_ROLE_GOT',
  ERR = 'START_RATE_SUPPLIER_ROLE_ERR'
}

const initialState: GetStartRateSupplierRoleStoreState = {
  status: REQUEST_STATUSES.NONE,
  data: {
    regData: null,
    bankAccountsData: null,
    certificateData: null,
    debtorData: null,
    supplyContractsData: null
  },
  error: {}
};

const getStartRateSupplierRole = (
  state: GetStartRateSupplierRoleStoreState = initialState,
  action: {
    type: string;
    data: ResponseDataType;
    error: {};
  }
): GetStartRateSupplierRoleStoreState => {
  switch (action.type) {
    case START_RATE_SUPPLIER_ROLE.REQ:
      return {
        ...state,
        status: REQUEST_STATUSES.REQUEST
      };

    case START_RATE_SUPPLIER_ROLE.GOT:
      return {
        ...state,
        data: action.data,
        status: REQUEST_STATUSES.GOT
      };

    case START_RATE_SUPPLIER_ROLE.ERR:
      return {
        ...state,
        status: REQUEST_STATUSES.ERROR,
        error: action.error
      };

    default:
      return state;
  }
};

export default getStartRateSupplierRole;
