import { Reducer, REQUEST_STATUSES } from 'globaltypes';
import { RequestData } from '../actions/sendCertificateData';

export interface SendCertificateDataStoreState extends Reducer { }

export enum SEND_CERTIFICATE_DATA {
    REQ = 'SEND_CERTIFICATE_DATA_REQ',
    GOT = 'SEND_CERTIFICATE_DATA_GOT',
    ERR = 'SEND_CERTIFICATE_DATA_ERR'
}

export const initialState: SendCertificateDataStoreState = {
    status: REQUEST_STATUSES.NONE,
    error: {}
};

const sendCertificateData = (
    state: SendCertificateDataStoreState = initialState,
    action: {
        type: string;
        data: RequestData
        error: {};
    }
): SendCertificateDataStoreState => {
    switch (action.type) {
        case SEND_CERTIFICATE_DATA.REQ:
            return {
                ...state,
                ...action.data,
                status: REQUEST_STATUSES.REQUEST
            };
        case SEND_CERTIFICATE_DATA.GOT:
            return {
                ...state,
                status: REQUEST_STATUSES.GOT,
            };
        case SEND_CERTIFICATE_DATA.ERR:
            return {
                ...state,
                status: REQUEST_STATUSES.ERROR,
                error: action.error
            };
        default:
            return state;
    }
};

export default sendCertificateData;