import { Document } from 'Application/types';

export enum PROPERTY_TYPES {
    NONE = "",
    COMMERCIAL_PROPERTY = "COMMERCIAL_PROPERTY",
    RESIDENTIAL_PROPERTY =  "RESIDENTIAL_PROPERTY"
}

export enum PROPERTY_SUB_TYPES {
    NONE = "NONE",
    FLAT =  "FLAT",
    HOUSE =  "HOUSE",
    TOWN_HOUSE =  "TOWN_HOUSE",
    LAND =  "LAND",
    OFFICE = "OFFICE",
    WAREHOUSE = "WAREHOUSE",
    RETAIL_SPACE = "RETAIL_SPACE",
    PRODUCTION_BUILDINGS = "PRODUCTION_BUILDINGS",
    HOTEL = "HOTEL",
}

export interface PropertyWrite {
    type: PROPERTY_TYPES;
    subType: PROPERTY_SUB_TYPES;
    region: string;
    address: string;
    price: number;
    hasEvaluation: boolean;
    isRented: boolean;
    applicationId: number;
}

export interface PropertyRead extends PropertyWrite {
  id?: number;
  documents?: Document[];
}
