import styled from 'styled-components';
import { BoxStyled } from 'shared/styled';
import { rr, rb, gray, navyBlue, darkBlack } from 'shared/styled';
import { ButtonStyled } from 'src/shared/ui/Button/styled';

export const ActionResponseBlockStyled = styled.div`
  height: inherit;

  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 48px;
    text-align: center;
    font-family: ${rr};
    color: ${darkBlack};
    white-space: pre-line;
  }

  .illustration {
    margin-bottom: 48px;
  }

  && ${BoxStyled} {
    width: 640px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      text-align: center;
    }

    h2 {
      font-family: ${rr};
      line-height: 1.5;
    }

    p {
      margin: 0;
      color: ${gray};
      line-height: 1.71;
      span {
        color: ${navyBlue};
        font-family: ${rb};
      }
      white-space: pre-line;
    }

    ${ButtonStyled} {
      width: 290px;
      margin-top: 20px;
    }
  }
`;
