import { call, put, takeLatest } from 'redux-saga/effects';

import { got, err } from 'Application/actions/getApplicationFlags';
import { GET_APPLICATION_FLAGS } from 'Application/reducers/getApplicationFlags';

import ApplicationApi from 'Application/api';

function* getApplicationFlags(action: { type: GET_APPLICATION_FLAGS }) {
  try {
    const res:any = yield call(ApplicationApi.getApplicationFlags);
    yield put(got(res));
  } catch (e) {
    yield put(err(e));
  }
}

export default function* getApplicationFlagsSaga() {
  yield takeLatest(GET_APPLICATION_FLAGS.REQ, getApplicationFlags);
}
