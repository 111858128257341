import { call, put, takeLatest } from 'redux-saga/effects';
import { got, err } from '../actions/postCreateForLead';

import { POST_CREATE_FOR_LEAD } from '../reducers/postCreateForLead';
import ApplicationApi from '../api';

function* postCreateForLead(action: {
  type: POST_CREATE_FOR_LEAD;
  id: string;
}): Generator {
  try {
    yield call(ApplicationApi.postCreateForLead, action.id);

    yield put(got());
  } catch (e) {
    yield put(err(e));
  }
}

export default function* postCreateForLeadSaga(): Generator {
  yield takeLatest(POST_CREATE_FOR_LEAD.REQ, postCreateForLead);
}
