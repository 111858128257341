import ApiRequest from 'src/shared/utils/ApiRequest';
import { RequestData } from '../actions/getCounterparties';

export default class CounterpartiesApi {
  static getCounterparties(data: RequestData) {
    return ApiRequest(`/api/buyers_to_suppliers_link/all`, 'get', data);
  }

  static getAgents(inn: string) {
    return ApiRequest(`/api/companies_of_agents/all?companyinn=${inn}`);
  }
}
