import {
  FACTORING_FORM_ACTIONS,
  SetFactoringApplicationType
} from 'Application/reducers/setFactoringFormData';

export const setFactoringFormData = (data: SetFactoringApplicationType) => ({
  type: FACTORING_FORM_ACTIONS.SET,
  data
});

export const resetFactoringFormData = () => ({
  type: FACTORING_FORM_ACTIONS.RESET
});
