import { POST_ALL_IN_LIST_REFRESH } from '../reducers/postAllInListRefresh';

export const req = (id: string) => ({
  type: POST_ALL_IN_LIST_REFRESH.REQ,
  id
});

export const got = () => ({
  type: POST_ALL_IN_LIST_REFRESH.GOT
});

export const err = (error: {}) => ({
  type: POST_ALL_IN_LIST_REFRESH.ERR,
  error
});
