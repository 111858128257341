import { POST_COMPANIES_LIST_CRM } from '../reducers/postCompaniesList';

export interface RequestData {
  Name: string;
  Type: string;
  CompanyInn: string;
  openForCompany: boolean;
  openForList: boolean;
}

export const req = (data: RequestData) => ({
  type: POST_COMPANIES_LIST_CRM.REQ,
  data
});

export const got = (id: string) => ({
  type: POST_COMPANIES_LIST_CRM.GOT,
  id
});

export const err = (error: {}) => ({
  type: POST_COMPANIES_LIST_CRM.ERR,
  error
});
