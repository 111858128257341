import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STORE, REQUEST_STATUSES } from 'globaltypes';
import {
  req as prepareForApplicationEditRequest,
  reset as prepareForApplicationEditReset
} from 'Application/actions/prepareForApplicationEdit';
import { RequestDataType as PrepareForApplicationEditDataType } from 'Application/reducers/prepareForApplicationEdit';
import { ApplicationProcess } from 'Application/components';
import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { PageWrapper } from 'shared/styled';

interface StateToProps {
  status: REQUEST_STATUSES;
}

interface DispatchToProps {
  prepareForApplicationEditRequest: (
    data: PrepareForApplicationEditDataType
  ) => void;
  prepareForApplicationEditReset: () => void;
}

interface MatchParams {
  id: string;
  inBankId: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationEdit: React.FC<Props> = ({
  prepareForApplicationEditRequest,
  prepareForApplicationEditReset,
  status,
  match,
  history
}) => {
  React.useEffect(() => {
    prepareForApplicationEditRequest({ id: getActualId() });

    return () => {
      prepareForApplicationEditReset();
    };
  }, []);

  const getActualId = () => {
    const { id, inBankId } = match.params;

    if (!!parseInt(inBankId, 10)) {
      return parseInt(inBankId, 10);
    } else if (id) {
      return parseInt(id, 10);
    }
  };

  const saveApplication = () => {
    const { id, inBankId } = match.params;

    const segments = location.pathname.split('/');
    const roleIndex = segments.indexOf('cabinet') + 1;
    const role = segments[roleIndex];

    !!parseInt(inBankId, 10)
      ? history.push(`/cabinet/${role}/application/${id}/${inBankId}`)
      : history.push(`/cabinet/${role}/application/${id}`);
  };

  return (
    <React.Fragment>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <ApplicationProcess cancelProcess={saveApplication} />
      )}

      {status === REQUEST_STATUSES.ERROR && (
        <PageWrapper>
          <ActionResponseBlock
            template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
          />
        </PageWrapper>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ Application }: STORE) => ({
  status: Application.prepareForApplicationEdit.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { prepareForApplicationEditRequest, prepareForApplicationEditReset },
    dispatch
  );

const ApplicationEditConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationEdit)
);

export { ApplicationEditConnect as ApplicationEdit };
